import Vue from 'vue'
import Vuetify from 'vuetify'

import Centrelink from "~/components/icons/Centrelink";

Vue.use(Vuetify)

export default new Vuetify({
  icons: {
    values: {
      centrelink: {
        component: Centrelink,
      }
    }
  }
})
