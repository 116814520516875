import { mapState, mapActions, mapGetters, mapMutations } from 'vuex'
import { isEmpty } from 'lodash'
import { store } from './__store'

export default {  
    computed: {
        selectedLenderObj() {
            return store.selectedLenderObj
        },
        selectedPrimaryLender() {
            return store.selectedPrimaryLender
        },
        selectedPrimaryLenderName() {
            return store.selectedPrimaryLenderName
        }
    },
    methods: {
        resourceObjHelper(requestType = 'update', localResource = {}, userData = {}) {
            
            let data = null
            
            if ( requestType === 'update' ) {
                data = {
                    attributes: userData.attributes,
                    id: localResource.id,
                    relationships: { 
                        application: localResource.relationships.application
                    },
                    type: userData.type
                }
            }
            else {
                data = userData
            }

            return data
        },

        filterResources (resources) {

            for (var propName in resources) {
                if (resources[propName] === '' || resources[propName] === null) {
                    delete resources[propName];
                }
            }
            
            return resources
        },

        filterLenderType() {
            return ''
        },

        confirmedLender(data) {
            this.selected_lender = data
            this.$emit('user-confirmed', this.selected_lender)
        },     
    }
}