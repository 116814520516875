import StackTrace from 'stacktrace-js';

export async function processStackTrace(error) {
  try {
    const stackframes = await StackTrace.fromError(error);
    const stackString = stackframes
      .map(frame => frame.toString())
      .join('\n');
    return stackString;
  } catch (stackError) {
    console.error('Error processing stack trace:', stackError);
    return error.stack;
  }
}
