var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('FlexWrap',{on:{"resize":_vm.handleResize}},[_c('v-card',{staticClass:"mx-auto",attrs:{"disabled":_vm.isDisabled || _vm.blockPermissionsReadOnly,"tile":"","flat":""}},[_c('v-card-text',{staticClass:"px-4 py-2 px-md-10 pt-md-5"},[_c('v-container',{attrs:{"pa-0":"","fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{staticClass:"elevation-0 v-data-table-3",attrs:{"headers":_vm.tableHeaders,"items":_vm.collectedData,"items-per-page":25,"hide-default-footer":"","no-data-text":"No Records","mobile-breakpoint":0},scopedSlots:_vm._u([{key:"item.relationship",fn:function(ref){
var item = ref.item;
return [_vm._v("\n                                    "+_vm._s(item.attributes.relationship)+"\n                                ")]}},{key:"item.reference-name",fn:function(ref){
var item = ref.item;
return [_vm._v("\n                                    "+_vm._s(item.attributes['name'])+"\n                                ")]}},{key:"item.address",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"cell"},[_c('div',{staticClass:"text-truncate"},[_vm._v(_vm._s(_vm.getfullAddress(item)))])])]}},{key:"item.phone",fn:function(ref){
var item = ref.item;
return [_vm._v("\n                                    "+_vm._s(item.attributes.phone)+"\n                                ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{key:item.id,staticClass:"d-flex justify-end"},[_c('div',{key:item.id},[_c('EditReference',{key:item.id,attrs:{"action":"edit","app-id":_vm.appId,"form-address":_vm.filteredReferenceAddress(item),"form-reference":_vm.filteredReferenceData(item),"requiresLandLordReference":_vm.requiresLandLordReference},on:{"update-record":_vm.initializeSavedData}})],1),_vm._v(" "),_c('ConfirmModal',{attrs:{"title":"Delete Reference","confirm-text":"Delete","message":("Remove " + (item.attributes.name) + " as reference?")},on:{"confirm":function($event){_vm.removeReference(_vm.filteredReferenceAddress(item), item)}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',{attrs:{"text":"","depressed":"","fab":"","color":"light_gray_2","small":""},on:{"click":function($event){$event.stopPropagation();return on.apply(null, arguments)}}},[_c('v-icon',{attrs:{"size":"20"}},[_vm._v("mdi-delete")])],1)]}}],null,true)})],1)]}}])})],1)],1),_vm._v(" "),(_vm.hasErrors)?_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-alert',{attrs:{"dense":"","outlined":"","type":"error"}},[_c('div',[_vm._v("Landlord reference is required")])])],1)],1):_vm._e()],1)],1),_vm._v(" "),_c('v-card-actions',{staticClass:"controls px-4 px-md-10 pt-0 pt-md-0 pb-4 pb-md-8"},[_c('v-container',{attrs:{"pa-0":"","fluid":""}},[_c('v-row',[_c('v-col',{staticClass:"pt-0 d-flex justify-end"},[_c('SelectReference',{attrs:{"app-id":_vm.appId,"support-data":{ reference: _vm.resources, address: _vm.addresses },"requiresLandLordReference":_vm.requiresLandLordReference},on:{"create-record":_vm.initializeCreatedData}})],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }