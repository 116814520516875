<template>

    <v-card
    class="d-flex  flex-column lender-item justify-space-between"
    outlined
    :ripple="false"
    :width="width"
    :max-width="maxWidth">

        <v-card-title>
            <div>
                <div class="px-2 py-4 body-1 font-weight-medium font-size-22">
                    {{ getMonthlyAmount(lender['actual-payment']['monthly-payment'], getRepaymentsTermField) | currency }}
                    <span class="font-weight-normal font-size-12">/{{mappedRepaymentsTermField}}</span>
                    <div class="body-2 font-weight-medium" style="line-height: 1">
                        Over {{ parseInt(lender['actual-payment']['term-in-months']) / 12 }} Years
                    </div>
                </div>
            </div>
            <v-spacer />
        </v-card-title>

        <hr class="outline" :style="`background-color: ${hexToRGBA('#000000', 0.1)}`" />  

        <v-card-text class="pt-6">
            <div :class="`d-flex ${isUnsecured ? '' : 'mb-6'}`">
                <div style="width: 50%">
                    <div class="px-2">Interest rate</div>
                    <div class="px-2 body-1 font-weight-medium font-size-22">{{ (lender['actual-payment']['customer-rate'] ) | percentage }}</div>
                </div> 
                <div>
                    <div class="px-2">Comparison rate</div>
                    <div class="px-2 body-1 font-weight-medium font-size-22">{{ lender['actual-payment']['comparison-rate'] | percentage }}</div>
                </div> 
            </div> 

            <div class="d-flex" v-if="!isUnsecured">
                <div style="width: 50%">
                    <div class="px-2">All Fee Included</div>
                    <div class="px-2 body-1 font-weight-medium font-size-22">Yes</div>
                </div> 
                <div>
                    <div class="px-2">Balloon Payment</div>
                    <div class="px-2 body-1 font-weight-medium font-size-22">{{ lender['actual-payment']['balloon'] | currency }}</div>
                </div> 
            </div> 

        </v-card-text>

       <!-- <pre> {{ lender['actual-payment'] }}</pre> -->

        <hr class="outline" :style="`background-color: ${hexToRGBA('#000000', 0.1)}`" />  

        <v-card-text class="py-6">
            <div class="d-flex">
                <div style="width: 50%" class="pr-2">
                    <v-btn color="secondary" width="100%" 
                        outlined 
                        large 
                        @click="addToCompare(lender)"
                        depressed>
                        <v-icon size="25" class="mx-1" color="primary">
                            {{ 
                                getLendersToCompareNames.includes(lender.name) ? 
                                'mdi-check-circle-outline' :
                                'mdi-circle-outline'
                            }}
                        </v-icon>
                        Compare 
                        <!-- Lenders -->
                    </v-btn>
                </div> 
                <div style="width: 50%" class="pl-2">
                    <v-btn
                        depressed
                        large
                        width="100%"
                        @click="showInfoDialog(lender)"
                        color="secondary">
                            More Details
                    </v-btn>
                </div> 
            </div>  
        </v-card-text>
        <v-card-actions
            class="secondary justify-center cursor-pointer"
            @click="showApplyModal(lender.name, lender)">
            <div class="white--text body-1 font-size-18 py-4">
                Apply Now
            </div>
        </v-card-actions>

    </v-card>
 
</template>

<script>

    import { mapActions, mapGetters, mapState, mapMutations } from 'vuex'
    import mixins from '~/components/v1/QuoteResult6/_mixins'
    import {store, actions} from '~/components/v1/QuoteResult6/__store'

    export default {
        mixins: [
            mixins
        ],
        props: {
            lender: {
                type: Object,
                default: null
            },
            hideLender: {
                type: Boolean,
                default: false
            },
            index: {
                type: Number,
                default: 0
            },
            maxWidth: {
                type: [String, Number],
                default: 470
            },
            width: {
                type: String,
                default: '100%'
            }
        },
        data() {
            return {
            }
        },
        mounted(){

        },
        computed: {
            getLendersToCompareNames(){
                return store.lendersToCompare.map(lender => lender.name)
            }
        }, 
        methods: {
            showInfoDialog(lender){
                this.$emit('show-info', lender)
            },
            showApplyModal(name, lender){
                this.$emit('show-apply', {name, lender})
            },
            addToCompare(lender) {
                actions.setSelectedLendersToCompare(lender)
            }
    
        },
        watch: {
   
        }
    }
</script>

<style lang="scss">

    .flex__component-QuoteResult6 {

        .loan-details-section {
            .bl-spacer {
                border-right: 1px solid var(--v-light_gray_1-base);
            }
        }

    }
  
</style> 