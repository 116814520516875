export default [
    { 
        title: 'Consumer',
        icon: 'mdi-form-select',
        children: [
            { title: 'Consumer - Individual', icon: 'mdi-account' },
            { title: 'Consumer - Joint', icon: 'mdi-account-plus' },
            { title: 'Consumer - Individual - Non Borrowing Spouse', icon: 'mdi-account-multiple' }
        ]
    },
    { 
        title: 'Commercial',
        icon: 'mdi-form-select',
        children: [
            { title: 'Commercial - Company', icon: 'mdi-city' },
            { title: 'Commercial - Partnership', icon: 'mdi-account-multiple' },
            { title: 'Commercial - Trust - Individual Trustee', icon: 'mdi-briefcase-account' },
            { title: 'Commercial - Trust - Company Trustee', icon: 'mdi-shield-star' },
            { title: 'Commercial - Sole Trader', icon: 'mdi-account' },
            { title: 'Commercial - PAYG Chattel Mortgage', icon: 'mdi-shield-account' }
        ]
    }
]
