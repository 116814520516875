<template>
    <v-dialog
        v-model="dialog"
        persistent
        scrollable
        max-width="1124px"
    >

        <template v-slot:activator="{on}">
            <slot name="activator" v-bind:on="toggle"></slot>
        </template>

            <v-card tile flat class="rounded-lg flex-dialog" :disabled="saving">
            <v-progress-linear color="secondary" indeterminate stream absolute top v-if="saving"></v-progress-linear>

                <v-card-title>
                        <v-spacer />
                        <v-btn icon @click="close" >
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                </v-card-title>

                <v-card-text>
                    <v-carousel
                            light
                            v-model="carousel"
                            :continuous="false"
                            :show-arrows="false"
                            hide-delimiters
                            touchless
                            height="auto"
                            hide-delimiter-background>

                        <v-carousel-item>
                            
                            <v-card class="overflow-y-auto" tile  height="100%">
                                <v-card-text class="pa-0 pa-md-8 pt-0 pt-md-0">

                                    <v-card tile flat class="rounded-lg mx-auto" width="766px">
                                        
                                        <slot name="title-1"></slot>

                                        <v-card-text>
                                        <ValidationObserver ref="observer1" tag="form">

                                            <v-container>
                                                <v-row>

                                                <v-col cols="12" class="py-0">
                                                    <google-address-tool
                                                        name="address"
                                                        label="Address Search"
                                                        v-model="fullAddress" 
                                                        @change="handleAddressToolChange"
                                                    ></google-address-tool>
                                                </v-col>
                                                <v-col cols="12" sm="6" md="4" 
                                                        class="py-0"
                                                        v-for="(val, key) in resources.addresses.attributes"
                                                        :key="key">

                                                    <ValidationProvider 
                                                        v-slot="{ errors }" 
                                                        :name="labels.addresses[key]" 
                                                        :rules="getRules(key)" :vid="key">

                                                        <v-select
                                                            v-if="isFieldType('picklist', key)"
                                                            :items="getOptions(key)"
                                                            :label="labels.addresses[key]"
                                                            :error-messages="errors"
                                                            v-model="resources.addresses.attributes[key]"
                                                            color="secondary"
                                                        >
                                                        </v-select>

                                                        <v-text-field 
                                                            v-else
                                                            :type="'text'"
                                                            :label="labels.addresses[key]"
                                                            :error-messages="errors"
                                                            v-model="resources.addresses.attributes[key]"
                                                            color="secondary"
                                                        >
                                                        </v-text-field>

                                                    </ValidationProvider>

                                                </v-col>

                                                </v-row>
                                            </v-container>

                                        </ValidationObserver>
                                        </v-card-text>

                                    </v-card>
                                </v-card-text>
                            </v-card>
                        </v-carousel-item>

                        <v-carousel-item>
                            <v-card class="overflow-y-auto" tile  height="100%">
                                <v-card-text class="pa-0 pa-md-8 pt-0 pt-md-0">
                                    <v-card tile flat class="rounded-lg mx-auto" width="766px">
                                        
                                        <slot name="title-2"></slot>

                                        <v-card-text>
                                        <ValidationObserver ref="observer2" tag="form">
                                            <v-container>
                                                <v-row>

                                                <v-col cols="12" sm="6" 
                                                        class="py-0"
                                                        v-for="(val, key) in resources.occupancies.attributes"
                                                        :key="key">

                                                    <ValidationProvider     
                                                        v-if="isVisible(key)"
                                                        v-slot="{ errors }" 
                                                        :name="labels.occupancies[key]" 
                                                        :rules="getRules(key)" :vid="key">

                                                        <v-select
                                                            v-if="isFieldType('picklist', key)"
                                                            :readonly="readonly[key]"
                                                            :items="getOptions(key)"
                                                            :label="labels.occupancies[key]"
                                                            :error-messages="errors"
                                                            v-model="resources.occupancies.attributes[key]"
                                                            color="secondary"
                                                            @change="handleChange">
                                                        </v-select>

                                                        <flex-date-picker
                                                            v-else-if="isFieldType('date', key)"
                                                            :label="labels.occupancies[key]"
                                                            :errors="errors"
                                                            v-model="resources.occupancies.attributes[key]"
                                                        ></flex-date-picker>

                                                        <v-text-field 
                                                            v-else
                                                            :type="'text'"
                                                            :label="labels.occupancies[key]"
                                                            :error-messages="errors"
                                                            v-model="resources.occupancies.attributes[key]"
                                                            color="secondary"
                                                        >
                                                        </v-text-field>

                                                    </ValidationProvider>

                                                </v-col>

                                                </v-row>
                                            </v-container>

                                        </ValidationObserver>
                                        </v-card-text>

                                    </v-card>
                                </v-card-text>
                            </v-card>
                        </v-carousel-item>

                    </v-carousel>
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions class="pa-4 pa-md-6 flex-wrap controls">

                    <v-btn color="secondary" v-if="showNext" :min-width="140" large elevation="0" nuxt @click="handleNext">Next</v-btn>

                    <v-btn color="secondary" v-if="showSave" :min-width="140" large elevation="0" nuxt @click="handleSaveClose">Save & Close</v-btn>
                    <v-btn color="secondary" v-if="showSave" :min-width="140" large elevation="0" nuxt @click="handleSaveAdd">Save & Add <span class="d-none d-md-inline">&nbsp;Another Address</span></v-btn>

                    <v-spacer></v-spacer>

                    <template v-if="showBack">
                        <v-btn v-if="carousel == 0"  class="flex-back-button" color="light_gray_2" :min-width="140" large outlined elevation="0" nuxt @click="close">Back</v-btn>
                        <v-btn v-else color="light_gray_2" class="flex-back-button"  :disabled="disableBack" :min-width="140" large outlined elevation="0" nuxt @click="carousel--">Back</v-btn>
                    </template>

                    <template v-else>
                        <v-btn v-if="carousel == 0" color="light_gray_2" :min-width="140" large outlined elevation="0" nuxt @click="close">Back</v-btn>
                    </template>
                    
                </v-card-actions>

            </v-card>

    </v-dialog>
</template>

<script>

    import { mapActions, mapState, mapGetters, mapMutations } from 'vuex'
    import formHelpers from '~/components/shared/form-helpers.js'
    import validation from '~/components/shared/validation'
    import GoogleAddressTool from '~/components/base/GoogleAddressTool'
    import FlexDatePicker from '~/components/base/FlexDatePicker'
    import fieldOptions from '~/components/shared/field-options'
    import shared from '~/components/v1/ResidentialHistoryEditor2/__shared'

    export default {
        name: 'AddressEditorDialog1',
        mixins: [validation, formHelpers, shared],
        components: {
            GoogleAddressTool,
            FlexDatePicker
        },
        model: {
            prop: 'modelValue',
            event: 'input'
        },

        props: {
            modelValue: {
                type: Boolean,
                default: false
            },
        },

        data(){
            return {
                mode: 'create', // edit
                dialog: false,
                carousel: 0,
                fullAddress: '',
                saving: false,
                labels: {
                    addresses : {
                        "street-number": "Street Number",
                        "street-name": "Street Name",
                        "street-type": "Street Type",
                        "suburb": "Suburb",
                        "state": "State",
                        "postcode": "Postcode"
                    },
                    occupancies: {
                        "occupancy-situation": "Active",
                        "residential-situation": "Situation",
                        "start-date": "Start Date",
                        "end-date": "End Date"
                    }
                },
                visible: {},
                resources: {
                    people: {
                        type: 'people',
                        attributes: {}
                    },
                    businesses: {
                        type: 'businesses',
                        attributes: {}
                    },
                    addresses: {
                        type: 'addresses',
                        attributes: {
                            "street-number": null,
                            "street-name": null,
                            "street-type": null,
                            "suburb": null,
                            "state": null,
                            "postcode": null
                        },
                        relationships: {
                            application: {
                                data: { type: "applications", id: null } 
                            }
                        }
                    },
                    occupancies: {
                        type: 'occupancies',
                        attributes: {
                            "occupancy-situation": null,
                            "residential-situation": null,
                            "start-date": null,
                            "end-date": null
                        },
                        relationships: {
                            application: {
                                data: { type: "applications", id: null } 
                            }
                        }
                    },
                },
                readonly: {
                },
                validation_rules: {
                    attributes: {
                        "street-number" : "required|string|max:40",
                        "street-name"   : "required|string|max:255",
                        "street-type"   : "required|string|max:50",
                        "suburb": "required|string|max:80",
                        "state" : "required|in:required|in:Australian Capital Territory,New South Wales,Northern Territory,Queensland,South Australia,Tasmania,Victoria,Western Australia,Outside Australia",   
                        "postcode": "required|string|max:20|exists:postcodes,value",
                        "residential-situation": `required|in:${fieldOptions["residential-situation"]}`,
                        "occupancy-situation": "required|in:Current,Previous",
                        "start-date": "required|date_format:d/m/Y|before:now",
                        "end-date": "required_if:occupancy-situation,Previous|date_format:d/m/Y|before:now"
                    }
                }
            }
        },
        mounted(){

            this.mapResources(null)

        },
        computed: {
            ...mapState({
                appId: state => state.flows.appId,
            }),
            getPersonId(){

                if(this.entity){

                    return this.entity.id
                
                } else {

                    return this.resources.people.id || null

                }

            },
            getOccupancyId(){
                return this.resources.occupancies.id || null
            },
            getAddressId(){
                return this.resources.addresses.id || null
            },
            disableBack(){
                return this.carousel == 0
            },
            showNext(){

                if(this.entity && this.entity.type == 'people'){
                    return this.carousel == 0
                }

                return false

            },
            showSave(){

                if(this.entity && this.entity.type == 'people'){
                    return this.carousel != 0
                }

                return this.carousel == 0

            },
            showBack(){

                if(this.entity && this.entity.type == 'people'){
                    return true
                }

                return false

            },
        },
        methods: {
            ...mapActions('resource-actions', [
                'getAddress',
                'createAddress',
                'updateAddress',
                'createOccupancy',
                'updateOccupancy',
                'updateBusiness',
            ]),
            ...mapActions('flows', [
                'fetchAppData'
            ]),
            ...mapMutations('flows', ['updateAppData']),
            toggle(){
                this.dialog = !this.dialog
            },
            close(){

                this.reset()

                this.toggle()
                this.$emit('close')
            },
            handleAddressToolChange(address, obj){

                this.resources.addresses.attributes = {
                    "street-number": obj["street-number"],
                    "street-name": obj["street-name"],
                    "street-type": obj["street-type"],
                    "suburb": obj["suburb"],
                    "state": obj["state"],
                    "postcode": obj["postcode"]
                }

            },
            async handleNext(){
                const valid = await this.$refs.observer1.validate();
                
                if(valid){
                    this.carousel++
                }

            },
            async handleSaveAdd(){

                let valid = null;

                if(this.entity.type == 'people'){
                    valid = await this.$refs.observer2.validate();
                } else {
                    valid = await this.$refs.observer1.validate();
                }

                if(valid){

                        if(this.entity.type == 'people'){
                            await this.save()
                        } else {
                            await this.saveBusinessAddress()
                        }

                    this.mode = 'create'
                    
                    this.reset()

                }
                
            },
            async handleSaveClose(){

                let valid = null;

                if(this.entity.type == 'people'){
                    valid = await this.$refs.observer2.validate();
                } else {
                    valid = await this.$refs.observer1.validate();
                }

                if(valid){

                    if(this.entity.type == 'people'){
                        await this.save()
                    } else {
                        await this.saveBusinessAddress()
                    }

                    this.close()

                }

            },
            async saveBusinessAddress(){

                const {entity} = this
                const {addresses} = this.resources

                let businessReq = null;
                let addressReq = null;
                let data = null

                this.saving = true

                if(this.getAddressId) {

                    addressReq = this.updateAddress(addresses)

                    let payload = {
                        ...entity
                    }

                    payload.relationships = {
                            "addresses": {
                                data: [
                                    {
                                        id: this.getAddressId,
                                        type: 'addresses'
                                    }
                                ],
                                meta: {
                                    strategy: 'attach'
                                }
                            }
                        }

                    businessReq = this.updateBusiness(payload)

                    data = await this.processResponses([addressReq, businessReq])
    
                    this.saving = false


                    if(this.mode == 'create'){
                        this.$emit('create', data)
                    }

                    if(this.mode == 'edit'){
                        this.$emit('update', data)
                    }

                } else {
                    
                    let initAddress = await this.createAddress(addresses).then(res => {
                        this.resources.addresses = this.resourceFieldPicker(addresses, res.data.data)
                        return res
                    })


                    let payload = {
                        ...entity
                    }

                    payload.relationships = {
                            "addresses": {
                                data: [
                                    {
                                        id: initAddress.data.data.id,
                                        type: 'addresses'
                                    }
                                ],
                                meta: {
                                    strategy: 'attach'
                                }
                            }
                        }

                    businessReq = this.updateBusiness(payload)


                    if(initAddress.data.data){
                        addressReq = this.getAddress(initAddress.data.data.id)
                    }


                    data = await this.processResponses([addressReq, businessReq])

                    this.saving = false

                    if(this.mode == 'create'){
                        this.$emit('create', data)
                    }

                    if(this.mode == 'edit'){
                        this.$emit('update', data)
                    }

                }
                
                
            },
            async save(){

                const {addresses, occupancies} = this.resources

                let occupancyReq = null;
                let addressReq = null;
                let data = null

                this.saving = true

                if(this.getAddressId) {

                    addressReq = this.updateAddress(addresses)

                    occupancyReq = this.saveOccupancy()

                    data = await this.processResponses([addressReq, occupancyReq])
    
                    this.saving = false

                    // this.updateAppData(data)

                    // this.fetchAppData().then( res => {
                    //     this.updateAppData(data)
                    // })

                    if(this.mode == 'create'){
                        this.$emit('create', data)
                    }

                    if(this.mode == 'edit'){
                        this.$emit('update', data)
                    }

                } else {
                    
                    let initAddress = await this.createAddress(addresses).then(res => {
                        this.resources.addresses = this.resourceFieldPicker(addresses, res.data.data)
                        return res
                    })
                    
                    // address created
                    if(initAddress.status === 201) {

                        occupancyReq = await this.saveOccupancy()

                        if(initAddress.data.data){
                            addressReq = this.getAddress(initAddress.data.data.id)
                        }

                        data = await this.processResponses([addressReq, occupancyReq])

                    }

                    this.saving = false

                    // this.updateAppData(data)

                    // this.fetchAppData().then( res => {
                    //     this.updateAppData(data)
                    // })

                    if(this.mode == 'create'){
                        this.$emit('create', data)
                    }

                    if(this.mode == 'edit'){
                        this.$emit('update', data)
                    }

                }
                
            },
            async saveOccupancy(){

                const {occupancies} = this.resources

                if(this.getOccupancyId) {

                    // Relate address if new
                    if(this.getAddressId) {

                        let relationships = {
                            address: { data: {type: "addresses", id: this.getAddressId} }
                        }

                        this.resources.occupancies.relationships = {
                            ...occupancies.relationships,
                            ...relationships
                        }

                    }

                    return this.updateOccupancy(occupancies)

                } else {
                    
                    /**
                     *  1. If Occupancy is new, relate person
                     *  2. Save Address first then Occupancy
                     *  3. Then relate Address to Occupancy
                     */

                    let relationships = {
                        person: { data: {type: "people", id: this.getPersonId} },
                        address: { data: {type: "addresses", id: this.getAddressId} }
                    }

                    this.resources.occupancies.relationships = {
                        ...occupancies.relationships,
                        ...relationships
                    }

                    return this.createOccupancy(occupancies)

                }

            },
            async processResponses(args){

                try {

                    return await Promise.all(args).then(res => {
                        // add handler of requests error 
                        let items = []
                        res.forEach(data => {
                            items.push(data.data)
                        })

                        return items
                    })

                } catch (error) {

                    console.log('Ooops!..', error)

                    return {}
                }

            },
            
            mapResources(data){

                const {people, businesses, addresses, occupancies} = this.resources

                if(data){

                    // clone data to remove reactivity

                    if(data.type == 'people'){
                        this.resources.people = {...data.entity}
                    } else {
                        this.resources.businesses = {...data.entity}
                    }

                    this.resources.occupancies = {...data.occupancies}

                    if(data.type == 'people'){
                        this.resources.people = this.resourceFieldPicker(people, {...data.entity})
                    } else {
                        this.resources.businesses = this.resourceFieldPicker(businesses, {...data.entity})
                    }

                    this.resources.occupancies = this.resourceFieldPicker(occupancies, {...data.occupancies})
                    
                    if(data.addresses){
                        this.resources.addresses = {...data.addresses}
                        this.resources.addresses = this.resourceFieldPicker(addresses, {...data.addresses})
                    }

                }

                let relationships = {
                    application: {
                        data: {
                            type: "applications",
                            id: this.appId
                        }
                    }
                }

                this.resources.occupancies.relationships = relationships
                this.resources.addresses.relationships = relationships

            },
            initialize(){

                // add Current if none
                if(this.mode == 'create'){

                    let situation = this.hasCurrentOccupancy == null ? 'Current' : 'Previous'

                    this.resources.occupancies.attributes['occupancy-situation'] = situation

                    // Prefill end-date of Previous
                    
                    if(situation == 'Previous'){

                        if(this.getMostPreviousOccupancyByDate != null){

                            let date = this.getMostPreviousOccupancyByDate.attributes["start-date"]
                            let endDate = this.$options.filters.computeDateBy({operation: 'subtract', date, count: 1})

                            this.resources.occupancies.attributes['end-date'] = endDate
                        }

                    }

                    // Prefill start-date of Current
                    
                    if(situation == 'Current'){ 

                        // Reset end-date if its Current
                        this.resources.occupancies.attributes['end-date'] = ''  

                        if(this.getMostRecentOccupancyByDate != null){

                            let date = this.getMostRecentOccupancyByDate.attributes["end-date"]
                            let startDate = this.$options.filters.computeDateBy({operation: 'add', date, count: 1})

                            this.resources.occupancies.attributes['start-date'] = startDate
                        }

                    }

                }

            },
            async reset(){

                this.carousel = 0

                this.fullAddress = ''

                this.resources = {
                    people: {
                        type: 'people',
                        attributes: {}
                    },
                    businesses: {
                        type: 'businesses',
                        attributes: {}
                    },
                    addresses: {
                        type: 'addresses',
                        attributes: {
                            "street-number": null,
                            "street-name": null,
                            "street-type": null,
                            "suburb": null,
                            "state": null,
                            "postcode": null
                        },
                        relationships: {
                            application: {
                                data: { type: "applications", id: this.appId } 
                            }
                        }
                    },
                    occupancies: {
                        type: 'occupancies',
                        attributes: {
                            "occupancy-situation": null,
                            "residential-situation": null,
                            "start-date": null,
                            "end-date": null
                        },
                        relationships: {
                            application: {
                                data: { type: "applications", id: this.appId } 
                            }
                        }
                    },
                }

                // Reset validation

                if(this.$refs.observer1){
                    await this.$refs.observer1.reset()
                }

                if(this.$refs.observer2){
                    await this.$refs.observer2.reset()
                }

                this.initialize()

            },
            handleChange(value) {
                if(value == 'Current'){
                    this.resources.occupancies.attributes['end-date'] = ''
                }
            }
        },
        watch: {
            modelValue(value){
                this.dialog = value
            },
            data(data){

                this.mapResources(data.data)

                if(data.data){
                    this.mode = 'edit'
                }

                if(data.data == null){
                    this.mode = 'create'
                }

                // Do fields logic
                this.initialize()

            },
        }
    }
</script>

<style lang="scss" scoped>

    .v-card__title {


        .v-subheader  {
            display: block;
            line-height: 1.4;
            height: auto;
        }
    }

</style>