<template>
    <FlexWrap @resize="handleResize">
        <div class="d-flex flex-wrap justify-center">
    
            <template v-for="item in lenders" >
                <v-hover v-slot="{ hover }">
                        <v-card :key="item.name" 
                            @click="handleSaveCustomLender(item)"
                            class="custom-lender-card ma-3" 
                            elevation="6" 
                            :disabled="isDisabled(item.name)"
                            :style="`flex: 1 0; min-width: 200px;  max-width: ${cardSize}px; ${hover ? getHoverStyle : ''}`" 
                            width="auto">
                            <v-card-text class="card-item-inner">
                                
                                <div v-if="hasConnector(item)" class="direct-badge" :style="`background-color: ${hexToRGBA(getActiveTheme.colors.primary, 0.2)}`">
                                    <v-img 
                                        contain
                                        height="25"
                                        width="25"
                                        :src="require(`~/assets/images/direct-icon.png`)">
                                    </v-img>
                                </div>
    
                                <div class="text-center py-4">
                                    <v-img 
                                        max-width="160"
                                        max-height="45"
                                        :src="getLenderLogo(item['logo-class'])" 
                                        position="center center" 
                                        contain>
                                    </v-img>
                                </div>
    
                                <div class="mt-2 d-none">
                                    <v-chip color="light_gray_3" small>
                                        {{ item.alias }}
                                    </v-chip>
                                </div>
    
                            </v-card-text>
                        </v-card>
                </v-hover>
            </template>
    
            <QuoteToolCustomLenderModal
                v-if="isConsumer"
                :show-dialog="otherLenderDialog"
                @save-name="handleSaveOtherCustomLender">
                <template v-slot:activator-button="{ on, attrs }">
                    <v-hover v-slot="{ hover }">
                    <v-card 
                        v-bind="attrs" v-on="on"
                        :style="`flex: 1 0; min-width: 200px;  max-width: ${cardSize}px; ${hover ? getHoverStyle : ''}`" 
                        class="custom-lender-card ma-3" 
                        elevation="6" 
                        width="auto">
                        <v-card-title class="card-item-inner sm text-center">
                            Custom Lender
                        </v-card-title>
                    </v-card>
                </v-hover>
    
                </template>
            </QuoteToolCustomLenderModal>        
            
        </div>
    </FlexWrap>
    </template>
    <script>
    import { mapGetters, mapActions, mapState } from 'vuex'
    import FlexWrap from '~/components/base/FlexWrap'
    import helpers from '~/plugins/helpers'
    import QuoteToolCustomLenderModal from './_QuoteToolCustomLenderModal'
    
    import { store, mutations } from './__store'
    
    export default {
        mixins: [ helpers ],
        components: {
            QuoteToolCustomLenderModal,
            FlexWrap
        },
        props: {
            lenders: {
                type: Array,
                default: () => []
            },
            quotedLenders: {
                type: Array,
                default: () => []
            },
            otherLenderDialog: {
                type: Boolean,
                default: false
            }
        },
        data() {
            return {
                selectedLenderName: '',
                selectedLender: {},
                cardSize: 240
            }
        },
        computed: {
            ...mapState('flows', [
            'loanData',
        ]),
            ...mapGetters('themes', [
                'getActiveTheme'
            ]),
            getHoverStyle(){
                return `background-color: ${this.hexToRGBA(this.getActiveTheme.colors.light_gray_3, 1)}`
            },
            isConsumer(){
                if(this.loanData){
                    return  this.loanData.attributes['finance-for-business-purposes'] == 'No'
                }
                return false
            },
        },
        mounted() {
    
        },
        methods: {
            getLenderLogo(name){
    
                let lender = require(`~/assets/images/logo/placeholder.png`)
    
                try {
    
                    lender = require(`~/assets/images/logo/cropped/${name}.png`)
                    return lender
    
                } catch (error) {
    
                    console.log('Ooops!..', error)
                    return lender
    
                }
    
            },
            handleSaveCustomLender(lender) {
                this.$emit('save', lender?.name)
            },
            handleSaveOtherCustomLender(name) {
                this.$emit('save', name)
            },
            hexToRGBA(hex, a = 1){
                return this.$options.filters.hexToRGBA(hex, a)
            },
            hasConnector(item){
                return item?.connector
            },
            isDisabled(name){
    
                let flag = false
    
                this.quotedLenders.forEach(lender => {
                    if(lender.name == name) {
                        flag = true
                    }
                });
    
               return flag
            },
            handleResize(sizes){
    
                const cards = Array.from(this.$el.querySelectorAll('.custom-lender-card'))
    
                const width = Math.min(...cards.map(card => {
                    return card.clientWidth
                })) || 200
                
                this.cardSize = width
    
                // console.log(width)
    
            },
        },
        watch: {
     
        }
    }
    </script>
    <style lang="scss">
        .direct-badge {
            position: absolute;
            z-index: 1;
            top: -15px;
            right: -15px;
            width: 40px;
            height: 40px;
            border-radius: 20px;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    
        .card-item-inner {
            display: flex;
            align-items: center;
            flex-direction: column;
            justify-content: center;
            height: 100%;
            line-height: 2rem !important;
        }
    
        .custom-lender-card {
            min-height: 110px;
        }
    
    </style>
    