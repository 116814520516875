<template>
  <v-container class="fill-height ma-0 pa-0 pb-10">
    <v-card
      v-if="boxToken && boxFolderId"
      flat
      width="100%"
      height="100%"
      class="light_gray_1 mx-0 pa-0"
    >
      <v-card-text>
        <div class="display-1">
          <div
            class="box-container"
            id="box-container"
            :style="`height: ${boxHeight}px !important`"
          ></div>
          <input type="hidden" id="box_token" :value="boxToken" />
          <input type="hidden" id="folder_id" :value="boxFolderId" />
        </div>
      </v-card-text>
    </v-card>

    <v-card
      v-else
      flat
      width="100%"
      height="100%"
      class="light_gray_1 mx-0 pa-0"
    >
      <v-container fill-height>
        <v-row class="justify-center align-center">
          <v-col cols="12">
            <v-card flat class="light_gray_1 py-12 my-12">
              <p class="body-1 light_gray_2--text text-center pa-0 ma-0">
                Cant find your Resources
              </p>
              <p class="caption light_gray_2--text text-center">
                Please contact your data manager.
              </p>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </v-container>
</template>

<script>
import LoaderImage from "./loaderImage";
// import ContentExplorer from 'box-ui-elements/es/elements/content-explorer'
// import messages from 'box-ui-elements/i18n/en-US'
import resourceHelpers from "~/components/shared/resource-helpers";
import { mapActions, mapState } from "vuex";
import { isEmpty } from "lodash";

export default {
  name: "BoxApi",

  mixins: [resourceHelpers],

  head() {
    return {
      link: [
        {
          rel: "stylesheet",
          href: "https://cdn01.boxcdn.net/platform/elements/13.0.0/en-US/explorer.css",
        },
      ],
      script: [
        {
          src: "https://cdn.polyfill.io/v2/polyfill.min.js?features=es6,Intl",
        },
        {
          src: "https://cdn01.boxcdn.net/polyfills/core-js/2.5.3/core.min.js",
        },
        {
          src: "https://cdn01.boxcdn.net/platform/elements/4.4.0/en-US/explorer.js",
          callback: async () => {
            await this.loadBox();
            this.$emit("box-created", this.box);
          },
        },
      ],
    };
  },

  components: {
    LoaderImage,
  },
  data() {
    return {
      loading: true,
    };
  },
  props: {
    boxToken: {
      type: [String, Number],
      default: "",
    },
    boxFolderId: {
      type: [String, Number],
      default: "",
    },
    boxHeight: {
      type: [Number, String],
      default: 500
    }
  },

  computed: {
    ...mapState("auth-actions", ["userSettings"]),
    ...mapState("auth", ["user"]),
  },

  methods: {
    ...mapActions("resource-actions", [
      "getApplicationBox",
      "updateBox",
      "createBox",
      "createFileNote",
    ]),
    async loadBox() {
      if (typeof Box == "object") {
        var folderId = this.boxFolderId;
        var accessToken = this.boxToken;
        var contentExplorer = await new Box.ContentExplorer();

        contentExplorer.show(folderId, accessToken, {
          container: ".box-container",
          canDownload: true,
          canDelete: false,
          canRename: false,
          canUpload: false,
          canShare: false,
          canNavigate: true,
          canSearch: false,
          canCreateNewFolder: false,
          canUpload: false,
        });

        this.$emit("rendered", true);
      }
    },
  },

  data() {
    return {
      loading: false,
    };
  },
};
</script>

<style lang="scss" scoped>
::v-deep .box-message {
  position: absolute;
  height: 100% !important;
  width: 100% !important;
}
::v-deep div#box-container {
  height: 500px !important;
}
::v-deep .be .btn-plain {
  font-size: 15px;
}

::v-deep .be-logo {
  display: none !important;
}
::v-deep .be-breadcrumb-more {
  display: none !important;
}
::v-deep .be-header {
  display: none !important;
}
::v-deep .dropdown-menu-element {
  z-index: 999999 !important;
}
</style>
