<template>
    <FlexWrap @resize="handleResize">
        <v-card elevation="0" flat class="light_gray_1 mb-6">
            <v-expansion-panels v-model="panels">
                <v-expansion-panel class="pa-0">

                    <v-expansion-panel-header expand-icon="mdi-menu-down" class="py-0 px-2 ma-0">
                        <template v-slot:actions>
                            <v-icon color="light_gray_2" size="30" class="panel-icon">mdi-menu-down</v-icon>
                        </template>
                        <div class="body-2 panel-name font-weight-bold">
                            {{ label }}
                        </div>
                    </v-expansion-panel-header>

                    <v-expansion-panel-content class="inner-content pa-0 ma-0">
                        
                            <v-card flat class="pa-2 ma-0" width="100%" elevation="0">
                                <v-card-text class="light_gray_1 text-center pa-0 ma-0">

                                    <v-sheet width="100%" elevation="0" class="light_gray_1 mx-auto">
                                        <v-data-table dense
                                            :headers="tableHeaders"
                                            :items="filteredIncomes"
                                            hide-default-footer
                                            class="elevation-0 v-data-table-2"
                                            :mobile-breakpoint="0">

                                            <template #[`item.owner`]="{ item, index }">
                                                <!-- -><pre>{{ item }}</pre> -->
                                                {{ displayOwner(index) }}
                                            </template>

                                            <template #[`item.income-situation`]="{ item }">
                                                {{ displayData(item.attributes['income-situation']) }}
                                            </template>

                                            <template #[`item.income-type`]="{ item }">
                                                {{ displayIncomeKind(item) }}
                                            </template>

                                            <template #[`item.start-date`]="{ item }">
                                                {{ displayData(item.attributes['start-date'], 'date') }}
                                            </template>

                                            <template #[`item.end-date`]="{ item }">
                                                {{ displayData(item.attributes['end-date'], 'date') }}
                                            </template>

                                        </v-data-table>
                                        
                                    </v-sheet>                                        
                                    
                                </v-card-text>
                            </v-card>

                    </v-expansion-panel-content>

                </v-expansion-panel>
            </v-expansion-panels>
        </v-card>
    </FlexWrap>
</template>

<script>

    import helpers from '~/plugins/helpers'
    import FlexWrap from '~/components/base/FlexWrap'
    import {store} from '~/components/v1/FinancialObjectsEditor1/__store'
    import {  mapGetters } from 'vuex'
    import { isEmpty } from 'lodash'

    export default {
        name: 'IncomeSummaryTable',
        mixins: [ helpers ],
        components: { FlexWrap },
        props: {
            label: {
                type: String,
                default: 'View Incomes'
            },
            entities: {
                type: Array,
                default: () => []
            },
            incomes: {
                type: Array,
                default: () => []
            },
            activeRoles: {
                type: Array,
                default: () => []
            }
        },
        computed: {
            ...mapGetters('kinds', ['getKindsIncome']),
            tableHeaders() {
                if (!this.sizes) return

                const { sm, xs } = this.sizes

                let newHeaders = [
                    ...this.headers
                ]

                let heads = [
                    // { value: 'owner', width: '20%' },
                    { value: 'income-situation', width: '25%' },
                    { value: 'income-type', width: '25%' },                        
                    { value: 'start-date', width: '25%' },
                    { value: 'end-date', width: '25%' }
                ]

                if (xs) {
                    heads = [
                        { value: 'income-situation', width: '20%' },
                        { value: 'start-date', width: '20%' },
                        { value: 'end-date', width: '20%' }
                    ]
                }

                return newHeaders.reduce((accumulator, current) => {

                    heads.forEach(head => {

                        if(head.value == current.value){

                            let newCurrent = {...current}

                            newCurrent.width = head.width

                            accumulator.push(newCurrent)
                        }

                    })

                    return accumulator 

                }, [])

            },
            filteredIncomes() {
                
                let incomes = []
                
                this.filteredEntities.forEach( entity => {
                    entity.relationships.incomes.data.forEach( income => {
                        incomes.push(income.id)
                    })
                })

                let list = this.incomes.filter( i => {
                    return incomes.find( f => {
                        return f === i.id
                    })
                })
                
                // Get Current incomes
                let current = list.filter( f => {
                    return f.attributes['income-situation'] === 'Current'
                })
                current.sort( (a, b) => {
                    return new Date(b.attributes['start-date']) - new Date(a.attributes['start-date'])
                })                

                // Get Secondary incomes
                let secondary = list.filter( f => {
                    return f.attributes['income-situation'] === 'Secondary'
                })
                secondary.sort( (a, b) => {
                    return new Date(b.attributes['start-date']) - new Date(a.attributes['start-date'])
                })

                // Get Previous incomes
                let previous = list.filter( f => {
                    return f.attributes['income-situation'] === 'Previous'
                })
                previous.sort( (a, b) => {
                    return new Date(b.attributes['start-date']) - new Date(a.attributes['start-date'])
                })
                
                return current.concat(secondary).concat(previous)
            },
            filteredEntities() {
                return this.entities.filter( entity => {
                    return this.activeRoles.find( role => {
                        return role === entity.id
                    })                    
                })
            },
            incomeKinds() {
                return this.getKindsIncome.children
            }
        },
        methods: {
            displayData(value, type = 'string') {
                if ( type === 'string')
                    return isEmpty(value) ? '--' : value
                else 
                    return isEmpty(value) ? '--' : this.$options.filters.dateFormatFE_2(value)
            },
            displayOwner(index) {
                if (this.filteredEntities?.[index]?.type === 'people') {
                    if ( isEmpty(this.filteredEntities[index].attributes['first-name']) && isEmpty(this.filteredEntities[index].attributes['last-name'])) {
                        return `Applicant ${this.filteredEntities[index].id}`
                    }
                    return `${this.filteredEntities[index]?.attributes?.['first-name']} ${this.filteredEntities[index]?.attributes?.['last-name']}`
                }
                else {
                    if ( isEmpty(this.filteredEntities[index]?.attributes?.['business-legal-name'])) {
                        return `Applicant ${this.filteredEntities?.[index]?.id}`
                    }
                    return `${this.filteredEntities[index]?.attributes?.['business-legal-name']}`
                }                
            },
            displayIncomeKind(item) {
                let id = item?.relationships.kind.data.id              
                let kind = this.findById(this.incomeKinds, id)
                return kind?.name ? kind.name : ''
            },
            findById(obj, id, flag = true) {

                let kind = null

                kind = obj.find( o => {
                    if (o.id === id) {
                        return o                        
                    }
                
                    else if (o.children.length > 0) {
                        return this.findById(o.children, id, false)    
                        
                    }
                })

                if ( !flag ) {
                    return kind
                }

                return kind
            }
        },
        data() {
            return {
                panels: 0,
                headers: [
                    { text: 'Owner', align: 'left', sortable: false, value: 'owner' },
                    { text: 'Income Situation', align: 'left', sortable: false, value: 'income-situation' },
                    { text: 'Income Type', align: 'left', sortable: false, value: 'income-type' },
                    { text: 'Start Date', align: 'left', sortable: false, value: 'start-date' },
                    { text: 'End Date', align: 'left', sortable: false, value: 'end-date' }
                ]
            }
        }
    }
</script>

<style lang="scss" scoped>

    $expansion-panel-content-padding: 0px !default;

    .v-expansion-panels {
        .v-expansion-panel {
            background: none !important;
            &:before {
                box-shadow: none !important;
            }
            &:after, &::after {
                border-color: rgba(0, 0, 0, 0) i !important;
            }

            
            
        }
        
    }

    .inner-content {        
        padding: 10px !important;
        padding-top: 0 !important;
        .v-expansion-panel-content__wrap {
            padding: 0 !important;
        }
    }

    .panel-icon {
        order: 0;
    }

    .panel-name {
        order: 1;
        padding-left: 5px;
    }

    tr {
        .v-data-table__expanded {
            .v-data-table__expanded__content {
                &.hover {
                    background-color: red !important;
                }
            }
        }
        &.hover {
            background-color: red !important;
        }
    }

    ::v-deep {
        .v-expansion-panel-header__icon {
            &:not(.v-expansion-panel-header__icon--disable-rotate) {
                .v-icon {
                    transform: rotate(-90deg);
                }
            }
        }
    }

    .v-expansion-panel--active {
        .v-expansion-panel-header--active .v-expansion-panel-header__icon {
            &:not(.v-expansion-panel-header__icon--disable-rotate) {
                .v-icon {
                    transform: rotate(0deg);
                }
            }
        }
    }
</style>