//  Automatic Component Registration
const requireComponent = require.context(
    // The relative path of the components folder
    '../v1',
    // Whether or not to look in subfolders
    true,
    // The regular expression used to match base component filenames
    /[A-Z]\w+\.(vue|js)$/
)

let components = {}

requireComponent.keys().forEach(fileName => {

    if(!fileName.includes('./templates/')){

        const componentConfig = requireComponent(fileName)
        const name = fileName.split('/').pop().replace(/\.\w+$/, '')
        const first = name.charAt(0)

        // Exclude components with underscore

        
        if(first != '_'){
            components[name] = componentConfig.default
        }

    }

})

export default components
