<template>
    <v-card 
        flat
        class="light_gray_1 pa-6">

        <v-card-actions class="pa-0">
            <v-btn width="40%" disabled class="light_gray_1 darken-1" color="red" depressed text large></v-btn>
        </v-card-actions>

        <v-card-text class="px-0">
            
            <v-sheet 
                class="pa-0 mb-2"
                color="secondary" 
                outlined dark rounded>
                <v-list class="secondary" three-line>
                    <v-list-item>
                        <v-list-item-avatar>
                            <v-icon>mdi-information</v-icon>
                        </v-list-item-avatar>
                        <v-list-item-content>
                            <v-list-item-subtitle class="white--text font-weight-bold">To view lender info, select a lender from the table</v-list-item-subtitle>
                            
                        </v-list-item-content>
                    </v-list-item>
                </v-list>
            </v-sheet>

            <v-sheet 
                class="px-0 my-6" height="20"
                color="light_gray_1 darken-1" 
                outlined dark>
            </v-sheet>

            <v-row>
                <v-col 
                    v-for="n in 3" :key="n"
                    cols="10" class="my-0">
                    <v-sheet 
                        v-for="n in 3" :key="n" height="10"
                        class="px-0 mb-1"
                        color="light_gray_1 darken-1" 
                        outlined dark>                                
                    </v-sheet>
                </v-col>                        
            </v-row>

            <template v-for="x in 2">
                <v-sheet 
                    :key="x"
                    class="px-0 my-6" height="20"
                    color="light_gray_1 darken-1" 
                    outlined dark>
                </v-sheet>

                <v-row :key="`x${x}`">
                    <v-col 
                        v-for="n in 1" :key="n"
                        cols="10" class="my-0">
                        <v-sheet 
                            v-for="n in 3" :key="n" height="10"
                            class="px-0 mb-1"
                            color="light_gray_1 darken-1" 
                            outlined dark>                                
                        </v-sheet>
                    </v-col>                        
                </v-row>
            </template>
            
        </v-card-text>
    </v-card>

</template>

<script>
    export default {
        name: 'QuotePlaceholder'
    }
</script>

<style lang="scss" scoped>

</style>