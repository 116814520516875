/**
 * All stepable / navable related
 */

// initial state
export const  state = () => ({

    // Global Modes to disable / enable all components with subscriptions
    navableMode: "enabled", 
    stepableMode: "enabled",
    
    isStepable: false, // Globally declared since 1 and only 1 Stepable component per Step (is current step stepable?)
    hardValidation: true,

    // Controlled via block permission read-only
    validation: true, 

    isStepableDataWorkable: true, // Handles if current step data is valid see: selectNextStep & selectPrevStep for usage

    // Globalize Stepable's push method
    stepablePush: ()=>{},
    appableDataChangeFns: [], // Array of onAppDataChange()
})

// getters
export const  getters = {

}

// mutations
export const  mutations = {

    // Check if Step is Stepable
    setStepable (state, payload = false) {
        state.isStepable = payload
    },

    // Globalize Stepable's push method
    setStepablePush (state, payload) {
        if(!payload){ console.log('%c Push is required ', 'background: #e63946; color: #f1faee'); return };
        state.stepablePush = payload
    },

    setHardValidation(state, payload = true) {
        state.hardValidation = payload
    },

    setStepableDataWorkable(state, payload = true) {
        state.isStepableDataWorkable = payload
    },

    /**
     * setGlobalMode: set stepable and navable modes at once. 
     */
    setGlobalMode(state, payload) {
        state.stepableMode = payload
        state.navableMode = payload
    },

    setNavableMode(state, payload) {
        state.navableMode = payload
    },

    setStepableMode(state, payload) {
        state.stepableMode = payload
    },
    setValidation(state, payload) {
        state.validation = payload
    },
    resetSubscriptions(state) {
        state.validation = true
    }
}
