<template>
    <v-card class="lender-badge" color="light_gray_1" height="100%" style="width: 100%;" flat>

        <v-progress-linear 
            v-if="disabled || syncedDisabled"
            color="secondary"
            indeterminate stream top 
            value="0">
        </v-progress-linear>

        <v-overlay
            absolute
            :opacity="0.1"
            v-if="disabled || syncedDisabled">
        </v-overlay>

        <v-card             
            :class="`selection-badge py-6 px-2 ${hasError ? 'error lighten-5' : 'light_gray_1'} pb-0`" 
            flat>

            <v-card-text class="text-center pa-0">
                
                <p :class="`${$vuetify.breakpoint.lgAndUp ? 'title' : 'title'} font-weight-bold py-2 px-sm-6 px-md-6 px-lg-6 text-capitalize`">
                    {{ title }}<br/>Lender
                    <v-avatar v-if="hasError" size="25" color="error">
                        <v-icon color="white">mdi-exclamation</v-icon>
                    </v-avatar>
                </p>
                
            </v-card-text>

            <!-- Selected lenders -->
            <v-card-text class="pa-0" v-if="hasLogo">
                
                <v-card-text height="500" class="text-center py-0 px-0">
                    
                    <p class="lender-status overline text-uppercase font-weight-bold light_gray_2--text pa-0 ma-0">
                        {{ selectedLender.attributes['lender-name'] }}
                    </p>
                    
                    <v-card class="light_gray_1" flat height="100">
                        <div class="text-center py-0 px-6">
                            <v-img
                                height="100"
                                contain
                                :class="`${$vuetify.breakpoint.lgAndDown ? 'ma-0 pa-0' : 'ma-0 pa-0'} text-center`"
                                :src="lenderImage" >
                            </v-img>
                        </div>
                    </v-card>
                    
                </v-card-text>
                
            </v-card-text>
            
            <!-- Custom Lenders -->
            <div v-if="isCustom || hasLogo">                
                <v-card-text v-if="!hasLogo" class="text-center py-0 px-0">
                    
                    <p class="lender-status overline text-uppercase font-weight-bold light_gray_2--text pa-0 ma-0">
                        Custom Lender
                    </p>

                    
                    <v-card class="light_gray_1" flat height="100">
                        <v-card-text class="py-8 px-0 pb-4">
                            <v-chip
                                draggable
                                class="mx-0 text-capitalize"
                                color="light_gray_2 lighten-1">
                                <span class="subtitle-2 font-weight-bold">{{ lenderName }}</span>
                            </v-chip>
                        </v-card-text>                    
                    </v-card>
                    
                </v-card-text>                

            </div>

            <!-- Empty entry of lenders -->
            <div v-else>
                <v-card-text class="text-center py-0 px-0">                

                    <p class="lender-status overline text-uppercase font-weight-bold error--text pa-0 ma-0">
                        Lender not set
                    </p>
                    
                </v-card-text>

                <v-card class="light_gray_1" flat height="100">
                    <v-card-text class="py-8 px-0 pb-4">
                        <p class="lender-description caption text-center font-weight-medium ma-0 mx-4">
                            Please select {{ title === 'primary' ? 'a' : 'an' }} 
                            <span class="text-lowercase">{{ title }}</span>
                            lender or add your own custom lender.
                        </p>
                    </v-card-text>                    
                </v-card>
                
            </div>
            
            
        </v-card>

        <v-footer color="light_gray_1 pb-4" padless>
            <v-col
                class="text-center px-0"
                cols="12">
                
                <v-card flat class="light_gray_1 px-0">

                    <v-card-actions
                        v-if="isCustom || hasLogo">
                        <v-spacer></v-spacer>
                        <CustomerLendersDialog
                            v-if="isCustom"                        
                            @confirm-save="save"
                            :title="`Edit Custom ${title} Lender`"
                            :lender-type="title"
                            :bid-data="selectedBid"
                            :bid-lender-data="selectedLender"
                            label="Edit" />
                        <CustomerLendersDialog
                            v-else
                            @confirm-save="save"
                            :request-type="title"
                            :title="`Edit ${title} Lender`"
                            :lender-type="title"
                            :bid-data="selectedBid"
                            :bid-lender-data="selectedLender"
                            label="Edit" />

                        <ResetLender 
                            @confirmed-action="resetLender"
                            :message="`Are you sure you want to remove ${lenderName} as your ${title} lender?`"
                            :outlined="true"
                            text
                            color="white"
                            :disabled="false"
                            :fixed-button="false"
                            button-label="Reset" />
                        <v-spacer></v-spacer>
                    </v-card-actions>

                    <v-card-actions
                        v-else>                    
                        <v-spacer></v-spacer>        
                        <CustomerLendersDialog
                            @confirm-save="save"
                            :bid-data="selectedBid"
                            :new-record="true"
                            :bid-lender-data="selectedLender"
                            :title="`Add Custom ${title} Lender`"
                            :lender-type="title"                    
                            :label="`${$vuetify.breakpoint.lgAndUp ? 'Add Custom' : 'Custom'}`" />
                        <v-spacer></v-spacer>                
                    </v-card-actions>

                </v-card>

            </v-col>
        </v-footer>

    </v-card>
</template>

<script>

    import { store, mutations } from './__store'
    import CustomerLendersDialog from './_BidCustomLenders'
    import ResetLender from '~/components/base/ConfirmModal'
    import { cloneDeep } from 'lodash'
    import { mapActions } from 'vuex'    

    export default {
        name: 'SelectionBadge',

        components: {
            CustomerLendersDialog,
            ResetLender
        },

        props: {
            selectedLender: {
                type: Object,
                default: () => {}
            },
            selectedBid: {
                type: Object,
                default: () => {}
            },
            title: {
                type: String,
                default: 'Primary'
            },            
            hasError: {
                type: Boolean,
                default: false
            },
            syncedDisabled: {
                type: Boolean,
                default: false
            }
        },

        computed: {
            lenderName() {
                return this.selectedLender?.attributes?.['lender-name']
            },
            getBidLenderId() {
                return this.selectedLender?.id
            },
            getBidId() {
                return this.selectedBid?.id
            },
            hasLogo() {
                return this.selectedLender?.attributes?.['lender-logo-class'] ? true : false
            },
            isCustom() {
                return this.selectedLender?.id && !this.selectedLender?.attributes?.['lender-logo-class'] ? true : false
            },
            lenderImage() {
                return require(`~/assets/images/logo/${this.selectedLender.attributes['lender-logo-class']}.png`)
            },
            getSelectedLender() {
                return store.selectedLenderObj[this.title]
            }
        },      

        methods: {
            
            ...mapActions('resource-actions', [
                'deleteBidLender',
                'createBidLender',
                'updateBidLender',
                'createBid',
                'updateBid'
            ]),

            async save(data) {

                this.disabled = true
                
                let reqs = []
                let noBidRecord = false
                let bid = null
                let bidLender = null

                delete this.resources['bid']?.relationships?.['bid-lender']

                if ( data['bid']?.id ) {
                    // Update BID record
                    delete data['bid'].relationships['bid-lenders']

                    reqs.push( this.updateBid(data['bid']) )
                }
                else {
                    // Create BID record
                    let bidPayload = await this.createBid(data['bid']) 
                    noBidRecord = true

                    if ( bidPayload ) {
                        bid = bidPayload                    
                        data['bid-lender'].relationships.bid.data.id = bidPayload.data.data.id
                    }                    
                    
                }

                if ( data['bid-lender']?.id ) {
                    // Update BID LENDER record
                    reqs.push( this.updateBidLender(data['bid-lender']) )
                }
                else {
                    // Create BID LENDER record
                    reqs.push( this.createBidLender(data['bid-lender']) )
                }

                try {
                    Promise.all( reqs ).then( res => {

                        if ( !noBidRecord )
                            bid = res.find( payload => {
                                return payload.data.data.type === 'bids'
                            })

                        bidLender = res.find( payload => {
                            return payload.data.data.type === 'bid-lenders'
                        })

                        this.resources['bid'] = bid.data.data
                        this.resources['bid-lender'] = bidLender.data.data

                        // remove empty
                        this.resources['bid'].attributes = this.filterResources(this.resources['bid'].attributes)
                        // console.log(this.resources['bid'].attributes)
                        
                        // this.isCustom = true
                        this.isCleared = false

                        if ( this.title === 'primary' )
                            mutations.setPrimary(
                                {
                                    'bid': this.resources['bid'],
                                    'bid-lender': this.resources['bid-lender']
                                }
                            )
                        else
                            mutations.setAlternative(
                                {
                                    'bid': this.resources['bid'],
                                    'bid-lender': this.resources['bid-lender']
                                }
                            )
                        
                        this.$emit('save-custom-lender', {
                            flag: true,
                            data: {
                                'bid': this.resources['bid'],
                                'bid-lender': this.resources['bid-lender']
                            }
                        })
                        
                        this.disabled = false                  
                        
                    })
                } catch ( error ) {
                    console.log(`Ooops!.. `, error)
                    this.disabled = false
                }

            },

            filterResources (resources) {

                for (var propName in resources) {
                    if (resources[propName] === '' || resources[propName] === null) {
                        delete resources[propName];
                    }
                }
                
                return resources
            },

            async resetLender() {
                
                this.disabled = true
                
                let res = await this.deleteBidLender(this.getBidLenderId)

                if ( res.status === 204 ) {
                    // this.isCustom = false
                    this.isCleared = true

                    if ( this.title === 'primary' )
                        mutations.resetPrimary()
                    else
                        mutations.resetAlternative()
                    
                    this.$emit('reset-custom-lender', {
                        type: this.title,
                        id: this.getBidLenderId
                    })

                    this.resources['bid'] = {}
                    this.resources['bid-lender'] = {}
                
                }

                this.disabled = false

            },
        },

        watch: {
            
        },

        data() {
            return {
                disabled: false,
                // isCustom: false,
                isCleared: false,
                flag: false,
                resources: {
                    'bid': {},
                    'bid-lender': {}
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
    .selection-badge {
        width: 100%;
        
        .lender-status,
        .lender-description {
            line-height: normal;
        }
    }
</style>