<template>
    <div class="flex__sub-component-CheckBox2">
        <v-list>
            <v-list-item-group
                v-model="selected"
                active-class="white--text"
                multiple>

                <div :class="`body-1 font-weight-medium ${errors[0] ? 'has-errors': ''}`" style="font-size: 1.1rem !important;">{{ label }}</div>
                
                <template v-for="item in options">                    
                    <v-list-item :key="item.title" :ripple="false" class="pa-0 ma-0 my-2">
                        <template v-slot:default="{ active }">
                            <v-card flat width="100%" class="pa-0 ma-0">
                                <v-alert outlined :color="`${active ? 'secondary' : errors[0] ? 'error' : 'light_gray_1'}`" class="rounded-xl pa-0 my-1">
                                    <v-card flat :class="`${active ? 'secondary lighten-5' : ''} rounded-xl`">
                                        <v-list-item-content>
                                        
                                            <v-list-item-subtitle>
                                                <div class="text--primary font-weight-medium px-8">
                                                    {{ item.text }}
                                                </div>
                                            </v-list-item-subtitle>
                                            
                                        </v-list-item-content>
                                    </v-card>
                                </v-alert>
                            </v-card>
                        </template>
                    </v-list-item>

                </template>
            </v-list-item-group>
        </v-list>
        <div class="with-dynamic-validations">
            <div class="caption error--text">
                {{ errors[0] }}
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'CheckBox2',
        props: {
            options: {
                type: Array,
                default: () => []
            },
            errors: {
                type: Array,
                default: () => []
            },
            label: {
                type: String,
                dafault: ''
            }
        },
        methods: {
            toggled(toggle, active, item) {
                toggle()
                this.$emit('input', active || this.selected.length ? this.selected : [])
            }
        },
        data() {
            return {
                selected: []
            }
        }
    }
</script>

<style lang="scss" scoped>
    .flex__sub-component-CheckBox2 {

        .v-list-item, .v-card, .v-list-item__content {
            min-height: 77px !important;
        }

        .v-list-item__subtitle {
            font-size: 1.375rem !important;
        }

    }
</style>