import Vue from 'vue'

export const store = Vue.observable({
   isCurrentLive: null,
   occupancies: {
   },
   isLivedTogether: null,
   addressId:null,
   addressResource: null,
   addressWithOccupancy: null,
   occupancyResource:null,
   occupancyId: [],
   occupancyAttributes:{
    //    'residential-situation': '',
    //    'start-date': '',
    //    'end-date': '',
    //    'occupancy-situation': '',
   },
   occupancyRelationships: {
        // 'application' : '',
        // 'address': '',
        // 'people': '',
   },
   assetAttributes:{
        // 'asset-value': ''
   },
   assetRelationships: {
        // 'kinds': '',
        // 'address': '',
        // 'people': ''

   },
   expenseAttributes: {
        // 'expense-group': 'Property Investment',
        // 'payment-amount': '',
        // 'payment-frequency': ''
   },
   expenseRelationships: {
        // 'people': '',
        // 'kinds:': '',
        // 'assets': ''
   },
   addressRelationships: {
        // 'person': ''
   },
   liabilityAttributes: {
        // 'amount-financed': '',
        // 'amount-owing': '',
        
   },
   liabilitiesRelationships: {
        // 'people': '',
        // 'kinds': '',
        // 'assets': '',
        // 'expenses': ''
   },
   partsRelationships: {
        // 'kinds': ''
   },
   referenceAttributes: {
        // 'first-name': '',
        // 'last-name': '',
        // 'email': '',
        // 'phone': '',
        // 'relationship': ''
   },
   referenceRelationships: {
    //    'parent': '',
    //    'kinds': ''
   },
   referenceAdressAttributes : {

   },
   referenceAdressRelationships : {

   },
   person1: null,
   person2: null,
   possibleOwner: [],
   selectedOwner: null,
   userOccupancies: [],

   allAddresses: [],
   allAssets: [],
   allExpenses: [],
   allLiabilities: [],

   residentialSituation: null,
     
})

export const mutations = {
    setResidentialSituation(payload) {
        store.residentialSituation = payload
    },
    setAllAddresses(payload) {
        store.allAddresses= payload
    },
    setUserOccupancies(payload) {
        store.userOccupancies= payload
    },
    setAllAssets(payload) {
        store.allAssets= payload
    },
    setAllExpenses(payload) {
        store.allExpenses= payload
    },
    setAllLiabilities(payload) {
        store.allLiabilities= payload
    },
    setIsCurrentLive (payload){
        store.isCurrentLive= payload
    },
    setOccupancies(payload){
        store.occupancyAttributes['residential-situation'] = payload['residential-situation']  ? payload['residential-situation'] : store.occupancyAttributes['residential-situation'] 
        store.occupancyAttributes['start-date'] = payload['start-date'] ? payload['start-date'] : store.occupancyAttributes['start-date']
        store.occupancyAttributes['end-date'] = payload['end-date'] ? payload['end-date'] : store.occupancyAttributes['end-date']
        store.occupancyAttributes['occupancy-situation'] = payload['occupancy-situation'] ? payload['occupancy-situation'] : store.occupancyAttributes['occupancy-situation']
        // console.log('occupancy',store.occupancyAttributes)
    },
    setOccupancyRelationships (payload) {
        store.occupancyRelationships['application'] = payload['application'] ? payload['application'] : store.occupancyRelationships['application'] 
        store.occupancyRelationships['people'] = payload['people'] ? payload['people'] : store.occupancyRelationships['people']
    },
    setAssetRelationships (payload) {
        store.setAssetRelationships['kinds'] = payload['kinds'] ? payload['kinds'] : store.setAssetRelationships['kinds']
        store.setAssetRelationships['address'] = payload['address'] ? payload['address'] : store.setAssetRelationships['address']
        store.setAssetRelationships['people'] = payload['people'] ? payload['people'] : store.setAssetRelationships['people']
    },
    setAssetAttributes (payload) {
        store.assetAttributes['asset-value'] = payload['asset-value'] ? payload['asset-value'] : store.assetAttributes['asset-value']
    },
    setExpenseAttributes(payload) {
        store.expenseAttributes['expense-group'] = payload['expense-group'] ? payload['expense-group'] : store.expenseAttributes['expense-group']
        store.expenseAttributes['payment-amount'] = payload['payment-amount'] ? payload['payment-amount'] : store.expenseAttributes['payment-amount']
        store.expenseAttributes['payment-frequency'] = payload['payment-frequency'] ? payload['payment-frequency'] : store.expenseAttributes['payment-frequency']
    },
    setExpenseRelationships(payload) {
        store.expenseRelationships['kinds'] = payload['kinds'] ? payload['kinds'] : store.expenseRelationships['kinds']
        store.expenseRelationships['address'] = payload['address'] ? payload['address'] : store.expenseRelationships['address']
        store.expenseRelationships['assets'] = payload['assets'] ? payload['assets'] : store.expenseRelationships['assets']
    },
    // setAddressRelationships(payload){
    //     console.log('payloadd address relations',payload)
    //     store.addressRelationships['person'] = store.addressRelationships['person'] ? store.addressRelationships['person'] : payload['people']
    // },
    setLiabilityAttributes (payload) {
        store.liabilityAttributes['amount-financed'] = payload['amount-financed'] ? payload['amount-financed'] : store.liabilityAttributes['amount-financed']
        store.liabilityAttributes['amount-owing'] = payload['amount-owing'] ? payload['amount-owing'] : store.liabilityAttributes['amount-owing']
        store.liabilityAttributes['financial-institution'] = payload['financial-institution'] ? payload['financial-institution'] : store.liabilityAttributes['financial-institution']
    },
    setLiabilityRelationships (payload){
        store.liabilitiesRelationships['people'] = payload['people'] ? payload['people'] : store.liabilitiesRelationships['people']
        store.liabilitiesRelationships['kinds'] = payload['kinds'] ? payload['kinds'] : store.liabilitiesRelationships['kinds']
        store.liabilitiesRelationships['assets'] = payload['assets'] ? payload['assets'] : store.liabilitiesRelationships['assets']
        store.liabilitiesRelationships['expenses'] = payload['expenses'] ? payload['expenses'] : store.liabilitiesRelationships['expenses']
        // console.log('expenses relationships store',store.liabilitiesRelationships)
    },
    setPartsRelationships (payload) {
        store.partsRelationships['kinds'] = payload['kinds'] ? payload['kinds'] : store.partsRelationships['kinds']
    },
    setReferenceAttributes(payload){
        store.referenceAttributes['name'] = payload['name'] ? payload['name'] : store.referenceAttributes['name']
        store.referenceAttributes['email'] = payload['email'] ? payload['email'] : store.referenceAttributes['email']
        store.referenceAttributes['phone'] = payload['phone'] ? payload['phone'] : store.referenceAttributes['phone']
        store.referenceAttributes['relationship'] = payload['relationship'] ? payload['relationship'] : store.referenceAttributes['relationship']
    },
    setReferenceRelationships (payload) {
        store.referenceRelationships['parent'] = payload['parent'] ? payload['parent'] : store.referenceAttributes['parent']
        store.referenceRelationships['kinds'] = payload['kinds'] ? payload['kinds'] : store.referenceAttributes['kinds']
    },
    setReferenceAddressAttributes (payload) {
        store.referenceAdressAttributes['street-number'] = payload['street-number'] ? payload['street-number'] : store.referenceAdressAttributes['street-number']
        store.referenceAdressAttributes['street-name'] = payload['street-name'] ? payload['street-name'] : store.referenceAdressAttributes['street-name']
        store.referenceAdressAttributes['suburb'] = payload['suburb'] ? payload['suburb'] : store.referenceAdressAttributes['suburb']
        store.referenceAdressAttributes['state'] = payload['state'] ? payload['state'] : store.referenceAdressAttributes['state']
        store.referenceAdressAttributes['postcode'] = payload['postcode'] ? payload['postcode'] : store.referenceAdressAttributes['postcode']

        console.log('refers address', store.referenceAdressAttributes)
    },
    setReferenceAddressRelationships (payload) {
        store.referenceAdressRelationships['references'] = payload['references'] ? payload['references'] :  store.referenceAdressRelationships['references']
    },
    setIsLiveTogether(payload){
        store.isLivedTogether = payload
    },
    setAddressId (payload) {
        store.addressId = payload
    },
    setAddressResource (payload) {
        store.addressResource = payload
    },
    setAddressWithOccupancy (payload) {
        store.addressWithOccupancy = payload
    },
    setPerson1 (payload) {
        store.person1 = payload
    },
    setPerson2 (payload) {
        store.person2 = payload
    },
    setPossibleOwner (payload) {
        store.possibleOwner = payload
    },
    setSelectedOwner (payload) {
        store.selectedOwner = payload
        //console.log('set selected owner',store.selectedOwner)
    },
    
}