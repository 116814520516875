var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('FlexWrap',{on:{"resize":_vm.handleResize}},[_c('v-card',{staticClass:"light_gray_1 mb-6",attrs:{"elevation":"0","flat":""}},[_c('v-expansion-panels',{model:{value:(_vm.panels),callback:function ($$v) {_vm.panels=$$v},expression:"panels"}},[_c('v-expansion-panel',{staticClass:"pa-0"},[_c('v-expansion-panel-header',{staticClass:"py-0 px-2 ma-0",attrs:{"expand-icon":"mdi-menu-down"},scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c('v-icon',{staticClass:"panel-icon",attrs:{"color":"light_gray_2","size":"30"}},[_vm._v("mdi-menu-down")])]},proxy:true}])},[_vm._v(" "),_c('div',{staticClass:"body-2 panel-name font-weight-bold"},[_vm._v("\n                        "+_vm._s(_vm.label)+"\n                    ")])]),_vm._v(" "),_c('v-expansion-panel-content',{staticClass:"inner-content pa-0 ma-0"},[_c('v-card',{staticClass:"pa-2 ma-0",attrs:{"flat":"","width":"100%","elevation":"0"}},[_c('v-card-text',{staticClass:"light_gray_1 text-center pa-0 ma-0"},[_c('v-sheet',{staticClass:"light_gray_1 mx-auto",attrs:{"width":"100%","elevation":"0"}},[_c('v-data-table',{staticClass:"elevation-0 v-data-table-2",attrs:{"dense":"","headers":_vm.tableHeaders,"items":_vm.filteredIncomes,"hide-default-footer":"","mobile-breakpoint":0},scopedSlots:_vm._u([{key:"item.owner",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_vm._v("\n                                            "+_vm._s(_vm.displayOwner(index))+"\n                                        ")]}},{key:"item.income-situation",fn:function(ref){
var item = ref.item;
return [_vm._v("\n                                            "+_vm._s(_vm.displayData(item.attributes['income-situation']))+"\n                                        ")]}},{key:"item.income-type",fn:function(ref){
var item = ref.item;
return [_vm._v("\n                                            "+_vm._s(_vm.displayIncomeKind(item))+"\n                                        ")]}},{key:"item.start-date",fn:function(ref){
var item = ref.item;
return [_vm._v("\n                                            "+_vm._s(_vm.displayData(item.attributes['start-date'], 'date'))+"\n                                        ")]}},{key:"item.end-date",fn:function(ref){
var item = ref.item;
return [_vm._v("\n                                            "+_vm._s(_vm.displayData(item.attributes['end-date'], 'date'))+"\n                                        ")]}}],null,true)})],1)],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }