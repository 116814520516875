<template>
    <div :class="['foe-role-selector v-input v-text-field', {'d-none': hidden}]">
    <div class="v-input__control">
    <div class="v-input__slot">
    <div class="v-text-field__slot">

    <div class="">

        <label :class="['v-label theme--light v-label--active', {'error--text': errors.length}]">
            {{label}}
        </label>

        <v-chip-group v-model="selected" column multiple :max="max" @change="handleChange">
            <v-chip :filter="false" v-for="entity in data" :key="entity.id" 
                active-class="secondary"
                :class="[{'px-8': large}]"
                :large="large"
                color="light_gray_1"
                :value="entity.id">
                <span class="font-weight-bold">
                    {{ getFriendlyRoles(entity, parts) }}
                </span>&nbsp;
                {{ getEntityFriendlyName(entity) }}
            </v-chip>
        </v-chip-group>

        <div class="v-text-field__details">
            <div class="v-messages theme--light error--text" role="alert">
                <div class="v-messages__wrapper" v-if="errors.length">
                    <div class="v-messages__message">{{...errors}}</div>
                </div>
            </div>
        </div>

    </div>

    </div>
    </div>
    </div>
    </div>
</template>

<script>

    import resourceHelpers from '~/components/shared/resource-helpers'

    export default {
        mixins: [resourceHelpers],
        model: {
            prop: 'modelValue',
            event: 'change'
        },
        props: {
            modelValue: {
                type: Array,
                default: () => []
            },
            label: {
                type: String,
                default: ""
            },
            data: {
                type: Array,
                default: () => [],
            },
            parts: {
                type: Array,
                default: () => [],
            },
            errors: {
                type: Array,
                default: () => [],
            },
            hidden: {
                type: Boolean,
                default: false,
            },
            large: {
                type: Boolean,
                default: false,
            },
            max: {
                type: Number,
                default: undefined,
            },
        },
        data() {
            return {
                selected: []
            }
        },
        computed: {

        },
        watch: {
            modelValue(val) {
                if (val) {
                    this.selected = this.modelValue
                    return
                }
                this.selected = []
            }
        },
        methods: {
            handleChange(value){
                this.$emit('change', value)
            }
        }

    }
</script>

<style lang="scss">

    .foe-role-selector.v-input {

        .v-input__slot {
            background: transparent;

            &::before {
                display: none;
            }
        }

        .v-label {
            left: 0px;
            right: auto;
            position: absolute;
            transform: translateY(-32px) scale(1);
        }

        .v-slide-group__wrapper {

            .v-chip {
                // padding: 12px;

                .v-chip__content {
                    padding: 5px;
                    font-weight: 500;
                }
            }

            .secondary {
                background: var(--v-secondary-base) !important;
            }

        }

    }



</style>