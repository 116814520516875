/**
 * 
 * Define here required FOs 
 * 
 */
export default {
    incomes: {
        // Rental Income
        "12-21": ['assets'], // Required FO
        // Superannuation
        "12-22": ['assets'],
    },
    assets: {

    },
    liabilities: {
        // Credit Card
        "13-3": ['expenses'],
        // Fuel Card 
        "13-4": ['expenses'],
        // Line of Credit - Unsecured 
        "13-5": ['expenses'],
        // Overdraft
        "13-6": ['expenses'],
        // Store Card 13-7
        "13-7": ['expenses'],
        // Car Loan 
        "13-9": ['assets', 'expenses'],
        // Novated Lease
        "13-10": ['assets', 'expenses'],
        // Other Asset Loan
        "13-11": ['assets', 'expenses'],
        // Interest-Free (non-card) 13-13
        "13-13": ['expenses'],
        // Loan as Guarantor 13-14
        "13-14": ['expenses'],
        // Other Loan 13-15
        "13-15": ['expenses'],
        // Pay-Day Loan 13-16
        "13-16": ['expenses'],
        // Unsecured Personal Loan 13-17
        "13-17": ['expenses'],
        // Line of Credit - Secured 13-19
        "13-19": ['assets', 'expenses'],
        // Mortgage - Investment 13-20
        "13-20": ['assets', 'expenses'],
        // Mortgage - Owner Occupied 13-21
        "13-21": ['assets', 'expenses'],
        // HECS-HELP 13-23
        "13-23": ['expenses'],
        // Other Liability 13-24
        "13-24": ['expenses'],
        // Outstanding Taxation 13-25
        "13-25": ['expenses'],
        // Buy Now Pay Later 13-26
        "13-26": ['expenses'],
        
    },
    expenses: {
        // Mortgage - Owner Occupied 11-6
        "11-6": ['assets', 'liabilities'],
        // Own Outright 11-7
        "11-7": ['assets'],
        // Credit Card 11-11
        "11-11": ['liabilities'],
        // Fuel Card 11-12
        "11-12": ['liabilities'],
        // Line Of Credit - Unsecured 11-13
        "11-13": ['liabilities'],
        // Overdraft 11-14
        "11-14": ['liabilities'],
        // Store Card 11-15
        "11-15": ['liabilities'],
        // Car Loan 11-18
        "11-18": ['assets', 'liabilities'],
        // Novated Lease 11-20
        "11-20": ['assets', 'liabilities'],
        // Other Asset Loan 11-21
        "11-21": ['assets', 'liabilities'],
        // Interest-Free Finance 11-23
        "11-23": ['liabilities'],
        // Loan as Guarantor 11-24
        "11-24": ['assets', 'liabilities'],
        // Other Loan 11-25
        "11-25": ['liabilities'],
        // Pay-Day Loan 11-26
        "11-26": ['liabilities'],
        // Unsecured Personal Loan 11-27
        "11-27": ['liabilities'],
        // Line of Credit - Secured 11-29
        "11-29": ['liabilities'],
        // Mortgage - Investment 11-30
        "11-30": ['assets', 'liabilities'],

        // HECS-Help 11-32
        "11-32": ['liabilities'],
        // Other Expense 11-33
        "11-33": ['liabilities'],
        // Outstanding Taxation 11-34
        "11-34": ['liabilities'],
        // Buy Now Pay Later 11-35
        "11-35": ['liabilities'],
    }

}