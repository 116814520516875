<template>
  <v-container>
    <ValidationObserver ref="observer" tag="form">
      <v-row
        class="m-0"
      >
        <v-col cols="py-0">
          <v-row>
            <v-col class="py-0 px-8" sm="12" md="4">
              <ValidationProvider
                key="'medicare-expiry'"
                v-slot="{ errors }" 
                :name="labels['medicare-expiry']"
                :rules="validation_rules.attributes['medicare-expiry']"
                vid="'medicare-expiry'"
              >
                  <FlexDatePicker3
                    tile
                    type="text"
                    :label="labels['medicare-expiry']"
                    color="secondary"
                    :errors="errors"
                    v-model="form['medicare-expiry']"
                  >
                </FlexDatePicker3>
              </ValidationProvider>
            </v-col>
            <v-col class="py-0 px-8" sm="12" md="4">
              <ValidationProvider
                key="'medicare-number'"
                v-slot="{ errors }"
                :name="labels['medicare-number']"
                vid="'medicare-number'"
                :rules="validation_rules.attributes['medicare-number']"
              >
              <v-text-field
                  tile
                  type="text"
                  :label="labels['medicare-number']"
                  color="secondary"
                  :error-messages="errors"
                  v-model="form['medicare-number']"
                  required
                >
                </v-text-field>
              </ValidationProvider>
            </v-col>
            <v-col class="py-0 px-8" sm="12" md="4">
              <ValidationProvider
                key="'middle-initial'"
                v-slot="{ errors }"
                :name="labels['middle-initial']"
                vid="'middle-initial'"
              >
                <v-text-field
                  tile
                  type="text"
                  :label="labels['middle-initial']"
                  color="secondary"
                  :error-messages="errors"
                  :value="form['middle-initial']"
                  required
                  disabled
                >
                </v-text-field>
              </ValidationProvider>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row>
          <v-col class="py-0 px-8" sm="12" md="4">
            <ValidationProvider
              key="'medicare-position-on-card'"
              v-slot="{ errors }"
              :name="labels['medicare-position-on-card']"
              vid="'medicare-position-on-card'"
              :rules="validation_rules.attributes['medicare-position-on-card']"
            >
              <v-text-field
                tile
                type="number"
                :label="labels['medicare-position-on-card']"
                color="secondary"
                :error-messages="errors"
                v-model="form['medicare-position-on-card']"
              >
              </v-text-field>
            </ValidationProvider>
          </v-col>
          <v-col class="py-0 px-8" sm="12" md="4">
            <ValidationProvider
              key="medicare-colour-of-card"
              v-slot="{ errors }"
              :name="labels['medicare-colour-of-card']"
              vid="medicare-colour-of-card"
              :rules="getRules('medicare-colour-of-card')"
            >
              <v-select
                  :items="getOptions('medicare-colour-of-card')"
                  :label="labels['medicare-colour-of-card']"
                  :error-messages="errors"
                  v-model="form['medicare-colour-of-card']"
                  color="secondary"/>
            </ValidationProvider>
          </v-col>
          <v-col class="py-0 px-8" sm="12" md="4">
            <ValidationProvider
              key="lender-wisr-bank-statement-preference"
              v-slot="{ errors }"
              :name="labels['lender-wisr-bank-statement-preference']"
              vid="lender-wisr-bank-statement-preference"
              :rules="getRules('lender-wisr-bank-statement-preference')"
            >
              <v-select
                  :items="getOptions('lender-wisr-bank-statement-preference')"
                  :label="labels['lender-wisr-bank-statement-preference']"
                  :error-messages="errors"
                  v-model="form['lender-wisr-bank-statement-preference']"
                  color="secondary"/>
            </ValidationProvider>
          </v-col>
      </v-row>
      <v-row>
        <v-col class="py-0 px-8">
            <v-alert dense outlined type="error" v-if="errors.length > 0">
                <div v-for="(msg, index) in errors" v-html="msg" :key="index"></div>
            </v-alert>
        </v-col>
      </v-row>
    </ValidationObserver>

  </v-container>
</template>

<script>
import { mapActions, mapMutations, mapGetters } from "vuex";
import subscriptions from "~/components/shared/subscriptions";
import shared from "~/components/v1/submission/LenderPepperEntities1/__shared";
import {
  store,
  actions,
} from "~/components/v1/submission/LenderPepperEntities1/__store";
import ButtonGroup3 from "~/components/base/ButtonGroup3";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import FlexDatePicker3 from '~/components/base/FlexDatePicker3';
import rules from '~/components/pages/Calculator/rules';
import veeValidate from '~/components/shared/validation'
import formHelpers from '~/components/shared/form-helpers'
import fieldOptions from '~/components/shared/field-options'

export default {
  components: { ButtonGroup3, FlexDatePicker3 },
  mixins: [formHelpers, subscriptions, shared, veeValidate],
  props: {
    data: {
      type: Object,
      default: null,
    },
    applicantType: {
      type: String,
      default: "primary",
    },
    isPrivacyConsentExists: {
      type: Boolean,
      default: false,
    },
    term: {
      type: Object,
      default: () => {},
    },
    updater: undefined,
  },
  data() {
    return {
      form: {
        "medicare-expiry": "",
        "medicare-number": "",
        "middle-name": "",
        "middle-initial": "",
        "medicare-position-on-card": "",
        "medicare-colour-of-card": "",
        "lender-wisr-bank-statement-preference": "",
      },
      tabSelection: "nodifi",
      resources: {
        person: {
          id: null,
          type: "people",
          attributes: {
            "medicare-expiry": "",
            "medicare-number": "",
            "middle-name": "",
            "middle-initial": "",
            "medicare-position-on-card": "",
            "medicare-colour-of-card": "",
            "lender-wisr-bank-statement-preference": "",
          },
        },
      },
      labels: {
        "medicare-expiry": "Medicare Expriry Date ",
        "medicare-number": "Medicare Card Number",
        "medicare-colour-of-card": "Colour of Card",
        "middle-initial": "Middle Name Initial",
        "medicare-position-on-card": "Medicare Reference Number",
        "lender-wisr-bank-statement-preference": "Bank Statement Preference",
      },
      validation_rules: {
        attributes: {
          "medicare-expiry": "required|date_format:Y/m/d|after:now",
          "medicare-number": "required|numeric|digits:10",
          "middle-initial": "string",
          "medicare-position-on-card": "required|numeric|max_value:99",
          "medicare-colour-of-card": `required|in:${fieldOptions["medicare-colour-of-card"]}`,
          "lender-wisr-bank-statement-preference": `required|in:credit-sense,illion,pdf`,
        },
      },
      options: {
        "medicare-position-on-card": [
          { text: "De Facto", value: "De Facto" },
          { text: "Spouse", value: "Spouse" },
        ],
        "lender-wisr-bank-statement-preference": [
          { text: "Credit-Sense", value: "credit-sense" },
          { text: "Illion", value: "illion" },
          { text: "PDF", value: "pdf" },
        ],
      },
      errors: [],
    };
  },

  computed: {
    vb() {
      return this.$vuetify.breakpoint;
    },
    privacyConsent(){
      return this.form.privacy_consent
    }
  },
  mounted() {
    this.updateResourceFields();
  },
  methods: {
    ...mapActions("resource-actions", ["getPerson"]),
    selectTab(tab) {
      this.tabSelection = tab;
    },
    isBtnGroup(key) {
      let keys = ["lender-pepper-forsee-repayment"];

      return keys.includes(key);
    },
    async validate() {

      const valid = await this.$refs.observer.validate();

      let allValid = this.errors.length == 0 && valid

      if (allValid) {

        this.$emit("submit", {
          person: {
            ...this.resources.person,
            attributes: this.form,
          },
        });
      }
    },
    reset() {
      this.$refs.observer.reset();
    },
    async updateResourceFields() {
      // console.log('updateResourceFields')

      this.$emit("loading", true);

      const { data } = this;

      if (data) {
        let hasValue = false;
        let res = await this.getPerson(data.id);

        this.resources.person = this.filterResourceFields(
          this.resources.person,
          res.data.data
        );

        Object.keys(this.resources.person.attributes).forEach((key) => {
          this.form[key] = this.resources.person.attributes[key];
        });

        if (this.resources.person.attributes['middle-name']) {
          // console.log(this.resources.person.attributes['middle-name'])
          this.form['middle-initial'] = this.resources.person.attributes['middle-name'].split('').shift().toUpperCase()
        }

        this.$emit("loading", false);
      } else {
        this.$emit("loading", false);
      }
    },
  },
  watch: {
    updater(val) {
      // console.log(val)

      if (val) {
        this.updateResourceFields();
      }
    },
    privacyConsent(val) {
        if(val) {
            this.errors = []
        }
    },
    isPrivacyConsentExists: {
      handler(value) {
        this.form.privacy_consent = value;
      },
      immediate: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.privacy-consent-container {
  ul li,
  p {
    font-weight: 400 !important;
    font-size: 14px !important;
    line-height: 15px !important;
  }

  ul {
    margin-bottom: 16px;
  }

  p {
    margin-top: 8px;
  }
}
.privacy-consent {
  ::v-deep .v-slide-group__content {
    justify-content: flex-end;
  }
}
</style>
