<template>
  <v-app>

      <!-- 

          Layout Description: 
          - No Logo   
          - No Footer

       -->

    <v-main class="light_gray_1">
        <nuxt />
    </v-main>

  </v-app>
</template>

<script>

    import { mapMutations, mapActions, mapGetters, mapState } from 'vuex';
    import meta from '~/layouts/meta'

    export default {
        mixins: [
            meta
        ],
        name: 'BasicLayout4',
        data () {
            return {

            }
        },
        computed: {
            ...mapGetters({
                getActiveTheme: 'themes/getActiveTheme',
                getGroupSlug: 'slug/getGroupSlug'
            }),
        }
    }

</script>

<style scoped lang="scss">
</style>
