/**
 * 
 *  All spaces related logic, configurations, add it here
 * 
 */

import { mapState, mapMutations, mapActions, mapGetters } from 'vuex';

export default {
    props: {

    },
    computed: {
        ...mapGetters({
            getSpacesObj: 'flows/getSpacesObj',
        }),

        /**
         * Helpers for Left/Right Sidebar Drawers.
         * @returns Boolean
         * 
         *  this.blockSettings : computed property from  shared/blocks.js
         */
        showLeftSideBarToggleButton(){

            // check if sidebar exists
            if( this.getSpacesObj.LeftSideBar ) {

                // check if showLeftSideBarToggleButton exists
                if( typeof this.blockSettings.showLeftSideBarToggleButton != "undefined" ){ 

                    if(this.blockSettings.showLeftSideBarToggleButton == 1 || this.blockSettings.showLeftSideBarToggleButton == true ) {
                            return true
                    }
                    return false

                }
                return false
            }

            return false

        },
        showLeftSideBarMinimiseButton(){

            // check if sidebar exists
            if( this.getSpacesObj.LeftSideBar ) {

                // check if showLeftSideBarMinimiseButton exists
                if( typeof this.blockSettings.showLeftSideBarMinimiseButton != "undefined" ){ 

                    if(this.blockSettings.showLeftSideBarMinimiseButton == 1 || this.blockSettings.showLeftSideBarMinimiseButton == true ) {
                            return true
                    }
                    return false

                }
                return false
            }

            return false

        },
        showRightSideBarToggleButton(){

            // check if sidebar exists
            if( this.getSpacesObj.RightSideBar ) {

                // check if showRightSideBarToggleButton exists
                if( typeof this.blockSettings.showRightSideBarToggleButton != "undefined" ){ 

                    if(this.blockSettings.showRightSideBarToggleButton == 1 || this.blockSettings.showRightSideBarToggleButton == true ) {
                            return true
                    }
                    return false

                }
                return false
            }

            return false

        },
        showRightSideBarMinimiseButton(){

            // check if sidebar exists
            if( this.getSpacesObj.RightSideBar ) {

                // check if showRightSideBarMinimiseButton exists
                if( typeof this.blockSettings.showRightSideBarMinimiseButton != "undefined" ){ 

                    if(this.blockSettings.showRightSideBarMinimiseButton == 1 || this.blockSettings.showRightSideBarMinimiseButton == true ) {
                            return true
                    }
                    return false

                }
                return false
            }

            return false

        },

    },
    methods: {
        ...mapActions({
            saveDataOnly: 'flows/saveDataOnly'
        }),

    }
}