<template>

    <div class="px-4">

        <InfoBox1>
            <template v-slot:text>
                <div>
                    Not sure? Click <button class="text-decoration-underline" @click="handleClick">here</button> to learn more.
                </div>
            </template>
        </InfoBox1>

        <v-dialog
            v-model="dialog"
            persistent
            content-class="modal-CreditHistoryInfo1"
            scrollable
            width="500">

            <v-card>

                <v-card-title class="px-6 pt-4">
                    <v-spacer/>
                    <v-btn icon @click="dialog = false">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-card-title>

                <v-card-text :class="`px-8 py-0 pb-8 avatar ${vb.smAndDown ? ' avatar-sm' : ''}`">
                    <img src="~/assets/images/jane.png" :height="vb.smAndDown ? 76: 90"/>
                </v-card-text>

                <v-card-text class="px-8 pt-2 pb-8 body-1">

                    <v-alert class="px-6 body-2">
                        I know credit history can be confusing, so here is a general guide on how to measure your credit history.
                    </v-alert>

                    <h3 class="py-3">
                        <v-icon color="secondary" size="28">mdi-emoticon-happy-outline</v-icon>
                        <span>Excellent</span>
                    </h3>
                    <p>No infringements. I have always paid all my loans on time, and all of my bills on time and when they are due.</p>

                    <h3 class="py-3">
                        <v-icon color="secondary" size="28">mdi-emoticon-neutral-outline</v-icon>
                        <span>Average</span>
                    </h3>
                    <p>Paid infringements. I’m not sure, there may be a time i missed a couple of payments, but i have always caught them up or paid them off.</p>

                    <h3 class="py-3">
                        <v-icon color="secondary" size="28">mdi-emoticon-sad-outline</v-icon>
                        <span>Poor</span>
                    </h3>
                    <p>Currently have unpaid infringements. I have made some mistakes in the past and have some debts/payments which have defaulted and may be still due.</p>

                </v-card-text>

            </v-card>
        </v-dialog>
                
    </div>


</template>

<script>
    import InfoBox1 from '~/components/base/InfoBox1'
    
    export default {
        mixins: [],
        components: {
            InfoBox1
        },
        data(){
            return {
                dialog: false
            }
        },
        mounted() {

        },
        computed: {
            vb() {
                return this.$vuetify.breakpoint
            },
        },
        methods: {
            handleClick(){
                this.dialog = true
            }
        }
    }
</script>

<style lang="scss">

    .modal-CreditHistoryInfo1 {
        overflow: visible;
        border-radius: 36px !important;

        & > .v-card {
            border-radius: 36px !important;
        }

        .v-alert {
            border-radius: 20px !important;
        }

        .avatar {
            text-align: center;
            margin-top: -100px;
            overflow: hidden  !important;
            overflow-y: hidden  !important;

            &.avatar-sm {

                img {
                    border-radius: 20px !important;
                }
            }

            img {
                border-radius: 36px !important;
                margin: 0 auto;
            }
        }

    }

</style>
