<template>
    <v-dialog
        v-model="dialog"
        width="900"
        scrollable
        persistent>

        <template v-slot:activator="{ on, attrs }">
            <v-btn                 
                depressed large
                v-bind="attrs"
                v-on="on"
                color="red"
                class="secondary px-5 mx-1">
                {{ label }}
            </v-btn>
        </template>

        <v-card elevation="0" flat>
            <v-card-title>
                <v-spacer />
                <v-btn icon @click="dialog = false" >
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-card-title>

            <v-card-text class="py-0 px-4 px-md-8">
                <v-stepper v-model="e1" alt-labels>
                    <v-stepper-items>
                        
                        <v-stepper-content step="1" class="pa-0">
                            <v-card
                                flat elevation="0">

                                <v-card-title class="px-3 pb-6">
                                    <div class="text-h4 text-capitalize">{{ title }}</div>
                                </v-card-title>

                                <v-card-text>
                                    <v-row>
                                        <v-col>
                                            <ValidationObserver
                                                ref="observer1"
                                                tag="form">

                                                <div 
                                                    v-for="(group, gkey) in fields[lenderType][0].labels"
                                                    :key="`d${gkey}`"
                                                    class="flex-form">                                                    

                                                    <div
                                                        v-for="(field, fkey) in group"
                                                        :key="fkey"   
                                                        class="flex-field">

                                                        <ValidationProvider
                                                            v-slot="{ errors }"
                                                            :name="field.text"
                                                            rules="required"
                                                            :vid="fkey">

                                                            <v-select
                                                                v-if="fkey === 'loan-term'"
                                                                :items="['12', '24', '36', '48', '60', '72', '84']"
                                                                v-model="resources['bid-lenders'].attributes[fkey]"
                                                                :error-messages="errors"
                                                                :label="field.text"
                                                                :type="field.type">
                                                            </v-select>

                                                            <v-text-field
                                                                v-else-if="fkey === 'loan-fees-amount' || fkey === 'total-loan-amount'"
                                                                v-model="resources['bid-lenders'].attributes[fkey]"
                                                                :hint="resources.bids.attributes[fkey]"
                                                                :error-messages="errors"
                                                                readonly
                                                                :label="field.text"
                                                                :type="field.type">
                                                            </v-text-field>

                                                            <v-text-field
                                                                v-else
                                                                v-model="resources['bid-lenders'].attributes[fkey]"
                                                                :error-messages="errors"
                                                                :label="field.text"
                                                                :type="field.type">
                                                            </v-text-field>

                                                        </ValidationProvider>

                                                    </div>

                                                </div>

                                            </ValidationObserver>
                                        </v-col>
                                    </v-row>
                                </v-card-text>

                            </v-card>                       
                        </v-stepper-content>

                        <v-stepper-content step="2" class="pa-0">

                            <v-card flat elevation="0">

                                <v-card-title class="px-3 pb-6">
                                    <div class="text-h4 text-capitalize">{{ title }}</div>
                                </v-card-title>
                                <v-card-text>
                                    
                                    <v-row>
                                        <v-col>
                                            <ValidationObserver
                                                ref="observer2"
                                                tag="form">

                                                <div class="flex-form">
                                                    <div
                                                        v-for="(field, fkey) in fields[lenderType][1].labels"
                                                        :key="fkey" 
                                                        class="flex-field">

                                                        <ValidationProvider
                                                            v-slot="{ errors }"
                                                            :name="field"
                                                            rules="required"
                                                            :vid="fkey">
                                    
                                                            <v-textarea
                                                                v-if="fkey === 'alternative-summary'"
                                                                v-model="resources.bids.attributes[fkey]"
                                                                :error-messages="errors"
                                                                :label="field">
                                                            </v-textarea>    

                                                            <v-textarea
                                                                v-else
                                                                v-model="resources.bids.attributes[fkey]"
                                                                :error-messages="errors"
                                                                auto-grow
                                                                row-height="30"
                                                                :label="field">
                                                            </v-textarea>

                                                        </ValidationProvider>

                                                    </div>
                                                </div>

                                            </ValidationObserver>
                                        </v-col>
                                    </v-row>
                                </v-card-text>
                            </v-card>

                            
                        </v-stepper-content>

                    </v-stepper-items>

                </v-stepper>
                
            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions class="pa-4 pa-md-6 flex-wrap controls">                                
                <v-btn
                    v-if="e1 === 2"
                    outlined
                    color="secondary"
                    large
                    style="min-width:140px;"
                    @click="e1 = 1">
                    Back
                </v-btn>
                <v-btn
                    v-else
                    outlined
                    color="secondary"
                    large
                    style="min-width:140px;"
                    @click="cancel">
                    Back
                </v-btn>

                <v-spacer />
                <ProgressIndicator :current="e1" steps="2" />   
                <v-spacer />

                <v-btn
                    v-if="e1 === 1"
                    color="secondary"
                    depressed
                    large
                    style="min-width:140px;"
                    @click="nextSlide">
                    Next
                </v-btn>
                <v-btn
                    v-else
                    color="secondary"
                    depressed
                    large
                    style="min-width:140px;"
                    @click="save">
                    Save
                </v-btn>
            </v-card-actions>
        </v-card>

    </v-dialog>
</template>

<script>

    import subscriptions from '~/components/shared/subscriptions'
    import { isEmpty } from 'lodash'
    import ProgressIndicator from '~/components/base/ProgressIndicator'

    export default {
        name: 'BidCustomLenders',
        components: {
            ProgressIndicator,
        },
        mixins: [ subscriptions ],

        props: {
            label: {
                type: String,
                default: 'Submit'
            },
            title: {
                type: String,
                default: 'Submit'
            },
            lenderType: {
                type: String,
                default: 'primary'
            },
            bidData: {
                type: Object,
                default: () => {}
            },
            bidLenderData: {
                type: Object,
                default: () => {}
            }
        },

        methods: {
            async save() {
                if ( this.e1 === 2 ) {
                    const valid = await this.$refs.observer2.validate()
                    if ( valid ) {

                        this.resources['bid-lenders'].attributes['lender-type'] = this.lenderType.charAt(0).toUpperCase() + this.lenderType.slice(1)
                        
                        if ( !this.resources['bids'].relationships['application'].data?.id ) {
                            this.resources['bids'].relationships['application'].data.id = this.appId
                        }
                        
                        if ( this.bidData?.id )
                            this.resources['bid-lenders'].relationships.bid.data.id = this.bidData.id

                        this.$emit('confirm-save', this.resources)
                        this.e1 = 1
                        this.dialog = false
                    }
                }
            },

            cancel() {
                this.e1 = 1
                this.dialog = false
            },

            async nextSlide() {                
                if ( this.e1 === 1 ) {
                    const valid = await this.$refs.observer1.validate()
                    if ( valid ) {
                        this.e1 = 2
                    }
                }
            }
        },

        watch: {
            resources: {
                handler(val) {
                    this.resources['bid-lenders'].attributes['loan-fees-amount'] = +this.resources['bid-lenders'].attributes['establishment-fee'] + +this.resources['bid-lenders'].attributes['risk-fee'] + +this.resources['bid-lenders'].attributes['ppsr-fee']
                    this.resources['bid-lenders'].attributes['total-loan-amount'] = +this.resources['bid-lenders'].attributes['loan-fees-amount'] + +this.resources['bid-lenders'].attributes['required-loan-amount'] 
                },
                deep: true
            },
            dialog: {
                handler(val) {
                    if ( val && !isEmpty(this.bidData) ) {
                        this.resources['bids'] = Object.assign({}, this.bidData)
                    }                    
                    if ( val && !isEmpty(this.bidLenderData) ) {         
                        this.resources['bid-lenders'] = Object.assign({}, this.bidLenderData)
                    }
                },
                deep: true
            }
        },

        data() {
            return {
                dialog: false,
                e1: 1,
                steps: {
                    primary: [
                        { text: 'Recommendation', icon: 'mdi-card-bulleted-settings', step: 1 },
                        { text: 'Rationale for Recommendation', icon: 'mdi-card-bulleted-settings-outline', step: 2 }
                    ],
                    alternative: [
                        { text: 'Alternative Recommendation', icon: 'mdi-card-bulleted-settings', step: 1 },
                        { text: 'Rationale for Alternative', icon: 'mdi-card-bulleted-settings-outline', step: 2 }
                    ]
                },
                resources: {
                    bids: {
                        "type": "bids",
                        "attributes": {
                            "guid": '',
                            "recommendation-cost": '',
                            "recommendation-priorities": '',
                            "recommendation-needs-objectives": '',
                            "recommendation-circumstances": '',
                            "recommendation-other": '',
                            "alternative-summary": '',
                            "client-lender-preference": '',
                            "why-this-lender": '',
                            "client-budgeted-monthly-repayment": '',
                            "client-additional-payments": '',
                            "client-settlement": '',
                            "customer-priorities-ratings": "[Low Early Payout Fees,Lowest Repayments,Fast Settlement,Loan Flexibility,Maximum Pre-Approval Limit]",
                            "other-priorities": '',
                            "referral-partner-name": '',
                            "referral-fee-estimate": '',
                            "client-found-asset": '',
                            "asset-preference-type": ''
                        },
                        "relationships": {
                            "application": {
                                "data": {
                                    "type": "applications",
                                    "id": null
                                }
                            }
                        }
                    },
                    'bid-lenders': {
                        "type": "bid-lenders",
                        "attributes": {
                            "lender-name": '',
                            "loan-term": '',
                            "required-loan-amount": '',
                            "balloon-amount": '',
                            "rate-range-from": '',
                            "rate-range-to": '',
                            "payment-range-from": '',
                            "payment-range-to": '',
                            "brokerage-commission-from": '',
                            "brokerage-commission-to": '',
                            "origination-fee": '',
                            "establishment-fee": '',
                            "risk-fee": '',
                            "ppsr-fee": '',
                            "monthly-account-fee": '',
                            "loan-fees-amount": '',
                            "total-loan-amount": '',
                            "total-cost-range-from": '',
                            "total-cost-range-to": '',
                            "lender-type": "", // lender type
                            "lender-logo-class": "", // logo-class
                        },
                        "relationships": {
                            "bid": {
                                "data": {
                                    "type": "bids",
                                    "id": null
                                }
                            }
                        }
                    },
                },
                fields: {
                    primary: [
                        {
                            title: 'Recommendation',
                            labels: [
                                {
                                    'lender-name': { text: 'Lender Name', type: 'text' },
                                    'loan-term': { text: 'Loan Term (Months)', type: 'select' },
                                    'required-loan-amount': { text: 'Required Loan Amount', type: 'number' },
                                    'balloon-amount': { text: 'Balloon', type: 'number' },
                                    'rate-range-from': { text: 'Rate Range From', type: 'number' },
                                    'rate-range-to': { text: 'Rate Range To', type: 'number' },
                                    'payment-range-from': { text: 'Payment Range From', type: 'number' },
                                    'payment-range-to': { text: 'Payment Range To', type: 'number' },
                                    'brokerage-commission-from': { text: 'Brokerage Commission From', type: 'number' },
                                    'brokerage-commission-to': { text: 'Brokerage Commission To', type: 'number' },
                                },
                                {
                                    'origination-fee': { text: 'Origination Fee', type: 'number' },
                                },
                                {
                                    'establishment-fee': { text: 'Establishment Fee', type: 'number' },
                                    'risk-fee': { text: 'Risk Fee', type: 'number' },
                                    'ppsr-fee': { text: 'PPSR Fee', type: 'number' },
                                    'monthly-account-fee': { text: 'Monthly Account Fee', type: 'texnumbert' },
                                },
                                {
                                    'loan-fees-amount': { text: 'Total Loan Fees', type: 'number' },
                                },
                                {
                                    'total-loan-amount': { text: 'Amount Financed', type: 'number' },
                                },
                                {
                                    'total-cost-range-from': { text: 'Total Cost Range From', type: 'number' },
                                    'total-cost-range-to': { text: 'Total Cost Range To', type: 'number' },
                                    // 'lender-type': 'Primary', // lender type
                                    // 'lender-logo-class': '', // logo-class
                                }
                            ],
                        },
                        {
                            title: 'Rationale for Recommendation',
                            labels: {
                                'recommendation-cost': 'Cost',
                                'recommendation-priorities': 'Your Stated Priorities',
                                'recommendation-needs-objectives': 'Your Stated Needs and Objectives',
                                'recommendation-circumstances': 'Your Personal Circumstances',
                                'recommendation-other': 'Other'
                            }
                        }
                    ],
                    alternative: [
                        {
                            title: 'Alternative Recommendation',
                            labels: [
                                {
                                    'lender-name': { text: 'Lender Name', type: 'text' },
                                    'loan-term': { text: 'Loan Term (Months)', type: 'select' },
                                    'required-loan-amount': { text: 'Required Loan Amount', type: 'number' },
                                    'balloon-amount': { text: 'Balloon', type: 'number' },
                                    'rate-range-from': { text: 'Rate Range From', type: 'number' },
                                    'rate-range-to': { text: 'Rate Range To', type: 'number' },
                                    'payment-range-from': { text: 'Payment Range From', type: 'number' },
                                    'payment-range-to': { text: 'Payment Range To', type: 'number' },
                                    'brokerage-commission-from': { text: 'Brokerage Commission From', type: 'number' },
                                    'brokerage-commission-to': { text: 'Brokerage Commission To', type: 'number' },
                                },
                                {
                                    'origination-fee': { text: 'Origination Fee', type: 'number' },
                                },
                                {
                                    'establishment-fee': { text: 'Establishment Fee', type: 'number' },
                                    'risk-fee': { text: 'Risk Fee', type: 'number' },
                                    'ppsr-fee': { text: 'PPSR Fee', type: 'number' },
                                    'monthly-account-fee': { text: 'Monthly Account Fee', type: 'texnumbert' },
                                },
                                {
                                    'loan-fees-amount': { text: 'Total Loan Fees', type: 'number' },
                                },
                                {
                                    'total-loan-amount': { text: 'Amount Financed', type: 'number' },
                                },
                                {
                                    'total-cost-range-from': { text: 'Total Cost Range From', type: 'number' },
                                    'total-cost-range-to': { text: 'Total Cost Range To', type: 'number' },
                                    // 'lender-type': 'Primary', // lender type
                                    // 'lender-logo-class': '', // logo-class
                                }
                            ],
                        },
                        {
                            title: 'Rationale for Alternative',
                            labels: {
                                'alternative-summary': 'Summary'
                            }
                        }
                    ]
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
    .v-stepper {
        box-shadow: none;

        .v-stepper__header {
            box-shadow: none;
        }
    }
    .v-stepper .v-stepper__step--active .v-stepper__step__step {
        color: red !important;
    }
</style>