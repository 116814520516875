<template>
    <v-card class="d-flex justify-center" tile flat :disabled="isLoading" max-height="1676" width="100%">
        <v-card width="100%" tile flat class="mx-auto pb-4" :class="vb.mdAndUp ? 'px-8' : 'px-2'" max-width="1600">
            <FinanceDetails title="Finance Details" :addresses="addresses"   :actionType="action" @inputAddress="inputAddress"  :address="address" :step="currentStep" @step="nextStep" @back="backStep" />
            <ReferenceAddress title="Agent Address" @inputAddress="inputAddress" :addresses="addresses"  @updateTable="updateTable" :actionType="action"  :address="address" :step="currentStep" @step="nextStep" @back="backStep" />
            <LandLordReference title="Landlord Reference Details" :addresses="addresses"  @updateTable="updateTable" :actionType="action"  :address="address" :step="currentStep" @step="nextStep" @back="backStep" />
            <Mortgage title="Is there a mortgage on the property?" :addresses="addresses" @inputAddress="inputAddress"  @updateTable="updateTable" :actionType="action"  :address="address" :step="currentStep" @step="nextStep" @back="backStep" />
            <AccommodationExpense title="How much is your accommodation expense?" :addresses="addresses"  @updateTable="updateTable" :actionType="action"  :address="address" :step="currentStep" @step="nextStep" @back="backStep" />
            <AssetValue title="Approximate Property Value" :addresses="addresses"  @updateTable="updateTable" :actionType="action"  :address="address" :step="currentStep" @step="nextStep" @back="backStep" />
            <RentalSituation title="Describe your rental situation" :addresses="addresses"  @updateTable="updateTable" :actionType="action"  :address="address" :step="currentStep" @step="nextStep" @back="backStep" />
            <PropertyOwner title="Who owns this property?" :addresses="addresses"  @updateTable="updateTable" :actionType="action"  :address="address" :step="currentStep" @step="nextStep" @back="backStep" />
            <YesNo :title="IsSpouseLivedSamePeriodQuestion" :occupancySituation="occupancySituation" :addresses="addresses"  @updateTable="updateTable" :actionType="action"  :address="address" :step="currentStep" @step="nextStep" @back="backStep" />
            <AddressForm :addresses="addresses" :title="title" @updateTable="updateTable" :actionType="action"  :address="address" :step="currentStep" @step="nextStep" @back="backStep" />
            <PropertyDescription title="What was the residential situation?" :occupancySituation="occupancySituation"  :index="index" @changeAllDate="changeDatesAllOccupancy"  @inputAddress="inputAddress" @updateTable="updateTable" :actionType="action" :address="address"  @input="input" :step="currentStep" @step="nextStep" @back="backStep"  />
            <DateMoveIn title="When did you move in?" :endDate="endDate" @updateTable="updateTable" @inputAddress="inputAddress" :actionType="action" :address="address" @save="save" :occupancySituation="occupancySituation" @input="input"  :step="currentStep" @step="nextStep" @back="backStep"  />

            <div class="px-4 pb-4 d-flex">
                <div class="title" >
                    Please list at least 3 years of your address history!
                </div>
            </div>

            <v-card-text class="pt-0" :class="vb.mdAndUp ? 'pa-10' : ''">
                <v-container class="pa-0">
                    <v-data-table
                    :loading="false"
                    loading-text="Please wait..."
                    :items="sortedAddresses"
                    hide-default-footer
                    :mobile-breakpoint="0"
                    hide-default-header
                    outlined
                    class="v-data-table mx-auto">
                    <template v-slot:item="{ item, index }">
                        
                        <!-- Desktop -->

                        <v-card v-if="vb.mdAndUp" outlined tile flat class="ma-2 rounded-xl" :class="vb.mdAndUp ? 'pa-2' : ''">

                            <div class="d-flex justify-space-between align-center py-2 px-4 flex-table-row">

                                <div class="d-flex flex-column justify-start pr-3" style="width:auto">
                                    <div class="rounded-xl pa-2 text-center font-size-16"
                                        :style="{
                                            width: (vb.mdAndUp ? '100%' : ''),
                                            minWidth: '100px',
                                            backgroundColor: (item.occupancy.attributes['occupancy-situation'] == 'Current' ? hexToRGBA(getActiveTheme.colors.accent, 0.1) : 'var(--v-light_gray_3-base)')
                                        }"
                                        >
                                        {{ item.occupancy.attributes['occupancy-situation'] }}
                                    </div>
                                </div>

                                <div v-if="vb.mdAndUp" class="d-flex flex-column justify-start pr-3" style="width:35%">
                                    <div  class="font-size-16 cursor-pointer" >
                                        <!-- @click="changeAddress(item,index)" -->
                                        {{getFullAddress(item.address)}}
                                        <!-- <v-icon  color="accent" v-if="hover">mdi-pencil</v-icon> -->
                                    </div>
                                </div>

                                <div v-if="vb.mdAndUp" class="d-flex d-lg-flex flex-column justify-start" style="width:20%">
                                    <div  class="font-size-16 cursor-pointer" >
                                        <!-- @click="ChangePropertyDescription(item,index)" -->
                                        {{item.occupancy.attributes['residential-situation']}}
                                        <!-- <v-icon color="accent" v-if="hover">mdi-pencil</v-icon> -->
                                    </div>
                                </div>

                                <div v-if="vb.mdAndUp" class="d-flex flex-column justify-center " style="width:20%">
                                    <div  class="font-size-16 cursor-pointer" >
                                        <!-- @click="changeDate(item,index)" -->
                                        {{ convertDate(item.occupancy.attributes['start-date']) }} - {{ convertDate(item.occupancy.attributes['end-date']) }}
                                        <!-- <v-icon color="accent" v-if="hover">mdi-pencil</v-icon> -->
                                    </div>
                                </div>

                                <div class="d-flex justify-end" style="width:10%">
                                    
                                    <v-btn
                                        fab                    
                                        depressed
                                        max-width="40"
                                        max-height="40"
                                        @click="changeAddress(item,index)"
                                        :style="`background-color: ${hexToRGBA(getActiveTheme.colors.light_gray_3, 1)}`">
                                        <v-icon size="18">
                                            mdi-pencil
                                        </v-icon>
                                    </v-btn>

                                    <ConfirmModal 
                                        async
                                        :title="`Delete Address`"
                                        :processing="deleting"
                                        @confirm="removeAddress(item,index)">
                                        <template v-slot:activator="{on}">
                                            <v-btn
                                                depressed
                                                max-width="40"
                                                :disabled="(sortedAddresses.length - 1) != index"
                                                max-height="40"
                                                fab :ripple="false"
                                                :style="`background-color: ${hexToRGBA(getActiveTheme.colors.light_gray_3, 1)}`"
                                                class="ml-2"
                                                @click.stop="on"> 
                                                <v-icon size="18">mdi-delete</v-icon>
                                            </v-btn>
                                        </template>
                                    </ConfirmModal>

                                </div>
                            </div>
                        </v-card>

                        <!-- Mobile -->
                        <v-card v-else flat tile outlined class="rounded-lg mt-4 pa-2">

                            <v-card-text class="d-flexflex-column">
                                <div  class="body-2 font-weight-medium cursor-pointer" >
                                    <!-- @click="changeAddress(item,index)" -->
                                    {{getFullAddress(item.address)}}
                                    <!-- <v-icon  color="accent" v-if="hover">mdi-pencil</v-icon> -->
                                </div>
                                <div  class="body-2 cursor-pointer" >
                                    <!-- @click="ChangePropertyDescription(item,index)" -->
                                    {{item.occupancy.attributes['residential-situation']}}
                                    <!-- <v-icon color="accent" v-if="hover">mdi-pencil</v-icon> -->
                                </div>
                                <div  class="body-2 cursor-pointer" >
                                    <!-- @click="changeDate(item,index)" -->
                                    {{ convertDate(item.occupancy.attributes['start-date']) }} - {{ convertDate(item.occupancy.attributes['end-date']) }}
                                    <!-- <v-icon color="accent" v-if="hover">mdi-pencil</v-icon> -->
                                </div>
                            </v-card-text>

                             <v-card-actions>
                                 <v-spacer />

                                <div
                                class="rounded-xl pa-2 text-center mx-2 body-1"
                                :style="`background-color: ${ item.occupancy.attributes['occupancy-situation'] == 'Current' ?  hexToRGBA(getActiveTheme.colors.accent, 0.1) : 'var(--v-light_gray_3-base)'}; min-width: 100px;`">
                                    {{ item.occupancy.attributes['occupancy-situation'] }}
                                </div>

                                <v-btn
                                    fab                    
                                    depressed
                                    max-width="40"
                                    max-height="40"
                                    @click.stop="changeAddress(item,index)"
                                    :style="`background-color: ${hexToRGBA(getActiveTheme.colors.light_gray_3, 1)}`"
                                    class="mr-2">
                                    <v-icon size="18">
                                        mdi-pencil
                                    </v-icon>
                                </v-btn>

                                <ConfirmModal 
                                    async
                                    :title="`Delete Address`"
                                    :processing="deleting"
                                    @confirm="removeAddress(item,index)">
                                    <template v-slot:activator="{on}">

                                        <v-btn 
                                            fab
                                            depressed
                                            max-width="40"
                                            max-height="40"
                                            :style="`background-color: ${hexToRGBA(getActiveTheme.colors.light_gray_3, 1)}`"
                                            :ripple="false"
                                            :disabled="(sortedAddresses.length - 1) != index"
                                            @click.stop="on"> 
                                            <v-icon size="18">mdi-delete</v-icon>
                                        </v-btn>

                                    </template>
                                </ConfirmModal>

                             </v-card-actions>

                        </v-card>
                    </template>
                    </v-data-table>
                    <div class="pa-2 mt-n2 white"></div>
                    <div class="pb-4"></div>
                    <div class="d-flex justify-end">
                        <v-btn :disabled="isLoading" depressed large color="secondary" @click="showAddNewAddress">
                            <div class="px-4">
                                Add an Address
                            </div>
                        </v-btn>
                    </div>
                    <v-col cols="12" v-if="errorMessages.length > 0">
                        <v-alert dense outlined type="error" class="ma-0">
                            <div v-for="(error, index) in errorMessages" :key="index" v-html="error"></div>
                        </v-alert>
                    </v-col>
                </v-container> 
            
            </v-card-text>
        </v-card>
    </v-card>
</template>
<script>

import subscriptions from '~/components/shared/subscriptions'
import shared from './__shared'
import { mapState, mapActions, mapGetters } from 'vuex'
import {store,mutations} from './__store'
import resourceHelpers from '~/components/shared/resource-helpers'
import ConfirmModal from '~/components/base/ConfirmModal2'

export default {
    components: {
        FinanceDetails : () => import('./_FinanceDetails'),
        ReferenceAddress : () => import('./_ReferenceAddress'),
        AddressForm : () => import('./_AdressFormModal'),
        LandLordReference : () => import('./_LandlordReferenceDetails'),
        PropertyDescription : () => import('./_PropertyDescriptionModal'),
        DateMoveIn : () => import('./_DateMoveIn'),
        YesNo : () => import('./_YesOrNoModal'),
        PropertyOwner : () => import('./_PropertyOwner') ,
        RentalSituation : () => import('./_RentalSituationModal'),
        AssetValue : () => import('./_AssetValue'),
        AccommodationExpense : () => import('./_AccommodationExpense'),
        Mortgage : () => import('./_Mortgage'),
        ConfirmModal
    },
    mixins:[subscriptions,resourceHelpers,shared],
    computed:{
        ...mapState('flows',['appData','calculation']),
        ...mapState({
            appId: state => state.flows.appId
        }),
        peopleId () {
            // for testing
                if(this.$route?.params && this.$route?.params.record == 'people') {
                    return this.$route?.params?.record_id
                }
                return null
            // return "4"
        },
        fullName () {
            let name = "Person"

            if(this.person) {
                if(this.person.attributes['first-name'] != null || this.person.attributes['last-name']){
                    name = this.person.attributes['first-name'] + " " +  this.person.attributes['last-name']
                }
            }

           return name
        },
        sortedAddresses () {
            let item = []
            let arr = this.addresses
            arr.forEach((data) => {
                if(data?.occupancy?.attributes['occupancy-situation'] == 'Current'){
                    item.unshift(data)
                } else {
                    item.push(data)
                }
            })
            return item
        }
            
    },
    watch:{
        userOccupancies(val) {
            if(val.length > 0) {

                this.addresses = []

                val.forEach(occ => {
                    this.addresses.push({
                        address: this.filterAddress(occ),
                        occupancy: occ,
                    })
                })
            }
        },
        'addresses': {
            handler (val) {
                if(val.length != 0 ){
                    let find = val.find(data => data.occupancy.attributes['occupancy-situation'] == 'Current')
                    if(find){
                        this.occupancySituation = "Previous"
                        this.title = `What is ${this.fullName}'s previous address?`
                    } else {
                        this.occupancySituation = "Current"
                        this.title = `What is ${this.fullName}'s current address?`
                    }
                } else {
                    this.occupancySituation = "Current"
                    this.title = `What is ${this.fullName}'s current address?`
                }
                // this.getLatestEndDate(val)
                this.validate()
                
            }
        },
        sortedAddresses (val) {
            return val
        }
    },
    methods: {
        ...mapActions('resource-actions',[
            'getPeople',
            'getAddresses',
            'getAddress',
            'createOccupancy',
            'deleteAddress',
            'deleteAsset',
            'deleteExpense',
            'deleteLiability',
            'getOccupancy',
            'updateOccupancy',
            'deleteOccupancy',
            'getPerson',
            'getApplication'
        ]),
        async pull () {

            this.isLoading = true
            let data = await this.getPerson(this.peopleId)

            // fetch resources
            await this.updateData()

            //console.log(this.userOccupancies)

            // console.log('occupancisess people',data)
            this.person = data.data.data
            await this.getPeopleDetails(this.person)

            // get primary applicant
            this.primaryApplicant = await this.getPrimaryApplicant()

            let items = data.data.data.relationships.occupancies.data
            this.occupancyCounter = items.length

            // for (const data of items) {
            //     await this.processResponse(data)
            // }

            if(this.addresses.length == 0){
                this.occupancySituation = "Current"
                this.title = `What is ${this.fullName} 's current address?`
            }

            this.getLatestEndDate(this.addresses)
            this.isLoading = false

        },
        filterAddress(occ) {
            let address = {}

            if(this.allAddresses.length > 0) {
                this.allAddresses.forEach(add => {
                    if(add.id == occ?.relationships?.address?.data?.id) {
                        address = add
                    }
                })
            }

            return address
        },
        async getPrimaryApplicant() {

            let res = await this.getApplication({
                id: this.appId, 
                params: {
                    include: 'people,parts'
                }
            })

            this.people = res.data.included.filter( person => {
                return person.type === 'people'
            })

            let priority_part = res.data.included.find( pp => {
                return pp.type === 'parts' && pp.attributes.priority === 1
            })

            let person = res.data.included.find(p => {
                return p.id === priority_part.relationships.child.data.id
            })

            return person
        },
        async push () {
            await this.validate()
            if(this.errorMessages.length > 0){
                 return {valid:false, status:'pristine', data:{}}
            }
            return {valid:true, status:'valid', data:{}}
        },
        convertDate(date) {
            return this.$options.filters.dateFormatFE_3(date) ? this.$options.filters.dateFormatFE_3(date) : 'now'
        },
        async processResponse(data){ 
            let occupancy = await this.getOccupancy(data.id)
            let address = await this.getAddress(occupancy.data.data.relationships.address.data ? occupancy.data.data.relationships.address.data.id : '' )
            this.addresses.push({address: address.data.data,occupancy: occupancy.data.data})
            if(this.addresses.length == this.occupancyCounter){
                this.validate()
            }
        },
        getLatestEndDate (item) {
            let dateList = []
            item.forEach(data => {
                dateList.push(data.occupancy.attributes['start-date'])
            })
            
            return dateList[dateList.length - 1]
        },
        showAddNewAddress () {
            this.address = {}
            this.action = 'add'
            this.currentStep = 'address-form'
            
            if(this.resources.occupancies.hasOwnProperty('id')){
                delete this.resources.occupancies.id
            }
            let date = this.getLatestEndDate(this.addresses)
            this.endDate = date
            // console.log('datesss',this.endDate)
        },
        inputAddress(data){
            // console.log('data address',data)
            if(data.occupancy.attributes['occupancy-situation'] == 'Current'){
                this.addresses.unshift({address: data.address,occupancy: data.occupancy})
            } else {
                this.addresses.push({address: data.address,occupancy: data.occupancy})
            }
            
        },
        async getPeopleDetails(person){
            if(this.calculation['application-type'].search('Non Borrowing Spouse') != -1 ||  this.calculation['application-type'].search('Joint')  != -1 ) {
                if(person.id == this.calculation['primary-applicant-id']){
                    mutations.setPerson1(person)
                    let person2 = await this.getPerson(this.appData.relationships.people.data[1].id)
                    mutations.setPerson2(person2.data.data)
                } else {
                    mutations.setPerson2(person)
                    let person1 = await this.getPerson(this.appData.relationships.people.data[0].id)
                    mutations.setPerson1(person1.data.data)
                }
            } else {
                mutations.setPerson1(person)
            }
        },

        addAddress () {
            this.addresses.push({address: this.createdAddress,occupancy: this.createdOccupancy})
        },
        
        updateAddress () {
            this.addresses.splice(this.key,1,{address: this.createdAddress,occupancy: this.createdOccupancy})
        },
        updateTable (data,index,endDate) {
           this.addresses.splice(this.key,1,{address: data.address, occupancy: data.occupancy})
        //    this.changeDatesAllOccupancy(index,endDate)
        },
        nextStep(data){
            // console.log('current step',data)
            if(data == 'is-spouse-live-same-period' && this.primaryApplicant && this.primaryApplicant.id != this.peopleId) {
                this.currentStep = 'property-owner'
            } else {
                this.currentStep = data
            }
        },
        backStep(data){
            //console.log('current step',data)
            if(data == 'is-spouse-live-same-period' && this.primaryApplicant && this.primaryApplicant.id != this.peopleId && this) {
                this.currentStep = 'date-move'
            } else {
                this.currentStep = data
            }
        },
        input(data){
            this.occupancies[data.model] = data.value
        },
        changeDate(address,key){
            // console.log('adress',address)
            this.action = 'edit'
            this.currentStep = 'date-move'
            this.address = address
            this.occupancySituation = address.occupancy.attributes['occupancy-situation']
            this.key = key
        },
        ChangePropertyDescription (address,key) {
            this.action = 'edit'
            this.currentStep = 3
            this.address = address
            this.key = key
        },
        changeAddress (address,key) {
            this.currentStep = 'address-form'
            this.address = address
            this.occupancySituation = address.occupancy.attributes['occupancy-situation']
            this.action = 'edit'
            this.key = key
            this.index = key
            this.endDate = address.occupancy.attributes['end-date']
            if(key != this.sortedAddresses.length - 1  && this.sortedAddresses.length > 1){
                this.nextToEdit = this.sortedAddresses[key + 1]
            } else {
                this.nextToEdit = null
            }
            // console.log('addrss',address)
            
        },
        async changeDatesAllOccupancy (endDate) {
            this.isLoading = true
            if(this.nextToEdit != null){
                let occResource = this.nextToEdit.occupancy
                occResource.attributes['end-date'] = endDate
                occResource.relationships.address = this.nextToEdit.address.relationships.address
                delete occResource.relationships.validations
                // await this.updateAddress(this.addresses[index + 1])
                await this.updateOccupancy(occResource)
                this.endDate = null
            }
            this.isLoading = false
            
            // let occResource = this.addresses[index + 1].occupancy
            // console.log('occ REsource',occResource)
            // delete occResource.relationships.validations
            // occResource.id = this.addresses[index].occupancy.id
            // occResource.attributes['end-date'] = this.addresses[index].occupancy.attributes['start-date']
            // await this.updateOccupancy(occResource)
        },
        async updateAllDates(index){
            // let occResource = this.addresses[index].occupancy
            // console.log('occ REsource',occResource)
            // delete occResource.relationships.validations
            // occResource.id = this.addresses[index].occupancy.id
            // occResource.attributes['end-date'] = this.addresses[index - 1].occupancy.attributes['start-date']
            // await this.updateOccupancy(occResource)
        },
        async save() {
            // this.resources.occupancies.attributes['occupancy-situation'] = this.occupancies['occupancy-situation']
            // this.resources.occupancies.attributes['residential-situation'] = this.occupancies['residential-situation']
            // this.resources.occupancies.attributes['start-date'] = this.occupancies['start-date']
            // this.resources.occupancies.attributes['end-date'] = this.occupancies['end-date']
            // this.resources.occupancies.relationships.application.data.id = this.appId
            // this.resources.occupancies.relationships.address.data.id = store.addressId
            // this.resources.occupancies.relationships.person.data.id = this.peopleId
            // this.resources.occupancies.relationships.address.data.id = store.addressId
            // let add = await this.createOccupancy(this.resources.occupancies)
            // this.createdOccupancy = add.data.data
            // this.addAddress()
            // this.validate()
        },
        async removeAddress(address,key){
            this.deleting = true
            this.isLoading = true

            let res = await this.deleteOccupancy(address.occupancy.id)

            if(res.status === 204) {

                 if(address.address.relationships.assets.data.length > 0) {

                    let asset = null 
                    let expense = null 
                    let liability = null 

                    // find related assets
                    address.address.relationships.assets.data.forEach(as1 => {
                        if(this.allAssets.length > 0) {
                            this.allAssets.forEach(ass => {
                                if(ass.id == as1.id) asset = ass
                            })
                        }
                    })

                    if(asset) {

                        // find related expense
                        if(asset.relationships.expenses.data.length > 0) {
                            asset.relationships.expenses.data.forEach(exp1 => {
                                if(this.allExpenses.length > 0) {
                                    this.allExpenses.forEach(exp2 => {
                                        if(exp2.id == exp1.id) expense = exp2
                                    })
                                }
                            })
                        }

                        // find related liability
                        if(asset.relationships.liabilities.data.length > 0) {
                            asset.relationships.liabilities.data.forEach(l1 => {
                                if(this.allLiabilities.length > 0) {
                                    this.allLiabilities.forEach(l2 => {
                                        if(l2.id == l1.id) liability = l2
                                    })
                                }
                            })
                        }

                    }

                    if(asset) {
                        await this.deleteAsset(asset.id)
                    }

                    if(expense) {
                        await this.deleteExpense(expense.id)
                    }

                    if(liability) {
                        await this.deleteLiability(liability.id)
                    }

                }
                
                await this.updateData()
            }

            // // await this.deleteAddress(address.address.id)
            this.addresses = this.sortedAddresses
            this.sortedAddresses.splice(key,1)
            this.addresses.splice(key,1)
            this.validate()

            this.isLoading = false
            this.deleting = false
        },
        reset() {
            this.address.address.attributes['street-number'] = ''
            this.address.address.attributes['street-name'] = ''
            this.address.address.attributes['suburb'] = ''
            this.address.address.attributes['state'] = ''
            this.address.address.attributes['postcode'] = ''
            this.address.occupancy.attributes['occupancy-situation'] = ""
            this.address.occupancy.attributes['residential-situation'] = ""
            this.address.occupancy.attributes['start-date'] = ""
            this.address.occupancy.attributes['end-date'] = ""
        },
        async validate(){
            
            const filteredOccupancies = this.addresses

                    let dates = []

                    this.errorMessages = []

                    filteredOccupancies.forEach(data => {

                        dates.push({
                            "primary": data.occupancy.attributes['occupancy-situation'],
                            "start-date": this.parseDate(data.occupancy.attributes['start-date']),
                            "end-date": this.parseDate(data.occupancy.attributes['end-date']),
                        })

                    })
                    let totalDays = 0;
                    let prevObject;
                    // Sort date by start-date old to new
                    dates.sort((a,b) => {
                        return a['start-date'] - b['start-date'];
                    });
                        /**
                         *  1. Applicant must have 1 (and only 1) current address.
                         */

                    if(!this.hasOnlyOneCurrent(dates)){
                        this.errorMessages.push('People must have 1 (and only 1) current address.')
                    }

                    if (!this.currentIsMostRecent(dates)) {
                            this.errorMessages.push("Current address must be the most recent.");
                    }
                    /**
                         * 3. Further more validation
                         */

                        for(let i=0; i < dates.length; i++) {
                                
                            let date = dates[i];
                            // check if primary address
                                    
                            if (date['primary'] == 'Current') {
                                
                                //is current (& most recent) address (no input for['end-date']), we auto-set this to today (needed for days at address calculation)
                                
                                date['end-date'] = new Date();
                
                            } else {
                                
                                // is not the current (primary) address, needs both dates
                                
                                if (date['end-date'] == null  ) {
                                    
                                    // fail validation
                                    
                                    this.errorMessages.push("Please complete your <strong>End Dates</strong> and <strong>Start Dates</strong>.");

                                    break;	
                                }
                                
                            }
                            
                            // check to see that this address starts the day of (or day after) the last dates end date (can't allow gaps between dates)
                            
                            let overlappingDays = 0;
                            
                            if (prevObject) {
                                
                                let days = this.getDays(prevObject['end-date'], date['start-date']);
                                
                                
                                if (days == null) {
                                    
                                    
                                    // fail validation
                                    

                                    this.errorMessages.push("Please complete your <strong>End Dates</strong> and <strong>Start Dates</strong>.");

                                    break;	
                                    
                                } else if (days > 1) {
                                    
                                    // fail validation
                                    

                                    this.errorMessages.push("There are gaps in your address history. Please check your <strong>End Dates</strong> and <strong>Start Dates</strong>.");

                                    break;	
                                    
                                } else if (days < 0) {
                                    
                                    // fail validation
                                 

                                    this.errorMessages.push("Dates between addresses can't overlap. Please check your <strong>Move-in Dates</strong> and <strong>Move-out Dates</strong>.");


                                    break;		
                                    
                                } else if (days == 0) {
                                    
                                    // previous object end date is same as this object start date - don't count the day twice when getting total days
                                    
                                    overlappingDays = 1;
                                    
                                    /**
                                     * NOTE: Allowed to overlap 1 day
                                     * 
                                     * uncomment the code below if overlap must be 0
                                     *  
                                     */

                                    // fail validation
                                    // requirements[id].no_dates_overlap = 0; 

                                    // this.errorMessages[id] = "Dates between dates can't overlap. Please check your <strong>Move-in Dates</strong> and <strong>Move-out Dates</strong>";

                                    // break;

                                }
                                
                            }

                            
                            // get days
                            
                            let days = this.getDays(date['start-date'], date['end-date']);
                            
                            // per-address validation
                            
                            if (days != null) {
                            
                                if (days < 0) {
                                    
                                    // fail validation
        
                                    this.errorMessages.push("Dates must be <strong>Chronological</strong>.");

                                    break;	
                                    
                                } else {
                                    
                                    days -= overlappingDays; // remove any overlapping days before adding to total
                                    
                                    totalDays += days;
                                    
                                }
                                
                            }
                            
                            //set this as previous object for next iteration
                            
                            prevObject = dates[i];
                            
                            // console.log(date.primary+' : '+date['start-date']+' - '+date['end-date']+'. days : '+days);
                        }
                        /**
                         * 4. Count 3 year history validation
                         * 
                         */
                        if (totalDays < (365*3)  ) {
                                
                            // fail validation
                            this.errorMessages.push("Applicant must have <strong>3 Years or More</strong> address history.");     
                        }

        },
        hasOnlyOneCurrent(dates){
            let counter = []
            dates.forEach(data => {
                if(data.primary == 'Current') {
                    counter.push(data)
                }        
            })
            if(counter.length == 1){
                return true
            }
            return false
        },
        daydiff(first, second) {
                return Math.round((second-first)/(1000*60*60*24));
        },
        getDays(first, second) {
            if ((first != null) && (second != null)) {
                return this.daydiff(first, second)
            } else {
                return null;
            }
        },
        currentIsMostRecent(dates) {

            if (dates[dates.length-1] && dates[dates.length-1].primary == 'Current') {
                return true;
            } else {
                return false;
            }
            
        },
        parseDate(str) {
            if (str) {
                return new Date(str);
            } else {
                return null;
            }
        },
    },
    data () {
        return {
            deleting: false,

            primaryApplicant: null,
            people: null,

            isLoading: false,
            currentStep: null,
            nextToEdit : null,
            addresses: [],
            occupancies: { 
            },
            title: '',          
            endDate: null,
            person: null,
            occupancySituation: 'Current',
            errorMessages: [],
            occupancyCounter: 0,
            key:'',
            createdOccupancy: {},
            createdAddress: {},
            address: {},
            action:'',
            isCurrent: '',
            resources: {
                    people: {
                        type: 'people',
                        attributes: {}
                    },
                    businesses: {
                        type: 'businesses',
                        attributes: {}
                    },
                    addresses: {
                        type: 'addresses',
                        attributes: {
                            "street-number": null,
                            "street-name": null,
                            "street-type": null,
                            "suburb": null,
                            "state": null,
                            "postcode": null
                        },
                        relationships: {
                            application: {
                                data: { type: "applications", id: null } 
                            }
                        }
                    },
                    occupancies: {
                        type: 'occupancies',
                        attributes: {
                            "occupancy-situation": null,
                            "residential-situation": null,
                            "start-date": null,
                            "end-date": null
                        },
                        relationships: {
                            application: {
                                data: { type: "applications", id:  null} 
                            },
                            address: {
                                data: {
                                    type: "addresses",
                                    id: null
                                }
                            },
                            person: {
                                data: {
                                    type: "people",
                                    id: null
                                }
                            }
                        }
                    },
                },
            index: null
        }
    }
}
</script>
<style lang="scss">

</style>