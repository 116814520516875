<template>
    <v-dialog
        v-model="dialog"
        persistent
        scrollable
        width="844">

        <v-card flat tile :class="vb.mdAndUp ? 'pa-8' : 'pa-2'" :disabled="processing">
            <v-progress-linear color="secondary" indeterminate stream absolute top v-if="processing"></v-progress-linear>

            <v-card-title class="pa-2 ma-0" :class="{'mt-n4': vb.mdAndUp}">
                <v-spacer />
                <v-icon @click="closeModal" size="20">
                    mdi-close
                </v-icon>
            </v-card-title>

            <v-card-text :class="vb.mdAndUp ? 'px-16' : 'px-2'">
                 <v-stepper v-model="stepper" alt-labels>
                    <v-stepper-items>
                        <v-stepper-content v-for="(step, index) in steps" :key="index" class="pa-0" :step="index">

                            <template v-if="step.children && step.children.length > 0">
                                <div class="text-center mb-5" :class="vb.mdAndUp ? 'title' : 'title-2 font-weight-bold'">
                                    Liability Type
                                </div>
                                <v-row
                                    :justify="step.children.length <= 3 ? 'center' : 'start'" 
                                    :style="step.children.length <= 3 && vb.mdAndUp ? 'width: 55%;' : 'width: 100%;'"
                                    align="center" class="rounded-lg light_gray_1 pa-2 ma-2 mx-auto">
                                    <v-col
                                        v-for="child in step.children" :key="child.name"
                                        cols="12" class="pa-2" :md="step.children.length <= 3 ? '12' : '6'">
                                        <!-- <v-card
                                            flat
                                            tile
                                            outlined
                                            :ripple="false"
                                            :width="vb.mdAndUp ? 223 : ''"
                                            :height="vb.mdAndUp ? 157 : ''"
                                            @click="nextChild(child)"
                                            class="pa-4 rounded-xl" :class="{'pt-16': vb.mdAndUp}">
                                                <div class="py-auto my-auto body-1">
                                                    {{ child.name }}
                                                </div>
                                        </v-card> -->
                                        <v-card
                                            flat
                                            height="100%" 
                                            @click="nextChild(child)"
                                            rounded="lg"
                                            class="d-flex pa-4 align-center justify-center">
                                            <div class="flex-options body-2 font-weight-medium">
                                                {{ child.name }}
                                            </div>
                                            <v-spacer></v-spacer>
                                            <v-btn color="secondary" :width="86" small depressed>
                                                Select
                                            </v-btn>
                                        </v-card>
                                    </v-col>
                                </v-row>
                            </template>

                            <ValidationObserver v-else :ref="`observer${index+1}`">
                                
                                <div v-if="step.toString().includes('role-selector')" class="text-center" :class="vb.mdAndUp ? 'title' : 'title-2 font-weight-bold'">Who owns this liability?</div>
                                <div v-else class="text-center" :class="vb.mdAndUp ? 'title' : 'title-2 font-weight-bold'">Finance Details</div>
                                
                                <div v-for="(field, key) in step" :key="key">
                                    <v-row class="justify-center align-center">
                                        <v-col 
                                            class="my-0 py-0"
                                            v-for="(f, key) in field" 
                                            :key="key"
                                            cols="12"
                                            :md="getFieldColWidth(f, field.length)"
                                        >
                                            <template v-if="f == 'role-selector'">
                                                <ValidationProvider 
                                                    v-if="isVisible(f)"
                                                    :hidden="isHidden(f)"
                                                    v-slot="{ errors }" 
                                                    name="Role" 
                                                    rules="required">
                                                    
                                                    <RoleSelector
                                                        v-model="preSelectRoles"
                                                        :parts="entityParts"
                                                        :errors="errors"
                                                        :data="filterEntities" />
                                                </ValidationProvider>
                                            </template>

                                            <template v-else>
                                                <ValidationProvider 
                                                    v-if="isVisible(f)"
                                                    :hidden="isHidden(f)"
                                                    v-slot="{ errors }" 
                                                    :name="labels[f]" 
                                                    :rules="getRules(f)">

                                                    <ButtonGroup1 
                                                        v-if="isBtnGroup(f)"
                                                        v-model="resource[resourcesFieldChecker(f)].attributes[f]"
                                                        :error-messages="errors"
                                                        :options="['Yes', 'No']"
                                                        :label="labels[f]" />

                                                    <v-select
                                                        v-else-if="isFieldType('picklist', f)"
                                                        :items="getOptions(f)"
                                                        :label="labels[f]"
                                                        :error-messages="errors"
                                                        v-model="resource[resourcesFieldChecker(f)].attributes[f]"
                                                        color="secondary">
                                                    </v-select>

                                                    <v-textarea 
                                                        v-else-if="isFieldType('textarea', f)"
                                                        :label="labels[f]"
                                                        rows="10"
                                                        :error-messages="errors"
                                                        v-model="resource[resourcesFieldChecker(f)].attributes[f]"
                                                        color="secondary">
                                                    </v-textarea>

                                                    <template v-else>
                                                        <div v-if="f == '__spacer'">
                                                        </div>
                                                        <NodifiMoney
                                                            v-else-if="isFieldType('money', f)"
                                                            v-model="resource[resourcesFieldChecker(f)].attributes[f]"
                                                            :label="labels[f]"
                                                            :errors="errors" />
                                                        <v-text-field
                                                            v-else
                                                            v-model="resource[resourcesFieldChecker(f)].attributes[f]"
                                                            :label="labels[f]"
                                                            :error-messages="errors">
                                                        </v-text-field>
                                                    </template>

                                                </ValidationProvider>
                                            </template>
                                            
                                        </v-col>
                                    </v-row>
                                </div>
                            </ValidationObserver>

                        </v-stepper-content>
                    </v-stepper-items>
                 </v-stepper>
            </v-card-text>
            <v-card-actions class="justify-center">
                <v-btn
                    large
                    min-width="125" 
                    max-height="50"
                    color="secondary"
                    class="mx-1"
                    @click="back"
                    outlined> Back
                </v-btn>
                <v-btn 
                    v-if="hideNext"
                    large
                    min-width="125" 
                    max-height="50"
                    class="mx-1"
                    color="secondary" 
                    @click="next" 
                    depressed> Next 
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
import shared from '~/components/v1/LiabilityEditor1/__shared'
import { actions } from '~/components/v1/LiabilityEditor1/__store'
import formHelpers from '~/components/shared/form-helpers'
import ResourceHelpers from '~/components/shared/resource-helpers'
import validation from '~/components/shared/validation'
import fieldOptions from '~/components/shared/field-options'
import RoleSelector from '~/components/v1/LiabilityEditor1/_LiabilityEditor1RoleSelector'
import ButtonGroup1 from '~/components/v1/LiabilityEditor1/_ButtonSelection'
import { mapActions } from 'vuex'

export default {
    mixins: [ validation, formHelpers, shared, ResourceHelpers ],
    components: { RoleSelector, ButtonGroup1 },
    props: {
        dialog: {
            type: Boolean,
            default: false
        },
        fieldsData: {
            type: Object,
            default: false
        }
    },
    data() {
        return {
            processing: false,
            visible: {
                "other-institution": false,
                "__spacer": false,
            },
            hidden: {},
            resource: {
                'liabilities': {
                    type: 'liabilities',
                    attributes: {
                        "amount-financed": null,
                        "amount-owing": null,
                        "credit-limit": null,
                        "description-other": null,
                        "financial-institution": null,
                        "other-institution": null,
                        "to-be-paid-out": null,
                        "__spacer": null
                    }
                },
                'expenses': {
                    type: 'expenses',
                    attributes: {
                        "payment-amount": null,
                        "payment-frequency": null
                    }
                }
            },
            labels: {
                "amount-financed": "Amount Financed",
                "amount-owing": "Amount Owing",
                "payment-amount": "Payment Amount",
                "payment-frequency": "Payment Frequency",
                "credit-limit": "Credit Limit",
                "description-other": "Other Description",
                "financial-institution": "Financial Institution",
                "liability-description": "Liability Description",
                "other-institution": "Other Institution",
                "to-be-paid-out": "Is this to be paid out before the new loan starts?"
            },
            validation_rules: {
                attributes: {
                    "__spacer": "",
                    "amount-financed": "required|between:0,99999999.99",
                    "amount-owing": "required|between:0,99999999.99",
                    "credit-limit": "required|between:0,99999999.99",
                    "description-other": "required|string|max:32768",
                    "payment-amount": "required|between:0,99999999.99",
                    "payment-frequency": 'required|in:Weekly,Fortnightly,Monthly,Annual',
                    "financial-institution": `required|in:${fieldOptions["financial-institution"]}`,
                    "liability-description": "required|string",
                    "other-institution": "",
                    "to-be-paid-out": "required|string|in:Yes,No"
                }
            }
        }
    },
    computed: {
        hideNext() {
            if(this.currentStep?.children && this.currentStep.children.length > 0) return false
            else return true
        },
        financialInstitution(){
            return this.resource.liabilities.attributes['financial-institution']
        },
    },
    async mounted() {
        await this.initializeFields()
    },
    methods: {
        ...mapActions('resource-actions', [
            'createLiability',
            'updateLiability',
            'createExpense',
            'updateExpense',
        ]),
        async nextChild(child) {
            await actions.nextStep(child)
            if(child.children.length === 0) {
                await this.getLiabilityFieldLayout(child)
               
            }
        },
        async save() {
            this.processing = true

            // save expenses
            let resExpenses = null
            let payloadExpense = await this.getPayloads('expenses')

            if(this.resource.expenses?.id) {
                payloadExpense.id = this.resource.expenses.id 
                resExpenses = await this.updateExpense(payloadExpense)
            } else {
                resExpenses = await this.createExpense(payloadExpense)
            }

            await actions.updateResources(resExpenses.data.data)

            // save liability
            let payloadLiabilities = this.getPayloads('liabilities')

            payloadLiabilities.relationships.expenses = {
                "data": [
                    {
                        "type": "expenses",
                        "id": resExpenses.data.data.id
                    }
                ]
            }

            let res = null
            if(this.resource.liabilities?.id) {
                payloadLiabilities.id = this.resource.liabilities.id 
                res = await this.updateLiability(payloadLiabilities)
            } else {
                res = await this.createLiability(payloadLiabilities)
            }

            if(res.status === 201) {
                actions.updateResources(res.data.data)
                this.updateEntitiesAndParts()
                this.$emit('save-liability', res.data.data)
                this.closeModal()
            } else if(res.status === 200) {
                actions.updateResources(res.data.data)
                this.updateEntitiesAndParts()
                this.$emit('update-liability', res.data.data)
                this.closeModal()
            } else {
                console.log("error....")
            }

            this.filterLiabilitiesData(this.selectedEntity)
        },
        async next() {
            // find observer of current step
            let ob = Object.keys(this.$refs).filter((ob) => ob == `observer${this.stepper+1}`)
            const valid = await this.$refs[ob[0]][0].validate()
            if(valid) {
                if(this.isLastStep) {
                    await this.save()
                    await this.closeModal()
                } else {
                    actions.selectNextStep()
                }
            }
        },
        closeModal() {
            this.$emit('close-modal')
            // this.resetFields()
            actions.resetSteps()
            actions.setSelectedIndex(null)
            // this.processing = false
            this.preSelectRoles = []
        },
        back() {
            if(this.isFirstStep)
                this.closeModal()
            else
                actions.prevStep()
        },
        resetFields() {
            this.resource = {
                 'liabilities': {
                    type: 'liabilities',
                    attributes: {
                        "amount-financed": null,
                        "amount-owing": null,
                        "credit-limit": null,
                        "description-other": null,
                        "financial-institution": null,
                        "other-institution": null,
                        "to-be-paid-out": null,
                        "__spacer": null
                    }
                },
                'expenses': {
                    type: 'expenses',
                    attributes: {
                        "payment-amount": null,
                        "payment-frequency": null
                    }
                }
            }
        },
        initializeFields() {
            let roles = []
            if(Object.keys(this.fieldsData).length > 0) {
                
                this.resource.liabilities.id = this.fieldsData.id
                this.resource.liabilities.attributes = this.fieldsData.attributes

                // get related expenses
                let relatedExpenesse = this.fieldsData.relationships.expenses.data

                if(relatedExpenesse.length > 0) {
                    // get expense record related to asset
                    if(relatedExpenesse.length > 0) {
                        this.expenses.forEach(expense => {
                            if(expense.id == relatedExpenesse[0].id) {
                                this.resource.expenses = expense
                            }
                        })
                    }
                }

                // get people role
                if(this.fieldsData.relationships.people.data.length > 0) {
                    this.fieldsData.relationships.people.data.forEach(person => {
                        if(!roles.includes(person.id)){
                            roles.push(person.id)
                        }
                    })
                }

                // get business role
                if(this.fieldsData.relationships.businesses.data.length > 0) {
                    this.fieldsData.relationships.businesses.data.forEach(business => {
                        if(!roles.includes(business.id)){
                            roles.push(business.id)
                        }
                    })
                }

                if(roles.length > 0) {
                    roles.forEach(role => {
                        if(!this.preSelectRoles.includes(role)) {
                            this.preSelectRoles.push(role)
                        }
                    })
                }
            }
        },
        resourcesFieldChecker(field) {
            let resources = 'liabilities'

            let expensesFields = ['payment-amount', 'payment-frequency']

            if(expensesFields.includes(field)) {
                resources = 'expenses'
            }

            return resources
        },
        getPayloads(type = 'liabilities') {
            let payload = {
                type: type,
                attributes: this.excludeInvalidAttributes(this.resource[type].attributes),
                relationships: {
                    application: {
                        data: {type: "applications", id: this.appId}
                    },
                    people: this.getPeopleRelationship(),
                    businesses: this.getBusinessesRelationship(),
                    kind: this.getKinds(type)
                }
            }

            return payload
        },
        getKinds(type = '') {
            let kinds = {}

            if(type == 'expenses') {
                kinds = {
                    data: {
                        type: 'kinds',
                        id: this.linkExpenses[this.kindsLiabilityId]
                    }
                }
            } else {
                kinds = {
                    data: {
                        type: 'kinds',
                        id: this.kindsLiabilityId
                    }
                }
            }

            return kinds

        }
    },
    watch: {
        financialInstitution(val){
            this.resource.liabilities.attributes['financial-institution'] = val
            if(val == 'Other'){
                this.visible["other-institution"] = true
                this.validation_rules.attributes['other-institution'] = 'required'
            } else {
                this.visible["other-institution"] = false
                this.validation_rules.attributes['other-institution'] = ''
            }
        },
    }
}
</script>
