<template>
   <div>
        <v-card 
            v-for="(section, skey) in sections"
            :key="skey" 
            :class="$vuetify.breakpoint.smAndDown ? '' : 'light_gray_1'"
            class="px-4 py-2"
            flat>

            <template v-if="centered">
                <v-card-text 
                    v-if="section.name === 'Basic Terms and Fees'"
                    class="pt-0 pa-2 text-center">

                    <v-row>
                        <v-col class="ma-0 pa-0 pt-4 text-center" cols="12">                            
                            <img
                                height="120"                             
                                class="ma-0 pa-0" 
                                :src="lenderImage" />                            
                        </v-col>
                        <v-col class="pb-2 text-center" cols="12">
                            <v-badge
                                v-if="hasSelectedLender"  
                                avatar
                                overlap
                                color="success">
                                <template v-slot:badge>
                                    <v-avatar>
                                        <v-icon size="15" dark>
                                            mdi-check
                                        </v-icon>
                                    </v-avatar>
                                </template>

                                <ConfirmModal 
                                    @confirmed-action="confirmedLender"
                                    :id="selectedLender.name"
                                    :disabled="hasSelectedLender"
                                    :outlined="hasSelectedLender"
                                    :button-label="`${hasSelectedLender ? 'Selected' : 'Select Lender'}`"
                                    :title="`${hasSelectedLender ? 'Current Selected Lender' : 'Confirm Lender'}`"
                                    color="secondary"
                                    :message="`Are you sure you want to select ${selectedLender.details.name.toUpperCase()} as your lender?`" />
                                
                            </v-badge>

                            <ConfirmModal 
                                v-else
                                @confirmed-action="confirmedLender"
                                :id="selectedLender.name"
                                :disabled="hasSelectedLender"
                                :outlined="hasSelectedLender"
                                :button-label="`${hasSelectedLender ? 'Selected' : 'Select Lender'}`"
                                :title="`${hasSelectedLender ? 'Current Selected Lender' : 'Confirm Lender'}`"
                                color="secondary"
                                :message="`Are you sure you want to select ${selectedLender.details.name.toUpperCase()} as your lender?`" />
                            
                        </v-col>
                        
                    </v-row>

                    <p class="subtitle-1 font-weight-medium pt-4 pb-0">{{ section.name }}</p>
                    <v-row class="summary-detail-card">
                        <v-col cols="6" sm="4" class="caption pt-1">
                            Amount Financed<br/>
                            <span class="font-weight-medium body-2">{{ actualPayment('naf') | currency }}</span>
                        </v-col>                        
                        <v-col cols="6" sm="4" class="caption pt-1">
                            Loan Term<br/>
                            <span class="font-weight-medium body-2">{{ actualPayment('term-in-months') }} Months</span>
                        </v-col>
                        <v-col cols="6" sm="4" class="caption pt-1">
                            Payment Terms<br/>
                            <span class="font-weight-medium body-2">{{ paymentTerms }}</span>
                        </v-col>
                        <v-col cols="6" sm="4" class="caption pt-1">
                            Balloon<br/>
                            <span class="font-weight-medium body-2">{{ actualPayment('balloon') | currency }}</span>
                        </v-col>
                        <v-col cols="6" sm="4" class="caption pt-1">
                            Customer Rate<br/>
                            <span class="font-weight-medium body-2">{{ actualPayment('customer-rate') | percentage }}</span>
                        </v-col>
                        <v-col cols="6" sm="4" class="caption pt-1">
                            Monthly Payment<br/>
                            <span class="font-weight-medium body-2">{{ actualPayment('monthly-payment') | currency }}</span>
                        </v-col>
                        <v-col cols="6" sm="4" class="caption pt-1">
                            Origination Fee<br/>
                            <span class="font-weight-medium body-2">{{ actualPayment('broker-fee') | currency }}</span>
                        </v-col>
                        <v-col cols="6" sm="4" class="caption pt-1">
                            Bank Setup Fees<br/>
                            <span class="font-weight-medium body-2">{{ actualPayment('bank-fees') | currency }}</span>
                        </v-col>
                        <v-col cols="6" sm="4" class="caption pt-1">
                            Monthly Account Fee<br/>
                            <span class="font-weight-medium body-2">{{ actualPayment('account-fee') | currency }}</span>
                        </v-col>
                        
                    </v-row>
                </v-card-text>                        
            
                <v-card-text 
                    v-else-if="section.name === 'Advanced Information'"
                    class="pa-2 text-center">
                    <p class="subtitle-1 font-weight-medium pt-4 pb-3">{{ section.name }}</p>
                    <v-row class="summary-detail-card">
                        <v-col cols="6" sm="4" class="caption py-0">
                            Base Rate<br/>
                            <span class="font-weight-medium body-2">{{ actualPayment('base-rate') | percentage }}</span>
                        </v-col>
                        <v-col cols="6" sm="4" class="caption py-0">
                            Brokerage<br/>
                            <span class="font-weight-medium body-2">{{ actualPayment('commission-inc-gst') | currency }}</span>
                        </v-col>
                        <v-col cols="6" sm="4" class="caption py-0">
                            Total Commission<br/>
                            <span class="font-weight-medium body-2">{{ computedTotalCommission() | currency }}</span>
                        </v-col>
                    </v-row>
                </v-card-text>
            </template>
            <template v-else>
                <v-card-text 
                    v-if="section.name === 'Basic Terms and Fees'"
                    class="pt-4 pa-2 pt-6">

                    <v-row>
                        <v-col class="py-0 my-0" cols="1"></v-col>
                        <v-col class="pb-2" cols="12">
                            <ConfirmModal 
                                @confirmed-action="confirmedLender"
                                :id="selectedLender.name"
                                :disabled="hasSelectedLender"
                                :outlined="hasSelectedLender"
                                :button-label="`${hasSelectedLender ? 'Selected' : 'Select Lender'}`"
                                :title="`${hasSelectedLender ? 'Current Selected Lender' : 'Confirm Lender'}`"
                                color="secondary"
                                :message="`Are you sure you want to select ${selectedLender.details.name.toUpperCase()} as your lender?`" />
                        </v-col>
                        <v-col class="mt-2" cols="10">
                            <img
                                height="100" 
                                class="ma-0 pa-0" 
                                :src="lenderImage" />
                        </v-col>
                    </v-row>

                    <p class="subtitle-1 font-weight-medium pt-4 pb-3">{{ section.name }}</p>
                    <v-row class="summary-detail-card">
                        <v-col cols="6" class="caption py-0">Amount Financed</v-col>
                        <v-col cols="6" class="caption py-0">{{ actualPayment('naf') | currency }}</v-col>
                        <v-col cols="6" class="caption py-0">Loan Term</v-col>
                        <v-col cols="6" class="caption py-0">{{ actualPayment('term-in-months') }} Months</v-col>
                        <v-col cols="6" class="caption py-0">Payment Terms</v-col>
                        <v-col cols="6" class="caption py-0">{{ paymentTerms }}</v-col>
                        <v-col cols="12" class="caption py-0">&nbsp;</v-col>
                        <v-col cols="6" class="caption py-0">Balloon</v-col>
                        <v-col cols="6" class="caption py-0">{{ actualPayment('balloon') | currency }}</v-col>
                        <v-col cols="6" class="caption py-0">Customer Rate</v-col>
                        <v-col cols="6" class="caption py-0">{{ actualPayment('customer-rate') | percentage }}</v-col>
                        <v-col cols="6" class="caption py-0">Monthly Payment</v-col>
                        <v-col cols="6" class="caption py-0">{{ actualPayment('monthly-payment') | currency }}</v-col>
                        <v-col cols="12" class="caption py-0">&nbsp;</v-col>
                        <v-col cols="6" class="caption py-0">Origination Fee</v-col>
                        <v-col cols="6" class="caption py-0">{{ actualPayment('broker-fee') | currency }}</v-col>
                        <v-col cols="6" class="caption py-0">Bank Setup Fees</v-col>
                        <v-col cols="6" class="caption py-0">{{ actualPayment('bank-fees') | currency }}</v-col>
                        <v-col cols="6" class="caption py-0">Monthly Account Fee</v-col>
                        <v-col cols="6" class="caption py-0">{{ actualPayment('account-fee') | currency }}</v-col>
                    </v-row>
                </v-card-text>                        
            
                <v-card-text 
                    v-else-if="section.name === 'Advanced Information'"
                    class="pa-2">
                    <p class="subtitle-1 font-weight-medium pt-4 pb-3">{{ section.name }}</p>
                    <v-row class="summary-detail-card">
                        <v-col cols="6" class="caption py-0">Base Rate</v-col>
                        <v-col cols="6" class="caption py-0">{{ actualPayment('base-rate') | percentage }}</v-col>
                        <v-col cols="6" class="caption py-0">Brokerage</v-col>
                        <v-col cols="6" class="caption py-0">{{ actualPayment('commission-inc-gst') | currency }}</v-col>
                        <v-col cols="6" class="caption py-0">Total Commission</v-col>
                        <v-col cols="6" class="caption py-0">{{ computedTotalCommission() | currency }}</v-col>                                
                    </v-row>
                </v-card-text>
            </template>
        
            <!-- <v-card-text 
                v-else-if="section.name === 'Early Payout Information'"
                class="pa-2">
                <p class="subtitle-1 font-weight-medium pt-4 pb-3">{{ section.name }}</p>
                <v-row class="summary-detail-card">
                    <v-col cols="12" class="caption py-0">
                        Templated text/image here that will detail the early payout info
                    </v-col>
                </v-row>
            </v-card-text>  

            <v-card-text 
                v-else-if="section.name === 'Minimum Supporting Documents'"
                class="pa-2">
                <p class="subtitle-1 font-weight-medium pt-4">Notes</p>
                <template v-if="hasSupportDocsList" class="px-8">
                    <p v-for="(list, lkey) in supportingDocs()"
                        class="caption ma-0"
                        :key="lkey">
                        {{ list }}
                    </p>
                </template>
                
                <div v-else class="px-0">
                    <v-alert 
                        type="info" color="secondary" dense
                        v-for="(list, lkey) in supportingDocs()"
                        :key="lkey">
                        <div class="subtitle-2 white--text">{{ list }}</div>
                    </v-alert>
                </div>
            </v-card-text> -->

        </v-card>

    </div>
</template>

<script>
    import ConfirmModal from '~/components/base/ConfirmModal'
    import { mapActions } from 'vuex'
    export default {
        name: 'QuotableSummary',
        components: { ConfirmModal },
        props: {
            selectedLender: {
                type: Object,
                default: () => {}
            },
            selectedLogo: {
                type: String,
                default: ''
            },
            userConfirmedLender: {
                type: String,
                default: ''
            },
            loanResource: {
                type: Object,
                default: () => {}
            },
            centered: {
                type: Boolean,
                default: false
            }
        },
        computed: {
            paymentTerms() {
                return this.loanResource?.attributes?.['requested-payment-terms'] === '' ? 'In Arrears' : this.loanResource.attributes['requested-payment-terms']
            },
            hasSupportDocsList() {
                return this.selectedLender?.details?.['supporting-docs']?.length > 1 ? true : false
            },
            hasSelectedLender() {
                if ( this.userConfirmedLender === this.selectedLender.name )
                    return true
                return this.selected_lender.length ? true : false
            },
            lenderImage() {
                return require(`~/assets/images/logo/${this.selectedLender.details['logo-class']}.png`)
            }
        },
        methods: {
            ...mapActions('resource-actions', [
                'updateLoan'
            ]),
            confirmedLender(data) {
                this.selected_lender = data
                this.$emit('user-confirmed', this.selected_lender)
            },
            actualPayment(key) {
                return this.selectedLender?.details?.['actual-payment']?.[key]
            },
            supportingDocs() {
                return this.selectedLender?.details?.['supporting-docs']
            },
            computedTotalCommission() {
                let brokerFee = parseInt( this.actualPayment('broker-fee') ) === null ? 0 : parseInt( this.actualPayment('broker-fee') )
                let cig = parseInt( this.actualPayment('commission-inc-gst') )
                return parseInt(cig) + parseInt(brokerFee)
            }
        },
        watch: {
            selectedLender(newValue) {                
                this.selected_lender = ''
            }
        },
        data() {
            return {
                selected_lender: '',
                sections: [
                    { 
                        name: 'Basic Terms and Fees',
                        data: {}
                    },
                    { 
                        name: 'Advanced Information',
                        data: {}
                    },
                    { 
                        name: 'Early Payout Information',
                        data: {}
                    },
                    { 
                        name: 'Minimum Supporting Documents',
                        data: {}
                    }
                ]
            }
        }
    }
</script>

<style lang="scss" scoped>
   
</style>