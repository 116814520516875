<template>
    <v-row :justify="options.length <= 3 ? 'center' : 'start'" 
            :style="options.length <= 3 && vb.mdAndUp ? 'width: 60%;' : 'width: 100%;'"
            align="center" class="rounded-lg light_gray_1 pa-2 ma-2 mx-auto">
        <v-col
            v-for="(option, index) in options" :key="index" class="pa-2" cols="12" :md="options.length <= 3 ? '12' : '6'">
            <!-- <v-card
                flat
                tile
                outlined
                :ripple="false"
                :width="vb.mdAndUp ? 223 : ''"
                :height="vb.mdAndUp ? 157 : ''"
                @click="select(option)"
                :style="option.id == modelValue ? `background-color: ${hexToRGBA(getActiveTheme.colors.secondary, 0.1)}; border-color: ${hexToRGBA(getActiveTheme.colors.secondary, 1)}` : ''"
                class="pa-4 rounded-xl" :class="{'pt-15': vb.mdAndUp}">
                    <div class="py-auto my-auto body-1">
                        {{ option.attributes.name }}
                    </div>
            </v-card> -->
            <v-card
                flat
                height="100%"
                rounded="lg"
                @click="select(option)"
                :style="option.id == selected ? `background-color: ${hexToRGBA(getActiveTheme.colors.primary, 0.1)}; border-color: ${hexToRGBA(getActiveTheme.colors.primary, 1)}` : ''"
                class="d-flex pa-4 align-center justify-center">
                <div class="flex-options body-2 font-weight-medium">
                    {{ option.attributes.name }}
                </div>
                <v-spacer></v-spacer>
                <v-btn color="secondary" :width="86" small depressed>
                    Select
                </v-btn>
            </v-card>
        </v-col>
        <v-col cols="12" v-if="errors.length > 0">
            <div class="v-text-field__details">
                <div class="v-messages theme--light error--text" role="alert">
                    <div class="v-messages__wrapper" v-if="errors.length">
                        <div class="v-messages__message  text-center">{{...errors}}</div>
                    </div>
                </div>
            </div>
        </v-col>
    </v-row>
</template>
<script>
import shared from '~/components/v1/AssetEditor1/__shared'

export default {
    mixins: [shared],
    model: {
        prop: 'modelValue',
        event: 'input'
    },
    props: {
        modelValue: {
            type: String,
            default: () => ''
        },
        options: {
            type: Array,
            default: () => []
        },
        errors: {
            type: Array,
            default: () => []
        }
    },
    watch: {
        modelValue(val) {
            this.selected = val
        }
    },
    mounted() {
        if(this.modelValue) this.selected = this.modelValue
    },
    computed: {
        vb() {
            return this.$vuetify.breakpoint
        }
    },
    data() {
        return {
            selected: ''
        }   
    },
    methods: {
        select(item) {
            this.selected = item.id
            this.$emit('input', this.selected)
        }
    }
}
</script>
