<template>
    <!-- appsubmitter for b2c flow -->
    <v-card class="mx-auto" tile flat>
        <v-card-text class="pb-4 pt-0">
            <v-container pa-0 fluid>

                <!-- <ValidationObserver ref="observer" tag="form">
                    <v-row>                        
                        <v-col cols="12" class="mb-4 overflow-hidden" :class="vb.smAndDown ? 'px-2': 'px-10'">

                            <div class="font-size-26 font-weight-medium mt-3 mb-6">Submission notes</div>
                            <ValidationProvider
                                v-slot="{ errors }"
                                name="Submission Comment"
                                :rules="getRules('submission-comments')"
                                tag="div"
                                class="submission-text-wrap"
                                vid="submission-comments">

                                    <v-textarea
                                        v-model="resources.loan.attributes['submission-comments']"
                                        auto-grow
                                        placeholder="Regarding any information to help with the deal"
                                        rows="10"
                                        height="100%"
                                        row-height="16"
                                        class="ma-0 pa-0"
                                        :error-messages="errors"
                                    >
                                    </v-textarea> 

                            </ValidationProvider>

                        </v-col>
                        
                    </v-row>

                </ValidationObserver> -->

                <div :class="vb.smAndDown ? 'px-2': 'px-8'">
                    <FileUpload
                        :required-docs="fileStructure" 
                        :loading="uploading"
                        :error-messages="fileErrorMessages"
                        :title="`<div class='font-size-26 font-weight-medium mt-2 mb-4'>Submission Documents</div><p class='font-size-16 pb-0 mb-1' style='max-width: 1189px;'>Please provide the relevant supporting documents for your application.</p><p class='font-size-16 pb-0 mb-0' style='max-width: 1189px;'>Please note that further supporting documents may be required to review your application and progress through the stages of the process.</p>`"
                        @remove-file="removeFile"
                        @upload-files="uploadFile" />
                </div>
                
            </v-container>
        </v-card-text>

        <!-- This will replace Next button in StepIncrementer1  -->
        <Portal to="step-incrementer-next">
            <v-btn 
                color="secondary" 
                large depressed nuxt 
                :min-width="btnWidth" 
                @click="saveApplication" 
                class="ml-3" 
                :disabled="processing">
                Submit
            </v-btn>
        </Portal>

        <v-overlay class="ma-0 pa-0" :value="overlay" opacity=".2">
            <div class="spinner">      
                <div class="flex-brand-loader">
                    <div class="flex-brand d-block">
                        <loaderImage />
                    </div>
                </div>
            </div>
        </v-overlay>
    </v-card>
</template>

<script>

    import loaderImage from '~/components/base/loaderImage'
    import FileUpload from '~/components/base/FileUpload'
    import subscriptions from '~/components/shared/subscriptions'
    import { mapState, mapGetters, mapActions, mapMutations } from 'vuex'

    export default {
        components: {
            loaderImage,
            FileUpload
        },
        mixins: [subscriptions],

        data(){
            return {
                uploading: false,
                requiredDocs: {},
                confirmDialogTitle: '',
                confirmDialog: false,
                fileErrorMessages: {},

                overlay: false,
                validComponentData: true,

                resources: {
                    loan: {
                        type: "loan",
                        id: null,
                        attributes: {
                            "submission-comments": null,
                        },
                        relationships: {
                            application: {
                                data: {type: "applications", id: null}
                            }
                        }
                    },
                    applications: {
                        type: "applications",
                        id: null,
                        attributes: {},
                    }
                },
                validation_rules: {
                    type: "people",
                    attributes: {
                        "submission-comments": "min:250",
                    }
                }
            }
        },

        computed: {
            ...mapState({
                appId: state => state.flows.appId,
                appGuid: state => state.flows.appGuid,
            }),
            ...mapState('statuses', [
                'stepStatuses'
            ]),
            ...mapGetters('slug', [
                'getTenant',
            ]),
            ...mapGetters('statuses', [
                'getCurrentFlowStatus'
            ]),
            processing(){
                return  this.isDisabled
            },
            btnWidth () {
                switch (this.$vuetify.breakpoint.name) {
                    case 'xs': return 0
                    case 'lg': 
                    case 'xl':
                    case 'md': 
                    case 'sm': return 140
                }
            },
            isFinancePurpose() {
                if ( this.getFlowSlug.indexOf('commercial') >= 0 || this.getFlowSlug.indexOf('business') >= 0 )
                    return 'Yes'
                return 'No'
            },
            isCommercial(){
              if(this.resources.loan){
                  return  this.resources.loan.attributes['finance-for-business-purposes'] == 'Yes'
              }
              return false
            },
            fileStructure() {
                const { requiredDocs } = this
                let requiredFiles = []

                if(Object.keys(requiredDocs).length) {
                    Object.keys(requiredDocs).forEach(keys => {

                        if(!keys.includes('application')) {

                            const applicant = requiredDocs[keys]['name']

                            if(Object.keys(requiredDocs[keys].requirements).length) {

                                Object.keys(requiredDocs[keys].requirements).forEach(docType => {

                                    requiredFiles.push({
                                        'title': docType,
                                        'chip-name': docType,
                                        'chip-description': requiredDocs[keys].requirements[docType].description,
                                        'paths': requiredDocs[keys].requirements[docType].paths,
                                        'applicant': applicant ? applicant : 'Person',
                                        'prefix': requiredDocs[keys].requirements[docType]['upload_prefix'],
                                    })

                                })

                            }

                        } else {
                            requiredFiles.push({
                                'title': 'Other Documents',
                                'chip-name': 'Other Documents',
                                'chip-description': requiredDocs[keys].requirements['Other Documents'].description,
                                'paths': requiredDocs[keys].requirements['Other Documents'].paths,
                                'applicant': '',
                                'prefix': requiredDocs[keys].requirements['Other Documents']['upload_prefix'],
                            })
                        }

                    })
                }

                return requiredFiles
            },   
            vb() {
                return this.$vuetify.breakpoint
            }
        },
        methods: {
            ...mapActions('resource-actions', [
                'getLoan',
                'updateLoan',
                'getApplication',
                'runServiceUpload',
                'runServiceDeleteFile',
                'getRequiredDocs'
            ]),
            ...mapActions('statuses', [
                'completeFlowStatus'
            ]),
            ...mapActions('flows', [
                'selectFinalStep',
            ]),
            ...mapMutations('utils', [
                'toggleAlert',
                'toggleQuickMessage'
            ]),

            async pull() {

                let requests = [
                    this.getApplication({ id: this.appId, 
                        params: {
                            include: 'loan'
                        }
                    }),
                ]

                await this.getRequiredDocuments()

                Promise.all(requests).then( data => {
                    let applications = data.find( dat => {
                        return dat.data.data.type === 'applications'
                    })

                    this.resources.applications = applications.data.data
                    this.resources.loan = this.resourceFieldPicker(this.resources.loan, applications.data.included[0])

                    if ( !this.resources.loan.attributes['finance-for-business-purposes'] ) {
                        this.resources.loan.attributes['finance-for-business-purposes'] = this.isFinancePurpose
                    }

                    return data

                })

            },
            async push() {
                try {

                    this.overlay = true

                    const observer = this.$refs.observer
                    let validSteps = true

                    let iterations = this.stepStatuses.length

                    this.stepStatuses.forEach( (step, i) => {
                        if (--iterations) {
                            if ( step.attributes.value !== 'valid' && step.attributes.included === 1 )
                                validSteps = false
                        }
                    })

                    //const {valid, status} = await this.validateFormData()
                    // const validFiles = this.validateFiles()

                    const {loan} = this.resources

                    // Exclude relationships and other stuff
                    // let payload = {
                    //     type: loan.type,
                    //     id: loan.id,
                    //     attributes: this.excludeInvalids(loan.attributes, observer.fields)
                    // }

                    // payload.attributes['finance-for-business-purposes'] = this.resources.loan.attributes['finance-for-business-purposes']

                    if( loan?.id ) {

                        // await this.updateLoan(payload)

                        if ( validSteps ) {


                        } else {

                            this.overlay = false

                            this.toggleAlert({
                                text: 'Steps are incomplete... To continue, please fill the steps below, completely:',
                                isStepValidator: true,
                                type: 'error',
                                prominent: true,
                                dismissible: true
                            })
                        }

                    }
                    console.log(validSteps)

                    return { valid: validSteps, status: validSteps ? 'valid' : 'dirty' }

                } catch (err) {
                    this.overlay = false
                    console.log(err)
                }
            },
            async saveApplication() {
                try {

                    this.overlay = true
                    
                    await this.selectFinalStep({flowStatus: this.getCurrentFlowStatus.id})

                    this.overlay = false

                } catch (err) {
                    this.overlay = false
                    console.log(err)
                }

            },

            wordCount(field = '') {
                return this.resources?.loan?.attributes?.[field]?.length <= 250 ? 250 : false
            },

            async uploadFile(data) {
                try {

                    this.uploading = true

                    if(data.files.length > 0) {

                        let payload = new FormData()

                        payload.append('application', this.appGuid)
                        payload.append('prefix', data.prefix)

                        for(let index = 0; index < data.files.length; index++) {
                            payload.append('files['+ index +']', data.files[index])
                        }

                        const res = await this.runServiceUpload(payload)

                        if(res.status === 200) {

                            await this.getRequiredDocuments()

                        } else {

                            this.toggleQuickMessage({text: 'Uh oh! something went wrong!' , color: 'error'})
                            await this.getRequiredDocuments()

                        }

                    } else {

                        await this.getRequiredDocuments()

                    }

                    this.uploading = false
                    
                } catch (err) {
                    this.uploading = false
                    console.log(err)
                }
                
            },

            async removeFile(name) {
                try {

                    this.uploading = true

                    const res = await this.runServiceDeleteFile({ "application": this.appGuid, "file": name })

                    if(res.status === 200) {
                        await this.getRequiredDocuments()
                    }

                    this.uploading = false

                } catch (err) {
                    await this.getRequiredDocuments()
                    this.uploading = false
                    this.toggleQuickMessage({text: 'Uh oh! something went wrong!' , color: 'error'})
                }
            },

            async getRequiredDocuments() {
                try {

                    const res = await this.getRequiredDocs(this.appGuid)

                    if(res.status === 200) {
                        this.requiredDocs = res.data.data
                        //this.validateFiles()
                    }

                } catch (err) {
                    console.log(err)
                }
            },

            getPrefix(keys, document_name) {
                console.log(keys + '_' + document_name)
                return keys + '_' + document_name
            },

            filterFiles(files, prefix) {

                if(files.length > 0) {
                    return files.filter(file => file.includes(prefix))
                }

                return files
            },

            validateFiles() {
                let valid = true
                const files = this.fileStructure
                this.fileErrorMessages = {}

                if(files.length > 0 && this.submitFlag) {

                    files.forEach(file => {
                        if(file.paths.length === 0) {
                            this.fileErrorMessages[file.prefix] = file['chip-name'] + ' is required'
                            valid = false
                        }
                    })

                }

                return valid

            },
        },
        watch: {
            'terms': {
                handler(val) {
                    if (val['agree-documents'] === false) {
                        val['agree-documents'] = ''
                    }
                    if (val['agree-application'] === false) {
                        val['agree-application'] = ''
                    }
                    if (val['hybrid-declaration'] === false) {
                        val['hybrid-declaration'] = ''
                    }
                },
                deep: true
            },
            validComponentData(val){
                if(!val) {
                    this.showComponentWarning()
                }
            },
        }
    
    }
</script>

<style lang="scss">
</style>

