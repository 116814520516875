<template>
    <div>
      <v-card :disabled="isDisabled" class="mx-auto" tile flat>
        <v-card-text class="pa-4 pt-0 pa-md-10 pt-md-0">
          <v-container pa-0 fluid>
            <v-row>
              <v-col cols="12">
                <v-data-table
                  :loading="false"
                  loading-text="Please wait..."
                  :items="people"
                  hide-default-footer
                  :mobile-breakpoint="0"
                  hide-default-header
                  class="v-data-table-6 mx-auto"
                >
                  <!-- Body -->
                  <template v-if="vb.mdAndUp" v-slot:item="{ item, index }">
                    <v-card
                      tile
                      flat
                      class="mb-6 pa-2 rounded-xl"
                      color="light_gray_1"
                    >
                      <div
                        class="
                          d-flex
                          justify-space-between
                          align-center
                          py-2
                          px-4
                          flex-table-row
                        "
                      >
                        <div class="d-flex flex-column">
                          <div class="mr-6">
                            <v-icon size="32">mdi-account</v-icon>
                          </div>
                        </div>
  
                        <div class="d-flex flex-column">
                          <div class="mr-6 body-1 font-weight-medium">
                            {{ getFriendlyName(item) }}
                          </div>
                        </div>
  
                        <div class="d-flex flex-column">
                          <div>
                            <v-chip
                              :filter="false"
                              :ripple="false"
                              :style="`background-color: ${hexToRGBA(
                                getActiveTheme.colors.secondary,
                                0.2
                              )}`"
                              class="px-5 font-weight-medium"
                            >
                              {{
                                item.roles.length > 1
                                  ? `${item.roles[0]} +${item.roles.length - 1}`
                                  : item.roles.length == 1
                                  ? item.roles[0]
                                  : ""
                              }}
                            </v-chip>
                          </div>
                        </div>
  
                        <v-spacer></v-spacer>
  
                        <div class="d-flex justify-end">
                          <v-btn
                            depressed
                            small
                            @click="handleAddDetails(item)"
                            :color="
                              getCompleted.includes(item.id)
                                ? 'success'
                                : 'secondary'
                            "
                          >
                            {{
                              getCompleted.includes(item.id)
                                ? "Completed"
                                : "Add Details"
                            }}
                          </v-btn>
                        </div>
                      </div>
                    </v-card>
                  </template>
                  <!-- mobile version -->
                  <template v-else v-slot:item="{ item, index }">
                    <v-card
                      tile
                      flat
                      class="mb-6 pa-2 rounded-lg"
                      color="light_gray_1"
                    >
                      <v-card-text class="d-flex pb-1">
                        <v-icon size="32" class="mt-n2">mdi-account</v-icon>
                        <div class="body-1 font-weight-medium pl-1">
                          {{ getFriendlyName(item) }}
                        </div>
                      </v-card-text>
                      <v-card-text class="pt-0">
                        <v-chip
                          :filter="false"
                          :ripple="false"
                          :style="`background-color: ${hexToRGBA(
                            getActiveTheme.colors.secondary,
                            0.2
                          )}`"
                          class="px-5 font-weight-medium"
                        >
                          {{
                            item.roles.length > 1
                              ? `${item.roles[0]} +${item.roles.length - 1}`
                              : item.roles.length == 1
                              ? item.roles[0]
                              : ""
                          }}
                        </v-chip>
                      </v-card-text>
                      <v-card-actions>
                        <v-spacer></v-spacer>
  
                        <v-btn
                          depressed
                          small
                          @click="handleAddDetails(item)"
                          :color="
                            getCompleted.includes(item.id)
                              ? 'success'
                              : 'secondary'
                          "
                        >
                          {{
                            getCompleted.includes(item.id)
                              ? "Completed"
                              : "Add Details"
                          }}
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </template>
                </v-data-table>
  
                <v-alert dense outlined type="error" v-if="errors.length > 0">
                  <div v-for="msg in errors" v-html="msg"></div>
                </v-alert>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
  
      <!-- Modal -->
      <v-dialog
        max-width="844"
        persistent
        scrollable
        v-model="requirementsDialog"
      >
        <v-card flat :disabled="requirementsDialogLoading">
          <v-progress-linear
            color="secondary"
            indeterminate
            stream
            absolute
            top
            v-if="requirementsDialogLoading"
          ></v-progress-linear>
  
          <v-card-title class="justify-end">
            <v-btn
              icon
              max-width="40"
              max-height="40"
              :style="`background-color: ${hexToRGBA(
                getActiveTheme.colors.secondary,
                0.1
              )}`"
              @click="closeDialog"
              fab
            >
              <v-icon color="secondary">mdi-close</v-icon>
            </v-btn>
          </v-card-title>
  
          <v-card-text class="px-0 py-0 pb-8">
            <v-carousel
              light
              v-model="carousel"
              :continuous="false"
              :show-arrows="false"
              hide-delimiters
              touchless
              height="auto"
              hide-delimiter-background
            >
              <v-carousel-item v-for="(step, key) in steps" :key="key">
                <v-card flat>
                  <v-card-title
                    class="d-flex py-0"
                    :class="vb.mdAndUp ? 'px-8' : 'px-4'"
                  >
                    <div>
                      <h4 :class="vb.mdAndUp ? 'font-size-26' : 'font-size-18'">
                        {{ step.title }} | {{ getFriendlyName(selectedEntity) }}
                      </h4>
                      <p :class="vb.mdAndUp ? 'body-1' : 'caption'">
                        {{ step.subtitle }}
                      </p>
                    </div>
                    <v-spacer />
                  </v-card-title>
  
                  <v-card-text class="px-0">
                    <template v-if="key == 'employment'">
                      <FormEmploymentInfo
                        :ref="step['form-ref']"
                        :updater="formUpdater"
                        type="commercial"
  
                        :data="selectedEntity"
                        :with-boat="hasBoatPurchase"
                        :with-vehicle="hasVehiclePurchase"
  
                        :self-employed-id="getSelfEmployedIncomeId"
                        :current-occupancy="selectedEntityCurrentOccupancy"
                        :current-income="selectedEntityCurrentIncome"
  
                        @loading="handleLoading"
                        @submit="handleFormEmploymentInfoSubmit"
                      />
                    </template>

                    
                    <template v-if="key == 'financial'">
                      <FormFinancialExpense
                        :ref="step['form-ref']"
                        :updater="formUpdater"
                        :data="selectedEntity"
                        :loans="loans"
                        :loanId="getLoanId"
                        :with-boat="hasBoatPurchase"
                        :with-vehicle="hasVehiclePurchase"
                        :businesses="businesses"
                        @loading="handleLoading"
                        @submit="handleFormPersonalInformationSubmit"
                      />
                    </template>
                  </v-card-text>
                </v-card>
              </v-carousel-item>
            </v-carousel>
          </v-card-text>
  
          <v-card-actions class="pa-4 pa-md-6 flex-wrap controls">
            <v-btn
              v-if="!isFirstStep"
              color="light_gray_2"
              class="flex-back-button"
              :min-width="140"
              large
              outlined
              @click="handleBack"
              elevation="0"
              nuxt
            >
              Back
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn
              color="secondary"
              :min-width="140"
              large
              elevation="0"
              @click="handleNext"
              nuxt
            >
              {{ isLastStep ? "Save" : "Next" }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
  
    </div>
  </template>
  
  <script>
  import { mapActions, mapState, mapMutations } from "vuex";
  import subscriptions from "~/components/shared/subscriptions";
  import shared from "~/components/v1/submission/MacquarieApplicant1/__shared";
  
  import {
    store,
    actions,
  } from "~/components/v1/submission/MacquarieApplicant1/__store";
  
  import FormEmploymentInfo from "~/components/v1/submission/MacquarieApplicant1/_FormEmploymentInfo";
  import FormFinancialExpense from "~/components/v1/submission/MacquarieApplicant1/_FormFinancialExpense";
  
  export default {
    components: {
      FormEmploymentInfo,
      FormFinancialExpense,
    },
  
    mixins: [subscriptions, shared],
    props: {
      loans: {
        type: Object
      }
    },
    data() {
      return {
        requirementsDialog: false,
        requirementsDialogLoading: false,
  
        people: [],
        incomes: [],
        purchases: [],
        occupancies: [],
        businesses: [],
  
        selectedEntity: null,
        selectedEntityCurrentIncome: null,
        selectedEntityIncomes: [],
        
        selectedEntityCurrentOccupancy: null,
  
        errors: [],
  
        secondaryApplicantId: null,
      };
    },
  
    computed: {
      vb() {
        return this.$vuetify.breakpoint;
      },
      carousel: {
        get() {
          return store.carousel;
        },
        set(value) {
          actions.setCarousel(value);
        },
      },
      steps() {
        return store.steps;
      },
      formUpdater() {
        return this.requirementsDialog;
      },
      isLastStep() {
        if (this.carousel == Object.keys(this.steps).length - 1) {
          return true;
        }
  
        return false;
      },
      isFirstStep() {
        if (this.carousel == 0) {
          return true;
        }
  
        return false;
      },
      getCompleted() {
        const { loans, people, businesses } = this;
  
        let completed = [];
  
        people.forEach((person) => {
  
          let loanFields = [
            "repaymentAffordability",
            "creditOption",
            "loanStatement",
          ]
          let personFields = [
            "driversLicenceType",
            "otherHasCircumstancesChanging",
            "name",
            "beneficiaryType",
          ]
          let businessesFields = [
          "isProprietaryCompany"
          ]
          

          if(loans.attributes.lender.loanStatement == 'NO') {
            loanFields.push("financialReference")
          }
          if(loans.attributes.lender.loanStatement == 'NO') {
            loanFields.push("accountReference")
          }



          const loanValidFields = loanFields.every( field => {
              return loans?.attributes?.lender?.macquarie?.[field] 
          })
          
          const personValidFields = personFields.every( field => {
              return person?.attributes?.lender?.macquarie?.[field] 
          })
          const businessesValidFields = businessesFields.every( field => {
              return businesses?.attributes?.lender?.macquarie?.[field] 
          })
          
          if (loanValidFields && personValidFields && businessesValidFields) {
            completed.push(person.id)
          }
          
        });
  
        return completed;
      },
      getLoanId() {
        return this.appData?.relationships?.loan?.data?.id;
      },
      isJoint() {
        return this.calculation["application-type"].includes("Consumer - Joint");
      },
      hasBoatPurchase(){
  
        let flag = false
  
        if(this.purchases.length > 0) {
          this.purchases.forEach( purchase => {
            let kind = this.kindsUnformat.find(kind => kind.id == purchase.relationships?.kind?.data?.id)
            if(["9-4", "9-5"].includes(kind?.id)) { // "Boat (Moared) & Boat (Trailered)"
              flag = true
            }
          })
        }
  
        return flag
      },
      hasVehiclePurchase(){
        
        let flag = false
  
        if(this.purchases.length > 0) {
          this.purchases.forEach( purchase => {
            let kind = this.kindsUnformat.find(kind => kind.id == purchase.relationships?.kind?.data?.id)
  
            if(kind?.id == "9-2") { // "Motor Vehicle"
              flag = true
            }
          })
        }
  
        return flag
      },
  
      getSelfEmployedIncomeId(){
  
        let id = null
  
        this.selectedEntityIncomes.forEach( income => {
  
           let kindId = income.relationships?.kind?.data?.id
  
           if(kindId == "12-29") {
              id = income.id
           }
  
        })
  
        return id
      },
      isRenting(){
        
        if(this.selectedEntityCurrentOccupancy) {
          return ["Renter - Agent", "Renter - Private"].includes(this.selectedEntityCurrentOccupancy?.attributes["residential-situation"])
        }
        
        return false
      },
    },
    methods: {
      ...mapActions("resource-actions", [
        "getLoan",
        "getPeople",
        "getBusinesses",
        "updateBusiness",
        "getIncomes",
        "getPurchases",
        "getExpenses",
        "getOccupancies",
        "updateLoan",
        "updatePerson",
        "updateIncome",
        "updateExpense",
        "updateOccupancy",
        "getBusinesses"
      ]),
      async pullChild() {
  
        let promise1 = this.getLoan(this.getLoanId, { include: "parent-parts" });
        let promise2 = this.getPeople({ include: "parent-parts" });
        let promise3 = this.getIncomes();
        let promise4 = this.getPurchases();
        let promise5 = this.getOccupancies();
        let promise6= this.getBusinesses();
  
        // Get the secondary applicant id
  
        const res = await Promise.all([
          promise1,
          promise2,
          promise3,
          promise4,
          promise5,
          promise6
        ]);
  
        promise1 = res[0];
        promise2 = res[1];
        promise3 = res[2];
        promise4 = res[3];
        promise5 = res[4];
        promise6 = res[5];

        if (this.isJoint && promise2.data.included) {
          promise2.data.included.forEach((part) => {
            if (part.attributes.priority == 2) {
              this.secondaryApplicantId = part.relationships.child.data.id;
            }
          });
        }
  
        if (promise1.data?.data?.relationships?.kind?.data?.id) {
          this.loanKindId = promise1.data.data.relationships.kind.data.id;
        }
  
        this.people = this.getPeopleByRoles(promise2.data);
        // this.people = promise2.data.data
        this.incomes = promise3?.data?.data ? promise3.data.data : [];
        this.purchases = promise4?.data?.data ? promise4.data.data : [];
        this.occupancies = promise5?.data?.data ? promise5.data.data : [];
        this.businesses = promise6?.data?.data ? promise6.data.data[0] : [];
        
      },
      async pushChild() {
        this.checkCompleted();
  
        let valid = this.errors.length == 0;
  
        let status = valid ? "valid" : "dirty";
  
        return { valid, status, data: {} };
      },
      closeDialog() {
        this.requirementsDialog = false;
        actions.resetStore();
      },
      checkCompleted() {
        this.errors = [];
  
        if (this.getCompleted.length != this.people.length) {
          this.errors.push("Please complete all requirements");
        }
      },
      async handleAddDetails(data) {
  
        this.requirementsDialog = true;
        this.selectedEntity = data;
  
          // /***
          //  * Find selected entity's first current income
          //  */
  
          // let current_income = null
          // let current_occupancy = null
          // let allIncomes = []
  
          // if(data.relationships.incomes.data.length > 0) {
  
          //     this.incomes.forEach(income => {
          //         data.relationships.incomes.data.forEach(dat => {
          //             if(dat.id == income.id) {
  
          //                 allIncomes.push(income)
  
          //                 if(income.attributes['income-situation'] == 'Current') {
          //                   current_income = income
          //                 }
          //             }
          //         })
          //     })
  
          // }
  
          // this.selectedEntityIncomes = allIncomes
          // this.selectedEntityCurrentIncome = current_income
  
  
          // /**
          //  * Filter selected entities current occupancy
          //  */
          // this.occupancies.forEach( occupancy => {
  
          //   if(occupancy.relationships?.person?.data?.id == this.selectedEntity?.id) {
          //     if(occupancy.attributes["occupancy-situation"] == "Current") {
          //       current_occupancy = occupancy
          //     }
          //   }
          // })
  
          // this.selectedEntityCurrentOccupancy = current_occupancy
  
      },
      async handleNext() {
        let stepsArray = Object.keys(this.steps);
  
        switch (stepsArray[this.carousel]) {
          case "financial":
            if (this.$refs.FinancialExpenseForm[0]) {
              await this.$refs.FinancialExpenseForm[0].validate();
            }
  
            break;
          case "employment":
            if (this.$refs.EmploymentInformationForm[0]) {
              await this.$refs.EmploymentInformationForm[0].validate();
            }
  
            break;
          default:
            this.carousel++;
  
            break;
        }
      },
      async handleBack() {
        this.carousel--;
      },
      async handleFormPersonalInformationSubmit(payload) {
        this.requirementsDialogLoading = true;
  
        if (payload.loans.hasOwnProperty("id")) {
  
          let res = await this.updateLoan(payload.loans);
          let res1 = {status: 200}
          let res2 = {status: 200}

          if (this.businesses) {
            res2 = await this.updateBusiness(payload.businesses);
          }
  
          if(res.status == 200 && res1.status == 200 && res2.status == 200) {
  
              this.updateLocalResourceAttributes(res.data.data)
  
              actions.setCarouselNext()
              this.requirementsDialog = false
              this.requirementsDialogLoading = false
  
          } else {
  
              this.requirementsDialogLoading = false
          }
  
        }
      },
      async handleFormEmploymentInfoSubmit(payload) {
  
      this.requirementsDialogLoading = true;

      if (payload.person.hasOwnProperty("id")) {

        let res = await this.updatePerson(payload.person);

        if(res.status == 200) {

            this.updateLocalResourceAttributes(res.data.data)
            actions.setCarouselNext()
          
            this.requirementsDialogLoading = false

        } else {

            this.requirementsDialogLoading = false
        }

      }
  
      },
      updateLocalResourceAttributes(resource) {
        if (!resource) return;

        if (resource.type === 'loans') {
          this.$emit('loans', resource)
          return
        }

        this[resource.type].attributes = resource.attributes
        // let getType = resource.type;
  
        // this[getType].forEach((v, i) => {
        //   if (v.id == resource.id) {
        //     this[getType][i]["attributes"] = resource.attributes;
        //   }
        // });
      },
      handleLoading(payload) {
        this.requirementsDialogLoading = payload;
      },
    },
  };
  </script>
  
  <style lang="scss" scoped></style>
  