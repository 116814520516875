<template>
    <div>
      <div class="d-flex" style="width: 100%">
        <v-sheet
          color="light_gray_1"
          class="py-8 px-12"
          width="50%"
          outlined
          elevation="0"
        >
          <v-img
            max-width="120"
            class="flex-brand"
            :src="require(`~/assets/images/nodifi.svg`)"
            position="center left"
            contain
          >
          </v-img>
          <div class="mt-2 mb-6 font-size-20">Data</div>
  
          <v-list color="transparent">
            <v-list-item two-line class="px-0">
              <v-list-item-content>
                <v-list-item-title class="font-weight-medium"
                  >Driver Licence Number</v-list-item-title
                >
                <v-list-item-subtitle>
                  {{ data.attributes['drivers-licence-number'] ? data.attributes['drivers-licence-number'] || "&mdash;" : "&mdash;" }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
  
            <v-list-item two-line class="px-0">
              <v-list-item-content>
                <v-list-item-title class="font-weight-medium"
                  >Driver Licence Card Number</v-list-item-title
                >
                <v-list-item-subtitle>
                  {{ data.attributes['drivers-licence-card-number'] ? data.attributes['drivers-licence-card-number'] || "&mdash;" : "&mdash;" }}
               </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
  
            <v-list-item two-line class="px-0">
              <v-list-item-content>
                <v-list-item-title class="font-weight-medium"
                  >Driver Licence State</v-list-item-title
                >
                <v-list-item-subtitle>
                  {{ data.attributes['drivers-licence-state'] || "&mdash;" }}
               </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-sheet>
        <v-sheet class="py-8 px-12" width="50%">
          <v-img
            max-width="120"
            class="flex-brand"
            :src="require(`~/assets/images/logo/cropped/lender-logo-macquarie.png`)"
            position="center left"
            contain
          >
          </v-img>
          <div class="mt-2 mb-8 font-size-20">Requirements</div>
          <ValidationObserver ref="observer" tag="form">
            <ValidationProvider
              key="beneficiary_type"
              v-slot="{ errors }"
              name="Field"
              :rules="getRules('beneficiary_type')"
              vid="beneficiary_type"
              v-if="type === 'commercial'"
            >
              <v-select
                  v-model="resources.person.attributes.lender.macquarie.beneficiary_type"
                  :items="options.beneficiary_type"
                  :error-messages="errors"
                  color="secondary"
                  label="Beneficiary Type"
                />
            </ValidationProvider>
            <ValidationProvider
              key="name"
              v-slot="{ errors }"
              name="Field"
              :rules="getRules('name')"
              vid="name"
              v-if="type === 'commercial'"
            >
            <v-text-field
                  tile
                  type="text"
                  label="Name of Beneficiary"
                  color="secondary"
                  :error-messages="errors"
                  v-model="resources.person.attributes.lender.macquarie.name"
                  required
                >
                </v-text-field>
            </ValidationProvider>
            <ValidationProvider
              key="licence_type"
              v-slot="{ errors }"
              name="Field"
              :rules="getRules('licence_type')"
              vid="licence_type"
            >
              <v-select
                  v-model="resources.person.attributes.lender.macquarie.licence_type"
                  :items="options.licence_type"
                  :error-messages="errors"
                  color="secondary"
                  label="Driver’s Licence Type"
                />
            </ValidationProvider>
            <ValidationProvider
              key="foreseeable_changes"
              v-slot="{ errors }"
              name="Field"
              :rules="getRules('foreseeable_changes')"
              vid="foreseeable_changes"
            >
              <ButtonGroup3
                  v-model="resources.person.attributes.lender.macquarie.foreseeable_changes"
                  :error-messages="errors"
                  :options="[
                    { value: '1', label: 'Yes' },
                    { value: '0', label: 'No' },
                  ]"
                  label="Is the applicant expecting any changes to their circumstances in the foreseeable future that will impact their ability to make repayments?"
                />
            </ValidationProvider>
          </ValidationObserver>
  <!-- 
          <ValidationObserver ref="observer" tag="form">
            <template v-for="(field, key) in resources.incomes.attributes">
              <ValidationProvider
                :key="key"
                v-slot="{ errors }"
                :name="labels[key]"
                :rules="getRules(key)"
                :vid="key"
           >
                <ButtonGroup3
                  v-if="isBtnGroup(key)"
                  v-model="resources.incomes.attributes[key]"
                  :error-messages="errors"
                  :options="[
                    { value: '1', label: 'Yes' },
                    { value: '0', label: 'No' },
                  ]"
                  :label="labels[key]"
                />
  
                <v-select
                  v-else-if="isFieldType('picklist', key)"
                  v-model="resources.incomes.attributes[key]"
                  :items="getOptions(key)"
                  :error-messages="errors"
                  color="secondary"
                  :label="labels[key]"
                ></v-select>
  
                <v-text-field
                  v-else
                  tile
                  :type="key === 'gross-annual-income' ? 'number' : 'text'"
                  :prefix="key === 'gross-annual-income' ? '$' : undefined"
                  :label="labels[key]"
                  color="secondary"
                  :error-messages="errors"
                  v-model="resources.incomes.attributes[key]"
                >
                </v-text-field>
              </ValidationProvider>
            </template>
  
          </ValidationObserver> -->
        </v-sheet>
      </div>
  </div>
  </template>

  <script>  
  import { mapActions, mapMutations, mapGetters } from "vuex";
  import subscriptions from "~/components/shared/subscriptions";
  import shared from "~/components/v1/submission/MacquarieApplicant1/__shared";
  import {
    store,
    actions,
  } from "~/components/v1/submission/MacquarieApplicant1/__store";
  import ButtonGroup3 from "~/components/base/ButtonGroup3";
  import moment from 'moment';
  
  export default {
    components: { ButtonGroup3 },
    mixins: [subscriptions, shared],
    props: {
      data: {
        type: Object,
        default: null,
      },
      updater: undefined,
      type: String
    },
    data() {
      return {
        driversLicenceHasSpecialRestriction: false,
        otherHasCircumstancesChanging: false,
        resources: {
          person: {
            id: null,
            type: "people",
            attributes: {
               "lender": {
                  "macquarie": {
                    licence_type: '',
                    foreseeable_changes: '',
                    name:'',
                    beneficiary_type: ''
                  }
              }
            }
          }
        },
        visible: {
          "driversLicenceSpecialRestrictionDetails": true,
          "otherChangeInformation": true,
        },
        labels: {
          beneficiary_type: 'Beneficiary Type',
          name: 'Name',
          licence_type: 'Licence Type',
          foreseeable_changes: 'Foreseeable Changes',
        },
        validation_rules: {
          attributes: {
            "beneficiary_type": "required",
            "name": "required",
            "licence_type": "required",
            "foreseeable_changes": "required",
          },
        },
        options: {
          'licence_type': [
            {text:'Full', value: 'FULL'},
            {text:'Provisional', value: 'PROVISIONAL'},
            {text:'Learner', value: 'LEARNER'},
            {text:'Heavy Vehicle', value: 'HEAVY_VEHICLE'},
            {text:'None', value: 'NONE'},
          ],
          'beneficiary_type': [
            {text:'Class', value: 'CLASS'},
            {text:'Individual', value: 'INDIVIDUAL'},
          ]
        },
      };
    },
    computed: {
      // startDate() {
      //   if (this.getSelectedIncome) {
      //     return moment(this.getSelectedIncome.attributes["start-date"]).format('DD/MM/YYYY')
      //   }
      //   return null
      // },
      // requiredCovidWfh () {
      //   return [
      //     'Full-Time', 'Part-Time', 'Casual', 'Contract', 'Self-Employed - Sole-Trader', 
      //     'Self-Employed - Company', 'Self-Employed - Other', 
      //   ].includes(this.data.attributes['employment-job-status'])
      // },
      // requiredPrimaryPermanentAndHours () {
      //   return ['Part-Time'].includes(this.data.attributes['employment-job-status'])
      // },
      // vb() {
      //   return this.$vuetify.breakpoint;
      // },
      // hasPreviousIncome () {
  
      //   // /**
      //   //  * Include Secondary only if PAYG or Self-Employed
      //   //  *  12-28 - PAYG
      //   //  *  12-29 - Self-Employed
      //   //  */
  
  
      //   return this.incomes.some(item => {
  
      //     return item.attributes['income-situation'] === 'Previous'
  
      //   })
      // },
      // getSelectedIncome(){
      //   return this.incomes.find(income => {
      //     return income.attributes['income-situation'] === 'Current'
      //   })
      // }
    },
    methods: {
      ...mapActions("resource-actions", ["getPerson", "getIncome"]),
      isFieldType(field, key) {
        return field === "picklist"
          ? ["lender-wisr-industry", "lender-wisr-occupation"].includes(key)
          : false;
      },
      selectTab(tab) {
        this.tabSelection = tab;
      },
      isBtnGroup(key) {
        let keys = ["lender-wisr-forsee-repayment"];
  
        return keys.includes(key);
      },
      async validate() {
        const valid = await this.$refs.observer.validate();
  
        if (valid) {
          let fields = {
              driversLicenceType: this.resources.person.attributes.lender.macquarie.licence_type,
              otherHasCircumstancesChanging: this.resources.person.attributes.lender.macquarie.foreseeable_changes === '1' ? 'YES' : 'NO',
          }
          if (this.type === 'commercial') {
            fields = {
              ...fields,
              name: this.resources.person.attributes.lender.macquarie.name,
              beneficiaryType: this.resources.person.attributes.lender.macquarie.beneficiary_type,
            }
          }
          this.$emit('submit', {
            person: {
              ...this.resources.person,
              attributes: {
                lender: {
                  macquarie: fields
                }
              }
            }
          })
        }
      },
      reset() {
        this.$refs.observer.reset();
      },
      async updateResourceFields() {
        if (this.data) {
          this.resources.person.id = this.data.id
          this.resources.person.attributes.lender.macquarie = {
            licence_type: this.data.attributes.lender.macquarie.driversLicenceType,
            foreseeable_changes: this.data.attributes.lender.macquarie.otherHasCircumstancesChanging === 'YES' ? '1' : '0',
            name: this.data.attributes.lender.macquarie.name,
            beneficiary_type: this.data.attributes.lender.macquarie.beneficiaryType,
          }
        }

        // this.moneyPlace = {
        //   covidWfh: '',
        //   previousIndustry: '',
        //   primaryHours: '',
        //   primaryIndustry: '',
        //   primaryPermanent: '',
        // }
  
        // this.$emit("loading", true);
  
        // const { data } = this;
  
        // if (data) {
        //   let res = await this.getIncome(data.id);
        //   const { moneyPlace } = res.data.data.attributes.lender
        //   this.moneyPlace = {
        //     ...moneyPlace, 
        //     primaryHours: moneyPlace.primaryHours === null ? null : moneyPlace.primaryHours ? '1' : '0',
        //     covidWfh: moneyPlace.covidWfh === null ? null : moneyPlace.covidWfh ? '1' : '0',
        //     primaryPermanent: moneyPlace.primaryPermanent === null ? null : moneyPlace.primaryPermanent ? '1' : '0'
        //   }
  
        //   this.$emit("loading", false);
        // } else {
        //   this.$emit("loading", false);
        // }
      },
    },
    watch: {
      updater: {
        handler(val) {
        if (val) {
          this.updateResourceFields();
        }
      },
      immediate: true
      }
    },
  };
  </script>
  
  <style lang="scss" scoped></style>
  