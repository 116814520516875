import { mapState, mapActions, mapGetters, mapMutations } from 'vuex'
import {store,mutations} from './__store'
import moment from 'moment'

export default {  
    computed: {
        ...mapGetters('themes', [
            'getActiveTheme'
        ]),
        ...mapState('flows',['calculation','appData','appId']),
        IsSpouseLivedSamePeriodQuestion () {
            if(this.calculation['application-type'].search('Non Borrowing Spouse') != -1) {
                return 'Has your spouse lived in this address for the same period?'
            } else {
               let person = "Did Person live in this address for the same period?"
            if(store.person1 != null && store.person2 != null){
                   if(this.entityId == this.calculation['primary-applicant-id']){
                    if(store.person2.attributes['first-name'] != null || store.person2.attributes['last-name'] != null){
                        return person = "Did" + " " + this.getFullName(store.person2) + " live in this address for the same period?"
                    } 
                } else {
                    if(store.person1.attributes['first-name'] != null || store.person1.attributes['last-name'] != null){
                        return person = "Did" + " " + this.getFullName(store.person1) + " live in this address for the same period?"
                    } 
               }
            }
               return person
           }
        },
        residentialSituation() {
            return store.residentialSituation
        },
        userOccupancies() {
            return store.userOccupancies
        },
        entities () {
            return this.appData.relationships.people.data
        },
        allAddresses() {
            return store.allAddresses
        },
        vb() {
            return this.$vuetify.breakpoint
        },
        entityId () {
            if(this.$route?.params && this.$route?.params.record == 'people') {
                return this.$route?.params?.record_id
            }
            return null
    
        },
        allAssets() {
            return store.allAssets
        },
        allExpenses() {
            return store.allExpenses
        },
        allLiabilities() {
            return store.allLiabilities
        },
        occupancyResource1(){
            this.resources.occupancies.attributes = store.occupancyAttributes
            this.resources.occupancies.relationships.person = { data: {id: this.appData.relationships['people'].data[0].id, type: 'people'}}
            this.resources.occupancies.relationships.application = { data: {id: this.appId , type:'applications'} }
            return this.resources.occupancies
        },
        occupancyResource2 (){
            this.resources.occupancies2.attributes = store.occupancyAttributes
            this.resources.occupancies2.relationships.person = { data: {id: this.appData.relationships['people'].data[1].id, type: 'people'} }
            this.resources.occupancies2.relationships.application = { data: {id: this.appId , type:'applications'}}
            // console.log('rsources2 shared',{ data: {id: this.appData.relationships['people'].data[1].id, type: 'people'} })
            return this.resources.occupancies2
        }
        // addressResource() {
        //     this.resources.address = store.addressResource
        //     this.resources.address.relationships.person = store.addressRelationships['person']
        //     return this.resources.address
        // }

    },
    methods: { 
        ...mapActions('resource-actions',['getPerson','createAddress', 'getPeople', 'getAddresses']),
        async updateData() {
            // get all occupancies in this app
            let response = this.getPeople({"include" : 'occupancies,assets,expenses,liabilities'})
            let response2 = this.getAddresses() 

            const res = await Promise.all([response, response2]) 

            let occupancies = this.getAllOccupancies(res[0].data)
            let resources = this.getAllResources(res[0].data)
            // let addresses = this.getRequiredAddresses(res[1].data)

            let fiteredOccupancies = []

            if(occupancies.length > 0) {
                occupancies.forEach(async occupancy => {
                    if(occupancy.relationships.person.data.id == this.entityId && occupancy.relationships.address.data != null) {
                        await fiteredOccupancies.push(occupancy)
                    }
                })
            }

            await mutations.setAllAddresses(res[1].data.data)
            await mutations.setAllAssets(resources['assets'])
            await mutations.setAllExpenses(resources['expenses'])
            await mutations.setAllLiabilities(resources['liabilities'])
            await mutations.setUserOccupancies(fiteredOccupancies)

            await this.getOccupancySituation()
            await this.getEndDate()
        },
        getOccupancySituation() {
            let current = false

            if(this.userOccupancies.length > 0) {
                this.userOccupancies.forEach(occupancy => {
                    if(occupancy.attributes['occupancy-situation'] == 'Current') {
                        current = true
                    }
                })
            }

            let payload = {
                'occupancy-situation': current ? 'Previous' : 'Current'
            }

            mutations.setOccupancies(payload)

            return current ? 'Previous' : 'Current'
        },
        getEndDate() {
            let endDate = null
            let dates = []

            if(this.userOccupancies.length > 0) {
                this.userOccupancies.forEach(occupancy => {

                    dates.push({
                        "start-date": this.parseDate(occupancy.attributes['start-date'])
                    })
    
                })
            }

            // Sort date by start-date old to new
            dates.sort((a,b) => {
                return a['start-date'] - b['start-date']
            })

            if(dates.length > 0) {
                endDate = moment(dates[0]['start-date']).subtract(1, "days").format('YYYY-MM-DD')

                let payload = {
                    'end-date': endDate
                }
    
                mutations.setOccupancies(payload)
            }

            return endDate
        },
        isVisible(key){
                
            const {visible} = this

            if(visible.hasOwnProperty(key)){
                return visible[key]
            }

            return true

        },
        getFullName(person){

            if(!person.hasOwnProperty('attributes')) return 'Person'

            let fname = person.attributes['first-name']
            let lname = person.attributes['last-name']

            if(!fname && !lname){
                return 'Person'
            }

            return `${fname ? fname : ''} ${lname ? lname : ''}` 
        },
        hexToRGBA(hex, a = 1){
            return this.$options.filters.hexToRGBA(hex, a)
        },
        parseDate(str) {
            if (str) {
                return new Date(str);
            } else {
                return null;
            }
        },
        saveOccupancy () {
            let data = store.occupancyAttributes
        },
        async getPossibleOwner (data) {
            let person1 = null
            let person2 = null

            if(data == "Yes"){
                
                person1 = store.person1.attributes['first-name'] == null ? 'Person' : store.person1.attributes['first-name'] 
                person2 = store.person2.attributes['first-name'] == null ? 'Person' : store.person2.attributes['first-name'] 
                // console.log('item1',item1)
                let possibleOwner = [
                    {name : person1 , id: [store.person1.id]},
                    {name : person2 , id: [store.person2.id]},
                    {name : person1 + " " +  " and " + " " +  person2 , id: [store.person1.id,store.person2.id]},
                    {name :'Somebody else' , id: null},
                ]
                let payload = {
                    'people': {data: [{id:store.person1.id,type: 'people'},{id:store.person2.id,type: 'people'}]}
                }
                mutations.setOccupancyRelationships(payload)
                // mutations.setAddressRelationships(payload)
                return possibleOwner
            } else {
                let possibleOwner = []

                if(this.calculation['application-type'].search('Non Borrowing Spouse') != -1 ||  this.calculation['application-type'].search('Joint')  != -1 ) {
                    person1 = store.person1.attributes['first-name'] == null ? 'Person' : store.person1.attributes['first-name'] 
                    person2 = store.person2.attributes['first-name'] == null ? 'Person' : store.person2.attributes['first-name'] 
                    // console.log('item1',item1)
                    possibleOwner = [
                        {name : person1 , id: [store.person1.id]},
                        {name : person2 , id: [store.person2.id]},
                        {name : person1 + " " +  " and " + " " +  person2 , id: [store.person1.id,store.person2.id]},
                        {name :'Somebody else' , id: null},
                    ]
                    let payload = {
                        'people': {data: [{id:store.person1.id,type: 'people'},{id:store.person2.id,type: 'people'}]}
                    }
                    mutations.setOccupancyRelationships(payload)
                    // mutations.setAddressRelationships(payload)
                    possibleOwner
                } else {
                    let item = store.person1
                    // console.log('itemss person',item)
                    person1 = item.attributes['first-name'] == null ? 'Person' : item.attributes['first-name'] 
                    possibleOwner = [
                        {name: person1 , id: [item.id]},
                        {name: 'Somebody else', id: null}
                    ]
                    let payload = {
                        'people': {data: [{id:item.id,type: 'people'}]}
                    }
                    mutations.setOccupancyRelationships(payload)
                    // mutations.setAddressRelationships(payload)
                }
                
                return possibleOwner
            }
        },
        async getPerson2 (data) {
            let item = await this.getPerson(data.id)
            mutations.setPerson2(item.data.data)
        },
        getAssetResource (address,people) {
            this.resources.assets.attributes = store.assetAttributes
            this.resources.assets.relationships.people = people 
            this.resources.assets.relationships.address = address
            this.resources.assets.relationships.kind = {data: {id: '10-16',type:'kinds'}}
            this.resources.assets.relationships.application.data.id = this.appId
            return this.resources.assets
        },
        getExpensesResource (people,assets) {
            this.resources.expenses.attributes = store.expenseAttributes
            this.resources.expenses.relationships.people = people 
            this.resources.expenses.relationships.assets = assets
            this.resources.expenses.relationships.kind =  store.expenseRelationships['kinds']
            this.resources.expenses.relationships.application.data.id = this.appId
            return this.resources.expenses
        },
        getliabilityResource () {
            this.resources.liabilities.attributes = store.liabilityAttributes
            this.resources.liabilities.relationships.people = store.liabilitiesRelationships['people'] 
            this.resources.liabilities.relationships.assets = store.liabilitiesRelationships['assets'] 
            this.resources.liabilities.relationships.kind =  store.liabilitiesRelationships['kinds']
            this.resources.liabilities.relationships.expenses =  store.liabilitiesRelationships['expenses']
            this.resources.liabilities.relationships.application.data.id = this.appId
            return this.resources.liabilities
        },
        getExpensesResource2(people){
            this.resources.expenses.attributes = store.expenseAttributes
            this.resources.expenses.relationships.people = people 
            this.resources.expenses.relationships.address = store.expenseRelationships['address'] 
            this.resources.expenses.relationships.kind =  store.expenseRelationships['kinds']
            this.resources.expenses.relationships.application.data.id = this.appId
            return this.resources.expenses
        },
        getPartsResource(){
            this.resources.parts.relationships.kind = store.partsRelationships['kinds']
            this.resources.parts.relationships.application.data.id = this.appId
            return this.resources.parts
        },
        getReferenceResource () {
            this.resources.reference.attributes = store.referenceAttributes
            this.resources.reference.relationships.parentParts =  store.referenceRelationships['parent']
            this.resources.reference.relationships.kind = store.referenceRelationships['kinds']
            this.resources.reference.relationships.application.data.id = this.appId
            return this.resources.reference
        },
        getReferenceAddressResource () {
            this.resources.addressReference.attributes = store.referenceAdressAttributes
            this.resources.addressReference.relationships.references = store.referenceAdressRelationships['references']
            this.resources.addressReference.relationships.application.data.id = this.appId
            return this.resources.addressReference
        },
        getAllOccupancies(data){

            let occupancies = []

            // Filter occupancies by financial objects

            if(data.hasOwnProperty('included')){

                data.included.forEach(item => {

                    if(item.type == 'occupancies'){
                        occupancies.push(item)
                    }

                })

            }

            return occupancies
        },

        getAllResources(data){

            let resources = {
                assets: [],
                liabilities: [],
                expenses: [],
            }

            // Filter occupancies by financial objects

            if(data.hasOwnProperty('included')){

                data.included.forEach(item => {

                    if(resources[item.type]){
                        resources[item.type].push(item)
                    }

                })

            }

            return resources
        },
        
    },
    data () {
        return {
            resources: {
                people: {
                    type: 'people',
                    attributes: {}
                },
                businesses: {
                    type: 'businesses',
                    attributes: {}
                },
                addresses: {
                    type: 'addresses',
                    attributes: {
                        "street-number": null,
                        "street-name": null,
                        "street-type": null,
                        "suburb": null,
                        "state": null,
                        "postcode": null
                    },
                    relationships: {
                        application: {
                            data: { type: "applications", id: null } 
                        },
                        person:{
                            data: []
                        }
                    }
                },
                occupancies: {
                    type: 'occupancies',
                    attributes: {
                        "occupancy-situation": null,
                        "residential-situation": null,
                        "start-date": null,
                        "end-date": null
                    },
                    relationships: {
                        application: {
                            data: { type: "applications", id:  null} 
                        },
                        person: {
                            data: {
                                type: "people",
                                id: null
                            }
                        }
                    }
                },
                occupancies2: {
                    type: 'occupancies',
                    attributes: {
                        "occupancy-situation": null,
                        "residential-situation": null,
                        "start-date": null,
                        "end-date": null
                    },
                    relationships: {
                        application: {
                            data: { type: "applications", id:  null} 
                        },
                        person: {
                            data: {
                                type: "people",
                                id: null
                            }
                        }
                    }
                },
                assets: {
                    type: 'assets',
                    attributes: {},
                    relationships: {
                        application: {
                            data: { type: "applications", id: null } 
                        },
                    }
                },
                expenses: {
                    type: 'expenses',
                    attributes: {},
                    relationships: {
                        application: {
                            data: { type: "applications", id: null } 
                        },
                    }
                },
                liabilities: {
                    type: 'liabilities',
                    attributes: {},
                    relationships: {
                        application: {
                            data: { type: "applications", id: null } 
                        },
                    }
                },
                parts: {
                    type: 'parts',
                    attributes: {},
                    relationships: {
                        application: {
                            data: { type: "applications", id: null } 
                        },
                    }
                },
                reference: {
                    type: 'references',
                    attributes: {},
                    relationships: {
                        application: {
                            data: { type: "applications", id: null } 
                        },
                    }
                },
                addressReference: {
                    type: 'addresses',
                    attributes: {},
                    relationships: {
                        application: {
                            data: { type: "applications", id: null } 
                        },
                    }
                },
                
                
            },
        }
    }
}