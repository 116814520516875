<template>
  <div>
    <v-card :disabled="isDisabled" class="mx-auto" tile flat>
      <v-card-text class="pa-4 pt-0 pa-md-10 pt-md-0">
        <v-container pa-0 fluid>
          <v-row>
            <v-col cols="12">
              <v-data-table
                :loading="false"
                loading-text="Please wait..."
                :items="getRequiredEntities"
                hide-default-footer
                :mobile-breakpoint="0"
                hide-default-header
                class="v-data-table-6 mx-auto">
                <!-- Body -->
                <template v-if="vb.mdAndUp" v-slot:item="{ item, index }">
                  <v-card
                    tile
                    flat
                    class="mb-6 pa-2 rounded-xl"
                    color="light_gray_1"
                  >
                  <div class=" d-flex justify-space-between align-center py-2 px-4 flex-table-row " >
                    <div class="d-flex flex-column">
                      <div class="mr-6">
                        <v-icon size="32">{{ item.type == 'people' ? 'mdi-account' : 'mdi-city'}}</v-icon>
                      </div>
                    </div>

                      <div class="d-flex flex-column">
                        <div class="mr-6 body-1 font-weight-medium">
                          {{ getFriendlyName(item) }}
                        </div>
                      </div>

                      <div class="d-flex flex-column">
                        <div>
                          <v-chip
                            :filter="false"
                            :ripple="false"
                            :style="`background-color: ${hexToRGBA(
                              getActiveTheme.colors.secondary,
                              0.2
                            )}`"
                            class="px-5 font-weight-medium"
                          >
                            {{
                              item.roles.length > 1
                                ? `${item.roles[0]} +${item.roles.length - 1}`
                                : item.roles.length == 1
                                ? item.roles[0]
                                : ""
                            }}
                          </v-chip>
                        </div>
                      </div>

                      <v-spacer></v-spacer>

                      <div class="d-flex justify-end">
                        <v-btn
                          depressed
                          small
                          @click="handleAddDetails(item)"
                          :color="
                            getCompleted.includes(item.id)
                              ? 'success'
                              : 'secondary'
                          "
                        >
                          {{
                            getCompleted.includes(item.id)
                              ? "Completed"
                              : "Add Details"
                          }}
                        </v-btn>
                      </div>
                    </div>
                  </v-card>
                </template>

                <!-- mobile version -->
                <template v-else v-slot:item="{ item, index }">
                    <v-card
                      tile
                      flat
                      class="mb-6 pa-2 rounded-lg"
                      color="light_gray_1"
                    >
                      <v-card-text class="d-flex pb-1">
                        <v-icon size="32">{{ item.type == 'people' ? 'mdi-account' : 'mdi-city'}}</v-icon>
                        <div class="body-1 font-weight-medium pl-1">
                          {{ getFriendlyName(item) }}
                        </div>
                      </v-card-text>
                      <v-card-text class="pt-0">
                        <v-chip
                          :filter="false"
                          :ripple="false"
                          :style="`background-color: ${hexToRGBA(
                            getActiveTheme.colors.secondary,
                            0.2
                          )}`"
                          class="px-5 font-weight-medium"
                        >
                          {{
                            item.roles.length > 1
                              ? `${item.roles[0]} +${item.roles.length - 1}`
                              : item.roles.length == 1
                              ? item.roles[0]
                              : ""
                          }}
                        </v-chip>
                      </v-card-text>
                      <v-card-actions>
                        <v-spacer></v-spacer>
  
                        <v-btn
                          depressed
                          small
                          @click="handleAddDetails(item)"
                          :color="
                            getCompleted.includes(item.id)
                              ? 'success'
                              : 'secondary'
                          "
                        >
                          {{
                            getCompleted.includes(item.id)
                              ? "Completed"
                              : "Add Details"
                          }}
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                </template>

              </v-data-table>
              <v-alert dense outlined type="error" v-if="errors.length > 0">
                <div v-for="msg in errors" v-html="msg"></div>
              </v-alert>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>

    <!-- Modal -->
    <v-dialog
      max-width="844"
      persistent
      scrollable
      v-model="requirementsDialog"
    >
      <v-card flat :disabled="requirementsDialogLoading">
        <v-progress-linear
          color="secondary"
          indeterminate
          stream
          absolute
          top
          v-if="requirementsDialogLoading"
        ></v-progress-linear>

        <v-card-title class="justify-end">
          <v-btn
            icon
            max-width="40"
            max-height="40"
            :style="`background-color: ${hexToRGBA(
              getActiveTheme.colors.secondary,
              0.1
            )}`"
            @click="closeDialog"
            fab
          >
            <v-icon color="secondary">mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text class="px-0 py-0 pb-8">
          <v-carousel
            light
            v-model="carousel"
            :continuous="false"
            :show-arrows="false"
            hide-delimiters
            touchless
            height="auto"
            hide-delimiter-background
          >
            <v-carousel-item v-for="(step, key) in steps" :key="key">
              <v-card flat>
                <v-card-title
                  class="d-flex py-0"
                  :class="vb.mdAndUp ? 'px-8' : 'px-4'"
                >
                  <div>
                    <h4 :class="vb.mdAndUp ? 'font-size-26' : 'font-size-18'">
                      {{ step.title }} | {{ getFriendlyName(selectedEntity) }}
                    </h4>
                    <p :class="vb.mdAndUp ? 'body-1' : 'caption'">
                      {{ step.subtitle }}
                    </p>
                  </div>
                  <v-spacer />
                </v-card-title>

                <v-card-text class="px-0">
                  <template v-if="key == 'information'">

                    <FormEntityInfo
                      :ref="step['form-ref']"
                      :updater="updater"
                      :data="selectedEntity"
                      :addresses="addresses"
                      :livingExpense="getLivingExpense"
                      @loading="handleLoading"
                      @submit="handleFormEntityInfoSubmit"
                    />

                  </template>

                </v-card-text>
              </v-card>
            </v-carousel-item>
          </v-carousel>
        </v-card-text>

        <v-card-actions class="pa-4 pa-md-6 flex-wrap controls">
        <v-btn
          v-if="!isFirstStep"
          color="light_gray_2"
          class="flex-back-button"
          :min-width="140"
          large
          outlined
          @click="handleBack"
          elevation="0"
          nuxt>
          Back
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn
          color="secondary"
          :min-width="140"
          large
          elevation="0"
          @click="handleNext"
          nuxt>
          {{ isLastStep ? "Save" : "Next" }}
        </v-btn>
      </v-card-actions>
      </v-card>
    </v-dialog>

  </div>
</template>

<script>
import { mapActions, mapState, mapMutations } from "vuex";
import subscriptions from "~/components/shared/subscriptions";
import shared from "~/components/v1/submission/MacquarieEntities1/__shared";
import { store, actions, } from "~/components/v1/submission/MacquarieEntities1/__store";
import FormEntityInfo from "~/components/v1/submission/MacquarieEntities1/_FormEntityInfo";

export default {
  components: {
    FormEntityInfo,
  },
  mixins: [subscriptions, shared],
  data() {
    return {
      requirementsDialog: false,
      requirementsDialogLoading: false,

      people: [],
      businesses: [],
      addresses: [],
      expenses: [],

      selectedEntity: null,

      errors: [],

      secondaryApplicantId: null,

      updater: 0,
    };
  },

  computed: {
    ...mapState("flows", [
      "calculation"
    ]),
    vb() {
      return this.$vuetify.breakpoint;
    },
    carousel: {
      get() {
        return store.carousel;
      },
      set(value) {
        actions.setCarousel(value);
      },
    },
    steps() {
      return store.steps;
    },
    isLastStep() {
      if (this.carousel == Object.keys(this.steps).length - 1) {
        return true;
      }

      return false;
    },
    isFirstStep() {
      if (this.carousel == 0) {
        return true;
      }
      return false;
    },
    getCompleted() {  
      let completed = [];

      this.getRequiredEntities.forEach((entity) => {

          let entityFieldsValid = false

          // Businesses logic
          if(entity.type == "businesses") {

            const {isProprietaryCompany, organisationType } = entity.attributes?.lender?.macquarie

            // isCompany
            // if(entity?.relationships?.kind?.data?.id == '5-2') {
              if(isProprietaryCompany) {
                entityFieldsValid = true
              }
            // }

            // isTrust
            if(entity?.relationships?.kind?.data?.id == '5-4') {
              if(organisationType) {
                entityFieldsValid = true
              }
            }

          }

          // // People logic
          if(entity.type == "people") {

            const {
              otherHasCircumstancesChanging, 
              driversLicenceType, 
              beneficiaryType, 
              name
            } = entity.attributes?.lender?.macquarie

            if(this.isTrustApplication ) {

              if(otherHasCircumstancesChanging && driversLicenceType && beneficiaryType && name) {
                entityFieldsValid = true
              }

            } else {

              if(otherHasCircumstancesChanging && driversLicenceType) {
                entityFieldsValid = true
              }

            }

          }

          if(entityFieldsValid) {
              completed.push(entity.id)
          }

      });

      return completed;
    },
    getLoanId() {
      return this.appData?.relationships?.loan?.data?.id;
    },
    primaryApplicantId(){
      return this.calculation['primary-applicant-id']
    },
    getRequiredEntities(){
      let entities = [
        ...this.businesses,
        ...this.people,
      ]

      let applicants = entities.filter(entity => entity?.roles?.includes('Applicant'))

      let directors = entities.filter(entity => entity?.roles?.includes('Director'))
      let partners = entities.filter(entity => entity?.roles?.includes('Partner'))
      let trustees = entities.filter(entity => entity?.roles?.includes('Trustee'))
      // Get only guarantor people
      let guarantors = entities.filter(entity => entity?.roles?.includes('Guarantor') && entity.type == "people")

      return [
        ...applicants,
        ...directors,
        ...guarantors,
        ...partners,
        ...trustees
      ]
    },
    getLivingExpense(){
      return this.expenses.find(expense => expense.relationships.kind?.data?.id == '11-16')
    }
  },
  methods: {
    formUpdater() {
      this.updater = Date.now();
    },
    ...mapActions("resource-actions", [
      "getLoan",
      "getPeople",
      "getBusinesses",
      "getAddresses",
      "updateBusiness",
      "updateLoan",
      "updatePerson",
      "getBusinesses",
      "getExpenses",
      "updateExpense",
    ]),
    async pull() {

      let promise1 = this.getPeople({ include: "parent-parts" });
      let promise2= this.getBusinesses({ include: "parent-parts" });
      let promise3 = this.getAddresses();
      let promise4 = this.getExpenses();

      // Get the secondary applicant id

      const res = await Promise.all([
        promise1,
        promise2,
        promise3,
        promise4
      ]);

      promise1 = res[0];
      promise2 = res[1];
      promise3 = res[2];
      promise4 = res[3];

      this.people = this.getEntityByRoles(promise1.data);
      this.businesses = this.getEntityByRoles(promise2.data);
      this.addresses = promise3.data.data;
      this.expenses = promise4.data.data

    },
    async push() {
      this.checkCompleted();

      let valid = this.errors.length == 0;

      let status = valid ? "valid" : "dirty";

      if (valid) {

        // console.log(this.form);

      }

      return { valid, status, data: {} };
    },
    closeDialog() {
      this.requirementsDialog = false;
      actions.resetStore();
    },
    checkCompleted() {
      this.errors = [];

      if (this.getCompleted.length != this.getRequiredEntities.length) {
        this.errors.push("Please complete all requirements");
      }
    },
    async handleAddDetails(data) {

      this.requirementsDialog = true;
      this.selectedEntity = data;
      this.formUpdater()

    },
    async handleNext() {
      let stepsArray = Object.keys(this.steps);

      switch (stepsArray[this.carousel]) {
        case "information":
          if (this.$refs.EntityInfoForm[0]) {
            await this.$refs.EntityInfoForm[0].validate();
          }
          break;
        default:
          this.carousel++;
          break;
      }
    },
    async handleBack() {
      this.carousel--;
    },
    async handleFormEntityInfoSubmit(payload) {

    this.requirementsDialogLoading = true;

    if(this.isConsumer) {

      const {getLivingExpense} = this

      if(getLivingExpense) {

        this.updateExpense({
          id: getLivingExpense.id,
          type: getLivingExpense.type,
          attributes: {
            lender: {
              macquarie: {
                householdReason: payload.expense
              }
            }
          }
        }).then(res => {
          this.updateLocalResourceAttributes(res.data.data);
        })

      }
      
    }

    if (payload.entity.hasOwnProperty("id")) {

      let res = null
      
      if(payload.entity.type == 'people') {

        res = await this.updatePerson(payload.entity);

      } else {

        res = await this.updateBusiness(payload.entity);
      }

      if(res?.status == 200) {

        this.updateLocalResourceAttributes(res.data.data);

          setTimeout(() => {
            this.requirementsDialogLoading = false;
            this.requirementsDialog = false;
            actions.resetStore();
          }, 600);

      } else {
          this.requirementsDialogLoading = false
      }

    }

    },
    updateLocalResourceAttributes(resource) {
        if(!resource) return;

        let getType = resource.type

        this[getType].forEach( (v, i) => {

            if(v.id == resource.id) {
                this[getType][i]['attributes'] = resource.attributes
            }

        })
    },
    handleLoading(payload) {
        this.requirementsDialogLoading = payload;
    },
  },
};
</script>

<style lang="scss" scoped></style>
