<template>
    <v-card class="mx-auto" tile flat>
        <v-card-text class="pa-4 pt-0 pa-md-10 pt-md-0">
            <ValidationObserver ref="observer" tag="form">
                   <div class="flex-form" v-for="layout in fieldLayout">
                        <template v-for="(key, index) in fieldLayoutMaker2(layout, resources.loan.attributes)">

                            <div class="flex-field" :key="key + index" v-if="key == '__spacer'"></div>

                            <div class="flex-field" :key="key + index" v-else>

                                <ValidationProvider
                                    v-slot="{ errors }"
                                    :name="labels[key]"
                                    :rules="getRules(key)"
                                    :vid="key">

                                    <v-select
                                        v-if="isFieldType('picklist', key)"
                                        v-model="resources.loan.attributes[key]"
                                        :items="getOptions(key)"
                                        :error-messages="errors"
                                        color="secondary"
                                        :label="labels[key]"
                                    ></v-select>

                                    <ButtonGroup1 
                                        v-else-if="isBtnGroup(key)"
                                        v-model="resources.loan.attributes[key]"
                                        :error-messages="errors"
                                        :options="key == 'submission-required' ? ['Draft', 'Credit'] : ['Yes', 'No']"
                                        :label="labels[key]" />

                                    <NodifiMoney
                                        v-else-if="isNumber(key)"
                                        v-model="resources.loan.attributes[key]"
                                        :label="labels[key]"
                                        :errors="errors" />

                                    <v-text-field
                                        v-else
                                        v-model="resources.loan.attributes[key]"
                                        :label="labels[key]"
                                        :error-messages="errors"
                                        color="secondary"
                                    ></v-text-field> 

                                </ValidationProvider>

                                

                            </div>
                        </template> 
                    </div>
            </ValidationObserver>

            
            <div class="pb-12"> 
                <!-- spacer -->
            </div>

            <div class="body-1 font-weight-bold mb-6">Broker Declaration</div>

            <ValidationObserver ref="observer2" tag="form">

                <template v-for="(term, index) in terms">
                    <ValidationProvider
                        v-slot="{ errors }"
                        :name="term.attributes.content"
                        rules="required"
                        :vid="term.attributes.content">    

                        <v-checkbox
                            v-model="checkboxes"
                            :value="term.attributes.content"
                            color="secondary"
                        >
                            <template v-slot:label>
                                <div v-html="term.attributes.content"></div>
                            </template>
                        </v-checkbox>

                </ValidationProvider>
                </template>

                <!-- {{ checkboxes }} -->

            </ValidationObserver>

            <v-alert dense outlined type="error" v-if="errors.length > 0">
                <div v-for="msg in errors" v-html="msg"></div>
            </v-alert>

        </v-card-text>
    </v-card>
</template>

<script>
import { mapActions, mapState, mapMutations } from 'vuex'
import helpers from '~/plugins/helpers'
import subscriptions from '~/components/shared/subscriptions'
import lenderFields from '~/components/v1/submission/Customisations1/__lenders'
import ButtonGroup1 from '~/components/base/ButtonGroup1'
import NodifiMoney from '~/components/base/NodifiMoney'

export default {
    name: 'LenderPepperCustomizations1',
    components: { ButtonGroup1 , NodifiMoney},
    mixins: [subscriptions, helpers],
    data() {
        return {
            resources: {
                loan: {
                    type: 'loans',
                    id: null,
                    attributes: {
                        // see lenderFields
                        "lender-pepper-risk-tier": "",
                        "submission-required": "Draft",
                        "actual-term-months": "",
                        "actual-payment-frequency": "",
                        "customer-discount-rate": "",
                        "requested-balloon": "",
                        "actual-broker-fee": "",
                        "broker-declaration" : "",
                        "financing-insurance": "No"
                    }
                }

            },
            options: {
                'actual-term-months': [12,24,36,48,60,72,84],
                'actual-payment-frequency': ['Monthly', 'Fortnightly', 'Weekly', 'Annual'],
                'lender-pepper-risk-tier': ['A', 'B', 'C']
            },
            labels: {
                "lender-pepper-risk-tier": "Risk Tier",
                "submission-required": "Submission Target",
                "actual-term-months": "Loan Term",
                "actual-payment-frequency": "Payment Frequency",
                "customer-discount-rate": "Customer Rate",
                "requested-balloon": "Balloon Amount",
                "actual-broker-fee": "Origination Fee",
                "financing-insurance": "Financing Insurance?",
            },
            validation_rules: {
                type: "loans",
                attributes: {
                    "actual-term-months": "required|in:12,24,36,48,60,72,84",
                    "actual-payment-frequency": "required|in:Monthly,Fortnightly,Weekly,Annual",
                    "lender-pepper-risk-tier": "required|in:A,B,C",
                    "submission-required": "",
                    "customer-discount-rate": "required|between:0,100",
                    "requested-balloon": "required|between:0,99999999999",
                    "actual-broker-fee": "required|between:0,99999999999",
                    "financing-insurance": "",
                }
            },
            fieldLayout: [
                // see lenderFields
            ],
            terms: [],
            people: [],
            checkboxes: [],
            errors: [],

        }
    },
    computed: {
        ...mapState({
            appId: state => state.flows.appId,
            loanData: state => state.flows.loanData,
            termGroup: state => state.flows.termGroup,
            commitments: state => state.flows.commitments,
        }),
        getLoanId(){
            return this.appData.relationships.loan.data.id
        },
        ...mapState('auth-actions', [
                'user'
        ]),
        isCommitmentExists(){
            let exists = false

            if(this.commitments && this.terms.length > 0){

                return true

            }

            return exists
        },
    },
    methods: {
        ...mapActions('resource-actions', [
            'getLoan',
            'getTerms',
            'updateLoan',
            'getPeople',
            'getCommitments',
            'createCommitments',
        ]),
        ...mapMutations('flows', [
            'setCommitments'
        ]),
        async pull() {


            let promise1 =  this.getTerms(this.termGroup)
            let promise2 = this.getPeople()
            let promise3 = this.getLoan(this.getLoanId)

            const res = await Promise.all([
                promise1, 
                promise2,
                promise3
            ]) 

            promise1 = res[0]
            promise2 = res[1]
            promise3 = res[2]

            this.fieldLayout = {
                ...lenderFields['pepper']['layout']
            }

            if(promise1.status == 200) {

                // Only get the pepper-broker-declaration-001 term
                if(promise1.data.data.length){
                    promise1.data.data.forEach( term => {
                        if(term.attributes["slug"] == "pepper-broker-declaration-001") {
                            this.terms = promise1.data.data = [term]
                        }
                    })
                }


                
            }

            if(promise2.status == 200) {
                this.people = promise2.data.data
            }

            if(promise2.status == 200) {
                this.resources.loan = this.resourceFieldPicker(this.resources.loan, promise3.data.data)

                // Re-add Temp Fields for clarification EB-57
                this.resources.loan.attributes = {
                    ...this.resources.loan.attributes,
                    "submission-required": "Draft",
                    "financing-insurance": "No"
                }
            }

            // Check checkboxes if commitment exists

            if(this.isCommitmentExists) {

                let termsArray = []

                this.terms.forEach(term => {
                    termsArray.push(term.attributes.content)
                });

                this.checkboxes = termsArray

            }

        },

        async push() {

            const observer = this.$refs.observer
            const {valid, status} = await this.validateFormData()
            let loanRes = null

            if(valid) {
                
                const {loan} = this.resources
                    
                // Exclude resources.relationships and other stuff

                let payload = {
                    type: loan.type,
                    id: this.getLoanId,
                    attributes: this.excludeInvalids(loan.attributes, observer.fields)
                }

                loanRes = await this.updateLoan(payload)

            }

            const data = await this.appDataHelper([loanRes])


            // Validate Checkboxes

            this.checkCompleted()
    
            let allValid = this.errors.length == 0 && valid

            let allStatus = allValid ?  'valid' : 'dirty'

            if(allValid && !this.isCommitmentExists) {

                let userId = this.appData.relationships.owner?.data?.id;

                if(this.appData.attributes.target == 'Assister') {
                    userId = this.user?.id;
                }

                let termsData = this.terms.map(term => {
                    return {
                        id: term.id,
                        type: 'terms'
                    }
                });

                let peopleData = this.people.map(person => {
                    return {
                        id: person.id,
                        type: 'people'
                    }
                });

                let payload = {  
                    "type": "commitments",
                    "attributes": {},
                    "relationships": {
                        "application": {
                            "data": {
                                "type":"applications",
                                "id": this.appId
                            },

                        },
                        "committed-users": {
                            "data": [
                                {
                                    "type": "users",
                                    "id": userId
                                }
                            ]
                        },
                        "committed-people": {
                            "data": peopleData // all people in  this app
                        },
                        "terms" : {
                            "data": termsData // all the terms
                        }
                    }
                }

                let res = await this.createCommitments(payload)

                this.setCommitments(res.data.data)

                return {valid: allValid, status: allStatus, data}

            } else {

                return {valid: allValid, status: allStatus, data}
            }

        },

        isBtnGroup(key){

            let keys = [
                "submission-required",
                "financing-insurance"
            ]

        return keys.includes(key)

        },
        isIncluded(field){

            const {included} = this

            if(included.hasOwnProperty(field)) {
                return included[field]
            }

            return true
            },
        isReadOnly(key){

            const {readonly} = this

            if(readonly.hasOwnProperty(key)){
                return readonly[key]
            }

            return false

        },
        isNumber(key){
            return [
                "requested-balloon",
                "actual-broker-fee",
            ].includes(key)
        },
        checkCompleted(){
            this.errors = []

            if(this.checkboxes.length != this.terms.length) {
                this.errors.push('Please accept all terms to proceed')
            } 
        },
    },
    watch: {
        checkboxes(val) {
            if(val.length == this.terms.length) {
                this.errors = []
            }
        }
    }
}
</script>

<style>
    .debug {
        border: 1px solid red;
    }
</style>

