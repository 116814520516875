<template>

    <v-card flat tile :disabled="isDisabled">
        <v-card-title class="px-4 px-md-10 pt-0 d-flex align-center" :class="{'flex-column': vb.mdAndDown}">

            <div>
                <p class="flex__step-header-title ma-0">{{ heading }}</p>
            </div>
            
            <v-spacer />

            <InviteApplicantToEdit
                v-if="showInviteApplicantToEdit"
                :is-flow="true"
                label="Invite Applicant"
                label-type="button" />
            <div class="mx-1"> <!-- spacer --> </div>
            <div v-for="component in subComponents">
                <component :is="component.name" :class="`flex__component-${component.name}`" :settings="component" />
            </div>

            <!-- <div v-if="vb.lgAndUp" class="d-flex">
                

            </div>
            <template v-else>
                <v-menu
                v-if="showMenu"
                bottom
                left
                offset-y
                min-width="300"
                rounded="lg"
                content-class="context-menu"
                class="user-menu px-4 mx-4">
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn icon v-bind="attrs" v-on="on" >
                            <v-icon>mdi-dots-vertical</v-icon>
                        </v-btn>
                    </template>
                    <v-list>
                        <v-list-item v-if="showInviteApplicantToEdit">
                            <v-list-item-title>
                                <InviteApplicantToEdit
                                    :is-flow="true"
                                    label="Invite Applicant"
                                    label-type="label" />
                            </v-list-item-title>
                        </v-list-item>
                        <v-list-item v-for="component in subComponents" :key="component.name">
                            <v-list-item-title>
                                <component :is="component.name" button-type="text" :class="`flex__component-${component.name}`" color="white" min-width="100%" :settings="component" />
                            </v-list-item-title>
                        </v-list-item>
                    </v-list>
                </v-menu>
            </template> -->

        </v-card-title>
    </v-card>

</template>

<script>

    import block from '~/components/shared/block' 
    import InviteApplicantToEdit from '~/components/base/InviteApplicantEditor'
    import EditButton1 from '~/components/v1/EditButton1'
    import { mapState, mapGetters } from 'vuex'

    export default {
        mixins: [
            block, 
        ],
        components: { InviteApplicantToEdit, EditButton1 },
        props: {

        },
        mounted(){

            // console.log(this.blockSettings)

        },
        computed: {
            ...mapState({
                stepableMode: state => state.subscriptions.stepableMode,
                steps: state => state.flows.steps,
                activeStep: state => parseInt(state.flows.activeStep)
            }),
            ...mapGetters('auth-actions', [
                'getUserSetting'
            ]),
            showInviteApplicantToEdit(){

                const {blockSettings} = this

                if(blockSettings.hasOwnProperty('showInviteApplicantButton')) {
                    if(blockSettings.showInviteApplicantButton == 1) {
                        return true
                    }
                }

                return false
            },
            showMenu(){

                if(this.showInviteApplicantToEdit || this.subComponents.length ) {
                    return true
                }

                return false
            },
            isDisabled(){
                return this.stepableMode == 'disabled'
            },
            heading(){

                const {steps, activeStep} = this

                if( steps[activeStep - 1] && steps[activeStep - 1].name ) {
                    return steps[activeStep - 1].name
                }

                return ''
            },
            vb() {
                return this.$vuetify.breakpoint
            }

        },
        
    }
</script>