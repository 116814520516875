<template>
    <div>

        <div>
            <v-btn
                color="primary"
                large
                depressed
                :disabled="selectedCount <= 1 || selectedCount > 3"
                @click="handleClick"
            >
                <slot name="button">
                    <span>
                        Compare Lenders
                    </span>
                </slot>
            </v-btn>
        </div>

        <!-- Compare Modal -->
        <v-dialog
            max-width="950"
            persistent
            scrollable
            v-model="dialog">

            <!-- # md and up -->
            <v-card v-if="vb.mdAndUp" flat :disabled="disabled">

                <v-toolbar flat class="pr-4">
                    <v-spacer></v-spacer>
                    <v-btn icon @click="dialog = false" fab>
                        <v-icon color="secondary">mdi-close</v-icon>
                    </v-btn>
                </v-toolbar>
                
                <v-card-title class="d-flex justify-start px-4 pl-8 py-0">
                    <v-row class="mx-0 px-0">

                        <v-col class="pa-0 d-flex align-center" :md="selectedCount == 3 ? '3' : '4'"><h3 class="font-weight-bold">Compare Lenders</h3></v-col>
                        
                        <v-col class="mx-0 px-0 pt-0" :md="selectedCount == 3 ? '3' : '4'" v-for="lender in selectedLenders" :key="lender.name">

                            <div v-if="hideLender" class="title text-center font-weight-bold">
                                {{ lender.alias }}
                            </div>
                            <div v-else>
                                <v-img 
                                    max-width="100"
                                    class="mx-auto"
                                    :src="getLenderLogo(lender['logo-class'])"
                                    position="center center" 
                                    contain>
                                </v-img>
                            </div>


                        </v-col>
                    </v-row>
                </v-card-title>

                <v-card-text class="px-4 pt-0 pb-10"> 

                    <div class="d-flex justify-start px-4 px-md-6 py-0 light_gray_1"> 
                        <v-row>
                            <v-col :md="selectedCount == 3 ? '3' : '4'"><div class="font-weight-bold subtitle-2">Requested Amount</div> </v-col>
                            <v-col :md="selectedCount == 3 ? '3' : '4'" v-for="lender in selectedLenders" :key="lender.name">
                                <div class="text-center body-2">
                                    {{ requiredAmount }}
                                </div>
                            </v-col>
                        </v-row>
                    </div>

                    <div class="d-flex justify-start mt-1 px-4 px-md-6 py-0"> 
                        <v-row>
                            <v-col :md="selectedCount == 3 ? '3' : '4'"><div class="font-weight-bold subtitle-2">Origination Fee</div> </v-col>
                            <v-col :md="selectedCount == 3 ? '3' : '4'" v-for="lender in selectedLenders" :key="lender.name">
                                <div class="text-center body-2">
                                    {{ lender['actual-payment']['broker-fee'] | currency }}
                                </div>
                            </v-col>
                        </v-row>
                    </div>

                    <div class="d-flex justify-start mt-1 px-4 px-md-6 py-0 light_gray_1"> 
                        <v-row>
                            <v-col :md="selectedCount == 3 ? '3' : '4'"><div class="font-weight-bold subtitle-2">Bank Setup Fees</div> </v-col>
                            <v-col :md="selectedCount == 3 ? '3' : '4'" v-for="lender in selectedLenders" :key="lender.name">
                                <div class="text-center body-2">
                                    {{ lender['actual-payment']['bank-fees'] | currency }}
                                </div>
                            </v-col>
                        </v-row>
                    </div>

                    <div class="d-flex justify-start mt-1 px-4 px-md-6 py-0"> 
                        <v-row>
                            <v-col :md="selectedCount == 3 ? '3' : '4'"><div class="font-weight-bold subtitle-2">Amount Financed</div> </v-col>
                            <v-col :md="selectedCount == 3 ? '3' : '4'" v-for="lender in selectedLenders" :key="lender.name">
                                <div class="text-center body-2">
                                    {{ lender['actual-payment']['naf'] | currency }}
                                </div>
                            </v-col>
                        </v-row>
                    </div>
                    <div class="d-flex justify-start mt-1 px-4 py-3 light_gray_1"> 
                        <v-row>
                            <v-col :md="selectedCount == 3 ? '3' : '4'"><div class="font-weight-bold subtitle-2"></div> </v-col>
                            <v-col :md="selectedCount == 3 ? '3' : '4'" v-for="lender in selectedLenders" :key="lender.name">
                                <div class="text-center body-2">
                                </div>
                            </v-col>
                        </v-row>
                    </div>

                    <div class="d-flex justify-start mt-1 px-4 px-md-6 py-0"> 
                        <v-row>
                            <v-col :md="selectedCount == 3 ? '3' : '4'"><div class="font-weight-bold subtitle-2">Loan Term</div> </v-col>
                            <v-col :md="selectedCount == 3 ? '3' : '4'" v-for="lender in selectedLenders" :key="lender.name">
                                <div class="text-center body-2">
                                    {{ lender['actual-payment']['term-in-months'] }} Months
                                </div>
                            </v-col>
                        </v-row>
                    </div>

                    <div class="d-flex justify-start mt-1 px-4 px-md-6 py-0 light_gray_1"> 
                        <v-row>
                            <v-col :md="selectedCount == 3 ? '3' : '4'"><div class="font-weight-bold subtitle-2">Weekly Payment</div> </v-col>
                            <v-col :md="selectedCount == 3 ? '3' : '4'" v-for="lender in selectedLenders" :key="lender.name">
                                <div class="text-center body-2">
                                    {{ ((lender['actual-payment']['monthly-payment'] * 12) / 52) | currency }}
                                </div>
                            </v-col>
                        </v-row>
                    </div>

                    <div class="d-flex justify-start mt-1 px-4 px-md-6"> 
                        <v-row>
                            <v-col :md="selectedCount == 3 ? '3' : '4'"><div class="font-weight-bold subtitle-2"></div> </v-col>
                            <v-col :md="selectedCount == 3 ? '3' : '4'" v-for="lender in selectedLenders" :key="lender.name">
                                <div class="text-center body-2">
                                    <!-- <v-btn 
                                        :color="selectedLender === lender.name ? 'secondary is-disabled' : 'secondary'"
                                        :disabled="disabled"
                                        @click="setSelected(lender.name, lender)"
                                        small
                                        depressed>{{ selectedLender === lender.name ? 'Applied' : 'Apply Now' }}
                                    </v-btn> -->
                                </div>
                            </v-col>
                        </v-row>
                    </div>

                    <v-row class="px-2">
                        <v-col cols="12" class="mt-2">
                        
                            <v-sheet max-width="854" class="mx-auto">
                                <p class="caption text-center gray">*Terms and conditions apply. Finance for credit approved purchaser's only. The finance offer is for amounts and terms shown above. The comparison rate is based on the amounts and terms above at the annual percentage rate shown above. The amount of credit provided to you and the term of the loan may be different to this WARNING. The comparison rate only applies to the example given and may not include all fees and charges. Different terms, fees or other loan amounts might result in a different comparison rate. Credit Asssistance is provided through Online Loans ABN 58 610 067 715 and/or its panel of lenders. Australian Credit Licence Number 484982.</p>
                            </v-sheet>

                        </v-col>
                    </v-row>
                    
                </v-card-text>

            </v-card>

            <!-- # mobile responsive -->
            <v-card v-else flat>

                <v-card-title class="px-6 pt-4 pb-0">
                    <v-spacer/>
                    <h4 class="font-weight-bold ml-8">Compare Lenders</h4>
                    <v-spacer/>
                    <v-btn icon @click="dialog = false">
                        <v-icon color="secondary">mdi-close</v-icon>
                    </v-btn>
                </v-card-title>

                <div class="d-flex mx-0 px-0 mt-4">
                    <v-row class="mx-0 px-0 mt-4 mb-0 pb-0">
                        <v-col class="mx-0 px-0 pt-0" :md="selectedCount == 3 ? '4' : '6'" v-for="lender in selectedLenders" :key="lender.name">
                            <div v-if="hideLender" class="title text-center font-weight-bold">
                                {{ lender.alias }}
                            </div>
                            <div v-else>
                                <v-img 
                                    max-width="100"
                                    max-height="20"
                                    class="mx-auto"
                                    :src="getLenderLogo(lender['logo-class'])"
                                    position="center center" 
                                    contain>
                                </v-img>
                            </div>
                        </v-col>
                    </v-row>
                </div>

                <v-card-text class="px-4"> 
                    <div class="d-flex flex-column text-center pa-2 light_gray_1"> 
                        <v-row class="mt-2">
                            <v-col cols="12" class="pt-0 font-weight-bold subtitle-2">Requested Amount</v-col> 
                            <v-col class="mx-0 px-0 py-0 my-0" :md="selectedCount == 3 ? '4' : '6'" v-for="lender in selectedLenders" :key="lender.name">
                                <div class="text-center body-2">
                                    <p>{{ requiredAmount }}</p>
                                </div>
                            </v-col>
                        </v-row>
                    </div>

                    <div class="d-flex flex-column text-center pa-2"> 
                        <v-row class="mt-2 pt-1">
                            <v-col cols="12" class="pt-0 font-weight-bold subtitle-2">Origination Fee</v-col> 
                            <v-col class="mx-0 px-0 py-0 my-0" :md="selectedCount == 3 ? '4' : '6'" v-for="lender in selectedLenders" :key="lender.name">
                                <div class="text-center body-2">
                                    <p>{{ lender['actual-payment']['broker-fee'] | currency }}</p>
                                </div>
                            </v-col>
                        </v-row>
                    </div>

                    <div class="d-flex flex-column text-center pa-2 light_gray_1"> 
                        <v-row class="mt-2 pt-1">
                            <v-col cols="12" class="pt-0 font-weight-bold subtitle-2">Bank Setup Fees</v-col> 
                            <v-col class="mx-0 px-0 py-0 my-0" :md="selectedCount == 3 ? '4' : '6'" v-for="lender in selectedLenders" :key="lender.name">
                                <div class="text-center body-2">
                                    <p>{{ lender['actual-payment']['bank-fees'] | currency }}</p>
                                </div>
                            </v-col>
                        </v-row>
                    </div>

                    <div class="d-flex flex-column text-center pa-2"> 
                        <v-row class="mt-2 pt-1">
                            <v-col cols="12" class="pt-0 font-weight-bold subtitle-2">Amount Financed</v-col> 
                            <v-col class="mx-0 px-0 py-0 my-0" :md="selectedCount == 3 ? '4' : '6'" v-for="lender in selectedLenders" :key="lender.name">
                                <div class="text-center body-2">
                                    <p>{{ lender['actual-payment']['naf'] | currency }}</p>
                                </div>
                            </v-col>
                        </v-row>
                    </div>

                    <div class="d-flex flex-column text-center pa-2 light_gray_1 py-8"> 
                        <v-row class="mt-2 pt-1">
                            <v-col cols="12" class="pt-0 font-weight-bold subtitle-2"></v-col> 
                            <v-col class="mx-0 px-0 py-0 my-0" :md="selectedCount == 3 ? '4' : '6'" v-for="lender in selectedLenders" :key="lender.name">
                                <div class="text-center body-2">
                                </div>
                            </v-col>
                        </v-row>
                    </div>

                    <div class="d-flex flex-column text-center pa-2"> 
                        <v-row class="mt-2 pt-1">
                            <v-col cols="12" class="pt-0 font-weight-bold subtitle-2">Loan Term</v-col> 
                            <v-col class="mx-0 px-0 py-0 my-0" :md="selectedCount == 3 ? '4' : '6'" v-for="lender in selectedLenders" :key="lender.name">
                                <div class="text-center body-2">
                                    <p>{{ lender['actual-payment']['term-in-months'] }} Months</p>
                                </div>
                            </v-col>
                        </v-row>
                    </div>

                    <div class="d-flex flex-column text-center pa-2 light_gray_1"> 
                        <v-row class="mt-2 pt-1">
                            <v-col cols="12" class="pt-0 font-weight-bold subtitle-2">Weekly Payment</v-col> 
                            <v-col class="mx-0 px-0 py-0 my-0" :md="selectedCount == 3 ? '4' : '6'" v-for="lender in selectedLenders" :key="lender.name">
                                <div class="text-center body-2">
                                    <p>{{ ((lender['actual-payment']['monthly-payment'] * 12 ) / 52) | currency }}</p>
                                </div>
                            </v-col>
                        </v-row>
                    </div>

                    <div class="d-flex flex-column text-center pa-2"> 
                        <v-row class="mt-2 pt-1">
                            <v-col cols="12" class="pt-0 font-weight-bold subtitle-2"></v-col> 
                            <v-col class="mx-0 px-0 py-0 my-0" :md="selectedCount == 3 ? '4' : '6'" v-for="lender in selectedLenders" :key="lender.name">
                                <div class="text-center body-2">
                                    <p>
                                        <!-- <v-btn 
                                            :color="selectedLender === lender.name ? 'secondary is-disabled' : 'secondary'"
                                            :disabled="disabled"
                                            @click="setSelected(lender.name, lender)"
                                            small
                                            depressed>{{ selectedLender === lender.name ? 'Applied' : 'Apply' }}
                                        </v-btn> -->
                                    </p>
                                </div>
                            </v-col>
                        </v-row>
                    </div>

                    <v-row class="px-2">
                        <v-col cols="12" class="mt-0">
                        
                            <v-sheet max-width="854" class="mx-auto">
                                <p class="caption text-center gray">*Terms and conditions apply. Finance for credit approved purchaser's only. The finance offer is for amounts and terms shown above. The comparison rate is based on the amounts and terms above at the annual percentage rate shown above. The amount of credit provided to you and the term of the loan may be different to this WARNING. The comparison rate only applies to the example given and may not include all fees and charges. Different terms, fees or other loan amounts might result in a different comparison rate. Credit Asssistance is provided through Online Loans ABN 58 610 067 715 and/or its panel of lenders. Australian Credit Licence Number 484982.</p>
                            </v-sheet>

                        </v-col>
                    </v-row>

                </v-card-text>

            </v-card>

        </v-dialog>

    </div>
    
</template>
<script>

    import subscriptions from '~/components/shared/subscriptions'

    export default {
        mixins: [subscriptions],
        props: {
            selectedLenders: {
                type: Array,
                default: () => []
            },
            selectedCount: {
                type: Number,
                default: 0
            },
            requiredAmount: {
                type: String,
                default: ''
            },
            selectedLender: {
                type: String,
                default: ''
            },
            disabled: {
                type: Boolean,
                default: false
            },
            hideLender: {
                type: Boolean,
                default: false
            }
        },

        computed: {
            vb() {
                return this.$vuetify.breakpoint
            }
        },

        data() {
            return {
                dialog: false,
            }
        },

        methods: {
            setSelected(name, lender) {

            },
            handleClick() {
                this.dialog = true
            },
            getLenderLogo(name){

                let lender = require(`~/assets/images/logo/placeholder.png`)

                try {

                    lender = require(`~/assets/images/logo/cropped/${name}.png`)

                    return lender

                } catch (error) {

                    console.log('Ooops!..', error)

                    return lender

                }

            },
        }

    }

</script>

<style lang="scss" scoped>
    .theme--dark.v-btn.v-btn--disabled.v-btn--has-bg {
        background-color: var(--v-light_gray_1-base) !important; 
    }
</style>