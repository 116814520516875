<template>
    <v-card class="mx-auto" tile flat :disabled="isDisabled">
        <v-card-text class="pa-4 pt-0 pa-md-10 pt-md-0 mx-2">
            <v-container pa-0 fluid>

                <ValidationObserver ref="observer" tag="form">
                    <v-row>                        
                        <v-col cols="12" md="4" class="mb-4 overflow-hidden pt-0">

                            <div class="body-2 font-weight-medium mt-3 mb-2">Bank Statements Doc ID</div>
                            <ValidationProvider
                                v-slot="{ errors }"
                                name="Bank Statements Doc ID"
                                rules="required|length:9"
                                vid="bankStatementsDocId">

                                    <v-text-field
                                        v-model="resources.applications.attributes['lender']['moneyPlace']['bankStatementsDocId']"
                                        class="ma-0 pa-0"
                                        :error-messages="errors">
                                    </v-text-field>

                            </ValidationProvider>

                        </v-col>

                    </v-row>

                </ValidationObserver>
            </v-container>
        </v-card-text>

        <!-- This will replace Next button in StepIncrementer1  -->
        <Portal to="step-incrementer-next">
            <v-btn 
                v-if="authUserCanSubmitApp"
                color="secondary" 
                large depressed nuxt 
                :min-width="btnWidth" 
                :disabled="loading || isDisabled"
                @click="submitApplication" 
                class="ml-3">
                Submit
            </v-btn>
            <!-- Auth user not allowed to submit -->
            <div v-else></div>
        </Portal>

        <v-overlay class="ma-0 pa-0" :value="overlay" opacity=".2">
            <div class="spinner">      
                <div class="flex-brand-loader">
                    <div class="flex-brand d-block">
                        <loaderImage />
                    </div>
                </div>
            </div>
        </v-overlay>
    </v-card>
</template>

<script>
    import loaderImage from '~/components/base/loaderImage'
    import subscriptions from '~/components/shared/subscriptions'
    import { mapState, mapGetters, mapActions, mapMutations } from 'vuex'
    export default {
        components: {
            loaderImage,
        },
        mixins: [subscriptions],
        data(){
            return {
                isSameLender: false,
                confirmDialogTitle: '',
                confirmDialog: false,
                lenderSubmissions: [],
                overlay: false,
                validComponentData: true,
                resources: {
                    applications: {
                        type: "applications",
                        id: null,
                        attributes: {
                            lender: {
                                "moneyPlace": {
                                    "bankStatementsDocId": "",
                                }
                            }
                        }
                    }
                },
            }
        },
        computed: {
            ...mapState({
                appId: state => state.flows.appId,
                appGuid: state => state.flows.appGuid,
                loanData: state => state.flows.loanData
            }),
            ...mapState('statuses', [
                'stepStatuses'
            ]),
            ...mapGetters('kinds', [
                'kindsOfApplicant'
            ]),
            ...mapGetters('statuses', [
                'getCurrentFlowStatus'
            ]),
            ...mapGetters('flows', [
                'redirectPaths'
            ]),
            ...mapState(['appConfig']),
            getLoanType(){
                return this.loanData?.attributes['loan-type']
            },
            getSelectedLender(){
                return this.loanData?.attributes['selected-lender-quote-algorithm-slug']
            },
            btnWidth () {
                switch (this.$vuetify.breakpoint.name) {
                    case 'xs': return 0
                    case 'lg': 
                    case 'xl':
                    case 'md': 
                    case 'sm': return 140
                }
            },
            getLoanId(){
                return this.appData?.relationships?.loan?.data?.id
            },
            activeLenderSubmissions() {
                let { lenderSubmissions } = this
                let active = null
                if( lenderSubmissions.length > 0 ) {
                    lenderSubmissions.forEach(lenderSub => {
                        if( lenderSub.attributes.active ) {
                            active = lenderSub
                        }
                    })
                }
                return active
            },       
        },
        mounted(){
        },
        methods: {
            ...mapActions('resource-actions', [
                'getLoan',
                'updateLoan',
                'getPeopleByResource',
                'getLender',
                'runLenderSubmitService',
                'getApplication',
                'updateApplication'
            ]),
            ...mapActions('statuses', [
                'completeFlowStatus'
            ]),
            ...mapActions('flows', [
                'selectFinalStep',
                'selectNextStep',
                'selectFinalStepNoFlowComplete'
            ]),
            ...mapMutations('utils', [
                'toggleAlert'
            ]),
            async pull() {
                let requests = [
                    this.getApplication({id: this.appId})
                ]
                
                // get lender submissions
                let lenderSub = await this.getPeopleByResource({resource: 'lender-submissions'})
                if(lenderSub.status === 200) this.lenderSubmissions = lenderSub.data.data

                Promise.all(requests).then( data => {
                    
                    let applications = data.find( dat => {
                        return dat.data.data.type === 'applications'
                    })

                    this.resources.applications = this.resourceFieldPicker(this.resources.applications, applications.data.data)
                    return data
                })
            },
            async push() {
                
                const {valid, status} = await this.validateFormData()
                return {valid, status}
                
            },
            async submitApplication() {
                try {
                    this.loading = true
                    const { valid } = await this.validateFormData()
                    
                    if(valid) {
                        const {applications} = this.resources

                        await this.updateApplication({
                            id: this.appId,
                            type: "applications",
                            attributes: {
                                ...applications.attributes
                            }
                        })
                        await this.lenderSubmit()
                    }
                    this.loading = false
                } catch (err) {
                    this.loading = false
                    console.log(err)
                }
            },
            async lenderSubmit() {
                try {
                    let res = await this.runLenderSubmitService({
                        "application": this.appGuid, 
                    })
                    if(res.status === 200) {
                        
                        if(res.data.message == 'Lender Submission Failed') {
                            await this.selectFinalStepNoFlowComplete()
                        } else {
                            await this.selectFinalStep({flowStatus: this.getCurrentFlowStatus.id})
                        }
                    }
                } catch (err) {
                    this.loading = false
                }
            },
        },
        watch: {
        }
    
    }
</script>
<style lang="scss">
</style>