<template>
    <v-card class="mx-auto" tile flat>
        <v-card-text class="pa-4 pt-0 pa-md-10 pt-md-0">

            <ValidationObserver ref="observer" tag="form">
                <div v-if="hasBalloonPayment && isPlenti" class="mb-6">
                    <div class="body-1 font-weight-bold mb-2">Balloon payment</div>
                    <div class="flex-form" v-for="layout in fieldLayout">
                            <template v-for="(key, index) in fieldLayoutMaker2(layout, resources.loan.attributes)">
                            
                            <div class="flex-field" :key="key + index" v-if="key == '__spacer'"></div>
                            
                            <div class="flex-field" :key="key + index" v-else>
                                
                                <ValidationProvider
                                    v-slot="{ errors }"
                                    :name="labels[key]"
                                    :hidden="hiddens[key]"
                                    :rules="getRules(key)"
                                    :vid="key">               

                                    <v-select
                                        v-if="isFieldType('picklist', key)"
                                        v-model="resources.loan.attributes[key]"
                                        :items="getOptions(key)"
                                        item-text="text"
                                        item-value="value"
                                        :error-messages="errors"
                                        color="secondary"
                                        :prefix="prefix[key]"
                                        :label="labels[key]"
                                    ></v-select>

                                    <v-text-field
                                        v-else
                                        v-model="resources.loan.attributes[key]"
                                        :type="'text'"
                                        :label="labels[key]"
                                        :error-messages="errors"
                                        :prefix="prefix[key]"
                                        color="secondary"
                                    ></v-text-field> 

                                </ValidationProvider>

                            </div>
                            </template>

                    </div>
                </div>    
            </ValidationObserver>

            <div class="body-1 font-weight-bold mb-6">Terms and Acknowledgements</div>

            <ValidationObserver ref="observer2" tag="form">

                <template v-for="(term, index) in terms">
                    <ValidationProvider
                        v-slot="{ errors }"
                        :name="term.attributes.content"
                        rules="required"
                        :vid="term.attributes.content">    

                        <v-checkbox
                            v-model="checkboxes"
                            :value="term.attributes.content"
                            color="secondary"
                        >
                            <template v-slot:label>
                                <div v-html="term.attributes.content"></div>
                            </template>
                        </v-checkbox>

                </ValidationProvider>
                </template>

                <!-- {{ checkboxes }} -->

            </ValidationObserver>

            <v-alert dense outlined type="error" v-if="errors.length > 0">
                <div v-for="msg in errors" v-html="msg"></div>
            </v-alert>

        </v-card-text>
     
    </v-card>
</template>

<script>

    import { mapActions, mapState, mapMutations } from 'vuex'
    import helpers from '~/plugins/helpers'
    import subscriptions from '~/components/shared/subscriptions'

    export default {
        components: { },

        mixins: [subscriptions, helpers],

        data() {
            return {
                resources: {
                    loan: {
                        type: "loans",
                        id: null,
                        attributes: {
                            "lender-plenti-customer-reason" : null,
                            "lender-plenti-customer-reason-explanation" : null,
                            "lender-plenti-customer-paid-method-when-loan-due" : null
                        }
                    }
                },

                terms: [],
                people: [],
                checkboxes: [],
                errors: [],

                labels: {
                    "lender-plenti-customer-reason" : 'Customer reason for balloon',
                    "lender-plenti-customer-reason-explanation" : 'Explanation',
                    "lender-plenti-customer-paid-method-when-loan-due" : 'Intended payment method'
                },
                validation_rules: {
                    type: "loans",
                    attributes: {
                            "lender-plenti-customer-reason" : 'required|in:LowerMonthlyPayment,PlannedUpgradeOfVehicle,Other',
                            "lender-plenti-customer-paid-method-when-loan-due" : 'required|in:TradeIn,Cash,Refinance',
                            "lender-plenti-customer-reason-explanation" : 'required|in:DebtConsolidation,Car,HomeImprovement,Holiday,Wedding,Repairs,MovingCosts,RentalBond,PayBills,SolarBattery,MedicalDental,ProfessionalServiceFees,Boat,Motorbike,FuneralExpenses,Business,Investment,Other,PayoffLoan'
                    }
                },
                hiddens: {
                },
                prefix: {
                },
                fieldLayout: [
                    [
                        "lender-plenti-customer-reason",
                        "lender-plenti-customer-reason-explanation",
                        "lender-plenti-customer-paid-method-when-loan-due",
                        "__spacer",
                    ]
                ],
                options: {
                    "lender-plenti-customer-reason" : [
                        { text: "Lower Monthly Payment", value: "LowerMonthlyPayment" },
                        { text: "Planned Upgrade Of Vehicle", value: "PlannedUpgradeOfVehicle" },
                        { text: "Other", value: "Other" },
                    ],
                    "lender-plenti-customer-reason-explanation": [
                        { text: "Debt Consolidation", value: "DebtConsolidation" },
                        { text: "Car", value: "Car" },
                        { text: "Home Improvement", value: "HomeImprovement" },
                        { text: "Holiday", value: "Holiday" },
                        { text: "Wedding", value: "Wedding" },
                        { text: "Repairs", value: "Repairs" },
                        { text: "Moving Costs", value: "MovingCosts" },
                        { text: "Rental Bond", value: "RentalBond" },
                        { text: "Pay Bills", value: "PayBills" },
                        { text: "Solar Battery", value: "SolarBattery" },
                        { text: "Medical Dental", value: "MedicalDental" },
                        { text: "Professional Service Fees", value: "ProfessionalServiceFees" },
                        { text: "Boat", value: "Boat" },
                        { text: "Motorbike", value: "Motorbike" },
                        { text: "Funeral Expenses", value: "FuneralExpenses" },
                        { text: "Business", value: "Business" },
                        { text: "Investment", value: "Investment" },
                        { text: "Other", value: "Other" },
                        { text: "Payoff Loan", value: "PayoffLoan" },
                    ],
                    "lender-plenti-customer-paid-method-when-loan-due": [
                        { text: "Trade In", value: "TradeIn" },
                        { text: "Cash", value: "Cash" },
                        { text: "Refinance", value: "Refinance" },
                    ]
                }
            }
        },
        computed: {
            ...mapState({
                user: state => state.auth.user,
                appId: state => state.flows.appId,
                loanData: state => state.flows.loanData,
                lenderSubmissionTo: state => state.flows.lenderSubmissionTo,
                termGroup: state => state.flows.termGroup,
                commitments: state => state.flows.commitments,
            }),
            storeLoanData(){
                 return this.loanData
            },
            selectedLender(){
                return this.loanData?.attributes['selected-lender-quote-algorithm-slug']
            },
            isPlenti(){

                if(this.selectedLender) {
                    return this.selectedLender == 'plenti'
                }

                return false
            },
            getLoanType(){
                 return this.loanData?.attributes['loan-type']
            },
            getLoanId(){
                return this.appData.relationships.loan.data.id
            },
            hasBalloonPayment(){

                let balloon = this.loanData.attributes['requested-balloon']

                if(balloon && balloon > 0) {
                    return true
                }

                return true
            },
            isCommitmentExists(){

                let exists = false
                
                if(this.commitments && this.terms.length > 0){

                    return true

                }

                return exists
            }
        },
        methods: {
            ...mapActions('resource-actions', [
                'getLoan',
                'updateLoan',

                'getTerms',
                'getPeople',
                'getCommitments',
                'createCommitments',
            ]),
            ...mapMutations('flows', [
                'setCommitments'
            ]),
            async pull() {

                let promise1 =  await this.getTerms(this.termGroup)

                let promise2 = this.getPeople()

                const res = await Promise.all([
                        promise1, 
                        promise2
                    ]) 

                promise1 = res[0]
                promise2 = res[1]

                // console.log(promise1)
                // console.log(promise2)

                if(promise1.status == 200) {
                    this.terms = promise1.data.data
                }

                if(promise2.status == 200) {
                    this.people = promise2.data.data
                }

                // Check checkboxes if commitment exists

                if(this.isCommitmentExists) {

                    let termsArray = []

                    this.terms.forEach(term => {
                        termsArray.push(term.attributes.content)
                    });

                    this.checkboxes = termsArray

                }


                // Populate loan fields 

                // console.log(this.storeLoanData)

                const {storeLoanData} = this
                const {loan} = this.resources

                let obj = {}

                let localLoanDataKeys = Object.keys(loan.attributes)
                
                Object.keys(storeLoanData.attributes).forEach( key => {
                    if( localLoanDataKeys.includes(key)) {
                        obj[key] = storeLoanData.attributes[key]
                    }
                })

                this.resources.loan.attributes = obj

            },
            async push() {

                // Validate Balloon payment

                const ballonPaymentValid = await this.$refs.observer.validate()

                if(ballonPaymentValid) {
                    this.resources.loan.id = this.getLoanId
                    this.updateLoan(this.resources.loan)
                }

                // Validate Checkboxes

                this.checkCompleted()
      
                let valid = this.errors.length == 0 && ballonPaymentValid

                let status = valid ?  'valid' : 'dirty'

                if(valid && !this.isCommitmentExists) {

                    let termsData = this.terms.map(term => {
                        return {
                            id: term.id,
                            type: 'terms'
                        }
                    });

                    let peopleData = this.people.map(person => {
                        return {
                            id: person.id,
                            type: 'people'
                        }
                    });

                    let payload = {  
                        "type": "commitments",
                        "attributes": {},
                        "relationships": {
                            "application": {
                                "data": {
                                    "type":"applications",
                                    "id": this.appId
                                },

                            },
                            "committed-users": {
                                "data": [
                                    {
                                        "type": "users",
                                        "id": this.user.id // auth user
                                    }
                                ]
                            },
                            "committed-people": {
                                "data": peopleData // all people in  this app
                            },
                            "terms" : {
                                "data": termsData // all the terms
                            }
                        }
                    }

                    let res = await this.createCommitments(payload)

                    this.setCommitments(res.data.data)

                    return {valid, status, data:{}}

                } else {

                    return {valid, status, data:{}}
                }


                
            },
            checkCompleted(){

                this.errors = []

                if(this.checkboxes.length != this.terms.length) {
                    this.errors.push('Please accept all terms to proceed')
                } 

            },
        },
        watch: {
            checkboxes(val) {
                if(val.length == this.terms.length) {
                    this.errors = []
                }
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>