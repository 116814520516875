<template>

    <div v-if="vb.mdAndUp" class="d-flex" style="width: 100%">
        <v-sheet color="light_gray_1" class="py-8 px-12" width="50%" outlined elevation="0">
            <v-img 
                max-width="120"
                class="flex-brand" 
                :src="require(`~/assets/images/nodifi.svg`)" 
                position="center left" 
                contain>
            </v-img>
            <div class="mt-2 mb-6 font-size-20">Data</div>

            <v-list color="transparent">
                <v-list-item two-line class="px-0">
                    <v-list-item-content>
                        <v-list-item-title class="font-weight-medium">Primary Country of Citizenship</v-list-item-title>
                        <v-list-item-subtitle>{{data ? data.attributes['primary-country-of-citizenship'] : '&mdash;'}}</v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>

                <v-list-item two-line class="px-0">
                    <v-list-item-content>
                        <v-list-item-title class="font-weight-medium">Do you have dual citizenship?</v-list-item-title>
                        <v-list-item-subtitle>{{data ? data.attributes['secondary-country-of-citizenship'] ? 'Yes' : 'No' : '&mdash;'}}</v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>

                <v-list-item two-line class="px-0">
                    <v-list-item-content>
                        <v-list-item-title class="font-weight-medium">Secondary Country of Citizenship</v-list-item-title>
                        <v-list-item-subtitle>{{data ? data.attributes['secondary-country-of-citizenship'] : '&mdash;'}}</v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>

                <v-list-item two-line class="px-0">
                    <v-list-item-content>
                        <v-list-item-title class="font-weight-medium">Visa Number</v-list-item-title>
                        <v-list-item-subtitle>{{data ? data.attributes['visa'] : '&mdash;'}}</v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>

                <v-list-item two-line class="px-0">
                    <v-list-item-content>
                        <v-list-item-title class="font-weight-medium">Visa Expiry</v-list-item-title>
                        <v-list-item-subtitle>{{data ? data.attributes['visa-expiry-date'] : '&mdash;'}}</v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>
            </v-list>

        </v-sheet>
        <v-sheet class="py-8 px-12" width="50%">

            <v-img 
                max-width="120"
                class="flex-brand" 
                :src="require(`~/assets/images/logo/cropped/lender-logo-plenti.png`)" 
                position="center left" 
                contain>
            </v-img>
            <div class="mt-2 mb-8 font-size-20">Requirements</div>

            <ValidationObserver ref="observer" tag="form">
                <template v-for="(field, key) in resources.person.attributes" >
                <ValidationProvider 
                        :key="key"
                        v-slot="{ errors }" 
                        :name="labels[key]"
                        :rules="getRules(key)" 
                        :vid="key">

                            <ButtonGroup3 
                                v-if="isBtnGroup(key)"
                                v-model="resources.person.attributes[key]"
                                :error-messages="errors"
                                :options="[{value: '1', label:'Yes'}, {value: '0', label:'No'}]"
                                :label="labels[key]" />

                            <v-select
                                v-else-if="isFieldType('picklist', key)"
                                v-model="resources.person.attributes[key]"
                                :items="getOptions(key)"
                                :error-messages="errors"
                                color="secondary"
                                :label="labels[key]"
                                ></v-select>

                            <v-text-field 
                                v-else
                                tile
                                type="text"
                                :label="labels[key]"
                                color="secondary"
                                :error-messages="errors"
                                v-model="resources.person.attributes[key]"
                                required>
                            </v-text-field>

                </ValidationProvider>
                </template>
            </ValidationObserver>


        </v-sheet>
    </div>

    <!-- mobile version -->
    <div v-else>

        <v-card flat class="mx-5 rounded-xl" :style="`background-color: ${hexToRGBA(getActiveTheme.colors.secondary, 0.2)}`">

            <v-card-text class="d-flex py-1">
                <v-row>
                    <v-col cols="6" class="pa-1">
                        <v-card
                        min-height="45"
                            flat class="rounded-xl px-1"
                            :color="tabSelection == 'nodifi' ? 'white' : 'transparent'">
                            <div class="d-flex justify-center pt-1" @click="selectTab('nodifi')">
                                <v-img 
                                    max-width="55"
                                    class="flex-brand" 
                                    :src="require(`~/assets/images/nodifi.svg`)" 
                                    position="center left" 
                                    contain>
                                </v-img>
                                <div class="mt-3 font-size-12 pl-1">Data</div>
                            </div>
                        </v-card>
                    </v-col>
                    <v-col cols="6" class="pa-1">
                        <v-card
                            flat class="rounded-xl px-1"
                            :color="tabSelection == 'plenti' ? 'white' : 'transparent'">
                            <div class="d-flex flex-column" @click="selectTab('plenti')">
                                <v-img 
                                    max-width="45"
                                    class="flex-brand mx-auto mt-n1" 
                                    :src="require(`~/assets/images/logo/cropped/lender-logo-plenti.png`)" 
                                    position="center left" 
                                    contain>
                                </v-img>
                                <div class="font-size-12 pl-1 mx-auto mt-n3">Requirements</div>
                            </div>
                        </v-card>
                    </v-col>
                </v-row>
            </v-card-text>
            
        </v-card>

        <v-list  v-show="tabSelection == 'nodifi'" class="mx-8" color="transparent">
            <v-list-item two-line class="px-0">
                <v-list-item-content>
                    <v-list-item-title class="font-weight-medium">Primary Country of Citizenship</v-list-item-title>
                    <v-list-item-subtitle>{{data ? data.attributes['primary-country-of-citizenship'] : '&mdash;'}}</v-list-item-subtitle>
                </v-list-item-content>
            </v-list-item>

            <v-list-item two-line class="px-0">
                <v-list-item-content>
                    <v-list-item-title class="font-weight-medium">Do you have dual citizenship?</v-list-item-title>
                    <v-list-item-subtitle>{{data ? data.attributes['secondary-country-of-citizenship'] ? 'Yes' : 'No' : '&mdash;'}}</v-list-item-subtitle>
                </v-list-item-content>
            </v-list-item>

            <v-list-item two-line class="px-0">
                <v-list-item-content>
                    <v-list-item-title class="font-weight-medium">Secondary Country of Citizenship</v-list-item-title>
                    <v-list-item-subtitle>{{data ? data.attributes['secondary-country-of-citizenship'] : '&mdash;'}}</v-list-item-subtitle>
                </v-list-item-content>
            </v-list-item>

            <v-list-item two-line class="px-0">
                <v-list-item-content>
                    <v-list-item-title class="font-weight-medium">Visa Number</v-list-item-title>
                    <v-list-item-subtitle>{{data ? data.attributes['visa'] : '&mdash;'}}</v-list-item-subtitle>
                </v-list-item-content>
            </v-list-item>

            <v-list-item two-line class="px-0">
                <v-list-item-content>
                    <v-list-item-title class="font-weight-medium">Visa Expiry</v-list-item-title>
                    <v-list-item-subtitle>{{data ? data.attributes['visa-expiry-date'] : '&mdash;'}}</v-list-item-subtitle>
                </v-list-item-content>
            </v-list-item>
        </v-list>

        <div v-show="tabSelection != 'nodifi'" class="mx-8">
            <ValidationObserver ref="observer" tag="form">
                <template v-for="(field, key) in resources.person.attributes" >
                <ValidationProvider 
                        :key="key"
                        v-slot="{ errors }" 
                        :name="labels[key]"
                        :rules="getRules(key)" 
                        :vid="key">

                            <v-select
                                v-if="isFieldType('picklist', key)"
                                v-model="resources.person.attributes[key]"
                                :items="getOptions(key)"
                                :error-messages="errors"
                                color="secondary"
                                :label="labels[key]"
                                ></v-select>

                            <v-text-field 
                                v-else
                                tile
                                type="text"
                                :label="labels[key]"
                                color="secondary"
                                :error-messages="errors"
                                v-model="resources.person.attributes[key]"
                                required>
                            </v-text-field>

                </ValidationProvider>
                </template>
            </ValidationObserver>
        </div>

    </div>
    

</template>

<script>

    import { mapActions, mapMutations, mapGetters } from 'vuex'
    import subscriptions from '~/components/shared/subscriptions'
    import shared from '~/components/v1/submission/LenderPlentiEntities1/__shared'
    import {store, actions} from '~/components/v1/submission/LenderPlentiEntities1/__store'
    import ButtonGroup3 from '~/components/base/ButtonGroup3'

    export default {
        components: {ButtonGroup3},
        mixins: [subscriptions, shared],
        props: {
            data: {
                type: Object,
                default: null
            },
            updater: undefined
        },
        data() {
            return {
                tabSelection: 'nodifi',
                resources: {
                    person: {
                        id: null,
                        type: "people",
                        attributes: {
                            "lender-plenti-residency" : null,
                            "lender-plenti-forsee-repayment" : null
                        }
                    }
                },

                labels: {
                    "lender-plenti-residency": "Client residency status ",
                    "lender-plenti-forsee-repayment": "Foreseeable change in circumstances",
                },
                validation_rules: {
                    attributes: {
                        "lender-plenti-residency": "required|in:auNzCitizen,auPermanentResident,onVisa",
                        "lender-plenti-forsee-repayment": "required|integer|in:1,0",
                    }
                },
                options: {
                    "lender-plenti-residency" : [
                        { text: "Australian or New Zealand Citizen", value: "auNzCitizen" },
                        { text: "Australian Permanent Resident", value: "auPermanentResident" },
                        { text: "On Visa", value: "onVisa" }
                    ]
                }
            }
        },
        computed: {
            vb() {
                return this.$vuetify.breakpoint
            }
        },
        mounted() {
            this.updateResourceFields()
        },
        methods: {
            ...mapActions('resource-actions', [
                'getPerson',
            ]),
            selectTab(tab) {
                this.tabSelection = tab
            },
            isBtnGroup(key){

                let keys = [
                    "lender-plenti-forsee-repayment",
                ]

                return keys.includes(key)

            },
            async validate(){

                const valid = await this.$refs.observer.validate()

                if(valid) {

                    // attache person id
                    // this.resources.person.id = this.data.id

                    this.$emit('submit', this.resources.person)

                }

            },
            reset(){

                this.$refs.observer.reset()

            },
            async updateResourceFields(){

                // console.log('updateResourceFields')

                this.$emit('loading', true)

                const {data} = this

                if(data) {

                    let res = await this.getPerson(data.id)

                    this.resources.person = this.filterResourceFields(this.resources.person, res.data.data)

                    this.$emit('loading', false)

                } else {

                    this.$emit('loading', false)
                }

            }
        },
        watch: {
            updater(val) {

                // console.log(val)

                if(val) {
                    this.updateResourceFields()
                }
            },
        }
    }
</script>

<style lang="scss" scoped>

</style>