<template>
    <v-card :disabled="isDisabled || !validComponentData" class="mx-auto" tile flat>
        <v-card-text class="pa-4 pt-0 pa-md-10 pt-md-0">
            <v-container fluid pa-0>
                <v-row>
                    <v-col cols="12" class="pt-0">

                        <ValidationObserver ref="observer" tag="form">

                            <div class="title mb-4 body_text_color--text">
                                Purchase Type
                            </div>

                            <div class="flex-form">

                                <div class="flex-field">
                                    <ValidationProvider 
                                            v-slot="{ errors }" 
                                            name="Sale Type" 
                                            :rules="getRules('sale-type')" 
                                            vid="sale-type">
                                        <v-select
                                            v-model="resourceObj.loans.attributes['sale-type']"
                                            :items="getOptions('sale-type')"
                                            :error-messages="errors"
                                            color="secondary"
                                            tile
                                            label="Sale Type"></v-select>
                                    </ValidationProvider>
                                </div>

                                <div class="flex-field">
                                    <ValidationProvider v-slot="{ errors }" name="Asset Type" rules="required" vid="asset_type">
                                        <v-select
                                            v-model="assetType"
                                            :items="assetTypeList"
                                            item-text="name"
                                            item-value="id"
                                            color="secondary"
                                            :error-messages="errors"
                                            label="Asset Type"></v-select>
                                    </ValidationProvider>
                                </div>
        
                                <div class="flex-field">
                                    <ValidationProvider v-slot="{ errors }" name="Asset Sub-Type" rules="required" v-if="assetType && (assetSubTypeList.length > 1)" vid="sub_asset_type">
                                        <v-select
                                            v-model="assetSubType"
                                            :items="assetSubTypeList"
                                            item-text="name"
                                            item-value="id"
                                            color="secondary"
                                            :error-messages="errors"
                                            label="Asset Sub-Type"></v-select>
                                    </ValidationProvider>
                                </div>

                                <!-- Temp Spacer -->
                                <div class="flex-field" v-for="index in 6" :key="index"></div>

                           </div>


                            <div class="title my-4 body_text_color--text">
                                Purchase Details
                            </div>

                            <div class="flex-form" v-for="layout in fieldLayout">
                                <template v-for="(field, key) in fieldLayoutMaker(layout, resourceObj.purchases.attributes)">
                                <div class="flex-field" :key="key" >
                                    <ValidationProvider 
                                            v-slot="{ errors }" 
                                            :name="labels[key]"
                                            :rules="getRules(key)" 
                                            :vid="key">

                                                <nvic-finder 
                                                    v-if="key == 'nvic'"
                                                    :label="labels[key]"
                                                    :errors="errors"
                                                    @accept="handleNvicAccept"
                                                    :default-value="resourceObj.purchases.attributes[key]"
                                                    v-model="resourceObj.purchases.attributes[key]"
                                                    >
                                                </nvic-finder>

                                                <v-select
                                                    v-else-if="isFieldType('picklist', key)"
                                                    v-model="resourceObj.purchases.attributes[key]"
                                                    :items="getOptions(key)"
                                                    :error-messages="errors"
                                                    color="secondary"
                                                    :label="labels[key]"
                                                    ></v-select>

                                                <flex-date-picker
                                                    v-else-if="isFieldType('date', key)"
                                                    :label="labels[key]"
                                                    :errors="errors"
                                                    v-model="resourceObj.purchases.attributes[key]"
                                                ></flex-date-picker>

                                                <v-text-field 
                                                    v-else
                                                    tile
                                                    type="text"
                                                    :label="labels[key]"
                                                    color="secondary"
                                                    :error-messages="errors"
                                                    v-model="resourceObj.purchases.attributes[key]"
                                                    required>
                                                </v-text-field>

                                    </ValidationProvider>
                                </div>
                                </template>
                                <!-- Temp Spacer -->
                                <div class="flex-field" v-for="index in 6" :key="index"></div>

                            </div>
                        </ValidationObserver>
                    </v-col>
                </v-row>
            </v-container>
        </v-card-text>
    </v-card>
</template>

<script>

    import subscriptions from '~/components/shared/subscriptions'
    import { mapState, mapGetters, mapActions, mapMutations } from 'vuex'
    import FlexDatePicker from '~/components/base/FlexDatePicker' 
    import NvicFinder from '~/components/base/NvicFinder' 
    
    export default {
        name: 'PurchaseEditor2',
        mixins: [subscriptions],
        components: { FlexDatePicker, NvicFinder },
        data(){
            return {
                mode: "default",
                assetType: null,
                assetSubType: null,
                resources: [
                    {
                        type: "loans",
                        id: null,
                        attributes: {
                            "sale-type": "",
                        },
                        relationships: {
                            application: {
                                data: { type: "applications", id: null } 
                            }
                        }
                    },
                    {
                        type: "purchases",
                        id: null,
                        attributes: {
                            "nvic": "",
                            "condition": "",
                            "year-of-manufacture": "",
                            "make": "",
                            "model": "",
                            "variant": "",
                            // "kms": "",
                            "purchase-price": "",
                            "cash-deposit": "",
                            "trade-in-amount": "",
                            "payout-amount": ""
                        },
                        relationships: {
                            application: {
                                data: { type: "applications", id: null } 
                            },                
                            kind: {
                                data: { type: "kinds", id: null }  
                            }
                        }
                    }
                ],
                fieldLayout: [
                    [ "nvic", "condition",],
                    [
                        "year-of-manufacture",
                        "make",
                        "model",
                        "variant"               
                    ],
                    [
                       "purchase-price",
                        "cash-deposit",
                        "trade-in-amount",
                        "payout-amount"
                    ]
                ],
                // Static field labels. cause sometimes field names and labels are different
                labels: {
                    "nvic": "NVIC",
                    "year-of-manufacture": "Year of Manufacture",
                    "make": "Make",
                    "model": "Model",
                    "variant": "Variant",
                    "condition": "Condition",
                    // "kms": "KMS",
                    "purchase-price": "Purchase Price",
                    "cash-deposit": "Cash Deposit",
                    "trade-in-amount": "Trade in Amount",
                    "payout-amount": "Payout Amount"
                },
                validation_rules: {
                    attributes: {
                        "nvic": "required|string|max:50",
                        "year-of-manufacture": "required|integer|digits_between:4,4",
                        "make": "required|string|max:255",
                        "model": "required|string|max:200",
                        "variant": "required|string|max:128",
                        "condition": "required|string|in:New,Used",
                        // "kms": "required|integer",
                        "purchase-price": "required|between:0,9999999",
                        "cash-deposit": "required|between:0,9999999",
                        "trade-in-amount": "required|between:0,9999999",
                        "payout-amount": "required|between:0,9999999",
                        "sale-type": "required|string|in:Dealer,Private Sale,Refinance"
                    }
                },
                /**
                 * resourceObj : This will hold formatted resources so we can easily map it to the view.
                 */
                resourceObj: {},
                /**
                 * payloadObj : This will give us access to the payloads in push method during Unit Test
                 */
                payloadObj: {},
            }
        },
        computed: {
            ...mapGetters('kinds', ['kinds', 'kindsUnformat']),
            assetTypeList() {

                // We need Kinds for Purchases
                let purchasesKinds = this.kinds.find( kind => {
                    return kind.name == 'Purchase'
                })

                let list = []

                if(purchasesKinds) {

                    list = purchasesKinds.children.filter( child => {

                        if(child.id == "9-45" || child.id == "9-34") {
                            return false
                        }

                        return true

                    })
                }
                
                return list
            },
            assetSubTypeList() {

                let subType = this.assetTypeList.find( item => {
                    return item.id == this.assetType
                })
                
                if(subType) {
                    return subType.children || []
                } else {
                    return []
                }
            },
            /**
             * Checks for minimum required data
             */
            validComponentData(){
                
                const {data} = this.appData.relationships.loan

                if(data == null){
                    return false;
                }

                return true;
            },
            getLoanId(){
                
                if(this.validComponentData){
                    return this.appData.relationships.loan.data.id
                } 

                return null
            },
            getPurchaseId(){

                if(this.appData.relationships.purchases.data.length) {

                    /**
                     * Check if purchases exists.
                     * Then get the first one, this is subject to change.
                     */
                    return this.appData.relationships.purchases.data[0].id
                }

                return null

            }
        },
        created() {

            this.resourceObj = this.resourceObjHelper()

            /**
             * Attach Application id, not sure why can't initialize this in vue.data
             */ 
            this.resourceObj.purchases.relationships.application.data.id = this.appId

        },
        mounted(){

            if(!this.validComponentData){
                this.showComponentWarning()
            }

        },
        methods: {
            ...mapActions('resource-actions', [
                'getLoan',
                'getPurchase',
                'updateLoan',
                'createPurchase',
                'updatePurchases'
            ]),
            async pull() {

                // Do nothing if minimum data required is not met.
                if(!this.validComponentData) return; 

                const {loans, purchases} = this.resourceObj

                let loansRes = this.getLoan(this.getLoanId).then(res => {
                    
                    // Pick only the fields we need from the response
                    this.resourceObj.loans = this.resourceFieldPicker(loans, res.data.data)

                })

                let purchasesRes = null

                if(this.getPurchaseId) {

                    purchasesRes = this.getPurchase(this.getPurchaseId).then(res => {

                        this.resourceObj.purchases = this.resourceFieldPicker(purchases, res.data.data)

                        // Reset component kind's data if the response kind.data is null
                        if(!res.data.data.relationships.kind.data){
                            this.resourceObj.purchases.relationships.kind.data = { type: "kinds", id: null }  
                        }
                        
                        this.handleAssetTypes(res.data.data.relationships.kind.data)

                    })

                }

                try {
                    await Promise.all([loansRes, purchasesRes])
                } catch (error) {
                    console.log('Ooops!..', error)
                }
                
            },

            async push() {

                const observer = this.$refs.observer

                const {loans, purchases} = this.resourceObj
                
                this.payloadObj.loans = {
                    type: loans.type, 
                    id: loans.id, 
                    attributes: loans.attributes
                }

                const loanRes =  this.updateLoan(this.payloadObj.loans).then(res => {
                        this.resourceObj.loans = this.resourceFieldPicker(loans, res.data.data)
                        return res
                    })

                let purchaseRes = null;

                this.payloadObj.purchases = {
                    type: purchases.type,
                    attributes: this.excludeInvalids(purchases.attributes, observer.fields),
                    relationships: {}
                }

                if(this.getPurchaseId) {

                    // Include id when updating resource
                    this.payloadObj.purchases.id = purchases.id

                    // Include kind if not empty
                    if(purchases.relationships.kind.data && purchases.relationships.kind.data.id){
                        this.payloadObj.purchases.relationships.kind = purchases.relationships.kind
                    }

                    // On patch don't update component data. 
                    purchaseRes = this.updatePurchases(this.payloadObj.purchases).then(res => {
                        // this.resourceObj.purchases =  this.resourceFieldPicker(purchases, res.data.data)
                        return res
                    })

                }  else {

                    // Include relationships when creating resource
                    this.payloadObj.purchases.relationships = purchases.relationships

                    // Include kind if not empty
                    if(purchases.relationships.kind.data.id == null){
                        delete this.payloadObj.purchases.relationships.kind
                    }

                    purchaseRes =  this.createPurchase(this.payloadObj.purchases).then(res => {
                        this.resourceObj.purchases =  this.resourceFieldPicker(purchases, res.data.data)
                        return res
                    })

                }

                const {valid, status} = await this.validateFormData()
                const data = await this.appDataHelper([loanRes, purchaseRes])

                return {valid, status, data}

            },
            /**
             * This logic will pre-populate Asset Types if exists.
             * Move to shared file id needed.
             */
            handleAssetTypes(data){

                if(data == null) {
                    return;
                }

                let asset = this.kindsUnformat.find(kind => {
                    return kind.id == data.id
                })

                if(asset) {

                    if(asset.relationships.parent.data) { // has parent

                        let parentId = asset.relationships.parent.data.id;

                        let resource = this.kindsUnformat.find(kind => { // get top level parent ex: purchases
                            return kind.id == parentId
                        })
                        
                        if(resource.relationships.parent.data == null) { // if parent id is null means it is top level
                            this.assetType = data.id
                            this.assetSubType = ''
                        } 
                        else if (resource.relationships.parent.data.id == null) {
                            this.assetType = data.id
                            this.assetSubType = ''
                        }
                        else {
                            this.assetType = parentId
                            this.assetSubType = data.id
                        }

                    }
                }

            },
            /**
             * Nvic Event Handler
             */
            handleNvicAccept(data){

                // this.resourceObj.purchases.attributes.kms = data.details.AVERAGEKM
                this.resourceObj.purchases.attributes["make"] = data.details["manufacturer-name"]
                this.resourceObj.purchases.attributes["model"] = data.details["family-name"]
                this.resourceObj.purchases.attributes["variant"] = data.details["variant-name"]
                this.resourceObj.purchases.attributes["year-of-manufacture"] = data.details["year-create"]

            }
        },

        watch: {
            assetType(newVal, oldVal) {
                
                if(this.assetSubType == "" || this.assetSubType == null){
                        this.resourceObj.purchases.relationships.kind.data = {
                            id: newVal,
                            type: "kinds"
                        }
                }
                
                if( (oldVal != "" && oldVal != null) && newVal != oldVal) {
                    this.assetSubType = ""
                }

            },
            assetSubType(pid) {
                if (pid == '')
                    this.resourceObj.purchases.relationships.kind.data.id = this.assetType
                else this.resourceObj.purchases.relationships.kind.data.id = pid

            },
            /**
             * Pre-select item if sub type choices has only one.
             */
            assetSubTypeList(val){

                if(val.length == 1) {
                    this.assetSubType = val[0]['id']
                }

            },
            validComponentData(val){
                if(!val) {
                    this.showComponentWarning()
                }
            },
        }
    
     
    }
</script>

<style scoped lang="scss">
    .flex-form {
        display: grid;
        grid-column-gap: 15px;
        grid-row-gap: 0;
        grid-template-columns:  repeat(auto-fit, minmax(260px, 1fr));

        .flex-field {
            align-self: start;
        }
    }
</style>




