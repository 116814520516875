import Vue from 'vue'
import { mapMutations, mapGetters } from 'vuex'
import NodifiMoney from '~/components/base/NodifiMoney'
import moment from 'moment'
import { assign } from 'lodash'

Vue.use(NodifiMoney)

export default {

    components: {NodifiMoney},

    computed: {

        ...mapGetters('kinds', [
            'kindsOfApplicant',
            'kinds',
            'kindsUnformat'
        ]),

        ...mapGetters('slug', [
            'getFlowSlug'
        ]),

        flexible(val) {
            return this.$vuetify.breakpoint.smAndDown ? 'px-1 basic-field' : 'xxl-3 xl-3 lg-3 md-4 px-1 basic-field'
        },

        assetTypeList() {

            // We need Kinds for Purchases
            let purchasesKinds = this.kinds.find( kind => {
                return kind.name == 'Purchase'
            })

            return purchasesKinds ? purchasesKinds.children : []
        }

    },

    data() {
        return {
            loader: null,
            loading: false,
            saving: false,
            sizes: null,
        }
    },

    watch: {
        loader () {
            const l = this.loader
            this[l] = !this[l]
            
            setTimeout(() => (this[l] = false), 3000)

            this.loader = null
        }
    },

    methods: {

        ...mapMutations('utils', [
            'toggleQuickMessage',
            'toggleAlert'
        ]),

        ...mapMutations('kinds', [
            'setvalidKindsWithPriorities'
        ]),

        
        peopleResourceWithInclusion(obj, field = '', keyword = '') {
            
            let collection = []
            
            collection = obj.filter( (res) => {
                return (res.attributes['end-date'] !== null && res.attributes['end-date'] !== "" ) 
                    && 
                    res.attributes[field] === keyword
            })
            
            if ( collection.length === 0 ) {
                collection = obj.filter( (res) => {
                    return res.attributes[field] === keyword
                })

            }

            collection = this.filterSpecificResources(collection, field, keyword)

            return collection

        },
        
        filterSpecificResources(data = [], field = '', status = '') {              
            
            /**
             * If compiled data contains only 1 object
             */
            if ( data.length === 1 ) {                
                data = data[0]                
            }

            /*
             * If compiled data contains 2 or more object
             * Mapping rule applied for lowest ID value
             */
            else if ( data.length > 1 ) {
                
                data = data.reduce( (res, obj) => {
                    return ( moment(obj.attributes['end-date']).isAfter(res.attributes['end-date']) ) ? obj : res
                    // return (obj.id < res.id) ? obj : res
                })

                if ( Object.keys(data).length === 0 )
                    data = data.reduce( (res, obj) => {
                        return (obj.id < res.id) ? obj : res
                    })                
                
            }

            if ( Object.keys(data).length ) data.attributes[field] = status
            
            return data            

        },

        getPrimaryApplicant(data = {}) {
            let applicant = {}
            let applicants = []
            let dataHasParts = false
            
            if ( data.included && data.included.length > 0 ) {
                
                applicants = data.included.filter( (res) => {     
                     
                    if ( res?.type === 'parts' ) dataHasParts = true               
                    // return (res.relationships.kinds?.data.filter( (f) => {
                    //     return f.id === this.kindsOfApplicant.id
                    // }).length )
                    return res.relationships?.kind?.data?.id === this.kindsOfApplicant.id && res.type === 'parts'
                })

            }
            
            if ( !dataHasParts ) { 
                this.setvalidKindsWithPriorities( false )
                return {}
            }

            if ( applicants.length === 1 ) {
                
                applicant = applicants.find( x => { return x.attributes.priority === "1" || x.attributes.priority === 1 })                
                applicant = applicant?.id ? applicant : {}
            }
            else if ( applicants.length === 0 ) {
                if ( !data?.data?.length ) {
                    this.setvalidKindsWithPriorities( false )
                    return {}
                }
                else if ( dataHasParts ) {
                    this.setvalidKindsWithPriorities( false )
                    return {}
                }
                else {
                    applicant = data?.data.reduce( (res, obj) => {
                        return ( obj.id < res.id ? obj : res )
                    })
                    this.setvalidKindsWithPriorities( applicant?.id ? true : false )
                    return applicant
                }                

            }
            else if ( applicants.length > 1 ) {
                
                applicants = applicants.filter( f => {
                    return f.attributes.priority === '1' || f.attributes.priority === 1
                })
                
                if ( applicants.length !== 1) {
                    this.setvalidKindsWithPriorities( false )
                    return {}
                }

                else {                    
                    applicant = applicants.find( x => { return x.attributes.priority === "1" || x.attributes.priority === 1 })
                }
            }
            
            applicant = data.data.find( person => {
                return person.id === applicant?.relationships?.child?.data.id
            })
            
            this.setvalidKindsWithPriorities( applicant?.id ? true : false )
            
            return applicant

        },


        getPriorityParts(data = {}) {
            
            let parts = []
            let dat = [{}]
            let fdata = [].concat(data.data || [{}])
            let dataHasPriorities = false

            if ( data.included && data.included.length > 1 ) {
                
                // parts = data.included.filter( (res) => {
                //     return (res.attributes.priority === "1" && res.type === "parts") && (res.relationships.kinds && this.kindsOfApplicant.id === res.relationships.kinds.data[0].id)
                // })

                parts = data.included.filter( (res) => {
                    return (res.attributes.priority === "1" && res.type === "parts")
                })

                if ( parts.length ) {
                    dataHasPriorities = true
                    parts = parts.filter( (res) => {
                        return res.relationships.kinds && this.kindsOfApplicant.id === res.relationships.kinds.data[0].id
                    })
                }

                if ( dataHasPriorities && parts.length === 0 ) {
                    this.setvalidKindsWithPriorities( dataHasPriorities && parts.length === 0 ? false : true)
                }

            } 
            
            else {                

                this.setvalidKindsWithPriorities( true )

                dat = fdata.filter( (res) => {
                    return res.type === 'people'
                })
                
                if ( dat.length ) {
                    parts = dat.reduce( (res, obj) => {
                        return ( obj.id < res.id ? obj : res )
                    })
                }

                parts = dat.length ? [ parts ] : []

            }
            
            if ( (parts.length === 0 || fdata.length === 1) && fdata.length ) {
                
                parts[0] = fdata.reduce( (res, obj) => {
                    return (obj.id < res.id) && (res.attributes.priority === "1" || obj.attributes.priority === "1") ? obj : res
                })

            }
            
            let collection = []

            parts.forEach( p => {
                
                collection.push( {
                    type: data.included && data.included.length > 1 ? p.relationships.child ? p.relationships.child.data.type: p.type : p.type,
                    id: data.included && data.included.length > 1 ? p.relationships.child ? p.relationships.child.data.id : p.id : p.id,
                    relationships: p.relationships
                } )

            })
            
            return collection.length ? collection : [{id: null, relationships: {}, type: 'people'}]

        },

        attachPersonRelationships(origin, source, toRemove = '', relationshipResource = '') {
            
            let { attributes, id, relationships, type } = { ...source }
            
            Object.keys(origin).forEach( hash => {
                
                origin[hash].relationships = Object.assign({}, relationships)
                
                if ( type === 'people' ) {
                    origin[hash].relationships[relationshipResource] = { 
                        data: { 
                            type: type, 
                            id: id 
                        }
                    }
                }
                else {
                    origin[hash].relationships[relationshipResource] = { 
                        data: { 
                            type: relationships?.child !== undefined ? relationships.child.data.type : type, 
                            id: relationships?.child !== undefined ? relationships.child.data.id : id 
                        } 
                    }
                }

                if (relationshipResource === 'people') {
                    origin[hash].relationships[relationshipResource].data = [ origin[hash].relationships[relationshipResource].data ]
                }
                
                if ( toRemove ) {
                    delete origin[hash].relationships[toRemove]
                }
                
                /**
                 * Removing empty relationships object
                 */
                Object.keys(origin[hash].relationships).forEach( child => {
                    
                    if ( origin[hash].relationships[child].data === null || origin[hash].relationships[child].data?.length === 0) {
                        delete origin[hash].relationships[child]
                    }

                })

            })

            if (toRemove === 'incomes') {
                // if ( !origin.previous.attributes['employment-job-status'] )
                    delete origin.previous.relationships.kind
                    delete origin.current.relationships.kind
                if ( origin.current.attributes['employment-job-status'] ) 
                    origin.current.relationships.kind = {
                        data: {
                            id: this.getIncomeKind(origin.current.attributes['employment-job-status']),
                            type: 'kinds'
                        }
                    }
            }
            
            return origin

        },

        isAllPromisesValid(responses) {
            
            let valid = responses.length > 0 ? true : false
            
            if (valid)
                responses.forEach( res => {
                    if (res.status != 200 && res.status != 201) valid = false
                })
            
            return valid

        },

        fetchNewlyUpdatedRecord(responses = [], local_resource = [], fields = {}, keyword = '' ) {
            let resources = {}
            
            local_resource.forEach( res => {

                let flagged = {}
                
                responses.forEach( dat => {
                    
                    if ( res.attributes[keyword] === dat.data.data.attributes[keyword] ) {
                        flagged = dat.data.data
                    }
                })

                if ( flagged?.id ) {

                    Object.keys( flagged.attributes ).forEach( (f) => {
                        if (fields[f] === undefined)
                            delete flagged.attributes[f]
                    })

                    resources[res.attributes[keyword].toLowerCase()] = flagged
                }
                else {
                    resources[res.attributes[keyword].toLowerCase()] = res
                }

            })
            
            return resources

        },

        filterResourceWithoutTheseRelationships(keywords = [], resource = {}) {

            resource.forEach( data => {
                Object.keys(data.relationships).forEach( rel => {
                    if (keywords.indexOf(rel) >= 0) {
                        delete data.relationships[rel]
                    }
                    
                    // Removing validations resources in relationships
                    if (rel === 'validations' || rel === 'commitments') {
                        delete data.relationships[rel]
                    }

                })
            })

            return resource

        },

        subtractDateBy(str, num, format) {
            
            return moment().subtract(Number(num), str).format(format)

        },

        resourceObjHelper(resources) {

            let obj = {}

            if(!resources) {
                resources = this.resources
            }
            
            resources.forEach(resource => {
                obj[resource.type] = resource
            })

            return obj

        },

        fieldType(key) {
            
            let f = this.fields.filter( f => {
                return f.name === key
            })

            return f[0] ? f[0].type : 'text'

        },

        hasUpperCase(str) {
            return (/[A-Z]/.test(str))
        },
        convertStr(str) {
            return str.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join('_').replace(/_/g, ' ')
        },

        async imageExist(path) {
            let img = await new Image()
            img.src = path
            return img.height != 0
        },

        async fetchLogo(filename) {
            let img = require(`@/assets/images/logo/${filename}.png`)
            let validImage = await this.imageExist( img )
            
            if (validImage) {
                return img
            }
            return require(`@/assets/images/logo/placeholder.png`)
        },
        handleResize(sizes){

            this.sizes = sizes

        },
        
    }
}