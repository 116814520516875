<template>
    <v-dialog
        v-model="dialog"
        width="950"
        persistent
        class="white">
        <template v-slot:activator="{ on, attrs }">
            <v-btn
                x-large outlined elevation="5"
                color="secondary"
                class="white"
                icon
                v-bind="attrs"
                v-on="on">
                <v-icon>mdi-car-info</v-icon>
            </v-btn>
        </template>

        <v-card class="white" flat>
            <v-card-title>
                <v-spacer />
                <v-btn icon @click="dialog = false" >
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-card-title>

            <v-card-text class="white">
                <p class="display-1 text-center">
                    Vehicle Specifications
                </p>
                <v-row>
                    <v-col>
                        <v-card flat class="overflow-y-auto" tile>
                            <v-card-text class="px-0">
                                
                                <v-card flat>
                                    <v-card-text>                        
                                        <v-row>
                                            <v-col v-for="(spec, skey) in specifications" :key="skey" cols="6" class="py-0 px-10">
                                                <v-row clsdd="py-0 my-0">
                                                    <v-col cols="6" class="py-0 my-1">  
                                                        <p class="caption pa-0 ma-0 primary--text font-weight-regular text-break">
                                                            {{ skey | formalizer }}:
                                                        </p>
                                                    </v-col>
                                                    <v-col cols="6" class="py-0 my-1">
                                                        <p class="button black--text pa-0 ma-0 text-break font-weight-medium">
                                                            {{ spec }}                                                
                                                        </p>
                                                    </v-col>
                                                </v-row>                                                
                                            </v-col>
                                        </v-row>
                                    </v-card-text>                                      
                                </v-card>

                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>                
            </v-card-text>
        </v-card>

    </v-dialog>
</template>

<script>

    export default {
        name: 'QuoteToolDetailsDialog',

        props: {
            specifications: {
                type: Object, 
                default: () => {}
            }
        },

        filters: {
            formalizer: function (value) {
                if (!value) return ''
                value = value.toString()
                let words = value.replaceAll('-', ' ').split(' ')

                for (let i = 0; i < words.length; i++) {
                    words[i] = words[i][0].toUpperCase() + words[i].substr(1);
                }

                return words.join(' ')
            }
        },

        components: {
            
        },

        methods: {
            
        },

        data () {
            return {
                dialog: false
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>