<template>
    <v-dialog
        persistent
        scrollable
        height="600"
        max-width="500"
        class="pa-4"
        :value="dialog">
            <v-card flat :disabled="loading">
                <v-progress-linear color="secondary" indeterminate stream absolute top v-if="loading"></v-progress-linear>

                <v-card-title>
                    <div class="d-flex font-size-32 font-weight-bold">
                        Submission notes
                    </div>
                </v-card-title>
                <ValidationObserver ref="observer" tag="form">
                    <ValidationProvider
                        v-slot="{ errors }"
                        name="Submission Comment"
                        rules="required|min:250"
                        tag="div"
                        class="px-2"
                        vid="submission-comments">
                            <v-card-text>
                                <v-textarea
                                    v-model="note"
                                    persistent-hint
                                    class="mt-0 pt-0"
                                    :error-messages="errors"
                                    placeholder="Regarding any information to help with the deal"></v-textarea>
                            </v-card-text>
                    </ValidationProvider>
                </ValidationObserver>

                <v-card-actions class="pa-4 ma-2">
                    <v-btn @click="closeModal" :min-width="140" large depressed outlined color="secondary">
                        Close
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn @click="saveFileNote" :min-width="140" large depressed class="secondary">
                        Submit
                    </v-btn>
                </v-card-actions>

            </v-card>
    </v-dialog>
</template>
<script>
    import { mapState, mapGetters, mapActions, mapMutations } from 'vuex'

import validation from '~/components/shared/validation' 

    export default {
        mixins: [
            validation, 
        ],
        props: {
            dialog: {
                type: Boolean,
                default: false
            },
            loan: {
                type: Object,
                default: null
            }
        },
        data() {
            return {
                loading: false,
                note: ''
            }
        },
        async mounted() {
            if(this.loan) {
                this.note = this.loan.attributes["submission-comments"]
            }
        },
        methods: {
        ...mapActions('resource-actions', [
                'updateLoan'
            ]),
            closeModal() {
                this.$emit('close')
            },
            async saveFileNote() {

                const valid = await this.$refs.observer.validate()

                if(valid && this.loan) {

                    this.loading = true

                    let payload = {
                        type: 'loans',
                        id: this.loan.id,
                        attributes: {
                            "submission-comments": this.note
                        }
                    }

                    let res = await this.updateLoan(payload)

                    if(res.status == 200) {

                        this.$emit('success')
                        // this.$emit('close')

                    } else {

                        this.loading = false

                    }
                }
            }
        },
        watch: {

        },
    }
</script>