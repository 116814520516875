export default [
    {
        name: 'Full-Time',
        parent: {
            name: 'PAYG Employment'
        }
    },
    {
        name: 'Part-Time',
        parent: {
            name: 'PAYG Employment'
        }
    },
    {
        name: 'Casual',
        parent: {
            name: 'PAYG Employment'
        }
    },
    {
        name: 'Contract',
        parent: {
            name: 'PAYG Employment'
        }
    },
    {
        name: 'Self-Employed - Sole-Trader',
        parent: {
            name: 'Self-Employed'
        }
    },
    {
        name: 'Self-Employed - Company',
        parent: {
            name: 'Self-Employed'
        }
    },
    {
        name: 'Self-Employed - Other',
        parent: {
            name: 'Self-Employed'
        }
    },
    {
        name: 'Not Gainfully Employed',
        parent: {
            name: 'No Income'
        }
    },
    {
        name: 'Other Income',
        parent: {
            name: 'Other Income'
        }
    }
]