import Vue from "vue";

export const store = Vue.observable({

    // Resource
    loanType: null,
    appType: null,
    selectedEntity: 0,
    selectedEntityData: null,

    selectedEntityAll: false,
    selectedIndex: null,
    preSelectRoles: null,
    resources: {
        assets: [],
        liabilities: [],
        expenses: [],
        people: [],
        businesses: [],
        addresses: {
            assets: [],
        },
    },
    roleLiability: [],

    allAddresses: [],

    // Entities
    entities: [],
    entityParts: [],

    // Dialog
    saving: false,
    
    stepper: 0,
    steps: [],
    currentStep: {},

    // Kinds
    kindsLiabilityId: null,
    linkExpenses: null

});


export const actions = {

    // Resources

    setLinkExpenses(payload) {
        store.linkExpenses = payload;
    },
    setLoanType(payload) {
        store.loanType = payload;
    },
    setAppType(payload) {
        store.appType = payload;
    },

    setSelectedEntity(payload) {
        store.selectedEntity = payload;
    },
    setSelectedEntityData(payload) {
        store.selectedEntityData = payload;
    },

    setPeople(payload) {
        store.resources.people = payload;
    },

    setEntities(payload) {
        store.entities = payload;
    },
    setEntityParts(payload) {
        store.entityParts = payload;
    },

    setBusinesses(payload) {
        store.resources.businesses = payload;
    },

    setResources(payload) {

        // const {incomes, assets, expenses, liabilities} = payload

        let keys = Object.keys(payload)

        keys.forEach( key => {
            store.resources[key] = payload[key];
        });

    },
    updateResources(payload){

        let newData = store.resources[payload.type].filter(resource => {
            return resource.id != payload.id
        })

        newData = [
            payload,
            ...newData
        ]

        store.resources[payload.type] = newData

    },
    deleteResource(payload){
        
        let newData = store.resources[payload.type].filter(resource => {
            return resource.id != payload.id
        })

        store.resources[payload.type] = newData

    },
    addResource({key, value}) {
        store.resources[key].push(value);
    },

    setAddresses(payload) {

        store.resources.addresses = payload;
    },

    setAllAddresses(payload) {

        store.allAddresses = payload;
    },
    updateAddress({key, value}) {
        store.resources.addresses[key].push(value);
    },

    //  Dialog
    toggleSaving(payload) {
        store.saving = payload;
    },
    setCurrentStep(payload){
        store.currentStep = payload
    },
    setSteps(payload){
        store.steps = payload
    },
    pushStep(payload){
        store.steps.push(payload)
    },
    async nextStep(payload) {

        // Filter, remove obsolete kinds
        // console.log(payload)

        let obsolete = [
            //"11-19", // "Equipment Finance"
        ]

        let newPayload = {...payload}
        let children = [...newPayload.children]
        
        // check if payload children has only 1
        if (payload.children.length === 1) {
            newPayload = {...payload.children[0]}
        } else {
            newPayload.children = children.filter( child => {
                return !obsolete.includes(child.id)
            })
        }
        
        // reset steps if payload dont have children and setting income kind id
        if(newPayload.children.length == 0) {
            store.steps.length = store.stepper + 1
        } else {
            await store.steps.splice(store.stepper + 1, 0, newPayload)
        }

        store.saving = true

        setTimeout( () => {
            store.currentStep = newPayload 
            store.stepper = store.stepper+1
            store.saving = false
        }, 300)

    },

    selectNextStep() {
        store.saving = true
        // actions.setCurrentStep([])

        setTimeout( () => {
            store.stepper = store.stepper+1
            store.saving = false
        }, 300)
    },

    prevStep() {

        store.stepper = store.stepper-1
        store.saving = true

        setTimeout( () => {
            store.currentStep = store.steps[store.stepper] 
            store.saving = false
        }, 300)
    },

    setKindsAsset(payload){
        store.kindsAsset = payload 
    },
    setSelectedEntityAll(payload){
        store.selectedEntityAll = payload 
    },
    setPreSelectRoles(payload){
        store.preSelectRoles = payload 
    },
    resetAll(){

        store.loanType = null
        store.selectedEntity = 0

        actions.entityReset()
    
        // Dialog
        store.saving = false
        
        store.stepper = 0
        store.steps = []
        store.currentStep = null
    
        // Kinds
        store.kindsLiabilityId = null

    },
    entityReset(){
        
        store.selectedEntityAll = false
        store.preSelectRoles = [] 
        store.selectedEntityData = null
        
    },
    resetSteps() {
        store.steps = []
        store.kindsLiabilityId = null
        store.stepper = 0
        store.currentStep = null
    },
    setRoleLiability(payload) {
        store.roleLiability = payload
    },
    setKindsLiabilityId(payload) {
        store.kindsLiabilityId = payload
    },
    setSelectedIndex(payload) {
        store.selectedIndex = payload
    },

};