<template>
<div class="d-md-flex" style="width: 100%">

    <v-sheet color="light_gray_1" class="py-12 px-12" style="min-width: 45%;" outlined elevation="0">
        <v-img
            max-width="120"
            class="flex-brand"
            :src="require(`~/assets/images/nodifi.svg`)"
            position="center left"
            contain>
        </v-img>
        <div class="mt-2 font-size-20 light_gray_2--text">Data</div>
        <v-list color="transparent">
            <v-list-item two-line class="px-0">
                <v-list-item-content>
                    <v-list-item-title class="font-weight-medium">Driver Licence Number </v-list-item-title>
                    <v-list-item-subtitle>{{data ? data.attributes['drivers-licence-card-number'] : '&mdash;'}}</v-list-item-subtitle>
                </v-list-item-content>
            </v-list-item>
            <v-list-item two-line class="px-0">
                <v-list-item-content>
                    <v-list-item-title class="font-weight-medium">Driver Licence Card Number</v-list-item-title>
                    <v-list-item-subtitle>{{data ? data.attributes['drivers-licence-number'] : '&mdash;'}}</v-list-item-subtitle>
                </v-list-item-content>
            </v-list-item>
            <v-list-item two-line class="px-0">
                <v-list-item-content>
                    <v-list-item-title class="font-weight-medium">Driver Licence State</v-list-item-title>
                    <v-list-item-subtitle>{{data ? data.attributes['drivers-licence-state'] : '&mdash;'}}</v-list-item-subtitle>
                </v-list-item-content>
            </v-list-item>
        </v-list>
    </v-sheet>

    <v-sheet class="py-12 px-12">
        <v-img
            max-width="300"
            class="flex-brand"
            :src="require(`~/assets/images/logo/cropped/lender-logo-financeone.png`)"
            position="center left"
            contain>
        </v-img>
        <div class="mt-2 mb-4 font-size-20 light_gray_2--text">Requirements</div>

        <ValidationObserver ref="observer" tag="form">

            <ValidationProvider
              key="driversLicenceHasSpecialRestriction"
              v-slot="{ errors }" 
              :name="labels['driversLicenceHasSpecialRestriction']"
              :rules="validation_rules.attributes['driversLicenceHasSpecialRestriction']"
              vid="driversLicenceHasSpecialRestriction"
            >
                <ButtonGroup3
                v-model="driversLicenceHasSpecialRestriction"
                :error-messages="errors"
                :options="['Yes','No']"
                :label="labels['driversLicenceHasSpecialRestriction']"
              />
            </ValidationProvider>

            <div class="py-0" v-if="visible['driversLicenceSpecialRestrictionDetails']">
              <ValidationProvider
                key="driversLicenceSpecialRestrictionDetails"
                v-slot="{ errors }"
                :name="labels['driversLicenceSpecialRestrictionDetails']"
                vid="driversLicenceSpecialRestrictionDetails"
                :rules="validation_rules.attributes['driversLicenceSpecialRestrictionDetails']">
              <v-text-field
                  tile
                  type="text"
                  :label="labels['driversLicenceSpecialRestrictionDetails']"
                  color="secondary"
                  :error-messages="errors"
                  v-model="resources.person.attributes.lender.financeOne['driversLicenceSpecialRestrictionDetails']"
                  required>
                </v-text-field>
              </ValidationProvider>
            </div>

            <div class="py-0" v-if="withBoat">
              <ValidationProvider
                key="driversLicenceClass"
                v-slot="{ errors }"
                :name="labels['driversLicenceClass']"
                vid="driversLicenceClass"
                :rules="validation_rules.attributes['driversLicenceClass']"
              >
              <v-select
                  :items="[
                    {value: 'general_boating', text: 'General Boating'},
                    {value: 'personal_watercraft', text: 'Personal Watercraft'}
                  ]"
                  :label="labels['driversLicenceClass']"
                  :error-messages="errors"
                  v-model="resources.person.attributes.lender.financeOne['driversLicenceClass']"
                  color="secondary"/>
              </ValidationProvider>
            </div>
            <div class="py-0">  <!-- v-if="withVehicle" -->
              <ValidationProvider
                key="driversLicenceType"
                v-slot="{ errors }"
                :name="labels['driversLicenceType']"
                vid="driversLicenceType"
                :rules="validation_rules.attributes['driversLicenceType']"
              >
              <v-select
                  :items="[
                    {value: 'learner', text: 'Learner'},
                    {value: 'provisional', text: 'Provisional'},
                    {value: 'probational', text: 'Probational'},
                    {value: 'open', text: 'Open'},
                  ]"
                  :label="labels['driversLicenceType']"
                  :error-messages="errors"
                  v-model="resources.person.attributes.lender.financeOne['driversLicenceType']"
                  color="secondary"/>
              </ValidationProvider>
            </div>

          <div class="py-0">
              <v-alert dense outlined type="error" v-if="errors.length > 0">
                  <div v-for="(msg, index) in errors" v-html="msg" :key="index"></div>
              </v-alert>
          </div>
        </ValidationObserver>
    </v-sheet>

</div>
</template>

<script>
import { mapActions, mapMutations, mapGetters } from "vuex";
import subscriptions from "~/components/shared/subscriptions";
import shared from "~/components/v1/submission/FinanceOneEntities1/__shared";
import ButtonGroup3 from "~/components/base/ButtonGroup3";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import FlexDatePicker3 from '~/components/base/FlexDatePicker3';
import veeValidate from '~/components/shared/validation'
import formHelpers from '~/components/shared/form-helpers'

export default {
  components: { ButtonGroup3, FlexDatePicker3 },
  mixins: [formHelpers, subscriptions, shared, veeValidate],
  props: {
    data: {
      type: Object,
      default: null,
    },
    withBoat: {
      type: Boolean,
      default: false,
    },
    withVehicle: {
      type: Boolean,
      default: false,
    },
    updater: undefined,
  },
  data() {
    return {
      driversLicenceHasSpecialRestriction: false,
      resources: {
        person: {
          id: null,
          type: "people",
          attributes: {
            "lender": {
                "financeOne": {
                  "driversLicenceHasSpecialRestriction": false,
                  "driversLicenceSpecialRestrictionDetails": null,
                  "driversLicenceClass": null,
                  "driversLicenceType": null,
                }
            }
          },
        },
      },
      visible: {
        "driversLicenceSpecialRestrictionDetails": false,
      },
      labels: {
        "driversLicenceHasSpecialRestriction": "Does the applicant have any licence special restrictions?",
        "driversLicenceSpecialRestrictionDetails": "Special Restrictions Details",
        "driversLicenceType": "Drivers Licence Type",
        "driversLicenceClass": "Drivers Licence Class",
      },
      validation_rules: {
        attributes: {
          "driversLicenceHasSpecialRestriction": "required",
          "driversLicenceSpecialRestrictionDetails": "required|max:5000|min:20",
          "driversLicenceClass": "required",
          "driversLicenceType": "required",
        },
      },
      options: {
      },
      errors: [],
    };
  },
  computed: {

  },
  mounted() {
    this.updateResourceFields();
  },
  methods: {
    ...mapActions("resource-actions", ["getPerson"]),
    async validate() {

      const valid = await this.$refs.observer.validate();

      let allValid = this.errors.length == 0 && valid

      if (allValid) {
        
        const {person} = this.resources

        this.$emit("submit", {
          person: {
            id: person?.id,
            type: "people",
            attributes: {
              "lender": {
                  "financeOne": {
                    ...person.attributes.lender.financeOne
                  }
              }
            },
          },
        });

      }
    },
    reset() {
      this.$refs.observer.reset();
    },
    async updateResourceFields() {

      // console.log('Updating Resource Fields...')
      // console.log('_FormPersonalInformation', this.data.id)

      this.$emit("loading", true);

      const { data } = this;

      if (data) {

        let res = await this.getPerson(data.id);

        this.resources.person = this.filterResourceFields(
          this.resources.person,
          res.data.data
        );

        Object.keys(this.resources.person.attributes).forEach((key) => {
          this.resources.person.attributes[key] = this.resources.person.attributes[key];
        });

        const { driversLicenceHasSpecialRestriction } = this.resources.person.attributes.lender.financeOne

        this.driversLicenceHasSpecialRestriction = driversLicenceHasSpecialRestriction === null ? 'No' : driversLicenceHasSpecialRestriction === true ? 'Yes' : 'No'
        this.resources.person.attributes.lender.financeOne.driversLicenceHasSpecialRestriction = driversLicenceHasSpecialRestriction === null ? false : driversLicenceHasSpecialRestriction

        this.$emit("loading", false);
      } else {
        this.$emit("loading", false);
      }
    },
  },
  watch: {
    updater(val) {
      if (val) {
        this.updateResourceFields();
      }
    },
    driversLicenceHasSpecialRestriction(val) {
      if(val == "Yes"){
          this.visible.driversLicenceSpecialRestrictionDetails = true
          this.resources.person.attributes.lender.financeOne.driversLicenceHasSpecialRestriction = true
      } else {
          this.visible.driversLicenceSpecialRestrictionDetails = false
          this.resources.person.attributes.lender.financeOne.driversLicenceHasSpecialRestriction = false
          this.resources.person.attributes.lender.financeOne.driversLicenceSpecialRestrictionDetails = ""
      }
    },
  },
};
</script>

<style lang="scss" scoped>
</style>

