<template>
  <v-app>

      <!-- 

          Layout Description:
          - Logo not clickable   
          - Logo center   
          - No Footer

       -->

    <v-app-bar fixed flat app color="white" height="65"> 
        <v-container fluid px-4>
            <div class="d-flex">
                <!-- Left : Nodifi Logo : Fixed for all tenants -->
                <v-card flat tile class="px-4">
                    <img class="flex-brand d-block" :src="require(`~/assets/images/nodifi.svg`)" height="30"/>
                </v-card>

                <v-spacer></v-spacer>

                <!-- Right: Tenant Logo : Visible only on white labelled themes -->
                <v-card flat tile class="px-4" v-if="isWhiteLabelled">
                    <img class="flex-brand d-block" :src="require(`~/themes/${getActiveTheme.name}/svg/logo.svg`)" height="30"/>
                </v-card>
            </div>
        </v-container>
    </v-app-bar>

    <v-main class="white">
        <nuxt />
    </v-main>

  </v-app>
</template>

<script>

    import { mapMutations, mapActions, mapGetters, mapState } from 'vuex';
    import meta from '~/layouts/meta'

    export default {
        mixins: [
            meta
        ],
        name: 'BasicLayout3',
        data () {
            return {

            }
        },
        computed: {
            ...mapGetters({
                getActiveTheme: 'themes/getActiveTheme',
                getGroupSlug: 'slug/getGroupSlug',
                isWhiteLabelled: 'isWhiteLabelled',
            }),
        }
    }

</script>

<style scoped lang="scss">
</style>
