<template>

    <div v-if="vb.mdAndUp" class="d-flex" style="width: 100%">
        <v-sheet color="light_gray_1" class="py-8 px-12" width="50%" outlined elevation="0">
            <v-img 
                max-width="120"
                class="flex-brand" 
                :src="require(`~/assets/images/nodifi.svg`)" 
                position="center left" 
                contain>
            </v-img>
            <div class="mt-2 mb-6 font-size-20">Data</div>

            <v-list color="transparent">

                <v-list-item two-line class="px-0">
                    <v-list-item-content>
                        <v-list-item-title class="font-weight-medium">Job Title</v-list-item-title>
                        <v-list-item-subtitle>{{data.income.attributes['job-title'] ? data.income.attributes['job-title'] : '&mdash;'}}</v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>

                <v-list-item two-line class="px-0">
                    <v-list-item-content>
                        <v-list-item-title class="font-weight-medium">Primary Country of Citizenship</v-list-item-title>
                        <v-list-item-subtitle>{{data.person.attributes['primary-country-of-citizenship'] ? data.person.attributes['primary-country-of-citizenship'] : '&mdash;'}}</v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>

                <v-list-item two-line class="px-0">
                    <v-list-item-content>
                        <v-list-item-title class="font-weight-medium">Secondary Country of Citizenship</v-list-item-title>
                        <v-list-item-subtitle>{{data.person.attributes['secondary-country-of-citizenship'] ? data.person.attributes['secondary-country-of-citizenship'] : '&mdash;'}}</v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>

                <v-list-item two-line class="px-0">
                    <v-list-item-content>
                        <v-list-item-title class="font-weight-medium">Visa Number</v-list-item-title>
                        <v-list-item-subtitle>{{data.person.attributes['visa'] ? data.person.attributes['visa'] : '&mdash;'}}</v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>
       
            </v-list>

        </v-sheet>
        <v-sheet class="py-8 px-12" width="50%">

            <v-img 
                max-width="120"
                class="flex-brand" 
                :src="require(`~/assets/images/logo/cropped/lender-logo-society.png`)" 
                position="center left" 
                contain>
            </v-img>
            <div class="mt-2 mb-8 font-size-20">Requirements</div>

            <ValidationObserver ref="observer" tag="form">
                <template v-for="(field, key) in resources.income.attributes" >
                    <ValidationProvider 
                            :key="key"
                            v-slot="{ errors }" 
                            :name="labels[key]"
                            :rules="getRules(key)" 
                            :vid="key">
                                <v-select
                                    v-if="isFieldType('picklist', key)"
                                    v-model="resources.income.attributes[key]"
                                    :items="getOptions(key)"
                                    :error-messages="errors"
                                    color="secondary"
                                    :label="labels[key]"
                                    ></v-select>
                                <v-text-field 
                                    v-else
                                    tile
                                    type="text"
                                    :label="labels[key]"
                                    color="secondary"
                                    :error-messages="errors"
                                    v-model="resources.income.attributes[key]"
                                    required>
                                </v-text-field>
                    </ValidationProvider>
                </template>

                <template v-for="(field, key) in resources.person.attributes" >
                    <ValidationProvider 
                            :key="key"
                            v-slot="{ errors }" 
                            :name="labels[key]"
                            :rules="getRules(key)" 
                            :vid="key">
                                <v-select
                                    v-if="isFieldType('picklist', key)"
                                    v-model="resources.person.attributes[key]"
                                    :items="getOptions(key)"
                                    :error-messages="errors"
                                    color="secondary"
                                    :label="labels[key]"
                                    ></v-select>

                                <v-text-field 
                                    v-else
                                    tile
                                    type="text"
                                    :label="labels[key]"
                                    color="secondary"
                                    :error-messages="errors"
                                    v-model="resources.person.attributes[key]"
                                    required>
                                </v-text-field>
                    </ValidationProvider>
                </template>
            </ValidationObserver>

        </v-sheet>
    </div>

    <!-- mobile version -->
    <div v-else>

        <v-card flat class="mx-5 rounded-xl" :style="`background-color: ${hexToRGBA(getActiveTheme.colors.secondary, 0.2)}`">

            <v-card-text class="d-flex py-1">
                <v-row>
                    <v-col cols="6" class="pa-1">
                        <v-card
                        min-height="45"
                            flat class="rounded-xl px-1"
                            :color="tabSelection == 'nodifi' ? 'white' : 'transparent'">
                            <div class="d-flex justify-center pt-1" @click="selectTab('nodifi')">
                                <v-img 
                                    max-width="55"
                                    class="flex-brand" 
                                    :src="require(`~/assets/images/nodifi.svg`)" 
                                    position="center left" 
                                    contain>
                                </v-img>
                                <div class="mt-3 font-size-12 pl-1">Data</div>
                            </div>
                        </v-card>
                    </v-col>
                    <v-col cols="6" class="pa-1">
                        <v-card
                            flat class="rounded-xl px-1"
                            :color="tabSelection == 'society' ? 'white' : 'transparent'">
                            <div class="d-flex flex-column" @click="selectTab('society')">
                                <v-img 
                                    max-width="45"
                                    class="flex-brand mx-auto mt-n1" 
                                    :src="require(`~/assets/images/logo/cropped/lender-logo-society.png`)" 
                                    position="center left" 
                                    contain>
                                </v-img>
                                <div class="font-size-12 pl-1 mx-auto mt-n3">Requirements</div>
                            </div>
                        </v-card>
                    </v-col>
                </v-row>
            </v-card-text>
            
        </v-card>

        <v-list v-show="tabSelection == 'nodifi'" class="mx-8" color="transparent">

            <v-list-item two-line class="px-0">
                <v-list-item-content>
                    <v-list-item-title class="font-weight-medium">Job Title</v-list-item-title>
                    <v-list-item-subtitle>{{data.income.attributes['job-title'] ? data.income.attributes['job-title'] : '&mdash;'}}</v-list-item-subtitle>
                </v-list-item-content>
            </v-list-item>

            <v-list-item two-line class="px-0">
                <v-list-item-content>
                    <v-list-item-title class="font-weight-medium">Primary Country of Citizenship</v-list-item-title>
                    <v-list-item-subtitle>{{data.person.attributes['primary-country-of-citizenship'] ? data.person.attributes['primary-country-of-citizenship'] : '&mdash;'}}</v-list-item-subtitle>
                </v-list-item-content>
            </v-list-item>

            <v-list-item two-line class="px-0">
                <v-list-item-content>
                    <v-list-item-title class="font-weight-medium">Secondary Country of Citizenship</v-list-item-title>
                    <v-list-item-subtitle>{{data.person.attributes['secondary-country-of-citizenship'] ? data.person.attributes['secondary-country-of-citizenship'] : '&mdash;'}}</v-list-item-subtitle>
                </v-list-item-content>
            </v-list-item>

            <v-list-item two-line class="px-0">
                <v-list-item-content>
                    <v-list-item-title class="font-weight-medium">Visa Number</v-list-item-title>
                    <v-list-item-subtitle>{{data.person.attributes['visa'] ? data.person.attributes['visa'] : '&mdash;'}}</v-list-item-subtitle>
                </v-list-item-content>
            </v-list-item>
    
        </v-list>

        <div v-show="tabSelection != 'nodifi'" class="mx-8">
            <ValidationObserver ref="observer" tag="form">
                <template v-for="(field, key) in resources.income.attributes" >
                    <ValidationProvider 
                            :key="key" 
                            v-slot="{ errors }" 
                            :name="labels[key]"
                            :rules="getRules(key)" 
                            :vid="key">
                                <v-select
                                    v-if="isFieldType('picklist', key)"
                                    v-model="resources.income.attributes[key]"
                                    :items="getOptions(key)"
                                    :error-messages="errors"
                                    color="secondary"
                                    :label="labels[key]"
                                    ></v-select>
                                <v-text-field 
                                    v-else
                                    tile
                                    type="text"
                                    :label="labels[key]"
                                    color="secondary"
                                    :error-messages="errors"
                                    v-model="resources.income.attributes[key]"
                                    required>
                                </v-text-field>
                    </ValidationProvider>
                </template>

                <template v-for="(field, key) in resources.person.attributes" >
                    <ValidationProvider 
                            :key="key"
                            v-slot="{ errors }" 
                            :name="labels[key]"
                            :rules="getRules(key)" 
                            :vid="key">
                                <v-select
                                    v-if="isFieldType('picklist', key)"
                                    v-model="resources.person.attributes[key]"
                                    :items="getOptions(key)"
                                    :error-messages="errors"
                                    color="secondary"
                                    :label="labels[key]"
                                    ></v-select>

                                <v-text-field 
                                    v-else
                                    tile
                                    type="text"
                                    :label="labels[key]"
                                    color="secondary"
                                    :error-messages="errors"
                                    v-model="resources.person.attributes[key]"
                                    required>
                                </v-text-field>
                    </ValidationProvider>
                </template>
            </ValidationObserver>
        </div>
        

    </div>

</template>

<script>

    import { mapActions, mapState, mapGetters } from 'vuex'
    import subscriptions from '~/components/shared/subscriptions'
    import shared from '~/components/v1/submission/LenderSocietyOnePeople1/__shared'


    export default {
        components: {},
        mixins: [subscriptions, shared],
        props: {
            data: {
                type: Object,
                default: null
            },
            updater: undefined
        },
        data() {
            return {
                tabSelection: 'nodifi',
                resources: {
                    person: {
                        id: null,
                        type: "people",
                        attributes: {
                            "lender-society-one-visa-status" : null
                        }
                    },
                    income: {
                        id: null,
                        type: "incomes",
                        attributes: {
                            "lender-society-one-occupation" : null
                        }
                    }
                },

                labels: {
                    "lender-society-one-occupation": "Occupation",
                    "lender-society-one-visa-status": "Visa Status",
                },
                validation_rules: {
                    attributes: {
                        "lender-society-one-visa-status": "required|in:Australian Citizen,New Zealand Citizen,Permanent Resident,Student Visa,Working Visa,Other",
                        "lender-society-one-occupation": "required|in:Director,Manager,Office Staff,Professional,Sales/Retail,Student,Trade,Other",
                    }
                },
                options: {
                    "lender-society-one-visa-status" : [
                        { text: "Australian Citizen", value: "Australian Citizen" },
                        { text: "New Zealand Citizen", value: "New Zealand Citizen" },
                        { text: "Permanent Resident", value: "Permanent Resident" },
                        { text: "Student Visa", value: "Student Visa" },
                        { text: "Working Visa", value: "Working Visa" },
                        { text: "Other", value: "Other" }
                    ],
                    "lender-society-one-occupation" : [
                        { text: "Director", value: "Director" },
                        { text: "Manager", value: "Manager" },
                        { text: "Office Staff", value: "Office Staff" },
                        { text: "Professional", value: "Professional" },
                        { text: "Sales/Retail", value: "Sales/Retail" },
                        { text: "Student", value: "Student" },
                        { text: "Trade", value: "Trade" },
                        { text: "Other", value: "Other" }
                    ]
                }
            }
        },
        computed: {
            vb() {
                return this.$vuetify.breakpoint
            }
        },
        mounted() {
            this.updateResourceFields()
        },
        methods: {
            ...mapActions('resource-actions', [
                'getPerson',
                'getIncome',
            ]),
            selectTab(tab) {
                this.tabSelection = tab
            },
            async validate(){

                const valid = await this.$refs.observer.validate()

                if(valid) {

                    // attache person id
                    // this.resources.person.id = this.data.id

                    this.$emit('submit', this.resources)

                }

            },
            reset(){

                this.$refs.observer.reset()

            },
            async updateResourceFields(){

                this.$emit('loading', true)

                const {data} = this

                if(data) {

                    let promise1 = this.getPerson(data.person.id)
                    let promise2 = this.getIncome(data.income.id)

                    const res = await Promise.all([
                            promise1, 
                            promise2, 
                    ]) 

                    promise1 = res[0]
                    promise2 = res[1]

                    this.resources.person = this.filterResourceFields(this.resources.person, promise1.data.data)
                    this.resources.income = this.filterResourceFields(this.resources.income, promise2.data.data)

                    this.$emit('loading', false)

                } else {

                    this.$emit('loading', false)
                }

            }
        },
        watch: {
            updater(val) {
                if(val) {
                    this.updateResourceFields()
                }
            },
        }
    }
</script>

<style lang="scss" scoped>

</style>