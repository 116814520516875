import {stringify} from 'qs'

export const state = () => ({
   group: {
       id: '',
       name: ''
   },
   application: {
       id: '',
       guid: ''
   },
   resources: {},
   resourcesCollection: {},
   quotes: {},
   users: [],
   initialDataFromQuote: false,
   nvicTemp: {},
   nvic: {},
   appInitiator: false,
   selectedLoanType: '' // Consumer Secured or Commercial Secured
})

export const getters = {
    getGroup: (state) => {
        return state.group
    },
    getApplication: (state) => {
        return state.application
    },
    getQuotes: (state) => {
        return state.quotes
    },
    selectedLoanType: (state) => {
        return state.selectedLoanType
    },
    /**
     * Not sure of the use of this, will refactor in the future.
     */
    securedOnly: (state) => {
        // return state.selectedLoanType?.toLowerCase().indexOf('secure') >= 0 ? true : false
        return true
    },
    fullNvic: (state) => { return state.nvic },
    selectedNvic: (state) => {         
        let data = {
            'nvic': state?.nvic?.details?.['nvic-cur'] ? state?.nvic?.details?.['nvic-cur'] : '',
            'year-of-manufacture': state?.nvic?.details?.['year-create'] ? state?.nvic?.details?.['year-create'] : '',
            'make': state?.nvic?.details?.['manufacturer-name'] ? state?.nvic?.details?.['manufacturer-name'] : '',
            'model': state?.nvic?.details?.['family-name'] ? state?.nvic?.details?.['family-name'] : '',
            'variant': state?.nvic?.details?.['variant-name'] ? state?.nvic?.details?.['variant-name'].length ? state?.nvic?.details?.['variant-name'] : '' : '',
            'retail-value': state?.nvic?.details?.['retail'] ? state?.nvic?.details?.['retail'] : '',
            'trade-low': state?.nvic?.details?.['trade-low'] ? state?.nvic?.details?.['trade-low'] : '',
            'trade-value': state?.nvic?.details?.['trade'] ? state?.nvic?.details?.['trade'] : '',
            'dealer-retail': state?.nvic?.details?.['rrp'] ? state?.nvic?.details?.['rrp'] : '',
        }
        return data
    },
    commercialOnly: (state) => {
        // console.log(state.selectedLoanType?.toLowerCase().indexOf('commercial') >= 0 ? true : false)
        return state.selectedLoanType?.toLowerCase().indexOf('commercial') >= 0 ? true : false
    },
    getFilteredUsers: (state) => {
        return state.users
    },
    getResources: (state) => { return state.resources },
    getResourceCollection: (state) => { return state.resourcesCollection },
    initialDataFromQuote: (state) => { return state.initialDataFromQuote }
}

export const actions = {

    /**
     * ---------------------------------------
     * ACTIONS FOR Quote Payload
     * ---------------------------------------
     */

    // update quote settings with quotes
     async updateQuoteSettings({}, data = {}) {

        let res = await this.$api.patch(`quote-settings/${data.id}?run-quote&include=quote`, {
            data: data
        })
        
        return res
    },

    // add quote settings with quotes
    async addQuoteSettings({}, data = {}) {

        let res = await this.$api.post(`quote-settings?run-quote&include=quote`, {
            data: data
        })
        
        return res
    },

    // generate pdf on quote
    async makeFileQuote({}, data = {}) {

        let res = await this.$api.post(`run/make-file`, {
            data: data
        })
        
        return res
    },

    async generateQuotes({commit}, data = {}) {

        if ( data.data.attributes['loan-product'] === 'Consumer Asset' || data.data.attributes['loan-product'] === 'Consumer Secured' ) {
            data.data.attributes['loan-product'] = 'Consumer Asset'
        }
        else data.data.attributes['loan-product'] = 'Commercial Asset'

        let res = await this.$api.post(`run/quote`, data)
        
        if (res.status === 200)
            commit('onOkGetQuote', res.data.data)        
        return res
    },

    async saveQuote({}, data = {}) {
        // if ( data.attributes['loan-product'] === 'Consumer Asset' || data.attributes['loan-product'] === 'Consumer Secured' ) {
        //     data.attributes['loan-product'] = 'Consumer Secured'
        // }
        // else data.attributes['loan-product'] = 'Commercial Secured'

        return await this.$api.post(`quote-settings`, { data: data })
    },

    async deleteQuote({}, id = '') {
        return await this.$api.delete(`quote-settings/${id}`)
    },

    async getQuote({}, id = '') {
        return await this.$api.get(`quote-settings/${id}`)
    },

    async updateQuote({}, obj = {}) {

        // if (  obj.attributes['loan-product'] === 'Consumer Asset' ||  obj.attributes['loan-product'] === 'Consumer Secured') {
        //     obj.attributes['loan-product'] = 'Consumer Secured'
        // }
        // else obj.attributes['loan-product'] = 'Commercial Secured' 
        
        return await this.$api.patch(`quote-settings/${obj.id}`, {
            data: obj
        })
    },

    async deactivateQuote({}, obj = {}) {
        return await this.$api.patch(`quote-settings/${obj.id}`, {
            data: obj
        })
    },

    async getQuotes({}, obj = {}) {
        return await this.$api.get(`quote-settings`, {
            params: obj.data,
            paramsSerializer: params => stringify(params)
        })
    },

    async getUserQuotes({}, obj = {}) {
        return await this.$api.get(`users/${obj.user_filter}/quote-settings`, {
            params: obj.data,
            paramsSerializer: params => stringify(params)
        })
    },

    /**
     * ---------------------------------------
     * ACTIONS FOR Create Group 
     * ---------------------------------------
     */
    async createGroup({commit}, id = '') {
        let res = await this.$api.post(`groups`, {
            "data": {
                "type": "groups",
                "attributes": {
                    "name": new Date().getTime(),
                    "slug": new Date().getTime(),
                }
            }
        })
        if (res.status === 201)
            commit('onOkGetGroup', res.data.data)        
    },

    /**
     * ---------------------------------------
     * ACTIONS FOR Create Application 
     * ---------------------------------------
     */
    async createApplication({commit}, groupSlug = '') {
        let res = await this.$api.post(`applications`, {
            "data": {
                "type": "applications",
                "attributes": {},
                "relationships": {
                    "group": {
                        "data":{
                            "type": "groups",
                            "id": groupSlug
                        }
                    }
                }
            }
        })
        
        if (res.status === 201)
            commit('onOkGetApplication', res.data.data)        
    },

    /**
     * ---------------------------------------
     * Asset Information 
     * ---------------------------------------
     */
    async createAssetInformation({commit}, data = {}) {
        let res = await this.$api.post(`assets`, {
            data: data
        })
        return res
    },
    async updateAssetInformation({commit}, data = {}) {
        let res = await this.$api.patch(`assets/${data.id}`, {
            data: data
        })
        return res
    },

    /**
     * ---------------------------------------
     * Loan Requirement
     * ---------------------------------------
     */
    async createLoanRequirement({commit}, data = {}) {
        let res = await this.$api.post(`loans`, {
            data: data
        })
        return res
    },
    async updateLoanRequirement({commit}, data = {}) {
        let res = await this.$api.patch(`loans/${data.id}`, {
            data: data
        })
        return res
    },

    /**
     * ---------------------------------------
     * Client Situation
     * ---------------------------------------
     */
    async createClientSituation({commit}, data = {}) {
        let res = await this.$api.post(`loans`, {
            data: data
        })
        return res
    },
    async updateClientSituation({commit}, data = {}) {
        let res = await this.$api.patch(`loans/${data.id}`, {
            data: data
        })
        return res
    },

    /**
     * ---------------------------------------
     * Request Template
     * ---------------------------------------
     */
    async fetchTemplates({}, params = {}) {
        let res = await this.$api.post(
            `applications?run-template=${params.template}&include=${params.include}`,
            params.body)
        return res
    },
    async getUsers({commit}) {
        let res = await this.$api.get('users')
        if ( res.status === 200 ) {
            commit('onOkGetUsers', res.data.data)
        }
    },
    async getUsersFromSearch({commit}, param = {}) {
        let res = await this.$api.get('users', {
            params: {
                page: param.page,
                'filter[]': param.filter,
                sort: param.sort
            },
            paramsSerializer: params => stringify(params)
        })
        if ( res.status === 200 ) {
            commit('onOkGetUsers', res.data.data)
        }
    },

    /**
     * ---------------------------------------
     * Request Flows and Flow Statuses
     * ---------------------------------------
     */
    async getFlowsStepStatuses({commit}, param = {}) {
        let res = await this.$api.get(`flow-statuses?include=flow,step-statuses`, {
            params: {
                page: {
                    number: 1,
                    size: 500
                },
                filter: param.filter
            },
            paramsSerializer: params => stringify(params)
        })
        return res
    },

    async getFlowStatus({commit}, id = '') {
        let res = await this.$api.get(`flow-statuses/${id}?include=flow,step-statuses`)
        return res
    },

    // get soca pdf
    async generateSocaPDF({}, id = '') {
        let res = await this.$api.patch(`applications/${id}?run-bid&include=bid`, {
            data: {
                type: 'applications',
                id: id
            }
        })
        return res
    }
}

export const mutations = {
    onOkGetQuote(state, data) {
        state.quotes = data
    },
    onOkGetGroup(state, data) {
        state.group.id = data.id
        state.group.name = data.attributes.name
    },
    onOkGetApplication(state, data) {
        state.application.id = data.id
        state.application.guid = data.attributes.guid
    },
    setselectedLoanType(state, data) {
        // console.log(data)
        state.selectedLoanType = data
    },
    setResources(state, data) {
        state.resources = data
    },
    setResourceCollection(state, data) {
        state.resourcesCollection = data
    },
    setInitialDataFromQuote(state, data) {
        state.initialDataFromQuote = data
    },
    onOkGetUsers(state, data) {
        
        state.users = []
        
        data.forEach( d => {
            state.users.push(
                { 
                    id: d.id,
                    name: d.attributes.username,
                    email: d.attributes.email,
                }
            )
        })        
    },
    setNvic(state, data) {
        state.nvic = data
    },
    // setNvicTemp(state, data) {
    //     state.nvicTemp = data
    // },
    setAppInitiator(state) {
        state.appInitiator = true
    },
    unsetAppInitiator(state) {
        state.appInitiator = false
    }
}