import {stringify} from 'qs'

import lendersList from '~/config/lenders'
export const actions = {

    /**
     *
     *
     * ---------------------------------------
     * ACTIONS FOR SImpleKYC page
     * ---------------------------------------
     */
    async getAllSimpleKYC({}, params = {}) {
        return await this.$api.get('kyc', {
            params: params
        })
    },

    async getSimpleKYC({}, id = null) {
        return await this.$api.get(`kyc/${id}`)
    },
    /**
     *
     *
     * ---------------------------------------
     * create kyc record on database
     * data = {
     *  entity: data.entity,
        abn: data.abn, 
        acn: data.acn, 
        source: 'SimpleKYC'
     * }
        either abn or acn must be present
     * ---------------------------------------
     */

    async addSimpleKYC({}, data = {}) {
        return await this.$api.post('kyc', data)
    },

    async searchSimpleKYC({}, data = {}) {
        return await this.$api.post('kyc/search', {
            searchTerm: data.searchTerm
        })
    },

    async addKYCApplication({}, data = {}) {
        return await this.$api.post(`kyc/${data.id}/applications`, {group: data.group})
    },

    /**
     *
     *
     * ---------------------------------------
     * ACTIONS FOR home page
     * ---------------------------------------
     */
    async getDashboard({}, obj = {}) {
        let res = await this.$api.get('dashboard', {
            params: {
                data: {
                    unit: obj.unit,
                    interval: obj.interval
                },
            },
            paramsSerializer: params => stringify(params)
        })
        return res.data.data
    },

    /**
     * TODOs: Could use getPeopleByResource instead
     *
     * ---------------------------------------
     * ACTIONS FOR ReferenceEditor1
     * ---------------------------------------
     */
    async getReferencePerson({rootState}, id = '') {
        let endpoint = `people/${id}`
        let res = await this.$api.get(endpoint)
        return res.data.data
    },

    async addNewReference({}, data = {}) {
        let endpoint = `references`
        let res = await this.$api.post(endpoint, {"data": data})
        return res
    },

    async updateReference({}, data = {}) {
        let res = await this.$api.patch(`references/${data.id}`, {
            data: data
        })
        return res
    },

    async saveReferencesOfEmptyRecord({}, params = {}) {
      let results = []
      let res1 = null
      if (!params.selectedAddressId) {

        res1 = await this.$api.post(`addresses`, {
              data: {
                  type: params.data.address.type,
                  attributes: params.payload.address,
                  relationships: params.data.address.relationships
              }
          })

          results.push(res1)
      }

        let refPayload = {
            type: params.data.reference.type,
            attributes: params.payload.reference,
            relationships: params.data.reference.relationships
                // kind: params.data.reference.relationships.kind,
                // application: params.data.reference.relationships.application

        }

        refPayload.relationships.address = {
            data: { id: params.selectedAddressId ? params.selectedAddressId : res1.data.data.id, type: 'addresses' }
        }
        // refPayload.relationships.address.data.id = '100' //res1.data.data.id

        let res2 = await this.$api.post(`references`, {
            data: refPayload
        })

        results.push(res2)

        return results
    },

    async saveReferences({}, params = {}) {

        let payload = []

        params.data.forEach( req => {
            // PATCH ACTIONS
            if ( req.ref === 'reference' ) {
              let address = null
              if (params.selectedAddressId) {
                address = { data: { id:params.selectedAddressId, type: 'addresses' } }
              }
              payload.push(
                  this.$api.patch(`references/${req.data.id}`, {
                      data: {
                          id: req.data.id,
                          type: req.data.type,
                          attributes: params.payload.reference,
                          relationships: {
                            ...req.data.relationships,
                            address: address ? address : req.data.relationships.address
                          }
                      }
                  })
              )
            }
            if ( req.ref === 'address' && !params.selectedAddressId) {
              payload.push(
                  this.$api.patch(`addresses/${req.data.id}`, {
                      data: {
                          id: req.data.id,
                          type: req.data.type,
                          attributes: params.payload.address,
                          relationships: req.data.relationships
                      }
                  })
              )
            }
        })

        return Promise.all(payload)
        .then( data => {
                return data
            }
        )
        .catch( err => {
                return err
            }
        )
    },

    async deleteReference({}, id = '') {
        let res = await this.$api.delete(`references/${id}`)
        return res
    },
    /** END OF ReferenceEditor1 ACTIONS */





	/**
     * ---------------------------------------
     * ACTIONS FOR ResidentialHistoryEditor1
     * ---------------------------------------
     */
    async getOccupancyAddress({}, params = {}) {

        let response = await this.$api.get(`occupancies/${params.id}`,
            {
                params: {
                    "include": params.include
                }
            }
        )
        return response
    },

    async getOccupanciesData({rootState}, params = { include: 'occupancies,parent-parts'}) {
        let endpoint = `applications/${rootState.flows.appId}/people`
        let res = await this.$api.get(endpoint, { params: params })
        return res
    },

    async saveOccupancies({}, reqs = []) {

        let payload = []

        reqs.forEach( request_payload => {

            if (request_payload.id || request_payload.id != null)

                payload.push( this.$api.patch(`occupancies/${request_payload.id}`,
                    {
                        "data": {
                            id: request_payload.id,
                            type: request_payload.type,
                            attributes: request_payload.attributes
                        }
                    }
                ))

            else
                payload.push( this.$api.post(`occupancies`,
                    {
                        "data": {
                            relationships: request_payload.relationships,
                            type: request_payload.type,
                            attributes: request_payload.attributes
                        }
                    }
                ))
        })

        return Promise.all(payload)
        .then(data => {
                return data
            }
        )
        .catch( err => {
                return err
            }
        )
    },
    /** END OF EmploymentHistoryEditor1 ACTIONS */



    /**
     * ---------------------------------------
     * ACTIONS FOR LoansEditor1
     * ---------------------------------------
     */
    async getApplicantLoan({rootState}) {
        // let endpoint = `applications/${rootState.flows.appId}/assets?include=address`
        let endpoint = `applications/${rootState.flows.appId}/loan`
        let res = await this.$api.get(endpoint)
        return res
    },

    async saveApplicantLoan({rootState}, data = {}) {
        let res
        if ( data.id || data.id !== null ) {
            res = await this.$api.patch(`/loans/${data.id}`, {
                data
            })
        }
        else {
            res = await this.$api.post(`/loans`, data )
        }

        return res
    },
    /** END OF PEOPLE RESOURCES actions */


    /**
     * ---------------------------------------
     * ACTIONS FOR Quotes1
     * ---------------------------------------
     */

    // get quotes on flow pages
    async getQuotes({rootState}, params = {}) {

        // console.log(rootState)

        let loanData = rootState.flows?.loanData
        let appType = rootState.flows?.appTypeFE
        let type = 'asset'

        let isCommercial = appType?.type.indexOf('Commercial') >= 0
        let isUnsecured = loanData?.attributes['loan-type'] == 'Unsecured'

        // console.log('isUnsecured: ', isUnsecured)
        // console.log('isCommercial: ', isCommercial)

        if(isUnsecured && !isCommercial) {
            type = 'personal'
        }

        let payload = {...lendersList[type]}

        // console.log(payload)

        let quotes = this.$api.patch(`applications/${rootState.flows.appId}?run-quote&include=quote`, {
            "data" : {
                "type": "applications",
                "id": params.id,
            },
            "meta": {
                "service": {
                    "data": {
                        "lenders":payload
                    }
                }
            }
        })
        let loan = this.$api.get(`applications/${rootState.flows.appId}/loan`)
        return Promise.all([quotes, loan])
        .then(data => {
            return data
        })
        .catch( err => {
            return err
        })
    },


    async getQuotesPL({rootState}, params = {}) {

        let quotes = this.$api.patch(`applications/${rootState.flows.appId}?run-quote&include=quote`, {
            "data" : {
                "type": "applications",
                "id": params.id,
            },
            "meta": {
                "service": {
                    "data": {
                        "lenders": {...lendersList['personal']}
                    }
                }
            }
        })
        
        let loan = this.$api.get(`applications/${rootState.flows.appId}/loan`)
        return Promise.all([quotes, loan])
        .then(data => {
            return data
        })
        .catch( err => {
            return err
        })
    },

    // global of getting quotes
    async getGlobalQuotes({rootState}, params = {}) {

        let payload = {...lendersList[params['app-type']]}

        let quotes = this.$api.patch(`applications/${params.id}?run-quote&include=quote`, {
            "data" : {
                "type": "applications",
                "id": params.id,
            },
            "meta": {
                "service": {
                    "data": {
                        "lenders":payload
                    }
                }
            }
        })

        return Promise.all([quotes])
        .then(data => {
            return data
        })
        .catch( err => {
            return err
        })

    },

    async setApplicationNow({rootState}, params = {}) {

        let res = await this.$api.patch(`/loans/${params.payload.id}`, {
            data: {
                id: params.payload.id,
                type: params.payload.type,
                attributes: params.payload.attributes,
                relationships: params.payload.relationships
            }
        })

        return res
    },
    /** END OF Quotes1 ACTIONS */

    /**
     * ---------------------------------------
     * ---------------------------------------
     * RESOURCES: PEOPLE
     * ---------------------------------------
     * ---------------------------------------
     */
    async getPeopleByResource({rootState}, params = {}) {

        // ACCEPTED ARGS: { include = '', resource = '' }
        let endpoint = `applications/${rootState.flows.appId}/${params.resource}`
        let res = await this.$api.get(endpoint, { params: { include: params.include} })
        return res

    },

    async savePeopleByResources({}, reqs = {}) {

        let payload = []

        reqs.params.forEach( request_payload => {
            if (request_payload.id || request_payload.id != null) {
                payload.push( this.$api.patch(`${reqs.resource}/${request_payload.id}`, {
                    data: request_payload
                }) )
            }
            else {
                payload.push( this.$api.post(`${reqs.resource}`, {
                    data: {
                        relationships: request_payload.relationships,
                        type: request_payload.type,
                        attributes: request_payload.attributes
                    }
                }) )
            }
        })

        return Promise.all(payload)
        .then(data => {
            return data
        })
        .catch( err => {
            return err
        })
    },
    /** END OF PEOPLE RESOURCES actions */



	/**
     * ---------------------------------------
     * ACTIONS FOR LOAN
     * ---------------------------------------
     */

    async getLoan({rootState}, id) {
        return this.$api.get(`loans/${id}`)
    },

    async fetchLoan({rootState}, payload) {
        const {id, params} = payload
        return this.$api.get(`loans/${id}`, params)
    },

    async updateLoan({}, data = {}) {
        let endpoint = `/loans/${data.id}`
        let res = await this.$api.patch(endpoint, {"data": data})
        return res
    },

    async updateGuestLoan({}, data = {}) {
        let endpoint = `/loans/${data.id}?key[guid]=${data.guid}`
        let res = await this.$api.patch(endpoint, {"data": data})
        return res
    },

    async createLoan({}, data = {}) {
        let endpoint = `/loans`
        let res = await this.$api.post(endpoint, {"data": data})
        return res
    },

    async getApplicationLoan({rootState}) {
        let endpoint = `applications/${rootState.flows.appId}/loan`
        let res = await this.$api.get(endpoint)
        return res
    },

    /** END OF LOAN ACTIONS */

    /**
     * ---------------------------------------
     * ACTIONS FOR GLASSES GUIDES
     * ---------------------------------------
     */
    async runGlassesGuides({}, data = {}) {
        return this.$api.post('/run/glasses-guide', data)
    },
     /** END OF GLASSES GUIDES ACTIONS */


    /**
     * ---------------------------------------
     * ACTIONS FOR PURCHASES RESOURCES
     * ---------------------------------------
     */

    async createGuestPurchase({}, data = {}) {
        let endpoint = `purchases?key[guid]=${data.guid}`
        let res = await this.$api.post(endpoint, {"data": data})
        return res
    },

    async createPurchase({}, data) {
        let endpoint = `purchases`
        let res = await this.$api.post(endpoint, {"data": data})
        return res
    },

    async updatePurchases({}, data) {
        let endpoint = `purchases/${data.id}`
        let res = await this.$api.patch(endpoint, {"data": data})
        return res
    },

    async getPurchase({rootState}, id) {
        return this.$api.get(`purchases/${id}`)
    },

    async getPurchases({rootState}) {
        let endpoint = `applications/${rootState.flows.appId}/purchases`
        let res = await this.$api.get(endpoint)
        return res
    },

    async getLoansAndPurchases({commit, rootState}, params = { include: 'purchases'}) {
        let endpoint = `loanapp/${rootState.flows.appGuid}/loans`
        let res = await this.$api.get(endpoint, {
            params: params,
        })
       return res
    },

    async deletePurchase({}, id = '') {
        let res = await this.$api.delete(`purchases/${id}`)
        return res
    },

    async getAutoImages({}, key = '') {
        return await this.$api.axios.get(`http://autoedgenet.com.au/Pictures/Auto/${key}.jpg`)
            .then( (response) => {
                console.log('response :>> ', response)
            }).catch( (error) => {
                console.log('error :>> ', error);
            })

        // let response = await this.$api.get(`http://autoedgenet.com.au/Pictures/Auto/${key}.jpg`)
        // console.log('response :>> ', response);
        // return response
    },
    /** END OF PURCHASES RESOURCES ACTIONS */





	/**
     * ---------------------------------------
     * ACTIONS FOR BUSINESS RESOURCES
     * ---------------------------------------
     */
    async getBusinesses({rootState}, params) {
        return this.$api.get(`applications/${rootState.flows.appId}/businesses`, {params})
    },
    async getBusinessesByAppId({rootState}, data) {
        return this.$api.get(`applications/${data.id}/businesses`, {params: data.params})
    },
    async getBusiness({rootState}, id) {
        return this.$api.get(`businesses/${id}`)
    },
    async createBusiness({}, data) {
        return this.$api.post(`businesses`, {"data":data})
    },
    async updateBusiness({rootState}, data) {
        return this.$api.patch(`businesses/${data.id}`, {"data":data})
    },


	/**
     * ---------------------------------------
     * ACTIONS FOR PEOPLE RESOURCES
     * ---------------------------------------
     */
    async getPeople({rootState}, params) {
        return this.$api.get(`applications/${rootState.flows.appId}/people`, {params})
    },
    async getPeopleByAppId({rootState}, data) {
        return this.$api.get(`applications/${data.id}/people`, {params: data.params})
    },
    async getPerson({rootState}, id) {
        return this.$api.get(`people/${id}`)
    },
    async createPerson({}, data) {
        return this.$api.post(`people`, {"data":data})
    },
    async updatePerson({rootState}, data) {
        return this.$api.patch(`people/${data.id}`, {"data":data})
    },
    // patch people for guest user
    async updateGuestPerson({rootState}, data) {
        return this.$api.patch(`people/${data.id}?key[guid]=${data.guid}`, {"data":data})
    },
    async guestCreatePerson({rootState}, data) {
        return this.$api.post(`people?key[guid]=${data.guid}`, {"data":data.data})
    },
    /** END OF PEOPLE RESOURCES ACTIONS */


    /**
     * ---------------------------------------
     * PARTS CRUD
     * ---------------------------------------
     */
    async createPart({}, data) {
        return await this.$api.post(`parts`, {"data":data})
    },
    async deletePart({}, id = '') {
        return await this.$api.delete(`parts/${id}`)
    },
    async guestCreatePart({}, data) {
        return await this.$api.post(`parts?key[guid]=${data.guid}`, {"data":data.data})
    },

    /** END OF PARTS RESOURCES ACTIONS */



	/**
     * ---------------------------------------
     * ADDRESS CRUD
     * ---------------------------------------
     */
    async getAddresses({rootState}, params = { size: 100 }) {
        let res = await this.$api.get(`applications/${rootState.flows.appId}/addresses`, {
            params : {
                page: params
            },
            paramsSerializer: params => { return stringify(params) }
        })

        return res
    },
    async getAddress({rootState}, id) {
        return this.$api.get(`addresses/${id}`)
    },
    async createAddress({}, data) {
        return this.$api.post(`addresses`, {"data":data})
    },
    async updateAddress({rootState}, data) {
        return this.$api.patch(`addresses/${data.id}`, {"data":data})
    },
    async deleteAddress({}, id = '') {
        return await this.$api.delete(`addresses/${id}`)
    },

    /**
     * ---------------------------------------
     * ASSISTERS CRUD
     * ---------------------------------------
     */

    async getAssisters({rootState}, params) {
        return await this.$api.get(`assisters`, {params})
    },

    // create assister-submissions resource
    async createAssisterSubmissions({}, data) {
        return await this.$api.post(`assister-submissions`, {"data":data})
    },

    async runAssisterSubmit({}, data = {}) {
        return await this.$api.post(`/run/assister-submit`, {
            data: data
        })
    },

    /**
     * ---------------------------------------
     * CPDD CRUD
     * ---------------------------------------
     */
    
    async createCreditProposalDisclosures({}, payload = {}) {
        return await this.$api.post(`/applications/${payload.appGuid}/credit-proposal-disclosures`, payload.data)
    },

    async updateCreditProposalDisclosures({}, payload = {}) {
        return await this.$api.patch(`/applications/${payload.appGuid}/credit-proposal-disclosures`, payload.data)
    },

    async getCreditProposalDisclosures({}, appGuid = '') {
        return await this.$api.get(`/applications/${appGuid}/credit-proposal-disclosures`)
    },

    // async sendCreditProposalDisclosures({}, payload = {}) {
    //     return await this.$api.post(`/applications/${payload.appGuid}/generate-credit-proposal-disclosures`, payload.data)
    // },

    async generateCreditProposalDisclosures({}, params = {}) {
        return await this.$downloadApi.post(`/applications/${params.guid}/generate-credit-proposal-disclosures`)
    },

     /**
     * ---------------------------------------
     * BROKER HUB CRUD
     * ---------------------------------------
     */
     
    async runBrokerBible({}, obj = {}) {
        return await this.$api.get(`/run/broker-bible?for=${obj.params.for}`)
    },


	/**
     * ---------------------------------------
     * OCCUPANCY CRUD
     * ---------------------------------------
     */
    async getOccupancies({rootState}, params) {
        return this.$api.get(`applications/${rootState.flows.appId}/occupancies`, {params})
    },
    async getOccupancy({rootState}, id) {
        return this.$api.get(`occupancies/${id}`)
    },
    async createOccupancy({}, data) {
        return this.$api.post(`occupancies`, {"data":data})
    },
    async updateOccupancy({rootState}, data) {
        return this.$api.patch(`occupancies/${data.id}`, {"data":data})
    },
    async deleteOccupancy({}, id = '') {
        return this.$api.delete(`occupancies/${id}`)
    },


	/**
     * ---------------------------------------
     * INCOMES CRUD
     * ---------------------------------------
     */
    async getIncomes({rootState}, params) {
        return this.$api.get(`applications/${rootState.flows.appId}/incomes`, {params})
    },
    async getIncome({rootState}, id) {
        return this.$api.get(`incomes/${id}`)
    },
    async createIncome({}, data) {
        return this.$api.post(`incomes`, {"data":data})
    },
    async updateIncome({rootState}, data) {
        return this.$api.patch(`incomes/${data.id}`, {"data":data})
    },
    async deleteIncome({}, id = '') {
        return this.$api.delete(`incomes/${id}`)
    },


	/**
     * ---------------------------------------
     * ASSETS CRUD
     * ---------------------------------------
     */
    async getAssets({rootState}, params) {
        return this.$api.get(`applications/${rootState.flows.appId}/assets`, {params})
    },
    async getAsset({rootState}, id) {
        return this.$api.get(`assets/${id}`)
    },
    async createAsset({}, data) {
        return this.$api.post(`assets`, {"data":data})
    },
    async updateAsset({rootState}, data) {
        return this.$api.patch(`assets/${data.id}`, {"data":data})
    },
    async deleteAsset({}, id = '') {
        return this.$api.delete(`assets/${id}`)
    },


	/**
     * ---------------------------------------
     * LIABILITIES CRUD
     * ---------------------------------------
     */
    async getLiabilities({rootState}, params) {
        return this.$api.get(`applications/${rootState.flows.appId}/liabilities`, {params})
    },
    async getLiability({rootState}, id) {
        return this.$api.get(`liabilities/${id}`)
    },
    async createLiability({}, data) {
        return this.$api.post(`liabilities`, {"data":data})
    },
    async updateLiability({rootState}, data) {
        return this.$api.patch(`liabilities/${data.id}`, {"data":data})
    },
    async deleteLiability({}, id = '') {
        return this.$api.delete(`liabilities/${id}`)
    },

	/**
     * ---------------------------------------
     * EXPENSES CRUD
     * ---------------------------------------
     */
    async getExpenses({rootState}, params) {
        return this.$api.get(`applications/${rootState.flows.appId}/expenses`, {params})
    },
    async getExpense({rootState}, id) {
        return this.$api.get(`expenses/${id}`)
    },
    async createExpense({}, data) {
        return this.$api.post(`expenses`, {"data":data})
    },
    async updateExpense({rootState}, data) {
        return this.$api.patch(`expenses/${data.id}`, {"data":data})
    },
    async deleteExpense({}, id = '') {
        return this.$api.delete(`expenses/${id}`)
    },

 /**
     * ---------------------------------------
     * REFERENCES CRUD
     * ---------------------------------------
     */
    async createReference({}, data) {
        return this.$api.post(`references`, {"data":data})
    },

    /**
     * ---------------------------------------
     * SUBS RESOURCES
     * ---------------------------------------
     */
    async getSubs({}, obj = {}) {

        return await this.$api.get(`subs`, {
            params: {
                page: {
                    number: obj.pagination.page,
                    size: obj.pagination.itemsPerPage
                },
                filter: {
                    users: obj.user_filter,
                    status: obj.filter
                },
                include: obj.include,
                sort: obj.sort
            },
            paramsSerializer: params => stringify(params)
        })
    },

    async getSub({}, id = '') {
        return await this.$api.get(`subs/${id}`)
    },

    async updateSub({}, params = {}) {
        return await this.$api.patch(`subs/${params.id}`, {
            data: params
        })
    },


    /**
     * ACTIONS FOR LEADS RESOURCES
     * AND
     * ACTIONS FOR SALESFORCE PUSH RESOURCES
     * ---------------------------------------
     */

    // get lead by id, guest user
    async getGuestLeadById({}, obj = {}) {
        return await this.$api.get(`leads/${obj.id}`, {
            params: obj.params
        })
    },
    // update lead, guest user
    async updateGuestLead({}, params = {}) {
        return await this.$api.patch(`leads/${params.id}?key[guid]=${params.guid}`, {
            data: params,
        })
    },

    async createLeads({}, data) {
        return await this.$api.post(`leads`, {"data":data})
    },

    async updateLead({}, params = {}) {
        return await this.$api.patch(`leads/${params.id}`, {
            data: params
        })
    },

    async expandLead({}, params = {}) {
        let res = await this.$api.patch(`leads/${params.id}`,
            {
                params: {
                    'expand': params.type
                }
            }
        )
        return res
    },
    async salesforcePush({}, params = {}) {
        return await this.$api.patch(`leads/${params.data.id}/?${params.key}`, { data: params.data})
    },
    async getSalesforceApplications({}, params = {}) {
        let res = await this.$api.get(`salesforce-datas`, {
            params: {
                filter: {
                    applications: params.appIds
                }
            },
            paramsSerializer: params => stringify(params)
        })

        return res
    },

    async expand({}, params = {}) {
        return await this.$api.patch(
            `leads/${params.body.id}?run-expand=${params.template}&include=application`,
            {
                data: params.body,
            }
        )
    },

    // expand for guest user
    async expandGuestApp({}, params = {}) {
        return await this.$api.patch(
            `leads/${params.body.id}?run-expand=${params.template}&include=application&key[guid]=${params.guid}`,
            {
                data: params.body,
            }
        )
    },




    /**
     * ACTIONS FOR BOX RESOURCES
     * ---------------------------------------
     */
    async updateBox({}, data = {}) {
        return await this.$api.patch(`boxes/${data.id}`, {
            data: data
        })
    },
    async createBox({}, data) {
        return await this.$api.post(`boxes`, {"data":data})
    },
    async getApplicationBox({}, id = '') {
        return await this.$api.get(`applications/${id}/box`)
    },





    /**
     * ---------------------------------------
     * APPLICATIONS RESOURCES
     * ---------------------------------------
     */

    // generate pdf on applications
    async makeFile({}, data = {}) {

        let res = await this.$api.post(`run/make-file`, {
            data: data
        })

        return res
    },

    // copy an applications
    async copyApp({}, data = {}) {

        let res = await this.$api.post(`run/clone`, {
            data: data
        })

        return res
    },

    async createApplication({}, data) {
        return await this.$api.post(`applications`, { "data": data })
    },

    async runApplicationSnapshot({}, obj = {}) {
        return await this.$api.post(`run/application-snapshot`, {data:obj})
    },

    async getApplicationbyGuid({}, obj = {}) {

        let res = await this.$api.get(`applications`, {
            params: {
                ...obj,
                page: {
                    number: 1,
                    size: 100
                },
            },
            paramsSerializer: params => stringify(params)
        })
        if ( res.status === 200 ) return res
        return {}
    },

    async getApplication({}, obj = {}) {
        return await this.$api.get(`applications/${obj.id}`, {
            params: obj.params
        })
    },

    async updateApplication({}, params = {}) {
        return await this.$api.patch(`applications/${params.id}`, {
            data: params
        })
    },

    async getApplications({}, obj = {}) {

        let endpoint = `applications` // `applications?filter[archived]=true`

        let res = await this.$api.get(endpoint, {
            params: obj.data,
            paramsSerializer: params => stringify(params)
        })
        if ( res.status === 200 ) return res
        return {}
    },

    async setArchiveApplication({}, data = {}) {
        let res = await this.$api.patch(`applications/${data.id}`, {
            "data": data
        })
        return res
    },

    async inviteApplicantToEdit({}, params = {}) {
        let res = await this.$api.post(`run/invite`,
            {
                data: {
                    'application': params.id,
                    'email': params.email,
                    'redirect-url': params.url,
                    'first-name': params['first-name'],
                    'last-name': params['last-name'],
                    'sms-template': null,
                    'email-template': "tenant-default"
                }
            }
        )
        return res
    },

    async getUserApplications({}, obj = {}) {

        let res = await this.$api.get(`users/${obj.user_filter}/applications`, {
            params: obj.data,
            paramsSerializer: params => stringify(params)
        })
        if ( res.status === 200 ) return res
        return {}
    },

    async getOwnApplications({}, obj = {}) {
        let res = await this.$api.get(`users/me/applications`, {
            params: {
                page: {
                    number: obj.pagination.page,
                    size: obj.pagination.itemsPerPage
                },
                filter: {
                    'archived-at': obj['archived-at']
                },
                include: obj.include,
                sort: obj.sort
            },
            paramsSerializer: params => stringify(params)
        })
        if ( res.status === 200 ) return res
        return {}
    },

    async getApplicantEntity({}, id = '') {
        return await this.$api.get(`applications/${id}/people`)
    },

    async getBusinessEntity({}, id = '') {
        return await this.$api.get(`applications/${id}/businesses`)
    },

    async getActiveLenderSubmissions({}, id = '') {
        return await this.$api.get(`applications/${id}/lender-submissions?filter[]=[active=1]`)
    },

    /**
     * ---------------------------------------
     * BIDS and BID-LENDERS RESOURCES
     * ---------------------------------------
     */
    async getApplicationBid({}, obj = {}) {
        return await this.$api.get(`applications/${obj.id}/bid`, {
            params: {
                include: obj.include
            },
            paramsSerializer: params => stringify(params)
        })
    },

    async createBid({}, data = {}) {
        let endpoint = `/bids`
        let res = await this.$api.post(endpoint, {"data": data})
        return res
    },

    async updateBid({}, data = {}) {
        let endpoint = `/bids/${data.id}`
        let res = await this.$api.patch(endpoint, {"data": data})
        return res
    },

    async deleteBid({}, id = '') {
        return await this.$api.delete(`/bids/${id}`)
    },

    async createBidLender({}, data = {}) {
        let endpoint = `/bid-lenders`
        let res = await this.$api.post(endpoint, {"data": data})
        return res
    },

    async updateBidLender({}, data = {}) {
        let endpoint = `/bid-lenders/${data.id}`
        let res = await this.$api.patch(endpoint, {"data": data})
        return res
    },

    async deleteBidLender({}, id = '') {
        return await this.$api.delete(`/bid-lenders/${id}`)
    },

    async sendBidDoc({}, guid = null) {
        return await this.$api.post(`/bid/${guid}/sign`)
    },
    /** END OF BIDs RESOURCES ACTIONS */

    /**
     * ---------------------------------------
     * LENDERS RESOURCES
     * ---------------------------------------
     */
    async getLender({}, id) {
        return await this.$api.get(`lenders/${id}`)
    },
    async getLenders({}, params) {
        return await this.$api.get(`lenders`, {params})
    },

    async runLenderSubmitService({}, data = {}) {
        return await this.$api.post(`run/lender-submit`, {
            data: data
        })
    },

    async runLenderFlowService({}, data = {}) {
        return await this.$api.post(`run/lender-flow`, {
            application: data
        })
    },


    /** END OF LENDERS RESOURCES ACTIONS */



    /**
     * ---------------------------------------
     * MESSAGES/FILE-NOTES RESOURCES
     * ---------------------------------------
     */
    async getMessages({}, params = {}) {
        // return await this.$api.get(`applications/${params.appId}/messages?include=author`)
        return await this.$api.get(`applications/${params.appId}`)
    },

    async getFileNotes({}, obj = {}) {
        return await this.$api.get(`applications/${obj.id}/file-notes`, {
            params: obj.params
        })
    },

    async createFileNoteInService({}, params = {}) {
        return await this.$api.post(`run/salesforce`, {
            body: params
        })
    },

    async createFileNote({}, params = {}) {
        return await this.$api.post(`file-notes`, params)
    },

    async deleteMessage({}, id = '') {
        return await this.$api.delete(`messages/${id}`)
    },


    /**
     * ---------------------------------------
     * APPLICATION SERVICES RESOURCES -- RUN CONVERT, RUN CALCULATE, RUN INIT DATA , RUN UPLOAD, RUN RETRIEVE
     * ---------------------------------------
     */
     async runConvert({}, data = {}) {
        return await this.$api.post(`/run/convert`, {
            data: data
        })
    },
    async runCalculate({}, data = {}) {

        await this.$api.post(`run/calculate`, {
            "data": {
                "application-id": data.id
            }
        })

        return await this.$api.get(`applications/${data.id}?include=calculation,loan`)

    },
    async runServiceTemplate({}, data = {}) {
        return await this.$api.patch(`leads`, {
            params: data,
            paramsSerializer: params => stringify(params)
        })
    },

    async runTransferApplication({}, params = {}) {
        return await this.$api.post('/run/transfer-application', params)
    },

    // file
    async runServiceUpload({}, data = {}) {
        return await this.$api.post(`/run/upload`, data)
    },

    async runServiceRetrieve({}, data = {}) {
        return await this.$api.post(`/run/retrieve`, data)
    },

    async runServiceDeleteFile({}, data = {}) {
        return await this.$api.post(`/run/delete-file`, data)
    },

    async getRequiredDocs({}, appGuid = null) {
        return await this.$api.get(`applications/${appGuid}/supporting-documents`)
    },

    async runFillApplicationRoles({}, data = {}) {
        return await this.$api.post(`/run/fill-application-roles`, data)
    },

    async getPrivacyDisclosures({}, {appGuid, personId}) {
        return await this.$api.get(`applications/${appGuid}/people/${personId}/privacy-disclosure`)
    },

    async submitPrivacyDisclosures({}, {appGuid,personId, payload}) {
        return await this.$api.post(`applications/${appGuid}/people/${personId}/privacy-disclosures`, payload)
    },

    async checkPrivacyDisclosure({}, {appGuid}) {
        return await this.$api.get(`applications/${appGuid}/valid-credit-license`)
    },

    // Commitments

    async getCommitments({rootState}, params) {
        return this.$api.get(`commitments`, {params})
    },

    async createCommitments({rootState}, data={}) {
        return this.$api.post(`commitments`, {"data": data})
    },


    // Terms

    async getTerms({rootState}, slug) {
        return this.$api.get(`terms?filter[]=[term-groups.slug=${slug}]`)
    },

    async runServiceInitData({}, data = {}) {
        return await this.$api.post(`run/init-data`, {
            data: data
        })
    },

    //Application Snapshot

    async getPeopleEntitySnapshot({}, obj = {}) {

        let endpoint = `applications/${obj.id}/people`
        let res = await this.$api.get(endpoint, { params: {include : obj.include } })
        return res
    },


    async getBusinessEntitySnapshot({}, obj = {}) {
        let endpoint = `applications/${obj.id}/businesses`
        let res = await this.$api.get(endpoint, { params: {include : obj.include } })
        return res
    },

    // Application Summary
    async runApplicationSummary({}, obj = {}) {
        return await this.$api.post(`run/application-summary`, {data:obj})
    },

    // Submit other lender
    async submitOtherLender({}, obj = {}) {
        return await this.$api.post(`run/other-submit`, {data:obj})
    },

    // Get Box token
    async getBoxToken({}) {
        return await this.$api.get(`box-token`)
    },
}
