<template>
    <v-card :disabled="isDisabled || loading" class="mx-auto" tile flat>


        <v-card-text class="pa-4 pt-0 pa-md-10 pt-md-0">

            <v-row>
              <v-col>
                <h3>Supporting Documents:</h3>
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <p>Upload all required supporting documents as one combined PDF</p>

                <v-btn class="secondary elevation-0" @click="uploadIncome" rounded :disabled="uploading" > 
                  <v-icon>mdi-upload</v-icon>
                    Click to upload
                  <input ref="income" type="file" hidden @change="handleFileIncome($event, 'income-statements')" multiple>
                </v-btn>

                <div v-if="(files['income-statements'].length > 0)">
                  <v-chip
                    v-for="(data, index) in files['income-statements']"
                    :key="index"
                    close
                    @click:close="removeFile(data, 'income-statements')"
                    class="ma-2">
                      {{ data.name || data }}
                  </v-chip>
                </div>

              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <p>If new venture, please include evidence of any contracts</p>
                <v-btn class="secondary elevation-0" @click="uploadContracts" rounded :disabled="uploading2">
                  <v-icon>mdi-upload</v-icon>
                  Click to upload
                  <input ref="contracts" type="file" hidden @change="handleFileContracts($event, 'contracts')" multiple>
                </v-btn>

                <div v-if="(files['contracts'].length > 0)">
                  <v-chip
                    v-for="(data, index) in files['contracts']"
                    :key="index"
                    close
                    @click:close="removeFile(data, 'contracts')"
                    class="ma-2">
                      {{ data.name || data }}
                  </v-chip>
                </div>

              </v-col>
            </v-row>

            <v-alert
              v-if="!hasFile"
              outlined
              class="mt-4"
              color="error"
              type="error">
              <div class="success-darken-1--text font-weight-medium">
                Please Upload a file.
              </div>
            </v-alert>

            <v-row>
              <v-col>
                <ValidationObserver ref="observer" tag="form">

                  <ValidationProvider
                    v-slot="{ errors }"
                    name="Field"
                    rules="required">

                    <v-checkbox
                      v-model="checkboxes"
                      :error-messages="errors"
                      value="check"
                      color="secondary">
                        <template v-slot:label>
                            <div class="checkbox-help">
                              <p>I confirm there is a detailed Asset & Liability summary of each <b>Owner/Client/Director</b> </p>
                              <p><i>This information should be filled in the application</i></p>
                            </div>
                        </template>
                    </v-checkbox>

                  </ValidationProvider>
                  
                </ValidationObserver>

              </v-col>
            </v-row>

        </v-card-text>

        <Portal to="step-incrementer-next">
          <v-btn 
              color="secondary" 
              large depressed nuxt 
              min-width="140" 
              class="ml-3" 
              @click="lenderSubmit"
              :disabled="isDisabled">
              Submit
          </v-btn>
        </Portal>

    </v-card>
</template>

<script>

    import { mapActions, mapState, mapGetters, mapMutations } from 'vuex'
    import subscriptions from '~/components/shared/subscriptions'
    import helpers from '~/plugins/helpers'

    export default {
        mixins: [subscriptions, helpers],
        data() {
            return {
              uploading: false,
              uploading2: false,
              loading: false,
              hasFile: true,
              terms: [],
              checkboxes: [],
              fileContractsUploaded: true,
              files: {
                'income-statements': [],
                'contracts': [],
              },
            }
        },
        computed: {
            ...mapGetters('kinds', ['kindsUnformat']),
            ...mapGetters('themes', [
                'getActiveTheme'
            ]),
            ...mapState({
                user: state => state.auth.user,
                appId: state => state.flows.appId,
                appGuid: state => state.flows.appGuid,
                calculation: state => state.flows.calculation,
            }),
            ...mapGetters('statuses', [
              'getCurrentFlowStatus'
            ]),
            vb() {
                return this.$vuetify.breakpoint
            },
            storeLoanData(){
                 return this.loanData
            },
            getLoanId(){
                return this.appData.relationships.loan.data.id
            },
        },
        methods: {
            ...mapActions('resource-actions', [
              'runServiceUpload',
              'runServiceRetrieve',
              'runLenderSubmitService',
              'runServiceDeleteFile'
            ]),
            ...mapActions('flows', [
              'selectFinalStep',
              'selectFinalStepNoFlowComplete'
            ]),
            ...mapMutations('utils', ['toggleQuickMessage']),
            async pull() {

              let { files } = this

              let promise1 = this.runServiceRetrieve({
                "application": this.appGuid,
                "prefix": "morris-income-statements"
              })

              let promise2 = this.runServiceRetrieve({
                "application": this.appGuid,
                "prefix": "morris-contracts"
              })

              const res = await Promise.all([
                promise1,
                promise2
              ])

              if(res[0].data.files.length > 0) {

                res[0].data.files.forEach(file => {
                  files['income-statements'].push(file)
                })

              }

              if(res[1].data.files.length > 0) {

                res[1].data.files.forEach(file => {
                  files['contracts'].push(file)
                })

              }

            },

            async push() {

              try {
                
                const {valid, status} = await this.validateFormData()

                return {valid, status, data: {}}

              } catch (err) {
                console.log(err)
              }

            },

            hasUploaded() {
              let uploaded = false

              let {files} = this

              if(files['income-statements'].length > 0) {
                uploaded = true
              }

              if(!this.fileContractsUploaded) {
                uploaded = false
              }

              this.hasFile = uploaded

              return uploaded
            },

            async lenderSubmit() {

              this.loading = true

              try {

                const { valid } = await this.validateFormData()
                const uploaded = await this.hasUploaded()

                if(uploaded && valid) {

                  let res = await this.runLenderSubmitService({
                    "application": this.appGuid, 
                  })

                  if(res.status === 200) {
                      
                    if(res.data.message == 'Lender Submission Failed') {
                      await this.selectFinalStepNoFlowComplete()
                    } else {
                      await this.selectFinalStep({flowStatus: this.getCurrentFlowStatus.id})
                    }

                  }

                }
               
                this.loading = false

              } catch (err) {
                this.loading = false
              }

            },

            async handleFileContracts (e, key) {

              try {

                const files = Array.from(e.target.files) || []
                this.uploading2 = true

                this.files[key] = files

                if(this.files[key].length > 0) {

                  let payload = new FormData()

                  payload.append('application', this.appGuid)
                  payload.append('prefix', `morris-${key}`)

                  for(let index = 0; index < this.files[key].length; index++) {
                    payload.append('files['+ index +']', this.files[key][index])
                  }

                  const res = await this.runServiceUpload(payload)

                  if(res.status === 200) {

                    this.fileContractsUploaded = true
                    const retrieve = await this.runServiceRetrieve({"application": this.appGuid,"prefix": "morris-contracts"})

                    if(retrieve.data.files.length > 0) {
                      this.files['contracts'] = retrieve.data.files
                    }

                  } else {

                    this.fileContractsUploaded = false
                    this.toggleQuickMessage({text: 'Uh oh! something went wrong!' , color: 'error'})

                  }

                }

                this.uploading2 = false

              } catch (err) {
                console.log(err)
                this.uploading2 = false
              }

            },

            async handleFileIncome (e, key) {

              try {

                const files = Array.from(e.target.files) || []
                this.uploading = true

                this.files[key] = files

                if(this.files[key].length > 0) {

                  let payload = new FormData()

                  payload.append('application', this.appGuid)
                  payload.append('prefix', `morris-${key}`)

                  for(let index = 0; index < this.files[key].length; index++) {
                    payload.append('files['+ index +']', this.files[key][index])
                  }

                  const res = await this.runServiceUpload(payload)

                  if(res.status === 200) {

                    const retrieve = await this.runServiceRetrieve({"application": this.appGuid,"prefix": "morris-income-statements"})

                    if(retrieve.data.files.length > 0) {
                      this.files['income-statements'] = retrieve.data.files
                    }

                  } else {

                    this.toggleQuickMessage({text: 'Uh oh! something went wrong!' , color: 'error'})

                  }

                }

                this.uploading = false

              } catch (err) {
                console.log(err)
                this.uploading = false
              }

            },

            uploadIncome() {
              this.$refs.income.click()
            },

            uploadContracts() {
              this.$refs.contracts.click()
            },

            async removeFile(name, key) {
              try {

                const res = await this.runServiceDeleteFile({ "application": this.appGuid,"file": name })

                if(res.status === 200) {
                  this.files[key] = this.files[key].filter(f => f != name)
                }

              } catch (err) {
                console.log(err)
              }
            }

        }
    }
</script>

<style lang="scss" scoped>
.checkbox-help {

  p {
    font-weight: 400;
    font-size: 14px;
    line-height: 15px;
  }
}
</style>
