export default [
    {
        icon: 'mdi-home-account',
        title: 'Home',
        to: 'home',
        visible: true,
    },
    // {
    //     icon: 'mdi-view-dashboard',
    //     title: 'Dashboard',
    //     to: 'dashboard',
    //     visible: true,
    // },
    {
        icon: 'mdi-phone-in-talk',
        title: 'Contacts',
        to: 'contacts',
        visible: true,
    },
    { header: "FINANCE" },


    {
        icon: 'mdi-folder-plus',
        title: 'New',
        to: null,
        visible: true,
        children: [
            {
                icon: 'mdi-comment-quote',
                title: 'Quote',
                to: 'quote',
                visible: true,
                settings: 'can-select-quote'
            },
            {
                icon: 'mdi-file-document-multiple',
                title: 'Application',
                to: 'startapp',
                visible: true,
                settings: 'can-select-application'
            },
            {
                icon: 'mdi-file-check',
                title: 'Tick n Flick',
                to: 'tick-flick',
                visible: true,
                settings: 'can-select-tnf'
            },
            {
                icon: 'mdi-file-document',
                title: 'Scenario',
                to: 'scenario',
                visible: true,
                settings: 'can-select-scenario'
            }
        ]
    },

    {
        icon: 'mdi-folder-open',
        title: 'MyApps',
        to: null,
        visible: true,
        children: [
            {
                icon: 'mdi-note-text-outline',
                title: 'Scenarios',
                to: 'my-apps/scenario',
                visible: true,
                settings: 'can-select-scenario'
            },
            {
                icon: 'mdi-comment-quote',
                title: 'Quotes',
                to: 'my-apps/quote',
                visible: true,
                settings: 'can-select-quote'
            },
            {
                icon: 'mdi-pencil',
                title: 'Drafts',
                to: 'my-apps/draft',
                visible: true,
            },
            {
                icon: 'mdi-send',
                title: 'Submitted',
                to: 'my-apps/submitted',
                visible: true,
            },
            {
                icon: 'mdi-check-decagram',
                title: 'Settled',
                to: 'my-apps/settled',
                visible: true,
            },
            {
                icon: 'mdi-close-octagon',
                title: 'Lost',
                to: 'my-apps/lost',
                visible: true,
            },
            {
                icon: 'mdi-archive',
                title: 'Archived',
                to: 'my-apps/archived',
                visible: true,
            }
        ]
    },

    { header: "TOOLS" },

    {
        icon: 'mdi-glasses',
        title: "Glass's Guide",
        to: 'glasses-guide',
        visible: true,
    },
    {
        icon: 'mdi-text-box-search',
        title: 'ABN Lookup',
        to: 'abn-lookup',
        visible: true,
    },
    // {
    //     icon: 'mdi-book-open',
    //     title: 'Nodifi Knowledge',
    //     to: 'bible',
    //     visible: true,
    //     // badge: 'Beta'
    // },
    {
        icon: 'mdi-book-open-variant',
        title: 'Broker Hub',
        to: 'broker-hub',
        visible: true,
    },
    {
        icon: 'mdi-calculator',
        title: 'Servicing Calculator',
        to: 'servicing-calculator',
        visible: true,
        // badge: 'Beta'
    },
    {
        icon: 'mdi-book-open-variant',
        title: 'SimpleKYC',
        to: 'simplekyc',
        visible: true,
        // badge: 'Beta'
    },
    // {
    //     icon: 'mdi-file-upload',
    //     title: 'Upload Docs',
    //     to: 'upload-docs',
    //     visible: true,
    // }
]
