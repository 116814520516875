<template>
    <v-dialog
        v-model="dialog"
        width="900"
        scrollable
        persistent>
        
        <template v-slot:activator="{ on, attrs }">
            <v-btn  
                v-if="disabled"               
                depressed large
                v-bind="attrs"
                v-on="on" text
                :block="block"
                color="white"
                :disabled="disabled"
                :outlined="outlined"
                class="float-left px-5 mx-2">
                Selected
            </v-btn>
            <v-btn  
                v-else               
                depressed large
                v-bind="attrs"
                v-on="on" text
                :block="block"
                color="white"
                :disabled="disabled"
                :outlined="outlined"
                :class="`${disabled ? 'light_gray_1 darken-1' : 'secondary'} text-left ${block ? '' : 'float-left px-5 mx-2'}`">
                {{ label }}
            </v-btn>
        </template>

        <v-card flat>

            <v-overlay
                v-if="alert"
                absolute
                :opacity="0.6">
                <v-card width="400" class="pa-10 light_gray_1" light>
                    <v-card-text class="text-center">
                        <v-icon color="error" size="50">mdi-alert</v-icon>
                        <p class="pt-6">Do you want to remove the existing lender and create a new one?</p>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                            @click="cancel"
                            outlined
                            color="grey lighten-1"
                            large>No</v-btn>
                        <v-btn 
                            @click="flagged"
                            large 
                            class="secondary" 
                            depressed>Yes</v-btn>                        
                        <v-spacer></v-spacer>
                    </v-card-actions>
                </v-card>
            </v-overlay>
        
            <v-card-title>
                <v-spacer />
                <v-btn icon @click="dialog = false" >
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-card-title>

            <v-card-text class="pa-0 pa-md-8 pt-0 pt-md-0">

                <v-card flat elevation="0">

                    <v-card-title class="px-4 pb-3">
                        <div class="text-h4 text-capitalize">{{ title }}</div>
                    </v-card-title>

                    <v-card-text>
                        <div class="text-subtitle-1">
                            Rationale for {{ selectType === 'primary' ? 'Recommendation' : 'Alternative' }}
                        </div>
                    </v-card-text>  

                    <v-card-text>

                        <ValidationObserver
                            ref="observer"
                            tag="form">

                            <div class="flex-form">
                                <div
                                    v-for="(field, fkey) in labels[selectType]"
                                    :key="fkey" 
                                    class="flex-field">

                                    <ValidationProvider
                                        v-slot="{ errors }"
                                        :name="labels[fkey]"
                                        rules="required"
                                        :vid="fkey">

                                        <v-textarea
                                            v-model="resources['bid'].attributes[fkey]"
                                            :label="labels[selectType][fkey]"
                                            auto-grow
                                            :error-messages="errors"
                                            row-height="1">
                                        </v-textarea>

                                    </ValidationProvider>

                                </div>
                            </div>

                        </ValidationObserver>
            
                    </v-card-text>

                </v-card>   

            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions class="pa-4 pa-md-6 flex-wrap controls">                                
                <v-btn
                    outlined
                    color="secondary"
                    large
                    style="min-width:140px;"
                    @click="cancel">
                    Back
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn
                    color="secondary"
                    depressed
                    large
                    style="min-width:140px;"
                    @click="save">
                    Save
                </v-btn>
            </v-card-actions>
        </v-card>

    </v-dialog>
</template>

<script>

    import { mapState, mapActions } from 'vuex'
    import subscriptions from '~/components/shared/subscriptions'
    import { cloneDeep } from 'lodash'

    export default {
        name: 'BidLenderSelection',

        mixins: [ subscriptions ],

        props: {
            selectType: {
                type: String,
                default: 'primary'
            },
            label: {
                type: String,
                default: 'Submit'
            },
            title: {
                type: String,
                default: 'Submit'
            },
            block: {
                type: Boolean,
                default: false
            },
            hasData: {
                type: Boolean,
                default: false
            },
            lender: {
                type: Object,
                default: () => {}
            },
            loan: {
                type: Object,
                default: () => {}
            },
            disabled: {
                type: Boolean,
                default: false
            },
            outlined: {
                type: Boolean,
                default: false
            }
        },

        mounted () {
            // console.log(this.loan)
        },

        methods: {
            ...mapActions('resource-actions', [
                'setApplicationNow',
            ]),
            async save() {
                // remove empty
                this.resources.bid.attributes = this.filterResources(this.resources.bid.attributes)

                const valid = await this.$refs.observer.validate()

                if ( valid ) {

                    if ( !this.resources['bid']?.id ) delete this.resources['bid'].id
                    if ( !this.resources['bid-lender']?.id ) delete this.resources['bid'].id

                    this.resources['bid'].relationships.application.data.id = this.appId

                    if (this.selectType === 'primary') {

                        await this.populateLoanFields()

                        // filter relationship and attributes
                        if(this.loan?.attributes) {
                            this.loan.attributes = await this.removeNullAttributes(this.loan.attributes)
                        }

                        if (this.loan?.relationships?.validations) {
                            delete this.loan.relationships.validations
                        }

                        await this.setApplicationNow({
                            payload: this.loan,
                        })

                    }
                   

                    await this.populateResourceObj()

                    this.$emit(
                        'validated-data', {
                            type: this.selectType,
                            data: cloneDeep(this.resources),
                            flag: this.hasData
                        }
                    )

                    this.dialog = false
                }
            },

            populateLoanFields() {

                this.loan.attributes['requested-balloon'] = this.lender.details['actual-payment']['balloon']
                this.loan.attributes['quoted-bank-fees'] = this.lender.details['actual-payment']['bank-fees']
                this.loan.attributes['quoted-base-rate'] = this.lender.details['actual-payment']['base-rate']
                this.loan.attributes['quoted-broker-commission-inc-gst'] = this.lender.details['actual-payment']['commission-inc-gst']
                this.loan.attributes['quoted-broker-fee'] = this.lender.details['actual-payment']['broker-fee']
                this.loan.attributes['quoted-customer-rate'] = this.lender.details['actual-payment']['customer-rate']
                this.loan.attributes['quoted-monthly-payments'] = this.lender.details['actual-payment']['monthly-payment']
                this.loan.attributes['quoted-naf'] = this.lender.details['actual-payment']['naf']
                this.loan.attributes['quoted-account-fee'] = this.lender.details['actual-payment']['account-fee']
                this.loan.attributes['requested-term-months'] = this.lender.details['actual-payment']['term-in-months']
                this.loan.attributes['quoted-lender-selected'] = this.lender.details.name

            },

            removeNullAttributes(attributes = {}) {

                let newAttributes = {}

                Object.keys(attributes).forEach(attribute => {
                    if(attributes[attribute] != null && attributes[attribute] != "") {
                        newAttributes[attribute] = attributes[attribute]
                    }
                })

                return newAttributes

            },

            populateResourceObj() {
                this.resources['bid-lender'].attributes = {
                    "lender-name": this.lender.details.name,
                    "loan-term": this.actualPayment('term-in-months'),
                    "required-loan-amount": this.loanValue('requested-required-loan-amount'),
                    "balloon-amount": this.actualPayment('balloon'),
                    "rate-range-from": this.baseRatePayment('customer-rate'),
                    "rate-range-to": this.maxRatePayment('customer-rate'),
                    "payment-range-from": this.baseRatePayment('monthly-payment'),
                    "payment-range-to": this.maxRatePayment('monthly-payment'),
                    "brokerage-commission-from": this.baseRatePayment('commission-inc-gst'),
                    "brokerage-commission-to": this.maxRatePayment('commission-inc-gst'),
                    "origination-fee": this.actualPayment('broker-fee'),
                    "establishment-fee": this.lender.details.variables['setup-risk-fee'],
                    "risk-fee": this.lender.details.variables['setup-risk-fee'],
                    "ppsr-fee": this.lender.details.variables['ppsr'],
                    "monthly-account-fee": this.lender.details.variables['monthly-account-fee'],
                    "loan-fees-amount": this.computeLoanFeesAmount(this.actualPayment('bank-fees'), this.actualPayment('broker-fee')),
                    "total-loan-amount": this.actualPayment('naf'),
                    "total-cost-range-from": this.computeTotalCostRange(this.baseRatePayment('monthly-payment'), this.actualPayment('term-in-months'), this.actualPayment('balloon')),
                    "total-cost-range-to": this.computeTotalCostRange(this.maxRatePayment('monthly-payment'), this.actualPayment('term-in-months'), this.actualPayment('balloon')),
                    "lender-type": this.label === 'Set Primary' ? 'Primary' : 'Alternative', // lender type
                    "lender-logo-class": this.lender.details['logo-class'], // logo-class
                }
            },
            filterResources (resources) {

                for (var propName in resources) {
                    if (resources[propName] === '') {
                        delete resources[propName];
                    }
                }
                
                return resources
            },
            computeTotalCostRange (basePayment, maxPayment, balloon) {
                return parseFloat((basePayment * maxPayment) + balloon)
            },

            computeLoanFeesAmount (bankFees, brokerFee) {
                return parseFloat(bankFees) + parseFloat(brokerFee)
            },

            loanValue(key = '') {
                return this.loan.attributes?.[key]
            },

            actualPayment(key = '') {
                return this.lender.details['actual-payment']?.[key]
            },

            baseRatePayment(key = '') {
                return this.lender.details['base-rate-payment']?.[key]
            },

            maxRatePayment(key = '') {
                return this.lender.details['max-rate-payment']?.[key]
            },

            flagged() {
                this.alert = false                
            },

            cancel() {                
                this.dialog = false
            }
        },

        watch: {
            dialog: {
                handler(val) {
                    if ( !val ) {
                        this.$refs.observer.reset()
                        this.alert = false
                    }
                    else {
                        if ( this.hasData ) {
                            this.alert = true                            
                        }

                        // Empty form when modal is opened
                        Object.keys( this.resources.bid.attributes ).forEach( key => {
                            this.resources.bid.attributes[key] = ''
                        })
                    }

                },
                deep: true
            }
        },

        data() {
            return {                
                dialog: false,
                alert: false,
                resources: {
                    'bid': {
                        id: null,
                        type: 'bids',
                        attributes: {
                            'recommendation-cost': '',
                            'recommendation-priorities': '',
                            'recommendation-needs-objectives': '',
                            'recommendation-circumstances': '',
                            'recommendation-other': ''
                        },
                        relationships: {
                            application: {
                                data: {
                                    type: 'applications',
                                    id: null
                                }
                            }
                        }
                    },
                    'bid-lender': {
                        type: "bid-lenders",
                        attributes: {
                            "lender-name": '',
                            "loan-term": '',
                            "required-loan-amount": '',
                            "balloon-amount": '',
                            "rate-range-from": '',
                            "rate-range-to": '',
                            "payment-range-from": '',
                            "payment-range-to": '',
                            "brokerage-commission-from": '',
                            "brokerage-commission-to": '',
                            "origination-fee": '',
                            "establishment-fee": '',
                            "risk-fee": '',
                            "ppsr-fee": '',
                            "monthly-account-fee": '',
                            "loan-fees-amount": '',
                            "total-loan-amount": '',
                            "total-cost-range-from": '',
                            "total-cost-range-to": '',
                            "lender-type": "", // lender type
                            "lender-logo-class": "", // logo-class
                        },
                        relationships: {
                            "bid": {
                                "data": {
                                    "type": "bids",
                                    "id": null
                                }
                            }
                        }
                    },
                    
                },
                labels: {
                    primary: {
                        'recommendation-cost': 'Cost',
                        'recommendation-priorities': 'Your Stated Priorities',
                        'recommendation-needs-objectives': 'Your Stated Needs and Objectives',
                        'recommendation-circumstances': 'Your Personal Circumstances',
                        'recommendation-other': 'Other'
                    },
                    alternative: {
                        'alternative-summary': 'Summary'
                    }
                }
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>