<template>

      <v-list nav dark class="flex-sidebar">
        <v-list-item-group :flex="selected" active-class="flex-active" >

        <template v-for="(item, i) in items">

            <template v-if="item.header">
                <v-col :key="i" class="subheader pt-10 text-truncate">{{ item.header }}</v-col>
            </template>


            <v-list-group 
                eager
                color="secondary"
                active-class="flex-active"
                v-else-if="item.children">
              
                <template v-slot:activator>

                  <v-list-item-action class="mr-3">
                    <v-icon size="22" v-text="item.icon"></v-icon>
                  </v-list-item-action>

                  <v-list-item-content>
                    <v-list-item-title>{{item.title}}</v-list-item-title>
                  </v-list-item-content>

                </template>

                <v-list-item
                    class="pl-8"
                    v-for="child in item.children"
                    :key="child.title"
                    :to="`/${getGroupSlug}/${child.to}`"
                  >

                  <v-list-item-icon class="mr-3">
                    <v-icon v-text="child.icon"></v-icon>
                  </v-list-item-icon>

                  <v-list-item-title v-text="child.title"></v-list-item-title>

                </v-list-item>
            </v-list-group>


            <v-list-item v-else :key="i" :to="`/${getGroupSlug}/${item.to}`">
                  <v-list-item-action class="mr-3">
                    <v-icon size="22" v-text="item.icon"></v-icon>
                  </v-list-item-action>

                  <v-list-item-content>
                    <v-list-item-title v-text="item.title" /> 
                  </v-list-item-content>
            </v-list-item>

        </template>

        </v-list-item-group>
      </v-list>

</template>

<script>

    import { mapState, mapGetters, mapActions } from 'vuex';
    import navItems from '~/components/base/BaseNavigation1/base-navigation-items'

    export default {
        data(){
            return {
              items: navItems
            }
        },
        computed: {
            ...mapGetters({
                getGroupSlug: 'slug/getGroupSlug'
            }),

            selected(){
                return false
            }
        },
        created() {

        },
        methods: {
        }
    }
</script>

<style lang="scss">

  .flex-sidebar {

      &.v-list--nav {

          .subheader {
              font-size: 0.65rem;
              letter-spacing: 2px;
              color: white;
          }

          .v-list-item {
            min-height: 42px;

              .v-list-item__action,
              .v-list-item__icon {
                  margin: 8px 0;
              }

              .v-list-item__content {
                  padding: 8px 0;
              }

              .v-list-item__title {
                font-size: 0.92rem;
              }
          }

          .v-list-item:not(:last-child):not(:only-child) {
              margin-bottom: 2px;
          }

      }

      .v-list-group__header {
          .v-list-item__icon {
            margin-right: 16px !important;
          }
      }

      .flex-active{
          color: var(--v-secondary-base);
      }

  }

</style>



 