<template>
  <div v-if="vb.mdAndUp" class="d-flex" style="width: 100%">
    <v-sheet
      color="light_gray_1"
      class="py-8 px-12"
      width="50%"
      outlined
      elevation="0" >
      <v-img
        max-width="120"
        class="flex-brand"
        :src="require(`~/assets/images/nodifi.svg`)"
        position="center left"
        contain >
      </v-img>
      <div class="mt-2 mb-6 font-size-20">Data</div>

      <v-list color="transparent">
        <v-list-item two-line class="px-0" v-for="item in getNodifiData" :key="item.name">
          <v-list-item-content>
            <v-list-item-title class="font-weight-medium" >{{ item.name }}</v-list-item-title >
            <v-list-item-subtitle>{{ item.value }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>

    </v-sheet>
    <v-sheet class="py-8 px-12" width="50%">
      <v-img
        max-width="120"
        class="flex-brand"
        :src="require(`~/assets/images/logo/cropped/lender-logo-pepper.png`)"
        position="center left"
        contain >
      </v-img>
      <div class="mt-2 mb-8 font-size-20">Requirements</div>
        <ValidationObserver ref="observer" tag="form">
            <ValidationProvider
              key="isAbncontinuityrequired"
              v-slot="{ errors }"
              name="ABN Continuity"
              rules="required"
              vid="isAbncontinuityrequired">
                <ButtonGroup3
                  v-model="isAbncontinuityrequired"
                  :error-messages="errors"
                  :options="['Yes', 'No']"
                  label="ABN Continuity" />
            </ValidationProvider>
            
            <template v-if="isAbncontinuityrequired == 'Yes'">
              <ValidationProvider
                key="abn"
                v-slot="{ errors }"
                name="ABN"
                rules="required|numeric|digits:11"
                vid="abn">
                <v-text-field
                    tile
                    type="text"
                    label="Previous ABN"
                    color="secondary"
                    :error-messages="errors"
                    v-model="resources.entity.attributes['previous-abn']"
                    required>
                </v-text-field>
              </ValidationProvider>
      
              <ValidationProvider
                key="acn"
                v-slot="{ errors }"
                name="ACN"
                rules="numeric|digits:9"
                vid="acn">
                <v-text-field
                    tile
                    type="text"
                    label="Previous ACN"
                    color="secondary"
                    :error-messages="errors"
                    v-model="resources.entity.attributes['previous-acn']"
                    required>
                </v-text-field>
              </ValidationProvider>

              <ValidationProvider
                key="previousIndustry"
                v-slot="{ errors }"
                name="Industry"
                rules="required"
                vid="industry">
                <v-select
                v-model="resources.entity.attributes.lender.pepper.previousIndustry"
                  :items="getOptions('previousIndustry')"
                  :error-messages="errors"
                  color="secondary"
                  label="Previous Industry"
                ></v-select>
              </ValidationProvider>
            </template>
        </ValidationObserver>
    </v-sheet>
  </div>

  <!-- mobile version -->
  <div v-else>
      <v-card
        flat
        class="mx-5 rounded-xl"
        :style="`background-color: ${hexToRGBA( getActiveTheme.colors.secondary, 0.2 )}`">
        <v-card-text class="d-flex py-1">
          <v-row>
            <v-col cols="6" class="pa-1">
              <v-card
                min-height="45"
                flat
                class="rounded-xl d-flex align-center justify-center"
                :color="tabSelection == 'nodifi' ? 'white' : 'transparent'" >
                <div class="d-flex" @click="selectTab('nodifi')" >
                  <v-img
                    max-width="55"
                    class="flex-brand"
                    :src="require(`~/assets/images/nodifi.svg`)"
                    position="center left"
                    contain >
                  </v-img>
                </div>
              </v-card>
            </v-col>
            <v-col cols="6" class="pa-1">
              <v-card
                min-height="45"
                flat
                class="rounded-xl d-flex align-center justify-center"
                :color="tabSelection == 'pepper' ? 'white' : 'transparent'" >
                <div class="d-flex" @click="selectTab('pepper')">
                    <v-img
                      max-width="45"
                      class="flex-brand"
                      :src="require(`~/assets/images/logo/cropped/lender-logo-pepper.png`)"
                      position="center left"
                      contain >
                    </v-img>
                </div>
              </v-card>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>

      <v-list v-show="tabSelection == 'nodifi'" class="mx-8" color="transparent">
        <v-list-item two-line class="px-0" v-for="item in getNodifiData" :key="item.name">
          <v-list-item-content>
              <v-list-item-title class="font-weight-medium">
                  {{ item.name }}
              </v-list-item-title>
              <v-list-item-subtitle>              
                  {{ item.value }}
              </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>

      <div v-show="tabSelection != 'nodifi'" class="mx-8">
          <ValidationObserver ref="observer" tag="form">

              <ValidationProvider
                key="isAbncontinuityrequired"
                v-slot="{ errors }"
                name="ABN Continuity"
                rules="required"
                vid="isAbncontinuityrequired">
                <ButtonGroup3
                  v-model="isAbncontinuityrequired"
                  :error-messages="errors"
                  :options="['Yes', 'No']"
                  label="ABN Continuity"
                />
              </ValidationProvider>
              
              <template v-if="isAbncontinuityrequired == 'Yes'">
                <ValidationProvider
                  key="abn"
                  v-slot="{ errors }"
                  name="ABN"
                  rules="required|numeric|digits:11"
                  vid="abn">
                  <v-text-field
                      tile
                      type="text"
                      label="ABN"
                      color="secondary"
                      :error-messages="errors"
                      v-model="resources.entity.attributes['previous-abn']"
                      required>
                  </v-text-field>
                </ValidationProvider>
        
                <ValidationProvider
                  key="acn"
                  v-slot="{ errors }"
                  name="ACN"
                  rules="numeric|digits:9"
                  vid="acn">
                  <v-text-field
                      tile
                      type="text"
                      label="ACN"
                      color="secondary"
                      :error-messages="errors"
                      v-model="resources.entity.attributes['previous-acn']"
                      required>
                  </v-text-field>
                </ValidationProvider>

                <ValidationProvider
                  key="previousIndustry"
                  v-slot="{ errors }"
                  name="Industry"
                  rules="required"
                  vid="previousIndustry">
                  <v-select
                  v-model="resources.entity.attributes.lender.pepper.previousIndustry"
                    :items="getOptions('previousIndustry')"
                    :error-messages="errors"
                    color="secondary"
                    label="Industry"
                  ></v-select>
                </ValidationProvider>
              </template>
          </ValidationObserver>
      </div>
  </div>

</template>

<script>
import { mapActions, mapMutations, mapGetters } from "vuex";
import subscriptions from "~/components/shared/subscriptions";
import shared from "~/components/v1/submission/PepperCommercialEntities1/__shared";
import { store, actions, } from "~/components/v1/submission/PepperCommercialEntities1/__store";
import ButtonGroup3 from "~/components/base/ButtonGroup3";
import { ValidationProvider, ValidationObserver } from "vee-validate";

export default {
  components: { ButtonGroup3 },
  mixins: [subscriptions, shared],
  props: {
    data: {
      type: Object,
      default: null,
    },
    updater: undefined,
  },
  data() {
    return {
      tabSelection: "nodifi",
      // not included in the BE
      isAbncontinuityrequired: 'Yes',
      resources: {
        entity: {
          id: null,
          type: null,
          attributes: {
            "previous-abn": null,
            "previous-acn": null,
            "nature-of-business": null,
            "lender": {
                "pepper": {
                  "previousIndustry": null,
                  "mainBusinessActivity": null,
                }
            },
          },
        },
      },
      labels: {
      },
      validation_rules: {
        attributes: {
        },
      },
      options: {
        "previousIndustry": [
          {
            text: "Accommodation And Food Services",
            value: "ACCOMMODATION AND FOOD SERVICES",
          },
          {
            text: "Administrative And Support Services",
            value: "ADMINISTRATIVE AND SUPPORT SERVICES",
          },
          {
            text: "Agriculture, Forestry And Fishing",
            value: "AGRICULTURE, FORESTRY AND FISHING",
          },
          {
            text: "Arts And Recreation Services",
            value: "ARTS AND RECREATION SERVICES",
          },
          { text: "Construction", value: "CONSTRUCTION" },
          { text: "Education And Training", value: "EDUCATION AND TRAINING" },
          {
            text: "Electricity, Gas, Water And Waste Services",
            value: "ELECTRICITY, GAS, WATER AND WASTE SERVICES",
          },
          {
            text: "Financial And Insurance Services",
            value: "FINANCIAL AND INSURANCE SERVICES",
          },
          {
            text: "Health Care And Social Assistance",
            value: "HEALTH CARE AND SOCIAL ASSISTANCE",
          },
          {
            text: "Information Media And Telecommunications",
            value: "INFORMATION MEDIA AND TELECOMMUNICATIONS",
          },
          { text: "Manufacturing", value: "MANUFACTURING" },
          { text: "Mining", value: "MINING" },
          { text: "Other Services", value: "OTHER SERVICES" },
          {
            text: "Professional, Scientific And Technical Services",
            value: "PROFESSIONAL, SCIENTIFIC AND TECHNICAL SERVICES",
          },
          {
            text: "Public Administration And Safety",
            value: "PUBLIC ADMINISTRATION AND SAFETY",
          },
          {
            text: "Rental Hiring And Real Estate Services",
            value: "RENTAL HIRING AND REAL ESTATE SERVICES",
          },
          { text: "Retail Trade", value: "RETAIL TRADE" },
          {
            text: "Transport, Postal And Warehousing",
            value: "TRANSPORT, POSTAL AND WAREHOUSING",
          },
          { text: "Wholesale Trade", value: "WHOLESALE TRADE" },
        ],
      },
      errors: [],

    };
  },

  computed: {
    vb() {
      return this.$vuetify.breakpoint;
    },
    getNodifiData(){
      return [
        {
          name: 'ABN Number', 
          value: this.data.attributes['abn-number']
        },
        {
          name: 'Busines Legal Name', 
          value: this.data.attributes['business-legal-name']
        },
        {
          name: 'Date Registered ABN', 
          value: this.data.attributes['date-registered-abn']
        }
      ]
    },
    getIsAbncontinuityrequired(){
      return this.isAbncontinuityrequired
    }
  },
  mounted() {
    this.updateResourceFields();
  },
  methods: {
    ...mapActions("resource-actions", ["getPerson", "getBusiness" ]),
    isBtnGroup(key) {
      let keys = ["lender-pepper-forsee-repayment"];

      return keys.includes(key);
    },
    async validate() {

      const valid = await this.$refs.observer.validate();

      let allValid = this.errors.length == 0 && valid

      if (allValid) {
        this.$emit("submit", this.resources.entity);
      }

    },
    reset() {
      this.$refs.observer.reset();
    },
    async updateResourceFields() {
      // console.log('updateResourceFields')
      
      this.$emit("loading", true);

      const { data } = this;

      if (data) {

        let res = null

        if(data.type == "businesses") {

          res = await this.getBusiness(data.id);

        } else {

          res = await this.getPerson(data.id);

        }

        this.resources.entity = this.filterResourceFields(
          this.resources.entity,
          res.data.data
        );

        this.$emit("loading", false);

      } else {
        this.$emit("loading", false);
      }
    },
    selectTab(tab) {
      this.tabSelection = tab;
    },
  },
  watch: {
    updater(val) {
      if (val) {
        this.updateResourceFields();
      }
    },
    getIsAbncontinuityrequired(val) {
      if(val == "No") {
        this.resources.entity.attributes['previous-abn'] = null
        this.resources.entity.attributes['previous-acn'] = null
        this.resources.entity.attributes.lender.pepper.previousIndustry = null
      }
    }
  },
};
</script>

<style lang="scss" scoped>

</style>
