export default async (context) => {

    if(!context.store.state.flows.steps) return; // Error fetching config if flow is null

    /**
     * Check for Stepables in the current step
     */

    const {steps} = context.store.state.flows
    const {activeStep} = context.store.state.flows

    let stepables = findStepables(steps[parseInt(activeStep) - 1])


    if(stepables.length == 0) {
        context.store.commit('subscriptions/setStepable', false)
        return
    }

    if(stepables.length == 1) {
        context.store.commit('subscriptions/setStepable', true)
        return
    }

    if(stepables.length > 1) {

        context.error({
            message: 'Multiple Stepables',
            statusCode: 400
        })

    }

}



function findStepables(step){

    let stepables = []

    for (let x = 0; x < step.layout.spaces.length; x++) {

        const space = step.layout.spaces[x];

        for (let y = 0; y < space.blocks.length; y++) {

            const block = space.blocks[y];

            if( block.subscriptions ) {

                if( block.subscriptions.includes('Stepable') ) {
                    stepables.push(block)
                }

            }
            
        }
        
    }

    return stepables;

}