import { getField, updateField } from 'vuex-map-fields'

export const state = () => ({
    loader_dialog: false,
    current_page: '',
    drawer: true,
    alert_settings: {
        alert: false,
        type: 'success',
        text: '',
        prominent: false,       // optional
        dense: false,           // optional
        dismissible: true,       // optional
        isStepValidator: false
    },
    notification_settings: {
        snackbar: false,
        color: 'success',
        y: 'top',
        x: null,
        mode: 'multi-line',
        timeout: -1,
        text: 'Default Message!',
        timer: 5000
    },
    errorMessages: {},
    simpleKYCApplicationCreated: false,
    test: ''
})

export const getters = {
    getField,
    notification_settings: (state) => state.notification_settings,
    getSimpleKYCApplicationCreated: (state) => state.simpleKYCApplicationCreated,
}

export const actions = {
    async requestAbn({}, data = {}) {
        return await this.$api.post(`run/abn-lookup`, data )
    }
}

export const mutations = {
    setSimpleKYCApplicationCreated(state, bool) {
        state.simpleKYCApplicationCreated = bool
    },
    updateField,
    toggleAlert(state, data) {
        if (state.alert_settings.alert) { state.alert_settings.alert = false }
        state.alert_settings.alert = !state.alert_settings.alert
        state.alert_settings.text = data.text
        state.alert_settings.isStepValidator = data.isStepValidator
        state.alert_settings.type = data.type
        state.alert_settings.dense = data.dense
        state.alert_settings.dismissible = data.dismissible
        state.alert_settings.prominent = data.prominent 
    },
    clearAlert(state) {
        state.alert_settings.alert = false
        state.alert_settings.type = 'success'
        state.alert_settings.text = ''
        state.alert_settings.prominent = false
        state.alert_settings.dense = false
        state.alert_settings.dismissible = true
        state.alert_settings.isStepValidator = false
    },
    closeAlert(state) {
        state.alert_settings.alert = false
    },
    toggleQuickMessage(state, data) {
        if (state.notification_settings.snackbar) { state.notification_settings.snackbar = false }
        state.notification_settings.snackbar = !state.notification_settings.snackbar
        state.notification_settings.text = data.text
        state.notification_settings.color = data.color
        if(data.timer) { state.notification_settings.timer = data.timer}
    },
    forceCloseQuickMessage(state) {
        state.notification_settings.snackbar = false
    }
}