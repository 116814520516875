<template>
    <v-dialog
        persistent
        scrollable
        height="600"
        max-width="1100"
        v-model="dialog">

        <v-card flat tile :disabled="saving" class="pa-4">

            <v-progress-linear color="secondary" indeterminate stream absolute top v-if="saving"></v-progress-linear>

            <v-card-title class="d-flex py-0 my-0">
                <div class="v-card__title pa-0 sm">
                    {{title}}
                </div>
                <v-spacer />
                <div>
                    <v-img 
                        v-if="selectedLender.details"
                        max-width="100"
                        height="100"
                        :src="getLenderLogo(selectedLender.details['logo-class'])" 
                        position="center left" 
                        contain>
                    </v-img>
                </div>
            </v-card-title>

            <v-card-text>
                <v-stepper v-model="activeStep" alt-labels>
                    <v-stepper-items>
                        <v-stepper-content class="pa-0" step="1">
                            <ValidationObserver 
                                ref="observer1" 
                                tag="form">   
                                <v-row>
                                    <v-col :cols="vb.smAndUp ? 4 : 12" 
                                        v-for="(field, key) in getQuotedFields"
                                        :key="key"
                                        class="py-0"
                                    >
                                        <ValidationProvider
                                            v-if="isVisible(key)"
                                            v-slot="{ errors }"
                                            :name="labels[key]"
                                            :rules="getRules(key)"
                                            :vid="key">

                                            <v-select
                                                v-if="key == 'quoted-term-months'"
                                                v-model="resources.loans.attributes[key]"
                                                :items="options[key]"
                                                item-text="key"
                                                item-value="value"
                                                :error-messages="errors"
                                                color="secondary"
                                                :suffix="suffix[key]"
                                                :label="labels[key]"
                                            ></v-select>

                                            <v-select
                                                v-else-if="key == 'quoted-payment-terms'"
                                                v-model="resources.loans.attributes[key]"
                                                :items="options[key]"
                                                item-text="key"
                                                item-value="value"
                                                :error-messages="errors"
                                                color="secondary"
                                                :suffix="suffix[key]"
                                                :label="labels[key]"
                                            ></v-select>

                                            <template v-else>
                                                <v-text-field
                                                    v-if="key == 'quoted-base-rate' || key == 'quoted-customer-rate'"
                                                    v-model="resources.loans.attributes[key]"
                                                    :label="labels[key]"
                                                    :error-messages="errors"
                                                    suffix="%"
                                                    flat>
                                                </v-text-field>
                                                <NodifiMoney
                                                    v-else
                                                    v-model="resources.loans.attributes[key]"
                                                    :label="labels[key]"
                                                    :errors="errors" /> 
                                            </template>                                    

                                        </ValidationProvider>
                                        
                                    </v-col>
                                </v-row>
                            </ValidationObserver>
                        </v-stepper-content>

                        <v-stepper-content class="pa-0" step="2">
                            <ValidationObserver 
                                ref="observer2" 
                                tag="form"
                            >   
                                <v-row>
                                    <v-col cols="12" 
                                        v-for="(field, key) in resources['cpdd']"
                                        :key="key"
                                        class="py-0"
                                    >

                                        <ValidationProvider
                                            v-if="isVisible(key)"
                                            v-slot="{ errors }"
                                            :name="labels[key]"
                                            :rules="getRules(key)"
                                            :vid="key">

                                            <v-textarea
                                                v-model="resources['cpdd'][key]"
                                                rows="3"
                                                auto-grow
                                                :error-messages="errors"
                                                :label="labels[key]"></v-textarea>                                 

                                        </ValidationProvider>
                                        
                                    </v-col>
                                </v-row>
                            </ValidationObserver>
                        </v-stepper-content>



                    </v-stepper-items>
                </v-stepper>
                
            </v-card-text>

            <v-card-actions class="pa-4 ma-2">

                <template>
                    <v-btn v-if="activeStep == 1" @click="closeModal" :min-width="140" large depressed outlined color="secondary">
                        Back
                    </v-btn>
                    <v-btn v-else @click="activeStep--, modalSize = 1100" :min-width="140" large depressed outlined color="secondary">
                        Back
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn v-if="activeStep == 1" @click="selectNextStep" :min-width="140" large depressed class="secondary">
                        Next
                    </v-btn>
                    <v-btn v-else @click="selectLender" :min-width="140" large depressed class="secondary">
                        Next
                    </v-btn>
                </template>
                
            </v-card-actions>

        </v-card>

    </v-dialog>
</template>
<script>
import { mapState, mapActions, mapMutations } from 'vuex'
import subscriptions from '~/components/shared/subscriptions'
import helpers from '~/plugins/helpers'
import { store, mutations } from './__store'
import { isEmpty } from 'lodash'
import shared from './__shared'
import baseComponents from '~/components/base'

export default {
    name: 'QuoteToolLenderModal',
    mixins: [ subscriptions, helpers, shared ],
    components: {
        ...baseComponents
    },
    props: {
        dialog: {
            type: Boolean,
            default: false
        },
        selectedLender: {
            type: Object,
            default: () => {}
        },
        title: {
            type: String,
            default: 'Primary'
        },
        requiredLoanAmount: {
            type: [String, Number],
            default: ""
        }
    },
    data() {
        return {
            saving: false,
            activeStep: 1,
            modalSize: 1100,
            resources: {
                loans: {
                    attributes: {
                        "quoted-term-months": "",
                        "quoted-customer-rate": "",
                        "quoted-bank-fees": "",
                        "quoted-broker-fee": "",
                        "quoted-broker-commission-inc-gst": "",
                        "quoted-naf": "",
                        "quoted-account-fee": "",
                        "quoted-monthly-payments": "",
                        "quoted-balloon": "",
                        "quoted-base-rate": "",
                        "selected-lender-quote-algorithm-slug": null,
                        "actual-balloon": "",
                        "actual-bank-fees": "",
                        "actual-base-rate": "",
                        "actual-broker-commission-inc-gst": "",
                        "actual-broker-fee": "",
                        "actual-customer-rate": "",
                        "actual-monthly-payments": "",
                        "actual-account-fee": "",
                        "actual-term-months": "",
                    }
                },
                // CPDD Fields
                cpdd: {
                    'tmd_client_recommendation_reason': '',
                }
            },
            labels: {
                'quoted-payment-terms': 'Payment Terms',
                'quoted-balloon': 'Balloon Payment',
                'quoted-bank-fees': 'Establishment Fee',
                'quoted-base-rate': 'Base Rate',
                'quoted-broker-commission-inc-gst': 'Brokerage Commission',
                'quoted-broker-fee': 'Origination Fee',
                'quoted-customer-rate': 'Customer Rate',
                'quoted-monthly-payments': 'Monthly Payment',
                'quoted-naf': 'Amount Financed',
                'quoted-account-fee': 'Monthly Fee',
                'quoted-term-months': 'Loan term',

                "tmd_client_recommendation_reason": "Recommendation Reason",
            },
            validation_rules: {
                attributes: {
                    "quoted-balloon": 'required',
                    "quoted-bank-fees": 'required|between:0,9999999',
                    "quoted-base-rate": 'required|between:0,99',
                    "quoted-broker-commission-inc-gst": 'required|between:0,9999999',
                    "quoted-broker-fee": 'required',
                    "quoted-customer-rate":  'required|between:0,99',
                    "quoted-monthly-payments": 'required|between:0,9999999',
                    "quoted-naf": 'required',
                    "quoted-account-fee": 'required|between:0,9999999',
                    "quoted-term-months": "required",
                    "selected-lender-quote-algorithm-slug": "",
                    "tmd_client_recommendation_reason": "required",
                }
            },
            options: {
                "quoted-term-months": [
                    { key: 12, value: 12 },
                    { key: 24, value: 24 },
                    { key: 36, value: 36 },
                    { key: 48, value: 48 },
                    { key: 60, value: 60 },
                    { key: 72, value: 72 },
                    { key: 84, value: 84 }
                ],
                "quoted-payment-terms": [ "In Arrears", "In Advance" ]
            },
            visible: {
                'quoted-ppsr-fee': false, 
                'origination-fee': false, 
                'total-loan-amount': false, 
                'monthly-account-fee': false, 
                'monthly-payment': false, 
                "selected-lender-quote-algorithm-slug": false,
            },
            suffix: {
                "quoted-term-months": 'months',
                "quoted-payment-terms": ''
            },
        }
    },
    computed: {
        ...mapState({
            appGuid: state => state.flows.appGuid,
        }),
        vb() {
            return this.$vuetify.breakpoint
        },
        getLoanId(){
            return this.appData?.relationships?.loan?.data?.id
        },
        getQuotedFields(){

            return Object.keys(this.resources.loans.attributes).reduce((result, key) => {
                if (!key.startsWith("actual-")) {
                    result[key] = this.resources.loans.attributes[key];
                }
                return result;
            }, {});

        }
    },
    watch: {
        selectedLender(val) {

            // console.log('selectedLender', val)

            /**
             * CPDD get quoted-* fields populated. 
             */
            this.resources.loans.attributes['selected-lender-quote-algorithm-slug'] = val['name']

            // console.log(val)

            this.resources.loans.attributes['quoted-payment-terms'] = val['payment-terms'] || this.options['quoted-payment-terms'][1]
            this.resources.loans.attributes['quoted-balloon'] = val.details['actual-payment']['balloon']
            this.resources.loans.attributes['quoted-bank-fees'] = val.details['actual-payment']['bank-fees']
            this.resources.loans.attributes['quoted-base-rate'] = val.details['actual-payment']['base-rate']
            this.resources.loans.attributes['quoted-broker-commission-inc-gst'] = val.details['actual-payment']['commission-inc-gst']
            this.resources.loans.attributes['quoted-broker-fee'] = val.details['actual-payment']['broker-fee']
            this.resources.loans.attributes['quoted-customer-rate'] = val.details['actual-payment']['customer-rate']
            this.resources.loans.attributes['quoted-monthly-payments'] = val.details['actual-payment']['monthly-payment']
            this.resources.loans.attributes['quoted-naf'] = val.details['actual-payment']['naf']
            this.resources.loans.attributes['quoted-account-fee'] = val.details['actual-payment']['account-fee']
            this.resources.loans.attributes['quoted-term-months'] = parseInt(val.details['actual-payment']['term-in-months'])

            // Actual fields
            this.resources.loans.attributes['actual-lender-quote-algorithm-slug'] = val['name']
            this.resources.loans.attributes['actual-payment-terms'] = val['payment-terms'] || this.options['quoted-payment-terms'][1]
            this.resources.loans.attributes['actual-balloon'] = val.details['actual-payment']['balloon']
            this.resources.loans.attributes['actual-bank-fees'] = val.details['actual-payment']['bank-fees']
            this.resources.loans.attributes['actual-base-rate'] = val.details['actual-payment']['base-rate']
            this.resources.loans.attributes['actual-broker-commission-inc-gst'] = val.details['actual-payment']['commission-inc-gst']
            this.resources.loans.attributes['actual-broker-fee'] = val.details['actual-payment']['broker-fee']
            this.resources.loans.attributes['actual-customer-rate'] = val.details['actual-payment']['customer-rate']
            this.resources.loans.attributes['actual-monthly-payments'] = val.details['actual-payment']['monthly-payment']
            this.resources.loans.attributes['actual-account-fee'] = val.details['actual-payment']['account-fee']
            this.resources.loans.attributes['actual-term-months'] = parseInt(val.details['actual-payment']['term-in-months'])
            // this.resources.loans.attributes['actual-ppsr-fee'] = val.details['variables']['ppsr'] ?? 0

            // Populate hidden fields
            this.resources.loans.attributes['quoted-ppsr-fee'] = val.details['variables']['ppsr'] ?? 0
            this.resources.loans.attributes['origination-fee'] = val.inputs['requested-broker-fee'] ?? 0
            this.resources.loans.attributes['total-loan-amount'] = val.details['actual-payment']['naf']
            this.resources.loans.attributes['monthly-account-fee'] = val.details['variables']['monthly-account-fee']
            this.resources.loans.attributes['monthly-payment'] = val.details['actual-payment']['monthly-payment']

        }
    },
    methods: {
        ...mapActions('resource-actions', [
            'updateLoan',
            'updateCreditProposalDisclosures',
        ]),
        closeModal() {
            this.$emit('close-modal')
        },
        async selectLender() {
            const valid = await this.$refs.observer2.validate()

            if(valid) {

                let payload = {
                    appGuid: this.appGuid,
                    data: this.resources.cpdd,
                }

                await this.updateCreditProposalDisclosures(payload)

                this.resources.loans.attributes['selected-lender-quote-algorithm-slug'] = this.selectedLender.name

                this.resources.loans.attributes['actual-payment-terms'] = this.resources.loans.attributes['quoted-payment-terms']
                this.resources.loans.attributes['actual-balloon'] = this.resources.loans.attributes['quoted-balloon']
                this.resources.loans.attributes['actual-bank-fees'] = this.resources.loans.attributes['quoted-bank-fees']
                this.resources.loans.attributes['actual-base-rate'] = this.resources.loans.attributes['quoted-base-rate']
                this.resources.loans.attributes['actual-broker-commission-inc-gst'] = this.resources.loans.attributes['quoted-broker-commission-inc-gst']
                this.resources.loans.attributes['actual-broker-fee'] = this.resources.loans.attributes['quoted-broker-fee']
                this.resources.loans.attributes['actual-customer-rate'] = this.resources.loans.attributes['quoted-customer-rate']
                this.resources.loans.attributes['actual-monthly-payments'] = this.resources.loans.attributes['quoted-monthly-payments']
                this.resources.loans.attributes['actual-account-fee'] = this.resources.loans.attributes['quoted-account-fee']
                this.resources.loans.attributes['actual-term-months'] = this.resources.loans.attributes['quoted-term-months']

                this.$emit('select-lender', {
                    loans: this.resources.loans, 
                    lender: this.selectedLender.name
                })
            }
            
        },

        async selectNextStep() {

            const valid = await this.$refs.observer1.validate()

            if(valid) {
                this.activeStep = 2
            }
        },
        isVisible(key){
                
            const {visible} = this

            if(visible.hasOwnProperty(key)){
                return visible[key]
            }

            return true

        },
        getLenderLogo(name){

            let lender = require(`~/assets/images/logo/placeholder.png`)

            if(!name)
                return lender

            try {

                lender = require(`~/assets/images/logo/${name}.png`)
                return lender

            } catch (error) {

                console.log('Ooops!..', error)
                return lender

            }

        },
    }
}
</script>
