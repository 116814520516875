<template>
    <v-card class="mx-auto px-8" flat>
        <div v-if="vb.mdAndUp" >
            <div class="d-flex">
                <v-sheet width="50%" color="light_gray_1" class="pa-4">
                    <div class="px-4 pt-4">
                        <v-img 
                            max-width="120"
                            class="flex-brand" 
                            :src="require(`~/assets/images/nodifi.svg`)" 
                            position="center left" 
                            contain>
                        </v-img>
                        <div class="mt-2 font-size-20">Data</div>
                    </div>

                    <v-card flat class="overflow-y-auto light_gray_1 mt-4">
                        <v-card-text class="d-flex">

                            <v-list class="pt-0 light_gray_1">

                                <v-list-item v-if="!creditAndCardLiabilities && !unsecuredPersonalLoanLiability" two-line class="px-0">
                                    <v-list-item-content class="pt-0">
                                        <v-list-item-title class="pt-2 font-italic">No Data</v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>

                                <v-list-item v-if="creditAndCardLiabilities" two-line class="px-0">
                                    <v-list-item-content class="pt-0">
                                        <v-list-item-title class="font-weight-bold">Does the applicant have a credit card?</v-list-item-title>
                                        <v-list-item-title class="pt-2">Yes</v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>

                                <v-list-item v-if="creditAndCardLiabilities" two-line class="px-0 pt-2">
                                    <v-list-item-content class="pt-0">
                                        <v-list-item-title class="font-weight-bold">Credit Card Limit</v-list-item-title>
                                        <v-list-item-title class="pt-2">{{creditAndCardLiabilities.attributes['credit-limit'] | currency}}</v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>

                                <v-list-item v-if="unsecuredPersonalLoanLiability" two-line class="px-0 pt-2">
                                    <v-list-item-content class="pt-0">
                                        <v-list-item-title class="font-weight-bold">Does the applicant have a personal Loan?</v-list-item-title>
                                        <v-list-item-title class="pt-2">Yes</v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>

                                <v-list-item v-if="unsecuredPersonalLoanLiability" two-line class="px-0 pt-2">
                                    <v-list-item-content class="pt-0">
                                        <v-list-item-title class="font-weight-bold">Personal Loan Limit</v-list-item-title>
                                        <v-list-item-title class="pt-2">{{unsecuredPersonalLoanLiability.attributes['amount-owing'] |currency}}</v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>

                            </v-list>

                        </v-card-text>
                    </v-card>
                </v-sheet>


                <v-sheet width="50%" class="pa-4">
                    <div class="px-4">
                        <v-img 
                            max-width="120"
                            class="flex-brand" 
                            :src="require(`~/assets/images/logo/cropped/lender-logo-moneyplace.png`)" 
                            position="center left" 
                            contain>
                        </v-img>
                        <div class="mt-2 font-size-20">Requirements</div>
                    </div>
                    <v-card flat class="overflow-y-auto mt-4">
                        <v-card-text class="d-flex pa-0">

                            <div class="px-4" style="width: 400px;">
                                <ValidationObserver ref="observer" tag="form">
                                    <div v-for="(layout, index) in layouts" :key="index">
                                        <template v-for="(key, index) in layout">

                                            <div :key="index" v-if="key == '__spacer'">
                                                <div class="py-5"></div>
                                            </div>

                                            <div v-else>
                                                <ValidationProvider 
                                                    v-if="isVisible(key)"
                                                    v-slot="{ errors }" 
                                                    :name="labels[key]"
                                                    :rules="getRules(key)" 
                                                    :vid="key">
                                                        <button-selections 
                                                            v-if="isBtnGroup(key)"
                                                            class="pb-2"
                                                            v-model="resources['money-place'].attributes[key]"
                                                            :error-messages="errors"
                                                            :options="getOptions(key)"
                                                            :label="labels[key]" />
                                                        
                                                        <v-select
                                                            v-else-if="isSelect(key)"
                                                            v-model="resources['money-place'].attributes[key]"
                                                            :items="getOptions(key)"
                                                            :error-messages="errors"
                                                            color="secondary"
                                                            :label="labels[key]">
                                                        </v-select>

                                                        <v-text-field
                                                            v-else
                                                            :label="labels[key]"
                                                            class="pt-3"
                                                            color="secondary"
                                                            :error-messages="errors"
                                                            :prefix="key === 'newLimit' ? '$' : ''"
                                                            v-model="resources['money-place'].attributes[key]">
                                                        </v-text-field>
                                                </ValidationProvider>
                                            </div>

                                        </template>
                                    </div>
                                </ValidationObserver>
                            </div>

                        </v-card-text>
                    </v-card>
                </v-sheet>
            
            </div>

        </div>
        
        <!-- mobile version -->
        <div v-else>

            <v-card flat class="mx-2 rounded-xl" :style="`background-color: ${hexToRGBA(getActiveTheme.colors.secondary, 0.2)}`">

                <v-card-text class="d-flex py-1">
                    <v-row>
                        <v-col cols="6" class="pa-1">
                            <v-card
                                min-height="45"
                                flat class="rounded-xl px-1"
                                :color="tabSelection == 'nodifi' ? 'white' : 'transparent'">
                                <div class="d-flex justify-center pt-1" @click="selectTab('nodifi')">
                                    <v-img 
                                        max-width="55"
                                        class="flex-brand" 
                                        :src="require(`~/assets/images/nodifi.svg`)" 
                                        position="center left" 
                                        contain>
                                    </v-img>
                                    <div class="mt-3 font-size-12 pl-1">Data</div>
                                </div>
                            </v-card>
                        </v-col>
                        <v-col cols="6" class="pa-1">
                            <v-card
                                min-height="45"
                                flat class="rounded-xl px-1"
                                :color="tabSelection == 'money-place' ? 'white' : 'transparent'">
                                <div class="d-flex flex-column" @click="selectTab('money-place')">
                                    <v-img 
                                        max-width="55"
                                        class="flex-brand mx-auto mt-1" 
                                        :src="require(`~/assets/images/logo/cropped/lender-logo-moneyplace.png`)" 
                                        position="center left" 
                                        contain>
                                    </v-img>
                                    <div class="font-size-12 pl-1 mx-auto mt-n1">Requirements</div>
                                </div>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-card-text>
                
            </v-card>

            <v-list v-show="tabSelection == 'nodifi'" class="mx-2" color="transparent">

                <v-list-item v-if="!creditAndCardLiabilities && !unsecuredPersonalLoanLiability" two-line class="px-0">
                    <v-list-item-content class="pt-0">
                        <v-list-item-title class="pt-2 font-italic">No Data</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>

                <v-list-item v-if="creditAndCardLiabilities" two-line class="px-0">
                    <v-list-item-content class="pt-0">
                        <v-list-item-title class="font-weight-bold">Does the applicant have a credit card?</v-list-item-title>
                        <v-list-item-title class="pt-2">Yes</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>

                <v-list-item v-if="creditAndCardLiabilities" two-line class="px-0 pt-2">
                    <v-list-item-content class="pt-0">
                        <v-list-item-title class="font-weight-bold">Credit Card Limit</v-list-item-title>
                        <v-list-item-title class="pt-2">{{creditAndCardLiabilities.attributes['credit-limit'] | currency}}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>

                <v-list-item v-if="unsecuredPersonalLoanLiability" two-line class="px-0 pt-2">
                    <v-list-item-content class="pt-0">
                        <v-list-item-title class="font-weight-bold">Does the applicant have a personal Loan?</v-list-item-title>
                        <v-list-item-title class="pt-2">Yes</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>

                <v-list-item v-if="unsecuredPersonalLoanLiability" two-line class="px-0 pt-2">
                    <v-list-item-content class="pt-0">
                        <v-list-item-title class="font-weight-bold">Personal Loan Limit</v-list-item-title>
                        <v-list-item-title class="pt-2">{{unsecuredPersonalLoanLiability.attributes['amount-owing'] | currency}}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>

            </v-list>

            <div v-show="tabSelection != 'nodifi'" class="mx-2">
                <ValidationObserver ref="observer" tag="form">
                    <div v-for="(layout, index) in layouts" :key="index">
                        <template v-for="(key, index) in layout">

                            <div :key="index" v-if="key == '__spacer'">
                                <div class="py-5"></div>
                            </div>

                            <div v-else>
                                <ValidationProvider 
                                    v-if="isVisible(key)"
                                    v-slot="{ errors }" 
                                    :name="labels[key]"
                                    :rules="getRules(key)" 
                                    :vid="key">
                                        <button-selections 
                                            v-if="isBtnGroup(key)"
                                            class="pb-2"
                                            v-model="resources['money-place'].attributes[key]"
                                            :error-messages="errors"
                                            :options="getOptions(key)"
                                            :label="labels[key]" />
                                        
                                        <v-select
                                            v-else-if="isSelect(key)"
                                            v-model="resources['money-place'].attributes[key]"
                                            :items="getOptions(key)"
                                            :error-messages="errors"
                                            color="secondary"
                                            :label="labels[key]">
                                        </v-select>

                                        <v-text-field
                                            v-else
                                            :label="labels[key]"
                                            class="pt-3"
                                            color="secondary"
                                            :error-messages="errors"
                                            :prefix="key === 'newLimit' ? '$' : ''"
                                            v-model="resources['money-place'].attributes[key]">
                                        </v-text-field>
                                </ValidationProvider>
                            </div>

                        </template>
                    </div>
                </ValidationObserver>
            </div>

        </div>
    </v-card>
</template>

<script>
    import { mapActions, mapGetters } from 'vuex'
    import subscriptions from '~/components/shared/subscriptions'
    import ButtonSelections from '~/components/base/ButtonSelections'

    export default {
        name: 'LenderPepperExpenses1',
        components: { ButtonSelections },
        mixins: [subscriptions],
        data() {
            return {
                tabSelection: "nodifi",
                liabilities: [],
                expenses: [],
                visible: {
                    "close": false,
                    "creditCardPayments": false,
                    "newLimit": false,
                    "personalLoanPayments": false,
                    "reduce": false,
                },
                resources: {
                    "nodifi-expenses": {
                        "attributes": {
                        }
                    },
                    "money-place": {
                        "attributes": {
                            "close": null,
                            "creditCardPayments": null,
                            "newLimit": null,
                            "personalLoanPayments": null,
                            "reduce": null,
                            "__spacer": null,
                        }
                    }
                },
                options: {
                    "creditCardPayments": [
                        {text: 'Ahead', value: 'ahead'},
                        {text: 'Minimum', value: 'minimum'},
                        {text: 'Behind', value: 'behind'},
                        {text: 'Completely', value: 'completely'},
                    ],
                    "personalLoanPayments": [
                        {text: 'Ahead', value: 'ahead'},
                        {text: 'Minimum', value: 'minimum'},
                        {text: 'Behind', value: 'behind'},
                    ]
                },
                layouts: [
                    [
                        "creditCardPayments",
                        "personalLoanPayments",
                        "close",
                        "reduce", 
                    ],
                    [
                        "newLimit",
                    ]
                ],
                labels: {
                    "close": "Close",
                    "creditCardPayments": "Credit Card Payment",
                    "newLimit": "New Limit",
                    "personalLoanPayments": "Personal Loan Payment",
                    "reduce": "Reduce",
                },
                validation_rules: {
                    attributes: {
                        "__spacer": "",
                        "close": "required|string|in:Yes,No",
                        "creditCardPayments": "string|in:ahead,minimum,behind,completely",
                        "newLimit": "required|integer|between:1,9999999",
                        "personalLoanPayments": "string|in:ahead,minimum,behind",
                        "reduce": "required|string|in:Yes,No",
                    }
                },
            }
        },
        computed: {
            ...mapGetters('themes', [
                'getActiveTheme'
            ]),
            vb(){
                return this.$vuetify.breakpoint
            },
            creditAndCardLiabilities() {
                let { liabilities } = this
                let filteredLiability = null

                // 13-2 | name: Cards & Credit
                //     id: 13-3 | name: Credit Card
                //     id: 13-4 | name: Fuel Card
                //     id: 13-5 | name: Line of Credit - Unsecured
                //     id: 13-6 | name: Overdraft
                //     id: 13-7 | name: Store Card
                const kinds = ["13-3","13-4","13-5","13-6","13-7"]

                if(liabilities.length > 0) {

                    liabilities.forEach(liability => {
                        if(liability.relationships?.kind?.data?.id && kinds.includes(liability.relationships.kind.data.id)) {
                            filteredLiability = liability
                        }

                    })

                }

                return filteredLiability
            },

            unsecuredPersonalLoanLiability() {
                let filteredLiability = null
                let { liabilities } = this

                if(liabilities.length > 0) {

                    liabilities.forEach(liability => {
                        if(liability.relationships?.kind?.data?.id && liability.relationships.kind.data.id === '13-17') {
                            filteredLiability = liability
                        }

                    })

                }

                return filteredLiability
            },
            creditAndCardExpenses() {
                let { expenses } = this
                let filteredExpense = null

                // id: 11-10 | name: Card & Credit Payment
                //     id: 11-11 | name: Credit Card
                //     id: 11-12 | name: Fuel Card
                //     id: 11-13 | name: Line Of Credit - Unsecured
                //     id: 11-14 | name: Overdraft
                //     id: 11-15 | name: Store Card
                const kinds = ["11-11","11-12","11-13","11-14","11-15"]

                if(expenses.length > 0) {

                    expenses.forEach(expense => {
                        if(expense.relationships?.kind?.data?.id && kinds.includes(expense.relationships.kind.data.id)) {
                            filteredExpense = expense
                        }

                    })

                }

                return filteredExpense
            },
            reduceField() {
                return this.resources['money-place'].attributes['reduce']
            },
            closeField() {
                return this.resources['money-place'].attributes['close']
            },
            reduceField() {
                return this.resources['money-place'].attributes['reduce']
            },
            toBePaidOutField() {
                return this.creditAndCardLiabilities?.attributes?.['to-be-paid-out']
            }
        },
        methods: {
            ...mapActions('resource-actions', [
                'updateLiability',
                'getPeople',
            ]),
            async pull() {

                try {
                    let promise1 = this.getPeople({"include" : 'parent-parts,liabilities,expenses'})

                    const res = await Promise.all([
                        promise1, 
                    ]) 

                    promise1 = res[0]

                    if(promise1.data && promise1.data.hasOwnProperty('included')) {
                        let liabilities = []
                        let expenses = []

                        promise1.data.included.forEach(item => {
                            if(item.type == 'liabilities') {
                                liabilities.push(item)
                            }

                            if(item.type == 'expenses') {
                                expenses.push(item)
                            }
                        })

                        this.liabilities = liabilities
                        this.expenses = expenses

                        await this.populateFields()
                        
                    }

                } catch (err) {
                    console.log(err)
                }

            },
            async push() {
                try {

                    let creditAndCardLiabilitiesRes = null;
                    let unsecuredPersonalLoanLiabilityRes = null;

                    if(this.creditAndCardLiabilities) {

                        const liability = {
                            "id": this.creditAndCardLiabilities.id,
                            "type": "liabilities",
                            "attributes": {
                                "lender": {
                                    "moneyPlace": {
                                        ...this.transformToBoolean(this.resources)
                                    }
                                }
                            }
                        }

                        creditAndCardLiabilitiesRes = this.updateLiability(liability)

                        await Promise.all([creditAndCardLiabilitiesRes])
                    }

                    if(this.unsecuredPersonalLoanLiability) {
                        
                        const liability = {
                            "id":this.unsecuredPersonalLoanLiability.id,
                            "type": "liabilities",
                            "attributes": {
                                "lender": {
                                    "moneyPlace": {
                                        ...this.transformToBoolean(this.resources)
                                    }
                                }
                            }
                        }

                        unsecuredPersonalLoanLiabilityRes = this.updateLiability(liability)

                        await Promise.all([unsecuredPersonalLoanLiabilityRes])
                    }

                    const {valid, status} = await this.validateFormData()
                    
                    return {valid, status, data: {}}
                } catch (err) {
                    console.log(err)
                }
            },
            populateFields() {
                let {  resources, creditAndCardLiabilities, creditAndCardExpenses, unsecuredPersonalLoanLiability, validation_rules} = this

                if(creditAndCardLiabilities) {
                    this.visible['creditCardPayments'] = true

                    // populate fields for pMoneyplace
                    Object.keys(resources['money-place'].attributes).forEach(key => {

                        if(['close','reduce'].includes(key)) {
                            resources['money-place'].attributes[key] = creditAndCardLiabilities.attributes['lender']['moneyPlace'][key] === true ? 'Yes' :  creditAndCardLiabilities.attributes['lender']['moneyPlace'][key] === false ? 'No' : ''
                        } else {
                            resources['money-place'].attributes[key] = creditAndCardLiabilities.attributes['lender']['moneyPlace'][key]
                        }

                    })

                } else if(unsecuredPersonalLoanLiability){

                    // populate fields for pMoneyplace
                    Object.keys(resources['money-place'].attributes).forEach(key => {

                        if(['close','reduce'].includes(key)) {
                            resources['money-place'].attributes[key] = unsecuredPersonalLoanLiability.attributes['lender']['moneyPlace'][key] === true ? 'Yes' :  unsecuredPersonalLoanLiability.attributes['lender']['moneyPlace'][key] === false ? 'No' : ''
                        } else {
                            resources['money-place'].attributes[key] = unsecuredPersonalLoanLiability.attributes['lender']['moneyPlace'][key]
                        }

                    })
                }

                if(creditAndCardExpenses) {
                    validation_rules.attributes['creditCardPayments'] = "required|string|in:ahead,minimum,behind,completely"
                }

                if(unsecuredPersonalLoanLiability){
                    this.visible['personalLoanPayments'] = true
                    validation_rules.attributes['personalLoanPayments'] = "required|string|in:ahead,minimum,behind"
                }

            },
            isTextarea (key) {
                return [
                    "lender-pepper-living-description",
                ].includes(key)
            },
            hexToRGBA(hex, a = 1){
                return this.$options.filters.hexToRGBA(hex, a)
            },
            selectTab(tab) {
                this.tabSelection = tab
            },
            isBtnGroup(key){

                let keys = [
                    "close",
                    "reduce"
                ]

                return keys.includes(key)

            },
            isVisible(key){
                
                const {visible} = this

                if(visible.hasOwnProperty(key)){
                    return visible[key]
                }

                return true

            },
            isSelect(key){
                let keys = [
                    "personalLoanPayments",
                    "creditCardPayments"
                ]

                return keys.includes(key)
            },
            transformToBoolean(resources) {
                let attr = {}

                Object.keys(resources['money-place'].attributes).forEach(key => {

                    if(!this.creditAndCardLiabilities && this.unsecuredPersonalLoanLiability) {
                        attr[key] = key === 'newLimit' ? parseInt(resources['money-place'].attributes[key]) : resources['money-place'].attributes[key]
                    } else {
                        if(['close','reduce'].includes(key)) {
                            attr[key] = resources['money-place'].attributes[key] === 'Yes'
                        } else {
                            attr[key] = key === 'newLimit' ? parseInt(resources['money-place'].attributes[key]) : resources['money-place'].attributes[key]
                        }
                    }
                    
                })

                return attr
            }
        },
        watch: {
            closeField(val) {
                if(val === "No") {
                    this.visible['reduce'] = true
                } else {
                    this.visible['reduce'] = false
                }
            },
            reduceField(val) {
                if(val === "Yes") {
                    this.visible['newLimit'] = true
                } else {
                    this.visible['newLimit'] = false
                }
            },
            toBePaidOutField(val) {
                if(val === 'Yes') {
                    this.visible['close'] = true
                } else {
                    this.visible['reduce'] = false
                    this.visible['newLimit'] = false
                    this.visible['close'] = false
                }
            }
        }
    }
</script>

<style lang="scss">
</style>