<template>
    <v-card class="mx-auto" tile flat>
        <v-card-text class="pa-4 pt-0 pa-md-10 pt-md-0">
            <div class="body-1 font-weight-bold mb-6">Acknowledgements</div>

            <ValidationObserver ref="observer" tag="form">

                <template v-for="(term, index) in terms">
                    <ValidationProvider
                        v-slot="{ errors }"
                        :name="term.attributes.content"
                        rules="required"
                        :vid="term.attributes.content">    

                        <v-checkbox
                            v-model="checkboxes"
                            :value="term.attributes.content"
                            color="secondary"
                        >
                            <template v-slot:label>
                                <div v-html="term.attributes.content"></div>
                            </template>
                        </v-checkbox>

                </ValidationProvider>
                </template>

                <!-- {{ checkboxes }} -->

            </ValidationObserver>

            <v-alert dense outlined type="error" v-if="errors.length > 0">
                <div v-for="msg in errors" v-html="msg"></div>
            </v-alert>

        </v-card-text>
    </v-card>
</template>

<script>

    import helpers from '~/plugins/helpers'
    import subscriptions from '~/components/shared/subscriptions'
    import { mapActions, mapGetters  } from 'vuex'
    export default {
        components: { },

        mixins: [subscriptions, helpers],

        data() {
            return {
                terms: [
                    {
                        attributes: {
                            content: "Agreement to Macquarie privacy policy for collection and usage of quote and application data.",
                        }
                    }
                ],
                checkboxes: [],
                errors: [],
            }
        },
        computed: {
            ...mapGetters('flows', [
                'getCalculation',
            ]),
            isCommercial(){
                return this.getCalculation['application-type'].includes("Commercial")
            },
            getLoanId(){
                return this.appData?.relationships?.loan?.data?.id
            }
        },
        methods: {
            ...mapActions("resource-actions", [
                "getLoan",
            ]),
            async pull() {

                let res = await this.getLoan(this.getLoanId);

                if (res.status == 200) {
                    if(res.data.data?.attributes?.['loan-type'] != 'Novated Lease' && this.isCommercial) {
                        this.terms.push({
                            attributes: {
                                content: "Agreement to Macquarie decision disclosure policy."
                            }
                        })
                    }
                }
            },
            async push() {

                // Validate Checkboxes

                this.checkCompleted()
      
                let valid = this.errors.length == 0

                let status = valid ?  'valid' : 'dirty'

                return {valid, status, data:{}}
                
            },
            checkCompleted(){

                this.errors = []

                if(this.checkboxes.length != this.terms.length) {
                    this.errors.push('Please accept to proceed')
                } 

            },
        },
        watch: {
            checkboxes(val) {
                if(val.length == this.terms.length) {
                    this.errors = []
                }
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>