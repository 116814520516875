<template>
    <FlexWrap @resize="handleResize">

        <div class="flex__sub-component-Radio2">
            
            <v-chip-group
                column 
                ref="selection"
                class="d-flex justify-center v-chip-group flex-wrap"
                v-model="selectedItem"
                @change="handleChange">

                <div 
                    v-for="(option, key) in options"
                    class="chip-wrap pa-2"
                    :style="`width: ${100 / options.length}%`"
                    :key="key"> 

                    <v-chip
                        :ripple="false"
                        :value="option.value"
                        @keyup.space="handleSpace(option.value, $event)"
                        active-class="secondary"
                        class="pa-0 ma-0 text-center">

                            <div v-if="bp.smAndDown" class="text-wrap px-2 text-center body-1">
                                {{ option.text }}
                            </div>
                            <div v-else class="text-wrap px-4 text-center chip-text-smAndUp">
                                {{ option.text }}
                            </div>

                    </v-chip>
                </div> 


            </v-chip-group>


            <div
            v-if="hasErrors" 
            class="error-messages">
                <span v-for="(err, ekey) in errorMessages" :key="ekey">
                    {{ err }}
                </span>
            </div>

        </div>
    </FlexWrap>
</template>

<script>
    import FlexWrap from '~/components/base/FlexWrap'

    export default {
        name: 'Radio2',
        components: {
            FlexWrap
        },
        model: {
            prop: 'modelValue',
            event: 'input'
        },
        props: {
            modelValue: {
                type: String,
                default: ''
            },
            options: {
                type: Array,
                default: () => ['Yes', 'No']
            },
            label: {
                type: String,
                default: ''
            },
            color: {
                type: String,
                default: 'secondary'
            },
            errorMessages: {
                type: Array,
                default: () => []
            },
            syncReset: {
                type: Boolean,
                default: false
            },
            noLabel: {
                type: Boolean,
                default: false
            },
            small: {
                type: Boolean,
                default: false
            }

        },

        computed: {
            hasErrors() {
                return this.errorMessages.length ? true : false
            },
            maxWidth() {

                if (!this.sizes) return

                if (this.sizes.width < 550) return '130px;'

                else if (this.sizes.width > 550 && this.sizes.width < 700) return '250px;'

                else return '370px;'
            },
            bp(){
                return this.$vuetify.breakpoint
            },
        },

        created () {
            this.selectedItem = this.modelValue
        },
        
        methods: {
            handleChange(value) {
                this.$emit('input', value)
            },
            handleSpace(value, $event) {
                if($event.keyCode == 32 ||$event.code == 'Space'){
                    this.$emit('input', value)
                }
            },
            handleResize(sizes){
                this.sizes = sizes
            },
        },

        watch: {
            'syncReset': {
                handler(val) {
                    if (val && this.modelValue.length < 1)
                        this.selectedItem = ''
                },
                deep: true
            },
            'modelValue': {
                handler(val) {
                    this.selectedItem = val
                },
                deep: true
            }
        },

        data() {
            return {
                selectedItem: '',
                sizes: null
            }
        }
    }
</script>


<style lang="scss" scoped>

    .flex__sub-component-Radio2 {

        .v-chip {
            background-color: white !important;
            border: 2px solid !important;
            border-color: var(--v-light_gray_6-base) !important;
            border-radius: 20px !important;
            height: 152px !important;
            display: block;

            &:focus {
            box-shadow: none !important;
            }

            .v-chip__content {
                background: transparent !important;
            }

            .chip-text-smAndUp {
                font-size: 1.3rem;
                line-height: 1.6rem;
            }

        }

        .v-chip:before, .v-chip:hover:before, .v-chip:focus:before {
            background: var(--v-secondary-base) !important;
        }

        .v-chip.v-chip--active{
            border: 2px solid;
            border-color: var(--v-secondary-base) !important;
            color: black;
        }

        .error-messages {
            border: none;
            color: var(--v-error-base) !important;
            caret-color: var(--v-error-base) !important;
            font-size: 12px;
        }

    } 
    

</style>