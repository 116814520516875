<template>
    <v-card class="mx-auto" tile flat>
        <v-card-text class="pa-4 pt-0 pa-md-10 pt-md-0 mx-2">
            <v-container pa-0 fluid>

                <ValidationObserver ref="observer" tag="form">
                    <v-row>                        
                        <v-col cols="12" md="6" class="mb-4 overflow-hidden">

                            <div class="body-2 font-weight-medium mt-3 mb-2">Submission notes</div>
                            <ValidationProvider
                                v-slot="{ errors }"
                                name="Submission Comment"
                                :rules="noteRules"
                                tag="div"
                                class="submission-text-wrap"
                                vid="submission-comments">

                                    <v-textarea
                                        v-model="resources.loan.attributes['submission-comments']"
                                        auto-grow
                                        placeholder="Regarding any information to help with the deal"
                                        rows="10"
                                        height="100%"
                                        row-height="16"
                                        class="ma-0 pa-0"
                                        :error-messages="errors"
                                    >
                                    </v-textarea> 

                            </ValidationProvider>

                        </v-col>

                        <v-col v-if="showBoxTile" cols="12" md="6" class="mb-4 overflow-hidden">

                            <div class="body-2 font-weight-medium mt-3 mb-2">Document upload </div>
                                
                            <div class="box-api-wrapper">
                                <BoxApi
                                    v-if="box"
                                    :application-id="appId"
                                    :box-data="box" />

                                <v-card v-else height="100%" flat class="py-12">
                                    <div class="text-center">
                                        <loaderImage class="mx-auto" />
                                    </div>
                                </v-card>
                            </div>

                        </v-col>

                    </v-row>

                </ValidationObserver>
            </v-container>
        </v-card-text>

        <!-- This will replace Next button in StepIncrementer1  -->
        <Portal to="step-incrementer-next">
            <v-btn 
                v-if="authUserCanSubmitApp"
                color="secondary" 
                large depressed nuxt 
                :min-width="btnWidth" 
                @click="submitApplication" 
                class="ml-3" 
                :disabled="processing">
                Submit
            </v-btn>
            <!-- Auth user not allowed to submit -->
            <div v-else></div>
        </Portal>

        <v-overlay class="ma-0 pa-0" :value="overlay" opacity=".2">
            <div class="spinner">      
                <div class="flex-brand-loader">
                    <div class="flex-brand d-block">
                        <loaderImage />
                    </div>
                </div>
            </div>
        </v-overlay>

        <!-- <v-dialog
            persistent
            scrollable
            max-width="500"
            class="pa-4"
            v-model="confirmDialog">

                <v-card :disabled="loading" flat>
                    <v-progress-linear color="secondary" indeterminate stream absolute top v-if="loading"></v-progress-linear>

                    <v-card-title class="px-8 pt-8">
                        <div class="v-card__title sm pa-0">
                        </div>
                    </v-card-title>

                    <v-card-text class="px-8 pt-2 pb-6 body-1" v-html="confirmDialogTitle"></v-card-text>

                    <v-card-actions class="pa-4 ma-2">
                        <v-btn @click="confirmDialog = false"  class="flex-back-button" outlined large depressed>
                            Back
                        </v-btn>
                        <v-spacer></v-spacer>
                        <v-btn v-if="!isSameLender" @click="lenderSubmit(getSelectedLender)" class="warning"  large depressed >
                            Next
                        </v-btn>
                    </v-card-actions>

                </v-card>

        </v-dialog> -->

    </v-card>
</template>

<script>

    import BoxApi from '~/components/base/BoxApi'
    import loaderImage from '~/components/base/loaderImage'
    import subscriptions from '~/components/shared/subscriptions'
    import { mapState, mapGetters, mapActions, mapMutations } from 'vuex'

    export default {
        components: {
            BoxApi,
            loaderImage,
        },
        mixins: [subscriptions],

        data(){
            return {
                isSameLender: false,
                confirmDialogTitle: '',
                confirmDialog: false,
                lenderSubmissions: [],

                overlay: false,
                validComponentData: true,

                resources: {
                    loan: {
                        type: "loan",
                        id: null,
                        attributes: {
                            "submission-comments": "",
                        },
                        relationships: {
                            application: {
                                data: {type: "applications", id: null}
                            }
                        }
                    }
                },
                box: null
            }
        },

        computed: {
            ...mapState({
                appId: state => state.flows.appId,
                appGuid: state => state.flows.appGuid,
                loanData: state => state.flows.loanData
            }),
            ...mapState('slug', [
                'flowSlug'
            ]),
            ...mapState('statuses', [
                'stepStatuses'
            ]),
            ...mapGetters('kinds', [
                'kindsOfApplicant'
            ]),
            ...mapGetters('statuses', [
                'getCurrentFlowStatus'
            ]),
            ...mapGetters('flows', [
                'redirectPaths'
            ]),
            ...mapState(['appConfig']),
            showBoxTile(){
                return this.appConfig.myAppsTileMenu.files
            },
            getLoanType(){
                 return this.loanData?.attributes['loan-type']
            },
            getSelectedLender(){
                return this.loanData?.attributes['selected-lender-quote-algorithm-slug']
            },

            processing(){
                return  this.isDisabled
            },
            btnWidth () {
                switch (this.$vuetify.breakpoint.name) {
                    case 'xs': return 0
                    case 'lg': 
                    case 'xl':
                    case 'md': 
                    case 'sm': return 140
                }
            },
            getLoanId(){
                return this.appData?.relationships?.loan?.data?.id
            },
            activeLenderSubmissions() {
                let { lenderSubmissions } = this
                let active = null

                if( lenderSubmissions.length > 0 ) {
                    lenderSubmissions.forEach(lenderSub => {
                        if( lenderSub.attributes.active ) {
                            active = lenderSub
                        }
                    })
                }

                return active
            },
            noteRules() {

                if(this.flowSlug === 'lender-submission-pepper') {
                    return 'required|max:1000|min:25'
                }

                if(this.flowSlug === 'lender-submission-wisr') {
                    return 'max:512|min:25'
                }

                return 'max:1000|min:25'
            }       
        },
        mounted(){

        },
        methods: {
            ...mapActions('resource-actions', [
                'getLoan',
                'createBox',
                'updateLoan',
                'getApplicationBox',
                'getPeopleByResource',
                'getLender',
                'runLenderSubmitService'
            ]),
            ...mapActions('statuses', [
                'completeFlowStatus'
            ]),
            ...mapActions('flows', [
                'selectFinalStep',
                'selectNextStep',
                'selectFinalStepNoFlowComplete'
            ]),
            ...mapMutations('utils', [
                'toggleAlert'
            ]),

            async pull() {

                let requests = [
                    this.getLoan(this.getLoanId),
                    this.getApplicationBox(this.appId)
                ]
                
                // get lender submissions
                let lenderSub = await this.getPeopleByResource({resource: 'lender-submissions'})

                if(lenderSub.status === 200) this.lenderSubmissions = lenderSub.data.data

                Promise.all(requests).then( data => {

                    let loan = data.find( dat => {
                        return dat.data.data.type === 'loans'
                    })

                    this.resources.loan = this.resourceFieldPicker(this.resources.loan, loan.data.data)

                    let box = data.find( dat => {
                        return dat.data.data.type === 'boxes'
                    })

                    this.box = box?.data?.data || null

                    return data

                }).catch( async error => {
                    
                    if ( !this.box ) {
                        
                        let res = await this.createBox({
                            type: "boxes",
                            attributes: {
                                folder_id: "new"
                            },
                            relationships: {
                                application: {
                                    data: {
                                        type: "applications",
                                        id: this.appId
                                    }
                                }
                            }
                        })

                        if ( res.status === 201 ) {
                            this.box = res.data.data
                        }
                    }
                    return error
                })

            },
            async push() {
                
                const {valid, status} = await this.validateFormData()

                return {valid, status}
                
            },

            async submitApplication() {

                const { valid } = await this.validateFormData()

                if(valid) {

                    const observer = this.$refs.observer
                    const {loan} = this.resources

                    // Exclude relationships and other stuff
                    let loanPayload = {
                        type: loan.type,
                        id: loan.id,
                        attributes: this.excludeInvalids(loan.attributes, observer.fields)
                    }

                    await this.updateLoan(loanPayload) 
                    await this.submitLender()
                }
                
            },

            async submitLender() {

                try {

                    this.loading = true

                    await this.lenderSubmit()

                    this.loading = false

                } catch (err) {
                    
                    this.loading = false

                }
            },

            async lenderSubmit() {

                try {

                    let res = await this.runLenderSubmitService({
                        "application": this.appGuid, 
                    })

                    if(res.status === 200) {
                        
                        if(res.data.message == 'Lender Submission Failed') {
                            await this.selectFinalStepNoFlowComplete()
                        } else {
                            await this.selectFinalStep({flowStatus: this.getCurrentFlowStatus.id})
                        }

                    }

                } catch (err) {

                    this.loading = false

                }

            },

            wordCount(field = '') {
                return this.resources?.loan?.attributes?.[field]?.length <= 250 ? 250 : false
            }
        },
        watch: {

        }
    
    }
</script>

<style lang="scss">

    .flex__component-AppSubmitter3 {

        .dashed-border {
            border: 2px dashed var(--v-light_gray_1-base) !important;
            overflow: hidden;
            // border-radius: 20px;
        }


        .submission-text-wrap, 
        .submission-text-wrap > .v-input,
        .submission-text-wrap > .v-input > .v-input__control
        {
            height: 100%;
        }
        
        .submission-text-wrap > .v-input > .v-input__control  .v-input__slot {
            align-items: flex-start;
            flex-basis: 564px;
        }

        .box-api-wrapper {
            height: 100%;
            background: var(--v-light_gray_1-base);

            & > .container.container  {
                padding: 0 !important;
            }
        }

    }

</style>

