<template>
    <div class="pt-2 flex__button-group-1">

        <span :class="['button-selection-label subtitle-1', {'error--text': hasErrors}]">
            {{ label }}
        </span>

        <v-chip-group
            ref="selection"
            class="button-selections"
            column
            v-model="selectedItem"
            @change="handleChange"
        >
            <v-chip
                v-for="(option, key) in options"
                :key="key"
                class="mb-2"
                :class="[['py-0 my-0 justify-center'], {'px-8': large}]"
                :value="option"
                :large="large"
                @keyup.space="handleSpace(option, $event)"
                active-class="secondary"
                color="light_gray_1">
                <div class="px-1 px-sm-6 py-1">
                    {{ option }}
                </div>
            </v-chip>

            <!-- default value of selected since there is no selected when render -->
            <v-chip
                class="d-none"
                value="default"
            >
            </v-chip>

        </v-chip-group>
        <div
            v-if="hasErrors" 
            class="error-messages">
            <span 
                v-for="(err, ekey) in errorMessages"
                :key="ekey">
                {{ err }}
            </span>
        </div>
    </div>
</template>

<script>
    export default {
        model: {
            prop: 'modelValue',
            event: 'input'
        },
        props: {
            modelValue: {
                type: String,
                default: 'default'
            },
            options: {
                type: Array,
                default: () => ['Yes', 'No']
            },
            label: {
                type: String,
                default: 'Select Type'
            },
            color: {
                type: String,
                default: 'secondary'
            },
            errorMessages: {
                type: Array,
                default: () => []
            },
            large: {
                type: Boolean,
                default: false,
            },
        },

        computed: {
            hasErrors() {
                return this.errorMessages.length ? true : false
            }
        },
        created() {
            if(this.modelValue)
                this.selectedItem = this.modelValue
        },
        methods: {
            handleChange(value) {
                this.$emit('input', value)
            },
            handleSpace(value, $event) {
                if($event.keyCode == 32 ||$event.code == 'Space'){
                    this.$emit('input', value)
                }
            }
        },
        watch: {
            modelValue(val){
                if(val)
                    this.selectedItem = val
            }
        },
        data() {
            return {
                selectedItem: 'default'
            }
        }
    }
</script>


<style lang="scss" scoped>

    .flex__button-group-1 {

        .button-selection-label {
            font-size: 0.9rem !important;
            font-weight: 600 !important;
            color: var(--v-body_text_color-base); 
            transform: translateY(-32px) scale(1);
        }

        .v-chip {
            width: 140px;
            border-radius: 20px;
            height: 40px;
            font-size: 1rem !important;
            font-weight: 600 !important;

            &.v-chip--active {
                background: var(--v-secondary-base) !important;
            }

            &.v-size--large {
                border-radius: 27px;
                font-size: 16px !important;
                height: 54px;
            }

        }

    }

    .error-messages {
        border: none;
        color: var(--v-error-base) !important;
        caret-color: var(--v-error-base) !important;
        font-size: 12px;
    }

</style>