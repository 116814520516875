<template>
    <v-dialog
        v-model="dialog"
        persistent
        width="500">

        <v-card>

            <v-card-title class="px-8 pt-8">
                 <div class="v-card__title sm pa-0">
                    Third-Party Guarantor
                </div>
            </v-card-title>

            <v-card-text class="px-8 pt-2 pb-8 body-1">
                <p>
                    By clicking next you will be adding a Third-Party Guarantor who is <strong><u>NOT</u></strong> a Director, or any other entity/person who is playing a role underneath this parent entity.
                </p>
                <p>
                    If you are trying to add a Director under a Company, please use the <strong>Add Role</strong> tool/button.
                </p>
            </v-card-text>

            <v-card-actions class="px-8 pb-8">
                <v-spacer></v-spacer>

                <v-btn
                    color="light_gray_2"
                    outlined
                    large depressed
                    @click="back">
                    Back
                </v-btn>

                <v-btn
                    color="secondary"
                    large depressed
                    @click="next">
                    Next
                </v-btn>
            </v-card-actions>

        </v-card>

    </v-dialog>
</template>

<script>
export default {
    name: 'WarningDialog',

    props: {
        modal: {
            type: Boolean,
            default: false
        },
    },
    
    data() {
        return {
            dialog: false
        }
    },

    mounted() {
        this.dialog = this.modal
    },

    methods: {
        next() {
            this.$emit('next-step')
        },
        back() {
            this.$emit('back-step')
        }
    },

    watch: {
        modal(val) {
            this.dialog = val
        }
    }
}
</script>
