import Vue from 'vue'
import moment from 'moment'
import VueCurrencyFilter from 'vue-currency-filter' 

Vue.use(VueCurrencyFilter, [
    {
      symbol : '$',
      thousandsSeparator: ',',
      fractionCount: 2,
      fractionSeparator: '.',
      symbolPosition: 'front',
      symbolSpacing: true,
      avoidEmptyDecimals: undefined,
    },
    {
        name: 'currencyNoSymbol',
        symbol : '',
        thousandsSeparator: ',',
        fractionCount: 2,
        fractionSeparator: '.',
        symbolPosition: 'front',
        symbolSpacing: false,
        avoidEmptyDecimals: undefined,
    },
    {
        name: 'currencyPlainDigit',
        symbol : '',
        thousandsSeparator: '',
        fractionCount: 2,
        fractionSeparator: '.',
        symbolPosition: 'front',
        symbolSpacing: false,
        avoidEmptyDecimals: undefined,
    },
    {
        name: 'percentage',
        symbol: '%',
        thousandsSeparator: ' ',
        fractionCount: 2,
        fractionSeparator: '.',
        symbolPosition: 'back',
        symbolSpacing: false,
        avoidEmptyDecimals: undefined,
    },
    {
        name: 'currencyNoDecimal',
        symbol: '$',
        thousandsSeparator: ',',
        fractionCount: 0,
        fractionSeparator: '.',
        symbolPosition: 'front',
        symbolSpacing: false,
        avoidEmptyDecimals: undefined,
    }
])

Vue.filter('uppercase', val => {

    if(val) {
        return val.toUpperCase()
    }

    return ''
})

Vue.filter('capitalize', string => {

    if(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    return ''
})

Vue.filter('formalAndCapitalize', value => {
    if (!value) return ''
    var splitStr = value.toLowerCase().split(' ');
    for (var i = 0; i < splitStr.length; i++) {
        // You do not need to check if i is larger than splitStr length, as your for does that for you
        // Assign it back to the array
        splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);     
    }
    // Directly return the joined string
    return splitStr.join(' '); 
})

Vue.filter('withDecimalPlacesOf', (value, arg) => {
    let re = new RegExp('^-?\\d+(?:\.\\d{0,' + (arg || -1) + '})?')
    let temp = ''
    for (let x = 0; x < arg; x++) {
        temp += '0'
    }
    
    if (value % 1 === 0)
        return value + '.' + temp
    else if (value === 0 || value === '0' || value === null)
        return value + '.' + temp
    else {
        return value ? value?.toString().match(re)[0] : value
    }
})

Vue.filter('formalText', (value) => {
    if (!value) return ''
    value += ''
    value = value.toLowerCase()
    return value.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join('_').replace(/_/g, ' ')
})

// Backend required date format 
Vue.filter('dateFormat', val => {
    return val ? moment(val).format('YYYY-MM-DD') : ''
})

// Presentation format Date
Vue.filter('dateFormatFE', val => {
    return val ? moment(val).format('DD-MM-YYYY') : ''
})

Vue.filter('dateFormatFE_2', val => {
    return val ? moment(val).format('DD / MM / YY') : null
})

Vue.filter('dateFormatFE_3', val => {
    return val ? moment(val).format('DD MMM YYYY') : null
})

Vue.filter('toAppDataFormat', payload => {

    const relationships = {}

    for (let i = 0; i < payload.length; i++) {

        const response = payload[i];

        if(response == null) continue;

        const data = response.data.data
        const method = response.config.method

        let name = '';

        // Convert resource type to resource name
        switch (data.type) {
            case 'loans':
                name = 'loan';
                break;
            case 'boxes':
                name = 'box';
                break;
            case 'group':
                name = 'groups';
                break;
            default:
                name = data.type;
                break;
        }

        relationships[name] = {
            "id": data.id,
            "type": data.type,
            "method": method,
        }

    }

    return relationships
})

Vue.filter('toSingularResource', payload => {

        switch (payload) {
            case 'incomes':
            return 'income'
            case 'assets':
            return 'asset'
            case 'liabilities':
            return 'liability'
            case 'expenses':
            return 'expense'
        }

        return ''
})


// Add / Subtract Dates

Vue.filter('computeDateBy', payload => {

    let options = {
        date: null,
        operation: 'add', // subtract
        type: 'days', // months, years
        count: 1, 
        format: "YYYY-MM-DD",
    }

    if(typeof payload == 'object'){

        options = {
            ...options,
            ...payload
        }
    }

    if(options.operation == 'subtract'){
        return moment(options.date, options.format).subtract(options.count, options.type).format(options.format)
    } else {
        return moment(options.date, options.format).add(options.count, options.type).format(options.format)
    }

})

//  Compare
Vue.filter('compareDateBy', payload => {

    let options = {
        date: null,
        comparee: null,
        type: 'before', // after
    }

    if(typeof payload == 'object'){

        options = {
            ...options,
            ...payload
        }
    }

    if(options.type == 'before'){
        return moment(options.comparee).isBefore(options.date)
    } else {
        return moment(options.comparee).isAfter(options.date)
    }

})


//  Compare
Vue.filter('hexToRGBA', (hex, alpha = 1) => {

    let result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);

    return result ? `rgba(${parseInt(result[1], 16)}, ${parseInt(result[2], 16)}, ${parseInt(result[3], 16)}, ${alpha})` : "";

})
Vue.filter('isEmpty', (value) => {
    return value || '-'
})



