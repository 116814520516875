<template>
    <v-card flat tile class="mx-auto pb-4" :class="vb.mdAndUp ? 'px-8' : 'px-2'">

        <div class="px-4 pb-4 d-flex">
            <div class="title" >
                Please list your incomes and employment history
            </div>
        </div>

        <v-card v-if="error" flat tile class="rounded-xl warning lighten-5 pa-2">
            <v-card-actions :class="{'d-flex flex-column': vb.smAndDown}">
                <v-icon color="warning" size="50">mdi-information</v-icon>
                <div class="px-4 title" :class="{'text-center pt-2': vb.smAndDown}">
                    <div>
                        Record not found please check the URL.
                    </div>
                    <div class="title">Or please try hard-refreshing your browser. If you’re not sure how to do this, see <a href="https://www.howtogeek.com/672607/how-to-hard-refresh-your-web-browser-to-bypass-your-cache" target="blank">here</a></div>
                    <div class="title">If a hard-refresh does not fix the issues you’re experiencing, please reach out to your consultant or relationship manager.</div>
                </div>
                <v-spacer />
            </v-card-actions>
        </v-card>

        <v-card-text v-if="tableData && tableData.length > 0" class="pt-0" :class="vb.mdAndUp ? 'px-12' : ''">
            <v-data-table
                loading-text="Please wait..."
                :headers="headers"
                :items="tableData"
                hide-default-footer
                :mobile-breakpoint="0"
                hide-default-header
                outlined
                class="v-data-table-2 mx-auto">

                <!-- Body -->
                <template v-slot:item="{ item }">
                    <!-- md and Up -->
                    <v-card v-if="vb.mdAndUp" outlined class="ma-2 mx-auto rounded-xl" :class="vb.mdAndUp ? 'pa-2' : ''">

                        <!-- # md and up design -->
                        <div class="align-center py-2 px-4 flex-table-row">
                            <v-row>
                                <v-col cols="2" class="pa-0 ma-0 d-flex align-center">
                                    <div
                                        class="rounded-xl pa-2 text-center body-1 mx-3"
                                        :style="`background-color: ${ item['situation'] == 'Current' ?  hexToRGBA(getActiveTheme.colors.accent, 0.1) : 'var(--v-light_gray_3-base)'}; min-width: 100px;`">
                                        {{ item['situation'] }}
                                    </div>
                                </v-col>
                                <v-col cols="2" class="pa-0 ma-0 d-flex align-center">
                                    <div class="pl-4 font-size-16">
                                        {{ item['income-type'] }}
                                    </div>
                                </v-col>
                                <v-col cols="3" class="pa-0 ma-0 d-flex align-center">
                                    <div class="d-flex flex-column justify-start pt-1">
                                        <p class="td-inner ma-0 font-size-16">{{ item['monthly-payment'] ? item['monthly-payment'] : 0 | currency }} per month</p>
                                    </div>
                                </v-col>
                                <v-col cols="3" class="pa-0 ma-0 d-flex align-center">
                                    <div class="d-flex flex-column justify-start pt-1">
                                        <p class="td-inner ma-0 font-size-16">{{ item['date'] }}</p>
                                    </div>
                                </v-col>
                                <v-col cols="2" class="pa-0 ma-0 pr-4 pt-n2 d-flex align-center">
                                    <v-spacer />
                                    <div class="d-flex justify-end">
                                        <div class="inner end-mobile d-flex">
                                            <v-btn
                                                fab                    
                                                depressed
                                                max-width="40"
                                                max-height="40"
                                                @click="editIncome(item.income)"
                                                :style="`background-color: ${hexToRGBA(getActiveTheme.colors.light_gray_3, 1)}`"
                                                class="mx-2">
                                                <v-icon size="18">
                                                    mdi-pencil
                                                </v-icon>
                                            </v-btn>
                                            <ConfirmModal 
                                                async
                                                :title="`Delete ${capitalize(toSingular(item.income.type))}`"
                                                :processing="deleting"
                                                :message="buildDeleteMsg(item.income)"
                                                class="mx-2"
                                                @confirm="handleConfirm(item.income)">
                                                <template v-slot:activator="{on}">
                                                    <v-btn
                                                        depressed
                                                        max-width="40"
                                                        max-height="40"
                                                        fab :ripple="false"
                                                        :style="`background-color: ${hexToRGBA(getActiveTheme.colors.light_gray_3, 1)}`"
                                                        @click.stop="on"> 
                                                        <v-icon size="18">mdi-delete</v-icon>
                                                    </v-btn>
                                                </template>
                                            </ConfirmModal>
                                        </div>
                                    </div>
                                </v-col>
                            </v-row>
                        </div>

                    </v-card>
                    
                    <!-- xs -->
                    <!-- <v-card  v-else outlined class="card-row ma-2 pa-3 rounded-xl">

                        <div class="d-flex justify-space-between align-center">

                            <div class="d-flex flex-column justify-start ml-2">
                                <p class="td-inner ma-0">{{ item['income-type'] }}</p>
                            </div>

                            <div class="d-flex justify-center">
                                <div class="inner end-mobile d-flex">
                                    <div
                                        class="rounded-xl pa-2 text-center mx-2 body-1"
                                        :style="`background-color: ${ item['situation'] == 'Current' ?  hexToRGBA(getActiveTheme.colors.accent, 0.1) : 'var(--v-light_gray_3-base)'}; min-width: 90px;`">
                                        {{ item['situation'] }}
                                    </div>
                                    <v-btn
                                        fab                    
                                        depressed
                                        max-width="40"
                                        max-height="40"
                                        @click="editIncome(item.income)"
                                        :style="`background-color: ${hexToRGBA(getActiveTheme.colors.light_gray_3, 1)}`"
                                        class="mx-2">
                                        <v-icon size="18">
                                            mdi-pencil
                                        </v-icon>
                                    </v-btn>
                                    <ConfirmModal 
                                        async
                                        :title="`Delete ${capitalize(toSingular(item.income.type))}`"
                                        :processing="deleting"
                                        :message="buildDeleteMsg(item.income)"
                                        class="mx-2"
                                        @confirm="handleConfirm(item.income)">
                                        <template v-slot:activator="{on}">
                                            <v-btn 
                                                fab
                                                depressed
                                                max-width="40"
                                                max-height="40"
                                                :style="`background-color: ${hexToRGBA(getActiveTheme.colors.light_gray_3, 1)}`"
                                                :ripple="false"
                                                @click.stop="on"> 
                                                <v-icon size="18">mdi-delete</v-icon>
                                            </v-btn>
                                        </template>
                                    </ConfirmModal>
                                </div>
                            </div>
                        </div>

                    </v-card> -->

                    <v-card v-else flat tile outlined class="rounded-lg mt-4 pa-2">

                        <v-card-text class="d-flexflex-column">
                            <div  class="body-2 font-weight-medium cursor-pointer" >
                                {{ item['income-type'] }}
                            </div>
                            <div  class="body-2 cursor-pointer" >
                                {{ item['monthly-payment'] ? item['monthly-payment'] : 0 | currency }} per month
                            </div>
                            <div  class="body-2 cursor-pointer" >
                                {{ item['date'] }}
                            </div>
                        </v-card-text>

                        <v-card-actions>
                            <v-spacer />

                        <div
                            class="rounded-xl pa-2 text-center mx-2 body-1"
                            :style="`background-color: ${ item['situation'] == 'Current' ?  hexToRGBA(getActiveTheme.colors.accent, 0.1) : 'var(--v-light_gray_3-base)'}; min-width: 90px;`">
                            {{ item['situation'] }}
                        </div>

                       <v-btn
                            fab                    
                            depressed
                            max-width="40"
                            max-height="40"
                            @click="editIncome(item.income)"
                            :style="`background-color: ${hexToRGBA(getActiveTheme.colors.light_gray_3, 1)}`"
                            class="mx-2">
                            <v-icon size="18">
                                mdi-pencil
                            </v-icon>
                        </v-btn>
                        <ConfirmModal 
                            async
                            :title="`Delete ${capitalize(toSingular(item.income.type))}`"
                            :processing="deleting"
                            :message="buildDeleteMsg(item.income)"
                            class="mx-2"
                            @confirm="handleConfirm(item.income)">
                            <template v-slot:activator="{on}">
                                <v-btn 
                                    fab
                                    depressed
                                    max-width="40"
                                    max-height="40"
                                    :style="`background-color: ${hexToRGBA(getActiveTheme.colors.light_gray_3, 1)}`"
                                    :ripple="false"
                                    @click.stop="on"> 
                                    <v-icon size="18">mdi-delete</v-icon>
                                </v-btn>
                            </template>
                        </ConfirmModal>

                        </v-card-actions>

                    </v-card>
                </template>

            </v-data-table>

            <div style="max-width: 1228px; margin: auto" class="px-2">
                <v-row>
                    <v-col cols="12" md="4">
                        <!-- Spacer -->
                    </v-col>
                    <v-col cols="12" md="4">
                        <div :style="`background-color: ${hexToRGBA(getActiveTheme.colors.accent, 0.1)}; max-width: 260px; margin: auto`" class="pa-4 rounded-xl text-center">
                            <div class="body-1">Your total income is</div>
                            <h2 class="font-size-26 font-weight-medium">{{ getTotalMonthlyIncome | currency }}</h2>
                        </div>
                    </v-col>
                    <v-col cols="12" md="4 text-center text-md-right">
                        <v-btn depressed large class="secondary" @click="addIncome" :min-width="vb.mdAndUp ? 190 : 100">
                            Add Income
                        </v-btn>
                    </v-col>
                </v-row>
            </div>

            <template v-if="isPreviousRequired">
                <div class="" :class="vb.mdAndUp ? 'title' : 'title-2 font-weight-bold text-center'">
                    Previous Employment History
                </div>
                <v-card flat :disabled="error" tile class="rounded-xl pa-1 px-4 mt-4" style="background-color: #EEF0FB">
                    <v-card-actions :class="{'d-flex flex-column': vb.smAndDown}">
                        <v-avatar color="light_gray_1" tile class="rounded-lg" height="70" width="70">
                            <img src="~/assets/images/jane.png" />
                        </v-avatar>
                        <div class="px-5 title-3">
                            <div :class="{'text-center pt-2': vb.smAndDown}">Since we require three years of employment history, please add your previous employment if you had a gap in employment, or no previous employment, please select the appropriate option.</div>
                        </div>
                        <v-spacer />
                        <v-btn depressed large class="secondary" @click="addHistory" :min-width="190">
                            Add History
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </template>

        </v-card-text>

        <v-card v-else :disabled="error" outlined class="rounded-lg pa-1 px-4 mt-4 py-5 mx-4" style="border-color: var(--v-secondary-base);">
            <v-card-actions :class="{'d-flex flex-column': vb.smAndDown}">
                <div class="px-2 title-3">
                    <div :class="{'text-center pt-2': vb.smAndDown}">Please input all incomes sources.</div>
                    <div :class="{'text-center pb-2': vb.smAndDown}">
                        If you are not currently receiving any income, please click 
                        <span style="text-decoration: underline; cursor: pointer;" @click="showNoIncome">HERE</span>.
                    </div>
                </div>
                <v-spacer />
                <v-btn depressed large class="secondary mr-5" @click="addIncome" :min-width="152">
                    Add Income
                </v-btn>
            </v-card-actions>
        </v-card>

        <v-alert v-if="errorMessage.length > 0" dense outlined type="error" class="mt-4">
            <div v-for="(msg, index) in errorMessage" :key="index" v-html="msg.message"></div>
        </v-alert>
       
        <EmploymentHistoryStepper
            v-if="dialog"
            :dialog="dialog"
            :fields-data="fieldsData"
            @save-income="saveIncome"
            @update-income="updateIncome"
            @close-modal="closeDialog" />

    </v-card>
</template>
<script>
import EmploymentHistoryStepper from '~/components/v1/EmploymentHistoryEditor4/_EmploymentHistoryModal'
import { mapActions, mapGetters } from 'vuex'

import shared from '~/components/v1/EmploymentHistoryEditor4/__shared'
import subscriptions from '~/components/shared/subscriptions'
import {store, actions} from '~/components/v1/EmploymentHistoryEditor4/__store'
import ConfirmModal from '~/components/base/ConfirmModal2'

export default {
    name: 'EmploymentHistoryEditor4',

    mixins: [shared, subscriptions],

    components: {
        EmploymentHistoryStepper,
        ConfirmModal
    },

    computed: {
        ...mapGetters('themes', [
            'getActiveTheme'
        ]),
        vb() {
            return this.$vuetify.breakpoint
        },
        getLoanId(){
            return this.appData?.relationships?.loan?.data?.id
        }, 
        routeParams(){
            return this.$route.params
        },
        entityData(){
            let entities = []

            this.entities.forEach(element => {
                entities.push({
                    id: element.id,
                    entity: element,
                    name: this.getEntityFriendlyName(element),
                    role: this.getFriendlyRoles(element, this.entityParts)
                })
            })

            return entities
        },
        hasCurrentIncome(){

            let hasCurrent = false

            let incomes = this.incomes.filter(income => {
                let entities = income.relationships[this.selectedEntity.type].data.filter(e => e.id == this.selectedEntity.id)
                return entities.length > 0
            })

            incomes.forEach(record => {
                if(record.attributes['income-situation'] == 'Current'){
                    hasCurrent = true
                }
            })

            return hasCurrent
        },
        getTotalMonthlyIncome(){

            let totalIncomes = 0
            let relevantIncomes = []

            let incomes = this.incomes.filter(income => {
                let entities = income.relationships[this.selectedEntity.type].data.filter(e => e.id == this.selectedEntity.id)
                return entities.length > 0
            })

            let situations = ['Current', 'Secondary']

            incomes.forEach(record => {
                if(situations.includes(record.attributes['income-situation'])){
                    relevantIncomes.push(record)
                }
            })

            relevantIncomes.forEach(record => {
                    totalIncomes = (totalIncomes + parseFloat(record.attributes['net-monthly-amount']))
            })

            return totalIncomes
        },
        isPreviousRequired() {

            let isError = false

            if(this.errorMessage.length > 0) {
                this.errorMessage.forEach(err => {
                    if(err.message == 'Applicant must have <strong>3 Years or More</strong> income history.') {
                        isError = true
                    }
                })
            }

            return isError

        }
    },

    data() {
        return {
            deleting: false,
            error: false,
            dialog: false,
            tableData: null,
            errorMessage: [],
            fieldsData: {},
            headers: [
                { text: 'Income Type', sortable: false, value: 'income-type', width: '200px'},
                { text: 'Monthly Payment', sortable: false, value: 'monthly-payment', width: '200px'},
                { text: 'Date', sortable: false, value: 'date', width: '20%'},
                { text: 'Situation', sortable: false, value: 'situation', width: '20%'},
                { text: '', sortable: false, value: 'action'}
            ],
        }
    },
    methods: {
        ...mapActions('resource-actions', [
            'getPeople',
            'getBusinesses',
            'getAddresses',
            'getLoan',
            'deleteIncome'
        ]),
        async pull() {
            
            actions.resetAll()

            let promise1 = this.getPeople({"include" : 'parent-parts,incomes,occupancies,expenses,liabilities,assets'})
            
            let promise2 = this.getAddresses()

            let promise3 = this.getLoan(this.getLoanId)

            let promise4 = this.getBusinesses({"include" : 'parent-parts,incomes,expenses,liabilities,assets'})

            const res = await Promise.all([promise1, promise2, promise3, promise4]) 

            let people = this.getRequiredPeopleByRoles(res[0].data, res[3].data)
            let businesses = this.getRequiredBusinessesByRoles(res[3].data)

            let parts = this.getRequiredParts([res[0].data, res[3].data])

            let requiredResources = this.getRequiredResources([res[3].data, res[0].data])

            let addresses = this.getAllAddresses(res[1].data)
            let occupancies = this.getAllOccupancies(res[0].data)

            let loan = res[2]

            if(loan.data?.data?.attributes){

                let loanType = (loan.data.data.attributes["finance-for-business-purposes"] == 'Yes') ? 'Commercial' : 'Consumer';

                let appType = this.getKindName(loan.data.data.relationships.kind.data.id)
                
                actions.setLoanType(loanType)
                actions.setAppType(appType)
            }

            actions.setPeople(people)
            actions.setBusinesses(businesses)
            actions.setResources(requiredResources)
            // actions.setAddresses(addresses)
            actions.setAllAddresses(addresses)
            // set all Occupancies
            actions.setAllOccupancies(occupancies)

            let entities = [
                ...businesses,
                ...people
            ]

            actions.setEntities(entities)
            actions.setEntityParts(parts)

            if(this.routeParams?.record_id) {

                let entity = this.entities.filter(entity => entity.id == this.routeParams.record_id && entity.type == this.routeParams.record)
                
                if(entity.length > 0) {
                    // if entity type dont match with entities on the application
                    if(entity[0].type != this.routeParams.record) {
                        this.error = true
                    } else {
                        actions.setSelectedEntity(entity[0])
                        this.filterIncomesData(entity[0])
                        // this.errorMessage = this.validateIncomeRecords(entity[0], this.incomes)
                    }
                } else {
                    this.error = true
                }


            }

        },
        async push() {

            let valid = true

            if(this.selectedEntity) {
                this.errorMessage = this.validateIncomeRecords(this.selectedEntity, this.incomes)
            }

            if(this.errorMessage.length > 0) valid = false

            return {valid: valid, status: valid ? 'valid' : 'dirty', data:{}}

        },

        saveIncome(data) {

            this.tableData.push({
                "income": data.income,
                "income-type": this.getKindName(data.income?.relationships?.kind?.data?.id),
                "monthly-payment": data.income.attributes['net-monthly-amount'],
                "date": this.convertDateDiff(data.income.attributes['start-date'], data.income.attributes['end-date']),
                "situation": data.income.attributes['income-situation']
            })

            this.errorMessage = this.validateIncomeRecords(this.selectedEntity, this.incomes)

        },

        updateIncome(income) {
            const data = {
                "income": income,
                "income-type": this.getKindName(income?.relationships?.kind?.data?.id),
                "monthly-payment": income.attributes['net-monthly-amount'],
                "date": this.convertDateDiff(income.attributes['start-date'], income.attributes['end-date']),
                "situation": income.attributes['income-situation']
            }

            this.tableData.splice(this.tableData.findIndex(table => table.income.id == data.income.id), 1, data)
            this.errorMessage = this.validateIncomeRecords(this.selectedEntity, this.incomes)
        },
        async handleConfirm(data) {

            this.deleting = true

            let res = await this.deleteIncome(data.id)

            if(res.status === 204) {

                // Update local states
                actions.deleteResource(data)
                
                await this.updateEntitiesAndParts()

                this.tableData = this.tableData.filter(table => table.income.id != data.id)
                this.errorMessage = this.validateIncomeRecords(this.selectedEntity, this.incomes)

            }

            this.deleting = false
        },
        editIncome(data) {
            actions.setStandalone(true)

            if(data.relationships.kind.data.id == '12-23') {
                actions.setNoIncome(true)
            }

            actions.setIncomeId(data.relationships.kind.data.id)
            actions.setPreselectIncomeSituation(data.attributes['income-situation'])
            this.fieldsData = data

            // logic for Do you receive additional entitlements or allowances, including overtime? field
            let isShow = false

            if(this.fieldsData.attributes['overtime-pay-monthly-net'] != 0 || this.fieldsData.attributes['commission-monthly-net'] != 0 || this.fieldsData.attributes['bonus-monthly-net'] != 0 || this.fieldsData.attributes['work-allowance-net-monthly'] != 0) {
                isShow = true
            }

            this.fieldsData.attributes['is-allowances'] = isShow ? 'Yes' : 'No'

            this.dialog = true
        },
        filterIncomesData(role) {
            
            // filter incomes by selected role or entities
            let incomes = this.incomes.filter(inc => inc.relationships.businesses.data.some(element => element.id == role.id) || inc.relationships.people.data.some(element => element.id == role.id))
            let data = []
            incomes.forEach(element => {
                data.push({
                    "income": element,
                    "income-type": this.getKindName(element?.relationships?.kind?.data?.id),
                    "monthly-payment": element.attributes['net-monthly-amount'],
                    "date": this.convertDateDiff(element.attributes['start-date'], element.attributes['end-date']),
                    "situation": element.attributes['income-situation']
                })
            })

            this.tableData = data

        },
        closeDialog() {
            this.dialog = false
            this.fieldsData = {}
            actions.setNoIncome(false)
        },
        showNoIncome() {
            actions.setNoIncome(true)
            this.dialog = true
        },
        addIncome() {

            if(this.hasCurrentIncome) {
                actions.setPreselectIncomeSituation('Secondary')
            } else {
                actions.setPreselectIncomeSituation('Current')
            }

            this.dialog = true
        },
        async addHistory() {
            await actions.setPreselectIncomeSituation('Previous')
            this.dialog = true
        }
    },
    watch: {
    }
}
</script>


<style lang="scss">

    .flex__component-EmploymentHistoryEditor4 {
        .v-data-table table {
            border-bottom: none !important;
        }
    }
    
</style>
