import {stringify} from 'qs'
import meta from '~/static/manifest'

export default async (context) => {

    let {store} = context
    let {group_slug} = context.params
    let {themes} = context.store.state
    let {appGuid} = context.store.state.flows

    if( !themes.isSet || (!appGuid && themes.isActiveAppThemeSet)) {

        // let groupSlug = context.store.getters['slug/getGroupSlug']

        let themeRes = null

        if(group_slug) {

            // groupSlug = group_slug

            themeRes = context.app.$api.get(`groups`,
                {
                    params: {
                        "filter[slug]": group_slug,
                        "include": 'active-theme'
                    },
                    paramsSerializer: params => stringify(params) 
                }
            )

            const mainData = await Promise.all([themeRes])

            themeRes = mainData[0]

        }


        /**
         * Check Responses
         */

        if ( themeRes && themeRes.data.included && themeRes.data.included.length > 0 ) {

            let active = themeRes.data.included[0]

            // set groups theme
            context.store.commit('groups/setGroupsTheme', themeRes.data.included)
            
            context.store.commit('themes/setActive', active.attributes.slug)
            

            // Prevent test themes getting onto PROD
            if(context.store.state.development) {

                // Test themes here, comment out once done
                //  context.store.commit('themes/setActive', 'online-loans-2021-c')
            }


        } else {
            // no active themes
            context.store.dispatch('themes/fetchDefaultTheme')
        }


        if(themeRes && themeRes?.data?.data?.length > 0){
            context.store.commit('slug/setGroupId', themeRes.data.data[0].id)
        }

        context.store.commit('themes/setIsSet')
        context.store.commit('groups/setApplicationGroup', null)
        context.store.commit('themes/setIsActiveAppThemeSet', false)

    }


    /**
     * Set the theme if not yet
     * 
     */

    // Set default values for Vuetify
    context.$vuetify.theme.options.customProperties = true

    let vThemes = context.$vuetify.theme.themes
    let dark = vThemes.dark
    let light = vThemes.light

    vThemes.light = {
        ...light,
        ...store.getters['themes/getActiveTheme']['colors']
    }

    /**
     * Dynamic injection in the head() 
     *  but requires CSS to be static
     */

    let hash = meta.hash

    context.app.head.link = context.app.head.link.filter( item => item.id !== 'mainthemeCss')
    context.app.head.link.push({
        rel: 'stylesheet', 
        type: 'text/css',
        id: 'mainthemeCss',
        href: `/${themes.active}.css?cb=${hash}`,
    })

}