<template>
    <v-card class="mx-auto rounded-lg mb-4 mb-md-10 pa-6" flat :disabled="loading">
        <v-progress-linear color="secondary" indeterminate stream absolute top v-if="loading"></v-progress-linear>
        <v-card-title class="mb-8">
            Two Factor Authentication
        </v-card-title>

        <template v-if="twoFactorConfirmed">
        <v-card-text>
            <v-row>
                <v-col cols="12">
                    <div class="body-1 font-weight-medium">Two factor authentication is enabled.</div>
                </v-col>
            </v-row>
        </v-card-text>
        </template>

        <template v-else>
        <v-card-text v-if="showEnableTwoFactor">
            <v-row>
                <v-col cols="12">
                    <div class="body-1 font-weight-medium">Enable Two Factor Authentication</div>
                </v-col>
                <v-col cols="12">
                    <v-btn depressed large color="secondary" width="100" @click="handleEnable2FA">
                        Enable 2FA
                    </v-btn>
                </v-col>
            </v-row>
        </v-card-text>

    <v-card-text v-else>

        <v-row>
            <v-col cols="12" md="4">
                <div class="body-1 font-weight-medium">Scan QR Code</div>
            </v-col>
            <v-col cols="12" md="8">
                <p>Scan this QR code with your authentication app</p>
                <dd class="mt-4" v-html="qrCode"></dd>
            </v-col>
        </v-row>

        <v-row>
            <v-col cols="12" md="4">
                <div class="body-1 font-weight-medium">Recovery Codes</div>
            </v-col>
            <v-col cols="12" md="8">
                <p>These codes will only appear once. If you lose access and don't have access to your authentication app, these codes will be the only way to recover your account.</p>
                <div class="recover-code-wrapper mb-4">
                    <pre :class="{'blur': blur}">
<template v-for="code in recoveryCodes">{{ code }} </template></pre>
                </div>
                <v-btn depressed large color="secondary" width="100" @click="onCopyCodes">
                    {{copyText}}
                    <v-icon size="18" right dark style="color: white !important"> mdi-content-copy </v-icon>
                    </v-btn>
                    <v-btn depressed large text color="secondary" width="100" @click="toggleBlur">
                    {{revealText}}
                    </v-btn>
            </v-col>
        </v-row>

        <v-row>
            <v-col cols="12" md="4">
                <div class="body-1 font-weight-medium">Confirm Authentication Code</div>
            </v-col>
            <v-col cols="12" md="8">
                <div class="auth-code-field">
                    <v-text-field
                        v-model="totp"
                        label="Confirm your two factor authentication code to enable"
                        placeholder="Authentication Code"
                        color="secondary">
                    </v-text-field>
                </div>
                <v-checkbox
                    v-model="recoveryCodesSavedCheckbox"
                    class="shrink pa-0 ma-0 ">
                    <template v-slot:label>
                    <div class="pt-1 pb-0 mb-0 float-bottom font-size-16 font-weight-bold">I have saved the recovery codes in a safe place</div>
                    </template>
                </v-checkbox>
                <v-btn depressed large color="secondary" :disabled="!confirmButtonEnabled" width="100" @click="handleConfirmChallenge">Confirm</v-btn>
            </v-col>
        </v-row>

        </v-card-text>
    </template>

  </v-card>
</template>

<script>
    import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
    
    export default {
        props: {
            standalone: {
                type: Boolean,
                default: false
            },
            standaloneConfirm: {
                type: Function,
                default: () => {}
            }
        },
        data() {
            return {
                showEnableTwoFactor: true,
                totp: null,
                recoveryCodesSavedCheckbox: false,
                twoFactorConfirmed: false,
                loading: false,
                blur: true,
                qrCode: null,
                recoveryCodes: [],
                copyText: 'Copy',
                revealText: 'Reveal',
            }
        },
        async beforeMount() {

            if(this.user && this.user?.attributes) {
                this.twoFactorConfirmed = this.user?.attributes?.['is-two-factor-confirmed']
                this.showEnableTwoFactor = !this.user?.attributes?.['is-two-factor-enabled']
            }

            if(this.standalone) {
                this.showEnableTwoFactor = false
                this.qrCode = this.qrCode2FA
                this.recoveryCodes = this.recoveryCodes2FA
            } 
        },
        async mounted(){

            if(this.user?.attributes?.['is-two-factor-enabled'] && !this.user?.attributes?.['is-two-factor-confirmed'] && !this.standalone) {
                this.getRecoveryCodesAndQRCode()
            }

        },
        computed:{
            ...mapGetters('slug', [
                'getGroupSlug',
                'getBaseUrl',
            ]),
            ...mapState('auth-actions', [
                'userSettings',
                'user',
                'qrCode2FA',
                'recoveryCodes2FA',
            ]),
            ...mapState({
                development: state => state.development,
            }),
            confirmButtonEnabled(){
                return !!this.totp && this.recoveryCodesSavedCheckbox
            }
        },
        methods: {
            ...mapActions('auth-actions', [
                'enable2FA',
                'disable2FA',
                'get2FAQR',
                'get2FARecoveryCodes',
                'confirm2FAChallenge',
                'confirmPassword',
                'getCSRFCookie',
            ]),
            onCopyCodes() {
                navigator.clipboard.writeText(this.recoveryCodes.join('\n'));
                this.copyText = 'Copied';
            },
            async handleConfirmChallenge() {
                this.loading = true

                if(this.standalone) {

                    await this.standaloneConfirm({code: this.totp})
                    
                    this.loading = false
                
                } else {

                    const res = await this.confirm2FAChallenge({code: this.totp})

                    if(res.status === 200) {
                        this.twoFactorConfirmed = true
                        this.$store.commit('utils/toggleQuickMessage',  {text: 'Your Two-Factor Authentication is now enabled.', color: 'success'})
                    }

                    this.loading = false
                }
 
            },
            async handleDisable2FA() {

                await this.disable2FA()
                this.twoFactorConfirmed = false
                this.showEnableTwoFactor = true
                this.$store.commit('utils/toggleQuickMessage',  {text: 'Your Two-Factor Authentication is now disabled.', color: 'warning'})

            },
            async handleEnable2FA() {
                this.loading = true
                const res = await this.enable2FA()

                if(res.status === 200) {
                    this.getRecoveryCodesAndQRCode()
                    this.showEnableTwoFactor = false
                }

                this.loading = false
            },
            async getRecoveryCodesAndQRCode() {

                let res = await Promise.all([this.get2FAQR(), this.get2FARecoveryCodes()])

                let qrCode = res[0]
                let recoveryCodes = res[1]

                if(qrCode.status === 200) {
                    this.qrCode = qrCode?.data?.svg
                }

                if(recoveryCodes.status === 200) {
                    this.recoveryCodes = recoveryCodes?.data
                }
            },
            toggleBlur() {
                this.blur = !this.blur;
                this.revealText = this.blur ? 'Reveal' : 'Hide'
            }

        }
    }
</script>

<style lang="scss" scoped>
    .recover-code-wrapper {
        pre {
            white-space: pre-wrap;
            padding: 10px;
            max-width: 362px;
            background: var(--v-light_gray_1-base);
            border: 1px solid var(--v-light_gray_1-darken1);
            filter: blur(0);

            &.blur {
                filter: blur(5px);
                transition: filter 0.3s ease;
            }
        }
    }

    .auth-code-field {
        max-width: 362px;
    }
</style>
