<template>
    <v-card class="d-flex flex-column justify-space-between align-center" flat>

        <v-card :class="['d-flex flex-grow-1 align-center py-0', {'rounded-bottom': hasRoundedBottom}]" flat tile outlined width="100%" max-width="1680" color="secondary">

            <v-col class="d-flex justify-space-between align-center py-4" color="secondary">

                <div class="d-flex" v-if="!vb.mdAndUp">
                    <v-btn icon @click.stop="handleLeftDrawer" 
                        fab 
                        :style="`background-color: ${hexToRGBA('#ffffff', 1)}`">
                        <v-icon color="white">mdi-menu</v-icon>
                    </v-btn>
                </div>

                <div class="d-flex">
                    <div class="pl-2 text-center">

                        <img class="flex-brand d-block" 
                        :src="getLogo" 
                        height="34" style="max-width: 180px"/>


                        <div class="white--text body-2 font-weight-bold pt-2" v-if="!vb.mdAndUp">
                            {{ getActiveStepName }}
                        </div>
                    </div>
                </div>

                <v-stepper alt-labels :value="computedSteps.active" width="100%" v-if="Object.keys(computedSteps.steps).length > 0 && vb.mdAndUp">
                    <v-stepper-header>

                                
                        <template v-for="(step, index) in computedSteps.steps">
                            <v-stepper-step 
                                v-if="computedSteps.active === step.index"
                                color="secondary" 
                                :step="step.index" 
                                :rules="[() => isValid(step)]"
                                :error-icon="step.icon ? step.icon : 'mdi-alert'"
                                :complete="true"
                                :complete-icon="step.icon"
                                class="py-0"
                                >
                                {{step.name}}
                                <div class="d-block d-md-none">{{`${step.index} of ${steps.length}`}}</div>
                            </v-stepper-step>
                            <v-stepper-step 
                                v-else
                                color="secondary" 
                                :step="step.index" 
                                :rules="[() => isValid(step)]"
                                :error-icon="step.icon ? step.icon : 'mdi-alert'"
                                :complete="true"
                                :complete-icon="step.icon"
                                style="cursor: pointer;"
                                @click="handleNavigationClick('parent-flow', index + 1, step)"
                                class="py-0"
                                >
                                {{step.name}}
                                <div class="d-block d-md-none">{{`${step.index} of ${steps.length}`}}</div>
                            </v-stepper-step>

                            <v-divider 
                                v-if="(index + 1) < computedSteps.steps.length"
                                :key="`s${step.index}`" 
                                :class="[{'error': !isValid(step), 'completed': completed(step)  }]"></v-divider>

                        </template>    

                    </v-stepper-header>
                </v-stepper>

                <div class="d-flex button-group" v-if="vb.mdAndUp">
                    <div class="mr-4" v-if="getInviteButton">
                        <InviteApplicantToEdit
                            v-if="getUserSetting && getUserSetting.b2b == 1"
                            :is-flow="true"
                            :label="getInviteButton.label"
                            label-type="button" />
                    </div>
                    <div class="mr-4" v-if="getReturnToPeopleButton">
                        <Button1 :settings="getReturnToPeopleButton" color="primary">
                            <template v-slot:content>
                                <div class="px-4">{{getReturnToPeopleButton.label}}</div>
                            </template>
                        </Button1>
                    </div>
                    <div class="mr-4" v-if="getSaveButton">
                        <Button1 :settings="getSaveButton" color="primary">
                            <template v-slot:content>
                                <div class="px-4">{{getSaveButton.label}}</div>
                            </template>
                        </Button1>
                    </div>
                </div>

                <div class="d-flex"  v-if="!vb.mdAndUp">
                    <v-btn icon @click.stop="handleRightDrawer" 
                    :style="`background-color: ${hexToRGBA('#ffffff', 1)}`"
                    fab>
                        <v-icon>mdi-dots-horizontal</v-icon>
                    </v-btn>
                </div>

            </v-col>
            
        </v-card>


        <!-- Left Drawer -->

        <v-navigation-drawer 
            v-model="leftDrawer" 
            clipped 
            absolute 
            width="100%"
            :style="`background-color: ${hexToRGBA('#000000', 0.8)}`"
            :disable-resize-watcher="true"
            temporary
            app
            class="pa-4">
                <div class="">

                    <v-btn icon @click.stop="handleLeftDrawer" 
                        fab 
                        :style="`background-color: ${hexToRGBA('#ffffff', 1)}`">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>

                    <div class="pt-8">

                    <div class="stepper-mobile">
                        <div class="d-flex">
                            <v-stepper v-if="computedStepsMobile.steps.length > 0" class="pr-8" :class="{'border-right': hasParentFlow}" alt-labels vertical :value="computedStepsMobile.active" width="auto">
                                <div v-for="(step, index) in computedStepsMobile.steps" :key="step.index">
                                    <v-stepper-step 
                                        v-if="computedStepsMobile.active === step.index"
                                        color="secondary" 
                                        :step="step.index" 
                                        :rules="[() => isValid(step)]"
                                        :error-icon="step.icon ? step.icon : 'mdi-alert'"
                                        :complete="true"
                                        :complete-icon="step.icon ? step.icon : 'mdi-check'"
                                        :class="['pa-0', {'completed': completed(step)}]">
                                        {{step.name}}
                                    </v-stepper-step>

                                    <v-stepper-step 
                                        v-else
                                        color="secondary" 
                                        :step="step.index" 
                                        :rules="[() => isValid(step)]"
                                        :error-icon="step.icon ? step.icon : 'mdi-alert'"
                                        :complete="true"
                                        :complete-icon="step.icon ? step.icon : 'mdi-check'"
                                        style="cursor: pointer;"
                                        @click="handleNavigationClick('parent-flow', index + 1, step)"
                                        :class="['pa-0', {'completed': completed(step)}]">
                                        {{step.name}}
                                    </v-stepper-step>

                                    <v-divider 
                                        v-if="(index + 1) < computedStepsMobile.steps.length"
                                        :key="`s${step.index}`" 
                                        :class="[{ 'completed': completed(step)  }]">
                                    </v-divider>
                                </div>    
                            </v-stepper>

                            <div v-if="hasParentFlow" class="sub-flow-navigation pl-6">
                                <div v-for="(step, index) in computedSubFlowStepsMobile.steps" :class="`nav-item d-block px-0 px-md-4 mb-2 ${index == (computedSubFlowStepsMobile.active - 1) ? 'active' : ''}`">
                                    <button class="d-inline-block py-4 white--text text-left" @click="handleNavigationClick('sub-flow', index + 1, step)">
                                        {{step.name}}
                                    </button>
                                </div>
                            </div>

                        </div>
                    </div>

                    </div>
                </div>
        </v-navigation-drawer>

        <!-- Right Drawer -->

        <v-navigation-drawer 
            v-model="rightDrawer" 
            clipped 
            absolute 
            width="100%"
            :style="`background-color: ${hexToRGBA('#000000', 0.8)}`"
            :disable-resize-watcher="true"
            temporary
            right
            app
            class="pa-4"
            >
            <div class="d-flex justify-end">
                <div class="text-right">
                        <v-btn icon @click.stop="handleRightDrawer" 
                            fab 
                            :style="`background-color: ${hexToRGBA('#ffffff', 1)}`">
                            <v-icon color="white">mdi-close</v-icon>
                        </v-btn>

                        <div class="pt-8">
                            <div v-if="getInviteButton" class="mb-4">

                                <div class="d-flex align-center">
                                        <div class="px-4 justify-end white--text body-1">{{getInviteButton.label}}</div>
                                        <InviteApplicantToEdit
                                            v-if="getUserSetting && getUserSetting.b2b == 1"
                                            :is-flow="true"
                                            :label="getInviteButton.label"
                                            icon="mdi-account-plus"
                                            label-type="fab" />
                                </div>
                            </div>

                            <div v-if="getReturnToPeopleButton" class="mb-4">
                                <div class="d-flex align-center">
                                        <div class="px-4 justify-end white--text body-1">{{getReturnToPeopleButton.label}}</div>
                                        <Button1 :settings="getReturnToPeopleButton" fab color="white" :large="false">
                                            <template v-slot:content>
                                                <v-icon>mdi-arrow-left</v-icon>
                                            </template>
                                        </Button1>
                                </div>
                            </div>

                            <div v-if="getSaveButton" class="mb-4">
                                <div class="d-flex justify-end align-center">
                                    <div class="px-4 white--text body-1">{{getSaveButton.label}}</div>
                                    <Button1 :settings="getSaveButton" fab color="white" :large="false">
                                        <template v-slot:content>
                                            <v-icon>mdi-content-save</v-icon>
                                        </template>
                                    </Button1>
                                </div>
                            </div>


                        </div>
                </div>
            </div>
        </v-navigation-drawer>

    </v-card>
</template>


<script>

    import { mapGetters, mapState, mapActions, mapMutations } from 'vuex'
    import subscriptions from '~/components/shared/subscriptions'

    // import formHelpers from '~/components/shared/form-helpers'

    import Button1 from '~/components/base/Button1'
    import InviteApplicantToEdit from '~/components/base/InviteApplicantEditor'

    export default {
        components: { Button1, InviteApplicantToEdit },
        mixins: [subscriptions],
        data(){
            return {
                leftDrawer: false,
                rightDrawer: false,
                parentFlowSteps: [],
            }
        },
        computed: {
            ...mapGetters({
                getActiveTheme: 'themes/getActiveTheme',
                getUserSetting: 'auth-actions/getUserSetting',
                getFlowSlug: 'slug/getFlowSlug',
                getGroupSlug: 'slug/getGroupSlug',
            }),
            ...mapState({
                config: state => state.flows.config,
                activeStep: state => state.flows.activeStep,
                steps: state => state.flows.steps,
                allStepStatuses: state => state.statuses.allStepStatuses,
                flowStatuses: state => state.statuses.flowStatuses,
                appGuid: state => state.flows.appGuid,
            }),
            computedSteps(){

                const {steps, activeStep, parentFlowSteps, getParentStepIndex} = this

                let bp = this.$vuetify.breakpoint.name;
                
                let newSteps = steps
                let newActive = parseInt(activeStep)

                if(this.hasParentFlow) {

                    newSteps = parentFlowSteps
                    newActive = getParentStepIndex

                } else {

                    newSteps = steps
                    newActive = parseInt(activeStep)

                }

                newSteps = newSteps.map((step, i) => {
                    step.index = (i + 1)                    
                    return step
                })

                if(bp == 'xs' || bp == 'sm' || bp == 'md'){

                    if(newActive <= 2) {

                        newSteps = newSteps.slice(0, 3)

                    }  else if(newActive == steps.length) {

                        newSteps = newSteps.slice(newActive - 3)
                    
                    } else {

                        newSteps = newSteps.slice((newActive - 2), (newActive + 1))

                    }

                }

                return {
                    active: newActive,
                    steps: newSteps
                }

            },
            computedStepsMobile(){

                const {steps, activeStep, parentFlowSteps, getParentStepIndex} = this

                let newSteps = steps
                let newActive = parseInt(activeStep)

                if(this.hasParentFlow) {

                    newSteps = parentFlowSteps
                    // get the parent active step
                    newActive = getParentStepIndex

                } else {

                    newSteps = steps
                    newActive = parseInt(activeStep)

                }

                newSteps = newSteps.map((step, i) => {
                    step.index = (i + 1)                    
                    return step
                })

                return {
                    active: newActive,
                    steps: newSteps
                }

            },
            computedSubFlowStepsMobile(){

                const {steps, activeStep} = this

                let newSteps = steps
                let newActive = parseInt(activeStep)

                newSteps = newSteps.map((step, i) => {
                    step.index = (i + 1)                    
                    return step
                })

                return {
                    active: newActive,
                    steps: newSteps
                }

            },

            getActiveStepName(){

                const {hasParentFlow, steps, parentFlowSteps, activeStep, getParentStepIndex} = this

                let stepName = ''
                let currentStepObj = steps[parseInt(activeStep) - 1]

                if(hasParentFlow) {
                    currentStepObj = parentFlowSteps[getParentStepIndex - 1]
                }

                if(currentStepObj) {
                    stepName = currentStepObj.name
                }

                return stepName
            },  

            hasParentFlow() {
                if(this.config?.flow && this.config?.flow.hasOwnProperty('parent-flow')) {
                    return true
                } 
                return false
            },
            hasRoundedBottom(){

                if(this.hasParentFlow || this.vb.smAndDown) {
                    return false
                }

                return true

            },
            subComponents(){

                const {blockSettings} = this
                
                let components = []
                
                if(blockSettings && blockSettings.hasOwnProperty('subComponents')) {
                    return blockSettings.subComponents
                }

                return components
            },
            getReturnToPeopleButton(){

                let componentSetting = null

                this.subComponents.forEach(component => {
                    if(component.name == "ReturnToPeopleButton1") {
                        componentSetting = component
                    }
                })

                return componentSetting

            },
            getSaveButton(){

                let componentSetting = null

                this.subComponents.forEach(component => {
                    if(component.name == "SaveButton1") {
                        componentSetting = component
                    }
                })

                if(this.getFlowSlug.includes('b2c-consumer-personal-7')){
                    return false
                }

                return componentSetting

            },
            getInviteButton(){

                let componentSetting = null

                this.subComponents.forEach(component => {
                    if(component.name == "InviteApplicantButton1") {
                        componentSetting = component
                    }
                })

                return componentSetting

            },
            getLogoTheme(){
                return this.blockSettings?.flexLogoTheme
            },
            getLogo(){  

                let def = require(`~/themes/${this.getActiveTheme.name}/svg/logo.svg`)

                try {

                    if(this.getLogoTheme == 'light') {
                        return require(`~/themes/${this.getActiveTheme.name}/svg/logo-light.svg`)
                    } 

                    return  def
                        
                } catch (error) {
                    
                    return def
                }

            },
            getParentFlowId() {
                return this.config.flow?.['parent-flow']?.id
            },
            getParentStepIndex() {
                let { getParentFlowId, flowStatuses } = this
                let index = 1
                let parentFlow = null

                if(flowStatuses.length > 0) {
                    if(getParentFlowId) {
                        
                        // filter flow statuses
                        flowStatuses.forEach(flow => {
                            if(flow.relationships.flow.data.id == getParentFlowId) {
                                parentFlow = flow
                            }
                        })
                        if(parentFlow) {
                            index = parentFlow.attributes['current-step']
                        }

                    }
                }

                return index
            },
        },
        mounted() {

            this.initialize().then( () => {
                this.$emit('onMount')
            })

        },
        methods: {
            ...mapActions('flows', ['selectStep']),
            ...mapActions('statuses', ['updateFlowStatus']),

            async initialize(){
        
                if(this.hasParentFlow) {

                    let slug = this.config.flow['parent-flow']['slug']
                    let flowResponse = await this.$api.get(`flows?filter[slug]=${slug}`)

                    let stepsConfig = flowResponse.data.data[0].attributes.config

                    if(typeof stepsConfig == 'string'){
                        stepsConfig = JSON.parse(stepsConfig)
                    }

                    this.parentFlowSteps = stepsConfig.flow.steps 

                }

            },
            async handleNavigationClick(type, index, step = {}){
                let { getParentFlowId, flowStatuses } = this

                this.leftDrawer = false
                this.rightDrawer = false

                if(type === 'parent-flow') {
                    if(this.hasParentFlow) {

                        let parentFlow = null

                        if(flowStatuses.length > 0) {
                            if(getParentFlowId) {
                                    
                                // filter flow statuses
                                flowStatuses.forEach(flow => {
                                    if(flow.relationships.flow.data.id == getParentFlowId) {
                                        parentFlow = flow
                                    }
                                })

                                if(parentFlow) {

                                    const flowStatusPayload = {
                                        "id": parentFlow.id, 
                                        "type": "flow-statuses",
                                        "attributes": {
                                            "current-step": `${index}`
                                        }
                                    }

                                    await this.$api.patch(`/flow-statuses/${parentFlow.id}`, {"data": flowStatusPayload})

                                    let slug = this.config.flow?.['parent-flow']?.slug;

                                    if(step.name.includes('Finances')) {
                                        slug = slug + '-finances-1'
                                    }

                                    const url = `/${this.getGroupSlug}/flows/${slug}/${index}/${this.appGuid}`
                                    
                                    this.__block_flexRedirect({url: url})

                                }

                            }
                        }
                    } else {
                        this.selectStep(index)
                    }
                } else {
                    this.selectStep(index)
                }

            },
            handleLeftDrawer(){
                this.leftDrawer = !this.leftDrawer
            },
            handleRightDrawer(){
                this.rightDrawer = !this.rightDrawer
            },
            hexToRGBA(hex, a = 1){
                return this.$options.filters.hexToRGBA(hex, a)
            },
            completed(step){

                let status = this.allStepStatuses.find( status => {
                    return status.relationships.step.data.id == step.id
                });
                
                if(status) {
                    return status.attributes.value == 'valid'
                }
                
                return true
            },
            isValid(step){

                let status = this.allStepStatuses.find( status => {
                    return status.relationships.step.data.id == step.id
                });

                if(status && status.attributes.value == 'dirty') {
                    return false
                }

                return true
            }
        }
    }
</script>

<style  lang="scss">

    .flex__component-Header3 {

        & > .v-card.v-card.rounded-bottom {
            border-bottom-left-radius: 40px !important;
            border-bottom-right-radius: 40px !important;
        }

        .v-stepper {
            box-shadow: none;
            background: transparent !important;
            .v-divider {
                margin-top: 25px !important;
            }
        }

        .v-stepper .v-stepper__step--inactive.v-stepper__step--complete {

            .v-stepper__step__step {
                background: transparent !important;
            }

            .v-stepper__label {
                color: var(--v-light_gray_1-darken1) !important;
            }

        }

        .stepper-mobile {
            
            .v-stepper {
                
                &.border-right {
                    border-right: 3px solid var(--v-accent-base);
                }

                .v-stepper__step {
                        flex-direction: row;

                        .v-stepper__step__step {
                            margin-bottom: 0;
                        }
                }

                .v-stepper__step--active {

                    .v-stepper__step__step {
                        border-color: var(--v-secondary-base) !important;
                        background: var(--v-secondary-base) !important;

                        .v-icon {
                            color: white !important;
                        }
                    }

                    .v-stepper__label {
                        color: white !important;
                        font-size: 17px !important;
                        font-weight: 700 !important;
                    }
                }

            }

            .sub-flow-navigation {
                .nav-item.active button {
                    border-bottom: 3px solid var(--v-accent-base);
                }
            }
        }

    }

</style>
