<template>
<div>
    <v-card :disabled="isDisabled" class="mx-auto" tile flat>
        <v-card-text class="pa-4 pt-0 pa-md-10 pt-md-0">
            <v-container pa-0 fluid>
                <v-row>
                    <v-col cols="12">
                        <v-data-table
                            :loading="false"
                            loading-text="Please wait..."
                            :items="people"
                            hide-default-footer
                            :mobile-breakpoint="0"
                            hide-default-header
                            class="v-data-table-6 mx-auto">

                            <!-- Body -->
                            <template v-if="vb.mdAndUp"  v-slot:item="{ item, index }">
                                <v-card tile flat class="mb-6 pa-2 rounded-xl" color="light_gray_1">

                                    <div class="d-flex justify-space-between align-center py-2 px-4 flex-table-row">

                                    <div class="d-flex flex-column">
                                            <div class="mr-6">
                                                <v-icon size="32">mdi-account</v-icon>
                                            </div>
                                        </div>

                                        <div class="d-flex flex-column">
                                            <div class="mr-6 body-1 font-weight-medium">
                                                {{ getFriendlyName(item) }}
                                            </div>
                                        </div>

                                        <div class="d-flex flex-column">
                                            <div>

                                                <v-chip :filter="false" :ripple="false"
                                                    :style="`background-color: ${hexToRGBA(getActiveTheme.colors.secondary, 0.2)}`"
                                                    class="px-5 font-weight-medium">
                                                    {{ item.roles.length > 1 ? `${item.roles[0]} +${item.roles.length - 1}`: item.roles.length == 1 ? item.roles[0] : '' }}
                                                </v-chip>

                                            </div>
                                        </div>

                                        <v-spacer></v-spacer>

                                        <div class="d-flex justify-end">
                                            <v-btn
                                                depressed
                                                small
                                                @click="handleAddDetails(item)"
                                                :color="getCompleted.includes(item.id) ? 'success' : 'secondary'">
                                                {{ getCompleted.includes(item.id) ? 'Completed' : 'Add Details' }}
                                            </v-btn>
                                        </div>
                                    </div>


                                </v-card>
                            </template>
                            <!-- mobile version -->
                            <template v-else v-slot:item="{ item, index }">
                                <v-card tile flat class="mb-6 pa-2 rounded-lg" color="light_gray_1">
                                    <v-card-text class="d-flex pb-1">
                                        <v-icon size="32" class="mt-n2">mdi-account</v-icon>
                                        <div class="body-1 font-weight-medium pl-1">
                                            {{ getFriendlyName(item) }}
                                        </div>
                                    </v-card-text>
                                    <v-card-text class="pt-0">
                                        <v-chip :filter="false" :ripple="false"
                                            :style="`background-color: ${hexToRGBA(getActiveTheme.colors.secondary, 0.2)}`"
                                            class="px-5 font-weight-medium">
                                            {{ item.roles.length > 1 ? `${item.roles[0]} +${item.roles.length - 1}`: item.roles.length == 1 ? item.roles[0] : '' }}
                                        </v-chip>
                                    </v-card-text>
                                    <v-card-actions>

                                        <v-spacer></v-spacer>

                                        <v-btn
                                            depressed
                                            small
                                            @click="handleAddDetails(item)"
                                            :color="getCompleted.includes(item.id) ? 'success' : 'secondary'">
                                            {{ getCompleted.includes(item.id) ? 'Completed' : 'Add Details' }}
                                        </v-btn>

                                    </v-card-actions>
                                </v-card>
                            </template>

                        </v-data-table>

                        <v-alert dense outlined type="error" v-if="errors.length > 0">
                            <div v-for="msg in errors" v-html="msg"></div>
                        </v-alert>

                    </v-col>
                </v-row>
            </v-container>
        </v-card-text>
    </v-card>



    <!-- Modal -->
    <v-dialog
        max-width="844"
        persistent
        scrollable
        v-model="requirementsDialog">

        <v-card flat :disabled="requirementsDialogLoading">
            <v-progress-linear color="secondary" indeterminate stream absolute top v-if="requirementsDialogLoading"></v-progress-linear>

                <v-card-title class="justify-end">
                    <v-btn icon
                        max-width="40"
                        max-height="40"
                        @click="requirementsDialog = false" fab>
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-card-title>

                <v-card-text class="px-0 py-0 pb-8">
                    <v-carousel
                        light
                        v-model="carousel"
                        :continuous="false"
                        :show-arrows="false"
                        hide-delimiters
                        touchless
                        height="auto"
                        hide-delimiter-background>
                        <v-carousel-item v-for="(step, index) in steps" :key="index">
                                <v-card flat>

                                    <v-card-title class="d-flex py-0" :class="vb.mdAndUp ? 'px-8' : 'px-4'">
                                        <div>
                                            <h4 :class="vb.mdAndUp ? 'font-size-26' : 'font-size-18'">{{step.title}} | {{ getFriendlyName(selectedEntity) }}</h4>
                                            <p :class="vb.mdAndUp ? 'body-1' : 'caption'">{{step.subtitle}}</p>
                                        </div>
                                        <v-spacer />
                                        <div>
                                            <v-icon @click="showHelperModal">mdi-help-circle-outline</v-icon>
                                        </div>
                                    </v-card-title>

                                    <v-card-text class="px-0">
                                        <template>
                                            <FormCitizenship
                                                :ref="step['form-ref']"
                                                :incomes="filteredIncome"
                                                :data="step.data"
                                                @loading="handleLoading"
                                                @submit="handleCitizenshipSubmit" />
                                        </template>
                                    </v-card-text>

                                </v-card>
                        
                        </v-carousel-item>

                    </v-carousel>

                </v-card-text>


                <v-card-actions class="pa-4 pa-md-6 flex-wrap controls">

                    <v-btn
                        color="light_gray_2"
                        class="flex-back-button"
                        :min-width="140" large outlined
                        @click="handleBack"
                        elevation="0" nuxt > Back
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn color="secondary" :min-width="140"
                        large elevation="0"
                        @click="handleNext"
                        nuxt> {{ isLastStep ? "Save" : "Next" }}
                    </v-btn>
                </v-card-actions>


        </v-card>
    </v-dialog>

    <HelperModal v-if="vb.smAndDown" />

</div>

</template>

<script>

    import { mapActions, mapState, mapMutations } from 'vuex'
    import subscriptions from '~/components/shared/subscriptions'
    import shared from '~/components/v1/submission/Money3Entities1/__shared'

    import {store, actions} from '~/components/v1/submission/Money3Entities1/__store'

    import FormCitizenship from '~/components/v1/submission/Money3Entities1/_FormCitizenship'

    import HelperModal from '~/components/base/LenderHelperDialog'
    import { isEmpty } from 'lodash'

    export default {
        components: {
            FormCitizenship,
            HelperModal
        },

        mixins: [subscriptions, shared],

        data() {
            return {
                steps: [],
                requirementsDialog: false,
                requirementsDialogLoading: false,

                people: [],
                incomes: [],

                selectedEntity: null,

                errors: [],

            }
        },

        computed: {
            ...mapState('flows', [
                'lenderSubmissionTo'
            ]),
            vb(){
                return this.$vuetify.breakpoint
            },
            getApplicantKinds(){

                let parts = this.kinds.find( kind => {
                    if(kind.name == 'Part'){
                        return kind
                    }
                })

                return parts.children
            },
            carousel: {
                get(){
                    return store.carousel
                },
                set(value){
                    actions.setCarousel(value)
                }
            },
            formUpdater(){

                return this.requirementsDialog

            },
            isLastStep(){
                if(this.carousel == this.steps.length - 1){
                    return true
                }

                return false
            },
            isFirstStep(){
                if(this.carousel == 0){
                    return true
                }

                return false
            },
            getCompleted(){

                const {people, incomes} = this

                let completed = []

                people.forEach(person => {

                    let relatedIncomes = true

                    if( person.relationships.incomes.data.length > 0 ) {
                        person.relationships.incomes.data.forEach( data => {

                            incomes.forEach( income => {

                                // check for income under 3 years
                                if(income.id == data.id) {
                                    if( income.attributes['income-situation'] === 'Current' ) {
                                        if ( isEmpty(income.attributes['lender']['money3']['occupation']) || isEmpty(income.attributes['lender']['money3']['industry']) ) {
                                            relatedIncomes = false
                                        }
                                    } else {
                                        if ( isEmpty(income.attributes['lender']['money3']['occupation']) ) {
                                            relatedIncomes = false
                                        }
                                    }
                                }

                            })

                        })
                    }

                    if(relatedIncomes) {
                        completed.push(person.id)
                    }

                });

                return completed

            },
            originationFee() {
                let fee = 0

                if(this.blockSettings && this.blockSettings.originationFee) {
                    fee = this.blockSettings.originationFee
                }

                return fee
            },
            getLoanId(){
                return this.appData?.relationships?.loan?.data?.id
            },
            filteredIncome() {
                let filteredIncome = []
                let { incomes, selectedEntity } = this

                if(selectedEntity) {
                    incomes.forEach(income => {
                        this.selectedEntity.relationships.incomes.data.forEach(dat => {
                            if(dat.id == income.id) {
                                filteredIncome.push(income)
                            }
                        })
                    })
                }

                return filteredIncome || []
            },
        },

        methods: {
            ...mapActions('resource-actions', [
                'getLoan',
                'getPeople',
                'getBusinesses',
                'getIncomes',
                'getExpenses',
                'updateLoan',
                'updatePerson',
                'updateIncome',

            ]),
            ...mapMutations('dialogs', [
                'toggleLenderSubmissionHelperDialog'
            ]),
            async pull() {

                let promise1 = this.getLoan(this.getLoanId, {"include" : 'parent-parts'})
                let promise2 = this.getPeople({"include" : 'parent-parts'})
                let promise3 = this.getIncomes()

                const res = await Promise.all([
                        promise1,
                        promise2,
                        promise3
                    ])

                promise1 = res[0]
                promise2 = res[1]
                promise3 = res[2]

                if(promise1.data?.data?.relationships?.kind?.data?.id){
                    this.loanKindId = promise1.data.data.relationships.kind.data.id
                }

                this.people = this.getPeopleByRoles(promise2.data)
                this.incomes = promise3?.data?.data ? promise3.data.data : []

                if(promise2.data.included){
                    this.parts = promise2.data.included.filter( part => {
                        return part.type == "parts"
                    })
                }

            },
            async setInitSteps(data) {
                this.steps = []

                if(data.relationships.incomes.data.length > 0) {

                    this.incomes.forEach(async income => {
                        data.relationships.incomes.data.forEach(dat => {

                            if(dat.id == income.id) {
                                this.steps.push({
                                    "situation": income.attributes['income-situation'],
                                    "data": income,
                                    "title": "Data Mapping",
                                    "subtitle":"Please use the Nodifi Data to answer the questions in the Money3 Requirements",
                                    "form-ref": income.attributes['income-situation'].toLowerCase() + '-' + income.id,
                                })
                            }

                        })
                    })

                }

                return this.steps.length > 0
            },
            async push() {

                this.checkCompleted()

                let valid = this.errors.length == 0

                let status = valid ?  'valid' : 'dirty'

                return {valid, status, data:{}}

            },

            checkCompleted(){

                this.errors = []

                if(this.getCompleted.length != this.people.length) {
                    this.errors.push('Please complete all requirements')
                }

            },

            showHelperModal() {
                this.toggleLenderSubmissionHelperDialog(true)
            },

            async handleAddDetails(data){

                this.selectedEntity = data
                const res = await this.setInitSteps(data)

                if(res) {
                    this.requirementsDialog = true
                }

            },

            async handleNext(){


                let stepsArray = this.steps

                if(this.$refs[stepsArray[this.carousel]['form-ref']][0]) {
                    await await this.$refs[stepsArray[this.carousel]['form-ref']][0].validate()
                }

            },
            async handleBack(){
                if(this.isFirstStep)
                    this.requirementsDialog = false
                else
                    this.carousel--
            },
            async handleCitizenshipSubmit(payload){

                // console.log(payload)

                this.requirementsDialogLoading = true

                if(payload.hasOwnProperty('id')) {

                    let res = await this.updateIncome(payload)

                    if(res.status == 200) {

                        this.updateLocalResourceAttributes(res.data.data)

                        if(this.isLastStep) {
                            this.requirementsDialogLoading = false
                            this.requirementsDialog = false;
                            actions.resetStore();
                        } else {
                            this.carousel++
                            this.requirementsDialogLoading = false
                        }

                        
                    } else {

                        this.requirementsDialogLoading = false
                    }

                }

            },
            updateLocalResourceAttributes(resource){

                if(!resource) return;

                let getType = resource.type

                this[getType].forEach( (v, i) => {

                    if(v.id == resource.id) {
                        this[getType][i]['attributes'] = resource.attributes
                    }

                })

            },
            handleLoading(payload){
                this.requirementsDialogLoading = payload
            }
        },
    }
</script>

<style lang="scss" scoped>

</style>
    
    