<template>
    <v-card class="mx-auto" tile flat>
        <v-card-text class="pa-4 pt-0 pa-md-10 pt-md-0">

               <ValidationObserver ref="observer" tag="form">
                   <div class="flex-form" v-for="layout in fieldLayout">
                        <template v-for="(key, index) in fieldLayoutMaker2(layout, resources.loan.attributes)">

                        <div class="flex-field" :key="key + index" v-if="key == '__spacer'"></div>
                        
                        <div class="flex-field" :key="key + index" v-else>

                            <ValidationProvider
                                v-slot="{ errors }"
                                :name="labels[key]"
                                v-if="isIncluded(key)"
                                :rules="getRules(key)"
                                :vid="key">

                                <v-select
                                    v-if="isFieldType('picklist', key)"
                                    v-model="resources.loan.attributes[key]"
                                    :items="getOptions(key)"
                                    :error-messages="errors"
                                    color="secondary"
                                    :readonly="isReadOnly(key)"
                                    :prefix="prefix[key]"
                                    :label="labels[key]"
                                ></v-select>

                                <v-textarea 
                                    v-else-if="isFieldType('textarea', key)"
                                    v-model="resources.loan.attributes[key]"
                                    :error-messages="errors"
                                    color="secondary"
                                    rows="10"
                                    auto-grow
                                    :readonly="isReadOnly(key)"
                                    :label="labels[key]">
                                </v-textarea>

                                <v-text-field
                                    v-else
                                    v-model="resources.loan.attributes[key]"
                                    :type="isNumber(key) ? 'number' : 'text'"
                                    :label="labels[key]"
                                    :error-messages="errors"
                                    :prefix="prefix[key]"
                                    color="secondary"
                                ></v-text-field> 

                            </ValidationProvider>

                        </div>
                        </template>

                    </div>
                </ValidationObserver>

        </v-card-text>
     
    </v-card>
</template>

<script>

    import { mapActions, mapState } from 'vuex'
    import helpers from '~/plugins/helpers'
    import subscriptions from '~/components/shared/subscriptions'

    import lenderFields from '~/components/v1/submission/Customisations1/__lenders'
    import fieldOptions from '~/components/shared/field-options'
    import lendersAlgo from '~/config/lenders-slug-algo'


    export default {
        components: { },

        mixins: [subscriptions, helpers],

        data() {
            return {
                resources: {
                    loan: {
                        type: 'loans',
                        id: null,
                        attributes: {
                            // see lenderFields
                            "requested-term-months": "",
                            "requested-payment-frequency": "",
                            "requested-broker-fee": "",
                            "customer-discount-rate": "",
                            "bank-account-number": "",
                            "bank-bsb-number": "",
                            "quoted-term-months": "",
                            "lender-plenti-brokerage-amount": "",
                            "lender-plenti-customer-reason" : "",
                            "lender-plenti-customer-paid-method-when-loan-due" : "",
                            "lender-plenti-customer-reason-explanation" : "",
                            "otherLoanPurposeDescription": ""
                        }
                    }
                },
                options: {
                    'requested-term-months': [12, 24, 36, 48, 60, 72, 84],
                    'quoted-term-months': [36,60,84],
                    "lender-plenti-customer-reason" : [
                        { text: "Lower Monthly Payment", value: "LowerMonthlyPayment" },
                        { text: "Planned Upgrade Of Vehicle", value: "PlannedUpgradeOfVehicle" },
                        { text: "Other", value: "Other" },
                    ],
                    "lender-plenti-customer-reason-explanation": [
                        { text: "Debt Consolidation", value: "DebtConsolidation" },
                        { text: "Car", value: "Car" },
                        { text: "Home Improvement", value: "HomeImprovement" },
                        { text: "Holiday", value: "Holiday" },
                        { text: "Wedding", value: "Wedding" },
                        { text: "Repairs", value: "Repairs" },
                        { text: "Moving Costs", value: "MovingCosts" },
                        { text: "Rental Bond", value: "RentalBond" },
                        { text: "Pay Bills", value: "PayBills" },
                        { text: "Solar Battery", value: "SolarBattery" },
                        { text: "Medical Dental", value: "MedicalDental" },
                        { text: "Professional Service Fees", value: "ProfessionalServiceFees" },
                        { text: "Boat", value: "Boat" },
                        { text: "Motorbike", value: "Motorbike" },
                        { text: "Funeral Expenses", value: "FuneralExpenses" },
                        { text: "Business", value: "Business" },
                        { text: "Investment", value: "Investment" },
                        { text: "Other", value: "Other" },
                        { text: "Payoff Loan", value: "PayoffLoan" },
                    ],
                    "lender-plenti-customer-paid-method-when-loan-due": [
                        { text: "Trade In", value: "TradeIn" },
                        { text: "Cash", value: "Cash" },
                        { text: "Refinance", value: "Refinance" },
                    ]
                },
                labels: {
                    "requested-term-months": "Loan Term",
                    "requested-payment-frequency": "Repayment Frequency",
                    "requested-broker-fee": "Origination Fee",
                    "bank-account-number": "Bank Account Number",
                    "bank-bsb-number": "Bank BSB Number",
                    "quoted-term-months": "Loan Term",
                    "customer-discount-rate": 'Customer Rate Reduction',
                    "lender-plenti-brokerage-amount": "Brokerage Amount",
                    "lender-plenti-customer-reason" : 'Customer reason for balloon',
                    "lender-plenti-customer-paid-method-when-loan-due" : 'Intended payment method',
                    "lender-plenti-customer-reason-explanation" : 'Explanation',
                    "otherLoanPurposeDescription" : 'Other Loan Purpose Description',

                },
                validation_rules: {
                    type: "loans",
                    attributes: {
                        "requested-term-months": "required|in:12,24,36,48,60,72,84",
                        "requested-payment-frequency": "required|in:Monthly,Fortnightly,Weekly,Annual",
                        "requested-broker-fee": "required|integer",
                        "customer-discount-rate": "required",
                        "quoted-term-months": "required|in:36,60,84",
                        "bank-account-number": "required",
                        "bank-bsb-number": "required|integer|max:6|min:6",
                        "lender-plenti-brokerage-amount": "required|between:0,9999999",
                        "lender-plenti-customer-reason" : 'required|in:LowerMonthlyPayment,PlannedUpgradeOfVehicle,Other',
                        "lender-plenti-customer-paid-method-when-loan-due" : 'required|in:TradeIn,Cash,Refinance',
                        "lender-plenti-customer-reason-explanation" : 'required|string',
                        "otherLoanPurposeDescription" : 'required|string|max:100',
                    }
                },
                included: {
                    "lender-plenti-customer-reason" : false,
                    "lender-plenti-customer-paid-method-when-loan-due" : false,
                    "lender-plenti-customer-reason-explanation" : false,
                    "otherLoanPurposeDescription": false
                },
                prefix: {
                    "requested-broker-fee": '$',
                    "customer-discount-rate": '%',
                    "lender-plenti-brokerage-amount": '%',
                },
                fieldLayout: [
                    // see lenderFields
                ],
                readonly: {},
            }
        },
        computed: {
            ...mapState({
                appId: state => state.flows.appId,
                loanData: state => state.flows.loanData,
            }),
            getLoanType(){
                 return this.loanData?.attributes['loan-type']
            },
            getSelectedLender(){
                
                const loanAttr = this.loanData?.attributes

                if(loanAttr && loanAttr['actual-lender-quote-algorithm-slug']) {
                    return loanAttr['actual-lender-quote-algorithm-slug']
                }

                return loanAttr['selected-lender-quote-algorithm-slug']
                
            },
            getRequestedRequiredLoanAmount(){
                return this.loanData?.attributes['requested-required-loan-amount'] ? this.loanData?.attributes['requested-required-loan-amount'] : 0
            },
            getLoanId(){
                return this.appData.relationships.loan.data.id
            },
            combinedLendersAlgo(){
                return [
                    ...lendersAlgo.asset,
                    ...lendersAlgo.personal
                ] 
            },
            isPersonal(){
                if(this.getLoanType){
                    return  this.getLoanType == 'Unsecured'
                }
                return false
            },
            fieldLenderPlentiCustomerReason(){
                return this.resources.loan.attributes["lender-plenti-customer-reason"]
            },
            isTargetAssister() {
                return this.appData.attributes['target'] === 'Assister'
            },
            hasBalloonPayment(){

                if(this.isPersonal) {
                    return false
                } else {
                    let balloon = this.loanData.attributes['quoted-balloon']

                    if(this.isTargetAssister) {
                        balloon = this.loanData.attributes['actual-balloon']
                    }

                    if(balloon && balloon > 0) {
                        return true
                    }

                    return false
                }
            },
        },
        methods: {
            ...mapActions('resource-actions', [
                'getLoan',
                'updateLoan',
            ]),
            async pull() {

                const {getSelectedLender, combinedLendersAlgo, getRequestedRequiredLoanAmount} = this

                let res = await this.getLoan(this.getLoanId)

                let lenderSlug = ''

                combinedLendersAlgo.forEach(lender => {
                    if(lender['lender-slug-algorithm'] == getSelectedLender) {
                        lenderSlug = lender['lender-slug']
                    }
                });

                try {

                    // pass configuration for fields and layout
                    this.resources.loan.attributes = {
                        ...lenderFields[lenderSlug]['fields']
                    }

                    this.fieldLayout = {
                        ...lenderFields[lenderSlug]['layout']
                    }

                } catch (err) {
                    console.log('Uh oh!', err)                    
                }

                this.resources.loan = this.resourceFieldPicker(this.resources.loan, res.data.data)

                // console.log(this.getLoanType)

                /**
                 *  Add your Lender, LoanType and other conditional logic here:
                 */

                // Plenti
                if(lenderSlug == 'plenti') {

                    this.applyOriginationFeeLogicPlenti(getRequestedRequiredLoanAmount)

                    if(this.getLoanType == "Unsecured") {
                        this.included["customer-discount-rate"] = false
                    }

                    if(this.getLoanType == "Asset Finance") {
                        this.validation_rules.attributes["bank-account-number"] =  ""
                        this.validation_rules.attributes["bank-bsb-number"] =  "integer|max:6|min:6"
                    }

                    if(this.hasBalloonPayment) {
                        this.included["lender-plenti-customer-reason"] = true
                        this.included["lender-plenti-customer-paid-method-when-loan-due"] = true
                    }

                }

                // NowFinance
                if(lenderSlug == 'now-finance') {
                    this.applyOriginationFeeLogicNowFinance(getRequestedRequiredLoanAmount)
                    
                    if(res.data.data.attributes['personal-loan-reason'] === 'Other') {
                        this.resources.loan.attributes['otherLoanPurposeDescription'] = res.data.data.attributes.lender.nowFinance['otherLoanPurposeDescription']
                        this.included['otherLoanPurposeDescription'] = true
                    } else {
                        this.included['otherLoanPurposeDescription'] = false
                    }

                }

                // SocietyOne

                if(lenderSlug == 'society-one') {

                    let paymentFrequency = this.resources.loan.attributes['requested-payment-frequency']
                    this.resources.loan.attributes['requested-payment-frequency'] = paymentFrequency ? paymentFrequency : 'Monthly'
                    this.validation_rules.attributes["requested-payment-frequency"] = "required|in:Monthly,Fortnightly,Weekly"
                    this.validation_rules.attributes["requested-broker-fee"] = "required|integer"
                    this.validation_rules.attributes["quoted-term-months"] = "required|in:24,36,60,84"
                }

            },
            async push() {

                const observer = this.$refs.observer

                const {loan} = this.resources
                    
                // Exclude resources.relationships and other stuff
                let payload = {
                    type: loan.type,
                    id: loan.id,
                    attributes: {
                        ...this.excludeInvalids(loan.attributes, observer.fields),
                        lender: {
                            nowFinance: {
                                "otherLoanPurposeDescription": loan.attributes['otherLoanPurposeDescription']
                            }
                        }
                    } 
                }

                let loanRes = null;

                if (this.getLoanId) {
                    loanRes = await this.updateLoan(payload)

                } else {

                    // Include relationships when creating resource

                    payload.relationships = {
                        application: {
                            data: {type: "applications", id: this.appId}
                        }
                    }

                    loanRes = this.createLoan(payload)
                }

                const {valid, status} = await this.validateFormData()
                const data = await this.appDataHelper([loanRes])

                return {valid, status, data}
                
            },
            isIncluded(field){

                const {included} = this

                if(included.hasOwnProperty(field)) {
                    return included[field]
                }

                return true
            },
            isReadOnly(key){
                
                const {readonly} = this

                if(readonly.hasOwnProperty(key)){
                    return readonly[key]
                }

                return false

            },
            isNumber(key){
                return [
                    'bank-account-number', 
                    'bank-bsb-number'
                ].includes(key)
            },
            applyOriginationFeeLogicNowFinance(val){

                // "val" is loan.requested-required-loan-amount

                // loan_amount from 0 to 8000: maximum origination_fee is 550
                // loan_amount from 8001 to 10000: maximum origination_fee is 880
                // loan_amount from 10001 to 20000: maximum origination_fee is 990
                // loan_amount from 20001 to 50000: maximum origination_fee is 1650
                // loan_amountt larger than 50000: maximum origination_fee is 1990

               if(val <= 8000) {
                    this.validation_rules.attributes["requested-broker-fee"] = "required|integer|max_value:550"
               }
               if(val >= 8001 || val <= 10000) {
                    this.validation_rules.attributes["requested-broker-fee"] = "required|integer|max_value:880"
               }
               if(val >= 10001 || val <= 20000) {
                    this.validation_rules.attributes["requested-broker-fee"] = "required|integer|max_value:990"
               }
               if(val >= 20001 || val <= 50000) {
                    this.validation_rules.attributes["requested-broker-fee"] = "required|integer|max_value:1650"
               }
               if(val > 50000) {
                    this.validation_rules.attributes["requested-broker-fee"] = "required|integer|max_value:1990"
               }

            },
            applyOriginationFeeLogicPlenti(val){ 
                
                // "val" is loan.requested-required-loan-amount

                // cannot be greater than $990 for requested_required_loan_amount below $10,000
                // cannot be greater than $1,500 for requested_required_loan_amount below $20,000
                // cannot be greater than $2,000 for requested_required_loan_amount below $35,000
                // cannot be greater than $2,500 for requested_required_loan_amount above $35,000

               if(val < 10000) {
                    this.validation_rules.attributes["requested-broker-fee"] = "required|integer|max_value:990"
               }
               if(val >= 10000 || val < 20000) {
                    this.validation_rules.attributes["requested-broker-fee"] = "required|integer|max_value:1500"
               }
               if(val >= 20000 || val <= 35000) {
                    this.validation_rules.attributes["requested-broker-fee"] = "required|integer|max_value:2000"
               }
               if(val >= 35000) {
                    this.validation_rules.attributes["requested-broker-fee"] = "required|integer|max_value:2500"
               }

            }
        },
        watch: {
            fieldLenderPlentiCustomerReason(val){
                if(val == 'Other'){
                    this.included["lender-plenti-customer-reason-explanation"] = true
                }else {
                    this.included["lender-plenti-customer-reason-explanation"] = false
                }
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>