<template>
    <v-card :disabled="isSaving" flat class="mx-auto flex__ApplicantCreator1" :class="vb.mdAndUp ? 'px-8' : 'px-2'" max-width="1500">

        <v-card-title class="title py-1">
            {{isCommercial ? 'Entities' : 'People'}}
        </v-card-title>

        <v-card v-if="buildRoleTree.length === 0" flat class="secondary lighten-5 rounded-xl pa-4 my-3 mx-2">

            <v-card-actions>
                <v-spacer />
                    <v-btn
                        class="mx-auto primary"
                        depressed large
                        :min-width="vb.mdAndUp ? '378' : ''"
                        :max-height="vb.mdAndUp ? '42' : ''">
                        {{isCommercial ? 'Create Entity': 'Create Person'}}
                    </v-btn>
                <v-spacer />
            </v-card-actions>

        </v-card>

        <v-list dense>
            <v-list-item-group color="primary">
                <div v-if="isConsumer && buildRoleTree.length == 2" class="filler-consumer"></div>

                <EntityList 
                    v-for="(role, index) in buildRoleTree" 
                    :key="index"
                    :size="buildRoleTree.length"
                    :arguments-value="argumentsValue"
                    :role="role"/>
            </v-list-item-group>
        </v-list>

        <ApplicantStepper 
            :dialog="showDialog"
            @close-modal="dialog = false"/>

        <ConfirmModal 
            v-if="showDelete"
            async
            :standalone="false"
            :value="showDelete"
            :title="`Remove Role`"
            :processing="isSaving"
            :message="buildDeleteMsg(deletedItems)"
            confirmText="Remove"
            @close="handleClose"
            @confirm="confirmDelete(deletedItems)">
        </ConfirmModal>

        <div v-if="errorMessages.length > 0">
            <v-alert dense outlined type="error" class="ma-0">
                <div v-for="(error, index) in errorMessages" :key="index" v-html="error"></div>
            </v-alert>
        </div>

    </v-card>
</template>
<script>
import { mapActions } from 'vuex'
import {store, actions} from '~/components/v1/ApplicantCreator1/__store'
import EntityList from '~/components/v1/ApplicantCreator1/_EntityList'
import ApplicantStepper from '~/components/v1/ApplicantCreator1/_ApplicantStepper'
import shared from '~/components/v1/ApplicantCreator1/__shared'
import resourceHelpers from '~/components/shared/resource-helpers'
import ConfirmModal from '~/components/base/ConfirmModal2'

export default {
    name: 'ApplicantCreator1',
    mixins: [shared, resourceHelpers],
    components: {EntityList, ApplicantStepper, ConfirmModal},
    data() {
        return {
            errorMessages: [],
            list: 0,
            selected: null,
        }  
    },
    computed: {
        getLoanId(){
            try {
                return this.appData.relationships.loan.data.id
            } catch (error) {
                console.log('Ooops!..', error)
            }
        },
        vb() {
            return this.$vuetify.breakpoint
        },
        argumentsValue() {
            return this.blockListeners2
        }
    },
    methods: {
        ...mapActions('resource-actions', [
            'getLoan',
            'updateLoan',
            'getPeople',
            'createPerson',
            'updatePerson',
            'createBusiness',
            'updateBusiness',
            'getBusinesses',
            'createPart',
            'deletePart',
        ]),
        ...mapActions('kinds', [
            'getKindById',
        ]),
        async pull(){

            const {getLoanId} = this

            try {

                let loan = await this.getLoan(getLoanId)

                actions.setResource(loan.data.data)

                await this.updatePrimaryApplicant()

                let tree = this.buildRoleTree

                this.list = 0
                this.selected = tree[0]


            } catch (error) {

                console.log(error)
            }

        },
        async push() {

            this.buildRoleTree.forEach(tree => {
                if(tree['sub-step-status'] == 'pristine') {
                    let found = this.errorMessages.find(item => item == `Please complete ${this.getFriendlyName(tree)} details.`);
                    if(!found) {
                        this.errorMessages.push(`Please complete ${this.getFriendlyName(tree)} details.`)
                    }
                    
                }
            })

            let valid = this.errorMessages.length === 0

            let status = valid ? 'valid' : 'dirty'

            return {valid: valid, status: status, data:{}}

        },
        handleClose() {
            actions.setShowDelete(false)
        },
        async confirmDelete(data){

                actions.setSaving(true)

                // Detach entity from parent entity
                let parent = data.parent

                let parentChildParts = parent.relationships['child-parts']
                let parentChildPartsIds = []

                if(parentChildParts && parentChildParts.data.length > 0){
                    parentChildParts.data.forEach(part => {
                        parentChildPartsIds.push(part.id)
                    })
                }

                let entityParentParts = data.relationships['parent-parts']
                let entityParentPartId = null

                if(entityParentParts && entityParentParts.data.length > 0){

                    entityParentParts.data.forEach(part => {
                        if( parentChildPartsIds.includes(part.id) ){

                            entityParentPartId = part.id

                        }

                    })
                }

                let req1 = this.deletePart(entityParentPartId)

                await Promise.all([req1])

                await this.updatePrimaryApplicant()

                actions.setSaving(false)

        },
    }
}
</script>
<style lang="scss" scoped>
    .flex__ApplicantCreator1 {
        .filler-consumer {
            position: absolute;
            bottom: 66px;
            width: 4px;
            left: 20px;
            height: 50%;
            border-left: 3px dashed #f7f7f7;
            z-index: 1;
        }
    }
</style>
