<template>    
    <v-container class="flex__sub-component-Radio4">
        <div class="body-1 text-center font-weight-medium" style="font-size: 1.1rem !important;">{{ label }}</div>
            <v-item-group v-model="selected">
                <v-row align="center" justify="center">
                    <div
                        v-for="(n, key) in options"
                        :key="key"
                        class="d-flex pa-2 pb-0 ma-0">
                        <v-item v-slot="{ active, toggle }" style="cursor: pointer;">
                            <v-alert 
                                @click="toggled(toggle, !active, n)" outlined 
                                :color="`${active ? 'secondary' : errors[0] ? 'error' : 'light_gray_1'}`" 
                                class="rounded-xl pa-0 mx-0 my-0">
                                <v-card flat
                                    class="align-center rounded-xl"
                                    width="170">
                                    <v-card-text class="text-center">
                                        <div class="body-1 py-4">{{n.text}}</div>
                                    </v-card-text>
                                    
                                    <v-card-actions 
                                        :class="`text-center ${active ? 'secondary lighten-5' : 'grey lighten-5'} px-0 py-4`">
                                        <v-spacer></v-spacer>
                                        
                                        <v-icon 
                                            v-if="active"
                                            color="secondary"
                                            size="20">
                                            mdi-check
                                        </v-icon>
                                        <div v-else class="body-2 body--text">Select</div>
                                        <span class="white--text headline"></span>
                                        
                                        <v-spacer></v-spacer>
                                    </v-card-actions>
                                </v-card>
                            </v-alert>
                        </v-item>
                    </div>
                </v-row>
            </v-item-group>
            <div class="with-dynamic-validations caption text-center pt-1">
                {{ errors[0] }}
            </div>
    </v-container>    
</template>

<script>
    export default {
        name: 'Radio4',
        props: {
            options: {
                type: Array,
                default: () => []
            },
            errors: {
                type: Array,
                default: () => []
            },
            label: {
                type: String,
                dafault: ''
            }
        },
        data() {
            return {
                selected: ''
            }
        },
        methods: {
            toggled(toggle, active, item) {
                toggle()
                this.$emit('input', active ? item.text : '')
            }
        }
    }
</script>

<style lang="scss" scoped>
</style>