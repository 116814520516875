import { mapState, mapActions, mapGetters, mapMutations } from 'vuex'
import { isEmpty } from 'lodash'

export default {  
    computed: {
        paymentTerms() {
            // console.log(`Item this.loanResource?.attributes : `, this.loanResource?.attributes)
            return ''
            //this.loanResource?.attributes?.['requested-payment-terms'] === '' ? 'In Arrears' : this.loanResource.attributes['requested-payment-terms']
        },
        hasSupportDocsList() {
            return this.selectedLender?.details?.['supporting-docs']?.length > 1 ? true : false
        },
        hasSelectedLender() {
            if ( this.userConfirmedLender === this.selectedLender.name )
                return true
            return this.selected_lender.length ? true : false
        },
        hasPrimary() {
            return isEmpty(this.userSelectedLenders.primary['bid-lender']?.id) ? false : true
        },

        hasAlternative() {
            return isEmpty(this.userSelectedLenders.alternative['bid-lender']?.id) ? false : true
        },

        isUserSelected() {
            
            let flag = false

            Object.keys(this.userSelectedLenders).forEach( lender => {
                if ( this.userSelectedLenders[lender]?.['bid-lender']?.attributes?.['lender-name'] === this.selectedLender.details.name )
                    flag = true
            })

            return flag
        }
        
    },
    methods: {
        ...mapActions('resource-actions', [
            'deleteBidLender',
            'createBidLender',
            'updateBidLender',
            'deleteBid',
            'createBid',
            'updateBid'
        ]),

        async save(data) {

            this.$emit('disable-badges', { value: true, type: data.type})

            // NOTE: Must delete existing BID record here... and create a new one everytime                
            let bidPayload = null
            let bid_lender = this.userSelectedLenders[data.type]['bid-lender']
            let bid = this.userSelectedLenders[data.type]['bid']

            if ( data.flag ) await this.deleteBidLender(bid_lender.id)

            // filter object data
            bid.attributes = this.filterResources(bid.attributes)
            //console.log(bid.attributes)

            if ( !bid?.id ) {
                bidPayload = await this.createBid( this.resourceObjHelper('create', bid, data.data.bid) )                     
            }
            else {

                let param = this.resourceObjHelper('create', bid, data.data.bid)

                param.id = bid.id
                delete param.relationships['bid-lender']

                bidPayload = await this.updateBid( param )

            }
            
            if ( bidPayload.status === 201 || bidPayload.status === 200 ) {

                data.data['bid'] = bidPayload.data.data
                data.data['bid-lender'].relationships.bid.data.id = bidPayload.data.data.id

                let params = this.resourceObjHelper('create', bid_lender, data.data['bid-lender'])
                delete params.id

                let bidLenderPayload = await this.createBidLender( params )

                if ( bidLenderPayload.status === 201 ) {
                    data.data['bid-lender'] = bidLenderPayload.data.data
                    this.$emit('save-official-lender', data)
                }
                
            }
            
        },

        resourceObjHelper(requestType = 'update', localResource = {}, userData = {}) {
            
            let data = null
            
            if ( requestType === 'update' ) {
                data = {
                    attributes: userData.attributes,
                    id: localResource.id,
                    relationships: { 
                        application: localResource.relationships.application
                    },
                    type: userData.type
                }
            }
            else {
                data = userData
            }

            return data
        },

        filterResources (resources) {

            for (var propName in resources) {
                if (resources[propName] === '' || resources[propName] === null) {
                    delete resources[propName];
                }
            }
            
            return resources
        },

        filterLenderType() {
            return ''
        },

        confirmedLender(data) {
            this.selected_lender = data
            this.$emit('user-confirmed', this.selected_lender)
        },

        

        

               
    }
}