/**
 * 
 *  WARNING: Do not change the IDS e.g. [12-3|12-4|12-5|12-6] !
 *  consult Paul first if your not sure what you're doing...
 *  
 * 
 */

export default {
    /**
     * ########################################################### INCOMES
     */

    // Centrelink 12-2
    // --Age Pension 12-3se
    // --Carer Allowance 12-4
    // --Child Care Benefit 12-5
    // --Defence Service Pension 12-6
    // --Disability Support Pension 12-7
    // --Family Allowance 12-8
    // --Family Tax Benefit 12-9
    // --Newstart 12-10
    // --Parenting Payments 12-12
    // --Widows Allowance 12-13
    // --Youth Allowance 12-14

    "12-3|12-4|12-5|12-6|12-7|12-8|12-9|12-10|12-12|12-13|12-14": {
        incomes: [
            {field: "role-selector", label: "Select Role"}, 
            [
                "income-situation",
                "net-monthly-amount",
                "start-date",
                "end-date",
            ],
            [
                "benefit-amount",
                "income-period",
            ],
            // hiddens
            [
                "employment-job-status",
            ], 
        ]
    },

    // --Other Benefits 12-11

    "12-11": {
        incomes: [
            {field: "role-selector", label: "Select Role"}, 
            [
                "income-situation",
                "other-benefit-description",
                "start-date",
                "end-date",
            ],
            [
                "benefit-amount",
                "income-period",
                "net-monthly-amount",
            ],
            // hiddens
            [
                "employment-job-status",
            ], 
        ]
    },

    // Child Support 12-15

    "12-15": {
        incomes: [
            {field: "role-selector", label: "Select Role"}, 
            [ 
                "income-situation",
                "net-monthly-amount",
                "start-date",
                "end-date",
            ],
            [
                "benefit-amount",
                "income-period",
            ],
            // hiddens
            [
                "employment-job-status",
            ], 
        ]
    },

    // Company Director 
    "12-16": { 
        incomes: [
            {field: "role-selector", label: "Select Role"}, 
            [
                "income-situation",
                "business-name",
                "net-monthly-amount",
            ], 
            [
                "start-date",
            ],
            // hiddens
            [
                "employment-job-status",
            ], 
        ]
    },

    // Investment Income
    // --Dividends 12-18
    // --Private Pension 12-20

    "12-18|12-20": {
        incomes: [
            {field: "role-selector", label: "Select Role"}, 
            [ 
                "income-situation",
                "net-monthly-amount",
                "start-date",
                "end-date",
            ], 
            [
                "investment-income",
                "income-period",
            ],
            // hiddens
            [
                "employment-job-status",
            ], 
        ]
    },
    // --Other Investment 12-19

    "12-19": {
        incomes: [
            {field: "role-selector", label: "Select Role"}, 
            [ 
                "income-situation",
                "income-additional-comments",
                "start-date",
                "end-date",
            ], 
            [
                "investment-income",
                "income-period",
                "net-monthly-amount",
            ],
            // hiddens
            [
                "employment-job-status",
            ], 
        ]
    },

    // Investment Income
    // --Rental Income 12-21
    "12-21": {
        incomes: [
            {field: "income-sharing", label: "Split Income"}, 
            // {field: "role-selector", label: "Select Role"}, 
            [ 
                "income-situation",
                "__rental-address",
                "start-date",
                "end-date",
            ], 
            [
                "investment-income",
                "income-period",
                "net-monthly-amount",
            ],
            // hiddens
            [
                "primary-income-split",
                "employment-job-status",
            ], 
        ],
        assets: "10-14",
        liabilities: "13-20",
        expenses: "11-30",
    },

    // Investment Income
    // --Superannuation 12-22
    "12-22": {
        incomes: [
            {field: "role-selector", label: "Select Role"}, 
            [ 
                "income-situation",
                "net-monthly-amount",
                "start-date",
                "end-date",
            ], 
            [
                "investment-income",
                "income-period",
            ],
            // hiddens
            [
                "employment-job-status",
            ], 
        ],
        assets: "10-12"
    },


    // No Income
    "12-23": { 
        incomes: [
            {field: "role-selector", label: "Select Role"}, 
            [
                "income-situation",
                "no-income-reason",
                "start-date",
                "end-date",
            ], 
            ["additional-notes"],
            // hiddens
            [
                "employment-job-status",
            ], 
        ]
    },

    // Other Income 12-24
    // --Income Protection Payment 12-25
    // --Other Income 12-26
    // --Workers Compensation 12-27

    "12-25|12-26|12-27": { 
        incomes: [
            {field: "role-selector", label: "Select Role"}, 
            [
                "income-situation",
                "net-monthly-amount",
                "start-date",
                "end-date",
            ], 
            [
                "net-income",
                "income-period",
            ],
            ["additional-notes"],
            // hiddens
            [
                "employment-job-status",
            ], 
        ]
    },

    // PAYG Employment 12-28
    "12-28": { 
        incomes: [
            {field: "role-selector", label: "Select Role"}, 
            [
                "income-situation",
                "start-date",
                "end-date",
                "employment-on-probation",
            ], 
            [
                "employment-job-status",
                "job-title",
            ], 
            {title: "Employer Details"},
            [
                "employer-name",
                "abn",
                "__employer-address",
            ],
            [
                "employment-contact-person",
                "employment-contact-phone",
                'contact-person-direct-mobile',
            ],
            {title: "Base Salary/Wage Details"},
            [
                "net-standard-pay",
                "income-period",
                "__spacer",
                "gross-annual-income",
            ],
            {title: "Additional Package Elements"},
            [
                "overtime-pay-monthly-net",
                "bonus-monthly-net",
                "commission-monthly-net",
                "work-allowance-net-monthly",
                "net-monthly-amount",
            ],
        ]
    },

    // Self-Employed 12-29
    "12-29": { 
        incomes: [
            {field: "role-selector", label: "Select Role"}, 
            [
                "income-situation", 
                "employment-job-status",
                "start-date",
                "end-date",
            ], 
            [
                "business-name",
                "abn",
                "nature-of-business",
            ],
            [
                "__business-address",
            ],
            [
                "self-employed-gst-registered",
                "self-employed-date-registered-gst",
            ],
            {title: "Income Details"},
            [
                "income-period",
                "income-verification-method",
                "reporting-period",
            ],
            [
                "revenue",
                "depreciation",
                "profit-before-tax-annual",
            ],
            [
                "gross-annual-income",
                "__spacer",
                "net-monthly-amount",
            ],

        ]
    },

    // Business Income 12-30
    "12-30": { 
        incomes: [
            {field: "role-selector", label: "Select Role"}, 
            [
                "income-situation",
            ],
            [
                "income-verification-method",
                "reporting-period",
            ],
            [
                "revenue",
                "depreciation",
                "profit-before-tax-annual",
                "net-monthly-amount",
            ],
        ]
    },

    /**
     * ########################################################### ASSETS
     */
    
    //  Asset 10-1
    //  --Cash in Bank 10-2
    //  ----Business Account 10-3
    //  ----Personal Account 10-4
    //  ----Term Deposit 10-5

    "10-3|10-4|10-5": {
        selector: [
            {field: "role-selector", label: "Select Role"},
        ],
        assets: [
            {overline: "ASSET"}, 
            {title: "Business Account", if: '10-3'}, 
            {title: "Personal Account", if: '10-4'}, 
            {title: "Term Deposit", if: '10-5'}, 
            [ 
                "asset-description",
                "financial-institution",
                "other-institution",
                "asset-value",
            ], 
            // {subtitle: "Account Details"},
            // [ 
            //     "bank-account-name",
            //     "bsb-number",
            //     "bank-account-number",
            // ], 

        ],
        liabilities: "unavailable",
        expenses: "unavailable",
    },


    //  Asset 10-1
    //  --Home Contents 10-6

    "10-6": {
        selector: [
            {field: "role-selector", label: "Select Role"},
        ],
        assets: [
            {overline: "ASSET"}, 
            {title: "Home Contents"}, 
            [ 
                "asset-description",
                "asset-value",
                // "covered-by-insurance",
            ]
        ],
        liabilities: "unavailable",
        expenses: "unavailable",
    },

    //  Asset 10-1
    //  --Other Investment 10-7
    //  ----Business Equity 10-8
    //  ----Goodwill of Business 10-9
    //  ----Managed Funds 10-10
    //  ----Shares 10-11
    //  ----Superannuation 10-12

    "10-8|10-9|10-10|10-11|10-12": {
        selector: [
            {field: "role-selector", label: "Select Role"},
        ],
        assets: [
            {overline: "ASSET"}, 
            {title: "Business Equity", if: "10-8"}, 
            {title: "Goodwill of Business", if: "10-9"}, 
            {title: "Managed Funds", if: "10-10"}, 
            {title: "Shares", if: "10-11"}, 
            {title: "Superannuation", if: "10-12"}, 
            [ 
                "asset-value",
                "asset-description",
                "description-other",
            ]
        ],
        liabilities: "unavailable",
        expenses: "unavailable",
    },


    //  Asset 10-1
    // --Property - Investment 10-13
    // ----Investment Property 10-14


    "10-14": {
        selector: [
            {field: "role-selector", label: "Select Role"},
        ],
        incomes: "12-21", // Reference ID, initially inactive in view
        assets: [
            {overline: "ASSET"}, 
            {title: "Investment Property"}, 
            [ 
                "asset-value",
                "asset-description",
                "__full-address"
            ], 
        ],
        // liabilities: "13-20", 
        // expenses: "11-30",
        liabilities: "13-19|13-20", 
        expenses: "11-29|11-30", 
    },


    //  Asset 10-1
    // --Property - Owner Occupied 10-15
    // ----Owner Occupied 10-16

    "10-16": {
        selector: [
            {field: "role-selector", label: "Select Role"},
        ],
        assets: [
            {overline: "ASSET"}, 
            {title: "Owner Occupied"}, 
            [ 
                "asset-value",
                "asset-description",
                "__full-address"
            ], 
        ],
        liabilities: "13-21",
        expenses: "11-6|11-7",
    },

    //  Asset 10-1
    // --Stock & Equipment 10-17
    // ----Stock 10-30
    // ----Tools of Trade 10-31

    "10-30|10-31": {
        selector: [
            {field: "role-selector", label: "Select Role"},
        ],
        assets: [
            {overline: "ASSET"}, 
            [ 
                "asset-description",
                "asset-value",
                // "covered-by-insurance"
            ],
        ],
        liabilities: "unavailable",
        expenses: "unavailable",
    },


    //  Asset 10-1
    // --Vehicle - Motor 10-32
    // ----Motor Vehicle 10-33

    "10-33": {
        selector: [
            {field: "role-selector", label: "Select Role"},
        ],
        assets: [
            {overline: "ASSET"}, 
            {title: "Motor Vehicle"}, 
            [ 
                "asset-description",
                "asset-value",
               // "being-refinanced",
               // "being-sold-traded",
            ],
            [ 
                "year-of-manufacture",
                "make",
                "model",
            ],
        ],
        liabilities: "13-9|13-10",
        expenses: "11-18|11-20",
    },

    //  Asset 10-1
    // --Vehicle - Other 10-34
    // ----Boat 10-35
    // ----Camper Trailer 10-36
    // ----Caravan 10-37
    // ----Heavy Vehicles 10-38
    // ----Motor Bike 10-44
    // ----Jetski 10-46
    // ----Motor Home 10-47
    // ----Light Trailer 10-48
    // ----Horse Float 10-49
    // --Stock & Equipment 10-17
    // ----Machinery 10-27
    // ------Agricultural Machinery 10-28
    // ------Yellow Goods 10-29
    // ----Equipment 10-18
    // ------Industrial Plant (Lathes etc) 10-19
    // ------Material Handling (Fork Lifts etc) 10-20
    // ------Medical Equipment 10-21
    // ------Office Equipment (Copiers etc) 10-22
    // ------Computer Hardware 10-23
    // ------POS Systems 10-24
    // ------Solar Equipment 10-25
    // ------Other (Not Listed) 10-26
    // ----Other Vehicle 10-45
    
    "10-35|10-36|10-37|10-38|10-44|10-45|10-46|10-47|10-48|10-49|10-19|10-20|10-21|10-22|10-23|10-24|10-25|10-28|10-29|10-26": {
        selector: [
            {field: "role-selector", label: "Select Role"},
        ],
        assets: [
            {overline: "ASSET"}, 
            {title: "Boat", if: "10-35"}, 
            {title: "Camper Trailer", if: "10-36"}, 
            {title: "Caravan", if: "10-37"}, 
            {title: "Heavy Vehicles", if: "10-38"}, 
            {title: "Motor Bike", if: "10-44"}, 
            {title: "Other Vehicle", if: "10-45"}, 
            {title: "Jetski", if: "10-46"}, 
            {title: "Motor Home", if: "10-47"}, 
            {title: "Light Trailer", if: "10-48"}, 
            {title: "Horse Float", if: "10-49"}, 
            {title: "Agricultural Machinery", if: "10-28"}, 
            {title: "Yellow Goods", if: "10-29"}, 
            {title: "Industrial Plant", if: "10-19"}, 
            {title: "Material Handling", if: "10-20"}, 
            {title: "Medical Equipment", if: "10-21"}, 
            {title: "Office Equipment", if: "10-22"}, 
            {title: "Computer Hardware", if: "10-23"},
            {title: "POS Systems", if: "10-24"}, 
            {title: "Solar Equipment", if: "10-25"}, 
            {title: "Other", if: "10-26"}, 
            [ 
                "asset-description",
                "asset-value",
                // "being-sold-traded",
                // "covered-by-insurance",
                "description-other"

            ],
            [ 
                "year-of-manufacture",
                "make",
                "model",
            ],
        ],
        liabilities: "13-11",
        expenses: "11-21",
    },

    // ------Prime Mover 10-39
    // ------Rigid Truck (>4.5t) 10-40
    // ------Trailer (Truck) 10-41
    // ------Bus 10-42
    // ------Crane 10-43

    "10-39|10-40|10-41|10-42|10-43": {
        selector: [
            {field: "role-selector", label: "Select Role"},
        ],
        assets: [
            {overline: "ASSET"}, 
            {title: "Prime Mover", if: "10-39"}, 
            {title: "Rigid Truck (>4.5t)", if: "10-40"}, 
            {title: "Trailer (Truck)", if: "10-41"}, 
            {title: "Bus", if: "10-42"}, 
            {title: "Crane", if: "10-43"}, 
            [ 
                "asset-description",
                "asset-value",
                // "covered-by-insurance",
                // "being-sold-traded",
            ],
            // {subtitle: "Equipment Details"}, 
            [ 
                "year-of-manufacture",
                "make",
                "model",
            ],
        ],
        liabilities: "13-11",
        expenses: "11-21",
    },

    /**
     * ########################################################### LIABILITY
     */



    //  Liability 13-1
    // --Loan - Real Estate 13-18
    // ----Mortgage - Owner Occupied 13-21

    "13-21": {
        selector: [
            {field: "role-selector", label: "Select Role"},
        ],
        assets: "10-16",
        liabilities: [
            {overline: "LIABILITY"}, 
            {title: "Mortgage - Owner Occupied"}, 
            [ 
                "liability-description",
                "financial-institution",
                "other-institution",
                "amount-owing",
                "amount-financed",
            ], 
            // {subtitle: "Account Info"}, 
            // [ 
            //     "loan-account-number",
            //     "mortgage-structure",
            //     "interest-rate",
            //     "end-date",
            // ], 
        ],
        expenses: "11-6",
    },



    //  Liability 13-1
    // --Loan - Asset 13-8
    // ----Other Asset Loan 13-11

    "13-11": {
        selector: [
            {field: "role-selector", label: "Select Role"},
        ],
        assets: "10-35|10-36|10-37|10-38|10-44|10-45|10-46|10-47|10-48|10-49|10-19|10-20|10-21|10-22|10-23|10-24|10-25|10-28|10-29|10-26",
        liabilities: [
            {overline: "LIABILITY"}, 
            {title: "Other Asset Loan"}, 
            [ 
                "description-other",
                "liability-description",
                "financial-institution",
                "other-institution",
                "amount-owing",
                "to-be-paid-out",
            ], 
            // {subtitle: "Account Info"}, 
            [ 
                // "loan-account-number",
                "amount-financed",
                // "interest-rate",
                // "end-date",
            ], 
        ],
        expenses: "11-21",
    },


    //  Liability 13-1
    // --Loan - Asset 13-8
    // ----Car Loan 13-9
    // ----Novated Lease 13-10


    "13-9|13-10": {
        selector: [
            {field: "role-selector", label: "Select Role"},
        ],
        assets: "10-33",
        liabilities: [
            {overline: "LIABILITY"}, 
            {title: "Car Loan", if: "13-9"}, 
            {title: "Novated Lease", if: "13-10"}, 

            [ 
                "liability-description",
                "financial-institution",
                "other-institution",
                "amount-owing",
                "to-be-paid-out",
                "amount-financed",
            ], 
            // {subtitle: "Account Info"}, 
            // [ 
            //     "loan-account-number",
            //     "interest-rate",
            //     "end-date",
            // ], 
        ],
        expenses: "11-18|11-20",
    },



    //  Liability 13-1
    // --Cards & Credit 13-2
    // ----Credit Card 13-3

    "13-3": {
        selector: [
            {field: "role-selector", label: "Select Role"},
        ],
        assets: "unavailable",
        liabilities: [
            {overline: "LIABILITY"}, 
            {title: "Credit Card"}, 
            [ 
                "financial-institution",
                "other-institution",
                "liability-description",
                "credit-limit",
                "amount-owing",
                "to-be-paid-out",
            ]
        ],
        expenses: "11-11",
    },


    //  Liability 13-1
    // --Cards & Credit 13-2
    // ----Fuel Card 13-4

    "13-4": {
        selector: [
            {field: "role-selector", label: "Select Role"},
        ],
        assets: "unavailable",
        liabilities: [
            {overline: "LIABILITY"}, 
            {title: "Fuel Card"}, 
            [ 
                "financial-institution",
                "other-institution",
                "liability-description",
                "credit-limit",
                "amount-owing",
                "to-be-paid-out",
            ]
        ],
        expenses: "11-12",
    },


    //  Liability 13-1
    // --Cards & Credit 13-2
    // ----Line of Credit - Unsecured 13-5

    "13-5": {
        selector: [
            {field: "role-selector", label: "Select Role"},
        ],
        assets: "unavailable",
        liabilities: [
            {overline: "LIABILITY"}, 
            {title: "Line of Credit"}, 
            [ 
                "financial-institution",
                "other-institution",
                "liability-description",
                "credit-limit",
                "amount-owing",
                "to-be-paid-out",
            ]
        ],
        expenses: "11-13",
    },


    //  Liability 13-1
    // --Cards & Credit 13-2
    // ----Overdraft 13-6

    "13-6": {
        selector: [
            {field: "role-selector", label: "Select Role"},
        ],
        assets: "unavailable",
        liabilities: [
            {overline: "LIABILITY"}, 
            {title: "Overdraft"}, 
            [ 
                "financial-institution",
                "other-institution",
                "liability-description",
                "credit-limit",
                "amount-owing",
                "to-be-paid-out",
            ]
        ],
        expenses: "11-14",
    },

    //  Liability 13-1
    // --Cards & Credit 13-2
    // ----Store Card 13-7

    "13-7": {
        selector: [
            {field: "role-selector", label: "Select Role"},
        ],
        assets: "unavailable",
        liabilities: [
            {overline: "LIABILITY"}, 
            {title: "Store Card"}, 
            [ 
                "financial-institution",
                "other-institution",
                "liability-description",
                "credit-limit",
                "amount-owing",
                "to-be-paid-out",
            ]
        ],
        expenses: "11-15",
    },


    
    //  Liability 13-1
    // --Loan - Other 13-12
    // ----Interest-Free (non-card) 13-13

    "13-13": {
        selector: [
            {field: "role-selector", label: "Select Role"},
        ],
        assets: "unavailable",
        liabilities: [
            {overline: "LIABILITY"}, 
            {title: "Interest-Free (non-card)"}, 
            [ 
                "liability-description",
                "financial-institution",
                "other-institution",
                "amount-owing",
                "to-be-paid-out",
                "amount-financed",
            ],
            // {subtitle: "Account Info"}, 
            // [ 
            //     "loan-account-number",
            //     "interest-rate",
            //     "end-date",
            // ], 
        ],
        expenses: "11-23",
    },

    
    //  Liability 13-1
    // --Loan - Other 13-12
    // ----Loan as Guarantor 13-14

    "13-14": {
        selector: [
            {field: "role-selector", label: "Select Role"},
        ],
        assets: "unavailable",
        liabilities: [
            {overline: "LIABILITY"}, 
            {title: "Loan as Guarantor"}, 
            [ 
                "liability-description",
                "financial-institution",
                "other-institution",
                "amount-owing",
                "to-be-paid-out",
                "amount-financed",
            ],
            // {subtitle: "Account Info"}, 
            // [ 
            //     "loan-account-number",
            //     "interest-rate",
            //     "end-date",
            // ], 
        ],
        expenses: "11-24",
    },

        
    //  Liability 13-1
    // --Loan - Other 13-12
    // ----Other Loan 13-15

    "13-15": {
        selector: [
            {field: "role-selector", label: "Select Role"},
        ],
        assets: "unavailable",
        liabilities: [
            {overline: "LIABILITY"}, 
            {title: "Other Loan"}, 
            [ 
                "description-other",
                "liability-description",
                "financial-institution",
                "other-institution",
                "amount-owing",
                "to-be-paid-out",
                "amount-financed",
            ],
            // {subtitle: "Account Info"}, 
            // [ 
            //     "loan-account-number",
            //     "interest-rate",
            //     "end-date",
            // ], 
        ],
        expenses: "11-25",
    },


            
    //  Liability 13-1
    // --Loan - Other 13-12
    // ----Pay-Day Loan 13-16

    "13-16": {
        selector: [
            {field: "role-selector", label: "Select Role"},
        ],
        assets: "unavailable",
        liabilities: [
            {overline: "LIABILITY"}, 
            {title: "Pay-Day Loan"}, 
            [ 
                "liability-description",
                "financial-institution",
                "other-institution",
                "amount-owing",
                "to-be-paid-out",
                "amount-financed",
            ],
            // {subtitle: "Account Info"}, 
            // [ 
            //     "loan-account-number",
            //     "interest-rate",
            //     "end-date",
            // ], 
        ],
        expenses: "11-26",
    },


            
    //  Liability 13-1
    // --Loan - Other 13-12
    // ----Unsecured Personal Loan 13-17

    "13-17": {
        selector: [
            {field: "role-selector", label: "Select Role"},
        ],
        assets: "unavailable",
        liabilities: [
            {overline: "LIABILITY"}, 
            {title: "Unsecured Personal Loan"}, 
            [ 
                "liability-description",
                "financial-institution",
                "other-institution",
                "amount-owing",
                "to-be-paid-out",
                "amount-financed",
            ],
            // {subtitle: "Account Info"}, 
            // [ 
            //     "loan-account-number",
            //     "interest-rate",
            //     "end-date",
            // ], 
        ],
        expenses: "11-27",
    },

            
    //  Liability 13-1
    // --Loan - Real Estate 13-18
    // ----Line of Credit - Secured 13-19
    // ----Mortgage - Investment 13-20

    "13-19|13-20": {
        selector: [
            {field: "role-selector", label: "Select Role"},
        ],
        incomes: "12-21",
        assets: "10-14|10-16",
        liabilities: [
            {overline: "LIABILITY"}, 
            {title: "Line of Credit - Secured", if: '13-19'}, 
            {title: "Mortgage - Investment", if: '13-20'}, 
            [ 
                "liability-description",
                "financial-institution",
                "other-institution",
                "amount-owing",
                "to-be-paid-out",
                "amount-financed",
            ],
            // {subtitle: "Account Info"}, 
            // [ 
            //     "loan-account-number",
            //     "mortgage-structure",
            //     "interest-rate",
            //     "end-date",
            // ], 
        ],
        expenses: "11-29|11-30",
    },


            
    //  Liability 13-1
    // --Other Liability 13-22
    // ----HECS-HELP 13-23

    "13-23": {
        selector: [
            {field: "role-selector", label: "Select Role"},
        ],
        assets: "unavailable",
        liabilities: [
            {overline: "LIABILITY"}, 
            {title: "HECS-HELP"}, 
            [ 
                "liability-description",
                "amount-owing",
                "to-be-paid-out",
            ],
            // {subtitle: "Account Info"}, 
            // [ 
            //     "end-date",
            // ], 
        ],
        expenses: "11-32",
    },

            
    //  Liability 13-1
    // --Other Liability 13-22
    // ----Other Liability 13-24

    "13-24": {
        selector: [
            {field: "role-selector", label: "Select Role"},
        ],
        assets: "unavailable",
        liabilities: [
            {overline: "LIABILITY"}, 
            {title: "Other Liability"}, 
            [ 
                "description-other",
                "liability-description",
                "amount-owing",
                "to-be-paid-out",
                "financial-institution",
                "other-institution",
            ],
            // {subtitle: "Account Info"}, 
            // [ 
            //     "end-date",
            // ], 
        ],
        expenses: "11-33",
    },

            
    //  Liability 13-1
    // --Other Liability 13-22
    // ----Outstanding Taxation 13-25

    "13-25": {
        selector: [
            {field: "role-selector", label: "Select Role"},
        ],
        assets: "unavailable",
        liabilities: [
            {overline: "LIABILITY"}, 
            {title: "Outstanding Taxation"}, 
            [ 
                "liability-description",
                "amount-owing",
                "to-be-paid-out",
            ],
            // {subtitle: "Account Info"}, 
            // [ 
            //     "end-date",
            // ], 
        ],
        expenses: "11-34",
    },

    //  Liability 13-1
    // --Other Liability 13-22
    // ----Buy Now Pay Later 13-26

    "13-26": {
        selector: [
            {field: "role-selector", label: "Select Role"},
        ],
        assets: "unavailable",
        liabilities: [
            {overline: "LIABILITY"}, 
            {title: "Buy Now Pay Later"}, 
            [ 
                "liability-description",
                "financial-institution",
                "other-institution",
                "amount-owing",
                "to-be-paid-out",
                "amount-financed",
            ],
        ],
        expenses: "11-35",
    },

    /**
     * ########################################################### EXPENSES 
     */



    //  Expense 11-1
    // --Accommodation Expense 11-2
    // ----Boarder 11-3
    // ----Employer Provided 11-4
    // ----Living With Parents 11-5
    // --Loan Payment - Asset Finance 11-17
    // X ----Equipment Finance 11-19 // Obsolete

    "11-3|11-4|11-5": {
        selector: [
            {field: "role-selector", label: "Select Role"},
        ],
        assets: "unavailable",
        liabilities: "unavailable",
        expenses: [
            {overline: "EXPENSE"}, 
            [ 
                "payment-amount",
                "payment-frequency",
            ], 
        ],
    },

    //  Expense 11-1
    // ----Renter - Agent 11-8
    // ----Renter - Private 11-9
    "11-8|11-9": {
        selector: [
            {field: "role-selector", label: "Select Role"},
        ],
        assets: "unavailable",
        liabilities: "unavailable",
        expenses: [
            {overline: "EXPENSE"}, 
            [ 
                "payment-amount",
                "payment-frequency",
                "__rental-address"
            ], 
        ],
    },

    //  Expense 11-1
    // --Accommodation Expense 11-2
    // ----Mortgage - Owner Occupied 11-6
    // ----Own Outright 11-7

    "11-6|11-7": {
        selector: [
            {field: "role-selector", label: "Select Role"},
        ],
        assets: "10-16",
        liabilities: "13-21",
        expenses: [
            {overline: "EXPENSE"}, 
            {title: "Mortgage - Owner Occupied", if: "11-6"},
            {title: "Own Outright", if: "11-7"},
            [ 
                "payment-amount",
                "payment-frequency",
                // "financial-institution",
                "other-institution"
            ], 
        ],
    },
  
 
    //  Expense 11-1
    // --Loan Payment - Asset Finance 11-17
    // ----Other Asset Loan 11-21

    "11-21": {
        selector: [
            {field: "role-selector", label: "Select Role"},
        ],
        assets: "10-35|10-36|10-37|10-38|10-44|10-45|10-46|10-47|10-48|10-49|10-19|10-20|10-21|10-22|10-23|10-24|10-25|10-28|10-29|10-26",
        liabilities: "13-11",
        expenses: [
            {overline: "EXPENSE"}, 
            {title: "Other Asset Loan"}, 
            [ 
                "payment-amount",
                "payment-frequency",
                // "financial-institution",
                "other-institution",
                // "to-be-paid-out"
            ], 
        ],
    },
  

    //  Expense 11-1
    // --Loan Payment - Asset Finance 11-17
    // ----Car Loan 11-18
    // ----Novated Lease 11-20

    "11-18|11-20": {
        selector: [
            {field: "role-selector", label: "Select Role"},
        ],
        assets: "10-33",
        liabilities: "13-9|13-10",
        expenses: [
            {overline: "EXPENSE"}, 
            {title: "Car Loan", if: "11-18"},
            {title: "Novated Lease", if: "11-20"},
            [ 
                "payment-amount",
                "payment-frequency",
                // "financial-institution",
                "other-institution",
                // "to-be-paid-out"
            ], 
        ],
    },
  

    //  Expense 11-1
    // --Card & Credit Payment 11-10
    // ----Credit Card 11-11


    "11-11": {
        selector: [
            {field: "role-selector", label: "Select Role"},
        ],
        assets: "unavailable",
        liabilities: "13-3",
        expenses: [
            {overline: "EXPENSE"}, 
            {title: "Credit Card"}, 
            [ 
                "payment-amount",
                "payment-frequency",
                // "financial-institution",
                "other-institution",
                // "to-be-paid-out",
            ], 
        ],
    },
  
    //  Expense 11-1
    // --Card & Credit Payment 11-10
    // ----Fuel Card 11-12

    "11-12": {
        selector: [
            {field: "role-selector", label: "Select Role"},
        ],
        assets: "unavailable",
        liabilities: "13-4",
        expenses: [
            {overline: "EXPENSE"}, 
            {title: "Fuel Card"}, 
            [ 
                "payment-amount",
                "payment-frequency",
                // "financial-institution",
                "other-institution",
                // "to-be-paid-out",
            ], 
        ],
    },

    //  Expense 11-1
    // --Card & Credit Payment 11-10
    // ----Line Of Credit - Unsecured 11-13

    "11-13": {
        selector: [
            {field: "role-selector", label: "Select Role"},
        ],
        assets: "unavailable",
        liabilities: "13-5",
        expenses: [
            {overline: "EXPENSE"}, 
            {title: "Line Of Credit"}, 
            [ 
                "payment-amount",
                "payment-frequency",
                // "financial-institution",
                "other-institution",
                // "to-be-paid-out",
            ], 
        ],
    },
  
    //  Expense 11-1
    // --Card & Credit Payment 11-10
    // ----Overdraft 11-14

    "11-14": {
        selector: [
            {field: "role-selector", label: "Select Role"},
        ],
        assets: "unavailable",
        liabilities: "13-6",
        expenses: [
            {overline: "EXPENSE"}, 
            {title: "Overdraft"}, 
            [ 
                "payment-amount",
                "payment-frequency",
                // "financial-institution",
                "other-institution",
                // "to-be-paid-out",
            ], 
        ],
    },
     
    //  Expense 11-1
    // --Card & Credit Payment 11-10
    // ----Store Card 11-15

    "11-15": {
        selector: [
            {field: "role-selector", label: "Select Role"},
        ],
        assets: "unavailable",
        liabilities: "13-7",
        expenses: [
            {overline: "EXPENSE"}, 
            {title: "Store Card"}, 
            [ 
                "payment-amount",
                "payment-frequency",
                // "financial-institution",
                "other-institution",
                // "to-be-paid-out",
            ], 
        ],
    },


    //  Expense 11-1
    // --Loan Payment - Other 11-22
    // ----Interest-Free Finance 11-23

    "11-23": {
        selector: [
            {field: "role-selector", label: "Select Role"},
        ],
        assets: "unavailable",
        liabilities: "13-13",
        expenses: [
            {overline: "EXPENSE"}, 
            {title: "Interest-Free Finance"}, 
            [ 
                "payment-amount",
                "payment-frequency",
                // "financial-institution",
                "other-institution",
                // "to-be-paid-out",
            ], 
        ],
    },



    //  Expense 11-1
    // --Loan Payment - Other 11-22
    // ----Loan as Guarantor 11-24

    "11-24": {
        selector: [
            {field: "role-selector", label: "Select Role"},
        ],
        assets: "unavailable",
        liabilities: "13-14",
        expenses: [
            {overline: "EXPENSE"}, 
            {title: "Loan as Guarantor"}, 
            [ 
                "payment-amount",
                "payment-frequency",
                // "financial-institution",
                "other-institution",
                // "to-be-paid-out",
            ], 
        ],
    },


    //  Expense 11-1
    // --Loan Payment - Other 11-22
    // ----Other Loan 11-25

    "11-25": {
        selector: [
            {field: "role-selector", label: "Select Role"},
        ],
        assets: "unavailable",
        liabilities: "13-15",
        expenses: [
            {overline: "EXPENSE"}, 
            {title: "Other Loan"}, 
            [ 
                "payment-amount",
                "payment-frequency",
                // "financial-institution",
                "other-institution",
                // "to-be-paid-out",
            ], 
        ],
    },


    //  Expense 11-1
    // --Loan Payment - Other 11-22
    // ----Pay-Day Loan 11-26

    "11-26": {
        selector: [
            {field: "role-selector", label: "Select Role"},
        ],
        assets: "unavailable",
        liabilities: "13-16",
        expenses: [
            {overline: "EXPENSE"}, 
            {title: "Pay-Day Loan"}, 
            [ 
                "payment-amount",
                "payment-frequency",
                // "financial-institution",
                "other-institution",
                // "to-be-paid-out",
            ], 
        ],
    },



    //  Expense 11-1
    // --Loan Payment - Other 11-22
    // ----Unsecured Personal Loan 11-27

    "11-27": {
        selector: [
            {field: "role-selector", label: "Select Role"},
        ],
        assets: "unavailable",
        liabilities: "13-17",
        expenses: [
            {overline: "EXPENSE"}, 
            {title: "Unsecured Personal Loan"}, 
            [ 
                "payment-amount",
                "payment-frequency",
                // "financial-institution",
                "other-institution",
                // "to-be-paid-out",
            ], 
        ],
    },

    //  Expense 11-1
    // --Loan Payment - Property Investment 11-28
    // ----Line of Credit - Secured 11-29
    // ----Mortgage - Investment 11-30

    "11-29|11-30": {
        selector: [
            {field: "role-selector", label: "Select Role"},
        ],
        incomes: "12-21", 
        assets: "10-14",
        liabilities: "13-19|13-20",
        expenses: [
            {overline: "EXPENSE"}, 
            {title: "Line of Credit - Secured", if: '11-29'}, 
            {title: "Mortgage - Investment", if: '11-30'}, 
            [ 
                "payment-amount",
                "payment-frequency",
                // "financial-institution",
                "other-institution",
                // "to-be-paid-out",
            ], 
        ],
    },


    //  Expense 11-1
    // --Other Expense 11-31
    // ----HECS-Help 11-32

    "11-32": {
        selector: [
            {field: "role-selector", label: "Select Role"},
        ],
        assets: "unavailable",
        liabilities: "13-23",
        expenses: [
            {overline: "EXPENSE"}, 
            {title: "HECS-Help"}, 
            [ 
                "payment-amount",
                "payment-frequency",
                // "to-be-paid-out",
            ], 
        ],
    },

    //  Expense 11-1
    // --Other Expense 11-31
    // ----Other Expense 11-33

    "11-33": {
        selector: [
            {field: "role-selector", label: "Select Role"},
        ],
        assets: "unavailable",
        liabilities: "13-24",
        expenses: [
            {overline: "EXPENSE"}, 
            {title: "Other Expense"}, 
            [ 
                "payment-amount",
                "payment-frequency",
                // "to-be-paid-out",
            ], 
        ],
    },

    //  Expense 11-1
    // --Other Expense 11-31
    // ----Outstanding Taxation 11-34

    "11-34": {
        selector: [
            {field: "role-selector", label: "Select Role"},
        ],
        assets: "unavailable",
        liabilities: "13-25",
        expenses: [
            {overline: "EXPENSE"}, 
            {title: "Outstanding Taxation"}, 
            [ 
                "payment-amount",
                "payment-frequency",
                // "to-be-paid-out",
            ], 
        ],
    },

    //  Expense 11-1
    // --Other Expense 11-31
    // ----Buy Now Pay Later 11-35

    "11-35": {
        selector: [
            {field: "role-selector", label: "Select Role"},
        ],
        assets: "unavailable",
        liabilities: "13-26",
        expenses: [
            {overline: "EXPENSE"}, 
            {title: "Buy Now Pay Later"}, 
            [ 
                "payment-amount",
                "payment-frequency",
                "other-institution",
            ], 
        ],
    },



    //  Expense 11-1
    // --Other Expense 11-31
    // ----Living Expenses 11-16

    "11-16": {
        selector: [
            {field: "role-selector", label: "Select Role"},
        ],
        expenses: [
            {overline: "EXPENSE"}, 
            [
                "payment-frequency",
                "payment-amount"
            ],
            [ 
                "living-food-and-clothing",
                "living-transport",
                "living-utilities-and-rates",
                "living-lifestyle",
                "living-tv-and-communications",
                "living-child-care-fees",
                "living-family-maintenance",
                "living-insurance",
                "living-education",
                "living-other-living-expenses",
            ], 
        ],
    },







}




