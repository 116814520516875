<template>
    <div>
        <v-dialog
            v-model="dialog"
            persistent
            width="500">
            
            <template v-slot:activator="{ on, attrs }">
                <p
                    v-if="labelType === 'label'"
                    dark
                    class="pt-4"
                    v-bind="attrs"
                    @click="getPeople"
                    v-on="on">
                    {{ label }}
                </p>
                <v-btn
                    v-else-if="labelType === 'fab'"
                    v-bind="attrs"
                    v-on="on"
                    :fab="labelType === 'fab'"
                    @click="saveData"
                    depressed>
                    <v-icon>{{icon}}</v-icon>
                </v-btn>
                <v-btn
                    v-else
                    large class="secondary"
                    v-bind="attrs"
                    v-on="on"
                    @click="saveData"
                    depressed>
                    {{ label }}
                </v-btn>
            </template>

            <v-card flat :disabled="saving">
                <v-progress-linear color="secondary" indeterminate stream absolute top v-if="saving"></v-progress-linear>

                <v-card-text class="pa-10">

                    <div class="text-h4 font-weight-bold mb-4">Invite Applicant to Edit</div>

                    <ValidationObserver ref="observer" tag="form">
                        <div class="flex-form">

                            <div class="flex-field">
                                <ValidationProvider 
                                    v-slot="{ errors }" rules="required" name="First Name" vid="first_name">
                                    <v-text-field
                                        v-model="form['first-name']"
                                        :error-messages="errors"
                                        label="First Name"></v-text-field>
                                </ValidationProvider>
                            </div>

                            <div class="flex-field">
                                <ValidationProvider 
                                    v-slot="{ errors }"  rules="required" name="Last Name" vid="last_name">
                                    <v-text-field
                                        v-model="form['last-name']"
                                        :error-messages="errors"
                                        label="Last Name"></v-text-field>
                                </ValidationProvider>
                            </div>

                            <div class="flex-field">
                                <ValidationProvider 
                                    v-slot="{ errors }" name="Email" rules="required|email" vid="email">
                                    <v-text-field
                                        v-model="form['email']"
                                        :error-messages="errors"
                                        label="Email"></v-text-field>
                                </ValidationProvider>
                            </div>

                        </div>
                    </ValidationObserver>

                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions
                    class="pa-6 px-md-10 py-6 flex-wrap controls">                
                    <v-btn
                        outlined
                        color="light_gray_2"
                        large
                        style="min-width:140px;"
                        class="flex-back-button"
                        @click="dialog = false">
                        Back
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="secondary"
                        depressed
                        large
                        style="min-width:140px;"
                        @click="save">
                        Submit
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <ConfirmModal 
            v-if="dialog2"
            async
            color="warning"
            title="Invitee not Found on Application"
            confirmText="Yes, Send Invite"
            :value="dialog2"
            :standalone="false"
            :processing="saving"
            :message="`The invitee email address ${peopleEmail} does not match the email address of any person in this finance application. Are you sure you wish to give access to this person?`"
            @close="handleClose"
            @confirm="handleConfirm" />
    </div>
</template>

<script>

    import { mapActions, mapGetters, mapState } from 'vuex'
    import formHelpers from '~/components/shared/form-helpers.js'
    import validation from '~/components/shared/validation'
    import ConfirmModal from '~/components/base/ConfirmModal2'
    import { isEmpty } from 'lodash'

    export default {
        name: 'InviteApplicantToEdit',

        mixins: [ validation, formHelpers ],

        components: {
            ConfirmModal
        },

        props: {
            label: {
                type: String,
                default: ''
            },

            labelType: {
                type: String,
                default: 'label' // label or button
            },
            
            icon: {
                type: String,
                default: 'mdi-arrow-left' // label or button
            },
            
            selectedItem: {
                type: Object,
                default: () => {}
            },

            applicant: {
                type: Object,
                default: () => {}
            },

            isFlow: {
                type: Boolean,
                default: false
            },

            isSnapshot: {
                type: Boolean,
                default: false
            },

            appSnapshotId: {
                type: String,
                default: null
            }
        },

        async mounted() {

            // if ( this.isFlow ) {
            //     // console.log('Should fetch API')
            // }
            // else {
            //     if ( !isEmpty(this.applicant) ) {
            //         await this.prePopulate()
            //     }
            // }

        },

        computed: {
            ...mapState({
                appId: state => state.flows.appId,
                appGuid: state => state.flows.appGuid,
                primaryApplicant: state => state.flows.primaryApplicant,
                tenant: state => state.slug.tenant,
                loanData: state => state.flows.loanData
            }),
            ...mapGetters('slug', [
                'getGroupSlug'
            ]),
            isCommercial(){
              if(this.loanData){
                  return  this.loanData.attributes['finance-for-business-purposes'] == 'Yes'
              }
              return false
            },
            getInviteAppLink() {
                let url = `${process.env.baseUrl}${this.$route.fullPath}`
                if ( this.getGroupSlug ) url = `${this.getGroupSlug}.${url}`
                return `${window.location.origin}/${this.getGroupSlug}/applications/invite-landing`
            },
            peopleEmail() {
                return this.form['email']
            }
        },

        methods: {
            ...mapActions('resource-actions', [
                'inviteApplicantToEdit',
                'getApplication',
                'updatePerson'
            ]),

            ...mapActions('flows', ['saveDataOnly']),

            async save() {

                this.saving = true
                const valid = await this.$refs.observer.validate()

                // check email of people's resources on this application
                let peopleExist = false

                if(!isEmpty(this.people)) {

                    this.people.forEach(p => {
                        if(p.attributes['email'] == this.form['email'])
                            peopleExist = true
                    })

                }

                if ( valid ) {

                    if (peopleExist) {
                        await this.saveInvite()
                    } else {
                        this.dialog2 = true
                    }

                    this.saving = false

                }

                this.saving = false       

            },

            async saveInvite() {

                this.saving = true

                if (this.appGuid) {
                    this.form.id = this.appGuid
                } else {
                    this.form.id = this.selectedItem.id
                }

                this.form.url = this.getInviteAppLink

                if(!this.isCommercial && this.person?.id) {
                    await this.updatePerson({
                        type: 'people',
                        id: this.person.id,
                        attributes: {
                            'first-name': this.form['first-name'],
                            'last-name': this.form['last-name'],
                            'email': this.form['email']
                        }
                    })
                }

                let res = await this.inviteApplicantToEdit(this.form)

                if ( res.status === 200 ) {
                    this.$store.commit(
                        'utils/toggleQuickMessage',  
                        {
                            text: 'Successfully send an invitation!', 
                            color: 'success'
                        }
                    )
                    this.saving = false
                    this.dialog = false
                }
                else {
                    this.$store.commit(
                        'utils/toggleQuickMessage',  
                        {
                            text: 'Something went wrong! Please try again...', 
                            color: 'error'
                        }
                    )
                    this.saving = false
                } 

            },

            async saveData() {

                this.saving = true

                // save data
                if(!this.isSnapshot)
                    await this.saveDataOnly()

                // get primary applicant
                await this.getPrimaryApplicant()

                this.saving = false

            },

            async handleConfirm() {
                this.saving = true
                await this.saveInvite()
            },

            handleClose() {
                this.dialog2 = false
            },

            async prePopulate(isFlow = false) {
                
                if ( !isFlow ) {
                    this.form = {
                        'first-name': this.applicant?.['first-name'],
                        'last-name': this.applicant?.['last-name'],
                        'email': this.applicant?.['email']
                    }
                }
                else {
                    if (isEmpty(this.primaryApplicant)) {
                        
                        let res = await this.getApplication({
                            id: this.isSnapshot ? this.appSnapshotId : this.appId, 
                            params: {
                                include: 'people,parts'
                            }
                        })
                        let priority_part = res.data.included.find( pp => {
                            return pp.type === 'parts' && pp.attributes.priority === 1
                        })
                        let person = res.data.included.find(p => {
                            return p.id === priority_part.relationships.child.data.id
                        })
                        this.form = {
                            'first-name': person?.attributes?.['first-name'] ? person?.attributes?.['first-name'] : '',
                            'last-name': person?.attributes?.['last-name'] ? person?.attributes?.['last-name'] : '',
                            'email': person?.attributes?.['email'] ? person?.attributes?.['email'] : ''
                        }
                    }
                    else {
                        this.form = {
                            'first-name': this.primaryApplicant?.attributes?.['first-name'],
                            'last-name': this.primaryApplicant?.attributes?.['last-name'],
                            'email': this.primaryApplicant?.attributes?.['email']
                        }
                    }
                }
            },
            async getPrimaryApplicant() {

                let res = await this.getApplication({
                    id: this.isSnapshot ? this.appSnapshotId : this.appId,
                    params: {
                        include: 'people,parts'
                    }
                })

                this.people = res.data.included.filter( person => {
                    return person.type === 'people'
                })

                let priority_part = res.data.included.find( pp => {
                    return pp.type === 'parts' && pp.attributes.priority === 1
                })

                let person = res.data.included.find(p => {
                    return p.id === priority_part.relationships.child.data.id
                })

                this.person = person

                this.form = {
                    'first-name': person?.attributes?.['first-name'] ? person?.attributes?.['first-name'] : '',
                    'last-name': person?.attributes?.['last-name'] ? person?.attributes?.['last-name'] : '',
                    'email': person?.attributes?.['email'] ? person?.attributes?.['email'] : ''
                }

            },
            async getPeople() {
                // get people in specific application
                if (this.selectedItem?.id) {

                    let res = await this.getApplication({
                        id: this.selectedItem.id, 
                        params: {
                            include: 'people,parts'
                        }
                    })

                    this.people = res.data.included.filter( person => {
                        return person.type === 'people'
                    })

                }
                
            }
        },

        watch: {
            dialog: {
                async handler(val) {
                    if ( !val ) {
                        this.form['first-name'] = '',
                        this.form['last-name'] = '',
                        this.form['email'] = ''
                        await this.$refs.observer.reset()
                    }
                    else {
                        await this.prePopulate(this.isFlow)
                    }
                },
                deep: true
            }
        },

        data() {
            return {
                saving: false,
                dialog: false,
                dialog2: false,
                people: null,
                person: null,
                form: {
                    'first-name': '',
                    'last-name': '',
                    'email': ''
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
    .flex-form {
        display: grid;
        grid-column-gap: 15px;
        grid-row-gap: 0;
        grid-template-columns:  repeat(auto-fit, minmax(260px, 1fr));

        .flex-field {
            align-self: start;
        }
    }
</style>