import { mapState, mapMutations, mapActions, mapGetters } from 'vuex';

/**
 * 
 * More Mixins
 * Include your mixins here
 * 
 */
import block from '~/components/shared/block' 
import spaces from '~/components/shared/spaces' 
import validation from '~/components/shared/validation' 
import formHelpers from '~/components/shared/form-helpers.js'
import requirementChecklist from '~/components/shared/requirement-checklist.js'
import helpers from '~/plugins/helpers.js'
import RequirementChecklist1 from '~/components/base/RequirementChecklist1/RequirementChecklist1'

export default {  
    mixins: [
        block, 
        spaces, 
        validation, 
        formHelpers,
        requirementChecklist,
        helpers
    ],
    beforeCreate() {
        window.scrollTo(0, 0)
    },
    components: {
        RequirementChecklist1
    },
    data(){
        return {
            validation_rules: {}  
        }
    },
    async mounted() {

        /**
         * REQUIRED! 
         * 
         * Every component should have this condition.
         * This will register PUSH to store and trigger PULL 
         * if the component has stepable subscription.
         * 
         */
        if( this.block?.subscriptions?.includes('Stepable') ){

            // if the stepable component has read-only permission, set validation to false.
            if(this.blockPermissionsReadOnly) {
                this.setValidation(false)
            }


            this.setGlobalMode('disabled')

            // Reset touched and observer states every step change
            this.setFormFieldsObserverName('')
            this.toggleTouched(false)


            await this.pull();  


            this.setGlobalMode('enabled')

            /**
             * 
             *  Globalize Stepable's push method
             *  so it can be called anywhere using store's stepablePush()
             *  e.g  StepIncrementer1, StepSelector1
             * 
             */
            this.setStepablePush(this.push)
        }

        if(this.getSimpleKYCApplicationCreated) {
            this.toggleQuickMessage({
                text: 'Business information has been pulled into the role tree.', 
                color: 'green',
                timer: 10000,
            })
            this.setSimpleKYCApplicationCreated(false)
        }


    },
    computed: {
        ...mapState({

            auth: state => state.auth,

            // Flow Data
            appData: state => state.flows.appData,
            loanData: state => state.flows.loanData,
            appDataWatcher: state => state.flows.appDataWatcher,
            appId: state => state.flows.appId,
            appGuid: state => state.flows.appGuid,
            flowId: state => state.flows.flowId,
            navableMode: state => state?.subscriptions?.navableMode,
            stepableMode: state => state?.subscriptions?.stepableMode,
            hardValidation: state => state?.subscriptions?.hardValidation,
            flowSlug: state => state.slug.flowSlug,

        }),
        ...mapGetters('statuses', ['getActiveStepStatus']),
        ...mapGetters('dialogs', ['getFormFieldsObserveTrigger', 'getFormFieldsObserverName']),
        ...mapGetters('auth-actions', ['getAppOwnerSettings', 'getUserSetting']),
        ...mapGetters('utils', ['getSimpleKYCApplicationCreated']),
        /**
         * isDisabled will only work if component subscription is Navable or Stepable
         */
        isDisabled(){
            
            if( this.block?.subscriptions?.includes('Stepable') ){
                return this.stepableMode == 'disabled'
            }

            if( this.block?.subscriptions?.includes('Navable') ){
                return this.navableMode == 'disabled'
            }

            return false
        },
        vb(){
            return this.$vuetify.breakpoint
        },
        /**
         * Checker if the authenticated user can submit an application
         * @returns Boolean
         */
        authUserCanSubmitApp(){

            if(this.getUserSetting && this.getUserSetting.b2b == 1) {
                
                let appOwnerData = this.appData?.relationships?.owner?.data

                // I own the app!
                if(appOwnerData && this.auth && appOwnerData.id == this.auth.user.id){

                    // console.log("can-submit-app-own", this.getUserSetting["can-submit-app-own"])

                    if(this.getUserSetting["can-submit-app-own"] == 1 && this.getAppOwnerSettings["can-submit-app-own"] == 1) {
                        return true
                    }

                    return false

                // I dont own the app!
                } else {

                    // console.log("can-submit-app-others", this.getUserSetting["can-submit-app-others"])

                    if(this.getUserSetting["can-submit-app-others"] == 1 && this.getAppOwnerSettings["can-submit-app-own"] == 1) {
                        return true
                    }

                    return false

                }

            }

            return true
        }
    },
    methods: {
        ...mapMutations('subscriptions', [
            'setStepablePush',
            'setGlobalMode',
            'setValidation',
            'setStepableDataWorkable'
        ]),
        ...mapMutations('flows', [
            'updateAppData',
        ]),
        ...mapMutations('requirements-checklist', [
            'setRequirementChecklist',
        ]),
        ...mapMutations('dialogs', [
            'toggleTouched',
            'setFormFieldsObserverName',
        ]),
        ...mapMutations('utils', [
            'closeAlert',
            'toggleAlert',
            'toggleQuickMessage',
        ]),
        ...mapActions('statuses', ['updateStepStatus']),
        ...mapActions('dialogs', ['toggleFormFieldsObserver']),
        ...mapMutations('utils', ['setSimpleKYCApplicationCreated']),

        /**
         *  Override this by adding push method to the component.
         *  
         */
        async push(){
            
            return {valid:true, status:'pristine', data:{}}
        
        },
        /**
         *  Override this by adding pull method to the component.
         */
        async pull(){

        /**
         * Do stuff like requests for remote data, etc..
         */

        },

        async validateFormData(){
            const valid = await this.$refs.observer.validate();
            let status = this.getActiveStepStatus?.attributes?.value 
            const touched = this.$refs.observer.flags.touched
                
            if(this.hardValidation) {

                status =  valid ? 'valid' : 'dirty'

            } else {
                /**
                 * NOTE: uncomment if condition is hardValidation == true
                 */
                if(touched) {
                    status =  valid ? 'valid' : 'dirty'
                }
            }

            return {valid, status} 

        },
        async appDataHelper(args){

            try {

                return await Promise.all(args).then(res => {
                    return this.$options.filters.toAppDataFormat(res)
                })

            } catch (error) {

                console.log('Ooops!..', error)

                return {error}
            }

        },
        /**
         * Step States Management
         */


        /**
         * Overwride onAppDataChange in your appable component 
         * to trigger updates 
         */
        onAppDataChange(){

            console.log('onAppDataChange from : ', this.$options._componentTag)

        }, 
        showComponentWarning(){

            this.toggleAlert({
                text: 'Data incompatible or minimum required data not found', 
                type: 'error',
                prominent: true,
                dismissible: false
            })

            this.setStepableDataWorkable(false)
        },

        // this parser is for sub component listeners
        flowConfigParser(){

            let parsedConfig = {}

            Object.keys(this.blockSettings).forEach(block => {
                if(block == 'subComponents') {

                    let stepsConfig = this.blockSettings[block]

                    if(typeof stepsConfig == 'string'){
                        stepsConfig = JSON.parse(stepsConfig)
                    }

                    stepsConfig.forEach(value => {

                        // if listeners is present in the config
                        if(value['listeners']) {

                            value['listeners'].forEach(listener => {

                                // check for the method, arguments and url
                                if(listener['method'] && listener['method']['arguments']) {

                                    // split the url
                                    let parseArguments = listener['method']['arguments']

                                    if(typeof parseArguments == 'string'){
                                        stepsConfig = JSON.parse(listener['method']['arguments'])
                                    }
                                    
                                    parsedConfig[value.name] = parseArguments['url']

                                }

                            })

                        }

                    })

                }
            })

            return parsedConfig
        },

    },
    watch: {
        /**
         *  Watch changes in the application data
         *  
         */
        appDataWatcher() {

            if( this.block?.subscriptions?.includes('Appable') ){
                
                this.onAppDataChange()
        
            }

        },
        /**
         * This will trigger Veevalidate's form observer when next button is clicked
         */
        getFormFieldsObserveTrigger(value) {

            if( this.block?.subscriptions?.includes('Stepable') ){

                const name = this.getFormFieldsObserverName
                const observer = this.$refs[name]

                let count = []
                
                if(observer && observer.fields) {
    
                    Object.keys(observer.fields).forEach( key => {
                        if(observer.fields[key]['touched']) {
                            count.push(key)
                        }
                    })
    
                }
                
                if(count.length > 0) {
                    this.toggleTouched(true)
                }

            }

        }

    },
    /**
     * Do cleanup, restart states, etc...
     */
    beforeDestroy(){

        if( this.block?.subscriptions?.includes('Stepable') ){

            // Close invalid component data alert
            this.closeAlert()

            // Reset isStepableDataWorkable to true
            this.setStepableDataWorkable(true)

        }

    }
}