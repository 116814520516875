<template>

    <BaseLayout1 :spaces="spaces" />

</template>

<script>

    import { mapMutations, mapActions, mapGetters, mapState } from 'vuex';
    import BaseLayout1 from '~/components/layouts/BaseLayout1'
    import meta from '~/layouts/meta'
    import b2bSpaces from '~/layouts/spaces/b2b'
    import b2cSpaces from '~/layouts/spaces/b2c'

    export default {
        errorCaptured(err, vm, info) {
            console.error(`${err.toString()}\nInfo: ${info}\nComponent: ${vm?.$options?._componentTag}`);
            return false;
        },
        mixins: [
            meta
        ],
        components: {
            BaseLayout1,
        },
        data () {
          return {
            
          }
        },
        computed:{
            ...mapGetters('auth-actions', ['getUserSetting']),
            spaces() {

              return this.getUserSetting?.b2b == 1 ? b2bSpaces : b2cSpaces

            }
        },
        created() {

        },
        mounted(){

        },
        methods: {

        }
    }
</script>


<style lang="scss">

</style>
