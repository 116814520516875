<template>
    <v-dialog
        v-model="dialog"
        width="700"
        scrollable
        persistent>   
        <v-card flat class="ol-residential-history-5-is-currently-living">
            <v-card-title class="pa-0 ma-0 pr-5 pt-3 mb-n5">
                <v-spacer></v-spacer>
                <v-btn icon rounded small @click="close" depressed>
                    <v-icon color="primary" size="20">mdi-close</v-icon>
                </v-btn>
            </v-card-title>
            <v-card-title class="px-8 py-4 text-center">
                <div class="sm pa-0 text-center" style="width:100%">
                   {{title}}
                </div>
            </v-card-title>
            <v-card-text  class="px-16 mt-n4">
                <ValidationObserver class="mx-auto" :style="vb.mdAndUp ? 'width: 50%;' : ''" ref="observer1" tag="form">
                    <v-row  >
                        <v-col class="pa-0">
                            <ValidationProvider v-slot="{ errors }" rules="required" name="Property Value">
                                <v-text-field inputmode="decimal" type="number" :error-messages="errors" prefix="$" v-model="form['asset-value']"></v-text-field>
                            </ValidationProvider>
                        </v-col>
                    </v-row>
               </ValidationObserver>
                
            </v-card-text>
            <v-card-text class="d-flex justify-center pb-8" v-if="actionType == 'edit'">
                <v-btn class="px-10 mx-4 secondary" :loading="isSaving" width="140" height="50" depressed large @click="update ">Update</v-btn>
            </v-card-text>
            <v-card-text class="d-flex justify-center pb-8" v-else>
                <v-btn class="px-10 " width="140" height="50" depressed large @click="back">Back</v-btn>
                <v-btn class="px-10 mx-4 secondary" width="140" height="50" depressed large @click="nextStep">Next</v-btn>
            </v-card-text>
        </v-card>

    </v-dialog>
</template>
<script>
import {store,mutations} from './__store'
import shared from './__shared'
import fieldOptions from '~/components/shared/field-options'
import formHelpers from '~/components/shared/form-helpers.js'
import validation from '~/components/shared/validation'
import { mapState, mapActions, mapGetters } from 'vuex'
export default {
    props : {
        title: {
            type: String,
            default: 'Title'
        },
        step: {
            type: String,
            default:''
        },
        address: {
            type:Object,
            default: () => {}
        },
        actionType: {
            type:String,
            default: ''
        }
    },
    mixins: [ formHelpers, validation, shared ],
    watch: {
        "step": {
            handler (val) {
                val == 'asset-value' ? this.dialog = true : this.dialog = false
            }
        },
        "address": {
            handler (val) {
                if (val.hasOwnProperty('occupancy')){
                    this.form['residential-situation'] = val.occupancy.attributes['residential-situation']
                } else {
                    this.form['residential-situation'] = ""
                }
            }
        },
        'actionType': {
            handler (val) {
                return val
            }
        }
    },
    methods: {
        ...mapActions('resource-actions',['updateOccupancy']),
        async nextStep(data) {
           let valid = await this.$refs.observer1.validate();
           if(valid){
                let payload = {
                    'asset-value' : this.form['asset-value']
                }
                mutations.setAssetAttributes(payload)
                this.$emit('step','mortgage')
           }
            
        },
        back() {
            this.$emit('back','property-owner')
        },
        close(){
           this.$emit('back',null)
        },
        async update(){
            this.isSaving = true
            let valid = await this.$refs.observer1.validate()
            if (valid) {
                this.resources.occupancies.attributes['residential-situation'] = this.form['residential-situation']
                this.resources.occupancies.id =  this.address.occupancy.id
                this.resources.occupancies.relationships.address.data.id = this.address.occupancy.relationships.address.data.id
                this.resources.occupancies.relationships.person.data.id = this.address.occupancy.relationships.person.data.id
                this.resources.occupancies.relationships.application.data.id = this.address.occupancy.relationships.application.data.id
                await this.updateOccupancy(this.resources.occupancies)
                this.address.occupancy.attributes['residential-situation'] = this.form['residential-situation']
                this.$emit('updateTable',this.address)
                this.isSaving = false
                this.$emit('back',0)

            }
           
        }
        
    },
    data() {
        return {
            isSaving: false,
            dialog : false,
            fields : [
                
            ],
            form:{
                "asset-value": ''
            },
            items: fieldOptions['residential-situation'],
            resources: {
                addresses: {
                    type: 'addresses',
                        attributes: {
                            "street-number": null,
                            "street-name": null,
                            "street-type": null,
                            "suburb": null,
                            "state": null,
                            "postcode": null
                        },
                        relationships: {
                            application: {
                                data: { type: "applications", id: null } 
                            }
                        }
                    },
                    occupancies: {
                        type: 'occupancies',
                        attributes: {
                            "residential-situation": null,
                        },
                        relationships: {
                            application: {
                                data: { type: "applications", id:  null} 
                            },
                            address: {
                                data: {
                                    type: "addresses",
                                    id: null
                                }
                            },
                            person: {
                                data: {
                                    type: "people",
                                    id: null
                                }
                            }
                        }
                    },
            }
        }
    }
}
</script>
<style lang="scss" >
    
    .ol-residential-history-5-is-currently-living {
        .btn {
            text-align: center;
            padding: 50px;
            border-radius: 20px;
            font-size: 15px;
            border: 1px solid;
            cursor: pointer;
            width: 250px !important;
            height: 150px !important;
        }
        .btn:hover{
            background: #E7FAFC;
        }
        .custom-outline1{
            border-color: var(--v-secondary-base);
        }
        .close-icon {
            position: absolute;
            right: 40px;
            top: 30px;
            // background: var(--v-secondary-lighten2);
            color: var(--v-secondary-base)!important;
            padding: 6px;
            border-radius: 36px !important;
            i {
                color: var(--v-secondary-base) !important;
            }
        }
    }
</style>