import Auth from './auth'

import './middleware'

// Active schemes
import scheme_003d9a64 from './schemes/local.js'

export default function (ctx, inject) {
  // Options
  const options = {"resetOnError":false,"scopeKey":"scope","rewriteRedirects":true,"fullPathRedirect":false,"watchLoggedIn":true,"redirect":{"login":"/login","logout":false,"home":false,"callback":false},"vuex":{"namespace":"auth"},"cookie":{"prefix":"auth.","options":{"path":"/","expires":7,"sameSite":"None","secure":true}},"localStorage":false,"token":{"prefix":"_token."},"refresh_token":{"prefix":"_refresh_token."},"defaultStrategy":"local"}

  // Create a new Auth instance
  const $auth = new Auth(ctx, options)

  // Register strategies
  // local
  $auth.registerStrategy('local', new scheme_003d9a64($auth, {"endpoints":{"login":{"url":"/oauth/token","method":"post","propertyName":"token","headers":{"Accept":"*/*","Content-Type":"application/x-www-form-urlencoded"}},"logout":false,"user":false},"autoFetchUser":false,"tokenName":"Authorization","tokenType":"Bearer","globalToken":true,"_name":"local"}))

  // Inject it to nuxt context as $auth
  inject('auth', $auth)
  ctx.$auth = $auth

  // Initialize auth
  return $auth.init().catch(error => {
    if (process.client) {
      console.error('[ERROR] [AUTH]', error)
    }
  })
}
