<template>
    <v-dialog
        v-model="dialog"
        width="600"
        persistent>
        <v-card>

            <v-progress-linear 
                v-if="loader"
                color="secondary"
                indeterminate stream top 
                value="0">
            </v-progress-linear>

            <v-overlay
                absolute
                :opacity="0.1"
                v-if="loader">
            </v-overlay>
            
            <v-card-title>
                <v-subheader></v-subheader>
                <v-spacer />
                <v-btn icon @click="close" >
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-card-title>

            <v-card-actions>
                    
                <v-spacer></v-spacer>
                <BidLenderSelectionButton
                    @validated-data="saveData"
                    select-type="primary"
                    :lender="selectedLender"
                    :loan="loanResource"
                    :has-data="hasPrimary"
                    :disabled="isUserSelected"
                    label="Set Primary"                        
                    title="Set Primary Lender" />
                <BidLenderSelectionButton
                    @validated-data="saveData"
                    select-type="alternative"
                    :lender="selectedLender"
                    :loan="loanResource"
                    :has-data="hasAlternative"
                    :disabled="isUserSelected"
                    label="Set Alternative"
                    title="Set Alternative Lender" />
                <v-spacer></v-spacer>

            </v-card-actions>

            <v-card-text class="px-10">
                <SummaryDetails
                    :selected-lender="selectedLender"
                    :loan-resource="loanResource" />
            </v-card-text>

        </v-card>
    </v-dialog>
</template>

<script>

    import BidLenderSelectionButton from './_BidLenderSelection'
    import SummaryDetails from './_BidQuoteSummaryDetails'
    import shared from './__shared'

    export default {
        name: 'QuotableSummaryDialog',

        mixins: [ shared ],

        props: {
            dialog: {
                type: Boolean,
                default: false
            },
            selectedLender: {
                type: Object,
                default: () => {}
            },
            userSelectedLenders: {
                type: Object,
                default: () => {}
            },
            loanResource: {
                type: Object,
                default: () => {}
            },
            bidResource: {
                type: Object,
                default: () => {}
            }
        },

        components: { 
            BidLenderSelectionButton,
            SummaryDetails
        },

        computed: {
            
        },

        methods: {
            
            close() {
                this.$emit('close', true)
            },

            async saveData(data) {

                this.loader = true

                await this.save(data)

                this.$emit('close', true)
                this.loader = false

            }
            
        },

        watch: {
            selectedLender(newValue) {                
                this.selected_lender = ''                
            }
        },

        data() {
            return {
                selected_lender: '',
                resources: null,
                loader: false
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>