<template>
    <div :style="gridStyles" class="grid-area-container mt-16 pt-6 px-6">

        <template v-for="column in this.gridLayoutConverter(getActiveGrid)">
            <div :class="`grid-area grid-area-${column.area}`" :key="column.area">

                <component  
                    v-if="column.block"
                    :block="column.block" 
                    :is="column.block.component.name" 
                    :class="`flex__component-${column.block.component.name}`" 
                    :key="column.block.id" 
                    :leftDrawer="leftDrawer"
                    :rightDrawer="rightDrawer"
                    :leftDrawerVariant="leftDrawerVariant" 
                    :rightDrawerVariant="rightDrawerVariant" 
                    @onToggleLeftDrawer="handleLeftDrawer"
                    @onToggleRightDrawer="handleRightDrawer"
                    @onToggleVariant="handleVariant"
                />

            </div>
        </template>

    </div>
</template>

<script>
    import baseComponents from '~/components/base'
    import v1Components from '~/components/v1'

    export default {
        components: {
            ...baseComponents,
            ...v1Components,
        },
        props: {
            space: {
                type: Object,
                default: () => ({})
            },
            leftDrawer: {
                type: Boolean,
                default: false
            },
            rightDrawer: {
                type: Boolean,
                default: false
            },
            leftDrawerVariant: {
                type: Boolean,
                default: false
            },
            rightDrawerVariant: {
                type: Boolean,
                default: false
            }
        },
        data () {
              return {
 
              }
        },
        computed:{
            breakpoint(){

                const { xl, lg, md, sm, xs} = this.$vuetify.breakpoint

                if(xl) return 'xl';
                if(lg) return 'lg';
                if(md) return 'md';
                if(sm) return 'sm';
                if(xs) return 'xs';

            },
            blocks(){
                return this.space['blocks']
            },
            grids(){

                if(this.space['grid-template-areas']['grids']){
                    return JSON.parse(this.space['grid-template-areas']['grids'])
                }

                return []

            },
            mapping(){

                if(this.space['grid-template-areas']['mapping']){
                    return JSON.parse(this.space['grid-template-areas']['mapping'])
                }

                return []

            },
            getActiveGrid(){

                let sizes = [ 'xs', 'sm', 'md', 'lg', 'xl']

                const {grids, breakpoint} = this
                
                let gridObj = {}

                let sections = []

                // Convert grids into managable Object

                sizes.forEach(size => {
                    grids.forEach(grid => {
                        if(size == grid.breakpoint){

                            gridObj[size] = grid

                            let layout = grid.matrix.join(' ').split(' ').filter(item => item != '.')
                            
                            sections = [...sections, ...layout]

                        }
                    })
                })

                // Add default to "xs" if not in the config

                if(!gridObj['xs']){

                    sections = [...new Set(sections)]

                    gridObj['xs'] = {
                        breakpoint: "xs",
                        layout: sections 
                    }
                }

                let key =  "xs"

                sizes.forEach(size => {

                    if( !gridObj[size] ){

                       gridObj[size] = gridObj[key]
                        
                    } else {

                        key = size
                    }

                })

                return gridObj[breakpoint]['matrix']

            },
            gridStyles(){

                const {getActiveGrid} = this

                let areas = ''
                let columns = ''

                getActiveGrid.forEach(row => {
                    areas += `"${row}" 
                    `
                })

                let row = getActiveGrid[0] || ""
                let cols = row.split(' ')

                cols.forEach(col => {
                    columns += ( 100 / cols.length)  + '% '
                })
                
                return {
                    "grid-template-columns": columns ,
                    "grid-template-areas": areas
                }
            }
        },
        created() {


        },
        mounted(){

        },
        methods: {
            gridLayoutConverter(layout){
            
                let data = {}
                let collection = []

                layout.forEach((val1, key1) => {
                    
                    let cols = val1.split(' ')
                    let raw = {}
                    let divisor = (12 / cols.length); // do we need this?
                    
                    cols.forEach(function(val2, key2) { 
                            
                    if(val2 == '.'){
                        
                        // spaces...
                        // raw[`s-${key1}`] = (raw[`s-${key1}`] || 0) + 1;

                    } else {
                        
                        raw[val2] = (raw[val2] || 0) + 1;
                        
                    }
                    
                    });

                    collection.push(raw)
                    
                })
                
                collection.forEach((val1, key1) => {

                    Object.keys(val1).forEach(val2 => {

                        if(!data[val2]) {

                        data[val2] = {
                            "hspan": val1[val2],
                            "vspan": 1,
                        }

                        } else {

                        data[val2]["vspan"] = data[val2]["vspan"] + 1;

                        }

                    })
                })

                let component = {}

                // get component
                Object.keys(this.mapping).forEach(item => {
                    this.blocks.forEach(block => {
                        if(block?.component?.name == this.mapping[item])
                            component[item] = block
                    })
                })

                let final = []

                Object.keys(data).forEach(item => {
                    let obj = {
                    area: item,
                    ...data[item],
                    block: component[item]
                    }
                    final.push( obj )
                })

                return final
            },
            getDefautLayout() {

                let layout = []

                this.grids.forEach(grid => {

                    grid.matrix.forEach(lay => {

                        const vspan = lay.split(' ')

                        vspan.forEach(span => {
                            if (span != '.')
                                layout.push(span)
                        })

                    })
                    
                })

                return layout.filter((x, i, a) => a.indexOf(x) === i)

            },
            handleLeftDrawer() {
                this.$emit('onToggleLeftDrawer')
            },
            handleRightDrawer() {
                this.$emit('onToggleRightDrawer')
            },
            handleVariant() {
                this.$emit('onToggleVariant')
            }
        },
        watch: {

        }
    }
</script>


<style lang="scss">

    .grid-area-container {
        // justify-items: stretch;
        display: grid;
        // gap: 20px;
        grid-template-rows: auto;

        $areas: a,b,c,d,e,f,g,h,i,j,k,l,m,n,o,p,q,r,s,t,u,v,w,x,y,z;

        @for $i from 1 through length($areas) {

            .grid-area-#{nth($areas, $i)} {
                grid-area: nth($areas, $i)
            }

        }

        .grid-area {
            // border: 1px solid red;
            // align-self: stretch;
        }

    }

</style>
