<template>
    <v-dialog
        persistent
        scrollable
        max-width="600"
        v-model="dialog">

        <v-card flat tile :disabled="saving" class="pa-4">

            <v-progress-linear color="secondary" indeterminate stream absolute top v-if="saving"></v-progress-linear>

            <v-card-title class="mx-auto">
                <div class="v-card__title pa-0">
                    <div class="text-h4 font-weight-bold">Submit Application to</div>
                </div>
                <v-spacer />
            </v-card-title>

            <v-card-text class="px-8 pt-4" >
                <v-row class="py-4 justify-space-between">
                    <v-col v-if="hasAssisterSubmit" cols="12" class="text-center">
                        <v-card
                            outlined 
                            class="rounded-lg pa-2 mx-auto d-flex align-center"
                            min-height="155"
                            max-width="350"
                            @click="submitToAssister">
                            <v-card-text>
                                <AssisterImage/>
                            </v-card-text>
                        </v-card>
                    </v-col>

                    <v-col v-if="!isNodifiCoPilot && hasLenderFlow && selectedPrimaryLender" cols="12" class="text-center">
                        <v-card
                            outlined 
                            class="rounded-lg pa-2 mx-auto d-flex align-center"
                            min-height="155"
                            max-width="350"
                            @click="submitToLender()">
                                <v-card-text>
                                    <v-img 
                                    :src="require(`~/assets/images/logo/cropped/${selectedPrimaryLender}.png`)"
                                    position="center center" 
                                    contain
                                    class="mx-auto"
                                    max-height="100"
                                    max-width="150"/>
                                </v-card-text>
                        </v-card>
                    </v-col>

                    <v-col v-if="!isNodifiCoPilot && !hasLenderFlow && canForceSubmitToLender" cols="12" class="text-center">
                        <v-card
                            outlined 
                            class="rounded-lg pa-2 mx-auto d-flex flex-column align-center"
                            min-height="155"
                            max-width="350"
                            @click="forceSubmitToLender()">
                                <v-card-text>
                                    <v-img 
                                    :src="require(`~/assets/images/logo/cropped/${getTransitionToSubmittedImage}.png`)"
                                    position="center center" 
                                    contain
                                    class="mx-auto"
                                    max-height="100"
                                    max-width="150"/>
                                </v-card-text>
                                <v-card-actions>
                                    <div class="body-2">
                                        <div class="font-weight-bold body-1">Transition to Submitted</div>
                                        <div>I will submit this application in the lender system directly</div>
                                    </div>
                                </v-card-actions>
                        </v-card>
                    </v-col>

                    <v-col v-if="enableOtherSubmit" cols="12" class="text-center">
                        <v-card
                            outlined 
                            class="rounded-lg pa-2 mx-auto"
                            min-height="155"
                            max-width="350"
                            @click="$emit('on-other')">
                                <v-card-title class="justify-center mt-6">
                                    Other
                            </v-card-title>
                        </v-card>
                    </v-col>
                </v-row>
            </v-card-text>

            <v-card-actions class="px-8 pt-4 pb-6">
                <v-btn @click="closeModal" :min-width="140" large depressed outlined color="light_gray_2">
                    Back
                </v-btn>
            </v-card-actions>

        </v-card>

    </v-dialog>
</template>
<script>
import shared from './__shared'
import { mapGetters, mapMutations, mapActions, mapState } from 'vuex';
import AssisterImage from '~/components/base/AssisterImage'

export default {
    mixins: [ shared ],
    components: {
        AssisterImage
    },
    props: {
        dialog: {
            type: Boolean,
            default: true
        },
        hasAssisterSubmit: {
            type: Boolean,
            default: true
        },
        hasLenderFlow: {
            type: Boolean,
            default: false
        },
        assisters: {
            type: Array,
            default: () => []
        },
        processing: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            saving: false,
            lenderLogo: ''
        }
    },
    computed: {
        ...mapState({
            appGuid: state => state.flows.appGuid,
            appData: state => state.flows.appData
        }),
        ...mapGetters('flows', ['getSteps']),
        ...mapGetters('slug', [
            'getGroupSlug',
        ]),
        ...mapGetters('slug', [
            'getTenant',
        ]),
        ...mapState('auth-actions', [
            'appOwnerSettings'
        ]),
        isBid() {
            return !!this.appOwnerSettings.bid
        },
        getLoanId(){
            return this.appData?.relationships?.loan?.data?.id
        },
        enableOtherSubmit() {
            return process.env.envNCEnableOtherSubmit == 1
        },
        isNodifiCoPilot(){
            return this.getTenant === 'nodifi' && this.appData?.attributes?.['hybrid-tnf'] === 0
        },
        getTransitionToSubmittedImage(){
            return this.selectedPrimaryLender ?? 'placeholder'
        },
        canForceSubmitToLender(){
            if(this.isBid) {

                return !!this.selectedPrimaryLender || !!this.selectedLenderObj?.primary?.['bid-lender']?.id

            } else {

                return !!this.selectedPrimaryLender
            }

        },
    },
    methods: {
        ...mapActions('flows', ['selectStep']),
        ...mapActions('resource-actions', [
            'updateLoan',
        ]),
        submitToLender(){
            this.$emit('submit-to-lender')
        },
        forceSubmitToLender(){
            this.$emit('force-submit-to-lender')
        },
        submitToAssister() {
            this.$emit('submit-to-assister')
        },
        closeModal(){
            this.$emit('close-modal')
        }
    },
    watch: {
        processing(val) {
            this.saving = val
        },
        selectedLenderObj(val) {
            console.log('selectedLenderObj', val)
        }
    }
}
</script>
<style lang="scss" scoped>
    .lender-logo .col {
        border: 1px solid #E3E3E3;
        padding: 45px;
        width: 100%;
        border-radius: 12px;
        max-width: 48% !important;
    }

    .other-lender .col {
        max-width: unset !important;
    }
</style>
