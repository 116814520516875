const lazy = fn => {
    let called = false
    let ret
  
    return () => {
      if (!called) {
        called = true
        ret = fn()
      }
  
      return ret
    }
}
  
let isApiSetUp = false

const loadGmapApi = (options) => {

    if (typeof document === 'undefined') {
      // Do nothing if run from server-side
      return
    }

    if (!isApiSetUp) {

      isApiSetUp = true
  
      const googleMapScript = document.createElement('SCRIPT')
  
      if (typeof options !== 'object') {
        throw new Error('options should  be an object')
      }
  
      options['callback'] = 'GMapsInit'

      let baseUrl = 'https://maps.googleapis.com/'
  
      let url = baseUrl + 'maps/api/js?' +
        Object.keys(options)
          .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(options[key]))
          .join('&')
  
      googleMapScript.setAttribute('src', url)
      googleMapScript.setAttribute('async', '')
      googleMapScript.setAttribute('defer', '')

      document.head.appendChild(googleMapScript)
      
    } else {
      throw new Error('You already started the loading of google maps')
    }
}


function gmapApiPromise() {

    function onApiLoaded () {
      return window.google
    }
  
    const gapis = [
      'AIzaSyDA1hc655kDhJWBUxC5yE46ky3A1Eq2SHg',
      'AIzaSyAQt6PO0VaNOn64d0FPhI-Q7Gbk1o6IXAs',
      'AIzaSyCxERIKlrqI4HL9pkPDL5IJta4-5iGPPnQ',
      'AIzaSyCnmXsqQt9mqgMKH9vcXiVBxeQettc53P0',
      'AIzaSyCqMQVkPiT_Ir1-5KneKiy1NHgFgvrrC20',
      'AIzaSyAcwVIWmJyO12neg_OPj8sEEQoCs4p8bAg',
      'AIzaSyBSaQId92yznp6EPH59WnlYugxR4R0My_Q',
      'AIzaSyCpYHZNIFYsw7FAzh43wcBCDk9nhtBok3g',
      'AIzaSyBXAkB0lOJIebMP9SiBKyMgsIYgcOmcw5E',
      'AIzaSyA9j86jZTXFFVvcEfMBiUkcAuGssZJUCJE'];
  
    return lazy(() => {
  
          return new Promise((resolve, reject) => {
            
              try {
  
                window['GMapsInit'] = resolve
                
                let key = Math.floor((Math.random() * gapis.length) + 1);

                loadGmapApi({
                    key: gapis[key-1],
                    libraries: 'places',
                    region: 'AU'
                })
  
              } catch (err) {
  
                reject(err)
  
              }
  
          }).then(onApiLoaded)
    })
  
  }


export default ({ app }, inject) => {
    let g = gmapApiPromise()
    inject('gmapApiPromise', g)
}

