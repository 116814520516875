import { mapState, mapActions, mapGetters, mapMutations } from 'vuex'
import resourceHelpers from '~/components/shared/resource-helpers'
import {store, actions} from '~/components/v1/FinancialObjectsEditor1/__store'
import foFieldLayouts from '~/components/v1/FinancialObjectsEditor1/fo-field-layouts'
import foRequiredLogic1 from '~/components/v1/FinancialObjectsEditor1/fo-required-logic'
import moment from 'moment'

export default {
    mixins: [resourceHelpers],
    data() {
        return {
            activeRoles: [],
            fieldLayout: foFieldLayouts,
            foRequiredLogic: foRequiredLogic1,
        }
    },
    computed: {
        ...mapGetters('kinds', ['kinds', 'kindsUnformat']),
        ...mapGetters('kinds', ['getKindsIncome']),
        ...mapGetters('flows', ['getCalculation']),
        ...mapState({
            development: state => {
                return state.development
            },
            primaryApplicant: state => state.flows.primaryApplicant,
        }),
        getRequiredKindsByName(){

            const refs = [
                    'Applicant',
                    'Director',
                    'Guarantor',
                    'Non-Borrowing Spouse',
                    'Partner',
                    'Trustee']
            
            return this.kindsUnformat.filter( kind => {
                return refs.includes(kind.attributes.name)
            })

        },
        bp(){
            return this.$vuetify.breakpoint
        },
        loanType(){
            return store.loanType
        },
        appType(){
            return store.appType
        },
        stepper(){
            return store.stepper
        },
        steps(){
            return store.steps
        },
        currentStep(){
            return store.currentStep
        },
        isLastStep(){

            if(store.currentStep) {

                return store.currentStep.children.length == 0
            }

            return false
        },
        currencyFields(){
            return [
                'asset-value',
                'amount-owing',
                'profit-before-tax-annual',
                'revenue',
                'profit-before-tax-annual',
                'net-monthly-amount',
                'benefit-amount',
                'net-standard-pay',
                'credit-limit',
                'amount-financed',
                "living-food-and-clothing",
                "living-transport",
                "living-utilities-and-rates",
                "living-lifestyle",
                "living-tv-and-communications",
                "living-child-care-fees",
                "living-family-maintenance",
                "living-insurance",
                "living-education",
                "living-other-living-expenses",
                'payment-amount',
                '__sum-living-expenses',
                '__monthly-payment',
                '__monthly-payment-amount'
            ]
        },
        selectedEntity: {
            get(){
                return store.selectedEntity
            },
            set(value){
                actions.setSelectedEntity(value)
            }
        },
        preset(){
            return store.preset
        },
        people(){
            return store.resources.people
        },
        addresses(){
            return store.allAddresses
        },
        occupancies(){
            return store.allOccupancies
        },
        businesses(){
            return store.resources.businesses
        },
        entities(){
            return store.entities
        },
        entityParts(){
            return store.entityParts
        },
        kindsIncome(){
            return store.kindsIncome
        },
        incomes(){
            return store.resources.incomes
        },
        assets(){
            return store.resources.assets
        },
        liabilities(){
            return store.resources.liabilities
        },
        expenses(){
            return store.resources.expenses
        },
        hasFORecord(){
            return [
                ...this.incomes,
                ...this.assets,
                ...this.liabilities,
                ...this.expenses,
            ].length > 0
        },
        selectedFOName () {
            return store.selectedFOName
        },
        selectedEntityAll(){
            return store.selectedEntityAll
        },
        selectedEntityData(){
            return store.selectedEntityData
        },
        incomeSituationOptions(){
            return store.incomeSituationOptions
        },
        validate(){
            return store.validate
        },
        isBusiness(){
            if(store.selectedEntityData){
                return store.selectedEntityData.type == 'businesses'
            }

            return false
        }
    },
    methods: {
            ...mapActions('resource-actions', [
                'getPeople',
                'getAddresses',
                'getBusinesses',
            ]),
        async updateEntitiesAndParts(){

                let promise1 = this.getPeople({"include" : 'parent-parts'})
                let promise2 = this.getBusinesses({"include" : 'parent-parts'})
                let promise3 = this.getAddresses()

                const res = await Promise.all([promise1, promise2, promise3]) 

                let people = this.getRequiredPeopleByRoles(res[0].data, res[1].data)
                let businesses = this.getRequiredBusinessesByRoles(res[1].data)
                
                let parts = this.getRequiredParts([res[0].data, res[1].data])
                let addresses = this.getAllAddresses(res[2].data)

                actions.setPeople(people)
                actions.setBusinesses(businesses)
                actions.setAllAddresses(addresses)

                let entities = [
                    ...businesses,
                    ...people
                ]

                actions.setEntities(this.sortEntities(entities))
                actions.setEntityParts(parts)

        },
        getRequiredPeopleByRoles(data, business = {}){

            if(!data.included){
                return [];
            }

            let parts = data.included.filter(part => {
                return part.type == 'parts'
            })

            let roleIds = [];	
            let rolesObj = {}
            
            parts.forEach(part => {
                
                if (!part.relationships.kind.data) {	
                    // person must have part with kind
                    return []
                }
                
                this.getRequiredKindsByName.forEach(kind => {

                    if(kind.id == part.relationships.kind.data.id) {

                        roleIds.push(part.id);
                        rolesObj[part.id] = kind.attributes.name

                    }

                })


            })

            if(roleIds.length == 0) {
                return []
            } 

            let people = [] 
            
            data.data.forEach( person => {

                let parentParts = person.relationships['parent-parts'].data

                person.roles = []
                
                if( parentParts.length ){

                    let included = false;

                    parentParts.forEach( part => {

                        // combine required parts
                        let parts = [...data.included]

                        if( business.included ){
                            parts =  [...business.included, ...data.included]
                        }                    
                            
                        // check parent parts is not empty until to the primary applicant
                        let parentPartsFiltered = this.filterParent(part.id, business, parts)

                        if(roleIds.includes(part.id) ) {

                            // Add extra data to person
                            person.roles.push(rolesObj[part.id])

                            // Include person once
                            if(!included && parentPartsFiltered){
                                people.push(person)
                                included = true
                            }

                        }

                    })

                }

            })

            return people 

        },
        getRequiredBusinessesByRoles(data){

            if(!data.included){
                return [];
            }

            let parts = data.included.filter(part => {
                return part.type == 'parts'
            })

            let roleIds = [];	
            let rolesObj = {}
            
            parts.forEach(part => {
                
                if (!part.relationships.kind.data) {	
                    // person must have part with kind
                    return []
                }
                
                this.getRequiredKindsByName.forEach(kind => {

                    if(kind.id == part.relationships.kind.data.id) {

                        roleIds.push(part.id);
                        rolesObj[part.id] = kind.attributes.name

                    }

                })


            })

            if(roleIds.length == 0) {
                return []
            } 

            let people = [] 
            
            data.data.forEach( person => {

                let parentParts = person.relationships['parent-parts'].data

                person.roles = []
                
                if( parentParts.length ){

                    let included = false;

                    parentParts.forEach( part => {

                        if(roleIds.includes(part.id) ) {

                            // check parent parts is not empty until to the primary applicant
                            let parentPartsFiltered = this.filterParent(part.id, data, data.included)

                            // Add extra data to person
                            person.roles.push(rolesObj[part.id])

                            // exclude role base on kind and parts
                            let isNotExcluded = false

                            let __exclude = [
                                {part: 'Trustee', kind: '5-2'}
                            ]

                            if(data.data) {
                                data.data.forEach(role => {
                                    __exclude.forEach(el => {
                                        if(role.relationships.kind.data.id == el.kind && rolesObj[part.id] == el.part) {
                                            isNotExcluded = true
                                        }
                                    })
                                })
                            }

                            // Include person once
                            if(!included && parentPartsFiltered && !isNotExcluded){
                                people.push(person)
                                included = true
                            }

                        }

                    })

                }

            })

            return people 

        },
        getRequiredParts(data = []){

            let parts = []

            data.forEach(item => {

                if(item.included){

                    let entityParts = item.included.filter(part => {
                        return part.type == 'parts'
                    })

                    parts = [
                        ...parts,
                        ...entityParts
                    ]

                }

            })

            return parts

        },
        getAllAddresses(data){

            // Filter addresses by financial objects

            if(data.data.length == 0){
                return [];
            }

            return data.data
        },
        getAllOccupancies(data){

            let occupancies = []

            // Filter occupancies by financial objects

            if(data.hasOwnProperty('included')){

                data.included.forEach(item => {

                    if(item.type == 'occupancies'){
                        occupancies.push(item)
                    }

                })

            }

            return occupancies
        },
        /**
         * getRequiredAddresses: Note in use
         */
        getRequiredAddresses(data){

            // Filter addresses by financial objects

            let fos = {
                incomes: [],
                assets: [],
                expenses: [],
                liabilities: [],
            }

            if(data.data.length == 0){
                return fos;
            }

            data.data.forEach( address => {

                let keys = Object.keys(fos)

                keys.forEach(key => {

                    if(address.relationships[key] && address.relationships[key]['data'].length > 0 ){

                        fos[key].push(address)

                    }

                })

            })

            return fos
        },
        getRequiredResources(data = []){

            // Filter financial objects

            let fos = {
                incomes: [],
                assets: [],
                expenses: [],
                liabilities: [],
            }

            data.forEach(collection => {

                if(!collection.included){
                    return fos;
                }

                let keys = Object.keys(fos)

                collection.included.forEach( item => {

                    if( keys.includes(item.type) ){

                        fos[item.type].push(item)

                    }
                })

            })

            return fos
        },
        buildGrid(entity = null){

            const {incomes, assets, liabilities, expenses} = this

            let fos = [].concat(assets, expenses, liabilities, incomes)

            // Filter FOs by relationship.entity
            if(entity){
                fos = fos.filter( fo => {
                    let entities = fo.relationships[entity.type].data.filter(e => e.id == entity.id)
                    return entities.length > 0
                })
            }

            let data = fos.reduce( (accumulator, currentFo, index, foArray) => {

                let type = currentFo.type
                let id = currentFo.id
                let typeIndex = this.getIndexByType(type)
                let foNames = ["incomes", "assets", "liabilities", "expenses"].filter(t => t != type)

                // If FO has been added, skip it.
                if( accumulator.keys.includes(type+id) ) {
                    return accumulator
                }

                // If FO has no KIND, skip it.
                if( currentFo.relationships.kind.data == null ) {
                    return accumulator
                }


                // Else add id to the list of keys
                accumulator.keys.push(type+id)

                accumulator.rows[`row-${index}`] = this.getRowLayout(currentFo)
                accumulator.rows[`row-${index}`][typeIndex] = currentFo


                foNames.forEach( foName => {

                    //  Check if relationships exists
                    if(currentFo.relationships[foName]) {

                        if(currentFo.relationships[foName].data.length > 0) {

                            let childIds = currentFo.relationships[foName].data
                            let childrenArray = []
                                
                            childIds.forEach(child => {
                                childrenArray = foArray.filter(fo => {
                                    return fo.id == child.id
                                })
                            });

                            childrenArray.forEach(child => {

                                if(child.relationships[type]) {

                                    if(!accumulator.keys.includes(child.type+child.id)) {

                                        let parentIds = child.relationships[type].data
                                        
                                        parentIds.forEach(parent => {

                                            if(id == parent.id){
                                                
                                                let childIndex = this.getIndexByType(child.type)

                                                accumulator.rows[`row-${index}`][childIndex] = child
                                                accumulator.keys.push(child.type+child.id)
                                            }

                                        });

                                    }
                                }

                            })


                        }

                    }

                })

                return accumulator

            }, {
                keys: [],
                rows: {}
            })

            // console.log(this.sortRows(data.rows))

            // Sort rows by date created, new at the top

            return this.sortRows(data.rows)
        },
        sortRows(rows = {}){

            let newRows = {}
            let sortable = []

            Object.keys(rows).forEach( key => {

                let fos = rows[key]
                let date = 0

                fos.forEach(fo => {
                    if( fo.hasOwnProperty('attributes') ){
                        let foDate = new Date(fo.attributes['created-at']).getTime()
                        date = (foDate > date) ? foDate : date;
                    }
                })

                sortable.push({date, key, data: fos})

            })

            sortable.sort((a, b) => a.date - b.date )

            sortable.forEach(item => {
                newRows[item.key] = item.data
            })

            return newRows;
        },
        getIndexByType(type){
            switch (type) {
                case 'incomes':
                    return  0
                case 'assets':
                    return  1
                case 'liabilities':
                    return  2
                case 'expenses':
                    return  3
            }
        },
        getRowLayout(fo){
                
            const {fieldLayout} = this

            // Row initial layout
            let template = [
                            {status: "unavailable", type: "incomes", id: null}, 
                            {status: "unavailable", type: "assets", id: null}, 
                            {status: "unavailable", type: "liabilities", id: null}, 
                            {status: "unavailable", type: "expenses", id: null}
                        ];

            if(fo.relationships.kind.data == null) return template; 

            let foNames = ["incomes", "assets", "liabilities", "expenses"].filter(t => t != fo.type)

            let keys = Object.keys(fieldLayout)
            let layout = null

            for (let i = 0; i < keys.length; i++) {

                const key = keys[i];
                const items = key.split('|')

                if(items.includes(fo.relationships.kind.data.id)) {

                    layout = fieldLayout[key]

                    break
                }
            }

            foNames.forEach( foName => {

                let index = this.getIndexByType(foName)

                if(layout[foName] && typeof layout[foName] == 'string') {

                    if(layout[foName] == 'unavailable'){
                        template[index] = {status: "unavailable", type: foName, id: null}
                    } else {
                        template[index] = {status: "inactive", type: foName, id: layout[foName]}
                    }


                }

            })


            return template

        },
        currency(val){
            return this.$options.filters.currency(val)
        },
        getFullAddress(address){

            if(!address) return "";

            if(!address.hasOwnProperty('attributes')) return "";
            
            return  `${address.attributes["street-number"]} ${address.attributes["street-name"]} ${address.attributes["street-type"]}, ${address.attributes["suburb"]} ${address.attributes["state"]} ${address.attributes["postcode"]}`

        },
        getFriendlyName(entity){

            let fullname = ''

            if(entity.type == 'businesses') {

                fullname += entity.attributes['business-legal-name'] ? entity.attributes['business-legal-name'] : 'Business'

            }

            if(entity.type == 'people'){

                fullname += entity.attributes['first-name'] ? entity.attributes['first-name'] + ' ' : ''

                fullname += entity.attributes['last-name'] ? entity.attributes['last-name'] : ''

                if(!entity.attributes['first-name'] && !entity.attributes['last-name']){
                    fullname = 'Person'
                }
            }

            return fullname
        },
        toSingular(payload){
            return this.$options.filters.toSingularResource(payload)
        },
        capitalize(payload){
            return this.$options.filters.capitalize(payload)
        },
        isBtnGroup(key){

            let keys = [
                "employment-on-probation",
                "self-employed-gst-registered",
                "covered-by-insurance",
                "being-refinanced",
                "being-sold-traded",
                "to-be-paid-out"
            ]

            return keys.includes(key)

        },
        getPeopleRelationship(){
            const people = {
                data: [],
                // Default Strategy: attach
                meta: {
                    strategy: 'replace'  
                }
            }

            this.activeRoles.forEach(role => {

                let person = this.people.find( p => p.id == role )

                if(person){
                    people.data.push({
                        type: 'people',
                        id: person.id
                    })
                }
            })

            return people
        },
        getBusinessesRelationship(){
            const businesses = {
                data: [],
                // Default Strategy: attach
                meta: {
                    strategy: 'replace'  
                }
            }

            this.activeRoles.forEach(role => {

                let business = this.businesses.find( b => b.id == role )

                if(business){
                    businesses.data.push({
                        type: 'businesses',
                        id: business.id
                    })
                }

            })

            return businesses
        },
        isAddressField(field){
            let fields = [
                '__full-address',
                '__rental-address'
            ]

            return fields.includes(field)
        },
        isAddressField3(field){
            let fields = [
                '__employer-address',
                '__business-address',
            ]

            return fields.includes(field)
        },
        getKindName(id){

            let kind = this.kindsUnformat.find(k => {
                return id == k.id
            })
            
            if(kind){
                return kind.attributes.name
            }

            return ''
        },
        collectionErrorCount(){

            const  { buildGrid, entities} = this

            let errors = []
            let moreErrors = []

            entities.forEach( entity => {

                    let collection = buildGrid(entity)
                    let entityFORecords = []

                    Object.keys(collection).forEach(fos => {

                        collection[fos].forEach(fo => {


                            // Collect FOs with data for Record Level Validation
                            if(fo.hasOwnProperty('attributes')){
                                entityFORecords.push(fo)
                            }

                            // Grid View Errors
                            if(fo.hasOwnProperty('status') && fo['status'] == 'inactive'){

                                let required = this.validateCollection(fo, collection[fos])

                                if(required){

                                    let group = collection[fos]

                                    let name = this.getKindName(fo.id)

                                    errors.push({
                                            owner: entity, 
                                            tab: 'all', 
                                            required: name, 
                                            message: `${name} ${this.toSingular(fo.type)} is required.`,
                                            group
                                        })

                                }

                            }


                        })

                    });


                    // Find Required Record

                    // let someErrors = this.validateEntityRecords(entity, entityFORecords)
                    let someErrors = this.validateIncomeRecords(entity, entityFORecords)

                    moreErrors = moreErrors.concat(someErrors) 

            })

            // console.log([...moreErrors, ...errors])

            return [...moreErrors, ...errors]

        },
        validateCollection(data, collection){

            const {foRequiredLogic} = this

            let keys = collection.filter( item => item.hasOwnProperty('attributes'))
            let required = false

            keys.forEach( key => {

                let kindId = key.relationships?.kind?.data?.id
                let logic = foRequiredLogic[key.type][kindId]

                if(logic && logic.includes(data.type)){
                    required = true
                }

            })

            return required
        },
        initializeIncomeEntityLogic(){

            let entity = this.entities[this.selectedEntity]

            let entityKind = this.getEntityRoles(entity, this.entityParts)
            
            actions.setSelectedEntityData(entity)

            /**
             * Business Income Logic
             */
            if(entity && entity.type == 'businesses'){
                this.setupBusinessIncomeForm()
            }

            /**
             * People Income Logic
             * loan type Commercial
             * Not director
             */
            if(entity && entity.type == 'people' && store.loanType == 'Commercial' && !entityKind.includes('Director')) {
                this.filterKindIncome()
            }

            /**
             * Check for current income
             */

            // Collect all records of current entity
            let collection = this.buildGrid(entity)
            let entityFORecords = []

            Object.keys(collection).forEach(fos => {

                collection[fos].forEach(fo => {

                    if(fo.hasOwnProperty('attributes')){
                        entityFORecords.push(fo)
                    }

                })

            });


            let hasCurrent = false

            entityFORecords.forEach(record => {
                if(record.attributes['income-situation'] == 'Current'){
                    hasCurrent = true
                }
            })


            if(hasCurrent){

                actions.setIncomeSituationOptions(['Secondary', 'Previous'])

            } else {
                
                /**
                 * No entity means All tab is selected
                 */
                if(!entity){
                
                    actions.setIncomeSituationOptions(['Current', 'Secondary', 'Previous'])
                
                } else {

                    actions.setIncomeSituationOptions(['Current'])
                }

            }

            /**
             * Company Director Logic
             */

            if(entityKind && entityKind.includes('Director')){
                if(!hasCurrent){
                    this.setupCompanyDirectorForm()
                } 
                else {
                    this.filterKindIncome()
                }
            }


            /**
             * Sole Trader - Self-Employed 12-29
             */

            if(store.appType == "Sole Trader"){

                if(!hasCurrent){
                    this.setupSelfEmployedForm()
                }
            }


        },
        setupSelfEmployedForm(){

            let selfEmployed = this.kindsIncome.children.find(income => {
                return income.id == '12-29'  // Self-Employed 12-29
            })

            if(selfEmployed){
                actions.setCurrentStep(selfEmployed)

                setTimeout( () => {
                    actions.setSteps([selfEmployed])
                }, 1)

            }

        },
        filterKindIncome() {
            // exclude company director
            this.kindsIncome.children = this.kindsIncome.children.filter(child => child.id != '12-16')
            setTimeout( () => {
                actions.setSteps([this.kindsIncome])
            }, 1)
        },
        setupCompanyDirectorForm(){

            actions.setPreselectIncomeSituation('Current')

            let companyDirector = this.getKindsIncome.children.find(income => {
                return income.id == '12-16'  // Company Director 12-16
            })

            if(companyDirector){
                actions.setCurrentStep(companyDirector)

                setTimeout( () => {
                    actions.setSteps([companyDirector])
                }, 1)

            }

        },
        setupBusinessIncomeForm(){

            actions.setPreselectIncomeSituation('Current')

            let businessIncome = this.kindsIncome.children.find(income => {
                return income.id == '12-30'  // Business Income 12-30
            })

            if(businessIncome){
                actions.setCurrentStep(businessIncome)

                setTimeout( () => {
                    actions.setSteps([businessIncome])
                }, 1)

            }

        },

        /**
         * 
         *  Record Level Validation
         * 
         * 
         */

        validateIncomeRecords(entity, records){

            let fullname = this.getFriendlyName(entity)
            let roles = this.getEntityRoles(entity, this.entityParts)
            let role = this.getRoles(entity)

            // Build requirment data for validation
            let requirements = {}

            // Reset error messages
            let errorMessages = []

            let __exclude = [
                'Beneficial Owner'
            ]

            let skip = false

            __exclude.forEach( role => {
                if( roles.length == 1 &&  roles.includes(role)){
                    skip = true
                }
            })

            // default all to 1 (pass validation), then set any who fail validation to 0

            if(!skip){
                
                if(entity.type == 'people'){

                    requirements = {
                        has_three_year_history: 1, 
                        has_no_history_gaps: 1, 
                        dates_are_complete: 1, 
                        no_dates_overlap: 1, 
                        dates_are_chronological: 1, 
                        has_exactly_one_current_income: 1, 
                        current_is_most_recent: 1, 
                    }

                } else {

                    let incomes = entity.relationships?.incomes?.data

                    requirements = {
                        has_income: (incomes && incomes.length) ? true : false,
                    }
                }

            }
                
            const filteredIncomes = this.getIncomesByEntityId(records, entity)

            let dates = []

            /**
             *  Check if Secondary is older than Primary, use push it instead of Primary
             */
            let currentIncome = {}
            let secondaryIncome = {}

            filteredIncomes.forEach(income => {

                let situation = income.attributes['income-situation']
                let payload = {
                    "primary": situation,
                    "start-date": this.parseDate(income.attributes['start-date']),
                    "end-date": this.parseDate(income.attributes['end-date']),
                }

                if(situation == 'Current'){
                    currentIncome = payload
                }

                if(situation == 'Secondary'){
                    secondaryIncome = payload
                }

                if(situation == 'Previous'){
                    dates.push(payload)
                }
            })

            // if olderIncome is negative number, it means Secondary Income is older than Current Income 
            let olderIncome = this.getDays(currentIncome['start-date'], secondaryIncome['start-date']);

            if( olderIncome < 0) {
                // Force Secondary Income to be Current if older.
                secondaryIncome["primary"] = 'Current'
                dates.push(secondaryIncome)
            } else {
                dates.push(currentIncome)
            }

            // Sort date by start-date old to new
            dates.sort((a,b) => {
                return a['start-date'] - b['start-date'];
            });

            let totalDays = 0;
            let prevObject;

            let isBusiness = entity.type == 'businesses'

            if(isBusiness){

                if ( !requirements.has_income ) {

                    //fail	

                    errorMessages.push({
                        owner: entity, 
                        tab: 'incomes', 
                        required: "", 
                        message: `${role} ${fullname} must have 1 current income.`
                    })
        
                }
                
                return errorMessages;

            }

            let currentAddressOnly = false
            let currentMostRecentAnd3YearsOrMoreOnly = false

            let __currentAddressOnly = [
                'Non-Borrowing Spouse',
                'Guarantor'
            ]
            let __currentMostRecentAnd3YearsOrMoreOnly = [
                'Applicant'
            ]

            roles.forEach( role => {

                if(__currentAddressOnly.includes(role)){
                    currentAddressOnly = true
                }

                if(__currentMostRecentAnd3YearsOrMoreOnly.includes(role)){
                    currentMostRecentAnd3YearsOrMoreOnly = true
                }

            })

            if( this.isValid(requirements) ){

                /**
                 *  1. Applicant must have 1 (and only 1) current address.
                 */

                if (!this.hasOnlyOneCurrent(dates) && !isBusiness) {
                    
                    //fail	

                    if(!currentMostRecentAnd3YearsOrMoreOnly){
                    
                        requirements.has_exactly_one_current_income = 0; 

                        errorMessages.push({
                            owner: entity, 
                            tab: 'incomes', 
                            required: "", 
                            message: `${role} ${fullname} must have 1 current income.`
                        })

                    }

                }
                
                // Stop process if current address only
                if(currentAddressOnly) return errorMessages;
        

                /**
                 * 2. Current address must be the most recent.  | deactivated
                 */

                // if (!this.currentIsMostRecent(dates)) {
                    
                    //fail

                    // if(!isBusiness){
                        
                        // requirements.current_is_most_recent = 0; 

                        // errorMessages.push({
                        //     owner: entity, 
                        //     tab: 'incomes', 
                        //     required: "", 
                        //     message: `${role} ${fullname} current income must be the most recent.`
                        // })

                    // }
                // }
            

                /**
                 * 3. Further more validation
                 */

                for(let i=0; i < dates.length; i++) {
                        
                    let date = dates[i];
                    
                    // check if primary address
                            
                    if (date['primary'] == 'Current') {
                        
                        //is current (& most recent) address (no input for['end-date']), we auto-set this to today (needed for days at address calculation)
                        
                        date['end-date'] = new Date();
        
                    } else {
                        
                        // is not the current (primary) address, needs both dates
                        
                        if (date['end-date'] == null && !isBusiness) {
                            
                            // fail validation
                            requirements.dates_are_complete = 0; 

                            errorMessages.push({
                                owner: entity, 
                                tab: 'incomes', 
                                required: "", 
                                message: `${role} ${fullname} must have complete <strong>Start</strong> and <strong>End Dates</strong>.`
                            })

                            break;	
                        }
                        
                    }
                    
                    // check to see that this address starts the day of (or day after) the last dates end date (can't allow gaps between dates)
                    
                    let overlappingDays = 0;
                    
                    if (prevObject) {

                        let days = this.getDays(prevObject['end-date'], date['start-date']);

                        // console.log('compare days: '+days);
                        
                        // Must have Start and End dates
                        if (days == null) {
                            
                            
                            // fail validation
                            requirements.dates_are_complete = 0; 

                            errorMessages.push({
                                owner: entity, 
                                tab: 'incomes', 
                                required: "", 
                                message: `${role} ${fullname} must have complete <strong>Start</strong> and <strong>End Dates</strong>.`
                            })

                            break;	
                        
                        // No gaps between dates | deactivated
                        } else if (days > 1) {
                            
                            // fail validation
                            // requirements.has_no_history_gaps = 0; 

                            // errorMessages.push({
                            //     owner: entity, 
                            //     tab: 'incomes', 
                            //     required: "", 
                            //     message: `${role} ${fullname} income history have gaps. Please check <strong>Start</strong> and <strong>End Dates</strong>.`
                            // })

                            // break;	
                        
                        // No OVERLAPing days | deactivated
                        } else if (days < 0) {
                            
                            // fail validation
                            // requirements.no_dates_overlap = 0; 

                            // errorMessages.push({
                            //     owner: entity, 
                            //     tab: 'incomes', 
                            //     required: "", 
                            //     message: `${role} ${fullname} dates between incomes can't overlap. Please check <strong>Start</strong> and <strong>End Dates</strong>.`
                            // })

                            // break;		
                            
                        } else if (days == 0) {
                            
                            // previous object end date is same as this object start date - don't count the day twice when getting total days
                            
                            // overlappingDays = 1;
                            
                            /**
                             * NOTE: Allowed to overlap 1 day
                             * 
                             * uncomment the code below if overlap must be 0
                             *  
                             */

                            // fail validation
                            // requirements.no_dates_overlap = 0; 

                            // errorMessages.push({
                            //     owner: entity, 
                            //     tab: 'incomes', 
                            //     required: "", 
                            //     message: `${role} ${fullname} dates between dates can't overlap. Please check <strong>Start</strong> and <strong>End Dates</strong>`
                            // })

                            // break;

                        }
                        
                    }
                    
                    // get days
                    
                    let days = this.getDays(date['start-date'], date['end-date']);
                    
                    // per-address validation
                    
                    if (days != null) {
                    
                        if (days < 0) {
                            
                            // fail validation
                            requirements.dates_are_chronological = 0; 

                            errorMessages.push({
                                owner: entity, 
                                tab: 'incomes', 
                                required: "", 
                                message: `${role} ${fullname} dates must be <strong>Chronological</strong>.`
                            })

                            break;	
                            
                        } else {
                            
                            // days -= overlappingDays; // remove any overlapping days before adding to total
                            totalDays += days;
                            
                        }
                        
                    }
                    
                    //set this as previous object for next iteration
                    
                    prevObject = dates[i];
                    
                    // console.log(date.primary+' : '+date['start-date']+' - '+date['end-date']+'. days : '+days);

                }
            
                /**
                 * 4. Count 3 year history validation
                 * 
                 *   - dates[0] is always the oldest.
                 */

                if(dates.length > 0) {

                    // console.log('totalDays', totalDays)
                    if (totalDays < (365*3) && !isBusiness) {
                            
                        // fail validation
                        requirements.has_three_year_history = 0; 
    
                        errorMessages.push({
                            owner: entity, 
                            tab: 'incomes', 
                            required: "", 
                            message: `${role} ${fullname} - Please provide 3 years minimum employment history.`
                        })
                    }

                    let oldestIncomeTotalDays = this.getDays(dates[0]['start-date'], new Date());

                    // console.log('oldestIncomeTotalDays', oldestIncomeTotalDays)

                    if (oldestIncomeTotalDays < (365*3) && !isBusiness) {
                            
                        // fail validation
                        requirements.has_three_year_history = 0; 
    
                        errorMessages.push({
                            owner: entity, 
                            tab: 'incomes', 
                            required: "", 
                            message: `${role} ${fullname} - Please provide employment history dating back 3 years.`
                        })
                            
                    }
                }

            }

            // validation for occupancy type
            if (!isBusiness) {
                    
                const filteredAssets = this.getAssetsByEntityId(records, entity)
                const filteredOccupancy = this.getOccupancyByEntityId(this.occupancies, entity)

                let hasRequiredAsset = false

                // check if occupancy == Mortgage - Owner Occupied or Own Outright and occupancy-situation = current
                filteredOccupancy.forEach( occupancy => {

                    if(occupancy.attributes['residential-situation'] == 'Mortgage - Owner Occupied' || occupancy.attributes['residential-situation'] == 'Own Outright') {
                        
                        if (occupancy.attributes['occupancy-situation'] == 'Current') {
                            hasRequiredAsset = true
                        }

                    }

                })

                if(hasRequiredAsset) {

                    if(filteredAssets.length > 0) {

                        let requiredAsset = false

                        // check if asset is == Owner Occupied 10-16
                        filteredAssets.forEach( asset => {

                            if (asset.relationships.kind.data.id == '10-16') requiredAsset = true

                        })

                        // 
                        if (!requiredAsset) {

                            errorMessages.push({
                                owner: entity, 
                                tab: 'assets', 
                                required: "", 
                                message: `${role} ${fullname} must have Owner Occupied Asset`
                            })

                        }

                    } else {

                        errorMessages.push({
                            owner: entity, 
                            tab: 'assets', 
                            required: "", 
                            message: `${role} ${fullname} must have Owner Occupied Asset`
                        })

                    }

                }
                
            }

            // console.log(errorMessages)

            if(this.loanType == 'Consumer'){

                if(records !== []){

                    let expenses = []

                    records.forEach(data => {
                        if(data.type == 'expenses'){
                            expenses.push(data)
                        }
                    });

                    let hasLivingExpenses = expenses.find(data => data.relationships?.kind?.data?.id  == '11-16')
                    
                    if(!hasLivingExpenses){
                        errorMessages.push({
                            owner: entity, 
                            tab: 'expenses', 
                            required: "", 
                            message: `${role} ${fullname} must have Living Expenses`
                        })
    
                    } 
                    
                }
            }


            return errorMessages

        },
        getIncomesByEntityId(records, entity){

            return records.filter( record => {

                if(record.type == 'incomes'){

                    let ids = []

                    if(entity.type == 'people') {
    
                        record.relationships.people.data.forEach( person => {
                            ids.push(person.id)
                        })
    
                    } else {
    
                        record.relationships.businesses.data.forEach( business => {
                            ids.push(business.id)
                        })
    
                    }
    
                    if(ids.includes(entity.id)){
                        return true
                    }


                }

                return false

            })

        },
        getAssetsByEntityId(records, entity){

            return records.filter( record => {

                if(record.type == 'assets'){

                    let ids = []

                    if(entity.type == 'people') {
    
                        record.relationships.people.data.forEach( person => {
                            ids.push(person.id)
                        })
    
                    }
    
                    if(ids.includes(entity.id)){
                        return true
                    }


                }

                return false

            })

        },
        getOccupancyByEntityId(occupancies, entity) {

            return occupancies.filter( occupancy => {

                let ids = []

                if(entity.type == 'people') {

                    ids.push(occupancy.relationships.person.data.id)

                }

                if(ids.includes(entity.id)){
                    return true
                }

                return false

            })
        },
        isValid(data) {
            for (let key in data) {
                if (data.hasOwnProperty(key)) {
                    if (data[key] == 0) {
                        return false;
                        break;
                    }
                }
            }
            return true;
        },
        parseDate(str) {
            if (str) {
                return new Date(str);
            } else {
                return null;
            }
        },
        daydiff(first, second) {
            return Math.round((second-first)/(1000*60*60*24));
        },
        getDays(first, second) {
            if ((first != null) && (second != null)) {
                return this.daydiff(first, second)
            } else {
                return null;
            }
        },
        hasOnlyOneCurrent(dates) {
            
            let valid = false;
            let hasPrimaryIncome = false;

            for(let i=0;i<dates.length;i++) {
            
                if (dates[i].primary == 'Current') {
                    
                    // found a primary address
                        
                    if (!hasPrimaryIncome) {
                        
                        hasPrimaryIncome = true;
                        
                        valid = true;
                        
                    } else {
                        
                        //more than 1 address is set to primary
                        
                        valid = false;
                        break;
                    }
                }
            }
            
            return valid;

        },
        currentIsMostRecent(dates) {

            if (dates[dates.length-1] && dates[dates.length-1].primary == 'Current') {
                return true;
            } else {
                return false;
            }
            
        },
        getRoles(entity){

            let roles = this.getEntityRoles(entity, this.entityParts)

            return roles.length > 1 ? `${roles[0]} +${roles.length - 1}`: roles.length == 1 ? roles[0] : ''

        },
        getMinDate(key){

            let min = undefined

            if(key == 'end-date'){

                let start = this.resources.incomes.attributes['start-date']

                if(start){

                    let newStart = moment(start, "YYYY-MM-DD").add(1, 'days').format("YYYY-MM-DD")

                    min = newStart
                }

            }

            return min

        },
        getMaxDate(key){

            let max = undefined

            if(key == 'start-date'){

                let end = this.resources.incomes.attributes['end-date']

                if(end && this.visible['end-date']){

                    let newEnd = moment(end, "YYYY-MM-DD").subtract(1, 'days').format("YYYY-MM-DD")

                    max = newEnd
                }

            }

            return max

        },
        filterParent(partId = '', businesses = {}, parts = []) {

            let parent = {}
            let kind = {}

            // filter parent
            if (parts.length > 0) {
                parts.forEach(part => {
                    // get parent
                    if (partId == part.id && part.relationships?.parent?.data) {
                        parent = part.relationships.parent.data
                    }
                    // get kind
                    if (partId == part.id && part.relationships?.kind?.data) {
                        kind = part.relationships.kind.data
                    }
                })

            }

            // check if parent has parent parts
            // if has parent parts recursive until to the primary applicant
            if(Object.keys(parent).length > 0) {

                let hasParentParts = false
                let part_id = ''

                businesses.data.forEach(business => {

                    if (business.id == parent.id && business.relationships['parent-parts'].data.length > 0) {
                        hasParentParts = true
                        part_id = business.relationships['parent-parts'].data[0].id
                    }

                })

                // if has parent parts and primary applicant id is not equal to parent
                if (hasParentParts && this.primaryApplicant?.type != 'loans')
                    return this.filterParent(part_id, businesses, parts)

            }
            
            // return if parent is the primary applicant or kind id = 3-6 / non-borrowing spouse
            if (parent.type == 'loans' || kind.id == '3-6') {
                return true
            } else 
                return false
            
        },
        checklistCollection(){

            const  { buildGrid, entities} = this

            let records = []
            let moreErrors = []

            entities.forEach( entity => {

                    let collection = buildGrid(entity)
                    let entityFORecords = []

                    Object.keys(collection).forEach(fos => {

                        collection[fos].forEach(fo => {

                            if(fo.hasOwnProperty('attributes')){
                                entityFORecords.push(fo)
                            }

                            let required = this.validateCollection(fo, collection[fos])

                            if(required){

                                let name = ''
                                let valid = false
                                let resource = null

                                if(fo?.['status'] == 'inactive'){
                                    name = this.getKindName(fo.id)
                                } else {
                                    name = this.getKindName(fo?.relationships?.kind?.data?.id)
                                    valid = true
                                }

                                records.push({
                                        owner: entity, 
                                        name, 
                                        required, 
                                        resource: fo, 
                                        valid,
                                        tab: fo?.type,
                                        message: `${name} ${this.toSingular(fo.type)} is required.`,
                                    })

                            }

                        })

                    });

                    let someErrors = this.validateIncomeRecords(entity, entityFORecords)

                    moreErrors = moreErrors.concat(someErrors) 


            })

            return [...moreErrors, ...records]

        },
        sortEntities(entities = []){

            let primaryId = this.getCalculation?.["primary-applicant-id"]

            let arrangedEntities = entities.reduce((acc, current) => {
                if(current.id == primaryId) {
                    acc.unshift(current)
                } else {
                    acc.push(current)
                }
                return acc
            }, [])

            return arrangedEntities
        }
    }
}