import Vue from 'vue'
import { required, confirmed, length, email, min, digits, max, min_value, is, between, numeric, max_value, } from "vee-validate/dist/rules";
import { extend } from "vee-validate";
import VueMoment from 'vue-moment'
import moment from 'moment-timezone'

Vue.use(VueMoment, {
    moment,
})

/**
 * 
 * Required & Cross Fields
 * 
 */
extend("required", {
    ...required,
    message: (field, values) => `${field} is required`
});

extend("length", {
    ...length,
    message: (field, values) => { 
        return `${field} must be ${values.length} characters only.` 
    }
});

extend("required_if", {
    /**
     * NOTE: {ValidationProvider} add attribute {vid} to other-field
     *       to get its value.
     */
    validate: (value, {other, values}) => {

        // Single option
        if(typeof values === 'string') {
            if(values == other) {
                return value !== null && value !== '';
            } 
            return true
        } 

        // Mutiple options
        if(typeof values === 'object') {
            if(values.includes(other)) {
                return value !== null && value !== '';
            }
            return true
        }
    },
    params: [{name: 'other', isTarget: true}, 'values'],
    message: (field, values) => `${field} is required`,
    computesRequired: true,
});


extend("required_unless", {
    /**
     * NOTE: {ValidationProvider} add attribute {vid} to other-field
     *       to get its value.
     */
    validate: (value, {other, values}) => {

        // Single option
        if(typeof values === 'string') {
            if(values != other) {
                return value !== null && value !== '';
            }
            return true
        } 

        // Mutiple options
        if(typeof values === 'object') {
            if(values.includes(other)) {
                return value !== null && value !== '';
            }
            return true
        }
        
    },
    params: [{name: 'other', isTarget: true}, 'values'],
    message: (field, values) => `${field} is required`,
    computesRequired: true,
});


/**
 * 
 * Common
 * 
 */

extend("max", {
    validate: (value, { count }) => {
        return value.length <= count
    },
    params: ['count'],
    message: (field, values) => `${field} must be ${values.count} characters or less`
});

extend("min", {
    validate: (value, { count }) => {
        return value.length >= count
    },
    params: ['count'],
    message: (field, values) => `${field} must be ${values.count} characters or more`
});

extend("email", {
    ...email,
    message: "This field must be a valid email"
});

extend("oneOf", {
    validate: (value, { choices }) => {

        let val = value + ''

        val = val.trim();

        return  choices.includes(val)
    },
    params: ['choices'],
    message: (field, values) => `${field} value must be in ${values.choices}`
});

extend("min_value", {
    ...min_value
});

extend("max_value", {
    ...max_value,
    message: (field, values) => `${field} max value is ${values.max}`
});

/**
 * 
 * Dates
 * 
 */
extend("date_format", {
    validate: (value, { date }) => {

        // Default delimeters : backslash {/} or dash {-}
        let dateReg = /^\d{4}([//-])\d{2}\1\d{2}$/ 

        return value.match(dateReg);
    },
    params: ['date'],
    message: (field, values) => `${field} format is invalid`
});

extend("after", {
    validate: (value, { date }) => {
        let entry = new Date(value)
        let comparee =  (date === 'now') ? new Date() : new Date(date)
        return entry >= comparee;
    },
    params: ['date'],
    message: (field, values) => `${field} must be after ${values.date}`
});

extend("before", {
    validate: (value, { date }) => {
        let entry = new Date(value).toISOString().substr(0, 10)
        let comparee =  (date === 'now') ? new Date().toISOString().substr(0, 10) : new Date(date).toISOString().substr(0, 10)
        
        return entry <= comparee;
        // return entry < comparee;
    },
    params: ['date'],
    message: (field, values) => {
        let date =  (values.date === 'now') ? new Date() : values.date
        return `${field} must be before ${moment(date).format('DD-MM-YYYY')}`
    }
});



/**
 * 
 * Numbers
 * 
 * {between} is used for both decimal and range
 * 
 * 
 */

extend("numeric", {
    ...numeric
});

extend("between", {
    ...between,
    message: (field, values) => {

        /*
         * NOTE: custom validation message for decimal like money
         *       you may want to refactor this.
        */
        if(values.min == '0' && values.max == '99999999.99') {
            return `please enter a valid ${field}`
        }

        /*
         * TEMP: validation for custom error message profit_before_tax
        */
        if(values.min == '1' && values.max == '99999999.99') {
            return `With the Income Verification Method selected, ${field} is required`
        }

        /**
         * range validation message
         */
        return `${field} must be from ${values.min} to ${values.max}`
    }
});


extend("digits", {
    ...digits,
    message: (field, values) => {
        return  `${field} must be ${values.length} digits`
    }
});


/**
 * 
 * Password
 * 
 */

extend('password', {
    validate: (value, { other }) => value === other,
        message: 'The password confirmation does not match.',
        params: [{ name: 'other', isTarget: true }]
    });
    extend("confirmed", {
    ...confirmed,
    message: "This field confirmation does not match"
});



  
  /**
   * 
   * Custom | Field Specific Validation
   * 
   * 
   */

  extend("age_dependants", {
    /**
     * NOTE: {ValidationProvider} add attribute {vid} to other-field
     *       to get its value.
     */
    validate: (value, {other, values}) => {

        if(other == 0) {
            return true
        }

        let comparee = parseInt(other);

        let valArr = value ? value.replace(/(^,)|(,$)/g, "") : '';
            valArr = valArr.split(',')

        return valArr.length == comparee;
    },
    params: [{name: 'other', isTarget: true}, 'values'],
    message: (field, values) => `${field} count is invalid. Please provide age of each dependant separated by commas e.g. "3,4" or "2,4,7" etc.`,
    computesRequired: true,
});


extend("decimal_place", {
    /**
     * NOTE: {ValidationProvider} add attribute {vid} to other-field
     *       to get its value.
     */
    validate: (value, {values}) => {

        if(!value) return true; // if value is null, force valid

        const alArr = value.toString().split('.')
        const comparee = parseInt(values)

        if( alArr.length > 2 ) {
            return false
        }

        if( alArr.length == 2 && alArr[1].length > comparee ) {
                return false
        } 

        return true
    },
    params: ['values'],
    message: (field, values) => {
        return `maximum of ${values.values} decimal places`
    },
    computesRequired: true,
});

extend("accepted", {
    /**
     * NOTE: {ValidationProvider} add attribute {vid} to other-field
     *       to get its value.
     */
    validate: (value, {other, values}) => {

        return other.toString() == values

    },
    params: [{name: 'other', isTarget: true}, 'values'],
    message: (field, values) => `please accept ${field}`
});



extend("is", {
    validate: (value, {values}) => {

        let ival = value.toString().toUpperCase();
        let rval = values.toString().toUpperCase();

        return ival === rval

    },
    params: ['values'],
    message: (field, values) => `${field} is required`
});



extend("has_other", {
    validate: (value, { values }) => {

        let comparee = `${values}`
        let val = `${value}`.substring(0, comparee.length)

        return val == comparee
    },
    params: ['values'],
    message: (field, values) => `${field} must start with 0`
});

extend("has", {
    validate: (value, { values }) => {

        let comparee = `${values}`.split(',')

        let flag = false

        comparee.forEach( (k, i) => {
            if(k ==  `${value}`.substring(0, k.length)){
                flag = true
            }
        })

        return flag
    },
    params: ['values'],
    message: (field, values) => {

        let comparee = ''

        if(typeof values.values == 'string') {

            comparee = values.values

        } else if(values.values.length == 2) {

            comparee = `${values.values[0]} or ${values.values[1]}`

        } else {

            values.values.forEach(val => {
                comparee += `${val},`
            })

        }

        return `${field} must start with ${comparee}`
    }
});

extend("is_mobile_number", {
    validate: (value, { values }) => {

        let comparee = `${value}`

        // character valid for mobile number
        const ismobile = string => [...string].every(c => '+0123456789'.includes(c))

        // get the first letter
        const fLetter = comparee.charAt(0)

        // set number length to 9
        let validNumber = ''
        if (fLetter == '0') validNumber = comparee.substring(1)

        if (fLetter == '+') validNumber = comparee.substring(3)

        return ismobile(comparee) && validNumber.length == 9
    },
    params: ['values'],
    message: (field, values) => `${field} must be a valid phone number`
});

extend("is_employment_phone", {
    validate: (value, { values }) => {

        let comparee = `${values}`.split(',')
        let valid = false
        
        comparee.forEach(comp => {
            const val = `${value}`.substring(0, comp.length)
            if(val == comp) {
                valid = true
            }
        })

        return valid
    },
    params: ['values'],
    message: (field, values) => `${field} must start with 02, 03, 07, 08, 1300, 1800 or 13`
});



extend("is_positive", {
    validate: (value) => {
        return value >= 0
    },
    message: (field) => `${field} cannot be a negative number`
});


// Custom Percentage Share validation message

extend("percentage_share", {
    validate: (value, { values }) => {

        let min = parseInt(values[0])
        let max = parseInt(values[1])

        if(value < min || value > max) {
            return false
        }

        return true
    },
    params: ['values'],
    message: (field, values) => {

        let min = parseInt(values.values[0])
        let max = parseInt(values.values[1])

        if(values._value_ < min) {
            return `${field} cannot be smaller than ${values.values[0]}`
        } else {
            return `${field} cannot be greater than ${values.values[1]}`
        }


    }
});

extend("is_abn", {
    validate: (value, { values }) => {

        const abn = value.replace(/\s|\D/g, '')

        const digits = abn.split('')

        digits[0] = digits[0] - 1

        const weights = [10, 1, 3, 5, 7, 9, 11, 13, 15, 17, 19]

        const sum = digits.reduce((acc, digit, i) => {
            const weight = weights[i]
            if (!weight) {
                return acc
            }
            return acc + digit * weight
        }, 0)

        return sum % 89 === 0
    },
    message: (field) => `${field} Invalid ABN Number`
});

extend("is_bank_account_name", {
    validate: (value, { values }) => {
        return /^[a-zA-Z0-9' -]+$/.test(value)
    },
    message: (field) => {
        return `${field} should only contain letters, numbers, space, hyphen and apostrophe only.`
    }
});

extend('valid_vin', {
    message: (field) => `${field} Invalid VIN`,
    validate: (value, { values }) => {
        console.log({vin: value})
        return /^(?![IOQioq])[A-HJ-NPR-Z0-9]{17}$/.test(value)
    },
})
