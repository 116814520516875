<template>
    <div :disabled="isChangeUrl" class="d-flex flex-entity-selector">
        <v-select 
            class="rounded-xl pa-0 ma-0"
            color="white"
            v-model="form.person" 
            :items="entities" @change="flexRedirect">
            <template v-slot:prepend-inner>
                <v-icon color="accent">mdi-account</v-icon>
            </template>
        </v-select>
    </div>
</template>
<script>

import { mapState, mapGetters, mapActions, mapMutations } from 'vuex'
import subscriptions from '~/components/shared/subscriptions'

export default {
    mixins: [subscriptions],
    props: {
        entities : {
            type: Array,
            default: () => []
        },
        args : {
            type: Object,
            default: () => {}
        }
    },
    computed : {
        selected () {
            return this.$route?.params?.record_id
        },
        listenerDataArgument() {
            return {
                person: {
                    id: this.form.person
                }
            }
        }
    },
    watch: {
        'entities': {
            handler (val) {
                return val  
            }
        },
        'subComponent': {
            handler (val) {
                return val
            }
        },
        selected(val) {
            if(val) {
                this.form.person = val
            }
        }
    },
    mounted() {
        if(this.selected) {
            this.form.person = this.selected
        }
    },
    methods: {
        ...mapMutations('flows', [
            'setAppID'
        ]),
        ...mapActions('resource-actions',['getBusiness','getPerson']),
        async flexRedirect () {
            
            this.isChangeUrl = true
            if(this.args && this.args['EditPersonRecordButton']) {
                
                // call listener method ( __block_flexRedirect )
                // this should technically be a dynamic method call (based on what's defined in the flow config)
                // but we will simplify the flow system soon, at which point this wont be necessary
                // read more here: https://app.clickup.com/t/36609304/FLX-8654
                // for now, we will just hardcode the __block_flexRedirect method call here
                
                this.__block_flexRedirect({
                    url: this.args['EditPersonRecordButton'],
                    data: this.listenerDataArgument,
                    refresh: true
                })
            }
            this.isChangeUrl = false   
        },
        getEntity () {
            if(this.entityType == 'people'){
                
            }
        }
    },
    data () {
        return {
            form: {
                person: ''
            },
            entityType: null,
            isChangeUrl: false
        }
    }
}
</script>
<style lang="scss">

    .flex-entity-selector {

        .v-input {

            .v-select__slot {
                margin: 0 !important;
                padding: 0 !important;
            }

            .v-input__slot {
                margin: 0 !important;
                padding: 0 !important;
                background: white !important;

                &::before,
                &::after {
                    display: none !important;
                }

                .v-input__prepend-inner {
                    padding: 0 !important;
                    margin: 6px 0px 0px 14px !important;
                }

            }

            .v-text-field__details {
                display: none !important;
            }
        }
    }
</style>