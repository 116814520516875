export default {
    /**
     * ########################################################### INCOMES
     */

    // Business Income 12-30
    "12-30": { 
        short: [
            'income-verification-method',
            'profit-before-tax-annual'
        ],
        long: [
            'income-verification-method',
            'reporting-period',
            'income-verification-method',
            'revenue',
            'depreciation',
            'profit-before-tax-annual',
        ]
    },

    // Self-Employed 12-29
    
    "12-29": { 
        short: [
            'income-situation',
            'income-verification-method'
        ],
        long: [
            'income-situation',
            'business-name',
            'income-verification-method',
            'profit-before-tax-annual',
            'net-monthly-amount',
            'start-date',
            'end-date',
        ]
    },

    // Child Support 12-15
    // Centrelink 12-2

    "12-15|12-2|12-3|12-4|12-5|12-6|12-7|12-8|12-9|12-10|12-11|12-12|12-13|12-14": { 
        short: [
            'income-situation',
            'net-monthly-amount'
        ],
        long: [
            'income-situation',
            'other-benefit-description',
            'net-monthly-amount',
            'benefit-amount',
            'income-period',
            'start-date',
            'end-date',
        ]
    },


    // Company Director 12-16
    
    "12-16": { 
        short: [
            'income-situation',
            'income-verification-method'
        ],
        long: [
            'income-situation',
            'income-verification-method',
            'start-date',
        ]
    },



    // Investment Income 12-17
    
    "12-17|12-18|12-19|12-20|12-21|12-22": { 
        short: [
            'income-situation',
            'net-monthly-amount',
        ],
        long: [
            'income-situation',
            'net-monthly-amount',
            'investment-income',
            'income-period',
            'start-date',
            'end-date',
            '__full-address',
        ]
    },


    // No Income 12-23
    
    "12-23": { 
        short: [
            'income-situation',
            'no-income-reason'
        ],
        long: [
            'income-situation',
            'no-income-reason',
            'additional-notes',
            'start-date',
            'end-date',
        ]
    },


    // Other Income 12-24
    
    "12-25|12-26|12-27": { 
        short: [
            'income-situation',
            'net-monthly-amount',
        ],
        long: [
            'income-situation',
            'net-monthly-amount',
            'additional-notes',
            'start-date',
            'end-date',
        ]
    },

    // PAYG Employment 12-28
    
    "12-28": { 
        short: [
            'income-situation',
            'net-monthly-amount',
        ],
        long: [
            'income-situation',
            'net-monthly-amount',
            'employer-name',
            'job-title',
            'income-period',
            'net-standard-pay',
            'start-date',
            'end-date',
        ]
    },


    /**
     * ########################################################### ASSETS
     */


    // Cash in Bank 10-2
    "10-3|10-4|10-5": { 
        short: [
            'asset-value',
            'financial-institution',
        ],
        long: [
            'asset-description',
            'financial-institution',
            'other-institution',
            'asset-value'
        ]
    },

    // Home Contents 10-6
    "10-6": { 
        short: [
            'asset-value'
        ],
        long: [
            'asset-value',
            'covered-by-insurance'
        ]
    },

    // Other Investment 10-7
    "10-8|10-9|10-10|10-11|10-12": { 
        short: [
            'asset-description',
            'asset-value'
        ],
        long: [
            'asset-description',
            'asset-value'
        ]
    },

    // Property - Investment 10-13
    // Property - Owner Occupied 10-15

    "10-14|10-15": { 
        short: [
            '__full-address',
            'asset-value'
        ],
        long: [
            'asset-description',
            '__full-address',
            'asset-value',
            'being-refinanced',
            'being-sold-traded'
        ]
    },


    // Stock & Equipment 10-17

    "10-19|10-20|10-21|10-22|10-23|10-24|10-25|10-26|10-28|10-29|10-30|10-31": { 
        short: [
            'asset-value',
        ],
        long: [
            'asset-description',
            'year-of-manufacture',
            'make',
            'model',
            'asset-value',
            'being-refinanced',
            'being-sold-traded'
        ]
    },

    // Vehicle - Motor 10-32
    // Vehicle - Other 10-34

    "10-33|10-35|10-36|10-37|10-38|10-44|10-45|10-46|10-47|10-48|10-49": { 
        short: [
            'description-other',
            'asset-value'
        ],
        long: [
            'description-other',
            'year-of-manufacture',
            'make',
            'model',
            'asset-value',
            'being-refinanced',
            'being-sold-traded'
        ]
    },


    /**
     * ########################################################### LIABILITY
     */


    // Cards & Credit 13-2

    "13-3|13-4|13-5|13-6|13-7": { 
        short: [
            'financial-institution',
            'credit-limit'
        ],
        long: [
            'liability-description',
            'financial-institution',
            'other-institution',
            'credit-limit',
            'amount-owing'
        ]
    },

    // Loan - Asset 13-8
    // Loan - Other 13-12
    // Other Liability 13-22

    "13-9|13-10|13-11|13-13|13-14|13-15|13-16|13-17|13-23|13-24|13-25|13-26": { 
        short: [
            'financial-institution',
            'amount-owing'
        ],
        long: [
            'liability-description',
            'description-other',
            'financial-institution',
            'other-institution',
            'amount-financed',
            'interest-rate',
            'amount-owing',
            'end-date',
            'to-be-paid-out'
        ]
    },

    // Loan - Real Estate 13-18

    "13-19|13-20|13-21": { 
        short: [
            'financial-institution',
            'amount-owing'
        ],
        long: [
            'liability-description',
            'financial-institution',
            'other-institution',
            'amount-financed',
            'interest-rate',
            'amount-owing',
            'end-date',
            'to-be-paid-out'
        ]
    },


    /**
     * ########################################################### EXPENSES 
     */


    // Living Expenses 11-16

    "11-16": { 
        short: [
            '__monthly-payment-amount',
        ],
        long: [
            "living-food-and-clothing",
            "living-transport",
            "living-utilities-and-rates",
            "living-lifestyle",
            "living-tv-and-communications",
            "living-child-care-fees",
            "living-family-maintenance",
            "living-insurance",
            "living-education",
            "living-other-living-expenses",
            '__sum-living-expenses'
        ]
    },


    // Accommodation Expense 11-2
    // Card & Credit Payment 11-10
    // Loan Payment - Asset Finance 11-17
    // Loan Payment - Other 11-22
    // Loan Payment - Property Investment 11-28
    // Other Expense 11-31

    "11-3|11-4|11-5|11-6|11-7|11-11|11-12|11-13|11-14|11-15|11-18|11-19|11-20|11-21|11-23|11-24|11-25|11-26|11-27|11-29|11-30|11-32|11-33|11-34|11-35": { 
        short: [
            'financial-institution',
            '__monthly-payment-amount',
        ],
        long: [
            'financial-institution',
            'other-institution',
            'payment-amount',
            'payment-frequency',
            '__monthly-payment',
            'to-be-paid-out'
        ]
    },

    //  Expense 11-1
    // ----Renter - Agent 11-8
    // ----Renter - Private 11-9
    "11-8|11-9": { 
        short: [
            'financial-institution',
            '__monthly-payment-amount',
            '__rental-address'
        ],
        long: [
            'financial-institution',
            'other-institution',
            'payment-amount',
            'payment-frequency',
            '__monthly-payment',
            'to-be-paid-out',
            '__rental-address'
        ]
    }

}



