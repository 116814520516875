<template>
    <v-dialog
        v-model="dialog"
        width="800"
        persistent>   
        <v-card flat class="ol-residential-history-5-is-currently-living">
            <v-card-title class="pa-0 ma-0 pr-5 pt-3 mb-n5">
                <v-spacer></v-spacer>
                <v-btn icon rounded small @click="close" depressed>
                    <v-icon color="primary" size="20">mdi-close</v-icon>
                </v-btn>
            </v-card-title>
            <v-card-title class="px-8 py-4 text-center">
                <div class="sm pa-0 text-center" style="width:100%">
                   {{title}}
                </div>
            </v-card-title>
            <v-card-text  :class="vb.mdAndUp ? 'px-16' : 'px-6'">
                <ValidationObserver ref="observer1" tag="form">
                    <v-row>
                        <v-col class="my-0 py-0" v-for="(field,key) in fields" :key="key" cols="12" :md="isFullwidth(field.model)">
                            <ValidationProvider
                                v-if="isVisible(field.model)"
                                v-slot="{ errors }"
                                :rules="field.rules"
                                :name="field.label">
                                    <v-text-field
                                        :error-messages="errors"
                                        :label="field.label"
                                        v-model="form[field.model]">
                                    </v-text-field>
                            </ValidationProvider>
                        </v-col>   
                    </v-row>
               </ValidationObserver> 
            </v-card-text>
            <v-card-text class="d-flex justify-center pb-8" v-if="actionType == 'edit'">
                <v-btn class="px-10 mx-4 secondary" :loading="isSaving" width="140" height="50" depressed large>Update</v-btn>
            </v-card-text>
            <v-card-text class="d-flex justify-center pb-8" v-else>
                <v-btn class="px-10 " width="140" height="50" depressed large @click="back">Back</v-btn>
                <v-btn class="px-10 mx-4 secondary" width="140" height="50" depressed large @click="nextStep">Next</v-btn>
            </v-card-text>
        </v-card>

    </v-dialog>
</template>
<script>
import {store,mutations} from './__store'
import shared from './__shared'
import fieldOptions from '~/components/shared/field-options'
import formHelpers from '~/components/shared/form-helpers.js'
import validation from '~/components/shared/validation'
import { mapState, mapActions, mapGetters } from 'vuex'
export default {
    props : {
        title: {
            type: String,
            default: 'Title'
        },
        step: {
            type: String,
            default: ''
        },
        address: {
            type:Object,
            default: () => {}
        },
        actionType: {
            type:String,
            default: ''
        }
    },
    mixins: [formHelpers,validation,shared],
    watch: {
        dialog(val) {
            if(val) {
                if(this.residentialSituation == 'Renter - Agent')
                    this.visible['business-name'] = true
                else
                    this.visible['business-name'] = false
            }
        },
        "step": {
            handler (val) {
                val == 'landlord-reference-details' ? this.dialog = true : this.dialog = false
            }
        },
        "address": {
            handler (val) {
                if (val.hasOwnProperty('occupancy')){
                    this.form['residential-situation'] = val.occupancy.attributes['residential-situation']
                } else {
                    this.form['residential-situation'] = ""
                }
            }
        },
        'actionType': {
            handler (val) {
                return val
            }
        },
        'form.email' : {
            handler(val) {
                //console.log(this.fields);
                this.fields.forEach((item) => {
                    if(item.model == 'phone') {
                        if(val) {
                            item.rules = ''
                        } else {
                            item.rules = 'required'
                        }
                    }
                });
            },
            deep: true
        },
        'form.phone' : {
            handler(val) {
                this.fields.forEach((item) => {
                    if(item.model == 'email') {
                        if(val) {
                            item.rules = ''
                        } else {
                            item.rules = 'required|email'
                        }
                    }
                });
            },
            deep: true
        }
    },
    methods: {
        ...mapActions('resource-actions',['updateOccupancy']),
        async nextStep(data) {
           let valid = await this.$refs.observer1.validate();
           if(valid){
                let referenceAttr = {
                    'name': this.form['first-name'] + " " +  this.form['last-name'],
                    'email': this.form['email'],
                    'phone': this.form['phone'],
                    'business-name': this.form['business-name'],
                    'relationship': store.occupancyAttributes['residential-situation'] == 'Renter - Agent' ? 'Landlord - RE Agent' : 'Landlord - Private'
                }
                mutations.setReferenceAttributes(referenceAttr)
                let partsRelationships = {kinds: {data: {id: '3-11' , type:'kinds'}}}
                mutations.setPartsRelationships(partsRelationships)
                this.$emit('step','reference-expense')
                this.resetFields();
           }
            
        },
        back() {
            this.$emit('back','accommodation-expense')
        },
        resetFields() {
            this.fields = [
                {model: 'business-name', label: 'Agent Business Name',rules:"required"},
                {model: 'first-name', label: 'First Name',rules:"required"},
                {model: 'last-name', label: 'Last Name',rules:"required"},
                {model: 'email', label: 'Email', rules:"required|email"},
                {model: 'phone', label: 'Phone',rules:"required"},
            ]
        },
        close(){
           this.$emit('back',null)
        },
        isFullwidth(key) {
            let keys = [
                'business-name'
            ]

            if(keys.includes(key)) {
                return 12
            }

            return 6
        }
        
    },
    data() {
        return {
            isSaving: false,
            dialog : false,
            visible: {
                'business-name': false
            },
            fields : [
                {model: 'business-name', label: 'Agent Business Name',rules:"required"},
                {model: 'first-name', label: 'First Name',rules:"required"},
                {model: 'last-name', label: 'Last Name',rules:"required"},
                {model: 'email', label: 'Email', rules:"required|email"},
                {model: 'phone', label: 'Phone',rules:"required"},
            ],
            form:{
                "business-name": '',
                "first-name": '',
                "last-name": '',
                "email": '',
                "phone": '',
            },
            items: fieldOptions['residential-situation'],
            resources: {
                addresses: {
                    type: 'addresses',
                        attributes: {
                            "street-number": null,
                            "street-name": null,
                            "street-type": null,
                            "suburb": null,
                            "state": null,
                            "postcode": null
                        },
                        relationships: {
                            application: {
                                data: { type: "applications", id: null } 
                            }
                        }
                    },
                    occupancies: {
                        type: 'occupancies',
                        attributes: {
                            "residential-situation": null,
                        },
                        relationships: {
                            application: {
                                data: { type: "applications", id:  null} 
                            },
                            address: {
                                data: {
                                    type: "addresses",
                                    id: null
                                }
                            },
                            person: {
                                data: {
                                    type: "people",
                                    id: null
                                }
                            }
                        }
                    },
            }
        }
    }
}
</script>
<style lang="scss" >
    
    .ol-residential-history-5-is-currently-living {
        .btn {
            text-align: center;
            padding: 50px;
            border-radius: 20px;
            font-size: 15px;
            border: 1px solid;
            cursor: pointer;
            width: 250px !important;
            height: 150px !important;
        }
        .btn:hover{
            background: #E7FAFC;
        }
        .custom-outline1{
            border-color: var(--v-secondary-base);
        }
        .close-icon {
            position: absolute;
            right: 40px;
            top: 30px;
            // background: var(--v-secondary-lighten2);
            color: var(--v-secondary-base)!important;
            padding: 6px;
            border-radius: 36px !important;
            i {
                color: var(--v-secondary-base) !important;
            }
        }
    }
</style>