<template>
  <div>
    <v-card :disabled="isDisabled" class="mx-auto" tile flat>
      <v-card-text class="pa-4 pt-0 pa-md-10 pt-md-0">
        <v-container pa-0 fluid>
          <v-row>
            <v-col cols="12">
              <v-data-table
                :loading="false"
                loading-text="Please wait..."
                :items="people"
                hide-default-footer
                :mobile-breakpoint="0"
                hide-default-header
                class="v-data-table-6 mx-auto"
              >
                <!-- Body -->
                <template v-if="vb.mdAndUp" v-slot:item="{ item, index }">
                  <v-card
                    tile
                    flat
                    class="mb-6 pa-2 rounded-xl"
                    color="light_gray_1"
                  >
                    <div
                      class="
                        d-flex
                        justify-space-between
                        align-center
                        py-2
                        px-4
                        flex-table-row
                      "
                    >
                      <div class="d-flex flex-column">
                        <div class="mr-6">
                          <v-icon size="32">mdi-account</v-icon>
                        </div>
                      </div>

                      <div class="d-flex flex-column">
                        <div class="mr-6 body-1 font-weight-medium">
                          {{ getFriendlyName(item) }}
                        </div>
                      </div>

                      <div class="d-flex flex-column">
                        <div>
                          <v-chip
                            :filter="false"
                            :ripple="false"
                            :style="`background-color: ${hexToRGBA(
                              getActiveTheme.colors.secondary,
                              0.2
                            )}`"
                            class="px-5 font-weight-medium"
                          >
                            {{
                              item.roles.length > 1
                                ? `${item.roles[0]} +${item.roles.length - 1}`
                                : item.roles.length == 1
                                ? item.roles[0]
                                : ""
                            }}
                          </v-chip>
                        </div>
                      </div>

                      <v-spacer></v-spacer>

                      <div class="d-flex justify-end">
                        <v-btn
                          depressed
                          small
                          @click="handleAddDetails(item)"
                          :color="
                            getCompleted.includes(item.id)
                              ? 'success'
                              : 'secondary'
                          "
                        >
                          {{
                            getCompleted.includes(item.id)
                              ? "Completed"
                              : "Add Details"
                          }}
                        </v-btn>
                      </div>
                    </div>
                  </v-card>
                </template>
                <!-- mobile version -->
                <template v-else v-slot:item="{ item, index }">
                  <v-card
                    tile
                    flat
                    class="mb-6 pa-2 rounded-lg"
                    color="light_gray_1"
                  >
                    <v-card-text class="d-flex pb-1">
                      <v-icon size="32" class="mt-n2">mdi-account</v-icon>
                      <div class="body-1 font-weight-medium pl-1">
                        {{ getFriendlyName(item) }}
                      </div>
                    </v-card-text>
                    <v-card-text class="pt-0">
                      <v-chip
                        :filter="false"
                        :ripple="false"
                        :style="`background-color: ${hexToRGBA(
                          getActiveTheme.colors.secondary,
                          0.2
                        )}`"
                        class="px-5 font-weight-medium"
                      >
                        {{
                          item.roles.length > 1
                            ? `${item.roles[0]} +${item.roles.length - 1}`
                            : item.roles.length == 1
                            ? item.roles[0]
                            : ""
                        }}
                      </v-chip>
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer></v-spacer>

                      <v-btn
                        depressed
                        small
                        @click="handleAddDetails(item)"
                        :color="
                          getCompleted.includes(item.id)
                            ? 'success'
                            : 'secondary'
                        "
                      >
                        {{
                          getCompleted.includes(item.id)
                            ? "Completed"
                            : "Add Details"
                        }}
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </template>
              </v-data-table>

              <v-alert dense outlined type="error" v-if="errors.length > 0">
                <div v-for="msg in errors" v-html="msg"></div>
              </v-alert>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>

    <!-- Modal -->
    <v-dialog
      max-width="844"
      persistent
      scrollable
      v-model="requirementsDialog"
    >
      <v-card flat :disabled="requirementsDialogLoading">
        <v-progress-linear
          color="secondary"
          indeterminate
          stream
          absolute
          top
          v-if="requirementsDialogLoading"
        ></v-progress-linear>

        <v-card-title class="justify-end">
          <v-btn
            icon
            max-width="40"
            max-height="40"
            :style="`background-color: ${hexToRGBA(
              getActiveTheme.colors.secondary,
              0.1
            )}`"
            @click="closeDialog"
            fab
          >
            <v-icon color="secondary">mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text class="px-0 py-0 pb-8">
          <v-carousel
            light
            v-model="carousel"
            :continuous="false"
            :show-arrows="false"
            hide-delimiters
            touchless
            height="auto"
            hide-delimiter-background
          >
            <v-carousel-item v-for="(step, key) in steps" :key="key">
              <v-card flat>
                <v-card-title
                  class="d-flex py-0"
                  :class="vb.mdAndUp ? 'px-8' : 'px-4'"
                >
                  <div>
                    <h4 :class="vb.mdAndUp ? 'font-size-26' : 'font-size-18'">
                      {{ step.title }} | {{ getFriendlyName(selectedEntity) }}
                    </h4>
                    <p :class="vb.mdAndUp ? 'body-1' : 'caption'">
                      {{ step.subtitle }}
                    </p>
                  </div>
                  <v-spacer />
                </v-card-title>

                <v-card-text class="px-0">
                  <template
                    v-if="key == 'personal' || key === 'personal-secondary'"
                  >
                    <FormPersonalInformation
                      :ref="step['form-ref']"
                      :updater="formUpdater"
                      :data="selectedEntity"
                      :applicant-type="applicantType"
                      :term="term"
                      :isPrivacyConsentExists="isPrivacyConsentExists"
                      @loading="handleLoading"
                      @submit="handleFormCitizenshipInfoSubmit"
                    />
                  </template>

                  <template
                    v-if="
                      key == 'citizenship' || key === 'citizenship-secondary'
                    "
                  >
                    <FormCitizenshipInfo
                      :ref="step['form-ref']"
                      :updater="formUpdater"
                      :data="selectedEntity"
                      :applicant-type="applicantType"
                      :income="selectedEntityIncome"
                      :incomes="incomes"
                      @loading="handleLoading"
                      @submit="handleFormCitizenshipInfoSubmit"
                    />
                  </template>
                </v-card-text>
              </v-card>
            </v-carousel-item>
          </v-carousel>
        </v-card-text>

        <v-card-actions class="pa-4 pa-md-6 flex-wrap controls">
          <v-btn
            v-if="!isFirstStep"
            color="light_gray_2"
            class="flex-back-button"
            :min-width="140"
            large
            outlined
            @click="handleBack"
            elevation="0"
            nuxt
          >
            Back
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="secondary"
            :min-width="140"
            large
            elevation="0"
            @click="handleNext"
            nuxt
          >
            {{ isLastStep ? "Save" : "Next" }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <HelperModal v-if="vb.smAndDown" />
  </div>
</template>

<script>
import { mapActions, mapState, mapMutations } from "vuex";
import subscriptions from "~/components/shared/subscriptions";
import shared from "~/components/v1/submission/LenderPepperEntities1/__shared";

import {
  store,
  actions,
} from "~/components/v1/submission/LenderPepperEntities1/__store";

import FormCitizenshipInfo from "~/components/v1/submission/LenderPepperEntities1/_FormCitizenshipInfo";
import FormPersonalInformation from "~/components/v1/submission/LenderPepperEntities1/_FormPersonalInformation";

import HelperModal from "~/components/base/LenderHelperDialog";

export default {
  components: {
    FormCitizenshipInfo,
    FormPersonalInformation,
    HelperModal,
  },

  mixins: [subscriptions, shared],

  data() {
    return {
      requirementsDialog: false,
      requirementsDialogLoading: false,

      people: [],
      incomes: [],

      peopleIncluded: [],

      selectedEntity: null,
      selectedEntityIncome: null,

      errors: [],
      secondaryApplicantId: null,
      applicantsCommitments: {
        primary: null,
        secondary: null,
      },
      term: {
        attributes: {
          content: "",
        },
      },
    };
  },

  computed: {
    ...mapState("flows", [
      "lenderSubmissionTo",
      "calculation",
      "termGroup",
      "commitments",
    ]),
    vb() {
      return this.$vuetify.breakpoint;
    },
    getApplicantKinds() {
      let parts = this.kinds.find((kind) => {
        if (kind.name == "Part") {
          return kind;
        }
      });

      return parts.children;
    },
    carousel: {
      get() {
        return store.carousel;
      },
      set(value) {
        actions.setCarousel(value);
      },
    },
    steps() {
      return store.steps;
    },
    formUpdater() {
      return this.requirementsDialog;
    },
    isLastStep() {
      if (this.carousel == Object.keys(this.steps).length - 1) {
        return true;
      }

      return false;
    },
    isFirstStep() {
      if (this.carousel == 0) {
        return true;
      }

      return false;
    },
    getCompleted() {
      const { people, incomes } = this;

      let completed = [];

      people.forEach((person) => {
        let relatedPeople = false;
        let relatedIncomes = false;

        if (person.attributes["lender-pepper-residency"]) {
          relatedPeople = true;
        }

        if (person.relationships.incomes.data.length > 0) {
          relatedIncomes = person.relationships.incomes.data.every((data) => {
            return incomes.every((income) => {
              if(this.centrelinkKinds.includes(income?.relationships?.kind?.data?.id )){
                return true
              }
              if (income.id == data.id) {
                return income.attributes["lender-pepper-industry"] && income.attributes["lender-pepper-occupation"]
              }
              return true
            });
          });
        }

        if (relatedPeople && relatedIncomes) {
          completed.push(person.id);
        }
      });

      return completed;
    },
    originationFee() {
      let fee = 0;

      if (this.blockSettings && this.blockSettings.originationFee) {
        fee = this.blockSettings.originationFee;
      }

      return fee;
    },
    getLoanId() {
      return this.appData?.relationships?.loan?.data?.id;
    },
    applicantType() {
      return this.selectedEntity &&
        this.selectedEntity.id === this.secondaryApplicantId
        ? "secondary"
        : "primary";
    },
    isJoint() {
      return this.calculation["application-type"].includes("Consumer - Joint");
    },
    primaryApplicantId(){
      return this.calculation['primary-applicant-id']
    },
    isPrivacyConsentExists() {

      let isPrimary = (this.selectedEntity?.id == this.primaryApplicantId)
      
      if (isPrimary && this.applicantsCommitments.primary) {
        return true;
      }
      
      if (!isPrimary && this.applicantsCommitments.secondary) {
        return true;
      }

      return false;
    },

  },

  methods: {
    ...mapActions("resource-actions", [
      "getLoan",
      "getPeople",
      "getBusinesses",
      "getIncomes",
      "getExpenses",
      "updateLoan",
      "updatePerson",
      "updateIncome",
      "getOccupancies",
      "getOccupancy",
      "createCommitments",
      "getTerms",
    ]),
    ...mapMutations("flows", ["setCommitments"]),
    ...mapMutations("dialogs", ["toggleLenderSubmissionHelperDialog"]),
    async pull() {

      this.getOccupancies();
      let promise1 = this.getLoan(this.getLoanId, { include: "parent-parts" });
      let promise2 = this.getPeople({ include: "parent-parts" });
      let promise3 = this.getIncomes();
      let promise4 = this.getTerms(this.termGroup);
      let promise5 = this.$api.get(`applications/${this.appId}/commitments`)

      // Get the secondary applicant id

      const res = await Promise.all([
        promise1,
        promise2,
        promise3,
        promise4,
        promise5
      ]);

      promise1 = res[0];
      promise2 = res[1];
      promise3 = res[2];
      promise4 = res[3];
      promise5 = res[4]

      if (this.isJoint && promise2.data.included) {
        promise2.data.included.forEach((part) => {
          if (part.attributes.priority == 2) {
            this.secondaryApplicantId = part.relationships.child.data.id;
          }
        });
      }

      if (promise1.data?.data?.relationships?.kind?.data?.id) {
        this.loanKindId = promise1.data.data.relationships.kind.data.id;
      }

      this.people = this.getPeopleByRoles(promise2.data);
      this.incomes = promise3?.data?.data ? promise3.data.data : [];

      if (promise2.data.included) {
        this.parts = promise2.data.included.filter((part) => {
          return part.type == "parts";
        });
      }

      if (promise4.data?.data?.length) {
        this.term = promise4.data.data.find(
          (item) => item.attributes.slug === "pepper-privacy-001"
        );
      }

      if(promise5.data?.data?.length) {

            let commitments = promise5.data.data

            let commitmentsWithPrivacyConsent = []

            commitments.forEach(commitment => {
              commitment.relationships.terms.data.forEach(term => {
                  if(term.id == this.term.id) {
                    commitmentsWithPrivacyConsent.push(commitment)
                  }
              })
            })

            commitmentsWithPrivacyConsent.forEach(commitment => {
              commitment.relationships["committed-people"].data.forEach(person => {

                  if(person.id == this.primaryApplicantId) {
                    this.applicantsCommitments.primary = commitment
                  }

                  if( this.isJoint && person.id == this.secondaryApplicantId) {
                    this.applicantsCommitments.secondary = commitment
                  }

              })
            })

      }

      this.peopleIncluded = promise2.data.included;
    },
    async push() {
      this.checkCompleted();

      let valid = this.errors.length == 0;

      let status = valid ? "valid" : "dirty";

      if (valid) {
        console.log(this.form);
      }

      return { valid, status, data: {} };
    },
    closeDialog() {
      this.requirementsDialog = false;
      actions.resetStore();
    },
    checkCompleted() {
      this.errors = [];

      if (this.getCompleted.length != this.people.length) {
        this.errors.push("Please complete all requirements");
      }
    },

    showHelperModal() {
      this.toggleLenderSubmissionHelperDialog(true);
    },

    async handleAddDetails(data) {
      this.requirementsDialog = true;
      this.selectedEntity = data;

      // Find selected entity's current income

      let entityCurrentIncome = null;
      if (data.relationships.incomes.data.length > 0) {
        let entityIncomeIds = data.relationships.incomes.data.map(i => i.id);
        entityCurrentIncome = this.incomes.find((income) => entityIncomeIds.includes(income.id) && income.attributes["income-situation"] == "Current");
      }
      
      this.selectedEntityIncome = entityCurrentIncome;
    },

    async handleNext() {
      let stepsArray = Object.keys(this.steps);

      switch (stepsArray[this.carousel]) {
        case "personal":
          if (this.$refs.PersonalInformationForm[0]) {
            await this.$refs.PersonalInformationForm[0].validate();
          }

          break;
        case "citizenship":
          if (this.$refs.CitizenshipInformationForm[0]) {
            await this.$refs.CitizenshipInformationForm[0].validate();
          }

          break;
        default:
          this.carousel++;

          break;
      }
    },
    async handleBack() {
      this.carousel--;
    },

    async handleFormCitizenshipInfoSubmit(payload) {
      // console.log(payload)
      this.requirementsDialogLoading = true;

      if (payload.person.hasOwnProperty("id") || payload.incomes.id) {

        let res2 = {};
        let res = await this.updatePerson(payload.person);

        if (payload.income && payload.income.id) {
          res2 = await this.updateIncome({
            ...payload.income,
            attributes: {
              ...payload.person.attributes,
                lender: {
                  pepper: {
                    industry: payload.person.attributes["lender-pepper-industry"]
                  }
                },
              "lender-pepper-occupation":
                payload.person.attributes["lender-pepper-occupation"],
            },
          });
        }

        if (res.status == 200 || res2.status == 200) {
          this.updateLocalResourceAttributes(res.data.data);

          if (payload.income && payload.income.id) {
            this.pull();
            setTimeout(() => {
              this.requirementsDialogLoading = false;
              this.requirementsDialog = false;
              actions.resetStore();
            }, 600);
          } else {

            actions.setCarouselNext();

            this.requirementsDialogLoading = false;

            if (!this.isPrivacyConsentExists) {
              let payload = {
                type: "commitments",
                attributes: {},
                relationships: {
                  application: {
                    data: {
                      type: "applications",
                      id: this.appId,
                    },
                  },
                  "committed-users": {
                    data: [
                      {
                        type: "users",
                        id: this.appData.relationships.owner?.data?.id,
                      },
                    ],
                  },
                  "committed-people": {
                    data: [
                      {
                        id: this.selectedEntity?.id,
                        type: "people",
                      }
                    ],
                  },
                  terms: {
                    data: [
                      {
                        id: this.term.id,
                        type: "terms",
                      },
                    ], // all the terms
                  },
                },
              };

              let res = await this.createCommitments(payload);

              this.setCommitments(res.data.data);

            } 

          }
        } else {
          this.requirementsDialogLoading = false;
        }


      }
    },
    async handleFormEmploymentInfoSubmit(payload) {
      // console.log(payload)

      this.requirementsDialogLoading = true;

      if (payload.hasOwnProperty("id")) {
        let res = await this.updateIncome(payload);

        if (res.status == 200) {
          this.updateLocalResourceAttributes(res.data.data);

          setTimeout(() => {
            this.requirementsDialogLoading = false;
            this.requirementsDialog = false;
            actions.resetStore();
          }, 600);
        } else {
          this.requirementsDialogLoading = false;
        }
      }
    },
    updateLocalResourceAttributes(resource) {
      if (!resource) return;

      let getType = resource.type;

      this[getType].forEach((v, i) => {
        if (v.id == resource.id) {
          this[getType][i]["attributes"] = resource.attributes;
        }
      });
    },
    handleLoading(payload) {
      this.requirementsDialogLoading = payload;
    },
  },
};
</script>

<style lang="scss" scoped></style>
