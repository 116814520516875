<template>
    <v-dialog
        persistent
        scrollable
        max-width="600"
        v-model="dialog">

        <v-card flat tile :disabled="processing" class="pa-4">

            <v-progress-linear color="secondary" indeterminate stream absolute top v-if="processing"></v-progress-linear>

            <v-card-title class="px-8 d-flex">
                <div class="v-card__title pa-0">
                    <div class="text-h4 font-weight-bold">Submit Application to</div>
                </div>
                <v-spacer />
            </v-card-title>

            <v-card-text class="px-8 pt-4" >
                <v-row class="py-4 justify-space-between">
                    <v-col v-if="!hasActiveAssisterSub" cols="12" class="text-center" >
                        <v-card
                            outlined 
                            class="rounded-lg pa-2 mx-auto d-flex align-center"
                            min-height="155"
                            max-width="350"
                            @click="submitToAssister">
                            <v-card-text>
                                <AssisterImage/>                                
                            </v-card-text>
                        </v-card>
                    </v-col>
                    <v-col v-if="selectedLender && hasLenderFlow && appTarget != 'Assister'" cols="12" class="text-center">
                        <v-card
                            outlined 
                            class="rounded-lg pa-2 mx-auto d-flex align-center"
                            min-height="155"
                            max-width="350"
                            @click="submitToLender(selectedLender)">
                                <v-card-text>
                                    <v-img 
                                    :src="require(`~/assets/images/logo/cropped/${lenderLogo}.png`)"
                                    position="center center" 
                                    contain
                                    class="mx-auto"
                                    max-height="100"
                                    max-width="150"/>
                                </v-card-text>
                        </v-card>
                    </v-col>
                    <v-col cols="12" class="text-center">
                        <v-card
                            outlined 
                            class="rounded-lg pa-2 mx-auto"
                            min-height="155"
                            max-width="350"
                            @click="showSubmitOtherField">
                                <v-card-title class="justify-center mt-6">
                                    Other
                            </v-card-title>
                        </v-card>
                    </v-col>
                </v-row>
            </v-card-text>

            <v-card-actions class="px-8 pt-4 pb-6">
                <v-btn @click="closeModal" :min-width="140" large depressed outlined color="light_gray_2">
                    Back
                </v-btn>
            </v-card-actions>

            <OtherField 
                :value="showOtherField"
                :processing="processing"
                @save-other-lender="submitToOther"
                @close-modal="showOtherField = false" />

        </v-card>

    </v-dialog>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
import lenders from '~/config/lenders-slug-algo'
import OtherField from '~/components/base/OtherSubmitModal'
import AssisterImage from '~/components/base/AssisterImage'

export default {

    components: { OtherField, AssisterImage },

    props: {
        dialog: {
            type: Boolean,
            default: true
        },
        assisters: {
            type: Array,
            default: () => []
        },
        selectedLender: {
            type: String,
            default: ''
        },
        appTarget: {
            type: String,
            default: ''
        },
        saving: {
            type: Boolean,
            default: false
        },
        hasLenderFlow: {
            type: Boolean,
            default: false
        },
        hasActiveAssisterSub: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            showOtherField: false,
            processing: false
        }
    },
    computed: {

        ...mapGetters('flows', ['getSteps']),
        ...mapGetters('slug', [
            'getGroupSlug',
        ]),
        
        lenderLogo() {

            let { selectedLender } = this
            let logo = 'placeholder'

            if(selectedLender) {

                Object.keys(lenders).forEach(lender => {

                    lenders[lender].forEach(data => {

                        if(data['lender-slug-algorithm'] == selectedLender) {
                            logo = data['logo-class']
                        }

                    })

                })

            }

            return logo

        }
        
    },
    methods: {
        ...mapActions('flows', ['selectStep']),
        submitToLender(lender){
            this.$emit('submit-to-lender', lender)
        },
        submitToAssister() {
            this.$emit('submit-to-assister')
        },
        showSubmitOtherField() {
            this.showOtherField = true
        },
        submitToOther(form) {
            this.$emit('submit-to-other', form)
        },
        closeModal(){
            this.$emit('close-modal')
        }
    },
    watch: {
        saving(val) {
            this.processing = val
        }
    }
}
</script>
<style lang="scss" scoped>
    .lender-logo .col {
        border: 1px solid #E3E3E3;
        padding: 45px;
        width: 100%;
        border-radius: 12px;
        max-width: 48% !important;
    }
</style>
