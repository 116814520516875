import { processStackTrace } from '../utils/sourceMap';
import Vue from 'vue';
import axios from 'axios';

Vue.config.errorHandler = async function (err) {
  axios.post('/log-error', {
      type: err.name,
      title: err.message,
      content: await processStackTrace(err)
  });
};

export default async ({ $axios}) => {
    
    if (process.client) {
        window.addEventListener('unhandledrejection', async (event) => {
            const error = event.reason;

            if (error instanceof Error) {
                $axios.post('/log-error', { 
                    type: "Unhandled Promise Rejection",
                    title: error?.message,
                    content: await processStackTrace(error)
                });
            }
        });
    }
}