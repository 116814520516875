<template>
    <v-row justify="center" align="center">
        <v-col cols="12" align="center">

            <v-card :disabled="disabled" tile flat class="rounded-lg" max-width="400">

                <v-progress-linear 
                    v-if="disabled"
                    color="secondary"
                    indeterminate stream top 
                    value="0">
                </v-progress-linear>

                <v-card-title :class="`px-10 pt-10 pb-4`">
                    <div v-html="formTitle" class="mx-auto"></div>
                </v-card-title>

                <ValidationObserver ref="observer">

                    <div v-if="isForgotPassword">
                        
                        <v-card-text class="px-10 pb-0">

                            <div
                                class="caption py-4">
                                Enter your email in the box below, then hit <strong>send</strong> to get your reset link
                            </div>

                            <ValidationProvider 
                                v-slot="{ errors }" 
                                name="Email" 
                                rules="required|email">
                                <v-text-field                                
                                    v-model="params.email"
                                    label="Email"
                                    color="secondary"
                                    :error-messages="errors"
                                    placeholder=" "></v-text-field>
                            </ValidationProvider>
                        </v-card-text>
                        <v-card-actions class="px-10 pb-4">
                            <v-btn 
                                :loading="disabled"
                                color="secondary" large block elevation="0" 
                                @click="sendForgotPassword">
                                Send
                            </v-btn>
                        </v-card-actions>
                        <v-card-actions class="px-10 pb-6 justify-end">
                            <div v-if="isLoanMarket && !$route.query.app" class="pb-4">
                                <v-btn class="caption primary--text" to="/login" small text>Login with Nodifi</v-btn>
                            </div>      
                        </v-card-actions>
                    </div> 
                    
                    <!-- Nodifi UI -->
                    <div v-else>

                        <template v-if="!showTwoFactorField">
                            <v-card-text class="px-10 pb-0">

                                <ValidationProvider 
                                    v-if="isRegistration"
                                    v-slot="{ errors }" 
                                    name="Email" 
                                    rules="required|email">
                                    <v-text-field                                
                                        v-model="params.email"
                                        label="Email"
                                        color="secondary"
                                        :error-messages="errors"
                                        placeholder=" "></v-text-field>
                                </ValidationProvider>

                                <ValidationProvider 
                                    v-if="!isRegistration || !isForgotPassword"
                                    v-slot="{ errors }" 
                                    name="Username" 
                                    rules="required">
                                    <v-text-field
                                        v-model="params.username"
                                        label="Username or Email"
                                        color="secondary"
                                        :error-messages="errors"
                                        @keyup.enter="handleEnter"
                                        data-field="username"
                                        placeholder=" ">
                                    </v-text-field>
                                </ValidationProvider>

                                <ValidationProvider 
                                    v-if="!isRegistration || !isForgotPassword"
                                    v-slot="{ errors }" 
                                    name="Password" 
                                    rules="required">
                                    <v-text-field
                                        :type="showPassword ? 'text' : 'password'"
                                        v-model="params.password"
                                        label="Password"
                                        color="secondary"
                                        :error-messages="errors"
                                        @keyup.enter="handleEnter"
                                        :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                                        @click:append="showPassword = !showPassword"
                                        data-field="password"
                                        placeholder=" ">
                                    </v-text-field>
                                </ValidationProvider> 

                            </v-card-text>

                            <v-card-actions 
                                v-if="!isForgotPassword"
                                class="px-10 pt-0">
                                <v-spacer></v-spacer>
                                <v-btn 
                                    to="/account/forgot-password"
                                    small text>
                                    <span 
                                        class="font-weight-regular light_gray_4--text">
                                        Forgot Password?
                                    </span>
                                </v-btn>
                            </v-card-actions>

                            <v-card-actions class="px-10 pb-4" :class="!showCRMLogin ? 'pb-12' : ''">
                                <v-btn 
                                    v-if="!isRegistration || !isForgotPassword"
                                    color="secondary" large block elevation="0" 
                                    :loading="disabled"
                                    data-btn="login-btn"
                                    @click="validate">
                                    {{ okButton }}
                                </v-btn>
                                <v-btn 
                                    v-if="isForgotPassword"
                                    :loading="disabled"
                                    color="secondary" large block elevation="0" 
                                    @click="sendForgotPassword">
                                    Send
                                </v-btn>
                            </v-card-actions>

                            <v-card-actions v-if="showCRMLogin && !isForgotPassword" class="px-10 pb-10 d-flex flex-column">
                                <div class="mb-4">OR</div>
                                <v-btn 
                                    color="secondary" large block elevation="0" 
                                    @click="gotoOktaLogin">
                                    Login with MyCRM
                                </v-btn>
                            </v-card-actions>
                        </template>

                        <template v-else>
                            <v-card-text class="px-10 pb-0">
                                <ValidationProvider 
                                    v-slot="{ errors }" 
                                    name="OTP">
                                    <v-text-field
                                        v-model="code"
                                        label="One Time Password"
                                        color="secondary"
                                        :error-messages="errors"
                                        @keyup.enter="handleEnter"
                                        data-field="code"
                                        placeholder="">
                                    </v-text-field>
                                </ValidationProvider>   
                            </v-card-text>
                            <v-card-actions class="px-10 pb-4">
                                <v-btn 
                                    color="secondary" large block 
                                    :disabled="disableOTPButton"
                                    elevation="0" 
                                    @click="validate">
                                    Send
                                </v-btn>
                            </v-card-actions>
                        </template>


                    </div>
                </ValidationObserver>

            </v-card>
        </v-col>
    </v-row>
</template>

<script>

    import { mapActions, mapGetters, mapMutations } from 'vuex'
    import { ValidationObserver, ValidationProvider } from 'vee-validate'

    export default {
        name: 'AuthForm',
        props: {
            submitForm: null,
            formTitle: null, 
            isRegistration: null, 
            isForgotPassword: null, 
            okButton: null,
            showTwoFactorField: {
                type: Boolean,
                default: false
            },
        },
        components: {
            ValidationObserver,
            ValidationProvider
        },
        computed: {
            ...mapGetters('slug', [
                'getGroupSlug',
                'getTenant',
            ]),
            ...mapGetters([
                'getCRMTenant'
            ]),
            isLoanMarket(){
                return this.getTenant == "loanmarket"
            },
            getPasswordResetLink() {
                let url = `${process.env.baseUrl}${this.$route.fullPath}`
                if ( this.getGroupSlug ) url = `${this.getGroupSlug}.${url}`
                return `${window.location.origin}/account/reset`
            },
            disableOTPButton(){
                return this.code.length < 4
            }
        },
        mounted() {
            if(this.getCRMTenant.includes(this.getTenant)) this.showCRMLogin = true        
        },
        data() {
            return {
                showCRMLogin: false,
                disabled: false,
                code: '',
                params: {
                    email: '',
                    username: '',
                    password: '',
                },
                showPassword: false
            }
        },
        methods: {
            ...mapActions('auth-actions', [
                'forgotPassword'
            ]),
            ...mapMutations('utils', [
                'toggleQuickMessage'
            ]),
            gotoOktaLogin() {
                let url = `https://${this.getTenant}.${process.env.envDomain}/login/okta`
                window.location.href = url
            },
            async validate(){
                try {

                    this.disabled = true

                    const valid = await this.$refs.observer.validate()

                    if(valid) {

                        let payload = {
                            ...this.params
                        }

                        if(this.showTwoFactorField) {
                            payload.code = this.code
                        }

                        let data = await this.submitForm(payload)

                        if (data) {
                            this.disabled = false
                        }

                    } else {
                            this.disabled = false
                    }

                } catch (err) {

                    this.disabled = false
                    
                }

            },

            async sendForgotPassword() {
                
                this.disabled = true

                const valid = await this.$refs.observer.validate()

                if ( valid ) {
                
                    let res = await this.forgotPassword({                    
                        "attributes":{
                            "reset-url": this.getPasswordResetLink,
                            "email": this.params.email
                        }
                    })

                    if ( res.status === 200 ) {
                        this.toggleQuickMessage({
                            text: res.data.message, 
                            color: res.data.status
                        })
                    }
                }

                this.disabled = false
            },

            handleEnter(){

                this.validate()

            }
        }
    }
</script>

<style lang="scss" scoped>

</style>