<template>
<div>

    <v-card :disabled="isDisabled" class="mx-auto" tile flat>
        <v-card-text class="pa-4 pt-0 pa-md-10 pt-md-0">
            <v-container pa-0 fluid>
                <v-row>
                    <v-col cols="12">
                                
                        <v-data-table
                            :loading="false"
                            loading-text="Please wait..."
                            :items="people"
                            hide-default-footer
                            :mobile-breakpoint="0"
                            hide-default-header
                            class="v-data-table-6 mx-auto">

                            <!-- Body -->
                            <template v-if="vb.mdAndUp" v-slot:item="{ item, index }">
                                <v-card tile flat class="mb-6 pa-2 rounded-xl" color="light_gray_1">

                                    <div class="d-flex justify-space-between align-center py-2 px-4 flex-table-row">

                                    <div class="d-flex flex-column">
                                            <div class="mr-6"> 
                                                <v-icon size="32">mdi-account</v-icon>
                                            </div>
                                        </div>

                                        <div class="d-flex flex-column">
                                            <div class="mr-6 body-1 font-weight-medium">
                                                {{ getFriendlyName(item) }}
                                            </div>
                                        </div>

                                        <div class="d-flex flex-column">
                                            <div>

                                                <v-chip :filter="false" :ripple="false" 
                                                    :style="`background-color: ${hexToRGBA(getActiveTheme.colors.secondary, 0.2)}`"
                                                    class="px-5 font-weight-medium">
                                                    {{ item.roles.length > 1 ? `${item.roles[0]} +${item.roles.length - 1}`: item.roles.length == 1 ? item.roles[0] : '' }}
                                                </v-chip>

                                            </div>
                                        </div>

                                        <v-spacer></v-spacer>

                                        <div class="d-flex justify-end">
                                            <v-btn 
                                                depressed 
                                                small
                                                @click="handleAddDetails(item)"
                                                :color="getCompleted.includes(item.id) ? 'success' : 'secondary'">
                                                {{ getCompleted.includes(item.id) ? 'Completed' : 'Add Details' }}
                                            </v-btn>
                                        </div>
                                    </div>


                                </v-card>
                            </template>

                            <!-- mobile version -->
                            <template v-else v-slot:item="{ item, index }">
                                <v-card tile flat class="mb-6 pa-2 rounded-lg" color="light_gray_1">
                                    <v-card-text class="d-flex pb-1">
                                        <v-icon size="32" class="mt-n2">mdi-account</v-icon>
                                        <div class="body-1 font-weight-medium pl-1">
                                            {{ getFriendlyName(item) }}
                                        </div>
                                    </v-card-text>
                                    <v-card-text class="pt-0">
                                        <v-chip :filter="false" :ripple="false" 
                                            :style="`background-color: ${hexToRGBA(getActiveTheme.colors.secondary, 0.2)}`"
                                            class="px-5 font-weight-medium">
                                            {{ item.roles.length > 1 ? `${item.roles[0]} +${item.roles.length - 1}`: item.roles.length == 1 ? item.roles[0] : '' }}
                                        </v-chip>
                                    </v-card-text>
                                    <v-card-actions>

                                        <v-spacer></v-spacer>

                                        <v-btn 
                                            depressed 
                                            small
                                            @click="handleAddDetails(item)"
                                            :color="getCompleted.includes(item.id) ? 'success' : 'secondary'">
                                            {{ getCompleted.includes(item.id) ? 'Completed' : 'Add Details' }}
                                        </v-btn>

                                    </v-card-actions>
                                </v-card>
                            </template>

                        </v-data-table>

                        <v-alert dense outlined type="error" v-if="errors.length > 0">
                            <div v-for="msg in errors" v-html="msg"></div>
                        </v-alert>

                    </v-col>
                </v-row>
            </v-container>
        </v-card-text>
    </v-card>


    <!-- Modal -->
    <v-dialog
        max-width="844"
        persistent
        scrollable
        v-model="requirementsDialog">

        <v-card flat :disabled="requirementsDialogLoading">
            <v-progress-linear color="secondary" indeterminate stream absolute top v-if="requirementsDialogLoading"></v-progress-linear>

                <v-card-title class="justify-end">
                    <v-btn icon
                        max-width="40"
                        max-height="40"
                        :style="`background-color: ${hexToRGBA(getActiveTheme.colors.secondary, 0.1)}`" 
                        @click="requirementsDialog = false" fab>
                        <v-icon color="secondary">mdi-close</v-icon>
                    </v-btn>
                </v-card-title>

                <v-card-text class="px-0 py-0 pb-8">

                    <v-carousel
                        light
                        v-model="carousel"
                        :continuous="false"
                        :show-arrows="false"
                        hide-delimiters
                        touchless
                        height="auto"
                        hide-delimiter-background>

                        <v-carousel-item v-for="(step, key) in steps" :key="key">
                                <v-card flat>

                                    <v-card-title class="d-flex py-0" :class="vb.mdAndUp ? 'px-8' : 'px-4'">
                                        <div>
                                            <h4 :class="vb.mdAndUp ? 'font-size-26' : 'font-size-18'">{{step.title}} | {{ getFriendlyName(selectedEntity) }}</h4>
                                            <p :class="vb.mdAndUp ? 'body-1' : 'caption'">{{step.subtitle}}</p>
                                        </div>
                                        <v-spacer />
                                        <div>
                                            <v-icon @click="showHelperModal">mdi-help-circle-outline</v-icon>
                                        </div>
                                    </v-card-title>

                                    <v-card-text class="px-0">

                                        <template v-if="key == 'person'">
                                            <FormPersonDetails 
                                            :ref="step['form-ref']"
                                            :updater="formUpdater"
                                            :data="resourceData"
                                            @loading="handleLoading"
                                            @submit="handlePersonDetailsSubmit" />
                                        </template>

                                        <template v-if="key == 'income'">
                                            <FormIncomeDetails 
                                            :ref="step['form-ref']"
                                            :data="currentIncome"
                                            :updater="formUpdater"
                                            @loading="handleLoading"
                                            @submit="handleIncomeDetailsSubmit" />
                                        </template>

                                    </v-card-text>

                                </v-card>
                        </v-carousel-item>

                    </v-carousel>

                </v-card-text>


                <v-card-actions class="pa-4 pa-md-6 flex-wrap controls">

                    <v-btn 
                        v-if="!isFirstStep"
                        color="light_gray_2" 
                        class="flex-back-button" 
                        :min-width="140" large outlined 
                        @click="handleBack"
                        elevation="0" nuxt > Back
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn color="secondary" :min-width="140" 
                        large elevation="0" 
                        @click="handleNext"
                        nuxt> {{ isLastStep ? 'Save' : 'Next'}}
                    </v-btn>

                </v-card-actions>


        </v-card>
    </v-dialog>

    <HelperModal v-if="vb.smAndDown" />

</div>

</template>

<script>

    import { mapActions, mapState, mapMutations } from 'vuex'
    import subscriptions from '~/components/shared/subscriptions'
    import shared from '~/components/v1/submission/LenderSocietyOnePeople1/__shared'

    import {store, actions} from '~/components/v1/submission/LenderSocietyOnePeople1/__store'
    import FormPersonDetails from '~/components/v1/submission/LenderSocietyOnePeople1/_FormPersonDetails'
    import FormIncomeDetails from '~/components/v1/submission/LenderSocietyOnePeople1/_FormIncomeDetails'

    import HelperModal from '~/components/base/LenderHelperDialog'

    export default {
        components: { 
            FormPersonDetails,
            FormIncomeDetails,
            HelperModal
        },
        mixins: [subscriptions, shared],

        data() {
            return {

                requirementsDialog: false,
                requirementsDialogLoading: false,

                people: [],
                incomes: [],

                selectedEntity: null,
                currentIncome: null,

                resourceData: null,

                errors: [],

            }
        },
        computed: {
            ...mapState('flows', [
                'lenderSubmissionTo'
            ]),
            vb() {
                return this.$vuetify.breakpoint
            },
            getApplicantKinds(){

                let parts = this.kinds.find( kind => {
                    if(kind.name == 'Part'){
                        return kind
                    }
                })

                return parts.children
            },
            carousel: {
                get(){
                    return store.carousel
                },
                set(value){
                    actions.setCarousel(value)
                }
            },
            steps(){
                return store.steps
            },
            formUpdater(){

                return this.requirementsDialog

            },
            isLastStep(){
                if(this.carousel == (Object.keys(this.steps).length - 1)){
                    return true
                }

                return false
            },
            isFirstStep(){
                if(this.carousel == 0){
                    return true
                }

                return false
            },
            getCompleted(){

                const {people, incomes} = this

                let completed = []

                people.forEach(person => {

                    let relatedPeople = null
                    let relatedIncomes = null

                    if( person.attributes['lender-society-one-visa-status']) {
                        relatedPeople = true
                    }

                    if( person.relationships.incomes.data.length > 0 ) {
                        person.relationships.incomes.data.forEach( data => {

                            incomes.forEach( income => {
                                if(income.id === data.id) {

                                    if(person.roles[0] === 'Non-Borrowing Spouse') {
                                        if(income.attributes['lender-society-one-occupation'] && income.attributes['gross-annual-income']) {
                                            relatedIncomes = true
                                        }
                                    } else {
                                        if(income.attributes['lender-society-one-occupation']) {
                                            relatedIncomes = true
                                        }
                                    }

                                }
                            })

                        })
                    }

                    if(relatedPeople && relatedIncomes) {
                        completed.push(person.id)
                    }

                });

                return completed

            },
            originationFee() {
                let fee = 0

                if(this.blockSettings && this.blockSettings.originationFee) {
                    fee = this.blockSettings.originationFee
                }

                return fee
            },
            getLoanId(){
                return this.appData?.relationships?.loan?.data?.id
            }
        },
        methods: {
            ...mapActions('resource-actions', [
                'getLoan',
                'getPeople',
                'getBusinesses',
                'getIncomes',
                'getExpenses',
                'updateLoan',
                'updatePerson',
                'updateIncome',

            ]),
            ...mapMutations('dialogs', [
                'toggleLenderSubmissionHelperDialog'
            ]),
            async pull() {

                // if (this.getLoanId) {

                //     let payload = {
                //         type: 'loans',
                //         id: this.getLoanId,
                //         attributes: {
                //             "requested-broker-fee": this.originationFee,
                //         }
                //     }

                //     await this.updateLoan(payload)
                // }

                let promise1 = this.getLoan(this.getLoanId, {"include" : 'parent-parts'})
                let promise2 = this.getPeople({"include" : 'parent-parts'})
                let promise3 = this.getIncomes()
                // let promise4 = this.getBusinesses({"include" : 'parent-parts,child-parts'})
                // let promise5 = this.getExpenses()

                const res = await Promise.all([
                        promise1, 
                        promise2, 
                        promise3, 
                        // promise4, 
                        // promise5
                    ]) 

                promise1 = res[0]
                promise2 = res[1]
                promise3 = res[2]
                // promise4 = res[3]
                // promise5 = res[4]

                if(promise1.data?.data?.relationships?.kind?.data?.id){
                    this.loanKindId = promise1.data.data.relationships.kind.data.id
                }

                this.people = this.getPeopleByRoles(promise2.data)
                this.incomes = promise3?.data?.data ? promise3.data.data : []

                // console.log(this.incomes)

                if(promise2.data.included){
                    this.parts = promise2.data.included.filter( part => {
                        return part.type == "parts"
                    })
                }

            },
            async push() {

                this.checkCompleted()
      
                let valid = this.errors.length == 0

                let status = valid ?  'valid' : 'dirty'

                return {valid, status, data:{}}
                
            },

            showHelperModal() {
                this.toggleLenderSubmissionHelperDialog(true)
            },

            checkCompleted(){

                this.errors = []

                if(this.getCompleted.length != this.people.length) {
                    this.errors.push('Please complete all requirements')
                } 

            },

            async handleAddDetails(data){

                const {incomes} = this;

                this.requirementsDialog = true
                this.selectedEntity = data

                // if non borrowing spouse add 1 step
                if(data.roles.length > 0 && data.roles[0] === 'Non-Borrowing Spouse') {
                    actions.setSteps({
                        "person": {
                            "title": "Person Details",
                            "subtitle": "Please use the Nodifi Data to answer the questions in the Society One Requirements",
                            "form-ref": "FormPersonDetailsForm",
                        },
                        "income": {
                            "title": "Income Details",
                            "subtitle": "Please use the Nodifi Data to answer the questions in the Society One Requirements",
                            "form-ref": "FormIncomeDetailsForm",
                        }
                    })
                } else {
                    actions.setSteps({
                        "person": {
                            "title": "Person Details",
                            "subtitle": "Please use the Nodifi Data to answer the questions in the Society One Requirements",
                            "form-ref": "FormPersonDetailsForm",
                        }
                    })
                }

                // Find selected entity's current income

                this.currentIncome = this.getPersonsCurrentIncome(data, incomes)

                this.resourceData = {
                    person: data,
                    income: this.currentIncome
                }

            },

            async handleNext(){


                let stepsArray = Object.keys(this.steps)

                switch (stepsArray[this.carousel]) {

                    case "person":

                        if(this.$refs.FormPersonDetailsForm[0]) {

                            await this.$refs.FormPersonDetailsForm[0].validate()

                        }
                            
                    break;
                    case "income":

                        if(this.$refs.FormIncomeDetailsForm[0]) {

                            await this.$refs.FormIncomeDetailsForm[0].validate()

                        }
                            
                    break;
                    default:
                        
                        this.carousel++

                    break;
                }

            },
            async handleBack(){
                this.carousel--
            },
            async handlePersonDetailsSubmit(payload){

                // console.log(payload)
                this.requirementsDialogLoading = true

                if(payload && payload.person.hasOwnProperty('id') && payload.income.hasOwnProperty('id')) {

                    let promise1 = this.updatePerson(payload.person)
                    let promise2 = this.updateIncome(payload.income)

                    const res = await Promise.all([
                            promise1, 
                            promise2, 
                    ]) 

                    promise1 = res[0]
                    promise2 = res[1]

                    if(promise1.status == 200 && promise2.status == 200) {

                        this.updateLocalResourceAttributes(promise1.data.data)
                        this.updateLocalResourceAttributes(promise2.data.data)

                        if(Object.keys(this.steps).length > 1) {
                            this.requirementsDialogLoading = false
                            this.carousel = 1
                        } else {
                            setTimeout(() => {
                                this.requirementsDialogLoading = false
                                this.requirementsDialog = false
                                actions.resetStore()
                            }, 600);
                        }

                    } else {

                        this.requirementsDialogLoading = false
                        this.requirementsDialog = false

                    }

                }

            },

            async handleIncomeDetailsSubmit(payload){

                // console.log(payload)
                this.requirementsDialogLoading = true

                if(payload && payload.income.hasOwnProperty('id')) {
                    let promise1 = this.updateIncome(payload.income)

                    const res = await Promise.all([
                        promise1
                    ]) 

                    promise1 = res[0]

                    if(promise1.status == 200) {

                        this.updateLocalResourceAttributes(promise1.data.data)

                        setTimeout(() => {
                            this.requirementsDialogLoading = false
                            this.requirementsDialog = false
                            actions.resetStore()
                        }, 600);

                    } else {

                        this.requirementsDialogLoading = false
                        this.requirementsDialog = false
                    }

                }

            },
            handleLoading(payload){
                this.requirementsDialogLoading = payload
            },
            updateLocalResourceAttributes(resource){

                if(!resource) return;

                let getType = resource.type

                this[getType].forEach( (v, i) => {

                    if(v.id == resource.id) {
                        this[getType][i]['attributes'] = resource.attributes
                    }

                })

            },
            getPersonsCurrentIncome(person, incomes){

                let personCurrentIncome = null

                if(incomes.length > 0 && person) {

                    let incomesList = []

                    incomes.forEach(income => {

                        let incomePeopleData = income.relationships.people.data

                        if(incomePeopleData.length > 0) {

                            incomePeopleData.forEach( incomePeopleItem => {
                                
                                if(incomePeopleItem.id == person.id) {
                                    incomesList.push(income)
                                }

                            })

                        }
                    })

                    if(incomesList.length > 0) {

                        incomesList.forEach(income => {
                            if(income.attributes['income-situation'] == 'Current') {
                                personCurrentIncome = income
                            }
                        })

                    }

                }

                return personCurrentIncome
            }

        }
    }
</script>

<style lang="scss" scoped>

</style>