<template>
    <v-alert
        @input="onToggle"
        text
        :value="settings.alert"
        :prominent="settings.prominent"
        :dismissible="settings.dismissible"
        :type="settings.type">
        {{ settings.text }}
        <v-container v-if="settings.isStepValidator" class="pa-0 pt-2" fluid>
            <template 
                v-for="(step_status) in stepStatuses">
                <div
                    v-if="step_status.attributes.included == 1 && step_status.attributes.value !== 'valid'"
                    :key="step_status.id" >

                    <div v-for="(step) in getSteps" :key="step.id">
                        <p v-if="step.id == step_status.relationships.step.data.id" class="font-weight-bold ma-0">
                            <v-icon size="20" color="error" class="mt-n2">mdi-checkbox-marked</v-icon>
                            <span>
                                {{ step.name }}
                            </span>
                        </p>
                    </div>
                    
                </div>
            </template>
        </v-container>
    </v-alert>
</template>

<script>

    import { mapFields } from 'vuex-map-fields' 
    import { mapMutations, mapGetters, mapState } from 'vuex'   

    export default {
        name: 'Alerts',

        computed: {
            ...mapFields('utils', { settings: 'alert_settings' }),
            ...mapGetters('flows', [
                'getSteps'
            ]),
            ...mapState('statuses', [
                'stepStatuses'
            ]),
        },

        methods: {            
            ...mapMutations('utils', [
                'clearAlert'
            ]),
            onToggle(val) {
                if ( !val ) {
                    this.clearAlert()
                }
            }
        },

        watch: {
            'settings.isStepValidator': {
                handler(val) {                    
                    if ( val ) {
                        console.log('Validators to display')
                    }
                    else console.log('No items to display')
                }, 
                deep: true
            }
        },

        data() {
            return {
                
            }
        }

    }
</script>