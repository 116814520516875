<template>
    <div>
        <v-row class="rounded-lg light_gray_1 mx-auto mt-4 pa-2" :style="vb.smAndUp ? {'width' : '60%'} : ''">
            <v-col v-for="(option, index) in options" :key="index" cols="12" class="pa-2">
                <!-- <v-card
                    flat
                    tile
                    outlined
                    :ripple="false"
                    :width="vb.mdAndUp ? 223 : ''"
                    :height="vb.mdAndUp ? 157 : ''"
                    @click="select(option)"
                    :style="option == modelValue ? `background-color: ${hexToRGBA(getActiveTheme.colors.secondary, 0.1)}; border-color: ${hexToRGBA(getActiveTheme.colors.secondary, 1)}` : ''"
                    class="pa-4 rounded-xl" :class="{'pt-16': vb.mdAndUp}">
                        <div class="py-auto my-auto body-1">
                            {{ option }}
                        </div>
                </v-card> -->
                <v-card
                    flat
                    height="100%" 
                    @click="select(option)"
                    rounded="lg"
                    :style="option == modelValue ? `background-color: ${hexToRGBA(getActiveTheme.colors.primary, 0.1)}; border-color: ${hexToRGBA(getActiveTheme.colors.primary, 1)}` : ''"
                    class="d-flex pa-4 align-center justify-center">
                    <div class="flex-options body-2 font-weight-medium">
                        {{ option }}
                    </div>
                    <v-spacer></v-spacer>
                    <v-btn color="secondary" :width="86" small depressed>
                        Select
                    </v-btn>
                </v-card>
            </v-col>
        </v-row>
        <div>
            <div class="v-text-field__details">
                <div class="v-messages theme--light error--text" role="alert">
                    <div class="v-messages__wrapper" v-if="errors.length">
                        <div class="v-messages__message  text-center">{{...errors}}</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import shared from '~/components/v1/AssetEditor1/__shared'

export default {
    mixins: [shared],
    model: {
        prop: 'modelValue',
        event: 'input'
    },
    props: {
        modelValue: {
            type: String,
            default: () => ''
        },
        options: {
            type: Array,
            default: () => ['Yes', 'No']
        },
        errors: {
            type: Array,
            default: () => []
        }
    },
    watch: {
        // modelValue(val) {
        //     console.log(val)
        // }
    },
    data() {
        return {

        }   
    },
    methods: {
        select(item) {
            this.$emit('input', item)
        }
    }
}
</script>
