/**
 * 
 * @param {string} rules Laravel rules syntax
 * 
 * This method will generate new rules which is inline to Veevalidate rules syntax
 * 
 */
const toFlexRules = (rules) => {
  let pipe = rules.split('|')
  let output = '';

  if(pipe.length === 0 ) return '';

  for (let i = 0; i < pipe.length; i++) {

    let rule = pipe[i];
    let colon = rule.split(':');

    let isLast = pipe.length === (i + 1);
    let __ = isLast ? '' : '|';

    /**
     *  If colon is 1, method only exists 
     *  ex: required, string, etc.
     */
    if(colon.length === 1 ) {

      let method = colon[0]

      switch(method){
          case 'string':
          break;
          case 'is_abn':
              output += method + __;
          break;
          case 'valid_vin':
              output += method + __;
          break;
          case 'email':
              output += method + __;
          break;
          case 'integer':
              output += 'numeric' + __;
          break;
          case 'numeric':
              output += method + __;
          break;
          case 'is_bank_account_name':
            output += method + __;
          break;
          case 'exists':
          break;
          case 'required':
            output += method + __;
          break;
      }

    }

    /**
     *  If colon length is 2, need to check both method & value(s)
     */
    if(colon.length === 2 ) {

      let method = colon[0]
      let value = colon[1]
      let options;
      let key;

      switch(method){

          case 'min_value':
              output += `${method}:${value}` + __;
          break;

          case 'max_value':
              output += `${method}:${value}` + __;
          break;

          case 'max':
              output += `${method}:${value}` + __;
          break;

          case 'min':
              output += `${method}:${value}` + __;
          break;

          case 'in':
            // Do nothing if {value} is required 
            if(value !== 'required'){
              output += `oneOf:${value}` + __;
            }
          break;


          case 'required_if':
            options = value.split(',');
            key = options.shift();
            output += `${method}:${key},${options.join(',')}` + __;
          break;

          case 'required_unless':
            options = value.split(',');
            key = options.shift();
            output += `${method}:${key},${options.join(',')}` + __;
          break;



          case 'date_format':
              output += `${method}:${value}` + __; // yyyy-MM-dd
          break;

          case 'after':
              output += `${method}:${value}` + __;
          break;

          case 'before':
              output += `${method}:${value}` + __;
          break;



          case 'between':
              options = value.split(',');

              let currency = ''
              /*
              * NOTE: custom rule for decimal like money
              *       you may want to refactor this.
              */
              if(options[0] == '0' && options[1] == '99999999.99') {
                  currency = '|decimal_place:2'
              }
              
              // TEMP: validation for custom error message profit_before_tax
              if(options[0] == '1' && options[1] == '99999999.99') {
                  currency = '|decimal_place:2'
              }

              output += `${method}:${options.join(',')}` + currency + __;

          break;
          
          // For exact character length digits_between:10,10
          case 'digits_between':
              options = value.split(',');
              
              if(options[0] == options[1]){
                output += `digits:${options[0]}` + __;
              } else {
                output += `min:${options[0]}|`;
                output += `max:${options[1]}` + __;
              }

          break;

          case 'exists':
            // What will happen if method is {exists}
          break;
      }

    }

  }

  /**
   * Remove the trailing pipe if exists.
   */
  return output.replace(/\|\s*$/, "");
}

/**
 * 
 * @param {string} rules Laravel rules syntax
 * 
 * This method will generate options for picklist or button group
 * 
 */
const toFlexOptions = (rule) => {

  let pipe = rule.split('|')
  let output = [];

  if(pipe.length === 0 ) return '';

  for (let i = 0; i < pipe.length; i++) {

    let r = pipe[i];
    let colon = r.split(':');

    /**
     *  If colon length is 2, need to check both method & value(s)
     *  ex: in:foo,baz, exists:goo,bar, etc.
     */
    if(colon.length === 2 ) {

      let method = colon[0]
      let value = colon[1]

      // if(method === 'oneOf') {
      if(method === 'in' && value !== 'required') {

        output = value.split(',')

      }

    } 


  }

  return output
}

export default ({ app }, inject) => {
  inject('toFlexRules', toFlexRules)
  inject('toFlexOptions', toFlexOptions)
}