<template>
    <v-card class="px-4 py-4 flex_component-footer-1" flat tile>
        <SecurityIncidentReportingLink v-if="isApplicationFlow"/>
    </v-card>
</template>

<script>
    import { mapState, mapActions } from 'vuex'
    import SecurityIncidentReportingLink from '~/components/base/SecurityIncidentReportingLink'
    import { isEmbeddedApplicationFlow, isApplicationFlow } from '~/middleware/embed';

    export default {
        components: {
        SecurityIncidentReportingLink
    },
        data(){
            return {
                isVisible: true
            }
        },
        computed: {
            ...mapState('slug', ['tenant']),
            isApplicationFlow() {
              return isApplicationFlow();
            },
        },
        mounted() {
            /**
             * Emit on mount so we can calculate header height
             */ 
            this.$emit('onMount')
        },
        methods: {
            
        }
    }
</script>

<style scoped lang="scss">

</style>
