
export const actions = {

    async getConnectorTypes({}, params = {}) {
        return this.$api.get('/run/connector-types', params)
    },

    getConnectors(_, params = {}) {
      return this.$api.get('/run/connectors', params)
    },
    createConnectors(_, payload) {
      return this.$api.post('/run/connectors', payload)
    },
    updateConnectors(_, payload) {
      return this.$api.patch(`/run/connectors/${payload.id}`, payload.form)
    },
    deleteConnectors(_, payload) {
      return this.$api.delete(`/run/connectors/${payload.id}`)
    },

}

export const mutations = {
}
