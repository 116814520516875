<template>
    <v-layout wrap row>

        <v-flex 
            xs12            
            class="pa-3">

            <BidQuotePlaceholder v-if="!emptySelection" />
            
            <v-card 
                v-else
                flat class="light_gray_1 pa-4">

                <v-card-actions class="ma-0 px-0" v-if="$vuetify.breakpoint.lgAndUp">
                    
                    <BidLenderSelectionButton
                        @validated-data="save"
                        select-type="primary"                        
                        :lender="selectedLender"
                        :loan="loanResource"
                        :has-data="hasPrimary"
                        :disabled="isUserSelected"
                        :outlined="true"
                        label="Set Primary"                        
                        title="Set Primary Lender" />
                    
                    <v-spacer></v-spacer>
                    
                    <BidLenderSelectionButton
                        @validated-data="save"
                        select-type="alternative"
                        :lender="selectedLender"
                        :loan="loanResource"
                        :has-data="hasAlternative"
                        :disabled="isUserSelected"
                        :outlined="true"
                        label="Set Alternative"
                        title="Set Alternative Lender" />

                </v-card-actions>

                <template v-else>
                    <v-card-actions class="ma-0 px-0">
                        <v-spacer></v-spacer>
                        <BidLenderSelectionButton                            
                            @validated-data="save"
                            select-type="primary"
                            :lender="selectedLender"
                            :loan="loanResource"                            
                            :has-data="hasPrimary"
                            :disabled="isUserSelected"
                            :outlined="true"
                            label="Set Primary"
                            title="Set Primary Lender"
                            :block="true" />                        
                        <v-spacer></v-spacer>
                    </v-card-actions>
                    <v-card-actions class="ma-0">
                        <v-spacer></v-spacer>
                        <BidLenderSelectionButton                            
                            @validated-data="save"
                            select-type="alternative"
                            :lender="selectedLender"
                            :loan="loanResource"                            
                            :has-data="hasAlternative"
                            :disabled="isUserSelected"
                            :outlined="true"
                            label="Set Alternative"
                            title="Set Alternative Lender"
                            :block="true" />
                        <v-spacer></v-spacer>
                    </v-card-actions>
                </template>

                <SummaryDetails
                    :selected-lender="selectedLender"
                    :loan-resource="loanResource" />

            </v-card>

        </v-flex>
    </v-layout>
    
</template>

<script>

    import BidLenderSelectionButton from './_BidLenderSelection'
    import BidQuotePlaceholder from './_BidQuotePlaceholder'
    import SummaryDetails from './_BidQuoteSummaryDetails'
    import shared from './__shared'

    export default {
        name: 'QuotableSummary',

        mixins: [ shared ],

        components: { 
            BidLenderSelectionButton,
            SummaryDetails,
            BidQuotePlaceholder
        },

        props: {
            selectedLender: {
                type: Object,
                default: () => {}
            },
            userSelectedLenders: {
                type: Object,
                default: () => {}
            },
            loanResource: {
                type: Object,
                default: () => {}
            },
            bidResource: {
                type: Object,
                default: () => {}
            },
            emptySelection: {
                type: Boolean,
                default: false
            }
        },

        watch: {
            selectedLender(newValue) {                
                this.selected_lender = ''
            }
        },

        data() {
            return {
                selected_lender: '',
                resources: null
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>+