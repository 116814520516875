<template>
    <div>
        <v-footer
            color="white"
            class="pa-2"
            style="z-index: 200 !important;"
            paddless
            fixed >

            <slot name="tools"></slot>

        </v-footer>
    </div>
    

</template>
<script>
    export default {
        name: 'Tools',

        data() {
            return {
                fab: false
            }
        },

        methods: {
        }
    }
</script>
<style lang="scss" scoped>
</style>
