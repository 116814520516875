<template>
    <v-dialog
        v-model="dialog"
        persistent
        width="900">
        <template v-slot:activator="{ on, attrs }">
            <span
                v-if="label"
                dark
                text
                x-small
                depressed
                :class="activatorClass"
                v-bind="attrs"
                v-on="on">
                {{ label }}
            </span>
            <v-btn
                v-else
                fab text
                depressed
                x-small
                dark
                class="mx-0"
                color="light_gray_2"
                v-bind="attrs"
                v-on="on">
                <v-icon size="25" color="light_gray_2">mdi-email</v-icon>
            </v-btn>
        </template>

        <FlexWrap @resize="handleResize">

            <v-card flat>
                <!-- <v-progress-linear color="secondary" indeterminate stream absolute top v-if="loading"></v-progress-linear> -->
                <v-card-text class="pa-12">
                    
                    <div class="display-2 text-left pb-6">
                        Messages
                    </div>

                    <v-card 
                        outlined flat 
                        class="light_gray_1 pa-2">
                        <v-card flat 
                            v-if="hasMessages"
                            class="overflow-y-auto pa-0 mb-20" 
                            max-height="480">
                            <v-card-text
                                v-for="(msg, mkey) in allMessages" 
                                :key="mkey" 
                                flat class="white px-0 py-0">
                                <v-card flat 
                                    @click="messageSummary(msg)" 
                                    class="px-4">
                                    <div class="py-5">
                                        <p class="ma-0" 
                                            v-html="msg.attributes['sf-comment-body']">
                                        </p>
                                        <v-row class="pa-0">
                                            <v-col cols="6" class="py-0">
                                                <p class="ma-0 pt-4 secondary--text font-weight-medium text-left">
                                                    {{ msg.attributes['sf-creator-name'] || 'Anonymous'}}
                                                </p>
                                            </v-col>
                                            <v-col cols="6" class="py-0">
                                                <p class="ma-0 pt-4 secondary--text font-weight-medium text-right">
                                                    {{ footnote(msg.attributes['created-at']) }}
                                                </p>
                                            </v-col>
                                        </v-row>
                                    </div>
                                </v-card>
                                <v-divider v-if="mkey < messages.length - 1"></v-divider>
                            </v-card-text>
                        </v-card>
                        <v-card flat 
                            v-else
                            class="overflow-y-auto pa-0 mb-20" 
                            max-height="480">
                            <v-card-text
                                flat class="light_gray_1 px-0 py-0">
                                <div class="body-1 text-center py-12">No New Messages</div>                                
                            </v-card-text>
                        </v-card>
                    </v-card>

                    <!-- <v-data-table
                        @click:row="messageSummary"
                        :headers="tableHeaders"
                        :items="messages"
                        class="v-data-table-3 elevation-0 pt-10"
                        hide-default-footer
                        :mobile-breakpoint="0">

                        <template #[`item.icon`]="{ item }">
                            <v-icon>mdi-email</v-icon>
                        </template>

                        <template #[`item.content`]="{ item }">
                            <div class="cell pb-0">
                                <p class="ma-0 font-weight-bold text-truncate">{{ item.attributes['content'] }}</p>
                            </div>
                        </template>

                        <template #[`item.created-at`]="{ item }">
                            <div class="cell pb-0">
                                <p class="ma-0 font-weight-bold text-truncate">{{ item.attributes['created-at'] | dateFormatFE  }}</p>
                            </div>                            
                        </template>

                        <template #[`item.data-table-expand`]="{ item }">
                            <div class="float-right">
                                <DeleteMessage
                                    button-type="icon"
                                    message="Are you sure you want to delete this message?"
                                    title="Delete Message"
                                    x-small />                                    
                            </div>                            
                        </template>
                        
                    </v-data-table> -->
                    

                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions
                    class="pa-6 pa-md-8 flex-wrap controls">                
                    <v-btn
                        outlined
                        class="flex-back-button"
                        color="light_gray_2"
                        large
                        style="min-width:140px;"
                        @click="dialog = false">
                        Close
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="secondary"
                        depressed
                        large
                        style="min-width:140px;"
                        @click="openNewMessage">
                        New Message
                    </v-btn>
                </v-card-actions>
            </v-card>

            <v-dialog
                v-model="dialog2"
                width="600"
                persistent>
                <v-card>
                    <v-progress-linear color="secondary" indeterminate stream absolute top v-if="loading"></v-progress-linear>
                    <v-card-title class="display-2 font-weight-bold pa-12">
                        {{ isNewMessage ? 'New Message' : 'Message' }} 
                    </v-card-title>

                    <v-card-text class="px-12">
                        <v-textarea
                            v-if="isNewMessage"
                            v-model="new_message"
                            :hint="new_message_hint"
                            persistent-hint
                            rows="10"
                            auto-grow
                            label="Write your message here"
                            placeholder="Write your message here."></v-textarea>
                        <v-textarea
                            v-else
                            v-model="new_message"
                            :hint="new_message_hint"
                            readonly
                            persistent-hint
                            rows="10"
                            auto-grow
                            label="Message"
                            placeholder="Write your message here."></v-textarea>
                    </v-card-text>
                    <v-divider></v-divider>
                    <v-card-actions class="pa-6 pa-md-8 flex-wrap controls">
                        <v-btn
                            color="light_gray_2"
                            min-width="140"
                            large depressed outlined
                            :disabled="loading"
                            @click="closeDialog2">
                            Close
                        </v-btn>
                        <v-spacer></v-spacer>
                        <v-btn
                            v-if="isNewMessage"
                            color="secondary darken-1"
                            :disabled="loading"
                            min-width="140"
                            large depressed
                            @click="newMessage">
                            Send
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
            
        </FlexWrap>

    </v-dialog>
</template>

<script>

    import { mapActions, mapState } from 'vuex'
    import helpers from '~/plugins/helpers'
    import FlexWrap from '~/components/base/FlexWrap'
    import DeleteMessage from '~/components/base/ConfirmModal'
    import moment from 'moment'

    export default {
        name: 'AppMessages',

        mixins: [ helpers ],

        props: {
            activatorClass: {
                type: String,
                default: ''
            },
            label: {
                type: String,
                default: ''
            },
            application: {
                type: Object,
                default: () => {}
            }
        },

        components: { FlexWrap, DeleteMessage },

        computed: {
            ...mapState('auth', ['user']),
            tableHeaders() {
                if ( !this.sizes ) return

                const { xs } = this.sizes

                let newHeaders = [
                    ...this.headers
                ]

                let heads = []

                heads = [
                    { value: 'icon', width: '5%' },
                    { value: 'content', width: '60%' },
                    { value: 'created-at', width: '15%' },
                    { value: 'data-table-expand', width: '20%' }
                ]

                if ( xs ) {
                     heads = [
                        { value: 'content', width: '60%' },
                        { value: 'data-table-expand', width: '25%' }
                    ]
                }

                return newHeaders.reduce( (accumulator, current) => {
                    heads.forEach( head => {
                        if ( head.value == current.value ) {
                            let newCurrent = { ...current }
                            newCurrent.width = head.width
                            accumulator.push(newCurrent)
                        }
                    })

                    return accumulator

                }, [])

            },
            allMessages() {
                if (this.messageFilter === 1 || this.messageFilter === '1') 
                    return this.messages?.filter( msg => {
                        return msg.attributes['sf-is-published'] === 1
                    })
                else if (this.messageFilter === 0 || this.messageFilter === '0')
                    return this.messages?.filter( msg => {
                        return msg.attributes['sf-is-published'] === 0
                    })
                return this.messages
            },
            hasMessages() {
                return this.allMessages?.length > 0 ? true : false
            }
        },

        methods: {
            ...mapActions('resource-actions', [
                'createFileNote',
                'createFileNoteInService',
                'getFileNotes'
            ]),
            async fetchMessages() {
                // this.disabled = true
                let res = await this.getFileNotes({
                    'id': this.application.id,
                    params: {
                        // 'filter[sf-is-published]': 1,
                        'sort': '-created-at'
                    }                    
                })
                
                this.messages = res.data.data
                this.disabled = false
                this.disabled = false
            },
            async remove(selectedItem) {
                console.log(`Item 777 : `, selectedItem)
            },
            save() {

            },
            openNewMessage() {
                this.isNewMessage = true
                this.dialog2 = true
            },
            async newMessage() {
                this.loading = true
                let res = await this.createFileNote({
                    data: {
                        type: 'file-notes',
                        attributes: {
                            'sf-comment-body': this.new_message,
                            'sf-creator-name': this.user?.username

                        },
                        relationships: {
                            application: {
                                data: {
                                    id: this.application.id,
                                    type: 'applications'
                                }
                            }
                        }
                    }
                })
                
                if ( res.status === 200 || res.status === 201 ) {
                    this.messages.unshift(res.data.data)
                }
                else {
                    this.toggleQuickMessage({text: res?.data?.message, color: 'error'})
                }
                
                this.new_message = ''
                this.isNewMessage = false
                this.dialog2 = false
                this.loading = false
            },            
            messageSummary(selectedItem = null) {
                this.isNewMessage = false
                this.selectedMessage = selectedItem.attributes
                this.new_message = selectedItem.attributes['sf-comment-body']
                this.new_message_hint = 'Created: ' + moment(selectedItem.attributes['created-at']).format('MMM DD, YYYY - hh:mm:ss a')
                this.dialog2 = true
            },
            footnote(date = new Date()) {
                if ( date) {
                    let temp = moment(date).format('MMM DD, YYYY - hh:mm:ss a')
                    return temp //this.$root.$options.filters.dateFormatFE(date)
                }
                return '--'
            },
            closeDialog2() {
                this.dialog2 = false
                this.isNewMessage = true
                this.selectedMessage = null
                this.new_message = ''
                this.new_message_hint = ''
            }
        },

        watch: {
            'dialog': {
                async handler(val) {
                    if ( val ) {
                        await this.fetchMessages()  
                    }
                },
                deep: true
            }
        },

        data() {
            return {
                dialog: false,
                dialog2: false,
                disabled: false,
                headers: [
                    { text: '', value: 'icon' },
                    { text: 'Body', value: 'content' },
                    { text: 'Date', value: 'created-at' },
                    { text: '', value: 'data-table-expand' }
                ],
                selectedMessage: null,
                new_message: '',
                new_message_hint: '',
                isNewMessage: true,
                messages: [],
                messageFilter: 1,
                loading: false
            }
        }
    }
</script>

<style lang="sass" scoped>

</style>