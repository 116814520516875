import { mapMutations } from 'vuex'

export default {  
    data(){
        return {
        }
    },
    methods: {
        ...mapMutations('requirements-checklist', [
            'setRequirementChecklist',
        ]),
        updateRequirementChecklistSidebar({name, component, entity}){
            this.$nextTick( async () => {

               if(this.$refs.hasOwnProperty(name)) {

                   let observer = this.$refs?.[name]
                   let entityObj = null

                    // console.log(observer)

                    await observer.validate({ silent: true });

                    if(entity){
                        entityObj = {
                            id: entity.id,
                            type: entity.type,
                            attributes: {...entity.attributes}
                        }
                    }

                    let obj = {
                        "id": null,
                        component,
                        "entity": entityObj,
                        "fields": {}
                    }

                    // console.log(observer)

                    obj["id"] = observer.$attrs["data-id"]
                    
                    Object.keys(observer.fields).forEach((field, index) => {

                        let observerField =  observer.fields[field]
                        let child =  observer?.$children[index]

                        // console.log(child ? child.value : null)

                        obj.fields[field] = {
                                value: child ? child.value : null,
                                id: observerField["id"],
                                name: observerField["name"],
                                untouched: observerField["untouched"],
                                touched: observerField["touched"],
                                dirty: observerField["dirty"],
                                pristine: observerField["pristine"],
                                valid: observerField["valid"],
                                invalid: observerField["invalid"],
                                pending: observerField["pending"],
                                required: observerField["required"],
                                changed: observerField["changed"],
                                passed: observerField["passed"],
                                failed: observerField["failed"]
                        }

                    })

                   this.setRequirementChecklist(obj)

               } else {

                   this.setRequirementChecklist(null)
               }

           })
       }, 

    }
}