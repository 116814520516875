var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{attrs:{"items":_vm.collection,"headers":_vm.headers[_vm.recordType],"disable-pagination":"","hide-default-footer":""},scopedSlots:_vm._u([{key:"item.roles",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"inner",domProps:{"innerHTML":_vm._s(_vm.getEntityName(item))}})]}},{key:"item.income-type",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"inner"},[_vm._v("\n            "+_vm._s(_vm.getKindName(item.relationships.kind.data.id))+"\n        ")])]}},{key:"item.income-situation",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"inner"},[_vm._v("\n            "+_vm._s(item.attributes['income-situation'])+"\n        ")])]}},{key:"item.employer-name",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"inner"},[_vm._v("\n            "+_vm._s(item.attributes['employer-name'] || '--')+"\n        ")])]}},{key:"item.start-date",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"inner"},[_vm._v("\n            "+_vm._s(item.attributes['start-date'] || '--')+"\n        ")])]}},{key:"item.end-date",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"inner"},[_vm._v("\n            "+_vm._s(item.attributes['end-date'] || '--')+"\n        ")])]}},{key:"item.net-monthly-income",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"inner"},[_vm._v("\n            "+_vm._s(_vm._f("currency")(item.attributes['net-monthly-amount']))+"\n        ")])]}},{key:"item.share-value",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"inner"},[_vm._v("\n            "+_vm._s(_vm._f("currency")(_vm.shareValue(item)))+"\n        ")])]}},{key:"item.linked-to-asset",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"inner"},[_vm._v("\n            "+_vm._s(item.relationships.assets.data.length ? 'Yes' : 'No')+"\n        ")])]}},{key:"item.asset-type",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"inner"},[_vm._v("\n            "+_vm._s(_vm.getParentKindName(item.relationships.kind.data.id))+"\n        ")])]}},{key:"item.asset-sub-type",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"inner"},[_vm._v("\n            "+_vm._s(_vm.getKindName(item.relationships.kind.data.id))+"\n        ")])]}},{key:"item.asset-description",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"inner"},[_vm._v("\n            "+_vm._s(item.attributes['asset-description'] || '--')+"\n        ")])]}},{key:"item.asset-value",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"inner"},[_vm._v("\n            "+_vm._s(_vm._f("currency")(item.attributes['asset-value']))+"\n        ")])]}},{key:"item.linked-to-income",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"inner"},[_vm._v("\n            "+_vm._s(item.relationships.incomes.data.length ? 'Yes' : 'No')+"\n        ")])]}},{key:"item.linked-to-liability",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"inner"},[_vm._v("\n            "+_vm._s(item.relationships.liabilities.data.length ? 'Yes' : 'No')+"\n        ")])]}},{key:"item.linked-to-expense",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"inner"},[_vm._v("\n            "+_vm._s(item.relationships.expenses.data.length ? 'Yes' : 'No')+"\n        ")])]}},{key:"item.liability-type",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"inner"},[_vm._v("\n            "+_vm._s(_vm.getParentKindName(item.relationships.kind.data.id))+"\n        ")])]}},{key:"item.liability-sub-type",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"inner"},[_vm._v("\n            "+_vm._s(_vm.getKindName(item.relationships.kind.data.id))+"\n        ")])]}},{key:"item.financial-institution",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"inner"},[_vm._v("\n            "+_vm._s(item.attributes['financial-institution'] || '--')+"\n        ")])]}},{key:"item.credit-limit",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"inner"},[_vm._v("\n            "+_vm._s(_vm._f("currency")(item.attributes['credit-limit']))+"\n        ")])]}},{key:"item.amount-financed",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"inner"},[_vm._v("\n            "+_vm._s(_vm._f("currency")(item.attributes['amount-financed']))+"\n        ")])]}},{key:"item.amount-owing",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"inner"},[_vm._v("\n            "+_vm._s(_vm._f("currency")(item.attributes['amount-owing']))+"\n        ")])]}},{key:"item.expense-type",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"inner"},[_vm._v("\n            "+_vm._s(_vm.getParentKindName(item.relationships.kind.data.id))+"\n        ")])]}},{key:"item.expense-sub-type",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"inner"},[_vm._v("\n            "+_vm._s(_vm.getKindName(item.relationships.kind.data.id))+"\n        ")])]}},{key:"item.monthly-payment-amount",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"inner"},[_vm._v("\n            "+_vm._s(_vm._f("currency")(item.attributes['payment-amount']))+"\n        ")])]}},{key:"item.to-be-paid-out",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"inner"},[_vm._v("\n            "+_vm._s(item.attributes['to-be-paid-out'] || '--')+"\n        ")])]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }