<template>
    <v-snackbar 
        v-model="settings.snackbar"
        :color="settings.color"
        :top="settings.y === 'top'"
        :bottom="settings.y === 'bottom'"
        :left="settings.x === 'left'"
        :right="settings.x === 'right'"
        :timeout="settings.timeout"
        :vertical="settings.mode === 'vertical'"
        :multi-line="settings.mode === 'multi-line'"
        content-class="pr-0">

        <div class="py-4">
            <div class="d-flex align-center justify-center">
                <div class="white--text body-2 font-weight-medium" style="word-break: break-all">
                    {{ message }}
                </div>

                <v-spacer></v-spacer>

                <div class="d-flex align-self-start">
                    <v-btn fab depressed :color="settings.color" small @click="close">
                        <v-icon >mdi-close</v-icon>
                    </v-btn>
                </div>
            </div>
        </div>

    </v-snackbar>
</template>

<script>

    import { mapMutations } from 'vuex'
    import { mapFields } from 'vuex-map-fields'

    export default {
        name: 'Snackbar',

        computed: {
            ...mapFields('utils', { settings: 'notification_settings' }),
            message(){

                let text = this.settings.text
                let max = 600

                if(text.length > max){
                    return text.slice(0, max) + '...'
                }

                return text

            }
        },

        methods: {
            ...mapMutations('utils', ['forceCloseQuickMessage']),
            close() {
                this.forceCloseQuickMessage()
            }
        },

        watch: {
            'settings.snackbar': {
                handler(val) {

                    let _s = this
                    
                    if (val == true) {
                        setTimeout( () => {
                            this.forceCloseQuickMessage()
                        }, this.settings.timer)
                    }
                },
                deep: true
            }
        }
    }
    
</script>

<style lang="scss" scoped>

</style>