<template>
    <v-sheet max-width="854" class="mx-auto">
        <p class="caption text-center gray font-size-10 line-height-14" v-text="getDisclaimer"></p>
    </v-sheet>
</template>

<script>

    export default {
        props: {
            settings: {
                type: Object,
                default: null
            }
        },
        data() {
            return {
              
            }
        },
        mounted(){

        },
        computed: {
            getDisclaimer() {

                let defaultText = "*Terms and conditions apply. Finance for credit approved purchaser's only. The finance offer is for amounts and terms shown above. The comparison rate is based on the amounts and terms above at the annual percentage rate shown above. The amount of credit provided to you and the term of the loan may be different to this WARNING. The comparison rate only applies to the example given and may not include all fees and charges. Different terms, fees or other loan amounts might result in a different comparison rate. Credit Asssistance is provided through Online Loans ABN 58 610 067 715 and/or its panel of lenders. Australian Credit Licence Number 484982."

                if (this.settings && this.settings.disclaimer) {

                    switch (this.settings.disclaimer) {
                        case "assumed-parameters":
                            return "*Terms and conditions apply. Some parameters for the quote have been assumed, therefore the quote may change once accurate information has been received about these assumptions. Finance for credit approved purchaser's only. The finance offer is for amounts and terms shown above. The comparison rate is based on the amounts and terms above at the annual percentage rate shown above. The amount of credit provided to you and the term of the loan may be different to this WARNING. The comparison rate only applies to the example given and may not include all fees and charges. Different terms, fees or other loan amounts might result in a different comparison rate. Credit Asssistance is provided through Online Loans ABN 58 610 067 715 and/or its panel of lenders. Australian Credit Licence Number 484982."
                    
                        default:
                            return defaultText;
                    }

                }

                return defaultText
            },
        },

    }
</script>

<style lang="scss">

  
</style> 