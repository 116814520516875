<template>
    <div>
        <v-row>
            <v-col cols="12" lg="6" xl="6">
                <v-list nav dense class="draggable-list pa-2 pt-2">
            
                    <span class="draggable-list-item-label font-weight-bold pb-2 mb-2">
                        {{ label }}
                    </span>
                    <slot name="tooltip"></slot>
                    
                    <v-card outlined flat elevation="0" class="drop-list mt-2" >
                        <drop-list :items="defaultItem" @reorder="$event.apply(defaultItem)" @insert="insert" mode="cut">
                            <template v-slot:item="{item}">
                                <drag :key="item" :data="item" @cut="remove(defaultItem, item)">
                                    <v-list-item class="py-0 my-0">
                                        <v-list-item-content>
                                            <v-list-item-title class="draggable-list-item">
                                                <v-avatar class="mr-3" color="grey lighten-1" size="30" dark>
                                                    <v-icon size="20" dark>{{ getIcon(item) }}</v-icon>
                                                </v-avatar>
                                                {{ item }}
                                            </v-list-item-title>                                
                                        </v-list-item-content>
                                    </v-list-item>
                                    <v-divider/>
                                </drag>
                            </template>
                            <template v-slot:feedback="{item}">
                                <v-skeleton-loader
                                        type="list-item-avatar"
                                        :key="defaultItem.indexOf(item)"
                                        class="skeleton-avatar"
                                />
                            </template>
                        </drop-list>
                    </v-card>
                </v-list>
            </v-col>
            <v-col cols="12" lg="6" xl="6">
                <v-list nav dense class="draggable-list pa-0 pt-2">
            
                    <span class="draggable-list-item-label font-weight-bold pb-2 mb-4">
                        Please Drag and Drop it Here...
                    </span>
                    
                    <v-card outlined flat elevation="0" class="drop-list mt-2">
                        <drop-list :items="modelValue" @reorder="$event.apply(modelValue)" @insert="insert2" mode="cut">
                            <template v-slot:item="{item}">
                                <drag :key="item" :data="item" @cut="remove(modelValue, item)">
                                    <v-list-item class="py-0 my-0">
                                        <v-list-item-content>
                                            <v-list-item-title class="draggable-list-item">
                                                <v-avatar class="mr-3" color="#4e69d9" size="30" dark>
                                                    <v-icon size="20" dark>{{ getIcon(item) }}</v-icon>
                                                </v-avatar>
                                                {{ item }}
                                            </v-list-item-title>                                
                                        </v-list-item-content>
                                        <v-list-item-action class="pa-0 ma-0">                                
                                            <v-avatar class="ma-0" color="grey lighten-2" size="20" dark>
                                                <span class="primary--text font-weight-bold">
                                                    {{ modelValue.map(function(x) {return x }).indexOf(item) + 1 }}
                                                </span>
                                            </v-avatar>
                                        </v-list-item-action>
                                    </v-list-item>
                                    <v-divider/>
                                </drag>
                            </template>
                           <template v-slot:feedback="{item}">
                                <v-skeleton-loader
                                        type="list-item-avatar"
                                        :key="modelValue.indexOf(item)"
                                        class="skeleton-avatar"
                                />
                            </template>
                        </drop-list>
                    </v-card>
                </v-list>
                <div
                    v-if="hasErrors" 
                    class="error-messages">
                    <span 
                        v-for="(err, ekey) in errorMessages"
                        :key="ekey">
                        {{ err }}
                    </span>
                </div>
            </v-col>
        </v-row>
    </div>
</template>

<script>

    import { Drag, Drop, DropList } from "vue-easy-dnd"

    export default {
        name: 'CustomerPriorityRatings',

        model: {
            prop: 'modelValue',
            event: 'input'
        },

        props: {
            modelValue: {
                type: Array,
                default: () => []
            },
            label: {
                type: String,
                default: 'Label'
            },
            syncReset: {
                type: Boolean,
                default: false
            },
            errorMessages: {
                type: Array,
                default: () => [],
            },
            defaultItem: {
                type: Array,
                default: () => ['Low Early Payout Fees', 'Fast Settlement', 'Lowest Repayments', 'Loan Flexibility', 'Maximum Pre-Approval Limit']
            }
        },

        components: {
            Drag,
            Drop,
            DropList
        },

        computed: {
            hasErrors() {
                return this.errorMessages.length ? true : false
            }
        },  

        methods: {
            insert(event) {
                this.defaultItem.splice(event.index, 0, event.data);
            },
            insert2(event) {
                this.modelValue.splice(event.index, 0, event.data);
            },
            remove(array, value) {
                let index = array.indexOf(value);
                array.splice(index, 1);
            },
            getIcon(key = '') {
                
                let x = this.itemFields.find( item => {
                    return item.title === key
                })
                
                return x.icon
            }
        },

        watch: {
            'syncReset': {
                handler(val) {
                    if (val && this.modelValue.length < 1)
                        this.selectedItem = ''
                },
                deep: true
            },
            'modelValue': {
                handler(val) {
                    if (val && this.modelValue.length < 1)
                        this.selectedItem = val
                },
                deep: true
            }
        },

        data() {
            return {
                selectedItem: 0,
                itemFields: [
                    { title: "Low Early Payout Fees", icon: 'mdi-sort-clock-descending' },
                    { title: "Fast Settlement", icon: 'mdi-clock-fast' },
                    { title: "Lowest Repayments", icon: 'mdi-hand-okay' },
                    { title: "Loan Flexibility", icon: 'mdi-arrow-decision' },
                    { title: "Maximum Pre-Approval Limit", icon: 'mdi-briefcase-check' }
                ]
            }
        }
    }
</script>

<style lang="scss" scoped>
    .draggable-list {

        .draggable-list-item-label {
            font-weight: 700 !important;
            font-size: 13px;            
        }

        .drop-list {
            height: 238px;

            .draggable-list-item {
                font-size: 10px;
                font-weight: bold;
            }
        }

        .skeleton-avatar {
            border-left: 2px solid #1976D2;
            margin-left: -2px;
        }

    }

    .error-messages {
        border-top: 2px red solid;
        color: var(--v-error-base) !important;
        caret-color: var(--v-error-base) !important;
        font-size: 12px;
    }

    .dnd-drag.drag-out.drag-no-handle {        
        &:hover {
            border-left: 2px solid #1976D2;
            background-color: var(--v-light_gray_1-base);
        }
    }
</style>