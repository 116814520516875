<template>
    <v-card class="mx-auto" tile flat>
        <v-card-text class="pa-4 pt-0 pa-md-10 pt-md-0 mx-2">

            <v-container v-if="showTYMessage">

                    <v-card class="mx-auto rounded-lg" flat>

                    <v-card-title class="text-center">
                            <v-row>
                                <v-col cols="12">
                                    <v-icon size="100" color="success">mdi-text-box-check-outline</v-icon>
                                </v-col>
                                <v-col cols="12">
                                    <p class="text-center">
                                        Success!
                                    </p>
                                </v-col>
                            </v-row>            
                            
                        </v-card-title>

                        <v-card-text class="mb-10">

                            <v-alert
                                prominent
                                outlined
                                color="success"
                                max-width="900"
                                class="mx-auto"
                                type="success">
                                <div class="success-darken-1--text font-weight-medium">
                                    Thanks, your file note has been submitted.
                                </div>
                            </v-alert>

                        </v-card-text>

                    </v-card>

            </v-container>
            <v-container v-else pa-0 fluid>
                <ValidationObserver ref="observer" tag="form">
                    <v-row>                        
                        <v-col cols="12">

                            <v-row>
                                <v-col
                                    cols="12"
                                    :class="$vuetify.breakpoint.smAndDown ? 'px-1 pr-3 mt-n6' : 'mt-n6'"
                                    class="py-0"
                                >
                                    <ValidationProvider
                                        v-slot="{ errors }"
                                        name="File Note"
                                        rules="required|max:500|min:250"
                                        vid="file-note"
                                    >
                                        <v-textarea
                                            v-model="resources['file-note']"
                                            hint="Please enter notes regarding resubmission"
                                            auto-grow
                                            label="File Note"
                                            rows="10"
                                            :counter="wordCount(resources['file-note'])"
                                            :error-messages="errors"
                                        >
                                        </v-textarea>
                                    </ValidationProvider>
                                </v-col>
                            </v-row>

                        </v-col>
                    </v-row>

                </ValidationObserver>
            </v-container>
        </v-card-text>

        <!-- This will replace Next button in StepIncrementer1  -->
        <Portal to="step-incrementer-next">

            <v-btn 
                v-if="!showTYMessage"
                color="secondary" 
                large depressed nuxt 
                :min-width="btnWidth" 
                @click="push" 
                class="ml-3" 
                :disabled="processing">
                Submit
            </v-btn>

            <v-btn 
                v-if="showTYMessage"
                color="secondary" 
                large depressed nuxt 
                :min-width="btnWidth" 
                @click="handleExit" 
                class="ml-3" >
                Exit
            </v-btn>

        </Portal>

        <v-overlay class="ma-0 pa-0" :value="overlay" opacity=".2">
            <div class="spinner">      
                <div class="flex-brand-loader">
                    <div class="flex-brand d-block">
                        <loaderImage />
                    </div>
                </div>
            </div>
        </v-overlay>

    </v-card>
</template>

<script>

    import BoxApi from '~/components/base/BoxApi'
    import loaderImage from '~/components/base/loaderImage'

    import subscriptions from '~/components/shared/subscriptions'
    import { mapState, mapGetters, mapActions, mapMutations } from 'vuex'
    import { cloneDeep } from 'lodash'

    export default {
        name: 'AppSubmitter2',
        components: {
            BoxApi,
            loaderImage
        },
        mixins: [subscriptions],

        data(){
            return {
                showTYMessage: false,
                overlay: false,
                rules: [v => (v && v.length >= 250) || 'Minimum limit of 250 characters'],
                resources:{
                    "file-note": ''
                }
            }
        },

        computed: {
            ...mapGetters({
                getFlowSlug: 'slug/getFlowSlug',
                getGroupSlug: 'slug/getGroupSlug',
                getConfig: 'flows/getConfig'
            }),
            ...mapState({
                user: state => state.auth.user,
                appId: state => state.flows.appId,
                appGuid: state => state.flows.appGuid,
                flowData: state => state.flows.flowData,
            }),
            ...mapState('statuses', [
                'stepStatuses'
            ]),
            ...mapGetters('statuses', [
                'getCurrentFlowStatus'
            ]),
            ...mapGetters('flows', [
                'redirectPaths'
            ]),
            processing(){
                return  this.isDisabled || this.showTYMessage
            },
            btnWidth () {
                switch (this.$vuetify.breakpoint.name) {
                    case 'xs': return 0
                    case 'lg': 
                    case 'xl':
                    case 'md': 
                    case 'sm': return 140
                }
            },
            getLoanId(){
                return this.appData?.relationships?.loan?.data?.id
            }            
        },

        created() {

        },

        mounted(){
            if(!this.getLoanId){
                // Error...
            }
        },

        methods: {
            ...mapActions('resource-actions', [
                'getLoan',
                'createBox',
                'updateLoan',
                'createFileNote',
            ]),
            ...mapActions('flows', [
                'resetFlowData'
            ]),
            ...mapActions('flows', [
                'selectFinalStep',
                'selectNextStep'
            ]),
            ...mapMutations('utils', [
                'toggleQuickMessage'
            ]),

            async pull() {

                


            },
            async push() {
                                
                try {

                    const {valid} = await this.validateFormData()
                        
                    if(valid){

                        this.overlay = true

                        let res = await this.createFileNote({
                            data: {
                                type: 'file-notes',
                                attributes: {
                                    'sf-comment-body': this.resources['file-note'],
                                    'sf-creator-name': this.user?.username

                                },
                                relationships: {
                                    application: {
                                        data: {
                                            id: this.appData.id,
                                            type: 'applications'
                                        }
                                    }
                                }
                            }
                        })


                        if ( res.status === 200 || res.status === 201 ) {

                            this.showTYMessage = true
                            this.overlay = false
              
                        } else {

                            let errorMsg = res?.data?.message ? res?.data?.message : 'Uh oh!, something went wrong.'

                            this.toggleQuickMessage({text: errorMsg, color: 'error'})

                            this.overlay = false

                        }

                    }

                } catch (error) {

                    console.log(error)
                    this.overlay = false
                }

                
            },
            handleExit(){

                // Redirect to flow.redirect.on-complete-flow

                const url = this.$flexConfigStringParser(this.redirectPaths['on-complete-flow'], {
                    config: this.getConfig,
                    route: {
                    groupSlug: this.getGroupSlug,
                    flowSlug: this.getFlowSlug,
                    applicationGuid: this.appGuid,
                },
                    data: null
                })
                
                //  this.resetFlowData()
                
                window.location.href = `${url}`
                // this.$router.push({ path: `/${url}` })
            },
            wordCount(field = '') {
                return this.resources?.["file-note"]?.length <= 250 ? 250 : false
            }
        },
        watch: {

        }
    
    }
</script>

<style lang="scss">
    .v-input__slot.transparent.acknowledgment {
        height: 20px !important;
    }
</style>

