<template>
  <div>
    <div class="d-flex" style="width: 100%">
      <v-sheet
        color="light_gray_1"
        class="py-8 px-12"
        width="50%"
        outlined
        elevation="0"
      >
        <v-img
          max-width="120"
          class="flex-brand"
          :src="require(`~/assets/images/nodifi.svg`)"
          position="center left"
          contain
        >
        </v-img>
        <div class="mt-2 mb-6 font-size-20">Data</div>

        <v-list color="transparent">
          <v-list-item two-line class="px-0">
            <v-list-item-content>
              <v-list-item-title class="font-weight-medium"
                >Driver Licence Number</v-list-item-title
              >
              <v-list-item-subtitle>
                {{ data.attributes['drivers-licence-number'] ? data.attributes['drivers-licence-number'] || "&mdash;" : "XXX" }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <v-list-item two-line class="px-0">
            <v-list-item-content>
              <v-list-item-title class="font-weight-medium"
                >Driver Licence Card Number</v-list-item-title
              >
              <v-list-item-subtitle>
                {{ data.attributes['drivers-licence-card-number'] ? data.attributes['drivers-licence-card-number'] || "&mdash;" : "XXX" }}
             </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <v-list-item two-line class="px-0">
            <v-list-item-content>
              <v-list-item-title class="font-weight-medium"
                >Driver Licence State</v-list-item-title
              >
              <v-list-item-subtitle>
                {{ data.attributes['drivers-licence-state'] || "XXX" }}
             </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-sheet>
      <v-sheet class="py-8 px-12" width="50%">
        <v-img
          max-width="120"
          class="flex-brand"
          :src="require(`~/assets/images/logo/cropped/lender-logo-macquarie.png`)"
          position="center left"
          contain
        >
        </v-img>
        <div class="mt-2 mb-8 font-size-20">Requirements</div>
        <ValidationObserver ref="observer" tag="form">
          <ValidationProvider
            key="repayment_affordability"
            v-slot="{ errors }"
            name="Field"
            :rules="getRules('repayment_affordability')"
            vid="repayment_affordability"
          >
            <ButtonGroup3
                v-model="resources.loans.attributes.lender.macquarie.repayment_affordability"
                :error-messages="errors"
                :options="[
                  { value: '1', label: 'Yes' },
                  { value: '0', label: 'No' },
                ]"
                label="Repayment Affordability"
              />
          </ValidationProvider>
          <ValidationProvider
            key="credit_option"
            v-slot="{ errors }"
            name="Field"
            :rules="getRules('credit_option')"
            vid="credit_option"
          >
            <v-select
                v-model="resources.loans.attributes.lender.macquarie.credit_option"
                :items="options.credit_option"
                :error-messages="errors"
                color="secondary"
                label="Credit Option"
              />
          </ValidationProvider>
          <ValidationProvider
            key="loan_statement"
            v-slot="{ errors }"
            name="Field"
            :rules="getRules('loan_statement')"
            vid="loan_statement"
            v-if="['COMM_EXPRESS', 'REPLACEMENT', 'BALLOON_RV_REFINANCE'].includes(resources.loans.attributes.lender.macquarie.credit_option)"
          >
            <ButtonGroup3
                v-model="resources.loans.attributes.lender.macquarie.loan_statement"
                :error-messages="errors"
                :options="[
                  { value: '1', label: 'Yes' },
                  { value: '0', label: 'No' },
                ]"
                label="Loan Statement"
              />
          </ValidationProvider>
          <ValidationProvider
            v-if="resources.loans.attributes.lender.macquarie.loan_statement === '0'"
            key="financial_reference"
            v-slot="{ errors }"
            name="Field"
            :rules="getRules('financial_reference')"
            vid="financial_reference"
            >
            <v-select
                v-model="resources.loans.attributes.lender.macquarie.financial_reference"
                :items="options.financial_reference"
                :error-messages="errors"
                color="secondary"
                label="Financial Reference"
              />
          </ValidationProvider>
          <ValidationProvider
            v-if="resources.loans.attributes.lender.macquarie.loan_statement == '0'"
            key="account_reference"
            v-slot="{ errors }"
            name="Field"
            :rules="getRules('account_reference')"
            vid="account_reference"
          >

            <v-text-field
              tile
              type="text"
              label="Account References"
              color="secondary"
              :error-messages="errors"
              v-model="resources.loans.attributes.lender.macquarie.account_reference"
              required
            >
            </v-text-field>
          </ValidationProvider>
          <ValidationProvider
            key="is_proprietary_company"
            v-slot="{ errors }"
            name="Field"
            :rules="getRules('is_proprietary_company')"
            vid="is_proprietary_company"
          >
          <ButtonGroup3
              class="mt-5"
              label="Is Company Public or Proprietary"
                v-model="resources.businesses.attributes.lender.macquarie.is_proprietary_company"
                :error-messages="errors"
                :options="[
                  { value: '1', label: 'Yes' },
                  { value: '0', label: 'No' },
                ]"
              />
            
          </ValidationProvider>
        </ValidationObserver>
      </v-sheet>
    </div>
</div>
</template>

<script>  
import { mapActions, mapMutations, mapGetters } from "vuex";
import subscriptions from "~/components/shared/subscriptions";
import shared from "~/components/v1/submission/MacquarieApplicant1/__shared";
import {
  store,
  actions,
} from "~/components/v1/submission/MacquarieApplicant1/__store";
import ButtonGroup3 from "~/components/base/ButtonGroup3";
import moment from 'moment';

export default {
  components: { ButtonGroup3 },
  mixins: [subscriptions, shared],
  props: {
    loanId: String,
    data: {
      type: Object,
      default: null,
    },
    businesses: {
      type: Object,
      default: null,
    },
    loans: {
      type: Object,
      default: null,
    },
    updater: undefined,
  },
  data() {
    return {
      driversLicenceHasSpecialRestriction: false,
      otherHasCircumstancesChanging: false,
      resources: {
        loans: {
          id: null,
          type: "loans",
          attributes: {
              lender: {
                macquarie: {
                    repayment_affordability : '',
                    credit_option: '', 
                    loan_statement: '',
                    financial_reference: '',
                    account_reference: '',
                    is_proprietary_company: '',
                }
              }
           },
        },
        businesses: {
          id: null,
          type: "businesses",
          attributes: {
              lender: {
                macquarie: {
                    is_proprietary_company: '',
                }
              }
           },
        },
      },
      labels: {
            household_reason: 'Household Reason',
            repayment_affordability : 'Repayment Affordability',
            credit_option: 'Credit Option',
            loan_statement: 'Loan Statement',
            financial_reference: 'Financial Reference',
            account_reference: 'Account Reference',
            decision_disclosure: 'Decision Disclosure',
            is_proprietary_company: 'Is Company Public or Proprietary',
        },
        validation_rules: {
          attributes: {
            household_reason: 'required',
            repayment_affordability : 'required',
            credit_option: 'required',
            loan_statement: 'required',
            financial_reference: 'required',
            account_reference: '',
            decision_disclosure: 'required',
            is_proprietary_company: 'required',
          },
        },
      options: {
        'household_reason': [
          {text: 'Living with family', value: 'LIVING_WITH_FAMILY' },
          {text: 'Senior Citizen', value: 'SENIOR_CITIZEN' },
          {text: 'Pre-tax Amount', value: 'PRE_TAX_AMOUNT' },
          {text: 'Rural Residence', value: 'RURAL_RESIDENCE' },
          {text: 'Others', value: 'OTHER' },
        ],
        'credit_option': [
          {text:'Commercial Express', value: 'COMM_EXPRESS'},
          {text:'Replacement', value: 'REPLACEMENT'},
          {text:'Balloon Refinance', value: 'BALLOON_RV_REFINANCE'},
          {text:'Non-Streamline', value: 'NON_STREAMLINE'},
        ],
        'financial_reference': [
          { value: 'FR001', text: 'ANZ Asset Finance', },
          { value: 'FR002', text: 'Australian Motorcycle & Marine Finance', },
          { value: 'FR003', text: 'Automotive Financial Services', },
          { value: 'FR004', text: 'Bank of Melbourne Automotive Finance - Commercial & Auto Finance', },
          { value: 'FR005', text: 'BMW Australia Finance', },
          { value: 'FR006', text: 'BOQ Equipment Finance', },
          { value: 'FR007', text: 'BOQ Vendor Finance (formerly known as CIT)', },
          { value: 'FR008', text: 'Branded Financial Services', },
          { value: 'FR009', text: 'Capital Finance - Consumer', },
          { value: 'FR010', text: 'Caterpillar Financial Australia', },
          { value: 'FR011', text: 'Classic Funding Group', },
          { value: 'FR012', text: 'Commonwealth Bank of Australia /Bankwest - Asset Finance', },
          { value: 'FR013', text: 'CNH Industrial Capital', },
          { value: 'FR014', text: 'De Lage Landen / AGCO Finance', },
          { value: 'FR015', text: 'Eclipx Group', },
          { value: 'FR016', text: 'Finance One', },
          { value: 'FR017', text: 'Fleetcare', },
          { value: 'FR018', text: 'FlexFleet', },
          { value: 'FR019', text: 'Flexigroup', },
          { value: 'FR020', text: 'Get Capital', },
          { value: 'FR021', text: 'Group & General Finance', },
          { value: 'FR022', text: 'HP Financial Services 122', },
          { value: 'FR023', text: 'Indigenous Business Australia Must receive written consent before giving the Reference (according to IBA’s governing Act)', },
          { value: 'FR024', text: 'John Deere Financial', },
          { value: 'FR025', text: 'Kawasaki Motors Finance', },
          { value: 'FR026', text: 'Komatsu Finance', },
          { value: 'FR027', text: 'Kubota Australia Finance', },
          { value: 'FR028', text: 'Latitude Automotive Financial Services (formerly GE Automotive Financial Services)', },
          { value: 'FR029', text: 'Latitude Personal Finance', },
          { value: 'FR030', text: 'Leasewise Australia', },
          { value: 'FR031', text: 'Liberty Financial', },
          { value: 'FR032', text: 'Skye/Lombard/Once', },
          { value: 'FR033', text: 'Macquarie Equipment Rentals', },
          { value: 'FR034', text: 'Macquarie Leasing (including Esanda Dealer)', },
          { value: 'FR035', text: 'Mercury Finance', },
          { value: 'FR036', text: 'MetroFinance', },
          { value: 'FR037', text: 'Mercedes-Benz Financial Services', },
          { value: 'FR038', text: 'Nissan Financial Services', },
          { value: 'FR039', text: 'OnDeck Capital', },
          { value: 'FR040', text: 'PACCAR Financial', },
          { value: 'FR041', text: 'Pepper Asset Finance', },
          { value: 'FR042', text: 'RAC Finance', },
          { value: 'FR043', text: 'RACV Finance', },
          { value: 'FR044', text: 'Ricoh Finance', },
          { value: 'FR045', text: 'Selfco Leasing', },
          { value: 'FR046', text: 'Service Finance Corporation', },
          { value: 'FR047', text: 'Sharp Finance', },
          { value: 'FR048', text: 'St George - Commercial & Auto Finance', },
          { value: 'FR049', text: 'TL Rentals', },
          { value: 'FR050', text: 'Thorn/Radio Rentals 123', },
          { value: 'FR051', text: 'Toyota Financial Services (National Customer Service)', },
          { value: 'FR052', text: 'Volkswagen Financial Services', },
          { value: 'FR053', text: 'Volvo Financial Services', },
          { value: 'FR054', text: 'Wells Fargo International', },
          { value: 'FR055', text: 'Westlawn Finance Limited', },
          { value: 'FR056', text: 'Westpac (Equipment Finance)', },
          { value: 'FR057', text: 'WEX Australia', },
          { value: 'FR058', text: 'Wingate Consumer Finance', },
          { value: 'FR059', text: 'Yamaha Finance', },
        ]
      },
    };
  },
  computed: {

  },
  methods: {
    ...mapActions("resource-actions", ["getPerson", "getIncome"]),
    isFieldType(field, key) {
      return field === "picklist"
        ? ["lender-wisr-industry", "lender-wisr-occupation"].includes(key)
        : false;
    },
    selectTab(tab) {
      this.tabSelection = tab;
    },
    isBtnGroup(key) {
      let keys = ["lender-wisr-forsee-repayment"];

      return keys.includes(key);
    },
    async validate() {
      const valid = await this.$refs.observer.validate();
      if (valid) {
        const { macquarie } = this.resources.loans.attributes.lender
        this.$emit('submit', {
          loans: {
            id: this.loanId,
            type: "loans",
            attributes: {
              lender: {
                macquarie: {
                  repaymentAffordability: macquarie.repayment_affordability === '1' ? 'YES' : 'NO',
                  creditOption: macquarie.credit_option,
                  loanStatement: macquarie.loan_statement === '1' ? 'YES' : 'NO',
                  financialReference: macquarie.financial_reference,
                  accountReference: macquarie.account_reference,
                }
              }
            }
          },
          businesses: {
            id: this.businesses.id,
            type: "businesses",
            attributes: {
              lender: {
                macquarie: {
                  isProprietaryCompany: this.resources.businesses.attributes.lender.macquarie.is_proprietary_company === '1' ? 'YES' : 'NO',
                }
              }
            }
          }
        })
      }
    },
    reset() {
      this.$refs.observer.reset();
    },
    async updateResourceFields() {

      if (this.loans) {
        const { macquarie } = this.loans.attributes.lender
          this.resources.loans.attributes.lender.macquarie = {
            repayment_affordability: macquarie.repaymentAffordability === 'YES' ? '1' : '0',
            credit_option: macquarie.creditOption,
            loan_statement: macquarie.loanStatement  === 'YES' ? '1' : '0',
            financial_reference: macquarie.financialReference,
            account_reference: macquarie.accountReference,
          }
          this.resources.businesses.attributes.lender.macquarie = {
            is_proprietary_company: this.businesses.attributes.lender.macquarie.isProprietaryCompany  === 'YES' ? '1' : '0',
          }

        }
    },
  },
  watch: {
    'resources.loans.attributes.lender.macquarie.loan_statement' (val) {
      if (val === '1') {
        this.resources.loans.attributes.lender.macquarie.financial_reference = ''
        this.resources.loans.attributes.lender.macquarie.account_reference = ''
      }
    },
    'resources.loans.attributes.lender.macquarie.credit_option' (val) {
      if (val === 'NON_STREAMLINE') {
        this.resources.loans.attributes.lender.macquarie.loan_statement = ''
      }
    },
    loans: {
      handler (val) {
        if (val) {
          this.updateResourceFields();
        }
      },
      immediate: true
    },
  }
};
</script>

<style lang="scss" scoped></style>
