<template>

    <v-card class="mx-auto flex__step-indicator" tile flat color="primary">
        <v-card-text class="py-4 py-md-6 pt-0 pt-md-0 text-center">
            <v-stepper alt-labels :value="computedSteps.active">
                <v-stepper-header width="600">

                    <template v-for="(step, index) in computedSteps.steps">
                        <v-stepper-step 
                            :key="step.index"
                            color="secondary" 
                            :step="step.index" 
                            :rules="[() => isValid(step)]"
                            :error-icon="step.icon ? step.icon : 'mdi-alert'"
                            :complete="true"
                            :complete-icon="step.icon ? step.icon : 'mdi-check'"
                            :class="['pt-0', {'completed': completed(step)}]"
                            >
                            {{step.name}}
                            <div class="d-block d-md-none">{{`${step.index} of ${steps.length}`}}</div>
                        </v-stepper-step>

                        <v-divider 
                            v-if="(index + 1) < computedSteps.steps.length"
                            :key="`s${step.index}`" 
                            :class="[{'error': !isValid(step), 'completed': completed(step)  }]"></v-divider>

                    </template>    

                </v-stepper-header>
            </v-stepper>
        </v-card-text>
        <v-card-actions>
            <v-card class="mx-auto pb-12 white bottom-spacer" tile flat />
        </v-card-actions>
    </v-card>

</template>

<script>

    import { mapState, mapGetters, mapActions } from 'vuex';
    import subscriptions from '~/components/shared/subscriptions'

    export default {
        mixins: [subscriptions],
        data(){
            return {}
        },
        computed: {
            ...mapGetters('flows', ['getSteps']),
            ...mapState({
                activeStep: state => state.flows.activeStep,
                steps: state => state.flows.steps,
                stepStatuses: state => state.statuses.stepStatuses,
            }),
            computedSteps(){

                const {steps, activeStep} = this

                let bp = this.$vuetify.breakpoint.name;
                
                let newSteps = steps
                let newActive = parseInt(activeStep)

                newSteps = newSteps.map((step, i) => {
                    step.index = (i + 1)                    
                    return step
                })

                if(bp == 'xs' || bp == 'sm'){

                    if(newActive <= 2) {

                        newSteps = newSteps.slice(0, 3)

                    }  else if(newActive == steps.length) {

                        newSteps = newSteps.slice(newActive - 3)
                    
                    } else {

                        newSteps = newSteps.slice((newActive - 2), (newActive + 1))

                    }

                }

                return {
                    active: newActive,
                    steps: newSteps
                }

            }
        },
        mounted() {
       
        },
        methods: {
            ...mapActions('flows', ['selectStep']),
            completed(step){

                let status = this.stepStatuses.find( status => {
                    return status.relationships.step.data.id == step.id
                });

                if(status) {
                    return status.attributes.value == 'valid'
                }
                
                return true
            },
            isValid(step){

                let status = this.stepStatuses.find( status => {
                    return status.relationships.step.data.id == step.id
                });

                if(status && status.attributes.value == 'dirty') {
                    return false
                }

                return true
            }
        }
    }
</script>

<style scoped lang="scss">

    .v-stepper {
        box-shadow: none;

        .v-divider {
            margin-top: 25px !important;
        }
    }

</style>
