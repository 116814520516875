<template>
<p class="font-weight-medium body_text_color--text text--lighten-4 ma-0" style="font-size: 12px">Your security is our priority. 
<a href="/report-incident" target="blank" class="body_text_color--text text--lighten-4">Report a concern</a><v-icon class="body_text_color--text text--lighten-4 ml-1" size="12">mdi-open-in-new</v-icon></p>
</template>

<script>
    export default {
        async mounted() {
        },
        methods: {
        },
        data() {
            return {
                reportLink: 'https://forms.clickup.com/36826178/f/133v22-90922/8KO5CAYLZCG6F4GDL4'
            }
        }
    }
</script>

<style lang="scss" scoped>
</style>
