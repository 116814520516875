<template>
    <div>
        <ValidationObserver ref="observer" tag="form" class="flex-form">
            <div class="flex-field" v-for="(field, key) in layout" :key="key">
                <ValidationProvider 
                    v-slot="{ errors }" 
                    :name="labels[key]"
                    :rules="getRules(key)" 
                    :hidden="hidden[key]"
                    :vid="key">

                    <ButtonSelection 
                        v-if="key == 'is-trading'"
                        v-model="resources.purchases.attributes[key]"
                        :label="labels[key]"
                        :options="['Yes', 'No']"
                        :error-messages="errors" />

                    <v-select
                        v-else-if="isFieldType('picklist', key)"
                        v-model="resources.purchases.attributes[key]"
                        :items="getOptions(key)"
                        :error-messages="errors"
                        color="secondary"
                        :label="labels[key]"
                        ></v-select>

                    <flex-date-picker
                        v-else-if="isFieldType('date', key)"
                        :label="labels[key]"
                        :errors="errors"
                        v-model="resources.purchases.attributes[key]"
                    ></flex-date-picker>

                    <v-text-field 
                        v-else-if="isCurrency(key)"
                        prefix="$"
                        tile
                        type="number"
                        :label="labels[key]"
                        color="secondary"
                        :error-messages="errors"
                        v-model="resources.purchases.attributes[key]"
                        required>
                    </v-text-field>

                    <template v-else>
                        <div v-if="key == '__spacer'"></div>
                        <v-text-field 
                            v-else
                            tile
                            type="text"
                            :label="labels[key]"
                            color="secondary"
                            :error-messages="errors"
                            v-model="resources.purchases.attributes[key]"
                            required>
                        </v-text-field>
                    </template>

                </ValidationProvider>
            </div>
        </ValidationObserver>

        <div class="d-flex justify-center mt-6">

            <div :style="`background-color: ${hexToRGBA(getActiveTheme.colors.secondary, 0.1)}`" class="pa-8 rounded-xl text-center">
                <div class="body-1 mb-2">Required Amount for this Purchase</div>
                <h2 class="font-size-22">{{ getRequiredLoanAmount | currency }}</h2>
            </div>

        </div>

    </div>
</template>

<script>
    /**
     * REQUIRED!
     */
 
    import { mapActions, mapGetters } from 'vuex'
    import {store, actions} from '~/components/v1/PurchaseEditor6/__store'
    import ButtonSelection from '~/components/base/ButtonSelections'
    import Radio2 from '~/components/v1/DynamicField1/includes/_Radio2'
    import NvicFinder from '~/components/base/NvicFinder' 
    import mixins from '~/components/v1/PurchaseEditor6/_mixins'


    export default {
        mixins: [mixins],
        components: {
            Radio2,
            NvicFinder,
            ButtonSelection
        },
        props: {
            data: {
                type: Object,
                default: null
            },
            updater: undefined
        },
        data(){
            return {
                layout: {
                    "purchase-price": 0,
                    "cash-deposit": 0,
                    "is-trading": "",
                    "__spacer": "",
                    "trade-in-amount": 0,
                    "trade-in-description": "",
                    "payout-amount": 0,
                    "payout-to": "",
                    "required-loan-amount": "",
                },
                resources: {
                    loans : {
                        type: "loans",
                        id: null,
                        attributes: {
                            "sale-type": "",
                        }
                    },
                    purchases: {
                        type: "purchases",
                        attributes: {
                            "purchase-price": 0,
                            "cash-deposit": 0,
                            "is-trading": "",
                            "__spacer": "",
                            "trade-in-amount": 0,
                            "trade-in-description": "",
                            "payout-amount": 0,
                            "payout-to": "",
                            "required-loan-amount": "",
                        },
                        relationships: {
                            application: {
                                data : {
                                    type: "applications",
                                    id: null
                                }
                            }
                        }
                    }
                },

                hidden: {
                    "required-loan-amount": true,
                    "trade-in-amount": true,
                    "trade-in-description": true,
                    "payout-amount": true,
                    "payout-to": true,
                },
                currencies: [
                    "purchase-price",
                    "cash-deposit",
                    "trade-in-amount",
                    "payout-amount",
                ],
                labels: {
                    "is-trading": "Are you trading in an asset?",
                    "purchase-price": "Purchase Price",
                    "cash-deposit": "Cash Deposit",
                    "trade-in-amount": "Trade in Amount",
                    "trade-in-description": "Trade Description",
                    "payout-amount": "Payout Amount",
                    "payout-to": "Payout To",
                    "required-loan-amount": "Required Loan Amount",
                    "sale-type": "Sale Type",
                },
                validation_rules: {
                    attributes: {
                        "__spacer": "",
                        "is-trading": "required",
                        "purchase-price": "required|between:1,9999999",
                        "cash-deposit": "between:0,9999999",
                        "trade-in-amount": "between:0,9999999",
                        "trade-in-description": "",
                        "payout-amount": "between:0,9999999",
                        "payout-to": "",
                        "sale-type": "required|string|in:Dealer,Private Sale",
                        "required-loan-amount": "required"
                    }
                },

            }
        },
        mounted() {
            this.updateResourceFields()
        },
        computed:{
            isFormTypeEdit(){
                return this.data != null
            },
            getRequiredLoanAmount(){
                return this.resources.purchases.attributes["required-loan-amount"]
            },
            getPurchasePrice(){
                return this.resources.purchases.attributes["purchase-price"]
            },
            getDeposit(){
                return this.resources.purchases.attributes["cash-deposit"]
            },
            getIsTradingField() {
                return this.resources.purchases.attributes['is-trading']
            },
            getTradeAmountField() {
                return this.resources.purchases.attributes['trade-in-amount']
            },   
            getPayoutAmountField() {
                return this.resources.purchases.attributes['payout-amount']
            }        
        },
        methods: {
            // Submit Observer
            async validate(){

                const valid = await this.$refs.observer.validate()

                if(valid) {

                    // attache app id
                    this.resources.purchases.relationships.application.data.id = this.appId

                    this.$emit('submit', this.resources.purchases)
                }

                return valid

            },
            reset(){

                this.resources.purchases =  {
                        type: "purchases",
                        attributes: {
                            "purchase-price": "",
                            "cash-deposit": "",
                            "trade-in-amount": "",
                            "trade-in-description": "",
                            "payout-amount": "",
                            "payout-to": "",
                            "required-loan-amount": "",
                        },
                        relationships: {
                            application: {
                                data : {
                                    type: "applications",
                                    id: null
                                }
                            }
                        }
                    }

                this.$refs.observer.reset()

        },
        updateResourceFields(){

                if(this.isFormTypeEdit){
                    this.resources.purchases = this.filterResourceFields(this.resources.purchases, this.data)

                    Object.keys(this.resources.purchases.attributes).forEach(attr => {
                        if(this.isCurrency(attr)) {
                            this.resources.purchases.attributes[attr] = this.resources.purchases.attributes[attr] ? this.resources.purchases.attributes[attr] : 0
                        }
                    })

                    if(this.resources.purchases.attributes['trade-in-amount'] || this.resources.purchases.attributes['payout-amount']) {
                        this.resources.purchases.attributes['is-trading'] = 'Yes'
                    } else {
                        this.resources.purchases.attributes['is-trading'] = 'No'
                    }

                }
            },
            isCurrency(key){
                return this.currencies.includes(key)
            },
        },
        watch: {
            getPayoutAmountField(value) {
                let amount = value ? parseInt(value) : 0

                if(amount > 0) {
                    this.validation_rules.attributes['payout-to'] = 'required'
                } else {
                    this.validation_rules.attributes['payout-to'] = ''
                }
            },
            getTradeAmountField(value) {
                let amount = value ? parseInt(value) : 0

                if(amount > 0) {
                    this.validation_rules.attributes['trade-in-description'] = 'required'
                } else {
                    this.validation_rules.attributes['trade-in-description'] = ''
                }
            },
            getIsTradingField(value) {
                if(value == 'Yes') {
                    this.hidden['trade-in-amount'] = false
                    this.hidden['trade-in-description'] = false
                    this.hidden['payout-amount'] = false
                    this.hidden['payout-to'] = false
                } else {
                    this.hidden['trade-in-amount'] = true
                    this.hidden['trade-in-description'] = true
                    this.hidden['payout-amount'] = true
                    this.hidden['payout-to'] = true
                }
            },
            'resources.purchases.attributes': {
                handler(val) {
                    let loanAmount = 0
                    let purchasePrice = val['purchase-price'] ? parseFloat( val['purchase-price'] ) : 0
                    let payoutAmount = val['payout-amount'] ? parseFloat( val['payout-amount'] ) : 0
                    let cashDeposit = val['cash-deposit'] ? parseFloat( val['cash-deposit'] ) : 0
                    let tradeInAmount = val['trade-in-amount'] ? parseFloat( val['trade-in-amount'] ) : 0
                    loanAmount = ( purchasePrice + payoutAmount ) - ( cashDeposit + tradeInAmount )

                    val['required-loan-amount'] = loanAmount
                },
                deep: true
            },
            updater(val) {
                this.updateResourceFields()
            },
        }

    }
</script>

<style scoped lang="scss">
    .flex-form {
        display: grid;
        grid-column-gap: 30px;
        grid-row-gap: 0;
        grid-template-columns:  repeat(auto-fit, minmax(260px, 1fr));

        .flex-field {
            align-self: start;
        }
    }
</style>
