<template>
    <v-card flat tile class="mx-auto" :class="vb.mdAndUp ? 'px-8' : 'px-2'" max-width="1600">

        <v-card v-if="error" flat tile class="rounded-xl warning lighten-5 pa-2" :class="vb.mdAndUp ? 'px-16' : 'px-2'">
            <v-card-actions :class="{'d-flex flex-column': vb.smAndDown}">
                <v-icon color="warning" size="50">mdi-information</v-icon>
                <div class="px-4 title" :class="{'text-center pt-2': vb.smAndDown}">
                    <div>
                        Record not found please check the URL.
                    </div>
                    <div class="title">Or please try hard-refreshing your browser. If you're not sure how to do this, see <a href="https://www.howtogeek.com/672607/how-to-hard-refresh-your-web-browser-to-bypass-your-cache" target="blank">here</a></div>
                    <div class="title">If a hard-refresh does not fix the issues you’re experiencing, please reach out to your consultant or relationship manager.</div>
                </div>
                <v-spacer />
            </v-card-actions>
        </v-card>

        <template v-else>
            <div :class="vb.mdAndUp ? 'px-16 title font-size-22' : 'px-2 title-2 text-center'">
                Please tell us about your assets
            </div>
            <v-card-text :class="vb.mdAndUp ? 'px-16' : 'px-2'">

                <v-expansion-panels flat :accordion="true" v-model="panels" multiple>
                    <v-expansion-panel
                        v-for="(asset, index) in buildList"
                        :key="index">

                        <v-card
                            v-if="filterData(asset)"
                            flat
                            tile
                            outlined
                            :ripple="false"
                            class="rounded-xl my-2">

                                <v-expansion-panel-header class="py-1 px-2">
                                    <v-card-text class="d-flex pa-2">
                                        <div class="d-flex py-2 px-2">
                                            <div :class="vb.mdAndUp ? 'body-1' : 'caption'">
                                                {{ asset.name }}
                                            </div>
                                            <v-avatar
                                                v-if="asset.data.length > 0" size="24"
                                                class="caption mx-2"
                                                :style="`background-color: ${hexToRGBA(getActiveTheme.colors.secondary, 0.1)}`">
                                                {{ asset.data.length }}
                                            </v-avatar>
                                        </div>
                                        <v-spacer />
                                        <div v-if="getTotalAsset(asset.data) != 0 && vb.mdAndUp" class="py-2 px-16 font-weight-bold justify-start">
                                            {{ getTotalAsset(asset.data) | currency}}
                                        </div>
                                        <v-btn depressed large max-height="40" class="secondary" @click.stop="addAsset(asset, index)">
                                            Add
                                        </v-btn>
                                    </v-card-text>
                                    <template v-slot:actions>
                                        <v-icon color="accent lighten-3" class="mr-2">
                                            {{ panels.includes(index) ? 'mdi-minus' : 'mdi-plus' }}
                                        </v-icon>
                                    </template>
                                </v-expansion-panel-header>

                                <v-expansion-panel-content v-if="asset.data.length > 0">
                                    <div v-for="(item, indx) in asset.data" :key="indx">
                                        <v-divider></v-divider>
                                        <v-card-text  class="d-flex pa-2 px-3">
                                            
                                            <div v-if="asset.name == 'Property - Investment' || asset.name == 'Property - Owner Occupied'" class="py-3 px-4 body-1 font-weight-bold">
                                                {{ getFullAddress(getAddress(item)) }}
                                            </div>
                                            <div v-else-if="asset.name == 'Vehicle - Motor' || asset.name == 'Vehicle - Other'" class="py-3 px-4 body-1 font-weight-bold">
                                                {{ getFullVehicleDescription(item) }}
                                            </div>
                                            <div v-else class="py-3 px-4 body-1 font-weight-bold">
                                                {{ getKindName(item.relationships.kind.data.id) }}
                                            </div>

                                            <template v-if="vb.mdAndUp">
                                                <div v-if="asset.name == 'Vehicle - Motor' || asset.name == 'Vehicle - Other'" class="py-2 px-4">
                                                    <v-chip :style="`background-color: ${hexToRGBA(getActiveTheme.colors.secondary, 0.1)}`">
                                                        {{ getKindName(item.relationships.kind.data.id) }}
                                                    </v-chip>
                                                </div>
                                            </template>

                                            <template v-if="getOwners(item).length > 0 && vb.mdAndUp">
                                                <div class="py-2 px-4 d-flex">
                                                    <v-chip class="mx-1" v-for="(own, indx) in getOwners(item)" :key="indx" :style="`background-color: ${hexToRGBA(getActiveTheme.colors.secondary, 0.1)}`">
                                                        {{ own }}
                                                    </v-chip>
                                                </div>
                                            </template>

                                            <v-spacer />
                                            <div v-if="vb.mdAndUp" class="px-2 py-3">
                                                {{ item.attributes['asset-value'] | currency}}
                                            </div>

                                            <div v-if="item.attributes['being-refinanced'] && item.attributes['being-refinanced'] == 'Yes'" class="px-2 py-2">
                                                <v-chip :style="`background-color: ${hexToRGBA(getActiveTheme.colors.secondary, 0.1)}`">
                                                    Financed
                                                </v-chip>
                                            </div>
                                            <div v-else class="px-13 py-3">
                                            </div>

                                            <v-avatar class="mx-2 mt-2" size="30" :style="`background-color: ${hexToRGBA(getActiveTheme.colors.primary, 0.1)}`">
                                                <v-btn
                                                    fab text                    
                                                    depressed
                                                    rounded
                                                    x-small
                                                    dark
                                                    color="secondary"
                                                    @click="editAsset(item)">
                                                    <v-icon size="18">
                                                        mdi-pencil
                                                    </v-icon>
                                                </v-btn>
                                            </v-avatar>
                                            <ConfirmModal 
                                                async
                                                :title="`Delete ${capitalize(toSingular(item.type))}`"
                                                :processing="deleting"
                                                :message="buildDeleteMsg(item)"
                                                @confirm="handleConfirm(item)"
                                                class="mx-2">
                                                <template v-slot:activator="{on}">
                                                    <v-avatar class="mt-2" size="30" :style="`background-color: ${hexToRGBA(getActiveTheme.colors.primary, 0.1)}`">
                                                        <v-btn text depressed fab x-small :ripple="false" color="light_gray_2" @click.stop="on" > 
                                                            <v-icon size="18">mdi-delete</v-icon>
                                                        </v-btn>
                                                    </v-avatar>
                                                </template>
                                            </ConfirmModal>
                                        </v-card-text>
                                    </div>
                                </v-expansion-panel-content>

                        </v-card>

                    </v-expansion-panel>
                </v-expansion-panels>

            </v-card-text>

            <v-card-actions>
                <v-spacer v-if="vb.mdAndUp"></v-spacer>
                <v-card flat tile
                    class="rounded-xl py-2"
                    :style="`background-color: ${hexToRGBA(getActiveTheme.colors.secondary, 0.1)}`"
                    :class="vb.mdAndUp ? 'mx-14 px-10' : ''"
                    :width="vb.mdAndUp ? '' : '100%'">
                        <v-card-text>
                            <div class="body-1 text-right mb-2">Your total asset value is</div>
                            <h3 class="font-size-24 text-right">{{ totalAsset | currency }}</h3>
                        </v-card-text>
                </v-card>
            </v-card-actions>

        </template>
       

        <AssetEditorStepper
            v-if="dialog"
            :dialog="dialog" 
            :fieldsData="fieldsData"
            @save-asset="saveAsset"
            @close-modal="closeStepper"/>

    </v-card>
</template>
<script>
import AssetEditorStepper from '~/components/v1/AssetEditor1/_AssetEditor1Stepper'
import ConfirmModal from '~/components/base/ConfirmModal2'

import shared from '~/components/v1/AssetEditor1/__shared'
import { actions } from '~/components/v1/AssetEditor1/__store'
import { mapActions } from 'vuex'


export default {
    components: { AssetEditorStepper, ConfirmModal },
    mixins: [shared],
    data() {
        return {
            fieldsData: {},
            deleting: false,
            error: '',
            panels: [],
            dialog: false
        }
    },
    watch: {
    },
    methods: {
        ...mapActions('resource-actions', [
            'getPeople',
            'getBusinesses',
            'getAddresses',
            'getLoan',
            'deleteIncome',
            'deleteAsset',
        ]),
        async pull(){

            try {

                actions.resetAll()

                let promise1 = this.getPeople({"include" : 'parent-parts,assets,liabilities,expenses'})

                let promise2 = this.getAddresses()

                let promise3 = this.getLoan(this.getLoanId)

                let promise4 = this.getBusinesses({"include" : 'parent-parts,assets,liabilities,expenses'})

                const res = await Promise.all([promise1, promise2, promise3, promise4]) 

                let people = this.getRequiredPeopleByRoles(res[0].data, res[3].data)
                let businesses = this.getRequiredBusinessesByRoles(res[3].data)

                let parts = this.getRequiredParts([res[0].data, res[3].data])

                let requiredResources = this.getRequiredResources([res[3].data, res[0].data])

                // let addresses = this.getRequiredAddresses(res[1].data)
                let addresses = this.getAllAddresses(res[1].data)
                
                let loan = res[2]

                if(loan.data?.data?.attributes){

                    let loanType = (loan.data.data.attributes["finance-for-business-purposes"] == 'Yes') ? 'Commercial' : 'Consumer';

                    let appType = this.getKindName(loan.data.data.relationships.kind.data.id)
                    
                    actions.setLoanType(loanType)
                    actions.setAppType(appType)
                }
                
                actions.setPeople(people)
                actions.setBusinesses(businesses)
                actions.setResources(requiredResources)
                // actions.setAddresses(addresses)
                actions.setAllAddresses(addresses)

                let entities = [
                    ...businesses,
                    ...people
                ]

                actions.setEntities(entities)
                actions.setEntityParts(parts)

                if(this.routeParams?.record_id) {

                    let entity = this.entities.filter(entity => entity.id == this.routeParams.record_id && entity.type == this.routeParams.record)
                    
                    if(entity.length > 0) {
                        // if entity type dont match with entities on the application
                        if(entity[0].type != this.routeParams.record) {
                            this.error = true
                        } else {
                            actions.setSelectedEntity(entity[0])
                            actions.setPreSelectRoles([entity[0].id])
                            this.filterAssetsData(entity[0])
                        }
                    } else {
                        this.error = true
                    }


                }

            } catch (error) {

                console.log('Ooops!..', error)

            }
        },
        async push() {

            return {valid:true, status:'valid', data:{}}

        },
        filterData(asset) {
            let isShow = true

            // condition for Property - Owner Occupied
            if(asset.id == '10-15') {
                if(asset.data.length > 0) {
                    let hasOwnerOccupied = false

                    asset.data.forEach(element => {
                        // 10-16 = owner occupied
                        if(element.relationships.kind.data.id == '10-16') {
                            hasOwnerOccupied = true
                        }
                    })
                    
                    if(hasOwnerOccupied) {
                        isShow = true
                    } else {
                        isShow = false
                    }

                } else {

                    isShow = false

                }
            }

            return isShow
        },
        async addAsset(asset, index) {
            actions.setCurrentStep(asset)
            actions.setSelectedIndex(index)
            actions.setPreSelectRoles([this.selectedEntity.id])

            if(asset.children.length === 0) {
                await this.getAssetFieldLayout(asset)
            } else {
                await actions.setSteps([asset])
            }
           
            this.dialog = true
        },
        saveAsset(data) {
            // expand panel if added
            if(!this.panels.includes(this.selectedIndex)) {
                this.panels.push(this.selectedIndex)
            }

            // if(data.attributes['being-refinanced'] && data.attributes['being-refinanced'] == 'Yes') {
            //     this.dialog2 = true
            // }
        },
        async editAsset(item) {
            await actions.setCurrentStep([])
            await actions.setSteps([])
            await this.getAssetFieldLayout({ id: item.relationships.kind.data.id })
            this.fieldsData = item
            this.dialog = true
        },
        async handleConfirm(data) {

            this.deleting = true

            let res = await this.deleteAsset(data.id)

            if(res.status === 204) {

                // Update local states
                actions.deleteResource(data)

                await this.filterAssetsData(this.selectedEntity)
                await this.updateEntitiesAndParts()

            }

            this.deleting = false
        },
        closeStepper() {
            this.dialog = false
            this.fieldsData = {}
        },
        closeLiabitityStepper() {
            this.dialog2 = false
            this.fieldsLiabilityData = {}
        }
    }
}
</script>
