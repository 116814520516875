
<template>
    <FlexWrap @resize="handleResize">
        <v-card :disabled="isDisabled || !validComponentData" class="mx-auto" tile flat>
            <v-card-text class=" pa-4 pt-0 pa-md-10 pt-md-0" >
                <v-card tile flat class="mx-auto " max-width="1500" >
                    <v-container pa-0 fluid>
                        <v-row>
                            <v-col cols="12">
                                <ValidationObserver ref="observer" tag="form" >
                                    <div v-for="(layout, index) in fieldLayout" :key="index">
                                        <div class="title ma-0 my-2 body_text_color--text" v-if="layout.title">
                                            {{layout.title}}
                                        </div>
                                        <v-row>
                                            <template v-for="(field, key) in fieldLayoutMaker(layout, resources.people.attributes)">    
                                                <v-col :cols="responsiveComponent" v-if="visible[key]" class="py-0" :key="key">
                                                    <ValidationProvider 
                                                        v-slot="{ errors }" 
                                                        :name="labels[key]" 
                                                        :rules="getRules(key)" :vid="key">

                                                        <v-select
                                                        v-if="isFieldType('picklist', key)"
                                                        :items="getOptions(key)"
                                                        :label="labels[key]"
                                                        :error-messages="errors"
                                                        v-model="resources.people.attributes[key]"
                                                        color="secondary">
                                                        </v-select>
                                                        
                                                        <template v-else-if="isFieldType('date', key)">
                                                            <FlexDatePicker3
                                                                :label="labels[key]"
                                                                :errors="errors"
                                                                v-model="resources.people.attributes[key]"
                                                            ></FlexDatePicker3>
                                                        </template>

                                                        <v-text-field 
                                                        v-else
                                                        :type="'text'"
                                                        :label="labels[key]"
                                                        :error-messages="errors"
                                                        v-model="resources.people.attributes[key]"
                                                        color="secondary">
                                                        </v-text-field>
                                                    </ValidationProvider>
                                                </v-col>
                                            </template>    
                                        </v-row>
                                    </div>
                                </ValidationObserver>
                            </v-col>   
                        </v-row>  
                    </v-container>
                </v-card>
            </v-card-text>                           
        </v-card>
    </FlexWrap>
</template>
<script>

    import subscriptions from '~/components/shared/subscriptions'
    import { mapState, mapGetters, mapActions, mapMutations } from 'vuex'
    import FlexDatePicker3 from '~/components/base/FlexDatePicker3'  
    import fieldOptions from '~/components/shared/field-options'
    import FlexWrap from '~/components/base/FlexWrap'
    import helpers from '~/plugins/helpers'
    import formHelpers from '~/components/shared/form-helpers'
    import veeValidate from '~/components/shared/validation'

    import { getDateValidations } from '~/plugins/date-helper.js'
    const { minDate } = getDateValidations()

    export default {
        name: 'CreditHistoryEditor2',
        components: {
            FlexDatePicker3,
            FlexWrap,
            
        },
        mixins: [subscriptions, helpers,formHelpers,veeValidate],
        data(){
            return {
                sizes: null,
                resources: {
                    people : {
                        type: "people",
                        id: null,
                        attributes: {
                            "credit-status" : "",
                            "previous-bankrupt": "",
                            "bankruptcy-status": "",
                            "bankruptcy-declared-date": "",
                            "bankruptcy-discharge-date": ""
                        },
                        relationships: {
                            application: {
                                data: {type: "applications", id: null}
                            }
                        }
                    }
                },
                fieldLayout: [
                    [
                        "previous-bankrupt",
                        "bankruptcy-status",
                        "bankruptcy-declared-date",
                        "bankruptcy-discharge-date",
                        "credit-status"
                    ],
                ],
                visible: {
                    "credit-status": true,
                    "previous-bankrupt": true,
                    "bankruptcy-status": false,
                    "bankruptcy-declared-date": false,
                    "bankruptcy-discharge-date": false,
                },
                // Static field labels. cause sometimes field names and labels are different
                labels: {
                    "credit-status": "Credit File Status",
                    "previous-bankrupt": "Previous Bankrupt",
                    "bankruptcy-status": "What is the current  status?",
                    "bankruptcy-declared-date": "When did you declare bankruptcy?",
                    "bankruptcy-discharge-date": "When was the bankruptcy discharged?",
                },
                validation_rules: {
                    type: "people",
                    attributes: {
                        "previous-bankrupt": "required|string|in:Yes,No",
                        "credit-status": `required|in:${fieldOptions["credit-status"]}`,
                        "bankruptcy-status": `required_if:previous-bankrupt,Yes|in:${fieldOptions["bankruptcy-status"]}`,
                        "bankruptcy-declared-date": `required_if:previous-bankrupt,Yes|date_format:Y/m/d|after:${minDate}|before:now`,
                        "bankruptcy-discharge-date": `required_if:bankruptcy-status,Discharged Part 9,Discharged Bankrupt|date_format:Y/m/d|after:${minDate}|before:now`
                        
                    }
                },
                validComponentData: true
            }
        },
        computed: {
            ...mapState({
                appId: state => state.flows.appId
            }),
            ...mapGetters('kinds', [
                'kindsOfApplicant'
            ]),
            hasPeople(){
                return this.appData.relationships.people.data.length > 0
            },
            previousBankrupt(){
                return this.resources.people.attributes["previous-bankrupt"]
            },
            bankruptcyStatus(){
                return this.resources.people.attributes["bankruptcy-status"]
            },
            peopleId () {

                if(this.$route?.params && this.$route?.params.record == 'people') {

                    return this.$route?.params?.record_id
                }

                return null

            },
            responsiveComponent(){

                if(!this.sizes) return 12

                const {sm, xs, md, lgAndUp, width} = this.sizes

                let formTypes = [
                    'fieldLayout',
                    
                ]
                
                if(lgAndUp){

                    if ( formTypes.includes(this.entityFormType) ) {
                        return 6
                    } else {
                        return 3
                    }
                }

                if(md){

                    if ( formTypes.includes(this.entityFormType)) {
                        return 6
                    } else {
                        return 3
                    }
                }

                if(sm){
                    return 6
                }

                if(xs){
                    return 12
                }

            },
        },
        watch: {
           
            validComponentData(val){
                if(!val) {
                    this.showComponentWarning()
                }
            },
            previousBankrupt(val){

                if(val == 'No' || val == null){

                    this.resources.people.attributes["bankruptcy-status"] = ""
                    this.resources.people.attributes["bankruptcy-declared-date"] = ""

                    this.visible["bankruptcy-status"] = false
                    this.visible["bankruptcy-declared-date"] = false
                } 

                if(val == 'Yes'){

                    this.visible["bankruptcy-status"] = true
                    this.visible["bankruptcy-declared-date"] = true
                }

            },
            bankruptcyStatus(val){

                if(val && val.includes('Discharged')){

                    this.visible["bankruptcy-discharge-date"] = true

                } else {

                    this.resources.people.attributes["bankruptcy-discharge-date"] = ""
                    this.visible["bankruptcy-discharge-date"] = false
                }

            },
        },
        created() {

        },
        mounted(){

            if(!this.hasPeople){
                // Error...
                this.validComponentData = false
            }

        },
        methods: {
            ...mapActions('resource-actions', [
                'getPeople',
                'getPerson',
                'updatePerson',
                'createPerson'
            ]),
            async pull() {


                if(this.peopleId) {

                    let res = await this.getPerson(this.peopleId)

                    this.resources.people = this.filterResourceFields(this.resources.people, res.data.data)

                } else {
                    
                    let res = await this.getPeople()

                    this.resources.people = this.filterResourceFields(this.resources.people, res.data.data[0])

                }

            },
            async push() {

                const observer = this.$refs.observer
     
                const {people} = this.resources

                // Exclude relationships and other stuff
                let payload = {
                    type: people.type,
                    attributes: this.resources.people.attributes,

                }    

                let personRes = null

                if(this.peopleId) {

                    payload.id = this.peopleId
                    personRes = this.updatePerson(payload)

                } else {

                    // Include relationships when creating resource

                    payload.relationships = {
                        application: {
                            data: {type: "applications", id: this.appId}
                        }
                    }

                    personRes = this.createPerson(payload)
                }

                const {valid, status} = await this.validateFormData()
                const data = await this.appDataHelper([personRes])

                return {valid, status, data}

            },
            filterResourceFields(source, target){

                // console.log(source)
                // console.log(target)

                let newResource = {
                        type: "",
                        id: null,
                        attributes: {},
                        relationships: {}
                }

                Object.keys(source.attributes).forEach( field => {
                    newResource.attributes[field] = target.attributes[field]
                })

                newResource.type = target.type
                newResource.id = target.id

                if(target.hasOwnProperty('relationships')) {

                    Object.keys(target.relationships).forEach(item => {

                        if( target.relationships[item].hasOwnProperty('data') ) {
                            newResource.relationships[item] = target.relationships[item]
                        }
                    })

                }
                return newResource
            },
            handleResize(sizes){
                this.sizes = sizes
            },
            
        },

    
    }
</script>

<style scoped lang="scss">
</style>

