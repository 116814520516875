<template>
   <v-container>
        <div class="body-1 text-center font-weight-medium px-10" style="font-size: 1.1rem !important;">{{ label }}</div>
            <v-item-group v-model="selected">
                <v-row align="center" justify="center" class="mx-12">

                    <v-col
                        v-for="(entity, key) in data"
                        :key="key" cols="12" md="4"
                        align="center"
                        class="d-flex pa-0 ma-0">
                        <v-item :value="entity.id" v-slot="{ active, toggle }" style="cursor: pointer;" class="mx-auto">
                            <v-alert
                                disabled
                                @click="toggled(toggle, !active, entity)" outlined 
                                :color="`${active ? 'secondary' : errors[0] ? 'error' : 'light_gray_1'}`" 
                                class="rounded-xl pa-0 mx-0 my-0 px-0 mb-5">
                                <v-card flat
                                    class="align-center rounded-xl"
                                    width="170">
                                    <v-card-text class="text-center py-8">
                                        <span class="font-weight-bold">
                                            {{ entity.attributes['first-name'] ? entity.attributes['first-name'] : 'Person' }}
                                        </span>
                                    </v-card-text>
                                    
                                    <v-card-actions 
                                        :class="`text-center ${active ? 'secondary lighten-5' : 'grey lighten-5'} px-0 py-4`">
                                        <v-spacer></v-spacer>
                                        
                                        <v-avatar
                                            color="light_gray_1"
                                            size="26">
                                            <v-icon 
                                                :class="`${active ? 'secondary' : 'light_gray_1'}`"
                                                :color="`${active ? 'secondary lighten-5' : 'light_gray_1'}`"
                                                size="16">
                                                mdi-check
                                            </v-icon>
                                            <span class="white--text headline"></span>
                                        </v-avatar>
                                        
                                        <v-spacer></v-spacer>
                                    </v-card-actions>
                                </v-card>
                            </v-alert>
                        </v-item>
                    </v-col>

                </v-row>
            </v-item-group>
            <div class="with-dynamic-validations caption text-center pt-1">
                {{ errors[0] }}
            </div>
    </v-container>
</template>
<script>
import resourceHelpers from '~/components/shared/resource-helpers'
export default {
    mixins: [resourceHelpers],
    model: {
        prop: 'modelValue',
        event: 'input'
    },
    props: {
        modelValue: {
            type: String,
            default: () => ''
        },
        label: {
            type: String,
            default: ""
        },
        data: {
            type: Array,
            default: () => [],
        },
        errors: {
            type: Array,
            default: () => [],
        },
        parts: {
            type: Array,
            default: () => [],
        },
    },
    data() {
        return {
            selected: ''
        }
    },
    created() {
        this.selected = this.modelValue
    },
    watch: {
        modelValue(val) {
            if (val.length > 0) {
                this.selected = this.modelValue
            }
        }
    },
    methods: {
        toggled(toggle, active, item) {
            toggle()
            this.$emit('input', active || this.selected ? this.selected : '')
        }
    }
}
</script>