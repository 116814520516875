import Vue from "vue";

export const store = Vue.observable({

    primaryApplicant: null,
    primaryApplicantKind: null,

    
    // Modal Tiles Values
    entityPartKind: null,
    entityKind: null,
    parentEntity: null,


    resources: {
        'flow-statuses': [],
        'step-statuses': [],
        loans: null,
        people: [],
        businesses: [],
        parts: []
    },

    // Stepper
    stepper: 0,
    steps: [],
    currentStep: null,


    // Dialog
    saving: false,
    deleting: false,
    dialog: false,

    // form
    fieldType: 'people',
    isEdit: false,
    showDelete: false,
    deletedItems: {}

});


export const actions = {

    setPrimaryApplicant(payload) {
        store.primaryApplicant = payload;
    },

    setIncludedResources(payload) {

        let resource = [
            'flow-statuses',
            'step-statuses'
        ]

        resource.forEach(res => {
            if(payload[res]) {
                store.resources[res] = payload[res]
            }
        })
    },

    setResource(payload) {

        let type = ''

        if(payload.hasOwnProperty('type')){

            type = payload.type

        }  else {

            if (payload && payload.length){

                type = payload[0].type
    
            }

        }

        store.resources[type] = payload;
    },

    setPrimaryApplicantKind(payload) {
        store.primaryApplicantKind = payload;
    },

    setupStep(payload) {
        store.steps.push(payload)
    },
    setStepper(payload){
        store.stepper = payload
    },
    setCurrentStep(payload){
        store.currentStep = payload
    },
    setSteps(payload) {
        store.steps = payload
    },
    nextStep(payload) {

        store.steps.push(payload)

        setTimeout( () => {
            store.stepper = store.stepper+1
            store.currentStep = payload 
        }, 300)

    },
    prevStep() {

        store.stepper = store.stepper-1
        store.saving = true

        setTimeout( () => {
            store.steps.pop()
            store.currentStep = store.steps[store.stepper] 
            store.saving = false
        }, 300)
    },
    setSaving(value){
        store.saving = value
    },
    setDeleting(value){
        store.deleting = value
    },
    setDialog(value){
        store.dialog = value
    },
    initialize(){

        store.primaryApplicant = null
        store.primaryApplicantKind = null
        
        // Modal Tiles Values
        store.entityPartKind = null
        store.entityKind = null
        store.parentEntity = null
    
        store.resources = {
            loans: null,
            people: [],
            businesses: [],
            parts: []
        }
    
        // Stepper
        store.stepper = 0
        store.steps = []
        store.currentStep = null
    
        // Dialog
        store.saving = false

    },
    resetSteps(){

        store.stepper = 0
        store.steps = []
        store.currentStep = null
        store.parentEntity = null
        store.entityPartKind = null
        store.entityKind = null
        store.isEdit = false

    },
    updateResources(payload){

        if(payload.data.data){

            let entity = payload.data.data;

            store.resources[entity.type] = store.resources[entity.type].map(resource => {

                if(resource.id == entity.id){
                    return entity
                }

                return resource
            })

        }

    },
    addResource(payload){

        if(payload.data.data){

            let entity = payload.data.data;

            store.resources[entity.type].push(entity)

        }

    },
    setEntityPartKind(payload) {
        store.entityPartKind = payload
    },
    setEntityKind(payload) {
        store.entityKind = payload
    },
    setParentEntity(payload) {
        store.parentEntity = payload
    },
    setFieldType(payload) {
        store.fieldType = payload
    },
    setIsEdit(payload) {
        store.isEdit = payload
    },
    setShowDelete(payload) {
        store.showDelete = payload
    },
    setDeletedItems(payload) {
        store.deletedItems = payload
    }
};