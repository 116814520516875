<template>
    <div>
        <v-alert
            v-model="alert"
            dismissible
            color="cyan"
            border="left"
            elevation="2"
            colored-border
            icon="mdi-twitter"
            >
            You've got <strong>5</strong> new updates on your timeline!.
        </v-alert>
        
    </div>
</template>

<script>
    export default {
        name: 'SubmissionAlert',

        data() {
            return {
                alert: true
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>