<template>
    <FlexWrap @resize="handleResize">
        <v-card :disabled="isDisabled || blockPermissionsReadOnly" class="mx-auto" tile flat>
            <v-card-text class="px-4 py-2 px-md-10 pt-md-5">

                <v-container pa-0 fluid>
                    <v-row>

                        <v-col cols="12">

                                <v-data-table
                                    :headers="tableHeaders"
                                    :items="collectedData"
                                    :items-per-page="25"
                                    hide-default-footer
                                    class="elevation-0 v-data-table-3"
                                    no-data-text="No Records"
                                    :mobile-breakpoint="0"
                                    >

                                    <template v-slot:item.relationship="{ item }">
                                        {{ item.attributes.relationship }}
                                    </template>

                                    <!-- <template v-slot:item.business-name="{ item }">
                                        {{ item.attributes['is-business'] === 'No' ? '' : item.attributes['business-name'] }}
                                    </template> -->

                                    <template v-slot:item.reference-name="{ item }">
                                        {{ item.attributes['name'] }}
                                    </template>

                                    <template v-slot:item.address="{ item }">
                                        <div class="cell">
                                            <div class="text-truncate">{{ getfullAddress(item) }}</div>
                                        </div>
                                    </template>

                                    <template v-slot:item.phone="{ item }">
                                        {{ item.attributes.phone }}
                                    </template>

                                    <template class="lol" v-slot:item.actions="{ item }">

                                        <div class="d-flex justify-end" :key="item.id">
                                            <div :key="item.id">
                                              <EditReference
                                                :key="item.id"
                                                action="edit"
                                                :app-id="appId"
                                                @update-record="initializeSavedData"
                                                :form-address="filteredReferenceAddress(item)"
                                                :form-reference="filteredReferenceData(item)"
                                                :requiresLandLordReference="requiresLandLordReference" />

                                            </div>
                                            <ConfirmModal
                                                :title="`Delete Reference`"
                                                confirm-text="Delete"
                                                :message="`Remove ${item.attributes.name} as reference?`"
                                                @confirm="removeReference(filteredReferenceAddress(item), item)">
                                                    <template v-slot:activator="{on}">
                                                        <v-btn text depressed fab color="light_gray_2" small @click.stop="on">
                                                            <v-icon size="20">mdi-delete</v-icon>
                                                        </v-btn>
                                                    </template>
                                            </ConfirmModal>

                                        </div>

                                    </template>

                                </v-data-table>
                        </v-col>
                    </v-row>
                    <v-row v-if="hasErrors">
                        <v-col cols="12">
                            <v-alert dense outlined type="error">
                                <div>Landlord reference is required</div>
                            </v-alert>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card-text>
            <v-card-actions class="controls px-4 px-md-10 pt-0 pt-md-0 pb-4 pb-md-8">
                <v-container pa-0 fluid>
                    <v-row>
                        <v-col class="pt-0 d-flex justify-end">

                        <SelectReference
                        :app-id="appId"
                            @create-record="initializeCreatedData"
                            :support-data="{ reference: resources, address: addresses }"
                            :requiresLandLordReference="requiresLandLordReference" />

                        </v-col>
                    </v-row>
                </v-container>
            </v-card-actions>
        </v-card>
    </FlexWrap>
</template>

<script>

    import subscriptions from '~/components/shared/subscriptions'
    import resourceHelpers from '~/components/shared/resource-helpers'
    import { mapActions, mapGetters, mapState } from 'vuex'
    import SelectReference from '~/components/v1/ReferenceEditor1/_AddReference'
    import EditReference from '~/components/v1/ReferenceEditor1/_FormReferenceEditor'
    import ConfirmModal from '~/components/base/ConfirmModal2'
    import FlexWrap from '~/components/base/FlexWrap'

    export default {
        name: 'ReferenceEditor1',

        mixins: [ subscriptions, resourceHelpers ],

        components: {
            SelectReference,
            EditReference,
            FlexWrap,
            ConfirmModal
        },

        computed: {
            ...mapState({
                loanData: state => state.flows.loanData,
            }),
            ...mapGetters('flows', [
                'getCalculation',
            ]),
            fullname() {
                return this.person.attributes ? `${this.person?.attributes['first-name']} ${this.person?.attributes['last-name']}` : ''
            },

            collectedData() {
                let flagged = true

                Object.keys(this.resources).forEach( res => {
                    if (this.resources[res].id !== undefined && !this.resources[res].id?.length )
                        flagged = false
                })

                if ( !flagged ) this.setCurrentRelationship()

                return flagged ? this.resources : []
            },
            tableHeaders(){

                if(!this.sizes) return

                const {sm, xs} = this.sizes

                let newHeaders = [
                    ...this.headers
                ]

                let heads = [
                    {value: 'reference-name', width: '20%'},
                    {value: 'relationship', width: '20%'},
                    {value: 'address', width: '30%'},
                    {value: 'phone', width: '15%'},
                    {value: 'actions', width: '10%'}
                ]

                if(sm){
                    heads = [
                        {value: 'reference-name', width: '20%'},
                        {value: 'relationship', width: '20%'},
                        {value: 'phone', width: '20%'},
                        {value: 'actions', width: '10%'}
                    ]
                }

                if(xs){
                    heads = [
                        {value: 'reference-name', width: '20%'},
                        {value: 'actions', width: '10%'}
                    ]
                }

                return newHeaders.reduce((accumulator, current) => {

                    heads.forEach(head => {

                        if(head.value == current.value){

                            let newCurrent = {...current}

                            newCurrent.width = head.width

                            accumulator.push(newCurrent)
                        }

                    })

                    return accumulator

                }, [])

            },
            hasLandLordReference(){
                let hasLandlord = false

                if(this.resources.length > 0) {
                    this.resources.forEach(reference => {
                        if(['Landlord - RE Agent', 'Landlord - Private'].includes(reference.attributes.relationship)){
                            hasLandlord = true
                        }
                    })
                }

                return hasLandlord
            },
            requiresLandLordReference(){

                const {applicantOccupancy} = this

                if(applicantOccupancy) {

                    let situation = ["Boarder", "Employer Provided", "Living With Parents", "Renter - Agent", "Renter - Private"]

                    return situation.includes(applicantOccupancy.attributes['residential-situation'])
                }

                return false
            },
            isConsumer(){
                if(this.loanData){
                    return  this.loanData.attributes['finance-for-business-purposes'] == 'No'
                }
                return false
            },
        },

        created() {
            // Running these before everything else after component is already created
        },

        watch: {
        },

        methods: {
            ...mapActions('resource-actions', [
                'getReferencesData',
                'getPeopleByResource',
                'getReferencePerson',
                'deleteReference',
                'deleteAddress'
            ]),

            /**
             * Calling actions: getPeopleByResource
             * from store which trigger an axios call,
             * pulling all data from an api response
             */
            async pull() {

                let response = await this.getPeopleByResource(
                    {
                        resource: 'references',
                        include: 'address'
                    }
                )

                if (response.data.data.length) {
                    this.resources = response.data.data
                    this.addresses = response.data.included || [{
                      relationships: {
                        application: {data: []}
                      }
                    }]
                    this.removeAddressReferences()
                }


                // Apply Landlord Record Validation only for Consumer

                let primaryApplicantId = this.getCalculation["primary-applicant-id"]
                let primaryApplicantType = this.getCalculation["primary-applicant-type"]

                if(this.isConsumer && primaryApplicantType == "people") {

                    let applicantData = await this.$api.get(`people/${primaryApplicantId}`, {params:{"include" : 'occupancies'}})

                    if(applicantData.status == 200 && applicantData?.data?.included) {
                        this.applicantOccupancy = applicantData.data.included.find(occupancy => occupancy.attributes['occupancy-situation'] == 'Current')
                    }
                }

                this.resourceObj = this.resources

            },

            async push() {

                let valid = true

                if(this.isConsumer) {

                    if(this.requiresLandLordReference) {

                        if(this.hasLandLordReference) {
                            this.hasErrors = false
                        } else {
                            this.hasErrors = true
                        }
                    }

                }

                valid = !this.hasErrors

                return {valid, status: valid ? 'valid': 'dirty', data:{}}

            },

            async removeReference(addressObj, ref) {

                let index = null

                if ( ref.id ) {
                    let resReference = this.deleteReference(ref.id)
                    let resAddress = null

                    if (!(this.requiresLandLordReference && ref.attributes.relationship.includes('Landlord'))) {
                      resAddress = this.deleteAddress(addressObj.id)
                    }

                    try {
                        await Promise.all([resReference, resAddress]).then( res => {
                            // this.$store.commit('utils/toggleQuickMessage',  {text: 'Selected reference successfully removed!', color: 'success'})
                        })
                        this.resources.forEach( (res, j) => {
                            if ( res.id === ref.id ) index = j
                        })

                        this.resources.splice(index, 1)

                        await this.pull()

                    } catch ( error ) {
                        // this.$store.commit('utils/toggleQuickMessage',  {text: 'Selected reference successfully removed!', color: 'success'})
                    }
                }
            },

            removeAddressReferences() {
                this.addresses.forEach( index => {
                    delete index.relationships.references
                })
            },

            async initializeSavedData(params) {
                this.resources.forEach( (res, key) => {
                    params.forEach( (response_payload, pkey) => {
                        if ( params[pkey].data.data.type === 'references' ) {
                            if ( params[pkey].data.data.id === this.resources[key].id)
                                this.resources[key] = params[pkey].data.data
                        }
                    })
                })

                this.addresses.forEach( (res, key) => {
                    params.forEach( (response_payload, pkey) => {
                        if ( params[pkey].data.data.type === 'addresses' ) {
                            if ( params[pkey].data.data.id === this.addresses[key].id)
                                this.addresses[key] = params[pkey].data.data
                        }
                    })
                })

                this.resources = Object.assign([], this.resources)
                this.addresses = Object.assign([], this.addresses)

                await this.pull()

            },

            async initializeCreatedData(params) {

                this.resources = this.resources.filter( res => {
                    return res.id !== null
                })
                this.addresses = this.addresses.filter( res => {
                    return res.id !== null
                })

                params.forEach( payload => {
                    if ( payload.data.data.type === 'addresses' ) {
                        this.addresses.push(payload.data.data)
                    }

                    if ( payload.data.data.type === 'references' ) {
                        this.resources.push(payload.data.data)
                    }
                })

                await this.pull()

            },

            filteredReferenceAddress(data) {
                let filterData = {}

                this.addresses.forEach( res => {
                    if ( data?.relationships?.address?.data?.id === res.id ) {

                        filterData = {...res}

                        // if(filterData.relationships?.validations){
                        //     delete filterData.relationships.validations
                        // }

                        if(filterData?.relationships) {
                            filterData.relationships = this.filterRelationships(filterData.relationships)
                        }

                    }

                })

                return filterData
            },
            filteredReferenceData(data) {

                if(data && data.hasOwnProperty('relationships')){

                    let filterData = {...data}

                    // if(filterData.relationships?.validations){
                    //     delete filterData.relationships.validations
                    // }

                    if(filterData?.relationships) {
                        filterData.relationships = this.filterRelationships(filterData.relationships)
                    }

                    return filterData

                }

                return data
            },

            setCurrentRelationship() {
                this.resources[0].relationships.application.data.id = this.appId
                this.addresses[0].relationships.application.data.id = this.appId
            },

            getfullAddress(item) {

                let address = this.filteredReferenceAddress(item).attributes

                return address === undefined ? '' : `${address['street-number']} ${address['street-name']} ${address['suburb']} ${address['state']}, ${address['postcode']}`

            },
            handleResize(sizes){

                this.sizes = sizes

            },

        },

        data() {
            return {
                sizes: null,
                resourceObj: {},
                resources: [
                    {
                        'id': null,
                        'attributes': {
                            'business-name': null,
                            'date': null,
                            'email': null,
                            'is-business': null,
                            'name': null,
                            'phone': null,
                            'relationship': null
                        },
                        'relationships': {
                            'address': {
                                'data': { 'id': null, 'type': 'addresses' }
                            },
                            'application': {
                                'data': { 'id': null, 'type': 'applications' }
                            }
                        },
                        'type': 'references'
                    }
                ],
                addresses: [
                    {
                        'id': null,
                        'attributes': {
                            'country': null,
                            'postcode': null,
                            'state': null,
                            'street-name': null,
                            'street-number': null,
                            'street-type': null,
                            'suburb': null
                        },
                        'relationships': {
                            'application': {
                                'data': { 'id': null, 'type': 'applications' }
                            }
                        },
                        'type': 'addresses'
                    }
                ],
                person: '',
                labels: {
                    reference: {
                        'relationship': { text: 'Relationship', hint: '' },
                        'is-business': { text: 'Is Business', hint: '' },
                        'business-name': { text: 'Business Name', hint: '' },
                        'date': { text: 'Date', hint: '' },
                        'phone': { text: 'Phone Number', hint: '' },
                        'email': { text: 'Email', hint: '' }
                    },
                    address: {
                        'postcode': { text: '', hint: '' },
                        'state': { text: '', hint: '' },
                        'street-name': { text: '', hint: '' },
                        'street-number': { text: '', hint: '' },
                        'street-type': { text: '', hint: '' },
                        'suburb': { text: '', hint: '' }
                    }
                },
                headers: [
                    { text: 'Name', sortable: false, value: 'reference-name', width: '25%' },
                    { text: 'Relationship', sortable: false, value: 'relationship', width: '20%' },
                    { text: 'Address', sortable: false, value: 'address', width: '30%' },
                    { text: 'Phone', sortable: false, value: 'phone', width: '15%' },
                    { text: '', sortable: false, value: 'actions', width: '10%' }
                ],
                applicantOccupancy: null,
                hasErrors: false
            }
        }
    }
</script>

<style lang="scss" scoped>
    .inline-icons {
        display: flex;
    }

</style>
