<template>
    <v-card
    class="mx-auto"
    tile
    outlined
    >
        <v-list-item three-line>
            <v-list-item-content>

                <div class="overline mb-4">{{ block.subscriptions || 'None' }}</div>

                <v-list-item-title class="headline mb-1">
                    {{ block.component.name }}
                </v-list-item-title>

                <ValidationObserver ref="observer">
                    <form>

                        <ValidationProvider v-slot="{ errors }" name="Case" rules="required" vid="case_field">
                            <v-text-field
                            v-model="resources.cases.attributes.case_field"
                            :error-messages="errors"
                            :disabled="isDisabled"
                            label="Case"
                            outlined
                            ></v-text-field>
                        </ValidationProvider>

                        <ValidationProvider v-slot="{ errors }" name="Vehicle" rules="required" vid="vehicle_field">
                            <v-text-field
                            v-model="resources.vehicles.attributes.vehicle_field"
                            :error-messages="errors"
                            :disabled="isDisabled"
                            label="Vehicle"
                            outlined
                            ></v-text-field>
                        </ValidationProvider>

                    </form>
                </ValidationObserver>

            </v-list-item-content>
        </v-list-item>

        <v-progress-linear indeterminate stream absolute bottom v-if="isDisabled"></v-progress-linear>

    </v-card>
</template>

<script>

    /**
     * NOTE: Mock data, methods, etc. only.
     * this is just a test flow of a block component,
     * we will use mixins for common functions, data etc.
     */

    import subscriptions from '~/components/shared/subscriptions'

    export default {
        mixins: [subscriptions],
        data(){
            return {
                resources: {
                    cases: {
                        type: "cases",
                        id: null,
                        attributes: {
                            case_field: "",
                        }
                    },
                    vehicles: {
                        type: "vehicles",
                        id: null,
                        attributes: {
                            vehicle_field: "",
                        }
                    }
                }
            }
        }
    }
</script>

<style scoped lang="scss">

</style>
