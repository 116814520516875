import {stringify} from 'qs'

export default async function ({ store, $axios, $auth, redirect, error }, inject) {

    // Set default, to be used by Auth Module
    $axios.defaults.baseURL = store.getters['slug/getApiDomain']

    const api = $axios.create({
        baseURL: store.getters['slug/getApiBase'],
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json"
        }
    })

    let isRefreshingToken = false;
    let failedQueue = [];

    const processQueue = (error, data = null) => {
        failedQueue.forEach(prom => {
            if (error) {
                prom.reject(error);
            } else {
                prom.resolve(data);
            }
        });

        failedQueue = [];
    };

    /**
     * Intercepting requests before it is thrown
     * Perform URL endpoint update before the request is sent
     */
    api.interceptors.request.use(
        (config) => {

        // let loggedIn = store.state.auth.loggedIn

        let { appGuid } = store.state.flows

        // No key if logged in
        if($auth.loggedIn){
            return config
        }

        // Apply key if guest
        if ( appGuid ) {
            if ( config.url.includes('?')) {
                config.url = `${config.url}&key[guid]=${appGuid}`
            }
            else {
                config.url = `${config.url}?key[guid]=${appGuid}`
            }
        }

        return config

    },
    (err) => {
        return Promise.reject( err )
    });

    /**
     * Intercepting responses before it is loaded as payload
     */
    api.interceptors.response.use(
        (response) => {
            return response
        },
        async (err) => {

            const originalRequest = err.config

            const code = parseInt(err.response && err.response.status)
            let message = err?.response?.data?.message ? err?.response?.data?.message.toString() : 'Uh oh! Something went wrong.'

            $axios.post('/log-error', {
                type: `HTTP Error`,
                title: `${code} - ${message}`,
                content: `${err?.response?.config?.baseURL ?? '[No Base Url]'}/${err?.response?.config?.url ?? '[No Url]'}`
            });

            // Unauthorized
            if (code == 401 && !originalRequest._retry) {

                if (isRefreshingToken) {

                    return new Promise((resolve, reject) => {

                        failedQueue.push({ resolve, reject });

                    }).then(data => {

                        originalRequest.headers['Authorization'] = 'Bearer ' + data.access_token;
                        return $axios(originalRequest)

                    })
                    .catch(err => {
                        return Promise.reject(err);
                    });
                }

                const strategy = $auth.strategy.name
                let refreshToken = $auth.getRefreshToken(strategy)
                let accessToken = $auth.getToken(strategy)

                originalRequest._retry = true
                isRefreshingToken = true

                const params = {
                    grant_type: 'refresh_token',
                    refresh_token: refreshToken,
                    scope: '',
                }

                return new Promise(function(resolve, reject) {

                    $auth.loginWith('local', {
                        data: stringify(params)
                    }).then(res => {

                        accessToken = res.data.access_token
                        refreshToken = res.data.refresh_token

                        api.setHeader('Authorization', 'Bearer ' + accessToken)
                        baseDatUrl.setHeader('Authorization', 'Bearer ' + accessToken)
                        $axios.setHeader('Authorization', 'Bearer ' + accessToken)
                        $auth.setUserToken(accessToken)
                        $auth.setRefreshToken(strategy, refreshToken)

                        originalRequest.headers['Authorization'] = 'Bearer ' + accessToken

                        processQueue(null, res.data);

                        // console.log('originalRequest: ', originalRequest)

                        // re-run the previous request
                        resolve($axios(originalRequest));


                    }).catch(err => {

                        // console.log('catch', err)

                        processQueue(err, null);

                        store.commit('utils/toggleQuickMessage',  {text: message, color: 'error'})

                        reject(err);

                        $auth.logout().then(()=> {
                            redirect('/login')
                        })

                    }).then(() => {
                        isRefreshingToken = false;
                    })

                });

            }

            // Forbidden
            if (code == 403) {

                store.commit('utils/toggleQuickMessage',  {text: message, color: 'error'})

                error({
                    statusCode: 403,
                    message: 'Forbidden'
                })
            }

            if (code == 422) {

                let excludedApis = [
                    "run/lender-flow",
                ]

                if(err?.response?.data?.errors && err?.response?.data?.errors?.length) {
                    if( err.response.data.errors?.[0]?.detail == "No licensee configured for Broker" ) {
                        message = "There was an error generating your BID Document because your Credit Licensee information isn't held. Please contact the support team on 1300 769 416 - Option#3 for assistance."
                    }
                }

                if(!excludedApis.includes(err?.config?.url)) {
                    store.commit('utils/toggleQuickMessage',  {text: message, color: 'error'})
                }
            }

            if (code == 400) {

                let excludedApis = ["/valid-credit-license"]

                if(err?.config?.url && !excludedApis.some(path => err?.config?.url.includes(path)) ){

                    message = err?.response?.data?.errors ? err?.response?.data?.errors[0]?.detail : message

                    store.commit('utils/toggleQuickMessage',  {text: message, color: 'error'})
                }

            }

            if (code == 404) {

                let excludedApis = [
                    "/privacy-disclosure",
                    "/valid-credit-license"
                ]

                if(err?.config?.url && !excludedApis.some(path => err?.config?.url.includes(path)) ){
                    store.commit('utils/toggleQuickMessage',  {text: message, color: 'error'})
                }

            }

            if (code == 500) {

                let excludedApis = [
                    "/valid-credit-license"
                ]

                if(err?.config?.url && !excludedApis.some(path => err?.config?.url.includes(path)) ){
                    store.commit('utils/toggleQuickMessage',  {text: message, color: 'error'})
                }

            }

            return err.response
        }
    )

    inject('api', api)

    const baseDatUrl = $axios.create({
        baseURL: store.getters['slug/getBaseUrl'],
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json"
        }
    })

    baseDatUrl.interceptors.response.use(
        (response) => {

            return response

        },
        (err) => {

            const code = parseInt(err.response && err.response.status)
            let message = err?.response?.data?.message ? err?.response?.data?.message.toString() : 'Uh oh! Something went wrong.'

            // Unauthorized
            if (code == 401) {

                store.commit('utils/toggleQuickMessage',  {text: message, color: 'error'})

                $auth.logout().then(()=> {
                    redirect('/login')
                })

            }

            // Forbidden
            if (code == 403) {

                store.commit('utils/toggleQuickMessage',  {text: message, color: 'error'})

                error({
                    statusCode: 403,
                    message: 'Forbidden'
                })
            }

            if (code == 400) {
                store.commit('utils/toggleQuickMessage',  {text: message, color: 'error'})
            }

            if (code == 404) {
                store.commit('utils/toggleQuickMessage',  {text: message, color: 'error'})
            }

            if (code == 500) {
                store.commit('utils/toggleQuickMessage',  {text: message, color: 'error'})
            }
            return err.response
        }
    )

    inject('baseDatUrl', baseDatUrl)

    const downloadApi = $axios.create({
        baseURL: store.getters['slug/getApiBase'],
        responseType: 'blob', // important
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json"
        }
    })

    downloadApi.interceptors.response.use(
        (response) => {
            return response
        },
        (err) => {

            const originalRequest = err.config

            const code = parseInt(err.response && err.response.status)
            let message = err?.response?.data?.message ? err?.response?.data?.message.toString() : 'Uh oh! Something went wrong.'

            // Unauthorized
            if (code == 401 && !originalRequest._retry) {

                if (isRefreshingToken) {

                    return new Promise((resolve, reject) => {

                        failedQueue.push({ resolve, reject });

                    }).then(data => {

                        originalRequest.headers['Authorization'] = 'Bearer ' + data.access_token;
                        return $axios(originalRequest)

                    })
                    .catch(err => {
                        return Promise.reject(err);
                    });
                }

                const strategy = $auth.strategy.name
                let refreshToken = $auth.getRefreshToken(strategy)
                let accessToken = $auth.getToken(strategy)

                originalRequest._retry = true
                isRefreshingToken = true

                const params = {
                    grant_type: 'refresh_token',
                    refresh_token: refreshToken,
                    scope: '',
                }

                return new Promise(function(resolve, reject) {

                    $auth.loginWith('local', {
                        data: stringify(params)
                    }).then(res => {

                        accessToken = res.data.access_token
                        refreshToken = res.data.refresh_token

                        api.setHeader('Authorization', 'Bearer ' + accessToken)
                        baseDatUrl.setHeader('Authorization', 'Bearer ' + accessToken)
                        $axios.setHeader('Authorization', 'Bearer ' + accessToken)
                        $auth.setUserToken(accessToken)
                        $auth.setRefreshToken(strategy, refreshToken)

                        originalRequest.headers['Authorization'] = 'Bearer ' + accessToken

                        processQueue(null, res.data);

                        // console.log('originalRequest: ', originalRequest)

                        // re-run the previous request
                        resolve($axios(originalRequest));


                    }).catch(err => {

                        // console.log('catch', err)

                        processQueue(err, null);

                        store.commit('utils/toggleQuickMessage',  {text: message, color: 'error'})

                        reject(err);

                        $auth.logout().then(()=> {
                            redirect('/login')
                        })

                    }).then(() => {
                        isRefreshingToken = false;
                    })

                });

            }

            return err.response
        }
    )

    inject('downloadApi', downloadApi)
}
