import fieldTypes from '~/components/shared/field-types'
export default {  
    data(){
        return {
            validation_rules: {}  
        }
    },
    methods: {
        /**
         * This will pick fields from a target
         * based on a refference object.
         * 
         * Usage Example: selecting only the required fields of a component from 
         * a json response.
         * 
         */
        resourceFieldPicker(ref={}, target={}){
            
            if(!ref.hasOwnProperty('attributes') || !target.hasOwnProperty('attributes')) {
                console.log('Error: resourceFieldPicker arguments must be a type of resource')
                return;
            }

            let r = Object.keys(ref.attributes)
            let t = Object.keys(target.attributes)

            let attributes = {}

            for (let i = 0; i < r.length; i++) {
                
                let key = r[i]

                if(t.includes(key)){
                    attributes[key] = target.attributes[key]
                } 

            }

            return {
                ...target,
                attributes: attributes
            }

        },
        nullifyInvalids(fields={}, observer={}) {

            let sortedFields = {}

            Object.keys(fields).forEach( field => {

                if(observer[field]["valid"]) {
                    sortedFields[field] = fields[field]
                } 
                /**
                 * Set field to null to keep the field in json-server DB. 
                 * OR do nothin to exclude invalid fields in the request payload.
                 */
                else {
                    // sortedFields[field] = null 
                }

            })

            return sortedFields

        },
        excludeInvalids(fields={}, observer={}) {
            
            let sortedFields = {}

            Object.keys(fields).forEach( field => {
                
                if(observer[field] && observer[field]["valid"]) {
                    sortedFields[field] = fields[field]
                } 
                
            })

            return sortedFields
        },

        // FORM HELPER METHODS 

        /**
         * Used in v-if to define what field component to render 
         * 
         * @param {String} type - type of field to check
         * @param {String} key - key in the validation_rules
         * 
         */
        isFieldType(type, key){
                
            const {validation_rules} = this;

            try {

                // if(!validation_rules.attributes[key]) throw `Validation rule ${key} is not defined!`;
                
                let index;

                switch (type) {
                    case 'picklist':
                        index = '|in:'
                        break;
                    case 'date':
                        index = '|date_format:'
                        break;
                    case 'money':
                        index = '|between:'
                        break;
                    case 'textarea':
                        index = '|max:32768'
                        break;
                }
                // e.g. check for 'in' rule
                let pipe = validation_rules.attributes[key].split(index)

                return pipe.length > 1 

            }
            catch(err) {
                console.log(err)
            }
   
        },
        getRules(name){

            const {validation_rules} = this;

            let moreRules = ''

            if(arguments.length > 1) {

                for (let i = 0; i < arguments.length; i++) {
                    if(i == 0) continue;
                    moreRules = '|' + arguments[i]
                }

            }

            if(!validation_rules.attributes[name]) return '';

            return this.$toFlexRules(validation_rules.attributes[name]) + moreRules
        },
        getFieldType(key) {
            let type = 'text'

            Object.keys(fieldTypes).forEach(field => {
                if(fieldTypes[field].includes(key)) {
                    type = field
                }
            })

            return type
        },
        getOptions(name){
            
            const {validation_rules} = this;

            // Check if props.options is set then use it.
            if( this.options && this.options[name] !== undefined){
                return this.options[name];
            }
            // Otherwise use options in the rules
            return this.$toFlexOptions(validation_rules.attributes[name])
        },
        fieldLayoutMaker(layout, collection){

            let keys = Object.keys(collection)
            let newCollection = {} 

            if(layout instanceof Array) {

                layout.forEach(key => {
                    if(keys.includes(key)){
                        newCollection[key] = collection[key]
                    } 
                })

            }
            return newCollection
        },  
        // Added spacer and returns array
        fieldLayoutMaker2(layout, collection){
            
            let keys = Object.keys(collection)
            let newCollection = []
            
            if(layout instanceof Array) {
                layout.forEach(key => {
                    if(keys.includes(key) || key == '__spacer'){
                        newCollection.push(key) 
                    } 
                })
                
            }
            
            return newCollection
        }  
    }
}