<template>
        <v-card :disabled="isDisabled" tile flat class="mx-auto flex__DynamicField1">
            <v-card-text :class="vb.mdAndDown ? 'px-4' : ''" class="pa-0">
                <v-container pa-0 fluid>
                    <v-row>
                        <v-col cols="12" class="mt-0 pt-0">
                            <ValidationObserver ref="observer" tag="form">
                                <ValidationProvider 
                                    v-slot="{ errors }" 
                                    :name="newBlockSettings['field-label'] || newBlockSettings['attribute']" 
                                    :rules="newBlockSettings['validation-rules']"
                                    :vid="newBlockSettings['attribute']">

                                    <template v-if="newBlockSettings['ui'] == 'text'">
                                        
                                        <!-- Textfield incrementer designs -->
                                        <Incrementer1
                                            v-if="newBlockSettings['ui-view'] == 'incrementer'"
                                            :key="`field-${getActiveStep}`"
                                            :errors="errors"
                                            :options="increment_options" />

                                        <!-- Textfield for currency since it is different with other inputs -->
                                        <TextField1
                                            v-else-if="newBlockSettings['ui-view'] == 'currency'"
                                            :key="`field-${getActiveStep}`"
                                            v-model="formInput"
                                            :errors="errors"
                                            :field-type="newBlockSettings['ui-view']"
                                            :prefix="newBlockSettings['prefix']" />

                                        <!-- textfield for other input field eg. number and text -->
                                        <TextField2
                                            v-else
                                            :key="`field-${getActiveStep}`"
                                            v-model="formInput"
                                            :errors="errors"
                                            :field-type="newBlockSettings['ui-view'] || 'text'"
                                            :prefix="newBlockSettings['prefix']"
                                            :suffix="newBlockSettings['suffix']" />

                                    </template>

                                    <template v-if="newBlockSettings['ui'] == 'radio'">

                                        <!-- single select list view design -->
                                        <Radio1
                                            v-if="newBlockSettings['ui-view'] == 'list'"
                                            :key="`field-${getActiveStep}`"
                                            v-model="formInput"
                                            @input="handleClick"
                                            :error-messages="errors"
                                            :options="newBlockSettings.options" />

                                        <!-- single select grid view design -->
                                        <Radio2
                                            v-if="newBlockSettings['ui-view'] == 'grid'"
                                            :key="`field-${getActiveStep}`"
                                            v-model="formInput"
                                            @input="handleClick"
                                            :error-messages="errors"
                                            :options="newBlockSettings.options" />

                                        <!-- single select toggle view design -->
                                        <Radio3
                                            v-if="newBlockSettings['ui-view'] == 'toggle'"
                                            :key="`field-${getActiveStep}`"
                                            v-model="formInput"
                                            :errors="errors"
                                            :options="newBlockSettings.options" />

                                        <!-- single select with check view design -->
                                        <Radio4
                                            v-if="newBlockSettings['ui-view'] == 'grid-check'"
                                            :key="`field-${getActiveStep}`"
                                            v-model="formInput"
                                            :errors="errors"
                                            :options="newBlockSettings.options" />

                                        <!-- single select with check view design -->
                                        <Radio5
                                            v-if="newBlockSettings['ui-view'] == 'grid-icon'"
                                            :key="`field-${getActiveStep}`"
                                            v-model="formInput"
                                            :errors="errors"
                                            :options="newBlockSettings.options" />

                                        <!-- Remove Next btn in footer -->
                                        <Portal to="step-incrementer-next">
                                            <span class="d-none"></span>
                                        </Portal>

                                    </template>

                                    <template v-if="newBlockSettings['ui'] == 'checkbox'">

                                        <!-- multiple select grid view design -->
                                        <CheckBox1 
                                            v-if="newBlockSettings['ui-view'] == 'grid'"
                                            :key="`field-${getActiveStep}`"
                                            v-model="formInput"
                                            :errors="errors"
                                            :options="newBlockSettings.options" />

                                        <!-- multiple select list view design -->
                                        <CheckBox2
                                            v-if="newBlockSettings['ui-view'] == 'list'"
                                            :key="`field-${getActiveStep}`"
                                            v-model="formInput"
                                            :errors="errors"
                                            :options="newBlockSettings.options" />

                                    </template>

                                    <template v-if="newBlockSettings['ui'] == 'select'">

                                        <!-- dropdown select view design -->
                                        <Select1 
                                            v-if="newBlockSettings['ui-view'] == 'dropdown'"
                                            :key="`field-${getActiveStep}`"
                                            v-model="formInput"
                                            :errors="errors"
                                            :options="newBlockSettings.options" />
                                        
                                        <!-- dropdown select view design rounded corners-->
                                        <Select2
                                            v-if="newBlockSettings['ui-view'] == 'dropdown-rounded'"
                                            :key="`field-${getActiveStep}`"
                                            v-model="formInput"
                                            :errors="errors"
                                            :options="newBlockSettings.options" />

                                    </template>

                                </ValidationProvider>
                            </ValidationObserver>
                        </v-col>
                    </v-row>
                </v-container>

            </v-card-text>
        </v-card>
    
</template>

<script>
    
    import { mapActions, mapGetters, mapState } from 'vuex'
    import subscriptions from '~/components/shared/subscriptions'
    import ResourceHelpers from '~/components/shared/resource-helpers'
    import {store, actions} from '~/components/v1/DynamicField1/__store'
    import shared from '~/components/v1/DynamicField1/__shared'
    import includedComponents from '~/components/v1/DynamicField1/includes'
    import _Select1 from './includes/_Select1.vue'


    export default {
        mixins: [subscriptions, shared, ResourceHelpers],
        components: {
            ...includedComponents
        },
        data(){
            return {
                formInput: null,
                resource: {},
                increment_options: [
                    {text: '1 year', value: 12},
                    {text: '2 years', value: 24},
                    {text: '3 years', value: 36},
                    {text: '4 years', value: 48},
                    {text: '5 years', value: 60},
                    {text: '6 years', value: 72},
                    {text: '7 years', value: 84},
                ]
            }
        },
        mounted() {
            // console.log(JSON.parse(this.blockSettings['dynamicSettings']))
        },
        computed: {
            ...mapGetters('flows', ['getActiveStep']),
            ...mapGetters('kinds', ['kinds', 'kindsUnformat']),
            ...mapState('flows', [
                'calculation'
            ]),
            newBlockSettings() {
                if(this.blockSettings['dynamicSettings'])
                    return this.blockSettings['dynamicSettings']
            },
            getLoanId(){
                return this.appData?.relationships?.loan?.data?.id
            },
            getPeopleId(){
                return this.appData?.relationships?.people?.data?.[0].id
            },
            getOccupancyId(){
                return this.appData?.relationships?.occupancies?.data?.[0]?.id
            },
            getPurchaseId(){
                return this.appData?.relationships?.purchases?.data?.[0]?.id
            },
            selectedKind(){
                return this.kindsUnformat.filter(kind => kind.attributes.name == this.formInput || kind.id == this.formInput)
            },
            vb() {
                return this.$vuetify.breakpoint
            }
        },
        methods: {
            ...mapActions('resource-actions', [
                'getLoan',
                'getPerson',
                'getPurchase',
                'getIncome',
                'getOccupancy',
                'createOccupancy',
                'createIncome',
                'updatePerson',
                'updateIncome',
                'updateOccupancy',
                'updateLoan',
                'updatePurchases'
            ]),
            ...mapActions('flows', [
                'selectNextStep'
            ]),
            /**
             *  PULL method
             * 
             *  RECOMMENDED: If multiple requests are needed, do so asynchronously to reduce total time to response.
             */
            async pull(){

                if (this.newBlockSettings['record']) {

                    let promise = null

                    // condtion for resources api

                    if (this.newBlockSettings.record['keyword'] == 'loan') {

                        if (this.getLoanId)
                            promise = this.getLoan(this.getLoanId)

                    }

                    if (this.newBlockSettings.record['keyword'] == 'people') {

                        if (this.getPeopleId)
                            promise = this.getPerson(this.getPeopleId)

                    }

                    if (this.newBlockSettings.record['keyword'] == 'primary-applicant') {

                        if (this.calculation['primary-applicant-id'] && this.calculation['primary-applicant-type'] == 'people')
                            promise = this.getPerson(this.calculation['primary-applicant-id'])

                    }

                    if (this.newBlockSettings.record['keyword'] == 'occupancies') {
                        
                        if (this.getOccupancyId)
                            promise = this.getOccupancy(this.getOccupancyId)

                    }

                    if (this.newBlockSettings.record['keyword'] == 'primary-applicant-current-occupancy') {

                        let people = {}

                        if (this.calculation['primary-applicant-id'] && this.calculation['primary-applicant-type'] == 'people')
                            people = await this.getPerson(this.calculation['primary-applicant-id'])

                        if(people && people.data.data.relationships.occupancies.data.length > 0) {

                            promise = this.getOccupancy(people.data.data.relationships.occupancies.data[0].id)

                        }

                    }

                    if (this.newBlockSettings.record['keyword'] == 'primary-applicant-current-income') {

                        let people = {}

                        if (this.calculation['primary-applicant-id'] && this.calculation['primary-applicant-type'] == 'people')
                            people = await this.getPerson(this.calculation['primary-applicant-id'])

                        if(people && people.data.data.relationships.incomes.data.length > 0) {

                            promise = this.getIncome(people.data.data.relationships.incomes.data[0].id)

                        }

                    }

                    if (this.newBlockSettings.record['keyword'] == 'purchase') {
                        
                        if (this.getPurchaseId)
                            promise = this.getPurchase(this.getPurchaseId)

                    }

                    try {

                        if (promise) {

                            const res = await Promise.all([promise])

                            // collect all resource
                            this.resource = this.collectResource(res[0].data.data)

                            // filter relationships
                            if(this.resource?.relationships)
                                this.resource.relationships = this.filterRelationships(this.resource?.relationships)

                            // console.log(this.filterRelationships(this.resource?.relationships))
                            // if(this.resource?.relationships?.validations) delete this.resource.relationships.validations

                            // get the required field input
                            // if attribute is visible in the block settings else execute relational logic

                            if (this.resource.hasOwnProperty('attributes') && this.newBlockSettings['attribute']){

                                this.resource.attributes = this.getRequiredAttribute()

                                if( this.resource.attributes.hasOwnProperty(this.newBlockSettings['attribute']) ) {

                                    this.formInput = this.resource.attributes[this.newBlockSettings['attribute']]

                                }                             
                                
                            } else {
                                // for relational logic here
                                if (this.resource.relationships[this.newBlockSettings.relationship]) {

                                    if (this.newBlockSettings.relationship == 'kind') {

                                        // filter relationship and delete attributes
                                        delete this.resource.attributes
                                        this.resource.relationships = {'kind': this.resource.relationships.kind}

                                        const kind = this.kindsUnformat.filter(kind => kind.id == this.resource.relationships.kind.data.id)

                                        let kindID = false
                                        // check if the values are kind ids
                                        this.newBlockSettings.options.forEach(option => {
                                            if (option.value == kind[0].id) {
                                                kindID = true
                                            }
                                        })

                                        if (kind[0] && !kindID) {
                                            this.formInput = kind[0].attributes.name
                                        } else {
                                            this.formInput = kind[0].id
                                        }

                                    }

                                }

                            }


                        }

                    } catch (error) {

                        console.log('Ooops!..', error)

                    }

                }

            },
            async push(){

                const {valid, status} = await this.validateFormData()

                if(status == 'pristine' && !valid) {
                    return { valid: false, status }
                }

                let promise = null


                // condtion for resources api
                if (this.newBlockSettings.record && this.resource && Object.keys(this.resource).length != 0) {

                    if (this.newBlockSettings['attribute'])
                        this.resource.attributes[this.newBlockSettings['attribute']] = this.formInput

                    if (this.newBlockSettings.record['keyword'] == 'loan') {
                        promise = this.updateLoan(this.resource)
                    }

                    if (this.newBlockSettings.record['keyword'] == 'people') {
                        promise = this.updatePerson(this.resource)
                    }

                    if (this.newBlockSettings.record['keyword'] == 'primary-applicant') {
                        promise = this.updatePerson(this.resource)
                    }

                    if (this.newBlockSettings.record['keyword'] == 'primary-applicant-current-occupancy') {
                        promise = this.updateOccupancy(this.resource)
                    }

                    if (this.newBlockSettings.record['keyword'] == 'primary-applicant-current-income') {
                        
                        if (this.newBlockSettings['attribute']) {
                            promise = this.updateIncome(this.resource)
                        } else {
                            if (this.formInput) {
                                this.resource.relationships.kind.data = {"type": "kinds","id": this.selectedKind[0].id}
                                promise = this.updateIncome(this.resource)
                            }
                        }

                    }

                    if (this.newBlockSettings.record['keyword'] == 'occupancies') {
                        promise = this.updateOccupancy(this.resource)
                    }

                    if (this.newBlockSettings.record['keyword'] == 'purchase') {
                        promise = this.updatePurchases(this.resource)
                    }

                } else {
                    
                    // if no resource found make post request for occupancies
                    if (this.newBlockSettings.record['keyword'] == 'occupancies') {

                        let resource = {
                            type: 'occupancies',
                            attributes: {},
                            relationships: {
                                "application": {
                                    "data": {
                                        "type": "applications",
                                        "id": this.appId
                                    }
                                }
                            }
                        }

                        resource.attributes[this.newBlockSettings['attribute']] = this.formInput

                        promise = this.createOccupancy(resource)

                    }

                    if (this.newBlockSettings.record['keyword'] == 'primary-applicant-current-occupancy') {

                        let resource = {
                            type: 'occupancies',
                            attributes: {
                                "occupancy-situation": "Current"
                            },
                            relationships: {
                                "application": {
                                    "data": {
                                        "type": "applications",
                                        "id": this.appId
                                    }
                                },
                                "person": {
                                    "data": {
                                        "type": "people",
                                        "id": this.calculation['primary-applicant-id'].toString()
                                    }
                                }
                            }
                        }

                        resource.attributes[this.newBlockSettings['attribute']] = this.formInput

                        promise = this.createOccupancy(resource)

                    }

                    if (this.newBlockSettings.record['keyword'] == 'primary-applicant-current-income') {

                        let resource = {
                            type: 'incomes',
                            attributes: {
                                "income-situation": "Current",
                                "income-verification-method": "Full Documentation"
                            },
                            relationships: {
                                "application": {
                                    "data": {
                                        "type": "applications",
                                        "id": this.appId
                                    }
                                },
                                "people": {
                                    "data": [
                                        {
                                            "type": "people",
                                            "id": this.calculation['primary-applicant-id'].toString()
                                        }
                                    ]
                                },
                                "kind":{
                                    "data": {
                                        "type": "kinds",
                                        "id": this.selectedKind[0]?.id ? this.selectedKind[0].id : '12-28'
                                    }
                                }
                            }
                        }

                        if (this.newBlockSettings['attribute'])
                            resource.attributes[this.newBlockSettings['attribute']] = this.formInput

                        promise = this.createIncome(resource)

                    }

                }

                const data = await this.appDataHelper([promise])

                return { valid, status, data }

            },
            reset(){

                this.resource = {}
                this.formInput = null

            },
            handleClick() {
                this.selectNextStep()
            },
            getRequiredAttribute() {
                let attribute = {}

                attribute[this.newBlockSettings['attribute']] = this.resource.attributes[this.newBlockSettings['attribute']]

                return attribute
            }

        },
        watch: {
            'blockSettings': {
                handler(val) {
                    // this.pull()
                },
                deep: true
            },
            getActiveStep(oldVal, newVal){

                if(oldVal != newVal){
                    this.reset()
                    this.pull()
                }
            
            }
        }
    }
</script>

<style scoped lang="scss">

</style>
