<template>
    <v-card flat class="mx-auto" :disabled="isDisabled">
        <v-card-text class="pa-0 px-10">
            <ValidationObserver ref="observer" tag="form">

                <div class="flex-form" >

                    <template v-for="(field, key) in loans.attributes">
                        <div class="flex-field" :key="key">
                            <ValidationProvider 
                                v-slot="{ errors }" 
                                :name="labels[key]"
                                :rules="getRules(key)" 
                                :vid="key">

                                <v-select
                                    v-if="isFieldType('picklist', key)"
                                    v-model="loans.attributes[key]"
                                    :items="getOptions(key)"
                                    color="secondary"
                                    :error-messages="errors"
                                    :label="labels[key]">
                                </v-select>

                                <v-text-field 
                                    v-else
                                    :label="labels[key]"
                                    color="secondary"
                                    :error-messages="errors"
                                    v-model="loans.attributes[key]">
                                </v-text-field>

                            </ValidationProvider>
                        </div>
                    </template>
                    
                </div>
                    
            </ValidationObserver>
        </v-card-text>
    </v-card>
</template>
<script>
    import { mapActions, mapState, mapMutations } from 'vuex'
    import helpers from '~/plugins/helpers'
    import subscriptions from '~/components/shared/subscriptions'
    import { isEmpty } from 'lodash'

    export default {
        mixins: [ helpers, subscriptions ],
        data() {
            return {
                loans: {
                    attributes: {
                        "bank-account-name": "",
                        "bank-bsb-number": "",
                        "bank-account-number": "",
                    }
                },
                labels: {
                    "bank-account-name": "Account Name",
                    "bank-bsb-number": "BSB",
                    "bank-account-number": "Account Number",
                },
                validation_rules: {
                    attributes: {
                        "bank-account-name": "is_bank_account_name",
                        "bank-bsb-number": "integer",
                        "bank-account-number": "integer",
                    }
                }
            }
        },
        computed: {
            ...mapState({
                appId: state => state.flows.appId,
                appData: state => state.flows.appData
            }),
            getLoanId(){
                return this.appData?.relationships?.loan?.data?.id
            }
        },
        methods: {
            ...mapActions('resource-actions', [
                'updateLoan',
                'getLoan',
            ]),
            async pull() {

                try {

                    let res = await this.getLoan(this.getLoanId)

                    if(res.status === 200) {
                        this.loans = this.filterFields(res.data.data)
                    }

                } catch (err) {
                    console.log(err)
                }

            },
            async push() {

                try {

                    if(this.loans) {

                        await this.updateLoan({
                            "id": this.getLoanId,
                            "type": "loans",
                            "attributes": {
                                ...this.loans.attributes
                            }
                        })

                    }

                    const {valid, status} = await this.validateFormData()
                    return {valid, status, data: {}}

                } catch (err) {
                    console.log(err)
                }

            },
            filterFields(data){

                let attributes = {}

                Object.keys(this.loans.attributes).forEach(field => {

                    if(data.attributes.hasOwnProperty(field)) {
                        attributes[field] = data.attributes[field]
                    }

                })

                return {
                    type: data.type,
                    id: data.id,
                    attributes
                }

            },
        }
    }
</script>
<style lang="scss" scoped>
</style>