<template>
    <v-data-table
        :items="collection"
        :headers="headers[recordType]"
        disable-pagination
        hide-default-footer>

        <template v-slot:[`item.roles`]="{ item }">
            <div class="inner" v-html="getEntityName(item)">
            </div>
        </template>

        <!-- INCOMES -->        
        <template v-slot:[`item.income-type`]="{ item }">
            <div class="inner">
                {{ getKindName(item.relationships.kind.data.id) }}
            </div>
        </template>
        <template v-slot:[`item.income-situation`]="{ item }">
            <div class="inner">
                {{ item.attributes['income-situation'] }}
            </div>
        </template>
        <template v-slot:[`item.employer-name`]="{ item }">
            <div class="inner">
                {{ item.attributes['employer-name'] || '--' }}
            </div>
        </template>
        <template v-slot:[`item.start-date`]="{ item }">
            <div class="inner">
                {{ item.attributes['start-date'] || '--' }}
            </div>
        </template>
        <template v-slot:[`item.end-date`]="{ item }">
            <div class="inner">
                {{ item.attributes['end-date'] || '--' }}
            </div>
        </template>
        <template v-slot:[`item.net-monthly-income`]="{ item }">
            <div class="inner">
                {{ item.attributes['net-monthly-amount'] | currency }}
            </div>
        </template>
        <template v-slot:[`item.share-value`]="{ item }">
            <div class="inner">
                {{ shareValue(item) | currency }}
            </div>
        </template>
        <template v-slot:[`item.linked-to-asset`]="{ item }">
            <div class="inner">
                {{ item.relationships.assets.data.length ? 'Yes' : 'No' }}
            </div>
        </template>

        <!-- ASSETS -->
        <template v-slot:[`item.asset-type`]="{ item }">
            <div class="inner">
                {{ getParentKindName(item.relationships.kind.data.id) }}
            </div>
        </template>
        <template v-slot:[`item.asset-sub-type`]="{ item }">
            <div class="inner">
                {{ getKindName(item.relationships.kind.data.id) }}
            </div>
        </template>
        <template v-slot:[`item.asset-description`]="{ item }">
            <div class="inner">
                {{ item.attributes['asset-description'] || '--' }}
            </div>
        </template>
        <template v-slot:[`item.asset-value`]="{ item }">
            <div class="inner">
                {{ item.attributes['asset-value'] | currency }}
            </div>
        </template>
        <template v-slot:[`item.linked-to-income`]="{ item }">
            <div class="inner">
                {{ item.relationships.incomes.data.length ? 'Yes' : 'No' }}
            </div>
        </template>
        <template v-slot:[`item.linked-to-liability`]="{ item }">
            <div class="inner">
                {{ item.relationships.liabilities.data.length ? 'Yes' : 'No' }}
            </div>
        </template>
        <template v-slot:[`item.linked-to-expense`]="{ item }">
            <div class="inner">
                {{ item.relationships.expenses.data.length ? 'Yes' : 'No' }}
            </div>
        </template>

        <!-- LIABILITIES -->
        <template v-slot:[`item.liability-type`]="{ item }">
            <div class="inner">
                {{ getParentKindName(item.relationships.kind.data.id) }}
            </div>
        </template>
        <template v-slot:[`item.liability-sub-type`]="{ item }">
            <div class="inner">
                {{ getKindName(item.relationships.kind.data.id) }}
            </div>
        </template>
        <template v-slot:[`item.financial-institution`]="{ item }">
            <div class="inner">
                {{ item.attributes['financial-institution'] || '--' }}
            </div>
        </template>
        <template v-slot:[`item.credit-limit`]="{ item }">
            <div class="inner">
                {{ item.attributes['credit-limit'] | currency }}
            </div>
        </template>
        <template v-slot:[`item.amount-financed`]="{ item }">
            <div class="inner">
                {{ item.attributes['amount-financed'] | currency }}
            </div>
        </template>
        <template v-slot:[`item.amount-owing`]="{ item }">
            <div class="inner">
                {{ item.attributes['amount-owing'] | currency }}
            </div>
        </template>

        <!-- EXPENSES -->
        <template v-slot:[`item.expense-type`]="{ item }">
            <div class="inner">
                {{ getParentKindName(item.relationships.kind.data.id) }}
            </div>
        </template>
        <template v-slot:[`item.expense-sub-type`]="{ item }">
            <div class="inner">
                {{ getKindName(item.relationships.kind.data.id) }}
            </div>
        </template>
        <template v-slot:[`item.monthly-payment-amount`]="{ item }">
            <div class="inner">
                {{ item.attributes['payment-amount'] | currency }}
            </div>
        </template>
        <template v-slot:[`item.to-be-paid-out`]="{ item }">
            <div class="inner">
                {{ item.attributes['to-be-paid-out'] || '--' }}
            </div>
        </template>

        
    </v-data-table>
</template>

<script>

    import { mapGetters } from 'vuex'
    import shared from '~/components/v1/FinancialObjectsEditor1/__shared'
    import {store, actions} from '~/components/v1/FinancialObjectsEditor1/__store'

    export default {
        mixins: [
            shared,
        ],
        props: {
            recordType: {
                type: String,
                default: 'incomes'
            }
        },
        data() {
            return {
                headers: {
                    incomes: [
                        { text: 'Roles', value: 'roles', sortable: false, align: 'center' },
                        { text: 'Income Type', value: 'income-type', sortable: false, align: 'center' },
                        { text: 'Income Situation', value: 'income-situation', sortable: false, align: 'center' },
                        { text: 'Employer Name', value: 'employer-name', sortable: false, align: 'center' },
                        { text: 'Start Date', value: 'start-date', sortable: false, align: 'center' },
                        { text: 'End Date', value: 'end-date', sortable: false, align: 'center' },
                        { text: 'Net Monthly Income', value: 'net-monthly-income', sortable: false, align: 'center' },
                        { text: 'Share Value', value: 'share-value', sortable: false, align: 'center' },
                        { text: 'Linked to Asset?', value: 'linked-to-asset', sortable: false, align: 'center' },
                    ],
                    assets: [
                        { text: 'Roles', value: 'roles', sortable: false, align: 'center' },
                        { text: 'Asset Type', value: 'asset-type', sortable: false, align: 'center' },
                        { text: 'Asset Subtype', value: 'asset-sub-type', sortable: false, align: 'center' },
                        { text: 'Asset Description', value: 'asset-description', sortable: false, align: 'center' },
                        { text: 'Asset Value', value: 'asset-value', sortable: false, align: 'center' },
                        { text: 'Linked to Income?', value: 'linked-to-income', sortable: false, align: 'center' },
                        { text: 'Linked to Liability?', value: 'linked-to-liability', sortable: false, align: 'center' },
                        { text: 'Linked to Expense?', value: 'linked-to-expense', sortable: false, align: 'center' },
                    ],
                    liabilities: [
                        { text: 'Roles', value: 'roles', sortable: false, align: 'center' },
                        { text: 'Liability Type', value: 'liability-type', sortable: false, align: 'center' },
                        { text: 'Liability Subtype', value: 'liability-sub-type', sortable: false, align: 'center' },
                        { text: 'Financial Institution', value: 'financial-institution', sortable: false, align: 'center' },
                        { text: 'Credit Limit', value: 'credit-limit', sortable: false, align: 'center' },
                        { text: 'Amount Financed', value: 'amount-financed', sortable: false, align: 'center' },
                        { text: 'Amount Owing', value: 'amount-owing', sortable: false, align: 'center' },
                        { text: 'Linked to Asset', value: 'linked-to-asset', sortable: false, align: 'center' },
                        { text: 'Linked to Expense', value: 'linked-to-expense', sortable: false, align: 'center' }
                    ],
                    expenses: [
                        { text: 'Roles', value: 'roles', sortable: false, align: 'center' },
                        { text: 'Expense Type', value: 'expense-type', sortable: false, align: 'center' },
                        { text: 'Expense Subtype', value: 'expense-sub-type', sortable: false, align: 'center' },
                        { text: 'Monthly Payment Amount', value: 'monthly-payment-amount', sortable: false, align: 'center' },
                        { text: 'To Be Paid Out?', value: 'to-be-paid-out', sortable: false, align: 'center' },
                        { text: 'Linked to Liability', value: 'linked-to-liability', sortable: false, align: 'center' }
                    ]
                },
            }
        },
        computed: {
            ...mapGetters('kinds', ['kinds', 'kindsUnformat']),
            collection(){

                let entity = this.entities[this.selectedEntity]
 
                let collection = this[this.recordType]

                if(entity){

                    return collection.filter(item1 => {

                        let ids = []
                        
                        if( item1.relationships[entity.type].data.length ){
                            item1.relationships[entity.type].data.forEach( item2 => {
                                ids.push(item2.id)
                            });
                        }

                        if(ids.includes(entity.id)){
                            return true
                        }

                        return false

                    })

                }

                return this[this.recordType]


            },
   
        },

        methods: {
            shareValue(data) {
                
                let items = [
                    ...data.relationships.businesses.data,
                    ...data.relationships.people.data,
                ]

                let value = data.attributes['net-monthly-amount']

                return parseFloat(value) / items.length
            },
            getEntityName(data){
                
                let entity = this.entities[this.selectedEntity]

                if(entity){
                    return '<div>'+ this.getFriendlyName(entity) +'</div>'
                }

                let markup = ''

                let items = [
                    ...data.relationships.businesses.data,
                    ...data.relationships.people.data,
                ]

                this.entities.forEach(item1 => {

                    items.forEach( item2 => {
                        
                        if(item1.type == item2.type && item1.id == item2.id ) {
                            markup += '<div>'+ this.getFriendlyName(item1) +'</div>'
                        }

                    })

                })

                return markup
            },
            getParentKindName(id){

                let parent = null
                let kind = this.kindsUnformat.find(k => {
                    return id == k.id
                })
                
                if(kind){                    
                    parent = this.kindsUnformat.find(k => {
                        return kind.relationships.parent.data.id == k.id
                    })                    
                }

                if (parent) {
                    return parent.attributes.name
                }

                return ''
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>