var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{ref:"observer",attrs:{"tag":"form"}},[_c('v-row',[_c('v-col',{staticClass:"px-3 py-0",attrs:{"cols":"12","md":"6"}},[_c('ValidationProvider',{attrs:{"name":"Name","rules":"required","vid":"Name"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"color":"secondary","error-messages":errors,"label":_vm.fields['name'].label},model:{value:(_vm.form.reference.name),callback:function ($$v) {_vm.$set(_vm.form.reference, "name", $$v)},expression:"form.reference.name"}})]}}])})],1),_vm._v(" "),(_vm.selectedReference === 'Accountant' ? false : true)?_c('v-col',{staticClass:"px-3 py-0",attrs:{"cols":"12","md":"6"}},[_c('ValidationProvider',{attrs:{"name":"Relationship","rules":"required","vid":"Relationship"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"items":_vm.relationship[_vm.selectedReference],"error-messages":errors,"color":"secondary","label":_vm.fields['relationship'].label},model:{value:(_vm.form.reference.relationship),callback:function ($$v) {_vm.$set(_vm.form.reference, "relationship", $$v)},expression:"form.reference.relationship"}})]}}],null,false,25543248)})],1):_vm._e(),_vm._v(" "),(_vm.selectedReference === 'Accountant' || _vm.selectedReference === 'Landlord')?_c('v-col',{staticClass:"px-3 py-0",attrs:{"cols":"12","md":"6"}},[_c('ValidationProvider',{attrs:{"name":"Email","rules":"email","vid":"Email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"color":"secondary","label":_vm.fields['email'].label},model:{value:(_vm.form.reference.email),callback:function ($$v) {_vm.$set(_vm.form.reference, "email", $$v)},expression:"form.reference.email"}})]}}],null,false,99491834)})],1):_vm._e(),_vm._v(" "),(false)?_c('v-col',{staticClass:"px-3 py-0",attrs:{"cols":"12","md":"6"}},[_c('ValidationProvider',{attrs:{"name":"Date","rules":"required","vid":"Date"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('FlexDatePicker',{attrs:{"errors":errors,"color":"secondary","label":_vm.fields['date'].label},model:{value:(_vm.form.reference.date),callback:function ($$v) {_vm.$set(_vm.form.reference, "date", $$v)},expression:"form.reference.date"}})]}}],null,false,1582185904)})],1):_vm._e(),_vm._v(" "),_c('v-col',{staticClass:"px-3 py-0",attrs:{"cols":"12","md":_vm.selectedReference === 'Accountant' || _vm.selectedReference === 'Business' ? 12 : 6}},[_c('ValidationProvider',{attrs:{"name":"Phone","rules":"required|length:10|numeric","vid":"Phone"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"inputmode":"numeric","error-messages":errors,"color":"secondary","label":_vm.fields['phone'].label},model:{value:(_vm.form.reference.phone),callback:function ($$v) {_vm.$set(_vm.form.reference, "phone", $$v)},expression:"form.reference.phone"}})]}}])})],1),_vm._v(" "),(!_vm.isPersonal)?_c('v-col',{staticClass:"px-3 py-0",attrs:{"cols":"12","sm":"6","md":"5"}},[_c('ValidationProvider',{attrs:{"name":"Is Business","rules":"required","vid":"Is Business"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('IsBusiness',{attrs:{"items":['Yes', 'No'],"error-messages":errors,"label":_vm.fields['is-business'].label},model:{value:(_vm.form.reference['is-business']),callback:function ($$v) {_vm.$set(_vm.form.reference, 'is-business', $$v)},expression:"form.reference['is-business']"}})]}}],null,false,4124970634)})],1):_vm._e(),_vm._v(" "),(!_vm.isPersonal && (_vm.form.reference['is-business'] !== 'No' && _vm.form.reference['is-business'] !== '' ? true : false))?_c('v-col',{staticClass:"px-3 py-0",attrs:{"cols":"12","sm":"6","md":"7"}},[_c('ValidationProvider',{attrs:{"name":"Business Name","rules":_vm.form.reference['is-business'] === 'Yes' ? 'required' : _vm.form.reference['is-business'] === '' ? 'required' : '',"vid":"Business Name"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"disabled":_vm.form.reference['is-business'] === 'No' ? true : false,"color":"secondary","label":_vm.fields['business-name'].label},model:{value:(_vm.form.reference['business-name']),callback:function ($$v) {_vm.$set(_vm.form.reference, 'business-name', $$v)},expression:"form.reference['business-name']"}})]}}],null,false,201323306)})],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }