var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-dialog',{attrs:{"persistent":"","width":"500"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(_vm.labelType === 'label')?_c('p',_vm._g(_vm._b({staticClass:"pt-4",attrs:{"dark":""},on:{"click":_vm.getPeople}},'p',attrs,false),on),[_vm._v("\n                "+_vm._s(_vm.label)+"\n            ")]):(_vm.labelType === 'fab')?_c('v-btn',_vm._g(_vm._b({attrs:{"fab":_vm.labelType === 'fab',"depressed":""},on:{"click":_vm.saveData}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(_vm._s(_vm.icon))])],1):_c('v-btn',_vm._g(_vm._b({staticClass:"secondary",attrs:{"large":"","depressed":""},on:{"click":_vm.saveData}},'v-btn',attrs,false),on),[_vm._v("\n                "+_vm._s(_vm.label)+"\n            ")])]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_vm._v(" "),_c('v-card',{attrs:{"flat":"","disabled":_vm.saving}},[(_vm.saving)?_c('v-progress-linear',{attrs:{"color":"secondary","indeterminate":"","stream":"","absolute":"","top":""}}):_vm._e(),_vm._v(" "),_c('v-card-text',{staticClass:"pa-10"},[_c('div',{staticClass:"text-h4 font-weight-bold mb-4"},[_vm._v("Invite Applicant to Edit")]),_vm._v(" "),_c('ValidationObserver',{ref:"observer",attrs:{"tag":"form"}},[_c('div',{staticClass:"flex-form"},[_c('div',{staticClass:"flex-field"},[_c('ValidationProvider',{attrs:{"rules":"required","name":"First Name","vid":"first_name"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"label":"First Name"},model:{value:(_vm.form['first-name']),callback:function ($$v) {_vm.$set(_vm.form, 'first-name', $$v)},expression:"form['first-name']"}})]}}])})],1),_vm._v(" "),_c('div',{staticClass:"flex-field"},[_c('ValidationProvider',{attrs:{"rules":"required","name":"Last Name","vid":"last_name"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"label":"Last Name"},model:{value:(_vm.form['last-name']),callback:function ($$v) {_vm.$set(_vm.form, 'last-name', $$v)},expression:"form['last-name']"}})]}}])})],1),_vm._v(" "),_c('div',{staticClass:"flex-field"},[_c('ValidationProvider',{attrs:{"name":"Email","rules":"required|email","vid":"email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"label":"Email"},model:{value:(_vm.form['email']),callback:function ($$v) {_vm.$set(_vm.form, 'email', $$v)},expression:"form['email']"}})]}}])})],1)])])],1),_vm._v(" "),_c('v-divider'),_vm._v(" "),_c('v-card-actions',{staticClass:"pa-6 px-md-10 py-6 flex-wrap controls"},[_c('v-btn',{staticClass:"flex-back-button",staticStyle:{"min-width":"140px"},attrs:{"outlined":"","color":"light_gray_2","large":""},on:{"click":function($event){_vm.dialog = false}}},[_vm._v("\n                    Back\n                ")]),_vm._v(" "),_c('v-spacer'),_vm._v(" "),_c('v-btn',{staticStyle:{"min-width":"140px"},attrs:{"color":"secondary","depressed":"","large":""},on:{"click":_vm.save}},[_vm._v("\n                    Submit\n                ")])],1)],1)],1),_vm._v(" "),(_vm.dialog2)?_c('ConfirmModal',{attrs:{"async":"","color":"warning","title":"Invitee not Found on Application","confirmText":"Yes, Send Invite","value":_vm.dialog2,"standalone":false,"processing":_vm.saving,"message":("The invitee email address " + _vm.peopleEmail + " does not match the email address of any person in this finance application. Are you sure you wish to give access to this person?")},on:{"close":_vm.handleClose,"confirm":_vm.handleConfirm}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }