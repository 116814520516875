import { mapState, mapActions, mapGetters, mapMutations } from 'vuex'
import resourceHelpers from '~/components/shared/resource-helpers'
import subscriptions from '~/components/shared/subscriptions'  
import formHelpers from '~/components/shared/form-helpers' 

export default {
    mixins: [resourceHelpers, subscriptions, formHelpers],
    data(){
        return {
   
        }
    },
    computed:{
        ...mapGetters('themes', [
            'getActiveTheme'
        ]),
        vb() {
            return this.$vuetify.breakpoint
        }
    },
    methods: {
        filterResourceFields(source, target){

            // console.log(source)
            // console.log(target)

            let newResource = {
                    type: "",
                    id: null,
                    attributes: {},
                    relationships: {}
            }

            Object.keys(source.attributes).forEach( field => {
                newResource.attributes[field] = target.attributes[field]
            })

            newResource.type = target.type
            newResource.id = target.id

            if(target.hasOwnProperty('relationships')) {

                Object.keys(target.relationships).forEach(item => {

                    if( target.relationships[item].hasOwnProperty('data') ) {
                        newResource.relationships[item] = target.relationships[item]
                    }
                })

            }
            return newResource
        },
        hexToRGBA(hex, a = 1){
            return this.$options.filters.hexToRGBA(hex, a)
        },
    }

}