export default {
    "occupation": [
        { "text": "Aboriginal Cultural Advisor", "value": "Aboriginal Cultural Advisor" },
        { "text": "Aboriginal Health Service Worker", "value": "Aboriginal Health Service Worker" },
        { "text": "Account Manager", "value": "Account Manager" },
        { "text": "Accountant", "value": "Accountant" },
        { "text": "Accountant Assistant", "value": "Accountant Assistant" },
        { "text": "Accounts Clerk/Bookkeeper", "value": "Accounts Clerk/Bookkeeper" },
        { "text": "Actuarial Student", "value": "Actuarial Student" },
        { "text": "Acupuncturist", "value": "Acupuncturist" },
        { "text": "Administration", "value": "Administration" },
        { "text": "Advertising Agent", "value": "Advertising Agent" },
        { "text": "Advertising Executive", "value": "Advertising:Executive" },
        { "text": "Aeronautical Engineer", "value": "Aeronautical Engineer" },
        { "text": "Aged Care Worker", "value": "Aged Care Worker" },
        { "text": "Agricultural Contractor", "value": "Agricultural Contractor" },
        { "text": "Air Traffic Controller", "value": "Air Traffic Controller" },
        { "text": "Airconditioning Technician/Installer", "value": "Airconditioning Technician/Installer" },
        { "text": "Aircraft Mechanic", "value": "Aircraft Mechanic" },
        { "text": "Airforce", "value": "Airforce" },
        { "text": "Airline Staff Pilot/Crew", "value": "Airline Staff:Pilot/Crew" },
        { "text": "Airline Staff Office Worker", "value": "Airline Staff:Office Worker" },
        { "text": "Airline Staff Porters/Baggage Handlers", "value": "Airline Staff:Porters/Baggage Handlers" },
        { "text": "Ambulance Driver", "value": "Ambulance Driver" },
        { "text": "Anaesthetist", "value": "Anaesthetist" },
        { "text": "Antique Dealer", "value": "Antique Dealer" },
        { "text": "Apiarist/Beekeeper", "value": "Apiarist/Beekeeper" },
        { "text": "Applied Engineering", "value": "Applied Engineering" },
        { "text": "Apprentice", "value": "Apprentice" },
        { "text": "Arborist", "value": "Arborist" },
        { "text": "Archaeologist", "value": "Archaeologist" },
        { "text": "Architect", "value": "Architect" },
        { "text": "Armed Forces", "value": "Armed Forces" },
        { "text": "Army", "value": "Army" },
        { "text": "Artist", "value": "Artist" },
        { "text": "Asbestos Industry", "value": "Asbestos Industry" },
        { "text": "Asphalt Layer", "value": "Asphalt Layer" },
        { "text": "Assembly Line Worker", "value": "Assembly Line Worker" },
        { "text": "Assistant Manager", "value": "Assistant Manager" },
        { "text": "Assistant Principal", "value": "Assistant Principal" },
        { "text": "Astronomer", "value": "Astronomer" },
        { "text": "Attendant", "value": "Attendant" },
        { "text": "Auctioneer", "value": "Auctioneer" },
        { "text": "Audiologist", "value": "Audiologist" },
        { "text": "Auditor", "value": "Auditor" },
        { "text": "Author", "value": "Author" },
        { "text": "Auto Machanic", "value": "Auto Machanic" },
        { "text": "Autoelectrician", "value": "Autoelectrician" },
        { "text": "Backhoe/Bobcat/Bulldozer Operator", "value": "Backhoe/Bobcat/Bulldozer Operator" },
        { "text": "Backlanding", "value": "Backlanding" },
        { "text": "Baker", "value": "Baker" },
        { "text": "Bank Teller", "value": "Bank Teller" },
        { "text": "Banks/Building Society/Credit Union:Clerks And Tellers", "value": "Banks/Building Society/Credit Union:Clerks And Tellers" },
        { "text": "Bar Tender", "value": "Bar Tender" },
        { "text": "Barista", "value": "Barista" },
        { "text": "Barrister", "value": "Barrister" },
        { "text": "Beach Patrol Lifeguard Professional", "value": "Beach Patrol:Lifeguard Professional" },
        { "text": "Beautician", "value": "Beautician" },
        { "text": "Bicycle StorekeeperRepairs", "value": "Bicycle Storekeeper:Repairs" },
        { "text": "Biochemist", "value": "Biochemist" },
        { "text": "Biologist", "value": "Biologist" },
        { "text": "Blacksmith/Farrier", "value": "Blacksmith/Farrier" },
        { "text": "Blaster/Explosive Handler", "value": "Blaster/Explosive Handler" },
        { "text": "Blind/Awning Or Screen Installer", "value": "Blind/Awning Or Screen Installer" },
        { "text": "Boarding Coordinator", "value": "Boarding Coordinator" },
        { "text": "Boat Builder", "value": "Boat Builder" },
        { "text": "Boilermaker/Welder", "value": "Boilermaker/Welder" },
        { "text": "Book Keeper", "value": "Book Keeper" },
        { "text": "Book_in Officer", "value": "Book_in Officer" },
        { "text": "Bookmaker", "value": "Bookmaker" },
        { "text": "Botanist", "value": "Botanist" },
        { "text": "Bouncer/Security Staff", "value": "Bouncer/Security Staff" },
        { "text": "Bread Vendor", "value": "Bread Vendor" },
        { "text": "Bricklayer", "value": "Bricklayer" },
        { "text": "Bridge Builder", "value": "Bridge Builder" },
        { "text": "Builder", "value": "Builder" },
        { "text": "Building Industry:Heavy Equipment Operator", "value": "Building Industry:Heavy Equipment Operator" },
        { "text": "Building Labourer", "value": "Building Labourer" },
        { "text": "Building Supply Store:Shop Worker", "value": "Building Supply Store:Shop Worker" },
        { "text": "Bus Driver", "value": "Bus Driver" },
        { "text": "Business Analyst", "value": "Business Analyst" },
        { "text": "Business Consultant", "value": "Business Consultant" },
        { "text": "Business Development Manager", "value": "Business Development Manager" },
        { "text": "Butcher", "value": "Butcher" },
        { "text": "Buyer Retail Store", "value": "Buyer Retail Store" },
        { "text": "Cabinet Maker", "value": "Cabinet Maker" },
        { "text": "Cable Maker/Wire Maker", "value": "Cable Maker/Wire Maker" },
        { "text": "Cafe/Coffee Lounge Proprietor", "value": "Cafe/Coffee Lounge Proprietor" },
        { "text": "Call Centre Operator", "value": "Call Centre Operator" },
        { "text": "Car Salesperson", "value": "Car Salesperson" },
        { "text": "Caravan Park Owner", "value": "Caravan Park Owner" },
        { "text": "Carbon Recycler", "value": "Carbon Recycler" },
        { "text": "Cardiac Technologist", "value": "Cardiac Technologist" },
        { "text": "Carer", "value": "Carer" },
        { "text": "Carers Assistant", "value": "Carers Assistant" },
        { "text": "Caretaker", "value": "Caretaker" },
        { "text": "Carpenter", "value": "Carpenter" },
        { "text": "Carpet Cleaner", "value": "Carpet Cleaner" },
        { "text": "Carpet/Linoleum Layer", "value": "Carpet/Linoleum Layer" },
        { "text": "Cartage Co_Ordinator", "value": "Cartage Co_Ordinator" },
        { "text": "Case Manager", "value": "Case Manager" },
        { "text": "Cashier:Financial Institution", "value": "Cashier:Financial Institution" },
        { "text": "Cashier:Shop", "value": "Cashier:Shop" },
        { "text": "Caterer", "value": "Caterer" },
        { "text": "Catering and Event Assistant", "value": "Catering and Event Assistant" },
        { "text": "Cement Manufacturer", "value": "Cement Manufacturer" },
        { "text": "Chauffeur", "value": "Chauffeur" },
        { "text": "Chef / Cook", "value": "Chef / Cook" },
        { "text": "Chemical Industry:Chemist/Pharmacist", "value": "Chemical Industry:Chemist/Pharmacist" },
        { "text": "Chemical Operator", "value": "Chemical Operator" },
        { "text": "Chemical Service Tech", "value": "Chemical Service Tech" },
        { "text": "Chemist/Pharmacist _ Retail", "value": "Chemist/Pharmacist _ Retail" },
        { "text": "Chicken Catcher", "value": "Chicken Catcher" },
        { "text": "Child Care Coordinator", "value": "Child Care Coordinator" },
        { "text": "Child Care Worker", "value": "Child Care Worker" },
        { "text": "Child Protection Officer", "value": "Child Protection Officer" },
        { "text": "Chiropodist/Podiatrist", "value": "Chiropodist/Podiatrist" },
        { "text": "Chiropractor", "value": "Chiropractor" },
        { "text": "Civil Engineer", "value": "Civil Engineer" },
        { "text": "Claims Officer", "value": "Claims Officer" },
        { "text": "Cleaner", "value": "Cleaner" },
        { "text": "Clergy/Minister", "value": "Clergy/Minister" },
        { "text": "Clerical", "value": "Clerical" },
        { "text": "Clothing/Textile Industry:Process Worker/Machinist", "value": "Clothing/Textile Industry:Process Worker/Machinist" },
        { "text": "Club Worker:Bar Person", "value": "Club Worker:Bar Person" },
        { "text": "Coal Miner", "value": "Coal Miner" },
        { "text": "Coating Technician", "value": "Coating Technician" },
        { "text": "Collections Manager", "value": "Collections Manager" },
        { "text": "Collections Officer", "value": "Collections Officer" },
        { "text": "Commercial Traveller", "value": "Commercial Traveller" },
        { "text": "Commodity Broker", "value": "Commodity Broker" },
        { "text": "Communication Technician", "value": "Communication Technician" },
        { "text": "Communications Officer", "value": "Communications Officer" },
        { "text": "Communications Sailor", "value": "Communications Sailor" },
        { "text": "Community Care Worker", "value": "Community Care Worker" },
        { "text": "Company Secretary", "value": "Company Secretary" },
        { "text": "Composer", "value": "Composer" },
        { "text": "Computer Industry", "value": "Computer Industry" },
        { "text": "Computer Technician", "value": "Computer Technician" },
        { "text": "Concrete Contractor/Labourer", "value": "Concrete Contractor/Labourer" },
        { "text": "Console Operator", "value": "Console Operator" },
        { "text": "Construction", "value": "Construction" },
        { "text": "Consultant", "value": "Consultant" },
        { "text": "Contractor", "value": "Contractor" },
        { "text": "Control Room Operator", "value": "Control Room Operator" },
        { "text": "Conveyor Belt Technician", "value": "Conveyor Belt Technician" },
        { "text": "Coordinators", "value": "Coordinators" },
        { "text": "Coroner", "value": "Coroner" },
        { "text": "Council Worker", "value": "Council Worker" },
        { "text": "Counsellor", "value": "Counsellor" },
        { "text": "Counter Sales", "value": "Counter Sales" },
        { "text": "Courier", "value": "Courier" },
        { "text": "Court Reporter", "value": "Court Reporter" },
        { "text": "Crane Operator", "value": "Crane Operator" },
        { "text": "Credit Manager", "value": "Credit Manager" },
        { "text": "Credit Officer", "value": "Credit Officer" },
        { "text": "Crew Trainer", "value": "Crew Trainer" },
        { "text": "Crossing Guard", "value": "Crossing Guard" },
        { "text": "Cultural Heritage Officer", "value": "Cultural Heritage Officer" },
        { "text": "Curator:Groundsman", "value": "Curator:Groundsman" },
        { "text": "Curtain Installer", "value": "Curtain Installer" },
        { "text": "Customer Service Area Manager", "value": "Customer Service Area Manager" },
        { "text": "Customer Service Officer", "value": "Customer Service Officer" },
        { "text": "Customs Agent _ Clerical", "value": "Customs Agent _ Clerical" },
        { "text": "Customs Officer", "value": "Customs Officer" },
        { "text": "Dairy Farmer", "value": "Dairy Farmer" },
        { "text": "Dairy Industry:Farm Proprietor", "value": "Dairy Industry:Farm Proprietor" },
        { "text": "Dairy Technician", "value": "Dairy Technician" },
        { "text": "Dance Instructor", "value": "Dance Instructor" },
        { "text": "Data Entry", "value": "Data Entry" },
        { "text": "Debt Collector", "value": "Debt Collector" },
        { "text": "Decorator", "value": "Decorator" },
        { "text": "Delicatessen", "value": "Delicatessen" },
        { "text": "Delivery Driver", "value": "Delivery Driver" },
        { "text": "Demolition Worker", "value": "Demolition Worker" },
        { "text": "Dental Assistant", "value": "Dental Assistant" },
        { "text": "Dental Nurse", "value": "Dental Nurse" },
        { "text": "Dental Receptionist", "value": "Dental Receptionist" },
        { "text": "Dentist", "value": "Dentist" },
        { "text": "Department Store:Manager & Clerical Only", "value": "Department Store:Manager & Clerical Only" },
        { "text": "Department Store:Sales With Some Light Manual (No Deliveries)", "value": "Department Store:Sales With Some Light Manual (No Deliveries)" },
        { "text": "Department Store:Storeperson", "value": "Department Store:Storeperson" },
        { "text": "Desktop Publisher", "value": "Desktop Publisher" },
        { "text": "Despatch", "value": "Despatch" },
        { "text": "Despatch Manager", "value": "Despatch Manager" },
        { "text": "Detailer", "value": "Detailer" },
        { "text": "Developmental Care Worker", "value": "Developmental Care Worker" },
        { "text": "Diemaker", "value": "Diemaker" },
        { "text": "Diesel Mechanic", "value": "Diesel Mechanic" },
        { "text": "Dietician", "value": "Dietician" },
        { "text": "Digital Developer", "value": "Digital Developer" },
        { "text": "Director", "value": "Director" },
        { "text": "Disability Support Officer", "value": "Disability Support Officer" },
        { "text": "Disc Jockey", "value": "Disc Jockey" },
        { "text": "Diver", "value": "Diver" },
        { "text": "Dockyard Worker", "value": "Dockyard Worker" },
        { "text": "Doctor", "value": "Doctor" },
        { "text": "Document Control", "value": "Document Control" },
        { "text": "Draftsperson", "value": "Draftsperson" },
        { "text": "Dressmaker", "value": "Dressmaker" },
        { "text": "Driller (Water/Oil/Mineral)", "value": "Driller (Water/Oil/Mineral)" },
        { "text": "Driver", "value": "Driver" },
        { "text": "Dry Cleaning Industry:Other", "value": "Dry Cleaning Industry:Other" },
        { "text": "Duct Installer", "value": "Duct Installer" },
        { "text": "Economist", "value": "Economist" },
        { "text": "Editor", "value": "Editor" },
        { "text": "Education", "value": "Education" },
        { "text": "Electrician", "value": "Electrician" },
        { "text": "Electronic Equipment Installer", "value": "Electronic Equipment Installer" },
        { "text": "Electronics Assembler", "value": "Electronics Assembler" },
        { "text": "Electroplater", "value": "Electroplater" },
        { "text": "Employment Agent", "value": "Employment Agent" },
        { "text": "Employment Training Advisor", "value": "Employment Training Advisor" },
        { "text": "Engineer", "value": "Engineer" },
        { "text": "Engraver", "value": "Engraver" },
        { "text": "Entertainer", "value": "Entertainer" },
        { "text": "Estimator", "value": "Estimator" },
        { "text": "Events Manager", "value": "Events Manager" },
        { "text": "Excavator Operator", "value": "Excavator Operator" },
        { "text": "Executive Assistant", "value": "Executive Assistant" },
        { "text": "Explosives Industry", "value": "Explosives Industry" },
        { "text": "Export/Import (Clerical Only)", "value": "Export/Import (Clerical Only)" },
        { "text": "Facilitator", "value": "Facilitator" },
        { "text": "Factory Worker", "value": "Factory Worker" },
        { "text": "Farm Hand", "value": "Farm Hand" },
        { "text": "Farmer", "value": "Farmer" },
        { "text": "Farrier", "value": "Farrier" },
        { "text": "Fashion Designer", "value": "Fashion Designer" },
        { "text": "Fast Food Takeaway", "value": "Fast Food Takeaway" },
        { "text": "Fencing Contractor", "value": "Fencing Contractor" },
        { "text": "Fibreglass Moulder", "value": "Fibreglass Moulder" },
        { "text": "Finance Adviser/Analyst Or Consultant:Tertiary Qualified", "value": "Finance Adviser/Analyst Or Consultant:Tertiary Qualified" },
        { "text": "Finance Broker", "value": "Finance Broker" },
        { "text": "Financial", "value": "Financial" },
        { "text": "Firefighter", "value": "Firefighter" },
        { "text": "First Mate", "value": "First Mate" },
        { "text": "Fisherman", "value": "Fisherman" },
        { "text": "Fitness Centre:Instructor", "value": "Fitness Centre:Instructor" },
        { "text": "Fitness Centre:Owner/Operator", "value": "Fitness Centre:Owner/Operator" },
        { "text": "Fitter And Turner", "value": "Fitter And Turner" },
        { "text": "Fleet Controller", "value": "Fleet Controller" },
        { "text": "Fleet Driver", "value": "Fleet Driver" },
        { "text": "Floor Coverer/Sander", "value": "Floor Coverer/Sander" },
        { "text": "Florist", "value": "Florist" },
        { "text": "Food and Beverage Attendant", "value": "Food and Beverage Attendant" },
        { "text": "Food Technologist", "value": "Food Technologist" },
        { "text": "Foreman", "value": "Foreman" },
        { "text": "Forest Ranger/Warden", "value": "Forest Ranger/Warden" },
        { "text": "Fork Lift Driver", "value": "Fork Lift Driver" },
        { "text": "Foster Carer", "value": "Foster Carer" },
        { "text": "Foundry Worker", "value": "Foundry Worker" },
        { "text": "Freight Forwarding", "value": "Freight Forwarding" },
        { "text": "French Polisher", "value": "French Polisher" },
        { "text": "Fresh Produce Assistant", "value": "Fresh Produce Assistant" },
        { "text": "Fruit Packer/Picker", "value": "Fruit Packer/Picker" },
        { "text": "Fund Consultant", "value": "Fund Consultant" },
        { "text": "Funeral Parlour:Director/Undertaker", "value": "Funeral Parlour:Director/Undertaker" },
        { "text": "Funeral Parlour:Driver/Pallbearer", "value": "Funeral Parlour:Driver/Pallbearer" },
        { "text": "Fur Cutter", "value": "Fur Cutter" },
        { "text": "Furniture Restorer", "value": "Furniture Restorer" },
        { "text": "Galvaniser", "value": "Galvaniser" },
        { "text": "Gaming Attendant", "value": "Gaming Attendant" },
        { "text": "Garbage Collector", "value": "Garbage Collector" },
        { "text": "Gardener", "value": "Gardener" },
        { "text": "Gas Fitter", "value": "Gas Fitter" },
        { "text": "General Hand", "value": "General Hand" },
        { "text": "Geologist", "value": "Geologist" },
        { "text": "Glazier", "value": "Glazier" },
        { "text": "Goldsmith/Silversmith", "value": "Goldsmith/Silversmith" },
        { "text": "Golf Professional (Teaching And Pro Shop Only)", "value": "Golf Professional (Teaching And Pro Shop Only)" },
        { "text": "Government", "value": "Government" },
        { "text": "Graphic Artist/Designer", "value": "Graphic Artist/Designer" },
        { "text": "Ground Keeper", "value": "Ground Keeper" },
        { "text": "Gunsmith", "value": "Gunsmith" },
        { "text": "Gymnasium:Instructor _ Full_Time", "value": "Gymnasium:Instructor _ Full_Time" },
        { "text": "Gynaecologist", "value": "Gynaecologist" },
        { "text": "H.R. Coordinator", "value": "H.R. Coordinator" },
        { "text": "Hairdresser", "value": "Hairdresser" },
        { "text": "Handyman", "value": "Handyman" },
        { "text": "Headmaster", "value": "Headmaster" },
        { "text": "Health Inspector", "value": "Health Inspector" },
        { "text": "Health Worker", "value": "Health Worker" },
        { "text": "Herbalist", "value": "Herbalist" },
        { "text": "Heritage Officer", "value": "Heritage Officer" },
        { "text": "Home Duties", "value": "Home Duties" },
        { "text": "Home Maker", "value": "Home Maker" },
        { "text": "Homeopathist", "value": "Homeopathist" },
        { "text": "Iridologist (Qualified)", "value": "Iridologist (Qualified)" },
        { "text": "Horse Trainer", "value": "Horse Trainer" },
        { "text": "Horticulturist", "value": "Horticulturist" },
        { "text": "Hospital Porter", "value": "Hospital Porter" },
        { "text": "Hospitality Worker", "value": "Hospitality Worker" },
        { "text": "Hotel Manager", "value": "Hotel Manager" },
        { "text": "Hotel Services", "value": "Hotel Services" },
        { "text": "House Cleaner", "value": "House Cleaner" },
        { "text": "Housing Officer", "value": "Housing Officer" },
        { "text": "Human Resources Consultant", "value": "Human Resources Consultant" },
        { "text": "Hypnotherapist", "value": "Hypnotherapist" },
        { "text": "Implementation Lead", "value": "Implementation Lead" },
        { "text": "Importer/Exporter", "value": "Importer/Exporter" },
        { "text": "Installer", "value": "Installer" },
        { "text": "Instrument Manufacturer", "value": "Instrument Manufacturer" },
        { "text": "Insulation Installer", "value": "Insulation Installer" },
        { "text": "Insurance Assessor/Loss Adjustor", "value": "Insurance Assessor/Loss Adjustor" },
        { "text": "Integration Developer", "value": "Integration Developer" },
        { "text": "Interior Designer", "value": "Interior Designer" },
        { "text": "Interpreter", "value": "Interpreter" },
        { "text": "Investment Advisor", "value": "Investment Advisor" },
        { "text": "IT Consultant", "value": "IT Consultant" },
        { "text": "Jackaroo/Jillaroo", "value": "Jackaroo/Jillaroo" },
        { "text": "Janitor", "value": "Janitor" },
        { "text": "Jeweller", "value": "Jeweller" },
        { "text": "Jewellery Industry:Retail Store", "value": "Jewellery Industry:Retail Store" },
        { "text": "Jockey", "value": "Jockey" },
        { "text": "Journalist/Reporter", "value": "Journalist/Reporter" },
        { "text": "Judge", "value": "Judge" },
        { "text": "Juice Vendor", "value": "Juice Vendor" },
        { "text": "Kennel Proprietor", "value": "Kennel Proprietor" },
        { "text": "Kitchen Hand", "value": "Kitchen Hand" },
        { "text": "Laboratory Technician", "value": "Laboratory Technician" },
        { "text": "Labourer", "value": "Labourer" },
        { "text": "Land Broker", "value": "Land Broker" },
        { "text": "Landscape Gardener", "value": "Landscape Gardener" },
        { "text": "Lapidary", "value": "Lapidary" },
        { "text": "Laser Cutter", "value": "Laser Cutter" },
        { "text": "Lathe Operator", "value": "Lathe Operator" },
        { "text": "Laundry Staff", "value": "Laundry Staff" },
        { "text": "Lawyer", "value": "Lawyer" },
        { "text": "Leading Hand", "value": "Leading Hand" },
        { "text": "Lecturer", "value": "Lecturer" },
        { "text": "Librarian", "value": "Librarian" },
        { "text": "Line Worker", "value": "Line Worker" },
        { "text": "Liquor Assistant", "value": "Liquor Assistant" },
        { "text": "Loader", "value": "Loader" },
        { "text": "Locksmith", "value": "Locksmith" },
        { "text": "Machanic", "value": "Machanic" },
        { "text": "Machine Operator", "value": "Machine Operator" },
        { "text": "Machinery Equipment Hire & Service:Repair & Maintenance", "value": "Machinery Equipment Hire & Service:Repair & Maintenance" },
        { "text": "Machinery Equipment Hire & Service:Sales Only", "value": "Machinery Equipment Hire & Service:Sales Only" },
        { "text": "Mail Sorter", "value": "Mail Sorter" },
        { "text": "Maintenance Worker", "value": "Maintenance Worker" },
        { "text": "Makeup Artist", "value": "Makeup Artist" },
        { "text": "Management", "value": "Management" },
        { "text": "Management Consultant (Qualified)", "value": "Management Consultant (Qualified)" },
        { "text": "Manager", "value": "Manager" },
        { "text": "Manager Administration", "value": "Manager Administration" },
        { "text": "Managing Director", "value": "Managing Director" },
        { "text": "Manufacturing Specialist Supervisor", "value": "Manufacturing Specialist Supervisor" },
        { "text": "Marina Owner", "value": "Marina Owner" },
        { "text": "Marketing/Sales Manager", "value": "Marketing/Sales Manager" },
        { "text": "Massage Therapist", "value": "Massage Therapist" },
        { "text": "Meat Industry:Boner", "value": "Meat Industry:Boner" },
        { "text": "Meat Industry:Inspector", "value": "Meat Industry:Inspector" },
        { "text": "Meat Industry:Packer", "value": "Meat Industry:Packer" },
        { "text": "Meat Worker", "value": "Meat Worker" },
        { "text": "Mechanic", "value": "Mechanic" },
        { "text": "Mechanical Fitter", "value": "Mechanical Fitter" },
        { "text": "Medical Officer", "value": "Medical Officer" },
        { "text": "Medical Practitioner (Doctor)", "value": "Medical Practitioner (Doctor)" },
        { "text": "Medical Technician", "value": "Medical Technician" },
        { "text": "Medical Theatre Technician", "value": "Medical Theatre Technician" },
        { "text": "Merchandiser", "value": "Merchandiser" },
        { "text": "Metal Industry:Sheet Metal Worker", "value": "Metal Industry:Sheet Metal Worker" },
        { "text": "Metal Industry:Supervisor", "value": "Metal Industry:Supervisor" },
        { "text": "Metallurgist:Other", "value": "Metallurgist:Other" },
        { "text": "Meteorologist", "value": "Meteorologist" },
        { "text": "Meter Reader", "value": "Meter Reader" },
        { "text": "Midwife", "value": "Midwife" },
        { "text": "Milk Bar:Proprietor", "value": "Milk Bar:Proprietor" },
        { "text": "Mill Hand", "value": "Mill Hand" },
        { "text": "Miner", "value": "Miner" },
        { "text": "Mining Industry", "value": "Mining Industry" },
        { "text": "Minister", "value": "Minister" },
        { "text": "Model", "value": "Model" },
        { "text": "Mortician", "value": "Mortician" },
        { "text": "Motor Vehicle Industry:Assembly/Production Line", "value": "Motor Vehicle Industry:Assembly/Production Line" },
        { "text": "Motor Vehicle Industry:Detailer", "value": "Motor Vehicle Industry:Detailer" },
        { "text": "Motor Vehicle Industry:Wrecker", "value": "Motor Vehicle Industry:Wrecker" },
        { "text": "Music Teacher", "value": "Music Teacher" },
        { "text": "Musician", "value": "Musician" },
        { "text": "Nail Technician", "value": "Nail Technician" },
        { "text": "Nanny", "value": "Nanny" },
        { "text": "Naturopath", "value": "Naturopath" },
        { "text": "Navy Personnel:Seagoing", "value": "Navy Personnel:Seagoing" },
        { "text": "Network Administrator", "value": "Network Administrator" },
        { "text": "Network Engineer", "value": "Network Engineer" },
        { "text": "Night Watchman", "value": "Night Watchman" },
        { "text": "NON TRADE (blue collar worker)", "value": "NON TRADE (blue collar worker)" },
        { "text": "Nurse", "value": "Nurse" },
        { "text": "Nursery Hand", "value": "Nursery Hand" },
        { "text": "Nurses Aide", "value": "Nurses Aide" },
        { "text": "Obstetrician", "value": "Obstetrician" },
        { "text": "Occupational Therapist", "value": "Occupational Therapist" },
        { "text": "Office Worker", "value": "Office Worker" },
        { "text": "Office/Factory/School Cleaner", "value": "Office/Factory/School Cleaner" },
        { "text": "Oil & Gas Industry", "value": "Oil & Gas Industry" },
        { "text": "Operational Support", "value": "Operational Support" },
        { "text": "Operations Consultant", "value": "Operations Consultant" },
        { "text": "Operations Manager", "value": "Operations Manager" },
        { "text": "Operative Miller", "value": "Operative Miller" },
        { "text": "Operator", "value": "Operator" },
        { "text": "Ophthalmologist", "value": "Ophthalmologist" },
        { "text": "Optician", "value": "Optician" },
        { "text": "Optometrist", "value": "Optometrist" },
        { "text": "Order Selector", "value": "Order Selector" },
        { "text": "Orthodontist", "value": "Orthodontist" },
        { "text": "Orthopaedic Surgeon", "value": "Orthopaedic Surgeon" },
        { "text": "Osteopath", "value": "Osteopath" },
        { "text": "Other", "value": "Other" },
        { "text": "Oyster Farm Hand", "value": "Oyster Farm Hand" },
        { "text": "Packer", "value": "Packer" },
        { "text": "Paediatrician", "value": "Paediatrician" },
        { "text": "Painter", "value": "Painter" },
        { "text": "Painter And Decorator:Spray Painter", "value": "Painter And Decorator:Spray Painter" },
        { "text": "Panel Beater", "value": "Panel Beater" },
        { "text": "Paramedic", "value": "Paramedic" },
        { "text": "Park Ranger", "value": "Park Ranger" },
        { "text": "Parking Attendant", "value": "Parking Attendant" },
        { "text": "Pastry Cook", "value": "Pastry Cook" },
        { "text": "Pathologist", "value": "Pathologist" },
        { "text": "Pathology Collector", "value": "Pathology Collector" },
        { "text": "Patient Services", "value": "Patient Services" },
        { "text": "Paver", "value": "Paver" },
        { "text": "Pawnbroker", "value": "Pawnbroker" },
        { "text": "Payroll Clerk", "value": "Payroll Clerk" },
        { "text": "Pensioner", "value": "Pensioner" },
        { "text": "Personal Assistant", "value": "Personal Assistant" },
        { "text": "Personal Carer", "value": "Personal Carer" },
        { "text": "Personnel Consultant", "value": "Personnel Consultant" },
        { "text": "Pest Exterminator/Fumigator", "value": "Pest Exterminator/Fumigator" },
        { "text": "Pharmacist", "value": "Pharmacist" },
        { "text": "Photographer", "value": "Photographer" },
        { "text": "Physician", "value": "Physician" },
        { "text": "Physicist", "value": "Physicist" },
        { "text": "Physiotherapist", "value": "Physiotherapist" },
        { "text": "Piano Tuner", "value": "Piano Tuner" },
        { "text": "Picker", "value": "Picker" },
        { "text": "Picture Framer", "value": "Picture Framer" },
        { "text": "Pilot", "value": "Pilot" },
        { "text": "Pipe Lagger", "value": "Pipe Lagger" },
        { "text": "Plant Operator", "value": "Plant Operator" },
        { "text": "Plasterer", "value": "Plasterer" },
        { "text": "Plumber", "value": "Plumber" },
        { "text": "Police", "value": "Police" },
        { "text": "Politician", "value": "Politician" },
        { "text": "Pool Technician", "value": "Pool Technician" },
        { "text": "Post Delivery Officer", "value": "Post Delivery Officer" },
        { "text": "Post Placement Officer", "value": "Post Placement Officer" },
        { "text": "Postman", "value": "Postman" },
        { "text": "Pottery And Ceramics:Potter", "value": "Pottery And Ceramics:Potter" },
        { "text": "Poultry Farmer/Poulterer", "value": "Poultry Farmer/Poulterer" },
        { "text": "Powder Coater", "value": "Powder Coater" },
        { "text": "Priest", "value": "Priest" },
        { "text": "Printer", "value": "Printer" },
        { "text": "Prison Officer", "value": "Prison Officer" },
        { "text": "Private Detective", "value": "Private Detective" },
        { "text": "Process Worker", "value": "Process Worker" },
        { "text": "Production Assistant", "value": "Production Assistant" },
        { "text": "Professional", "value": "Professional" },
        { "text": "Professional Sportsperson", "value": "Professional Sportsperson" },
        { "text": "Professor", "value": "Professor" },
        { "text": "Project Manager", "value": "Project Manager" },
        { "text": "Project Scheduler", "value": "Project Scheduler" },
        { "text": "Project Support Officer", "value": "Project Support Officer" },
        { "text": "Property Developer/Consultant", "value": "Property Developer/Consultant" },
        { "text": "Property Manager", "value": "Property Manager" },
        { "text": "Psychiatric Nurse", "value": "Psychiatric Nurse" },
        { "text": "Psychiatrist", "value": "Psychiatrist" },
        { "text": "Psychologist", "value": "Psychologist" },
        { "text": "Public Relation Officer", "value": "Public Relation Officer" },
        { "text": "Public Servant", "value": "Public Servant" },
        { "text": "Purchasing Manager", "value": "Purchasing Manager" },
        { "text": "Purchasing Officer", "value": "Purchasing Officer" },
        { "text": "QA Officer", "value": "QA Officer" },
        { "text": "Quality Control", "value": "Quality Control" },
        { "text": "Quantity Surveyor", "value": "Quantity Surveyor" },
        { "text": "Radiographer/Radiotherapist", "value": "Radiographer/Radiotherapist" },
        { "text": "Radiologist", "value": "Radiologist" },
        { "text": "Railway Worker", "value": "Railway Worker" },
        { "text": "Ranger", "value": "Ranger" },
        { "text": "Real Estate Agent", "value": "Real Estate Agent" },
        { "text": "Receptionist", "value": "Receptionist" },
        { "text": "Recreational Activity Officer", "value": "Recreational Activity Officer" },
        { "text": "Refrigerator Mechanic", "value": "Refrigerator Mechanic" },
        { "text": "Registered Nurse", "value": "Registered Nurse" },
        { "text": "Removalist", "value": "Removalist" },
        { "text": "Rendering Applicator", "value": "Rendering Applicator" },
        { "text": "Repair Person/Service Person", "value": "Repair Person/Service Person" },
        { "text": "Repossession Agent", "value": "Repossession Agent" },
        { "text": "Researcher", "value": "Researcher" },
        { "text": "Restaurant:Bar Staff (Full Time)", "value": "Restaurant:Bar Staff (Full Time)" },
        { "text": "Restaurant:Kitchen Hand/Other", "value": "Restaurant:Kitchen Hand/Other" },
        { "text": "Retail Assistant", "value": "Retail Assistant" },
        { "text": "Retail Sales", "value": "Retail Sales" },
        { "text": "Reticulation Maintenence", "value": "Reticulation Maintenence" },
        { "text": "Retired", "value": "Retired" },
        { "text": "Reverend", "value": "Reverend" },
        { "text": "Rigger", "value": "Rigger" },
        { "text": "Road Crew", "value": "Road Crew" },
        { "text": "Roof Plumber", "value": "Roof Plumber" },
        { "text": "Roof Tiler", "value": "Roof Tiler" },
        { "text": "Sailmaker", "value": "Sailmaker" },
        { "text": "Sailor", "value": "Sailor" },
        { "text": "Sales Assistant", "value": "Sales Assistant" },
        { "text": "Sales Manager", "value": "Sales Manager" },
        { "text": "Sales Person", "value": "Sales Person" },
        { "text": "Sales Representative", "value": "Sales Representative" },
        { "text": "Sand Blaster", "value": "Sand Blaster" },
        { "text": "Scaffolder", "value": "Scaffolder" },
        { "text": "Scheduling Support Officer", "value": "Scheduling Support Officer" },
        { "text": "Scientist", "value": "Scientist" },
        { "text": "Screen Printer", "value": "Screen Printer" },
        { "text": "Secretary", "value": "Secretary" },
        { "text": "Security Guard: Armed", "value": "Security Guard: Armed" },
        { "text": "Security Guard: Unarmed", "value": "Security Guard: Unarmed" },
        { "text": "Self Employed", "value": "Self Employed" },
        { "text": "Senior Claims Officer", "value": "Senior Claims Officer" },
        { "text": "Sergeant", "value": "Sergeant" },
        { "text": "Service Manager", "value": "Service Manager" },
        { "text": "Service Personnel", "value": "Service Personnel" },
        { "text": "Service Technician", "value": "Service Technician" },
        { "text": "Settlement Manager", "value": "Settlement Manager" },
        { "text": "Settlement Officer", "value": "Settlement Officer" },
        { "text": "Shearer", "value": "Shearer" },
        { "text": "Shed Hand", "value": "Shed Hand" },
        { "text": "Shipmate", "value": "Shipmate" },
        { "text": "Shoemaker And Repairs", "value": "Shoemaker And Repairs" },
        { "text": "Shop Assistant", "value": "Shop Assistant" },
        { "text": "Shopfitter", "value": "Shopfitter" },
        { "text": "Shower Screen Installer", "value": "Shower Screen Installer" },
        { "text": "Signage Installer", "value": "Signage Installer" },
        { "text": "Signwriter", "value": "Signwriter" },
        { "text": "Site Manager", "value": "Site Manager" },
        { "text": "Skipper", "value": "Skipper" },
        { "text": "Slaughterman", "value": "Slaughterman" },
        { "text": "Social Worker", "value": "Social Worker" },
        { "text": "Soil Technician", "value": "Soil Technician" },
        { "text": "Soldier", "value": "Soldier" },
        { "text": "Solicitor", "value": "Solicitor" },
        { "text": "Sonographer", "value": "Sonographer" },
        { "text": "Speech Therapist", "value": "Speech Therapist" },
        { "text": "Sports Commissioning Trainer", "value": "Sports Commissioning Trainer" },
        { "text": "Spray Painter", "value": "Spray Painter" },
        { "text": "Stable Hand", "value": "Stable Hand" },
        { "text": "State Manager", "value": "State Manager" },
        { "text": "Station Hand", "value": "Station Hand" },
        { "text": "Statistician", "value": "Statistician" },
        { "text": "Stay at home Mum/Dad", "value": "Stay at home Mum/Dad" },
        { "text": "Steel Erector And Rigger", "value": "Steel Erector And Rigger" },
        { "text": "Stenographer", "value": "Stenographer" },
        { "text": "Stevedore", "value": "Stevedore" },
        { "text": "Steward", "value": "Steward" },
        { "text": "Stockbroker", "value": "Stockbroker" },
        { "text": "Stonemason", "value": "Stonemason" },
        { "text": "Store Team Member", "value": "Store Team Member" },
        { "text": "Storeman", "value": "Storeman" },
        { "text": "Storeperson/Packer", "value": "Storeperson/Packer" },
        { "text": "Strapper", "value": "Strapper" },
        { "text": "Student", "value": "Student" },
        { "text": "Student Carer", "value": "Student Carer" },
        { "text": "Stylist", "value": "Stylist" },
        { "text": "Superanuation Consultant", "value": "Superanuation Consultant" },
        { "text": "Supervisor", "value": "Supervisor" },
        { "text": "Support Worker", "value": "Support Worker" },
        { "text": "Supported Residential Services Manager", "value": "Supported Residential Services Manager" },
        { "text": "Surgeon", "value": "Surgeon" },
        { "text": "Surveyor", "value": "Surveyor" },
        { "text": "TAB Agent", "value": "TAB Agent" },
        { "text": "Tax Consultant", "value": "Tax Consultant" },
        { "text": "Taxi Driver", "value": "Taxi Driver" },
        { "text": "Teacher", "value": "Teacher" },
        { "text": "Teachers Assistant", "value": "Teachers Assistant" },
        { "text": "Team Leader", "value": "Team Leader" },
        { "text": "Technical Support", "value": "Technical Support" },
        { "text": "Technician", "value": "Technician" },
        { "text": "Telemarketer", "value": "Telemarketer" },
        { "text": "Telephone Technician", "value": "Telephone Technician" },
        { "text": "Telephonist/Switchboard Operator", "value": "Telephonist/Switchboard Operator" },
        { "text": "Theatre/Cinema Industry: Actor/Actress", "value": "Theatre/Cinema Industry: Actor/Actress" },
        { "text": "Tiler", "value": "Tiler" },
        { "text": "Timber Industry:Supervisor (Up To 10% Manual)", "value": "Timber Industry:Supervisor (Up To 10% Manual)" },
        { "text": "Track Maintainer", "value": "Track Maintainer" },
        { "text": "Trade", "value": "Trade" },
        { "text": "Trade Assistant", "value": "Trade Assistant" },
        { "text": "Traffic Controller", "value": "Traffic Controller" },
        { "text": "Train Driver", "value": "Train Driver" },
        { "text": "Training Manager", "value": "Training Manager" },
        { "text": "Training Officer", "value": "Training Officer" },
        { "text": "Travel Agent", "value": "Travel Agent" },
        { "text": "Trolley Collection", "value": "Trolley Collection" },
        { "text": "Truck Driver", "value": "Truck Driver" },
        { "text": "Truck Jockey", "value": "Truck Jockey" },
        { "text": "Tutor", "value": "Tutor" },
        { "text": "TV Programmer", "value": "TV Programmer" },
        { "text": "Typesetter", "value": "Typesetter" },
        { "text": "Typewriter Mechanic", "value": "Typewriter Mechanic" },
        { "text": "Typist", "value": "Typist" },
        { "text": "Tyre Fitter", "value": "Tyre Fitter" },
        { "text": "Unemployed", "value": "Unemployed" },
        { "text": "Upholsterer", "value": "Upholsterer" },
        { "text": "Urologist", "value": "Urologist" },
        { "text": "Valuer", "value": "Valuer" },
        { "text": "Vehicle Body Builder", "value": "Vehicle Body Builder" },
        { "text": "Vending Machine Serviceman", "value": "Vending Machine Serviceman" },
        { "text": "Venue Support Officer", "value": "Venue Support Officer" },
        { "text": "Veterinarian\Domestic Pets", "value": "Veterinarian\Domestic Pets" },
        { "text": "Veterinarian\Other", "value": "Veterinarian\Other" },
        { "text": "Vigneron", "value": "Vigneron" },
        { "text": "Waiters/Barstaff", "value": "Waiters/Barstaff" },
        { "text": "Wall & Floor Tiler", "value": "Wall & Floor Tiler" },
        { "text": "Wallpaper Hanger", "value": "Wallpaper Hanger" },
        { "text": "Wardsperson", "value": "Wardsperson" },
        { "text": "Warehouse Manager", "value": "Warehouse Manager" },
        { "text": "Warehouse Operator", "value": "Warehouse Operator" },
        { "text": "Warranty Technician", "value": "Warranty Technician" },
        { "text": "Washing Machine Mechanic", "value": "Washing Machine Mechanic" },
        { "text": "Watchmaker And Repairs", "value": "Watchmaker And Repairs" },
        { "text": "Weaver", "value": "Weaver" },
        { "text": "Web Developer and Designer", "value": "Web Developer and Designer" },
        { "text": "Welder", "value": "Welder" },
        { "text": "Welfare Office/Youth Worker", "value": "Welfare Office/Youth Worker" },
        { "text": "Wharf/Waterside Worker", "value": "Wharf/Waterside Worker" },
        { "text": "Windfarming", "value": "Windfarming" },
        { "text": "Window Cleaner", "value": "Window Cleaner" },
        { "text": "Window Tinter", "value": "Window Tinter" },
        { "text": "Wine/Spirit Merchant", "value": "Wine/Spirit Merchant" },
        { "text": "Wool Broker", "value": "Wool Broker" },
        { "text": "Wool Buyer/Classer", "value": "Wool Buyer/Classer" },
        { "text": "Work Group Leader", "value": "Work Group Leader" },
        { "text": "Workers Compensation", "value": "Workers Compensation" },
        { "text": "Workplace Health & Safety Co_Ordinator", "value": "Workplace Health & Safety Co_Ordinator" },
        { "text": "X_ray Technician", "value": "X_ray Technician" },
        { "text": "Yard Hand", "value": "Yard Hand" },
        { "text": "Youth Worker", "value": "Youth Worker" },
        { "text": "Zoo/Wildlife Keeper/Attendant", "value": "Zoo/Wildlife Keeper/Attendant" },
        { "text": "Zoologist", "value": "Zoologist" },
    ],
    "occupation-keys": [
        "Aboriginal Cultural Advisor",
        "Aboriginal Health Service Worker",
        "Account Manager",
        "Accountant",
        "Accountant Assistant",
        "Accounts Clerk/Bookkeeper",
        "Actuarial Student",
        "Acupuncturist",
        "Administration",
        "Advertising Agent",
        "Advertising:Executive",
        "Aeronautical Engineer",
        "Aged Care Worker",
        "Agricultural Contractor",
        "Air Traffic Controller",
        "Airconditioning Technician/Installer",
        "Aircraft Mechanic",
        "Airforce",
        "Airline Staff:Pilot/Crew",
        "Airline Staff:Office Worker",
        "Airline Staff:Porters/Baggage Handlers",
        "Ambulance Driver",
        "Anaesthetist",
        "Antique Dealer",
        "Apiarist/Beekeeper",
        "Applied Engineering",
        "Apprentice",
        "Arborist",
        "Archaeologist",
        "Architect",
        "Armed Forces",
        "Army",
        "Artist",
        "Asbestos Industry",
        "Asphalt Layer",
        "Assembly Line Worker",
        "Assistant Manager",
        "Assistant Principal",
        "Astronomer",
        "Attendant",
        "Auctioneer",
        "Audiologist",
        "Auditor",
        "Author",
        "Auto Machanic",
        "Autoelectrician",
        "Backhoe/Bobcat/Bulldozer Operator",
        "Backlanding",
        "Baker",
        "Bank Teller",
        "Banks/Building Society/Credit Union:Clerks And Tellers",
        "Bar Tender",
        "Barista",
        "Barrister",
        "Beach Patrol:Lifeguard Professional",
        "Beautician",
        "Bicycle Storekeeper:Repairs",
        "Biochemist",
        "Biologist",
        "Blacksmith/Farrier",
        "Blaster/Explosive Handler",
        "Blind/Awning Or Screen Installer",
        "Boarding Coordinator",
        "Boat Builder",
        "Boilermaker/Welder",
        "Book Keeper",
        "Book_in Officer",
        "Bookmaker",
        "Botanist",
        "Bouncer/Security Staff",
        "Bread Vendor",
        "Bricklayer",
        "Bridge Builder",
        "Builder",
        "Building Industry:Heavy Equipment Operator",
        "Building Labourer",
        "Building Supply Store:Shop Worker",
        "Bus Driver",
        "Business Analyst",
        "Business Consultant",
        "Business Development Manager",
        "Butcher",
        "Buyer Retail Store",
        "Cabinet Maker",
        "Cable Maker/Wire Maker",
        "Cafe/Coffee Lounge Proprietor",
        "Call Centre Operator",
        "Car Salesperson",
        "Caravan Park Owner",
        "Carbon Recycler",
        "Cardiac Technologist",
        "Carer",
        "Carers Assistant",
        "Caretaker",
        "Carpenter",
        "Carpet Cleaner",
        "Carpet/Linoleum Layer",
        "Cartage Co_Ordinator",
        "Case Manager",
        "Cashier:Financial Institution",
        "Cashier:Shop",
        "Caterer",
        "Catering and Event Assistant",
        "Cement Manufacturer",
        "Chauffeur",
        "Chef / Cook",
        "Chemical Industry:Chemist/Pharmacist",
        "Chemical Operator",
        "Chemical Service Tech",
        "Chemist/Pharmacist _ Retail",
        "Chicken Catcher",
        "Child Care Coordinator",
        "Child Care Worker",
        "Child Protection Officer",
        "Chiropodist/Podiatrist",
        "Chiropractor",
        "Civil Engineer",
        "Claims Officer",
        "Cleaner",
        "Clergy/Minister",
        "Clerical",
        "Clothing/Textile Industry:Process Worker/Machinist",
        "Club Worker:Bar Person",
        "Coal Miner",
        "Coating Technician",
        "Collections Manager",
        "Collections Officer",
        "Commercial Traveller",
        "Commodity Broker",
        "Communication Technician",
        "Communications Officer",
        "Communications Sailor",
        "Community Care Worker",
        "Company Secretary",
        "Composer",
        "Computer Industry",
        "Computer Technician",
        "Concrete Contractor/Labourer",
        "Console Operator",
        "Construction",
        "Consultant",
        "Contractor",
        "Control Room Operator",
        "Conveyor Belt Technician",
        "Coordinators",
        "Coroner",
        "Council Worker",
        "Counsellor",
        "Counter Sales",
        "Courier",
        "Court Reporter",
        "Crane Operator",
        "Credit Manager",
        "Credit Officer",
        "Crew Trainer",
        "Crossing Guard",
        "Cultural Heritage Officer",
        "Curator:Groundsman",
        "Curtain Installer",
        "Customer Service Area Manager",
        "Customer Service Officer",
        "Customs Agent _ Clerical",
        "Customs Officer",
        "Dairy Farmer",
        "Dairy Industry:Farm Proprietor",
        "Dairy Technician",
        "Dance Instructor",
        "Data Entry",
        "Debt Collector",
        "Decorator",
        "Delicatessen",
        "Delivery Driver",
        "Demolition Worker",
        "Dental Assistant",
        "Dental Nurse",
        "Dental Receptionist",
        "Dentist",
        "Department Store:Manager & Clerical Only",
        "Department Store:Sales With Some Light Manual (No Deliveries)",
        "Department Store:Storeperson",
        "Desktop Publisher",
        "Despatch",
        "Despatch Manager",
        "Detailer",
        "Developmental Care Worker",
        "Diemaker",
        "Diesel Mechanic",
        "Dietician",
        "Digital Developer",
        "Director",
        "Disability Support Officer",
        "Disc Jockey",
        "Diver",
        "Dockyard Worker",
        "Doctor",
        "Document Control",
        "Draftsperson",
        "Dressmaker",
        "Driller (Water/Oil/Mineral)",
        "Driver",
        "Dry Cleaning Industry:Other",
        "Duct Installer",
        "Economist",
        "Editor",
        "Education",
        "Electrician",
        "Electronic Equipment Installer",
        "Electronics Assembler",
        "Electroplater",
        "Employment Agent",
        "Employment Training Advisor",
        "Engineer",
        "Engraver",
        "Entertainer",
        "Estimator",
        "Events Manager",
        "Excavator Operator",
        "Executive Assistant",
        "Explosives Industry",
        "Export/Import (Clerical Only)",
        "Facilitator",
        "Factory Worker",
        "Farm Hand",
        "Farmer",
        "Farrier",
        "Fashion Designer",
        "Fast Food Takeaway",
        "Fencing Contractor",
        "Fibreglass Moulder",
        "Finance Adviser/Analyst Or Consultant:Tertiary Qualified",
        "Finance Broker",
        "Financial",
        "Firefighter",
        "First Mate",
        "Fisherman",
        "Fitness Centre:Instructor",
        "Fitness Centre:Owner/Operator",
        "Fitter And Turner",
        "Fleet Controller",
        "Fleet Driver",
        "Floor Coverer/Sander",
        "Florist",
        "Food and Beverage Attendant",
        "Food Technologist",
        "Foreman",
        "Forest Ranger/Warden",
        "Fork Lift Driver",
        "Foster Carer",
        "Foundry Worker",
        "Freight Forwarding",
        "French Polisher",
        "Fresh Produce Assistant",
        "Fruit Packer/Picker",
        "Fund Consultant",
        "Funeral Parlour:Director/Undertaker",
        "Funeral Parlour:Driver/Pallbearer",
        "Fur Cutter",
        "Furniture Restorer",
        "Galvaniser",
        "Gaming Attendant",
        "Garbage Collector",
        "Gardener",
        "Gas Fitter",
        "General Hand",
        "Geologist",
        "Glazier",
        "Goldsmith/Silversmith",
        "Golf Professional (Teaching And Pro Shop Only)",
        "Government",
        "Graphic Artist/Designer",
        "Ground Keeper",
        "Gunsmith",
        "Gymnasium:Instructor _ Full_Time",
        "Gynaecologist",
        "H.R. Coordinator",
        "Hairdresser",
        "Handyman",
        "Headmaster",
        "Health Inspector",
        "Health Worker",
        "Herbalist",
        "Heritage Officer",
        "Home Duties",
        "Home Maker",
        "Homeopathist",
        "Iridologist (Qualified)",
        "Horse Trainer",
        "Horticulturist",
        "Hospital Porter",
        "Hospitality Worker",
        "Hotel Manager",
        "Hotel Services",
        "House Cleaner",
        "Housing Officer",
        "Human Resources Consultant",
        "Hypnotherapist",
        "Implementation Lead",
        "Importer/Exporter",
        "Installer",
        "Instrument Manufacturer",
        "Insulation Installer",
        "Insurance Assessor/Loss Adjustor",
        "Integration Developer",
        "Interior Designer",
        "Interpreter",
        "Investment Advisor",
        "IT Consultant",
        "Jackaroo/Jillaroo",
        "Janitor",
        "Jeweller",
        "Jewellery Industry:Retail Store",
        "Jockey",
        "Journalist/Reporter",
        "Judge",
        "Juice Vendor",
        "Kennel Proprietor",
        "Kitchen Hand",
        "Laboratory Technician",
        "Labourer",
        "Land Broker",
        "Landscape Gardener",
        "Lapidary",
        "Laser Cutter",
        "Lathe Operator",
        "Laundry Staff",
        "Lawyer",
        "Leading Hand",
        "Lecturer",
        "Librarian",
        "Line Worker",
        "Liquor Assistant",
        "Loader",
        "Locksmith",
        "Machanic",
        "Machine Operator",
        "Machinery Equipment Hire & Service:Repair & Maintenance",
        "Machinery Equipment Hire & Service:Sales Only",
        "Mail Sorter",
        "Maintenance Worker",
        "Makeup Artist",
        "Management",
        "Management Consultant (Qualified)",
        "Manager",
        "Manager Administration",
        "Managing Director",
        "Manufacturing Specialist Supervisor",
        "Marina Owner",
        "Marketing/Sales Manager",
        "Massage Therapist",
        "Meat Industry:Boner",
        "Meat Industry:Inspector",
        "Meat Industry:Packer",
        "Meat Worker",
        "Mechanic",
        "Mechanical Fitter",
        "Medical Officer",
        "Medical Practitioner (Doctor)",
        "Medical Technician",
        "Medical Theatre Technician",
        "Merchandiser",
        "Metal Industry:Sheet Metal Worker",
        "Metal Industry:Supervisor",
        "Metallurgist:Other",
        "Meteorologist",
        "Meter Reader",
        "Midwife",
        "Milk Bar:Proprietor",
        "Mill Hand",
        "Miner",
        "Mining Industry",
        "Minister",
        "Model",
        "Mortician",
        "Motor Vehicle Industry:Assembly/Production Line",
        "Motor Vehicle Industry:Detailer",
        "Motor Vehicle Industry:Wrecker",
        "Music Teacher",
        "Musician",
        "Nail Technician",
        "Nanny",
        "Naturopath",
        "Navy Personnel:Seagoing",
        "Network Administrator",
        "Network Engineer",
        "Night Watchman",
        "NON TRADE (blue collar worker)",
        "Nurse",
        "Nursery Hand",
        "Nurses Aide",
        "Obstetrician",
        "Occupational Therapist",
        "Office Worker",
        "Office/Factory/School Cleaner",
        "Oil & Gas Industry",
        "Operational Support",
        "Operations Consultant",
        "Operations Manager",
        "Operative Miller",
        "Operator",
        "Ophthalmologist",
        "Optician",
        "Optometrist",
        "Order Selector",
        "Orthodontist",
        "Orthopaedic Surgeon",
        "Osteopath",
        "Other",
        "Oyster Farm Hand",
        "Packer",
        "Paediatrician",
        "Painter",
        "Painter And Decorator:Spray Painter",
        "Panel Beater",
        "Paramedic",
        "Park Ranger",
        "Parking Attendant",
        "Pastry Cook",
        "Pathologist",
        "Pathology Collector",
        "Patient Services",
        "Paver",
        "Pawnbroker",
        "Payroll Clerk",
        "Pensioner",
        "Personal Assistant",
        "Personal Carer",
        "Personnel Consultant",
        "Pest Exterminator/Fumigator",
        "Pharmacist",
        "Photographer",
        "Physician",
        "Physicist",
        "Physiotherapist",
        "Piano Tuner",
        "Picker",
        "Picture Framer",
        "Pilot",
        "Pipe Lagger",
        "Plant Operator",
        "Plasterer",
        "Plumber",
        "Police",
        "Politician",
        "Pool Technician",
        "Post Delivery Officer",
        "Post Placement Officer",
        "Postman",
        "Pottery And Ceramics:Potter",
        "Poultry Farmer/Poulterer",
        "Powder Coater",
        "Priest",
        "Printer",
        "Prison Officer",
        "Private Detective",
        "Process Worker",
        "Production Assistant",
        "Professional",
        "Professional Sportsperson",
        "Professor",
        "Project Manager",
        "Project Scheduler",
        "Project Support Officer",
        "Property Developer/Consultant",
        "Property Manager",
        "Psychiatric Nurse",
        "Psychiatrist",
        "Psychologist",
        "Public Relation Officer",
        "Public Servant",
        "Purchasing Manager",
        "Purchasing Officer",
        "QA Officer",
        "Quality Control",
        "Quantity Surveyor",
        "Radiographer/Radiotherapist",
        "Radiologist",
        "Railway Worker",
        "Ranger",
        "Real Estate Agent",
        "Receptionist",
        "Recreational Activity Officer",
        "Refrigerator Mechanic",
        "Registered Nurse",
        "Removalist",
        "Rendering Applicator",
        "Repair Person/Service Person",
        "Repossession Agent",
        "Researcher",
        "Restaurant:Bar Staff (Full Time)",
        "Restaurant:Kitchen Hand/Other",
        "Retail Assistant",
        "Retail Sales",
        "Reticulation Maintenence",
        "Retired",
        "Reverend",
        "Rigger",
        "Road Crew",
        "Roof Plumber",
        "Roof Tiler",
        "Sailmaker",
        "Sailor",
        "Sales Assistant",
        "Sales Manager",
        "Sales Person",
        "Sales Representative",
        "Sand Blaster",
        "Scaffolder",
        "Scheduling Support Officer",
        "Scientist",
        "Screen Printer",
        "Secretary",
        "Security Guard:Armed",
        "Security Guard:Unarmed",
        "Self Employed",
        "Senior Claims Officer",
        "Sergeant",
        "Service Manager",
        "Service Personnel",
        "Service Technician",
        "Settlement Manager",
        "Settlement Officer",
        "Shearer",
        "Shed Hand",
        "Shipmate",
        "Shoemaker And Repairs",
        "Shop Assistant",
        "Shopfitter",
        "Shower Screen Installer",
        "Signage Installer",
        "Signwriter",
        "Site Manager",
        "Skipper",
        "Slaughterman",
        "Social Worker",
        "Soil Technician",
        "Soldier",
        "Solicitor",
        "Sonographer",
        "Speech Therapist",
        "Sports Commissioning Trainer",
        "Spray Painter",
        "Stable Hand",
        "State Manager",
        "Station Hand",
        "Statistician",
        "Stay at home Mum/Dad",
        "Steel Erector And Rigger",
        "Stenographer",
        "Stevedore",
        "Steward",
        "Stockbroker",
        "Stonemason",
        "Store Team Member",
        "Storeman",
        "Storeperson/Packer",
        "Strapper",
        "Student",
        "Student Carer",
        "Stylist",
        "Superanuation Consultant",
        "Supervisor",
        "Support Worker",
        "Supported Residential Services Manager",
        "Surgeon",
        "Surveyor",
        "TAB Agent",
        "Tax Consultant",
        "Taxi Driver",
        "Teacher",
        "Teachers Assistant",
        "Team Leader",
        "Technical Support",
        "Technician",
        "Telemarketer",
        "Telephone Technician",
        "Telephonist/Switchboard Operator",
        "Theatre/Cinema Industry: Actor/Actress",
        "Tiler",
        "Timber Industry:Supervisor (Up To 10% Manual)",
        "Track Maintainer",
        "Trade",
        "Trade Assistant",
        "Traffic Controller",
        "Train Driver",
        "Training Manager",
        "Training Officer",
        "Travel Agent",
        "Trolley Collection",
        "Truck Driver",
        "Truck Jockey",
        "Tutor",
        "TV Programmer",
        "Typesetter",
        "Typewriter Mechanic",
        "Typist",
        "Tyre Fitter",
        "Unemployed",
        "Upholsterer",
        "Urologist",
        "Valuer",
        "Vehicle Body Builder",
        "Vending Machine Serviceman",
        "Venue Support Officer",
        "Veterinarian\Domestic Pets",
        "Veterinarian\Other",
        "Vigneron",
        "Waiters/Barstaff",
        "Wall & Floor Tiler",
        "Wallpaper Hanger",
        "Wardsperson",
        "Warehouse Manager",
        "Warehouse Operator",
        "Warranty Technician",
        "Washing Machine Mechanic",
        "Watchmaker And Repairs",
        "Weaver",
        "Web Developer and Designer",
        "Welder",
        "Welfare Office/Youth Worker",
        "Wharf/Waterside Worker",
        "Windfarming",
        "Window Cleaner",
        "Window Tinter",
        "Wine/Spirit Merchant",
        "Wool Broker",
        "Wool Buyer/Classer",
        "Work Group Leader",
        "Workers Compensation",
        "Workplace Health & Safety Co_Ordinator",
        "X_ray Technician",
        "Yard Hand",
        "Youth Worker",
        "Zoo/Wildlife Keeper/Attendant",
        "Zoologist",
    ],
    "industry": [
        { "text": "Accounting", "value": "Accounting" },
        { "text": "Agriculture and Non-petrol Natural Resource Extraction", "value": "Agriculture and Non-petrol Natural Resource Extraction" },
        { "text": "Broadcasting Printing and Publishing", "value": "Broadcasting Printing and Publishing" },
        { "text": "Brokers", "value": "Brokers" },
        { "text": "Building Supply Retail", "value": "Building Supply Retail" },
        { "text": "Business Services", "value": "Business Services" },
        { "text": "Consulting", "value": "Consulting" },
        { "text": "Consumer Services", "value": "Consumer Services" },
        { "text": "Design, Direction and Creative Management", "value": "Design, Direction and Creative Management" },
        { "text": "Distributors, Dispatchers and Processors", "value": "Distributors, Dispatchers and Processors" },
        { "text": "Durable Manufacturing", "value": "Durable Manufacturing" },
        { "text": "Eating and Drinking Places", "value": "Eating and Drinking Places" },
        { "text": "Entertainment Retail", "value": "Entertainment Retail" },
        { "text": "Equipment Rental and Leasing", "value": "Equipment Rental and Leasing" },
        { "text": "Financial", "value": "Financial" },
        { "text": "Food and Tobacco Processing", "value": "Food and Tobacco Processing" },
        { "text": "Inbound Capital Intensive Processing", "value": "Inbound Capital Intensive Processing" },
        { "text": "Inbound Repair Services", "value": "Inbound Repair Services" },
        { "text": "Insurance", "value": "Insurance" },
        { "text": "Legal Services", "value": "Legal Services" },
        { "text": "New Industry", "value": "New Industry" },
        { "text": "Non-Durable Merchandise Retail", "value": "Non-Durable Merchandise Retail" },
        { "text": "Outbound Consumer Services", "value": "Outbound Consumer Services" },
        { "text": "Petrochemical Extraction and Distribution", "value": "Petrochemical Extraction and Distribution" },
        { "text": "Service Retail", "value": "Service Retail" },
        { "text": "SIG Affiliations", "value": "SIG Affiliations" },
        { "text": "Social Services", "value": "Social Services" },
        { "text": "Special Outbound Trade Contractors", "value": "Special Outbound Trade Contractors" },
        { "text": "Specialty Realty", "value": "Specialty Realty" },
        { "text": "Transportation", "value": "Transportation" },
        { "text": "Utility Creation and Distribution", "value": "Utility Creation and Distribution" },
        { "text": "Vehicle Retail", "value": "Vehicle Retail" },
        { "text": "Wholesale", "value": "Wholesale" },
    ],
    "industry-keys": [
        "Accounting",
        "Agriculture and Non-petrol Natural Resource Extraction",
        "Broadcasting Printing and Publishing",
        "Brokers",
        "Building Supply Retail",
        "Business Services",
        "Consulting",
        "Consumer Services",
        "Design, Direction and Creative Management",
        "Distributors, Dispatchers and Processors",
        "Durable Manufacturing",
        "Eating and Drinking Places",
        "Entertainment Retail",
        "Equipment Rental and Leasing",
        "Financial",
        "Food and Tobacco Processing",
        "Inbound Capital Intensive Processing",
        "Inbound Repair Services",
        "Insurance",
        "Legal Services",
        "New Industry",
        "Non-Durable Merchandise Retail",
        "Outbound Consumer Services",
        "Petrochemical Extraction and Distribution",
        "Service Retail",
        "SIG Affiliations",
        "Social Services",
        "Special Outbound Trade Contractors",
        "Specialty Realty",
        "Transportation",
        "Utility Creation and Distribution",
        "Vehicle Retail",
        "Wholesale",
    ]
}

