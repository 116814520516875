<template>
    <v-card :disabled="isDisabled" tile flat class="pa-4 mx-auto" max-width="854">
        <v-card-text class="pa-4 pt-0 pa-md-10 pt-md-0">
            <v-container pa-0 fluid>
                <v-row>
                    <v-col cols="12" class="pa-0 ma-0 mb-4">
                        <ValidationObserver ref="observer" tag="form">

                            <div v-show="false" class="flex-form">
                                <div
                                    v-for="(field, fkey) in fields"
                                    :key="fkey" 
                                    class="flex-field">

                                    <ValidationProvider
                                        v-slot="{ errors }"
                                        :name="field.meta | formalText"
                                        rules="required"
                                        :vid="field.meta">
                                        <v-text-field
                                            v-model="resourceObj[field.meta]"
                                            :error-messages="errors"
                                            :label="field.label">
                                        </v-text-field>
                                    </ValidationProvider>

                                </div>
                            </div>

                            <QuoteTable
                                v-if="validComponentData"
                                :lenders="lenders"
                                :headers="headers"
                                :disabled="isDisabled"
                                :selected-lender="resourceObj['selected-lender-quote-algorithm-slug']"
                                :settings="settings"
                                :required-amount="required_amount"
                                :hasLenders="validComponentData"
                                @selectLender="setSelected"
                                @setSelect="setSelect"
                                @continue="handleContinue" />

                        </ValidationObserver>
                    </v-col>
                </v-row>
            </v-container>
        </v-card-text>

        <!-- tools -->
        <Tools>
            <template v-slot:tools>
                <div class="d-flex justify-center mx-auto">

                    <CompareLenders
                        :selected-lenders="selectedLenders"
                        :selected-lender="resourceObj['selected-lender-quote-algorithm-slug']"
                        :selected-count="selectedCount"
                        :hide-lender-identity="hideLenderIndentity"
                        :required-amount="required_amount"
                        :disabled="isDisabled" />

                    <RequestCallBack class="mx-2" @save="pull"/>

                </div>
            </template>
        </Tools>
        
    </v-card>
</template>

<script>
    import subscriptions from '~/components/shared/subscriptions'
    import includedComponents from '~/components/v1/QuoteResult3/includes'
    import { mapActions, mapMutations } from 'vuex'
    import { isEmpty } from 'lodash'
    
    export default {
        mixins: [subscriptions],
        
        name: 'QuoteResult3',

        components: { ...includedComponents },

        computed: {
            vb(){
                return this.$vuetify.breakpoint
            },
            selectedLenders(){

                let lenders = []


                this.lenders.forEach( lender => {

                    if( this.selected.includes(lender.name)) {

                        lenders.push(lender)
                    }

                })

                return lenders

            },
            selectedCount(){

                return this.selected.length

            },
            hideLenderIndentity(){
                return this.settings.hideLenderNames == 1
            },
        },

        data() {
            return {
                required_amount: '',
                lenders: [],
                selected: [],
                validComponentData: true,
                quotes: {},
                resources: {                        
                    "type": "loans",
                    "id": null,
                    "attributes": {
                        "selected-lender-quote-algorithm-slug": null
                    },
                    "relationships": {
                        "application": {
                            "data": {
                                "type": "applications",
                                "id": null
                            },
                        }
                    }
                },
                resourceObj: {},
                fields: {
                    'selected-lender-quote-algorithm-slug': { label: 'Lender', meta: 'selected-lender-quote-algorithm-slug' }
                },
                headers: [
                    { text: '', sortable: false, value: 'compare', width: 'auto'},
                    { text: 'Lender', sortable: false, value: 'lender', width: '15%'},
                    { text: 'Repayments', sortable: false, value: 'repayments', width: '20%'},
                    { text: 'Loan Term', sortable: false, value: 'loan-term', width: '20%'},
                    { text: 'Comparison Rate', sortable: false, value: 'comparison-rate', width: '20%'},
                    { text: 'Customer Rate', sortable: false, value: 'customer-rate', width: '20%'},
                    { text: '', sortable: false, value: 'action'}
                ],
                settings: {
                    hideLenderNames: 0,
                    customerDiscountRate: 0,
                    originationFee: 1000
                },
                productAlias: [ 'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'C' ]
            }
        },

        mounted() {
        },

        methods: {
            ...mapActions('flows', ['selectNextStep']),
            ...mapActions('resource-actions', [
                'getQuotes',
                'updateLoan',
                'setApplicationNow'
            ]),
            ...mapMutations('subscriptions', [
                'setStepableDataWorkable'
            ]),

            async pull() {

                // Update Loan first with user testing values before getting the getQuotes
                
                if (this.getLoanId) {

                    let payload = {
                        type: 'loans',
                        id: this.getLoanId,
                        attributes: {
                            "customer-discount-rate": this.settings.customerDiscountRate,
                            "requested-broker-fee": this.settings.originationFee
                        }
                    }

                    await this.updateLoan(payload)
                }

                let response = await this.getQuotes({                    
                    'type': 'applications',
                    'id': this.appId,
                    'attributes': {}
                })

                this.quotes = {}
                this.lenders = []

                if ( response.length > 1 ) {

                    response.forEach( res => {
                        if (res?.data?.data?.type === 'applications') {

                            let list = res?.data?.included?.[0]?.attributes?.results ? res?.data.included[0].attributes.results : {}
                            
                            if ( !isEmpty(list) ) 
                                list = JSON.parse( list )

                            this.quotes = list?.lenders
                            this.required_amount = list?.['required-amount']

                        } else {
                            this.resources = res.data.data
                            if (this.resources)
                                this.resources.relationships = this.removeEmptyRelationships(this.resources?.relationships)
                        }
                    })
                }

                if (this.quotes)
                    Object.keys(this.quotes).forEach( (lender, index) => {
                        // check if it is quotable
                        if(this.quotes[lender]['quotable'] == 'Yes') {
                            // check if lenders has value
                            if(this.lenders.length > 0) {
                                this.lenders[index] = this.quotes[lender]
                                this.lenders[index]['slug'] = lender
                                this.lenders[index]['alias'] = `Product ${this.productAlias[index]}`
                            } else {
                                this.lenders.push(this.quotes[lender])
                                this.lenders[index]['slug'] = lender
                                this.lenders[index]['alias'] = `Product ${this.productAlias[index]}`
                            }   
                        }
                    })

                this.resourceObj = { 
                    'selected-lender-quote-algorithm-slug': this.resources.attributes['selected-lender-quote-algorithm-slug']
                }

                // console.log(this.lenders)
                this.validComponentData = this.lenders.length > 0 ? true : false

            },

            async push() {

                let requestPayload = Object.assign({}, this.resources)

                let res = await this.setApplicationNow({
                    payload: requestPayload,
                    picked: this.resourceObj['selected-lender-quote-algorithm-slug']
                })
                
                const {valid, status} = await this.validateFormData()
                let data = this.$options.filters.toAppDataFormat(res)

                return  { valid, status, data } 
            },

            setSelected(name = '', lender = {}) {

                this.resourceObj = { 'selected-lender-quote-algorithm-slug': lender.slug }

                this.populateLoanFields(lender)

                this.selectNextStep()

            },

            setSelect(selected) {
                this.selected = selected
            },

            handleContinue(){

                this.resourceObj = { 'selected-lender-quote-algorithm-slug': "No options available" }
                this.selectNextStep()
                
            },

            removeEmptyRelationships(relationships = {}) {
                
                let temp = {}

                Object.keys(relationships).forEach( key => {
                    if (relationships[key].data?.length > 0 || relationships[key].data?.id !== undefined)
                        temp[key] = relationships[key]
                })

                return temp
            },

            populateLoanFields(item) {

                this.resources.attributes['quoted-balloon'] = item['actual-payment']['balloon']
                this.resources.attributes['quoted-bank-fees'] = item['actual-payment']['bank-fees']
                this.resources.attributes['quoted-base-rate'] = item['actual-payment']['base-rate']
                this.resources.attributes['quoted-broker-commission-inc-gst'] = item['actual-payment']['commission-inc-gst']
                this.resources.attributes['quoted-broker-fee'] = item['actual-payment']['broker-fee']
                this.resources.attributes['quoted-customer-rate'] = item['actual-payment']['customer-rate']
                this.resources.attributes['quoted-monthly-payments'] = item['actual-payment']['monthly-payment']
                this.resources.attributes['quoted-naf'] = item['actual-payment']['naf']
                this.resources.attributes['quoted-account-fee'] = item['actual-payment']['account-fee']
                this.resources.attributes['quoted-term-months'] = item['actual-payment']['term-in-months']
                this.resources.attributes['selected-lender-quote-algorithm-slug'] = item.slug

            },

        }

    }
</script>

