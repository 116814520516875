<template>
    <v-footer
        dark
        padless>
        <v-card
            flat
            width="100%"
            tile
            class="white white--text text-center">

            <v-divider></v-divider>

            <v-card-text class="white--text">
                <v-rating
                    v-model="step"
                    :length="stepLength"
                    color="yellow darken-3"
                    background-color="grey darken-1"
                    empty-icon="$ratingFull"
                    clearable
                    >
                    <template v-slot:item="props">
                        <v-icon
                            @click="props.click"
                            :color="props.isFilled ? 'secondary' : 'light_gray_1'"
                            >mdi-circle</v-icon>
                    </template>
                </v-rating>
            </v-card-text>
        </v-card>
    </v-footer>
</template>

<script>
    export default {
        name: 'StepIndicator',

        model: {
            prop: 'modelValue',
            event: 'input'
        },

        props: {
            modelValue: {
                type: [String, Number],
                default: ''
            },
            stepLength: {
                type: Number,
                default: 3
            },
            color: {
                type: String,
                default: 'secondary'
            },
        },

        watch: {
            'modelValue': {
                handler(val) {
                    this.step = val
                },
                deep: true
            }
        },

        data() {
            return {
                step: 1
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>