import Vue from "vue";

export const store = Vue.observable({
  steps: [],

  carousel: 0,
});

export const actions = {
  setCarousel(payload) {
    store.carousel = payload;
  },

  resetStore() {
    store.carousel = 0;
  },

  setCarouselNext() {
    store.carousel++;
  },
  setCarouselBack() {
    store.carousel--;
  },

  setSteps(payload) {
    store.steps = payload
  },
};
