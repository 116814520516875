
export default async (context) => {

    if(!context.$auth.loggedIn && !context.store.state.hasAuthToken) {

        try {

            const guest = await context.app.$axios.post(`oauth/token`)

            context.$api.setHeader('Authorization', 'Bearer ' + guest.data.access_token)
            context.store.commit('setHasAuthToken', true)
            

        } catch (error) {
            
            // console.log('Uh oh!', error)

            let message = 'Forbidden'

            context.error({
                message,
                statusCode: 403
            })

        }

    }


}

