export default {
    "12-1": [ // "Income"
        "12-28",  // "PAYG Employment"
        "12-29",  // "Self-Employed"
        "12-17",  // "Investment Income"
        "12-2",  // "Centrelink"
        "12-15",  // "Child Support"
        "12-24",  // "Other Income"
        "12-23"  // "No Income"
    ],
    "12-17": [ // "Investment Income"
        "12-21",  // "Rental Income"
        "12-22",  // "Superannuation"
        "12-18",  // "Dividends"
        "12-20",  // "Private Pension"
        "12-19"  // "Other Investment"
    ],
    "000000": [ // 00000000
        "000000",  // 0000000
        "000000",  // 0000000
        "000000",  // 0000000
    ],
    "000000": [ // 00000000
        "000000",  // 0000000
        "000000",  // 0000000
        "000000",  // 0000000
    ],
    "000000": [ // 00000000
        "000000",  // 0000000
        "000000",  // 0000000
        "000000",  // 0000000
    ],
}