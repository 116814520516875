<template>
    <v-stepper :value="parseInt(current)" class="flex-progress-indicator">
        <v-stepper-header>

            <v-stepper-step
                v-for="n in parseInt(steps)"
                :key="n"
                :color="color"
                :step="n"
                :complete="true">
            </v-stepper-step>

        </v-stepper-header>
    </v-stepper>
</template>

<script>

    export default {
        props: {
            current: {
                type: [String, Number],
                default: 0
            },
            steps: {
                type: [String, Number],
                default: 4
            },
            color: {
                type: String,
                default: 'secondary'
            },
        },
        data(){
            return {
            }
        },
        computed: {

        },
        mounted(){

        }
    }
</script>

<style lang="scss">

    .flex-progress-indicator {

        .v-stepper__header {
            height: 44px !important;
        }

        .v-stepper__step {
            padding: 0;

            .v-icon {
                display: none;
            }

            .v-stepper__step__step {
                height: 14px;
                width: 14px;
                min-width: 0;
                margin: 0 6px;
                padding: 0;
                border: none;
            }
        }

        .v-stepper__step--inactive.v-stepper__step--complete .v-stepper__step__step {
            border-color: var(--v-light_gray_1-darken1) !important;
            background: var(--v-light_gray_1-darken1) !important;
        }

    }
</style>
