import { mapState, mapActions, mapGetters, mapMutations } from 'vuex'

export default {  
    props: {
        data: {
            type: Object,
            default: null
        },
        // person / business
        entity: {
            type: Object,
            default: null
        },
        occupancies: {
            type: Array,
            default: () => []
        },
    },
    computed: {
        occupancySituation(){
            return this.resources.occupancies.attributes['occupancy-situation']
        },
        filteredOccupancies(){

            const {occupancies, entity} = this
            let data =  []
            
            if(entity){
                data = occupancies.filter(occupancy => {
                    return occupancy.relationships.person.data.id == entity.id
                })
            }

            return data
        },
        hasCurrentOccupancy(){

            const {filteredOccupancies} = this
            let current = null
            
            filteredOccupancies.forEach(occupancy => {
                if(occupancy.attributes["occupancy-situation"] == "Current"){
                    current = occupancy
                }
            })

            return current
        },
        getMostRecentOccupancyByDate(){

            // check for recent end-date

            const {filteredOccupancies} = this

            let comparee = null

            filteredOccupancies.forEach(occupancy => {

                if( occupancy.attributes["end-date"] ) {

                    if(comparee == null){

                        comparee = occupancy

                    } else {

                        let date1 = comparee.attributes["end-date"]
                        let date2 = occupancy.attributes["end-date"]

                        if( this.$options.filters.compareDateBy({date: date1, comparee: date2, type: 'after'}) ){
                            comparee = occupancy
                        }

                    }

                }

            })
            
            return comparee 

        },
        getMostPreviousOccupancyByDate(){

            // check for oldest start-date

            const {filteredOccupancies} = this

            let comparee = null

            filteredOccupancies.forEach(occupancy => {

                if(comparee == null){

                    comparee = occupancy

                } else {

                    let date1 = comparee.attributes["start-date"]
                    let date2 = occupancy.attributes["start-date"]

                    if( this.$options.filters.compareDateBy({date: date1, comparee: date2}) ){
                        comparee = occupancy
                    }

                }

            })
            
            return comparee 

        },

    },
    methods: {
        isVisible(key){
                
            const {visible} = this

            if(visible.hasOwnProperty(key)){
                return visible[key]
            }

            return true

        },
    },
    watch: {
        occupancySituation(val){
            this.visible['end-date'] = (val != 'Current')
        }

    }
}