<template>
    <v-dialog
        persistent
        scrollable
        height="600"
        max-width="500"
        class="pa-4"
        v-model="dialog">
            <v-card flat :disabled="loading">
                <v-progress-linear color="secondary" indeterminate stream absolute top v-if="loading"></v-progress-linear>

                <v-card-title>
                    
                    <!-- <div class="d-flex font-size-32 font-weight-bold">
                        <v-icon color="warning" size="40" class="mr-4">mdi-alert-outline</v-icon>
                        <div>
                            {{title}}
                        </div>
                    </div> -->

                </v-card-title>

                <v-card-text class="body-1">
                    {{bodyMessage}}
                </v-card-text>

                <v-card-text>
                    <v-textarea
                        v-model="notes"
                        required
                        persistent-hint
                        rows="10"
                        auto-grow
                        label="Write your message here"
                        placeholder="Write your message here."></v-textarea>
                </v-card-text>

                <v-card-actions class="pa-4 ma-2">
                    <v-btn @click="closeModal" :min-width="140" large depressed outlined color="secondary">
                        Back
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn @click="saveFileNotes" :min-width="140" large depressed class="secondary">
                        Next
                    </v-btn>
                </v-card-actions>

            </v-card>
    </v-dialog>
</template>
<script>
    export default {

        props: {
            modal: {
                type: Boolean,
                default: false
            },
            loading: {
                type: Boolean,
                default: false
            },
            title: {
                type: String,
                default: 'Uh oh!'
            },
            bodyMessage: {
                type: String,
                default: ''
            }
        },

        data() {
            return {
                dialog: false,
                notes: ''
            }
        },

        methods: {

            closeModal() {
                this.$emit('close-modal')
            },

            saveFileNotes() {
                this.$emit('save-file-notes', this.notes)
            }

        },

        watch: {
            modal(val) {
                this.dialog = val
            }
        }
    }
</script>