<template lang="html">
    <v-overlay class="ma-0 pa-0" :value="loading" color="white" :opacity="opacity">
        <div class="spinner">      
            <div class="flex-brand-loader">
                <img class="flex-brand d-block" :src="require(`~/static/loader.svg`)" height="50"/>
            </div>
        </div>
    </v-overlay>
</template>

<script>
    export default {        
        props: {
            loading: {
                type: Boolean,
                default: false
            },
            opacity: {
                type: Number,
                default: .5
            }
        }
    }
</script>

<style lang="scss" scoped>
    .flex-brand-loader {
        -webkit-animation: round 1.7s infinite ease;
        -moz-animation: round 1.7s infinite ease;
        animation: round 1.7s infinite ease;
    }

    @-moz-keyframes spin { 100% { -moz-transform: rotate(360deg); } }
    @-webkit-keyframes spin { 100% { -webkit-transform: rotate(360deg); } }
    @keyframes spin { 100% { -webkit-transform: rotate(360deg); transform:rotate(360deg); } }
    
    @-webkit-keyframes sk-scaleout {
      0% { -webkit-transform: scale(0) }
      100% {
        -webkit-transform: scale(1.0);
        opacity: 0;
      }
    }
    
    @keyframes sk-scaleout {
      0% {
        -webkit-transform: scale(0);
        transform: scale(0);
      } 100% {
        -webkit-transform: scale(1.0);
        transform: scale(1.0);
        opacity: 0;
      }
    }
    @-webkit-keyframes round {
      0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
      }
      100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
      }
    }
    @keyframes round {
      0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
      }
      100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
      }
    }
</style>