<template>
    <h1>Range Slider</h1>
</template>

<script>
    
    import { mapActions, mapGetters, mapMutations } from 'vuex'

    export default {
        name: 'RangeSlider',
        mixins: [],
        components: {

        },
        data(){
            return {
                
            }
        },
        mounted() {

        },
        computed: {
            ...mapGetters('kinds', ['kinds', 'kindsUnformat']),
        },
        methods: {
            ...mapActions('resource-actions', [
            ]),
  

        }
    }
</script>

<style scoped lang="scss">

</style>
