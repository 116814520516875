<template>
    <v-card class="mx-auto px-8" flat>
        <div v-if="vb.mdAndUp" >
            <div class="d-flex">
                <v-sheet width="50%" color="light_gray_1" class="pa-4">
                    <div class="px-4 pt-4">
                        <v-img 
                            max-width="120"
                            class="flex-brand" 
                            :src="require(`~/assets/images/nodifi.svg`)" 
                            position="center left" 
                            contain>
                        </v-img>
                        <div class="mt-2 font-size-20">Data</div>
                    </div>

                    <v-card flat class="overflow-y-auto light_gray_1 mt-4">
                        <v-card-text class="d-flex">

                            <v-list class="pt-0 light_gray_1">

                                <v-list-item two-line class="px-0" v-for="(item, key) in nodifiData" :key="key">
                                    <v-list-item-content class="pt-0">
                                        <div class="mb-4">
                                            <v-list-item-title class="font-weight-medium">{{item.label}}</v-list-item-title>
                                            <v-list-item-subtitle v-if="key == '_rent-boarding-expense'">{{ fieldRentBoardingExpense }}</v-list-item-subtitle>
                                            <v-list-item-subtitle v-else>{{ livingExpense ? livingExpense.attributes[key] : '&mdash;' }}</v-list-item-subtitle>
                                        </div>
                                    </v-list-item-content>
                                </v-list-item>

                            </v-list>

                        </v-card-text>
                    </v-card>
                </v-sheet>


                <v-sheet width="50%" class="pa-4">
                    <div class="px-4">
                        <v-img 
                            max-width="120"
                            class="flex-brand" 
                            :src="require(`~/assets/images/logo/cropped/lender-logo-pepper.png`)" 
                            position="center left" 
                            contain>
                        </v-img>
                        <div class="mt-2 font-size-20">Requirements</div>
                    </div>
                    <v-card flat class="overflow-y-auto mt-4">
                        <v-card-text class="d-flex pa-0">
                            <div class="px-4" style="width: 400px;">
                                <ValidationObserver ref="observer" tag="form">
                                    <div class="mt-2 font-size-20 pb-5">Basic</div>
                                    <div v-for="(value, key) in layout.basic" :key="key">
                                        <ValidationProvider 
                                            v-slot="{ errors }"
                                            :name="labels[key]"
                                            :rules="getRules(key)" 
                                            :vid="key">
                                                <NodifiMoney 
                                                    :label="labels[key]"
                                                    color="secondary"
                                                    :errors="errors"
                                                    v-model="resources.expense.attributes[key]" />
                                        </ValidationProvider>
                                    </div>
                                    <div class="mt-2 font-size-20 pb-5">Other</div>
                                    <div v-for="(value, key) in layout.other" :key="key">
                                        <ValidationProvider 
                                            v-slot="{ errors }"
                                            :name="labels[key]"
                                            v-if="isIncluded(key)"
                                            :rules="getRules(key)" 
                                            :vid="key">

                                                <NodifiMoney 
                                                    v-if="key == '_rent-boarding-expense'"
                                                    :label="labels[key]"
                                                    color="secondary"
                                                    :errors="errors"
                                                    v-model="fieldRentBoardingExpense" />

                                                <NodifiMoney 
                                                    v-else-if="key == '_private-school-fees'"
                                                    :label="labels[key]"
                                                    color="secondary"
                                                    :errors="errors"
                                                    v-model="fieldPrivateSchoolFees" />

                                                <NodifiMoney 
                                                    v-else-if="key == '_hecs-help'"
                                                    :label="labels[key]"
                                                    color="secondary"
                                                    :errors="errors"
                                                    v-model="fieldHecsHelp" />

                                                <NodifiMoney 
                                                    v-else-if="key == '_buy-now-pay-later'"
                                                    :label="labels[key]"
                                                    color="secondary"
                                                    :errors="errors"
                                                    v-model="fieldBuyNowPayLater" />

                                                <NodifiMoney 
                                                    v-else
                                                    :label="labels[key]"
                                                    color="secondary"
                                                    :errors="errors"
                                                    v-model="resources.expense.attributes[key]" />

                                        </ValidationProvider>
                                    </div>
                                </ValidationObserver>
                            </div>
                        </v-card-text>
                    </v-card>
                </v-sheet>
            </div>

        </div>
        
        <!-- mobile version -->
        <div v-else>
            <v-card flat class="mx-5 rounded-xl" :style="`background-color: ${hexToRGBA(getActiveTheme.colors.secondary, 0.2)}`">
                <v-card-text class="d-flex py-1">
                    <v-row>
                        <v-col cols="6" class="pa-1">
                            <v-card
                                min-height="45"
                                flat class="rounded-xl px-1"
                                :color="tabSelection == 'nodifi' ? 'white' : 'transparent'">
                                <div class="d-flex flex-column" @click="selectTab('nodifi')">
                                    <v-img 
                                        max-width="55"
                                        class="flex-brand mx-auto mt-1" 
                                        :src="require(`~/assets/images/nodifi.svg`)" 
                                        position="center left" 
                                        contain>
                                    </v-img>
                                    <div class="font-size-12  mx-auto mt-n1 ">Data</div>
                                </div>
                            </v-card>
                        </v-col>
                        <v-col cols="6" class="pa-1">
                            <v-card
                                min-height="45"
                                flat class="rounded-xl px-1"
                                :color="tabSelection == 'pepper' ? 'white' : 'transparent'">
                                <div class="d-flex flex-column" @click="selectTab('pepper')">
                                    <v-img 
                                        max-width="55"
                                        class="flex-brand mx-auto mt-1" 
                                        :src="require(`~/assets/images/logo/cropped/lender-logo-pepper.png`)" 
                                        position="center left" 
                                        contain>
                                    </v-img>
                                    <div class="font-size-12  mx-auto mt-n1">Requirements</div>
                                </div>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>

            <v-list  v-show="tabSelection == 'nodifi'" class="mx-8" color="transparent">
                <v-list-item two-line class="px-0" v-for="(item, key) in nodifiData" :key="key">
                    <v-list-item-content class="pt-0">
                        <v-list-item-title class="font-weight-medium">{{item.label}}</v-list-item-title>
                        <v-list-item-subtitle v-if="key == '_rent-boarding-expense'">{{ fieldRentBoardingExpense }}</v-list-item-subtitle>
                        <v-list-item-subtitle v-else>{{ livingExpense ? livingExpense.attributes[key] : '&mdash;' }}</v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>
            </v-list>

            <div v-show="tabSelection != 'nodifi'" class="mx-8">
                <ValidationObserver ref="observer" tag="form">
                    <div class="mt-2 font-size-20 pb-5">Basic</div>
                    <div v-for="(value, key) in layout.basic" :key="key">
                        <ValidationProvider 
                            v-slot="{ errors }"
                            :name="labels[key]"
                            :rules="getRules(key)" 
                            :vid="key">
                                <NodifiMoney 
                                    :label="labels[key]"
                                    color="secondary"
                                    :errors="errors"
                                    v-model="resources.expense.attributes[key]" />
                        </ValidationProvider>
                    </div>
                    <div class="mt-2 font-size-20 pb-5">Other</div>
                    <div v-for="(value, key) in layout.other" :key="key">
                        <ValidationProvider 
                            v-slot="{ errors }"
                            :name="labels[key]"
                            v-if="isIncluded(key)"
                            :rules="getRules(key)" 
                            :vid="key">

                                <NodifiMoney 
                                    v-if="key == '_rent-boarding-expense'"
                                    :label="labels[key]"
                                    color="secondary"
                                    :errors="errors"
                                    v-model="fieldRentBoardingExpense" />

                                <NodifiMoney 
                                    v-else-if="key == '_private-school-fees'"
                                    :label="labels[key]"
                                    color="secondary"
                                    :errors="errors"
                                    v-model="fieldPrivateSchoolFees" />

                                <NodifiMoney 
                                    v-else-if="key == '_hecs-help'"
                                    :label="labels[key]"
                                    color="secondary"
                                    :errors="errors"
                                    v-model="fieldHecsHelp" />

                                <NodifiMoney 
                                    v-else-if="key == '_buy-now-pay-later'"
                                    :label="labels[key]"
                                    color="secondary"
                                    :errors="errors"
                                    v-model="fieldBuyNowPayLater" />

                                <NodifiMoney 
                                    v-else
                                    :label="labels[key]"
                                    color="secondary"
                                    :errors="errors"
                                    v-model="resources.expense.attributes[key]" />

                        </ValidationProvider>
                    </div>
                </ValidationObserver>
            </div>

        </div>
    </v-card>
</template>

<script>
    import { mapActions, mapState, mapMutations, mapGetters } from 'vuex'
    import subscriptions from '~/components/shared/subscriptions'
    import NodifiMoney from '~/components/base/NodifiMoney'
    export default {
        components: { NodifiMoney },
        mixins: [subscriptions],
        data() {
            return {
                tabSelection: "nodifi",
                expenses: [],

                // Conditional Fields
                fieldRentBoardingExpense: 0,
                fieldPrivateSchoolFees: 0,
                fieldHecsHelp: 0,
                fieldBuyNowPayLater: 0,

                resources: {
                    expense: {
                        attributes : {
                            "lender-pepper-living-basic": null,
                            "lender-pepper-living-clothing": null,
                            "lender-pepper-living-utilities": null,
                            "lender-pepper-living-home-insurance": null,
                            "lender-pepper-living-vehicle-insurance": null,
                            "lender-pepper-living-travel": null,
                            "living-tv-and-communications": null,
                            "living-child-care-fees": null,

                            "lender-pepper-living-additional-vehicle": null,
                            "lender-pepper-living-life-insurance": null,
                            "lender-pepper-living-medical": null,
                            "lender-pepper-living-owner-occupied": null,
                            "lender-pepper-living-investment": null,
                            "living-family-maintenance": null,
                            "lender-pepper-living-household": null,
                        }
                    }
                },
                nodifiData: {
                    "living-food-and-clothing": {
                        label: "Food and Clothing"
                    },
                    "living-transport": {
                        label: "Transport"
                    },
                    "living-utilities-and-rates": {
                        label: "Utilities and Rates"
                    },
                    "living-lifestyle": {
                        label: "Lifestyle"
                    },
                    "living-tv-and-communications": {
                        label: "TV and Communications"
                    },
                    "living-child-care-fees": {
                        label: "Child Care Fees"
                    },
                    "living-family-maintenance": {
                        label: "Family Maintenance"
                    },
                    "living-insurance": {
                        label: "Insurance"
                    },
                    "living-education": {
                        label: "Education"
                    },
                    "living-other-living-expenses": {
                        label: "Other Living Expenses"
                    },
                    "_rent-boarding-expense": {
                        label: "Rent"
                    }
                },
                labels: {
                    "lender-pepper-living-basic": "Groceries & Food (including takeaway)",
                    "lender-pepper-living-clothing": "Clothing for Child and Adults",
                    "lender-pepper-living-utilities": "Utilities",
                    "lender-pepper-living-home-insurance": "Home & Contents Insurance",
                    "lender-pepper-living-vehicle-insurance": "Vehicle Insurance / Child Maintenance | Alimony Payments",
                    "lender-pepper-living-travel": "Travel Costs (Petrol, Parking Public Transport)",
                    "living-tv-and-communications": "Mobile Phone / Internet / Pay TV",
                    "living-child-care-fees": "Childcare / Public School Fees",

                    // OTHER
                    "_rent-boarding-expense": "Rent / Boarding Expense", 
                    "lender-pepper-living-additional-vehicle": "Expenses Assoicated with Additonal Motor Vehicles",
                    "_private-school-fees": "Private School Fees",  
                    "lender-pepper-living-life-insurance": "Life Insurance",
                    "lender-pepper-living-medical": "Health Insurance and Medical Expenses",
                    "lender-pepper-living-owner-occupied": "Expenses for Owner Occupied Dwelling (rates, body corporate)", 
                    "lender-pepper-living-investment": "Expenses for Investment Property (rates, body corporate, land tax)", 
                    "living-family-maintenance": "Child Maintenance / Alimony Payments",
                    "lender-pepper-living-household": "Household Items / Expenses",
                     
                    "_hecs-help": "HECs/HELP", 
                    "_buy-now-pay-later": "Buy Now Pay Later (eg After Pay)",

                },
                layout: {
                    basic: {
                        "lender-pepper-living-basic": null,
                        "lender-pepper-living-clothing": null,
                        "lender-pepper-living-utilities": null,
                        "lender-pepper-living-home-insurance": null,
                        "lender-pepper-living-vehicle-insurance": null,
                        "lender-pepper-living-travel": null,
                        "living-tv-and-communications": null,
                        "living-child-care-fees": null,
                    },
                    other: {
                        "_rent-boarding-expense": null,
                        "lender-pepper-living-additional-vehicle": null,
                        "_private-school-fees": null, 
                        "lender-pepper-living-life-insurance": null,
                        "lender-pepper-living-medical": null,
                        "lender-pepper-living-owner-occupied": null,
                        "lender-pepper-living-investment": null,
                        "living-family-maintenance": null,
                        "lender-pepper-living-household": null,
                        "_hecs-help": null,
                        "_buy-now-pay-later": null
                    }
                },
                included: {
                    "_rent-boarding-expense": false,
                    "_hecs-help": false,
                    "_buy-now-pay-later": false
                },
                validation_rules: {
                    attributes: {
                        "lender-pepper-living-basic": "required|between:0,9999999",
                        "lender-pepper-living-clothing": "required|between:0,9999999",
                        "lender-pepper-living-utilities": "required|between:0,9999999",
                        "lender-pepper-living-home-insurance": "required|between:0,9999999",
                        "lender-pepper-living-vehicle-insurance": "required|between:0,9999999",
                        "lender-pepper-living-travel": "required|between:0,9999999",
                        "living-tv-and-communications": "required|between:0,9999999",
                        "living-child-care-fees": "required|between:0,9999999",
                        
                        "lender-pepper-living-additional-vehicle": "required|between:0,9999999",
                        "lender-pepper-living-life-insurance": "required|between:0,9999999",
                        "lender-pepper-living-medical": "required|between:0,9999999",
                        "lender-pepper-living-owner-occupied": "required|between:0,9999999",
                        "lender-pepper-living-investment": "required|between:0,9999999",
                        "living-family-maintenance": "required|between:0,9999999",
                        "lender-pepper-living-household": "required|between:0,9999999",

                        // Custom
                        "_rent-boarding-expense": "required|between:0,9999999", // if expense kind == boarder, employerProvided, livingWithParents, ownOutright, renterAgent, renterPrivate 
                        "_private-school-fees": "required|between:0,9999999", // lender field
                        "_hecs-help": "required|between:0,9999999", // If expense kind == HECs 
                        "_buy-now-pay-later": "required|between:0,9999999"  // If expense kind related to a liabilty kind == Buy Now Pay Later
                    }
                },
            }
        },
        computed: {
            ...mapGetters('themes', [
                'getActiveTheme'
            ]),
            vb(){
                return this.$vuetify.breakpoint
            },
            livingExpense() {
                let livingExpense = null
                this.expenses.forEach(expense => {
                    //if kind is living expense
                    if(expense.relationships.kind.data.id == '11-16') {
                        livingExpense = expense
                    }
                })
                return livingExpense
            },
            rentBoardingExpense(){
                return this.expenses.find(expense => {
                    // if expense kind == boarder, employerProvided, livingWithParents, ownOutright, renterAgent, renterPrivate 
                    return ['11-3','11-4','11-5','11-7','11-8','11-9'].includes(expense.relationships.kind.data.id)
                })
            },
            hecsHelpExpense(){
                return this.expenses.find(expense => {
                    // if expense kind == HECS-Help 
                    return expense.relationships.kind.data.id == '11-32'
                })
            },
            buyNowPayLaterExpense(){
                return this.expenses.find(expense => {
                    // if expense kind ==  Buy Now Pay Later 
                    return expense.relationships.kind.data.id == '11-11'
                })
            },
        },
        methods: {
            ...mapActions('resource-actions', [
                'updateExpense',
                'getPeople',
            ]),
            async pull() {

                try {
                    let promise1 = this.getPeople({"include" : 'expenses'})
                    
                    const res = await Promise.all([
                        promise1, 
                    ]) 

                    promise1 = res[0]

                    if(promise1.data && promise1.data.hasOwnProperty('included')) {
                        let expenses = []

                        promise1.data.included.forEach(item => {
                            if(item.type == 'expenses') {
                                expenses.push(item)
                            }
                        })

                        this.expenses = expenses

                        this.populateFields()
                        
                    }

                } catch (err) {
                    console.log(err)
                }

            },
            async push() {
                try {
                    
                    let expenseReq = [];
                    

                    // Living Expense
                    if(this.livingExpense) {
                        const expenses1 = {
                            "id": this.livingExpense.id,
                            "type": "expenses",
                            "attributes": {
                                ...this.resources.expense.attributes,
                                lender: {
                                    pepper: {
                                        privateSchoolFees: {
                                            "amount": this.fieldPrivateSchoolFees,
                                            "currency": "AUD"
                                        }
                                    }
                                }
                            }
                        }
                        expenseReq.push(this.updateExpense(expenses1))
                    }

                    // Rent / Boarding Expense 
                    if(this.rentBoardingExpense) {
                        const expenses2 = {
                            "id": this.rentBoardingExpense.id,
                            "type": "expenses",
                            "attributes": {
                                "payment-amount" : this.fieldRentBoardingExpense
                            }
                        }

                        expenseReq.push(this.updateExpense(expenses2))
                    }
                    // Rent / Boarding Expense 
                    if(this.hecsHelpExpense) {
                        const expenses3 = {
                            "id": this.hecsHelpExpense.id,
                            "type": "expenses",
                            "attributes": {
                                "payment-amount" : this.fieldHecsHelp
                            }
                        }

                        expenseReq.push(this.updateExpense(expenses3))
                    }


                    await Promise.all([expenseReq])

                    const {valid, status} = await this.validateFormData()
                    
                    return {valid, status, data: {}}

                } catch {err} {
                    console.log(err)
                }
            },
            populateFields() {
                
                let { livingExpense, rentBoardingExpense, hecsHelpExpense, resources } = this

                if(livingExpense) {

                    // populate fields for nodifi living expense
                    Object.keys(resources.expense.attributes).forEach(key => {
                        resources.expense.attributes[key] = livingExpense.attributes[key]
                    })

                    this.fieldPrivateSchoolFees = livingExpense.attributes.lender.pepper?.privateSchoolFees?.amount

                }

                if(rentBoardingExpense) {
                    this.fieldRentBoardingExpense = rentBoardingExpense.attributes["payment-amount"]
                }

                if(hecsHelpExpense) {
                    this.fieldHecsHelp = hecsHelpExpense.attributes["payment-amount"]
                }

                if(hecsHelpExpense) {
                    this.fieldBuyNowPayLater = hecsHelpExpense.attributes["payment-amount"]
                }

            },
            hexToRGBA(hex, a = 1){
                return this.$options.filters.hexToRGBA(hex, a)
            },
            selectTab(tab) {
                this.tabSelection = tab
            },
            isIncluded(field){
                const {included} = this
                if(included.hasOwnProperty(field)) {
                    return included[field]
                }
                return true
            },
        },
        watch: {
            // "_rent-boarding-expense": false,
            // "_private-school-fees": false, 
            // "_hecs-help": false,
            // "_buy-now-pay-later": false

            rentBoardingExpense(val){
                if(val != null) {
                    this.included['_rent-boarding-expense'] = true
                } else {
                    this.included['_rent-boarding-expense'] = false
                }
            },

            hecsHelpExpense(val){
                if(val != null) {
                    this.included['_hecs-help'] = true
                } else {
                    this.included['_hecs-help'] = false
                }
            },
        }
    }
</script>

<style lang="scss">
</style>