<template>

    <v-card class="d-flex flex-column justify-space-between align-center" flat tile :height="vb.mdAndUp ? 100 : 65">

        <v-card class="d-flex flex-grow-1 align-center" flat tile width="100%">
            
            <v-col class="d-flex justify-center"  color="secondary">

                <div class="d-flex align-center" v-if="b2bClickHeaderLogo">
                    <router-link :to="`/${getGroupSlug}/home`" class="d-flex align-center">
                        <img class="flex-brand d-block" :src="require(`~/themes/${getActiveTheme.name}/svg/logo.svg`)" height="34" style="max-width: 170px" />
                    </router-link>
                </div>
                <div class="d-flex align-center" v-else>
                    <img class="flex-brand d-block" :src="require(`~/themes/${getActiveTheme.name}/svg/logo.svg`)" height="34" style="max-width: 170px" />
                </div>


                <div v-if="showRequestACallBack" class="request-a-call">
                    
                    <PortalTarget name="component-header-call-back">
                    </PortalTarget>
                    
                </div>

                

                
            </v-col>


        </v-card>
    </v-card>

</template>


<script>

    import { mapGetters, mapState, mapActions } from 'vuex'
    import subscriptions from '~/components/shared/subscriptions'
    import formHelpers from '~/components/shared/form-helpers'
    import RequestCallback1 from '~/components/base/RequestCallback1'
    export default {
        components: {
            RequestCallback1
        },
        mixins: [subscriptions, formHelpers],
        data(){
            return {
                breakpoint: 768,
                requestCallback0Dialog : false
            }
        },
        computed: {
            ...mapGetters({
                getSpacesObj: 'flows/getSpacesObj',
                getActiveTheme: 'themes/getActiveTheme',
                getGroupSlug: 'slug/getGroupSlug'
            }),
            ...mapGetters('flows', ['getSteps']),
            ...mapState({
                activeStep: state => state.flows.activeStep,
                steps: state => state.flows.steps,
            }),
            ...mapState({
                setup: state => state.setup
            }),
            b2bClickHeaderLogo(){
                return this.blockSettings?.onClickLogo == "b2bClickHeaderLogo"
            },
            showRequestACallBack(){
                return this.blockSettings.showRequestACallBack && this.blockSettings.showRequestACallBack == 1
            },
        },
        created() {
        },
        mounted() {

            this.$emit('onMount')
            
        },
        methods: {
            ...mapActions('flows', ['selectNextStep']),
            ...mapActions('dialogs', [
                    'evaluateSteps'
                ]),
            async nextStep() {
                await this.evaluateSteps()
            }
        },
        watch: {
   
        }
    }
</script>

<style scoped lang="scss">

    .flex__component-Header2 {

        .request-a-call {
            position: absolute;
            right: 16px;
            top: 50%;
            transform: translate(0, -50%);
        }

    }

</style>
