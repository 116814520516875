


<template>

    <div>
      <!-- isCommercial: {{isCommercial}}
      isConsumer  : {{isConsumer  }} -->
        <MacquarieCommercialEntities1 @loans="updateLoans"  :loans="loans" v-if="isCommercial"></MacquarieCommercialEntities1>
        <MacquarieConsumerEntities1  @loans="updateLoans" :loans="loans" v-else-if="isConsumer"></MacquarieConsumerEntities1>
    </div>

</template>
<script>
import shared from "~/components/v1/submission/MacquarieApplicant1/__shared";
import subscriptions from "~/components/shared/subscriptions";
import MacquarieCommercialEntities1 from './_MacquarieCommercialEntities1.vue';
import MacquarieConsumerEntities1 from './_MacquarieConsumerEntities1.vue';
import { mapActions } from 'vuex'
  
export default {
  mixins: [subscriptions, shared],
    components: {
      MacquarieCommercialEntities1,
      MacquarieConsumerEntities1,
    },
    data () {
      return {
        loans: {
          attributes: {}
        }
      }
    },
    computed: {
      isConsumer(){
            if(this.loans){
                return  this.loans.attributes['finance-for-business-purposes'] == 'No'
            }
            return false
        },
        isCommercial(){

            if(this.loans){
                return  this.loans.attributes['finance-for-business-purposes'] == 'Yes'
            }
            return false
        },
    },
    async mounted() {
      let promise1 = await this.getLoan(this.getLoanId, { include: "parent-parts" });4
      if (promise1.status == 200) {
        
        this.loans = promise1.data.data
        await this.$nextTick()
        this.$children[0].pullChild()
      }
    },
    methods: {
      ...mapActions("resource-actions", [
      "getLoan",
    ]),
    updateLoans (resource) {
        this.loans.attributes = resource.attributes
    },
      async pull () {
        // this.$children[0].pullChild()
        },
        push () {
          return this.$children[0].pushChild()
      }
    }
}


</script>

<style></style>