import { mapState, mapActions, mapGetters, mapMutations } from 'vuex'
import {store, actions} from '~/components/v1/RoleEditor1/__store'


export default {  
    computed: {
        ...mapState({
            development: state => {
                return state.development
            },
        }),
        ...mapGetters('kinds', ['kinds', 'kindsUnformat']),
        combinedEntitiesParts(){
            return store.resources.parts
        },

        loans(){
            return store.resources.loans
        },
        isConsumer(){
            if(this.loans){
                return  this.loans.attributes['finance-for-business-purposes'] == 'No'
            }
            return false
        },
        isCommercial(){

            if(this.loans){
                return  this.loans.attributes['finance-for-business-purposes'] == 'Yes'
            }
            return false
        },

    },
    methods: {
        getFriendlyName(role){

            let fullname = ''

            if(role.type == 'add') {
                return role.name
            }

            if(role.type == 'businesses') {

                fullname += role.attributes['business-legal-name'] ? role.attributes['business-legal-name'] : 'Business'

            }

            if(role.type == 'people'){

                fullname += role.attributes['first-name'] ? role.attributes['first-name'] + ' ' : ''

                fullname += role.attributes['last-name'] ? role.attributes['last-name'] : ''

                if(!role.attributes['first-name'] && !role.attributes['last-name']){
                    fullname = 'Person'
                }
            }

            return fullname

        },
        getRoleKind(role){

            let roleKind = null
            let roleKindName = ''

            if( role.hasOwnProperty('relationships') ){

                roleKind = this.kindsUnformat.find(kind => kind.id == role.relationships?.kind?.data?.id)

                if(roleKind){
                    roleKindName = roleKind.attributes.name 
                }
            }

            return roleKindName

        },
        getRolesEntity(entity){
                
            let roles = []

            let entityParentParts = entity.relationships['parent-parts']

            if(entity.parent){

                let parent = entity.parent

                let parentChildParts = parent.relationships['child-parts']
    
    
                if(entityParentParts.data.length > 0 && parentChildParts.data.length > 0){
    
                    let parentChildPartsIds = []
    
                    parentChildParts.data.forEach( part => {
                        parentChildPartsIds.push(part.id)
                    })
    
    
                    entityParentParts.data.forEach( (part1) => {
    
                        if(parentChildPartsIds.includes(part1.id)){
    
                            this.combinedEntitiesParts.forEach(part2 => {
    
                                if(part1.id == part2.id){
        
                                    let partKind = this.kindsUnformat.find(kind => kind.id == part2.relationships?.kind?.data?.id)
        
                                    if(!roles.includes(partKind.attributes.name)){
                                        roles.push(partKind.attributes.name)
                                    }
                                
                                }
        
                            })
    
                        }
    
                    });
    
                }


            } else {


                if(entityParentParts.data.length > 0){
    
                    entityParentParts.data.forEach( (part1) => {
    
                        this.combinedEntitiesParts.forEach(part2 => {

                            if(part1.id == part2.id){
    
                                let partKind = this.kindsUnformat.find(kind => kind.id == part2.relationships?.kind?.data?.id)
    
                                if(!roles.includes(partKind.attributes.name)){
                                    roles.push(partKind.attributes.name)
                                }
                            
                            }
    
                        })

    
                    });
    
                }


            }

            
            return roles

        },

    }
}