<template>
    <div>
        <div v-if="!data" class="error--text font-size-20 px-8 font-weight-bold">
            No Business Records Found for this Entity.
        </div>
        <div v-if="vb.mdAndUp" class="d-flex" style="width: 100%">
            <v-sheet color="light_gray_1" class="py-12 px-12" width="50%" outlined elevation="0">
                <v-img
                    max-width="120"
                    class="flex-brand"
                    :src="require(`~/assets/images/nodifi.svg`)"
                    position="center left"
                    contain>
                </v-img>
                <div class="mt-2 font-size-20 light_gray_2--text">Data</div>
    
                <v-list color="transparent">
                        <v-list-item two-line class="px-0">
                            <v-list-item-content>
                                <v-list-item-title class="font-weight-medium">ABN</v-list-item-title>
                                <v-list-item-subtitle>{{data ? data.attributes['abn-number'] : '&mdash;'}}</v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
            
                        <v-list-item two-line class="px-0">
                            <v-list-item-content>
                                <v-list-item-title class="font-weight-medium">Nature of Business</v-list-item-title>
                                <v-list-item-subtitle>{{data ? data.attributes['nature-of-business'] : '&mdash;'}}</v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                </v-list>
    
            </v-sheet>
            <v-sheet class="py-8 px-12" width="50%">
    
                <v-img
                    max-width="120"
                    class="flex-brand"
                    :src="lenderLogo"
                    position="center left"
                    contain>
                </v-img>
                <div class="mt-2 mb-8 font-size-20">Requirements</div>
    
                <ValidationObserver ref="observer" tag="form">
                    <template v-for="(field, key) in currentStep['form-fields']" >
                        <ValidationProvider
                                v-if="visible[field]"
                                :key="key"
                                v-slot="{ errors }"
                                :name="getLabel(field)"
                                :rules="getRules(field)"
                                :vid="field">
        
                                    <v-select
                                        v-if="isFieldType('picklist', field) || field === 'trustType'"
                                        v-model="resources.business.attributes['lender']['westpac'][field]"
                                        :items="getOptions(field)"
                                        :error-messages="errors"
                                        color="secondary"
                                        :label="getLabel(field)"
                                        ></v-select>
        
                                    <v-text-field
                                        v-else
                                        tile
                                        type="text"
                                        :label="getLabel(field)"
                                        color="secondary"
                                        :error-messages="errors"
                                        v-model="resources.business.attributes['lender']['westpac'][field]"
                                        required>
                                    </v-text-field>
        
                        </ValidationProvider>
                    </template>
                </ValidationObserver>
    
    
            </v-sheet>
        </div>
    
        <!-- mobile version -->
        <div v-else>
    
            <v-card flat class="mx-5 rounded-xl" :style="`background-color: ${hexToRGBA(getActiveTheme.colors.secondary, 0.2)}`">
                <v-card-text class="d-flex py-1">
                    <v-row>
                        <v-col cols="6" class="pa-1">
                            <v-card
                            min-height="45"
                                flat class="rounded-xl px-1"
                                :color="tabSelection == 'nodifi' ? 'white' : 'transparent'">
                                <div class="d-flex justify-center pt-1" @click="selectTab('nodifi')">
                                    <v-img
                                        max-width="55"
                                        class="flex-brand"
                                        :src="require(`~/assets/images/nodifi.svg`)"
                                        position="center left"
                                        contain>
                                    </v-img>
                                    <div class="mt-3 font-size-12 pl-1">Data</div>
                                </div>
                            </v-card>
                        </v-col>
                        <v-col cols="6" class="pa-1">
                            <v-card
                                flat class="rounded-xl px-1"
                                :color="tabSelection == 'westpac' ? 'white' : 'transparent'">
                                <div class="d-flex flex-column" @click="selectTab('westpac')">
                                    <v-img
                                        max-width="45"
                                        class="flex-brand mx-auto mt-2"
                                        :src="lenderLogo"
                                        position="center left"
                                        contain>
                                    </v-img>
                                    <div class="font-size-12 pl-1 mx-auto">Requirements</div>
                                </div>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-card-text>
    
            </v-card>
    
                <v-list v-show="tabSelection == 'nodifi'" class="mx-8" color="transparent">
                    <v-list-item two-line class="px-0">
                        <v-list-item-content>
                            <v-list-item-title class="font-weight-medium">ABN</v-list-item-title>
                            <v-list-item-subtitle>{{data ? data.attributes['abn-number'] : '&mdash;'}}</v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>

                    <v-list-item two-line class="px-0">
                        <v-list-item-content>
                            <v-list-item-title class="font-weight-medium">Nature of Business</v-list-item-title>
                            <v-list-item-subtitle>{{data ? data.attributes['nature-of-business'] : '&mdash;'}}</v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>
                </v-list>
    
            <div v-show="tabSelection != 'nodifi'" class="mx-8">
                <ValidationObserver ref="observer" tag="form">
                    <template v-for="(field, key) in currentStep['form-fields']" >
                    <ValidationProvider
                            v-if="visible[field]"
                            :key="key"
                            v-slot="{ errors }"
                            :name="getLabel(field)"
                            :rules="getRules(field)"
                            :vid="field">
    
                                <v-select
                                    v-if="isFieldType('picklist', field) || field === 'trustType'"
                                    v-model="resources.business.attributes['lender']['westpac'][field]"
                                    :items="getOptions(field)"
                                    :error-messages="errors"
                                    color="secondary"
                                    :label="getLabel(field)"
                                ></v-select>
    
                                <v-text-field
                                    v-else
                                    tile
                                    type="text"
                                    :label="getLabel(field)"
                                    color="secondary"
                                    :error-messages="errors"
                                    v-model="resources.business.attributes['lender']['westpac'][field]"
                                    required>
                                </v-text-field>
    
                    </ValidationProvider>
                    </template>
                </ValidationObserver>
            </div>
    
        </div>
    </div>
</template>

<script>

    import { mapActions } from 'vuex'
    import subscriptions from '~/components/shared/subscriptions'
    import shared from '~/components/v1/submission/WestpacEntities1/__shared'
    import Enums from '~/components/v1/submission/WestpacEntities1/__enums'

    export default {
        components: {},
        mixins: [subscriptions, shared],
        props: {
            data: {
                type: Object,
                default: null
            },
            updater: {
                type: Boolean,
                default: false
            }
        },
        data() {
            return {
                tabSelection: 'nodifi',
                resources: {
                    business: {
                        id: null,
                        type: "businesses",
                        attributes: {
                            "lender": {
                                "westpac": {
                                    "industry" : null,
                                    "trustType": null,
                                    "companyType" : null,
                                }
                            }
                        },
                        relationships: {
                            application: {
                                data : {
                                    type: "applications",
                                    id: null
                                }
                            }
                        }
                    }
                },
                visible: {
                    "industry": false,
                    "trustType": false,
                    "companyType": true
                },
                labels: {
                    "industry": "Industry",
                    "trustType": "Trust Type",
                    "companyType": "Company Type",
                },
                options: {
                    "industry": Enums['industryOptions'],
                    "trustType": Enums['trustType'],
                    "companyType": Enums['companyType'],
                },
                validation_rules: {
                    attributes: {
                        "industry": `required|in:${Enums['industry']}`,
                        "trustType": ``,
                        "companyType": `|in:${Enums['companyType']}`,
                    }
                }
            }
        },
        computed: {
            vb() {
                return this.$vuetify.breakpoint
            },
        },
        mounted() {
            this.updateResourceFields()
        },
        methods: {
            ...mapActions('resource-actions', [
                'getBusiness',
            ]),
            selectTab(tab) {
                this.tabSelection = tab
            },
            getLabel(key) {
                return this.labels[key]
            },
            async validate(){

                const valid = await this.$refs.observer.validate()

                if(valid) {

                    // attache income id
                    // this.resources.incomes.id = this.data.id
                    this.$emit('submit', this.resources.business)
                }

            },
            async reset(){

                this.$refs.observer.reset()
                this.resources.business = {
                    id: null,
                    type: "businesses",
                    attributes: {
                        "lender": {
                            "westpac": {
                                "industry" : null,
                                "trustType": null,
                                "companyType" : null,
                            }
                        }
                    },
                    relationships: {
                        application: {
                            data : {
                                type: "applications",
                                id: null
                            }
                        }
                    }
                }

            },
            async updateResourceFields(){

                this.$emit('loading', true)

                const {data} = this

                if(data) {
                    if(data.roles[0] === 'Applicant' && (this.getRoleKind(data) === 'Trust' || this.getRoleKind(data) === 'Trustee')) {

                        this.validation_rules.attributes['trustType'] = `required`
                        this.validation_rules.attributes['companyType'] = `|in:${Enums['companyType']}`
                        this.visible['trustType'] = true
                        this.visible['companyType'] = false

                    } else {

                        if(data.roles[0] === 'Trustee' || data.roles[0] === 'Trust') {
                            this.validation_rules.attributes['trustType'] = `required`
                            this.validation_rules.attributes['companyType'] = `|in:${Enums['companyType']}`
                            this.visible['trustType'] = true
                            this.visible['companyType'] = false
                        } else {
                            this.validation_rules.attributes['trustType'] = ``
                            this.validation_rules.attributes['companyType'] = `required|in:${Enums['companyType']}`
                            this.visible['trustType'] = false
                            this.visible['companyType'] = true
                        }
                    }

                    let res = await this.getBusiness(data.id)

                    if(res.status === 200) {
                        this.resources.business.id = res.data.data.id
                        // mapped industry in 'anzsic-code'
                        this.resources.business.attributes.lender.westpac['industry'] = Enums.anzicToIndustryMapping[res.data.data.attributes['anzsic-code']] ?? null
                        this.resources.business.attributes.lender.westpac['trustType'] = res.data.data.attributes?.lender?.westpac ? res.data.data.attributes.lender.westpac['trustType'] : null
                        this.resources.business.attributes.lender.westpac['companyType'] = res.data.data.attributes?.lender?.westpac ? res.data.data.attributes.lender.westpac['companyType'] : null
                        this.resources.business.relationships.application = res.data.data.relationships.application
                    }

                    this.$emit('loading', false)

                } else {

                    this.$emit('loading', false)
                }



            }
        },
        watch: {
            async updater(val) {
                await this.updateResourceFields()
            }
        }
    }
</script>

<style lang="scss" scoped>
</style>
  