<template>
    <div v-if="getComponentName">
        <component :is="getComponentName" :class="`flex__component-${getComponentName}`" />
    </div>
</template>

<script>
    
    import subscriptions from '~/components/shared/subscriptions'
    import includes from '~/components/v1/Image1/includes'

    export default {
        mixins: [subscriptions],
        components: {
            ...includes
        },
        data(){
            return {
                dialog: false,
            }
        },
        mounted() {
        },
        computed: {

            getComponentName(){

                // console.log(this.blockSettings.name)

                switch (this.blockSettings.name) {
                    case "lender-submission-logo-plenti":
                        return "PlentiSubmissionImage1"
                    case "lender-submission-logo-nowfinance":
                        return "NowFinanceSubmissionImage1"
                    case "lender-submission-logo-societyone":
                        return "SocietyOneSubmissionImage1"
                    case "lender-submission-logo-morris":
                        return "MorrisSubmissionImage1"
                    case "lender-submission-logo-pepper":
                        return "PepperSubmissionImage1"
                    case "lender-submission-logo-wisr":
                        return "WisrSubmissionImage1"
                    case "lender-submission-logo-money-3":
                        return "Money3SubmissionImage1"
                    case "lender-submission-logo-moneyplace":
                        return "MoneyPlaceSubmissionImage1"
                    case "lender-submission-logo-finance-one":
                        return "FinanceOneSubmissionImage1"
                    case "lender-submission-logo-macquarie":
                        return "MacquarieSubmissionImage1"
                    case "lender-submission-logo-westpac":
                        return "WestpacSubmissionImage1"
                    case "lender-submission-logo-capital":
                        return "CapitalSubmissionImage1"
                    default:
                        return null
                }


            }
 
        },
        methods: {

        },

    }
</script>

<style scoped lang="scss">

</style>
