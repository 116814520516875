<template>
    <v-card :disabled="isDisabled || !validComponentData" class="mx-auto" tile flat>
        <v-card-text class="pa-0 px-4 pb-6">
            <FlexWrap @resize="handleResize">
                <ValidationObserver ref="observer" tag="form">

                    <v-card flat class="overflow-y-auto mx-auto" tile max-width="800">
                        <v-card-text class="pa-0">
                            <v-row class="ma-0 pa-0">
                                <v-col cols="12" class="pa-0 ma-0">
                                    <ValidationProvider 
                                        v-slot="{ errors }" 
                                        name="nvic" 
                                        rules="required" 
                                        vid="nvic">
                                        <v-list two-line>
                                            <v-list-item-group
                                                v-model="selected"
                                                :mandatory="mandatory">
                                                <template v-for="(item, index) in list">
                                                    <v-list-item class="v-list-bar" :key="item.title">
                                                        <template v-slot:default="{ active }">
                                                            <v-list-item-content>                  
                                                                <v-list-item-subtitle
                                                                    v-text="item['model-name']"
                                                                    class="px-2 body-1 nvic-items font-weight-medium black--text">
                                                                </v-list-item-subtitle>
                                                            </v-list-item-content>
                                            
                                                        </template>
                                                    </v-list-item>
                                                </template>
                                            </v-list-item-group>
                                        </v-list>
                                        <div class="with-dynamic-validations">
                                            <div class="caption error--text">
                                                {{ errors[0] }}
                                            </div>
                                        </div>
                                    </ValidationProvider>
                                    
                                </v-col>                               
                            </v-row>                            
                        </v-card-text>
                    </v-card>

                </ValidationObserver>
            </FlexWrap>
        </v-card-text>
    </v-card>
</template>

<script>

    import helpers from '~/plugins/helpers'
    import subscriptions from '~/components/shared/subscriptions'
    import { mapActions } from 'vuex'
    import FlexWrap from '~/components/base/FlexWrap'
    import { isEmpty } from 'lodash'
    
    export default {
        name: 'NvicFinder1',
        mixins: [ helpers, subscriptions ],
        components: { FlexWrap },
        computed: {
            validateResource() {
                let valid = true
                if ( isEmpty(this.resources.attributes['nvic']) ) {
                    valid = false
                }
                return valid
            },
            mandatory() {
                return this.selected.toString().length > 0 ? true : false
            }
        },
        methods: {
            ...mapActions('resource-actions', [
                'runGlassesGuides',
                'getPurchases',
                'createPurchase',
                'updatePurchases'
            ]),
            async pull() {
                let res = await this.getPurchases()

                if ( res.status === 200 ) {
                    this.resourceObj = res.data.data[0]
                    this.prepareResources()
                    // this.glassConfig = this.prepareGlassConfig()

                    let glassRes = await this.runGlassesGuides(this.prepareGlassConfig())                    
                    this.list = glassRes.data.data.attributes.output

                    this.list.forEach( (nvic, i) => {
                        if (nvic['nvic-cur'] === this.resources.attributes.nvic)
                            this.selected = i
                    })
                }
            },
            async push() {
                let { resources } = this
                const valid = this.validateResource
                let res = null

                if (this.resources.id) {
                    res = await this.updatePurchases(resources)
                }
                else {
                    delete resources.id
                    res = await this.createPurchase(resources)
                }

                if(this.hardValidation) {
                    status =  valid ? 'valid' : 'dirty'
                }

                // let data = this.$options.filters.toAppDataFormat(res)

                return  { valid, status, data: {} }
            },
            prepareGlassConfig() {
                
                let { attributes } = this.resourceObj
                
                return {
					"data": {
                        "type": "glasses-guides",
                        "attributes": {
                            "input": {
                                "end-point" : "GetAutoDetailsSpeByMake",
                                "params" : {
                                    "keyword": null,
                                    "model-type-code": "A",
                                    "make": attributes?.['make-code'],
                                    "model": attributes?.['model-code'],
                                    "series": null,
                                    "variant-name": attributes?.variant,
                                    "year-create": attributes?.['year-of-manufacture'],
                                }
                            }
                        }
                    }
				}
            },
            prepareResources() {
                
                let { id, relationships, attributes } = this.resourceObj
                
                this.year = attributes['year-of-manufacture'].toString()
                
                this.nvic = attributes['nvic']

                this.resources.id = id
                this.resources.relationships.application = relationships.application
                this.resources.attributes = {
                    nvic: attributes['nvic']
                }
            },
            codedString(value) {
                let str = value.substring(0,3)
                if (str.length === 1) return str + '--'
                if (str.length === 2) return str + '-'
                return str
            }
        },
        watch: {
            selected(value) {
                this.resources.attributes['nvic'] = this.list[value]['nvic-cur']
            },
            "validComponentData": {
                handler(val) {
                    if ( !this.validComponentData ) {

                        this.showComponentWarning()

                    }
                },
                deep: true
            }
        },
        data() {
            return {
                selected: '',
                resources: {
                    "type": "purchases",
                    "id": null,
                    "attributes": {
                        "nvic": ''
                    },
                    "relationships": {
                        "application": {
                            "data": {
                                "type": "applications",
                                "id": null
                            }
                        }
                    }
                },
                resourceObj: {},
                list: [],
                glassConfig: {},
                validComponentData: true
            }
        }
    }
</script>

<style lang="scss">

    .flex__component-NvicFinder1 {

        .v-list-item-group {

            .v-list-item {
                overflow: hidden !important;

                &.v-list-bar {
                    margin-bottom: 20px;
                    border-radius: 15px;
                    border: 2px var(--v-light_gray_6-base) solid;

                    &:last-child {
                        margin-bottom: 0;
                    }
                }
            }
        }


        .v-item-group {

            .v-list-item__subtitle {
                font-size: 20px !important;
            }

        }
        
        .nvic-items {
            letter-spacing: 0 !important;
        }

    }

</style>