<template>
    <div class="flex__sub-component-Select1">
        <v-select
            v-model="selected"
            :items="options"
            :label="label"
            :errors="errors">
        </v-select>   
    </div>
</template>

<script>
    export default {
        name: 'Select1',
        props: {
            options: {
                type: Array,
                default: () => []
            },
            errors: {
                type: Array,
                default: () => []
            },
            label: {
                type: String,
                dafault: ''
            }
        },
        data() {
            return {
                selected: ''
            }
        },
        watch: {
            selected() {
                // this.modelValue = this.selected
                this.$emit('input', this.selected)
            }
        }
    }
</script>

<style lang="scss" scoped>
</style>