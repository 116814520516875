export default {
    "type1": {
        "loan-kinds": ['Individual', 'Sole Trader'],   // loan kinds
        "entity-kinds": ['Applicant', 'Director', 'Non-Borrowing Spouse', 'Guarantor', 'Trustee', 'Partner'],   // Person / Business resource parent-parts
        "layout": [
            {title: "Personal Details"}, 
            [
                "first-name",
                "middle-name",
                "last-name",
                "date-of-birth"
            ],
            [
                "mobile-phone",
                "other-phone",
                "email",
            ],
            [
                "gender",
                "marital-status",
                "number-of-dependants",
                "age-of-dependants",
            ],
            {title: "Citizenship Information"}, 
            [
                "primary-country-of-citizenship",
                "secondary-country-of-citizenship",
                "visa",
                "visa-expiry-date",
            ],
            {title: "ID Information"}, 
            [
                "drivers-licence-number",
                "drivers-licence-expiry",
                "drivers-licence-state",
                "drivers-licence-card-number",
            ],
            [
                "medicare-number",
                "passport-number",
                "passport-expiry",
            ],
            {title: "Credit Information"}, 
            [
                "comprehensive-credit-score",
                "credit-status"
            ],
            [
                "previous-bankrupt",
                "bankruptcy-status",
                "bankruptcy-declared-date",
                "bankruptcy-discharge-date"
            ],
        ]
    },
    "type2": {
        "loan-kinds": ['Individual'],   // loan kinds
        "entity-kinds": ['Beneficial Owner'],   // Person / Business resource parent-parts
        "layout": [
            [
                "first-name",
                "middle-name",
                "last-name",
                "date-of-birth"
            ],
            [
                "mobile-phone",                   
                "other-phone",
                "email",
            ]
        ]
    },
    "type3": {
        "loan-kinds": ['Company'],   // loan kinds
        "entity-kinds": ['Applicant', 'Guarantor'],   // Person / Business resource parent-parts
        "layout": [
            [
                "abn-number",
            ],
            [
                "business-legal-name",
                "acn-number",
                "registered-business-name",
                "nature-of-business",
                // "abn-number",
            ],
            [
                "date-registered-abn",
                "date-registered-gst"
            ]
        ]
    },
    "type3-1": {
        "loan-kinds": ['Company'],   
        "entity-kinds": ['Beneficial Owner', 'Trustee'], 
        "layout": [
            [
                "business-legal-name",
                "acn-number",
                // "registered-business-name",
                // "nature-of-business",
                // "abn-number",
            ],
            // [
            //     "date-registered-abn",
            //     "date-registered-gst"
            // ]
        ]
    },
    "type3-2": {
        "loan-kinds": ['Company'],   // loan kinds
        "entity-kinds": ['Partner'],   // Person / Business resource parent-parts
        "layout": [
            [
                "abn-number",
            ],
            [
                "business-legal-name",
                "acn-number",
                // "registered-business-name",
                // "nature-of-business",
                // "abn-number",
            ],
            [
                "date-registered-abn",
                "date-registered-gst"
            ]
        ]
    },
    "type4": {
        "loan-kinds": ['Partnership', 'Trust'],   
        "entity-kinds": ['Applicant', 'Partner', 'Guarantor'], 
        "layout": [
            [
                "abn-number",
            ],
            [
                "business-legal-name",
            ],
            [
                //"business-legal-name",
                //"abn-number",
                "registered-business-name",
                "nature-of-business",
                //"acn-number",
            ],
            [
                "date-registered-abn",
                "date-registered-gst"
            ]
        ]
    },
    "type4-1": {
        "loan-kinds": ['Partnership', 'Trust'],   
        "entity-kinds": ['Beneficial Owner'], 
        "layout": [
            [
                "abn-number",
            ],
            [
                "business-legal-name",
                // "acn-number",
                //"abn-number",
            ]
        ]
    },
}



// ## PARTS
// "Applicant",
// "Beneficial Owner",
// "Director",
// "Guarantor",
// "Non-Borrowing Spouse",
// "Partner",
// "Purchaser - Sourcing",
// "Reference - Accountant",
// "Reference - Business",
// "Reference - Landlord",
// "Reference - Personal",
// "Trustee"


// ## LOAN
// "Company"
// "Partnership"
// "Trust"
// "Individual"
// "Sole Trader"

// ## PERSON
// "Individual"

// ## BUSINESS
// "Company"
// "Partnership"
// "Trust"
