export default {
    "person": {
        "layout": [
            { title: "Applicants details"},
            ['first-name','middle-name','last-name','date-of-birth'],
            ['mobile-phone','other-phone','email'],
            ['gender','marital-status','number-of-dependants','age-of-dependants'],
            { title: 'Identification'},
            ['drivers-licence-state','drivers-licence-number','drivers-licence-card-number','drivers-licence-expiry'],
            ['medicare-number','passport-number','passport-expiry'],
            { title: 'Citizen Information'},
            ['primary-country-of-citizenship','have-dual-citizenship','secondary-country-of-citizenship'],
            ['visa','visa-expiry-date'],
            { title: 'Credit History'},
            ['credit-status'],
            ['previous-bankrupt','bankruptcy-status','bankruptcy-declared-date','bankruptcy-discharge-date']
        ]
    },
    "business": {
        "layout": [
            {title: 'Business details'},
            ['abn-number','acn-number'],
            ['business-legal-name'],
            ['registered-business-name','nature-of-business'],
            ['date-registered-abn','date-registered-gst']
        ]
    }
}