import Vue from "vue";

export const store = Vue.observable({

    lendersToCompare: [],
    repaymentsTermField: 'Monthly',
});


export const actions = {

    setSelectedLendersToCompare(payload) {

        let newList = []
        let lenderNames = store.lendersToCompare.map(lender => lender.name)

        if (payload.hasOwnProperty('name') && lenderNames.includes(payload.name)) {

            store.lendersToCompare = store.lendersToCompare.filter(lender => {
                return lender.name !== payload.name
            })

        } else {

            store.lendersToCompare.push(payload);

        }

    },    

    setRepaymentsTermField(payload) {
        store.repaymentsTermField = payload
    },    

};