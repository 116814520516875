<template>


    <v-card :disabled="disabled" color="primary" class="mx-auto" tile flat>

        <v-card-title class="pt-6 pb-10 text-center white--text flex-column">

            <v-icon class="mb-4" size="72" color="secondary">mdi-check-decagram</v-icon>
            <div class="mx-auto mb-4 column-title">Finance profile completed!</div>
            <div class="mx-auto mb-4 body-2">Congratulations, you've completed your finance profile. One of our friendly staff will contact you soon.</div>

        </v-card-title>

        <!-- <v-card-text class="pa-4 pt-0 pa-md-10 pt-md-0 text-center white">

            <v-card tile flat max-width="600" class="mx-auto">

                <v-container pa-0 fluid>
                    <v-row>

                        <v-col cols="12">
                            <v-card-title class="py-10 text-center flex-column">
                                <div class="mx-auto mb-4">How would you like to proceed?</div>
                                <div class="mx-auto mb-4 body-2">Please select from the options below.</div>
                            </v-card-title>
                        </v-col>

                        <v-col cols="12" sm="6">
                            <v-sheet height="100%" class="d-flex flex-column">
                                <v-icon class="mb-6" size="72" color="secondary">mdi-file-document-multiple-outline</v-icon>
                                <div class="column-title font-weight-black mb-6"> Complete<br/> Full App </div>
                                <div class="mb-6 mx-auto" style="max-width: 250px;">Proceed to your full application. The full app typically takes 10-15 minutes to complete.</div>
                                <v-spacer></v-spacer>
                                <div>
                                    <v-btn 
                                        depressed large 
                                        class="mb-8" 
                                        color="secondary" 
                                        width="100%" 
                                        max-width="140" 
                                        link @click="selectFinalStep({ url: getLinks.path0, flowStatus: getCurrentFlowStatus.id })">
                                        Go Now
                                    </v-btn>
                                </div>
                            </v-sheet>
                        </v-col>

                        <v-col cols="12" sm="6">
                            <v-sheet height="100%" class="d-flex flex-column">
                                <v-icon class="mb-6" size="72" color="secondary">mdi-cellphone-android</v-icon>
                                <div class="column-title font-weight-black mb-6">Contact<br/> Me</div>
                                <div class="mb-6 mx-auto" style="max-width: 250px;">Tired of typing? One of our friendly brokers will be happy to call you and pick up where you left.</div>
                                <v-spacer></v-spacer>
                                <div>
                                    <v-btn 
                                        depressed large
                                        class="mb-8" 
                                        color="secondary" 
                                        width="100%" 
                                        max-width="140" 
                                        link @click="selectFinalStep({ url: getLinks.path0, flowStatus: getCurrentFlowStatus.id })">
                                        Go Now
                                    </v-btn>
                                </div>
                            </v-sheet>
                        </v-col>

                    </v-row>
                </v-container>

            </v-card>

        </v-card-text> -->

    </v-card>

</template>

<script>

    import { mapState, mapGetters, mapActions, mapMutations } from 'vuex'
    import subscriptions from '~/components/shared/subscriptions'

    export default {
        mixins: [subscriptions],
        data(){
            return {
                links: null,
                disabled: false
            }
        },
        computed: {
            ...mapState('statuses', [
                'stepStatuses'
            ]),
            ...mapGetters('statuses', [
                'getCurrentFlowStatus'
            ]),            
            ...mapGetters({
                getGroupSlug: 'slug/getGroupSlug'
            }),
            getLinks(){

              let paths = {}

              if(this.links){


                this.links.forEach( (link, index) => {

                    paths[`path${index}`] = link.path

                })

              }

              return paths
              
            }
        },
        async mounted() {

            if (this.getCurrentFlowStatus.attributes.completed != 1) {
                await this.updateStepStatusToCompleted()
            }

        },
        methods: {
             ...mapActions('statuses', [
                'completeFlowStatus'
            ]),
            ...mapActions('flows', [
                'selectFinalStep'
            ]),
            ...mapMutations('utils', [
                'toggleAlert'
            ]),
            async pull(){

                const pullData = new Promise((resolve) => {

                    resolve([
                        {
                            label: "Complete Preliminary Application",
                            path: `/${this.getGroupSlug}/flow-select/preliminary-app/${this.appGuid}`
                        },
                        {
                            label: "Complete Full Application",
                            path: `/${this.getGroupSlug}/flow-select/full-app/${this.appGuid}`
                        },
                        {
                            label: "Contact Me",
                            path: `/${this.getGroupSlug}/contact/${this.appGuid}`
                        },
                    ])
                    
                }).then(res => {

                    this.links = res
                    return res

                })

                try {

                    await Promise.all([pullData])

                } catch (error) {

                    console.log('Ooops!..', error)

                }

            },

            async push(url) {

                let validSteps = true

                this.disabled = true

                let newFlow = {
                    "type": "flow-statuses",
                    "id": this.getCurrentFlowStatus.id,
                    "attributes": {
                        "completed": 1
                    }
                }

                let iterations = this.stepStatuses.length

                this.stepStatuses.forEach( (step, i) => {
                    if (--iterations) {
                        if ( step.attributes.value !== 'valid' )
                            validSteps = false
                    }                    
                })

                if ( validSteps ) {

                    await this.completeFlowStatus(newFlow)

                }
                else {
                    this.toggleAlert({
                        text: 'Steps are incomplete... To continue, please fill the steps below, completely:', 
                        isStepValidator: true,
                        type: 'error',
                        prominent: true,
                        dismissible: true
                    })
                }

                this.disabled = false

                return { valid: true, status: 'valid' }
                
            },

            async updateStepStatusToCompleted() {

                let newFlow = {
                    "type": "flow-statuses",
                    "id": this.getCurrentFlowStatus.id,
                    "attributes": {
                        "completed": 1
                    }
                }

                await this.completeFlowStatus(newFlow)

            }

        }
    }
</script>

<style scoped lang="scss">

  .column-title {
    line-height: 3.5rem;
    letter-spacing: .012rem;
  }

</style>
