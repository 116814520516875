export default {
    data: [
        { 
            type: 'Consumer - Individual',
            descripton: 'Individual Loan for Single Applicant', 
            icon: 'mdi-account', 
            markup: '<span>Individual Loan</span> <div>for Single <br>Applicant</div>' 
        },
        { 
            type: 'Consumer - Individual - Non Borrowing Spouse',
            descripton: 'Individual Loan with Non-Borrowing Spouse', 
            icon: 'mdi-account-plus', 
            markup: '<span>Individual Loan</span> <div>& Non-Borrowing <br>Spouse</div>' 
        },
        { 
            type: 'Consumer - Joint',
            descripton: 'Joint Loan with Two Applicants', 
            icon: 'mdi-account-multiple', 
            markup: '<span>Joint Loan</span> <div>with Two <br>Applicants</div> ' 
        },
        { 
            type: 'Commercial - Company',
            descripton: 'Company',
            icon: 'mdi-city', 
            markup: '<span>Company</span><div>Entity</div>' 
        },
        { 
            type: 'Commercial - Partnership',
            descripton: 'Partnership',
            icon: 'mdi-account-multiple', 
            markup: '<span>Partnership</span><div>Entity</div>' 
        },
        { 
            type: 'Commercial - Sole Trader',
            descripton: 'Sole Trader',
            icon: 'mdi-briefcase-account', 
            markup: '<span>Sole Trader</span><div>Entity</div>' 
        },
        { 
            type: 'Commercial - Trust - Company Trustee',
            descripton: 'Trust Company Trustee',
            icon: 'mdi-shield-star', 
            markup: '<span>Trust</span> <div>Company <br>Trustee</div>' 
        },
        { 
            type: 'Commercial - PAYG Chattel Mortgage',
            descripton: 'PAYG Chattel Mortgage',
            icon: 'mdi-account', 
            markup: '<span>PAYG</span> <div>Chattel <br>Mortgage</div> ' 
        },
        { 
            type: 'Commercial - Trust - Individual Trustee',
            descripton: 'Trust Individual Trustee',
            icon: 'mdi-shield-account', 
            markup: '<span>Trust</span> <div>Individual <br>Trustee</div>' 
        }
    ]
}






