<template>
  <v-app>

    <v-app-bar fixed flat app color="white" height="65"> 
        <v-container fluid px-4>
        <div class="d-flex">

            <!-- Left : Nodifi Logo : Fixed for all tenants -->
            <v-card flat tile class="px-4">
                <router-link :to="`/${getGroupSlug}/home`" tag="button" class="d-flex">
                    <img class="flex-brand d-block" :src="require(`~/assets/images/nodifi.svg`)" height="30"/>
                </router-link>
            </v-card>

            <v-spacer></v-spacer>

            <!-- Right: Tenant Logo : Visible only on white labelled themes -->
            <v-card flat tile class="px-4" v-if="isWhiteLabelled">
                <router-link :to="`/${getGroupSlug}/home`" tag="button" class="d-flex">
                    <img class="flex-brand d-block" :src="require(`~/themes/${getActiveTheme.name}/svg/logo.svg`)" height="30"/>
                </router-link>
            </v-card>

        </div>
        </v-container>
    </v-app-bar>

    <v-main class="light_gray_1">
        <nuxt />
    </v-main>


    <Snackbar />
    
    <Alerts />

  </v-app>
</template>

<script>

    import Snackbar from '@/components/base/Snackbar'
    import Alerts from '@/components/base/Alerts'

    import { mapMutations, mapActions, mapGetters, mapState } from 'vuex';
    import meta from '~/layouts/meta'

    export default {
        mixins: [
            meta
        ],
        name: 'BasicLayout',
        components: { Snackbar, Alerts },
        data () {
            return {

            }
        },
        computed: {
            ...mapGetters({
                getActiveTheme: 'themes/getActiveTheme',
                getGroupSlug: 'slug/getGroupSlug',
                isWhiteLabelled: 'isWhiteLabelled',
            }),
        }
    }

</script>

<style scoped lang="scss">
</style>
