<template>
<div class="d-md-flex" style="width: 100%">

      <v-sheet color="light_gray_1" class="py-12 px-12" style="min-width: 45%;" outlined elevation="0">
          <v-img
              max-width="120"
              class="flex-brand"
              :src="require(`~/assets/images/nodifi.svg`)"
              position="center left"
              contain>
          </v-img>
          <div class="mt-2 font-size-20 light_gray_2--text">Data</div>
          <v-list color="transparent">
              <v-list-item two-line class="px-0">
                  <v-list-item-content>
                      <v-list-item-title class="font-weight-medium">Address </v-list-item-title>
                      <v-list-item-subtitle>{{currentAddress ? getFullAddress(currentAddress) : '&mdash;'}}</v-list-item-subtitle>
                  </v-list-item-content>
              </v-list-item>
              <v-list-item two-line class="px-0">
                  <v-list-item-content>
                      <v-list-item-title class="font-weight-medium">Situation </v-list-item-title>
                      <v-list-item-subtitle>{{currentOccupancy ? currentOccupancy.attributes['residential-situation'] : '&mdash;'}}</v-list-item-subtitle>
                  </v-list-item-content>
              </v-list-item>
              <v-list-item two-line class="px-0">
                  <v-list-item-content>
                      <v-list-item-title class="font-weight-medium">Start Date</v-list-item-title>
                      <v-list-item-subtitle>{{currentOccupancy ? currentOccupancy.attributes['start-date'] : '&mdash;'}}</v-list-item-subtitle>
                  </v-list-item-content>
              </v-list-item>
          </v-list>

      </v-sheet>

      <v-sheet class="py-12 px-12">
          <v-img
              max-width="300"
              class="flex-brand"
              :src="require(`~/assets/images/logo/cropped/lender-logo-financeone.png`)"
              position="center left"
              contain>
          </v-img>
          <div class="mt-2 mb-6 font-size-20 light_gray_2--text">Requirements</div>

          <ValidationObserver ref="observer" tag="form">
              <div cols="6" class="py-0" v-if="isRenting">
                <ValidationProvider
                  key="numberOfIndividualOnLease"
                  v-slot="{ errors }"
                  :name="labels['numberOfIndividualOnLease']"
                  vid="numberOfIndividualOnLease"
                  :rules="validation_rules.attributes['numberOfIndividualOnLease']">
                <v-text-field
                    tile
                    type="number"
                    :label="labels['numberOfIndividualOnLease']"
                    color="secondary"
                    :error-messages="errors"
                    v-model.number="resources.occupancy.attributes.lender.financeOne['numberOfIndividualOnLease']"
                    required>
                  </v-text-field>
                </ValidationProvider>
              </div>

              <v-alert dense outlined type="info" v-if="!hasFields">
                  <div>Number of individuals on lease is not applicable</div>
              </v-alert>
          
              <v-alert dense outlined type="error" v-if="errors.length > 0">
                  <div v-for="(msg, index) in errors" v-html="msg" :key="index"></div>
              </v-alert>
          </ValidationObserver>
      </v-sheet>


  </div>
</template>

<script>
import { mapActions, mapMutations, mapGetters } from "vuex";
import subscriptions from "~/components/shared/subscriptions";
import shared from "~/components/v1/submission/FinanceOneEntities1/__shared";
import ButtonGroup3 from "~/components/base/ButtonGroup3";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import FlexDatePicker3 from '~/components/base/FlexDatePicker3';
import veeValidate from '~/components/shared/validation'
import formHelpers from '~/components/shared/form-helpers'
import resourceHelpers from '~/components/shared/resource-helpers'

export default {
  components: { ButtonGroup3, FlexDatePicker3 },
  mixins: [resourceHelpers, formHelpers, subscriptions, shared, veeValidate],
  props: {
    data: {
      type: Object,
      default: null,
    },
    currentOccupancy: {
      type: Object,
      default: null,
    },
    currentAddress: {
      type: Object,
      default: null,
    },
    updater: undefined,
  },
  data() {
    return {
      resources: {
        occupancy: {
          id: null,
          type: "occupancies",
          attributes: {
            lender: {
              "financeOne": {
                "numberOfIndividualOnLease": null
              }
            }
          },
        },
      },
      visible: {

      },
      labels: {
        "numberOfIndividualOnLease": "Number of individuals on lease",
      },
      validation_rules: {
        attributes: {
          "numberOfIndividualOnLease": "required|numeric",
        },
      },
      options: {
      },
      errors: [],
    };
  },

  computed: {
    isRenting(){
      
      if(this.currentOccupancy) {
        return ["Renter - Agent", "Renter - Private"].includes(this.currentOccupancy?.attributes["residential-situation"])
      }
      
      return false
    },
    hasFields(){

      if(!this.isRenting) {
        return false
      }

      return true
    }
  },
  mounted() {
    this.updateResourceFields();
  },
  methods: {
    ...mapActions("resource-actions", [
      "getOccupancy",
    ]),
    async validate() {

      const valid = await this.$refs.observer.validate();

      let allValid = this.errors.length == 0 && valid

      if (allValid) {

        this.$emit("submit", {
          occupancy: this.resources.occupancy,
        });

      }
    },
    reset() {
      this.$refs.observer.reset();
    },
    async updateResourceFields() {

      // console.log('Updating Resource Fields...')

      this.$emit("loading", true);

      const { data, isRenting } = this;

      if (data) {

        if (isRenting) {

          let res = await this.getOccupancy(this.currentOccupancy?.id);

          this.resources.occupancy = this.filterResourceFields(
            this.resources.occupancy,
            res.data.data
          );

          Object.keys(this.resources.occupancy.attributes).forEach((key) => {
            this.resources.occupancy.attributes[key] = this.resources.occupancy.attributes[key];
          });
        }

        this.$emit("loading", false);

      } else {
        this.$emit("loading", false);
      }
    },
  },
  watch: {
    updater(val) {
      if (val == "occupancy") {
        this.updateResourceFields();
      }
    }
  },
};
</script>

<style lang="scss" scoped>
</style>
