<template>
    <FlexWrap @resize="handleResize">
        
        <v-tabs-items v-model="selectedEntity" v-if="entities.length">

            <v-tab-item v-for="(slide, i) in slides" :key="i">
            
                <template>
                    <v-card tile flat class="tab-item-wrapper pa-4">

                        <v-tabs class="fo-tabs" v-model="selectedFO" show-arrows height="66">

                            <v-tab v-for="(n, k) in displayTotals(slide)" :key="k" >
                                <v-list-item two-line class="px-0">
                                    <v-list-item-content class="text-left">
                                        <v-list-item-title class="ma-0">
                                            {{ capitalize(k)}}
                                        </v-list-item-title>
                                        <v-list-item-subtitle class="mb-2">
                                            {{ currency( n ) }}
                                        </v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-tab>

                        </v-tabs>

                        <v-tabs-items v-model="selectedFO">

                            <v-tab-item v-for="fo in slidesFO" :key="fo.type">

                                <!-- Grid View -->
                                <template v-if="fo.type == 'all'">

                                    <v-card tile flat class="px-4" :disabled="readOnly">
                                        <div class="title pt-4">
                                            {{ slide ? getListViewTitle(slide, fo.name) : `All Financial Objects` }}
                                        </div>

                                        <!-- Totals -->
                                        <v-container fluid class="foe-total-heading">
                                            <v-row dense>
                                                <template v-for="(n, k) in displayTotals(slide)" v-if="k != 'all'">
                                                    <v-col class="pb-0" cols="6" md="3" :key="k">
                                                        <v-card @click="getRecordsInfo(k)" flat :ripple="false">
                                                            <v-card-text class="pa-0 text-center light_gray_2--text">
                                                                <div class="overline font-weight-medium">{{k}}</div>
                                                                <div v-if="bp.smAndDown" class="body-1 font-weight-medium text-no-wrap light_gray_1 rounded-lg">
                                                                    <div class="py-2">{{ currency(n) }}</div>
                                                                </div>
                                                                <div v-else class="headline font-weight-medium text-no-wrap">{{ currency(n) }}</div>
                                                            </v-card-text>
                                                        </v-card>
                                                    </v-col>
                                                </template>
                                            </v-row>
                                        </v-container>

                                        <!-- Grid -->
                                        <v-container fluid :class="['fo-table', {'fo-table-mobile': bp.smAndDown}]">
                                            <v-row v-for="(collection, key) in buildGrid(slide)" :key="key"
                                                    dense
                                                    class="light_gray_1 mb-2 pa-1">
                                                <v-col v-for="(data, index) in collection" :key="index" cols="12" md="3">

                                                    <v-card v-if="data.status == 'unavailable'" flat disabled height="100%" 
                                                        class="d-flex overflow-hidden dnd-unavailable">
                                                        <v-flex align-self-center class="text-center">
                                                            <div class="body-1 font-weight-bold py-4 light_gray_2--text">
                                                                Not Available
                                                            </div>
                                                        </v-flex>
                                                    </v-card>

                                                    <template v-else-if="data.status == 'inactive'" >
                                                        <drop 
                                                            :class="['dnd-box', {'required': validateCollection(data, collection)}]" 
                                                            :disabled="bp.smAndDown"
                                                            :accepts-data="(d) => dataAcceptable(d, data, collection)" 
                                                            @drop="handleDrop($event, collection)">
                                                            <v-card flat height="100%" 
                                                                @click="openFormDialog(data, collection)"
                                                                class="dnd-box-card d-flex overflow-hidden">
                                                                <v-flex align-self-center class="text-center">
                                                                    <div class="body-1 font-weight-bold py-4 light_gray_2--text text-capitalize">
                                                                        Add {{ data.type | toSingularResource }} {{ development ? `${data.id}` : ''}}
                                                                    </div>
                                                                </v-flex>
                                                            </v-card>
                                                        </drop>
                                                    </template>
                                                    
                                                    <template v-else >
                                                        <drag 
                                                            class="dnd-box" 
                                                            :disabled="bp.smAndDown"
                                                            :data="data">

                                                            <v-card flat height="100%" 
                                                                :ripple="false"
                                                                @click="openFODialog(data, collection)"
                                                                class="draggable-item d-flex flex-column overflow-hidden pa-2">

                                                                <!-- Roles -->
                                                                <v-card-subtitle class="pa-2"> 
                                                                    <v-chip v-if="slide" :filter="false" :ripple="false" class="light_gray_1 mb-1 mr-1 font-weight-bold" small>
                                                                        {{ getNameByRoles(slide) }}
                                                                    </v-chip>
                                                                    <v-chip v-else :filter="false" :ripple="false" class="light_gray_1 mb-1 mr-1 font-weight-bold" small v-for="entity in getEntityData(data)" :key="entity.id">
                                                                        {{ getNameByRoles(entity) }}
                                                                    </v-chip>
                                                                </v-card-subtitle>

                                                                <v-card-text class="pb-2 d-flex flex-column"> 
                                                                    <caption class="pb-1 text-left" v-if="development">
                                                                        {{ data.relationships.kind.data.id }} {{data.id}}
                                                                    </caption>
                                                                    <caption v-for="(item, i) in getCardData(data)" :key="i" class="pb-1 text-left">
                                                                        
                                                                        <template v-if="i == 'type'">
                                                                            <div class="body-1 font-weight-bold">
                                                                            {{ formatCardData(i, item) }}
                                                                            </div>
                                                                        </template>
                                                                        <template v-else>
                                                                            {{ formatCardData(i, item) }}
                                                                        </template>

                                                                    </caption>
                                                                </v-card-text>

                                                                <v-spacer/>
                                                                
                                                                <v-card-actions>

                                                                    <v-chip v-if="showPercentageChip(data)" small class="success font-weight-bold mr-3">{{ displayPercentage(data) }}</v-chip>
                                                                    
                                                                    <template v-if="getBoxStatus(data) == 'checked'">    
                                                                        <v-chip small class="px-1 secondary lighten-5">
                                                                            <v-icon size="16" color="secondary">
                                                                                mdi-check
                                                                            </v-icon>
                                                                        </v-chip>
                                                                    </template>

                                                                    <template v-if="getBoxStatus(data) == 'error'">    
                                                                        <v-chip small class="px-1 warning lighten-4">
                                                                            <v-icon size="16" color="warning">
                                                                                mdi-exclamation
                                                                            </v-icon>
                                                                        </v-chip>
                                                                    </template>  

                                                                    <v-spacer/>

                                                                    <div class="text-no-wrap">
                                                                        <v-btn text depressed fab x-small :ripple="false" color="light_gray_2" @click.stop="openFormDialog(data, collection)"> 
                                                                            <v-icon size="20">mdi-pencil</v-icon>
                                                                        </v-btn>
                                                                        <ConfirmModal 
                                                                            async
                                                                            :title="`Delete ${capitalize(toSingular(data.type))}`"
                                                                            :processing="deleting"
                                                                            :message="buildDeleteMsg(data)"
                                                                            @confirm="handleConfirm(data, collection)">
                                                                            <template v-slot:activator="{on}">
                                                                                <v-btn text depressed fab x-small :ripple="false" color="light_gray_2" @click.stop="on"> 
                                                                                    <v-icon size="20">mdi-delete</v-icon>
                                                                                </v-btn>
                                                                            </template>
                                                                        </ConfirmModal>
                                                                    </div>

                                                                </v-card-actions>
                                                            </v-card>

                                                        </drag>
                                                    </template>

                                                </v-col>
                                            </v-row>
                                        </v-container>

                                        <div class="d-flex">
                                            <v-spacer></v-spacer>
                                            <v-btn color="secondary" large depressed class="ml-3" @click="addFO('incomes')">New Income</v-btn>
                                            <v-btn color="secondary" large depressed class="ml-3" @click="addFO()">New FO</v-btn>
                                        </div>
                                    </v-card>

                                </template>

                                <!-- List View -->
                                <template v-else>
                                    <v-card tile flat class="px-4" :disabled="readOnly">
                                        
                                        <div class="d-flex">
                                            <v-card-title class="px-0 title">
                                                {{ getListViewTitle(slide, fo.name) }}
                                            </v-card-title>
                                            <v-spacer></v-spacer>
                                            <v-btn color="secondary" large depressed class="mt-4" @click="printTable(slide, fo)">
                                                Print
                                            </v-btn>
                                        </div>
                                        <v-card-text class="px-0">

                                            <v-data-table
                                                class="v-data-table-1"
                                                :headers="tableHeaders"
                                                :items="buildTable(slide, fo.type)"
                                                hide-default-footer
                                                :items-per-page="100"
                                                no-data-text="No Records"
                                                :mobile-breakpoint="0">

                                                <!-- Table Head -->
                                                <template v-slot:header.amount="props">
                                                    <span>{{props.header.text[fo.type]}}</span>
                                                </template>

                                                <!-- Table Body -->
                                                <template v-slot:item.description="props">
                                                    <div class="cell top">
                                                        <div class="text-truncate font-weight-bold">{{ props.item.description.main }}</div>
                                                        <div class="text-truncate">{{ props.item.description.sub }}</div>
                                                    </div>
                                                </template>

                                                <template v-slot:item.owners="props">
                                                    <div class="cell top">
                                                        <div class="text-truncate" v-for="(owner, i) in props.item.owners" :key="`${owner}-${i}`">
                                                            {{ owner }}
                                                        </div>
                                                    </div>
                                                </template>

                                                <template v-slot:item.share="props">
                                                    <div v-for="(share, i) in props.item.share" :key="`${share}-${i}`">
                                                        {{ share }}
                                                    </div>
                                                </template>

                                                <template v-slot:item.start-date="props">
                                                    {{ props.item.extra.data.attributes['start-date'] | dateFormatFE_2 }}
                                                </template>

                                                <template v-slot:item.end-date="props">
                                                    {{ props.item.extra.data.attributes['end-date'] | dateFormatFE_2 }}
                                                </template> 
                                                
                                                <template v-slot:item.links="props">
                                                    <div class="cell top">
                                                        <div class="text-truncate" v-for="(link, i) in props.item.links" :key="`${link.type}-${i}`">
                                                            <span class="pill">{{ link.type }}</span> <span>{{ link.name }}</span>
                                                        </div>
                                                    </div>
                                                </template>

                                                <template v-slot:item.ctrl="props">
                                                    <div class="d-flex justify-end">

                                                        <v-btn text depressed fab color="light_gray_2" small @click.stop="openFormDialog(props.item.extra.data, props.item.extra.collection)"> 
                                                            <v-icon size="20">mdi-pencil</v-icon>
                                                        </v-btn>
                                                        
                                                        <ConfirmModal 
                                                            async
                                                            :title="`Delete ${capitalize(toSingular(fo.type))}`"
                                                            :processing="deleting"
                                                            :message="buildDeleteMsg(props.item.extra.data)"
                                                            :confirmText="'Delete'"
                                                            @confirm="handleConfirm(props.item.extra.data, props.item.extra.collection)">
                                                            <template v-slot:activator="{on}">
                                                                <v-btn text depressed fab x-small :ripple="false" color="light_gray_2" @click.stop="on"> 
                                                                    <v-icon size="20">mdi-delete</v-icon>
                                                                </v-btn>
                                                            </template>
                                                        </ConfirmModal>
                                                    </div>
                                                </template>

                                            </v-data-table>

                                        </v-card-text>

                                        <v-card-actions>
                                            <v-spacer></v-spacer>
                                            <v-btn color="secondary" :min-width="140" depressed nuxt large @click="addFO(fo.type)">
                                                Add {{ capitalize(toSingular(fo.type)) }}
                                            </v-btn>
                                        </v-card-actions>

                                    </v-card>

                                </template>

                            </v-tab-item>
                            
                        </v-tabs-items>


                    </v-card>
                </template>

            </v-tab-item>
            
        </v-tabs-items>

        <!-- FO Dialog Record Information -- Datatable -->
        <v-dialog v-model="recordDialog">

            <v-card flat>

                <v-card-text>

                    <v-card tile flat class="mx-auto"> 

                        <v-card-title class="pa-8">
                            {{capitalize(recordFOType)}}
                        </v-card-title>

                        <v-card-text>
                            <RecordInfoTable
                                :record-type="recordFOType"
                            />
                        </v-card-text>  

                    </v-card>
                </v-card-text>

                <v-card-actions class="pa-4 pa-md-6 pt-md-0 flex-wrap controls">
                    <v-spacer></v-spacer>
                    <v-btn color="light_gray_2" :min-width="140" depressed nuxt outlined large @click="recordDialog = false">Close</v-btn> 
                </v-card-actions>

            </v-card>
        </v-dialog>

        <!-- FO Card Dialog -->
        <v-dialog
            v-if="dialogData"
            v-model="dialog"
            max-width="420"
            class="elevation-1"
            >
                <v-card flat>
                    <v-card-title class="px-6 pt-4">
                        <v-subheader class="overline pl-0">
                            {{dialogData.type}}
                        </v-subheader>
                        <v-spacer/>
                        <v-btn icon @click="dialog = false">
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                    </v-card-title>

                    <v-card-text class="px-8 py-0"> 

                        <!-- Roles -->
                        <template v-if="selectedEntityData">
                            <v-chip :filter="false" :ripple="false" class="light_gray_1 mb-1 mr-1 font-weight-bold" small>
                                {{ getNameByRoles(selectedEntityData) }}
                            </v-chip>
                        </template>  
                        <template v-else>
                            <v-chip :filter="false" :ripple="false" class="light_gray_1 mb-1 mr-1 font-weight-bold" small 
                                v-for="entity in getEntityData(dialogData)" :key="entity.id">
                                {{ getNameByRoles(entity) }}
                            </v-chip>
                        </template>  

                        <!-- Data -->
                        <div v-for="(item, key) in getCardData(dialogData, true)" :key="key" class="d-flex flex-column">
                            <div class="text-left">
                                {{ labels[key] }}
                            </div>
                            <div v-if="key == 'type'" class="v-card__title my-6 pa-0 font-weight-bold text-left">
                                {{ formatCardData(key, item) }}
                            </div>
                            <div v-else class="body-1 mb-2 font-weight-medium text-left">
                                {{ formatCardData(key, item) }}
                            </div>
                        </div>  

                    </v-card-text>

                    <v-card-actions class="px-8 pb-6">

                        <v-chip v-if="showPercentageChip(dialogData)" small class="success font-weight-bold mr-3">{{ displayPercentage(dialogData) }}</v-chip>
                        
                        <template v-if="getBoxStatus(dialogData) == 'checked'">    
                            <v-chip small class="px-1 secondary lighten-5">
                                <v-icon size="16" color="secondary">
                                    mdi-check
                                </v-icon>
                            </v-chip>
                        </template>   

                        <template v-if="getBoxStatus(dialogData) == 'error'">    
                            <v-chip small class="px-1 warning lighten-4">
                                <v-icon size="16" color="warning">
                                    mdi-exclamation
                                </v-icon>
                            </v-chip>
                        </template>   

                        <v-spacer/>

                        <div>
                            <v-btn text depressed fab small :ripple="false" color="light_gray_2" @click.stop="openFormDialog(dialogData, dialogCollection)"> 
                                <v-icon size="20">mdi-pencil</v-icon>
                            </v-btn>
                            <ConfirmModal 
                                async
                                :title="`Delete ${capitalize(toSingular(dialogData.type))}`"
                                :processing="deleting"
                                :message="buildDeleteMsg(dialogData)"
                                @confirm="handleConfirm(dialogData, dialogCollection)">
                                <template v-slot:activator="{on}">
                                    <v-btn text depressed fab small :ripple="false" color="light_gray_2" @click.stop="on"> 
                                        <v-icon size="20">mdi-delete</v-icon>
                                    </v-btn>
                                </template>
                            </ConfirmModal>
                        </div>

                    </v-card-actions>
                </v-card>
        </v-dialog>

        <!-- FO Form Dialog -->
        <v-dialog
            v-model="formDialog"
            persistent
            scrollable
            max-width="1124px">

            <v-card tile flat class="rounded-lg flex-dialog">
                
                <v-progress-linear color="secondary" indeterminate stream absolute top v-if="progress"></v-progress-linear>

                <v-card-title>
                    <v-spacer/>
                    <v-btn icon @click="cancel">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-card-title>

                <v-card-text>
                    
                    <v-card tile flat :disabled="progress" v-if="formDialogData">

                        <v-card-text class="pa-4 pb-8 mx-0 px-0 py-0">
                            <v-card tile flat class="rounded-lg mx-auto" :width="dialogType == 'income' ? 1000 : 1000">

                                <template v-if="dialogType == 'fo'">
                                    <v-container>
                                        <FOForms :data="formDialogData" standalone :fields-data="formDialogFieldsData" :ref="`foForm`"/>
                                    </v-container>
                                </template>

                                <template  v-if="dialogType == 'income'">
                                    <v-container>

                                        <FOIncomeForms 
                                            :data="formDialogData" 
                                            standalone 
                                            :fields-data="formDialogFieldsData"
                                            :ref="`incomeForm`"/>

                                    </v-container>
                                </template>

                            </v-card>
                        </v-card-text>

                    </v-card>

                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions class="pa-4 pa-md-6 flex-wrap controls">
                    <v-btn :disabled="loading" color="secondary" :min-width="140" depressed nuxt large @click="save">Save & Close</v-btn> 
                    <v-spacer></v-spacer>
                    <v-btn :disabled="loading" color="light_gray_2" :min-width="140" depressed outlined nuxt large @click="cancel">Back</v-btn> 
                </v-card-actions>

            </v-card>

        </v-dialog>

        <!-- printable dialog for table -->
        <FoPrintTable
            :dialog="printDialog"
            :fo-title="getListViewTitle(foObject['slide'], foObject['fo-name'])"
            :table-header="tableHeaders"
            :fo-type="foObject['fo-type']"
            :fo-object="buildTable(foObject['slide'], foObject['fo-type'])"
            @close="closePrint"/>

        <ConfirmModal 
            async
            color="secondary"
            title="Confirm"
            :value="dropConfirmDialog"
            :standalone="false"
            :processing="progress"
            :message="`Move ${dropData.name || ' this record'}?`"
            @close="closeDropConfirm"
            @confirm="acceptDropConfirm" />

        <!-- This will replace Next button in StepIncrementer1  -->
        <Portal to="step-incrementer-next">
            <v-btn color="secondary" large depressed nuxt :min-width="btnWidth" class="ml-3" @click="validateTable" :disabled="!getNextStep || isDisabled">Next</v-btn>
        </Portal>

    </FlexWrap>    

</template>

<script>

    import { mapState, mapActions, mapGetters, mapMutations } from 'vuex'
    import {store, actions} from '~/components/v1/FinancialObjectsEditor1/__store'

    import FOForms from '~/components/v1/FinancialObjectsEditor1/_FOForms'
    import FOIncomeForms from '~/components/v1/FinancialObjectsEditor1/_FOIncomeForms'
    import RecordInfoTable from '~/components/v1/FinancialObjectsEditor1/_FORecordInfoTable'
    import FoPrintTable from './_PrintModal'
    

    import foCardData from '~/components/v1/FinancialObjectsEditor1/fo-card-data'
    import foLabels from '~/components/v1/FinancialObjectsEditor1/fo-labels'

    import shared from '~/components/v1/FinancialObjectsEditor1/__shared'
    import ConfirmModal from '~/components/base/ConfirmModal2'
    import validation from '~/components/shared/validation' 


    import FlexWrap from '~/components/base/FlexWrap'
    import { Drag, Drop } from "vue-easy-dnd"
    import moment from 'moment'

    export default {
        components: {
            FOForms,
            FOIncomeForms,
            RecordInfoTable,
            ConfirmModal,
            FlexWrap,
            Drag,
            Drop,
            FoPrintTable
        },
        mixins: [
            shared,
            validation,
        ],
        props: {
            isDisabled: {
                type: Boolean,
                default: false
            },
            readOnly: {
                type: Boolean,
                default: false
            },
            label: {
                type: String,
                default: ""
            },
        },
        data() {
            return {
                foObject: {
                    "fo-name": '',
                    "slide": '',
                    "fo-type": ''
                },
                printDialog: false,
                sizes: null,
                tableErrorsCount: '',

                dialog: false,
                dialogData: null,
                dialogCollection: null,
                
                recordDialog: false,
                recordFOType: '',

                formDialogData: null,
                formDialogFieldsData: null,

                foCardData: foCardData,
                selected: [],

                builtTable: null,

                dropConfirmDialog: false,
                dropData: { name: null, record: null, fos: null },

                deleting: false,
                deleteWarningMsg: 'This action will delete this Financial Object Record, not just this shared portion. If you would like to remove the Role from this Financial Object, but not delete the record entirely, please EDIT the record and unshare from this Role.',


                // List View
                listViewTableHeaders: [
                    { sortable: false, text: 'Description', align: 'start', value: 'description', width: '12.50%' },
                    { sortable: false, text: 'Owners', value: 'owners', width: '12.50%' },
                    { sortable: false, text: 'Share', value: 'share', width: '12.50%' },
                    { sortable: false, text: 'Start Date', value: 'start-date', width: '12.50%' },
                    { sortable: false, text: 'End Date', value: 'end-date', width: '12.50%' },
                    { sortable: false, text: {incomes: 'Monthly Amount', assets: 'Asset Value', liabilities: 'Amount Owing', expenses: 'Monthly Amount'}, value: 'amount', width: '12.50%' },
                    { sortable: false, text: 'Links', value: 'links', width: '12.50%' },
                    { sortable: false, text: '', align: 'end', value: 'ctrl', width: '12.50%' },
                ]
            }
        },
        computed: {
            ...mapGetters('kinds', ['kinds', 'kindsUnformat']),
            ...mapGetters('flows', ['getPrevStep', 'getNextStep']),
            selectedFO: {
                get(){
                    return store.selectedFO
                },
                set(value){
                    actions.setSelectedFO(value)
                }
            },
            selectedEntityData(){
                
                let index = this.selectedEntity

                if(this.entities[index]) return this.entities[index];

                return null

            },
            slides(){

                let count = store.entities.length

                if(count == 1) {
                    return [
                        ...store.entities
                    ]
                }

                return [
                    ...store.entities,
                    null
                ]
            },
            slidesFO(){
                return store.slidesFO
            },
            labels(){
                const {incomes, assets, liabilities, expenses} = foLabels
                return {
                    ...incomes, 
                    ...assets, 
                    ...liabilities, 
                    ...expenses
                }
            },
            formDialog(){
                return store.standaloneDialog
            },
            progress(){
                return store.saving
            },
            dialogType(){
                return store.dialogType
            },
            foAddress(){
                return store.resources.addresses
            },
            btnWidth () {
                switch (this.$vuetify.breakpoint.name) {
                    case 'xs': return 0
                    case 'lg': 
                    case 'xl':
                    case 'md': 
                    case 'sm': return 140
                }
            },
            tableHeaders() {

                if ( !this.sizes ) return

                const { sm, xs } = this.sizes
                
                let newHeaders = [
                    ...this.listViewTableHeaders
                ]

                let heads = [
                    { value: 'description', width: '12.50%' },
                    { value: 'owners', width: '12.50%' },
                    { value: 'share', width: '12.50%' },
                    { value: 'amount', width: '12.50%' },
                    { value: 'links', width: '12.50%' },
                    { value: 'ctrl', width: '12.50%' }
                ]

                if ( this.selectedFO === 0 ) {
                    heads = [
                        { value: 'description', width: '12.50%' },
                        { value: 'owners', width: '12.50%' },
                        { value: 'share', width: '12.50%' },
                        { value: 'start-date', width: '12.50%' },
                        { value: 'end-date', width: '12.50%' },
                        { value: 'amount', width: '12.50%' },
                        { value: 'links', width: '12.50%' },
                        { value: 'ctrl', width: '12.50%' }
                    ]
                }
                
                if ( sm ) {                    
                    heads = [ 
                        { value: 'description', width: '20%' },
                        { value: 'owners', width: '20%' },
                        { value: 'share', width: '20%' },
                        { value: 'amount', width: '20%' },
                        { value: 'ctrl', width: '20%' }
                    ]
                }

                if(xs){
                    heads = [ 
                        { value: 'description', width: '33.33%' },
                        { value: 'owners', width: '33.33%' },
                        { value: 'ctrl', width: '33.33%' }
                    ]
                }

                // return newHeaders.filter( head => heads.includes(head.text))
                return newHeaders.reduce((accumulator, current) => {

                    heads.forEach(head => {

                        if(head.value == current.value){

                            let newCurrent = {...current}

                            newCurrent.width = head.width

                            accumulator.push(newCurrent)
                        }

                    })

                    return accumulator 

                }, [])

            },
            loading(){
                return store.saving
            },
        },
        mounted(){


            actions.setValidate( this.softValidateTable )

        },
        methods: {
            ...mapActions('resource-actions', [
                'createIncome',
                'createAsset',
                'createLiability',
                'createExpense',
                'updateIncome',
                'updateAsset',
                'updateLiability',
                'updateExpense',
                'deleteIncome',
                'deleteAsset',
                'deleteExpense',
                'deleteLiability',
                'getIncome',
                'getAsset',
                'getExpense',
                'getLiability'
            ]),
            ...mapMutations('dialogs', [
                'toggleTouched',
            ]),
            async printTable (slide, fo) {

                this.foObject['fo-type'] = await fo.type
                this.foObject['fo-name'] = await fo.name
                this.foObject['slide'] = await slide
                
                this.printDialog = !this.printDialog

                setTimeout (() => {
                    window.print()
                }, 1000)
            },
            closePrint () {
                this.printDialog = !this.printDialog
            },
            openFODialog(data, collection){

                this.dialogData = data
                this.dialogCollection = collection
                this.dialog = !this.dialog

            },
            toggleDialog(data){
                this.dialog = !this.dialog
            },
            getRoles(data){
                
                let roles = ''

                this.entities.forEach(entity => {

                    if(data.id == entity.id){

                        roles = (entity.roles.length > 1) ? `${entity.roles[0]} +${entity.roles.length - 1}`: (entity.roles.length == 1) ? entity.roles[0] : ''
                    }
                })

                return roles

            },
            getNameByRoles(data){
                
                let fullname = ''

                this.entities.forEach(entity => {

                    if(data.id == entity.id){

                        fullname = this.getEntityFriendlyName(entity)
                    }
                })

                let len = 8

                if(fullname.length > len){
                    fullname = fullname.substring(0, len) + '...'
                }

                return fullname

            },
            getCardData(data, long = false){
                
                const {foCardData, foAddress} = this
                let dataObj = {}

                if(data) {

                    // ✔️kind id is always present, made sure by buildGrid()
                    let kindId = data.relationships.kind.data.id 
                    let kindName = this.getKindName(kindId)
                    let collection = []

                    // dataObj.id = kindId
                    dataObj.type = kindName
                    // dataObj.attributes = {}

                    Object.keys(foCardData).forEach( keys => {

                        let keysArray = keys.split('|')

                        if( keysArray.includes(kindId) ){

                            collection = long ? foCardData[keys].long :  foCardData[keys].short
                        }

                    })


                    for (let i = 0; i < collection.length; i++) {

                        const item = collection[i];

                       if(item == '__full-address'){

                           const addressId = data.relationships.address.data?.id

                            if(addressId && foAddress[data.type]){

                                const address = foAddress[data.type].find(address => {
                                    return address.id == addressId
                                })

                                dataObj[item] = this.getFullAddress(address)

                            }

                           continue
                        }

                       if(item == '__sum-living-expenses'){

                            let total = 0;
                            let expenses = ["living-food-and-clothing",
                                        "living-transport",
                                        "living-utilities-and-rates",
                                        "living-lifestyle",
                                        "living-tv-and-communications",
                                        "living-child-care-fees",
                                        "living-family-maintenance",
                                        "living-insurance",
                                        "living-education",
                                        "living-other-living-expenses"];

                            expenses.forEach( expense => {
                                
                                total = total += parseFloat(data.attributes[expense]);

                            });

                            dataObj[item] = total
                           
                           continue
                        }

                       if(item == '__monthly-payment'){
                            // do we need this?...    
                            continue
                        }

                        if (item == '__monthly-payment-amount') {
                            dataObj[item] = this.getMonthlyAmount(data.attributes['payment-amount'], data.attributes['payment-frequency'])
                            continue
                        }

                        if( data.attributes[item] ){
                            dataObj[item] = data.attributes[item]
                        }
                    }

                }
                
                return dataObj
            },
            openFormDialog(fo, siblings = null){

                // console.log(fo)
                // console.log(siblings)

                let type = (fo.type == 'incomes') ? 'income' : 'fo'

                actions.setDialogType(type)
                actions.openStandaloneDialog()

                // Update FO
                if(fo.hasOwnProperty('attributes')){

                    fo = {
                        id: fo.relationships.kind.data.id,
                        status: "active",
                        type: fo.type
                    }

                    actions.setSelectedFOName(fo.type)

                }

                this.formDialogData = fo
                this.formDialogFieldsData = siblings

                this.toggleTouched(true)

            },
            save(){

                const {dialogType} = this

                if(dialogType == 'fo' && this.$refs.foForm){

                    this.$refs.foForm.save().then(res => {
                        if(res.status == 'ok'){
                            this.$emit('saved')
                            this.cancel()
                        }
                    })

                }

                if(dialogType == 'income' && this.$refs.incomeForm){

                    this.$refs.incomeForm.save().then(res => {
                        if(res.status == 'ok'){
                            this.$emit('saved')
                            this.cancel()
                        }
                    })

                }

            },
            cancel(){

                actions.closeStanaloneDialog()

                setTimeout(() => {
                    this.reset()
                }, 300)

            },
            reset(){

                this.formDialogData = null
                this.formDialogFieldsData = null

            },
            getBoxStatus(data){

                const statuses = [
                    'being-refinanced',
                    'being-sold-traded',
                    'to-be-paid-out'
                    ]

                let output = []

                statuses.forEach( status => {

                    if( data.hasOwnProperty('attributes') ) {

                        if(data.attributes.hasOwnProperty(status)){

                            if(data.attributes[status]){

                                if(data.attributes[status].toLowerCase() == 'yes'){

                                    output.push('error')

                                } else {

                                    output.push('checked')
                                }

                            }

                        }

                    }

                });

                if(output.length == 0){
                     return 'none';
                }

                if(output.includes('error')){
                     return 'error';
                }
                
                return 'checked';
            },
            formatCardData(key, value) {

                var formats = [
                    "YYYY/MM/DD", "DD/MM/YYYY", "YYYY-MM-DD", "DD-MM-YYYY"
                ]
                
                let currencies = this.currencyFields

                let percent = [
                    'interest-rate',
                ]

                if( currencies.includes(key) ) {
                    return this.currency(value)
                }

                if( percent.includes(key) ) {
                    return `${value}%` 
                }

                if ( moment(value, formats, true).isValid() ) {
                    return this.$options.filters.dateFormatFE(value)
                }

                return value
            },
            displayTotals(entity){

                const  {buildGrid} = this
                const  {primaryApplicant} = this

                let collection = buildGrid(entity)

                let amounts = {
                    incomes: 0,
                    assets: 0,
                    liabilities: 0,
                    expenses: 0,
                    all: 0
                }

                Object.keys(collection).forEach(fos => {
                    
                    collection[fos].forEach(fo => {

                        if(fo.hasOwnProperty('attributes')){

                            let field = ''

                            if(fo.type == 'incomes'){
                                field = 'net-monthly-amount'
                            }

                            if(fo.type == 'assets'){
                                field = 'asset-value'
                            }

                            if(fo.type == 'liabilities'){
                                field = 'amount-owing'
                            }

                            if(fo.type == 'expenses'){
                                field = 'payment-amount'
                            }

                            if(fo.attributes[field]) {

                                let split = fo.relationships.people.data.length + fo.relationships.businesses.data.length

                                let amount = parseFloat(fo.attributes[field])

                                if(fo.type == 'expenses'){
                                    amount = this.getMonthlyAmount(amount, fo.attributes['payment-frequency']) 
                                }

                                if(fo.type == 'incomes'){

                                    let primary = primaryApplicant
                                    let selectedEntity = entity
                                    let incomeSplit = 100

                                    amount = this.getMonthlyAmount(amount, fo.attributes['payment-frequency']) 

                                    if(selectedEntity == null) {
                    
                                        incomeSplit = 100

                                    } else if(primary?.id == selectedEntity?.id) {

                                        incomeSplit = fo?.attributes?.["primary-income-split"] || 100

                                    } else {

                                        if(fo?.attributes?.["primary-income-split"] != 100) {
                                            incomeSplit = 100 - fo?.attributes?.["primary-income-split"]
                                        }

                                    }

                                    amount = (incomeSplit * amount) / 100

                                    if(fo.attributes['income-situation'] === "Previous") {
                                        amount = 0
                                    }

                                } else {

                                    if(entity){
                                        amount = amount / split
                                    }

                                }


                                amounts[fo.type] = amounts[fo.type] + amount
                                amounts.all =  amounts.all + amount

                            }


                        }
                        
                    })

                });

                return amounts

            },
            displayPercentage(fo, entity = null){

                const  {primaryApplicant, selectedEntityData} = this

                // console.log("entity", entity)
                // console.log("selectedEntityData" , selectedEntityData)
                // console.log("primaryApplicant" , primaryApplicant)

                let primary = primaryApplicant
                let selectedEntity = selectedEntityData
                let percent = 100

                if(fo && fo.type == 'incomes'){

                    let split = fo?.attributes?.["primary-income-split"] || 100

                    if(entity) {
                        selectedEntity = entity
                    }

                    if(selectedEntity == null) {
                    
                        percent = 100

                    } else if(primary?.id == selectedEntity?.id) {

                        percent = split

                    } else {

                        if(split != 100) {
                            percent = (100 - split)
                        }

                    }

                } else {

                    let people = fo.relationships.people.data.length
                    let businesses = fo.relationships.businesses.data.length
                    let split = people + businesses
                    
                    percent = percent / split
                }

                if(percent % 1 != 0){
                    return `${percent.toFixed(2)}%`
                } else {
                    return `${parseInt(percent)}%`
                }

            },
            handleConfirm(data, collection){
                
                this.deleteFO(data, collection)

            },
            async saveFO(record, financialObjects){

                actions.toggleSaving(true)

                try {

                    let payload = {
                        type: record.type,
                        attributes: record.attributes,
                        relationships: {
                            application: record.relationships.application,
                            kind: record.relationships.kind,
                            people: {
                                data: []
                            },
                        }
                    }

                    let res = null
                    let fos = ['incomes', 'assets', 'liabilities', 'expenses']

                    // Include existing FOs if it matches

                    let peopleIds = []
                    let relatedFos = {}

                    fos.forEach( (type, index) => {

                        if(record.relationships[type]){

                            let fo = financialObjects[index]

                            if(fo.hasOwnProperty('attributes')){
                                        
                                let data = {type, id: fo.id} 

                                payload.relationships[type] = {
                                    data: [data]
                                } 

                                relatedFos[type] = data

                                fo.relationships.people.data.forEach(person => {
                                    
                                    if( !peopleIds.includes(person.id) ){

                                        payload.relationships.people.data.push(person)

                                    }

                                });

                            }

                        }

                    })

                    if(record.type == fos[0]){
                        res = await this.createIncome(payload)
                    }

                    if(record.type == fos[1]){
                        res = await this.createAsset(payload)
                    }

                    if(record.type == fos[2]){
                        res = await this.createLiability(payload)
                    }

                    if(record.type == fos[3]){
                        res = await this.createExpense(payload)
                    }

                    await this.updateLocalResources(res, relatedFos)

                    actions.toggleSaving(false)

                } catch (error) {

                    console.log('Ooops!..', error)
                    actions.toggleSaving(false)

                }


            },
            async updateFO(record, financialObjects) {

                actions.toggleSaving(true)

                try {

                    let payload = {
                        id: record.id,
                        type: record.type,
                        relationships: {
                            application: record.relationships.application,
                            kind: record.relationships.kind,
                            people: {
                                data: [],
                                meta: {
                                    strategy: 'replace'  
                                }
                            },
                            businesses: {
                                data: [],
                                meta: {
                                    strategy: 'replace'  
                                }
                            },
                        }
                    }

                    let res = null
                    let fos = ['incomes', 'assets', 'liabilities', 'expenses']

                    // Include existing FOs if it matches

                    let entityIds = []
                    let relatedFos = {}

                    fos.forEach( (type, index) => {

                        if(record.relationships[type]){

                            let fo = financialObjects[index]

                                // Add entities from destination
                            if(fo.hasOwnProperty('attributes')){

                                let data = {type, id: fo.id} 

                                payload.relationships[type] = {
                                    data: [data],
                                    meta: {
                                        strategy: 'replace'  
                                    }
                                } 

                                relatedFos[type] = data

                                fo.relationships.people.data.forEach(person => {
                                    if( !entityIds.includes(person.id) ){
                                        payload.relationships.people.data.push(person)
                                        entityIds.push(person.id)
                                    }
                                });

                                fo.relationships.businesses.data.forEach(business => {
                                    if( !entityIds.includes(business.id) ){
                                        payload.relationships.businesses.data.push(business)
                                        entityIds.push(business.id)
                                    }
                                });
                            
                            } else {

                                payload.relationships[type] = {
                                    data: [],
                                    meta: {
                                        strategy: 'replace'  
                                    }
                                } 

                            }

                        }

                    })


                    if(record.type == fos[0]){
                        res = await this.updateIncome(payload)
                    }

                    if(record.type == fos[1]){
                        res = await this.updateAsset(payload)
                    }

                    if(record.type == fos[2]){
                        res = await this.updateLiability(payload)
                    }

                    if(record.type == fos[3]){
                        res = await this.updateExpense(payload)
                    }


                    // No update local state yet...
                    await this.updateLocalResources(res, relatedFos)

                    await this.updateEntitiesAndParts()

                    store.validate()

                    actions.toggleSaving(false)

                } catch (error) {

                    console.log('Ooops!..', error)
                    actions.toggleSaving(false)

                }


            },
            async deleteFO(data, collection){

                // console.log(data)
                // console.log(collection)

                this.deleting = true

                try {
                    
                    if(data.type == 'incomes'){
                        await this.deleteIncome(data.id)
                    }

                    if(data.type == 'assets'){
                        await this.deleteAsset(data.id)
                    }

                    if(data.type == 'expenses'){
                        await this.deleteExpense(data.id)
                    }

                    if(data.type == 'liabilities'){
                        await this.deleteLiability(data.id)
                    }

                    // Update local states
                    actions.deleteResource([data])
                    
                    await this.updateEntitiesAndParts()

                    store.validate()

                    this.deleting = false

                } catch (error) {

                    console.log('Ooops!..', error)
                    this.deleting = false

                }
                

            },
            async updateLocalResources(data, relatedFos){

                let payload = [data]

                if(relatedFos['incomes']){
                    let res4 = this.getIncome(relatedFos.incomes.id)
                    payload.push(res4)
                }

                if(relatedFos['assets']){
                    let res1 = this.getAsset(relatedFos.assets.id)
                    payload.push(res1)
                }

                if(relatedFos['liabilities']){
                    let res3 = this.getLiability(relatedFos.liabilities.id)
                    payload.push(res3)
                }

               if(relatedFos['expenses']){
                    let res2 = this.getExpense(relatedFos.expenses.id)
                    payload.push(res2)
                }

                try {

                    let data = await Promise.all(payload)

                    actions.updateResources(data)

                } catch (error) {

                    console.log('Ooops!..', error)

                }

            },

            buildDeleteMsg(data){

                const {entities, getKindName} = this

                let recordKind = getKindName(data.relationships.kind.data?.id)

                let roles = [
                    ...data.relationships.people.data,
                    ...data.relationships.businesses.data
                ]

                // ${resource}

                let msg = `Delete ${recordKind} for `

                roles.forEach((role, index) => {

                    entities.forEach(entity => {

                        if(entity.id == role.id){

                            let binder = ', '

                            if(index == 0) {
                                binder = ''
                            }

                            if(index == (roles.length - 1) && roles.length != 1) {
                                binder = ' and'
                            }

                            let name = `${binder} ${this.getEntityFriendlyName(entity)}`

                            msg += name

                        }

                    })

                })

                return `${msg}?`
            },

            /**
             * Drag and Drop
             */
            handleDrop(evt, group){

                this.dropData = {
                    name: this.getKindName(evt.data.relationships.kind.data.id),
                    record: evt.data,
                    fos: group
                }

                this.dropConfirmDialog = true

            },
            dataAcceptable(data, target, collection) {


                // Check if relatable, e.g check if there is an asset to bind FOs to make a row

                let relatable = true

                let incompleteRow = collection.find( fo => fo.id == null)

                if(!incompleteRow && data.type != 'assets') {

                    collection.forEach( fo => {

                        if(fo.type == 'assets') {

                            if(fo.status && fo.status == 'inactive') {
                                relatable = false
                            }

                        }
                    })
                }
                
                // Check if  Record Type & FO Type matches

                let recordType = data.type == target.type
                let FOType = false

                let ids = target.id.split('|')

                FOType = ids.includes(data.relationships.kind.data.id)

                return  recordType && FOType && relatable

            },
            closeDropConfirm(){
                this.dropConfirmDialog = false
            },
            acceptDropConfirm(){

                const {record, fos} = this.dropData

                // this.saveFO(record, fos)
                this.updateFO(record, fos)

            },
            getRecordsInfo(key = '') {

                this.recordDialog = true
                this.recordFOType = key

            },
            validateTable(){

                this.tableErrorsCount = this.collectionErrorCount()
                this.$emit('next', this.tableErrorsCount)

            },
            softValidateTable(){

                this.tableErrorsCount = this.collectionErrorCount()
                this.$emit('validate', this.tableErrorsCount)

            },
            handleResize(sizes){
                this.sizes = sizes
            },
            // List View
            buildTable(entity, foCol){

                const  {buildGrid} = this

                let collection = buildGrid(entity)


                let data = []

                Object.keys(collection).forEach(row => {
                    
                    let obj = {
                        description: {
                            main: '',
                            sub: ''
                        },
                        owners: [],
                        share: '',
                        amount: '',
                        links: [],
                        extra: {
                            data: null,
                            collection: collection[row]
                        }
                    }

                    collection[row].forEach(fo => {

                        if( fo.hasOwnProperty('attributes') ){

                            let foData = this.getCardData(fo, true)

                            // console.log(foCol, foData)

                            if(fo.type == foCol){

                                // Logic for Description
                                obj.description.main = foData["type"]
                                obj.description.sub = this.getDescriptionSub(foCol, foData["type"], foData) 

                                /**
                                 * Logic for Owners
                                 */

                                obj.owners = this.getOwners(fo)

                                let ownersData = this.getOwnersData(fo)

                                /**
                                 * Logic for Shares
                                 */

                                obj.share = ownersData.map(owner => {
                                    return this.displayPercentage(fo, owner)
                                })


                                /**
                                 * Logic for Amount
                                 */

                                let amount = 0 

                                if(fo.type == 'incomes' && fo.attributes['income-situation'] !== "Previous"){
                                    amount = fo.attributes['net-monthly-amount']
                                }

                                if(fo.type == 'assets'){
                                    amount = fo.attributes['asset-value']
                                }

                                if(fo.type == 'liabilities'){
                                    amount = fo.attributes['amount-owing']
                                }

                                if(fo.type == 'expenses'){

                                    let frequency = fo.attributes['payment-frequency']
                                    let payment = fo.attributes['payment-amount']

                                    amount = this.getMonthlyAmount(payment, frequency) 
                                }

                                obj.amount = this.currency(amount)

                                /**
                                 * Extra Data
                                 */

                                obj.extra.data = fo

                                data.push(obj)

                            } else {

                                // Logic for Links

                                obj.links.push({
                                    type: fo.type.substring(0, 1).toUpperCase(),
                                    name: foData["type"]
                                })

                            }

                        }

                    })

                });


                // Sort Incomes
                
                if(foCol == 'incomes'){

                    let currentIncome = []
                    let secondIncome = []
                    let previousIncome = []

                    data.forEach(record => {

                        if(record.extra.data.attributes['income-situation'] == 'Current'){
                            currentIncome.push(record)
                        }

                        if(record.extra.data.attributes['income-situation'] == 'Secondary'){
                            secondIncome.push(record)
                        }

                        if(record.extra.data.attributes['income-situation'] == 'Previous'){
                            previousIncome.push(record)
                        }

                    })

                    currentIncome.sort( (a, b) => {
                        return new Date(b.extra.data.attributes['start-date']) - new Date(a.extra.data.attributes['start-date'])
                    })                

                    secondIncome.sort( (a, b) => {
                        return new Date(b.extra.data.attributes['start-date']) - new Date(a.extra.data.attributes['start-date'])
                    })

                    previousIncome.sort( (a, b) => {
                        return new Date(b.extra.data.attributes['start-date']) - new Date(a.extra.data.attributes['start-date'])
                    })
                    
                    return currentIncome.concat(secondIncome).concat(previousIncome)


                }

                return data

            }, 
            getDescriptionSub(type, name, data){


                if(type == 'incomes'){

                    switch (name) {
                        // case 'Rental Income':
                            // return data["__full-address"]
                        // case 'Self-Employed':
                            // return data["business-name"]
                        default:
                            return data["income-situation"]
                    }

                }

                if(type == 'assets'){

                    switch (name) {
                        case 'Investment Property':
                            return data["__full-address"]
                        default:
                            return data['asset-description']
                    }

                }

                if(type == 'liabilities'){

                    switch (name) {
                        case 'Investment Property':
                            return data["__full-address"]
                        default:
                            return data['financial-institution']
                    }

                }

                if(type == 'expenses'){

                    switch (name) {
                        case 'Investment Property':
                            return data["__full-address"]
                        default:
                            return data['financial-institution']
                    }

                }



            },
            getOwners(data){

                const  {people, businesses} = this

                let owners = []

                if(data.relationships.people.data){
                    data.relationships.people.data.forEach( item => {
                        people.forEach(person => {
                            if(item.id == person.id){
                                owners.push( this.getEntityFriendlyName(person) )
                            }
                        })
                    })
                }
            
                if(data.relationships.businesses.data){
                    data.relationships.businesses.data.forEach( item => {
                        businesses.forEach(business => {
                            if(item.id == business.id){
                                owners.push( this.getEntityFriendlyName(business) )
                            }
                        })
                    })
                }

                return owners
            },
            getOwnersData(data){

                const  {people, businesses} = this

                let owners = []

                if(data.relationships.people.data){
                    data.relationships.people.data.forEach( item => {
                        people.forEach(person => {
                            if(item.id == person.id){
                                owners.push( person )
                            }
                        })
                    })
                }
            
                if(data.relationships.businesses.data){
                    data.relationships.businesses.data.forEach( item => {
                        businesses.forEach(business => {
                            if(item.id == business.id){
                                owners.push( business )
                            }
                        })
                    })
                }

                return owners
            },
            addFO(type = null){

                this.$emit('add', type)
                this.toggleTouched(true)

            },
            getListViewTitle(data, type){

                if(data){

                    let name = this.getEntityFriendlyName(data)

                    let apostrophe = name.slice(-1).toLowerCase();
                        apostrophe = (apostrophe == 's') ? "'" : "'s"

                    return `${name + apostrophe} ${type == 'All' ? 'Financial Objects': type}`

                } else {

                    return `All ${type}`
                }



            },
            getEntityData(data){

                let collection = []

                if(data.relationships?.people?.data){
                    data.relationships.people.data.forEach(item => {
                        collection.push(item)
                    })
                }

                if(data.relationships?.businesses?.data){
                    data.relationships.businesses.data.forEach(item => {
                        collection.push(item)
                    })
                }

                return collection

            },
            showPercentageChip(data){

                const  { selectedEntityData} = this

                if(selectedEntityData != null) {
                    return true
                }

                /**
                 * Don't show for Rental Incomes
                 */
                return data?.relationships?.kind?.data?.id != "12-21"

            }
        }
    }
</script>

<style scoped lang="scss">


</style>