<template>
        
        <v-dialog
            max-width="520"
            v-model="dialog">

            <v-card flat class="more-info-modal_quote-details" :disabled="loading">

                <v-card-title class="py-0 px-4 px-sm-8">
                    <div v-if="hideLender" class="title font-weight-bold py-6">
                        Quote Details
                    </div>
                    <v-spacer></v-spacer>
                    <v-btn icon @click="handleClose" fab>
                        <v-icon color="secondary">mdi-close</v-icon>
                    </v-btn>
                </v-card-title>

                <v-card-text class="pt-4 pb-8 px-4 px-sm-8">
                    <div class="d-flex justify-space-between">
                        <div>
                            <div v-if="hideLender" class="title font-weight-bold">
                                {{ data['alias'] }}
                            </div>
                            <v-img 
                                v-else 
                                :max-width="vb.mdAndUp ? 220 : 100"
                                :max-height="220"
                                class="flex-brand" 
                                :src="getLenderLogo(data['logo-class'])" 
                                position="center center" 
                                contain>
                            </v-img>

                        </div>
                        <v-spacer></v-spacer>
                        <div>
                            <div class="px-2 body-1 font-weight-medium font-size-22">
                                {{ data['actual-payment']['monthly-payment'] | currency }}
                                <span class="font-weight-normal font-size-12">/Month</span>
                                <div class="body-2 font-weight-medium text-right" style="line-height: 1">
                                    Over {{ parseInt(data['actual-payment']['term-in-months']) / 12 }} Years
                                </div>
                            </div>
                            
                        </div>
                    </div>
                </v-card-text>

                <hr class="outline" :style="`background-color: ${hexToRGBA('#000000', 0.1)}`" />  

                <v-card-text class="pt-6">
                    <div :class="`d-flex text-center ${isUnsecured ? '' : 'mb-6'}`">
                        <div style="width: 50%">
                            <div class="px-2">Interest rate</div>
                            <div class="px-2 body-1 font-weight-medium font-size-22">{{ (data['actual-payment']['customer-rate'] ) | percentage }}</div>
                        </div> 
                        <div>
                            <div class="px-2">Comparison rate</div>
                            <div class="px-2 body-1 font-weight-medium font-size-22">{{ data['actual-payment']['comparison-rate'] | percentage }}</div>
                        </div> 
                    </div> 
                    <div class="d-flex text-center" v-if="!isUnsecured">
                        <div style="width: 50%">
                            <div class="px-2">All Fee Included</div>
                            <div class="px-2 body-1 font-weight-medium font-size-22">Yes</div>
                        </div> 
                        <div>
                            <div class="px-2">Balloon Payment</div>
                            <div class="px-2 body-1 font-weight-medium font-size-22">{{ data['actual-payment']['balloon']  }}</div>
                        </div> 
                    </div> 
                </v-card-text>

                <hr class="outline" :style="`background-color: ${hexToRGBA('#000000', 0.1)}`" />  

                <v-card-text class="px-4 px-sm-8 pt-8 pb-10 text-center"> 
                    <v-container fluid pa-0>

                        <v-row class="mt-n4" :class="vb.mdAndUp ? 'mx-1' : 'mx-0'">

                            <v-col cols="12" class="d-flex justify-space-between rounded-lg pa-2 px-md-6">
                                <div class="font-weight-bold" :class="vb.mdAndUp ? 'subtitle-1' : 'caption'">Requested Amount</div> 
                                <div style="width:40%;">
                                    <div :class="vb.mdAndUp ? 'body-1' : 'caption'">{{ requiredAmount }}</div> 
                                </div>
                            </v-col>

                            <v-col cols="12" class="d-flex justify-space-between rounded-lg pa-2 px-md-6">
                                <div class="font-weight-bold" :class="vb.mdAndUp ? 'subtitle-1' : 'caption'">Deposit</div> 
                                <div style="width:40%;">
                                    <div :class="vb.mdAndUp ? 'body-1' : 'caption'">{{(userInput ? userInput['deposit'] : 0) | currency }}</div> 
                                </div>
                            </v-col>

                            <v-col cols="12" class="d-flex justify-space-between rounded-lg pa-2 px-md-6">
                                <div class="font-weight-bold" :class="vb.mdAndUp ? 'subtitle-1' : 'caption'">Origination Fee</div> 
                                <div style="width:40%;">
                                    <div :class="vb.mdAndUp ? 'body-1' : 'caption'">{{ data['actual-payment']['broker-fee'] | currency }}</div> 
                                </div>
                            </v-col>

                            <v-col cols="12" class="d-flex justify-space-between rounded-lg pa-2 px-md-6">
                                <div class="font-weight-bold" :class="vb.mdAndUp ? 'subtitle-1' : 'caption'">Bank Setup Fees</div> 
                                <div style="width:40%;">
                                    <div :class="vb.mdAndUp ? 'body-1' : 'caption'">{{ data['actual-payment']['bank-fees'] | currency }}</div> 
                                </div>
                            </v-col>

                            <v-col cols="12" class="d-flex justify-space-between rounded-lg pa-2 px-md-6">
                                <div class="font-weight-bold" :class="vb.mdAndUp ? 'subtitle-1' : 'caption'">Amount Financed</div> 
                                <div style="width:40%;">
                                    <div :class="vb.mdAndUp ? 'body-1' : 'caption'">{{ data['actual-payment']['naf'] | currency }}</div> 
                                </div>
                            </v-col>

                            <v-col cols="12" class="pa-2 px-md-6">
                                
                                <v-btn
                                    depressed
                                    large
                                    width="100%"
                                    @click="showApplyModal"
                                    color="secondary">
                                        Apply Now
                                </v-btn>

                            </v-col>
                            
                        </v-row>

                    </v-container>
                </v-card-text>

            </v-card>
        </v-dialog>
</template>

<script>
    import mixins from '~/components/v1/QuoteResult6/_mixins'

    export default {
        mixins: [
            mixins
        ],
        props: {
            data: {
                type: Object,
                default: null
            },
            loading: {
                type: Boolean,
                default: false
            },
            dialog: {
                type: Boolean,
                default: false
            },
            requiredAmount: {
                type: [String, Number],
                default: ''
            },
            userInput: {
                type: Object,
                default: null
            },
            hideLender: {
                type: Boolean,
                default: false
            },
            selectedLender: {
                type: String,
                default: ''
            },
        },
        data() {
            return {
              
            }
        },
        mounted(){

        },
        methods: {
            handleClose(){
                this.$emit('close')
            },
            showApplyModal(){

                const {data} = this

                if(data && data.hasOwnProperty('name')) {
                    this.$emit('show-apply', data)
                }

            },
        },
        computed: {
        
        },

    }
</script>

<style lang="scss">

    .more-info-modal_quote-details {
        
        .outline {
            border: none;
            height: 1px;
        }
    }


</style> 