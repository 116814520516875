export default {
    "asset": [
        {
            "name": 'AFS',
            "key": 'AFS',
            "logo-class": 'lender-logo-afs',
            "lender-slug": 'afs',
            "lender-slug-algorithm": 'nod-asset-afs',
            "lender-submission-flow-slug": null
        },
        {
            "name": 'AMMF',
            "key": 'AMMF',
            "logo-class": 'lender-logo-yamaha',
            "lender-slug": 'ammf',
            "lender-slug-algorithm": 'nod-asset-ammf',
            "lender-submission-flow-slug": null
        },
        {
            "name": 'Angle',
            "key": 'Angle',
            "logo-class": 'lender-logo-angle',
            "lender-slug": 'angle',
            "lender-slug-algorithm": 'nod-asset-angle',
            "lender-submission-flow-slug": null
        },
        {
            "name": 'ANZ',
            "key": 'ANZ Edge',
            "logo-class": 'lender-logo-anz',
            "lender-slug": 'anz',
            "lender-slug-algorithm": 'nod-asset-anz-edge',
            "lender-submission-flow-slug": null
        },
        {
            "name": 'ANZ',
            "key": 'ANZ Other Wheels',
            "logo-class": 'lender-logo-anz',
            "lender-slug": 'anz',
            "lender-slug-algorithm": 'nod-asset-anz-other-wheels',
            "lender-submission-flow-slug": null
        },
        {
            "name": 'Capital',
            "key": 'Capital',
            "logo-class": 'lender-logo-capital',
            "lender-slug": 'capital',
            "lender-slug-algorithm": 'nod-asset-capital',
            "lender-submission-flow-slug": null
        },
        {
            "name": 'Finance One Commercial',
            "key": 'Finance One Commercial',
            "logo-class": 'lender-logo-financeone',
            "lender-slug": 'finance-one',
            "lender-slug-algorithm": 'nod-asset-finance-one-commercial',
            "lender-submission-flow-slug": null
        },
        {
            "name": 'Finance One',
            "key": 'Finance One',
            "logo-class": 'lender-logo-financeone',
            "lender-slug": 'finance-one',
            "lender-slug-algorithm": 'api-asset-finance-one',
            "lender-submission-flow-slug": 'lender-submission-finance-one'
        },
        {
            "name": 'First Mac',
            "key": 'First Mac',
            "logo-class": 'lender-logo-first-mac',
            "lender-slug": 'first-mac',
            "lender-slug-algorithm": 'nod-asset-first-mac',
            "lender-submission-flow-slug": null
        },
        {
            "name": 'Flexi Commercial',
            "key": 'Flexi Commercial',
            "logo-class": 'lender-logo-flexicomm',
            "lender-slug": 'flexi',
            "lender-slug-algorithm": 'nod-asset-flexi-commercial',
            "lender-submission-flow-slug": null
        },
        {
            "name": 'Dynamoney',
            "key": 'Dynamoney',
            "logo-class": 'lender-logo-dynamoney',
            "lender-slug": 'dynamoney',
            "lender-slug-algorithm": 'nod-asset-dynamoney',
            "lender-submission-flow-slug": null
        },
        {
            "name": 'Latitude',
            "key": 'Latitude',
            "logo-class": 'lender-logo-latitude',
            "lender-slug": 'latitude',
            "lender-slug-algorithm": 'nod-asset-latitude',
            "lender-submission-flow-slug": null
        },
        {
            "name": 'Liberty',
            "key": 'Liberty',
            "logo-class": 'lender-logo-liberty',
            "lender-slug": 'liberty',
            "lender-slug-algorithm": 'nod-asset-liberty',
            "lender-submission-flow-slug": null
        },
        {
            "name": 'Liberty Commercial',
            "key": 'Liberty Commercial',
            "logo-class": 'lender-logo-liberty',
            "lender-slug": 'liberty',
            "lender-slug-algorithm": 'nod-asset-liberty-commercial',
            "lender-submission-flow-slug": null
        },
        {
            "name": 'Macquarie',
            "key": 'Macquarie',
            "logo-class": 'lender-logo-macquarie',
            "lender-slug": 'macquarie',
            "lender-slug-algorithm": 'nod-asset-macquarie',
            "lender-submission-flow": null,
            "lender-submission-flow-slug": null
        },
        {
            "name": 'Metro',
            "key": 'Metro',
            "logo-class": 'lender-logo-metro',
            "lender-slug": 'metro',
            "lender-slug-algorithm": 'nod-asset-metro',
            "lender-submission-flow-slug": null
        },
        {
            "name": 'Money 3',
            "key": 'Money 3',
            "logo-class": 'lender-logo-money3',
            "lender-slug": 'money-3',
            "lender-slug-algorithm": 'api-secured-money-3',
            "lender-submission-flow-slug": 'lender-submission-money-3'
        },
        {
            "name": 'Morris',
            "key": 'Morris',
            "logo-class": 'lender-logo-morris',
            "lender-slug": 'morris',
            "lender-slug-algorithm": 'nod-asset-morris',
            "lender-submission-flow-slug": null
        },
        {
            "name": 'NAB',
            "key": 'Nab',
            "logo-class": 'lender-logo-nab',
            "lender-slug": 'nab',
            "lender-slug-algorithm": 'nod-asset-nab',
            "lender-submission-flow-slug": null
        },
        {
            "name": 'Now Finance',
            "key": 'Now Finance',
            "logo-class": 'lender-logo-now-finance',
            "lender-slug": 'now-finance',
            "lender-slug-algorithm": 'nod-asset-now-finance',
            "lender-submission-flow-slug": null
        },
        {
            "name": 'Pepper',
            "key": 'Pepper Consumer',
            "logo-class": 'lender-logo-pepper',
            "lender-slug": 'pepper',
            "lender-slug-algorithm": 'nod-asset-pepper-consumer',
            "lender-submission-flow-slug": 'lender-submission-pepper'
        },
        {
            "name": 'Pepper',
            "key": 'Pepper',
            "logo-class": 'lender-logo-pepper',
            "lender-slug": 'pepper',
            "lender-slug-algorithm": 'nod-asset-pepper',
            "lender-submission-flow-slug": 'lender-submission-pepper'
        },
        {
            "name": 'Plenti',
            "key": 'Plenti',
            "logo-class": 'lender-logo-plenti',
            "lender-slug": 'plenti',
            "lender-slug-algorithm": 'nod-asset-plenti',
            "lender-submission-flow-slug": 'lender-submission-plenti'
        },
        {
            "name": 'Plenti Auto Mortgage',
            "key": 'Plenti Auto Mortgage',
            "logo-class": 'lender-logo-plenti',
            "lender-slug": 'plenti',
            "lender-slug-algorithm": 'nod-asset-plenti-auto-mortgage',
            "lender-submission-flow-slug": null
        },
        {
            "name": 'Resimac',
            "key": 'Resimac',
            "logo-class": 'lender-logo-resimac',
            "lender-slug": 'resimac',
            "lender-slug-algorithm": 'nod-asset-resimac',
            "lender-submission-flow-slug": null
        },
        {
            "name": 'Selfco Leasing',
            "key": 'Selfco Leasing',
            "logo-class": 'lender-logo-selfco',
            "lender-slug": 'selfco',
            "lender-slug-algorithm": 'nod-asset-selfco-leasing',
            "lender-submission-flow-slug": null
        },
        {
            "name": 'Westpac',
            "key": 'Westpac',
            "logo-class": 'lender-logo-westpac',
            "lender-slug": 'westpac',
            "lender-slug-algorithm": 'nod-asset-westpac',
            "lender-submission-flow-slug": null
        },
        {
            "name": 'Wisr',
            "key": 'Wisr Auto',
            "logo-class": 'lender-logo-wisr',
            "lender-slug": 'wisr',
            "lender-slug-algorithm": 'nod-asset-wisr-auto',
            "lender-submission-flow-slug": null
        },
        {
            "name": 'Alex Bank',
            "key": 'Alex Bank',
            "logo-class": 'lender-logo-alexbank',
            "lender-slug": 'alex-bank',
            "lender-slug-algorithm": 'nod-asset-alex-bank',
            "lender-submission-flow-slug": null
        }
    ],
    "personal": [
        {
            "name": 'Wisr',
            "key": 'Wisr PL',
            "logo-class": 'lender-logo-wisr',
            "lender-slug": 'wisr',
            "lender-slug-algorithm": 'nod-personal-wisr',
            "lender-submission-flow-slug": null
        },
        {
            "name": 'Society One',
            "key": 'Society One',
            "logo-class": 'lender-logo-society',
            "lender-slug": 'society-one',
            "lender-slug-algorithm": 'api-personal-society-one',
            "lender-submission-flow-slug": 'lender-submission-society-one'
        },
        {
            "name": 'Plenti',
            "key": 'Plenti',
            "logo-class": 'lender-logo-plenti',
            "lender-slug": 'plenti',
            "lender-slug-algorithm": 'api-personal-plenti',
            "lender-submission-flow-slug": 'lender-submission-plenti'
        },
        {
            "name": 'Now Finance',
            "key": 'Now Finance',
            "logo-class": 'lender-logo-now-finance',
            "lender-slug": 'now-finance',
            "lender-slug-algorithm": 'api-personal-now-finance',
            "lender-submission-flow-slug": 'lender-submission-now-finance'
        },
        {
            "name": 'Money Place',
            "key": 'Money Place',
            "logo-class": 'lender-logo-moneyplace',
            "lender-slug": 'money-place',
            "lender-slug-algorithm": 'api-money-place',
            "lender-submission-flow-slug": "lender-submission-money-place"
        },
    ]
}
