<template>
      <!-- TODO: Incomplete component -->
        
        <div>

          <v-slider
            v-model="slider"
            class="align-center"
            :max="max"
            :min="min"
            hide-details
          >
            <template v-slot:append>

            <v-text-field
                v-model="slider" 
                :label="label"
                v-bind="attrs"
                v-on="on"
                autocomplete="off"
                placeholder="dd-mm-yyyy"
                color="secondary"
                :error-messages="errors"
                :disabled="disabled"
                @blur="handleBlur"
                @click:clear="handleClear">
            </v-text-field>

            </template>
          </v-slider>

        </div>

</template>

<script>

    export default {
        props: {

        },
        directives: {

        },
        data() {
            return {
                min: 1,
                max: 7,
                slider: 5,
            }
        },
        created() {
        },
        methods: {
    
        }
    }
</script>

<style lang="scss" scoped>

</style>