<template>
    <div class="pb-6">
        <div class="title-2 text-center pb-2">{{title}}</div>
            <v-row v-if="items.length > 0" justify="center" align="center" class="rounded-lg light_gray_1 pa-2 ma-2">
                <v-col v-for="(item, index) in items" :key="index" class="pa-2" cols="12" md="6" align="center">
                    <!-- <v-card
                        flat
                        tile
                        outlined
                        :width="vb.mdAndUp ? 223 : ''"
                        :height="vb.mdAndUp ? 157 : ''"
                        @click="select(item)"
                        class="pa-4 rounded-xl" :class="{'pt-15 pa-8': vb.mdAndUp}">
                            <div class="py-auto my-auto body-1">
                                {{ item }}
                            </div>
                    </v-card> -->
                    <v-card
                        flat
                        height="100%" 
                        @click="select(item)"
                        rounded="lg"
                        class="d-flex pa-4 align-center justify-center">
                        <div class="flex-options body-2 font-weight-medium">
                            {{ item }}
                        </div>
                        <v-spacer></v-spacer>
                        <v-btn color="secondary" :width="86" small depressed>
                            Select
                        </v-btn>
                    </v-card>
                </v-col>
            </v-row>
        <div
            v-if="errors.length > 0" 
            class="error-messages text-center">
            <span 
                v-for="(err, ekey) in errors"
                :key="ekey">
                {{ err }}
            </span>
        </div>
    </div>
</template>
<script>
export default {

    model: {
        prop: 'modelValue',
        event: 'select'
    },

    props: {
        modelValue: {
            type: String,
            default: ''
        },
        items: {
            type: Array,
            default: () => []
        },
        title: {
            type: String,
            default: ''
        },
        errors: {
            type: Array,
            default: () => []
        }
    },
    
    computed: {
        vb() {
            return this.$vuetify.breakpoint
        }
    },
    data() {
        return {
            selectedStyle: 'background-color: var(--v-secondary-lighten5); border: 2px solid var(--v-secondary-base);'
        }
    },
    methods: {
        select(item) {
            this.$emit('select', item)
        }
    }
}
</script>
<style lang="scss" scoped>
    .error-messages {
        border: none;
        color: var(--v-error-base) !important;
        caret-color: var(--v-error-base) !important;
        font-size: 12px;
    }
</style>
