<template>
  <div>
    <v-data-table
      hide-default-footer
      :items-per-page="-1"
      :headers="[
        {
          text: 'Situation',
          value: 'attributes.income-situation',
        },
        {
          text: 'Industry',
          value: 'attributes.lender-plenti-industry',
        },
        {
          text: 'Occupation',
          value: 'attributes.lender-plenti-occupation',
        },
        {
          text: 'Action',
          value: 'action'
        }
      ]"
      :items="previousAndCurrentIncomes"
      class="px-8 v-data-table-2"
      >
      <template #body="{items}">
        <tbody>
          <tr v-for="(item, index) in items" :key="index" :class="{'light_gray_1': resources.incomes.id == item.id}">
            <td>
              {{ item.attributes['income-situation'] ||'-' }}
            </td>
            <td>
              {{ item.attributes['lender-wisr-industry'] ||'-' }}
            </td>
            <td>
              {{ item.attributes['lender-wisr-occupation'] ||'-' }}
            </td>
            <td>
              <v-chip v-if="resources.incomes.id != item.id" depressed small color="secondary" @click="resources.incomes = filterResourceFields(resources.incomes, item)">
                Edit
              </v-chip>
              <v-chip v-else depressed small>
                Selected
              </v-chip>
            </td>
          </tr>
        </tbody>
      </template>
    </v-data-table>

    <div v-if="vb.mdAndUp" class="d-flex" style="width: 100%">
      <v-sheet
        color="light_gray_1"
        class="py-8 px-12"
        width="50%"
        outlined
        elevation="0"
      >
        <v-img
          max-width="120"
          class="flex-brand"
          :src="require(`~/assets/images/nodifi.svg`)"
          position="center left"
          contain
        >
        </v-img>
        <div class="mt-2 mb-6 font-size-20">Data</div>

        <v-list color="transparent">
          <v-list-item two-line class="px-0">
            <v-list-item-content>
              <v-list-item-title class="font-weight-medium"
                >Employer Name</v-list-item-title
              >
              <v-list-item-subtitle>{{
                getSelectedIncome ? getSelectedIncome.attributes["employer-name"] || "&mdash;" : "&mdash;"
              }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <v-list-item two-line class="px-0">
            <v-list-item-content>
              <v-list-item-title class="font-weight-medium"
                >Job Title</v-list-item-title
              >
              <v-list-item-subtitle>{{
                getSelectedIncome ? getSelectedIncome.attributes["job-title"] || "&mdash;" : "&mdash;"
              }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-sheet>
      <v-sheet class="py-8 px-12" width="50%">
        <v-img
          max-width="120"
          class="flex-brand"
          :src="require(`~/assets/images/logo/cropped/lender-logo-wisr.png`)"
          position="center left"
          contain
        >
        </v-img>
        <div class="mt-2 mb-8 font-size-20">Requirements</div>

        <ValidationObserver ref="observer" tag="form">
          <template v-for="(field, key) in resources.incomes.attributes">
            <ValidationProvider
              :key="key"
              v-slot="{ errors }"
              :name="labels[key]"
              :rules="getRules(key)"
              :vid="key"
            >
              <ButtonGroup3
                v-if="isBtnGroup(key)"
                v-model="resources.incomes.attributes[key]"
                :error-messages="errors"
                :options="[
                  { value: '1', label: 'Yes' },
                  { value: '0', label: 'No' },
                ]"
                :label="labels[key]"
              />

              <v-select
                v-else-if="isFieldType('picklist', key)"
                v-model="resources.incomes.attributes[key]"
                :items="getOptions(key)"
                :error-messages="errors"
                color="secondary"
                :label="labels[key]"
              ></v-select>

              <v-text-field
                v-else
                tile
                :type="key === 'gross-annual-income' ? 'number' : 'text'"
                :prefix="key === 'gross-annual-income' ? '$' : undefined"
                :label="labels[key]"
                color="secondary"
                :error-messages="errors"
                v-model="resources.incomes.attributes[key]"
              >
              </v-text-field>
            </ValidationProvider>
          </template>

        </ValidationObserver>
      </v-sheet>
    </div>

    <!-- mobile version -->
    <div v-else>
      <v-card
        flat
        class="mx-5 rounded-xl"
        :style="`background-color: ${hexToRGBA(
          getActiveTheme.colors.secondary,
          0.2
        )}`"
      >
        <v-card-text class="d-flex py-1">
          <v-row>
            <v-col cols="6" class="pa-1">
              <v-card
                min-height="45"
                flat
                class="rounded-xl px-1"
                :color="tabSelection == 'nodifi' ? 'white' : 'transparent'"
              >
                <div
                  class="d-flex justify-center pt-1"
                  @click="selectTab('nodifi')"
                >
                  <v-img
                    max-width="55"
                    class="flex-brand"
                    :src="require(`~/assets/images/nodifi.svg`)"
                    position="center left"
                    contain
                  >
                  </v-img>
                  <div class="mt-3 font-size-12 pl-1">Data</div>
                </div>
              </v-card>
            </v-col>
            <v-col cols="6" class="pa-1">
              <v-card
                flat
                class="rounded-xl px-1"
                :color="tabSelection == 'pepper' ? 'white' : 'transparent'"
              >
                <div class="d-flex flex-column" @click="selectTab('pepper')">
                  <v-img
                    max-width="45"
                    class="flex-brand mx-auto mt-n1"
                    :src="
                      require(`~/assets/images/logo/cropped/lender-logo-pepper.png`)
                    "
                    position="center left"
                    contain
                  >
                  </v-img>
                  <div class="font-size-12 pl-1 mx-auto mt-n3">Requirements</div>
                </div>
              </v-card>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>

      <v-list v-show="tabSelection == 'nodifi'" class="mx-8" color="transparent">

        <v-list-item two-line class="px-0">
          <v-list-item-content>
            <v-list-item-title class="font-weight-medium"
              >Employer Name</v-list-item-title
            >
            <v-list-item-subtitle>{{
                getSelectedIncome ? getSelectedIncome.attributes["employer-name"] || "&mdash;" : "&mdash;"
              }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>

        <v-list-item two-line class="px-0">
          <v-list-item-content>
            <v-list-item-title class="font-weight-medium"
                >Job Title</v-list-item-title
              >
              <v-list-item-subtitle>{{
                getSelectedIncome ? getSelectedIncome.attributes["job-title"] || "&mdash;" : "&mdash;"
              }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>

      </v-list>

      <div v-show="tabSelection != 'nodifi'" class="mx-8">
        <ValidationObserver ref="observer" tag="form">
          <template v-for="(field, key) in resources.incomes.attributes">
            <ValidationProvider
              :key="key"
              v-slot="{ errors }"
              :name="labels[key]"
              :rules="getRules(key)"
              :vid="key"
            >
              <v-select
                v-if="isFieldType('picklist', key)"
                v-model="resources.incomes.attributes[key]"
                :items="getOptions(key)"
                :error-messages="errors"
                color="secondary"
                :label="labels[key]"
              ></v-select>

              <v-text-field
                v-else
                tile
                type="text"
                :label="labels[key]"
                color="secondary"
                :error-messages="errors"
                v-model="resources.incomes.attributes[key]"
                required
              >
              </v-text-field>
            </ValidationProvider>
          </template>
        </ValidationObserver>
      </div>
    </div>
</div>
</template>

<script>
import { mapActions, mapMutations, mapGetters } from "vuex";
import subscriptions from "~/components/shared/subscriptions";
import shared from "~/components/v1/submission/LenderPepperEntities1/__shared";
import {
  store,
  actions,
} from "~/components/v1/submission/LenderPepperEntities1/__store";
import ButtonGroup3 from "~/components/base/ButtonGroup3";
import moment from 'moment';

export default {
  components: { ButtonGroup3 },
  mixins: [subscriptions, shared],
  props: {
    incomes: {
        type: Array,
        default: () => []
    },
    data: {
      type: Object,
      default: null,
    },
    updater: undefined,
  },
  data() {
    return {
      tabSelection: "nodifi",
      resources: {
        incomes: {
          id: null,
          type: "incomes",
          attributes: {
            "lender-wisr-industry": "",
            "lender-wisr-occupation": "",
            "gross-annual-income": "",
          },
        }
      },
      labels: {
        "lender-wisr-industry": "Industry",
        "lender-wisr-occupation": "Occupation",
        "gross-annual-income": "Gross Annual Income",
      },
      validation_rules: {
        attributes: {
          "lender-wisr-industry": "",
          "lender-wisr-occupation": "",
          "gross-annual-income": "between:0,99999999.99",
        },
      },
      options: {
        "lender-wisr-occupation": [
          {
            text: "Administration",
            value: "administration",
          },
          {
            text: "Office Worker",
            value: "office-worker",
          },
          {
            text: "Professional",
            value: "professional",
          },
          {
            text: "Sales",
            value: "sales",
          },
          {
            text: "Trade",
            value: "trade",
          },
          {
            text: "Unskilled",
            value: "unskilled",
          },
          {
            text: "Home Duties",
            value: "home-duties",
          },
          {
            text: "Other",
            value: "other",
          }
        ],
        "lender-wisr-industry": [
          {
            text: "Education",
            value: "education",
          },
          {
            text: "Agriculture Fishing Forestry",
            value: "agriculture-fishing-forestry",
          },
          {
            text: "Finance Insurance",
            value: "finance-insurance",
          },
          {
            text: "Health Services",
            value: "health-services",
          },
          {
            text: "Supermarkets Fast Food Petrol",
            value: "supermarkets-fast-food-petrol",
          },
          {
            text: "Other",
            value: "other",
          },
          {
            text: "Hospitality",
            value: "hospitality",
          },
          {
            text: "Technology",
            value: "technology",
          },
          {
            text: "Construction Manufacturing",
            value: "construction-manufacturing",
          },
          {
            text: "Non-Profit Religious",
            value: "non-profit-religious",
          },
          {
            text: "Tourism",
            value: "tourism",
          },
          {
            text: "Professional Services",
            value: "professional-services",
          },
          {
            text: "Vet",
            value: "vet",
          },
          {
            text: "Events",
            value: "events",
          },
          {
            text: "Government",
            value: "government",
          },
          {
            text: "Transport Logistics",
            value: "transport-logistics",
          },
          {
            text: "Arts",
            value: "arts",
          },
          {
            text: "Mining Energy",
            value: "mining-energy",
          },
          {
            text: "Home Duties",
            value: "home-duties",
          },
          {
            text: "Real Estate",
            value: "real-estate",
          },
          {
            text: "Retail",
            value: "retail",
          },
          {
            text: "Childcare",
            value: "childcare",
          },
          {
            text: "Sport",
            value: "sport",
          },
          {
            text: "Services",
            value: "services",
          },
        ],
      },
    };
  },
  computed: {
    vb() {
      return this.$vuetify.breakpoint;
    },
    previousAndCurrentIncomes () {

      /**
       * Include Secondary only if PAYG or Self-Employed
       *  12-28 - PAYG
       *  12-29 - Self-Employed
       */

      let exemptKinds =  ['12-28', '12-29']

      return this.incomes.filter(item => {

        if(exemptKinds.includes(item?.relationships?.kind?.data?.id)) {
          return true
        }

        if (!this.validCurrentIncome) {
          return true
        }

        return item.attributes['income-situation'] !== 'Secondary'

      })
    },
    validCurrentIncome () {

      const current = this.incomes.find(item => {

        return item.attributes['income-situation'] === 'Current'

      })

      if (!current) {
        return false
      }

      return moment(new Date()).diff(current.attributes['start-date'], 'years') >= 3
    },
    getSelectedIncome(){
      if(this.resources.incomes.id) {
        return this.previousAndCurrentIncomes.find(i => i.id == this.resources.incomes.id)
      }
    }
  },
  mounted() {
    this.updateResourceFields();
  },
  methods: {
    ...mapActions("resource-actions", ["getPerson", "getIncome"]),
    isFieldType(field, key) {
      return field === "picklist"
        ? ["lender-wisr-industry", "lender-wisr-occupation"].includes(key)
        : false;
    },
    selectTab(tab) {
      this.tabSelection = tab;
    },
    isBtnGroup(key) {
      let keys = ["lender-wisr-forsee-repayment"];

      return keys.includes(key);
    },
    async validate() {
      const valid = await this.$refs.observer.validate();

      if (valid) {
        this.$emit('submit', this.resources.incomes)
      }
    },
    reset() {
      this.$refs.observer.reset();
    },
    async updateResourceFields() {
      // console.log('updateResourceFields')

      this.$emit("loading", true);

      const { data } = this;

      if (data) {
        let res = await this.getIncome(data.id);

        this.resources.incomes = this.filterResourceFields(this.resources.incomes, res.data.data)

        this.$emit("loading", false);
      } else {
        this.$emit("loading", false);
      }
    },
  },
  watch: {
    updater(val) {
      if (val) {
        this.updateResourceFields();
      }
    },
    getSelectedIncome(val) {

      /**
       * Include Secondary only if PAYG or Self-Employed
       *  12-28 - PAYG
       *  12-29 - Self-Employed
       */
        if(['12-28', '12-29'].includes(val?.relationships?.kind?.data?.id)) {

          this.validation_rules.attributes =  {
            "lender-wisr-industry": "required",
            "lender-wisr-occupation": "required",
            "gross-annual-income": "required|between:0,99999999.99",
          }

        } else {

          this.validation_rules.attributes =  {
            "lender-wisr-industry": "",
            "lender-wisr-occupation": "",
            "gross-annual-income": "between:0,99999999.99",
          }

        }

    }
  },
};
</script>

<style lang="scss" scoped></style>
