export default {
    "asset": {
        "nod-asset-liberty-commercial" : {
            "include": "Yes"
        },
        "nod-asset-anz-edge" : {
            "include": "Yes"
        },
        "nod-asset-anz-other-wheels" : {
            "include": "Yes"
        },
        "nod-asset-macquarie" : {
            "include": "Yes"
        },
        "nod-asset-pepper" : {
            "include": "Yes"
        },
        "nod-asset-metro" : {
            "include": "Yes"
        },
        "nod-asset-capital" : {
            "include": "Yes"
        },
        "nod-asset-first-mac" : {
            "include": "Yes"
        },
        "nod-asset-nab" : {
            "include": "Yes"
        },
        "nod-asset-westpac" : {
            "include": "Yes"
        },
        "nod-asset-plenti" : {
            "include": "Yes"
        },
        "nod-asset-pepper-consumer" : {
            "include": "Yes"
        },
        "nod-asset-liberty" : {
            "include": "Yes"
        },
        "nod-asset-ammf" : {
            "include": "Yes"
        },
        "nod-asset-afs" : {
            "include": "Yes"
        },
        "nod-asset-latitude" : {
            "include": "Yes"
        },
        "nod-asset-now-finance" : {
            "include": "Yes"
        },
        "nod-asset-wisr-auto" : {
            "include": "Yes"
        },
        "nod-asset-resimac" : {
            "include": "Yes"
        },
        "nod-asset-morris": {
            "include": "Yes"
        },
        "nod-asset-angle": {
            "include": "Yes"
        },
        "nod-asset-dynamoney": {
            "include": "Yes"
        },
        "nod-asset-flexi-commercial": {
            "include": "Yes"
        },
        "nod-asset-selfco-leasing": {
            "include": "Yes"
        },
        "nod-asset-finance-one-commercial" : {
            "include": "Yes"
        },
        "nod-asset-plenti-auto-mortgage": {
            "include": "Yes"
        },
        "nod-asset-alex-bank": {
            "include": "Yes"
        }
      },
      "personal" : {
        "api-personal-society-one" : {
            "include": "Yes"
        },
        "nod-personal-wisr" : {
            "include": "Yes"
        },
        "api-personal-plenti": {
            "include": "Yes"
        },
        "api-personal-now-finance": {
            "include": "Yes"
        },
        "api-money-place": {
            "include": "No"
        },
    }
}
