import {getCookie, setCookie, deleteCookie} from '~/plugins/cookies'

// initial state
export const  state = () => ({
    setup: {
        LeftSideBar: {
            "name" : "variant",
            "value" : "expanded",
            "method" : "cookie"
        },
        RightSideBar: {
            "name" : "variant",
            "value" : "expanded",
            "method" : "cookie"
        }
    },
    leftDrawerVariant: false,
    rightDrawerVariant: false,
    controlledDrawerMutationObserver: 0,
})

// getters
export const  getters = {
    getLeftDrawer({setup}){
        return ['expanded', 'mini'].includes(setup.LeftSideBar.value)
    },
    getRightDrawer({setup}){
        return ['expanded', 'mini'].includes(setup.RightSideBar.value)
    },
    getLeftDrawerVariant({leftDrawerVariant}){
        return leftDrawerVariant
    },
    getRightDrawerVariant({rightDrawerVariant}){
        return rightDrawerVariant
    }
}

// actions
export const  actions = {
    spacesSetup: ({state, commit, rootState}) => {

        const {spaces} = rootState.flows

        let configSetup = {}
        
        if(spaces){
            spaces.forEach(space => {
                if(space.hasOwnProperty('settings')) {
                    configSetup[space.name] = space.settings
                }
            });
        }

        /**
         * Merge Store and Config setup
         */
        let mergedSetup = { ...state.setup, ...configSetup}


        /**
         * Check for spaces in Cookies then change settings if exists 
         */
        Object.keys(mergedSetup).forEach( name => {

            //  deleteCookie(name)
            
            if(getCookie(name)){

                let settings = {...mergedSetup[name]}

                settings.value = getCookie(name)

                mergedSetup[name] = settings
            }

        })

        commit('setup', mergedSetup)
        commit('setLeftDrawerVariant', state.setup.LeftSideBar.value == 'mini')
        commit('setRightDrawerVariant', state.setup.RightSideBar.value == 'mini')
    },
}

// mutations
export const  mutations = {
    
    setup (state, payload) {
        state.setup = payload
    },

    // Toggle Drawer Mutations
    setLeftDrawer(state) {

        const {setup, leftDrawerVariant} = state

        const left = ['expanded', 'mini'].includes(setup.LeftSideBar.value)
        let value = left ? 'collapsed' : leftDrawerVariant ? 'mini' : 'expanded';

        // Save to cookie if LeftSideBar method is cookie.
        if(setup.LeftSideBar.method == 'cookie'){
          setCookie('LeftSideBar', value)
        }

        state.setup.LeftSideBar = {
          ...setup.LeftSideBar,
          value: value
        }
    },
    setRightDrawer(state) {

        const {setup, rightDrawerVariant} = state

        const right = ['expanded', 'mini'].includes(setup.RightSideBar.value) 
        let value = right ? 'collapsed' : rightDrawerVariant ? 'mini' : 'expanded';

        // Save to cookie if RightSideBar method is cookie.
        if(setup.RightSideBar.method == 'cookie'){
          setCookie('RightSideBar', value)
        }

        state.setup.RightSideBar = {
          ...setup.RightSideBar,
          value: value
        }
    },
    setLeftDrawerVariant(state, payload=null) {

        const {setup, leftDrawerVariant} = state

        let isMini = (payload != null) ? payload : !leftDrawerVariant

        let preVal = setup.LeftSideBar.value;

        const value = isMini ? 'mini' : (preVal == 'mini') ? 'expanded' : preVal

        if(setup.LeftSideBar.method == 'cookie'){
            setCookie('LeftSideBar', value)
        }

        // state.setup.LeftSideBar = {
        //     ...setup.LeftSideBar,
        //     value: value
        // },

        state.leftDrawerVariant = isMini
    },
    setRightDrawerVariant(state, payload=null) {

        const {setup, rightDrawerVariant} = state

        let isMini = (payload != null) ? payload : !rightDrawerVariant

        let preVal = setup.RightSideBar.value;

        const value = isMini ? 'mini' : (preVal == 'mini') ? 'expanded' : preVal

        if(setup.RightSideBar.method == 'cookie'){
            setCookie('RightSideBar', value)
        }

        state.rightDrawerVariant = isMini
    },


    // Controlled Drawer Mutation
    // e.g payload = ['LeftSideBar', 'collapsed']
    toggleSideBarSpace(state, payload){ 

        const {setup} = state

        state.setup[payload[0]] = {
            ...setup[payload[0]],
            value: payload[1]
        }

        if( payload[1] == 'mini' ) {

            if(payload[0] == 'LeftSideBar') {
                state.leftDrawerVariant = true
            } else {
                state.rightDrawerVariant = true
            }
        }

        if( payload[1] == 'expanded' ) {

            if(payload[0] == 'LeftSideBar') {
                state.leftDrawerVariant = false
            } else {
                state.rightDrawerVariant = false
            }
        }

        setCookie(payload[0], payload[1])

        state.controlledDrawerMutationObserver = payload.join('-')
    },
}