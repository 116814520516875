export default {

    'plenti': {
        name: 'Plenti',
        slug: 'plenti',
        fields: {
            "requested-broker-fee": "",
            "customer-discount-rate": "",
            "bank-account-number": "",
            "bank-bsb-number": "",
            "quoted-term-months": "",

            "lender-plenti-customer-reason" : null,
            "lender-plenti-customer-paid-method-when-loan-due" : null,
            "lender-plenti-customer-reason-explanation" : null
        },
        layout: [
            [
                "requested-broker-fee",
                "customer-discount-rate",
                "__spacer",
                "__spacer"
            ],
            [
                "bank-account-number",
                "bank-bsb-number",
                "__spacer",
                "__spacer"
            ],
            [
                "quoted-term-months",
                "__spacer",
                "__spacer",
                "__spacer"
            ],
            [
                "lender-plenti-customer-reason",
                "lender-plenti-customer-reason-explanation",
                "lender-plenti-customer-paid-method-when-loan-due",
                "__spacer"

            ]
        ]
    },
    'now-finance':  {
        name: 'Now Finance',
        slug: 'now-finance',
        fields: {
            "requested-broker-fee": "",
            "otherLoanPurposeDescription": "",
        },
        layout: [
            [
                "requested-broker-fee",
                "otherLoanPurposeDescription",
                "__spacer",
                "__spacer"
            ]
        ]
    },
    'society-one': {
        name: 'Society One',
        slug: 'society-one',
        fields: {
            "quoted-term-months": "Loan Term",
            "requested-payment-frequency": "Repayment Frequency",
            "requested-broker-fee": "Origination Fee",
        },
        layout: [
            [
                "quoted-term-months",
                "requested-payment-frequency",
                "requested-broker-fee",
                "__spacer"
            ]
        ]
    },
    'pepper': {
        name: 'Pepper',
        slug: 'pepper',
        fields: {
            "lender-pepper-risk-tier": "",
            "submission-required": "",
            "actual-term-months": "",
            "actual-payment-frequency": "",
            "customer-discount-rate": "",
            "requested-balloon": "",
            "actual-broker-fee": "",
            "financing-insurance": "",
        },
        layout: [
            [
                "lender-pepper-risk-tier",
                "submission-required",
                "actual-term-months",
                "actual-payment-frequency"
            ],
            [
                "customer-discount-rate",
                "requested-balloon",
                "actual-broker-fee",
                "financing-insurance"
            ]
        ]
    },
    'moneyplace': {
        name: 'moneyplace',
        slug: 'moneyplace',
        fields: {
            "actual-term-months": "",
        },
        layout: [
            [
                "actual-term-months",
            ],
        ]
    },
}