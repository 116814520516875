<template>
  <div>
    <div v-if="vb.mdAndUp" class="d-flex" style="width: 100%">
      <v-sheet
        color="light_gray_1"
        class="py-8 px-12"
        width="50%"
        outlined
        elevation="0"
      >
        <v-img
          max-width="120"
          class="flex-brand"
          :src="require(`~/assets/images/nodifi.svg`)"
          position="center left"
          contain
        >
        </v-img>
        <div class="mt-2 mb-6 font-size-20">Data</div>

        <v-list color="transparent">
          <v-list-item two-line class="px-0">
            <v-list-item-content>
              <v-list-item-title class="font-weight-medium"
                >Income Situation</v-list-item-title
              >
              <v-list-item-subtitle>
                {{ getSelectedIncome ? getSelectedIncome.attributes["income-situation"] || "&mdash;" : "&mdash;" }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <v-list-item two-line class="px-0">
            <v-list-item-content>
              <v-list-item-title class="font-weight-medium"
                >Employment Job Status</v-list-item-title
              >
              <v-list-item-subtitle>
                {{getSelectedIncome ? getSelectedIncome.attributes["employment-job-status"] || "&mdash;" : "&mdash;" }}
             </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <v-list-item two-line class="px-0">
            <v-list-item-content>
              <v-list-item-title class="font-weight-medium"
                >Start Date</v-list-item-title
              >
              <v-list-item-subtitle>
                {{ startDate || "&mdash;" }}
             </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item two-line class="px-0">
            <v-list-item-content>
              <v-list-item-title class="font-weight-medium"
                >Job Title</v-list-item-title
              >
              <v-list-item-subtitle>
                {{getSelectedIncome ? getSelectedIncome.attributes["job-title"] || "&mdash;" : "&mdash;" }}
             </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-sheet>
      <v-sheet class="py-8 px-12" width="50%">
        <v-img
          max-width="120"
          class="flex-brand"
          :src="require(`~/assets/images/logo/cropped/lender-logo-moneyplace.png`)"
          position="center left"
          contain
        >
        </v-img>
        <div class="mt-2 mb-8 font-size-20">Requirements</div>
        <ValidationObserver ref="observer" tag="form">

          <ValidationProvider
            key="covidWfh"
            v-slot="{ errors }"
            name="Field"
            :rules="getRules('covidWfh')"
            vid="covidWfh"
          >
            <ButtonGroup3
                v-model="moneyPlace.covidWfh"
                :error-messages="errors"
                :options="[
                  { value: '1', label: 'Yes' },
                  { value: '0', label: 'No' },
                ]"
                label="Is the applicant currently working from home due to COVID?"
              />
          </ValidationProvider>
          <ValidationProvider
            key="primaryIndustry"
            v-slot="{ errors }"
            name="Field"
            :rules="getRules('primaryIndustry')"
            vid="primaryIndustry"
          >
            <v-select
                v-model="moneyPlace.primaryIndustry"
                :items="options.industries"
                :error-messages="errors"
                color="secondary"
                label="Current Industry"
              />
          </ValidationProvider>
          <ValidationProvider
            key="primaryHours"
            v-if="requiredPrimaryPermanentAndHours"
            v-slot="{ errors }"
            name="Field"
            :rules="getRules('primaryHours')"
            vid="primaryHours"
          >
            <ButtonGroup3
                v-model="moneyPlace.primaryHours"
                :error-messages="errors"
                :options="[
                  { value: '1', label: 'Yes' },
                  { value: '0', label: 'No' },
                ]"
                label="Is the applicants employment position permanent?"
              />
          </ValidationProvider>
          <ValidationProvider
            key="primaryPermanent"
            v-slot="{ errors }"
            v-if="requiredPrimaryPermanentAndHours"
            name="Field"
            :rules="getRules('primaryPermanent')"
            vid="primaryPermanent"
          >
            <ButtonGroup3
                v-model="moneyPlace.primaryPermanent"
                :error-messages="errors"
                :options="[
                  { value: '1', label: 'Yes' },
                  { value: '0', label: 'No' },
                ]"
                label="Is the applicants employment required hours greater than 20 hours per week?"
              />
          </ValidationProvider>
          <ValidationProvider
            key="previousIndustry"
            v-slot="{ errors }"
            name="Field"
            :rules="getRules('previousIndustry')"
            vid="previousIndustry"
            v-show="hasPreviousIncome"
          >
            <v-select
                v-model="previousIndustry"
                :items="options.industries"
                :error-messages="errors"
                color="secondary"
                label="Previous Industry"
              />
          </ValidationProvider>
        </ValidationObserver>
        
<!-- 
        <ValidationObserver ref="observer" tag="form">
          <template v-for="(field, key) in resources.incomes.attributes">
            <ValidationProvider
              :key="key"
              v-slot="{ errors }"
              :name="labels[key]"
              :rules="getRules(key)"
              :vid="key"
            >
              <ButtonGroup3
                v-if="isBtnGroup(key)"
                v-model="resources.incomes.attributes[key]"
                :error-messages="errors"
                :options="[
                  { value: '1', label: 'Yes' },
                  { value: '0', label: 'No' },
                ]"
                :label="labels[key]"
              />

              <v-select
                v-else-if="isFieldType('picklist', key)"
                v-model="resources.incomes.attributes[key]"
                :items="getOptions(key)"
                :error-messages="errors"
                color="secondary"
                :label="labels[key]"
              ></v-select>

              <v-text-field
                v-else
                tile
                :type="key === 'gross-annual-income' ? 'number' : 'text'"
                :prefix="key === 'gross-annual-income' ? '$' : undefined"
                :label="labels[key]"
                color="secondary"
                :error-messages="errors"
                v-model="resources.incomes.attributes[key]"
              >
              </v-text-field>
            </ValidationProvider>
          </template>

        </ValidationObserver> -->
      </v-sheet>
    </div>

    <!-- mobile version -->
    <div v-else>
      <v-card
        flat
        class="mx-5 rounded-xl"
        :style="`background-color: ${hexToRGBA(
          getActiveTheme.colors.secondary,
          0.2
        )}`"
      >
        <v-card-text class="d-flex py-1">
          <v-row>
            <v-col cols="6" class="pa-1">
              <v-card
                min-height="45"
                flat
                class="rounded-xl px-1"
                :color="tabSelection == 'nodifi' ? 'white' : 'transparent'"
              >
                <div
                  class="d-flex justify-center pt-1"
                  @click="selectTab('nodifi')"
                >
                  <v-img
                    max-width="55"
                    class="flex-brand"
                    :src="require(`~/assets/images/nodifi.svg`)"
                    position="center left"
                    contain
                  >
                  </v-img>
                  <div class="mt-3 font-size-12 pl-1">Data</div>
                </div>
              </v-card>
            </v-col>
            <v-col cols="6" class="pa-1">
              <v-card
                flat
                class="rounded-xl px-1"
                :color="tabSelection == 'moneyplace' ? 'white' : 'transparent'"
              >
                <div class="d-flex flex-column" @click="selectTab('moneyplace')">
                  <v-img
                    max-width="45"
                    min-height="45"
                    class="flex-brand mx-auto mt-n1"
                    :src="
                      require(`~/assets/images/logo/cropped/lender-logo-moneyplace.png`)
                    "
                    position="center left"
                    contain
                  >
                  </v-img>
                  <div class="font-size-12 pl-1 mx-auto mt-n3">Requirements</div>
                </div>
              </v-card>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>

      <v-list v-show="tabSelection == 'nodifi'" class="mx-8" color="transparent">

        <v-list-item two-line class="px-0">
          <v-list-item-content>
            <v-list-item-title class="font-weight-medium"
              >Income Situation</v-list-item-title
            >
            <v-list-item-subtitle>
                {{ getSelectedIncome ? getSelectedIncome.attributes["income-situation"] || "&mdash;" : "&mdash;" }}
              </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>

        <v-list-item two-line class="px-0">
          <v-list-item-content>
            <v-list-item-title class="font-weight-medium"
                >Employment Job</v-list-item-title
              >
              <v-list-item-subtitle>
                
                {{getSelectedIncome ? getSelectedIncome.attributes["employment-job-status"] || "&mdash;" : "&mdash;" }}
              </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>

        <v-list-item two-line class="px-0">
          <v-list-item-content>
            <v-list-item-title class="font-weight-medium"
                >Start Date</v-list-item-title
              >
              <v-list-item-subtitle>
                
                {{ startDate || "&mdash;" }}
              </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-list-item two-line class="px-0">
          <v-list-item-content>
            <v-list-item-title class="font-weight-medium"
                >Job Title</v-list-item-title
              >
              <v-list-item-subtitle>
                
                {{getSelectedIncome ? getSelectedIncome.attributes["job-title"] || "&mdash;" : "&mdash;" }}
              </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>

      </v-list>

      <div v-show="tabSelection != 'nodifi'" class="mx-8">
        <ValidationObserver ref="observer" tag="form">
          <ValidationProvider
            key="covidWfh"
            v-slot="{ errors }"
            name="Field"
            :rules="getRules('covidWfh')"
            vid="covidWfh"
          >
            <ButtonGroup3
                v-model="moneyPlace.covidWfh"
                :error-messages="errors"
                :options="[
                  { value: '1', label: 'Yes' },
                  { value: '0', label: 'No' },
                ]"
                label="Is the applicant currently working from home due to COVID?"
              />
          </ValidationProvider>
          <ValidationProvider
            key="primaryIndustry"
            v-slot="{ errors }"
            name="Field"
            :rules="getRules('primaryIndustry')"
            vid="primaryIndustry"
          >
            <v-select
                v-model="moneyPlace.primaryIndustry"
                :items="options.industries"
                :error-messages="errors"
                color="secondary"
                label="Current Industry"
              />
          </ValidationProvider>
          <ValidationProvider
            key="primaryHours"
            v-slot="{ errors }"
            name="Field"
            v-if="requiredPrimaryPermanentAndHours"
            :rules="getRules('primaryHours')"
            vid="primaryHours"
          >
            <ButtonGroup3
                v-model="moneyPlace.primaryHours"
                :error-messages="errors"
                :options="[
                  { value: '1', label: 'Yes' },
                  { value: '0', label: 'No' },
                ]"
                label="Is the applicants employment position permanent?"
              />
          </ValidationProvider>
          <ValidationProvider
            key="primaryPermanent"
            v-slot="{ errors }"
            v-if="requiredPrimaryPermanentAndHours"
            name="Field"
            :rules="getRules('primaryPermanent')"
            vid="primaryPermanent"
          >
            <ButtonGroup3
                v-model="moneyPlace.primaryPermanent"
                :error-messages="errors"
                :options="[
                  { value: '1', label: 'Yes' },
                  { value: '0', label: 'No' },
                ]"
                label="Is the applicants employment required hours greater than 20 hours per week?"
              />
          </ValidationProvider>
          <ValidationProvider
            key="previousIndustry"
            v-slot="{ errors }"
            name="Field"
            :rules="getRules('previousIndustry')"
            vid="previousIndustry"
            v-show="hasPreviousIncome"
          >
            <v-select
                v-model="previousIndustry"
                :items="options.industries"
                :error-messages="errors"
                color="secondary"
                label="Previous Industry"
              />
          </ValidationProvider>
          </ValidationObserver>
      </div>
    </div>
</div>
</template>

<script>
import { mapActions, mapMutations, mapGetters } from "vuex";
import subscriptions from "~/components/shared/subscriptions";
import shared from "~/components/v1/submission/MoneyPlaceEntities1/__shared";
import {
  store,
  actions,
} from "~/components/v1/submission/MoneyPlaceEntities1/__store";
import ButtonGroup3 from "~/components/base/ButtonGroup3";
import moment from 'moment';

const industries = [
  {value: 'accounting', text: 'Accounting'},
  {value: 'administration & office support', text: 'Administration & Office Support'},
  {value: 'advertising arts & media', text: 'Advertising Arts & Media'},
  {value: 'banking & financial services', text: 'Banking & Financial Services'},
  {value: 'customer service & call centre', text: 'Customer Service & Call Centre'},
  {value: 'ceo & general management', text: 'Ceo & General Management'},
  {value: 'community services & development', text: 'Community Services & Development'},
  {value: 'construction', text: 'Construction'},
  {value: 'consulting & strategy', text: 'Consulting & Strategy'},
  {value: 'design & architecture', text: 'Design & Architecture'},
  {value: 'education & training', text: 'Education & Training'},
  {value: 'engineering', text: 'Engineering'},
  {value: 'farming animals & conservation', text: 'Farming Animals & Conservation'},
  {value: 'government & defence', text: 'Government & Defence'},
  {value: 'healthcare & medical', text: 'Healthcare & Medical'},
  {value: 'hospitality & tourism', text: 'Hospitality & Tourism'},
  {value: 'human resources & recruitment', text: 'Human Resources & Recruitment'},
  {value: 'information & communication technology', text: 'Information & Communication Technology'},
  {value: 'insurance & superannuation', text: 'Insurance & Superannuation'},
  {value: 'legal', text: 'Legal'},
  {value: 'manufacturing transport & logistics', text: 'Manufacturing Transport & Logistics'},
  {value: 'marketing & communications', text: 'Marketing & Communications'},
  {value: 'mining resources & energy', text: 'Mining Resources & Energy'},
  {value: 'real estate & property', text: 'Real Estate & Property'},
  {value: 'retail & consumer products', text: 'Retail & Consumer Products'},
  {value: 'sales', text: 'Sales'},
  {value: 'science & technology', text: 'Science & Technology'},
  {value: 'sport & recreation', text: 'Sport & Recreation'},
  {value: 'trades & services', text: 'Trades & Services'},
  {value: 'home duties', text: 'Home Duties'},
  {value: 'retired', text: 'Retired'},
  {value: 'student', text: 'Student'},
]

const industryValues = industries.map(item => item.value).join(',')

export default {
  components: { ButtonGroup3 },
  mixins: [subscriptions, shared],
  props: {
    incomes: {
        type: Array,
        default: () => []
    },
    data: {
      type: Object,
      default: null,
    },
    updater: undefined,
  },
  data() {
    return {
      moneyPlace: {
        covidWfh: '',
        primaryHours: '',
        primaryIndustry: '',
        primaryPermanent: '',
      },
      previousIndustry: '',
      tabSelection: "nodifi",
      resources: {
        people: {
          id: null,
          type: "incomes",
          attributes: {
            'covid-wfh': '',
            'primary-permanent': '',
            'primary-hours': '',
            'previous-industry': '',
            'primary-industry': '',
          },
        }
      },
      labels: {
        'covid-wfh': 'Is the applicant currently working from home due to COVID?',
        'primary-permanent': 'Is the applicants employment position permanent? ',
        'primary-hours': 'Is the applicants employment required hours greater than 20 hours per week?',
        'previous-industry': 'Previous Industry',
        'primary-industry': 'Current Industry',
      },
      validation_rules: {
          attributes: {
          'covidWfh': this.requiredCovidWfh ? 'required' : '',
          'primaryPermanent': this.requiredPrimaryPermanentAndHours ? 'required' : '',
          'primaryHours': this.requiredPrimaryPermanentAndHours ? 'required' : '',
          'primaryIndustry': `required|in:${industryValues}`,
          'previousIndustry': this.hasPreviousIncome ? `required|in:${industryValues}` : '',
        },
      },
      options: {
        industries,
      },
    };
  },
  computed: {
    startDate() {
      if (this.getSelectedIncome) {
        return moment(this.getSelectedIncome.attributes["start-date"]).format('DD/MM/YYYY')
      }
      return null
    },
    requiredCovidWfh () {
      return [
        'Full-Time', 'Part-Time', 'Casual', 'Contract', 'Self-Employed - Sole-Trader', 
        'Self-Employed - Company', 'Self-Employed - Other', 
      ].includes(this.data.attributes['employment-job-status'])
    },
    requiredPrimaryPermanentAndHours () {
      return ['Part-Time'].includes(this.data.attributes['employment-job-status'])
    },
    vb() {
      return this.$vuetify.breakpoint;
    },
    hasPreviousIncome () {

      return this.incomes.some(item => {

        return item.attributes['income-situation'] === 'Previous'

      })
    },
    getPreviousIncome () {

      let prevIncomes = this.incomes.filter(item => {
        return item.attributes['income-situation'] === 'Previous'
      })

      prevIncomes.sort((a,b)=> {
          return new Date(b.date) - new Date(a.date);
      });

      return prevIncomes.length ? prevIncomes[0] : []

    },
    getSelectedIncome(){
      return this.incomes.find(income => {
        return income.attributes['income-situation'] === 'Current'
      })
    }
  },
  mounted() {
    this.updateResourceFields();
  },
  methods: {
    ...mapActions("resource-actions", ["getPerson", "getIncome"]),
    isFieldType(field, key) {
      return field === "picklist"
        ? ["lender-wisr-industry", "lender-wisr-occupation"].includes(key)
        : false;
    },
    selectTab(tab) {
      this.tabSelection = tab;
    },
    isBtnGroup(key) {
      let keys = ["lender-wisr-forsee-repayment"];

      return keys.includes(key);
    },
    async validate() {
      const valid = await this.$refs.observer.validate();

      if (valid) {


      let payload = {
        current: {
          id: this.data.id,
          type: "incomes",
          attributes: {
            lender: {
              moneyPlace: {
                ...this.moneyPlace,
                primaryHours: this.moneyPlace.primaryHours === '1',
                covidWfh: this.moneyPlace.covidWfh === '1',
                primaryPermanent: this.moneyPlace.primaryPermanent === '1'
              }
            }
          }
        },
        previous: null
      }

      if(this.hasPreviousIncome) {
        payload.previous = {
          id: this.getPreviousIncome.id,
          type: "incomes",
          attributes: {
            lender: {
              moneyPlace: {
                previousIndustry: this.previousIndustry
              }
            }
          }
        }

      }

        this.$emit('submit', payload)
      }
    },
    reset() {
      this.$refs.observer.reset();
    },
    async updateResourceFields() {

      this.moneyPlace = {
        covidWfh: '',
        primaryHours: '',
        primaryIndustry: '',
        primaryPermanent: '',
      }

      this.previousIndustry = '',
      

      this.$emit("loading", true);

      const { data } = this;

      if (data) {

        let reqs = [this.getIncome(data.id)]

        if(this.hasPreviousIncome) {
          reqs.push(this.getIncome(this.getPreviousIncome.id))
        }

        let res = await Promise.all(reqs);

        const { moneyPlace } = res[0].data.data.attributes.lender

        this.moneyPlace = {
          ...moneyPlace,
          primaryHours: moneyPlace.primaryHours === null ? null : moneyPlace.primaryHours ? '1' : '0',
          covidWfh: moneyPlace.covidWfh === null ? null : moneyPlace.covidWfh ? '1' : '0',
          primaryPermanent: moneyPlace.primaryPermanent === null ? null : moneyPlace.primaryPermanent ? '1' : '0'
        }

        if(this.hasPreviousIncome) {
          this.previousIndustry = res[1].data.data.attributes?.lender?.moneyPlace?.previousIndustry
        }
        this.$emit("loading", false);
      } else {
        this.$emit("loading", false);
      }
    },
  },
  watch: {
    updater(val) {
      if (val) {
        this.updateResourceFields();
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
