<template>

    <v-card :disabled="isDisabled || !validComponentData" class="px-6" tile flat>

        <Portal to="step-header-buttons" v-if="$vuetify.breakpoint.mdAndUp">

            <QuotableComponentDialog
                title="Quick Edit"
                @confirm-update="getUpdateLoan" />

            <v-btn 
                v-if="lenders.length > 0"
                @click="selectNextStep"
                :disabled="!selectedAndConfirmedLender"
                depressed
                large
                class="secondary ml-2">
                Proceed
            </v-btn>

            <v-btn 
                v-else
                @click="proceedToSubmission"
                depressed
                elevation="0"
                large
                class="secondary ml-2">
                Proceed to Submission
            </v-btn>
        </Portal>

        <v-row class="ml-2 mr-2">
            <v-col cols="12" md="6" lg="7" class="mx-0 px-2">

                <v-row v-if="$vuetify.breakpoint.smAndDown" class="px-0 mx-0">
                    <v-col cols="6" :class="`px-0 mx-0 ${$vuetify.breakpoint.xsOnly ? 'pr-4' : 'px-1'}`">

                        <QuotableComponentDialog
                            title="Quick Edit"
                            @confirm-update="getUpdateLoan" />

                    </v-col>
                    <v-col cols="6" :class="`px-0 mx-0 ${$vuetify.breakpoint.xsOnly ? 'pl-4' : 'pl-1'}`">
                        <v-btn 
                            v-if="lenders.length > 0"
                            @click="selectNextStep"
                            block
                            elevation="0"
                            large
                            :disabled="!selectedAndConfirmedLender"
                            class="secondary">
                            Proceed
                        </v-btn>
                        <v-btn 
                            v-else
                            @click="proceedToSubmission"
                            block
                            elevation="0"
                            large
                            class="secondary">
                            Submit
                        </v-btn>
                    </v-col>
                </v-row>

                <v-container class="mt-n6">
                    <v-row>
                        
                        <v-col 
                            :cols="$vuetify.breakpoint.xlOnly ? 8 : 12"
                            class="px-2">
                            <v-row>
                                <v-col cols="6" :class="$vuetify.breakpoint.xlOnly ? 'px-1' : 'px-1'">
                                    <v-text-field 
                                        v-model="requested_broker_fee"
                                        label="Origination Fee"
                                        prefix="$"
                                        flat>
                                    </v-text-field>
                                </v-col>
                                
                                <v-col cols="6" :class="$vuetify.breakpoint.xlOnly ? 'px-1' : 'px-1'">
                                    <v-text-field 
                                        v-if="isCommercialFlow"
                                        v-model="requested_writing_rate"
                                        label="Customer Rate"
                                        suffix="%"
                                        flat>
                                    </v-text-field>
                                    <v-text-field 
                                        v-else
                                        v-model="customer_discount_rate"
                                        :disabled="isLockedRate"
                                        label="Customer Rate Reduction"
                                        suffix="%"
                                        flat>
                                    </v-text-field>
                                </v-col>
                            </v-row>
                        </v-col>

                        <v-col
                            :cols="$vuetify.breakpoint.xlOnly ? 4 : 12"
                            :class="$vuetify.breakpoint.xlOnly ? 'mt-12 pl-2' : 'mt-n13'"
                            class="px-0"
                        >
                            <v-btn 
                                @click="recalculate"
                                :loading="loading"
                                :disabled="disabled"
                                elevation="0"
                                block
                                large
                                class="secondary px-0">
                                Recalculate
                            </v-btn>
                        </v-col>

                        <v-col v-if="$vuetify.breakpoint.smAndDown">
                            <p class="display-1 font-weight-bold text-center mb-0">
                                Results
                            </p>
                        </v-col>

                        <v-col v-if="lenders.length > 0" cols="12" :class="$vuetify.breakpoint.xlOnly ? 'mt-n8' : ''" class="mt-n4 px-0 mx-0">
                            <FlexWrap @resize="handleResize">
                                <v-data-table
                                    loading-text="Please wait..." 
                                    no-data-text="No Results Found"                                           
                                    v-if="validComponentData"
                                    :headers="tableHeaders"
                                    :items="lenders"
                                    hide-default-footer
                                    @click:row="lenderDetails"
                                    class="elevation-0 v-data-table-2 allow-hover"
                                    :must-sort="true"
                                    :mobile-breakpoint="0">
                                    
                                    <!-- Body -->
                                    <template #[`item.lender`]="{ item }">
                                        <div class="inner center">
                                            <v-img 
                                                :max-width="$vuetify.breakpoint.lgAndUp ? 50 : 50"
                                                :height="$vuetify.breakpoint.lgAndUp ? 50 : 50"
                                                class="flex-brand" 
                                                :src="getLenderLogo(item.details['logo-class'])" 
                                                position="center" 
                                                contain>
                                            </v-img>
                                        </div>
                                        
                                    </template>

                                    <template #[`item.loan-term`]="{ item }">
                                        <div class="inner blocks px-0">
                                            <p class="d-none d-xl-flex ma-0 pa-0 font-weight-medium">
                                                {{ item.details['actual-payment']['term-in-months']  }} mths
                                            </p>
                                            <p class="d-flex d-sm-none ma-0 pa-0 font-weight-medium">
                                                {{ item.details['actual-payment']['term-in-months']  }} mths
                                            </p>
                                            <p class="d-none d-sm-flex d-md-none ma-0 pa-0 font-weight-medium">
                                                {{ item.details['actual-payment']['term-in-months']  }} mths
                                            </p>
                                            <p class="d-none d-md-flex d-xl-none ma-0 pa-0 px-2 font-weight-medium">
                                                {{ item.details['actual-payment']['term-in-months']  }} <span>&nbsp;mths</span>
                                            </p>
                                        </div>
                                    </template>

                                    <template #[`item.repayments`]="{ item }">
                                        <div class="inner blocks">
                                            <p class="text-no-wrap d-none d-xl-flex ma-0 pa-0 font-weight-medium">
                                                {{item.details['actual-payment']['monthly-payment'] | currency }}/mo
                                                <!-- {{ ( (item.details['actual-payment']['monthly-payment'] * 12 ) / 52 ) | currency }}/wk -->
                                            </p>
                                            <p class="text-no-wrap d-flex d-sm-none ma-0 pa-0 font-weight-medium">
                                                {{item.details['actual-payment']['monthly-payment'] | currency }}/mo
                                            </p>
                                            <p class="text-no-wrap d-none d-sm-flex d-md-none ma-0 pa-0 font-weight-medium">
                                                {{item.details['actual-payment']['monthly-payment'] | currency }}/mo
                                            </p>
                                            <p class="text-no-wrap d-none d-md-flex d-xl-none ma-0 pa-0 font-weight-medium">
                                                {{item.details['actual-payment']['monthly-payment'] | currency }}/mo
                                            </p>                                                    
                                        </div>
                                    </template>

                                    <template #[`item.comparison-rate`]="{ item }">
                                        <div class="inner blocks">                                                    
                                            <p class="ma-0 pa-0 font-weight-medium">
                                                {{ item.details['actual-payment']['comparison-rate'] | percentage }}
                                            </p>
                                        </div>
                                    </template>

                                    <template #[`item.customer-rate`]="{ item }">
                                        <div class="inner blocks">                                                    
                                            <p class="ma-0 pa-0 font-weight-medium">
                                                {{ item.details['actual-payment']['customer-rate'] | percentage }}
                                            </p>
                                        </div>
                                    </template>

                                    <template #[`item.amount-financed`]="{ item }">
                                        <div class="inner blocks">                                                    
                                            <p class="ma-0 pa-0 font-weight-medium">
                                                {{ item.details['actual-payment']['naf'] | currency }}
                                            </p>
                                        </div>
                                    </template>

                                    <template #[`item.terms`]="{ item }">
                                        <div class="inner blocks">                                                    
                                            <p class="ma-0 pa-0 font-weight-medium">
                                                {{ item.details['actual-payment']['term-in-months']  }} mths
                                            </p>
                                            <p class="text-no-wrap ma-0 pa-0 font-weight-medium">
                                                {{ ( (item.details['actual-payment']['monthly-payment'] * 12 ) / 52 ) | currency }}/wk
                                            </p>
                                        </div>
                                    </template>

                                    <template #[`item.actions`]="{ item }">
                                        <div class="inner blocks">
                                            <v-sheet width="45" height="45" rounded class="secondary pa-1" color="secondary">
                                                <v-icon color="white" large>mdi-dots-horizontal</v-icon>
                                            </v-sheet>
                                        </div>
                                    </template>
                                </v-data-table>
                            </FlexWrap>
                        </v-col>
                        <v-col v-else cols="12" :class="$vuetify.breakpoint.xlOnly ? 'mt-n8' : ''" class="mt-n4 px-0 mx-0">
                            <v-card flat class="text-center grey--text">
                                <v-card-text class="subtitle-1 pb-0">
                                    No Lenders Available
                                </v-card-text>
                                <v-card-text class="text-caption">
                                    Unfortunately we have not been able to find any lenders suitable for the applicant. Don’t worry, we may still be able to assist. You can send through your application and our processing team will review the application.
                                </v-card-text>
                            </v-card>
                        </v-col>

                        <v-col 
                            v-show="false"
                            v-if="$vuetify.breakpoint.lgOnly" 
                            cols="12" class="px-0 mx-0 mt-n3">
                            <div 
                                ref="quotable-selection"
                                id="quotable-selection">

                                <QuotableSelectionBadge 
                                    @user-confirmed="selectAndProceed"
                                    :user-confirmed-lender="confirmedSelectedLender"
                                    :lenders="lenders" />

                            </div>
                        </v-col>
                    </v-row>
                </v-container>
            </v-col>
            <v-col cols="12" md="6" lg="5" class="mx-0 px-0 pr-2">
                <v-row>
                    <v-col 
                        
                        v-if="$vuetify.breakpoint.smAndDown" 
                        cols="12" class="px-3 mx-0 mt-n6">
                        <div 
                            ref="quotable-selection"
                            id="quotable-selection">

                            <QuotableSelectionBadge 
                                @user-confirmed="selectAndProceed"
                                :user-confirmed-lender="confirmedSelectedLender"
                                :lenders="lenders" />

                        </div>
                    </v-col>
                    <v-col
                        v-if="$vuetify.breakpoint.mdAndUp && emptySelection" 
                        :cols="$vuetify.breakpoint.xlOnly ? 12 : 12"
                    >
                        <div 
                            ref="quotable-summary"
                            id="quotable-summary">

                            <QuotableSummary
                                @user-confirmed="selectAndProceed"
                                :user-confirmed-lender="confirmedSelectedLender"
                                :selected-lender="selectedLender"
                                :selected-logo="selectedLogo"
                                :loan-resource="loan"
                                :centered="true"
                            />

                        </div>
                    </v-col>
                    <v-col
                        v-else-if="$vuetify.breakpoint.mdAndUp"
                        :cols="$vuetify.breakpoint.xlOnly ? 12 : 12"
                    >
                        <QuotablePlaceholder />
                    </v-col>

                    <v-col 
                        v-show="false" 
                        v-if="$vuetify.breakpoint.xlOnly" 
                        cols="6" class="mx-0 px-0">
                        <div 
                            ref="quotable-selection"
                            id="quotable-selection"
                        >
                            <QuotableSelectionBadge 
                                @user-confirmed="selectAndProceed"
                                :user-confirmed-lender="confirmedSelectedLender"
                                :lenders="lenders"
                            />
                        </div>
                    </v-col>  

                </v-row>
            </v-col>
        </v-row>

        <QuotableSummaryDialog
            v-if="summaryDialog && $vuetify.breakpoint.smAndDown"
            @close="summaryDialog = false"
            @user-confirmed="selectAndProceed"
            :user-confirmed-lender="confirmedSelectedLender"
            :selected-lender="selectedLender"
            :selected-logo="selectedLogo"
            :loan-resource="loan"
            :dialog="summaryDialog" />

    </v-card>

</template>

<script>

    import { mapState, mapActions } from 'vuex'
    import QuotableSummary from './QuotableSummary'
    import QuotableComponentDialog from './QuotableComponentDialog'
    import QuotableSelectionBadge from './_QuotableSelectionBadge'
    import QuotablePlaceholder from './_QuotePlaceholder'
    import QuotableSummaryDialog from './_QuoteSummaryDialog'
    import FlexWrap from '~/components/base/FlexWrap'
    import subscriptions from '~/components/shared/subscriptions'
    import helpers from '~/plugins/helpers'
    import { isEmpty } from 'lodash'

    export default {
        name: 'IndicativeQuote',

        mixins: [ subscriptions, helpers ],

        components: {
            QuotableSummary,
            QuotableComponentDialog,
            QuotableSelectionBadge,
            QuotablePlaceholder,
            QuotableSummaryDialog,
            FlexWrap
        },

        computed: {
            ...mapState('auth-actions', ['appOwnerSettings']),

            isLockedRate() {
                return this.appOwnerSettings['locked-rate'] ? true : false
            },

            tableHeaders() {

                if ( !this.sizes ) return

                const { md, sm, xs } = this.sizes

                let newHeaders = [
                    ...this.headers
                ]

                let heads = [                    
                    { value: 'lender', width: '20%' },
                    { value: 'repayments', width: '20%' },
                    { value: 'customer-rate', width: '10%' },
                    { value: 'loan-term', width: '20%' },
                    { value: 'amount-financed', width: '30%' },
                ]
                
                if ( md ) {                    
                    heads = [ 
                        { value: 'lender', width: '15%' },
                        { value: 'repayments', width: '20%' },
                        { value: 'customer-rate', width: '10%' },
                        { value: 'loan-term', width: '15%' },
                        { value: 'amount-financed', width: '30%' }
                    ]
                }

                if ( sm ) {                    
                    heads = [ 
                        { value: 'lender', width: '20%' },
                        { value: 'repayments', width: '30%' },
                        { value: 'loan-term', width: '20%' },
                        { value: 'amount-financed', width: '30%' }
                    ]
                }

                if(xs){
                    heads = [ 
                        { value: 'lender', width: '33%' },
                        { value: 'loan-term', width: '33%' },
                        { value: 'amount-financed', width: '33%' }
                    ]
                }

                // return newHeaders.filter( head => heads.includes(head.text))
                return newHeaders.reduce((accumulator, current) => {

                    heads.forEach(head => {

                        if(head.value == current.value){

                            let newCurrent = {...current}

                            newCurrent.width = head.width

                            accumulator.push(newCurrent)
                        }

                    })

                    return accumulator 

                }, [])

            },

            loan() {
                let payload = this.responsePayload?.find( res => {
                    return res.data?.data?.type === 'loans'
                })
                
                let loan = {
                    attributes: payload?.data?.data?.attributes,
                    id: payload?.data?.data?.id,
                    relationships: payload?.data?.data?.relationships,
                    type: 'loans'
                }
                return loan
            },

            lenders() {
                
                let lenders = []
                let quotes = {}

                quotes = this.responsePayload?.find( res => {
                    return res.data?.data?.type === 'applications'
                })

                if ( quotes ) {  
                    
                    let data = quotes?.data?.included?.[0]?.attributes?.results ? quotes.data.included[0].attributes.results : {}
                    
                    if (!isEmpty(data)) {
                        data = JSON.parse(data)

                        // check if lenders is null return []
                        if ( data.lenders == null ) return lenders
                           
                        Object.keys( data?.lenders ).forEach( lender => {
                            if(!data?.lenders[lender].hasOwnProperty('status') && data?.lenders[lender]['quotable'] == 'Yes') {
                                lenders.push({ 
                                    name: lender, 
                                    details: data.lenders[lender],
                                    'customer-rate': data.lenders[lender]['actual-payment']['customer-rate'],
                                    term: data.lenders[lender]['actual-payment']['term-in-months'],
                                    'amount-financed': data.lenders[lender]['actual-payment']['naf'],
                                    'repayments': data.lenders[lender]['actual-payment']['monthly-payment']
                                })
                            }
                        })
                        
                    }
                    
                }

                return lenders
                
            },

            isCommercialFlow() {
                return this.loan?.attributes?.['finance-for-business-purposes'] === 'Yes' ? true : false
            },

            emptySelection() {
                return isEmpty( this.selectedLender ) ? false : true
            },

            selectedAndConfirmedLender() {
                return this.lenders.find( lender => {
                    return lender.name === this.confirmedSelectedLender
                })
            },
            getLoanId(){
                return this.appData?.relationships?.loan?.data?.id
            }
            
        },

        async mounted() {
            // populate loan field
            if (this.getLoanId) {

                const loan = await this.getLoan(this.getLoanId)
                this.populateLoan(loan?.data?.data)

            }

            // is locked rate
            if (this.appOwnerSettings['locked-rate']) {
                if(!this.isCommercialFlow)
                    this.customer_discount_rate = this.appOwnerSettings['locked-discount']
                    
            }

        },

        methods: {

            ...mapActions('resource-actions', [
                'getQuotes',
                'setApplicationNow',
                'updateLoan',
                'getLoan'
            ]),
            ...mapActions('flows', ['selectNextStep']),

            async pull() {

                // this.responsePayload = []

                let response = await this.getQuotes({
                    'type': 'applications',
                    'id': this.appId,
                    'attributes': {}
                })

                this.responsePayload = response

                this.lenders.forEach( lender => {
                    if ( lender.details.name === this.loan.attributes['quoted-lender-selected'] ) {
                        this.confirmedSelectedLender = lender.name
                        this.selectedLender = lender
                    }
                })                

            },

            async push() {
                if (this.lenders.length > 0) {
                    if ( this.selectedAndConfirmedLender?.name ) {
                        // set loan quoted
                        await this.populateLoanFields()
                        // console.log(this.selectedLender.details)

                        // remove null or empty attributes
                        if(this.loan?.attributes) {
                            this.loan.attributes = await this.removeNullAttributes(this.loan.attributes)
                        }

                        // remove validations
                        if (this.loan?.relationships?.validations) {
                            delete this.loan.relationships.validations
                        }

                        let res = await this.setApplicationNow({
                            payload: this.loan,
                            picked: this.selectedLender.details.name
                        })
                        
                        let data = this.$options.filters.toAppDataFormat(res)

                        return  { valid: true, status: 'valid', data } 
                        
                    }

                    this.$store.commit('utils/toggleQuickMessage',  {text: 'Please select a lender!', color: 'warning'})

                    return  { valid: false, status: 'dirty' }

                } else {

                    return  { valid: true, status: 'valid'}

                } 
            },

            removeNullAttributes(attributes = {}) {

                let newAttributes = {}

                Object.keys(attributes).forEach(attribute => {
                    if(attributes[attribute] != null && attributes[attribute] != "") {
                        newAttributes[attribute] = attributes[attribute]
                    }
                })

                return newAttributes

            },

            populateLoanFields() {

                this.loan.attributes['quoted-balloon'] = this.selectedLender.details['actual-payment']['balloon']
                this.loan.attributes['quoted-bank-fees'] = this.selectedLender.details['actual-payment']['bank-fees']
                this.loan.attributes['quoted-base-rate'] = this.selectedLender.details['actual-payment']['base-rate']
                this.loan.attributes['quoted-broker-commission-inc-gst'] = this.selectedLender.details['actual-payment']['commission-inc-gst']
                this.loan.attributes['quoted-broker-fee'] = this.selectedLender.details['actual-payment']['broker-fee']
                this.loan.attributes['quoted-customer-rate'] = this.selectedLender.details['actual-payment']['customer-rate']
                this.loan.attributes['quoted-monthly-payments'] = this.selectedLender.details['actual-payment']['monthly-payment']
                this.loan.attributes['quoted-naf'] = this.selectedLender.details['actual-payment']['naf']
                this.loan.attributes['quoted-account-fee'] = this.selectedLender.details['actual-payment']['account-fee']
                this.loan.attributes['quoted-term-months'] = this.selectedLender.details['actual-payment']['term-in-months']
                this.loan.attributes['quoted-lender-selected'] = this.selectedLender.details.name
                    
            },

            async getUpdateLoan(data) {

                let payload = {
                    type: data.type,
                    id: data.id,
                    attributes: data.attributes
                }

                if ( data?.id ) {
                    await this.updateLoan(payload)
                }

                await this.pull()
            },

            async proceedToSubmission() {
                await this.selectNextStep()
            },

            selectAndProceed(data) {
                this.confirmedSelectedLender = data
            },

            lenderDetails(data) {

                this.selectedLender = data
                this.selectedLogo = this.getLenderLogo(data.details['logo-class'])
                this.summaryDialog = true
                
            },

            lenderDetailsItem(data) {

                this.selectedLender = data
                this.selectedLogo = this.getLenderLogo(data.details['logo-class'])

                setTimeout( () => {
                    let el = this.$refs['quotable-selection']
                    let top = el.offsetTop                   
                    this.$vuetify.goTo(top)
                }, 1)
                
            },

            async recalculate() {
                
                this.disabled = true
                this.loading = true

                let data = {
                    "id": this.loan.id,
                    "type": "loans",
                    "attributes": {
                        "requested-broker-fee": this.requested_broker_fee,
                        "requested-writing-rate": this.requested_writing_rate,
                        "customer-discount-rate": this.customer_discount_rate
                    }
                }

                // requested-writing-rate == commercial flows
                // customer-discount-rate == consumer flows

                let res = await this.updateLoan(data)
                
                if ( res.status === 200 ) {
                    
                    await this.pull()
                }

                // let res = await this.generateQuotes({
                //     data: {
                //         type: "quotes",
                //         attributes: this.form
                //     }
                // })
                
                this.disabled = false
                this.loading = false
                this.iscalculated = true
            },

            getLenderLogo(name){

                let lender = require(`~/assets/images/logo/placeholder.png`)

                try {

                    lender = require(`~/assets/images/logo/${name}.png`)
                    return lender

                } catch (error) {

                    console.log('Ooops!..', error)
                    return lender

                }

            },

            calculatable() {
                if ( this.isCommercialFlow ) {
                    if (this.requested_writing_rate == '' || this.requested_broker_fee == '') {
                        this.disabled = true
                    }
                    else {
                        this.disabled = false
                    }
                }
                else {
                    if (this.customer_discount_rate == '' || this.requested_broker_fee == '') {
                        this.disabled = true
                    }
                    else {
                        this.disabled = false
                    }
                }
                
            },

            populateLoan(data) {
                // populate some loans fields
                if (data.hasOwnProperty('attributes')) {

                    this.requested_broker_fee = data.attributes['requested-broker-fee']
                    this.requested_writing_rate = data.attributes['requested-writing-rate']
                    this.customer_discount_rate = data.attributes['customer-discount-rate']

                }

            }
            
        },

        watch: {

            'requested_broker_fee': {
                handler(value) {
                    this.calculatable()
                },
                deep: true
            },

            'requested_writing_rate': {
                handler(value) {
                    this.calculatable()
                },
                deep: true
            },

            'customer_discount_rate': {
                handler(value) {
                    this.calculatable()
                },
                deep: true
            },

        },

        data() {
            return {
                selectedLender: {},
                confirmedSelectedLender: '',
                summaryDialog: false,
                selectedLogo: '',
                validComponentData: true,
                requested_broker_fee: '',
                requested_writing_rate: 0,
                customer_discount_rate: 0,
                iscalculated: false,
                disabled: true,
                headers: [
                    { text: 'Lender', sortable: false, value: 'lender', width: '20%'},
                    { text: 'Repayments', sortable: true, value: 'repayments', width: '20%'},                    
                    { text: 'Rate', sortable: true, value: 'customer-rate', width: '20%'},
                    { text: 'Term', sortable: false, value: 'loan-term', width: '20%'},
                    { text: 'Amount Financed', sortable: true, value: 'amount-financed', width: '20%'},
                    // { text: 'Comparison Rate', sortable: false, value: 'comparison-rate', width: '20%'}                    
                ],
                responsePayload: []
            }
        }
    }
</script>

<style lang="scss" scoped>
    .allow-hover {
        table {
            tbody {
                tr {
                    &:hover {
                        background-color: red !important;
                    }
                }
            }
        }   
    }
</style>