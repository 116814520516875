<template>
    <v-dialog
        persistent
        scrollable
        height="600"
        max-width="1100"
        v-model="dialog">

        <v-card flat tile :disabled="saving" class="pa-4">

            <v-progress-linear color="secondary" indeterminate stream absolute top v-if="saving"></v-progress-linear>

            <v-card-title class="d-flex">
                <div class="v-card__title pa-0 sm">
                    Loan Details
                </div>
                <v-spacer />
                <div>
                    <!-- image -->
                </div>
            </v-card-title>

            <v-card-text>
                <ValidationObserver 
                    ref="observer" 
                    tag="form"
                >   
                    <v-row>
                        <v-col :cols="vb.smAndUp ? isUnsecured ? 6 : 4 : 12" 
                            v-for="(field, key) in fieldFilter"
                            :key="key"
                            class="py-0"
                        >
                            <ValidationProvider
                                v-if="isVisible(key)"
                                v-slot="{ errors }"
                                :name="labels[key]"
                                :rules="getRules(key)"
                                :vid="key">

                                <v-select
                                    v-if="isFieldType('picklist', key)"
                                    v-model="fieldFilter[key]"
                                    :items="options[key]"
                                    item-text="key"
                                    item-value="value"
                                    :error-messages="errors"
                                    color="secondary"
                                    :suffix="suffix[key]"
                                    :label="labels[key]"
                                ></v-select>

                                <template v-else>
                                    <v-text-field 
                                        v-if="key == 'base-rate' || key == 'customer-rate'"
                                        v-model="fieldFilter[key]"
                                        :label="labels[key]"
                                        :error-messages="errors"
                                        suffix="%"
                                        flat>
                                    </v-text-field>
                                    <NodifiMoney
                                        v-else
                                        :disabled="key == 'total-loan-amount'"
                                        v-model="fieldFilter[key]"
                                        :label="labels[key]"
                                        :errors="errors" />   
                                </template>
                                    
                                                                   

                            </ValidationProvider>
                            
                        </v-col>
                    </v-row>
                </ValidationObserver>
            </v-card-text>

            <v-card-actions class="pa-4 ma-2">

                <v-btn @click="$emit('back')" :min-width="140" large depressed outlined color="secondary">
                    Back
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn @click="addLender" :min-width="140" large depressed class="secondary">
                    Next
                </v-btn>
                
            </v-card-actions>

        </v-card>

    </v-dialog>
</template>
<script>
import subscriptions from '~/components/shared/subscriptions'
import helpers from '~/plugins/helpers'
import { store } from './__store'

export default {
    mixins: [ subscriptions, helpers ],
    props: {
        dialog: {
            type: Boolean,
            default: true
        },
        selectedLender: {
            type: Object,
            default: () => {}
        },
        title: {
            type: String,
            default: 'Lender'
        },
        requiredLoanAmount: {
            type: [String, Number],
            default: ""
        }
    },
    data() {
        return {
            saving: false,
            resources: {
                "bid-lenders": {
                    type: "bid-lenders",
                    attributes: {
                        "loan-term": '',
                        "payment-terms": '',
                        "balloon-amount": 0,
                        "establishment-fee": 0,
                        "ppsr-fee": 0,
                        "origination-fee": 0,
                        "base-rate": 0,
                        "customer-rate": 0,
                        "total-loan-amount": 0,
                        "brokerage-commission": 0,
                        "total-commission": 0,
                        "monthly-account-fee": 0,
                        "monthly-payment": 0,
                        "lender-name": '',
                        "risk-fee": 0,
                    }
                },
                "unsecured": {
                    type: "bid-lenders",
                    attributes: {
                        "loan-term": '',
                        "customer-rate": '',
                        "establishment-fee": '',
                        "risk-fee": '',
                        "origination-fee": '',
                        "total-loan-amount": '',
                        "brokerage-commission": '',
                        "total-commission": '',
                        "monthly-account-fee": '',
                        "monthly-payment": '',
                        "lender-name": '',
                    }
                }
            },
            labels: {
                "loan-term": 'Loan term',
                "payment-terms": 'Payment Terms',
                "balloon-amount": 'Balloon Payment',
                "establishment-fee": 'Establishment Fee',
                "ppsr-fee": 'PPSR Fee',
                "origination-fee": 'Origination Fee',
                "base-rate": 'Base Rate',
                "customer-rate": 'Customer Rate',
                "total-loan-amount": 'Amount Financed',
                "brokerage-commission": 'Brokerage Commission',
                "total-commission": 'Total Commission',
                "monthly-account-fee": 'Monthly Fee',
                "monthly-payment": 'Monthly Payment',
                "risk-fee": 'Risk Fee',
            },
            validation_rules: {
                attributes: {
                    "loan-term": 'required|in:12,24,36,48,60,72,84',
                    "payment-terms": 'required|in:In Arrears,In Advance',
                    "balloon-amount": 'required',
                    "establishment-fee": 'required',
                    "ppsr-fee": 'required',
                    "origination-fee": 'required',
                    "base-rate": 'required|between:0,99',
                    "customer-rate": 'required|between:0,99',
                    "total-loan-amount": 'required',
                    "brokerage-commission": 'required',
                    "total-commission": 'required',
                    "monthly-account-fee": 'required',
                    "monthly-payment": 'required',
                    "lender-name": '',
                    "risk-fee": 'required',
                }
            },
            options: {
                "loan-term": [
                    { key: "12", value: "12" },
                    { key: "24", value: "24" },
                    { key: "36", value: "36" },
                    { key: "48", value: "48" },
                    { key: "60", value: "60" },
                    { key: "72", value: "72" },
                    { key: "84", value: "84" }
                ],
                "payment-terms": [ "In Arrears", "In Advance" ]
            },
            visible: {
                "risk-fee": false,
                "lender-name": false
            },
            suffix: {
                "loan-term": 'months',
                "payment-terms": ''
            },
        }
    },
    watch: {
        dialog(val) {

            if(this.isUnsecured)
                this.resources['unsecured'].attributes['total-loan-amount'] = this.computeLoanAmount()
            else
                this.resources['bid-lenders'].attributes['total-loan-amount'] = this.computeLoanAmount()

        },
        'resources': {
            handler(val) {
                if(val['bid-lenders'].attributes['establishment-fee'] || val['bid-lenders'].attributes['risk-fee'] || val['bid-lenders'].attributes['origination-fee']) {
                    val['bid-lenders'].attributes['total-loan-amount'] = this.computeLoanAmount()
                }

                if(val['unsecured'].attributes['establishment-fee'] || val['unsecured'].attributes['risk-fee'] || val['unsecured'].attributes['origination-fee']) {
                    val['unsecured'].attributes['total-loan-amount'] = this.computeLoanAmount()
                }
            },
            deep: true
        },
    },
    computed: {
        isUnsecured() {
            return store.isUnsecured
        },
        fieldFilter() {
            let field = {}

            if(this.isUnsecured) {
                field = this.resources['unsecured'].attributes
            } else {
                field = this.resources['bid-lenders'].attributes
            } 

            return field
        }
    },
    methods: {
        async addLender(){
            const valid = await this.$refs.observer.validate()

            if(valid) {
                this.$emit('submit', this.fieldFilter)
                this.$emit('close-modal')
            }
        },
        computeLoanAmount() {
            let total = 0
            let establishment = 0
            let risk = 0
            let origination = 0
            let loanAmount = this.requiredLoanAmount ? parseInt(this.requiredLoanAmount) : 0

            if(this.isUnsecured) {

                establishment = this.resources['unsecured'].attributes['establishment-fee'] ? parseInt(this.resources['unsecured'].attributes['establishment-fee']) : 0
                risk = this.resources['unsecured'].attributes['risk-fee'] ? parseInt(this.resources['unsecured'].attributes['risk-fee']) : 0
                origination = this.resources['unsecured'].attributes['origination-fee'] ? parseInt(this.resources['unsecured'].attributes['origination-fee']) : 0

                total = establishment + risk + origination + loanAmount

            } else {

                establishment = this.resources['bid-lenders'].attributes['establishment-fee'] ? parseInt(this.resources['bid-lenders'].attributes['establishment-fee']) : 0
                risk = this.resources['bid-lenders'].attributes['risk-fee'] ? parseInt(this.resources['bid-lenders'].attributes['risk-fee']) : 0
                origination = this.resources['bid-lenders'].attributes['origination-fee'] ? parseInt(this.resources['bid-lenders'].attributes['origination-fee']) : 0

                total = establishment + risk + origination + loanAmount

            }

            return total
        },
        isVisible(key){
                
            const {visible} = this

            if(visible.hasOwnProperty(key)){
                return visible[key]
            }

            return true

        }
    }
}
</script>
