import Vue from "vue";

export const store = Vue.observable({
  steps: {
    personal: {
      title: "Personal Information",
      subtitle:
        "Please use the Nodifi Data to answer the questions in the Wisr Requirements",
      "form-ref": "PersonalInformationForm",
    },
    employment: {
      title: "Data Mapping",
      subtitle:
        "Please use the Nodifi Data to answer the questions in the Wisr Requirements",
      "form-ref": "EmploymentInformationForm",
    },
  },

  carousel: 0,
});

export const actions = {
  setCarousel(payload) {
    store.carousel = payload;
  },

  resetStore() {
    store.carousel = 0;
  },

  setCarouselNext() {
    store.carousel++;
  },
  setCarouselBack() {
    store.carousel--;
  },
};
