<template>
    <v-card :disabled="isDisabled || !validComponentData" class="mx-auto" tile flat>
        <v-card-text class="pa-4 pt-0 pa-md-10 pt-md-0">
            <v-container pa-0 fluid>
                <v-row>
                    <v-col cols="12">
                        <ValidationObserver ref="observer" tag="form">
                            <v-row wrap row>
                                <v-flex lg3 md6 sm6 xs12 px-3>
                                    <div class="flex-form">
                                        <div class="flex-field">
                                            <ValidationProvider 
                                                v-slot="{ errors }" 
                                                :name="'residential-situation' | formalText" 
                                                rules="required"
                                                vid="residential-situation">
                                                <v-select
                                                    v-model="resourceObj.occupancies.current.attributes['residential-situation']"
                                                    :error-messages="errors"
                                                    :label="labels['residential-situation']"
                                                    ref="residential-situation"
                                                    :items="situation"
                                                    color="secondary"
                                                    :hint="hint.residential_status"
                                                    persistent-hint></v-select>
                                            </ValidationProvider>
                                        </div>
                                    </div>
                                </v-flex>
                            </v-row>

                            <v-row wrap row>
                                <v-flex lg3 md6 sm6 xs12 px-3>
                                    <!-- CURRENT ADDRESS -->
                                    <div class="flex-form">
                                        <div class="flex-field">
                                            <ValidationProvider 
                                                v-slot="{ errors }" 
                                                :name="'current-estimated-term-in-months' | formalText" 
                                                rules="required"
                                                vid="current-estimated-term-in-months">
                                                <v-text-field
                                                    v-model="resourceObj.occupancies.current.attributes['estimated-term-in-months']"
                                                    :error-messages="errors"
                                                    :label="labels['current-estimated-term-in-months']"
                                                    ref="current-estimated-term-in-months"
                                                    color="secondary"
                                                    type="number"
                                                    suffix="Months"
                                                    :hint="hint['current-term']"
                                                    persistent-hint></v-text-field>
                                            </ValidationProvider>
                                        </div>
                                    </div>
                                </v-flex>

                                <v-flex lg3 md6 sm6 xs12 px-3>
                                    <div class="flex-form">
                                        <div class="flex-field">
                                            <ValidationProvider                                                 
                                                v-slot="{ errors }" 
                                                :name="'current-residential-address' | formalText" 
                                                rules="required"
                                                vid="current-residential-address">
                                                <v-text-field
                                                    readonly
                                                    :disabled="resourceObj.occupancies.current.attributes['estimated-term-in-months'] ? false : true"
                                                    @click="current_address_dialog = true"
                                                    v-model="current_address"
                                                    :error-messages="errors"
                                                    :label="labels['current-residential-address']"
                                                    color="secondary"></v-text-field>

                                            </ValidationProvider>
                                        </div>
                                    </div>
                                </v-flex>

                                <v-flex lg3 md6 sm6 xs12 px-3>
                                    <!-- PREVIOUS ADDRESS -->
                                    <div class="flex-form"
                                        v-if="currentEstimatedTerm <= 35">
                                        <div class="flex-field">
                                            <ValidationProvider                                         
                                                v-slot="{ errors }" 
                                                :name="'previous-estimated-term-in-months' | formalText" 
                                                rules="required"
                                                vid="previous-estimated-term-in-months">
                                                <v-text-field
                                                    v-model="resourceObj.occupancies.previous.attributes['estimated-term-in-months']"
                                                    :error-messages="errors"
                                                    :label="labels['previous-estimated-term-in-months']"
                                                    ref="previous-estimated-term-in-months"
                                                    color="secondary"
                                                    type="number"
                                                    suffix="Months"
                                                    :hint="hint.previous_term"
                                                    persistent-hint></v-text-field>
                                            </ValidationProvider>
                                        </div>
                                    </div>
                                </v-flex>

                                <v-flex lg3 md6 sm6 xs12 px-3>
                                    <div class="flex-form"
                                        v-if="currentEstimatedTerm <= 35">
                                        <div class="flex-field">
                                            <ValidationProvider 
                                                v-slot="{ errors }" 
                                                :name="'previous-residential-address' | formalText" 
                                                rules="required"
                                                vid="previous-residential-address">
                                                <v-text-field
                                                    readonly
                                                    :disabled="resourceObj.occupancies.previous.attributes['estimated-term-in-months'] ? false : true"
                                                    @click.prevent="previous_address_dialog = true"
                                                    v-model="previous_address"
                                                    :error-messages="errors"
                                                    :label="labels['previous-residential-address']"
                                                    color="secondary"></v-text-field>
                                            </ValidationProvider>
                                        </div>
                                    </div>
                                </v-flex>

                            </v-row>
                            
                        </ValidationObserver>
                    </v-col>
                </v-row>
            </v-container>

            <CurrentAddressEditor 
                v-model="current_address_dialog" 
                @close="close"
                @preloaded-value="fetchAddress"
                @update-address="updateAddress"
                @no-existing-records="preCreateOccupancy"
                :occupancy-resources="resourceObj.occupancies"
                :address="address.current"
                address-type="current"
                :occupancy="occupancy.current"
                :person="person">

                <template v-slot:subheader>
                    <strong>ADDRESS DETAILS</strong>&nbsp;                         
                </template>

                <template v-slot:title-1>
                    <v-col class="py-0 pt-10">
                        <v-card-title>
                            Current Address Details
                        </v-card-title>
                        <v-card-subtitle>
                            Please fill out the details below accurately
                        </v-card-subtitle>
                    </v-col>
                </template>
            </CurrentAddressEditor>

            <PreviousAddressEditor 
                v-model="previous_address_dialog" 
                @close="close"
                @preloaded-value="fetchAddress"
                @update-address="updateAddress"
                @no-existing-records="preCreateOccupancy"
                :occupancy-resources="resourceObj.occupancies"
                :address="address.previous"
                address-type="previous"
                :occupancy="occupancy.previous"                
                :person="person">

                <template v-slot:subheader>
                    <strong>ADDRESS DETAILS</strong>&nbsp;                         
                </template>

                <template v-slot:title-1>
                    <v-col class="py-0 pt-10">
                        <v-card-title>
                            Previous Address Details
                        </v-card-title>
                        <v-card-subtitle>
                            Please fill out the details below accurately
                        </v-card-subtitle>
                    </v-col>
                </template>
            </PreviousAddressEditor>
            
        </v-card-text>
    </v-card>
</template>

<script>

    import CurrentAddressEditor from './_AddressEditorDialog1'
    import PreviousAddressEditor from './_AddressEditorDialog1'
    import subscriptions from '~/components/shared/subscriptions'
    import { mapActions, mapGetters } from 'vuex'
    import { isEmpty } from 'lodash'
    
    export default {
        name: 'ResidentialHistoryEditor3',

        mixins: [ subscriptions ],

        components: { CurrentAddressEditor, PreviousAddressEditor },

        created() {
            this.resourceObj.occupancies = this.resourceObjHelper()            
        },

        computed: {
            ...mapGetters('kinds', ['validKindsWithPriorities']),

            activePerson() {
                return this.person
            },
            currentEstimatedTerm() {
                let result = this.resourceObj?.occupancies?.current?.attributes?.['estimated-term-in-months']
                return parseInt(result)
            }
        },

        methods: {

            ...mapActions('resource-actions', [
                'getOccupanciesData',
                'saveOccupancies',
                'getOccupancyAddress',
                'createAddress'
            ]),

            /**
             * Calling actions: getOccupanciesData 
             * from store which trigger an axios call,
             * pulling all data from an api response
             */
            async pull() {
                
                let response = await this.getOccupanciesData()
                
                if ( response.data?.included )
                    response.data.included.forEach( data => {
                        if ( data.attributes['occupancy-situation'] == 'Current' )
                            this.occupancy.current = data.id
                        else if ( data.attributes['occupancy-situation'] === 'Previous' )
                            this.occupancy.previous = data.id
                    })

                // this.person = this.getPriorityParts( response.data )
                this.person = this.getPrimaryApplicant(response.data)
                
                this.validComponentData = this.validKindsWithPriorities
                
                // Get person/people resources
                if (response.data.included && response.data.included.length > 0) {   
                    this.resourceObj.occupancies = this.resourceObjHelper(response.data, response.data.data)
                    
                    let addressRes = []

                    Object.keys(this.occupancy).forEach( occ => {
                        addressRes.push( this.getOccupancyAddress({ id: this.occupancy[occ], include: 'address' }) )
                    })

                    try {
                        let resPayload = Promise.all(addressRes).then( res => {
                            res.forEach( payload => {
                                if ( payload.data?.included ) {
                                    if ( payload.data.data.attributes['occupancy-situation'] === 'Current' ) {
                                        this.address.current = payload.data.included.find( individual_payload => {
                                            return individual_payload.type === 'addresses'
                                        })
                                        
                                    }
                                    else if ( payload.data.data.attributes['occupancy-situation'] === 'Previous' ) {
                                        this.address.previous = payload.data.included.find( individual_payload => {
                                            return individual_payload.type === 'addresses'
                                        })
                                    }

                                }
                            })
                        })                        

                    } catch (error) {
                        console.log('Oooops!..', error)
                    }
                    
                    // let addressRes = await this.getOccupancyAddress({ id: this.occupancy?.id, include: 'address' })
                    
                    // this.address = addressRes.data.included.find( data => {
                    //     return data.type === 'addresses'
                    // })
                }

                
                this.resources = []
                this.resources[0] = this.resourceObj.occupancies.current
                this.resources[1] = this.resourceObj.occupancies.previous

                this.emptiedResources(this.validComponentData ? false : true)
                
                if ( this.activePerson?.id )
                    this.resourceObj.occupancies = this.attachPersonRelationships(this.resourceObj.occupancies, this.activePerson, 'occupancies', 'person')
                
            },            

            /**
             * Calling actions: saveOccupancies 
             * from store which trigger an axios call,
             * saving/updating all data in DB from local resource
             */
            async push() {

                const valid = await this.$refs.observer.validate()
                const observer = this.$refs.observer
                const touched = this.$refs.observer.flags.touched

                // Getter getActiveStepStatus from: shared/subscriptions.js
                let status = this.getActiveStepStatus.attributes.value 

                if(this.hardValidation) {
                    status =  valid ? 'valid' : 'dirty'
                } else {
                    // if(touched) { status =  valid ? 'valid' : 'dirty' }
                    status =  valid ? 'valid' : 'dirty'
                }

                this.request_data = []
                
                this.resources.forEach( f => {
                    f.attributes = this.nullifyInvalids(f.attributes)
                })                
                
                let res = await this.saveOccupancies( 
                    this.filterResourceWithoutTheseRelationships(['occupancies'], this.request_data) 
                )

                this.preCreateAddresses(res)
                
                if (this.isAllPromisesValid(res)) {
                    
                    let data = this.$options.filters.toAppDataFormat(res)

                    // await this.pull()
                    this.resourceObj.occupancies = this.fetchNewlyUpdatedRecord( res, this.resources, this.fields, 'occupancy-situation' )
                    
                    return  { valid, status, data } 
                }
                else {
                    return  { valid, status } 
                }

            },

            preCreateAddresses(reqData = []) {
                reqData.forEach( async (req) => {
                    
                    let req_type = req.data.data.attributes['occupancy-situation'].toLowerCase()

                    if ( !isEmpty( this.occupancyResource[req_type] ) ) {
                        // this.occupancyResource[req_type].relationships.occupancies.data[0].id = req.data.data.id
                        this.occupancyResource[req_type].relationships.occupancies.data.forEach( d => {
                            d.id = req.data.data.id
                        })
                        await this.createAddress( this.occupancyResource[req_type] )
                        this.occupancyResource[req_type] = {}
                    }
                })
            },

            preCreateOccupancy(data) {
                this.occupancyResource[data.type] = data.payload
            },

            fetchAddress(data) {
                
                if ( data.type === 'current' )
                    this.current_address = data.address
                else if ( data.type === 'previous' )
                    this.previous_address = data.address

            },

            updateAddress(data) {
                if ( data.type === 'current' )
                    this.address.current = data.data
                if ( data.type === 'previous' )
                    this.address.previous = data.data
            },

            /**
             * HELPERS:
             * NOTE: Can also be overrided if a specific helper will be implemented
             */
            nullifyInvalids(fields = {}) {
                
                let { request_data } = this
                let sortedFields = {}
                
                Object.keys(fields).forEach( field => {
                    
                    if(fields[field] || typeof fields[field] == 'boolean') {
                        sortedFields[field] = fields[field]
                    }
                    else {
                        sortedFields[field] = "" 
                    }
                    
                })

                let temp = {}

                if (sortedFields['occupancy-situation'] === 'Current') {
                    if ( (sortedFields['residential-situation'].length) || sortedFields['estimated-term-in-months'].length) {
                        
                        temp = Object.assign({}, this.resourceObj.occupancies.current)
                        
                        Object.keys(temp.attributes).forEach( f => {
                            if (temp.attributes[f] === '' || temp.attributes[f] === null) {
                                delete temp.attributes[f]
                            }
                        })
                        
                        request_data.push( temp )
                    }
                }
                else if (sortedFields['occupancy-situation'] === 'Previous') {
                    if ( sortedFields['estimated-term-in-months'].length ) {
                        
                        temp = Object.assign({}, this.resourceObj.occupancies.previous)
                        
                        Object.keys(temp.attributes).forEach( f => {
                            if (temp.attributes[f] === '' || temp.attributes[f] === null)
                                delete temp.attributes[f]
                        })

                        request_data.push( temp )
                    }
                }
                
                return sortedFields

            },

            /**
             * Fetching API response data for compilation
             * from API/axios call
             */
            resourceObjHelper(resources, parentData) {
                
                let obj = {}
                let collection = []
                
                /**
                 * IF fetched data is not empty:
                 * Data are fetch from the API and is compiled for interaction
                 */                
                if (parentData && this.activePerson?.relationships) {
                    
                    if ( this.activePerson?.relationships?.child ) {
                        collection = resources.included.filter( f => {
                            return f.type === 'occupancies' && ( f.relationships.person && JSON.stringify(f.relationships.person.data) === JSON.stringify(this.activePerson.relationships.child.data ) )
                        })
                    }
                    else {
                        collection = resources.included.filter( f => {                                
                            return f.type === 'occupancies' && ( f.relationships.person && f.relationships.person.data.id === this.activePerson.id )
                        })                   
                    }

                    if (collection.length) obj = this.compileResources( collection )
                    else obj = this.compileResources(this.resources)
                }

                /**
                 * ELSE: This will use component data as default
                 */
                else {
                    obj = this.compileResources(this.resources)
                }
                
                return obj

            },

            emptiedResources( isInvalid = false ) {
                this.resources.forEach( res => {
                    let temp = {}

                    Object.keys(this.fields).forEach( f => {
                        if ( f !== 'occupancy-situation') temp[f] = isInvalid ? null : res?.attributes[f]
                        else temp[f] = res?.attributes[f]
                    })

                    res.attributes = {}
                    res.attributes = temp
                    if (isInvalid) res.id = null
                })
            },

            compileResources(resObj) {
                
                let { resources } = this
                let sortedObj = resObj.sort( ( a, b ) => ( parseInt(a.id) > parseInt(b.id) ) ? 1 : -1 )
                let obj = {}

                /**
                 * Filter resources for CURRENT and PREVIOUS incomes
                 */
                obj.current = this.peopleResourceWithInclusion(sortedObj, 'occupancy-situation', 'Current')
                obj.previous = this.peopleResourceWithInclusion(sortedObj, 'occupancy-situation', 'Previous')
                
                /**
                 * If only CURRENT is empty
                 */
                if ( Object.keys(obj.previous).length > 0 &&  Object.keys(obj.current).length === 0 ) {
                    this.validComponentData = false
                    obj.current =  this.getLocalResource('get', 'Current')[0]
                }

                /**
                 * If only PREVIOUS is empty
                 */
                else if ( Object.keys(obj.current).length > 0 &&  Object.keys(obj.previous).length === 0 ) {
                    obj.previous = this.getLocalResource('get', 'Previous')[0]
                }

                return obj
            },

            getLocalResource(action = '', keyword = '') {

                let filtered

                if ( action === 'get' ) {
                    filtered = this.resources.filter( res => {
                        return res.attributes['occupancy-situation'] === keyword
                    })
                }

                if ( action === 'set' ) {}

                return filtered
            },

            close(data) {
                this.current_address_dialog = false
                this.previous_address_dialog = false
            }

        },

        watch: {

            'resourceObj.occupancies.current.attributes': {
                handler(val) {
                    if ( val?.['estimated-term-in-months'] != null && val?.['estimated-term-in-months'] != '' ) {
                        let term = this.subtractDateBy('months', this.resourceObj.occupancies.current.attributes['estimated-term-in-months'], 'MMMM YYYY')
                        this.hint['current-term'] = `Current residential term is from ${term} to present.`
                        this.totalYears = Number(val['estimated-term-in-months']) + Number(this.resourceObj.occupancies.previous.attributes['estimated-term-in-months'])
                    }
                }, 
                deep: true
            },

            'resourceObj.occupancies.previous.attributes': {
                handler(val) {
                    this.totalYears = 0
                    this.validYears = 0
                    if ( this.resourceObj.occupancies?.current?.attributes['estimated-term-in-months'] && this.resourceObj.occupancies.previous?.attributes['estimated-term-in-months']) {
                        this.totalYears = Number(this.resourceObj.occupancies?.current?.attributes['estimated-term-in-months'] ? this.resourceObj.occupancies.current?.attributes['estimated-term-in-months'] : 0) + Number(this.resourceObj.occupancies.previous?.attributes['estimated-term-in-months'] ? this.resourceObj.occupancies.previous?.attributes['estimated-term-in-months'] : 0)
                        this.validYears = this.resourceObj.occupancies.previous.attributes['estimated-term-in-months'] ? true : false
                    }
                    
                }, 
                deep: true
            },

            'totalYears': {
                handler(val) {
                    let end_term = this.subtractDateBy('months', this.resourceObj.occupancies.current.attributes['estimated-term-in-months'], 'MMMM YYYY')
                    let start_term = this.subtractDateBy('months', this.totalYears, 'MMMM YYYY')
                    this.hint['previous-term'] = `Previous residential term is from ${start_term} to ${end_term}.`
                }, deep: true
            },

            // "person": {
            //     handler(val) {
            //         if ( val.id && this.validComponentData ) {
            //             if ( val.id ) 
            //                 this.validComponentData = true
            //             else 
            //                 this.validComponentData = false
            //         }
            //         if ( this.activePerson?.id === null ) this.validComponentData = false
            //     },
            //     deep: true
            // },

            "validComponentData": {
                handler(val) {
                    if ( !this.validComponentData ) {

                        this.showComponentWarning()

                    }
                },
                deep: true
            }
            
        },

        data(){

            return {
                person: {},
                request_data: [],
                address: { current: {}, previous: {} },
                occupancy: {},
                current_address_dialog: false,
                previous_address_dialog: false,
                validComponentData: true,
                resources: [
                    {
                        "type": "occupancies",
                        "id": null,
                        "relationships": {
                            "application": {
                                "data": {
                                    "type": "applications",
                                    "id": null
                                }
                            },
                            "parent-parts": {
                                "data": [
                                    {
                                        "type": "parts",
                                        "id": null
                                    }
                                ]
                            },
                            "person": {
                                "data": {
                                    "type": "people",
                                    "id": null
                                }
                            }
                        },
                        "attributes": {
                            "residential-situation": null,
                            "estimated-term-in-months": null,
                            "start-date": null,
                            "end-date": null,
                            "occupancy-situation": "Current"
                        }
                    },
                    {
                        "type": "occupancies",
                        "id": null,
                        "relationships": {
                            "application": {
                                "data": {
                                    "type": "applications",
                                    "id": null
                                }
                            },
                            "parent-parts": {
                                "data": [
                                    {
                                        "type": "parts",
                                        "id": null
                                    }
                                ]
                            },
                            "person": {
                                "data": {
                                    "type": "people",
                                    "id": null
                                }
                            }
                        },
                        "attributes": {
                            "residential-situation": null,
                            "estimated-term-in-months": null,
                            "start-date": null,
                            "end-date": null,
                            "occupancy-situation": "Previous"
                        }
                    }
                ],
                occupancyResource: {
                    current: {},
                    previous: {}
                },
                fields: {
                    "residential-situation": "",
                    "estimated-term-in-months": "",
                    "start-date": "",
                    "end-date": "",
                    "occupancy-situation": ""
                },
                labels: {
                    "residential-situation": 'Residential Situation',
                    "current-estimated-term-in-months": 'Time at Current Residence',
                    "previous-estimated-term-in-months": 'Time at Previous Residence',
                    "current-residential-address": "Current Address",
                    "previous-residential-address": "Previous Address"
                },
                validation_rules: {
                    types: 'occupancies',
                    attributes: {
                        "residential-situation": 'required|in:Boarder,Employer Provided,Living With Parents,Mortgage - Owner Occupied,Own Outright,Renter - Agent,Renter - Private,Mortgage,Renter Agent,Renter Private',
                        "current-estimated-term-in-months": 'required|integer',
                        "previous-estimated-term-in-months": 'required|integer',
                        "current-residential-address": 'required',
                        "previous-residential-address": 'required'
                    }
                },
                situation: ['Boarder', 'Employer Provided', 'Living With Parents', 'Mortgage - Owner Occupied', 'Own Outright', 'Renter - Agent', 'Renter - Private'],
                totalYears: 0,
                validYears: false,
                resourceObj: {
                    occupancies: {}
                },
                current_address: '',
                previous_address: '',
                hint: {
                    "residential-status": '',
                    "current-term": '',
                    "previous-term": ''
                }
            }

        }

    }
</script>

<style lang="scss" scoped>
    .flex-form {
        display: grid;
        grid-column-gap: 15px;
        grid-row-gap: 0;
        grid-template-columns:  repeat(auto-fit, minmax(260px, 1fr));

        .flex-field {
            align-self: start;
        }
    }
</style>