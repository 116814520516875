<template>
    <v-progress-linear color="primary" background-color="light_gray_3" :value="getPercentage" stream bottom></v-progress-linear>
</template>

<script>

    import { mapState, mapGetters, mapActions } from 'vuex';
    import subscriptions from '~/components/shared/subscriptions'

    export default {
        mixins: [subscriptions],
        data(){
            return {}
        },
        computed: {
            ...mapGetters({
                getSpacesObj: 'flows/getSpacesObj',
                getActiveTheme: 'themes/getActiveTheme',
                getGroupSlug: 'slug/getGroupSlug'
            }),
            ...mapGetters('flows', ['getSteps']),
            ...mapState({
                activeStep: state => state.flows.activeStep,
                steps: state => state.flows.steps,
            }),
            ...mapState({
                setup: state => state.setup
            }),
             getPercentage(){

                const {steps, activeStep} = this

                let step = parseInt(activeStep)
                let len = parseInt(steps.length)

                return Math.round((step / len ) * 100 )

            }
        },
        created() {

        },
        methods: {
        }
    }
</script>

<style scoped lang="scss">


</style>
