<template>
    <div>
        <component :is="getThemeLayout" :spaces="getSpacesObj" />
        <ComplianceModal
            :show-modal="showComplianceModal"
            :processing="processing"
            @handle-next="saveCompliance" />
    </div>
</template>

<script>
    import ComplianceModal from '~/components/base/ComplianceModal'

    import { mapMutations, mapActions, mapGetters, mapState } from 'vuex';
    import layouts from '~/components/layouts'
    import meta from '~/layouts/meta'

    export default {
        mixins: [
            meta
        ],
        components: {
            ComplianceModal,
            ...layouts,
        },
        data () {
          return {
            alert: true,
            processing: false
          }
        },
        computed:{
            ...mapGetters({
                getSpacesObj: 'flows/getSpacesObj', 
                getCalculation: 'flows/getCalculation', 
                getActiveTheme: 'themes/getActiveTheme', 
            }),
            ...mapState({
                appId: state => state.flows.appId,
                showComplianceModal: state => state.flows.showComplianceModal
            }),
            getThemeLayout(){

                const {getActiveTheme} = this

                let layout = 'BaseLayout1'

                if(getActiveTheme && getActiveTheme.hasOwnProperty('layout')){

                    layout = getActiveTheme.layout.name
                }

                return layout

            },
            isCommercial(){
                let appType = this.getCalculation?.['app-loan-type'] || ''
                // console.log(appType)
                return appType.includes('Commercial')
            }
        },
        methods: {
            ...mapActions('dialogs', [
                'evaluateStepsForCompliance'
            ]),
            ...mapActions('resource-actions', [
                'updateApplication'
            ]),
            ...mapActions('statuses', [
                'evalStepStatuses'
            ]),
            ...mapMutations('flows', [
                'setComplianceModal',
                'setAppData'
            ]),
            async saveCompliance(isHybrid) {

                try {

                    this.processing = true

                    const app = {
                        "id": this.appId,
                        "type": 'applications',
                        "attributes": {
                            "hybrid-tnf": isHybrid
                        }
                    }

                    const resApp = await this.updateApplication(app)
                    this.setAppData(resApp.data.data)

                    if(resApp.status === 200) {
                        await this.evalStepStatuses()
                        await this.evaluateStepsForCompliance()
                        await this.setComplianceModal(false)
                    }

                    this.processing = false

                } catch (err) {
                    this.processing = false
                }

            },
            removeAppTheme() {
                this.$nuxt.$options.head.link = this.$nuxt.$options.head.link.filter( item => item.id !== 'appthemeCss')
            } 
        },
        beforeDestroy() {
            this.removeAppTheme();
        },

    }
</script>


<style lang="scss">

</style>
