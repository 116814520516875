import Vue from "vue";

export const store = Vue.observable({
  steps: [],

  carousel: 0,
  roleKind: null
});

export const actions = {
    setRoleKind(payload) {
        store.roleKind = payload
    },
    setCarousel(payload) {
      store.carousel = payload
    },
    setSteps(payload) {
      store.steps = payload
    },
    resetStore() {
        store.carousel = 0
        store.roleKind = null
    }
}