<template>
    <v-card tile flat class="mx-auto" max-width="500">
        <FlexWrap @resize="handleResize">
            <ValidationObserver :data-id="id" v-if="entity">
                <div v-for="(layout, index) in fieldLayout" :key="index">
                    <div class="title ma-0 my-2 body_text_color--text" v-if="layout.title">
                        {{layout.title}}
                    </div>
                    <v-row>
                        <template v-for="(field, key) in fieldLayoutMaker(layout, entity.attributes)">
                            <v-col v-if="visible[key]" :cols="responsiveComponent" class="py-0 my-0 mx-0" :key="key">

                                <ValidationProvider
                                    v-slot="{ errors }"
                                    :name="labels[key]"
                                    :rules="getCustomRules(key)"
                                    :vid="`${key}`">


                                    <ButtonGroup1
                                        v-if="isBtnGroup(key)"
                                        v-model="entity.attributes[key]"
                                        :error-messages="errors"
                                        :options="['Yes', 'No']"
                                        :label="labels[key]" />

                                    <v-select
                                        v-else-if="isFieldType('picklist', key)"
                                        :items="getOptions(key)"
                                        :label="labels[key]"
                                        :error-messages="errors"
                                        v-model="entity.attributes[key]"
                                        clearable
                                        color="secondary">
                                    </v-select>

                                    <template v-else-if="isFieldType('date', key)">
                                        <FlexDatePicker
                                            :label="labels[key]"
                                            :errors="errors"
                                            v-model="entity.attributes[key]">
                                        </FlexDatePicker>
                                    </template>

                                    <v-text-field
                                        v-else
                                        :type="'text'"
                                        :placeholder="placeholders[key]"
                                        :label="labels[key]"
                                        :error-messages="errors"
                                        v-model="entity.attributes[key]"
                                        color="secondary">
                                    </v-text-field>
                                </ValidationProvider>
                            </v-col>
                        </template>
                    </v-row>
                    <!-- <template >
                        <div class="flex-field":key="key">


                        </div>
                    </template> -->

                    <!-- Temp Spacer -->
                    <!-- <div class="flex-field" v-for="index in 6" :key="index"></div> -->

                </div>
            </ValidationObserver>
        </FlexWrap>
    </v-card>
</template>

<script>

    import { mapActions, mapGetters, mapMutations } from 'vuex'
    import formHelpers from '~/components/shared/form-helpers'
    import veeValidate from '~/components/shared/validation'

    import entityData from '~/components/v1/ApplicantEditor4/entity-data'
    import fieldOptions from '~/components/shared/field-options'
    import baseComponents from '~/components/base'

    import { getDateValidations } from '~/plugins/date-helper.js'
    const { maxAgeDate, minAgeDate, minDate } = getDateValidations()

    export default {
        mixins: [formHelpers, veeValidate],
        components: {
            ...baseComponents
        },
        props: {
            loanKindId: {
                type: String,
                required: true,
            },
            data: {
                type: Object,
                required: true,
            },
            entityPartKindId: {
                type: String,
                required: true,
            },
            id: {
                type: String,
                default: '',
            },
        },
        data(){
            return {
                sizes: null,
                entity: null,
                errorMessages: {},
                visible: {
                    "first-name": true,
                    "middle-name": true,
                    "last-name": true,
                    "date-of-birth": true,
                    "email": true,
                    "mobile-phone": true,
                    "other-phone": true,
                    "primary-country-of-citizenship": true,
                    "secondary-country-of-citizenship": true,
                    "visa": false,
                    "visa-expiry-date": false,
                    "gender": true,
                    "marital-status": true,
                    "number-of-dependants": true,
                    "age-of-dependants": false,
                    "credit-status": true,
                    "previous-bankrupt": true,
                    "bankruptcy-status": false,
                    "bankruptcy-declared-date": false,
                    "bankruptcy-discharge-date": false,

                    "business-legal-name": true,
                    "registered-business-name": true,
                    "nature-of-business": true,
                    "acn-number": true,
                    "abn-number": true,
                    "date-registered-abn": true,
                    "date-registered-gst": true,

                    "drivers-licence-number": true,
                    "drivers-licence-state": true,
                    "drivers-licence-card-number": false,
                    "drivers-licence-expiry": true,

                    "medicare-number": true,
                    "passport-number": true,
                    "passport-expiry": false,
                },
                placeholders: {
                    "age-of-dependants": "e.g. 1,2,3"
                },
                labels: {
                    "first-name": "First Name",
                    "middle-name": "Middle Name",
                    "last-name": "Last Name",
                    "date-of-birth": "Date of Birth",
                    "email": "Email",
                    "mobile-phone": "Mobile Number",
                    "other-phone": "Other Phone",
                    "primary-country-of-citizenship": "Primary Country of Citizenship",
                    "secondary-country-of-citizenship": "Secondary Country of Citizenship",
                    "visa": "Visa",
                    "visa-expiry-date": "Visa Expiry Date",
                    "gender": "Gender",
                    "marital-status": "Marital Status",
                    "number-of-dependants": "Dependants",
                    "age-of-dependants": "Age of Dependants",
                    "credit-status": "Credit Status",
                    "previous-bankrupt": "Previous Bankrupt",
                    "bankruptcy-status": "Bankruptcy Status",
                    "bankruptcy-declared-date": "Bankruptcy Declared Date",
                    "bankruptcy-discharge-date": "Bankruptcy Discharge Date",

                    "business-legal-name": "Business Legal Name",
                    "registered-business-name": "Registered Business Name",
                    "nature-of-business": "Nature of Business",
                    "acn-number": "ACN",
                    "abn-number": "ABN",
                    "date-registered-abn": "Date Registered ABN",
                    "date-registered-gst": "Date Registered GST",

                    "drivers-licence-number": "Drivers Licence Number",
                    "drivers-licence-state": "Drivers Licence State",
                    "drivers-licence-card-number": "Drivers Licence Card Number",
                    "drivers-licence-expiry": "Drivers Licence Expiry",

                    "medicare-number": "Medicare Number",
                    "passport-number": "Passport Number",
                    "passport-expiry": "Passport Expiry",

                },
                validation_rules: {
                    attributes: {
                        "first-name": "required|string",
                        "middle-name": "string",
                        "last-name": "required|string",
                        "date-of-birth": `required|string|date_format:Y/m/d|before:${minAgeDate}|after:${maxAgeDate}`,
                        "email": "required|email",
                        "mobile-phone": "required",
                        "primary-country-of-citizenship": `required|in:${fieldOptions["country-of-citizenship"]}`,
                        "secondary-country-of-citizenship": `string|in:${fieldOptions["country-of-citizenship"]}`,
                        "visa": `required|in:${fieldOptions["visa"]}`,
                        "visa-expiry-date": "string|date_format:Y/m/d",
                        "gender": `required|in:${fieldOptions["gender"]}`,
                        "marital-status": `required|in:${fieldOptions["marital-status"]}`,
                        "number-of-dependants": 'required|integer',
                        "age-of-dependants": "required|string|max:225",
                        "previous-bankrupt": "required|string|in:Yes,No",
                        "credit-status": `required|in:${fieldOptions["credit-status"]}`,
                        "bankruptcy-status": `required_if:previous-bankrupt,Yes|in:${fieldOptions["bankruptcy-status"]}`,
                        "bankruptcy-declared-date": `required_if:previous-bankrupt,Yes|date_format:Y/m/d|after:${minDate}|before:now`,
                        "bankruptcy-discharge-date": `required_if:bankruptcy-status,Discharged Part 9,Discharged Bankrupt|date_format:Y/m/d|after:${minDate}|before:now`,

                        "business-legal-name": "required|string",
                        "registered-business-name": "required|string",
                        "nature-of-business": "required|string",
                        "acn-number": "required|string",
                        "abn-number": "required|digits_between:11,11",
                        "date-registered-abn": "required|date_format:Y/m/d|before:now",
                        "date-registered-gst": "required|date_format:Y/m/d|before:now",

                        "drivers-licence-number": "required|string",
                        "drivers-licence-state": `required|in:${fieldOptions["drivers-licence-state"]}`,
                        "drivers-licence-card-number": "string",
                        "drivers-licence-expiry": "required|date_format:Y/m/d|after:now",

                        "medicare-number": "required|string",
                        "passport-number": "required|string",
                        "passport-expiry": "required|date_format:Y/m/d|after:now",

                        "other-phone": "required|string",

                    }
                },

            }
        },
        computed: {
            ...mapGetters('kinds', ['kinds', 'kindsUnformat']),
            fieldLayout(){

                const {entityPartKindId, loanKindId} = this

                let loanKindName = this.getKindNameById(loanKindId)
                let entityKindName = null
                let key = null

                if(entityPartKindId){
                    entityKindName = this.getKindNameById(entityPartKindId)
                }

                Object.keys(entityData).forEach( type => {

                    if( entityData[type]['loan-kinds'].includes(loanKindName) && entityData[type]['entity-kinds'].includes(entityKindName) ) {
                        key = type
                    }

                })

                // console.log(loanKindName)
                // console.log(entityKindName)

                if(key){
                    return entityData[key].layout
                }

                return []
            },
            countryOfCitizenship(){
                return this.entity?.attributes["primary-country-of-citizenship"] +'|'+ this.entity?.attributes["secondary-country-of-citizenship"]
            },
            previousBankrupt(){
                return this.entity?.attributes["previous-bankrupt"]
            },
            bankruptcyStatus(){
                return this.entity?.attributes["bankruptcy-status"]
            },
            dependants(){
                return this.entity?.attributes["number-of-dependants"]
            },
            driversLicenceState(){
                return this.entity?.attributes["drivers-licence-state"]
            },
            passportNumber(){
                return this.entity?.attributes["passport-number"]
            },
            responsiveComponent(){

                if(!this.sizes) return 12

                const {sm, xs, md, lgAndUp, width} = this.sizes

                if(lgAndUp){
                    return 3
                }

                if(md){
                    return 3
                }

                if(sm){
                    return 6
                }

                if(xs){
                    return 12
                }

            },
        },
        mounted() {
            this.init()
        },
        methods: {
            ...mapActions('resource-actions', [
                'getPeople',
                'updatePerson',
                'createPerson'
            ]),
            init(){

                // localize data prop to trigger watchers
                this.entity = this.data

            },
            getKindNameById(id){

                let kind = this.kindsUnformat.find( kind => kind.id == id)

                if(kind){
                    return kind.attributes.name
                }

                return null

            },
            getCustomRules(name){

                const defaultRules = this.getRules(name);

                if(name == 'age-of-dependants') {
                    return 'age_dependants:number-of-dependants|' + defaultRules
                }

                if(name == 'mobile-phone') {
                    return 'is_mobile_number|has:04,+61|' + defaultRules
                }

                return defaultRules
            },
            isBtnGroup(key){

                let keys = [
                    "previous-bankrupt"
                ]

                return keys.includes(key)

            },
            handleResize(sizes){
                this.sizes = sizes
            },
        },
        watch: {
            countryOfCitizenship(val){

                let countries = val.split('|')

                let cond = countries.includes('Australia') || countries.includes('New Zealand')

                if(cond){

                    this.visible["visa"] = false
                    this.visible["visa-expiry-date"] = false

                } else {

                    if(countries[0] == 'null' && countries[1] == 'null'){

                        this.visible["visa"] = false
                        this.visible["visa-expiry-date"] = false

                    } else {

                        this.visible["visa"] = true
                        this.visible["visa-expiry-date"] = true

                    }

                }

            },
            previousBankrupt(val){

                if(val == 'No' || val == null){

                    this.entity.attributes["bankruptcy-status"] = ""
                    this.entity.attributes["bankruptcy-declared-date"] = ""

                    this.visible["bankruptcy-status"] = false
                    this.visible["bankruptcy-declared-date"] = false
                }

                if(val == 'Yes'){

                    this.visible["bankruptcy-status"] = true
                    this.visible["bankruptcy-declared-date"] = true
                }

            },
            bankruptcyStatus(val){

                if(val && val.includes('Discharged')){

                    this.visible["bankruptcy-discharge-date"] = true

                } else {

                    this.entity.attributes["bankruptcy-discharge-date"] = ""
                    this.visible["bankruptcy-discharge-date"] = false
                }

            },
            dependants(val){

                if(parseInt(val) > 0){

                    this.visible["age-of-dependants"] = true

                } else {

                    this.entity.attributes["age-of-dependants"] = ""
                    this.visible["age-of-dependants"] = false
                }

            },

            driversLicenceState(val){

                if(val) {

                    // no drivers licence card number on this state
                    let excludes = [ 'Outside Australia' ]

                    if(excludes.includes(val)) {
                       this.entity.attributes["drivers-licence-card-number"] = ""
                        this.visible["drivers-licence-card-number"] = false
                        this.validation_rules.attributes["drivers-licence-card-number"] = `string`
                    } else {
                        this.visible["drivers-licence-card-number"] = true
                        this.validation_rules.attributes["drivers-licence-card-number"] = `required|string`
                    }

                }

            },

             passportNumber(val){

                if(val){

                    this.visible["passport-expiry"] = true

                } else {

                    this.entity.attributes["passport-expiry"] = ""
                    this.visible["passport-expiry"] = false
                }

            },

        }
    }
</script>

<style scoped lang="scss">

</style>
