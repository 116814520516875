<template>
    <v-card class="mx-auto" tile flat>

        <v-card-text class="pa-4 pt-0 pb-6 pa-md-10 pt-md-0">
            <div class="body-1" :class="vb.mdAndUp ? 'body-1' : 'caption'">
                Now Finance requires some additional information about the applicants spouse. Please complete the Now Finance requirements using the information from the Nodifi Data
            </div>
        </v-card-text>

        <v-card-text class="pa-4 pt-0 pa-md-10 pt-md-0">

            <div v-if="vb.mdAndUp" class="d-flex" style="width: 100%">
                <v-sheet color="light_gray_1" class="py-8 px-12" width="50%" outlined elevation="0">
                    <v-img 
                        max-width="120"
                        class="flex-brand" 
                        :src="require(`~/assets/images/nodifi.svg`)" 
                        position="center left" 
                        contain>
                    </v-img>
                    <div class="mt-2 mb-6 font-size-20">Data</div>

                    <v-list color="transparent">
                        <v-list-item two-line class="px-0">
                            <v-list-item-content>
                                <v-list-item-title class="font-weight-medium">
                                    Current Income Type
                                </v-list-item-title>
                                <v-list-item-subtitle>{{getNBSCurrentIncomeType}}</v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>

                        <v-list-item two-line class="px-0">
                            <v-list-item-content>
                                <v-list-item-title class="font-weight-medium">
                                    Accommodation Expense Entered
                                </v-list-item-title>
                                <v-list-item-subtitle>$ {{getNBSAccomodationExpense | currencyNoSymbol }}</v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>

                        <v-list-item two-line class="px-0">
                            <v-list-item-content>
                                <v-list-item-title class="font-weight-medium">
                                    Household Living Expenses Entered
                                </v-list-item-title>
                                <v-list-item-subtitle>$ {{getNBSHouseholdExpense | currencyNoSymbol  }}</v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>

                    </v-list>

                </v-sheet>
                <v-sheet class="py-8 px-12" width="50%">

                    <v-img 
                        max-width="120"
                        class="flex-brand" 
                        :src="require(`~/assets/images/logo/cropped/lender-logo-now-finance.png`)" 
                        position="center left" 
                        contain>
                    </v-img>
                    <div class="mt-2 mb-8 font-size-20">Requirements</div>

                    <ValidationObserver ref="observer" tag="form">

                        <template v-for="(field, key) in resources.incomes.attributes" >
                        <ValidationProvider 
                                :key="key"
                                v-slot="{ errors }" 
                                :name="labels[key]"
                                :rules="getRules(key)" 
                                :vid="key">
                                    <template>
                                        <ButtonGroup1 
                                            v-model="resources.incomes.attributes[key]"
                                            :error-messages="errors"
                                            :options="['Yes', 'No']"
                                            :label="labels[key]" />
                                        <div class="pb-6"></div>
                                    </template>
                        </ValidationProvider>
                        </template>

                        <template v-for="(field, key) in resources.expenses.attributes" >
                        <ValidationProvider 
                                :key="key"
                                v-slot="{ errors }" 
                                :name="labels[key]"
                                :rules="getRules(key)" 
                                :vid="key">
                                    <div class="flex-range-slider">
                                        <v-slider
                                            v-model="resources.expenses.attributes[key]"
                                            :tick-labels="['0%','','','','','','','','','','50%','','','','','','','','','','100%']"
                                            thumb-size="50"
                                            :error-messages="errors"
                                            :label="labels[key]"
                                            :min="0"
                                            :max="100"
                                            :step="5"
                                            track-color="secondary"
                                            track-fill-color="primary"
                                            ticks="always"
                                            :thumb-size="10"
                                            thumb-label="always"
                                            color="secondary"
                                            class="pa-0 px-2 ma-0 mb-4">
                                        
                                        </v-slider>
                                    </div>

                        </ValidationProvider>
                        </template>
                    </ValidationObserver>


                </v-sheet>
            </div>

            <!-- mobile version -->
            <div v-else>

                <v-card flat class="mx-4 rounded-xl" :style="`background-color: ${hexToRGBA(getActiveTheme.colors.secondary, 0.2)}`">

                    <v-card-text class="d-flex py-1">
                        <v-row>
                            <v-col cols="6" class="pa-1">
                                <v-card
                                min-height="45"
                                    flat class="rounded-xl px-1"
                                    :color="tabSelection == 'nodifi' ? 'white' : 'transparent'">
                                    <div class="d-flex justify-center pt-1" @click="selectTab('nodifi')">
                                        <v-img 
                                            max-width="55"
                                            class="flex-brand" 
                                            :src="require(`~/assets/images/nodifi.svg`)" 
                                            position="center left" 
                                            contain>
                                        </v-img>
                                        <div class="mt-3 font-size-12 pl-1">Data</div>
                                    </div>
                                </v-card>
                            </v-col>
                            <v-col cols="6" class="pa-1">
                                <v-card
                                    flat class="rounded-xl px-1"
                                    :color="tabSelection == 'now' ? 'white' : 'transparent'">
                                    <div class="d-flex flex-column" @click="selectTab('now')">
                                        <v-img 
                                            max-width="45"
                                            class="flex-brand mx-auto mt-n1" 
                                            :src="require(`~/assets/images/logo/cropped/lender-logo-now-finance.png`)" 
                                            position="center left" 
                                            contain>
                                        </v-img>
                                        <div class="font-size-12 pl-1 mx-auto mt-n3">Requirements</div>
                                    </div>
                                </v-card>
                            </v-col>
                        </v-row>
                    </v-card-text>
                    
                </v-card>

                <v-list class="mx-4" v-show="tabSelection == 'nodifi'" color="transparent">
                    <v-list-item two-line class="px-0">
                        <v-list-item-content>
                            <v-list-item-title class="font-weight-medium">
                                Current Income Type
                            </v-list-item-title>
                            <v-list-item-subtitle>{{getNBSCurrentIncomeType}}</v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>

                    <v-list-item two-line class="px-0">
                        <v-list-item-content>
                            <v-list-item-title class="font-weight-medium">
                                Accommodation Expense Entered
                            </v-list-item-title>
                            <v-list-item-subtitle>$ {{getNBSAccomodationExpense | currencyNoSymbol }}</v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>

                    <v-list-item two-line class="px-0">
                        <v-list-item-content>
                            <v-list-item-title class="font-weight-medium">
                                Household Living Expenses Entered
                            </v-list-item-title>
                            <v-list-item-subtitle>$ {{getNBSHouseholdExpense | currencyNoSymbol  }}</v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>

                </v-list>

                <div v-show="tabSelection == 'now'" class="mx-4">
                    <ValidationObserver ref="observer" tag="form">

                        <template v-for="(field, key) in resources.incomes.attributes" >
                        <ValidationProvider 
                                :key="key"
                                v-slot="{ errors }" 
                                :name="labels[key]"
                                :rules="getRules(key)" 
                                :vid="key">
                                    <template>
                                        <ButtonGroup1 
                                            v-model="resources.incomes.attributes[key]"
                                            :error-messages="errors"
                                            :options="['Yes', 'No']"
                                            :label="labels[key]" />
                                        <div class="pb-6"></div>
                                    </template>
                        </ValidationProvider>
                        </template>

                        <template v-for="(field, key) in resources.expenses.attributes" >
                        <ValidationProvider 
                                :key="key"
                                v-slot="{ errors }" 
                                :name="labels[key]"
                                :rules="getRules(key)" 
                                :vid="key">
                                    <div class="flex-range-slider">
                                        <v-slider
                                            v-model="resources.expenses.attributes[key]"
                                            :tick-labels="['0%','','','','','','','','','','50%','','','','','','','','','','100%']"
                                            thumb-size="50"
                                            :error-messages="errors"
                                            :label="labels[key]"
                                            :min="0"
                                            :max="100"
                                            :step="5"
                                            track-color="secondary"
                                            track-fill-color="primary"
                                            ticks="always"
                                            :thumb-size="10"
                                            thumb-label="always"
                                            color="secondary"
                                            class="pa-0 px-2 ma-0 mb-4">
                                        
                                        </v-slider>
                                    </div>

                        </ValidationProvider>
                        </template>
                    </ValidationObserver>
                </div>

            </div>

        </v-card-text>

        <!-- <HelperModal /> -->
     
    </v-card>
</template>

<script>

    import { mapActions, mapState, mapGetters, mapMutations } from 'vuex'
    import helpers from '~/plugins/helpers'
    import ButtonGroup1 from '~/components/base/ButtonGroup1'
    import subscriptions from '~/components/shared/subscriptions'
    import HelperModal from '~/components/base/LenderHelperDialog'

    export default {
        components: { ButtonGroup1, HelperModal },
        mixins: [subscriptions, helpers],

        data() {
            return {
                tabSelection: 'nodifi',

                resources: {
                    incomes: {
                        type: "incomes",
                        id: null,
                        attributes: {
                            "lender-now-finance-partner-employed" : null
                        }
                    },
                    expenses: {
                        type: "expenses",
                        id: null,
                        attributes: {
                            "lender-now-finance-accommodations-payed-by-partner" : null,
                            "lender-now-finance-living-expenses-payed-by-partner" : null
                        }
                    },

                },

                people: [],
                incomes: [],
                expenses: [],
                nbs: null,

                errors: [],

                labels: {
                    "lender-now-finance-partner-employed" : 'Is the applicant’s spouse gainfully employed?',
                    "lender-now-finance-accommodations-payed-by-partner" : 'Spouse contribution to the Accommodation Expense entered',
                    "lender-now-finance-living-expenses-payed-by-partner" : 'Spouse contribution to the Household Living  Expense entered'
                },
                validation_rules: {
                    type: "loans",
                    attributes: {
                            "lender-now-finance-partner-employed" : 'required|in:Yes,No',
                            "lender-now-finance-living-expenses-payed-by-partner" : '',
                            "lender-now-finance-accommodations-payed-by-partner" : ''
                    }
                },
                hiddens: {
                },
                prefix: {
                },
                options: {
                }
            }
        },
        computed: {
            ...mapGetters('kinds', ['kindsUnformat']),
            ...mapGetters('themes', [
                'getActiveTheme'
            ]),
            ...mapState({
                user: state => state.auth.user,
                appId: state => state.flows.appId,
                loanData: state => state.flows.loanData,
                calculation: state => state.flows.calculation,
                lenderSubmissionTo: state => state.flows.lenderSubmissionTo,
            }),
            vb() {
                return this.$vuetify.breakpoint
            },
            storeLoanData(){
                 return this.loanData
            },
            getLoanType(){
                 return this.loanData?.attributes['loan-type']
            },
            getLoanId(){
                return this.appData.relationships.loan.data.id
            },
            hasBalloonPayment(){

                let balloon = this.loanData.attributes['requested-balloon']

                if(balloon && balloon > 0) {
                    return true
                }

                return true
            },
            isCommitmentExists(){

                let exists = false
                
                /**
                 *  NOTE: 
                 *  currently, an application can have multiple commitments
                 *  for now, we're getting the first commitment to check if 
                 *  the terms have been accepted.
                 */

                if(this.commitments && this.terms.length > 0){

                    // let commit = this.commitments[0]

                    return true

                }

                return exists
            },
            getNBSExpense(){

                const {expenses, nbs} = this

                let nbsExpenses = null

                if(expenses.length > 0 && nbs) {

                    let expensesList = []

                    expenses.forEach(expense => {

                        let expensePeopleData = expense.relationships.people.data

                        if(expensePeopleData.length > 0) {

                            expensePeopleData.forEach( expensePeopleItem => {
                                
                                if(expensePeopleItem.id == nbs.id) {
                                    expensesList.push(expense)
                                }

                            })

                        }
                    })

                    // Get the first NBS expenses
                    if(expensesList.length > 0) {
                        nbsExpenses = expensesList[0]
                    }

                }

                return nbsExpenses
            },
            getPrimaryApplicantCurrentIncome(){

                const {incomes, calculation} = this

                let currentIncome = null

                if(incomes.length > 0) {

                    let incomesList = []

                    incomes.forEach(income => {

                        let incomePeopleData = income.relationships.people.data

                        if(incomePeopleData.length > 0) {

                            incomePeopleData.forEach( incomePeopleItem => {
                                
                                if(incomePeopleItem.id == calculation?.['primary-applicant-id']) {
                                    incomesList.push(income)
                                }

                            })

                        }
                    })

                    if(incomesList.length > 0) {

                        incomesList.forEach(income => {
                            if(income.attributes['income-situation'] == 'Current') {
                                currentIncome = income
                            }
                        })

                    }

                }

                return currentIncome

            },
            getNBSCurrentIncome(){

                const {incomes, nbs} = this

                let nbsCurrentIncome = null

                if(incomes.length > 0 && nbs) {

                    let incomesList = []

                    incomes.forEach(income => {

                        let incomePeopleData = income.relationships.people.data

                        if(incomePeopleData.length > 0) {

                            incomePeopleData.forEach( incomePeopleItem => {
                                
                                if(incomePeopleItem.id == nbs.id) {
                                    incomesList.push(income)
                                }

                            })

                        }
                    })

                    if(incomesList.length > 0) {

                        incomesList.forEach(income => {
                            if(income.attributes['income-situation'] == 'Current') {
                                nbsCurrentIncome = income
                            }
                        })

                    }

                }

                return nbsCurrentIncome
            },
            getNBSCurrentIncomeType(){

                const {incomes, nbs} = this

                let incomeType = ''

                if(incomes.length > 0 && nbs) {

                    let incomesList = []

                    incomes.forEach(income => {

                        let incomePeopleData = income.relationships.people.data

                        if(incomePeopleData.length > 0) {

                            incomePeopleData.forEach( incomePeopleItem => {
                                
                                if(incomePeopleItem.id == nbs.id) {
                                    incomesList.push(income)
                                }

                            })

                        }
                    })


                    if(incomesList.length > 0) {

                        let currentIncome = null

                        incomesList.forEach(income => {
                            if(income.attributes['income-situation'] == 'Current') {
                                currentIncome = income
                            }
                        })

                        if(currentIncome) {

                            let currentIncomeKindId = currentIncome.relationships?.kind?.data?.id


                            this.kindsUnformat.forEach(kind => {

                                if( currentIncomeKindId == kind.id){
                                    incomeType = kind.attributes.name
                                }

                            })

                        }

                    }

                }

                return incomeType
            },
            getNBSAccomodationExpense(){
                let result = ''
                let accomodationExpensesKindIds = ['11-3', '11-4', '11-5', '11-6', '11-7', '11-8', '11-9']

                const {expenses, nbs} = this

                if(expenses.length > 0 && nbs) {

                    let nbsExpensesList = []

                    expenses.forEach(expense => {

                        let expensePeopleData = expense.relationships.people.data

                        if(expensePeopleData.length > 0) {

                            expensePeopleData.forEach( expensePeopleItem => {
                                
                                if(expensePeopleItem.id == nbs.id) {
                                    nbsExpensesList.push(expense)
                                }

                            })

                        }
                    })

                    if(nbsExpensesList.length > 0) {

                        let accomodationExpenses = []

                        nbsExpensesList.forEach(expense => {

                            let expenseKindData = expense.relationships.kind.data

                            if( expenseKindData && accomodationExpensesKindIds.includes(expenseKindData.id)) {
                                accomodationExpenses.push(expense)
                            }

                        })

                        if(accomodationExpenses.length > 0) {

                            let total = 0

                            accomodationExpenses.forEach( expense => {
                                total = (total + parseFloat(expense.attributes["payment-amount"]))
                            });

                            result = total
                        }

                    }

                }

                return result 
            },
            getNBSHouseholdExpense(){

                let result = ''
                let livingExpensesKindId = '11-16'

                const {expenses, nbs} = this

                if(expenses.length > 0 && nbs) {

                    let nbsExpensesList = []

                    expenses.forEach(expense => {

                        let expensePeopleData = expense.relationships.people.data

                        if(expensePeopleData.length > 0) {

                            expensePeopleData.forEach( expensePeopleItem => {
                                
                                if(expensePeopleItem.id == nbs.id) {
                                    nbsExpensesList.push(expense)
                                }

                            })

                        }
                    })


                    if(nbsExpensesList.length > 0) {

                        let livingExpense = null

                        nbsExpensesList.forEach(expense => {

                            let expenseKindData = expense.relationships.kind.data

                            if( expenseKindData && expenseKindData.id == livingExpensesKindId) {
                                livingExpense = expense
                            }

                        })

                        if(livingExpense) {

                            let fields = ["living-child-care-fees",
                                        "living-education",
                                        "living-family-maintenance",
                                        "living-food-and-clothing",
                                        "living-insurance",
                                        "living-lifestyle",
                                        "living-other-living-expenses",
                                        "living-transport",
                                        "living-tv-and-communications",
                                        "living-utilities-and-rates"]

                            let total = 0;

                            fields.forEach(field => {
                                total = (total + parseFloat(livingExpense.attributes[field]))
                            })

                            result = total
                            // result = livingExpense.attributes["payment-amount"]
                        }

                    }

                }

                return result 
            },
        },
        methods: {
            ...mapActions('resource-actions', [
                'updateIncome',
                'updateExpense',
                'getLoan',
                'updateLoan',
                'getPeople',
                'updatePeople',
            ]),
            ...mapMutations('dialogs', [
                'toggleLenderSubmissionHelperDialog'
            ]),
            async pull() {

                let promise1 = this.getPeople({"include" : 'parent-parts,incomes,expenses'})

                const res = await Promise.all([
                        promise1, 
                    ]) 

                promise1 = res[0]

                // console.log(promise1)

                this.nbs = this.getNBS(promise1.data)

                // console.log(promise1)
                // console.log('getNBS', this.nbs)

                if(promise1.data && promise1.data.hasOwnProperty('included')) {

                    let incomes = []
                    let expenses = []

                    promise1.data.included.forEach(item => {

                        if(item.type == 'incomes') {
                            incomes.push(item)
                        }

                        if(item.type == 'expenses') {
                            expenses.push(item)
                        }

                    })

                    this.incomes = incomes
                    this.expenses = expenses

                    const {getPrimaryApplicantCurrentIncome, getNBSExpense} = this

                    if(getPrimaryApplicantCurrentIncome) {
                        this.resources.incomes.id = getPrimaryApplicantCurrentIncome.id
                        this.resources.incomes.attributes["lender-now-finance-partner-employed"] = getPrimaryApplicantCurrentIncome.attributes["lender-now-finance-partner-employed"]
                    }

                    if(getNBSExpense) {
                        this.resources.expenses.id = getNBSExpense.id
                        this.resources.expenses.attributes["lender-now-finance-accommodations-payed-by-partner"] = getNBSExpense.attributes["lender-now-finance-accommodations-payed-by-partner"]
                        this.resources.expenses.attributes["lender-now-finance-living-expenses-payed-by-partner"] = getNBSExpense.attributes["lender-now-finance-living-expenses-payed-by-partner"]
                    }
                       
                }

          
            },
            async push() {

                const {incomes, expenses} = this.resources
                    
                let incomeRes = null;
                let expenseRes = null;

                if (this.getLoanId) {
                    incomeRes = this.updateIncome(incomes)
                    expenseRes = this.updateExpense(expenses)
                }

                await Promise.all([incomeRes, expenseRes])

                const {valid, status} = await this.validateFormData()

                return {valid, status, data: {}}

            },
            showHelperModal() {
                this.toggleLenderSubmissionHelperDialog(true)
            },
            selectTab(tab) {
                this.tabSelection = tab
            },
            hexToRGBA(hex, a = 1){
                return this.$options.filters.hexToRGBA(hex, a)
            },
            getNBS(data){

                let NBSid = '3-6'

                const {calculation} = this

                let appType = calculation['application-type']

                const enumsNBS = "Consumer - Individual - Non Borrowing Spouse"
                
                /**
                 * 1. check if data has included
                 * 2. filter included for 'parts'
                 * 3. select parts with kinds 'applicant'
                 * 4. select person based on selected part
                 */

                if(!data.included){
                    return null;
                }

                let parts = data.included.filter(part => {
                    return part.type == 'parts'
                })

                let targetParts = [];	
                let targetKind = null 

                parts.forEach((part) => {
                    
                    if (!part.relationships.kind.data) {	
                        // person must have part with kind
                        return null
                    }
                    
                    // If app type is NBS, get the nbs part by NBSid
                    if (appType == enumsNBS && part.relationships.kind.data.id == NBSid) {

                        targetParts.push(part);

                    } else {

                        // Otherwise get the part with priority = 2 e.g.  Consumer - Joint
                        if(part.attributes.priority == 2) {
                            targetParts.push(part);
                        }

                    }


                })

                if (targetParts.length == 0) {

                    // no result
                    return null
                    
                } else {

                    targetKind = targetParts[0];
                }

                let individual = data.data.find( person => {
                    if(person.relationships['parent-parts'].data[0]){
                        return person.relationships['parent-parts'].data[0].id == targetKind.id
                    }
                })

                // Success!
                return individual 
            
            },
            isBtnGroup(key){

                let keys = [
                    "field0001"
                ]
                return keys.includes(key)
            },
        },
        watch: {
      
        }
    }
</script>

<style lang="scss" scoped>

</style>