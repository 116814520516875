<template>
    <v-dialog
        v-model="dialog"
        width="800"
        scrollable
        persistent>   
        <v-card flat class="ol-residential-history-5-is-currently-living" :disabled="isSaving">
            <v-progress-linear color="primary" indeterminate stream absolute top v-if="isSaving"></v-progress-linear>
            <v-card-title class="pa-0 ma-0 pr-5 pt-3 mb-n5">
                <v-spacer></v-spacer>
                <v-btn icon rounded small @click="close" depressed>
                    <v-icon color="primary" size="20">mdi-close</v-icon>
                </v-btn>
            </v-card-title>
            <v-card-title class="px-8 py-4 text-center">
                <div class="sm pa-0 text-center" style="width:100%">
                   {{title}}
                </div>
            </v-card-title>
            <v-card-text  :class="vb.mdAndUp ? 'px-16' : 'px-4'">
                <ValidationObserver ref="observer1" tag="form">
               <v-row  >
                    <v-col class="py-0" cols="12" :lg="6" v-for="(field,key) in fields" :key="key">
                        <ValidationProvider v-slot="{ errors }" :rules="getRules(field.model)" :name="field.label">
                            <v-text-field inputmode="decimal" :error-messages="errors" :label="field.label" :prefix="field.prefix" v-model="form[field.model]" v-if="field.type == 'text'"></v-text-field>
                            <v-select :error-messages="errors" :label="field.label" v-model="form[field.model]" v-if="field.type == 'select'" :items ="field.item"> </v-select>
                        </ValidationProvider>
                    </v-col>                     
               </v-row>
               </ValidationObserver> 
            </v-card-text>
            <v-card-text class="d-flex justify-center pb-8" v-if="actionType == 'edit'">
                <v-btn class="px-10 mx-4 secondary" :loading="isSaving" width="140" height="50" depressed large @click="update ">Update</v-btn>
            </v-card-text>
            <v-card-text class="d-flex justify-center pb-8" v-else>
                <v-btn class="px-10 " width="140" height="50" depressed large @click="back">Back</v-btn>
                <v-btn class="px-10 mx-4 secondary" width="140" height="50" depressed large @click="nextStep" :loading="isSaving">Next</v-btn>
            </v-card-text>
        </v-card>

    </v-dialog>
</template>
<script>
import {store,mutations} from './__store'
import fieldOptions from '~/components/shared/field-options'
import formHelpers from '~/components/shared/form-helpers.js'
import validation from '~/components/shared/validation'
import shared from './__shared'
import { mapState, mapActions, mapGetters } from 'vuex'
export default {
    mixins: [formHelpers,validation,shared],

    props : {
        title: {
            type: String,
            default: 'Title'
        },
        step: {
            type: String,
            default: ''
        },
        address: {
            type:Object,
            default: () => {}
        },
        actionType: {
            type:String,
            default: ''
        }
    },
    watch: {
        "step": {
            handler (val) {
                val == 'finance-details' ? this.dialog = true : this.dialog = false
            }
        },
        // "address": {
        //     handler (val) {
        //         if (val.hasOwnProperty('occupancy')){
        //             this.form['residential-situation'] = val.occupancy.attributes['residential-situation']
        //         } else {
        //             this.form['residential-situation'] = ""
        //         }
        //     }
        // },
        'actionType': {
            handler (val) {
                return val
            }
        }
    },
    computed: {
        ...mapState('flows',['calculation'])
    },
    methods: {
        ...mapActions('resource-actions',['createAddress','createOccupancy','createAsset','createExpense','createLiability']),
        async nextStep(data) {
           let valid = await this.$refs.observer1.validate();
                
           if(valid){
                this.isSaving = true
                let expenseAttr = {'payment-amount': this.form['payment-amount'], 'payment-frequency': this.form['payment-frequency']}
                let liabilitAttr = {
                    'amount-financed': this.form['amount-financed'],
                    'amount-owing': this.form['amount-owing'],
                    'financial-institution': this.form['financial-institution']
                } 
                
                mutations.setExpenseAttributes(expenseAttr)
                mutations.setLiabilityAttributes(liabilitAttr)
                if(store.isLivedTogether == 'Yes'){
                    if(store.selectedOwner.id.length > 1){
                        let payload = {
                            'occupancy-situation': this.getOccupancySituation(),
                            'residential-situation': 'Mortgage - Owner Occupied'
                        }
                        mutations.setOccupancies(payload)
                        if(this.occupancyResource1.hasOwnProperty('id')) {
                            delete  this.occupancyResource1.id 
                            delete  this.occupancyResource2.id 
                            delete store.addressResource.id
                        }
                        this.occupancyResource1.attributes['end-date'] = this.getEndDate()
                        this.occupancyResource2.attributes['end-date'] = this.getEndDate()
                        let occupancy1 = await this.createOccupancy(this.occupancyResource1)
                        let occupancy2 = await this.createOccupancy(this.occupancyResource2)
                        let addressResource = store.addressResource
                        addressResource.relationships.occupancies = {data: [{id:occupancy1.data.data.id, type:'occupancies'},{id:occupancy2.data.data.id, type:'occupancies'}]}
                        delete addressResource.id

                        let address = await this.createAddress(addressResource)
                        let assetAttr = this.getAssetResource({data: { id: address.data.data.id,type: 'addresses'} }, {data: [{id: store.selectedOwner.id[0],type: 'people'},{id: store.selectedOwner.id[1],type: 'people'}]} )
                        let asset = await this.createAsset(assetAttr)
                        expenseAttr = this.getExpensesResource( {data: [{id: store.selectedOwner.id[0],type: 'people'},{id: store.selectedOwner.id[1],type: 'people'}]}, {data: [{id: asset.data.data.id,type:'assets'}]})
                        let expense = await this.createExpense(expenseAttr)
                        let liabilityRelationships = {
                            'people': {data: [{id: store.selectedOwner.id[0],type: 'people'},{id: store.selectedOwner.id[1],type: 'people'}]},
                            'kinds':  {data: { type : "kinds", "id": "13-21" }},
                            'assets': {data: [{ type: 'assets', id: asset.data.data.id }]},
                            'expenses': {data: [{type: 'expenses', id: expense.data.data.id}]}
                        }
                        mutations.setLiabilityRelationships(liabilityRelationships)
                        await this.createLiability(this.getliabilityResource())
                        let addressWithOccupancy = {address: address.data.data, occupancy: occupancy2.data.data}
                        this.$emit('inputAddress',addressWithOccupancy)
                        this.$emit('step',null)
                    } else {
                        let occresource1 = null
                        let occresource2 = null
                        let occupancy1= null
                        let occupancy2= null

                        if(this.occupancyResource1.hasOwnProperty('id')) {
                            delete  this.occupancyResource1.id 
                            delete  this.occupancyResource2.id 
                            delete store.addressResource.id
                        }

                        this.occupancyResource1.attributes['end-date'] = this.getEndDate()
                        this.occupancyResource2.attributes['end-date'] = this.getEndDate()

                        if(store.selectedOwner.id[0] == this.calculation['primary-applicant-id']){
                            occresource1 = this.occupancyResource1
                            occresource1.attributes['occupancy-situation'] = this.getOccupancySituation()
                            occresource1.attributes['residential-situation'] = "Mortgage - Owner Occupied"
                            occupancy1 = await this.createOccupancy(occresource1)
                            occresource2 = this.occupancyResource2
                            occresource2.attributes['occupancy-situation'] = this.getOccupancySituation()
                            occresource2.attributes['residential-situation'] = "Boarder"
                            occupancy2 = await this.createOccupancy(occresource2)
                            
                        } else {
                            occresource1 = this.occupancyResource1
                            occresource1.attributes['occupancy-situation'] = this.getOccupancySituation()
                            occresource1.attributes['residential-situation'] = "Boarder"
                            occupancy1 = await this.createOccupancy(occresource1)
                            occresource2 = this.occupancyResource2
                            occresource2.attributes['occupancy-situation'] = this.getOccupancySituation()
                            occresource2.attributes['residential-situation'] = "Mortgage - Owner Occupied"
                            occupancy2 = await this.createOccupancy(occresource2)
                            
                        }

                        let addressResource = store.addressResource
                        addressResource.relationships.occupancies = {data: [{id:occupancy1.data.data.id, type:'occupancies'},{id:occupancy2.data.data.id, type:'occupancies'}]}
                        delete addressResource.id

                        let address = await this.createAddress(addressResource)
                        let assetAttr = this.getAssetResource({data: { id: address.data.data.id,type: 'addresses'} }, {data: [{id: store.selectedOwner.id[0],type: 'people'}]} )
                        let asset = await this.createAsset(assetAttr)
                        expenseAttr = this.getExpensesResource( {data: [{id: store.selectedOwner.id[0],type: 'people'}]}, {data: [{id: asset.data.data.id,type:'assets'}]})
                        let expense = await this.createExpense(expenseAttr)

                        let liabilityRelationships = {
                            'people': {data: [{id: store.selectedOwner.id[0],type: 'people'}]},
                            'kinds':  {data: { type : "kinds", "id": "13-21" }},
                            'assets': {data: [{ type: 'assets', id: asset.data.data.id }]},
                            'expenses': {data: [{type: 'expenses', id: expense.data.data.id}]}
                        }

                        mutations.setLiabilityRelationships(liabilityRelationships)
                        await this.createLiability(this.getliabilityResource())

                        if(this.calculation['primary-applicant-id'] == this.entityId){
                            let addressWithOccupancy = {address: address.data.data, occupancy: occupancy1.data.data}
                            this.$emit('inputAddress',addressWithOccupancy)
                        } else {
                            let addressWithOccupancy = {address: address.data.data, occupancy: occupancy2.data.data}
                            this.$emit('inputAddress',addressWithOccupancy)
                        }

                        this.$emit('step',null)
                        
                    }
                    
                } else {

                    if(store.selectedOwner.id.length > 1) {
                        let payload = {
                            'occupancy-situation': this.getOccupancySituation(),
                            'residential-situation': 'Mortgage - Owner Occupied'
                        }

                        mutations.setOccupancies(payload)

                        if(this.occupancyResource1.hasOwnProperty('id')) {
                            delete  this.occupancyResource1.id 
                            delete  this.occupancyResource2.id 
                            delete store.addressResource.id
                        }

                        this.occupancyResource1.attributes['end-date'] = this.getEndDate()
                        this.occupancyResource2.attributes['end-date'] = this.getEndDate()
                        let occResource = this.occupancyResource1
                        occResource.person = {data: {id:store.selectedOwner.id[0], type: 'people'}}

                        let occupancy1 = await this.createOccupancy(occResource)
                        let occupancy2 = await this.createOccupancy(this.occupancyResource2)

                        let addressResource = store.addressResource

                        addressResource.relationships.occupancies = {data: [{id:occupancy1.data.data.id, type:'occupancies'},{id:occupancy2.data.data.id, type:'occupancies'}]}

                        delete addressResource.id

                        let address = await this.createAddress(addressResource)
                        let assetAttr = this.getAssetResource({data: { id: address.data.data.id,type: 'addresses'} }, {data: [{id: store.selectedOwner.id[0],type: 'people'}]} )
                        let asset = await this.createAsset(assetAttr)

                        expenseAttr = this.getExpensesResource( {data: [{id: store.selectedOwner.id[0],type: 'people'}]}, {data: [{id: asset.data.data.id,type:'assets'}]})
                        let expense = await this.createExpense(expenseAttr)

                        let liabilityRelationships = {
                            'people': {data: [{id: store.selectedOwner.id[0],type: 'people'}]},
                            'kinds':  {data: { type : "kinds", "id": "13-21" }},
                            'assets': {data: [{ type: 'assets', id: asset.data.data.id }]},
                            'expenses': {data: [{type: 'expenses', id: expense.data.data.id}]}
                        }

                        mutations.setLiabilityRelationships(liabilityRelationships)

                        await this.createLiability(this.getliabilityResource())

                        let addressWithOccupancy = {address: address.data.data, occupancy: occupancy2.data.data}
                        this.$emit('inputAddress',addressWithOccupancy)
                        this.$emit('step',null)
                    } else {

                        let payload = {
                            'occupancy-situation': this.getOccupancySituation(),
                            'residential-situation': 'Mortgage - Owner Occupied'
                        }

                        mutations.setOccupancies(payload)

                        if(this.occupancyResource1.hasOwnProperty('id')) {
                            delete  this.occupancyResource1.id 
                            delete store.addressResource.id
                        }

                        this.occupancyResource1.attributes['end-date'] = this.getEndDate()

                        let occResource = this.occupancyResource1

                        occResource.person = {data: {id:store.selectedOwner.id[0], type: 'people'}}

                        let occupancy = await this.createOccupancy(occResource)

                        let addressResource = store.addressResource
                        addressResource.relationships.occupancies = {data: [{id:occupancy.data.data.id, type:'occupancies'}]}
                        delete addressResource.id

                        let address = await this.createAddress(addressResource)
                        let assetAttr = this.getAssetResource({data: { id: address.data.data.id,type: 'addresses'} }, {data: [{id: store.selectedOwner.id[0],type: 'people'}]} )
                        
                        let asset = await this.createAsset(assetAttr)
                        expenseAttr = this.getExpensesResource( {data: [{id: store.selectedOwner.id[0],type: 'people'}]}, {data: [{id: asset.data.data.id,type:'assets'}]})
                        
                        let expense = await this.createExpense(expenseAttr)
                        let liabilityRelationships = {
                            'people': {data: [{id: store.selectedOwner.id[0],type: 'people'}]},
                            'kinds':  {data: { type : "kinds", "id": "13-21" }},
                            'assets': {data: [{ type: 'assets', id: asset.data.data.id }]},
                            'expenses': {data: [{type: 'expenses', id: expense.data.data.id}]}
                        }

                        mutations.setLiabilityRelationships(liabilityRelationships)

                        await this.createLiability(this.getliabilityResource())

                        let addressWithOccupancy = {address: address.data.data, occupancy: occupancy.data.data}
                        
                        this.$emit('inputAddress',addressWithOccupancy)
                        this.$emit('step',null)
                    }
                   
                }
                this.isSaving = false
           }
            
        },
        back() {
            this.$emit('back','mortgage')
        },
        close(){
           this.$emit('back',0)
        },
        async update(){
            // this.isSaving = true
            // let valid = await this.$refs.observer1.validate()
            // if (valid) {
            //     this.resources.occupancies.attributes['residential-situation'] = this.form['residential-situation']
            //     this.resources.occupancies.id =  this.address.occupancy.id
            //     this.resources.occupancies.relationships.address.data.id = this.address.occupancy.relationships.address.data.id
            //     this.resources.occupancies.relationships.person.data.id = this.address.occupancy.relationships.person.data.id
            //     this.resources.occupancies.relationships.application.data.id = this.address.occupancy.relationships.application.data.id
            //     await this.updateOccupancy(this.resources.occupancies)
            //     this.address.occupancy.attributes['residential-situation'] = this.form['residential-situation']
            //     this.$emit('updateTable',this.address)
            //     this.isSaving = false
            //     this.$emit('back',0)

            // }
           
        }
        
    },
    data() {
        return {
            isSaving: false,
            dialog : false,
            fields : [
                {model: 'amount-owing', label: 'Amount Owing',type:'text', prefix: '$'},
                {model: 'amount-financed', label: 'Amount Financed',type:'text', prefix: '$'},
                {model: 'payment-amount', label: 'Payment Amount',type:'text', prefix: '$'},
                {model: 'payment-frequency', label: 'Payment Frequency',type:'select',item: [ "Weekly", "Fortnightly", "Monthly" ], prefix: ''},
                {model: 'financial-institution', label: 'Financial Institution',type:'select',item: fieldOptions["financial-institution"], prefix: ''},
            ],
            form:{
                "amount-owing": '',
                "amount-financed": '',
                "payment-amount": '',
                "payment-frequency": '',
                "financial-institution": '',
            },
            validation_rules: {
                attributes: {
                    "amount-owing": 'required|between:0,99999999.99',
                    "amount-financed": 'required|between:0,99999999.99',
                    "payment-amount": 'required|between:0,99999999.99',
                    "payment-frequency": 'required|in:Weekly,Fortnightly,Monthly,Annual',
                    "financial-institution": `required|in:${fieldOptions["financial-institution"]}`,
                }
            }
        }
    }
}
</script>
<style lang="scss" >
    
    .ol-residential-history-5-is-currently-living {
        .btn {
            text-align: center;
            padding: 50px;
            border-radius: 20px;
            font-size: 15px;
            border: 1px solid;
            cursor: pointer;
            width: 250px !important;
            height: 150px !important;
        }
        .btn:hover{
            background: #E7FAFC;
        }
        .custom-outline1{
            border-color: var(--v-secondary-base);
        }
        .close-icon {
            position: absolute;
            right: 40px;
            top: 30px;
            // background: var(--v-secondary-lighten2);
            color: var(--v-secondary-base)!important;
            padding: 6px;
            border-radius: 36px !important;
            i {
                color: var(--v-secondary-base) !important;
            }
        }
    }
</style>