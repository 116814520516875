import Vue from "vue";

export const store = Vue.observable({

    // Resource
    loanType: null,
    appType: null,
    selectedEntity: 0,
    selectedEntityData: null,

    selectedEntityAll: false,
    preSelectRoles: [],
    preselectIncomeSituation: null,
    resources: {
        incomes: [],
        assets: [],
        expenses: [],
        liabilities: [],
        people: [],
        businesses: [],
        addresses: {
            incomes: [],
            assets: [],
            expenses: [],
            liabilities: [],
        },
    },

    allAddresses: [],
    allOccupancies: [],

    // Entities
    entities: [],
    entityParts: [],

    selectedFO: 0,
    selectedFOName: null,
    slidesFO: [
        {type: 'incomes', name: 'Incomes'},
        {type: 'assets', name: 'Assets'},
        {type: 'liabilities', name: 'Liabilities'},
        {type: 'expenses', name: 'Expenses'},
        {type: 'all', name: 'All'}
    ],
    preset: null,

    // Dialog
    saving: false,
    dialog: false,
    standaloneDialog: false,
    dialogType: '',
    
    stepper: 0,
    steps: [],
    currentStep: null,

    // Kinds
    kindsIncome: null,
    kindsAsset: null,
    kindsLiability: null,
    kindsExpense: null,

    incomeSituationOptions: ['Current', 'Secondary', 'Previous'],

    validate: ()=>{}, // passed function from _FOTable.softValidateTable()
});


export const actions = {

    // Resources
    setValidate(payload) {
        store.validate = payload;
    },

    setLoanType(payload) {
        store.loanType = payload;
    },
    setAppType(payload) {
        store.appType = payload;
    },

    setSelectedEntity(payload) {
        store.selectedEntity = payload;
    },
    setSelectedEntityData(payload) {
        store.selectedEntityData = payload;
    },
    setSelectedFO(payload) {
        store.selectedFO = payload;
    },

    setPeople(payload) {
        store.resources.people = payload;
    },

    setEntities(payload) {
        store.entities = payload;
    },
    setEntityParts(payload) {
        store.entityParts = payload;
    },

    setBusinesses(payload) {
        store.resources.businesses = payload;
    },

    setResources(payload) {

        // const {incomes, assets, expenses, liabilities} = payload

        let keys = Object.keys(payload)

        keys.forEach( key => {
            store.resources[key] = payload[key];
        });

    },
    updateResources(payload){

        payload.forEach( data => {

            if(data.data.data){

                let fo = data.data.data;

                let newData = store.resources[fo.type].filter(resource => {
                    return resource.id != fo.id
                })

                newData = [
                    fo,
                    ...newData
                ]
    
                store.resources[fo.type] = newData
    
            }

        })

    },
    deleteResource(payload){

        payload.forEach( data => {

            let newData = store.resources[data.type].filter(resource => {
                return resource.id != data.id
            })

            store.resources[data.type] = newData

        })


    },
    addResource({key, value}) {
        store.resources[key].push(value);
    },

    setAddresses(payload) {

        store.resources.addresses = payload;
    },

    setAllAddresses(payload) {

        store.allAddresses = payload;
    },

    setAllOccupancies(payload) {

        store.allOccupancies = payload;

    },

    updateAddress({key, value}) {
        store.resources.addresses[key].push(value);
    },

    //  Dialog

    closeDialog() {
        store.dialog = false;
    },

    closeStanaloneDialog() {
        store.standaloneDialog = false;
    },

    toggleSaving(payload) {
        store.saving = payload;
    },

    openDialog() {
        store.dialog = true;
    },

    openStandaloneDialog() {
        store.standaloneDialog = true;
    },
    setCurrentStep(payload){
        store.currentStep = payload
    },
    setSteps(payload){
        store.steps = payload
    },
    pushStep(payload){
        store.steps.push(payload)
    },
    nextStep(payload) {

        // Filter, remove obsolete kinds
        // console.log(payload)

        let obsolete = [
            "11-19", // "Equipment Finance"
        ]

        let newPayload = {...payload}
        let children = [...newPayload.children]
        
        // check if payload children has only 1
        if (payload.children.length === 1) {
            newPayload = {...payload.children[0]}
        } else {
            newPayload.children = children.filter( child => {
                return !obsolete.includes(child.id)
            })
        }


        // Save parent FO name: e.g.  Asset, Liabilities, etc.
        if(newPayload.main){
            store.selectedFOName = payload.name
        }
        

        store.steps.push(newPayload)
        store.saving = true

        setTimeout( () => {
            store.stepper = store.stepper+1
            store.currentStep = newPayload 
            store.saving = false
        }, 300)

    },

    prevStep() {

        store.stepper = store.stepper-1
        store.saving = true

        setTimeout( () => {
            store.steps.pop()
            store.currentStep = store.steps[store.stepper] 
            store.saving = false
        }, 300)
    },

    setDialogType(payload) {
        store.dialogType = payload;
    },

    resetIncomeEditorDialog(){
        store.stepper = 0
        store.steps = [store.kindsIncome]
        store.currentStep = store.kindsIncome 

        actions.entityReset()
    },
    
    resetFOEditorDialog(){

        store.stepper = 0
        store.steps = [{
            children: [
                {main: true, ...store.kindsAsset},
                {main: true, ...store.kindsLiability},
                {main: true, ...store.kindsExpense}
            ],
            name: 'Financial Objects',
            id: '19-45'
        }],

        store.currentStep = store.steps[0]
        store.selectedFOName = null
        store.preset = null

        actions.entityReset()
    },

    preSelectFOEditorDialog(payload){

        let data = null

        if(payload == 'assets'){
            data = store.kindsAsset
        }

        if(payload == 'liabilities'){
            data = store.kindsLiability
        }

        if(payload == 'expenses'){
            data = store.kindsExpense
        }

        store.stepper = 0
        store.steps = [data]
        store.currentStep = data

    },
    
    // Kinds
    setKindsIncome(payload){

        // Filter, remove obsolete kinds
        let obsolete = []

        // remove business income if consumer
        if (store.loanType === 'Consumer') {
            obsolete.push('12-30')
            obsolete.push('12-16')
        }

        let newPayload = {...payload}
        let children = [...newPayload.children]

        newPayload.children = children.filter( child => {
            return !obsolete.includes(child.id)
        })

        store.kindsIncome = newPayload 
    },
    setKindsAsset(payload){
        store.kindsAsset = payload 
    },
    setKindsLiability(payload){
        store.kindsLiability = payload 
    },
    setKindsExpense(payload){
        store.kindsExpense = payload 
    },
    setPreset(payload){
        store.preset = payload 
    },
    setSelectedEntityAll(payload){
        store.selectedEntityAll = payload 
    },
    setPreSelectRoles(payload){
        store.preSelectRoles = payload 
    },
    setPreselectIncomeSituation(payload){
        store.preselectIncomeSituation = payload 
    },

    setIncomeSituationOptions(payload) {
        store.incomeSituationOptions = payload;
    },
    setSelectedFOName(payload){
        store.selectedFOName = payload
    },
    resetAll(){

        store.loanType = null
        store.selectedEntity = 0
        store.selectedFO = 0
        store.preset = null
        store.selectedFOName = null

        actions.entityReset()
    
        // Dialog
        store.saving = false
        store.dialog = false
        store.standaloneDialog = false
        store.dialogType = ''
        
        store.stepper = 0
        store.steps = []
        store.currentStep = null
    
        // Kinds
        store.kindsIncome = null
        store.kindsAsset = null
        store.kindsLiability = null
        store.kindsExpense = null
    },
    entityReset(){
        
        store.selectedEntityAll = false
        store.preSelectRoles = [] 
        store.preselectIncomeSituation = null
        store.selectedEntityData = null
        
    }

};