<template>
    <v-card :disabled="!validComponentData" class="flex__sub-component-EmploymentEditor1 mx-auto" tile flat>
        <v-card-text class="pa-4 pt-0 pa-md-10 pt-md-0">
            <v-card tile flat class="mx-auto" max-width="700">
                <v-container pa-0 fluid>
                    <v-row>
                        <v-col cols="12">
                            <v-list>
                                <v-list-item-group
                                    v-model="selectedItem"
                                    @change="handleChange"
                                >
                                    <v-list-item
                                        :ripple="false"
                                        class="my-4 item-list"
                                        v-for="(option, key) in options"
                                        :key="key"
                                        :value="option.value"
                                    >

                                    <v-list-item-content>
                                        <v-list-item-title class="text-wrap">
                                            {{option.text}}
                                        </v-list-item-title>
                                    </v-list-item-content>

                                    </v-list-item>
                                </v-list-item-group>
                            </v-list>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card>
        </v-card-text>
        <!-- Remove Next btn in footer -->
        <Portal to="step-incrementer-next">
            <span class="d-none"></span>
        </Portal>
    </v-card>
</template>

<script>

    import { mapActions, mapState } from 'vuex'
    import helpers from '~/plugins/helpers'
    import localMixin from '~/components/shared/shared-mixins'
    import subscriptions from '~/components/shared/subscriptions'

    export default {
        name: 'EmploymentHistoryEditor1',

        components: { },

        mixins: [subscriptions, helpers, localMixin],

        data() {
            return {
                validComponentData: true,
                selectedItem: '',
                options: [
                    {text: 'Casual-Over 20 hours per week', value: 'No'},
                    {text: 'Casual-Under 20 hours per week', value: 'Yes'},
                    {text: 'I am an unemployed', value: 'Not Gainfully Employed'}
                ],
                person: {},
                resource: {
                    "type": "incomes",
                    "id": null,
                    "attributes": {
                        "employment-job-status": null,
                        "situation-casual-under-20-hours": null,
                    },
                    "relationships": null
                }
            }
        },

        computed: {
            ...mapState({
                appId: state => state.flows.appId
            }),
        },

        methods: {
            ...mapActions('resource-actions', [
                'getPeopleByResource',
                'savePeopleByResources',
                'createIncome',
                'updateIncome'
            ]),
            ...mapActions('flows', [
                'selectNextStep'
            ]),
            async pull() {
                let response = await this.getPeopleByResource({ include: 'incomes,parent-parts', resource: 'people'})

                this.person = await this.getPrimaryApplicant(response.data)

                if(response?.data?.included) {
                    await this.populateFields(response.data.included)

                    if(this.resource?.attributes) {

                        if(this.resource.attributes['employment-job-status'] == 'Casual') {
                            this.selectedItem = this.resource.attributes['situation-casual-under-20-hours']
                        } else {
                            this.selectedItem = this.resource.attributes['employment-job-status']
                        }
                        
                    }

                }
                
            },
            async push() {

                let res = await this.savePeopleByResources(
                    { 
                        resource: 'incomes', 
                        params: this.filterResourceWithoutTheseRelationships(['incomes'], [this.resource]) 
                    }
                )

                if (this.isAllPromisesValid(res)) {
                    
                    let data = this.$options.filters.toAppDataFormat(res)
                    
                    return  { valid: true, status: 'valid', data } 

                } else {
                    return  { valid: false, status: 'dirty' } 
                }
                
            },
            populateFields(data = []) {

                data.forEach(included => {

                    if(included.type == 'incomes') {
                        this.resource.id = included.id
                        this.resource.type = included.type
                        this.resource.relationships = included.relationships

                        Object.keys(included.attributes).forEach(attr => {
                            if(attr == 'employment-job-status' || attr == 'situation-casual-under-20-hours') {
                                this.resource.attributes[attr] = included.attributes[attr]
                            }
                        })
                    }

                })

            },
            handleChange(value){

                this.resource.relationships = {
                    "application": {
                        "data": {
                            "type": "applications",
                            "id": this.appId
                        }
                    },
                    "people": {
                        "data": [
                            {
                                "type": "people",
                                "id": this.person.id
                            }
                        ]
                    },
                    "kind": {
                        "data": {
                            "type": "kinds",
                            "id": '12-28'
                        }
                    },
                }

                if(value) {

                    if(value == 'Yes') {
                        this.resource.attributes['employment-job-status'] = 'Casual'
                        this.resource.attributes['situation-casual-under-20-hours'] = 'Yes'
                    } else if(value == 'No'){
                        this.resource.attributes['employment-job-status'] = 'Casual'
                        this.resource.attributes['situation-casual-under-20-hours'] = 'No'
                    } else {
                        this.resource.attributes['employment-job-status'] = this.selectedItem
                    }

                }

                this.selectNextStep()
            }
        }
    }
</script>

<style lang="scss" scoped>
    .flex__sub-component-EmploymentEditor1 {

        .v-list-item {
            min-height: 77px !important;
        }

        .v-list-item__title {
            font-size: 1.375rem !important;
        }

        .theme--light.v-list-item:not(.v-list-item--active):not(.v-list-item--disabled) {
            border-radius: 25px;
            border: 2px solid var(--v-light_gray_6-base) !important;
        }

        .theme--light.v-list-item--active {
            border-radius: 25px !important;
            border: 2px solid var(--v-secondary-base) !important;
        }

        .theme--light.v-list-item:before, .theme--light.v-list-item:hover:before, .theme--light.v-list-item:focus:before {
            border-radius: 25px;
            border: 1px solid var(--v-primary-base) !important;
            background-color: var(--v-secondary-base) !important;
        }

        .error-messages {
            border: none;
            color: var(--v-error-base) !important;
            caret-color: var(--v-error-base) !important;
            font-size: 12px;
        }

    }
</style>