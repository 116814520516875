<template>
    <v-card flat class="mx-auto" :disabled="isDisabled">
        <div class="px-6">
            <v-alert    
                v-if="debtConsolidationLiabilities.length > 4"
                dense
                outlined
                type="warning">
                    Debt Consolidation should not be greater than 4
            </v-alert>
        </div>
        <div v-if="vb.mdAndUp" >
            <div class="d-flex px-6">
                <v-sheet width="50%" color="light_gray_1" class="pa-4">
                    <div class="px-4 pt-4">
                        <v-img 
                            max-width="120"
                            class="flex-brand" 
                            :src="require(`~/assets/images/nodifi.svg`)" 
                            position="center left" 
                            contain>
                        </v-img>
                        <div class="mt-2 font-size-20">Data</div>
                    </div>

                    <v-card flat class="overflow-y-auto light_gray_1 mt-4">
                        <v-card-text class="d-flex">

                            <v-list class="pt-0 light_gray_1">

                                <v-list-item v-if="debtConsolidationLiabilities.length > 0" two-line class="px-0" v-for="(liability, index) in debtConsolidationLiabilities" :key="index">
                                    <v-list-item-content class="pt-0">
                                        <v-list-item-title class="font-weight-bold">Debt Consolidation {{ index + 1 }}</v-list-item-title>
                                        <v-list-item-title class="font-italic pt-2">{{ liability.attributes['amount-owing'] | currency }}</v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>

                                <v-list-item two-line class="px-0">
                                    <v-list-item-content class="pt-0">
                                        <v-list-item-title class="font-weight-bold">Consolidation Amount</v-list-item-title>
                                        <v-list-item-title class="font-italic pt-2">{{ totalConsolidationLiabilities | currency }}</v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>

                                <v-list-item two-line class="px-0">
                                    <v-list-item-content class="pt-0">
                                        <v-list-item-title class="font-weight-bold">Request Loan Amount</v-list-item-title>
                                        <v-list-item-title class="font-italic pt-2">{{ resources.loans.attributes[getRequiredLoanAmountFieldName] | currency }}</v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>

                            </v-list>

                        </v-card-text>
                    </v-card>
                </v-sheet>


                <v-sheet width="50%" class="pa-4">
                    <div class="px-4">
                        <v-img 
                            max-width="120"
                            class="flex-brand" 
                            :src="require(`~/assets/images/logo/cropped/lender-logo-now-finance.png`)" 
                            position="center left" 
                            contain>
                        </v-img>
                        <div class="mt-2 font-size-20">Requirements</div>
                    </div>
                    <v-card flat class="overflow-y-auto mt-4">
                        <v-card-text class="d-flex pa-0">

                            <div class="px-4" style="width: 400px;">
                                <ValidationObserver ref="observer" tag="form">
                                    <div class="flex-form" >

                                        <div v-for="(field, key) in resources.loans.attributes.lender.nowFinance" class="flex-field" :key="key">
                                            <ValidationProvider 
                                                v-slot="{ errors }" 
                                                :name="labels[key]"
                                                :rules="getRules(key)" 
                                                :vid="key">

                                                <NodifiMoney
                                                    v-if="key === 'additionalLoanAmount'"
                                                    v-model="resources.loans.attributes.lender.nowFinance['additionalLoanAmount']['amount']"
                                                    disabled
                                                    :label="labels[key]"
                                                    :errors="errors" />

                                                <v-select
                                                    v-else-if="isFieldType('picklist', key)"
                                                    v-model="resources.loans.attributes.lender.nowFinance[key]"
                                                    :items="getOptions(key)"
                                                    color="secondary"
                                                    :error-messages="errors"
                                                    :label="labels[key]">
                                                </v-select>

                                                <v-textarea
                                                    v-else
                                                    rows="10"
                                                    auto-grow
                                                    :label="labels[key]"
                                                    color="secondary"
                                                    :error-messages="errors"
                                                    v-model="resources.loans.attributes.lender.nowFinance[key]">
                                                </v-textarea>

                                            </ValidationProvider>
                                        </div>
                                        <div class="flex-field"></div>
                                    </div>
                                        
                                </ValidationObserver>
                            </div>

                        </v-card-text>
                    </v-card>
                </v-sheet>
            
            </div>

        </div>
        <div v-else>

            <v-card flat class="mx-2 rounded-xl" :style="`background-color: ${hexToRGBA(getActiveTheme.colors.secondary, 0.3)}`">

                <v-card-text class="d-flex py-1">
                    <v-row>
                        <v-col cols="6" class="pa-1">
                            <v-card
                                min-height="45"
                                flat class="rounded-xl px-1"
                                :color="tabSelection == 'nodifi' ? 'white' : 'transparent'">
                                <div class="d-flex justify-center pt-1" @click="selectTab('nodifi')">
                                    <v-img 
                                        max-width="55"
                                        class="flex-brand" 
                                        :src="require(`~/assets/images/nodifi.svg`)" 
                                        position="center left" 
                                        contain>
                                    </v-img>
                                    <div class="mt-3 font-size-12 pl-1">Data</div>
                                </div>
                            </v-card>
                        </v-col>
                        <v-col cols="6" class="pa-1">
                            <v-card
                                min-height="45"
                                flat class="rounded-xl px-1"
                                :color="tabSelection == 'nowfinance' ? 'white' : 'transparent'">
                                <div class="d-flex flex-column" @click="selectTab('nowfinance')">
                                    <v-img 
                                        max-width="55"
                                        class="flex-brand mx-auto mt-1" 
                                        :src="require(`~/assets/images/logo/cropped/lender-logo-now-finance.png`)" 
                                        position="center left" 
                                        contain>
                                    </v-img>
                                    <div class="font-size-12 pl-1 mx-auto mt-n1">Requirements</div>
                                </div>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-card-text>
                
            </v-card>

            <v-list v-show="tabSelection == 'nodifi'" class="mx-2" color="transparent">

                <v-list-item v-if="debtConsolidationLiabilities.length > 0" two-line class="px-0" v-for="(liability, index) in debtConsolidationLiabilities" :key="index">
                    <v-list-item-content class="pt-0">
                        <v-list-item-title class="font-weight-bold">Debt Consolidation {{ index + 1 }}</v-list-item-title>
                        <v-list-item-title class="font-italic pt-2">{{ liability.attributes['amount-owing'] | currency }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>

                <v-list-item two-line class="px-0">
                    <v-list-item-content class="pt-0">
                        <v-list-item-title class="font-weight-bold">Consolidation Amount</v-list-item-title>
                        <v-list-item-title class="font-italic pt-2">{{ totalConsolidationLiabilities | currency }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>

                <v-list-item two-line class="px-0">
                    <v-list-item-content class="pt-0">
                        <v-list-item-title class="font-weight-bold">Request Loan Amount</v-list-item-title>
                        <v-list-item-title class="font-italic pt-2">{{ resources.loans.attributes[getRequiredLoanAmountFieldName] | currency }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>

            </v-list>

            <div v-show="tabSelection != 'nodifi'" class="mx-2">
                <ValidationObserver ref="observer" tag="form">
                    <div class="flex-form" >

                        <div v-for="(field, key) in resources.loans.attributes.lender.nowFinance" class="flex-field" :key="key">
                            <ValidationProvider 
                                v-slot="{ errors }" 
                                :name="labels[key]"
                                :rules="getRules(key)" 
                                :vid="key">

                                <NodifiMoney
                                    v-if="key === 'additionalLoanAmount'"
                                    v-model="resources.loans.attributes.lender.nowFinance['additionalLoanAmount']['amount']"
                                    disabled
                                    :label="labels[key]"
                                    :errors="errors" />

                                <v-select
                                    v-else-if="isFieldType('picklist', key)"
                                    v-model="resources.loans.attributes.lender.nowFinance[key]"
                                    :items="getOptions(key)"
                                    color="secondary"
                                    :error-messages="errors"
                                    :label="labels[key]">
                                </v-select>

                                <v-textarea
                                    v-else
                                    rows="10"
                                    auto-grow
                                    :label="labels[key]"
                                    color="secondary"
                                    :error-messages="errors"
                                    v-model="resources.loans.attributes.lender.nowFinance[key]">
                                </v-textarea>

                            </ValidationProvider>
                        </div>
                        <div class="flex-field"></div>
                    </div>
                </ValidationObserver>
            </div>

        </div>
    </v-card>
</template>
<script>

    import { mapActions, mapState, mapGetters } from 'vuex'
    import helpers from '~/plugins/helpers'
    import subscriptions from '~/components/shared/subscriptions'

    export default {
        name: "NowFinanceDebtConsolidation1",

        mixins: [ helpers, subscriptions ],

        data() {
            return {
                liabilities: [],
                tabSelection: "nodifi",
                resources: {
                    loans: {
                        "attributes": {
                            "actual-required-loan-amount": 0,
                            "requested-required-loan-amount": 0,
                            "lender": {
                                "nowFinance": {
                                    "additionalLoanAmount": {
                                        "amount": 0,
                                        "currency": "AUD"
                                    },
                                    "additionalLoanPurpose": null,
                                    "additionalDescription": null
                                }
                            }
                        }
                    }
                },
                labels: {
                    "additionalLoanAmount": "Additional Loan Amount",
                    "additionalLoanPurpose": "Additional Loan Purpose",
                    "additionalDescription": "Additional Description"
                },
                options: {
                    "additionalLoanPurpose": ["Autoupgrade", "Boat Purchase", "Car Purchase", "Car Repairs",
                    "Caravan Purchase", "Dental Expenses", "Funeral Expenses", "Home Improvements",
                    "Household Furnishings", "Other Vehicle Purchase", "Medical Expenses", "Mortgage Cost Funding", "Sporting Equipment",
                    "Travel", "Wedding Expenses", "Other"]
                },
                validation_rules: {
                    attributes: {
                        "additionalLoanAmount": "required",
                        "additionalLoanPurpose": `required|in:Autoupgrade,Boat Purchase,Car Purchase,Car Repairs,Caravan Purchase,Dental Expenses,Funeral Expenses,Home Improvements,Household Furnishings,Other Vehicle Purchase,Medical Expenses,Mortgage Cost Funding,Sporting Equipment,Travel,Wedding Expenses,Other`,
                        "additionalDescription": "max:100"
                    }
                }
            }
        },

        computed: {
            ...mapState({
                appData: state => state.flows.appData,
                appId: state => state.flows.appId.$event
            }),
            ...mapGetters('themes', [
                'getActiveTheme'
            ]),

            getLoanId(){
                return this.appData?.relationships?.loan?.data?.id
            },
            debtConsolidationLiabilities() {
                let { liabilities } = this
                let filteredLiabilities = []

                if(liabilities.length > 0) {
                    liabilities.forEach(liability => {
                        if(liability.attributes['to-be-paid-out'] === 'Yes') {
                            filteredLiabilities.push(liability)
                        }
                    })
                }

                return filteredLiabilities
            },
            totalConsolidationLiabilities() {
                let { liabilities } = this
                let total = 0

                if(liabilities.length > 0) {
                    liabilities.forEach(liability => {
                        if(liability.attributes['to-be-paid-out'] === 'Yes') {
                            total += parseFloat(liability.attributes['amount-owing'])
                        }
                    })
                }

                return total
            },
            additionalLoanPurposeField() {
                return this.resources.loans.attributes.lender['nowFinance']['additionalLoanPurpose']
            },
            getRequiredLoanAmountFieldName(){
                if(this.appData?.attributes?.['target'] == 'Assister') {
                    return 'actual-required-loan-amount'
                } else {
                    return 'requested-required-loan-amount'
                }
            }
        },

        methods: {
            ...mapActions('resource-actions', [
                'getLoan',
                'updateLoan',
                'getPeopleByResource'
            ]),
            selectTab(tab) {
                this.tabSelection = tab
            },
            hexToRGBA(hex, a = 1){
                return this.$options.filters.hexToRGBA(hex, a)
            },
            async pull() {

                try {

                    let loan = await this.getLoan(this.getLoanId)
                    let people = await this.getPeopleByResource({ include: 'liabilities,parent-parts', resource: 'people'})

                    if(loan.status === 200) {
                        this.liabilities = people.data.included.filter(data => data.type === 'liabilities')
                        this.resources.loans.attributes[this.getRequiredLoanAmountFieldName] = loan.data.data.attributes[this.getRequiredLoanAmountFieldName]


                        if(this.liabilities.length > 0 && this.resources.loans.attributes[this.getRequiredLoanAmountFieldName] > 0) {
                            this.resources.loans.attributes.lender.nowFinance['additionalLoanAmount']['amount'] = this.getTotalAdditionalLoanAmount()
                        }

                        this.resources.loans.attributes.lender.nowFinance['additionalLoanPurpose'] = loan.data.data.attributes.lender.nowFinance['additionalLoanPurpose']
                        this.resources.loans.attributes.lender.nowFinance['additionalDescription'] = loan.data.data.attributes.lender.nowFinance['additionalDescription']

                    }

                } catch (err) {
                    console.log(err)
                }

            },
            async push() {

                try {

                    let res = null

                    if(this.getLoanId) {

                        res = this.updateLoan({
                            "id": this.getLoanId,
                            "type": "loans",
                            "attributes": {
                                "lender": {
                                    "nowFinance": {
                                        "additionalLoanAmount": {
                                            "amount": this.resources.loans.attributes.lender.nowFinance['additionalLoanAmount']['amount'],
                                            "currency": "AUD"
                                        },
                                        "additionalLoanPurpose": this.resources.loans.attributes.lender.nowFinance['additionalLoanPurpose'],
                                        "additionalDescription": this.resources.loans.attributes.lender.nowFinance['additionalDescription']
                                    }
                                }
                            }
                        })

                    }

                    const {valid, status} = await this.validateFormData()

                    const data = await this.appDataHelper([res])

                    return {valid, status, data}

                } catch (err) {
                    console.log(err)
                }

            },
            getTotalAdditionalLoanAmount() {
                let total = 0

                if(this.totalConsolidationLiabilities > 0) {
                    total = (parseFloat(this.resources.loans.attributes[this.getRequiredLoanAmountFieldName]) - this.totalConsolidationLiabilities)
                }

                return total
            }
        },

        watch: {
            additionalLoanPurposeField(val) {
                if(val === 'Other') {
                    this.validation_rules.attributes['additionalDescription'] = 'required|max:100'
                } else {
                    this.validation_rules.attributes['additionalDescription'] = 'max:100'
                }
            }
        }

    }
</script>
<style lang="scss" scoped>
    .flex-form {
        display: grid;
        grid-column-gap: 50px;
        grid-row-gap: 0;
        grid-template-columns:  repeat(auto-fit, minmax(260px, 1fr));

        .flex-field {
            align-self: start;
        }
    }

</style>