<template>
        <v-card tile flat :disabled="loading">
            <!-- #fo-form -->
            <Portal to="fo-form">
                <FOForms :data="currentStep" :ref="`foForm`"/>
            </Portal>

            <v-card-text class="pa-4 pb-8">

                <v-card tile flat class="mx-auto" :width="!isLastStep ? 800 : 1000"> 
                        
                        <template v-if="!isLastStep">
                            <v-card-title :class="['px-3 pt-0 pb-3 justify-center']">
                                {{ preset ? capitalize(toSingular(preset)) : selectedFOName }}
                            </v-card-title>
                            <v-card-text class="mb-6 text-center">
                                <div class="body-1 font-weight-medium">
                                    
                                </div>
                            </v-card-text>  
                        </template>

                        <v-carousel
                            light
                            :value="stepper"
                            :continuous="false"
                            :show-arrows="false"
                            hide-delimiters
                            touchless
                            hide-delimiter-background
                            height="auto"
                            >
                        <v-carousel-item v-for="(step, index) in steps" :key="step.id">

                            <template v-if="step.children.length > 0">

                                <v-sheet v-if="index == 0 && !preset" class="mx-auto text-center">
                                    <v-responsive 
                                        v-for="child in step.children"
                                        v-if="isVisible(child.id)"
                                        :key="child.id"
                                        width="240"
                                        height="280"
                                        class="ma-2 d-inline-flex"
                                        :aspect-ratio="1/1">
                                        <v-card 
                                            color="secondary" 
                                            flat height="100%" 
                                            @click="next(child)"
                                            rounded="lg"
                                            class="d-flex flex-column justify-center align-center flex-box px-6 py-12">

                                            <div class="mx-auto text-center mb-5">
                                                <v-icon size="56" color="white" class="mb-4">mdi-blur</v-icon>
                                                <div class="flex-options font-weight-bold white--text flex-tile-text">
                                                    <span>{{child.name}}</span>
                                                </div>
                                            </div>

                                            <v-spacer></v-spacer>

                                            <v-btn depressed large color="secondary" width="100">Select</v-btn>

                                        </v-card>
                                    </v-responsive>
                                </v-sheet>

                                <v-container class="light_gray_1 px-4 py-2 rounded-lg" v-else>
                                    <v-row>
                                        <v-col v-for="child in step.children"
                                        v-if="isVisible(child.id)"
                                        class="pa-2"
                                        :key="child.id" cols="12" sm="6">
                                        
                                            <v-card flat height="100%" 
                                            @click="next(child)"
                                            rounded="lg"
                                            class="d-flex pa-4 align-center justify-center">

                                                <div class="flex-options body-2 font-weight-medium">
                                                    {{ child.name }} {{ development ? `${child.id}` : ''}}
                                                </div>
                                                <v-spacer></v-spacer>
                                                <v-btn color="secondary" :width="86" small depressed>
                                                    Select
                                                </v-btn>

                                            </v-card>

                                        </v-col>
                                    </v-row>
                                </v-container>

                            </template>

                            <v-container v-else>

                                <v-card v-if="selectedEntityAll && !hasPreSectedRoles" flat tile>
                                    <v-card-text>
                                        <RoleSelector 
                                            v-model="preSelectRoles"
                                            label="Select Role"
                                            large
                                            :max="100"
                                            :data="entities"
                                            :parts="entityParts"
                                        />
                                    </v-card-text>
                                </v-card>
                                <template v-else>
                                    <PortalTarget name="fo-form">
                                        <!-- 
                                            Using portal to keep single instance of FOForms
                                            See #fo-form above ^
                                        -->
                                    </PortalTarget>
                                </template>

                            </v-container>

                        </v-carousel-item>
                        </v-carousel>
                </v-card>

            </v-card-text>
            
            <v-card :height="30" tile flat/>

            <Portal to="fo-editor-actions">
                <v-divider></v-divider>

                <v-card-actions class="pa-4 pa-md-6 flex-wrap controls">
                    <template v-if="selectedEntityAll && !hasPreSectedRoles">
                        <v-btn v-if="isLastStep" color="secondary" :disabled="preSelectRoles.length ==0" :min-width="140" depressed nuxt large @click="handleNext">Next</v-btn>
                    </template>
                    
                    <template v-else>
                        <v-btn :disabled="loading" v-if="isLastStep" color="secondary" :min-width="140" depressed nuxt large @click="save">Save & Close</v-btn> 
                        <v-btn :disabled="loading" v-if="isLastStep" color="secondary" :min-width="140" depressed nuxt large @click="saveAndNew">Save & New</v-btn> 
                    </template>

                    <v-spacer></v-spacer>  
                    <v-btn :disabled="loading" color="light_gray_2" class="flex-back-button" v-if="stepper == 0" :min-width="140" depressed outlined nuxt large @click="cancel">Back</v-btn> 
                    <v-btn :disabled="loading" color="light_gray_2" class="flex-back-button" v-if="stepper != 0" :min-width="140" depressed outlined nuxt large @click="back">Back</v-btn> 
                    
                </v-card-actions>
            </Portal>

        </v-card>
</template>

<script>

    import { mapState, mapActions, mapGetters, mapMutations } from 'vuex'
    import {store, actions} from '~/components/v1/FinancialObjectsEditor1/__store'
    import shared from '~/components/v1/FinancialObjectsEditor1/__shared'
    import obsoleteFos from '~/components/v1/FinancialObjectsEditor1/obsolete-fos'

    import FOForms from '~/components/v1/FinancialObjectsEditor1/_FOForms'
    import RoleSelector from '~/components/v1/FinancialObjectsEditor1/_RoleSelector'

    export default {
        name: 'FOEditor',
        components: {
            FOForms,
            RoleSelector
        },
        mixins: [shared],
        props: {

        },
        data() {
            return {
                hasPreSectedRoles: false,
                visible: {
                    "11-16": true, // Living Expenses 11-16
                }
            }
        },
        computed: {
            ...mapGetters('kinds', ['getKindsAsset', 'getKindsLiability', 'getKindsExpense']),
            ...mapGetters('flows', ['getCalculation']),
            preSelectRoles: {
                get(){
                    return store.preSelectRoles
                },
                set(value){
                    actions.setPreSelectRoles(value)
                }
            },
            loading(){
                return store.saving
            },
            selectedEntity(){
                
                let index = store.selectedEntity

                if(this.entities[index]) return this.entities[index];

                return null

            },
            primaryApplicantId () {
                return this.getCalculation && this.getCalculation['primary-applicant-id']
            }
        },
        mounted(){

            const {preset} = this

            actions.setKindsAsset(this.getKindsAsset)
            actions.setKindsLiability(this.getKindsLiability)
            actions.setKindsExpense(this.getKindsExpense)

            if(preset){

                actions.preSelectFOEditorDialog(preset)

            } else {

                actions.resetFOEditorDialog()
            }

            // If Selected Entity is "All"
            if(this.entities.length == this.selectedEntity){
                actions.setSelectedEntityAll(true)
            }

            this.expensesLogic()

        },
        methods: {
            cancel(){

                actions.closeDialog()

                setTimeout( () => {

                    actions.resetFOEditorDialog()
                    this.hasPreSectedRoles = false

                }, 300)

            },
            reset(){

                const {preset} = this

                setTimeout( () => {
                    
                    if(preset){

                        actions.preSelectFOEditorDialog(preset)

                    } else {

                        actions.resetFOEditorDialog()

                    }

                    this.hasPreSectedRoles = false

                }, 300)

            },
            next(child){
                actions.nextStep(child)
            },
            back(){
                actions.prevStep()
            },
            save(){

                if(this.$refs.foForm){
                    this.$refs.foForm.save().then(res => {
                        if(res.status == 'ok'){
                            this.cancel()
                        }
                    })
                }

            },
            saveAndNew(){

                if(this.$refs.foForm){
                    this.$refs.foForm.save().then(res => {
                        if(res.status == 'ok'){
                            this.reset()
                        }
                    })
                }

            },
            isRecordExists(data){

                let isObsolete = false

                Object.keys(obsoleteFos).forEach( fo => {
                    
                    if(data.id == fo){
                        isObsolete = true
                    }

                })

                return !isObsolete
            },
            handleNext(){

                this.hasPreSectedRoles = true

            },
            isVisible(key){
                
                const {visible} = this

                if(visible.hasOwnProperty(key)){
                    return visible[key]
                }

                return true

            },
            expensesLogic(data = null){

                let collection = data

                if(!data){
                    collection = this.expenses
                }

                let hasLivingExpenses = false

                collection.forEach(expense => {
                    // if consumer and has living expense record
                    
                    if(expense.relationships.kind.data.id == "11-16" && store.loanType == 'Consumer'){  // Living Expenses 11-16
                        const { people } = expense.relationships

                        if(people.data.length) {
                            people.data.forEach(person => {
                                if (person.id == this.primaryApplicantId) {
                                    hasLivingExpenses = true
                                }
                            })
                        }
 
                    }
                })

                if (store.loanType == 'Consumer') {

                    this.visible["11-16"] = !hasLivingExpenses

                } else {

                    if(this.selectedEntity?.type == 'businesses') {
                        this.visible['11-16'] = false
                    } else {
                        this.visible['11-16'] = true
                    }

                }

            },
        },
        watch: {
            preset(val){

                if(val){
                    actions.preSelectFOEditorDialog(val)
                }

            },
            'expenses': {
                handler(val) {
                    this.expensesLogic(val)
                },
                deep: true
            },
            selectedEntity(val) {
                this.expensesLogic()
            }
        },

    }
</script>

<style lang="scss" scoped>

    .flex-tile {
        line-height: 1.4;
    }

</style>