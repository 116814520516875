
export default async (context) => {

    if(context.$auth.loggedIn) {
        // Goto Home if already logged in...
        
        if(context.route.name == "login" || context.route.name == "group_slug-login"){
            let group = context.store.getters['slug/getGroupSlug']
            context.redirect(`/${group}/home`)
        }
        
        if(!context.$auth.user.hasOwnProperty('username')){

            try {

                let userRes = await context.store.dispatch(`auth-actions/getUser`)
                
                if(userRes?.data?.data) {

                    let userDetails = userRes.data.data[0]
                
                    userDetails.attributes.id = userDetails.id
    
                    context.$auth.setUser(userDetails.attributes)
                    context.store.commit('auth-actions/storeUserData', userDetails)
    
                    let user_setting = await context.store.dispatch(`auth-actions/getUserSetting`, userDetails.id)
    
                    context.store.commit('auth-actions/setUserSetting', user_setting?.data?.data?.attributes)

                } else {
                    context.error({
                        message: userRes?.data?.message,
                        statusCode: userRes?.status
                    })
                }
            } catch (error) {
                
                console.log('Uh oh!', error)
    
            }


        }

    } 

}

