import { mapState, mapMutations, mapActions, mapGetters } from 'vuex';
import tenantNames from '~/config/tenants'
import tenantScripts from '~/config/tenant-scripts'
import meta from '~/static/manifest'


export default {  

    head() {

        // console.log(process.env.envNC)
        // console.log(this.getTenantScripts)

        return {
          title: this.getTenantFriendlyName,
          bodyAttrs: {
            class: this.showLiveChat ? '' : 'hide-live-chat'
          },
          // Global Scripts

          script: [
            { 
              src: "//cdn.bizible.com/scripts/bizible.js",
              type: "text/javascript",
              async: "",
            },

            // Live Chat Scripts
            ...this.supportLiveChat,

            // Tenant Specific Scripts
            ...this.getTenantScripts
            
          ],
          noscript: [
            ...this.getTenantNoScripts
          ],
          link: [
            
            // favicon etc.
            {...this.getFavicon},

          ]
        }
    },
    computed: {
        ...mapGetters({
            getTenant: 'slug/getTenant',
            getActiveTheme: 'themes/getActiveTheme',
        }),
        getTenantScripts(){

          let scripts = []

          tenantScripts.data.forEach( tenant => {

            if(tenant.slug == this.getTenant){

                tenant.scripts.forEach(script => {

                  if(script.production){

                    if(process.env.envNC == 'production'){
                      scripts.push(script.data)
                    } 

                  } else {
                    scripts.push(script.data)
                  }
                  
                })

            }
            
          });

          return scripts
        },
        getTenantNoScripts(){

          let scripts = []

          tenantScripts.data.forEach( tenant => {

            if(tenant.slug == this.getTenant){

                tenant.scripts.forEach(script => {
                  
                  if(script.noscript) {

                      if(script.production){

                        if(process.env.envNC == 'production'){
                          scripts.push(script.data)
                        } 
    
                      } else {
                        scripts.push(script.data)
                      }

                  }
                  
                })

            }
            
          });

          return scripts
        },
        getTenantFriendlyName(){
            // TEMP: Add mapping
            let title = this.getTenant

            tenantNames.data.forEach( tenant => {

              if(tenant.slug == this.getTenant){
                title = tenant.name
              }
              
            });

            return title

        },
        getThemeStyle(){
          
            return { 
                rel: 'stylesheet', 
                type: 'text/css',
                href: `/${this.getActiveTheme.name}.css?cb=${meta.hash}`,
            }
        },
        getFavicon(){

          return { 
            hid: 'fav-icon',
            rel: 'icon', 
            type: 'image/x-icon', 
            href: `/themes/${this.getActiveTheme.name}/images/favicon.ico` 
          }

        },
        ...mapGetters({
            getFlowSlug: 'slug/getFlowSlug',
            getActiveStepData: 'flows/getActiveStepData',
        }),
        showLiveChat(){

          const {getActiveStepData, getFlowSlug} = this

          let isIndicativeQuoteStep = false
          let isLenderSubmissionFlow = getFlowSlug && getFlowSlug.includes('lender-submission')

          if(getActiveStepData) {
              if(getActiveStepData.name == "Indicative Quote") {
                isIndicativeQuoteStep = true
              }
          }

          return isIndicativeQuoteStep || isLenderSubmissionFlow

        },
        supportLiveChat(){

          const development = process.env.envNC !== 'production'

          if(development) {

              return [
                {
                  innerHTML: `function initFreshChat() {
                    window.fcWidget.init({
                         token: "b34da5b1-8cc5-4643-87f7-d547d17e71f5",
                   host: "https://loanmarket.freshchat.com",
                   widgetUuid: "273b16db-5473-4298-8b07-102f64c87215"
                    });
                  }
                  function initialize(i,t){var e;i.getElementById(t)?
                  initFreshChat():((e=i.createElement("script")).id=t,e.async=!0,
                  e.src="https://loanmarket.freshchat.com/js/widget.js",e.onload=initFreshChat,i.head.appendChild(e))}
                  function initiateCall(){initialize(document,"Freshchat-js-sdk")}
                  initiateCall();`,
                  body: true,
                },
              ]

          }

          return []
        }

    },
}