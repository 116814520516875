import Vue from "vue";

export const store = Vue.observable({
    
    

});


export const actions = {
    

};