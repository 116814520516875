<template>
    <FlexWrap @resize="handleResize">

        <v-card :disabled="isDisabled" class="mx-auto" tile flat :class="vb.mdAndUp ? 'px-8' : 'px-2'">
            <v-card-title class="title py-1">
                What are you buying?
            </v-card-title>

            <v-card-text class="pa-4 pt-0 pa-md-5 pt-md-0">
                <v-container pa-0 fluid>
                    <v-row>
                        <v-col v-if="vb.mdAndUp" class="py-0 my-0 mb-n4 mt-3">
                            <!-- <div class="d-flex justify-space-between align-center py-2 px-4 flex-table-row">

                                <div class="d-flex flex-column justify-start">
                                    <div class="font-size-16 pl-4 grey--text">
                                        Purchase Details
                                    </div>
                                </div>

                                <div class="d-flex flex-column justify-start">
                                    <div class="font-size-16 grey--text pl-2">
                                        Purchase Type
                                    </div>
                                </div>

                                <div class="d-flex flex-column justify-start">
                                    <div class="font-size-16 grey--text">
                                        Purchase Price
                                    </div>
                                </div>

                                <div class="d-flex flex-column justify-start">
                                    <div class="font-size-16 grey--text">
                                        Loan Required
                                    </div>
                                </div>

                                <div class="d-flex justify-end">

                                </div>
                            </div> -->
                        </v-col>

                        <v-col cols="12">

                            <v-data-table
                                :headers="tableHeaders"
                                :loading="false"
                                loading-text="Please wait..."
                                :items="purchasesTableData"
                                hide-default-footer
                                :mobile-breakpoint="0"
                                outlined
                                class="v-data-table-2 mx-auto  table-card">

                                <!-- <template
                                    v-slot:header
                                >
                                    <thead>
                                        <tr>
                                            <th>
                                            This is a header
                                            </th>
                                            <th>
                                            This is a header
                                            </th>
                                            <th>
                                            This is a header
                                            </th>
                                            <th>
                                            This is a header
                                            </th>
                                        </tr>
                                    </thead>
                                </template> -->

                                    <template
                                        v-slot:body="{ items }"
                                    >
                                        <tbody v-if="vb.smAndUp">
                                            <tr v-for="item in items"
                                                :key="item.id"
                                                 class="table-card-tr pa-6 ma-2">
                                            <td class="pl-5 black--text body-1 font-weight-medium">{{ getPurchaseDescription(item) }}</td>
                                            <td v-if="!vb.xsOnly" class="black--text body-2">
                                                <v-chip color="lighten-5 black--text pa-3" :style="`background-color: ${hexToRGBA(getActiveTheme.colors.primary, 0.1)}`">
                                                    {{ getAssetKind(item) }}
                                                </v-chip>
                                            </td>
                                            <td v-if="!vb.xsOnly" class="black--text body-2">{{ item.attributes["purchase-price"] | currency }}</td>
                                            <td v-if="!vb.xsOnly" class="black--text body-2">{{ item.attributes["required-loan-amount"] | currency }}</td>
                                            <td class="actions">
                                                <div class="d-flex justify-end pr-2">
                                                    <v-btn 
                                                        fab 
                                                        small
                                                        depressed 
                                                        text 
                                                        color="light_gray_2"
                                                        class="mx-1"
                                                        :style="`background-color: ${hexToRGBA(getActiveTheme.colors.light_gray_2, 0.1)}`"
                                                        @click="openEditPurchase(item)">
                                                        <v-icon size="18">mdi-pencil</v-icon>
                                                    </v-btn>

                                                    <ConfirmModal 
                                                        async
                                                        :title="`Delete Purchase`"
                                                        :processing="deleting"
                                                        class="mx-2"
                                                        @confirm="handleDelete(item.id)">
                                                        <template v-slot:activator="{on}">
                                                            <v-btn
                                                                depressed
                                                                max-width="40"
                                                                max-height="40"
                                                                fab :ripple="false"
                                                                :style="`background-color: ${hexToRGBA(getActiveTheme.colors.light_gray_2, 0.1)}`"
                                                                @click.stop="on"> 
                                                                <v-icon size="18">mdi-delete</v-icon>
                                                            </v-btn>
                                                        </template>
                                                    </ConfirmModal>
                                                </div>
                                            </td>
                                            </tr>
                                        </tbody>

                                        <tbody v-else>
                                            <tr v-for="item in items"
                                                :key="item.id"
                                                 class="table-card-tr pa-6 ma-2">
                                                <td class="pa-5">
                                                   <h3 class="black--text font-weight-medium mb-3"> {{ getPurchaseDescription(item) }}</h3>
                                                   <h3 class="black--text">Purchase Price:  <span class="ml-2">{{ item.attributes["purchase-price"] | currency }}</span></h3>
                                                   <h3 class="black--text">Loan Required:  <span class="ml-2">{{ item.attributes["required-loan-amount"] | currency }}</span></h3>

                                                   <div class="text-right mt-5">
                                                       <v-chip color="lighten-5 black--text pa-3" :style="`background-color: ${hexToRGBA(getActiveTheme.colors.primary, 0.1)}`">
                                                            {{ getAssetKind(item) }}
                                                        </v-chip>

                                                        <v-btn 
                                                            depressed
                                                            max-width="40"
                                                            max-height="40"
                                                            fab :ripple="false"
                                                            :style="`background-color: ${hexToRGBA(getActiveTheme.colors.light_gray_2, 0.1)}`"
                                                            @click="openEditPurchase(item)">
                                                            <v-icon size="18">mdi-pencil</v-icon>
                                                        </v-btn>

                                                        <ConfirmModal 
                                                            async
                                                            :title="`Delete Purchase`"
                                                            :processing="deleting"
                                                            class="mx-2"
                                                            @confirm="handleDelete(item.id)">
                                                            <template v-slot:activator="{on}">
                                                                <v-btn
                                                                    depressed
                                                                    max-width="40"
                                                                    max-height="40"
                                                                    fab :ripple="false"
                                                                    :style="`background-color: ${hexToRGBA(getActiveTheme.colors.light_gray_2, 0.1)}`"
                                                                    @click.stop="on"> 
                                                                    <v-icon size="18">mdi-delete</v-icon>
                                                                </v-btn>
                                                            </template>
                                                        </ConfirmModal>
                                                   </div>
                                                </td>
                                            </tr>
                                        </tbody>

                                    </template>

                                

                            </v-data-table>

                            <div class="pa-2 mt-n2 white"></div>

                            <div class="d-flex justify-end">
                                <v-btn depressed large color="secondary" @click="openAddPurchase">
                                    <div class="px-4">
                                        Add a Purchase
                                    </div>
                                </v-btn>
                            </div>

                        </v-col>
                    </v-row>
                </v-container>
            </v-card-text>

            <v-card-actions class="mt-n4">
                <v-spacer></v-spacer>
                <v-card flat tile
                    class="rounded-xl py-2"
                    :style="`background-color: ${hexToRGBA(getActiveTheme.colors.secondary, 0.1)}`"
                    :class="vb.mdAndUp ? 'px-10 mx-2' : ''"
                    :width="vb.mdAndUp ? '' : '100%'">
                        <v-card-text>
                            <div class="body-1 text-right pb-2">Total Required Loan Amount</div>
                            <h3 class="font-size-24 text-right">{{ requiredLoanAmount | currency }}</h3>
                        </v-card-text>
                </v-card>
            </v-card-actions>

        </v-card>

        <!-- Edit Modal -->
        <v-dialog
            max-width="844"
            persistent
            scrollable
            v-model="purchaseDialog">

            <v-card flat :disabled="purchaseDialogSaving">
                <v-progress-linear color="secondary" indeterminate stream absolute top v-if="purchaseDialogSaving"></v-progress-linear>

                    <v-card-title class="justify-end">
                        <v-btn icon
                            :style="`background-color: ${hexToRGBA(getActiveTheme.colors.secondary, 0.1)}`" 
                            @click="closeEditPurchase" fab>
                            <v-icon color="secondary">mdi-close</v-icon>
                        </v-btn>
                    </v-card-title>

                    <v-card-text>

                        <v-carousel
                            light
                            v-model="carousel"
                            :continuous="false"
                            :show-arrows="false"
                            hide-delimiters
                            touchless
                            height="auto"
                            hide-delimiter-background>

                            <v-carousel-item v-for="(step, key) in steps" :key="key">
                                
                                    <v-card flat>
                                        <v-card-title class="justify-center pt-0 mb-6">
                                            <h4 class="font-size-22">{{step.title}}</h4>
                                        </v-card-title>

                                        <v-card-text>

                                            <template v-if="key == 'purchase-asset-type'">
                                                <PurchaseTypeSelector 
                                                    :purchases-kinds="purchaseKinds"
                                                    :selected="assetType"
                                                    @select="handleSelectPurchaseAssetType"
                                                />
                                            </template>

                                            <template v-if="key == 'purchase-asset-sub-type'">
                                                <PurchaseTypeSelector 
                                                    :purchases-kinds="filteredSubType"
                                                    :selected="assetSubType"
                                                    @select="handleSelectPurchaseAssetType"
                                                />
                                            </template>

                                            <template v-if="key == 'condition'">
                                                <FormCondition 
                                                :ref="step['form-ref']"
                                                :updater="formUpdater"
                                                :data="selectedPurchase"
                                                @select="handlePurchaseConditionSelect" />
                                            </template>

                                            <template v-if="key == 'asset-details'">
                                                <FormAssetDetails 
                                                :updater="formUpdater"
                                                :data="selectedPurchase"
                                                :purchase-kind="purchaseKind"
                                                :ref="step['form-ref']" 
                                                @submit="handleAssetDetailsSubmit" />
                                
                                            </template>

                                            <template v-if="key == 'purchase-details'">
                                            <FormPrchaseDetails 
                                                :updater="formUpdater"
                                                :purchase-kind="purchaseKindForAdding"
                                                :data="selectedPurchase"
                                                :ref="step['form-ref']" 
                                                @submit="handlePurchaseDetailsSubmit" />

                                            </template>

                                        </v-card-text>

                                        <v-card-text v-if="assetType && assetType.id == '9-3' && carousel == 1" class="py-0 my-0 text-center">
                                            <v-btn v-if="subTypeLimit === 6" text class="primary" @click="showMore">
                                                More Options
                                                <v-icon
                                                    right
                                                    dark
                                                >
                                                    mdi-chevron-down
                                                </v-icon>
                                            </v-btn>
                                            <v-btn v-else text class="primary" @click="showLess">
                                                Less Options
                                                <v-icon
                                                    right
                                                    dark
                                                >
                                                    mdi-chevron-up
                                                </v-icon>
                                            </v-btn>
                                        </v-card-text>

                                    </v-card>
                            </v-carousel-item>



                        </v-carousel>

                    </v-card-text>


                    <v-card-actions class="pt-4 pb-8 justify-center">

                        <v-btn color="light_gray_2" 
                            v-if="!isFirstStep"
                            class="flex-back-button" 
                            :min-width="140" large outlined 
                            elevation="0" nuxt @click="handleBack"> Back
                        </v-btn>
                        <v-btn color="secondary" :min-width="140" 
                            v-if="showPurchaseDialogNextButton"
                            large elevation="0" 
                            nuxt @click="handleNext"> {{ isLastStep ? 'Save' : 'Next' }}
                        </v-btn>

                    </v-card-actions>


            </v-card>
        </v-dialog>

    </FlexWrap>
</template>

<script>
    /**
     * REQUIRED!
     */
    import subscriptions from '~/components/shared/subscriptions'  
    import { mapActions, mapGetters } from 'vuex'
    import FlexWrap from '~/components/base/FlexWrap'
    import {store, actions} from '~/components/v1/PurchaseEditor6/__store'
    import PurchaseTypeSelector from '~/components/v1/PurchaseEditor6/__PurchaseTypeSelector'
    import FormCondition from '~/components/v1/PurchaseEditor6/_FormCondition'
    import FormAssetDetails from '~/components/v1/PurchaseEditor6/_FormAssetDetails'
    import FormPrchaseDetails from '~/components/v1/PurchaseEditor6/_FormPurchaseDetails'
    import ConfirmModal from '~/components/base/ConfirmModal2'

    export default {
        mixins: [subscriptions],
        components: {
            FlexWrap, 
            PurchaseTypeSelector,
            FormCondition,
            FormAssetDetails,
            FormPrchaseDetails,
            ConfirmModal
        },
        data(){
            return {
                purchaseKindForAdding: '',
                subTypeLimit: 6,
                deleting: false,
                headers: [
                    { text: 'Purchase Details', sortable: false, value: 'purchase-details', width: '40%' },
                    { text: 'Purchase Type', sortable: false, value: 'purchase-type', width: '30%' },
                    { text: 'Purchase Price', sortable: false, value: 'purchase-price', width: '15%' },
                    { text: 'Loan Required', sortable: false, value: 'loan-required', width: '15%' },
                    { text: '', sortable: false, value: 'actions', width: '0%' }
                ],
                sizes: null,
                purchaseDialog: false,
                purchaseDialogSaving: false,

                purchasesTableData: [],
                selectedPurchase: null
            }
        },
        created() {

        },
        computed:{
            ...mapGetters('kinds', ['kinds', 'kindsUnformat']),
            getLoanId(){
                return this.appData?.relationships?.loan?.data ? this.appData.relationships.loan.data.id : ''
            },
            purchaseKinds() {
                let kinds = this.kinds.find( kind => {
                    return kind.name == 'Purchase'
                })
                return kinds ? kinds.children : []
            },
            purchaseKind() {
                return this.selectedPurchase?.relationships?.kind?.data?.id
            },
            ...mapGetters('themes', [
                'getActiveTheme'
            ]),
            carousel: {
                get(){
                    return store.carousel
                },
                set(value){
                    actions.setCarousel(value)
                }
            },
            steps(){
                return store.steps
            },
            assetType(){
                return store.assetType
            },
            assetSubType(){
                return store.assetSubType
            },
            assetSubTypeList(){

                if(this.assetType && this.assetType.children.length > 0){
                    return this.assetType.children
                }

                return []

            },
            showPurchaseDialogNextButton(){
                
                if(this.carousel < 3){
                    return false
                }

                return true
            },
            isLastStep(){
                if(this.carousel == (Object.keys(this.steps).length - 1)){
                    return true
                }

                return false
            },
            isFirstStep(){
                if(this.carousel == 0){
                    return true
                }

                return false
            },
            formUpdater(){
                return this.carousel
            },
            requiredLoanAmount() {
                let total = 0

                if(this.purchasesTableData.length > 0) {
                    this.purchasesTableData.forEach(purchase => {
                        let loanRequired = purchase?.attributes?.['required-loan-amount'] ? purchase.attributes['required-loan-amount'] : 0

                        total = total + parseInt(loanRequired)
                    })
                }

                return total
            },
            filteredSubType() {
                let { assetType, assetSubTypeList} = this
                let newList = []

                if(assetSubTypeList.length > 0) {
                    // if leisure goods reorder the sub type list
                    if(assetType && assetType.id === '9-3') {

                        let orders = [
                            '9-12',
                            '9-14',
                            '9-7',
                            '9-6',
                            '9-5',
                            '9-9',
                            '9-4',
                            '9-11',
                            '9-8',
                            '9-10',
                            '9-13',
                        ]
                        
                        orders.forEach(order => {
                            assetSubTypeList.forEach(list => {
                                if(order == list.id) {
                                    newList.push(list)
                                }
                            })
                        })

                        // filter size
                        newList.length = this.subTypeLimit

                    // if heavy vehicles reorder the sub type list
                    } else if(assetType && assetType.id === '9-15') {
                        
                        let orders = [
                            '9-19',
                            '9-18',
                            '9-20',
                            '9-16',
                            '9-17',
                        ]
                        
                        orders.forEach(order => {
                            assetSubTypeList.forEach(list => {
                                if(order == list.id) {
                                    newList.push(list)
                                }
                            })
                        })
                    // if other equipment reorder the sub type list
                    } else if(assetType && assetType.id === '9-25') {
                        
                        let orders = [
                            '9-28',
                            '9-27',
                            '9-29',
                            '9-32',
                            '9-26',
                            '9-30',
                            '9-33',
                            '9-31',
                        ]
                        
                        orders.forEach(order => {
                            assetSubTypeList.forEach(list => {
                                if(order == list.id) {
                                    newList.push(list)
                                }
                            })
                        })

                    } else {
                        newList = assetSubTypeList
                    }
                }

                return newList
            },
            tableHeaders(){
                
                if(!this.sizes) return

                const {sm, xs} = this.sizes

                let newHeaders = [
                    ...this.headers
                ]

                let heads = [
                    {value: 'purchase-details', width: '20%'},
                    {value: 'purchase-type', width: '20%'},
                    {value: 'purchase-price', width: '30%'},
                    {value: 'loan-required', width: '15%'},
                    {value: 'actions', width: '10%'}
                ]


                if(sm){
                    heads = [
                        {value: 'purchase-details', width: '20%'},
                        {value: 'purchase-type', width: '20%'},
                        {value: 'purchase-price', width: '30%'},
                        {value: 'loan-required', width: '15%'},
                        {value: 'actions', width: '10%'}
                    ]
                }

                if(xs){
                    heads = []
                }
                
                return newHeaders.reduce((accumulator, current) => {

                    heads.forEach(head => {

                        if(head.value == current.value){

                            let newCurrent = {...current}

                            newCurrent.width = head.width

                            accumulator.push(newCurrent)
                        }

                    })

                    return accumulator 

                }, [])

            }
        },
        methods: {
            ...mapActions('resource-actions', [
                'getApplicationLoan',
                'updateLoan',
                'createLoan',
                'getPurchases',
                'deletePurchase',
                'createPurchase',
                'updatePurchases',
                'getAutoImages'
            ]),
            isVisible(key){
                
                const {visible} = this

                if(visible.hasOwnProperty(key)){
                    return visible[key]
                }

                return true

            },     
            async pull(){
        
                try {

                    // let loanRes = this.getApplicationLoan(this.getLoanId)
                    let purchasesRes = this.getPurchases()

                    // let responses =  await Promise.all([loanRes, purchasesRes])
                    let responses =  await Promise.all([purchasesRes])

                    // let loan = responses[0]
                    let purchases = responses[0]

                    this.purchasesTableData = purchases.data.data

                } catch (error) {

                    console.log('Ooops!..', error)

                }

            },

            async push(){

                if (this.getLoanId) {

                    let payload = {
                        type: 'loans',
                        id: this.getLoanId,
                        attributes: {
                            "requested-required-loan-amount": this.requiredLoanAmount
                        }
                    }

                    await this.updateLoan(payload)
                }
                
                return {valid:true, status:'valid', data:{}}

            },

            showMore() {
                this.subTypeLimit = 11
            },

            showLess() {
                this.subTypeLimit = 6
            },

            async submitEditForm(purchase){

            },
            async openAddPurchase(){
                this.selectedPurchase = null
                this.purchaseDialog = true
            },
            async openEditPurchase(purchase){

                this.calculateAssetAndSubType(purchase)

                this.purchaseDialog = true
                this.selectedPurchase = purchase
            },
            async closeEditPurchase(){

                this.purchaseDialog = false
                this.selectedPurchase = null

                if(this.$refs.conditionForm && this.$refs.conditionForm[0]) {
                    await this.$refs.conditionForm[0].reset()
                }

                if(this.$refs.assetDetailsForm && this.$refs.assetDetailsForm[0]) {
                    await this.$refs.assetDetailsForm[0].reset()
                }
                    
                if(this.$refs.purchaseDetailsForm && this.$refs.purchaseDetailsForm[0]) {
                    await this.$refs.purchaseDetailsForm[0].reset()
                }

                actions.resetStore()
            },
            calculateAssetAndSubType(purchase){

                // console.log(purchase)

                if(purchase == null) {
                    return;
                }

                if(purchase.relationships.kind.data == null) {
                    return;
                }

                let kindId = purchase.relationships.kind.data.id


                let kindData = this.kindsUnformat.find(kind => {
                    return kind.id == kindId
                })

                // console.log('kindData', kindData)

                if(kindData) {

                    if(kindData.relationships.parent.data) { // has parent

                        let parentId = kindData.relationships.parent.data.id;

                        // get top level parent or grand parent ex: Purchases or Yellow Goods 
                        let parentKind = this.kindsUnformat.find(kind => { 
                            return kind.id == parentId
                        })

                        // console.log('parentKind', parentKind)
            
                        // if parent id is null means it is top level ex:  Yellow Goods , Leisure Goods
                        if(parentKind.relationships.parent.data == null) { 

                            let kind = this.purchaseKinds.find( kind => {
                                return kind.id == kindId
                            })

                            actions.setAssetType(kind)
                            actions.setAssetSubType(null)

                        }  else if (parentKind.relationships.parent.data.id == null) {

                            let kind = this.purchaseKinds.find( kind => {
                                return kind.id == kindId
                            })

                            actions.setAssetType(kind)
                            actions.setAssetSubType(null)
                        
                        } else {

                            let parent = this.purchaseKinds.find( kind => {
                                return kind.id == parentKind.id
                            })

                            let child = parent.children.find( kind => {
                                return kind.id == kindId
                            })

                            actions.setAssetType(parent)
                            actions.setAssetSubType(child)
                        }

                    }
                }

            },

            handleBack(){

                let stepsArray = Object.keys(this.steps)

                if(stepsArray[this.carousel] == 'condition' && !this.assetSubType) {

                        actions.setCarousel(0)

                } else {

                    this.carousel--

                }

            },

            async handleDelete(id){
                this.deleting = true

                await this.deletePurchase(id)

                this.deleting = false
                await this.pull()
            },  

            async handleNext(){

                let stepsArray = Object.keys(this.steps)

                switch (stepsArray[this.carousel]) {

                    case "asset-details":

                        if(this.$refs.assetDetailsForm[0]) {

                            await this.$refs.assetDetailsForm[0].validate()

                        }
                            
                    break;
                    case "purchase-details":

                        if(this.$refs.purchaseDetailsForm[0]) {

                            await this.$refs.purchaseDetailsForm[0].validate()

                        }
                            
                    break;
                    default:
                        
                        this.carousel++


                    break;
                }
                

            },

            async handleSelectPurchaseAssetType(payload){

                let kind = payload.kind
                this.purchaseKindForAdding = payload.kind.id
                this.purchaseDialogSaving = true

                /**
                0 "purchase-asset-type"
                1 "purchase-asset-sub-type"
                2 "condition"
                3 "asset-details"
                */

                if(payload.isParent) {

                    actions.setAssetType(kind)

                    if(kind.children.length > 0) {
                        // if kind has only one children
                        if(kind.children.length === 1) {

                            actions.setAssetType(kind.children[0])
                            actions.setCarousel(2)

                        } else {

                            actions.setCarousel(1)

                        }

                    } else {

                        actions.setAssetSubType(null)
                        actions.setCarousel(2)
                    }

                } else {
                    actions.setAssetSubType(kind)
                    actions.setCarousel(2)

                }

                this.purchaseDialogSaving = false

            },
            async handlePurchaseConditionSelect(payload){

                const {assetType, assetSubType} = this
                this.purchaseDialogSaving = true

                // Relate kinds
                if(assetType) {

                    let kind = {
                        "data": {
                            "type": "kinds",
                            "id": null
                        }
                    }

                    if(assetSubType) {
                        kind.data.id = assetSubType.id
                    } else {
                        kind.data.id = assetType.id
                    }

                    payload.relationships.kind = kind
                }
                
                if(payload.attributes.condition) {

                    if(payload.hasOwnProperty('id')) {
                        
                        let res = await this.updatePurchases(payload)

                        if(res.status == 200) {
                            this.selectedPurchase = res.data.data
                            actions.setCarouselNext()
                        }

                    } else {

                        let res = await this.createPurchase(payload)

                        if(res.status == 201) {
                            this.selectedPurchase = res.data.data
                            actions.setCarouselNext()
                        }
                        
                    }

                    // Update table data
                    await this.pull()
                }

                this.purchaseDialogSaving = false

            },
            async handleAssetDetailsSubmit(payload){
                this.purchaseDialogSaving = true

                if(payload.hasOwnProperty('id')) {
                    
                    let res = await this.updatePurchases(payload)

                    if(res.status == 200) {
                        actions.setCarouselNext()
                    }

                } else {

                    let res = await this.createPurchase(payload)

                    if(res.status == 201) {
                        actions.setCarouselNext()
                    }
                    
                }

                // Update table data
                await this.pull()

                this.purchaseDialogSaving = false

            },
            async handlePurchaseDetailsSubmit(payload){
                this.purchaseDialogSaving = true

                if(payload.hasOwnProperty('id')) {
                    
                    let res = await this.updatePurchases(payload)

                    if(res.status == 200) {
                        this.closeEditPurchase()
                    }

                } else {

                    let res = await this.createPurchase(payload)

                    if(res.status == 201) {
                        this.closeEditPurchase()
                    }
                    
                }

                // Update table data
                await this.pull()

                this.purchaseDialogSaving = false

            },
            getAssetKind(purchase){

                let fkind = purchase?.relationships?.kind.data?.id ? purchase.relationships.kind.data : 0
                let filteredKind = null

                this.purchaseKinds.forEach( kind => {
                    if ( kind.children?.length ) {
                        return kind.children.find( child_kind => {
                            if ( fkind.id === child_kind.id) {
                                filteredKind = child_kind
                            }
                        })
                    }
                    else {
                        if ( kind.id === fkind.id )
                            filteredKind = kind
                    }
                })

                return filteredKind?.name
            },
            getPurchaseDescription(purchase) {

                let fkind = purchase?.relationships?.kind.data?.id ? purchase.relationships.kind.data : 0
                let filteredKind = null
                let filteredParent = null

                this.purchaseKinds.forEach( kind => {
                    if ( kind.children?.length ) {
                        return kind.children.find( child_kind => {
                            if ( fkind.id === child_kind.id) {
                                filteredParent = kind
                                filteredKind = child_kind
                            }
                        })
                    }
                    else {
                        if ( kind.id === fkind.id )
                            filteredKind = kind
                    }
                })

                if ( filteredKind?.name === 'Motor Vehicle') {
                    let desc = `${purchase.attributes['year-of-manufacture']} ${this.$options.filters.formalAndCapitalize(purchase.attributes['make'])} ${purchase.attributes['model']}`
                    return desc.indexOf('null') >= 0 ? `Motor Vehicle (${purchase.attributes['year-of-manufacture'] ? purchase.attributes['year-of-manufacture'] : '--'})` : desc
                }
                
                if ( filteredParent?.name === filteredKind?.name){
                    return filteredKind?.name
                }

                return `${filteredParent?.name} - ${filteredKind?.name}`
            },
            hexToRGBA(hex, a = 1){
                return this.$options.filters.hexToRGBA(hex, a)
            },
            handleResize(sizes){
                this.sizes = sizes

            }
        }
    }
</script>

<style scoped lang="scss">
    .table-card > .v-data-table__wrapper > table {
        border-collapse:separate; 
        border-spacing: 0 1em !important;
    }

    .table-card > .v-data-table__wrapper > table .table-card-tr {
        height: 5rem;
        border-width: 1px !important;
        border-style: solid !important;
        border-color: var(--v-light_gray_1-darken1) !important;

        td {
            border-width: 1px !important;
            border-style: solid !important;
            border-color: var(--v-light_gray_1-darken1) !important;
            border-left: none !important;
            border-right: none !important;
        }

        .actions {
            .v-size--small {
                border-radius: 50%;
                height: 40px !important;
            }
        }

        td:first-child {
            border-bottom-left-radius: 1em;
            border-top-left-radius: 1em;
            border-left-width: 1px !important;
            border-left-style: solid !important;
            border-right: none !important;
            border-color: var(--v-light_gray_1-darken1) !important;
        }

        td:last-child {
            border-bottom-right-radius: 1em;
            border-top-right-radius: 1em;
            border-right-width: 1px !important;
            border-right-style: solid !important;
            border-color: var(--v-light_gray_1-darken1) !important;
        }

        &:hover {
            td:first-child {
                border-bottom-left-radius: 1em !important;
                border-top-left-radius: 1em !important;
                border-left-width: 1px !important;
                border-left-style: solid !important;
                border-right: none !important;
                border-color: var(--v-light_gray_1-darken1) !important;
            }

            td:last-child {
                border-bottom-right-radius: 1em !important;
                border-top-right-radius: 1em !important;
                border-right-width: 1px !important;
                border-right-style: solid !important;
                border-color: var(--v-light_gray_1-darken1) !important;
            }
        }
    }

    .flex-table-row {
        & > div {

            &:nth-child(1) {
                width: 40%;
                padding-right: 10px;
            }

            &:nth-child(2) {
                width: 30%;
                padding-right: 10px;
            }

            &:nth-child(3) {
                width: 20%;
            }

            &:nth-child(4) {
                width: 15%;
            }

            &:nth-child(5) {
                width: 15%;
            }

        }
    }

</style>
