<template>
    <v-card class="mx-auto" tile flat>
        <v-card-text class="pa-4 pt-0 pa-md-10 pt-md-0">
            <ValidationObserver ref="observer" tag="form">
                <div class="flex-form">
                    <div class="flex-field">
                        <ValidationProvider
                            v-slot="{ errors }"
                            :name="labels['term']"
                            :rules="getRules('term')"
                            :vid="'term'">
                            
                            <v-select
                                v-model="resources.loan.attributes.lender.moneyPlace['term']"
                                :items="getOptions('term')"
                                :error-messages="errors"
                                color="secondary"
                                :label="labels['term']"
                            ></v-select>
                        </ValidationProvider>
                    </div>
                    
                    <div v-if="isPersonalLoanReasonOther" class="flex-field">
                        <ValidationProvider
                            v-slot="{ errors }"
                            :name="labels['purpose']"
                            :rules="getRules('purpose')"
                            :vid="'purpose'">
                            
                            <v-select
                                v-model="resources.loan.attributes.lender.moneyPlace['purpose']"
                                :items="getOptions('purpose')"
                                item-text="key"
                                item-value="value"
                                :error-messages="errors"
                                color="secondary"
                                :label="labels['purpose']"
                            ></v-select>
                        </ValidationProvider>
                    </div>

                    <div class="flex-field" v-if="isAssister">
                        <ButtonGroup1
                            v-model="hasEstimateId"
                            :error-messages="errors"
                            :options="[ 'Yes','No']"
                            label="Do you have an estimate ID?"
                        />
                    </div>
                   
                    <div class="flex-field" v-if="hasEstimateId === 'Yes' && isAssister">
                        <ValidationProvider
                            v-slot="{ errors }" 
                            :name="labels['estimateId']"
                            :rules="getRules('estimateId')"
                            :vid="'estimateId'">
                            <v-text-field
                                v-model="resources.applications.attributes.lender.moneyPlace['estimateId']"
                                tile
                                required
                                :error-messages="errors"
                                color="secondary"
                                :label="labels['estimateId']"
                            ></v-text-field>
                        </ValidationProvider>
                    </div>

                    <div class="flex-field" v-else></div>
                    <div class="flex-field"></div>
                </div>
            </ValidationObserver>

            <v-alert dense outlined type="error" v-if="errors.length > 0">
                <div v-for="msg in errors" v-html="msg"></div>
            </v-alert>

        </v-card-text>
    </v-card>
</template>

<script>
import { mapActions, mapState, mapMutations } from 'vuex'
import helpers from '~/plugins/helpers'
import subscriptions from '~/components/shared/subscriptions'
import lenderFields from '~/components/v1/submission/Customisations1/__lenders'
import ButtonGroup1 from '~/components/base/ButtonGroup1'
import NodifiMoney from '~/components/base/NodifiMoney'

export default {
    name: 'MoneyPlaceCustomizations1',
    components: { ButtonGroup1 , NodifiMoney},
    mixins: [subscriptions, helpers],
    data() {
        return {
            hasEstimateId: 'No',
            resources: {
                loan: {
                    type: 'loans',
                    id: null,
                    attributes: {
                        // see lenderFields
                        "personal-loan-reason": null,
                        "lender": {
                            "moneyPlace": {
                                "purpose": null,
                                "term": null,
                            }
                        }
                    }
                },
                applications: {
                    type: 'applications',
                    id: null,
                    attributes: {
                        // see lenderFields
                        "personal-loan-reason": null,
                        "lender": {
                            "moneyPlace": {
                                'estimateId': null
                            }
                        }
                    }
                }

            },
            options: {
                'term': [3,5,7],
                'purpose': [
                    {key: 'Debt Consolidation', value: 'debt consolidation'},
                    {key: 'New Car', value: 'new car'},
                    {key: 'Used Car', value: 'used car'},
                    {key: 'Home Improvement', value: 'home improvement'},
                    {key: 'Medical', value: 'medical'},
                    {key: 'Travel', value: 'travel'},
                    {key: 'Educational', value: 'educational'},
                    {key: 'Wedding', value: 'wedding'},
                    {key: 'Tax Debt', value: 'tax debt'},
                    {key: 'Personal Investment', value: 'personal investment'},
                    {key: 'Gap Finance', value: 'gap finance'},
                    {key: 'Motorbike', value: 'motorbike'},
                    {key: 'Boat', value: 'boat'},
                    {key: 'Caravan', value: 'caravan'},
                    {key: 'Trailer', value: 'trailer'},
                    {key: 'Home Furnishing', value: 'home furnishing'},
                    {key: 'Solar and Renewable Energy', value: 'solar and renewable energy'},
                    {key: 'Vehicle Maintenance', value: 'vehicle maintenance'},
                ],
            },
            labels: {
                "estimateId": "Moneyplace ID",
                "term": "Loan Term",
                "purpose": "Purpose",
            },
            validation_rules: {
                type: "loans",
                attributes: {
                    "term": "required|in:3,5,7",
                    "purpose": "required",
                    "estimateId": "required",
                }
            },
            fieldLayout: [
                // see lenderFields
            ],
            terms: [],
            people: [],
            checkboxes: [],
            errors: [],

        }
    },
    computed: {
        ...mapState({
            appId: state => state.flows.appId,
            loanData: state => state.flows.loanData,
            termGroup: state => state.flows.termGroup,
            commitments: state => state.flows.commitments,
        }),
        isAssister() {
            return this.appData.attributes.target == 'Assister'
        },
        getLoanId(){
            return this.appData.relationships.loan.data.id
        },
        ...mapState('auth-actions', [
                'user'
        ]),
        isCommitmentExists(){
            let exists = false

            if(this.commitments && this.terms.length > 0){

                return true

            }

            return exists
        },
        isPersonalLoanReasonOther() {
            return this.resources.loan.attributes['personal-loan-reason'] === 'Other'
        },
        estimateIdExists(){
            return this.resources.applications.attributes.lender.moneyPlace.estimateId
        }
    },
    methods: {
        ...mapActions('resource-actions', [
            'getLoan',
            'getTerms',
            'updateLoan',
            'updateApplication',
            'getPeople',
            'getCommitments',
            'createCommitments',
        ]),
        ...mapMutations('flows', [
            'setCommitments'
        ]),
        async pull() {

            let promise3 = this.getLoan(this.getLoanId)

            const res = await Promise.all([
                promise3
            ]) 

            promise3 = res[0]

            if(promise3.status == 200) {

                this.resources.loan.attributes['personal-loan-reason'] = promise3.data.data.attributes['personal-loan-reason']

                if(promise3.data.data.attributes.lender?.['moneyPlace']) {
                    this.resources.loan.attributes.lender.moneyPlace['purpose'] = promise3.data.data.attributes.lender['moneyPlace']['purpose']
                    this.resources.loan.attributes.lender.moneyPlace['term'] = promise3.data.data.attributes.lender['moneyPlace']['term']
                }

            }

            this.resources.applications.attributes.lender.moneyPlace.estimateId = JSON.parse(this.appData.attributes?.lender)?.moneyPlace?.estimateId

        },

        async push() {

            const observer = this.$refs.observer
            const {valid, status} = await this.validateFormData()
            let loanRes = null
            let appRes = null

            if(valid) {
                
                const {loan, applications} = this.resources
                    
                // Exclude resources.relationships and other stuff

                let payload = {
                    type: loan.type,
                    id: this.getLoanId,
                    attributes: {
                        "lender": {
                            "moneyPlace": {
                                "term": loan.attributes.lender.moneyPlace['term'],
                            }
                        }
                    }
                }
                let payloadApp = {
                    type: applications.type,
                    id: this.appId,
                    attributes: {
                        "lender": {
                            "moneyPlace": {
                                "estimateId": applications.attributes.lender.moneyPlace['estimateId'] || null,
                            }
                        }
                    }
                }

                if(this.isPersonalLoanReasonOther) {
                    payload.attributes.lender.moneyPlace.purpose = loan.attributes.lender.moneyPlace['purpose']
                }

                appRes = await this.updateApplication(payloadApp)
                loanRes = await this.updateLoan(payload)

            }

            const data = await this.appDataHelper([loanRes])


            // Validate Checkboxes

            this.checkCompleted()

            return {valid, status, data}

        },

        isBtnGroup(key){

            let keys = [
                "submission-required",
                "financing-insurance"
            ]

        return keys.includes(key)

        },
        isIncluded(field){

            const {included} = this

            if(included.hasOwnProperty(field)) {
                return included[field]
            }

            return true
            },
        isReadOnly(key){

            const {readonly} = this

            if(readonly.hasOwnProperty(key)){
                return readonly[key]
            }

            return false

        },
        isNumber(key){
            return [
                "requested-balloon",
                "actual-broker-fee",
            ].includes(key)
        },
        checkCompleted(){
            this.errors = []

            if(this.checkboxes.length != this.terms.length) {
                this.errors.push('Please accept all terms to proceed')
            } 
        },
    },
    watch: {
        checkboxes(val) {
            if(val.length == this.terms.length) {
                this.errors = []
            }
        },
        estimateIdExists(val){
            console.log(val)
            if(val){
                this.hasEstimateId = 'Yes'  
            }
        },
    }
}
</script>

<style>
    .debug {
        border: 1px solid red;
    }
</style>

