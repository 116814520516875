import { mapState, mapActions, mapGetters, mapMutations } from 'vuex'
import subscriptions from '~/components/shared/subscriptions'
import resourceHelpers from '~/components/shared/resource-helpers'
import {store, actions} from '~/components/v1/LiabilityEditor1/__store'
import liabilityKinds from '~/components/v1/LiabilityEditor1/kind-logic'
import StepperFieldLayouts from '~/components/v1/LiabilityEditor1/field-layout'
import moment from 'moment'

export default {
    mixins: [resourceHelpers, subscriptions],
    computed: {
        ...mapGetters('kinds', ['kinds', 'kindsUnformat']),
        ...mapGetters('kinds', ['getKindsLiability']),
        ...mapState({
            development: state => {
                return state.development
            },
            primaryApplicant: state => state.flows.primaryApplicant,
        }),
        ...mapGetters({
            getActiveTheme: 'themes/getActiveTheme',
        }),  
        getLoanId(){
            return this.appData?.relationships?.loan?.data?.id
        },
        getRequiredKindsByName(){

            const refs = [
                    'Applicant',
                    'Director',
                    'Guarantor',
                    'Non-Borrowing Spouse',
                    'Partner',
                    'Trustee']
            
            return this.kindsUnformat.filter( kind => {
                return refs.includes(kind.attributes.name)
            })

        },
        vb(){
            return this.$vuetify.breakpoint
        },
        loanType(){
            return store.loanType
        },
        appType(){
            return store.appType
        },
        stepper(){
            return store.stepper
        },
        steps(){
            return store.steps
        },
        currentStep(){
            return store.currentStep
        },
        isLastStep(){

            if(store.steps.length == store.stepper + 1) return true

            return false
        },
        isFirstStep() {
            return this.stepper === 0
        },
        currencyFields(){
            return [
                'asset-value',
                'amount-owing',
                'profit-before-tax-annual',
                'revenue',
                'profit-before-tax-annual',
                'net-monthly-amount',
                'benefit-amount',
                'net-standard-pay',
                'credit-limit',
                'amount-financed',
                "living-food-and-clothing",
                "living-transport",
                "living-utilities-and-rates",
                "living-lifestyle",
                "living-tv-and-communications",
                "living-child-care-fees",
                "living-family-maintenance",
                "living-insurance",
                "living-education",
                "living-other-living-expenses",
                'payment-amount',
                '__sum-living-expenses',
                '__monthly-payment',
            ]
        },
        selectedEntity: {
            get(){
                return store.selectedEntity
            },
            set(value){
                actions.setSelectedEntity(value)
            }
        },
        people(){
            return store.resources.people
        },
        addresses(){
            return store.allAddresses
        },
        businesses(){
            return store.resources.businesses
        },
        entities(){
            return store.entities
        },
        filterEntities() {
            // let entities = []
            // // if commercial
            // if (store.loanType == 'Commercial') {

            //     this.entities.forEach(entity => {
            //         if (entity.type != 'businesses') {
            //             entities.push(entity)
            //         }
            //     })

            //     return entities
            // } else {
            //     return this.entities
            // }
            return this.entities
        },
        entityParts(){
            return store.entityParts
        },
        liabilities(){
            return store.resources.liabilities
        },
        expenses(){
            return store.resources.expenses
        },
        isBusiness(){
            if(store.selectedEntityData){
                return store.selectedEntityData.type == 'businesses'
            }

            return false
        },
        preSelectRoles: {
            get(){
                return store.preSelectRoles
            },
            set(value){
                actions.setPreSelectRoles(value)
            }
        },
        buildList() {
            const { getKindsLiability } = this

            let list = []

            if(getKindsLiability.children) {

                let filterKindsLiabilty = this.filterKindsLiability(getKindsLiability.children)

                if(filterKindsLiabilty.length > 0) {
                    filterKindsLiabilty.forEach(child => {
                        list.push({
                            'id': child.id,
                            'name': child.name,
                            'children': child.children,
                            'data': this.getChildrenData(liabilityKinds['kinds'][child.id])
                        })
                    })
                }

            }

            return list
        },
        roleLiability() {
            return store.roleLiability
        },
        kindsLiabilityId() {
            return store.kindsLiabilityId
        },
        selectedIndex() {
            return store.selectedIndex
        },
        routeParams(){
            return this.$route.params
        },
        linkExpenses(){
            return store.linkExpenses
        },
        totalLiabilities() {
            const { liabilities } = this

            let total = 0

            if(liabilities.length > 0) {
                liabilities.forEach(liability => {
                    if(liability.attributes['amount-owing']) {
                        total = total + parseFloat(liability.attributes['amount-owing'])
                    }
                })
            }


            return total
        },
    },
    methods: {
        ...mapActions('resource-actions', [
            'getPeople',
            'getAddresses',
            'getBusinesses',
        ]),
        async updateEntitiesAndParts(){

                let promise1 = this.getPeople({"include" : 'parent-parts,liabilities,expenses'})
                let promise2 = this.getBusinesses({"include" : 'parent-parts,liabilities,expenses'})
                let promise3 = this.getAddresses()

                const res = await Promise.all([promise1, promise2, promise3]) 

                let requiredResources = this.getRequiredResources([res[0].data, res[1].data])

                let people = this.getRequiredPeopleByRoles(res[0].data, res[1].data)
                let businesses = this.getRequiredBusinessesByRoles(res[1].data)
                
                let parts = this.getRequiredParts([res[0].data, res[1].data])
                let addresses = this.getAllAddresses(res[2].data)

                actions.setPeople(people)
                actions.setBusinesses(businesses)
                actions.setAllAddresses(addresses)
                actions.setResources(requiredResources)

                let entities = [
                    ...businesses,
                    ...people
                ]

                actions.setEntities(entities)
                actions.setEntityParts(parts)

        },
        getChildrenData(kinds) {
            const { roleLiability, liabilities } = this

            let childLiabilities = []
            const parsKinds = kinds.split('|')

            if(this.routeParams?.record_id) {

                roleLiability.forEach(liability => {
                    if(parsKinds.includes(liability.relationships.kind.data.id)) {
                        childLiabilities.push(liability)
                    }
                })
    
            } else {

                liabilities.forEach(liability => {
                    if(parsKinds.includes(liability.relationships.kind.data.id)) {
                        childLiabilities.push(liability)
                    }
                })

            }

            return childLiabilities

        },
        filterKindsLiability(children) {
            let arrangement = ['13-18', '13-8', '13-12', '13-2', '13-22']

            let newChild = []

            arrangement.forEach(arrange => {
                children.forEach(child => {
                    if(arrange == child.id) {
                        newChild.push(child)
                    }
                })
            })

            return newChild
        },
        getTotalLiabilities(liabilities) {
            let total = 0

            if(liabilities.length > 0) {
                liabilities.forEach(liability => {
                    if(liability.attributes['amount-owing']) {
                        total = total + parseFloat(liability.attributes['amount-owing'])
                    }
                })
            }

            return total
        },
        getDescriptionSub(name, data){

            switch (name) {
                case 'Investment Property':
                    return data["__full-address"]
                default:
                    return data['asset-description']
            }

        },
        hexToRGBA(hex, a = 1){
            return this.$options.filters.hexToRGBA(hex, a)
        },
        excludeInvalidAttributes(attributes) {

            let obj = {}

            Object.keys(attributes).forEach(attr => {
                if(attributes[attr]) {
                    obj[attr] = attributes[attr]
                }
            })

            return obj
        },
        isAddressField(field){
            let fields = [
                '__full-address'
            ]

            return fields.includes(field)
        },
        getRequiredPeopleByRoles(data, business = {}){

            if(!data.included){
                return [];
            }

            let parts = data.included.filter(part => {
                return part.type == 'parts'
            })

            let roleIds = [];	
            let rolesObj = {}
            
            parts.forEach(part => {
                
                if (!part.relationships.kind.data) {	
                    // person must have part with kind
                    return []
                }
                
                this.getRequiredKindsByName.forEach(kind => {

                    if(kind.id == part.relationships.kind.data.id) {

                        roleIds.push(part.id);
                        rolesObj[part.id] = kind.attributes.name

                    }

                })


            })

            if(roleIds.length == 0) {
                return []
            } 

            let people = [] 
            
            data.data.forEach( person => {

                let parentParts = person.relationships['parent-parts'].data

                person.roles = []
                
                if( parentParts.length ){

                    let included = false;

                    parentParts.forEach( part => {

                        // combine required parts
                        let parts = [...data.included]

                        if( business.included ){
                            parts =  [...business.included, ...data.included]
                        }                    
                            
                        // check parent parts is not empty until to the primary applicant
                        let parentPartsFiltered = this.filterParent(part.id, business, parts)

                        if(roleIds.includes(part.id) ) {

                            // Add extra data to person
                            person.roles.push(rolesObj[part.id])

                            // Include person once
                            if(!included && parentPartsFiltered){
                                people.push(person)
                                included = true
                            }

                        }

                    })

                }

            })

            return people 

        },
        getRequiredBusinessesByRoles(data){

            if(!data.included){
                return [];
            }

            let parts = data.included.filter(part => {
                return part.type == 'parts'
            })

            let roleIds = [];	
            let rolesObj = {}
            
            parts.forEach(part => {
                
                if (!part.relationships.kind.data) {	
                    // person must have part with kind
                    return []
                }
                
                this.getRequiredKindsByName.forEach(kind => {

                    if(kind.id == part.relationships.kind.data.id) {

                        roleIds.push(part.id);
                        rolesObj[part.id] = kind.attributes.name

                    }

                })


            })

            if(roleIds.length == 0) {
                return []
            } 

            let people = [] 
            
            data.data.forEach( person => {

                let parentParts = person.relationships['parent-parts'].data

                person.roles = []
                
                if( parentParts.length ){

                    let included = false;

                    parentParts.forEach( part => {

                        if(roleIds.includes(part.id) ) {

                            // check parent parts is not empty until to the primary applicant
                            let parentPartsFiltered = this.filterParent(part.id, data, data.included)

                            // Add extra data to person
                            person.roles.push(rolesObj[part.id])

                            // exclude role base on kind and parts
                            let isNotExcluded = false

                            let __exclude = [
                                {part: 'Trustee', kind: '5-2'}
                            ]

                            if(data.data) {
                                data.data.forEach(role => {
                                    __exclude.forEach(el => {
                                        if(role.relationships.kind.data.id == el.kind && rolesObj[part.id] == el.part) {
                                            isNotExcluded = true
                                        }
                                    })
                                })
                            }

                            // Include person once
                            if(!included && parentPartsFiltered && !isNotExcluded){
                                people.push(person)
                                included = true
                            }

                        }

                    })

                }

            })

            return people 

        },
        getRequiredParts(data = []){

            let parts = []

            data.forEach(item => {

                if(item.included){

                    let entityParts = item.included.filter(part => {
                        return part.type == 'parts'
                    })

                    parts = [
                        ...parts,
                        ...entityParts
                    ]

                }

            })

            return parts

        },
        getAllAddresses(data){

            // Filter addresses by financial objects

            if(data.data.length == 0){
                return [];
            }

            return data.data
        },
        /**
         * getRequiredAddresses: Note in use
         */
        getRequiredAddresses(data){

            // Filter addresses by financial objects

            let fos = {
                incomes: [],
                assets: [],
                expenses: [],
                liabilities: [],
            }

            if(data.data.length == 0){
                return fos;
            }

            data.data.forEach( address => {

                let keys = Object.keys(fos)

                keys.forEach(key => {

                    if(address.relationships[key] && address.relationships[key]['data'].length > 0 ){

                        fos[key].push(address)

                    }

                })

            })

            return fos
        },
        getRequiredResources(data = []){

            // Filter financial objects

            let fos = {
                assets: [],
                liabilities: [],
                expenses: [],
            }

            data.forEach(collection => {

                if(!collection.included){
                    return fos;
                }

                let keys = Object.keys(fos)

                collection.included.forEach( item => {
                    if( keys.includes(item.type) ){
                        fos[item.type].push(item)
                    }
                })

            })

            if(fos.liabilities.length > 0) {
                let ids = fos.liabilities.map(liabilty => liabilty.id)
                fos.liabilities = fos.liabilities.filter(({id}, index) => !ids.includes(id, index + 1))
            }
            
            return fos
        },
        currency(val){
            return this.$options.filters.currency(val)
        },
        getFullAddress(address){

            if(!address) return "";

            if(!address.hasOwnProperty('attributes')) return "";
            
            return  `${address.attributes["street-number"]} ${address.attributes["street-name"]} ${address.attributes["street-type"]}, ${address.attributes["suburb"]} ${address.attributes["state"]} ${address.attributes["postcode"]}`

        },
        getFriendlyName(entity){

            let fullname = ''

            if(entity.type == 'businesses') {

                fullname += entity.attributes['business-legal-name'] ? entity.attributes['business-legal-name'] : 'Business'

            }

            if(entity.type == 'people'){

                fullname += entity.attributes['first-name'] ? entity.attributes['first-name'] + ' ' : ''

                fullname += entity.attributes['last-name'] ? entity.attributes['last-name'] : ''

                if(!entity.attributes['first-name'] && !entity.attributes['last-name']){
                    fullname = 'Person'
                }
            }

            return fullname
        },
        toSingular(payload){
            return this.$options.filters.toSingularResource(payload)
        },
        capitalize(payload){
            return this.$options.filters.capitalize(payload)
        },
        isBtnGroup(key){

            let keys = [
                "employment-on-probation",
                "self-employed-gst-registered",
                "covered-by-insurance",
                "being-refinanced",
                "being-sold-traded",
                "to-be-paid-out"
            ]

            return keys.includes(key)

        },
        getPeopleRelationship(){
            const people = {
                data: [],
                // Default Strategy: attach
                meta: {
                    strategy: 'replace'  
                }
            }

            this.preSelectRoles.forEach(role => {

                let person = this.people.find( p => p.id == role )

                if(person){
                    people.data.push({
                        type: 'people',
                        id: person.id
                    })
                }
            })

            return people
        },
        getBusinessesRelationship(){
            const businesses = {
                data: [],
                // Default Strategy: attach
                meta: {
                    strategy: 'replace'  
                }
            }

            this.preSelectRoles.forEach(role => {

                let business = this.businesses.find( b => b.id == role )

                if(business){
                    businesses.data.push({
                        type: 'businesses',
                        id: business.id
                    })
                }

            })

            return businesses
        },
        getKindName(id){

            let kind = this.kindsUnformat.find(k => {
                return id == k.id
            })

            if(kind){
                return kind.attributes.name
            }

            return ''
        },
        getAssetsByEntityId(records, entity){

            return records.filter( record => {

                if(record.type == 'assets'){

                    let ids = []

                    if(entity.type == 'people') {
    
                        record.relationships.people.data.forEach( person => {
                            ids.push(person.id)
                        })
    
                    }
    
                    if(ids.includes(entity.id)){
                        return true
                    }


                }

                return false

            })

        },
        isValid(data) {
            for (let key in data) {
                if (data.hasOwnProperty(key)) {
                    if (data[key] == 0) {
                        return false;
                        break;
                    }
                }
            }
            return true;
        },
        getRoles(entity){

            let roles = this.getEntityRoles(entity, this.entityParts)

            return roles.length > 1 ? `${roles[0]} +${roles.length - 1}`: roles.length == 1 ? roles[0] : ''

        },
        getMinDate(key){

            let min = undefined

            if(key == 'end-date'){

                let start = this.resources.incomes.attributes['start-date']

                if(start){

                    let newStart = moment(start, "YYYY-MM-DD").add(1, 'days').format("YYYY-MM-DD")

                    min = newStart
                }

            }

            return min

        },
        getMaxDate(key){

            let max = undefined

            if(key == 'start-date'){

                let end = this.resources.incomes.attributes['end-date']

                if(end && this.visible['end-date']){

                    let newEnd = moment(end, "YYYY-MM-DD").subtract(1, 'days').format("YYYY-MM-DD")

                    max = newEnd
                }

            }

            return max

        },
        filterParent(partId = '', businesses = {}, parts = []) {

            let parent = {}
            let kind = {}

            // filter parent
            if (parts.length > 0) {
                parts.forEach(part => {
                    // get parent
                    if (partId == part.id && part.relationships?.parent?.data) {
                        parent = part.relationships.parent.data
                    }
                    // get kind
                    if (partId == part.id && part.relationships?.kind?.data) {
                        kind = part.relationships.kind.data
                    }
                })

            }

            // check if parent has parent parts
            // if has parent parts recursive until to the primary applicant
            if(Object.keys(parent).length > 0) {

                let hasParentParts = false
                let part_id = ''

                businesses.data.forEach(business => {

                    if (business.id == parent.id && business.relationships['parent-parts'].data.length > 0) {
                        hasParentParts = true
                        part_id = business.relationships['parent-parts'].data[0].id
                    }

                })

                // if has parent parts and primary applicant id is not equal to parent
                if (hasParentParts && this.primaryApplicant.type != 'loans')
                    return this.filterParent(part_id, businesses, parts)

            }
            
            // return if parent is the primary applicant or kind id = 3-6 / non-borrowing spouse
            if (parent.type == 'loans' || kind.id == '3-6') {
                return true
            } else 
                return false
            
        },
        getFieldColWidth(key, size){

            let keys = [
                "__full-address",
                "liability-description",
                "to-be-paid-out",
            ]

            let halfWidthKeys = [
                'description-other'
            ]

            if(keys.includes(key)) 
                return 12
            else {

                if(halfWidthKeys.includes(key)) {

                    return 6

                } else if( size === 1 ){

                    return  12
    
                } else if(size === 2) {
    
                     return 6
    
                } else {
                    
                    return 4
    
                }

            }
        },
        isVisible(key){
                
            const {visible} = this

            if(visible.hasOwnProperty(key)){
                return visible[key]
            }

            return true

        },
        /**
         * Hidden only
         */
        isHidden(key){
                
            const {hidden} = this

            if(hidden.hasOwnProperty(key)){
                return hidden[key]
            }

            return false

        },
        /**
         * Read only
         */
        isReadOnly(key){
            
            const {readonly} = this

            if(readonly.hasOwnProperty(key)){
                return readonly[key]
            }

            return false

        },

        getLiabilityFieldLayout(child) {

            let keys = Object.keys(StepperFieldLayouts)
    
            let layout = {}
    
            for (let i = 0; i < keys.length; i++) {
    
                const key = keys[i]
                const items = key.split('|')
    
                if(items.includes(child.id)) {
                    layout = StepperFieldLayouts[key]
                }

            }

            if(Object.keys(layout).length == 0){
                console.log('Uh oh!, check field layout if configured correctly.')
            }

            if(layout.steps.length > 0) {
                layout.steps.forEach(element => {
                    if(this.filterEntities.length == 1 && element[0].includes('role-selector')) {
                        this.preSelectRoles = [this.filterEntities[0].id]
                    } else {
                        actions.pushStep(element)
                    }
                })
            }

            actions.setLinkExpenses(layout.expenses)
            actions.setKindsLiabilityId(child.id)

        },

        filterLiabilitiesData(role) {
            let peopleLiability = []
            let businessLiability = []

            if(role.type == 'businesses')
                businessLiability = this.liabilities.filter(liability => liability.relationships.businesses.data.some(element => element.id == role.id))
            else
                peopleLiability = this.liabilities.filter(liability => liability.relationships.people.data.some(element => element.id == role.id))

            let liabilities = [
                ...peopleLiability,
                ...businessLiability
            ]

            if(liabilities) {
                actions.setRoleLiability(liabilities)
            }
        },

        buildDeleteMsg(data){

            let recordKind = this.getKindName(data.relationships.kind.data?.id)

            let roles = [
                ...data.relationships.people.data,
                ...data.relationships.businesses.data
            ]

            // ${resource}

            let msg = `Delete ${recordKind} for `

            roles.forEach((role, index) => {

                this.entities.forEach(entity => {

                    if(entity.id == role.id){

                        let binder = ', '

                        if(index == 0) {
                            binder = ''
                        }

                        if(index == (roles.length - 1) && roles.length != 1) {
                            binder = ' and'
                        }

                        let name = `${binder} ${this.getEntityFriendlyName(entity)}`

                        msg += name

                    }

                })

            })

            return `${msg}?`
        },

        toSingular(payload){
            return this.$options.filters.toSingularResource(payload)
        },

        capitalize(payload){
            return this.$options.filters.capitalize(payload)
        },

        getOwners(data){

            const  {people, businesses} = this

            let owners = []

            if(data.relationships.people.data){
                data.relationships.people.data.forEach( item => {
                    people.forEach(person => {
                        if(item.id == person.id){
                            owners.push( this.getEntityFriendlyName(person) )
                        }
                    })
                })
            }
        
            if(data.relationships.businesses.data){
                data.relationships.businesses.data.forEach( item => {
                    businesses.forEach(business => {
                        if(item.id == business.id){
                            owners.push( this.getEntityFriendlyName(business) )
                        }
                    })
                })
            }

            return owners
        },

    }
}