<template>
    <v-card :disabled="isDisabled || !validComponentData" class="mx-auto" tile flat>

        <Portal to="step-header-buttons">

            <GenerateSoca
                @saved-referrals="generateSoca"
                :disabled="!selectedAndConfirmedLender"
                :sync-loading="pdfLoading"
                :bid-resource="bidResource" />
            
            <QuotableComponentDialog
                title="Quick Edit"
                @confirm-update="getUpdateLoan" />
            
            <v-btn 
                v-if="selectedAndConfirmedLender"
                @click="selectNextStep"
                large depressed
                class="secondary ml-2">
                Proceed to Submission
            </v-btn>

            <!-- {{ $vuetify.breakpoint.name }} -->

        </Portal>

        <v-card-text class="pa-4 pt-0 pa-md-10 pt-md-0 pb-10">

            <v-container class="scroll-y" pa-0 fluid>
                <v-card :disabled="loading" flat>
                    
                    <v-card-text                        
                        class="pa-0">

                        <v-row class="px-2">

                            <!-- Summary and Selected Primary and Secondary Lenders -->
                            <v-col cols="12" class="px-1 pt-9">
                                <v-row 
                                    class="pa-0 ma-0">

                                    <!-- Selected Lender Badges on XL -->
                                    <v-col 
                                        cols="12">
                                        <v-row class="pa-0">                                            
                                            <template
                                                v-for="(n, nkey) in confirmedSelectedLenders">
                                                <v-col                                                    
                                                    :key="nkey"
                                                    cols="6"
                                                    class="flex-boxes px-1 py-0 ma-0 pb-2">

                                                    <SelectionBadge
                                                        @save-custom-lender="saveCustomLender"
                                                        @reset-custom-lender="resetCustomLender"
                                                        :bid-resource="bidResource"
                                                        :bid-lender-resource="bidLenderResource"
                                                        :has-data="isEmptySelection(nkey) ? true : false"
                                                        :caption-key="n"
                                                        :title="nkey"
                                                        :has-error="warningInfo[nkey]" />

                                                </v-col>
                                            </template>                                            
                                        </v-row>                                    
                                    </v-col>

                                </v-row>                                
                            </v-col>
                            
                        </v-row>

                    </v-card-text>
                </v-card>
            </v-container>

        </v-card-text>
        
    </v-card>
</template>

<script>

    import { mapActions, mapState } from 'vuex'
    import GenerateSoca from '../QuoteToolBid1/_BidQuoteGenerateSocaDialog'
    import QuotableComponentDialog from '../QuoteTool1/QuotableComponentDialog'
    import SelectionBadge from './_BidSelectionBadger'
    import subscriptions from '~/components/shared/subscriptions'
    import { isEmpty } from 'lodash'

    export default {
        name: 'IndicativeQuote',

        mixins: [ subscriptions ],

        components: { 
            QuotableComponentDialog,
            SelectionBadge,
            GenerateSoca
        },        

        computed: {
            ...mapState('flows', ['appId']),

            selectedAndConfirmedLender() {
                let flag = true

                if ( isEmpty(this.selectedLenderObj.alternative) ) flag = false
                if ( isEmpty(this.selectedLenderObj.primary) ) flag = false

                return flag
            }
            
        },

        methods: {

            ...mapActions('resource-actions', [
                'getApplicationBid',
                'updateLoan',
                'createBid',
                'updateBid',
                'createBidLender',
                'updateBidLender'
                // 'setApplicationNow',
                
            ]),
            
            ...mapActions('quote-actions', [
                'generateSocaPDF',
            ]),

            ...mapActions('flows', ['selectNextStep']),

            async pull() {

                let resApp = await this.getApplicationBid({ id: this.appId, include: 'bid-lenders'})

                let bid = resApp?.data?.data

                // Remove validations
                if(bid.relationships && bid.relationships.validations){
                    delete bid.relationships.validations
                }

                let bidLender = resApp?.data?.included?.filter( lender => {
                    return lender.type === 'bid-lenders'
                })

                // Remove validations
                if(bidLender){
                    bidLender.forEach( lender => {
                        if(lender.relationships && lender.relationships.validations){
                            delete lender.relationships.validations
                        }
                    })
                }

                this.bidResource = bid || {}
                this.bidLenderResource = bidLender || []

            },

            async push() {

                const valid = this.validateOptions()
                let status = valid ? 'valid' : 'dirty'

                if ( valid ) {
                    // Save Primary and Alternative lenders 

                    let requests = this.payloadRequests()
                    
                    try {
                        Promise.all( requests ).then( res => {
                            // console.log(`Lenders successfully updated/saved!`)
                        })
                    } catch ( error ) {
                        console.log(`Ooops!.. `, error)
                    }
                    
                }

                return  { valid, status } 
            },

            async generateSoca (data = {}) {
                
                this.pdfLoading = true

                this.bidResource.attributes['referral-fee-estimate'] = data['referral-fee-estimate']
                this.bidResource.attributes['referral-partner-name'] = data['referral-partner-name']
                
                const res = await this.generateSocaPDF(this.appId)

                if (res?.data?.included) {
                    if (res.data.included[0].attributes['file-url']) {
                        // open to new tab
                        window.open(res.data.included[0].attributes['file-url'], '_blank');

                    }
                } else {
                    console.log('Some Error')
                }

                this.pdfLoading = false

            },

            payloadRequests() {

                let reqs = []

                Object.keys(this.selectedLenderObj).forEach( payload_type => {
                    Object.keys(this.selectedLenderObj[payload_type]).forEach( payload => {
                        
                        let payloadObject = this.selectedLenderObj[payload_type][payload]

                        // Remove validations
                        if(payloadObject?.relationships?.validations){
                            delete payloadObject.relationships.validations
                        }

                        if ( payloadObject.type === 'bids' ) {
                            
                            if ( !payloadObject?.id ) {
                                // POST Actions
                                reqs.push( this.createBid(payloadObject) )
                            }
                            else {
                                // PATCH Actions
                                reqs.push( this.updateBid(payloadObject) )
                            }
                        }
                        else if ( payloadObject.type === 'bid-lenders' ) {
                            
                            if ( !payloadObject?.id ) {
                                // POST Actions
                                reqs.push( this.createBidLender(payloadObject) )
                            }
                            else {
                                // PATCH Actions
                                reqs.push( this.updateBidLender(payloadObject) )
                            }
                        }

                        
                    })
                })

                return reqs
            },
            
            validateOptions() {
                let valid = true
                if ( !this.selectedLenderObj?.primary  ) {
                    this.warningInfo.primary = true
                    valid = false
                }
                else this.warningInfo.primary = false
                if ( !this.selectedLenderObj?.alternative ) {
                    this.warningInfo.alternative = true
                    valid = false
                }
                else this.warningInfo.alternative = false
                return valid
            },

            async getUpdateLoan(data) {

                let payload = {
                    type: data.type,
                    id: data.id,
                    attributes: data.attributes
                }

                if ( data?.id ) {
                    await this.updateLoan(payload)
                }

                await this.pull()
            },

            resetCustomLender(data) {
                if ( data.type === 'primary' ) {
                    this.selectedLenderObj.primary = ''
                }
                else if ( data.type === 'alternative' ) {
                    this.selectedLenderObj.alternative = ''
                }

                let filterLender = this.bidLenderResource.filter( lender => {
                    return lender.id !== data.id
                })

                this.bidLenderResource = filterLender
            },

            saveCustomLender(data) {

                let type_of_lender = data?.['bid-lenders']?.attributes?.['lender-type']

                let flag = false

                // Remove Validations
                if(data['bid'] && data['bid']?.relationships?.validations){
                    delete data['bid']?.relationships?.validations
                }
                if(data['bid-lenders'] && data['bid-lenders']?.relationships?.validations){
                    delete data['bid-lenders']?.relationships?.validations
                }

                this.bidResource = data['bids']
                this.bidLenderResource.forEach( obj => {
                    if ( obj?.id === data?.['bid-lenders']?.id ) 
                        flag = true
                })

                if ( !flag ) 
                    this.bidLenderResource.push(data['bid-lenders'])
                
                if ( type_of_lender === 'Primary' ) {
                    this.selectedLenderObj.primary = data
                    this.warningInfo.primary = false
                }
                else if ( type_of_lender === 'Alternative' ) {
                    this.selectedLenderObj.alternative = data
                    this.warningInfo.alternative = false
                }
            },
            
            isEmptySelection(key) {
                return isEmpty(this.confirmedSelectedLenders[key]) ? false : true
            }
            
        },

        watch: {},

        data() {
            return {
                pdfLoading: false,
                selectedLenderObj: {
                    primary: '',
                    alternative: ''
                },
                confirmedSelectedLenders: {
                    primary: '',
                    alternative: ''
                },
                warningInfo: {
                    primary: false,
                    alternative: false
                },
                validComponentData: true,
                commercialOnly: false,
                disabled: true,
                quoteLenders: [],
                bidResource: {},
                bidLenderResource: []
            }
        }
    }
</script>

<style lang="scss" scoped>
    .allow-hover {
        table {
            tbody {
                tr {
                    &:hover {
                        background-color: red !important;
                    }
                }
            }
        }   
    }
    .flex-boxes {
        position: relative;
        display: inherit;

        .flex-box-content {
            width: 100%;
            height: 100%;
        }
    }
</style>