<template>
    <div v-if="role.children && role.children.length" class="flex-role-editor-list has-children" :class="[{'last-child': last}]">
        <v-list-item :style="listIndention" class="rounded-xl pa-0" :selectable="false" :ripple="false">

            <template>

                <div class="track-dot" :style="horizontalLinePosition"></div>
                <div v-if="last" :style="verticalLinePositionDot" class="filler-dot"></div>

                <v-card style="z-index: 7" flat width="100%" class="pa-0 ma-0" @click="editEntity(role.id)" :ripple="false">
                    <v-alert class="rounded-xl pa-0 my-1">
                        <v-card
                            flat class="fill rounded-xl pa-3"
                            :style="isPrimaryRole(role) ? `background-color: ${hexToRGBA(getActiveTheme.colors.accent, 0.1)}`
                                : `background-color: ${hexToRGBA(getActiveTheme.colors.light_gray_2, 0.1)}`">

                            <v-list-item-content :ripple="false"> 
                                <div class="d-flex flex-row">

                                    <v-list-item-avatar @click.stop="showTooltip = !showTooltip" class="ma-0 mr-2" color="white" :size="vb.smAndUp ? '34' : '25'">
                                        <v-icon v-if="vb.mdAndUp" color="secondary" size="20" v-text="role.icon"></v-icon>

                                        <v-tooltip v-else v-model="showTooltip" bottom color="light_gray_2">
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-icon color="secondary" v-bind="attrs" v-on="on" size="20" v-text="role.icon"></v-icon>
                                            </template>
                                            <span>{{ getFriendlyName(role) }}, </span>
                                            <span>{{ getApplicant(role) }}</span>
                                        </v-tooltip>

                                    </v-list-item-avatar>

                                    <div v-if="!isAdd(role)" class="title-3 d-flex">
                                        <div v-if="vb.smAndUp" class="font-weight-bold pt-2">
                                            {{ getFriendlyName(role) }}
                                            <!-- <v-icon class="mt-n1 mx-1 px-1 subtitle" color="black" size="15" @click.stop="editRole(role)">mdi-pencil</v-icon> -->
                                        </div>
                                        <v-chip v-if="vb.mdAndUp" color="white" class="mx-2">
                                            {{  getApplicant(role) }}
                                        </v-chip>

                                        <div v-if="vb.xsOnly">
                                            <b class="d-block">{{ getFriendlyName(role) }}</b>
                                            <span class="caption">{{ getApplicant(role) }}</span>
                                        </div>
                                    </div>

                                    <v-spacer />

                                    <v-chip v-if="vb.smAndUp" color="white" class="mx-2">
                                        {{ getRoleKind(role) }}
                                    </v-chip>
                                    <!-- <v-list-item-avatar v-else class="ma-0 mr-2" color="white" :size="vb.smAndUp ? '34' : '25'">
                                        <v-icon color="secondary" size="15">mdi-account</v-icon>
                                    </v-list-item-avatar> -->

                                    <template v-if="role['sub-step-status'] == 'valid'">
                                        <v-chip v-if="vb.smAndUp" color="primary" class="mr-2">
                                            <v-list-item-avatar class="ml-n2 mr-1" color="white" :size="vb.smAndUp ? '34' : '25'">
                                                <v-icon color="primary" size="15">mdi-check</v-icon>
                                            </v-list-item-avatar>

                                            <div v-if="vb.smAndUp">Complete</div>
                                        </v-chip>
                                    </template>
                                    <template v-else>
                                        <v-chip v-if="vb.smAndUp" color="secondary" class="mr-2">
                                            <v-list-item-avatar class="ml-n2 mr-1" color="white" :size="vb.smAndUp ? '34' : '25'">
                                                <v-icon color="secondary" size="15">mdi-check</v-icon>
                                            </v-list-item-avatar>

                                            <div v-if="vb.smAndUp">Add Details</div>
                                        </v-chip>
                                    </template>

                                    <!-- <v-list-item-avatar v-else class="ma-0 mr-2" color="white" :size="vb.smAndUp ? '34' : '25'">
                                        <v-icon color="secondary" size="15">mdi-check</v-icon>
                                    </v-list-item-avatar> -->

                                    <v-list-item-avatar v-if="vb.smAndUp" :class="vb.mdAndUp ? 'ma-0 mr-2' : 'ma-0 mr-2'" color="white" :size="vb.smAndUp ? '34' : '25'" @click.stop="editEntity(role.id)">
                                        <v-icon color="primary" size="15">mdi-pencil</v-icon>
                                    </v-list-item-avatar>
                                    <!-- <v-list-item-avatar class="ma-0 mr-2 mt-n5" color="white" :size="vb.smAndUp ? '34' : '25'">
                                        <v-icon color="primary" size="15" :disabled="disabledDelete(role)" @click.stop="deleteRole(role)">
                                            mdi-delete
                                        </v-icon>
                                    </v-list-item-avatar> -->

                                </div>
                            </v-list-item-content>

                            <!-- mobile version -->
                            <v-list-item-content v-if="vb.xsOnly" :ripple="false">
                                <div class="pa-1 d-flex flex-row justify-end">
                                    <v-chip color="white" class="mx-2">
                                        {{ getRoleKind(role) }} 
                                    </v-chip>

                                    <v-chip v-if="role['sub-step-status'] == 'valid'" color="primary" class="mr-2">
                                        <v-list-item-avatar class="ml-n2 mr-1" color="white" :size="vb.smAndUp ? '34' : '25'">
                                            <v-icon color="primary" size="15">mdi-check</v-icon>
                                        </v-list-item-avatar>

                                        <div>Complete</div>
                                    </v-chip>
                                    <v-chip v-else color="secondary" class="mr-2">
                                        <v-list-item-avatar class="ml-n2 mr-1" color="white" :size="vb.smAndUp ? '34' : '25'">
                                            <v-icon color="secondary" size="15">mdi-check</v-icon>
                                        </v-list-item-avatar>

                                        <div>Add Details</div>
                                    </v-chip>

                                    <v-list-item-avatar 
                                        class="ma-0 mr-2" 
                                        color="white"
                                        size="34"
                                        @click.stop="editEntity(role.id)">
                                        <v-icon color="primary" size="15">mdi-pencil</v-icon>
                                    </v-list-item-avatar>

                                </div>
                            </v-list-item-content>
                        </v-card>
                    </v-alert>
                </v-card>

            </template>

        </v-list-item>

        <div v-if="role.addBtn && role.addBtn.isAdd" class="d-flex px-2">
            <v-spacer />
            <v-btn text class="secondary--text" @click="addEntity(role)">
                <v-icon size="20" color="secondary">mdi-plus</v-icon> Add Role
            </v-btn>
        </div>
        <div v-else class="d-flex px-2 my-2">
            <v-spacer />
            <div class="secondary--text py-3"></div>
        </div>

        <div class="filler" :style="verticalLinePosition"></div>

        <EntityList 
            v-for="(child, index) in role.children" 
            :deep="deep + 1"
            :role="child" 
            :last="isLast(index)"
            :arguments-value="argumentsValue"
            :key="`${index}`"/>
    </div>

    <div v-else class="flex-role-editor-list" :class="[{'last-child': last}]">

        <v-list-item :style="listIndention" class="rounded-xl pa-0" :ripple="false">
            <!-- v-slot:default="{ active }" -->
            <template>

                <div class="track-dot" :style="horizontalLinePosition"></div>
                <div v-if="last" :style="verticalLinePositionDot" class="filler-dot"></div>

                <v-card flat width="100%" class="pa-0 ma-0" @click="editEntity(role.id)" :ripple="false">
                    <v-alert class="rounded-xl pa-0 my-1">
                        <v-card
                            flat class="rounded-xl pa-3"
                            :style="isPrimaryRole(role) ? `background-color: ${hexToRGBA(getActiveTheme.colors.accent, 0.1)}`
                                    : `background-color: ${hexToRGBA(getActiveTheme.colors.light_gray_2, 0.1)}`">

                           <v-list-item-content :ripple="false"> 
                                <div class="d-flex flex-row">

                                    <v-list-item-avatar @click.stop="showTooltip = !showTooltip" class="ma-0 mr-2" color="white" :size="vb.smAndUp ? '34' : '25'">
                                        <v-icon v-if="vb.mdAndUp" color="secondary" size="20" v-text="role.icon"></v-icon>

                                        <v-tooltip v-else v-model="showTooltip" bottom color="light_gray_2">
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-icon color="secondary" v-bind="attrs" v-on="on" size="20" v-text="role.icon"></v-icon>
                                            </template>
                                            <span>{{ getFriendlyName(role) }}, </span>
                                            <span>{{ getApplicant(role) }}</span>
                                        </v-tooltip>
                                    </v-list-item-avatar>

                                    <div v-if="!isAdd(role)" class="title-3 d-flex">
                                        <div class="d-flex font-weight-bold">
                                            <div v-if="vb.smAndUp" class="pt-2">
                                                <!-- {{ getFriendlyName(role) }} {{ development ? deep : '' }} -->
                                                {{ getFriendlyName(role) }}
                                            </div>
                                            <!-- <v-icon class="mt-n1 mx-1 px-1 subtitle" color="black" size="15" @click.stop="editRole(role)">mdi-pencil</v-icon> -->
                                        </div>
                                        <v-chip v-if="vb.mdAndUp" color="white" class="mx-2">
                                            {{  getApplicant(role) }}
                                        </v-chip>

                                        <div v-if="vb.xsOnly">
                                            <b class="d-block">{{ getFriendlyName(role) }}</b>
                                            <span class="caption">{{ getApplicant(role) }}</span>
                                        </div>
                                    </div>

                                    <v-spacer />
                                    
                                    <v-chip v-if="vb.smAndUp" color="white" class="mx-2">
                                        {{ getRoleKind(role) }} 
                                    </v-chip>
                                    <!-- <v-list-item-avatar v-else class="ma-0 mr-2" color="white" :size="vb.smAndUp ? '34' : '25'">
                                        <v-icon color="secondary" size="15">mdi-account</v-icon>
                                    </v-list-item-avatar> -->

                                    <template v-if="role['sub-step-status'] == 'valid'">
                                        <v-chip v-if="vb.smAndUp" color="primary" class="mr-2">
                                            <v-list-item-avatar class="ml-n2 mr-1" color="white" :size="vb.smAndUp ? '34' : '25'">
                                                <v-icon color="primary" size="15">mdi-check</v-icon>
                                            </v-list-item-avatar>

                                            <div v-if="vb.smAndUp">Complete</div>
                                        </v-chip>
                                    </template>
                                    <template v-else>
                                        <v-chip v-if="vb.smAndUp" color="secondary" class="mr-2">
                                            <v-list-item-avatar class="ml-n2 mr-1" color="white" :size="vb.smAndUp ? '34' : '25'">
                                                <v-icon color="secondary" size="15">mdi-check</v-icon>
                                            </v-list-item-avatar>

                                            <div v-if="vb.smAndUp">Add Details</div>
                                        </v-chip>
                                    </template>
                                    <!-- <v-list-item-avatar v-else class="ma-0 mr-2" color="white" :size="vb.smAndUp ? '34' : '25'">
                                        <v-icon color="secondary" size="15">mdi-check</v-icon>
                                    </v-list-item-avatar> -->

                                    <v-list-item-avatar v-if="vb.smAndUp" :class="vb.mdAndUp ? 'ma-0 mr-2' : 'ma-0 mr-2'" color="white" :size="vb.smAndUp ? '34' : '25'" @click.stop="editEntity(role.id)">
                                        <v-icon color="primary" size="15">mdi-pencil</v-icon>
                                    </v-list-item-avatar>
                                    <!-- <v-list-item-avatar class="ma-0 mr-2 mt-n5" color="white" :size="vb.smAndUp ? '34' : '25'">
                                        <v-icon color="primary" size="15" :disabled="disabledDelete(role)" @click.stop="deleteRole(role)">mdi-delete</v-icon>
                                    </v-list-item-avatar> -->
                                </div>
                            </v-list-item-content>
                            <!-- mobile version -->
                            <v-list-item-content v-if="vb.xsOnly" :ripple="false">
                                <div class="pa-1 d-flex flex-row justify-end">
                                    <v-chip color="white" class="mx-2">
                                        {{ getRoleKind(role) }} 
                                    </v-chip>

                                    <v-chip v-if="role['sub-step-status'] == 'valid'" color="primary" class="mr-2">
                                        <v-list-item-avatar class="ml-n2 mr-1" color="white" :size="vb.smAndUp ? '34' : '25'">
                                            <v-icon color="primary" size="15">mdi-check</v-icon>
                                        </v-list-item-avatar>

                                        <div>Complete</div>
                                    </v-chip>
                                    <v-chip v-else color="secondary" class="mr-2">
                                        <v-list-item-avatar class="ml-n2 mr-1" color="white" :size="vb.smAndUp ? '34' : '25'">
                                            <v-icon color="secondary" size="15">mdi-check</v-icon>
                                        </v-list-item-avatar>

                                        <div>Add Details</div>
                                    </v-chip>

                                    <v-list-item-avatar 
                                        class="ma-0 mr-2" 
                                        color="white"
                                        size="34"
                                        @click.stop="editEntity(role.id)">
                                        <v-icon color="primary" size="15">mdi-pencil</v-icon>
                                    </v-list-item-avatar>

                                </div>
                            </v-list-item-content>

                        </v-card>
                    </v-alert>
                </v-card>

            </template>

        </v-list-item>

        <div v-if="role.addBtn && role.addBtn.isAdd" class="d-flex px-2">
            <v-spacer />
            <v-btn text class="secondary--text" @click="addEntity(role)">
                <v-icon size="20" color="secondary">mdi-plus</v-icon> Add Role
            </v-btn>
        </div>
        <div v-else class="d-flex px-2 my-3">
            <v-spacer />
            <div class="secondary--text py-3"></div>
        </div>

    </div>
    
</template>
<script>
import EntityList from '~/components/v1/ApplicantCreator1/_EntityList'
import shared from '~/components/v1/ApplicantCreator1/__shared'
import {store, actions} from '~/components/v1/ApplicantCreator1/__store'
import { mapGetters, mapMutations, mapState, mapActions } from 'vuex'

export default {
    name: 'EntityList',
    mixins: [shared],
    components: {EntityList},
    props: {
        role: {
            type: Object,
            default: () => {}
        },
        deep: {
            type: Number,
            default: 1
        },
        size: {
            type: Number,
            default: 0
        },
        last: {
            type: Boolean,
            default: false
        },
        argumentsValue: {
            type: Array,
            default: () => {}
        }
    },
    computed: {
        ...mapGetters('themes', [
            'getActiveTheme'
        ]),
        ...mapState('flows', {
            appTypeFE: state => state.appTypeFE
        }),
        vb(){
            return this.$vuetify.breakpoint
        },
        listIndention(){

            const {deep} = this

            let base = 15
            let multiplier = 44

            if(deep == 1) {
                return ''
            }

            return `margin-left: ${((deep - 1) * multiplier) + base}px;`

        },
        verticalLinePosition(){

            const {deep} = this

            let base = 14
            let multiplier = 59

            if(deep == 1) {
                return ''
            }

            return `left: ${((deep - 1) * multiplier) + base}px`

        },
        horizontalLinePosition() {
            const {deep} = this

            let base = -50

            if(deep == 1) {
                return ''
            }

            return `left: ${(base + (deep * 8.9))}px`
        },
        verticalLinePositionDot() {
            const {deep} = this

            let left = ''

           switch (deep) {
                case 1:
                        left =  'left: -34px;'
                    break;
                case 2:
                        left =  'left: -34.5px;'
                    break;
                case 3:
                        left =  'left: -30px;'
                    break;
                case 4:
                        left =  'left: -15.5px;'
                    break;
                default:
                    left =  'left: -34.5px;'
            }

            return left
        },
        listenerDataArgument() {
            return {
                person: {
                    id: this.role.id
                }
            }
        }
    },
    data() {
        return {
            showTooltip: false
        }
    },
    methods: {
        ...mapActions('resource-actions', [
            'runServiceInitData'
        ]),
        ...mapMutations('flows', [
            'setAppID'
        ]),
        disabledDelete(role) {
            if(this.isCommercial) {
                return this.getApplicant(role) == 'Applicant'
            } else {
                return true
            }
        },
        isAdd(data){
            return data.type == 'add'
        },
        isLast(index){

            if(this.role.children){
                if((this.role.children.length - 1) == index) return true
            }

            return false
        },
        hexToRGBA(hex, a = 1){
            return this.$options.filters.hexToRGBA(hex, a)
        },
        async addEntity(data) {

            let step = {
                name: this.primaryApplicantKind.attributes.name,
                id: this.primaryApplicantKind.id,
                children: data.addBtn.parts
            }

            actions.setParentEntity(data)
            actions.setupStep(step)

            actions.setDialog(true)
        },
        editRole(role) {

            actions.setFieldType(role.type)

            const step = {
                id: role.id,
                attributes: role.attributes,
                type: role.type,
                relationships: role.relationships,
                children: []
            }

            actions.setupStep(step)

            actions.setIsEdit(true)

            actions.setCurrentStep(step)

            actions.setDialog(true)
        },
        async editEntity(id) {

            let args = {}

            this.argumentsValue.forEach(listener => {
                if (listener.event == 'components.click') {
                    args = listener.arguments
                    args.data = this.listenerDataArgument
                }
            })

            // call listener method ( __block_flexRedirect )
            // this should technically be a dynamic method call (based on what's defined in the flow config)
            // but we will simplify the flow system soon, at which point this wont be necessary
            // read more here: https://app.clickup.com/t/36609304/FLX-8654
            // for now, we will just hardcode the __block_flexRedirect method call here

            this.__block_flexRedirect(args)

        },
        async deleteRole(role) {
           actions.setShowDelete(true)
           actions.setDeletedItems(role)
        },
        getApplicant(role) {
            const type = this.getRolesEntity(role)

            if(type) {
                return type[0]
            }

            return ''
        },
        isPrimaryRole(role) {
            let isPrimary = false

            //if joint applicant make to primary
            if(this.appTypeFE.type.includes('Consumer - Joint')) {
                isPrimary = true
            } else if(this.getApplicant(role) == 'Applicant') {
                isPrimary = true
            }

            return isPrimary
        }
    }
}
</script>
<style scoped lang="scss">
    .flex-role-editor-list {
        overflow: hidden;
        position: relative;
        z-index: 1;
        
        .last-child {
            position: relative;
            background-color: white;
            z-index: 3;
        }

        .filler {
            position: absolute;
            bottom: 0;
            width: 4px;
            left: 25px;
            height: 9999px;
            // background: blue;
            border-left: 3px dashed #f7f7f7;
            z-index: 2;
        }

        .filler-dot {
            position: absolute;
            bottom: 38px;
            width: 4px;
            left: 20px;
            height: 9999px;
            // background: blue;
            border-left: 3px dashed #f7f7f7;
            z-index: 6;
        }

        .v-list-item {
            position: relative;
            z-index: 5;

            & > .track-dot {
                content: "";
                position: absolute;
                left: -37px;
                bottom: 38px;
                width:33px;
                height: 4px;
                border-bottom: 3px dashed #f7f7f7;
                z-index: 4 !important;
            }
        }
    }
    
</style>
