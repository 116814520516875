/**
 * All components must have this default block data from the config
 */

import {getCookie, setCookie, deleteCookie} from '~/plugins/cookies'
import { mapState, mapMutations, mapActions, mapGetters } from 'vuex';
import viewEditMapping from '~/config/view-edit-mapping'

export default {
    props: {
        block: {
            type: Object,
            default: null
        }
    },
    computed: {
        ...mapGetters({
            getTenant: 'slug/getTenant',
            getFlowSlug: 'slug/getFlowSlug',
            getGroupSlug: 'slug/getGroupSlug',
            getConfig: 'flows/getConfig',
            getParentFlow: 'flows/getParentFlow',
        }),
        ...mapState({
            appGuid: state => state.flows.appGuid,
            appId: state => state.flows.appId,
            appData: state => state.flows.appData,
            flowStatuses: state => state.statuses.flowStatuses,
            allStepStatuses: state => state.statuses.allStepStatuses,
        }),
        blockSettings(){

            const {block} = this;

            let configSetup = {}

            if(block.hasOwnProperty('settings')) {

                block.settings.forEach(setting => {

                    let name = `${block.component.name}__${block.id}__${setting.name}`

                    configSetup[setting.name] = setting.value

                    // deleteCookie(name);
                    
                    /**
                     * If already in the cookie, use its value,
                     * otherwise, check if context is 'cookie' then save it there. 
                     */
                    if(getCookie(name)){

                        // deleteCookie(name);
                        configSetup[setting.name] = JSON.parse(getCookie(name))

                    } else {

                        if( setting.context == 'cookie' ){
                            setCookie(name, setting.value)
                        }

                    }


                });
            }

            return configSetup;

        },
        blockListeners(){

            const {block} = this;

            let configSetup = {}

            if(block.hasOwnProperty('listeners')) {

                block.listeners.forEach(listener => {

                    configSetup[listener.method.name] = listener.method.arguments

                });
            }

            return configSetup;

        },
        /**
         * blockListeners2
         * 
         * maps stores block listeners from config in format:
         * 
         * [
         *      {
         *          method: string
         *          arguments: object
         *          event: string
         *      },
         *      ...
         * ]
         * 
         * these can then be implmented completely dynamically.
         * see Button3 component for an example
         * 
         */
        blockListeners2() {

            const {block} = this;

            let listeners = []

            if(block.hasOwnProperty('listeners')) {
                
                let listener = {}

                block.listeners.forEach(l => {

                    listener.method = l.method.name
                    listener.arguments = l.method.arguments
                    listener.event = l.event.name

                });

                listeners.push(listener)
            }

            return listeners;

        },
        blockPermissionsReadOnly(){

            if( this.blockSettings.hasOwnProperty('permissions') ) {
                if(this.blockSettings.permissions == "-R--") {
                    return true
                }
            }

            return false
        },
        subComponents(){

            const {blockSettings} = this
            
            let components = []
            
            if(blockSettings && blockSettings.hasOwnProperty('subComponents')) {
                return blockSettings.subComponents
            }

            return components
        },
    },
    /**
     * 
     * prefix block settings with "__block_" 
     * 
     */
    methods: {
        ...mapMutations('subscriptions', [
            'setGlobalMode',
        ]),
        ...mapMutations('flows', [
            'setAppID',
        ]),
        ...mapActions({
            saveDataOnly: 'flows/saveDataOnly',
            saveDataAndStepStatusOnly: 'flows/saveDataAndStepStatusOnly',
        }),
        // __block_b2bClickHeaderLogo(){
        //     console.log('__block_b2bClickHeaderLogo')
        // },
        // __block_b2cClickHeaderLogo(){
        //     console.log('__block_b2cClickHeaderLogo')
        // },

        /**
         * 
         * @param {string} source - the source string to parse
         * @param {object} data - required when source contains {data.*} values
         * @returns {string} the parsed string
         */
        __flexConfigStringParser(source, data=null) {

            return this.$flexConfigStringParser(source, {
                config: this.getConfig,
                route: {
                    groupSlug: this.getGroupSlug,
                    flowSlug: this.getFlowSlug,
                    applicationGuid: this.appGuid,
                },
                data: data
            })
        },

        /**
         * 
         * @param {object} args 
         * args.url {string} - required. the redirect url
         * args.data {object} - optional. can be used to pass additional/arbitrary data e.g. data to help the parsing of the url
         * args.refresh {boolean} - optional. if true will run redirect through window.location refreshing the browser, else will push the route via vue's router
         */
        async __block_flexRedirect(args){

            try {

                this.setGlobalMode('disabled')
                
                let url = this.__flexConfigStringParser(args.url, args.data)

                // Setting setAppID to null to re-run  flex-config middleware
                this.setAppID(null) 

                if (args.refresh) window.location.href = url

                this.$router.push({ path: url })

            } catch (error) {
                
                console.log(error)

            }
        },

        /**
         * 
         * @param {object} args 
         * args.url {string} - required. the redirect url
         * args.data {object} - optional. can be used to pass additional/arbitrary data e.g. data to help the parsing of the url
         * args.refresh {boolean} - optional. if true will run redirect through window.location refreshing the browser, else will push the route via vue's router
         * 
         */
        async __block_flexSaveAndRedirect(args){

            try {

                this.setGlobalMode('disabled')

                let url = this.__flexConfigStringParser(args.url, args.data)

                const status = await this.saveDataAndStepStatusOnly()

                // if(status == 'valid') {

                    this.setAppID(null) 

                    if (args.refresh) window.location.href = url

                    this.$router.push({ path: url })

                // } else {

                    this.setGlobalMode('enabled')
                // }  

            } catch (error) {
                
                console.error(error)

            }

        },
        
        /**
         * 
         * @param {object} args 
         * args.url {string} - required. the redirect url
         * args.data {object} - optional. can be used to pass additional/arbitrary data e.g. data to help the parsing of the url
         * args.refresh {boolean} - optional. if true will run redirect through window.location refreshing the browser, else will push the route via vue's router
         * 
         */
        async __block_flexSaveAndRedirectAndUpdateParentStepStatus(args){

            const { getParentFlow, flowStatuses, allStepStatuses } = this

            try {

                this.setGlobalMode('disabled')

                let url = this.__flexConfigStringParser(args.url, args.data)

                const status = await this.saveDataAndStepStatusOnly()

                if(status == 'valid') {

                    let slug = getParentFlow?.slug
                    let flowResponse = await this.$api.get(`flows?filter[slug]=${slug}`)   
                    let steps = flowResponse.data.data[0].attributes.config
    
                    if(typeof steps == 'string'){
                        steps = JSON.parse(steps)
                    }
    
                    let parentStep = steps?.flow?.steps?.[parseInt(args['parent-step'])-1]
    
                    if(parentStep){

                        // get parent step status
                        let getParentStepStatus = allStepStatuses.find(status => {
                            return status.relationships?.['step']?.data?.id == parentStep?.id
                        })

                        // get flow parent flow status
                        let getParentFlowStatus = flowStatuses.find(flow => {
                            return flow.relationships?.['flow']?.data?.id == flowResponse.data.data[0]?.id
                        })

                        let clickType = args?.['click-type']

                        if(getParentStepStatus && getParentFlowStatus) {

                            this.setAppID(null) 
                            let parentCurrentStep = parseInt(args['parent-step'])

                            // condition for Next and Back button
                            switch (clickType) {
                                case "Back":
                                    parentCurrentStep = parseInt(args['parent-step']) - 1
                                    break;
                                case "Next":
                                    parentCurrentStep = parseInt(args['parent-step']) + 1
                                    break;                    
                            }

                            // parent step status payload
                            const stepStatusPayload = {
                                "id": getParentStepStatus.id, 
                                "type": "step-statuses",
                                "attributes": {
                                    "value": "valid"
                                }
                            }

                            // parent flow status payload
                            const flowStatusPayload = {
                                "id": getParentFlowStatus.id, 
                                "type": "flow-statuses",
                                "attributes": {
                                    "current-step": parentCurrentStep
                                }
                            }

                            await this.$api.patch(`/step-statuses/${getParentStepStatus.id}`, {"data": stepStatusPayload})
                            await this.$api.patch(`/flow-statuses/${getParentFlowStatus.id}`, {"data": flowStatusPayload})
                            
                            if (args.refresh) window.location.href = url

                            this.$router.push({ path: url })

                        }
    
                    } else {

                        console.log('Uh oh!.. Something went wrong!')
                    }
                    
                
                } else {
                    this.setGlobalMode('enabled')
                } 
                       

            } catch (error) {
                
                console.log(error)

            }

        },
        __block_flexSave(args){
            this.saveDataOnly()
        },
        async __block_flexEditFlow(args){

            let editFlow = viewEditMapping?.[this.getFlowSlug]

            let url = `/${this.getGroupSlug}/flows/${editFlow}/1/${this.appGuid}`

            if(args?.flagAsEdited == 1) {

                await this.$api.patch(`applications/${this.appId}`, {
                    "data": {
                        "id": this.appId,
                        "type": "applications",
                        "attributes": {
                                "edited": 1
                        }
                    }
                })

                window.location.href = url 
                // this.$router.push({ path: url })

            } else {

                window.location.href = url 
                // this.$router.push({ path: url })
            }

        },
        __block_flexHideIfApplicationIsLocked(args){
            return this.appData?.attributes?.locked
        },
    }
}