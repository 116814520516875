<template>
    <v-card 
    tile
    class="mx-auto" 
    outlined >
        <v-list-item three-line>
            <v-list-item-content>
                <v-list-item-title class="headline mb-1">{{block.component.name}}</v-list-item-title>
            </v-list-item-content>
        </v-list-item>
    </v-card>
</template>

<script>

    import subscriptions from '~/components/shared/subscriptions'

    export default {
        mixins: [subscriptions],
        data(){
            return {
            }
        },
        computed: {
         
        },
        created() {

        },
        methods: {
        }
    }
</script>

<style scoped lang="scss">

</style>
