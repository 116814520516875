export default {
    /**
     * ########################################################### ASSETS
     */
    
    //  Asset 10-1
    //  --Cash in Bank 10-2
    //  ----Business Account 10-3
    //  ----Personal Account 10-4
    //  ----Term Deposit 10-5
    "10-3|10-4|10-5": {
        title: "",
        steps: [
            [
                [
                    "role-selector",
                ]
            ],
            [
                [
                    "financial-institution",
                ],
                [
                    "other-institution",
                ]
            ],
            [
                [
                    "asset-value"
                ]
            ]
        ],
        liabilities: "unavailable"
    },
    //  Asset 10-1
    //  --Home Contents 10-6
    "10-6": {
        title: "",
        steps: [
            [
                [
                    "role-selector",
                ]
            ],
            [
                [
                    "asset-value",
                ]
            ]
        ],
        liabilities: "unavailable"
    },
    //  Asset 10-1
    //  --Other Investment 10-7
    //  ----Business Equity 10-8
    //  ----Goodwill of Business 10-9
    //  ----Managed Funds 10-10
    //  ----Shares 10-11
    
    "10-8|10-9|10-10|10-11": {
        title: "",
        steps: [
            [
                [
                    "role-selector",
                ]
            ],
            [
                [
                    "description-other",
                ]
            ],
            [
                [
                    "asset-value",
                ]
            ]
        ],
        liabilities: "unavailable"
    },

    //  Asset 10-1
    //  ----Superannuation 10-12
    "10-12": {
        title: "",
        steps: [
            [
                [
                    "role-selector",
                ]
            ],
            [
                [
                    "asset-value",
                ]
            ]
        ],
        liabilities: "unavailable"
    },

    //  Asset 10-1
    // --Property - Investment 10-13
    // ----Investment Property 10-14
    "10-14": {
        title: "",
        steps: [
            [
                [
                    "role-selector",
                ]
            ],
            [
                [
                    "__full-address"
                ]
            ],
            [
                [
                    "asset-value"
                ]
            ],
            [
                [
                    "being-refinanced"
                ]
            ]
        ],
        liabilities: {
            "13-20": {
                "expense": "11-30"
            },
            "13-19": {
                "expense": "11-29"
            },
        }
    },
    //  Asset 10-1
    // --Property - Owner Occupied 10-15
    // ----Owner Occupied 10-16
    "10-16": {
        title: "",
        steps: [
            [
                [
                    "role-selector",
                ]
            ],
            [
                [
                    "__full-address"
                ]
            ],
            [
                [
                    "asset-value"
                ]
            ],
            [
                [
                    "being-refinanced"
                ]
            ]
        ],
        liabilities: {
            "13-21": {
                "expense": "11-6"
            }
        }
    },
    //  Asset 10-1
    // --Stock & Equipment 10-17
    // ----Stock 10-30
    // ----Tools of Trade 10-31
    "10-30|10-31|10-18|10-27": {
        title: "",
        steps: [
            [
                [
                    "role-selector",
                ]
            ],
            [
                [
                    "asset-value"
                ]
            ]
        ],
        liabilities: "unavailable"
    },
    //  Asset 10-1
    // --Vehicle - Motor 10-32
    // ----Motor Vehicle 10-33
    "10-33": {
        title: "",
        steps: [
            [
                [
                    "role-selector",
                ]
            ],
            [
                [
                    "year-of-manufacture",
                ],
                [
                    "make",
                ],
                [
                    "model",
                ],
            ],
            [
                [
                    "asset-value"
                ]
            ],
            [
                [
                    "being-refinanced"
                ]
            ]
        ],
        liabilities: {
            "13-9": {
                "expense": "11-18"
            },
            "13-10": {
                "expense": "11-20"
            }
        }
    },
    //  Asset 10-1
    // --Vehicle - Other 10-34
    // ----Boat 10-35
    // ----Camper Trailer 10-36
    // ----Caravan 10-37
    // ----Heavy Vehicles 10-38
    // ----Motor Bike 10-44
    // ----Jetski 10-46
    // ----Motor Home 10-47
    // ----Light Trailer 10-48
    // ----Horse Float 10-49
    // --Stock & Equipment 10-17
    // ----Machinery 10-27
    // ------Agricultural Machinery 10-28
    // ------Yellow Goods 10-29
    // ----Equipment 10-18
    // ------Industrial Plant (Lathes etc) 10-19
    // ------Material Handling (Fork Lifts etc) 10-20
    // ------Medical Equipment 10-21
    // ------Office Equipment (Copiers etc) 10-22
    // ------Computer Hardware 10-23
    // ------POS Systems 10-24
    // ------Solar Equipment 10-25
    "10-35|10-36|10-37|10-38|10-44|10-45|10-46|10-47|10-48|10-49|10-19|10-20|10-21|10-22|10-23|10-24|10-25|10-28|10-29": {
        title: "",
        steps: [
            [
                [
                    "role-selector",
                ]
            ],
            [
                [
                    "year-of-manufacture",
                ],
                [
                    "make",
                ],
                [
                    "model",
                ],
            ],
            [
                [
                    "asset-value"
                ]
            ],
            [
                [
                    "being-refinanced"
                ]
            ]
        ],
        liabilities: {
            "13-11": {
                "expense": "11-21"
            }
        }
    },

    // ------Other (Not Listed) 10-26
    // ----Other Vehicle 10-45
    "10-26|10-45": {
        title: "",
        steps: [
            [
                [
                    "role-selector",
                ]
            ],
            [
                [
                    "year-of-manufacture",
                ],
                [
                    "make",
                ],
                [
                    "model",
                ],
            ],
            [
                [
                    "asset-description"
                ]
            ],
            [
                [
                    "asset-value"
                ]
            ],
            [
                [
                    "being-refinanced"
                ]
            ]
        ],
        liabilities: {
            "13-11": {
                "expense": "11-21"
            }
        }
    },

    "10-39|10-40|10-41|10-42|10-43": {
        title: "",
        steps: [
            [
                [
                    "role-selector",
                ]
            ],
            [
                [
                    "year-of-manufacture",
                ],
                [
                    "make",
                ],
                [
                    "model",
                ],
            ],
            [
                [
                    "asset-value"
                ]
            ],
            [
                [
                    "being-refinanced"
                ]
            ]
        ],
        liabilities: {
            "13-11": {
                "expense": "11-21"
            }
        }
    }
}