import Vue from "vue"

export const store = Vue.observable({

    selectedLenderObj: {
        primary: {},
        secondary: {}
    },

    bidData: null,

    isUnsecured: false,

    selectedPrimaryLender: null,
    selectedPrimaryLenderName: null,


    lenderSlugAlgorithms: [],

    updatedLoanResource: null,

})


export const actions = {

    // Resources


}

export const mutations = {

    setPrimary(data) {
        store.selectedLenderObj.primary = data
    },

    setSecondary(data) {
        store.selectedLenderObj.secondary = data
    },
    
    resetPrimary() {
        store.selectedLenderObj.primary['bid-lender'] = {}
    },

    resetSecondary() {
        store.selectedLenderObj.secondary['bid-lender'] = {}
    },

    setBidData(data) {
        store.bidData = data
    },

    setIsUnsecured(data) {
        store.isUnsecured = data
    },

    setSelectedPrimaryLender(data) {
        store.selectedPrimaryLender = data
    },

    setSelectedPrimaryLenderName(data) {
        store.selectedPrimaryLenderName = data
    },

    setLenderSlugAlgorithms(data) {
        store.lenderSlugAlgorithms = data
    },

    setUpdatedLoanResource(data) {
        store.updatedLoanResource = data
    },

}