<template>
    <div>
        <v-stepper v-model="foStepper" alt-labels>
            <v-stepper-items>
                <v-stepper-content v-for="(step, index) in Object.keys(filteredLayout['fo-step'])" :key="index" class="pa-0" :step="index">
                     <v-row justify="center" align="center">
                        <v-col cols="12">
                            <div class="text-center mt-n4" :class="vb.mdAndUp ? 'title' : 'title-2 font-weight-bold'">{{filteredLayout['fo-step'][step].title}}</div>
                        </v-col>
                     </v-row>
                     <ValidationObserver :ref="`observer${index+1}`" class="px-5">

                        <div v-for="(field, key) in filteredLayout['fo-step'][step].fields" :key="key">
                            <v-row justify="center" :style="vb.mdAndUp && !hasLiabiltyType(field) ? 'width: 60%;' : 'width: 100%;'" class="mx-auto">
                                <v-col 
                                    class="my-0 py-0"
                                    v-for="(f, key) in Object.keys(field)" 
                                    :key="key"
                                    cols="12"
                                >
                                <!-- :cols="12" -->

                                    <ValidationProvider 
                                        v-if="isVisible(f)"
                                        :hidden="isHidden(f)"
                                        v-slot="{ errors }" 
                                        :name="labels[f]" 
                                        :rules="getRules(f)">

                                        <PickBox
                                            v-if="f == 'liability-type'"
                                            :options="getLinkKinds"
                                            @input="selectLiabilityType"
                                            v-model="resources[field[f].resources].attributes[f]"
                                            :errors="errors" />

                                        <RoleSelector
                                            v-else-if="f == 'role-selector'"
                                            v-model="roles"
                                            :parts="entityParts"
                                            @input="selectRole"
                                            :errors="errors"
                                            :data="entities" />

                                        <ButtonGroup1 
                                            v-else-if="f == 'to-be-paid-out'"
                                            v-model="resources[field[f].resources].attributes[f]"
                                            :error-messages="errors"
                                            :options="['Yes', 'No']"
                                            :label="labels[f]" />

                                        <v-select
                                            v-else-if="isFieldType('picklist', f)"
                                            :items="getOptions(f)"
                                            :label="labels[f]"
                                            :error-messages="errors"
                                            v-model="resources[field[f].resources].attributes[f]"
                                            color="secondary">
                                        </v-select>

                                        <v-textarea 
                                            v-else-if="isFieldType('textarea', f)"
                                            :label="labels[f]"
                                            rows="10"
                                            :error-messages="errors"
                                            v-model="resources[field[f].resources].attributes[f]"
                                            color="secondary">
                                        </v-textarea>

                                        <template v-else>
                                            <div v-if="f == '__spacer'"></div>
                                            <NodifiMoney
                                                v-else-if="isFieldType('money', f)"
                                                v-model="resources[field[f].resources].attributes[f]"
                                                :label="labels[f]"
                                                :errors="errors" />
                                            <v-text-field
                                                v-else
                                                v-model="resources[field[f].resources].attributes[f]"
                                                :label="labels[f]"
                                                :error-messages="errors">
                                            </v-text-field>
                                        </template>

                                    </ValidationProvider>
                                </v-col>
                            </v-row>
                        </div>
                    </ValidationObserver>
                </v-stepper-content>
            </v-stepper-items>
        </v-stepper>
        <div class="d-flex justify-center pa-2">
            <v-btn
                large
                min-width="125" 
                max-height="50"
                color="secondary"
                class="mx-1"
                @click="back"
                outlined> Back
            </v-btn>
            <v-btn 
                v-if="hideNext"
                large
                min-width="125" 
                max-height="50"
                class="mx-1"
                color="secondary" 
                @click="next" 
                depressed> Next 
            </v-btn>
        </div>
    </div>
</template>
<script>
import shared from '~/components/v1/AssetEditor1/__shared'
import { actions, store } from '~/components/v1/AssetEditor1/__store'
import formHelpers from '~/components/shared/form-helpers'
import ResourceHelpers from '~/components/shared/resource-helpers'
import validation from '~/components/shared/validation'

import RoleSelector from '~/components/v1/AssetEditor1/_AssetEditor1RoleSelector'
import ButtonGroup1 from '~/components/v1/AssetEditor1/_ButtonSelection'
import PickBox from '~/components/v1/AssetEditor1/_AssetEditor1PickBox2'
import { mapActions } from 'vuex'
import fieldOptions from '~/components/shared/field-options'
import foLogic from '~/components/v1/AssetEditor1/fo-field-logic'

export default {
    components: {RoleSelector, ButtonGroup1, PickBox},
    mixins: [ validation, formHelpers, shared, ResourceHelpers ],
    props: {
        foResources: {
            type: Object,
            default: () => {}
        },
        selectedAddress: {
            type: Object,
            default: () => {}
        },
        standalone: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        hideNext() {
            let keys = Object.keys(this.foLayout['fo-step'])
            
            return !(keys[this.foStepper].includes('liability-type') && keys[this.foStepper] == 'liability-type')
        },
        isLastStep() {
           return this.foStepper === Object.keys(this.foLayout['fo-step']).length - 1
        },
        isFirstStep() {
            return this.foStepper == 0
        },
        liabilityId() {
            return this.resources.liabilities.attributes['liability-type']
        },
        financialInstitutionField() {
            return this.resources.liabilities.attributes['financial-institution']
        },
        isEntitiesAndLinkAssetOnlyOne() {
            return this.entities.length === 1 && this.getLinkKinds.length === 1
        },
        filteredLayout() {

            if(this.entities.length === 1 && this.getLinkKinds.length === 1) {
                actions.setPreSelectLinkRoles([this.entities[0].id])
                this.resources.liabilities.attributes['liability-type'] = this.getLinkKinds[0].id

                this.foLayout = {
                    "fo-step": {
                        "finance-details": {
                            "title": "Finance Details",
                            "fields": [
                                {
                                    "amount-owing": {
                                        "resources": "liabilities"
                                    },
                                    "amount-financed": {
                                        "resources": "liabilities"
                                    },
                                    "payment-amount": {
                                        "resources": "expenses"
                                    },
                                    "payment-frequency": {
                                        "resources": "expenses"
                                    },
                                    "financial-institution": {
                                        "resources": "liabilities"
                                    },
                                    "other-institution": {
                                        "resources": "liabilities"
                                    },
                                    "to-be-paid-out": {
                                        "resources": "liabilities"
                                    }
                                }
                            ]
                        },
                    }
                }
            } else if(this.entities.length === 1 && this.getLinkKinds.length != 1) {
                actions.setPreSelectLinkRoles([this.entities[0].id])
                
                this.foLayout = {
                    "fo-step": {
                        "liability-type": {
                            "title": "Liability Type",
                            "fields": [
                                {
                                    "liability-type": {
                                        "resources": "liabilities"
                                    }
                                }
                            ]
                        },
                        "finance-details": {
                            "title": "Finance Details",
                            "fields": [
                                {
                                    "amount-owing": {
                                        "resources": "liabilities"
                                    },
                                    "amount-financed": {
                                        "resources": "liabilities"
                                    },
                                    "payment-amount": {
                                        "resources": "expenses"
                                    },
                                    "payment-frequency": {
                                        "resources": "expenses"
                                    },
                                    "financial-institution": {
                                        "resources": "liabilities"
                                    },
                                    "other-institution": {
                                        "resources": "liabilities"
                                    },
                                    "to-be-paid-out": {
                                        "resources": "liabilities"
                                    }
                                }
                            ]
                        },
                    }
                }
            } else if(this.entities.length != 1 && this.getLinkKinds.length === 1) {
                 this.resources.liabilities.attributes['liability-type'] = this.getLinkKinds[0].id
                
                this.foLayout = {
                    "fo-step": {
                        "role-selector": {
                            "title": "Who's name is on the loan?",
                            "fields": [
                                {
                                    "role-selector": {
                                        "resources": "assets"
                                    }
                                }
                            ]
                        },
                        "finance-details": {
                            "title": "Finance Details",
                            "fields": [
                                {
                                    "amount-owing": {
                                        "resources": "liabilities"
                                    },
                                    "amount-financed": {
                                        "resources": "liabilities"
                                    },
                                    "payment-amount": {
                                        "resources": "expenses"
                                    },
                                    "payment-frequency": {
                                        "resources": "expenses"
                                    },
                                    "financial-institution": {
                                        "resources": "liabilities"
                                    },
                                    "other-institution": {
                                        "resources": "liabilities"
                                    },
                                    "to-be-paid-out": {
                                        "resources": "liabilities"
                                    }
                                }
                            ]
                        },
                    }
                }
            } else {
                this.foLayout = {
                    "fo-step": {
                        "role-selector": {
                            "title": "Who's name is on the loan?",
                            "fields": [
                                {
                                    "role-selector": {
                                        "resources": "assets"
                                    }
                                }
                            ]
                        },
                        "liability-type": {
                            "title": "Liability Type",
                            "fields": [
                                {
                                    "liability-type": {
                                        "resources": "liabilities"
                                    }
                                }
                            ]
                        },
                        "finance-details": {
                            "title": "Finance Details",
                            "fields": [
                                {
                                    "amount-owing": {
                                        "resources": "liabilities"
                                    },
                                    "amount-financed": {
                                        "resources": "liabilities"
                                    },
                                    "payment-amount": {
                                        "resources": "expenses"
                                    },
                                    "payment-frequency": {
                                        "resources": "expenses"
                                    },
                                    "financial-institution": {
                                        "resources": "liabilities"
                                    },
                                    "other-institution": {
                                        "resources": "liabilities"
                                    },
                                    "to-be-paid-out": {
                                        "resources": "liabilities"
                                    }
                                }
                            ]
                        },
                    }
                }
            }

            return this.foLayout
        }
    },
    data() {
       return {
           foLayout: foLogic,
           visible: {
               "other-institution": false
           },
           hidden: {},
           roles: [],
           foStepper: 0,
           processing: false,
           resources: {
                expenses: {
                    id: null,
                    type: 'expenses',
                    attributes: {
                        "payment-amount": null,
                        "payment-frequency": null,
                    }
                },
                liabilities: {
                    id: null,
                    type: 'liabilities',
                    attributes: {
                        "liability-type": null,
                        "financial-institution": null,
                        "other-institution": null,
                        "amount-financed" : null,
                        "amount-owing": null,
                        "to-be-paid-out": null
                    }
                }
            },
            labels: {
                "role-selector": "Role",
                "liability-type": "Liability",
                "payment-amount": "Payment Amount",
                "payment-frequency": "Payment Frequency",
                "amount-financed": "Amount Financed",
                "amount-owing": "Amount Owing",
                "financial-institution": "Financial Institution",
                "other-institution": "Other Institution",
                "to-be-paid-out": "Is this to be paid out before the loan starts",
           },
           validation_rules: {
                attributes: {
                    "liability-type": "required",
                    "role-selector": "required",
                    "payment-amount": "required|between:0,99999999.99",
                    "payment-frequency": "required|in:Weekly,Fortnightly,Monthly,Annual",
                    "amount-financed": "required|between:0,99999999.99",
                    "amount-owing": "required|between:0,99999999.99",
                    "financial-institution": `required|in:${fieldOptions["financial-institution"]}`,
                    "other-institution": "",
                    "to-be-paid-out": "required|string|in:Yes,No"
                }
            }
       } 
    },
    mounted() { 
        // if(this.entities.length === 1) {
        //     actions.setPreSelectLinkRoles([this.entities[0].id])
        //     this.foStepper = 1
        // }

        // if(this.isEntitiesAndLinkAssetOnlyOne) {
        //     this.resources.liabilities.attributes['liability-type'] = this.getLinkKinds[0].id

        //     this.foStepper = 2
        // }
    },

    methods: {
        ...mapActions('resource-actions', [
            'createAddress',
            'updateAddress',
            'createAsset',
            'updateAsset',
            'createLiability',
            'updateLiability',
            'createExpense',
            'updateExpense',
            'getAsset'
        ]),
        async next() {
            // find observer of current step
            let ob = Object.keys(this.$refs).filter((ob) => ob == `observer${this.foStepper+1}`)
            const valid = await this.$refs[ob[0]][0].validate()

            if(valid) {
                if(this.isLastStep) {
                    let res = await this.save()

                    if(res.status === 200 && res.status === 201) {
                        assets = await this.getAsset(res.data.data.id)
                        await actions.updateResources(assets.data.data)
                    }

                    await this.saveFo(res)
                } else {
                    this.foStepper++
                }
            }
        },
        hasLiabiltyType(obj) {

            let has = false

            Object.keys(obj).forEach(key => {
                if(key == 'liability-type') {
                    has = true
                }
            })

            return has
        },
        saveFo(res) {
            if(!this.standalone) {
                this.$emit('save-and-close', res)
                this.updateEntitiesAndParts()
                this.resetFields()
                store.saving = false
            } else{
                this.$emit('update-and-close', res)
                this.updateEntitiesAndParts()
                this.resetFields()
                store.saving = false
            }
        },
        async save() {
            store.saving = true

            // save address first
            let addressPayload = await this.saveAddress()

            // save incomes
            let payloadAssets = await this.getPayload('assets')

            if(Object.keys(addressPayload).length > 0) {
                payloadAssets.relationships.address = addressPayload
            }

            let resAssets = null

            if(this.resources.assets?.id) {
                payloadAssets.id = this.resources.assets.id 
                resAssets = await this.updateAsset(payloadAssets)
            } else {
                resAssets = await this.createAsset(payloadAssets)
            }

            if(resAssets) {
                // save expenses
                let resExpenses = null
                let payloadExpense = await this.getPayload('expenses')
                payloadExpense.relationships.assets = {
                    "data": [
                        {
                            "type": "assets",
                            "id": resAssets.data.data.id
                        }
                    ]
                }

                if(this.resources.expenses?.id) {
                    payloadExpense.id = this.resources.expenses.id 
                    resExpenses = await this.updateExpense(payloadExpense)
                } else {
                    resExpenses = await this.createExpense(payloadExpense)
                }

                await actions.updateResources(resExpenses.data.data)

                // save liabilities
                let resLiabilities = null
                let payloadLiabilities = await this.getPayload('liabilities')
                payloadLiabilities.relationships.expenses = {
                    "data": [
                        {
                            "type": "expenses",
                            "id": resExpenses.data.data.id
                        }
                    ]
                }
                payloadLiabilities.relationships.assets = {
                    "data": [
                        {
                            "type": "assets",
                            "id": resAssets.data.data.id
                        }
                    ]
                }

                if(this.resources.liabilities?.id) {
                    payloadLiabilities.id = this.resources.liabilities.id 
                    resLiabilities = await this.updateLiability(payloadLiabilities)
                } else {
                   resLiabilities = await this.createLiability(payloadLiabilities)
                }

                await actions.updateResources(resLiabilities.data.data)

            }

            return resAssets

        },
        back() {
            if(this.isFirstStep) {
                this.$emit('back-to-parent')
            } else {
                this.foStepper--
            }
        },
        selectLiabilityType(data) {
            store.saving = true
            this.resources.liabilities.attributes['liability-type'] = data

            if(this.liabilityId) {
                setTimeout(() => {
                    this.next()
                    store.saving = false
                }, 100);
            }

        },
        selectRole(data) {
            actions.setPreSelectLinkRoles(data)
        },
        closeModal() {
            this.$emit('close-modal')
            this.resetFields()
        },
        resetFields() {
            this.resource = {
                expenses: {
                    id: null,
                    type: 'expenses',
                    attributes: {
                        "payment-amount": null,
                        "payment-frequency": null,
                    }
                },
                liabilities: {
                    id: null,
                    type: 'liabilities',
                    attributes: {
                        "liability-type": null,
                        "financial-institution": null,
                        "other-institution": null,
                        "amount-financed" : null,
                        "amount-owing": null,
                        "to-be-paid-out": null
                    }
                }
           }
           this.foStepper = 0
            actions.setPreSelectLinkRoles([])
        },
        getPayload(type = '') {
            let payload = {}

            payload = {
                type: type,
                attributes: this.excludeInvalidAttributes(this.resources[type].attributes),
                relationships: {
                    application: {
                        data: {type: "applications", id: this.appId}
                    },
                    people: this.getPeopleRelationship(type),
                    businesses: this.getBusinessesRelationship(type),
                    kind: this.getKinds(type)
                }
            }

            return payload
        },
        getKinds(type = 'assets') {
            let kinds = {}

            if(type == 'liabilities') {
                kinds = {
                    data: {
                        type: 'kinds',
                        id: this.liabilityId
                    }
                }
            } else if(type == 'expenses') {
                kinds = {
                    data: {
                        type: 'kinds',
                        id: this.linkLiability[this.liabilityId].expense
                    }
                }
            } else {
                kinds = {
                    data: {
                        type: 'kinds',
                        id: this.kindsAssetId
                    }
                }
            }

            return kinds
        },
        async saveAddress() {
            let { selectedAddress } = this
            let payloadAddress = {}

            if(selectedAddress != null){
                    
                if(selectedAddress.id){
                    payloadAddress = {
                        data: {
                            type: 'addresses',
                            id: selectedAddress.id 
                        }
                    }
                    if(selectedAddress?.relationships)
                        selectedAddress.relationships = this.filterRelationships(selectedAddress.relationships)
                    await this.updateAddress(selectedAddress)
                } else {
                    let relationships = {
                        application: {
                            data: {type: "applications", id: this.appId}
                        }
                    }
                    this.selectedAddress.relationships = relationships
                    delete this.selectedAddress.id
                    let address = await this.createAddress(selectedAddress)
                    payloadAddress = {
                        data: {
                            type: 'addresses',
                            id: address.data.data.id
                        }
                    }
                }
            }

            return payloadAddress
        },
        setResources() {
            let { foResources, resources } = this

            Object.keys(foResources).forEach(resource => {
                //store asset resources
                if(!resources[resource]) {
                    resources[resource] = foResources[resource]
                } else {

                    resources[resource].id = foResources[resource].id
                    resources[resource].relationships = foResources[resource].relationships

                    Object.keys(foResources[resource].attributes).forEach(attr => {
                        resources[resource].attributes[attr] = foResources[resource].attributes[attr]
                    })

                }
            })

        },
        fieldSizesLogic(step, field){

            if(step == 'finance-details' && field != 'to-be-paid-out' && this.vb.smAndUp) {
                return 6
            } else {
                return 12
            }

        }
    },
    watch: {
        financialInstitutionField(val) {
            if(val == 'Other') {
                this.visible['other-institution'] = true
                this.validation_rules.attributes['other-institution'] = "required"
            } else {
                this.visible['other-institution'] = false
                this.validation_rules.attributes['other-institution'] = ""
            }
        },
        preSelectLinkRoles(val) {
            this.setResources()
            this.roles = val
        },
        'foResources': {
            handler(val) {
                if(val?.liabilities?.relationships?.kind?.data) {
                    this.resources.liabilities.attributes['liability-type'] = val.liabilities.relationships.kind.data.id
                }
            },
            deep: true
        },
    }
    
}
</script>
