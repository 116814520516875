import {stringify} from 'qs'

export const state = () => ({
    userSettings: null,
    user: null,
    appOwnerSettings: null,
    hasNotFound: false,
    hasUnauthorized: false,
    hasFlaggedFlow: false,

    qrCode2FA: null,
    recoveryCodes2FA: [],
})

export const getters = {
    getUserSetting: (state) => {
        return state.userSettings
    },
    getHasNotFound: (state) => {
        return state.hasNotFound
    },
    getHasUnauthorized: (state) => {
        return state.hasNotFound
    },
    getHasFlaggedFlow: (state) => {
        return state.hasNotFound
    },
    getAppOwnerSettings: (state) => {
        return state.appOwnerSettings
    },
}

export const actions = {
    async getAuth({commit}, params = {}) {
        let res = await this.$auth.loginWith('local', {
            data: stringify(params)
        })
        return res
    },

    async getUser({}, params = {}) {
        return await this.$api.get('/users/?run-me', params)
    },

    async fetchUser({}, id = null) {
        return await this.$api.get(`users/${id}`)
    },

    async saveUser({}, params = {}) {
        return await this.$api.post('/users', params)
    },

    async getUsers() {
        return await this.$api.get('users')
    },

    async getUserSetting({}, id = '') {
        return await this.$api.get(`users/${id}/user-setting`)
    },

    async resetPassword({}, params = {}) {
        return await this.$api.post(`password/reset`, {
            data: params
        } )
    },

    async forgotPassword({}, params = {}) {
        return await this.$api.post(`password/forgot-password`, {
            data: params
        })
    },


    async enable2FA({}) {
        return await this.$api.post('two-factor-enable')
    },
    async get2FAQR() { 
        return await this.$api.get('two-factor-qr-code')
    },
    async get2FARecoveryCodes() {
        return await this.$api.get('two-factor-recovery-codes')
    },
    async confirm2FAChallenge({}, params = {}) {
        return await this.$api.post('two-factor-confirm', params)
    },
    async confirmPassword({}, params = {}) {
        return await this.$api.post('confirm-password', params)
    },
    async disable2FA() {
        return await this.$api.delete('two-factor-disable')
    },
}


export const mutations = {
    setUserSetting(state, data) {
        state.userSettings = data
    },
    storeUserData(state, data) {
        state.user = data
    },
    clearUserSetting(state) {
        state.userSettings = null
    },
    setHasNotFound(state) {
        state.hasNotFound = true
    },
    setHasUnauthorized(state) {
        state.hasUnauthorized = true
    },
    setHasFlaggedFlow(state) {
        state.getHasFlaggedFlow = true
    },
    setAppOwnerSettings(state, data) {
        state.appOwnerSettings = data
    },
    setQrCode2FA(state, payload) {
        state.qrCode2FA = payload
    },
    setRecoveryCodes2FA(state, payload) {
        state.recoveryCodes2FA = payload
    },
}