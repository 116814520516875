<template>
    <v-dialog
        v-model="dialog"
        width="900"
        persistent>
        
        <template v-slot:activator="{ on, attrs }">
            <v-btn 
                depressed large
                :disabled="disabled"
                :loading="syncLoading"
                class="secondary mx-2"
                v-bind="attrs"
                v-on="on">
                <div v-if="$vuetify.breakpoint.smAndUp">
                    BID Document
                </div>
                <div v-else>
                    SOCA
                </div>
            </v-btn>
        </template>

        <v-card class="overflow-y-auto" flat>

            <v-progress-linear 
                v-if="pageLoader"
                color="secondary"
                indeterminate stream top 
                value="0">
            </v-progress-linear>

            <v-overlay
                absolute
                :opacity="0.1"
                v-if="pageLoader">
            </v-overlay>

            <v-card-title>
                <v-spacer />
                <v-btn icon @click="dialog = false" >
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-card-title>

            <v-card-text class="pa-0">
                <v-card width="600" tile flat class="rounded-lg mx-auto">
                    <v-card-title class="pb-10">
                       Generate BID Document
                    </v-card-title>
                    <v-card-text>

                        <template v-if="step == 1 || step == 2">
                            <label class="font-weight-bold">Does the client fit the <a href="https://nodifi.box.com/s/yxwbuombboifh3hc08tvx5lb6br3boc7" target="_blank">Target Market Determination</a> for the product?</label>
                            <ReferralPartner 
                                v-model="form['tmd-client-fit']"
                                label="">
                            </ReferralPartner>  
                        </template>

                        <ValidationObserver v-if="step == 2" ref="observer" tag="form">

                            <ValidationProvider 
                                v-slot="{ errors }" 
                                v-if="showWhyRecommend"
                                name="Why have you recommended this product to the client"
                                rules="required" 
                                vid="tmd-client-recommendation-reason">
                                <v-select
                                    v-model="form['tmd-client-recommendation-reason']"
                                    :items="tmdPickList"
                                    item-text="name"
                                    item-value="value"
                                    label="Why have you recommended this product to the client"
                                    :error-messages="errors"
                                >

                                </v-select>
                            </ValidationProvider>

                            <ValidationProvider 
                                v-if="form['tmd-client-recommendation-reason'] == 'Other'"
                                v-slot="{ errors }"
                                name="Your answer"
                                rules="required" 
                                vid="tmd-client-recommendation-reason-other">
                                <v-textarea
                                    label="Please outline how the customer does not meet the Target Market?"
                                    :error-messages="errors"
                                    v-model="form['tmd-client-recommendation-reason-other']"
                                ></v-textarea>
                            </ValidationProvider>

                            <v-alert dense type="info" text>
                                <div class="font-size-12">
                                    Please note: under broker Design and Distribution Obligations (RG 274) please ensure that you carefully consider your client and the product target market when answering this question. If you indicate that the client does not meet the target market, we will provide this reporting directly to the lender in line with our distributor obligations under RG 274.
                                </div>
                            </v-alert>

                        </ValidationObserver>

                        <ValidationObserver v-if="step == 3" ref="observer2" tag="form">

                            <ReferralPartner 
                            v-model="referralPartner"
                            label="Will you be paying a referral partner for this deal?" />

                            <ValidationProvider 
                                v-slot="{ errors }" 
                                name="Referrer Name"
                                rules="required" 
                                vid="referral-partner-name">
                                <v-text-field                                
                                    v-model="form['referral-partner-name']"
                                    label="Referrer Name"
                                    :error-messages="errors">
                                </v-text-field>
                            </ValidationProvider>

                            <ValidationProvider 
                                v-slot="{ errors }" 
                                name="Referral Fee Estimate"
                                rules="required" 
                                vid="referral-fee-estimate">
                                
                                <NodifiMoney
                                    v-model="form['referral-fee-estimate']"
                                    label="Referral Fee Estimate"
                                    :errors="errors" />

                            </ValidationProvider>

                        </ValidationObserver>
                        
                    </v-card-text>
                </v-card>
            </v-card-text>


            <v-card height="20" tile flat />

            <v-divider></v-divider>

            <v-card-actions class="pa-6 pa-md-8 flex-wrap controls">                                
                <v-btn
                    outlined
                    color="grey lighten-1"
                    large
                    style="min-width:140px;"
                    @click="back">
                    Back
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn
                    v-if="step == 3"
                    color="secondary"
                    depressed
                    large
                    style="min-width:140px;"
                    @click="saveData">
                    Generate PDF
                </v-btn>
                <v-btn
                    v-else-if="step == 2 || step != 1"
                    color="secondary"
                    depressed
                    large
                    style="min-width:140px;"
                    @click="next">
                    Next
                </v-btn>
            </v-card-actions>
        </v-card>

    </v-dialog>
</template>

<script>

    import { mapActions } from 'vuex'
    import ReferralPartner from '~/components/base/ButtonSelections'
    import formHelpers from '~/components/shared/form-helpers'
    import validation from '~/components/shared/validation'
    import helpers from '~/plugins/helpers'
    import shared from './__shared'

    export default {
        name: 'QuoteToolGenerateSocaDialog',

        mixins: [ helpers, validation, formHelpers, shared ],

        props: {
            bidResource: {
                type: Object,
                default: () => {}
            },
            disabled: {
                type: Boolean,
                default: false
            },
            syncLoading: {
                type: Boolean,
                default: false
            }
        },

        components: {
            ReferralPartner
        },

        computed: {
            TmdClientFit() {
                return this.form['tmd-client-fit']
            },
            showWhyRecommend() {
                if(this.TmdClientFit == "No") {
                    return false
                }

                return true
            }
        },

        methods: {
            ...mapActions('resource-actions', [
                'updateBid'
            ]),
            close() {
                this.dialog = false
            },
            back() {
                this.form['tmd-client-recommendation-reason'] = null
                this.form['tmd-client-recommendation-reason-other'] = null
                this.step = 1
            },
            async next() {
                const valid = await this.$refs.observer.validate()
                if(valid) {
                    this.step = 3
                    this.referralPartner = 'Yes'
                }
            },
            async saveData() {
                
                this.pageLoader = true
                const valid = await this.$refs.observer2.validate()

                let obj = {
                    id: this.bidResource.id,
                    type: 'bids',
                    attributes: this.form
                }

                if ( valid ) {
                    let res = await this.updateBid(obj)
                    
                    if ( res.status === 200 )
                        Object.keys(this.form).forEach(f => {
                            this.form[f] = res.data.data.attributes[f]
                        })

                        this.$emit('saved-referrals', this.form)
                    this.dialog = false
                }

                this.pageLoader = false
                
            }
        },

        watch: {
            'referralPartner': {
                handler(val) {
                    if ( val === 'No' ) {
                        this.form['referral-partner-name'] = 'Not Applicable'
                        this.form['referral-fee-estimate'] = '0.00'
                    }
                    else {
                        this.form['referral-partner-name'] = this.bidResource?.attributes?.['referral-partner-name'] || ''
                        this.form['referral-fee-estimate'] = this.bidResource?.attributes?.['referral-fee-estimate'] || ''
                    }
                    // console.log('referral', val)
                },
                deep: true
            },
            step(val) {
                // console.log(val)
            },
            TmdClientFit(val) {
                if(val == 'Yes') {
                    this.step = 3
                    this.form['tmd-client-recommendation-reason'] = null
                    this.form['tmd-client-recommendation-reason-other'] = null
                } else if(val == 'No') {
                    this.step = 2
                    this.form['tmd-client-recommendation-reason'] = 'Other'
                } else {
                    this.step = 1
                }
            },
            'dialog': {
                handler(val) {
                    if ( val ) {

                        this.form['referral-partner-name'] = this.bidResource.attributes['referral-partner-name']
                        this.form['referral-fee-estimate'] = this.bidResource?.attributes?.['referral-fee-estimate']
                        this.form['tmd-client-fit'] = this.bidResource?.attributes?.['tmd-client-fit']
                        this.form['tmd-client-recommendation-reason'] = this.bidResource?.attributes?.['tmd-client-recommendation-reason']
                        this.form['tmd-client-recommendation-reason-other'] = this.bidResource?.attributes?.['tmd-client-recommendation-reason-other']

                    }
                },
                deep: true
            }
        },

        data () {
            return {
                step: 1,
                dialog: false,
                referralPartner: null,
                tmd: null,
                form: {
                    'referral-partner-name': '',
                    'referral-fee-estimate': '',
                    'tmd-client-fit': 'Yes',
                    'tmd-client-recommendation-reason': '',
                    'tmd-client-recommendation-reason-other': ''
                },
                tmdPickList: [
                    {name: 'The product is in the best interests of the client', value: 'Client Best Interest'},
                    {name: 'The client specifically requested this product', value: 'Client Requested'},
                    {name: 'Other (please provide details)', value:"Other"}
                ],
                pageLoader: false
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>