<template>

    <v-menu
        v-model="menu"
        :close-on-content-click="false"
        max-width="290">

        <template v-slot:activator="{ on, attrs }">
            <v-text-field
                :value="modelValue | dateFormatFE" 
                :label="label"
                readonly
                v-bind="attrs"
                v-on="on"
                color="secondary"
                :error-messages="errors"
                :disabled="disabled"
                @click:clear="handleClear"
                >
            </v-text-field>
        </template>

        <v-date-picker
            v-model="date"
            color="secondary"
            header-color="light_gray_1"
            @change="handleChange">
        </v-date-picker>

    </v-menu>

</template>

<script>

    export default {
        data(){
            return {
                menu: false,
                date: null
            }
        },
        model: {
            prop: 'modelValue',
            event: 'change'
        },
        props: {
            modelValue: {
                type: String,
                default: ''
            },
            name: {
                type: String,
                default: ''
            },
            maxDate: {
                type: [Number],
                default: 100 // +100 years | -100 years
            },
            minDate: {
                type: [Number],
                default: 0 // 0 == Present Date
            },
            disabled: {
                type: Boolean,
                default: false
            },
            delimiter: {
                type: String,
                default: '-'
            },
            label: {
                type: String,
                default: ''
            },
            errors: {
                type: Array,
                default: ()=>[]
            }
        },
        computed: {

        },
        
        watch: {
            modelValue(val) {
                /**
                 * Vuetify wont accept our format of date, only ISO format
                 */
                if (val) {
                    this.date = this.$options.filters.dateFormat(this.modelValue)
                    return
                }

                this.date = ''
            }
        },
        mounted(){
            this.date = this.$options.filters.dateFormat(this.modelValue)
        },
        methods: {
            handleChange(val){
                this.menu = false
                this.$emit('change', this.$options.filters.dateFormat(val))
            },
            handleClear(){
                this.date = null
                this.$emit('change', null)
            }
        }
        
    }
</script>

<style scoped lang="scss">
    

</style>

