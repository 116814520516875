<template>

    <v-card :disabled="isDisabled" class="mx-auto" tile flat>

        <v-card-text class="pa-4 pt-0 pa-md-10 pt-md-0">
            <v-sheet class="mx-auto" max-width="900">
                <h2 class="mb-1">Please tell us about the general expenses for the household</h2>
                <p class="body-1">(These values should be the full amount for the household, not just an individual share, and are not related to a financial liabilities)</p>
            </v-sheet>
        </v-card-text>


        <v-card-text class="pa-4 pt-0 pa-md-10 pt-md-0">
            <v-sheet class="mx-auto" max-width="900">

                <div class="d-flex mb-2" v-if="isRentBoard">
                    <div style="width:50%">
                        <div class="body-1 font-weight-bold">Rent / Board</div>
                        <div class="body-1"></div>
                    </div>
                    <div :style="vb.mdAndUp ? 'width:30%' : 'width:50%'">
                        <nodifi-money
                            class="pa-0 ma-0"
                            disabled
                            v-model="form2['payment-amount']" />
                    </div>
                    <div v-if="vb.mdAndUp" style="width:20%">
                        <v-select v-model="form2['payment-frequency']" :items="items" :disabled="true" class="pa-0 ma-0 pl-4">
                        </v-select>
                    </div>
                </div>

                <div class="d-flex mb-2" v-for="(field,key) in fields" :key="key">
                    <div style="width:50%">
                        <div class="body-1 font-weight-bold">{{field.name}}</div>
                        <div class="body-2">{{field.subname}}</div>
                    </div>
                    <div :style="vb.mdAndUp ? 'width:30%' : 'width:50%'">
                        <v-text-field
                            class="pa-0 ma-0"
                            :id="field.model"
                            prefix="$"
                            @keyup.enter="changeFocus(key)"
                            v-model="form[field.model]" />
                    </div>
                    <div v-if="vb.mdAndUp" style="width:20%">
                        <v-select value="Monthly" :items="items" :disabled="true" class="pa-0 ma-0 pl-4">
                        </v-select>
                    </div>
                </div>

                <div class="d-flex justify-end">
                    <div class="total my-4 pa-6 rounded-xl" :style="`background-color: ${hexToRGBA(getActiveTheme.colors.secondary, 0.1)}`">
                        <div class="body-1 text-right mb-2">Total general household expenses</div>
                        <h3 class="font-size-24 text-right">{{ totalExpense | currency }} per month</h3>
                    </div>
                </div>

            </v-sheet>
        </v-card-text>
    </v-card>
</template>

<script>
import resourceHelpers from '~/components/shared/resource-helpers'
import { mapState, mapGetters, mapActions, mapMutations } from 'vuex'
import subscriptions from '~/components/shared/subscriptions'
export default {
    name:'ExpenseEditor1',
    mixins: [resourceHelpers,subscriptions],
    computed: {
        ...mapState({
            appId: state => state.flows.appId,
            appData: state => state.flows.appData
        }),
        ...mapGetters({
            getActiveTheme: 'themes/getActiveTheme',
        }),        
        totalExpense () {

            let total = 0

            this.fields.forEach(field => {
                if(this.form[field.model]  == ""){
                    this.form[field.model] = 0
                }
               let result = parseInt(this.form[field.model])
               total = total + result
              
            })
           
            return  total ? total : 0;
            
        },
        vb() {
            return this.$vuetify.breakpoint
        },
        peopleId () {

            if(this.$route?.params && this.$route?.params.record == 'people') {

                return this.$route?.params?.record_id
            }

            return null

        },
        paymentAmount() {
            return this.form['payment-amount']
        },
        
        totalPaymentAmount () {
            return ["living-food-and-clothing",
            "living-transport",
            "living-utilities-and-rates",
            "living-lifestyle",
            "living-tv-and-communications",
            "living-child-care-fees",
            "living-family-maintenance",
            "living-insurance",
            "living-education",
            "living-other-living-expenses"].reduce((prev, curr) => {
                prev += parseInt(this.form[curr])
                return prev
            },0);
        }
    },
    methods: {
        ...mapActions('resource-actions', [
            'getExpense',
            'createExpense',
            'updateExpense',
            'getPeople',
            'getPerson',
            'getExpense'
        ]),
        async pull () {

            try {

                let promise1 = await this.getPeople({"include" : 'expenses,occupancies'})

                let included = promise1.data.included

                // logic for rent or board expenses show when current occupancy is not Own Outright or Mortgage - Owner Occupied
                if(included.length > 0) {
                    included.forEach(exp => {
                        if(exp.type == 'occupancies') {
                            if(exp.attributes['occupancy-situation'] == 'Current') {
                                if(exp.attributes['residential-situation'] == 'Own Outright' || exp.attributes['residential-situation'] == 'Mortgage - Owner Occupied') {
                                    this.isRentBoard = false
                                }
                            }
                        }
                    })
                }

                // get expense
                if(included.length > 0) {
                    included.forEach(include => {
                        // get the living expense
                        if(include.type == 'expenses') {
                            if(include.relationships.kind.data.id === '11-16') {
                                this.livingExpense = include
                            }
                            // get the rent or board expense 11-3 Boarder 11-8 Renter Agent 11-9 Renter Private
                            if(include.relationships.kind.data.id === '11-3' || include.relationships.kind.data.id === '11-8' || include.relationships.kind.data.id === '11-9') {
                                this.RentBoardExpense = include
                            }
                        }
                    })
                }

                // populate fields for rent or board expense
                if(this.RentBoardExpense) {
                    Object.keys(this.RentBoardExpense.attributes).forEach(rent => {
                        this.form2[rent] = this.RentBoardExpense.attributes[rent]
                    })
                }

                // populate fields for living expense
                if(this.livingExpense) {
                    Object.keys(this.livingExpense.attributes).forEach(living => {
                        this.form[living] = this.livingExpense.attributes[living]
                    })
                }

            } catch (error) {

                console.log('Ooops!..', error)

            }
        
        },
        async push () {

            // let relationships = this.application.relationships
            // let expenses = relationships.expenses?.data
            // this.resources.attributes = {...this.form}
            // this.resources.type = 'expenses'
            // this.resources.relationships.application.data.id = this.appId
            // this.resources.relationships.people.data = this.appData.relationships.people.data

            let payload = {
                "type": 'expenses',
                "attributes": {},
                "relationships": {
                    "application": {
                        "data": {
                            "type": "applications",
                            "id": this.appId
                        }
                    },
                    "people": {
                        "data": this.appData.relationships.people.data
                    }
                }
            }

            // save and update living expense
            if(this.livingExpense && this.livingExpense.id) {
                payload.id = this.livingExpense.id
                payload.attributes = {
                    ...this.form,
                    'payment-frequency': this.form2['payment-frequency'],
                    'payment-amount': this.totalPaymentAmount
                }

                await this.updateExpense(payload)
            } else {
                payload.attributes = {
                    ...this.form,
                    'payment-frequency': this.form2['payment-frequency'],
                    'payment-amount': this.totalPaymentAmount
                }
                payload.relationships.kind = {
                    "data": {
                        "type": "kinds",
                        "id": "11-16"
                    }
                }

                await this.createExpense(payload)
            }

            // save and update board expense
            if(this.RentBoardExpense && this.RentBoardExpense.id) {
                payload.id = this.RentBoardExpense.id
                payload.attributes = {
                    ...this.form2
                }
                delete payload.relationships.kind

                await this.updateExpense(payload)
            } else {
                payload.attributes = {
                    ...this.form2
                }
                payload.relationships.kind = {
                    "data": {
                        "type": "kinds",
                        "id": "11-8"
                    }
                }

                await this.createExpense(payload)
            }

            // if(expenses.length > 0){
            //     this.resources.id = this.expenseId
            //     this.updateExpense(this.resources)
            //     return {valid:true, status:'valid', data:{}}
            // } else {
            //     if(this.resources.hasOwnProperty('id')){
            //         delete this.resources.id
            //     }
            //     this.createExpense(this.resources)
            //     return {valid:true, status:'valid', data:{}}
            // }

            return { valid:true, status:'valid', data:{} }
        },
        hexToRGBA(hex, a = 1){
            return this.$options.filters.hexToRGBA(hex, a)
        },
        changeFocus(index) {
            if(this.fields[index+1]) {
                document.getElementById(this.fields[index+1].model).focus()
            }
        }
    },
    data(){
        return {
            isRentBoard: true,
            livingExpense: null,
            RentBoardExpense: null,
            application:null,
            items:[
                'Monthly',
                'Weekly',
                'Fortnightly',
                'Annual'
            ],
            expenseId: null,
            fields:[
                // {
                //     name: 'Rent / Board',
                //     subname: '',
                //     model:'payment-amount',
                //     type: 'Monthly'
                // },
                {
                    name: 'Food & clothing',
                    subname: '(Groceries, eating out)',
                    model:'living-food-and-clothing',
                    type: 'Monthly'
                },
                {
                    name: 'Transport',
                    subname: '(Public transport,fuel,vehicle registration)',
                    model:'living-transport',
                    type: 'Monthly'
                },
                {
                    name: 'Utilities & rates',
                    subname: '(streaming subscriptions,phone bill)',
                    model:'living-utilities-and-rates',
                    type: 'Monthly'
                },
                {
                    name: 'Lifestyle',
                    subname: '(Gym memberships,hair & beauty)',
                    model:'living-lifestyle',
                    type: 'Monthly'
                },
                {
                    name: 'TV & communicatons',
                    subname: '(streaming subscriptions,phone bill)',
                    model:'living-tv-and-communications',
                    type: 'Monthly'
                },
                {
                    name: 'Child care fees',
                    subname: '',
                    model:'living-child-care-fees',
                    type: 'Monthly'
                },
                {
                    name: 'Family maintenance',
                    subname: '',
                    model: 'living-family-maintenance',
                    type: 'Monthly'
                },
                {
                    name: 'Insurance',
                    subname: 'Home,contents,car',
                    model:'living-insurance',
                    type: 'Monthly'
                },
                {
                    name: 'Education',
                    subname: '',
                    model: 'living-education',
                    type: 'Monthly'
                },
                {
                    name: 'Other',
                    subname: '',
                    model:'living-other-living-expenses',
                    type: 'Monthly'
                },
            ],
            form:{
                "living-food-and-clothing": 0,
                "living-transport": 0,
                "living-utilities-and-rates": 0,
                "living-lifestyle": 0,
                "living-lifestyle": 0,
                "living-child-care-fees": 0,
                "living-family-maintenance": 0,
                "living-insurance": 0,
                "living-education": 0,
                "living-other-living-expenses": 0,
                'living-tv-and-communications': 0,
            },
            form2: {
                "payment-frequency": "Monthly",
                "payment-amount": 0
            },
            resources: {
                type: null,
                id:null,
                attributes: {

                },
                relationships: {
                    application: {
                        data: {
                            type: "applications",
                            id:null
                        }
                    },
                    people: {

                    }
                }
            },
            temp: {

            }
        }
    },
    watch: {
        // paymentAmount(val) {
        //     console.log(val)
        // }
    }
}
</script>
<style lang="scss">

</style>