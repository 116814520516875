<template>
    
        <v-card :disabled="isDisabled || !validComponentData" class="mx-auto" tile flat>

            <Portal v-if="$vuetify.breakpoint.xl" to="step-header-buttons">

                <v-btn
                    depressed
                    large
                    color="secondary" 
                    class="order-first"
                    href="https://nodifi.box.com/s/yxwbuombboifh3hc08tvx5lb6br3boc7" 
                    target="_blank">
                    TMD Files
                </v-btn>

                <GenerateSoca
                    @saved-referrals="generateSoca"
                    :disabled="!selectedAndConfirmedLender"
                    :sync-loading="pdfLoading"
                    :bid-resource="bidResource" />
                
                <QuotableComponentDialog
                    title="Quick Edit"
                    @confirm-update="getUpdateLoan" />
                
                <v-btn
                    v-if="lenders.length > 0"
                    @click="selectNextStep"
                    :disabled="!selectedAndConfirmedLender"
                    large depressed                
                    class="secondary ml-2">
                    Proceed to Submission
                </v-btn>
                <v-btn
                    v-else
                    @click="selectNextStep"
                    large depressed                
                    class="secondary ml-2">
                    Proceed to Submission
                </v-btn>

            </Portal>
            
            <v-card-text class="pa-4 pt-0 pa-md-10 pt-md-0">

                <v-container class="pa-0" v-if="$vuetify.breakpoint.lgAndDown">
                    <v-card flat class="pa-0 ma-0 pb-10">                    
                        <v-card-text class="text-center px-0 mx-0">

                            <v-btn
                                depressed
                                large
                                color="secondary" 
                                class="order-first"
                                href="https://nodifi.box.com/s/yxwbuombboifh3hc08tvx5lb6br3boc7" 
                                target="_blank">
                                TMD Files
                            </v-btn>

                            <GenerateSoca
                                @saved-referrals="generateSoca"
                                :disabled="!selectedAndConfirmedLender"
                                :sync-loading="pdfLoading"
                                :bid-resource="bidResource" />

                            <QuotableComponentDialog
                                title="Quick Edit"
                                @confirm-update="getUpdateLoan" />
                            
                            <v-btn
                                v-if="lenders.length > 0"
                                @click="selectNextStep"
                                :disabled="!selectedAndConfirmedLender"
                                large depressed
                                class="secondary ma-2">
                                {{ $vuetify.breakpoint.smAndUp ? 'Proceed to Submission' : 'Proceed' }}
                            </v-btn>
                            <v-btn
                                v-else
                                @click="selectNextStep"
                                large depressed
                                class="secondary ma-2">
                                {{ $vuetify.breakpoint.smAndUp ? 'Proceed to Submission' : 'Proceed' }}
                            </v-btn>

                        </v-card-text>
                    </v-card>
                </v-container>

                <v-container class="scroll-y" pa-0 fluid>
                    <v-card :disabled="loading" flat>
                        
                        <v-card-text                        
                            class="pa-0">

                            <v-row class="px-2">
    
                                <!-- Datatable / Lenders List -->
                                <v-col :cols="`${$vuetify.breakpoint.lgAndUp ? '6' : $vuetify.breakpoint.mdOnly ? '7' : '12'}`" class="px-1">
                                    
                                    <v-row class="pt-4 pa-3">
                                        
                                        <!-- Header textfields -->
                                        <v-col :cols="`${$vuetify.breakpoint.lgAndDown ? '6' : '4'}`" class="pa-0">
                                            <v-text-field 
                                                v-model="requested_broker_fee"
                                                class="pr-2 mt-0 pb-1"
                                                hide-details
                                                width="100%"
                                                label="Origination Fee"
                                                prefix="$"
                                                flat>
                                            </v-text-field>
                                        </v-col>
                                        <v-col :cols="`${$vuetify.breakpoint.lgAndDown ? '6' : '4'}`" class="pa-0">
                                            <v-text-field 
                                                v-if="commercialOnly"
                                                v-model="requested_writing_rate"
                                                class="pr-0 mt-0 pb-1"
                                                hide-details
                                                width="100%"
                                                label="Preferred Rate"
                                                suffix="%"
                                                flat>
                                            </v-text-field>
                                            <v-text-field 
                                                v-else
                                                v-model="requested_writing_rate"
                                                class="pr-xl-2 mt-0 pb-1"
                                                hide-details
                                                width="100%"
                                                :label="`${$vuetify.breakpoint.smAndDown ? 'Rate Reduction' : 'Consumer Rate Reduction'}`"
                                                suffix="%"
                                                flat>
                                            </v-text-field>
                                        </v-col>
                                        <v-col cols="4" class="pa-0">
                                            <v-btn 
                                                v-if="$vuetify.breakpoint.name === 'xl'"
                                                @click="recalculate"
                                                :loading="loading"
                                                :disabled="disabled"
                                                depressed large
                                                block
                                                class="secondary text-right float-right mt-6 ml-0">
                                                Recalculate
                                            </v-btn>
                                        </v-col>
                                        <v-col cols="12" class="pa-0">
                                            <v-btn 
                                                v-if="$vuetify.breakpoint.lgAndDown"
                                                @click="recalculate"
                                                :loading="loading"
                                                :disabled="disabled"
                                                depressed large
                                                block
                                                class="secondary text-right float-right mt-1 ml-0">
                                                Recalculate
                                            </v-btn>
                                        </v-col>
                                    </v-row>

                                    <v-row>

                                        <v-col v-if="$vuetify.breakpoint.smAndDown">
                                            <p class="display-1 font-weight-bold text-center mb-0">
                                                Results
                                            </p>
                                        </v-col>

                                        <!-- Lenders datatable -->
                                        <!-- :class="`${emptySelection ? 'md12 lg7 px-5 pb-10' : 'xs12 px-5 pb-10'}`" -->
                                        <v-col v-if="lenders.length > 0" class="pt-4" cols="12" :class="$vuetify.breakpoint.lgAndUp ? 'mt-n6' : 'mt-n6'">                                        
                                            <FlexWrap @resize="handleResize">
                                                <v-data-table
                                                    loading-text="Please wait..."  
                                                    no-data-text="No Results Found"                                          
                                                    v-if="validComponentData"
                                                    :headers="headers"
                                                    :items="lenders"
                                                    hide-default-footer
                                                    @click:row="lenderDetails"
                                                    :hide-default-header="$vuetify.breakpoint.smOnly"
                                                    class="elevation-0 v-data-table-2 allow-hover"
                                                    :must-sort="true"
                                                    :mobile-breakpoint="0">

                                                    <!-- Headers -->
                                                    <template #[`header.lender`]="{ header }">
                                                        <div class="start">{{ header.text }}</div>
                                                    </template>

                                                    <!-- Body -->
                                                    <template #[`item.lender`]="{ item }">
                                                        <div class="inner start">
                                                            <v-img 
                                                                :max-width="$vuetify.breakpoint.lgAndUp ? 50 : 50"
                                                                :height="$vuetify.breakpoint.lgAndUp ? 50 : 50"
                                                                class="flex-brand" 
                                                                :src="getLenderLogo(item.details['logo-class'])" 
                                                                position="center left" 
                                                                contain>
                                                            </v-img>
                                                        </div>
                                                        
                                                    </template>

                                                    <template #[`item.loan-term`]="{ item }">
                                                        <div class="inner blocks">
                                                            <p class="d-none d-xl-flex ma-0 pa-0 font-weight-medium">
                                                                {{ item.details['actual-payment']['term-in-months']  }} mths
                                                            </p>
                                                            <p class="d-flex d-sm-none ma-0 pa-0 font-weight-medium">
                                                                {{ item.details['actual-payment']['term-in-months']  }} mths
                                                            </p>
                                                            <p class="d-none d-sm-flex d-md-none ma-0 pa-0 font-weight-medium">
                                                                {{ item.details['actual-payment']['term-in-months']  }} mths
                                                            </p>
                                                            <p class="d-none d-lg-flex d-xl-none ma-0 pa-0 font-weight-medium">
                                                                {{ item.details['actual-payment']['term-in-months']  }} mths
                                                            </p>
                                                            <!-- <span class="d-none d-xl-flex body-2 grey--text">Months</span> -->
                                                        </div>
                                                    </template>

                                                    <template #[`item.repayments`]="{ item }">
                                                        <div class="inner blocks">
                                                            <p class="d-none d-xl-flex ma-0 pa-0 font-weight-medium">
                                                                {{ item.details['actual-payment']['monthly-payment'] | currency }}/mo
                                                            </p>
                                                            <p class="d-flex d-sm-none ma-0 pa-0 font-weight-medium">
                                                                {{ item.details['actual-payment']['monthly-payment'] | currency }}/mo
                                                            </p>
                                                            <p class="d-none d-sm-flex d-md-none ma-0 pa-0 font-weight-medium">
                                                                {{ item.details['actual-payment']['monthly-payment'] | currency }}/mo
                                                            </p>
                                                            <p class="d-none d-lg-flex d-xl-none ma-0 pa-0 font-weight-medium">
                                                                {{ item.details['actual-payment']['monthly-payment'] | currency }}/mo
                                                            </p>                                                    
                                                            <!-- <span class="d-none d-xl-flex body-2 grey--text">Week</span> -->
                                                        </div>
                                                    </template>

                                                    <template #[`item.comparison-rate`]="{ item }">
                                                        <div class="inner blocks">                                                    
                                                            <p class="ma-0 pa-0 font-weight-medium">
                                                                {{ (item.details['actual-payment']['customer-rate'] ) | percentage }}
                                                            </p>
                                                        </div>
                                                    </template>

                                                    <template #[`item.amount-financed`]="{ item }">
                                                        <div class="inner blocks">                                                    
                                                            <p class="ma-0 pa-0 font-weight-medium">
                                                                {{ (item.details['actual-payment']['naf'] ) | currency }}
                                                            </p>
                                                        </div>
                                                    </template>

                                                    <template #[`item.customer-rate`]="{ item }">
                                                        <div class="inner blocks">                                                    
                                                            <p class="ma-0 pa-0 font-weight-medium">
                                                                {{ item.details['actual-payment']['customer-rate'] | percentage }}
                                                            </p>
                                                        </div>
                                                    </template>

                                                    <template #[`item.rates`]="{ item }">
                                                        <div class="inner blocks">                                                    
                                                            <p class="ma-0 pa-0 font-weight-medium">
                                                                {{ (item.details['actual-payment']['customer-rate'] ) | percentage }}
                                                            </p>
                                                            <p class="ma-0 pa-0 font-weight-medium">
                                                                {{ item.details['actual-payment']['customer-rate'] | percentage }}
                                                            </p>
                                                        </div>
                                                    </template>

                                                    <template #[`item.terms`]="{ item }">
                                                        <div class="inner blocks">                                                    
                                                            <p class="ma-0 pa-0 font-weight-medium">
                                                                {{ item.details['actual-payment']['term-in-months']  }} mths
                                                            </p>
                                                            <p class="ma-0 pa-0 font-weight-medium">
                                                                {{ ( (item.details['actual-payment']['monthly-payment'] * 12 ) / 52 ) | currency }}/wk
                                                            </p>
                                                        </div>
                                                    </template>

                                                    <template 
                                                        #[`item.actions`]="{ item }">
                                                        <div class="inner blocks">
                                                            <v-sheet width="45" height="45" rounded class="secondary pa-1" color="primary">
                                                                <v-icon color="white" large>mdi-dots-horizontal</v-icon>
                                                            </v-sheet>
                                                            <!-- <v-btn width="30" class="primary" text depressed>
                                                                <v-icon>mdi-dots-horizontal</v-icon>
                                                            </v-btn> -->
                                                        </div>
                                                    </template>

                                                </v-data-table>
                                            </FlexWrap>
                                        </v-col>

                                        <v-col v-else cols="12" :class="$vuetify.breakpoint.xlOnly ? 'mt-n8' : ''" class="mt-n4 px-0 mx-0">
                                            <v-card flat class="text-center grey--text">
                                                <v-card-text class="subtitle-1 pb-0">
                                                    No Lenders Available
                                                </v-card-text>
                                                <v-card-text class="text-caption">
                                                    Unfortunately we have not been able to find any lenders suitable for the applicant. Don’t worry, we may still be able to assist. You can send through your application and our processing team will review the application.
                                                </v-card-text>
                                            </v-card>
                                        </v-col>
                                        
                                        <v-col cols="12" class="py-2">

                                            <!-- Display on LG -->
                                            <v-row 
                                                v-if="$vuetify.breakpoint.name === 'lg'"
                                                class="d-flex py-0 px-2">
                                                
                                                <template
                                                    v-for="(nObj, nkey) in selectedLenderObj">
                                                    <v-col                                                    
                                                        :key="nkey"
                                                        cols="6"
                                                        class="selection-badge px-1 py-0">

                                                        <SelectionBadge 
                                                            v-if="nObj"
                                                            @save-custom-lender="saveCustomLender"
                                                            @reset-custom-lender="resetCustomLender"                                                        
                                                            :synced-disabled="badgeStatus[nkey]"
                                                            :title="nkey"
                                                            :has-error="warningInfo[nkey]"
                                                            :selected-lender="nObj['bid-lender']"
                                                            :selected-bid="nObj['bid']" />                                           

                                                    </v-col>
                                                </template>
                                            </v-row>
                                        </v-col>

                                    </v-row>

                                </v-col>

                                <!-- Summary and Selected Primary and Secondary Lenders -->
                                <v-col :cols="`${$vuetify.breakpoint.lgAndUp ? '6' : $vuetify.breakpoint.mdOnly ? '5' : '12'}`" class="px-0">

                                    <!-- Display on XL -->
                                    <v-row v-if="$vuetify.breakpoint.name === 'xl'" class="pa-0 ma-0">
                                                                            
                                        <v-col cols="6" class="pa-1">

                                            <QuotableSummary
                                                @save-official-lender="saveOfficialLender"
                                                @disable-badges="disableBadge"
                                                :empty-selection="emptySelection"
                                                :bid-resource="bidResource"
                                                :user-selected-lenders="selectedLenderObj"
                                                :selected-lender="selectedLender"
                                                :loan-resource="loan" />

                                        </v-col>

                                        <v-col cols="6" class="pa-1">
                                            <v-row class="pa-1">
                                                <template
                                                    v-for="(nObj, nkey) in selectedLenderObj">
                                                    <v-col                                                    
                                                        :key="nkey"
                                                        cols="12"
                                                        class="selection-badge pa-2 pb-0">

                                                        <SelectionBadge 
                                                            v-if="nObj"
                                                            @save-custom-lender="saveCustomLender"
                                                            @reset-custom-lender="resetCustomLender"                                                        
                                                            :synced-disabled="badgeStatus[nkey]"
                                                            :title="nkey"
                                                            :has-error="warningInfo[nkey]"
                                                            :selected-lender="nObj['bid-lender']"
                                                            :selected-bid="nObj['bid']" />                                           

                                                    </v-col>
                                                </template>
                                            </v-row>
                                        </v-col>
                                        
                                    </v-row>

                                    <!-- Display on LG -->
                                    <v-row v-else-if="$vuetify.breakpoint.name === 'lg'" class="pa-0 ma-0">
                                        
                                        <v-col 
                                            :cols="12"
                                            class="pa-1 ma-0">

                                            <QuotableSummary
                                                @save-official-lender="saveOfficialLender"
                                                @disable-badges="disableBadge"
                                                :empty-selection="emptySelection"
                                                :bid-resource="bidResource"
                                                :user-selected-lenders="selectedLenderObj"
                                                :selected-lender="selectedLender"
                                                :loan-resource="loan" />

                                        </v-col>                                    
                                    </v-row>

                                    <!-- Display on MD -->
                                    <v-row v-else-if="$vuetify.breakpoint.name === 'md'" class="pa-0 ma-0">
                                        <template
                                            v-for="(nObj, nkey) in selectedLenderObj">
                                            <v-col                                                    
                                                :key="nkey"
                                                cols="12"
                                                class="selection-badge pa-1 pb-1">

                                                <SelectionBadge 
                                                    v-if="nObj"
                                                    @save-custom-lender="saveCustomLender"
                                                    @reset-custom-lender="resetCustomLender"                                                        
                                                    :synced-disabled="badgeStatus[nkey]"
                                                    :title="nkey"
                                                    :has-error="warningInfo[nkey]"
                                                    :selected-lender="nObj['bid-lender']"
                                                    :selected-bid="nObj['bid']" />                                           

                                            </v-col>
                                        </template>
                                        
                                    </v-row>

                                    <!-- Display on SM -->
                                    <v-row v-else-if="$vuetify.breakpoint.name === 'sm'" class="pa-0 ma-0">
                                        
                                        <!-- Selected Lender Badges -->
                                        <v-col cols="12">
                                            <p class="display-1 font-weight-bold text-center">
                                                My Lenders
                                            </p>
                                        </v-col>

                                        <template
                                            v-for="(nObj, nkey) in selectedLenderObj">
                                            <v-col                                                    
                                                :key="nkey"
                                                cols="6"
                                                class="selection-badge flex-boxes px-1 py-0 ma-0 pb-2">

                                                <SelectionBadge 
                                                    v-if="nObj"
                                                    @save-custom-lender="saveCustomLender"
                                                    @reset-custom-lender="resetCustomLender"                                                        
                                                    :synced-disabled="badgeStatus[nkey]"
                                                    :title="nkey"
                                                    :has-error="warningInfo[nkey]"
                                                    :selected-lender="nObj['bid-lender']"
                                                    :selected-bid="nObj['bid']" />                                           

                                            </v-col>
                                        </template>

                                        <!-- Quotable Summary -->
                                        <!-- Quotable summary should be in a modal -->
                                        
                                    </v-row>

                                    <!-- Display on XS -->
                                    <v-row v-else-if="$vuetify.breakpoint.name === 'xs'" class="pa-0 ma-0">
                                        
                                        <v-col cols="12">
                                            <p class="display-1 font-weight-bold text-center">
                                                My Lenders
                                            </p>
                                        </v-col>

                                        <!-- Selected Lender Badges -->
                                        <template
                                            v-for="(nObj, nkey) in selectedLenderObj">
                                            <v-col                                                    
                                                :key="nkey"
                                                cols="12"
                                                class="selection-badge flex-boxes px-1 py-0 ma-0 pb-2">

                                                <SelectionBadge 
                                                    v-if="nObj"
                                                    @save-custom-lender="saveCustomLender"
                                                    @reset-custom-lender="resetCustomLender"                                                        
                                                    :synced-disabled="badgeStatus[nkey]"
                                                    :title="nkey"
                                                    :has-error="warningInfo[nkey]"
                                                    :selected-lender="nObj['bid-lender']"
                                                    :selected-bid="nObj['bid']" />                                           

                                            </v-col>
                                        </template>

                                        <!-- Quotable Summary -->
                                        <!-- Quotable summary should be in a modal -->
                                        
                                    </v-row>

                                    

                                    <v-row 
                                        v-if="false"
                                        class="pa-0 ma-0">

                                        <!-- Quotable Summary -->
                                        <v-col 
                                            cols="6"
                                            class="flex-boxes px-1 py-0 ma-0"
                                            v-if="emptySelection"
                                            ref="quotable-summary"
                                            id="quotable-summary">
                                            
                                            <QuotableSummary
                                                @save-official-lender="saveOfficialLender"
                                                @disable-badges="disableBadge"
                                                :bid-resource="bidResource"
                                                :user-selected-lenders="selectedLenderObj"
                                                :selected-lender="selectedLender"
                                                :loan-resource="loan" />

                                        </v-col>

                                        <!-- Selected Lender Badges -->
                                        <template
                                            v-for="(nObj, nkey) in selectedLenderObj">
                                            <v-col                                                    
                                                :key="nkey"
                                                cols="6"
                                                class="selection-badge flex-boxes px-1 py-0 ma-0 pb-2">

                                                <SelectionBadge 
                                                    v-if="nObj"
                                                    @save-custom-lender="saveCustomLender"
                                                    @reset-custom-lender="resetCustomLender"                                                        
                                                    :synced-disabled="badgeStatus[nkey]"
                                                    :title="nkey"
                                                    :has-error="warningInfo[nkey]"
                                                    :selected-lender="nObj['bid-lender']"
                                                    :selected-bid="nObj['bid']" />                                           

                                            </v-col>
                                        </template>                                   

                                    </v-row>
                                    
                                </v-col>
                                
                            </v-row>

                        </v-card-text>
                    </v-card>
                </v-container>            

            </v-card-text>

            <QuotableSummaryDialog
                v-if="summaryDialog && $vuetify.breakpoint.mdAndDown"
                @save-official-lender="saveOfficialLender"
                @disable-badges="disableBadge"
                @close="summaryDialog = false"
                :bid-resource="bidResource"
                :user-selected-lenders="selectedLenderObj"
                :selected-lender="selectedLender"
                :loan-resource="loan"
                :dialog="summaryDialog" />

        </v-card>
</template>

<script>

    import { mapActions, mapState } from 'vuex'
    import { store, actions, mutations } from './__store'
    import QuotableComponentDialog from '../QuoteTool1/QuotableComponentDialog'
    import QuotableSummary from './_BidQuoteSummary'
    import QuotableSummaryDialog from './_BidQuoteSummaryDialog'
    import SelectionBadge from './_BidSelectionBadger'
    import GenerateSoca from './_BidQuoteGenerateSocaDialog'
    import subscriptions from '~/components/shared/subscriptions'
    import helpers from '~/plugins/helpers'
    import FlexWrap from '~/components/base/FlexWrap'
    import { isEmpty } from 'lodash'

    export default {
        name: 'IndicativeQuote',

        mixins: [ subscriptions, helpers ],

        components: { 
            QuotableSummary, 
            QuotableSummaryDialog,
            QuotableComponentDialog,
            SelectionBadge,
            GenerateSoca,
            FlexWrap
        },

        computed: {
             ...mapState('flows',[
                'loanData',
                'appTypeFE'
            ]),
            isUnsecured(){
                if(this.loanData.attributes['loan-type'].search('Asset') == -1 ){
                    return true
                }
                return false
            },
            selectedLenderObj() {
                return store.selectedLenderObj
            },

            tableHeaders() {

                if ( !this.sizes ) return

                const { sm, xs } = this.sizes

                let newHeaders = [
                    ...this.headers
                ]

                let heads = [
                    { value: 'lender', width: '20%' },
                    { value: 'repayments', width: '20%' },
                    { value: 'customer-rate', width: '15%' },
                    { value: 'loan-term', width: '20%' },
                    { value: 'amount-financed', width: '25%' }
                ]
                
                if ( sm ) {                    
                    heads = [ 
                        { value: 'lender', width: '20%' },
                        { value: 'repayments', width: '20%' },
                        { value: 'loan-term', width: '20%' },
                        { value: 'amount-financed', width: '20%' }
                    ]
                }

                if(xs){
                    heads = [ 
                        { value: 'lender', width: '20%' },
                        { value: 'loan-term', width: '20%' },
                        { value: 'amount-financed', width: '20%' }
                    ]
                }

                // return newHeaders.filter( head => heads.includes(head.text))
                return newHeaders.reduce((accumulator, current) => {

                    heads.forEach(head => {

                        if(head.value == current.value){

                            let newCurrent = {...current}

                            newCurrent.width = head.width

                            accumulator.push(newCurrent)
                        }

                    })

                    return accumulator 

                }, [])

            },
            
            lenders() {
                
                let lenders = []
                let quotes = {}

                quotes = this?.payloadResponses?.find( res => {
                    return res.data?.data?.type === 'applications'
                })

                if ( quotes ) {  
                    
                    let data = quotes?.data?.included?.[0]?.attributes?.results

                    if (!isEmpty(data)) {
                        data = JSON.parse(data)

                        // check if lenders is null return []
                        if ( data.lenders == null ) return lenders
                        
                        Object.keys( data.lenders ).forEach( lender => {
                            lenders.push({ 
                                name: lender, 
                                details: data.lenders[lender],
                                'customer-rate': data.lenders[lender]['actual-payment']['customer-rate'],
                                term: data.lenders[lender]['actual-payment']['term-in-months'],
                                'amount-financed': data.lenders[lender]['actual-payment']['naf'],
                                'repayments': data.lenders[lender]['actual-payment']['monthly-payment']
                            })
                        })                  
                    }
                }

                return lenders
            },

            loan() {
                let payload = this.payloadResponses.find( res => {
                    return res.data.data.type === 'loans'
                })
                
                let loan = {
                    attributes: payload?.data?.data?.attributes,
                    id: payload?.data?.data?.id,
                    relationships: payload?.data?.data?.relationships,
                    type: 'loans'
                }
                return loan
            },

            selectedAndConfirmedLender() {

                let flag = true

                if ( isEmpty(this.selectedLenderObj.alternative['bid-lender']) ) flag = false
                if ( isEmpty(this.selectedLenderObj.primary['bid-lender']) ) flag = false

                return flag
            },

            emptySelection() {
                return isEmpty( this.selectedLender ) ? false : true
            },

            getLoanId(){
                return this.appData?.relationships?.loan?.data?.id
            }
            
        },

        async mounted() {
            // populate loan field
            if (this.getLoanId) {

                const loan = await this.getLoan(this.getLoanId)
                this.populateLoan(loan?.data?.data)

            }

        },

        methods: {

            ...mapActions('resource-actions', [
                'getQuotes',
                'getApplicationBid',
                'updateLoan',
                'createBid',
                'updateBid',
                'createBidLender',
                'updateBidLender',
                'getLoan'
                // 'setApplicationNow',
                
            ]),

            ...mapActions('quote-actions', [
                'generateSocaPDF',
            ]),

            ...mapActions('flows', ['selectNextStep']),

            async generateSoca (data = {}) {
                
                this.pdfLoading = true

                this.bidResource.attributes['referral-fee-estimate'] = data['referral-fee-estimate']
                this.bidResource.attributes['referral-partner-name'] = data['referral-partner-name']
                
                const res = await this.generateSocaPDF(this.appId)

                if (res?.data?.included) {
                    if (res.data.included[0].attributes['file-url']) {
                        // open to new tab
                        window.open(res.data.included[0].attributes['file-url'], '_blank');

                    }
                } else {
                    console.log('Some Error')
                }

                this.pdfLoading = false

            },

            async pull() {

                // this.payloadResponses = []

                let resQuote = await this.getQuotes({
                    'type': 'applications',
                    'id': this.appId,
                    'attributes': {}
                })
                
                let resApp = await this.getApplicationBid({ id: this.appId, include: 'bid-lenders'})
                
                this.payloadResponses = resQuote

                let bid = resApp?.data?.data
                let bidLender = resApp?.data?.included?.filter( lender => {
                    return lender.type === 'bid-lenders'
                })

                mutations.setPrimary(
                    {
                        'bid': bid,
                        'bid-lender': bidLender?.find( payload => {
                            return payload.attributes['lender-type'] === 'Primary'
                        })
                    }
                )

                mutations.setAlternative(
                    {
                        'bid': bid,
                        'bid-lender': bidLender?.find( payload => {
                            return payload.attributes['lender-type'] === 'Alternative'
                        })
                    }
                )

                this.bidResource = bid
                this.bidLenderResource = bidLender || []

            },

            async push() {

                if(this.lenders.length > 0) {
                    const valid = this.validateOptions()
                    let status = valid ? 'valid' : 'dirty'

                    return  { valid, status } 
                } else
                    return {valid: true, status: 'valid'}

            },

            payloadRequests() {

                let reqs = []

                Object.keys(this.selectedLenderObj).forEach( payload_type => {
                    Object.keys(this.selectedLenderObj[payload_type]).forEach( payload => {
                        
                        let payloadObject = this.selectedLenderObj[payload_type][payload]

                        if ( payloadObject.type === 'bid' ) {
                            
                            if ( !payloadObject?.id ) {
                                // POST Actions
                                reqs.push( this.createBid(payloadObject) )
                            }
                            else {
                                // PATCH Actions
                                reqs.push( this.updateBid(payloadObject) )
                            }
                        }
                        else if ( payloadObject.type === 'bid-lender' ) {
                            
                            if ( !payloadObject?.id ) {
                                // POST Actions
                                reqs.push( this.createBidLender(payloadObject) )
                            }
                            else {
                                // PATCH Actions
                                reqs.push( this.updateBidLender(payloadObject) )
                            }
                        }

                        
                    })
                })

                return reqs
            },

            validateOptions() {
                let valid = true
                if ( !this.selectedLenderObj?.primary  ) {
                    this.warningInfo.primary = true
                    valid = false
                }
                else this.warningInfo.primary = false
                if ( !this.selectedLenderObj?.alternative ) {
                    this.warningInfo.alternative = true
                    valid = false
                }
                else this.warningInfo.alternative = false
                return valid
            },

            async getUpdateLoan(data) {

                let payload = {
                    type: data.type,
                    id: data.id,
                    attributes: data.attributes
                }

                if ( data?.id ) {
                    await this.updateLoan(payload)
                }

                await this.pull()
            },

            resetCustomLender(data) {
                
                let filterLender = this.bidLenderResource.filter( lender => {
                    return lender.id !== data.id
                })

                this.bidLenderResource = filterLender

            },

            saveOfficialLender(data) {

                this.selectedLenderObj[data.type] = data.data

                this.bidLenderResource.push(data.data['bid-lender'])                
                
                let filterLender = this.bidLenderResource.filter( lender => {
                    return lender.id === this.selectedLenderObj?.primary?.['bid-lender']?.id || lender.id === this.selectedLenderObj?.alternative?.['bid-lender']?.id
                })

                this.bidLenderResource = filterLender
                this.bidResource = data.data['bid']

                this.resetSyncedBadgeStatus()

            },

            saveCustomLender(data) {

                let type_of_lender = data?.data?.['bid-lender']?.attributes?.['lender-type']

                let isExist = false
                let index = null

                this.bidLenderResource.forEach( (obj, key) => {
                    if ( obj?.id === data?.data?.['bid-lender']?.id ) {
                        index = key
                        isExist = true
                    }
                })

                if ( !isExist ) {
                    this.bidLenderResource.push(data.data['bid-lender'])
                }
                else if ( data.flag ) {      
                    this.bidLenderResource.splice(index, 1)
                    this.bidLenderResource.push(data.data['bid-lender'])
                    this.bidResource = data.data['bid']
                }
                
                if ( type_of_lender === 'Primary' ) {
                    this.warningInfo.primary = false
                }
                else if ( type_of_lender === 'Alternative' ) {
                    this.warningInfo.alternative = false
                }
            },

            disableBadge(data) {
                this.badgeStatus[data.type] = data.value
            },

            resetSyncedBadgeStatus() {
                this.badgeStatus.primary = false
                this.badgeStatus.alternative = false
            },

            proceedToSubmission() {
                this.selectNextStep()
            },

            lenderDetails(data) {
                
                this.selectedLender = data
                this.selectedLogo = this.getLenderLogo(data.details['logo-class'])
                this.summaryDialog = true
                
            },

            async recalculate() {
                
                this.disabled = true
                this.loading = true

                let data = {
                    "id": this.loan.id,
                    "type": "loans",
                    "attributes": {
                        "requested-broker-fee": this.requested_broker_fee,
                        "customer-discount-rate": this.requested_writing_rate,
                        "requested-writing-rate": "0.00"
                    }
                }

                let res = await this.updateLoan(data)
                
                if ( res.status === 200 ) {
                    await this.pull()
                }

                this.disabled = false
                this.loading = false
                this.iscalculated = true
            },

            getLenderLogo(name){

                let lender = require(`~/assets/images/logo/placeholder.png`)

                try {

                    lender = require(`~/assets/images/logo/${name}.png`)
                    return lender

                } catch (error) {

                    console.log('Ooops!..', error)
                    return lender

                }

            },

            calculatable() {
                if (this.requested_writing_rate == '' || this.requested_broker_fee == '') {
                    this.disabled = true
                }
                else {
                    this.disabled = false
                }
            },

            isLenderSelected(lenderName = '') {
                let len = Object.keys(this.confirmedSelectedLenders).find( lender => {
                    return lenderName === this.confirmedSelectedLenders[lender]
                })
                return len ? true : false
            },

            isEmptySelection(key) {
                return isEmpty(this.confirmedSelectedLenders[key]) ? false : true
            },

            populateLoan(data) {
                // populate some loans fields
                if (data.hasOwnProperty('attributes')) {

                    this.requested_broker_fee = data.attributes['requested-broker-fee']
                    this.requested_writing_rate = data.attributes['customer-discount-rate']

                }

            }
            
        },

        watch: {

            'requested_broker_fee': {
                handler(value) {
                    // this.form['requested-broker-fee'] = this.requested_broker_fee
                    this.calculatable()
                },
                deep: true
            },

            'requested_writing_rate': {
                handler(value) {
                    // this.form['requested-writing-rate'] = this.requested_writing_rate
                    this.calculatable()
                },
                deep: true
            },

        },

        data() {
            return {
                pdfLoading: false,
                badgeStatus: {
                    primary: false,
                    alternative: false
                },
                selectedLender: {},
                selectedLenders: {
                    primary: '',
                    alternative: ''
                },
                warningInfo: {
                    primary: false,
                    alternative: false
                },
                confirmedSelectedLenders: {
                    primary: '',
                    alternative: ''
                },
                selectedLogo: '',
                validComponentData: true,
                requested_broker_fee: '',
                requested_writing_rate: '',
                iscalculated: false,
                commercialOnly: false,
                disabled: true,
                headers: [
                    { text: 'Lender', sortable: false, value: 'lender', width: '20%'},
                    { text: 'Repayments', sortable: true, value: 'repayments', width: '20%'},
                    { text: 'Rate', sortable: true, value: 'customer-rate', width: '10%'},
                    { text: 'Term', sortable: false, value: 'loan-term', width: '20%'},
                    { text: 'Amount Financed', sortable: true, value: 'amount-financed', width: '30%'}
                    // { text: 'Comparison Rate', sortable: false, value: 'comparison-rate', width: '20%'}
                ],
                payloadResponses: [],
                bidResource: null,
                bidLenderResource: [],
                summaryDialog: false
            }
        }
    }
</script>

<style lang="scss" scoped>
    .allow-hover {
        table {
            tbody {
                tr {
                    &:hover {
                        background-color: red !important;
                    }
                }
            }
        }   
    }
    .flex-boxes {
        position: relative;
        display: inherit;

        .flex-box-content {
            width: 100%;
            height: 100%;
        }
    }

    .v-data-table table thead tr th {
        padding-left: 16px 16px 16px 0px !important;        
    }

    .v-data-table.v-data-table-2 thead tr th {        
        .start {
            padding-left: 10px;
        }
    } 

    .inner {

        &.start {
            padding-left: 15px !important;
        }

        &.blocks {
            padding-left: 10px !important;
            white-space: nowrap;
            overflow: hidden;
        }
        
    }
</style>