import Vue from "vue";

export const store = Vue.observable({
  steps: {
    employment: {
        title: "Business Person Information",
        subtitle:
          "",
        "form-ref": "EmploymentInformationForm",
      },
    financial: {
      title: "Business Person Information",
      subtitle:
        "Please use the Nodifi Data to answer the questions in the FinanceOne Requirements",
      "form-ref": "FinancialExpenseForm",
    },
  },

  carousel: 0,
});

export const actions = {
  setCarousel(payload) {
    store.carousel = payload;
  },

  resetStore() {
    store.carousel = 0;
  },

  setCarouselNext() {
    store.carousel++;
  },
  setCarouselBack() {
    store.carousel--;
  },
};
