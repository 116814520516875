<template>
    <v-dialog
        v-model="toggleDialog"
        @keydown.esc="closeDialog"
        max-width="1124px"
        scrollable
        persistent>

        <template v-slot:activator="{ on, attrs }">
            <v-btn 
                depressed 
                color="light_gray_2"
                :text="$vuetify.breakpoint.xsOnly === false"
                :fab="$vuetify.breakpoint.xsOnly === false"
                :class="{'ml-1': $vuetify.breakpoint.xsOnly, 'ma-0': $vuetify.breakpoint.smAndUp}"
                v-bind="attrs"
                v-on="on">
                <v-icon>mdi-pencil</v-icon>
            </v-btn>
        </template>

        <v-card flat width="100%">
            <v-progress-linear color="secondary" indeterminate stream absolute top v-if="saving"></v-progress-linear>

            <v-card-title class="headline white lighten-2">
                <v-spacer></v-spacer>
                <v-btn icon @click="closeDialog">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-card-title>

            <v-card-text class="px-4 px-md-8">                
                <!-- <AssetGrid 
                    @on-updated-form="updateResources"
                    @on-step-change="changedSteps"
                    :is-new="dialog" /> -->
                    
                    <FormPurchaseDetails 
                        @on-form-update="prepareToSave"
                        @on-chosen-img="getImage"
                        :purchase-data="purchasedItem"
                        :purchase-kind="purchaseKind"
                        :selected-type="assetType"
                        :sync-refresh="dialog"
                        :kind-of-purchase="description" />
            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions 
                class="pa-6 flex-wrap controls">
                <v-btn 
                    @click="save"
                    depressed 
                    :min-width="140"
                    large elevation="0"                    
                    color="secondary">
                    Save
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn 
                    @click="closeDialog" 
                    depressed 
                    :min-width="140"
                    large outlined elevation="0"
                    color="light_gray_2">
                    Back
                </v-btn>                
            </v-card-actions>

        </v-card>

    </v-dialog>
</template>

<script>

    import { mapGetters, mapMutations } from 'vuex'
    import FormPurchaseDetails from './_FormPurchaseDetails'

    export default {
        name: 'EditPurchase',

        props: {
            purchasedItem: {
                type: Object,
                default: () => {}
            },
            forceOpen: {
                type: Boolean,
                default: false
            },
            description: {
                type: String,
                default: ''
            },
            hasRouteQuery: {
                type: Boolean,
                default: false
            }
        },

        components: { FormPurchaseDetails },

        mounted() {

        },

        computed: {
            ...mapGetters('kinds', ['kinds']),
            ...mapGetters('quote-actions', [
                'initialDataFromQuote'
            ]),

            assetTypeList() {
                let purchasesKinds = this.kinds.find( kind => {
                    return kind.name == 'Purchase'
                })
                return purchasesKinds ? purchasesKinds.children : []
            },
            
            purchaseKind() {
                return this.purchasedItem?.relationships?.kind?.data?.id
            },

            toggleDialog: {
                get() {
                    if ( this.forceOpen ) {
                        if ( this.initialDataFromQuote || this.dialog || this.hasRouteQuery) {                            
                            return true
                        }
                    }
                    else {
                        if ( this.dialog ) return true                        
                    }
                    return false
                },
                set(val) {
                    this.dialog = true
                }           
            }
        },

        methods: {
            ...mapMutations('quote-actions', [
                'setInitialDataFromQuote'
            ]),
            
            async save() {
                let valid = await this.observer?.validate()
                if ( valid ) {
                   this.$emit('confirmed-update', { resources: this.resources, img: this.img })
                    this.dialog = false
                    this.setInitialDataFromQuote(false)
                }
            },

            prepareToSave(data) {
                this.observer = data.observer
                this.resources = data.resources
            },

            getImage(img) {
                this.img = img
            },
            closeDialog() {
                this.dialog = false
                this.$emit('close-modal')
                this.setInitialDataFromQuote(false)
            }
        },
        
        watch: {
            'resources': {
                handler(val) {

                    if (this.purchasedItem?.id) {
                        // let relationshipKind = null // purchasedItem?.relationships?.kind?.data
                        let relationshipKind = this.assetTypeList.find( kind => {
                            return kind.id == this.purchasedItem?.relationships?.kind?.data?.id
                        })
                        this.assetType = relationshipKind?.name ? relationshipKind.name : ''
                    } else {
                        // let relationshipKind = null // val?.relationships?.kind?.data
                        let relationshipKind = this.assetTypeList.find( kind => {
                            return kind.id == val?.relationships?.kind?.data?.id
                        })
                        this.assetType = relationshipKind?.name ? relationshipKind.name : ''
                    }

                },
                deep: true
            }
        },

        data() {
            return {
                img: '',
                observer: null,
                dialog: false,
                saving: false,
                assetType: '',
                resources: {}
            }
        }
    }
</script>

<style lang="scss" scoped>
    .controls {
        flex-direction: row-reverse;
            .v-btn {
                margin: 0 5px;
                padding: 0 30px;
            }
    }

    .v-card__title {


        .v-subheader  {
            display: block;
            line-height: 1.4;
            height: auto;
        }
    }


    @media (max-width: 960px) {

        .controls {
            justify-content: center;

            .spacer {
                display: none;
            }
        }

        .controls {

            .v-btn {
                margin: 0 5px 10px;
                padding: 0 12px;

            }
            
        }

    }
</style>