<template>
  <div>
    <v-card :disabled="isDisabled" class="mx-auto" tile flat>
      <v-card-text class="pa-4 pt-0 pa-md-10 pt-md-0">
        <v-container pa-0 fluid>
          <v-row>
            <v-col cols="12">
              <v-data-table
                :loading="false"
                loading-text="Please wait..."
                :items="people"
                hide-default-footer
                :mobile-breakpoint="0"
                hide-default-header
                class="v-data-table-6 mx-auto"
              >
                <!-- Body -->
                <template v-if="vb.mdAndUp" v-slot:item="{ item, index }">
                  <v-card
                    tile
                    flat
                    class="mb-6 pa-2 rounded-xl"
                    color="light_gray_1"
                  >
                    <div
                      class="
                        d-flex
                        justify-space-between
                        align-center
                        py-2
                        px-4
                        flex-table-row
                      "
                    >
                      <div class="d-flex flex-column">
                        <div class="mr-6">
                          <v-icon size="32">mdi-account</v-icon>
                        </div>
                      </div>

                      <div class="d-flex flex-column">
                        <div class="mr-6 body-1 font-weight-medium">
                          {{ getFriendlyName(item) }}
                        </div>
                      </div>

                      <div class="d-flex flex-column">
                        <div>
                          <v-chip
                            :filter="false"
                            :ripple="false"
                            :style="`background-color: ${hexToRGBA(
                              getActiveTheme.colors.secondary,
                              0.2
                            )}`"
                            class="px-5 font-weight-medium"
                          >
                            {{
                              item.roles.length > 1
                                ? `${item.roles[0]} +${item.roles.length - 1}`
                                : item.roles.length == 1
                                ? item.roles[0]
                                : ""
                            }}
                          </v-chip>
                        </div>
                      </div>

                      <v-spacer></v-spacer>

                      <div class="d-flex justify-end">
                        <v-btn
                          depressed
                          small
                          @click="handleAddDetails(item)"
                          :color="
                            getCompleted.includes(item.id)
                              ? 'success'
                              : 'secondary'
                          "
                        >
                          {{
                            getCompleted.includes(item.id)
                              ? "Completed"
                              : "Add Details"
                          }}
                        </v-btn>
                      </div>
                    </div>
                  </v-card>
                </template>
                <!-- mobile version -->
                <template v-else v-slot:item="{ item, index }">
                  <v-card
                    tile
                    flat
                    class="mb-6 pa-2 rounded-lg"
                    color="light_gray_1"
                  >
                    <v-card-text class="d-flex pb-1">
                      <v-icon size="32" class="mt-n2">mdi-account</v-icon>
                      <div class="body-1 font-weight-medium pl-1">
                        {{ getFriendlyName(item) }}
                      </div>
                    </v-card-text>
                    <v-card-text class="pt-0">
                      <v-chip
                        :filter="false"
                        :ripple="false"
                        :style="`background-color: ${hexToRGBA(
                          getActiveTheme.colors.secondary,
                          0.2
                        )}`"
                        class="px-5 font-weight-medium"
                      >
                        {{
                          item.roles.length > 1
                            ? `${item.roles[0]} +${item.roles.length - 1}`
                            : item.roles.length == 1
                            ? item.roles[0]
                            : ""
                        }}
                      </v-chip>
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer></v-spacer>

                      <v-btn
                        depressed
                        small
                        @click="handleAddDetails(item)"
                        :color="
                          getCompleted.includes(item.id)
                            ? 'success'
                            : 'secondary'
                        "
                      >
                        {{
                          getCompleted.includes(item.id)
                            ? "Completed"
                            : "Add Details"
                        }}
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </template>
              </v-data-table>

              <v-alert dense outlined type="error" v-if="errors.length > 0">
                <div v-for="msg in errors" v-html="msg"></div>
              </v-alert>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>

    <!-- Modal -->
    <v-dialog
      max-width="1000"
      persistent
      scrollable
      v-model="requirementsDialog"
      :key="JSON.stringify(steps)"
    >
      <v-card flat :disabled="requirementsDialogLoading">
        <v-progress-linear
          color="secondary"
          indeterminate
          stream
          absolute
          top
          v-if="requirementsDialogLoading"
        ></v-progress-linear>

        <v-card-title class="justify-end">
          <v-btn
            icon
            max-width="40"
            max-height="40"
            :style="`background-color: ${hexToRGBA(
              getActiveTheme.colors.secondary,
              0.1
            )}`"
            @click="closeDialog"
            fab
          >
            <v-icon color="secondary">mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text class="px-0 py-0">
          <v-carousel
            light
            v-model="carousel"
            :continuous="false"
            :show-arrows="false"
            hide-delimiters
            touchless
            height="auto"
            hide-delimiter-background
          >
            <v-carousel-item v-for="(step, key) in steps" :key="key">
              <v-card flat>
                <v-card-title
                  class="d-flex py-0"
                  :class="vb.mdAndUp ? 'px-8' : 'px-4'"
                >
                  <div>
                    <h4 :class="vb.mdAndUp ? 'font-size-26' : 'font-size-18'" class="font-weight-bold">
                      {{ step.title }} | {{ getFriendlyName(selectedEntity) }}
                    </h4>
                    <p :class="vb.mdAndUp ? 'body-1' : 'caption'">
                      {{ step.subtitle }}
                    </p>
                  </div>
                  <v-spacer />
                </v-card-title>

                <v-card-text class="px-0">
                  <template v-if="key == 'personal'">
                    <FormPersonalInformation
                      :ref="step['form-ref']"
                      :updater="formUpdater"
                      :data="selectedEntity"
                      :with-boat="hasBoatPurchase"
                      :with-vehicle="hasVehiclePurchase"
                      @loading="handleLoading"
                      @submit="handleFormPersonalInformationSubmit"
                    />
                  </template>

                  <template v-if="key == 'employment'">
                    <FormEmploymentInfo
                      :ref="step['form-ref']"
                      :key="step['form-ref']"
                      :updater="getCurrentStep"
                      :data="selectedEntity"
                      :self-employed-id="getSelfEmployedIncomeId"
                      :selfEmployedIncome="selfEmployedIncome"
                      :selectedIncome="selectedEntityCurrentIncome"
                      @loading="handleLoading"
                      @submit="handleFormEmploymentInfoSubmit"
                    />
                  </template>
                  <div v-if="key == 'employment2' && getSelfEmployedIncomeId">
                    <FormEmploymentInfo
                      :ref="step['form-ref']"
                      :key="step['form-ref']"
                      :updater="getCurrentStep"
                      :data="selectedEntity"
                      :self-employed-id="getSelfEmployedIncomeId"
                      :selfEmployedIncome="selfEmployedIncome"
                      :selectedIncome="selectedEntityCurrentIncome"
                      entity-step
                      @loading="handleLoading"
                      @submit="handleFormEmploymentInfoSubmit"
                    />
                  </div>

                  <template v-if="key == 'occupancy'">
                    <FormOccupancyInfo
                      :ref="step['form-ref']"
                      :updater="getCurrentStep"
                      :data="selectedEntity"
                      :current-occupancy="selectedEntityCurrentOccupancy"
                      :current-address="selectedEntityCurrentAddress"
                      @loading="handleLoading"
                      @submit="handleFormOccupancyInfoSubmit"
                    />
                  </template>


                </v-card-text>
              </v-card>
            </v-carousel-item>
          </v-carousel>
        </v-card-text>

        <v-card-actions class="pa-4 pa-md-6 flex-wrap controls">
          <v-btn
            v-if="!isFirstStep"
            color="light_gray_2"
            class="flex-back-button"
            :min-width="140"
            large
            outlined
            @click="handleBack"
            elevation="0"
            nuxt
          >
            Back
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="secondary"
            :min-width="140"
            large
            elevation="0"
            @click="handleNext"
            nuxt
          >
            {{ isLastStep ? "Save" : "Next" }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </div>
</template>

<script>
import { mapActions, mapState, mapMutations } from "vuex";
import subscriptions from "~/components/shared/subscriptions";
import shared from "~/components/v1/submission/FinanceOneEntities1/__shared";

import {
  store,
  actions,
} from "~/components/v1/submission/FinanceOneEntities1/__store";

import FormEmploymentInfo from "~/components/v1/submission/FinanceOneEntities1/_FormEmploymentInfo";
import FormOccupancyInfo from "~/components/v1/submission/FinanceOneEntities1/_FormOccupancyInfo";
import FormPersonalInformation from "~/components/v1/submission/FinanceOneEntities1/_FormPersonalInformation";

export default {
  components: {
    FormEmploymentInfo,
    FormOccupancyInfo,
    FormPersonalInformation,
  },

  mixins: [subscriptions, shared],

  data() {
    return {
      requirementsDialog: false,
      requirementsDialogLoading: false,

      people: [],
      incomes: [],
      purchases: [],
      occupancies: [],
      addresses: [],

      selectedEntity: null,
      selectedEntityCurrentIncome: null,
      selectedEntityIncomes: [],
      
      selectedEntityCurrentOccupancy: null,
      selectedEntityCurrentAddress: null,

      errors: [],

      secondaryApplicantId: null,
    };
  },

  computed: {
    selfEmployedIncome() {
      return this.incomes.find(item => item.id === this.getSelfEmployedIncomeId)
    },  
    vb() {
      return this.$vuetify.breakpoint;
    },
    carousel: {
      get() {
        return store.carousel;
      },
      set(value) {
        actions.setCarousel(value);
      },
    },
    steps() {
      const _steps = {...store.steps}
  
      if (!this.isRenting) {
        delete _steps.occupancy
      }
      if (!this.getSelfEmployedIncomeId) {
        delete _steps.employment2
      }
      return _steps;
    },
    formUpdater() {
      return this.requirementsDialog;
    },
    isLastStep() {
      if (this.carousel == Object.keys(this.steps).length - 1) {
        return true;
      }

      return false;
    },
    isFirstStep() {
      if (this.carousel == 0) {
        return true;
      }

      return false;
    },
    getCompleted() {
      const { people, incomes, occupancies } = this;

      let completed = [];

      people.forEach((person) => {
        
        const selfEmployedIncome = incomes.find(item => item.relationships.people.data.some(people => people.id == person.id) && item.relationships?.kind?.data?.id == '12-29' && item.attributes['income-situation'] == "Current")
        const occupancy = occupancies.find(item => (item.relationships?.person?.data?.id == person.id && item.attributes["occupancy-situation"] == "Current"))
        const isRenting = ["Renter - Agent", "Renter - Private"].includes(occupancy?.attributes["residential-situation"])

        let personFieldsValid = false
        let occupancyFieldsValid = false
        let incomeFieldsValid = false

        let fields = [
          "otherHasCircumstancesChanging"
        ]

        let occupancyFields = []
        let incomeFields = []

        if(person.attributes.lender.financeOne.driversLicenceHasSpecialRestriction) {
          fields.push("driversLicenceSpecialRestrictionDetails")
        }
        if(person.attributes.lender.financeOne.otherHasCircumstancesChanging === true) {
          fields.push("otherChangeInformation")
        }

        if(this.hasBoatPurchase) {
          fields.push("driversLicenceClass")
        }

        if(this.hasVehiclePurchase) {
          fields.push("driversLicenceType")
        }

        if (isRenting) {
          occupancyFields.push('numberOfIndividualOnLease')
        }

        if (selfEmployedIncome) {
          incomeFields.push('entityType')
        }

        personFieldsValid = fields.every( field => {
            return person.attributes.lender.financeOne?.[field] !== null
        })

        incomeFieldsValid = incomeFields.every( field => {
            return selfEmployedIncome?.attributes.lender.financeOne?.[field] !== null
        })

        occupancyFieldsValid = occupancyFields.every( field => {
            return occupancy?.attributes.lender.financeOne?.[field] !== null
        })

        if(personFieldsValid && occupancyFieldsValid && incomeFieldsValid) {
            completed.push(person.id)
        }
      });

      return completed;
    },
    isRenting(){
      
      if(this.selectedEntityCurrentOccupancy) {
        return ["Renter - Agent", "Renter - Private"].includes(this.selectedEntityCurrentOccupancy?.attributes["residential-situation"])
      }
      
      return false
    },
    getLoanId() {
      return this.appData?.relationships?.loan?.data?.id;
    },
    isJoint() {
      return this.calculation["application-type"].includes("Consumer - Joint");
    },
    hasBoatPurchase(){

      let flag = false

      if(this.purchases.length > 0) {
        this.purchases.forEach( purchase => {
          let kind = this.kindsUnformat.find(kind => kind.id == purchase.relationships?.kind?.data?.id)
          if(["9-4", "9-5"].includes(kind?.id)) { // "Boat (Moored) & Boat (Trailered)"
            flag = true
          }
        })
      }

      return flag
    },
    hasVehiclePurchase(){
      
      let flag = false

      if(this.purchases.length > 0) {
        this.purchases.forEach( purchase => {
          let kind = this.kindsUnformat.find(kind => kind.id == purchase.relationships?.kind?.data?.id)

          if(kind?.id == "9-2") { // "Motor Vehicle"
            flag = true
          }
        })
      }

      return flag
    },

    getSelfEmployedIncomeId(){

      let id = null

      this.selectedEntityIncomes.forEach( income => {

         let kindId = income.relationships?.kind?.data?.id

         if(kindId == "12-29" && ["Current", "Secondary"].includes(income.attributes['income-situation'])) {
            id = income.id
         }

      })

      return id
    },
    selfEmployedIncome(){
      return this.selectedEntityIncomes.find(item => item.id === this.getSelfEmployedIncomeId)

    },
    isRenting(){
      
      if(this.selectedEntityCurrentOccupancy) {
        return ["Renter - Agent", "Renter - Private"].includes(this.selectedEntityCurrentOccupancy?.attributes["residential-situation"])
      }
      
      return false
    },
    getCurrentStep(){
        return Object.keys(this.steps)[this.carousel]
    }
  },
  methods: {
    ...mapActions("resource-actions", [
      "getLoan",
      "getPeople",
      "getBusinesses",
      "getIncomes",
      "getPurchases",
      "getExpenses",
      "getOccupancies",
      "getAddresses",
      "updateLoan",
      "updatePerson",
      "updateIncome",
      "updateOccupancy",
    ]),
    async pull() {


      let promise1 = this.getLoan(this.getLoanId, { include: "parent-parts" });
      let promise2 = this.getPeople({ include: "parent-parts" });
      let promise3 = this.getIncomes();
      let promise4 = this.getPurchases();
      let promise5 = this.getOccupancies();
      let promise6 = this.getAddresses();

      // Get the secondary applicant id

      const res = await Promise.all([
        promise1,
        promise2,
        promise3,
        promise4,
        promise5,
        promise6,
      ]);

      promise1 = res[0];
      promise2 = res[1];
      promise3 = res[2];
      promise4 = res[3];
      promise5 = res[4];
      promise6 = res[5];

      if (this.isJoint && promise2.data.included) {
        promise2.data.included.forEach((part) => {
          if (part.attributes.priority == 2) {
            this.secondaryApplicantId = part.relationships.child.data.id;
          }
        });
      }

      if (promise1.data?.data?.relationships?.kind?.data?.id) {
        this.loanKindId = promise1.data.data.relationships.kind.data.id;
      }

      this.people = this.getPeopleByRoles(promise2.data);
      this.incomes = promise3?.data?.data ? promise3.data.data : [];
      this.purchases = promise4?.data?.data ? promise4.data.data : [];
      this.occupancies = promise5?.data?.data ? promise5.data.data : [];
      this.addresses = promise6?.data?.data ? promise6.data.data : [];


    },
    async push() {
      this.checkCompleted();

      let valid = this.errors.length == 0;

      let status = valid ? "valid" : "dirty";

      return { valid, status, data: {} };
    },
    closeDialog() {
      this.requirementsDialog = false;
      actions.resetStore();
    },
    checkCompleted() {
      this.errors = [];

      if (this.getCompleted.length != this.people.length) {
        this.errors.push("Please complete all requirements");
      }
    },
    async handleAddDetails(data) {

      this.requirementsDialog = true;
      this.selectedEntity = data;

        /***
         * Find selected entity's first current income
         */

        let current_income = null
        let current_occupancy = null
        let current_address = null
        let allIncomes = []

        if(data.relationships.incomes.data.length > 0) {

            this.incomes.forEach(income => {
                data.relationships.incomes.data.forEach(dat => {
                    if(dat.id == income.id) {

                        allIncomes.push(income)

                        if(income.attributes['income-situation'] == 'Current') {
                          current_income = income
                        }
                    }
                })
            })
        }

        this.selectedEntityIncomes = allIncomes
        this.selectedEntityCurrentIncome = current_income

        /**
         * Filter selected entities current occupancy
         */
        this.occupancies.forEach( occupancy => {

          if(occupancy.relationships?.person?.data?.id == this.selectedEntity?.id) {
            if(occupancy.attributes["occupancy-situation"] == "Current") {
              current_occupancy = occupancy
            }
          }
        })

        this.selectedEntityCurrentOccupancy = current_occupancy

        /**
         * Filter selected entities current address
         */
        this.addresses.forEach( address => {
          if(current_occupancy.relationships?.address?.data?.id == address?.id) {
              current_address = address
          }
        })

        this.selectedEntityCurrentAddress = current_address

    },
    async handleNext() {
      let stepsArray = Object.keys(this.steps);

      switch (stepsArray[this.carousel]) {
        case "personal":
          if (this.$refs.PersonalInformationForm[0]) {
            await this.$refs.PersonalInformationForm[0].validate();
          }
          break;
        case "employment":
          if (this.$refs.EmploymentInformationForm[0]) {
            await this.$refs.EmploymentInformationForm[0].validate();
          }
          break;
        case "employment2":
          if (this.$refs.EmploymentInformationForm2[0]) {
            await this.$refs.EmploymentInformationForm2[0].validate();
          }
          break;
        case "occupancy":
          if (this.$refs.OccupancyInformationForm[0]) {
            await this.$refs.OccupancyInformationForm[0].validate();
          }
          break;
        default:
          this.carousel++;
          break;
      }
    },
    async handleBack() {
      this.carousel--;
    },
    async handleFormPersonalInformationSubmit(payload) {
      this.requirementsDialogLoading = true;

      if (payload.person.hasOwnProperty("id")) {

        let res = await this.updatePerson(payload.person);

        if(res.status == 200) {

            this.updateLocalResourceAttributes(res.data.data)
            
            actions.setCarouselNext()
            this.requirementsDialogLoading = false

        } else {

            this.requirementsDialogLoading = false
        }

      }
    },
    async handleFormEmploymentInfoSubmit(payload) {

      this.requirementsDialogLoading = true;

      if (payload.person?.id) {

        let promise1 = this.updatePerson(payload.person)
        let promise2 = null
          
        if (payload.income?.id) {
          promise2 = this.updateIncome(payload.income)
        }

        let res = await Promise.all([promise1, promise2])

        promise1 = res[0]
        promise2 = res[1]

        if (promise1.status == 200) {

          this.updateLocalResourceAttributes(promise1.data.data);

          if (promise2?.status == 200) {
            this.updateLocalResourceAttributes(promise2.data.data);
          }

          setTimeout(() => {

            if(this.isRenting || (this.getSelfEmployedIncomeId && this.carousel !== 2)) {
              actions.setCarouselNext()
              this.requirementsDialogLoading = false
            } else {
              this.requirementsDialogLoading = false;
              this.requirementsDialog = false
              actions.resetStore()
            }

          }, 600);

        } else {
          this.requirementsDialogLoading = false;
        }

      } 

      if(!payload.income?.id && !payload.person?.id){
          this.requirementsDialogLoading = false;
          this.requirementsDialog = false
          actions.resetStore()
      }

    },
    async handleFormOccupancyInfoSubmit(payload) {

      this.requirementsDialogLoading = true;

      if (payload.occupancy?.id) {

        let res = await this.updateOccupancy(payload.occupancy);

        if (res.status == 200) {

          this.updateLocalResourceAttributes(res.data.data);

          setTimeout(() => {
            this.requirementsDialogLoading = false;
            this.requirementsDialog = false
            actions.resetStore()
          }, 600);

        } else {
          this.requirementsDialogLoading = false;
        }
      } 

      if(!payload.occupancy?.id){
          this.requirementsDialogLoading = false;
          this.requirementsDialog = false
          actions.resetStore()
      }

    },
    updateLocalResourceAttributes(resource) {
      if (!resource) return;

      let getType = resource.type;

      this[getType].forEach((v, i) => {
        if (v.id == resource.id) {
          this[getType][i]["attributes"] = resource.attributes;
        }
      });
    },
    handleLoading(payload) {
      this.requirementsDialogLoading = payload;
    },
  },
};
</script>

<style lang="scss" scoped></style>
