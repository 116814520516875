<template>
    <v-list expand nav dark class="flex-sidebar">
        <v-list-item-group :flex="selected" active-class="flex-active" >

        <template v-for="(item, i) in filteredItems" >

            <template v-if="item.header">
                <v-col :key="i" class="subheader text-truncate">{{ item.header }}</v-col>
            </template>

            <template v-else-if="item.children && item.visible">

                <v-list-group
                    v-if="filterNavigation(item.children).length > 0"
                    :key="i"
                    value="true">
                    <template v-slot:activator>
                        <v-list-item-action class="mr-4">
                            <v-icon size="22" v-text="item.icon"></v-icon>
                        </v-list-item-action>
                        <v-list-item-content>
                            <v-list-item-title class="light_gray_2--text" v-text="`${item.title}`" />
                        </v-list-item-content>
                    </template>

                    <v-list-item
                        :class="`${ getLeftDrawerVariant ? '': 'pl-6 mr-4' }`"
                        v-for="child in filterNavigation(item.children)"
                        v-if="child.visible"
                        :key="child.to"
                        :to="`/${getGroupSlug}/${child.to}`"
                        :data-btn="`nav-${child.to}`">
                        <v-list-item-icon class="mr-4">
                            <v-icon v-text="child.icon"></v-icon>
                        </v-list-item-icon>
                        <v-list-item-title v-text="child.title"></v-list-item-title>
                    </v-list-item>
                </v-list-group>

            </template>

            <template v-else>
                <v-list-item v-if="item.visible" :key="i" :to="`/${getGroupSlug}/${item.to}`" class="px-2 mr-2" :data-btn="`nav-${item.to}`">
                        <v-list-item-action class="mr-4">
                            <v-icon size="22" v-text="item.icon"></v-icon>
                        </v-list-item-action>

                        <v-list-item-content>
                            <v-list-item-title>
                                <div class="d-flex align-center">
                                    <div class="mr-1">{{ item.title }}</div>
                                    <v-chip
                                        v-if="item.badge"
                                        style="cursor: pointer;"
                                        color="secondary"
                                        small>
                                        {{ item.badge }}
                                    </v-chip>
                                </div>
                            </v-list-item-title>
                        </v-list-item-content>
                </v-list-item>
            </template>

        </template>

        </v-list-item-group>
    </v-list>

</template>

<script>

    import { mapState, mapGetters, mapActions } from 'vuex';
    import navItems from '~/components/base/BaseNavigation1/base-navigation-items'
    import b2cNavItems from '~/components/base/BaseNavigation1/b2c-base-navigation-items'
    import navItemsNodifi from '~/components/base/BaseNavigation1/base-navigation-items-nodifi'

    export default {
        data(){
            return {

            }
        },
        computed: {
            ...mapState(['appConfig']),
            ...mapGetters({
                getGroupSlug: 'slug/getGroupSlug',
                getTenant: 'slug/getTenant',
                getLeftDrawerVariant: 'setup/getLeftDrawerVariant'
            }),
            ...mapGetters('auth-actions', ['getUserSetting']),
            selected(){
                return false
            },
            items() {

                if(this.getUserSetting?.b2b == 1) {

                    if(this.getTenant == 'nodifi') {

                        return navItemsNodifi

                    } else {

                        return navItems
                    }


                } else {

                    return b2cNavItems

                }

            },
            filteredItems() {

                let navigation = [...this.items]

                navigation.forEach(navitem => {

                    if(!this.showNavItem(navitem)){
                        navitem.visible = false
                    }

                    if(navitem.children){

                        navitem.children.forEach(subNavitem => {

                            if(!this.showNavItem(subNavitem)){
                                subNavitem.visible = false
                            }

                        })

                    }

                })

                return navigation

            }
        },
        created() {

        },
        methods: {
            filterNavigation(items) {

                let navigation = []

                // find for settings then ivaluate
                if(items.length > 0) {

                    // Remove items in the sidebar based on user-settings
                    items.forEach(item => {

                        if(item?.settings) {

                            if(this.getUserSetting?.[item.settings]) {
                                navigation.push(item)
                            }

                        } else {
                            navigation.push(item)
                        }
                    })

                }

                return navigation
            },

            showNavItem(navitem){

                // remove scenario on sidebar based on app-config
                if( navitem.title == 'Scenario' || navitem.title == 'Scenarios') {
                    return this.appConfig.navigation.scenarios
                }

                // remove scenario on sidebar based on app-config
                if( navitem.title == 'Nodifi Knowledge') {
                    return this.appConfig.brokerBible
                }

                return true

            }
        }
    }
</script>

<style lang="scss" scoped>

  .flex-sidebar {

      &.v-list--nav {

          .subheader {
              font-size: 0.7rem;
              font-weight: 600;
              letter-spacing: 4px;
              color: white //var(--v-accent-base);
          }

          .v-list-item {
            min-height: 40px;

              .v-list-item__action,
              .v-list-item__icon {
                  margin: 8px 0;
              }

              .v-list-item__content {
                  padding: 8px 0;
              }

              .v-list-item__title {
                font-size: 0.8rem;
              }
          }

          .v-list-item:not(:last-child):not(:only-child) {
              margin-bottom: 2px;
          }

      }

      .v-list-group__header {
          .v-list-item__icon {
            margin-right: 16px !important;
          }
      }

      .flex-active{
          color: var(--v-secondary-base);
      }

  }

</style>



