<template>
    <v-rating
        readonly
        empty-icon="mdi-circle-outline"
        :background-color="color"
        full-icon="mdi-circle"
        :color="color"
        :length="steps"
        size="18"
        :value="current"
    ></v-rating>
</template>

<script>

    export default {
        props: {
            current: {
                type: [String, Number],
                default: 0
            },
            steps: {
                type: [String, Number],
                default: 4
            },
            color: {
                type: String,
                default: 'secondary'
            },
        },
        data(){
            return {
            }
        },
        computed: {

        },
        mounted(){

        }
    }
</script>
