<template>
  <div v-if="vb.mdAndUp" class="d-flex" style="width: 100%">
    <v-sheet
      color="light_gray_1"
      class="py-8 px-12"
      width="50%"
      outlined
      elevation="0" >
      <v-img
        max-width="120"
        class="flex-brand"
        :src="require(`~/assets/images/nodifi.svg`)"
        position="center left"
        contain >
      </v-img>
      <div class="mt-2 mb-6 font-size-20">Data</div>

      <v-list color="transparent">
        <v-list-item two-line class="px-0" v-for="item in getNodifiData" :key="item.name">
          <v-list-item-content>
            <v-list-item-title class="font-weight-medium" >{{ item.name }}</v-list-item-title >
            <v-list-item-subtitle>{{ item.value }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>

    </v-sheet>
    <v-sheet class="py-8 px-12" width="50%">
      <v-img
        max-width="120"
        class="flex-brand"
        :src="require(`~/assets/images/logo/cropped/lender-logo-pepper.png`)"
        position="center left"
        contain >
      </v-img>
      <div class="mt-2 mb-8 font-size-20">Requirements</div>
        <ValidationObserver ref="observer" tag="form">

              <ValidationProvider
                key="lender-pepper-residency"
                v-slot="{ errors }"
                name="lender-pepper-residency"
                rules="required"
                vid="lender-pepper-residency">
                <v-select
                  v-model="resources.entity.attributes['lender-pepper-residency']"
                  :items="getOptions('lender-pepper-residency')"
                  :error-messages="errors"
                  color="secondary"
                  label="Residency Status"
                ></v-select>
              </ValidationProvider>
      
              <ValidationProvider
                key="lender-pepper-industry"
                v-slot="{ errors }"
                name="lender-pepper-industry"
                rules="required"
                vid="lender-pepper-industry">
                <v-select
                  v-model="resources.income.attributes['lender-pepper-industry']"
                  :items="getOptions('lender-pepper-industry')"
                  :error-messages="errors"
                  color="secondary"
                  label="Industry"
                ></v-select>
              </ValidationProvider>

              <ValidationProvider
                key="lender-pepper-occupation"
                v-slot="{ errors }"
                name="Occupation"
                rules="required"
                vid="lender-pepper-occupation">
                <v-autocomplete
                  v-model="resources.income.attributes['lender-pepper-occupation']"
                  :items="getOptions('lender-pepper-occupation')"
                  :error-messages="errors"
                  color="secondary"
                  label="Occupation"
                ></v-autocomplete>
              </ValidationProvider>

              <ValidationProvider
              v-if="isGuarantor"
                key="isAuthorisedSignatory"
                v-slot="{ errors }"
                name="ABN Continuity"
                rules="required"
                vid="isAuthorisedSignatory">
                  <ButtonGroup3
                    v-model="isAuthorisedSignatory"
                    :error-messages="errors"
                    :options="['Yes', 'No']"
                    label="Is Authorised Signatory" />
              </ValidationProvider>

              <template v-if="isGuarantor && isAuthorisedSignatory == 'Yes'">
                <ValidationProvider
                    key="title"
                    v-slot="{ errors }"
                    name="title"
                    rules="required"
                    vid="title">
                    <v-select
                      v-model="resources.business.attributes.lender.pepper.title"
                      :items="['Director', 'Secretary', 'Treasurer', 'Other']"
                      :error-messages="errors"
                      color="secondary"
                      label="Title"
                    ></v-select>
                </ValidationProvider>
                <ValidationProvider
                    key="firstName"
                    v-slot="{ errors }"
                    name="firstName"
                    rules="required"
                    vid="firstName">
                    <v-text-field 
                    :type="'text'"
                    label="First Name"
                    :error-messages="errors"
                    v-model="resources.business.attributes.lender.pepper.firstName"
                    color="secondary">
                    </v-text-field>
                </ValidationProvider>
                <ValidationProvider
                    key="lastName"
                    v-slot="{ errors }"
                    name="lastName"
                    rules="required"
                    vid="lastName">
                    <v-text-field 
                    :type="'text'"
                    label="Last Name"
                    :error-messages="errors"
                    v-model="resources.business.attributes.lender.pepper.lastName"
                    color="secondary">
                    </v-text-field>
                </ValidationProvider>
                <ValidationProvider
                    key="mobile"
                    v-slot="{ errors }"
                    name="mobile"
                    rules="is_mobile_number|has:04,+61"
                    vid="mobile">
                    <v-text-field 
                    :type="'text'"
                    label="Mobile"
                    :error-messages="errors"
                    v-model="resources.business.attributes.lender.pepper.mobile"
                    color="secondary">
                    </v-text-field>
                </ValidationProvider>
                <ValidationProvider
                    key="email"
                    v-slot="{ errors }"
                    name="email"
                    rules="email"
                    vid="email">
                    <v-text-field 
                    :type="'text'"
                    label="Email"
                    :error-messages="errors"
                    v-model="resources.business.attributes.lender.pepper.email"
                    color="secondary">
                    </v-text-field>
                </ValidationProvider>
              </template>

        </ValidationObserver>
    </v-sheet>
  </div>

  <!-- mobile version -->
  <div v-else>
      <v-card
        flat
        class="mx-5 rounded-xl"
        :style="`background-color: ${hexToRGBA( getActiveTheme.colors.secondary, 0.2 )}`">
        <v-card-text class="d-flex py-1">
          <v-row>
            <v-col cols="6" class="pa-1">
              <v-card
                min-height="45"
                flat
                class="rounded-xl d-flex align-center justify-center"
                :color="tabSelection == 'nodifi' ? 'white' : 'transparent'" >
                <div class="d-flex" @click="selectTab('nodifi')" >
                  <v-img
                    max-width="55"
                    class="flex-brand"
                    :src="require(`~/assets/images/nodifi.svg`)"
                    position="center left"
                    contain >
                  </v-img>
                </div>
              </v-card>
            </v-col>
            <v-col cols="6" class="pa-1">
              <v-card
                min-height="45"
                flat
                class="rounded-xl d-flex align-center justify-center"
                :color="tabSelection == 'pepper' ? 'white' : 'transparent'" >
                <div class="d-flex" @click="selectTab('pepper')">
                    <v-img
                      max-width="45"
                      class="flex-brand"
                      :src="require(`~/assets/images/logo/cropped/lender-logo-pepper.png`)"
                      position="center left"
                      contain >
                    </v-img>
                </div>
              </v-card>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>

      <v-list v-show="tabSelection == 'nodifi'" class="mx-8" color="transparent">
        <v-list-item two-line class="px-0" v-for="item in getNodifiData" :key="item.name">
          <v-list-item-content>
              <v-list-item-title class="font-weight-medium">
                  {{ item.name }}
              </v-list-item-title>
              <v-list-item-subtitle>              
                  {{ item.value }}
              </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>

      <div v-show="tabSelection != 'nodifi'" class="mx-8">

        <ValidationObserver ref="observer" tag="form">

              <ValidationProvider
                key="lender-pepper-residency"
                v-slot="{ errors }"
                name="lender-pepper-residency"
                rules="required"
                vid="lender-pepper-residency">
                <v-select
                  v-model="resources.entity.attributes['lender-pepper-residency']"
                  :items="getOptions('lender-pepper-residency')"
                  :error-messages="errors"
                  color="secondary"
                  label="Residency Status"
                ></v-select>
              </ValidationProvider>
      
              <ValidationProvider
                key="lender-pepper-industry"
                v-slot="{ errors }"
                name="lender-pepper-industry"
                rules="required"
                vid="lender-pepper-industry">
                <v-select
                  v-model="resources.income.attributes['lender-pepper-industry']"
                  :items="getOptions('lender-pepper-industry')"
                  :error-messages="errors"
                  color="secondary"
                  label="Industry"
                ></v-select>
              </ValidationProvider>

              <ValidationProvider
                key="lender-pepper-occupation"
                v-slot="{ errors }"
                name="Occupation"
                rules="required"
                vid="lender-pepper-occupation">
                <v-autocomplete
                  v-model="resources.income.attributes['lender-pepper-occupation']"
                  :items="getOptions('lender-pepper-occupation')"
                  :error-messages="errors"
                  color="secondary"
                  label="Occupation"
                ></v-autocomplete>
              </ValidationProvider>

              <ValidationProvider
              v-if="isGuarantor"
                key="isAuthorisedSignatory"
                v-slot="{ errors }"
                name="ABN Continuity"
                rules="required"
                vid="isAuthorisedSignatory">
                  <ButtonGroup3
                    v-model="isAuthorisedSignatory"
                    :error-messages="errors"
                    :options="['Yes', 'No']"
                    label="Is Authorised Signatory" />
              </ValidationProvider>

              <template v-if="isGuarantor && isAuthorisedSignatory == 'Yes'">
                <ValidationProvider
                    key="title"
                    v-slot="{ errors }"
                    name="title"
                    rules="required"
                    vid="title">
                    <v-select
                      v-model="resources.business.attributes.lender.pepper.title"
                      :items="['Director', 'Secretary', 'Treasurer', 'Other']"
                      :error-messages="errors"
                      color="secondary"
                      label="Title"
                    ></v-select>
                </ValidationProvider>
                <ValidationProvider
                    key="firstName"
                    v-slot="{ errors }"
                    name="firstName"
                    rules="required"
                    vid="firstName">
                    <v-text-field 
                    :type="'text'"
                    label="First Name"
                    :error-messages="errors"
                    v-model="resources.business.attributes.lender.pepper.firstName"
                    color="secondary">
                    </v-text-field>
                </ValidationProvider>
                <ValidationProvider
                    key="lastName"
                    v-slot="{ errors }"
                    name="lastName"
                    rules="required"
                    vid="lastName">
                    <v-text-field 
                    :type="'text'"
                    label="Last Name"
                    :error-messages="errors"
                    v-model="resources.business.attributes.lender.pepper.lastName"
                    color="secondary">
                    </v-text-field>
                </ValidationProvider>
                <ValidationProvider
                    key="mobile"
                    v-slot="{ errors }"
                    name="mobile"
                    rules="is_mobile_number|has:04,+61"
                    vid="mobile">
                    <v-text-field 
                    :type="'text'"
                    label="Mobile"
                    :error-messages="errors"
                    v-model="resources.business.attributes.lender.pepper.mobile"
                    color="secondary">
                    </v-text-field>
                </ValidationProvider>
                <ValidationProvider
                    key="email"
                    v-slot="{ errors }"
                    name="email"
                    rules="email"
                    vid="email">
                    <v-text-field 
                    :type="'text'"
                    label="Email"
                    :error-messages="errors"
                    v-model="resources.business.attributes.lender.pepper.email"
                    color="secondary">
                    </v-text-field>
                </ValidationProvider>
              </template>

        </ValidationObserver>
      </div>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapGetters } from "vuex";
import subscriptions from "~/components/shared/subscriptions";
import shared from "~/components/v1/submission/PepperCommercialEntities1/__shared";
import { store, actions, } from "~/components/v1/submission/PepperCommercialEntities1/__store";
import ButtonGroup3 from "~/components/base/ButtonGroup3";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import Occupation from '~/components/v1/submission/LenderPepperEntities1/__occupation-enums'

export default {
  components: { ButtonGroup3 },
  mixins: [subscriptions, shared],
  props: {
    data: {
      type: Object,
      default: null,
    },
    parts: {
      type: Array,
      default: [],
    },
    updater: undefined,  
  },
  data() {
    return {
      isAuthorisedSignatory: 'Yes',
      tabSelection: "nodifi",
      resources: {
        entity: {
          id: null,
          type: "people",
          attributes: {
            "lender-pepper-residency": "",
          },
        },
        business: {
          id: null,
          type: "businesses",
          attributes: {
            lender: {
              pepper: {
                "isAuthorisedSignatory": null,
                "title": null,
                "firstName": null,
                "lastName": null,
                "mobile": null,
                "email": null
              }
            }
          },
        },
        income: {
          id: null,
          type: "incomes",
          attributes: {
            "lender-pepper-industry": "",
            "lender-pepper-occupation": "",
            "employer-name": "",
          },
        },
      },
      labels: {
      },
      validation_rules: {
        attributes: {
          "lender-pepper-residency": "required|in:Citizen,PR,Other",
          "lender-pepper-industry": "required",
          "lender-pepper-occupation": `required`,
        },
      },
      options: {
        "lender-pepper-residency": [
          { text: "Australian or New Zealand Citizen", value: "Citizen" },
          {
            text: "Australian Permanent Resident",
            value: "PR",
          },
          { text: "On Visa", value: "Other" },
        ],
        "lender-pepper-industry": [
          {
            text: "Accommodation And Food Services",
            value: "ACCOMMODATION AND FOOD SERVICES",
          },
          {
            text: "Administrative And Support Services",
            value: "ADMINISTRATIVE AND SUPPORT SERVICES",
          },
          {
            text: "Agriculture, Forestry And Fishing",
            value: "AGRICULTURE, FORESTRY AND FISHING",
          },
          {
            text: "Arts And Recreation Services",
            value: "ARTS AND RECREATION SERVICES",
          },
          { text: "Construction", value: "CONSTRUCTION" },
          { text: "Education And Training", value: "EDUCATION AND TRAINING" },
          {
            text: "Electricity, Gas, Water And Waste Services",
            value: "ELECTRICITY, GAS, WATER AND WASTE SERVICES",
          },
          {
            text: "Financial And Insurance Services",
            value: "FINANCIAL AND INSURANCE SERVICES",
          },
          {
            text: "Health Care And Social Assistance",
            value: "HEALTH CARE AND SOCIAL ASSISTANCE",
          },
          {
            text: "Information Media And Telecommunications",
            value: "INFORMATION MEDIA AND TELECOMMUNICATIONS",
          },
          { text: "Manufacturing", value: "MANUFACTURING" },
          { text: "Mining", value: "MINING" },
          { text: "Other Services", value: "OTHER SERVICES" },
          {
            text: "Professional, Scientific And Technical Services",
            value: "PROFESSIONAL, SCIENTIFIC AND TECHNICAL SERVICES",
          },
          {
            text: "Public Administration And Safety",
            value: "PUBLIC ADMINISTRATION AND SAFETY",
          },
          {
            text: "Rental Hiring And Real Estate Services",
            value: "RENTAL HIRING AND REAL ESTATE SERVICES",
          },
          { text: "Retail Trade", value: "RETAIL TRADE" },
          {
            text: "Transport, Postal And Warehousing",
            value: "TRANSPORT, POSTAL AND WAREHOUSING",
          },
          { text: "Wholesale Trade", value: "WHOLESALE TRADE" },
        ],
        "lender-pepper-occupation": [],
      },
      errors: [],

    };
  },

  computed: {
    vb() {
      return this.$vuetify.breakpoint;
    },
    getNodifiData(){
      return [
        {
          name: 'Visa Number', 
          value: this.data.attributes['visa']
        },
        {
          name: 'Company Name', 
          value: this.resources.income.attributes['employer-name']
        }
      ]
    },
    getLenderPepperOccupationOptions(){

        return Occupation['lender-pepper-occupation'].map( option => {
            return {
              text: option,
              value: option
            }
        })

    },
    getGuarantorParts(){
      return this.parts.filter( part => {
        return part.relationships.kind.data.id == "3-5" // Guarantor Kind
      })
    },
    isGuarantor(){
      if(this.data?.roles) {
        return this.data.roles.includes('Guarantor')
      }
      return false
    }
  },
  mounted() {
    this.updateResourceFields();
    this.options["lender-pepper-occupation"] = this.getLenderPepperOccupationOptions

  },
  methods: {
    ...mapActions("resource-actions", ["getPerson", "getBusiness", "getIncome" ]),
    async validate() {

      const valid = await this.$refs.observer.validate();

      let allValid = this.errors.length == 0 && valid

      if (allValid) {
        this.$emit("submit", this.resources);
      }

    },
    reset() {
      this.$refs.observer.reset();
    },
    async updateResourceFields() {
      
      this.$emit("loading", true);
      
      const { data } = this;

      // console.log('updateResourceFields', data)

      if (data) {

        let parentParts = data?.relationships?.['parent-parts']?.data
        let guarantor = null;

        parentParts.forEach( parentPart => {
          this.getGuarantorParts.forEach( part => {
             if(part.id == parentPart.id && part.relationships?.kind?.data?.id == '3-5') {
                guarantor = part
             }
          })
        })
        
        let personRes = await this.getPerson(data.id, { include: "parent-parts" });

        if(guarantor) {

          let businessRes = await this.getBusiness(guarantor.relationships.parent.data.id);

          this.resources.business = this.filterResourceFields(
            this.resources.business,
            businessRes.data.data
          );

          this.updateAuthorizedSignatory()

        }

        this.resources.entity = this.filterResourceFields(
          this.resources.entity,
          personRes.data.data
        );

        if (data.relationships.incomes.data.length > 0) {
          
          let entityIncome = data.relationships.incomes.data[0];

          let incomeRes = await this.getIncome(entityIncome.id);

          this.resources.income = this.filterResourceFields(
              this.resources.income,
              incomeRes.data.data
          );

        }

        this.$emit("loading", false);

      } else {
        this.$emit("loading", false);
      }
    },
    updateAuthorizedSignatory(){
        let lenderField = this.resources.business.attributes.lender.pepper.isAuthorisedSignatory
        if( lenderField == null) {
          this.isAuthorisedSignatory = 'No' 
        } else {
          this.isAuthorisedSignatory = lenderField
        }
    },
    selectTab(tab) {
      this.tabSelection = tab;
    },
  },
  watch: {
    updater(val) {
      if (val) {
        this.updateResourceFields();
      }
    },
    isAuthorisedSignatory(val){
      this.resources.business.attributes.lender.pepper.isAuthorisedSignatory = val
    }
  },
};
</script>

<style lang="scss" scoped>

</style>
