<template>
  <v-container>
    <ValidationObserver ref="observer" tag="form">
      <v-row v-if="applicantType === 'primary'">
        <v-col class="py-0 px-8">
          <ValidationProvider
            key="applicant_another_name"
            v-slot="{ errors }"
            name="Applicant Another Name"
            rules="required"
            vid="applicant_another_name"
          >
            <ButtonGroup3
              v-model="form.applicant_another_name"
              :error-messages="errors"
              :options="[
                { value: '1', label: 'Yes' },
                { value: '0', label: 'No' },
              ]"
              label="Has the applicant ever been known by another name?"
            />
          </ValidationProvider>
        </v-col>
      </v-row>
      <v-row
        class="m-0"
        v-if="
          form.applicant_another_name === '1' && applicantType === 'primary'
        "
      >
        <v-col cols="py-0">
          <p class="px-5 mb-0">What was the other name?</p>

          <v-row>
            <v-col class="py-0 px-8">
              <ValidationProvider
                key="'other-first-name'"
                v-slot="{ errors }"
                name="First Name"
                rules="required"
                vid="'other-first-name'"
              >
                <v-text-field
                  tile
                  type="text"
                  label="First Name"
                  color="secondary"
                  :error-messages="errors"
                  v-model="form['other-first-name']"
                  required
                >
                </v-text-field>
              </ValidationProvider>
            </v-col>
            <v-col class="py-0 px-8">
              <ValidationProvider
                key="'other-middle-name'"
                v-slot="{ errors }"
                name="Middle Name"
                vid="'other-middle-name'"
              >
                <v-text-field
                  tile
                  type="text"
                  label="Middle Name"
                  color="secondary"
                  :error-messages="errors"
                  v-model="form['other-middle-name']"
                >
                </v-text-field>
              </ValidationProvider>
            </v-col>
            <v-col class="py-0 px-8">
              <ValidationProvider
                key="'other-last-name'"
                v-slot="{ errors }"
                name="Last Name"
                rules="required"
                vid="'other-last-name'"
              >
                <v-text-field
                  tile
                  type="text"
                  label="Last Name"
                  color="secondary"
                  :error-messages="errors"
                  v-model="form['other-last-name']"
                  required
                >
                </v-text-field>
              </ValidationProvider>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row v-if="applicantType === 'secondary'">
        <v-col class="py-0 px-8" sm="12" md="6">
          <ValidationProvider
            key="lender-pepper-relationship"
            v-slot="{ errors }"
            name="lender-pepper-relationship"
            rules="required"
            vid="lender-pepper-relationship"
          >
            <v-select
              v-model="form['lender-pepper-relationship']"
              :items="getOptions('lender-pepper-relationship')"
              :error-messages="errors"
              color="secondary"
              label="Relationship with First Application"
            ></v-select>
          </ValidationProvider>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="py-0 px-8">
          <ValidationProvider
            key="privacy_consent"
            v-slot="{ errors }"
            name="Privacy Consent"
            rules="required"
            vid="privacy_consent"
          >
            <v-checkbox v-model="form.privacy_consent" color="secondary">
              <template v-slot:label>
                <div v-html="term.attributes.content"></div>
              </template>
            </v-checkbox>
          </ValidationProvider>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="py-0 px-8">
            <v-alert dense outlined type="error" v-if="errors.length > 0">
                <div v-for="msg in errors" v-html="msg"></div>
            </v-alert>
        </v-col>
      </v-row>
    </ValidationObserver>

  </v-container>
</template>

<script>
import { mapActions, mapMutations, mapGetters } from "vuex";
import subscriptions from "~/components/shared/subscriptions";
import shared from "~/components/v1/submission/LenderPepperEntities1/__shared";
import {
  store,
  actions,
} from "~/components/v1/submission/LenderPepperEntities1/__store";
import ButtonGroup3 from "~/components/base/ButtonGroup3";
import { ValidationProvider, ValidationObserver } from "vee-validate";

export default {
  components: { ButtonGroup3 },
  mixins: [subscriptions, shared],
  props: {
    data: {
      type: Object,
      default: null,
    },
    applicantType: {
      type: String,
      default: "primary",
    },
    isPrivacyConsentExists: {
      type: Boolean,
      default: false,
    },
    term: {
      type: Object,
      default: () => {},
    },
    updater: undefined,
  },
  data() {
    return {
      form: {
        privacy_consent: "",
        applicant_another_name: "",
        "other-first-name": "",
        "other-middle-name": "",
        "other-last-name": "",
        "lender-pepper-relationship": "",
      },
      tabSelection: "nodifi",
      resources: {
        person: {
          id: null,
          type: "people",
          attributes: {
            "other-first-name": "",
            "other-middle-name": "",
            "other-last-name": "",
            "lender-pepper-relationship": "",
          },
        },
      },
      labels: {
        "other-first-name": "",
        "other-middle-name": "",
        "other-last-name": "",
      },
      validation_rules: {
        attributes: {
          "other-first-name": "required",
          "other-middle-name": "",
          "other-last-name": "required",
        },
      },
      options: {
        "lender-pepper-relationship": [
          { text: "De Facto", value: "Defacto" },
          { text: "Spouse", value: "Spouse" },
        ],
      },
      errors: [],

    };
  },

  computed: {
    vb() {
      return this.$vuetify.breakpoint;
    },
    privacyConsent(){
      return this.form.privacy_consent
    }
  },
  mounted() {
    this.updateResourceFields();
  },
  methods: {
    ...mapActions("resource-actions", ["getPerson"]),
    selectTab(tab) {
      this.tabSelection = tab;
    },
    isBtnGroup(key) {
      let keys = ["lender-pepper-forsee-repayment"];

      return keys.includes(key);
    },
    async validate() {

      const valid = await this.$refs.observer.validate();

      this.checkCompleted()

      let allValid = this.errors.length == 0 && valid

      if (allValid) {
        let person = {};

        if (this.applicantType === "primary") {
          person = Object.keys(this.form).reduce((prev, curr) => {
            if (
              [
                "other-first-name",
                "other-middle-name",
                "other-last-name",
              ].includes(curr)
            ) {
              prev[curr] =
                this.form["applicant_another_name"] === "1"
                  ? this.form[curr]
                  : "";
            }
            return prev;
          }, {});
        } else {
          person["lender-pepper-relationship"] =
            this.form["lender-pepper-relationship"];
        }

        this.$emit("submit", {
          person: {
            ...this.resources.person,
            attributes: person,
          },
        });
      }
    },
    reset() {
      this.$refs.observer.reset();
    },
    async updateResourceFields() {
      // console.log('updateResourceFields')

      this.$emit("loading", true);

      const { data } = this;

      if (data) {
        let hasValue = false;
        let res = await this.getPerson(data.id);

        this.resources.person = this.filterResourceFields(
          this.resources.person,
          res.data.data
        );

        Object.keys(this.resources.person.attributes).forEach((key) => {
          this.form[key] = this.resources.person.attributes[key];

          if (
            [
              "other-first-name",
              "other-middle-name",
              "other-last-name",
            ].includes(key)
          ) {
            this.form["applicant_another_name"] = hasValue
              ? "1"
              : this.resources.person.attributes[key]
              ? "1"
              : "0";
            hasValue = this.form["applicant_another_name"] === "1";
          }
        });
        this.$emit("loading", false);
      } else {
        this.$emit("loading", false);
      }
    },
    checkCompleted(){
        this.errors = []
        if(!this.form.privacy_consent) {
            this.errors.push('Please check the privacy consent to proceed')
        } 
    },
  },
  watch: {
    updater(val) {
      // console.log(val)

      if (val) {
        this.updateResourceFields();
      }
    },
    privacyConsent(val) {
        if(val) {
            this.errors = []
        }
    },
    isPrivacyConsentExists: {
      handler(value) {
        this.form.privacy_consent = value;
      },
      immediate: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.privacy-consent-container {
  ul li,
  p {
    font-weight: 400 !important;
    font-size: 14px !important;
    line-height: 15px !important;
  }

  ul {
    margin-bottom: 16px;
  }

  p {
    margin-top: 8px;
  }
}
.privacy-consent {
  ::v-deep .v-slide-group__content {
    justify-content: flex-end;
  }
}
</style>
