const requireComponent = require.context(
    // The relative path of the components folder
    '../includes',
    // Whether or not to look in subfolders
    true,
    // The regular expression used to match base component filenames
    /[A-Z]\w+\.(vue|js)$/
)

let components = {}

requireComponent.keys().forEach(fileName => {

    const componentConfig = requireComponent(fileName)
    const name = fileName.split('/').pop().replace(/\.\w+$/, '').substring(1)
    const first = name.charAt(0)

    // console.log(name)
    components[name] = componentConfig.default


    
})

export default components