<template>
    <v-card class="mx-auto" :disabled="isDisabled || !validComponentData" tile flat>
        <v-card-text class="pa-4 pt-0 pa-md-10 pt-md-0">
            <v-container pa-0 fluid>
                <v-row>
                    <v-col cols="12">
                        <ValidationObserver ref="observer" tag="form">

                            <div class="flex-form">
                                <div
                                    v-for="(field, fkey) in fields"
                                    :key="fkey" 
                                    class="flex-field">

                                    <ValidationProvider
                                        v-slot="{ errors }"
                                        :name="field.meta | formalText"
                                        rules="required"
                                        :vid="field.meta">
                                        <v-text-field
                                            v-model="resourceObj[field.meta]"
                                            :error-messages="errors"
                                            :label="field.label">
                                        </v-text-field>
                                    </ValidationProvider>

                                </div>
                            </div>

                        </ValidationObserver>
                    </v-col>

                    <v-col>
                        <v-alert outlined type="info" color="secondary" dark prominent>
                            <p class="body-2 ma-0">This information is optional, however, it is helpful is speeding up the settlement process, so we ask it be entered where known.</p>
                        </v-alert>
                    </v-col>
                </v-row>
            </v-container>
        </v-card-text>
    </v-card>
</template>

<script>

    import subscriptions from '~/components/shared/subscriptions'
    import { mapActions, mapGetters } from 'vuex'

    export default {
        name: 'DirectDebitEditor1',

        mixins: [subscriptions],

        created() {
            this.resourceObj = this.resourceObjHelper(this.resources)
        },

        methods: {
            ...mapActions('resource-actions', [
                'getApplicantLoan',
                'saveApplicantLoan'
            ]),

            async pull() {
                let response = await this.getApplicantLoan()
                if (response.data?.data !== null)
                    this.resourceObj = this.resourceObjHelper(response.data?.data)
                else {
                    this.validComponentData = false
                }
            },

            async push() {
                const valid = await this.$refs.observer.validate()
                const observer = this.$refs.observer
                const touched = this.$refs.observer.flags.touched

                let requestPayload = Object.assign({}, this.resources)
                let status = this.getActiveStepStatus.attributes.value 

                if(this.hardValidation) {
                    status =  valid ? 'valid' : 'dirty'
                } 
                
                else {
                    if(touched) { status =  valid ? 'valid' : 'dirty' }
                }

                requestPayload.attributes = this.removeInvalids(requestPayload.attributes)
                
                let res = await this.saveApplicantLoan(requestPayload)
                
                let data = this.$options.filters.toAppDataFormat(res)

                return  { valid, status, data } 
            },

            resourceObjHelper(resources) {
                
                let collection = {}
                Object.keys(resources).forEach( f => {
                    
                    collection[f] = resources[f]
                })
                
                Object.keys(collection.relationships).forEach( rel => {
                    if (collection?.relationships[rel].data === null || collection?.relationships[rel].data?.length < 1) {
                        delete collection.relationships[rel]
                    }
                })

                let attrField = {}
                
                Object.keys(collection.attributes).forEach( attr => {    
                    if (this.resources.attributes[attr] !== undefined)
                        attrField[attr] = collection.attributes[attr]
                })

                collection.attributes = attrField
                this.resources = collection
                return collection.attributes
                
            },

            removeInvalids(fields = {}) {
                let collection = {}
                Object.keys(fields).forEach( f => {
                    if (fields[f] !== null && fields[f] !== '')
                        collection[f] = fields[f]
                })
                return collection
            }
        },

        watch: {
            "validComponentData": {
                handler(val) {
                    if ( !this.validComponentData ) {

                        this.showComponentWarning()

                    }
                },
                deep: true
            }
        },

        data() {
            return {
                validComponentData: true,
                resources: {                        
                    'type': 'loans',
                    'id': null,
                    'attributes': {
                        'bank-account-name': null,
                        'bank-account-number': null,
                        'bank-bsb-number': null
                    },
                    'relationships': {
                        'application': {
                            "data": {
                                "type": "applications",
                                "id": null
                            },
                        }
                    }
                },
                resourceObj: {},
                fields: {
                    'bank-account-name': { label: 'Bank Account Name', meta: 'bank-account-name' },
                    'bank-account-number': { label: 'Bank Account Number', meta: 'bank-account-number' },
                    'bank-bsb-number': { label: 'Bank BSB Number', meta: 'bank-bsb-number' }
                },
                labels: {

                }
            }
        }
    }
</script>

<style lang="scss" scoped>
    .flex-form {
        display: grid;
        grid-column-gap: 15px;
        grid-row-gap: 0;
        grid-template-columns:  repeat(auto-fit, minmax(260px, 1fr));

        .flex-field {
            align-self: start;
        }
    }
</style>