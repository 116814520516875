<template>
    <v-card 
    tile
    class="mx-auto" 
    outlined >
        <v-list-item three-line>
            <v-list-item-content>
                <v-list-item-title class="headline mb-1">{{block.component.name}}</v-list-item-title>
            </v-list-item-content>
        </v-list-item>

        <v-card-actions>
            <v-btn text nuxt @click="selectCollapseLeftSideBarSpace">Collapse Left</v-btn>
        </v-card-actions>
        <v-card-actions>
            <v-btn text nuxt @click="selectExpandLeftSideBarSpace">Expand Left</v-btn>
        </v-card-actions>
        <v-card-actions>
            <v-btn text nuxt @click="selectMinimiseLeftSideBarSpace">Minimise Left</v-btn>
        </v-card-actions>

    </v-card>
</template>

<script>

    import { mapActions } from 'vuex';
    import subscriptions from '~/components/shared/subscriptions'

    export default {
        mixins: [subscriptions],
        data(){
            return {
            }
        },
        computed: {
         
        },
        created() {

        },
        methods: {
            ...mapActions(['selectCollapseLeftSideBarSpace', 'selectExpandLeftSideBarSpace', 'selectMinimiseLeftSideBarSpace']),
        }
    }
</script>

<style scoped lang="scss">

</style>
