var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('ValidationObserver',{ref:"observer",attrs:{"tag":"form"}},[_c('v-row',{staticClass:"m-0"},[_c('v-col',{attrs:{"cols":"py-0"}},[_c('v-row',[_c('v-col',{staticClass:"py-0 px-8",attrs:{"sm":"12","md":"4"}},[_c('ValidationProvider',{key:"'medicare-expiry'",attrs:{"name":_vm.labels['medicare-expiry'],"rules":_vm.validation_rules.attributes['medicare-expiry'],"vid":"'medicare-expiry'"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('FlexDatePicker3',{attrs:{"tile":"","type":"text","label":_vm.labels['medicare-expiry'],"color":"secondary","errors":errors},model:{value:(_vm.form['medicare-expiry']),callback:function ($$v) {_vm.$set(_vm.form, 'medicare-expiry', $$v)},expression:"form['medicare-expiry']"}})]}}])})],1),_vm._v(" "),_c('v-col',{staticClass:"py-0 px-8",attrs:{"sm":"12","md":"4"}},[_c('ValidationProvider',{key:"'medicare-number'",attrs:{"name":_vm.labels['medicare-number'],"vid":"'medicare-number'","rules":_vm.validation_rules.attributes['medicare-number']},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"tile":"","type":"text","label":_vm.labels['medicare-number'],"color":"secondary","error-messages":errors,"required":""},model:{value:(_vm.form['medicare-number']),callback:function ($$v) {_vm.$set(_vm.form, 'medicare-number', $$v)},expression:"form['medicare-number']"}})]}}])})],1),_vm._v(" "),_c('v-col',{staticClass:"py-0 px-8",attrs:{"sm":"12","md":"4"}},[_c('ValidationProvider',{key:"'middle-initial'",attrs:{"name":_vm.labels['middle-initial'],"vid":"'middle-initial'"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"tile":"","type":"text","label":_vm.labels['middle-initial'],"color":"secondary","error-messages":errors,"value":_vm.form['middle-initial'],"required":"","disabled":""}})]}}])})],1)],1)],1)],1),_vm._v(" "),_c('v-row',[_c('v-col',{staticClass:"py-0 px-8",attrs:{"sm":"12","md":"4"}},[_c('ValidationProvider',{key:"'medicare-position-on-card'",attrs:{"name":_vm.labels['medicare-position-on-card'],"vid":"'medicare-position-on-card'","rules":_vm.validation_rules.attributes['medicare-position-on-card']},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"tile":"","type":"number","label":_vm.labels['medicare-position-on-card'],"color":"secondary","error-messages":errors},model:{value:(_vm.form['medicare-position-on-card']),callback:function ($$v) {_vm.$set(_vm.form, 'medicare-position-on-card', $$v)},expression:"form['medicare-position-on-card']"}})]}}])})],1),_vm._v(" "),_c('v-col',{staticClass:"py-0 px-8",attrs:{"sm":"12","md":"4"}},[_c('ValidationProvider',{key:"medicare-colour-of-card",attrs:{"name":_vm.labels['medicare-colour-of-card'],"vid":"medicare-colour-of-card","rules":_vm.getRules('medicare-colour-of-card')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"items":_vm.getOptions('medicare-colour-of-card'),"label":_vm.labels['medicare-colour-of-card'],"error-messages":errors,"color":"secondary"},model:{value:(_vm.form['medicare-colour-of-card']),callback:function ($$v) {_vm.$set(_vm.form, 'medicare-colour-of-card', $$v)},expression:"form['medicare-colour-of-card']"}})]}}])})],1),_vm._v(" "),_c('v-col',{staticClass:"py-0 px-8",attrs:{"sm":"12","md":"4"}},[_c('ValidationProvider',{key:"lender-wisr-bank-statement-preference",attrs:{"name":_vm.labels['lender-wisr-bank-statement-preference'],"vid":"lender-wisr-bank-statement-preference","rules":_vm.getRules('lender-wisr-bank-statement-preference')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"items":_vm.getOptions('lender-wisr-bank-statement-preference'),"label":_vm.labels['lender-wisr-bank-statement-preference'],"error-messages":errors,"color":"secondary"},model:{value:(_vm.form['lender-wisr-bank-statement-preference']),callback:function ($$v) {_vm.$set(_vm.form, 'lender-wisr-bank-statement-preference', $$v)},expression:"form['lender-wisr-bank-statement-preference']"}})]}}])})],1)],1),_vm._v(" "),_c('v-row',[_c('v-col',{staticClass:"py-0 px-8"},[(_vm.errors.length > 0)?_c('v-alert',{attrs:{"dense":"","outlined":"","type":"error"}},_vm._l((_vm.errors),function(msg,index){return _c('div',{key:index,domProps:{"innerHTML":_vm._s(msg)}})}),0):_vm._e()],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }