export default {
    "fo-step": {
        "role-selector": {
            "title": "Who owns this investment property?",
            "fields": [
                {
                    "role-selector": {
                        "resources": "assets"
                    }
                }
            ]
        },
        "asset-value": {
            "title": "Approximate Value of Property",
            "fields": [
                {
                    "asset-value": {
                        "resources": "assets"
                    }
                }
            ]
        },
        "mortage-property": {
            "title": "Is there a mortgage on this property?",
            "fields": [
                {
                    "mortage-property": {
                        "resources": "assets"
                    }
                }
            ]
        },
        "mortage-details": {
            "title": "Mortage Details",
            "fields": [
                {
                    "amount-owing": {
                        "resources": "liabilities"
                    },
                    "amount-financed": {
                        "resources": "liabilities"
                    },
                    "payment-amount": {
                        "resources": "expenses"
                    },
                    "payment-frequency": {
                        "resources": "expenses"
                    },
                    "financial-institution": {
                        "resources": "liabilities"
                    },
                    "other-institution": {
                        "resources": "liabilities"
                    },
                    "to-be-paid-out": {
                        "resources": "liabilities"
                    }
                }
            ]
        },
    }
}