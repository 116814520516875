<template>
    <v-card class="mx-auto px-8" flat>
        <div v-if="vb.mdAndUp" >
            <div class="d-flex">
                <v-sheet width="50%" color="light_gray_1" class="pa-4">
                    <div class="px-4 pt-4">
                        <v-img 
                            max-width="120"
                            class="flex-brand" 
                            :src="require(`~/assets/images/nodifi.svg`)" 
                            position="center left" 
                            contain>
                        </v-img>
                        <div class="mt-2 font-size-20">Data</div>
                    </div>

                    <v-card flat class="overflow-y-auto light_gray_1 mt-4">
                        <v-card-text class="d-flex">

                            <v-list class="pt-0 light_gray_1">

                                <v-list-item two-line class="px-0">
                                    <v-list-item-content class="pt-0">
                                        <div style="height: 280px;">
                                            <v-list-item-title class="font-weight-medium">Basic & Household section</v-list-item-title>
                                            <v-list-item-subtitle>{{ resources['nodifi-expenses'].attributes['living-food-and-clothing'] ? resources['nodifi-expenses'].attributes['living-food-and-clothing'] : '&mdash;' | currency }}</v-list-item-subtitle>
                                        </div>
                                    </v-list-item-content>
                                </v-list-item>

                            </v-list>

                        </v-card-text>
                    </v-card>
                </v-sheet>


                <v-sheet width="50%" class="pa-4">
                    <div class="px-4">
                        <v-img 
                            max-width="120"
                            class="flex-brand" 
                            :src="require(`~/assets/images/logo/cropped/lender-logo-wisr.png`)" 
                            position="center left" 
                            contain>
                        </v-img>
                        <div class="mt-2 font-size-20">Requirements</div>
                    </div>
                    <v-card flat class="overflow-y-auto mt-4">
                        <v-card-text class="d-flex pa-0">

                            <div class="px-4" style="width: 400px;">
                                <ValidationObserver ref="observer" tag="form">
                                    <div v-for="(layout, index) in layouts" :key="index">
                                        <template v-for="(key, index) in layout">

                                            <div :key="index" v-if="key == '__spacer'">
                                                <div class="py-5"></div>
                                            </div>

                                            <div v-else>
                                                <ValidationProvider 
                                                    v-slot="{ errors }" 
                                                    :name="labels[key]"
                                                    :rules="getRules(key)" 
                                                    :vid="key">

                                                        <NodifiMoney 
                                                            :label="labels[key]"
                                                            class="pt-3"
                                                            color="secondary"
                                                            :errors="errors"
                                                            v-model="resources['wisr'].attributes[key]" />

                                                </ValidationProvider>
                                            </div>

                                        </template>
                                    </div>
                                </ValidationObserver>
                            </div>

                        </v-card-text>
                    </v-card>
                </v-sheet>
            
            </div>

        </div>
        
        <!-- mobile version -->
        <div v-else>

            <v-card flat class="mx-5 rounded-xl" :style="`background-color: ${hexToRGBA(getActiveTheme.colors.secondary, 0.2)}`">

                <v-card-text class="d-flex py-1">
                    <v-row>
                        <v-col cols="6" class="pa-1">
                            <v-card
                                min-height="45"
                                flat class="rounded-xl px-1"
                                :color="tabSelection == 'nodifi' ? 'white' : 'transparent'">
                                <div class="d-flex justify-center pt-1" @click="selectTab('nodifi')">
                                    <v-img 
                                        max-width="55"
                                        class="flex-brand" 
                                        :src="require(`~/assets/images/nodifi.svg`)" 
                                        position="center left" 
                                        contain>
                                    </v-img>
                                    <div class="mt-3 font-size-12 pl-1">Data</div>
                                </div>
                            </v-card>
                        </v-col>
                        <v-col cols="6" class="pa-1">
                            <v-card
                                min-height="45"
                                flat class="rounded-xl px-1"
                                :color="tabSelection == 'wisr' ? 'white' : 'transparent'">
                                <div class="d-flex flex-column" @click="selectTab('wisr')">
                                    <v-img 
                                        max-width="55"
                                        class="flex-brand mx-auto mt-1" 
                                        :src="require(`~/assets/images/logo/cropped/lender-logo-wisr.png`)" 
                                        position="center left" 
                                        contain>
                                    </v-img>
                                    <div class="font-size-12 pl-1 mx-auto mt-n1">Requirements</div>
                                </div>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-card-text>
                
            </v-card>

            <v-list  v-show="tabSelection == 'nodifi'" class="mx-8" color="transparent">
                <v-list-item two-line class="px-0">
                    <v-list-item-content>
                        <v-list-item-title class="font-weight-medium">Basic & Household section</v-list-item-title>
                        <v-list-item-subtitle>{{ resources['nodifi-expenses'].attributes['living-food-and-clothing'] ? resources['nodifi-expenses'].attributes['living-food-and-clothing'] : '&mdash;' | currency }}</v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>
            </v-list>

            <div v-show="tabSelection != 'nodifi'" class="mx-8">
                <ValidationObserver ref="observer" tag="form">
                    <div v-for="(layout, index) in layouts" :key="index">
                        <template v-for="(key, index) in layout">

                            <div :key="index" v-if="key == '__spacer'">
                                <div class="py-5"></div>
                            </div>

                            <div v-else>
                                <ValidationProvider 
                                    v-slot="{ errors }" 
                                    :name="labels[key]"
                                    :rules="getRules(key)" 
                                    :vid="key">

                                        <v-textarea
                                            v-if="isTextarea(key)"
                                            color="secondary"
                                            :error-messages="errors"
                                            :label="labels[key]"
                                            v-model="resources['wisr'].attributes[key]"
                                            rows="4"
                                            row-height="40"
                                            auto-grow>
                                        </v-textarea>

                                        <NodifiMoney 
                                            v-else
                                            :label="labels[key]"
                                            color="secondary"
                                            :errors="errors"
                                            v-model="resources['wisr'].attributes[key]" />

                                </ValidationProvider>
                            </div>

                        </template>
                    </div>
                </ValidationObserver>
            </div>

        </div>
    </v-card>
</template>

<script>
    import { mapActions, mapState, mapMutations, mapGetters } from 'vuex'
    import subscriptions from '~/components/shared/subscriptions'
    import NodifiMoney from '~/components/base/NodifiMoney'
    export default {
        name: 'LenderWisrExpenses1',
        components: { NodifiMoney },
        mixins: [subscriptions],
        data() {
            return {
                tabSelection: "nodifi",
                expenses: [],
                resources: {
                    "nodifi-expenses": {
                        "attributes": {
                            "living-food-and-clothing": null,
                            "living-transport": null,
                            "living-utilities-and-rates": null,
                            "living-insurance": null,
                            "living-other-living-expenses": null,
                        }
                    },
                    "wisr": {
                        "attributes": {
                            "lender-wisr-living-household": null,
                            "lender-wisr-living-clothing": null,
                            "lender-wisr-living-basic": null,
                            "lender-wisr-living-entertainment": null,
                            "lender-wisr-living-phone-internet": null,
                            "living-education": null,
                            "lender-wisr-living-health-insurance": null,
                            "lender-wisr-living-car-home-insurance": null,
                            "lender-wisr-living-car-motorbike-registration": null,
                            "lender-wisr-living-petrol-maintenance": null,
                            "lender-wisr-living-second-car-motorbike": null,
                            "lender-wisr-living-transportation-other": null,
                            "lender-wisr-living-council-rates": null,
                            "lender-wisr-living-utilities": null,
                            "living-other-living-expenses": null,
                            "__spacer": null,
                        }
                    }
                },
                layouts: [
                    [
                        "lender-wisr-living-household",
                        "lender-wisr-living-clothing",
                        "lender-wisr-living-basic",
                        "lender-wisr-living-entertainment"
                    ],
                    [
                        "lender-wisr-living-phone-internet",
                        "living-education",
                        "lender-wisr-living-health-insurance",
                        "lender-wisr-living-car-home-insurance"
                    ],
                    [
                        "lender-wisr-living-car-motorbike-registration",
                        "lender-wisr-living-petrol-maintenance",
                        "lender-wisr-living-second-car-motorbike",
                        "lender-wisr-living-transportation-other"
                    ],
                    [
                        "lender-wisr-living-council-rates",
                        "lender-wisr-living-utilities",
                        "living-other-living-expenses",
                        "__spacer",
                        "__spacer"
                    ]
                ],
                labels: {
                    "lender-wisr-living-household": "Groceries, Including Alcohol and Cigarettes",
                    "lender-wisr-living-clothing": "Clothing",
                    "lender-wisr-living-basic": "General Expenses Other",
                    "lender-wisr-living-entertainment": "Entertainment",
                    "lender-wisr-living-phone-internet": "Phone and Internet",
                    "living-education": "Private Education",
                    "lender-wisr-living-health-insurance": "Health Insurance",
                    "lender-wisr-living-car-home-insurance": "Car and Home Insurance",
                    "lender-wisr-living-car-motorbike-registration": "Vehicle Registration",
                    "lender-wisr-living-petrol-maintenance": "Petrol and Vehicle Maintenance",
                    "lender-wisr-living-second-car-motorbike": "Secondary Vehicle Expenses",
                    "lender-wisr-living-transportation-other": "Transportation Other",
                    "lender-wisr-living-council-rates": "Council Rates",
                    "lender-wisr-living-utilities": "Utilities",
                    "living-other-living-expenses": "Additional Other Expenses",
                },
                validation_rules: {
                    attributes: {
                        "__spacer": "",
                        "lender-wisr-living-household": "between:0,9999999",
                        "lender-wisr-living-clothing": "between:0,9999999",
                        "lender-wisr-living-basic": "between:0,9999999",
                        "lender-wisr-living-entertainment": "between:0,9999999",
                        "lender-wisr-living-phone-internet": "between:0,9999999",
                        "lender-wisr-living-health-insurance": "between:0,9999999",
                        "lender-wisr-living-car-home-insurance": "between:0,9999999",
                        "lender-wisr-living-car-motorbike-registration": "between:0,9999999",
                        "lender-wisr-living-petrol-maintenance": "between:0,9999999",
                        "lender-wisr-living-second-car-motorbike": "between:0,9999999",
                        "lender-wisr-living-transportation-other": "between:0,9999999",
                        "lender-wisr-living-council-rates": "between:0,9999999",
                        "lender-wisr-living-utilities": "between:0,9999999",
                        "living-other-living-expenses": "between:0,9999999"
                    }
                },
            }
        },
        computed: {
            ...mapGetters('themes', [
                'getActiveTheme'
            ]),
            vb(){
                return this.$vuetify.breakpoint
            },
            livingExpense() {
                let living_expense = null
                if(this.expenses.length > 0) {
                    this.expenses.forEach(expense => {
                        //if kind is living expense
                        if(expense.relationships.kind.data.id == '11-16') {
                            living_expense = expense
                        }
                    })
                }
                return living_expense
            },
            ownerOccupiedExpense() {
                let owner_occupied_expense = null
                if(this.expenses.length > 0) {
                    this.expenses.forEach(expense => {
                        //if kind is Mortgage - Owner Occupied
                        if(expense.relationships.kind.data.id == '11-6') {
                            owner_occupied_expense = expense
                        }
                    })
                }
                return owner_occupied_expense
            },
            mortgageInvestmentExpense() {
                let mortgage_investment = null
                if(this.expenses.length > 0) {
                    this.expenses.forEach(expense => {
                        //if kind is 11-30 Mortgage - Investment
                        if(expense.relationships.kind.data.id == '11-30') {
                            mortgage_investment = expense
                        }
                    })
                }
                return mortgage_investment
            }
        },
        methods: {
            ...mapActions('resource-actions', [
                'updateExpense',
                'getPeople',
            ]),
            async pull() {

                try {
                    let promise1 = this.getPeople({"include" : 'parent-parts,expenses'})

                    const res = await Promise.all([
                        promise1, 
                    ]) 

                    promise1 = res[0]

                    if(promise1.data && promise1.data.hasOwnProperty('included')) {
                        let expenses = []

                        promise1.data.included.forEach(item => {
                            if(item.type == 'expenses') {
                                expenses.push(item)
                            }
                        })

                        this.expenses = expenses

                        await this.populateFields()
                        
                    }

                } catch (err) {
                    console.log(err)
                }

            },
            async push() {
                try {

                    let expenseRes = null;

                    if(this.livingExpense) {

                        const expenses = {
                            "id": this.livingExpense.id,
                            "type": "expenses",
                            "attributes": {
                                ...this.resources['wisr'].attributes,
                            }
                        }

                        expenseRes = this.updateExpense(expenses)

                        await Promise.all([expenseRes])
                    }

                    const {valid, status} = await this.validateFormData()
                    
                    return {valid, status, data: {}}
                } catch {err} {
                    console.log(err)
                }
            },
            populateFields() {
                let { mortgageInvestmentExpense, ownerOccupiedExpense, livingExpense, resources } = this

                if(livingExpense) {

                    // populate fields for nodifi living expense
                    Object.keys(resources['nodifi-expenses'].attributes).forEach(key => {
                        resources['nodifi-expenses'].attributes[key] = livingExpense.attributes[key]
                    })

                    // populate fields for wisr living expense
                    Object.keys(resources['wisr'].attributes).forEach(key => {
                        resources['wisr'].attributes[key] = livingExpense.attributes[key]
                    })

                }

                // if it has owner occupied expense in nodifi data
                if(ownerOccupiedExpense) {
                    resources['wisr'].attributes['lender-wisr-living-owner-occupied'] = ownerOccupiedExpense.attributes['payment-amount']
                }

                // if it has mortgage investment expense in nodifi data
                if(mortgageInvestmentExpense) {
                    resources['wisr'].attributes['lender-wisr-living-investment'] = mortgageInvestmentExpense.attributes['payment-amount']
                }

            },
            isTextarea (key) {
                return [
                    "lender-wisr-living-description",
                ].includes(key)
            },
            hexToRGBA(hex, a = 1){
                return this.$options.filters.hexToRGBA(hex, a)
            },
            selectTab(tab) {
                this.tabSelection = tab
            },
        }
    }
</script>

<style lang="scss">
</style>