<template>
    <v-card class="mx-auto" tile flat>
        <v-card-text class="pa-4 pt-0 pa-md-10 pt-md-0">

               <ValidationObserver ref="observer" tag="form">
                   <div class="flex-form" v-for="layout in fieldLayout">
                        <template v-for="(key, index) in fieldLayoutMaker2(layout, resources.loan.attributes)">

                        <div class="flex-field" :key="key + index" v-if="key == '__spacer'"></div>
                        
                        <div class="flex-field" :key="key + index" v-else>

                            <ValidationProvider
                                v-slot="{ errors }"
                                :name="labels[key]"
                                v-if="isIncluded(key)"
                                :rules="getRules(key)"
                                :vid="key">

                                <v-select
                                    v-if="isFieldType('picklist', key)"
                                    v-model="resources.loan.attributes[key]"
                                    :items="getOptions(key)"
                                    :error-messages="errors"
                                    color="secondary"
                                    :readonly="isReadOnly(key)"
                                    :prefix="prefix[key]"
                                    :label="labels[key]"
                                ></v-select>

                                <v-text-field
                                    v-else
                                    v-model="resources.loan.attributes[key]"
                                    :type="isNumber(key) ? 'number' : 'text'"
                                    :label="labels[key]"
                                    :error-messages="errors"
                                    :prefix="prefix[key]"
                                    color="secondary"
                                ></v-text-field> 

                            </ValidationProvider>

                        </div>
                        </template>

                    </div>
                </ValidationObserver>

        </v-card-text>
     
    </v-card>
</template>

<script>

    import { mapActions, mapState } from 'vuex'
    import helpers from '~/plugins/helpers'
    import subscriptions from '~/components/shared/subscriptions'

    import lenderFields from '~/components/v1/submission/Customisations1/__lenders'
    import fieldOptions from '~/components/shared/field-options'


    export default {
        components: { },

        mixins: [subscriptions, helpers],

        data() {
            return {
                resources: {
                    loan: {
                        type: 'loans',
                        id: null,
                        attributes: {
                            // see lenderFields
                        }
                    }
                },
                options: {
     
                },
                labels: {


                },
                validation_rules: {
                    type: "loans",
                    attributes: {
        
                    }
                },
                included: {
                },
                prefix: {
                },
                fieldLayout: [
                    // see lenderFields
                ],
                readonly: {},
            }
        },
        computed: {
            ...mapState({
                appId: state => state.flows.appId,
                loanData: state => state.flows.loanData,
            }),
            getLoanId(){
                return this.appData.relationships.loan.data.id
            },
        },
        methods: {
            ...mapActions('resource-actions', [
                'getLoan',
                'updateLoan',
            ]),
            async pull() {

       

            },
            async push() {

                const observer = this.$refs.observer

                const {valid, status} = await this.validateFormData()

                return {valid, status}
                
            },
            isIncluded(field){

                const {included} = this

                if(included.hasOwnProperty(field)) {
                    return included[field]
                }

                return true
            },
            isReadOnly(key){
                
                const {readonly} = this

                if(readonly.hasOwnProperty(key)){
                    return readonly[key]
                }

                return false

            },
            isNumber(key){
                return [
                ].includes(key)
            },
        },
        watch: {
    
        }
    }
</script>

<style lang="scss" scoped>

</style>