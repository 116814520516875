<template>
    <div v-if="getComponentName">
        <component :is="getComponentName" :class="`flex__component-${getComponentName}`" />
    </div>
</template>

<script>
    
    import subscriptions from '~/components/shared/subscriptions'
    import includes from '~/components/v1/InfoBox1/includes'

    export default {
        mixins: [subscriptions],
        components: {
            ...includes
        },
        data(){
            return {
                dialog: false,
            }
        },
        mounted() {
        },
        computed: {

            getComponentName(){

                switch (this.blockSettings.name) {
                    case "credit-status-excellent-average-poor":
                        return "CreditHistoryInfo1"
                    default:
                        return null
                }


            }
 
        },
        methods: {

        },

    }
</script>

<style scoped lang="scss">

</style>
