<template>
    <!-- B2B -->
    <v-card :disabled="isDisabled" class="mx-auto" tile flat>

        <RequirementChecklist1/>

        <v-card-text class="px-4 py-2 px-md-10 pt-md-5">
            <v-container pa-0 fluid>
                <v-row>
                    <v-col cols="12">

                        <v-tabs v-model="selectedEntity" :slider-color="slideColor" height="66">
                            <v-tab v-for="entity in entities" 
                                :key="entity.id" 
                                :class="{'error--text': (errorMessages[entity.id] && errorMessages[entity.id].length)}">

                                <v-list-item class="px-0">
                                    <v-list-item-icon class="align-self-center ma-0 mr-3">
                                        <v-icon v-if="entity.type == 'people'" size="32" class="mb-2">mdi-account</v-icon>
                                        <v-icon v-else size="32" class="mb-2">mdi-city</v-icon>
                                    </v-list-item-icon>
                                    <v-list-item-content class="text-left">
                                        <v-list-item-title class="ma-0">
                                            {{ getEntityFriendlyName(entity) }}
                                        </v-list-item-title>
                                        <v-list-item-subtitle class="mb-2">
                                            {{ getRoles(entity) }}
                                        </v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-list-item>
                                
                            </v-tab>
                        </v-tabs>

                        <v-tabs-items v-model="selectedEntity">
                            <v-tab-item
                                v-for="entity in entities"
                                :key="entity.id"
                            >
                                <v-card flat class="pt-2" :disabled="blockPermissionsReadOnly">
                                    <v-data-table
                                        :headers="tableHeaders"
                                        :items="dataTables[entity.id]"
                                        hide-default-footer
                                        no-data-text="No Records"
                                        class="elevation-0 v-data-table-3"
                                        :custom-sort="customSort"
                                        :mobile-breakpoint="0">
                                    
                                        <template v-slot:item.ctrl="props">
                                            <div class="d-flex justify-end pa-4">
                                                <v-btn text depressed fab color="light_gray_2" small @click.stop="openAddressEditorModal(props.item)"> 
                                                    <v-icon size="20">mdi-pencil</v-icon>
                                                </v-btn>

                                                <ConfirmModal 
                                                    v-if="props.item.addresses"
                                                    :title="`Delete Address`"
                                                    async
                                                    confirm-text="Delete"
                                                    :processing="isDeleting"
                                                    :message="`Remove ${props.item.addresses.attributes['full-address']}?`"
                                                    @confirm="handleConfirm(props.item)">
                                                        <template v-slot:activator="{on}">
                                                            <v-btn text depressed fab color="light_gray_2" small @click.stop="on">
                                                                <v-icon size="20">mdi-delete</v-icon>
                                                            </v-btn>
                                                        </template>
                                                </ConfirmModal>
                                              
                                            </div>
                                        </template>

                                        <template v-slot:item.addresses.attributes.full-address="props">
                                            <div v-if="props.item.addresses" class="cell">
                                                <div class="text-truncate">{{ props.item.addresses.attributes['full-address'] }}</div>
                                            </div>
                                        </template>

                                        <template v-slot:item.occupancies.attributes.start-date="props">
                                            {{ props.item.occupancies.attributes['start-date'] | dateFormatFE}}
                                        </template>
                                        <template v-slot:item.occupancies.attributes.end-date="props">
                                            {{ props.item.occupancies.attributes['end-date'] | dateFormatFE}}
                                        </template>

                                    </v-data-table>
                                </v-card>
                                
                            </v-tab-item>
                        </v-tabs-items>

                    </v-col>
                    <v-col cols="12" v-if="getSlideErrors">
                        <v-alert dense outlined type="error" class="ma-0">
                            <div v-for="error in getSlideErrors" v-html="error"></div>
                        </v-alert>
                    </v-col>
                </v-row>
            </v-container>
        </v-card-text>

        <v-card-actions class="controls px-4 px-md-10 pt-4 pt-md-4 pb-4 pb-md-8">
            <v-container pa-0 fluid>
            <v-row>
                <v-col class="pt-0 d-flex justify-end">

                <AddressCopyDialog
                    v-if="filteredRole"
                    :dialog="toggleAddressCopyModal"
                    :selected-entity="entities[selectedEntity]"
                    :options="filteredRole"
                    :entity="entities"
                    :resources-data="resources"
                    @close="closeAddressCopyModal"
                    @save="handleSelector" 
                >
                    <template v-slot:title-1>
                        <v-col class="py-0 mx-0 px-0">
                            <v-card-title class="mb-2 py-0">
                                Copy History
                            </v-card-title>
                        </v-col>
                    </template>
                    <template v-slot:activator="{on}">
                        <v-btn color="secondary" large depressed nuxt @click.stop="on" :disabled="checkAddressExist">Copy History</v-btn>
                    </template>
                </AddressCopyDialog>

                <AddressSelectorDialog1 
                    :options="getExistingAddresses" 
                    :entity="entities[selectedEntity]" 
                    :data="AddressEditorData"
                    :occupancies="resources.occupancies"
                    @select="handleSelector">
                    <template v-slot:title-1>
                        <v-col class="py-0">
                            <v-card-title class="mt-4 mb-2">
                                Add Existing Address
                            </v-card-title>
                            <v-card-subtitle>
                            </v-card-subtitle>
                        </v-col>
                    </template>
                    <template v-slot:title-2>
                        <v-col class="py-0">
                            <v-card-title class="mt-4 mb-2">
                                Occupancy Details
                            </v-card-title>
                            <v-card-subtitle>
                                Please fill out the details below accurately
                            </v-card-subtitle>
                        </v-col>
                    </template>
                    <template v-slot:activator="{on}">
                        <v-btn color="secondary" large depressed nuxt @click.stop="on" :disabled="getExistingAddresses.length == 0 || blockPermissionsReadOnly">Add Existing <span class="d-none d-md-inline">Address</span></v-btn>
                    </template>
                </AddressSelectorDialog1>

                <v-btn color="secondary" :disabled="blockPermissionsReadOnly" large depressed class="ml-3" nuxt @click.stop="openAddressEditorModal(null)">Add New <span class="d-none d-md-inline">Address</span></v-btn>

                <AddressEditorDialog1 
                    v-model="toggleAddressEditorModal" 
                    :data="AddressEditorData"
                    :occupancies="resources.occupancies"
                    @close="closeAddressEditorModal" 
                    :entity="entities[selectedEntity]" 
                    @create="handleEditorCreate"
                    @update="handleEditorUpdate">
                    <template v-slot:title-1>
                        <v-col class="py-0">
                            <v-card-title class="mt-4 mb-2">
                                Address Details
                            </v-card-title>
                            <v-card-subtitle>
                                Please fill out the details below accurately
                            </v-card-subtitle>
                        </v-col>
                    </template>
                    <template v-slot:title-2>
                        <v-col class="py-0">
                            <v-card-title class="mt-4 mb-2">
                                Occupancy Details
                            </v-card-title>
                            <v-card-subtitle>
                                Please fill out the details below accurately
                            </v-card-subtitle>
                        </v-col>
                    </template>
                </AddressEditorDialog1>

                </v-col>
            </v-row>
            </v-container>
        </v-card-actions>

    </v-card>
</template>

<script>

    import subscriptions from '~/components/shared/subscriptions'
    import resourceHelpers from '~/components/shared/resource-helpers'
    import { mapState, mapActions, mapGetters, mapMutations } from 'vuex'

    import AddressSelectorDialog1 from '~/components/v1/ResidentialHistoryEditor2/_AddressSelectorDialog1'
    import AddressEditorDialog1 from '~/components/v1/ResidentialHistoryEditor2/_AddressEditorDialog1'
    import AddressCopyDialog from '~/components/v1/ResidentialHistoryEditor2/_AddressCopyDialog'
    import ConfirmModal from '~/components/base/ConfirmModal2'

    export default {
        name: 'ResidentialHistoryEditor2',
        components: {
            AddressSelectorDialog1,
            AddressEditorDialog1,
            ConfirmModal,
            AddressCopyDialog,
        },
        mixins: [ subscriptions, resourceHelpers ],
        data(){
            return {
                filteredRole: [],
                toggleAddressCopyModal: false,
                menu: false,
                toggleAddressEditorModal: false, 
                AddressEditorData: null,
                selectedEntity: 0,
                headers: [
                    {
                        text: 'Active',
                        align: 'start',
                        sortable: false,
                        value: 'occupancies.attributes.occupancy-situation',
                        width: '15%'
                    },
                    { sortable: false, text: 'Situation', value: 'occupancies.attributes.residential-situation', width: '15%' },
                    { sortable: false, text: 'Full Address', value: 'addresses.attributes.full-address', width: '35%' },
                    { sortable: false, text: 'Start Date', value: 'occupancies.attributes.start-date', width: '15%' },
                    { sortable: false, text: 'End Date', value: 'occupancies.attributes.end-date', width: '15%' },
                    {
                        text: '',
                        align: 'end',
                        sortable: false,
                        value: 'ctrl',
                        width: '15%'
                    },
                ],
                /**
                 * resources: 
                 * filtered roles and their occupancies & addresses
                 */
                resources: {
                    people: [
                    ],
                    parts: [
                    ],
                    businesses: [
                    ],
                    addresses: [
                    ],
                    occupancies: [
                    ]
                },
                isDeleting: false,
                dataTables: {},
                errorMessages: {},
                addressHistoryRequirements: null

            }
        },
        computed: {
            ...mapGetters('flows', ['getCalculation']),
            ...mapState({
                appId: state => state.flows.appId,
                primaryApplicant: state => state.flows.primaryApplicant,
            }),
            ...mapGetters('kinds', ['kinds', 'kindsUnformat']),
            entities(){
                
                const {people, businesses} = this.resources

                let entities = this.sortEntities([
                    ...businesses,
                    ...people
                ])

                return entities
            },
             checkAddressExist() {
                if (this.entities.length > 0) {
                    if(this.entities[this.selectedEntity].type == 'people') {
                        if (this.entities[this.selectedEntity].relationships?.occupancies?.data.length > 0) {
                            return true
                        } else return false
                    } else if(this.entities[this.selectedEntity].type == 'businesses') {
                        if (this.entities[this.selectedEntity].relationships?.addresses?.data.length > 0) {
                            return true
                        } else return false
                    }

                } else {
                   return false
                }
            },
            tableHeaders(){

                let newHeaders = [
                    ...this.headers
                ]

                let heads = [
                    'Active',
                    'Situation',
                    'Full Address',
                    'Start Date',
                    'End Date',
                    '' // controls
                ]


                if(this.$vuetify.breakpoint.md){
                    heads = [
                        'Active',
                        'Situation',
                        'Full Address',
                        ''
                    ]
                }

                if(this.$vuetify.breakpoint.sm){
                    heads = [
                        'Active',
                        'Situation',
                        'Full Address',
                        ''
                    ]
                }

                if(this.$vuetify.breakpoint.xs){
                    heads = [
                        'Active',
                        'Full Address',
                        ''
                    ]
                }

                return newHeaders.filter( head => heads.includes(head.text))

            },
            getRequiredKind(){

                const ref = [
                        'Applicant',
                        'Director',
                        'Guarantor',
                        'Non-Borrowing Spouse',
                        // 'Beneficial Owner',
                        'Partner',
                        'Trustee']
                
                let kinds = []

                let parts = this.kinds.find( kind => {
                    if(kind.name == 'Part'){
                        return kind
                    }
                })

                parts.children.forEach( kind => {

                    if(ref.includes(kind.name)) {
                        kinds.push(kind)
                    }

                })

                return kinds

            },
            getExistingAddresses(){

                const {entities} = this
                const {occupancies, addresses} = this.resources

                let data = []

                if(entities.length == 0 || addresses.length == 0) {
                    return data
                }

                const entity = entities[this.selectedEntity]


                // Occupancy and Businesses Address IDs
                let entitiesAddressIds = []


                let relatedOccupancies = {}

                if(entity.type == 'people'){

                    occupancies.forEach( occupancy => {

                        let addressId = occupancy.relationships?.address?.data?.id

                        if(occupancy.relationships?.person?.data.id == entity.id){

                            if(addressId){
                                entitiesAddressIds.push(addressId)
                            } 

                        } else {

                            if(addressId){
                                relatedOccupancies[addressId] = occupancy
                            } 

                        }
                    })
                } 

                if(entity.type == 'businesses'){
                    if(entity.relationships?.addresses?.data?.length){
                        entity.relationships.addresses.data.forEach(address => {
                            entitiesAddressIds.push(address.id)
                        })
                    } 
                } 

                addresses.forEach( (address) => {

                    let included = false

                    // Occupancies...
                    if(address.relationships?.occupancies?.data?.length) {

                        if( !entitiesAddressIds.includes(address.id) ) {

                            let occupancy = relatedOccupancies[address.id]

                            data.push({address, occupancy})

                            included = true

                        }

                    }

                    // Businesses...
                    if(address.relationships?.businesses?.data?.length) {
    
                        if( !entitiesAddressIds.includes(address.id) && !included ) {

                            // Dummy Occupancy...
                            let occupancy = {
                                type: 'occupancies',
                                attributes: {
                                    "occupancy-situation": null,
                                    "residential-situation": null,
                                    "start-date": null,
                                    "end-date": null
                                },
                            }

                            data.push({address, occupancy})
                        }

                    }

                })

                return data
            },
            validComponentData(){
                
                if(this.entities.length){
                    return true;
                }
                
                return false;

            },
            getSlideErrors(){

                if(this.entities.length) {

                    let id = this.entities[this.selectedEntity].id

                    if( Object.keys(this.errorMessages).length > 0 ) {

                        if(this.errorMessages[id] && this.errorMessages[id].length) {
                            return this.errorMessages[id]
                        }

                    }

                }

                return false

            },
            slideColor(){

                if(this.entities.length) {

                    let id = this.entities[this.selectedEntity].id

                    if( Object.keys(this.errorMessages).length > 0 ) {

                        if(this.errorMessages[id] && this.errorMessages[id].length) {
                            return 'error'
                        }

                    }

                }

                return 'primary'
            },
        },

        mounted () {

        },

        methods: {
            ...mapActions('resource-actions', [
                'getPeople',
                'getBusinesses',
                'getAddresses',
                'updateBusiness',
                'deleteOccupancy'
            ]),
            async pull(){

                let promise1 = this.getPeople({"include" : 'occupancies,parent-parts'})
                
                // console.log('WARN: get all addresses here...')

                let promise2 = this.getAddresses({size: 100}) 
                let promise3 = this.getBusinesses({"include" : 'parent-parts'})

                try {

                    const res = await Promise.all([promise1, promise2, promise3]) 

                    this.resources.people = this.getRequiredPeople(res[0].data, res[2].data)
                    this.resources.parts = this.getRequiredParts([res[0].data, res[2].data])

                    this.resources.businesses = this.getRequiredBusinesses(res[2].data)

                    this.resources.occupancies = this.getRequiredOccupancies(res[0].data)
                    this.resources.addresses = this.getRequiredAddresses(res[1].data)

                    if(!this.validComponentData){
                        this.showComponentWarning()
                    }

                    // Build data tables
                    this.buildDataTables()

                    this.handleFieldChange()


                } catch (error) {

                    console.log('Ooops!..', error)

                }
            },
            async push(){

                this.validate()

                this.slideToError()

                let keys = Object.keys(this.errorMessages);
                let errors = []

                for (let i = 0; i < keys.length; i++) {

                    const key = keys[i];

                    if( this.errorMessages[key].length > 0 ) {
                        errors.push(i)
                        break;
                    }
                }

                if(errors.length > 0){
                    return {valid: false, status:'dirty', data:{}}
                }

                return {valid: true, status:'valid', data:{}}

            },

            customSort (items) {

                items.sort((a, b) => {
                    // console.log(a.occupancies.attributes['start-date'])
                    if (b.occupancies && a.occupancies) {
                        return new Date(b.occupancies.attributes['start-date']) - new Date(a.occupancies.attributes['start-date']);
                    }
                });

                return items;

            },

            getRequiredPeople(data, business = {}){

                if(!data.included){
                    return [];
                }

                let parts = data.included.filter(part => {
                    return part.type == 'parts'
                })

                let roles = []; 
                let rolesObj = {}

                parts.forEach(part => {
                    
                    if (!part.relationships.kind.data) {    
                        // person must have part with kind
                        return []
                    }

                    this.getRequiredKind.forEach(kind => {

                        if(kind.id == part.relationships.kind.data.id) {

                            roles.push(part.id);
                            rolesObj[part.id] = kind.name

                        }

                    })

                })

                if(roles.length == 0) {
                    return []
                } 

                let people = [] 

                data.data.forEach( person => {

                    let parentParts = person.relationships['parent-parts'].data

                    person.roles = []

                    if( parentParts.length ){

                        let included = false;

                        parentParts.forEach( part => {

                            // combine required parts
                            let parts = [...data.included]

                            if( business.included ){
                                parts =  [...business.included, ...data.included]
                            }
                            
                            // check parent parts is not empty until to the primary applicant
                            let parentPartsFiltered = this.filterParent(part.id, business, parts)

                            if(roles.includes(part.id) ) {

                                // Add extra data to person
                                person.roles.push(rolesObj[part.id])

                                // Include person once
                                if(!included && parentPartsFiltered){
                                    people.push(person)
                                    included = true
                                }

                            }

                        })

                    }

                })

                return people 

            },
            getRequiredParts(data = []){

                let parts = []

                data.forEach(item => {

                    if(item.included){

                        let entityParts = item.included.filter(part => {
                            return part.type == 'parts'
                        })

                        parts = [
                            ...parts,
                            ...entityParts
                        ]

                    }

                })

                return parts

            },
            getRequiredBusinesses(data){

                if(!data.included){
                    return [];
                }

                let parts = data.included.filter(part => {
                    return part.type == 'parts'
                })

                let roles = []; 
                let rolesObj = {}

                parts.forEach(part => {
                    
                    if (!part.relationships.kind.data) {    
                        // person must have part with kind
                        return []
                    }

                    this.getRequiredKind.forEach(kind => {

                        if(kind.id == part.relationships.kind.data.id) {

                            roles.push(part.id);
                            rolesObj[part.id] = kind.name

                        }

                    })

                })

                if(roles.length == 0) {
                    return []
                } 

                let businesses = [] 

                data.data.forEach( business => {

                    let parentParts = business.relationships['parent-parts'].data

                    business.roles = []

                    if( parentParts.length ){

                        let included = false;

                        parentParts.forEach( part => {

                            // check parent parts is not empty until to the primary applicant
                            let parentPartsFiltered = this.filterParent(part.id, data, data.included)

                            if(roles.includes(part.id) ) {

                                // Add extra data to business
                                business.roles.push(rolesObj[part.id])

                                // Include business once
                                if(!included && parentPartsFiltered){
                                    businesses.push(business)
                                    included = true
                                }

                            }

                        })

                    }

                })

                // console.log(businesses)
                return businesses 

            },
            getRequiredOccupancies(data){

                if(!data.included){
                    return [];
                }

                if(this.resources.people.length == 0){
                    return [];
                }

                let pids = this.resources.people.map(person => {
                    return person.id
                })

                return data.included.filter(occupancy => {

                    if(occupancy.type == 'occupancies') {

                            return pids.includes(occupancy.relationships.person.data.id)

                    }

                    return false

                })

            },
            getRequiredAddresses(data){

                if(data.data.length == 0) {
                    return [];
                }

                let entities = [
                    ...this.resources.occupancies,
                    ...this.resources.businesses
                ]

                const oids = entities.map(entity => {
                    return entity.id
                })

                return data.data.filter(address => {

                    let match = []

                    if(address.relationships.occupancies.data.length) {

                        address.relationships.occupancies.data.forEach( occupancy => {

                            if(oids.includes(occupancy.id)) {
                                match.push(occupancy.id)
                            }
                        })

                        if(match.length > 0) {
                            return true
                        }

                    }

                    if(address.relationships.businesses.data.length) {

                        address.relationships.businesses.data.forEach( business => {

                            if(oids.includes(business.id)) {
                                match.push(business.id)
                            }
                        })

                        if(match.length > 0) {
                            return true
                        }

                    }

                    return false

                })

            },
            async handleEditorUpdate(data){

                //  Check for valid data
                data = data.filter(item => item.hasOwnProperty('data')) 

                data.forEach( item => {

                    this.resources[item.data.type].forEach((resource, index) => {

                        if(resource.id == item.data.id) {

                            this.resources[item.data.type][index] = item.data

                        }

                    })

                })

                await this.pull()
                this.buildDataTables()
                this.validate()

            },
            async handleEditorCreate(data){

                //  Check for valid data
                data = data.filter(item => item.hasOwnProperty('data')) 

                data.forEach( item => {

                    if(item.data.type == 'businesses'){

                        let newBusinesses = this.resources['businesses'].map( item2 => {
                            if(item2.id == item.data.id){
                                return item.data
                            }
                            return item2
                        })

                        this.resources['businesses'] = newBusinesses

                    } else {

                        this.resources[item.data.type].push(item.data)
                    }

                })

                await this.pull()
                this.buildDataTables()
                this.validate()

            },
            async handleSelector(data){

                // console.log(data)

                const {addresses} = this.resources

                data = data.filter(item => item.hasOwnProperty('data')) 

                data.forEach( item => {

                    if(item.data.type == 'occupancies') {

                        this.resources['occupancies'].push(item.data)
                    } 
                    
                    if(item.data.type == 'addresses') {
                        let newAddress = this.resources['addresses'].map(address => {
                                if(address.id == item.data.id) {
                                    return item.data
                                }
                                return address
                        })
                        this.resources['addresses'] = newAddress
                    }
                    
                    if(item.data.type == 'businesses') {
                        let newBusinesses = this.resources['businesses'].map(business => {
                                if(business.id == item.data.id) {
                                    return item.data
                                }
                                return business
                        })
                        this.resources['businesses'] = newBusinesses
                    }

                })

                await this.pull()
                this.buildDataTables()
                this.validate()

            },
            buildDataTables(){
                const {entities} = this
                const {occupancies, addresses} = this.resources

                if(entities.length == 0){
                    return
                }

                let tableObj = {}

                entities.forEach(entity => {

                    tableObj[entity.id] = []

                    if(entity.type == 'people'){
                        occupancies.forEach( occupancy => {

                            if(occupancy.relationships.person.data.id == entity.id) {

                                let address = addresses.find(address => {
                                    
                                    if(occupancy.relationships.address.data){
                                        return address.id == occupancy.relationships.address.data.id
                                    }

                                    return false

                                })

                                let obj = {
                                    "entity": {
                                        type: entity.type,
                                        id: entity.id,
                                        attributes: {}
                                    },
                                    "occupancies": {
                                        type: "occupancies",
                                        id: occupancy.id,
                                        attributes: {
                                            ...occupancy.attributes
                                        }
                                    }
                                }

                                if(address) {
                                    obj.addresses = {
                                        type: "addresses",
                                        id: address.id,
                                        attributes: {
                                            ...address?.attributes,
                                            "full-address": this.getFullAddress(address)
                                        }
                                    }
                                }

                                tableObj[entity.id].push(obj)

                            }

                        })
                    }

                    if(entity.type == 'businesses'){

                        entity.relationships.addresses.data.forEach(data => {

                            let obj = {
                                "entity": {
                                    type: entity.type,
                                    id: entity.id,
                                    attributes: {}
                                },
                                // Dummy occupancy, Coz businesses don't have occupancies
                                occupancies: {
                                    type: "occupancies",
                                    attributes: {
                                        'end-date': null,
                                        'occupancy-situation': null,
                                        'residential-situation': null,
                                        'start-date': null,
                                    }
                                }
                            }

                            let address = addresses.find(address => address.id == data.id)

                            if(address) {

                                obj.addresses = {
                                    type: "addresses",
                                    id: address.id,
                                    attributes: {
                                        ...address?.attributes,
                                        "full-address": this.getFullAddress(address)
                                    }
                                }

                                tableObj[entity.id].push(obj)
                            }


                        })

                    }


                })

                // console.log(tableObj)

                this.dataTables = tableObj

            },
            openAddressEditorModal(data){
                
                // Adding Date.now() so the watcher will always trigger. 
                this.AddressEditorData = { always: Date.now(), data}

                this.toggleAddressEditorModal = true

                this.toggleTouched(true)

            },

            /**
             * Delete Address Reference
             */

            async handleConfirm(data){

                if(data.entity.type == "businesses"){

                    this.deleteBusinessAddress(data.entity, data.addresses)

                } else {

                    this.confirmDeleteAddressReference(data.occupancies.id)
                }

            },
            async deleteBusinessAddress(entity, address) {

                try {

                    this.isDeleting = true

                    let payload = {
                        ...entity
                    }

                    payload.relationships = {
                            "addresses": {
                                data: [
                                    {
                                        id: address.id,
                                        type: 'addresses'
                                    }
                                ],
                                meta: {
                                    strategy: 'detach'
                                }
                            }
                        }

                    let businessReq = await this.updateBusiness(payload)
                    let data = businessReq.data.data

                    let newBusinesses = this.resources['businesses'].map( item => {
                        if(item.id == data.id){
                            return data
                        }
                        return item
                    })

                    this.resources['businesses'] = newBusinesses

                    await this.pull()
                    this.buildDataTables()
                    this.validate()

                } catch (error) {
                    console.log('Ooops!..', error)
                }

                this.isDeleting = false

            },
            async confirmDeleteAddressReference(id) {
                
                this.isDeleting = true

                let flagged = ''
                let filteredTable = ''
                let response = await this.deleteOccupancy(id)
                
                if (response.status === 204) {
                    
                    Object.keys(this.dataTables).forEach( table => {
                        Object.keys(this.dataTables[table]).forEach( occupancy => {
                            if ( id === this.dataTables[table][occupancy].occupancies.id ) {
                                filteredTable = table
                                flagged = occupancy
                            }                                
                        })
                    })

                    Object.keys(this.resources.occupancies).forEach( occ => {
                        if ( this.resources.occupancies[occ]?.id === id ) {
                            this.resources.occupancies.splice( occ, 1 )
                        }
                    })

                    this.dataTables[filteredTable].splice(flagged, 1)              

                }

                await this.pull()

                this.isDeleting = false

            },
            closeAddressEditorModal(){
                this.toggleAddressEditorModal = false
            },
            closeAddressCopyModal(){
                this.toggleAddressCopyModal = !this.toggleAddressCopyModal
            },
            getRoles(entity){

                let roles = this.getEntityRoles(entity, this.resources.parts)

                return roles.length > 1 ? `${roles[0]} +${roles.length - 1}`: roles.length == 1 ? roles[0] : ''

            },

            // Start Occupancy Dates Validation
            async validate(options = {silent:false}){

                const {entities, isValid} = this
                const {occupancies} = this.resources

                // Build requirment data for validation
                let requirements = {}

                // Reset error messages
                let errorObj = {}

                let __exclude = [
                    'Beneficial Owner'
                ]

                entities.forEach( entity => {

                    let roles = this.getEntityRoles(entity, this.resources.parts)

                    let skip = false

                    __exclude.forEach( role => {
                        if( roles.length == 1 &&  roles.includes(role)){
                            skip = true
                        }
                    })


                    // default all to 1 (pass validation), then set any who fail validation to 0

                    if(!skip){
                        
                        if(entity.type == 'people'){

                            requirements[entity.id] = {
                                type: entity.type,
                                entity: entity,
                                has_three_year_history: 1, 
                                has_no_history_gaps: 1, 
                                dates_are_complete: 1, 
                                no_dates_overlap: 1, 
                                dates_are_chronological: 1, 
                                has_exactly_one_current_address: 1, 
                                current_is_most_recent: 1, 
                            }


                        } else {

                            let addresses = entity.relationships?.addresses?.data

                            requirements[entity.id] = {
                                has_address: (addresses && addresses.length) ? true : false,
                                type: entity.type,
                                entity: entity,
                            }
                        }


                    }

                })

                Object.keys(requirements).forEach( id => {
                    
                    const filteredOccupancies = this.getOccupanciesByPersonId(occupancies, id)

                    let dates = []

                    errorObj[id] = []

                    filteredOccupancies.forEach(occupancy => {

                        dates.push({
                            "primary": occupancy.attributes['occupancy-situation'],
                            "start-date": this.parseDate(occupancy.attributes['start-date']),
                            "end-date": this.parseDate(occupancy.attributes['end-date']),
                        })

                    })

                    // Sort date by start-date old to new
                    dates.sort((a,b) => {
                        return a['start-date'] - b['start-date'];
                    });
                    
                    let totalDays = 0;
                    let prevObject;

                    let isBusiness = requirements[id]['type'] == 'businesses'

                    let roles = this.getEntityRoles(requirements[id]['entity'], this.resources.parts)
                    let role = this.getRoles(requirements[id]['entity'])

                    if(isBusiness){

                        if ( !requirements[id].has_address ) {
                            
                            //fail	
                            errorObj[id].push(`${role} must have 1 current address.`);

                        }

                    }

                    let currentAddressOnly = false
                    let currentMostRecentAnd3YearsOrMoreOnly = false

                    let __currentAddressOnly = [
                        'Non-Borrowing Spouse',
                        'Guarantor'
                    ]
                    let __currentMostRecentAnd3YearsOrMoreOnly = [
                        'Applicant'
                    ]

                    roles.forEach( role => {

                        if(__currentAddressOnly.includes(role)){
                            currentAddressOnly = true
                        }

                        if(__currentMostRecentAnd3YearsOrMoreOnly.includes(role)){
                            currentMostRecentAnd3YearsOrMoreOnly = true
                        }

                    })

                    if( isValid(requirements[id]) ){

                        /**
                         *  1. Applicant must have 1 (and only 1) current address.
                         */

                        if (!this.hasOnlyOneCurrent(dates) && !isBusiness) {
                            
                            //fail	

                            if(!currentMostRecentAnd3YearsOrMoreOnly){
                            
                                requirements[id].has_exactly_one_current_address = 0; 
                                errorObj[id].push(`${role} must have 1 (and only 1) current address.`);

                            }

                        }
                        
                        // Stop process if current address only
                        if(currentAddressOnly) return;
                

                        /**
                         * 2. Current address must be the most recent.
                         */

                        if (!this.currentIsMostRecent(dates)) {
                            
                            //fail

                            if(!isBusiness){
                                
                                requirements[id].current_is_most_recent = 0; 
                                errorObj[id].push("Current address must be the most recent.");
                            }
                        }
                    

                        /**
                         * 3. Further more validation
                         */

                        for(let i=0; i < dates.length; i++) {
                                
                            let date = dates[i];
                            
                            // check if primary address
                                    
                            if (date['primary'] == 'Current') {
                                
                                //is current (& most recent) address (no input for['end-date']), we auto-set this to today (needed for days at address calculation)
                                
                                date['end-date'] = new Date();
                
                            } else {
                                
                                // is not the current (primary) address, needs both dates
                                
                                if (date['end-date'] == null && !isBusiness) {
                                    
                                    // fail validation
                                    requirements[id].dates_are_complete = 0; 

                                    errorObj[id].push("Please complete your <strong>End Dates</strong> and <strong>Start Dates</strong>.");

                                    break;	
                                }
                                
                            }
                            
                            // check to see that this address starts the day of (or day after) the last dates end date (can't allow gaps between dates)
                            
                            let overlappingDays = 0;
                            
                            if (prevObject) {
                                
                                let days = this.getDays(prevObject['end-date'], date['start-date']);
                                
                                // console.log('compare days: '+days);
                                
                                if (days == null) {
                                    
                                    
                                    // fail validation
                                    requirements[id].dates_are_complete = 0; 

                                    errorObj[id].push("Please complete your <strong>End Dates</strong> and <strong>Start Dates</strong>.");

                                    break;	
                                    
                                } else if (days > 1) {
                                    
                                    // fail validation
                                    requirements[id].has_no_history_gaps = 0; 

                                    errorObj[id].push("There are gaps in your address history. Please check your <strong>End Dates</strong> and <strong>Start Dates</strong>.");

                                    break;	
                                    
                                } else if (days < 0) {
                                    
                                    // fail validation
                                    requirements[id].no_dates_overlap = 0; 

                                    errorObj[id].push("Dates between addresses can't overlap. Please check your <strong>Move-in Dates</strong> and <strong>Move-out Dates</strong>.");


                                    break;		
                                    
                                } else if (days == 0) {
                                    
                                    // previous object end date is same as this object start date - don't count the day twice when getting total days
                                    
                                    overlappingDays = 1;
                                    
                                    /**
                                     * NOTE: Allowed to overlap 1 day
                                     * 
                                     * uncomment the code below if overlap must be 0
                                     *  
                                     */

                                    // fail validation
                                    // requirements[id].no_dates_overlap = 0; 

                                    // errorObj[id] = "Dates between dates can't overlap. Please check your <strong>Move-in Dates</strong> and <strong>Move-out Dates</strong>";

                                    // break;

                                }
                                
                            }

                            
                            // get days
                            
                            let days = this.getDays(date['start-date'], date['end-date']);
                            
                            // per-address validation
                            
                            if (days != null) {
                            
                                if (days < 0) {
                                    
                                    // fail validation
                                    requirements[id].dates_are_chronological = 0; 
        
                                    errorObj[id].push("Dates must be <strong>Chronological</strong>.");

                                    break;	
                                    
                                } else {
                                    
                                    days -= overlappingDays; // remove any overlapping days before adding to total
                                    
                                    totalDays += days;
                                    
                                }
                                
                            }
                            
                            //set this as previous object for next iteration
                            
                            prevObject = dates[i];
                            
                            // console.log(date.primary+' : '+date['start-date']+' - '+date['end-date']+'. days : '+days);
                        }
                    

                        /**
                         * 4. Count 3 year history validation
                         * 
                         */
                    
                        if (totalDays < (365*3) && !isBusiness) {
                                
                            // fail validation
                            requirements[id].has_three_year_history = 0; 

                            errorObj[id].push("Applicant must have <strong>3 Years or More</strong> address history.");

                                
                        }
                    }

                })

                // Don't show error messages if silent
                if(!options.silent){
                    this.errorMessages = errorObj
                }

                this.addressHistoryRequirements = requirements
            },
            slideToError(){
                
                const {errorMessages, entities, selectedEntity} = this

                if( Object.keys(errorMessages).length > 0 ) {

                    let errors = []
                    
                    Object.keys(errorMessages).forEach( id => {
                        if(errorMessages[id].length > 0){
                            errors.push(id)
                        }
                    })

                    let currentEntity = entities[selectedEntity]

                    // Dont slide if current has error
                    if( errorMessages[currentEntity.id] && errorMessages[currentEntity.id].length ) {
                        return;
                    }

                    entities.forEach((entity, index) => {

                        if(entity.id == errors[0]) {
                            this.selectedEntity = index
                            return
                        }
                    })

                }

                return
            },
            isValid(data) {
                for (let key in data) {
                    if (data.hasOwnProperty(key)) {
                        if (data[key] == 0) {
                            return false;
                            break;
                        }
                    }
                }
                return true;
            },
            parseDate(str) {
                if (str) {
                    return new Date(str);
                } else {
                    return null;
                }
            },
            daydiff(first, second) {
                return Math.round((second-first)/(1000*60*60*24));
            },
            getDays(first, second) {
                if ((first != null) && (second != null)) {
                    return this.daydiff(first, second)
                } else {
                    return null;
                }
            },
            hasOnlyOneCurrent(dates) {
                
                let valid = false;
                let hasPrimaryAddress = false;

                for(let i=0;i<dates.length;i++) {
                
                    if (dates[i].primary == 'Current') {
                        
                        // found a primary address
                            
                        if (!hasPrimaryAddress) {
                            
                            hasPrimaryAddress = true;
                            
                            valid = true;
                            
                        } else {
                            
                            //more than 1 address is set to primary
                            
                            valid = false;
                            break;
                        }
                    }
                }
                
                return valid;

            },
            currentIsMostRecent(dates) {

                if (dates[dates.length-1] && dates[dates.length-1].primary == 'Current') {
                    return true;
                } else {
                    return false;
                }
                
            },
            // End Occupancy Dates Validation
            // filter role data exclude active
            filteredRoleData() {
                let filterRoleData = {}

                if (this.entities) {
                    // filter data by roles (busines to business and people to people)
                    filterRoleData = this.entities.filter((el, index) => {
                        if (index != this.selectedEntity) {
                            if (this.entities[this.selectedEntity]?.type == 'people') {
                                if (el.relationships.occupancies?.data.length) return el
                            } else if (this.entities[this.selectedEntity]?.type == 'businesses') {
                                if (el.relationships.addresses?.data.length) return el
                            }
                        }
                    })

                    filterRoleData = filterRoleData.map((el) => {
                        if (el.type == 'people') {
                            return {
                                'name': this.getEntityFriendlyName(el),
                                'type': el.type,
                                'id': el.id
                            }
                        }
                        if (el.type == 'businesses') {
                            return {
                                'name': this.getEntityFriendlyName(el),
                                'type': el.type,
                                'id': el.id
                            }
                        }
                    })
                }
                this.filteredRole = filterRoleData
            },

            filterParent(partId = '', businesses = {}, parts = []) {

                let parent = {}
                let kind = {}

                // filter parent
                if (parts.length > 0) {
                    parts.forEach(part => {
                        // get parent
                        if (partId == part.id && part.relationships?.parent?.data) {
                            parent = part.relationships.parent.data
                        }
                        // get kind
                        if (partId == part.id && part.relationships?.kind?.data) {
                            kind = part.relationships.kind.data
                        }
                    })

                }


                // check if parent has parent parts
                // if has parent parts recursive until to the primary applicant
                if(Object.keys(parent).length > 0) {

                    let hasParentParts = false
                    let part_id = ''

                    businesses.data.forEach(business => {

                        if (business.id == parent.id && business.relationships['parent-parts'].data.length > 0) {
                            hasParentParts = true
                            part_id = business.relationships['parent-parts'].data[0].id
                        }

                    })

                    // if has parent parts and primary applicant id is not equal to parent
                    if (hasParentParts && this.primaryApplicant?.type != 'loans')
                        return this.filterParent(part_id, businesses, parts)

                }
                
                // return if parent is the primary applicant or kind id = 3-6 / non-borrowing spouse
                if (parent.type == 'loans' || kind.id == '3-6') {
                    return true
                } else 
                    return false
                
            },
            ...mapMutations('requirements-checklist', [
                'setRequirementChecklistTemplateLess',
            ]),
            handleFieldChange(){

                this.validate({silent:true})

                // console.log(this.addressHistoryRequirements)

                this?.entities.forEach( entity => {

                    const resources = this.dataTables?.[entity.id]

                    this.updateRequirementChecklistAddressHistorySidebar({
                        entity,
                        resources
                    })
                })

            },
            updateRequirementChecklistAddressHistorySidebar({entity, resources = []}){
                this.$nextTick( async () => {

                    // console.log(component)
                    // console.log(entity)
                    // console.log(resources)

                    if(entity) {

                        let entityObj = {
                            id: entity.id,
                            type: entity.type,
                            attributes: {...entity.attributes}
                        }

                        let obj = {
                            "id": entity.id,
                            "entity": entityObj,
                            "sections": []
                        }

                        /**
                         * Add record validation in obj.sections
                         */

                         if(entity?.type == "people") {

                            let entityRequirements = this.addressHistoryRequirements?.[entity.id]
                            
                            if(entityRequirements) {

                                let required = {}

                                let currentAddressOnly = false
                 
                                entity?.roles.forEach( role => {
                                    if(['Non-Borrowing Spouse', 'Guarantor'].includes(role)){
                                        currentAddressOnly = true
                                    }
                                })

                                if(currentAddressOnly){
                            
                                    required = {
                                        "exact-one-current-address": {
                                            value: entityRequirements.has_exactly_one_current_address,
                                            id: "exact-one-current-address",
                                            name: "Current Address Dates",
                                            valid: entityRequirements.has_exactly_one_current_address == 1,
                                            required: true,
                                        }
                                    }

                                } else {

                                    required = {
                                        "three-year-history": {
                                            value: entityRequirements.has_three_year_history,
                                            id: "three-year-history",
                                            name: "3-year Address History",
                                            valid: entityRequirements.has_three_year_history == 1,
                                            required: true,
                                        },
                                        "current-is-most-recent": {
                                            value: entityRequirements.current_is_most_recent,
                                            id: "current-is-most-recent",
                                            name: "Current Address Dates",
                                            valid: entityRequirements.current_is_most_recent == 1,
                                            required: true,
                                        }
                                    }
                                    
                                    if(resources.length > 1) {
                                        required["no-dates-overlap"] = {
                                            value: entityRequirements.no_dates_overlap,
                                            id: "no-dates-overlap",
                                            name: "Overlapping Address Dates",
                                            valid: entityRequirements.no_dates_overlap == 1,
                                            required: true,
                                        }
                                    }

                                    if(resources.length > 1) {
                                        required["has-no-history-gaps"] = {
                                            value: entityRequirements.has_no_history_gaps,
                                            id: "has-no-history-gaps",
                                            name: "Continuous Address History",
                                            valid: entityRequirements.has_no_history_gaps == 1,
                                            required: true,
                                        }
                                    }
                                }

                                obj.sections.push({
                                    title: "Address Information",
                                    requirements: required
                                })
                            }
                         }

                        /**
                         * Add individual address as section in obj.sections
                         */                        
                        resources.forEach( record => {

                            const fieldLabels = {
                                "full-address": "Full Address",
                                "residential-situation": "Situation",
                                "start-date": "Start Date",
                                "end-date": "End Date",
                            }

                            const fieldValues = {
                                "full-address": record.addresses?.attributes["full-address"],
                                "residential-situation": record.occupancies?.attributes["residential-situation"],
                                "start-date": record.occupancies?.attributes["start-date"],
                            }

                            let sectionTemplate = {
                                title: "Previous Address",
                                subtitle: record.addresses?.attributes["full-address"],
                                requirements: {}
                            }

                            if(record.occupancies.attributes["occupancy-situation"] == "Current") {
                                sectionTemplate.title = "Current Address"
                            } else {
                                fieldValues["end-date"] = record.occupancies.attributes["end-date"]
                            }

                            Object.keys(fieldValues).forEach(field => {
                                sectionTemplate.requirements[field] = {
                                    value: fieldValues[field],
                                    id: field,
                                    name: fieldLabels[field],
                                    valid: fieldValues[field] != null,
                                    touched: true, // touched is always true if there is address record.
                                    required: true,
                                }
                            })
                            
                            obj.sections.push(sectionTemplate)

                        });


                        this.setRequirementChecklistTemplateLess(obj)
                    
                    }

                })
            }, 
            sortEntities(entities = []){

                let primaryId = this.getCalculation?.["primary-applicant-id"]

                let arrangedEntities = entities.reduce((acc, current) => {
                    if(current.id == primaryId) {
                        acc.unshift(current)
                    } else {
                        acc.push(current)
                    }
                    return acc
                }, [])

                return arrangedEntities
            },
        },
        watch: {
            validComponentData(val){
                if(!val) {
                    this.showComponentWarning()
                }
            },
            selectedEntity(val) {
                this.filteredRoleData()
            },
        },
    }
</script>

<style lang="scss" scoped>

    .controls {
        flex-direction: row-reverse;
            .v-btn {
                margin: 0 5px;
                padding: 0 30px;
            }
    }

    @media (max-width: 960px) {
        .controls {
            .v-btn {
                margin: 0 5px 10px;
                padding: 0 12px;

            }
        }
    }
</style>