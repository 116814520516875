<template>
    <FlexWrap @resize="handleResize">
        <v-card :disabled="isDisabled || !validComponentData" class="mx-auto" tile flat>
            <v-card-text class="">

                <div>
                    <!-- <div :class="vb.mdAndUp ? 'px-4 pb-2 title font-size-22' : 'title font-size-22 px-4 pb-2  text-center'">
                        References
                    </div> -->
                    <div :class="vb.mdAndUp ? 'body-2' : 'body-2 text-center'" class="px-4">Please list 2 personal references. They can be a friend or family member. They must not live with you, or with each other</div>
                </div>


                <v-container pa-0 fluid>
                    <v-row>

                        <v-col cols="12">

                                <v-data-table
                                    :headers="tableHeaders"
                                    :items="collectedData"
                                    :items-per-page="25"
                                    hide-default-footer
                                    :mobile-breakpoint="0"
                                    class="v-data-table-2 mx-auto px-4 mt-5 table-card"
                                    no-data-text="No Records"
                                    outlined
                                >

                                <template v-slot:body="{ items }">
                                    <tbody v-if="collectedData.length > 0">
                                        <tr
                                            v-for="item in items"
                                            :key="item.id"
                                            class="table-card-tr pa-6 ma-2"
                                        >
                                            <td class="pl-5 black--text body-1 font-weight-medium">{{ item.attributes.name }}</td>
                                            <td v-if="!vb.xsOnly" class="black--text py-2 body-2">{{ getfullAddress(item) }}</td>
                                            <td v-if="!vb.xsOnly" class="black--text body-2">{{ item.attributes.phone }}</td>
                                            <td v-if="!vb.xsOnly">
                                                <v-chip color="lighten-5 black--text pa-3" :style="`background-color: ${hexToRGBA(getActiveTheme.colors.secondary, 0.1)}`">
                                                    {{ item.attributes.relationship ? item.attributes.relationship : getRoleKind(item) }}
                                                </v-chip>
                                            </td>
                                            <td class="actions">
                                                <div class="d-flex justify-end pr-2">
                                                    <v-btn 
                                                        fab 
                                                        small
                                                        depressed 
                                                        text 
                                                        color="light_gray_2"
                                                        class="mx-1"
                                                        :style="`background-color: ${hexToRGBA(getActiveTheme.colors.primary, 0.1)}`"
                                                        @click="openEditForm(item)">
                                                        <v-icon size="18">mdi-pencil</v-icon>
                                                    </v-btn>

                                                    <ConfirmModal 
                                                        :title="`Delete Reference`"
                                                        confirm-text="Delete"
                                                        :message="`Remove ${item.attributes.name} as reference?`"
                                                        @confirm="removeReference(filteredReferenceAddress(item), item.id)">
                                                            <template v-slot:activator="{on}">
                                                                <v-btn :style="`background-color: ${hexToRGBA(getActiveTheme.colors.primary, 0.1)}`" small text depressed fab color="light_gray_2" @click.stop="on">
                                                                    <v-icon size="18">mdi-delete</v-icon>
                                                                </v-btn>
                                                            </template>
                                                    </ConfirmModal>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>

                                    <tbody v-else>
                                        <tr>
                                            <td colspan="5">
                                                <div class="text-center body_text_color--text font-weight-medium py-4 text-caption no-records">
                                                    No Records
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </template>

                                </v-data-table>

                                <div class="pa-2 mt-n2 white"></div>

                        </v-col>
                    </v-row>
                </v-container>
            </v-card-text>
            <v-card-actions class="controls px-4 px-md-10 pt-0 pt-md-0 pb-4 pb-md-8">
                <v-container pa-0 fluid>
                    <v-row>
                        <v-col class="pt-0 d-flex justify-end">
                            <v-btn
                                color="secondary"
                                large
                                depressed
                                @click="dialog = true">
                                Add New Reference
                            </v-btn>

                            <SelectReference 
                                :show-modal="dialog"
                                :app-id="appId"
                                :is-edit="isEdit"
                                :action="action"
                                @close-modal="closeFormModal"
                                @create-record="initializeCreatedData"
                                @update-record="initializeSavedData"
                                :address-data="editItem ? filteredReferenceAddress(editItem) : {}"
                                :reference-data="editItem ? filteredReferenceData(editItem) : {}"
                                :support-data="{ reference: resources, address: addresses }" />

                        </v-col>
                    </v-row>
                </v-container>
            </v-card-actions>
        </v-card>
    </FlexWrap>    
</template>

<script>

    import subscriptions from '~/components/shared/subscriptions'
    import { mapActions, mapGetters } from 'vuex'
    import SelectReference from '~/components/v1/ReferenceEditor2/_AddReference'
    import ConfirmModal from '~/components/base/ConfirmModal2'
    import FlexWrap from '~/components/base/FlexWrap'

    export default {
        name: 'ReferenceEditor2',

        mixins: [ subscriptions ],

        components: { 
            SelectReference,
            FlexWrap,
            ConfirmModal
        },

        computed: {
            ...mapGetters('kinds', ['kinds', 'kindsUnformat']),

            vb() {
                return this.$vuetify.breakpoint
            },

            ...mapGetters('themes', [
                'getActiveTheme'
            ]),

            fullname() {
                return this.person.attributes ? `${this.person?.attributes['first-name']} ${this.person?.attributes['last-name']}` : ''
            },

            collectedData() {
                let flagged = true

                Object.keys(this.resources).forEach( res => {
                    if (this.resources[res].id !== undefined && !this.resources[res].id?.length )
                        flagged = false
                })

                if ( !flagged ) this.setCurrentRelationship()
                
                return flagged ? this.resources : []
            },
            tableHeaders(){

                if(!this.sizes) return

                const {sm, xs} = this.sizes

                let newHeaders = [
                    ...this.headers
                ]

                let heads = [
                    {value: 'reference-name', width: '20%'},
                    {value: 'relationship', width: '20%'},
                    {value: 'address', width: '30%'},
                    {value: 'phone', width: '15%'},
                    {value: 'actions', width: '10%'}
                ]

                if(sm){
                    heads = [
                        {value: 'reference-name', width: '20%'},
                        {value: 'relationship', width: '20%'},
                        {value: 'phone', width: '20%'},
                        {value: 'actions', width: '10%'}
                    ]
                }

                if(xs){
                    heads = [
                        {value: 'reference-name', width: '20%'},
                        {value: 'actions', width: '10%'}
                    ]
                }
                
                return newHeaders.reduce((accumulator, current) => {

                    heads.forEach(head => {

                        if(head.value == current.value){

                            let newCurrent = {...current}

                            newCurrent.width = head.width

                            accumulator.push(newCurrent)
                        }

                    })

                    return accumulator 

                }, [])

            },
            
        },

        created() {
            // Running these before everything else after component is already created
        },

        watch: {
            "validComponentData": {
                handler(val) {
                    if ( !this.validComponentData ) {

                        this.showComponentWarning()

                    }
                },
                deep: true
            }
        },

        methods: {

            ...mapActions('resource-actions', [
                'getReferencesData',
                'getPeopleByResource',
                'getReferencePerson',
                'deleteReference',
                'deleteAddress'
            ]),

            /**
             * Calling actions: getPeopleByResource 
             * from store which trigger an axios call,
             * pulling all data from an api response
             */
            async pull() {
                
                let response = await this.getPeopleByResource(
                    {
                        resource: 'references',
                        include: 'address'
                    }
                )
                
                if (response.data.data.length) {
                    this.resources = response.data.data
                    this.addresses = response.data.included
                    this.removeAddressReferences()
                }

                this.resourceObj = this.resources
                
            },

            async push() {

                return {valid:true, status:'valid', data:{}}

            },

            hexToRGBA(hex, a = 1){
                return this.$options.filters.hexToRGBA(hex, a)
            },

            async removeReference(addressObj, refId) {
                
                let index = null
                
                if ( refId ) {
                    let resReference = this.deleteReference(refId)
                    let resAddress = this.deleteAddress(addressObj.id)

                    try {
                        await Promise.all([resReference, resAddress]).then( res => {
                            //this.$store.commit('utils/toggleQuickMessage',  {text: 'Selected reference successfully removed!', color: 'success'})
                        })
                        this.resources.forEach( (res, j) => {
                            if ( res.id === refId ) index = j
                        })
                        
                        this.resources.splice(index, 1)

                        await this.pull()

                    } catch ( error ) {
                        //this.$store.commit('utils/toggleQuickMessage',  {text: 'Selected reference successfully removed!', color: 'success'})
                    }
                }
            },

            removeAddressReferences() {
                this.addresses.forEach( index => {
                    delete index.relationships.references
                })
            },

            async initializeSavedData(params) {
                this.resources.forEach( (res, key) => {
                    params.forEach( (response_payload, pkey) => {
                        if ( params[pkey].data.data.type === 'references' ) {
                            if ( params[pkey].data.data.id === this.resources[key].id)
                                this.resources[key] = params[pkey].data.data
                        }
                    })
                })

                this.addresses.forEach( (res, key) => {
                    params.forEach( (response_payload, pkey) => {
                        if ( params[pkey].data.data.type === 'addresses' ) {
                            if ( params[pkey].data.data.id === this.addresses[key].id)
                                this.addresses[key] = params[pkey].data.data
                        }
                    })
                })
                
                this.resources = Object.assign([], this.resources)
                this.addresses = Object.assign([], this.addresses)

                await this.pull()
                
            },

            async initializeCreatedData(params) {

                this.resources = this.resources.filter( res => {
                    return res.id !== null
                })
                this.addresses = this.addresses.filter( res => {
                    return res.id !== null
                })
                
                params.forEach( payload => {
                    if ( payload.data.data.type === 'addresses' ) {
                        this.addresses.push(payload.data.data)
                    }
                    
                    if ( payload.data.data.type === 'references' ) {
                        this.resources.push(payload.data.data)
                    }
                })

                await this.pull()

            },

            filteredReferenceAddress(data) {
                let filterData = {}

                this.addresses.forEach( res => {
                    if ( data.relationships.address.data.id === res.id ) {

                        filterData = {...res}

                        if(filterData.relationships?.validations){
                            delete filterData.relationships.validations
                        }

                    }
                        
                })

                return filterData
            },
            filteredReferenceData(data) {

                if(data && data.hasOwnProperty('relationships')){

                    let filterData = {...data}

                    if(filterData.relationships?.validations){
                        delete filterData.relationships.validations
                    }

                    return filterData

                }

                return data
            },

            openEditForm(item) {
                this.editItem = item
                this.action = 'edit'
                this.isEdit = true
                this.dialog = true
            },

            closeFormModal() {
                this.dialog = false
                this.action = 'add'
                this.isEdit = false
                this.editItem = null
            },

            setCurrentRelationship() {
                this.resources[0].relationships.application.data.id = this.appId
                this.addresses[0].relationships.application.data.id = this.appId
            },

            getRoleKind(role){

                let roleKind = null
                let roleKindName = ''

                if( role.hasOwnProperty('relationships') ){

                    roleKind = this.kindsUnformat.find(kind => kind.id == role.relationships?.kind?.data?.id)

                    if(roleKind){
                        roleKindName = roleKind.attributes.name 
                    }
                }

                return roleKindName

            },

            getfullAddress(item) {
                
                let address = this.filteredReferenceAddress(item).attributes
                
                return address === undefined ? '' : `${address['street-number']} ${address['street-name']} ${address['suburb']} ${address['state']}, ${address['postcode']}`
               
            },
            handleResize(sizes){

                this.sizes = sizes

            },

        },

        data() {
            return {
                action: 'add',
                editItem: null,
                isEdit: false,
                dialog: false,
                sizes: null,
                validComponentData: true,
                resourceObj: {},
                resources: [
                    {
                        'id': null,
                        'attributes': {
                            'business-name': null,
                            'date': null,
                            'email': null,
                            'is-business': null,
                            'name': null,
                            'phone': null,
                            'relationship': null
                        },                        
                        'relationships': {
                            'address': {
                                'data': { 'id': null, 'type': 'addresses' }
                            },
                            'application': {
                                'data': { 'id': null, 'type': 'applications' }
                            }
                        },
                        'type': 'references'
                    }
                ],
                addresses: [
                    {
                        'id': null,
                        'attributes': {
                            'country': null,
                            'postcode': null,
                            'state': null,
                            'street-name': null,
                            'street-number': null,
                            'street-type': null,
                            'suburb': null
                        },                        
                        'relationships': {
                            'application': {
                                'data': { 'id': null, 'type': 'applications' }
                            }
                        },
                        'type': 'addresses'
                    }
                ],
                person: '',
                labels: {
                    reference: {
                        'relationship': { text: 'Relationship', hint: '' },
                        'is-business': { text: 'Is Business', hint: '' },
                        'business-name': { text: 'Business Name', hint: '' },
                        'date': { text: 'Date', hint: '' },
                        'phone': { text: 'Phone Number', hint: '' },
                        'email': { text: 'Email', hint: '' }
                    },
                    address: {
                        'postcode': { text: '', hint: '' },
                        'state': { text: '', hint: '' },
                        'street-name': { text: '', hint: '' },
                        'street-number': { text: '', hint: '' },
                        'street-type': { text: '', hint: '' },
                        'suburb': { text: '', hint: '' }
                    }
                },
                headers: [
                    { text: 'Name', sortable: false, value: 'reference-name', width: '25%' },
                    { text: 'Address', sortable: false, value: 'address', width: '30%' },
                    { text: 'Phone', sortable: false, value: 'phone', width: '15%' },
                    { text: 'Relationship', sortable: false, value: 'relationship', width: '20%' },
                    { text: '', sortable: false, value: 'actions', width: '10%' }
                ]
            }
        }
    }
</script>

<style lang="scss">
    .inline-icons {
        display: flex;
    }


    .table-card > .v-data-table__wrapper > table {
        border-collapse:separate; 
        border-spacing: 0 1em !important;
    }

    .table-card > .v-data-table__wrapper > table .table-card-tr {
        height: 5rem;
        border-width: 1px !important;
        border-style: solid !important;
        border-color: var(--v-light_gray_1-darken1) !important;

        td {
            border-width: 1px !important;
            border-style: solid !important;
            border-color: var(--v-light_gray_1-darken1) !important;
            border-left: none !important;
            border-right: none !important;
        }

        .actions {
            .v-size--small {
                border-radius: 50%;
                height: 40px !important;
            }
        }

        td:first-child {
            border-bottom-left-radius: 2em;
            border-top-left-radius: 2em;
            border-left-width: 1px !important;
            border-left-style: solid !important;
            border-right: none !important;
            border-color: var(--v-light_gray_1-darken1) !important;
        }

        td:last-child {
            border-bottom-right-radius: 2em;
            border-top-right-radius: 2em;
            border-right-width: 1px !important;
            border-right-style: solid !important;
            border-color: var(--v-light_gray_1-darken1) !important;
        }

        &:hover {
            td:first-child {
                border-bottom-left-radius: 2em !important;
                border-top-left-radius: 2em !important;
                border-left-width: 1px !important;
                border-left-style: solid !important;
                border-right: none !important;
                border-color: var(--v-light_gray_1-darken1) !important;
            }

            td:last-child {
                border-bottom-right-radius: 2em !important;
                border-top-right-radius: 2em !important;
                border-right-width: 1px !important;
                border-right-style: solid !important;
                border-color: var(--v-light_gray_1-darken1) !important;
            }
        }
    }

</style>