<template>
    <div class="flex__sub-component-Radio1">

        <v-list>
            <v-list-item-group
                v-model="selectedItem"
                @change="handleChange"
            >
                <v-list-item
                    :ripple="false"
                    class="my-4 item-list"
                    v-for="(option, key) in options"
                    :key="key"
                    :style="vb.smAndUp ? '' : 'min-height: 70px !important; height: 70px;'"
                    @keyup.space="handleSpace(option.value, $event)"
                    :value="option.value"
                >
                <v-list-item-action class="ml-2 mr-4" v-if="option.icon">
                    <v-icon color="secondary" :size="vb.smAndUp ? 32 : 28">{{option.icon}}</v-icon>
                </v-list-item-action>

                <v-list-item-content>
                    <v-list-item-title>
                        <div :class="`${vb.smAndUp ? '' : 'font-size-18'}`">
                            {{option.text}}
                        </div>
                    </v-list-item-title>
                </v-list-item-content>

                </v-list-item>
            </v-list-item-group>
        </v-list>

        <div
            v-if="hasErrors" 
            class="error-messages">
            <span 
                v-for="(err, ekey) in errorMessages"
                :key="ekey">
                {{ err }}
            </span>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'Radio1',
        model: {
            prop: 'modelValue',
            event: 'input'
        },
        props: {
            modelValue: {
                type: String,
                default: ''
            },
            options: {
                type: Array,
                default: () => ['Yes', 'No']
            },
            label: {
                type: String,
                default: 'Select Type'
            },
            color: {
                type: String,
                default: 'secondary'
            },
            errorMessages: {
                type: Array,
                default: () => []
            },
            syncReset: {
                type: Boolean,
                default: false
            },

        },

        computed: {
            hasErrors() {
                return this.errorMessages.length ? true : false
            },
            vb() {
                return this.$vuetify.breakpoint
            },
        },

        created () {
            this.selectedItem = this.modelValue
        },
        
        methods: {
            handleChange(value) {
                this.$emit('input', value)
            },
            handleSpace(value, $event) {
                if($event.keyCode == 32 ||$event.code == 'Space'){
                    this.$emit('input', value)
                }
            }
        },

        watch: {
            'syncReset': {
                handler(val) {
                    if (val && this.modelValue.length < 1)
                        this.selectedItem = ''
                },
                deep: true
            },
            'modelValue': {
                handler(val) {
                    this.selectedItem = val
                },
                deep: true
            }
        },

        data() {
            return {
                selectedItem: ''
            }
        }
    }
</script>


<style lang="scss" scoped>

    .flex__sub-component-Radio1 {

        .v-list-item {
            min-height: 77px !important;
        }

        .v-list-item__title {
            font-size: 1.375rem !important;
        }

        .theme--light.v-list-item:not(.v-list-item--active):not(.v-list-item--disabled) {
            border-radius: 25px;
            border: 2px solid var(--v-light_gray_6-base) !important;
        }

        .theme--light.v-list-item--active {
            border-radius: 25px !important;
            border: 2px solid var(--v-secondary-base) !important;
        }

        .theme--light.v-list-item:before, .theme--light.v-list-item:hover:before, .theme--light.v-list-item:focus:before {
            border-radius: 25px;
            border: 1px solid var(--v-primary-base) !important;
            background-color: var(--v-secondary-base) !important;
        }

        .error-messages {
            border: none;
            color: var(--v-error-base) !important;
            caret-color: var(--v-error-base) !important;
            font-size: 12px;
        }

    }



</style>