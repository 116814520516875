import { has } from "lodash"

export default async (context) => {
    
    let { onKeywordRoute } = context.store.state

    // let userSetting = context.store.getters['auth-actions/getUserSetting']
    let AppOwnerSettings = context.store.getters['auth-actions/getAppOwnerSettings']
    // console.log(AppOwnerSettings)
    let hasRedirect = false

    // Don't execute when on keyword route
    if(onKeywordRoute) {
        return
    }

    /**
     * Filter user settings in flows
     * Manipulating route object
     */

    if(!context.$auth.loggedIn) return;

    if ( context.params.flow_slug && (context.params.flow_slug.includes('b2b') || context.params.flow_slug.includes('assister')) ) {
        
        if ( AppOwnerSettings && AppOwnerSettings.bid ) {
            
            if ( context.params.flow_slug.indexOf('consumer') >= 0 || context.params.flow_slug.indexOf('personal') >= 0 ) {
            
                if ( context.params.flow_slug.indexOf('bid') < 0 ) {            
                    context.params.flow_slug = context.params.flow_slug + '-bid'
                    hasRedirect = true
                }

            }
            else {

                if ( context.params.flow_slug.indexOf('bid') >= 0 ) {
                    context.params.flow_slug = context.params.flow_slug.split('-bid')[0]
                    hasRedirect = true
                }
                
            }
            
        }
        else {

            if ( context.params.flow_slug.indexOf('bid') >= 0 ) {
                context.params.flow_slug = context.params.flow_slug.split('-bid')[0]    
                hasRedirect = true
            }
            
        }
    }
    if ( hasRedirect ) {              
        let step = context.route.params.steps == 1 ? 'first' : context.route.params.steps;
        window.location.href = `/${context.route.params.group_slug}/flows/${context.route.params.flow_slug}/${step}/${context.route.params.app_guid}`
        // context.redirect(`/${context.route.params.group_slug}/flows/${context.route.params.flow_slug}/${context.route.params.steps}/${context.route.params.app_guid}`)
    }

}

// bid user
// commercial
// consumer