import { ValidationObserver, ValidationProvider } from 'vee-validate'

/**
 * Optional: include if components have forms
 */

export default {
    components: {
        ValidationObserver,
        ValidationProvider
    }
}