<template>
    <div>
        <v-data-table
            :loading="disabled"
            loading-text="Please wait..."
            v-if="hasLenders"
            :headers="headers"
            :items="lenders"
            hide-default-footer
            :mobile-breakpoint="0"
            hide-default-header
            class="elevation-0 v-data-table-2 mx-auto">

            <!-- Body -->
            <template v-slot:item.compare="{ item, index }">

                <v-card class="card-row ma-2 pa-2 rounded-xl">

                    <!-- # Header side of table -->
                    <div class="px-2">
                        <div class="d-flex justify-space-between">
                            <div class="d-flex">
                                <div class="px-2">
                                    <div v-if="hideLenderIndentity" class="title font-weight-bold pt-4">
                                        {{ item['alias'] }}
                                    </div>
                                    <v-img 
                                        v-else
                                        max-width="80"
                                        class="flex-brand" 
                                        :src="getLenderLogo(item['logo-class'])" 
                                        position="center left" 
                                        contain>
                                    </v-img>
                                </div>
                            </div>
                            
                            <div class="pt-4 pr-6 d-flex">
                                <p class="td-inner ">{{ item['actual-payment']['monthly-payment'] | currency }}</p>
                                <span class="body-2 mx-1"> / month</span>
                            </div>
                        </div>
                    </div>

                    <!-- # line break -->
                    <hr color="#efecec">

                    <!-- # md and up design -->
                    <div v-if="vb.mdAndUp" class="d-flex justify-space-between">

                        <div class="d-flex flex-column justify-start ml-4 mt-4">
                            <p class="my-0 caption ">Loan Term</p>
                            <p class="font-weight-bold td-inner">{{ item['actual-payment']['term-in-months']  }} Months</p>
                        </div>

                        <div class="d-flex flex-column justify-start ml-4 mt-4">
                            <p class="my-0 caption">Interest Rate</p>
                            <p class="font-weight-bold td-inner">{{ (item['actual-payment']['customer-rate'] ) | percentage }}</p>
                        </div>

                        <div class="d-flex flex-column justify-start ml-4 mt-4">
                            <p class="my-0 caption">Comparison Rate</p>
                            <p class="font-weight-bold td-inner">{{ item['actual-payment']['comparison-rate'] | percentage }}</p>
                        </div>

                        <div class="inner end">
                            <v-btn class="mr-2" color="primary" :outlined="selected.indexOf(item.name) == -1" @click="setSelect(item.name)" large depressed> Select </v-btn>
                            <v-btn class="mr-2" color="secondary" outlined @click="showInfoDialog(item)" large depressed> More Info</v-btn>
                            <v-btn 
                                :color="selectedLender === item.slug ? 'secondary is-disabled' : 'secondary'"
                                :disabled="disabled"
                                @click="setSelected(item.name, item)"
                                large
                                depressed>{{ selectedLender === item.slug ? 'Applied' : 'Apply Now' }}
                            </v-btn>
                        </div>
                        
                    </div>

                    <!-- # mobile resposive -->
                    <div v-else>

                        <div class="d-flex justify-space-between">
                            <div class="d-flex flex-column justify-start ml-4 mt-4">
                                <p class="my-0 caption">Loan Term</p>
                                <p class="font-weight-bold">{{ item['actual-payment']['term-in-months']  }} Months</p>
                            </div>

                            <div class="d-flex flex-column justify-start ml-4 mt-4">
                                <p class="my-0 caption">Interest Rate</p>
                                <p class="font-weight-bold">{{ (item['actual-payment']['customer-rate'] ) | percentage }}</p>
                            </div>

                            <div class="d-flex flex-column justify-start mx-2 mt-4">
                                <p class="my-0 caption">Comparison Rate</p>
                                <p class="font-weight-bold">{{ item['actual-payment']['comparison-rate'] | percentage }}</p>
                            </div>
                        </div>
                        <div class="d-flex justify-center mt-n5">

                            <div class="inner end-mobile">

                                <v-btn class="mr-2" color="primary" :outlined="selected.indexOf(item.name) == -1" @click="setSelect(item.name)" large depressed> Select </v-btn>
                                <v-btn class="mr-2" color="secondary" outlined @click="showInfoDialog(item)" large depressed> Info </v-btn>
                                <v-btn 
                                    :color="selectedLender === item.slug ? 'secondary is-disabled' : 'secondary'"
                                    :disabled="disabled"
                                    @click="setSelected(item.name, item)"
                                    large
                                    depressed>{{ selectedLender === item.slug ? 'Applied' : 'Apply' }}
                                </v-btn>

                            </div>

                        </div>
                        
                    </div>

                </v-card>
                <br>
                
            </template>

        </v-data-table>
    
        <v-card v-else tile flat class="rounded-lg mx-auto" max-width="700">
            <v-card-text class="text-center pa-0 pa-md-66">
                <v-icon size="48" color="secondary">mdi-information</v-icon>
                <v-spacer class="mb-6"/>
                <div class="body_text_color--text">
                    <div class="headline mb-6">Sorry, we are having trouble locating options based on the information provided.</div>
                    <p class="subtitle-1">But, please continue and submit the application with detailed submission notes, and our team will look at options using the entire panel.</p>
                </div>
            </v-card-text>
        </v-card>

        <v-sheet max-width="854" class="mx-auto">
            <div class="text-center">
                <template v-if="!hasLenders">
                    <v-btn @click="handleContinue" color="primary" large depressed><div class="px-4">Continue</div></v-btn>
                </template>

            </div>
        </v-sheet>

        <v-sheet max-width="854" class="mx-auto mt-8">
            <p class="caption text-center gray">*Terms and conditions apply. Finance for credit approved purchaser's only. The finance offer is for amounts and terms shown above. The comparison rate is based on the amounts and terms above at the annual percentage rate shown above. The amount of credit provided to you and the term of the loan may be different to this WARNING. The comparison rate only applies to the example given and may not include all fees and charges. Different terms, fees or other loan amounts might result in a different comparison rate. Credit Asssistance is provided through Online Loans ABN 58 610 067 715 and/or its panel of lenders. Australian Credit Licence Number 484982.</p>
        </v-sheet>

        <!-- More Info Modal -->
        <v-dialog
            persistent
            scrollable
            max-width="520"
            v-if="dialogData"
            v-model="dialog">

            <v-card flat>

                <!-- <v-toolbar flat class="pt-3 pr-6 mb-n8">
                    <v-spacer></v-spacer>
                    <v-btn icon @click="dialog = false" fab>
                        <v-icon color="secondary">mdi-close</v-icon>
                    </v-btn>
                </v-toolbar> -->

                <v-card-title class="py-0 px-4 px-sm-8">
                    <div v-if="hideLenderIndentity" class="title font-weight-bold py-6 pl-4 mx-auto">
                        {{ dialogData['alias'] }}
                    </div>
                    <v-img
                        v-else
                        max-width="100"
                        class="mx-auto"
                        :src="getLenderLogo(dialogData['logo-class'])" 
                        position="center" 
                        contain>
                    </v-img>
                    <v-btn icon @click="dialog = false" fab class="ml-n6">
                        <v-icon color="secondary">mdi-close</v-icon>
                    </v-btn>
                </v-card-title>

                <v-card-text class="px-4 px-sm-8 pt-0 pb-10 text-center"> 

                    <v-container fluid pa-0>

                        <v-row :class="vb.mdAndUp ? 'mx-1' : 'mx-0'">

                            <v-col cols="12" class="d-flex justify-space-between rounded-lg px-4 px-md-6 py-4 light_gray_1 my-1">
                                <div class="font-weight-bold" :class="vb.mdAndUp ? 'subtitle-1' : 'caption'">Requested Amount</div> 
                                <div style="width:40%;">
                                    <div :class="vb.mdAndUp ? 'body-1' : 'caption'">{{ requiredAmount }}</div> 
                                </div>
                            </v-col>
                            
                            <v-col cols="12" class="d-flex justify-space-between rounded-lg px-4 px-md-6 py-4 my-1">
                                <div class="font-weight-bold" :class="vb.mdAndUp ? 'subtitle-1' : 'caption'">Origination Fee</div> 
                                <div style="width:40%;">
                                    <div :class="vb.mdAndUp ? 'body-1' : 'caption'">{{ dialogData['actual-payment']['broker-fee'] | currency }}</div> 
                                </div>
                            </v-col>

                            <v-col cols="12" class="d-flex justify-space-between rounded-lg px-4 px-md-6 py-4 light_gray_1 my-1">
                                <div class="font-weight-bold" :class="vb.mdAndUp ? 'subtitle-1' : 'caption'">Bank Setup Fees</div> 
                                <div style="width:40%;">
                                    <div :class="vb.mdAndUp ? 'body-1' : 'caption'">{{ dialogData['actual-payment']['bank-fees'] | currency }}</div> 
                                </div>
                            </v-col>

                            <v-col cols="12" class="d-flex justify-space-between rounded-lg px-4 px-md-6 py-4 my-1">
                                <div class="font-weight-bold" :class="vb.mdAndUp ? 'subtitle-1' : 'caption'">Amount Financed</div> 
                                <div style="width:40%;">
                                    <div :class="vb.mdAndUp ? 'body-1' : 'caption'">{{ dialogData['actual-payment']['naf'] | currency }}</div> 
                                </div>
                            </v-col>

                            <v-col cols="12" class="d-flex justify-space-between rounded-lg px-4 px-md-6 py-7 my-1 light_gray_1">
                                <div class="font-weight-bold subtitle-1"></div> 
                                <div style="width:40%;">
                                    <div :class="vb.mdAndUp ? 'body-1' : 'caption'"></div> 
                                </div>
                            </v-col>
                            
                            <v-col cols="12" class="d-flex justify-space-between rounded-lg px-4 px-md-6 py-4 my-1">
                                <div class="font-weight-bold" :class="vb.mdAndUp ? 'subtitle-1' : 'caption'">Loan Term</div> 
                                <div style="width:40%;">
                                    <div :class="vb.mdAndUp ? 'body-1' : 'caption'">{{ dialogData['actual-payment']['term-in-months'] }} Months</div> 
                                </div>
                            </v-col>

                            <v-col cols="12" class="d-flex justify-space-between rounded-lg px-4 px-md-6 py-4 light_gray_1 my-1">
                                <div class="font-weight-bold" :class="vb.mdAndUp ? 'subtitle-1' : 'caption'">Weekly Payment</div> 
                                <div style="width:40%;">
                                    <div :class="vb.mdAndUp ? 'body-1' : 'caption'">{{ ((dialogData['actual-payment']['monthly-payment'] * 12) / 52) | currency }}</div> 
                                </div>
                            </v-col>

                        </v-row>

                    </v-container>
                </v-card-text>

            </v-card>
        </v-dialog>

    </div>
    
</template>
<script>
    export default {
        name: 'QuoteTable',

        props: {

            lenders: {
                type: Array,
                default: () => []
            },
            headers: {
                type: Array,
                default: () => []
            },
            hasLenders: {
                type: Boolean,
                default: false
            },
            disabled: {
                type: Boolean,
                default: false
            },
            selectedLender: {
                type: String,
                default: ''
            },
            requiredAmount: {
                type: String,
                default: ''
            },
            settings: {
                type: Object,
                default: () => {}
            }
            
        },

        computed: {
            vb(){
                return this.$vuetify.breakpoint
            },
            selectedCount(){

                return this.selected.length

            },
            selectedLenders(){

                let lenders = []


                this.lenders.forEach( lender => {

                    if( this.selected.includes(lender.name)) {

                        lenders.push(lender)
                    }

                })

                return lenders

            },
            hideLenderIndentity(){
                return this.settings.hideLenderNames == 1
            },
        },

        data() {
            return {
                fab: false,
                dialog: false,
                dialog2: false,
                selected: [],
                dialogData: null,
            }
        },

        methods: {
            setSelected(name = '', lender = {}) {
                
                this.$emit('selectLender', name, lender)

            },
            showCompareDialog(){
                
                this.dialog2 = true

            },
            showInfoDialog(data){
                // console.log(data)

                this.dialogData = data
                this.dialog = true

            },
            setSelect(name = '') {

                if (this.selected.includes(name)) {
                    this.selected = this.selected.filter(
                        selected => selected !== name
                    )
                } else {
                    this.selected.push(name)
                }

                this.$emit('setSelect', this.selected)

            },
            getLenderLogo(name){

                let lender = require(`~/assets/images/logo/placeholder.png`)

                try {

                    lender = require(`~/assets/images/logo/${name}.png`)

                    return lender

                } catch (error) {

                    console.log('Ooops!..', error)

                    return lender

                }

            },
            handleContinue() {
                this.$emit('continue')
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>
