<template>
    <div>

        <div>
            <v-btn
                large
                depressed
                @click="handleClick"
                color="primary"
            >
                <span v-if="vb.mdAndUp">
                    Quick Edit
                </span>
                <span v-else>
                    <v-icon color="primary lighten-5">
                        mdi-square-edit-outline
                    </v-icon>
                </span>
            </v-btn>
        </div>

        <v-dialog
            v-model="dialog"
            persistent
            content-class="modal-RequestCallBack"
            scrollable
            width="500">

            <v-card :disabled="loading" class="primary lighten-5 mt-10 rounded-xl">

                <div class="primary rounded-t-xl">
                    <v-card-title class="px-6 pt-4">
                        <v-spacer/>
                        <v-btn icon @click="dialog = false">
                            <v-icon color="primary lighten-5">mdi-close</v-icon>
                        </v-btn>
                    </v-card-title>

                    <v-card-subtitle class="px-8 py-0 avatar">
                        <img src="~/assets/images/jane.png" height="90"/>
                    </v-card-subtitle>

                    <v-card-subtitle>
                        <div class="mx-auto body-1 white--text text-center">
                            Need some Help? Speak with one of our finance experts.
                        </div>
                    </v-card-subtitle>

                    <v-card-subtitle>
                        <CallBackModal />
                    </v-card-subtitle>
                </div>

                <v-card-text>
                    <ValidationObserver ref="observer" tag="form">

                        <div v-for="(field, index) in Object.keys(form)" :key="index">
                            <ValidationProvider 
                                v-slot="{ errors }" 
                                :name="labels[field]" 
                                :rules="getRules(field)"
                                :vid="labels[field]" >

                                    <v-select
                                        v-if="isFieldType('picklist', field)"
                                        v-model="form[field]"
                                        :label="labels[field]"
                                        :error-messages="errors"
                                        :items="options[field]"
                                        color="secondary"
                                    >
                                    </v-select>

                                    <NodifiMoney
                                        v-else
                                        v-model="form[field]"
                                        :label="labels[field]"
                                        :errors="errors" />

                            </ValidationProvider>
                        </div>

                    </ValidationObserver>
                </v-card-text>

                <v-card-actions class="py-4">

                    <v-btn
                        large
                        rounded
                        block
                        dark
                        color="primary"
                        @click="updateResources"
                        class="pa-2" >
                        Update
                    </v-btn>

                </v-card-actions>

            </v-card>
        </v-dialog>

    </div>
    
</template>
<script>

    import subscriptions from '~/components/shared/subscriptions'
    import formHelpers from '~/components/shared/form-helpers'
    import CallBackModal from '~/components/v1/QuoteResult3/includes/_CallBackModal'
    import validation from '~/components/shared/validation'
    import helpers from '~/plugins/helpers'
    import { mapActions } from 'vuex'

    export default {
        mixins: [helpers, subscriptions, formHelpers],

        name: 'RequestCallBack',

        components: {validation, CallBackModal},

        data() {
            return {
                dialog: false,
                loading: false,
                select: [],
                form: {
                    'requested-term-months': '',
                    'purchase-price': '',
                    'trade-in-amount': '',
                    'payout-amount': '',
                    'cash-deposit': '',
                },
                options: {
                    "requested-term-months": [
                        { text: "1 Year", value:  12 },
                        { text: "2 Years", value: 24 },
                        { text: "3 Years", value: 36 },
                        { text: "4 Years", value: 48 },
                        { text: "5 Years", value: 60 },
                        { text: "6 Years", value: 72 },
                    ]
                },
                labels: {
                    "requested-term-months": "Loan Term",
                    "purchase-price": "Car Price",
                    "trade-in-amount": "Estimated trade in price",
                    "payout-amount": "End of loan payment",
                    "cash-deposit": "Cash Deposit",
                },
                resources: {
                    "loans": null,
                    "purchases": null
                },
                validation_rules: {
                    attributes: {
                        "requested-term-months": "required|in:12,24,36,48,60,72,84",
                        "purchase-price": "required|between:0,9999999",
                        "cash-deposit": "required|between:0,9999999",
                        "trade-in-amount": "required|between:0,9999999",
                        "payout-amount": "required|between:0,9999999"
                    }
                }
            }
        },

        computed: {
            vb() {
                return this.$vuetify.breakpoint
            }
        },

        async mounted() {
            await this.initialize()
        },

        methods: {
            ...mapActions('resource-actions', [
                'getApplication',
                'updatePurchases',
                'updateLoan'
            ]),
            async initialize() {
                let res = await this.getApplication({
                    id: this.appId, 
                    params: {
                        include: 'loan,purchases'
                    }
                })

                if (res?.data?.included) {

                    res.data.included.forEach(data => {

                        this.filterRequiredField(data.attributes)

                        if (data.type == 'loans') {
                            this.resources.loans = data
                        }

                        if(data.type == 'purchases') {
                            this.resources.purchases = data
                        }

                    })

                }
            },
            filterRequiredField(attributes) {
                // filter required field
                Object.keys(attributes).forEach(key => {
                    Object.keys(this.form).forEach(fkey => {

                        if (key == fkey)
                            this.form[key] = attributes[key]

                    })
                })

            },

            handleClick(){
                this.dialog = true
            },

            async updateResources() {

                const valid = await this.$refs.observer.validate()

                if (valid) {

                    this.loading = true

                    // update resources for loans
                    if (Object.keys(this.resources['loans']).length > 0) {
                        this.resources['loans'].attributes = await this.populateRequiredField(this.resources['loans'].attributes)
                        await this.updateLoan(this.resources['loans'])
                    }

                    // update resources for purchase
                    if(Object.keys(this.resources['purchases']).length > 0) {
                        this.resources['purchases'].attributes = await this.populateRequiredField(this.resources['purchases'].attributes)
                        // calculate required loan amount
                        this.resources['purchases'].attributes['required-loan-amount'] = await this.calculateLoanAmount(this.resources['purchases'])
                        await this.updatePurchases(this.resources['purchases'])
                    }

                    await this.$emit('save')
                    
                    this.loading = false
                    this.dialog = false
                    
                }
            },

            populateRequiredField(attributes) {
                // populate required fields
                Object.keys(this.form).forEach(key => {

                    attributes[key] = this.form[key]

                })

                return attributes
            },

            calculateLoanAmount(purchase = {}) {
                let loanAmount = 0

                let purchasePrice = purchase.attributes['purchase-price'] ? parseFloat( purchase.attributes['purchase-price'] ) : 0
                let payoutAmount = purchase.attributes['payout-amount'] ? parseFloat( purchase.attributes['payout-amount'] ) : 0
                let cashDeposit = purchase.attributes['cash-deposit'] ? parseFloat( purchase.attributes['cash-deposit'] ) : 0
                let tradeInAmount = purchase.attributes['trade-in-amount'] ? parseFloat( purchase.attributes['trade-in-amount'] ) : 0
                loanAmount = ( purchasePrice + payoutAmount ) - ( cashDeposit + tradeInAmount )

                return loanAmount
            }
        }

    }

</script>

<style lang="scss" scoped>
    .modal-RequestCallBack {
        .v-dialog {
            overflow-y: unset !important;
        }
        .avatar {
            text-align: center;
            margin-top: -100px;
            img {
                border-radius: 36px !important;
                margin: 0 auto;
            }
        }
    }
</style>