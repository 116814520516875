<template>
    <v-dialog
        v-model="dialog"
        width="900"
        scrollable
        persistent>
        <template v-slot:activator="{ on, attrs }">
            <v-btn 
                v-if="normalButton"
                depressed
                large
                v-bind="attrs"
                v-on="on"
                elevation="0"
                class="secondary">
                Quick Edit                
            </v-btn>
        </template>

        <v-card flat>

            <v-card-title>
                <v-spacer />
                <v-btn icon @click="dialog = false" >
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-card-title>

            <v-card-text class="pa-0">

                <v-card tile flat class="px-4">
                    <v-card-title class="px-4 px-md-8 pb-4">
                        <span>{{ title }}</span>
                    </v-card-title>
                    <v-card-text class="pa-0">
                        <AssetLoanEditor1 
                            @loan-details-changed="getLoans"
                            :is-flow="!dialog"
                            :standalone="false" />   
                    </v-card-text>
                </v-card>
                           
            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions class="pa-4 pa-md-6 flex-wrap controls">  
                <v-btn
                    outlined
                    color="light_gray_2"
                    large
                    class="flex-back-button"
                    style="min-width:140px;"
                    @click="cancel">
                    Back
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn
                    color="secondary"
                    depressed
                    large
                    style="min-width:140px;"
                    @click="save">
                    Update
                </v-btn>
            </v-card-actions>
        </v-card>

    </v-dialog>
</template>

<script>
    
    import AssetLoanEditor1 from '~/components/v1/AssetLoanEditor1/AssetLoanEditor1'
    import baseComponents from '~/components/base'
    import v1Components from '~/components/v1'
    import { isEqual } from 'lodash'

    export default {
        name: 'QuotableComponentDialog',

        components: { 
            AssetLoanEditor1,
            ...baseComponents,
            ...v1Components,
        },

        props: {
            title: {
                type: String,
                default: ''
            },
            spacePages: {
                type: Array,
                default: () => []
            },
            normalButton: {
                type: Boolean,
                default: true
            }
        },

        computed: {
            componentName() {
                return this.spacePages.find( space => {
                    return this.title === space.title
                })
            }
        },

        methods: {
            save() {
                this.$emit('confirm-update', this.loanResources)
                this.dialog = false
            },

            cancel() {
                this.dialog = false
            },

            getLoans(data) {
                this.loanResources = data
            }
        },

        data() {
            return {
                dialog: false,
                loanResources: {},
                isFlow: false
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>