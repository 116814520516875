<template>
    <v-dialog
        v-model="dialog"
        width="800"
        scrollable
        persistent>
   

        <v-card flat class="owner relative">
            <v-card-title class="pa-0 ma-0 pr-5 pt-3 mb-n5">
                <v-spacer></v-spacer>
                <v-btn icon rounded small @click="close" depressed>
                    <v-icon color="primary" size="20">mdi-close</v-icon>
                </v-btn>
            </v-card-title>
            <v-card-title class="px-8 py-4 text-center">
                <div class="sm pa-0 text-center" style="width:100%">
                   {{title}}
                </div>
            </v-card-title>
            <v-card-text :class="vb.mdAndUp ? 'px-16' : 'px-4'">
                <div>
                    <v-row :justify="possibleOwner.length <= 3 ? 'center' : 'start'"
                        align="center"
                        :style="possibleOwner.length <= 3 && vb.mdAndUp ? 'width: 50%;' : 'width: 100%;'"
                        class="rounded-lg light_gray_1 pa-2 ma-2 mx-auto">
                        <!-- <v-col 
                            class="btn custom-outline1 ma-4"
                            v-for="(owner,key) in possibleOwner"
                            :key="key"
                            @click="nextStep(owner)">
                            {{owner.name}}
                        </v-col> -->
                        <v-col v-for="(owner,key) in possibleOwner" :key="key"
                            cols="12" class="pa-2" :md="possibleOwner.length <= 3 ? '12' : '6'">
                            <v-card
                                flat
                                height="100%" 
                                @click="nextStep(owner)"
                                rounded="lg"
                                class="d-flex pa-4 align-center justify-center">
                                <div class="flex-options body-2 font-weight-medium">
                                    {{owner.name}}
                                </div>
                                <v-spacer></v-spacer>
                                <v-btn color="secondary" :width="86" small depressed>
                                    Select
                                </v-btn>
                            </v-card>
                        </v-col>

                    </v-row>
                </div>
                <v-row class="d-flex justify-center py-8">
                    <v-btn class="px-12 custom-outline1" @click="back"  width="140" height="50" depressed large outlined>Back</v-btn>
                </v-row>
            </v-card-text>
          
        </v-card>

    </v-dialog>
</template>
<script>
import {store,mutations} from './__store'
import shared from './__shared'

export default {
    mixins: [shared],
    props : {
        title: {
            type: String,
            default: 'Title'
        },
        step: {
            type: String,
            default: ''
        },
        address: {
            type:Object,
            default: () => {}
        }
    },
    watch: {
        "step": {
            handler (val) {
                val == 'property-owner' ? this.dialog = true : this.dialog = false
            }
        },
        "address": {
            handler (val) {
                if (val.hasOwnProperty('occupancy')){
                    this.occupancySituation =  val.occupancy.attributes['occupancy-situation']
                } else {
                    this.occupancySituation = ""
                }
            }
        }
    },
    computed : {
        possibleOwner () {
            return store.possibleOwner
        }
    },
    methods: {
        nextStep(data) {
            // mutations.setIsCurrentLive(data)
            // this.$emit('input',{model:'occupancy-situation',value:data})
            // this.$emit('setCurrentLive',data)
            if(data.id == null){
                this.$emit('step','rental-situation')
            } else {
                this.$emit('step','asset-value')
                mutations.setSelectedOwner(data)
            }
            // console.log('loading')
            
        },
        back(){
            this.$emit('back','is-spouse-live-same-period')
        },
        close(){
            this.$emit('step',null)
        }
    },
    data() {
        return {
            isLoading:false,
            dialog : false,
            fields : [   
            ],
            occupancySituation : ''
        }
    }
}
</script>
<style lang="scss" >
    
    .owner{
        .btn {
            text-align: center;
            padding: 50px;
            border-radius: 20px;
            font-size: 15px;
            border: 1px solid;
            cursor: pointer;
            width: 250px !important;
            height: 150px !important;
        }
        .btn:hover{
            background: #E7FAFC;
        }
        .custom-outline1{
            border-color: var(--v-secondary-base);
        }
        .custom-padding {
            padding: 10px 140px;
        }
        .bg-light-blue{
            background: #E7FAFC;
        }
        .close-icon {
            position: absolute;
            right: 40px;
            top: 30px;
            // background: var(--v-secondary-lighten2);
            color: var(--v-secondary-base)!important;
            padding: 6px;
            border-radius: 36px !important;
            i {
                color: var(--v-secondary-base) !important;
            }
        }
    }
</style>