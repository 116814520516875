import Vue from "vue";

export const store = Vue.observable({

    incomeSituationOptions: ['Current', 'Secondary'],

    loanType: null,
    appType: null,

    kindsIncome: {},
    kindsIncomeId: null,
    preselectIncomeSituation: null,
    selectedFOName: null,
    preSelectRoles: null,

    resources: {
        incomes: [],
        assets: [],
        expenses: [],
        liabilities: [],
        people: [],
        businesses: [],
        addresses: {
            incomes: []
        },
    },

    // Entities
    entities: [],
    selectedEntity: null,
    entityParts: [],

    allAddresses: [],
    allOccupancies: [],

    saving: false,
    steps: [
        'income-situation'
    ],
    currentStep: null,
    stepper: 0,
    showNext: false,

    incomeLayout: [],
    incomeFormMaxSize: '',
    incomeLabels: null,
    cardTitle: null,
    noIncome: false,

    standalone: false,
    incomeId: null,

});


export const actions = {
    setLoanType(payload) {
        store.loanType = payload;
    },

    setAppType(payload) {
        store.appType = payload;
    },

    setPeople(payload) {
        store.resources.people = payload;
    },

    setEntities(payload) {
        store.entities = payload;
    },
    
    setEntityParts(payload) {
        store.entityParts = payload;
    },

    setBusinesses(payload) {
        store.resources.businesses = payload;
    },

    setIncomeKindId(payload) {
        store.kindsIncomeId = payload
    },

    setResources(payload) {

        // const {incomes, assets, expenses, liabilities} = payload

        let keys = Object.keys(payload)

        keys.forEach( key => {
            store.resources[key] = payload[key];
        });

    },

    updateResources(payload){
    
        let newData = store.resources[payload.type].filter(resource => {
            return resource.id != payload.id
        })

        newData = [
            payload,
            ...newData
        ]

        store.resources[payload.type] = newData

    },

    deleteResource(payload){

        let newData = store.resources[payload.type].filter(resource => {
            return resource.id != payload.id
        })

        store.resources[payload.type] = newData

    },

    setAllAddresses(payload) {

        store.allAddresses = payload;
    },

    setAllOccupancies(payload) {

        store.allOccupancies = payload;

    },

    setPreselectIncomeSituation(payload){
        store.preselectIncomeSituation = payload
    },

    setIncomeSituation() {
        this.setSteps(['income-situation'])
        store.steps.push(store.kindsIncome)
        store.currentStep = store.kindsIncome

        store.saving = true

        setTimeout( () => {
            store.stepper = store.stepper+1
            store.saving = false
        }, 300)
    },

    setIncomeSituationOptions(payload) {
        store.incomeSituationOptions = payload;
    },

    // Kinds
    setKindsIncome(payload){

        // Filter, remove obsolete kinds
        let obsolete = []

        // remove business income if consumer
        if (store.loanType === 'Consumer') {
            obsolete.push('12-30')
            obsolete.push('12-16')
        }

        let newPayload = {...payload}
        let children = [...newPayload.children]

       // check if payload children has only 1
       if (payload.children.length === 1) {
            newPayload = {...payload.children[0]}
        } else {
            newPayload.children = children.filter( child => {
            return !obsolete.includes(child.id)
        })
    }

        store.kindsIncome = newPayload 
    },

    async nextStep(payload) {

        // Filter, remove obsolete kinds
        // console.log(payload)

        let obsolete = [
            "11-19", // "Equipment Finance"
        ]

        let newPayload = {...payload}
        let children = [...newPayload.children]
        
        // check if payload children has only 1
        if (payload.children.length === 1) {
            newPayload = {...payload.children[0]}
        } else {
            newPayload.children = children.filter( child => {
                return !obsolete.includes(child.id)
            })
        }

        // if payload have children set steps to current steps and push the new payload
        if(newPayload.children.length != 0) {
            await this.setSteps(['income-situation', store.kindsIncome])
            await store.steps.splice(store.stepper + 1, 0, newPayload)
        }

        store.currentStep = newPayload 

        // reset steps if payload dont have children and setting income kind id
        if(newPayload.children.length == 0) {
            store.kindsIncomeId = newPayload.id
            store.steps.length = store.stepper + 1
        }

        store.saving = true

        setTimeout( () => {
            store.stepper = store.stepper+1
            store.saving = false
        }, 300)

    },

    setPreSelectRoles(payload){
        store.preSelectRoles = payload 
    },

    setCurrentStep(payload){
        store.currentStep = payload
    },

    setSteps(payload){
        store.steps = payload
    },

    selectNextStep() {
        store.saving = true
        actions.setCurrentStep([])

        setTimeout( () => {
            store.stepper = store.stepper+1
            store.saving = false
        }, 300)
    },

    prevStep() {

        if(store.currentStep?.id) {
            store.steps.splice(store.stepper, 1)
            actions.setShowNext(false)
        }

        store.stepper = store.stepper-1
        store.saving = true

        setTimeout( () => {
            store.currentStep = store.steps[store.stepper] 
            
            // if no income is selected
            if(store.currentStep?.id && store.currentStep.id == '12-1') {
                actions.setNoIncome(false)
            }

            store.saving = false
        }, 300)
    },

    resetAll(){

        store.loanType = null
        store.appType = null
        store.selectedEntity = null
        store.kindsIncomeId = null
        store.cardTitle = null
        store.incomeFormMaxSize = ''
        // store.selectedFO = 0
        // store.preset = null
        // store.selectedFOName = null

        // Dialog
        store.saving = false
        
        this.resetIncomeEditorDialog()
    
        // Kinds
        store.kindsIncome = null
        store.kindsIncomeId = null
    },

    resetIncomeEditorDialog(){
        store.stepper = 0
        store.steps = ['income-situation']
        store.currentStep = 'income-situation'
    },

    pushStep(payload){
        store.steps.push(payload)
    },

    setIncomeLayout(payload){
        store.incomeLayout = payload
    },

    setIncomeFormMaxSize(payload){
        store.incomeFormMaxSize = payload
    },

    setCardTitle(payload){
        store.cardTitle = payload
    },

    setNoIncome(payload){
        store.noIncome = payload
    },

    setSelectedEntity(payload){
        store.selectedEntity = payload
    },

    setIncomeLabels(payload){
        store.incomeLabels = payload
    },
    
    setStandalone(payload){
        store.standalone = payload
    },

    setIncomeId(payload){
        store.incomeId = payload
    },

    setShowNext(payload){
        store.showNext = payload
    }

};