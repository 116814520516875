export default {
    additionalInfoLayoutByKind: {
        // Agricultural Machinery, Yellow Goods
        "9-22|9-24": {
            "serial-number": "",
            "engine-number": "",
            "registration-number": "",
            "registration-state": "",
            "build-date": "",
            "compliance-date": "",
            "colour": "",
            "hours": "",
        },
        // Marine Engine
        "9-11": {
            "engine-number": "",
            "build-date": "",
            "compliance-date": "",
            "hours": "",
        },
        // Boat (Moored), Boat (Trailered), Jetski
        "9-4|9-5|9-9": {
            "hin": "",
            "engine-number": "",
            "registration-number": "",
            "registration-state": "",
            "build-date": "",
            "compliance-date": "",
            "colour": "",
            "hours": "",
        },
        // Motor Vehicle
        "9-2": {
            "vin": "",
            "engine-number": "",
            "registration-number": "",
            "registration-state": "",
            "build-date": "",
            "compliance-date": "",
            "colour": "",
            "fuel-type": "",
            "body-type": "",
            "transmission": "",
            "odometer": "",
        },
        //  Motorbike (Road Registered), Motorhome, Bus, Crane, Prime Mover, Rigid Truck (> 4.5t), Trailer (Truck)
        "9-12|9-13|9-16|9-17|9-18|9-19|9-20": {
            "vin": "",
            "engine-number": "",
            "registration-number": "",
            "registration-state": "",
            "build-date": "",
            "compliance-date": "",
            "colour": "",
            "odometer": "",
        },
        // Computer Hardware, Industrial Plant (Lathes etc), Material Handling (Fork Lifts etc), Medical Equipment, Office Equipment (Copiers etc),  Other (Not Listed), POS Systems, Solar Equipment
        "9-26|9-27|9-28|9-29|9-30|9-31|9-32|9-33": {
            "serial-number": "",
            "build-date": "",
            "hours": "",
        },
        // Trail Bike (Unregistered)
        "9-14": {
            "serial-number": "",
            "engine-number": "",
            "build-date": "",
            "colour": "",
            "odometer": "",
        },
        // Camper Trailer, Caravan, Horse Float, Light Trailer
        "9-6|9-7|9-8|9-10": {
            "vin": "",
            "registration-number": "",
            "registration-state": "",
            "build-date": "",
            "compliance-date": "",
        }
    }
}