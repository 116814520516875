<template>
    <v-dialog
        persistent
        scrollable
        max-width="550"
        v-model="dialog">
            <template v-slot:activator="{ on, attrs }">
                <slot name="activator-button" :on="on" :attrs="attrs">
                    <v-btn 
                        dv-bind="attrs"
                        v-on="on"
                        depressed
                        large
                        class="secondary mx-2">
                        Add Custom
                    </v-btn>
                </slot>
            </template>
            <v-card flat>

                <v-card-title class="pa-2 ma-0">
                    <v-spacer />
                    <v-icon @click="dialog = false" size="20">
                        mdi-close
                    </v-icon>
                </v-card-title>

                <v-card-title class="mt-n4 pt-0">
                    <div class="v-card__title sm">
                        Custom Lender Details
                    </div>
                    
                </v-card-title>

                <v-card-text class="pb-5 px-10">
                    <ValidationObserver 
                        ref="observer" 
                        tag="form">  

                        <ValidationProvider
                            v-slot="{ errors }"
                            name="Lender"
                            rules="required"> 
                                <v-text-field
                                    v-model="otherfield"
                                    :error-messages="errors"
                                    label="Lender">
                                </v-text-field>
                        </ValidationProvider>

                    </ValidationObserver>

                </v-card-text>

                <v-card-actions class="mt-0 pt-0 pb-8 px-10">
                    <v-btn @click="dialog = false" :min-width="140" large depressed outlined color="secondary">
                        Back
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn @click="handleNext" :min-width="140" large depressed class="secondary">
                        Next
                    </v-btn>
                </v-card-actions>

            </v-card>
    </v-dialog>
</template>
<script>
import subscriptions from '~/components/shared/subscriptions'
import helpers from '~/plugins/helpers'

export default {
    mixins: [helpers, subscriptions],
    props: {
        showDialog: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            dialog: false,
            otherfield: '',
        } 
    },
    watch: {
        showDialog(val) {
            this.dialog = val
        }
    },
    methods: {
        async handleNext() {

            const valid = await this.$refs.observer.validate()

            if(valid) {

                this.$emit('save-name', this.otherfield)
                this.dialog = false

            }
            
        }
    }
}
</script>
