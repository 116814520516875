
export default async (context) => {

    let appConfig = context.store.state.appConfig
    let tenant = context.store.getters['slug/getTenant']
    let userSetting = context.store.getters['auth-actions/getUserSetting']


    if(!appConfig.init) {

        context.store.commit('setAppConfigInit', true)

        if(tenant == 'nectar') {

            context.store.commit('setNavigation', {scenarios : false })
            context.store.commit('setMyAppsTileMenu', {files : false})
            context.store.commit('setMyAppsTileMenu', {fileNotes : false})

          }

          let canSelectIntegrations = 0
          
          if(userSetting && userSetting.hasOwnProperty('can-select-integrations') ) {
              canSelectIntegrations = userSetting['can-select-integrations']
          }

          context.store.commit('setNavigation', {integrations: canSelectIntegrations === 1 })
    }

}


