<template>

    <v-row
        :justify="purchasesKindsFiltered.length <= 3 ? 'center' : 'start'" 
        :style="purchasesKindsFiltered.length <= 3 && vb.mdAndUp ? 'width: 55%;' : 'width: 100%;'"
        align="center" class="rounded-lg light_gray_1 pa-2 ma-2 mx-auto">
        <v-col
            v-for="kind in purchasesKindsFiltered" :key="kind.id"
            cols="12" class="pa-2" :md="purchasesKindsFiltered.length <= 3 ? '12' : '6'">

            <!-- <v-card 
                :color="selectedTypeName == kind.name ? 'light_gray_3' : ''" 
                flat height="100%" 
                outlined
                rounded="lg"
                @click="handleClick(kind)"
                :class="['d-flex flex-column justify-center align-center flex-box px-6', {'selected': selectedTypeName == kind.name}]">

                <div class="mx-auto text-center mb-5">
                    <v-icon size="56" color="white" class="mb-4">mdi-blur</v-icon>
                    <div class="flex-options font-weight-medium flex-tile-text">
                        <span>{{kind.name}}</span>
                    </div>
                </div>

            </v-card> -->

            <v-card
                flat
                height="100%" 
                @click="handleClick(kind)"
                :color="selectedTypeName == kind.name ? 'primary lighten-5' : ''"
                rounded="lg"
                class="d-flex pa-4 align-center justify-center">
                <div class="flex-options body-2 font-weight-medium">
                    {{kind.name}}
                </div>
                <v-spacer></v-spacer>
                <v-btn color="secondary" :width="86" small depressed>
                    Select
                </v-btn>
            </v-card>

        </v-col>
    </v-row>  

</template>

<script>
    /**
     * REQUIRED!
     */
    import subscriptions from '~/components/shared/subscriptions'  
    import { mapActions, mapGetters } from 'vuex'
    import {store, actions} from '~/components/v1/PurchaseEditor6/__store'

    export default {
        mixins: [subscriptions],
        props: {
            purchasesKinds: {
                type: Array,
                default: () => []
            },
            selected: {
                type: Object,
                default: null
            },
        },
        data(){
            return {
  
            }
        },
        created() {

        },
        computed:{
            ...mapGetters('themes', [
                'getActiveTheme'
            ]),
            purchasesKindsFiltered(){

                let  filter = ['9-34', '9-45']

                return this.purchasesKinds.filter( kind => {
                    if(filter.includes(kind.id)) {
                        return false
                    }
                    return true
                })

            },
            purchaseKinds() {
                let kinds = this.kinds.find( kind => {
                    return kind.name == 'Purchase'
                })
                return kinds ? kinds.children : []
            },
            assetType(){
                return store.assetType
            },
            assetSubType(){
                return store.assetSubType
            },
            selectedTypeName(){

                if(this.selected) {
                    return this.selected.name
                }

                return ''
            },
            vb() {
                return this.$vuetify.breakpoint
            }
        },
        methods: {
            ...mapActions('resource-actions', [
                'getApplicationLoan',
                'updateLoan',
                'createLoan',
                'getPurchases',
                'deletePurchase',
                'createPurchase',
                'updatePurchases',
                'getAutoImages'
            ]),
            handleClick(item){

                let key = this.purchaseKinds.find( kind => {
                    return kind.id == item.id 
                })

                let payload = {
                    isParent: key ? true : false,
                    kind: item
                }

                // console.log(item)
                this.$emit('select', payload)

            },
            hexToRGBA(hex, a = 1){
                return this.$options.filters.hexToRGBA(hex, a)
            },
        }
    }
</script>

<style scoped lang="scss">




</style>
