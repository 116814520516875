<template>
    <v-card :disabled="isDisabled" class="mx-auto" tile flat>

        <RequirementChecklist1/>

        <!-- 
            Adding portal to solve cross component controls
            see StepHeader.vue
         -->
        <Portal to="step-header-buttons">

            <template v-if="bp.mdAndUp && false">
                <v-btn color="secondary" large depressed class="ml-3" @click="open('income')">New Income</v-btn>
                <v-btn color="secondary" large depressed class="ml-3" @click="open('fo')">New Financial Object </v-btn>
            </template>

            <v-menu v-if="bp.smAndDown && false">
                <template v-slot:activator="{ on, attrs }">
                    <v-btn icon v-bind="attrs" v-on="on" >
                        <v-icon>mdi-dots-vertical</v-icon>
                    </v-btn>
                </template>
                <v-list>
                    <v-list-item @click="open('income')">
                        <v-list-item-title>New Income</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="open('fo')">
                        <v-list-item-title>New Financial Object</v-list-item-title>
                    </v-list-item>
                </v-list>
          </v-menu>

        </Portal>

        <v-card-text class="pa-4 pt-0 pa-md-10 pt-md-5">
            <v-container pa-0 fluid>
                <v-row>
                    <v-col cols="12">

                        <v-tabs class="fo-tabs" v-model="selectedEntity" show-arrows height="66" v-if="entities.length">

                            <v-tab v-for="(entity, i) in modifiedEntities" :key="i">
                                <v-list-item two-line class="px-0" v-if="entity.id != 'all-tab'">
                                    <v-list-item-icon class="align-self-center ma-0 mr-3">
                                        <v-icon v-if="entity.type == 'people'" size="32" class="mb-2">mdi-account</v-icon>
                                        <v-icon v-else size="32" class="mb-2">mdi-city</v-icon>
                                    </v-list-item-icon>
                                    <v-list-item-content class="text-left">
                                        <v-list-item-title class="ma-0">
                                            {{ getEntityFriendlyName(entity) }}
                                        </v-list-item-title>
                                        <v-list-item-subtitle class="mb-2">
                                            {{ getFriendlyRoles(entity, entityParts) }}
                                        </v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-list-item>

                                <!-- Only visible on multiple -->
                                <v-list-item class="px-0" v-if="entity.id == 'all-tab'">
                                    <v-list-item-icon class="align-self-center ma-0 mr-3">
                                        <v-icon size="32" class="mb-3">mdi-account-group</v-icon>
                                    </v-list-item-icon>
                                    <v-list-item-content class="text-left">
                                        <v-list-item-title class="ma-0 mb-2">
                                            All
                                        </v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-tab>

                        </v-tabs>

                        <FOTable @next="handleNext" @validate="handleValidate" @add="handleAdd" @saved="reset" :is-disabled="isDisabled" :read-only="isReadOnly" />

                        <v-alert dense outlined type="error" class="mt-4" v-if="hasErrors">
                            <div v-for="msg in validationMsg" v-html="msg"></div>
                        </v-alert>


                    </v-col>
                </v-row>
            </v-container>
        </v-card-text>

        <v-dialog
            v-model="dialog"
            persistent
            scrollable
            max-width="1124px">

            <v-card tile flat class="rounded-lg flex-dialog">
                
                <v-progress-linear color="secondary" indeterminate stream absolute top v-if="progress"></v-progress-linear>

                <v-card-title class="pb-0">
                    <v-spacer/>
                    <v-btn icon @click="handleClose">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-card-title>

                <v-card-text class="pa-0">

                <FOIncomeEditor v-if="dialogType == 'income'">
                </FOIncomeEditor>

                <FOEditor v-if="dialogType == 'fo'">
                </FOEditor>

                </v-card-text>

                <!-- NOTE: Quick solution to modal fixed footer -->
                <PortalTarget name="fo-editor-actions">
                </PortalTarget>

            </v-card>

        </v-dialog>

    </v-card>
</template>

<script>

    import { mapActions, mapGetters, mapMutations } from 'vuex'
    import {store, actions} from '~/components/v1/FinancialObjectsEditor1/__store'
    import shared from '~/components/v1/FinancialObjectsEditor1/__shared'

    import subscriptions from '~/components/shared/subscriptions'
    import FOIncomeEditor from '~/components/v1/FinancialObjectsEditor1/_FOIncomeEditor'
    import FOEditor from '~/components/v1/FinancialObjectsEditor1/_FOEditor'
    import FOTable from '~/components/v1/FinancialObjectsEditor1/_FOTable'
    import StepHeader1 from '~/components/v1/StepHeader1'

    export default {
        name: 'FinancialObjectsEditor1',
        mixins: [subscriptions, shared],
        components: {
            FOIncomeEditor,
            FOEditor,
            FOTable,
            StepHeader1,
        },
        data() {
            return {
                tabsData: [],
                valid: false,
                errors: [],
            }
        },
        computed: {
            modifiedEntities(){

                let allTab = {
                    id: 'all-tab'
                }

                if(this.entities.length > 1){

                    return [
                        ...this.entities,
                        allTab
                    ] 
                }

                return [
                    ...this.entities
                ] 

            },
            getLoanId(){
                return this.appData?.relationships?.loan?.data?.id
            },
            dialog(){
                return store.dialog
            },
            dialogType(){
                return store.dialogType
            },
            progress(){
                return store.saving
            },
            validationMsg(){

                let msg = []

                let index = this.selectedEntity
                let currentEntity = null
                
                // console.log(index)
                // console.log(this.entities[index])

                if(this.entities[index]){
                    currentEntity = this.entities[index];
                }

                this.errors.forEach(error => {
                    
                    if(currentEntity){

                        if(error.owner.id == currentEntity.id){
                            msg.push(error.message)
                        }

                    } else {
                        msg.push(error.message)
                    } 

                })
                
                return msg
            },
            hasErrors(){

                return this.validationMsg.length > 0

            },
            stepper(){
                return store.stepper
            },
            isReadOnly(){
                return this.blockPermissionsReadOnly
            }

        },
        mounted(){

            actions.setKindsIncome(this.getKindsIncome)

        },
        methods: {
            ...mapActions('flows', ['selectNextStep']),
            ...mapActions('dialogs', [
                'evaluateSteps'
            ]),
            ...mapActions('resource-actions', [
                'getPeople',
                'getBusinesses',
                'getAddresses',
                'getLoan'
            ]),
            async pull(){

                try {

                    actions.resetAll()

                    let promise1 = this.getPeople({"include" : 'parent-parts,incomes,assets,liabilities,expenses,occupancies'})

                    let promise2 = this.getAddresses()

                    let promise3 = this.getLoan(this.getLoanId)

                    let promise4 = this.getBusinesses({"include" : 'parent-parts,incomes,assets,liabilities,expenses'})

                    const res = await Promise.all([promise1, promise2, promise3, promise4]) 

                    let people = this.getRequiredPeopleByRoles(res[0].data, res[3].data)
                    let businesses = this.getRequiredBusinessesByRoles(res[3].data)

                    let parts = this.getRequiredParts([res[0].data, res[3].data])

                    let requiredResources = this.getRequiredResources([res[3].data, res[0].data])

                    // let addresses = this.getRequiredAddresses(res[1].data)
                    let addresses = this.getAllAddresses(res[1].data)
                    
                    let occupancies = this.getAllOccupancies(res[0].data)

                    let loan = res[2]

                    if(loan.data?.data?.attributes){

                        let loanType = (loan.data.data.attributes["finance-for-business-purposes"] == 'Yes') ? 'Commercial' : 'Consumer';

                        let appType = this.getKindName(loan.data.data.relationships.kind.data.id)
                        
                        actions.setLoanType(loanType)
                        actions.setAppType(appType)
                    }
                    
                    actions.setPeople(people)
                    actions.setBusinesses(businesses)
                    actions.setResources(requiredResources)
                    // actions.setAddresses(addresses)
                    actions.setAllAddresses(addresses)
                    // set all Occupancies
                    actions.setAllOccupancies(occupancies)

                    let entities = [
                        ...businesses,
                        ...people
                    ]

                    actions.setEntities(this.sortEntities(entities))
                    actions.setEntityParts(parts)
                    
                    this.runChecklist()

                } catch (error) {

                    console.log('Ooops!..', error)

                }
            },
            async push(){

                store.validate()

                let valid = this.errors.length == 0

                if(!valid){
                    this.slideToError()
                }

                let status = valid ?  'valid' : 'dirty'

                return {valid, status, data:{}}
                
            },
            open(type){

                actions.setDialogType(type)
                actions.openDialog()

            },
            handleClose(){

                actions.closeDialog()

                setTimeout( () => {

                    if(this.dialogType == 'income'){
                        actions.resetIncomeEditorDialog()
                    } else {
                        actions.resetFOEditorDialog()
                    }

                }, 300)
            },
            getKindById(id){

                let kind = this.kindsUnformat.find( kind => kind.id == id)

                if(kind){
                    return kind
                }

                return null

            },
            handleNext(errors){

                this.errors = errors

                // this.selectNextStep()
                this.evaluateSteps()
            },
            handleValidate(errors){

                this.errors = errors

                this.runChecklist()

            },
            reset(){

                this.valid = false
            },
            handleAdd(type){


                if(type == 'incomes'){

                    this.open('income')
                    this.initializeIncomeEntityLogic()
                
                } else {

                    actions.setPreset(type)
                    this.open('fo')

                }

                // If Selected Entity is "All"
                if(this.entities.length == this.selectedEntity){
                    actions.setSelectedEntityAll(true)
                }


            },
            slideToError(){

                if(this.errors[0]){

                    let error = this.errors[0]
                    let index = 0

                    this.entities.forEach((entity, i) => {
                        if(error.owner.id == entity.id){
                            index = i
                        }
                    })

                    actions.setSelectedEntity(index)

                    let foIndex = {
                        'incomes': 0,
                        'assets': 1,
                        'liabilities': 2,
                        'expenses': 3,
                        'all': 4,
                    }

                    actions.setSelectedFO(foIndex[error.tab])

                }

            },

            ...mapMutations('requirements-checklist', [
                'setRequirementChecklistTemplateLess',
            ]),
            runChecklist(){

                let checklist = this.checklistCollection()

                // console.log('checklist', checklist)

                this?.entities.forEach( entity => {

                    const resources = checklist.filter(item => item.owner.id == entity.id)

                    this.updateRequirementChecklistFOESidebar({
                        entity,
                        resources
                    })
                })

            },
            updateRequirementChecklistFOESidebar({entity, resources = []}){
                this.$nextTick( async () => {

                    let obj = {
                        "id": entity.id,
                        "entity": entity,
                        "sections": []
                    }

                    let has3YearIncomeError = false
                    let hasIncomeError = false
                    let hasLivingExpenseError = false

                    let assetErrors = []
                    let liabilityErrors = []
                    let expenseErrors = []

                    let touched = this.hasFORecord

                    resources.forEach( record => {

                        // Incomes
                        if(record.message.includes("Please provide 3 years minimum employment history.")) {
                            has3YearIncomeError = true
                        }

                        if(record.message.includes("must have complete <strong>Start</strong> and <strong>End Dates</strong>.")) {
                            hasIncomeError = true
                        }

                        // Assets
                        if(record.message.includes("asset is required.")) {
                            assetErrors.push(record)
                        }

                        // Liabilities
                        if(record.message.includes("liability is required.")) {
                            liabilityErrors.push(record)
                        }

                        // Expenses
                        if(record.message.includes("must have Living Expenses")) {
                            hasLivingExpenseError = true
                        }

                        if(record.message.includes("expense is required.")) {
                            expenseErrors.push(record)
                        }
                    });

                    // Required Incomes
                    let requiredIncomeRecords = {
                        "has-income": {
                            value: !hasIncomeError,
                            id: "has-income",
                            name: "Must have an income",
                            valid: !hasIncomeError,
                            touched: touched,
                            required: true,
                        },
                        "three-year-history": {
                            value: !has3YearIncomeError,
                            id: "three-year-history",
                            name: "At least 3 years of income history ",
                            valid: !has3YearIncomeError,
                            touched: touched,
                            required: true,
                        },
                    }
                        
                    obj.sections.push({
                        title: "Incomes",
                        requirements: requiredIncomeRecords
                    })

                    // Required Assets
                    let requiredAssetRecords = {}
                    if(assetErrors.length){
                        assetErrors.forEach((asset, index) => {

                            let slug = asset.name.toLowerCase().split(' ').join('-') + '-' + index
      
                            requiredAssetRecords[slug] = {
                                value: asset.name,
                                id: slug,
                                name: asset.name,
                                valid: asset.valid,
                                touched: touched,
                                required: true,
                            }

                            const kinds = ['10-14'] // Investment Property 
                            const assetKindId = asset?.resource?.relationships?.kind?.data?.id

                            if(kinds.includes(assetKindId)) {

                                const addressId = asset?.resource?.relationships?.address?.data?.id
                                const address = store.allAddresses.find( address => address?.id == addressId)

                                if(address){
                                    requiredAssetRecords[slug]['info-1'] = this.getStreet(address)
                                }

                            }


                        })
                    }

                    if(Object.keys(requiredAssetRecords).length){
                        obj.sections.push({
                            title: "Assets",
                            requirements: requiredAssetRecords
                        })
                    }

                    // Required Liabilities
                    let requiredLiabilityRecords = {}
                    if(liabilityErrors.length){
                        liabilityErrors.forEach((liability, index) => {
                            let slug = liability.name.toLowerCase().split(' ').join('-') + '-' + index
                            requiredLiabilityRecords[slug] = {
                                value: liability.name,
                                id: slug,
                                name: liability.name,
                                valid: liability.valid,
                                touched: touched,
                                required: true,
                            }

                            const kinds = ['13-19', '13-20', '13-3'] // Line of Credit - Secured,  Mortgage - Investment, Credit Card
                            const liabilityKindId = liability?.resource?.relationships?.kind?.data?.id

                            if(kinds.includes(liabilityKindId)) {

                                const financialInstitution = liability?.resource?.attributes?.['financial-institution']
                                const amountFinanced = liability?.resource?.attributes?.['amount-financed']
                                const creditLimit = liability?.resource?.attributes?.['credit-limit']
                                
                                requiredLiabilityRecords[slug]['info-1'] = financialInstitution
                                
                                if(liabilityKindId == '13-3') { // show credit limit if liability kind is Credit Card
                                    requiredLiabilityRecords[slug]['info-2'] = this.currency(creditLimit)
                                } else {
                                    requiredLiabilityRecords[slug]['info-2'] = this.currency(amountFinanced)
                                }

                            }

                        })
                    }

                    if(Object.keys(requiredLiabilityRecords).length){
                        obj.sections.push({
                            title: "Liabilities",
                            requirements: requiredLiabilityRecords
                        })
                    }

                    // Required Expenses
                    let requiredExpenseRecords = {}

                    if(this.loanType == 'Consumer'){
                        requiredExpenseRecords["has-living-expense"] = {
                                value: !hasLivingExpenseError,
                                id: "has-living-expense",
                                name: "Living Expense",
                                valid: !hasLivingExpenseError,
                                required: true,
                        }
                    }

                    if(expenseErrors.length){
                        expenseErrors.forEach((expense, index) => {
                            let slug = expense.name.toLowerCase().split(' ').join('-') + '-' + index
                            requiredExpenseRecords[slug] = {
                                value: expense.name,
                                id: slug,
                                name: expense.name,
                                valid: expense.valid,
                                touched: touched,
                                required: true,
                            }

                            const kinds = ['11-29', '11-30', '11-11'] // Line of Credit - Secured,  Mortgage - Investment, Credit Card
                            const expenseKindId = expense?.resource?.relationships?.kind?.data?.id

                            if(kinds.includes(expenseKindId)) {

                                const financialInstitution = expense?.resource?.attributes?.['financial-institution']
                                const paymentAmount = expense?.resource?.attributes?.['payment-amount']
                                
                                requiredExpenseRecords[slug]['info-1'] = financialInstitution
                                requiredExpenseRecords[slug]['info-2'] = this.currency(paymentAmount)
                            }

                        })
                    }

                    if(Object.keys(requiredExpenseRecords).length){
                        obj.sections.push({
                            title: "Expenses",
                            requirements: requiredExpenseRecords
                        })
                    }

                    this.setRequirementChecklistTemplateLess(obj)
                    
                })
            }, 
        },

        watch: {

        },

    }
</script>

<style lang="scss" scoped>

</style>