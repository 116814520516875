var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"mx-auto",attrs:{"tile":"","flat":""}},[_c('v-card-text',{staticClass:"pa-4 pt-0 pa-md-10 pt-md-0"},[_c('ValidationObserver',{ref:"observer",attrs:{"tag":"form"}},[_c('div',{staticClass:"flex-form"},[_c('div',{staticClass:"flex-field"},[_c('ValidationProvider',{attrs:{"name":_vm.labels['dealType'],"rules":"required","vid":_vm.labels['dealType']},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"items":_vm.getOptions('dealType'),"error-messages":errors,"color":"secondary","label":_vm.labels['dealType']},model:{value:(_vm.resources.loan.attributes.lender.westpac.dealType),callback:function ($$v) {_vm.$set(_vm.resources.loan.attributes.lender.westpac, "dealType", $$v)},expression:"resources.loan.attributes.lender.westpac.dealType"}})]}}])})],1),_vm._v(" "),_c('div',{staticClass:"flex-field"},[_c('ValidationProvider',{attrs:{"name":_vm.labels['firmType'],"rules":"required","vid":_vm.labels['firmType']},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"items":_vm.getOptions('firmType'),"error-messages":errors,"color":"secondary","label":_vm.labels['firmType']},model:{value:(_vm.resources.loan.attributes.lender.westpac.firmType),callback:function ($$v) {_vm.$set(_vm.resources.loan.attributes.lender.westpac, "firmType", $$v)},expression:"resources.loan.attributes.lender.westpac.firmType"}})]}}])})],1),_vm._v(" "),_c('div',{staticClass:"flex-field"},[_c('ValidationProvider',{attrs:{"name":_vm.labels['financeType'],"rules":"required","vid":_vm.labels['financeType']},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"items":_vm.getOptions('financeType'),"error-messages":errors,"color":"secondary","label":_vm.labels['financeType']},model:{value:(_vm.resources.loan.attributes.lender.westpac.financeType),callback:function ($$v) {_vm.$set(_vm.resources.loan.attributes.lender.westpac, "financeType", $$v)},expression:"resources.loan.attributes.lender.westpac.financeType"}})]}}])})],1),_vm._v(" "),_c('div',{staticClass:"flex-field"},[_c('ValidationProvider',{attrs:{"name":_vm.labels['repaymentIn'],"rules":"required","vid":_vm.labels['repaymentIn']},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"items":_vm.getOptions('repaymentIn'),"error-messages":errors,"color":"secondary","label":_vm.labels['repaymentIn']},model:{value:(_vm.resources.loan.attributes.lender.westpac.repaymentIn),callback:function ($$v) {_vm.$set(_vm.resources.loan.attributes.lender.westpac, "repaymentIn", $$v)},expression:"resources.loan.attributes.lender.westpac.repaymentIn"}})]}}])})],1)])]),_vm._v(" "),_c('div',{staticClass:"pb-12"})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }