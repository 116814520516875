export default {
    "lender-pepper-occupation": [
        "Chief Executive or Managing Director",
        "Corporate General Manager",
        "Defence Force Senior Officer",
        "Local Government Legislator",
        "Member of Parliament",
        "Legislators nec",
        "Aquaculture Farmer",
        "Cotton Grower",
        "Flower Grower",
        "Fruit or Nut Grower",
        "Grain, Oilseed or Pasture Grower (Aus) Field Crop Grower (NZ)",
        "Grape Grower",
        "Mixed Crop Farmer",
        "Sugar Cane Grower",
        "Turf Grower",
        "Vegetable Grower (Aus) Market Gardener (NZ)",
        "Crop Farmers nec",
        "Apiarist",
        "Beef Cattle Farmer",
        "Dairy Cattle Farmer",
        "Deer Farmer",
        "Goat Farmer",
        "Horse Breeder",
        "Mixed Livestock Farmer",
        "Pig Farmer",
        "Car Detailer",
        "Commercial Cleaner",
        "Poultry Farmer",
        "Domestic Cleaner",
        "Sheep Farmer",
        "Livestock Farmers nec",
        "Commercial Housekeeper",
        "Domestic Housekeeper",
        "Mixed Crop and Livestock Farmer",
        "Laundry Worker (General)",
        "Drycleaner",
        "Ironer or Presser",
        "code retired (ANZSCO Revision 1)",
        "Carpet Cleaner",
        "Window Cleaner",
        "Cleaners nec",
        "Sales and Marketing Manager",
        "Advertising Manager",
        "Builder's Labourer",
        "Public Relations Manager",
        "Drainage, Sewerage and Stormwater Labourer",
        "Earthmoving Labourer",
        "Plumber's Assistant",
        "Concreter",
        "Corporate Services Manager",
        "Fencer",
        "Finance Manager",
        "Building Insulation Installer",
        "Home Improvement Installer",
        "Paving and Surfacing Labourer",
        "Human Resource Manager",
        "Railway Track Worker",
        "Construction Rigger",
        "Policy and Planning Manager",
        "Scaffolder",
        "Research and Development Manager",
        "Construction Project Manager",
        "Project Builder",
        "Steel Fixer",
        "Structural Steel Erector",
        "Engineering Manager",
        "Crane Chaser",
        "Driller's Assistant",
        "Lagger",
        "Mining Support Worker",
        "Surveyor's Assistant",
        "Importer or Exporter",
        "Wholesaler",
        "Manufacturer",
        "Baking Factory Worker",
        "Brewery Worker",
        "Confectionery Maker",
        "Dairy Products Maker",
        "Fruit and Vegetable Factory Worker",
        "Grain Mill Worker",
        "Production Manager (Forestry)",
        "Sugar Mill Worker",
        "Winery Cellar Hand",
        "Production Manager (Manufacturing)",
        "Food and Drink Factory Workers nec",
        "Production Manager (Mining)",
        "Meat Boner and Slicer",
        "Slaughterer",
        "Supply and Distribution Manager",
        "Procurement Manager",
        "Meat Process Worker",
        "Poultry Process Worker",
        "Seafood Process Worker",
        "Child Care Centre Manager",
        "Chocolate Packer",
        "Container Filler",
        "Fruit and Vegetable Packer",
        "Meat Packer",
        "Medical Administrator (Aus) Medical Superintendent (NZ)",
        "Seafood Packer",
        "Packers nec",
        "Product Assembler",
        "Nursing Clinical Director",
        "Primary Health Organisation Manager",
        "Welfare Centre Manager",
        "Health and Welfare Services Managers nec",
        "School Principal",
        "Metal Engineering Process Worker",
        "Plastics Factory Worker",
        "Rubber Factory Worker",
        "Faculty Head",
        "Regional Education Manager",
        "Product Examiner",
        "Education Managers nec",
        "Product Grader",
        "Product Tester",
        "Paper and Pulp Mill Worker",
        "Sawmill or Timber Yard Worker",
        "Wood and Wood Products Factory Worker",
        "Chief Information Officer",
        "ICT Project Manager",
        "Cement and Concrete Plant Worker",
        "Chemical Plant Worker",
        "ICT Managers nec",
        "Clay Processing Factory Worker",
        "Fabric and Textile Factory Worker",
        "Footwear Factory Worker",
        "Glass Processing Worker",
        "Hide and Skin Processing Worker",
        "Recycling Worker",
        "Factory Process Workers nec",
        "Commissioned Defense Force Officer",
        "Aquaculture Worker",
        "Commissioned Fire Officer",
        "Fruit or Nut Farm Worker",
        "Fruit or Nut Picker",
        "Commissioned Police Officer",
        "Grain, Oilseed or Pasture Farm Worker (Aus) Field Crop Farm Worker (NZ)",
        "Vegetable Farm Worker (Aus) Market Garden Worker (NZ)",
        "Vegetable Picker",
        "Vineyard Worker",
        "Mushroom Picker",
        "Crop Farm Workers nec",
        "Senior Non-commissioned Defence Force Member",
        "Forestry Worker",
        "Logging Assistant",
        "Tree Faller",
        "Arts Administrator or Manager",
        "Garden Labourer",
        "Horticultural Nursery Assistant",
        "Environmental Manager",
        "Laboratory Manager",
        "Beef Cattle Farm Worker",
        "Dairy Cattle Farm Worker",
        "Quality Assurance Manager",
        "Mixed Livestock Farm Worker",
        "Poultry Farm Worker",
        "Sheep Farm Worker",
        "Sports Administrator",
        "Stablehand",
        "Wool Handler",
        "Specialist Managers nec",
        "Livestock Farm Workers nec",
        "Mixed Crop and Livestock Farm Worker",
        "Hunter-Trapper",
        "code retired (ANZSCO Revision 1)",
        "Pest Controller",
        "Farm, Forestry and Garden Workers nec",
        "Fast Food Cook",
        "Cafe or Restaurant Manager",
        "Pastrycook's Assistant",
        "Food Trades Assistants nec",
        "Kitchenhand",
        "Caravan Park and Camping Ground Manager",
        "Freight Handler (Rail or Road)",
        "Truck Driver's Offsider",
        "Waterside Worker",
        "Shelf Filler",
        "Hotel or Motel Manager",
        "Caretaker",
        "Deck Hand",
        "Fishing Hand",
        "Handyperson",
        "Motor Vehicle Parts and Accessories Fitter (General)",
        "Autoglazier",
        "Exhaust and Muffler Repairer",
        "Radiator Repairer",
        "Tyre Fitter",
        "Licensed Club Manager",
        "Printer's Assistant",
        "Printing Table Worker",
        "Bed and Breakfast Operator",
        "Retirement Village Manager",
        "Recycling or Rubbish Collector",
        "Vending Machine Attendant",
        "Accommodation and Hospitality Managers nec",
        "Bicycle Mechanic",
        "Car Park Attendant",
        "Crossing Supervisor",
        "Electrical or Telecommunications Trades Assistant",
        "Leaflet or Newspaper Deliverer",
        "Mechanic's Assistant",
        "Railways Assistant",
        "Sign Erector",
        "Ticket Collector or Usher",
        "Trolley Collector",
        "Road Traffic Controller",
        "Labourers nec",
        "Ambulance Officer",
        "Intensive Care Ambulance Paramedic (Aus) Ambulance Paramedic (NZ)",
        "Retail Manager (General)",
        "Antique Dealer",
        "Betting Agency Manager",
        "Hair or Beauty Salon Manager",
        "Post Office Manager",
        "Travel Agency Manager",
        "Amusement Centre Manager",
        "Fitness Centre Manager",
        "Sports Centre Manager",
        "Call or Contact Centre Manager",
        "Customer Service Manager",
        "Conference and Event Organiser",
        "Dental Hygienist",
        "Fleet Manager",
        "Railway Station Manager",
        "Dental Prosthetist",
        "Dental Technician",
        "Transport Company Manager",
        "Dental Therapist",
        "Boarding Kennel or Cattery Operator",
        "Cinema or Theatre Manager",
        "Facilities Manager",
        "Diversional Therapist",
        "Financial Institution Branch Manager",
        "Equipment Hire Manager",
        "Enrolled Nurse",
        "Hospitality, Retail and Service Managers nec",
        "Mothercraft Nurse",
        "Aboriginal and Torres Strait Islander Health Worker",
        "Actor",
        "Kaiawhina (Hauora) (Maori Health Assistant)",
        "Dancer or Choreographer",
        "Entertainer or Variety Artist",
        "Actors, Dancers and Other Entertainers nec",
        "Massage Therapist",
        "Composer",
        "Music Director",
        "Community Worker",
        "Musician (Instrumental)",
        "Disabilities Services Officer",
        "Singer",
        "Family Support Worker",
        "Music Professionals nec",
        "Parole or Probation Officer",
        "Residential Care Officer",
        "Photographer",
        "Youth Worker",
        "Painter (Visual Arts)",
        "Potter or Ceramic Artist",
        "Sculptor",
        "Visual Arts and Crafts Professionals nec",
        "Artistic Director",
        "Media Producer (excluding Video)",
        "Radio Presenter",
        "Television Presenter",
        "Author",
        "Book or Script Editor",
        "Art Director (Film, Television or Stage)",
        "Director (Film, Television, Radio or Stage)",
        "Director of Photography",
        "Film and Video Editor",
        "Program Director (Television or Radio)",
        "Child Care Worker",
        "Stage Manager",
        "Family Day Care Worker",
        "Nanny",
        "Technical Director",
        "Video Producer",
        "Out of School Hours Care Worker",
        "Film, Television, Radio and Stage Directors nec",
        "Copywriter",
        "Newspaper or Periodical Editor",
        "Print Journalist",
        "Aboriginal and Torres Strait Islander Education Worker",
        "Radio Journalist",
        "Technical Writer",
        "Television Journalist",
        "Integration Aide",
        "Journalists and Other Writers nec",
        "Kaiawhina Kohanga Reo (Maori Language Nest Assistant)",
        "Kaiawhina Kura Kaupapa Maori (Maori-medium School Assistant)",
        "Preschool Aide",
        "Accountant (General)",
        "Management Accountant",
        "Teachers' Aide",
        "Taxation Accountant",
        "Company Secretary",
        "Corporate Treasurer",
        "External Auditor",
        "Internal Auditor",
        "Aged or Disabled Carer",
        "Dental Assistant",
        "Commodities Trader",
        "Finance Broker",
        "Insurance Broker",
        "Financial Brokers nec",
        "Hospital Orderly",
        "Nursing Support Worker",
        "Financial Market Dealer",
        "Futures Trader",
        "Stockbroking Dealer",
        "Personal Care Assistant",
        "Financial Dealers nec",
        "Therapy Aide",
        "Financial Investment Adviser",
        "Financial Investment Manager",
        "Child or Youth Residential Care Assistant",
        "Hostel Parent",
        "Human Resource Adviser",
        "Refuge Worker",
        "Recruitment Consultant",
        "Workplace Relations Adviser",
        "ICT Trainer",
        "Bar Attendant",
        "Barista",
        "Training and Development Professional",
        "Cafe Worker",
        "Actuary",
        "Mathematician",
        "Statistician",
        "Gaming Worker",
        "Archivist",
        "Gallery or Museum Curator",
        "Hotel Service Manager",
        "Health Information Manager",
        "Records Manager",
        "Economist",
        "Waiter",
        "Intelligence Officer",
        "Bar Useful or Busser",
        "Doorperson or Luggage Porter",
        "Hospitality Workers nec",
        "Policy Analyst",
        "Land Economist",
        "Valuer",
        "Librarian",
        "Defence Force Member - Other Ranks",
        "Management Consultant",
        "Organisation and Methods Analyst",
        "Emergency Service Worker",
        "Electorate Officer",
        "Fire Fighter",
        "Liaison Officer",
        "Migration Agent (Aus) Immigration Consultant (NZ)",
        "Detective",
        "Patents Examiner",
        "Police Officer",
        "Information and Organisation Professionals nec",
        "Prison Officer",
        "Advertising Specialist",
        "Market Research Analyst",
        "Marketing Specialist",
        "Alarm, Security or Surveillance Monitor",
        "Armoured Car Escort",
        "ICT Account Manager",
        "Crowd Controller",
        "ICT Business Development Manager",
        "Private Investigator",
        "ICT Sales Representative",
        "Retail Loss Prevention Officer",
        "Security Consultant",
        "Public Relations Professional",
        "Security Officer",
        "Security Officers and Guards nec",
        "Sales Representative (Industrial Products)",
        "Sales Representative (Medical and Pharmaceutical Products)",
        "Technical Sales Representatives nec",
        "Beauty Therapist",
        "Aeroplane Pilot",
        "Air Traffic Controller",
        "Driving Instructor",
        "Flying Instructor",
        "Funeral Director",
        "Helicopter Pilot",
        "Funeral Workers nec",
        "Air Transport Professionals nec",
        "Gallery or Museum Guide",
        "Tour Guide",
        "Master Fisher",
        "Ship's Engineer",
        "Ship's Master",
        "Ship's Officer",
        "Marine Surveyor",
        "Marine Transport Professionals nec",
        "Natural Remedy Consultant",
        "Weight Loss Consultant",
        "Herbalist (Western)",
        "Architect",
        "Tourist Information Officer",
        "Landscape Architect",
        "Travel Consultant",
        "code retired (ANZSCO Revision 1)",
        "Surveyor",
        "Cartographer",
        "Other Spatial Scientist",
        "Flight Attendant",
        "Travel Attendants nec",
        "Fashion Designer",
        "Industrial Designer",
        "Civil Celebrant",
        "Jewellery Designer",
        "Hair or Beauty Salon Assistant",
        "Graphic Designer",
        "Sex Worker or Escort",
        "Illustrator",
        "Body Artist",
        "Multimedia Designer",
        "First Aid Trainer",
        "Web Designer",
        "Religious Assistant",
        "Personal Service Workers nec",
        "Interior Designer",
        "Urban and Regional Planner",
        "Fitness Instructor",
        "Chemical Engineer",
        "Bungy Jump Master",
        "Materials Engineer",
        "Fishing Guide",
        "Hunting Guide",
        "Civil Engineer",
        "Geotechnical Engineer",
        "Mountain or Glacier Guide",
        "Quantity Surveyor",
        "Outdoor Adventure Instructor",
        "Structural Engineer",
        "Trekking Guide",
        "Transport Engineer",
        "Whitewater Rafting Guide",
        "Electrical Engineer",
        "Outdoor Adventure Guides nec",
        "Electronics Engineer",
        "Industrial Engineer",
        "Mechanical Engineer",
        "Production or Plant Engineer",
        "Diving Instructor (Open Water)",
        "Gymnastics Coach or Instructor",
        "Horse Riding Coach or Instructor",
        "Mining Engineer (excluding Petroleum)",
        "Petroleum Engineer",
        "Snowsport Instructor",
        "Swimming Coach or Instructor",
        "Aeronautical Engineer",
        "Tennis Coach",
        "Agricultural Engineer",
        "Biomedical Engineer",
        "Engineering Technologist",
        "Environmental Engineer",
        "Naval Architect (Aus) Marine Designer (NZ)",
        "Other Sports Coach or Instructor",
        "Engineering Professionals nec",
        "Dog or Horse Racing Official",
        "Sports Development Officer",
        "Sports Umpire",
        "Other Sports Official",
        "Agricultural Consultant",
        "Agricultural Scientist",
        "Forester (Aus) Forest Scientist (NZ)",
        "Footballer",
        "Golfer",
        "Chemist",
        "Jockey",
        "Food Technologist",
        "Wine Maker",
        "Lifeguard",
        "Sportspersons nec",
        "Conservation Officer",
        "Environmental Consultant",
        "Environmental Research Scientist",
        "Park Ranger",
        "Environmental Scientists nec",
        "Geologist",
        "Geophysicist",
        "Hydrogeologist",
        "Life Scientist (General)",
        "code retired",
        "Biochemist",
        "Biotechnologist",
        "Botanist",
        "Marine Biologist",
        "Microbiologist",
        "Zoologist",
        "Clay Products Machine Operator",
        "Life Scientists nec",
        "Medical Laboratory Scientist",
        "Concrete Products Machine Operator",
        "Glass Production Machine Operator",
        "Stone Processing Machine Operator",
        "Clay, Concrete, Glass and Stone Processing Machine Operators nec",
        "Industrial Spraypainter",
        "Paper Products Machine Operator",
        "code retired (ANZSCO Revision 1)",
        "Sawmilling Operator",
        "Conservator",
        "Other Wood Processing Machine Operator",
        "Metallurgist",
        "Photographic Developer and Printer",
        "Meteorologist",
        "Plastic Cablemaking Machine Operator",
        "Physicist",
        "Plastic Compounding and Reclamation Machine Operator",
        "Plastics Fabricator or Welder",
        "Exercise Physiologist",
        "Natural and Physical Science Professionals nec",
        "Plastics Production Machine Operator (General)",
        "Reinforced Plastic and Composite Production Worker",
        "Rubber Production Machine Operator",
        "Plastics and Rubber Production Machine Operators nec",
        "Sewing Machinist",
        "Early Childhood (Pre-primary School) Teacher",
        "Footwear Production Machine Operator",
        "Hide and Skin Processing Machine Operator",
        "Knitting Machine Operator",
        "Textile Dyeing and Finishing Machine Operator",
        "Kaiako Kohanga Reo (Maori Language Nest Teacher)",
        "Weaving Machine Operator",
        "Yarn Carding and Spinning Machine Operator",
        "Kaiako Kura Kaupapa Maori (Maori-medium Primary School Teacher)",
        "Textile and Footwear Production Machine Operators nec",
        "Pouako Kura Kaupapa Maori (Maori-medium Primary School Senior Teacher)",
        "Chemical Production Machine Operator",
        "Primary School Teacher",
        "Motion Picture Projectionist",
        "Sand Blaster",
        "Sterilisation Technician",
        "Machine Operators nec",
        "Middle School Teacher (Aus) Intermediate School Teacher (NZ)",
        "Contract Administrator",
        "Crane, Hoist or Lift Operator",
        "Program or Project Administrator",
        "Secondary School Teacher",
        "Driller",
        "Miner",
        "Shot Firer",
        "Special Needs Teacher",
        "Engineering Production Worker",
        "Teacher of the Hearing Impaired",
        "Office Manager",
        "Boiler or Engine Operator",
        "Teacher of the Sight Impaired",
        "Bulk Materials Handling Plant Operator",
        "Cement Production Plant Operator",
        "Concrete Batching Plant Operator",
        "Health Practice Manager",
        "Concrete Pump Operator",
        "Paper and Pulp Mill Operator",
        "Special Education Teachers nec",
        "Practice Managers nec",
        "Railway Signal Operator",
        "Train Controller",
        "Waste Water or Water Plant Operator",
        "Weighbridge Operator",
        "Stationary Plant Operators nec",
        "Personal Assistant",
        "Auctioneer",
        "University Lecturer",
        "Stock and Station Agent",
        "Secretary (General)",
        "University Tutor",
        "Insurance Agent",
        "Legal Secretary",
        "Sales Representative (Building and Plumbing Supplies)",
        "Sales Representative (Business Services)",
        "Vocational Education Teacher (Aus) Polytechnic Teacher (NZ)",
        "Sales Representative (Motor Vehicle Parts and Accessories)",
        "Sales Representative (Personal and Household Goods)",
        "Sales Representatives nec",
        "General Clerk",
        "Education Adviser",
        "Education Reviewer",
        "Art Teacher (Private Tuition)",
        "Data Entry Operator",
        "Dance Teacher (Private Tuition)",
        "Business Broker",
        "Machine Shorthand Reporter",
        "Property Manager",
        "Real Estate Agency Principal (Aus) Real Estate Agency Licensee (NZ)",
        "Drama Teacher (Private Tuition)",
        "Word Processing Operator",
        "Real Estate Agent",
        "Music Teacher (Private Tuition)",
        "Real Estate Representative",
        "Private Tutors and Teachers nec",
        "Call or Contact Centre Team Leader",
        "Call or Contact Centre Operator",
        "Teacher of English to Speakers of Other Languages",
        "Information Officer",
        "Dietitian",
        "Nutritionist",
        "Medical Diagnostic Radiographer",
        "Medical Radiation Therapist",
        "Receptionist (General)",
        "Sales Assistant (General)",
        "Nuclear Medicine Technologist",
        "Admissions Clerk",
        "Sonographer",
        "ICT Sales Assistant",
        "Motor Vehicle or Caravan Salesperson",
        "Hotel or Motel Receptionist",
        "Motor Vehicle Parts Interpreter (Aus) Automotive Parts Salesperson (NZ)",
        "Environmental Health Officer",
        "Medical Receptionist",
        "Occupational Health and Safety Adviser",
        "Pharmacy Sales Assistant",
        "Retail Supervisor",
        "Optometrist",
        "Service Station Attendant",
        "Orthoptist",
        "Cash Van Salesperson",
        "Door-to-door Salesperson",
        "Street Vendor",
        "Hospital Pharmacist",
        "Industrial Pharmacist",
        "Materials Recycler",
        "Retail Pharmacist",
        "Rental Salesperson",
        "Sales Assistants and Salespersons nec",
        "Accounts Clerk",
        "Cost Clerk",
        "Health Promotion Officer",
        "Orthotist or Prosthetist",
        "Checkout Operator",
        "Bookkeeper",
        "Health Diagnostic and Promotion Professionals nec",
        "Payroll Clerk",
        "Chiropractor",
        "Office Cashier",
        "Osteopath",
        "Acupuncturist",
        "Homoeopath",
        "Naturopath",
        "Bank Worker",
        "Traditional Chinese Medicine Practitioner",
        "Traditional Maori Health Practitioner",
        "Complementary Health Therapists nec",
        "Credit or Loans Officer (Aus) Finance Clerk (NZ)",
        "Model",
        "Sales Demonstrator",
        "Bookmaker",
        "Retail Buyer",
        "Insurance Consultant",
        "Wool Buyer",
        "Money Market Clerk",
        "Telemarketer",
        "Statistical Clerk",
        "Dental Specialist",
        "Dentist",
        "Ticket Seller",
        "Transport Conductor",
        "Visual Merchandiser",
        "Occupational Therapist",
        "Other Sales Support Worker",
        "Physiotherapist",
        "Podiatrist",
        "Audiologist",
        "Speech Pathologist (Aus) Speech Language Therapist (NZ)",
        "General Practitioner",
        "Resident Medical Officer",
        "Betting Agency Counter Clerk",
        "Bookmaker's Clerk",
        "Anaesthetist",
        "Telephone Betting Clerk",
        "Betting Clerks nec",
        "Specialist Physician (General Medicine)",
        "Cardiologist",
        "Clinical Haematologist",
        "Medical Oncologist",
        "Endocrinologist",
        "Gastroenterologist",
        "Intensive Care Specialist",
        "Neurologist",
        "Paediatrician",
        "Renal Medicine Specialist",
        "Rheumatologist",
        "Thoracic Medicine Specialist",
        "Specialist Physicians nec",
        "Courier",
        "Postal Delivery Officer",
        "Psychiatrist",
        "Filing or Registry Clerk",
        "Mail Clerk",
        "Postal Sorting Officer",
        "Surgeon (General)",
        "Cardiothoracic Surgeon",
        "Neurosurgeon",
        "Orthopaedic Surgeon",
        "Otorhinolaryngologist",
        "Paediatric Surgeon",
        "Plastic and Reconstructive Surgeon",
        "Urologist",
        "Vascular Surgeon",
        "Dermatologist",
        "Emergency Medicine Specialist",
        "Obstetrician and Gynaecologist",
        "Ophthalmologist",
        "Pathologist",
        "code retired (ANZSCO Revision 1)",
        "Diagnostic and Interventional Radiologist",
        "Radiation Oncologist",
        "Medical Practitioners nec",
        "Midwife",
        "Nurse Educator",
        "Nurse Researcher",
        "Nurse Manager",
        "Nurse Practitioner",
        "Registered Nurse (Aged Care)",
        "Registered Nurse (Child and Family Health)",
        "Registered Nurse (Community Health)",
        "Registered Nurse (Critical Care and Emergency)",
        "Registered Nurse (Developmental Disability)",
        "Registered Nurse (Disability and Rehabilitation)",
        "Registered Nurse (Medical)",
        "Registered Nurse (Medical Practice)",
        "Registered Nurse (Mental Health)",
        "Registered Nurse (Perioperative)",
        "Registered Nurse (Surgical)",
        "Registered Nurse (Paediatrics)",
        "Registered Nurses nec",
        "ICT Business Analyst",
        "Systems Analyst",
        "Multimedia Specialist",
        "Web Developer",
        "Analyst Programmer",
        "Developer Programmer",
        "Software Engineer",
        "Software Tester",
        "Software and Applications Programmers nec",
        "Database Administrator",
        "ICT Security Specialist",
        "Systems Administrator",
        "Computer Network and Systems Engineer",
        "Network Administrator",
        "Network Analyst",
        "Survey Interviewer",
        "Switchboard Operator",
        "Classified Advertising Clerk",
        "Meter Reader",
        "Parking Inspector",
        "Clerical and Office Support Workers nec",
        "ICT Quality Assurance Engineer",
        "ICT Support Engineer",
        "code retired (ANZSCO Revision 1)",
        "Production Clerk",
        "ICT Systems Test Engineer",
        "ICT Support and Test Engineers nec",
        "Purchasing Officer",
        "code retired (ANZSCO Revision 1)",
        "Stock Clerk",
        "Warehouse Administrator",
        "Telecommunications Engineer",
        "Order Clerk",
        "Telecommunications Network Engineer",
        "Despatching and Receiving Clerk",
        "Import-Export Clerk",
        "Conveyancer",
        "Legal Executive",
        "Clerk of Court",
        "Court Bailiff or Sheriff (Aus) Court Collections Officer (NZ)",
        "Court Orderly (Aus) Court Registry Officer (NZ)",
        "Law Clerk",
        "Trust Officer",
        "Debt Collector",
        "Barrister",
        "Human Resource Clerk",
        "Judge",
        "Magistrate",
        "Customs Officer",
        "Immigration Officer",
        "Tribunal Member",
        "Motor Vehicle Licence Examiner",
        "Intellectual Property Lawyer",
        "Noxious Weeds and Pest Inspector",
        "Social Security Assessor",
        "Judicial and Other Legal Professionals nec",
        "Taxation Inspector",
        "Train Examiner",
        "Transport Operations Inspector",
        "Solicitor",
        "Water Inspector",
        "Inspectors and Regulatory Officers nec",
        "Insurance Investigator",
        "Insurance Loss Adjuster",
        "Insurance Risk Surveyor",
        "Careers Counsellor",
        "Library Assistant",
        "Family and Marriage Counsellor",
        "Drug and Alcohol Counsellor",
        "code retired (ANZSCO Revision 1)",
        "Rehabilitation Counsellor",
        "Production Assistant (Film, Television, Radio or Stage)",
        "Student Counsellor",
        "Proof Reader",
        "Counsellors nec",
        "Radio Despatcher",
        "Clinical Coder",
        "Minister of Religion",
        "Facilities Administrator",
        "Clerical and Administrative Workers nec",
        "Clinical Psychologist",
        "Educational Psychologist",
        "Organisational Psychologist",
        "Psychotherapist",
        "Psychologists nec",
        "Historian",
        "Interpreter",
        "Translator",
        "Archaeologist",
        "Agricultural Technician",
        "Social Professionals nec",
        "Anaesthetic Technician",
        "Cardiac Technician",
        "Medical Laboratory Technician",
        "Operating Theatre Technician",
        "Pharmacy Technician",
        "Social Worker",
        "Pathology Collector (Aus) Phlebotomist (NZ)",
        "Medical Technicians nec",
        "Community Arts Worker",
        "Recreation Officer (Aus) Recreation Coordinator (NZ)",
        "Fisheries Officer",
        "Welfare Worker",
        "Meat Inspector",
        "Quarantine Officer",
        "Primary Products Inspectors nec",
        "Chemistry Technician",
        "Earth Science Technician",
        "Life Science Technician",
        "School Laboratory Technician",
        "Hairdresser",
        "Hydrographer",
        "Science Technicians nec",
        "Print Finisher",
        "Screen Printer",
        "Graphic Pre-press Trades Worker",
        "Architectural Draftsperson",
        "Printing Machinist",
        "Building Associate",
        "Small Offset Printer",
        "Building Inspector",
        "Construction Estimator",
        "Plumbing Inspector",
        "Surveying or Spatial Science Technician",
        "Architectural, Building and Surveying Technicians nec",
        "Canvas Goods Fabricator",
        "Leather Goods Maker",
        "Civil Engineering Draftsperson",
        "Sail Maker",
        "Civil Engineering Technician",
        "Shoemaker",
        "Electrical Engineering Draftsperson",
        "Apparel Cutter",
        "Electrical Engineering Technician",
        "Clothing Patternmaker",
        "Dressmaker or Tailor",
        "Clothing Trades Workers nec",
        "Electronic Engineering Draftsperson",
        "Electronic Engineering Technician",
        "Upholsterer",
        "Mechanical Engineering Draftsperson",
        "Mechanical Engineering Technician",
        "Safety Inspector",
        "Cabinetmaker",
        "Maintenance Planner",
        "Furniture Finisher",
        "Metallurgical or Materials Technician",
        "Picture Framer",
        "Mine Deputy",
        "Wood Machinist",
        "Building and Engineering Technicians nec",
        "Wood Turner",
        "Wood Machinists and Other Wood Trades Workers nec",
        "Hardware Technician",
        "ICT Customer Support Officer",
        "Boat Builder and Repairer",
        "Web Administrator",
        "Shipwright",
        "ICT Support Technicians nec",
        "Chemical Plant Operator",
        "Radiocommunications Technician",
        "Gas or Petroleum Operator",
        "Telecommunications Field Engineer",
        "Power Generation Plant Operator",
        "Telecommunications Network Planner",
        "Gallery or Museum Technician",
        "Telecommunications Technical Officer or Technologist",
        "Library Technician",
        "Jeweller",
        "Broadcast Transmitter Operator",
        "Automotive Electrician",
        "Camera Operator (Film, Television or Video)",
        "Light Technician",
        "Make Up Artist",
        "Musical Instrument Maker or Repairer",
        "Sound Technician",
        "Television Equipment Operator",
        "Performing Arts Technicians nec",
        "Motor Mechanic (General)",
        "Diesel Motor Mechanic",
        "Signwriter",
        "Motorcycle Mechanic",
        "Small Engine Mechanic",
        "Diver",
        "Interior Decorator",
        "Optical Dispenser (Aus) Dispensing Optician (NZ)",
        "Blacksmith",
        "Optical Mechanic",
        "Electroplater",
        "Photographer's Assistant",
        "Plastics Technician",
        "Farrier",
        "Wool Classer",
        "Metal Casting Trades Worker",
        "Fire Protection Equipment Technician",
        "Metal Polisher",
        "Technicians and Trades Workers nec",
        "Sheetmetal Trades Worker",
        "Metal Fabricator",
        "Pressure Welder",
        "Welder (First Class) (Aus) Welder (NZ)",
        "Dog Handler or Trainer",
        "Horse Trainer",
        "Aircraft Maintenance Engineer (Avionics)",
        "Pet Groomer",
        "Aircraft Maintenance Engineer (Mechanical)",
        "Zookeeper",
        "Aircraft Maintenance Engineer (Structures)",
        "Kennel Hand",
        "Animal Attendants and Trainers nec",
        "Fitter (General)",
        "Shearer",
        "Fitter and Turner",
        "Fitter-Welder",
        "Veterinary Nurse",
        "Metal Machinist (First Class)",
        "Textile, Clothing and Footwear Mechanic",
        "Metal Fitters and Machinists nec",
        "Engraver",
        "Gunsmith",
        "Locksmith",
        "Florist",
        "Precision Instrument Maker and Repairer",
        "Saw Maker and Repairer",
        "Watch and Clock Maker and Repairer",
        "Gardener (General)",
        "Engineering Patternmaker",
        "Arborist",
        "Toolmaker",
        "Landscape Gardener",
        "Greenkeeper",
        "Panelbeater",
        "Nurseryperson",
        "Vehicle Body Builder",
        "Vehicle Trimmer",
        "Vehicle Painter",
        "Baker",
        "Pastrycook",
        "Bricklayer",
        "Butcher or Smallgoods Maker",
        "Stonemason",
        "Carpenter and Joiner",
        "Chef",
        "Carpenter",
        "Joiner",
        "Cook",
        "Floor Finisher",
        "Painting Trades Worker",
        "Electrician (General)",
        "Electrician (Special Class)",
        "Glazier",
        "Lift Mechanic",
        "Fibrous Plasterer",
        "Solid Plasterer",
        "Airconditioning and Refrigeration Mechanic",
        "Roof Tiler",
        "Electrical Linesworker (Aus) Electrical Line Mechanic (NZ)",
        "Wall and Floor Tiler",
        "Technical Cable Jointer",
        "Plumber (General)",
        "Business Machine Mechanic",
        "Airconditioning and Mechanical Services Plumber",
        "Communications Operator",
        "Drainer (Aus) Drainlayer (NZ)",
        "Electronic Equipment Trades Worker",
        "Gasfitter",
        "Electronic Instrument Trades Worker (General)",
        "Roof Plumber",
        "Electronic Instrument Trades Worker (Special Class)",
        "Cabler (Data and Telecommunications)",
        "Telecommunications Cable Jointer",
        "Telecommunications Linesworker (Aus) Telecommunications Line Mechanic (NZ)",
        "Telecommunications Technician",
        "Agricultural and Horticultural Mobile Plant Operator",
        "Logging Plant Operator",
        "Earthmoving Plant Operator (General)",
        "Backhoe Operator",
        "Bulldozer Operator",
        "Excavator Operator",
        "Grader Operator",
        "Loader Operator",
        "Forklift Driver",
        "Aircraft Baggage Handler and Airline Ground Crew",
        "Linemarker",
        "Paving Plant Operator",
        "Railway Track Plant Operator",
        "Road Roller Operator",
        "Streetsweeper Operator",
        "Mobile Plant Operators nec",
        "Chauffeur",
        "Taxi Driver",
        "Automobile Drivers nec",
        "Bus Driver",
        "Charter and Tour Bus Driver",
        "Passenger Coach Driver",
        "Train Driver",
        "Tram Driver",
        "Delivery Driver",
        "Truck Driver (General)",
        "Aircraft Refueller",
        "Furniture Removalist",
        "Tanker Driver",
        "Tow Truck Driver",
        "Storeperson"
    ]
}

