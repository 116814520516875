
/**
 * 
 *  ✔️Mode  |  ✔️Group  | ✔️Theme  | ❌Flow
 * 
 *  
 *   "alias": Used in application wordings. default as "Nodifi" if it doesn't exist for a tenant
 */


export default {
    "data":[
        {
          "slug": "nodifi",
          "name": "Nodifi",
          "platform": "Nodifi",
          "assisters": {
            "default": "LMG Asset Finance Referral Hub",
          },
          "default-theme": "lmg",
          "groups": [
            {
              "slug": "nodifi",
              "default": true
            }
          ]
        },
        {
          "slug": "onlineloans",
          "name": "Online Loans",
          "groups": [
            {
              "slug": "leads",
              "default": true
            }
          ]
        },
        {
          "slug": "choice",
          "name": "Choice",
          "assisters": {
            "default": "LMG Asset Finance Referral Hub",
          },
          "default-theme": "lmg",
          "groups": [
            {
              "slug": "choice",
              "default": true
            }
          ]
        },
        {
          "slug": "fast",
          "name": "Fast",
          "assisters": {
            "default": "LMG Asset Finance Referral Hub",
          },
          "default-theme": "lmg",
          "groups": [
            {
              "slug": "fast",
              "default": true
            }
          ]
        },
        {
          "slug": "loanmarket",
          "name": "Loan Market",
          "platform": "Loan Market Asset Finance", 
          "assisters": {
            "default": "Loan Market Asset Finance", 
          },
          "default-theme": "loan-market-c",
          "groups": [
            {
              "slug": "loanmarket",
              "default": true
            }
          ]
        },
        {
          "slug": "loanmarket1",
          "name": "Loan Market",
          "groups": [
            {
              "slug": "group-a",
              "default": true
            }
          ]
        },
        {
          "slug": "money3",
          "name": "Money 3",
          "groups": [
            {
              "slug": "money3",
              "default": true
            }
          ]
        },
        {
          "slug": "plan",
          "name": "Plan",
          "assisters": {
            "default": "LMG Asset Finance Referral Hub",
          },
          "default-theme": "lmg",
          "groups": [
            {
              "slug": "plan",
              "default": true
            }
          ]
        },
        {
          "slug": "pls",
          "name": "Positive Lending Solutions",
          "groups": [
            {
              "slug": "leads",
              "default": true
            }
          ]
        },
        {
          "slug": "medezi",
          "name": "Medezi",
          "default-theme": "mannheim",
          "groups": [
            {
              "slug": "medezi",
              "default": true
            }
          ]
        },
        {
          "slug": "anchor",
          "name": "Anchor",
          "default-theme": "lmg",
          "groups": [
            {
              "slug": "nodifi",
              "default": true
            }
          ]
        },
        {
          "slug": "nectar",
          "name": "Nectar",
          "default-theme": "nectar-a",
          "groups": [
            {
              "slug": "nectar",
              "default": true
            }
          ]
        },
        {
          "slug": "byob",
          "name": "LOAN MARKET - BYOB",
          "assisters": {
            "default": "LMG Asset Finance Referral Hub",
          },
          "default-theme": "lmg",
          "groups": [
            {
              "slug": "byob",
              "default": true
            }
          ]
        },
        {
          "slug": "lmg",
          "api": "byob",
          "name": "Loan Market Group",
          "default-theme": "lmg",
          "groups": [
            {
              "slug": "lmg",
              "default": true
            }
          ]
        },
        {
          "slug": "lmgaf1",
          "api": "nodifi",
          "name": "Loan Market Group Asset Finance",
          "default-theme": "lmg",
          "groups": [
            {
              "slug": "lmgaf",
              "default": true
            }
          ]
        },
        {
          "slug": "lmgaf2",
          "api": "plan",
          "name": "Loan Market Group Asset Finance",
          "default-theme": "lmg",
          "groups": [
            {
              "slug": "lmgaf",
              "default": true
            }
          ]
        },
        {
          "slug": "lmgaf3",
          "api": "choice",
          "name": "Loan Market Group Asset Finance",
          "default-theme": "lmg",
          "groups": [
            {
              "slug": "lmgaf",
              "default": true
            }
          ]
        },
        {
          "slug": "lmgaf4",
          "api": "fast",
          "name": "Loan Market Group Asset Finance",
          "default-theme": "lmg",
          "groups": [
            {
              "slug": "lmgaf",
              "default": true
            }
          ]
        },
        {
          "slug": "demo",
          "name": "Loan Market Group Asset Finance",
          "default-theme": "lmg",
          "groups": [
            {
              "slug": "demo",
              "default": true
            }
          ]
        },
        {
          "slug": "app",
          "name": "Loan Market Group Asset Finance",
          "default-theme": "lmg",
          "groups": [
            {
              "slug": "lmg",
              "default": true
            }
          ]
        },
    ]
}