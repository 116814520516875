<template>

    <FlexWrap @resize="handleResize">
        <v-card :disabled="isDisabled" class="mx-auto" tile flat>
            <v-card-text class="pa-4 pt-0 pa-md-10 pt-md-0">
                <v-card tile flat class="mx-auto" max-width="500">
                    <v-container pa-0 fluid>
                        <v-row>
                            <v-col cols="12">
                                
                                <div class="flex-range-slider">
                                    <div class="button font-weight-bold">Loan Term Requested (Years)</div>
                                    <v-slider
                                        v-model="term"
                                        :tick-labels="termLabels"
                                        thumb-size="50"
                                        :min="12"
                                        :max="84"
                                        :step="12"
                                        ticks="always"
                                        color="secondary"
                                        class="pa-0 px-2 ma-0 mb-4">
                                        <template #[`thumb-label`]="{}">
                                            {{ term }}mths
                                        </template>
                                    </v-slider>
                                </div>
                                
                                <ValidationObserver ref="observer" tag="form">
                                    <div class="flex-form">
                                        <div 
                                            class="flex-field"
                                            v-for="(field, key) in resources.loan.attributes"
                                            :key="key">

                                            <ValidationProvider
                                                v-slot="{ errors }"
                                                :name="labels[key]"
                                                :hidden="hiddens[key]"
                                                :rules="getRules(key)"
                                                :vid="key">                                                

                                                <v-select
                                                    v-if="isFieldType('picklist', key)"
                                                    v-show="false"
                                                    v-model="resources.loan.attributes[key]"
                                                    :items="options[key]"
                                                    item-text="key"
                                                    item-value="value"
                                                    :error-messages="errors"
                                                    color="secondary"
                                                    :suffix="suffix[key]"
                                                    :label="labels[key]"
                                                    ></v-select>

                                                <v-text-field
                                                    v-else
                                                    v-model="resources.loan.attributes[key]"
                                                    :type="'text'"
                                                    :label="labels[key]"
                                                    :error-messages="errors"
                                                    color="secondary"
                                                ></v-text-field> 

                                            </ValidationProvider>

                                        </div>
                                    </div>
                                </ValidationObserver>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card>
            </v-card-text>
        </v-card>
    </FlexWrap>

</template>

<script>

    import FlexWrap from '~/components/base/FlexWrap'
    import helpers from '~/plugins/helpers'
    import subscriptions from '~/components/shared/subscriptions'
    import { mapActions } from 'vuex'

    export default {
        name: 'LoanEditor1',
        components: { FlexWrap },
        mixins: [subscriptions, helpers],
        computed: {
            getLoanId(){
                return this.appData.relationships.loan.data.id
            },
            getLoanKind(){

                if(this.resources.loan.relationships) {

                    return this.resources.loan.relationships.kind?.data?.id

                }

                return null

            }
        },
        methods: {
            ...mapActions('resource-actions', [
                'getLoan',
                'updateLoan',
                'createLoan'
            ]),

            /**
             * Calling actions: getLoans 
             * from store which trigger an axios call,
             * pulling all data from an api response
             */
            async pull() {

                let res = await this.getLoan(this.getLoanId)
                
                this.resources.loan = this.resourceFieldPicker(this.resources.loan, res.data.data)

                if ( this.resources.loan.attributes['requested-term-months'] ){
                    this.term = parseInt( this.resources.loan.attributes['requested-term-months'] )
                } else {
                    this.resources.loan.attributes['requested-term-months'] = this.term
                }
                    

                if(this.resources.loan.attributes['requested-balloon'] == null){
                    this.resources.loan.attributes['requested-balloon'] = 0
                }

            },

            async push() {

                const observer = this.$refs.observer

                const {loan} = this.resources
                    
                // Exclude resources.relationships and other stuff
                let payload = {
                    type: loan.type,
                    id: loan.id,
                    attributes: this.excludeInvalids(loan.attributes, observer.fields)
                }

                let loanRes = null;

                if (this.getLoanId) {

                    loanRes = this.updateLoan(payload)

                } else {

                    // Include relationships when creating resource

                    payload.relationships = {
                        application: {
                            data: {type: "applications", id: this.appId}
                        }
                    }

                    loanRes = this.createLoan(payload)
                }

                const {valid, status} = await this.validateFormData()
                const data = await this.appDataHelper([loanRes])

                return {valid, status, data}

            }
        },
        watch: {
            getLoanKind(val){

                if(val == '2-5') {
                    this.resources.loan.attributes["requested-payment-terms"] = "In Arrears"
                    this.hiddens["requested-payment-terms"] = true
                }

            },

            term: {
                handler(val) {
                    this.resources.loan.attributes['requested-term-months'] = val.toString()
                },
                deep: true
            }
        },

        data(){
            return {
                term: 60,
                termLabels: ['1', '2', '3', '4', '5', '6', '7'],
                resources: {
                    loan: {
                        type: 'loans',
                        id: null,
                        attributes: {
                            "requested-term-months": '',
                            "requested-balloon": '0',
                            "requested-payment-terms": '',
                        }
                    }
                },
                labels: {
                    "requested-term-months": "Loan Term Requested",
                    "requested-balloon": "Balloon Requested",
                    "requested-payment-terms": "Payment Terms"
                },
                hiddens: {
                    "requested-term-months": false,
                    "requested-balloon": true,
                    "requested-payment-terms": false
                },
                validation_rules: {
                    type: "loans",
                    attributes: {
                        "requested-term-months": "required|in:12,24,36,48,60,72,84",
                        "requested-balloon": "required",
                        "requested-payment-terms": "required|in:In Arrears,In Advance"
                    }
                },
                options: {
                    "requested-term-months": [
                        {key: "1", value: 12},
                        {key: "2", value: 24},
                        {key: "3", value: 36},
                        {key: "4", value: 48},
                        {key: "5", value: 60},
                        {key: "6", value: 72},
                        {key: "7", value: 84}
                    ],
                    "requested-payment-terms": ["In Arrears", "In Advance"]
                },
                suffix: {
                    "requested-term-months": 'years',
                    "requested-payment-terms": undefined
                }
            }
        }
    }
</script>

<style lang="scss" scoped>

    .flex-form {
        display: grid;
        grid-column-gap: 15px;
        grid-row-gap: 0;
        grid-template-columns:  repeat(auto-fit, minmax(260px, 1fr));

        .flex-field {
            align-self: start;
        }
    }

</style>