export default {
    "Consumer - Joint": { 
        "Unsecured": {
            base: "consumer-personal",
            //b2c: "b2c-consumer-personal",
            b2c: "b2c-consumer-1",
            b2b: "b2b-consumer-personal"
        },
        "Asset Finance": {
            base: "consumer-asset",
            //b2c: "b2c-consumer-asset",
            b2c: "b2c-consumer-1",
            b2b: "b2b-consumer-asset"
        }
    },
    "Consumer - Individual": {
        "Unsecured": {
            base: "consumer-personal",
            //b2c: "b2c-consumer-personal",
            b2c: "b2c-consumer-1",
            b2b: "b2b-consumer-personal"
        },
        "Asset Finance": {
            base: "consumer-asset",
            //b2c: "b2c-consumer-asset",
            b2c: "b2c-consumer-1",
            b2b: "b2b-consumer-asset"
        }
    },
    "Consumer - Individual - Non Borrowing Spouse": {
        "Unsecured": {
            base: "consumer-personal",
            //b2c: "b2c-consumer-personal",
            b2c: "b2c-consumer-1",
            b2b: "b2b-consumer-personal"
        },
        "Asset Finance": {
            base: "consumer-asset",
            //b2c: "b2c-consumer-asset",
            b2c: "b2c-consumer-1",
            b2b: "b2b-consumer-asset"
        }
    },
    "Commercial - Company": {
        "Asset Finance": {
            base: 'commercial-asset',
            b2c: 'b2c-commercial-asset',
            b2b: 'b2b-commercial-asset'
        },
        "Unsecured": {
            base: 'commercial-funding',
            b2c: 'b2c-commercial-funding',
            b2b: 'b2b-commercial-funding'
        }
    },
    "Commercial - Partnership": {
        "Asset Finance": {
            base: 'commercial-asset',
            b2c: 'b2c-commercial-asset',
            b2b: 'b2b-commercial-asset'
        },
        "Unsecured": {
            base: 'commercial-funding',
            b2c: 'b2c-commercial-funding',
            b2b: 'b2b-commercial-funding'
        }
    },
    "Commercial - Sole Trader": {
        "Asset Finance": {
            base: 'commercial-individual-asset',
            b2c: 'b2c-commercial-individual-asset',
            b2b: 'b2b-commercial-individual-asset'
        },
        "Unsecured": {
            base: 'commercial-individual-funding',
            b2c: 'b2c-commercial-individual-funding',
            b2b: 'b2b-commercial-individual-funding'
        }
    },
    "Commercial - PAYG Chattel Mortgage": {
        "Asset Finance": {
            base: 'commercial-individual-asset',
            b2c: 'b2c-commercial-individual-asset',
            b2b: 'b2b-commercial-individual-asset'
        },
        "Unsecured": {
            base: 'commercial-individual-funding',
            b2c: 'b2c-commercial-individual-funding',
            b2b: 'b2b-commercial-individual-funding'
        }
    },
    "Commercial - Trust - Company Trustee": {
        "Asset Finance": {
            base: 'commercial-asset',
            b2c: 'b2c-commercial-asset',
            b2b: 'b2b-commercial-asset'
        },
        "Unsecured": {
            base: 'commercial-funding',
            b2c: 'b2c-commercial-funding',
            b2b: 'b2b-commercial-funding'
        }
    },
    "Commercial - Trust - Individual Trustee": {
        "Asset Finance": {
            base: 'commercial-asset',
            b2c: 'b2c-commercial-asset',
            b2b: 'b2b-commercial-asset'
        },
        "Unsecured": {
            base: 'commercial-funding',
            b2c: 'b2c-commercial-funding',
            b2b: 'b2b-commercial-funding'
        }
    }
}


// b2c-consumer-asset
// b2c-consumer-personal
// b2c-commercial-asset
// b2c-commercial-funding
// b2c-commercial-individual-asset
// b2c-commercial-individual-funding


// Asset Finance
// Unsecured
// Property Finance