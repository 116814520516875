<template>
     <v-dialog
        v-model="dialog"
        scrollable
        max-width="850"
        persistent>

        <v-card flat :disabled="isSaving">

            <v-progress-linear color="secondary" indeterminate stream absolute top v-if="isSaving">
            </v-progress-linear>

            <v-card-title>
                <v-spacer />
                <v-icon @click="handleClose" size="20">
                    mdi-close
                </v-icon>
            </v-card-title>
            <v-card-title class="mt-n6">
                <div class="text-center mx-auto" :class="vb.mdAndUp ? 'title' : 'title font-weight-bold'">
                    {{ isLastStep ? getRecordType === 'businesses' ? 'Entity Details' : 'Applicant Details' : 'Applicant Type'}} 
                </div>
            </v-card-title>
            <v-card-text v-if="steps.length > 0" class="px-16">

                <v-stepper v-model="stepper" alt-labels>
                    <v-stepper-items>
                        <v-stepper-content v-for="(step, index) in steps" :key="index" class="pa-0" :step="index">

                            <v-row v-if="step.children && step.children.length > 0" justify="center" align="center">
                                <v-col v-for="child in step.children" :key="child.name" cols="12" md="4" align="center">
                                    <v-card
                                        flat
                                        tile
                                        outlined
                                        :ripple="false"
                                        :width="vb.mdAndUp ? 223 : ''"
                                        :height="vb.mdAndUp ? 157 : ''"
                                        @click="nextChild(child)"
                                        class="pa-4 rounded-xl" :class="{'pt-16': vb.mdAndUp}">
                                            <div class="py-auto my-auto body-1">
                                                {{ child.name }}
                                            </div>
                                    </v-card>
                                </v-col>
                            </v-row>

                            <v-row v-else-if="relatedRoles.length" justify="center" align="center">
                                <v-col v-for="(role, index) in relatedRoles" :key="index" cols="12" md="4" align="center">
                                    <v-card
                                        flat
                                        tile
                                        outlined
                                        :ripple="false"
                                        :width="vb.mdAndUp ? 223 : ''"
                                        :height="vb.mdAndUp ? 157 : ''"
                                        @click="nextChild(child)"
                                        class="pa-4 rounded-xl" :class="{'pt-16': vb.mdAndUp}">
                                            <div class="py-auto my-auto body-1">
                                                {{getFriendlyName(role)}}
                                            </div>
                                    </v-card>
                                </v-col>
                            </v-row>

                            <template v-else>
                                <ValidationObserver ref="observer">
                                    <v-row>
                                        <v-col 
                                            class="my-0 py-0"
                                            v-for="(f, key) in Object.keys(fields[getRecordType])" 
                                            :key="key"
                                            cols="12"
                                            :md="getFieldColWidth(f, Object.keys(fields[getRecordType]).length)"
                                        >

                                            <ValidationProvider 
                                                v-if="isVisible(f)"
                                                v-slot="{ errors }" 
                                                :name="labels[f]" 
                                                :rules="getRules(f)">

                                                <AbnLookup
                                                    v-if="f == 'abn-number'"
                                                    v-model="fields[getRecordType][f]"
                                                    :errors="errors"
                                                    @accept="handleAbnData"
                                                    :label="labels[f]" />

                                                <v-text-field
                                                    v-else
                                                    v-model="fields[getRecordType][f]"
                                                    :label="labels[f]"
                                                    :error-messages="errors">
                                                </v-text-field>

                                            </ValidationProvider>
                                        </v-col>
                                    </v-row>
                                </ValidationObserver>
                            </template>

                        </v-stepper-content>
                    </v-stepper-items>
                </v-stepper>

            </v-card-text>
            <v-card-actions class="justify-center pb-10">
                <v-btn
                    v-if="stepper != 0"
                    large
                    min-width="125" 
                    max-height="50"
                    color="secondary"
                    class="mx-1"
                    @click="back"
                    outlined> Back
                </v-btn>
                <v-btn 
                    v-if="isLastStep && !isEdit"
                    large
                    min-width="125" 
                    max-height="50"
                    class="mx-1"
                    color="secondary" 
                    @click="next" 
                    depressed> Next 
                </v-btn>

                <v-btn 
                    v-else
                    large
                    min-width="125" 
                    max-height="50"
                    class="mx-1"
                    color="secondary" 
                    @click="handleUpdate" 
                    depressed> Save 
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
import shared from '~/components/v1/ApplicantCreator1/__shared'
import {actions} from '~/components/v1/ApplicantCreator1/__store'
import { mapActions } from 'vuex'
import validation from '~/components/shared/validation'
import formHelpers from '~/components/shared/form-helpers'
import ResourceHelpers from '~/components/shared/resource-helpers'
import AbnLookup from '~/components/base/AbnLookup'

export default {
    name: "ApplicantStepper",
    mixins: [ shared, validation, formHelpers, ResourceHelpers ],
    components: { AbnLookup },
    props: {
        dialog: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        vb() {
            return this.$vuetify.breakpoint
        }
    },
    data() {
        return {
            visible: {},
            relatedRoles: [],
            submitting: false,
            fields: {
                people: {
                    "first-name": "",
                    "last-name": "",
                },
                businesses: {
                    "abn-number": "",
                    "business-legal-name": ""
                }
            },
            labels: {
                "first-name": "First Name",
                "abn-number": "ABN",
                "last-name": "Last Name",
                "business-legal-name": "Business Legal Name",
            },
            validation_rules: {
                attributes: {
                    "first-name": 'required',
                    "last-name": 'required',
                    "business-legal-name": 'required'
                }
            },
        }
    },
    mounted() {
        // actions.initialize()
    },
    methods: {
        ...mapActions('resource-actions', [
            'getLoan',
            'updateLoan',
            'getPeople',
            'createPerson',
            'updatePerson',
            'createBusiness',
            'updateBusiness',
            'getBusinesses',
            'createPart',
            'deletePart',
        ]),

        async nextChild(payload){

            actions.setEntityPartKind(payload)

            actions.setSaving(true)

            this.relatedRoles = await this.getRelatedRoles(payload.children)

            // form logic
            if(payload.children.length === 0) {

                actions.setEntityKind(payload)

                if(this.entityKind.name == 'Individual') {
                    actions.setFieldType('people')
                } else {
                    actions.setFieldType('businesses')
                }

            }

            actions.nextStep(payload)
            
            actions.setSaving(false)
            
        },

        handleAbnData(data) {
            // this.entity.attributes['date-registered-abn'] = data['abn-reg']
            // this.entity.attributes['date-registered-gst'] = data['gst-reg-date']
            this.fields['businesses']['business-legal-name'] = data['abn-name']
            // this.entity.attributes['acn-number'] = data['acn-number']
        },

        back() {
            actions.prevStep()
        },

        async next() {

            actions.setSaving(true)

            const valid = await this.$refs.observer[0].validate()

            if(valid) {

               await this.handleCreate()
                
            }

            actions.setSaving(false)
        },

        async handleCreate() {
            const {entityKind, entityPartKind, parentEntity} = this

            let part = {
                "type": "parts",
                "attributes": {
                    "priority": null
                },
                "relationships": {
                    "application": {
                        "data": {
                            "type": "applications",
                            "id": `${this.appId}`
                        }
                    },
                    "parent": {
                        "data": {
                            "type": parentEntity.type,
                            "id": parentEntity.id
                        }
                    },
                    "kind": {
                        "data": {
                            "type": "kinds",
                            "id": `${entityPartKind.id}`
                        }
                    }
                }
            }

            let partRes = await this.createPart(part)

            let personPayload = {
                "type": "people",
                "attributes": {
                    ...this.fields.people
                },
                "relationships": {
                    "application": {
                        "data": {
                            "type": "applications",
                            "id": this.appId
                        }
                    },
                    "parent-parts": {
                        "data": [
                            {
                                "type": "parts",
                                "id": partRes.data.data.id
                            }
                        ]
                    },
                    "kind": {
                        "data": {
                            "type": "kinds",
                            "id": entityKind.id
                        }
                    }
                }
            }

            let businessPayload = {
                "type": "businesses",
                "attributes": {
                    ...this.fields.businesses
                },
                "relationships": {
                    "application": {
                        "data": {
                            "type": "applications",
                            "id": this.appId
                        }
                    },
                    "parent-parts": {
                        "data": [
                            {
                                "type": "parts",
                                "id": partRes.data.data.id
                            }
                        ]
                    },
                    "kind": {
                        "data": {
                            "type": "kinds",
                            "id": entityKind.id
                        }
                    }
                }
            }

            let entityRes = null

            if(entityKind.name == "Individual"){

                entityRes = await this.createPerson(personPayload)

            } else {

                entityRes = await this.createBusiness(businessPayload)
            }

            await this.updatePrimaryApplicant()

            this.handleClose()

            actions.setSaving(false)
        },

        async handleUpdate(){

            actions.setSaving(true)

            const { currentStep } = this

            const valid = await this.$refs.observer[0].validate()

            if(valid) {

                let payload = {
                    type: currentStep.type,
                    id: currentStep.id,
                    attributes: this.fields[currentStep.type]
                }

                 let res = null

                if(currentStep.type == 'businesses'){
                    res = await this.updateBusiness(payload)
                }

                if(currentStep.type == 'people'){
                    res = await this.updatePerson(payload)
                }

                actions.updateResources(res)

                await this.updatePrimaryApplicant()

            }

            this.handleClose()

            actions.setSaving(false)

        },

        handleClose(){
                
             actions.setDialog(false)

            // Reset
            this.tabs = 0 
            this.relatedRoles = []

            // Reset Fields
            this.fields = {
                people: {
                    "first-name": "",
                    "last-name": "",
                },
                businesses: {
                    "abn-number": "",
                    "business-legal-name": ""
                }
            }

            actions.resetSteps()

        },
        
    },
    watch: {
        dialog(val) {
            if(val) {
                if(this.currentStep && this.currentStep.attributes) {
                    Object.keys(this.fields[this.currentStep.type]).forEach(attr => {
                        this.fields[this.currentStep.type][attr] = this.currentStep.attributes[attr]
                    })
                }
            }
        }
    }
}
</script>
