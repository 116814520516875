<template>
    <div class="address-tool">
        <ValidationObserver ref="addressToolObserver" tag="form" :disabled="disableForm">
                <v-row>
                <!-- <v-col cols="12" class="py-0">
                    <v-select
                        :items="addresses"
                        placeholder="Select existing address"
                        @change="handleAddressChange"
                        color="secondary"></v-select>
                </v-col> -->
                <v-col cols="12" class="py-0">

                    <ValidationProvider
                        v-slot="{ errors }" 
                        :name="label" 
                        rules="required">
                        <google-address-tool
                            name="address"
                            :label="label"
                            :errors="errors"
                            v-model="fullAddress" 
                            @change="handleAddressToolChange"
                        ></google-address-tool>
                    </ValidationProvider>

                </v-col>

                <!-- <template v-for="(val, key) in resource.address.attributes">    

                    <v-col cols="12" md="4" 
                            class="py-0"
                            :key="key">
                            
                        <ValidationProvider
                            v-slot="{ errors }" 
                            :name="labels[key]" 
                            :rules="getRules(key)" :vid="key">

                            <v-select
                                v-if="isFieldType('picklist', key)"
                                :items="getOptions(key)"
                                :label="labels[key]"
                                :error-messages="errors"
                                v-model="resource.address.attributes[key]"
                                color="secondary"></v-select>

                            <v-text-field 
                                v-else
                                :type="'text'"
                                :label="labels[key]"
                                :error-messages="errors"
                                v-model="resource.address.attributes[key]"
                                color="secondary"></v-text-field>

                        </ValidationProvider>
                    </v-col>

                </template>     -->

                </v-row>
        </ValidationObserver>
    </div>
</template>

<script>

    import formHelpers from '~/components/shared/form-helpers.js'
    import resourceHelpers from '~/components/shared/resource-helpers'
    import validation from '~/components/shared/validation'
    import { mapActions } from 'vuex'

    import GoogleAddressTool from '~/components/base/GoogleAddressTool'

    export default {
        mixins: [validation, formHelpers, resourceHelpers],
        components: {
            GoogleAddressTool
        },
        model: {
            prop: 'modelValue',
            event: 'change'
        },
        props: {
            modelValue: {
                type: Object,
                default: () => ({})
            },
            label: {
                type: String,
                default: ''
            },
            bus: {
                type: Number,
                default: ''
            },
            errors: {
                type: Array,
                default: () => [],
            },
            fieldLayout: {
                type: Array,
                default: () => ([1, 1, 1, 0, 1, 1, 1, 0])
            },
            addresses: {
                type: Array,
                default: () => []
            }
        },
        data() {
            return {
                mode: 1, // 0 = update, 1 = create
                list: null,
                disabled: false,
                fullAddress: '',
                resource: {
                    address: {
                        type: 'addresses',
                        id: null,
                        attributes: {
                            "street-number": null,
                            "street-name": null,
                            "street-type": null,
                            "suburb": null,
                            "state": null,
                            "postcode": null
                        }
                    },
                },
                labels : {
                    "street-number": "Street Number",
                    "street-name": "Street Name",
                    "street-type": "Street Type",
                    "suburb": "Suburb",
                    "state": "State",
                    "postcode": "Postcode"
                },
                validation_rules: {
                    attributes: {
                        "street-number" : "required|string|max:40",
                        "street-name"   : "required|string|max:255",
                        "street-type"   : "required|string|max:50",
                        "suburb": "required|string|max:80",
                        "state" : "required|in:required|in:Australian Capital Territory,New South Wales,Northern Territory,Queensland,South Australia,Tasmania,Victoria,Western Australia,Outside Australia",   
                        "postcode": "required|string|max:20|exists:postcodes,value",
                    }
                },
                disableForm: false
            }
        },
        computed: {
            selectedAddress(){
                return this.getFullAddress({...this.modelValue})
            },
        },
        mounted(){
            // console.log(this.modelValue)
            if(this.modelValue) {
                this.fullAddress = this.getFullAddress({...this.modelValue})
            }
        },
        methods: {
            ...mapActions('resource-actions', [
                'updateAddress'
            ]),
            showSpacer(index){
                return this.fieldLayout[index] == 0 && this.$vuetify.breakpoint.mdAndUp
            },
            async save(){

                // console.log(this.mode)

                if(this.mode == 0){

                    this.disabled = true

                    const valid = await this.$refs.addressToolObserver.validate();

                    if(!valid) return;

                    await this.updateAddress(this.resource.address)

                    this.$emit('change', {...this.resource.address})


                    this.disabled = false

                }

                if(this.mode == 1){

                    const valid = await this.$refs.addressToolObserver.validate();

                    if(!valid) return;
                    
                    delete this.resource.address.id

                    this.$emit('change', {...this.resource.address})

                }

            },
            reset(){

                this.resource = {
                    address: {
                        type: 'addresses',
                        id: null,
                        attributes: {
                            "street-number": null,
                            "street-name": null,
                            "street-type": null,
                            "suburb": null,
                            "state": null,
                            "postcode": null
                        }
                    },
                }

                this.fullAddress = ''

                this.mode = 1

                /**
                 * Disabling form so parent Observer will ignore it.
                 */

                this.disableForm = true
                    
            },
            handleAddressToolChange(address, obj){

                this.resource.address.attributes = {
                    "street-number": obj["street-number"],
                    "street-name": obj["street-name"],
                    "street-type": obj["street-type"],
                    "suburb": obj["suburb"],
                    "state": obj["state"],
                    "postcode": obj["postcode"]
                }

                this.$emit('change', {...this.resource.address})

            },
            
        },
        watch: {
            /**
             *  TBD: 
             *  Using Date.now() to trigger change
             */
            bus(value){
                
                if(this.modelValue && this.modelValue.hasOwnProperty('attributes')){

                    this.resource.address.attributes = {
                        "street-number": this.modelValue.attributes["street-number"],
                        "street-name": this.modelValue.attributes["street-name"],
                        "street-type": this.modelValue.attributes["street-type"],
                        "suburb": this.modelValue.attributes["suburb"],
                        "state": this.modelValue.attributes["state"],
                        "postcode": this.modelValue.attributes["postcode"]
                    }

                    if(this.modelValue.id){

                        this.resource.address.id = this.modelValue.id
                        this.mode = 0

                    }


                    this.resource.address.relationships = this.modelValue.relationships

                    this.fullAddress = this.selectedAddress


                }
                

            },
        }

    }
</script>

<style lang="scss" scoped>

    .v-input .v-input__slot {
        background: transparent;


        &::before {
            display: none;
        }
    }

    .v-label {
        left: 0px;
        right: auto;
        position: absolute;
        transform: translateY(-32px) scale(1);
        
    }

    .v-tabs .v-tab.v-tab--active::before {
        opacity: 0.04;
        background-color: transparent !important;
    }


</style>