<template>
    <div>

        <div>
            <v-btn
                large
                rounded
                block
                class="primary--text"
                @click="dialog = true"
                color="white" >
                Request call back
            </v-btn>
        </div>

        <v-dialog
            v-model="dialog"
            persistent
            content-class="modal-RequestCallBack"
            scrollable
            width="500">

            <v-card class="mt-10 rounded-xl">

                <div class="rounded-t-xl">
                    <v-card-title class="px-6 pt-4">
                        <v-spacer/>
                        <v-btn icon @click="dialog = false">
                            <v-icon color="lighten-5">mdi-close</v-icon>
                        </v-btn>
                    </v-card-title>

                    <v-card-subtitle class="px-8 py-0 avatar">
                        <img src="~/assets/images/jane.png" height="90"/>
                    </v-card-subtitle>

                    <v-card-subtitle>
                        <div class="pa-2 mx-auto body-1 text-center light_gray_1 rounded-xl">
                            Need some Help? Speak with one of our finance experts.
                        </div>
                    </v-card-subtitle>
                </div>

                <v-card-text>
                    <ValidationObserver ref="observer" tag="form">

                            <ValidationProvider 
                                v-slot="{ errors }" >

                                <v-text-field
                                    :error-messages="errors"
                                    type="text"
                                    label="First Name" >
                                </v-text-field>

                                <v-text-field
                                    :error-messages="errors"
                                    type="text"
                                    label="Last Name" >
                                </v-text-field>

                                <v-text-field
                                    :error-messages="errors"
                                    type="text"
                                    label="Phone" >
                                </v-text-field>

                                <v-text-field
                                    :error-messages="errors"
                                    type="text"
                                    label="Email" >
                                </v-text-field>

                            </ValidationProvider>

                    </ValidationObserver>
                </v-card-text>

                <v-card-actions class="py-4">

                    <v-btn
                        large
                        rounded
                        block
                        dark
                        color="secondary"
                        class="pa-2" >
                        Request
                    </v-btn>

                </v-card-actions>

            </v-card>
        </v-dialog>

    </div>
    
</template>
<script>
    import subscriptions from '~/components/shared/subscriptions'

    export default {
        mixins: [subscriptions],

        name: 'CallBackModal',

        components: {},

        data() {
            return {
                dialog: false
            }
        },

        computed: {
            vb() {
                return this.$vuetify.breakpoint
            }
        },

        async mounted() {
        },

        methods: {

        }

    }

</script>

<style lang="scss" scoped>
    .modal-RequestCallBack {
        .v-dialog {
            overflow-y: unset !important;
        }
        .avatar {
            text-align: center;
            margin-top: -100px;
            img {
                border-radius: 36px !important;
                margin: 0 auto;
            }
        }
    }
</style>