<template>
    <div class="flex__sub-component-Select2">
        <v-select
            v-model="selected"
            :items="options"
            :label="label"
            :errors="errors">
        </v-select>   
    </div>
</template>

<script>
    export default {
        name: 'Select2',
        props: {
            options: {
                type: Array,
                default: () => []
            },
            errors: {
                type: Array,
                default: () => []
            },
            label: {
                type: String,
                dafault: ''
            }
        },
        data() {
            return {
                selected: ''
            }
        },
        watch: {
            selected() {
                // this.modelValue = this.selected
                this.$emit('input', this.selected)
            }
        }
    }
</script>

<style lang="scss">
    .flex__sub-component-Select2 {

        .v-input {
            .v-input__control {
                .v-input__slot {
                    border-radius: 25px !important;
                }
            }
        }
    
    }
</style>