<template>
    <v-dialog
        persistent
        scrollable
        height="600"
        max-width="1100"
        v-model="dialog">

        <v-card flat tile :disabled="saving" class="pa-4">

            <v-progress-linear color="secondary" indeterminate stream absolute top v-if="saving"></v-progress-linear>

            <v-card-title class="d-flex">
                <div class="v-card__title pa-0 sm">
                    Loan Details
                </div>
                <v-spacer />
                <div>
                    <v-img 
                        max-width="160"
                        max-height="45"
                        :src="getLenderLogo(logoClass)" 
                        position="center center" 
                        contain>
                    </v-img>
                </div>
            </v-card-title>

            <v-card-text v-if="isUnsecured">
                <ValidationObserver 
                    ref="observer" 
                    tag="form"
                >   
                    <v-row>
                        <v-col :cols="vb.smAndUp ? isUnsecured ? 6 : 4 : 12" 
                            v-for="(field, key) in resources['unsecured'].attributes"
                            :key="key"
                            class="py-0"
                        >
                            <ValidationProvider
                                v-if="isVisible(key)"
                                v-slot="{ errors }"
                                :name="labels[key]"
                                :rules="getRules(key)"
                                :vid="key">

                                <v-select
                                    v-if="isFieldType('picklist', key)"
                                    v-model="resources['unsecured'].attributes[key]"
                                    :items="getOptions(key)"
                                    :error-messages="errors"
                                    color="secondary"
                                    :label="labels[key]">
                                </v-select>

                                <template v-else>
                                    <v-text-field 
                                        v-if="key == 'base-rate' || key == 'customer-rate'"
                                        v-model="resources['unsecured'].attributes[key]"
                                        :label="labels[key]"
                                        :error-messages="errors"
                                        suffix="%"
                                        flat>
                                    </v-text-field>
                                    <NodifiMoney
                                        v-else
                                        :disabled="key == 'total-loan-amount'"
                                        v-model="resources['unsecured'].attributes[key]"
                                        :label="labels[key]"
                                        :errors="errors" />   
                                </template>

                            </ValidationProvider>
                            
                        </v-col>
                    </v-row>
                </ValidationObserver>
            </v-card-text>

            <v-card-text v-else>
                <ValidationObserver 
                    ref="observer" 
                    tag="form"
                >   
                    <v-row>
                        <v-col :cols="vb.smAndUp ? isUnsecured ? 6 : 4 : 12" 
                            v-for="(field, key) in resources['bid-lenders'].attributes"
                            :key="key"
                            class="py-0"
                        >
                            <ValidationProvider
                                v-if="isVisible(key)"
                                v-slot="{ errors }"
                                :name="labels[key]"
                                :rules="getRules(key)"
                                :vid="key">

                                <v-select
                                    v-if="isFieldType('picklist', key)"
                                    v-model="resources['bid-lenders'].attributes[key]"
                                    :items="getOptions(key)"
                                    :error-messages="errors"
                                    color="secondary"
                                    :label="labels[key]">
                                </v-select>

                                <template v-else>
                                    <v-text-field 
                                        v-if="key == 'base-rate' || key == 'customer-rate'"
                                        v-model="resources['bid-lenders'].attributes[key]"
                                        :label="labels[key]"
                                        :error-messages="errors"
                                        suffix="%"
                                        flat>
                                    </v-text-field>
                                    <NodifiMoney
                                        v-else
                                        :disabled="key == 'total-loan-amount'"
                                        v-model="resources['bid-lenders'].attributes[key]"
                                        :ref-input="key"
                                        :label="labels[key]"
                                        :errors="errors" />   
                                </template>
                                    
                                                                   

                            </ValidationProvider>
                            
                        </v-col>
                    </v-row>
                </ValidationObserver>
            </v-card-text>

            <v-card-actions class="pa-4 ma-2">

                <v-btn @click="back" :min-width="140" large depressed outlined color="secondary">
                    Back
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn v-if="editForm" @click="saveLender" :min-width="140" large depressed class="secondary">
                    Save
                </v-btn>

                <v-btn v-else @click="addLender" :min-width="140" large depressed class="secondary">
                    Next
                </v-btn>
                
            </v-card-actions>

        </v-card>

    </v-dialog>
</template>
<script>
import subscriptions from '~/components/shared/subscriptions'
import helpers from '~/plugins/helpers'
import { store } from './__store'

export default {
    mixins: [ subscriptions, helpers ],
    props: {
        dialog: {
            type: Boolean,
            default: true
        },
        logoClass: {
            type: String,
            default: ''
        },
        title: {
            type: String,
            default: 'Lender'
        },
        requiredLoanAmount: {
            type: [String, Number],
            default: ""
        },
        editForm: {
            type: Object,
            default: null
        }
    },
    data() {
        return {
            saving: false,
            resources: {
                "bid-lenders": {
                    type: "bid-lenders",
                    attributes: {
                        "loan-term": '',
                        "payment-terms": '',
                        "balloon-amount": 0,
                        "establishment-fee": 0,
                        "ppsr-fee": 0,
                        "origination-fee": 0,
                        "base-rate": 0,
                        "customer-rate": 0,
                        "total-loan-amount": 0,
                        "brokerage-commission": 0,
                        "total-commission": 0,
                        "monthly-account-fee": 0,
                        "monthly-payment": 0,
                        "lender-name": '',
                        "risk-fee": 0,
                    }
                },
                "unsecured": {
                    type: "bid-lenders",
                    attributes: {
                        "loan-term": '',
                        "customer-rate": '',
                        "establishment-fee": '',
                        "risk-fee": '',
                        "origination-fee": '',
                        "total-loan-amount": '',
                        "brokerage-commission": '',
                        "total-commission": '',
                        "monthly-account-fee": '',
                        "monthly-payment": '',
                        "lender-name": '',
                    }
                }
            },
            labels: {
                "loan-term": 'Loan term',
                "payment-terms": 'Payment Terms',
                "balloon-amount": 'Balloon Payment',
                "establishment-fee": 'Establishment Fee',
                "ppsr-fee": 'PPSR Fee',
                "origination-fee": 'Origination Fee',
                "base-rate": 'Base Rate',
                "customer-rate": 'Customer Rate',
                "total-loan-amount": 'Amount Financed',
                "brokerage-commission": 'Brokerage Commission',
                "total-commission": 'Total Commission',
                "monthly-account-fee": 'Monthly Fee',
                "monthly-payment": 'Monthly Payment',
                "risk-fee": 'Risk Fee',
            },
            validation_rules: {
                attributes: {
                    "loan-term": 'required|in:12,24,36,48,60,72,84',
                    "payment-terms": 'required|in:In Arrears,In Advance',
                    "balloon-amount": 'required',
                    "establishment-fee": 'required',
                    "ppsr-fee": 'required',
                    "origination-fee": 'required',
                    "base-rate": 'required|between:0,99',
                    "customer-rate": 'required|between:0,99',
                    "total-loan-amount": 'required',
                    "brokerage-commission": 'required',
                    "total-commission": 'required',
                    "monthly-account-fee": 'required',
                    "monthly-payment": 'required',
                    "lender-name": '',
                    "risk-fee": 'required',
                }
            },
            options: {
                "loan-term": [ '12', '24', '36', '48', '60', '72', '84'],
                "payment-terms": [ "In Arrears", "In Advance" ]
            },
            visible: {
                "risk-fee": false,
                "lender-name": false
            },
            suffix: {
                "loan-term": 'months',
                "payment-terms": ''
            },
        }
    },

    computed: {
        isUnsecured() {
            return store.isUnsecured
        },
        fieldFilter() {
            let field = {}

            if(this.isUnsecured) {
                field = this.resources['unsecured'].attributes
            } else {
                field = this.resources['bid-lenders'].attributes
            } 

            return field
        }
    },
    methods: {
        async addLender(){
            const valid = await this.$refs.observer.validate()

            if(valid) {
                this.$emit('submit', this.fieldFilter)
                this.$emit('close-modal')
            }
        },
        async saveLender() {
            const valid = await this.$refs.observer.validate()

            if(valid) {

                if(this.isUnsecured) {
                    await this.$emit('save-lender', this.resources['unsecured'].attributes)
                } else {
                    await this.$emit('save-lender', this.resources['bid-lenders'].attributes)
                }

                await this.$emit('close-modal')
            }
        },
        back() {
            if(this.editForm) {
                this.$emit('close-modal')
            }
            this.$emit('back')
        },
        computeLoanAmount() {
            let total = 0
            let establishment = 0
            let risk = 0
            let origination = 0
            let loanAmount = this.requiredLoanAmount ? parseInt(this.requiredLoanAmount) : 0

            if(this.isUnsecured) {

                establishment = this.resources['unsecured'].attributes['establishment-fee'] ? parseInt(this.resources['unsecured'].attributes['establishment-fee']) : 0
                risk = this.resources['unsecured'].attributes['risk-fee'] ? parseInt(this.resources['unsecured'].attributes['risk-fee']) : 0
                origination = this.resources['unsecured'].attributes['origination-fee'] ? parseInt(this.resources['unsecured'].attributes['origination-fee']) : 0

                total = establishment + risk + origination + loanAmount

            } else {

                establishment = this.resources['bid-lenders'].attributes['establishment-fee'] ? parseInt(this.resources['bid-lenders'].attributes['establishment-fee']) : 0
                risk = this.resources['bid-lenders'].attributes['risk-fee'] ? parseInt(this.resources['bid-lenders'].attributes['risk-fee']) : 0
                origination = this.resources['bid-lenders'].attributes['origination-fee'] ? parseInt(this.resources['bid-lenders'].attributes['origination-fee']) : 0

                total = establishment + risk + origination + loanAmount

            }

            return total
        },
        isVisible(key){
                
            const {visible} = this

            if(visible.hasOwnProperty(key)){
                return visible[key]
            }

            return true

        },
        resetFields() {
            if(this.isUnsecured) {
                this.resources['unsecured'].attributes['loan-term'] = ''
                this.resources['unsecured'].attributes['customer-rate'] = ''
                this.resources['unsecured'].attributes['establishment-fee'] = '0'
                this.resources['unsecured'].attributes['risk-fee'] = '0'
                this.resources['unsecured'].attributes['origination-fee'] = '0'
                this.resources['unsecured'].attributes['total-loan-amount'] = this.computeLoanAmount()
                this.resources['unsecured'].attributes['brokerage-commission'] = '0'
                this.resources['unsecured'].attributes['total-commission'] = '0'
                this.resources['unsecured'].attributes['monthly-account-fee'] = '0'
                this.resources['unsecured'].attributes['monthly-payment'] = '0'
                this.resources['unsecured'].attributes['lender-name'] = ''
            } else {
                this.resources['bid-lenders'].attributes['loan-term'] = ''
                this.resources['bid-lenders'].attributes['payment-terms'] = ''
                this.resources['bid-lenders'].attributes['balloon-amount'] = '0'
                this.resources['bid-lenders'].attributes['establishment-fee'] = '0'
                this.resources['bid-lenders'].attributes['ppsr-fee'] = '0'
                this.resources['bid-lenders'].attributes['origination-fee'] = '0'
                this.resources['bid-lenders'].attributes['base-rate'] = '0'
                this.resources['bid-lenders'].attributes['customer-rate'] = '0'
                this.resources['bid-lenders'].attributes['total-loan-amount'] = this.computeLoanAmount()
                this.resources['bid-lenders'].attributes['brokerage-commission'] = '0'
                this.resources['bid-lenders'].attributes['total-commission'] = '0'
                this.resources['bid-lenders'].attributes['monthly-account-fee'] = '0'
                this.resources['bid-lenders'].attributes['monthly-payment'] = '0'
                this.resources['bid-lenders'].attributes['lender-name'] = ''
                this.resources['bid-lenders'].attributes['risk-fee'] = '0'
            }
        },
        getLenderLogo(name){

            let lender = require(`~/assets/images/logo/placeholder.png`)

            try {
                
                if(!name) return lender;
                lender = require(`~/assets/images/logo/cropped/${name}.png`)
                return lender

            } catch (error) {

                console.log('Ooops!..', error)
                return lender

            }

        },
    },

    watch: {
        dialog(val) {

            if(this.isUnsecured)
                this.resources['unsecured'].attributes['total-loan-amount'] = this.computeLoanAmount()
            else
                this.resources['bid-lenders'].attributes['total-loan-amount'] = this.computeLoanAmount()

        },
        'resources': {
            handler(val) {
                if(val['bid-lenders'].attributes['establishment-fee'] || val['bid-lenders'].attributes['risk-fee'] || val['bid-lenders'].attributes['origination-fee']) {
                    val['bid-lenders'].attributes['total-loan-amount'] = this.computeLoanAmount()
                }

                if(val['unsecured'].attributes['establishment-fee'] || val['unsecured'].attributes['risk-fee'] || val['unsecured'].attributes['origination-fee']) {
                    val['unsecured'].attributes['total-loan-amount'] = this.computeLoanAmount()
                }
            },
            deep: true
        },
        editForm(val) {
            if(val) {
                
                if(this.isUnsecured) {

                    Object.keys(this.resources['unsecured'].attributes).forEach(v => {
                        this.resources['unsecured'].attributes[v] = val[v]
                    })
                    this.resources['unsecured'].attributes['loan-term'] = val['term-in-months'] ? val['term-in-months'].toString() : ''
                    this.resources['unsecured'].attributes['establishment-fee'] = val['details']['actual-payment']['bank-fees'] ? val['details']['actual-payment']['bank-fees'] : 0
                    this.resources['unsecured'].attributes['origination-fee'] = val['details']['actual-payment']['broker-fee'] ? val['details']['actual-payment']['broker-fee'] : 0
                    this.resources['unsecured'].attributes['monthly-account-fee'] = val['monthly-fees'] ? val['monthly-fees'] : 0
                    this.resources['unsecured'].attributes['brokerage-commission'] = val['brokerage-commission'] ? val['brokerage-commission'] : 0
                    this.resources['unsecured'].attributes['lender-name'] = val['name'] ? val['name'] : ''
                } else {

                    Object.keys(this.resources['bid-lenders'].attributes).forEach(v => {
                        this.resources['bid-lenders'].attributes[v] = val[v]
                    })

                    this.resources['bid-lenders'].attributes['loan-term'] = val['term-in-months'] ? val['term-in-months'].toString() : ''
                    this.resources['bid-lenders'].attributes['establishment-fee'] = val['details']['actual-payment']['bank-fees'] ? val['details']['actual-payment']['bank-fees'] : 0
                    this.resources['bid-lenders'].attributes['origination-fee'] = val['details']['actual-payment']['broker-fee'] ? val['details']['actual-payment']['broker-fee'] : 0
                    this.resources['bid-lenders'].attributes['monthly-account-fee'] = val['monthly-fees'] ? val['monthly-fees'] : 0
                    this.resources['bid-lenders'].attributes['brokerage-commission'] = val['brokerage-commission'] ? val['brokerage-commission'] : 0
                    this.resources['bid-lenders'].attributes['lender-name'] = val['name'] ? val['name'] : ''
                    this.resources['bid-lenders'].attributes['balloon-amount'] = val['details']['actual-payment']['balloon'] ? val['details']['actual-payment']['balloon'] : 0
                    this.resources['bid-lenders'].attributes['ppsr-fee'] = val['details']['variables']['ppsr'] ? val['details']['variables']['ppsr'] : 0
                    this.resources['bid-lenders'].attributes['base-rate'] = val['details']['actual-payment']['base-rate'] ? val['details']['actual-payment']['base-rate'] : 0
                }
            } else {
                this.resetFields()
            }
        },
    },
}
</script>
