export default {

     /**
     * ########################################################### LIABILITY
     */



    //  Liability 13-1
    // --Loan - Real Estate 13-18
    // ----Mortgage - Owner Occupied 13-21
    "13-21": {
        title: "",
        steps: [
            [
                [
                    "role-selector"
                ]
            ],
            [
                [
                    "amount-owing",
                    "amount-financed"
                ],
                // this field is for expenses
                [
                    "payment-amount",
                    "payment-frequency"
                ],
                //
                [
                    "financial-institution",
                    "other-institution"
                ]
            ]
        ],
        expenses: {
            //liability id : expense id
            "13-21": "11-6"
        }
    },

    //  Liability 13-1
    // --Loan - Asset 13-8
    // ----Other Asset Loan 13-11
    // ----Other Loan 13-15

    "13-11|13-15": {
        title: "",
        steps: [
            [
                [
                    "role-selector"
                ]
            ],
            [
                [
                    "description-other"
                ]
            ],
            [
                [
                    "amount-owing",
                    "amount-financed"
                ],
                // this field is for expenses
                [
                    "payment-amount",
                    "payment-frequency"
                ],
                //
                [
                    "financial-institution",
                    "other-institution"
                ],
                [
                    "to-be-paid-out"
                ]
            ]
        ],
        expenses: {
            //liability id : expense id
            "13-11": "11-21",
            "13-15": "11-25",
        }
    },

    //  Liability 13-1
    // --Loan - Asset 13-8
    // ----Car Loan 13-9
    // ----Novated Lease 13-10
    // ----Interest-Free (non-card) 13-13
    // ----Loan as Guarantor 13-14
    // ----Pay-Day Loan 13-16
    // ----Unsecured Personal Loan 13-17
    // ----Line of Credit - Secured 13-19
    // ----Mortgage - Investment 13-20

    "13-9|13-10|13-13|13-14|13-16|13-17|13-19|13-20": {
        title: "",
        steps: [
            [
                [
                    "role-selector"
                ]
            ],
            [
                [
                    "amount-owing",
                    "amount-financed"
                ],
                // this field is for expenses
                [
                    "payment-amount",
                    "payment-frequency"
                ],
                //
                [
                    "financial-institution",
                    "other-institution"
                ],
                [
                    "to-be-paid-out"
                ]
            ]
        ],
        expenses: {
            //liability id : expense id
            "13-9": "11-18",
            "13-10": "11-20",
            "13-13": "11-23",
            "13-14": "11-24",
            "13-16": "11-26",
            "13-17": "11-27",
            "13-19": "11-29",
            "13-20": "11-30",
        }
    },

    //  Liability 13-1
    // --Cards & Credit 13-2
    // ----Credit Card 13-3
    // ----Fuel Card 13-4
    // ----Line of Credit - Unsecured 13-5
    // ----Overdraft 13-6
    // ----Store Card 13-7

    "13-3|13-4|13-5|13-6|13-7": {
        title: "",
        steps: [
            [
                [
                    "role-selector"
                ]
            ],
            [
                [
                    "credit-limit",
                    "amount-owing"
                ],
                // this field is for expenses
                [
                    "payment-amount",
                    "payment-frequency"
                ],
                //
                [
                    "financial-institution",
                    "other-institution"
                ],
                [
                    "to-be-paid-out"
                ]
            ]
        ],
        expenses: {
            //liability id : expense id
            "13-3": "11-11",
            "13-4": "11-12",
            "13-5": "11-13",
            "13-6": "11-14",
            "13-7": "11-15"
        }
    },

    //  Liability 13-1
    // --Other Liability 13-22
    // ----HECS-HELP 13-23
    // ----Outstanding Taxation 13-25

    "13-23|13-25": {
        title: "",
        steps: [
            [
                [
                    "role-selector"
                ]
            ],
            [
                [
                    "amount-owing",
                    "__spacer"
                ],
                // this field is for expenses
                [
                    "payment-amount",
                    "payment-frequency"
                ],
                //
                [
                    "to-be-paid-out"
                ]
            ]
        ],
        expenses: {
            //liability id : expense id
            "13-23": "11-32",
            "13-25": "11-34"
        }
    },

    //  Liability 13-1
    // --Other Liability 13-22
    // ----Other Liability 13-24

    "13-24": {
        title: "",
        steps: [
            [
                [
                    "role-selector"
                ]
            ],
            [
                [
                    "description-other"
                ]
            ],
            [
                [
                    "amount-owing",
                    "__spacer"
                ],
                // this field is for expenses
                [
                    "payment-amount",
                    "payment-frequency"
                ],
                //
                [
                    "to-be-paid-out"
                ]
            ]
        ],
        expenses: {
            //liability id : expense id
            "13-24": "11-33"
        }
    },



}