var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('FlexWrap',{on:{"resize":_vm.handleResize}},[_c('div',{staticClass:"d-flex flex-wrap justify-center"},[_vm._l((_vm.lenders),function(item){return [_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{key:item.name,staticClass:"custom-lender-card ma-3",style:(("flex: 1 0; min-width: 200px;  max-width: " + _vm.cardSize + "px; " + (hover ? _vm.getHoverStyle : ''))),attrs:{"elevation":"6","disabled":_vm.isDisabled(item.name),"width":"auto"},on:{"click":function($event){return _vm.handleSaveCustomLender(item)}}},[_c('v-card-text',{staticClass:"card-item-inner"},[(_vm.hasConnector(item))?_c('div',{staticClass:"direct-badge",style:(("background-color: " + (_vm.hexToRGBA(_vm.getActiveTheme.colors.primary, 0.2))))},[_c('v-img',{attrs:{"contain":"","height":"25","width":"25","src":require("~/assets/images/direct-icon.png")}})],1):_vm._e(),_vm._v(" "),_c('div',{staticClass:"text-center py-4"},[_c('v-img',{attrs:{"max-width":"160","max-height":"45","src":_vm.getLenderLogo(item['logo-class']),"position":"center center","contain":""}})],1),_vm._v(" "),_c('div',{staticClass:"mt-2 d-none"},[_c('v-chip',{attrs:{"color":"light_gray_3","small":""}},[_vm._v("\n                                    "+_vm._s(item.alias)+"\n                                ")])],1)])],1)]}}],null,true)})]}),_vm._v(" "),(_vm.isConsumer)?_c('QuoteToolCustomLenderModal',{attrs:{"show-dialog":_vm.otherLenderDialog},on:{"save-name":_vm.handleSaveOtherCustomLender},scopedSlots:_vm._u([{key:"activator-button",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',_vm._g(_vm._b({staticClass:"custom-lender-card ma-3",style:(("flex: 1 0; min-width: 200px;  max-width: " + _vm.cardSize + "px; " + (hover ? _vm.getHoverStyle : ''))),attrs:{"elevation":"6","width":"auto"}},'v-card',attrs,false),on),[_c('v-card-title',{staticClass:"card-item-inner sm text-center"},[_vm._v("\n                        Custom Lender\n                    ")])],1)]}}],null,true)})]}}],null,false,4101327054)}):_vm._e()],2)])}
var staticRenderFns = []

export { render, staticRenderFns }