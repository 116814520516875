import Vue from "vue";

export const store = Vue.observable({

    // Resource
    loanType: null,
    appType: null,
    selectedEntity: 0,
    selectedEntityData: null,

    selectedEntityAll: false,
    preSelectRoles: [],
    preSelectLinkRoles: [],
    resources: {
        assets: [],
        liabilities: [],
        expenses: [],
        people: [],
        businesses: [],
        addresses: {
            assets: [],
        },
    },
    roleAsset: [],

    allAddresses: [],

    // Entities
    entities: [],
    entityParts: [],

    selectedFO: 0,
    selectedFOName: null,
    preset: null,
    selectedIndex: null,

    // Dialog
    saving: false,
    dialog: false,
    standaloneDialog: false,
    dialogType: '',
    
    stepper: 0,
    steps: [],
    currentStep: {},

    // Kinds
    kindsAsset: null,
    kindsLiability: null,
    kindsAssetId: null,
    kindsLiabilityId: null,

    // link record
    linkLiability: null,
    currentStepValue: null,

});


export const actions = {

    setCurrentStepValue(payload) {
        store.currentStepValue = payload
    },

    // Resources
    setValidate(payload) {
        store.validate = payload;
    },

    setLoanType(payload) {
        store.loanType = payload;
    },
    setAppType(payload) {
        store.appType = payload;
    },

    setSelectedEntity(payload) {
        store.selectedEntity = payload;
    },
    setSelectedEntityData(payload) {
        store.selectedEntityData = payload;
    },
    setSelectedFO(payload) {
        store.selectedFO = payload;
    },

    setPeople(payload) {
        store.resources.people = payload;
    },

    setEntities(payload) {
        store.entities = payload;
    },
    setEntityParts(payload) {
        store.entityParts = payload;
    },

    setBusinesses(payload) {
        store.resources.businesses = payload;
    },

    setResources(payload) {

        // const {incomes, assets, expenses, liabilities} = payload

        let keys = Object.keys(payload)

        keys.forEach( key => {
            store.resources[key] = payload[key];
        });

    },
    updateResources(payload){

        let newData = store.resources[payload.type].filter(resource => {
            return resource.id != payload.id
        })

        newData = [
            payload,
            ...newData
        ]

        store.resources[payload.type] = newData

    },
    deleteResource(payload){
        
        let newData = store.resources[payload.type].filter(resource => {
            return resource.id != payload.id
        })

        store.resources[payload.type] = newData

    },
    addResource({key, value}) {
        store.resources[key].push(value);
    },

    setAddresses(payload) {

        store.resources.addresses = payload;
    },

    setAllAddresses(payload) {

        store.allAddresses = payload;
    },
    updateAddress({key, value}) {
        store.resources.addresses[key].push(value);
    },

    //  Dialog

    closeDialog() {
        store.dialog = false;
    },

    closeStanaloneDialog() {
        store.standaloneDialog = false;
    },

    toggleSaving(payload) {
        store.saving = payload;
    },

    openDialog() {
        store.dialog = true;
    },

    openStandaloneDialog() {
        store.standaloneDialog = true;
    },
    setCurrentStep(payload){
        store.currentStep = payload
    },
    setSteps(payload){
        store.steps = payload
    },
    pushStep(payload){
        store.steps.push(payload)
    },
    async nextStep(payload) {

        // Filter, remove obsolete kinds
        // console.log(payload)
        let obsolete = []

        let newPayload = {...payload}
        let children = [...newPayload.children]
        
        // check if payload children has only 1
        if (payload.children.length === 1) {
            newPayload = {...payload.children[0]}
        } else {
            newPayload.children = children.filter( child => {
                return !obsolete.includes(child.id)
            })
        }


        // reset steps if payload dont have children and setting income kind id
        if(newPayload.children.length == 0) {
            store.steps.length = store.stepper + 1
        } else {
            await store.steps.splice(store.stepper + 1, 0, newPayload)
        }

        store.saving = true

        setTimeout( () => {
            store.currentStep = newPayload 
            store.stepper = store.stepper+1
            store.saving = false
        }, 300)

    },

    removeFirstStep() {
        store.steps = store.steps.shift()
    },

    selectNextStep() {
        store.saving = true
        // actions.setCurrentStep([])

        setTimeout( () => {
            store.stepper = store.stepper+1
            store.saving = false
        }, 300)
    },

    prevStep() {

        // console.log(store.currentStepValue)

        // if(store.currentStep?.id) {
        //     console.log('yes')
        //     store.steps.splice(store.stepper, 1)
        // }

        store.stepper = store.stepper-1
        store.saving = true

        setTimeout( () => {
            // store.steps.pop()
            store.currentStep = store.steps[store.stepper] 
            store.saving = false
        }, 300)
    },

    setDialogType(payload) {
        store.dialogType = payload;
    },

    setKindsAsset(payload){
        store.kindsAsset = payload 
    },
    setPreset(payload){
        store.preset = payload 
    },
    setSelectedEntityAll(payload){
        store.selectedEntityAll = payload 
    },
    setPreSelectRoles(payload){
        store.preSelectRoles = payload 
    },

    setPreSelectLinkRoles(payload){
        store.preSelectLinkRoles = payload 
    },

    setSelectedFOName(payload){
        store.selectedFOName = payload
    },
    resetAll(){

        store.loanType = null
        store.selectedEntity = 0
        store.selectedFO = 0
        store.preset = null
        store.selectedFOName = null

        actions.entityReset()
    
        // Dialog
        store.saving = false
        store.dialog = false
        store.standaloneDialog = false
        store.dialogType = ''
        
        store.stepper = 0
        store.steps = []
        store.currentStep = null
    
        // Kinds
        store.kindsAsset = null
    },
    entityReset(){
        
        store.selectedEntityAll = false
        store.preSelectRoles = [] 
        store.preselectIncomeSituation = null
        store.selectedEntityData = null
        
    },
    resetSteps() {
        store.steps = []
        store.kindsAssetId = null
        store.stepper = 0
        store.currentStep = null
    },
    setRoleAsset(payload) {
        store.roleAsset = payload
    },
    setKindsAssetId(payload) {
        store.kindsAssetId = payload
    },
    setSelectedIndex(payload) {
        store.selectedIndex = payload
    },
    setKindsLiabilityId(payload) {
        store.kindsLiabilityId = payload
    },
    setKindsLiability(payload){
        store.kindsLiability = payload 
    },
    setLinkLiability(payload) {
        store.linkLiability = payload
    }

};