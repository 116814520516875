<template>
    <div>
        <h1>HelloWorld1</h1>
        <template v-if="!isNavable">
            <p><strong>I am not</strong> configured with a <strong>Navable</strong> subscription.</p>
        </template>
        <template v-else>
            <p v-if="!isDisabled">
                <v-icon 
                x-large
                class="pr-2">
                mdi-check-bold
                </v-icon>
                <strong>I am</strong> configured with a <strong>Navable</strong> subscription. Navigate to another step and watch what happens.</p>
            <p v-else>
                <v-icon 
                x-large
                class="pr-2">
                mdi-cancel
                </v-icon>
                <strong>I am disabled</strong> via my <strong>Navable</strong> subscription
            </p>
        </template>
    </div>
</template>

<script>

    import { mapState, mapActions } from 'vuex'
    import subscriptions from '~/components/shared/subscriptions'

    export default {
        mixins: [subscriptions],
        data(){
            return {

            }
        },
        computed: {
            // ...mapState(['flow', 'layout', 'spaces'])
            isNavable() {
                return this.block.subscriptions.includes('Navable')
            }
        },
        created() {

        },
        methods: {
            
        }
    }
</script>

<style scoped lang="scss">

    div {
        border: 1px solid lightseagreen;
        background: #f2f2f2;
        padding: 20px;
    }

</style>
