import { mapState, mapGetters, mapActions, mapMutations } from 'vuex';

export default {
    computed: {
        ...mapGetters('flows', ['getSteps', 'getActiveStep']),
        ...mapGetters('statuses', ['getStepStatuses']),
    },
    methods: {
        isStepCompleted(step){

            let completed = false

            let stepStatus = this.getStepStatuses.find( status => {
                return status.relationships.step.data.id == step.id 
            })

            if(stepStatus && stepStatus.attributes.value == 'valid'){
                completed = true
            }

            return completed

        },
        /**
         * 
         * @param {object} step 
         * @returns Boolean
         * 
         * Client side evalutation for enabled / disabled steps 
         * *** NOT IN USE
         */
        isStepDisabled(step){
          
            let disabled = false

            let hasStates = step && step.hasOwnProperty('states')

            if(hasStates){

                if(step.states.enabled){

                    let allMethodsValid = true

                    step.states.enabled.methods.forEach(method => {

                        let {name, side} = method

                        if(side == 'client'){

                            let fn = this[name]

                            if(typeof fn === 'function') {

                                let valid = fn({
                                        payload: method.arguments,
                                        targetStep: step
                                    })

                                if(!valid){
                                    allMethodsValid = false
                                }

                            }

                        }

                    });

                    if(!allMethodsValid) {
                        disabled = true
                    }

                }
            }

            return this.isDisabled || disabled  

        },
        /**
         * @param {object} step 
         * @returns Boolean
         *  
         * Serverside evaluation for enabled / disabled steps 
         * 
         */
        isStepDisabledServerSide(step){

            let status = this.getStepStatuses.find( status => {
                return status.relationships.step.data.id == step.id 
            })

            if(status && !status.attributes.hasOwnProperty('enabled') ){
                return false
            }

            if(status && status.attributes.enabled == 1 ){
                return false
            }

            return true
        },
        /**
         * @param {object} step 
         * @returns Boolean
         *  
         * Serverside evaluation for enabled / disabled steps 
         * 
         */
         isStepIncludedServerSide(step){

            let status = this.getStepStatuses.find( status => {
                return status.relationships.step.data.id == step.id 
            })

            if(status && status.attributes.hasOwnProperty('included') ){

                if(status.attributes.included == 0 ){
                    return false
                }

            }

            return true
        },
        /**
         * 
         * Step State Methods called in isStepDisabled()
         * *** NOT IN USE
         */
         allPreviousStepsAreValid({payload, targetStep}){

            let valid = true
            let proceed = true

            this.getSteps.forEach( step => {

                if(step.id == targetStep.id) proceed = false;

                if(proceed){

                    let stepStatus = this.getStepStatuses.find( status => {
                        return status.relationships.step.data.id == step.id 
                    })

                    if(stepStatus && stepStatus.attributes.value != 'valid'){
                        valid = false
                    }

                }

            })

            return valid
         },


    }
}