<template>
    <v-container
        class="fill-height ma-0 pa-0 pb-10" >

        <v-card
            v-if="!noApplicationData"
            flat width="100%" height="100%"
            class="light_gray_1 mx-0 pa-0">

            <v-card-text
                v-if="boxToken && valid">
                <div class="display-1">
                    <div class="box-container" id="box-container" style="height:500px"></div>
                    <input type="hidden" id="box_token" :value="token">
                    <input type="hidden" id="folder_id" :value="folder">
                </div>

                <v-alert v-if="subType" dense outlined type="warning" class="mt-4">
                    Submission type {{ subType }} requires supporting documents to be provided. Please upload to progress your referral hub submission.
                </v-alert>

            </v-card-text>

            <v-container
                v-else
                fill-height>

                <v-row class="justify-center align-center">
                    <v-col cols="12">
                        <v-card flat class="light_gray_1 py-12 my-12">

                            <v-col
                                class="text-center"
                                cols="12" sm="6"
                                offset-sm="3">

                                <LoaderImage
                                    class="mx-auto" />

                            </v-col>

                            <!-- <p class="body-1 light_gray_2--text text-center pa-0 ma-0">Loading data... Please wait.</p>
                             -->
                        </v-card>

                    </v-col>
                </v-row>

            </v-container>

        </v-card>

        <v-card
            v-else
            flat width="100%"
            height="100%"
            class="light_gray_1 mx-0 pa-0">

            <v-container
                fill-height>
                <v-row class="justify-center align-center">
                    <v-col cols="12">
                        <v-card flat class="light_gray_1 py-12 my-12">
                            <p class="body-1 light_gray_2--text text-center pa-0 ma-0">
                                Cant find your Application
                            </p>
                            <p class="caption light_gray_2--text text-center">
                                Please contact your data manager.
                            </p>
                        </v-card>
                    </v-col>
                </v-row>
            </v-container>

        </v-card>

    </v-container>

</template>

<script>
    import LoaderImage from './loaderImage'
    // import ContentExplorer from 'box-ui-elements/es/elements/content-explorer'
    // import messages from 'box-ui-elements/i18n/en-US'
    import resourceHelpers from '~/components/shared/resource-helpers'
    import { mapActions, mapState } from 'vuex'
    import { isEmpty } from 'lodash'

    export default {
        name: 'BoxApi',

        mixins: [resourceHelpers],

        head: {
            link: [
                {
                    rel: 'stylesheet',
                    href: 'https://cdn01.boxcdn.net/platform/elements/13.0.0/en-US/explorer.css'
                }
            ],
            script: [
                {
                    src: 'https://cdn.polyfill.io/v2/polyfill.min.js?features=es6,Intl'
                },
                {
                    src: 'https://cdn01.boxcdn.net/polyfills/core-js/2.5.3/core.min.js'
                },
                {
                    src: 'https://cdn01.boxcdn.net/platform/elements/4.4.0/en-US/explorer.js',
                    async: true
                },
            ]
        },

        components: {
            LoaderImage
        },

        props: {
            applicationId: {
                type: [String, Number],
                default: ''
            },
            boxData: {
                type: Object,
                default: () => {}
            },
            application: {
                type: Object,
                default: () => {}
            },
            sfApplicationNumber: {
                type: String,
                default: ''
            },
            hasFileNote: {
                type: Boolean,
                default: false
            },
            subType: {
                type: String,
                default: ''
            }
        },

        computed: {
            ...mapState('auth-actions', [
                'userSettings'
            ]),
            ...mapState('auth', ['user']),
            token() {
                return this.boxToken
            },
            folder() {
                if ( this.isB2bUser ) {
                    return this.box?.attributes?.['folder']
                }
                return this.box?.attributes?.['b2c-folder']
            },
            isB2bUser() {
                return this.userSettings?.b2b === 1 ? true : false
            }
        },

        async mounted() {
            await this.fetchBox()
        },

        methods: {
            ...mapActions('resource-actions', [
                'getApplicationBox',
                'updateBox',
                'getBoxToken',
                'createBox',
                'createFileNote'
            ]),
            async fetchBox() {

                let resBox = null
                let resCreate = null

                if ( this.applicationId ) {

                    if ( isEmpty(this.boxData) ) {
                        resCreate = await this.createBox({
                            type: "boxes",
                            attributes: {
                                folder_id: "new"
                            },
                            relationships: {
                                application: {
                                    data: {
                                        type: "applications",
                                        id: this.applicationId
                                    }
                                }
                            }
                        })

                        const boxToken = await this.getBoxToken();

                        if ( resCreate.status === 201 && boxToken.status == 200) {
                            this.box = resCreate.data.data
                            this.boxToken = boxToken.data.token
                            this.valid = true

                            await this.loadBox()

                            this.$emit('box-created', this.box)
                        }

                    } else {

                        const boxToken = await this.getBoxToken();

                        if ( boxToken.status == 200) {

                            this.box = this.boxData
                            this.boxToken = boxToken.data.token
                            this.valid = true

                            await this.loadBox()
                        }
                    }

                }
                else {
                    this.noApplicationData = true
                }

            },
            async writeFileNote() {

                this.$emit('rendered', true)

                await this.createFileNote({
                    data: {
                        type: 'file-notes',
                        attributes: {
                            'sf-comment-body': `User ${this.user?.username} has uploaded a file in application ${this.sfApplicationNumber}.`,
                            'sf-creator-name': this.user?.username
                        },
                        relationships: {
                            application: {
                                data: {
                                    id: this.applicationId,
                                    type: 'applications'
                                }
                            }
                        }
                    }
                })

            },

            async loadBox() {

                let _this = this

                if(typeof Box == 'object'){
                    var folderId = this.folder;
                    var accessToken = this.token;
                    var contentExplorer = await new Box.ContentExplorer();

                    // console.log('folderId', folderId)
                    // console.log('accessToken', accessToken)

                    contentExplorer.show(
                        folderId,
                        accessToken, 
                        {
                            container: '.box-container',
                            canDownload: true,
                            canDelete: false,
                            canRename: false,
                            canUpload: true,
                            canShare: false,
                            canNavigate: false,
                            canSearch: false,
                            canCreateNewFolder: false,
                            canUpload: true
                        })

                    contentExplorer.addListener('upload', async function(data) {
                        if (_this.hasFileNote)
                            if (!(_this.sfApplicationNumber === null || _this.sfApplicationNumber === 'NULL'))
                                await _this.writeFileNote()
                    })

                    contentExplorer.addListener('navigate', function(data) {

                        let crumbs = document.querySelectorAll('.be-breadcrumb')
                            crumbs.forEach(elem => elem.style.display='none');

                        // if ( data.parent.name === 'applications'){
                            // document.getElementsByClassName('be-breadcrumb')[0].style.display='none'
                        // } else {
                            // document.getElementsByClassName('be-breadcrumb')[0].style.display='block'
                        // }


                    })

                    document.getElementsByClassName('be-logo')[0].style.display='none'


                    if (this.userSettings?.b2b !== 1) {
                        this.elems = document.getElementsByClassName('be-breadcrumb')[0]
                        // document.getElementsByClassName('be-breadcrumb')[1].style.display='none'
                        const elems = await document.getElementsByClassName('be-breadcrumb')
                        setTimeout( async () => {
                            for (var i = elems.length - 1; i >= 0; i--) {
                                elems[i].style.display = 'none'
                            }
                        }, 800)

                    }
                    else {
                        this.elems = document.getElementsByClassName('be-breadcrumb')[0]
                        const elems = await document.getElementsByClassName('be-breadcrumb')
                        setTimeout( async () => {
                            for (var i = elems.length - 1; i >= 0; i--) {
                                elems[i].style.opacity = '1'
                            }
                        }, 800)
                    }

                    this.$emit('rendered', true)

                }
            }
        },

        data() {
            return {
                valid: false,
                noApplicationData: false,
                box: null,
                boxToken: null,
                elems: [],

            }
        }
    }
</script>

<style lang="scss" scoped>
  ::v-deep .box-message {
      position: absolute;
      height: 100% !important;
      width: 100% !important;
  }
  ::v-deep div#box-container {
      height: 500px !important;
  }
  ::v-deep .be .btn-plain {
      font-size: 15px;
  }
  ::v-deep .be-logo {
  display: none !important;
}
  ::v-deep .be-breadcrumb-more {
      display: none !important;
  }
  ::v-deep .be-header {
      display: none !important;
  }
  ::v-deep.be-breadcrumbs {

      .be-breadcrumb {
          opacity: 0;
          button {
              // display: flex;
              // width: 50px;
              // height: 20px;
              // overflow: hidden;
              // white-space: nowrap;
              width: 80px;
              height: 20px;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              margin: 0;
              // background: white;
          }
          &:first-child {
              // display: flex;
          }
          &:last-child {
              // display: flex;
              // width: 50px;
              // height: 20px;
              // overflow: hidden;
              // white-space: nowrap;
          }
      }
      // display: none !important;
      // &:last-child {
      //     display: flex;
      // }
  }
  
</style>
