<template>
    <v-card :disabled="isDisabled" class="mx-auto" tile flat>
        <v-card-text class="pa-4 pt-0 pa-md-10 pt-md-0">
            <v-sheet>
                
                <ValidationObserver ref="observer">
                    <v-tabs-items v-model="selectedPerson">
                        <v-tab-item eager v-for="person in people" :key="person.id">

                            <EntityEditor5 :id="person.id" :loan-kind-id="loanKindId" :data="person" :entity-part-kind-id="getPart(person.id)"/>

                        </v-tab-item>
                    </v-tabs-items>
                </ValidationObserver>

            </v-sheet>

        </v-card-text>
    </v-card>
</template>

<script>
    
    import { mapActions, mapGetters, mapMutations } from 'vuex'
    import subscriptions from '~/components/shared/subscriptions'
    import EntityEditor5 from '~/components/v1/ApplicantEditor5/_EntityEditor5'

    export default {
        mixins: [subscriptions],
        components: {
            EntityEditor5
        },
        data(){
            return {
                loanKindId: null,
                people: [],
                parts: [],
                errorMessages: {},
                selectedPerson: 0,
            }
        },
        created() {

        },
        computed: {
            ...mapGetters('kinds', ['kinds', 'kindsUnformat']),
            getApplicantKinds(){

                let parts = this.kinds.find( kind => {
                    if(kind.name == 'Part'){
                        return kind
                    }
                })

                return parts.children
            },
            slideColor(){

                if(this.people.length) {

                    let id = this.people[this.selectedPerson].id

                    if( Object.keys(this.errorMessages).length > 0 ) {

                        if(this.errorMessages[id] && this.errorMessages[id].length) {
                            return 'error'
                        }

                    }

                }

                return 'primary'
            },
            peopleId () {

                if(this.$route?.params && this.$route?.params.record == 'people') {

                    return this.$route?.params?.record_id
                }

                return null

            },
            getLoanId(){
                return this.appData?.relationships?.loan?.data?.id
            },
        },
        methods: {
            ...mapActions('resource-actions', [
                'getLoan',
                'getPeople',
                'updatePerson',
                'createPerson'
            ]),
            async pull(){
      
                try {

                    let promise1 = this.getLoan(this.getLoanId, {"include" : 'parent-parts'})
                    let promise2 = this.getPeople({"include" : 'parent-parts'})

                    const res = await Promise.all([promise1, promise2]) 

                    promise1 = res[0]
                    promise2 = res[1]

                    if(promise1.data?.data?.relationships?.kind?.data?.id){
                        this.loanKindId = promise1.data.data.relationships.kind.data.id
                    }

                    if(this.peopleId) {

                        let temp = this.getPeopleByRoles(promise2.data)

                        temp.forEach( item => {

                            if(item.id == this.peopleId) {
                                this.people.push(item)
                            }

                        })

                    } else {

                        this.people = []
                    }

                    if(promise2.data.included){
                        this.parts = promise2.data.included.filter( part => {
                            return part.type == "parts"
                        })
                    }

                    // Save VeeValidate's observer in Vuex for observing touched events
                    this.setFormFieldsObserverName('observer')


                } catch (error) {

                    console.log('Ooops!..', error)

                }
            },
            async push(){

                const {people} = this
                const observer = this.$refs.observer

                await this.$refs.observer.validate()

                let collection = [] 
                let hasError = false


                people.forEach(person => {

                    let currentObserver = observer.observers.find(o => o.$attrs['data-id'] == person.id)

                    Object.keys(person.attributes).forEach( field => {
                        if(currentObserver.fields[field] && currentObserver.fields[field]["invalid"]) {
                            hasError = true
                        }
                    })

                    if(hasError){
                        this.errorMessages[person.id] = 'Error'
                    } else {
                        delete this.errorMessages[person.id]
                    }


                    let payload = {
                        type: person.type,
                        id: person.id,
                        attributes: this.excludeInvalids(person.attributes, currentObserver.fields)
                    }

                    // condition for number of dependants
                    if(parseInt(payload.attributes['number-of-dependants']) == 0) {
                        payload.attributes['age-of-dependants'] = null
                    }

                    let res = this.updatePerson(payload)

                    collection.push(res)
                    
                })

                const {valid, status} = await this.validateFormData()
                const data = await this.appDataHelper(collection)

                return {valid, status, data}

            },
            getPart(id){

                let part = this.parts.find( part => {
                    return part.relationships.child.data.id == id
                })

                if(part){
                    return part.relationships.kind.data.id
                }

                return null

            },
            getPeopleByRoles(data){

                if(!data.included){
                    return [];
                }

                let parts = data.included.filter(part => {
                    return part.type == 'parts'
                })

                let roleIds = [];	
                let rolesObj = {}
                
                parts.forEach(part => {
                    
                    if (!part.relationships.kind.data) {	
                        // person must have part with kind
                        return []
                    }
                    
                    this.getApplicantKinds.forEach(kind => {

                        if(kind.id == part.relationships.kind.data.id) {

                            roleIds.push(part.id);
                            rolesObj[part.id] = kind.name

                        }

                    })
                })

                if(roleIds.length == 0) {
                    return []
                } 

                let people = [] 
                
                data.data.forEach( person => {

                    let parentParts = person.relationships['parent-parts'].data

                    person.roles = []

                    let dependantCount = 0

                    
                    if(person.attributes["age-of-dependants"]){
                        dependantCount = person.attributes["age-of-dependants"].split(',')
                        dependantCount = dependantCount.length
                    }

                    person.attributes["number-of-dependants"] = dependantCount // initialize dependants field
                    
                    if( parentParts.length ){

                        let included = false;

                        parentParts.forEach( part => {

                            if(roleIds.includes(part.id) ) {

                                // Add extra data to person
                                person.roles.push(rolesObj[part.id])

                                // Include person once
                                if(!included){
                                    people.push(person)
                                    included = true
                                }

                            }

                        })

                    }

                })

                return people 

            },
            getFullName(person){

                if(!person.hasOwnProperty('attributes')) return

                let fname = person.attributes['first-name']
                let lname = person.attributes['last-name']

                if(!fname && !lname){
                    return 'Person'
                }

                return `${fname ? fname : ''} ${lname ? lname : ''}` 
            },
            slideHasError(id){
                return (this.errorMessages[id] && this.errorMessages[id].length)
            }
        }
    }
</script>

<style scoped lang="scss">

</style>
