<template>
    <v-dialog v-model="propModel" persistent  width="500">
        <v-card>
            <v-card-title class="px-8 pt-8">
                    <div class="v-card__title sm pa-0">
                        Applicant Type
                    </div>
            </v-card-title>

            <v-card-text class="px-8 pt-2 pb-8 body-1">I confirm that the asset being purchased is for more than 50% of business use.</v-card-text>

            <v-card-actions class="px-8 pb-8">
                <v-spacer></v-spacer>
                <v-btn
                    color="light_gray_2"
                    outlined
                    large depressed
                    @click="closeDialog">
                    Cancel
                </v-btn>
                <v-btn
                    color="secondary"
                    large depressed
                    @click="confirmChange">
                    Yes
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    name: 'ConfirmChangeDialog',
    props: {
        show: {
            type: Boolean,
            default: false
        },
    },
    computed: {
        propModel: {
            get () { return this.show },
            set (value) { this.$emit('update:prop', value) },
        }
    },
    data() {
        return {

        }
    },
    methods: {
        closeDialog() {
            this.$emit('close-dialog');
        },
        confirmChange() {
            this.$emit('confirm-change');
        }
    }
}
</script>

<style scoped>

</style>