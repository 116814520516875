<template>
    <v-container class="ligh" fill-height fluid>
    <v-row justify="center" align="center">
        <v-col cols="12" align="center">

            <v-card flat class="light_gray_1 rounded-lg" max-width="800">
                <v-card-text class="px-10">

                    <div class="v-card__title mx-auto justify-center pb-2">{{ errorTitle }}</div>

                        <template v-if="error.statusCode == 500">
                            <v-card flat class="light_gray_1 rounded-lg" max-width="400">
                                <p class="body-2">Please try hard-refreshing your browser. If you're not sure how to do this, see <a href="https://www.howtogeek.com/672607/how-to-hard-refresh-your-web-browser-to-bypass-your-cache" target="blank">here</a></p>
                                <p class="body-2">If a hard-refresh does not fix the issues you're experiencing, please reach out to your consultant or relationship manager.</p>
                            </v-card>
                        </template>

                        <template v-else>
                            <p class="body-1 font-weight-black" v-if="error.message">{{ error.message }}</p>
                        </template>

                        <template v-if="error.misc">
                            <v-alert type="error" v-for="(msg, index) in error.misc" :key="index">
                                {{ msg }}
                            </v-alert>     
                        </template>

                        <small>
                            {{error}} <br/>
                            URL: {{getPathName}} <br/>
                            GUID: {{appGuid}} <br/>
                            Tenant: {{getTenant}}
                        </small>

                </v-card-text>
            </v-card>
        </v-col>
    </v-row>
    </v-container>
</template>

<script>

import { mapMutations, mapActions, mapGetters, mapState } from 'vuex';
    import meta from '~/layouts/meta'

    export default {
        mixins: [
            meta
        ],
        props: ['error'],
        layout: 'no-content',
        data () {
            return {

            }
        },
        computed: {
            ...mapState({
                appData: state => state.flows.appData,
                loanData: state => state.flows.loanData,
                appId: state => state.flows.appId,
                appGuid: state => state.flows.appGuid,
            }),
            ...mapGetters({
                getActiveTheme: 'themes/getActiveTheme'
            }),
            ...mapGetters('slug', [
                'getTenant',
            ]),
            errorTitle () {
                const { statusCode } = this.error
                if (statusCode === 503) {
                    return 'We are currently down for scheduled maintenance.'
                }
                return `${statusCode} Error`
            },
            getPathName(){
                return window.location.href;
            }
        },
        head () {

            const title = this.error.statusCode == 404 ? '404 Not Found' : 'Uh oh! Something went wrong.'

            return {
                title,
                link: [
                    { rel: 'stylesheet', href: `/${this.getActiveTheme.name}.css` }
                ],
            }
        },

        async mounted(){

            console.log('Error', this.error)
            console.log('appData', this.appData)
            console.log('loanData', this.loanData)

            const { statusCode } = this.error
            if (statusCode === 403) {
                await this.$auth.logout();
            }

        },

}
</script>