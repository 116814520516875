<template>
    <v-card class="mx-auto" tile flat :disabled="isDisabled">
        <v-card-text class="pa-4 pt-0 pa-md-10 pt-md-0 mx-2">
            <v-container pa-0 fluid>

                <ValidationObserver ref="observer" tag="form">
                    <v-row>                        
                        <v-col cols="12" md="6" class="mb-4 overflow-hidden pt-0">

                            <div class="body-2 font-weight-medium mt-3 mb-2">Submission notes</div>
                            <ValidationProvider
                                v-slot="{ errors }"
                                name="Submission Comment"
                                rules="required|max:512|min:25"
                                tag="div"
                                class="submission-text-wrap"
                                vid="submission-comments">

                                    <v-textarea
                                        v-model="resources.loan.attributes['submission-comments']"
                                        auto-grow
                                        placeholder="Regarding any information to help with the deal"
                                        rows="10"
                                        height="100%"
                                        row-height="16"
                                        class="ma-0 pa-0"
                                        :error-messages="errors"
                                    >
                                    </v-textarea> 

                            </ValidationProvider>

                        </v-col>
                        <v-col  cols="12" md="6" class="mb-4 pt-0">
                            <div class="body-2 font-weight-medium mt-3 mb-2">Document Upload</div>
                            <div class="rounded-lg dashed-border">
                                <v-card
                                    flat class="transparent"
                                    min-height="560"
                                    :loading="uploading"
                                    @drop.prevent="onDrop($event)"
                                    @dragover.prevent="dragover = true"
                                    @dragenter.prevent="dragover = true"
                                    @dragleave.prevent="dragover = false"
                                    :class="{ 'light_gray_1 darken-1': dragover }">
                                    <template slot="progress">
                                        <v-overlay absolute class="d-flex flex-column text-center" color="secondary" opacity=".8">
                                            <div>
                                                <v-progress-circular size="75" color="white" indeterminate>
                                                </v-progress-circular>
                                            </div>
                                        </v-overlay>
                                    </template>
                                    <v-card-text v-if="files.length > 0">
                                        <div class="d-flex pa-4">
                                            <v-icon size="32">mdi-cloud-upload</v-icon>
                                            <div class="px-2 pt-1">
                                                Drag and drop files or choose a file
                                            </div>
                                            <div>
                                                <v-btn
                                                    id="button-upload"
                                                    class="secondary rounded-lg font-size-14 mx-4"
                                                    :loading="uploadBtnLoading"
                                                    @click="onUpload"
                                                    x-small depressed min-height="24"
                                                    min-width="64"
                                                    style="font-weight: 400 !important;">
                                                    Browse
                                                </v-btn>
                                            </div>
                                        </div>
                                        <div v-for="(file, ind) in files" :key="ind" class="d-flex light_gray_1 py-2 mt-2 mx-4 px-4 rounded-lg">
                                            <div>
                                                {{ file.name || file }}
                                            </div>
                                            <v-spacer />
                                            <v-icon size="18" @click="removeFile(file)" color="black">mdi-close</v-icon>
                                        </div>
                                    </v-card-text>
                                    <v-card-text v-else class="d-flex flex-column mx-auto content" style="height: 100px;">
                                        <div class="text-center">
                                            <v-icon size="32">mdi-cloud-upload</v-icon>
                                        </div>
                                        <div class="d-flex justify-center mt-5">
                                            <div>
                                                Drag and drop files or choose a file
                                            </div>
                                            <div>
                                                <v-btn
                                                    id="button-upload"
                                                    class="secondary rounded-lg font-size-14 mx-4"
                                                    :loading="uploadBtnLoading"
                                                    @click="onUpload"
                                                    x-small depressed min-height="24"
                                                    min-width="64"
                                                    style="font-weight: 400 !important;">
                                                    Browse
                                                </v-btn>
                                            </div>
                                            
                                        </div>
                                    </v-card-text>
                                </v-card>
                                <input
                                    id="file-upload"
                                    class="d-none"
                                    type="file"
                                    accept="image/jpeg/pdf"
                                    multiple
                                    @change="onFileChanged($event)">
                            </div>
                        </v-col>

                    </v-row>

                </ValidationObserver>
            </v-container>
        </v-card-text>

        <!-- This will replace Next button in StepIncrementer1  -->
        <Portal to="step-incrementer-next">
            <v-btn 
                v-if="authUserCanSubmitApp"
                color="secondary" 
                large depressed nuxt 
                :min-width="btnWidth" 
                :disabled="loading || isDisabled"
                @click="submitApplication" 
                class="ml-3">
                Submit
            </v-btn>
            <!-- Auth user not allowed to submit -->
            <div v-else></div>
        </Portal>

        <v-overlay class="ma-0 pa-0" :value="overlay" opacity=".2">
            <div class="spinner">      
                <div class="flex-brand-loader">
                    <div class="flex-brand d-block">
                        <loaderImage />
                    </div>
                </div>
            </div>
        </v-overlay>
    </v-card>
</template>

<script>

    import loaderImage from '~/components/base/loaderImage'
    import subscriptions from '~/components/shared/subscriptions'
    import { mapState, mapGetters, mapActions, mapMutations } from 'vuex'

    export default {
        components: {
            loaderImage,
        },
        mixins: [subscriptions],

        data(){
            return {
                isSameLender: false,
                confirmDialogTitle: '',
                confirmDialog: false,
                lenderSubmissions: [],
                uploading: false,
                dragover: false,
                uploadBtnLoading: false,
                files: [],

                overlay: false,
                validComponentData: true,

                resources: {
                    loan: {
                        type: "loan",
                        id: null,
                        attributes: {
                            "submission-comments": "",
                        },
                        relationships: {
                            application: {
                                data: {type: "applications", id: null}
                            }
                        }
                    }
                },
            }
        },

        computed: {
            ...mapState({
                appId: state => state.flows.appId,
                appGuid: state => state.flows.appGuid,
                loanData: state => state.flows.loanData
            }),
            ...mapState('statuses', [
                'stepStatuses'
            ]),
            ...mapGetters('kinds', [
                'kindsOfApplicant'
            ]),
            ...mapGetters('statuses', [
                'getCurrentFlowStatus'
            ]),
            ...mapGetters('flows', [
                'redirectPaths'
            ]),
            ...mapState(['appConfig']),
            getLoanType(){
                return this.loanData?.attributes['loan-type']
            },
            getSelectedLender(){
                return this.loanData?.attributes['selected-lender-quote-algorithm-slug']
            },
            btnWidth () {
                switch (this.$vuetify.breakpoint.name) {
                    case 'xs': return 0
                    case 'lg': 
                    case 'xl':
                    case 'md': 
                    case 'sm': return 140
                }
            },
            getLoanId(){
                return this.appData?.relationships?.loan?.data?.id
            },
            activeLenderSubmissions() {
                let { lenderSubmissions } = this
                let active = null

                if( lenderSubmissions.length > 0 ) {
                    lenderSubmissions.forEach(lenderSub => {
                        if( lenderSub.attributes.active ) {
                            active = lenderSub
                        }
                    })
                }

                return active
            },       
        },
        mounted(){

        },
        methods: {
            ...mapActions('resource-actions', [
                'getLoan',
                'updateLoan',
                'getPeopleByResource',
                'getLender',
                'runLenderSubmitService',
                'runServiceRetrieve',
                'runServiceUpload',
                'runServiceDeleteFile',
            ]),
            ...mapActions('statuses', [
                'completeFlowStatus'
            ]),
            ...mapActions('flows', [
                'selectFinalStep',
                'selectNextStep',
                'selectFinalStepNoFlowComplete'
            ]),
            ...mapMutations('utils', [
                'toggleAlert'
            ]),

            async pull() {

                let requests = [
                    this.getLoan(this.getLoanId),
                    this.getFiles()
                ]
                
                // get lender submissions
                let lenderSub = await this.getPeopleByResource({resource: 'lender-submissions'})

                if(lenderSub.status === 200) this.lenderSubmissions = lenderSub.data.data

                Promise.all(requests).then( data => {

                    let loan = data.find( dat => {
                        return dat.data.data.type === 'loans'
                    })

                    this.resources.loan = this.resourceFieldPicker(this.resources.loan, loan.data.data)

                    return data

                })

            },
            async push() {
                
                const {valid, status} = await this.validateFormData()

                return {valid, status}
                
            },

            async submitApplication() {
                try {
                    this.loading = true

                    const { valid } = await this.validateFormData()

                    if(valid) {

                        const observer = this.$refs.observer
                        const {loan} = this.resources

                        // Exclude relationships and other stuff
                        let loanPayload = {
                            type: loan.type,
                            id: loan.id,
                            attributes: this.excludeInvalids(loan.attributes, observer.fields)
                        }

                        await this.updateLoan(loanPayload) 
                        await this.lenderSubmit()
                    }

                    this.loading = false

                } catch (err) {
                    this.loading = false
                    console.log(err)
                }
            },

            async lenderSubmit() {

                try {

                    let res = await this.runLenderSubmitService({
                        "application": this.appGuid, 
                    })

                    if(res.status === 200) {
                        
                        if(res.data.message == 'Lender Submission Failed') {
                            await this.selectFinalStepNoFlowComplete()
                        } else {
                            await this.selectFinalStep({flowStatus: this.getCurrentFlowStatus.id})
                        }

                    }

                } catch (err) {

                    this.loading = false

                }

            },

            async onDrop(event) {
                try {
                    this.uploading = true
                    this.dragover = false
                    let payload = new FormData()
                    
                    this.files = Array.from(event.dataTransfer.files) || []

                    payload.append('application', this.appGuid)
                    payload.append('prefix', 'macquarie')

                    for(let index = 0; index < this.files.length; index++) {
                        payload.append('files['+ index +']', this.files[index])
                    }

                    await this.runServiceUpload(payload)

                    await this.getFiles()

                    this.uploading = false

                } catch (err) {
                    this.uploading = false
                }
            },

            async onFileChanged(event) {
                try {
                    this.uploading = true
                    let payload = new FormData()
                    this.files = Array.from(event.target.files) || []

                    payload.append('application', this.appGuid)
                    payload.append('prefix', 'macquarie')

                    for(let index = 0; index < this.files.length; index++) {
                        payload.append('files['+ index +']', this.files[index])
                    }

                    await this.runServiceUpload(payload)

                    await this.getFiles()

                    this.uploading = false

                } catch (err) {
                    this.uploading = false
                }
            },

            async getFiles() {
                try {

                    this.uploading = true

                    const res = await this.runServiceRetrieve({
                        "application": this.appGuid,
                        "prefix": "macquarie"
                    })

                    if(res.status === 200) {
                        this.files = res.data.files
                    }

                    this.uploading = false

                } catch(err) {
                    this.uploading = false
                }
            },

            onUpload() {
                var inputId = "file-upload"
                this.uploadBtnLoading = true
                window.addEventListener('focus', () => {
                    this.uploadBtnLoading = false
                }, { once: true })
                document.getElementById(inputId).click()
            },

            wordCount(field = '') {
                return this.resources?.loan?.attributes?.[field]?.length <= 250 ? 250 : false
            },

            async removeFile(file) {
                try {
                    this.uploading = true

                    await this.runServiceDeleteFile({ "application": this.appGuid,"file": file })

                    await this.getFiles()

                    this.uploading = false
                } catch (err) {
                    this.uploading = false
                }
            }
        },
        watch: {

        }
    
    }
</script>

<style lang="scss">

    .flex__component-MacquarieSubmit1 {

        .dashed-border {
            border: 2px dashed var(--v-light_gray_1-base) !important;
            overflow: hidden;
        }

        .content {
            position: absolute;
            top: 50%;
            transform: translate(0, -50%);
            padding: 10px;
        }


        .submission-text-wrap, 
        .submission-text-wrap > .v-input,
        .submission-text-wrap > .v-input > .v-input__control
        {
            height: 100%;
        }
        
        .submission-text-wrap > .v-input > .v-input__control  .v-input__slot {
            align-items: flex-start;
            flex-basis: 564px;
        }

    }

</style>

