<template>
  <v-app dark :class="`tenant-${getActiveTheme ? getActiveTheme.name : 'nodifi-default'} ${getFlowClasses} ${lenderClass ? 'style-override-' + lenderClass : ''}${flexOverrideClass ? 'style-override-' + flexOverrideClass : ''}`">

    <!--  Relative Footer Layout  -->

    <HideWhenEmbedded>
      <v-app-bar
        class="flex__header"
        :class="{'hide-sub-nav': !showSubNav}"
        v-if="spaces.Header"
        :absolute="absoluteHeader"
        flat
        app dense
        :height="headerHeight"
        clipped-left
        clipped-right>
          <div ref="header" class="flex-grow-1">

              <GridTemplateAreas
                  v-if="spaces.Header['grid-template-areas']"
                  :space="spaces.Header"
                  :leftDrawer="leftDrawer"
                  :rightDrawer="rightDrawer"
                  :leftDrawerVariant="leftDrawerVariant"
                  :rightDrawerVariant="rightDrawerVariant"
                  @onToggleLeftDrawer="handleLeftDrawer"
                  @onToggleRightDrawer="handleRightDrawer"
                  @onToggleVariant="handleVariant"
                />

              <component
                  v-else
                  v-for="block in spaces.Header.blocks"
                  :block="block"
                  :is="block.component.name"
                  :key="block.id"
                  :class="`flex__component-${block.component.name}`"
                  :leftDrawer="leftDrawer"
                  :rightDrawer="rightDrawer"
                  :leftDrawerVariant="leftDrawerVariant"
                  :rightDrawerVariant="rightDrawerVariant"
                  @onToggleLeftDrawer="handleLeftDrawer"
                  @onToggleRightDrawer="handleRightDrawer"
                  @onToggleVariant="handleVariant"
                  @onMount="handleHeaderMount"/>
          </div>
      </v-app-bar>
    </HideWhenEmbedded>

    <HideWhenEmbedded :isApplicationFlow="isApplicationFlow" :when="() => ! isEmbeddedApplicationFlow">
      <v-navigation-drawer
          v-if="spaces.LeftSideBar"
          v-model="leftDrawer"
          clipped
          disable-resize-watcher
          :mini-variant="leftDrawerVariant"
          :mobile-breakpoint="breakpoint"
          fixed
          app
          color="primary"
          class="flex__space-left-sidebar py-0"
          >
          <div v-bar>
            <div class="d-flex flex-column">

              <GridTemplateAreas
                  v-if="spaces.LeftSideBar['grid-template-areas']"
                  :space="spaces.LeftSideBar"
                  :leftDrawer="leftDrawer"
                  :rightDrawer="rightDrawer"
                  :leftDrawerVariant="leftDrawerVariant"
                  :rightDrawerVariant="rightDrawerVariant"
                  @onToggleLeftDrawer="handleLeftDrawer"
                  @onToggleRightDrawer="handleRightDrawer"
                  @onToggleVariant="handleVariant"
                />

              <component
                  v-else
                  v-for="block in spaces.LeftSideBar.blocks"
                  :block="block"
                  :is="block.component.name"
                  :key="block.id"
                  :class="`flex__component-${block.component.name}`"
                  :leftDrawer="leftDrawer"
                  :rightDrawer="rightDrawer"
                  :leftDrawerVariant="leftDrawerVariant"
                  :rightDrawerVariant="rightDrawerVariant"
                  @onToggleLeftDrawer="handleLeftDrawer"
                  @onToggleRightDrawer="handleRightDrawer"
                  @onToggleVariant="handleVariant"
                />

            </div>
          </div>
      </v-navigation-drawer>
    </HideWhenEmbedded>

    <v-main v-if="spaces.Page" class="flex__main">


      <div class="d-flex" :style="`height: calc(100% - ${footerHeight}px)`">

        <PortalTarget name="component-side-utility"
                      class="component-side-utility">
          <!-- Component Sidebar : see RoleEditor1.vue for example -->
        </PortalTarget>

        <v-container fluid pa-4 pa-md-10 class="flex__main-container flex-grow-1">
            <Alerts />
            <card-cap v-if="isStepable"/>

            <GridTemplateAreas
                v-if="spaces.Page['grid-template-areas']"
                :space="spaces.Page"
                :leftDrawer="leftDrawer"
                :rightDrawer="rightDrawer"
                :leftDrawerVariant="leftDrawerVariant"
                :rightDrawerVariant="rightDrawerVariant"
                @onToggleLeftDrawer="handleLeftDrawer"
                @onToggleRightDrawer="handleRightDrawer"
                @onToggleVariant="handleVariant"
              />

            <component
                v-else
                v-for="block in spaces.Page.blocks"
                :block="block"
                :is="block.component.name"
                :class="`flex__component-${block.component.name}`"
                :key="block.id"
                :leftDrawer="leftDrawer"
                :rightDrawer="rightDrawer"
                :leftDrawerVariant="leftDrawerVariant"
                :rightDrawerVariant="rightDrawerVariant"
                @onToggleLeftDrawer="handleLeftDrawer"
                @onToggleRightDrawer="handleRightDrawer"
                @onToggleVariant="handleVariant"
              />

            <card-cap v-if="isStepable" bottom />
        </v-container>
      </div>

      <v-card :height="footerHeight" class="mx-auto flex__footer-spacer" tile flat />

      <v-footer
        tile
        :height="footerHeight"
        padless
        absolute
        class="flex__footer  justify-center"
        v-if="spaces.Footer">
          <div ref="footer">

            <GridTemplateAreas
                v-if="spaces.Footer['grid-template-areas']"
                :space="spaces.Footer"
                :leftDrawer="leftDrawer"
                :rightDrawer="rightDrawer"
                :leftDrawerVariant="leftDrawerVariant"
                :rightDrawerVariant="rightDrawerVariant"
                @onToggleLeftDrawer="handleLeftDrawer"
                @onToggleRightDrawer="handleRightDrawer"
                @onToggleVariant="handleVariant"
              />

            <component
                v-else
                v-for="block in spaces.Footer.blocks"
                :block="block"
                :is="block.component.name"
                :key="block.id"
                :class="`flex__component-${block.component.name}`"
                :leftDrawer="leftDrawer"
                :rightDrawer="rightDrawer"
                :leftDrawerVariant="leftDrawerVariant"
                :rightDrawerVariant="rightDrawerVariant"
                @onToggleLeftDrawer="handleLeftDrawer"
                @onToggleRightDrawer="handleRightDrawer"
                @onToggleVariant="handleVariant"
                @onMount="handleFooterMount" />

          </div>
      </v-footer>

    </v-main>

    <v-navigation-drawer
        v-if="spaces.RightSideBar"
        v-model="rightDrawer"
        clipped
        disable-resize-watcher
        :mini-variant="rightDrawerVariant"
        :mobile-breakpoint="breakpoint"
        class="flex__space-right-sidebar"
        right
        fixed app>

            <GridTemplateAreas
                v-if="spaces.RightSideBar['grid-template-areas']"
                :space="spaces.RightSideBar"
                :leftDrawer="leftDrawer"
                :rightDrawer="rightDrawer"
                :leftDrawerVariant="leftDrawerVariant"
                :rightDrawerVariant="rightDrawerVariant"
                @onToggleLeftDrawer="handleLeftDrawer"
                @onToggleRightDrawer="handleRightDrawer"
                @onToggleVariant="handleVariant"
              />

            <component
                v-else
                v-for="block in spaces.RightSideBar.blocks"
                :block="block"
                :is="block.component.name"
                :leftDrawer="leftDrawer"
                :rightDrawer="rightDrawer"
                :leftDrawerVariant="leftDrawerVariant"
                :rightDrawerVariant="rightDrawerVariant"
                @onToggleLeftDrawer="handleLeftDrawer"
                @onToggleRightDrawer="handleRightDrawer"
                @onToggleVariant="handleVariant"
                :key="block.id" />

    </v-navigation-drawer>

    <Snackbar />

  </v-app>


</template>

<script>

    import baseComponents from '~/components/base'
    import v1Components from '~/components/v1'
    import GridTemplateAreas from '~/components/layouts/includes/_GridTemplateAreas'

    import { mapMutations, mapActions, mapGetters, mapState } from 'vuex';
    import { isEmbeddedApplicationFlow, isApplicationFlow } from '~/middleware/embed';

    export default {
        components: {
            ...baseComponents,
            ...v1Components,
            GridTemplateAreas
        },
        props: {
            spaces: {
                type: Object,
                default: () => ({})
            },
        },
        data () {
              return {
                headerHeight: 48, // Default Header Height
                footerHeight: 48, // Default Footer Height
                breakpoint: 768,

                leftDrawer: false,
                rightDrawer: false,
                leftDrawerVariant: false,
                rightDrawerVariant: false,
                showSubNav: true,
              }
        },
        computed:{
          ...mapGetters({
              getActiveTheme: 'themes/getActiveTheme',
              getLeftDrawer: 'setup/getLeftDrawer' ,
              getRightDrawer: 'setup/getRightDrawer' ,
              getLeftDrawerVariant: 'setup/getLeftDrawerVariant' ,
              getRightDrawerVariant: 'setup/getRightDrawerVariant' ,
              getFlowSlug: 'slug/getFlowSlug',
              lenderClass: 'themes/getLenderClass',
              flexOverrideClass: 'themes/getFlexOverrideClass',

          }),
          ...mapState('setup', {
              controlledDrawerMutationObserver: state => state.controlledDrawerMutationObserver
            }),
          ...mapState('subscriptions', {
              isStepable: state => state.isStepable
            }),
            isEmbeddedApplicationFlow () {
                return isEmbeddedApplicationFlow();
            },
            isApplicationFlow() {
              return isApplicationFlow();
            },
            absoluteHeader(){

              const {getActiveTheme} = this

              /**
               * v-app-bar attr
               * absolute or fixed
               *
               * default is fixed
               */

              if(getActiveTheme.settings?.header){
                  return getActiveTheme.settings.header.absolute
              }

              return false
            },
            getFlowClasses(){

              let classes = ''

              if(this.getFlowSlug){
                classes = `flex_flow-page fle_flow-${this.getFlowSlug}`
              }

              return classes
            },
            isMobile(){
              return this.$vuetify.breakpoint.width <= this.breakpoint
            }
        },
        created() {

            /**
             * Store / Cookie setup
             */
            this.spacesSetup()


            /**
             * Initialize local data from store.
             */

            /**
             * Hide Drawer on Mobile initially
             */
            if(!this.isMobile){
                this.leftDrawer = this.getLeftDrawer
                this.rightDrawer = this.getRightDrawer
            }

            this.leftDrawerVariant = this.getLeftDrawerVariant
            this.rightDrawerVariant = this.getRightDrawerVariant

        },
        mounted(){
          window.addEventListener('scroll', this.onScroll)
        },
        beforeDestroy () {
          window.removeEventListener('scroll', this.onScroll)
        },
        methods: {
            ...mapActions({
                spacesSetup: 'setup/spacesSetup'
            }),
            ...mapMutations({
                setLeftDrawer: 'setup/setLeftDrawer',
                setRightDrawer: 'setup/setRightDrawer',
                setLeftDrawerVariant: 'setup/setLeftDrawerVariant',
                setRightDrawerVariant: 'setup/setRightDrawerVariant',
            }),
            handleLeftDrawer(){

                this.leftDrawer = !this.leftDrawer
                // Save to cookie/store
                this.setLeftDrawer()

            },
            handleRightDrawer(){

              this.rightDrawer = !this.rightDrawer
              // Save to cookie/store
              this.setRightDrawer()
            },
            handleVariant(pos, value = null){

              if(pos == 'left') {

                this.leftDrawerVariant = !this.leftDrawerVariant
                // Save to cookie/store
                this.setLeftDrawerVariant(value)

              } else {

                this.rightDrawerVariant = !this.rightDrawerVariant
                // Save to cookie/store
                this.setRightDrawerVariant(value)
              }


            },

            /**
             * This will determine the height of Header and Footer
             * based on header component counts.
             */
            handleHeaderMount(){
                this.headerHeight = this.$refs.header.offsetHeight
            },
            handleFooterMount(){
                this.footerHeight = this.$refs.footer.offsetHeight
            },
            onScroll () {
              if(this.isMobile) {
                this.showSubNav = window.scrollY === 0
              } else {
                this.showSubNav = true
              }
            },
        },
        watch: {
          /**
           * This watcher will update local data for Drawers if
           * on one of the Controlled Drawer Mutations is called.
           */
          controlledDrawerMutationObserver(newVal, oldVal){
              if(newVal != oldVal){
                  this.leftDrawer = this.getLeftDrawer
                  this.rightDrawer = this.getRightDrawer
                  this.leftDrawerVariant = this.getLeftDrawerVariant
                  this.rightDrawerVariant = this.getRightDrawerVariant
              }
          }
        }
    }
</script>


<style lang="scss">

  header.v-app-bar .v-toolbar__content {
      padding: 0;
  }

  .component-side-utility {
    width: auto;
  }

</style>
