<template>
    <ValidationObserver ref="observer" tag="form">
        <v-row>
            <v-col cols="12 py-0 px-3 pt-4">
                <google-address-tool
                    name="address"
                    label="Address Search"
                    v-model="googleSearch" 
                    @change="initializeAddress"
                ></google-address-tool>
            </v-col>
        </v-row>                            
        <!-- <v-row>
            <v-col 
                v-for="(refItem, rkey) in form.address"
                :key="rkey"
                cols="12" 
                lg="4"
                md="4" 
                sm="4" 
                class="px-3 py-0">
                
                <ValidationProvider 
                    v-slot="{ errors }" 
                    :name="fields[rkey].name | formalText" 
                    :rules="getRules(rkey)"
                    :vid="fields[rkey].name">

                    <v-select
                        v-if="isFieldType('picklist', rkey)"
                        :items="getOptions(rkey)"
                        :label="fields[rkey].label"
                        :placeholder="fields[rkey].placeholder ? fields[rkey].placeholder : ''"
                        :error-messages="errors"
                        v-model="form.address[rkey]"
                        color="secondary"
                    >
                    </v-select>

                    <v-text-field
                        v-else
                        v-model="form.address[rkey]"
                        :error-messages="errors"
                        color="secondary"
                        :label="fields[rkey].label"
                        :placeholder="fields[rkey].placeholder ? fields[rkey].placeholder : ''">
                    </v-text-field>

                </ValidationProvider>
            </v-col>
        </v-row> -->
    </ValidationObserver>
</template>
<script>
import formHelpers from '~/components/shared/form-helpers.js'
import validation from '~/components/shared/validation'
import GoogleAddressTool from '~/components/base/GoogleAddressTool'
import resourceHelpers from '~/components/shared/resource-helpers'

export default {
    name: 'AddressDetails',

    mixins: [formHelpers, validation, resourceHelpers],

    components: { GoogleAddressTool },

    props: {
        syncReset: {
            type: Boolean,
            default: false
        },
        address: {
            type: Object,
            default: () => {}
        },
    },

    watch: {
        'form.address': {
            async handler(val) {
                this.$emit('change-form', { form: this.form.address})
            },
            deep: true
        },
        address(val) {

            if(Object.keys(val).length > 0) {
                this.googleSearch = this.getFullAddress({attributes : {...val}})
            }

            if(Object.keys(val).length > 0) {
                Object.keys(val).forEach(f => {
                    Object.keys(this.form.address).forEach(k => {
                        if(k == f)
                            this.form.address[f] = val[f]
                    })
                })
            }
        },
        syncReset(val) {
            this.form.address = {
                'street-number': '',
                'street-name': '',
                'street-type': '',
                'suburb': '',
                'state': '',
                'postcode': ''
            }
        }
    },

    data() {
        return {
            googleSearch: '',
            form: {
                address: {
                    'street-number': '',
                    'street-name': '',
                    'street-type': '',
                    'suburb': '',
                    'state': '',
                    // 'country': '',
                    'postcode': ''
                }
            },
            fields: {
                    'street-number': { placeholder: 'Street No.', label: 'Street Address', name: 'street-number' },
                    'street-name': { placeholder: 'Street Name', label: '', name: 'street-name' },
                    'street-type': { placeholder: 'Street Type', label: '', name: 'street-type' },
                    'suburb': { placeholder: '', label: 'Suburb', name: 'suburb' },
                    'state': { placeholder: '', label: 'State', name: 'state' },
                    // 'country': { label: 'Country', name: 'country' },
                    'postcode': { label: 'Postcode', name: 'postcode' },
            },
            validation_rules: {
                attributes: {
                    "street-number"	: "required|string|max:40",
                    "street-name"	: "required|string|max:255",
                    "street-type"	: "required|string|max:50",
                    "suburb": "required|string|max:80",
                    "state"	: "required|in:required|in:Australian Capital Territory,New South Wales,Northern Territory,Queensland,South Australia,Tasmania,Victoria,Western Australia,Outside Australia",	
                    "postcode": "required|string|max:20|exists:postcodes,value",
                }
            },
        }
    },

    methods: {
        initializeAddress(address, obj) {

            // this.form.address = address

            this.form.address = {
                "street-number": obj["street-number"],
                "street-name": obj["street-name"],
                "street-type": obj["street-type"],
                "suburb": obj["suburb"],
                "state": obj["state"],
                "postcode": obj["postcode"]
            }
        },
    }

}
</script>
