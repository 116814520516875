var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('ValidationObserver',{ref:"observer",attrs:{"tag":"form"}},[(_vm.applicantType === 'primary')?_c('v-row',[_c('v-col',{staticClass:"py-0 px-8"},[_c('ValidationProvider',{key:"applicant_another_name",attrs:{"name":"Applicant Another Name","rules":"required","vid":"applicant_another_name"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('ButtonGroup3',{attrs:{"error-messages":errors,"options":[
              { value: '1', label: 'Yes' },
              { value: '0', label: 'No' } ],"label":"Has the applicant ever been known by another name?"},model:{value:(_vm.form.applicant_another_name),callback:function ($$v) {_vm.$set(_vm.form, "applicant_another_name", $$v)},expression:"form.applicant_another_name"}})]}}],null,false,4092078139)})],1)],1):_vm._e(),_vm._v(" "),(
        _vm.form.applicant_another_name === '1' && _vm.applicantType === 'primary'
      )?_c('v-row',{staticClass:"m-0"},[_c('v-col',{attrs:{"cols":"py-0"}},[_c('p',{staticClass:"px-5 mb-0"},[_vm._v("What was the other name?")]),_vm._v(" "),_c('v-row',[_c('v-col',{staticClass:"py-0 px-8"},[_c('ValidationProvider',{key:"'other-first-name'",attrs:{"name":"First Name","rules":"required","vid":"'other-first-name'"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var errors = ref.errors;
return [_c('v-text-field',{attrs:{"tile":"","type":"text","label":"First Name","color":"secondary","error-messages":errors,"required":""},model:{value:(_vm.form['other-first-name']),callback:function ($$v) {_vm.$set(_vm.form, 'other-first-name', $$v)},expression:"form['other-first-name']"}})]}}],null,false,607496138)})],1),_vm._v(" "),_c('v-col',{staticClass:"py-0 px-8"},[_c('ValidationProvider',{key:"'other-middle-name'",attrs:{"name":"Middle Name","vid":"'other-middle-name'"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var errors = ref.errors;
return [_c('v-text-field',{attrs:{"tile":"","type":"text","label":"Middle Name","color":"secondary","error-messages":errors},model:{value:(_vm.form['other-middle-name']),callback:function ($$v) {_vm.$set(_vm.form, 'other-middle-name', $$v)},expression:"form['other-middle-name']"}})]}}],null,false,1414248853)})],1),_vm._v(" "),_c('v-col',{staticClass:"py-0 px-8"},[_c('ValidationProvider',{key:"'other-last-name'",attrs:{"name":"Last Name","rules":"required","vid":"'other-last-name'"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var errors = ref.errors;
return [_c('v-text-field',{attrs:{"tile":"","type":"text","label":"Last Name","color":"secondary","error-messages":errors,"required":""},model:{value:(_vm.form['other-last-name']),callback:function ($$v) {_vm.$set(_vm.form, 'other-last-name', $$v)},expression:"form['other-last-name']"}})]}}],null,false,3156819082)})],1)],1)],1)],1):_vm._e(),_vm._v(" "),(_vm.applicantType === 'secondary')?_c('v-row',[_c('v-col',{staticClass:"py-0 px-8",attrs:{"sm":"12","md":"6"}},[_c('ValidationProvider',{key:"lender-pepper-relationship",attrs:{"name":"lender-pepper-relationship","rules":"required","vid":"lender-pepper-relationship"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var errors = ref.errors;
return [_c('v-select',{attrs:{"items":_vm.getOptions('lender-pepper-relationship'),"error-messages":errors,"color":"secondary","label":"Relationship with First Application"},model:{value:(_vm.form['lender-pepper-relationship']),callback:function ($$v) {_vm.$set(_vm.form, 'lender-pepper-relationship', $$v)},expression:"form['lender-pepper-relationship']"}})]}}],null,false,3563462987)})],1)],1):_vm._e(),_vm._v(" "),_c('v-row',[_c('v-col',{staticClass:"py-0 px-8"},[_c('ValidationProvider',{key:"privacy_consent",attrs:{"name":"Privacy Consent","rules":"required","vid":"privacy_consent"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var errors = ref.errors;
return [_c('v-checkbox',{attrs:{"color":"secondary"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('div',{domProps:{"innerHTML":_vm._s(_vm.term.attributes.content)}})]},proxy:true}],null,true),model:{value:(_vm.form.privacy_consent),callback:function ($$v) {_vm.$set(_vm.form, "privacy_consent", $$v)},expression:"form.privacy_consent"}})]}}])})],1)],1),_vm._v(" "),_c('v-row',[_c('v-col',{staticClass:"py-0 px-8"},[(_vm.errors.length > 0)?_c('v-alert',{attrs:{"dense":"","outlined":"","type":"error"}},_vm._l((_vm.errors),function(msg){return _c('div',{domProps:{"innerHTML":_vm._s(msg)}})}),0):_vm._e()],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }