<template>
<div class="d-md-flex" style="width: 100%">

      <v-sheet color="light_gray_1" class="py-12 px-12" style="min-width: 45%;" outlined elevation="0">
          <v-img
              max-width="120"
              class="flex-brand"
              :src="require(`~/assets/images/nodifi.svg`)"
              position="center left"
              contain>
          </v-img>
          <div class="mt-2 font-size-20 light_gray_2--text">Data</div>
          <v-list color="transparent" v-if="!entityStep">
              <v-list-item two-line class="px-0">
                  <v-list-item-content>
                      <v-list-item-title class="font-weight-medium">Employer </v-list-item-title>
                      <v-list-item-subtitle>{{selectedIncome ? selectedIncome.attributes['employer-name'] : '&mdash;'}}</v-list-item-subtitle>
                  </v-list-item-content>
              </v-list-item>
              <v-list-item two-line class="px-0">
                  <v-list-item-content>
                      <v-list-item-title class="font-weight-medium">Employment Type</v-list-item-title>
                      <v-list-item-subtitle>{{selectedIncome ? selectedIncome.attributes['employment-job-status'] : '&mdash;'}}</v-list-item-subtitle>
                  </v-list-item-content>
              </v-list-item>
          </v-list>
          <v-list color="transparent" v-else>
              <v-list-item two-line class="px-0">
                  <v-list-item-content>
                      <v-list-item-title class="font-weight-medium">Business Name </v-list-item-title>
                      <v-list-item-subtitle>{{selfEmployedIncome ? selfEmployedIncome.attributes['business-name'] : '&mdash;'}}</v-list-item-subtitle>
                  </v-list-item-content>
              </v-list-item>
              <v-list-item two-line class="px-0">
                  <v-list-item-content>
                      <v-list-item-title class="font-weight-medium">Employment Type</v-list-item-title>
                      <v-list-item-subtitle>Self-Employed</v-list-item-subtitle>
                  </v-list-item-content>
              </v-list-item>
          </v-list>

      </v-sheet>

      <v-sheet class="py-12 px-12">

      <v-img
          max-width="300"
          class="flex-brand"
          :src="require(`~/assets/images/logo/cropped/lender-logo-financeone.png`)"
          position="center left"
          contain>
      </v-img>
      <div class="mt-2 mb-4 font-size-20 light_gray_2--text">Requirements</div>

            <ValidationObserver ref="observer" tag="form">

              <div class="py-0" v-if="!entityStep">
                <ValidationProvider
                  key="otherHasCircumstancesChanging"
                  v-slot="{ errors }"
                  :name="labels['otherHasCircumstancesChanging']"
                  vid="otherHasCircumstancesChanging"
                  :rules="validation_rules.attributes['otherHasCircumstancesChanging']"
                >
                    <ButtonGroup3
                    v-model="otherHasCircumstancesChanging"
                    :error-messages="errors"
                    :options="[ 'Yes','No']"
                    :label="labels['otherHasCircumstancesChanging']"
                  />
                </ValidationProvider>
              </div>
              <div class="py-0" v-if="visible['otherChangeInformation'] && !entityStep">
                <ValidationProvider
                  key="otherChangeInformation"
                  v-slot="{ errors }"
                  :name="labels['otherChangeInformation']"
                  vid="otherChangeInformation"
                >
                  <v-text-field
                    tile
                    type="text"
                    :label="labels['otherChangeInformation']"
                    color="secondary"
                    :error-messages="errors"
                    v-model="resources.person.attributes.lender.financeOne['otherChangeInformation']"
                    required>
                  </v-text-field>
                </ValidationProvider>
              </div>
                  
                <div v-if="selfEmployedId && entityStep">
                  <ValidationProvider
                    key="entityType"
                    v-slot="{ errors }"
                    :name="labels['entityType']"
                    :rules="validation_rules.attributes['entityType']"
                    vid="entityType">
                  <v-select
                      :items="[
                        {value: 'sole_trader', text: 'Sole Trader'},
                        {value: 'partnership', text: 'Partnership'},
                        {value: 'company', text: 'Company'},
                        {value: 'trust', text: 'Trust'},
                      ]"
                      :label="labels['entityType']"
                      :error-messages="errors"
                      v-model="resources.income.attributes.lender.financeOne['entityType']"
                      color="secondary"/>
                  </ValidationProvider>
                </div>

                <v-alert dense outlined type="error" v-if="errors.length > 0">
                    <div v-for="(msg, index) in errors" v-html="msg" :key="index"></div>
                </v-alert>

            </ValidationObserver>

      </v-sheet>
  
</div>
</template>

<script>
import { mapActions, mapMutations, mapGetters } from "vuex";
import subscriptions from "~/components/shared/subscriptions";
import shared from "~/components/v1/submission/FinanceOneEntities1/__shared";
import ButtonGroup3 from "~/components/base/ButtonGroup3";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import FlexDatePicker3 from '~/components/base/FlexDatePicker3';
import veeValidate from '~/components/shared/validation'
import formHelpers from '~/components/shared/form-helpers'
import { parseInt } from "lodash";

export default {
  components: { ButtonGroup3, FlexDatePicker3 },
  mixins: [formHelpers, subscriptions, shared, veeValidate],
  props: {
    data: {
      type: Object,
      default: null,
    },
    selfEmployedId: {
      type: [String, Number],
      default: undefined,
    },
    selectedIncome: {
      type: Object,
      default: null,
    },
    selfEmployedIncome: {
      type: Object,
      default: null,
    },
    entityStep: {
      type: Boolean,
      default: false,
    },
    updater: undefined,
  },
  data() {
    return {
      otherHasCircumstancesChanging: false,
      resources: {
        person: {
          id: null,
          type: "people",
          attributes: {
            "lender": {
                "financeOne": {
                  "otherHasCircumstancesChanging": null,
                  "otherChangeInformation": null,
                }
            }
          },
        },
        income: {
          id: null,
          type: "incomes",
          attributes: {
            lender: {
              "financeOne": {
                "entityType": null
              }
            }
          },
        }

      },
      visible: {
        "otherChangeInformation": true,
      },
      labels: {
        "entityType": "Entity Type",
        "otherHasCircumstancesChanging": "Is the applicant expecting any changes to their circusmtances in the foreseeable future that may impact their ability to repay the loan?",
        "otherChangeInformation": "Details of foreseeable changes",

      },
      validation_rules: {
        attributes: {
          "entityType": "required",
          "otherHasCircumstancesChanging": "required",
          "otherChangeInformation": "required",

        },
      },
      options: {
      },
      errors: [],
    };
  },

  computed: {

  },
  mounted() {
    this.updateResourceFields();
  },
  methods: {
    ...mapActions("resource-actions", [
      "getIncome",
      "getPerson",
    ]),
    async validate() {

      const valid = await this.$refs.observer.validate();

      let allValid = this.errors.length == 0 && valid

      if (allValid) {

        const {income, person} = this.resources

        this.$emit("submit", {
          income: {
            id: income?.id,
            type: "incomes",
            attributes: {
              lender: {
                "financeOne": {
                  "entityType": income.attributes.lender.financeOne.entityType
                }
              }
            },
          },
          person: {
            id: person?.id,
            type: "people",
            attributes: {
              "lender": {
                  "financeOne": {
                    ...person.attributes.lender.financeOne
                  }
              }
            },
          },
        });

      }
    },
    reset() {
      this.$refs.observer.reset();
    },
    async updateResourceFields() {

      // console.log('Updating Resource Fields...')
      // console.log('_FormEmploymentInfo', this.data.id)

      this.$emit("loading", true);

      const { data, selfEmployedId} = this;

      if (data) {

        let res = await this.getPerson(data?.id);

        this.resources.person = this.filterResourceFields(
          this.resources.person,
          res.data.data
        );

        Object.keys(this.resources.person.attributes).forEach((key) => {
          this.resources.person.attributes[key] = this.resources.person.attributes[key];
        });

        const { otherHasCircumstancesChanging } = this.resources.person.attributes.lender.financeOne

        this.otherHasCircumstancesChanging = otherHasCircumstancesChanging === null ? '' : otherHasCircumstancesChanging === true ? 'Yes' : 'No'


        if (selfEmployedId) {

          let res = await this.getIncome(selfEmployedId);

          this.resources.income = this.filterResourceFields(
            this.resources.income,
            res.data.data
          );

          Object.keys(this.resources.income.attributes).forEach((key) => {
            this.resources.income.attributes[key] = this.resources.income.attributes[key];
          });

        }

        this.$emit("loading", false);

      } else {
        this.$emit("loading", false);
      }
    },
  },
  watch: {
    updater(val) {
      if (val == "employment") {
        this.updateResourceFields();
      }
    },
    otherHasCircumstancesChanging(val) {
      if(val == "Yes"){
          this.visible.otherChangeInformation = true
          this.resources.person.attributes.lender.financeOne.otherHasCircumstancesChanging = true
      } else {
          this.visible.otherChangeInformation = false
          this.resources.person.attributes.lender.financeOne.otherChangeInformation = ""
          this.resources.person.attributes.lender.financeOne.otherHasCircumstancesChanging = false
      }
    }
  },
};
</script>

<style lang="scss" scoped>
</style>
