<template>
        <v-card tile flat>
            <ValidationObserver ref="observer">

                <v-row dense >
                    <template v-for="(layouts, resource) in getMultiResourceLayout" v-if="resource !== 'incomes'">
                        <v-col cols="12" v-if="(resource == 'selector')">
                            <v-card tile flat>
                                <template v-for="(layout, index) in buildLayouts(layouts, resource)">
                                    <div class="d-flex flex-column flex-md-row pb-6" v-if="layout.field">
                                        <div class="pr-8">
                                            <div class="pl-0 pt-0 pb-2 v-card__title text-no-wrap">
                                                {{ preset ? capitalize(toSingular(preset)) : capitalize(toSingular(selectedFOName)) }}
                                            </div>
                                            <div class="mb-6">
                                                <div class="body-1 font-weight-medium">
                                                    {{ data.id == '11-16' ? 'Monthly Household ' : ''}}
                                                    {{ `${getKindName(data.id)} ${ development ? data.id : ''}` }}
                                                </div>
                                            </div>  
                                        </div>  
                                        <div class="flex-grow-1">
                                            <ValidationProvider 
                                                v-slot="{ errors }" 
                                                :name="layout.label" 
                                                rules="required">
                                                <RoleSelector 
                                                    v-if="layout.field == 'role-selector'"
                                                    :hidden="selectedEntityAll"
                                                    v-model="activeRoles"
                                                    :label="layout.label"
                                                    :data="filterEntities"
                                                    :parts="entityParts"
                                                    :errors="errors"
                                                />
                                                <AddressTool 
                                                    v-if="layout.field == 'address-selector'"
                                                    v-model="selectedAddress"
                                                    :label="layout.label"
                                                    :addresses="addresses"
                                                    :errors="errors"
                                                />
                                            </ValidationProvider>
                                        </div>
                                    </div>
                                </template>
                            </v-card>
                        </v-col>
                    </template>
                </v-row>

                <v-row dense class="rounded-lg light_gray_1 pa-2">
                    <template v-for="(layouts, resource) in getMultiResourceLayout" v-if="resource !== 'incomes'">
                        <v-col cols="12" :md="getColumnCount" v-if="(resource != 'selector')">

                            <v-card tile flat height="100%" :class="['overflow-auto', {'pa-5': resourcesStatus[resource] == 'active'}]">

                                <template v-if="resourcesStatus[resource] == 'active'">

                                    <template v-for="(layout, index) in buildLayouts(layouts, resource)">
                                            <template v-if="layout.overline">
                                                <div class="d-flex">
                                                    <div class="overline body_text_color--text">
                                                        {{layout.overline}}
                                                        {{ `${ development ? selectedKindIds[resource] : ''}` }}
                                                    </div>
                                                    <v-spacer />
                                                    <v-btn v-if="linkedResources[resource] == 'link'" @click="removeLinkedResource(resource, selectedKindIds[resource])" text color="error" depressed>
                                                        Remove
                                                    </v-btn>
                                                </div>
                                                <!-- #1 disable requiredResources alert for now -->
                                                <v-alert dense type="info" v-if="requiredResources.includes(resource) && false" class="white--text">
                                                    {{ getRequiredFOMsg(resource) }}
                                                </v-alert>
                                            </template>
                                            <template v-else-if="layout.title && layout.if">
                                                <div class="title mt-2 mb-4 body_text_color--text">
                                                    {{ layout.if == selectedKindIds[resource] ? layout.title : '' }}
                                                </div>
                                            </template>
                                            <div class="title mt-2 mb-4 body_text_color--text" v-else-if="layout.title || layout.subtitle">
                                                {{layout.title || layout.subtitle}}
                                            </div>
                                            <div v-else class="flex-form">
                                                <template v-if="resources[resource]">
                                                    <template v-for="(field, key) in fieldLayoutMaker(layout, resources[resource].attributes)">
                                                        <div class="flex-field":key="key">
                                                            <ValidationProvider 
                                                                v-if="isVisible(key)"
                                                                :hidden="isHidden(key)"
                                                                v-slot="{ errors }" 
                                                                :name="labels[key]" 
                                                                :rules="getRules(key)" :vid="key">

                                                                <ButtonGroup1 
                                                                    v-if="isBtnGroup(key)"
                                                                    v-model="resources[resource].attributes[key]"
                                                                    :error-messages="errors"
                                                                    :options="['Yes', 'No']"
                                                                    :label="labels[key]" />

                                                                <AddressTool 
                                                                    v-else-if="isAddressField(key)"
                                                                    v-model="selectedAddress"
                                                                    :label="labels[key]"
                                                                    :addresses="addresses"
                                                                    :errors="errors"
                                                                />


                                                                <v-select
                                                                v-else-if="isFieldType('picklist', key)"
                                                                :items="getOptions(key)"
                                                                :readonly="isReadOnly(key)"
                                                                :label="labels[key]"
                                                                :error-messages="errors"
                                                                v-model="resources[resource].attributes[key]"
                                                                color="secondary">
                                                                </v-select>
                                                                
                                                                <template v-else-if="isFieldType('date', key)">
                                                                <flex-date-picker
                                                                    :label="labels[key]"
                                                                    :errors="errors"
                                                                    v-model="resources[resource].attributes[key]"
                                                                ></flex-date-picker>
                                                                </template>

                                                                <v-textarea 
                                                                v-else-if="isFieldType('textarea', key)"
                                                                :label="labels[key]"
                                                                no-resize
                                                                :error-messages="errors"
                                                                v-model="resources[resource].attributes[key]"
                                                                color="secondary">
                                                                </v-textarea>
                                                                
                                                                <template v-else>

                                                                    <div v-if="living_expense_hints[key]" >
                                                                        
                                                                        <div class="d-block v-input mx-0 pa-0 my-2">
                                                                            <span class="v-label">
                                                                                {{ labels[key] }}
                                                                                <v-tooltip
                                                                                        right
                                                                                        max-width="250">
                                                                                        <template v-slot:activator="{ on, attrs }">
                                                                                            <v-icon
                                                                                                class="mt-n2"
                                                                                                size="17"
                                                                                                v-bind="attrs"
                                                                                                v-on="on">
                                                                                                mdi-information
                                                                                            </v-icon>
                                                                                        </template>
                                                                                        <span>{{ living_expense_hints[key] }}</span>
                                                                                </v-tooltip>
                                                                            </span>
                                                                        </div>

                                                                        <v-text-field 
                                                                        class="pa-0 ma-0"
                                                                        :type="'text'"
                                                                        :readonly="isReadOnly(key)"
                                                                        :error-messages="errors"
                                                                        v-model="resources[resource].attributes[key]"
                                                                        color="secondary">
                                                                        </v-text-field>

                                                                    </div>

                                                                    <v-text-field 
                                                                    v-else
                                                                    :type="'text'"
                                                                    :label="labels[key]"
                                                                    :readonly="isReadOnly(key)"
                                                                    :error-messages="errors"
                                                                    v-model="resources[resource].attributes[key]"
                                                                    color="secondary">
                                                                    </v-text-field>

                                                                </template>
                                                               
                                                            </ValidationProvider>
                                                        </div>

                                                    </template>
                                                </template>
                                            </div>
                                    </template>

                                </template>

                                <template v-if="resourcesStatus[resource] == 'inactive'">
                                        <template v-if="tabable(layouts)">

                                            <div class="d-flex flex-column justify-center align-center pa-5 rounded-lg">
                                                <!-- #2 disable requiredResources alert for now -->
                                                <v-alert dense type="info" :icon="false" v-if="requiredResources.includes(resource)" width="100%" class="white--text">
                                                    <div class="d-flex">
                                                        <v-icon class="mr-3">mdi-information</v-icon>
                                                        <div class="body-2 font-weight-medium">Required.<br/>Please select a related {{toSingular(resource)}}</div>
                                                    </div>
                                                </v-alert>
                                                <v-alert dense type="info" v-else width="100%" class="white--text info-bar">
                                                    <div class="body-2 font-weight-medium">Please select a related {{toSingular(resource)}}</div>
                                                </v-alert>

                                                <v-sheet color="light_gray_1 rounded-lg pa-2 pb-0" width="100%">
                                                    <v-card
                                                        class="d-flex rounded pa-1 mb-2 justify-center align-center"
                                                        tile flat
                                                        width="100%"
                                                        height="auto"
                                                        v-for="(layout, key) in layouts"
                                                        :key="key"
                                                        @click="handleAddResource(resource, layout.id)">
                                                            <div class="body-2 font-weight-medium px-3">{{layout.tab}}</div>
                                                            <v-spacer></v-spacer>
                                                            <v-btn small depressed color="secondary" class="px-2">Select</v-btn>
                                                    </v-card>
                                                </v-sheet>
                                                
                                            </div>
                                            
                                        </template>
                                        <template v-else>
                                            <v-card
                                                tile flat
                                                height="100%"
                                                :ripple="false"
                                                class="d-flex flex-column justify-center align-center pa-5 rounded-lg"
                                                @click="handleAddResource(resource)"
                                                >
                                                <div class="body-1 font-weight-bold py-4 light_gray_2--text text-capitalize mx-auto text-center">
                                                    Add {{ resource | toSingularResource }}
                                                </div>
                                            </v-card>
                                        </template>
                                </template>

                                <template v-if="resourcesStatus[resource] == 'unavailable'">
                                        <v-card
                                        tile flat
                                        disabled
                                        height="100%"
                                        class="d-flex flex-column justify-center align-center pa-5 rounded-lg"
                                        >
                                        <div class="body-1 font-weight-bold py-4 light_gray_2--text mx-auto text-center text-capitalize">
                                            {{ resource | toSingularResource }}
                                            Not Available
                                        </div>
                                    </v-card>
                                </template>

                            </v-card>

                        </v-col>
                    </template>
                </v-row>


            </ValidationObserver>

        </v-card>
</template>

<script>

    import { mapState, mapActions, mapGetters, mapMutations } from 'vuex'
    import {store, actions} from '~/components/v1/FinancialObjectsEditor1/__store'

    import formHelpers from '~/components/shared/form-helpers'
    import validation from '~/components/shared/validation'
    import shared from '~/components/v1/FinancialObjectsEditor1/__shared'

    import RoleSelector from '~/components/v1/FinancialObjectsEditor1/_RoleSelector'
    import AddressTool from '~/components/v1/FinancialObjectsEditor1/_AddressTool2'
    import fieldOptions from '~/components/shared/field-options'

    import foFieldLayouts from '~/components/v1/FinancialObjectsEditor1/fo-field-layouts'
    import foLinkedResources from '~/components/v1/FinancialObjectsEditor1/fo-linked-resources'
    import foLabels from '~/components/v1/FinancialObjectsEditor1/fo-labels'

    import baseComponents from '~/components/base'

    export default {
        name: 'FOForms',
        components:{
            ...baseComponents,
            RoleSelector,
            AddressTool
        },
        mixins: [validation, formHelpers, shared],
        props: {
           standalone: {
                type: Boolean,
                default: false
            },
           data: {
                type: Object,
                default: null
            },
            fieldsData: {
                type: Array,
                default: () => ([])
            }
        },
        data() {
            return {
                readonly: {},
                visible: {
                    "other-institution": false,
                    "asset-description": false,
                    "liability-description": false,
                },
                hidden: {

                },
                activeRoles: [],
                selectedKindIds: {
                    assets: null,
                    liabilities: null,
                    expenses: null,
                },
                // ⚠️ currenlty selectedAddress is only for assets, split this if needed by other FOs
                selectedAddress: null, 
                fieldLayout: foFieldLayouts,
                foLinkedResources: foLinkedResources,
                resources: {
                    incomes: {
                        type: "incomes",
                        attributes: {}
                    },
                    assets: {
                        type: "assets",
                        attributes: {
                            "asset-description": null,
                            "asset-value": null,
                            "bank-account-name": null,
                            "bank-account-number": null,
                            "bsb-number": null,
                            "being-refinanced": null,
                            "being-sold-traded": null,
                            "covered-by-insurance": null,
                            "description-other": null,
                            "financial-institution": null,
                            "make": null,
                            "model": null,
                            "other-institution": null,
                            "year-of-manufacture": null,
                            "__full-address": null,
                            "__spacer": null,
                        }
                    },
                    liabilities: {
                        type: "liabilities",
                        attributes: {
                            "amount-financed": null,
                            "amount-owing": null,
                            "credit-limit": null,
                            "description-other": null,
                            "end-date": null,
                            "financial-institution": null,
                            "interest-rate": null,
                            "liability-description": null,
                            "loan-account-number": null,
                            "mortgage-structure": null,
                            "other-institution": null,
                            "to-be-paid-out": null,
                            "__spacer": null,
                        }
                    },
                    expenses: {
                        type: "expenses",
                        attributes: {
                            "payment-amount": null,
                            "payment-frequency": null,
                            "financial-institution": null,
                            "other-institution": null,
                            "to-be-paid-out": null,
                            "living-food-and-clothing": null,
                            "living-transport": null,
                            "living-utilities-and-rates": null,
                            "living-lifestyle": null,
                            "living-tv-and-communications": null,
                            "living-child-care-fees": null,
                            "living-family-maintenance": null,
                            "living-insurance": null,
                            "living-education": null,
                            "living-other-living-expenses": null,
                            "__rental-address": null,
                            "__spacer": null,
                        }
                    }
                },
                living_expense_hints: {
                    "living-food-and-clothing": "Typical supermarket shop including food, cleaning products, toiletries. And essential household spending on clothing, shoes, personal grooming, cosmetics, etc.",
                    "living-transport": "Client spend on public transport and vehicle tuning costs (eg registration, servicing, petrol)",
                    "living-utilities-and-rates": "Electricity, gas and water bills for the household.",
                    "living-lifestyle": "Regular spending on entertainment, lifestyle hobbies (eg gym, eating out, pets, movies, travel)",
                    "living-tv-and-communications": "Phone, Internet, Pay tv",
                    "living-child-care-fees": "Out of pocket child care expenses",
                    "living-family-maintenance": "Family maintenance obligations, eg child support",
                    "living-insurance": "All personal and household insurance (eg health, cars, house)",
                    "living-education": "All education-related costs, such as school/training fees, uniforms, books, excursions, etc.",
                    "living-other-living-expenses": "Any other non-essential monthly expenses (eg. gardener, cleaner, housekeeper, gifts, donations, medical, etc)",
                },
                resourcesStatus: {
                    assets: 'inactive', // active, inactive, unavailable
                    liabilities: 'inactive', // active, inactive, unavailable
                    expenses: 'inactive', // active, inactive, unavailable
                },
                linkedResources: {
                    assets: 'unlink', // link, unlink
                    liabilities: 'unlink', // link, unlink
                    expenses: 'unlink', // link, unlink
                },
                requiredResources: [],
                validation_rules: {
                    attributes: {
                        "asset-description": "required|string",
                        "liability-description": "required|string",
                        "payment-amount": "required|between:0,99999999.99",
                        "financial-institution": "required",
                        "other-institution": "",
                        "asset-value": "required|between:0,99999999.99",
                        "bank-account-name": "required|string",
                        "bsb-number": "required|integer",
                        "bank-account-number": "required|integer",
                        "covered-by-insurance": "required|in:Yes,No",
                        "being-refinanced": "required|string|in:Yes,No",
                        "being-sold-traded": "required|string|in:Yes,No",
                        "amount-owing": "required|between:0,99999999.99",
                        "to-be-paid-out": "required|string|in:Yes,No",
                        "loan-account-number":"",
                        "mortgage-structure": "required|in:Variable,Fixed,Fixed/Variable",
                        "interest-rate": "required|between:0,99.99",
                        "amount-financed": "required|between:0,99999999.99",
                        "end-date": "required|date_format:Y/m/d",
                        "payment-frequency": 'required|in:Weekly,Fortnightly,Monthly,Annual',
                        "year-of-manufacture": "required|integer|digits_between:4,4",
                        "make": "required|string",
                        "model": "required|string",
                        "description-other": "required|string",
                        "credit-limit": "required|between:0,99999999.99",
                        "living-food-and-clothing": "required|between:0,99999999.99",
                        "living-transport": "required|between:0,99999999.99",
                        "living-utilities-and-rates": "required|between:0,99999999.99",
                        "living-lifestyle": "required|between:0,99999999.99",
                        "living-tv-and-communications": "required|between:0,99999999.99",
                        "living-child-care-fees": "required|between:0,99999999.99",
                        "living-family-maintenance": "required|between:0,99999999.99",
                        "living-insurance": "required|between:0,99999999.99",
                        "living-education": "required|between:0,99999999.99",
                        "living-other-living-expenses": "required|between:0,99999999.99",

                        "__full-address": 'required',
                        "__rental-address": 'required',
                    }
                }
            }
        },
        computed: {
            ...mapGetters('kinds', ['kinds', 'kindsUnformat', 'kindsOfApplicant']),
            ...mapState('flows', {
                appId: state => state.appId,
                appTypeFE: state => state.appTypeFE
            }),
            labels(){
                const {assets, liabilities, expenses} = foLabels
                return {
                    ...assets, 
                    ...liabilities, 
                    ...expenses
                }
            },
            getActiveFieldLayout(){
                
                const {data, fieldLayout, fieldsData} = this

                if(!data) return {};

                // console.log(data)
                // console.log(fieldLayout)
                // console.log(fieldsData)

                let keys = Object.keys(fieldLayout)

                let layout = {}

                for (let i = 0; i < keys.length; i++) {

                    const key = keys[i];
                    const items = key.split('|')
                    const dataId = data.id


                    // check if dataId has multiple ids
                    if( dataId.split('|').length > 1 ){

                        if( key == dataId ){

                            layout = {
                                ...fieldLayout[key] // spread fieldLayout to remove reactivity
                            }
                            
                            layout[data.type] = key

                            break
                        }
                    }

                    if(items.includes(dataId)) {
                        layout = fieldLayout[key]
                        break
                    }

                }

                if(Object.keys(layout).length == 0){
                    console.log('Uh oh!, check field layout if configured correctly.')
                }

                return layout

            },
            getMultiResourceLayout(){

                const {getActiveFieldLayout, fieldLayout} = this
                let resources = Object.keys(getActiveFieldLayout)
                let layout = {}

                resources.forEach(resource => {
                    /**
                     * {itemValue} is either "unavailable" or kind id 
                     * 
                     *  if itemValue == kind.id e.g "13-20"
                     *  find kind.id value from {fieldLayout}
                     */

                    let resourceValue = getActiveFieldLayout[resource]

                    if(typeof resourceValue  == "string"){

                        if(resourceValue != 'unavailable') {

                            let ids = resourceValue.split('|')
                            let keys = Object.keys(fieldLayout)
                            
                            if(ids.length > 1) {

                                layout[resource] = {}

                                ids.forEach( id => {

                                    for (let i = 0; i < keys.length; i++) {

                                        const key = keys[i];
                                        const items = key.split('|')

                                        if(items.includes(id)) {
                                            
                                            let collection = fieldLayout[key][resource]

                                            let title = collection.find( item => {

                                                if(item.hasOwnProperty("title")){

                                                    if(item.hasOwnProperty("if")){

                                                        if(item.if == id) {
                                                            return item
                                                        } else {
                                                            return false
                                                        }
                                                    }
                                                    return item
                                                }
                                            })

                                            layout[resource][id] = {
                                                id,
                                                tab: title ? title.title : '',
                                                content: collection
                                            }

                                            break
                                        }
                                    }

                                })

                            } else {

                                layout[resource] = []

                                for (let i = 0; i < keys.length; i++) {

                                    const key = keys[i];
                                    const items = key.split('|')

                                    if(items.includes(resourceValue)) {
                                        let collection = fieldLayout[key][resource]
                                        layout[resource].push(...collection)
                                        break
                                    }

                                }

                            }

                        } else {
    
                            layout[resource] = resourceValue

                        }

                    } else {

                        layout[resource] = resourceValue
                    }

                })

                return layout

            },
            getColumnCount(){

                let keys = Object.keys(this.getActiveFieldLayout)

                let count = keys.length 

                // Exclude incomes from count
                if(keys.includes('incomes')){
                    count--
                }

                switch (count) {
                    case 2:
                        return false                        
                    case 3:
                        return 6                        
                    case 4:
                        return 4                        
                    default:
                        return false;
                }

            },
            foName(){

                if(store.preset){
                    return store.preset
                }

                if(store.steps.length < 2 ) {
                    return null
                }
                
                let fo = store.steps[1]

                switch (fo.name) {
                    case 'Asset':
                    return 'assets'
                    case 'Liability':
                    return 'liabilities'
                    case 'Expense':
                    return 'expenses'
                }
                
            },
            selectedEntity(){
                
                let index = store.selectedEntity

                if(this.entities[index]) return this.entities[index];

                return null

            },
            filterEntities() {
                let entities = []
                // if commercial and = living expenses
                if (store.loanType == 'Commercial' && this.data.id == '11-16') {

                    this.entities.forEach(entity => {
                        if (entity.type != 'businesses') {
                            entities.push(entity)
                        }
                    })

                    return entities
                } else {
                    return this.entities
                }
            },
            preSelectRoles(){
                let clone = [...store.preSelectRoles]
                return clone
            },
            preSelectAllRoles(){
                let clone = []
                this.entities.forEach(entity => {
                    clone.push(entity.id)
                })
                return clone
            },
            getTotalLivingExpenses(){

                let fields = [
                            "living-food-and-clothing",
                            "living-transport",
                            "living-utilities-and-rates",
                            "living-lifestyle",
                            "living-tv-and-communications",
                            "living-child-care-fees",
                            "living-family-maintenance",
                            "living-insurance",
                            "living-education",
                            "living-other-living-expenses"
                            ]

                let total = 0

                fields.forEach(field => {

                    if(this.resources['expenses'].attributes[field]){
                        total = total + parseFloat(this.resources['expenses'].attributes[field])
                    }

                })

                return total

            },
            FieldFinancialInstitution(){
                const {assets, liabilities} = this.selectedKindIds

                if (assets) {
                    return this.resources['assets'].attributes['financial-institution']
                } else if (liabilities) {
                    return this.resources['liabilities'].attributes['financial-institution']
                } else {
                    return this.resources['expenses'].attributes['financial-institution']
                }
            },
            /**
             *  Do conditional fields here
             *  see watcher for samples
             */
            selectedKindAssetId(){
                return this.selectedKindIds.assets
            },
            selectedKindLiabilityId(){
                return this.selectedKindIds.liabilities
            },
            selectedKindExpenseId(){
                return this.selectedKindIds.expenses
            },

            liabilitiesFinancialInstitution(){
                return this.resources.liabilities.attributes['financial-institution']
            },
            expensesFinancialInstitution(){
                return this.resources.expenses.attributes['financial-institution']
            },
            liabilitiesOtherInstitution(){
                return this.resources.liabilities.attributes['other-institution']
            },
            expensesOtherInstitution(){
                return this.resources.expenses.attributes['other-institution']
            },
            liabilitiesToBePaidOut(){
                return this.resources.liabilities.attributes['to-be-paid-out']
            },
            expensesToBePaidOut(){
                return this.resources.expenses.attributes['to-be-paid-out']
            }

        },
        mounted(){

            /**
             * This will always trigger when the slider reaches the form
             */
            
            // console.log(this.data)
            // console.log(this.fieldsData)

            this.initialize()
        },
        methods: {
            ...mapActions('resource-actions', [
                'createAsset',
                'createLiability',
                'createExpense',
                'createAddress',
                'updateAsset',
                'updateLiability',
                'updateExpense',
                'getIncome',
                'getAsset',
                'getExpense',
                'getLiability'
            ]),
            isVisible(key){
                
                const {visible} = this

                if(visible.hasOwnProperty(key)){
                    return visible[key]
                }

                return true

            },
            isHidden(key){
                
                const {hidden} = this

                if(hidden.hasOwnProperty(key)){
                    return hidden[key]
                }

                return false

            },
            openLinkedResource(resource, id){

                let linkedResourceData = this.foLinkedResources[resource]

                if( linkedResourceData.hasOwnProperty(id) ){

                    let part = linkedResourceData[id]['part']
                    let key = linkedResourceData[id]['id']

                    // If Linked Status is already active... do nothing
                    if(this.resourcesStatus[part] == 'active') {
                        return
                    }

                    this.resourcesStatus[part] = 'active'
                    this.linkedResources[part] = 'link'
                    this.selectedKindIds[part] = key

                    this.initializeLinkedResource(key)

                }
            },
            closeLinkedResource(resource, id){

                let linkedResourceData = this.foLinkedResources[resource]

                if( linkedResourceData.hasOwnProperty(id) ){

                    let part = linkedResourceData[id]['part']
                    let key = linkedResourceData[id]['id']

                    // If Linked Status is already unlinked... do nothing
                    if(this.linkedResources[part] == 'unlink') {
                        return
                    }

                    this.resourcesStatus[part] = 'inactive'
                    this.linkedResources[part] = 'unlink'
                    this.selectedKindIds[part] = ''

                    this.resetLinkedResource(key)

                }
            },
            handleAddResource(resource, id){

                // console.log(id)
                // console.log(resource)

                this.resourcesStatus[resource] = 'active'
                this.linkedResources[resource] = 'link'

                /**
                 * if has an ID, it came from tabable select.
                 */
                if(id){

                    this.selectedKindIds[resource] = id

                    this.initializeLinkedResource(id)
                    this.openLinkedResource(resource, id)


                } else {

                    this.selectedKindIds[resource] = this.getActiveFieldLayout[resource]
                    
                    this.openLinkedResource(resource, this.selectedKindIds[resource])
                }


            },
            removeLinkedResource(resource, id) {
               
                this.resourcesStatus[resource] = 'inactive'
                this.linkedResources[resource] = 'unlink'

                this.selectedKindIds[resource] = ''

                if (id) {

                    this.resetLinkedResource(id)
                    this.closeLinkedResource(resource, id)
                }

            },
            initializeLinkedResource(id) {

                // Own Outright
                if( id == '11-7') {
                    this.resources.expenses.attributes["payment-frequency"] = 'Monthly'
                    this.resources.expenses.attributes["payment-amount"] = 1
                    this.readonly["payment-amount"] = true
                    this.readonly["payment-frequency"] = true
                }
            },
            resetLinkedResource(id) {

                // Own Outright
                if( id == '11-7') {
                    this.resources.expenses.attributes["payment-frequency"] = ''
                    this.resources.expenses.attributes["payment-amount"] = ''
                    this.readonly["payment-amount"] = false
                    this.readonly["payment-frequency"] = false
                }

            },
            initialize(){

                const {getActiveFieldLayout, selectedEntity, data, fieldsData, standalone, foName, foRequiredLogic} = this

                /**
                 * Logic for hiding / showing resources, fields, options, and other stuff
                 * 
                 * NOTE: See watcher for samples of hiding fields
                 * 
                 */


                if(standalone){  // FOForms opened from FOTable

                    // ✔️include resources id
                    // ✔️activate resources form already filled
                    // ✔️include selected roles 
                    // ✔️include selected addresses 

                    let roles = []

                    fieldsData.forEach(item => {

                        if(item.hasOwnProperty('attributes')){

                            if(item.type != 'incomes'){
                                this.resourcesStatus[item.type] = 'active'
                                this.selectedKindIds[item.type] = item.relationships.kind.data?.id
                            }

                            this.resources[item.type]['id'] = item.id
                            this.resources[item.type]['attributes'] = item.attributes

                            // Include place holder fields
                            this.resources.expenses.attributes['__sum-living-expenses'] = null
                            this.resources.assets.attributes['__full-address'] = null
                            this.resources.expenses.attributes['__rental-address'] = null
                            this.resources.assets.attributes['__spacer'] = ''


                            // ✔️Collect all roles of selected FO

                            if(data.type == item.type){

                                item.relationships.people.data.forEach(person => {

                                    if(!roles.includes(person.id)){
                                        roles.push(person.id)
                                    }

                                })

                                item.relationships.businesses.data.forEach(business => {

                                    if(!roles.includes(business.id)){
                                        roles.push(business.id)
                                    }

                                })

                            }

                            // ✔️Get assets address
                            if(item.type == 'assets'){

                                let addressData = item.relationships.address.data

                                if(addressData) {

                                    this.addresses.forEach(address => {

                                        if(address.id == addressData.id){
                                            this.selectedAddress = address
                                        }

                                    })

                                }

                            }

                             // ✔️Get expenses address
                             if(item.type == 'expenses'){

                                let addressData = item.relationships.address.data

                                if(addressData) {

                                    this.addresses.forEach(address => {

                                        if(address.id == addressData.id){
                                            this.selectedAddress = address
                                        }

                                    })

                                }

                            }

                        } else {

                            this.resourcesStatus[item.type] = item.status

                        }

                    })

                    this.resourcesStatus[data.type] = (data.id.split('|').length > 1) ? 'inactive' : 'active';
                    this.selectedKindIds[data.type] = data.id

                    this.activeRoles = roles

                } else {

                    this.resourcesStatus[this.foName] = 'active'
                    this.selectedKindIds[this.foName] = this.data.id

                    Object.keys(getActiveFieldLayout).forEach(item => {

                        let itemValue = getActiveFieldLayout[item]

                        /**
                         * {itemValue} is either "unavailable" or kind id 
                         */

                        if(typeof itemValue == "string"){

                            if(itemValue === 'unavailable') {
                                this.resourcesStatus[item] = "unavailable"
                            } else {
                                this.resourcesStatus[item] = "inactive"
                            }
                        }

                    })

                    if(selectedEntity){
                        this.activeRoles = [selectedEntity.id]
                    }

                    if(this.selectedEntityAll){
                        this.activeRoles = this.preSelectRoles
                    }

                }

                // Perform Required FO Logic:

                try {

                    let selectedName = standalone ? data.type : foName;

                    let logic = foRequiredLogic[selectedName][data.id]

                    if(logic){

                        logic.forEach( name => {

                            let filled = false 
                            
                            fieldsData.forEach( d => {

                                if(d.type == name){

                                    if(d.hasOwnProperty('attributes')){
                                        filled = true 
                                    }

                                }

                            })

                            if(!filled){
                                this.requiredResources.push(name)
                            }

                            let itemValue = getActiveFieldLayout[name]

                            // Check if multiple selection
                            if(typeof itemValue == "string"){

                                let selections = itemValue.split('|')

                                if(selections && selections.length == 1){
                                    this.resourcesStatus[name] = "active"
                                    this.selectedKindIds[name] = getActiveFieldLayout[name]
                                }

                            } 
                            

                        })
                    }


                } catch (error) {
                    console.log('Ooops!..', error)
                }

                // Combination boxes, auto activate assets row
                // TBD: look for better approach???
                
                if(getActiveFieldLayout.incomes){
                    if(data.type == 'liabilities' || data.type == 'expenses') {
                        if(getActiveFieldLayout.assets && getActiveFieldLayout.assets != "unavailable") {
                            this.resourcesStatus['assets'] = "active"
                        }
                    }
                } 

                this.validation_rules.attributes["financial-institution"] = `required|in:${fieldOptions["financial-institution"]}`

                //  Business Account
                if (this.data.name === 'Business Account' || this.data.name === 'Personal Account' || this.data.name === 'Term Deposit') {
                    this.validation_rules.attributes["bank-account-name"] = ''
                    this.validation_rules.attributes["bank-account-number"] = ''
                    this.validation_rules.attributes["bsb-number"] = ''
                }

                //  Business Account
                if (this.data.name === 'Home Contents') {
                    this.validation_rules.attributes["covered-by-insurance"] = ''
                }

                // console.log(this.data.name)
                
                //  Office Equipment
                if (this.data.name === 'Office Equipment (Copiers etc)' || this.data.name === 'Computer Hardware' || this.data.name === 'POS Systems' || this.data.name === 'Solar Equipment' || this.data.name === 'Other (Not Listed)') {
                    this.validation_rules.attributes["make"] = ''
                    this.validation_rules.attributes["model"] = ''
                    this.validation_rules.attributes["year-of-manufacture"] = ''
                }

                // Mortgage - Owner Occupied
                if (this.data.name === 'Mortgage-Owner Occupied') {
                    this.visible["financial-institution"] = true
                }

                // Living Expenses
                if (this.data.name === 'Living Expenses') {

                    this.resources.expenses.attributes["payment-frequency"] = 'Monthly'
                    this.resources.expenses.attributes["payment-amount"] = 0
                    this.hidden["payment-frequency"] = true
                    this.hidden["payment-amount"] = true

                    // Pre-selected entities

                    if(this.appTypeFE.type == 'Consumer - Joint' || this.appTypeFE.type == 'Consumer - Individual - Non Borrowing Spouse') {
                        this.activeRoles = this.preSelectAllRoles
                    } else {
                        this.activeRoles = [this.selectedEntity.id]
                    }

                }

                // Renter Agent
                if (this.data.id === '11-8') {
                    
                    // get renter agent address and occupancy
                    if (this.addresses && this.occupancies) {
                        let selectedOccupancy = null

                        // get renter agent occupancies
                        this.occupancies.forEach(occupancy => {
                            if(occupancy.attributes['occupancy-situation'] == 'Current') {
                                if(occupancy.relationships.person.data.id === this.selectedEntity.id && occupancy.attributes['residential-situation'] === 'Renter - Agent') {
                                    selectedOccupancy = occupancy
                                }
                            }
                        })

                        // get address
                        if(selectedOccupancy) {
                            this.addresses.forEach(address => {
                                if(address.relationships.occupancies.data.length) {
                                    address.relationships.occupancies.data.forEach(adc => {
                                        if(adc.id === selectedOccupancy.id) {
                                            this.selectedAddress = address
                                        }
                                    })
                                }
                            })
                        }
                    }
                }

                // Renter Private
                if (this.data.id === '11-9') {
                    // get renter agent address and occupancy
                    if (this.addresses && this.occupancies) {
                        let selectedOccupancy = null

                        // get renter private occupancies
                        this.occupancies.forEach(occupancy => {
                            if(occupancy.attributes['occupancy-situation'] == 'Current') {
                                if(occupancy.relationships.person.data.id === this.selectedEntity.id && occupancy.attributes['residential-situation'] === 'Renter - Private') {
                                    selectedOccupancy = occupancy
                                }
                            }
                        })

                        // get address
                        if(selectedOccupancy) {
                            this.addresses.forEach(address => {
                                if(address.relationships.occupancies.data.length) {
                                    address.relationships.occupancies.data.forEach(adc => {
                                        if(adc.id === selectedOccupancy.id) {
                                            this.selectedAddress = address
                                        }
                                    })
                                }
                            })
                        }
                    }
                }

                // Expense > Own Outright
                if (this.data.id === '11-7') {
                    this.resources.expenses.attributes["payment-frequency"] = 'Monthly'
                    this.resources.expenses.attributes["payment-amount"] = 1
                    this.visible["other-institution"] = false
                    this.readonly["payment-amount"] = true
                    this.readonly["payment-frequency"] = true
                }

                // Credit Card 13-2
                if (this.data.id === '13-2') {
                    this.resources.expenses.attributes["financial-institution"] = this.resources.liabilities.attributes["inancial-institution"]
                    this.resources.expenses.attributes["to-be-paid-out"] = this.resources.liabilities.attributes["to-be-paid-out"]
                }

                // Loan Asset 13-9
                if (this.data.id === '13-8') {
                    this.resources.expenses.attributes["financial-institution"] = this.resources.liabilities.attributes["inancial-institution"]
                    this.resources.expenses.attributes["to-be-paid-out"] = this.resources.liabilities.attributes["to-be-paid-out"]
                }

                // Loan other 13-12
                if (this.data.id === '13-12') {
                    this.resources.expenses.attributes["financial-institution"] = this.resources.liabilities.attributes["inancial-institution"]
                    this.resources.expenses.attributes["to-be-paid-out"] = this.resources.liabilities.attributes["to-be-paid-out"]
                }

                // Loan other 13-22
                if (this.data.id === '13-22') {
                    this.resources.expenses.attributes["to-be-paid-out"] = this.resources.liabilities.attributes["to-be-paid-out"]
                }

                // Owner Occupied 10-16
                if (this.data.id === '10-16') {

                    // get current address by occupancies
                    if (this.addresses && this.occupancies) {
                        let currentOccupancy = null

                        // get renter agent occupancies
                        this.occupancies.forEach(occupancy => {
                            if(occupancy.attributes['occupancy-situation'] == 'Current') {
                                if(occupancy.relationships.person.data.id === this.selectedEntity.id) {
                                    currentOccupancy = occupancy
                                }
                            }
                        })

                        // get address
                        if(currentOccupancy) {
                            this.addresses.forEach(address => {
                                if(address.relationships.occupancies.data.length) {
                                    address.relationships.occupancies.data.forEach(adc => {
                                        if(adc.id === currentOccupancy.id) {
                                            this.selectedAddress = address
                                        }
                                    })
                                }
                            })
                        }
                    }

                }

                // Superannuation
                if (this.data && this.data.id == "10-12") {
                    this.visible["description-other"] = false
                }
                
                // Other Vehicle 10-45
                if (this.data && this.data.id == "10-45") {
                    this.visible["year-of-manufacture"] = false
                    this.visible["make"] = false
                    this.visible["model"] = false
                }
                
                
                // Assets > Stock & Equipment > Equipment > ALL
                if (this.data && ["10-19", "10-20", "10-21", "10-22", "10-23", "10-24", "10-25", "10-26"].includes(this.data.id)) {
                    this.visible["year-of-manufacture"] = false
                    this.visible["make"] = false
                    this.visible["model"] = false
                }


            },
            isReadOnly(key){
                
                const {readonly} = this

                if(readonly.hasOwnProperty(key)){
                    return readonly[key]
                }

                return false

            },
            tabable(layouts){
                return ((typeof layouts === 'object') && !(layouts instanceof Array))
            },
            buildLayouts(layouts, resource){

                const {selectedKindIds} = this

                // console.log('layouts',layouts)
                // console.log('resource',resource)
                // console.log('selectedKindIds',selectedKindIds)

                // For FO with selections (tabable)
                if(typeof layouts === 'object' &&  !(layouts instanceof Array)) {

                    let kind = selectedKindIds[resource]

                    // console.log('1. ', resource)
                    // console.log('2. ', selectedKindIds)
                    // console.log('3. ', layouts)
                    // console.log('4. ', kind)
                    if(layouts[kind]){

                        return  layouts[kind].content

                    } else {

                        // console.log('Ooops!.. Please check layout')
                        return layouts

                    }
                }

                return layouts
            },
            async save(){

                const valid = await this.$refs.observer.validate();

                const {assets, liabilities, expenses} = this.selectedKindIds

                if(valid){
    
                    actions.toggleSaving(true)

                    let assetId = null
                    let expenseId = null
                    let liabilityId = null

                    try {

                        if(assets){
                            const res1 = await this.saveAsset()
                            assetId = res1.data.data.id
                        }

                        if(expenses){
                            const res2 = await this.saveExpense(assetId)
                            expenseId = res2.data.data.id
                        }

                        if(liabilities){
                            let res3 = await this.saveLiability(assetId, expenseId)
                            liabilityId = res3.data.data.id
                        }

                        await this.updateLocalResources(assetId, expenseId, liabilityId)

                        await this.updateEntitiesAndParts()

                        store.validate()

                        actions.toggleSaving(false)

                        return {status: 'ok'}

                    } catch (error) {

                        console.log('Ooops!..', error)
                        actions.toggleSaving(false)

                    }

                }

                return {status: 'fail'}

            },
            async saveAsset(){

                const observer = await this.$refs.observer;

                const {selectedAddress} = this
                const {incomes, assets} = this.resources

                let payload = {
                    type: 'assets',
                    attributes: this.excludeInvalids(assets.attributes, observer.fields),
                    relationships: {
                        application: {
                            data: {type: "applications", id: this.appId}
                        },
                        people: this.getPeopleRelationship(),
                        businesses: this.getBusinessesRelationship(),
                        kind: {
                            data: {
                                type: 'kinds',
                                id: this.selectedKindIds.assets
                            }
                        }
                    }
                }

                if(selectedAddress != null){
                    
                    if(selectedAddress.id){

                        payload.relationships.address = {
                            data: {
                                type: 'addresses',
                                id: selectedAddress.id 
                            }
                        }

                    } else {

                        let relationships = {
                            application: {
                                data: {type: "applications", id: this.appId}
                            }
                        }

                        this.selectedAddress.relationships = relationships

                        let address = await this.createAddress(selectedAddress)

                        actions.updateAddress({key: 'assets', value: address.data.data})

                        payload.relationships.address = {
                            data: {
                                type: 'addresses',
                                id: address.data.data.id
                            }
                        }

                    }

                }

                if(incomes.id){
                    payload.relationships.incomes = {
                        data: [
                            {
                                type: 'incomes',
                                id: incomes.id 
                            }
                        ],
                        meta: {
                            strategy: "attach"  
                        }
                    }
                }

                if(assets.id){

                    payload.id = assets.id 

                    return this.updateAsset(payload)

                } else {

                    return this.createAsset(payload)
                }

            },
            async saveExpense(assetId){

                const observer = await this.$refs.observer;

                const {selectedAddress} = this
                const {expenses} = this.resources

                let payload = {
                    type: 'expenses',
                    attributes: this.excludeInvalids(expenses.attributes, observer.fields),
                    relationships: {
                        application: {
                            data: {type: "applications", id: this.appId}
                        },
                        people: this.getPeopleRelationship(),
                        businesses: this.getBusinessesRelationship(),
                        kind: {
                            data: {
                                type: 'kinds',
                                id: this.selectedKindIds.expenses
                            }
                        }
                    }
                }

                if(selectedAddress != null){
                    
                    if(selectedAddress.id){

                        payload.relationships.address = {
                            data: {
                                type: 'addresses',
                                id: selectedAddress.id 
                            }
                        }

                    } else {

                        let relationships = {
                            application: {
                                data: {type: "applications", id: this.appId}
                            }
                        }

                        this.selectedAddress.relationships = relationships

                        let address = await this.createAddress(selectedAddress)

                        actions.updateAddress({key: 'assets', value: address.data.data})

                        payload.relationships.address = {
                            data: {
                                type: 'addresses',
                                id: address.data.data.id
                            }
                        }

                    }

                }

                if(assetId){
                    payload.relationships.assets = {
                        data: [
                            {
                                type: 'assets',
                                id: assetId 
                            }
                        ],
                        meta: {
                            strategy: "attach"  
                        }
                    }
                }

                if(expenses.id){

                    payload.id = expenses.id 
                    
                    return this.updateExpense(payload)

                } else {

                    return this.createExpense(payload)
                }

            },
            async saveLiability(assetId, expenseId){

                const observer = await this.$refs.observer;

                const {liabilities} = this.resources

                let payload = {
                    type: 'liabilities',
                    attributes: this.excludeInvalids(liabilities.attributes, observer.fields),
                    relationships: {
                        application: {
                            data: {type: "applications", id: this.appId}
                        },
                        people: this.getPeopleRelationship(),
                        businesses: this.getBusinessesRelationship(),
                        kind: {
                            data: {
                                type: 'kinds',
                                id: this.selectedKindIds.liabilities
                            }
                        }
                    }
                }

                if(assetId){
                    payload.relationships.assets = {
                        data: [
                            {
                                type: 'assets',
                                id: assetId 
                            }
                        ],
                        meta: {
                            strategy: "attach"  
                        }
                    }
                }

                if(expenseId){
                    payload.relationships.expenses = {
                        data: [
                            {
                                type: 'expenses',
                                id: expenseId 
                            }
                        ],
                        meta: {
                            strategy: "attach"  
                        }
                    }
                }

                if(liabilities.id){

                    payload.id = liabilities.id 
                    
                    return this.updateLiability(payload)

                } else {

                    return this.createLiability(payload)
                }


            },
            async updateLocalResources(assetId, expenseId, liabilityId){

                const {incomes} = this.resources

                let payload = []

                if(assetId){
                    let res1 = this.getAsset(assetId)
                    payload.push(res1)
                }

                if(expenseId){
                    let res2 = this.getExpense(expenseId)
                    payload.push(res2)
                }

                if(liabilityId){
                    let res3 = this.getLiability(liabilityId)
                    payload.push(res3)
                }

                if(incomes.id){
                    let res4 = this.getIncome(incomes.id)
                    payload.push(res4)
                }


                try {

                    let data = await Promise.all(payload)

                    actions.updateResources(data)

                } catch (error) {

                    console.log('Ooops!..', error)

                }

            },
            getRequiredFOMsg(type){

                const {selectedKindIds, kindsUnformat, data, foName, standalone} = this

                let selectedFOType = standalone ? data.type : foName;

                let msg = 'This is required!'

                let main = kindsUnformat.find( kind => kind.id == selectedKindIds[selectedFOType]) 
                let current = kindsUnformat.find( kind => kind.id == selectedKindIds[type])  

                if(main && current){
                    msg = `${main.attributes.name} ${this.toSingular(selectedFOType)} requires a ${current.attributes.name} ${this.toSingular(type)}`
                }

                return msg
            }
        },
        watch: {
            liabilitiesFinancialInstitution(val){
                this.resources.expenses.attributes['financial-institution'] = val

                if(val == 'Other'){
                    this.visible["other-institution"] = true
                } else {
                    this.visible["other-institution"] = false
                }

            },
            expensesFinancialInstitution(val){
                this.resources.liabilities.attributes['financial-institution'] = val

                if(val == 'Other'){
                    this.visible["other-institution"] = true
                } else {
                    this.visible["other-institution"] = false
                }

            },
            liabilitiesOtherInstitution(val){
                this.resources.expenses.attributes['other-institution'] = val
            },
            expensesOtherInstitution(val){
                this.resources.liabilities.attributes['other-institution'] = val
            },
            liabilitiesToBePaidOut(val){
                this.resources.expenses.attributes['to-be-paid-out'] = val
            },
            expensesToBePaidOut(val){
                this.resources.liabilities.attributes['to-be-paid-out'] = val
            },
            selectedKindAssetId(val){

                if(val){

                    if(val.split('|').length == 1){

                        // ------Computer Hardware 10-23
                        // ------POS Systems 10-24
                        // ------Solar Equipment 10-25

                        if( '10-23|10-24|10-25'.split('|').includes(val) ){
                            this.visible["being-sold-traded"] = false
                        }

                        // ----Other Vehicle 10-45
                        // ------Other (Not Listed) 10-26

                        // ** Display All

                        // ------The Rest
                        if( "10-35|10-36|10-37|10-38|10-44|10-46|10-47|10-48|10-49|10-19|10-20|10-21|10-22|10-23|10-24|10-25|10-28|10-29".split('|').includes(val) ){
                            this.visible["description-other"] = false
                        }

                    }

                }

            },
            selectedKindLiabilityId(val){

            },
            selectedKindExpenseId(val){

            },
            FieldFinancialInstitution (val) {
                if (val === 'Other') {
                    this.validation_rules.attributes["other-institution"] = 'required|string'
                    this.visible['other-institution'] = true
                } else {
                    this.validation_rules.attributes["other-institution"] = ''
                    this.visible['other-institution'] = false
                }
            },
            getTotalLivingExpenses(val){
                    this.resources.expenses.attributes["payment-amount"] = val
            }
        },

    }
</script>

<style lang="scss" scoped>

    .flex-form {
        display: grid;
        grid-column-gap: 15px;
        grid-row-gap: 0;
        grid-template-columns:  repeat(auto-fit, minmax(200px, 1fr));

        .flex-field {
            align-self: start;
        }
    }


</style>