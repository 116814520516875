<template>
    <v-card class="mx-auto" :disabled="isDisabled" tile flat>
        <v-card-text class="pa-4 pt-0 pa-md-10 pt-md-0">
            <v-container pa-0 fluid>
                <v-row>
                    <v-col cols="12" class="pt-0">

                        <ValidationObserver ref="observer" tag="form">
                            <div v-show="false">
                                <div v-for="(field, fkey) in fields"
                                    :key="fkey">
                                    <ValidationProvider
                                        v-slot="{ errors }"
                                        :name="field.meta | formalText"
                                        rules="required"
                                        :vid="field.meta">
                                        <v-text-field
                                            v-model="resourceObj[field.meta]"
                                            :error-messages="errors"
                                            :label="field.label">
                                        </v-text-field>
                                    </ValidationProvider>
                                </div>
                            </div>
                        </ValidationObserver>

                        <LoanDetails 
                            ref="loanDetails" 
                            @submit="handleLoanDetailsSubmit"
                        />

                        <template v-if="loanAmountHasValue">

                                <template v-if="!isLoading">

                                    <template v-if="topLenders.length > 0">
                                        <TopLoanMatches
                                            @show-info="showInfoDialog" 
                                            @show-people="showApplyModal" 
                                            :hide-lender="hideLenderIndentity"
                                            :data="topLenders"/>

                                        <div class="mb-8">
                                            <div class="d-flex align-center">
                                                <div>
                                                    <h1 class="flex__step-header-title" v-if="vb.mdAndUp">Other Results</h1>
                                                    <h1 class="font-size-16 font-weight-medium" v-else>Other Lender options</h1>
                                                </div>
                                                <v-spacer></v-spacer>
                                                <div class="d-flex align-center">
                                                    <CompareLenders
                                                        :selected-lenders="selectedLendersToCompare"
                                                        :selected-lender="getSelectedLender"
                                                        :selected-count="selectedLendersToCompareCount"
                                                        :hide-lender="hideLenderIndentity"
                                                        :required-amount="requiredAmount"
                                                        :disabled="isDisabled">
                                                    </CompareLenders>
                                                </div>
                                            </div>
                                        </div>

                                        <OtherResults 
                                            @show-info="showInfoDialog" 
                                            @show-people="showApplyModal" 
                                            :data="otherLenders"
                                            :selected-lender="getSelectedLender"
                                            :hide-lender="hideLenderIndentity"
                                        />
                                    </template>
                                    <template v-else>
                                        <v-card tile flat class="rounded-lg mx-auto" max-width="700">
                                            <v-card-text class="text-center pa-0 pa-md-66">
                                                <v-icon size="48" color="primary lighten-1">mdi-information</v-icon>
                                                <v-spacer class="mb-6"/>
                                                <div class="body_text_color--text">
                                                    <div class="headline mb-6">Sorry, we are having trouble locating options based on the information provided.</div>
                                                </div>
                                            </v-card-text>
                                        </v-card>
                                    </template>

                                </template>
                                <template v-else>
                                    <div class="text-center d-block ">
                                        <h1 class="flex__step-header-title line-height-32 mb-2" v-if="vb.mdAndUp">Loading</h1>
                                        <h1 class="font-size-20 mb-2" v-else>Loading</h1>
                                        <p>Please wait...</p>
                                    </div>
                                </template>

                        </template>


                    </v-col>

                    <v-col cols="12" class="mt-0 mt-md-6">
                        <Disclaimer :settings="blockSettings"/>  
                    </v-col>
                    
                </v-row>
            </v-container>

        </v-card-text>


        <!-- More Info Modal -->

        <MoreInfoModal
            v-if="dialogData"
            :dialog="dialog"
            :data="dialogData"
            :selected-lender="getSelectedLender"
            :required-amount="requiredAmount"
            :hide-lender="hideLenderIndentity"
            @show-apply="showApplyModal" 
            :user-input="userInput"
            @close="dialog = false"
        />

        <!-- Compare Lenders -->

        <!-- <RequestCallback3
            :person="person"
            :show-dialog="callDialog"
            @save-person="setSelected(lenderName, lenderData)"
            @close-modal="callDialog = false" /> -->
        <SubmitApplicationTo :lenderObj="lenderObj" :dialog="callDialog" @close-modal="closeModal"/>

        <Portal to="component-header-call-back">
            <RequestCallback1 @save-person="handleContinue" />
        </Portal>
    
    </v-card>
</template>

<script>

    import mixins from '~/components/v1/QuoteResult6/_mixins'
    import subscriptions from '~/components/shared/subscriptions'

    import { mapActions, mapGetters, mapState, mapMutations } from 'vuex'

    import { isEmpty } from 'lodash'

    import LoanDetails from '~/components/v1/QuoteResult6/_LoanDetails'
    import TopLoanMatches from '~/components/v1/QuoteResult6/_TopLoanMatches'
    import OtherResults from '~/components/v1/QuoteResult6/_OtherResults'
    import Disclaimer from '~/components/v1/QuoteResult6/_Disclaimer'
    import CompareLenders from '~/components/v1/QuoteResult6/_CompareLenders'
    import MoreInfoModal from '~/components/v1/QuoteResult6/_MoreInfoModal'
    import {store, actions} from '~/components/v1/QuoteResult6/__store'

    export default {
        mixins: [   
            mixins,
            subscriptions, 
        ],
        components:{
            LoanDetails,
            TopLoanMatches,
            OtherResults,
            Disclaimer,
            MoreInfoModal,
            CompareLenders
        },
        data() {
            return {
                callDialog: false,
                lenderName: '',
                lenderData: {},
                person: null,
                lenderObj: null,

                requiredAmount: '',
                leadLoanAmnount: 0,
                userInput: null,


                dialog: false,
                dialogData: null,

                // loading for running-quote
                isLoading: true,


                quotes: {},
                lenders: [],

                topLenders: [],
                otherLenders: [],

                resources: {                        
                    "type": "loans",
                    "id": null,
                    "attributes": {
                        "quoted-lender-selected": null,

                    },
                    "relationships": {
                        "application": {
                            "data": {
                                "type": "applications",
                                "id": null
                            },
                        }
                    }
                },
                resourceObj: {

                },
                fields: {
                    'quoted-lender-selected': { label: 'Lender', meta: 'quoted-lender-selected' }
                },
                settings: {
                    hideLenderNames: 0,
                    customerDiscountRate: 0,
                    originationFee: 1000
                },
                productAlias: [ 'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'C' ],
            }
        },
        beforeCreate() {
            window.scrollTo(0, 0)
        },
        mounted(){

            // console.log(this.lenders)

            Object.keys(this.blockSettings).forEach(item => {

                if(this.settings.hasOwnProperty(item)){
                    this.settings[item] = this.blockSettings[item]
                }
            })

            // console.log(this.settings)

        },
        computed: {
            getLoanId(){
                return this.appData.relationships.loan.data.id
            },
            getPersonId(){
                return this.appData.relationships.people.data[0].id
            },
            hideLenderIndentity(){
                return this.settings.hideLenderNames == 1
            },
            getSelectedLender(){
                if(this.resourceObj['quoted-lender-selected']) {
                    return this.resourceObj['quoted-lender-selected']
                }

                return ''
            },
            hasLenders(){
                return this.lenders.length > 0
            },
            selectedLendersToCompare(){
                return store.lendersToCompare
            },
            selectedLendersToCompareCount(){
                return store.lendersToCompare.length
            },
            loanAmountHasValue(){

                if(this.loanData.attributes['requested-required-loan-amount']) {

                    return true   

                } else {

                    if(this.leadLoanAmnount) {
                        return true
                    }
                    
                    return false   
                }

            }
        },
        methods: {
            ...mapActions('flows', ['selectNextStepOL']),
            ...mapActions('resource-actions', [
                'getQuotes',
                'updateLoan',
                'getPerson',
                'updatePerson',
                'getPurchases',
                'updatePurchases',
                'setApplicationNow'
            ]),
            async pull() {

                // Update Loan first with user testing values before getting the getQuotes

                // get person
                if(this.getPersonId) {
                    let res = await this.getPerson(this.getPersonId)
                    if(res.status == 200) {
                        this.person = res.data.data
                    }
                }
                
                if (this.getLoanId) {

                    let payload = {
                        type: 'loans',
                        id: this.getLoanId,
                        attributes: {
                            "customer-discount-rate": this.settings.customerDiscountRate,
                            "requested-broker-fee": this.settings.originationFee
                        }
                    }

                    if(this.isUnsecured){
                        payload.attributes['requested-payment-frequency'] = "Monthly"
                    }

                    await this.updateLoan(payload)

                    // Trigger pull method of child component
                    if(this.$refs.loanDetails) {
                        this.$refs.loanDetails.init()
                    }
                }

                if(this.loanAmountHasValue) {
                    this.updateLenderResults()
                }

            },
            async push() {

                let requestPayload = Object.assign({}, this.resources)

                let res = await this.setApplicationNow({
                    payload: this.resources,
                    picked: this.resourceObj['quoted-lender-selected']
                })
                
                const {valid, status} = await this.validateFormData()
                let data = this.$options.filters.toAppDataFormat(res)

                return  { valid, status, data } 
            },
            async updateLenderResults(){

                this.isLoading = true

                let response = await this.getQuotes({                    
                    'type': 'applications',
                    'id': this.appId,
                    'attributes': {}
                })

                if ( response.length > 1 ) {

                    response.forEach( res => {
                        if (res.data.data.type === 'applications') {

                            let list = res?.data?.included?.[0]?.attributes?.results ? res?.data.included[0].attributes.results : {}
                            
                            if ( !isEmpty(list) ) {
                                list = JSON.parse( list )
                            }

                            this.quotes = list?.lenders
                            this.requiredAmount = list?.['required-amount']
                            this.userInput = list?.['inputs']


                        } else {
                            
                            this.resources = res.data.data
                            this.resources.relationships = this.removeEmptyRelationships(this.resources.relationships)
                        }
                    })
                }

                if (this.quotes){

                    Object.keys(this.quotes).forEach( (lender, index) => {
                        this.lenders.push(this.quotes[lender])
                        this.lenders[index]['alias'] = `Product ${this.productAlias[index]}`
                    })
                }


                this.resourceObj = { 
                    'quoted-lender-selected': this.resources.attributes['quoted-lender-selected']
                }

                // sort lenders from lowest to highest monthly repayments

                if (this.lenders.length > 0){

                    this.lenders = this.sortLenders(this.lenders)
                    this.topLenders = this.lenders.slice(0, 3)


                    if(this.lenders.length > 3){
                        this.otherLenders = this.lenders.slice(3);
                    }

                }


                this.isLoading = false

                // console.log(this.lenders)
                this.validComponentData = this.lenders.length > 0 ? true : false
            },
            async handleLoanDetailsSubmit(loan){

                this.resetLenders()

                if(loan) {
                    this.leadLoanAmnount = loan?.attributes?.['requested-required-loan-amount']
                }

                await this.updateLenderResults()

            },
            resetLenders(){

                this.lenders = []
                this.topLenders = []
                this.otherLenders = []
                this.quotes = {}
            },
            sortLenders(lenders) {

                let data = []

                lenders.forEach(item => {
                    if(item.quotable == "Yes") {
                        data.push(item)
                    }
                })

                data.sort((a, b) => {
                    if (a['actual-payment']['monthly-payment'] && b['actual-payment']['monthly-payment'])
                        return a['actual-payment']['monthly-payment'] - b['actual-payment']['monthly-payment']
                    
                })

                return data;
            },
            handleContinue(){

                this.resourceObj = { 'quoted-lender-selected': "No options available" }
                this.selectNextStepOL({goToNext: true, returnStatus: false, params: {key: "source", value: "request-a-call"}})
                
            },
            setSelected(name = '', lender = {}) {

                this.resourceObj = { 'quoted-lender-selected': name }

                this.populateLoanFields(lender)

                this.selectNextStepOL({goToNext: true, returnStatus: false, params: {key: "source", value: "apply-now"}})

            },
            populateLoanFields(item) {

                this.resources.attributes['quoted-balloon'] = item['actual-payment']['balloon']
                this.resources.attributes['quoted-bank-fees'] = item['actual-payment']['bank-fees']
                this.resources.attributes['quoted-base-rate'] = item['actual-payment']['base-rate']
                this.resources.attributes['quoted-broker-commission-inc-gst'] = item['actual-payment']['commission-inc-gst']
                this.resources.attributes['quoted-broker-fee'] = item['actual-payment']['broker-fee']
                this.resources.attributes['quoted-customer-rate'] = item['actual-payment']['customer-rate']
                this.resources.attributes['quoted-monthly-payments'] = item['actual-payment']['monthly-payment']
                this.resources.attributes['quoted-naf'] = item['actual-payment']['naf']
                this.resources.attributes['quoted-account-fee'] = item['actual-payment']['account-fee']
                this.resources.attributes['quoted-term-months'] = item['actual-payment']['term-in-months']
                this.resources.attributes['quoted-lender-selected'] = item.name

            },
            removeEmptyRelationships(relationships) {
                
                let temp = {}

                Object.keys(relationships).forEach( key => {
                    if (relationships[key].data?.length > 0 || relationships[key].data?.id !== undefined)
                        temp[key] = relationships[key]
                })

                return temp
            },



            showInfoDialog(data){
                this.dialogData = data
                this.dialog = true

            },
            showApplyModal({name = '', lender = {}}) {
                this.lenderObj = {
                    ...name,
                    ...lender
                }
                this.lenderName = name
                this.lenderData = lender
                this.callDialog = true
            },
            closeModal() {
                this.callDialog = false
            }

        }
    }
</script>

<style lang="scss">

    .flex__component-QuoteResult6 {

        .top-lenders-slide-group {

            .v-slide-group__wrapper .v-slide-group__content {
                justify-content: center !important;
            }

            .lender-item .v-card__actions {
                cursor: pointer;
                justify-content: center !important;
            }

        }


        .outline {
            border: none;
            height: 1px;
        }


        .flex-form {
            display: grid;
            grid-column-gap: 15px;
            grid-row-gap: 0;
            grid-template-columns:  repeat(auto-fit, minmax(260px, 1fr));

            .flex-field {
                align-self: start;
            }
        }

        .inline-icons {
            display: flex;
        }

        .caption.text-center.gray {
            color: gray;
        }

        .lightgray {
            background-color: #fafafa;
        }

    }
  
</style> 