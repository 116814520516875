<template>
  <div>
      <v-data-table

        hide-default-footer
        :items-per-page="-1"
        :headers="[
          {
            text: 'Situation',
            value: 'attributes.income-situation',
          },
          {
            text: 'Industry',
            value: 'attributes.lender-plenti-industry',
          },
          {
            text: 'Occupation',
            value: 'attributes.lender-plenti-occupation',
          },
          {
            text: 'Action',
            value: 'action'
          }
        ]"
        :items="previousAndCurrentIncomes"
        class="px-8 v-data-table-2"
      >
      <template #body="{items}">
        <tbody>
          <tr v-for="(item, index) in items" :key="index" :class="{'light_gray_1': resources.incomes.id == item.id}">
            <td>
              {{ item.attributes['income-situation'] ||'-' }}
            </td>
            <td>
              {{ item.attributes['lender-plenti-industry'] ||'-' }}
            </td>
            <td>
              {{ item.attributes['lender-plenti-occupation'] ||'-' }}
            </td>
            <td>
              <v-chip v-if="resources.incomes.id != item.id" depressed small color="secondary" @click="resources.incomes = filterResourceFields(resources.incomes, item)">
                Edit
              </v-chip>
              <v-chip v-else depressed small>
                Selected
              </v-chip>
            </td>
          </tr>
        </tbody>
      </template>
      </v-data-table>
    <div v-if="vb.mdAndUp" class="d-flex" style="width: 100%">
        <v-sheet color="light_gray_1" class="py-12 px-12" width="50%" outlined elevation="0">
            <v-img
                max-width="120"
                class="flex-brand"
                :src="require(`~/assets/images/nodifi.svg`)"
                position="center left"
                contain>
            </v-img>
            <div class="mt-2 font-size-20 light_gray_2--text">Data</div>

            <v-list color="transparent">
                <v-list-item two-line class="px-0">
                    <v-list-item-content>
                        <v-list-item-title class="font-weight-medium">Employer Name</v-list-item-title>
                        <v-list-item-subtitle>{{data ? data.attributes['employer-name'] : '&mdash;'}}</v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>

                <v-list-item two-line class="px-0">
                    <v-list-item-content>
                        <v-list-item-title class="font-weight-medium">Job Title</v-list-item-title>
                        <v-list-item-subtitle>{{data ? data.attributes['job-title'] : '&mdash;'}}</v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>
            </v-list>

        </v-sheet>
        <v-sheet class="py-8 px-12" width="50%">

            <v-img
                max-width="120"
                class="flex-brand"
                :src="require(`~/assets/images/logo/cropped/lender-logo-plenti.png`)"
                position="center left"
                contain>
            </v-img>
            <div class="mt-2 mb-8 font-size-20">Requirements</div>

            <ValidationObserver ref="observer" tag="form">
                <template v-for="(field, key) in resources.incomes.attributes" >
                <ValidationProvider
                        :key="key"
                        v-slot="{ errors }"
                        :name="labels[key]"
                        :rules="getRules(key)"
                        :vid="key">

                            <v-select
                                v-if="isFieldType('picklist', key)"
                                v-model="resources.incomes.attributes[key]"
                                :items="getOptions(key)"
                                :error-messages="errors"
                                color="secondary"
                                :label="labels[key]"
                                ></v-select>

                            <v-text-field
                                v-else
                                tile
                                type="text"
                                :label="labels[key]"
                                color="secondary"
                                :error-messages="errors"
                                v-model="resources.incomes.attributes[key]"
                                required>
                            </v-text-field>

                </ValidationProvider>
                </template>
            </ValidationObserver>


        </v-sheet>
    </div>

    <!-- mobile version -->
    <div v-else>

        <v-card flat class="mx-5 rounded-xl" :style="`background-color: ${hexToRGBA(getActiveTheme.colors.secondary, 0.2)}`">
            <v-card-text class="d-flex py-1">
                <v-row>
                    <v-col cols="6" class="pa-1">
                        <v-card
                        min-height="45"
                            flat class="rounded-xl px-1"
                            :color="tabSelection == 'nodifi' ? 'white' : 'transparent'">
                            <div class="d-flex justify-center pt-1" @click="selectTab('nodifi')">
                                <v-img
                                    max-width="55"
                                    class="flex-brand"
                                    :src="require(`~/assets/images/nodifi.svg`)"
                                    position="center left"
                                    contain>
                                </v-img>
                                <div class="mt-3 font-size-12 pl-1">Data</div>
                            </div>
                        </v-card>
                    </v-col>
                    <v-col cols="6" class="pa-1">
                        <v-card
                            flat class="rounded-xl px-1"
                            :color="tabSelection == 'plenti' ? 'white' : 'transparent'">
                            <div class="d-flex flex-column" @click="selectTab('plenti')">
                                <v-img
                                    max-width="45"
                                    class="flex-brand mx-auto mt-n1"
                                    :src="require(`~/assets/images/logo/cropped/lender-logo-plenti.png`)"
                                    position="center left"
                                    contain>
                                </v-img>
                                <div class="font-size-12 pl-1 mx-auto mt-n3">Requirements</div>
                            </div>
                        </v-card>
                    </v-col>
                </v-row>
            </v-card-text>

        </v-card>

        <v-list v-show="tabSelection == 'nodifi'" class="mx-8" color="transparent">
            <v-list-item two-line class="px-0">
                <v-list-item-content>
                    <v-list-item-title class="font-weight-medium">Employer Name</v-list-item-title>
                    <v-list-item-subtitle>{{data ? data.attributes['employer-name'] : '&mdash;'}}</v-list-item-subtitle>
                </v-list-item-content>
            </v-list-item>

            <v-list-item two-line class="px-0">
                <v-list-item-content>
                    <v-list-item-title class="font-weight-medium">Job Title</v-list-item-title>
                    <v-list-item-subtitle>{{data ? data.attributes['job-title'] : '&mdash;'}}</v-list-item-subtitle>
                </v-list-item-content>
            </v-list-item>
        </v-list>

        <div v-show="tabSelection != 'nodifi'" class="mx-8">
            <ValidationObserver ref="observer" tag="form">
                <template v-for="(field, key) in resources.incomes.attributes" >
                <ValidationProvider
                        :key="key"
                        v-slot="{ errors }"
                        :name="labels[key]"
                        :rules="getRules(key)"
                        :vid="key">

                            <v-select
                                v-if="isFieldType('picklist', key)"
                                v-model="resources.incomes.attributes[key]"
                                :items="getOptions(key)"
                                :error-messages="errors"
                                color="secondary"
                                :label="labels[key]"
                                ></v-select>

                            <v-text-field
                                v-else
                                tile
                                type="text"
                                :label="labels[key]"
                                color="secondary"
                                :error-messages="errors"
                                v-model="resources.incomes.attributes[key]"
                                required>
                            </v-text-field>

                </ValidationProvider>
                </template>
            </ValidationObserver>
        </div>

    </div>
  </div>


</template>

<script>

    import { mapActions, mapState, mapGetters } from 'vuex'
    import subscriptions from '~/components/shared/subscriptions'
    import shared from '~/components/v1/submission/LenderPlentiEntities1/__shared'
    import {store, actions} from '~/components/v1/submission/LenderPlentiEntities1/__store'

    export default {
        components: {},
        mixins: [subscriptions, shared],
        props: {
          incomes: {
                type: Array,
                default: () => []
            },
          data: {
                type: Object,
                default: null
            },
            updater: undefined
        },
        data() {
            return {
                tabSelection: 'nodifi',
                resources: {
                    incomes: {
                        id: null,
                        type: "incomes",
                        attributes: {
                            "lender-plenti-industry" : null,
                            "lender-plenti-occupation" : null
                        },
                        relationships: {
                            application: {
                                data : {
                                    type: "applications",
                                    id: null
                                }
                            }
                        }
                    }
                },

                labels: {
                    "lender-plenti-industry": "Industry",
                    "lender-plenti-occupation": "Occupation",
                },
                validation_rules: {
                    attributes: {
                        "lender-plenti-industry": "required|in:Agribusiness,AssetWealthManagement,BankingAndCapitalMarkets,ConstructionTransportation,Defence,Education,EnergyOilGas,EntertainmentMedia,FinancialServices,Government,Healthcare,Infrastructure,Insurance,Mining,PowerUtilities,RealEstate,RetailConsumer,Superannuation,Technology,Other",
                        "lender-plenti-occupation": "required|in:AccountantFinancialAnalyst,Administrator,CabinCrew,CeoChairman,Chef,Clergy,ContractorConsultant,DefenceWorker,Diplomat,Driver,Engineer,FactoryWorker,GovernmentOfficial,HousePerson,Journalist,Labourer,LegalProfessional,Manager,ManagingDirector,MusicianActor,Nurse,OfficeWorker,Pensioner,Pilot,PoliceLawEnforcement,Professional,Sales,Scientist,SelfEmployed,SelfFundedRetiree,ServiceWorker,SystemsAnalystProgrammer,TeacherEducator,TeamLeaderSupervisor,Other",
          }
                },
                options: {
                    "lender-plenti-industry" : [
                        { text: "Agribusiness", value: "Agribusiness" },
                        { text: "Asset Wealth Management", value: "AssetWealthManagement" },
                        { text: "Banking And Capital Markets", value: "BankingAndCapitalMarkets" },
                        { text: "Construction Transportation", value: "ConstructionTransportation" },
                        { text: "Defence", value: "Defence" },
                        { text: "Education", value: "Education" },
                        { text: "Energy Oil Gas", value: "EnergyOilGas" },
                        { text: "Entertainment Media", value: "EntertainmentMedia" },
                        { text: "Financial Services", value: "FinancialServices" },
                        { text: "Government", value: "Government" },
                        { text: "Healthcare", value: "Healthcare" },
                        { text: "Infrastructure", value: "Infrastructure" },
                        { text: "Insurance", value: "Insurance" },
                        { text: "Mining", value: "Mining" },
                        { text: "Power Utilities", value: "PowerUtilities" },
                        { text: "Real Estate", value: "RealEstate" },
                        { text: "Retail Consumer", value: "RetailConsumer" },
                        { text: "Superannuation", value: "Superannuation" },
                        { text: "Technology", value: "Technology" },
                        { text: "Other", value: "Other" },
                    ],
                    "lender-plenti-occupation" : [
                        { text: "Accountant Financial Analyst", value: "AccountantFinancialAnalyst" },
                        { text: "Administrator", value: "Administrator" },
                        { text: "Cabin Crew", value: "CabinCrew" },
                        { text: "CEO Chairman", value: "CeoChairman" },
                        { text: "Chef", value: "Chef" },
                        { text: "Clergy", value: "Clergy" },
                        { text: "Contractor Consultant", value: "ContractorConsultant" },
                        { text: "Defence Worker", value: "DefenceWorker" },
                        { text: "Diplomat", value: "Diplomat" },
                        { text: "Driver", value: "Driver" },
                        { text: "Engineer", value: "Engineer" },
                        { text: "Factory Worker", value: "FactoryWorker" },
                        { text: "Government Official", value: "GovernmentOfficial" },
                        { text: "House Person", value: "HousePerson" },
                        { text: "Journalist", value: "Journalist" },
                        { text: "Labourer", value: "Labourer" },
                        { text: "Legal Professional", value: "LegalProfessional" },
                        { text: "Manager", value: "Manager" },
                        { text: "Managing Director", value: "ManagingDirector" },
                        { text: "Musician Actor", value: "MusicianActor" },
                        { text: "Nurse", value: "Nurse" },
                        { text: "Office Worker", value: "OfficeWorker" },
                        { text: "Pensioner", value: "Pensioner" },
                        { text: "Pilot", value: "Pilot" },
                        { text: "Police Law Enforcement", value: "PoliceLawEnforcement" },
                        { text: "Professional", value: "Professional" },
                        { text: "Sales", value: "Sales" },
                        { text: "Scientist", value: "Scientist" },
                        { text: "Self Employed", value: "SelfEmployed" },
                        { text: "Self Funded Retiree", value: "SelfFundedRetiree" },
                        { text: "Service Worker", value: "ServiceWorker" },
                        { text: "Systems Analyst Programmer", value: "SystemsAnalystProgrammer" },
                        { text: "Teacher Educator", value: "TeacherEducator" },
                        { text: "Team Leader Supervisor", value: "TeamLeaderSupervisor" },
                        { text: "Other", value: "Other" }

                    ],
                }
            }
        },
        computed: {
            vb() {
                return this.$vuetify.breakpoint
            },
            previousAndCurrentIncomes () {
              return this.incomes.filter(item => {
                return item.attributes['income-situation'] !== 'Secondary'
              })
            }
        },
        mounted() {
            this.updateResourceFields()
        },
        methods: {
            ...mapActions('resource-actions', [
                'getIncome',
            ]),
            selectTab(tab) {
                this.tabSelection = tab
            },
            async validate(){

                const valid = await this.$refs.observer.validate()

                if(valid) {

                    // attache income id
                    // this.resources.incomes.id = this.data.id

                    this.$emit('submit', this.resources.incomes)
                }

            },
            reset(){


                this.$refs.observer.reset()

            },
            async updateResourceFields(){

                // console.log('updateResourceFields')

                this.$emit('loading', true)

                const {data} = this

                if(data) {

                    let res = await this.getIncome(data.id)

                    this.resources.incomes = this.filterResourceFields(this.resources.incomes, res.data.data)

                    // console.log(this.resources['incomes'])

                    this.$emit('loading', false)

                } else {

                    this.$emit('loading', false)
                }



            }
        },
        watch: {
            updater(val) {

                // console.log(val)

                if(val) {
                    this.updateResourceFields()
                }
            },
        }
    }
</script>

<style lang="scss" scoped>
</style>
