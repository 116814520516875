<template>
    <v-card class="mx-auto" tile flat>
        <v-card-text v-if="isMotorVehicleKind" class="pa-4 pt-0 pa-md-10 pt-md-0">
               <ValidationObserver ref="observer" tag="form">
                   <div class="flex-form" v-for="layout in fieldLayout">
                        <template v-for="(key, index) in layout">

                        <div class="flex-field" :key="key + index" v-if="key == '__spacer'"></div>
                        
                        <div class="flex-field" :key="key + index" v-else>

                            <ValidationProvider
                                v-slot="{ errors }"
                                v-if="isIncluded(key)"
                                :name="labels[key]"
                                :rules="getRules(key)"
                                :vid="key">
                                <v-select
                                    v-if="isFieldType('picklist', key)"
                                    v-model="resources.purchase.attributes['lender']['macquarie'][key]"
                                    :items="getOptions(key)"
                                    :error-messages="errors"
                                    :readonly="isReadOnly(key)"
                                    color="secondary"
                                    :label="labels[key]">
                                </v-select>
                            </ValidationProvider>
                        </div>
                        </template>

                    </div>
                </ValidationObserver>

        </v-card-text>
        <v-card-text v-else class="pa-4 pt-0 pa-md-10 pt-md-0">
            <v-alert dense outlined type="warning">
                Submission requires 9-2 (Motor Vehicle) Purchase Kind
            </v-alert>
        </v-card-text>
    </v-card>
</template>

<script>

    import { mapActions, mapState } from 'vuex'
    import helpers from '~/plugins/helpers'
    import subscriptions from '~/components/shared/subscriptions'

    export default {
        components: { },

        mixins: [subscriptions, helpers],

        data() {
            return {
                resources: {
                    purchase: {
                        type: 'purchases',
                        id: null,
                        attributes: {
                            "lender": {
                                "macquarie": {
                                    "assetSubtype": null,
                                    "bodyType": null,
                                    "lvrBand": null,
                                }
                            },
                            "__spacer": null
                        },
                        relationships: {
                            kind: {
                                data: {
                                    id: null,
                                }
                            }
                        }
                    }
                },
                // mapping for sub asset type
                // MOTOV_CARS_-_OTHER_CARS_AU	3,5,7,13,15
                // MOTOV_UTILITIES_&_VANS_AU 	1,2,8,11,14,18
                options: {
                    "bodyType": ["4WD", "BUS", "CABRIOLET", "COUPE", "HATCH", "LORRY", "PANELVAN", "SEDAN", "SMALLBUS", "STATIONWAGON", "UTILITY"],
                    "assetSubtype": [{value: "MOTOV_CARS_-_OTHER_CARS_CM_AU", text: 'Motov Cars - Other Cars CM AU'}, {value: "MOTOV_UTILITIES_&_VANS_CM_AU", text: 'Motov Utilities & Vans CM AU'}],
                    'lvrBand': [
                        {text: 'Band A', value: 'BAND_A'},
                        {text: 'Band B', value: 'BAND_B'},
                        {text: 'Band C', value: 'BAND_C'},
                        {text: 'Band D', value: 'BAND_D'},
                    ],
                },
                labels: {
                    "bodyType": "Body Type",
                    "assetSubtype": "Asset Type",
                    "lvrBand": 'What is the expect Loan to Value ratio band of the vehicle?'
                },
                validation_rules: {
                    type: "purchase",
                    attributes: {
                        "bodyType": "required|in:4WD,BUS,CABRIOLET,COUPE,HATCH,LORRY,PANELVAN,SEDAN,SMALLBUS,STATIONWAGON,UTILITY",
                        "assetSubtype": "required|in:MOTOV_CARS_-_OTHER_CARS_CM_AU,MOTOV_UTILITIES_&_VANS_CM_AU",
                        "lvrBand": "requried|in:BAND_A,BAND_B,BAND_C,BAND_D",
                        "__spacer": ""
                    }
                },
                included: {
                    "assetSubtype": false,
                    "lvrBand": false,
                },
                readonly: {
                    "assetSubtype": true
                },
                fieldLayout: [
                    [
                        "bodyType",
                        "assetSubtype",
                        "lvrBand",
                        "__spacer",                  
                    ]
                ],
                vendorUnknown: false,
            }
        },
        computed: {
            ...mapState({
                appId: state => state.flows.appId,
            }),
            bodyType() {
                return this.resources.purchase.attributes['lender']['macquarie']['bodyType']
            },
            isMotorVehicleKind() {
                return this.resources.purchase?.relationships?.kind?.data?.id && this.resources.purchase.relationships.kind.data.id === '9-2'
            }
        },
        methods: {
            ...mapActions('resource-actions', [
                'getPurchases',
                'updatePurchases'
            ]),
            async pull() {

                let res = await this.getPurchases()

                if ( res.status === 200 ) {
                    if(res.data.data.length > 0) {
                        this.resources.purchase.id = res.data.data[0].id
                        this.resources.purchase.attributes.lender['macquarie']['bodyType'] = res.data.data[0].attributes.lender['macquarie']['bodyType']
                        this.resources.purchase.attributes.lender['macquarie']['assetSubtype'] = res.data.data[0].attributes.lender['macquarie']['assetSubtype']
                        this.resources.purchase.attributes.lender['macquarie']['lvrBand'] = res.data.data[0].attributes.lender['macquarie']['lvrBand']
                        this.resources.purchase.relationships = res.data.data[0].relationships
                        this.vendorUnknown = res.data.data[0].attributes['purchase-known'] !== 'Yes'
                    }
                }

                if(!this.isMotorVehicleKind) {
                    this.validation_rules.attributes['bodyType'] = ""
                    this.validation_rules.attributes['assetSubtype'] = ""
                }

            },
            async push() {
                let { resources } = this
                let res = null

                const {valid, status} = await this.validateFormData()

                if(valid) {
                    res = this.updatePurchases({
                        attributes: {
                            "lender": {
                                "macquarie": {
                                    "assetSubtype": resources.purchase.attributes['lender']['macquarie']['assetSubtype'],
                                    "bodyType": resources.purchase.attributes['lender']['macquarie']['bodyType'],
                                    "lvrBand": resources.purchase.attributes['lender']['macquarie']['lvrBand']
                                }
                            }
                        },
                        id: resources.purchase.id,
                        type: resources.purchase.type
                    })
                }

                const data = await this.appDataHelper([res])

                return {valid, status, data}
                
            },
            isIncluded(field){

                const {included} = this

                if(included.hasOwnProperty(field)) {
                    return included[field]
                }

                return true
            },
            isReadOnly(key){
                
                const {readonly} = this

                if(readonly.hasOwnProperty(key)){
                    return readonly[key]
                }

                return false

            },
            identifyAssetSubType(bodyTypeValue) {
                let subType = ''
                // const assetSubtypeMap = {
                //     "MOTOV_CARS_-_OTHER_CARS_CM_AU": "3,5,7,13,15",
                //     "MOTOV_UTILITIES_&_VANS_CM_AU": "1,2,8,11,14,18",
                // }
                const bodyTypeMap = [
                    {
                        "subType": "MOTOV_UTILITIES_&_VANS_CM_AU",
                        "value": "4WD",
                    },
                    {
                        "subType": "MOTOV_UTILITIES_&_VANS_CM_AU",
                        "value": "BUS",
                    },
                    {
                        "subType": "MOTOV_CARS_-_OTHER_CARS_CM_AU",
                        "value": "CABRIOLET",
                    },
                    {
                        "subType": "MOTOV_CARS_-_OTHER_CARS_CM_AU",
                        "value": "COUPE",
                    },
                    {
                        "subType": "MOTOV_CARS_-_OTHER_CARS_CM_AU",
                        "value": "HATCH",
                    },
                    {
                        "subType": "MOTOV_UTILITIES_&_VANS_CM_AU",
                        "value": "LORRY",
                    },
                    {
                        "subType": "MOTOV_UTILITIES_&_VANS_CM_AU",
                        "value": "PANELVAN",
                    },
                    {
                        "subType": "MOTOV_CARS_-_OTHER_CARS_CM_AU",
                        "value": "SEDAN",
                    },
                    {
                        "subType": "MOTOV_UTILITIES_&_VANS_CM_AU",
                        "value": "SMALLBUS",
                    },
                    {
                        "subType": "MOTOV_CARS_-_OTHER_CARS_CM_AU",
                        "value": "STATIONWAGON",
                    },
                    {
                        "subType": "MOTOV_UTILITIES_&_VANS_CM_AU",
                        "value": "UTILITY",
                    },
                ]

                bodyTypeMap.forEach(body => {
                    if(body.value === bodyTypeValue) {
                        subType = body.subType
                    }
                })

                return subType
            }
        },
        watch: {
            bodyType(val) {
                if(val) {
                    this.included['assetSubtype'] = true!== 'Yes'

                    this.resources.purchase.attributes['lender']['macquarie']['assetSubtype'] = this.identifyAssetSubType(val)
                }
            },
            vendorUnknown(val) {
                if(val) {
                    this.included['lvrBand'] = true
                }
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>