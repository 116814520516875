<template>
<div>
    <v-dialog v-model="show" persistent scrollable max-width="1800">

        <v-card class="full-height" elevation="0" :disabled="loading">

            <v-progress-linear v-if="loading" color="secondary" indeterminate stream top value="0"></v-progress-linear>

            <v-card-title class="pa-8 pb-4 align-center">
                <div class="title font-size-32">
                    Application Inspector
                </div>

                <v-spacer />

                <v-btn icon @click="$emit('close')" >
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-card-title>

            <v-card-text class="pa-8 pt-0">
                <json-viewer :value="preview" :expand-depth="2" copyable boxed></json-viewer>
            </v-card-text>
        </v-card>
    </v-dialog>
</div>
</template>

<script type="application/javascript">
import { mapActions } from 'vuex';
import JsonViewer from 'vue-json-viewer';

export default {
    name: 'ApplicationInspector',
    components: {
        JsonViewer,
    },
    props: {
        show: {
            type: Boolean,
            default: false,
        },
        applicationId: {
            type: String,
            default: '',
        },
    },
    data() {
        return {
            loading: true,
            preview: {},
        };
    },
    methods: {
        ...mapActions('resource-actions', [
            'getApplication',
        ]),
        async initInspector() {
            this.loading = true;

            const response = await this.getApplication({
                id: this.applicationId,
                params: {
                    include: '*',
                },
            });

            if (response?.data?.included) {
                response.data.included = response.data.included.reduce((accumulated, include) => {
                    accumulated[`${include.type}-${include.id}`] = include;
                    return accumulated;
                }, {});
            }

            this.preview = response.data ?? {};
            this.loading = false;
        },
    },
    watch: {
        show: function (value) {
            if (value) {
                this.initInspector();
            }
        },
    },
};
</script>
