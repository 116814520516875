<template>

    <v-card :disabled="isDisabled" class="mx-auto" tile flat>
        <v-card-text class="pa-4 pt-0 pa-md-10 pt-md-0">
            <v-container pa-0 fluid>
                <v-row>
                    <v-col cols="12">
         
                    <!-- Your markup here -->
                        <ValidationObserver ref="observer">
                            <form>
                                <ValidationProvider v-slot="{ errors }" name="test" rules="required" vid="test">
                                    <v-text-field
                                    v-model="resources.loan.attributes['test']"
                                    :error-messages="errors"
                                    label="Test Field"
                                    outlined
                                    ></v-text-field>
                                </ValidationProvider>
                            </form>
                        </ValidationObserver>
                    <!-- /Your markup here -->

                    </v-col>
                </v-row>
            </v-container>
        </v-card-text>
    </v-card>

</template>

<script>
    /**
     * REQUIRED!
     */
    import subscriptions from '~/components/shared/subscriptions'  

    export default {
        name: 'Default',
        mixins: [subscriptions],
        components: {},
        data(){
            return {
                resources: {
                    /////////////////////////////////////
                    /**
                     *  Put your resources data here. 
                     */ 
                    loan: {
                        type: "loans",
                        id: null,
                        attributes: {
                            "test": "",
                        }
                    }
                    ////////////////////////////////////
                }
            }
        },
        created() {

        },
        methods: {
            /**
             *  PULL method
             * 
             *  RECOMMENDED: If multiple requests are needed, do so asynchronously to reduce total time to response.
             */
            async pull(){

                /////////////////////////////////////
                /*
                * Put your logic here. 
                * 
                * 1. Call GET method
                * 2. Pass GET response(s) to the Promise.all below.
                * 3. Do your logic with the GET response
                

                // e.g

                    const promise1 = new Promise((resolve) => {

                        resolve({some data...})

                    }).then(res => {

                        // Do stuff...

                        return res
                    })

                */
                ////////////////////////////////////

                try {

                    await Promise.all([/*promise1, promise2*/]) 

                } catch (error) {

                    console.log('Ooops!..', error)

                }

            },
            /**
             *  PUSH method should always return an Object. 
             *  {valid, status, data}
             * 
             *  RECOMMENDED: If multiple requests are needed, do so asynchronously to reduce total time to response.
             */
            async push(){
                /**
                 *  OPTIONAL
                 *  VeeValidate's observer holds fields status, we use this
                 *  in excludeInvalids to target fields to exclude.
                 */
                const observer = this.$refs.observer
                
                /**
                 *  OPTIONAL
                 *  Exclude invalid fields to the request payload.
                 *  {fields} - list of resources fields
                 *  {observer} - VeeValidate's observer
                 * 
                 *  see components\shared\subscriptions.js 
                 *  for more helper functions.
                 */
                
                const requestPayload = this.excludeInvalids({/* fields */}, observer.fields)


                /////////////////////////////////////

                /*
                * Put your logic here. 
                * When your logic completes:
                * 1. Pass POST / PATCH response(s) to the appDataHelper below.
                * 
                *   e.g: 
                * 
                    const promise1 = this.pushLoan(requestPayload)

                */

                ////////////////////////////////////


                /**
                 *  REQUIRED!
                 */
                const {valid, status} = await this.validateFormData()

                /**
                 * REQUIRED!
                 */
                const data = this.appDataHelper([/*promise1?, promise2?*/])


                /**
                 * REQUIRED!
                 */
                return {valid, status, data}

            },


            /////////////////////////////////////
            /*
            * Your methods, computed properties, etc..
            */
            ////////////////////////////////////

        }
    }
</script>

<style scoped lang="scss">

</style>
