<template>
    <div>
        <v-stepper v-model="referenceStepper" alt-labels>
            <v-stepper-items>
                <v-stepper-content step="1" class="pa-0">
                    <ValidationObserver ref="observer">
                        <v-row class="justify-center align-center mx-auto" :style="vb.mdAndUp ? 'width: 50%;' : 'width: 100%;'">
                            <v-col cols="12" class="ma-0">
                                <div class="text-center mt-n4" :class="vb.mdAndUp ? 'title' : 'title-2 font-weight-bold'">Accountant Reference Details</div>
                            </v-col>
                            <v-col 
                                class="my-0 py-0"
                                v-for="(f, key) in Object.keys(references.attributes)" 
                                :key="key"
                                cols="12"
                            >
                                <ValidationProvider 
                                    v-if="isVisible(f)"
                                    :hidden="isHidden(f)"
                                    v-slot="{ errors }" 
                                    :name="labels[f]" 
                                    :rules="getRules(f)">

                                    <AddressTool3
                                        v-if="f == 'address'"
                                        ref="AddressTool3Component"
                                        v-model="Address"
                                        :bus="Date.now()"
                                        :errors="errors"
                                        label="Addresses">
                                    </AddressTool3>

                                    <v-select
                                        v-else-if="isFieldType('picklist', f)"
                                        :items="getOptions(f)"
                                        :label="labels[f]"
                                        :error-messages="errors"
                                        v-model="references.attributes[f]"
                                        color="secondary">
                                    </v-select>

                                    <template v-else>
                                        <v-text-field
                                            v-model="references.attributes[f]"
                                            :label="labels[f]"
                                            :error-messages="errors">
                                        </v-text-field>
                                    </template>
                                    

                                </ValidationProvider>
                            </v-col>
                        </v-row>
                    </ValidationObserver>
                </v-stepper-content>
                <!-- <v-stepper-content step="2" class="pa-0">
                    <ValidationObserver ref="observer2">
                        <v-row>
                            <v-col cols="12">
                                <div class="text-center mt-n4" :class="vb.mdAndUp ? 'title' : 'title-2 font-weight-bold'">Reference Address</div>
                            </v-col>
                            <v-col 
                                class="my-0 py-0"
                                cols="12"
                            >
                                <ValidationProvider 
                                    v-slot="{ errors }" 
                                    name="Address">

                                    

                                </ValidationProvider>
                            </v-col>
                        </v-row>
                    </ValidationObserver>
                </v-stepper-content> -->
            </v-stepper-items>
        </v-stepper>
        <div class="d-flex justify-center pa-2 mt-3">
            <v-btn
                large
                min-width="125" 
                max-height="50"
                color="secondary"
                class="mx-1"
                @click="back"
                outlined> Back
            </v-btn>
            <v-btn 
                large
                min-width="125" 
                max-height="50"
                class="mx-1"
                color="secondary" 
                @click="next" 
                depressed> Next 
            </v-btn>
        </div>
    </div>
</template>
<script>
import { mapState, mapActions } from 'vuex'
import {store} from '~/components/v1/EmploymentHistoryEditor4/__store'
import shared from '~/components/v1/EmploymentHistoryEditor4/__shared'
import formHelpers from '~/components/shared/form-helpers'
import ResourceHelpers from '~/components/shared/resource-helpers'
import validation from '~/components/shared/validation'
import AddressTool3 from '~/components/v1/EmploymentHistoryEditor4/_AddressTool4'

export default {
    components: {AddressTool3},
    mixins: [ validation, formHelpers, shared, ResourceHelpers ],
    data() {
        return {
            Address: null,
            referenceStepper: 1,
            visible: {
                'relationship': false,
            },
            hidden: {},
            references: {
                attributes: {
                    'business-name': '',
                    'name': '',
                    'relationship': '',
                    'email': '',
                    'phone': '',
                    'address': null
                }
            },
            labels: {
                'name': 'Name',
                'relationship': '',
                'business-name': 'Business name (if applicable)',
                'phone': 'Phone',
                'email': 'Email',
                'address': 'Address'
            },
            validation_rules: {
                attributes: {
                    "name": 'required',
                    "relationship": '',
                    "business-name": '',
                    "phone": 'required|numeric|digits_between:10,10',
                    "email": 'required|email',
                }
            },
        }
    },
    computed: {
        ...mapState({
            appId: state => state.flows.appId
        }),
        vb() {
            return this.$vuetify.breakpoint
        },
    },
    methods: {
        ...mapActions('resource-actions', [
            'createPart',
            'addNewReference',
            'createAddress'
        ]),
        back() {
            this.closeModal()
        },
        async next() {
            store.saving = true

            let valid = await this.$refs.observer.validate()

            if(valid) {
                let res = await this.saveReference()

                if(res.status === 201) {
                    this.closeModal()
                }
            }

            store.saving = false
        },
        closeModal() {
            this.resetFields()
            this.$emit('close-modal')
        },
        resetFields() {
            this.references = {
                attributes: {
                    'business-name': '',
                    'name': '',
                    'relationship': '',
                    'email': '',
                    'phone': '',
                    'address': null
                }
            }
        },
        async saveReference() {

            let res = null

            // create parts
            let part = {
                "type": "parts",
                "attributes": {},
                "relationships": {
                    "application": {
                        "data": {
                            "type": "applications",
                            "id": `${this.appId}`
                        }
                    },
                    "kind": {
                        "data": {
                            "type": "kinds",
                            "id": "3-11"
                        }
                    }
                }
            }

            let partRes = await this.createPart(part)

            // create references
            if(partRes.status === 201) {

                let referencePayload = {
                    "type": "references",
                    "attributes": {
                        ...this.references.attributes
                    },
                    "relationships": {
                        "application": {
                            "data": {
                                "type": "applications",
                                "id": `${this.appId}`
                            }
                        },
                        "parent-parts": {
                            "data": [
                                {
                                    "type": "parts",
                                    "id": partRes.data.data.id
                                }
                            ]
                        },
                        "kind": {
                            "data": {
                                "type": "kinds",
                                "id": "6-4"
                            }
                        }
                    }
                }

                res = await this.addNewReference(referencePayload)

                // create address
                if(res.status === 201) {

                    let address = {
                        "type": "addresses",
                        "attributes": {
                            ...this.references.address
                        },
                        "relationships": {
                            "application": {
                                "data": {
                                    "type": "applications",
                                    "id": `${this.appId}`
                                }
                            },
                            "references": {
                                "data": [
                                    {
                                        "type": "references",
                                        "id": res.data.data.id
                                    }
                                ]
                            }
                        }
                    }

                    await this.createAddress(address)

                }

            }

            return res
        }
    }
}
</script>
