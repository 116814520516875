<template>
  <v-card
    class="mx-auto"
    :disabled="isDisabled"
    tile
    flat
    :class="vb.mdAndUp ? 'px-8' : 'px-2'"
  >

    <!-- <div :class="vb.mdAndUp ? 'px-4 pb-2 title font-size-22' : 'd-none'">
        Summary
    </div> -->
    
    <v-card-text class="pa-4 pt-0 pa-md-10 pt-md-0">
      <v-container pa-0 fluid>
        <v-row class="mb-2">
          <v-col cols="12" class="pt-0">
            <div class="mb-8">
              <p>
                This page summarises the information you have already provided
                and also confirms your preferred loan setup.
              </p>
            </div>

            <!-- <div>
              <h3
                class="sub-title font-weight-bold"
              >
                Financial Position Stated
              </h3>
              <p v-if="loanType == 'Asset'">
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Rem
                quaerat libero nostrum quos cum! Quas distinctio id expedita
                iusto nihil!
              </p>
            </div> -->
          </v-col>
        </v-row>

        <div class="d-flex justify-space-between pb-10" :class="{'px-16 mx-10': vb.mdAndUp}">
          <div class="text-center px-2">
            <div class="font-size-10 font-weight-bold py-1" :class="{'font-size-14' : vb.mdAndUp}">
              Stated Income
            </div>
            <div class="d-flex justify-center" :class="{'flex-column': vb.smAndDown}">
              <div :class="vb.mdAndUp ? 'font-size-20' : 'font-size-14 font-weight-medium'">
                {{ stated_income | currencyNoDecimal }}
              </div>
              <div :class="vb.mdAndUp ? 'font-size-10' : 'font-size-8 mt-n2'">
                / month
              </div>
            </div>
          </div>
          <div class="text-center px-2">
            <div class="font-size-10 font-weight-bold py-1" :class="{'font-size-14' : vb.mdAndUp}">
              Stated Expenses
            </div>
            <div class="d-flex justify-center" :class="{'flex-column': vb.smAndDown}">
              <div :class="vb.mdAndUp ? 'font-size-20' : 'font-size-14 font-weight-medium'">
                {{ stated_expense | currencyNoDecimal }}
              </div>
              <div :class="vb.mdAndUp ? 'font-size-10' : 'font-size-8 mt-n2'">
                / month
              </div>
            </div>
          </div>
          <div class="text-center px-2" :class="uncomitted_icome < 0 ? 'red--text' : ''">
            <div class="font-size-10 font-weight-bold py-1" :class="{'font-size-14' : vb.mdAndUp}">
              <strong>Uncommited </strong> Income
            </div>
            <div class="d-flex justify-center" :class="{'flex-column': vb.smAndDown}">
              <div :class="vb.mdAndUp ? 'font-size-20' : 'font-size-14 font-weight-medium'">
                {{ uncomitted_icome | currencyNoDecimal }}
              </div>
              <div :class="vb.mdAndUp ? 'font-size-10' : 'font-size-8 mt-n2'">
                / month
              </div>
            </div>
          </div>
        </div>

        <v-row class="mb-10">
          <v-col cols="12">
            <h3 class="sub-title font-weight-bold">Declarations</h3>
          </v-col>

          <v-col cols="12" md="12" class="py-0 my-0" v-for="(item, index) in terms" :key="index">
            <v-checkbox v-model="checkboxes[index]">
              <template v-slot:label>
                <div
                  class="font-weight-regular"
                  v-html="item.attributes.content"
                ></div>
              </template>
            </v-checkbox>
          </v-col>
        </v-row>

        <v-alert dense outlined type="error" v-if="errors.length > 0">
          <div v-for="(msg, index) in errors" v-html="msg" :key="index"></div>
        </v-alert>
      </v-container>
    </v-card-text>
  </v-card>
</template>

<script>
import subscriptions from "~/components/shared/subscriptions";
import { store } from "~/components/v1/AppSummary1/__store.js";
import formHelpers from "~/components/shared/form-helpers.js";
import resourceHelpers from '~/components/shared/resource-helpers';
import validation from "~/components/shared/validation";
import { mapActions, mapState } from "vuex";
import NodifiMoney from "~/components/base/NodifiMoney";

export default {
  name: "AppSummary1",
  mixins: [store, formHelpers, validation, subscriptions, resourceHelpers],
  components: { NodifiMoney },
  data() {
    return {
      resources: {
        loans: {
          type: "loans",
          id: this.getLoanId,
          attributes: {
            "requested-required-loan-amount": null,
            "requested-term-months": 12,
            "personal-loan-reason": null,
            "requested-balloon": null,
          },
        },
      },
      stated_income: 0,
      stated_expense: 0,
      uncomitted_icome: 0,
      validation_rules: {
        attributes: {
          "requested-required-loan-amount": "required|between:0,9999999",
          "requested-term-months": "required",
          "personal-loan-reason": "required",
          "requested-balloon": "required|between:0,9999999",
        },
      },
      errors: [],
      terms: [
        {
          attributes: {
            content: "<p>I confirm there are no foreseeable changes that will reduce the uncommitted monthly income declared in this application</p>"
          }
        },
        {
          attributes: {
            content: "<p>I declare that the information that I have provided is true and correct</p>"
          }
        },
      ],
      checkboxes: [],
      commitments: [],
      loanType: "Asset",
      labels: {
        "requested-required-loan-amount": "Requested Loan Amount",
        "requested-term-months": "Preferred Loan Term",
        "requested-balloon": "Requested Balloon",
        "personal-loan-reason": "Personal Loan Reason",
      },
      people: [],
      reasons: [
        "Debt Consolidation",
        "Education",
        "Funeral Expenses",
        "Furniture and Appliances",
        "Home Improvements",
        "Medical Expenses",
        "Motor or Leisure Vehicle",
        "Other",
        "Solar Energy",
        "Tax Debt",
        "Travel",
        "Repairs",
        "Wedding",
      ],
      termMonths: [12, 24, 36, 48, 60, 72, 84],
      termOptions: [
          { 'value' : 12,
            'label' : '12 m/1y'
          },
          { 'value' : 24,
            'label' : '24 m/2y'
          },
          { 'value' : 36,
            'label' : '36 m/3y'
          },
          { 'value' : 48,
            'label' : '48 m/4y'
          },
          { 'value' : 60,
            'label' : '60 m/5y'
          },
          { 'value' : 72,
            'label' : '72 m/6y'
          },
          { 'value' : 84,
            'label' : '84 m/7y'
          },
      ],
      selectedTermMonths: ''
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.auth.user,
      appId: (state) => state.flows.appId,
    }),
    ...mapState("flows", {
      appTypeFE: (state) => state.appTypeFE,
    }),
    vb() {
      return this.$vuetify.breakpoint;
    },
    getLoanId() {
      return this.appData?.relationships?.loan?.data?.id;
    },
    filterResources() {
      let attributes = {};

      if (this.loanType == "Personal") {
        attributes = {
          "requested-required-loan-amount": null,
          "requested-term-months": 12,
          "personal-loan-reason": null,
        };
      } else {
        attributes = {
          "requested-required-loan-amount": null,
          "requested-term-months": 12,
          "requested-balloon": null,
        };
      }

      return attributes;
    },
  },
  methods: {
    ...mapActions("resource-actions", [
      "getPeople",
      "getBusinesses",
      "getLoan",
      "getTerms",
      "updateLoan",
      "getCommitments",
      "createCommitments",
    ]),
    async pull() {
        
      let promise1 = await this.getPeople({
        include: "incomes,liabilities,expenses",
      });
      let people = promise1.data;
      this.people = promise1.data.data;

      let incomes = [];
      let liabilities = [];

      if(people?.included) {
        people.included.forEach((item) => {
          if (item.type == "incomes") {
            incomes.push(item.attributes["net-monthly-amount"]);
          }

          // if (item.type == "liabilities") {
          //   liabilities.push(item.attributes["amount-owing"]);
          // }

          if (item.type == "expenses") {
              if(item.attributes["payment-amount"]) {
                  let amount = this.getMonthlyAmount(item.attributes["payment-amount"], item.attributes["payment-frequency"]);
                  liabilities.push(amount)
              }
              liabilities.push(item.attributes["living-child-care-fees"]);
              liabilities.push(item.attributes["living-education"]);
              liabilities.push(item.attributes["living-family-maintenance"]);
              liabilities.push(item.attributes["living-food-and-clothing"]);
              liabilities.push(item.attributes["living-lifestyle"]);
              liabilities.push(item.attributes["living-other-living-expenses"]);
              liabilities.push(item.attributes["living-transport"]);
              liabilities.push(item.attributes["living-tv-and-communications"]);
              liabilities.push(item.attributes["living-utilities-and-rates"]);
          }
        });
      }

      this.stated_income = incomes.reduce((a, b) => a + b, 0);
      this.stated_expense = liabilities.reduce((a, b) => a + b, 0);
      this.uncomitted_icome = this.stated_income - this.stated_expense;

      //WIP
      let promise2 = await this.getBusinesses({
        include: "incomes,liabilities,expenses",
      });
      let businesses = promise2.data.data;

      let promise3 = await this.getLoan(this.getLoanId);
      let loans = promise3.data.data;

      this.loanType =
        loans.attributes["loan-type"] == "Unsecured" ? "Personal" : "Asset";

      let termSlug

      if (this.loanType == "Personal") {
        if (this.appTypeFE.type.includes("Individual")) {
          termSlug = "b2c-summary-individual";
        } else {
          termSlug = "b2c-summary-joint";
        }
      } else {
        termSlug = "now-pl-submission-individual";
      }

      // let terms = await this.getTerms(termSlug);
      // this.terms = terms.data.data;

      // let commitments = await this.$api.get(
      //   `applications/${this.appId}?include=commitments`
      // );

      // if (commitments.data.hasOwnProperty("included")) {
      //   this.commitments = commitments.data.included;

      //   if (this.isCommitmentExists) {
      //     let termsArray = [];

      //     this.terms.forEach((term) => {
      //       termsArray.push(term.attributes.content);
      //     });

      //     this.checkboxes = termsArray;
      //   }
      // }

      Object.entries(this.resources.loans.attributes).forEach(([k, v]) => {
        Object.entries(loans.attributes).forEach(([key, value]) => {
          if (k == key) {
            this.resources.loans.attributes[k] = value;
          }
        });
      });

      if(this.resources.loans.attributes["requested-term-months"] == null || this.resources.loans.attributes["requested-term-months"] == 0) {
          this.resources.loans.attributes["requested-term-months"] = 60;
      }

      this.resources.loans.attributes["requested-term-months"] = this.termMonths.reduce(
        (a, b) => {
          return Math.abs(
            b - parseInt(this.resources.loans.attributes["requested-term-months"])
          ) <
            Math.abs(
              a -
                parseInt(this.resources.loans.attributes["requested-term-months"])
            )
            ? b
            : a;
        }
      );

      this.transformSelectedMonths();
    },

    async push() {
      this.checkCompleted();

      try {
        //const validate = await this.$refs.summary.validate();
        let valid = this.errors.length === 0;
        let status = valid ? "valid" : "dirty";

        let loansPayload = {
          type: "loans",
          id: this.getLoanId,
          attributes: { ...this.resources.loans.attributes },
        };

        if (valid) {
          await this.updateLoan(loansPayload);

          // let termsData = this.terms.map((term) => {
          //   return {
          //     id: term.id,
          //     type: "terms",
          //   };
          // });

          // let peopleData = this.people.map((person) => {
          //   return {
          //     id: person.id,
          //     type: "people",
          //   };
          // });

          // let committedUsers = []

          // if(this.user && this.user.id) {
          //     committedUsers.push({
          //           type: "users",
          //           id: this.user.id, // auth user
          //     })
          // }

          // let commitmentsPayload = {
          //   type: "commitments",
          //   attributes: {},
          //   relationships: {
          //     application: {
          //       data: {
          //         type: "applications",
          //         id: this.appId,
          //       },
          //     },
          //     "committed-users": {
          //       data: committedUsers
          //     },
          //     "committed-people": {
          //       data: peopleData, // all people in  this app
          //     },
          //     terms: {
          //       data: termsData, // all the terms
          //     },
          //   },
          // };
          // await this.createCommitments(commitmentsPayload);
        }

        return { valid, status, data: {} };
      } catch (error) {
        let status = "dirty";
        return { valid, status, data: {} };
      }
    },

    increase() {
      if (this.resources.loans.attributes["requested-term-months"] == null) {
        return false;
      }

      if (
        parseInt(this.resources.loans.attributes["requested-term-months"]) == 84
      ) {
        return false;
      }
      this.resources.loans.attributes["requested-term-months"] = parseInt(this.resources.loans.attributes["requested-term-months"]) + 12;
      this.transformSelectedMonths();
    },

    decrease() {
      if (this.resources.loans.attributes["requested-term-months"] == null) {
        return false;
      }

      if (
        parseInt(this.resources.loans.attributes["requested-term-months"]) == 12
      ) {
        return false;
      }
      this.resources.loans.attributes["requested-term-months"] = parseInt(this.resources.loans.attributes["requested-term-months"]) - 12;
        this.transformSelectedMonths();
    },

    checkCompleted() {
      this.errors = [];

      if (this.checkboxes.length != this.terms.length) {
        this.errors.push("Please accept all terms to proceed");
      }
    },

    isCommitmentExists() {
      let exists = false;

      /**
       *  NOTE:
       *  currently, an application can have multiple commitments
       *  for now, we're getting the first commitment to check if
       *  the terms have been accepted.
       */

      if (this.commitments.length > 0 && this.terms.length > 0) {
        // let commit = this.commitments[0]

        return true;
      }

      return exists;
    },

    transformSelectedMonths() {
        this.selectedTermMonths = this.termOptions.filter(item => item.value == this.resources.loans.attributes["requested-term-months"])[0].label;
    }
  },
  watch: {
    checkboxes(val) {
      if (val.length == this.terms.length) {
        this.errors = [];
      }
    },
  },
};
</script>

<style scoped>
.centered-input >>> input {
  text-align: center;
}
</style>