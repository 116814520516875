<template>
    <v-container class="flex__sub-component-CheckBox1">
        <div class="body-1 text-center font-weight-medium" style="font-size: 1.1rem !important;">{{ label }}</div>
        
            <v-item-group v-model="selected" multiple>
                <v-row align="center" justify="center">                
                    <div
                        class="d-flex pa-2 pb-0 ma-0">
                        <v-item
                            v-for="(n, key) in options"
                            :key="key"
                            v-slot="{ active, toggle }"
                            style="cursor: pointer;" >
                            <v-alert 
                                @click="toggled(toggle, !active, n)" outlined 
                                :color="`${active ? 'secondary' :  errors[0] ? 'error' : 'light_gray_1'}`"
                                class="rounded-xl pa-0 mx-2 my-0">
                                <v-card flat
                                    class="align-center rounded-xl"
                                    width="170">
                                    <v-card-text class="text-center">
                                        <div class="body-1 py-4">{{n.text}}</div>
                                    </v-card-text>
                                    <v-divider></v-divider>
                                    <v-card-actions 
                                        :class="`text-center ${active ? 'secondary lighten-5' : 'grey lighten-5'} px-0 py-4`">
                                        <v-spacer></v-spacer>
                                        <v-avatar
                                            color="light_gray_1"
                                            size="26">
                                            <v-icon 
                                                :class="`${active ? 'secondary' : 'light_gray_1'}`"
                                                :color="`${active ? 'secondary lighten-5' : 'light_gray_1'}`"
                                                size="16">
                                                mdi-check
                                            </v-icon>
                                            <span class="white--text headline"></span>
                                        </v-avatar>
                                        <v-spacer></v-spacer>
                                    </v-card-actions>
                                </v-card>
                            </v-alert>
                        </v-item>
                    </div>
                </v-row>
            </v-item-group>
            <div class="with-dynamic-validations caption text-center pa-1">
                {{ errors[0] }}
            </div>
    </v-container>    
</template>

<script>
    export default {
        name: 'CheckBox1',
        props: {
            options: {
                type: Array,
                default: () => []
            },
            errors: {
                type: Array,
                default: () => []
            },
            label: {
                type: String,
                dafault: ''
            }
        },
        methods: {
            toggled(toggle, active, item) {
                toggle()
                this.$emit('input', active || this.selected.length ? this.selected : [])
            }
        },
        data() {
            return {
                selected: []
            }
        }
    }
</script>

<style lang="scss" scoped>
</style>