var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{staticClass:"mx-auto",attrs:{"disabled":_vm.isDisabled,"tile":"","flat":""}},[_c('v-card-text',{staticClass:"pa-4 pt-0 pa-md-10 pt-md-0"},[_c('v-container',{attrs:{"pa-0":"","fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{staticClass:"v-data-table-6 mx-auto",attrs:{"loading":false,"loading-text":"Please wait...","items":_vm.people,"hide-default-footer":"","mobile-breakpoint":0,"hide-default-header":""},scopedSlots:_vm._u([(_vm.vb.mdAndUp)?{key:"item",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('v-card',{staticClass:"mb-6 pa-2 rounded-xl",attrs:{"tile":"","flat":"","color":"light_gray_1"}},[_c('div',{staticClass:"\n                      d-flex\n                      justify-space-between\n                      align-center\n                      py-2\n                      px-4\n                      flex-table-row\n                    "},[_c('div',{staticClass:"d-flex flex-column"},[_c('div',{staticClass:"mr-6"},[_c('v-icon',{attrs:{"size":"32"}},[_vm._v("mdi-account")])],1)]),_vm._v(" "),_c('div',{staticClass:"d-flex flex-column"},[_c('div',{staticClass:"mr-6 body-1 font-weight-medium"},[_vm._v("\n                        "+_vm._s(_vm.getFriendlyName(item))+"\n                      ")])]),_vm._v(" "),_c('div',{staticClass:"d-flex flex-column"},[_c('div',[_c('v-chip',{staticClass:"px-5 font-weight-medium",style:(("background-color: " + (_vm.hexToRGBA(
                            _vm.getActiveTheme.colors.secondary,
                            0.2
                          )))),attrs:{"filter":false,"ripple":false}},[_vm._v("\n                          "+_vm._s(item.roles.length > 1
                              ? ((item.roles[0]) + " +" + (item.roles.length - 1))
                              : item.roles.length == 1
                              ? item.roles[0]
                              : "")+"\n                        ")])],1)]),_vm._v(" "),_c('v-spacer'),_vm._v(" "),_c('div',{staticClass:"d-flex justify-end"},[_c('v-btn',{attrs:{"depressed":"","small":"","color":_vm.getCompleted.includes(item.id)
                            ? 'success'
                            : 'secondary'},on:{"click":function($event){return _vm.handleAddDetails(item)}}},[_vm._v("\n                        "+_vm._s(_vm.getCompleted.includes(item.id)
                            ? "Completed"
                            : "Add Details")+"\n                      ")])],1)],1)])]}}:{key:"item",fn:function(ref){
                            var item = ref.item;
                            var index = ref.index;
return [_c('v-card',{staticClass:"mb-6 pa-2 rounded-lg",attrs:{"tile":"","flat":"","color":"light_gray_1"}},[_c('v-card-text',{staticClass:"d-flex pb-1"},[_c('v-icon',{staticClass:"mt-n2",attrs:{"size":"32"}},[_vm._v("mdi-account")]),_vm._v(" "),_c('div',{staticClass:"body-1 font-weight-medium pl-1"},[_vm._v("\n                      "+_vm._s(_vm.getFriendlyName(item))+"\n                    ")])],1),_vm._v(" "),_c('v-card-text',{staticClass:"pt-0"},[_c('v-chip',{staticClass:"px-5 font-weight-medium",style:(("background-color: " + (_vm.hexToRGBA(
                        _vm.getActiveTheme.colors.secondary,
                        0.2
                      )))),attrs:{"filter":false,"ripple":false}},[_vm._v("\n                      "+_vm._s(item.roles.length > 1
                          ? ((item.roles[0]) + " +" + (item.roles.length - 1))
                          : item.roles.length == 1
                          ? item.roles[0]
                          : "")+"\n                    ")])],1),_vm._v(" "),_c('v-card-actions',[_c('v-spacer'),_vm._v(" "),_c('v-btn',{attrs:{"depressed":"","small":"","color":_vm.getCompleted.includes(item.id)
                          ? 'success'
                          : 'secondary'},on:{"click":function($event){return _vm.handleAddDetails(item)}}},[_vm._v("\n                      "+_vm._s(_vm.getCompleted.includes(item.id)
                          ? "Completed"
                          : "Add Details")+"\n                    ")])],1)],1)]}}],null,true)}),_vm._v(" "),(_vm.errors.length > 0)?_c('v-alert',{attrs:{"dense":"","outlined":"","type":"error"}},_vm._l((_vm.errors),function(msg){return _c('div',{domProps:{"innerHTML":_vm._s(msg)}})}),0):_vm._e()],1)],1)],1)],1)],1),_vm._v(" "),_c('v-dialog',{attrs:{"max-width":"844","persistent":"","scrollable":""},model:{value:(_vm.requirementsDialog),callback:function ($$v) {_vm.requirementsDialog=$$v},expression:"requirementsDialog"}},[_c('v-card',{attrs:{"flat":"","disabled":_vm.requirementsDialogLoading}},[(_vm.requirementsDialogLoading)?_c('v-progress-linear',{attrs:{"color":"secondary","indeterminate":"","stream":"","absolute":"","top":""}}):_vm._e(),_vm._v(" "),_c('v-card-title',{staticClass:"justify-end"},[_c('v-btn',{style:(("background-color: " + (_vm.hexToRGBA(
            _vm.getActiveTheme.colors.secondary,
            0.1
          )))),attrs:{"icon":"","max-width":"40","max-height":"40","fab":""},on:{"click":_vm.closeDialog}},[_c('v-icon',{attrs:{"color":"secondary"}},[_vm._v("mdi-close")])],1)],1),_vm._v(" "),_c('v-card-text',{staticClass:"px-0 py-0 pb-8"},[_c('v-carousel',{attrs:{"light":"","continuous":false,"show-arrows":false,"hide-delimiters":"","touchless":"","height":"auto","hide-delimiter-background":""},model:{value:(_vm.carousel),callback:function ($$v) {_vm.carousel=$$v},expression:"carousel"}},[_c('v-carousel-item',[_c('v-card',{attrs:{"flat":""}},[_c('v-card-title',{staticClass:"d-flex py-0",class:_vm.vb.mdAndUp ? 'px-8' : 'px-4'},[_c('div',[_c('h4',{class:_vm.vb.mdAndUp ? 'font-size-26' : 'font-size-18'},[_vm._v("\n                    Employment Information | "+_vm._s(_vm.getFriendlyName(_vm.selectedEntity))+"\n                  ")]),_vm._v(" "),_c('p',{class:_vm.vb.mdAndUp ? 'body-1' : 'caption'},[_vm._v("\n                    Please use the Nodifi Data to answer the questions in the Money Place Requirements\n                  ")])]),_vm._v(" "),_c('v-spacer')],1),_vm._v(" "),_c('v-card-text',{staticClass:"px-0"},[_c('FormEmploymentInfo',{ref:"EmploymentInformationForm",attrs:{"updater":_vm.formUpdater,"incomes":_vm.selectedEntityIncomes,"data":_vm.selectedEntityCurrentIncome},on:{"loading":_vm.handleLoading,"submit":_vm.handleFormEmploymentInfoSubmit}})],1)],1)],1)],1)],1),_vm._v(" "),_c('v-card-actions',{staticClass:"pa-4 pa-md-6 flex-wrap controls"},[(!_vm.isFirstStep)?_c('v-btn',{staticClass:"flex-back-button",attrs:{"color":"light_gray_2","min-width":140,"large":"","outlined":"","elevation":"0","nuxt":""},on:{"click":_vm.handleBack}},[_vm._v("\n          Back\n        ")]):_vm._e(),_vm._v(" "),_c('v-spacer'),_vm._v(" "),_c('v-btn',{attrs:{"color":"secondary","min-width":140,"large":"","elevation":"0","nuxt":""},on:{"click":_vm.handleNext}},[_vm._v("\n          Save\n        ")])],1)],1)],1),_vm._v(" "),(_vm.vb.smAndDown)?_c('HelperModal'):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }