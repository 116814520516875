<template>

    <div v-if="getLeftDrawerVariant" class="pa-2 pb-4">
        <v-btn
            v-if="isAppTargetAssister"
            color="secondary" 
            fab
            small 
            :disabled="isDisabled"
            depressed 
            v-on="enableClick() ? { click: handleClick } : {}"
            >
                <v-icon  size="18">{{ blockSettings.icon }}</v-icon>
        </v-btn>
    </div>
    <div v-else class="pa-4 pr-8">
        <v-btn
            v-if="isAppTargetAssister"
            color="secondary" 
            large 
            :disabled="isDisabled"
            depressed 
            width="100%"
            v-on="enableClick() ? { click: handleClick } : {}"
            >
                {{ blockSettings.label }}
        </v-btn>
    </div>

</template>

<script>

    import { mapState, mapGetters, mapActions, mapMutations } from 'vuex';
    import subscriptions from '~/components/shared/subscriptions'

    export default {
        mixins: [subscriptions],
        data(){
            return {
              saving: false,
              clickListener: null,
            }
        },        
        computed: {
            ...mapState('flows', [
                'appGuid',
                'config',
                'appData'
            ]),
            ...mapGetters('setup', [
                'getLeftDrawerVariant'
            ]),
            ...mapState('slug', [
                'groupSlug',
                'flowSlug'
            ]),
            isDisabled(){
                return this.stepableMode == 'disabled'
            },

            /**
             * NOTE: Our temporary solution to hide "Back to Indicative" button on lender-submission flows: application.target == 'Assister'.
             * Button2 component is also being used on "edit" and "view" flows ( Save and Exit Button ) 
             * 
             */
            isAppTargetAssister(){

                // Adding this temp logic to show on "edit" and "view" flows
                if(this.blockSettings && this.blockSettings.label) {
                    if(this.blockSettings.label == 'Save and Exit') {
                        return true
                    }
                }

                if(this.appData) {
                    return this.appData.attributes.target == 'Assister'
                }

            }
        },
        mounted() {
        },
        methods: {
            enableClick () {

                this.blockListeners2.forEach(listener => {
                    if (listener.event == 'components.click') {
                        this.clickListener = listener
                    }
                })

                return this.clickListener !== null
            },
            async handleClick () {

                let methodName = `__block_${this.clickListener.method}`
                let method = this[methodName] 

                if (typeof method === "function") {
                    method.call(null, this.clickListener.arguments)
                } else {
                    console.error(`Error: Method ${methodName} not found.`)
                }

            },
        }
    }
</script>

<style scoped lang="scss">


</style>
