<template>
    <div class="d-flex flex-column pr-6 pl-2 my-2" v-if="!isShown">

        <v-badge
            v-if="showReassignmentModal"
            color="white"
            class="mr-4 flex-start"
            style="z-index:1"
            overlap>
            <v-icon slot="badge" color="black" @click="handleOpenReassignmentModal">
                mdi-pencil
            </v-icon>
        </v-badge>
        
        <v-list  nav class="flex-sidebar2 "   :style="`background-color: ${hexToRGBA(getActiveTheme.colors.accent, 0.3)}`">
            <v-list-group
                @click="showInfo"
                v-model="isExpand"
                >

                <template v-slot:activator >
                    <v-list-item-action class="ml-n2 mr-4" >
                        <v-icon size="22">mdi-information</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                        <v-list-item-title class="white--text font-weight-bold py-0" v-text="`App Owner Info`" /> 
                    </v-list-item-content>
                </template>    

                <div  class="primary item-content">
                    <v-list-item class="pt-2">
                        <v-list-item-icon class="ml-2 mr-4 mt-1">
                            <v-icon size="22" v-text="`mdi-account-key`"></v-icon>
                        </v-list-item-icon>
                        <v-list-item-title>
                            <div>
                                {{ownerUsername}}
                            </div>
                            <div class="caption">
                                {{ownerEmail}}
                            </div>
                        </v-list-item-title>
                    </v-list-item> 
                    <v-list-item class="pb-2">
                        <v-list-item-icon class="ml-2 mr-4 mt-1">
                            <v-icon size="22" v-text="`mdi-account-group`"></v-icon>
                        </v-list-item-icon>
                        <v-list-item-title>
                            <div>
                                {{groupName}}
                            </div>
                            <div class="caption">
                                {{groupSlug}}
                            </div>
                        </v-list-item-title>
                    </v-list-item> 
                </div>
                

            </v-list-group>
        </v-list>

        <OwnerAssignmentModal show 
            :dialog-prop="ownerAssignmentDialog"
            @cancel="handleCloseReassignmentModal"
            @selectedUser="handleSelectUser"
            @submit="handleSubmitReassignment"
            title="Select Application Owner" 
            message="Who do you want to assign as the owner of this application?">
        </OwnerAssignmentModal>

    </div>
</template>
<script>

import { mapGetters, mapState, mapActions, mapMutations } from 'vuex' 
import subscriptions from '~/components/shared/subscriptions'
import OwnerAssignmentModal from '~/components/base/OwnerAssignmentModal'

export default {
    mixins: [subscriptions],
    components: {
        OwnerAssignmentModal
    },
    data(){
        return {
            owner: null,
            group: null,
            isExpand: true,

            ownerAssignmentDialog: false,
            canSelectOtherAsOwner: true,
            canSelectAuthUserAsOwner: false,
            reassignmentSelectedUser: null,
            groupList: [],
            isAfx: false,
        }
    },
    computed: {
        ...mapGetters({
            getLeftDrawer: 'setup/getLeftDrawer' ,
            getRightDrawer: 'setup/getRightDrawer' ,
            getLeftDrawerVariant: 'setup/getLeftDrawerVariant' ,
            getRightDrawerVariant: 'setup/getRightDrawerVariant' ,
        }),
        isShown() {

            if(this.getLeftDrawer || this.getRightDrawer) {
                return this.getLeftDrawerVariant || this.getRightDrawerVariant;
            }

            return false;
        },
        ...mapGetters('themes', [
            'getActiveTheme'
        ]),
        ...mapGetters('groups', [
            'groups',
        ]),
        ...mapState('flows', [
            'appData',
            'appOwnerData',
            'appGroupData',
            'expand'
        ]),
        ...mapState('auth-actions', [
            'user',
            'userSettings'
        ]),
        authUserGroups(){
            return this.user?.relationships?.groups?.data
        },
        ownerUsername(){
            const {owner} = this
            return owner.attributes ? owner.attributes.username : '--';
        },
        ownerEmail(){
            const {owner} = this
            return owner?.attributes ? owner.attributes.email : '--';
        },
        groupName(){
            const {group} = this
            return group?.attributes ? group.attributes.name : '--';
        },
        groupSlug(){
            const {group} = this
            return group?.attributes ? group.attributes.slug : '--';
        },
        showReassignmentModal(){
            if(this.isAfx) {
                return false
            }
            return true
        },
    },
    created () {
        this.initializeAppOwnerInfo()
        this.isExpand = this.expand
    },
    mounted(){
        const embedData = JSON.parse(sessionStorage.getItem('flex_app_embed_data'))

        if(embedData && embedData?.embed === "asset-finance-exchange") {
            this.isAfx = true
        }
    },
    methods: {
        ...mapActions('auth-actions', {
            fetchUser: 'fetchUser',
            fetchUserSetting: 'getUserSetting'
        }),
        ...mapActions('resource-actions', {
            runTransferApplication: 'runTransferApplication'
        }),
        ...mapActions('groups', {
            getGroup: 'getGroup',
        }),
        ...mapMutations('auth-actions', [
            'setAppOwnerSettings'
        ]),
        hexToRGBA(hex, a = 1){
            return this.$options.filters.hexToRGBA(hex, a)
        },
        async initializeAppOwnerInfo () {

            const {appOwnerData, appGroupData} = this

            if(!appOwnerData) {
                let res = await this.fetchUser(this.appData?.relationships?.owner?.data?.id)
                // let group = await this.$api('groups/' + item.group.data.id)

                this.$store.commit('flows/setAppOwnerData', res.data.data)
                this.owner = res.data.data

            } else {

                this.owner = appOwnerData
            }

            if(!appGroupData) {
                let res = await this.getGroup(this.appData?.relationships?.group?.data?.id)

                this.$store.commit('flows/setAppGroupData', res.data.data)
                this.group = res.data.data

            } else {

                this.group = appGroupData
            }

        },
        showInfo () {
            this.$store.commit('flows/setExpand',this.expand)

        },

        // App Assignment Methods
        handleOpenReassignmentModal(){

            this.ownerAssignmentDialog = true

            this.initializeOwnerAssignmentDialog()

        },
        handleCloseReassignmentModal (val){

            this.ownerAssignmentDialog = false

        },
        initializeOwnerAssignmentDialog() {

            const {userSettings} = this

        },
        async handleSelectUser(userId) {

            if(!userId) return;

            this.reassignmentSelectedUser = userId

            // try {
                
            //     let promise1 = await this.fetchUser(userId)

            //     this.groupList = this.filterApplicableGroups(promise1.data?.data?.relationships?.groups?.data)

            // } catch (error) {
            //     console.log('Uh oh!', error)
            // }
        },
        filterApplicableGroups(selectedUserGroups){

            const {authUserGroups, groups} = this

            let groupList = []

            /**
             * Check if the selected user has groups. then, get the common groups with the authenticated user
             */
            if(selectedUserGroups.length > 0) {

                /**
                 *  Check if auth user has groups, then, proceed filtering common groups.
                 *  Otherwise, use only the groups of selected user.
                 */
                if(authUserGroups.length > 0){

                    let commonGroups = []

                    authUserGroups.forEach( data => {

                        let group =  selectedUserGroups.find(el => el.id == data.id)

                        if(group) {
                            commonGroups.push(group)
                        }
                    })

                    /**
                     * Now that we have the common group ids, get the complete data of common-groups 
                     *  in the "groups" ( complete data of each group, e.g. slug, name. etc. )
                     */
                    if(commonGroups.length > 0 ) {

                        commonGroups.forEach(data => {

                            let group = this.groups.find(el => el.id == data.id)

                            if(group) {
                                groupList.push(group)
                            }
                        })

                    } else {

                        this.toggleQuickMessage({
                            text: "Can't assign app to user. No common groups.",
                            color: "error"
                        })
                    }

                } else {

                    selectedUserGroups.forEach(data => {
                        
                        let group = this.groups.find(el => el.id == data.id)

                        if(group) {
                            groupList.push(group)
                        }

                    })

                }
                
            } else {

                groupList = groups
            }

            return groupList
        },

        async handleSubmitReassignment(groupId) {

            this.setGlobalMode('disabled')

            try {
                    
                const {reassignmentSelectedUser} = this

                let payload = {
                    "application-guid": this.appData.attributes.guid,
                    "owner-id": reassignmentSelectedUser,
                    "group-id": groupId,
                }

                this.handleCloseReassignmentModal()

                let promise1 = await this.runTransferApplication(payload)
                let promise2 = await this.fetchUserSetting(reassignmentSelectedUser)
                let promise3 = await this.fetchUser(reassignmentSelectedUser)
                let promise4 = await this.getGroup(groupId)

                const res = await Promise.all([promise1, promise2, promise3, promise4]) 

                promise1 = res[0]
                promise2 = res[1]
                promise3 = res[2]
                promise4 = res[3]

                // Update the App Owner in Store
                this.setAppOwnerSettings(promise2?.data?.data?.attributes)

                this.$store.commit('flows/setAppOwnerData', promise3.data.data)
                this.$store.commit('flows/setAppGroupData', promise4.data.data)

                await this.initializeAppOwnerInfo()

                this.handleClearReassignmentData()

                this.setGlobalMode('enabled')

                this.toggleQuickMessage({
                    text: "Application transfer successful!",
                    color: "success"
                })

            } catch (error) {

                this.toggleQuickMessage({
                    text: "Transfer failed, please try again.",
                    color: "error"
                })

                this.setGlobalMode('enabled')
            }

        },
        handleClearReassignmentData(){
            this.groupList = []
        },
    },
}
</script>
<style lang="scss">
    .flex-sidebar2 {
        border-radius: 4px !important;

        &.v-list--nav {
            .item-content {
                border-radius: 4px;
            }

            .v-list-item {
            
                .v-list-item__action,
                .v-list-item__icon,
                .v-list-item__title,
                .v-list-item__icon .v-icon {
                    color: white !important;
                }
                .v-list-item__content {
              
                }
            }

            .v-list-item__action .v-icon {
                color: white;
            }

            .v-list-group, .v-list-group.v-list-group--active {
                .v-list-group__header {
                    .v-list-item__icon.v-list-group__header__append-icon {
                        min-width: 10px !important;
                    }
                }
            }

            .v-list-item:not(:last-child):not(:only-child), .v-list--rounded .v-list-item:not(:last-child):not(:only-child) {
                margin-bottom: 0px !important;
            }
        }

    } 
</style>
