<template>
    <v-card width="100%" flat>

        <v-card-title :class="['px-3 pb-3', 'justify-center']" v-if="parseInt(activeStep) == 1 || parseInt(activeStep) == 2">
            Add Purchase
        </v-card-title>

        <v-card-text :class="['mb-6', 'text-center' ]" v-if="parseInt(activeStep) == 1 || parseInt(activeStep) == 2">
            <div class="body-1 font-weight-medium">
                Please select one that applies
            </div>
        </v-card-text>

        <v-card-text class="text-center mx-0 px-0">
            <v-stepper
                v-model="activeStep" flat
                class="elevation-0 purchase-box">
                <v-stepper-items>

                    <!-- STEP 1 -->
                    <v-stepper-content step="1" class="pa-0">
                        <v-sheet max-width="800" class="mx-auto mb-16">
                            <v-container class="light_gray_1 px-4 py-2 rounded-lg">
                                <v-row>
                                    <v-col v-for="(asset, akey) in assetTypeList"
                                    class="pa-2"
                                    :key="akey" cols="12" sm="6">
                                        <v-card flat height="100%"
                                        @click="setAssetType(asset)"
                                        rounded="lg"
                                        class="d-flex pa-4 align-center justify-center">
                                            <div class="flex-options body-2 font-weight-medium">
                                                {{ asset.name }}
                                            </div>
                                            <v-spacer></v-spacer>
                                            <v-btn color="secondary" :width="86" small depressed :data-btn="`asset-${akey + 1}`">
                                                Select
                                            </v-btn>
                                        </v-card>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-sheet>
                    </v-stepper-content>

                    <!-- STEP 1 -->
                    <v-stepper-content step="2" class="pa-0">
                        <v-sheet class="mx-auto">
                            <v-container class="light_gray_1 px-4 py-2 mb-6 rounded-lg" style="max-width:800px;">
                                <v-row>
                                    <v-col v-for="(sub_asset, akey) in assetSubList"
                                    class="pa-2"
                                    :key="akey" cols="12" sm="6">
                                        <v-card flat height="100%"
                                        @click="setSubAssetType(sub_asset)"
                                        rounded="lg"
                                        class="d-flex pa-4 align-center justify-center">
                                            <div class="flex-options body-2 font-weight-medium">
                                                {{ sub_asset.name }}
                                            </div>
                                            <v-spacer></v-spacer>
                                            <v-btn color="secondary" :width="86" small depressed :data-btn="`sub-asset-${akey + 1}`">
                                                Select
                                            </v-btn>
                                        </v-card>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-sheet>
                    </v-stepper-content>

                    <!-- STEP 2 -->
                    <v-stepper-content step="3" class="pa-0 px-md-12">
                        <FormPurchaseDetails
                            @on-form-update="prepareResources"
                            @on-chosen-img="getImage"
                            :purchase-kind="purchaseKindId"
                            :selected-type="assetType"
                            :selected-sub-type="assetSubType"
                            :sync-refresh="isNew"
                            :kind-of-purchase="assetType" />
                    </v-stepper-content>
                </v-stepper-items>
            </v-stepper>
        </v-card-text>

    </v-card>
</template>

<script>

    import { mapGetters } from 'vuex'
    import FormPurchaseDetails from './_FormPurchaseDetails'

    export default {
        name: 'NewPurchaseAssetsGrid',

        components: { FormPurchaseDetails },

        props: {
            isNew: {
                type: Boolean,
                default: false
            },
            assetIcons: {
                type: Object,
                default: () => {}
            },
            prevStep: {
                type: Number,
                default: 0
            }
        },

        computed: {
            ...mapGetters('kinds', ['kinds']),

            gridBoxHeight() {
                if ( this.$vuetify.breakpoint.xsOnly ) return '140'
                return '200'
            },

            gridBoxWidth() {
                if ( this.$vuetify.breakpoint.xsOnly ) return '120'
                return '250'
            },

            assetTypeList() {
                let hiddenKinds = ['Real Estate - Established', 'Real Estate - Construction']
                // We need Kinds for Purchases
                let purchasesKinds = this.kinds.find( kind => {
                    return kind.name == 'Purchase'
                })

                let purchases = purchasesKinds.children.filter( (purchase) => {
                    return hiddenKinds.indexOf(purchase.name) < 0
                })

                return purchasesKinds ? purchases : []
            },

            assetSubTypeList() {

                let subType = this.assetTypeList.find( item => {
                    return item.name == this.assetType
                })

                if(subType) {
                    return subType.children || []
                } else {
                    return []
                }
            },
        },

        mounted() {
        },

        methods: {
            backToAssetType () {
                this.assetType = this.assetType = null
            },
            setAssetType(asset) {
                this.purchaseKindId = asset.id
                this.assetType = asset.name
                this.currentStep = 1
                this.activeStep = 2
                this.assetSubList = this.assetSubTypeList
                if (!this.assetSubTypeList.length) {
                    this.activeStep = 3
                }
                // this.activeStep = 2
            },
            setSubAssetType(asset) {
                this.purchaseKindId = asset.id
                this.assetType = asset.name
                this.currentStep = 2
                this.activeStep = 3
                if (!this.assetSubTypeList.length) {
                    this.activeStep = 3
                }
            },
            getIcon(key) {
                return this.assetIcons?.[key]?.icon ? this.assetIcons[key].icon : 'mdi-cupboard'
            },
            prepareResources(data) {
                this.resources = data.resources
                this.resources.relationships.kind.data.id = this.purchaseKindId
                this.$emit('on-updated-form',
                    {
                        resources: data.resources,
                        observer: data.observer,
                        img: this.img
                    }
                )
            },
            getImage(img) {
                this.img = img
            }
        },

        watch: {
            'isNew': {
                handler(val) {
                    if (val) {
                        this.activeStep = 1
                        this.assetType = ''
                        this.assetSubType = ''
                        this.purchaseKindId = ''
                    }
                },
                deep: true
            },
            'activeStep': {
                handler(val) {
                    this.$emit('on-step-change', { step: this.activeStep, last_step: this.activeStep === 3 ? true : false, prev_step: this.currentStep })
                },
                deep: true
            },
            prevStep(val) {
                if (val) {
                    this.activeStep = val
                }
            },
            'img': {
                handler(val) {
                    this.$emit('on-updated-form', { resources: this.resources, img: this.img })
                },
                deep: true
            },
            'assetSubTypeList': {
                handler(val) {
                    if ( val.length === 1 ) {
                        this.assetSubType = val[0].name
                        this.purchaseKindId = val[0].id
                        this.activeStep = 3
                    }
                },
                deep: true
            }
        },

        data() {
            return {
                img: '',
                form: {},
                assetType: '',
                assetSubType: '',
                activeStep: '1',
                resources: {},
                purchaseKindId: '',
                assetSubList: [],
                currentStep: 0
            }
        }
    }
</script>

<style lang="scss" scoped>
    .square-buttons {
        display: inline-flex;
    }
</style>
