<template>
    <v-dialog
        v-model="dialog"
        @keydown.esc="dialog = false"
        max-width="1124px"
        scrollable
        persistent>

        <template v-if="customButtons" v-slot:activator="{ on, attrs }">
            <v-card flat height="100%"
                v-bind="attrs"
                v-on="on"
                rounded="lg"
                class="d-flex pa-4 align-center justify-center">
                <div class="flex-options body-2 font-weight-medium">
                    {{ buttonLabel }}
                </div>
                <v-spacer></v-spacer>
                <v-btn color="secondary" :width="86" small depressed>
                    Select
                </v-btn>
            </v-card>
        </template>

        <template v-else v-slot:activator="{ on, attrs }">
            <v-btn
                fab
                depressed
                text
                small
                color="light_gray_2"
                @click="openEditForm">
                <v-icon size="20">mdi-pencil</v-icon>
            </v-btn>
        </template>

            <v-card tile flat class="rounded-lg" :disabled="saving">
            <v-progress-linear color="secondary" indeterminate stream absolute top v-if="saving"></v-progress-linear>

            <v-card-title class="headline white lighten-2 mb-6">
                <v-spacer></v-spacer>
                <v-btn icon @click="dialog=false">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-card-title>


            <v-card-text class="pa-0 pa-md-8 pt-0 pt-md-0">
                <v-card tile flat class="rounded-lg mx-auto" width="766px">

                    <v-card-title class="px-3 pb-6">
                        <span>Reference Details</span>
                    </v-card-title>

                    <v-card-text>
                        <ValidationObserver ref="observer" tag="form">

                            <v-row>
                                <v-col cols="12" md="6" class="px-3 py-0">
                                    <ValidationProvider
                                        v-slot="{ errors }"
                                        name="Name"
                                        rules="required"
                                        vid="Name">
                                        <v-text-field
                                            v-model="form.reference.name"
                                            color="secondary"
                                            :error-messages="errors"
                                            :label="fields['name'].label">
                                        </v-text-field>
                                    </ValidationProvider>
                                </v-col>
                                <v-col
                                    v-show="selectedRelationship === 'Accountant' ? false : true"
                                    cols="12" md="6" class="px-3 py-0">
                                    <ValidationProvider
                                        v-slot="{ errors }"
                                        name="Relationship"
                                        rules="required"
                                        vid="Relationship">
                                        <v-select
                                            v-model="form.reference.relationship"
                                            :items="relationship[selectedRelationship]"
                                            :error-messages="errors"
                                            color="secondary"
                                            :label="fields['relationship'].label">
                                        </v-select>
                                    </ValidationProvider>
                                </v-col>
                                <v-col
                                    v-if="selectedRelationship === 'Accountant' || selectedRelationship === 'Landlord'"
                                    cols="12" md="6" class="px-3 py-0">
                                    <ValidationProvider
                                        v-slot="{ errors }"
                                        name="Email"
                                        rules="email"
                                        vid="Email">
                                        <v-text-field
                                            v-model="form.reference.email"
                                            :error-messages="errors"
                                            color="secondary"
                                            :label="fields['email'].label">
                                        </v-text-field>
                                    </ValidationProvider>
                                </v-col>
                                <v-col
                                    v-show="false"
                                    cols="12" md="6" class="px-3 py-0">
                                    <ValidationProvider
                                        v-slot="{ errors }"
                                        name="Date"
                                        rules="required"
                                        vid="Date">
                                        <FlexDatePicker
                                            v-model="form.reference.date"
                                            :errors="errors"
                                            color="secondary"
                                            :label="fields['date'].label" />
                                    </ValidationProvider>
                                </v-col>
                                <v-col
                                    cols="12"
                                    :md="selectedRelationship === 'Accountant' || selectedRelationship === 'Business' ? 12 : 6"
                                    class="px-3 py-0">
                                    <ValidationProvider
                                        v-slot="{ errors }"
                                        name="Phone"
                                        rules="required|length:10|numeric"
                                        vid="Phone">
                                        <v-text-field
                                            v-model="form.reference.phone"
                                            :error-messages="errors"
                                            color="secondary"
                                            :label="fields['phone'].label">
                                        </v-text-field>
                                    </ValidationProvider>
                                </v-col>
                                <v-col
                                    v-if="!isPersonal"
                                    cols="12" sm="6" md="5" class="px-3 py-0">
                                    <ValidationProvider
                                        v-slot="{ errors }"
                                        name="Is Business"
                                        rules="required"
                                        vid="Is Business">
                                        <IsBusiness
                                            v-model="form.reference['is-business']"
                                            :items="['Yes', 'No']"
                                            :error-messages="errors"
                                            :label="fields['is-business'].label"
                                            :sync-reset="dialog" />
                                    </ValidationProvider>
                                </v-col>

                                <v-col
                                    v-if="!isPersonal && (form.reference['is-business'] !== 'No' && form.reference['is-business'] !== '' ? true : false)"
                                    cols="12" sm="6" md="7" class="px-3 py-0">
                                    <ValidationProvider
                                        v-slot="{ errors }"
                                        name="Business Name"
                                        :rules="form.reference['is-business'] === 'Yes' ? 'required' : form.reference['is-business'] === '' ? 'required' : '' "
                                        vid="Business Name">
                                        <v-text-field
                                            v-model="form.reference['business-name']"
                                            :error-messages="errors"
                                            :disabled="form.reference['is-business'] === 'No' ? true : false"
                                            color="secondary"
                                            :label="fields['business-name'].label">
                                        </v-text-field>
                                    </ValidationProvider>
                                </v-col>
                            </v-row>

                            <v-row v-if="isLandlord">
                              <v-col>
                                <label class="font-weight-medium" :class="{'error--text' :addressSelectionError}">Select an address</label>
                                <span class="error--text text-sm d-block mt-2" v-if="addressSelectionError">
                                  Address is required
                                </span>
                                <v-list two-line>
                                    <v-list-item-group class="list-item-selector">

                                        <v-list-item
                                                    :ripple="false"
                                                    selectable
                                                    v-for="(option, index) in addressList"
                                                    @click.stop="initializeAddress(option, option.attributes)"
                                                    :key="index">
                                            <v-list-item-content>
                                                <v-list-item-title>
                                                    <span>{{getFullAddress(option)}}</span>
                                                </v-list-item-title>
                                            </v-list-item-content>

                                            <v-list-item-action>
                                                <v-btn :color="selectedAddress.id === option.id  ? 'secondary' : 'primary'"   small elevation="0" nuxt outlined>
                                                    {{ selectedAddress.id === option.id  ? 'Selected' : 'Select'}}
                                                </v-btn>
                                            </v-list-item-action>

                                        </v-list-item>

                                    </v-list-item-group>
                                </v-list>
                              </v-col>
                            </v-row>

                            <v-row v-if="!isLandlord">
                                <v-col cols="12 py-0 px-3 pt-4">
                                    <google-address-tool
                                        name="address"
                                        label="Address Search"
                                        v-model="googleSearch"
                                        @change="initializeAddress"
                                    ></google-address-tool>
                                </v-col>
                            </v-row>
                            <v-row v-if="!isLandlord">
                                <v-col
                                    v-for="(refItem, rkey) in form.address"
                                    :key="rkey"
                                    cols="12"
                                    lg="4"
                                    md="4"
                                    sm="4"
                                    class="px-3 py-0">

                                    <ValidationProvider
                                        v-slot="{ errors }"
                                        :name="fields[rkey].name | formalText"
                                        :rules="getRules(rkey)"
                                        :vid="fields[rkey].name">

                                        <v-select
                                            v-if="isFieldType('picklist', rkey)"
                                            :items="getOptions(rkey)"
                                            :label="fields[rkey].label"
                                            :placeholder="fields[rkey].placeholder ? fields[rkey].placeholder : ''"
                                            :error-messages="errors"
                                            v-model="form.address[rkey]"
                                            color="secondary"
                                        >
                                        </v-select>

                                        <v-text-field
                                            v-else
                                            v-model="form.address[rkey]"
                                            :error-messages="errors"
                                            color="secondary"
                                            :label="fields[rkey].label"
                                            :placeholder="fields[rkey].placeholder ? fields[rkey].placeholder : ''">
                                        </v-text-field>

                                    </ValidationProvider>
                                </v-col>
                            </v-row>

                        </ValidationObserver>
                    </v-card-text>

                </v-card>

            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions class="pa-4 pa-md-6 flex-wrap controls">

                <v-btn
                    @click="dialog = false"
                    depressed
                    :min-width="140" large outlined
                    color="light_gray_2"
                    class="flex-back-button"
                    text>Back</v-btn>

                <v-spacer></v-spacer>

                <v-btn
                    @click="save"
                    :disabled="saving"
                    depressed
                    :min-width="140" large
                    color="secondary"
                    >Save</v-btn>

            </v-card-actions>
        </v-card>

    </v-dialog>
</template>

<script>

    // TODOs:
    // Double check the required fields

    import formHelpers from '~/components/shared/form-helpers.js'
    import validation from '~/components/shared/validation'
    import { mapActions, mapMutations } from 'vuex'
    import IsBusiness from '~/components/base/ButtonSelections'
    import GoogleAddressTool from '~/components/base/GoogleAddressTool'
    import FlexDatePicker from '~/components/base/FlexDatePicker'
    import helpers from '~/plugins/helpers'

    export default {
        name: 'ReferenceEditorForm',
        mixins: [ helpers, validation, formHelpers ],
        components: {
            IsBusiness,
            GoogleAddressTool,
            FlexDatePicker
        },

        computed: {
          isLandlord () {
            return this.requiresLandLordReference && this.selectedRelationship === 'Landlord'
          },
            isPersonal() {
                let flagged = false
                let temp = ''
                if (this.action === 'add') {
                    flagged = this.buttonLabel === 'Personal'
                }
                else {
                    Object.keys(this.relationship).forEach( res => {
                        Object.keys(this.relationship[res]).forEach( item => {
                            if ( this.relationship[res][item] === this.form.reference.relationship) {
                                temp = res
                            }
                        })
                    })
                    flagged = temp === 'Personal'
                }
                return flagged

            },
            addresses() {
                return this.form.address
            },
            selectedRelationship() {
                let temp = this.buttonLabel
                if (this.action === 'edit') {
                    Object.keys(this.relationship).forEach( res => {
                        Object.keys(this.relationship[res]).forEach( item => {
                            if ( this.relationship[res][item] === this.form.reference.relationship || this.relationship[res][item] === this.buttonLabel ) {
                                temp = res
                                this.selectedReferenceKind = this.re
                            }
                        })
                    })
                }
                return temp
            },
            getSelectedReferenceKind() {
                let ref = this.selectedKind.children.find( (skind) => {
                    return skind.name === this.buttonLabel
                })
                return {
                    data: {
                        id: ref.id,
                        type: 'kinds'
                    }
                }
            }
        },
        props: {

            formReference: {
                type: Object,
                default: () => {}
            },
            requiresLandLordReference: {
                type: Boolean,
                default: false
            },

            formAddress: {
                type: Object,
                default: () => {}
            },

            action: {
                type: String,
                default: 'add'
            },
            appId: {
                type: String,
                default: null
            },

            customButtons: {
                type: Boolean,
                default: false
            },

            buttonLabel: {
                type: String,
                default: 'Click Here'
            },

            selectedKind: {
                type: Object,
                default: () => {}
            }

        },

        async created() {
            this.resourceObj = {}
            const { data } = await this.getAddresses()
            this.addressList = data.data.filter(item => {
              return item.relationships.occupancies && item.relationships.occupancies.data.length
            })
        },

        methods: {

            ...mapActions('resource-actions', [
                'saveReferences',
                'saveReferencesOfEmptyRecord',
                'getAddresses'
            ]),
            ...mapMutations('dialogs', [
                'toggleTouched',
            ]),
            // ...mapMutations('utils', ['toggleQuickMessage']),
            getFullAddress(address){
              if(!address && address?.attributes) return "";

              if(!address.hasOwnProperty('attributes')) return "";

              return  `${address.attributes["street-number"]} ${address.attributes["street-name"]} ${address.attributes["street-type"]}, ${address.attributes["suburb"]} ${address.attributes["state"]} ${address.attributes["postcode"]}`

              },
            openEditForm(){

                this.dialog = true
                this.toggleTouched(true)

            },
            async save() {
                let _s = this
                const valid = await this.$refs.observer.validate()
                const observer = this.$refs.observer
                const touched = this.$refs.observer.flags.touched

                this.addressSelectionError = false
                if (this.isLandlord && !this.form.address.postcode) {
                  this.addressSelectionError = true
                  return
                }

                if ( valid ) {

                    this.saving = true

                    Object.keys(this.formReference.relationships).forEach( rel => {
                        if ( this.formReference.relationships[rel]?.data === null || this.formReference.relationships[rel]?.data.length === 0 )
                            delete this.formReference.relationships[rel]
                    })

                    // Object.keys(this.formAddress.relationships).forEach( rel => {
                    //     if ( this.formAddress.relationships[rel]?.data === null || this.formAddress.relationships[rel]?.data.length === 0 )
                    //         delete this.formAddress.relationships[rel]
                    // })

                    this.form.reference['name'] = this.$options.filters.formalAndCapitalize(this.form.reference['name'])
                    this.form.reference['business-name'] = this.$options.filters.formalAndCapitalize(this.form.reference['business-name'])

                    let res = null

                    if (this.action === 'add') {



                        res = await this.saveReferencesOfEmptyRecord({
                            payload: this.form,
                            data: {
                                reference: this.formReference,
                                address: this.formAddress
                            },
                            selectedAddressId: this.isLandlord ? this.selectedAddress.id : null
                        })
                    }
                    else {
                      // add condition when for landlord
                        res = await this.saveReferences({
                            payload: this.form,
                            data: [
                                { data: this.formReference, ref: 'reference' },
                                { data: this.formAddress, ref: 'address' }
                            ],
                            selectedAddressId: this.isLandlord ? this.selectedAddress.id : null
                        })

                    }

                    this.saving = false

                    if (this.isAllPromisesValid(res)) {

                        let data = this.$options.filters.toAppDataFormat(res)
                        if ( this.action === 'add') {
                            // this.toggleQuickMessage({text: 'You have successfully added a new reference!', color: 'success'})
                            this.$emit('save-record', res)
                        }
                        else {
                            // this.toggleQuickMessage({text: 'Selected reference has been updated!', color: 'success'})
                            this.$emit('update-record', res)
                        }
                        this.dialog = false
                        this.selectedAddress = {}
                        return  { valid, status, data }
                    }

                    else {
                        return  { valid, status }
                    }
                }

            },

            initializeAddress(address, obj) {
                // this.form.address = address
                this.addressSelectionError = false
                this.selectedAddress = address
                this.form.address = {
                    "street-number": obj["street-number"],
                    "street-name": obj["street-name"],
                    "street-type": obj["street-type"],
                    "suburb": obj["suburb"],
                    "state": obj["state"],
                    "postcode": obj["postcode"]
                }
            },

            isAllPromisesValid(responses) {

                let valid = responses.length > 0 ? true : false

                if (valid)
                    responses.forEach( res => {
                        if (res && res.status != 200 && res.status != 201) valid = false
                    })

                return valid

            },
        },

        watch: {
          formAddress (address) {
            if (address && address.id) {
              this.selectedAddress.id = address.id
            }
          },
            "form.reference": {
                handler(val) {
                    if ( val['is-business'] === 'No' ) {
                        // this.form.reference['business-name'] = ""
                    }
                },
                deep: true
            },

            dialog: {
                handler(val) {
                    let _s = this
                    if ( val ) {
                        if ( this.action === 'edit' ) {

                            this.form = {
                                reference: Object.assign({}, this.formReference.attributes),
                                address: Object.assign({}, this.formAddress.attributes)
                            }

                            Object.keys(_s.form).forEach( f => {
                                if ( _s.form[f] !== undefined )
                                    Object.keys( _s.form[f]).forEach( field => {
                                        if ( _s.fields[field] === undefined )
                                            delete _s.form[f][field]
                                    })
                                else _s.form[f] = {}
                            })
                            this.form.reference.date = this.$options.filters.dateFormat(this.form.reference.date)
                        }
                        else {
                            this.form = {
                                reference: {
                                    'name': '',
                                    'relationship': '',
                                    'is-business': '',
                                    'business-name': '',
                                    'phone': '',
                                    'date': '',
                                    'email': ''
                                },
                                address: {
                                    'street-number': '',
                                    'street-name': '',
                                    'street-type': '',
                                    'suburb': '',
                                    'state': '',
                                    // 'country': '',
                                    'postcode': ''
                                }
                            }
                            this.$refs?.observer?.reset()

                            this.form.reference.date = this.$moment().format('YYYY-MM-DD')
                            this.form.reference.relationship = this.buttonLabel === 'Accountant' ? 'Accountant' : ''

                            if(this.formReference.relationships && this.formReference.relationships.kind){

                                this.formReference.relationships.kind = this.getSelectedReferenceKind //{ id: this.selectedKind.id, type: 'kinds' }

                            } else {

                                this.formReference.relationships = {
                                    'address': {
                                        'data': { 'id': null, 'type': 'addresses' }
                                    },
                                    'application': {
                                        'data': { 'id': this.appId, 'type': 'applications' }
                                    },
                                    'kind': this.getSelectedReferenceKind
                                }
                            }

                            // this.form.reference['is-business'] = this.buttonLabel === 'Personal' ? 'No' : 'Yes'
                        }
                        this.googleSearch = ''
                        this.$emit('selected-item', false)
                    }
                    if (this.isPersonal) this.form.reference['is-business'] = "No"
                },
                deep: true
            }
        },

        data() {
            return {
              addressSelectionError: false,
                dialog: false,
                saving: false,
                googleSearch: '',
                selectedAddress: {},
                resources: [],
                addressList: [],
                resourceObj: {},
                form: {
                    reference: {
                        'name': '',
                        'relationship': '',
                        'is-business': '',
                        'business-name': '',
                        'phone': '',
                        'date': '',
                        'email': ''
                    },
                    address: {
                        'street-number': '',
                        'street-name': '',
                        'street-type': '',
                        'suburb': '',
                        'state': '',
                        // 'country': '',
                        'postcode': ''
                    }
                },
                fields: {
                    'name': { placeholder: '', label: 'Contact Name', name: 'name' },
                    'relationship': { placeholder: '', label: 'Relationship', name: 'relationship' },
                    'is-business': { placeholder: '', label: 'Is Business', name: 'is-business' },
                    'business-name': { placeholder: '', label: 'Business Name', name: 'business-name' },
                    'phone': { placeholder: '', label: 'Phone Number', name: 'phone' },
                    'date': { placeholder: '', label: 'Reference Date', name: 'date' },
                    'email': { placeholder: '', label: 'Email', name: 'email' },
                    'street-number': { placeholder: 'Street No.', label: 'Street Address', name: 'street-number' },
                    'street-name': { placeholder: 'Street Name', label: '', name: 'street-name' },
                    'street-type': { placeholder: 'Street Type', label: '', name: 'street-type' },
                    'suburb': { placeholder: '', label: 'Suburb', name: 'suburb' },
                    'state': { placeholder: '', label: 'State', name: 'state' },
                    // 'country': { label: 'Country', name: 'country' },
                    'postcode': { label: 'Postcode', name: 'postcode' },
                },
                validation_rules: {
                    attributes: {
                        "email": "email",
                        "street-number"	: "required|string|max:40",
                        "street-name"	: "required|string|max:255",
                        "street-type"	: "required|string|max:50",
                        "suburb": "required|string|max:80",
                        "state"	: "required|in:required|in:Australian Capital Territory,New South Wales,Northern Territory,Queensland,South Australia,Tasmania,Victoria,Western Australia,Outside Australia",
                        "postcode": "required|string|max:20|exists:postcodes,value",
                        "address": "required"
                    }
                },
                relationship: {
                    'Accountant': ['Accountant'],
                    'Business': ['Trade', 'Work Colleague'],
                    'Landlord': ['Landlord - RE Agent', 'Landlord - Private'],
                    'Personal': ['Friend', 'Family Member']
                }
            }
        }
    }
</script>


<style lang="scss" scoped>

    .controls {
            .v-btn {
                margin: 0 5px;
                padding: 0 30px;
            }
    }

    .v-card__title {

        .v-subheader  {
            display: block;
            line-height: 1.4;
            height: auto;
        }
    }

    .flex-box {
        padding-top: 100%;
        height: 0;
        position: relative;

        & > span {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }

    @media (max-width: 960px) {

        .controls {
            justify-content: center;

            .spacer {
                display: none;
            }
        }

        .controls {

            .v-btn {
                margin: 0 5px 10px;
                padding: 0 12px;

            }

        }

    }

</style>
