<template>
    <div>
        <v-dialog
            v-model="dialog"
            persistent
            width="500">
            
            <template v-slot:activator="{ on, attrs }">

                <div style="position: relative">
                    <v-btn
                        large 
                        :class="getButtonColor"
                        v-bind="attrs"
                        @click="openPrivacyModal"
                        :disabled="disablePrivacyButton"
                        depressed>
                        {{ privacyButtonLabel }}
                    </v-btn>

                    <v-tooltip
                        v-if="privacyButtonTooltip"
                        bottom
                        max-width="300">
                        <template v-slot:activator="{ on, attrs }">
                            <v-icon
                                class="warning--text"
                                style="cursor: pointer; position: absolute; left: -8px;"
                                size="20"
                                v-on="on">
                                mdi-information
                            </v-icon>
                        </template>
                        <span>{{privacyButtonTooltip}}</span>
                    </v-tooltip>
                </div>

            </template>

            <v-card flat :disabled="saving">
                <v-progress-linear color="secondary" indeterminate stream absolute top v-if="saving"></v-progress-linear>

                <v-card-text class="pa-10">

                    <div class="text-h4 font-weight-bold mb-4">Send Privacy</div>

                    <ValidationObserver ref="observer" tag="form">
                        <div class="flex-form">
                            <div class="flex-field">
                                <ValidationProvider 
                                    v-slot="{ errors }" rules="required" name="First Name" vid="first_name">
                                    <v-text-field
                                        v-model="fieldFirstName"
                                        @change="handleFieldChange"
                                        :error-messages="errors"
                                        label="First Name"></v-text-field>
                                </ValidationProvider>
                            </div>

                            <div class="flex-field">
                                <ValidationProvider 
                                    v-slot="{ errors }"  rules="required" name="Last Name" vid="last_name">
                                    <v-text-field
                                        v-model="fieldLastName"
                                        @change="handleFieldChange"
                                        :error-messages="errors"
                                        label="Last Name"></v-text-field>
                                </ValidationProvider>
                            </div>

                            <div class="flex-field">
                                <ValidationProvider 
                                    v-slot="{ errors }" name="Email" rules="required|email" vid="email">
                                    <v-text-field
                                        v-model="fieldEmail"
                                        @change="handleFieldChange"
                                        :error-messages="errors"
                                        label="Email"></v-text-field>
                                </ValidationProvider>
                            </div>

                            <div class="flex-field">
                                <ValidationProvider 
                                    v-slot="{ errors }"  
                                    rules="required|is_mobile_number|has:04,+61"  
                                     name="Phone number" vid="phone-number">
                                    <v-text-field
                                        v-model="fieldPhone"
                                        @change="handleFieldChange"
                                        :error-messages="errors"
                                        label="Phone number"></v-text-field>
                                </ValidationProvider>
                            </div>

                        </div>
                    </ValidationObserver>

                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions
                    class="pa-6 px-md-10 py-6 flex-wrap controls">                
                    <v-btn
                        outlined
                        color="light_gray_2"
                        large
                        style="min-width:140px;"
                        class="flex-back-button"
                        @click="dialog = false">
                        Back
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="secondary"
                        depressed
                        large
                        style="min-width:140px;"
                        @click="handleSave">
                        Send
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <ConfirmModal 
            v-if="confirmDialog"
            async
            color="warning"
            title="Send Privacy"
            confirmText="Yes, Send Privacy"
            :value="confirmDialog"
            :standalone="false"
            :processing="saving"
            :message="`Are you sure you wish to send privacy to this person?`"
            @close="handleClose"
            @confirm="handleConfirm" />
    </div>
</template>

<script>

    import { mapActions, mapGetters, mapState, mapMutations } from 'vuex'
    import formHelpers from '~/components/shared/form-helpers.js'
    import veeValidate from '~/components/shared/validation'

    import ConfirmModal from '~/components/base/ConfirmModal2'
    import moment from 'moment'

    export default {
        mixins: [formHelpers, veeValidate ],
        components: {
            ConfirmModal
        },
        props: {
 
        },
        data() {
            return {
                saving: false,
                dialog: false,
                confirmDialog: false,
            }
        },
        async mounted() {

        },
        computed: {
            ...mapState({
                appGuid: state => state.flows.appGuid,
            }),
            ...mapState("privacy-disclosure", ["activeApplicant", "privacyDisclosure", "privacyButtonLabel", "disablePrivacyButton", "privacyButtonTooltip"]),
            ...mapGetters('slug', [
                'getGroupSlug'
            ]),

            fieldFirstName: {
                get() {
                    return this.activeApplicant?.attributes?.['first-name']
                },
                set(value) {
                    this.setActiveApplicantAttributes({key: 'first-name', value})
                }
            },
            fieldLastName: {
                get() {
                    return this.activeApplicant?.attributes?.['last-name']
                },
                set(value) {
                    this.setActiveApplicantAttributes({key: 'last-name', value})
                }
            },
            fieldEmail: {
                get() {
                    return this.activeApplicant?.attributes?.['email']
                },
                set(value) {
                    this.setActiveApplicantAttributes({key: 'email', value})
                }
            },
            fieldPhone: {
                get() {
                    return this.activeApplicant?.attributes?.['mobile-phone']
                },
                set(value) {
                    this.setActiveApplicantAttributes({key: 'mobile-phone', value})
                }
            },

            getActiveApplicantId(){
                return this.activeApplicant?.id
            },
            hasDisclosure(){
                return this.privacyDisclosure != null
            },
            isPrivacySigned(){
                if(this.privacyDisclosure != null) {
                    return this.privacyDisclosure.signed
                } 

                return  false
            },
            isPrivacySent(){
                if(this.privacyButtonLabel == 'Privacy Sent') {
                    return true
                } 

                return  false
            },
            isBusinessEntity(){
                return this.activeApplicant && this.activeApplicant.type == 'business'
            },
            getButtonColor(){
                if(this.isPrivacySigned) {
                    return 'success'
                }
                return this.hasDisclosure ? 'warning':'secondary'
            }
        },

        methods: {
            ...mapActions('resource-actions', [
                'inviteApplicantToEdit',
                'getApplication',
                'updatePerson',
                'submitPrivacyDisclosures'
            ]),
            ...mapMutations('privacy-disclosure', [
                'setPrivacyDisclosure',
                'setPrivacyButtonLabel',
                'setActiveApplicantAttributes',
                'setPrivacyUpdater',
            ]),
            ...mapMutations('utils', [
                'forceCloseQuickMessage',
                'toggleQuickMessage',
            ]),
            async handleSave(){

                const valid = await this.$refs.observer.validate()

                if(valid) {
                    this.confirmDialog = true
                }

            },
            async save() {

                this.saving = true

                const personId = this.activeApplicant?.id

                await this.updatePerson({
                    id: personId,
                    type: 'people',
                    attributes: {
                        'first-name': this.fieldFirstName,
                        'last-name': this.fieldLastName,
                        'email': this.fieldEmail,
                        'mobile-phone': this.fieldPhone,
                    }
                })

                let res = await this.submitPrivacyDisclosures({appGuid:this.appGuid, personId, payload:{
                    first_name: this.fieldFirstName,
                    last_name: this.fieldLastName,
                    email: this.fieldEmail,
                    phone: this.fieldPhone,
                }})

                if(res?.status == 201 || res?.status == 200) {

                    this.setPrivacyDisclosure(res.data.data)
                    this.setPrivacyButtonLabel('Privacy Sent')

                } else {
                    
                    if(res.data?.message && res.data?.message.includes("There is no privacy disclosure")) {
                        this.forceCloseQuickMessage()

                        this.setPrivacyDisclosure({
                            status : "Current",
                            signed: false
                        })

                        this.setPrivacyButtonLabel('Privacy Sent')
                    }
                    
                    if(res?.status == 500) {
                        this.toggleQuickMessage({text: "Please contact your state manager", color: 'error'})
                    }
                }

                this.dialog = false
                this.saving = false
            },
            async handleConfirm() {
                this.saving = true
                await this.save()
            },
            openPrivacyModal(){
                if(this.isPrivacySent) return;
                this.dialog = true
            },
            handleClose() {
                this.confirmDialog = false
            },
            handleFieldChange(){
                this.setPrivacyUpdater()
            },
        },
        watch: {

        },
    }
</script>

<style lang="scss" scoped>
</style>