<template>
    <v-card class="mx-auto" tile flat>
        <v-card-text class="pa-4 pt-0 pa-md-10 pt-md-0">
            <ValidationObserver ref="observer" tag="form">
                    <div class="flex-form">
                        <div class="flex-field">
                            <ValidationProvider
                                v-slot="{ errors }"
                                :name="labels[`requested-broker-fee`]"
                                :rules="getRules(`requested-broker-fee`)"
                                :vid="`requested-broker-fee`">

                                <NodifiMoney
                                    v-model="resources.loan.attributes[`requested-broker-fee`]"
                                    :label="labels[`requested-broker-fee`]"
                                    :errors="errors" />

                            </ValidationProvider>
                        </div>
                        <div class="flex-field">
                            <ValidationProvider
                                v-slot="{ errors }"
                                :name="labels[`requested-term-months`]"
                                :rules="getRules(`requested-term-months`)"
                                :vid="`requested-term-months`">

                                <v-select
                                    v-model="resources.loan.attributes[`requested-term-months`]"
                                    :items="getOptions(`requested-term-months`)"
                                    :error-messages="errors"
                                    color="secondary"
                                    :label="labels[`requested-term-months`]"
                                ></v-select>

                            </ValidationProvider>
                        </div>
                        <div class="flex-field">
                            <ValidationProvider
                                v-slot="{ errors }"
                                :name="labels[`customer-discount-rate`]"
                                :rules="getRules(`customer-discount-rate`)"
                                :vid="`customer-discount-rate`">

                                <v-text-field
                                    v-model="resources.loan.attributes[`customer-discount-rate`]"
                                    :label="labels[`customer-discount-rate`]"
                                    :error-messages="errors"
                                    color="secondary"
                                ></v-text-field> 

                            </ValidationProvider>
                        </div>
                        <div class="flex-field">
                            <ValidationProvider
                                v-slot="{ errors }"
                                :name="labels[`bank-account-name`]"
                                :rules="getRules(`bank-account-name`)"
                                :vid="`bank-account-name`">

                                <v-text-field
                                    v-model="resources.loan.attributes[`bank-account-name`]"
                                    :label="labels[`bank-account-name`]"
                                    :error-messages="errors"
                                    color="secondary"
                                ></v-text-field> 

                            </ValidationProvider>
                        </div>
                    </div>

                    <div class="flex-form">
                        <div class="flex-field">
                            <ValidationProvider
                                v-slot="{ errors }"
                                :name="labels[`bank-bsb-number`]"
                                :rules="getRules(`bank-bsb-number`)"
                                :vid="`bank-bsb-number`">

                                <v-text-field
                                    v-model="resources.loan.attributes[`bank-bsb-number`]"
                                    :label="labels[`bank-bsb-number`]"
                                    :error-messages="errors"
                                    color="secondary"
                                ></v-text-field> 

                            </ValidationProvider>
                        </div>
                        <div class="flex-field">
                            <ValidationProvider
                                v-slot="{ errors }"
                                :name="labels[`bank-account-number`]"
                                :rules="getRules(`bank-account-number`)"
                                :vid="`bank-account-number`">

                                <v-text-field
                                    v-model="resources.loan.attributes[`bank-account-number`]"
                                    :label="labels[`bank-account-number`]"
                                    :error-messages="errors"
                                    color="secondary"
                                ></v-text-field> 

                            </ValidationProvider>
                        </div>
                        <div class="flex-field"></div>
                        <div class="flex-field"></div>
                    </div>

            </ValidationObserver>

            <!-- <ValidationObserver ref="observer2" tag="form">

                <template v-for="(term, index) in terms">
                    <ValidationProvider
                        v-slot="{ errors }"
                        :name="term.attributes.content"
                        rules="required"
                        :vid="term.attributes.content">    

                        <v-checkbox
                            v-model="checkboxes"
                            :value="term.attributes.content"
                            color="secondary"
                        >
                            <template v-slot:label>
                                <div v-html="term.attributes.content"></div>
                            </template>
                        </v-checkbox>

                </ValidationProvider>
                </template>


            </ValidationObserver>

            <v-alert dense outlined type="error" v-if="errors.length > 0">
                <div v-for="msg in errors" v-html="msg"></div>
            </v-alert> -->

        </v-card-text>
    </v-card>
</template>

<script>
import { mapActions, mapState, mapMutations } from 'vuex'
import helpers from '~/plugins/helpers'
import subscriptions from '~/components/shared/subscriptions'
import lenderFields from '~/components/v1/submission/Customisations1/__lenders'
import ButtonGroup1 from '~/components/base/ButtonGroup1'
import NodifiMoney from '~/components/base/NodifiMoney'

export default {
    name: 'LenderWisrCustomizations1',
    components: { ButtonGroup1 , NodifiMoney},
    mixins: [subscriptions, helpers],
    data() {
        return {
            resources: {
                loan: {
                    type: 'loans',
                    id: null,
                    attributes: {
                        "requested-broker-fee": "",
                        "customer-discount-rate": "",
                        "bank-account-name": "",
                        "bank-bsb-number": "",
                        "bank-account-number": "",
                        "requested-term-months": ""
                    }
                }

            },
            options: {
                'requested-term-months': [12,24,36,48,60,72,84],
            },
            labels: {
                "requested-broker-fee": "Origination Fee",
                "customer-discount-rate": "Rate Discount",
                "bank-account-name": "Account Name",
                "bank-bsb-number": "BSB No",
                "bank-account-number": "Account No",
                "requested-term-months": "Loan Term"
            },
            validation_rules: {
                type: "loans",
                attributes: {
                    "requested-term-months": "required|in:12,24,36,48,60,72,84",
                    "customer-discount-rate": "required|between:0,99999999999",
                    "requested-broker-fee": "required|between:0,99999999999",
                    "bank-account-name": "is_bank_account_name",
                    "bank-bsb-number": "",
                    "bank-account-number": ""
                }
            },
            terms: [],
            people: [],
            checkboxes: [],
            errors: [],

        }
    },
    computed: {
        ...mapState({
            appId: state => state.flows.appId,
            loanData: state => state.flows.loanData,
            termGroup: state => state.flows.termGroup,
            commitments: state => state.flows.commitments,
        }),
        getLoanId(){
            return this.appData.relationships.loan.data.id
        },
        ...mapState('auth-actions', [
                'user'
        ]),
        isCommitmentExists(){
            let exists = false

            if(this.commitments && this.terms.length > 0){

                return true

            }

            return exists
        },
    },
    methods: {
        ...mapActions('resource-actions', [
            'getLoan',
            'getTerms',
            'updateLoan',
            'getPeople',
            'getCommitments',
            'createCommitments',
        ]),
        ...mapMutations('flows', [
            'setCommitments'
        ]),
        async pull() {

            let promise1 =  this.getTerms(this.termGroup)
            let promise2 = this.getPeople()
            let promise3 = this.getLoan(this.getLoanId)

            const res = await Promise.all([
                promise1, 
                promise2,
                promise3
            ]) 

            promise1 = res[0]
            promise2 = res[1]
            promise3 = res[2]

            // console.log(res);

            if(promise1.status == 200) {

                // Only get the pepper-broker-declaration-001 term
                if(promise1.data.data.length){
                    promise1.data.data.forEach( term => {
                        if(term.attributes["slug"] == "pepper-broker-declaration-001") {
                            this.terms = promise1.data.data = [term]
                        }
                    })
                }


                
            }

            if(promise2.status == 200) {
                this.people = promise2.data.data
            }

            if(promise2.status == 200) {
                this.resources.loan = this.resourceFieldPicker(this.resources.loan, promise3.data.data)

                // Re-add Temp Fields for clarification EB-57
                this.resources.loan.attributes = {
                    ...this.resources.loan.attributes,
                    "submission-required": "Draft",
                    "financing-insurance": "No"
                }
            }

            // Check checkboxes if commitment exists

            if(this.isCommitmentExists) {

                let termsArray = []

                this.terms.forEach(term => {
                    termsArray.push(term.attributes.content)
                });

                this.checkboxes = termsArray

            }

        },

        async push() {

            const observer = this.$refs.observer
            const {valid, status} = await this.validateFormData()
            let loanRes = null

            if(valid) {
                
                const {loan} = this.resources
                    
                // Exclude resources.relationships and other stuff

                let payload = {
                    type: loan.type,
                    id: this.getLoanId,
                    attributes: this.excludeInvalids(loan.attributes, observer.fields)
                }

                loanRes = await this.updateLoan(payload)

            }

            const data = await this.appDataHelper([loanRes])


            // Validate Checkboxes

            this.checkCompleted()
    
            let allValid = this.errors.length == 0 && valid

            let allStatus = allValid ?  'valid' : 'dirty'

            // if(allValid && !this.isCommitmentExists) {

            //     let userId = this.appData.relationships.owner?.data?.id;

            //     if(this.appData.attributes.target == 'Assister') {
            //         userId = this.user.id;
            //     }

            //     let termsData = this.terms.map(term => {
            //         return {
            //             id: term.id,
            //             type: 'terms'
            //         }
            //     });

            //     let peopleData = this.people.map(person => {
            //         return {
            //             id: person.id,
            //             type: 'people'
            //         }
            //     });

            //     let payload = {  
            //         "type": "commitments",
            //         "attributes": {},
            //         "relationships": {
            //             "application": {
            //                 "data": {
            //                     "type":"applications",
            //                     "id": this.appId
            //                 },

            //             },
            //             "committed-users": {
            //                 "data": [
            //                     {
            //                         "type": "users",
            //                         "id": userId
            //                     }
            //                 ]
            //             },
            //             "committed-people": {
            //                 "data": peopleData // all people in  this app
            //             },
            //             "terms" : {
            //                 "data": termsData // all the terms
            //             }
            //         }
            //     }

            //     let res = await this.createCommitments(payload)

            //     this.setCommitments(res.data.data)

            //     return {valid: allValid, status: allStatus, data}

            // } else {

            //     return {valid: allValid, status: allStatus, data}
            // }

            return {valid: allValid, status: allStatus, data}

        },
        checkCompleted(){
            this.errors = []

            if(this.checkboxes.length != this.terms.length) {
                this.errors.push('Please accept all terms to proceed')
            } 
        },
    },
    watch: {
        checkboxes(val) {
            if(val.length == this.terms.length) {
                this.errors = []
            }
        }
    }
}
</script>

<style>
</style>

