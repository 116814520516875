export default {
    incomes: {
        "income-situation": "Income Situation",
        "income-period": "Income Period",
        "benefit-amount": "Income Amount",
        "no-income-reason": "No Income Reason",
        "additional-notes": "Additional Notes",
        "income-additional-comments": "Additional Comments",
        "other-benefit-description": "Other Benefit Description",
        "start-date": "Start Date",
        "end-date": "End Date",
        "investment-income": "Income Amount",
        "net-income": "Income Amount",
        "net-monthly-amount": "Net Monthly Amount",
        "job-title": "Job Title",
        "employer-name": "Employer Name",
        "employment-contact-person": "Employment Contact Person",
        "abn": "ABN (manual)",
        "employment-contact-phone": "Employment Contact Number",
        "contact-person-direct-mobile": "Contact Person Direct Mobile",
        "employment-job-status": "Employment Job Status",
        "employment-on-probation": "Employment On Probation",
        "estimated-term-in-months": "FE Employment Duration (months)",
        "gross-annual-income": "Gross Annual Income",
        "net-standard-pay": "Net Standard Pay",
        "overtime-pay-monthly-net": "Overtime Pay (monthly net)",
        "bonus-monthly-net": "Bonus (monthly net)",
        "commission-monthly-net": "Commission (monthly net)",
        "work-allowance-net-monthly": "Work Allowance (monthly net)",
        "business-name": "Business Name",
        "self-employed-date-registered-abn": "Date Registered ABN",
        "self-employed-gst-registered": "GST Registered",
        "nature-of-business": "Nature of Business",
        "self-employed-date-registered-gst": "Date Registered GST",
        "income-verification-method": "Income Verification Method",
        "revenue": "Revenue",
        "reporting-period": "Reporting Period",
        "depreciation": "Depreciation",
        "primary-income-split": "Primary Income Split",
        "profit-before-tax-annual": "Profit Before Tax (Annual)",
        "__rental-address": "Investment Property Address",
        "__employer-address": "Employer Address",
        "__business-address": "Business Address"
    },
    assets: {
        "asset-description": "Asset Description",
        "asset-value": "Asset value",
        "bank-account-name": "Bank Account Name",
        "bank-account-number": "Bank Account Number",
        "bsb-number": "BSB Number",
        "being-refinanced": "Being Refinanced",
        "being-sold-traded": "Being Sold/Traded",
        "covered-by-insurance": "Covered by Insurance",
        "description-other": "Description (Other)",
        "financial-institution": "Financial Institution",
        "make": "Make",
        "model": "Model",
        "other-institution": "Other Institution",
        "year-of-manufacture": "Year of Manufacture",
        "__full-address": "Address"
    },
    liabilities: {
        "amount-financed": "Amount Financed",
        "amount-owing": "Amount Owing",
        "credit-limit": "Credit Limit",
        "description-other": "Description (Other)",
        "end-date": "End Date",
        "financial-institution": "Financial Institution",
        "interest-rate": "Interest Rate",
        "liability-description": "Liability Description",
        "loan-account-number": "Loan Account Number",
        "mortgage-structure": "Mortgage Structure",
        "other-institution": "Other Institution",
        "to-be-paid-out": "To Be Paid Out",
    },
    expenses: {
        "expense-group": "Expense Group",
        "payment-amount": "Payment Amount",
        "payment-frequency": "Payment Frequency",
        "financial-institution": "Financial Institution",
        "other-institution": "Other Institution",
        "to-be-paid-out": "To Be Paid Out",
        "living-food-and-clothing": "Food & Clothing",
        "living-transport": "Transport",
        "living-utilities-and-rates": "Utilities & Rates",
        "living-lifestyle": "Lifestyle",
        "living-tv-and-communications": "TV & Communications",
        "living-child-care-fees": "Child Care Fees",
        "living-family-maintenance": "Family Maintenance",
        "living-insurance": "Insurance",
        "living-education": "Education",
        "living-other-living-expenses": "Other Living Expense",
        "__sum-living-expenses": "Sum of Living Expenses",
        "__rental-address": "Rental Address",
    },
    prefixIcon: {
        "benefit-amount": "$",
        "net-income": "$",
        "investment-income": "$",
        "net-monthly-amount": "$",
        "gross-annual-income": "$",
        "net-standard-pay": "$",
        "overtime-pay-monthly-net": "$",
        "bonus-monthly-net": "$",
        "commission-monthly-net": "$",
        "work-allowance-net-monthly": "$",
        "revenue": "$",
        "depreciation": "$",
        "profit-before-tax-annual": "$",
    }
}