<!--  

   Previous version of OwnerAssignmentModal with async search

-->
<template>
    <v-dialog
        v-model="dialog"
        persistent
        width="550">

        <template v-slot:activator="{on}">
            <slot name="activator" ></slot>
        </template>
        <v-card flat :disabled="processing || ownAssignmentModalLoading">
            <v-progress-linear :color="color" indeterminate stream absolute top v-if="processing || ownAssignmentModalLoading"></v-progress-linear>
            <template v-if="canSelect && choosed == 0 || choosed == 2">
                
                <v-card-title class="px-8 pt-8">
                    <div class="v-card__title sm pa-0">
                        {{ title }}
                    </div>
                </v-card-title>

                <v-card-text class="px-8 pt-2 body-1" v-html="message"></v-card-text>
            
                <v-card-text class="px-8 ">
                    <v-btn data-btn="app-owner-me" v-if="canSelectAppOwner && choosed == 0 " depressed block large color="secondary" @click="selectSelf(authUser)">Me</v-btn>
                    <div v-if="canSelectAppOwner && choosed == 0" class="text-center font-weight-bold pt-4">or</div>
                    <div v-if="choosed == 0 || choosed == 2" class="font-weight-bold body-1">Select User</div>
                    <v-autocomplete
                        v-if="choosed == 0 || choosed == 2"
                        v-model="searchKey"
                        @keydown="debounceCall"
                        :items="filteredSearchKeys"
                        :search-input.sync="search"
                        item-text="name"
                        item-value="id"
                        placeholder="Start typing to search"
                        :hide-no-data="true"
                        @change="selectedUser(searchKey)"
                        return-object
                        single-line
                        auto-select-first
                        no-filter
                        class="pa-0 font-weight-regular"
                        :hide-selected="hasSearched ? false : true"
                        data-field="app-owner-other"
                        >

                        <template v-slot:item="{ item }">
                        <v-list-item-content>
                        <v-list-item-title>{{ item.name }}</v-list-item-title>
                        <v-list-item-subtitle>{{ item.email }}</v-list-item-subtitle>
                        </v-list-item-content>                                  
                        </template>

                    </v-autocomplete>
                </v-card-text>
                <v-card-text class="px-8  body-1" v-if="groupList.length > 0 && choosed > 0">
                    <p class="font-weight-bold">Select Group</p>
                    <v-autocomplete  placeholder="Select Group" :filter="filterGroups"  :item-disabled="disableItems"   :item-text="item_selected != ''? 'attributes.name' : ''" v-model="groupSelected"  return-object :items="groupList" @change="selectedGroup(groupSelected)"  item-value="attributes.id" class="pt-0" :menu-props="{ contentClass: 'flex_page-GroupSelect-list' }">

                        <template  v-slot:item="{item}">
                            {{item.attributes.name}}
                        </template>

                        <template v-slot:item="{item}" >
                            <div style="width: 100%" >
                                <div :style="item.attributes['can-own-applications'] == 0 ? 'opacity:0.5': ''">
                                <v-list-item-content class="px-6 ">
                                    <v-list-item-title class=" font-weight-bold">
                                {{item.attributes.name}}
                                    </v-list-item-title>
                                    <v-list-item-subtitle class="caption">
                                    {{item.attributes.slug}}
                                    </v-list-item-subtitle>
                                </v-list-item-content>
                                </div>
                                <v-divider></v-divider>
                            </div>
                        </template>
                    </v-autocomplete>
                </v-card-text>

                <v-card-actions class="px-8 pb-8">
                <v-btn
                        class="flex-back-button"                    
                        outlined
                        color="light_gray_2"
                        large
                        style="min-width:140px;"
                        @click="cancel ">
                        Back
                    </v-btn>
                </v-card-actions>
            </template>
            <template v-if="!canSelect || choosed == 1">
                <v-card-title class="px-8 pt-8">
                    <div class="v-card__title sm pa-0">
                        Select Application Group
                    </div>
                </v-card-title>
                <v-card-text class="px-8 pt-2 body-1" > 
                    <p class="body-1">Which group do you want to assign this application to?</p>
                    <v-autocomplete data-field="group-select" placeholder="Select Group" :filter="filterGroups"  v-model="groupSelected" :item-disabled="disableItems" :item-text="item_selected != ''? 'attributes.name' : ''"  return-object  :items="groupList"  @change="selectedGroup(groupSelected)"  item-value="attributes.id" class="pt-0" :menu-props="{ contentClass: 'flex_page-GroupSelect-list' }">

                        <template  v-slot:item="{item}">
                        {{item.attributes.name }}
                            
                        </template>

                        <template v-slot:item="{item}">
                            <div style="width: 100%">
                                <v-list-item-content :data-btn="item.attributes.slug" class="px-6 py-5 elevation-0" >
                                    <v-list-item-title class="font-weight-bold elevation-0" >
                                    {{item.attributes.name}}
                                    </v-list-item-title>
                                    <v-list-item-subtitle class="caption elevation-0">
                                    {{item.attributes.slug}}
                                    </v-list-item-subtitle>
                                </v-list-item-content>
                                <v-divider></v-divider>
                            </div>
                        </template>
                    </v-autocomplete>
                </v-card-text>
                            <v-card-actions class="px-8 pb-8">
                <v-btn
                        outlined
                        class="flex-back-button"
                        color="light_gray_2 "
                        large
                        style="min-width:140px;"
                        @click="cancel ">
                        Back
                    </v-btn>
                </v-card-actions>
            </template>
        </v-card>
    </v-dialog>
</template>

<script>   
    import { mapGetters, mapState, mapActions, mapMutations } from 'vuex'  
    import { isEmpty, debounce } from 'lodash'
    export default {
        name: "OwnerAssignModal",
        props: {
            title: {
                type: String,
                default: 'Confirm Action'
            },
            message: {
                type: String,
                default: 'Are you sure?'
            },
            dialogProp : {
                type: Boolean,
                default: false
            },
            showConfirm: {
                type: Boolean,
                default: true
            },
            confirmText: {
                type: String,
                default: 'Confirm'
            },
            showCancel: {
                type: Boolean,
                default: true
            },
            cancelText: {
                type: String,
                default: 'Cancel'
            },
            standalone: {
                type: Boolean,
                default: true
            },
            value: {
                type: Boolean,
                default: false
            },
            async: {
                type: Boolean,
                default: false
            },
            processing: {
                type: Boolean,
                default: false
            },
            color: {
                type: String,
                default: 'success'
            },
            tNf_backSteps: {
                type: Number,
                default: 0
            },
            scenario_backsteps: {
                type:Number,
                default : 0
            },
            isKYC: {
                type: Boolean,
                default: false
            },
            isStartApp: {
                type: Boolean,
                default: false
            }
        },
        computed: {
            ...mapState('auth-actions', [
                'user',
                'userSettings'
            ]),
            ...mapGetters('groups', [
                'groups',
            ]),
            ...mapGetters('quote-actions', ['getFilteredUsers']),
            filteredSearchKeys() {

                if ( this.hasSearched != '' ) {
                    this.filterResult = Object.assign([], this.getFilteredUsers)

                } 
                if(this.getFilteredUsers.length != 0 && this.filterResult.length != 0){
                    return this.filterResult //this.getFilteredUsers
                } else {
                    return []
                }
            },
            authUser(){
                return Array.isArray(this.user) ? this.user[0] : this.user
            }
        },
        methods: {
            ...mapActions('quote-actions', [
                'getUsers',
                'getUsersFromSearch',
                'getQuotes',
                'getUserQuotes'
            ]),
            ...mapActions('groups', [
                'fetchGroups',
                'getUserGroups'
            ]),
            ...mapMutations('utils', [
                'toggleQuickMessage'
            ]), 
            ...mapActions('auth-actions', [
                'getUserSetting'
            ]),
            ...mapMutations('auth-actions', ['setAppOwnerSettings']),
            cancel () {
                this.$emit('cancel',false)
                this.searchKey = ''
                this.choosed = 0
            },
            selectSelf(data) {
              this.searchKey =  ''
              this.selectedUser(data)
              this.choosed = 1
            },
            async selectedUser (selected) {
                try {
                    if(!selected) {
                        return
                    }

                    let commonGroups = []
                    this.groupList = []
                    this.ownAssignmentModalLoading = true

                    if(this.isStartApp && selected?.id) {

                        let appOwnerSettings = await this.getUserSetting(selected.id)

                        if(appOwnerSettings.status == 200) {
                            this.setAppOwnerSettings(appOwnerSettings?.data?.data?.attributes)
                        }

                    }

                    const findUser = await this.$api.get(`users/${selected.id}`)
                    const userSelectedGroups = findUser.data?.data?.relationships?.groups?.data

                    if(userSelectedGroups && userSelectedGroups.length === 0) {
                        await this.fetchGroups()

                    }  else if(this.authUser.relationships.groups?.data.length === 0 ){

                        await this.getUserGroups({ id: selected.id, include: 'groups' })

                    } else {
                        
                        await this.getUserGroups({ id: this.authUser?.id, include: 'groups' })
                    }

                    if(userSelectedGroups?.length > 0) {

                        userSelectedGroups.forEach(data => {
                            let found =  this.groups.find(el => el.id == data.id)

                            if(found) {
                                commonGroups.push(found)
                            }
                        })

                        if(commonGroups.length == 0) {
                            this.toggleQuickMessage({
                                text: "Can't assign app to user. No common groups.",
                                color: "error"
                            })
                        }

                        this.groupList = commonGroups
                    } else {
                        this.groupList = this.groups
                    }

                    this.$emit('selectedUser', selected.id)
                    this.ownAssignmentModalLoading = false

                } catch (err) {
                    console.log(err)
                    this.ownAssignmentModalLoading = false
                }
            },
            initUserSettings() {
                const { userSettings } = this

                if(userSettings['can-select-app-owner-others'] && userSettings['can-select-app-owner-others'] == 1 ){

                    if(userSettings['can-select-app-owner-own'] === 1) {
                        this.canSelectAppOwner = true
                    } else {
                        this.canSelectAppOwner = false
                    }

                    this.canSelect = true

                } else {

                    this.canSelect = false
                    this.selectedUser(this.authUser)

                }
            },
            selectedGroup(selected) {
                this.item_selected = selected.attributes.name
                this.groupSelected = selected.attributes.name
                this.$emit('submit', selected.id)
                this.clear()
            },
            disableItems (item) {
                return item.attributes?.['can-own-applications'] == 0  
            },
            clear() {
                this.hasSearched = false
                this.filterResult = []
                this.searchKey = ''
                this.item_selected = ''
                this.choosed = 0
            },
            filterGroups(item, queryText, itemTex){
                const textOne = item.attributes.name.toLowerCase()
                const searchText = queryText.toLowerCase()
                return textOne.indexOf(searchText) > -1 
            },
            debounceCall: debounce(async function (evt) {

                if (this.search) {
                    
                    await this.getUsersFromSearch({
                        filter: `[[username~${this.search},email~${this.search}];user-setting.can-select-app-owner-own=1;active=1]`,
                        page: {
                            size: 5
                        },
                        sort: '-username'
                    })
                    // filter[]=[username~${dev.user},email~${dev.user}]&sort=-username

                    this.hasSearched = true
                }

            }, 200),

        },
        watch: {
            'dialogProp': {
                async handler (val) {
                    this.dialog = val

                    if(val) {
                        await this.initUserSettings()
                    }

                    if(this.isKYC) {
                        this.canSelect = false
                        if(this.groups.length === 0) {
                            await this.fetchGroups()
                        }

                        this.groupList = this.groups
                    }
                }
            },
            'searchKey': {
                handler (val) {
                    if (val != '') {
                        this.choosed = 2
                    }
                   
                }
            },
            'tNf_backSteps': {
                handler (val) {
                   if(val == 2){
                       this.cancel()
                   }
                }
            },
            'scenario_backsteps': {
                handler(val) {
                    if(val == 1){
                        this.cancel()
                    }
                }
            }
        },
        data () {
            return {
                selected: '',
                allusers: [],
                searchKey: { id: null, name: '', email: '' },
                search: null,
                groupSelected:'',
                hasSearched: false,
                dialog: false,
                item_selected: '',
                choosed:0,
                searchInput: "",
                filterResult : [],
                groupList: [],
                canSelect: true,
                canSelectAppOwner: false,
                ownAssignmentModalLoading: false,
            }
        }
    }
</script>

<style lang="scss" scoped>
        .flex_page-GroupSelect-list.v-menu__content {
        overflow: hidden;

        border-radius: 8px !important;
        box-shadow: 0px 3px 10px 5px rgb(0 0 0 / 5%),
                    0px 3px 4px 0px rgb(0 0 0 / 3%),
                    0px 1px 8px 0px rgb(0 0 0 / 3%) !important;

        .v-list,
        .v-list-item.v-list-item--link.theme--light {
            position: relative;
        }
        .v-divider.theme--light {
            position: absolute;
            width: 100%;
            padding: 0 !important;
            margin-left: -16px !important;
        }
       .v-list-item:last-child hr {
           display: none;
       }
       .v-list-item__content {
           padding: 20px;
       }
    }
    
</style>