<template>
    <v-dialog
        v-model="dialog"
        width="900"
        persistent>
        
        <template v-slot:activator="{ on, attrs }">
            <v-btn 
                depressed large
                :disabled="disabled"
                :loading="syncLoading"
                class="secondary mx-2"
                v-bind="attrs"
                v-on="on">
                <div v-if="$vuetify.breakpoint.smAndUp">
                    Generate SOCA
                </div>
                <div v-else>
                    SOCA
                </div>
            </v-btn>
        </template>

        <v-card class="overflow-y-auto" flat>

            <v-progress-linear 
                v-if="pageLoader"
                color="secondary"
                indeterminate stream top 
                value="0">
            </v-progress-linear>

            <v-overlay
                absolute
                :opacity="0.1"
                v-if="pageLoader">
            </v-overlay>

            <v-card-title>
                <v-spacer />
                <v-btn icon @click="dialog = false" >
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-card-title>

            <v-card-text class="pa-0">
                <v-card width="600" tile flat class="rounded-lg mx-auto">
                    <v-card-title class="pb-10">
                        Generate BID Document
                    </v-card-title>
                    <v-card-text>

                        <template v-if="tmd == null || tmd == 'No'">
                            <label class="font-weight-bold">Does the client fit the <a href="https://nodifi.box.com/s/yxwbuombboifh3hc08tvx5lb6br3boc7" target="_blank">Target Market Determination</a> for this product?</label>
                            <ReferralPartner 
                                v-model="tmd"
                                label="">
                            </ReferralPartner>  
                        </template>
                            
                        <ReferralPartner 
                            v-if="tmd == 'Yes'"
                            v-model="referralPartner"
                            label="Will you be paying a referral partner for this deal?" />

                        <ValidationObserver ref="observer" tag="form">
                            <ValidationProvider 
                                v-if=" tmd === 'No'"
                                v-slot="{ errors }" 
                                name="Why have you recommended this product to the client"
                                rules="required" 
                                vid="tmd-picklist-value">
                                <v-select
                                    v-model="form['tmd-picklist-value']"
                                    :items="tmdPickList"
                                    item-text="name"
                                    item-value="value"
                                    label="Why have you recommended this product to the client"
                                    :error-messages="errors"
                                >

                                </v-select>
                            </ValidationProvider>
                            <ValidationProvider 
                                v-if="form['tmd-picklist-value'] == 'others' && tmd == 'No'"
                                v-slot="{ errors }"
                                name="Your answer"
                                rules="required" 
                                vid="tmd-picklist-value-others">
                                <v-textarea
                                    
                                    label="Please provide your answer"
                                    :error-messages="errors"
                                    v-model="form['tmd-picklist-value-others']"
                                ></v-textarea>
                            </ValidationProvider>

                            <ValidationProvider 
                                v-if="referralPartner === 'Yes' && tmd == 'Yes'"
                                v-slot="{ errors }" 
                                name="Referrer Name"
                                rules="required" 
                                vid="referral-partner-name">
                                <v-text-field                                
                                    v-model="form['referral-partner-name']"
                                    label="Referrer Name"
                                    :error-messages="errors">
                                </v-text-field>
                            </ValidationProvider>

                            <ValidationProvider 
                            v-if="referralPartner === 'Yes' && tmd == 'Yes' "
                                v-slot="{ errors }" 
                                name="Referral Fee Estimate"
                                rules="required" 
                                vid="referral-fee-estimate">
                                
                                <NodifiMoney
                                    v-model="form['referral-fee-estimate']"
                                    label="Referral Fee Estimate"
                                    :errors="errors" />

                            </ValidationProvider>

                        </ValidationObserver>
                        
                    </v-card-text>
                </v-card>
            </v-card-text>


            <v-card height="20" tile flat />

            <v-divider></v-divider>

            <v-card-actions class="pa-6 pa-md-8 flex-wrap controls">                                
                <v-btn
                    outlined
                    color="grey lighten-1"
                    large
                    style="min-width:140px;"
                    @click="back">
                    Back
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn
                    v-if="tmd == 'Yes'"
                    color="secondary"
                    depressed
                    large
                    :disabled="referralPartner ? false : true"
                    style="min-width:140px;"
                    @click="saveData">
                    Generate PDF
                </v-btn>
                <v-btn
                    v-if="tmd == 'No'"
                    color="secondary"
                    depressed
                    large
                    :disabled="  form['tmd-picklist-value'] == 'others'  && form['tmd-picklist-value-others'] == '' || form['tmd-picklist-value'] == ''   ? true : false"
                    style="min-width:140px;"
                    @click="next">
                    Next
                </v-btn>
            </v-card-actions>
        </v-card>

    </v-dialog>
</template>

<script>

    import { mapActions } from 'vuex'
    import ReferralPartner from '~/components/base/ButtonSelections'
    import formHelpers from '~/components/shared/form-helpers'
    import validation from '~/components/shared/validation'
    import helpers from '~/plugins/helpers'
    import shared from './__shared'

    export default {
        name: 'BidQuoteGenerateSocaDialog',

        mixins: [ helpers, validation, formHelpers, shared ],

        props: {
            bidResource: {
                type: Object,
                default: () => {}
            },
            disabled: {
                type: Boolean,
                default: false
            },
            syncLoading: {
                type: Boolean,
                default: false
            }
        },

        components: {
            ReferralPartner
        },

        methods: {
            ...mapActions('resource-actions', [
                'updateBid'
            ]),
            close() {
                this.dialog = false
            },
            back() {
                this.tmd = null
                this.form['tmd-picklist-value'] = ""
            },
            next() {
                this.tmd = 'Yes'
            },
            async saveData() {
                
                this.pageLoader = true
                const valid = await this.$refs.observer.validate()

                let obj = {
                    id: this.bidResource.id,
                    type: 'bids',
                    attributes: this.form
                }

                if ( valid ) {
                    let res = await this.updateBid(obj)
                    
                    if ( res.status === 200 )
                        this.$emit('saved-referrals', this.form)
                    this.dialog = false
                }

                this.pageLoader = false
                
            }
        },

        watch: {
            'referralPartner': {
                handler(val) {
                    if ( val === 'No' ) {
                        this.form['referral-partner-name'] = 'Not Applicable'
                        this.form['referral-fee-estimate'] = '0.00'
                    }
                    else {
                        this.form['referral-partner-name'] = this.bidResource?.attributes?.['referral-partner-name'] || ''
                        this.form['referral-fee-estimate'] = this.bidResource?.attributes?.['referral-fee-estimate'] || ''
                    }
                    console.log('referral', val)
                },
                deep: true
            },
            'tmd': {
                handler(val) {
                    if(val == 'Yes') {
                        this.referralPartner = 'Yes'
                    }
                }
            },
            'dialog': {
                handler(val) {
                    if ( val ) {

                        this.form['referral-partner-name'] = this.bidResource.attributes['referral-partner-name']
                        this.form['referral-fee-estimate'] = this.bidResource?.attributes?.['referral-fee-estimate']

                        if ( this.form['referral-partner-name'] && this.form['referral-fee-estimate'] ) {
                            this.referralPartner = 'Yes'
                        }
                    }
                },
                deep: true
            }
        },

        data () {
            return {
                dialog: false,
                referralPartner: null,
                tmd: null,
                form: {
                    'referral-partner-name': '',
                    'referral-fee-estimate': '',
                    'tmd-picklist-value': '',
                    'tmd-picklist-value-others': ''
                },
                tmdPickList: [
                    {name: 'The product is in the best interests of the client', value: 'The product is in the best interests of the client'},
                    {name: 'The client specifically requested this product', value: 'The client specifically requested this product'},
                    {name: 'Other (please provide details)', value:"others"}
                ],
                pageLoader: false
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>