<template>

    <v-card 
        :disabled="isDisabled || blockPermissionsReadOnly" 
        class="mx-auto" 
        tile flat>

        <v-card-text 
            class="pa-4 pt-0 pa-md-10 pt-md-0">
            
            <v-container pa-0 fluid>
                <v-row>
                    <v-col cols="12">

                        <ValidationObserver 
                            ref="observer" 
                            tag="form">                            
                            <div class="flex-form">

                                <template 
                                    v-for="(field, key) in resources.loan.attributes">
                                    <div 
                                        :key="key"
                                        v-if="!(kindType === 'Business' && key === 'personal-loan-reason')"
                                        class="flex-field">
                                        <ValidationProvider
                                            v-slot="{ errors }"
                                            :name="labels[key]"
                                            :rules="getRules(key)"
                                            :vid="key">

                                            <v-select
                                                v-if="isFieldType('picklist', key)"
                                                v-model="resources.loan.attributes[key]"
                                                :items="options[key]"
                                                item-text="key"
                                                item-value="value"
                                                :error-messages="errors"
                                                color="secondary"
                                                :suffix="suffix[key]"
                                                :label="labels[key]"></v-select>

                                            <v-text-field
                                                v-else
                                                v-model="resources.loan.attributes[key]"
                                                :type="'text'"
                                                :label="labels[key]"
                                                :error-messages="errors"
                                                color="secondary"></v-text-field> 

                                        </ValidationProvider>
                                    </div>
                                </template>
                            </div>
                        </ValidationObserver>
                    </v-col>
                </v-row>
            </v-container>
            
        </v-card-text>
        
    </v-card>

</template>

<script>

    import subscriptions from '~/components/shared/subscriptions'
    import { mapActions } from 'vuex'

    export default {
        name: 'LoanEditor2',

        mixins: [ subscriptions ],

        computed: {
            form() {
                return this.resources.loan.attributes
            },
            getLoanId(){
                return this.appData?.relationships?.loan?.data ? this.appData.relationships.loan.data.id : ''
            },
            loanKinds() {
                return this.kinds.find( loan => {
                    return loan.name === 'Loan'
                })
            },
            activeKind() {
                return this.loanKinds.children.find( k => {
                    return k.id === this.resources.loan?.relationships?.kind?.data?.id
                })
            },
            kindType() {
                if ( ['Company', 'Partnership', 'Trust', 'Sole Trader'].indexOf(this.activeKind?.name) >= 0 ) {
                    return 'Business'
                }
                else if (['Individual'].indexOf(this.activeKind?.name) >= 0) {
                    return 'Personal'
                }
            },
            validComponentData(){
                
                const { data } = this.appData?.relationships?.loan

                if ( data == null ) {
                    return false;
                }

                return true;
            }
        },

        mounted() {
            if(!this.validComponentData){
                this.showComponentWarning()
            }
        },

        methods: {
            ...mapActions('resource-actions', [
                'getApplicationLoan',
                'updateLoan',
                'createLoan',
                'getPurchases'
            ]),

            /**
             * Calling actions: getLoans 
             * from store which trigger an axios call,
             * pulling all data from an api response
             */
            async pull() {

                // Do nothing if minimum data required is not met.
                if(!this.validComponentData) return; 
                
                let loanRes = await this.getApplicationLoan(this.getLoanId)
                // let purchasesRes = await this.getPurchases()

                // this.resources.loan = loanRes.data.data.attributes
                this.resources.loan = this.resourceFieldPicker(this.resources.loan, loanRes.data.data)
                // this.resources.purchases = purchasesRes.data.data

            },

            async push() {

                const observer = this.$refs.observer

                const {loan} = this.resources
                    
                let payload = {
                    type: loan.type,
                    id: loan.id,
                    attributes: this.excludeInvalids(loan.attributes, observer.fields)
                }

                // Manually add hidden fields
                payload.attributes = {
                    ...payload.attributes,
                    "requested-payment-terms": 'In Arrears',
                    "requested-balloon": '0'
                }

                let loanRes = null;

                if (this.getLoanId) {

                    loanRes = this.updateLoan(payload)

                } 
                else {

                    payload.relationships = {
                        application: {
                            data: { type: "applications", id: this.appId }
                        }
                    }

                    loanRes = this.createLoan(payload)
                }

                const { valid, status } = await this.validateFormData()
                const data = await this.appDataHelper([loanRes])

                return { valid, status, data }

            }
        },

        data(){
            return {
                resources: {
                    loan: {
                        type: 'loans',
                        id: null,
                        attributes: {
                            "requested-required-loan-amount": '',
                            "personal-loan-reason": '',
                            "requested-term-months": '',
                            "requested-payment-frequency": ''
                        }
                    },
                    purchases: []
                },
                // LOAN resources
                labels: {
                    "requested-required-loan-amount": "Required Loan Amount",
                    "personal-loan-reason": "Personal Loan Reason",
                    "requested-term-months": "Loan Term",
                    "requested-payment-frequency": "Requested Payment Frequency"
                },
                validation_rules: {
                    type: "loans",
                    attributes: {
                        "requested-required-loan-amount": "required",
                        "personal-loan-reason": "required|in:Debt Consolidation,Education,Funeral Expenses,Furniture and Appliances,Home Improvements,Medical Expenses,Motor or Leisure Vehicle,Other,Solar Energy,Tax Debt,Travel,Vehicle Repairs,Wedding",
                        "requested-term-months": "required|in:12,24,36,48,60,72,84",
                        "requested-payment-frequency": "required|in:Monthly,Fortnightly,Weekly,Annual",                        
                    }
                },
                options: {
                    "requested-term-months": [
                        { key: "1", value: 12 },
                        { key: "2", value: 24 },
                        { key: "3", value: 36 },
                        { key: "4", value: 48 },
                        { key: "5", value: 60 },
                        { key: "6", value: 72 },
                        { key: "7", value: 84 }
                    ],
                    "requested-payment-terms": [ "In Arrears", "In Advance" ],
                    "requested-payment-frequency": [ "Weekly", "Fortnightly", "Monthly" ], // "Annual"
                    "sale-type": [ "Dealer", "Private Sale", "Refinance", "Sale & Buyback", "Pre-Approval", "Purchase", "Top up", "Variation" ],
                    "personal-loan-reason": [ "Debt Consolidation", "Education", "Funeral Expenses", "Furniture and Appliances", "Home Improvements", "Medical Expenses", "Motor or Leisure Vehicle", "Other", "Solar Energy", "Tax Debt", "Travel", "Vehicle Repairs", "Wedding"]
                },
                suffix: {
                    "requested-term-months": 'years',
                    "requested-payment-terms": ''
                }
            }
        }
    }

</script>

<style lang="scss" scoped>

    .flex-form {
        display: grid;
        grid-column-gap: 15px;
        grid-row-gap: 0;
        grid-template-columns:  repeat(auto-fit, minmax(260px, 1fr));

        .flex-field {
            align-self: start;
        }
    }

    .inline-icons {
        display: flex;
    }

</style>