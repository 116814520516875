<template>
    <v-navigation-drawer
        v-if="showRequirementSidebar"
        v-model="drawer"
        :mini-variant.sync="mini"
        :width="getDrawerAndToggleWidth + 'px'"
        clipped
        app
        right
        permanent
        floating
        hide-overlay
    >
    <div class="d-flex" style="height: 100%">

        <v-btn
            :color="getIndicatorColor"
            height="100%"
            class="pa-0"
            min-width="56"
            dark tile depressed :rounded="false"
            @click.stop="mini = !mini">
            <v-icon size="30">{{ getIndicatorIcon }}</v-icon>
        </v-btn>
        <div class="requirement-checklist">

            <div class="requirement-checklist-head" :style="[{'width': getDrawerWidth + 'px'}]">
                <div :style="[{'width': getColWidth + 'px'}]" class="d-flex flex-row align-center px-4">
                    <div>
                        <h3 class="mb-2">Information Checklist</h3>
                        <v-chip dark :color="getIndicatorColor">
                            <v-icon left>
                                {{ getIndicatorIcon }}
                            </v-icon>
                            {{ getStatusText }}
                        </v-chip>
                    </div>
                </div>
            </div>
            <v-divider></v-divider>
            <div class="requirement-checklist-sub-head" v-if="showSubHead" :style="[{'width': getDrawerWidth + 'px'}]">
                <template v-for="data in requirements">
                    <div :style="[{'width': getColWidth + 'px'}]" class="d-flex flex-row align-center px-4">
                        <div>
                            <v-chip dark color="secondary" v-if="data.entity" :title="getEntityFriendlyName(data.entity)">
                                <v-icon left>
                                    mdi-account-circle
                                </v-icon>
                                <span class="text-truncate" style="max-width: 240px;">
                                    {{ getEntityFriendlyName(data.entity) }}
                                </span>
                            </v-chip>
                        </div>
                    </div>
                </template>
            </div>
            <v-divider v-if="showSubHead"></v-divider>
            <div :class="['requirement-checklist-body', {'smaller': showSubHead}]" :style="[{'width': getDrawerWidth + 'px'}]">

                <template v-for="data in requirements">
                    <div :style="[{'width': getColWidth + 'px'}]">
                        <div class="column">
                            <template v-for="section, index in data.sections" >

                                    <h4 class="pt-4 px-4 d-flex align-center">
                                        <span>{{ section.title }}</span>
                                        <v-spacer></v-spacer>
                                        <v-icon color="success" v-if="isSectionCompleted(section.requirements)">mdi-check-circle</v-icon>
                                        <v-icon v-else :color="getAlertColor">mdi-alert-circle</v-icon>
                                    </h4>

                                    <v-list nav dense :key="data.id +'-'+ index" :width="getColWidth">

                                        <v-list-item v-if="section.subtitle">
                                            <v-list-item-content>
                                                <v-list-item-title >
                                                    <div>{{ section.subtitle }}</div>
                                                </v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>

                                        <v-list-item v-for="field, key in section.requirements" :key="key" v-if="field && field.id">
                                            <v-list-item-icon class="mr-4">
                                                <v-icon v-if="!field.required && !field.value" color="light_gray_2">mdi-minus-circle</v-icon>
                                                <v-icon v-else-if="field.valid" color="success">mdi-check-circle</v-icon>
                                                <v-icon v-else :color="getAlertColor">mdi-alert-circle</v-icon>
                                            </v-list-item-icon>
                                            <v-list-item-content>
                                                <v-list-item-title >
                                                    {{ field.name }}
                                                </v-list-item-title>
                                                <v-list-item-subtitle v-if="field['info-1']">
                                                    {{ field['info-1'] }}
                                                </v-list-item-subtitle>
                                                <v-list-item-subtitle v-if="field['info-2']">
                                                    {{ field['info-2'] }}
                                                </v-list-item-subtitle>
                                            </v-list-item-content>
                                        </v-list-item>
                                    </v-list>

                                    <v-divider v-if="data.sections.length > (index + 1)"></v-divider>

                            </template>    
                        </div>
                    </div>
                </template>    

            </div>
        </div>
    </div>
    </v-navigation-drawer>

</template>

<script>

    import { mapActions, mapState, mapGetters, mapMutations } from 'vuex'
    import resourceHelpers from '~/components/shared/resource-helpers'

    export default {
        mixins: [resourceHelpers],
        props: {
            entities: {
                type: [Array],
                default: () => [],
            }
        },
        data() {
            return {
                drawer: false,
                mini: true,

                colWidth: 340,
                toggleSize: 56,
            }
        },
        computed: {
            ...mapState({
                reqs: state => state['requirements-checklist'].requirements,
            }),
            /** Arranged reqs state, making sure primary applicant is on the first column  */
            requirements(){
                
                const primaryApplicantId = this.getCalculation?.['primary-applicant-id']

                return this.reqs.reduce((acc, item) => {
                    
                    if (item?.entity?.id == primaryApplicantId) {
                        acc.unshift(item);
                    } else {
                        acc.push(item);
                    }
                    return acc;
                }, []);

            },
            ...mapGetters('flows', [
                'getCalculation'
            ]),
            ...mapGetters('statuses', [
                'getActiveStepStatus'
            ]),
            allSectionsComplete(){
                let flag = true
                this.requirements.forEach(req => {
                    req.sections.forEach(sec => {
                        Object.keys(sec.requirements).forEach( f => {
                            let field = sec.requirements[f]
                            if(field.hasOwnProperty('valid') && !field.valid) {
                                flag = false
                            }
                        })
                    })
                })
                return flag
            },
            isTouched(){
                let flag = false
                this.requirements.forEach(req => {
                    req.sections.forEach(sec => {
                        Object.keys(sec.requirements).forEach( f => {
                            let field = sec.requirements[f]
                            if(field.hasOwnProperty('touched') && field.touched) {
                                flag = true
                            }
                        })
                    })
                })
                return flag
            },
            isPristine(){
                return this?.getActiveStepStatus?.attributes?.value == 'pristine' && !this.isTouched
            },
            getIndicatorColor(){

                const {isPristine, allSectionsComplete} = this

                if(isPristine) return  'light_gray_2'

                if(allSectionsComplete) return 'success'

                return 'warning'
            },
            getIndicatorIcon(){

                const {isPristine, allSectionsComplete} = this

                if(isPristine) return  'mdi-alert-circle'

                if(allSectionsComplete) return 'mdi-check-circle'

                return 'mdi-alert-circle'
            },
            getAlertColor(){
                return this.isPristine ? 'light_gray_2' : 'warning'
            },
            getStatusText(){
                const {isPristine, allSectionsComplete} = this

                if(isPristine) return  'Not Available'

                if(allSectionsComplete) return 'Completed'

                return 'Incomplete'
            },
            getColumnCount(){
                return this.requirements.length
            },
            showSubHead(){
                return this.requirements.length > 1
            },
            getDrawerAndToggleWidth(){
                return (this.getColumnCount * this.colWidth) + this.toggleSize 
            },
            getDrawerWidth(){
                return (this.getColumnCount * this.colWidth)
            },
            getColWidth(){
                return this.colWidth - (18 / this.getColumnCount) // minus the size of scrollbar
            },
            isCommercial() {
                let appType = this.getCalculation['application-type']
                return appType.includes('Commercial')
            },
            showRequirementSidebar(){
                return !this.isCommercial
            }
        },
        mounted(){
            
            // console.log('mounted', this.requirements)
            
        },
        methods: {
            isSectionCompleted(data) {
                return Object.keys(data).every(item => {
                    // ignore hidden fields ( no 'valid' property)
                    if(data[item].hasOwnProperty('valid')) {
                        return data[item].valid
                    } else {
                        return true
                    }

                })
            }
        }
    }
</script>

<style lang="scss" scoped>

    .requirement-checklist {
        width: 100%;
        height: 100%;
        overflow: hidden;
        overflow-x: auto;

        .requirement-checklist-head {
            display: flex;
            height: 126px;
        }
        .requirement-checklist-sub-head {
            display: flex;
            height: 64px;
            padding-right: 17px; // scrollbar

            & > div {
                border-right: 1px solid rgba(0, 0, 0, 0.12);
            }
        }

        .requirement-checklist-body {
            display: flex;
            height: calc(100% - 126px);
            overflow: auto;

            &.smaller {
                height: calc(100% - (126px + 64px));
            }

            .column {
                border-right: 1px solid rgba(0, 0, 0, 0.12);
                position: relative;
                min-height: 100%;

            }
        }
    }
</style>