<template>
    <v-dialog
        v-model="dialog"
        persistent
        scrollable
        max-width="1124px"
        >
        <template v-slot:activator="{ on, attrs }">
            <v-btn
                color="secondary"
                large
                depressed
                @click="handleAdd">
                Add New Reference
            </v-btn>
        </template>

        <v-card>
            <v-card-title class="headline white lighten-2">
                <v-spacer></v-spacer>
                <v-btn fab depressed text x-small @click="dialog=false">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-card-title>

            <v-card-text>

                <v-card tile flat class="rounded-lg mx-auto" width="800">

                    <v-card-title class="px-3 justify-center pb-2">
                        <span>New Reference</span>
                    </v-card-title>
                    <v-card-text class="text-center pt-0 pb-8">
                        <div class="text-subtitle-1">Please select one that applies</div>
                    </v-card-text>

                    <v-card-text>

                        <v-container class="light_gray_1 px-4 py-2 rounded-lg">
                            <v-row>
                                <v-col v-for="(refKind, key) in referenceSelections"
                                class="pa-2"
                                :key="key" cols="12" sm="6">

                                    <CreateNewReference
                                        action="add"
                                        :app-id="appId"
                                        @save-record="save"
                                        @selected-item="dialog = true"
                                        :form-address="addresses"
                                        :form-reference="references"
                                        :custom-buttons="true"
                                        :selected-kind="referenceKinds"
                                        :requiresLandLordReference="requiresLandLordReference"
                                        :button-label="refKind.name" />

                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card-text>

                </v-card>

            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions class="pa-4 pa-md-6 flex-wrap controls">

                <v-btn
                    @click="dialog = false"
                    depressed
                    class="flex-back-button"
                    :min-width="140" large outlined
                    color="light_gray_2"
                    text>Back</v-btn>

                <v-spacer></v-spacer>

            </v-card-actions>

        </v-card>
    </v-dialog>
</template>

<script>

    import { mapState, mapMutations } from 'vuex'

    import CreateNewReference from '~/components/v1/ReferenceEditor1/_FormReferenceEditor'

    export default {
        name: 'AddReference',

        components: {
            CreateNewReference
        },

        props: {
            supportData: {
                type: Object,
                default: () => {}
            },
            appId: {
                type: String,
                default: null
            },
            requiresLandLordReference: {
              type: Boolean,
              default: false
            }
        },

        async mounted() {
            this.dialog = await false
        },

        computed: {
            ...mapState('flows', ['loanType']),

            references() {
                return { relationships: this.supportData?.reference[0]?.relationships , type: 'references' }
            },
            addresses() {
                return { relationships: this.supportData?.address[0]?.relationships, type: 'addresses' }
            },
            referenceKinds() {
                return this?.$store.state.kinds.kinds.find( ref => {
                    return ref.name === 'Reference'
                })
            },
            referenceSelections() {
                if ( this.loanType === 'Commercial' ) {
                    return this.referenceKinds.children.filter( ref => {
                        return ref.name === 'Accountant' || ref.name === 'Business'
                    })
                }
                return this.referenceKinds.children
            }
        },

        methods: {
            ...mapMutations('dialogs', [
                'toggleTouched',
            ]),
            handleAdd(){

                this.dialog = true

                this.toggleTouched(true)

            },
            save(params) {
                this.dialog = false
                this.$emit('create-record', params)
            }
        },

        data() {
            return {
                dialog: true,
                links: [
                    { text: 'Personal' },
                    { text: 'Business' },
                    { text: 'Landlord' },
                    { text: 'Accountant' }
                ]
            }
        }
    }
</script>
