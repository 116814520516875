import { mapState, mapActions, mapGetters, mapMutations } from 'vuex'

import validation from '~/components/shared/validation' 
import formHelpers from '~/components/shared/form-helpers' 
import baseComponents from '~/components/base'
import {store, actions} from '~/components/v1/QuoteResult6/__store'


export default {
    mixins: [
        validation, 
        formHelpers
    ],
    components:{
        ...baseComponents
    },
    data(){
        return {
            validComponentData: true,
        }
    },
    computed:{
        ...mapGetters('themes', [
            'getActiveTheme'
        ]),
        ...mapState('flows', [
            'loanData'
        ]),
        vb(){
            return this.$vuetify.breakpoint
        },
        isUnsecured(){
            if(this.loanData.attributes['loan-type'].search('Asset') == -1 ){
                return true
            }
            return false
        },
        getRepaymentsTermField(){
            return store.repaymentsTermField
        },
        mappedRepaymentsTermField(){
            switch (store.repaymentsTermField) {
                case "Monthly":
                    return "Month"
                case "Fortnightly":
                    return "Fortnight"
                case "Weekly":
                    return "Week"
                case "Annual":
                    return "Year"
            }
        },
    },
    methods: {
        ...mapMutations('subscriptions', [
            'setStepableDataWorkable'
        ]),
        getLenderLogo(name){

            let lender = require(`~/assets/images/logo/placeholder.png`)

            try {

                lender = require(`~/assets/images/logo/cropped/${name}.png`)

                return lender

            } catch (error) {

                console.log('Ooops!..', error)

                return lender

            }

        },
        isBtnGroup(key){
            let keys = [
                "condition"
            ]
            return keys.includes(key)
        },
        isVisible(key){
            
            const {visible} = this

            if(visible.hasOwnProperty(key)){
                return visible[key]
            }

            return true

        },
        filterResourceFields(source, target){

            // console.log(source)
            // console.log(target)

            let newResource = {
                    type: "",
                    id: null,
                    attributes: {},
                    relationships: {}
            }

            Object.keys(source.attributes).forEach( field => {
                newResource.attributes[field] = target.attributes[field]
            })

            newResource.type = target.type
            newResource.id = target.id

            if(target.hasOwnProperty('relationships')) {

                Object.keys(target.relationships).forEach(item => {

                    if( target.relationships[item].hasOwnProperty('data') ) {
                        newResource.relationships[item] = target.relationships[item]
                    }
                })

            }
            return newResource
        },
        hexToRGBA(hex, a = 1){
            return this.$options.filters.hexToRGBA(hex, a)
        },
        /**
         * 
         * @param {Number} amount is the Monthly Amount 
         * @param {String} frequency 
         * @returns 
         */
        getMonthlyAmount(amount, frequency = 'Monthly'){

            switch (frequency) {
                case 'Monthly':
                    return parseFloat(amount)
                case 'Weekly':
                    return (parseFloat(amount) * 12) / 52
                case 'Fortnightly':
                    return (parseFloat(amount) * 12) / 26
                case 'Annual':
                    return (parseFloat(amount) * 12)
            }

        }
    },
    "validComponentData": {
        handler(val) {
            if ( !this.validComponentData ) {

                this.setStepableDataWorkable(false)

            }
        },
        deep: true
    }

}