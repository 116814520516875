<template>
    <v-app>

        <v-app-bar fixed flat app color="white" height="65"> 

            <v-container fluid px-4>
                <div class="d-flex">
                    <!-- Left : Nodifi Logo : Fixed for all tenants -->
                    <v-card flat tile class="px-4 d-flex align-center">
                        <img class="flex-brand d-block" :src="require(`~/assets/images/nodifi.svg`)" height="30"/>
                    </v-card>

                    <v-spacer></v-spacer>

                    <!-- Right: Tenant Logo : Visible only on white labelled themes -->
                    <v-card flat tile class="px-4 d-flex align-center" v-if="isWhiteLabelled">
                        <img class="flex-brand d-block" :src="require(`~/themes/${getActiveTheme.name}/svg/logo.svg`)" height="30"/>
                    </v-card>

                    <div class="px-4">
                        <UserMenu />
                    </div>
                </div>
            </v-container>
        </v-app-bar>

        <v-main class="light_gray_1">
            <nuxt />
        </v-main>

        <Snackbar />
        <Alerts />

    </v-app>
</template>

<script>

    import Snackbar from '@/components/base/Snackbar'
    import Alerts from '@/components/base/Alerts'
    import UserMenu from '~/components/base/UserMenu'

    import { mapMutations, mapActions, mapGetters, mapState } from 'vuex';

    export default {
        name: 'ErrorLayout',
        components: { Snackbar, Alerts, UserMenu },
        data () {
            return {

            }
        },
        computed: {
            ...mapGetters({
                getActiveTheme: 'themes/getActiveTheme',
                getGroupSlug: 'slug/getGroupSlug',
                isWhiteLabelled: 'isWhiteLabelled',
            }),
            ...mapState('themes', [
                'isSet'
            ]),
        },
        async mounted(){

            if(!this.isSet){

                let vThemes = await this.$vuetify.theme.themes

                this.$vuetify.theme.options.customProperties = true

                let light = vThemes.light

                vThemes.light = {
                    ...light,
                    ...this.getActiveTheme['colors']
                }
            }
        }
    }

</script>

<style scoped lang="scss">
</style>
