<template>
    <div>
        <div v-if="!data" class="error--text font-size-20 px-8 font-weight-bold">
            No Income Records Found for this Entity.
        </div>
        <div v-if="vb.mdAndUp" class="d-flex" style="width: 100%">
            <v-sheet color="light_gray_1" class="py-12 px-12" width="50%" outlined elevation="0">
                <v-img
                    max-width="120"
                    class="flex-brand"
                    :src="require(`~/assets/images/nodifi.svg`)"
                    position="center left"
                    contain>
                </v-img>
                <div class="mt-2 font-size-20 light_gray_2--text">Data</div>
    
                <v-list color="transparent">
                    <v-list-item two-line class="px-0">
                        <v-list-item-content>
                            <v-list-item-title class="font-weight-medium">Employer Name</v-list-item-title>
                            <v-list-item-subtitle>{{data ? data.attributes['employer-name'] ? data.attributes['employer-name'] : "-" : '&mdash;'}}</v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>
    
                    <v-list-item two-line class="px-0">
                        <v-list-item-content>
                            <v-list-item-title class="font-weight-medium">Employment Type</v-list-item-title>
                            <v-list-item-subtitle>{{data ? data.attributes['job-title'] ? data.attributes['job-title'] : '-' : '&mdash;'}}</v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>
                </v-list>
    
            </v-sheet>
            <v-sheet class="py-8 px-12" width="50%">
    
                <v-img
                    max-width="120"
                    class="flex-brand"
                    :src="lenderLogo"
                    position="center left"
                    contain>
                </v-img>
                <div class="mt-2 mb-8 font-size-20">Requirements</div>
    
                <ValidationObserver ref="observer" tag="form">
                    <template v-for="(field, key) in currentStep['form-fields']" >
                        <ValidationProvider
                                v-if="visible[field]"
                                :key="key"
                                v-slot="{ errors }"
                                :name="getLabel(field)"
                                :rules="getRules(field)"
                                :vid="field">
        
                                    <v-select
                                        v-if="isFieldType('picklist', field)"
                                        v-model="resources.incomes.attributes['lender']['westpac'][field]"
                                        :items="getOptions(field)"
                                        :error-messages="errors"
                                        color="secondary"
                                        :label="getLabel(field)"
                                        ></v-select>
        
                                    <v-text-field
                                        v-else
                                        tile
                                        type="text"
                                        :label="getLabel(field)"
                                        color="secondary"
                                        :error-messages="errors"
                                        v-model="resources.incomes.attributes['lender']['westpac'][field]"
                                        required>
                                    </v-text-field>
        
                        </ValidationProvider>
                    </template>
                </ValidationObserver>
    
    
            </v-sheet>
        </div>
    
        <!-- mobile version -->
        <div v-else>
    
            <v-card flat class="mx-5 rounded-xl" :style="`background-color: ${hexToRGBA(getActiveTheme.colors.secondary, 0.2)}`">
                <v-card-text class="d-flex py-1">
                    <v-row>
                        <v-col cols="6" class="pa-1">
                            <v-card
                            min-height="45"
                                flat class="rounded-xl px-1"
                                :color="tabSelection == 'nodifi' ? 'white' : 'transparent'">
                                <div class="d-flex justify-center pt-1" @click="selectTab('nodifi')">
                                    <v-img
                                        max-width="55"
                                        class="flex-brand"
                                        :src="require(`~/assets/images/nodifi.svg`)"
                                        position="center left"
                                        contain>
                                    </v-img>
                                    <div class="mt-3 font-size-12 pl-1">Data</div>
                                </div>
                            </v-card>
                        </v-col>
                        <v-col cols="6" class="pa-1">
                            <v-card
                                flat class="rounded-xl px-1"
                                :color="tabSelection == 'westpac' ? 'white' : 'transparent'">
                                <div class="d-flex flex-column" @click="selectTab('westpac')">
                                    <v-img
                                        max-width="45"
                                        class="flex-brand mx-auto mt-2"
                                        :src="lenderLogo"
                                        position="center left"
                                        contain>
                                    </v-img>
                                    <div class="font-size-12 pl-1 mx-auto">Requirements</div>
                                </div>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-card-text>
    
            </v-card>
    
            <v-list v-show="tabSelection == 'nodifi'" class="mx-8" color="transparent">
                <v-list-item two-line class="px-0">
                    <v-list-item-content>
                        <v-list-item-title class="font-weight-medium">Employer Name</v-list-item-title>
                        <v-list-item-subtitle>{{data ? data.attributes['employer-name'] ? data.attributes['employer-name'] : "-" : '&mdash;'}}</v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>

                <v-list-item two-line class="px-0">
                    <v-list-item-content>
                        <v-list-item-title class="font-weight-medium">Employment Type</v-list-item-title>
                        <v-list-item-subtitle>{{data ? data.attributes['job-title'] ? data.attributes['job-title'] : '-' : '&mdash;'}}</v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>
            </v-list>
    
            <div v-show="tabSelection != 'nodifi'" class="mx-8">
                <ValidationObserver ref="observer" tag="form">
                    <template v-for="(field, key) in currentStep['form-fields']" >
                    <ValidationProvider
                            v-if="visible[field]"
                            :key="key"
                            v-slot="{ errors }"
                            :name="getLabel(field)"
                            :rules="getRules(field)"
                            :vid="field">
    
                                <v-select
                                    v-if="isFieldType('picklist', field)"
                                    v-model="resources.incomes.attributes['lender']['westpac'][field]"
                                    :items="getOptions(field)"
                                    :error-messages="errors"
                                    color="secondary"
                                    :label="getLabel(field)"
                                    ></v-select>
    
                                <v-text-field
                                    v-else
                                    tile
                                    type="text"
                                    :label="getLabel(field)"
                                    color="secondary"
                                    :error-messages="errors"
                                    v-model="resources.incomes.attributes['lender']['westpac'][field]"
                                    required>
                                </v-text-field>
    
                    </ValidationProvider>
                    </template>
                </ValidationObserver>
            </div>
    
        </div>
    </div>
</template>

<script>

    import { mapActions } from 'vuex'
    import subscriptions from '~/components/shared/subscriptions'
    import shared from '~/components/v1/submission/WestpacEntities1/__shared'
    import Enums from '~/components/v1/submission/WestpacEntities1/__enums'

    export default {
        components: {},
        mixins: [subscriptions, shared],
        props: {
            data: {
                type: Object,
                default: null
            },
            updater: {
                type: Boolean,
                default: false
            }
        },
        data() {
            return {
                tabSelection: 'nodifi',
                resources: {
                    incomes: {
                        id: null,
                        type: "incomes",
                        attributes: {
                            "lender": {
                                "westpac": {
                                    "currentOccupation" : null,
                                }
                            }
                        },
                        relationships: {
                            application: {
                                data : {
                                    type: "applications",
                                    id: null
                                }
                            }
                        }
                    }
                },
                visible: {
                    "currentOccupation": true
                },
                labels: {
                    "currentOccupation": "Occupation",
                },
                validation_rules: {
                    attributes: {
                        "currentOccupation": 'required',
                    }
                }
            }
        },
        computed: {
            vb() {
                return this.$vuetify.breakpoint
            },
            incomeTypes() {
                    let incomeKinds = this.kinds.find( kind => {
                        return kind.name == 'Income'
                    })
                    return incomeKinds ? incomeKinds.children : []
                },
        },
        mounted() {
            this.updateResourceFields()
        },
        methods: {
            ...mapActions('resource-actions', [
                'getIncome',
            ]),
            selectTab(tab) {
                this.tabSelection = tab
            },
            getLabel(key) {

                return this.labels[key]
            },
            async validate(){

                const valid = await this.$refs.observer.validate()

                if(valid) {
                    this.$emit('submit', this.resources.incomes)
                }

            },
            async reset(){

                this.$refs.observer.reset()
                this.resources.incomes = {
                    id: null,
                    type: "incomes",
                    attributes: {
                        "lender": {
                            "westpac": {
                                "currentOccupation" : null,
                            }
                        }
                    },
                    relationships: {
                        application: {
                            data : {
                                type: "applications",
                                id: null
                            }
                        }
                    }
                }

            },

            getKind(kindId) {

                    if(!kindId) return undefined;

                    let filteredKind = null

                    this.incomeTypes.forEach( kind => {
                        if ( kind.children?.length ) {
                            return kind.children.find( child_kind => {
                                if ( kindId === child_kind.id) {
                                    filteredKind = child_kind
                                }
                            })
                        }
                        else {
                            if ( kind.id === kindId )
                                filteredKind = kind
                        }
                    })
                    return `${filteredKind?.id}`
            },
            async updateResourceFields(){

                this.$emit('loading', true)

                const {data} = this

                if(data.id) {

                    let res = await this.getIncome(data.id)
                    
                    if(res.status === 200) {

                        const incomeKind = this.getKind(res.data.data.relationships.kind?.data?.id)
                        let currentOccupationDefaultValue = undefined;

                        switch (incomeKind) {
                            case '12-16': // Company Director 
                            currentOccupationDefaultValue = 'Director'
                                break;
                            case '12-29': // Self-Employed
                            currentOccupationDefaultValue = 'Sole Trader'
                                break;
                            case '12-28': // PAYG Employment
                            currentOccupationDefaultValue = res.data.data.attributes['job-title']
                                break;
                            default:
                                break;
                        }

                        this.resources.incomes.id = res.data.data.id
                        this.resources.incomes.attributes.lender.westpac['currentOccupation'] = res.data.data.attributes.lender?.westpac?.['currentOccupation'] ? res.data.data.attributes.lender.westpac['currentOccupation'] : currentOccupationDefaultValue
                        this.resources.incomes.relationships.application = res.data.data.relationships.application
                    }

                    this.$emit('loading', false)

                } else {

                    this.$emit('loading', false)
                }



            }
        },
        watch: {
            async updater(val) {
                await this.updateResourceFields()
            }
        }
    }
</script>

<style lang="scss" scoped>
</style>
  