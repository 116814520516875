<template>
        <v-card 
            :disabled="isDisabled || blockPermissionsReadOnly" 
            class="mx-auto" 
            tile flat>

            <RequirementChecklist1/>

            <v-row>
                <v-col cols="12">
                    <v-card-text class="pa-4 pt-4 pa-md-10 pt-md-0">
                        <div
                            :class="$vuetify.breakpoint.smAndUp ? 'pb-4 pt-2' : ''"
                            class="title">
                            Purchases
                        </div>

                        <FlexWrap @resize="handleResize">
                        <v-data-table
                            :headers="tableHeaders"
                            :items="resources.purchases"
                            hide-default-footer
                            :items-per-page="numberOfPurchases"
                            :mobile-breakpoint="0">

                            <template #[`item.image`]="{ item }">
                                <v-avatar
                                    size="50px"
                                    class="my-2 light_gray_1"                        
                                    rounded>
                                    <v-icon 
                                        v-if="!isMotorVehicle(item)" 
                                        color="light_gray_2"
                                        x-large
                                        class="light_gray_1" 
                                        v-text="getIcons(item)">
                                    </v-icon>
                                    <v-img 
                                        v-else-if="fetchImage(item.id)"
                                        :lazy-src="fetchImage(item.id)"
                                        :src="fetchImage(item.id)"
                                        :aspect-ratio="1"
                                        height="50" width="50">
                                    </v-img>
                                    <p-image 
                                        v-else
                                        :item="item.attributes.nvic" />
                                    
                                </v-avatar>
                            </template>

                            <template #[`item.condition`]="{ item }">
                                {{ item.attributes.condition }}
                            </template>

                            <template #[`item.description`]="{ item }">
                                <div class="cell">
                                    <div class="text-truncate">{{ purchaseDescription(item) }}</div>
                                </div>
                            </template>

                            <template #[`item.purchase-price`]="{ item }">
                                {{ item.attributes['purchase-price'] | currency }}
                            </template>

                            <template #[`item.required-loan-amount`]="{ item }">
                                <span v-if="item.attributes['required-loan-amount']">
                                    {{ item.attributes['required-loan-amount'] | currency }}
                                </span>
                                <span v-else></span>
                            </template>

                            <template #[`item.actions`]="{ item }">
                                <div class="inline-icons">
                                    <EditPurchase
                                        v-if="referencedAndQuotable"
                                        :force-open="referencedAndQuotable"
                                        :description="purchaseDescription(item)"
                                        @confirmed-update="saveUpdatedPurchase"
                                        :purchased-item="item" />

                                    <EditPurchase
                                        v-else-if="hasRouteQuery"
                                        :force-open="initialDataFromExpand"
                                        :has-route-query="initialDataFromExpand"
                                        @confirmed-update="saveUpdatedPurchase"
                                        @close-modal="hasRouteQuery = false"
                                        :description="purchaseDescription(purchaseItem)"
                                        :purchased-item="purchaseItem" />

                                    <EditPurchase
                                        v-else
                                        @confirmed-update="saveUpdatedPurchase"
                                        :description="purchaseDescription(item)"
                                        :purchased-item="item" />
                                    <DeletePurchase 
                                        color="light_gray_2"
                                        confirm-color="error"
                                        @confirmed-action="removeSelectedPurchase"
                                        button-type="icon"
                                        :id="item.id" 
                                        title="Delete Purchase"
                                        confirm-text="Delete"
                                        message="Are you sure you want to remove the selected purchase?" />
                                </div>                    
                            </template>

                        </v-data-table>
                        </FlexWrap>

                        <v-card-actions
                            class="px-0 pt-4 pb-0">
                            <v-spacer></v-spacer>
                            <AddPurchase
                                :asset-icons="assetIcons"
                                @on-data-change="updatePurchaseObj"
                                @confirmed-save="saveNewPurchase" />
                        </v-card-actions>
                            
                    </v-card-text>
                </v-col>
                <v-col v-show="false" :cols="standalone ? $vuetify.breakpoint.lgAndUp ? 6 : 12 : 12">
                    <v-card-text
                        :class="standalone ? $vuetify.breakpoint.lgAndUp ? 'faint-line' : '' : ''"
                        class="pa-4 pt-4 pa-md-10 pt-md-0">
                        
                        <div class="title mb-4">Loan Details</div>

                        <v-container pa-0 fluid>
                            <ValidationObserver 
                                ref="observer" 
                                tag="form"
                            >   
                                <v-row>
                                    <v-col :cols="$vuetify.breakpoint.smAndUp ? 6 : 12" 
                                        v-for="(field, key) in resources.loan.attributes"
                                        :key="key"
                                        class="py-0"
                                    >
                                        <ValidationProvider
                                            v-if="isVisible(key)"
                                            v-slot="{ errors }"
                                            :name="labels[key]"
                                            :rules="getRules(key)"
                                            :vid="key">

                                            

                                            <template >
                                                
                                                <NodifiMoney
                                                    v-model="resources.loan.attributes[key]"
                                                    :label="labels[key]"
                                                    :disabled="key === 'requested-required-loan-amount' ? true : false"
                                                    :errors="errors" />
                                                    
                                            </template>                                        
                                        </ValidationProvider>
                                          
                                    </v-col>
                                </v-row>
                                <ValidationProvider rules="required|min_value:1" v-slot="{ errors }" name="Purchase Count">
                                    <v-text-field
                                    v-model="purchaseCount"
                                    :error-messages="errors"
                                    label="Purchase Count"
                                    ></v-text-field>
                                </ValidationProvider>
                            </ValidationObserver>
                        </v-container>

                    </v-card-text>
                </v-col>
            </v-row>
            
        </v-card>

</template>

<script>

    import { mapActions, mapGetters, mapMutations } from 'vuex'
    import subscriptions from '~/components/shared/subscriptions'
    import AddPurchase from '~/components/v1/AssetLoanEditor1/_AddPurchaseDialog1'
    import EditPurchase from '~/components/v1/AssetLoanEditor1/_EditPurchaseDialog'
    import DeletePurchase from '~/components/base/ConfirmModal'
    import PImage from '~/components/base/ImageFetcher'    
    import FlexWrap from '~/components/base/FlexWrap'
    import helpers from '~/plugins/helpers'
    
    export default {
        name: 'PurchaseEditor5',

        mixins: [ subscriptions, helpers ],

        props: {
            isFlow: {
                type: Boolean,
                default: true
            },
            /**
             *  {standalone}
             *  true : as step
             *  false : on a modal
             */
            standalone: {
                type: Boolean,
                default: true 
            }
        },

        components: { 
            AddPurchase,             
            DeletePurchase,
            EditPurchase,
            PImage,
            FlexWrap
        },

        computed: {
            ...mapGetters('quote-actions', [
                'initialDataFromQuote'
            ]),            
            ...mapGetters('kinds', ['kinds']),
            ...mapGetters('slug', ['getFlowSlug']),
            ...mapGetters('flows', ['getCalculation']),

            tableHeaders() {

                if ( !this.sizes ) return

                const { sm, xs } = this.sizes
                
                let newHeaders = [
                    ...this.headers
                ]

                let heads = [
                    { value: 'image', width: '15%' },
                    { value: 'condition', width: '20%' },
                    { value: 'description', width: '25%' },
                    { value: 'purchase-price', width: '20%' },
                    { value: 'required-loan-amount', width: '10%' },
                    { value: 'actions', width: '10%' }
                ]
                
                if ( sm ) {                    
                    heads = [ 
                        { value: 'image', width: '15%' },
                        { value: 'condition', width: '20%' },
                        { value: 'description', width: '25%' },
                        { value: 'purchase-price', width: '20%' },
                        { value: 'actions', width: '10%' }
                    ]
                }

                if(xs){
                    heads = [ 
                        { value: 'image', width: '15%' },
                        { value: 'purchase-price', width: '20%' },
                        { value: 'actions', width: '10%' }
                    ]
                }

                // return newHeaders.filter( head => heads.includes(head.text))
                return newHeaders.reduce((accumulator, current) => {

                    heads.forEach(head => {

                        if(head.value == current.value){

                            let newCurrent = {...current}

                            newCurrent.width = head.width

                            accumulator.push(newCurrent)
                        }

                    })

                    return accumulator 

                }, [])

            },

            numberOfPurchases() {
                return this.resources?.purchases?.length > 10 ? this.resources.purchases.length + 1 : 10
            },
            
            getLoanId(){
                return this.loanData?.id
            },

            validComponentData(){
                
                const { data } = this.appData?.relationships?.loan

                if ( data == null ) {
                    return false;
                }

                return true;
            },

            referencedAndQuotable() {
                if ( this.initialDataFromQuote ) {
                    if ( this.resources.purchases?.length === 1 ) {
                        return true
                    }                    
                }
                return false
            },
            assetTypeList() {
                let purchasesKinds = this.kinds.find( kind => {
                    return kind.name == 'Purchase'
                })
                return purchasesKinds ? purchasesKinds.children : []
            },
            hasRouteQuery: {
                get() {
                    if(this.$route.query && this.$route.query.hasOwnProperty('edit-purchase')) {
                        let purchaseItem = this.resources.purchases.find(item => item.id == this.$route.query['edit-purchase'])
                        if(purchaseItem && this.hasOpened == false) {
                            this.purchaseItem = purchaseItem
                            this.initialDataFromExpand = true
                            this.hasOpened = true                     
                            return true
                        }
                        return false
                    }
                },
                set(val) {
                    this.initialDataFromExpand = val;
                }
            }
        },

        async mounted() {
            if(!this.validComponentData){
                this.showComponentWarning()
            }
            if ( !this.isFlow ) {
                await this.pull()
            }
        },

        methods: {
            ...mapActions('resource-actions', [
                'getApplicationLoan',
                'updateLoan',
                'createLoan',
                'getPurchases',
                'deletePurchase',
                'createPurchase',
                'updatePurchases',
                'getAutoImages'
            ]),
            ...mapMutations('dialogs', [
                'toggleTouched',
            ]),
            initialize () {

                this.resources.loan.attributes['requested-balloon'] = this.resources.loan.attributes['requested-balloon'] || 0
                this.resources.loan.attributes['budgeted-payment-amount'] = this.resources.loan.attributes['budgeted-payment-amount'] || 0                
            },
            isVisible(key){
                
                const {visible} = this

                if(visible.hasOwnProperty(key)){
                    return visible[key]
                }

                return true

            },

            /**
             * Calling actions: getLoans 
             * from store which trigger an axios call,
             * pulling all data from an api response
             */
            async pull() {

                // Do nothing if minimum data required is not met.
                if(!this.validComponentData) return; 
                
                let res = await this.fetchData()
                
                this.resources.loan = this.resourceFieldPicker(this.resources.loan, res[0].data.data)
                await this.initialize()

                this.resources.purchases = res[1].data.data

                // Save VeeValidate's observer in Vuex for observing touched events
                this.setFormFieldsObserverName('observer')

                this.runChecklist()

            },

            async push() {

                const observer = this.$refs.observer

                const {loan} = this.resources
                
                let payload = {
                    type: loan.type,
                    id: loan.id,
                    attributes: this.excludeInvalids(loan.attributes, observer.fields)
                }

                let loanRes = null;

                if (this.getLoanId) {

                    loanRes = this.updateLoan(payload)

                } 
                else {

                    payload.relationships = {
                        application: {
                            data: { type: "applications", id: this.appId }
                        }
                    }

                    loanRes = this.createLoan(payload)
                }

                const { valid, status } = await this.validateFormData()
                const data = await this.appDataHelper([loanRes])

                return { valid, status, data }

            },

            async fetchData() {

                let loanRes = this.getApplicationLoan(this.getLoanId)
                let purchasesRes = this.getPurchases()

                return Promise.all([loanRes, purchasesRes])
                .then( data => {
                        return data
                    }
                )
                .catch( err => {
                        return err
                    }
                )
            },

            updatePurchaseObj(data) {
                this.resPurchase = data.resources
                this.tempImg = data.img
            },

            async saveNewPurchase(data) {
                this.toggleTouched(true)
                let res = await this.createPurchase({
                    attributes: this.resPurchase.attributes,
                    relationships: {
                        application: { data: { type: 'applications', id: this.appId } },
                        kind: this.resPurchase.relationships.kind
                    },
                    type: 'purchases'
                })

                if ( res.status === 201 ) {
                    this.resources.purchases.push(res.data.data)
                    this.images.push({ id:res.data?.data?.id, img: this.tempImg })
                    this.hasRouteQuery = false;

                    this.runChecklist()
                }
            },

            async saveUpdatedPurchase(data) {   
                this.toggleTouched(true)
                let response = await this.updatePurchases(data.resources)
                if ( response.status === 200 ) {
                    this.resources.purchases.forEach( (purchase, key) => {
                        if ( purchase.id === response.data?.data?.id ) {
                            this.resources.purchases[key].attributes = response.data.data.attributes
                        }
                    })

                    this.runChecklist()

                    this.images.push({ id: data?.resources?.id, img: data.img })
                    this.hasRouteQuery = false;
                }
            },

            fetchImage(id) {
                let image = this.images.find( img => {
                    return img.id === id
                })
                return image?.img
            },

            async removeSelectedPurchase(data) {
                
                let index = null
                let res = await this.deletePurchase(data)
                
                if ( res.status === 204 ) {
                    this.$store.commit('utils/toggleQuickMessage',  {text: 'Selected purchase successfully removed!', color: 'success'})
                    this.resources.purchases.forEach( (res, j) => {
                        if ( res.id === data ) index = j
                    })

                    this.resources.purchases.splice(index, 1)

                    this.runChecklist()
                }
            },

            purchaseDescription(item) {
                let temp = this.getFilteredDescription(item)
                return temp
            },

            getFilteredDescription(currentKind) {
                let fkind = currentKind?.relationships?.kind.data?.id ? currentKind.relationships.kind.data : 0
                let filteredKind = null
                let filteredParent = null

                this.assetTypeList.forEach( kind => {
                    if ( kind.children?.length ) {
                        return kind.children.find( child_kind => {
                            if ( fkind.id === child_kind.id) {
                                filteredParent = kind
                                filteredKind = child_kind
                            }
                        })
                    }
                    else {
                        if ( kind.id === fkind.id )
                            filteredKind = kind
                    }
                })

                if ( filteredKind?.name === 'Motor Vehicle') {
                    let desc = `${currentKind.attributes['year-of-manufacture']} ${this.$options.filters.formalAndCapitalize(currentKind.attributes['make'])} ${currentKind.attributes['model']}`
                    return desc.indexOf('null') >= 0 ? `Motor Vehicle (${currentKind.attributes['year-of-manufacture'] ? currentKind.attributes['year-of-manufacture'] : '--'})` : desc
                }
                
                if ( filteredParent?.name === filteredKind?.name)
                    return filteredKind?.name
                return `${filteredParent?.name} - ${filteredKind?.name}`
            },

            isMotorVehicle(item) {
                let temp = ''
                
                this.assetTypeList.forEach( kind => {
                    if ( kind.id === item?.relationships?.kind?.data?.id ) temp = kind
                })
                
                return temp?.name === 'Motor Vehicle' ? true : false
            },

            getIcons(item) {
                let iconObj = null
                this.assetTypeList.forEach( kind => {
                    if ( kind.children?.length ) {
                        return kind.children.find( child_kind => {
                            if ( item?.relationships?.kind?.data?.id === child_kind.id) {
                                iconObj = child_kind
                            }
                        })
                    }
                })
                
                return this.assetIcons[iconObj?.name]?.icon
            },
            ...mapMutations('requirements-checklist', [
                'setRequirementChecklistTemplateLess',
                'reset',
            ]),
            runChecklist(){

                /**
                 * Reset requirements checklist to prevent merging requirements from 
                 * other steps.
                 */
                this.reset()

                this.updateRequirementChecklistPurchaseSidebar({
                    resources: this.resources.purchases
                })

            },
            updateRequirementChecklistPurchaseSidebar({resources = []}){
                this.$nextTick( async () => {

                    let applicantId = this.getCalculation['primary-applicant-id']

                    let obj = {
                        "id": applicantId,
                        "entity": null,
                        "sections": []
                    }

                    let required = {
                        "has-purchase": {
                            value: resources.length,
                            id: "has-purchase",
                            name: "Has Purchase Record",
                            valid: resources.length > 0,
                            required: true,
                        }
                    }
                        
                    obj.sections.push({
                        title: "Purchase Information",
                        requirements: required
                    })

                    /**
                     * Add individual address as section in obj.sections
                     */                        
                    resources.forEach( record => {

                        const fieldLabels = {
                            "condition": "Condition",
                            "vendor-known": "Vendor Found",
                            "year-of-manufacture": "Year of Manufacture",
                            "purchase-price": "Purchase Price",
                            "required-loan-amount": "Required Loan Amount",
                        }

                        const fieldValues = {
                            "purchase-price": record.attributes["purchase-price"],
                            "condition": record.attributes["condition"],
                            "vendor-known": record.attributes["vendor-known"],
                            "year-of-manufacture": record.attributes["year-of-manufacture"],
                            "purchase-price": record.attributes["purchase-price"],
                            "required-loan-amount": record.attributes["required-loan-amount"],
                        }

                        let subtitle = this.purchaseDescription(record)

                        let sectionTemplate = {
                            // title: "Purchase Information",
                            title: subtitle,
                            // subtitle: subtitle,
                            requirements: {}
                        }

                        Object.keys(fieldValues).forEach(field => {
                            sectionTemplate.requirements[field] = {
                                value: fieldValues[field],
                                id: field,
                                name: fieldLabels[field],
                                valid: fieldValues[field] != null,
                                touched: true, // touched is always true if there is purchase record.
                                required: true,
                            }
                        })
                        
                        obj.sections.push(sectionTemplate)

                    });


                    this.setRequirementChecklistTemplateLess(obj)
                    
                })
            }, 

        },

        watch: {
            'resources.purchases': {
                handler(val) {
                    let totalLoanAmount = 0
                    val.forEach( purchaseItem => {
                        totalLoanAmount += parseInt(purchaseItem.attributes['required-loan-amount'])
                    })
                    this.resources.loan.attributes['requested-required-loan-amount'] = totalLoanAmount
                    val.length != 0 ? this.purchaseCount = val.length : this.purchaseCount = 0
                },
                deep: true
            },
            'resources.loan': {
                handler(newVal, oldVal) {
                    if ( !this.isFlow && oldVal?.id ) {
                        this.$emit('loan-details-changed', this.resources.loan)
                    }
                },
                deep: true
            },
            'isFlow': {
                async handler(val) {
                    if ( !val ) {
                        await this.pull()                        
                    }
                },
                deep: true
            }
        },
        data(){
            return {
                hasOpened: false,
                purchaseItem: null,
                initialDataFromExpand: false,
                visible: {},
                purchaseCount: 0,
                resources: {
                    loan: {
                        type: 'loans',
                        id: null,
                        attributes: {
                            "requested-required-loan-amount": '',
                            "requested-balloon": '',
                            "budgeted-payment-amount": ''
                        }
                    },
                    purchases: []
                },
                resPurchase: {},
                // LOAN resources
                labels: {
                    "requested-required-loan-amount": "Required Loan Amount",
                    "requested-balloon": 'Balloon Requested',
                    "budgeted-payment-amount": 'Budgeted Payment Amount'
                },
                validation_rules: {
                    type: "loans",
                    attributes: {
                        "requested-required-loan-amount": "required",
                    },
                },
                options: {},
                suffix: {},
                headers: [
                    { text: 'Image', sortable: false, value: 'image' },
                    { text: 'Condition', sortable: false, value: 'condition' },
                    { text: 'Description', sortable: false, value: 'description' },
                    { text: 'Purchase Price', sortable: false, value: 'purchase-price' },
                    { text: 'Required Loan Amount', sortable: false, value: 'required-loan-amount' },
                    { text: '', sortable: false, value: 'actions' }
                ],
                tempImg: '',
                images: [],
                assetIcons: {
                    "Heavy Vehicles": { icon: 'mdi-dump-truck' },
                    "Motor Vehicle": { icon: 'mdi-car' },
                    "Agricultural Machinery": { icon: 'mdi-tractor' },
                    "Yellow Goods": { icon: 'mdi-rv-truck' },
                    "Other Equipment": { icon: 'mdi-caravan' },
                    "Leisure Goods": { icon: 'mdi-alpha-l-box' },
                    "Real Estate - Established": { icon: 'mdi-home-city' },
                    "Real Estate - Construction": { icon: 'mdi-home-group' },
                    "Bus": { icon: 'mdi-bus' },
                    "Crane": { icon: 'mdi-crane' },
                    "Prime Mover": { icon: 'mdi-tow-truck' },
                    "Rigid Truck (> 4.5t)": { icon: 'mdi-dump-truck' },
                    "Trailer (Truck)": { icon: 'mdi-truck-trailer' },
                    "Computer Hardware": { icon: 'mdi-desktop-classic' },
                    "Industrial Plant (Lathes etc)": { icon: 'mdi-factory' },
                    "Material Handling (Fork Lifts etc)": { icon: 'mdi-forklift' },
                    "Medical Equipment": { icon: 'mdi-medical-bag' },
                    "Office Equipment (Copiers etc)": { icon: 'mdi-printer' },
                    "Other (Not Listed)": { icon: 'mdi-wallet-giftcard' },
                    "POS Systems": { icon: 'mdi-printer-pos' },
                    "Solar Equipment": { icon: 'mdi-solar-panel' },
                    "Boat (Moored)": { icon: 'mdi-sail-boat' },
                    "Boat (Trailered)": { icon: 'mdi-sail-boat' },
                    "Camper Trailer": { icon: 'mdi-truck-trailer' },
                    "Caravan": { icon: 'mdi-caravan' },
                    "Horse Float": { icon: 'mdi-truck-trailer' },
                    "Jetski": { icon: 'mdi-sail-boat' },
                    "Apartment/Unit/Flat": { icon: 'mdi-home-modern' },
                    "Display Home": { icon: 'mdi-home-variant' },
                    "Duplex": { icon: 'mdi-home-floor-2' },
                    "Fully-Detached House": { icon: 'mdi-home' },
                    "Hobby Farm": { icon: 'mdi-barn' },
                    "Kit Home": { icon: 'mdi-home-minus' },
                    "Semi-Detached House": { icon: 'mdi-home-variant-outline' },
                    "Serviced Apartment": { icon: 'mdi-home-city' },
                    "Townhouse": { icon: 'mdi-home-modern' },
                    "Vacant Land": { icon: 'mdi-crop-landscape' },
                    // "Vehicles": { icon: 'mdi-' },
                },
                hasFlow: false
            }
        }
    }

</script>

<style lang="scss" scoped>
    .inline-icons {
        display: inline-flex;
    }
    .v-avatar {
        position: relative;
    }
    .faint-line {
        border-left: 2px solid var(--v-light_gray_1-base) !important
    }
</style>