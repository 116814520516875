<template>
    <v-dialog
        v-model="dialog"
        width="700"
        scrollable
        persistent>   
        <v-card flat class="ol-residential-history-5-is-currently-living" :disabled="isSaving">
            <v-progress-linear color="primary" indeterminate stream absolute top v-if="isSaving"></v-progress-linear>
            <v-card-title class="pa-0 ma-0 pr-5 pt-3 mb-n5">
                <v-spacer></v-spacer>
                <v-btn icon rounded small @click="close" depressed>
                    <v-icon color="primary" size="20">mdi-close</v-icon>
                </v-btn>
            </v-card-title>
            <v-card-title class="px-8 py-6 text-center">
                <div class="sm pa-0 text-center" style="width:100%">
                   {{title}}
                </div>
            </v-card-title>
            <v-card-text :class="vb.mdAndUp ? 'px-10' : 'px-4'">
                <ValidationObserver ref="observer1" tag="form">

                    <v-row v-if="actionType == 'edit'" :justify="items.length <= 3 ? 'center' : 'start'"
                        align="center"
                        :style="items.length <= 3 && vb.mdAndUp ? 'width: 50%;' : 'width: 100%;'"
                        class="rounded-lg light_gray_1 pa-2 ma-2 mx-auto">
                        <v-col v-for="(item, key) in items" :key="key"
                            cols="12" class="pa-2" :md="items.length <= 3 ? '12' : '6'">
                            <v-card
                                flat
                                height="100%" 
                                @click="updateProperty(item.value)"
                                rounded="lg"
                                class="d-flex pa-4 align-center justify-center"
                                :style=" item.value == form['residential-situation'] ? `background: ${hexToRGBA(getActiveTheme.colors.primary, 0.1)}`: ''"
                                >
                                <div class="flex-options body-2 font-weight-medium">
                                    {{item.label}}
                                </div>
                                <v-spacer></v-spacer>
                                <v-btn color="secondary" :width="86" small depressed>
                                    Select
                                </v-btn>
                            </v-card>
                        </v-col>
                    </v-row>

                    <v-row v-else :justify="items.length <= 3 ? 'center' : 'start'"
                        align="center"
                        :style="items.length <= 3 && vb.mdAndUp ? 'width: 50%;' : 'width: 100%;'"
                        class="rounded-lg light_gray_1 pa-2 ma-2 mx-auto">
                        <v-col v-for="(item, key) in items" :key="key"
                            cols="12" class="pa-2" :md="items.length <= 3 ? '12' : '6'">
                            <v-card
                                flat
                                height="100%" 
                                @click="saveProperty(item.value)"
                                rounded="lg"
                                class="d-flex pa-4 align-center justify-center"
                                :style=" item.value == form['residential-situation'] ? `background: ${hexToRGBA(getActiveTheme.colors.primary, 0.1)}`: ''"
                                >
                                <div class="flex-options body-2 font-weight-medium">
                                    {{item.label}}
                                </div>
                                <v-spacer></v-spacer>
                                <v-btn color="secondary" :width="86" small depressed>
                                    Select
                                </v-btn>
                            </v-card>
                        </v-col>
                    </v-row>

               </ValidationObserver>
                
            </v-card-text>
            <v-card-text class="d-flex justify-center pb-8" v-if="actionType == 'edit'">
                <v-btn class="px-10 " width="140" height="50" depressed large @click="back">Back</v-btn>
                <!-- <v-btn class="px-10 mx-4 secondary" :loading="isSaving" width="140" height="50" depressed large @click="update ">Update</v-btn> -->
            </v-card-text>
            <v-card-text class="d-flex justify-center pb-8" v-else>
                <v-btn class="px-10 " width="140" height="50" depressed large @click="back">Back</v-btn>
                <!-- <v-btn class="px-10 mx-4 secondary" width="140" height="50" depressed large @click="nextStep" :loading="isSaving">Next</v-btn> -->
            </v-card-text>
        </v-card>

    </v-dialog>
</template>
<script>
import {store,mutations} from './__store'
import shared from './__shared'
import fieldOptions from '~/components/shared/field-options'
import formHelpers from '~/components/shared/form-helpers.js'
import validation from '~/components/shared/validation'
import { mapState, mapActions, mapGetters } from 'vuex'
export default {
    props : {
        title: {
            type: String,
            default: 'Title'
        },
        step: {
            type: String,
            default: ''
        },
        address: {
            type:Object,
            default: () => {}
        },
        actionType: {
            type:String,
            default: ''
        },
        occupancySituation : {
            type: String,
            default: ''
        },
        index : {
            type: Number,
            default: 0
        },
        endDate : {
            type: String,
            default: ''
        }
    },
    mixins: [formHelpers,validation,shared],
    watch: {
        "step": {
            handler (val) {
                val == 'property-description' ? this.dialog = true : this.dialog = false
            }
        },
        "address": {
            handler (val) {
                if (val.hasOwnProperty('occupancy')){
                    this.form['residential-situation'] = val.occupancy.attributes['residential-situation']
                } else {
                    this.form['residential-situation'] = ""
                }
            }
        },
        'actionType': {
            handler (val) {
                return val
            }
        },
        'occupancySituation': {
            handler (val) {
                return val
            }
        },
        'endDate': {
            handler (val) {
                return val
            }
        },
        'index': {
            handler (val) {
                return val
            }
        }
    },
    computed : {
        ...mapState('flows',['calculation'])
    },
    methods: {
        ...mapActions('resource-actions',['updateOccupancy','createOccupancy','updateAddress']),
        async nextStep(data) {
           let valid = await this.$refs.observer1.validate();
           if(valid){
            //    this.$emit('input',{model:'residential-situation',value:this.form['residential-situation']})
                let payload = {
                    'residential-situation': this.form['residential-situation'],
                    'occupancy-situation': 'Previous',
                    'end-date' : this.endDate
                }
                mutations.setOccupancies(payload)
                
                if(store.isLivedTogether == 'Yes'){

                    if(this.occupancyResource1.hasOwnProperty('id')) {
                        delete  this.occupancyResource1.id 
                        delete  this.occupancyResource2.id 
                    }

                    let occupancy1 = await this.createOccupancy(this.occupancyResource1)
                    let occupancy2 = await this.createOccupancy(this.occupancyResource2)

                    let addressResource = store.addressResource
                    if(addressResource.hasOwnProperty('id')){
                            delete addressResource.id
                    }

                    let activeOccupancy = occupancy1

                    // find active occupancy
                    if(occupancy1 && occupancy2) {
                        if(occupancy1.data.data.relationships.person.data.id == this.entityId) {
                            activeOccupancy = occupancy1
                        } else if(occupancy2.data.data.relationships.person.data.id == this.entityId) {
                            activeOccupancy = occupancy2
                        }
                    }

                    addressResource.relationships.occupancies = {data: [{id:occupancy1.data.data.id, type:'occupancies'},{id:occupancy2.data.data.id, type:'occupancies'}]}
                    
                    let address = await this.createAddress(addressResource)
                    let addressWithOccupancy = { address: address.data.data, occupancy: activeOccupancy.data.data}

                    this.$emit('inputAddress',addressWithOccupancy)
                    this.$emit('step',null)
                } else {
                    if(this.occupancyResource1.hasOwnProperty('id')) {
                        delete  this.occupancyResource1.id 
                        delete  this.occupancyResource2.id 
                    }

                    let occupancy = null

                    if(this.calculation['primary-applicant-id'] == this.entityId){
                        occupancy = await this.createOccupancy(this.occupancyResource1)
                    } else {
                        occupancy = await this.createOccupancy(this.occupancyResource2)
                    }

                    let addressResource = store.addressResource

                    if(addressResource.hasOwnProperty('id')){
                        delete addressResource.id
                    }

                    addressResource.relationships.occupancies = {data: [{id:occupancy.data.data.id, type:'occupancies'}]}

                    let address = await this.createAddress(addressResource)

                    let addressWithOccupancy = { address: address.data.data, occupancy: occupancy.data.data}

                    this.$emit('inputAddress',addressWithOccupancy)
                    this.$emit('step',null)
                }
                // this.$emit('step',null)
           }
            
        },
        async updateProperty(val) {
            this.form['residential-situation'] = val

            await this.update()
        },
        async saveProperty(val) {
            this.isSaving = true
            this.form['residential-situation'] = val

            await this.nextStep()
            this.isSaving = false
        },
        back() {
            this.$emit('back','date-move')
        },
        close(){
           this.$emit('back',null)
        },
        async update(){
            this.isSaving = true
            let addResource = store.addressResource
            addResource.id = this.address.address.id
            let occResource = this.address.occupancy
            
            let occAttr = {
                'residential-situation': this.form['residential-situation'],
                'occupancy-situation': this.occupancySituation,
            }
            mutations.setOccupancies(occAttr)
            // console.log('occcupancu ',store.occupancyAttributes)
            occResource.attributes = store.occupancyAttributes
            occResource.attributes['end-date'] = this.occupancySituation == "Current" ?  null : this.form['end-date']
            delete occResource.relationships.validations
            delete occResource.relationships.address
            let updateOccupancy = await this.updateOccupancy(occResource)
            let updateAddress = await this.updateAddress(addResource)
            let payload = {address: updateAddress.data.data, occupancy: updateOccupancy.data.data }
            this.$emit('updateTable',payload,this.index,updateOccupancy.data.data.attributes['start-date'])
            this.isSaving = false
            this.$emit('changeAllDate',updateOccupancy.data.data.attributes['start-date'])
            this.$emit('step',null)
        }
        
    },
    data() {
        return {
            isSaving: false,
            dialog :false,
            fields : [
                
            ],
            form:{
                "residential-situation": ''
            },
            items: [
                {
                    label: 'Own Outright',
                    value: 'Own Outright'
                },
                {
                    label: 'Mortgage Owner Occupied',
                    value: 'Mortgage - Owner Occupied'
                },
                {
                    label: 'Renter - Agent',
                    value: 'Renter - Agent'
                },
                {
                    label: 'Renter - Private',
                    value: 'Renter - Private'
                },
                {
                    label: 'Boarder',
                    value: 'Boarder'
                },
                {
                    label: 'Living with Parents',
                    value: 'Living With Parents'
                },
                {
                    label: 'Employer Provided',
                    value: 'Employer Provided'
                }
            ],
            resources: {
                addresses: {
                    type: 'addresses',
                        attributes: {
                            "street-number": null,
                            "street-name": null,
                            "street-type": null,
                            "suburb": null,
                            "state": null,
                            "postcode": null
                        },
                        relationships: {
                            application: {
                                data: { type: "applications", id: null } 
                            }
                        }
                    },
                    
            }
        }
    }
}
</script>
<style lang="scss" >
    
    .ol-residential-history-5-is-currently-living {
        .btn {
            text-align: center;
            padding: 50px;
            border-radius: 20px;
            font-size: 15px;
            border: 1px solid;
            cursor: pointer;
            width: 250px !important;
            height: 150px !important;
        }
        .btn:hover{
            background: #E7FAFC;
        }
        .custom-outline1{
            border-color: var(--v-secondary-base);
        }
        .close-icon {
            position: absolute;
            right: 40px;
            top: 30px;
            // background: var(--v-secondary-lighten2);
            color: var(--v-secondary-base)!important;
            padding: 6px;
            border-radius: 36px !important;
            i {
                color: var(--v-secondary-base) !important;
            }
        }
    }
</style>