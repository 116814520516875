<template>
    <v-dialog
        v-model="dialog"
        persistent
        scrollable
        width="844"
    >
        <v-card flat tile :class="vb.mdAndUp ? 'pa-8' : 'pa-2'" :disabled="processing">
            <v-progress-linear color="secondary" indeterminate stream absolute top v-if="processing"></v-progress-linear>

            <v-card-title class="pa-2 ma-0" :class="{'mt-n4': vb.mdAndUp}">
                <v-spacer />
                <v-icon @click="closeModal" size="20">
                    mdi-close
                </v-icon>
            </v-card-title>

            <v-card-text :class="vb.mdAndUp ? 'px-16' : ''">
                <v-stepper v-model="parentStepper" alt-labels>
                    <v-stepper-items>
                        <v-stepper-content class="pa-0" step="1">
                            <!-- stepper for assets -->
                            <v-stepper v-model="stepper" alt-labels>
                                <v-stepper-items>
                                    <v-stepper-content v-for="(step, index) in steps" :key="index" class="pa-0" :step="index">

                                        <template v-if="step.children && step.children.length > 0">
                                            <div class="text-center mb-5" :class="vb.mdAndUp ? 'title' : 'title-2 font-weight-bold'">Asset Type</div>
                                            <v-row
                                                :justify="step.children.length <= 3 ? 'center' : 'start'" 
                                                :style="step.children.length <= 3 && vb.mdAndUp ? 'width: 55%;' : 'width: 100%;'"
                                                align="center" class="rounded-lg light_gray_1 pa-2 ma-2 mx-auto">

                                                <v-col v-for="child in step.children" class="pa-2" :key="child.name"
                                                    cols="12" :md="step.children.length <= 3 ? '12' : '6'">
                                                    <!-- <v-card
                                                        flat
                                                        tile
                                                        outlined
                                                        :ripple="false"
                                                        :width="vb.mdAndUp ? 223 : ''"
                                                        :height="vb.mdAndUp ? 157 : ''"
                                                        @click="nextChild(child)"
                                                        class="pa-4 rounded-xl" :class="{'pt-16': vb.mdAndUp}">
                                                            <div class="py-auto my-auto body-1">
                                                                {{ child.name }}
                                                            </div>
                                                    </v-card> -->
                                                    <v-card
                                                        flat
                                                        height="100%" 
                                                        @click="nextChild(child)"
                                                        rounded="lg"
                                                        class="d-flex pa-4 align-center justify-center">
                                                        <div class="flex-options body-2 font-weight-medium">
                                                            {{ child.name }}
                                                        </div>
                                                        <v-spacer></v-spacer>
                                                        <v-btn color="secondary" :width="86" small depressed>
                                                            Select
                                                        </v-btn>
                                                    </v-card>
                                                </v-col>
                                            </v-row>
                                        </template>

                                        <ValidationObserver v-else :ref="`observer${index+1}`">
                                            <div v-if="step.toString().includes('role-selector')" class="text-center" :class="vb.mdAndUp ? 'title' : 'title-2 font-weight-bold'">Who owns this asset?</div>
                                            <div v-else-if="step.toString().includes('being-refinanced')" class="text-center" :class="vb.mdAndUp ? 'title' : 'title-2 font-weight-bold'">Do you have any finance secured by this asset?</div>
                                            <div v-else class="text-center" :class="vb.mdAndUp ? 'title' : 'title-2 font-weight-bold'">Asset Details</div>
                                            <div v-for="(field, key) in step" :key="key">
                                                <v-row class="justify-center align-center">
                                                    <v-col 
                                                        class="my-0 py-0"
                                                        v-for="(f, key) in field" 
                                                        :key="key"
                                                        cols="12"
                                                        :md="getFieldColWidth(f, field.length)"
                                                    >

                                                        <template v-if="f == 'role-selector'">
                                                            <ValidationProvider 
                                                                v-if="isVisible(f)"
                                                                :hidden="isHidden(f)"
                                                                v-slot="{ errors }" 
                                                                name="Role" 
                                                                rules="required">

                                                                <!-- superannuation only one role must be selected -->
                                                                <RoleSelector2
                                                                    v-if="kindsAssetId == '10-12'"
                                                                    v-model="roleSelected2"
                                                                    :parts="entityParts"
                                                                    :errors="errors"
                                                                    @input="selectRole"
                                                                    :data="filterEntities" />

                                                                <RoleSelector
                                                                    v-else
                                                                    v-model="roleSelected"
                                                                    :parts="entityParts"
                                                                    :errors="errors"
                                                                    @input="selectRole"
                                                                    :data="filterEntities" />

                                                            </ValidationProvider>
                                                        </template>

                                                        <template v-else>
                                                            <ValidationProvider 
                                                                v-if="isVisible(f)"
                                                                :hidden="isHidden(f)"
                                                                v-slot="{ errors }" 
                                                                :name="labels[f]" 
                                                                :rules="getRules(f)">

                                                                <PickBox
                                                                    v-if="f == 'being-refinanced'"
                                                                    :options="['Yes', 'No']"
                                                                    v-model="resource.assets.attributes[f]"
                                                                    @input="selectRefinance"
                                                                    :errors="errors" />

                                                                <AddressTool 
                                                                    v-else-if="isAddressField(f)"
                                                                    :bus="Date.now()"
                                                                    v-model="selectedAddress"
                                                                    :label="labels[f]"
                                                                />

                                                                <v-select
                                                                    v-else-if="isFieldType('picklist', f)"
                                                                    :items="getOptions(f)"
                                                                    :label="labels[f]"
                                                                    :error-messages="errors"
                                                                    v-model="resource.assets.attributes[f]"
                                                                    color="secondary">
                                                                </v-select>

                                                                <v-textarea 
                                                                    v-else-if="isFieldType('textarea', f)"
                                                                    :label="labels[f]"
                                                                    rows="10"
                                                                    :error-messages="errors"
                                                                    v-model="resource.assets.attributes[f]"
                                                                    color="secondary">
                                                                </v-textarea>

                                                                <template v-else>
                                                                    <NodifiMoney
                                                                        v-if="isFieldType('money', f)"
                                                                        v-model="resource.assets.attributes[f]"
                                                                        :label="labels[f]"
                                                                        :errors="errors" />
                                                                    <v-text-field
                                                                        v-else
                                                                        v-model="resource.assets.attributes[f]"
                                                                        :inputmode="f == 'year-of-manufacture' ? 'numeric' : 'search'"
                                                                        :label="labels[f]"
                                                                        :error-messages="errors">
                                                                    </v-text-field>
                                                                </template>

                                                            </ValidationProvider>
                                                        </template>
                                                        
                                                    </v-col>
                                                </v-row>
                                            </div>
                                        </ValidationObserver>

                                    </v-stepper-content>
                                </v-stepper-items>
                            </v-stepper>

                            <div class="d-flex justify-center py-2 my-2">
                                <v-btn
                                    large
                                    min-width="125" 
                                    max-height="50"
                                    color="secondary"
                                    class="mx-1"
                                    @click="back"
                                    outlined> Back
                                </v-btn>
                                <v-btn 
                                    v-if="hideNext"
                                    large
                                    min-width="125" 
                                    max-height="50"
                                    class="mx-1"
                                    color="secondary" 
                                    @click="next" 
                                    depressed> Next 
                                </v-btn>
                            </div>

                        </v-stepper-content>

                        <v-stepper-content class="pa-0" step="2">
                            <LiabilityExpenseStepper
                                :fo-resources="resource"
                                :standalone="standalone"
                                :selected-address="selectedAddress"
                                @save-and-close="closeModal"
                                @update-and-close="closeModal"
                                @back-to-parent="backToParent" />
                        </v-stepper-content>
                    </v-stepper-items>
                </v-stepper>
            </v-card-text>
            
        </v-card>
    </v-dialog>
</template>
<script>
import shared from '~/components/v1/AssetEditor1/__shared'
import { actions, store } from '~/components/v1/AssetEditor1/__store'
import formHelpers from '~/components/shared/form-helpers'
import ResourceHelpers from '~/components/shared/resource-helpers'
import validation from '~/components/shared/validation'
import RoleSelector from '~/components/v1/AssetEditor1/_AssetEditor1RoleSelector'
import RoleSelector2 from '~/components/v1/AssetEditor1/_AssetEditor1RoleSelector2'
import AddressTool from '~/components/v1/AssetEditor1/_AddressTool3'
import PickBox from '~/components/v1/AssetEditor1/_AssetEditor1PickBox'
import LiabilityExpenseStepper from '~/components/v1/AssetEditor1/_AssetEditor1LiabilityExpenseStepper'
import fieldOptions from '~/components/shared/field-options'
import { mapActions } from 'vuex'

export default {
    components: { RoleSelector, RoleSelector2, AddressTool, PickBox, LiabilityExpenseStepper },
    mixins: [ validation, formHelpers, shared, ResourceHelpers ],
    props: {
        dialog: {
            type: Boolean,
            default: false
        },
        fieldsData: {
            type: Object,
            default: () => {}
        }
    },
    computed: {
        hideNext() {
            if(this.isLastStep && this.currentStepValue && this.currentStepValue[0] && this.currentStepValue[0][0] == 'being-refinanced') {
                return false
            } else if(this.currentStep?.children && this.currentStep.children.length > 0) return false
            else return true
        },
        financialInstitution(){
            return this.resource.assets.attributes['financial-institution']
        },
        refinance(){
            return this.resource.assets.attributes['being-refinanced']
        },
        processing() {
            return store.saving
        }
    },
    async created() {
        if(this.steps[0].children && this.steps[0].children.length == 1) {
            await this.nextChild(this.steps[0].children[0])
        }
    },
    async mounted() {
        await this.initializeFields()
    },
    data() {
        return {
            standalone: false,
            parentStepper: 1,
            roleSelected: [],
            roleSelected2: '',
            selectedAddress: null,
            visible: {
                "other-institution": false
            },
            hidden: {},
            resource: {
                'assets': {
                    type: 'assets',
                    attributes: {
                        "asset-description": null,
                        "asset-value": null,
                        "bank-account-name": null,
                        "bank-account-number": null,
                        "bsb-number": null,
                        "being-refinanced": null,
                        "being-sold-traded": null,
                        "covered-by-insurance": null,
                        "description-other": null,
                        "financial-institution": null,
                        "make": null,
                        "model": null,
                        "other-institution": null,
                        "year-of-manufacture": null,
                        "__full-address": null,
                    }
                }
            },
            labels: {
                "asset-description": "Asset Description",
                "asset-value": "Approximate Value of Asset",
                "bank-account-name": null,
                "bank-account-number": null,
                "bsb-number": null,
                "being-refinanced": "Finance secured",
                "being-sold-traded": null,
                "covered-by-insurance": "Do you have finance secured by this asset?",
                "description-other": "Other Description",
                "financial-institution": "Name of Financial Institution",
                "make": "Make",
                "model": "Model",
                "other-institution": "Other Institution",
                "year-of-manufacture": "Year",
                "__full-address": "Address of Asset"
            },
            validation_rules: {
                attributes: {
                    "asset-description": "required|string",
                    "asset-value": "required|between:0,99999999.99",
                    "bank-account-name": "required|string",
                    "bank-account-number": "required|integer",
                    "bsb-number": "required|integer",
                    "being-refinanced": "required|string|in:Yes,No",
                    "being-sold-traded": "required|string|in:Yes,No",
                    "covered-by-insurance": "required|in:Yes,No",
                    "description-other": "required|string|max:32768",
                    "financial-institution": `required|in:${fieldOptions["financial-institution"]}`,
                    "make": "required|string",
                    "model": "required|string",
                    "other-institution": "",
                    "year-of-manufacture": "required|integer|digits_between:4,4",
                    "__full-address": 'required'
                }
            }
        }  
    },
    methods: {
        ...mapActions('resource-actions', [
            'createAsset',
            'createAddress',
            'updateAsset',
            'getAsset',
            'updateAddress'
        ]),
        async nextChild(child) {
            await actions.nextStep(child)

            if(child.children.length === 0) {

                await this.getAssetFieldLayout(child)
               
            }
        },
        async next() {

            // find observer of current step
            let ob = Object.keys(this.$refs).filter((ob) => ob == `observer${this.stepper+1}`)

            const valid = await this.$refs[ob[0]][0].validate()

            if(valid) {
                if(this.isLastStep) {
                    if(this.refinance == 'Yes') {
                        this.parentStepper = 2
                    } else {
                        await this.save()
                        await this.closeModal()
                    }
                } else {
                    actions.selectNextStep()
                }
            }

        },
        selectRefinance(data) {
            store.saving = true
            this.resource.assets.attributes['being-refinanced'] = data

            if(this.refinance) {
                setTimeout(() => {
                    this.next()
                    store.saving = false
                }, 100);
            }

        },
        selectRole(data) {
            if(typeof data == 'string') {
                this.preSelectRoles = [data]
            } else {
                this.preSelectRoles = data
            }
        },
        async save() {

            const { selectedAddress } = this

            store.saving = true

            let payload = {
                type: 'assets',
                attributes: this.excludeInvalidAttributes(this.resource.assets.attributes),
                relationships: {
                    application: {
                        data: {type: "applications", id: this.appId}
                    },
                    people: this.getPeopleRelationship('assets'),
                    businesses: this.getBusinessesRelationship('assets'),
                    kind: {
                        data: {
                            type: 'kinds',
                            id: this.kindsAssetId
                        }
                    }
                }
            }

            if(selectedAddress != null){
                    
                if(selectedAddress.id){

                    payload.relationships.address = {
                        data: {
                            type: 'addresses',
                            id: selectedAddress.id 
                        }
                    }

                    if(selectedAddress?.relationships)
                        selectedAddress.relationships = this.filterRelationships(selectedAddress.relationships)

                    await this.updateAddress(selectedAddress)

                } else {

                    let relationships = {
                        application: {
                            data: {type: "applications", id: this.appId}
                        }
                    }

                    this.selectedAddress.relationships = relationships

                    delete this.selectedAddress.id

                    let address = await this.createAddress(selectedAddress)

                    payload.relationships.address = {
                        data: {
                            type: 'addresses',
                            id: address.data.data.id
                        }
                    }

                }

            }

            let res = null

            if(this.resource.assets?.id) {

                payload.id = this.resource.assets.id

                // delete expenses and liabilities if no is selected
                if(this.resource.assets.relationships.liabilities.data.length > 0) {
                    const emptyRecords = {
                        data: [],
                        // Default Strategy: attach
                        meta: {
                            strategy: 'replace'  
                        }
                    }
                    payload.relationships.expenses = emptyRecords
                    payload.relationships.liabilities = emptyRecords
                }

                res = await this.updateAsset(payload)

            } else {

                res = await this.createAsset(payload)

            }

            if(res.status === 201) {

                actions.updateResources(res.data.data)
                this.updateEntitiesAndParts()

                this.$emit('save-asset', res.data.data)
                this.closeModal()

            } else if(res.status === 200) {

                actions.updateResources(res.data.data)
                this.updateEntitiesAndParts()

                this.$emit('update-asset', res.data.data)
                this.closeModal()
                
            } else {
                console.log("error....")
            }

            this.filterAssetsData(this.selectedEntity)

        },
        back() {
            if(this.isFirstStep)
                this.closeModal()
            else
                actions.prevStep()
        },
        backToParent() {
            this.parentStepper = 1
        },
        closeModal() {
            this.$emit('close-modal')
            this.resetFields()
            actions.resetSteps()
            actions.setSelectedIndex(null)
            store.saving = false
            this.preSelectRoles = []
        },
        resetFields() {
            this.resource =  {
                'assets': {
                    type: 'assets',
                    attributes: {
                        "asset-description": null,
                        "asset-value": null,
                        "bank-account-name": null,
                        "bank-account-number": null,
                        "bsb-number": null,
                        "being-refinanced": null,
                        "being-sold-traded": null,
                        "covered-by-insurance": null,
                        "description-other": null,
                        "financial-institution": null,
                        "make": null,
                        "model": null,
                        "other-institution": null,
                        "year-of-manufacture": null,
                        "__full-address": null,
                    }
                },
                'liabilitites': {},
                'expenses': {},
            }

            this.standalone = false
        },
        initializeFields() {

            let roles = []

            if(Object.keys(this.fieldsData).length > 0) {

                this.standalone = true
                
                this.resource.assets.id = this.fieldsData.id
                this.resource.assets.attributes = this.fieldsData.attributes
                this.resource.assets.relationships = this.fieldsData.relationships

                // Include place holder fields
                this.resource.assets.attributes['__full-address'] = null

                let addressData = this.fieldsData.relationships.address.data

                if(addressData) {

                    this.addresses.forEach(address => {

                        if(address.id == addressData.id){
                            this.selectedAddress = address
                        }

                    })

                }

                // get people role
                if(this.fieldsData.relationships.people.data.length > 0) {
                    this.fieldsData.relationships.people.data.forEach(person => {

                        if(!roles.includes(person.id)){
                            roles.push(person.id)
                        }

                    })
                }

                // get business role
                if(this.fieldsData.relationships.businesses.data.length > 0) {
                    this.fieldsData.relationships.businesses.data.forEach(business => {

                        if(!roles.includes(business.id)){
                            roles.push(business.id)
                        }

                    })
                }

                if(roles.length > 0) {
                    roles.forEach(role => {
                        if(!this.preSelectRoles.includes(role)) {
                            this.preSelectRoles.push(role)
                        }
                    })
                }

                // get assets of incomes
                let expensesRelated = this.fieldsData.relationships.expenses.data

                if(expensesRelated.length > 0) {
                    let expenseRecord = null

                    // get expense record related to asset
                    if(expensesRelated.length > 0) {
                        this.expenses.forEach(expense => {
                            if(expense.id == expensesRelated[0].id) {
                                this.resource.expenses = expense
                                expenseRecord = expense
                            }
                        })
                    }

                    // get role selected on expenses, liabilities
                    let roles = []

                    expenseRecord.relationships.people.data.forEach(person => {
                        roles.push(person.id)
                    })

                    if(roles.length > 0) {
                        actions.setPreSelectLinkRoles(roles)
                    }
                    
                    let liabilitiesRelated = expenseRecord.relationships.liabilities.data
                    // get liabilities related to expenses
                    if(liabilitiesRelated.length > 0) {
                        this.liabilities.forEach(liability => {
                            if(liability.id == liabilitiesRelated[0].id) {
                                this.resource.liabilities = liability
                            }
                        })
                    }

                }

            }

        }
    },
    watch: {
        stepper(val) {
            actions.setCurrentStepValue(this.steps[val])
        },
        preSelectRoles(val) {
            this.roles = val

            if(this.kindsAssetId == '10-12') {
                this.roleSelected2 = val[0]
            } else {
                this.roleSelected = val
            }
        },
        financialInstitution(val){
            this.resource.assets.attributes['financial-institution'] = val

            if(val == 'Other'){
                this.visible["other-institution"] = true
                this.validation_rules.attributes['other-institution'] = 'required'
            } else {
                this.visible["other-institution"] = false
                this.validation_rules.attributes['other-institution'] = ''
            }

        },
        // steps(val) {
        //     console.log("steps", val.length)
        // }
    }
}
</script>
