<template>
    <v-img
        class="mx-auto"
        :lazy-src="imagePath"
        :src="imagePath"
        position="center center" 
        contain
        max-height="100"
        max-width="160"/>   
</template>

<script>

    import { mapGetters, mapMutations, mapActions, mapState } from 'vuex';

    export default {
        data() {
            return {
                
            }
        },
        mounted(){
            // console.log(this.settings)
        },
        computed: {
            ...mapGetters('slug', [
                'getTenant',
            ]),
            ...mapGetters([
                'getCRMTenant'
            ]),
            isLoanMarket(){
                return this.getTenant == "loanmarket"
            },
            imagePath(){

                let img = require(`~/assets/images/nodifi.svg`)

                // console.log(this.getTenant)
                
                if(this.isLoanMarket) {
                    img = require(`~/assets/images/lmgaf.svg`)
                }

                return img
            }
        },
        methods: {
      
        }
    }
</script>


<style lang="scss" scoped>


</style>