<template>
    <div>
        <div class="mb-2 mb-md-8">
            <div class="d-flex align-center">
                <div>

                    <h1 class="flex__step-header-title line-height-32 pb-4" v-if="vb.mdAndUp">Your top{{ data.length == 3 ? " three" : ' '}} loan matches</h1>
                    <h1 class="font-size-14 font-weight-medium" v-else>Your top{{ data.length == 3 ? " three" : ' '}} loan matches</h1>

                    <p v-if="vb.mdAndUp">How does your choice compare to other lenders? Hit the <span class="font-weight-medium">'Compare Lenders'</span> button to find out. </p>
                </div>
                <v-spacer></v-spacer>

                <div class="d-flex align-center">
                    <div class="body-2 font-weight-medium pr-4" v-if="vb.mdAndUp">Repayments Term</div>
                    <div :style="`max-width: ${vb.mdAndUp ? '200px' : '150px'}`">
                        <v-select
                            class="pt-4 ma-0"
                            v-model="repaymentsTermField"
                            :items="['Monthly','Fortnightly','Weekly','Annual']"
                            color="secondary"
                        ></v-select>
                    </div>
                </div>

            </div>
        </div>

        <v-sheet
            class="mx-auto mb-8 mb-md-16"
            elevation="0">

            <div class="d-flex" v-if="vb.width >= 1180">
                <template v-for="(lender, index) in data" >
                    <Card1
                        :key="lender.name"
                        @show-info="showInfoDialog" 
                        @show-apply="showApplyModal" 
                        :hide-lender="hideLender"
                        :lender="lender"
                        :index="index"
                        />
                    <!-- spacer -->
                    <v-spacer style="min-width: 40px" v-if="index < (data.length - 1)"></v-spacer>
                </template>
            </div>

            <div v-else >

                <template>    
                    <v-tabs v-model="selectedLender" show-arrows height="76" class="mb-2">
                        <v-tab v-for="(lender, index) in data" :key="lender.name" >
                            <v-list-item :class="vb.mdAndUp ? 'px-4' : 'px-0'">
                                <v-list-item-content class="text-center">
                                    <v-list-item-title :class="['mb-1', {'font-size-12': !vb.mdAndUp}]">
                                        Option {{ parseInt(index) + 1 }}
                                    </v-list-item-title> 
                                    <v-list-item-subtitle class="mb-2">
                                         <div v-if="hideLender" class="font-weight-bold">
                                            {{ lender['alias'] }}
                                        </div>
                                        <v-img 
                                            v-else 
                                            max-height="20"
                                            :max-width="vb.mdAndUp ? 100 : 82"
                                            class="flex-brand" 
                                            :src="getLenderLogo(lender['logo-class'])" 
                                            position="center center" 
                                            contain>
                                        </v-img>
                                    </v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                        </v-tab>
                    </v-tabs>
                </template>

                <v-tabs-items v-model="selectedLender">
                    <v-tab-item eager v-for="(lender, index) in data" :key="lender.name">
                        <Card2
                            :key="lender.name"
                            @show-info="showInfoDialog" 
                            @show-apply="showApplyModal" 
                            :hide-lender="hideLender"
                            :lender="lender"
                            :index="index"
                            max-width="100%"
                            />
                    </v-tab-item>
                </v-tabs-items>
            </div>
        </v-sheet>

    </div>
</template>

<script>

    import { mapActions, mapGetters, mapState, mapMutations } from 'vuex'
    import mixins from '~/components/v1/QuoteResult6/_mixins'
    import Card1 from '~/components/v1/QuoteResult6/_Card1'
    import Card2 from '~/components/v1/QuoteResult6/_Card2'
    import {store, actions} from '~/components/v1/QuoteResult6/__store'

    export default {
        mixins: [
            mixins
        ],
        components:{
            Card1,
            Card2
        },
        props: {
            data: {
                type: Array,
                default: () => {[]}
            },
            hideLender: {
                type: Boolean,
                default: false
            }
        },
        data() {
            return {
                selectedLender: 0
            }
        },
        mounted(){

        },
        computed: {
            repaymentsTermField: {
                get() {
                    return store.repaymentsTermField
                },
                set(val) {
                    actions.setRepaymentsTermField(val)
                }
            }
        },
        methods: {
            showInfoDialog(lender){
                this.$emit('show-info', lender)
            },
            showApplyModal(lender){
                this.$emit('show-apply', lender)
            }
    
        },
        watch: {
   
        }
    }
</script>

<style lang="scss">

    .flex__component-QuoteResult6 {

        .loan-details-section {
            .bl-spacer {
                border-right: 1px solid var(--v-light_gray_1-base);
            }
        }

    }
  
</style> 