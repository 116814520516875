<template>
  <div v-if="! isEmbedded">
    <slot />
  </div>
</template>

<script>
import { hasEmbedData } from '../../middleware/embed';

export default {
  props: {
    when: {
      type: Function,
      required: false,
      default: () => () => true
    },
    isApplicationFlow: {
      type: Boolean,
      default: false,
    }
  },
  computed: {
    isEmbedded: function () {
      return hasEmbedData() && this.when() && !this.isApplicationFlow;
    },
  },
};
</script>
