<template>
    <v-dialog
        v-model="dialog"
        persistent
        width="500">
        <template v-slot:activator="{ on, attrs }">
            <v-btn
                v-if="buttonType === 'icon'"
                :color="color"
                dark
                :large="large"
                :small="small"
                :x-small="xSmall"
                :text="$vuetify.breakpoint.xsOnly === false"
                fab rounded
                :class="{'ml-1': $vuetify.breakpoint.xsOnly, 'ma-0': $vuetify.breakpoint.smAndUp}"
                v-bind="attrs"
                depressed
                v-on="on">
                <v-icon size="20">mdi-delete</v-icon>
                {{ buttonLabel }}
            </v-btn>
            <template v-else>
                <v-btn 
                    v-if="outlined"
                    depressed large
                    :disabled="disabled"
                    outlined
                    color="grey"
                    v-on="on"
                    :style="`${fixedButton ? 'min-width:140px;' : ''}`">
                    {{ buttonLabel }}
                </v-btn>
                <v-btn 
                    v-else
                    depressed large
                    :disabled="disabled"
                    :outlined="outlined"
                    :class="`${disabled ? 'light_gray_1 darken-1' : 'secondary'}`"
                    :color="color"
                    v-on="on"
                    :style="`${fixedButton ? 'min-width:140px;' : ''}`">
                    {{ buttonLabel }}
                </v-btn>
            </template>
            
        </template>

        <v-card>
           <v-card-title class="px-8 pt-8">
                <div class="v-card__title sm pa-0">
                    {{ title }}
                </div>
            </v-card-title>

            <v-card-text class="px-8 pt-2 pb-8 body-1" v-html="message"></v-card-text>

            <v-card-actions class="px-8 pb-8">
                <v-spacer></v-spacer>
                <v-btn
                    color="light_gray_2"
                    outlined
                    large depressed
                    @click="dialog = false">
                    Cancel
                </v-btn>
                <v-btn
                    :color="confirmColor"
                    large depressed
                    @click="confirmDelete">
                    {{confirmText}}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
    export default {
        name: 'ConfirmationModal',

        props: {
            action: {
                type: String,
                default: 'delete' // CRUD values
            },
            buttonLabel: {
                type: String,
                default: ''
            },
            buttonType: {
                type: String,
                default: '' // icon OR text
            },
            color: {
                type: String,
                default: 'light_gray_2'
            },
            confirmColor: {
                type: String,
                default: 'secondary'
            },
            id: {
                type: String,
                default: ''
            },
            title: {
                type: String,
                default: 'Confirm Action'
            },
            message: {
                type: String,
                default: 'Are you sure?'
            },
            buttonSize: {
                type: String,
                default: 'v-size--large'
            },
            disabled: {
                type: Boolean,
                default: false
            },
            outlined: {
                type: Boolean,
                default: false
            },
            large: {
                type: Boolean,
                default: false
            },
            small: {
                type: Boolean,
                default: false
            },
            xSmall: {
                type: Boolean,
                default: false
            },
            rounded: {
                type: Boolean,
                default: false
            },
            confirmText: {
                type: String,
                default: 'Confirm'
            },
            fixedButton: {
                type: Boolean,
                default: true
            }
        },

        methods: {
            confirmDelete() {
                this.$emit('confirmed-action', this.id)
                this.dialog = false
            }
        },

        data() {
            return {
                dialog: false
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>