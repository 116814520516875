<template>
    <div v-if="vb.mdAndUp" class="d-flex" style="width: 100%">
        <v-sheet color="light_gray_1" class="py-8 px-12" width="50%" outlined elevation="0">
            <v-img 
                max-width="120"
                class="flex-brand" 
                :src="require(`~/assets/images/nodifi.svg`)" 
                position="center left" 
                contain>
            </v-img>
            <div class="mt-2 mb-6 font-size-20">Data</div>

            <v-list color="transparent">

                <v-list-item two-line class="px-0">
                    <v-list-item-content>
                        <v-list-item-title class="font-weight-medium">Net Monthly Income</v-list-item-title>
                        <v-list-item-subtitle>{{resources.income.attributes['net-monthly-amount'] ? resources.income.attributes['net-monthly-amount'] : '&mdash;'}}</v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>
       
            </v-list>

        </v-sheet>
        <v-sheet class="py-8 px-12" width="50%">

            <v-img 
                max-width="120"
                class="flex-brand" 
                :src="require(`~/assets/images/logo/cropped/lender-logo-society.png`)" 
                position="center left" 
                contain>
            </v-img>
            <div class="mt-2 mb-8 font-size-20">Requirements</div>

            <ValidationObserver ref="observer" tag="form">
                <div v-for="(field, key) in resources.income.attributes" :key="key">
                    <ValidationProvider 
                            v-if="visible[key]"
                            v-slot="{ errors }" 
                            :name="labels[key]"
                            :rules="getRules(key)" 
                            :vid="key">
                                <NodifiMoney
                                    v-model="resources.income.attributes[key]"
                                    :label="labels[key]"
                                    :errors="errors" />
                    </ValidationProvider>
                </div>
            </ValidationObserver>

        </v-sheet>
    </div>

    <!-- mobile version -->
    <div v-else>

        <v-card flat class="mx-5 rounded-xl" :style="`background-color: ${hexToRGBA(getActiveTheme.colors.secondary, 0.2)}`">

            <v-card-text class="d-flex py-1">
                <v-row>
                    <v-col cols="6" class="pa-1">
                        <v-card
                        min-height="45"
                            flat class="rounded-xl px-1"
                            :color="tabSelection == 'nodifi' ? 'white' : 'transparent'">
                            <div class="d-flex justify-center pt-1" @click="selectTab('nodifi')">
                                <v-img 
                                    max-width="55"
                                    class="flex-brand" 
                                    :src="require(`~/assets/images/nodifi.svg`)" 
                                    position="center left" 
                                    contain>
                                </v-img>
                                <div class="mt-3 font-size-12 pl-1">Data</div>
                            </div>
                        </v-card>
                    </v-col>
                    <v-col cols="6" class="pa-1">
                        <v-card
                            flat class="rounded-xl px-1"
                            :color="tabSelection == 'society' ? 'white' : 'transparent'">
                            <div class="d-flex flex-column" @click="selectTab('society')">
                                <v-img 
                                    max-width="45"
                                    class="flex-brand mx-auto mt-n1" 
                                    :src="require(`~/assets/images/logo/cropped/lender-logo-society.png`)" 
                                    position="center left" 
                                    contain>
                                </v-img>
                                <div class="font-size-12 pl-1 mx-auto mt-n3">Requirements</div>
                            </div>
                        </v-card>
                    </v-col>
                </v-row>
            </v-card-text>
            
        </v-card>

        <v-list v-show="tabSelection == 'nodifi'" class="mx-8" color="transparent">

            <v-list-item two-line class="px-0">
                <v-list-item-content>
                    <v-list-item-title class="font-weight-medium">Net Monthly Income</v-list-item-title>
                    <v-list-item-subtitle>{{resources.income.attributes['net-monthly-amount'] ? resources.income.attributes['net-monthly-amount'] : '&mdash;'}}</v-list-item-subtitle>
                </v-list-item-content>
            </v-list-item>

        </v-list>

        <div v-show="tabSelection != 'nodifi'" class="mx-8">
            <ValidationObserver ref="observer" tag="form">
                <div v-for="(field, key) in resources.income.attributes" :key="key">
                    <ValidationProvider 
                            v-if="visible[key]"
                            v-slot="{ errors }" 
                            :name="labels[key]"
                            :rules="getRules(key)" 
                            :vid="key">
                                <NodifiMoney
                                    v-model="resources.income.attributes[key]"
                                    :label="labels[key]"
                                    :errors="errors" />
                    </ValidationProvider>
                </div>
            </ValidationObserver>
        </div>

    </div>
</template>
<script>
import subscriptions from '~/components/shared/subscriptions'
import shared from '~/components/v1/submission/LenderSocietyOnePeople1/__shared'

import { mapActions } from 'vuex'

export default {
    mixins: [ subscriptions, shared ],
    props: {
        data: {
            type: Object,
            default: null
        },
        updater: undefined
    },
    data() {
        return {
            tabSelection: 'nodifi',
            resources: {
                income: {
                    id: null,
                    type: "incomes",
                    attributes: {
                        "gross-annual-income" : null,
                        "net-monthly-amount": null
                    }
                }
            },
            labels: {
                "gross-annual-income": "Gross Annual Income"
            },
            visible: {
                "gross-annual-income": true,
                "net-monthly-amount": false
            },
            validation_rules: {
                attributes: {
                    "gross-annual-income": "required",
                    "net-monthly-amount": ""
                }
            },
        }
    },
    computed: {
        vb() {
            return this.$vuetify.breakpoint
        }
    },
    async mounted() {
        if(this.data) {
            await this.updateResourceFields()
        }
    },
    methods: {
        ...mapActions('resource-actions', [
            'getIncome',
        ]),
        selectTab(tab) {
            this.tabSelection = tab
        },
        async validate(){

            const valid = await this.$refs.observer.validate()

            if(valid) {

                this.$emit('submit', this.resources)

            }

        },
        reset(){

            this.$refs.observer.reset()

        },
        async updateResourceFields(){

            this.$emit('loading', true)

            const {data} = this

            if(data) {
                let promise = this.getIncome(data.id)

                const res = await Promise.all([
                    promise, 
                ]) 
                promise = res[0]

                this.resources.income = this.filterResourceFields(this.resources.income, promise.data.data)

                this.$emit('loading', false)

            } else {

                this.$emit('loading', false)
            }

        }
    },
    watch: {
        updater(val) {
            if(val) {
                this.updateResourceFields()
            }
        },
    }
}
</script>
