<template>
  <v-dialog v-model="dialog" scrollable width="900" persistent>
    <template v-slot:activator="{ on, attrs }">
      <div style="position: relative" class="mx-2">
        <v-btn
          depressed
          large
          :disabled="disableCPDDButton"
          :loading="syncLoading"
          :class="cpddSent ? 'success' : 'secondary'"
          v-bind="attrs"
          v-on="on"
        >
          <div v-if="$vuetify.breakpoint.smAndUp">
            {{ cpddSent ? "Completed" : "CPDD Generation" }}
          </div>
          <div v-else>CPD</div>
        </v-btn>

        <v-tooltip v-if="privacyButtonTooltip" bottom max-width="300">
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              class="warning--text"
              style="cursor: pointer; position: absolute; left: -8px"
              size="20"
              v-on="on"
            >
              mdi-information
            </v-icon>
          </template>
          <span>{{ privacyButtonTooltip }}</span>
        </v-tooltip>
      </div>
    </template>

    <v-card class="overflow-y-auto" flat>
      <v-progress-linear
        v-if="pageLoader"
        color="secondary"
        indeterminate
        stream
        top
        value="0"
      >
      </v-progress-linear>

      <v-overlay absolute :opacity="0.1" v-if="pageLoader"> </v-overlay>

      <v-card-title>
        <v-spacer />
        <v-btn icon @click="close">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text class="pa-0">
        <v-card width="600" tile flat class="rounded-lg mx-auto">
          <v-card-title class="pb-5"> Generate CPD Document </v-card-title>
          <v-card-text>
            <ValidationObserver ref="observer" tag="form">
              <v-stepper :value="activeStep" alt-labels>
                <v-stepper-items>
                  <v-stepper-content :step="1">
                    <ValidationProvider
                      v-slot="{ errors }"
                      name="Does the client fit the Target Market Determination for the product?"
                      :rules="getRules('tmd_client_fit')"
                      vid="tmd_client_fit"
                    >
                      <label for="tmd_client_fit">
                        <div class="font-weight-bold">
                          Does the client fit the
                          <a
                            href="https://nodifi.box.com/s/yxwbuombboifh3hc08tvx5lb6br3boc7"
                            target="_blank"
                            >Target Market Determination</a
                          >
                          for the product?
                        </div>
                      </label>
                      <ReferralPartner
                        v-model="form['tmd_client_fit']"
                        :error-messages="errors"
                        :label="''"
                      >
                      </ReferralPartner>
                    </ValidationProvider>
                  </v-stepper-content>
                  <v-stepper-content :step="2">
                    <ValidationProvider
                      v-slot="{ errors }"
                      name="Why have you recommended this product to the client"
                      :rules="getRules('tmd_client_recommendation_reason')"
                      vid="tmd_client_recommendation_reason"
                    >
                      <v-select
                        v-model="form['tmd_client_recommendation_reason']"
                        :items="tmdPickList"
                        item-text="name"
                        item-value="value"
                        label="Why have you recommended this product to the client"
                        :error-messages="errors"
                      >
                      </v-select>
                    </ValidationProvider>

                    <ValidationProvider
                      v-if="form['tmd_client_recommendation_reason'] == 'Other'"
                      v-slot="{ errors }"
                      name="Your answer"
                      :rules="
                        getRules('tmd_client_recommendation_reason_other')
                      "
                      vid="tmd_client_recommendation_reason_other"
                    >
                      <v-textarea
                        label="Please outline how the customer does not meet the Target Market?"
                        :error-messages="errors"
                        v-model="form['tmd_client_recommendation_reason_other']"
                      ></v-textarea>
                    </ValidationProvider>

                    <v-alert dense type="info" text>
                      <div class="font-size-12">
                        Please note: Under broker Design and Distribution
                        Obligations (RG 274) please ensure that you carefully
                        consider your client and the product target market when
                        answering this question. If you indicate that the client
                        does not meet the target market, we will provide this
                        reporting directly to the lender in line with our
                        distributor obligations under RG 274.
                      </div>
                    </v-alert>
                  </v-stepper-content>
                  <v-stepper-content :step="3">
                    <ValidationProvider
                      v-slot="{ errors }"
                      name="Referrer Payment"
                      :rules="getRules('referral_payment')"
                      vid="referral_payment"
                    >
                      <ReferralPartner
                        v-model="form['referral_payment']"
                        :error-messages="errors"
                        label="Will you be paying a referral partner for this deal?"
                      />
                    </ValidationProvider>

                    <ValidationProvider
                      v-slot="{ errors }"
                      name="Referrer Name"
                      :rules="getRules('referral_partner_name')"
                      vid="referral_partner_name"
                    >
                      <v-text-field
                        v-model="form['referral_partner_name']"
                        label="Referrer Name"
                        :error-messages="errors"
                      >
                      </v-text-field>
                    </ValidationProvider>

                    <ValidationProvider
                      v-slot="{ errors }"
                      name="Referral Fee Estimate"
                      :rules="getRules('referral_fee_estimate')"
                      vid="referral_fee_estimate"
                    >
                      <NodifiMoney
                        v-model="form['referral_fee_estimate']"
                        label="Referral Fee Estimate"
                        :errors="errors"
                      />
                    </ValidationProvider>
                  </v-stepper-content>
                </v-stepper-items>
              </v-stepper>
            </ValidationObserver>
          </v-card-text>
        </v-card>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions class="pa-6 pa-md-8 flex-wrap controls">
        <v-btn
          outlined
          color="grey lighten-1"
          large
          style="min-width: 140px"
          @click="back"
        >
          Back
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn
          v-if="activeStep !== 1"
          color="secondary"
          depressed
          large
          style="min-width: 140px"
          @click="saveData"
        >
          Download PDF
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import ReferralPartner from "~/components/base/ButtonSelections";
import formHelpers from "~/components/shared/form-helpers";
import validation from "~/components/shared/validation";
import helpers from "~/plugins/helpers";
import shared from "./__shared";
import { mapState, mapActions, mapGetters, mapMutations } from "vuex";

export default {
  name: "QuoteToolGenerateSocaDialog",

  mixins: [helpers, validation, formHelpers, shared],

  props: {
    selectedLender: {
      type: String,
      default: undefined,
    },
    syncLoading: {
      type: Boolean,
      default: false,
    },
    cppdResources: {
      type: Array,
      default: () => [],
    },
  },

  components: {
    ReferralPartner,
  },

  computed: {
    ...mapState("privacy-disclosure", ["privacyButtonTooltip"]),
    ...mapState({
      appGuid: (state) => state.flows.appGuid,
      calculation: (state) => state.flows.calculation,
    }),
    tmdClientFit() {
      return this.form["tmd_client_fit"];
    },
    referralPayment() {
      return this.form["referral_payment"];
    },
    currentCPPDResource() {
      let { cppdResources } = this;
      let current = null;
      let stopper = false;

      if (cppdResources.length > 0) {
        cppdResources.forEach((cppd) => {
          if (cppd.status === "Current" && !stopper) {
            current = cppd;
            stopper = true;
          }
        });
      }

      return current;
    },
    cpddSent() {
      if (this.currentCPPDResource) {
        return this.currentCPPDResource?.sent_at != null;
      }

      return false;
    },
    disableCPDDButton() {
      if (this.selectedLender && this.canSendCPDD) {
        return false;
      }

      return true;
    },
  },
  async mounted() {
    let res = await this.checkPrivacyDisclosure({ appGuid: this.appGuid });

    if (res.status == 200) {
      this.canSendCPDD = true;
    } else {
      this.setPrivacyButtonTooltip(
        "It appears we are missing important information for your compliance document generation. Please send an email asset.partners+privacy@lmg.broker and we will respond with a simple form for you to complete so we can update your information and get the documents working."
      );
    }
  },
  methods: {
    ...mapActions("resource-actions", ["checkPrivacyDisclosure"]),
    ...mapMutations("privacy-disclosure", ["setPrivacyButtonTooltip"]),
    close() {
      this.dialog = false;
      this.form["referral_partner_name"] = null;
      this.form["referral_fee_estimate"] = "0";
      this.form["referral_payment"] = null;
      this.form["tmd_client_fit"] = null;
      this.form["tmd_client_recommendation_reason"] = null;
    },
    back() {
      this.form["tmd_client_recommendation_reason"] = null;
      this.form["tmd_client_recommendation_reason_other"] = null;

      if (this.activeStep === 1) this.close();
      else this.activeStep = 1;
    },
    async saveData() {
      this.pageLoader = true;
      const valid = await this.$refs.observer.validate();

      this.$refs.observer;

      let payload = {
        ...this.currentCPPDResource,
        ...this.form,
      };

      if (valid) {
        this.$emit("saved-referrals", this.transformBool(payload));
        this.close();
      }

      this.pageLoader = false;
    },
    transformBool(form) {
      let newForm = {};
      // transform some field to boolean value
      Object.keys(form).forEach((f) => {
        if (["referral_payment", "tmd_client_fit"].includes(f)) {
          newForm[f] = form[f] === "Yes" ? true : false;
        } else {
          newForm[f] = form[f];
        }
      });

      return newForm;
    },
  },
  watch: {
    referralPayment(val) {
      if (val === "No") {
        this.form["referral_partner_name"] = "Not Applicable";
        this.form["referral_fee_estimate"] = "0.00";
      } else {
        this.form["referral_partner_name"] = "";
      }
    },
    dialog: {
      handler(val) {
        if (val && this.cpddSent) {
          this.form["referral_partner_name"] = this.currentCPPDResource[
            "referral"
          ]
            ? this.currentCPPDResource["referral"]["partner_name"]
            : null;
          this.form["referral_fee_estimate"] = this.currentCPPDResource[
            "referral"
          ]
            ? this.currentCPPDResource["referral"]["fee_estimate"]
            : "0";
          this.form["referral_payment"] = this.currentCPPDResource[
            "referral_payment"
          ]
            ? "Yes"
            : "No";
          this.form["tmd_client_fit"] = this.currentCPPDResource[
            "tmd_client_fit"
          ]
            ? "Yes"
            : "No";
          this.form["tmd_client_recommendation_reason"] =
            this.currentCPPDResource["tmd_client_recommendation_reason"];
        }
      },
      deep: true,
    },
    tmdClientFit(val) {
      switch (val) {
        case "Yes":
          this.activeStep = 3;
          this.form["tmd_client_recommendation_reason"] = null;
          this.form["tmd_client_recommendation_reason_other"] = null;
          break;
        case "No":
          this.activeStep = 2;
          this.form["tmd_client_recommendation_reason"] = "Other";
          break;
        default:
          this.activeStep = 1;
      }
    },
  },
  data() {
    return {
      activeStep: 1,
      dialog: false,
      tmd: null,
      form: {
        referral_partner_name: null,
        referral_payment: null,
        referral_fee_estimate: "0",
        tmd_client_fit: null,
        tmd_client_recommendation_reason: null,
        tmd_client_recommendation_reason_other: null,
      },
      tmdPickList: [
        {
          name: "The product is in the best interests of the client",
          value: "Client Best Interest",
        },
        {
          name: "The client specifically requested this product",
          value: "Client Requested",
        },
        { name: "Other (please provide details)", value: "Other" },
      ],
      validation_rules: {
        attributes: {
          referral_partner_name: "required_if:referral_payment,Yes",
          referral_payment: "required_if:tmd_client_fit,Yes",
          referral_fee_estimate: "required_if:referral_payment,No",
          tmd_client_fit: "required",
          tmd_client_recommendation_reason: "required_if:tmd_client_fit,No",
          tmd_client_recommendation_reason_other: "",
        },
      },
      pageLoader: false,
      canSendCPDD: false,
    };
  },
};
</script>

<style lang="scss" scoped></style>
