<template>
    <v-dialog
        v-model="dialog"
        width="800"
        scrollable
        persistent>
   

        <v-card flat class="is-spouse-lived-same-period relative">
            <v-card-title class="pa-0 ma-0 pr-5 pt-3 mb-n5">
                <v-spacer></v-spacer>
                <v-btn icon rounded small @click="close" depressed>
                    <v-icon color="primary" size="20">mdi-close</v-icon>
                </v-btn>
            </v-card-title>
            <v-card-title class="px-8 pt-8 text-center">
                <div class="sm pa-0 text-center" style="width:100%">
                   {{title}}
                </div>
            </v-card-title>
            <v-card-text class="px-16" >
                    <v-row class="d-flex justify-center align-center"  >
                        <div :class="['custom-btn custom-outline1 ma-4',
                            {'custom-disable': isLoading},
                            {'bg-light-blue': occupancySituation == 'Current'}]" 
                                @click="nextStep('Yes')">
                            <v-progress-circular  indeterminate color="base" v-if="isLoading"></v-progress-circular>
                            <span v-if="!isLoading">Yes</span>
                        
                        </div>
                        <div :class="['custom-btn  custom-outline1 ma-4 align-center',
                            {'custom-disable': isLoading},
                            {'bg-light-blue': occupancySituation == 'Previous'}]" 
                            @click="nextStep('No')">
                            <v-progress-circular indeterminate color="base" v-if="isLoading"></v-progress-circular>
                            <span v-if="!isLoading">No</span>
                        </div>
                    </v-row>
                <v-row class="d-flex justify-center pb-8">
                    <v-btn class="px-12 custom-outline1" @click="back"  width="140" height="50" depressed large outlined>Back</v-btn>
                </v-row>
            </v-card-text>
          
        </v-card>

    </v-dialog>
</template>
<script>
import {store,mutations} from './__store'
import shared from './__shared'
export default {
    props : {
        title: {
            type: String,
            default: 'Title'
        },
        step: {
            type: String,
            default: ''
        },
        address: {
            type:Object,
            default: () => {}
        },
        occupancySituation : {
            type: String,
            default: 'Current'
        },
    },
    mixins: [shared],
    watch: {
        "step": {
            handler (val) {
                val == 'is-spouse-live-same-period' ? this.dialog = true : this.dialog = false
            }
        },
        "address": {
            handler (val) {
                return val
            }
        },
        'occupancySituation': {
            handler (val) {
                // console.log()
                return val
            }
        }
    },
    computed : {
        
    },
    methods: {
        async nextStep(data) {
            this.isLoading = true
            // console.log('occupation',this.occupancySituation)
            if(this.occupancySituation == 'Current'){
                let item = await this.getPossibleOwner(data)
                mutations.setPossibleOwner(item)
                this.$emit('step','property-owner')
            } else {
                this.$emit('step','property-description')
            }
            this.isLoading = false
            mutations.setIsLiveTogether(data)
        },
        back(){
            this.$emit('step','date-move')
        },
        close() {
            this.$emit('step',null)
        }
    },
    data() {
        return {
            isLoading:false,
            dialog : false,
            fields : [
                
            ]
        }
    }
}
</script>
<style lang="scss" >
    
    .is-spouse-lived-same-period {
        .custom-btn  {
            text-align: center;
            padding: 50px;
            border-radius: 20px;
            font-size: 15px;
            border: 1px solid;
            cursor: pointer;
            width: 200px !important;
        }
        .custom-btn:hover{
            background: #E7FAFC;
        }
        .custom-disable {
            pointer-events:none !important ;
        }
        .custom-outline1{
            border-color: var(--v-secondary-base);
        }
        .bg-light-blue{
            background: #E7FAFC;
        }
        .close-icon {
            position: absolute;
            right: 40px;
            top: 30px;
            // background: var(--v-secondary-lighten2);
            color: var(--v-secondary-base)!important;
            padding: 6px;
            border-radius: 36px !important;
            i {
                color: var(--v-secondary-base) !important;
            }
        }
    }
</style>