// initial state
export const  state = () => ({
    development: process.env.development,
    production: process.env.production,
    hasAuthToken: false,
    globalDisabled: false,
    globalLoading: false,


    /**
     * Application setup of navigation, menu etc.
     */
    appConfig: {
        init: false,
        navigation: {
            scenarios: true,
            integrations: true
        },
        myAppsTileMenu: {
            files: true,
            fileNotes: true
        },
        autoInvite: false,
        brokerBible: true,
        CRMTenant: [
            'app',
            'plan',
            'choice',
            'fast',
            'nectar',
            'byob',
            'loanmarket'
        ],
        complianceTenants: [
            'app',
            'plan',
            'choice',
            'fast',
            'nectar',
            'byob',
            'loanmarket',
            'nodifi', 
            'lmgaf1'
        ],
        whiteLabelledTenants: [
            'loanmarket',
            'nectar',
            'nodifi',
            'plan',
            'choice',
            'fast',
            'byob',
            'lmg',
            'lmgaf1',
            'lmgaf2',
            'lmgaf3',
            'lmgaf4',
        ],
    },

    onKeywordRoute: false,

})
// getters
export const  getters = {
    getAppConfig: (state) => state.appConfig,
    getCRMTenant: (state) => state.appConfig.CRMTenant,
    isWhiteLabelled: (state, rootGetters, rootState) => {
        return state.appConfig.whiteLabelledTenants.includes(rootState.slug.tenant)
    }
}
// actions
export const actions = {

    nuxtServerInit ({ commit }, { req }) {

        // if(req.headers.host){
            // let subdomain = req.headers.host.split('.')
                // subdomain = subdomain.length == 1 ? '' : subdomain[0]
            // commit('slug/setSubdomain', subdomain, {root: true})
        // }

    },
    resetGlobalStatesAction({commit, getters, dispatch, rootState}){
        commit('resetGlobalStates')
    },

}
// mutations
export const  mutations = {
    setHasAuthToken(state, payload) {
        state.hasAuthToken = payload
    },
    setGlobalDisabled(state, payload) {
        state.globalDisabled = payload
    },
    setGlobalLoading(state, payload) {
        state.globalLoading = payload
    },
    resetGlobalStates(state) {
        state.globalDisabled = false
        state.globalLoading = false
    },
    setAppConfigInit(state, payload) {
        state.appConfig.init = payload
    },
    setNavigation(state, payload) { // {scenarios : false|true }

        state.appConfig.navigation = {
            ...state.appConfig.navigation,
            ...payload
        }

    },
    setMyAppsTileMenu(state, payload) { // {files : false|true }

        state.appConfig.myAppsTileMenu = {
            ...state.appConfig.myAppsTileMenu,
            ...payload
        }

    },
    setAutoInvite(state, payload) {
        state.appConfig.autoInvite = payload
    },
    setBrokerBible(state, payload) {
        state.appConfig.brokerBible = payload
    },
    setKeywordRoute(state, payload) {
        state.onKeywordRoute = payload
    },
}
