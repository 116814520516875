var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","lg":"6","xl":"6"}},[_c('v-list',{staticClass:"draggable-list pa-2 pt-2",attrs:{"nav":"","dense":""}},[_c('span',{staticClass:"draggable-list-item-label font-weight-bold pb-2 mb-2"},[_vm._v("\n                    "+_vm._s(_vm.label)+"\n                ")]),_vm._v(" "),_vm._t("tooltip"),_vm._v(" "),_c('v-card',{staticClass:"drop-list mt-2",attrs:{"outlined":"","flat":"","elevation":"0"}},[_c('drop-list',{attrs:{"items":_vm.defaultItem,"mode":"cut"},on:{"reorder":function($event){return $event.apply(_vm.defaultItem)},"insert":_vm.insert},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('drag',{key:item,attrs:{"data":item},on:{"cut":function($event){return _vm.remove(_vm.defaultItem, item)}}},[_c('v-list-item',{staticClass:"py-0 my-0"},[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"draggable-list-item"},[_c('v-avatar',{staticClass:"mr-3",attrs:{"color":"grey lighten-1","size":"30","dark":""}},[_c('v-icon',{attrs:{"size":"20","dark":""}},[_vm._v(_vm._s(_vm.getIcon(item)))])],1),_vm._v("\n                                            "+_vm._s(item)+"\n                                        ")],1)],1)],1),_vm._v(" "),_c('v-divider')],1)]}},{key:"feedback",fn:function(ref){
var item = ref.item;
return [_c('v-skeleton-loader',{key:_vm.defaultItem.indexOf(item),staticClass:"skeleton-avatar",attrs:{"type":"list-item-avatar"}})]}}])})],1)],2)],1),_vm._v(" "),_c('v-col',{attrs:{"cols":"12","lg":"6","xl":"6"}},[_c('v-list',{staticClass:"draggable-list pa-0 pt-2",attrs:{"nav":"","dense":""}},[_c('span',{staticClass:"draggable-list-item-label font-weight-bold pb-2 mb-4"},[_vm._v("\n                    Please Drag and Drop it Here...\n                ")]),_vm._v(" "),_c('v-card',{staticClass:"drop-list mt-2",attrs:{"outlined":"","flat":"","elevation":"0"}},[_c('drop-list',{attrs:{"items":_vm.modelValue,"mode":"cut"},on:{"reorder":function($event){return $event.apply(_vm.modelValue)},"insert":_vm.insert2},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('drag',{key:item,attrs:{"data":item},on:{"cut":function($event){return _vm.remove(_vm.modelValue, item)}}},[_c('v-list-item',{staticClass:"py-0 my-0"},[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"draggable-list-item"},[_c('v-avatar',{staticClass:"mr-3",attrs:{"color":"#4e69d9","size":"30","dark":""}},[_c('v-icon',{attrs:{"size":"20","dark":""}},[_vm._v(_vm._s(_vm.getIcon(item)))])],1),_vm._v("\n                                            "+_vm._s(item)+"\n                                        ")],1)],1),_vm._v(" "),_c('v-list-item-action',{staticClass:"pa-0 ma-0"},[_c('v-avatar',{staticClass:"ma-0",attrs:{"color":"grey lighten-2","size":"20","dark":""}},[_c('span',{staticClass:"primary--text font-weight-bold"},[_vm._v("\n                                                "+_vm._s(_vm.modelValue.map(function(x) {return x }).indexOf(item) + 1)+"\n                                            ")])])],1)],1),_vm._v(" "),_c('v-divider')],1)]}},{key:"feedback",fn:function(ref){
var item = ref.item;
return [_c('v-skeleton-loader',{key:_vm.modelValue.indexOf(item),staticClass:"skeleton-avatar",attrs:{"type":"list-item-avatar"}})]}}])})],1)],1),_vm._v(" "),(_vm.hasErrors)?_c('div',{staticClass:"error-messages"},_vm._l((_vm.errorMessages),function(err,ekey){return _c('span',{key:ekey},[_vm._v("\n                    "+_vm._s(err)+"\n                ")])}),0):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }