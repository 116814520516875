<template>
    <v-dialog
        v-model="dialog"
        width="700"
        scrollable
        persistent>
        <v-card flat class="ol-residential-history-5-is-currently-living">
            
            <v-card-title class="pa-0 ma-0 pr-5 pt-3 mb-n5">
                <v-spacer></v-spacer>
                <v-btn icon rounded small @click="close" depressed>
                    <v-icon color="primary" size="20">mdi-close</v-icon>
                </v-btn>
            </v-card-title>

            <v-card-title class="px-8 pt-4 text-center">
                <div class="sm pa-0 text-center" style="width:100%">
                   {{title}}
                </div>
            </v-card-title>
            <v-card-text class="px-16 mt-n4">
                <ValidationObserver class="mx-auto" :style="vb.mdAndUp ? 'width: 50%;' : ''" ref="observer1" tag="form">
                    <ValidationProvider v-slot="{ errors }"  :rules="rules['start-date']" name="Start Date" >
                        <FlexDatePicker3
                            :errors="errors"
                            v-model="form['start-date']" >
                        </FlexDatePicker3>
                    </ValidationProvider>
                    <div class="body-1 text-center" v-if="visible['end-date']">Move out date for this address is {{this.formatedDate}}</div>
                </ValidationObserver>
            </v-card-text>
            <!-- <v-card-text class="d-flex justify-center" v-if="actionType == 'edit'">
                <v-btn  class="px-10 mx-4 secondary" :loading="isSaving" depressed @click="update">Update</v-btn>
            </v-card-text> -->
            <v-card-text class="d-flex justify-center pb-8" >
                <v-btn class="px-10 " width="140" height="50" depressed large @click="back">Back</v-btn>
                <v-btn class="px-10 mx-4 secondary" width="140" height="50" depressed large @click="nextStep">Next</v-btn>
            </v-card-text>
        </v-card>

    </v-dialog>
</template>
<script>
import FlexDatePicker3 from '~/components/base/FlexDatePicker3'  
import {store,mutations} from './__store'
import { mapState, mapActions, mapGetters } from 'vuex'
import formHelpers from '~/components/shared/form-helpers.js'
import validation from '~/components/shared/validation'
import shared from './__shared'
import moment from 'moment'

export default {
    components: {
        FlexDatePicker3
    },
    props : {
        title: {
            type: String,
            default: 'Title'
        },
        step: {
            type: String,
            default: ''
        },
        occupancySituation : {
            type: String,
            default: 'Current'
        },
        address: {
            type:Object,
            default: () => {}
        },
        actionType: {
            type:String,
            default: ''
        },
        endDate: {
            type:String,
            default: ''
        }
    },
    data() {
        return {
            presetVal : ''
        }
    },
    mixins: [formHelpers,validation,shared],
    watch: {
        dialog(val) {

            if(val) {

                if(this.endDate) {
                    this.rules['start-date'] = `required|before:${moment(this.endDate).format('MM/DD/YYYY')}`
                } else {
                    this.rules['start-date'] = `required`
                }

                this.evaluateAddress()

            } else {

                this.form['start-date'] = null

            }

        },
        "step": {
            handler (val) {
                val == 'date-move' ? this.dialog = true : this.dialog = false
            }
        },
        'address': {
            handler (val) {
                if (val.hasOwnProperty('occupancy')){
                    //this.presetVal = val.occupancy.attributes['start-date'];

                    this.form['start-date'] = val.occupancy.attributes['start-date']

                    //this.form['start-date'] = val.occupancy.attributes['start-date']
                    this.addressId = val.address.id
                }
            }
        },
        'actionType': {
            handler (val) {
                if(val == "edit"){
                    this.form['end-date'] = this.address.occupancy.attributes['end-date']
                }
            }
        },
        'occupancySituation': {
            handler (val) {
                if(val == "Current"){
                    this.visible['end-date'] = false
                } else {
                    this.visible['end-date'] = true
                }
                return val
            }
        },
        'endDate' : {
            handler (val) {
                this.form['end-date'] = val
            }
        }
    },
    computed: {
        ...mapState('flows',['calculation','appData']),
        formatedDate () {
            if(this.form['end-date'] != null ){
                let item  = this.form['end-date'].split('-')
                return item[2] + "/" + item[1] + "/" +item[0]
            }
        }
    },
    methods: {
        ...mapActions('resource-actions',['getAddresses','createOccupancy','deleteAddress','getOccupancy','createAddress','updateAddress','updateOccupancy']),
        async nextStep(data) {
            let valid = await this.$refs.observer1.validate();
            if(valid){
                this.isloading = true
                let occupancy = {
                    'start-date': this.form['start-date'],
                    'end-date': this.occupancySituation == "Current" ? null : this.form['end-date'],
                    // 'residential-situation':store.occupancyAttributes['residential-situation']
                }
                mutations.setOccupancies(occupancy)
                let item = await this.getPossibleOwner(data)
                mutations.setPossibleOwner(item)

                ///console.log('action type',this.actionType)
                if(this.actionType !== 'edit'){
                    if (this.calculation['application-type'].search('Non Borrowing Spouse') != -1 || this.calculation['application-type'].search('Joint')  != -1 ) {
                        this.$emit('step','is-spouse-live-same-period') 
                    } else {
                        if(this.occupancySituation == "Current"){
                            mutations.setIsLiveTogether('No')
                            this.$emit('step', 'property-owner')
                        } else {
                            this.$emit('step','property-description')
                        }
                    }
                } else {
                    // console.log('editttt',this.actionType)
                    this.$emit('step','property-description')
                }
                this.isloading = false
                this.reset()
            }
        },
        showPresetVal(e) {
            console.log(this.form['start-date']);
            this.form['start-date'] = this.presetVal;
            if(this.form['start-date'] != null) {
                e.target.setSelectionRange(0,this.form['start-date'].length);
            }
        },
        evaluateAddress() {
            if (this.address.hasOwnProperty('occupancy')){

                this.form['start-date'] = this.address.occupancy.attributes['start-date']
                this.addressId = this.address.address.id
                
            }
        },
        back() {
            this.$emit('back','address-form')
        },
        close(){
           this.$emit('back',null)
        },
        reset(){
            this.presetVal = ''
            this.form['start-date'] = null
            this.form['end-date'] = null
        },
        async update () {
            this.isSaving = true
            let valid = await this.$refs.observer1.validate()
            if (valid) {
                this.resources.occupancy.attributes['end-date'] = this.form['end-date']
                this.resources.occupancy.attributes['start-date'] = this.form['start-date']
                if(this.form['end-date'] == null){
                    this.resources.occupancy.attributes['occupancy-situation'] = "Current"
                } else {
                    this.resources.occupancy.attributes['occupancy-situation'] = "Previous"
                }
                this.resources.occupancy.id = this.address.occupancy.id
                this.resources.occupancy.relationships.person = this.address.address.relationships.person
                this.resources.occupancy.relationships.application = this.address.address.relationships.application
                this.resources.occupancy.relationships.address = this.address.occupancy.relationships.address
                await this.updateOccupancy(this.resources.occupancy)
                this.address.occupancy.attributes['start-date'] = this.resources.occupancy.attributes['start-date']
                this.address.occupancy.attributes['end-date'] = this.resources.occupancy.attributes['end-date']
                this.address.occupancy.attributes['occupancy-situation'] = this.resources.occupancy.attributes['occupancy-situation']
                this.$emit('updateTable',this.address)
                this.$emit('back',null)
                this.isSaving = false
            }
        }
    },
    data() {
        return {
            isSaving: false,
            isloading: false,
            dialog :false,
            fields : [ 
            ],
            addressId:'',
            form:{
                'start-date': null,
                'end-date': null

            },
            resources: {
                occupancy: {
                    type: 'occupancies',
                    attributes: {
                        "occupancy-situation": null,
                        "start-date": null,
                        "end-date": null
                    },
                    relationships: {
                        application: {
                            data: { type: "applications", id:  null} 
                        }
                    }
                },
            },
            fields: [
                {model: 'start-date', label: "Start Date" ,disable: false},
                {model: 'end-date', label: "End Date",disable: true},

            ],
            visible: {
                'start-date': true,
                'end-date' : false
            },
            rules : {
                'start-date': 'required',
                'end-date': ''
            }
        }
    }
}
</script>
<style lang="scss" >
    
    .ol-residential-history-5-is-currently-living {
        .btn {
            text-transform: uppercase;
            min-width: 200px;
            text-align: center;
            padding: 50px;
            border-radius: 10px;
            font-size: 15px;
            border: 1px solid;
            cursor: pointer;
        }
        .btn:hover{
            background: #E7FAFC;
        }
        .custom-outline1{
            border-color: var(--v-secondary-base);
        }
        .close-icon {
            position: absolute;
            right: 40px;
            top: 30px;
            // background: var(--v-secondary-lighten2);
            color: var(--v-secondary-base)!important;
            padding: 6px;
            border-radius: 36px !important;
            i {
                color: var(--v-secondary-base) !important;
            }
        }
    }
</style>