<template>
    <div>
        <template 
            v-for="(section, skey) in sections">
            <div :key="skey">

                <v-card-text 
                    v-if="section.name === 'Basic Terms and Fees'"
                    class="pa-2">

                    <v-row v-if="hasLogo">                        
                        <v-col class="pa-0 ma-0 mx-2 pt-6 pb-2 px-2" cols="10">
                            <img
                                height="100" 
                                class="ma-0 pa-0" 
                                :src="lenderImage" />
                        </v-col>                        
                    </v-row>

                    <p class="subtitle-1 font-weight-medium pt-4 pb-3">{{ section.name }}</p>
                    <v-row class="summary-detail-card">
                        <v-col cols="6" class="caption py-0">Amount Financed</v-col>
                        <v-col cols="6" class="caption py-0">{{ actualPayment('naf') | currency }}</v-col>
                        <v-col cols="6" class="caption py-0">Loan Term</v-col>
                        <v-col cols="6" class="caption py-0">{{ actualPayment('term-in-months') }} Months</v-col>
                        <v-col cols="6" class="caption py-0">Payment Terms</v-col>
                        <v-col cols="6" class="caption py-0">{{ paymentTerms }}</v-col>
                        <v-col cols="12" class="caption py-0">&nbsp;</v-col>
                        <v-col cols="6" class="caption py-0">Balloon</v-col>
                        <v-col cols="6" class="caption py-0">{{ actualPayment('balloon') | currency }}</v-col>
                        <v-col cols="6" class="caption py-0">Customer Rate</v-col>
                        <v-col cols="6" class="caption py-0">{{ actualPayment('customer-rate') | percentage }}</v-col>
                        <v-col cols="6" class="caption py-0">Monthly Payment</v-col>
                        <v-col cols="6" class="caption py-0">{{ actualPayment('monthly-payment') | currency }}</v-col>
                        <v-col cols="12" class="caption py-0">&nbsp;</v-col>
                        <v-col cols="6" class="caption py-0">Origination Fee</v-col>
                        <v-col cols="6" class="caption py-0">{{ actualPayment('broker-fee') | currency }}</v-col>
                        <v-col cols="6" class="caption py-0">Bank Setup Fees</v-col>
                        <v-col cols="6" class="caption py-0">{{ actualPayment('bank-fees') | currency }}</v-col>
                        <v-col cols="6" class="caption py-0">Monthly Account Fee</v-col>
                        <v-col cols="6" class="caption py-0">{{ actualPayment('account-fee') | currency }}</v-col>
                    </v-row>
                </v-card-text>                        
            
                <v-card-text 
                    v-else-if="section.name === 'Advanced Information'"
                    class="pa-2">
                    <p class="subtitle-1 font-weight-medium pt-4 pb-3">{{ section.name }}</p>
                    <v-row class="summary-detail-card">
                        <v-col cols="6" class="caption py-0">Base Rate</v-col>
                        <v-col cols="6" class="caption py-0">{{ actualPayment('base-rate') | percentage }}</v-col>
                        <v-col cols="6" class="caption py-0">Brokerage</v-col>
                        <v-col cols="6" class="caption py-0">{{ actualPayment('commission-inc-gst') | currency }}</v-col>
                        <v-col cols="6" class="caption py-0">Total Commission</v-col>
                        <v-col cols="6" class="caption py-0">{{ computedTotalCommission() | currency }}</v-col>                                
                    </v-row>
                </v-card-text>
            
                <!-- <v-card-text 
                    v-else-if="section.name === 'Early Payout Information'"
                    class="pa-2">
                    <p class="subtitle-1 font-weight-medium pt-4 pb-3">{{ section.name }}</p>
                    <v-row class="summary-detail-card">
                        <v-col cols="12" class="caption py-0">
                            Templated text/image here that will detail the early payout info
                        </v-col>
                    </v-row>
                </v-card-text>                
            
                <v-card-text 
                    v-else-if="section.name === 'Minimum Supporting Documents'"
                    class="pa-2">
                    <p class="subtitle-1 font-weight-medium pt-4">Notes</p>
                    <template v-if="hasSupportDocsList" class="px-8">
                        <p v-for="(list, lkey) in supportingDocs()"
                            class="caption ma-0"
                            :key="lkey">
                            {{ list }}
                        </p>
                    </template>
                    
                    <div v-else class="px-0">
                        <v-alert 
                            type="info" color="secondary" dense
                            v-for="(list, lkey) in supportingDocs()"
                            :key="lkey">
                            <div class="subtitle-2 white--text">{{ list }}</div>
                        </v-alert>
                    </div>
                </v-card-text> -->

            </div>
        </template>

    </div>
</template>

<script>
    export default {
        name: 'BidQuoteSummaryDetails',

        props: {
            hasLogo: {
                type: Boolean,
                default: true
            },
            selectedLender: {
                type: Object,
                default: () => {}
            },
            loanResource: {
                type: Object,
                default: () => {}
            }
        },

        computed: {
            lenderImage() {
                return require(`~/assets/images/logo/${this.selectedLender.details['logo-class']}.png`)
            },
            paymentTerms() {
                return this.loanResource?.attributes?.['requested-payment-terms'] === '' ? 'In Arrears' : this.loanResource.attributes['requested-payment-terms']
            },
            hasSupportDocsList() {
                return this.selectedLender?.details?.['supporting-docs']?.length > 1 ? true : false
            },
        },

        methods: {
            actualPayment(key) {
                return this.selectedLender?.details?.['actual-payment']?.[key]
            },

            computedTotalCommission() {
                let brokerFee = parseInt( this.actualPayment('broker-fee') ) === null ? 0 : parseInt( this.actualPayment('broker-fee') )
                let cig = parseInt( this.actualPayment('commission-inc-gst') )
                return parseInt(cig) + parseInt(brokerFee)
            },

            supportingDocs() {
                return this.selectedLender?.details?.['supporting-docs']
            }
        },

        data() {
            return {
                sections: [
                    { 
                        name: 'Basic Terms and Fees',
                        data: {}
                    },
                    { 
                        name: 'Advanced Information',
                        data: {}
                    },
                    { 
                        name: 'Early Payout Information',
                        data: {}
                    },
                    { 
                        name: 'Minimum Supporting Documents',
                        data: {}
                    }
                ]
            }
        }
    }
</script>

<style lang="scss" scoped>
    .summary-detail-card {
        div {
            
        }
    }
</style>