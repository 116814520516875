<template>

        <div v-if="role.children && role.children.length" :class="['flex-role-editor-list', `flex-role-editor-list-${deep}`, 'has-children', {'last-child': last}]">

            <v-list-item
                :class="[{'add-btn': isAdd(role)}]"
                :style="indentation"
                :disabled="readOnly && isAdd(role)"
                @click="handleClick(role)">

                <div :class="['track-dot', 'track-dot-' + deep, {'track-dot-add': isAdd(role)}]"></div>
                <div class="track-blocker"></div>

                <v-list-item-content> 
                    <div class="flex-list d-flex flex-column">
                        <div v-if="!isAdd(role)">
                            <v-chip :filter="false" :ripple="false" class="light_gray_1 mb-1 mr-1 font-weight-bold" small v-for="entity in getRolesEntity(role)" :key="entity">
                                {{ entity }}
                            </v-chip>
                        </div>
                        <div class="d-flex flex-row mt-1">

                            <v-list-item-action v-if="isAdd(role)" class="mr-0">
                                <v-icon size="14" v-text="role.icon" color="secondary"></v-icon>
                            </v-list-item-action>
                            <v-list-item-avatar v-else class="ma-0 mr-2" color="light_gray_1" size="34">
                                <v-icon size="20" :color="isValid ? '' : 'error'" v-text="role.icon"></v-icon>
                            </v-list-item-avatar>

                            <v-list-item-title :class="wrap" >

                                <span :class="['text-subtitle-2', {'secondary--text': isAdd(role)}, {'error--text': !isValid}]">
                                    {{ getFriendlyName(role) }} {{ development ? deep : '' }}
                                </span>

                                <v-list-item-subtitle v-if="!isAdd(role)" :class="{'error--text': !isValid}" class="d-flex pt-1">
                                    <div :class="{'error--text': role['validation-errors']}">
                                        {{ getRoleKind(role) }}
                                    </div>
                                    <div class="px-2" v-if="role['validation-errors']">
                                        <v-tooltip right color="error" max-width="250">
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-icon
                                                    size="16"
                                                    color="error"
                                                    v-bind="attrs"
                                                    v-on="on"
                                                    class="mt-n1"
                                                >
                                                    mdi-information
                                                </v-icon>
                                            </template>
                                            <span>{{ role['validation-errors'] }}</span>
                                        </v-tooltip>  
                                    </div>
                                </v-list-item-subtitle>
                                
                            </v-list-item-title>
                        </div>
                    </div>
                </v-list-item-content>

            </v-list-item>

            <div class="filler" :style="position"></div>

            <ListGroup 
                v-for="(child, index) in role.children" 
                :role="child" 
                :key="`${index}-${deep + 1}`"
                :deep="deep + 1" 
                :mini="mini"
                :errors="errors"
                :last="isLast(index)"
                :read-only="readOnly"
                @add="handleAdd" 
                @select="handleClick" />
            
        </div>

        <v-list-item v-else
            :style="indentation"
            :class="[{'add-btn': isAdd(role)}, {'last-child': last}, 'flex-role-editor-list']"
            :disabled="readOnly && isAdd(role)"
             @click="handleClick(role)">

            <div :class="['track-dot', 'track-dot-' + deep, {'add': isAdd(role)}]"></div>
            <div class="track-blocker"></div>


            <v-list-item-content>
                <div class="flex-list d-flex flex-column">

                    <div v-if="!isAdd(role)">
                        <v-chip :filter="false" :ripple="false" class="light_gray_1 mb-1 mr-1 font-weight-bold" small v-for="entity in getRolesEntity(role)" :key="entity">
                            {{ entity }}
                        </v-chip>
                    </div>

                    <div class="d-flex flex-row mt-1">

                        <v-list-item-action v-if="isAdd(role)" class="ma-0">
                            <v-icon size="14" v-text="role.icon" color="secondary"></v-icon>
                        </v-list-item-action>
                        <v-list-item-avatar v-else class="ma-0 mr-2" color="light_gray_1" size="34">
                            <v-icon size="20" :color="isValid ? '' : 'error'" v-text="role.icon"></v-icon>
                        </v-list-item-avatar>

                        <v-list-item-title :class="[wrap]" >
                            <span :class="['text-subtitle-2', {'secondary--text': isAdd(role)}, {'error--text': !isValid}]">
                                {{ getFriendlyName(role) }} {{ development ? deep : '' }}
                            </span>
                            <v-list-item-subtitle v-if="!isAdd(role)" :class="{'error--text': !isValid}">{{ getRoleKind(role) }}</v-list-item-subtitle>
                        </v-list-item-title>
                    </div>
                </div>

            </v-list-item-content>

        </v-list-item>

</template>

<script>

    import { mapState, mapGetters, mapActions, mapMutations } from 'vuex'
    import ListGroup from '~/components/v1/RoleEditor1/_ListGroup'
    import shared from '~/components/v1/RoleEditor1/__shared'

    export default {
        name: 'ListGroup',
        mixins: [shared],
        props: {
            role: {
                type: Object,
                default: () => ({})
            },
            mini: {
                type: Boolean,
                default: false
            },
            wrap: {
                type: String,
                default: ''
            },
            deep: {
                type: Number,
                default: 1
            },
            // List or entity ids with errors
            errors: {
                type: Array,
                default: () => ([])
            },
            last: {
                type: Boolean,
                default: false
            },
            readOnly: {
                type: Boolean,
                default: false
            },
        },
        components: {
            ListGroup
        },
        data(){
            return {

            }
        },
        computed: {
            isValid(){

                const {role, errors} = this

                if(role.id){
                    return !errors.includes(role.id)
                }

                return true
            },
            indentation(){

                const {deep, mini} = this

                let base = 10
                let multiplier = 34

                if(mini) {
                    return ''
                }

                if(deep == 1) {
                    return ''
                }

                return `padding-left: ${((deep - 1) * multiplier) + base}px`

            },
            position(){

                const {deep, mini} = this

                let base = -5
                let multiplier = 34

                if(mini) {
                    return ''
                }

                if(deep == 1) {
                    return ''
                }

                return `left: ${((deep - 1) * multiplier) + base}px`

            }
        },
        mounted(){

            // console.log(this.$refs)
        },
        methods: {
            ...mapActions('kinds', [
                'getKindById',
            ]),
            isAdd(data){
                return data.type == 'add'
            },
            handleAdd(data){
                this.$emit('add', data)
            },
            handleClick(data){
    
                if(data.type == 'add'){

                    this.$emit('add', data)

                } else {

                    this.$emit('select', data)
                }

            },
            isLast(index){

                if(this.role.children){
                    if((this.role.children.length - 1) == index) return true
                }

                return false
            }
        }
    }
</script>

<style scoped lang="scss">



    .flex-role-editor-list {

        &.has-children {
            overflow: hidden;
            position: relative;

            & > .v-list-item:first-child {
                z-index: 3;
            }

            &:not(.last-child) > .v-list-item:first-child {
                & > .track-dot::before {
                    display: none;
                }
            }

            .filler {
                position: absolute;
                bottom: 0;
                width: 4px;
                height: 9999px;
                // background: blue;
                border-left: 4px dotted var(--v-light_gray_2-lighten1);
                z-index: 1;
            }
        }

        &.has-children.last-child {
            overflow: visible;

            & > .filler {
                display: none;
            }

            & > .v-list-item:first-child {
                z-index: 2;
            }
        }

        .v-list-item {
            overflow: hidden;
            
            & > .track-dot {
                position: relative;
                width: 8px;
                height: 2px;
                // background: red;

                &::before {
                    content: "";
                    position: absolute;
                    left: -15px;
                    bottom: -4999px;
                    width: 4px;
                    height: 9999px;
                    // background: green;
                    border-left: 4px dotted var(--v-light_gray_2-lighten1);
                    z-index: 2;
                }

                &::after {
                    content: "";
                    position: absolute;
                    left: -8px;
                    bottom: 20px;

                    width: 12px;
                    height: 4px;
                    // background: pink;
                    border-bottom: 4px dotted var(--v-light_gray_2-lighten1);
                    z-index: 2;
                }

            }

            &.add-btn > .track-dot::after {
                bottom: -4px;
            }
        }

        &.last-child > .v-list-item:first-child
        ,&.last-child 
        {

            & > .track-dot {
                position: relative;
                width: 8px;
                height: 2px;
                // background: red;

                &::before {
                    content: "";
                    position: absolute;
                    left: -15px;
                    bottom: 16px;

                    width: 4px;
                    height: 9999px;
                    // background: green;
                    border-left: 4px dotted var(--v-light_gray_2-lighten1);
                    z-index: 2;
                }

                &::after {
                    content: "";
                    position: absolute;
                    left: -8px;
                    bottom: 20px;

                    width: 12px;
                    height: 4px;
                    // background: pink;
                    border-bottom: 4px dotted var(--v-light_gray_2-lighten1);
                    z-index: 2;
                }

            }
                
        }

        &.last-child.v-list-item.add-btn {

            & > .track-dot {
                position: relative;
                width: 8px;
                height: 2px;

                &::before {
                    bottom: -8px;
                }
            }   
        }

    }



    // Style Specific
    .flex-role-editor-list-1 {
        // overflow: hidden;

        & > .filler {
            display: none;
        }

        & > .v-list-item:first-child {
            & > .track-dot {
                display: none;
            }
        }
    }

    .v-list-item.add-btn {
        min-height: auto;

        .v-list-item__content {
            padding: 5px 0 5px 14px;

            .v-list-item__action {
                min-width: 14px;
            }
        }
    }


</style>
