<template>
    <v-card class="mx-auto" tile flat>
        <v-card-text class="pa-4 pt-0 pa-md-10 pt-md-0">
            <ValidationObserver ref="observer" tag="form" v-if="true">
                   <div class="flex-form">

                            <div class="flex-field">
                                <ValidationProvider
                                    v-slot="{ errors }"
                                    :name="labels['body-type']"
                                    :rules="getRules('body-type')"
                                    :vid="'body-type'">
                                    <v-select
                                        v-model="resources.purchases.attributes['body-type']"
                                        :items="options['body-type']"
                                        :error-messages="errors"
                                        color="secondary"
                                        :label="labels['body-type']"
                                    ></v-select>
                                </ValidationProvider>
                            </div>

                            <div class="flex-field">
                                <ValidationProvider
                                    v-slot="{ errors }"
                                    :name="labels['sale-type']"
                                    :rules="getRules('sale-type')"
                                    :vid="'sale-type'">
                                    <v-select
                                        v-model="resources.loans.attributes['sale-type']"
                                        :items="options['sale-type']"
                                        :error-messages="errors"
                                        color="secondary"
                                        :label="labels['sale-type']"
                                    ></v-select>
                                </ValidationProvider>
                            </div>

                            <div class="flex-field">
                                <ValidationProvider
                                    v-if="vendorUnknown"
                                    v-slot="{ errors }"
                                    :name="labels['lvr-band']"
                                    :rules="getRules('lvr-band')"
                                    :vid="'lvr-band'">
                                    <v-select
                                        v-model="resources.purchases.attributes['lvr-band']"
                                        :items="options['lvr-band']"
                                        :error-messages="errors"
                                        color="secondary"
                                        :label="labels['lvr-band']"
                                    >
                                    <template v-slot:label>
                                        <label :title="labels['lvr-band']">{{ labels['lvr-band'] }}</label>
                                    </template>
                                </v-select>
                                </ValidationProvider>
                            </div>
                            <div class="flex-field">
                                <ValidationProvider
                                    v-slot="{ errors }"
                                    :name="labels['asset-subtype']"
                                    :rules="getRules('asset-subtype')"
                                    :vid="'asset-subtype'">
                                    <v-select
                                        v-model="resources.purchases.attributes['asset-subtype']"
                                        :items="options['asset-subtype']"
                                        :error-messages="errors"
                                        color="secondary"
                                        :label="labels['asset-subtype']"
                                    ></v-select>
                                </ValidationProvider>
                            </div>
                            <div class="flex-field"></div>
                            <div class="flex-field"></div>
                            <div class="flex-field"></div>

                    </div>
            </ValidationObserver>


            <v-alert v-else dense outlined type="info" >
                <div>Not Applicable</div>
            </v-alert>

        </v-card-text>
    </v-card>
</template>

<script>
import { mapActions, mapState, mapMutations } from 'vuex'
import helpers from '~/plugins/helpers'
import subscriptions from '~/components/shared/subscriptions'
import ButtonGroup1 from '~/components/base/ButtonGroup1'
import NodifiMoney from '~/components/base/NodifiMoney'

export default {
    components: { ButtonGroup1 , NodifiMoney},
    mixins: [subscriptions, helpers],
    data() {
        return {
            resources: {
                purchases:{
                    type: 'purchases',
                    id: null,
                    attributes: {
                        'asset-subtype': '',
                        'body-type': '',
                        'lvr-band': '',
                    }
                },
                loans: {
                        type: "loans",
                        id: this.getLoanId,
                        attributes: {}
                    }
            },
            vendorUnknown: false,
            options: {
                'body-type': [
                    {text: '4WD', value: '4WD'},
                    {text: 'Bus', value: 'BUS'},
                    {text: 'Cabriolet', value: 'CABRIOLET'},
                    {text: 'Caravan', value: 'CARAVAN'},
                    {text: 'Coupe', value: 'COUPE'},
                    {text: 'Forklift', value: 'FORKLIFT'},
                    {text: 'Hatch', value: 'HATCH'},
                    {text: 'Lorry', value: 'LORRY'},
                    {text: 'Mobileplant', value: 'MOBILEPLANT'},
                    {text: 'Motorcycle', value: 'MOTORCYCLE'},
                    {text: 'Panelvan', value: 'PANELVAN'},
                    {text: 'Primemover', value: 'PRIMEMOVER'},
                    {text: 'Sedan', value: 'SEDAN'},
                    {text: 'Smallbus', value: 'SMALLBUS'},
                    {text: 'Stationwagon', value: 'STATIONWAGON'},
                    {text: 'Tractor', value: 'TRACTOR'},
                    {text: 'Trailer', value: 'TRAILER'},
                    {text: 'Utility', value: 'UTILITY'},
                ],
                'sale-type': [
                    'Dealer',
                    'Private Sale',
                    'Refinance',
                ],
                'lvr-band': [
                    {text: 'Band A', value: 'BAND_A'},
                    {text: 'Band B', value: 'BAND_B'},
                    {text: 'Band C', value: 'BAND_C'},
                    {text: 'Band D', value: 'BAND_D'},
                ],
                'asset-subtype': [
                    // Consumer
                    // {text: 'Cars', value: 'MOTOV_CARS_-_OTHER_CARS_AU'},
                    // {text: 'Utilities & Vans', value: 'MOTOV_UTILITIES_&_VANS_AU '},

                    // Commercial
                    {text: 'Cars - Hire & Travellers', value: 'MOTOV_CARS_-_HIRE_&_TRAVELLERS_AU'},
                    {text: 'Car', value: 'MOTOV_CARS_-_OTHER_CARS_CM_AU'},
                    {text: 'Cars - Taxis', value: 'MOTOV_CARS_-_TAXIS_AU'},
                    {text: 'Lorries & Trucks', value: 'MOTOV_LORRIES_&_TRUCKS_AU '},
                    {text: 'Trailers', value: 'MOTOV_TRAILERS_AU'},
                    {text: 'Utilities and Vans', value: 'MOTOV_UTILITIES_&_VANS_CM_AU'},
                ]
            },
            labels: {
                'body-type': 'Body Type',
                'sale-type': 'Sale Type',
                'lvr-band': 'What is the expect Loan to Value ratio band of the vehicle?',
                'asset-subtype': 'Asset Type'
            },
            validation_rules: {
                attributes: {
                    'body-type': 'required',
                    'sale-type': 'required',
                    'lvr-band': 'required',
                    'asset-subtype': 'required',
                }
            },
        }
    },
    computed: {
        ...mapState({
            appId: state => state.flows.appId,
            loanData: state => state.flows.loanData,
            kindsUnformat: state => state.kinds.kindsUnformat,
        }),
        getLoanId(){
            return this.appData.relationships.loan.data.id
        },
        hasPurchases(){
            return this.appData.relationships.purchases.data.length
        },
        isConsumer(){
            if(this.loanData){
                return  this.loanData.attributes['finance-for-business-purposes'] == 'No'
            }
            return false
        },
    },
    methods: {
        ...mapActions('resource-actions', [
            'getPurchases',
            'getApplicationLoan',
            'updateLoan',
            'updatePurchases',
        ]),
        async pull() {

            let [res, loanRes] = await Promise.all([this.getPurchases(), this.getApplicationLoan(this.getLoanId)])

            if(res.status == 200 && loanRes.status == 200) {

                const purchase = res.data?.data?.[0]
                this.vendorUnknown = purchase.attributes['purchase-known'] !== 'Yes'
                this.resources.purchases =  {
                        id: purchase.id,
                        type: purchase.type,
                        attributes: {
                            "asset-subtype": purchase.attributes.lender.macquarie.assetSubtype,
                            "body-type": purchase.attributes.lender.macquarie.bodyType,
                            "lvr-band": purchase.attributes.lender.macquarie.lvrBand,
                        },
                        relationships: purchase.relationships
                    }

                this.resources.loans.attributes['sale-type'] = loanRes.data.data.attributes['sale-type']

                if(this.isConsumer) {
                    this.options['asset-subtype'] = [
                        {text: 'Cars', value: 'MOTOV_CARS_-_OTHER_CARS_AU'},
                        {text: 'Utilities & Vans', value: 'MOTOV_UTILITIES_&_VANS_AU '},
                    ]
                }

            }
        },

        async push() {

            const observer = this.$refs.observer
            const {valid, status} = await this.validateFormData()

            if(valid) {
                
                const {purchases} =  this.resources
                const purchasesReqs = []

                let payload = {
                    type: purchases.type,
                    id: purchases.id,
                    attributes: {
                        lender: {
                            macquarie: {
                                assetSubtype: purchases.attributes['asset-subtype'],
                                bodyType: purchases.attributes['body-type'],
                                lvrBand: purchases.attributes['lvr-band'],
                            }
                        }
                    }
                }

                let req = this.updatePurchases(payload)

                purchasesReqs.push(req)


                purchasesReqs.push(this.updateLoan({
                    ...this.resources.loans,
                    id: this.getLoanId
                }))

                await Promise.all(purchasesReqs) 

                return {valid, status, data: {}}

            } else {

                return {valid, status, data: {}}
            }

        },

    },

}
</script>

<style>
</style>

