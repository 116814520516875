export default async (context) => {
  const embed = context.query.embed ?? '';
  const access_token = context.query.access_token ?? '';

  if (embed && access_token) {
    context.$auth.setUserToken(access_token);
    context.$api.setHeader('Authorization', 'Bearer ' + access_token);

    setEmbedData({
      embed,
      access_token,
      iframeSrc: context.route.path
    });
  }
}

export function getEmbedData() {
  return JSON.parse(sessionStorage.getItem('flex_app_embed_data'));
}

export function setEmbedData(data) {
  sessionStorage.setItem('flex_app_embed_data', JSON.stringify(data));
}

export function hasEmbedData() {
  return Boolean(getEmbedData());
}

export function isApplicationFlow() {
  return window.location.href.indexOf('/flows/') > 0;
}

export function isEmbeddedApplicationFlow() {
  console.log(hasEmbedData() && getEmbedData().iframeSrc.indexOf('/applications/') > 0)
  return hasEmbedData() && getEmbedData().iframeSrc.indexOf('/applications/') > 0;
}
