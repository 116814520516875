<template>
    <v-btn :color="color" outlined class="" 
    :large="large"
    :small="small"
    @click="handleEvent"
    @hover="handleEvent"
    :disabled="disabled"
    :outlined="outlined"
    :min-width="minWidth"
    
    :style="styles"
    :fab="fab"

     depressed nuxt>
        <slot name="content">{{ label }}</slot>
    </v-btn>
</template>

<script>

    import block from '~/components/shared/block' 

    export default {
        mixins: [
            block, 
        ],
        props: {
            settings: {
                type: Object,
                default: () => {}
            },
            color: {
                type: String,
                default: "primary"
            },
            styles: {
                type: String,
                default: ""
            },
            large: {
                type: Boolean,
                default: true
            },
            small: {
                type: Boolean,
                default: false
            },
            disabled: {
                type: Boolean,
                default: false
            },
            outlined: {
                type: Boolean,
                default: false
            },
            fab: {
                type: Boolean,
                default: false
            },
            minWidth: {
                type: [String, Number],
                default: "auto"
            },
        },
        data() {
            return {
                
            }
        },
        mounted(){
            // console.log(this.settings)
        },
        computed: {
            label(){
                
                const {settings} = this

                let label = "Button1"

                if(settings && settings.label) {
                    label = settings.label
                }

                return label
            },
            listeners(){
                
                const {settings} = this

                let listeners = null

                if(settings && settings.listeners && settings.listeners.length > 0) {

                    let newObj = {}

                    settings.listeners.forEach(lister => {

                        if(!newObj.hasOwnProperty(lister.event.name)) {
                            newObj[lister.event.name] = lister.method
                        }

                    });

                    listeners = newObj

                }

                return listeners
            },
        },
        methods: {
            handleEvent($e) {

                const {listeners} = this

                if( $e.type == 'hover') {
                    // Do hover stuff
                }

                if( $e.type == 'click' && listeners.hasOwnProperty('components.click')) {

                    let args = listeners['components.click']?.arguments

                    //  fn will resolve to e.g.  "this.__block_flexRedirect"
                    let fn =  this[`__block_${listeners['components.click']['name']}`]

                    if(typeof fn === 'function') {

                        fn(args)
                    }

                }


            }
        }
    }
</script>


<style lang="scss" scoped>


</style>