import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _0a66b6bd = () => interopDefault(import('../pages/401.vue' /* webpackChunkName: "pages/401" */))
const _0a82e5bf = () => interopDefault(import('../pages/403.vue' /* webpackChunkName: "pages/403" */))
const _0a90fd40 = () => interopDefault(import('../pages/404.vue' /* webpackChunkName: "pages/404" */))
const _3f692100 = () => interopDefault(import('../pages/503.vue' /* webpackChunkName: "pages/503" */))
const _11c28210 = () => interopDefault(import('../pages/error.vue' /* webpackChunkName: "pages/error" */))
const _091beb9c = () => interopDefault(import('../pages/group-select.vue' /* webpackChunkName: "pages/group-select" */))
const _dadc5698 = () => interopDefault(import('../pages/login/index.vue' /* webpackChunkName: "pages/login/index" */))
const _5754e04b = () => interopDefault(import('../pages/register.vue' /* webpackChunkName: "pages/register" */))
const _aca3505a = () => interopDefault(import('../pages/report-incident.vue' /* webpackChunkName: "pages/report-incident" */))
const _d761ac38 = () => interopDefault(import('../pages/account/expand.vue' /* webpackChunkName: "pages/account/expand" */))
const _ac21a6aa = () => interopDefault(import('../pages/account/forgot-password.vue' /* webpackChunkName: "pages/account/forgot-password" */))
const _a807b4f0 = () => interopDefault(import('../pages/account/set.vue' /* webpackChunkName: "pages/account/set" */))
const _d01456a6 = () => interopDefault(import('../pages/account/set-2.vue' /* webpackChunkName: "pages/account/set-2" */))
const _066cabad = () => interopDefault(import('../pages/login/okta/callback.vue' /* webpackChunkName: "pages/login/okta/callback" */))
const _7f0c4078 = () => interopDefault(import('../pages/login/okta/redirect.vue' /* webpackChunkName: "pages/login/okta/redirect" */))
const _26b1dfee = () => interopDefault(import('../pages/login/salesforce/redirect.vue' /* webpackChunkName: "pages/login/salesforce/redirect" */))
const _77514784 = () => interopDefault(import('../pages/account/reset/_token.vue' /* webpackChunkName: "pages/account/reset/_token" */))
const _6407384c = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _e22193d6 = () => interopDefault(import('../pages/_group_slug/index.vue' /* webpackChunkName: "pages/_group_slug/index" */))
const _2d9a1173 = () => interopDefault(import('../pages/_group_slug/2fa/index.vue' /* webpackChunkName: "pages/_group_slug/2fa/index" */))
const _5fc6d8ea = () => interopDefault(import('../pages/_group_slug/abn-lookup/index.vue' /* webpackChunkName: "pages/_group_slug/abn-lookup/index" */))
const _6f35158c = () => interopDefault(import('../pages/_group_slug/bible/index.vue' /* webpackChunkName: "pages/_group_slug/bible/index" */))
const _2720ca51 = () => interopDefault(import('../pages/_group_slug/broker-hub/index.vue' /* webpackChunkName: "pages/_group_slug/broker-hub/index" */))
const _43f61863 = () => interopDefault(import('../pages/_group_slug/contacts/index.vue' /* webpackChunkName: "pages/_group_slug/contacts/index" */))
const _5ecf3acc = () => interopDefault(import('../pages/_group_slug/dashboard/index.vue' /* webpackChunkName: "pages/_group_slug/dashboard/index" */))
const _6b37b14b = () => interopDefault(import('../pages/_group_slug/flows/index.vue' /* webpackChunkName: "pages/_group_slug/flows/index" */))
const _6b1c1642 = () => interopDefault(import('../pages/_group_slug/glasses-guide/index.vue' /* webpackChunkName: "pages/_group_slug/glasses-guide/index" */))
const _5de1df22 = () => interopDefault(import('../pages/_group_slug/home/index.vue' /* webpackChunkName: "pages/_group_slug/home/index" */))
const _eef5e8a2 = () => interopDefault(import('../pages/_group_slug/integrations/index.vue' /* webpackChunkName: "pages/_group_slug/integrations/index" */))
const _5896d92c = () => interopDefault(import('../pages/_group_slug/login.vue' /* webpackChunkName: "pages/_group_slug/login" */))
const _064abbfc = () => interopDefault(import('../pages/_group_slug/quote/index.vue' /* webpackChunkName: "pages/_group_slug/quote/index" */))
const _0d510360 = () => interopDefault(import('../pages/_group_slug/scenario/index.vue' /* webpackChunkName: "pages/_group_slug/scenario/index" */))
const _ff1384e6 = () => interopDefault(import('../pages/_group_slug/servicing-calculator/index.vue' /* webpackChunkName: "pages/_group_slug/servicing-calculator/index" */))
const _669779ee = () => interopDefault(import('../pages/_group_slug/simplekyc/index.vue' /* webpackChunkName: "pages/_group_slug/simplekyc/index" */))
const _0141b38f = () => interopDefault(import('../pages/_group_slug/startapp/index.vue' /* webpackChunkName: "pages/_group_slug/startapp/index" */))
const _b3bec6aa = () => interopDefault(import('../pages/_group_slug/tick-flick/index.vue' /* webpackChunkName: "pages/_group_slug/tick-flick/index" */))
const _4bd99fbd = () => interopDefault(import('../pages/_group_slug/applications/b2b-landing.vue' /* webpackChunkName: "pages/_group_slug/applications/b2b-landing" */))
const _57dca600 = () => interopDefault(import('../pages/_group_slug/applications/invite-completed-landing.vue' /* webpackChunkName: "pages/_group_slug/applications/invite-completed-landing" */))
const _7d65b242 = () => interopDefault(import('../pages/_group_slug/applications/invite-landing.vue' /* webpackChunkName: "pages/_group_slug/applications/invite-landing" */))
const _95a1ca32 = () => interopDefault(import('../pages/_group_slug/applications/invite-landing-2.vue' /* webpackChunkName: "pages/_group_slug/applications/invite-landing-2" */))
const _5e59a92b = () => interopDefault(import('../pages/_group_slug/b2c/home/index.vue' /* webpackChunkName: "pages/_group_slug/b2c/home/index" */))
const _496efe05 = () => interopDefault(import('../pages/_group_slug/broker-hub/broker-resources/index.vue' /* webpackChunkName: "pages/_group_slug/broker-hub/broker-resources/index" */))
const _9b20554e = () => interopDefault(import('../pages/_group_slug/broker-hub/lenders/index.vue' /* webpackChunkName: "pages/_group_slug/broker-hub/lenders/index" */))
const _a2a5c432 = () => interopDefault(import('../pages/_group_slug/home/backup-home.vue' /* webpackChunkName: "pages/_group_slug/home/backup-home" */))
const _0b7350ea = () => interopDefault(import('../pages/_group_slug/my-apps/archived.vue' /* webpackChunkName: "pages/_group_slug/my-apps/archived" */))
const _51fe6748 = () => interopDefault(import('../pages/_group_slug/my-apps/draft.vue' /* webpackChunkName: "pages/_group_slug/my-apps/draft" */))
const _0cac264d = () => interopDefault(import('../pages/_group_slug/my-apps/lost.vue' /* webpackChunkName: "pages/_group_slug/my-apps/lost" */))
const _0cc346a3 = () => interopDefault(import('../pages/_group_slug/my-apps/quote.vue' /* webpackChunkName: "pages/_group_slug/my-apps/quote" */))
const _22570c39 = () => interopDefault(import('../pages/_group_slug/my-apps/scenario.vue' /* webpackChunkName: "pages/_group_slug/my-apps/scenario" */))
const _fac59b40 = () => interopDefault(import('../pages/_group_slug/my-apps/settled.vue' /* webpackChunkName: "pages/_group_slug/my-apps/settled" */))
const _acba8bbc = () => interopDefault(import('../pages/_group_slug/my-apps/submitted.vue' /* webpackChunkName: "pages/_group_slug/my-apps/submitted" */))
const _df7b0ce0 = () => interopDefault(import('../pages/_group_slug/completed-flows/b2b/completed/_flow/_application/index.vue' /* webpackChunkName: "pages/_group_slug/completed-flows/b2b/completed/_flow/_application/index" */))
const _33259474 = () => interopDefault(import('../pages/_group_slug/completed-flows/b2b/on-complete/_flow/_application/index.vue' /* webpackChunkName: "pages/_group_slug/completed-flows/b2b/on-complete/_flow/_application/index" */))
const _313f2b22 = () => interopDefault(import('../pages/_group_slug/completed-flows/b2c/completed/_flow/_application/index.vue' /* webpackChunkName: "pages/_group_slug/completed-flows/b2c/completed/_flow/_application/index" */))
const _3a8ac993 = () => interopDefault(import('../pages/_group_slug/completed-flows/b2c/on-complete/_flow/_application/index.vue' /* webpackChunkName: "pages/_group_slug/completed-flows/b2c/on-complete/_flow/_application/index" */))
const _7992b946 = () => interopDefault(import('../pages/_group_slug/flow-select/contact/_app_guid.vue' /* webpackChunkName: "pages/_group_slug/flow-select/contact/_app_guid" */))
const _493e670a = () => interopDefault(import('../pages/_group_slug/flow-select/full-app/_app_guid.vue' /* webpackChunkName: "pages/_group_slug/flow-select/full-app/_app_guid" */))
const _3e68c6b0 = () => interopDefault(import('../pages/_group_slug/flow-select/preliminary-app/_app_guid.vue' /* webpackChunkName: "pages/_group_slug/flow-select/preliminary-app/_app_guid" */))
const _579653d8 = () => interopDefault(import('../pages/_group_slug/quote/templates/_id.vue' /* webpackChunkName: "pages/_group_slug/quote/templates/_id" */))
const _c3586554 = () => interopDefault(import('../pages/_group_slug/simplekyc/tree-view/_id.vue' /* webpackChunkName: "pages/_group_slug/simplekyc/tree-view/_id" */))
const _a6c76cf2 = () => interopDefault(import('../pages/_group_slug/assister/applications/_app_guid/indicative-quote/index.vue' /* webpackChunkName: "pages/_group_slug/assister/applications/_app_guid/indicative-quote/index" */))
const _09ed7170 = () => interopDefault(import('../pages/_group_slug/assister/applications/_app_guid/snapshot/index.vue' /* webpackChunkName: "pages/_group_slug/assister/applications/_app_guid/snapshot/index" */))
const _0bb6e90e = () => interopDefault(import('../pages/_group_slug/b2b/applications/_app_guid/indicative-quote/index.vue' /* webpackChunkName: "pages/_group_slug/b2b/applications/_app_guid/indicative-quote/index" */))
const _0b6dab1b = () => interopDefault(import('../pages/_group_slug/broker-hub/lenders/_lender/resources/index.vue' /* webpackChunkName: "pages/_group_slug/broker-hub/lenders/_lender/resources/index" */))
const _0307b975 = () => interopDefault(import('../pages/_group_slug/bible/_loan_type/index.vue' /* webpackChunkName: "pages/_group_slug/bible/_loan_type/index" */))
const _72db8280 = () => interopDefault(import('../pages/_group_slug/expand/_guid.vue' /* webpackChunkName: "pages/_group_slug/expand/_guid" */))
const _77f77af7 = () => interopDefault(import('../pages/_group_slug/flows/_flow_slug/index.vue' /* webpackChunkName: "pages/_group_slug/flows/_flow_slug/index" */))
const _84bb7df8 = () => interopDefault(import('../pages/_group_slug/my-app/_app_guid.vue' /* webpackChunkName: "pages/_group_slug/my-app/_app_guid" */))
const _6b4d3cea = () => interopDefault(import('../pages/_group_slug/quote/_id.vue' /* webpackChunkName: "pages/_group_slug/quote/_id" */))
const _8b7b544c = () => interopDefault(import('../pages/_group_slug/startapp/_quote_id.vue' /* webpackChunkName: "pages/_group_slug/startapp/_quote_id" */))
const _b2e862bc = () => interopDefault(import('../pages/_group_slug/bible/_loan_type/low-doc.vue' /* webpackChunkName: "pages/_group_slug/bible/_loan_type/low-doc" */))
const _c4f49d9a = () => interopDefault(import('../pages/_group_slug/bible/_loan_type/other-income.vue' /* webpackChunkName: "pages/_group_slug/bible/_loan_type/other-income" */))
const _7cae2bd4 = () => interopDefault(import('../pages/_group_slug/flows/_flow_slug/_steps/index.vue' /* webpackChunkName: "pages/_group_slug/flows/_flow_slug/_steps/index" */))
const _69605b8d = () => interopDefault(import('../pages/_group_slug/flows/_flow_slug/_steps/_app_guid/index.vue' /* webpackChunkName: "pages/_group_slug/flows/_flow_slug/_steps/_app_guid/index" */))
const _4f73ca21 = () => interopDefault(import('../pages/_group_slug/flows/_flow_slug/_steps/_app_guid/_record/_record_id/index.vue' /* webpackChunkName: "pages/_group_slug/flows/_flow_slug/_steps/_app_guid/_record/_record_id/index" */))
const _5a2f0f18 = () => interopDefault(import('../pages/_group_slug/_page/index.vue' /* webpackChunkName: "pages/_group_slug/_page/index" */))
const _5cb55782 = () => interopDefault(import('../pages/_group_slug/_user_type/leads/_lead_id/expand.vue' /* webpackChunkName: "pages/_group_slug/_user_type/leads/_lead_id/expand" */))
const _30bae4e7 = () => interopDefault(import('../pages/_.vue' /* webpackChunkName: "pages/_" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/401",
    component: _0a66b6bd,
    name: "401"
  }, {
    path: "/403",
    component: _0a82e5bf,
    name: "403"
  }, {
    path: "/404",
    component: _0a90fd40,
    name: "404"
  }, {
    path: "/503",
    component: _3f692100,
    name: "503"
  }, {
    path: "/error",
    component: _11c28210,
    name: "error"
  }, {
    path: "/group-select",
    component: _091beb9c,
    name: "group-select"
  }, {
    path: "/login",
    component: _dadc5698,
    name: "login"
  }, {
    path: "/register",
    component: _5754e04b,
    name: "register"
  }, {
    path: "/report-incident",
    component: _aca3505a,
    name: "report-incident"
  }, {
    path: "/account/expand",
    component: _d761ac38,
    name: "account-expand"
  }, {
    path: "/account/forgot-password",
    component: _ac21a6aa,
    name: "account-forgot-password"
  }, {
    path: "/account/set",
    component: _a807b4f0,
    name: "account-set"
  }, {
    path: "/account/set-2",
    component: _d01456a6,
    name: "account-set-2"
  }, {
    path: "/login/okta/callback",
    component: _066cabad,
    name: "login-okta-callback"
  }, {
    path: "/login/okta/redirect",
    component: _7f0c4078,
    name: "login-okta-redirect"
  }, {
    path: "/login/salesforce/redirect",
    component: _26b1dfee,
    name: "login-salesforce-redirect"
  }, {
    path: "/account/reset/:token?",
    component: _77514784,
    name: "account-reset-token"
  }, {
    path: "/",
    component: _6407384c,
    name: "index"
  }, {
    path: "/:group_slug",
    component: _e22193d6,
    name: "group_slug"
  }, {
    path: "/:group_slug/2fa",
    component: _2d9a1173,
    name: "group_slug-2fa"
  }, {
    path: "/:group_slug/abn-lookup",
    component: _5fc6d8ea,
    name: "group_slug-abn-lookup"
  }, {
    path: "/:group_slug/bible",
    component: _6f35158c,
    name: "group_slug-bible"
  }, {
    path: "/:group_slug/broker-hub",
    component: _2720ca51,
    name: "group_slug-broker-hub"
  }, {
    path: "/:group_slug/contacts",
    component: _43f61863,
    name: "group_slug-contacts"
  }, {
    path: "/:group_slug/dashboard",
    component: _5ecf3acc,
    name: "group_slug-dashboard"
  }, {
    path: "/:group_slug/flows",
    component: _6b37b14b,
    name: "group_slug-flows"
  }, {
    path: "/:group_slug/glasses-guide",
    component: _6b1c1642,
    name: "group_slug-glasses-guide"
  }, {
    path: "/:group_slug/home",
    component: _5de1df22,
    name: "group_slug-home"
  }, {
    path: "/:group_slug/integrations",
    component: _eef5e8a2,
    name: "group_slug-integrations"
  }, {
    path: "/:group_slug/login",
    component: _5896d92c,
    name: "group_slug-login"
  }, {
    path: "/:group_slug/quote",
    component: _064abbfc,
    name: "group_slug-quote"
  }, {
    path: "/:group_slug/scenario",
    component: _0d510360,
    name: "group_slug-scenario"
  }, {
    path: "/:group_slug/servicing-calculator",
    component: _ff1384e6,
    name: "group_slug-servicing-calculator"
  }, {
    path: "/:group_slug/simplekyc",
    component: _669779ee,
    name: "group_slug-simplekyc"
  }, {
    path: "/:group_slug/startapp",
    component: _0141b38f,
    name: "group_slug-startapp"
  }, {
    path: "/:group_slug/tick-flick",
    component: _b3bec6aa,
    name: "group_slug-tick-flick"
  }, {
    path: "/:group_slug/applications/b2b-landing",
    component: _4bd99fbd,
    name: "group_slug-applications-b2b-landing"
  }, {
    path: "/:group_slug/applications/invite-completed-landing",
    component: _57dca600,
    name: "group_slug-applications-invite-completed-landing"
  }, {
    path: "/:group_slug/applications/invite-landing",
    component: _7d65b242,
    name: "group_slug-applications-invite-landing"
  }, {
    path: "/:group_slug/applications/invite-landing-2",
    component: _95a1ca32,
    name: "group_slug-applications-invite-landing-2"
  }, {
    path: "/:group_slug/b2c/home",
    component: _5e59a92b,
    name: "group_slug-b2c-home"
  }, {
    path: "/:group_slug/broker-hub/broker-resources",
    component: _496efe05,
    name: "group_slug-broker-hub-broker-resources"
  }, {
    path: "/:group_slug/broker-hub/lenders",
    component: _9b20554e,
    name: "group_slug-broker-hub-lenders"
  }, {
    path: "/:group_slug/home/backup-home",
    component: _a2a5c432,
    name: "group_slug-home-backup-home"
  }, {
    path: "/:group_slug/my-apps/archived",
    component: _0b7350ea,
    name: "group_slug-my-apps-archived"
  }, {
    path: "/:group_slug/my-apps/draft",
    component: _51fe6748,
    name: "group_slug-my-apps-draft"
  }, {
    path: "/:group_slug/my-apps/lost",
    component: _0cac264d,
    name: "group_slug-my-apps-lost"
  }, {
    path: "/:group_slug/my-apps/quote",
    component: _0cc346a3,
    name: "group_slug-my-apps-quote"
  }, {
    path: "/:group_slug/my-apps/scenario",
    component: _22570c39,
    name: "group_slug-my-apps-scenario"
  }, {
    path: "/:group_slug/my-apps/settled",
    component: _fac59b40,
    name: "group_slug-my-apps-settled"
  }, {
    path: "/:group_slug/my-apps/submitted",
    component: _acba8bbc,
    name: "group_slug-my-apps-submitted"
  }, {
    path: "/:group_slug/completed-flows/b2b/completed/:flow?/:application",
    component: _df7b0ce0,
    name: "group_slug-completed-flows-b2b-completed-flow-application"
  }, {
    path: "/:group_slug/completed-flows/b2b/on-complete/:flow?/:application",
    component: _33259474,
    name: "group_slug-completed-flows-b2b-on-complete-flow-application"
  }, {
    path: "/:group_slug/completed-flows/b2c/completed/:flow?/:application",
    component: _313f2b22,
    name: "group_slug-completed-flows-b2c-completed-flow-application"
  }, {
    path: "/:group_slug/completed-flows/b2c/on-complete/:flow?/:application",
    component: _3a8ac993,
    name: "group_slug-completed-flows-b2c-on-complete-flow-application"
  }, {
    path: "/:group_slug/flow-select/contact/:app_guid?",
    component: _7992b946,
    name: "group_slug-flow-select-contact-app_guid"
  }, {
    path: "/:group_slug/flow-select/full-app/:app_guid?",
    component: _493e670a,
    name: "group_slug-flow-select-full-app-app_guid"
  }, {
    path: "/:group_slug/flow-select/preliminary-app/:app_guid?",
    component: _3e68c6b0,
    name: "group_slug-flow-select-preliminary-app-app_guid"
  }, {
    path: "/:group_slug/quote/templates/:id?",
    component: _579653d8,
    name: "group_slug-quote-templates-id"
  }, {
    path: "/:group_slug/simplekyc/tree-view/:id?",
    component: _c3586554,
    name: "group_slug-simplekyc-tree-view-id"
  }, {
    path: "/:group_slug/assister/applications/:app_guid?/indicative-quote",
    component: _a6c76cf2,
    name: "group_slug-assister-applications-app_guid-indicative-quote"
  }, {
    path: "/:group_slug/assister/applications/:app_guid?/snapshot",
    component: _09ed7170,
    name: "group_slug-assister-applications-app_guid-snapshot"
  }, {
    path: "/:group_slug/b2b/applications/:app_guid?/indicative-quote",
    component: _0bb6e90e,
    name: "group_slug-b2b-applications-app_guid-indicative-quote"
  }, {
    path: "/:group_slug/broker-hub/lenders/:lender/resources",
    component: _0b6dab1b,
    name: "group_slug-broker-hub-lenders-lender-resources"
  }, {
    path: "/:group_slug/bible/:loan_type",
    component: _0307b975,
    name: "group_slug-bible-loan_type"
  }, {
    path: "/:group_slug/expand/:guid?",
    component: _72db8280,
    name: "group_slug-expand-guid"
  }, {
    path: "/:group_slug/flows/:flow_slug",
    component: _77f77af7,
    name: "group_slug-flows-flow_slug"
  }, {
    path: "/:group_slug/my-app/:app_guid?",
    component: _84bb7df8,
    name: "group_slug-my-app-app_guid"
  }, {
    path: "/:group_slug/quote/:id",
    component: _6b4d3cea,
    name: "group_slug-quote-id"
  }, {
    path: "/:group_slug/startapp/:quote_id",
    component: _8b7b544c,
    name: "group_slug-startapp-quote_id"
  }, {
    path: "/:group_slug/bible/:loan_type/low-doc",
    component: _b2e862bc,
    name: "group_slug-bible-loan_type-low-doc"
  }, {
    path: "/:group_slug/bible/:loan_type/other-income",
    component: _c4f49d9a,
    name: "group_slug-bible-loan_type-other-income"
  }, {
    path: "/:group_slug/flows/:flow_slug/:steps",
    component: _7cae2bd4,
    name: "group_slug-flows-flow_slug-steps"
  }, {
    path: "/:group_slug/flows/:flow_slug/:steps/:app_guid",
    component: _69605b8d,
    name: "group_slug-flows-flow_slug-steps-app_guid"
  }, {
    path: "/:group_slug/flows/:flow_slug/:steps/:app_guid/:record/:record_id",
    component: _4f73ca21,
    name: "group_slug-flows-flow_slug-steps-app_guid-record-record_id"
  }, {
    path: "/:group_slug/:page",
    component: _5a2f0f18,
    name: "group_slug-page"
  }, {
    path: "/:group_slug/:user_type/leads/:lead_id?/expand",
    component: _5cb55782,
    name: "group_slug-user_type-leads-lead_id-expand"
  }, {
    path: "/*",
    component: _30bae4e7,
    name: "all"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
