<template>
   <v-card flat>

        <div v-if="selectedLender && hasLogo">
            
            <v-card 
                :class="$vuetify.breakpoint.mdOnly ? 'mt-4 pt-7 px-2' : 'pt-6'"
                class="light_gray_1" 
                :height="$vuetify.breakpoint.mdOnly ? 250 : 300"
                flat
            >

                <p class="title pt-2 font-weight-bold text-center">Preferred Lender</p>
                <v-card-text>
    
                    <p class="body-2 font-weight-bold text-uppercase mt-n2 mb-n6 light_gray_2--text text-center">
                        {{ selectedLender['name'] }}
                    </p>
                    
                    <div class="text-center">
                        <v-img
                            v-if="selectedLender.details['logo-class']"
                            contain
                            max-height="100"
                            :class="`${$vuetify.breakpoint.lgAndDown ? 'my-6 mx-3 px-4' : 'my-6 mx-8 px-10'} text-center`"
                            :src="getLenderLogo(selectedLender.details['logo-class'])" >
                        </v-img>
                    </div>
                    
                </v-card-text>
            </v-card>
            
        </div>
        <div v-else class="light_gray_1 pt-6">
            <p class="title pt-2 font-weight-bold text-center">Preferred Lender</p>

            <v-card class="light_gray_1" height="150" flat>
                <v-card-text class="text-center py-0 px-0">                
                    <p class="lender-status overline text-uppercase font-weight-bold error--text pa-0 ma-0">
                        Lender not set
                    </p>
                </v-card-text>

                <v-card-text class="py-6 px-0">
                    <p class="lender-description caption text-center font-weight-medium ma-0 mx-4">
                        Please select a lender
                    </p>
                </v-card-text>
            </v-card>

        </div>
    </v-card>
</template>

<script>

    export default {
        name: 'QuotableSelectionBadge',

        props: {
            lenders: {
                type: Array,
                default: () => []
            },
            userConfirmedLender: {
                type: String,
                default: ''
            }
        },

        computed: {
            selectedLender() {

                let select = {}

                this.lenders.forEach( lender => {
                    if ( lender.details.name.toUpperCase() == this.userConfirmedLender.replace(/-/g, ' ').toUpperCase()) {
                        select = lender
                    }
                })   

                return select
            },
            hasLogo() {
                return this.selectedLender?.details?.['logo-class'] ? true : false
            },
        },
        methods: {
            confirmedLender(data) {
                this.selected_lender = data
                this.$emit('user-confirmed', this.selected_lender)

            },
            getLenderLogo(name){

                let lender = require(`~/assets/images/logo/placeholder.png`)

                try {

                    lender = require(`~/assets/images/logo/${name}.png`)
                    return lender

                } catch (error) {

                    console.log('Ooops!..', error)
                    return lender

                }

            }
        },
        watch: {
            selectedLender(newValue) {                
                this.selected_lender = ''
            }
        },
        data() {
            return {
                selected_lender: '',
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>