<template>

    <v-dialog
        v-model="dialog"
        content-class="modal-RequestCallBack3"
        scrollable
        persistent
        :max-width="vb.smAndDown ? 360 : 420">

            <v-card :disabled="saving" elevation="4"  class="rounded-xl">

                <v-card-title class="px-6 pb-0 p" style="position: relative; z-index: 9999;">
                    <v-spacer/>
                    <v-btn icon @click="closeModal">
                        <v-icon color="secondary">mdi-close</v-icon>
                    </v-btn>
                </v-card-title>


                <v-card-text class="px-lg-8 pb-0 mt-3">

                    <div class=" pa-4 mb-6 mx-auto body-1 text-center rounded-xl line-height-18"
                        :style="`background-color: ${hexToRGBA(getActiveTheme.colors.primary, 0.1)}`">
                            Need some Help? Speak with one of our finance experts.
                    </div>


                    <div class="form-fields-sm">

                    <ValidationObserver ref="requestACallFormObserver" tag="form">

                            <div 
                                class="my-0 py-0"
                                v-for="(field, key) in formFields.fields" 
                                :key="key">

                                <ValidationProvider 
                                    v-slot="{ errors }" 
                                    :name="formFields.labels[key]" 
                                    :rules="getRules(key)"  
                                    :vid="key">

                                    <v-text-field 
                                        :type="'text'"
                                        label=""
                                        :placeholder="formFields.labels[key]"
                                        :error-messages="errors"
                                        v-model="formFields.fields[key]"
                                        color="secondary">
                                    </v-text-field>

                                </ValidationProvider>
                            </div>

                    </ValidationObserver>

                    </div>

                </v-card-text>


                <v-card-actions class="d-block text-center pt-0 px-lg-8 pb-8">
                    <v-btn 
                        class="mx-auto" 
                        width="100%" 
                        large
                        color="secondary" 
                        @click="submitForm" 
                        depressed> Request 
                    </v-btn>
                </v-card-actions>

            </v-card>
    </v-dialog>
    
</template>
<script>
    import { mapActions, mapGetters } from 'vuex'
    import veeValidate from '~/components/shared/validation'
    import formHelpers from '~/components/shared/form-helpers'
    export default {
        mixins: [formHelpers, veeValidate],

        props: {
            person: {
                type: Object,
                default: () => {}
            },
            showDialog: {
                type: Boolean,
                default: false
            }
        },

        data() {
            return {
                saving: false,
                dialog: false,
                formFields: {
                    fields: {
                        "first-name": null,
                        "last-name": null,
                        "mobile-phone": null,
                        "email": null,
                    },
                    labels: {
                        "first-name": "First Name",
                        "last-name": "Last Name",
                        "mobile-phone": "Phone",
                        "email": "Email"
                    },
                    visible: {},
                    enabled: {},
                },
                validation_rules: {
                    attributes: {
                        "first-name": "required",
                        "last-name": "required",
                        "mobile-phone": "required|numeric|digits_between:10,10",
                        "email": "required|email"
                    }
                },
            }
        },

        watch: {
            showDialog(val) {
               this.dialog = val
            },
            person(val) {
                if(val?.attributes) {
                    Object.keys(this.formFields.fields).forEach(f => {
                        Object.keys(val.attributes).forEach(attr => {
                            if(f == attr) this.formFields.fields[f] = val.attributes[f]
                        })
                    })
                }
            }
        },

        computed: {
            vb() {
                return this.$vuetify.breakpoint
            },
            ...mapGetters('themes', [
                'getActiveTheme'
            ]),
        },

        async mounted() {
        },

        methods: {
            ...mapActions('resource-actions', [
                'updatePerson',
            ]),
            hexToRGBA(hex, a = 1){
                return this.$options.filters.hexToRGBA(hex, a)
            },
            closeModal() {
                this.$emit('close-modal')
            },
            async submitForm(){

                const valid = await this.$refs.requestACallFormObserver.validate();

                if(valid) {

                    this.saving = true

                    let payload = {
                        type: this.person.type,
                        id: this.person.id,
                        attributes: this.formFields.fields
                    }

                    let personRes = await this.updatePerson(payload)

                    if (personRes.status === 200) {
                        this.closeModal()
                        this.$emit('save-person')
                    }

                } else {

                    //this.closeModal()
                }

                this.saving = false
            }
        }

    }

</script>

<style lang="scss">

    .modal-RequestCallBack3 {

        position: relative !important;

        .avatar {
            position: absolute;
            top: 0;
            left: 0;
            bottom: 5;
            z-index: 9998;
        }

        .form-fields-sm {

            .v-text-field.v-text-field {
                padding-top: 0 !important;

                input:-moz-placeholder,
                input:-ms-input-placeholder {
                    color: var(--v-body_text_color-base) !important;
                }
            }

            .v-messages__message {
                margin-bottom: 0.5rem;
            }
        }

    }
</style>