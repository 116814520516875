export default {
    "Company": {
        "Beneficial Owner": {
            min: 1,
            options: [
                "Company",
                "Partnership",
                "Trust",
                "Individual"
            ]
        },
        "Director": {
            min: 1,
            options: [
                "Individual"
            ]
        },
        "Guarantor": {
            min: 1,
            options: [
                "Company",
                "Trust",
                "Individual"
            ]
        }
    },
    "Partnership": {
        "Partner": {
            min: 2,
            options: [
                "Company",
                "Partnership",
                "Trust",
                "Individual"
            ]
        }
    },
    "Trust": {
        "Beneficial Owner": {
            min: 1,
            options: [
                "Company",
                "Partnership",
                "Trust",
                "Individual"
            ]
        },
        "Trustee": {
            min: 1,
            max: 1,
            options: [
                "Company",
                "Individual"
            ]
        },
        "Guarantor": {
            min: 1,
            options: [
                "Company",
                "Trust",
                "Individual"
            ]
        }
    }
}