let qs = require('qs')

export const  state = () => ({
    groups: [],
    tempUrl: null,
    userGroups: [],
    applicationGroup: null,
    groupsTheme: [],
    selectGroup: false
})

export const getters = {
    groups: (state) => state.groups,
    getApplicationGroup: (state) => state.applicationGroup
}

export const actions = {

    async fetchGroups({commit, getters}, params = { size: 1000 }) {

        let res = await this.$api.get('groups', {
            params: {
                page: params,
                filter: {'can-own-applications': 1},
                fields: {groups: 'name,slug,can-own-applications,active-theme,active-email-theme'}
            },
            paramsSerializer: params => { return qs.stringify(params) }
        })
     
        commit('setGroups', res.data.data)

        return res

    },
    async getGroup({}, id = '') {
        return await this.$api.get(`groups/${id}`)
    },
    async getUserGroups({commit, getters}, params = {}) {
        let res = await this.$api.get(`users/${params.id}`, {
            params: {
                include: params.include,
            },
            paramsSerializer: params => { return qs.stringify(params) }
        })

        if(res?.data?.included) {
            commit('setGroups', res.data.included)
        }

        return res
    }
}

export const  mutations = {
    setGroups(state, payload) {
        state.groups = payload
    },
    setTempUrl(state, payload) {
        state.tempUrl = payload
    },
    setUserGroups(state, payload) {
        state.userGroups = payload
    },
    setGroupsTheme(state, payload) {
        state.groupsTheme = payload
    },
    setSelectGroup(state, payload) {
        state.selectGroup = payload
    },
    setApplicationGroup(state, payload) {
        state.applicationGroup = payload
    },
}