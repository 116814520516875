<template>
    <v-dialog
        v-model="dialog"
        persistent
        max-width="1124px">

        <template 
            v-slot:activator="{on}">
            <slot 
                name="activator" 
                v-bind:on="toggle">
            </slot>
        </template>

        <v-card 
            tile flat 
            class="rounded-lg flex-dialog" 
            :disabled="saving">

            <v-card-title>
                <v-subheader class="pa-0">
                    <slot name="subheader"></slot>
                </v-subheader>
                <v-spacer />
                <v-btn icon @click="close" >
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-card-title>
                    
            <v-card 
                class="overflow-y-auto" 
                tile flat height="100%">

                <v-card-text class="pa-0 pa-md-8 pt-0 pt-md-0">

                    <v-card 
                        tile flat
                        class="rounded-lg mx-auto"
                        width="766px">
                        
                        <slot name="title-1"></slot>

                        <v-card-text>
                            <ValidationObserver ref="observer1" tag="form">

                                <v-container>
                                    <v-row>

                                        <v-col cols="12" class="py-0">

                                            <GoogleAddressTool
                                                name="address"
                                                label="Address Search"
                                                v-model="fullAddress" 
                                                @change="handleAddressToolChange" />

                                        </v-col>

                                        <v-col cols="12" sm="6" md="4" 
                                                class="py-0"
                                                v-for="(val, key) in resources.addresses.attributes"
                                                :key="key">

                                            <ValidationProvider 
                                                v-slot="{ errors }" 
                                                :name="labels.addresses[key]" 
                                                :rules="getRules(key)" :vid="key">

                                                <v-select
                                                    v-if="isFieldType('picklist', key)"
                                                    :items="getOptions(key)"
                                                    :label="labels.addresses[key]"
                                                    :error-messages="errors"
                                                    v-model="resources.addresses.attributes[key]"
                                                    color="secondary">
                                                </v-select>

                                                <v-text-field 
                                                    v-else
                                                    :type="'text'"
                                                    :label="labels.addresses[key]"
                                                    :error-messages="errors"
                                                    v-model="resources.addresses.attributes[key]"
                                                    color="secondary">
                                                </v-text-field>

                                            </ValidationProvider>

                                        </v-col>

                                    </v-row>
                                </v-container>

                            </ValidationObserver>
                        </v-card-text>

                    </v-card>
                </v-card-text>
            </v-card>

            <v-divider></v-divider>

            <v-card-actions 
                class="pa-4 pa-md-8 flex-wrap controls">

                <v-btn 
                    @click="save"
                    color="secondary" 
                    :min-width="140" 
                    large elevation="0" 
                    nuxt>Save</v-btn>
                <v-spacer></v-spacer>
                <v-btn 
                    color="light_gray_2"
                    @click="close"
                    :min-width="140" 
                    large outlined 
                    elevation="0" 
                    nuxt>Back</v-btn>

            </v-card-actions>
            
        </v-card>
        
    </v-dialog>
</template>

<script>

    import { mapActions, mapState, mapGetters, mapMutations } from 'vuex'
    import formHelpers from '~/components/shared/form-helpers.js'
    import validation from '~/components/shared/validation'
    import GoogleAddressTool from '~/components/base/GoogleAddressTool'
    import FlexDatePicker from '~/components/base/FlexDatePicker'

    export default {
        name: 'AddressEditorDialog1',

        mixins: [validation, formHelpers],

        components: {
            GoogleAddressTool,
            FlexDatePicker
        },

        model: {
            prop: 'modelValue',
            event: 'input'
        },

        props: {
            modelValue: {
                type: Boolean,
                default: false
            },
            data: {
                type: Object,
                default: null
            },
            person: {
                type: Object,
                default: null
            },
            address: {
                type: Object,
                default: null
            },
            occupancy: {
                type: String,
                default: ''
            },
            occupancyResources: {
                type: Object,
                default: null
            },
            addressType: {
                type: String,
                default: ''
            }
        },        

        created() {},

        mounted(){
            this.mapResources(this.resources)                        
        },

        computed: {
            ...mapState({
                appId: state => state.flows.appId,
            }),

            getPersonId(){
                return this.person ? this.person.id : this.resources.people.id || null
            },

            getAddressId(){
                return this.address.id || null
            },

            getOccupancyId() {
                return this.temporary_occupation ? this.temporary_occupation : this.occupancy
            }
        },
        methods: {
            ...mapActions('resource-actions', [
                'getAddress',
                'createAddress',
                'updateAddress',
                'createOccupancy',
                'updateOccupancy'
            ]),

            ...mapActions('flows', [
                'fetchAppData'
            ]),

            ...mapMutations('flows', ['updateAppData']),

            toggle(){
                this.dialog = !this.dialog
            },
            
            close(){
                this.reset()
                this.toggle()
                this.$emit('close')
            },

            handleAddressToolChange(address, obj){

                this.resources.addresses.attributes = {
                    "street-number": obj["street-number"],
                    "street-name": obj["street-name"],
                    "street-type": obj["street-type"],
                    "suburb": obj["suburb"],
                    "state": obj["state"],
                    "postcode": obj["postcode"]
                }

            },

            async save(){                
                
                let addressReq = null;
                let data = null

                this.saving = true

                this.mapResources(this.resources)
                
                const { addresses } = this.resources

                if( this.getAddressId ) {
                    
                    this.updateEmitted(addresses)
                    addresses.id = this.getAddressId
                    
                    addressReq = await this.updateAddress(addresses)

                    if ( addressReq.status === 200 ) {
                        this.$emit('update-address', {
                            type: this.addressType,
                            data: {
                                attributes: addressReq.data.data.attributes,
                                id: addressReq.data.data.id,
                                relationships: addressReq.data.data.relationships,
                                type: 'addresses'
                            }
                        })
                    }

                    this.close()

                } 
                else {
                    
                    if ( !this.getOccupancyId ) {
                        
                        this.$emit('no-existing-records', 
                            {
                                type: this.addressType,
                                payload: addresses
                            }
                        )

                        this.updateEmitted(addresses)
                        
                    }
                    else {
                        
                        this.updateEmitted(addresses)

                        addressReq = await this.createAddress(addresses)

                        if ( addressReq.status === 200 ) {
                            this.$emit('update-address', {
                                type: this.addressType,
                                data: {
                                    attributes: addressReq.data.data.attributes,
                                    id: addressReq.data.data.id,
                                    relationships: addressReq.data.data.relationships,
                                    type: 'addresses'
                                }
                            })
                        }
                    }                    

                    this.close()
                    
                }                

                this.saving = false
                
            },

            async forceCreateOccupancy() {
                let occRes = await this.createOccupancy({                    
                    "type": "occupancies",
                    "attributes": {
                        "end-date": null,
                        "estimated-term-in-months": this.occupancyResources?.[this.addressType]?.attributes?.['estimated-term-in-months'],
                        "occupancy-situation": this.addressType === 'current' ? "Current" : "Previous",
                        "residential-situation": null,
                        "start-date": null
                    },
                    "relationships": {
                        "application": {
                            "data": {
                                "type": "applications",
                                "id": this.appId
                            }
                        }
                    }
                })
                // temporary_occupation
                if ( occRes.status === 201 ) {
                    this.temporary_occupation = occRes.data.data.id
                }
            },

            async processResponses(args){

                try {

                    return await Promise.all(args).then(res => {
                        console.log('args :>> ', args);
                        // add handler of requests error 
                        let items = []
                        res.forEach(data => {
                            items.push(data.data)
                        })
                        return items
                    })

                } catch (error) {

                    console.log('Ooops!..', error)

                    return {}
                }

            },
            
            mapResources(data){
                
                const { people, addresses } = this.resources

                if(data){
                    // clone data to remove reactivity
                    this.resources.people = {...data.people}
                    
                    this.resources.people = this.resourceFieldPicker(people, {...data.people})
                    
                    if(data.addresses){
                        this.resources.addresses = {...data.addresses}
                        this.resources.addresses = this.resourceFieldPicker(addresses, {...data.addresses})
                    }
                }

                let relationships = {
                    application: {
                        data: {
                            type: "applications",
                            id: this.appId
                        }
                    },
                    occupancies: {
                        data: [
                            {
                                type: 'occupancies',
                                id: this.getOccupancyId
                            }
                        ]
                    }
                }

                this.resources.addresses.relationships = relationships                

            },
            
            async reset(){

                this.fullAddress = ''

                this.resources = {
                    people: {
                        type: 'people',
                        attributes: {}
                    },
                    addresses: {
                        type: 'addresses',
                        attributes: {
                            "street-number": null,
                            "street-name": null,
                            "street-type": null,
                            "suburb": null,
                            "state": null,
                            "postcode": null
                        },
                        relationships: {
                            application: {
                                data: { type: "applications", id: this.appId } 
                            },
                            occupancies: {
                                data: [{ type: "occupancies", id: null}]
                            }
                        }
                    }
                }

                // Reset validation

                if(this.$refs.observer1){
                    await this.$refs.observer1.reset()
                }
            },

            updateEmitted(val) {
                
                this.resources.addresses.attributes = {
                    "street-number": val.attributes["street-number"],
                    "street-name": val.attributes["street-name"],
                    "street-type": val.attributes["street-type"],
                    "suburb": val.attributes["suburb"],
                    "state": val.attributes["state"],
                    "postcode": val.attributes["postcode"]
                }

                this.$emit(
                    'preloaded-value', 
                    {
                        type: this.addressType,
                        address: `${val.attributes["street-number"]} ${val.attributes["street-name"]}, ${val.attributes["suburb"]} ${val.attributes["state"]} ${val.attributes["postcode"]}`
                    }
                )
            }
        },

        watch: {
            modelValue(value){
                this.dialog = value
            },

            occupancy: {
                handler(val) {
                    if ( val )
                        this.mapResources(this.resources)
                },
                deep: true
            },

            address: {
                handler(val) {
                    if ( this.address?.id ) {
                        this.updateEmitted(val)                        
                    }
                },
                deep: true
            }

        },

        data(){
            return {
                mode: 'create', // edit
                dialog: false,
                fullAddress: '',
                saving: false,
                temporary_occupation: null,
                labels: {
                    addresses : {
                        "street-number": "Street Number",
                        "street-name": "Street Name",
                        "street-type": "Street Type",
                        "suburb": "Suburb",
                        "state": "State",
                        "postcode": "Postcode"
                    }
                },
                resources: {
                    people: {
                        type: 'people',
                        attributes: {}
                    },
                    addresses: {
                        type: 'addresses',
                        attributes: {
                            "street-number": null,
                            "street-name": null,
                            "street-type": null,
                            "suburb": null,
                            "state": null,
                            "postcode": null
                        },
                        relationships: {
                            application: {
                                data: { type: "applications", id: null } 
                            },
                            occupancies: {
                                data: [{ type: "applications", id: null }]
                            }
                        }
                    }
                },
                validation_rules: {
                    attributes: {
                        "street-number" : "required|string|max:40",
                        "street-name"   : "required|string|max:255",
                        "street-type"   : "required|string|max:50",
                        "suburb": "required|string|max:80",
                        "state" : "required|in:required|in:Australian Capital Territory,New South Wales,Northern Territory,Queensland,South Australia,Tasmania,Victoria,Western Australia,Outside Australia",   
                        "postcode": "required|string|max:20|exists:postcodes,value",
                        "residential-situation": 'required|in:Boarder,Employer Provided,Living With Parents,Mortgage - Owner Occupied,Own Outright,Renter - Agent,Renter - Private,Mortgage,Renter Agent,Renter Private',
                        "occupancy-situation": "required|in:Current,Previous",
                        "start-date": "required|date_format:d/m/Y|before:now",
                        "end-date": "required_if:occupancy-situation,Previous|date_format:d/m/Y|before:now"
                    }
                }
            }
        },
    }
</script>

<style lang="scss" scoped>

    .v-card__title {


        .v-subheader  {
            display: block;
            line-height: 1.4;
            height: auto;
        }
    }

</style>