import moment from 'moment'

export const  state = () => ({
    showPrivacy: true,
    privacyDisclosure: null,
    activeApplicant: {
        id: null,
        type: null,
        attributes: {
            'first-name': null,
            'last-name': null,
            'email': null,
            'mobile-phone': null,
        }
    },

    privacyUpdater: 0,
    disablePrivacyButton: true,
    privacyButtonLabel: 'Send Privacy',
    privacyButtonTooltip: null,
    canSendPrivacy: false
})


// getters
export const  getters = {
    getShowPrivacy: (state, getters, rootState) => {
        return state.showPrivacy
    },
    getPrivacyButtonTooltip: (state, getters, rootState) => {
        return state.privacyButtonTooltip
    },
    getActiveApplicant: (state, getters, rootState) => {
        return state.activeApplicant
    },
    getPrivacyDisclosure: (state, getters, rootState) => {
        return state.privacyDisclosure
    },
    getCanSendPrivacy: (state, getters, rootState) => {
        return state.canSendPrivacy
    },
}

// actions
export const actions = {
    getPrivacyDisclosure({commit, getters, dispatch, rootState}, payload){

        if(!getters.getShowPrivacy) return;
        if(!getters.getCanSendPrivacy) return;

        commit('setActiveApplicant', payload)

        if(getters.getActiveApplicant?.id) {

            dispatch('resource-actions/getPrivacyDisclosures', {appGuid: rootState.flows.appGuid, personId: getters.getActiveApplicant.id}, {root: true}).then(res => {

                let privacy = res?.status == 200 ? res.data.data : null

                commit('setPrivacyDisclosure', privacy)

                // console.log('getPrivacyDisclosure', privacy)

                if(privacy){

                    if ( privacy.expires_at) {
                        if(moment(privacy.expires_at).diff(moment(), 'days') <= 0) {
                            commit('setPrivacyButtonLabel', 'Resend Required')
                        }
                    }

                    if(privacy.signed) {
                        commit('setPrivacyButtonLabel', 'Privacy Signed')

                    } else {
                        commit('setPrivacyButtonLabel',  'Privacy Sent')
                    }

                } else {
                    commit('setPrivacyButtonLabel',  'Send Privacy')
                }


            })
        }
    },
    checkDiffSetActiveApplicant({commit, getters, dispatch, rootState}, payload){

        let fields = ['first-name', 'last-name', 'email', 'mobile-phone']

        let newValues = fields.map(field => {
            return payload?.attributes?.[field]
        }).join('')

        let oldValues = fields.map(field => {
            return getters.getActiveApplicant.attributes?.[field]
        }).join('')

        if(newValues != oldValues) {
            commit('setActiveApplicant', payload)
            commit('setPrivacyButtonLabel',  'Send Privacy')
            commit('resetPrivacyDisclosure')
        }

    },
    resetPrivacyAndApplicant({commit, getters, dispatch, rootState}, payload){
        commit('resetPrivacyDisclosure')
        commit('resetActiveApplicant')
    },
    checkPrivacyDisclosure({commit, getters, dispatch, rootState}, {disabler = true}){

            // Reset tooltip message and canSendPrivacy
            commit('setPrivacyButtonTooltip', null)
            commit('setCanSendPrivacy', false)

            dispatch('resource-actions/checkPrivacyDisclosure', {appGuid: rootState.flows.appGuid}, {root: true}).then( async res => {

                if(res?.status == 200) {

                    if(disabler){
                        commit('setDisablePrivacyButton', false)
                    }

                    commit('setCanSendPrivacy', true)

                } else {

                    commit('setDisablePrivacyButton', true)
                    commit('setCanSendPrivacy', false)
                    commit(
                        'setPrivacyButtonTooltip',
                        "It appears we are missing important information for your compliance document generation. Please send an email asset.partners+privacy@lmg.broker and we will respond with a simple form for you to complete so we can update your information and get the documents working."
                    )
                }

            })

    },
}

// mutations
export const  mutations = {
    setPrivacyDisclosure (state, payload) {
        state.privacyDisclosure = payload
    },
    setActiveApplicant (state, payload) {

        let newPayload = {
            id: payload.id,
            type: payload.type,
            attributes:{
                'first-name': payload?.attributes?.['first-name'],
                'last-name': payload?.attributes?.['last-name'],
                'email': payload?.attributes?.['email'],
                'mobile-phone': payload?.attributes?.['mobile-phone'],
            }
        }

        state.activeApplicant = newPayload
    },
    resetPrivacyDisclosure(state){
        state.privacyDisclosure = null
    },
    resetActiveApplicant(state){
        state.activeApplicant =  {
            id: null,
            type: null,
            attributes: {
                'first-name': null,
                'last-name': null,
                'email': null,
                'mobile-phone': null,
            }
        }
    },

    setActiveApplicantAttributes (state, payload) {
        state.activeApplicant.attributes[payload.key] = payload.value
    },

    setPrivacyUpdater(state){
        state.privacyUpdater = Date.now()
    },
    setPrivacyButtonLabel(state, payload){
        state.privacyButtonLabel = payload
    },
    setDisablePrivacyButton(state, payload){
        state.disablePrivacyButton = payload
    },
    setPrivacyButtonVisibility(state, payload){
        state.showPrivacy = payload
    },
    setPrivacyButtonTooltip(state, payload){
        state.privacyButtonTooltip = payload
    },
    setCanSendPrivacy(state, payload){
        state.canSendPrivacy = payload
    },

}
