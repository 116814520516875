<template>
    <div>
        <v-img 
            v-if="isValid"
            @error="onImageLoadFailure($event)"
            :lazy-src="img"
            :src="img"
            :aspect-ratio="1"
            height="55" width="55" >
        </v-img>
        <v-icon 
            v-else
            color="light_gray_2"
            x-large
            class="light_gray_1" >
            mdi-cart
        </v-icon>
    </div>    
</template>

<script>
    export default {
        name: 'ImageFetcher',

        props: {
            item: {
                type: String,
                default: ''
            }
        },

        mounted() {
            this.img = this.fetchAutoImage(this.item)
        },

        methods: {
            fetchAutoImage(key) {
                if (this.isValid)
                    return `http://autoedgenet.com.au/Pictures/Auto/${key}.jpg`
            },

            onImageLoadFailure( evt ) {
                // this.img = require('~/assets/images/logo/symbol.png')
                this.isValid = false
            },
        },

        data() {
            return {
                img: '',
                isValid: true
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>