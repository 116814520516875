<template>
    <FlexWrap @resize="handleResize">
        <v-data-table
            loading-text="Please wait..."  
            no-data-text="No Results Found"                                          
            :headers="tableHeaders"
            :items="lenders"
            hide-default-footer
            class="elevation-0 v-data-table-5"
            single-expand
            :expanded.sync="expanded"
            :items-per-page="-1"
            item-key="name"
            show-expand
            :mobile-breakpoint="0">

            <!-- Headers -->
            <template #[`header.lender`]="{ header }">
                <div class="start">{{ header.text }}</div>
            </template>

            <!-- Body -->
            <template #[`item.lender`]="{ item }">
                <div class="inner start rounded-corner-left">
                    <v-img 
                        v-if="item.details['logo-class'] != 'placeholder'"
                        max-width="70"
                        height="70"
                        class="flex-brand" 
                        :src="getLenderLogo(item.details['logo-class'])" 
                        position="center left" 
                        contain>
                    </v-img>
                    <div v-else class="body-1 font-weight-bold">
                        {{ getLenderNameBySlug(item.details.name) }}
                    </div>
                </div>
                
            </template>

            <template #[`item.loan-term`]="{ item }">
                <div class="inner blocks">
                    <p class="ma-0 pa-0 font-weight-medium">
                        {{ item.details['actual-payment']['term-in-months']  }} mths
                    </p>
                </div>
            </template>

            <template #[`item.repayments`]="{ item }">
                <div class="inner blocks">
                    <p class="ma-0 pa-0 font-weight-medium text-no-wrap">
                        {{ item.details['actual-payment']['naf'] | currency }}
                    </p>
                </div>
            </template>

            <template #[`item.customer-rate`]="{ item }">
                <div class="inner blocks">                                                    
                    <p class="ma-0 pa-0 font-weight-medium">
                        {{ (item.details['actual-payment']['customer-rate'] ) | percentage }}
                    </p>
                </div>
            </template>

            <template #[`item.payment-terms`]="{ item }">
                <div class="inner blocks">                                                    
                    <p class="ma-0 pa-0 font-weight-medium">
                        {{ item.inputs['payment-terms'] }}
                    </p>
                </div>
            </template>

            <template #[`item.balloon-payment`]="{ item }">
                <div class="inner blocks">                                                    
                    <p class="ma-0 pa-0 font-weight-medium">
                        {{ (item.details['actual-payment']['balloon'] ) | currency }}
                    </p>
                </div>
            </template>

            <template #[`item.bank-fees`]="{ item }">
                <div class="inner blocks">
                    <p class="ma-0 pa-0 font-weight-medium text-no-wrap">
                        {{ item.details['actual-payment']['bank-fees'] | currency }}
                    </p>
                </div>
            </template>

            <template #[`item.monthly-fees`]="{ item }">
                <div class="inner blocks">
                    <p class="ma-0 pa-0 font-weight-medium">
                        {{ item.details['actual-payment']['account-fee'] | currency }}
                    </p>
                </div>
            </template>

            <template #[`item.origination-fees`]="{ item }">
                <div class="inner blocks">
                    <p class="ma-0 pa-0 font-weight-medium">
                        {{ item.details['actual-payment']['broker-fee'] | currency }}
                    </p>
                </div>
            </template>

            <template #[`item.brokerage-commission`]="{ item }">
                <div class="inner blocks">
                    <p class="ma-0 pa-0 font-weight-medium">
                        {{ item.details['actual-payment']['commission-inc-gst'] | currency }}
                    </p>
                </div>
            </template>

            <template #[`item.total-commission`]="{ item }">
                <div class="inner blocks">
                    <p class="ma-0 pa-0 font-weight-medium text-no-wrap">
                        {{ (parseFloat(item.details['actual-payment']['commission-inc-gst']) + parseFloat(item.details['actual-payment']['broker-fee'])) | currency }}
                    </p>
                </div>
            </template>

            <template #[`item.monthly-payment`]="{ item }">
                <div class="inner blocks">
                    <p class="ma-0 pa-0 font-weight-medium text-no-wrap">
                        {{ item.details['actual-payment']['monthly-payment'] | currency }}/mo
                    </p>
                </div>
            </template>

            <template #[`item.data-table-expand`]="{ item, isExpanded }">
                <div class="inner blocks">
                    <v-btn
                        v-if="showEdit(item)"
                        @click="editLender(item)"
                        fab text                    
                        depressed
                        x-small
                        dark
                        class="mx-1"
                        color="primary">
                        <v-icon size="22">
                            mdi-pencil
                        </v-icon>
                    </v-btn>
                    <v-btn
                        v-else
                        @click="clicked(item)"
                        :class="{'disable-events': isUnsecured}"
                        fab text                    
                        depressed
                        x-small
                        dark
                        class="mx-1"
                        color="primary">
                        <v-icon size="25" v-if="!isExpanded">
                            mdi-information-outline
                        </v-icon>
                        <v-icon v-if="isExpanded" dark>
                            mdi-information
                        </v-icon>
                    </v-btn>
                </div>
            </template>

            <template #[`item.table-select`]="{ item }">
                <div class="inner blocks rounded-corner-right">
                    <v-btn
                        v-if="hasPrimary && item.details.name == selectedLenderObj.primary['bid-lender'].attributes['lender-name']"
                        class="primary"
                        depressed
                        large
                        @click="resetSelectedLender"
                    >
                        Primary
                    </v-btn>
                     <v-btn
                        v-else-if="hasSecondary && item.details.name == selectedLenderObj.secondary['bid-lender'].attributes['lender-name']"
                        class="primary"
                        depressed
                        large
                        @click="resetSelectedLender"
                    >
                        Secondary
                    </v-btn>
                    <v-btn v-else class="light_gray_2 primary--text" depressed large @click="preselectLender(item)">Select</v-btn>
                </div>
            </template>

            <template v-slot:expanded-item="{ headers, item }" transition="scale-transition">
                        
                <td :colspan="headers.length"
                    :class="{'ma-0 pa-0 rounded-lg': true, 'expanded-closing': !transitioned[headers]}"
                    style="height: auto;">

                    <div class="d-flex pa-2">
                        <v-spacer></v-spacer>
                        <v-icon color="primary" @click="clicked(item)">
                            mdi-close
                        </v-icon>
                    </div>

                    <div class="px-0 pb-4 mt-n9">
                        <v-row class="px-4">
                            <v-col cols="4" v-if="false">
                                <div class="body-2 primary--text font-weight-bold">
                                    Common Supporting Documents
                                </div>
                            </v-col>
                             <v-col cols="6">
                                <div class="body-2 primary--text font-weight-bold">
                                    Advance Information
                                </div>
                            </v-col>
                             <v-col cols="6" v-if="false">
                                <div class="body-2 primary--text font-weight-bold">
                                    Lender Resources
                                </div>
                            </v-col>
                        </v-row>
                        <v-divider ></v-divider>
                        <v-row class="px-4">
                            <v-col cols="4" v-if="false">
                                <!-- Common Supporting Documents -->
                            </v-col>
                             <v-col cols="6">
                                <!-- Advance Information -->
                                <div class="d-flex">
                                    <div class="pt-3 pr-4 caption font-weight-medium">
                                        <div>Base Rate</div>
                                        <div>Brokerage Commission</div>
                                        <div>Origination Fee</div>
                                    </div>
                                    <div class="pt-3 caption">
                                        <div>{{ forceAddDecimalPlaces(item.details['actual-payment']['base-rate']) | percentage }} </div>
                                        <div>{{ item.details['actual-payment']['commission-inc-gst'] | currency }}</div>
                                        <div>{{ item.details['actual-payment']['broker-fee'] | currency }}</div>
                                    </div>
                                </div>
                            </v-col>
                             <v-col cols="6"  v-if="false">
                                <!-- Lender Resources -->
                            </v-col>
                        </v-row>
                    </div>

                </td>
            </template>

        </v-data-table>

        <QuoteToolLenderModal
            :selected-lender="selectedLender"
            :dialog="dialog"
            :required-loan-amount="requiredLoanAmount"
            :title="formTitle"
            :sub-title="formSubTitle"
            @save-primary-lender="$emit('save-primary-lender')"
            @close-modal="dialog = false" />

        <ConfirmModal 
            v-if="dialog2"
            async
            color="warning"
            title="Reset"
            confirmText="Reset"
            :message="message"
            :value="dialog2"
            :standalone="false"
            :processing="saving"
            @close="handleClose"
            @confirm="handleConfirm" />


    </FlexWrap>
</template>
<script>
import { mapState, mapActions } from 'vuex'
import FlexWrap from '~/components/base/FlexWrap'
import helpers from '~/plugins/helpers'
import QuoteToolLenderModal from './_QuoteToolLenderModal'
import ConfirmModal from '~/components/base/ConfirmModal2'
import { store, mutations } from './__store'
import { isEmpty } from 'lodash'

export default {
    mixins: [ helpers ],
    components: {
        FlexWrap,
        QuoteToolLenderModal,
        ConfirmModal
    },
    props: {
        lenders: {
            type: Array,
            default: () => []
        },
        requiredLoanAmount: {
            type: [String, Number],
            default: ""
        },
        assisters: {
            type: Array,
            default: () => []
        }
    },
    computed: {
        selectedLenderObj() {
            return store.selectedLenderObj
        },
        hasPrimary() {
            return isEmpty(this.selectedLenderObj.primary['bid-lender']?.id) ? false : true
        },

        hasSecondary() {
            return isEmpty(this.selectedLenderObj.secondary['bid-lender']?.id) ? false : true
        },
        isUnsecured() {
            return store.isUnsecured
        },
        tableHeaders() {

            if ( !this.sizes ) return

            const { sm, xs } = this.sizes

            let newHeaders = [
                ...this.headers
            ]

            let heads = []

            // customized tables data if unsecured
            if(store.isUnsecured) {
                heads = [
                    { text: 'Lender', sortable: false, value: 'lender', width: '10%'},
                    { text: 'Amount Financed', sortable: true, value: 'repayments', width: '8%'},
                    { text: 'Loan Term', sortable: true, value: 'loan-term', width: '10%'},
                    { text: 'Customer Rate', sortable: false, value: 'customer-rate', width: '7%'},
                    { text: 'Bank Fees', sortable: true, value: 'bank-fees', width: '10%'},
                    { text: 'Monthly Fee', sortable: true, value: 'monthly-fees', width: '10%'},
                    { text: 'Origination Fee', sortable: true, value: 'origination-fees', width: '7%'},
                    { text: 'Brokerage Commission', sortable: true, value: 'brokerage-commission', width: '10%'},
                    { text: 'Total Commission', sortable: true, value: 'total-commission', width: '10%'},
                    { text: 'Monthly Payment', sortable: true, value: 'monthly-payment', width: '10%'},
                    { sortable: false, value: 'data-table-expand', width: '5%'},
                    { sortable: false, value: 'table-select', width: '5%'}
                ]
            } else {
                heads = [
                    { text: 'Lenders', sortable: false, value: 'lender', width: '10%'},
                    { text: 'Amount Financed', sortable: true, value: 'repayments', width: '8%'},
                    { text: 'Loan Term', sortable: true, value: 'loan-term', width: '10%'},
                    { text: 'Payment Terms', sortable: false, value: 'payment-terms', width: '7%'},
                    { text: 'Balloon payment', sortable: false, value: 'balloon-payment', width: '7%'},
                    { text: 'Customer Rate', sortable: false, value: 'customer-rate', width: '7%'},
                    { text: 'Bank Fees', sortable: true, value: 'bank-fees', width: '10%'},
                    { text: 'Monthly Fee', sortable: true, value: 'monthly-fees', width: '10%'},
                    { text: 'Total Commission', sortable: true, value: 'total-commission', width: '10%'},
                    { text: 'Monthly Payment', sortable: true, value: 'monthly-payment', width: '10%'},
                    { sortable: false, value: 'data-table-expand', width: '5%'},
                    { sortable: false, value: 'table-select', width: '5%'}
                ]
            }
            
            if ( sm ) {                    
                heads = [ 
                    { value: 'lender', width: '20%' },
                    { value: 'repayments', width: '15%' },
                    { value: 'loan-term', width: '15%' },
                    { value: 'customer-rate', width: '15%' },
                    { value: 'data-table-expand', width: '5%'},
                    { value: 'table-select', width: '5%'}
                ]
            }

            if(xs){
                heads = [ 
                    { value: 'lender', width: '20%' },
                    { value: 'loan-term', width: '20%' },
                    { value: 'customer-rate', width: '20%' },
                    { value: 'data-table-expand', width: '5%'},
                    { value: 'table-select', width: '5%'}
                ]
            }

            // return newHeaders.filter( head => heads.includes(head.text))
            return newHeaders.reduce((accumulator, current) => {

                heads.forEach(head => {

                    if(head.value == current.value){

                        let newCurrent = {...current}

                        newCurrent.width = head.width

                        accumulator.push(newCurrent)
                    }

                })

                return accumulator 

            }, [])

        },
        getLendersSlugAlgorithms(){
            return store.lenderSlugAlgorithms
        },
    },
    methods: {
        ...mapActions('resource-actions', [
            'deleteBidLender',
            'createBidLender',
            'updateBidLender',
            'createBid',
            'updateBid'
        ]),
        forceAddDecimalPlaces(val){

            let input = val + ''
            input = input.split('.')

            if(input && input.length == 1){
                return val + '.00'
            } else {
                return val + ''
            }
        },
        async handleConfirm() {

            this.saving = true

            let res = await this.deleteBidLender(this.bidLenderId)

            if(res.status === 204) {

                if(this.message.includes('Secondary')) {
                    mutations.resetSecondary()
                } else {
                    mutations.resetPrimary()
                    mutations.setSelectedPrimaryLender(null)
                    this.$emit('reset-primary')
                }

                this.dialog2 = false
                this.saving = false
            }

        },
        handleClose() {
            this.dialog2 = false
        },
        resetSelectedLender() {
            if(this.hasSecondary) {
                this.message = "Reset Secondary Lender?"
                this.dialog2 = true
                this.bidLenderId = this.selectedLenderObj.secondary['bid-lender'].id
            } else if(this.hasPrimary) {
                this.message = "Reset Primary Lender?"
                this.dialog2 = true
                this.bidLenderId = this.selectedLenderObj.primary['bid-lender'].id
            }
        },
        getLenderLogo(name){
            
            let lender = null

            if(!name)
                return lender

            try {

                lender = require(`~/assets/images/logo/${name}.png`)
                return lender

            } catch (error) {

                console.log('Ooops!..', error)
                return lender

            }
            

        },
        clicked (value) {

            const index = this.expanded.indexOf(value)
            
            if (index === -1) {                    
                this.expanded.push(value)
            } else {
                this.expanded.splice(index, 1)
            }

        },
        preselectLender(item = {}) {

            if(!this.hasPrimary) {
                // if don't have primary selected
                this.selectedLender = item
                this.formTitle = "Primary Lender Recommendation"
                this.formSubTitle = "Please provide the rationale for why this is the primary recommendation"
                this.dialog = true
            } else if(this.hasPrimary && !this.hasSecondary) {
                // has primary selected then select for secondary
                this.selectedLender = item
                this.formTitle = "Alternative Lender Recommendation"
                this.formSubTitle = "Please provide the rationale for why this is the alternative recommendation"
                this.dialog = true
            } else {
                this.resetSelectedLender()
            }

        },
        getLenderNameBySlug(name){
            let algo = this.getLendersSlugAlgorithms.find(slug => name == slug["lender-slug-algorithm"])
            if(algo){
                return algo?.key
            } else {
                return name
            }
        },
        showEdit(item) {
            
            if(item?.['custom-lender']) {
                return true
            }

            return false
        },
        editLender(item) {
            this.$emit('edit-lender', item)
        }
    },
    data() {
        return {
            dialog2: false,
            saving: false,
            message: '',
            formTitle: 'Lender',
            formSubTitle: '',
            bidLenderId: null,

            expanded: [],
            selectedLender: {},
            dialog: false,
            transitioned: [],
            singleExpand: false,
            headers: [
                { text: 'Lenders', sortable: false, value: 'lender', width: '10%'},
                { text: 'Amount Financed', sortable: true, value: 'repayments', width: '8%'},
                { text: 'Loan Term', sortable: true, value: 'loan-term', width: '10%'},
                { text: 'Payment Terms', sortable: false, value: 'payment-terms', width: '7%'},
                { text: 'Balloon payment', sortable: false, value: 'balloon-payment', width: '7%'},
                { text: 'Customer Rate', sortable: false, value: 'customer-rate', width: '7%'},
                { text: 'Bank Fees', sortable: true, value: 'bank-fees', width: '10%'},
                { text: 'Monthly Fee', sortable: true, value: 'monthly-fees', width: '10%'},
                { text: 'Origination Fee', sortable: true, value: 'origination-fees', width: '7%'},
                { text: 'Brokerage Commission', sortable: true, value: 'brokerage-commission', width: '10%'},
                { text: 'Total Commission', sortable: true, value: 'total-commission', width: '10%'},
                { text: 'Monthly Payment', sortable: true, value: 'monthly-payment', width: '10%'},
                { sortable: false, value: 'data-table-expand', width: '5%'},
                { sortable: false, value: 'table-select', width: '5%'}
            ],
        }
    },
}
</script>
<style lang="scss" scoped>
    .disable-events {
        pointer-events: none
    }
</style>