<template>
  <div>
    <div class="mb-2 mb-md-8">
      <div class="d-flex align-center">
        <div>
          <h1
            class="flex__step-header-title line-height-32 pb-4"
            v-if="vb.mdAndUp"
          >
            Your top{{ data.length == 3 ? " 3" : " " }} loan matches
          </h1>
          <h1 class="font-size-14 font-weight-medium" v-else>
            Your top{{ data.length == 3 ? " 3" : " " }} loan matches
          </h1>

          <p v-if="vb.mdAndUp">
            How does your choice compare to other lenders? Hit the
            <span class="font-weight-medium">'Compare Lenders'</span> button to
            find out.
          </p>
        </div>
        <v-spacer></v-spacer>

        <div class="d-flex align-center">
          <div class="body-2 font-weight-medium pr-4" v-if="vb.mdAndUp">
            Repayments Term
          </div>
          <div :style="`max-width: ${vb.mdAndUp ? '200px' : '172px'}`">
            <v-select
              class="pt-4 ma-0"
              v-model="repaymentsTermField"
              :items="['Monthly', 'Fortnightly', 'Weekly', 'Annual']"
              color="secondary"
            ></v-select>
          </div>
        </div>
      </div>
    </div>

    <v-sheet class="mx-auto mb-8 mb-md-16" elevation="0">
      <div class="d-flex" v-if="vb.width >= 1180">
        <template v-for="(lender, i) in data" v-key="i">
          <Card1
            @show-info="showInfoDialog"
            @show-apply="showApplyModal(lender)"
            :hide-lender="hideLender"
            :lender="lender"
            :index="i"
          />
          <!-- spacer -->
          <v-spacer
            style="min-width: 40px"
            v-if="i < data.length - 1"
          ></v-spacer>
        </template>
      </div>

      <div v-else>
        <v-row no-gutters>
          <v-col v-for="(lender, index) in data" :key="`${index}-1`" cols="4">
            <v-card tile elevation="0" class="py-5">
              <div class="text-center">
                <v-img
                  max-height="20"
                  :max-width="vb.mdAndUp ? 100 : 82"
                  class="flex-brand mx-auto"
                  :src="getLenderLogo(lender['logo-class'])"
                  :class="lender.quotable == 'No' ? 'img-grayscale' : ''"
                  position="center center"
                  contain
                >
                </v-img>
              </div>
            </v-card>
          </v-col>

          <v-col cols="12">
            <h3 class="text-center my-1 font-size-12">
              {{ repaymentsTermField }} Repayment Amount
            </h3>
          </v-col>

          <v-col v-for="(lender, index) in data" :key="`${index}-2`" cols="4">
            <v-card tile elevation="0" class="py-3">
              <div class="text-center font-weight-bold">
                <span v-if="lender.quotable == 'No'">-</span>
                <span v-else>{{ getMonthlyAmount(
                    lender["actual-payment"]["monthly-payment"],
                    getRepaymentsTermField
                  ) | currency }}</span>
              </div>
            </v-card>
          </v-col>

          <v-col cols="12">
            <h3 class="text-center my-1 font-size-12">Term</h3>
          </v-col>

          <v-col v-for="(lender, index) in data" :key="`${index}-3`" cols="4">
            <v-card tile elevation="0" class="py-3">
              <div class="text-center font-weight-bold">
                <span v-if="lender.quotable == 'No'">-</span>
                <span v-else>{{ parseInt(lender["actual-payment"]["term-in-months"]) }} months</span>
              </div>
            </v-card>
          </v-col>

          <v-col cols="12">
            <h3 class="text-center my-1 font-size-12">Interest Rate</h3>
          </v-col>

          <v-col v-for="(lender, index) in data" :key="`${index}-4`" cols="4">
            <v-card tile elevation="0" class="py-3">
              <div class="text-center font-weight-bold">
                <span v-if="lender.quotable == 'No'">-</span>
                <span v-else>{{ lender["actual-payment"]["customer-rate"] | percentage }}</span>
              </div>
            </v-card>
          </v-col>

          <v-col cols="12">
            <h3 class="text-center my-1 font-size-12">Comparison Rate</h3>
          </v-col>

          <v-col v-for="(lender, index) in data" :key="`${index}-5`" cols="4">
            <v-card tile elevation="0" class="py-3">
              <div class="text-center font-weight-bold">
                <span v-if="lender.quotable == 'No'">-</span>
                <span v-else>{{ lender["actual-payment"]["comparison-rate"] | percentage }}</span>
              </div>
            </v-card>
          </v-col>

          <v-col cols="12" class="mt-5 mb-5" v-if="!showMoreDetails">
            <div class="block">
              <div class="text-center">
                <v-btn
                  @click="moreDetails"
                  x-small
                  tile
                  plain
                  depressed
                  class="more-less-btn"
                  >More Information</v-btn
                >
              </div>
            </div>
          </v-col>

          <v-col class="" v-if="showMoreDetails">
            <v-row no-gutters>
              <v-col cols="12">
                <h3 class="text-center my-1 font-size-12">Loan Amount</h3>
              </v-col>
              <v-col
                v-for="(lender, index) in data"
                :key="`${index}-6`"
                cols="4"
              >
                <v-card tile elevation="0" class="py-3">
                  <div class="text-center font-weight-bold">
                    {{ requiredAmount | currency }}
                  </div>
                </v-card>
              </v-col>

              <v-col cols="12">
                <h3 class="text-center my-1 font-size-12">Origination Fee</h3>
              </v-col>
              <v-col
                v-for="(lender, index) in data"
                :key="`${index}-7`"
                cols="4"
              >
                <v-card tile elevation="0" class="py-3">
                  <div class="text-center font-weight-bold">
                    <span v-if="lender.quotable == 'No'">-</span>
                    <span v-else>{{ lender["actual-payment"]["broker-fee"] | currency }}</span>
                  </div>
                </v-card>
              </v-col>

              <v-col cols="12">
                <h3 class="text-center my-1 font-size-12">Bank Setup Fees</h3>
              </v-col>
              <v-col
                v-for="(lender, index) in data"
                :key="`${index}-8`"
                cols="4"
              >
                <v-card tile elevation="0" class="py-3">
                  <div class="text-center font-weight-bold">
                    <span v-if="lender.quotable == 'No'">-</span>
                    <span v-else> {{ lender["actual-payment"]["bank-fees"] | currency }}</span>
                  </div>
                </v-card>
              </v-col>

              <v-col cols="12">
                <h3 class="text-center my-1 font-size-12">Amount Financed</h3>
              </v-col>
              <v-col
                v-for="(lender, index) in data"
                :key="`${index}-9`"
                cols="4"
              >
                <v-card tile elevation="0" class="py-3">
                  <div class="text-center font-weight-bold">
                    <span v-if="lender.quotable == 'No'">-</span>
                    <span v-else> {{ lender["actual-payment"]["naf"] | currency }}</span>
                  </div>
                </v-card>
              </v-col>
            </v-row>
          </v-col>

          <v-col cols="12" class="mt-5 mb-5" v-if="showMoreDetails">
            <div class="block">
              <div class="text-center">
                <v-btn
                  @click="moreDetails"
                  x-small
                  tile
                  plain
                  depressed
                  class="more-less-btn"
                  >Less Information</v-btn
                >
              </div>
            </div>
          </v-col>

          <v-col v-for="(lender, index) in data" :key="`${index}-10`" cols="4">
            <v-card tile elevation="0" class="py-3">
              <div class="text-center">
                <v-btn
                  v-if="lender.quotable !== 'No'"
                  color="primary"
                  class="px-6 font-size-12"
                  small
                  depressed
                  tile
                  @click="showApplyModal(lender)"
                  >Apply</v-btn
                >

                <v-btn
                  disabled
                  v-else
                  color="primary"
                  class="px-6 font-size-12"
                  small
                  style="width:100%; max-width: 80px"
                  depressed
                  tile
                  @click="showApplyModal(lender)"
                  >?</v-btn>
              </div>
            </v-card>
          </v-col>
        </v-row>

        <!-- <template>    
                    <v-tabs v-model="selectedLender" show-arrows height="76" class="mb-2">
                        <v-tab v-for="(lender, index) in data"  >
                            <v-list-item :class="vb.mdAndUp ? 'px-4' : 'px-0'">
                                <v-list-item-content class="text-center">
                                    <v-list-item-title :class="['mb-1', {'font-size-12': !vb.mdAndUp}]">
                                        Option {{ parseInt(index) + 1 }}
                                    </v-list-item-title> 
                                    <v-list-item-subtitle class="mb-2">
                                         <div v-if="hideLender" class="font-weight-bold">
                                            {{ lender['alias'] }}
                                        </div>
                                        <v-img 
                                            v-else 
                                            max-height="20"
                                            :max-width="vb.mdAndUp ? 100 : 82"
                                            class="flex-brand" 
                                            :src="getLenderLogo(lender['logo-class'])" 
                                            position="center center" 
                                            contain>
                                        </v-img>
                                    </v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                        </v-tab>
                    </v-tabs>
                </template>
                 -->

        <!-- <v-tabs-items v-model="selectedLender">
                    <v-tab-item eager v-for="(lender, index) in data" >
                        <Card2
                            
                            @show-info="showInfoDialog" 
                            @show-apply="showApplyModal" 
                            :hide-lender="hideLender"
                            :lender="lender"
                            :index="index"
                            max-width="100%"
                            />
                    </v-tab-item>
                </v-tabs-items> -->
      </div>
    </v-sheet>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState, mapMutations } from "vuex";
import mixins from "~/components/v1/QuoteResult6/_mixins";
import Card1 from "~/components/v1/QuoteResult6/_Card1";
import Card2 from "~/components/v1/QuoteResult6/_Card2";
import { store, actions } from "~/components/v1/QuoteResult6/__store";

export default {
  mixins: [mixins],
  components: {
    Card1,
    Card2,
  },
  props: {
    data: {
      type: Array,
      default: () => {
        [];
      },
    },
    hideLender: {
      type: Boolean,
      default: false,
    },
    requiredAmount: {
      typerequiredAmount: {
        type: [String, Number],
        default: "",
      },
    },
  },
  data() {
    return {
      selectedLender: 0,
      showMoreDetails: false,
    };
  },
  mounted() {},
  computed: {
    repaymentsTermField: {
      get() {
        return store.repaymentsTermField;
      },
      set(val) {
        actions.setRepaymentsTermField(val);
      },
    },
  },
  methods: {
    showInfoDialog(lender) {
      this.$emit("show-info", lender);
    },
    showApplyModal(lender) {
      this.$emit("show-apply", lender);
    },
    moreDetails() {
      console.log(this.showMoreDetails);
      this.showMoreDetails = !this.showMoreDetails;
    },
  },
  watch: {},
};
</script>

<style lang="scss">
.flex__component-QuoteResult6 {
  .loan-details-section {
    .bl-spacer {
      border-right: 1px solid var(--v-light_gray_1-base);
    }
  }
}

.more-less-btn {
  position: relative;
}

.v-ripple__container {
  opacity: 0 !important;
}

.more-less-btn::before {
  display: block !important;
  opacity: 1 !important;
  content: "";
  position: absolute;
  height: 1px;
  width: 100%;
  background-color: var(--v-light_gray_1-darken1) !important;
  top: 40%;
  left: -100%;
}

.more-less-btn::after {
  content: "";
  position: absolute;
  height: 1px;
  width: 100%;
  background-color: var(--v-light_gray_1-darken1) !important;
  top: 40%;
  right: -100%;
}

.img-grayscale .v-image__image.v-image__image--contain{
  filter: grayscale(100%);
}
</style> 