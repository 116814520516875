<template>
        <v-card :disabled="isDisabled" class="mx-auto" tile flat>
            <v-card-text class=" pa-4 pt-0 pa-md-10 pt-md-0" >
                <v-card tile flat class="mx-auto " max-width="1500" >
                    <v-container pa-0 fluid>
                        <ValidationObserver ref="observer" tag="form">

                            <v-row v-for="(layout, lkey) in fieldLayout" :key="lkey">

                                <v-col cols="12" v-if="layout.title" class="py-2">
                                    <div class="title body_text_color--text" >
                                    {{layout.title}}
                                    </div>
                                </v-col>

                                <template v-for="(field, key) in fieldLayoutMaker(layout, resources.people.attributes)">
                                    <v-col cols="12" sm="6" :md="computeMDSize(key)" :key="key" class="py-0">
                                        <ValidationProvider
                                            v-if="visible[key]"
                                            v-slot="{ errors }"
                                            :name="labels[key]"
                                            :rules="getRules(key)" :vid="key">

                                            <ButtonGroup1
                                            v-if="isBtnGroup(key)"
                                            v-model="resources.people.attributes[key]"
                                            :error-messages="errors"
                                            :options="['Yes', 'No']"
                                            :label="labels[key]" />

                                            <v-select
                                            v-else-if="isFieldType('picklist', key)"
                                            :items="getOptions(key)"
                                            :label="labels[key]"
                                            :error-messages="errors"
                                            v-model="resources.people.attributes[key]"
                                            color="secondary">
                                            </v-select>

                                            <template v-else-if="isFieldType('date', key)">
                                                <FlexDatePicker3
                                                    :label="labels[key]"
                                                    :errors="errors"
                                                    v-model="resources.people.attributes[key]">
                                                </FlexDatePicker3>
                                            </template>

                                            <v-text-field
                                            v-else
                                            :type="getFieldType(key)"
                                            :label="labels[key]"
                                            :error-messages="errors"
                                            v-model="resources.people.attributes[key]"
                                            color="secondary">
                                            </v-text-field>
                                        </ValidationProvider>
                                    </v-col>
                                </template>

                            </v-row>
                        </ValidationObserver>
                    </v-container>
                </v-card>
            </v-card-text>
        </v-card>
</template>

<script>

    import subscriptions from '~/components/shared/subscriptions'
    import { mapState, mapGetters, mapActions, mapMutations } from 'vuex'
    import FlexDatePicker3 from '~/components/base/FlexDatePicker3'
    import fieldOptions from '~/components/shared/field-options'
    import FlexWrap from '~/components/base/FlexWrap'
    import helpers from '~/plugins/helpers'
    import formHelpers from '~/components/shared/form-helpers'
    import veeValidate from '~/components/shared/validation'
    import moment from 'moment'
    import ButtonGroup1 from '~/components/base/ButtonGroup1'

    const minAgeDate = moment().subtract(18, 'years').format('MM/DD/YYYY')
    export default {
        name: 'IdentificationEditor1',
        components: {
            FlexDatePicker3,
            FlexWrap,
            ButtonGroup1
        },
        mixins: [subscriptions, helpers,formHelpers,veeValidate],
        data(){
            return {
                resources: {
                    people : {
                        type: "people",
                        id: null,
                        attributes: {
                            "drivers-licence-state": "",
                            "drivers-licence-number": "",
                            "drivers-licence-card-number": "",
                            "drivers-licence-expiry": "",

                            "medicare-position-on-card": "",
                            "medicare-expiry": "",
                            "medicare-colour-of-card": "",
                            "medicare-number": "",

                            "passport-number": "",
                            "passport-expiry": "",
                            "primary-country-of-citizenship": "",
                            "secondary-country-of-citizenship": "",
                            "visa": "",
                            "visa-expiry-date": "",
                            "_dual-citizenship": "No"
                        },
                        relationships: {
                            application: {
                                data: {type: "applications", id: null}
                            }
                        }
                    }
                },
                fieldLayout: [
                    {title: "Identification"},
                    [
                        "drivers-licence-state",
                        "drivers-licence-number",
                        "drivers-licence-card-number",
                        "drivers-licence-expiry",

                        "medicare-number",
                        "medicare-position-on-card",
                        "medicare-expiry",
                        "medicare-colour-of-card",
                    ],
                    {title: "Citizen Information"},
                    [
                        "primary-country-of-citizenship",
                        "_dual-citizenship",
                        "secondary-country-of-citizenship",
                        "passport-number",
                        "passport-expiry",
                        "visa",
                        "visa-expiry-date",

                    ]
                ],
                visible: {
                    "drivers-licence-number": true,
                    "drivers-licence-state": true,
                    "drivers-licence-card-number": false,
                    "drivers-licence-expiry": true,
                    "passport-number": true,
                    "passport-expiry": true,
                    "primary-country-of-citizenship": true,
                    "secondary-country-of-citizenship": false,
                    "_dual-citizenship": true,
                    "visa": true,
                    "visa-expiry-date": true,

                    "medicare-number": true,
                    "medicare-expiry": false,
                    "medicare-position-on-card": false,
                    "medicare-colour-of-card": false,

                },
                // Static field labels. cause sometimes field names and labels are different
                labels: {
                    "drivers-licence-number": "Drivers Licence Number",
                    "drivers-licence-state": "Drivers Licence State",
                    "drivers-licence-card-number": "Licence Card Number",
                    "drivers-licence-expiry": "Expiry",

                    "medicare-position-on-card": "Position on Card",
                    "medicare-expiry": "Medicare Expiry",
                    "medicare-colour-of-card": "Colour of Card",
                    "medicare-number": "Medicare Number",

                    "passport-number": "Passport Number",
                    "passport-expiry": "Passport Expiry",
                    "_dual-citizenship":"Have Dual Citizenship?",
                    "primary-country-of-citizenship": "Primary Country of Citizenship",
                    "secondary-country-of-citizenship": "Secondary Country of Citizenship",
                    "visa": "Visa Number",
                    "visa-expiry-date": "Visa Expiry",
                },
                validation_rules: {
                    type: "people",
                    attributes: {
                        "drivers-licence-state": `required|in:${fieldOptions["drivers-licence-state"]}`,
                        "drivers-licence-number": `string`,
                        "drivers-licence-card-number": `string`,
                        "drivers-licence-expiry": `string|date_format:Y/m/d|after:now`,
                        "passport-number": `string`,
                        "passport-expiry": `string|date_format:Y/m/d|after:now`,
                        "primary-country-of-citizenship": `required|in:${fieldOptions["country-of-citizenship"]}`,
                        "secondary-country-of-citizenship": `required|string|in:${fieldOptions["country-of-citizenship"]}`,
                        "visa": `required|in:${fieldOptions["visa"]}`,
                        "visa-expiry-date": "required|string|date_format:Y/m/d",
                        "_dual-citizenship": "",
                        "medicare-position-on-card": "required|integer|max_value:99",
                        "medicare-expiry": "required|date_format:Y/m/d|after:now",
                        "medicare-colour-of-card": `required|in:${fieldOptions["medicare-colour-of-card"]}`,
                        "medicare-number": "required|numeric|digits_between:10,10",
                    }
                }
            }
        },
        computed: {
            ...mapState({
                appId: state => state.flows.appId
            }),
            ...mapGetters('kinds', [
                'kindsOfApplicant'
            ]),
            isDriversLicenceNumberRequired(){

                let field1 = this.resources.people.attributes["medicare-number"]
                let field2 = this.resources.people.attributes["passport-number"]

                if(field1 && field2) {
                    return false
                }

                return true
            },

            isMedicareNumberRequired(){
                let field1 = this.resources.people.attributes["drivers-licence-number"]
                let field2 = this.resources.people.attributes["passport-number"]

                if(field1 && field2) {
                    return false
                }

                return true
            },
            isPassportRequired(){
                let field1 = this.resources.people.attributes["drivers-licence-number"]
                let field2 = this.resources.people.attributes["medicare-number"]

                if(field1 && field2) {
                    return false
                }

                return true
            },

            primaryCountryOfCitizenship(){
                return this.resources.people.attributes["primary-country-of-citizenship"]
            },
            secondaryCountryOfCitizenship(){
                return this.resources.people.attributes["secondary-country-of-citizenship"]
            },
            isAUorNZ(){

                let citi1 = this.resources.people.attributes["primary-country-of-citizenship"]
                let citi2 = this.resources.people.attributes["secondary-country-of-citizenship"]

                let ref = ["Australia", "New Zealand"]

                if(ref.includes(citi1) || ref.includes(citi2)) {
                    return false
                }

                return true

            },
            dualCitizenship(){
                return this.resources.people.attributes['_dual-citizenship']
            },

            driversLicenceState(){
                return this.resources.people.attributes["drivers-licence-state"]
            },
            peopleId () {

                if(this.$route?.params && this.$route?.params.record == 'people') {

                    return this.$route?.params?.record_id
                }

                return null

            },
            medicareNumber() {
                return this.resources.people.attributes["medicare-number"]
            }
        },
        created() {

        },
        mounted(){


        },
        methods: {
            ...mapActions('resource-actions', [
                'getPeople',
                'getPerson',
                'updatePerson',
                'createPerson'
            ]),
            async pull() {

                if(this.peopleId) {

                    let res = await this.getPerson(this.peopleId)

                    this.resources.people = this.filterResourceFields(this.resources.people, res.data.data)

                } else {

                    let res = await this.getPeople()

                    this.resources.people = this.filterResourceFields(this.resources.people, res.data.data[0])

                }

                let peopleAttr = this.resources.people.attributes

                if(peopleAttr["primary-country-of-citizenship"] && peopleAttr["secondary-country-of-citizenship"]) {
                    this.resources.people.attributes['_dual-citizenship'] = "Yes"
                }

            },
            async push() {

                const observer = this.$refs.observer

                const {people} = this.resources

                // Exclude relationships and other stuff
                // console.log('this.',this.peopleId)

                let payload = {
                    type: people.type,
                    attributes: this.resources.people.attributes,

                }

                let personRes = null

                if(this.peopleId) {

                    payload.id = this.peopleId
                    personRes = this.updatePerson(payload)

                } else {

                    // Include relationships when creating resource

                    payload.relationships = {
                        application: {
                            data: {type: "applications", id: this.appId}
                        }
                    }

                    personRes = this.createPerson(payload)
                }

                const {valid, status} = await this.validateFormData()
                const data = await this.appDataHelper([personRes])

                return {valid, status, data}

            },
            filterResourceFields(source, target){

                // console.log(source)
                // console.log(target)

                let newResource = {
                        type: "",
                        id: null,
                        attributes: {},
                        relationships: {}
                }

                Object.keys(source.attributes).forEach( field => {
                    newResource.attributes[field] = target.attributes[field]
                })

                newResource.type = target.type
                newResource.id = target.id

                if(target.hasOwnProperty('relationships')) {

                    Object.keys(target.relationships).forEach(item => {

                        if( target.relationships[item].hasOwnProperty('data') ) {
                            newResource.relationships[item] = target.relationships[item]
                        }
                    })

                }
                return newResource
            },
            isBtnGroup(key){

                let keys = [
                    "_dual-citizenship"
                ]

                return keys.includes(key)

            },
            computeMDSize(key){

                let defaultMD = 3

                if(key == "primary-country-of-citizenship") {
                    return 6
                }

                return defaultMD
            }
        },
        watch: {
            driversLicenceState(val){

                if(val) {

                    // no drivers licence card number on this state
                    let excludes = [ 'Outside Australia' ]

                    if(excludes.includes(val)) {
                        this.visible['drivers-licence-card-number'] = false
                        this.resources.people.attributes['drivers-licence-card-number'] = null
                        this.validation_rules.attributes["drivers-licence-card-number"] = `string`
                    } else {
                        this.visible['drivers-licence-card-number'] = true
                        this.validation_rules.attributes["drivers-licence-card-number"] = `required|string`
                    }

                }

            },
            isAUorNZ(val){

                if(val) {
                    this.visible['visa'] = true
                    this.visible['visa-expiry-date'] = true
                } else {
                    this.visible['visa'] = false
                    this.visible['visa-expiry-date'] = false
                    this.resources.people.attributes['visa'] = null
                    this.resources.people.attributes['visa-expiry-date'] = null
                }
            },
            dualCitizenship(val){
                if(val == "Yes") {
                    this.visible['secondary-country-of-citizenship'] = true
                } else {
                    this.visible['secondary-country-of-citizenship'] = false
                    this.resources.people.attributes['secondary-country-of-citizenship'] = null
                }
            },

            isDriversLicenceNumberRequired(val){
                if(val) {
                    this.validation_rules.attributes["drivers-licence-number"] = `required|string`
                    this.validation_rules.attributes["drivers-licence-card-number"] = `required|string`
                    this.validation_rules.attributes["drivers-licence-expiry"] = `required|date_format:Y/m/d|after:now`
                } else {
                    this.validation_rules.attributes["drivers-licence-number"] = `string`
                    this.validation_rules.attributes["drivers-licence-card-number"] = `string`
                    this.validation_rules.attributes["drivers-licence-expiry"] = `string|date_format:Y/m/d|after:now`
                }
            },
            isMedicareNumberRequired(val){
                if(val) {
                    this.validation_rules.attributes["medicare-number"] = `required|string`
                } else {
                    this.validation_rules.attributes["medicare-number"] = `string`
                }
            },
            isPassportRequired(val){
                if(val) {
                    this.validation_rules.attributes["passport-number"] = `required|string`
                    this.validation_rules.attributes["passport-expiry"] = `required|date_format:Y/m/d|after:now`
                } else {
                    this.validation_rules.attributes["passport-number"] = `string`
                    this.validation_rules.attributes["passport-expiry"] = `string|date_format:Y/m/d|after:now`
                }
            },

            medicareNumber(val) {
                if(val) {
                    this.visible["medicare-position-on-card"] = true
                    this.visible["medicare-expiry"] = true
                    this.visible["medicare-colour-of-card"] = true

                    this.validation_rules.attributes["medicare-position-on-card"] = `required|integer|max_value:99`
                    this.validation_rules.attributes["medicare-expiry"] = `required|date_format:Y/m/d|after:now`
                    this.validation_rules.attributes["medicare-colour-of-card"] = `required|in:${fieldOptions["medicare-colour-of-card"]}`
                } else {
                    this.visible["medicare-position-on-card"] = false
                    this.visible["medicare-expiry"] = false
                    this.visible["medicare-colour-of-card"] = false

                    this.validation_rules.attributes["medicare-position-on-card"] = ``
                    this.validation_rules.attributes["medicare-expiry"] = ``
                    this.validation_rules.attributes["medicare-colour-of-card"] = ``
                }
            }

        },

    }
</script>

<style scoped lang="scss">
</style>
