<template>
    <div class="flex__sub-component-TextField1">
        <v-text-field
            v-model="val"
            ref="input"
            :label="label"
            :disabled="disabled"
            :error-messages="errors"
            @change="emitData($event)"
            :distraction-free="{hideNegligibleDecimalDigits: false, hideCurrencySymbol: false, hideGroupingSymbol: false}"
            v-currency="{currency: { prefix: ''}, locale: 'en'}"
            :prefix="prefix ? prefix : ''">
        </v-text-field>
    </div>
</template>

<script>

    export default {
        name: 'TextField1',
        model: {
            prop: 'modelValue',
            event: 'change'
        },
        props: {
            modelValue: {
                type: [String, Number],
                default: ''
            },
            label: {
                type: String,
                default: ''
            },
            fieldType: {
                type: String,
                default: 'text'
            },
            disabled: {
                type: Boolean,
                default: false
            },
            errors: {
                type: Array,
                default: ()=>[]
            },
            prefix: {
                type: String,
                default: ''
            },
        },
        methods: {
            emitData(evt) {     
                this.$emit('change', this.val1)
                return
            }
        },
        watch: {
            modelValue(val) {
                if (val) {
                    let temp = val.toString()                    
                    this.val = this.$options.filters.currencyNoSymbol(temp)
                    this.$emit('change', temp)
                }
            },
            val(value) {
                this.val1 = this.$options.filters.currencyPlainDigit(value)
            }
        },
        data() {
            return {
                val: '0',
                val1: '0'
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>