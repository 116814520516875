
export default {

    // Assister
    "assister-view-consumer-asset": "assister-edit-consumer-asset",
    "assister-view-consumer-personal": "assister-edit-consumer-personal",
    "assister-view-commercial-asset": "assister-edit-commercial-asset",
    "assister-view-commercial-funding": "assister-edit-commercial-funding",
    "assister-view-commercial-individual-asset": "assister-edit-commercial-individual-asset",
    "assister-view-commercial-individual-funding": "assister-edit-commercial-individual-funding",

    // Assister BID
    "assister-view-consumer-personal-bid": "assister-edit-consumer-personal-bid",
    "assister-view-consumer-asset-bid": "assister-edit-consumer-asset-bid",
    

    // B2B
    "b2b-view-consumer-asset": "b2b-edit-consumer-asset",
    "b2b-view-consumer-personal": "b2b-edit-consumer-personal",
    "b2b-view-commercial-asset": "b2b-edit-commercial-asset",
    "b2b-view-commercial-funding": "b2b-edit-commercial-funding",
    "b2b-view-commercial-individual-asset": "b2b-edit-commercial-individual-asset",
    "b2b-view-commercial-individual-funding": "b2b-edit-commercial-individual-funding",

    // B2B BID
    "b2b-view-consumer-personal-bid": "b2b-edit-consumer-personal-bid",
    "b2b-view-consumer-asset-bid": "b2b-edit-consumer-asset-bid",
    

}
