<template>
        <v-card tile flat>

            <ValidationObserver ref="observer">

                <template v-for="(layouts, resource) in getActiveFieldLayout">

                    <template v-if="resource == 'incomes'" v-for="layout in layouts">

                        <div class="title my-4  body_text_color--text" v-if="layout.title && showSubTitle">
                            {{layout.title}}
                        </div>

                        <div v-else-if="layout.field">

                            <div class="d-flex flex-column flex-md-row pb-6">
                                <div class="pr-8">
                                    <div class="pl-0 pt-0 pb-2 v-card__title text-no-wrap">Income</div>
                                    <div class="mb-6">
                                        <div class="body-1 font-weight-medium">
                                            {{ `${getKindName(data.id)} ${ development ? data.id : ''}` }}
                                        </div>
                                    </div>
                                </div>

                                <div class="flex-grow-1">
                                    <ValidationProvider
                                        v-slot="{ errors }"
                                        :name="layout.label"
                                        rules="required">

                                        <RoleSelector
                                            v-if="layout.field == 'role-selector'  "
                                            :hidden="selectedEntityAll"
                                            v-model="activeRoles"
                                            :max="1"
                                            :label="layout.label"
                                            :data="entities"
                                            :parts="entityParts"
                                            :errors="errors"/>

                                        <!--
                                            Rental Income condition:
                                            if Commercial, use Role Selector otherwise use Income Sharing
                                         -->
                                        <template v-else-if="layout.field == 'income-sharing'">
                                            <IncomeSharingEditor
                                                v-if="showIncomeSharing()"
                                                class="mt-6"
                                                v-model="activeRoles"
                                                @share-percentage-update="handleSharePercentageUpdateChage"
                                                :label="layout.label"
                                                :entities="entities"
                                                :income="resources.incomes"
                                                :address="selectedAddress"
                                                :parts="entityParts"
                                                :errors="errors"/>
                                            <RoleSelector
                                                v-else
                                                :hidden="selectedEntityAll"
                                                v-model="activeRoles"
                                                :max="1"
                                                :label="layout.label"
                                                :data="entities"
                                                :parts="entityParts"
                                                :errors="errors"/>
                                        </template>


                                        <AddressTool
                                            v-if="layout.field == 'address-selector'"
                                            v-model="selectedAddress"
                                            :label="layout.label"
                                            :addresses="addresses"
                                            :errors="errors"
                                        />
                                    </ValidationProvider>
                                </div>
                            </div>

                            <IncomesSummary
                                class="flex"
                                v-if="getListOfIncomes"
                                :incomes="getListOfIncomes"
                                :entities="entities"
                                :active-roles="activeRoles" />

                        </div>
                        <div v-else>

                            <template v-if="resources[resource]">
                                <v-row>
                                    <v-col
                                        class="my-0 py-0"
                                        v-for="(field, key) in fieldLayoutMaker(layout, resources[resource].attributes)"
                                        :key="key"
                                        :cols="getFieldColWidth(key)"
                                    >
                                        <ValidationProvider
                                            v-if="isVisible(key)"
                                            :hidden="isHidden(key)"
                                            v-slot="{ errors }"
                                            :name="labels[key]"
                                            :rules="getCustomRules(key)"
                                            :vid="key">

                                            <ButtonGroup1
                                                v-if="isBtnGroup(key)"
                                                v-model="resources[resource].attributes[key]"
                                                :error-messages="errors"
                                                :options="['Yes', 'No']"
                                                :label="labels[key]" />

                                            <AddressTool2
                                                v-else-if="isAddressField(key)"
                                                v-model="selectedAddress"
                                                :label="labels[key]"
                                                :addresses="addresses"
                                                :errors="errors">
                                            </AddressTool2>

                                            <AddressTool3
                                                ref="AddressTool3Component"
                                                v-else-if="isAddressField3(key)"
                                                :bus="Date.now()"
                                                v-model="selectedAddress"
                                                :label="labels[key]">
                                            </AddressTool3>

                                            <v-select
                                                v-else-if="isFieldType('picklist', key)"
                                                :items="getOptions(key)"
                                                :readonly="isReadOnly(key)"
                                                :label="labels[key]"
                                                :error-messages="errors"
                                                v-model="resources[resource].attributes[key]"
                                                color="secondary">
                                            </v-select>

                                            <template v-else-if="isFieldType('date', key)">
                                                <flex-date-picker
                                                    :label="key === 'start-date' && getLoanType === 'Commercial' && data.id === '12-29' ? 'Self Employed ABN Start Date' :labels[key]"
                                                    :errors="errors"
                                                    :min="getMinDate(key)"
                                                    :max="getMaxDate(key)"
                                                    v-model="resources[resource].attributes[key]"
                                                ></flex-date-picker>
                                            </template>

                                            <v-textarea
                                                v-else-if="isFieldType('textarea', key)"
                                                :label="labels[key]"
                                                auto-grow
                                                :error-messages="errors"
                                                v-model="resources[resource].attributes[key]"
                                                color="secondary">
                                            </v-textarea>

                                            <template v-else>

                                                <div v-if="key === '__spacer'"></div>

                                                <v-text-field
                                                v-else-if="!['12-29'].includes(data.id) && key == 'net-monthly-amount' && getLoanType == 'Commercial'"
                                                :class="'d-none'"
                                                :type="'text'"
                                                :label="labels[key]"
                                                :error-messages="errors"
                                                :prefix="prefix[key]"
                                                v-model="resources[resource].attributes[key]"
                                                color="secondary">
                                                </v-text-field>

                                                <v-text-field
                                                v-else
                                                :class="!['12-29', '12-16'].includes(data.id) && key == 'net-monthly-amount' && getLoanType == 'Consumer' ? 'd-none' : ''"
                                                :type="'text'"
                                                :label="labels[key]"
                                                :error-messages="errors"
                                                :prefix="prefix[key]"
                                                v-model="resources[resource].attributes[key]"
                                                color="secondary">
                                                </v-text-field>
                                            </template>

                                        </ValidationProvider>
                                    </v-col>
                                </v-row>
                            </template>

                        </div>



                        </template>
                    </template>
            </ValidationObserver>

        </v-card>
</template>

<script>

    import { mapState, mapActions, mapGetters, mapMutations } from 'vuex'
    import {store, actions} from '~/components/v1/FinancialObjectsEditor1/__store'

    import formHelpers from '~/components/shared/form-helpers'
    import validation from '~/components/shared/validation'
    import shared from '~/components/v1/FinancialObjectsEditor1/__shared'

    import RoleSelector from '~/components/v1/FinancialObjectsEditor1/_RoleSelector'
    import IncomeSharingEditor from '~/components/v1/FinancialObjectsEditor1/_IncomeSharingEditor'
    import AddressTool from '~/components/v1/FinancialObjectsEditor1/_AddressTool'
    import AddressTool2 from '~/components/v1/FinancialObjectsEditor1/_AddressTool2'
    import AddressTool3 from '~/components/v1/FinancialObjectsEditor1/_AddressTool3'
    import foFieldLayouts from '~/components/v1/FinancialObjectsEditor1/fo-field-layouts'
    import foLabels from '~/components/v1/FinancialObjectsEditor1/fo-labels'
    import IncomesSummary from '~/components/base/IncomeSummaryTable'

    import baseComponents from '~/components/base'

    import moment from 'moment'
    import { isEmpty } from 'lodash'

    export default {
        name: 'FOIncomeForms',
        components:{
            ...baseComponents,
            RoleSelector,
            IncomeSharingEditor,
            AddressTool,
            AddressTool2,
            AddressTool3,
            IncomesSummary
        },
        mixins: [validation, formHelpers, shared],
        props: {
            standalone: {
                type: Boolean,
                default: false
            },
            data: {
                type: Object,
                default: null
            },
            fieldsData: {
                type: Array,
                default: () => ([])
            }
        },
        data() {
            return {
                showSubTitle: true,
                activeRoles: [],
                selectedAddress: null,
                relatedAsset: null,
                fieldLayout: foFieldLayouts,
                readonly: {},
                visible: {

                    /**
                     * NOTE: Removed from DOM
                     * Initially all fields are in DOM
                     */
                    'self-employed-date-registered-gst': false,

                },
                hidden: {

                    // NOTE: hidden only from DOM
                    "employment-job-status": true,
                    "primary-income-split": true,

                },
                resources: {
                    incomes: {
                        type: "incomes",
                        attributes: {
                            "income-situation": null,
                            "income-period": null,
                            "benefit-amount": null,
                            "no-income-reason": null,
                            "additional-notes": null,
                            "start-date": null,
                            "end-date": null,
                            "income-additional-comments": null,
                            "investment-income": null,
                            "other-benefit-description": null,
                            "abn": null,
                            "employer-name": null,
                            "employment-contact-person": null,
                            "employment-contact-phone": null,
                            'contact-person-direct-mobile': null,
                            "bonus-monthly-net": 0,
                            "business-name": null,
                            "commission-monthly-net": 0,
                            "depreciation": 0,
                            "employment-job-status": "Other Income", // Setting Default, required for Run-Quote
                            "employment-on-probation": null,
                            "gross-annual-income": null,
                            "income-verification-method": null,
                            "job-title": null,
                            "nature-of-business": null,
                            "net-income": null,
                            "net-monthly-amount": null,
                            "net-standard-pay": null,
                            "overtime-pay-monthly-net": 0,
                            "primary-income-split": 100,
                            "profit-before-tax-annual": 0,
                            "reporting-period": null,
                            "revenue": 0,
                            "same-industry": null,
                            "self-employed-date-registered-abn": null,
                            "self-employed-date-registered-gst": null,
                            "self-employed-gst-registered": null,
                            "estimated-gap-before-in-weeks": null,
                            "work-allowance-net-monthly": 0,

                            "__employer-address": null,
                            "__business-address": null,
                            "__rental-address": null,
                            "__spacer": null,
                        }
                    }
                },
                labels: foLabels.incomes,
                prefix: foLabels.prefixIcon,
                validation_rules: {
                    attributes: {
                        "income-situation": 'required|in:Current,Secondary,Previous',
                        "income-period": 'required|in:Weekly,Fortnightly,Monthly,Annual',
                        "benefit-amount": 'required|between:0,99999999.99',
                        "no-income-reason": 'required|in:Student,Travel,Illness,Prison,Unpaid Maternity Leave,Other Reason',
                        "investment-income": "required|integer",
                        "additional-notes": "required|string|max:32768",
                        "income-additional-comments": "string|max:32768",
                        "other-benefit-description": "string|max:32768",
                        "start-date": "required|date_format:Y/m/d|before:now",
                        "end-date": "required|date_format:Y/m/d|before:now",
                        "net-income": "required|between:0,99999999.99",
                        "net-monthly-amount": "required|between:0,99999999.99",
                        "job-title": "required|string",
                        "employer-name": "required|string",
                        "employment-contact-person": "required|string",
                        "abn": "required|digits_between:11,11",
                        "employment-contact-phone": "required_if:contact-person-direct-mobile,null,|numeric",
                        "contact-person-direct-mobile": "required_if:employment-contact-phone,null,numeric|digits_between:10,10",
                        "employment-job-status": "required|in:Full-Time,Part-Time,Casual,Contract,Self-Employed - Sole-Trader,Self-Employed - Company,Self-Employed - Other,Not Gainfully Employed,Other Income",
                        "employment-on-probation": "required|in:Yes,No",
                        "gross-annual-income": "between:0,99999999.99",
                        "net-standard-pay": "required|between:0,99999999.99",
                        "overtime-pay-monthly-net": "between:0,99999999.99",
                        "bonus-monthly-net": "between:0,99999999.99",
                        "commission-monthly-net": "between:0,99999999.99",
                        "work-allowance-net-monthly": "between:0,99999999.99",
                        "business-name": "required|string",
                        "self-employed-date-registered-abn": "required|date_format:Y/m/d|before:now",
                        "self-employed-gst-registered": "required|in:Yes,No",
                        "nature-of-business": "required|string",
                        "self-employed-date-registered-gst": "required|date_format:Y/m/d|before:now",
                        "income-verification-method": "required|in:Full Documentation,No Documentation,Self Declaration",
                        "revenue": "between:0,99999999.99",
                        "reporting-period":"required|in:Previous FY,Current FY YTD,Projected",
                        "depreciation": "between:0,99999999.99",
                        "primary-income-split": "integer",
                        "profit-before-tax-annual": "required|between:0,99999999.99",

                        "__employer-address": 'required',
                        "__business-address": 'required',
                        "__rental-address": 'required',

                        "__spacer": '',
                    }
                }
            }
        },
        computed: {
            ...mapGetters('kinds', ['kinds', 'kindsUnformat', 'kindsOfApplicant']),
            ...mapState('flows', {
                appId: state => state.appId
            }),
            ...mapGetters('slug', ['getFlowSlug']),
            getActiveFieldLayout(){

                const {data, fieldLayout} = this

                if(!data) return {};

                let keys = Object.keys(fieldLayout)

                let layout = {}

                for (let i = 0; i < keys.length; i++) {

                    const key = keys[i];
                    const items = key.split('|')

                    if(items.includes(data.id)) {

                        layout = fieldLayout[key]
                        break
                    }

                }

                if(Object.keys(layout).length == 0){
                    console.log('Uh oh!, check field layout if configured correctly.')
                }

                return layout

            },
            selectedEntity(){

                let index = store.selectedEntity

                if(this.entities[index]) return this.entities[index];

                return null

            },
            isCompanyTrustee() {

                if(this.selectedEntity?.relationships?.kind?.data) {
                    if(this.selectedEntity.relationships.kind.data.id == '5-4' && this.selectedEntity.type == 'businesses')
                        return true
                }

                return false
            },
            preSelectRoles(){
                let clone = [...store.preSelectRoles]
                return clone
            },
            preselectIncomeSituation(){
                return store.preselectIncomeSituation
            },
            getLoanType () {
                return store.loanType
            },
            getAppType () {
                return store.appType
            },
            getListOfIncomes() {
                return store.resources.incomes.length > 0 ? store.resources.incomes : false
            },
            getIncomePeriod () {
                switch (this.fieldIncomePeriod) {
                    case 'Weekly': return 52
                    case 'Fortnightly': return 26
                    case 'Monthly': return 12
                    case 'Annual': return 1
                }
            },

            // Field Specific
            fieldIncomeSituation(){
                return this.resources.incomes.attributes['income-situation']
            },
            fieldEmployedGSTRegistered(){
                return this.resources.incomes.attributes['self-employed-gst-registered']
            },
            fieldIncomeVerificationMethod(){
                return this.resources.incomes.attributes['income-verification-method']
            },
            fieldIncomePeriod(){
                return this.resources.incomes.attributes['income-period']
            },
            fieldBenefitAmount(){
                return this.resources.incomes.attributes['benefit-amount']
            },
            netMonthlyAmount(){
                return this.resources.incomes.attributes['net-monthly-amount']
            },
            fieldIvestmentIncome(){
                return this.resources.incomes.attributes['investment-income']
            },
            fieldNetIncome(){
                return this.resources.incomes.attributes['net-income']
            },
            fieldProfitBeforeTaxAnnual(){
                return this.resources.incomes.attributes['profit-before-tax-annual']
            },
            fieldNetStandardPay(){
                return this.resources.incomes.attributes['net-standard-pay']
            },
            fieldStartDate(){
                return this.resources.incomes.attributes['start-date']
            },
            fieldSelfEmployedGSTRegistered(){
                return this.resources.incomes.attributes['self-employed-gst-registered']
            },


            fieldOvertimePayMonthlyNet(){
                return this.resources.incomes.attributes['overtime-pay-monthly-net']
            },
            fieldBonusMonthlyNet(){
                return this.resources.incomes.attributes['bonus-monthly-net']
            },
            fieldCommissionMonthlyNet(){
                return this.resources.incomes.attributes['commission-monthly-net']
            },
            fieldWorkAllowanceNetMonthly(){
                return this.resources.incomes.attributes['work-allowance-net-monthly']
            },
            employmentContactPhone() {
                return this.resources.incomes.attributes?.['employment-contact-phone']
            },
            hasCurrentIncome () {
              if (!this.getListOfIncomes) {
                return false
              }
              const entities = this.entities.filter( entity => {
                    return this.activeRoles.find( role => {
                        return role === entity.id
                    })
                })

              const incomeIdsOfEntities = entities.reduce((prev, curr) => {
                const ids = curr.relationships.incomes.data.map(income => income.id)
                prev = [...prev, ...ids]
                return prev
              }, [])

              return this.getListOfIncomes.find(income => incomeIdsOfEntities.includes(income.id) && income.attributes['income-situation'] === 'Current')
            }
        },
        mounted(){

            /**
             * This will always trigger when the slider reaches the form
             */
            this.initialize()

            // console.log(this.data)
            // console.log(this.fieldsData)
            // console.log(this.getLoanType)

        },
        methods: {
            ...mapActions('resource-actions', [
                'createIncome',
                'updateIncome',
                'getIncome',
                'getAsset',
                'createAddress'
            ]),
            initialize(){

                /**
                 * Logic for hiding / showing fields and other stuff
                 *
                 */

                const {selectedEntity, data, fieldsData, standalone} = this

                // console.log('selectedEntity', selectedEntity)
                // console.log('data', data)
                // console.log('fieldsData', fieldsData)


                if(standalone){ // EDIT MODE : FOIncomeForms opened from GridView or ListView

                    // ✔️include selected roles
                    // ✔️include selected addresses

                    let roles = []

                    fieldsData.forEach(item => {

                        if(item.hasOwnProperty('attributes')){

                            // ✔️Get incomes initial data
                            // ✔️Get incomes address

                            if(item.type == 'incomes'){

                                this.resources.incomes.id = item.id
                                this.resources.incomes.attributes = item.attributes
                                // console.log(this.resources.incomes.attributes)

                                // Include place holder fields
                                this.resources.incomes.attributes['__employer-address'] = null
                                this.resources.incomes.attributes['__business-address'] = null
                                this.resources.incomes.attributes['__rental-address'] = null
                                this.resources.incomes.attributes['__spacer'] = ''

                                let addressData = item.relationships.address.data

                                if(addressData) {

                                    this.addresses.forEach(address => {

                                        if(address.id == addressData.id){
                                            this.selectedAddress = address
                                        }

                                    })

                                }

                            }

                            if(item.type == 'assets'){
                                this.relatedAsset = item.id
                            }

                            // ✔️Collect all roles of selected FO

                            if(data.type == item.type){

                                item.relationships.people.data.forEach(person => {

                                    if(!roles.includes(person.id)){
                                        roles.push(person.id)
                                    }

                                })

                                item.relationships.businesses.data.forEach(business => {

                                    if(!roles.includes(business.id)){
                                        roles.push(business.id)
                                    }

                                })

                            }
                        }

                    })


                    if(roles.length){

                        this.activeRoles = roles

                    } else {

                        if(selectedEntity) {
                            this.activeRoles = [selectedEntity.id]
                        }
                    }

                } else {

                    if(selectedEntity){
                        this.activeRoles = [selectedEntity.id]
                    }

                    if(this.selectedEntityAll){
                        this.activeRoles = this.preSelectRoles
                    }

                    if(this.preselectIncomeSituation){
                        this.resources.incomes.attributes['income-situation']  = this.preselectIncomeSituation
                    }

                }


                /**
                 * Record specific logic
                 */

                // No Income
                if(this.data && this.data.id == "12-23"){
                    this.validation_rules.attributes["income-situation"] = "required|in:Current,Previous"
                }

                if (this.hasCurrentIncome && this.resources?.incomes?.attributes?.["income-situation"] !== 'Current') {
                  this.validation_rules.attributes["income-situation"] = "required|in:Secondary,Previous"
                }

                // Other Benefits
                if (this.data && this.data.id == "12-11") {
                    this.validation_rules.attributes['other-benefit-description'] = 'string|max:32768'
                }

                // Rental Income
                if (this.data && this.data.id == "12-21") {
                    this.resources.incomes.attributes['__rental-address'] = this.getFullAddress(this.selectedAddress)
                }

                // Company Director
                if (this.data && this.data.id == "12-16") {

                    this.resources.incomes.attributes['income-situation'] = 'Current'

                    if(selectedEntity){

                        let businessName = ''

                        // Get Business Name
                        this.entityParts.forEach(parts => {


                            if(parts.id == selectedEntity.relationships['parent-parts'].data[0].id){

                                this.entities.forEach(entity => {

                                    if(parts.relationships.parent.data.id == entity.id){
                                        businessName = entity.attributes['business-legal-name'] ? entity.attributes['business-legal-name'] : 'Business'
                                    }

                                })

                            }

                        })

                        let currentBusinessName = this.resources.incomes.attributes['business-name']

                        this.resources.incomes.attributes['business-name'] = currentBusinessName || businessName
                    }

                    this.readonly["income-period"] = true
                    this.resources.incomes.attributes["employment-job-status"] = "Self-Employed - Company"

                    if (this.getLoanType == 'Commercial') {
                        this.validation_rules.attributes['net-monthly-amount'] = 'between:1,99999999.99'
                    }

                }

                // PAYG Employment
                if (this.data && this.data.id == "12-28") {

                    this.validation_rules.attributes["employment-job-status"] = "required|in:Full-Time,Part-Time,Casual,Contract";
                    this.resources.incomes.attributes['__employer-address'] = this.getFullAddress(this.selectedAddress)
                    this.validation_rules.attributes['abn'] = 'string|digits_between:11,11'

                    this.hidden["employment-job-status"] = false
                }

                // Self-Employed
                if (this.data && this.data.id == "12-29") {

                    this.validation_rules.attributes["employment-job-status"] = "required|in:Self-Employed - Sole-Trader,Self-Employed - Company,Self-Employed - Other";
                    this.resources.incomes.attributes['__business-address'] = this.getFullAddress(this.selectedAddress)
                    this.validation_rules.attributes['net-monthly-amount'] = 'required|between:1,99999999.99'
                    //this.resources.incomes.attributes['net-monthly-amount'] = 0

                    this.hidden["employment-job-status"] = false

                    if (this.getLoanType == 'Commercial') {

                        this.resources.incomes.attributes['income-period'] = "Annual"

                        this.readonly["income-period"] = true

                        // if role equal to individual trustee or partner
                        if(this.selectedEntity.relationships.kind.data.id == '4-2' && this.selectedEntity.roles[0] == 'Partner' || this.selectedEntity.roles[0] == 'Trustee') {

                            this.visible["income-verification-method"] = false
                            this.visible["income-period"] = false
                            this.visible["reporting-period"] = false
                            this.visible["depreciation"] = false
                            this.visible["revenue"] = false
                            this.visible["profit-before-tax-annual"] = false

                            this.visible["gross-annual-income"] = true
                            this.visible["net-monthly-amount"] = true

                            this.resources.incomes.attributes['employment-job-status'] = 'Self-Employed - Other'

                            this.validation_rules.attributes['gross-annual-income'] = 'between:0,99999999.99'
                            this.resources.incomes.attributes['gross-annual-income'] = 0
                            this.validation_rules.attributes['net-monthly-amount'] = 'between:0,99999999.99'

                            // get parent of selected entity
                            let parentEntity = this.filterParent()

                            // console.log(parentEntity)

                            // prefill some fields
                            if(parentEntity.length > 0) {

                                let addressId = null

                                parentEntity.forEach(parent => {

                                    this.resources.incomes.attributes['business-name'] = parent.attributes['business-legal-name']
                                    this.resources.incomes.attributes['abn'] = parent.attributes['abn-number']
                                    this.resources.incomes.attributes['start-date'] = parent.attributes['date-registered-abn']
                                    this.resources.incomes.attributes['nature-of-business'] = parent.attributes['nature-of-business']

                                    if (parent.attributes['date-registered-gst']) {
                                        this.resources.incomes.attributes['self-employed-gst-registered'] = 'Yes'
                                        this.resources.incomes.attributes['self-employed-date-registered-gst'] = parent.attributes['date-registered-gst']
                                    } else {
                                        this.resources.incomes.attributes['self-employed-gst-registered'] = 'No'
                                    }

                                    // get address id if exist in parent
                                    if(parent.relationships?.addresses && parent.relationships.addresses.data.length > 0) {
                                        addressId = parent.relationships.addresses.data[0].id
                                    }

                                })

                                // if address exist populate fields to address tool
                                if (addressId) {
                                    this.addresses.forEach(address => {

                                        if(address.id == addressId){
                                            this.selectedAddress = address
                                        }

                                    })
                                }

                            }

                        }

                    } else {

                        this.visible["income-verification-method"] = false
                        this.visible["income-period"] = false
                        this.visible["reporting-period"] = false
                        this.visible["depreciation"] = false
                        this.visible["revenue"] = false
                        this.visible["profit-before-tax-annual"] = false

                    }

                }

                // Business Income
                if (this.data && this.data.id == "12-30") {
                    this.hidden["income-situation"] = true
                    this.resources.incomes.attributes['net-monthly-amount'] = 0
                }

            },
            /**
             * Show / Remove Completely
             */
            isVisible(key){

                const {visible} = this

                if(visible.hasOwnProperty(key)){
                    return visible[key]
                }

                return true

            },

            /**
             * filter parent
             */
            filterParent() {
                let parent = []

                this.entities.forEach(entity => {
                    if (entity.relationships['child-parts']) {
                        entity.relationships['child-parts'].data.forEach(child => {
                            if(child.id == this.selectedEntity?.relationships?.['parent-parts']?.data?.[0]?.id) {
                                parent.push(entity)
                            }
                        })
                    }
                })

                return parent
            },

            /**
             * Hidden only
             */
            isHidden(key){

                const {hidden} = this

                if(hidden.hasOwnProperty(key)){
                    return hidden[key]
                }

                return false

            },
            isReadOnly(key){

                const {readonly} = this

                if(readonly.hasOwnProperty(key)){
                    return readonly[key]
                }

                return false

            },
            getFieldColWidth(key){

                if( key === '__business-address' || key === '__employer-address'){

                    return  12

                } else {

                      if(this.$vuetify.breakpoint.lgAndUp){

                          return 3

                      } else {

                          return this.$vuetify.breakpoint.xsOnly ? 12 : 6
                      }
                }

            },
            async save(){

                /**
                 * Save address component first
                 * Update: Save address if address ref object is found
                 */
                if ( !isEmpty(this.$refs.AddressTool3Component) ) {
                    await this.$refs.AddressTool3Component[0].save()
                }



                const valid = await this.$refs.observer.validate();

                if(valid){

                    actions.toggleSaving(true)

                    try {

                        let income = await this.saveIncome()

                        await this.updateLocalResources(income)

                        await this.updateEntitiesAndParts()

                        store.validate()

                        actions.toggleSaving(false)

                        return {status: 'ok'}

                    } catch (error) {

                        console.log('Ooops!..', error)
                        actions.toggleSaving(false)
                    }

                }

                return {status: 'fail'}

            },
            populateHiddenFields(attributes) {
                // if self employed
                if(this.data.id === '12-29') {
                    attributes['self-employed-date-registered-abn'] = attributes['start-date']
                }

                return attributes
            },
            calculateNetMonthlyAmount(val) {

                if(val){

                    let incomePeriod = this.getIncomePeriod ? this.getIncomePeriod : 0
                    let incomeAttr = this.resources.incomes.attributes

                    let overPayMontly = incomeAttr['overtime-pay-monthly-net'] ? parseFloat(incomeAttr['overtime-pay-monthly-net']) : 0
                    let bonusMonthly = incomeAttr['bonus-monthly-net'] ? parseFloat(incomeAttr['bonus-monthly-net']) : 0
                    let commissionMonthly = incomeAttr['commission-monthly-net'] ? parseFloat(incomeAttr['commission-monthly-net']) : 0
                    let workAllowance = incomeAttr['work-allowance-net-monthly'] ? parseFloat(incomeAttr['work-allowance-net-monthly']) : 0

                    const additionalIncome = ( overPayMontly + bonusMonthly + commissionMonthly + workAllowance )
                    this.resources.incomes.attributes['net-monthly-amount'] = parseFloat(((val * incomePeriod) / 12) + additionalIncome).toFixed(2)

                }

            },
            async saveIncome(){

                const observer = await this.$refs.observer;

                const {selectedAddress, relatedAsset} = this

                const {incomes} = this.resources

                let attributes = this.excludeInvalids(incomes.attributes, observer.fields)

                let payload = {
                    type: 'incomes',
                    attributes: this.populateHiddenFields(attributes),
                    relationships: {
                        application: {
                            data: {type: "applications", id: this.appId}
                        },
                        people: this.getPeopleRelationship(),
                        businesses: this.getBusinessesRelationship(),
                        kind: {
                            data: {
                                type: 'kinds',
                                id: this.data.id
                            }
                        }
                    }
                }

                if(selectedAddress != null){

                    if(selectedAddress.id){

                        payload.relationships.address = {
                            data: {
                                type: 'addresses',
                                id: selectedAddress.id
                            }
                        }

                    } else {

                        let relationships = {
                            application: {
                                data: {type: "applications", id: this.appId}
                            }
                        }

                        this.selectedAddress.relationships = relationships

                        let address = await this.createAddress(selectedAddress)

                        actions.updateAddress({key: 'incomes', value: address.data.data})

                        payload.relationships.address = {
                            data: {
                                type: 'addresses',
                                id: address.data.data.id
                            }
                        }

                    }

                }

                // Attach Asset to Income
                if(relatedAsset){
                    payload.relationships.assets = {
                        data: [
                            {
                                type: 'assets',
                                id: relatedAsset
                            }
                        ],
                        meta: {
                            strategy: 'attach'
                        }
                    }
                }


                if(incomes.id){

                    payload.id = incomes.id

                    return this.updateIncome(payload)

                } else {

                    return this.createIncome(payload)
                }

            },

            async updateLocalResources(income){

                const {relatedAsset} = this

                let payload = []

                if(relatedAsset){
                    let res1 = this.getAsset(relatedAsset)
                    payload.push(res1)
                }

                if(income.data.data && income.data.data.id){
                    let res2 = this.getIncome(income.data.data.id)
                    payload.push(res2)
                }

                try {

                    let data = await Promise.all(payload)

                    actions.updateResources(data)

                } catch (error) {

                    console.log('Ooops!..', error)

                }

            },

            computeNetMonthlyAmount(income) {
                let incomePeriod = this.getIncomePeriod ? this.getIncomePeriod : 0
                return parseFloat((income * incomePeriod) / 12).toFixed(2)
            },
            getCustomRules(name){

                const defaultRules = this.getRules(name);

                if(name == 'employment-contact-phone') {

                    // get digits rule
                    let digits = 10
                    const isRequired = this.data?.id == '12-28'

                    if(this.employmentContactPhone) {
                        const comp1 = this.employmentContactPhone.substring(0, 4)
                        const comp2 = this.employmentContactPhone.substring(0, 2)

                        if(comp1 != '1300' && comp2 == '13') {
                            digits = 6
                        }
                    }

                    return `${isRequired ? 'required|' : ''}is_employment_phone:02,03,07,08,1300,1800,13,04|digits:${digits}|` + defaultRules
                  }

                if (name === 'contact-person-direct-mobile') {
                  return 'is_mobile_number|has:04|' + defaultRules
                }

                return defaultRules
            },
            handleSharePercentageUpdateChage(val){
                if(val){
                    this.resources.incomes.attributes['primary-income-split'] = val
                }
            },
            showIncomeSharing(){

                let isPrevious = this.resources?.incomes?.attributes?.["income-situation"] == "Previous"

                if(this.getLoanType == 'Consumer') {
                    if(this.getCalculation?.['application-type'] != "Consumer - Individual" && !isPrevious) {
                        return true
                    }
                }

                return false
            }
        },
        watch: {
            fieldIncomeSituation(val){

                const {data, getLoanType} = this

                let types = [
                    "12-15", // Child Support
                    "12-28", // PAYG Employment
                    "12-29", // Self-Employed
                    "12-23", // No Income

                    // Other Income
                    "12-25", // Income Protection Payment
                    "12-26", // Other Income
                    "12-27", // Workers Compensation

                    // Centrelink
                    "12-3", // Age Pension
                    "12-4", // Carer Allowance
                    "12-5", // Child Care Benefit
                    "12-6", // Defence Service Pension
                    "12-7", // Disability Support Pension
                    "12-8", // Family Allowance
                    "12-9", // Family Tax Benefit
                    "12-10", // Newstart
                    "12-11", // Other Benefits
                    "12-12", // Parenting Payments
                    "12-13", // Widows Allowance
                    "12-14", // Youth Allowance

                    //Investment Income
                    "12-18", // Dividends
                    "12-19", // Other Investment
                    "12-20", // Private Pension
                    "12-21", // Rental Income
                    "12-22", // Superannuation
                ]

                let common = [
                    "net-monthly-amount",
                    "benefit-amount",
                    "investment-income",
                    "net-income",
                    "income-period",

                    "overtime-pay-monthly-net",
                    "bonus-monthly-net",
                    "commission-monthly-net",
                    "work-allowance-net-monthly",
                    "gross-annual-income",

                    "income-verification-method",
                    "reporting-period",
                    "revenue",
                    "depreciation",
                    "profit-before-tax-annual",

                    "net-standard-pay",
                    "employment-contact-person",
                    "employment-on-probation",
                    // "employment-contact-phone", // require for previous

                    "__business-address",
                    "self-employed-gst-registered",
                    "self-employed-date-registered-gst",
                ]

                let fields = []
                let newCommon = [...common]
                let exclude = []

                if (this.getLoanType == 'Commercial') {

                    // Exclude fields

                    if(data.id == '12-29'){
                        exclude = [
                            "gross-annual-income",
                        ]

                        // if role equal to individual trustee or partner
                        if(this.selectedEntity.relationships.kind.data.id == '4-2' && this.selectedEntity.roles[0] == 'Partner' || this.selectedEntity.roles[0] == 'Trustee') {
                            exclude.push("income-verification-method","reporting-period","depreciation","profit-before-tax-annual", "revenue", "income-period")
                        } else {
                            exclude.push("net-monthly-amount")
                        }

                        newCommon = common.filter(item => !exclude.includes(item))

                    }

                    fields = [
                        ...newCommon,
                    ]

                } else {

                    // Exclude fields

                    if(data.id == '12-29'){

                        exclude = [
                            "income-period",
                            "income-verification-method",
                            "reporting-period",
                            "revenue",
                            "depreciation",
                            "profit-before-tax-annual",
                        ]

                        newCommon = common.filter(item => !exclude.includes(item))
                    }

                    fields = [
                        ...newCommon,
                    ]
                }

                if(val == 'Previous'){

                    if(data && types.includes(data.id)){

                        fields.forEach(field => {
                            this.visible[field] = false
                        })

                        this.showSubTitle = false
                    }

                } else {

                    if(data && types.includes(data.id)){

                        fields.forEach(field => {
                            this.visible[field] = true
                        })

                        this.showSubTitle = true
                    }
                }

                this.visible['end-date'] = val == 'Previous'
                this.visible['contact-person-direct-mobile'] = val == 'Current'

            },
            fieldEmployedGSTRegistered (val) {
                if (val === 'Yes') {
                    this.validation_rules.attributes['self-employed-date-registered-gst'] = 'required|date_format:Y/m/d|before:now'
                } else {
                    this.validation_rules.attributes['self-employed-date-registered-gst'] = 'string|date_format:Y/m/d|before:now'
                }
            },
            fieldIncomeVerificationMethod (val) {

                if (val === 'No Documentation') {
                    this.validation_rules.attributes['profit-before-tax-annual'] = 'numeric|between:0,99999999.99'
                    this.validation_rules.attributes['revenue'] = 'numeric|between:0,99999999.99'
                    this.validation_rules.attributes['depreciation'] = 'numeric|between:0,99999999.99'
                } else {

                    if(this.isCompanyTrustee) {
                        this.validation_rules.attributes['profit-before-tax-annual'] = 'required|between:1,99999999.99'
                        this.validation_rules.attributes['revenue'] = 'required|between:1,99999999.99'
                        this.validation_rules.attributes['depreciation'] = 'numeric|between:0,99999999.99'
                    } else {
                        this.validation_rules.attributes['profit-before-tax-annual'] = 'required|between:1,99999999.99'
                        this.validation_rules.attributes['revenue'] = 'required|between:1,99999999.99'
                        this.validation_rules.attributes['depreciation'] = 'required|between:1,99999999.99'
                    }

                }

            },

            // Centrelink Income computation of Net Monthly Amount
            fieldBenefitAmount(val){
                if(val)
                    this.resources.incomes.attributes['net-monthly-amount'] = this.computeNetMonthlyAmount(val)
            },

            // Investment Income and Other Investment computation of Net Monthly Amount
            fieldIvestmentIncome(val){
                if(val)
                    this.resources.incomes.attributes['net-monthly-amount'] = this.computeNetMonthlyAmount(val)
            },

            // Other Income computation of net monthly amount
            fieldNetIncome(val){
                if(val)
                    this.resources.incomes.attributes['net-monthly-amount'] = this.computeNetMonthlyAmount(val)
            },

            // Trigger Net Monthly Amount computation
            fieldIncomePeriod(val) {
                if(val) {

                    if(this.fieldNetIncome)
                        this.resources.incomes.attributes['net-monthly-amount'] = this.computeNetMonthlyAmount(this.fieldNetIncome)

                    else if(this.fieldIvestmentIncome)
                        this.resources.incomes.attributes['net-monthly-amount'] = this.computeNetMonthlyAmount(this.fieldIvestmentIncome)

                    else if(this.fieldBenefitAmount)
                        this.resources.incomes.attributes['net-monthly-amount'] = this.computeNetMonthlyAmount(this.fieldBenefitAmount)


                    this.calculateNetMonthlyAmount(this.fieldNetStandardPay)
                }
            },

            fieldProfitBeforeTaxAnnual(val){
                if(val) {

                    let incomeAttr = this.resources.incomes.attributes
                    const depreciation = incomeAttr['depreciation'] ? parseFloat(incomeAttr['depreciation']) : 0
                    const pftAnual = val ? parseFloat(val) : 0
                    this.resources.incomes.attributes['net-monthly-amount'] = parseFloat((pftAnual + depreciation) / 12).toFixed(2)

                }
            },
            fieldNetStandardPay(val){

                if(val) {
                    this.calculateNetMonthlyAmount(val)
                }

            },
            fieldStartDate(val){
                let startDate = moment(val, 'YYYY-MM-DD')
                const dif = moment().diff(startDate, 'months')
                if (dif < 3) {
                    this.visible['employment-on-probation'] = true
                } else {
                    this.visible['employment-on-probation'] = false
                }
            },
            fieldSelfEmployedGSTRegistered(val){
                if (val == 'Yes'){
                    this.visible['self-employed-date-registered-gst'] = true
                } else {
                    this.visible['self-employed-date-registered-gst'] = false
                }
            },
            fieldOvertimePayMonthlyNet(val){
                if(val) {
                    this.calculateNetMonthlyAmount(this.fieldNetStandardPay)
                }
            },
            fieldBonusMonthlyNet(val){
                if(val) {
                    this.calculateNetMonthlyAmount(this.fieldNetStandardPay)
                }
            },
            fieldCommissionMonthlyNet(val){
                if(val) {
                    this.calculateNetMonthlyAmount(this.fieldNetStandardPay)
                }
            },
            fieldWorkAllowanceNetMonthly(val){
                if(val) {
                    this.calculateNetMonthlyAmount(this.fieldNetStandardPay)
                }
            },

        }
    }
</script>

<style lang="scss" scoped>

</style>



