
export const  state = () => ({


    // Confirmation and Warning Dialogs

    processing: false, // modal is loading

    touched: false, // step form touched
    
    invalidDataDialog: false,
    confirmDetailsDialog: false,
    incompleteStepsDialog: false,

    // Veevalidate's Observer Data

    formFieldsObserverName: '', // Name of Veevalidate's form 
    formFieldsObserveTrigger: 0, // Date.now() 

    // helper dialog for lender submission
    lenderSubmissionHelperDialog: true,

})


// getters
export const  getters = {
    getTouched(state){
        return state.touched
    },
    getFormFieldsObserveTrigger(state){
        return state.formFieldsObserveTrigger
    },
    getFormFieldsObserverName(state){
        return state.formFieldsObserverName
    },
}

// actions
export const actions = {
    async evaluateStepsForCompliance({commit, getters, dispatch, rootState, rootGetters}) {

        const activeStep = parseInt(rootGetters['flows/getActiveStep']) - 1

        // get step statuses
        // update step statuses store
        await dispatch('statuses/fetchStepStatuses', {}, {root: true}).then(res => {
            commit('statuses/setStepStatuses', res, { root: true })

            // condition for included steps
            // update get getNextStep getter
            // redirect if pass
            if(res[activeStep] && res[activeStep].attributes.included === 0) {

                this.$router.push({
                    name: process.env.flowRouteName, 
                    params: { 
                        group_slug: rootState.slug.groupSlug, 
                        flow_slug: rootState.slug.flowSlug, 
                        steps: `${rootGetters['flows/getNextStep']}`, 
                        app_guid: rootState.flows.appGuid
                    } 
                })
    
            }

        })

        return
    },

    async evaluateSteps({commit, getters, dispatch, rootState, rootGetters}){

                const {validation} = rootState.subscriptions

                // Check if clone data 
                if(rootState.flows.appData?.attributes?.clone == 1 && !getters.getTouched) {

                    if(rootGetters['statuses/getActiveStepStatus'] && 
                        rootGetters['statuses/getActiveStepStatus'].attributes.value == "pristine") {

                        commit('toggleConfirmDetailsDialog', true)

                        return
                    }
                }

                //  Else, validate field values, etc.
                let steps = rootGetters['flows/getSteps']
                let currentStep = steps[parseInt(rootGetters['flows/getActiveStep']) -1]
                let nextStep = steps[parseInt(rootGetters['flows/getNextStep']) -1]

                let nextStepDisabled = actions.__isStepDisabled({"step": nextStep, rootGetters})

                 let data = null

                if(nextStepDisabled){

                    data = await  dispatch('flows/selectNextStep', {goToNext: false, returnStatus: true}, {root: true})

                    // check if it's still disabled else proceed to next step
                    nextStep = steps[parseInt(rootGetters['flows/getNextStep']) -1]

                    nextStepDisabled = actions.__isStepDisabled({"step": nextStep, rootGetters})

                    if(nextStepDisabled){
                        
                        commit('toggleIncompleteStepsDialog', true)

                    } else {

                        this.$router.push({
                            name: process.env.flowRouteName, 
                            params: { 
                                group_slug: rootState.slug.groupSlug, 
                                flow_slug: rootState.slug.flowSlug, 
                                steps: `${rootGetters['flows/getNextStep']}`, 
                                app_guid: rootState.flows.appGuid
                            } 
                        })

                    }


                } else {

                    data = await  dispatch('flows/selectNextStep', {goToNext: true, returnStatus: true}, {root: true})

                }

                /**
                 * Halt execution here if permission is read-only / validation:false
                 */
                if(!validation){
                    return
                }

                if(currentStep['can-bypass'] == 1 ){

                    /**
                     * Show only the "Invalid or Missing Data" modal if
                     * the next step is NOT disabled.
                     * 
                     * Showing only the "Incomplete Steps" modal instead.
                     */

                    // console.log('nextStep', nextStep)
                    // console.log('nextStepDisabled', nextStepDisabled)

                    if(!data?.valid && !nextStepDisabled){

                        commit('toggleInvalidDataDialog', true)

                    }

                }

    },
    async confirmInvalidDataDialog({commit, getters, dispatch, rootState, rootGetters}){

        let route = {
            name: process.env.flowRouteName, 
            params: { 
                group_slug: rootState.slug.groupSlug, 
                flow_slug: rootState.slug.flowSlug, 
                steps: `${rootGetters['flows/getNextStep']}`, 
                app_guid: rootState.flows.appGuid
            } 
        }

        await dispatch('statuses/updateFlowStatus', rootGetters['flows/getNextStep'], {root: true}).then(res => {
            commit('statuses/updateFlowStatus', res, { root: true })
        })
        
        commit('toggleInvalidDataDialog', false)

        this.$router.push(route)

    },
    async confirmConfirmDetailsDialog({commit, getters, dispatch, rootState, rootGetters}){


        let steps = rootGetters['flows/getSteps']
        let currentStep = steps[parseInt(rootGetters['flows/getActiveStep']) -1]
        let nextStep = steps[parseInt(rootGetters['flows/getNextStep']) -1]

        let nextStepDisabled = actions.__isStepDisabled({"step": nextStep, rootGetters})

         let data = null

        if(nextStepDisabled){

            data = await  dispatch('flows/selectNextStep', {goToNext: false, returnStatus: true}, {root: true})

            commit('toggleConfirmDetailsDialog', false)

            // check if it's still disabled else proceed to next step
            nextStep = steps[parseInt(rootGetters['flows/getNextStep']) -1]

            nextStepDisabled = actions.__isStepDisabled({"step": nextStep, rootGetters})

            if(nextStepDisabled){
                
                commit('toggleIncompleteStepsDialog', true)

            } else {

                this.$router.push({
                    name: process.env.flowRouteName, 
                    params: { 
                        group_slug: rootState.slug.groupSlug, 
                        flow_slug: rootState.slug.flowSlug, 
                        steps: `${rootGetters['flows/getNextStep']}`, 
                        app_guid: rootState.flows.appGuid
                    } 
                })

            }


        } else {

            data = await  dispatch('flows/selectNextStep', {goToNext: true, returnStatus: true}, {root: true})

            commit('toggleConfirmDetailsDialog', false)

        }

        if(currentStep['can-bypass'] == 1 ){

            if(!data.valid){

                commit('toggleInvalidDataDialog', true)

            }

        }

    },

    async toggleFormFieldsObserver({commit, getters, dispatch, rootState, rootGetters}){
        commit('updateObserveTrigger')
    },
    // Copied from store/step-statuses.js

    __isStepDisabled(payload){
          
        let disabled = false

        if(payload?.step?.states?.enabled){

            let allMethodsValid = true

            payload?.step.states.enabled.methods.forEach( method => {

                let {name, side} = method

                /**
                 * Server side
                 * Request for updated step-statuses and apply
                 * their values. e.g  enabled, included. 
                 *  
                 */
                if(side == 'server'){

                    let fn = actions[`__${name}`]

                    if(typeof fn === 'function') {

                        let valid = actions[`__${name}`]({
                                        payload: method.arguments,
                                        targetStep: payload.step,
                                        rootGetters: payload.rootGetters
                                    })

                        if(!valid){
                            allMethodsValid = false
                        }


                    }

                }

            });

            if(!allMethodsValid) {
                disabled = true
            }

        }

        return disabled  

    },
    __allPreviousStepsAreValid(payload){

        let valid = true
        let proceed = true
    
        let steps = payload.rootGetters['flows/getSteps']
        let statuses = payload.rootGetters['statuses/getStepStatuses']
    
        steps.forEach( step => {
    
            if(step.id == payload.targetStep.id) proceed = false;
    
            if(proceed){
    
                let stepStatus = statuses.find( status => {
                    return status.relationships.step.data.id == step.id 
                })

                // Validate only the included ones
                if(stepStatus && stepStatus.attributes.included == 1) {

                    // Check if the status is NOT valid, then return false
                    if(stepStatus.attributes.value != 'valid'){
                        valid = false
                    }

                }
    
            }
    
        })
    
        return valid
    },
    __b2bEnableSubmit(payload){

        let enabled = true

        let statuses = payload.rootGetters['statuses/getStepStatuses']

        statuses.forEach( status => {

            if(status.relationships?.step?.data?.id == payload.targetStep.id){

                if(status && status.attributes.enabled == 0) {
                    enabled = false
                }

            }

        })

        return enabled
    }
}

// mutations
export const  mutations = {
    toggleLenderSubmissionHelperDialog (state, payload) {
        state.lenderSubmissionHelperDialog = payload
    },
    toggleInvalidDataDialog (state, payload) {
        state.invalidDataDialog = payload
    },
    toggleConfirmDetailsDialog (state, payload) {
        state.confirmDetailsDialog = payload
    },
    toggleIncompleteStepsDialog (state, payload) {
        state.incompleteStepsDialog = payload
    },
    toggleProcessing (state, payload) {
        state.processing = payload
    },
    toggleTouched(state, payload) {
        // console.log('Touched = ', payload)
        state.touched = payload
    },
    setFormFieldsObserverName(state, payload){
        state.formFieldsObserverName = payload 
    },
    updateObserveTrigger(state){
        state.formFieldsObserveTrigger = Date.now() 
    },
}