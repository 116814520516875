import Vue from "vue";

export const store = Vue.observable({


    steps: {
        "purchase-asset-type": {
            "title": "Purchase Type",
            "subtitle": "",
            "form-ref": "purchaseTypeForm",
        },
        "purchase-asset-sub-type": {
            "title": "Purchase Sub Type",
            "subtitle": "",
            "form-ref": "purchaseSubTypeForm",
        },
        "condition": {
            "title": "What condition is this asset in?",
            "subtitle": "",
            "form-ref": "conditionForm",
        },
        "asset-details": {
            "title": "Asset Details",
            "subtitle": "",
            "form-ref": "assetDetailsForm",
        },
        "purchase-details": {
            "title": "Purchase Details",
            "subtitle": "",
            "form-ref": "purchaseDetailsForm",
        },
    },


    
    resources: {
        loans : {
            type: "loans",
            id: null,
            attributes: {
                "sale-type": "",
            }
        },
        purchases: {
            type: "purchases",
            id: null,
            attributes: {
                "nvic": "",
                "condition": "",
                "vendor-known": "",
                "year-of-manufacture": "",
                "make": "",
                "model": "",
                "variant": "",
                "purchase-price": "",
                "cash-deposit": "",
                "trade-in-amount": "",
                "payout-amount": "",
                "required-loan-amount": ""
            }
        }
    },
    labels: {
        "nvic": "NVIC",
        "year-of-manufacture": "Year of Manufacture",
        "make": "Make",
        "model": "Model",
        "variant": "Variant",
        "condition": "Condition",
        "vendor-known": "Vehicle Known",
        // "kms": "KMS",
        "purchase-price": "Purchase Price",
        "cash-deposit": "Cash Deposit",
        "trade-in-amount": "Trade in Amount",
        "payout-amount": "Payout Amount",
        "required-loan-amount": "Required Loan Amount"
    },
    validation_rules: {
        "nvic": "required|string|max:50",
        "year-of-manufacture": "required|integer|digits_between:4,4",
        "make": "required|string|max:255",
        "model": "required|string|max:200",
        "variant": "required|string|max:128",
        "condition": "required|string|in:New,Used",
        "vendor-known": "required|string|in:Yes,No",
        // "kms": "required|integer",
        "purchase-price": "between:0,9999999",
        "cash-deposit": "between:0,9999999",
        "trade-in-amount": "between:0,9999999",
        "payout-amount": "between:0,9999999",
        "sale-type": "required|string|in:Dealer,Private Sale,Refinance",
        "sale-type-2": "required|string|in:Dealer,Private Sale",
        "required-loan-amount": "required"
    },




    carousel: 0,

    assetType: null,
    assetSubType: null,


});


export const actions = {

    setCarousel(payload) {
        store.carousel = payload
    },
    setAssetType(payload) {
        store.assetType = payload
    },    
    setAssetSubType(payload) {
        store.assetSubType = payload
    },
    resetStore() {
        store.carousel = 0
        store.assetType = null
        store.assetSubType = null
    },

    setCarouselNext() {
        store.carousel++
    },
    setCarouselBack() {
        store.carousel--
    },

};
