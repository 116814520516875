<template>

    <v-dialog
        v-model="dialog"
        persistent
        scrollable
        :max-width="`${index === 0 ? '700' : '950'}`"
        content-class="flex__base-component-nvic-finder"
        >

        <template v-slot:activator="{ on, attrs }">
            <v-text-field
                v-model="nvic"
                :label="label"
                :error-messages="errors"
                :disabled="disabled"
                color="secondary"
                class="flex-nvic-finder"
                data-field="nvic-finder"
                @change="handleChange" >
                <template v-slot:append>
                    <v-btn depressed small color="primary" v-bind="attrs" v-on="on" data-btn="nvic-find">
                        <v-icon>mdi-magnify</v-icon>
                    </v-btn>
                </template>
            </v-text-field>
        </template>

        <v-card :disabled="loading">

            <v-progress-linear indeterminate stream absolute top v-if="loading"></v-progress-linear>

            <v-card-title class="px-4 px-sm-10">
                <v-subheader class="pl-0">NVIC FINDER</v-subheader>
                <v-spacer></v-spacer>
                <v-icon @click="dialog = false">mdi-close</v-icon>
            </v-card-title>

            <v-card-text class="px-0">
                <v-carousel
                    light
                    v-model="index"
                    :continuous="false"
                    :show-arrows="false"
                    hide-delimiters
                    touchless
                    hide-delimiter-background
                    :height="`${index === 0 ? '500' : '600'}`" >

                    <v-carousel-item>
                        <v-card flat class="overflow-y-auto mx-auto" tile height="100%">
                            <v-card-text class="px-4 px-sm-10">
                                <v-row class="ma-0 pa-0">
                                    <v-col cols="12" class="pa-0 ma-0">
                                        <div class="headline mb-6 font-weight-bold body_text_color--text text-center">
                                            {{ steps[0] }}
                                        </div>
                                    </v-col>
                                </v-row>
                                <v-row class="ma-0 pa-0">
                                    <v-col cols="12" class="pa-0 ma-0">
                                        <!-- Select Year -->
                                        <v-select
                                            v-model="year"
                                            :items="yearOptions"
                                            :disabled="yearOptions.length == 0"
                                            data-field="nvic-year"
                                            label="Year">
                                                <template #item="{item, attrs, on}"  >
                                                    <v-list-item :data-field="`item-${item}`" v-bind="attrs" v-on="on">
                                                        {{item}}
                                                    </v-list-item>
                                                </template>
                                        </v-select>
                                    </v-col>
                                    <v-col cols="12" class="pa-0 ma-0">
                                        <!-- Select Manufacturer -->
                                        <v-select
                                            v-model="manufacturer"
                                            item-text="name"
                                            :disabled="manufacturerOptions.length == 0"
                                            :return-object="true"
                                            data-field="nvic-manufacturer"
                                            :items="manufacturerOptions"
                                            label="Manufacturer">
                                                <template #item="{item, attrs, on}"  >
                                                    <v-list-item :data-field="`item-${item.name}`" v-bind="attrs" v-on="on">
                                                        {{item.name}}
                                                    </v-list-item>
                                                </template>
                                        </v-select>
                                    </v-col>
                                    <v-col cols="12" class="pa-0 ma-0">
                                        <!-- Select Model -->
                                        <v-select
                                            v-model="model"
                                            item-text="name"
                                            data-field="nvic-model"
                                            :disabled="modelOptions.length == 0"
                                            :return-object="true"
                                            :items="modelOptions"
                                            label="Model">
                                                <template #item="{item, attrs, on}"  >
                                                    <v-list-item :data-field="`item-${item.name}`" v-bind="attrs" v-on="on">
                                                        {{item.name}}
                                                    </v-list-item>
                                                </template>
                                        </v-select>
                                    </v-col>
                                    <v-col cols="12" class="pa-0 ma-0">
                                        <!-- Select Variant -->
                                        <v-select
                                            v-model="variant"
                                            data-field="nvic-variant"
                                            item-text="name"
                                            :disabled="variantOptions.length == 0"
                                            :return-object="true"
                                            :items="variantOptions"
                                            label="Variant">
                                        </v-select>
                                    </v-col>
                                    <v-col cols="6" v-if="seriesOptions.length > 0">
                                        <!-- Select Series -->
                                        <v-select
                                            v-model="series"
                                            data-field="nvic-series"
                                            item-text="name"
                                            :return-object="true"
                                            :items="seriesOptions"
                                            label="Series">
                                        </v-select>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                        </v-card>
                    </v-carousel-item>

                    <v-carousel-item>
                        <div class="px-4 px-sm-10">
                            <v-row>
                                <v-col cols="6">
                                    <div class="headline mb-4 font-weight-bold body_text_color--text text-center">{{ steps[1] }}</div>
                                    <v-card flat class="overflow-y-auto" tile>
                                        <v-card-text class="px-0">

                                            <v-card flat  max-height="500">
                                                <v-card-text class="pa-0">

                                                    <v-list three-line
                                                        subheader>
                                                        <v-list-item-group
                                                            v-model="selectedResult"
                                                            active-class="secondary--text"

                                                            color="primary">
                                                            <template
                                                                v-for="(result, n) in results">
                                                                <v-list-item
                                                                    :key="n"
                                                                    :disabled="loading"
                                                                    :data-btn="`nvic-result-${n + 1}`"
                                                                    name="nvic-result"
                                                                    :value="result['nvic-cur'] + '-' + result['nvic-model']"
                                                                    @change="handleNVICChange(result)"
                                                                    class="py-0">

                                                                    <template v-slot:default="{ active, toggle }">

                                                                        <v-list-item-icon class="mr-4 my-3">
                                                                            <v-icon
                                                                                v-if="!active"
                                                                                class="ma-0 pa-0"
                                                                                color="grey lighten-1">
                                                                                mdi-checkbox-blank-outline
                                                                            </v-icon>
                                                                            <v-icon
                                                                                v-else
                                                                                class="ma-0 pa-0"
                                                                                color="secondary">
                                                                                mdi-checkbox-marked
                                                                            </v-icon>
                                                                        </v-list-item-icon>

                                                                        <v-list-item-content class="py-0">
                                                                            <v-list-item-subtitle
                                                                                class="caption">
                                                                                {{ removeManufacturerName(result['model-name']) }}
                                                                            </v-list-item-subtitle>
                                                                        </v-list-item-content>
                                                                        <!-- <div class="caption">{{ removeManufacturerName(result['model-name']) }}</div> -->
                                                                    </template>

                                                                </v-list-item>
                                                            </template>
                                                        </v-list-item-group>
                                                    </v-list>
                                                </v-card-text>
                                            </v-card>

                                        </v-card-text>
                                    </v-card>
                                </v-col>

                                <v-col cols="6">

                                    <v-row class="pa-0">
                                        <v-col cols="12" class="py-0">

                                            <v-row>
                                                <v-col cols="12" class="pa-0">
                                                    <v-card-actions class="pa-0">
                                                        <div class="headline mb-0 body_text_color--text">NVIC <strong>{{ nvic }}</strong></div>
                                                        <v-spacer></v-spacer>
                                                        <div class="headline mb-0 body_text_color--text pr-3">{{ manufacturer.name }}</div>
                                                    </v-card-actions>

                                                    <template>
                                                        <v-card
                                                            dark flat class="light_gray_1">

                                                            <v-img
                                                                height="250px"
                                                                class="px-10 pt-2"
                                                                :aspect-ratio="1"
                                                                :src="imgPath">

                                                                <v-app-bar
                                                                    flat
                                                                    color="rgba(0, 0, 0, 0)">
                                                                    <v-spacer></v-spacer>

                                                                    <VehicleSpecs
                                                                        v-if="imgPath"
                                                                        :specifications="selected" />

                                                                </v-app-bar>

                                                            </v-img>

                                                        </v-card>
                                                        <div v-if="imgPath" class="headline mb-0 text-center body_text_color--text">NEW - RRP {{ rrp | currency }}</div>
                                                    </template>
                                                </v-col>
                                            </v-row>


                                            <v-row class="pa-0 ma-0">
                                                <v-col cols="12">
                                                    <div class="headline mb-0 pt-6 body_text_color--text text-center">USED VALUES</div>
                                                </v-col>
                                                <v-col cols="12" class="pa-0">
                                                    <v-toolbar
                                                        dense
                                                        flat class="px-0 mx-0">
                                                        <div class="font-weight-bold" v-text="`Actual KM's `"></div>
                                                        <v-spacer></v-spacer>
                                                        <v-text-field
                                                            class="mt-4 flex-nvic-finder"
                                                            type="number"
                                                            :rules="rules"

                                                            maxlength="6"
                                                            v-model="kmActual">
                                                            <template v-slot:append>
                                                                <v-btn depressed small color="primary" @click="getKmAdjustment">
                                                                    Update
                                                                </v-btn>
                                                            </template>
                                                        </v-text-field>
                                                    </v-toolbar>

                                                </v-col>
                                                <v-col class="pa-0">

                                                </v-col>
                                            </v-row>

                                        </v-col>
                                        <v-col v-if="selected" cols="12" class="py-1 pl-0 pr-4 mx-0">
                                            <v-row class="pa-0 ma-0">
                                                <v-col cols="4" class="caption font-weight-medium">TRADE LOW</v-col>
                                                <v-col cols="4" class="caption font-weight-medium">TRADE</v-col>
                                                <v-col cols="4" class="caption font-weight-medium">RETAIL VALUE</v-col>
                                            </v-row>
                                            <v-row v-for="(val, key) in filteredBreakdown" :key="key" class="pa-0 ma-0">
                                                <v-col cols="4" class="body-2 py-0 pb-1">
                                                    <v-tooltip bottom>
                                                        <template v-slot:activator="{ on, attrs }">
                                                            <span
                                                                v-if="key === 2"
                                                                v-bind="attrs"
                                                                v-on="on">{{ totalTradeLow || val['trade-low'] | currency }}</span>
                                                            <span
                                                                v-else
                                                                v-bind="attrs"
                                                                v-on="on">{{ val['trade-low'] | currency }}</span>
                                                        </template>
                                                        <span v-if="key === 0">Glass' Value</span>
                                                        <span v-if="key === 1">KM Adjustment</span>
                                                        <span v-if="key === 2">Adjusted Values</span>
                                                    </v-tooltip>
                                                </v-col>
                                                <v-col cols="4" class="body-2 py-0 pb-1">
                                                    <v-tooltip bottom>
                                                        <template v-slot:activator="{ on, attrs }">
                                                            <span
                                                                v-if="key === 2"
                                                                v-bind="attrs"
                                                                v-on="on">{{ totalTrade || val['trade'] | currency }}</span>
                                                            <span
                                                                v-else
                                                                v-bind="attrs"
                                                                v-on="on">{{ val['trade'] | currency }}</span>
                                                        </template>
                                                        <span v-if="key === 0">Glass' Value</span>
                                                        <span v-if="key === 1">KM Adjustment</span>
                                                        <span v-if="key === 2">Adjusted Values</span>
                                                    </v-tooltip>
                                                </v-col>
                                                <v-col cols="4" class="body-2 py-0 pb-1">
                                                    <v-tooltip bottom>
                                                        <template v-slot:activator="{ on, attrs }">
                                                            <span
                                                                v-if="key === 2"
                                                                v-bind="attrs"
                                                                v-on="on">{{ totalRetailValue || val['retail-value'] | currency }}</span>
                                                            <span
                                                                v-else
                                                                v-bind="attrs"
                                                                v-on="on">{{ val['retail-value'] | currency }}</span>
                                                        </template>
                                                        <span v-if="key === 0">Glass' Value</span>
                                                        <span v-if="key === 1">KM Adjustment</span>
                                                        <span v-if="key === 2">Adjusted Values</span>
                                                    </v-tooltip>
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                    </v-row>

                                </v-col>
                            </v-row>
                        </div>
                    </v-carousel-item>

                </v-carousel>
            </v-card-text>

            <div class="d-none">
                <div class="my-4 overline text-center">Hiddens</div>
                <v-text-field readonly outlined tile dense type="text" :value="manufacturer.id" name="manufacturer_id" />
                <v-text-field readonly outlined tile dense type="text" :value="manufacturer.name" name="manufacturer_name" />
                <v-text-field readonly outlined tile dense type="text" :value="model.id" name="model_id" />
                <v-text-field readonly outlined tile dense type="text" :value="model.name" name="model_name" />
                <v-text-field readonly outlined tile dense type="text" :value="series.id" name="series_id" />
                <v-text-field readonly outlined tile dense type="text" :value="series.name" name="series_name" />
                <v-text-field readonly outlined tile dense type="text" :value="year" name="yearFrom" />
                <v-text-field readonly outlined tile dense type="text" :value="year" name="yearTo" />
                <v-text-field readonly outlined tile dense type="text" :value="kmAdjustmentAmmount" name="kmAdjustmentAmmount" />
                <v-text-field readonly outlined tile dense type="text" :value="kmAverage" name="kmAverage" />
                <v-text-field readonly outlined tile dense type="text" v-for="(details, key) in selected" :name="key" :value="details" :key="key" />
            </div>

            <v-divider></v-divider>

            <v-card-actions class="pa-4 pa-md-6">
                <v-btn large depressed color="light_gray_2" outlined v-if="showBack" @click="index--"> Back </v-btn>
                <v-spacer/>
                <v-btn large depressed color="secondary" data-btn="nvic-next" v-if="showNext" :disabled="!completedSteps[index]" @click="index++"> Next </v-btn>
                <v-btn large depressed color="secondary" data-btn="nvic-submit" v-if="showConfirm" btn-primary @click="handleAccept" class="px-6"> Confirm NVIC </v-btn>
            </v-card-actions>

        </v-card>

    </v-dialog>

</template>

<script>

    import { mapState } from 'vuex'
    import moment from 'moment'
    import helpers from '~/plugins/helpers'
    import VehicleSpecs from '~/components/pages/General/NvicSpecifications'

    export default {

        name: "nvic-finder",

        mixins: [ helpers ],

        components: { VehicleSpecs },

        data(){
            return {

                manufacturer: { id: null, name: null },
                model: { id: null, name: null },
                variant: { id: null, name: null },
                series: { id: null, name: null },

                yearOptions: [],
				manufacturerOptions: [],
                modelOptions: [],
                variantOptions: [],
				seriesOptions: [],


                nvic: '',
                nvicInfo: '',
                year: null,

                results: null,
                selected: null,
				selectedResult: '',
				kmActual: 19000,
				kmAverage: 0,
				kmAdjustmentAmmount: 0,
                imgPath: '',

           		loading: false,
                dialog: false,
                steps: [
                    'Set Options',
                    'Select Your Car'
                ],
                completedSteps: [],
                index: 0,

                rrp: '',
                headers: [
                    { text: 'Trade Low', sortable: false, value: 'trade-low' },
                    { text: 'Trade', sortable: false, value: 'trade' },
                    { text: 'Retail Value', sortable: false, value: 'retail-value' }
                ],
                priceBreakdown: [],
                priceSubLabels: [ 'Glass Value', 'KM Adjustment', 'Adjusted Values' ],
                rules: [v => v < 1000000 || 'Max value: 999,999'],
            }
        },


        model: {
            prop: 'modelValue',
            event: 'input'
        },


        props: {
            modelValue: {
                type: String,
                default: ''
            },
            defaultValue: {
                type: String,
                default: null
            },
            name: {
                type: String,
                default: ''
            },
            disabled: {
                type: Boolean,
                default: false
            },
            label: {
                type: String,
                default: ''
            },
            errors: {
                type: Array,
                default: ()=>[]
            }
        },


	    watch: {
            defaultValue(){
                this.nvic = this.modelValue
            },
		    year() {
                this.getManufacturers()
            },
			manufacturer: ['getModels'],
            model: ['getVariants'],
            variant: ['getSeries'],
            modelValue: {
                handler(val) {
                    this.nvic = this.modelValue
                },
                deep: true
            },
            kmAdjustmentAmmount: {
                handler(val) {
                    //
                },
                deep: true
            }
        },


        computed: {
            ...mapState('slug', ['flowSlug']),
            showNext(){
                return this.steps.length > (this.index + 1)
            },
            showBack(){
                return this.index != 0
            },
            showConfirm(){
                return this.steps.length == (this.index + 1)
            },
            filteredBreakdown() {
                if ( this.selected ) {

                    this.priceBreakdown = [
                        {
                            'trade-low': this.selected?.['trade-low'],
                            'trade': this.selected?.['trade'],
                            'retail-value': this.selected?.['retail']
                        },
                        {
                            'trade-low': this.kmAdjustmentAmmount / 3,
                            'trade': this.kmAdjustmentAmmount / 2,
                            'retail-value': this.kmAdjustmentAmmount
                        },
                        {
                            'trade-low': this.selected?.['trade-low'],
                            'trade': this.selected?.['trade'],
                            'retail-value': this.selected?.['retail']
                        }
                    ]
                }
                else this.priceBreakdown = []
                return this.priceBreakdown
            },
            totalTradeLow() {
                return parseFloat( this.priceBreakdown?.[0]['trade-low'] ) + parseFloat( this.priceBreakdown?.[1]['trade-low'] )
            },
            totalTrade() {
                return parseFloat( this.priceBreakdown?.[0]['trade'] ) + parseFloat( this.priceBreakdown?.[1]['trade'] )
            },
            totalRetailValue() {
                return parseFloat( this.priceBreakdown?.[0]['retail-value'] ) + parseFloat( this.priceBreakdown?.[1]['retail-value'] )
            }
        },


        created() {

            this.nvic = this.modelValue
            this.setStatuses();

            this.getListOfYears();

        },


        methods: {
	        apiRequest(config) {

                let path = '/run/glasses-guide';

				return this.$api.post(path, config).then((res) => {

					return res.data;

				}).catch(e => {

					console.log(e)

				})

            },

            async getListOfYears() {

                let config = {
                    "data": {
                        "type": "glasses-guides",
                        "attributes": {
                            "input": {
                                "end-point" : "GetListOfYears",
                                "params" : {
                                    "model-type-code": "A"
                                }
                            }
                        }
                    }
                }

                let res = await this.apiRequest(config)

				this.yearOptions = res.data.attributes.output

            },

            async getManufacturers() {

                this.manufacturerOptions = [];

				if(this.yearOptions.length) {

					this.manufacturer = { id: null, name: null };
					this.model = { id: null, name: null };
					this.variant = { id: null, name: null };
                    this.series = { id: null, name: null };

                    this.resetResultsData();

					this.loading = true;

					let config = {
						"data": {
                            "type": "glasses-guides",
                            "attributes": {
                                "input": {
                                    "end-point" : "GetListOfMakesYearFromTo",
                                    "params" : {
                                        "model-type-code": "A",
                                        "year-from": this.year,
                                        "year-to": this.year,
                                    }
                                }
                            }
                        }
					}

                    let res = await this.apiRequest(config)

                    this.manufacturerOptions = res.data.attributes.output
					this.checkCompletedStep()
					this.loading = false;

				}

            },

            async getModels() {

                this.modelOptions = [];

				if(this.manufacturerOptions.length) {

					this.model = { id: null, name: null };
					this.variant = { id: null, name: null };
                    this.series = { id: null, name: null };

                    this.resetResultsData();


					this.loading = true;

					let config = {
						"data": {
                            "type": "glasses-guides",
                            "attributes": {
                                "input": {
                                    "end-point" : "GetListOfModelsYearFromTo",
                                    "params" : {
                                        "model-type-code": "A",
                                        "year-from": this.year,
                                        "year-to": this.year,
                                        "manufacturer-code": this.manufacturer.id
                                    }
                                }
                            }
                        }
					}

                    let res = await this.apiRequest(config)

                    this.modelOptions = res.data.attributes.output

                    this.checkCompletedStep()
					this.loading = false;

				}

            },

	        async getVariants() {

				this.variantOptions = [];

		        if( this.modelOptions.length > 0 ){

					this.variant = { id: null, name: null };
                    this.series = { id: null, name: null };

                    this.resetResultsData();

					this.loading = true;

					let config = {
						"data": {
                            "type": "glasses-guides",
                            "attributes": {
                                "input": {
                                    "end-point" : "GetListOfVariants",
                                    "params" : {
                                        "model-type-code": "A",
                                        "year-from": this.year,
                                        "year-to": this.year,
                                        "year-create": this.year,
                                        "manufacturer-code": this.manufacturer.id,
                                        "family-code": this.model.id
                                    }
                                }
                            }
                        }
					}


					let results = this.getResults();
                    let response = this.apiRequest(config)

                    try {

						let responsePayloads = await Promise.all([results, response]).then(res => {

                            this.variantOptions = res[1].data.attributes.output

                            this.checkCompletedStep()
							this.loading = false;
                        })

					} catch (error) {
						console.log('Ooops!..', error)
					}

				}

            },

	        async getSeries() {

				this.seriesOptions = [];

				 if( this.variantOptions.length > 0 ){

                     this.series = { id: null, name: null };

                    this.resetResultsData();

					 this.loading = true;

					let config = {
						"data": {
                            "type": "glasses-guides",
                            "attributes": {
                                "input": {
                                    "end-point" : "GetListOfSeriesYearFromTo",
                                    "params" : {
                                        "model-type-code": "A",
                                        "year-from": this.year,
                                        "year-to": this.year,
                                        "manufacturer-code": this.manufacturer.id,
                                        "family-code": this.model.id,
                                        "variant-name": this.variant.id
                                    }
                                }
                            }
                        }
					}

					let results = this.getResults();
					let response = this.apiRequest(config)

					try {

						await Promise.all([results, response]).then(res => {

							this.seriesOptions = res[1].data.attributes.output
							this.checkCompletedStep()
							this.loading = false;
						})

					} catch (error) {
						console.log('Ooops!..', error)
					}

				 }

            },

	        async getResults() {

				let config = {
					"data": {
                        "type": "glasses-guides",
                        "attributes": {
                            "input": {
                                "end-point" : "GetAutoDetailsSpeByMake",
                                "params" : {
                                    "keyword": null,
                                    "model-type-code": "A",
                                    "make": this.manufacturer.id,
                                    "model": this.model.id,
                                    "series": this.series.id,
                                    "variant-name": this.variant.id,
                                    "year-create": this.year,
                                }
                            }
                        }
                    }
				}

                let res = await this.apiRequest(config)
                this.results = res.data.attributes.output

            },

	        async getDetail() {

				this.loading = true;

		        if(this.nvic && this.nvic.length == 6){

					let config = {
						"data": {
                            "type": "glasses-guides",
                            "attributes": {
                                "input": {
                                    "end-point" : "GetAutoDetailsSpecification",
                                    "params" : {
                                        "nvic": this.nvic
                                    }
                                }
                            }
                        }
                    }

                    await this.apiRequest(config).then((response) => {

                        this.selected = response.data.attributes.output

                        this.checkCompletedStep()

				    	if(!this.year){
				    		this.year = moment(this.selected['release-date']).format('YYYY')
						}

						this.getImage();
				    	this.getMagicKms();

				    })

                    this.loading = false;

			    }

            },

            convertValue(val) {
                return parseInt(val)
            },

	        getMagicKms() {

		        if(this.selected){

					let totalKms = 0;

                    let carAgeYears = moment().diff(moment(this.year), 'years', true);

                    // if car years under X years

			        if(carAgeYears <= this.selected['averagekm']) {
				        totalKms = this.selected['averagekm'] * carAgeYears;
			        } else {
				        totalKms = this.selected['averagekm'] * this.selected['averagekmfor'];
				        totalKms += this.selected['averagekmthen'] * (carAgeYears - this.selected['averagekmfor']);
                    }

                    let result = Math.round(totalKms) * 1000;

                    this.kmAverage = result
			        this.kmActual = result

			        this.getKmAdjustment();

		        }

            },

	        async getKmAdjustment() {

                this.loading = true;

				let config = {
					"data": {
                        "type": "glasses-guides",
                        "attributes": {
                            "input": {
                                "end-point" : "GetKilometerAdjustment",
                                "params" : {
                                    "model-type-code": 'A',
                                    "nvic": this.nvic,
                                    "actual-km": this.kmActual ? this.kmActual : 0,
                                    "average-km": this.kmAverage ? this.kmAverage : 0
                                }
                            }
                        }
                    }
				}

                let res = await this.apiRequest(config)
                this.kmAdjustmentAmmount = parseFloat(res.data.attributes.output['adjustment-amount'])
                this.loading = false;

            },

            handleNVICChange(result){

                this.nvicInfo = this.removeManufacturerName(result['model-name'])
                this.nvic = result['nvic-cur']
                this.rrp = result['rrp']


                this.getDetail()
                this.$emit('change', result['nvic-cur'])

            },

			getImage(){
				this.imgPath = 'http://autoedgenet.com.au/Pictures/Auto/' + this.selected['nvic-cur'] + '.jpg';
				return this.imgPath;
            },


			getErrorImage(){
				this.imgPath = '/assets/img/no-img-placeholder.png';
            },


			checkCompletedStep(){

                const {index} = this

                switch (index) {
                    case 0:
                        this.completedSteps[index] = this.model.id != null
                        break;
                    case 1:
                        this.completedSteps[index] = true
                        break;
                }

            },


			setStatuses(){
                this.completedSteps = this.steps.map(step => false)
            },


			resetResultsData(){
                this.results = null
                this.selected = null
				this.selectedResult = ''
				this.kmActual = 0
				this.kmAverage = 0
				this.kmAdjustmentAmmount = 0
                this.imgPath = ''
            },


			resetAllData(){
                this.manufacturer = { id: null, name: null }
                this.model = { id: null, name: null }
                this.variant = { id: null, name: null }
                this.series = { id: null, name: null }
                this.yearOptions = []
				this.manufacturerOptions = []
                this.modelOptions = []
                this.variantOptions = []
				this.seriesOptions = []
                this.nvicInfo = ''
                this.year = null
           		this.loading = false
                this.index = 0
                this.results = null
                this.selected = null
				this.selectedResult = ''
				this.kmActual = 0
				this.kmAverage = 0
				this.kmAdjustmentAmmount = 0
                this.imgPath = ''
            },


            handleAccept(){

                this.dialog = false
           		this.loading = false
                this.index = 0

                this.$emit('input', this.nvic)

                this.$emit('accept', {
                    nvic: this.nvic,
                    details: this.selected,
                    img: this.imgPath
                })

            },


            handleChange(val){
                this.$emit('input', val)
            },


            removeManufacturerName(value) {

                const { manufacturer } = this

                if (!value) return ''
                if (!manufacturer.name) return ''

                let newValue = value.replace(manufacturer.name, "");

                return newValue

            },


        }
    }
</script>

<style lang="scss">
    .v-toolbar__content {
        padding: 0 !important;
    }
</style>

