<template>
    <v-card class="mx-auto" tile flat>
        <v-card-text class="pa-4 pt-0 pa-md-10 pt-md-0">
            <ValidationObserver ref="observer" tag="form">
                   <div class="flex-form">
                        <div class="flex-field">

                            <ValidationProvider
                                v-slot="{ errors }"
                                :name="labels['isExistingClient']"
                                :rules="getRules('isExistingClient')"
                                :vid="'isExistingClient'">

                                <ButtonGroup3
                                    v-model="isExistingClient"
                                    :error-messages="errors"
                                    :options="['Yes', 'No']"
                                    :label="labels['isExistingClient']" />

                            </ValidationProvider>

                        </div>
                        <div class="flex-field">

                            <ValidationProvider
                                v-slot="{ errors }"
                                :name="labels['productType']"
                                :rules="getRules('productType')"
                                :vid="'productType'">
                                <v-select
                                    v-model="resources.loan.attributes.lender.financeOne.productType"
                                    :items="options['productType']"
                                    :error-messages="errors"
                                    color="secondary"
                                    :label="labels['productType']"
                                ></v-select>

                            </ValidationProvider>

                        </div>
                        <div class="flex-field" v-if="isTargetAssister">

                            <ValidationProvider
                                v-slot="{ errors }"
                                :name="labels['actual-broker-fee']"
                                :rules="getRules('actual-broker-fee')"
                                :vid="'actual-broker-fee'">

                                <NodifiMoney
                                    v-model="resources.loan.attributes['actual-broker-fee']"
                                    :errors="errors"
                                    color="secondary"
                                    :label="labels['actual-broker-fee']" />

                            </ValidationProvider>

                        </div>
                        <div class="flex-field" v-else>

                            <ValidationProvider
                                v-slot="{ errors }"
                                :name="labels['quoted-broker-fee']"
                                :rules="getRules('quoted-broker-fee')"
                                :vid="'quoted-broker-fee'">

                                <NodifiMoney
                                    v-model="resources.loan.attributes['quoted-broker-fee']"
                                    :errors="errors"
                                    color="secondary"
                                    :label="labels['quoted-broker-fee']" />

                            </ValidationProvider>

                        </div>
                        <div class="flex-field"> <!-- Spacer --> </div>
                    </div>

                    <div class="flex-form">
                        <div class="flex-field">
                            <ValidationProvider
                                v-slot="{ errors }"
                                :name="labels['requested-term-months']"
                                :rules="getRules('requested-term-months')"
                                :vid="'requested-term-months'">
                                <v-select
                                    v-model="resources.loan.attributes['requested-term-months']"
                                    :items="options['requested-term-months']"
                                    :error-messages="errors"
                                    color="secondary"
                                    :label="labels['requested-term-months']"
                                ></v-select>
                            </ValidationProvider>
                        </div>
                        <div class="flex-field">
                            <ValidationProvider
                                v-slot="{ errors }"
                                :name="labels['purchase-price']"
                                :rules="getRules('purchase-price')"
                                :vid="'purchase-price'">
                                <NodifiMoney
                                    v-model="resources.purchase.attributes['purchase-price']"
                                    :errors="errors"
                                    color="secondary"
                                    :label="labels['purchase-price']" />
                            </ValidationProvider>
                        </div>
                        <div class="flex-field"> <!-- Spacer --> </div>
                        <div class="flex-field"> <!-- Spacer --> </div>
                    </div>

            </ValidationObserver>

            <v-alert dense outlined type="error" v-if="showProductTypeError">
                <div>Product Type is required! Please adjust Loan Term or Purchase Price</div>
            </v-alert>

        </v-card-text>
    </v-card>
</template>

<script>
import { mapActions, mapState, mapMutations } from 'vuex'
import helpers from '~/plugins/helpers'
import subscriptions from '~/components/shared/subscriptions'
import ButtonGroup3 from '~/components/base/ButtonGroup3'
import NodifiMoney from '~/components/base/NodifiMoney'
import { join } from 'path'

export default {
    components: { ButtonGroup3 , NodifiMoney},
    mixins: [subscriptions, helpers],
    data() {
        return {
            isExistingClient: 'No',
            resources: {
                loan: {
                    type: 'loans',
                    id: null,
                    attributes: {
                        "actual-broker-fee": null,
                        "quoted-broker-fee": null,
                        "requested-term-months": "",
                        "lender": {
                            "financeOne": {
                                "productType": null
                            }
                        }
                    }
                },
                person: {
                    type: 'people',
                    id: null,
                    attributes: {
                        "lender": {
                            "financeOne": {
                                "isExistingClient": false
                            }
                        }
                    }
                },
                purchase: {
                    type: 'purchases',
                    id: null,
                    attributes: {
                        "purchase-price": "",
                    }
                },

            },
            productTypeOptions: [
                {
                    value: 'CON.S.ECO',
                    text: 'Consumer Economy',
                    minLoan: 5000,
                    maxLoan: 12000,
                    minTerm: 36,
                    maxTerm: 48,
                },
                {
                    value: 'CON.S.BRO',
                    text: 'Consumer Bronze',
                    minLoan: 8000,
                    maxLoan: 25000,
                    minTerm: 36,
                    maxTerm: 60,
                },
                {
                    value: 'CON.S.SIL',
                    text: 'Consumer Silver',
                    minLoan: 8000,
                    maxLoan: 50000,
                    minTerm: 36,
                    maxTerm: 72,
                },
                {
                    value: 'CON.S.GOL',
                    text: 'Consumer Gold',
                    minLoan: 8000,
                    maxLoan: 100000,
                    minTerm: 36,
                    maxTerm: 84,
                },
                {
                    value: 'CON.S.PLAT',
                    text: 'Consumer Platinum',
                    minLoan: 8000,
                    maxLoan: 100000,
                    minTerm: 36,
                    maxTerm: 84,
                },
                {
                    value: "CON.S.PRIM",
                    text: "Consumer Plus",
                    minLoan: 8000,
                    maxLoan: 100000,
                    minTerm: 36,
                    maxTerm: 84,
                },
                {
                    value: 'COM.S.SIL',
                    text: 'Commercial Silver',
                    minLoan: 8000,
                    maxLoan: 50000,
                    minTerm: 1,
                    maxTerm: 60,
                },
                {
                    value: 'COM.S.GOL',
                    text: 'Commercial Gold',
                    minLoan: 8000,
                    maxLoan: 150000,
                    minTerm: 1,
                    maxTerm: 84,
                },
                {
                    value: 'COM.S.PLAT',
                    text: 'Commercial Platinum',
                    minLoan: 8000,
                    maxLoan: 150000,
                    minTerm: 1,
                    maxTerm: 84,
                },
            ],
            options: {
                'productType': [],
                'requested-term-months': [12,24,36,48,60,72,84]
            },
            labels: {
                "isExistingClient": "Is applicant currently a client of Finance One?",
                "productType": "Product Type",
                "actual-broker-fee": "Broker Fee",
                "quoted-broker-fee": "Broker Fee",
                "requested-term-months": "Requested Loan Term",
                "purchase-price": "Purchase Price",
            },
            validation_rules: {
                attributes: {
                    "isExistingClient": "required",
                    "productType": "required",
                    "actual-broker-fee": `required|between:0,1100`,
                    "quoted-broker-fee": `required|between:0,1100`,
                    "requested-term-months": `required|in:12,24,36,48,60,72,84`,
                    "purchase-price": `required|between:0,75000`,
                }
            },
            people: [],
        }
    },
    computed: {
        ...mapState({
            appId: state => state.flows.appId,
            loanData: state => state.flows.loanData,
            calculation: state => state.flows.calculation,
        }),
        productType () {
            return this.resources.loan.attributes.lender.financeOne.productType
        },
        getLoanId(){
            return this.appData.relationships.loan.data.id
        },
        ...mapState('auth-actions', [
            'user'
        ]),
        isConsumer(){
            if(this.loanData){
                return  this.loanData.attributes['finance-for-business-purposes'] == 'No'
            }
            return false
        },
        isTargetAssister() {
            return this.appData.attributes['target'] == 'Assister'
        },
        requestedTermMonths(){
            return this.resources.loan.attributes['requested-term-months']
        },
        purchasePrice(){
            return this.resources.purchase.attributes?.['purchase-price']
        },
        showProductTypeError(){
            return this.options['productType'].length == 0
        }
    },
    methods: {
        ...mapActions('resource-actions', [
            'getLoan',
            'getTerms',
            'updateLoan',
            'getPurchases',
            'updatePerson',
            'getPerson',
            'getPeople',
            'getCommitments',
            'createCommitments',
            'updatePurchases',
        ]),
        async pull() {

            let promise2 = this.getPeople()
            let promise3 = this.getLoan(this.getLoanId)
            let promise4 = this.getPurchases()

            const res = await Promise.all([
                promise2,
                promise3,
                promise4
            ])

            promise2 = res[0]
            promise3 = res[1]
            promise4 = res[2]

            if(promise2.status == 200) {

                this.people = promise2.data.data

                let allPeopleExistingClient = true

                promise2.data.data.forEach(person => {
                    if(!person.attributes.lender.financeOne.isExistingClient) {
                        allPeopleExistingClient = false
                    }
                })

                this.isExistingClient = allPeopleExistingClient ? 'Yes' : 'No'

            }

            if(promise3.status == 200) {
                this.resources.loan = this.resourceFieldPicker(this.resources.loan, promise3.data.data)
            }

            if (promise4.status == 200 && promise4.data?.data?.length > 0) {
                this.resources.purchase = this.resourceFieldPicker(this.resources.purchase, promise4.data?.data?.[0])
            }
        },
        async push() {

            const observer = this.$refs.observer
            const {valid, status} = await this.validateFormData()

            let loanRes = null

            if(valid) {

                const {loan, purchase} = this.resources

                // Exclude resources.relationships and other stuff

                let payload1 = {
                    type: loan.type,
                    id: this.getLoanId,
                    attributes: {
                        ...loan.attributes
                    }
                }

                // Populate actual field on save
                payload1.attributes = {
                    ...payload1.attributes,
                    "actual-term-months": loan.attributes["requested-term-months"]
                }

                loanRes = await this.updateLoan(payload1)

                let peopleReqs = []

                this.people.forEach( person => {

                    let payload2 = {
                        type: 'people',
                        id: person.id,
                        attributes: {
                            lender: {
                                financeOne: {
                                    ...person.attributes.lender.financeOne,
                                    isExistingClient: (this.isExistingClient == 'Yes') ? true : false
                                }
                            }
                        }
                    }

                    peopleReqs.push(this.updatePerson(payload2))
                })

                await Promise.all(peopleReqs)


                let payload3 = {
                    type: purchase.type,
                    id: purchase.id,
                    attributes: {
                        ...purchase.attributes
                    }
                }

                await this.updatePurchases(payload3)

            }

            const data = await this.appDataHelper([loanRes])

            return {valid: valid, status: valid ?  'valid' : 'dirty', data}

        },
        isReadOnly(key){

            const {readonly} = this

            if(readonly.hasOwnProperty(key)){
                return readonly[key]
            }

            return false

        },
        reCalculateProductTypeOptions(){

            let options = []

            let termMonths = this.requestedTermMonths
            const purchasePrice = this.purchasePrice

            this.productTypeOptions.forEach(item => {
                if ((this.isConsumer && item.text.includes('Consumer')) || (!this.isConsumer && item.text.includes('Commercial')) ) {
                    if((purchasePrice >= item.minLoan  && purchasePrice <= item.maxLoan) && (termMonths >= item.minTerm && termMonths <= item.maxTerm)) {
                        options.push(item)
                    }
                }
            })

            this.options['productType'] = options

            let productTypeValue = this.resources.loan.attributes.lender.financeOne.productType
            let inOptions = options.find(option => option.value == productTypeValue)

            if(!inOptions) {
                this.resources.loan.attributes.lender.financeOne.productType = null
            }

        }

    },
    watch: {
        requestedTermMonths(){
            this.reCalculateProductTypeOptions()
        },
        purchasePrice(){
            this.reCalculateProductTypeOptions()
        },
        
        productType(value) {
            if (value) {
                const item = this.options['productType'].find(item => item.value === value)
                this.validation_rules.attributes['purchase-price'] = `required|between:${item.minLoan},${item.maxLoan}`
            }
        },
    }
}
</script>

<style>
    .debug {
        border: 1px solid red;
    }
</style>

