<template>
    <v-card class="mx-auto" tile flat>
        <v-card-text class="pa-4 pt-0 pa-md-10 pt-md-0">
            <ValidationObserver ref="observer" tag="form">
                   <div class="flex-form">

                            <div class="flex-field">
                                <ValidationProvider
                                    v-slot="{ errors }"
                                    :name="labels['dealType']"
                                    rules="required"
                                    :vid="labels['dealType']">
                                    <v-select
                                        v-model="resources.loan.attributes.lender.westpac.dealType"
                                        :items="getOptions('dealType')"
                                        :error-messages="errors"
                                        color="secondary"
                                        :label="labels['dealType']"
                                    ></v-select>
                                </ValidationProvider>
                            </div>

                            <div class="flex-field">
                                <ValidationProvider
                                    v-slot="{ errors }"
                                    :name="labels['firmType']"
                                    rules="required"
                                    :vid="labels['firmType']">
                                    <v-select
                                        v-model="resources.loan.attributes.lender.westpac.firmType"
                                        :items="getOptions('firmType')"
                                        :error-messages="errors"
                                        color="secondary"
                                        :label="labels['firmType']"
                                    ></v-select>
                                </ValidationProvider>
                            </div>

                            <div class="flex-field">
                                <ValidationProvider
                                    v-slot="{ errors }"
                                    :name="labels['financeType']"
                                    rules="required"
                                    :vid="labels['financeType']">
                                    <v-select
                                        v-model="resources.loan.attributes.lender.westpac.financeType"
                                        :items="getOptions('financeType')"
                                        :error-messages="errors"
                                        color="secondary"
                                        :label="labels['financeType']"
                                    ></v-select>
                                </ValidationProvider>
                            </div>

                            <div class="flex-field">
                                <ValidationProvider
                                    v-slot="{ errors }"
                                    :name="labels['repaymentIn']"
                                    rules="required"
                                    :vid="labels['repaymentIn']">
                                    <v-select
                                        v-model="resources.loan.attributes.lender.westpac.repaymentIn"
                                        :items="getOptions('repaymentIn')"
                                        :error-messages="errors"
                                        color="secondary"
                                        :label="labels['repaymentIn']"
                                    ></v-select>
                                </ValidationProvider>
                            </div>

                    </div>
            </ValidationObserver>

            <div class="pb-12"> 
                <!-- spacer -->
            </div>
        </v-card-text>
    </v-card>
</template>

<script>
import { mapActions, mapState, mapMutations } from 'vuex'
import helpers from '~/plugins/helpers'
import subscriptions from '~/components/shared/subscriptions'
import lenderFields from '~/components/v1/submission/Customisations1/__lenders'
import ButtonGroup1 from '~/components/base/ButtonGroup1'
import NodifiMoney from '~/components/base/NodifiMoney'

export default {
    name: 'LenderPepperCustomizations1',
    components: { ButtonGroup1 , NodifiMoney},
    mixins: [subscriptions, helpers],
    data() {
        return {
            resources: {
                loan: {
                    type: 'loans',
                    id: null,
                    attributes: {
                        lender: {
                            westpac: {
                                "dealType": null,
                                "firmType": null,
                                "financeType": null,
                                "repaymentIn": null,
                            }
                        }
                    }
                }

            },
            options: {
                "dealType": [
                    {value: "CALApproval", text:"CAL Approval"},
                    {value: "CALApprovalOldPolicy", text:"CAL Approval (expired policy)"},
                    {value: "CreditApprovalHeld", text:"Credit Approval Held"},
                    {value: "Drawdown", text:"Revolving Limit Drawdown"},
                    {value: "DriveXpress", text:"DriveXpress"},
                    {value: "NovatedLease", text:"Novated Lease"},
                    {value: "RapidDecisioningMotorVehicle", text:"Rapid Decisioning Motor Vehicle"},
                    {value: "RapidDecisioningSmallTicket", text:"Rapid Decisioning Small Ticket"},
                    {value: "Replacement", text:"Replacement"},
                    {value: "ReplacementOldPolicy", text:"Replacement (expired policy)"},
                    {value: "Rollover", text:"Rollover"},
                    {value: "RolloverOldPolicy", text:"Rollover (expired policy)"},
                    {value: "StandardApplication", text:"Standard Application"},
                ],
                "firmType": [
                    {value: "Ltd", text:"Limited (Ltd.)"},
                    {value: "PtyLtd", text:"Proprietary Limited (Pty. Ltd.)"},
                    {value: "Individual", text:"Individual"},
                    {value: "UndefinedType", text:"UndefinedType"},
                ],
                "financeType": [
                    {value: "CommercialLoan", text: "Commercial Loan / Goods Loan"},
                    {value: "CommercialHirePurchase", text: "Commercial Hire Purchase"},
                    {value: "FinanceLease", text: "Finance Lease"},
                    {value: "NovatedLease", text: "Novated Lease"},
                    {value: "Rental", text: "Rental"},
                ],
                "repaymentIn": [
                    {value:"Advance", text:"Advance"},
                    {value:"Arrears", text:"Arrears"},
                    {value:"2 months in Arrears", text:"2 months in Arrears"},
                ]
            },
            labels: {
                "dealType": "Application Type",
                "firmType": "Brokerage Details - Firm Type",
                "financeType": "Finance Type",
                "repaymentIn": "Repayment In",
            },

        }
    },
    computed: {
        ...mapState({
            appId: state => state.flows.appId,
            loanData: state => state.flows.loanData,
        }),
        getLoanId(){
            return this.appData.relationships.loan.data.id
        },
    },
    methods: {
        ...mapActions('resource-actions', [
            'getLoan',
            'updateLoan',
        ]),
        isPickList (key) {
            return [
                "dealType",
                "firmType",
                "financeType",
                "repaymentIn",
            ].includes(key)
        },
        async pull() {

            let res = await this.getLoan(this.getLoanId)


            if(res.status == 200) {
                this.resources.loan.attributes.lender.westpac = {
                    ...res.data.data.attributes.lender.westpac
                }
            }

        },
        async push() {

            const observer = this.$refs.observer

            const {valid, status} = await this.validateFormData()

            let loanRes = null

            if(valid) {
                
                const {loan} = this.resources
                    
                // Exclude resources.relationships and other stuff

                let payload = {
                    type: loan.type,
                    id: this.getLoanId,
                    attributes: {
                        lender: {
                            westpac: {
                                ...this.resources.loan.attributes.lender.westpac
                            }
                        }
                    }
                }

                await this.updateLoan(payload)
            }

            return {valid: valid, status: status, data: {}}

        },
    },
    watch: {
    }
}
</script>

<style>

</style>

