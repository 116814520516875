<template>
    <div class="flex__sub-component-TextField2">

        <v-text-field
            v-if="fieldType == 'number'"
            v-model="inputData"
            :label="label"
            :type="fieldType"
            :disabled="disabled"
            @input="emitData($event)"
            :error-messages="errors"
            :prefix="prefix"
            :suffix="suffix">
        </v-text-field>

        <v-text-field
            v-else-if="fieldType == 'text'"
            v-model="inputData"
            :label="label"
            :type="fieldType"
            :disabled="disabled"
            @input="emitData($event)"
            :error-messages="errors"
            :prefix="prefix"
            :suffix="suffix">
        </v-text-field>

    </div>
</template>

<script>

    export default {
        name: 'TextField2',
        model: {
            prop: 'modelValue',
            event: 'input'
        },
        props: {
            modelValue: {
                type: [String, Number],
                default: ''
            },
            label: {
                type: String,
                default: ''
            },
            fieldType: {
                type: String,
                default: 'text'
            },
            disabled: {
                type: Boolean,
                default: false
            },
            errors: {
                type: Array,
                default: ()=>[]
            },
            prefix: {
                type: String,
                default: ''
            },
            suffix: {
                type: String,
                default: ''
            }
        },
        mounted() {
            if (this.modelValue) {
                this.inputData = this.modelValue
            }
        },
        methods: {
            emitData(evt) {     
                this.$emit('input', this.inputData)
                return
            }
        },
        watch: {
            modelValue(val) {
                this.inputData = val
            }
        },
        data() {
            return {
                inputData: null,
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>