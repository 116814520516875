/**
 * Middleware for Flex-Flow
 * see: pages\_group_slug\flows\_flow_slug\_steps\_app_guid\index.vue
 * 
 */


/**
 *  Test Flow:
 *  - You can change this to any static flow during development
 *  - DON'T forget to comment out TestFlow once you're done development
 */

// import TestFlow from '~/config/test/test-flow-westpac'
// import TestFlow2 from '~/config/test/test-init-data'

export default async (context) => {

    let {appId, appGuid} = context.store.state.flows

    let keywords = ['current', 'next', 'previous', 'first', 'last']

    // Checker for entity in the URL
    let isEntitySpecificFlow = (context?.params?.record && context?.params?.record_id)


    /**
     *  Check if guid exists otherwise redirect to 404
     *  see .nuxt/router.js  for route names  e.g: 'group_slug-flow_slug-steps-app_guid'
     */

    // Then: Initialize Flow config & AppData once

    if (!appId) {

        let flowSlug = context.store.getters['slug/getFlowSlug']   

        let initDataPayload = {
            "data": [
                {
                    "type": "init-flow",
                    "input": {
                        "guid": appGuid,
                        "slug": flowSlug
                    }
                }
            ]
        }

        // Supply entity info if entity specific URL
        if(isEntitySpecificFlow) {
            initDataPayload.data[0].input["entity-type"] = context.params.record
            initDataPayload.data[0].input["entity-id"] = context.params.record_id
        } else {
            // reset records if does not exist
            context.store.commit('flows/setRecords', null)
        }
        
        let initDataResponse = await context.app.$api.post(`run/init-data`, initDataPayload)   

        if(initDataResponse && initDataResponse.status === 403) {
            context.redirect(`/${context.store.state.slug.groupSlug}/login?app=${context.store.state.flows.appGuid}`)
        }

        let initFlowData = initDataResponse.data.data[0]


        if (  initFlowData.output == null ) {

            let statusCode = 400
            let message = initFlowData.message
            
            // if(!flowResponse) {
            //     message = 'Error requesting Flow' 
            // }

            // if(!appData) {
            //     statusCode = 403
            //     message = 'Forbidden' 
            // }

            context.error({
                statusCode,
                message
            })

            return

        }
        

        let appData = initFlowData?.output?.application?.data
        let appIncludes = initFlowData?.output?.application?.included
        let termGroup = initFlowData?.output?.['term-group']
        let commitment = initFlowData?.output?.commitment
        
        let flowResponse = initFlowData.output.flow.data


        let calculation = null 
        let loan = null 
        let box = null 
        let appOwnerData = null 
        let appGroupData = null 


        appIncludes.forEach( item => {

            if(item.type == 'calculations') {
                calculation = item
            }

            if(item.type == 'loans') {
                loan = item
            }

            if(item.type == 'box') {
                box = item
            }
            
            if(item.type == 'users') {
                appOwnerData = item
            }

            if(item.type == 'groups') {
                appGroupData = item
            }

        })


        // Save Box data for future use.
        if(box){
            context.store.commit('flows/setBoxData', box?.data)
        }

        // show compliance modal action and set application theme
        if(appData) {
            

            if(appData?.relationships?.group?.data?.id) {

                const appGroupData = await context.store.dispatch('groups/getGroup', appData?.relationships?.group?.data?.id)

                context.store.commit('groups/setApplicationGroup', appGroupData?.data?.data)
    
                if(appGroupData?.data?.data?.relationships?.['active-theme']?.data?.id) {


                    const appThemeData = await context.store.dispatch('themes/fetchTheme', appGroupData?.data?.data?.relationships?.['active-theme']?.data?.id)

                    context.store.commit('themes/setActive', appThemeData.data.data.attributes.slug)

                    context.$vuetify.theme.options.customProperties = true

                    let vThemes = context.$vuetify.theme.themes
                    let light = vThemes.light
                
                    vThemes.light = {
                        ...light,
                        ...context.store.getters['themes/getActiveTheme']['colors']
                    }

                    context.app.head.link = context.app.head.link.filter( item => item.id !== 'appthemeCss')
                
                    context.app.head.link.push({
                        rel: 'stylesheet', 
                        type: 'text/css',
                        id: 'appthemeCss',
                        href: `/${appThemeData.data.data.attributes.slug}.css`,
                    })

                    context.store.commit('themes/setIsActiveAppThemeSet', true)

                }
            }

            context.store.dispatch('flows/showComplianceModalAction', appData)

        }

        if (calculation) {
            context.store.commit('flows/setCalculation', calculation.attributes)
        }

        if (loan) {
            context.store.commit('flows/setLoanData', loan)
        }

        if (termGroup) {
            context.store.commit('flows/setTermGroup', termGroup)
        }

        if (commitment) {
            context.store.commit('flows/setCommitments', commitment)
        }

        if(appOwnerData) {
            context.store.commit('flows/setAppOwnerData', appOwnerData)
        }

        if(appGroupData) {
            context.store.commit('flows/setAppGroupData', appGroupData)
        }


        /**
         * Start Integrity Check
         */

        let fullStepsConfig = flowResponse?.attributes?.config     
        let stepsConfig = fullStepsConfig  
        
        // Make sure this condition only works on development
        if(typeof TestFlow != 'undefined' && process.env.NC_ENV != 'production'){
            stepsConfig = TestFlow
        }

        /**
         * We use string config in testing, 
         * remove if not needed. 
         */

        if(typeof stepsConfig == 'string'){
            fullStepsConfig = JSON.parse(fullStepsConfig)
            stepsConfig = JSON.parse(stepsConfig)
        }

        let redirectObj = stepsConfig.flow.redirect

        // get flow tags
        let flowTags = stepsConfig.flow.tags        

        stepsConfig = stepsConfig.flow.steps

        let layoutSpacesErrors = await checkLayoutsAndSpaces(stepsConfig)
        let blockSettingsErrors = await checkBlockSettings(stepsConfig)

        if(layoutSpacesErrors.length > 0 || blockSettingsErrors.length > 0) {

            let count = layoutSpacesErrors.length + blockSettingsErrors.length

            context.error({
                statusCode: 400,
                message: `Fatal: ${count} configuration errors found`, 
                misc: [...layoutSpacesErrors, ...blockSettingsErrors] 
            })

            return
        }

        /**
         * End Integrity Check
         */

        
        /**
         * Start filter flow-status
         * 
         * Check for current flow-status in applications.
         * Also, filter flow statuses if entity specific flows
         */
        let currentFlowStatus = null
        let includedFlowStatuses = appIncludes.filter( item => item.type == 'flow-statuses')
        let flowStatuses = []

        if(includedFlowStatuses && includedFlowStatuses.length > 0) {

            // save all flow statuses and step statuses in vuex
            context.store.commit('statuses/setFlowStatuses', includedFlowStatuses)

            includedFlowStatuses.forEach(status => {

                if(status.relationships?.flow?.data?.["id"] == flowResponse.id) {
                    flowStatuses.push(status)
                }

            })

        }

        currentFlowStatus = flowStatuses[0]
        
        if(isEntitySpecificFlow) {

            let entityFlowStatus = null

            flowStatuses.forEach(status => {
                if(status.relationships.entity.data["id"] == context.params.record_id) {
                    entityFlowStatus = status
                }
            })

            currentFlowStatus = entityFlowStatus
        }

        /**
         *  Collect all step-statuses in application.included
         *  filtered via flow-status
         */
        let stepStatuses = [] 
        let includedStepStatuses = appIncludes.filter(item => item.type == 'step-statuses')
 
        if(includedStepStatuses){
            context.store.commit('statuses/setAllStepStatuses', includedStepStatuses)
            
            stepStatuses = includedStepStatuses.filter(step => {

                if(step.relationships['flow-status'].data?.id == currentFlowStatus.id){
                    return true
                }

                return false
            })
            
        }

        // console.log('currentFlowStatus: ', currentFlowStatus)
        /**
         *  End filter flow-status
         */

        context.store.commit('flows/setAppID', appData.id)
        context.store.commit('flows/setAppData', appData)

        context.store.commit('flows/setFlowID', flowResponse.id)
        context.store.commit('flows/setFlowData', flowResponse)
        context.store.commit('statuses/setFlowStatus', currentFlowStatus)

        context.store.commit('statuses/setStepStatuses', stepStatuses)
        context.store.commit('flows/setConfigData', fullStepsConfig)
        context.store.commit('flows/setStepsData', stepsConfig)

        context.store.commit('flows/setRedirectPaths', redirectObj)
        context.store.commit('flows/setFlowTags', flowTags)

        /**
         *  Keywords Redirect
         */ 
        if( keywords.includes(context.params.steps) ) {

            context.store.commit('setKeywordRoute', true)

            let currentStep = currentFlowStatus.attributes['current-step']
            const {steps, flow_slug, group_slug, app_guid, record, record_id} = context.params

            switch (steps) {
                case "next":
                    currentStep = parseInt(currentStep) + 1
                break;
                case "first":
                    let loopStopper = false
                    currentStep = 1

                    if(stepStatuses.length > 0) {
                        stepStatuses.forEach((status, index) => {
                            if(!loopStopper && status.attributes.included === 1) {
                                currentStep = index + 1
                                loopStopper = true
                            }
                        })
                    }

                break;
                case "last":
                    currentStep = stepsConfig.length
                break;
                case "previous":
                    currentStep = parseInt(currentStep) > 1 ? parseInt(currentStep) - 1 : 1
                break;
            }

            if(isEntitySpecificFlow) {

                context.redirect(`/${group_slug}/flows/${flow_slug}/${currentStep}/${app_guid}/${record}/${record_id}`)

            } else {

                // context.redirect(`/${group_slug}/flows/${flow_slug}/${currentStep}/${app_guid}`)
                context.app.router.push(`/${group_slug}/flows/${flow_slug}/${currentStep}/${app_guid}`)

            }

            return

        } 
        /**
         *  End Keywords Redirect
         */ 

    } else {
    
        // Reset onKeywordRoute to false, means current route is not on the keyword step

        context.store.commit('setKeywordRoute', false)

    }

    // Set record params in vuex
    if(isEntitySpecificFlow) {
        context.store.commit('flows/setRecords', {
            type: context.params.record, 
            id: context.params.record_id
        })
    }


    // Set step, layout and spaces in every route change.
    if(context.params.hasOwnProperty('steps')) {

        if(!keywords.includes(context.params.steps)) {


            context.store.commit('flows/setStep', context.params.steps)

            /**
             *  Apply lender style override
             * 
             *  NOTE: this will add new stylesheet specific for lender,
             *  needs page refresh to include/exclude css file.
             *  may need to look for differenct approach in the future.
             * 
             */
    
            let activeStepData = context.store.getters['flows/getActiveStepData']

            // Redirect to first step if step is out of range
            if(!activeStepData) {
                window.location.replace(`/${context.store.state.slug.groupSlug}/flows/${context.store.getters['slug/getFlowSlug']}/first/${appGuid}`)
                return
            }

            if(activeStepData.hasOwnProperty('css-class') && activeStepData['css-class']) {

                const flow_slug = context.store.getters['slug/getFlowSlug']

                if(flow_slug.includes('lender')) {

                    let lenderStyles = activeStepData['css-class']
    
                    let theme = {
                        rel: 'stylesheet', 
                        type: 'text/css',
                        href: `/${lenderStyles}.css`,
                    }
                    
                    context.app.head.link.push(theme)
                    context.store.commit('themes/setLenderClass', lenderStyles)

                    let vThemes = context.$vuetify.theme.themes
                    let light = vThemes.light
        
                    vThemes.light = {
                        ...light,
                        ...context.store.getters['themes/getActiveLenderTheme']['colors']
                    }
                    
                } else {

                    const flexOverrideClass = activeStepData['css-class']
                    context.store.commit('themes/setFlexOverrideClass', flexOverrideClass)

                }
    
            } 
    
            context.store.commit('flows/setLayout')
            context.store.commit('flows/setSpaces')

            // check for listeners in steps then redirect
            await context.store.dispatch('flows/checkStepsListeners')

        }


    }

}


/**
 * Config Integrety checker for Layouts, Spaces, Blocks,
 * Put these checkers in a separate file if needed.
 * 
 * checkLayoutsAndSpaces
 * checkBlockSettings
 * 
 */

const checkLayoutsAndSpaces = (steps) => {

    const errors = [];
    
    return new Promise( (resolve, reject) => {

        steps.forEach( (step, index) => {

            switch (step.layout.name ) {

                case "FullWidth1":
                    checkSpaces(step, index, ['Header', 'Page', 'Footer'])
                break;

                case "LeftSideBar1":
                    checkSpaces(step, index, ['Header', 'Page', 'LeftSideBar', 'Footer'])
                break;

                case "RightSideBar1":
                    checkSpaces(step, index, ['Header', 'Page', 'RightSideBar', 'Footer'])
                break;

                case "DualSideBar1":
                    checkSpaces(step, index, ['Header', 'Page', 'LeftSideBar', 'RightSideBar', 'Footer'])
                break;

                default:
                    // Error: Unknown Layout!
                    checkSpaces(step, index, null)
                break;
            }

        });
      

        function checkSpaces(step, index, comparee) {
            
            const name = step.layout.name 
            const spaces = step.layout.spaces.map(space => space.name) // get only array of space names
            
            let unique = []
    
            // Throw error if comparee is null.
            if(comparee == null ){
                
                let errorMsg2 = `Config integrity check failed. Step: ${index + 1}. Unknown layout ${name}.`
    
                errors.push(errorMsg2)
    
            } else {
    
                let errorMsg1 = `Config integrity check failed. Step: ${index + 1}. Layout ${name} must have the following spaces, and only the following spaces: ${comparee.join(', ')}.`;
    
                // Throw error if spaces length did not match.
                if(spaces.length != comparee.length ) {
                    errors.push(errorMsg1)
                    return
                };
                
                spaces.forEach( async (space, i) => {
    
                    if( comparee.includes(space) && !unique.includes(space) ){
  
                        unique.push(space)
    
                    } else {
    
                        // missing or duplicate
                        errors.push(errorMsg1)
    
                    }
    
                })
    
            }
    
        }

        resolve(errors);

    })

}


const checkBlockSettings = (steps) => { 

    let errors = []

    return new Promise( (resolve, reject) => {

        steps.forEach( (step, index) => {

            const layout = step.layout.name
            const spaces = step.layout.spaces

            let stepSpaces = spaces.map( space => space.name)
            
            spaces.forEach( space => {

                space.blocks.forEach( block => {

                    if(block.settings) {
        
                        block.settings.forEach( seting => {
        
                            switch (seting.name ) {
            
                                case "showLeftSideBarToggleButton":
                                case "showLeftSideBarMinimiseButton":
                                    checkBlock(layout, index+1, stepSpaces, seting.name, 'LeftSideBar')
                                break;
            
                                case "showRightSideBarMinimiseButton":
                                case "showRightSideBarToggleButton":
                                    checkBlock(layout, index+1, stepSpaces, seting.name, 'RightSideBar')
                                break;
        
                                default:
                                    // Error: Unknown Setting!
                                    // checkBlock(layout, index+1, stepSpaces, seting.name, null)
                                break;
        
                            }
            
                        });
        
                    };
        
                });

            });

        });


        function checkBlock(layout, step, spaces, setting, block) {
            
            let errorMsg1 = `Config integrity check failed. Step: ${step}. Can not have ${setting} setting on a component when there is not ${block} space in ${layout} layout.`;
            
            let errorMsg2 = `Config integrity check failed. Step: ${step}. Unknown setting ${setting}`
            
            // Error: Unknown Setting!
            if(block == null) {
                errors.push(errorMsg2)
                return;
            }

            // Throw error if required space for a setting not is set.
            if( !spaces.includes(block) ) errors.push(errorMsg1);

        }

        resolve(errors);

    });

}