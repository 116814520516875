var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"mx-auto",attrs:{"tile":"","flat":""}},[_c('v-card-text',{staticClass:"pa-4 pt-0 pa-md-10 pt-md-0"},[_c('ValidationObserver',{ref:"observer",attrs:{"tag":"form"}},[_c('div',{staticClass:"flex-form"},[_c('div',{staticClass:"flex-field"},[_c('ValidationProvider',{attrs:{"name":_vm.labels['isExistingClient'],"rules":_vm.getRules('isExistingClient'),"vid":'isExistingClient'},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('ButtonGroup3',{attrs:{"error-messages":errors,"options":['Yes', 'No'],"label":_vm.labels['isExistingClient']},model:{value:(_vm.isExistingClient),callback:function ($$v) {_vm.isExistingClient=$$v},expression:"isExistingClient"}})]}}])})],1),_vm._v(" "),_c('div',{staticClass:"flex-field"},[_c('ValidationProvider',{attrs:{"name":_vm.labels['productType'],"rules":_vm.getRules('productType'),"vid":'productType'},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"items":_vm.options['productType'],"error-messages":errors,"color":"secondary","label":_vm.labels['productType']},model:{value:(_vm.resources.loan.attributes.lender.financeOne.productType),callback:function ($$v) {_vm.$set(_vm.resources.loan.attributes.lender.financeOne, "productType", $$v)},expression:"resources.loan.attributes.lender.financeOne.productType"}})]}}])})],1),_vm._v(" "),(_vm.isTargetAssister)?_c('div',{staticClass:"flex-field"},[_c('ValidationProvider',{attrs:{"name":_vm.labels['actual-broker-fee'],"rules":_vm.getRules('actual-broker-fee'),"vid":'actual-broker-fee'},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('NodifiMoney',{attrs:{"errors":errors,"color":"secondary","label":_vm.labels['actual-broker-fee']},model:{value:(_vm.resources.loan.attributes['actual-broker-fee']),callback:function ($$v) {_vm.$set(_vm.resources.loan.attributes, 'actual-broker-fee', $$v)},expression:"resources.loan.attributes['actual-broker-fee']"}})]}}],null,false,4218401539)})],1):_c('div',{staticClass:"flex-field"},[_c('ValidationProvider',{attrs:{"name":_vm.labels['quoted-broker-fee'],"rules":_vm.getRules('quoted-broker-fee'),"vid":'quoted-broker-fee'},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('NodifiMoney',{attrs:{"errors":errors,"color":"secondary","label":_vm.labels['quoted-broker-fee']},model:{value:(_vm.resources.loan.attributes['quoted-broker-fee']),callback:function ($$v) {_vm.$set(_vm.resources.loan.attributes, 'quoted-broker-fee', $$v)},expression:"resources.loan.attributes['quoted-broker-fee']"}})]}}])})],1),_vm._v(" "),_c('div',{staticClass:"flex-field"})]),_vm._v(" "),_c('div',{staticClass:"flex-form"},[_c('div',{staticClass:"flex-field"},[_c('ValidationProvider',{attrs:{"name":_vm.labels['requested-term-months'],"rules":_vm.getRules('requested-term-months'),"vid":'requested-term-months'},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"items":_vm.options['requested-term-months'],"error-messages":errors,"color":"secondary","label":_vm.labels['requested-term-months']},model:{value:(_vm.resources.loan.attributes['requested-term-months']),callback:function ($$v) {_vm.$set(_vm.resources.loan.attributes, 'requested-term-months', $$v)},expression:"resources.loan.attributes['requested-term-months']"}})]}}])})],1),_vm._v(" "),_c('div',{staticClass:"flex-field"},[_c('ValidationProvider',{attrs:{"name":_vm.labels['purchase-price'],"rules":_vm.getRules('purchase-price'),"vid":'purchase-price'},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('NodifiMoney',{attrs:{"errors":errors,"color":"secondary","label":_vm.labels['purchase-price']},model:{value:(_vm.resources.purchase.attributes['purchase-price']),callback:function ($$v) {_vm.$set(_vm.resources.purchase.attributes, 'purchase-price', $$v)},expression:"resources.purchase.attributes['purchase-price']"}})]}}])})],1),_vm._v(" "),_c('div',{staticClass:"flex-field"}),_vm._v(" "),_c('div',{staticClass:"flex-field"})])]),_vm._v(" "),(_vm.showProductTypeError)?_c('v-alert',{attrs:{"dense":"","outlined":"","type":"error"}},[_c('div',[_vm._v("Product Type is required! Please adjust Loan Term or Purchase Price")])]):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }