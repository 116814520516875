
export default async (context) => {

    const { user } = context.store.state['auth-actions'];
    const authUser = Array.isArray(user) ? user[0] : user;
    const isLogin = context.store.state.auth.loggedIn;

    const { groupSlug } = context.store.state.slug;

    let userGroups = null
    

    // let tenant = context.store.getters['slug/getTenant']

    // check if tenant is loanmarket then change login route
    // if(tenant == 'loanmarket'){
    //     context.store.commit('slug/setGroupSlug', 'loanmarket')
    //     context.store.commit('slug/setLoginPath', '/login')
    // } else {
    //     context.store.commit('slug/setLoginPath', `/${tenant}/login`)
    // }



    // DO NOT execute if there is no user (guest) e.g OL clients
    if(!authUser) return;

    // NOTE: Temporary solution NOT to run the following process on these routes.
    let excludedRoutes = [
        'login-salesforce-redirect'
    ]

    if ( excludedRoutes.includes(context.route.name) ) {
        return
    }

    let isSlugIncludedInGroups = false

    // let req = await context.store.dispatch('groups/fetchGroups', {size: 1000})

    if(isLogin) {

        let req = await context.store.dispatch('groups/getUserGroups', { id: authUser?.id, include: 'groups' })

        if ( req.status !== 200 ) {

            message = 'Error requesting User Groups' 

            context.error({
                message,
                statusCode: 400
            })

            return
        
        } else {
            userGroups = req?.data?.included ?? null;
        }


        // Do nothing if there are no groups
        if(!userGroups) return;

        // Do this if user has groups
        userGroups.forEach(data => {
            if(data.attributes.slug == groupSlug){
                isSlugIncludedInGroups = true
            }
        })

        // Do this if user has groups
        if(!isSlugIncludedInGroups) {

            // get the url that user want to go and remove group slug
            let url = context.from.fullPath.split('/')
            url.splice(0, 2)

            // combine temp url
            let tempUrl = ''
            url.forEach(u => {
                tempUrl = tempUrl + '/' + u
            })

            // store temporary url for later use
            context.store.commit('groups/setTempUrl', tempUrl)
            context.store.commit('groups/setSelectGroup', true)

            // redirect to group select page
            if(context.route.name != 'group-select')
             context.redirect('/group-select')

        }
    }

}
