<template>
    <FlexWrap @resize="handleResize">
        <ValidationObserver :data-id="id" :vid="id" v-if="entity" :ref="`validation-observer`">
            <div v-for="(layout, index) in fieldLayout" :key="index">
                <div class="title ma-0 my-2 body_text_color--text" v-if="layout.title">
                    {{layout.title}}
                </div>
                <v-row>
                    <v-col :cols="responsiveComponent" class="py-0 my-0 mx-0" v-for="(field, key) in fieldLayoutMaker(layout, entity.attributes)" :key="key">
                        <ValidationProvider 
                            v-if="visible[key]"
                            v-slot="{ errors }" 
                            :name="labels[key]" 
                            :rules="getCustomRules(key)" 
                            :vid="`${key}`">

                            <ButtonGroup1 
                                v-if="isBtnGroup(key)"
                                v-model="entity.attributes[key]"
                                @input="handleFieldChange"
                                :error-messages="errors"
                                :data-field="`people-${key}`"
                                :options="['Yes', 'No']"
                                :label="labels[key]" />

                            <v-select
                                v-else-if="isFieldType('picklist', key)"
                                :items="getOptions(key)"
                                :label="labels[key]"
                                :error-messages="errors"
                                v-model="entity.attributes[key]"
                                @change="handleFieldChange"
                                :data-field="`people-${key}`"
                                clearable
                                color="secondary">
                                    <template #item="{item, attrs, on}"  >
                                        <v-list-item :data-field="`${key}-item-${item}`" v-bind="attrs" v-on="on">
                                            {{item}}
                                        </v-list-item>
                                    </template>
                            </v-select>

                            <template v-else-if="isFieldType('date', key)">
                                <FlexDatePicker
                                    :label="labels[key]"
                                    :errors="errors"
                                    @change="handleFieldChange"
                                    :data-field="`people-${key}`"
                                    v-model="entity.attributes[key]">
                                </FlexDatePicker>
                            </template>

                            <AbnLookup
                                v-else-if="labels[key] === 'ABN'"
                                v-model="entity.attributes[key]"
                                :errors="errors"
                                @accept="handleAbnData"
                                :label="labels[key]" />

                            <v-text-field 
                                v-else
                                :type="'text'"
                                :label="labels[key]"
                                :data-field="`people-${key}`"
                                :error-messages="errors"
                                @change="handleFieldChange"
                                v-model="entity.attributes[key]"
                                color="secondary">
                            </v-text-field>
                        </ValidationProvider>
                    </v-col>
                </v-row>
            </div>
        </ValidationObserver>
    </FlexWrap>
</template>

<script>
    
    import { mapState, mapActions, mapGetters, mapMutations } from 'vuex'
    import formHelpers from '~/components/shared/form-helpers'
    import veeValidate from '~/components/shared/validation'
    import requirementChecklist from '~/components/shared/requirement-checklist.js'


    import entityData from '~/components/v1/ApplicantEditor1/entity-data'
    import fieldOptions from '~/components/shared/field-options'
    import baseComponents from '~/components/base'

    import { getDateValidations } from '~/plugins/date-helper.js'
    const { maxAgeDate, minAgeDate, minDate } = getDateValidations()

    export default {
        mixins: [formHelpers, veeValidate, requirementChecklist],
        components: {
            ...baseComponents
        },
        props: {
            loanKindId: {
                type: String,
                required: true,
            },
            data: {
                type: Object,
                required: true,
            },
            entityPartKindId: {
                type: String,
                required: true,
            },
            id: {
                type: String,
                default: '',
            },
        },
        data(){
            return {
                options: {
                    "primary-country-of-citizenship": fieldOptions["country-of-citizenship"],
                    "secondary-country-of-citizenship": fieldOptions["country-of-citizenship"]
                },
                sizes: null,
                entity: null,
                errorMessages: {},
                visible: {
                    "first-name": true,
                    "middle-name": true,
                    "last-name": true,
                    "date-of-birth": true,
                    "email": true,
                    "mobile-phone": true,
                    "other-phone": true,
                    "primary-country-of-citizenship": true,
                    "secondary-country-of-citizenship": true,
                    "visa": false,
                    "visa-expiry-date": false,
                    "gender": true,
                    "marital-status": true,
                    "number-of-dependants": true,
                    "age-of-dependants": false,
                    "credit-status": true,
                    "comprehensive-credit-score":true,
                    "previous-bankrupt": true,
                    "bankruptcy-status": false,
                    "bankruptcy-declared-date": false,
                    "bankruptcy-discharge-date": false,


                    "business-legal-name": true,
                    "registered-business-name": true,
                    "nature-of-business": true,
                    "acn-number": true,
                    "abn-number": true,
                    "date-registered-abn": true,
                    "date-registered-gst": true,

                    "drivers-licence-number": true,
                    "drivers-licence-state": true,
                    "drivers-licence-card-number": false,
                    "drivers-licence-expiry": true,

                    "medicare-number": true,
                    "medicare-expiry": true,
                    "medicare-position-on-card": true,
                    "medicare-colour-of-card": true,
                    
                    "passport-number": true,
                    "passport-expiry": false,
                    "passport-country-of-issue": false,
                    "passport-issue-date": false,
                },
                labels: {
                    "first-name": "First Name",
                    "middle-name": "Middle Name",
                    "last-name": "Last Name",
                    "date-of-birth": "Date of Birth",
                    "email": "Email",
                    "mobile-phone": "Mobile Number",
                    "other-phone": "Other Phone",
                    "primary-country-of-citizenship": "Primary Country of Citizenship",
                    "secondary-country-of-citizenship": "Secondary Country of Citizenship",
                    "visa": "Visa",
                    "visa-expiry-date": "Visa Expiry Date",
                    "gender": "Gender",
                    "marital-status": "Marital Status",
                    "number-of-dependants": "Dependants",
                    "age-of-dependants": "Age of Dependants",
                    "credit-status": "Credit Status",
                    "comprehensive-credit-score": "Comprehensive Score Credit",
                    "previous-bankrupt": "Previous Bankrupt",
                    "bankruptcy-status": "Bankruptcy Status",
                    "bankruptcy-declared-date": "Bankruptcy Declared Date",
                    "bankruptcy-discharge-date": "Bankruptcy Discharge Date",

                    "business-legal-name": "Business Legal Name",
                    "registered-business-name": "Registered Business Name",
                    "nature-of-business": "Nature of Business",
                    "acn-number": "ACN",
                    "abn-number": "ABN",
                    "date-registered-abn": "Date Registered ABN",
                    "date-registered-gst": "Date Registered GST",

                    "drivers-licence-number": "Drivers Licence Number",
                    "drivers-licence-state": "Drivers Licence State",
                    "drivers-licence-card-number": "Drivers Licence Card Number",
                    "drivers-licence-expiry": "Drivers Licence Expiry",

                    "medicare-position-on-card": "Position on Card",
                    "medicare-expiry": "Medicare Expiry",
                    "medicare-colour-of-card": "Colour of Card",
                    "medicare-number": "Medicare Number",

                    "passport-country-of-issue": "Country of Issue",
                    "passport-issue-date": "Passport Issue Date",
                    "passport-number": "Passport Number",
                    "passport-expiry": "Passport Expiry",

                },
                validation_rules: {
                    attributes: {
                        "first-name": "required|string",
                        "middle-name": "string",
                        "last-name": "required|string",
                        "date-of-birth": `required|string|date_format:Y/m/d|before:${minAgeDate}|after:${maxAgeDate}`,
                        "email": "required|email",
                        "mobile-phone": "required|numeric|digits_between:10,10",
                        "primary-country-of-citizenship": `required|in:${fieldOptions["country-of-citizenship"]}`,
                        "secondary-country-of-citizenship": `string|in:${fieldOptions["country-of-citizenship"]}`,
                        "visa": `required|in:${fieldOptions["visa"]}`,
                        "visa-expiry-date": "string|date_format:Y/m/d",
                        "gender": `required|in:${fieldOptions["gender"]}`,
                        "marital-status": `required|in:${fieldOptions["marital-status"]}`,
                        "number-of-dependants": 'required|integer',
                        "age-of-dependants": "required|string|max:225",
                        "previous-bankrupt": "required|string|in:Yes,No",
                        "credit-status": `required|in:${fieldOptions["credit-status"]}`,
                        "bankruptcy-status": `required_if:previous-bankrupt,Yes|in:${fieldOptions["bankruptcy-status"]}`,
                        "bankruptcy-declared-date": `required_if:previous-bankrupt,Yes|date_format:Y/m/d|after:${minDate}|before:now`,
                        "bankruptcy-discharge-date": `required_if:bankruptcy-status,Discharged Part 9,Discharged Bankrupt|date_format:Y/m/d|after:${minDate}|before:now`,
                        "comprehensive-credit-score":"required",
                        "business-legal-name": "required|string",
                        "registered-business-name": "required|string",
                        "nature-of-business": "required|string",
                        "acn-number": "required|string",
                        "abn-number": "required|digits_between:11,11",
                        "date-registered-abn": "required|date_format:Y/m/d|before:now",
                        "date-registered-gst": "string|date_format:Y/m/d|before:now",

                        "drivers-licence-number": "required|string",
                        "drivers-licence-state": `required|string|in:${fieldOptions["drivers-licence-state"]}`,
                        "drivers-licence-card-number": "string",
                        "drivers-licence-expiry": "required|string|date_format:Y/m/d|after:now",

                        "medicare-number": "required|numeric|digits_between:10,10",
                        "medicare-position-on-card": "required|integer|max_value:99",
                        "medicare-expiry": "required|string|date_format:Y/m/d|after:now",
                        "medicare-colour-of-card": `required|string|in:${fieldOptions["medicare-colour-of-card"]}`,

                        "passport-number": "required",
                        "passport-country-of-issue": `required|string|in:${fieldOptions["country-of-citizenship"]}`,
                        "passport-issue-date": "required|string|date_format:Y/m/d|before:now",
                        "passport-expiry": "required|string|date_format:Y/m/d|after:now",

                        "other-phone": "numeric|digits_between:10,10",

                    }
                },

            }
        },
        computed: {
            ...mapGetters('kinds', ['kinds', 'kindsUnformat']),
            ...mapGetters('flows', [
                'getCalculation'
            ]),
            isPersonalLoan(){
                return this.getCalculation?.["app-loan-type"] == "Personal Loan"
            },
            fieldLayout(){

                const {entityPartKindId, loanKindId} = this

                let loanKindName = this.getKindNameById(loanKindId)
                let entityKindName = null
                let key = null

                if(entityPartKindId){
                    entityKindName = this.getKindNameById(entityPartKindId)
                }

                Object.keys(entityData).forEach( type => {

                    if( entityData[type]['loan-kinds'].includes(loanKindName) && entityData[type]['entity-kinds'].includes(entityKindName) ) {
                        key = type
                    }

                })
                // console.log(loanKindName)
                // console.log(entityKindName)

                if(key){
                    return entityData[key].layout
                }

                return []
            },
            entityFormType(){

                const {entityPartKindId, loanKindId} = this

                let loanKindName = this.getKindNameById(loanKindId)
                let entityKindName = null
                let key = null

                if(entityPartKindId){
                    entityKindName = this.getKindNameById(entityPartKindId)
                }

                Object.keys(entityData).forEach( type => {

                    if( entityData[type]['loan-kinds'].includes(loanKindName) && entityData[type]['entity-kinds'].includes(entityKindName) ) {
                        key = type
                    }

                })

                return key
            },
            countryOfCitizenship(){
                return this.entity?.attributes["primary-country-of-citizenship"] +'|'+ this.entity?.attributes["secondary-country-of-citizenship"]
            },
            previousBankrupt(){
                return this.entity?.attributes["previous-bankrupt"]
            },
            bankruptcyStatus(){
                return this.entity?.attributes["bankruptcy-status"]
            },
            dependants(){
                return this.entity?.attributes["number-of-dependants"]
            },
            driversLicenceNumber(){
                return this.entity?.attributes["drivers-licence-number"]
            },
            driversLicenceState(){
                return this.entity?.attributes["drivers-licence-state"]
            },
            responsiveComponent(){

                if(!this.sizes) return 12

                const {sm, xs, md, lgAndUp, width} = this.sizes

                let formTypes = [
                    'type4',
                    'type4-1',
                    'type3',
                    'type3-1',
                    'type3-2'
                ]
                
                if(lgAndUp){

                    if ( formTypes.includes(this.entityFormType) ) {
                        return 6
                    } else {
                        return 3
                    }
                }

                if(md){

                    if ( formTypes.includes(this.entityFormType)) {
                        return 6
                    } else {
                        return 3
                    }
                }

                if(sm){
                    return 6
                }

                if(xs){
                    return 12
                }

            },
            mobilePhone() {
                return this.entity?.attributes?.['mobile-phone']
            },
            otherPhone(){
                return this.entity?.attributes?.['other-phone'] 
            },
            medicareNumber() {
                return this.entity?.attributes?.['medicare-number']
            },
            passportNumber() {
                return this.entity?.attributes?.['passport-number']
            },
            driversLicenceFields(){
                return [
                    "drivers-licence-number",
                    "drivers-licence-state",
                    "drivers-licence-card-number",
                    "drivers-licence-expiry"
                ]
            },
            passportFields(){
                return [
                        'passport-number',
                        'passport-country-of-issue',
                        'passport-issue-date',
                        'passport-expiry'
                    ]
            },
            medicareFields(){
                return [
                        'medicare-number',
                        'medicare-position-on-card',
                        'medicare-expiry',
                        'medicare-colour-of-card']
            },
            isDriversLicenceInfoComplete(){

                const {entity} = this

                if(entity) {

                    return this.driversLicenceFields.every( field => {
                        return entity?.attributes[field]
                    })

                }
                
                return false
            },
            isPassportInfoComplete(){

                const {entity} = this

                if(entity) {

                    return this.passportFields.every( field => {
                        return entity?.attributes[field]
                    })

                }
                
                return false
            },
            isMedicareInfoComplete(){

                const {entity} = this

                if(entity) {

                    return this.medicareFields.every( field => {
                        return entity?.attributes[field]
                    })

                }
                
                return false
            },

            ...mapState("privacy-disclosure", 
                {
                    privacyUpdater: state => state.privacyUpdater, 
                    privacyActiveApplicant: state => state.activeApplicant, 
                }),

        },
        mounted() {

            this.init()
            this.handleFieldChange()

        },
        methods: {
            ...mapActions('resource-actions', [
                'getPeople',
                'updatePerson',
                'createPerson'
            ]),
            ...mapMutations('privacy-disclosure', [
                'setActiveApplicant',
            ]),    
            init(){

                // localize data prop to trigger watchers
                this.entity = this.data

            },
            getKindNameById(id){

                let kind = this.kindsUnformat.find( kind => kind.id == id)

                if(kind){
                    return kind.attributes.name
                }

                return null

            },
            getCustomRules(name){

                const defaultRules = this.getRules(name);

                if(name == 'age-of-dependants') {
                    return 'age_dependants:number-of-dependants|' + defaultRules
                } 

                if(name == 'mobile-phone') {
                    return 'is_mobile_number|has:04,+61|' + defaultRules
                } 

                if(name == 'other-phone') {
                    return 'has_other:0|' + defaultRules
                } 

                return defaultRules
            },
            isBtnGroup(key){

                let keys = [
                    "previous-bankrupt"
                ]

                return keys.includes(key)

            },
            handleResize(sizes){
                this.sizes = sizes
            },
            handleAbnData(data) {
                this.entity.attributes['date-registered-abn'] = data['abn-reg']
                this.entity.attributes['date-registered-gst'] = data['gst-reg-date']
                this.entity.attributes['business-legal-name'] = data['abn-name']
                this.entity.attributes['acn-number'] = data['acn-number']
            },
            handleFieldChange(){

                this.updateRequirementChecklistSidebar({
                    name: 'validation-observer', 
                    component: "ApplicantEditor1",
                    entity: this.entity
                })

                this.$emit('change', this.entity)

            },
            fieldsVisibility(fields = [], visible = true){
                fields.forEach(field => {
                    this.visible[field] = visible
                })
            },
            clearFieldValues(fields = []){
                fields.forEach(field => {
                    this.entity.attributes[field] = ""
                })
            },
            removeRequired(fields){

                const {validation_rules} = this

                fields.forEach(field => {
                    if(validation_rules.attributes.hasOwnProperty(field)) {

                        let rules = validation_rules.attributes[field]

                        if(rules.includes('required')){
                            rules = rules.split('|')
                            rules.shift()
                            rules = rules.join('|')
                            this.validation_rules.attributes[field] = rules
                        }

                    }
                })
            },
            addRequired(fields){

                const {validation_rules} = this

                fields.forEach(field => {
                    if(validation_rules.attributes.hasOwnProperty(field)) {

                        let rules = validation_rules.attributes[field]

                        if(!rules.includes('required')){
                            rules = rules.split('|')
                            rules.unshift('required')
                            rules = rules.join('|')
                            this.validation_rules.attributes[field] = rules
                        }
                    }
                })
            },
            requireDriversLicence(){
                this.addRequired(this.driversLicenceFields)
            },
            optionalDriversLicence(){
                this.removeRequired(this.driversLicenceFields)
            },
            requirePassportInfo(){
                this.addRequired(this.passportFields)
            },
            optionalPassportInfo(){
                this.removeRequired(this.passportFields)
            },
            requireMedicareInfo(){
                this.addRequired(this.medicareFields)
            },
            optionalMedicareInfo(){
                this.removeRequired(this.medicareFields)
            },
            passportLogicAssetLoan(value){

                let fields = [
                    "passport-expiry",
                    "passport-country-of-issue",
                    "passport-issue-date"
                ]

                if(value){

                    this.fieldsVisibility(fields, true)

                    if (this.isMedicareInfoComplete) {

                        this.requireMedicareInfo()
                        this.optionalPassportInfo()
                        
                    } else {

                        this.requirePassportInfo()
                        this.optionalMedicareInfo()
                    }

                } else {

                    this.clearFieldValues(fields)
                    this.fieldsVisibility(fields, false)
                    
                    if (this.medicareNumber) {
                        
                            this.requireMedicareInfo()
                            this.optionalPassportInfo()

                    } else {

                        this.requirePassportInfo()
                        this.optionalMedicareInfo()
                        this.addRequired(['medicare-number'])
                    }

                }
            },
            passportLogicPersonalLoan(value){

                let fields = [
                    "passport-expiry",
                    "passport-country-of-issue",
                    "passport-issue-date"
                ]

                if(value){

                    this.fieldsVisibility(fields, true)

                    if (this.isDriversLicenceInfoComplete) {

                        this.requireDriversLicence()
                        this.optionalPassportInfo()
                        
                    } else {

                        this.requirePassportInfo()
                        this.optionalDriversLicence()
                    }

                } else {

                    this.clearFieldValues(fields)
                    this.fieldsVisibility(fields, false)
                    
                    if (this.medicareNumber) {
                        
                            this.requireDriversLicence()
                            this.optionalPassportInfo()

                    } else {

                        this.requirePassportInfo()
                        this.optionalDriversLicence()
                        this.addRequired(['drivers-licence-number'])
                    }

                }
            }
        },
        watch: {
            countryOfCitizenship(val){

                let countries = val.split('|')

                let cond = countries.includes('Australia') || countries.includes('New Zealand')

                if(cond){

                    this.visible["visa"] = false
                    this.visible["visa-expiry-date"] = false

                } else {

                    if(countries[0] == 'null' && countries[1] == 'null'){
                        
                        this.visible["visa"] = false
                        this.visible["visa-expiry-date"] = false

                    } else {

                        this.visible["visa"] = true
                        this.visible["visa-expiry-date"] = true

                    }

                }

            },
            previousBankrupt(val){

                if(val == 'No' || val == null){

                    this.entity.attributes["bankruptcy-status"] = ""
                    this.entity.attributes["bankruptcy-declared-date"] = ""

                    this.visible["bankruptcy-status"] = false
                    this.visible["bankruptcy-declared-date"] = false
                } 

                if(val == 'Yes'){

                    this.visible["bankruptcy-status"] = true
                    this.visible["bankruptcy-declared-date"] = true
                }

            },
            bankruptcyStatus(val){

                if(val && val.includes('Discharged')){

                    this.visible["bankruptcy-discharge-date"] = true

                } else {

                    this.entity.attributes["bankruptcy-discharge-date"] = ""
                    this.visible["bankruptcy-discharge-date"] = false
                }

            },
            dependants(val){

                if(parseInt(val) > 0){

                    this.visible["age-of-dependants"] = true

                } else {
                    
                    this.entity.attributes["age-of-dependants"] = ""
                    this.visible["age-of-dependants"] = false
                }

            },
            driversLicenceState(val){

                if(val) {

                    // no drivers licence card number on this state
                    let excludes = ['Outside Australia' ]

                    if(excludes.includes(val)) {
                        this.entity.attributes["drivers-licence-card-number"] = ""
                        this.visible["drivers-licence-card-number"] = false
                        this.validation_rules.attributes["drivers-licence-card-number"] = `string`
                    } else {
                        this.visible["drivers-licence-card-number"] = true
                        this.validation_rules.attributes["drivers-licence-card-number"] = `required|string`
                    }

                }

            },
            driversLicenceNumber(val) {

                if(!this.isPersonalLoan) return;

                let fields = [
                    "drivers-licence-state",
                    "drivers-licence-card-number",
                    "drivers-licence-expiry"
                ]

                if(val){

                    this.fieldsVisibility(fields, true)

                    if (this.isPassportInfoComplete) {

                        this.requirePassportInfo()
                        this.optionalDriversLicence()

                    } else {

                        this.requireDriversLicence()
                        this.optionalPassportInfo()
                    }

                } else {

                    this.clearFieldValues(fields)
                    this.fieldsVisibility(fields, false)
                    
                    if (this.passportNumber) {

                        this.requirePassportInfo()
                        this.optionalDriversLicence()

                    } else {

                        this.requireDriversLicence()
                        this.optionalPassportInfo()
                        this.addRequired(['passport-number'])

                    }

                }
            },
            passportNumber(val) {

              if(this.isPersonalLoan) {
                this.passportLogicPersonalLoan(val)
              } else {
                this.passportLogicAssetLoan(val)
              }

            },
            medicareNumber(val) {

                if(this.isPersonalLoan) return;

                let fields = [
                    "medicare-position-on-card",
                    "medicare-expiry",
                    "medicare-colour-of-card"
                ]

                if(val){

                    this.fieldsVisibility(fields, true)

                    if (this.isPassportInfoComplete) {

                        this.requirePassportInfo()
                        this.optionalMedicareInfo()

                    } else {

                        this.requireMedicareInfo()
                        this.optionalPassportInfo()
                    }

                } else {

                    this.clearFieldValues(fields)
                    this.fieldsVisibility(fields, false)

                    if (this.passportNumber) {

                        this.requirePassportInfo()
                        this.optionalMedicareInfo()

                    } else {

                        this.requireMedicareInfo()
                        this.optionalPassportInfo()
                        this.addRequired(['passport-number'])

                    }
                    
                }
            },

            privacyUpdater(val){

                const {entity, privacyActiveApplicant} = this
                
                if(entity && entity.id == privacyActiveApplicant.id) {

                    Object.keys(privacyActiveApplicant.attributes).forEach(field => {
                        this.entity.attributes[field] = this.privacyActiveApplicant.attributes[field]
                    })

                    // console.log('privacyActiveApplicant', privacyActiveApplicant.attributes)
                    // console.log('entity', this.entity.attributes)
                }
            }
        }
    }
</script>

<style scoped lang="scss">
    
</style>
