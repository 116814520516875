import moment from 'moment'
import {store, actions} from '~/components/v1/EmploymentHistoryEditor4/__store'
import StepperFieldLayouts from '~/components/v1/EmploymentHistoryEditor4/field-layout'
import kindsOrder from '~/components/v1/EmploymentHistoryEditor4/kinds-order'
import StepperFieldLabels from '~/components/v1/EmploymentHistoryEditor4/field-label'
import resourceHelpers from '~/components/shared/resource-helpers'
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex'
import { has, times } from 'lodash'

export default {
    mixins: [resourceHelpers],
    computed: {
        ...mapGetters('kinds', ['getKindsIncome', 'kinds', 'kindsUnformat']),

        ...mapState({
            development: state => {
                return state.development
            },
            primaryApplicant: state => state.flows.primaryApplicant,
        }),

        ...mapState('flows', {
            appId: state => state.appId
        }),

        getRequiredKindsByName(){

            const refs = [
                    'Applicant',
                    'Director',
                    'Guarantor',
                    'Non-Borrowing Spouse',
                    'Partner',
                    'Trustee']
            
            return this.kindsUnformat.filter( kind => {
                return refs.includes(kind.attributes.name)
            })

        },

        progress() {
            return store.saving
        },

        incomeSituationOptions(){
            return store.incomeSituationOptions
        },
        incomeSituation(){
            return store.preselectIncomeSituation
        },
        kindsIncome(){
            return store.kindsIncome
        },
        addresses(){
            return store.allAddresses
        },
        steps(){
            return store.steps
        },
        appType(){
            return store.appType
        },
        loanType(){
            return store.loanType
        },
        stepper(){
            return store.stepper
        },
        currentStep(){
            return store.currentStep
        },
        incomeLayout(){
            return store.incomeLayout
        },
        incomeFormMaxSize(){
            return store.incomeFormMaxSize
        },
        cardTitle(){
            return store.cardTitle
        },
        noIncome(){
            return store.noIncome
        },
        entities(){
            return store.entities
        },
        selectedEntity(){
            return store.selectedEntity
        },
        entityParts(){
            return store.entityParts
        },
        businesses(){
            return store.resources.businesses
        },
        people(){
            return store.resources.people
        },
        incomes(){
            return store.resources.incomes
        },
        assets(){
            return store.resources.assets
        },
        liabilities(){
            return store.resources.liabilities
        },
        expenses(){
            return store.resources.expenses
        },
        kindsIncomeId() {
            return store.kindsIncomeId
        },
        incomeLabels() {
            return store.incomeLabels
        },
        standalone() {
            return store.standalone
        },
        incomeId() {
            return store.incomeId
        },
        showNext() {
            return store.showNext
        },
        preSelectRoles: {
            get(){
                return store.preSelectRoles
            },
            set(value){
                actions.setPreSelectRoles(value)
            }
        },
        assetHasExpensesLiabilities() {
            return store.assetHasExpensesLiabilities
        },
        incomePrevEndDate() {
            let endDate = null

            if(this.incomes.length > 0) {

                let currentIncome = this.incomes.filter(income => income.attributes['income-situation'] == 'Current')
                // get error first
                let errors = this.validateIncomeRecords(this.selectedEntity, this.incomes)

                if(errors.length === 1) {

                    errors.forEach(error => {
                        if(error.message == 'Applicant must have <strong>3 Years or More</strong> income history.') {
                            if(currentIncome.length > 0) {
                                
                                // minus 1 day to avoid overlap
                                endDate = moment(currentIncome[0].attributes['start-date']).subtract(1, 'days').format('YYYY-MM-DD')

                            }
                        }
                    })

                }
            }

            return endDate
        }
    },
    methods: {
        ...mapActions('resource-actions', [
            'getPeople',
            'getBusinesses',
            'getAddresses',
            'getLoan',
            'deleteIncome'
        ]),
        async updateEntitiesAndParts(){

            let promise1 = this.getPeople({"include" : 'parent-parts,incomes,occupancies,expenses,liabilities,assets'})
            let promise2 = this.getBusinesses({"include" : 'parent-parts,incomes,expenses,liabilities,assets'})
            let promise3 = this.getAddresses()

            const res = await Promise.all([promise1, promise2, promise3]) 

            let people = this.getRequiredPeopleByRoles(res[0].data, res[1].data)
            let businesses = this.getRequiredBusinessesByRoles(res[1].data)

            let requiredResources = this.getRequiredResources([res[1].data, res[0].data])
            
            let parts = this.getRequiredParts([res[0].data, res[1].data])
            let addresses = this.getAllAddresses(res[2].data)

            actions.setPeople(people)
            actions.setBusinesses(businesses)
            actions.setResources(requiredResources)
            actions.setAllAddresses(addresses)

            let entities = [
                ...businesses,
                ...people
            ]

            actions.setEntities(entities)
            actions.setEntityParts(parts)

        },
        hexToRGBA(hex, a = 1){
            return this.$options.filters.hexToRGBA(hex, a)
        },
        isPickBox(key) {

            let keys = [
                "no-income-reason"
            ]

            return keys.includes(key)
        },
        getFieldColWidth(key, size){

            let keys = [
                "no-income-reason",
            ]

            let halfWidthKeys = [
                'employment-job-status',
                'employment-on-probation',
                'job-title',
                'abn',
                'employer-name',
                'employment-contact-person',
                'employment-contact-phone',
                "__employer-address",
                'net-standard-pay',
                'gross-annual-income',
            ]

            let midWidthKeys = [
                'is-allowances',
                'overtime-pay-monthly-net',
                'bonus-monthly-net',
                'commission-monthly-net',
                'work-allowance-net-monthly'
            ]

            let smallWidthKeys = [
                'gross-annual-income-period',
                'frequency',
                '__spacer'
            ]

            // condition for payg
            if(this.kindsIncomeId == '12-28') {
                smallWidthKeys.push('income-period')
                halfWidthKeys.push('start-date')
            }

            // condition for self employed
            if(this.kindsIncomeId == '12-29') {
                halfWidthKeys.push('start-date')
                halfWidthKeys.push('nature-of-business')
                halfWidthKeys.push('business-name')
                halfWidthKeys.push('__business-address')
                halfWidthKeys.push('self-employed-gst-registered')
                halfWidthKeys.push('self-employed-date-registered-gst')
                halfWidthKeys.push('profit-before-tax-annual')
            }

            // condition for no income
            if(this.kindsIncomeId == '12-23') {
                halfWidthKeys.push('start-date')
                halfWidthKeys.push('additional-notes')
            }

            if(keys.includes(key)) 
                return 12
            else {
                if(midWidthKeys.includes(key)) {
                    return 8
                } else if(smallWidthKeys.includes(key)) {
                    return 3
                } else if(halfWidthKeys.includes(key)) {
                    return 5
                } else if(key == 'start-date' && this.kindsIncomeId == '12-28') {

                    return 12

                } else if( size === 1 ){

                    return  12
    
                } else {
                    
                    return 6
    
                }

            }
        },

        isDisable(key) {

            let keys = [
                'frequency'
            ]

            return keys.includes(key)

        },

        isAddressField(field){
            let fields = [
                '__full-address',
            ]

            return fields.includes(field)
        },
        
        isAddressField3(key){
            let fields = [
                '__employer-address',
                '__business-address',
                '__rental-address'
            ]

            return fields.includes(key)
        },

        isBtnGroup(key){

            let keys = [
                "employment-on-probation",
                "self-employed-gst-registered",
                "covered-by-insurance",
                "being-refinanced",
                "being-sold-traded",
                "to-be-paid-out",
                "employment-job-status",
            ]

            return keys.includes(key)

        },

        convertDateDiff(start, end){

            let endDate = null
            let startDate = moment(start)

            if(end) {
                endDate = moment(end)
            } else {
                endDate = moment()
            }

            let diffDuration = moment.duration(endDate.diff(startDate))

            if(diffDuration.years() === 0) {
                return `${diffDuration.months() > 0 ? diffDuration.months() > 1 ? diffDuration.months() + ' months' : diffDuration.months() + ' month' : ''}`
            } else if(diffDuration.years() > 0) {
                return `${diffDuration.years()} ${diffDuration.years() > 1 ? 'years' : 'year'} ${diffDuration.months() > 0 ? diffDuration.months() > 1 ? diffDuration.months() + ' months' : diffDuration.months() + ' month' : ''}`
            } else {
                return "-"
            }

        },

        getMinDate(key){

            let min = undefined

            if(key == 'end-date'){

                let start = this.resources.incomes.attributes['start-date']

                if(start){

                    let newStart = moment(start, "YYYY-MM-DD").add(1, 'days').format("YYYY-MM-DD")

                    min = newStart
                }

            }

            return min

        },

        getMaxDate(key){

            let max = undefined

            if(key == 'start-date'){

                let end = this.resources.incomes.attributes['end-date']

                if(end && this.visible['end-date']){

                    let newEnd = moment(end, "YYYY-MM-DD").subtract(1, 'days').format("YYYY-MM-DD")

                    max = newEnd
                }

            }

            return max

        },

        async getIncomeFieldLayout(child) {

            let keys = Object.keys(StepperFieldLayouts)
    
            let layout = {}
            let labels = {}

            for (let i = 0; i < keys.length; i++) {
    
                const key = keys[i]
                const items = key.split('|')
    
                if(items.includes(child.id)) {
                    layout = StepperFieldLayouts[key]

                    // set labels
                    if(StepperFieldLabels.incomes[key]) {
                        labels = StepperFieldLabels.incomes[key]
                    } else {
                        labels = StepperFieldLabels.incomes['default']
                    }

                }
                
            }
    
            if(Object.keys(layout).length == 0){
                console.log('Uh oh!, check field layout if configured correctly.')
            }

            if(this.preselectIncomeSituation == 'Current' || this.preselectIncomeSituation == 'Secondary') this.visible['end-date'] = false

            if(layout.steps.length > 0) {

                if(this.preselectIncomeSituation == 'Previous' && child.id == '12-28') {
                    layout.previousSteps.forEach( async element => {
                        await actions.pushStep(element)
                    })
                } else {
                    layout.steps.forEach( async element => {
                        await actions.pushStep(element)
                    })
                }
                
            }

            actions.setIncomeLabels(labels)
            actions.setIncomeFormMaxSize(layout?.['max-size'])
            actions.setIncomeLayout(layout.steps)
            actions.setCardTitle(layout?.title ? layout.title : "No Income")
        },

        constructNames(entity) {

            let name = ''

            entity.forEach((element, index) => {

                if(index === entity.length - 1) {
                    name = name + 'and '
                }
                
                if(element.attributes['first-name']) {

                    name = name + element.attributes['first-name'] ? (element.attributes['first-name'] + ' ') : 'Person '

                } else {
                    name = name + 'Person '
                }

            })

            return name
            
        },

        initialization(){
            // update income form
            if(this.standalone) {
                actions.setSteps([])
                actions.setIncomeKindId(this.incomeId)
                actions.setCurrentStep([])
                this.initializeIncomesLogic({id: this.incomeId})
                this.getIncomeFieldLayout({id: this.incomeId})
            } else {
                let entityKind = this.getEntityRoles(this.selectedEntity, this.entityParts)

                if(this.incomeSituation) {
                    this.resources.incomes.attributes['income-situation'] = this.incomeSituation
                    actions.setIncomeSituation()
                }

                /**
                 * Business Income Logic
                 */
                if(this.selectedEntity && this.selectedEntity.type == 'businesses'){
                    this.setupBusinessIncomeForm()
                }

                /**
                 * People Income Logic
                 * loan type Commercial
                 * Not director
                 */
                if(this.selectedEntity && this.selectedEntity.type == 'people' && store.loanType == 'Commercial' && !entityKind.includes('Director')) {
                    this.filterKindIncome()
                }

                // check for current income
                let hasCurrent = false

                let incomes = this.incomes.filter(income => {
                    let entities = income.relationships[this.selectedEntity.type].data.filter(e => e.id == this.selectedEntity.id)
                    return entities.length > 0
                })

                incomes.forEach(record => {
                    if(record.attributes['income-situation'] == 'Current'){
                        hasCurrent = true
                    }
                })

                if(hasCurrent){

                    if(this.incomeSituation == 'Previous') {
                        actions.setIncomeSituationOptions(['Previous'])
                    } else {
                        actions.setIncomeSituationOptions(['Secondary'])
                    }

                } else {
                    
                    /**
                     * No entity means All tab is selected
                     */
                    if(!this.selectedEntity){
                    
                        actions.setIncomeSituationOptions(['Current', 'Secondary'])
                    
                    } else {

                        actions.setIncomeSituationOptions(['Current'])
                    }

                }

                /**
                 * Company Director Logic
                 */

                if(entityKind && entityKind.includes('Director')){
                    if(!hasCurrent){
                        this.setupCompanyDirectorForm()
                    } 
                    else {
                        this.filterKindIncome()
                    }
                }

                /**
                 * Sole Trader - Self-Employed 12-29
                 */

                // if(store.appType == "Sole Trader"){

                //     if(!hasCurrent){
                //         this.setupSelfEmployedForm()
                //     }
                // }

                // no income selected
                if(this.noIncome) {
                    this.setupNoIncomeForm()
                }

            }

            
        },

        setupNoIncomeForm(){

            actions.setPreselectIncomeSituation('Current')

            let noIncome = this.kindsIncome.children.find(income => {
                return income.id == '12-23'  // No Income 12-23
            })

            if(noIncome){
                actions.setCurrentStep([])
                actions.setIncomeKindId('12-23')

                setTimeout( () => {
                    actions.setSteps([])
                    this.initializeIncomesLogic(noIncome)
                    this.getIncomeFieldLayout(noIncome)
                }, 1)

            }

        },

        setupBusinessIncomeForm(){

            actions.setPreselectIncomeSituation('Current')

            let businessIncome = this.kindsIncome.children.find(income => {
                return income.id == '12-30'  // Business Income 12-30
            })

            if(businessIncome){
                actions.setCurrentStep([])
                actions.setIncomeKindId('12-30')

                setTimeout( () => {
                    actions.setSteps([])
                    this.initializeIncomesLogic(businessIncome)
                    this.getIncomeFieldLayout(businessIncome)
                }, 1)

            }

        },

        setupCompanyDirectorForm(){

            actions.setPreselectIncomeSituation('Current')

            let companyDirector = this.getKindsIncome.children.find(income => {
                return income.id == '12-16'  // Company Director 12-16
            })

            if(companyDirector){
                actions.setCurrentStep([])
                actions.setIncomeKindId('12-16')

                setTimeout( () => {
                    actions.setSteps([])
                    this.initializeIncomesLogic(companyDirector)
                    this.getIncomeFieldLayout(companyDirector)
                }, 1)

            }

        },

        filterKindIncome() {
            // exclude company director
            this.kindsIncome.children = this.kindsIncome.children.filter(child => child.id != '12-16' && child.id != '12-30')
            setTimeout( () => {
                actions.setSteps(['income-situation', this.kindsIncome])
            }, 1)
        },

        /**
             * filter parent
             */
        filterParents() {
            let parent = []

            this.entities.forEach(entity => {
                if (entity.relationships['child-parts']) {
                    entity.relationships['child-parts'].data.forEach(child => {
                        if(child.id == this.selectedEntity.relationships['parent-parts'].data[0].id) {
                            parent.push(entity)
                        }
                    })
                }
            })

            return parent
        },

        initializeIncomesLogic(child) {

            const { selectedEntity } = this

            /**
             * Record specific logic
             */

            // No Income
            if(child && child.id == "12-23"){
                this.validation_rules.attributes["income-situation"] = "required|in:Current,Previous"
            }

            // Other Benefits
            if (child && child.id == "12-11") {
                this.validation_rules.attributes['other-benefit-description'] = 'string|max:32768'
            }

            // Rental Income
            if (child && child.id == "12-21") {
                this.resources.incomes.attributes['__rental-address'] = this.getFullAddress(this.selectedAddress)
            }

            // Company Director
            if (child && child.id == "12-16") {

                this.resources.incomes.attributes['income-situation'] = 'Current'

                if(selectedEntity){

                    let businessName = 'Business'

                    // Get Business Name
                    this.entityParts.forEach(parts => {


                        if(parts.id == selectedEntity.relationships['parent-parts'].data[0].id){

                            this.entities.forEach(entity => {

                                if(parts.relationships.parent.data.id == entity.id){
                                    businessName = entity.attributes['business-legal-name'] ? entity.attributes['business-legal-name'] : 'Business'
                                }

                            })
                            
                        }

                    })

                    let currentBusinessName = this.resources.incomes.attributes['business-name']

                    this.resources.incomes.attributes['business-name'] = currentBusinessName || businessName
                }

                this.readonly["income-period"] = true
                this.resources.incomes.attributes["employment-job-status"] = "Self-Employed - Company"

            }

            // PAYG Employment
            if (child && child.id == "12-28") {

                this.EmployedOptions = [
                    {
                        name: "Full-Time",
                        value: "Full-Time"
                    },
                    {
                        name: "Part-Time",
                        value: "Part-Time"
                    },
                    {
                        name: "Casual",
                        value: "Casual"
                    },
                    {
                        name: "Contract",
                        value: "Contract"
                    }
                ]

                this.validation_rules.attributes["employment-job-status"] = "required|in:Full-Time,Part-Time,Casual,Contract"; 
                this.resources.incomes.attributes['__employer-address'] = this.getFullAddress(this.selectedAddress)
                this.validation_rules.attributes['abn'] = 'numeric|digits_between:11,11'

                this.hidden["employment-job-status"] = false
            }

            // Self-Employed
            if (child && child.id == "12-29") {

                this.EmployedOptions = [
                    {
                        name: "Sole Trader",
                        value: "Self-Employed - Sole-Trader"
                    },
                    {
                        name: "Company",
                        value: "Self-Employed - Company"
                    },
                    {
                        name: "Other",
                        value: "Self-Employed - Other"
                    }
                ]

                this.validation_rules.attributes["employment-job-status"] = "required|in:Self-Employed - Sole-Trader,Self-Employed - Company,Self-Employed - Other"; 
                this.resources.incomes.attributes['__business-address'] = this.getFullAddress(this.selectedAddress)
                this.resources.incomes.attributes['net-monthly-amount'] = 0

                this.hidden["employment-job-status"] = false

                // if (this.loanType == 'Commercial') {

                //     this.resources.incomes.attributes['income-period'] = "Annual"
                    
                //     this.readonly["income-period"] = true

                //     // if role equal to individual trustee or partner
                //     if(this.selectedEntity.relationships.kind.data.id == '4-2' && this.selectedEntity.roles[0] == 'Partner' || this.selectedEntity.roles[0] == 'Trustee') {

                //         this.visible["income-verification-method"] = false
                //         this.visible["income-period"] = false
                //         this.visible["reporting-period"] = false
                //         this.visible["depreciation"] = false
                //         this.visible["revenue"] = false
                //         this.visible["profit-before-tax-annual"] = false

                //         this.visible["gross-annual-income"] = true
                //         this.visible["net-monthly-amount"] = true

                //         this.resources.incomes.attributes['employment-job-status'] = 'Self-Employed - Other'

                //         this.validation_rules['gross-annual-income'] = 'between:0,99999999.99'
                //         this.resources.incomes.attributes['gross-annual-income'] = 0
                //         this.validation_rules['net-monthly-amount'] = 'between:0,99999999.99'

                //         // get parent of selected entity
                //         let parentEntity = this.filterParents()

                //         // prefill some fields
                //         if(parentEntity.length > 0) {

                //             let addressId = null

                //             parentEntity.forEach(parent => {

                //                 this.resources.incomes.attributes['business-name'] = parent.attributes['business-legal-name']
                //                 this.resources.incomes.attributes['abn'] = parent.attributes['abn-number']
                //                 this.resources.incomes.attributes['start-date'] = parent.attributes['date-registered-abn']
                //                 this.resources.incomes.attributes['nature-of-business'] = parent.attributes['nature-of-business']

                //                 if (parent.attributes['date-registered-gst']) {
                //                     this.resources.incomes.attributes['self-employed-gst-registered'] = 'Yes'
                //                     this.resources.incomes.attributes['self-employed-date-registered-gst'] = parent.attributes['date-registered-gst']
                //                 } else {
                //                     this.resources.incomes.attributes['self-employed-gst-registered'] = 'No'
                //                 }

                //                 // get address id if exist in parent
                //                 if(parent.relationships?.addresses && parent.relationships.addresses.data.length > 0) {
                //                     addressId = parent.relationships.addresses.data[0].id
                //                 }

                //             })

                //             // if address exist populate fields to address tool
                //             if (addressId) {
                //                 this.addresses.forEach(address => {

                //                     if(address.id == addressId){
                //                         this.selectedAddress = address
                //                     }

                //                 })
                //             }

                //         }

                //     }

                // } else {

                //     this.visible["income-verification-method"] = false
                //     this.visible["income-period"] = false
                //     this.visible["reporting-period"] = false
                //     this.visible["depreciation"] = false
                //     this.visible["revenue"] = false
                //     this.visible["profit-before-tax-annual"] = false

                // }

            }

            // Business Income
            if (child && child.id == "12-30") {
                this.hidden["income-situation"] = true
                this.hidden["net-monthly-amount"] = true
                this.resources.incomes.attributes['net-monthly-amount'] = 0
            }


        },

        excludeInvalidAttributes(attributes) {

            let obj = {}

            Object.keys(attributes).forEach(attr => {
                if(attributes[attr]) {
                    obj[attr] = attributes[attr]
                }
            })

            return obj
        },

        /**
         * Show / Remove Completely
         */
        isVisible(key){
                
            const {visible} = this

            if(visible.hasOwnProperty(key)){
                return visible[key]
            }

            return true

        },
        /**
         * Hidden only
         */
        isHidden(key){
                
            const {hidden} = this

            if(hidden.hasOwnProperty(key)){
                return hidden[key]
            }

            return false

        },
        /**
         * Read only
         */
        isReadOnly(key){
            
            const {readonly} = this

            if(readonly.hasOwnProperty(key)){
                return readonly[key]
            }

            return false

        },

        getRequiredPeopleByRoles(data, business = {}){

            if(!data.included){
                return [];
            }

            let parts = data.included.filter(part => {
                return part.type == 'parts'
            })

            let roleIds = [];	
            let rolesObj = {}
            
            parts.forEach(part => {
                
                if (!part.relationships.kind.data) {	
                    // person must have part with kind
                    return []
                }
                
                this.getRequiredKindsByName.forEach(kind => {

                    if(kind.id == part.relationships.kind.data.id) {

                        roleIds.push(part.id);
                        rolesObj[part.id] = kind.attributes.name

                    }

                })


            })

            if(roleIds.length == 0) {
                return []
            } 

            let people = [] 
            
            data.data.forEach( person => {

                let parentParts = person.relationships['parent-parts'].data

                person.roles = []
                
                if( parentParts.length ){

                    let included = false;

                    parentParts.forEach( part => {

                        // combine required parts
                        let parts = [...data.included]

                        if( business.included ){
                            parts =  [...business.included, ...data.included]
                        }                    
                            
                        // check parent parts is not empty until to the primary applicant
                        let parentPartsFiltered = this.filterParent(part.id, business, parts)

                        if(roleIds.includes(part.id) ) {

                            // Add extra data to person
                            person.roles.push(rolesObj[part.id])

                            // Include person once
                            if(!included && parentPartsFiltered){
                                people.push(person)
                                included = true
                            }

                        }

                    })

                }

            })

            return people 

        },
        getPeopleRelationship(type = ''){
            const people = {
                data: [],
                // Default Strategy: attach
                meta: {
                    strategy: 'replace'  
                }
            }
            
            // for multiple role
            // this.activeRoles.forEach(role => {

            //     let person = this.people.find( p => p.id == role )

            //     if(person){
            //         people.data.push({
            //             type: 'people',
            //             id: person.id
            //         })
            //     }
            // })

            if(type != 'incomes') {

                this.preSelectRoles.forEach(role => {

                    let person = []
                    
                    if(role == 'all') {
                        person = this.people

                        if(person.length > 0) {
                            person.forEach(p => {
                                people.data.push({
                                    type: 'people',
                                    id: p.id
                                })
                            })
                        }
                    } else {
                        person = this.people.find( p => p.id == role )

                        if(person){
                            people.data.push({
                                type: 'people',
                                id: person.id
                            })
                        }

                    }
                })
                
            } else {
                let person = this.people.find( p => p.id == this.selectedEntity.id )

                if(person){
                    people.data.push({
                        type: 'people',
                        id: person.id
                    })
                }
            }

            return people
        },

        getBusinessesRelationship(type = ''){
            const businesses = {
                data: [],
                // Default Strategy: attach
                meta: {
                    strategy: 'replace'  
                }
            }

            // for multiple role
            // this.activeRoles.forEach(role => {

            //     let business = this.businesses.find( b => b.id == role )

            //     if(business){
            //         businesses.data.push({
            //             type: 'businesses',
            //             id: business.id
            //         })
            //     }

            // })

            if(type != 'incomes') {
                
                this.preSelectRoles.forEach(role => {

                    let business = this.businesses.find( b => b.id == role )

                    if(business){
                        businesses.data.push({
                            type: 'businesses',
                            id: business.id
                        })
                    }
                })
                
            } else {
                let business = this.businesses.find( b => b.id == this.selectedEntity.id )

                if(business){
                    businesses.data.push({
                        type: 'businesses',
                        id: business.id
                    })
                }
            }

            return businesses
        },

        getRequiredBusinessesByRoles(data){

            if(!data.included){
                return [];
            }

            let parts = data.included.filter(part => {
                return part.type == 'parts'
            })

            let roleIds = [];	
            let rolesObj = {}
            
            parts.forEach(part => {
                
                if (!part.relationships.kind.data) {	
                    // person must have part with kind
                    return []
                }
                
                this.getRequiredKindsByName.forEach(kind => {

                    if(kind.id == part.relationships.kind.data.id) {

                        roleIds.push(part.id);
                        rolesObj[part.id] = kind.attributes.name

                    }

                })


            })

            if(roleIds.length == 0) {
                return []
            } 

            let people = [] 
            
            data.data.forEach( person => {

                let parentParts = person.relationships['parent-parts'].data

                person.roles = []
                
                if( parentParts.length ){

                    let included = false;

                    parentParts.forEach( part => {

                        if(roleIds.includes(part.id) ) {

                            // check parent parts is not empty until to the primary applicant
                            let parentPartsFiltered = this.filterParent(part.id, data, data.included)

                            // Add extra data to person
                            person.roles.push(rolesObj[part.id])

                            // exclude role base on kind and parts
                            let isNotExcluded = false

                            let __exclude = [
                                {part: 'Trustee', kind: '5-2'}
                            ]

                            if(data.data) {
                                data.data.forEach(role => {
                                    __exclude.forEach(el => {
                                        if(role.relationships.kind.data.id == el.kind && rolesObj[part.id] == el.part) {
                                            isNotExcluded = true
                                        }
                                    })
                                })
                            }

                            // Include person once
                            if(!included && parentPartsFiltered && !isNotExcluded){
                                people.push(person)
                                included = true
                            }

                        }

                    })

                }

            })

            return people 

        },

        buildDeleteMsg(data){

            let recordKind = this.getKindName(data.relationships.kind.data?.id)

            let roles = [
                ...data.relationships.people.data,
                ...data.relationships.businesses.data
            ]

            // ${resource}

            let msg = `Delete ${recordKind} for `

            roles.forEach((role, index) => {

                this.entities.forEach(entity => {

                    if(entity.id == role.id){

                        let binder = ', '

                        if(index == 0) {
                            binder = ''
                        }

                        if(index == (roles.length - 1) && roles.length != 1) {
                            binder = ' and'
                        }

                        let name = `${binder} ${this.getEntityFriendlyName(entity)}`

                        msg += name

                    }

                })

            })

            return `${msg}?`
        },

        toSingular(payload){
            return this.$options.filters.toSingularResource(payload)
        },

        capitalize(payload){
            return this.$options.filters.capitalize(payload)
        },

        getRequiredParts(data = []){

            let parts = []

            data.forEach(item => {

                if(item.included){

                    let entityParts = item.included.filter(part => {
                        return part.type == 'parts'
                    })

                    parts = [
                        ...parts,
                        ...entityParts
                    ]

                }

            })

            return parts

        },
        getAllAddresses(data){

            // Filter addresses by financial objects

            if(data.data.length == 0){
                return [];
            }

            return data.data
        },
        getAllOccupancies(data){

            let occupancies = []

            // Filter occupancies by financial objects

            if(data.hasOwnProperty('included')){

                data.included.forEach(item => {

                    if(item.type == 'occupancies'){
                        occupancies.push(item)
                    }

                })

            }

            return occupancies
        },

        getRequiredResources(data = []){

            // Filter financial objects

            let fos = {
                incomes: [],
                assets: [],
                expenses: [],
                liabilities: [],
            }

            data.forEach(collection => {

                if(!collection.included){
                    return fos;
                }

                let keys = Object.keys(fos)

                collection.included.forEach( item => {

                    if( keys.includes(item.type) ){

                        fos[item.type].push(item)

                    }
                })

            })

            return fos
        },

        getKindName(id){

            let kind = this.kindsUnformat.find(k => {
                return id == k.id
            })
            
            if(kind){
                return kind.attributes.name
            }

            return ''
        },

        async filterParent(partId = '', businesses = {}, parts = []) {

            let parent = {}
            let kind = {}

            // filter parent
            if (parts.length > 0) {
                parts.forEach(part => {
                    // get parent
                    if (partId == part.id && part.relationships?.parent?.data) {
                        parent = part.relationships.parent.data
                    }
                    // get kind
                    if (partId == part.id && part.relationships?.kind?.data) {
                        kind = part.relationships.kind.data
                    }
                })

            }

            // check if parent has parent parts
            // if has parent parts recursive until to the primary applicant
            if(Object.keys(parent).length > 0) {

                let hasParentParts = false
                let part_id = ''

                businesses.data.forEach(business => {

                    if (business.id == parent.id && business.relationships['parent-parts'].data.length > 0) {
                        hasParentParts = true
                        part_id = business.relationships['parent-parts'].data[0].id
                    }

                })

                // if has parent parts and primary applicant id is not equal to parent
                if (hasParentParts && this.primaryApplicant.type != 'loans')
                    return await setTimeout(() => {this.filterParent(part_id, businesses, parts)}, 100) 

            }
            
            // return if parent is the primary applicant or kind id = 3-6 / non-borrowing spouse
            if (parent.type == 'loans' || kind.id == '3-6') {
                return true
            } else 
                return false
            
        },

        /**
         * 
         *  Record Level Validation
         * 
         * 
         */

        validateIncomeRecords(entity, records){

            // let fullname = this.getFriendlyName(entity)
            let roles = this.getEntityRoles(entity, this.entityParts)
            let role = this.getRoles(entity)

            // Build requirment data for validation
            let requirements = {}

            // Reset error messages
            let errorMessages = []

            let __exclude = [
                'Beneficial Owner'
            ]

            let skip = false

            __exclude.forEach( role => {
                if( roles.length == 1 &&  roles.includes(role)){
                    skip = true
                }
            })

            // default all to 1 (pass validation), then set any who fail validation to 0

            if(!skip){
                
                if(entity.type == 'people'){

                    requirements = {
                        has_three_year_history: 1, 
                        has_no_history_gaps: 1, 
                        dates_are_complete: 1, 
                        no_dates_overlap: 1, 
                        dates_are_chronological: 1, 
                        has_exactly_one_current_income: 1, 
                        current_is_most_recent: 1, 
                    }

                } else {

                    let incomes = entity.relationships?.incomes?.data

                    requirements = {
                        has_income: (incomes && incomes.length) ? true : false,
                    }
                }

            }

            // get incomes for specific user
            const filteredIncomes = this.getIncomesByEntityId(records, entity)

            let dates = []

            filteredIncomes.forEach(income => {

                if(income.attributes['income-situation'] != 'Secondary'){
                    dates.push({
                        "primary": income.attributes['income-situation'],
                        "start-date": this.parseDate(income.attributes['start-date']),
                        "end-date": this.parseDate(income.attributes['end-date']),
                    })
                }

            })

            // Sort date by start-date old to new
            dates.sort((a,b) => {
                return a['start-date'] - b['start-date'];
            });
            
            let totalDays = 0;
            let prevObject;

            let isBusiness = entity.type == 'businesses'

            if(isBusiness){

                if ( !requirements.has_income ) {

                    //fail	

                    errorMessages.push({
                        owner: entity, 
                        tab: 'incomes', 
                        required: "", 
                        message: `${role} must have 1 current income.`
                    })
        
                }
                
                return errorMessages;

            }

            let currentAddressOnly = false
            let currentMostRecentAnd3YearsOrMoreOnly = false

            let __currentAddressOnly = [
                'Non-Borrowing Spouse',
                'Guarantor'
            ]
            let __currentMostRecentAnd3YearsOrMoreOnly = [
                'Applicant'
            ]

            roles.forEach( role => {

                if(__currentAddressOnly.includes(role)){
                    currentAddressOnly = true
                }

                if(__currentMostRecentAnd3YearsOrMoreOnly.includes(role)){
                    currentMostRecentAnd3YearsOrMoreOnly = true
                }

            })

            if( this.isValid(requirements) ){

                /**
                 *  1. Applicant must have 1 (and only 1) current address.
                 */

                if (!this.hasOnlyOneCurrent(dates) && !isBusiness) {
                    
                    //fail	

                    if(!currentMostRecentAnd3YearsOrMoreOnly){
                    
                        requirements.has_exactly_one_current_income = 0; 

                        errorMessages.push({
                            owner: entity, 
                            tab: 'incomes', 
                            required: "", 
                            message: `${role} must have 1 current income.`
                        })

                    }

                }
                
                // Stop process if current address only
                if(currentAddressOnly) return errorMessages;
        

                /**
                 * 2. Current address must be the most recent.
                 */

                if (!this.currentIsMostRecent(dates)) {
                    
                    //fail

                    if(!isBusiness){
                        
                        requirements.current_is_most_recent = 0; 

                        errorMessages.push({
                            owner: entity, 
                            tab: 'incomes', 
                            required: "", 
                            message: `${role} current income must be the most recent.`
                        })

                    }
                }
            

                /**
                 * 3. Further more validation
                 */

                for(let i=0; i < dates.length; i++) {
                        
                    let date = dates[i];
                    
                    // check if primary address
                            
                    if (date['primary'] == 'Current') {
                        
                        //is current (& most recent) address (no input for['end-date']), we auto-set this to today (needed for days at address calculation)
                        
                        date['end-date'] = new Date();
        
                    } else {
                        
                        // is not the current (primary) address, needs both dates
                        
                        if (date['end-date'] == null && !isBusiness) {
                            
                            // fail validation
                            requirements.dates_are_complete = 0; 

                            errorMessages.push({
                                owner: entity, 
                                tab: 'incomes', 
                                required: "", 
                                message: `${role} must have complete <strong>Start</strong> and <strong>End Dates</strong>.`
                            })

                            break;	
                        }
                        
                    }
                    
                    // check to see that this address starts the day of (or day after) the last dates end date (can't allow gaps between dates)
                    
                    let overlappingDays = 0;
                    
                    if (prevObject) {
                        
                        let days = this.getDays(prevObject['end-date'], date['start-date']);
                        
                        // console.log('compare days: '+days);
                        
                        if (days == null) {
                            
                            
                            // fail validation
                            requirements.dates_are_complete = 0; 

                            errorMessages.push({
                                owner: entity, 
                                tab: 'incomes', 
                                required: "", 
                                message: `${role} must have complete <strong>Start</strong> and <strong>End Dates</strong>.`
                            })

                            break;	
                            
                        } else if (days > 1) {
                            
                            // fail validation
                            requirements.has_no_history_gaps = 0; 

                            errorMessages.push({
                                owner: entity, 
                                tab: 'incomes', 
                                required: "", 
                                message: `${role} income history have gaps. Please check <strong>Start</strong> and <strong>End Dates</strong>.`
                            })

                            break;	
                            
                        } else if (days < 0) {
                            
                            // fail validation
                            requirements.no_dates_overlap = 0; 

                            errorMessages.push({
                                owner: entity, 
                                tab: 'incomes', 
                                required: "", 
                                message: `${role} dates between incomes can't overlap. Please check <strong>Start</strong> and <strong>End Dates</strong>.`
                            })

                            break;		
                            
                        } else if (days == 0) {
                            
                            // previous object end date is same as this object start date - don't count the day twice when getting total days
                            
                            overlappingDays = 1;
                            
                            /**
                             * NOTE: Allowed to overlap 1 day
                             * 
                             * uncomment the code below if overlap must be 0
                             *  
                             */

                            // fail validation
                            // requirements.no_dates_overlap = 0; 

                            errorMessages.push({
                                owner: entity, 
                                tab: 'incomes', 
                                required: "", 
                                message: `${role} dates between dates can't overlap. Please check <strong>Start</strong> and <strong>End Dates</strong>`
                            })

                            // break;

                        }
                        
                    }

                    
                    // get days
                    
                    let days = this.getDays(date['start-date'], date['end-date']);
                    
                    // per-address validation
                    
                    if (days != null) {
                    
                        if (days < 0) {
                            
                            // fail validation
                            requirements.dates_are_chronological = 0; 

                            errorMessages.push({
                                owner: entity, 
                                tab: 'incomes', 
                                required: "", 
                                message: `${role} dates must be <strong>Chronological</strong>.`
                            })

                            break;	
                            
                        } else {
                            
                            days -= overlappingDays; // remove any overlapping days before adding to total
                            
                            totalDays += days;
                            
                        }
                        
                    }
                    
                    //set this as previous object for next iteration
                    
                    prevObject = dates[i];
                    
                    // console.log(date.primary+' : '+date['start-date']+' - '+date['end-date']+'. days : '+days);
                }
            
                /**
                 * 4. Count 3 year history validation
                 * 
                 */
            
                if (totalDays < (365*3) && !isBusiness) {
                        
                    // fail validation
                    requirements.has_three_year_history = 0; 

                    errorMessages.push({
                        owner: entity, 
                        tab: 'incomes', 
                        required: "", 
                        message: `${role} must have <strong>3 Years or More</strong> income history.`
                    })
                        
                }
            }

            // validation for occupancy type
            // if (!isBusiness) {
                    
            //     const filteredAssets = this.getAssetsByEntityId(records, entity)
            //     const filteredOccupancy = this.getOccupancyByEntityId(this.occupancies, entity)

            //     let hasRequiredAsset = false

            //     // check if occupancy == Mortgage - Owner Occupied or Own Outright and occupancy-situation = current
            //     filteredOccupancy.forEach( occupancy => {

            //         if(occupancy.attributes['residential-situation'] == 'Mortgage - Owner Occupied' || occupancy.attributes['residential-situation'] == 'Own Outright') {
                        
            //             if (occupancy.attributes['occupancy-situation'] == 'Current') {
            //                 hasRequiredAsset = true
            //             }

            //         }

            //     })

            //     if(hasRequiredAsset) {

            //         if(filteredAssets.length > 0) {

            //             let requiredAsset = false

            //             // check if asset is == Owner Occupied 10-16
            //             filteredAssets.forEach( asset => {

            //                 if (asset.relationships.kind.data.id == '10-16') requiredAsset = true

            //             })

            //             // 
            //             if (!requiredAsset) {

            //                 errorMessages.push({
            //                     owner: entity, 
            //                     tab: 'assets', 
            //                     required: "", 
            //                     message: `${role} must have Owner Occupied Asset`
            //                 })

            //             }

            //         } else {

            //             errorMessages.push({
            //                 owner: entity, 
            //                 tab: 'assets', 
            //                 required: "", 
            //                 message: `${role} must have Owner Occupied Asset`
            //             })

            //         }

            //     }
                
            // }

            // console.log(errorMessages)

            // if(this.loanType == 'Consumer'){

            //     if(records !== []){

            //         let expenses = []

            //         records.forEach(data => {
            //             if(data.type == 'expenses'){
            //                 expenses.push(data)
            //             }
            //         });

            //         let hasLivingExpenses = expenses.find(data => data.relationships?.kind?.data?.id  == '11-16')
                    
            //         if(!hasLivingExpenses){
            //             errorMessages.push({
            //                 owner: entity, 
            //                 tab: 'expenses', 
            //                 required: "", 
            //                 message: `${role} must have Living Expenses`
            //             })
    
            //         } 
                    
            //     }
            // }

            return errorMessages

        },

        getIncomesByEntityId(records, entity){

            return records.filter( record => {

                if(record.type == 'incomes'){

                    let ids = []

                    if(entity.type == 'people') {
    
                        record.relationships.people.data.forEach( person => {
                            ids.push(person.id)
                        })
    
                    } else {
    
                        record.relationships.businesses.data.forEach( business => {
                            ids.push(business.id)
                        })
    
                    }
    
                    if(ids.includes(entity.id)){
                        return true
                    }


                }

                return false

            })

        },

        parseDate(str) {
            if (str) {
                return new Date(str);
            } else {
                return null;
            }
        },

        hasOnlyOneCurrent(dates) {
            
            let valid = false;
            let hasPrimaryIncome = false;

            for(let i=0;i<dates.length;i++) {
            
                if (dates[i].primary == 'Current') {
                    
                    // found a primary address
                        
                    if (!hasPrimaryIncome) {
                        
                        hasPrimaryIncome = true;
                        
                        valid = true;
                        
                    } else {
                        
                        //more than 1 address is set to primary
                        
                        valid = false;
                        break;
                    }
                }
            }
            
            return valid;

        },

        currentIsMostRecent(dates) {

            if (dates[dates.length-1] && dates[dates.length-1].primary == 'Current') {
                return true;
            } else {
                return false;
            }
            
        },

        getDays(first, second) {
            if ((first != null) && (second != null)) {
                return this.daydiff(first, second)
            } else {
                return null;
            }
        },

        getRoles(entity){

            let roles = this.getEntityRoles(entity, this.entityParts)

            return roles.length > 1 ? `${roles[0]} +${roles.length - 1}`: roles.length == 1 ? roles[0] : ''

        },

        getEndDatePrevious() {

            let { selectedEntity, incomes } = this
            let date = null

            const filteredIncomes = this.getIncomesByEntityId(incomes, selectedEntity)

            let dates = []

            filteredIncomes.forEach(income => {

                dates.push({
                    "primary": income.attributes['income-situation'],
                    "start-date": this.parseDate(income.attributes['start-date']),
                    "end-date": this.parseDate(income.attributes['end-date']),
                })

            })

            // Sort date by start-date old to new
            dates.sort((a,b) => {
                return a['start-date'] - b['start-date']
            })

            if(dates.length > 0) {
                date = moment(dates[0]['end-date']).format('YYYY-MM-DD')
            }

            return date
        },

        isValid(data) {
            for (let key in data) {
                if (data.hasOwnProperty(key)) {
                    if (data[key] == 0) {
                        return false;
                        break;
                    }
                }
            }
            return true;
        },

        daydiff(first, second) {
            return Math.round((second-first)/(1000*60*60*24));
        },

        kindsOrderOrganizer(kind){

            let newChildrenOrder = []

            if(kind?.children) {

                let parentFound = false

                Object.keys(kindsOrder).forEach( key => {

                    if(key == kind.id) {

                        parentFound = true

                        kindsOrder[key].forEach( item => {

                            kind.children.forEach(child => {
                                
                                if(child.id == item) {
                                    newChildrenOrder.push(child)
                                }

                            });

                        })

                    }

                })

                // Default to original if not in organizer json file
                if(!parentFound) {
                    newChildrenOrder = kind.children
                }

            }

            return newChildrenOrder
        }
    }
}