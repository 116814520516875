<template>
    <v-card flat class="mx-auto" :disabled="isDisabled">
        <div v-if="vb.mdAndUp" >
            <div class="d-flex">
                <v-sheet width="50%" color="light_gray_1" class="pa-4">
                    <div class="px-4 pt-4">
                        <v-img 
                            max-width="120"
                            class="flex-brand" 
                            :src="require(`~/assets/images/nodifi.svg`)" 
                            position="center left" 
                            contain>
                        </v-img>
                        <div class="mt-2 font-size-20">Data</div>
                    </div>

                    <v-card flat class="overflow-y-auto light_gray_1 mt-4">
                        <v-card-text class="d-flex">

                            <v-list class="pt-0 light_gray_1">

                                <v-list-item two-line class="px-0">
                                    <v-list-item-content class="pt-0">
                                        <v-list-item-title class="font-weight-bold">Asset Known</v-list-item-title>
                                        <v-list-item-title class="font-italic pt-2">{{ purchase && purchase.attributes ? purchase.attributes['vendor-known'] : '&mdash;' }}</v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>

                                <v-list-item two-line class="px-0 pt-2">
                                    <v-list-item-content class="pt-0">
                                        <v-list-item-title class="font-weight-bold">Asset Seller Name</v-list-item-title>
                                        <v-list-item-title class="font-italic pt-2">{{ purchase && purchase.attributes ? purchase.attributes['vendor-name'] : '&mdash;' }}</v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>

                                <v-list-item two-line class="px-0 pt-2">
                                    <v-list-item-content class="pt-0">
                                        <v-list-item-title class="font-weight-bold">Asset Seller Phone</v-list-item-title>
                                        <v-list-item-title class="font-italic pt-2">{{ purchase && purchase.attributes ? purchase.attributes['vendor-phone'] : '&mdash;' }}</v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>

                            </v-list>

                        </v-card-text>
                    </v-card>
                </v-sheet>


                <v-sheet width="50%" class="pa-4">
                    <div class="px-4">
                        <v-img 
                            max-width="120"
                            class="flex-brand" 
                            :src="require(`~/assets/images/logo/cropped/lender-logo-moneyplace.png`)" 
                            position="center left" 
                            contain>
                        </v-img>
                        <div class="mt-2 font-size-20">Requirements</div>
                    </div>
                    <v-card flat class="overflow-y-auto mt-4">
                        <v-card-text class="d-flex pa-0">

                            <div class="px-4" style="width: 400px;">
                                <ValidationObserver ref="observer" tag="form">
                                    <div class="flex-form" >

                                        <div v-for="(field, key) in resources.purchase.attributes" class="flex-field" :key="key">
                                            <ValidationProvider 
                                                v-slot="{ errors }" 
                                                :name="labels[key]"
                                                :rules="getRules(key)" 
                                                :vid="key">

                                                <v-select
                                                    v-if="isFieldType('picklist', key)"
                                                    v-model="resources.purchase.attributes[key]"
                                                    :items="getOptions(key)"
                                                    color="secondary"
                                                    :error-messages="errors"
                                                    :label="labels[key]">
                                                </v-select>

                                                <v-text-field 
                                                    v-else
                                                    :label="labels[key]"
                                                    color="secondary"
                                                    :error-messages="errors"
                                                    v-model="resources.purchase.attributes[key]">
                                                </v-text-field>

                                            </ValidationProvider>
                                        </div>
                                        <div class="flex-field"></div>
                                    </div>
                                        
                                </ValidationObserver>
                            </div>

                        </v-card-text>
                    </v-card>
                </v-sheet>
            
            </div>

        </div>
        <div v-else>

            <v-card flat class="mx-2 rounded-xl" :style="`background-color: ${hexToRGBA(getActiveTheme.colors.secondary, 0.2)}`">

                <v-card-text class="d-flex py-1">
                    <v-row>
                        <v-col cols="6" class="pa-1">
                            <v-card
                                min-height="45"
                                flat class="rounded-xl px-1"
                                :color="tabSelection == 'nodifi' ? 'white' : 'transparent'">
                                <div class="d-flex justify-center pt-1" @click="selectTab('nodifi')">
                                    <v-img 
                                        max-width="55"
                                        class="flex-brand" 
                                        :src="require(`~/assets/images/nodifi.svg`)" 
                                        position="center left" 
                                        contain>
                                    </v-img>
                                    <div class="mt-3 font-size-12 pl-1">Data</div>
                                </div>
                            </v-card>
                        </v-col>
                        <v-col cols="6" class="pa-1">
                            <v-card
                                min-height="45"
                                flat class="rounded-xl px-1"
                                :color="tabSelection == 'money-place' ? 'white' : 'transparent'">
                                <div class="d-flex flex-column" @click="selectTab('money-place')">
                                    <v-img 
                                        max-width="55"
                                        class="flex-brand mx-auto mt-1" 
                                        :src="require(`~/assets/images/logo/cropped/lender-logo-moneyplace.png`)" 
                                        position="center left" 
                                        contain>
                                    </v-img>
                                    <div class="font-size-12 pl-1 mx-auto mt-n1">Requirements</div>
                                </div>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-card-text>
                
            </v-card>

            <v-list v-show="tabSelection == 'nodifi'" class="mx-2" color="transparent">

                <v-list-item two-line class="px-0">
                    <v-list-item-content class="pt-0">
                        <v-list-item-title class="font-weight-bold">Asset Known</v-list-item-title>
                        <v-list-item-title class="font-italic pt-2">{{ purchase && purchase.attributes ? purchase.attributes['vendor-known'] : '&mdash;' }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>

                <v-list-item two-line class="px-0 pt-2">
                    <v-list-item-content class="pt-0">
                        <v-list-item-title class="font-weight-bold">Asset Seller Name</v-list-item-title>
                        <v-list-item-title class="font-italic pt-2">{{ purchase && purchase.attributes ? purchase.attributes['vendor-name'] : '&mdash;' }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>

                <v-list-item two-line class="px-0 pt-2">
                    <v-list-item-content class="pt-0">
                        <v-list-item-title class="font-weight-bold">Asset Seller Phone</v-list-item-title>
                        <v-list-item-title class="font-italic pt-2">{{ purchase && purchase.attributes ? purchase.attributes['vendor-phone'] : '&mdash;' }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </v-list>

            <div v-show="tabSelection != 'nodifi'" class="mx-2">
                <ValidationObserver ref="observer" tag="form">
                <div class="flex-form" >

                    <div v-for="(field, key) in resources.purchase.attributes" class="flex-field" :key="key">
                        <ValidationProvider 
                            v-slot="{ errors }" 
                            :name="labels[key]"
                            :rules="getRules(key)" 
                            :vid="key">

                            <v-select
                                v-if="isFieldType('picklist', key)"
                                v-model="resources.purchase.attributes[key]"
                                :items="getOptions(key)"
                                color="secondary"
                                :error-messages="errors"
                                :label="labels[key]">
                            </v-select>

                            <v-text-field 
                                v-else
                                :label="labels[key]"
                                color="secondary"
                                :error-messages="errors"
                                v-model="resources.purchase.attributes[key]">
                            </v-text-field>

                        </ValidationProvider>
                    </div>
                    <div class="flex-field"></div>
                </div>
                    
            </ValidationObserver>
            </div>

            </div>
    </v-card>
</template>
<script>

    import { mapActions, mapState, mapGetters } from 'vuex'
    import helpers from '~/plugins/helpers'
    import subscriptions from '~/components/shared/subscriptions'

    export default {
        name: "MoneyPlaceSecurity1",

        components: {  },

        mixins: [ helpers, subscriptions ],

        data() {
            return {
                purchase: null,
                tabSelection: "nodifi",
                resources: {
                    purchase: {
                        attributes: {
                            "assetSellerCompany": null,
                            "vendor-email": null,
                        }
                    }
                },
                labels: {
                    "assetSellerCompany": "Company",
                    "vendor-email": "Email",
                    "__spacer": null
                },
                validation_rules: {
                    attributes: {
                        "assetSellerCompany": "required",
                        "vendor-email": "required|email",
                    }
                }
            }
        },

        computed: {
            ...mapState({
                appId: state => state.flows.appId
            }),
            ...mapGetters('themes', [
                'getActiveTheme'
            ]),
        },

        methods: {
            ...mapActions('resource-actions', [
                'getPurchases',
                'updatePurchases'
            ]),
            selectTab(tab) {
                this.tabSelection = tab
            },
            hexToRGBA(hex, a = 1){
                return this.$options.filters.hexToRGBA(hex, a)
            },
            async pull() {

                try {

                    let res = await this.getPurchases()

                    if(res.status === 200) {
                        this.purchase = res.data.data[0]

                        await this.populateFields()
                    }

                } catch (err) {
                    console.log(err)
                }

            },
            async push() {

                try {

                    let res = null

                    if(this.purchase) {

                        res = await this.updatePurchases({
                            "id": this.purchase.id,
                            "type": "purchases",
                            "attributes": {
                                'vendor-email': this.resources.purchase.attributes['vendor-email'],
                                lender: {
                                    moneyPlace: {
                                        assetSellerCompany: this.resources.purchase.attributes.assetSellerCompany
                                    }
                                }
                            }
                        })

                    }

                    const {valid, status} = await this.validateFormData()

                    return {valid, status, data: {}}

                } catch (err) {
                    console.log(err)
                }

            },
            populateFields() {

                let { purchase, resources } = this

                if(purchase) {

                    Object.keys(resources.purchase.attributes).forEach(key => {
                        if (key === 'vendor-email') {
                            resources.purchase.attributes[key] = purchase.attributes?.[key]
                        } else {
                            resources.purchase.attributes[key] = purchase.attributes?.lender?.moneyPlace?.[key]
                        }
                    })

                }

            },
        },

    }
</script>
<style lang="scss" scoped>
    .flex-form {
        display: grid;
        grid-column-gap: 50px;
        grid-row-gap: 0;
        grid-template-columns:  repeat(auto-fit, minmax(260px, 1fr));

        .flex-field {
            align-self: start;
        }
    }

</style>