export default {
    "loan-type": [
        "Bills - Non Utility",
        "Boat purchase",
        "Bus purchase",
        "Camper Trailer purchase",
        "Car purchase",
        "Car Repairs",
        "Caravan purchase",
        "Dental/Medical Expenses",
        "Education Expenses",
        "Emergency Expenses",
        "Entertainment/Leisure",
        "Family Assistance",
        "Holiday",
        "Household/White Goods",
        "Land Purchase",
        "Loan/Debt Consolidation",
        "Motorbike purchase",
        "Personal Water Craft",
        "Professional/Business Expenses",
        "Purchase of boat and additional products",
        "Purchase of bus and additional products",
        "Purchase of camper trailer and additional products",
        "Purchase of car and additional products",
        "Purchase of caravan and additional products",
        "Purchase of motorbike and additional products",
        "Purchase of Ride on Mower and additional products",
        "Purchase of trailer and additional products",
        "Purchase of truck and additional products",
        "Refinance",
        "Rent/Bond/Moving Costs",
        "Repairs/Renovations",
        "Ride on Mower purchase",
        "Scooter purchase",
        "Special Occasion",
        "Tax Refund Advance",
        "Tractor purchase",
        "Trailer purchase",
        "Travel Expenses",
        "Truck purchase",
        "Vehicle Expenses",
        "Vehicle Purchase",
        "Vet Expenses",
        "Work Expenses",
        "Xmas Loan",
    ],
    "purchase-kinds-value": {
        "9-2": "Motor Vehicle",
        "9-4": "Boat (Moored)",
        "9-5": "Boat (Trailered)",
        "9-6": "Camper Trailer",
        "9-7": "Caravan",
        "9-8": "Horse Float",
        "9-9": "Jetski",
        "9-10": "Light Trailer",
        "9-11": "Marine Engine",
        "9-12": "Motorbike (Road Registered)",
        "9-13": "Motorhome",
        "9-14": "Trail Bike (Unregistered)",
        "9-16": "Bus",
        "9-17": "Crane",
        "9-18": "Prime Mover",
        "9-19": "Rigid Truck (> 4.5t)",
        "9-20": "Trailer (Truck)",
        "9-22": "Agricultural Machinery",
        "9-24": "Yellow Goods",
        "9-26": "Computer Hardware",
        "9-27": "Industrial Plant (Lathes etc)",
        "9-28": "Material Handling (Fork Lifts etc)",
        "9-29": "Medical Equipment",
        "9-30": "Office Equipment (Copiers etc)",
        "9-31": "Other (Not Listed)",
        "9-32": "POS Systems",
        "9-33": "Solar Equipment",
        "9-35": "Apartment/Unit/Flat",
        "9-36": "Display Home",
        "9-37": "Duplex",
        "9-38": "Fully-Detached House",
        "9-39": "Hobby Farm",
        "9-40": "Kit Home",
        "9-41": "Semi-Detached House",
        "9-42": "Serviced Apartment",
        "9-43": "Townhouse",
        "9-44": "Vacant Land",
        "9-45": "Real Estate - Construction",
    }
}