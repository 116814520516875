<template>
    <v-sheet width="416" style="margin: auto;">

        <ValidationObserver ref="observer" tag="form">
            <template v-for="(field, key) in resources.purchases.attributes" >

            <ValidationProvider 
                    v-if="visible[key]"
                    :key="key"
                    v-slot="{ errors }" 
                    :name="labels[key]"
                    :rules="getRules(key)" 
                    :vid="key">

                        <nvic-finder
                            v-if="key == 'nvic' && isMotorVehicle"
                            :label="labels[key]"
                            :errors="errors"
                            @accept="handleNvicAccept"
                            :default-value="resources.purchases.attributes[key]"
                            v-model="resources.purchases.attributes[key]">
                        </nvic-finder>

                        <v-select
                            v-else-if="isFieldType('picklist', key)"
                            v-model="resources.purchases.attributes[key]"
                            :items="getOptions(key)"
                            :error-messages="errors"
                            color="secondary"
                            :label="labels[key]"
                            ></v-select>

                        <flex-date-picker
                            v-else-if="isFieldType('date', key)"
                            :label="labels[key]"
                            :errors="errors"
                            v-model="resources.purchases.attributes[key]"
                        ></flex-date-picker>

                        <v-text-field 
                            v-else
                            tile
                            :type="key === 'year-of-manufacture' ? 'number' : 'text'"
                            :label="labels[key]"
                            color="secondary"
                            :inputmode="key === 'year-of-manufacture' ? 'numeric' : ''"
                            :error-messages="errors"
                            v-model="resources.purchases.attributes[key]"
                            required>
                        </v-text-field>

            </ValidationProvider>
            </template>
        </ValidationObserver>

    </v-sheet>
</template>

<script>
    /**
     * REQUIRED!
     */
 
    import {store, actions} from '~/components/v1/PurchaseEditor6/__store'
    import Radio2 from '~/components/v1/DynamicField1/includes/_Radio2'
    import NvicFinder from '~/components/base/NvicFinder' 
    import mixins from '~/components/v1/PurchaseEditor6/_mixins'


    export default {
        mixins: [mixins],
        components: {
            Radio2,
            NvicFinder,
        },
        props: {
            data: {
                type: Object,
                default: null
            },
            updater: undefined
        },
        data(){
            return {
                visible: {
                    "nvic": false,
                    "year-of-manufacture": true,
                    "make": true,
                    "model": true,
                    "variant": true,
                },

                resources: {
                    loans : {
                        type: "loans",
                        id: null,
                        attributes: {
                            "sale-type": "",
                        }
                    },
                    purchases: {
                        type: "purchases",
                        attributes: {
                            "nvic": "",
                            "year-of-manufacture": "",
                            "make": "",
                            "model": "",
                            "variant": ""
                        },
                        relationships: {
                            application: {
                                data : {
                                    type: "applications",
                                    id: null
                                }
                            }
                        }
                    }
                },

                labels: {
                    "nvic": "NVIC",
                    "year-of-manufacture": "Year of Manufacture",
                    "make": "Make",
                    "model": "Model",
                    "variant": "Variant",
                    "condition": "Condition",
                    "vendor-known": "Vehicle Known",
                    // "kms": "KMS",
                    "purchase-price": "Purchase Price",
                    "cash-deposit": "Cash Deposit",
                    "trade-in-amount": "Trade in Amount",
                    "payout-amount": "Payout Amount",
                    "required-loan-amount": "Required Loan Amount"
                },
                validation_rules: {
                    attributes: {
                        "nvic": "required|string|max:50",
                        "year-of-manufacture": "required|integer|digits_between:4,4",
                        "make": "required|string|max:255",
                        "model": "required|string|max:200",
                        "variant": "required|string|max:128",
                        "condition": "required|string|in:New,Used",
                        "vendor-known": "required|string|in:Yes,No",
                        // "kms": "required|integer",
                        "purchase-price": "between:0,9999999",
                        "cash-deposit": "between:0,9999999",
                        "trade-in-amount": "between:0,9999999",
                        "payout-amount": "between:0,9999999",
                        "sale-type": "required|string|in:Dealer,Private Sale,Refinance",
                        "sale-type-2": "required|string|in:Dealer,Private Sale",
                        "required-loan-amount": "required"
                    }
                },

            }
        },
        mounted() {
            this.updateResourceFields()
        },
        computed:{
            isFormTypeEdit(){
                return this.data != null
            },
            isMotorVehicle(){

                if(store.assetType) {
                    return store.assetType.id == "9-2"
                }

                return false
            },
            purchaseCondition() {
                return this.data?.attributes?.['condition']
            }
        },
        methods: {
            // Submit Observer
            async validate(){

                const valid = await this.$refs.observer.validate()

                if(valid) {

                    // attache app id
                    this.resources.purchases.relationships.application.data.id = this.appId

                    this.$emit('submit', this.resources.purchases)
                }

                return valid

            },
            /**
             * Nvic Event Handler
             */
            handleNvicAccept(data){

                // this.resourceObj.purchases.attributes.kms = data.details.AVERAGEKM
                this.resources.purchases.attributes["make"] = data.details["manufacturer-name"]
                this.resources.purchases.attributes["model"] = data.details["family-name"]
                this.resources.purchases.attributes["variant"] = data.details["variant-name"]
                this.resources.purchases.attributes["year-of-manufacture"] = data.details["year-create"]

            },
            reset(){

                this.resources.purchases =  {
                        type: "purchases",
                        attributes: {
                            "nvic": "",
                            "year-of-manufacture": "",
                            "make": "",
                            "model": "",
                            "variant": ""
                        },
                        relationships: {
                            application: {
                                data : {
                                    type: "applications",
                                    id: null
                                }
                            }
                        }
                    }

                this.$refs.observer.reset()

            },
          updateResourceFields(){
                
                if(this.isFormTypeEdit){
                    this.resources.purchases = this.filterResourceFields(this.resources.purchases, this.data)
                }
            }
        },
        watch: {
            updater(val) {
                this.updateResourceFields()
            }
        }

    }
</script>

<style scoped lang="scss">

</style>
