<template>
    <div>
        <v-data-table

          hide-default-footer
          :items-per-page="-1"
          :headers="[
            {
              text: 'Situation',
              value: 'attributes.income-situation',
            },
            {
              text: 'Industry',
              value: 'attributes.lender-pepper-industry',
            },
            {
              text: 'Occupation',
              value: 'attributes.lender-pepper-occupation',
            },
            {
              text: 'Action',
              value: 'action'
            }
          ]"
          :items="previousAndCurrentIncomes"
          class="px-8 v-data-table-2"
        >
        <template #body="{items}">
          <tbody>
            <tr v-for="(item, index) in items" :key="index" :class="{'light_gray_1': resources.income.id == item.id}">
              <td>
                {{ item.attributes['income-situation'] ||'-' }}
              </td>
              <td>
                {{ item.attributes['lender-pepper-industry'] ||'-' }}
              </td>
              <td>
                {{ item.attributes['lender-pepper-occupation'] ||'-' }}
              </td>
              <td>
                <v-chip v-if="resources.income.id != item.id" depressed small color="secondary" @click="setIncomeFields(item)">
                  Edit
                </v-chip>
                <v-chip v-else depressed small>
                  Selected
                </v-chip>
              </td>
            </tr>
          </tbody>
        </template>
        </v-data-table>
        <div class="d-flex" style="width: 100%">
          <v-sheet
            color="light_gray_1"
            class="py-8 px-12"
            width="50%"
            outlined
            elevation="0"
          >
            <v-img
              max-width="120"
              class="flex-brand"
              :src="require(`~/assets/images/nodifi.svg`)"
              position="center left"
              contain
            >
            </v-img>
            <div class="mt-2 mb-6 font-size-20">Data</div>

            <v-list color="transparent">
              <v-list-item two-line class="px-0">
                <v-list-item-content>
                  <v-list-item-title class="font-weight-medium"
                    >Visa Number</v-list-item-title
                  >
                  <v-list-item-subtitle>{{
                    data ? data.attributes["visa"] || "&mdash;" : "&mdash;"
                  }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>

              <v-list-item two-line class="px-0">
                <v-list-item-content>
                  <v-list-item-title class="font-weight-medium"
                    >Employer Name</v-list-item-title
                  >
                  <v-list-item-subtitle>{{
                    income ? income.attributes["employer-name"] || "&mdash;" : "&mdash;"
                  }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>

              <v-list-item two-line class="px-0">
                <v-list-item-content>
                  <v-list-item-title class="font-weight-medium"
                    >Job Title</v-list-item-title
                  >
                  <v-list-item-subtitle>{{
                    income ? income.attributes["job-title"] || "&mdash;" : "&mdash;"
                  }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-sheet>
          <v-sheet class="py-8 px-12" width="50%">
            <v-img
              max-width="120"
              class="flex-brand"
              :src="require(`~/assets/images/logo/cropped/lender-logo-pepper.png`)"
              position="center left"
              contain
            >
            </v-img>
            <div class="mt-2 mb-8 font-size-20">Requirements</div>

            <ValidationObserver ref="observer" tag="form">
              <template v-for="(field, key) in resources.person.attributes" v-if="!updatingFields">
                <ValidationProvider
                  :key="key"
                  v-slot="{ errors }"
                  :name="labels[key]"
                  :rules="getRules(key)"
                  :vid="key"
                >
                  <ButtonGroup3
                    v-if="isBtnGroup(key)"
                    v-model="resources.person.attributes[key]"
                    :error-messages="errors"
                    :options="[
                      { value: '1', label: 'Yes' },
                      { value: '0', label: 'No' },
                    ]"
                    :label="labels[key]"
                  />

                  <v-select
                    v-else-if="isFieldType('picklist', key)"
                    v-model="resources.person.attributes[key]"
                    :items="getOptions(key)"
                    :error-messages="errors"
                    color="secondary"
                    :label="labels[key]"
                  ></v-select>
                  
                  <v-autocomplete
                    v-else-if="isAutoComplete(key)"
                    v-model="resources.person.attributes[key]"
                    :items="getOptions(key)"
                    :error-messages="errors"
                    color="secondary"
                    :label="labels[key]"
                  ></v-autocomplete>

                  <v-text-field
                    v-else
                    tile
                    type="text"
                    :label="labels[key]"
                    color="secondary"
                    :error-messages="errors"
                    v-model="resources.person.attributes[key]"
                    required
                  >
                  </v-text-field>
                </ValidationProvider>
              </template>
            </ValidationObserver>
          </v-sheet>
        </div>
    </div>
</template>

<script>
import { mapActions, mapMutations, mapGetters } from "vuex";
import subscriptions from "~/components/shared/subscriptions";
import shared from "~/components/v1/submission/LenderPepperEntities1/__shared";
import {
  store,
  actions,
} from "~/components/v1/submission/LenderPepperEntities1/__store";
import ButtonGroup3 from "~/components/base/ButtonGroup3";
import Occupation from '~/components/v1/submission/LenderPepperEntities1/__occupation-enums'

export default {
  components: { ButtonGroup3 },
  mixins: [subscriptions, shared],
  props: {
    data: {
      type: Object,
      default: null,
    },
    applicantType: {
      type: String,
      default: "primary",
    },
    incomes: {
      type: Array,
      default: () => [],
    },
    income: {
      type: Object,
      default: () => {},
    },
    updater: undefined,
  },
  data() {
    return {
      tabSelection: "nodifi",
      updatingFields: false,
      resources: {
        person: {
          id: null,
          type: "people",
          attributes: {
            "lender-pepper-residency": "",
            "lender-pepper-industry": "",
            "lender-pepper-occupation": "",
          },
        },
        income: {
          id: null,
          type: "incomes",
        },
      },

      labels: {
        "lender-pepper-residency": "Client residency status ",
        "lender-pepper-industry": "Industry",
        "lender-pepper-occupation": "Occupation",
      },
      validation_rules: {
        attributes: {
          "lender-pepper-residency": "required|in:Citizen,PR,Other",
          "lender-pepper-industry": "required",
          "lender-pepper-occupation": `required`,
        },
      },
      options: {
        "lender-pepper-residency": [
          { text: "Australian or New Zealand Citizen", value: "Citizen" },
          {
            text: "Australian Permanent Resident",
            value: "PR",
          },
          { text: "On Visa", value: "Other" },
        ],
        "lender-pepper-industry": [
          {
            text: "Accommodation And Food Services",
            value: "ACCOMMODATION AND FOOD SERVICES",
          },
          {
            text: "Administrative And Support Services",
            value: "ADMINISTRATIVE AND SUPPORT SERVICES",
          },
          {
            text: "Agriculture Forestry And Fishing",
            value: "AGRICULTURE FORESTRY AND FISHING",
          },
          {
            text: "Arts And Recreation Services",
            value: "ARTS AND RECREATION SERVICES",
          },
          { text: "Construction", value: "CONSTRUCTION" },
          { text: "Education And Training", value: "EDUCATION AND TRAINING" },
          {
            text: "Electricity Gas Water And Waste Services",
            value: "ELECTRICITY GAS WATER AND WASTE SERVICES",
          },
          {
            text: "Financial And Insurance Services",
            value: "FINANCIAL AND INSURANCE SERVICES",
          },
          {
            text: "Health Care And Social Assistance",
            value: "HEALTH CARE AND SOCIAL ASSISTANCE",
          },
          {
            text: "Information Media And Telecommunications",
            value: "INFORMATION MEDIA AND TELECOMMUNICATIONS",
          },
          { text: "Manufacturing", value: "MANUFACTURING" },
          { text: "Mining", value: "MINING" },
          { text: "Other Services", value: "OTHER SERVICES" },
          {
            text: "Professional Scientific And Technical Services",
            value: "PROFESSIONAL SCIENTIFIC AND TECHNICAL SERVICES",
          },
          {
            text: "Public Administration And Safety",
            value: "PUBLIC ADMINISTRATION AND SAFETY",
          },
          {
            text: "Rental Hiring And Real Estate Services",
            value: "RENTAL HIRING AND REAL ESTATE SERVICES",
          },
          { text: "Retail Trade", value: "RETAIL TRADE" },
          {
            text: "Transport Postal And Warehousing",
            value: "TRANSPORT POSTAL AND WAREHOUSING",
          },
          { text: "Wholesale Trade", value: "WHOLESALE TRADE" },
        ],
        "lender-pepper-occupation": [],
      },
    };
  },
  computed: {
    vb() {
      return this.$vuetify.breakpoint;
    },
    getLenderPepperOccupationOptions(){

        return Occupation['lender-pepper-occupation'].map( option => {
            return {
              text: option,
              value: option
            }
        })

    },
    previousAndCurrentIncomes () {
      return this.incomes.filter(item => {
        return item.relationships.people.data.some(item => item.id == this.resources.person.id)
      })
    }
  },
  mounted() {

    this.updateResourceFields();
    this.options["lender-pepper-occupation"] = this.getLenderPepperOccupationOptions

  },
  methods: {
    ...mapActions("resource-actions", ["getPerson"]),
    isFieldType(field, key) {
      return field === "picklist"
        ? ["lender-pepper-industry", "lender-pepper-residency"].includes(key)
        : false;
    },
    selectTab(tab) {
      this.tabSelection = tab;
    },
    isAutoComplete(key) {
      let keys = ["lender-pepper-occupation"]
      return keys.includes(key);
    },
    isBtnGroup(key) {
      let keys = ["lender-pepper-forsee-repayment"];

      return keys.includes(key);
    },
    async validate() {
      const valid = await this.$refs.observer.validate();

      if (valid) {
        // attache person id
        // this.resources.person.id = this.data.id
        // this.resources.income.id = this.income.id;
        this.$emit("submit", this.resources);
      }
    },
    reset() {
      this.$refs.observer.reset();
    },
    setIncomeFields (income) {

      this.updatingFields = true;

      const isCentrelink = income && this.centrelinkKinds.includes(income?.relationships?.kind?.data?.id )

      if (income && Object.keys(income).length) {

          if(isCentrelink) {
            const newAttr = {
              "lender-pepper-residency": this.resources.person.attributes["lender-pepper-residency"]
            }
            this.resources.person.attributes = newAttr
          } else {
            this.resources.person.attributes["lender-pepper-industry"] = income.attributes["lender-pepper-industry"];
            this.resources.person.attributes["lender-pepper-occupation"] = income.attributes["lender-pepper-occupation"];
          }

          this.resources.income.id = income.id
        }

        this.updatingFields = false;

    },
    async updateResourceFields() {
      // console.log('updateResourceFields', this.income)

      this.$emit("loading", true);

      const { data } = this;

      if (data) {
        let res = await this.getPerson(data.id);

        this.resources.person = this.filterResourceFields(
          this.resources.person,
          res.data.data
        );

        this.setIncomeFields(this.income)

        this.$emit("loading", false);
      } else {
        this.$emit("loading", false);
      }
    },
  },
  watch: {
    updater(val) {
      if (val) {
        this.updateResourceFields();
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
