<template>
<div>

    <v-sheet 
        class="mx-auto rounded-xl mb-4 mb-md-16 px-4 px-md-12 py-4 py-md-8" 
        elevation="0" 
        :style="`background-color: ${hexToRGBA(getActiveTheme.colors.primary, 0.1)}`">

        <div class="d-flex justify-space-between flex-wrap loan-details-section">

            <v-sheet class="d-flex justify-center justify-md-left align-center" color="transparent" width="auto" >
                <div class="bl">
                    <div class="text-h5 font-weight-medium" v-if="vb.mdAndUp">Loan Details</div>
                    <div class="font-weight-medium font-size-12" v-else>Loan Details</div>
                </div>
            </v-sheet>

            <template>
                <v-spacer/>    
                    <div class="bl-spacer"></div>
                <v-spacer/>    

                <v-sheet color="transparent" width="auto" >
                    <div class="bl" v-if="vb.mdAndUp">
                        <div>Loan amount</div>
                        <div class="text-no-wrap text-h5 font-weight-medium">
                            {{ isUnsecured ? getLoanDetails["requested-required-loan-amount"] : getLoanDetails["required-loan-amount"] | currency }}
                        </div>
                    </div>
                    <div class="bl" v-else>
                        <div class="font-size-10">Loan amount</div>
                        <div class="text-no-wrap font-size-10 line-height-10 font-weight-medium">
                            {{ isUnsecured ? getLoanDetails["requested-required-loan-amount"] : getLoanDetails["required-loan-amount"] | currency }}
                        </div>
                    </div>
                </v-sheet>

                <v-spacer/>    
                    <div class="bl-spacer"></div>
                <v-spacer/>    

                <v-sheet color="transparent" width="auto" >
                    <template v-if="isUnsecured">

                        <template v-if="vb.mdAndUp">
                            <div>{{ vb.mdAndUp ? 'Personal Loan Reason'  : 'Reason'}}</div>
                            <div class="ftext-no-wrap text-h5 font-weight-medium">
                                {{  getLoanDetails["personal-loan-reason"] }}
                            </div>
                        </template>
                        <template v-else>
                            <div class="font-size-10">{{ vb.mdAndUp ? 'Personal Loan Reason'  : 'Reason'}}</div>
                            <div class="ftext-no-wrap font-size-10 line-height-10 font-weight-medium">
                                {{  getLoanDetails["personal-loan-reason"] || '&mdash;' }}
                            </div>
                        </template>

                    </template>
                    <template v-else>
                        <template v-if="vb.mdAndUp">
                            <div>Asset year</div>
                            <div class="ftext-no-wrap text-h5 font-weight-medium">
                                {{ getLoanDetails["year-of-manufacture"] || "0000" }}
                            </div>
                        </template>
                        <template v-else>
                            <div class="font-size-10">Asset year</div>
                            <div class="ftext-no-wrap font-size-12 line-height-10 font-weight-medium">
                                {{ getLoanDetails["year-of-manufacture"] || "0000" }}
                            </div>
                        </template>
                    </template>
                </v-sheet>

                <v-spacer/>    

            </template>

            <v-sheet class="d-flex justify-center justify-md-end align-center" color="transparent" width="auto">
                <v-btn
                    v-if="vb.mdAndUp"
                    color="primary"
                    class="px-10"
                    large
                    @click="editDialog = true"
                    depressed>
                    Customize
                </v-btn>
                <v-btn
                    v-else
                    color="primary"
                    class="px-2 font-size-12"
                    small
                    @click="editDialog = true"
                    depressed>
                    Customize
                </v-btn>
            </v-sheet>
        </div>
    </v-sheet>


    <!-- Edit Modal -->
    <v-dialog
        max-width="520"
        persistent
        scrollable
        v-model="editDialog">

        <v-card flat :disabled="editDialogSaving">
            <v-progress-linear color="secondary" indeterminate stream absolute top v-if="editDialogSaving"></v-progress-linear>
            
            <v-card-title class="justify-space-between">

                <h3>Customise Assumption</h3>
                
                <v-btn icon @click="editDialog = false" fab>
                    <v-icon color="secondary">mdi-close</v-icon>
                </v-btn>
            </v-card-title>

            <v-card-text class="form-fields-sm"> 
                <ValidationObserver ref="editFormObserver" tag="form">

                        <div class="my-0 py-0"
                            v-for="layout in fieldLayout" 
                            :key="layout.field">

                            <ValidationProvider 
                                v-slot="{ errors }" 
                                :name="labels[layout.field]" 
                                :rules="getRules(layout.field)" 
                                v-if="isVisible(layout.field)"
                                :vid="layout.field">

                                <ButtonGroup1 
                                    v-if="isBtnGroup(layout.field)"
                                    v-model="resources[layout.type].attributes[layout.field]"
                                    :error-messages="errors"
                                    :options="['New', 'Used']"
                                    class="mb-3"
                                    :label="labels[layout.field]" />

                                <v-select
                                    v-else-if="isFieldType('picklist', layout.field)"
                                    v-model="resources[layout.type].attributes[layout.field]"
                                    :items="getOptions(layout.field)"
                                    :error-messages="errors"
                                    color="secondary"
                                    :label="labels[layout.field]"
                                ></v-select>

                                <v-text-field 
                                    v-else
                                    :type="'text'"
                                    :label="labels[layout.field]"
                                    :error-messages="errors"
                                    v-model="resources[layout.type].attributes[layout.field]"
                                    color="secondary">
                                </v-text-field>
                            </ValidationProvider>
                        </div>
            
                </ValidationObserver>
            </v-card-text>

            <v-card-actions class="d-block text-center pt-4 pb-8">
                <v-btn 
                    class="mx-auto" 
                    width="100%" 
                    max-width="225" 
                    color="secondary"
                    large 
                    @click="submitEditForm" 
                    depressed> Save and Calculate 
                </v-btn>
            </v-card-actions>

        </v-card>
    </v-dialog>


</div>
</template>

<script>

    import { mapState, mapActions, mapGetters, mapMutations } from 'vuex'
    import mixins from '~/components/v1/QuoteResult6/_mixins'

    export default {
        mixins: [
            mixins
        ],
        data() {
            return {
                resources: {
                    loans: {
                        "type": "loans",
                        "attributes": {
                            'sale-type': null,
                            "requested-balloon": null,
                            "requested-term-months": null,
                            "personal-loan-reason": '',
                            "requested-required-loan-amount": null
                        }
                    },
                    purchases: {
                        "type": "purchases",
                        "attributes": {
                            "condition": null,
                            "year-of-manufacture": null,
                            "purchase-price": null,
                            "cash-deposit": null,
                            "required-loan-amount": null
                        },
                    },
                },
                validation_rules: {
                    attributes: {
                        "condition": "required|string|in:New,Used",
                        "sale-type": "required|string|in:Dealer,Private Sale,Refinance",
                        "year-of-manufacture": "required|integer|digits_between:4,4",
                        "purchase-price": "between:0,99999999",
                        "cash-deposit": "between:0,99999999",
                        "required-loan-amount": "required",
                        "requested-balloon": "required",
                        "requested-term-months": "required|in:12,24,36,48,60,72,84",
                        "personal-loan-reason": "required|in:Debt Consolidation,Education,Funeral Expenses,Furniture and Appliances,Home Improvements,Medical Expenses,Motor or Leisure Vehicle,Other,Solar Energy,Tax Debt,Travel,Vehicle Repairs,Wedding",
                        "requested-required-loan-amount": "required|between:0,99999999",
                    }
                },
                labels: {
                    "condition": "Asset condition",
                    'sale-type': "Vendor Type",
                    "requested-term-months": "Requested Loan Term",
                    "year-of-manufacture": "Vehicle year",
                    "purchase-price": "Purchase price",
                    "cash-deposit": "Deposit",
                    "required-loan-amount": "Loan amount",
                    "requested-balloon": "Balloon payment",
                    "personal-loan-reason": "Personal Loan Reason",
                    "requested-required-loan-amount": "Loan Amount",
                },
                visible: {},
                enabled: {},
                editDialog: false,
                editDialogSaving: false,
            }
        },
        computed: {
            getLoanId(){
                return this.loanData.id
            },
            getPurchasePrice(){
                return this.resources.purchases.attributes["purchase-price"]
            },
            getDeposit(){
                return this.resources.purchases.attributes["cash-deposit"]
            },
            getLoanDetails(){

                const {purchases, loans} = this.resources
                
                let ref = [
                    "personal-loan-reason",
                    "required-loan-amount",
                    "requested-required-loan-amount",
                    "year-of-manufacture"
                ]

                let mappedFields = {}

                Object.keys(purchases.attributes).forEach(field => {
                    if(ref.includes(field)) {
                        mappedFields[field] = purchases.attributes[field]
                    }
                })

                Object.keys(loans.attributes).forEach(field => {
                    if(ref.includes(field)) {
                        mappedFields[field] = loans.attributes[field]
                    }
                })

                return mappedFields
            },
            handleEdit(){
                
                this.editDialog = true

                // this.$emit('edit')
            },
            isUnsecured(){
                if(this.loanData.attributes['loan-type'].search('Asset') == -1 ){
                    return true
                }
                return false
            },
            fieldLayout(){

                if(this.isUnsecured) {
                    return [
                        {type: "loans", field: 'requested-term-months'},
                        // {type: "purchases", field: 'required-loan-amount'},
                        {type: "loans", field: 'requested-required-loan-amount'},
                        {type: "loans", field: 'personal-loan-reason'}
                    ]
                } else {
                    return [
                        {type: "loans", field: 'requested-term-months'},
                        {type: "purchases", field: 'condition'},
                        {type: "loans", field: 'sale-type'},
                        {type: "purchases", field: 'year-of-manufacture'},
                        {type: "purchases", field: 'purchase-price'},
                        {type: "purchases", field: 'cash-deposit'},
                        // {type: "purchases", field: 'required-loan-amount'},
                        {type: "loans", field: 'requested-required-loan-amount'},
                        {type: "loans", field: 'requested-balloon'}
                    ]
                }

            },
        },
        methods: {
            ...mapActions('flows', ['selectNextStepOL']),
            ...mapActions('resource-actions', [
                'getLoan',
                'updateLoan',
                'updatePurchases',
                'getPurchases'
            ]),
            ...mapMutations('subscriptions', [
                'setStepableDataWorkable'
            ]),
            async init() {

                let loanResponse = await this.getLoan(this.getLoanId)

                if(loanResponse.data.data) {
                    this.resources.loans = this.filterResourceFields(this.resources.loans, loanResponse.data.data)
                }
                
                let purchases = await this.getPurchases()

                if(!this.isUnsecured) {
                    if(purchases.status == 200 || purchases.statusText == 'OK'){
                        this.resources.purchases = this.filterResourceFields(this.resources.purchases, purchases.data.data[0])
                    }
                }

            },
            async submitEditForm(){
                
                const {resources} = this

                this.editDialogSaving = true

                const valid = await this.$refs.editFormObserver.validate();

                if(valid) {

                    let requests = []

                    let loan = this.updateLoan({
                        ...resources.loans
                    })

                    let purchase = null

                    requests.push(loan)

                    if(!this.isUnsecured) {

                        purchase =  this.updatePurchases({
                            ...resources.purchases
                        })

                        requests.push(purchase)
                    }

                    let response = await Promise.all(requests)

                    // this.$emit('reset-lenders')
                    // this.$emit('update-lender-results')

                    loan = response[0]
                    
                    this.$emit('submit', loan.data.data)


                    // Close Edit Form
                    this.editDialogSaving = false
                    this.editDialog = false

                } else {

                    this.editDialogSaving = false
                }

            },
        },
        watch: {
            getPurchasePrice(value){
                this.resources.purchases.attributes["required-loan-amount"] = value - this.resources.purchases.attributes["cash-deposit"]
            },
            getDeposit(value){
                this.resources.purchases.attributes["required-loan-amount"] = this.resources.purchases.attributes["purchase-price"] - value
            },
            "validComponentData": {
                handler(val) {
                    if ( !this.validComponentData ) {

                        this.setStepableDataWorkable(false)

                    }
                },
                deep: true
            }
        }
    }
</script>

<style lang="scss">

    .flex__component-QuoteResult6 {

        .loan-details-section {
            .bl-spacer {
                border-right: 1px solid var(--v-light_gray_1-base);
            }
        }

    }
  
</style> 