import tenants from '~/config/tenants'

export const state = () => ({
    tenant: '',
    groupSlug: '',
    subPath: '',
    flowSlug: '',
    groupId: '',
    loginPath: null
})

export const getters = {
    getTenant: (state) => state.tenant,
    getTenantAssister: (state, getters, rootState) => {

        let assister = 'LMG Asset Finance Referral Hub'

        const slug = rootState.groups.applicationGroup ? rootState.groups.applicationGroup.attributes.slug : state.tenant

        tenants.data.forEach(tenant => {
            if(slug == tenant.slug && tenant.hasOwnProperty('assisters')){
                assister = tenant.assisters.default
            }
        });

        return assister
    },
    getTenantPlatform: (state) => {

        let platform = 'Nodifi'

        tenants.data.forEach(tenant => {
            if(state.tenant == tenant.slug && tenant.hasOwnProperty('platform')){
                platform = tenant.platform
            }
        });

        return platform
    },
    getApiDomain: (state, getters, rootState) => {
        return `/`;
    },
    
    /**
     *  All resources request except auth
     *  will now request directly to dev-nodifidata
     */
    getApiBase: (state, getters, rootState) => {

        const {tenant} = state

        let protocol = process.env.envProtocol
        let domain = process.env.envDomain
        let config = process.env.envConfig

        let tenantsData = tenants.data.find(t => t.slug == tenant);
        let tenantSlug = tenant

        if(tenantsData && tenantsData.hasOwnProperty('api')) {
            tenantSlug = tenantsData.api
        }

        return protocol + tenantSlug + '.' + domain + config
        
        // if(rootState.development && state.tenant){
        //     return protocol + tenant + '.' + domain + config
        // }

        // Hardcoded on production for the meantime...
        // return `https://${tenant}.dev-nodifidata.com/api/v1/`

        // This will use proxy server...
        // return `/api/v1`;

    },
    getBaseUrl: (state, getters, rootState) => {

        const {tenant} = state

        let protocol = process.env.envProtocol
        let domain = process.env.envDomain

        let tenantsData = tenants.data.find(t => t.slug == tenant);
        let tenantSlug = tenant

        if(tenantsData && tenantsData.hasOwnProperty('api')) {
            tenantSlug = tenantsData.api
        }

        return protocol + tenantSlug + '.' + domain
    },
    getFlowSlug: (state) => state.flowSlug,
    getGroupSlug: (state) => {

        // Return group if exists
        if(state.groupSlug){
            return state.groupSlug
        }

        // Get default group...
        let defaultGroup = 'nodifi'

        tenants.data.forEach(tenant => {

            if(state.tenant == tenant.slug){

                tenant.groups.forEach(group => {

                    if(group.default){
                        defaultGroup = group.slug
                    }

                })

            }
            
        });

        // console.log(defaultGroup)

        return defaultGroup
    },
    getGroupId: (state) => {
        return state.groupId ? state.groupId : '0'
    },
    getLoginPath: (state) => {
        return state.loginPath ? state.loginPath : '/nodifi/login'
    }
}

export const mutations = {
    setSubdomain(state, data) {
        state.tenant = data
    },
    setGroupSlug(state, data) {
        state.groupSlug = data
    },
    setFlowSlug(state, data) {
        state.flowSlug = data
    },
    setGroupId(state, data) {
        state.groupId = data
    },
    setLoginPath(state, data) {
        state.loginPath = data
    },
}