<template>
    <v-dialog
        persistent
        scrollable
        height="600"
        max-width="400"
        v-model="dialog"
    >
        <v-card flat tile :disabled="processing" class="pa-4">

            <v-progress-linear color="secondary" indeterminate stream absolute top v-if="processing"></v-progress-linear>

            <v-card-title class="d-flex py-0 my-0">
                <div class="v-card__title px-0 py-6 sm">
                    {{title}}
                </div>
                <v-spacer />
            </v-card-title>

            <v-card-text>
                <ValidationObserver 
                    ref="observer" 
                    tag="form"
                >   
                    <v-row>
                        <v-col cols="12" 
                            v-for="(field, key) in resources.otherLender.attributes"
                            :key="key"
                            class="py-0"
                        >

                            <ValidationProvider
                                v-slot="{ errors }"
                                :name="labels[key]"
                                :rules="getRules(key)"
                                :vid="key">

                                <v-text-field
                                    v-model="resources.otherLender.attributes[key]"
                                    :error-messages="errors"
                                    :label="labels[key]"></v-text-field>                                 

                            </ValidationProvider>
                            
                        </v-col>
                    </v-row>
                </ValidationObserver>
            </v-card-text>

            <v-card-actions class="pa-4 ma-2">
                <v-btn :disabled="processing || flowLoading" @click="$emit('close-modal')" :min-width="140" large depressed outlined color="secondary">
                    Back
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn :disabled="processing || flowLoading" @click="handleLender" :min-width="140" large depressed class="secondary">
                    Next
                </v-btn>
            
            </v-card-actions>

        </v-card>

    </v-dialog>
</template>
<script>
import { mapActions } from 'vuex'
import subscriptions from '~/components/shared/subscriptions'
import helpers from '~/plugins/helpers'

export default {
    name: 'QuoteToolLenderModal',
    mixins: [ subscriptions, helpers ],
    props: {
        value: {
            type: Boolean,
            default: false
        },
        flowLoading: {
            type: Boolean,
            default: false
        },
        processing: {
            type: Boolean,
            default: false
        },
        selectedLender: {
            type: Object,
            default: () => {}
        },
        title: {
            type: String,
            default: 'Other Lender'
        },
        requiredLoanAmount: {
            type: [String, Number],
            default: ""
        },
        assisters: {
            type: Array,
            default: () => []
        }
    },
    data() {
        return {
            dialog: this.value,
            activeStep: 1,
            modalSize: 1100,
            resources: {
                "otherLender": {
                    "attributes": {
                        "lender-name": "",
                        "lender-reference": ""
                    }
                },
            },
            labels: {
                "lender-name": 'Lender Name',
                "lender-reference": 'Lender Reference',
            },
            validation_rules: {
                attributes: {
                    "lender-name": "required",
                    "lender-reference": ""
                }
            },
        }
    },
    methods: {
        ...mapActions('resource-actions', [
            'submitOtherLender'
        ]),
        async handleLender () {

            try {

                const valid = await this.$refs.observer.validate()

                if(valid) {
                    this.$emit('save-other-lender', this.resources.otherLender.attributes)
                }
                  
            } catch (error) {

                console.log(error)
            }
        },
    },
    watch: {
        value (value) {
            this.dialog = value
        }
    }
}
</script>
