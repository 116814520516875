<template>
    <v-dialog
        max-width="844"
        persistent
        scrollable
        v-model="lenderSubmissionHelperDialog">
            <v-card flat>
                <v-card-title class="pb-0">
                    <v-spacer></v-spacer>
                    <v-btn icon
                        max-width="40"
                        max-height="40"
                        :style="`background-color: ${hexToRGBA(getActiveTheme.colors.secondary, 0.1)}`" 
                        @click="closeModal" fab>
                        <v-icon color="secondary">mdi-close</v-icon>
                    </v-btn>
                </v-card-title>

                <v-card-text class="pa-4 rounded-xl">
                    <v-stepper v-model="activeStep" alt-labels>

                        <v-stepper-items>
                            <v-stepper-content v-for="(step, index) in stepperData" :key="step.name" class="pa-0" :step="index + 1">

                                <v-img
                                    class="mx-auto rounded-lg"
                                    width="200"
                                    max-height="300"
                                    :lazy-src="require(`~/assets/images/helper/${step.img}.png`)"
                                    :src="require(`~/assets/images/helper/${step.img}.png`)" />

                                <div class="px-10 pt-6 text-center font-size-20 font-weight-bold">
                                    {{step.title}}
                                </div>

                                <div class="text-center px-10 pt-4">
                                    {{step.subtitle}}
                                </div>

                            </v-stepper-content>
                        </v-stepper-items>

                    </v-stepper>

                </v-card-text>

                <v-card-actions class="pb-1 justify-center">
                    <v-btn 
                        color="secondary" 
                        :min-width="140" large 
                        @click="handleNext"
                        elevation="0" nuxt > Got it
                    </v-btn>
                </v-card-actions>

                <v-card-actions class="justify-center py-2">
                    <ProgressIndicator :current="activeStep" :color="'primary'" steps="4" />
                </v-card-actions>
                
            </v-card>
    </v-dialog>
</template>

<script>
import { mapState, mapMutations, mapGetters } from 'vuex'
import ProgressIndicator from '~/components/base/ProgressIndicator2'

export default {
    components: { ProgressIndicator },
    data() {
       return {
           activeStep: 1,
           stepperData: [
               {
                   name: 'step 1',
                   title: 'Nodifi Data',
                   subtitle: "You will be able to find the relevant information relating to the applicants, previously stored in Nodifi's database here.",
                   img: 'p2'
               },
               {
                   name: 'step 2',
                   title: 'Lender Requirements',
                   subtitle: "These questions are fomatted specificially to your chosen lender. We need your help to answer, based on the Nodifi Data.",
                   img: 'p3'
               },
               {
                   name: 'step 3',
                   title: 'Easily Switch Views',
                   subtitle: "You can easily switch the view between Nodifi Data and Lender Requirements by selecting the correct option in this section.",
                   img: 'p1'
               },
               {
                   name: 'step 4',
                   title: 'Come Back Here',
                   subtitle: "You can access this tutorial anytime by clicking the Help icon here.",
                   img: 'p4'
               }
           ]
       } 
    },
    computed: {
        ...mapState('dialogs', [
            'lenderSubmissionHelperDialog'
        ]),
        ...mapGetters('themes', [
            'getActiveTheme'
        ]),
        isLastStep() {
            return this.activeStep === 4
        }
    },
    methods: {
        ...mapMutations('dialogs', [
            'toggleLenderSubmissionHelperDialog'
        ]),
        handleNext() {
            if(!this.isLastStep) {
                this.activeStep++
            } else {
                this.toggleLenderSubmissionHelperDialog(false)
                this.activeStep = 1
            }
        },
        closeModal() {
            this.toggleLenderSubmissionHelperDialog(false)
            this.activeStep = 1
        },
        hexToRGBA(hex, a = 1){
            return this.$options.filters.hexToRGBA(hex, a)
        },
    }
}
</script>
