export default {
    incomes: {
        // no income
        "12-23": {
            "no-income-reason": "What best describes the reason for no income?",
            "start-date": "When did this period of no income start?",
            "additional-notes": "Additional Notes"
        },
        // PAYG Employment 12-28 labels
        "12-28": {
            "start-date": "When did you start this job?",
            "employment-on-probation": "Are you still on probation?",
            "employment-job-status": "Employment job status",
            "job-title": "Job Title",
            "employer-name": "Employer Name",
            "abn": "Employer ABN",
            "employment-contact-person": "Employment Contact Person",
            "employment-contact-phone": "Employer Phone",
            "__employer-address": "Employer Address",
            "net-standard-pay": "What is your base income AFTER tax?",
            "is-allowances": "Do you receive additional entitlements or allowances, including overtime?",
            "income-period": "Frequency",
            "frequency": "Frequency",
            "gross-annual-income-period": "Frequency",
            "gross-annual-income": "What is your annual income BEFORE tax?",
            "overtime-pay-monthly-net": "How much do you receive in overtime?",
            "bonus-monthly-net": "How much do you receive in bonuses?",
            "commission-monthly-net": "How much do you receive in commission?",
            "work-allowance-net-monthly": "How much do you receive in other allowances?",
            "net-monthly-amount": "Net Monthly Amount"
        },
        // self employed
        "12-29": {
            "start-date": "When did you start this job?",
            "nature-of-business": "What is the nature of the business?",
            "employment-job-status": "What is the business structure?",
            "business-name": "Business Name",
            "abn": "Business ABN",
            "self-employed-gst-registered": "Is the business registered for GST",
            "self-employed-date-registered-gst": "When was it registered for GST",
            "__business-address": "Business Address",
            "income-period": "Income Period",
            "income-verification-method": "Income Verification Method",
            "reporting-period": "Reporting Period",
            "revenue": "Revenue",
            "depreciation": "Depreciation",
            "profit-before-tax-annual": "How much is your annual income <u>before tax</u>?",
            "gross-annual-income": "Gross Annual Income",
            "net-monthly-amount": "How much is your monthly income after tax?"
        },
        "default": {
            "asset-value": "Current Superannuation Balance",

            "income-situation": "Income Situation",
            "income-period": "Income Period",
            "benefit-amount": "Income Amount",
            "no-income-reason": "No Income Reason",
            "additional-notes": "Additional Notes",
            "income-additional-comments": "Additional Comments",
            "other-benefit-description": "Other Benefit Description",
            "start-date": "Start Date",
            "end-date": "End Date",
            "investment-income": "Income Amount",
            "net-income": "Income Amount",
            "net-monthly-amount": "Net Monthly Amount",
            "job-title": "Job Title",
            "employer-name": "Employer Name",
            "employment-contact-person": "Employment Contact Person",
            "abn": "ABN (manual)",
            "employment-contact-phone": "Employment Contact Number",
            "employment-job-status": "Employment Job Status",
            "employment-on-probation": "Employment On Probation",
            "estimated-term-in-months": "FE Employment Duration (months)",
            "gross-annual-income": "Gross Annual Income",
            "net-standard-pay": "Net Standard Pay",
            "overtime-pay-monthly-net": "Overtime Pay (monthly net)",
            "bonus-monthly-net": "Bonus (monthly net)",
            "commission-monthly-net": "Commission (monthly net)",
            "work-allowance-net-monthly": "Work Allowance (monthly net)",
            "business-name": "Business Name",
            "self-employed-date-registered-abn": "Date Registered ABN",
            "self-employed-gst-registered": "GST Registered",
            "nature-of-business": "Nature of Business",
            "self-employed-date-registered-gst": "Date Registered GST",
            "income-verification-method": "Income Verification Method",
            "revenue": "Revenue",
            "reporting-period": "Reporting Period",
            "depreciation": "Depreciation",
            "profit-before-tax-annual": "Profit Before Tax (Annual)",
            "__employer-address": "Employer Address",
            "__business-address": "Business Address",
            "__rental-address": "Rental Address"
        }
    }
}