<template>
    <v-dialog
        v-model="dialog"
        persistent
        scrollable
        width="870"
    >
        <v-card flat tile :class="vb.mdAndUp ? 'pa-8' : 'pa-2'" :disabled="progress">
            <v-progress-linear color="secondary" indeterminate stream absolute top v-if="progress"></v-progress-linear>

            <v-card-title class="pa-2 ma-0" :class="{'mt-n4': vb.mdAndUp}">
                <v-spacer />
                <v-icon @click="closeModal" size="20">
                    mdi-close
                </v-icon>
            </v-card-title>


            <v-card-text class="py-0 px-6 px-md-0">
                <v-stepper v-model="parentStepper" alt-labels>
                    <v-stepper-items>
                        <v-stepper-content step="1" class="pa-0">
                            <v-stepper v-model="stepper" alt-labels>
                                <v-stepper-items>
                                    <v-stepper-content v-for="(step, index) in steps" :key="index" class="pa-0" :step="index">

                                        <template v-if="step == 'income-situation'">

                                            <div class="text-center mb-5" :class="vb.mdAndUp ? 'title' : 'title-2 font-weight-bold'">
                                                What best describes this income source?
                                            </div>

                                            <v-row
                                                :justify="incomeSituationOptions.length <= 3 ? 'center' : 'start'"
                                                align="center"
                                                :style="incomeSituationOptions.length <= 3 ? 'max-width: 400px;' : 'width: 100%;'"
                                                class="rounded-lg light_gray_1 pa-2 ma-2 mx-auto">
                                                <v-col
                                                    v-for="(step, index) in incomeSituationOptions" :key="index"
                                                    cols="12" class="pa-2" :md="incomeSituationOptions.length <= 3 ? '12' : '6'">
                                                    <v-card
                                                        flat
                                                        height="100%" 
                                                        @click="selectOption(step)"
                                                        rounded="lg"
                                                        class="d-flex pa-4 align-center justify-center">
                                                        <div class="flex-options body-2 font-weight-medium">
                                                            {{ step }} Income 
                                                        </div>
                                                        <v-spacer></v-spacer>
                                                        <v-btn color="secondary" :width="86" small depressed>
                                                            Select
                                                        </v-btn>
                                                    </v-card>
                                                </v-col>
                                            </v-row>
                                        </template>

                                        <template v-else-if="step.children && step.children.length > 0" >
                                            <div class="text-center mb-5" :class="vb.mdAndUp ? 'title' : 'title-2 font-weight-bold'">
                                                What kind of income is it?
                                            </div>
                                            <v-row
                                                :justify="step.children.length <= 3 ? 'center' : 'start'" 
                                                :style="step.children.length <= 3 && vb.mdAndUp ? 'width: 55%;' : 'width: 100%;'"
                                                align="center" class="rounded-lg light_gray_1 pa-2 ma-2 mx-auto">
                                                <v-col
                                                    v-for="child in kindsOrderOrganizer(step)" :key="child.name"
                                                    cols="12" class="pa-2" :md="step.children.length <= 3 ? '12' : '6'">
                                                    <v-card
                                                        flat
                                                        height="100%" 
                                                        @click="nextChild(child)"
                                                        rounded="lg"
                                                        class="d-flex pa-4 align-center justify-center">
                                                        <div class="flex-options body-2 font-weight-medium">
                                                            {{ child.name }}
                                                        </div>
                                                        <v-spacer></v-spacer>
                                                        <v-btn color="secondary" :width="86" small depressed>
                                                            Select
                                                        </v-btn>
                                                    </v-card>
                                                </v-col>
                                            </v-row>
                                        </template>

                                        <template v-else>

                                            <!-- no income -->
                                            <ValidationObserver v-if="noIncome" :ref="`observer${index+1}`">
                                                <div v-for="(field, key) in step" :key="key">
                                                    <v-row class="justify-center align-center">
                                                        <v-col 
                                                            class="my-0 py-0"
                                                            v-for="(f, key) in field" 
                                                            :key="key"
                                                            cols="12"
                                                            :md="getFieldColWidth(f, field.length)"
                                                        >
                                                            <!-- Title -->
                                                            <div class="text-center mx-auto" :class="vb.mdAndUp ? 'title' : 'title-2 font-weight-bold'">
                                                                {{incomeLabels[f]}}
                                                            </div>

                                                            <ValidationProvider 
                                                                v-if="isVisible(f)"
                                                                :hidden="isHidden(f)"
                                                                v-slot="{ errors }" 
                                                                :name="incomeLabels[f]" 
                                                                :rules="getRules(f)">

                                                                <EmploymentHistoryPickBox
                                                                    v-if="isPickBox(f)"
                                                                    v-model="resources.incomes.attributes[f]"
                                                                    :items="getOptions(f)"
                                                                    :errors="errors"
                                                                    @select="selectPickBox"/>

                                                                <FlexDatePicker3
                                                                    v-else-if="isFieldType('date', f)"
                                                                    v-model="resources.incomes.attributes[f]"
                                                                    :min="getMinDate(f)"
                                                                    :max="getMaxDate(f)"
                                                                    delimiter="-"
                                                                    :errors="errors" />

                                                                <v-textarea 
                                                                    v-else-if="isFieldType('textarea', f)"
                                                                    rows="8"
                                                                    auto-grow
                                                                    row-height="20"
                                                                    :error-messages="errors"
                                                                    v-model="resources.incomes.attributes[f]"
                                                                    color="secondary">
                                                                </v-textarea>

                                                            </ValidationProvider>
                                                        </v-col>
                                                    </v-row>
                                                </div>
                                            </ValidationObserver>

                                            
                                            <ValidationObserver v-else :ref="`observer${index+1}`">
                                                <!-- title on each steps -->
                                                <div v-if="step.toString().includes('__reference')" class="text-center" :class="vb.mdAndUp ? 'title' : 'title-2 font-weight-bold'">
                                                    Do you have an Accountant?
                                                </div>

                                                <template v-else>
                                                    <div v-if="!noIncome" class="text-center mb-4" :class="vb.mdAndUp ? 'title' : 'title-2 font-weight-bold'">
                                                        {{ getIncomeSituation + " " + cardTitle}}
                                                    </div>
                                                </template>

                                                <div v-for="(field, key) in step" :key="key" :style="`${getFormSize(incomeFormMaxSize)};margin: auto`">
                                                    <v-row class="justify-center align-center">

                                                        <v-col 
                                                            class="my-0 py-0"
                                                            v-for="(f, key) in field" 
                                                            :key="key"
                                                            cols="12"
                                                            :md="getFieldColWidth(f, field.length)"
                                                        >
                                                            <ValidationProvider 
                                                                v-if="isVisible(f)"
                                                                :hidden="isHidden(f)"
                                                                v-slot="{ errors }" 
                                                                :name="incomeLabels[f]" 
                                                                :rules="getRules(f)">
                                                                
                                                                <ButtonSelection2 
                                                                    v-if="isSelfEmployed(f)"
                                                                    v-model="resources.incomes.attributes[f]"
                                                                    :error-messages="errors"
                                                                    :options="EmployedOptions"
                                                                    :label="incomeLabels[f]" />

                                                                <EmploymentHistoryPickBox2
                                                                    v-else-if="f == '__reference'"
                                                                    v-model="resources.incomes.attributes[f]"
                                                                    :title="incomeLabels[f]"
                                                                    :items="getOptions(f)"
                                                                    @select="selectPickBox"
                                                                    :errors="errors"/>

                                                                <EmploymentHistoryPickBox
                                                                    v-else-if="isPickBox(f)"
                                                                    v-model="resources.incomes.attributes[f]"
                                                                    :title="incomeLabels[f]"
                                                                    :items="getOptions(f)"
                                                                    :errors="errors"
                                                                    @select="selectPickBox"/>

                                                                <FlexDatePicker3
                                                                    v-else-if="isFieldType('date', f)"
                                                                    v-model="resources.incomes.attributes[f]"
                                                                    :label="incomeLabels[f]"
                                                                    :min="getMinDate(f)"
                                                                    :max="getMaxDate(f)"
                                                                    delimiter="-"
                                                                    :errors="errors" />

                                                                <!-- <ButtonSelection3 
                                                                    v-else-if="f === 'is-allowances'"
                                                                    v-model="resources.incomes.attributes[f]"
                                                                    :error-messages="errors"
                                                                    :options="getOptions(f)"
                                                                    :label="incomeLabels[f]" /> -->
                                                                <ButtonSelection3
                                                                    v-else-if="f === 'is-allowances'"
                                                                    v-model="resources.incomes.attributes['is-allowances']"
                                                                    :error-messages="errors"
                                                                    @input="selectSelection"
                                                                    :options="['Yes', 'No']"
                                                                    :label="incomeLabels[f]" />

                                                                <ButtonSelection 
                                                                    v-else-if="isBtnGroup(f)"
                                                                    v-model="resources.incomes.attributes[f]"
                                                                    :error-messages="errors"
                                                                    :options="getOptions(f)"
                                                                    :label="incomeLabels[f]" />


                                                                <AddressTool2
                                                                    v-else-if="isAddressField(f)"
                                                                    v-model="selectedAddress"
                                                                    :label="incomeLabels[f]"
                                                                    :addresses="addresses"
                                                                    :errors="errors">
                                                                </AddressTool2>

                                                                <AddressTool3
                                                                    ref="AddressTool3Component"
                                                                    v-else-if="isAddressField3(f)"
                                                                    :bus="Date.now()"
                                                                    v-model="selectedAddress"
                                                                    :label="incomeLabels[f]">
                                                                </AddressTool3>

                                                                <v-select
                                                                    v-else-if="isFieldType('picklist', f) && kindsIncomeId != '12-29'"
                                                                    :items="getOptions(f)"
                                                                    :disabled="isDisable(f)"
                                                                    :label="incomeLabels[f]"
                                                                    :error-messages="errors"
                                                                    v-model="resources.incomes.attributes[f]"
                                                                    color="secondary">
                                                                </v-select>

                                                                <v-textarea 
                                                                    v-else-if="isFieldType('textarea', f)"
                                                                    :label="incomeLabels[f]"
                                                                    rows="3"
                                                                    auto-grow
                                                                    :error-messages="errors"
                                                                    v-model="resources.incomes.attributes[f]"
                                                                    color="secondary">
                                                                </v-textarea>

                                                                <template v-else>
                                                                    <div v-if="f == '__spacer'"></div>
                                                                    <v-text-field
                                                                        :inputmode="getInputMode(f)"
                                                                        v-else
                                                                        v-model="resources.incomes.attributes[f]"
                                                                        :prefix="isFieldType('money', f) ? '$' : ''"
                                                                        :suffix="checkAttrs(f) ? 'per month' : ''"
                                                                        :error-messages="errors">
                                                                        <template v-slot:label>
                                                                            <div v-html="incomeLabels[f]">
                                                                            </div>
                                                                        </template>
                                                                    </v-text-field>
                                                                </template>
                                                                

                                                            </ValidationProvider>
                                                        </v-col>
                                                    </v-row>
                                                </div>
                                            </ValidationObserver>

                                        </template>

                                    </v-stepper-content>
                                </v-stepper-items>
                            </v-stepper>

                            <v-card-actions v-if="hideBtns" class="d-flex justify-center pt-8">
                                <v-btn
                                    large
                                    min-width="125" 
                                    max-height="50"
                                    color="secondary"
                                    class="mx-1"
                                    @click="back"
                                    outlined> Back
                                </v-btn>
                                <v-btn 
                                    v-if="hideNext"
                                    large
                                    min-width="125" 
                                    max-height="50"
                                    class="mx-1"
                                    color="secondary" 
                                    @click="next" 
                                    depressed> Next 
                                </v-btn>
                            </v-card-actions>
                        </v-stepper-content>
                        <!-- employment reference -->
                        <v-stepper-content step="2" class="pa-0">
                            <EmploymentReference @close-modal="closeModal" />
                        </v-stepper-content>
                        <!-- fo step-->
                        <v-stepper-content step="3" class="pa-0">
                            <EmploymentHistoryALE
                                :fo-resources="resources"
                                :selected-address="selectedAddress"
                                @save-and-close="saveAndClose"
                                @update-and-close="updateAndClose"
                                @back-parent-step="backParentStep" />
                        </v-stepper-content>
                    </v-stepper-items>
                </v-stepper>
            </v-card-text>
            
        </v-card>
    </v-dialog>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import {store, actions} from '~/components/v1/EmploymentHistoryEditor4/__store'
import shared from '~/components/v1/EmploymentHistoryEditor4/__shared'
import EmploymentHistoryPickBox from '~/components/v1/EmploymentHistoryEditor4/_EmploymentHistoryPickBox'
import EmploymentHistoryPickBox2 from '~/components/v1/EmploymentHistoryEditor4/_EmploymentHistoryPickBox2'
import AddressTool2 from '~/components/v1/EmploymentHistoryEditor4/_AddressTool2'
import AddressTool3 from '~/components/v1/EmploymentHistoryEditor4/_AddressTool3'
import ButtonSelection from '~/components/v1/EmploymentHistoryEditor4/_ButtonSelection'
import ButtonSelection3 from '~/components/v1/EmploymentHistoryEditor4/_ButtonSelection2'
import ButtonSelection2 from '~/components/v1/EmploymentHistoryEditor4/_ButtonSelectionSelfEmployed'
import EmploymentReference from '~/components/v1/EmploymentHistoryEditor4/_EmploymentHistoryReferences'
import EmploymentHistoryALE from '~/components/v1/EmploymentHistoryEditor4/_EmploymentHistoryALE'
import baseComponents from '~/components/base'
import formHelpers from '~/components/shared/form-helpers'
import ResourceHelpers from '~/components/shared/resource-helpers'
import validation from '~/components/shared/validation'
import moment from 'moment'

export default {

    mixins: [validation, formHelpers, shared, ResourceHelpers],

    components: {
        ...baseComponents,
        EmploymentHistoryPickBox,
        AddressTool2,
        AddressTool3,
        EmploymentReference,
        EmploymentHistoryALE,
        EmploymentHistoryPickBox2,
        ButtonSelection3,
        ButtonSelection,
        ButtonSelection2,
    },

    props: {
        dialog: {
            type: Boolean,
            default: false
        },
        fieldsData: {
            type: Object,
            default: () => {}
        }
    },

    data() {
        return {
            superannuationAsset: null,

            EmployedOptions: [
                {
                    name: "Sole Trader",
                    value: "Self-Employed - Sole-Trader"
                },
                {
                    name: "Company",
                    value: "Self-Employed - Company"
                },
                {
                    name: "Other",
                    value: "Self-Employed - Other"
                }
            ],
            readonly: {
                "net-monthly-amount": true
            },
            parentStepper: 1,
            selectedAddress: null,
            activeStep: 1,
            activeIncomeStep: 1,
            visible: {

                /**
                 * NOTE: Removed from DOM
                 * Initially all fields are in DOM
                 */ 
                'self-employed-date-registered-gst': false,
                '12-30': false,
                'employment-on-probation': false,
                'frequency': false,
                'overtime-pay-monthly-net': false,
                'bonus-monthly-net': false,
                'commission-monthly-net': false,
                'work-allowance-net-monthly': false,

            },
            hidden: { 

                // NOTE: hidden only from DOM
                "employment-job-status": true,
                
            },
            resources: {
                incomes: {
                    type: "incomes",
                    attributes: {
                        // dummy fields not in the resources
                        "is-allowances": null,
                        "gross-annual-income-period": 'Annual',
                        "frequency": "Monthly",
                        "income-situation": null,
                        "income-period": null,
                        "benefit-amount": null,
                        "no-income-reason": null,
                        "additional-notes": null,
                        "start-date": null,
                        "end-date": null,
                        "income-additional-comments": null,
                        "investment-income": null,
                        "other-benefit-description": null,
                        "abn": null,
                        "employer-name": null,
                        "employment-contact-person": null,
                        "employment-contact-phone": null,
                        "bonus-monthly-net": 0,
                        "business-name": null,
                        "commission-monthly-net": 0,
                        "depreciation": 0,
                        "employment-job-status": "Other Income", // Setting Default, required for Run-Quote
                        "employment-on-probation": null,
                        "gross-annual-income": null,
                        "income-verification-method": null,
                        "job-title": null,
                        "nature-of-business": null,
                        "net-income": null,
                        "net-monthly-amount": null,
                        "net-standard-pay": null,
                        "overtime-pay-monthly-net": 0,
                        "profit-before-tax-annual": 0,
                        "reporting-period": null,
                        "revenue": 0,
                        "same-industry": null,
                        "self-employed-date-registered-abn": null,
                        "self-employed-date-registered-gst": null,
                        "self-employed-gst-registered": null,
                        "estimated-gap-before-in-weeks": null,
                        "work-allowance-net-monthly": 0,
                        "asset-value": null,

                        "__employer-address": null,
                        "__business-address": null,
                        "__rental-address": null,
                        "__reference": null,
                        "__spacer": null,
                    }
                }
            },
            validation_rules: {
                attributes: {
                    "frequency": "string|in:Weekly,Fortnightly,Monthly,Annual",
                    "is-allowances": "string|in:Yes,No",
                    "gross-annual-income-period": "required|in:Weekly,Fortnightly,Monthly,Annual",

                    "asset-value": "required|between:0,99999999.99",
                    "income-situation": 'required|in:Current,Secondary,Previous',
                    "income-period": 'required|in:Weekly,Fortnightly,Monthly,Annual',
                    "benefit-amount": 'required|between:0,99999999.99',
                    "no-income-reason": 'required|in:Student,Unpaid Maternity Leave,Travel,Illness,Prison,Other Reason',
                    "investment-income": "required|between:0,99999999.99",
                    "additional-notes": "required|string|max:32768",
                    "income-additional-comments": "string|max:32768",
                    "other-benefit-description": "string|max:32768",
                    "start-date": "required|date_format:Y/m/d|before:now",
                    "end-date": "required|date_format:Y/m/d|before:now",
                    "net-income": "required|between:0,99999999.99",
                    "net-monthly-amount": "required|between:0,99999999.99",
                    "job-title": "required|string",
                    "employer-name": "required|string",
                    "employment-contact-person": "required|string",
                    "abn": "numeric|digits_between:11,11",
                    "employment-contact-phone": "required|digits_between:10,10",
                    "employment-job-status": "required|in:Full-Time,Part-Time,Casual,Contract,Self-Employed - Sole-Trader,Self-Employed - Company,Self-Employed - Other,Not Gainfully Employed,Other Income",
                    "employment-on-probation": "in:Yes,No",
                    "gross-annual-income": "required|between:0,99999999.99",
                    "net-standard-pay": "required|between:0,99999999.99",
                    "overtime-pay-monthly-net": "required|between:0,99999999.99",
                    "bonus-monthly-net": "required|between:0,99999999.99",
                    "commission-monthly-net": "required|between:0,99999999.99",
                    "work-allowance-net-monthly": "required|between:0,99999999.99",
                    "business-name": "required|string",
                    "self-employed-date-registered-abn": "required|date_format:Y/m/d|before:now",
                    "self-employed-gst-registered": "required|in:Yes,No",
                    "nature-of-business": "required",
                    "self-employed-date-registered-gst": "required|date_format:Y/m/d|before:now",
                    "income-verification-method": "required|in:Full Documentation,No Documentation,Self Declaration",
                    "revenue": "required|between:0,99999999.99",
                    "reporting-period":"required|in:Previous FY,Current FY YTD,Projected",
                    "depreciation": "required|between:0,99999999.99",
                    "profit-before-tax-annual": "required|between:0,99999999.99",
                    "__reference": "required|in:Yes,No",

                    "__employer-address": 'required',
                    "__business-address": 'required',
                    "__rental-address": 'required',

                    "__spacer": '',
                }
            },
            showAllowances: false,
            numpadInputModeItems: ['abn', 'employment-contact-phone', 'net-standard-pay', 'gross-annual-income', 'overtime-pay-monthly-net', 'bonus-monthly-net', 'commission-monthly-net', 'work-allowance-net-monthly', 'investment-income']
        }
    },

    computed: {
        ...mapGetters('kinds', ['getKindsIncome']),
        hideBtns() {

            if(this.noIncome && this.stepper == 0) return false

            if(this.currentStep == 'income-situation') return false

            return true
        },
        hideNext() {
            let hide = false

            if(this.currentStep.children && this.currentStep.children.length === 0) hide = true

            if(this.currentStep && this.currentStep.length === 0) hide = true

            if(this.currentStep && this.currentStep.length > 0) hide = true

            if(this.kindsIncomeId == '12-29' && this.isLastStep && !this.standalone) hide = false

            if(this.noIncome && this.isFirstStep) hide = false

            return hide
        },
        getIncomeSituation() {
            if(this.currentStep.children && this.currentStep.children.length == 0) return this.preselectIncomeSituation

            if(this.currentStep && this.currentStep.length == 0) return this.preselectIncomeSituation

            return ''
        },
        isLastStep() {
            let isLast = this.stepper === this.steps.length - 1

            // for self employed editing skip the last step
            if(this.kindsIncomeId == '12-29' && this.standalone) {
                isLast = this.stepper === this.steps.length - 2
            }

            return isLast
        },
        isFirstStep() {
            return this.stepper === 0
        },
        vb() {
            return this.$vuetify.breakpoint
        },
        preselectIncomeSituation: {
            get(){
                return store.preselectIncomeSituation
            },
            set(value){
                actions.setPreselectIncomeSituation(value)
            }
        },
        hasPreselectIncomeSituation(){
            if(this.preselectIncomeSituation){
                return true
            }
            return false
        },
        kindsIncome() {
            return store.kindsIncome
        },
        fieldIncomePeriod(){
            return this.resources.incomes.attributes['income-period']
        },
        getIncomePeriod () {
            switch (this.fieldIncomePeriod) {
                case 'Weekly': return 52
                case 'Fortnightly': return 26
                case 'Monthly': return 12
                case 'Annual': return 1
            }
        },
        isSelfEmployedAndStandalone() {
            return this.kindsIncomeId == '12-29' && !this.standalone && this.resources.incomes.attributes['__reference'] == 'Yes'
        },
        fieldEndDate() {
            return this.resources.incomes.attributes['end-date']
        },
        fieldAssetValue() {
            return this.resources.incomes.attributes['asset-value']
        },
        grossAnualPeriodField() {
            return this.resources.incomes.attributes['gross-annual-income-period']
        },
        isAllowancesField() {
            return this.resources.incomes.attributes['is-allowances']
        }
    },

    async mounted() {

        await actions.setKindsIncome(this.getKindsIncome)
        await actions.resetIncomeEditorDialog()
        
        await this.initialization()

        await this.initializeFields()

        if(this.currentStep == 'income-situation') {
            actions.setCardTitle('What best describes this income source?')
        }

    },

    methods: {
        ...mapActions('resource-actions', [
            'createIncome',
            'updateIncome',
            'createAddress',
            'updateAddress',
            'getAddress',
            'createAsset',
            'updateAsset'
        ]),
        async next() {

            // find observer of current step
            let ob = Object.keys(this.$refs).filter((ob) => ob == `observer${this.stepper+1}`)

            const valid = await this.$refs[ob[0]][0].validate()

            if(valid) {
                if(this.isLastStep) {
                    // if investment income go to another step
                    if(this.kindsIncomeId == '12-21') {
                        this.parentStepper = 3
                    } else {
                        let res = await this.save()

                        // check for saving status
                        if(res.status === 201 || res.status === 200) {
                            // check if creating self employed else close modal
                            if(this.isSelfEmployedAndStandalone) {

                                this.parentStepper = 2

                            } else {

                                await this.closeModal()

                            }
                        }
                    }

                } else {
                    actions.selectNextStep()
                }
            }

        },
        async save() {

            const { selectedAddress } = this

            store.saving = true

            if(!this.fieldEndDate && this.incomeSituation == 'Previous') {
                this.resources.incomes.attributes['end-date'] = this.getEndDatePrevious()
            }

            let payload = {
                type: 'incomes',
                attributes: this.excludeInvalidAttributes(this.resources.incomes.attributes),
                relationships: {
                    application: {
                        data: {type: "applications", id: this.appId}
                    },
                    people: this.getPeopleRelationship('incomes'),
                    businesses: this.getBusinessesRelationship('incomes'),
                    kind: {
                        data: {
                            type: 'kinds',
                            id: this.kindsIncomeId
                        }
                    }
                }
            }

            if(selectedAddress != null){
                    
                if(selectedAddress.id){

                    payload.relationships.address = {
                        data: {
                            type: 'addresses',
                            id: selectedAddress.id 
                        }
                    }

                    if(selectedAddress?.relationships)
                        selectedAddress.relationships = this.filterRelationships(selectedAddress.relationships)

                    await this.updateAddress(selectedAddress)

                } else {

                    let relationships = {
                        application: {
                            data: {type: "applications", id: this.appId}
                        }
                    }

                    this.selectedAddress.relationships = relationships

                    delete this.selectedAddress.id

                    let address = await this.createAddress(selectedAddress)

                    payload.relationships.address = {
                        data: {
                            type: 'addresses',
                            id: address.data.data.id
                        }
                    }

                }

            }

            let res = null

            if(this.resources.incomes?.id) {

                payload.id = this.resources.incomes.id 
                res = await this.updateIncome(payload)

            } else {

                res = await this.createIncome(payload)

            }

            if(res.status === 201) {

                // if superannuation save asset
                if(this.kindsIncomeId == '12-22') {
                    await this.saveAssets(res)
                }

                actions.updateResources(res.data.data)
                this.updateEntitiesAndParts()

                this.$emit('save-income', {income: res.data.data})

            } else if(res.status === 200) {

                // if superannuation save asset
                if(this.kindsIncomeId == '12-22') {
                    await this.saveAssets(res)
                }

                actions.updateResources(res.data.data)
                this.updateEntitiesAndParts()

                this.$emit('update-income', res.data.data)
                
            } else {
                console.log("error....")
            }

            store.saving = false
            return res

        },
        async saveAssets(resIncomes) {

            if(this.fieldAssetValue) {

                let assetPayload = {
                    type: 'assets',
                    attributes: {
                        "asset-value": this.fieldAssetValue
                    },
                    relationships: {
                        application: {
                            data: {type: "applications", id: this.appId}
                        },
                        people: this.getPeopleRelationship('incomes'),
                        businesses: this.getBusinessesRelationship('incomes'),
                        incomes: {
                            "data": [
                                {
                                    "type": "incomes",
                                    "id": resIncomes.data.data.id
                                }
                            ]
                        },
                        kind: {
                            data: {
                                type: 'kinds',
                                id: '12-22'
                            }
                        }
                    }
                }

                let resAssets = null

                if(this.superannuationAsset?.id) {

                    assetPayload.id = this.superannuationAsset.id 
                    resAssets = await this.updateAsset(assetPayload)

                } else {

                    resAssets = await this.createAsset(assetPayload)

                }

                return resAssets

            }

        },
        getAnualIncome(type, data) {

            let amount = data ? parseFloat(data) : 0

            switch (type) {
                case 'Monthly':
                    return amount * 12
                case 'Weekly':
                    return amount * 52
                case 'Fortnightly':
                    return amount * 26
                case 'Annual':
                    return amount * 1
                default:
                    return amount
            }
        },
        saveAndClose(res) {
            this.$emit('save-income', {income: res.data.data})
            this.closeModal()
        },
        updateAndClose(res) {
            this.$emit('update-income', res.data.data)
            this.closeModal()
        },
        selectSelection(val) {
            if(val == 'Yes') {
                this.visible['frequency'] = true
                this.visible['overtime-pay-monthly-net'] = true
                this.visible['bonus-monthly-net'] = true
                this.visible['commission-monthly-net'] = true
                this.visible['work-allowance-net-monthly'] = true

                this.showAllowances = true;

            } else {
                this.visible['frequency'] = false
                this.visible['overtime-pay-monthly-net'] = false
                this.visible['bonus-monthly-net'] = false
                this.visible['commission-monthly-net'] = false
                this.visible['work-allowance-net-monthly'] = false

                this.resources.incomes.attributes['overtime-pay-monthly-net'] = "0"
                this.resources.incomes.attributes['commission-monthly-net'] = "0"
                this.resources.incomes.attributes['bonus-monthly-net'] = "0"
                this.resources.incomes.attributes['work-allowance-net-monthly'] = "0"
                this.showAllowances = false;
            }
            this.resources.incomes.attributes['is-allowances'] = val
        },
        isSelfEmployed(f) {
            let isSelf = false

            if(f == 'employment-job-status' && this.kindsIncomeId == '12-29') {
                isSelf = true
            }

            return isSelf
        },   
        back() {
            if(this.isFirstStep)
                this.closeModal()
            else
                actions.prevStep()
        },
        backParentStep() {
            this.parentStepper = 1
        },
        async nextChild(child){
            await actions.nextStep(child)

            if(child.children.length === 0) {

                if(child.id == '12-23') {
                    actions.setNoIncome(true)
                }

                await this.initializeIncomesLogic(child)
                await this.getIncomeFieldLayout(child)
                await actions.setCurrentStep([])
               
            }
        },
        async selectPickBox(item) {
            // self employed
            if(this.kindsIncomeId == '12-29') {
                this.resources.incomes.attributes['__reference'] = item
                await this.next()
            } else {
                // no income
                this.resources.incomes.attributes['no-income-reason'] = item
                actions.setCurrentStep(["no-income-reason"])
                actions.selectNextStep()
            }
        },
        async selectOption(value) {
            this.resources.incomes.attributes['income-situation'] = value
            await actions.setPreselectIncomeSituation(value)
            await actions.setIncomeSituation()
        },
        async  initializeFields() {

            if(this.preselectIncomeSituation == 'Previous') {

                if(this.incomePrevEndDate) {
                    this.validation_rules.attributes['end-date'] = `required|date_format:Y/m/d|before:${this.incomePrevEndDate ? this.incomePrevEndDate : 'now'}`
                    this.resources.incomes.attributes['end-date'] = this.incomePrevEndDate
                }
                
            }

            if(Object.keys(this.fieldsData).length > 0) {
                
                this.resources.incomes.id = this.fieldsData.id
                this.resources.incomes.attributes = this.fieldsData.attributes
                // Include place holder fields
                this.resources.incomes.attributes['__employer-address'] = null
                this.resources.incomes.attributes['__business-address'] = null
                this.resources.incomes.attributes['__rental-address'] = null
                this.resources.incomes.attributes['__spacer'] = ''
                this.resources.incomes.attributes['frequency'] = 'Monthly'
                this.resources.incomes.attributes['gross-annual-income-period'] = 'Annual'

                let addressData = this.fieldsData.relationships.address.data

                if(addressData) {

                    let address = await this.getAddress(addressData.id)

                    if(address) this.selectedAddress = address.data.data

                    // this.addresses.forEach(address => {

                    //     if(address.id == addressData.id){
                    //         this.selectedAddress = address
                    //     }

                    // })

                }

                // get assets of incomes
                let assetsRelated = this.fieldsData.relationships.assets.data

                if(assetsRelated.length > 0) {

                    // if superannuation asset
                    if(this.kindsIncomeId == '12-22') {

                        this.assets.forEach(asset => {
                            if(asset.id == assetsRelated[0].id){

                                this.superannuationAsset = asset 

                                if(this.superannuationAsset) {
                                    this.resources.incomes.attributes['asset-value'] = this.superannuationAsset.attributes['asset-value']
                                }

                            }
                        })

                    }

                    let assetsRecord = null

                    this.assets.forEach(asset => {

                        if(asset.id == assetsRelated[0].id){
                            this.resources.assets = asset
                            assetsRecord = asset
                        }

                    })

                    if(assetsRecord) {
                        // get role selected on assets, expenses, liabilities
                        //let roles = []

                        if(assetsRecord.relationships.people.data.length === 1) {
                            actions.setPreSelectRoles([assetsRecord.relationships.people.data[0].id])
                        } else {
                            actions.setPreSelectRoles(['all'])
                        }

                        // assetsRecord.relationships.people.data.forEach(person => {
                        //     roles.push(person.id)
                        // })

                        // if(roles.length > 0) {
                        //    actions.setPreSelectRoles(roles)
                        // }

                        // get expenses related
                        let expensesRelated = assetsRecord.relationships.expenses.data

                        // get expense record related to asset
                        if(expensesRelated.length > 0) {
                            this.expenses.forEach(expense => {
                                if(expense.id == expensesRelated[0].id) {
                                    this.resources.expenses = expense
                                }
                            })
                        }

                        let liabilitiesRelated = assetsRecord.relationships.liabilities.data
                        // get liabilities related to expenses
                        if(liabilitiesRelated.length > 0) {
                            this.liabilities.forEach(liability => {
                                if(liability.id == liabilitiesRelated[0].id) {
                                    this.resources.liabilities = liability
                                }
                            })
                        }

                    }
                }

            } 

        },
        async closeModal() {
            await this.resetForm()
            await this.$emit('close-modal')
            await actions.setPreselectIncomeSituation(null)
            await actions.setCardTitle(null)
            await actions.setStandalone(false)
            await actions.setIncomeId(null)
            actions.setPreSelectRoles([])
        },
        resetForm() {
            this.visible = {}
            this.superannuationAsset = null
            this.resources =  {
                incomes: {
                    type: "incomes",
                    attributes: {
                        "asset-value": null,

                        "income-situation": null,
                        "income-period": null,
                        "benefit-amount": null,
                        "no-income-reason": null,
                        "additional-notes": null,
                        "start-date": null,
                        "end-date": null,
                        "income-additional-comments": null,
                        "investment-income": null,
                        "other-benefit-description": null,
                        "abn": null,
                        "employer-name": null,
                        "employment-contact-person": null,
                        "employment-contact-phone": null,
                        "bonus-monthly-net": 0,
                        "business-name": null,
                        "commission-monthly-net": 0,
                        "depreciation": 0,
                        "employment-job-status": "Other Income", // Setting Default, required for Run-Quote
                        "employment-on-probation": null,
                        "gross-annual-income": null,
                        "income-verification-method": null,
                        "job-title": null,
                        "nature-of-business": null,
                        "net-income": null,
                        "net-monthly-amount": null,
                        "net-standard-pay": null,
                        "overtime-pay-monthly-net": 0,
                        "profit-before-tax-annual": 0,
                        "reporting-period": null,
                        "revenue": 0,
                        "same-industry": null,
                        "self-employed-date-registered-abn": null,
                        "self-employed-date-registered-gst": null,
                        "self-employed-gst-registered": null,
                        "estimated-gap-before-in-weeks": null,
                        "work-allowance-net-monthly": 0,

                        "__employer-address": null,
                        "__business-address": null,
                        "__rental-address": null,
                        "__spacer": null,
                    }
                }
            }
            this.selectedAddress = null
        },
        getFormSize(size){
            if(size) {
                return `max-width: ${size}`
            }
            return ''
        },
        checkAttrs(attr) {
            if(this.showAllowances == true) {
                let arr = ['overtime-pay-monthly-net', 'bonus-monthly-net', 'commission-monthly-net', 'work-allowance-net-monthly'];
                if(arr.includes(attr)) {
                    return true;
                } else {
                    return false;
                }
            } else {
                return false;
            }
        },
        isNumpad(key) {
            if(this.numpadInputModeItems.includes(key)) {
                return 'decimal';
            } else {
                return 'text';
            }
        },
        getInputMode(key) {

            if( this.isFieldType('money', key) ) {
                return 'decimal'
            } 

            if( this.numpadInputModeItems.includes(key) ) {
                return 'decimal'
            } 
            
            return 'text'

        }
    },

    watch: {
        hideNext(val){
            if(val == false) {
                this.visible = {
                    'self-employed-date-registered-gst': false,
                    '12-30': false,
                    'employment-on-probation': false,
                    'frequency': false,
                    'overtime-pay-monthly-net': false,
                    'bonus-monthly-net': false,
                    'commission-monthly-net': false,
                    'work-allowance-net-monthly': false,
                }
                this.hidden = {
                    'employment-job-status': true
                }
            }
        },
        currentStep(val){
            if(val == 'income-situation') {
                actions.setCardTitle('What best describes this income source?')
            } else if(val?.children && val.children.length > 0) {
                actions.setCardTitle('What kind of income is it?')
            }
        },
        incomeSituation(val) {
            this.resources.incomes.attributes['income-situation'] = val
        },
        'resources.incomes.attributes': {
            handler(val) {
                // Compute net monthly amount
                if (val['benefit-amount']) {
                    let incomePeriod = this.getIncomePeriod ? this.getIncomePeriod : 0
                    val['net-monthly-amount'] = parseFloat((val['benefit-amount'] * incomePeriod) / 12).toFixed(2)
                }

                if (val['investment-income']) {
                    let incomePeriod = this.getIncomePeriod ? this.getIncomePeriod : 0
                    val['net-monthly-amount'] = parseFloat((val['investment-income'] * incomePeriod) / 12).toFixed(2)
                }

                if (val['net-income']) {
                    let incomePeriod = this.getIncomePeriod ? this.getIncomePeriod : 0
                    val['net-monthly-amount'] = parseFloat((val['net-income'] * incomePeriod) / 12).toFixed(2)
                }

                if (val['profit-before-tax-annual']) {
                    const depreciation = val['depreciation'] ? parseFloat(val['depreciation']) : 0
                    const pftAnual = val['profit-before-tax-annual'] ? parseFloat(val['profit-before-tax-annual']) : 0

                    val['net-monthly-amount'] = parseFloat((pftAnual + depreciation) / 12).toFixed(2)
                }

                if (val['net-standard-pay']) {
                    let incomePeriod = this.getIncomePeriod ? this.getIncomePeriod : 0

                    let overPayMontly = val['overtime-pay-monthly-net'] ? parseFloat(val['overtime-pay-monthly-net']) : 0
                    let bonusMonthly = val['bonus-monthly-net'] ? parseFloat(val['bonus-monthly-net']) : 0
                    let commissionMonthly = val['commission-monthly-net'] ? parseFloat(val['commission-monthly-net']) : 0
                    let workAllowance = val['work-allowance-net-monthly'] ? parseFloat(val['work-allowance-net-monthly']) : 0

                    const additionalIncome = ( overPayMontly + bonusMonthly + commissionMonthly + workAllowance )
                    val['net-monthly-amount'] = parseFloat(((val['net-standard-pay'] * incomePeriod) / 12) + additionalIncome).toFixed(2)
                }

                // check if date is less than 3 months
                if (val['start-date']) {

                    let startDate = moment(val['start-date'], 'YYYY-MM-DD')
                    const dif = moment().diff(startDate, 'months')

                    if (dif < 3) {
                        this.visible['employment-on-probation'] = true
                    } else {
                        this.visible['employment-on-probation'] = false
                    }

                }

                // check if 
                if (val['self-employed-gst-registered'] == 'Yes') this.visible['self-employed-date-registered-gst'] = true
                else this.visible['self-employed-date-registered-gst'] = false
            },
            deep: true
        },
        grossAnualPeriodField(val) {
            if(this.resources.incomes.attributes['gross-annual-income']) {
                this.resources.incomes.attributes['gross-annual-income'] = this.getAnualIncome(val, this.resources.incomes.attributes['gross-annual-income'])
            }
        },
        isAllowancesField(val) {
            if(val == 'Yes') {
                this.visible['frequency'] = true
                this.visible['overtime-pay-monthly-net'] = true
                this.visible['bonus-monthly-net'] = true
                this.visible['commission-monthly-net'] = true
                this.visible['work-allowance-net-monthly'] = true
            } else {
                this.visible['frequency'] = false
                this.visible['overtime-pay-monthly-net'] = false
                this.visible['bonus-monthly-net'] = false
                this.visible['commission-monthly-net'] = false
                this.visible['work-allowance-net-monthly'] = false

                this.resources.incomes.attributes['overtime-pay-monthly-net'] = "0"
                this.resources.incomes.attributes['commission-monthly-net'] = "0"
                this.resources.incomes.attributes['bonus-monthly-net'] = "0"
                this.resources.incomes.attributes['work-allowance-net-monthly'] = "0"
            }
        }
    }
}
</script>
