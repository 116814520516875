/**
 * 
 * @param {string} source  - the source string to parse
 * @param {object} values - the values from the flow (object properties detailed below)
 * 
 * values = {
 *      config: {object}, - required. the flow config for the flow
 *      route: {
 *          groupSlug: {string}, - required. group-slug from the flow route
 *          flowSlug: {string}, - required. flow-slug from the flow route
 *          applicationGuid: {string}, - required. application-guid from the flow route
 *      },
 *      data: {string, optional} - optional. required when source contains {data.*} values
 *  }
 * 
 * @returns {string} the parsed value
 */
  const flexConfigStringParser = (source, values) => {
    
    if (!source) throw `Error: expecting 'source' argument for flexConfigStringParser`
    if (!values) throw `Error: expecting 'values' argument for flexConfigStringParser`
    if (!values.config) throw `Error: expecting 'values.config' for flexConfigStringParser`
    if (!values.route) throw `Error: expecting 'values.route' for flexConfigStringParser`
    if (!values.route.groupSlug) throw `Error: expecting 'values.route.groupSlug' for flexConfigStringParser`
    if (!values.route.flowSlug) throw `Error: expecting 'values.route.flowSlug' for flexConfigStringParser`
    if (!values.route.applicationGuid) throw `Error: expecting 'values.route.applicationGuid' for flexConfigStringParser`

    let str = source

    while (str.includes('{')) {

        let unparsed = str.substring(
            str.indexOf("{") + 1, 
            str.indexOf("}")
        )
        
        /**
         * config variables
         * ----------------
         * substitutes with values that originated in the flow config
         */

        if (unparsed.startsWith('config.')) {

            let parsed = valueFromPath(unparsed.substring(7), values.config)

            if (!parsed) throw `Error: variable at path '${unparsed.substring(7)}' not found in flow config`
            
            str = str.replace(('{'+unparsed+'}'), parsed)
        } 

        /**
         * route variables
         * ---------------
         * substitutes from variables that originated in the route
         */

        else if (unparsed.startsWith('route.')) {
            switch (unparsed.substring(6)) {
                case 'group-slug':
                    str = str.replace(('{'+unparsed+'}'), values.route.groupSlug)
                    break
                case 'flow-slug':
                    str = str.replace(('{'+unparsed+'}'), values.route.flowSlug)
                    break
                case 'application-guid':
                    str = str.replace(('{'+unparsed+'}'), values.route.applicationGuid)
                    break
                default:
                    throw `Error: unsupported route variable 'route.${unparsed.substring(6)}' in flow config`

            }

        }

        /**
         * data argument variables
         * -----------------------
         * substitutes from variables that have been passed into this parsing function via data argument
         */

          else if (unparsed.startsWith('data.')) {

            if (!values.data) throw `Error: data argument is required to process string '${unparsed}'`

            let parsed = valueFromPath(unparsed.substring(5), values.data)

            if (!parsed) throw `Error: variable at path '${unparsed.substring(5)}' not found in data`
            
            str = str.replace(('{'+unparsed+'}'), parsed)

          }

        /**
         * top-depth variables
         * -------------------
         * subtitutes with the values determined by the logic here
         * (deprecated but still supported, from before route variables were introduced)
         */
        
        else {

            switch (unparsed) {
                case 'group':
                    str = str.replace(('{'+unparsed+'}'), values.route.groupSlug)
                    break
                case 'flow':
                    str = str.replace(('{'+unparsed+'}'), values.route.flowSlug)
                    break
                case 'application':
                    str = str.replace(('{'+unparsed+'}'), values.route.applicationGuid)
                    break
                default:
                    throw `Error: unsupported variable '${unparsed.substring(6)}' in flow config`
            }
        }

    }

    return str
  }

  /**
   * 
   * @param {string} path - dot syntax object path e.g. 'some.path.ends.here'
   * @param {object} obj - the object that the path will navigate
   * @returns {*} value of element found at path for object (e.g. the above path example, would return the value of 'here')
   * 
   */
  const valueFromPath = (path, obj) => {

      let s = path
      let o = obj

      /**
       * Took this from stackoverflow
       * See here for more details: https://stackoverflow.com/questions/6491463/accessing-nested-javascript-objects-and-arrays-by-string-path
       */

      s = s.replace(/\[(\w+)\]/g, '.$1') // convert indexes to properties
      s = s.replace(/^\./, '')           // strip a leading dot
      var a = s.split('.')
      for (var i = 0, n = a.length; i < n; ++i) {
          var k = a[i]
          if (k in o) {
              o = o[k]
          } else {
              return null
          }
      }

      return o;

  }
  
  export default ({ app }, inject) => {
    inject('flexConfigStringParser', flexConfigStringParser)
    inject('valueFromPath', valueFromPath)
  }
