<template>
    <div class="address-selector">

        <v-text-field 
            :type="'text'"
            :label="label"
            placeholder="Select or create address"
            :error-messages="errors"
            readonly
            append-icon="mdi-map-marker"
            @click="handleOpen"
            :value="selectedAddress"
            color="secondary">
        </v-text-field>

        <v-dialog
            v-model="dialog"
            persistent
            max-width="600"
        >
            <v-card tile flat class="rounded-lg" :disabled="disabled">
                <v-progress-linear color="secondary" indeterminate stream absolute top v-if="disabled"></v-progress-linear>
                <v-card-title>
                    <v-spacer/>
                    <v-btn icon @click="dialog = false">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-card-title>
                <v-card-text>
                    <v-card tile flat class="rounded-lg mx-auto" :width="600">
                        <template>
                            <ValidationObserver ref="observer" tag="form" :disabled="disableForm">
                                <v-container>
                                    <v-row>
                                    <v-col cols="12" class="py-0">
                                        <google-address-tool
                                            name="address"
                                            label="Address Search"
                                            v-model="fullAddress" 
                                            @change="handleAddressToolChange"
                                        ></google-address-tool>
                                    </v-col>
                                    <v-col cols="12" sm="6" md="6" 
                                            class="py-0"
                                            v-for="(val, key) in resource.address.attributes"
                                            :key="key">

                                        <ValidationProvider
                                            v-slot="{ errors }" 
                                            :name="labels[key]" 
                                            :rules="getRules(key)" :vid="key">

                                            <v-select
                                            v-if="isFieldType('picklist', key)"
                                            :items="getOptions(key)"
                                            :label="labels[key]"
                                            :error-messages="errors"
                                            v-model="resource.address.attributes[key]"
                                            color="secondary">
                                            </v-select>

                                            <v-text-field 
                                            v-else
                                            :type="'text'"
                                            :label="labels[key]"
                                            :error-messages="errors"
                                            v-model="resource.address.attributes[key]"
                                            color="secondary">
                                            </v-text-field>
                                        </ValidationProvider>

                                    </v-col>
                                    </v-row>
                                </v-container>
                            </ValidationObserver>
                        </template>

                    </v-card>
                </v-card-text>

                <v-card-actions class="pa-8 pt-0 flex-wrap">

                    <v-spacer></v-spacer>

                    <v-btn color="light_gray_2" class="px-5" outlined depressed nuxt large @click="reset">Back</v-btn> 
                    <v-btn color="secondary" class="px-5" depressed nuxt large @click="save">Save & Close</v-btn> 

                </v-card-actions>


            </v-card>
        </v-dialog>


    </div>
</template>

<script>

    import formHelpers from '~/components/shared/form-helpers.js'
    import resourceHelpers from '~/components/shared/resource-helpers'
    import validation from '~/components/shared/validation'
    import { mapActions } from 'vuex'

    import GoogleAddressTool from '~/components/base/GoogleAddressTool'

    export default {
        mixins: [validation, formHelpers, resourceHelpers],
        components: {
            GoogleAddressTool
        },
        model: {
            prop: 'modelValue',
            event: 'change'
        },
        props: {
            modelValue: {
                type: Object,
                default: () => ({})
            },
            addresses: {
                type: Array,
                default: () => [],
            },
            label: {
                type: String,
                default: ''
            },
            errors: {
                type: Array,
                default: () => [],
            }
        },
        data() {
            return {
                mode: 1, // 0 = update, 1 = create
                list: null,
                dialog: false,
                disabled: false,
                fullAddress: '',
                resource: {
                    address: {
                        type: 'addresses',
                        id: null,
                        attributes: {
                            "street-number": null,
                            "street-name": null,
                            "street-type": null,
                            "suburb": null,
                            "state": null,
                            "postcode": null
                        }
                    },
                },
                labels : {
                    "street-number": "Street Number",
                    "street-name": "Street Name",
                    "street-type": "Street Type",
                    "suburb": "Suburb",
                    "state": "State",
                    "postcode": "Postcode"
                },
                validation_rules: {
                    attributes: {
                        "street-number" : "required|string|max:40",
                        "street-name"   : "required|string|max:255",
                        "street-type"   : "required|string|max:50",
                        "suburb": "required|string|max:80",
                        "state" : "required|in:required|in:Australian Capital Territory,New South Wales,Northern Territory,Queensland,South Australia,Tasmania,Victoria,Western Australia,Outside Australia",   
                        "postcode": "required|string|max:20|exists:postcodes,value",
                    }
                },
                disableForm: false
            }
        },
        computed: {
            selectedAddress(){
                return this.getFullAddress({...this.modelValue})
            },
            btndWidth () {
                switch (this.$vuetify.breakpoint.name) {
                    case 'xs': 
                    case 'sm': return 'auto'
                    case 'lg': 
                    case 'xl':
                    case 'md': return '110'
                }
            },
        },
        mounted(){
            // console.log(this.mode)
        },
        methods: {
            ...mapActions('resource-actions', [
                'updateAddress'
            ]),
            handleOpen(){

                this.dialog = true

                // set to Update if addresses exists
                // console.log(this.addresses)
                // console.log(this.modelValue)
                if(this.addresses.length == 0){
                    this.mode = 1
                }

                // check if modelValue has ID, then address exists
                if(this.modelValue && this.modelValue.id){

                    this.mode = 0

                    delete this.modelValue.attributes['country']
                    delete this.modelValue.attributes['created-at']
                    delete this.modelValue.attributes['updated-at']

                    this.resource.address.attributes = this.modelValue.attributes
                    this.resource.address.id = this.modelValue.id

                } 

                // // 
                if(this.modelValue && !this.modelValue.id){
                    this.mode = 1
                    this.resource.address.attributes = this.modelValue.attributes
                } 

                this.disableForm = false

            },
            async save(){
                // console.log(this.mode)

                if(this.mode == 0){

                    this.disabled = true

                    const valid = await this.$refs.observer.validate();

                    if(!valid) return;

                    await this.updateAddress(this.resource.address)

                    this.$emit('change', {...this.resource.address})

                    this.dialog = false

                    // this.reset()

                    this.disabled = false
                    
                }

                if(this.mode == 1){

                    const valid = await this.$refs.observer.validate();

                    if(!valid) return;
                    
                    delete this.resource.address.id

                    this.$emit('change', {...this.resource.address})

                    this.dialog = false

                    //this.reset()

                }

            },
            reset(){

                this.dialog = false

                this.resource = {
                    address: {
                        type: 'addresses',
                        id: null,
                        attributes: {
                            "street-number": null,
                            "street-name": null,
                            "street-type": null,
                            "suburb": null,
                            "state": null,
                            "postcode": null
                        }
                    },
                }

                this.mode = 1

                /**
                 * Disabling form so parent Observer will ignore it.
                 */

                this.disableForm = true
                    
            },
            handleAddressToolChange(address, obj){

                this.resource.address.attributes = {
                    "street-number": obj["street-number"],
                    "street-name": obj["street-name"],
                    "street-type": obj["street-type"],
                    "suburb": obj["suburb"],
                    "state": obj["state"],
                    "postcode": obj["postcode"]
                }

            },
        }

    }
</script>

<style lang="scss" scoped>

    .v-input .v-input__slot {
        background: transparent;


        &::before {
            display: none;
        }
    }

    .v-label {
        left: 0px;
        right: auto;
        position: absolute;
        transform: translateY(-32px) scale(1);
        
    }

    .v-tabs .v-tab.v-tab--active::before {
        opacity: 0.04;
        background-color: transparent !important;
    }


</style>