import { render, staticRenderFns } from "./_NoQuoteTableBid.vue?vue&type=template&id=23c94f6a&scoped=true&"
import script from "./_NoQuoteTableBid.vue?vue&type=script&lang=js&"
export * from "./_NoQuoteTableBid.vue?vue&type=script&lang=js&"
import style0 from "./_NoQuoteTableBid.vue?vue&type=style&index=0&id=23c94f6a&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "23c94f6a",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VHover } from 'vuetify/lib/components/VHover';
import { VImg } from 'vuetify/lib/components/VImg';
installComponents(component, {VCard,VCardText,VCardTitle,VChip,VHover,VImg})
