import tenants from '~/config/tenants'


export default async ( context ) => {
    
    // console.log(context)
    // console.log(context.route.name)
    // console.log(context.route)
    // console.log(context.params)


    const {group_slug} = context.params

    let groupSlug = group_slug ? group_slug : 'nodifi';
    
    let currentTenant = context.store.getters['slug/getTenant']

    // Use tenant's default group if there is no group in the URL. 
    if(!group_slug) {

        let tenant = tenants.data.find( tenant => tenant.slug == currentTenant);

        if( tenant.groups && tenant.groups.length > 0 ){
    
            tenant.groups.forEach( group => {
                
                if(group.default){
                    groupSlug = group.slug
                }

            });

        }

        // Use tenant's default theme if there is no group in the URL. 
        if(tenant?.['default-theme']) {
            context.store.commit('themes/setActive', tenant['default-theme'])
        }
    }

    context.store.commit('slug/setGroupSlug', groupSlug)

    /**
     * Stop the application on error.
     */ 

    if(context.route.name === '401') {

        context.error({
            message: 'Unauthorized',
            statusCode: 401
        })

        return;
    }
    else if(context.route.name === '403') {

        context.error({
            message: 'Forbidden',
            statusCode: 403
        })

        return;
    }        
    else if(context.route.name === '404') {

        context.error({
            message: 'Not Found',
            statusCode: 404
        })

        return;
    }
    else if(context.route.name === '503') {

        context.error({
            message: 'Please check back later.',
            statusCode: 503
        })

        return;
    }

    let userSetting = context.store.getters['auth-actions/getUserSetting']

    const {flow_slug, app_guid} = context.params

    context.store.commit('slug/setFlowSlug', flow_slug)
    context.store.commit('flows/setAppGuid', app_guid)

    // if not in the flow url
    if ( !flow_slug ) {
        // reset the flow data if app id is not null
        if(context.store.state.flows.appId)
            context.store.dispatch('flows/resetFlowData')

        context.store.commit('subscriptions/setStepable', false)
    }

    // Temp Route Blocking
    let blocked = [
        'group_slug-dashboard'
    ]

    if(blocked.includes(context.route.name)) { 
        context.store.commit('auth-actions/setHasNotFound')
        context.redirect('/404')
    }

    // console.log('routeName: ', context.route.name)
    // console.log('userSetting: ', userSetting?.b2b)


    // TBD: Later b2b user 0 will have home
    if (userSetting?.b2b == 0) {
            
            /**
             * Only allowed routes for b2b = 0 users
             * 
             */

            let allowedRoutes = [
                process.env.flowRouteName,
                process.env.flowRouteSubFlowName,
                'group_slug-completed-flows-b2c-on-complete-flow-application',
                'group_slug-applications-invite-landing',
                'group_slug-applications-invite-landing-2',
                'group_slug-b2c-home',
                'account-expand',
                'report-incident',
            ]


            if ( !allowedRoutes.includes(context.route.name) ) {
                context.redirect('/401')
            }                

    }

    // logic for user settings
    let userSettingsRoute = [
        {
            'name': 'TickNFlick',
            'route-names': [
                'group_slug-tick-flick'
            ],
            'setting': 'can-select-tnf'
        },
        {
            'name': 'Quotes',
            'route-names': [
                'group_slug-quote',
                'group_slug-my-apps-quote',
                'group_slug-quote-templates-id',
                'group_slug-quote-id'
            ],
            'setting': 'can-select-quote'
        },
        {
            'name': 'Scenario',
            'route-names': [
                'group_slug-scenario'
            ],
            'setting': 'can-select-scenario'
        },
        {
            'name': 'Application',
            'route-names': [
                'group_slug-startapp'
            ],
            'setting': 'can-select-application'
        }
    ]

    // conditional logic allowing routes base on user settings
    userSettingsRoute.forEach(route => {

        if(route['route-names'].includes(context.route.name)) {

            // if setting = 0 redirect to 403
            if(!userSetting[route.setting]) {

                context.redirect('/403')

            }

        }

    })

}
