<template>
    <v-card :disabled="(isDisabled || !validComponentData) || loading" class="mx-auto" tile flat>
        <v-card-text class="pa-0 px-4 pb-6">
            <v-container pa-0 fluid>
                <v-row>
                    <v-col cols="12">
                        <ValidationObserver ref="observer" tag="form">
                            <v-card flat class="overflow-y-auto mx-auto" tile max-width="800">
                                <v-card-text class="pa-0">
                                    <v-row class="ma-0 pa-0">

                                        <v-col cols="12" class="pa-0 ma-0">
                                            <!-- Select Year -->
                                            <ValidationProvider 
                                                v-slot="{ errors }" 
                                                name="Year" 
                                                rules="required" 
                                                vid="year">
                                                <v-select
                                                    v-model="year"
                                                    :items="yearOptions"
                                                    :disabled="yearOptions.length == 0"
                                                    solo flat
                                                    :error-messages="errors"
                                                    placeholder="Year">
                                                    <template v-slot:append>
                                                        <v-icon color="red">mdi-chevron-down</v-icon>
                                                    </template>
                                                </v-select>
                                            </ValidationProvider>
                                        </v-col>

                                        <v-col cols="12" class="pa-0 ma-0">
                                            <!-- Select Manufacturer -->
                                            <ValidationProvider 
                                                v-slot="{ errors }" 
                                                name="Make" 
                                                rules="required" 
                                                vid="make">
                                                <v-select
                                                    v-model="make"
                                                    item-text="name"
                                                    :disabled="makeOptions.length == 0"
                                                    :return-object="true"
                                                    :items="makeOptions"                            
                                                    solo flat
                                                    :error-messages="errors"
                                                    placeholder="Manufacturer">
                                                    <template v-slot:append>
                                                        <v-icon color="red">mdi-chevron-down</v-icon>
                                                    </template>
                                                </v-select>
                                            </ValidationProvider>
                                        </v-col>
                                        
                                        <v-col cols="12" class="pa-0 ma-0">
                                            <!-- Select Model -->
                                            <ValidationProvider 
                                                v-slot="{ errors }" 
                                                name="Model" 
                                                rules="required" 
                                                vid="model">
                                                <v-select
                                                    v-model="model"
                                                    item-text="name"
                                                    :disabled="modelOptions.length == 0"
                                                    :return-object="true"
                                                    :items="modelOptions"
                                                    solo flat
                                                    :error-messages="errors"
                                                    placeholder="Model">
                                                    <template v-slot:append>
                                                        <v-icon color="red">mdi-chevron-down</v-icon>
                                                    </template>
                                                </v-select>
                                            </ValidationProvider>
                                        </v-col>
                                        <v-col cols="12" class="pa-0 ma-0">
                                            <!-- Select Variant -->
                                            <!-- <ValidationProvider 
                                                v-slot="{ errors }" 
                                                name="Variant" 
                                                rules="required" 
                                                vid="variant"> -->
                                                <v-select
                                                    v-model="variant"
                                                    item-text="name"
                                                    :disabled="variantOptions.length == 0"
                                                    :return-object="true"
                                                    :items="variantOptions"
                                                    solo flat
                                                    placeholder="Variant">
                                                    <template v-slot:append>
                                                        <v-icon color="red">mdi-chevron-down</v-icon>
                                                    </template>
                                                </v-select>
                                            <!-- </ValidationProvider> -->
                                        </v-col>
                                        <v-col cols="6" v-if="seriesOptions.length > 0">
                                            <!-- Select Series -->
                                            <v-select
                                                v-model="series"
                                                item-text="name"                                        
                                                :return-object="true"
                                                :items="seriesOptions"
                                                solo flat
                                                placeholder="Series">
                                                <template v-slot:append>
                                                    <v-icon color="red">mdi-chevron-down</v-icon>
                                                </template>
                                            </v-select>
                                        </v-col>
                                    </v-row>                            
                                </v-card-text>
                            </v-card>

                        </ValidationObserver>
                    </v-col>
                    
                </v-row>
            </v-container>

            <!-- This will replace Next button in StepIncrementer1  
            <Portal to="step-incrementer-next">
                <v-btn color="secondary" min-width="140" large depressed nuxt @click="selectNextStep" class="ml-3 next-btn" :disabled="!validateResource">Next</v-btn>
            </Portal>
            -->

        </v-card-text>
    </v-card>
</template>

<script>

    import helpers from '~/plugins/helpers'
    import subscriptions from '~/components/shared/subscriptions'
    import { mapActions } from 'vuex'
    import { isEmpty } from 'lodash'

    export default {
        name: 'NvicInput1',
        mixins: [ helpers, subscriptions ],
        computed: {
            validateResource() {
                let valid = true

                Object.keys(this.resources.attributes).forEach( res => {
                    if ( res === 'year-of-manufacture' )
                        this.resources.attributes[res] = this.year
                    else
                        this.resources.attributes[res] = this[res].name                    
                })

                if ( isEmpty(this.resources.attributes['year-of-manufacture']) ) {
                    valid = false
                }
                if ( isEmpty(this.resources.attributes['make']) ) {
                    valid = false
                }
                if ( isEmpty(this.resources.attributes['model']) ) {
                    valid = false
                }
                // Variant is optional...
                // if ( isEmpty(this.resources.attributes['variant']) ) {
                //     valid = false
                // }
                return valid
            }
        },
        methods: {
            ...mapActions('resource-actions', [
                'runGlassesGuides',
                'getPurchases',
                'createPurchase',
                'updatePurchases'
            ]),
            ...mapActions('flows', [
                'selectNextStep'
            ]),
            async pull() {
                
                await this.getListOfYears()
                
                let res = await this.getPurchases()
                
                if ( res.status === 200 ) {
                    this.resourceObj = res.data.data[0]
                    this.prepareResources()
                }
            },
            async push() {

                // await this.$refs.observer.reset()
                
                let { resources } = this
                // const valid = this.validateResource
                let valid = await this.customValidate()

                let res = null
                
                if ( this.resources.id ) {
                    // update
                    res = await this.updatePurchases({
                        attributes: {
                            'make': this.make.name,
                            'make-code': this.make.id,
                            'model': this.model.name,
                            'model-code': this.model.id,
                            'variant': this.variant.name,
                            'variant-code': this.variant.id,
                            'year-of-manufacture': this.year
                        },
                        id: resources.id,
                        relationships: resources.relationships,
                        type: resources.type
                    })
                }
                else {
                    // create
                    delete resources.id
                    res = await this.createPurchase(resources)
                }
                
                if(this.hardValidation) {
                    status =  valid ? 'valid' : 'dirty'
                }

                // let data = this.$options.filters.toAppDataFormat(res)

                return  { valid, status, data: {} }

            },
            async customValidate() {

                let valid = true
                let errors = await this.$refs.observer.errors

                if ( isEmpty(this.year) ) {
                    errors.year.push('Year is required')
                    valid = false
                }
                else if ( isEmpty(this.make.id) ) {
                    errors.make.push('Manufacturer is required')
                    valid = false
                }
                else if ( isEmpty(this.model.id) ) {
                    errors.model.push('Model is required')
                    valid = false
                }
                // else if ( isEmpty(this.variant.id) ) {
                //     errors.variant.push('Variant is required')
                //     valid = false
                // }

                return valid
                
            },
            prepareResources() {
                
                let payload = this.resourceObj

                // console.log('object :>> ',  payload?.attributes['year-of-manufacture'])
                
                if ( payload?.attributes['year-of-manufacture'] ) {
                    this.year = payload?.attributes['year-of-manufacture']?.toString()
                }
                
                this.fieldValues = {
                    make: payload?.attributes['make'],
                    model: payload?.attributes['model'],
                    variant: payload?.attributes['variant']
                }

                this.resources.id = payload?.id
                this.resources.relationships.application = payload?.relationships.application
                this.resources.attributes = {
                    make: payload?.attributes['make'],
                    model: payload?.attributes['model'],
                    variant: payload?.attributes['variant'],
                    'year-of-manufacture': payload?.attributes['year-of-manufacture']
                }
                
                // this.makeOptions.push([this.manufacturer])

            },
            async getListOfYears() {
                
                let config = {
                    "data": {
                        "type": "glasses-guides",
                        "attributes": {
                            "input": {
                                "end-point" : "GetListOfYears",
                                "params" : {
                                    "model-type-code": "A",
                                    "year-from": this.year,
                                    "year-to": this.year,
                                }
                            }
                        }
                    }
                }
                
                let res = await this.runGlassesGuides(config)
                
				this.yearOptions = res.data.data.attributes.output
                // localStorage.removeItem('nvic')
            },
            
            async getManufacturers() {
                
				this.makeOptions = []
                
				if(this.yearOptions.length) {
                    
                    this.make = { id: null, name: null }
					this.model = { id: null, name: null }
					this.variant = { id: null, name: null }
                    this.series = { id: null, name: null }
					
                    this.loading = true

					let config = {
						"data": {
                            "type": "glasses-guides",
                            "attributes": {
                                "input": {
                                    "end-point" : "GetListOfMakesYearFromTo",
                                    "params" : {
                                        "model-type-code": "A",
                                        "year-from": this.year,
                                        "year-to": this.year,
                                    }
                                }
                            }
                        }
					}

                    let res = await this.runGlassesGuides(config)
                    
                    this.makeOptions = res.data.data.attributes.output
                    // localStorage.removeItem('nvic')
                    this.loading = false

				}

            },

            async getModels() {
                
				this.modelOptions = [];
	            
				if(this.makeOptions.length) {

					this.model = { id: null, name: null }
					this.variant = { id: null, name: null }
                    this.series = { id: null, name: null }
                    
                    this.loading = true

					let config = {
						"data": {
                            "type": "glasses-guides",
                            "attributes": {
                                "input": {
                                    "end-point" : "GetListOfModelsYearFromTo",
                                    "params" : {
                                        "model-type-code": "A",
                                        "year-from": this.year,
                                        "year-to": this.year,
                                        "manufacturer-code": this.make.id
                                    }
                                }
                            }
                        }
					}

                    let res = await this.runGlassesGuides(config)
                    
                    this.modelOptions = res.data.data.attributes.output
                    // localStorage.removeItem('nvic')             
                    this.loading = false;

				}
					            
            },

            async getVariants() {
                
				this.variantOptions = [];
				
		        if( this.modelOptions.length > 0 ){

					this.variant = { id: null, name: null }
                    this.series = { id: null, name: null }
                    
                    this.loading = true
			
					let config = {
						"data": {
                            "type": "glasses-guides",
                            "attributes": {
                                "input": {
                                    "end-point" : "GetListOfVariants",
                                    "params" : {
                                        "model-type-code": "A",
                                        "year-from": this.year,
                                        "year-to": this.year,
                                        "year-create": this.year,
                                        "manufacturer-code": this.make.id,
                                        "family-code": this.model.id
                                    }
                                }
                            }
                        }
					}
					
					let results = this.getResults();
                    let response = this.runGlassesGuides(config)
                    
                    try {

						let responsePayloads = await Promise.all([results, response]).then(res => {
                            
                            this.variantOptions = res[1].data.data.attributes.output
                            this.loading = false
                        })
						
					} catch (error) {
						console.log('Ooops!..', error)
					}

				}
	            
            },

            async getSeries() {
                                
				this.seriesOptions = [];
		
				 if( this.variantOptions.length > 0 ){

                    this.series = { id: null, name: null }
                    this.loading = true

					let config = {
						"data": {
                            "type": "glasses-guides",
                            "attributes": {
                                "input": {
                                    "end-point" : "GetListOfSeriesYearFromTo",
                                    "params" : {
                                        "model-type-code": "A",
                                        "year-from": this.year,
                                        "year-to": this.year,
                                        "manufacturer-code": this.make.id,
                                        "family-code": this.model.id,
                                        "variant-name": this.variant.id
                                    }
                                }
                            }
                        }
					}

					let results = this.getResults()
					let response = this.runGlassesGuides(config)
					
					try {

						await Promise.all([results, response]).then(res => {
                            
							this.seriesOptions = res[1].data.data.attributes.output
                            this.loading = false;
						})
						
					} catch (error) {
						console.log('Ooops!..', error)
					}

				 }
	            
            },

            async getResults() {
                
				let config = {
					"data": {
                        "type": "glasses-guides",
                        "attributes": {
                            "input": {
                                "end-point" : "GetAutoDetailsSpeByMake",
                                "params" : {
                                    "keyword": null,
                                    "model-type-code": "A",
                                    "make": this.make.id,
                                    "model": this.model.id,
                                    "series": this.series.id,
                                    "variant-name": this.variant.id,
                                    "year-create": this.year,
                                }
                            }
                        }
                    }
				}

                let res = await this.runGlassesGuides(config)
                
                this.results = res.data.data.attributes.output
                
                // localStorage.setItem(
                //     'nvic',
                //     JSON.stringify(
                //         {
                //             endpoint: config,
                //             result: this.results
                //         }
                //     ),
                //     {'max-age': 86400}
                // )
				
            },
            
            resetAllData(){
                this.make = { id: null, name: null }
                this.model = { id: null, name: null }
                this.variant = { id: null, name: null }
                this.series = { id: null, name: null }
                this.yearOptions = []
				this.makeOptions = []
                this.modelOptions = []
                this.variantOptions = []
				this.seriesOptions = []                
            }
        },
        watch: {
            year: ['getManufacturers'],
			make: ['getModels'],
			model: ['getVariants'],
            variant: ['getSeries'],
            makeOptions(val) {
                if ( val.length > 0 ) {
                    if ( !isEmpty(this.fieldValues.make) ) {
                        let optn = this.makeOptions.find( o => {
                            return o.name === this.fieldValues.make
                        })
                        this.make = { id: optn.id, name: optn.name }
                        this.fieldValues.make = ''
                    }
                }
            },
            modelOptions(val) {
                if ( val.length > 0 ) {
                    if ( !isEmpty(this.fieldValues.model) ) {
                        let optn = this.modelOptions.find( o => {
                            return o.name === this.fieldValues.model
                        })
                        this.model = { id: optn.id, name: optn.name }
                        this.fieldValues.model = ''
                    }
                }
            },
            variantOptions(val) {
                if ( val.length > 0 ) {
                    if ( !isEmpty(this.fieldValues.variant) ) {
                        let optn = this.variantOptions.find( o => {
                            return o.name === this.fieldValues.variant
                        })
                        this.variant = { id: optn.id, name: optn.name }
                        this.fieldValues.variant = ''
                    }
                }
            },
            "validComponentData": {
                handler(val) {
                    if ( !this.validComponentData ) {

                        this.showComponentWarning()

                    }
                },
                deep: true
            }
        },
        data() {
            return {
                resources: {
                    "type": "purchases",
                    "id": null,
                    "attributes": {
                        "year-of-manufacture": '',
                        "make": '',
                        "model": '',
                        "variant": ''
                    },
                    "relationships": {
                        "application": {
                            "data": {
                                "type": "applications",
                                "id": null
                            }
                        }
                    }
                },
                resourceObj: {},
                year: null,
                make: { id: null, name: null },
                model: { id: null, name: null },
                variant: { id: null, name: null },
                series: { id: null, name: null },
                yearOptions: [],
                makeOptions: [],
                modelOptions: [],
                variantOptions: [],
                seriesOptions: [],
                loading: false,
                fieldValues: {
                    make: '',
                    model: '',
                    variant: ''
                },
                results: [],
                validComponentData: true
            }
        }
    }
</script>

<style lang="scss">


    .flex__component-NvicInput1 {

        .v-text-field__details {
            margin: 0 !important;
            padding: 0 !important;
        }

        .v-input .v-select__selections input {

            &::placeholder { 
                color: var(--v-body_text_color-base);
                opacity: 1; 
            }

            &:-ms-input-placeholder { 
                color: var(--v-body_text_color-base);
            }

            &::-ms-input-placeholder { 
                color: var(--v-body_text_color-base);
            }

        }

        .v-input.v-input--is-disabled .v-select__selections input {

            &::placeholder { 
                color: var(--v-light_gray_6-base);
                opacity: 1; 
            }

            &:-ms-input-placeholder { 
                color: var(--v-light_gray_6-base);
            }

            &::-ms-input-placeholder { 
                color: var(--v-light_gray_6-base);
            }

        }

    }

</style>