<template>
    <v-card class="mx-auto" tile flat>
        <v-card-text class="pa-4 pt-0 pa-md-10 pt-md-0 mx-2">
            <v-container pa-0 fluid>
                <ValidationObserver ref="observer" tag="form">
                    <v-row>                        
                        <v-col :cols="$vuetify.breakpoint.mdAndUp ? 6 : 12">

                            <v-row v-for="(fieldGroup, fkey) in fields2" :key="fkey">

                                <v-col
                                    cols="12"
                                    :class="$vuetify.breakpoint.mdAndUp ? 'mt-n3 mx-0 px-3' : 'mx-0 px-1'"
                                >
                                    <p class="title my-0"> {{ fieldGroup.title }} </p>
                                </v-col>

                                <v-col
                                    cols="12"
                                    :class="$vuetify.breakpoint.smAndDown ? 'px-1 pr-3 mt-n6' : 'mt-n6'"
                                    class="py-0"
                                    v-for="(field, key) in fieldGroup.items"
                                    :key="key"
                                >
                                    <ValidationProvider
                                        v-slot="{ errors }"
                                        :name="field.shortMessage | formalText"
                                        rules="max:500"
                                        :vid="field.meta"
                                    >
                                        <v-textarea
                                            v-model="resources.loan.attributes[field.meta]"
                                            hint="Regarding any information to help with the deal"
                                            auto-grow
                                            rows="10"
                                            row-height="16"
                                            :counter="wordCount(field.meta)"
                                            :error-messages="errors"
                                        >
                                        </v-textarea>
                                    </ValidationProvider>
                                </v-col>
                            </v-row>

                            <v-row>
                                
                                <v-col>
                                    <p class="title mt-4 mb-0"> Acknowledgement </p>
                                    <v-row v-for="(fieldGroup, fkey) in fields3" :key="fkey">
                                        <v-col
                                            :cols="$vuetify.breakpoint.mdAndUp ? 6 : 12"
                                            v-for="(field, key) in fieldGroup.items"
                                            :key="key">

                                            <ValidationProvider
                                                v-slot="{ errors }"
                                                :name="field.shortMessage | formalText"
                                                :rules="getRules(key)"
                                                :vid="field.meta">

                                                <p class="caption pt-2 pb-0 mb-0">{{field.label}}</p>
                                               
                                                <v-checkbox
                                                    v-model="terms[field.meta]"
                                                    :error-messages="errors"
                                                    background-color="transparent acknowledgment"
                                                    class="shrink float-left pt-4 pa-0 ma-0 ">

                                                    <template v-slot:label>
                                                        <div class="pt-16 pb-0 mb-0 float-bottom font-weight-bold">Agree</div>
                                                    </template>

                                                </v-checkbox>

                                            </ValidationProvider>

                                        </v-col>
                                    </v-row>
                                </v-col>

                            </v-row>

                        </v-col>

                        <v-col v-if="showBoxTile" :cols="$vuetify.breakpoint.mdAndUp ? 6 : 12">

                            <p class="title mt-0 mb-6"> Supporting Documents </p>
                            
                            <BoxApi
                                v-if="box"
                                :application-id="appId"
                                :box-data="box" />

                            <v-card v-else height="165px" flat class="light_gray_1 py-12">
                                <div class="text-center">
                                    <loaderImage class="mx-auto" />
                                </div>
                            </v-card>


                        </v-col>

                    </v-row>

                </ValidationObserver>
            </v-container>
        </v-card-text>

        <!-- This will replace Next button in StepIncrementer1  -->
        <Portal to="step-incrementer-next">
            <v-btn 
                v-if="authUserCanSubmitApp"
                color="secondary" 
                large depressed nuxt 
                :min-width="btnWidth" 
                @click="selectFinalStep({flowStatus: getCurrentFlowStatus.id})" 
                class="ml-3" 
                :disabled="processing">
                Submit
            </v-btn>
            <!-- Auth user not allowed to submit -->
            <div v-else></div>
        </Portal>

        <v-overlay class="ma-0 pa-0" :value="overlay" opacity=".2">
            <div class="spinner">      
                <div class="flex-brand-loader">
                    <div class="flex-brand d-block">
                        <loaderImage />
                    </div>
                </div>
            </div>
        </v-overlay>

    </v-card>
</template>

<script>

    import BoxApi from '~/components/base/BoxApi'
    import loaderImage from '~/components/base/loaderImage'

    import subscriptions from '~/components/shared/subscriptions'
    import { mapState, mapGetters, mapActions, mapMutations } from 'vuex'
    import { cloneDeep } from 'lodash'

    export default {
        name: 'AppSubmitter2',
        components: {
            BoxApi,
            loaderImage
        },
        mixins: [subscriptions],

        data(){
            return {
                assisters: [],

                overlay: false,
                validComponentData: true,
                rules: [v => (v && v.length >= 250) || 'Minimum limit of 250 characters'],
                terms: {
                    "agree-documents": "",
                    "agree-application": ""
                },
                box: null,
                resources: {
                    loan: {
                        type: "loan",
                        id: null,
                        attributes: {
                            "submission-comments": "",
                            'finance-for-business-purposes': ''
                        },
                        relationships: {
                            application: {
                                data: {type: "applications", id: null}
                            }
                        }
                    }
                },
                fields2: [
                    {
                        title: 'Submission Notes',
                        items: {
                            'submission-comments':
                                { 
                                    label: 'Regarding any information to help the deal', 
                                    meta: 'submission-comments', 
                                    type: 'textarea',
                                    shortMessage: 'Submission Notes'
                                }
                        }
                    }
                ],
                fields3: [
                    {
                        title: 'Agree',
                        items: {
                            // 'agree-documents':
                            //     { 
                            //         label: 'I understand and advised the client they will receive communication relating to electronic privacy documents', 
                            //         meta: 'agree-documents', 
                            //         type: 'checkbox',
                            //         shortMessage: 'Acknowledgement'
                            //     },
                            'agree-application':
                                { 
                                    label: 'I acknowledge the information supplied by me in this application to be true and correct to the best of my knowledge', 
                                    meta: 'agree-application', 
                                    type: 'checkbox',
                                    shortMessage: 'Acknowledgement'
                                }
                        }
                    }
                ],
                validation_rules: {
                    type: "people",
                    attributes: {
                        "submission-comments": "required|string|max:500",
                        "agree-documents": "required|is_not:false",
                        "agree-application": "required"
                    }
                }
            }
        },

        computed: {
            ...mapState({
                appId: state => state.flows.appId,
                appGuid: state => state.flows.appGuid,
                flowData: state => state.flows.flowData,
            }),
            ...mapState('statuses', [
                'stepStatuses'
            ]),
            ...mapGetters('kinds', [
                'kindsOfApplicant'
            ]),
            ...mapGetters('statuses', [
                'getCurrentFlowStatus'
            ]),
            ...mapGetters('flows', [
                'redirectPaths'
            ]),
            ...mapState(['appConfig']),
            showBoxTile(){
                return this.appConfig.myAppsTileMenu.files
            },
            processing(){
                return  this.isDisabled
            },
            btnWidth () {
                switch (this.$vuetify.breakpoint.name) {
                    case 'xs': return 0
                    case 'lg': 
                    case 'xl':
                    case 'md': 
                    case 'sm': return 140
                }
            },
            getLoanId(){
                return this.appData?.relationships?.loan?.data?.id
            }            
        },

        created() {

        },

        mounted(){
            if(!this.getLoanId){
                // Error...
                // this.validComponentData = false
            }
        },

        methods: {
            ...mapActions('resource-actions', [
                'getLoan',
                'createBox',
                'updateLoan',
                'getApplicationBox',
                'getAssisters',
                'runAssisterSubmit'
            ]),
            ...mapActions('statuses', [
                'completeFlowStatus'
            ]),
            ...mapActions('flows', [
                'selectFinalStep',
                'selectNextStep'
            ]),
            ...mapMutations('utils', [
                'toggleAlert'
            ]),

            async pull() {

                let requests = [
                    this.getLoan(this.getLoanId),
                    this.getApplicationBox(this.appId)
                ]

                Promise.all(requests).then( data => {

                    let loan = data.find( dat => {
                        return dat.data.data.type === 'loans'
                    })

                    this.resources.loan = this.resourceFieldPicker(this.resources.loan, loan.data.data)

                    let box = data.find( dat => {
                        return dat.data.data.type === 'boxes'
                    })

                    this.box = box?.data?.data || null

                    if ( !this.resources.loan.attributes['finance-for-business-purposes'] ) {
                        this.resources.loan.attributes['finance-for-business-purposes'] = this.isFinancePurpose()
                    }

                    return data

                }).catch( async error => {
                    
                    if ( !this.resources.loan )
                        this.validComponentData = false

                    if ( !this.box ) {
                        
                        let res = await this.createBox({
                            type: "boxes",
                            attributes: {
                                folder_id: "new"
                            },
                            relationships: {
                                application: {
                                    data: {
                                        type: "applications",
                                        id: this.appId
                                    }
                                }
                            }
                        })

                        if ( res.status === 201 ) {
                            this.box = res.data.data
                        }
                    }
                    return error
                })

            },
            async push() {
                
                // this.validComponentData = false
                this.overlay = true
                
                const observer = this.$refs.observer
                
                const {valid, status} = await this.validateFormData()

                let loanRes = null

                const {loan} = this.resources

                // Exclude relationships and other stuff
                let payload = {
                    type: loan.type,
                    id: loan.id,
                    attributes: this.excludeInvalids(loan.attributes, observer.fields)
                }

                payload.attributes['finance-for-business-purposes'] = this.resources.loan.attributes['finance-for-business-purposes']

                if( this.getLoanId ) {

                    loanRes = await this.updateLoan(payload)
                    
                    let validSteps = true

                    let newFlow = {
                        "type": "flow-statuses",
                        "id": this.getCurrentFlowStatus.id,
                        "attributes": {
                            "completed": 1
                        }
                    }

                    let iterations = this.stepStatuses.length

                    this.stepStatuses.forEach( (step, i) => {
                        if (--iterations) {
                            if ( step.attributes.value !== 'valid' )
                                validSteps = false
                        }                    
                    })

                    if ( validSteps && valid ) {
                        
                        // triger assister submission
                        await this.assistersSubmissions()

                        // await this.completeFlowStatus(newFlow)

                    }
                    else {
                        this.overlay = false

                        this.toggleAlert({
                            text: 'Steps are incomplete... To continue, please fill the steps below, completely:', 
                            isStepValidator: true,
                            type: 'error',
                            prominent: true,
                            dismissible: true
                        })
                    }
                    
                } else {

                    // Include relationships when creating resource

                    payload.relationships = {
                        application: {
                            data: {type: "applications", id: this.appId}
                        }
                    }

                    loanRes = this.createLoan(payload)

                }

                
                // const data = await this.appDataHelper([loanRes])

                // this.validComponentData = true

                return {valid, status}
                
            },


            async assistersSubmissions() {

                let { appGuid, assisters, flowData } = this

                try {

                    this.overlay = true

                    let assisterSubmissions = {
                        'application': appGuid, // guid
                        //'assister': assisters.length > 0 ? assisters[0].attributes['slug'] : "nodifi-referral-hub", // asssisters slug
                        'flow': flowData.attributes.slug // flow response slug 
                    }

                    let res = await this.runAssisterSubmit(assisterSubmissions)

                    // if(res.status === 422) {

                    //     // if re submitting pop up file notes
                    //     if(res.data.message.includes('Cannot re-submit the application with existing active nodifi-referral-hub assister submission.')) {
                    //         this.showFileNotes = true
                    //         this.fileNotesMessage = 'You have an active submission with this lender, please file a notes for the reason of resubmitting.'
                    //     }


                    // }

                    this.overlay = false

                } catch (err) {
                    this.overlay = false

                    console.log(err)
                }

            },
            
            isFinancePurpose() {
                if ( this.getFlowSlug.indexOf('commercial') >= 0 || this.getFlowSlug.indexOf('business') >= 0 )
                    return 'Yes'
                return 'No'
            },
            getPrimaryApplicant(data){
                
                /**
                 * 1. check if data has included
                 * 2. filter included for 'parts'
                 * 3. select parts with kinds 'applicant'
                 * 4. select person based on selected part
                 */

                if(!data.included){
                    return null;
                }

                let parts = data.included.filter(part => {
                    return part.type == 'parts'
                })

                let applicants = [];	
                let primaryApplicant = null 

                parts.forEach((part) => {
                    
                    if (!part.relationships.kind.data) {	
                        // person must have part with kind
                        return null
                    }
                    
                    if (part.relationships.kind.data.id == this.kindsOfApplicant.id) {
                        applicants.push(part);
                    }
                })

                if (applicants.length == 0) {

                    // no applicant
                    return null

                } else if (applicants.length == 1) {
                    
                    if (applicants[0].attributes.priority == "1") {

                        primaryApplicant = applicants[0];

                    } else {

                        // primary applicant must be priority 1
                        return null
                        
                    }

                } else if (applicants.length > 1) {
                    
                    let primaryApplicants = applicants.filter((applicant) => {
                        return applicant.attributes.priority == "1"
                    })
                    
                    if( primaryApplicants.length != 1) {	

                        // too many, or too few primary applicants (must be 1 and only 1)
                        return null
                        
                    } else {		

                        primaryApplicant = primaryApplicants[0];
                    }

                }

                let applicant = data.data.find( person => {
                    if(person.relationships['parent-parts'].data[0]){
                        return person.relationships['parent-parts'].data[0].id == primaryApplicant.id
                    }
                })

                // Success!
                return applicant 
            
            },

            wordCount(field = '') {
                return this.resources?.loan?.attributes?.[field]?.length <= 250 ? 250 : false
            }
        },
        watch: {
            previousBankrupt(val){

                
            },
            bankruptcyStatus(val){

                

            },
            'terms': {
                handler(val) {
                    if (val['agree-documents'] === false) {
                        val['agree-documents'] = ''
                    }
                    if (val['agree-application'] === false) {
                        val['agree-application'] = ''
                    }
                },
                deep: true
            },
            validComponentData(val){
                if(!val) {
                    this.showComponentWarning()
                }
            },
        }
    
    }
</script>

<style lang="scss">
    .v-input__slot.transparent.acknowledgment {
        height: 20px !important;
    }
</style>

