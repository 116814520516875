export default async (context) => {

    let {kinds} = context.store.state.kinds

    // Don't fetch kinds on login, quick fix...
    if(context.route.name == "login"){
        return
    }

    // Call fetch kinds once 

    if (kinds.length == 0) {

        let kinds = await context.store.dispatch(`kinds/${context.store.state.development ? 'fetchKindsLocalStorage' : 'fetchKinds'}`, {size: 500})
        // let kinds = await context.store.dispatch(`kinds/fetchKinds`, {size: 500})

        if ( kinds.status == 404 ) {

            message = 'Error requesting Kinds' 

            context.error({
                message,
                statusCode: 400
            })

            return
        }

    }

}