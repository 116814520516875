<template>
    <v-dialog
        v-model="dialog"
        persistent
        scrollable
        max-width="1124px"
    >

        <template v-slot:activator="{on}">
            <slot name="activator" v-bind:on="toggle"></slot>
        </template>

            <v-card tile flat class="rounded-lg" :disabled="saving">
            <v-progress-linear color="secondary" indeterminate stream absolute top v-if="saving"></v-progress-linear>

                <v-card-title>
                    <v-subheader>
                        <slot name="subheader"></slot>
                    </v-subheader>
                    <v-spacer/>
                    <v-btn icon @click="close" >
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-card-title>

                <v-card-text>
                    <v-carousel
                        light
                        v-model="carousel"
                        :continuous="false"
                        :show-arrows="false"
                        hide-delimiters
                        touchless
                        height="auto"
                        hide-delimiter-background>

                        <v-carousel-item>
                            <v-card class="overflow-y-auto" tile  height="100%">
                                <v-card-text class="pa-0 pa-md-8 pt-0 pt-md-0">

                                    <v-card tile flat class="rounded-lg mx-auto" width="766px">
                                        
                                        <slot name="title-1"></slot>

                                        <v-card-text>
                                            <v-card tile flat class="rounded-lg mx-auto" width="766px">

                                                <slot name="title"></slot>

                                                <v-card-text class="pa-0 py-md-8">

                                                    <v-list two-line>
                                                        <v-list-item-group class="list-item-selector" v-model="list">

                                                            <v-list-item 
                                                                        :ripple="false"
                                                                        selectable
                                                                        v-for="(option, index) in options" 
                                                                        @click.stop="handleClick(option)"
                                                                        :disabled="list == index"
                                                                        :key="index">
                                                                <v-list-item-content>

                                                                    <v-list-item-title>
                                                                        <span>{{getFullAddress(option.address)}}</span>
                                                                    </v-list-item-title>
                                                                </v-list-item-content>

                                                                <v-list-item-action>
                                                                    <v-btn :color="list == index ? 'secondary' : 'primary'" :width="btndWidth" small elevation="0" nuxt :outlined="list == index ? false : true">
                                                                        select
                                                                    </v-btn>
                                                                </v-list-item-action>

                                                            </v-list-item>

                                                        </v-list-item-group>
                                                    </v-list>

                                                </v-card-text>

                                            </v-card>
                                        </v-card-text>

                                    </v-card>
                                </v-card-text>
                            </v-card>
                        </v-carousel-item>

                        <v-carousel-item>
                            <v-card class="overflow-y-auto" tile  height="100%">
                                <v-card-text class="pa-0 pa-md-8 pt-0 pt-md-0">
                                    <v-card tile flat class="rounded-lg mx-auto" width="766px">
                                        
                                        <slot name="title-2"></slot>

                                        <v-card-text>
                                        <ValidationObserver ref="observer2" tag="form">
                                            <v-container>
                                                <v-row>

                                                <v-col cols="12" sm="6" 
                                                        class="py-0"
                                                        v-for="(val, key) in resources.occupancies.attributes"
                                                        :key="key">

                                                    <ValidationProvider 
                                                        v-if="isVisible(key)"
                                                        v-slot="{ errors }" 
                                                        :name="labels.occupancies[key]" 
                                                        :rules="getRules(key)" :vid="key">

                                                        <v-select
                                                            v-if="isFieldType('picklist', key)"
                                                            :items="getOptions(key)"
                                                            :readonly="readonly[key]"
                                                            :label="labels.occupancies[key]"
                                                            :error-messages="errors"
                                                            v-model="resources.occupancies.attributes[key]"
                                                            color="secondary"
                                                            @change="handleChange"
                                                        >
                                                        </v-select>

                                                        <flex-date-picker
                                                            v-else-if="isFieldType('date', key)"
                                                            :label="labels.occupancies[key]"
                                                            :errors="errors"
                                                            v-model="resources.occupancies.attributes[key]"
                                                        ></flex-date-picker>

                                                        <v-text-field 
                                                            v-else
                                                            :type="'text'"
                                                            :label="labels.occupancies[key]"
                                                            :error-messages="errors"
                                                            v-model="resources.occupancies.attributes[key]"
                                                            color="secondary"
                                                        >
                                                        </v-text-field>

                                                    </ValidationProvider>

                                                </v-col>

                                                </v-row>
                                            </v-container>

                                        </ValidationObserver>
                                        </v-card-text>

                                    </v-card>
                                </v-card-text>
                            </v-card>
                        </v-carousel-item>

                    </v-carousel>
                </v-card-text>

                <v-card-actions class="pa-4 pa-md-6">

                    <v-btn color="light_gray_2" class="flex-back-button" v-if="false" :min-width="140" large outlined elevation="0" nuxt @click="carousel--">Back</v-btn>
                    <v-spacer></v-spacer>
                    <v-btn color="secondary" v-if="showSave" :min-width="140" large elevation="0" nuxt @click="handleSaveClose">Save & Close</v-btn>

                </v-card-actions>

            </v-card>

    </v-dialog>
</template>

<script>

    import { mapActions, mapState, mapGetters, mapMutations } from 'vuex'
    import formHelpers from '~/components/shared/form-helpers.js'
    import resourceHelpers from '~/components/shared/resource-helpers'
    import validation from '~/components/shared/validation'
    import GoogleAddressTool from '~/components/base/GoogleAddressTool'
    import FlexDatePicker from '~/components/base/FlexDatePicker'
    import fieldOptions from '~/components/shared/field-options'
    import shared from '~/components/v1/ResidentialHistoryEditor2/__shared'

    export default {
        name: 'AddressSelectorDialog1',
        mixins: [validation, formHelpers, resourceHelpers, shared],
        components: {
            GoogleAddressTool,
            FlexDatePicker
        },
        props: {
            options: {
                type: Array,
                default: () => []
            },
        },
        data(){
            return {
                dialog: false,
                carousel: 0,
                list: null,
                saving: false,
                visible: {},
                readonly: {
                    "occupancy-situation": true
                },
                labels: {
                    occupancies: {
                        "occupancy-situation": "Active",
                        "residential-situation": "Situation",
                        "start-date": "Start Date",
                        "end-date": "End Date"
                    }
                },
                resources: {
                    occupancies: {
                        type: 'occupancies',
                        attributes: {
                            "occupancy-situation": null,
                            "residential-situation": null,
                            "start-date": null,
                            "end-date": null
                        },
                        relationships: {
                            application: {
                                data: { type: "applications", id: null } 
                            },
                            person: {
                                data: { type: "people", id: null } 
                            },
                            address: {
                                data: { type: "addresses", id: null } 
                            }
                        }
                    },
                },
                validation_rules: {
                    attributes: {
                        "residential-situation": `required|in:${fieldOptions["residential-situation"]}`,
                        "occupancy-situation": "required|in:Current,Previous",
                        "start-date": "required|date_format:d/m/Y|before:now",
                        "end-date": "required_if:occupancy-situation,Previous|date_format:d/m/Y|before:now"
                    }
                }
            }
        },
        computed: {
            ...mapState({
                appId: state => state.flows.appId,
            }),
            btndWidth () {
                switch (this.$vuetify.breakpoint.name) {
                    case 'xs': 
                    case 'sm': return 'auto'
                    case 'lg': 
                    case 'xl':
                    case 'md': return '110'
                }
            },
            hasEndDate(){
                return this.resources.occupancies.attributes['occupancy-situation'] != 'Current'
            },
            showSave(){

                if(this.entity && this.entity.type == 'businesses'){
                    return false
                }

                return this.carousel == 1

            }
        },
        mounted(){
        },
        methods: {
            ...mapActions('resource-actions', [
                'getAddress',
                'updateBusiness',
                'createOccupancy',
            ]),
            toggle(){
                this.dialog = !this.dialog
            },
            close(){
                this.reset()
                this.toggle()
            },
            async handleClick(option) {
                
                const {entity} = this
                const {occupancy, address} = option

                if(entity){

                    if(entity.type == 'businesses'){

                            await this.saveBusinessAddress(address)

                            this.close()

                    } else {

                        this.resources.occupancies.relationships = {
                            application: {data: { type: "applications", id: this.appId }},
                            address: { data: { type: "addresses", id: address.id } },
                            person: {data: {id: entity.id, type: 'people'}},
                        }

                        this.carousel++

                    }

                }


            },
            async handleSaveClose(){
                const valid = await this.$refs.observer2.validate();

                if(valid){

                    await this.save()

                    this.close()

                }

            },
            async saveBusinessAddress(address){

                const {entity} = this

                let businessReq = null;
                let data = null

                this.saving = true

                if(address.id) {

                    let payload = {
                        ...entity
                    }

                    payload.relationships = {
                            "addresses": {
                                data: [
                                    {
                                        id: address.id,
                                        type: 'addresses'
                                    }
                                ],
                                meta: {
                                    strategy: 'attach'
                                }
                            }
                        }

                    businessReq = this.updateBusiness(payload)

                    data = await this.processResponses([businessReq])
    
                    this.$emit('select', data)

                    this.saving = false


                }
                
            },
            async save() {

                const {occupancies} = this.resources

                this.saving = true
                let data = null

                let occupancyReq = await this.createOccupancy(occupancies)

                let addressReq = this.getAddress(occupancies.relationships.address.data.id)

                data = await this.processResponses([addressReq, occupancyReq])

                this.$emit('select', data)

                this.saving = false

            },
            async processResponses(args){

                try {

                    return await Promise.all(args).then(res => {
                        // add handler of requests error 
                        let items = []
                        res.forEach(data => {
                            items.push(data.data)
                        })

                        return items
                    })

                } catch (error) {

                    console.log('Ooops!..', error)

                    return {}
                }

            },
            async reset(){

                this.carousel = 0
                this.list = null

                this.resources = {
                    occupancies: {
                        type: 'occupancies',
                        attributes: {
                            "occupancy-situation": null,
                            "residential-situation": null,
                            "start-date": null,
                            "end-date": null
                        },
                        relationships: {
                            application: {
                                data: { type: "applications", id: this.appId } 
                            },
                            person: {
                                data: { type: "people", id: null } 
                            },
                            address: {
                                data: { type: "addresses", id: null } 
                            }
                        }
                    },
                }

                // Reset validation

                if(this.$refs.observer2){
                    await this.$refs.observer2.reset()
                }

            },
            handleChange(value) {
                if(value == 'Current'){
                    this.resources.occupancies.attributes['end-date'] = ''
                }
            },
            initialize(){

                    let situation = this.hasCurrentOccupancy == null ? 'Current' : 'Previous'

                    this.resources.occupancies.attributes['occupancy-situation'] = situation

                    // Prefill end-date of Previous
                    
                    if(situation == 'Previous'){

                        if(this.getMostPreviousOccupancyByDate != null){

                            let date = this.getMostPreviousOccupancyByDate.attributes["start-date"]
                            let endDate = this.$options.filters.computeDateBy({operation: 'subtract', date, count: 1})

                            this.resources.occupancies.attributes['end-date'] = endDate
                        }

                    }

                    // Prefill start-date of Current
                    
                    if(situation == 'Current'){

                        if(this.getMostRecentOccupancyByDate != null){

                            let date = this.getMostRecentOccupancyByDate.attributes["end-date"]
                            let startDate = this.$options.filters.computeDateBy({operation: 'add', date, count: 1})

                            this.resources.occupancies.attributes['start-date'] = startDate
                        }

                    }


            },
        },
        watch: {
            dialog(){
                this.initialize()
            },
        }
    }
</script>

<style lang="scss" scoped>

    .v-card__title {

        .v-subheader  {
            display: block;
            line-height: 1.4;
            height: auto;
        }
    }
</style>