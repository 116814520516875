<template>
    <v-card tile flat outlined width="100%" class="flex_base-component-InfoBox1">
        <v-card-actions>
            <slot name="avatar">
                <v-avatar color="light_gray_1" tile class="rounded-lg" v-if="!hideAvatar">
                    <img src="~/assets/images/jane.png" height="53" />
                </v-avatar>
            </slot>

            <div class="ml-4 py-1 body-1">
                <slot name="text"></slot>
            </div>

            <v-spacer></v-spacer>
            <slot name="action"></slot>

        </v-card-actions>
    </v-card>
</template>

<script>
    
    export default {
        mixins: [],
        props: {
            hideAvatar: {
                type: Boolean,
                default: false
            },
        }, 
        components: {

        },
        data(){
            return {
            }
        },
        mounted() {
        },
        computed: {

        },
        methods: {
        }
    }
</script>

<style lang="scss">

</style>
