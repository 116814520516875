import incomeKinds from '~/components/shared/income-kinds'

export default {
    data() {
        return {
            flagged: true,
        }
    },
    computed: {
        incomeSituation() {
            let items = []
            
            this.getIncomeKinds.forEach( k => {

                let foundKind = this.kindsUnformat.find( ku => {
                    if ( ku.relationships.children.data.length === 0 ) {                        
                        return ku.attributes.name === k.parent.name                    
                    }
                })

                if (foundKind) {
                    items.push(
                        {
                            text: k.name,
                            value: foundKind.id
                        }
                    )
                }
            })

            return items

        },
        getIncomeKinds() {
            return incomeKinds
        }
    },
    methods: {
        getIncomeKind(kind) {
            
            let income = this.incomeSituation.find( i => {
                return i.text === kind
            })

            return income.value

        }
    },
    watch: {
        'resourceObj.current': {
            handler(val) {
                if ( val.type === 'incomes' && this.flagged ) {
                    if (val.attributes['employment-job-status']) {

                        let foundKind = this.incomeSituation.find( i => {
                            return i.text === val.attributes['employment-job-status']
                        })
                
                        val.relationships['kind'] = {
                            data: {
                                id: foundKind.value,
                                type: 'kinds'
                            }
                        }
                    }
                }
            },
            deep: true
        },
        'resourceObj.previous': {
            handler(val) {
                if ( val.type === 'incomes' && this.flagged ) {
                    if (val.attributes['employment-job-status']) {

                        let foundKind = this.incomeSituation.find( i => {
                            return i.text === val.attributes['employment-job-status']
                        })
                
                        val.relationships['kind'] = {
                            data: {
                                id: foundKind.value,
                                type: 'kinds'
                            }
                        }
                    }
                }
            },
            deep: true
        }
    }
}