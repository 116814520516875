<template>
    <v-dialog
        persistent
        scrollable
        max-width="800"
        v-model="dialog">
        
        <v-card flat tile class="pa-4">
            <v-card-title class="d-flex" :class="vb.smAndUp ? 'font-size-24' : 'font-size-16'">
                <div class="text-h4 font-weight-bold">Submit Application to</div>
            </v-card-title>

            <v-card-text>
                <v-row class="pa-5 justify-space-between">
                    <v-col cols="12" lg="6" sm="12" @click="submitToNextStep('nodifi')" class="text-center" style="cursor:pointer;" >
                        <v-card outlined class="sa-card">
                           <v-card-text class="pa-6 sa-card-text">
                               <v-row align="center"
                                    justify="center" class="sa-card-row">
                                    <v-col>
                                        <img class="sa-img" :src="require(`~/assets/images/logo/lender-logo-nodifi.png`)" alt="">
                                        </v-col>
                                </v-row>
                                
                           </v-card-text>
                        </v-card>
                    </v-col>
                    <v-col cols="12" lg="6" sm="12" v-if="lenderObj != null" @click="submitToNextStep(lenderObj.name)" class="text-center" style="cursor:pointer">
                       <v-card outlined class="sa-card">
                           <v-card-text class="pa-6 sa-card-text">
                               <v-row align="center"
                                    justify="center" class="sa-card-row">
                                    <v-col>
                                        <img class="sa-img" :src="require(`~/assets/images/logo/cropped/${lenderObj['logo-class']}.png`)" alt="">
                                    </v-col>
                               </v-row>
                           </v-card-text>
                       </v-card>
                    </v-col>
                </v-row>
            </v-card-text>

            <v-card-actions class="px-8 pt-4 pb-6">
                <v-btn @click="closeModal" :min-width="140" large depressed outlined color="grey lighten-1">
                    Back
                </v-btn>
            </v-card-actions>
        </v-card>

        
    </v-dialog>
</template>

<script>
import subscriptions from '~/components/shared/subscriptions'
import { mapGetters, mapActions, mapState } from 'vuex';
export default {
    name: 'SubmitApplicationTo',
    mixins: [subscriptions],
    props: {
        dialog: {
            required: true,
            default: false
        },
        lenderObj: {
            required: true,
            default: null
        }
    },
    data() {
        return {
            saving: false
        }
    },
    computed: {
        vb() {
                return this.$vuetify.breakpoint
        },
        ...mapState({
            appGuid: state => state.flows.appGuid,
            appData: state => state.flows.appData
        }),
        ...mapGetters('flows', ['getSteps']),
        ...mapGetters('slug', [
            'getGroupSlug',
        ]),
        getLoanId(){
            return this.appData?.relationships?.loan?.data?.id
        }     
    },
    methods: {
        ...mapActions('resource-actions', [
            'updateLoan'
        ]),
        async submitToNextStep(lender){
            this.saving = true

            if(lender) {

                let selectedLender = lender.toLowerCase().split(' ').join('')

                let loan = {
                    type: 'loans',
                    id: this.getLoanId,
                    attributes: {
                        "selected-lender-quote-algorithm-slug": selectedLender
                    }
                }

                let res = await this.updateLoan(loan)

                if(res.status === 200) {

                    const url = `/${this.getGroupSlug}/flows/lender-submission-${selectedLender}-2/1/${this.appGuid}`

                    if(url) {
                        await window.location.replace(url)
                    }
                }

            }

            this.saving = false

        },
        closeModal() {
            this.$emit('close-modal');
        }
    }
}
</script>

<style>
/* .logo .v-image__image.v-image__image--cover {
    height: 5em !important;
    display: block !important;
    background-size: contain !important;
} */

.sa-card{
    min-height: 180px;
    height: 180px;
}

.sa-card-text, .sa-card-row{
    height: 100%;
}

.logo .v-image__image--contain {
    background-size: 70% !important;
}

.sa-img {
    height: auto;
    width: 175px;
    display: block;
    margin: 0 auto;
}
</style>