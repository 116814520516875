import { datadogRum } from '@datadog/browser-rum';

if(process.env.envNC == 'production') {

    datadogRum.init({
        applicationId: 'bf704694-1381-40b1-8c15-053df43c86e9',
        clientToken: 'pub9cb1f5232d1e2e6051616779a8b881f5',
        site: 'datadoghq.com',
        service:'nodifi',
        env:'production',
        version: process.env.envCommitHash,
        sampleRate: 100,
        sessionReplaySampleRate: 100,
        trackInteractions: true,
        trackUserInteractions: true,
        trackResources: true,
        trackLongTasks: true,
        defaultPrivacyLevel:'mask-user-input'});

    datadogRum.startSessionReplayRecording();

}
