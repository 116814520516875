var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"mx-auto",attrs:{"tile":"","flat":""}},[_c('v-card-text',{staticClass:"pa-4 pt-0 pa-md-10 pt-md-0"},[(true)?_c('ValidationObserver',{ref:"observer",attrs:{"tag":"form"}},[_c('div',{staticClass:"flex-form"},[_c('div',{staticClass:"flex-field"},[_c('ValidationProvider',{attrs:{"name":_vm.labels['body-type'],"rules":_vm.getRules('body-type'),"vid":'body-type'},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"items":_vm.options['body-type'],"error-messages":errors,"color":"secondary","label":_vm.labels['body-type']},model:{value:(_vm.resources.purchases.attributes['body-type']),callback:function ($$v) {_vm.$set(_vm.resources.purchases.attributes, 'body-type', $$v)},expression:"resources.purchases.attributes['body-type']"}})]}}],null,false,1712151538)})],1),_vm._v(" "),_c('div',{staticClass:"flex-field"},[_c('ValidationProvider',{attrs:{"name":_vm.labels['sale-type'],"rules":_vm.getRules('sale-type'),"vid":'sale-type'},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"items":_vm.options['sale-type'],"error-messages":errors,"color":"secondary","label":_vm.labels['sale-type']},model:{value:(_vm.resources.loans.attributes['sale-type']),callback:function ($$v) {_vm.$set(_vm.resources.loans.attributes, 'sale-type', $$v)},expression:"resources.loans.attributes['sale-type']"}})]}}],null,false,3733495422)})],1),_vm._v(" "),_c('div',{staticClass:"flex-field"},[(_vm.vendorUnknown)?_c('ValidationProvider',{attrs:{"name":_vm.labels['lvr-band'],"rules":_vm.getRules('lvr-band'),"vid":'lvr-band'},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"items":_vm.options['lvr-band'],"error-messages":errors,"color":"secondary","label":_vm.labels['lvr-band']},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('label',{attrs:{"title":_vm.labels['lvr-band']}},[_vm._v(_vm._s(_vm.labels['lvr-band']))])]},proxy:true}],null,true),model:{value:(_vm.resources.purchases.attributes['lvr-band']),callback:function ($$v) {_vm.$set(_vm.resources.purchases.attributes, 'lvr-band', $$v)},expression:"resources.purchases.attributes['lvr-band']"}})]}}],null,false,260885498)}):_vm._e()],1),_vm._v(" "),_c('div',{staticClass:"flex-field"},[_c('ValidationProvider',{attrs:{"name":_vm.labels['asset-subtype'],"rules":_vm.getRules('asset-subtype'),"vid":'asset-subtype'},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"items":_vm.options['asset-subtype'],"error-messages":errors,"color":"secondary","label":_vm.labels['asset-subtype']},model:{value:(_vm.resources.purchases.attributes['asset-subtype']),callback:function ($$v) {_vm.$set(_vm.resources.purchases.attributes, 'asset-subtype', $$v)},expression:"resources.purchases.attributes['asset-subtype']"}})]}}],null,false,2653456118)})],1),_vm._v(" "),_c('div',{staticClass:"flex-field"}),_vm._v(" "),_c('div',{staticClass:"flex-field"}),_vm._v(" "),_c('div',{staticClass:"flex-field"})])]):_c('v-alert',{attrs:{"dense":"","outlined":"","type":"info"}},[_c('div',[_vm._v("Not Applicable")])])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }