<template>
  <v-card class="mx-auto" tile flat>
      <v-card-text class="pa-4 pt-0 pa-md-10 pt-md-0">

          <v-row v-if="!isSingleColFields">
              <v-col  cols="12" sm="6" class="align-center">
                      <!-- spacer -->
              </v-col>
              <v-col  cols="12" sm="6">
                  <div class="flex-form">
                      <div class="flex-field">
                          <div class="body-1 font-weight-medium">Applicant 1</div>
                      </div>
                      <div class="flex-field" v-if="isJoint">
                          <div class="body-1 font-weight-medium">Applicant 2</div>
                      </div>
                  </div>
              </v-col>
          </v-row>

          <ValidationObserver ref="observer" tag="form">
            <v-row>
              <v-col>
                <div class="flex-field">
                    <h2>Declarations</h2>
                </div>
              </v-col>
            </v-row>

              <v-row v-for="(value, key) in labels.declarations" :key="key">
                <v-col cols="12" sm="6" class="align-center">
                  <div class="body-1 font-weight-medium" v-html="value.content"></div>
                </v-col>

                <v-col cols="12" sm="6">
                  <div class="flex-form">
                    <div
                      v-for="(person, index) in resources.people"
                      class="flex-field pb-4"
                    >
                      <div :key="`${key}-${index}`">
                        <ValidationProvider
                          v-slot="{ errors }"
                          name="Declaration"
                          :rules="getRules(key)"
                          :vid="`${key}-${index}`"
                        >
                        <div class="flex-field" v-if="isSingleColFields">
                              <div class="body-1 font-weight-medium">Applicant {{index + 1}}</div>
                          </div>
                          <v-checkbox
                            :error-messages="errors"
                            v-model="person.attributes[key]"
                            color="secondary"
                            label="Agree"
                          >
                          </v-checkbox>
                                        </ValidationProvider>
                                    </div>
                                </div>
                        </div>



                        </v-col>
                    </v-row>


        <v-row>
                <v-col class="py-0 px-2">
                    <v-alert dense outlined type="error" v-if="errors.length > 0">
                        <div v-for="msg in errors" v-html="msg"></div>
                    </v-alert>
                </v-col>
              </v-row>
              <v-row>
              <v-col>
                <div class="flex-field">
                    <h2>Significant Planned Changes</h2>
                </div>
              </v-col>
            </v-row>
              <v-row v-for="(value, key) in labels.significantPlannedChanges" :key="key">
          <v-col cols="12" sm="6" class="align-center">
            <div class="body-1 font-weight-medium">{{ value }}</div>
          </v-col>

          <v-col cols="12" sm="6">
            <div class="flex-form">
              <div
                v-for="(person, index) in resources.people"
                class="flex-field pb-4"
              >
                <div :key="`${key}-${index}`">
                  <ValidationProvider
                    v-slot="{ errors }"
                    :name="labels.significantPlannedChanges[key]"
                    v-if="isIncluded(key)"
                    :rules="getRules(key)"
                    :vid="`${key}-${index}`"
                  >
                  <div class="flex-field" v-if="isSingleColFields">
                        <div class="body-1 font-weight-medium">Applicant {{index + 1}}</div>
                    </div>
                    <v-checkbox
                      :error-messages="errors"
                      v-model="person.attributes[key]"
                      color="secondary"
                      :label="person.attributes[key] ? 'Yes' : 'No'"
                    >
                    </v-checkbox>
                  </ValidationProvider>
                </div>
              </div>
            </div>
          </v-col>
        </v-row>
          </ValidationObserver>

      </v-card-text>

  </v-card>
</template>

<script>

  import { mapActions, mapState } from 'vuex'
  import helpers from '~/plugins/helpers'
  import subscriptions from '~/components/shared/subscriptions'
  import fieldOptions from '~/components/shared/field-options'


  export default {
      components: { },

      mixins: [subscriptions, helpers],

      data() {
          return {
              resources: {
                  people: [
                      {
                          type: 'people',
                          id: null,
                          attributes: {
                              //formFields
                          }
                      }
                  ]
              },
              formFields: {
              declarations: [
                  "lender-wisr-meets-eligibility",
                  "lender-wisr-privacy-consent",
                  "lender-wisr-access-seeker-credit-report-consent",
                  "lender-wisr-legal-authority-to-submit",
                  "lender-wisr-identity-check-consent",
                  "lender-wisr-electronic-communications-consent",
                  "lender-wisr-hard-credit-enquiry-consent",
                ],

                significantPlannedChanges: [
                  "lender-wisr-has-significant-plan",
                  "lender-wisr-expense-increase-short-term",
                  "lender-wisr-expense-increase-long-term",
                  "lender-wisr-income-decrease-short-term",
                  "lender-wisr-income-decrease-long-term",
                ],
              },
              labels: {
                declarations: {},
                significantPlannedChanges: {
                  "lender-wisr-has-significant-plan":
                    "Any Foreseeable changes",
                  "lender-wisr-expense-increase-short-term":
                    "Short Term Expense Increase",
                  "lender-wisr-expense-increase-long-term":
                    "Long Term Expense Increase",
                  "lender-wisr-income-decrease-short-term":
                    "Short Term Expense Decrease",
                  "lender-wisr-income-decrease-long-term": "Long Term Expense Decrease",
                },
              },
              validation_rules: {
                  type: "people",
                  attributes: {
                  }
              },
              included: {
              },
              prefix: {
              },
              fieldLayout: [
              ],
              readonly: {},

              commitments: [],
              terms: [],
              toAddCommitments: {},
              errors: []
          }
      },
      computed: {
          ...mapState('flows', [
              'appId',
              'loanData',
              'calculation',
              'termGroup'
          ]),
          getLoanId(){
              return this.appData.relationships.loan.data.id
          },
          isJoint(){
              return this.calculation['application-type'].includes('Consumer - Joint')
          },
          vb(){
              return this.$vuetify.breakpoint
          },
          isSingleColFields(){
              return this.vb.width < 1526
          },
          termIds() {
            return this.terms.map((item) => item.id);
          },

          peopleIds() {
            return this.resources.people.map((item) => item.id);
          },

          termAttributeSlugs() {
            return this.terms.reduce((prev, curr) => {
              prev[curr.id] = curr.attributes.slug;

              return prev;
            }, {});
          },

          hasCommittedTerms() {
            return this.commitments.some((item) => {
              const committerTermIds = item.relationships.terms.data.map(
                (item) => item.id
              );

              return this.termIds.some((id) => committerTermIds.includes(id));
            });
          },
      },
      methods: {
          ...mapActions('resource-actions', [
            "getPeople",
            "updatePerson",
            "getTerms",
            "createCommitments",
            "setCommitments",
          ]),
          async pull() {
      try {
        let peoplePS = this.getPeople({ include: "parent-parts" });

        let commitmentPS = this.$api.get(
          `applications/${this.appId}/commitments`
        );

        let termsPS = this.getTerms(this.termGroup);

        const [peopleDataRes, commitmentRes, termsRes] = await Promise.all([
          peoplePS,

          commitmentPS,

          termsPS,
        ]);

        this.commitments = commitmentRes?.data?.data;

        this.terms = termsRes?.data?.data;

        if (peopleDataRes.data.data && peopleDataRes.data.data.length) {
          let primaryApplicantId = this.calculation["primary-applicant-id"];

          let secondaryApplicantId = null;

          // Get the secondary applicant id

          if (this.isJoint && peopleDataRes.data.included) {
            peopleDataRes.data.included.forEach((part) => {
              if (part.attributes.priority == 2) {
                secondaryApplicantId = part.relationships.child.data.id;
              }
            });
          }

          // Get the primary & secondary applicant

          peopleDataRes.data.data.forEach((person) => {
            if (person.id == primaryApplicantId) {
              this.resources.people[0] = this.filterFields(person);
            }

            if (person.id == secondaryApplicantId) {
              let secondary = this.filterFields(person);

              this.resources.people.push(secondary);
            }
          });
        }

        if (this.terms && this.terms.length) {
          this.terms.forEach((term) => {
            const { attributes } = term;

            this.$set(this.labels.declarations, attributes.slug, term.attributes)
          });
        }


        if (this.hasCommittedTerms) {
          this.terms.forEach((term) => {
            this.commitments.forEach((commitment) => {
              const {
                "committed-people": committedPeople,
                terms: committedTerms,
              } = commitment.relationships;

              const isCommitted = committedTerms.data.some(
                (item) => item.id == term.id
              );

              if (isCommitted) {
                this.resources.people.forEach((people) => {
                  const isPeopleCommitted = committedPeople.data.some(
                    (item) => item.id == people.id
                  );

                  if (isPeopleCommitted) {

                    people.attributes[term.attributes.slug] = true;
                    const toAdd = this.toAddCommitments[people.id]
                    const index = toAdd && toAdd.indexOf(term.id)
                    if (index > -1) {
                      this.toAddCommitments[people.id].splice(index, 1)
                    }
                  } else {
                    if (people.attributes[term.attributes.slug] === undefined) {
                      people.attributes[term.attributes.slug] = false;

                      !this.toAddCommitments[people.id] &&
                        (this.toAddCommitments[people.id] = []);

                      this.toAddCommitments[people.id].push(term.id);
                    }
                  }
                });
              } else {
                this.resources.people.forEach((people) => {
                  if (people.attributes[term.attributes.slug] === undefined) {
                    people.attributes[term.attributes.slug] = false;

                    !this.toAddCommitments[people.id] &&
                      (this.toAddCommitments[people.id] = []);

                    this.toAddCommitments[people.id].push(term.id);
                  }
                });
              }
            });
          });
        } else {

          this.toAddCommitments = this.resources.people.reduce((prev, curr) => {
            prev[curr.id] = this.termIds;

            return prev;
          }, {});

        }
      } catch (error) {
        console.log(error);
      }
    },

    async push() {
      try {
        const keys = Object.keys(this.labels.declarations)
        this.resources.people.forEach((people) => {
          Object.keys(people.attributes).forEach((key) => {

            !people.attributes[key] && (people.attributes[key] = keys.includes(key) ? "" : false);

          });
        });

        const valid = await this.$refs.observer.validate();


        if (valid) {

          const observer = this.$refs.observer;

          const { people } = this.resources;

          let promises = [];

          promises.push(this.updatePerson(people[0]));

          if (this.isJoint) {
            promises.push(this.updatePerson(people[1]));
          }

          const incompleteDeclarations = !this.resources.people.every(people => {
            return keys.every(key => people.attributes[key])
          })

          this.errors = []
          if (incompleteDeclarations) {
            this.errors.push('Please check the terms to proceed')
            await Promise.all(promises);
            return {valid: false, status: 'dirty'}
          } else {
            promises.push(this.submitCommitments());
          }



          await Promise.all(promises);

          const { valid, status } = await this.validateFormData();

          return { valid, status };

        }

        return {valid: false, status: 'dirty'}
      } catch (error) {
        console.log(error);

        return { valid: false, status: "dirty" };
      }
    },
    async submitCommitments() {
      const promises = [];

      this.resources.people.forEach((people) => {
        const termIds = this.toAddCommitments[people.id];

          const terms = this.terms.reduce((prev, curr) => {

              if (people.attributes[curr.attributes.slug] && termIds && termIds.includes(curr.id)) {

                  prev.push({
                    id: curr.id,
                    type: "terms",
                  })
              }

              return prev
              }, [])
            let payload = {
              type: "commitments",

              relationships: {
                application: {
                  data: {
                    type: "applications",

                    id: this.appId,
                  },
                },

                "committed-users": {
                  data: [
                    {
                      type: "users",

                      id: this.appData.relationships.owner?.data?.id,
                    },
                  ],
                },

                "committed-people": {
                  data: [
                    {
                      id: people.id,

                      type: "people",
                    },
                  ],
                },

                terms: {
                  data: terms
                },
              },
            };


            if (terms.length) {
              promises.push(this.createCommitments(payload));
            }

      });

      return promises;
    },
    filterFields(data, type = "significantPlannedChanges") {
      let attributes = {};

      this.formFields[type].forEach((field) => {

        if (data.attributes.hasOwnProperty(field)) {

          attributes[field] = data.attributes[field];
        }
      });

      return {
        type: data.type,

        id: data.id,

        attributes,
      };
    },

          isIncluded(field){

              const {included} = this

              if(included.hasOwnProperty(field)) {
                  return included[field]
              }

              return true
          },
          isReadOnly(key){

              const {readonly} = this

              if(readonly.hasOwnProperty(key)){
                  return readonly[key]
              }

              return false

          },
          isNumber(key){
              return [
              ].includes(key)
          },
          getLabel(index) {
              if(this.isSingleColFields) {
                  return `Applicant ${index + 1}`
              }
              return ''
          },
          isMultiSelectType(key) {
              return [
                  "lender-pepper-loan-looking-for"
              ].includes(key)
          }
      },
      watch: {

      }
  }
</script>

<style lang="scss" scoped>

</style>


