<template>
    <v-card class="mx-auto" tile flat>
        <v-card-text class="pa-4 pt-0 pa-md-10 pt-md-0">
            <ValidationObserver ref="observer" tag="form">
                   <div class="flex-form">

                            <div class="flex-field">
                                <ValidationProvider
                                    v-slot="{ errors }"
                                    :name="labels['lender-pepper-risk-tier']"
                                    :rules="getRules('lender-pepper-risk-tier')"
                                    :vid="'lender-pepper-risk-tier'">
                                    <v-select
                                        v-model="resources.loan.attributes['lender-pepper-risk-tier']"
                                        :items="getOptions('lender-pepper-risk-tier')"
                                        :error-messages="errors"
                                        color="secondary"
                                        :label="labels['lender-pepper-risk-tier']"
                                    ></v-select>
                                </ValidationProvider>
                            </div>
                            <div class="flex-field">
                                <ValidationProvider
                                    v-slot="{ errors }"
                                    :name="labels['applicationType']"
                                    :rules="getRules('applicationType')"
                                    :vid="'applicationType'">
                                    <v-select
                                        v-model="resources.loan.attributes.lender.pepper.applicationType"
                                        :items="getOptions('applicationType')"
                                        :error-messages="errors"
                                        color="secondary"
                                        :label="labels['applicationType']"
                                    ></v-select>
                                </ValidationProvider>
                            </div>
                            <div class="flex-field"></div>
                            <div class="flex-field"></div>
                    </div>
<!-- 
                    <div class="body-1 font-weight-bold mt-6 mb-6">Structure Details</div>

                   <div class="flex-form">

                        <div class="flex-field">
                            <ValidationProvider
                                v-slot="{ errors }"
                                :name="labels['structured-payment-amount']"
                                :rules="getRules('structured-payment-amount')"
                                :vid="'structured-payment-amount'">
                                <NodifiMoney
                                        v-model="resources.loan.attributes['structured-payment-amount']"
                                        :label="labels['structured-payment-amount']"
                                        :errors="errors" />
                            </ValidationProvider>
                        </div>
                        <div class="flex-field">
                            <ValidationProvider
                                v-if="isIncluded('structured-payment-month')"
                                v-slot="{ errors }"
                                :name="labels['structured-payment-month']"
                                :rules="getRules('structured-payment-month')"
                                :vid="'structured-payment-month'">
                                <v-select
                                    v-model="resources.loan.attributes['structured-payment-month']"
                                    :items="getOptions('structured-payment-month')"
                                    :error-messages="errors"
                                    color="secondary"
                                    :label="labels['structured-payment-month']"
                                ></v-select>
                            </ValidationProvider>
                        </div>
                        <div class="flex-field">
                            <ValidationProvider
                                v-slot="{ errors }"
                                :name="labels['customer-discount-rate']"
                                :rules="getRules('customer-discount-rate')"
                                :vid="'customer-discount-rate'">
                                <v-text-field 
                                    v-model="resources.loan.attributes['customer-discount-rate']"
                                    :label="labels['customer-discount-rate']"
                                    :error-messages="errors"
                                    suffix="%"
                                    flat>
                                </v-text-field>
                            </ValidationProvider>
                        </div>
                        <div class="flex-field">
                            <ValidationProvider
                                v-slot="{ errors }"
                                :name="labels['requested-balloon']"
                                :rules="getRules('requested-balloon')"
                                :vid="'requested-balloon'">
                                <NodifiMoney
                                        v-model="resources.loan.attributes['requested-balloon']"
                                        :label="labels['requested-balloon']"
                                        :errors="errors" />
                            </ValidationProvider>
                        </div>
                    </div> -->
            </ValidationObserver>

        </v-card-text>
    </v-card>
</template>

<script>
import { mapActions, mapState, mapMutations } from 'vuex'
import helpers from '~/plugins/helpers'
import subscriptions from '~/components/shared/subscriptions'
import ButtonGroup1 from '~/components/base/ButtonGroup1'
import NodifiMoney from '~/components/base/NodifiMoney'

export default {
    name: 'LenderPepperCustomizations1',
    components: { ButtonGroup1 , NodifiMoney},
    mixins: [subscriptions, helpers],
    data() {
        return {
            resources: {
                loan: {
                    type: 'loans',
                    id: null,
                    attributes: {
                        "lender-pepper-risk-tier": "",
                        "structured-payment-amount": "",
                        "structured-payment-month": "",
                        "customer-discount-rate": "",
                        "requested-balloon": "",
                        lender: {
                            pepper: {
                                applicationType: null
                            }
                        }
                    }
                }

            },
            included: {
                "structured-payment-month": false
            },
            options: {
                'applicationType': ['FastTrack', 'FullDoc'],
                'lender-pepper-risk-tier': ['A', 'B', 'C'],
                "structured-payment-month": [12,24,36,48,60,72,84]
            },
            labels: {
                "lender-pepper-risk-tier": "Risk Tier",
                "structured-payment-amount": "Required Loan Amount",
                "structured-payment-month": "Period",
                "applicationType": "Application Type",
                "customer-discount-rate": "Customer Discount Rate",
                "requested-balloon": "Requested Balloon",
            },
            validation_rules: {
                type: "loans",
                attributes: {
                    "structured-payment-month": "",
                    "structured-payment-amount": "between:0,9999999",
                    "lender-pepper-risk-tier": "|in:A,B,C",
                    "applicationType": "required|in:FastTrack,FullDoc",
                    "requested-balloon": "",
                    "customer-discount-rate": "",
                }
            },
            people: [],
        }
    },
    computed: {
        ...mapState({
            appId: state => state.flows.appId,
            loanData: state => state.flows.loanData,
        }),
        getLoanId(){
            return this.appData.relationships.loan.data.id
        },
        ...mapState('auth-actions', [
                'user'
        ]),
        getStructuredPaymentAmount(){
            return this.resources.loan.attributes['structured-payment-amount']
        }
    },
    methods: {
        ...mapActions('resource-actions', [
            'getLoan',
            'updateLoan',
            'getPeople',
        ]),
        async pull() {


            let promise2 = this.getPeople()
            let promise3 = this.getLoan(this.getLoanId)

            const res = await Promise.all([
                promise2,
                promise3
            ]) 

            promise2 = res[0]
            promise3 = res[1]

            if(promise2.status == 200) {
                this.people = promise2.data.data
            }

            if(promise3.status == 200) {
                this.resources.loan = promise3.data.data
            }
        },

        async push() {

            const observer = this.$refs.observer
            const {valid, status} = await this.validateFormData()
            let loanRes = null
            let data = {}

            if(valid) {
                
                const {loan} = this.resources
                    
                // Exclude resources.relationships and other stuff

                let payload = {
                    type: loan.type,
                    id: this.getLoanId,
                    attributes: {
                        ...loan.attributes
                    }
                }

                loanRes = await this.updateLoan(payload)
                data = await this.appDataHelper([loanRes])
            }


            return {valid: valid, status: status, data}

        },

        isBtnGroup(key){

            let keys = [
                "submission-required",
                "financing-insurance"
            ]

        return keys.includes(key)

        },
        isIncluded(field){

            const {included} = this

            if(included.hasOwnProperty(field)) {
                return included[field]
            }

            return true
        },
        isReadOnly(key){

            const {readonly} = this

            if(readonly.hasOwnProperty(key)){
                return readonly[key]
            }

            return false

        },
        isNumber(key){
            return [
                "requested-balloon",
                "actual-broker-fee",
            ].includes(key)
        },
    },
    watch: {
        getStructuredPaymentAmount(val){
            if(parseInt(val) > 0) {
                this.included["structured-payment-month"] = true
            } else {
                this.included["structured-payment-month"] = false
            }
        }
    }
}
</script>

<style>
    .debug {
        border: 1px solid red;
    }
</style>

