<template>
    <div class="d-flex flex-column file-upload">
        <div class="mb-4" v-html="title">
        </div>

        <div v-if="requiredDocs.length > 0" v-for="(docs, i) of requiredDocs" class="mb-4">
            <v-card :key="i"
                flat outlined class="rounded-0"
                :loading="loading && index === i"
                :style="errorMessages[docs.prefix] ? 'border: var(--v-error-base) solid 1px' : ''">

                    <template slot="progress">
                        <v-overlay absolute class="d-flex flex-column text-center" color="secondary" opacity=".8">
                            <div>
                                <v-progress-circular size="75" color="white" indeterminate>
                                </v-progress-circular>
                            </div>
                        </v-overlay>
                    </template>

                    <v-card-text class="d-flex light_gray_1 darken-1" :class="{'flex-column': vb.xs}">
                        <div class="font-size-20 font-weight-medium">
                            {{ docs.title }} {{ docs.applicant ? ' - ' + docs.applicant : '' }}
                        </div>
                        <v-spacer />
                        <!-- <div v-if="docs.title !== 'Other Documents'" class="font-size-18 font-italic mr-11">*required</div> -->
                    </v-card-text>

                    <v-card-text class="d-flex px-5" :class="{'flex-column': vb.mdAndDown}">
                        <div class="d-flex flex-column pr-4" :class="{'mb-4': vb.smAndDown}">
                            <!-- <div>
                                <v-chip class="accent font-size-18 mb-4 mr-2" small>
                                    <span>
                                        {{ docs['chip-name'] }}
                                    </span>
                                </v-chip>
                            </div> -->
                            <div
                                v-for="des in transformDescription(docs['chip-description'])"
                                class="py-1 font-size-16 description"
                                :class="{'pl-12': vb.mdAndUp, 'pl-16': des.includes('- ')}">
                                    {{ des }}
                            </div>
                        </div>
                        <v-spacer v-if="vb.lgAndUp" />
                        <div class="d-flex justify-center">
                            <v-card
                                @drop.prevent="onDrop($event, i, docs['prefix'])"
                                @dragover.prevent="dragover = true, index = i"
                                @dragenter.prevent="dragover = true, index = i"
                                @dragleave.prevent="dragover = false, index = -1"
                                class="rounded-lg light_gray_1" :width="vb.mdAndUp ? 487 : '100%'" min-height="139"
                                :class="{ 'light_gray_1 darken-1': dragover && i == index, 'mr-11': vb.lgAndUp }">

                                <v-card-text v-if="docs.paths.length > 0" >
                                    <div class="d-flex" :class="{'flex-column justify-center': vb.xs}">
                                        <v-icon size="18" color="black">
                                            mdi-tray-arrow-up
                                        </v-icon>
                                        <div class="px-3 font-size-14 text-center">
                                            Drop files here
                                        </div>
                                        <v-btn
                                            :id="'button-' + i"
                                            class="secondary rounded-lg font-size-14 mx-4"
                                            :loading="uploadBtnLoading && i == index"
                                            @click="onUpload(docs['prefix'], i)"
                                            x-small depressed min-height="24"
                                            min-width="64"
                                            style="font-weight: 400 !important;">
                                            Browse
                                        </v-btn>
                                        
                                    </div>
                                    <div v-for="(file, ind) in docs.paths" :key="ind" class="d-flex pt-5">
                                        <v-tooltip bottom max-width="450">
                                            <template v-slot:activator="{ on, attrs }">
                                                <div v-on="on" v-bind="attrs" class="font-size-14 px-2 text-truncate">
                                                    {{ file.name || transformFilename(docs.title, docs.applicant, docs.prefix, file) }}
                                                </div>
                                            </template>
                                            <span>{{ file.name || transformFilename(docs.title, docs.applicant, docs.prefix, file) }}</span>
                                        </v-tooltip>
                                        <v-spacer />
                                        <v-icon size="18" @click="removeFile(i, file)" color="black">mdi-close</v-icon>
                                    </div>
                                </v-card-text>

                                <v-card-text v-else class="d-flex justify-center pt-14" :class="{'flex-column': vb.smAndDown}">
                                    <v-icon size="18">
                                        mdi-tray-arrow-up
                                    </v-icon>
                                    <div class="px-3 font-size-14 text-center">
                                        Drop files here
                                    </div>
                                    <v-btn
                                        :id="'button-' + i"
                                        class="secondary rounded-lg font-size-14 mx-4"
                                        :loading="uploadBtnLoading && i == index"
                                        @click="onUpload(docs['prefix'], i)"
                                        x-small depressed min-height="24"
                                        min-width="64"
                                        style="font-weight: 400 !important;">
                                        Browse
                                    </v-btn>
                                </v-card-text>

                                <input
                                    :id="'file-' + i"
                                    class="d-none"
                                    type="file"
                                    accept="image/jpeg/pdf"
                                    multiple
                                    @change="onFileChanged($event, i)"
                                >
                            </v-card>
                        </div>
                    </v-card-text>

            </v-card>
            <div v-if="errorMessages[docs.prefix]" class="py-2 error--text">
                {{ errorMessages[docs.prefix] }}
            </div>
        </div>
        
        <v-card v-if="requiredDocs.length === 0" flat>
            <v-card-text class="light_gray_2--text text-center">
                No Required Documents.
            </v-card-text>
        </v-card>
       
    </div>
</template>
<script>
export default {
    props: {
        requiredDocs: {
            type: Array,
            default: () => []
        },
        loading: {
            type: Boolean,
            default: false
        },
        errorMessages: {
            type: Object,
            default: () => {}
        },
        title: {
            type: String,
            default: "<div class='font-size-26 font-weight-medium mt-2 mb-4'>Submission Documents</div><p class='font-size-16 pb-0 mb-0' style='max-width: 1189px;'>Based on the data entered in the application, we recommend providing the requested documents below to speed up the approval process.</p>"
        }
    },
    data(){
        return {
            index: -1,
            uploadBtnLoading: false,
            dragover: false,
            prefix: ''
        }
    },
    computed: {
        vb() {
            return this.$vuetify.breakpoint
        }
    },
    methods: {
        onUpload(key, index) {
            var inputId = "file-" + index

            this.prefix = key
            this.index = index
            this.uploadBtnLoading = true

            window.addEventListener('focus', () => {
                this.uploadBtnLoading = false
            }, { once: true })

            document.getElementById(inputId).click()
        },
        onFileChanged(event, index) {

            if(Array.from(event.target.files).length) {
                this.index = index
                this.requiredDocs[index].paths = Array.from(event.target.files) || []
                this.emitUpload(this.requiredDocs[index].paths)
            }
            
        },
        transformFilename(docs, name, prefix, filename) {

            const newFilename = docs + ' - ' + (name ? name + ' - ' : '')  + filename.replaceAll(prefix, '').replaceAll('_', '')

            return newFilename
        },
        onDrop(event, index, key) {
            this.dragover = false
            this.index = index
            this.prefix = key

            this.requiredDocs[index].paths = Array.from(event.dataTransfer.files) || []
            this.emitUpload(this.requiredDocs[index].paths)
        },
        removeFile(index, name) {
            this.index = index
            this.requiredDocs[index].paths = this.requiredDocs[index].paths.filter(item => item.name != name)
            this.$emit('remove-file', name)
        },
        emitUpload(files) {
            this.$emit('upload-files', {files: files, prefix: this.prefix})
        },
        transformDescription(des) {
            let newDes = []

            if(des) {
                newDes = des.split('\n')
            }

            return newDes
        }
    }
}
</script>
<style scoped lang="scss">
    .file-upload {
        .description {
            max-width: 600px;
        }
    }
</style>

