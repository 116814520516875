<template>
    <v-card 
        :disabled="isDisabled || blockPermissionsReadOnly" 
        class="mx-auto" 
        tile flat>
        <v-card-text class="pa-4 pa-md-10 pt-md-0 pb-10">
            <v-container pa-0 fluid>
                <ValidationObserver ref="observer" tag="form">
                    <v-row>
                        <template v-for="(fieldGroup, fkey) in fields">
                            <v-col
                                :cols="$vuetify.breakpoint.smAndUp ? '6' : '12'"
                                :class="$vuetify.breakpoint.mdAndUp && fkey === 0 ? 'faint-line' : ''"
                                :key="fkey"
                            >

                                <div :class="fkey === 0 ? 'pr-0 pr-md-4' : 'pl-0 pl-md-4'">
                                    <p class="title pt-6"> {{ fieldGroup.title }} </p>
                                    <div v-for="(field, key) in fieldGroup.items" :key="key">
                                        <ValidationProvider
                                            v-slot="{ errors }"
                                            :name="field.shortMessage | formalText"
                                            :rules="getRules(key)"
                                            :vid="field.meta">

                                            <v-textarea
                                                v-if="field.type === 'textarea'"
                                                v-model="resourceObj[field.meta]"
                                                rows="1"
                                                row-height="10"
                                                :error-messages="errors"
                                                :label="field.label"
                                            >
                                            </v-textarea>

                                            <ButtonSelections 
                                                v-else-if="field.type === 'radio'"
                                                v-model="resourceObj[field.meta]"
                                                :error-messages="errors"
                                                :options="['Yes', 'No']"
                                                :label="field.label"
                                            >
                                                <template v-slot:tooltip>
                                                    <v-tooltip
                                                        right
                                                        max-width="250px"
                                                    >
                                                        <template v-slot:activator="{ on, attrs }">
                                                            <v-icon
                                                                class="mt-n2"
                                                                size="17"
                                                                v-bind="attrs"
                                                                v-on="on"
                                                            >
                                                                mdi-information
                                                            </v-icon>
                                                        </template>
                                                        <span>{{ field.tooltipMessage }}</span>
                                                    </v-tooltip>
                                                </template>
                                            </ButtonSelections>
                                            
                                            <PriorityRatings 
                                                v-else-if="field.type === 'array'"
                                                v-model="resourceObj[field.meta]"
                                                :error-messages="errors"
                                                :default-item="defaultItem"
                                                :label="field.label"
                                            >
                                            <template v-slot:tooltip>
                                                <v-tooltip
                                                    right
                                                >
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <v-icon
                                                            size="17"
                                                            class="mt-n2"
                                                            v-bind="attrs"
                                                            v-on="on"
                                                        >
                                                            mdi-information
                                                        </v-icon>
                                                    </template>
                                                    <span>{{ field.tooltipMessage }}</span>
                                                </v-tooltip>
                                            </template>
                                            </PriorityRatings>

                                            <template v-else>
                                                <div class="mb-n8 mt-4">
                                                    <span class="font-weight-bold">
                                                        {{ field.label }}
                                                    </span>
                                                    <v-tooltip
                                                        v-if="field.tooltipMessage"
                                                        right
                                                    >
                                                        <template v-slot:activator="{ on, attrs }">
                                                            <v-icon
                                                                class="mt-n2"
                                                                size="17"
                                                                v-bind="attrs"
                                                                v-on="on"
                                                            >
                                                                mdi-information
                                                            </v-icon>
                                                        </template>
                                                        <span>{{ field.tooltipMessage }}</span>
                                                    </v-tooltip>
                                                </div>
                                                <div class="mt-4">
                                                    <v-text-field
                                                        v-model="resourceObj[field.meta]"
                                                        :error-messages="errors"
                                                    >
                                                    </v-text-field>
                                                </div>
                                            </template>

                                        </ValidationProvider>
                                    </div>
                                </div>

                            </v-col>
                        </template>
                    </v-row>
                </ValidationObserver>
            </v-container>            

         </v-card-text>
    </v-card>
</template>

<script>

    import { mapActions, mapGetters } from 'vuex'
    import subscriptions from '~/components/shared/subscriptions'
    import ButtonSelections from '~/components/base/ButtonSelections'
    import PriorityRatings from './_BidPriorityRatings'    

    export default {
        name: 'BidEditor1',

        mixins: [ subscriptions ],

        components: { 
            ButtonSelections,
            PriorityRatings
        },

        computed: {
            getLoanId(){
                return this.appData.relationships.loan.data.id
            }
        },

        mounted() {
        },

        methods: {
            ...mapActions('resource-actions', [
                'getApplicationBid',
                'createBid',
                'updateBid'
            ]),

            stringHelper(val) {
                val = val.toString()
                return '[' + val + ']'
            },
            
            async pull() {
                // Get BID Resources
                let payload = {
                    id: this.appData.id
                }

                let response = await this.getApplicationBid(payload)

                if ( response?.data?.data ) {
                    this.action = 'Update'
                    this.resourceObj = this.resourceObjHelper(response.data.data)
                } else {
                    this.action = 'Create'
                    this.resourceObj = this.resourceObjHelper(this.resources)
                }


                // Save VeeValidate's observer in Vuex for observing touched events
                this.setFormFieldsObserverName('observer')

            },

            async push() {
                const valid = await this.$refs.observer.validate()
                const touched = this.$refs.observer.flags.touched

                let requestPayload = Object.assign({}, this.resources)
                let status = this.getActiveStepStatus.attributes.value 

                if(this.hardValidation) {
                    status =  valid ? 'valid' : 'dirty'
                }                 
                else {
                    if(touched) { status =  valid ? 'valid' : 'dirty' }
                }

                let res = {}

                if( valid ) {

                    let payload = {
                        type: 'bids',
                        attributes: this.removeInvalids(requestPayload.attributes),
                        relationships: {
                            application: {
                                data: {type: "applications", id: this.appData.id}
                            }
                        }
                    }

                    if (this.action === 'Create') {
                        payload.attributes.guid = this.appGuid
                        payload.attributes['customer-priorities-ratings'] = this.stringHelper(payload.attributes['customer-priorities-ratings'])
                        res = await this.createBid(payload)
                    } else {
                        payload.id = requestPayload.id
                        payload.attributes.guid = this.appGuid
                        payload.attributes['customer-priorities-ratings'] = this.stringHelper(payload.attributes['customer-priorities-ratings'])
                        res = await this.updateBid(payload)
                    }

                }
                
                let data = this.$options.filters.toAppDataFormat(res)                

                return  { valid, status, data } 
            },

            resourceObjHelper(resources) {
                
                let collection = {}
                
                Object.keys(resources).forEach( f => {                    
                    collection[f] = resources[f]
                })
                
                Object.keys(collection.relationships).forEach( rel => {
                    if (collection?.relationships[rel].data === null || collection?.relationships[rel].data?.length < 1) {
                        delete collection.relationships[rel]
                    }
                })

                let attrField = {}
                
                Object.keys(collection.attributes).forEach( attr => {    
                    if (this.resources.attributes[attr] !== undefined)
                        attrField[attr] = collection.attributes[attr]
                })


                let customerPrioritiesRatings = attrField['customer-priorities-ratings']

                let aF = (typeof customerPrioritiesRatings == 'string') ? customerPrioritiesRatings.split(',') : ['']

                if(aF.length < 5 ){
                    let item = ['Low Early Payout Fees', 'Fast Settlement', 'Lowest Repayments', 'Loan Flexibility', 'Maximum Pre-Approval Limit']
                    aF[0] = aF[0].substring(1,aF[0].length )
                    aF[aF.length - 1] = aF[aF.length - 1].substring(0,aF[aF.length - 1].length - 1)
                    aF.forEach(data => {
                        let index = item.findIndex(i => i == data)
                        item.splice(index,1)
                    })
                   this.defaultItem = item
                } else {
                    this.defaultItem = []
                }
                

                if (attrField['customer-priorities-ratings'] === '[]' || attrField['customer-priorities-ratings'] === '' || attrField['customer-priorities-ratings'] === null || attrField['customer-priorities-ratings'] === undefined) {
                    this.defaultItem = ['Low Early Payout Fees', 'Fast Settlement', 'Lowest Repayments', 'Loan Flexibility', 'Maximum Pre-Approval Limit']
                    attrField['customer-priorities-ratings'] = []
                } else {
                    // this.defaultItem = []
                    // console.log(attrField['customer-priorities-ratings'])
                    attrField['customer-priorities-ratings'] = attrField['customer-priorities-ratings'].substring(1,attrField['customer-priorities-ratings'].length-1)
                    attrField['customer-priorities-ratings'] = attrField['customer-priorities-ratings'].split(',')
                }

                collection.attributes = attrField
                this.resources = collection
                // console.log(this.resources)
                return collection.attributes
                
            },

            removeInvalids(fields = {}) {
                let collection = {}
                Object.keys(fields).forEach( f => {
                    if (fields[f] !== null && fields[f] !== '')
                        collection[f] = fields[f]
                })
                return collection
            }
        },

        data() {
            return {
                defaultItem: [],
                action: '',
                resourceObj: {},
                resources: {
                    'type': 'loans',
                    'id': null,
                    'attributes': {
                        'client-lender-preference': '',
                        'why-this-lender': '',
                        'client-additional-payments': '',
                        'customer-priorities-ratings': '',
                        'other-priorities': '',
                        'client-settlement': ''
                    },
                    'relationships': {
                        'application': {
                            "data": {
                                "type": "applications",
                                "id": null
                            },
                        }
                    }
                },
                fields: [
                    {
                        title: 'Initial Preferences',
                        items: {
                            'client-lender-preference':
                                { 
                                    label: 'What is the customers preferred lender?', 
                                    meta: 'client-lender-preference', 
                                    type: 'text',
                                    shortMessage: 'Preferred Lender',
                                    tooltipMessage: ''
                                },    
                            'why-this-lender':
                                { 
                                    label: 'Why this Lender?',
                                    meta: 'why-this-lender', 
                                    type: 'textarea',
                                    shortMessage: 'Why this Lender' ,
                                    tooltipMessage: ''
                                },                        
                            'client-additional-payments':
                                { 
                                    label: 'Additional Payments?', 
                                    meta: 'client-additional-payments', 
                                    type: 'radio',
                                    shortMessage: 'Is Additional Payments' ,
                                    tooltipMessage: 'Is the client intending to any large additional payments (payment events), or do they expect to be regularly making additional payments on the loan that are outside the payment schedule?'
                                },
                            'client-settlement':
                                { 
                                    label: 'When Does the Loan Need to Settle?', 
                                    meta: 'client-settlement', 
                                    type: 'text',
                                    shortMessage: 'Settle By' ,
                                    tooltipMessage: ''
                                },
                        }
                    },
                    {
                        title: 'Customer Priorities',
                        items: {
                            'customer-priorities-ratings':
                                {
                                    label: 'Customer Priorities Rating', 
                                    meta: 'customer-priorities-ratings', 
                                    type: 'array',
                                    shortMessage: 'Rating on Customer Prior',
                                    tooltipMessage: 'Please rate the customer prior'
                                },
                            'other-customer-priorities':
                                {
                                    label: 'Other Customer Priorities',
                                    meta: 'other-priorities', 
                                    type: 'textarea',
                                    shortMessage: 'Other Priorities',
                                    tooltipMessage: ''
                                }
                        }
                    }
                ],
                validation_rules: {
                    type: "test",
                    attributes: {
                        "client-lender-preference": "required",
                        "why-this-lender": "required",
                        "client-additional-payments": "required",
                        "customer-priorities-ratings": "required|array|min:5",
                        "other-customer-priorities": "required",
                        "client-settlement": "required",
                        // "requested-payment-frequency": "required|in:Monthly,Fortnightly,Weekly,Annual",                        
                    }
                },
            }
        }
    }
</script>

<style lang="scss" scoped>
    .faint-line {
        border-right: 2px solid var(--v-light_gray_1-base) !important
    }
    .flex-form {
        display: grid;
        grid-column-gap: 15px;
        grid-row-gap: 0;
        grid-template-columns:  repeat(auto-fit, minmax(260px, 1fr));

        .flex-field {
            align-self: start;
        }
    }
</style>