<template>
    <FlexWrap @resize="handleResize">
        <v-card :disabled="isDisabled || !validComponentData" class="mx-auto pt-10" tile flat>
            <v-card-text class="mx-auto pa-4 pt-0 pa-md-10 pt-md-0">
                <v-card tile flat class="mx-auto" max-width="500">
                    <v-container fluid pa-0>
                        <v-row>
                            <v-col cols="12" class="pt-0">

                                <ValidationObserver ref="observer" tag="form">

                                    <!-- <div class="title mb-4 body_text_color--text">
                                        Purchase Type
                                    </div> -->

                                    <div class="flex-form">

                                        <div class="flex-field">
                                            <ValidationProvider 
                                                    v-slot="{ errors }" 
                                                    name="Sale Type" 
                                                    :rules="getRules('sale-type')" 
                                                    vid="sale-type">
                                                <v-select
                                                    v-model="loanResourceSaleType"
                                                    :items="getOptions('sale-type-2')"
                                                    :error-messages="errors"
                                                    color="secondary"
                                                    tile
                                                    label="Sale Type"></v-select>
                                            </ValidationProvider>
                                        </div>

                                        <div
                                            v-if="!filteredUserFlow" 
                                            class="flex-field">
                                            <ValidationProvider v-slot="{ errors }" name="Asset Type" vid="asset_type">
                                                <v-select
                                                    v-model="assetType"
                                                    :items="assetTypeList"
                                                    item-text="name"
                                                    item-value="id"
                                                    color="secondary"
                                                    :error-messages="errors"
                                                    label="Asset Type"></v-select>
                                            </ValidationProvider>
                                        </div>
                
                                        <div class="flex-field" v-if="assetType && (assetSubTypeList.length > 1)">
                                            <ValidationProvider v-slot="{ errors }" name="Asset Sub-Type" vid="sub_asset_type">
                                                <v-select
                                                    v-model="assetSubType"
                                                    :items="assetSubTypeList"
                                                    item-text="name"
                                                    item-value="id"
                                                    color="secondary"
                                                    :error-messages="errors"
                                                    label="Asset Sub-Type"></v-select>
                                            </ValidationProvider>
                                        </div>

                                        <!-- Temp Spacer -->
                                        <!-- <div class="flex-field" v-for="index in 6" :key="index"></div> -->

                                    </div>


                                    <!-- <div class="title my-4 body_text_color--text">
                                        Purchase Details
                                    </div> -->

                                    <div 
                                        v-for="(layout, lkey) in fieldLayout" 
                                        :key="lkey" 
                                        class="flex-form">

                                        <template v-for="(field, key) in fieldLayoutMaker(layout, resourceObj.purchases.attributes)">
                                            <div class="flex-field" :key="key" >
                                                
                                                <ValidationProvider 
                                                        v-slot="{ errors }" 
                                                        :name="labels[key]"
                                                        :rules="getRules(key)" 
                                                        :vid="key">

                                                            <nvic-finder 
                                                                v-if="key == 'nvic'"
                                                                :label="labels[key]"
                                                                :errors="errors"
                                                                @accept="handleNvicAccept"
                                                                :default-value="resourceObj.purchases.attributes[key]"
                                                                v-model="resourceObj.purchases.attributes[key]" >
                                                            </nvic-finder>

                                                            <ButtonSelections 
                                                                v-else-if="isBtnGroup(key)"
                                                                
                                                                v-model="resourceObj.purchases.attributes[key]"
                                                                @input="handleBunttonSelect"
                                                                :error-messages="errors"
                                                                :options="['Yes', 'No']"
                                                                
                                                                :label="labels[key]" />

                                                            <v-select
                                                                v-else-if="isFieldType('picklist', key)"
                                                                v-model="resourceObj.purchases.attributes[key]"
                                                                :items="getOptions(key)"
                                                                :error-messages="errors"
                                                                color="secondary"
                                                                :label="labels[key]"
                                                                ></v-select>

                                                            <flex-date-picker
                                                                v-else-if="isFieldType('date', key)"
                                                                :label="labels[key]"
                                                                :errors="errors"
                                                                v-model="resourceObj.purchases.attributes[key]"
                                                            ></flex-date-picker>

                                                            <v-text-field 
                                                                v-else
                                                                tile
                                                                type="text"
                                                                :label="labels[key]"
                                                                color="secondary"
                                                                :error-messages="errors"
                                                                :disabled="isReadyOnly(key)"
                                                                v-model="resourceObj.purchases.attributes[key]"
                                                                required>
                                                            </v-text-field>

                                                </ValidationProvider>
                                            </div>
                                        </template>
                                        <!-- Temp Spacer -->
                                        <div class="flex-field" v-for="index in 6" :key="index"></div>

                                    </div>

                                    <div class="d-none">
                                        <ValidationProvider 
                                            v-slot="{ errors }" 
                                            name="required-loan-amount"
                                            rules="required" 
                                            vid="Required Loan Amount">
                                            <v-text-field 
                                                type="text"
                                                label="Required Loan Amount"
                                                color="secondary"
                                                :error-messages="errors"
                                                disabled
                                                v-model="resourceObj.purchases.attributes['required-loan-amount']"
                                                required>
                                            </v-text-field>
                                        </ValidationProvider>
                                    </div>

                                </ValidationObserver>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card>
            </v-card-text>
        </v-card>
    </FlexWrap>
</template>

<script>

    import subscriptions from '~/components/shared/subscriptions'
    import { mapState, mapGetters, mapActions, mapMutations } from 'vuex'
    import FlexDatePicker from '~/components/base/FlexDatePicker' 
    import NvicFinder from '~/components/base/NvicFinder' 
    import baseComponents from '~/components/base'
    import FlexWrap from '~/components/base/FlexWrap'
    import helpers from '~/plugins/helpers'
    import { isEmpty } from 'lodash'
    
    export default {
        name: 'PurchaseEditor4',
        mixins: [subscriptions, helpers],
        components: { FlexWrap, FlexDatePicker, NvicFinder, ...baseComponents },
        data(){
            return {
                mode: "default",
                assetType: null,
                assetSubType: null,
                vehicleKnown: '',
                resources: [
                    {
                        type: "loans",
                        id: null,
                        attributes: {
                            "sale-type": "",
                        },
                        relationships: {
                            application: {
                                data: { type: "applications", id: null } 
                            }
                        }
                    },
                    {
                        type: "purchases",
                        id: null,
                        attributes: {
                            "nvic": "",
                            "condition": "",
                            "vendor-known": "",
                            "year-of-manufacture": "",
                            "make": "",
                            "model": "",
                            "variant": "",
                            "purchase-price": "",
                            // "cash-deposit": "",
                            // "trade-in-amount": "",
                            // "payout-amount": "",
                            "required-loan-amount": ""
                        },
                        relationships: {
                            application: {
                                data: { type: "applications", id: null } 
                            },                
                            kind: {
                                data: { type: "kinds", id: null }  
                            }
                        }
                    }
                ],
                fieldLayout: [
                    [ "condition", "vendor-known", "nvic", "year-of-manufacture"],
                    [
                        "make",
                        "model",
                        "variant"               
                    ],
                    [
                       "purchase-price",
                        // "cash-deposit",
                        // "trade-in-amount",
                        // "payout-amount"
                    ],
                    [
                        "required-loan-amount"
                    ]
                ],
                // Static field labels. cause sometimes field names and labels are different
                labels: {
                    "nvic": "NVIC",
                    "year-of-manufacture": "Year of Manufacture",
                    "make": "Make",
                    "model": "Model",
                    "variant": "Variant",
                    "condition": "Condition",
                    "vendor-known": "Vehicle Known",
                    // "kms": "KMS",
                    "purchase-price": "Loan Amount",
                    // "cash-deposit": "Cash Deposit",
                    // "trade-in-amount": "Trade in Amount",
                    // "payout-amount": "Payout Amount",
                    "required-loan-amount": "Required Loan Amount"
                },
                validation_rules: {
                    attributes: {
                        "nvic": "required|string|max:50",
                        "year-of-manufacture": "required|integer|digits_between:4,4",
                        "make": "required|string|max:255",
                        "model": "required|string|max:200",
                        "variant": "required|string|max:128",
                        "condition": "required|string|in:New,Used",
                        "vendor-known": "required|string|in:Yes,No",
                        // "kms": "required|integer",
                        "purchase-price": "between:0,9999999",
                        // "cash-deposit": "between:0,9999999",
                        // "trade-in-amount": "between:0,9999999",
                        // "payout-amount": "between:0,9999999",
                        "sale-type": "required|string|in:Dealer,Private Sale,Refinance",
                        "sale-type-2": "required|string|in:Dealer,Private Sale",
                        "required-loan-amount": "required"
                    }
                },
                /**
                 * resourceObj : This will hold formatted resources so we can easily map it to the view.
                 */
                resourceObj: {},
                /**
                 * payloadObj : This will give us access to the payloads in push method during Unit Test
                 */
                payloadObj: {},
            }
        },
        computed: {
            ...mapGetters('kinds', ['kinds', 'kindsUnformat']),
            assetTypeList() {

                // We need Kinds for Purchases
                let purchasesKinds = this.kinds.find( kind => {
                    return kind.name == 'Purchase'
                })

                let list = []

                if(purchasesKinds) {

                    list = purchasesKinds.children.filter( child => {

                        if(child.id == "9-45" || child.id == "9-34") {
                            return false
                        }

                        return true

                    })
                }
                
                return list
            },
            assetSubTypeList() {

                let subType = this.assetTypeList.find( item => {
                    return item.id == this.assetType
                })
                
                if(subType) {
                    return subType.children || []
                } else {
                    return []
                }
            },
            /**
             * Checks for minimum required data
             */
            validComponentData(){
                
                const {data} = this.appData.relationships.loan

                if(data == null){
                    return false;
                }

                return true;
            },
            getLoanId(){
                
                if(this.validComponentData){
                    return this.appData.relationships.loan.data.id
                } 

                return null
            },
            getPurchaseId(){

                if(this.appData.relationships.purchases.data.length) {

                    /**
                     * Check if purchases exists.
                     * Then get the first one, this is subject to change.
                     */
                    return this.appData.relationships.purchases.data[0].id
                }

                return null

            },
            fieldsLogic(){

                let isVehicleKnown = false 
                let assetTypeSelected = !!this.assetType 
                let isMotorVehicle = this.assetType == "9-2" // Motor Vehicle

                if(this.resourceObj.purchases) {

                    let nvic = this.resourceObj.purchases.attributes['nvic']
                    let vehicleKnown = this.vehicleKnown

                    if(nvic ||  vehicleKnown == "Yes") {
                        isVehicleKnown = "Yes"
                    } 

                    if( vehicleKnown == "No") {
                        isVehicleKnown = "No"
                    } 

                }

                let data = {
                    isMotorVehicle,
                    assetTypeSelected,
                    isVehicleKnown
                }

                return data
            },
            filteredUserFlow() {
                if ( this.getFlowSlug === 'flow-3-1' || 
                     this.getFlowSlug === 'flow-3-3' ||
                     this.getFlowSlug === 'flow-5-1' || 
                     this.getFlowSlug === 'flow-5-3' || 
                     this.getFlowSlug === 'flow-5-4' || 
                     this.getFlowSlug === 'flow-5-5' ||
                     this.getFlowSlug === 'flow-5-6' ) {
                    return true
                }
                return true
            },
            getPurchaseCondition() {
                return this.resourceObj?.purchases?.attributes?.['condition']
            },
            loanResourceSaleType: {
                get() {
                    return this.resourceObj?.loans?.attributes?.['sale-type']
                },
                set(val) {
                    this.resourceObj.loans.attributes['sale-type'] = val
                }
                
            }
        },
        created() {

            this.resourceObj = this.resourceObjHelper()

            /**
             * Attach Application id, not sure why can't initialize this in vue.data
             */ 
            this.resourceObj.purchases.relationships.application.data.id = this.appId

        },
        mounted(){

            if(!this.validComponentData){
                this.showComponentWarning()
            }

        },
        methods: {
            ...mapActions('resource-actions', [
                'getLoan',
                'getPurchase',
                'updateLoan',
                'createPurchase',
                'updatePurchases'
            ]),
            async pull() {

                // Do nothing if minimum data required is not met.
                if(!this.validComponentData) return; 

                const {loans, purchases} = this.resourceObj

                let loansRes = this.getLoan(this.getLoanId).then(res => {
                    
                    // Pick only the fields we need from the response
                    this.resourceObj.loans = this.resourceFieldPicker(loans, res.data.data)

                })

                let purchasesRes = null

                if(this.getPurchaseId) {

                    purchasesRes = this.getPurchase(this.getPurchaseId).then(res => {

                        this.resourceObj.purchases = this.resourceFieldPicker(purchases, res.data.data)

                        // Reset component kind's data if the response kind.data is null
                        if(!res.data.data.relationships.kind.data){
                            this.resourceObj.purchases.relationships.kind.data = { type: "kinds", id: null }  
                        }
                        
                        this.handleAssetTypes(res.data.data.relationships.kind.data)

                        this.attachVehicleKnown()

                    })

                }


                try {

                    await Promise.all([loansRes, purchasesRes])

                    this.prefillFields()

                } catch (error) {
                    console.log('Ooops!..', error)
                }
                
            },

            async push() {

                const observer = this.$refs.observer

                const {loans, purchases} = this.resourceObj
                
                this.payloadObj.loans = {
                    type: loans.type, 
                    id: loans.id, 
                    attributes: loans.attributes
                }

                const loanRes =  this.updateLoan(this.payloadObj.loans).then(res => {
                        this.resourceObj.loans = this.resourceFieldPicker(loans, res.data.data)
                        return res
                    })

                let purchaseRes = null;

                this.payloadObj.purchases = {
                    type: purchases.type,
                    // attributes: this.excludeInvalids(purchases.attributes, observer.fields),
                    attributes: purchases.attributes,
                    relationships: {}
                }

                if(this.getPurchaseId) {

                    // Include id when updating resource
                    this.payloadObj.purchases.id = purchases.id

                    // Include kind if not empty
                    if(purchases.relationships.kind.data && purchases.relationships.kind.data.id){
                        this.payloadObj.purchases.relationships.kind = purchases.relationships.kind
                    }

                    // On patch don't update component data. 
                    purchaseRes = this.updatePurchases(this.payloadObj.purchases).then(res => {
                        // this.resourceObj.purchases =  this.resourceFieldPicker(purchases, res.data.data)
                        return res
                    })

                }  else {

                    // Include relationships when creating resource
                    this.payloadObj.purchases.relationships = purchases.relationships

                    // Include kind if not empty
                    if(purchases.relationships.kind.data.id == null){
                        delete this.payloadObj.purchases.relationships.kind
                    }

                    purchaseRes =  this.createPurchase(this.payloadObj.purchases).then(res => {
                        this.resourceObj.purchases =  this.resourceFieldPicker(purchases, res.data.data)
                        return res
                    })

                }

                const {valid, status} = await this.validateFormData()
                const data = await this.appDataHelper([loanRes, purchaseRes])

                return {valid, status, data}

            },
            
            /**
             * This logic will pre-populate Asset Types if exists.
             * Move to shared file id needed.
             */
            handleAssetTypes(data){

                if(data == null) {
                    return;
                }

                let asset = this.kindsUnformat.find(kind => {
                    return kind.id == data.id
                })

                if(asset) {

                    if(asset.relationships.parent.data) { // has parent

                        let parentId = asset.relationships.parent.data.id;

                        let resource = this.kindsUnformat.find(kind => { // get top level parent ex: purchases
                            return kind.id == parentId
                        })
                        
                        if(resource.relationships.parent.data == null) { // if parent id is null means it is top level
                            this.assetType = data.id
                            this.assetSubType = ''
                        } 
                        else if (resource.relationships.parent.data.id == null) {
                            this.assetType = data.id
                            this.assetSubType = ''
                        }
                        else {
                            this.assetType = parentId
                            this.assetSubType = data.id
                        }

                    }
                }

            },
            /**
             * Nvic Event Handler
             */
            handleNvicAccept(data){

                // this.resourceObj.purchases.attributes.kms = data.details.AVERAGEKM
                this.resourceObj.purchases.attributes["make"] = data.details["manufacturer-name"]
                this.resourceObj.purchases.attributes["model"] = data.details["family-name"]
                this.resourceObj.purchases.attributes["variant"] = data.details["variant-name"]
                this.resourceObj.purchases.attributes["year-of-manufacture"] = data.details["year-create"]

            },
            isBtnGroup(key){

                let keys = [
                    "vendor-known",
                ]

                return keys.includes(key)

            },
            isReadyOnly(key){

                let keys = [
                    "make",
                    "model",
                    "variant"  
                ]
                
                if(key == "year-of-manufacture" && (!!this.resourceObj.purchases.attributes['nvic'] || this.vehicleKnown == 'Yes')) {
                    return true
                }

                return keys.includes(key)

            },
            attachVehicleKnown(){

                this.resourceObj.purchases.attributes['vendor-known'] = ""

                if(this.resourceObj.purchases.attributes['nvic']) {

                    this.resourceObj.purchases.attributes['vendor-known'] = "Yes"

                } else {

                    if(this.resourceObj.purchases.attributes['year-of-manufacture']) {
                        this.resourceObj.purchases.attributes['vendor-known'] = "No"
                    }
                }
            

            },
            prefillFields(){

                const {purchases} = this.resourceObj
                            
                if(purchases.attributes["purchase-price"] == null) {
                    purchases.attributes["purchase-price"] = "0"
                }
                
                // if(purchases.attributes["cash-deposit"] == null) {
                //     purchases.attributes["cash-deposit"] = "0"
                // }

                // if(purchases.attributes["trade-in-amount"] == null) {
                //     purchases.attributes["trade-in-amount"] = "0"
                // }

                // if(purchases.attributes["payout-amount"] == null) {
                //     purchases.attributes["payout-amount"] = "0"
                // }

                if ( isEmpty(purchases.attributes['sale-type']) ) {
                    if (purchases.attributes['condition'] === 'New') {
                        purchases.attributes['sale-type'] = 'Dealer'
                    }
                }

                if ( isEmpty(purchases.attributes['vendor-known']) ) {
                    purchases.attributes['vendor-known'] = 'No'
                }

            },
            handleBunttonSelect(val) {
                this.vehicleKnown = val
            }
        },

        watch: {
            assetType(newVal, oldVal) {
                
                if(this.assetSubType == "" || this.assetSubType == null){
                        this.resourceObj.purchases.relationships.kind.data = {
                            id: newVal,
                            type: "kinds"
                        }
                }
                
                if( (oldVal != "" && oldVal != null) && newVal != oldVal) {
                    this.assetSubType = ""


                    this.vehicleKnown = ""
                    this.resourceObj.purchases.attributes['vendor-known'] = ""
                }

            },
            assetSubType(pid) {
                if (pid == '')
                    this.resourceObj.purchases.relationships.kind.data.id = this.assetType
                else this.resourceObj.purchases.relationships.kind.data.id = pid

            },
            fieldsLogic(data){

                const {isMotorVehicle, assetTypeSelected, isVehicleKnown} = data

                if(isMotorVehicle) {

                    if(isVehicleKnown == 'Yes' || isVehicleKnown == true) {

                        this.fieldLayout = [
                            [ "condition", "year-of-manufacture" ], // [ "condition", "vendor-known"],
                            [ "nvic" ],
                            [
                                "year-of-manufacture",
                                "make",
                                "model",
                                "variant"
                            ],
                            [
                                "purchase-price",
                                // "cash-deposit",
                                // "trade-in-amount",
                                // "payout-amount"
                            ]
                        ]

                    } 
                    
                    if(isVehicleKnown == 'No' || isVehicleKnown == false) {

                        this.fieldLayout = [
                            [ "condition", "year-of-manufacture" ], // [ "condition", "vendor-known", ],
                            [ "year-of-manufacture" ],
                            [
                                "purchase-price",
                                // "cash-deposit",
                                // "trade-in-amount",
                                // "payout-amount"
                            ]
                        ]

                        this.resourceObj.purchases.attributes['nvic'] = ""
                        this.resourceObj.purchases.attributes['make'] = ""
                        this.resourceObj.purchases.attributes['model'] = ""
                        this.resourceObj.purchases.attributes['variant'] = ""

                    }
                    
                    if(isVehicleKnown == '') {


                        this.fieldLayout = [
                            [ "condition", "year-of-manufacture" ], // [ "condition", "vendor-known"],
                            [
                                "purchase-price",
                                // "cash-deposit",
                                // "trade-in-amount",
                                // "payout-amount"
                            ]
                        ]
                    }

                } else {

                    if(assetTypeSelected){

                        this.fieldLayout = [
                            [ "condition", "year-of-manufacture"],
                            [
                                "purchase-price",
                                // "cash-deposit",
                                // "trade-in-amount",
                                // "payout-amount"
                            ]
                        ]

                        this.resourceObj.purchases.attributes['nvic'] = ""
                        this.resourceObj.purchases.attributes['make'] = ""
                        this.resourceObj.purchases.attributes['model'] = ""
                        this.resourceObj.purchases.attributes['variant'] = ""

                    } else {

                        this.fieldLayout = [
                            [ "condition", "year-of-manufacture" ],
                            [
                                "purchase-price",
                                // "cash-deposit",
                                // "trade-in-amount",
                                // "payout-amount"
                            ]
                        ]

                        this.resourceObj.purchases.attributes['nvic'] = ""
                        this.resourceObj.purchases.attributes['make'] = ""
                        this.resourceObj.purchases.attributes['model'] = ""
                        this.resourceObj.purchases.attributes['variant'] = ""

                    }


                }

            },
            /**
             * Pre-select item if sub type choices has only one.
             */
            assetSubTypeList(val){

                if(val.length == 1) {
                    this.assetSubType = val[0]['id']
                }

            },
            validComponentData(val){
                if(!val) {
                    this.showComponentWarning()
                }
            },
            'resourceObj.purchases.attributes': {
                handler(val) {
                    let loanAmount = 0
                    let purchasePrice = val['purchase-price'] ? parseFloat( val['purchase-price'] ) : 0
                    let payoutAmount = val['payout-amount'] ? parseFloat( val['payout-amount'] ) : 0
                    let cashDeposit = val['cash-deposit'] ? parseFloat( val['cash-deposit'] ) : 0
                    let tradeInAmount = val['trade-in-amount'] ? parseFloat( val['trade-in-amount'] ) : 0
                    loanAmount = ( purchasePrice + payoutAmount ) - ( cashDeposit + tradeInAmount )

                    val['required-loan-amount'] = loanAmount
                },
                deep: true
            },
        }
    
     
    }
</script>

<style scoped lang="scss">
    .flex-form {
        display: grid;
        grid-column-gap: 15px;
        grid-row-gap: 0;
        grid-template-columns:  repeat(auto-fit, minmax(260px, 1fr));

        .flex-field {
            align-self: start;
        }
    }
</style>




