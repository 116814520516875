<template>
    <v-card class="mx-auto" tile flat>
        <v-card-text class="px-4 pt-0 px-md-10 pt-md-0 font-size-20 font-weight-medium">Accountant</v-card-text>
        <v-card-text class="pa-4 pt-0 pa-md-10 pt-md-0">

               <ValidationObserver ref="observer" tag="form">
                   <div class="flex-form">
                        
                        <div class="flex-field" v-for="(key, index) in Object.keys(resources.references.attributes)" :key="key + index">
                            <ValidationProvider
                                v-slot="{ errors }"
                                :name="labels[key]"
                                :rules="getRules(key)"
                                :vid="key">

                                <v-text-field
                                    v-model="resources.references.attributes[key]"
                                    :type="isNumber(key) ? 'number' : 'text'"
                                    :label="labels[key]"
                                    :error-messages="errors"
                                    color="secondary"
                                ></v-text-field> 

                            </ValidationProvider>
                        </div>

                    </div>
                </ValidationObserver>

        </v-card-text>
     
    </v-card>
</template>

<script>

    import { mapActions, mapState } from 'vuex'
    import helpers from '~/plugins/helpers'
    import subscriptions from '~/components/shared/subscriptions'


    export default {
        components: { },

        mixins: [subscriptions, helpers],

        data() {
            return {
                resources: {
                    references: {
                        type: 'references',
                        id: null,
                        attributes: {
                            "name": "",
                            "email": "",
                            "phone": "",
                        }
                    }
                },
                labels: {
                    "name": "Name",
                    "email": "Email",
                    "phone": "Phone",
                },
                validation_rules: {
                    type: "loans",
                    attributes: {
                        "name": "",
                        "email": "",
                        "phone": "",
                    }
                },
                references: [],
                incomes: [],
            }
        },
        computed: {
            ...mapState({
                appId: state => state.flows.appId,
            }),
            hasSelfEmployedIncome() {
                let { incomes } = this
                let hasIncome = false

                if(incomes.length > 0) {
                    incomes.forEach(income => {
                        if(income.relationships.kind.data.id === "12-29") {
                            hasIncome = true
                        }
                    })
                }

                return hasIncome
            },
        },
        methods: {
            ...mapActions('resource-actions', [
                'getApplication',
                'addNewReference',
                'updateReference'
            ]),
            async pull() {
                try {

                    let res = await this.getApplication({
                        id: this.appId, 
                        params: {
                            include: 'references,incomes'
                        }
                    })

                    if(res.status === 200) {

                        if(res.data.included) {
                            res.data.included.forEach(include => {

                                if(include.type === "incomes") {
                                    this.incomes.push(include)
                                }

                                if(include.type === "references") {
                                    this.references.push(include)
                                }

                            })

                            if(this.hasSelfEmployedIncome) {

                                this.validation_rules.attributes['name'] = "required"
                                this.validation_rules.attributes['phone'] = "required|numeric|digits_between:10,10"
                                this.validation_rules.attributes['email'] = "required|email"

                                const accountantReference = this.references.find(ref => ref.relationships.kind.data.id === "6-2")

                                if(accountantReference) {

                                    this.resources.references = this.resourceFieldPicker(this.resources.references, accountantReference)

                                }
                            }
                        }

                    }

                } catch (err) {
                    console.log(err)
                }
            },
            async push() {
                try {

                    const {valid, status} = await this.validateFormData()
                    let data = {}

                    if(valid) {

                        let payload = {
                            type: "references",
                            attributes: {
                                ...this.resources.references.attributes
                            },
                            relationships: {
                                "kind": {
                                    "data": {
                                        "type": "kinds",
                                        "id": "6-2"
                                    }
                                },
                                "application": {
                                    "data": {
                                        "type": "applications",
                                        "id": this.appId
                                    }
                                },
                            }
                        }

                        // update ref else create
                        if(this.hasSelfEmployedIncome) {
                            if(this.resources.references.id) {

                                payload.id = this.resources.references.id

                                const ref = this.updateReference(payload)

                                data = await this.appDataHelper([ref])

                            } else {

                                const ref = this.addNewReference(payload)

                                data = await this.appDataHelper([ref])

                            }
                        }
                        
                    }

                    return {valid, status,  data}

                } catch (err) {
                    console.log(err)
                }
            },
            isNumber(key){
                return ['phone'].includes(key)
            },
        },
        watch: {
    
        }
    }
</script>

<style lang="scss" scoped>

</style>