import { mapState, mapActions, mapGetters } from 'vuex'

export default {
    computed: {
        ...mapGetters('kinds', ['kinds', 'kindsUnformat']),
    },
    methods: {
        getOccupanciesByPersonId(collection, personId){

            return collection.filter( item => {

                if(item.relationships.person.data) {

                    return personId == item.relationships.person.data.id

                }

            })

        },
        getFullAddress(address){

            if(!address) return "";

            if(!address.hasOwnProperty('attributes')) return "";
            
            return  `${address.attributes["address-line-1"] == null ? '' : address.attributes["address-line-1"]}, ${address.attributes["suburb"] == null ? '' : address.attributes["suburb"]} ${address.attributes["state"] == null ? '' : address.attributes["state"]} ${address.attributes["postcode"] == null ? '' : address.attributes["postcode"]}`
        },
        getStreet(address){

            if(!address) return "";

            if(!address.hasOwnProperty('attributes')) return "";
            
            return  `${address.attributes["street-number"] == null ? '' : address.attributes["street-number"]} ${address.attributes["street-name"] == null ? '' : address.attributes["street-name"]} ${address.attributes["street-type"] == null ? '' : address.attributes["street-type"]}`
        },
        getFullVehicleDescription(data){

            if(!data) return "";

            if(!data.hasOwnProperty('attributes')) return "";
            
            return  `${data.attributes["make"]} ${data.attributes["model"]}` + " ("+`${data.attributes["year-of-manufacture"]}`+")"

        },

        selectPrimaryApplicant(data){
                
            /**
             * 1. check if data has included
             * 2. filter included for 'parts'
             * 3. select parts with kinds 'applicant'
             * 4. select person based on selected part
             */
    
            if(!data.included){
                return null;
            }
    
            let parts = data.included.filter(part => {
                return part.type == 'parts'
            })
    
            let applicants = [];	
            let primaryApplicant = null 
    
            parts.forEach((part) => {
                
                if (!part.relationships.kind.data) {	
                    // person must have part with kind
                    return null
                }
                
                if (part.relationships.kind.data.id == this.kindsOfApplicant.id) {
                    applicants.push(part);
                }
            })

            if (applicants.length == 0) {
    
                // no applicant
                return null
    
            } else if (applicants.length == 1) {
                
                if (applicants[0].attributes.priority == "1") {
    
                    primaryApplicant = applicants[0];
    
                } else {
    
                    // primary applicant must be priority 1
                    return null
                    
                }
    
            } else if (applicants.length > 1) {
                
                let primaryApplicants = applicants.filter((applicant) => {
                    return applicant.attributes.priority == "1"
                })
                
                if( primaryApplicants.length != 1) {	
    
                    // too many, or too few primary applicants (must be 1 and only 1)
                    return null
                    
                } else {		
    
                    primaryApplicant = primaryApplicants[0];
                }
    
            }
    
            let applicant = data.data.find( entity => {
                if(entity.relationships['parent-parts'].data[0]){
                    return entity.relationships['parent-parts'].data[0].id == primaryApplicant.id
                }
            })
    
            // Success!
            return applicant 
        
        },
        getEntityFriendlyName(entity){

            let fullname = ''

            if(entity.type == 'businesses') {

                fullname += entity.attributes['business-legal-name'] ? entity.attributes['business-legal-name'] : 'Business'

            }

            if(entity.type == 'people'){

                fullname += entity.attributes['first-name'] ? entity.attributes['first-name'] + ' ' : ''

                fullname += entity.attributes['last-name'] ? entity.attributes['last-name'] : ''

                if(!entity.attributes['first-name'] && !entity.attributes['last-name']){
                    fullname = 'Person'
                }
            }

            return fullname

        },
        getEntityRoles(entity, parts){
                
            let roles = []

            if(!entity) return roles;

            let entityParentParts = entity.relationships['parent-parts']

            if(entityParentParts.data.length > 0){

                entityParentParts.data.forEach( (part1) => {

                    parts.forEach(part2 => {

                        if(part1.id == part2.id){

                            let partKind = this.kindsUnformat.find(kind => kind.id == part2.relationships?.kind?.data?.id)

                            if(!roles.includes(partKind.attributes.name)){

                                roles.push(partKind.attributes.name)

                            }
                        
                        }

                    })


                });

            }

            // Move 'Beneficial Owner' last

            if(roles[0] && roles[0] == 'Beneficial Owner'){
                roles = roles.reverse()
            }
            
            return roles

        },
        getFriendlyRoles(entity, parts){

            let roles = this.getEntityRoles(entity, parts)

            return roles.length > 1 ? `${roles[0]} +${roles.length - 1}`: roles.length == 1 ? roles[0] : ''

        },
        getMonthlyAmount(amount, frequency = 'Monthly'){

            switch (frequency) {
                case 'Monthly':
                    return (parseFloat(amount) * 12) / 12
                case 'Weekly':
                    return (parseFloat(amount) * 52) / 12
                case 'Fortnightly':
                    return (parseFloat(amount) * 26) / 12
                case 'Annual':
                    return (parseFloat(amount) * 1) / 12
            }

        },
        // filter relationships
        filterRelationships(relationships) {
            // list of excluded relationships
            let excluded = [
                'validations',
                'commitments'
            ]

            Object.keys(relationships).forEach(rel => {
                if(excluded.includes(rel)) {
                    delete relationships[rel]
                }
            })

            return relationships

        }
    },

}