    <template>
    <div>

        <ValidationObserver ref="observer" tag="form">
            <ValidationProvider 
                v-slot="{ errors }" 
                rules="required"
                name="Condition"
                vid="condition">

                <v-row
                    :justify="items.length <= 3 ? 'center' : 'start'" 
                    :style="items.length <= 3 && vb.mdAndUp ? 'width: 55%;' : 'width: 100%;'"
                    align="center" class="rounded-lg light_gray_1 pa-2 ma-2 mx-auto">
                    <v-col
                        v-for="(name, index) in items" :key="index"
                        cols="12" class="pa-2" :md="items.length <= 3 ? '12' : '6'">

                        <v-card
                            flat
                            height="100%" 
                            @click="handleSelectCondition(name)"
                            :color="name === resources.purchases.attributes.condition ? 'primary lighten-5' : ''"
                            rounded="lg"
                            class="d-flex pa-4 align-center justify-center">
                            <div class="flex-options body-2 font-weight-medium">
                                {{name}}
                            </div>
                            <v-spacer></v-spacer>
                            <v-btn color="secondary" :width="86" small depressed>
                                Select
                            </v-btn>
                        </v-card>

                    </v-col>
                </v-row>

                <div v-if="errors.length > 0">
                    <div v-for="error in errors" :key="error" class="red--text">
                        {{errror}}
                    </div>
                </div>

            </ValidationProvider>
        </ValidationObserver>

    </div>
</template>

<script>
    /**
     * REQUIRED!
     */
    import { mapActions, mapGetters } from 'vuex'
    import {store, actions} from '~/components/v1/PurchaseEditor6/__store'
    import Radio2 from '~/components/v1/DynamicField1/includes/_Radio2'
    import mixins from '~/components/v1/PurchaseEditor6/_mixins'

    export default {
        mixins: [mixins],
        components: {
            Radio2,
        },
        props: {
            data: {
                type: Object,
                default: null
            },
            updater: undefined
        },
        data(){
            return {
                items: ['New', 'Used'],
                resources: {
                    purchases: {
                        type: "purchases",
                        attributes: {
                            "condition": ""
                        },
                        relationships: {
                            application: {
                                data : {
                                    type: "applications",
                                    id: null
                                }
                            }
                        }
                    }
                },

                labels: {
                    "condition": "Condition",
                  
                },
                validation_rules: {
                    "condition": "required|string|in:New,Used",
                },

            }
        },
        mounted() {
            this.updateResourceFields()
        },
        computed:{
            isFormTypeEdit(){
                return this.data != null
            }
        },
        methods: {
            ...mapActions('resource-actions', [
                'getApplicationLoan',
                'updateLoan',
                'createLoan',
                'getPurchases',
                'deletePurchase',
                'createPurchase',
                'updatePurchases',
                'getAutoImages'
            ]),
            async handleSelectCondition(val){
                this.resources.purchases.attributes.condition = val

                // const valid = await this.$refs.observer.validate()

                this.$emit('select', this.resources.purchases)

            },
            // Submit Observer
            async onSubmit(){
                this.handleSelectCondition()
            },
            reset(){

                this.resources.purchases =  {
                        type: "purchases",
                        attributes: {
                            "condition": ""
                        },
                        relationships: {
                            application: {
                                data : {
                                    type: "applications",
                                    id: null
                                }
                            }
                        }
                    }

                this.$refs.observer.reset()
            },
            updateResourceFields(){

                if(this.isFormTypeEdit){
                    this.resources.purchases = this.filterResourceFields(this.resources.purchases, this.data)
                } else {
                    this.resources.purchases.relationships.application.data.id = this.appId
                }
            }
        },
        watch: {
            updater(val) {

                this.updateResourceFields()
                
            },
        }
    }
</script>

<style scoped lang="scss">

</style>
