<template>
    <v-dialog
        v-model="showModal"
        persistent
        max-width="850"
        >

        <v-card flat :disabled="loading">

            <v-progress-linear color="secondary" indeterminate stream absolute top v-if="loading"></v-progress-linear>

            <v-card-title class="headline white lighten-2 pb-2">
                <v-spacer></v-spacer>
                <span v-if="activeStep === 1" class="v-card__title sm font-size-22">Reference Type</span>
                <span v-else-if="activeStep === 2" class="v-card__title sm font-size-22">Reference Details</span>
                <span v-else class="v-card__title sm font-size-22">Reference Address</span>
                <v-spacer></v-spacer>
                <v-btn fab depressed text x-small @click="closeModal">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-card-title>

            <v-card-text class="pb-5" :class="vb.mdAndUp ? 'px-16' : ''">
                <v-stepper v-model="activeStep" alt-labels>
                    <v-stepper-items>
                        <v-stepper-content class="pa-0" step="1">
                            <v-row :justify="referenceSelections.length <= 3 ? 'center' : 'start'"
                                :style="referenceSelections.length <= 3 && vb.mdAndUp ? 'width: 55%;' : 'width: 100%;'"
                                align="center" class="rounded-lg light_gray_1 pa-2 mx-auto">
                                <v-col v-for="(refKind, key) in referenceSelections" :key="key" cols="12" :md="referenceSelections.length <= 3 ? '12' : '6'">
                                    <v-card
                                        flat
                                        height="100%"
                                        @click="nextStep(refKind.name)"
                                        :style="selectedItem == refKind.name ? `background-color: ${hexToRGBA(getActiveTheme.colors.primary, 0.1)}` : ''"
                                        rounded="lg"
                                        class="d-flex pa-4 align-center justify-center">
                                        <div class="flex-options body-2 font-weight-medium">
                                            {{refKind.name}}
                                        </div>
                                        <v-spacer></v-spacer>
                                        <v-btn color="secondary" :width="86" small depressed>
                                            Select
                                        </v-btn>
                                    </v-card>
                                </v-col>
                            </v-row>
                        </v-stepper-content>
                        <v-stepper-content class="pa-0" step="2">
                            <ReferenceDetails
                                v-if="isEdit"
                                ref="reference-details"
                                :sync-reset="isReset"
                                :selected-reference="selectedItem"
                                :reference="form.reference"
                                @change-form="setReferenceDetails"/>
                            <ReferenceDetails
                                v-else
                                ref="reference-details"
                                :sync-reset="isReset"
                                :reference="{}"
                                :selected-reference="selectedItem"
                                @change-form="setReferenceDetails"/>
                        </v-stepper-content>
                        <v-stepper-content class="pa-0" step="3">
                            <AddressDetails
                                v-if="isEdit"
                                ref="reference-address"
                                :sync-reset="isReset"
                                :address="form.address"
                                @change-form="setAddressDetails"/>
                            <AddressDetails
                                v-else
                                ref="reference-address"
                                :sync-reset="isReset"
                                :address="{}"
                                @change-form="setAddressDetails"/>
                        </v-stepper-content>
                    </v-stepper-items>
                </v-stepper>
            </v-card-text>

            <v-card-actions class="pa-8 pa-md-6 flex-wrap controls">
                <div class="mx-auto">
                    <v-btn
                        class="mr-3"
                        @click="back"
                        depressed
                        :min-width="140" large outlined
                        color="secondary"
                        text>Back</v-btn>

                    <v-btn
                        v-if="!isFirstStep"
                        depressed
                        @click="nextStep"
                        :min-width="140" large
                        color="secondary"
                        >Next</v-btn>
                </div>
            </v-card-actions>

        </v-card>
    </v-dialog>
</template>

<script>

    import { mapState, mapMutations, mapActions, mapGetters } from 'vuex'

    import ReferenceDetails from '~/components/v1/ReferenceEditor2/_ReferenceDetails'
    import AddressDetails from '~/components/v1/ReferenceEditor2/_AddressDetails'

    export default {
        name: 'AddReference',

        components: {
            ReferenceDetails,
            AddressDetails
        },

        props: {
            supportData: {
                type: Object,
                default: () => {}
            },
            referenceData: {
                type: Object,
                default: () => {}
            },
            addressData: {
                type: Object,
                default: () => {}
            },
            appId: {
                type: String,
                default: null
            },
            action: {
                type: String,
                default: 'add'
            },
            showModal: {
                type: Boolean,
                default: false
            }
        },

        computed: {
            ...mapState('flows', ['loanType']),

             ...mapGetters('themes', [
                'getActiveTheme'
            ]),

            isLastStep() {
                return this.activeStep === 3
            },

            vb() {
                return this.$vuetify.breakpoint
            },

            isFirstStep() {
                return this.activeStep === 1
            },

            references() {
                if(this.action == 'edit' && Object.keys(this.referenceData).length > 0)
                    return {
                        id: this.referenceData?.id,
                        relationships: this.referenceData?.relationships,
                        attributes: this.referenceData?.attributes,
                        type: 'references'
                    }
                else
                    return { attributes: {}, relationships: this.supportData?.reference[0]?.relationships , type: 'references' }
            },
            addresses() {
                if(this.action == 'edit' && Object.keys(this.addressData).length > 0)
                    return {
                        id: this.addressData?.id,
                        relationships: this.addressData?.relationships,
                        attributes: this.addressData?.attributes,
                        type: 'addresses'
                    }
                else
                    return { attributes: {}, relationships: this.supportData?.address[0]?.relationships, type: 'addresses' }
            },
            referenceKinds() {
                return this?.$store.state.kinds.kinds.find( ref => {
                    return ref.name === 'Reference'
                })
            },
            referenceSelections() {
                if ( this.loanType === 'Commercial' ) {
                    return this.referenceKinds.children.filter( ref => {
                        return ref.name === 'Accountant' || ref.name === 'Business'
                    })
                }
                return this.referenceKinds.children
            }
        },

        watch: {
            async showModal(val) {
                await this.initialize()
            },
        },

        methods: {
            ...mapActions('resource-actions', [
                'saveReferences',
                'saveReferencesOfEmptyRecord'
            ]),
            ...mapMutations('dialogs', [
                'toggleTouched',
            ]),
            ...mapMutations('utils', ['toggleQuickMessage']),

            setReferenceDetails(data) {
                this.form['reference'] = data.form
            },
            setAddressDetails(data) {
                this.form['address'] = data.form
            },
            save(params) {
                this.$emit('create-record', params)
            },
            hexToRGBA(hex, a = 1){
                return this.$options.filters.hexToRGBA(hex, a)
            },
            async nextStep(item = {}) {
                if(this.activeStep === 1) {
                    this.selectedItem = item
                    this.getSelectedReferenceKind()
                    this.activeStep++
                } else if(this.activeStep === 2) {

                    const valid = await this.$refs['reference-details'].$refs.observer.validate()

                    if(valid) {
                        this.activeStep++
                    }

                } else {

                    const valid = await this.$refs['reference-address'].$refs.observer.validate()

                    if(valid) {

                        this.loading = true

                        this.form.reference['name'] = this.$options.filters.formalAndCapitalize(this.form.reference['name'])
                        this.form.reference['business-name'] = this.$options.filters.formalAndCapitalize(this.form.reference['business-name'])

                        let res = null

                        if (this.action === 'add') {

                            res = await this.saveReferencesOfEmptyRecord({
                                payload: this.form,
                                data: {
                                    reference: this.filteredRelationships(this.references),
                                    address: this.filteredRelationships(this.addresses)
                                }
                            })

                            if(this.isAllPromisesValid(res)) {
                                this.$emit('create-record', res)
                                this.closeModal()
                                //this.toggleQuickMessage({text: 'You have successfully added a new reference!', color: 'success'})
                            }
                        }
                        else {
                            res = await this.saveReferences({
                                payload: this.form,
                                data: [
                                    { data: this.references, ref: 'reference' },
                                    { data: this.addresses, ref: 'address' }
                                ]
                            })

                            if(res.status === 200) {
                                //this.toggleQuickMessage({text: 'Selected reference has been updated!', color: 'success'})
                            }

                            if(this.isAllPromisesValid(res)) {
                                this.$emit('update-record', res)
                                this.isReset = true
                                this.closeModal()
                                //this.toggleQuickMessage({text: 'Selected reference has been updated!', color: 'success'})
                            }

                        }

                        this.loading = false

                    }
                }
            },
            getSelectedReferenceKind() {

                let kind = {}

                let ref = this.referenceKinds.children.find( (skind) => {
                    return skind.name == this.selectedItem
                })

                if(ref)
                    kind = {
                        data: {
                            id: ref.id,
                            type: 'kinds'
                        }
                    }
                else
                    kind = {
                        data: {
                            id: null,
                            type: 'kinds'
                        }
                    }

                if(this.references.relationships && this.references.relationships.kind){

                    this.references.relationships.kind = kind //{ id: this.selectedKind.id, type: 'kinds' }

                } else {

                    this.references.relationships = {
                        'address': {
                            'data': { 'id': null, 'type': 'addresses' }
                        },
                        'application': {
                            'data': { 'id': this.appId, 'type': 'applications' }
                        },
                        'kind': kind
                    }
                }

            },
            async initialize() {
                if ( this.action === 'edit' ) {

                    if(this.references?.attributes)  this.form.reference = await this.references.attributes
                    if(this.addresses?.attributes) this.form.address = await this.addresses.attributes

                    this.selectedItem = this.getSelectedItem()

                    this.form.reference.date = this.$options.filters.dateFormat(this.form.reference.date)

                    this.isEdit = true

                }
                else {
                    this.isEdit = false

                    this.form = {
                        reference: {},
                        address: {}
                    }

                    this.form.reference.date = this.$moment().format('YYYY-MM-DD')
                    this.form.reference.relationship = this.selectedItem === 'Accountant' ? 'Accountant' : ''

                    // this.form.reference['is-business'] = this.buttonLabel === 'Personal' ? 'No' : 'Yes'
                }
                this.googleSearch = ''
                this.$emit('selected-item', false)
                if (this.isPersonal) this.form.reference['is-business'] = "No"
            },
            async back() {
                if(this.isFirstStep) {
                    this.closeModal()
                    this.activeStep = 1
                } else {
                    if(this.activeStep === 2) {
                        await this.$refs['reference-details'].$refs.observer.reset()
                    } else {
                        await this.$refs['reference-address'].$refs.observer.reset()
                    }
                    this.activeStep --
                }
            },

            getSelectedItem() {
                let item = ''

                if(this.references?.relationships?.kind?.data?.id) {
                    this.referenceSelections.forEach(ref => {
                        if(ref.id == this.references.relationships.kind.data.id) {
                            item = ref.name
                        }
                    })
                }

                return item
            },

            async closeModal() {
                this.activeStep = 1
                this.selectedItem = ''
                await this.$refs['reference-details'].$refs.observer.reset()
                await this.$refs['reference-address'].$refs.observer.reset()
                this.isReset = !this.isReset
                this.isEdit = false
                this.$emit('close-modal')
            },

            filteredRelationships(data) {

                if(data && data.hasOwnProperty('relationships')){

                    let filterData = {...data}

                    if(filterData.relationships?.validations){
                        delete filterData.relationships.validations
                    }

                    return filterData

                }

                return data
            },
            isAllPromisesValid(responses) {

                let valid = responses.length > 0 ? true : false

                if (valid)
                    responses.forEach( res => {
                        if (res.status != 200 && res.status != 201) valid = false
                    })

                return valid

            },
        },

        data() {
            return {
                isReset: false,
                isEdit: false,
                loading: false,
                activeStep: 1,
                selectedItem: '',
                form: {
                    reference: null,
                    address: null
                },
                links: [
                    { text: 'Personal' },
                    { text: 'Business' },
                    { text: 'Landlord' },
                    { text: 'Accountant' }
                ]
            }
        }
    }
</script>
<style lang="scss" scoped>
</style>
