<template>
  <div v-if="vb.mdAndUp" class="d-flex" style="width: 100%">
    <v-sheet
      color="light_gray_1"
      class="py-8 px-12"
      width="50%"
      outlined
      elevation="0" >
      <v-img
        max-width="120"
        class="flex-brand"
        :src="require(`~/assets/images/nodifi.svg`)"
        position="center left"
        contain >
      </v-img>
      <div class="mt-2 mb-6 font-size-20">Data</div>

      <v-list color="transparent">
        <v-list-item two-line class="px-0" v-for="item in getNodifiData" :key="item.name">
          <v-list-item-content>
            <v-list-item-title class="font-weight-medium" >{{ item.name }}</v-list-item-title >
            <v-list-item-subtitle>{{ item.value }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>

    </v-sheet>
    <v-sheet class="py-8 px-12" width="50%">
      <v-img
        max-width="120"
        class="flex-brand"
        :src="require(`~/assets/images/logo/cropped/lender-logo-macquarie.png`)"
        position="center left"
        contain >
      </v-img>
      <div class="mt-2 mb-8 font-size-20">Requirements</div>
        <ValidationObserver ref="observer" tag="form">

          <!-- // Person Fields -->
          <template v-if="data.type == 'people'">

                <ValidationProvider
                    key="beneficiaryType"
                    v-slot="{ errors }"
                    :name="labels.beneficiaryType"
                    :rules="getRules('beneficiaryType')"
                    v-if="isIncluded('beneficiaryType')"
                    :vid="labels.beneficiaryType">
                    <v-select
                    v-model="resources.entity.attributes.lender.macquarie.beneficiaryType"
                      :items="getOptions('beneficiaryType')"
                      :error-messages="errors"
                      color="secondary"
                      :label="labels.beneficiaryType"
                    ></v-select>
                  </ValidationProvider>

                  <ValidationProvider
                      key="name"
                      v-slot="{ errors }"
                      :name="labels.name"
                      :rules="getRules('name')"
                      v-if="isIncluded('name')"
                      vid="name">
                    <v-text-field
                        tile
                        type="text"
                        :label="labels.name"
                        color="secondary"
                        :error-messages="errors"
                        v-model="resources.entity.attributes.lender.macquarie.name"
                        required>
                    </v-text-field>
                  </ValidationProvider>

                <ValidationProvider
                    key="driversLicenceType"
                    v-slot="{ errors }"
                    :name="labels.driversLicenceType"
                    :rules="getRules('driversLicenceType')"
                    v-if="isIncluded('driversLicenceType')"
                    :vid="labels.driversLicenceType">
                    <v-select
                    v-model="resources.entity.attributes.lender.macquarie.driversLicenceType"
                      :items="getOptions('driversLicenceType')"
                      :error-messages="errors"
                      color="secondary"
                      :label="labels.driversLicenceType"
                    ></v-select>
                  </ValidationProvider>

                  <ValidationProvider
                    key="otherHasCircumstancesChanging"
                    v-slot="{ errors }"
                    :name="labels.otherHasCircumstancesChanging"
                    :rules="getRules('otherHasCircumstancesChanging')"
                    v-if="isIncluded('otherHasCircumstancesChanging')"
                    :vid="labels.otherHasCircumstancesChanging">
                      <ButtonGroup3
                        v-model="resources.entity.attributes.lender.macquarie.otherHasCircumstancesChanging"
                        :error-messages="errors"
                        :options="['YES', 'NO']"
                        :label="labels.otherHasCircumstancesChanging" />
                  </ValidationProvider>
                
                  <ValidationProvider
                    key="householdReason"
                    v-slot="{ errors }"
                    :name="labels.householdReason"
                    :rules="getRules('householdReason')"
                    v-if="isIncluded('householdReason')"
                    :vid="labels.householdReason">
                    <v-select
                    v-model="householdReason"
                      :items="getOptions('householdReason')"
                      :error-messages="errors"
                      color="secondary"
                      :label="labels.householdReason"
                    ></v-select>
                  </ValidationProvider>
          </template>

          <!-- // Business Fields -->

          <template v-if="data.type == 'businesses'">
              <ValidationProvider
                key="organisationType"
                v-slot="{ errors }"
                :name="labels.organisationType"
                :rules="getRules('organisationType')"
                v-if="isIncluded('organisationType')"
                :vid="labels.organisationType">
                <v-select
                v-model="resources.entity.attributes.lender.macquarie.organisationType"
                  :items="getOptions('organisationType')"
                  :error-messages="errors"
                  color="secondary"
                  :label="labels.organisationType"
                ></v-select>
              </ValidationProvider>

              <ValidationProvider
                key="isProprietaryCompany"
                v-slot="{ errors }"
                :name="labels.isProprietaryCompany"
                :rules="getRules('isProprietaryCompany')"
                v-if="isIncluded('isProprietaryCompany')"
                :vid="labels.isProprietaryCompany">
                  <ButtonGroup3
                    v-model="resources.entity.attributes.lender.macquarie.isProprietaryCompany"
                    :error-messages="errors"
                    :options="['YES', 'NO']"
                    :label="labels.isProprietaryCompany" />
              </ValidationProvider>
          </template>


        </ValidationObserver>
    </v-sheet>
  </div>

  <!-- mobile version -->
  <div v-else>
      <v-card
        flat
        class="mx-5 rounded-xl"
        :style="`background-color: ${hexToRGBA( getActiveTheme.colors.secondary, 0.2 )}`">
        <v-card-text class="d-flex py-1">
          <v-row>
            <v-col cols="6" class="pa-1">
              <v-card
                min-height="45"
                flat
                class="rounded-xl d-flex align-center justify-center"
                :color="tabSelection == 'nodifi' ? 'white' : 'transparent'" >
                <div class="d-flex" @click="selectTab('nodifi')" >
                  <v-img
                    max-width="55"
                    class="flex-brand"
                    :src="require(`~/assets/images/nodifi.svg`)"
                    position="center left"
                    contain >
                  </v-img>
                </div>
              </v-card>
            </v-col>
            <v-col cols="6" class="pa-1">
              <v-card
                min-height="45"
                flat
                class="rounded-xl d-flex align-center justify-center"
                :color="tabSelection == 'macquarie' ? 'white' : 'transparent'" >
                <div class="d-flex" @click="selectTab('macquarie')">
                    <v-img
                      max-width="45"
                      class="flex-brand"
                      :src="require(`~/assets/images/logo/cropped/lender-logo-macquarie.png`)"
                      position="center left"
                      contain >
                    </v-img>
                </div>
              </v-card>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>

      <v-list v-show="tabSelection == 'nodifi'" class="mx-8" color="transparent">
        <v-list-item two-line class="px-0" v-for="item in getNodifiData" :key="item.name">
          <v-list-item-content>
              <v-list-item-title class="font-weight-medium">
                  {{ item.name }}
              </v-list-item-title>
              <v-list-item-subtitle>              
                  {{ item.value }}
              </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>

      <div v-show="tabSelection != 'nodifi'" class="mx-8">
          <ValidationObserver ref="observer" tag="form">
          <!-- // Person Fields -->
          <template v-if="data.type == 'people'">

                <ValidationProvider
                    key="beneficiaryType"
                    v-slot="{ errors }"
                    :name="labels.beneficiaryType"
                    :rules="getRules('beneficiaryType')"
                    v-if="isIncluded('beneficiaryType')"
                    :vid="labels.beneficiaryType">
                    <v-select
                    v-model="resources.entity.attributes.lender.macquarie.beneficiaryType"
                      :items="getOptions('beneficiaryType')"
                      :error-messages="errors"
                      color="secondary"
                      :label="labels.beneficiaryType"
                    ></v-select>
                  </ValidationProvider>

                  <ValidationProvider
                      key="name"
                      v-slot="{ errors }"
                      :name="labels.name"
                      :rules="getRules('name')"
                      v-if="isIncluded('name')"
                      vid="name">
                    <v-text-field
                        tile
                        type="text"
                        :label="labels.name"
                        color="secondary"
                        :error-messages="errors"
                        v-model="resources.entity.attributes.lender.macquarie.name"
                        required>
                    </v-text-field>
                  </ValidationProvider>

                <ValidationProvider
                    key="driversLicenceType"
                    v-slot="{ errors }"
                    :name="labels.driversLicenceType"
                    :rules="getRules('driversLicenceType')"
                    v-if="isIncluded('driversLicenceType')"
                    :vid="labels.driversLicenceType">
                    <v-select
                    v-model="resources.entity.attributes.lender.macquarie.driversLicenceType"
                      :items="getOptions('driversLicenceType')"
                      :error-messages="errors"
                      color="secondary"
                      :label="labels.driversLicenceType"
                    ></v-select>
                  </ValidationProvider>

                  <ValidationProvider
                    key="otherHasCircumstancesChanging"
                    v-slot="{ errors }"
                    :name="labels.otherHasCircumstancesChanging"
                    :rules="getRules('otherHasCircumstancesChanging')"
                    v-if="isIncluded('otherHasCircumstancesChanging')"
                    :vid="labels.otherHasCircumstancesChanging">
                      <ButtonGroup3
                        v-model="resources.entity.attributes.lender.macquarie.otherHasCircumstancesChanging"
                        :error-messages="errors"
                        :options="['YES', 'NO']"
                        :label="labels.otherHasCircumstancesChanging" />
                  </ValidationProvider>

                  <ValidationProvider
                    key="householdReason"
                    v-slot="{ errors }"
                    :name="labels.householdReason"
                    :rules="getRules('householdReason')"
                    v-if="isIncluded('householdReason')"
                    :vid="labels.householdReason">
                    <v-select
                    v-model="householdReason"
                      :items="getOptions('householdReason')"
                      :error-messages="errors"
                      color="secondary"
                      :label="labels.householdReason"
                    ></v-select>
                  </ValidationProvider>

          </template>

          <!-- // Business Fields -->

          <template v-if="data.type == 'businesses'">
              <ValidationProvider
                key="organisationType"
                v-slot="{ errors }"
                :name="labels.organisationType"
                :rules="getRules('organisationType')"
                v-if="isIncluded('organisationType')"
                :vid="labels.organisationType">
                <v-select
                v-model="resources.entity.attributes.lender.macquarie.organisationType"
                  :items="getOptions('organisationType')"
                  :error-messages="errors"
                  color="secondary"
                  :label="labels.organisationType"
                ></v-select>
              </ValidationProvider>

              <ValidationProvider
                key="isProprietaryCompany"
                v-slot="{ errors }"
                :name="labels.isProprietaryCompany"
                :rules="getRules('isProprietaryCompany')"
                v-if="isIncluded('isProprietaryCompany')"
                :vid="labels.isProprietaryCompany">
                  <ButtonGroup3
                    v-model="resources.entity.attributes.lender.macquarie.isProprietaryCompany"
                    :error-messages="errors"
                    :options="['YES', 'NO']"
                    :label="labels.isProprietaryCompany" />
              </ValidationProvider>
          </template>


          </ValidationObserver>
      </div>
  </div>

</template>

  <script>  
  import { mapActions, mapMutations, mapGetters } from "vuex";
  import subscriptions from "~/components/shared/subscriptions";
  import shared from "~/components/v1/submission/MacquarieEntities1/__shared";
  import ButtonGroup3 from "~/components/base/ButtonGroup3";
  
  export default {
    components: { ButtonGroup3 },
    mixins: [subscriptions, shared],
    props: {
      data: {
        type: Object,
        default: null,
      },
      addresses: {
        type: Array,
        default: ()=>[],
      },
      livingExpense: {
        type: Object,
        default: null,
      },
      updater: undefined,
      type: String
    },
    data() {
      return {
        tabSelection: "nodifi",
        resources: {
            entity: {
              id: null,
              type: null,
              attributes: {
                lender : {
                  macquarie : {
                      // Person Fields
                      otherHasCircumstancesChanging: null,
                      driversLicenceType: null,
                      beneficiaryType: null,
                      name: null,
                      // Business Fields
                      isProprietaryCompany: null,
                      organisationType: null
                    }
                }
              }
            }
        },
        householdReason: null, 
        included: {
          organisationType: false,
          beneficiaryType: false,
          name: false,
          householdReason: false,
        },
        labels: {
          otherHasCircumstancesChanging: 'Is the applicant expecting any changes to their circumstances in the foreseeable future that will impact their ability to make repayments?',
          driversLicenceType: "Driver's Licence Type",
          beneficiaryType: 'Beneficiary Type',
          name: 'Name',
          isProprietaryCompany: 'Is Company Public or Proprietary',
          organisationType: 'Organisation Type',
          householdReason: 'Reason for low household living expenses'
        },
        validation_rules: {
          attributes: {
            otherHasCircumstancesChanging: 'required',
            driversLicenceType: 'required',
            beneficiaryType: 'required',
            name: 'required',
            isProprietaryCompany: 'required',
            organisationType: 'required',
            householdReason: 'required',
          },
        },
        options: {
          driversLicenceType: [
            {text:'Full', value: 'FULL'},
            {text:'Provisional', value: 'PROVISIONAL'},
            {text:'Learner', value: 'LEARNER'},
            {text:'Heavy Vehicle', value: 'HEAVY_VEHICLE'},
            {text:'None', value: 'NONE'},
          ],
          beneficiaryType: [
            {text:'Class', value: 'CLASS'},
            {text:'Individual', value: 'INDIVIDUAL'},
          ],
          organisationType: [
            {text:'Family Trust', value: 'FAMILY_TRUST'},
            {text:'Trading Trust', value: 'TRADING_TRUST'},
            {text:'Property Trust', value: 'PROPERTY_TRUST'},
          ],
          householdReason: [
            {text: 'Living with family', value: 'LIVING_WITH_FAMILY' },
            {text: 'Senior Citizen', value: 'SENIOR_CITIZEN' },
            {text: 'Pre-tax Amount', value: 'PRE_TAX_AMOUNT' },
            {text: 'Rural Residence', value: 'RURAL_RESIDENCE' },
            {text: 'Others', value: 'OTHER' },
          ],
        },
        errors: [],
        companyAddress: null
      };
    },
    computed: {
      getNodifiData(){

         let business = [
            {
              name: 'Company Address', 
              value: this.getFullAddress(this.companyAddress)
            },
          ]

         let person = [
          {
            name: 'Driver Licence Number ', 
            value: this.data.attributes['drivers-licence-number']
          }, 
          {
            name: 'Expiry Date', 
            value: this.data.attributes['drivers-licence-expiry']
          },
          {
            name: 'Driver Licence State', 
            value: this.data.attributes['drivers-licence-state']
          }
        ]

        return (this.data?.type == 'businesses') ? business : person

      },
      isCompany(){
        return this.data?.relationships?.kind?.data?.id == '5-2'
      },
      isTrust(){
        return this.data?.relationships?.kind?.data?.id == '5-4'
      },
    },
    methods: {
      ...mapActions("resource-actions", [
          "getPerson", 
          "getBusiness" 
        ]),
      selectTab(tab) {
        this.tabSelection = tab;
      },
      async validate() {

        const valid = await this.$refs.observer.validate();

        let allValid = this.errors.length == 0 && valid

        if (allValid) {
  
          this.$emit("submit", {
            entity: this.resources.entity,
            expense: this.householdReason
          });
        }

      },
      reset() {
        this.$refs.observer.reset();
      },
      async updateResourceFields() {
        // console.log('updateResourceFields')

        this.$emit("loading", true);

        const { data } = this;

        if (data) {

          let res = null

          if(data.type == "businesses") {

            res = await this.getBusiness(data.id);

            if(res.status == 200) {
            
              let companyAddress = res.data.data.relationships.addresses.data?.[0]

              if(companyAddress){

                let address = this.addresses.find(address => address.id == companyAddress?.id)

                this.companyAddress = address
              }

            }

          } else {

            res = await this.getPerson(data.id);

          }

          if(this.isConsumer) {
              if(this.livingExpense){
                this.householdReason = this.livingExpense?.attributes?.lender?.macquarie?.householdReason
              }
          }

          if(res.status == 200) {
            this.resources.entity.id = data.id
            this.resources.entity.type = data.type
            this.resources.entity.attributes.lender.macquarie = res.data.data.attributes.lender.macquarie
          }


          // Hide / Show Fields
          // console.log(this.data?.relationships?.kind?.data?.id)
          this.included.organisationType = this.isTrust
          this.included.beneficiaryType = this.isTrustApplication
          this.included.name = this.isTrustApplication
          this.included.householdReason = this.isConsumer

          this.$emit("loading", false);

        } else {
          this.$emit("loading", false);
        }
      },
    },
    watch: {
      updater: {
        handler(val) {
          this.updateResourceFields();
        },
        immediate: true
      }

    },
  };
  </script>
  
  <style lang="scss" scoped></style>
  