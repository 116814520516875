<template>
    <v-card class="mx-auto" tile flat>
        <v-card-text class="pa-4 pt-0 pa-md-10 pt-md-0">
            <div class="body-1 font-weight-bold mb-6">Terms and Acknowledgements</div>

            <ValidationObserver ref="observer" tag="form">

                <template v-for="(term, index) in terms">
                    <ValidationProvider
                        v-slot="{ errors }"
                        :name="term.attributes.content"
                        rules="required"
                        :vid="term.attributes.content">    

                        <v-checkbox
                            v-model="checkboxes"
                            :value="term.attributes.content"
                            color="secondary"
                        >
                            <template v-slot:label>
                                <div v-html="term.attributes.content"></div>
                            </template>
                        </v-checkbox>

                </ValidationProvider>
                </template>

                <!-- {{ checkboxes }} -->

            </ValidationObserver>

            <v-alert dense outlined type="error" v-if="errors.length > 0">
                <div v-for="msg in errors" v-html="msg"></div>
            </v-alert>

        </v-card-text>
     
    </v-card>
</template>

<script>

    import { mapActions, mapState, mapMutations  } from 'vuex'
    import helpers from '~/plugins/helpers'
    import subscriptions from '~/components/shared/subscriptions'

    export default {
        components: { },

        mixins: [subscriptions, helpers],

        data() {
            return {
                resources: {
                },

                terms: [],
                people: [],
                checkboxes: [],
                errors: [],

                labels: {},
                validation_rules: {},
                hiddens: {},
                prefix: {},
                fieldLayout: [],
                options: {}
            }
        },
        computed: {
            ...mapState({
                user: state => state.auth.user,
                appId: state => state.flows.appId,
                loanData: state => state.flows.loanData,
                calculation: state => state.flows.calculation,
                lenderSubmissionTo: state => state.flows.lenderSubmissionTo,
                termGroup: state => state.flows.termGroup,
                commitments: state => state.flows.commitments,
                appData: state => state.flows.appData
            }),
            storeLoanData(){
                 return this.loanData
            },
            getLoanType(){
                 return this.loanData?.attributes['loan-type']
            },
            getAppType(){
                 return this.calculation['application-type']
            },
            getLoanId(){
                return this.appData.relationships.loan.data.id
            },
            isCommitmentExists(){

                let exists = false
                
                if(this.commitments && this.terms.length > 0){

                    return true

                }

                return exists
            },
        },
        methods: {
            ...mapActions('resource-actions', [
                'getLoan',
                'updateLoan',

                'getTerms',
                'getPeople',
                'getCommitments',
                'createCommitments',
            ]),
            ...mapMutations('flows', [
                'setCommitments'
            ]),
            async pull() {

                // NOTE: Keeping it here if termGroup didn't work as expected
                // let termSlug = 'now-pl-submission-individual'
                // if(this.getAppType == 'Consumer - Joint') {
                //     termSlug = 'now-pl-submission-joint'
                // }

                let promise1 =  await this.getTerms(this.termGroup)

                let promise2 = this.getPeople()
                // let promise3 = this.$api.get(`applications/${this.appId}?include=commitments`)

                const res = await Promise.all([
                        promise1, 
                        promise2
                    ]) 

                promise1 = res[0]
                promise2 = res[1]

                // console.log(promise1)
                // console.log(promise2)

                if(promise1.status == 200) {
                    this.terms = promise1.data.data
                }

                if(promise2.status == 200) {
                    this.people = promise2.data.data
                }

                if(this.isCommitmentExists) {

                    let termsArray = []

                    this.terms.forEach(term => {
                        termsArray.push(term.attributes.content)
                    });

                    this.checkboxes = termsArray

                }


            },
            async push() {

                // Validate Checkboxes

                this.checkCompleted()
      
                let valid = this.errors.length == 0

                let status = valid ?  'valid' : 'dirty'

                if(valid && !this.isCommitmentExists) {

                    let userId = this.appData.relationships.owner?.data?.id;

                    if(this.appData.attributes.target == 'Assister') {
                        userId = this.user.id;
                    }

                    let termsData = this.terms.map(term => {
                        return {
                            id: term.id,
                            type: 'terms'
                        }
                    });

                    let peopleData = this.people.map(person => {
                        return {
                            id: person.id,
                            type: 'people'
                        }
                    });

                    let payload = {  
                        "type": "commitments",
                        "attributes": {},
                        "relationships": {
                            "application": {
                                "data": {
                                    "type":"applications",
                                    "id": this.appId
                                },

                            },
                            "committed-users": {
                                "data": [
                                    {
                                        "type": "users",
                                        "id": userId
                                    }
                                ]
                            },
                            "committed-people": {
                                "data": peopleData // all people in  this app
                            },
                            "terms" : {
                                "data": termsData // all the terms
                            }
                        }
                    }

                    let res = await this.createCommitments(payload)

                    this.setCommitments(res.data.data)

                    return {valid, status, data:{}}

                } else {

                    return {valid, status, data:{}}
                }


                
            },
            checkCompleted(){

                this.errors = []

                if(this.checkboxes.length != this.terms.length) {
                    this.errors.push('Please accept all terms to proceed')
                } 

            },
        },
        watch: {
            checkboxes(val) {
                if(val.length == this.terms.length) {
                    this.errors = []
                }
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>