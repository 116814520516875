<template>
    <FlexWrap @resize="handleResize">
        <v-card :disabled="isDisabled || !validComponentData" class="mx-auto" tile flat>
            <v-card-text class="pa-4 pt-0 pa-md-10 pt-md-0">
                <v-card tile flat class="mx-auto" max-width="500">
                    <v-container pa-0 fluid>
                        <v-row>
                            <v-col cols="12">
                                <ValidationObserver ref="observer" tag="form">

                                    <div class="flex-form" v-for="(layout, lkey) in fieldLayout" :key="lkey">
                                        <template v-for="(field, key) in fieldLayoutMaker(layout, resources.people.attributes)">
                                            <div class="flex-field" :key="key">
                                                <ValidationProvider 
                                                    v-if="visible[key]"
                                                    v-slot="{ errors }" 
                                                    :name="labels[key]" 
                                                    :rules="getRules(key)" :vid="key">

                                                    <v-select
                                                    v-if="isFieldType('picklist', key)"
                                                    :items="getOptions(key)"
                                                    :label="labels[key]"
                                                    :error-messages="errors"
                                                    v-model="resources.people.attributes[key]"
                                                    color="secondary">
                                                    </v-select>
                                                    
                                                    <template v-else-if="isFieldType('date', key)">
                                                    <flex-date-picker
                                                        :label="labels[key]"
                                                        :errors="errors"
                                                        v-model="resources.people.attributes[key]"
                                                    ></flex-date-picker>
                                                    </template>

                                                    <v-text-field 
                                                    v-else
                                                    :type="'text'"
                                                    :label="labels[key]"
                                                    :error-messages="errors"
                                                    v-model="resources.people.attributes[key]"
                                                    color="secondary">
                                                    </v-text-field>
                                                </ValidationProvider>
                                            </div>
                                        </template>
                                        <!-- Temp Spacer -->
                                        <div class="flex-field" v-for="index in 6" :key="index"></div>
                                    </div>

                                </ValidationObserver>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card>
            </v-card-text>
        </v-card>
    </FlexWrap>
</template>

<script>

    import subscriptions from '~/components/shared/subscriptions'
    import { mapState, mapGetters, mapActions, mapMutations } from 'vuex'
    import FlexDatePicker from '~/components/base/FlexDatePicker'  
    import fieldOptions from '~/components/shared/field-options'
    import FlexWrap from '~/components/base/FlexWrap'
    import helpers from '~/plugins/helpers'

    import { getDateValidations } from '~/plugins/date-helper.js'
    const { minDate } = getDateValidations()

    export default {
        components: {
            FlexDatePicker,
            FlexWrap
        },
        mixins: [subscriptions, helpers],
        data(){
            return {
                resources: {
                    people : {
                        type: "people",
                        id: null,
                        attributes: {
                            "credit-status": "",
                            "previous-bankrupt": "",
                            "bankruptcy-status": "",
                            "bankruptcy-declared-date": "",
                            "bankruptcy-discharge-date": ""
                        },
                        relationships: {
                            application: {
                                data: {type: "applications", id: null}
                            }
                        }
                    }
                },
                fieldLayout: [
                    ['credit-status'],
                    [
                        "previous-bankrupt",
                        "bankruptcy-status",
                        "bankruptcy-declared-date",
                        "bankruptcy-discharge-date"                     
                    ]
                ],
                visible: {
                    "previous-bankrupt": true,
                    "credit-status": true,
                    "bankruptcy-status": false,
                    "bankruptcy-declared-date": false,
                    "bankruptcy-discharge-date": false
                },
                // Static field labels. cause sometimes field names and labels are different
                labels: {
                    "previous-bankrupt": "Previous Bankrupt",
                    "credit-status": "Credit History",
                    "bankruptcy-status": "Bankruptcy Status",
                    "bankruptcy-declared-date": "Bankruptcy Declared Date",
                    "bankruptcy-discharge-date": "Bankruptcy Discharge Date"
                },
                validation_rules: {
                    type: "people",
                    attributes: {
                        "previous-bankrupt": "required|string|in:Yes,No",
                        "credit-status": `required|in:${fieldOptions["credit-status"]}`,
                        "bankruptcy-status": `required_if:previous-bankrupt,Yes|in:${fieldOptions["bankruptcy-status"]}`,
                        "bankruptcy-declared-date": `required_if:previous-bankrupt,Yes|date_format:Y/m/d|after:${minDate}|before:now`,
                        "bankruptcy-discharge-date": `required_if:bankruptcy-status,Discharged Part 9,Discharged Bankrupt|date_format:Y/m/d|after:${minDate}|before:now`,
                    }
                },
                validComponentData: true
            }
        },
        computed: {
            ...mapState({
                appId: state => state.flows.appId
            }),
            ...mapGetters('kinds', [
                'kindsOfApplicant'
            ]),
            hasPeople(){
                return this.appData.relationships.people.data.length > 0
            },
            previousBankrupt(){
                return this.resources.people.attributes["previous-bankrupt"]
            },
            bankruptcyStatus(){
                return this.resources.people.attributes["bankruptcy-status"]
            }
        },
        created() {

        },
        mounted(){

            if(!this.hasPeople){
                // Error...
                this.validComponentData = false
            }

        },
        methods: {
            ...mapActions('resource-actions', [
                'getPeople',
                'updatePerson',
                'createPerson'
            ]),
            async pull() {

                let res = await this.getPeople({"include" : 'parent-parts'})

                let priority = this.getPrimaryApplicant(res.data)
                
                if(priority){
                    
                    // Object.assign(this.resources.people, res.data)
                    this.resources.people = this.resourceFieldPicker(this.resources.people, priority)

                } else {

                    // Error...
                    this.validComponentData = false
                }

            },
            async push() {

                const observer = this.$refs.observer
     
                const {people} = this.resources

                // Exclude relationships and other stuff
                let payload = {
                    type: people.type,
                    id: people.id,
                    attributes: this.excludeInvalids(people.attributes, observer.fields)
                }

                let personRes = null

                if(this.hasPeople) {

                    personRes = this.updatePerson(payload)

                } else {

                    // Include relationships when creating resource

                    payload.relationships = {
                        application: {
                            data: {type: "applications", id: this.appId}
                        }
                    }

                    personRes = this.createPerson(payload)
                }

                const {valid, status} = await this.validateFormData()
                const data = await this.appDataHelper([personRes])

                return {valid, status, data}

            },
            getPrimaryApplicant(data){
                
                /**
                 * 1. check if data has included
                 * 2. filter included for 'parts'
                 * 3. select parts with kinds 'applicant'
                 * 4. select person based on selected part
                 */

                if(!data.included){
                    return null;
                }

                let parts = data.included.filter(part => {
                    return part.type == 'parts'
                })

                let applicants = [];	
                let primaryApplicant = null 

                parts.forEach((part) => {
                    
                    if (!part.relationships.kind.data) {	
                        // person must have part with kind
                        return null
                    }
                    
                    if (part.relationships.kind.data.id == this.kindsOfApplicant.id) {
                        applicants.push(part);
                    }
                })

                if (applicants.length == 0) {

                    // no applicant
                    return null

                } else if (applicants.length == 1) {
                    
                    if (applicants[0].attributes.priority == "1") {

                        primaryApplicant = applicants[0];

                    } else {

                        // primary applicant must be priority 1
                        return null
                        
                    }

                } else if (applicants.length > 1) {
                    
                    let primaryApplicants = applicants.filter((applicant) => {
                        return applicant.attributes.priority == "1"
                    })
                    
                    if( primaryApplicants.length != 1) {	

                        // too many, or too few primary applicants (must be 1 and only 1)
                        return null
                        
                    } else {		

                        primaryApplicant = primaryApplicants[0];
                    }

                }

                let applicant = data.data.find( person => {
                    if(person.relationships['parent-parts'].data[0]){
                        return person.relationships['parent-parts'].data[0].id == primaryApplicant.id
                    }
                })

                // Success!
                return applicant 
            
            },
        },
        watch: {
            previousBankrupt(val){

                if(val == 'No' || val == null){

                    this.resources.people.attributes["bankruptcy-status"] = ""
                    this.resources.people.attributes["bankruptcy-declared-date"] = ""

                    this.visible["bankruptcy-status"] = false
                    this.visible["bankruptcy-declared-date"] = false

                } 

                if(val == 'Yes'){

                    this.visible["bankruptcy-status"] = true
                    this.visible["bankruptcy-declared-date"] = true

                }

            },
            bankruptcyStatus(val){

                if(val && val.includes('Discharged')){
                    this.visible["bankruptcy-discharge-date"] = true
                } else {
                    this.resources.people.attributes["bankruptcy-discharge-date"] = ""
                    this.visible["bankruptcy-discharge-date"] = false
                }

            },
            validComponentData(val){
                if(!val) {
                    this.showComponentWarning()
                }
            },
        }
    
    }
</script>

<style scoped lang="scss">
</style>

