<template>    
    <v-container class="flex__sub-component-Incrementer1">
        <div class="body-1 text-center font-weight-medium" style="font-size: 1.1rem !important;">{{ label }}</div>
            <v-item-group v-model="index">
                <v-row align="center" justify="center">
                    <div class="d-flex pa-2 pb-0 ma-0" style="width: 100%;">
                        <v-item>
                            <div class="d-flex" style="width: 100%;">
                                <v-btn 
                                    @click="decrement" 
                                    x-small depressed fab 
                                    :disabled="!minLength"
                                    class="secondary my-0 mx-4 mt-3">
                                    <v-icon color="secondary lighten-5">mdi-minus</v-icon>
                                </v-btn>
                                <v-alert 
                                    width="100%"
                                    color="light_gray_1" 
                                    class="rounded-xl pa-0 mx-0 my-0">
                                    <v-card
                                        class="align-center rounded-xl"
                                        flat outlined height="60" width="100%">
                                        <v-card-text class="text-center py-3">
                                            <div class="body-1 black--text" v-text="activeItem"></div>
                                        </v-card-text>                                
                                    </v-card>
                                </v-alert>
                                <v-btn 
                                    @click="increment" 
                                    x-small depressed fab 
                                    :disabled="!maxLength"
                                    class="secondary my-0 mx-4 mt-3">
                                    <v-icon color="secondary lighten-5">mdi-plus</v-icon>
                                </v-btn>
                            </div>
                        </v-item>
                    </div>
                </v-row>
            </v-item-group>
            <div class="with-dynamic-validations caption text-center pt-1">
                {{ errors[0] }}
            </div>
    </v-container>
</template>

<script>
    export default {
        name: 'Incrementer1',
        mixins: [],
        props: {
            options: {
                type: Array,
                default: () => []
            },
            errors: {
                type: Array,
                default: () => []
            },
            label: {
                type: String,
                dafault: ''
            },
        },
        data() {
            return {
                index: 0,
                selected: ''
            }
        },
        computed: {
            activeItem() {
                return this.options[this.index].text
            },
            displayItem() {
                let val = this.options.find( cfg => {
                    return cfg.value === this.selected
                })
                console.log(val)
                
                return val?.text || ''
            },
            maxLength() {
                return parseInt(this.index) < parseInt(this.options.length - 1) ? true : false
            },
            minLength() {
                return parseInt(this.index) > 0 ? true : false
            }
        },
        mounted() {
        },
        watch: {
            selected() {
                this.$emit('input', this.selected)
            }
        },
        methods: {
            increment() {                
                if ( this.maxLength ) {
                    this.index++                    
                }
            },
            decrement() {
                if ( this.minLength ) {
                    this.index--
                }
            }
        }
    }
</script>
<style lang="scss" scoped>
    
</style>