<template>
    <div class="flex__sub-component-Incrementer1">
        <div class="v-input pa-0">
        <div class="v-input__control">

            <div class="v-label v-label-custom">{{ label }}</div>
            <v-item-group v-model="item">
                <v-row align="center" justify="center">
                    <div class="d-flex pb-0 ma-0" style="width: 100%;">
                        <v-item>
                            <div class="d-flex" style="width: 100%;">
                                <v-btn 
                                    @click="decrement" 
                                    x-small depressed fab 
                                    :disabled="minLength == item"
                                    class="secondary my-0 mx-4 mt-1">
                                    <v-icon color="secondary lighten-5">mdi-minus</v-icon>
                                </v-btn>
                                <v-alert 
                                    width="100%"
                                    color="light_gray_1" 
                                    class="rounded-xl pa-0 mx-0 my-0">
                                    <v-card
                                        class="align-center rounded-xl light_gray_1"
                                        flat outlined height="40" width="100%">
                                        <v-card-text class="text-center py-2">
                                            <div class="body-1 black--text" v-text="item"></div>
                                        </v-card-text>                                
                                    </v-card>
                                </v-alert>
                                <v-btn 
                                    @click="increment" 
                                    x-small depressed fab 
                                    :disabled="maxLength == item"
                                    class="secondary my-0 mx-4 mt-1">
                                    <v-icon color="secondary lighten-5">mdi-plus</v-icon>
                                </v-btn>
                            </div>
                        </v-item>
                    </div>
                </v-row>
            </v-item-group>
            <div class="with-dynamic-validations caption text-center pt-1">
                {{ errors[0] }}
            </div>
        </div>
        </div>

    </div>
</template>
<script>
export default {
    model: {
        prop: 'modelValue',
        event: 'input'
    },

    props: {
        modelValue: {
            type: Number,
            default: 0
        },
        errors: {
            type: Array,
            default: () => []
        },
        label: {
            type: String,
            dafault: ''
        },
    },

    mounted() {
        if(this.modelValue) {
            this.item = this.modelValue
        }
    },

    watch: {
        modelValue(val) {
            if(val) {
                this.item = val
            }
        }
    },

    data() {
        return {
            item: 0,
            maxLength: 10,
            minLength: 0,
        } 
    },

    methods: {
        increment() {                
            if ( this.maxLength != (this.item) ) {
                this.item = this.item + 1      
                this.$emit('input', this.item)              
            }
        },
        decrement() {
            if ( this.minLength != (this.item) ) {
                this.item = this.item - 1
                this.$emit('input', this.item)              
            }
        }
    }
}
</script>
<style  lang="scss">

    .v-application.v-application {

        .flex__sub-component-Incrementer1 {

            .v-btn--round .v-icon.v-icon {
                color: white !important;
            }

            .v-btn--round.v-btn--disabled .v-icon.v-icon {
                color: var(--v-light_gray_2-darken1) !important;
            }


        }

    }

</style>
