let qs = require('qs')

export const  state = () => ({
    kinds: [],
    kindsUnformat: [],
    validKindsWithPriorities: true
})

export const getters = {
    kinds: (state) => state.kinds,
    kindsUnformat: (state) => state.kindsUnformat,
    validKindsWithPriorities: (state) => state.validKindsWithPriorities,
    kindsOfApplicant: (state) => state.kindsUnformat.find( f => { return f.attributes.name === 'Applicant' }),
    kindsOfPart: (state) => state.kindsUnformat.find( f => { return f.attributes.name === 'Part' }),
    getKindsIncome: (state) => state.kinds.find( f => f.name == 'Income' ),
    getKindsAsset: (state) => state.kinds.find( f => f.name == 'Asset' ),
    getKindsLiability: (state) => state.kinds.find( f => f.name == 'Liability' ),
    getKindsExpense: (state) => state.kinds.find( f => f.name == 'Expense' ),
}

export const actions = {

    async getKindById({commit, getters}, id){
        const kind = getters.kindsUnformat.find(kind => kind.id == id)
        return kind ? kind : null
    },
    /**
     * ---------------------------------------
     * ACTIONS FOR PurchaseEditor1
     * ---------------------------------------
     */
    async fetchKinds({commit, getters}, params = { size: 0 }) {

        let res = await this.$api.get('kinds', {
            params: {
                page: params
            },
            paramsSerializer: params => { return qs.stringify(params) }
        })

        let kinds = res.data.data
        let collection = []

        sortKinds(kinds)

        kinds.forEach( kind => {

            let obj = {
                name: kind.attributes.name,
                id: kind.id,
                children: []
            }

            if (kind.relationships.parent.data == null)

                collection.push(obj)

            else {

                findParentKind(collection, obj, kind.relationships.parent.data)
            }
        })

        commit('setKinds', collection)
        commit('setRawKinds', kinds)

        return res

    },
    // Save / Fetch Kinds in LocalStorage
    async fetchKindsLocalStorage({commit, getters}, params = { size: 0 }) {

        let res = null

        // localStorage.removeItem('flex-kinds');

        if (localStorage.getItem('flex-kinds')) {

            try {

                res = JSON.parse(localStorage.getItem('flex-kinds'))

            } catch(e) {

                localStorage.removeItem('flex-kinds');

            }

        } else {

            res = await this.$api.get('kinds', {

                params: { page: params },
                paramsSerializer: params => { return qs.stringify(params) }

            }).then(res => {

                localStorage.setItem('flex-kinds', JSON.stringify(res), {'max-age': 86400}); // a day
                return res
            })
    
        }

        let kinds = res.data.data
        let collection = []

        sortKinds(kinds)

        kinds.forEach( kind => {

            let obj = {
                name: kind.attributes.name,
                id: kind.id,
                children: []
            }

            if (kind.relationships.parent.data == null)

                collection.push(obj)

            else {

                findParentKind(collection, obj, kind.relationships.parent.data)
            }
        })

        commit('setKinds', collection)
        commit('setRawKinds', res.data.data)

        return res

    }
}

export const  mutations = {
    setvalidKindsWithPriorities(state, value) {
        state.validKindsWithPriorities = value
    },
    setKinds(state, payload) {
        state.kinds = payload
    },
    setRawKinds(state, payload) {
        state.kindsUnformat = payload
    }
}

// Sort from lowest kind id to highest
function sortKinds(kinds){
    
    kinds.sort(function(a, b) {

        let x = a.id.split('-').join(''); 
        let y = b.id.split('-').join(''); 
        
        x = parseInt(x)
        y = parseInt(y)

        if (x < y) {
          return -1;
        }
        if (x > y) {
          return 1;
        }
        return 0;
    });
}

function findParentKind(collection, child, parent){

    collection.forEach( kind => {

        if (kind.id == parent.id) {

            kind.children.push(child)

        } else {

            findParentKind(kind.children, child, parent)

        }

    })

}