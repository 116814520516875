<template>

    <v-card class="d-flex align-center justify-space-between px-2" flat tile height="65">


        <template v-if="showLeftSideBarToggleButton">

            <template v-if="showLeftSideBarMinimiseButton">
                 <v-app-bar-nav-icon @click.stop="handleLeftDrawer" />
            </template>
            <template v-else>
                 <v-app-bar-nav-icon @click.stop="handleSingleToggleLeftDrawer" />
            </template>
             
        </template>

        <template v-if="showLeftSideBarMinimiseButton">
            <v-btn icon @click.stop="handleVariant('left')" >
                <v-icon>mdi-{{ `chevron-${leftDrawerVariant ? 'right' : 'left'}` }}</v-icon>
            </v-btn>
        </template>

        <!-- Left : Nodifi Logo : Fixed for all tenants -->
        <v-card flat tile class="px-4">
            <router-link :to="`/${getGroupSlug}/home`" tag="button" class="d-flex" :disabled="!b2bClickHeaderLogo">
                <img class="flex-brand d-block" :src="require(`~/assets/images/nodifi.svg`)" height="30"/>
            </router-link>
        </v-card>

        <v-spacer></v-spacer>

        <!-- Right: Tenant Logo : Visible only on white labelled themes -->
        <v-card flat tile class="px-4" v-if="isWhiteLabelled">
            <router-link :to="`/${getGroupSlug}/home`" tag="button" class="d-flex" :disabled="!b2bClickHeaderLogo">
                <img class="flex-brand d-block" :src="require(`~/themes/${getActiveTheme.name}/svg/logo.svg`)" height="30"/>
            </router-link>
        </v-card>

        <div class="px-4">
            <UserMenu />
        </div>

        <template v-if="showRightSideBarMinimiseButton">
            <v-btn icon @click.stop="handleVariant('right')" >
                <v-icon>mdi-{{ `chevron-${rightDrawerVariant ? 'left' : 'right'}` }}</v-icon>
            </v-btn>
        </template>

        <template v-if="showRightSideBarToggleButton">

            <template v-if="showRightSideBarMinimiseButton">
                 <v-app-bar-nav-icon @click.stop="handleRightDrawer" />
            </template>
            <template v-else>
                 <v-app-bar-nav-icon @click.stop="handleSingleToggleRightDrawer" />
            </template>

        </template>

    </v-card>

</template>


<script>

    import { mapGetters, mapState } from 'vuex';
    import subscriptions from '~/components/shared/subscriptions'
    import UserMenu from '~/components/base/UserMenu'

    export default {
        mixins: [subscriptions],
        components: { UserMenu },
        props: {
            leftDrawer: {
                type: Boolean,
                default: false
            },
            rightDrawer: {
                type: Boolean,
                default: false
            },
            leftDrawerVariant: {
                type: Boolean,
                default: false
            },
            rightDrawerVariant: {
                type: Boolean,
                default: false
            }
        },
        data(){
            return {
                breakpoint: 768
            }
        },
        computed: {
            ...mapGetters({
                getSpacesObj: 'flows/getSpacesObj',
                getActiveTheme: 'themes/getActiveTheme',
                getGroupSlug: 'slug/getGroupSlug',
                isWhiteLabelled: 'isWhiteLabelled',
            }),
            ...mapState({
                setup: state => state.setup
            }),
            bp(){
                return this.$vuetify.breakpoint
            },
            width(){
                return this.$vuetify.breakpoint.width
            },
            isSingleLeftToggle(){
                return this.showLeftSideBarToggleButton && !this.showLeftSideBarMinimiseButton
            },
            isSingleRightToggle(){
                return this.showRightSideBarToggleButton && !this.showRightSideBarMinimiseButton
            },
            b2bClickHeaderLogo(){
                return this.blockSettings?.onClickLogo == "b2bClickHeaderLogo"
            }
        },
        mounted() {
            /**
             * Emit on mount so we can calculate header height
             */ 
            this.$emit('onMount')

            if(this.isSingleLeftToggle) {

                // if initially collapsed, show it
                if(!this.leftDrawer && this.bp.width > this.breakpoint) {
                    this.handleLeftDrawer()
                }
                
            }

        },
        methods: {
            handleLeftDrawer(){
                this.$emit('onToggleLeftDrawer')
            },
            handleRightDrawer(){
                this.$emit('onToggleRightDrawer')
            },
            handleVariant(position, value = null){
                this.$emit('onToggleVariant', position, value)
            },
            handleSingleToggleLeftDrawer(){

                // Desktop
                if(this.bp.width > this.breakpoint){

                    this.handleVariant('left')

                // Mobile
                } else {

                    this.handleLeftDrawer()

                }

            },
            handleSingleToggleRightDrawer(){

                // Desktop
                if(this.bp.width > this.breakpoint){

                    this.handleVariant('right')

                // Mobile
                } else {

                    this.handleRightDrawer()

                }

            }
        },
        watch: {
            width(newVal, oldVal){

                // If left menu toggle and variant toggle is hidden
                if(this.isSingleLeftToggle) {

                    if(oldVal <= this.breakpoint && newVal > this.breakpoint){
                        
                        if(!this.leftDrawer){

                            setTimeout(() => {
                                this.handleLeftDrawer()
                            }, 200);

                        }

                        if(!this.leftDrawerVariant){
                            this.handleVariant('left', 'mini')
                        }

                    }

                    if(oldVal > this.breakpoint && newVal <= this.breakpoint){
                        
                        if(this.leftDrawerVariant){
                            this.handleLeftDrawer()
                        }

                        if(this.leftDrawerVariant){

                            setTimeout(() => {
                                this.handleVariant('left')
                            }, 200);

                        }

                    }


                }

                // If right menu toggle and variant toggle is hidden
                if(this.isSingleRightToggle) {

                    if(oldVal <= this.breakpoint && newVal > this.breakpoint){
                        
                        if(!this.rightDrawer){

                            setTimeout(() => {
                                this.handleRightDrawer()
                            }, 200);

                        }

                        if(!this.rightDrawerVariant){
                            this.handleVariant('right', 'mini')
                        }

                    }

                    if(oldVal > this.breakpoint && newVal <= this.breakpoint){
                        
                        if(this.rightDrawerVariant){
                            this.handleRightDrawer()
                        }

                        if(this.rightDrawerVariant){

                            setTimeout(() => {
                                this.handleVariant('right')
                            }, 200);

                        }

                    }


                }

            }
        }
    }
</script>

<style scoped lang="scss">
</style>
