var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"mx-auto",attrs:{"disabled":_vm.isDisabled || _vm.blockPermissionsReadOnly,"tile":"","flat":""}},[_c('v-card-text',{staticClass:"pa-4 pa-md-10 pt-md-0 pb-10"},[_c('v-container',{attrs:{"pa-0":"","fluid":""}},[_c('ValidationObserver',{ref:"observer",attrs:{"tag":"form"}},[_c('v-row',[_vm._l((_vm.fields),function(fieldGroup,fkey){return [_c('v-col',{key:fkey,class:_vm.$vuetify.breakpoint.mdAndUp && fkey === 0 ? 'faint-line' : '',attrs:{"cols":_vm.$vuetify.breakpoint.smAndUp ? '6' : '12'}},[_c('div',{class:fkey === 0 ? 'pr-0 pr-md-4' : 'pl-0 pl-md-4'},[_c('p',{staticClass:"title pt-6"},[_vm._v(" "+_vm._s(fieldGroup.title)+" ")]),_vm._v(" "),_vm._l((fieldGroup.items),function(field,key){return _c('div',{key:key},[_c('ValidationProvider',{attrs:{"name":_vm._f("formalText")(field.shortMessage),"rules":_vm.getRules(key),"vid":field.meta},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [(field.type === 'textarea')?_c('v-textarea',{attrs:{"rows":"1","row-height":"10","error-messages":errors,"label":field.label},model:{value:(_vm.cpdd[field.meta]),callback:function ($$v) {_vm.$set(_vm.cpdd, field.meta, $$v)},expression:"cpdd[field.meta]"}}):(field.type === 'money')?_c('NodifiMoney',{attrs:{"label":field.label,"errors":errors},model:{value:(_vm.cpdd[field.meta]),callback:function ($$v) {_vm.$set(_vm.cpdd, field.meta, $$v)},expression:"cpdd[field.meta]"}}):(field.type === 'radio')?_c('ButtonSelections',{attrs:{"error-messages":errors,"options":['Yes', 'No'],"label":field.label},scopedSlots:_vm._u([{key:"tooltip",fn:function(){return [_c('v-tooltip',{attrs:{"right":"","max-width":"250px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mt-n2",attrs:{"size":"17"}},'v-icon',attrs,false),on),[_vm._v("\n                                                            mdi-information\n                                                        ")])]}}],null,true)},[_vm._v(" "),_c('span',[_vm._v(_vm._s(field.tooltipMessage))])])]},proxy:true}],null,true),model:{value:(_vm.cpdd[field.meta]),callback:function ($$v) {_vm.$set(_vm.cpdd, field.meta, $$v)},expression:"cpdd[field.meta]"}}):(field.type === 'array')?_c('PriorityRatings',{attrs:{"error-messages":errors,"default-item":_vm.defaultItem,"label":field.label},scopedSlots:_vm._u([{key:"tooltip",fn:function(){return [_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mt-n2",attrs:{"size":"17"}},'v-icon',attrs,false),on),[_vm._v("\n                                                        mdi-information\n                                                    ")])]}}],null,true)},[_vm._v(" "),_c('span',[_vm._v(_vm._s(field.tooltipMessage))])])]},proxy:true}],null,true),model:{value:(_vm.cpdd[field.meta]),callback:function ($$v) {_vm.$set(_vm.cpdd, field.meta, $$v)},expression:"cpdd[field.meta]"}}):[_c('div',{staticClass:"mb-n8 mt-4"},[_c('span',{staticClass:"font-weight-bold"},[_vm._v("\n                                                    "+_vm._s(field.label)+"\n                                                ")]),_vm._v(" "),(field.tooltipMessage)?_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mt-n2",attrs:{"size":"17"}},'v-icon',attrs,false),on),[_vm._v("\n                                                            mdi-information\n                                                        ")])]}}],null,true)},[_vm._v(" "),_c('span',[_vm._v(_vm._s(field.tooltipMessage))])]):_vm._e()],1),_vm._v(" "),_c('div',{staticClass:"mt-4"},[_c('v-text-field',{attrs:{"error-messages":errors},model:{value:(_vm.cpdd[field.meta]),callback:function ($$v) {_vm.$set(_vm.cpdd, field.meta, $$v)},expression:"cpdd[field.meta]"}})],1)]]}}],null,true)})],1)})],2)])]})],2)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }