<template>
    <v-dialog
        v-model="dialog"
        @keydown.esc="dialog = false"
        max-width="1124px"
        scrollable
        persistent>

        <template v-slot:activator="{ on, attrs }">
            <v-btn
                data-btn="add-purchase"
                depressed
                color="secondary"
                v-bind="attrs"
                large
                v-on="on">
                Add <span class="d-none d-sm-flex">Purchase</span>
            </v-btn>
        </template>

        <v-card width="100%">
            <v-progress-linear color="secondary" indeterminate stream absolute top v-if="saving"></v-progress-linear>

            <v-card-title class="headline white lighten-2">
                <v-spacer></v-spacer>
                <v-btn icon @click="dialog=false">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-card-title>

            <v-card-text class="px-0 mx-0 py-0">
                <AssetGrid
                    @on-updated-form="updateResources"
                    @on-step-change="changedSteps"
                    :asset-icons="assetIcons"
                    :prev-step="prevStepData"
                    :is-new="dialog" />
            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions
                class="pa-6 flex-wrap controls">
                <v-btn
                    v-if="hasControlButton"
                    depressed
                    @click="finalizeAndSave"
                    :min-width="140"
                    large elevation="0"
                    data-btn="save-purchase"
                    color="secondary">
                    Save
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn
                    class="flex-back-button"
                    @click="backToPrev"
                    depressed
                    :min-width="140"
                    large outlined elevation="0"
                    color="light_gray_2">
                    Back
                </v-btn>
            </v-card-actions>

        </v-card>

    </v-dialog>
</template>

<script>

    import AssetGrid from './_NewPurchaseAssetsGrid'

    export default {
        name: 'AddPurchaseDialog1',

        components: { AssetGrid },

        props: {
            action: {
                type: String,
                default: 'add'
            },
            buttonLabel: {
                type: String,
                default: 'Click Here'
            },
            assetIcons: {
                type: Object,
                default: () => {}
            }
        },

        methods: {
            updateResources(data) {
                if ( data.observer ) {
                    this.observer = data.observer
                    this.$emit('on-data-change', data)
                }
            },
            async finalizeAndSave(data) {

                let valid = await this.observer?.validate()
                // console.log(`Item valid : `, valid)
                if ( valid ) {
                    this.$emit('confirmed-save', true)
                    this.dialog = false
                }
            },
            changedSteps(data) {
                this.hasControlButton = data.last_step ? true : false
                this.prevStep = data.prev_step
                this.currentStep = data.step
                this.prevStepData = 0
            },
            backToPrev() {

                // if step is 1
                if (this.currentStep == 1) {

                    this.dialog = false
                    this.prevStep = 1
                    this.currentStep = 1
                    this.prevStepData = 0

                } else {

                    // if step equal to current
                    if (this.currentStep == this.prevStep) this.prevStepData = this.prevStep - 1

                    else this.prevStepData = this.prevStep

                    // console.log( 'this is prev data ' + this.prevStepData)
                }

            }
        },

        data() {
            return {
                observer: null,
                hasControlButton: false,
                dialog: false,
                saving: false,
                prevStep: 1,
                currentStep: 1,
                prevStepData: 0,
            }
        }
    }
</script>

<style lang="scss" scoped>
    .controls {
        flex-direction: row-reverse;
            .v-btn {
                margin: 0 5px;
                padding: 0 30px;
            }
    }

    .v-card__title {


        .v-subheader  {
            display: block;
            line-height: 1.4;
            height: auto;
        }
    }


    @media (max-width: 960px) {

        .controls {
            justify-content: center;

            .spacer {
                display: none;
            }
        }

        .controls {

            .v-btn {
                margin: 0 5px 10px;
                padding: 0 12px;

            }

        }

    }
</style>
