var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"mx-auto",attrs:{"disabled":_vm.isDisabled || !_vm.validComponentData,"tile":"","flat":""}},[_c('v-card-text',{staticClass:"pa-4 pt-0 pa-md-10 pt-md-0"},[_c('v-container',{attrs:{"pa-0":"","fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('ValidationObserver',{ref:"observer",attrs:{"tag":"form"}},[_c('div',{staticClass:"flex-form"},[_c('div',{staticClass:"flex-field"},[_c('ValidationProvider',{attrs:{"name":_vm._f("formalText")('employment-job-status'),"rules":"required","vid":"employment-job-status"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{ref:"employment-job-status",attrs:{"error-messages":errors,"label":_vm.labels['employment-job-status'],"items":_vm.incomeSituation,"item-text":"text","item-value":"text","color":"secondary","hint":_vm.hint.employment_type,"persistent-hint":""},model:{value:(_vm.resourceObj.current.attributes['employment-job-status']),callback:function ($$v) {_vm.$set(_vm.resourceObj.current.attributes, 'employment-job-status', $$v)},expression:"resourceObj.current.attributes['employment-job-status']"}})]}}])})],1),_vm._v(" "),_c('div',{staticClass:"flex-field"},[_c('ValidationProvider',{attrs:{"name":_vm._f("formalText")('current-income-employment-job-status'),"rules":"required","vid":"current_income_employment-job-status"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{ref:"employment-job-status",attrs:{"error-messages":errors,"label":_vm.labels['current-employment-job-status'],"color":"secondary","type":"number","suffix":"Months","hint":_vm.hint.current_term,"persistent-hint":""},model:{value:(_vm.resourceObj.current.attributes['estimated-term-in-months']),callback:function ($$v) {_vm.$set(_vm.resourceObj.current.attributes, 'estimated-term-in-months', $$v)},expression:"resourceObj.current.attributes['estimated-term-in-months']"}})]}}])})],1),_vm._v(" "),_c('div',{staticClass:"flex-field"},[(parseInt(_vm.resourceObj.current.attributes['estimated-term-in-months']))?_c('ValidationProvider',{attrs:{"name":_vm._f("formalText")('current-employer-name'),"rules":"required","vid":"current_employer_name"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{ref:"employer-name",attrs:{"error-messages":errors,"label":_vm.labels['employer-name'],"color":"secondary","type":"text","hint":_vm.hint.employer_name,"persistent-hint":""},model:{value:(_vm.resourceObj.current.attributes['employer-name']),callback:function ($$v) {_vm.$set(_vm.resourceObj.current.attributes, 'employer-name', $$v)},expression:"resourceObj.current.attributes['employer-name']"}})]}}],null,false,1399632085)}):_vm._e()],1),_vm._v(" "),_c('div',{staticClass:"flex-field"},[(parseInt(_vm.resourceObj.current.attributes['estimated-term-in-months']) <= 35)?_c('ValidationProvider',{attrs:{"name":_vm._f("formalText")('estimated-gap-before-in-weeks'),"rules":"required","vid":"estimated-gap-before-in-weeks"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{ref:"estimated-gap-before-in-weeks",attrs:{"error-messages":errors,"label":_vm.labels['previous-employment-job-status'],"color":"secondary","type":"number","suffix":"Months","hint":_vm.hint.previous_term,"persistent-hint":""},model:{value:(_vm.resourceObj.previous.attributes['estimated-term-in-months']),callback:function ($$v) {_vm.$set(_vm.resourceObj.previous.attributes, 'estimated-term-in-months', $$v)},expression:"resourceObj.previous.attributes['estimated-term-in-months']"}})]}}],null,false,1057997372)}):_vm._e()],1),_vm._v(" "),_c('div',{staticClass:"flex-field"},[(parseInt(_vm.resourceObj.current.attributes['estimated-term-in-months']) <= 35)?_c('ValidationProvider',{attrs:{"name":_vm._f("formalText")('previous-income-employment-job-status'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{ref:"employment-job-status",attrs:{"error-messages":errors,"label":_vm.labels['estimated-gap-before-in-weeks'],"color":"secondary","type":"number","suffix":"Weeks","hint":_vm.hint.previous_term,"persistent-hint":""},model:{value:(_vm.resourceObj.previous.attributes['estimated-gap-before-in-weeks']),callback:function ($$v) {_vm.$set(_vm.resourceObj.previous.attributes, 'estimated-gap-before-in-weeks', $$v)},expression:"resourceObj.previous.attributes['estimated-gap-before-in-weeks']"}})]}}],null,false,4246589004)}):_vm._e()],1)])])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }