<template>
    <v-card color="light_gray_1" style="width: 100%;" flat>

        <v-progress-linear 
            v-if="disabled || syncedDisabled"
            color="secondary"
            indeterminate stream top 
            value="0">
        </v-progress-linear>

        <v-overlay
            absolute
            :opacity="0.1"
            v-if="disabled || syncedDisabled">                
        </v-overlay>

        <v-card             
            min-height="300"            
            :class="`selection-badge py-6 px-2 ${hasError ? 'error lighten-5' : 'light_gray_1'}`" 
            flat>

            <v-card-text class="text-center pa-0">
                
                <p :class="`${$vuetify.breakpoint.lgAndUp ? 'title' : 'subtitle-1'} font-weight-bold py-2 text-capitalize`">
                    {{ title }} Lender 
                    <v-avatar v-if="hasError" size="25" color="error">
                        <v-icon color="white">mdi-exclamation</v-icon>
                    </v-avatar>
                </p>
                
            </v-card-text>

            <!-- Selected lenders -->
            <v-card-text class="pa-0" v-if="hasLogo">
                
                <v-card-text height="500" class="text-center py-0 px-0">
                    
                    <p class="body-2 font-weight-bold text-uppercase py-2 pt-0 light_gray_2--text mb-0 pb-0">
                        {{ bidLenderData.attributes['lender-name'] }}
                    </p>
                    
                    <div class="text-center">
                        <v-img
                            contain
                            max-height="80"
                            :class="`${$vuetify.breakpoint.lgAndDown ? 'my-6 mx-3 px-4' : 'my-6 mx-10 px-10'} text-center`"
                            :src="lenderImage" >
                        </v-img>
                    </div>
                    
                </v-card-text>
                
            </v-card-text>
            
            <!-- Custom Lenders -->
            <div v-if="isCustom || hasLogo">
                <v-card-text v-if="!hasLogo" class="text-center py-0 px-0">
                    
                    <p class="lender-status overline text-uppercase font-weight-bold light_gray_2--text pa-0 ma-0">
                        Custom Lender
                    </p>

                    <v-chip
                        draggable
                        class="mx-0 my-6 text-capitalize"
                        color="light_gray_2 lighten-1">
                        <span class="subtitle-2 font-weight-bold">{{ lenderName }}</span>
                    </v-chip>
                    
                </v-card-text>
                
                <v-card-actions v-if="$vuetify.breakpoint.lgAndUp">

                    <v-spacer></v-spacer>

                    <CustomerLendersDialog
                        @confirm-save="save"
                        :title="`Edit Custom ${title} Lender`"
                        :lender-type="title"
                        :bid-data="bidData"
                        :bid-lender-data="bidLenderData"
                        label="Edit" />

                    <ResetLender 
                        @confirmed-action="resetLender"
                        :message="`Are you sure you want to remove ${lenderName} as your ${title} lender?`"
                        outlined
                        button-label="Reset" />

                    <v-spacer></v-spacer>

                </v-card-actions>

                <template v-else>
                    <v-card-actions>
                        <v-spacer></v-spacer>

                        <CustomerLendersDialog
                            @confirm-save="save"
                            :title="`Edit Custom ${title} Lender`"
                            :lender-type="title"
                            :bid-data="bidData"
                            :bid-lender-data="bidLenderData"
                            label="Edit" />

                        <v-spacer></v-spacer>
                    </v-card-actions>
                    <v-card-actions>
                        <v-spacer></v-spacer>

                        <ResetLender 
                            title="Delete"
                            confirm-text="Delete"
                            @confirmed-action="resetLender"
                            :message="`Are you sure you want to remove ${lenderName} as your ${title} lender?`"
                            outlined
                            button-label="Reset" />

                        <v-spacer></v-spacer>
                    </v-card-actions>
                </template>
            </div>

            <!-- Empty entry of lenders -->
            <div v-else>
                <v-card-text class="text-center py-0 px-0">                

                    <p class="lender-status overline text-uppercase font-weight-bold error--text pa-0 ma-0">
                        Lender not set
                    </p>
                    
                </v-card-text>

                <v-card-text class="py-6 px-0">
                    <p class="lender-description caption text-center font-weight-medium ma-0 mx-4">
                        Please select {{ title === 'Primary' ? 'a' : 'an' }} 
                        <span class="text-lowercase">{{ title }}</span>
                        lender or add your own custom lender.
                    </p>
                </v-card-text>

                <v-card-actions>
                    
                    <v-spacer></v-spacer>        

                    <CustomerLendersDialog
                        @confirm-save="save"
                        :bid-data="bidData"
                        :bid-lender-data="bidLenderData"
                        :title="`Add Custom ${title} Lender`"
                        :lender-type="title"                    
                        :label="`${$vuetify.breakpoint.lgAndUp ? 'Add Custom' : 'Custom'}`" />

                    <v-spacer></v-spacer>

                </v-card-actions>
            </div>
            
        </v-card>   
    </v-card>
</template>

<script>

    import CustomerLendersDialog from './_BidCustomLenders'
    import ResetLender from '~/components/base/ConfirmModal'
    import { isEmpty } from 'lodash'
    import { mapActions } from 'vuex'

    export default {
        name: 'SelectionBadge',

        components: {
            CustomerLendersDialog,
            ResetLender
        },

        props: {
            lenderList: {
                type: Array,
                default: () => []
            },
            title: {
                type: String,
                default: 'Primary'
            },
            bidResource: {
                type: Object,
                default: () => {}
            },
            bidLenderResource: {
                type: Array,
                default: () => []
            },
            hasError: {
                type: Boolean,
                default: false
            },
            syncedDisabled: {
                type: Boolean,
                default: false
            }
        },

        computed: {
            lenderName() {
                return this.resources['bid-lenders']?.attributes?.['lender-name']
            },
            bidData() {
                if ( !isEmpty(this.resources?.['bids']) ) {
                    return this.resources?.['bids']
                }
                else {
                    return this.bidResource
                }
            },
            bidLenderData() {
                
                // let resData = this.resources['bid-lenders']
                
                // if ( !isEmpty(this.resources?.['bid-lenders']) ) {
                //     if ( this.isCleared )
                //         return null
                //     return resData
                // }                
                // else {
                    
                    if ( this.title === 'primary' )
                        return this.primaryResource
                    else
                        return this.alternativeResource
                    
                // }
            },
            primaryResource() {
                return this.bidLenderResource?.find( lender => {
                    return lender.attributes['lender-type'] === 'Primary'
                })
            },
            alternativeResource() {
                return this.bidLenderResource?.find( lender => {
                    return lender.attributes['lender-type'] === 'Alternative'
                })
            },
            getBidLenderId() {
                return this.resources?.['bid-lenders']?.id
            },
            getBidId() {
                return this.resources?.['bids']?.id
            },
            hasLogo() {
                return this.bidLenderData?.attributes?.['lender-logo-class'] ? true : false
            },
            lenderImage() {
                return require(`~/assets/images/logo/${this.bidLenderData.attributes['lender-logo-class']}.png`)
            },
        },      

        methods: {
            
            ...mapActions('resource-actions', [
                'deleteBidLender',
                'createBidLender',
                'updateBidLender',
                'createBid',
                'updateBid'
            ]),

            async save(data) {

                this.disabled = true
                
                let temp = data                
                let reqs = []
                let noBidRecord = false

                delete this.resources['bids']?.relationships?.['bid-lenders']

                if ( data['bids']?.id ) {
                    // Update BID record
                    reqs.push( this.updateBid(data['bids']) )
                }
                else {
                    // Create BID record
                    let bidPayload = await this.createBid(data['bids']) 
                    noBidRecord = true

                    if ( bidPayload ) {
                        this.bid = bidPayload                    
                        data['bid-lenders'].relationships.bid.data.id = bidPayload.data.data.id
                    }                    
                    
                }

                if ( data['bid-lenders']?.id ) {
                    // Update BID LENDER record
                    reqs.push( this.updateBidLender(data['bid-lenders']) )
                }
                else {
                    // Create BID LENDER record
                    reqs.push( this.createBidLender(data['bid-lenders']) )
                }

                try {
                    Promise.all( reqs ).then( res => {

                        if ( !noBidRecord )
                            this.bid = res.find( payload => {
                                return payload.data.data.type === 'bids'
                            })

                        this.bidLender = res.find( payload => {
                            return payload.data.data.type === 'bid-lenders'
                        })

                        this.resources['bids'] = this.bid.data.data
                        this.resources['bid-lenders'] = this.bidLender.data.data

                        this.isCustom = true
                        this.isCleared = false
                        
                        this.$emit('save-custom-lender', {
                            'bids': this.resources['bids'],
                            'bid-lenders': this.resources['bid-lenders']
                        })
                        
                        this.disabled = false                  
                        
                    })
                } catch ( error ) {
                    console.log(`Ooops!.. `, error)
                    this.disabled = false
                }

            },

            async resetLender() {
                
                this.disabled = true
                
                let res = await this.deleteBidLender(this.getBidLenderId)

                if ( res.status === 204 ) {
                    this.isCustom = false
                    this.isCleared = true
                    
                    this.$emit('reset-custom-lender', {
                        type: this.title,
                        id: this.getBidLenderId
                    })

                    this.resources['bids'] = null
                    this.resources['bid-lenders'] = null
                    
                    
                
                }

                this.disabled = false

            },
        },

        watch: {
            bidLenderResource: {
                handler(val) {

                    this.resources['bid-lenders'] = this.bidLender?.data?.data || this.bidLenderData
                    this.resources['bids'] = this.bid?.data?.data || this.bidData
                    
                    if ( !isEmpty(val) && !isEmpty(this.bidLenderData) && !this.flag ) {
                        
                        this.isCustom = true
                        this.flag = true

                        this.$emit('save-custom-lender', {
                            'bids': this.bidData,
                            'bid-lenders': this.bidLenderData
                        })
                    }

                },
                deep: true
            }
        },

        data() {
            return {
                bid: null,
                bidLender: null,
                disabled: false,
                isCustom: false,
                isCleared: false,
                flag: false,
                resources: {
                    'bids': null,
                    'bid-lenders': null
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
    .selection-badge {
        width: 100%;
        
        .lender-status,
        .lender-description {
            line-height: normal;
        }
    }
</style>