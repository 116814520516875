export default {
    "fo-step": {
        "role-selector": {
            "title": "Who's name is on the loan?",
            "fields": [
                {
                    "role-selector": {
                        "resources": "assets"
                    }
                }
            ]
        },
        "liability-type": {
            "title": "Liability Type",
            "fields": [
                {
                    "liability-type": {
                        "resources": "liabilities"
                    }
                }
            ]
        },
        "finance-details": {
            "title": "Finance Details",
            "fields": [
                {
                    "amount-owing": {
                        "resources": "liabilities"
                    },
                    "amount-financed": {
                        "resources": "liabilities"
                    },
                    "payment-amount": {
                        "resources": "expenses"
                    },
                    "payment-frequency": {
                        "resources": "expenses"
                    },
                    "financial-institution": {
                        "resources": "liabilities"
                    },
                    "other-institution": {
                        "resources": "liabilities"
                    },
                    "to-be-paid-out": {
                        "resources": "liabilities"
                    }
                }
            ]
        },
    }
}