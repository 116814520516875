<template>
    <div class="pt-2 flex__button-group-2">

        <span :class="['button-selection-label ', {'error--text': hasErrors}]">
            {{ label }}
        </span>

        <v-chip-group
            ref="selection"
            class="button-selections mt-2"
            v-model="selectedItem"
            @change="handleChange"
        >
            <v-chip
                v-for="(option, key) in options"
                :key="key"
                :class="[['py-0 my-0 justify-center '], {'px-8': large},{'mr-lg-10 mr-md-2 mr-sm-2 mr-xs-2  custom-border': isOnlineLoans}]"
                :value="option"
                :large="large"
                @keyup.space="handleSpace(option, $event)"
                active-class="secondary"
                color="">
                <div class="px-1 px-sm-6 py-1">
                    {{ option }}
                </div>
            </v-chip>

            <!-- default value of selected since there is no selected when render -->
            <v-chip
                class="d-none"
                value="default"
            >
            </v-chip>

        </v-chip-group>
        <div
            v-if="hasErrors" 
            class="error-messages">
            <span 
                v-for="(err, ekey) in errorMessages"
                :key="ekey">
                {{ err }}
            </span>
        </div>
    </div>
</template>

<script>
    import { mapState, mapGetters, mapActions, mapMutations } from 'vuex'
    export default {
        model: {
            prop: 'modelValue',
            event: 'input'
        },
        props: {
            modelValue: {
                type: String,
                default: 'No'
            },
            options: {
                type: Array,
                default: () => ['Yes', 'No']
            },
            label: {
                type: String,
                default: 'Select Type'
            },
            color: {
                type: String,
                default: 'secondary'
            },
            errorMessages: {
                type: Array,
                default: () => []
            },
            large: {
                type: Boolean,
                default: false,
            },
        },

        computed: {
            ...mapGetters('themes',['getActiveTheme']),
            hasErrors() {
                return this.errorMessages.length ? true : false
            },
            isOnlineLoans () {
               let data =  this.getActiveTheme.name.search('online-loans-2021') != -1 ? true : false
               return data
            }
        },
        created() {
            if(this.modelValue)
                this.selectedItem = this.modelValue
        },
        methods: {
            handleChange(value) {
                this.$emit('input', value)
            },
            handleSpace(value, $event) {
                if($event.keyCode == 32 ||$event.code == 'Space'){
                    this.$emit('input', value)
                }
            }
        },
        watch: {
            modelValue(val){
                if(val)
                    this.selectedItem = val
            }
        },
        data() {
            return {
                selectedItem: 'default'
            }
        }
    }
</script>


<style lang="scss" scoped>

    .flex__button-group-2 {

        .button-selection-label {
            font-size: 20px !important;
            // font-weight: 600 !important;
            color: var(--v-body_text_color-base); 
            transform: translateY(-32px) scale(1);
        }

        .v-chip {
            min-width: 100px;
            border-radius: 20px;
            height: 40px;
            font-size: 1rem !important;
         

            &.v-chip--active {
                background: var(--v-secondary-base) !important;
            }

            &.v-size--large {
                font-size: 15px !important;
                height: 60px;
            }

        }
        .custom-border {
            background: none;
            border: 1px solid  var(--v-light_gray_5-darken1) !important;
        }

    }

    .error-messages {
        border: none;
        color: var(--v-error-base) !important;
        caret-color: var(--v-error-base) !important;
        font-size: 12px;
    }

</style>