<template>
    <v-text-field
        inputmode="decimal"
        v-model="val"
        :ref="`input-`+refInput"
        :label="label"
        color="secondary"
        :disabled="disabled"
        :error-messages="errors"
        @change="emitData($event)"
        @keyup.enter="$emit('enter')"
        :distraction-free="{hideNegligibleDecimalDigits: false, hideCurrencySymbol: false, hideGroupingSymbol: false}"
        v-currency="{currency: { prefix: ''}, locale: 'en'}"
        prefix="$">


        <slot v-for="slot in Object.keys($slots)" :name="slot" :slot="slot"/>
        <template v-for="slot in Object.keys($scopedSlots)" :slot="slot" slot-scope="scope">
            <slot :name="slot" v-bind="scope"/>
        </template>
    </v-text-field>
</template>

<script>

    export default {
        name: 'NodifiMoney',
        model: {
            prop: 'modelValue',
            event: 'change'
        },
        props: {
            modelValue: {
                type: [String, Number],
                default: ''
            },
            label: {
                type: String,
                default: ''
            },
            disabled: {
                type: Boolean,
                default: false
            },
            errors: {
                type: Array,
                default: ()=>[]
            },
            refInput: {
                type: String,
                default: ''
            }
        },
        mounted() {
            if (this.modelValue)
                this.val = this.$options.filters.currencyNoSymbol(this.modelValue.toString())
        },
        methods: {
            emitData(evt) {
                this.$emit('change', this.val1)
                return
            }
        },
        watch: {
            modelValue(val) {
                if (val) {
                    let temp = val.toString()
                    this.val = this.$options.filters.currencyNoSymbol(temp)
                    this.$emit('change', temp)
                }
            },
            val(value) {
                this.val1 = this.$options.filters.currencyPlainDigit(value)
                this.$emit('input', this.val1)
            }
        },
        data() {
            return {
                val: '0',
                val1: '0'
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>
