export default {
    "Header": {
      "name": "Header",
      "blocks": [
        {
          "component": {
            "name": "BaseHeader1"
          },
          "subscriptions": [],
          "settings": [
            {
              "name": "showLeftSideBarToggleButton",
              "value": true,
              "context": "component"
            },
            // {
            //   "name": "showLeftSideBarMinimiseButton",
            //   "value": true,
            //   "context": "component"
            // }
          ]
        }
      ]
    },
    "Page": {
      "name": "Page",
      "blocks": [
        {
          "component": {
            "name": "nuxt"
          },
          "subscriptions": []
        },
      ]
    },
    "LeftSideBar": {
      "name": "LeftSideBar",
      "blocks": [
        {
          "component": {
            "name": "BaseNavigation1"
          },
          "subscriptions": []
        }
      ]
    },
    "Footer" : {
      "name": "Footer",
      "blocks": [
        {
          "component": {
            "name": "Footer1"
          },
          "subscriptions": []
        }
      ]
    }
}