<template>
    <v-card class="mx-auto" tile flat>
        <v-card-text class="pa-4 pt-0 pa-md-10 pt-md-0">
            <ValidationObserver ref="observer" tag="form">
                   <div class="flex-form">
                        <div class="flex-field">
                            <ValidationProvider
                                v-slot="{ errors }"
                                :name="labels.repaymentAffordability"
                                :rules="getRules('repaymentAffordability')"
                                :vid="'repaymentAffordability'">
                                <v-select
                                    v-model="resources.loan.attributes.lender.macquarie.repaymentAffordability"
                                    :items="['YES', 'NO']"
                                    :error-messages="errors"
                                    color="secondary"
                                    :label="labels.repaymentAffordability"
                                ></v-select>
                            </ValidationProvider>
                        </div>
                        <div class="flex-field">
                            <ValidationProvider
                                v-slot="{ errors }"
                                :name="labels.creditOption"
                                :rules="getRules('creditOption')"
                                :vid="'creditOption'">
                                <v-select
                                    v-model="resources.loan.attributes.lender.macquarie.creditOption"
                                    :items="options.creditOption"
                                    :error-messages="errors"
                                    color="secondary"
                                    :label="labels.creditOption"
                                ></v-select>
                            </ValidationProvider>
                        </div>
                        <div class="flex-field">
                            <ValidationProvider
                                v-slot="{ errors }"
                                :name="labels.loanStatement"
                                :rules="getRules('loanStatement')"
                                v-if="isIncluded('loanStatement')"
                                :vid="'loanStatement'">
                                <v-select
                                    v-model="resources.loan.attributes.lender.macquarie.loanStatement"
                                    :items="['YES', 'NO']"
                                    :error-messages="errors"
                                    color="secondary"
                                    :label="labels.loanStatement"
                                ></v-select>
                            </ValidationProvider>
                        </div>
                        <div class="flex-field">
                            <ValidationProvider
                                v-slot="{ errors }"
                                :name="labels.financialReference"
                                :rules="getRules('financialReference')"
                                v-if="isIncluded('financialReference')"
                                :vid="'financialReference'">
                                <v-select
                                    v-model="resources.loan.attributes.lender.macquarie.financialReference"
                                    :items="options.financialReference"
                                    :error-messages="errors"
                                    color="secondary"
                                    :label="labels.financialReference"
                                ></v-select>
                            </ValidationProvider>
                        </div>
                    </div>

                    <div class="flex-form">
                        <div class="flex-field">
                            <ValidationProvider
                                v-slot="{ errors }"
                                :name="labels.accountReference"
                                :rules="getRules('accountReference')"
                                v-if="isIncluded('accountReference')"
                                tag="div"
                                :vid="'accountReference'">
                                    <v-textarea
                                        v-model="resources.loan.attributes.lender.macquarie.accountReference"
                                        auto-grow
                                        :label="labels.accountReference"
                                        rows="10"
                                        row-height="10"
                                        :error-messages="errors">
                                    </v-textarea> 
                            </ValidationProvider>
                        </div>
                        <div class="flex-field"></div>  
                    </div>

            </ValidationObserver>

        </v-card-text>
    </v-card>
</template>

<script>
import { mapActions, mapState, mapMutations } from 'vuex'
import helpers from '~/plugins/helpers'
import subscriptions from '~/components/shared/subscriptions'
import ButtonGroup3 from '~/components/base/ButtonGroup3'
import NodifiMoney from '~/components/base/NodifiMoney'

export default {
    components: { ButtonGroup3 , NodifiMoney},
    mixins: [subscriptions, helpers],
    data() {
        return {
            resources: {
                loan: {
                    type: 'loans',
                    id: null,
                    attributes: {
                        lender: {
                            "macquarie": {
                                "repaymentAffordability": null,
                                "creditOption": null,
                                "loanStatement": null,
                                "financialReference": null,
                                "accountReference": null
                            }
                        }
                    }
                },
            },
            options: {
                'creditOption': [
                    {text: 'Commercial Express', value:'COMM_EXPRESS'},
                    {text: 'Replacement', value:'REPLACEMENT'},
                    {text: 'Balloon Refinance', value:'BALLOON_RV_REFINANCE'},
                    {text: 'Non-Streamline', value:'NON_STREAMLINE'},
                ],
                'financialReference': [
                    { value: 'FR001', text: 'ANZ Asset Finance', },
                    { value: 'FR002', text: 'Australian Motorcycle & Marine Finance', },
                    { value: 'FR003', text: 'Automotive Financial Services', },
                    { value: 'FR004', text: 'Bank of Melbourne Automotive Finance - Commercial & Auto Finance', },
                    { value: 'FR005', text: 'BMW Australia Finance', },
                    { value: 'FR006', text: 'BOQ Equipment Finance', },
                    { value: 'FR007', text: 'BOQ Vendor Finance (formerly known as CIT)', },
                    { value: 'FR008', text: 'Branded Financial Services', },
                    { value: 'FR009', text: 'Capital Finance - Consumer', },
                    { value: 'FR010', text: 'Caterpillar Financial Australia', },
                    { value: 'FR011', text: 'Classic Funding Group', },
                    { value: 'FR012', text: 'Commonwealth Bank of Australia /Bankwest - Asset Finance', },
                    { value: 'FR013', text: 'CNH Industrial Capital', },
                    { value: 'FR014', text: 'De Lage Landen / AGCO Finance', },
                    { value: 'FR015', text: 'Eclipx Group', },
                    { value: 'FR016', text: 'Finance One', },
                    { value: 'FR017', text: 'Fleetcare', },
                    { value: 'FR018', text: 'FlexFleet', },
                    { value: 'FR019', text: 'Flexigroup', },
                    { value: 'FR020', text: 'Get Capital', },
                    { value: 'FR021', text: 'Group & General Finance', },
                    { value: 'FR022', text: 'HP Financial Services 122', },
                    { value: 'FR023', text: 'Indigenous Business Australia Must receive written consent before giving the Reference (according to IBA’s governing Act)', },
                    { value: 'FR024', text: 'John Deere Financial', },
                    { value: 'FR025', text: 'Kawasaki Motors Finance', },
                    { value: 'FR026', text: 'Komatsu Finance', },
                    { value: 'FR027', text: 'Kubota Australia Finance', },
                    { value: 'FR028', text: 'Latitude Automotive Financial Services (formerly GE Automotive Financial Services)', },
                    { value: 'FR029', text: 'Latitude Personal Finance', },
                    { value: 'FR030', text: 'Leasewise Australia', },
                    { value: 'FR031', text: 'Liberty Financial', },
                    { value: 'FR032', text: 'Skye/Lombard/Once', },
                    { value: 'FR033', text: 'Macquarie Equipment Rentals', },
                    { value: 'FR034', text: 'Macquarie Leasing (including Esanda Dealer)', },
                    { value: 'FR035', text: 'Mercury Finance', },
                    { value: 'FR036', text: 'MetroFinance', },
                    { value: 'FR037', text: 'Mercedes-Benz Financial Services', },
                    { value: 'FR038', text: 'Nissan Financial Services', },
                    { value: 'FR039', text: 'OnDeck Capital', },
                    { value: 'FR040', text: 'PACCAR Financial', },
                    { value: 'FR041', text: 'Pepper Asset Finance', },
                    { value: 'FR042', text: 'RAC Finance', },
                    { value: 'FR043', text: 'RACV Finance', },
                    { value: 'FR044', text: 'Ricoh Finance', },
                    { value: 'FR045', text: 'Selfco Leasing', },
                    { value: 'FR046', text: 'Service Finance Corporation', },
                    { value: 'FR047', text: 'Sharp Finance', },
                    { value: 'FR048', text: 'St George - Commercial & Auto Finance', },
                    { value: 'FR049', text: 'TL Rentals', },
                    { value: 'FR050', text: 'Thorn/Radio Rentals 123', },
                    { value: 'FR051', text: 'Toyota Financial Services (National Customer Service)', },
                    { value: 'FR052', text: 'Volkswagen Financial Services', },
                    { value: 'FR053', text: 'Volvo Financial Services', },
                    { value: 'FR054', text: 'Wells Fargo International', },
                    { value: 'FR055', text: 'Westlawn Finance Limited', },
                    { value: 'FR056', text: 'Westpac (Equipment Finance)', },
                    { value: 'FR057', text: 'WEX Australia', },
                    { value: 'FR058', text: 'Wingate Consumer Finance', },
                    { value: 'FR059', text: 'Yamaha Finance', },
                ]
            },
            included: {
                "loanStatement": false,
                "financialReference": false,
                "accountReference": false
            },
            labels: {
                "repaymentAffordability": "Repayment Affordability",
                "creditOption": "Credit Option",
                "loanStatement": "Loan Statement",
                "financialReference": "Financial Reference",
                "accountReference": "Account Reference",
            },
            validation_rules: {
                attributes: {
                    "repaymentAffordability": "required",
                    "creditOption": "required",
                    "loanStatement": "required",
                    "financialReference": "required",
                    "accountReference": "string|max:50",
                }
            },

        }
    },
    computed: {
        ...mapState({
            appId: state => state.flows.appId,
            loanData: state => state.flows.loanData,
            calculation: state => state.flows.calculation,
        }),
        getLoanId(){
            return this.appData.relationships.loan.data.id
        },
        ...mapState('auth-actions', [
            'user'
        ]),
        getCreditOption(){
            return this.resources.loan.attributes.lender.macquarie.creditOption
        },
        getLoanStatement(){
            return this.resources.loan.attributes.lender.macquarie.loanStatement
        },
    },
    methods: {
        ...mapActions('resource-actions', [
            'getLoan',
            'updateLoan'
        ]),
        async pull() {
            const {loanData} = this

            let res = await this.getLoan(loanData?.id)

            if(res.status == 200) {
                this.resources.loan.id = res?.data?.data?.id
                this.resources.loan.attributes.lender.macquarie = res?.data?.data?.attributes?.lender?.macquarie
            }

        },
        async push() {

            const observer = this.$refs.observer
            const {valid, status} = await this.validateFormData()

            let loanRes = null

            if(valid) {
                
                await this.updateLoan(this.resources.loan)
            }

            const data = await this.appDataHelper([loanRes])

            return {valid: valid, status: valid ?  'valid' : 'dirty', data}

        },
        isReadOnly(key){

            const {readonly} = this

            if(readonly.hasOwnProperty(key)){
                return readonly[key]
            }

            return false

        },
        isIncluded(field){

            const {included} = this

            if(included.hasOwnProperty(field)) {
                return included[field]
            }

            return true
        },
    },
    watch: {
        getCreditOption(val) {
            if(['COMM_EXPRESS', 'REPLACEMENT', 'BALLOON_RV_REFINANCE'].includes(val)) {
                this.included.loanStatement = true
            } else {
                this.included.loanStatement = false
                this.resources.loan.attributes.lender.macquarie.loanStatement = null
            }
        },
        getLoanStatement(val) {
            if(val == 'YES' || val == null) {

                this.included.financialReference = false
                this.included.accountReference = false

                this.resources.loan.attributes.lender.macquarie.financialReference = null
                this.resources.loan.attributes.lender.macquarie.accountReference = null

            } else {

                this.included.financialReference = true
                this.included.accountReference = true

            }
        },
    }
}
</script>

<style>
    .debug {
        border: 1px solid red;
    }
</style>

