<template>
    <div>
        <v-stepper v-model="foStepper" alt-labels>
            <v-stepper-items>
                <v-stepper-content v-for="(step, index) in Object.keys(filteredLayout['fo-step'])" :key="index" class="pa-0" :step="index">
                     <v-row justify="center" align="center">
                        <v-col cols="12">
                            <div class="text-center mt-n4" :class="vb.mdAndUp ? 'title' : 'title-2 font-weight-bold'">{{filteredLayout['fo-step'][step].title}}</div>
                        </v-col>
                     </v-row>
                     <ValidationObserver :ref="`observer${index+1}`" class="px-5">

                        <div v-for="(field, key) in filteredLayout['fo-step'][step].fields" :key="key">
                            <v-row :class="step == 'mortage-details' ? '' : 'justify-center align-center'">
                                <v-col 
                                    class="my-0 py-0"
                                    v-for="(f, key) in Object.keys(field)" 
                                    :key="key"
                                    cols="12"
                                    :md="getFieldColWidth(f, Object.keys(field).length)"
                                >
                                    <ValidationProvider 
                                        v-if="isVisible(f)"
                                        :hidden="isHidden(f)"
                                        v-slot="{ errors }" 
                                        :name="labels[f]" 
                                        :rules="getRules(f)">

                                        <RoleSelector
                                            v-if="f == 'role-selector'"
                                            v-model="roles"
                                            :parts="entityParts"
                                            :errors="errors"
                                            @input="selectRole"
                                            :data="filteredEntities" />

                                        <ButtonGroup1 
                                            v-else-if="f == 'to-be-paid-out'"
                                            v-model="resources[field[f].resources].attributes[f]"
                                            :error-messages="errors"
                                            :options="['Yes', 'No']"
                                            :label="labels[f]" />

                                        <EmploymentHistoryPickBox
                                            v-else-if="f == 'mortage-property'"
                                            v-model="resources[field[f].resources].attributes[f]"
                                            :items="['Yes', 'No']"
                                            :errors="errors"
                                            @select="selectPickBox"/>

                                        <v-select
                                            v-else-if="isFieldType('picklist', f)"
                                            :items="getOptions(f)"
                                            :label="labels[f]"
                                            :error-messages="errors"
                                            v-model="resources[field[f].resources].attributes[f]"
                                            color="secondary">
                                        </v-select>

                                        <v-textarea 
                                            v-else-if="isFieldType('textarea', f)"
                                            :label="labels[f]"
                                            rows="10"
                                            :error-messages="errors"
                                            v-model="resources[field[f].resources].attributes[f]"
                                            color="secondary">
                                        </v-textarea>

                                        <template v-else>
                                            <div v-if="f == '__spacer'"></div>
                                            <NodifiMoney
                                                v-else-if="isFieldType('money', f)"
                                                v-model="resources[field[f].resources].attributes[f]"
                                                :label="labels[f]"
                                                :errors="errors" />
                                            <v-text-field
                                                v-else
                                                v-model="resources[field[f].resources].attributes[f]"
                                                :label="labels[f]"
                                                :error-messages="errors">
                                            </v-text-field>
                                        </template>

                                    </ValidationProvider>
                                </v-col>
                            </v-row>
                        </div>
                    </ValidationObserver>
                </v-stepper-content>
            </v-stepper-items>
        </v-stepper>
        <div class="d-flex justify-center pa-2">
            <v-btn
                large
                min-width="125" 
                max-height="50"
                color="secondary"
                class="mx-1"
                @click="back"
                outlined> Back
            </v-btn>
            <v-btn 
                v-if="hideNext"
                large
                min-width="125" 
                max-height="50"
                class="mx-1"
                color="secondary" 
                @click="next" 
                depressed> Next 
            </v-btn>
        </div>
    </div>
</template>
<script>
import shared from '~/components/v1/EmploymentHistoryEditor4/__shared'
import fieldOptions from '~/components/shared/field-options'
import { mapState, mapActions } from 'vuex'
import {store, actions} from '~/components/v1/EmploymentHistoryEditor4/__store'
import formHelpers from '~/components/shared/form-helpers'
import ResourceHelpers from '~/components/shared/resource-helpers'
import validation from '~/components/shared/validation'
import baseComponents from '~/components/base'
import EmploymentHistoryPickBox from '~/components/v1/EmploymentHistoryEditor4/_EmploymentHistoryPickBox3'
import RoleSelector from '~/components/v1/EmploymentHistoryEditor4/_EmploymentHistoryRoleSelector2'
import ButtonGroup1 from '~/components/v1/EmploymentHistoryEditor4/_ButtonSelection'
import StepperFOFieldLayouts from '~/components/v1/EmploymentHistoryEditor4/fo-field-layouts'

export default {
    mixins: [ validation, formHelpers, shared, ResourceHelpers ],
    components: {
        ...baseComponents,
        EmploymentHistoryPickBox,
        RoleSelector,
        ButtonGroup1
    },
    props: {
        foResources: {
            type: Object,
            default: () => {}
        },
        selectedAddress: {
            type: Object,
            default: () => {}
        }
    },
    data() {
        return {
            visible: {
                'other-institution': false
            },
            hidden: {},
            foLayout: StepperFOFieldLayouts,
            foStepper: 0,
            roles: '',
            resources: {
                assets: {
                    id: null,
                    type: 'assets',
                    attributes: {
                        "asset-value": null,
                        "role-selector": null,
                        "mortage-property": null,
                    }
                },
                expenses: {
                    id: null,
                    type: 'expenses',
                    attributes: {
                        "payment-amount": null,
                        "payment-frequency": null,
                    }
                },
                liabilities: {
                    id: null,
                    type: 'liabilities',
                    attributes: {
                        "financial-institution": null,
                        "other-institution": null,
                        "amount-financed" : null,
                        "amount-owing": null,
                        "to-be-paid-out": null
                    }
                }
            },
            labels: {
                "asset-value": "Asset Value",
                "payment-amount": "Payment Amount",
                "payment-frequency": "Payment Frequency",
                "financial-institution": "Financial Institution",
                "other-institution": "Other Institution",
                "amount-financed" : "Amount Financed",
                "amount-owing": "Amount Owing",
                "to-be-paid-out": "Is this to be paid out before the new loan starts?",
                "role-selector": "Role",
                "mortage-property": "Mortgage"
            },
            validation_rules: {
                attributes: {
                    "asset-value": "required|between:0,99999999.99",
                    "payment-amount": "required|between:0,99999999.99",
                    "payment-frequency": "required|in:Weekly,Fortnightly,Monthly,Annual",
                    "financial-institution": `required|in:${fieldOptions["financial-institution"]}`,
                    "other-institution": "",
                    "amount-financed" : "required|between:0,99999999.99",
                    "amount-owing": "required|between:0,99999999.99",
                    "to-be-paid-out": "required|string|in:Yes,No",
                    "role-selector": "required",
                    "mortage-property": "required",
                }
            },
        }
    },
    computed: {
        hideNext() {

            let hide = false
            let steps = Object.keys(this.filteredLayout['fo-step'])

            if(steps[this.foStepper] == 'mortage-property' || steps[this.foStepper] == 'role-selector') hide = true

            return !hide
        },
        vb() {
            return this.$vuetify.breakpoint
        },
        isFirstStep() {
            return this.foStepper === 0
        },
        isLastStep() {
            return this.foStepper === Object.keys(this.foLayout['fo-step']).length - 1
        },
        isMortgageStep() {
            if(this.entities.length === 1) {
                return this.foStepper === 1
            } else {
                return this.foStepper === 2
            }
        },
        mortageProperty() {
            return this.resources.assets.attributes['mortage-property']
        },
        filteredEntities() {
            let filterEntities = []

            if(this.entities.length > 1) {

                this.entities.forEach(element => {
                   filterEntities.push(element)
                })

                filterEntities.push({
                    attributes: {
                        'first-name': this.constructNames(this.entities)
                    },
                    id: 'all',
                    relationships: {
                        'parent-parts': {
                            data: []
                        }
                    }
                })

            } else {
                filterEntities = this.entities
            }

            return filterEntities
        },
        filteredLayout() {

            if(this.entities.length === 1) {
                actions.setPreSelectRoles([this.entities[0].id])

                this.foLayout = {
                    "fo-step": {
                        "asset-value": {
                            "title": "Approximate Value of Property",
                            "fields": [
                                {
                                    "asset-value": {
                                        "resources": "assets"
                                    }
                                }
                            ]
                        },
                        "mortage-property": {
                            "title": "Is there a mortgage on this property?",
                            "fields": [
                                {
                                    "mortage-property": {
                                        "resources": "assets"
                                    }
                                }
                            ]
                        },
                        "mortage-details": {
                            "title": "Mortage Details",
                            "fields": [
                                {
                                    "amount-owing": {
                                        "resources": "liabilities"
                                    },
                                    "amount-financed": {
                                        "resources": "liabilities"
                                    },
                                    "payment-amount": {
                                        "resources": "expenses"
                                    },
                                    "payment-frequency": {
                                        "resources": "expenses"
                                    },
                                    "financial-institution": {
                                        "resources": "liabilities"
                                    },
                                    "other-institution": {
                                        "resources": "liabilities"
                                    },
                                    "to-be-paid-out": {
                                        "resources": "liabilities"
                                    }
                                }
                            ]
                        },
                    }
                }

            }

            return this.foLayout
        },
        financialInstitution(){
            return this.resources.liabilities.attributes['financial-institution']
        },
    },
    methods: {
        ...mapActions('resource-actions', [
            'createIncome',
            'updateIncome',
            'createAddress',
            'updateAddress',
            'createAsset',
            'updateAsset',
            'createLiability',
            'updateLiability',
            'createExpense',
            'updateExpense',
            'getIncome'
        ]),
        async next() {

            if(this.isFirstStep)
                this.setResources()

            // find observer of current step

            let ob = Object.keys(this.$refs).filter((ob) => ob == `observer${this.foStepper+1}`)

            const valid = await this.$refs[ob[0]][0].validate()

            if(valid) {
                // if no is selected
                if(this.isMortgageStep && this.mortageProperty == 'No') {

                    let res = await this.save()
                    await this.saveFo(res)
                  
                } else if(this.isLastStep) {

                    let res = await this.save()
                    await this.saveFo(res)

                } else {
                    this.foStepper++
                }
            }

        },
        saveFo(res) {
            if(!this.standalone) {
                this.$emit('save-and-close', res)
                store.saving = false
            } else{
                this.$emit('update-and-close', res)
                store.saving = false
            }
        },
        back() {
            if(this.isFirstStep) {
                this.backParentStep()
            } else {
                this.foStepper--
            }
        },
        selectPickBox(item) {
            store.saving = true
            this.resources.assets.attributes['mortage-property'] = item

            if(this.mortageProperty) {
                setTimeout(() => {
                    this.next()
                    store.saving = false
                }, 100);
            }
        },
        selectRole(data) {
            this.preSelectRoles = [data]

            if(this.preSelectRoles.length > 0) {
                setTimeout(() => {
                    this.next()
                    store.saving = false
                }, 100);
            }
        },
        backParentStep() {
            this.$emit('back-parent-step')
        },

        async save() {
            store.saving = true

            // save address first
            let addressPayload = await this.saveAddress()

            // save incomes
            let payloadIncomes = await this.getPayload('incomes')
            payloadIncomes.relationships.address = addressPayload

            let resIncomes = null

            if(this.resources.incomes?.id) {

                payloadIncomes.id = this.resources.incomes.id 
                resIncomes = await this.updateIncome(payloadIncomes)

            } else {

                resIncomes = await this.createIncome(payloadIncomes)

            }

            await actions.updateResources(resIncomes.data.data)

            // save assets
            let payloadAssets = await this.getPayload('assets')
            payloadAssets.relationships.address = addressPayload
            // relate incomes
            payloadAssets.relationships.incomes = {
                "data": [
                    {
                        "type": "incomes",
                        "id": resIncomes.data.data.id
                    }
                ]
            }

            let resAssets = null

            if(this.resources.assets?.id) {

                payloadAssets.id = this.resources.assets.id 
                resAssets = await this.updateAsset(payloadAssets)

            } else {

                resAssets = await this.createAsset(payloadAssets)

            }

            await actions.updateResources(resAssets.data.data)

            // if has mortgage else skip saving expenses and liabilities
            if(this.mortageProperty && this.mortageProperty == 'Yes') {
                // save expenses
                let resExpenses = null
                let payloadExpense = await this.getPayload('expenses')
                payloadExpense.relationships.assets = {
                    "data": [
                        {
                            "type": "assets",
                            "id": resAssets.data.data.id
                        }
                    ]
                }

                if(this.resources.expenses?.id) {

                    payloadExpense.id = this.resources.expenses.id 
                    resExpenses = await this.updateExpense(payloadExpense)

                } else {

                    resExpenses = await this.createExpense(payloadExpense)

                }

                await actions.updateResources(resExpenses.data.data)

                // save liabilities
                let resLiabilities = null

                let payloadLiabilities = await this.getPayload('liabilities')
                payloadLiabilities.relationships.expenses = {
                    "data": [
                        {
                            "type": "expenses",
                            "id": resExpenses.data.data.id
                        }
                    ]
                }
                payloadLiabilities.relationships.assets = {
                    "data": [
                        {
                            "type": "assets",
                            "id": resAssets.data.data.id
                        }
                    ]
                }

                if(this.resources.liabilities?.id) {

                    payloadLiabilities.id = this.resources.liabilities.id 
                    resLiabilities = await this.updateLiability(payloadLiabilities)

                } else {

                   resLiabilities = await this.createLiability(payloadLiabilities)

                }

                await actions.updateResources(resLiabilities.data.data)
            }

            this.updateEntitiesAndParts()

            let updatedIncome = null

            if(resIncomes) {
                updatedIncome = await this.getIncome(resIncomes.data.data.id)
            }

            return updatedIncome
        },

        getPayload(type = '') {
            let payload = {}

            payload = {
                type: type,
                attributes: this.excludeInvalidAttributes(this.resources[type].attributes),
                relationships: {
                    application: {
                        data: {type: "applications", id: this.appId}
                    },
                    people: this.getPeopleRelationship(type),
                    businesses: this.getBusinessesRelationship(type),
                    kind: this.getKinds(type)
                }
            }

            return payload
        },

        getKinds(type = '') {
            let kinds = {}

            if(type == 'assets') {
                kinds = {
                    data: {
                        type: 'kinds',
                        id: '10-14'
                    }
                }
            } else if(type == 'liabilities') {
                kinds = {
                    data: {
                        type: 'kinds',
                        id: '13-20'
                    }
                }
            } else if(type == 'expenses') {
                kinds = {
                    data: {
                        type: 'kinds',
                        id: '11-30'
                    }
                }
            } else {
                kinds = {
                    data: {
                        type: 'kinds',
                        id: this.kindsIncomeId
                    }
                }
            }

            return kinds

        },

        async saveAddress() {
            let { selectedAddress } = this

            let payloadAddress = {}

            if(selectedAddress != null){
                    
                if(selectedAddress.id){

                    payloadAddress = {
                        data: {
                            type: 'addresses',
                            id: selectedAddress.id 
                        }
                    }

                    if(selectedAddress?.relationships)
                        selectedAddress.relationships = this.filterRelationships(selectedAddress.relationships)

                    await this.updateAddress(selectedAddress)

                } else {

                    let relationships = {
                        application: {
                            data: {type: "applications", id: this.appId}
                        }
                    }

                    this.selectedAddress.relationships = relationships

                    delete this.selectedAddress.id

                    let address = await this.createAddress(selectedAddress)

                    payloadAddress = {
                        data: {
                            type: 'addresses',
                            id: address.data.data.id
                        }
                    }

                }

            }
            return payloadAddress

        },

        setResources() {
            let { foResources, resources } = this

            Object.keys(foResources).forEach(resource => {
                //store income resources
                if(!resources[resource]) {
                    resources[resource] = foResources[resource]
                } else {
                    resources[resource].id = foResources[resource].id
                    Object.keys(foResources[resource].attributes).forEach(attr => {
                        resources[resource].attributes[attr] = foResources[resource].attributes[attr]
                    })

                    let hasFo = this.checkExpensesAndLiabilties(foResources?.assets)

                    if(foResources?.assets) {
                        if(hasFo)
                            this.resources.assets.attributes['mortage-property'] = 'Yes'
                        else if(!hasFo && this.standalone) {
                            this.resources.assets.attributes['mortage-property'] = 'No'
                        }
                    }

                }

            })
        },

        checkExpensesAndLiabilties(data = null) {
            let hasEL = false

            if(data) {
                let expense = data.relationships.expenses.data
                let liabilities = data.relationships.liabilities.data

                if(expense.length > 0 && liabilities.length > 0) {
                    hasEL = true
                }
            }

            return hasEL
        }

    },
    watch: {
        preSelectRoles(val) {
            this.roles = val[0]
        },
        financialInstitution(val){
            this.resources.liabilities.attributes['financial-institution'] = val
            if(val == 'Other'){
                this.visible["other-institution"] = true
                this.validation_rules.attributes['other-institution'] = 'required'
            } else {
                this.visible["other-institution"] = false
                this.validation_rules.attributes['other-institution'] = ''
            }
        },
    }
}
</script>
