<template>
    <v-card :disabled="isDisabled" class="mx-auto" tile flat>
        <v-card-text class="pa-4 pt-0 pa-md-10 pt-md-0">

          <ValidationObserver ref="observer" tag="form">
            <v-row>
              <v-col cols="12" md="6" v-for="(layout, index) in fieldLayout" :key="index" :class="vb.mdAndUp ? 'px-10' : ''">
                <div class="flex flex-column">

                  <h2 :class="layout.title ? 'pb-10' : 'pb-15'">{{ layout.title }}</h2>
                  <h4>{{layout.subtitle}}</h4>
                  <p class="font-italic">{{layout.description}}</p>

                  <div>
                    <ValidationProvider
                      v-slot="{ errors }"
                      :name="layout.subtitle"
                      :rules="getRules(layout.field)"
                      :vid="layout.field">
                        <v-textarea
                          color="secondary"
                          :error-messages="errors"
                          v-model="form[layout.field]"
                          rows="4"
                          row-height="40"
                          auto-grow>
                        </v-textarea>
                    </ValidationProvider>
                  </div>

                </div>
                
              </v-col>
            </v-row>
          </ValidationObserver>

        </v-card-text>

    </v-card>
</template>

<script>

    import { mapActions, mapState, mapGetters, mapMutations } from 'vuex'
    import subscriptions from '~/components/shared/subscriptions'
    import helpers from '~/plugins/helpers'
    import validation from '~/components/shared/validation'
    import formHelpers from '~/components/shared/form-helpers.js'
    import loaderImage from '~/components/base/loaderImage'

    export default {
        mixins: [subscriptions, helpers, formHelpers, validation],
        components: {
          loaderImage
        },
        data() {
          return {
            fieldLayout: [
              {
                title: 'Employment',
                subtitle: 'Business Description',
                description: 'Industry experience details, what do they do?',
                field: 'lender-morris-business-description'
              },
              {
                title: '',
                subtitle: 'Source of Business',
                description: 'How does the customer source work? Who do they contract to? Are these contracts in place?',
                field: 'lender-morris-source-of-business'
              },
              {
                title: 'Opportunity',
                subtitle: 'Loan Purpose',
                description: 'What is the finance used for? What will be the benefit to the client? Is this a placement/additional asset?',
                field: 'lender-morris-loan-purpose'
              },
              {
                title: 'Understanding',
                subtitle: 'Credit History',
                description: 'Are there any issues we need to know about? If so, please explain the reasons behind adverse and current status',
                field: 'lender-morris-credit-history'
              }
            ],
            form: {
              "lender-morris-business-description": '',
              "lender-morris-source-of-business": '',
              "lender-morris-loan-purpose": '',
              "lender-morris-credit-history": '',
            },
            validation_rules: {
              attributes: {
                "lender-morris-business-description": 'required|string|max:255',
                "lender-morris-source-of-business": 'required|string|max:255',
                "lender-morris-loan-purpose": 'required|string|max:255',
                "lender-morris-credit-history": 'required|string|max:255',
              }
            },
          }
        },
        computed: {
          ...mapGetters('kinds', ['kindsUnformat']),
          ...mapGetters('themes', [
              'getActiveTheme'
          ]),
          ...mapState({
              user: state => state.auth.user,
              appId: state => state.flows.appId,
              calculation: state => state.flows.calculation,
          }),
          vb() {
              return this.$vuetify.breakpoint
          },
          storeLoanData(){
                return this.loanData
          },
          getLoanId(){
              return this.appData.relationships.loan.data.id
          },
        },
        methods: {
          ...mapActions('resource-actions', [
              'getLoan',
              'updateLoan'
          ]),
          
          async pull() {

            try  {

              let promise1 = this.getLoan(this.getLoanId)

              const res = await Promise.all([
                promise1,
              ])

              await this.fitleredResources(res[0]?.data?.data?.attributes)

            } catch (err) {
              console.log(err)
            }

          },

          async push() {

              let loan = null;

              const { valid } = await this.validateFormData()

              let status = valid ?  'valid' : 'dirty'

              if(valid){

                try {

                  let loanResource = {
                    type: 'loans',
                    id: this.getLoanId,
                    attributes: {
                      ...this.form
                    }
                  }

                  if (this.getLoanId) {
                    loan = this.updateLoan(loanResource)
                  }

                  await Promise.all([loan])

                } catch (error) {
                  console.log(error)
                }
              }

              return { valid, status, data: {} }

          },

          fitleredResources(attributes) {
            if(attributes) {
              Object.keys(this.form).forEach(key => {
                this.form[key] = attributes[key]
              })
            }
          }

        }
    }
</script>

<style lang="scss" scoped>
h2 {
  font-size: 26px !important;
}

::v-deep .v-input {
  margin: 0;
  padding: 0;
}
</style>
