import { mapState, mapActions, mapGetters, mapMutations } from 'vuex'
import {store, actions} from '~/components/v1/ApplicantCreator1/__store'
import logic from '~/components/v1/RoleEditor1/role-logic'
import subscriptions from '~/components/shared/subscriptions'  

export default {
    mixins: [subscriptions],
    computed: {
        ...mapGetters('kinds', ['kinds', 'kindsUnformat']),
        ...mapState('flows', {
            appId: state => state.appId,
            appGuid: state => state.appGuid,
            appTypeFE: state => state.appTypeFE
        }),
        ...mapState({
            development: state => {
                return state.development
            },
        }),
        ...mapState('statuses', ['flowStatuses', 'allStepStatuses']),
        ...mapState('slug', ['flowSlug']),
        ...mapGetters('kinds', [
            'kindsOfApplicant'
        ]),
        combinedEntitiesParts(){
            return store.resources.parts
        },
        loans(){
            return store.resources.loans
        },
        getPartKindChildren(){

            let parts = this.kinds.find( part => part.name == 'Part')

            if(parts){
                return parts.children
            }

            return null

        },
        getLoanKindChildren(){

            let parts = this.kinds.find( part => part.name == 'Loan')

            if(parts){
                return parts.children
            }

            return null

        },
        getBusinessKindChildren(){

            let parts = this.kinds.find( part => part.name == 'Business')

            if(parts){
                return parts.children
            }

            return null

        },
        getPersonKindChildren(){

            let parts = this.kinds.find( part => part.name == 'Person')

            if(parts){
                return parts.children
            }

            return null

        },
        getEntityType(){

            if(store.entityKind){

                if(store.entityKind.name == "Individual"){
                    return 'people'
                } else {
                    return 'businesses'
                }
            }

            return null
        },
        isLastStep(){

            if(store.currentStep) {
                return store.currentStep.children.length == 0
            }

            return false
        },
        stepper(){
            return store.stepper
        },
        steps(){
            return store.steps
        },
        currentStep(){
            return store.currentStep
        },
        isSaving(){
            return store.saving
        },
        isDeleting(){
            return store.deleting
        },
        primaryApplicantKind(){
            return store.primaryApplicantKind
        },
        entityKind(){
            return store.entityKind
        },
        entityPartKind(){
            return store.entityPartKind
        },
        parentEntity(){
            return store.parentEntity
        },
        people(){
            return store.resources.people
        },
        businesses(){
            return store.resources.businesses
        },

        showDialog(){
            return store.dialog
        },

        combinedEntities(){
            return [...store.resources.businesses, ... store.resources.people]
        },
        primaryApplicant(){
            return store.primaryApplicant
        },
        selectedLoanTypeId(){

            let {selected} = this

            return selected?.relationships?.kind?.data?.id

        },
        selectedRoleId(){

            let {selected} = this

            if(!selected) return ''

            let part = store.resources.parts.find(part => {
                return part.relationships.child.data.id == this.selected.id
            })

            if(part){
                return part.relationships.kind.data.id
            }

            return ''

        },
        showDelete() {
            return store.showDelete
        },
        deletedItems() {
            return store.deletedItems
        },
        isEdit: {
            get(){
                return store.isEdit
             },
             set(value){
                 actions.setIsEdit(value)
             }
        },
        getRecordType: {
            get(){
               return store.fieldType
            },
            set(value){
                actions.setFieldType(value)
            }
        },
        isConsumer(){
            if(this.loans){
                return  this.loans.attributes['finance-for-business-purposes'] == 'No'
            }
            return false
        },
        isCommercial(){
    
            if(this.loans){
                return  this.loans.attributes['finance-for-business-purposes'] == 'Yes'
            }
            return false
        },
        buildRoleTree(){

            const {primaryApplicant, primaryApplicantKind, people, appTypeFE} = this

            let roleTree = []
            let children = []

            if(!primaryApplicant) return []
            if(!primaryApplicantKind) return []

            if(this.isCommercial) {
    
                // Add Guarantor Button
                // let guarantor = this.buildAddGuarantorButton(primaryApplicantKind)
                // if(guarantor){ 
                //     guarantor.parent = primaryApplicant
                //     children.push(guarantor) 
                // }
    
                // Add Role Button
                let addBtn = this.buildAddRoleChildButton(primaryApplicantKind)
                //add.parent = primaryApplicant
                
                // remove guarantor
                if(addBtn) {
                    let newParts2 = addBtn.parts.filter(part => part.name != 'Guarantor' )
                    addBtn.parts = newParts2
                    addBtn.parent = primaryApplicant
                }
                // if(add){ children.push(add) }
    
    
                let childParts = primaryApplicant.relationships['child-parts'] ? primaryApplicant.relationships['child-parts']['data'] : []
                let roles = this.buildChildrenTree(childParts)
                children = children.concat(roles)
    
                roleTree.push({
                    ...primaryApplicant,
                    'sub-step-status': this.checkSubFlowStatuses(primaryApplicant),
                    addBtn: addBtn,
                    icon: (primaryApplicant.type == 'people') ? 'mdi-account' : 'mdi-city', 
                    children
                })

            } else {

                if(people.length > 0) {

                    people.forEach(person => {
                        if(this.getRolesEntity(person)[0] == 'Non-Borrowing Spouse') {
                            children.push({
                                ...person,
                                'sub-step-status': this.checkSubFlowStatuses(person),
                                icon: 'mdi-account',
                                children: []
                            })
                        } else {

                            if(this.getRolesEntity(person)[0] == 'Applicant') {
                                roleTree.push({
                                    ...person,
                                    'sub-step-status': this.checkSubFlowStatuses(person),
                                    icon: 'mdi-account', 
                                    children: children
                                })
                            } else {
                                roleTree.push({
                                    ...primaryApplicant,
                                    'sub-step-status': this.checkSubFlowStatuses(primaryApplicant),
                                    icon: 'mdi-account', 
                                    children: children
                                })
                            }
                            
                        }
                    })

                }

            }

            return roleTree
        }
    },
    methods: {
        ...mapActions('resource-actions', [
            'getPeople',
            'getBusinesses',
            'runServiceInitData'
        ]),
        ...mapActions('kinds', [
            'getKindById',
        ]),
        hexToRGBA(hex, a = 1){
            return this.$options.filters.hexToRGBA(hex, a)
        },
        getFieldColWidth(key, size){

            if(this.isCommercial) {
                return 12
            }

            if( size === 1 ){

                return  12

            } else if(size === 2) {

                 return 6

            } else {
                
                return 4

            }
        },
        buildDeleteMsg(data){

            let name = ''
            let parent = ''

            let role = this.getRolesEntity(data)

            role = role ? role.join('') : '';

            if(data.parent){
                parent = this.getFriendlyName(data.parent) 
            }

            name = this.getFriendlyName(data) 

            if(this.isCommercial)
                return `Remove ${name} ${role} from ${parent}`
            else
                return `Remove ${name} ${role}`

        },
        async updatePrimaryApplicant(){

            let people = this.getPeople({"include" : 'parent-parts'})
            let businesses = this.getBusinesses({"include" : 'parent-parts,child-parts'})
            // let includedResources = this.runServiceInitData({
            //     data: {
            //         "type": "init-flow",
            //         "input": {
            //             "guid": this.appGuid,
            //             "slug": this.flowSlug
            //         }
            //     }
            // })

            const collection = await Promise.all([people, businesses])

            people = collection[0]
            businesses = collection[1]
            //includedResources = collection[2]

            //let applicationIncluded = null

            // get flow statuses and step statuses
            // if(includedResources && includedResources.data.data.length > 0) {
            //     includedResources.data.data.forEach(dat => {
            //         if(dat.output) {
            //             applicationIncluded = dat.output.application.included
            //         }
            //     })
            // }

            // if(applicationIncluded) {
            //     actions.setIncludedResources(applicationIncluded)
            // }

            actions.setResource(people.data.data)
            actions.setResource(businesses.data.data)

            let entities = {
                data: [
                    ...people.data.data,
                    ...businesses.data.data
                ],
                included: [
                    ...(people.data.included ? people.data.included : []),
                    ...(businesses.data.included ? businesses.data.included : [])
                ]
            }

            let parts = []
            let partIds = []
            
            entities.included.forEach( part => {
                if(part.type == "parts" && !partIds.includes(part.id)){
                        parts.push(part)
                        partIds.push(part.id)
                }
            })

            actions.setResource(parts)

            let primary = this.selectPrimaryApplicant(entities)

            actions.setPrimaryApplicant(primary)


            let applicantKind = await this.getKindById(this.primaryApplicant.relationships.kind.data.id)

            actions.setPrimaryApplicantKind(applicantKind)

            // console.log('::: applicant kind: ', this.primaryApplicantKind.attributes.name)


        },
        checkSubFlowStatuses(record){
            let isValid = true
            let recordFlowStatus = null
            let { flowStatuses, allStepStatuses } = this

            // get flow status by record
            if(flowStatuses.length > 0) {
                flowStatuses.forEach(flow => {
                    if(record.id == flow.relationships?.entity?.data?.['id'] && record.type == flow.relationships?.entity?.data?.['type']) {
                        recordFlowStatus = flow
                    }
                })
            }

            // check step statuses if valid base on flow status
            if(recordFlowStatus) {
                if(allStepStatuses.length > 0) {

                    let recordStepFlowStatus = allStepStatuses.filter(step => step.relationships['flow-status'].data.id == recordFlowStatus.id)

                    recordStepFlowStatus.forEach(step => {
                        if(step.relationships['flow-status'].data.id == recordFlowStatus.id) {
                            if(step.attributes.value == 'pristine') isValid = false
                        }
                    })

                }
            } else {
                isValid = false
            }

            return isValid ? 'valid' : 'pristine'
        },
        buildAddGuarantorButton(kind){

            let guarantor = this.buildAddRoleChildButton(kind)
                
            guarantor.name = 'Third-Party Guarantor'
            // remove other except guarantor
            let newParts1 = guarantor.parts.filter(part => part.name == 'Guarantor' )

            if(newParts1.length == 0){
                return null
            }

            guarantor.parts = newParts1

            return guarantor

        },
        buildAddRoleChildButton(kind){

            const {getBusinessKindChildren, getPersonKindChildren, getPartKindChildren} = this

            if(!kind) return null;
            
            let roleTreeLogic = logic[kind?.attributes?.name]

            if(!roleTreeLogic) return null;

            let requiredParts = []

            // Customize Parts

            Object.keys(roleTreeLogic).forEach( role => {

                let part = getPartKindChildren.find(p => p.name == role)

                if(part){

                    let options = roleTreeLogic[role]['options']
                    let clonedPart = {
                        ...part
                    }

                    if(options){

                        let partOptions = []

                        options.forEach(option => {

                            let entityKind = [...getBusinessKindChildren,  ...getPersonKindChildren].find(p => p.name == option)

                            if(entityKind){
                                partOptions.push(entityKind)
                            }

                        })

                        clonedPart.children = partOptions

                    }

                    requiredParts.push(clonedPart)
                }

            })

            // if(requiredParts.length == 0){
            //     return null
            // }
            return {
                isAdd: requiredParts.length > 0,
                parts: requiredParts
            }

            // return requiredParts.length > 0

            // return {
            //     type: 'add',
            //     name: 'Add Role',
            //     icon: 'mdi-plus',
            //     parts: requiredParts
            // }

        },
        buildChildrenTree(childParts){

            const {combinedEntities, combinedEntitiesParts} = this

            let children = []

            childParts.forEach( child1 => {

                combinedEntitiesParts.forEach( part => {

                    if(child1.id == part.id){

                        let parent = null
                        
                        // Find parent 
                        combinedEntities.forEach( entity => {
                            if( part.relationships?.parent?.data?.id == entity.id){
                                // parent = entity.attributes['business-legal-name']
                                parent = entity
                            }
                        })


                        combinedEntities.forEach( entity => {

                            if( part.relationships?.child?.data?.id == entity.id){

                                entity.children = []
                                entity.parent = parent

                                entity.icon = (entity.type == 'people') ? 'mdi-account' : 'mdi-city'

                                let grandChildren = entity.relationships['child-parts']

                                let parentPart = this.kindsUnformat.find(kind => kind.id == part.relationships?.kind?.data?.id)

                                // Exclude Roles
                                let excludes = [
                                    'Beneficial Owner',
                                    'Guarantor'
                                ] 
                                
                                if(entity.type == 'businesses' && !excludes.includes(parentPart.attributes.name)){ 
                                    
                                    let entityKind = this.kindsUnformat.find(kind => kind.id == entity.relationships?.kind?.data?.id)

                                    let addBtn = this.buildAddRoleChildButton(entityKind)

                                    addBtn.parent = entity // add parent entity

                                    // remove guarantor
                                    let newParts = addBtn.parts.filter(part => part.name != 'Guarantor' )
                                    addBtn.parts = newParts

                                    if(addBtn){ entity.addBtn =  addBtn}

                                } 

                                if(grandChildren && grandChildren.data.length > 0){

                                    let roles = this.buildChildrenTree(grandChildren.data)

                                    entity.children = entity.children.concat(roles)
                                }

                                entity['sub-step-status'] = this.checkSubFlowStatuses(entity)

                                // Spreaded to remove reactivity
                                children.push({
                                    ...entity
                                })

                            }

                        })

                    }

                })

            })

            return children
        },
        getFriendlyName(role){

            let fullname = ''

            if(role.type == 'add') {
                return role.name
            }

            if(role.type == 'businesses') {

                fullname += role.attributes['business-legal-name'] ? role.attributes['business-legal-name'] : 'Business'

            }

            if(role.type == 'people'){

                fullname += role.attributes['first-name'] ? role.attributes['first-name'] + ' ' : ''

                fullname += role.attributes['last-name'] ? role.attributes['last-name'] : ''

                if(!role.attributes['first-name'] && !role.attributes['last-name']){
                    fullname = 'Person'
                }
            }

            return fullname

        },
        getRoleKind(role){

            let roleKind = null
            let roleKindName = ''

            if( role.hasOwnProperty('relationships') ){

                roleKind = this.kindsUnformat.find(kind => kind.id == role.relationships?.kind?.data?.id)

                if(roleKind){
                    roleKindName = roleKind.attributes.name 
                }
            }

            return roleKindName

        },
        getRolesEntity(entity){
                
            let roles = []

            let entityParentParts = entity.relationships?.['parent-parts'] ? entity.relationships['parent-parts'] : {}

            if(entity.parent){

                let parent = entity.parent

                let parentChildParts = parent.relationships['child-parts']
    
                if(parentChildParts && entityParentParts.data && entityParentParts.data.length > 0 && parentChildParts.data.length > 0){
    
                    let parentChildPartsIds = []
    
                    parentChildParts.data.forEach( part => {
                        parentChildPartsIds.push(part.id)
                    })
    
    
                    entityParentParts.data.forEach( (part1) => {
    
                        if(parentChildPartsIds.includes(part1.id)){
    
                            this.combinedEntitiesParts.forEach(part2 => {
    
                                if(part1.id == part2.id){
        
                                    let partKind = this.kindsUnformat.find(kind => kind.id == part2.relationships?.kind?.data?.id)
        
                                    if(!roles.includes(partKind.attributes.name)){
                                        roles.push(partKind.attributes.name)
                                    }
                                
                                }
        
                            })
    
                        }
    
                    });
    
                }


            } else {


                if( entityParentParts.data && entityParentParts.data.length > 0 ){
    
                    entityParentParts.data.forEach( (part1) => {
    
                        this.combinedEntitiesParts.forEach(part2 => {

                            if(part1.id == part2.id){
    
                                let partKind = this.kindsUnformat.find(kind => kind.id == part2.relationships?.kind?.data?.id)
    
                                if(!roles.includes(partKind.attributes.name)){
                                    roles.push(partKind.attributes.name)
                                }
                            
                            }
    
                        })

    
                    });
    
                }


            }

            
            return roles

        },
        /**
         * Show / Remove Completely
         */
        isVisible(key){
                
            const {visible} = this

            if(visible.hasOwnProperty(key)){
                return visible[key]
            }

            return true

        },

        async getRelatedRoles(roles){

            const {parentEntity, primaryApplicant} = this

            let items = []

            try {

                let individual = []
                let rest = []

                roles.forEach(role => {

                    if(role.name == "Individual"){
                        individual.push(role.id)
                    } else {
                        rest.push(role.id)
                    }

                })

                let all = []

                if(individual.length){
                    let res1 = this.getPeople({"filter[kind]" : individual.join(',')})
                    all.push(res1)
                }

                if(rest.length){
                    let res2 = this.getBusinesses({"filter[kind]" : rest.join(',')})
                    all.push(res2)
                }

                const collection = await Promise.all(all)

                collection.forEach( data => {
                    items = items.concat(data.data.data)
                })

                let filteredItems = []

                if(parentEntity){

                    let childParts = parentEntity.relationships['child-parts']
                    let partsIds = []

                    if(childParts.data.length){

                        childParts.data.forEach(part => {
                            partsIds.push(part.id)
                        })

                    }

                    items.forEach( item => {

                        let parentParts = item.relationships['parent-parts']
                        let parentPartsData = parentParts.data
                        let itemKindId = item.relationships?.kind?.data?.id

                        // console.log(parentPartsData)

                        if(parentPartsData){

                            let isIncluded = false;

                            parentPartsData.forEach(ppd => {
                                if(partsIds.includes(ppd.id)){
                                    isIncluded = true
                                }
                            })

                            /**
                             * NOTE: Excluding businesses entity for the meantime 
                             * There is no way to know if business' children
                             * has to be excluded from a copy.
                             * 
                             */
                            if(item.type == 'businesses'){
                                // isIncluded = true  
                            }

                            // Exclude Parents and PrimaryApplicant
                            if(item.id == parentEntity.id || item.id == primaryApplicant.id){
                                isIncluded = true
                            }

                            // Exclude existing Partners
                            let itemKind = this.kindsUnformat.find(kind => kind.id == itemKindId)

                            if(itemKind.attributes.name == 'Partnership'){
                                isIncluded = true
                            }

                            if(!isIncluded){
                                filteredItems.push(item)
                            }

                        }

                    })
                }

                return filteredItems

            } catch (error) {

                console.log('Ooops!..', error)

                return items
            }

        }

    }
}
