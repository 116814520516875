<template>
    <div>

        <div>
            <v-btn
            :x-small="vb.smAndDown"
            color="primary"
            depressed
            dark
            class="px-4 px-md-10"
            @click="dialog = true">
                Request {{vb.smAndDown ? '' : 'a'}} call
            </v-btn>
        </div>

        <v-dialog
            v-model="dialog"
            content-class="modal-RequestCallBack1 pt-8"
            scrollable
            :max-width="vb.smAndDown ? 360 : 420">

                <v-sheet width="100%" :height="vb.smAndDown ? 76: 90" color="transparent" class="avatar text-center">
                    <img class="rounded-xl ma-auto" src="~/assets/images/jane.png" :height="vb.smAndDown ? 76: 90"  />
                </v-sheet>

                <v-card :disabled="saving" elevation="4"  class="rounded-xl">

                    <v-card-title class="px-6 pb-0 pt-2" style="position: relative; z-index: 9999;">
                        <v-spacer/>
                        <v-btn icon @click="dialog = false">
                            <v-icon color="secondary">mdi-close</v-icon>
                        </v-btn>
                    </v-card-title>


                    <v-card-text class="px-lg-8 pb-0 mt-3">

                        <div class=" pa-4 mb-6 mx-auto body-1 text-center rounded-xl line-height-18"
                            :style="`background-color: ${hexToRGBA(getActiveTheme.colors.primary, 0.1)}`">
                                Need some Help? Speak with one of our finance experts.
                        </div>


                        <div class="form-fields-sm">

                        <ValidationObserver ref="requestACallFormObserver" tag="form">

                                <div 
                                    class="my-0 py-0"
                                    v-for="(field, key) in formFields.fields" 
                                    :key="key">

                                    <ValidationProvider 
                                        v-slot="{ errors }" 
                                        :name="formFields.labels[key]" 
                                        :rules="getRules(key)"
                                        :vid="key">

                                        <v-text-field 
                                            :type="'text'"
                                            label=""
                                            :placeholder="formFields.labels[key]"
                                            :error-messages="errors"
                                            v-model="formFields.fields[key]"
                                            color="secondary">
                                        </v-text-field>

                                    </ValidationProvider>
                                </div>

                        </ValidationObserver>

                        </div>

                    </v-card-text>


                    <v-card-actions class="d-block text-center pt-0 px-lg-8 pb-8">
                        <v-btn 
                            class="mx-auto" 
                            width="100%" 
                            color="secondary" 
                            @click="submitForm" 
                            depressed> Request 
                        </v-btn>
                    </v-card-actions>

                </v-card>
        </v-dialog>

    </div>
    
</template>
<script>

    import veeValidate from '~/components/shared/validation'
    import formHelpers from '~/components/shared/form-helpers'
    import subscriptions from '~/components/shared/subscriptions'
    import { mapActions, mapGetters, mapMutations } from 'vuex'
    
    export default {
        mixins: [formHelpers, subscriptions, veeValidate],
        components: {},

        data() {
            return {
                saving: false,
                person: {},
                dialog: false,
                formFields: {
                    fields: {
                        "first-name": null,
                        "last-name": null,
                        "mobile-phone": null,
                        "email": null,
                    },
                    labels: {
                        "first-name": "First Name",
                        "last-name": "Last Name",
                        "mobile-phone": "Phone",
                        "email": "Email"
                    },
                    visible: {},
                    enabled: {},
                },
                validation_rules: {
                    attributes: {
                        "first-name": "required",
                        "last-name": "required",
                        "mobile-phone": "required|numeric|digits_between:10,10",
                        "email": "required|email"
                    }
                },
            }
        },

        computed: {
            vb() {
                return this.$vuetify.breakpoint
            },
            ...mapGetters('themes', [
                'getActiveTheme'
            ]),
            getPersonId(){
                return this.appData.relationships.people.data[0].id
            },
        },

        watch: {
            person(val) {
                if(val?.attributes) {
                    Object.keys(this.formFields.fields).forEach(f => {
                        Object.keys(val.attributes).forEach(attr => {
                            if(f == attr) this.formFields.fields[f] = val.attributes[f]
                        })
                    })
                }
            }
        },

        async mounted() {
            await this.fetchPerson()
        },

        methods: {
            ...mapActions('resource-actions', [
                'getPerson',
                'updatePerson',
            ]),

            hexToRGBA(hex, a = 1){
                return this.$options.filters.hexToRGBA(hex, a)
            },
            async fetchPerson() {
                // get person
                if(this.getPersonId) {
                    let res = await this.getPerson(this.getPersonId)
                    if(res.status == 200) {
                        this.person = res.data.data
                    }
                }
            },
            async submitForm(){

                const valid = await this.$refs.requestACallFormObserver.validate();

                if(valid) {

                    this.saving = true

                    let payload = {
                        type: this.person.type,
                        id: this.person.id,
                        attributes: this.formFields.fields
                    }

                    let personRes = await this.updatePerson(payload)

                    if (personRes.status === 200) {
                        this.$emit('save-person')
                        this.dialog = false
                    }

                } else {

                    //this.closeModal()
                }

                this.saving = false
            }
        }

    }

</script>

<style lang="scss">

    .modal-RequestCallBack1 {

        position: relative !important;

        .avatar {
            position: absolute;
            top: 0;
            left: 0;
            z-index: 9998;
        }

        .form-fields-sm {

            .v-text-field.v-text-field {
                padding-top: 0 !important;

                input:-moz-placeholder,
                input:-ms-input-placeholder {
                    color: var(--v-body_text_color-base) !important;
                }
            }

            .v-messages__message {
                margin-bottom: 0.5rem;
            }
        }

    }
</style>