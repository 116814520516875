var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"persistent":"","width":"550"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_vm._t("activator")]}}],null,true),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_vm._v(" "),_c('v-card',{attrs:{"flat":"","disabled":_vm.processing || _vm.ownAssignmentModalLoading}},[(_vm.processing || _vm.ownAssignmentModalLoading)?_c('v-progress-linear',{attrs:{"color":_vm.color,"indeterminate":"","stream":"","absolute":"","top":""}}):_vm._e(),_vm._v(" "),(_vm.canSelect && _vm.choosed == 0 || _vm.choosed == 2)?[_c('v-card-title',{staticClass:"px-8 pt-8"},[_c('div',{staticClass:"v-card__title sm pa-0"},[_vm._v("\n                    "+_vm._s(_vm.title)+"\n                ")])]),_vm._v(" "),_c('v-card-text',{staticClass:"px-8 pt-2 body-1",domProps:{"innerHTML":_vm._s(_vm.message)}}),_vm._v(" "),_c('v-card-text',{staticClass:"px-8 "},[(_vm.canSelectAppOwner && _vm.choosed == 0 )?_c('v-btn',{attrs:{"data-btn":"app-owner-me","depressed":"","block":"","large":"","color":"secondary"},on:{"click":function($event){return _vm.selectSelf(_vm.authUser)}}},[_vm._v("Me")]):_vm._e(),_vm._v(" "),(_vm.canSelectAppOwner && _vm.choosed == 0)?_c('div',{staticClass:"text-center font-weight-bold pt-4"},[_vm._v("or")]):_vm._e(),_vm._v(" "),(_vm.choosed == 0 || _vm.choosed == 2)?_c('div',{staticClass:"font-weight-bold body-1"},[_vm._v("Select User")]):_vm._e(),_vm._v(" "),(_vm.choosed == 0 || _vm.choosed == 2)?_c('v-autocomplete',{staticClass:"pa-0 font-weight-regular",attrs:{"items":_vm.filteredSearchKeys,"search-input":_vm.search,"item-text":"name","item-value":"id","placeholder":"Start typing to search","hide-no-data":true,"return-object":"","single-line":"","auto-select-first":"","no-filter":"","hide-selected":_vm.hasSearched ? false : true,"data-field":"app-owner-other"},on:{"keydown":_vm.debounceCall,"update:searchInput":function($event){_vm.search=$event},"update:search-input":function($event){_vm.search=$event},"change":function($event){return _vm.selectedUser(_vm.searchKey)}},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(item.name))]),_vm._v(" "),_c('v-list-item-subtitle',[_vm._v(_vm._s(item.email))])],1)]}}],null,false,1619662487),model:{value:(_vm.searchKey),callback:function ($$v) {_vm.searchKey=$$v},expression:"searchKey"}}):_vm._e()],1),_vm._v(" "),(_vm.groupList.length > 0 && _vm.choosed > 0)?_c('v-card-text',{staticClass:"px-8  body-1"},[_c('p',{staticClass:"font-weight-bold"},[_vm._v("Select Group")]),_vm._v(" "),_c('v-autocomplete',{staticClass:"pt-0",attrs:{"placeholder":"Select Group","filter":_vm.filterGroups,"item-disabled":_vm.disableItems,"item-text":_vm.item_selected != ''? 'attributes.name' : '',"return-object":"","items":_vm.groupList,"item-value":"attributes.id","menu-props":{ contentClass: 'flex_page-GroupSelect-list' }},on:{"change":function($event){return _vm.selectedGroup(_vm.groupSelected)}},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('div',{staticStyle:{"width":"100%"}},[_c('div',{style:(item.attributes['can-own-applications'] == 0 ? 'opacity:0.5': '')},[_c('v-list-item-content',{staticClass:"px-6 "},[_c('v-list-item-title',{staticClass:" font-weight-bold"},[_vm._v("\n                            "+_vm._s(item.attributes.name)+"\n                                ")]),_vm._v(" "),_c('v-list-item-subtitle',{staticClass:"caption"},[_vm._v("\n                                "+_vm._s(item.attributes.slug)+"\n                                ")])],1)],1),_vm._v(" "),_c('v-divider')],1)]}}],null,false,1202346335),model:{value:(_vm.groupSelected),callback:function ($$v) {_vm.groupSelected=$$v},expression:"groupSelected"}})],1):_vm._e(),_vm._v(" "),_c('v-card-actions',{staticClass:"px-8 pb-8"},[_c('v-btn',{staticClass:"flex-back-button",staticStyle:{"min-width":"140px"},attrs:{"outlined":"","color":"light_gray_2","large":""},on:{"click":_vm.cancel}},[_vm._v("\n                    Back\n                ")])],1)]:_vm._e(),_vm._v(" "),(!_vm.canSelect || _vm.choosed == 1)?[_c('v-card-title',{staticClass:"px-8 pt-8"},[_c('div',{staticClass:"v-card__title sm pa-0"},[_vm._v("\n                    Select Application Group\n                ")])]),_vm._v(" "),_c('v-card-text',{staticClass:"px-8 pt-2 body-1"},[_c('p',{staticClass:"body-1"},[_vm._v("Which group do you want to assign this application to?")]),_vm._v(" "),_c('v-autocomplete',{staticClass:"pt-0",attrs:{"data-field":"group-select","placeholder":"Select Group","filter":_vm.filterGroups,"item-disabled":_vm.disableItems,"item-text":_vm.item_selected != ''? 'attributes.name' : '',"return-object":"","items":_vm.groupList,"item-value":"attributes.id","menu-props":{ contentClass: 'flex_page-GroupSelect-list' }},on:{"change":function($event){return _vm.selectedGroup(_vm.groupSelected)}},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('div',{staticStyle:{"width":"100%"}},[_c('v-list-item-content',{staticClass:"px-6 py-5 elevation-0",attrs:{"data-btn":item.attributes.slug}},[_c('v-list-item-title',{staticClass:"font-weight-bold elevation-0"},[_vm._v("\n                                "+_vm._s(item.attributes.name)+"\n                                ")]),_vm._v(" "),_c('v-list-item-subtitle',{staticClass:"caption elevation-0"},[_vm._v("\n                                "+_vm._s(item.attributes.slug)+"\n                                ")])],1),_vm._v(" "),_c('v-divider')],1)]}}],null,false,440183047),model:{value:(_vm.groupSelected),callback:function ($$v) {_vm.groupSelected=$$v},expression:"groupSelected"}})],1),_vm._v(" "),_c('v-card-actions',{staticClass:"px-8 pb-8"},[_c('v-btn',{staticClass:"flex-back-button",staticStyle:{"min-width":"140px"},attrs:{"outlined":"","color":"light_gray_2 ","large":""},on:{"click":_vm.cancel}},[_vm._v("\n                    Back\n                ")])],1)]:_vm._e()],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }