<template>
<div>

    <div v-if="vb.mdAndUp" class="d-flex" style="width: 100%">
      <v-sheet
        color="light_gray_1"
        class="py-8 px-12"
        width="50%"
        outlined
        elevation="0"
      >
        <v-img max-width="120" class="flex-brand" :src="require(`~/assets/images/nodifi.svg`)" position="center left" contain >
        </v-img>
        <div class="mt-2 mb-6 font-size-20">Data</div>
        <v-list color="transparent">
          <v-list-item two-line class="px-0" v-for="item in getNodifiData" :key="item.name">
            <v-list-item-content>
              <v-list-item-title class="font-weight-medium">{{item.name}}</v-list-item-title>
              <v-list-item-subtitle>{{item.value}}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>

      </v-sheet>
      <v-sheet class="py-8 px-12" width="50%">
        <v-img max-width="120" class="flex-brand" :src="require(`~/assets/images/logo/cropped/lender-logo-pepper.png`)" position="center left" contain >
        </v-img>
        <div class="mt-2 mb-8 font-size-20">Requirements</div>
        <ValidationObserver ref="observer" tag="form">

            <ValidationProvider
              v-slot="{ errors }"
              :name="labels['registeredAddressSameAsTrading']"
              :rules="getRules('registeredAddressSameAsTrading')"
              :vid="'registeredAddressSameAsTrading'">
              <ButtonGroup3
                v-model="registeredAddressSameAsTrading"
                :error-messages="errors"
                :options="['Yes','No']"
                :label="labels['registeredAddressSameAsTrading']"
              />
            </ValidationProvider>

            <ValidationProvider
              v-slot="{ errors }"
              :name="labels['postalAddressSameAsTrading']"
              :rules="getRules('postalAddressSameAsTrading')"
              :vid="'postalAddressSameAsTrading'">
              <ButtonGroup3
                v-model="postalAddressSameAsTrading"
                :error-messages="errors"
                :options="['Yes','No']"
                :label="labels['postalAddressSameAsTrading']"
              />
            </ValidationProvider>
            <ValidationProvider
                key="nature-of-business"
                v-slot="{ errors }"
                name="Nature of Business"
                rules="required"
                vid="nature-of-business">
                  <v-text-field 
                      :type="'text'"
                      label="Nature of Business"
                      :error-messages="errors"
                      v-model="resources.entity.attributes['nature-of-business']"
                      color="secondary">
                  </v-text-field>
              </ValidationProvider>

        </ValidationObserver>
      </v-sheet>
    </div>

    <!-- mobile version -->
    <div v-else>
      <v-card
        flat
        class="mx-5 rounded-xl"
        :style="`background-color: ${hexToRGBA( getActiveTheme.colors.secondary, 0.2 )}`" >
        <v-card-text class="d-flex py-1">
          <v-row>
            <v-col cols="6" class="pa-1">
                <v-card
                  min-height="45"
                  flat
                  class="rounded-xl d-flex align-center justify-center"
                  :color="tabSelection == 'nodifi' ? 'white' : 'transparent'" >
                  <div class="d-flex" @click="selectTab('nodifi')" >
                    <v-img
                      max-width="55"
                      class="flex-brand"
                      :src="require(`~/assets/images/nodifi.svg`)"
                      position="center left"
                      contain >
                    </v-img>
                  </div>
                </v-card>
            </v-col>
            <v-col cols="6" class="pa-1">
              <v-card
                  min-height="45"
                  flat
                  class="rounded-xl d-flex align-center justify-center"
                  :color="tabSelection == 'pepper' ? 'white' : 'transparent'" >
                  <div class="d-flex" @click="selectTab('pepper')">
                      <v-img
                        max-width="45"
                        class="flex-brand"
                        :src="require(`~/assets/images/logo/cropped/lender-logo-pepper.png`)"
                        position="center left"
                        contain >
                      </v-img>
                  </div>
                </v-card>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>

      <v-list v-show="tabSelection == 'nodifi'" class="mx-8" color="transparent">
        <v-list-item two-line class="px-0" v-for="item in getNodifiData" :key="item.name">
          <v-list-item-content>
            <v-list-item-title class="font-weight-medium">
              {{ item.name }}
            </v-list-item-title>
            <v-list-item-subtitle>
              {{ item.value}}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>

      <div v-show="tabSelection != 'nodifi'" class="mx-8">
        <ValidationObserver ref="observer" tag="form">

          <ValidationProvider
              v-slot="{ errors }"
              :name="labels['registeredAddressSameAsTrading']"
              :rules="getRules('registeredAddressSameAsTrading')"
              :vid="'registeredAddressSameAsTrading'">
              <ButtonGroup3
                v-model="registeredAddressSameAsTrading"
                :error-messages="errors"
                :options="['Yes','No']"
                :label="labels['registeredAddressSameAsTrading']"
              />
            </ValidationProvider>

            <ValidationProvider
              v-slot="{ errors }"
              :name="labels['postalAddressSameAsTrading']"
              :rules="getRules('postalAddressSameAsTrading')"
              :vid="'postalAddressSameAsTrading'">
              <ButtonGroup3
                v-model="postalAddressSameAsTrading"
                :error-messages="errors"
                :options="['Yes','No']"
                :label="labels['postalAddressSameAsTrading']"
              />
            </ValidationProvider>

            <ValidationProvider
                key="nature-of-business"
                v-slot="{ errors }"
                name="Nature of Business"
                rules="required"
                vid="nature-of-business">
                  <v-text-field 
                      :type="'text'"
                      label="Nature of Business"
                      :error-messages="errors"
                      v-model="resources.entity.attributes['nature-of-business']"
                      color="secondary">
                  </v-text-field>
              </ValidationProvider>


        </ValidationObserver>
      </div>
    </div>

    <v-dialog
            v-model="addressDialog"
            persistent
            max-width="600"
        >
            <v-card tile flat class="rounded-lg" :disabled="addressModalDisabled">
                <v-progress-linear color="secondary" indeterminate stream absolute top v-if="addressModalDisabled"></v-progress-linear>
                <v-card-title>
                    <v-spacer/>
                    <v-btn icon @click="handleCloseAddressModal">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-card-title>
                <v-card-text>
                    <v-card tile flat class="rounded-lg mx-auto" :width="600">
                        <template>
                            <ValidationObserver ref="observer" tag="form">
                                <v-container>
                                    <v-row>
                                    <v-col cols="12" class="py-0">
                                        <google-address-tool
                                            name="address"
                                            label="Address Search"
                                            v-model="googleSearch"
                                            @change="initializeAddress"
                                        ></google-address-tool>
                                    </v-col>
                                    <v-col cols="12" sm="6" md="6" 
                                            class="py-0"
                                            v-for="(val, key) in resources.address.attributes"
                                            :key="key">

                                        <ValidationProvider
                                            v-slot="{ errors }" 
                                            :name="labels[key]" 
                                            :rules="getRules(key)" :vid="key">

                                            <v-select
                                            v-if="isFieldType('picklist', key)"
                                            :items="getOptions(key)"
                                            :label="labels[key]"
                                            :error-messages="errors"
                                            v-model="resources.address.attributes[key]"
                                            color="secondary">
                                            </v-select>

                                            <v-text-field 
                                            v-else
                                            :type="'text'"
                                            :label="labels[key]"
                                            :error-messages="errors"
                                            v-model="resources.address.attributes[key]"
                                            color="secondary">
                                            </v-text-field>
                                        </ValidationProvider>

                                    </v-col>
                                    </v-row>
                                </v-container>
                            </ValidationObserver>
                        </template>

                    </v-card>
                </v-card-text>

                <v-card-actions class="pa-8 pt-0 flex-wrap">

                    <v-spacer></v-spacer>

                    <!-- <v-btn color="light_gray_2" class="px-5" outlined depressed nuxt large @click="reset">Back</v-btn>  -->
                    <v-btn color="secondary" class="px-5" depressed nuxt large @click="handleAddressSave">Save & Close</v-btn> 

                </v-card-actions>


            </v-card>
    </v-dialog>

</div>
</template>

<script>
import { mapActions, mapMutations, mapGetters } from "vuex";
import subscriptions from "~/components/shared/subscriptions";
import shared from "~/components/v1/submission/PepperCommercialEntities1/__shared";
import {
  store,
  actions,
} from "~/components/v1/submission/PepperCommercialEntities1/__store";
import ButtonGroup3 from "~/components/base/ButtonGroup3";
import Occupation from '~/components/v1/submission/PepperCommercialEntities1/__occupation-enums'
import GoogleAddressTool from '~/components/base/GoogleAddressTool'

export default {
  components: { ButtonGroup3, GoogleAddressTool },
  mixins: [subscriptions, shared],
  props: {
    data: {
      type: Object,
      default: null,
    },
    updater: undefined,
  },
  data() {
    return {
      tabSelection: "nodifi",

      "registeredAddressSameAsTrading": "Yes",
      "postalAddressSameAsTrading": "Yes",

      resources: {
        entity: {
          id: null,
          type: "businesses",
          attributes: {
            "nature-of-business": null
          },
        },
        address: {
          type: "addresses",
          attributes: {
            'street-number': "",
            'street-name': "",
            'street-type': "",
            'suburb': "",
            'state': "",
            'country': "",
            'postcode': ""
          }
        },
      },
      labels: {
        'street-number': 'Street No.',
        'street-name': 'Street Name',
        'street-type': 'Street Type',
        'suburb': 'Suburb',
        'state': 'State',
        'country': 'Country',
        'postcode':  'Postcode',

        "registeredAddressSameAsTrading": "Registered Address Same as Trading?",
        "postalAddressSameAsTrading": "Postal Address Same as Trading?",

      },
      validation_rules: {
        attributes: {
        },
      },
      options: {
        "registeredAddressSameAsTrading": [
          { text: "Australian or New Zealand Citizen", value: "Citizen" },
        ],
      },
      addressDialog: false,
      addressModalDisabled: false,
      googleSearch: '',
      businessAddresses: [],
      
    };
  },
  computed: {
    vb() {
      return this.$vuetify.breakpoint;
    },
    getLenderPepperOccupationOptions(){

        return Occupation['lender-pepper-occupation'].map( option => {
            return {
              text: option,
              value: option
            }
        })

    },
    getNodifiData(){
      return [
        {
          name: 'Address', 
          value: this.getFullAddress(this.getTradingAddress)
        },
      ]
    },
    getRegisteredAddressSameAsTrading(){
      return this.registeredAddressSameAsTrading
    },
    getPostalAddressSameAsTrading(){
      return this.postalAddressSameAsTrading
    },
    getTradingAddress(){
      // Trading address always the first address
      if(this.businessAddresses.length == 0) return null
      return this.businessAddresses[0]
    },
  },
  mounted() {

    this.updateResourceFields();
    this.options["lender-pepper-occupation"] = this.getLenderPepperOccupationOptions

  },
  methods: {
    ...mapActions("resource-actions", [
      "getPerson", 
      "getAddress", 
      "createAddress",
      "getBusiness",
      "updateBusiness",
    ]),
    selectTab(tab) {
      this.tabSelection = tab;
    },
    isFieldType(field, key) {
      return field === "picklist"
        ? [ ].includes(key)
        : false;
    },
    isAutoComplete(key) {
      let keys = []
      return keys.includes(key);
    },
    isBtnGroup(key) {
      let keys = ["registeredAddressSameAsTrading", "postalAddressSameAsTrading"];
      return keys.includes(key);
    },
    async validate() {
      const valid = await this.$refs.observer.validate();

      if (valid) {
        this.$emit("submit", this.resources);
      }
    },
    reset() {
      this.$refs.observer.reset();
    },
    initializeAddress(address, obj) {

        this.resources.address.attributes = {
            "street-number": obj["street-number"],
            "street-name": obj["street-name"],
            "street-type": obj["street-type"],
            "suburb": obj["suburb"],
            "state": obj["state"],
            "postcode": obj["postcode"]
        }
    },
    async updateResourceFields() {

      this.$emit("loading", true);

      const { data } = this;

      if (data && data.type == "businesses") {

        let businessRes = await this.getBusiness(data.id);

        this.resources.entity = this.filterResourceFields(
          this.resources.entity,
          businessRes.data.data
        );

        let businessAddressesIds = businessRes?.data?.data?.relationships?.addresses?.data

        if(businessAddressesIds.length) {

          let payload = []

          businessAddressesIds.forEach(address => {
            payload.push(this.getAddress(address.id))
          })

          let addressesRes = await Promise.all(payload);

          addressesRes.forEach(address => {
              if(address.status == 200) {
                this.businessAddresses.push(address.data.data)
              }
          })

        }

        this.$emit("loading", false);

      } else {
        this.$emit("loading", false);
      }
    },
    getFullAddress(address){

        if(!address) return "";

        if(!address.hasOwnProperty('attributes')) return "";
        
        return  `${address.attributes["street-number"] == null ? '' : address.attributes["street-number"]} ${address.attributes["street-name"] == null ? '' : address.attributes["street-name"]} ${address.attributes["street-type"] == null ? '' : address.attributes["street-type"]}, ${address.attributes["suburb"] == null ? '' : address.attributes["suburb"]} ${address.attributes["state"] == null ? '' : address.attributes["state"]} ${address.attributes["postcode"] == null ? '' : address.attributes["postcode"]}`
    },
    async handleAddressSave(){
      this.addressModalDisabled = true

      let payload = this.resources.address

      payload.relationships = {
        
          "application": {
              data: {type: "applications", id: "1"},
              meta: {
                  strategy: 'attach'
              }
          },
          "businesses": {
              data: [
                  {
                      id: this.resources.entity?.id,
                      type: 'businesses'
                  }
              ],
              meta: {
                  strategy: 'attach'
              }
          }
      }

      let res = await this.createAddress(payload)

      this.addressModalDisabled = false
      this.handleCloseAddressModal()

    },
    handleCloseAddressModal(){
      this.addressDialog = false
      this.resetAddressModal()
    },
    resetAddressModal(){
      this.googleSearch = ''
      this.resources.address = {
          id: null,
          type: "addresses",
          attributes: {
            'street-number': "",
            'street-name': "",
            'street-type': "",
            'suburb': "",
            'state': "",
            'country': "",
            'postcode': ""
          }
        }

    }
  },
  watch: {
    updater(val) {
      if (val) {
        this.updateResourceFields();
      }
    },
    getRegisteredAddressSameAsTrading(val){
      if(val == "No") {
        this.addressDialog = true
      }
    },
    getPostalAddressSameAsTrading(val){
      if(val == "No") {
        this.addressDialog = true
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
