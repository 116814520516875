import Vue from "vue"

export const store = Vue.observable({

    selectedLenderObj: {
        primary: {},
        alternative: {}
    },

})


export const actions = {

    // Resources


}

export const mutations = {

    setPrimary(data) {
        store.selectedLenderObj.primary = data
    },

    setAlternative(data) {
        store.selectedLenderObj.alternative = data
    },
    
    resetPrimary() {
        store.selectedLenderObj.primary['bid-lender'] = {}
    },

    resetAlternative() {
        store.selectedLenderObj.alternative['bid-lender'] = {}
    }

}