export default {

    // Centrelink 12-2
    // --Age Pension 12-3se
    // --Carer Allowance 12-4
    // --Child Care Benefit 12-5
    // --Defence Service Pension 12-6
    // --Disability Support Pension 12-7
    // --Family Allowance 12-8
    // --Family Tax Benefit 12-9
    // --Newstart 12-10
    // --Parenting Payments 12-12
    // --Widows Allowance 12-13
    // --Youth Allowance 12-14

    "12-3|12-4|12-5|12-6|12-7|12-8|12-9|12-10|12-12|12-13|12-14": {
        title: "Centrelink Income Details",
        "max-size": '300px',
        steps: [
            [
                [
                    "start-date"
                ],
                [
                    "end-date"
                ],
                [
                    "benefit-amount"
                ],
                [
                    "income-period"
                ],
                [
                    "net-monthly-amount"
                ]
            ]
        ]
    },

     // --Other Benefits 12-11
     "12-11": {
        title: "Other Benefits Income Details",
        "max-size": '300px',
        steps: [
            [
                [
                    "other-benefit-description"
                ],
                [
                    "start-date"
                ],
                [
                    "end-date"
                ],
                [
                    "benefit-amount"
                ],
                [
                    "income-period"
                ],
                [
                    "net-monthly-amount"
                ]
            ]
        ]
    },

    // Child Support 12-15

    "12-15": {
        title: "Child Support Income Details",
        "max-size": '300px',
        steps: [
            [
                [
                    "start-date"
                ],
                [
                    "end-date"
                ],
                [
                    "benefit-amount"
                ],
                [
                    "income-period"
                ],
                [
                    "net-monthly-amount"
                ]
            ]
        ]
    },

    // Company Director 
    "12-16": { 
        title: "Company Director Income Details",
        steps: [
            [
                [
                    "income-situation",
                    "business-name",
                ],
                [
                    "start-date",
                    "employment-job-status",
                ]
            ]
        ]
    },

    // Investment Income
    // --Dividends 12-18
    // --Private Pension 12-20

    "12-18|12-20": {
        title: "Investment Income Details",
        "max-size": '300px',
        steps: [
            [
                [
                    "start-date",
                ],
                [
                    "end-date",
                ],
                [
                    "investment-income",
                ],
                [
                    "income-period",
                ],
                [
                    "net-monthly-amount",
                ],
            ]
        ]
    },

    // Investment Income
    // --Rental Income 12-21
    "12-21": {
        title: "Investment Income Details",
        "max-size": '300px',
        steps: [
            [
                [
                    "start-date",
                ],
                [
                    "end-date",
                ],
                [
                    "__rental-address",
                ],
                [
                    "investment-income",
                ],
                [
                    "income-period",
                ],
                [
                    "net-monthly-amount",
                ]
            ]
        ]
    },

    // --Other Investment 12-19

    "12-19": {
        title: "Investment Income Details",
        "max-size": '300px',
        steps: [
            [
                [
                    "start-date",
                ],
                [
                    "end-date",
                ],
                [
                    "income-additional-comments",
                ],
                [
                    "investment-income",
                ],
                [
                    "income-period",
                ],
                [
                    "net-monthly-amount",
                ]
            ]
        ]
    },

    // Investment Income
    // --Superannuation 12-22
    "12-22": {
        title: "Investment Income Details",
        "max-size": '300px',
        steps: [
            [
                [
                    "start-date",
                ],
                [
                    "end-date",
                ],
                [
                    "investment-income",
                ],
                [
                    "income-period",
                ],
                [
                    "net-monthly-amount",
                ]
            ],
            // Next step should ask the user "Current Superannuation Balance" asset resources
            [
                [
                    "asset-value"
                ]
            ]
        ]
    },

    // No Income
    "12-23": { 
        steps: [
            [
                [
                    "no-income-reason",
                ]
            ],
            [
                [
                    "start-date",
                ]
            ],
            [
                [
                    "additional-notes"
                ]
            ]
        ]
    },

     // Other Income 12-24
    // --Income Protection Payment 12-25
    // --Other Income 12-26
    // --Workers Compensation 12-27

    "12-25|12-26|12-27": { 
        title: "Other Income",
        "max-size": '300px',
        steps: [
            [
                [
                    "start-date"
                ],
                [
                    "end-date"
                ],
                [
                    "net-income",
                ],
                [
                    "income-period"
                ],
                [
                    "employment-job-status",
                ]
                [
                    "net-monthly-amount"
                ],
                [
                    "additional-notes"
                ]
            ]
        ]
    },

    // PAYG Employment 12-28
    "12-28": { 
        title: "PAYG Income Details",
        steps: [
            [
                [
                    "start-date"
                ],
                [
                    "employment-on-probation"
                ],
                [
                    "employment-job-status"
                ],
                [
                    "job-title"
                ]
            ],
            [
                [
                    "abn"
                ],
                [
                    "employer-name"
                ],
                [
                    "employment-contact-person",
                ],
                [
                    "employment-contact-phone",
                ],
                [
                    "__employer-address",
                ],
            ],
            [
                [
                    "net-standard-pay",
                    "income-period",
                ],
                [
                    "gross-annual-income",
                    "__spacer"
                    //"gross-annual-income-period"
                ],
                [
                    "is-allowances",
                ],
                [
                    "overtime-pay-monthly-net"
                    //"frequency",
                ],
                [
                    "bonus-monthly-net"
                    //"frequency",
                ],
                [
                    "commission-monthly-net"
                    //"frequency",
                ],
                [
                    "work-allowance-net-monthly"
                    //"frequency",
                ]
            ]
        ],
        previousSteps: [
            [
                [
                    "start-date",
                ],
                [
                    "employment-on-probation"
                ],
                [
                    "employment-job-status"
                ],
                [
                    "job-title"
                ]
            ],
            [
                [
                    "abn"
                ],
                [
                    "employer-name"
                ],
                [
                    "employment-contact-person",
                ],
                [
                    "employment-contact-phone",
                ],
                [
                    "__employer-address",
                ],
            ]
        ]
    },

    // Self-Employed 12-29
    "12-29": { 
        title: "Self Employed Income Details",
        steps: [
            [
                [
                    "start-date"
                ],
                [
                    "employment-job-status",
                ],
                [
                    "nature-of-business",
                ]
            ],
            [
                [
                    "abn",
                ],
                [
                    "business-name",
                ],
                [
                    "self-employed-gst-registered",
                ],
                [
                    "self-employed-date-registered-gst",
                ],
                [
                    "__business-address",
                ],
            ],
            [
                [
                    "profit-before-tax-annual",
                ]
                // [
                //     "income-period",
                //     "income-verification-method",
                // ],
                // [
                //     "reporting-period",
                //     "revenue",
                // ],
                // [
                //     "depreciation",
                //     "profit-before-tax-annual",
                // ],
                // [
                //     "gross-annual-income",
                //     "net-monthly-amount",
                // ]
            ],
            [
                [
                    "__reference"
                ]
            ]
        ]
    },

    // Business Income 12-30
    "12-30": { 
        title: "Business Income Details",
        steps: [
            [
                [
                    "income-verification-method",
                    "reporting-period",
                ],
                [
                    "revenue",
                    "depreciation",
                ],
                [
                    "profit-before-tax-annual",
                    "net-monthly-amount",
                ],
                [
                    "income-situation",
                ]
            ]
        ]
    }

}