<template>
    <v-card :id="`${ bottom ? 'cardCapB' : 'cardCapT' }`" :height="bottom ? 10 :  50" :class="`mx-auto ${classes} ${ bottom ? 'rounded-bottom-lg' : 'rounded-top-lg'}`" tile flat>
        <template v-if="!bottom">
            <v-progress-linear color="secondary" indeterminate stream top v-if="isDisabled"></v-progress-linear>
        </template>
    </v-card>
</template>

<script>

    import { mapState, mapGetters } from 'vuex'

    export default {
        name: 'card-cap',
        props: {
            bottom: {
                type: Boolean,
                default: false
            },
        },
        data(){
            return {
                classes: ''
            }
        },
        computed: {
            ...mapState({
                stepableMode: state => state.subscriptions.stepableMode,
                steps: state => state.flows.steps,
                activeStep: state => parseInt(state.flows.activeStep)
            }),
            isDisabled(){
                return this.stepableMode == 'disabled'
            },
            heading(){

                const {steps, activeStep} = this

                if( steps[activeStep - 1] && steps[activeStep - 1].name ) {
                    return steps[activeStep - 1].name
                }

                return ''
            }

        },
        mounted(){

            /**
             * BG color grabber of the next element
             */ 
            if(!this.bottom) {

                const colors = Object.keys(this.$vuetify.theme.themes.light)
                let classes = document.getElementById("cardCapT").nextSibling.nextElementSibling.classList

                let bgs = [];

                if(classes && classes.length > 0){

                    classes.forEach( cls => {

                        if(colors.includes(cls)) {
                            bgs.push(cls)
                        }

                    });
                }
                this.classes = bgs.join(' ')
            }

        }
    }
</script>

<style scoped lang="scss">

    .v-card {
        overflow: hidden;
    }

</style>
