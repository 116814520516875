import tenants from '~/config/tenants'

const grays = {
    light_gray_1: '#f6f8fb',
    light_gray_2: '#afb9cc',
    light_gray_3: '#e9edf2',
    light_gray_4: '#b4bbc6',
    light_gray_5: '#F4FBFB',
    light_gray_6: '#E3E3E3',
}

export const state = () => ({
    active: 'nodifi-default', // Default
    isSet: false,
    isActiveAppThemeSet: false,
    themes: [
        {
            name: 'nodifi-default',
            colors: {
                primary: '#201547',
                secondary: '#009cde',
                accent: '#685bc7',
                success: '#99e800',
                error: '#fd5457',
                info: '#00a2ff',
                warning: '#fecc46',
                body_text_color: '#111111',
                ...grays
            }
        },
        {
            name: 'nodifi-test-theme-a',
            colors: {
                primary: '#4e69d9',
                secondary: '#2bc2d1',
                accent: '#323030',
                success: '#9fde26',
                error: '#ff5252',
                info: '#2196f3',
                warning: '#ffbb22',
                body_text_color: '#323030',
                ...grays,
            }
        },
        {
            name: 'nodifi-test-theme-b',
            colors: {
                primary: '#4e69d9',
                secondary: '#2bc2d1',
                accent: '#323030',
                success: '#9fde26',
                error: '#ff5252',
                info: '#2196f3',
                warning: '#ffbb22',
                body_text_color: '#323030',
                ...grays,
            }
        },
        {
            name: 'online-loans-2021',
            colors: {
                primary: '#4e69d9',
                secondary: '#2bc2d1', 
                accent: '#323030',
                success: '#9fde26',
                error: '#ff5252',
                info: '#2196f3',
                warning: '#ffbb22',
                body_text_color: '#01203C',
                ...grays,
                light_gray_1 :'#E3E3E3',
            },
        },
        {
            name: 'online-loans-2021-b',
            colors: {
                primary: '#4e69d9',
                secondary: '#2bc2d1', 
                accent: '#01203C',
                success: '#9fde26',
                error: '#ff5252',
                info: '#2196f3',
                warning: '#ffbb22',
                body_text_color: '#01203C',
                ...grays,
                light_gray_1 :'#E3E3E3',
            },
            settings: {
                header: {
                    absolute: true
                }
            }
        },
        {
            name: 'online-loans-2021-c',
            colors: {
                primary: '#4e69d9',
                secondary: '#32ABB3', 
                accent: '#01203C',
                success: '#9fde26',
                error: '#ff5252',
                info: '#2196f3',
                warning: '#ffbb22',
                body_text_color: '#01203C',
                ...grays,
                light_gray_1 :'#E3E3E3',
            },
            settings: {
                header: {
                    absolute: true
                }
            }
        },
        {
            name: 'online-loans-default',
            colors: {
                primary: '#4e69d9',
                secondary: '#2bc2d1',
                accent: '#323030',
                success: '#9fde26',
                error: '#ff5252',
                info: '#2196f3',
                warning: '#ffbb22',
                body_text_color: '#323030',
                ...grays,
            }
        },
        {
            name: 'online-loans-2022-a',
            colors: {
                primary: '#4e69d9',
                secondary: '#2bc2d1',
                accent: '#323030',
                success: '#9fde26',
                error: '#ff5252',
                info: '#2196f3',
                warning: '#ffbb22',
                body_text_color: '#01203C',
                ...grays
            },
        },
        {
            name: 'money3-a',
            colors: {
                primary: '#00ce5f',
                secondary: '#abb4cf',
                accent: '#323030',
                success: '#99e800',
                error: '#fd5457',
                info: '#00a2ff',
                warning: '#fecc45',
                body_text_color: '#323030',
                ...grays,
            }
        },
        {
            name: 'positive-lending-solutions-2021',
            colors: {
                primary: '#05447a',
                secondary: '#30c1ef',
                accent: '#eed7a5',
                success: '#99e800',
                error: '#fd5457',
                info: '#00a2ff',
                warning: '#fecc45',
                body_text_color: '#323030',
                ...grays,
            }
        },
        {
            name: 'iselect-a',
            colors: {
                primary: '#ff7f00',
                secondary: '#464646',
                accent: '#323030',
                success: '#4b9a5a',
                error: '#d93900',
                info: '#4db6ac',
                warning: '#ffbb22',
                body_text_color: '#323030',
                ...grays,
            }
        },
        {
            name: 'iselect-b',
            colors: {
                primary: '#737373',
                secondary: '#ff7f00',
                accent: '#323030',
                success: '#4b9a5a',
                error: '#d93900',
                info: '#4db6ac',
                warning: '#ffbb22',
                body_text_color: '#323030',
                ...grays,
            }
        },
        {
            name: 'choice-a',
            colors: {
                primary: '#201547',
                secondary: '#009cde',
                accent: '#685bc7',
                success: '#99e800',
                error: '#fd5457',
                info: '#00a2ff',
                warning: '#fecc46',
                body_text_color: '#111111',
                ...grays
            }
        },
        {
            name: 'choice-b',
            colors: {
                primary: '#eb6d32',
                secondary: '#1A1617',
                accent: '#FCB774',
                success: '#4b9a5a',
                error: '#d93900',
                info: '#4db6ac',
                warning: '#ffbb22',
                body_text_color: '#323030',
                ...grays,
            }
        },
        {
            name: 'fast-a',
            colors: {
                primary: '#201547',
                secondary: '#009cde',
                accent: '#685bc7',
                success: '#99e800',
                error: '#fd5457',
                info: '#00a2ff',
                warning: '#fecc46',
                body_text_color: '#111111',
                ...grays
            }
        },
        {
            name: 'fast-b',
            colors: {
                primary: '#f68b1f',
                secondary: '#3D454C',
                accent: '#FBB040',
                success: '#4b9a5a',
                error: '#d93900',
                info: '#4db6ac',
                warning: '#ffbb22',
                body_text_color: '#3d454b',
                ...grays,
            }
        },
        {
            name: 'plan-a',
            colors: {
                primary: '#201547',
                secondary: '#009cde',
                accent: '#685bc7',
                success: '#99e800',
                error: '#fd5457',
                info: '#00a2ff',
                warning: '#fecc46',
                body_text_color: '#111111',
                ...grays
            }
        },
        {
            name: 'plan-b',
            colors: {
                primary: '#172848',
                secondary: '#98d7fb',
                accent: '#8CE2D8',
                success: '#4b9a5a',
                error: '#d93900',
                info: '#4db6ac',
                warning: '#ffbb22',
                body_text_color: '#323030',
                ...grays,
            }
        },
        {
            name: 'loan-market-b',
            colors: {
                primary: '#201547',
                secondary: '#009cde',
                accent: '#685bc7',
                success: '#99e800',
                error: '#fd5457',
                info: '#00a2ff',
                warning: '#fecc46',
                body_text_color: '#111111',
                ...grays
            }
        },
        {
            name: 'loan-market-c',
            colors: {
                primary: '#08abe2',
                secondary: '#08abe2',
                accent: '#08abe2',
                success: '#32bcad',
                error: '#d93900',
                info: '#08abe2',
                warning: '#f58220',
                body_text_color: '#323030',
                ...grays,
            }
        },
        {
            name: 'loan-market-group-asset-finance-a',
            colors: {
                primary: '#26225b',
                secondary: '#26225b',
                accent: '#26225b',
                success: '#32bcad',
                error: '#d93900',
                info: '#08abe2',
                warning: '#f58220',
                body_text_color: '#323030',
                ...grays,
            }
        },
        // New LMGAF theme
        {
            name: 'lmg',
            colors: {
                primary: '#327E73',
                secondary: '#C7B54F',
                accent: '#C7B54F',
                success: '#41c52d',
                error: '#d93900',
                info: '#4db6ac',
                warning: '#ffbb22',
                body_text_color: '#323030',
                ...grays,
            }
        },
        {
            name: 'medezi-a',
            colors: {
                primary: '#ED1458',
                secondary: '#332959',
                accent: '#ff3d7a',
                success: '#99e800',
                error: '#fd5457',
                info: '#00a2ff',
                warning: '#fecc46',
                body_text_color: '#111111',
                ...grays,
            }
        },
        {
            name: 'nectar-a',
            colors: {
                primary: '#ff0013',
                secondary: '#1A1617',
                accent: '#FCB774',
                success: '#41c52d',
                error: '#d93900',
                info: '#4db6ac',
                warning: '#ffbb22',
                body_text_color: '#323030',
                ...grays,
            }
        },
        {
            name: 'psp-a',
            colors: {
                primary: '#5cb852',
                secondary: '#00437a',
                accent: '#FCB774',
                success: '#41c52d',
                error: '#d93900',
                info: '#4db6ac',
                warning: '#ffbb22',
                body_text_color: '#323030',
                ...grays,
            }
        },
        {
            name: 'ecarz',
            colors: {
                primary: '#f7941f',
                secondary: '#21409a',
                accent: '#e6e7e8',
                success: '#9fde26',
                error: '#ff5252',
                info: '#2196f3',
                warning: '#ffbb22',
                body_text_color: '#01203C',
                ...grays
            },
        },
        {
            name: 'mannheim',
            colors: {
                primary: '#323030',
                secondary: '#33ABB3',
                accent: '#e6e7e8',
                success: '#9fde26',
                error: '#ff5252',
                info: '#2196f3',
                warning: '#ffbb22',
                body_text_color: '#01203C',
                ...grays
            },
        },
        {
            name: 'sellmycar',
            colors: {
                primary: '#323030',
                secondary: '#33ABB3',
                accent: '#e6e7e8',
                success: '#9fde26',
                error: '#ff5252',
                info: '#2196f3',
                warning: '#ffbb22',
                body_text_color: '#01203C',
                ...grays
            },
        },
        // Lender Style Overrides
        
        {
            name: 'lender-nowfinance',
            colors: {
                primary: '#F1702A',
                secondary: '#8D8D8D',
                accent: '#F1702A',
                success: '#32bcad',
                error: '#d93900',
                info: '#08abe2',
                warning: '#f58220',
                body_text_color: '#323030',
                ...grays,
            }
        },
        {
            name: 'lender-plenti',
            colors: {
                primary: '#002A61',
                secondary: '#009cde',
                accent: '#685bc7',
                success: '#32bcad',
                error: '#d93900',
                info: '#08abe2',
                warning: '#f58220',
                body_text_color: '#323030',
                ...grays,
            }
        },
        {
            name: 'lender-pepper',
            colors: {
                primary: '#DB0024',
                secondary: '#1A1617',
                accent: '#685bc7',
                success: '#32bcad',
                error: '#d93900',
                info: '#08abe2',
                warning: '#f58220',
                body_text_color: '#323030',
                ...grays,
            }
        },
        {
            name: 'lender-pepper-commercial',
            colors: {
                primary: '#DB0024',
                secondary: '#1A1617',
                accent: '#685bc7',
                success: '#32bcad',
                error: '#d93900',
                info: '#08abe2',
                warning: '#f58220',
                body_text_color: '#323030',
                ...grays,
            }
        },
        {
            name: 'lender-wisr',
            colors: {
                primary: '#7AC2BB',
                secondary: '#1A1617',
                accent: '#685bc7',
                success: '#32bcad',
                error: '#d93900',
                info: '#08abe2',
                warning: '#f58220',
                body_text_color: '#323030',
                ...grays,
            }
        },
        {
            name: 'lender-societyone',
            colors: {
                primary: '#53bc82',
                secondary: '#264b65',
                accent: '#8D8D8D',
                success: '#32bcad',
                error: '#d93900',
                info: '#08abe2',
                warning: '#f58220',
                body_text_color: '#323030',
                ...grays,
            }
        },
        {
            name: 'lender-morris',
            colors: {
                primary: '#D6001C',
                secondary: '#264b65',
                accent: '#8D8D8D',
                success: '#32bcad',
                error: '#d93900',
                info: '#08abe2',
                warning: '#f58220',
                body_text_color: '#323030',
                ...grays,
            }
        },
        {
            name: 'lender-money-3',
            colors: {
                primary: '#02C006',
                secondary: '#009CDE',
                accent: '#8D8D8D',
                success: '#32bcad',
                error: '#d93900',
                info: '#08abe2',
                warning: '#f58220',
                body_text_color: '#3C3A3A',
                ...grays,
            }
        },
        {
            name: 'lender-moneyplace',
            colors: {
                primary: '#384650',
                secondary: '#264b65',
                accent: '#8D8D8D',
                success: '#32bcad',
                error: '#d93900',
                info: '#08abe2',
                warning: '#f58220',
                body_text_color: '#3C3A3A',
                ...grays,
            }
        },
        {
            name: 'lender-finance-one',
            colors: {
                primary: '#110F40',
                secondary: '#009cde',
                accent: '#685bc7',
                success: '#32bcad',
                error: '#d93900',
                info: '#08abe2',
                warning: '#f58220',
                body_text_color: '#323030',
                ...grays,
            }
        },
        {
            name: 'lender-macquarie',
            colors: {
                primary: '#000000',
                secondary: '#1A1617',
                accent: '#8D8D8D',
                success: '#32bcad',
                error: '#d93900',
                info: '#08abe2',
                warning: '#f58220',
                body_text_color: '#323030',
                ...grays,
            }
        },
        {
            name: 'lender-westpac',
            colors: {
                primary: '#DA1710',
                secondary: '#1A1617',
                accent: '#685bc7',
                success: '#32bcad',
                error: '#d93900',
                info: '#08abe2',
                warning: '#f58220',
                body_text_color: '#323030',
                ...grays,
            }
        },
        {
            name: 'lender-capital',
            colors: {
                primary: '#292C83',
                secondary: '#1A1617',
                accent: '#685bc7',
                success: '#32bcad',
                error: '#d93900',
                info: '#08abe2',
                warning: '#f58220',
                body_text_color: '#323030',
                ...grays,
            }
        },
    ],

    lenderClass: null,
    flexOverrideClass: null,

})


// getters
export const  getters = {
    getActiveTheme: (state, getters, rootState) => {
        
        const {themes, active} = state
        const tenant = tenants.data.find( tenant => tenant.slug == rootState.slug.tenant )

        // set for default theme
        let defaultTheme = themes.find(theme => theme.name == 'nodifi-default')

        // get default theme if exists in tenant and set active theme
        if(tenant?.['default-theme']) {
            defaultTheme = themes.find(theme => theme.name == tenant['default-theme'])
        }

        let theme = themes.find(theme => theme.name == active)

        return theme ? theme : defaultTheme
    },
    getLenderClass: (state, getters, rootState) => {
        const {lenderClass} = state
        return lenderClass
    },
    getActiveLenderTheme: (state, getters, rootState) => {

        const {themes, lenderClass} = state

        if(lenderClass) {

            let theme = themes.find(theme => theme.name == lenderClass)

            return theme
        }

        return null

    },
    getFlexOverrideClass: (state, getters, rootState) => {
        const { flexOverrideClass } = state
        return flexOverrideClass
    },
}

export const  actions = {
    async fetchDefaultTheme({state, commit, getters, rootState}){

        const tenant = tenants.data.find( tenant => tenant.slug == rootState.slug.tenant )
        
        if(tenant?.['default-theme']) {
            commit('setActive', tenant['default-theme'])
        }
   
    },
    async fetchTheme({}, id = '') {
        return await this.$api.get(`themes/${id}`)
    },

}

export const mutations = {
    setIsSet (state) {
        state.isSet = true
    },
    setIsActiveAppThemeSet (state, payload) {
        state.isActiveAppThemeSet = payload
    },
    setActive (state, payload) {
        state.active = payload
    },
    setLenderClass (state,payload) {
        state.lenderClass = payload
    },
    setFlexOverrideClass (state, payload) {
        state.flexOverrideClass = payload
    },

}
