<template>
    <v-card width="100%" flat>
        <v-card-text :class="`${$vuetify.breakpoint.smAndUp ? 'px-4' : 'px-4'}`">
            <ValidationObserver ref="observer" tag="form">

                <v-card tile flat class="rounded-lg mx-auto">

                    <v-card-title :class="`${kindOfPurchase ? 'px-0 pb-0' : 'px-0 pb-6'}`">
                        Purchase Details
                    </v-card-title>
                    <v-card-title
                        v-if="kindOfPurchase"
                        class="title text-uppercase font-weight-medium px-0 pt-0 pb-6">
                        {{ kindOfPurchase }}
                    </v-card-title>

                    <template v-for="(mfield, mkey) in filteredForm">
                        <!-- <div class="flex-form1" :key="mkey"> -->

                        <div v-if="mfield.title" class="title ma-0 my-2 body_text_color--text text-left">
                            {{ mfield.title }}
                        </div>

                        <v-row :key="mkey">
                            <template v-for="(field, key) in mfield.fields">
                                <template v-if="!nvicOnly(key)">
                                    <v-col
                                        v-if="!(resources.attributes['vendor-known'] !== 'Yes') || (key !== 'vendor-name' && key !== 'vendor-phone')"
                                        cols="12" sm="6" md="3" class="px-3 py-0"
                                        :key="key" ref="rer">
                                        <ValidationProvider
                                            v-if="isVisible(key)"
                                            v-slot="{ errors }"
                                            :name="labels[key]"
                                            :rules="filterRules(resources.attributes[key], key)"
                                            :vid="key">

                                            <nvic-finder
                                                v-if="key == 'nvic'"
                                                :label="labels[key]"
                                                :errors="errors"
                                                @accept="handleNvicData"
                                                v-model="resources.attributes[key]"
                                                >
                                                <!-- handleNvicAccept -->
                                            </nvic-finder>

                                            <template
                                                v-else-if="isFieldType('picklist', key)">
                                                <v-select
                                                    v-model="resources.attributes[key]"
                                                    :items="getOptions(key)"
                                                    :error-messages="errors"
                                                    color="secondary"
                                                    :label="labels[key]"
                                                    :data-field="`purchase-${key}`"
                                                    >
                                                    <template #item="{item, attrs, on}"  >
                                                      <v-list-item :data-field="`item-${item}`" v-bind="attrs" v-on="on">
                                                        {{item}}
                                                      </v-list-item>
                                                    </template>
                                                  </v-select>
                                            </template>

                                            <flex-date-picker
                                                v-else-if="isFieldType('date', key)"
                                                :label="labels[key]"
                                                :errors="errors"
                                                v-model="resources.attributes[key]"
                                                :data-field="`purchase-${key}`"
                                            ></flex-date-picker>

                                            <template
                                                v-else>

                                                <NodifiMoney
                                                    v-if="isNodifiMoney(key) && syncRefresh"
                                                    v-model="resources.attributes[key]"
                                                    :label="`${labels[key]}`"
                                                    :disabled="key === 'requested-required-loan-amount' ? true : false"
                                                    :data-field="`purchase-${key}`"
                                                    :errors="errors" />

                                                <v-text-field
                                                    v-else
                                                    tile
                                                    type="text"
                                                    :label="labels[key]"
                                                    color="secondary"
                                                    :error-messages="errors"
                                                    v-model="resources.attributes[key]"
                                                    :data-field="`purchase-${key}`"
                                                    required>
                                                </v-text-field>

                                            </template>

                                        </ValidationProvider>
                                    </v-col>
                                </template>
                            </template>


                            <!-- Temp Spacer -->
                            <div class="flex-field" v-for="index in 6" :key="index"></div>

                        <!-- </div> -->
                        </v-row>
                    </template>
                </v-card>
            </ValidationObserver>

        </v-card-text>
    </v-card>
</template>

<script>

    import { mapActions, mapGetters } from 'vuex'
    import NvicFinder from '~/components/base/NvicFinder'
    import FlexDatePicker from '~/components/base/FlexDatePicker'  
    import formHelpers from '~/components/shared/form-helpers.js'
    import validation from '~/components/shared/validation'
    import helpers from '~/plugins/helpers'
    import { isEmpty } from 'lodash'
    import additionalInfoLayout from './additional-information-layout'
    import fieldOptions from '~/components/shared/field-options'

    export default {
        name: 'FormPurchaseDetails',

        mixins: [ helpers, validation, formHelpers ],

        props: {
            syncRefresh: {
                type: Boolean,
                default: false,
            },
            purchaseData: {
                type: Object,
                default: () => {}
            },
            purchaseKind: {
                type: String,
                default: ''
            },
            selectedType: {
                type: String,
                default: ''
            },
            selectedSubType: {
                type: String,
                default: ''
            },
            kindOfPurchase: {
                type: String,
                default: ''
            }
        },

        async mounted() {
            if ( this.purchaseData?.id ) {
                await this.handleUpdateForm()
                // this.resources = Object.assign({}, this.purchaseData)
            }
            this.$emit('on-form-update',
                {
                    resources: this.resources,
                    observer: this.$refs.observer
                }
            )
        },

        components: { NvicFinder, FlexDatePicker },

        computed: {
            ...mapGetters('quote-actions', ['selectedNvic']),

            filteredForm() {

                let newForm = Object.assign([], this.form)

                if ( this.selectedNvic?.nvic ) {

                    this.resources.attributes['nvic'] = this.selectedNvic?.['nvic'] ? this.selectedNvic['nvic'] : ''
                    this.resources.attributes['year-of-manufacture'] = this.selectedNvic?.['year-of-manufacture'] ? this.selectedNvic['year-of-manufacture'] : ''
                    this.resources.attributes['make'] = this.selectedNvic?.['make'] ? this.selectedNvic['make'] : ''
                    this.resources.attributes['model'] = this.selectedNvic?.['model'] ? this.selectedNvic['model'] : ''
                    this.resources.attributes['variant'] = isEmpty(this.selectedNvic?.['variant']) ? '' : this.selectedNvic['variant']

                    this.partialUpdatePurchase(this.resources)

                }

                if (this.selectedSubType === 'Other (Not Listed)')
                    newForm[0].fields.description = ''
                else delete newForm[0].fields.description
                if (this.selectedSubType === 'Computer Hardware' || this.selectedSubType === 'Industrial Plant (Lathes etc)' || this.selectedSubType === 'Other (Not Listed)')
                    this.validation_rules.attributes['year-of-manufacture'] = ''
                else  this.validation_rules.attributes['year-of-manufacture'] = 'required|integer|digits_between:4,4'

                if (this.resources.attributes['trade-in-amount'] === 0 || this.resources.attributes['trade-in-amount'] === null || this.resources.attributes['trade-in-amount'] === '0.00' || this.resources.attributes['trade-in-amount'] === '') {
                    delete newForm[1].fields['trade-in-description']
                } else {
                    delete newForm[1].fields['required-loan-amount']
                    delete newForm[1].fields['payout-amount']
                    delete newForm[1].fields['payout-to']
                    newForm[1].fields['trade-in-description'] = ''
                    this.validation_rules.attributes['trade-in-description'] = 'required|string|max:50'
                    newForm[1].fields['payout-amount'] = ''
                    newForm[1].fields['required-loan-amount'] = ''
                }

                if (this.resources.attributes['payout-amount'] === 0 || this.resources.attributes['payout-amount'] === null || this.resources.attributes['payout-amount'] === '0.00' || this.resources.attributes['payout-amount'] === '') {
                    delete newForm[1].fields['payout-to']
                } else {
                    delete newForm[1].fields['required-loan-amount']
                    newForm[1].fields['payout-to'] = ''
                    this.validation_rules.attributes['payout-to'] = 'required|string|max:50'
                    newForm[1].fields['required-loan-amount'] = ''
                }

                if(this.purchaseKind) {

                    Object.keys(this.additionalInfoLayoutByKind).forEach(keys => {
                        let kinds = keys.split("|")

                        if(kinds.includes(this.purchaseKind)) {
                            newForm[2].fields = this.additionalInfoLayoutByKind[keys]
                        }

                    })

                }

                return newForm
            },
            tradeInAmountField () {
                return this.resources.attributes['trade-in-amount']
            },
            payoutAmountField () {
                return this.resources.attributes['payout-amount']
            },
            nvicField() {
                return this.resources.attributes['nvic']
            }
        },

        methods: {
            ...mapActions('resource-actions', [
                'updatePurchases'
            ]),

            isVisible(key){

                const {visible} = this

                if(visible.hasOwnProperty(key)){
                    return visible[key]
                }

                return true

            },

            async partialUpdatePurchase(data) {

                let attributes = {}

                Object.keys(data.attributes).forEach(attr => {
                    if (data.attributes[attr] != null && data.attributes[attr] != '') {
                        attributes[attr] = data.attributes[attr]
                    }
                })

                if (this.purchaseData?.id) {

                    Object.keys(this.purchaseData.attributes).forEach(field => {

                        if(this.purchaseData.attributes[field] != null)
                            attributes[field] = this.purchaseData.attributes[field]

                        if(field == 'payout-amount' && this.purchaseData.attributes['payout-amount'] == null) attributes['payout-amount'] = 0
                        if(field == 'trade-in-amount' && this.purchaseData.attributes['trade-in-amount'] == null) attributes['trade-in-amount'] = 0
                        if(field == 'cash-deposit' && this.purchaseData.attributes['cash-deposit'] == null) attributes['cash-deposit'] = 0
                        if(field == 'purchase-price' && this.purchaseData.attributes['purchase-price'] == null) attributes['purchase-price'] = 0

                    })

                }

                let dat = data
                dat.attributes = attributes

                if ( data?.id ) {
                    dat.attributes['vendor-known'] = 'Yes'
                    await this.updatePurchases(dat)
                }
            },

            filterRules(key) {

                if ( this.selectedType === 'Heavy Vehicles' ) {
                    if ( key === 'trade-in-amount')
                        if ( this.resources.attributes['trade-in-amount'] === '0' || this.resources.attributes['trade-in-amount'] === '' ) {
                            this.visible['trade-in-description'] = false
                            this.validation_rules.attributes['trade-in-description'] = ''
                        }
                        else {
                            this.validation_rules.attributes['trade-in-description'] = 'required|string|max:50'
                            this.visible['trade-in-description'] = true
                        }
                    if ( key === 'payout-amount')
                        if ( this.resources.attributes['payout-amount'] === '0' || this.resources.attributes['payout-amount'] === '' )
                            this.validation_rules.attributes['payout-to'] = ''
                        else
                            this.validation_rules.attributes['payout-to'] = 'required|string|max:200'
                }
                return this.getRules(key)
            },
            handleNvicData(data) {
                // console.log(data)
                this.imgPath = data.img
                this.resources.attributes["make"] = data.details["manufacturer-name"]
                this.resources.attributes["model"] = data.details["family-name"]
                this.resources.attributes["variant"] = !isEmpty(data.details["variant-name"]) ? data.details["variant-name"] : ''
                this.resources.attributes["year-of-manufacture"] = data.details["year-create"]

                // this.resources.attributes["vin"] = isEmpty(data.details["vin-number"]) ? '' : data.details["vin-number"]
                
                this.resources.attributes["fuel-type"] = this.getNvicDataMapping('fuel-type', data.details["fuel-name"])
                this.resources.attributes["body-type"] = this.getNvicDataMapping('body-type', data.details["body-name"])
                this.resources.attributes["transmission"] = this.getNvicDataMapping('transmission', data.details["transmission-name"])

                this.resources.attributes["retail-value"] = data.details["retail"]
                this.resources.attributes["dealer-retail"] = data.details["rrp"]
                
            },

            getNvicDataMapping(key, nvic) {
                const nvicFielData = this.options[key]
                let result = null

                if(!nvic) return null

                if(nvicFielData) {
                    nvicFielData.forEach(nv => {
                        if(nvic.toLowerCase().includes(nv.toLowerCase())) {
                            result = nv
                        }
                    })
                }

                // set transmission to Automatic if nvic has auto
                if(key === 'transmission') {
                    if(nvic.toLowerCase().includes('auto')) result = 'Automatic'
                }

                return result
            },
                
            refreshForm() {
                this.resources.attributes["vendor-known"] = ''
                this.resources.attributes["vendor-name"] = ''
                this.resources.attributes["vendor-phone"] = ''
                this.resources.attributes["make"] = ''
                this.resources.attributes["model"] = ''
                this.resources.attributes["variant"] = ''
                this.resources.attributes["year-of-manufacture"] = ''
                this.resources.attributes["nvic"] = ''
                this.resources.attributes["condition"] = ''
                this.resources.attributes["purchase-price"] = ''
                this.resources.attributes["cash-deposit"] = ''
                this.resources.attributes["trade-in-amount"] = ''
                this.resources.attributes["trade-in-description"] = ''
                this.resources.attributes["payout-amount"] = ''
                this.resources.attributes["payout-to"] = ''
                this.resources.attributes["required-loan-amount"] = ''

                this.resources.attributes["serial-number"] = ''
                this.resources.attributes["engine-number"] = ''
                this.resources.attributes["registration-number"] = ''
                this.resources.attributes["registration-state"] = ''
                this.resources.attributes["build-date"] = ''
                this.resources.attributes["compliance-date"] = ''
                this.resources.attributes["hours"] = ''
                this.resources.attributes["hin"] = ''
                this.resources.attributes["vin"] = ''
                this.resources.attributes["colour"] = ''
                this.resources.attributes["odometer"] = ''

                this.resources.attributes["retail-value"] = ''
                this.resources.attributes["dealer-retail"] = ''

                this.validation_rules.attributes['variant'] = ""
                this.validation_rules.attributes['fuel-type'] = "string|in:Petrol,Diesel,Electric,Hybrid,Plug-in Hybrid Electric"
                this.validation_rules.attributes['body-type'] = "string|in:4WD,Coupe,Hatch,Sedan,Station Wagon,Utility"
                this.validation_rules.attributes['transmission'] = "string|in:Automatic,Manual,CVT"

            },
            handleUpdateForm() {
                let rels = {}
                this.resources.id = this.purchaseData.id

                Object.keys(this.resources.attributes).forEach( field => {
                    if (this.purchaseData.attributes[field] != null && this.purchaseData.attributes[field] != '')
                        this.resources.attributes[field] = this.purchaseData.attributes[field]
                })

                Object.keys(this.purchaseData.relationships).forEach( rel => {
                    if ( this.purchaseData.relationships[rel]?.data?.id || this.purchaseData.relationships[rel]?.data?.length > 0 ) {
                        rels[rel] = this.purchaseData.relationships[rel]
                    }
                })

                if (this.resources.attributes['payout-amount'] == null) this.resources.attributes['payout-amount'] = 0
                if (this.resources.attributes['trade-in-amount'] == null) this.resources.attributes['trade-in-amount'] = 0
                if (this.resources.attributes['cash-deposit'] == null) this.resources.attributes['cash-deposit'] = 0
                if (this.resources.attributes['purchase-price'] == null) this.resources.attributes['purchase-price'] = 0

                this.resources.relationships = rels

            },
            nvicOnly(keyword) {
                let nvic_filters = ['nvic']
                if ( nvic_filters.indexOf(keyword) >= 0 ) {
                    return this.selectedType !== 'Motor Vehicle' ? true : false
                }
                return false
            },
            isNodifiMoney(key) {

                const nodifiMoney = [
                    "purchase-price",
                    "cash-deposit",
                    "trade-in-amount",
                    "payout-amount",
                    "required-loan-amount"
                ]

                if(nodifiMoney.includes(key)) {
                    return true
                }

                return false
            },
            filterRules(field = {}, key = '') {
                let rule = this.getRules(key)
                // required|string|max:200
                if ( key === 'trade-in-amount' ) {
                    if ( this.resources.attributes['trade-in-amount'] && (this.resources.attributes['trade-in-amount'] > 0 || this.resources.attributes['trade-in-amount'] !== '0' ))
                        this.validation_rules.attributes['trade-in-description'] = 'required|string|max:50'
                    else this.validation_rules.attributes['trade-in-description'] = ''
                }
                if ( key === 'payout-amount' ) {
                    if ( this.resources.attributes['payout-amount'] && (this.resources.attributes['payout-amount'] > 0 || this.resources.attributes['payout-amount'] !== '0' ))
                        this.validation_rules.attributes['payout-to'] = 'required|string|max:50'
                    else this.validation_rules.attributes['payout-to'] = ''
                }

                return rule
            }
        },

        watch: {
            'syncRefresh': {
                handler(val) {
                    if (!val) {
                        if ( this.purchaseData?.id) {
                            this.handleUpdateForm()
                        }
                        else {
                            this.$refs.observer.reset()
                            this.refreshForm()
                        }
                    }
                    else {
                        if ( this.purchaseData )
                            this.handleUpdateForm()
                    }
                },
                deep: true
            },
            'resources': {
                handler(val) {
                    this.$emit('on-form-update',
                        {
                            resources: this.resources,
                            observer: this.$refs.observer
                        }
                    )
                },
                deep: true
            },
            'imgPath': {
                handler(val) {
                    this.$emit('on-chosen-img', this.imgPath)
                },
                deep: true
            },
            'resources.attributes': {
                handler(val) {
                    let loanAmount = 0
                    let purchasePrice = val['purchase-price'] ? parseFloat( val['purchase-price'] ) : 0
                    let payoutAmount = val['payout-amount'] ? parseFloat( val['payout-amount'] ) : 0
                    let cashDeposit = val['cash-deposit'] ? parseFloat( val['cash-deposit'] ) : 0
                    let tradeInAmount = val['trade-in-amount'] ? parseFloat( val['trade-in-amount'] ) : 0
                    loanAmount = ( purchasePrice + payoutAmount ) - ( cashDeposit + tradeInAmount )

                    val['required-loan-amount'] = loanAmount
                },
                deep: true
            },
            tradeInAmountField (val) {
                if (val === '0.00' || val === '') {
                    this.visible['trade-in-description'] = false
                } else {
                    this.visible['trade-in-description'] = true
                }
            },
            payoutAmountField (val) {
                if (val === '0.00') {
                    this.visible['payout-to'] = false
                    delete this.resources.attributes['payout-to']
                } else {
                    this.visible['payout-to'] = true
                }
            },
            nvicField(val) {
                if(val) {
                    this.validation_rules.attributes['fuel-type'] = "required|string|in:Petrol,Diesel,Electric,Hybrid,Plug-in Hybrid Electric"
                    this.validation_rules.attributes['body-type'] = "required|string|in:4WD,Coupe,Hatch,Sedan,Station Wagon,Utility"
                    this.validation_rules.attributes['transmission'] = "required|string|in:Automatic,Manual,CVT"
                }
            }
        },

        data() {
            return {
                visible: {
                    "trade-in-description": false,
                    "payout-to": false,
                    "retail-value": false,
                    "dealer-retail": false,
                },
                imgPath: '',
                additionalInfoLayoutByKind: additionalInfoLayout.additionalInfoLayoutByKind,
                form: [
                    {
                        "title": "",
                        "fields": {
                            "condition": "",
                            "description": "",
                            "vendor-known": "",
                            "vendor-name": "",
                            "vendor-phone": "",
                        }
                    },
                    {
                        "title": "",
                        "fields": {
                            "nvic": "",
                            "year-of-manufacture": "",
                            "make": "",
                            "model": "",
                            "variant": "",
                            "purchase-price": "",
                            "cash-deposit": "",
                            "trade-in-amount": "",
                            "trade-in-description": "",
                            "payout-amount": "",
                            "payout-to": "",
                            "required-loan-amount": ""
                        }
                    },
                    {
                        "title": "Additional Information",
                        "fields": {
                            "serial-number": "",
                            "engine-number": "",
                            "registration-number": "",
                            "registration-state": "",
                            "build-date": "",
                            "compliance-date": "",
                            "hours": "",
                            "hin": "",
                            "vin": "",
                            "colour": "",
                            "odometer": "",
                            "fuel-type": "",
                            "body-type": "",
                            "transmission": "",
                        }
                    }
                ],
                resources: {
                    type: "purchases",
                    id: null,
                    attributes: {
                        "retail-value": "",
                        "dealer-retail": "",

                        "condition": "",
                        "description": "",
                        // "asset-found": "",
                        "vendor-known": "",
                        "vendor-name": "",
                        "vendor-phone": "",
                        "nvic": "",
                        "year-of-manufacture": "",
                        "make": "",
                        "model": "",
                        "variant": "",
                        // "kms": "",
                        "purchase-price": "",
                        "cash-deposit": "",
                        "trade-in-amount": "",
                        "trade-in-description": "",
                        "payout-amount": "",
                        "payout-to": "",
                        "required-loan-amount": "",

                        "serial-number": "",
                        "engine-number": "",
                        "registration-number": "",
                        "registration-state": "",
                        "build-date": "",
                        "compliance-date": "",
                        "hours": "",
                        "hin": "",
                        "vin": "",
                        "colour": "",
                        "odometer": "",

                        "fuel-type": "",
                        "body-type": "",
                        "transmission": "",
                    },
                    relationships: {
                        application: {
                            data: { type: "applications", id: null }
                        },
                        kind: {
                            data: { type: "kinds", id: null }
                        }
                    }
                },
                options: {
                    "fuel-type": ["Petrol", "Diesel", "Electric", "Hybrid", "Plug-in Hybrid Electric"],
                    "body-type": ["4WD", "Coupe", "Hatch", "Sedan", "Station Wagon", "Utility"],
                    "transmission": ["Automatic","Manual","CVT"],
                },
                labels: {
                    "nvic": "NVIC",
                    "asset-found": "Asset Found/Vendor Known?",
                    "vendor-name": "Vendor Name",
                    "vendor-known": "Vendor Found",
                    "vendor-phone": "Vendor Phone",
                    "year-of-manufacture": "Year of Manufacture",
                    "make": "Make",
                    "model": "Model",
                    "variant": "Variant",
                    "condition": "Condition",
                    "description": "Description",
                    // "kms": "KMS",
                    "purchase-price": "Purchase Price",
                    "cash-deposit": "Cash Deposit",
                    "trade-in-amount": "Trade in Amount",
                    "trade-in-description": "Trade Description",
                    "payout-amount": "Payout Amount",
                    "payout-to": "Payout To",
                    "required-loan-amount": "Amount Required (SUM)",
                    "serial-number": "Serial Number",
                    "engine-number": "Engine Number",
                    "registration-number": "Registration Number",
                    "registration-state": "Registration State",
                    "build-date": "Build Date",
                    "compliance-date": "Compliance Date",
                    "hours": "Hours",
                    "hin": "Hin",
                    "vin": "Vin",
                    "colour": "Colour",
                    "odometer": "Odometer",
                    "fuel-type": "Fuel Type",
                    "body-type": "Body Type",
                    "transmission": "Transmission",
                },
                validation_rules: {
                    attributes: {
                        "nvic": "string|max:50",
                        "asset-found": "required|string|in:Yes,No",
                        "vendor-known": "required|string|in:Yes,No",
                        "vendor-name": "required|string|max:50",
                        "vendor-phone": "required|string|max:50",
                        "year-of-manufacture": "required|integer|digits_between:4,4",
                        "make": "required",
                        "model": "required",
                        "variant": "", // "required|string|max:128",
                        "condition": "required|string|in:New,Used",
                        "description": "required|string|max:50",
                        // "kms": "required|integer",
                        "purchase-price": "required|between:1,9999999",
                        "cash-deposit": "", // "required|between:0,9999999",
                        "trade-in-amount": "", // "required|between:0,9999999",
                        "trade-in-description": "", // "required|string|max:50",
                        "payout-amount": "", // "required|between:0,9999999",
                        "payout-to": "", // "required|string|max:200",
                        "sale-type": "required|string|in:Dealer,Private Sale,Refinance,Sale & Buyback,Pre-Approval,Purchase,Top up,Variation",
                        "required-loan-amount": "required|between:0,9999999",

                        "serial-number": "",
                        "engine-number": "",
                        "registration-number": "",
                        "registration-state": `string|in:${fieldOptions["drivers-licence-state"]}`,
                        "build-date": "|date_format:Y/m/d|before:now",
                        "compliance-date": "|date_format:Y/m/d|before:now",
                        "hours": "",
                        "hin": "",
                        "vin": "|valid_vin",
                        "colour": "",
                        "odometer": "",

                        "retail-value": "",
                        "dealer-retail": "",

                        "fuel-type": "string|in:Petrol,Diesel,Electric,Hybrid,Plug-in Hybrid Electric",
                        "body-type": "string|in:4WD,Coupe,Hatch,Sedan,Station Wagon,Utility",
                        "transmission": "string|in:Automatic,Manual,CVT",
                    }
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
    .v-data-table__wrapper > table {
        border-spacing: 10  !important;
    }

    .controls {
            .v-btn {
                margin: 0 5px;
                padding: 0 30px;
            }
    }

    .v-card__title {

        .v-subheader  {
            display: block;
            line-height: 1.4;
            height: auto;
        }
    }

    .flex-box {
        padding-top: 100%;
        height: 0;
        position: relative;

        & > span {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }

    @media (max-width: 960px) {

        .controls {
            justify-content: center;

            .spacer {
                display: none;
            }
        }

        .controls {

            .v-btn {
                margin: 0 5px 10px;
                padding: 0 12px;

            }

        }

    }
</style>
