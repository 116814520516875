<template>
        <v-card tile flat :disabled="loading">
            <!-- #income-fo-form -->
            <Portal to="income-fo-form">
                <FOIncomeForms :data="currentStep" :ref="`incomeForm`"/>
            </Portal>

            <v-card-text class="pa-2 pb-8">

                <v-card tile flat class="mx-auto" :width="!isLastStep ? 800 : 1000"> 

                    <template v-if="!isLastStep">
                        <v-card-title :class="['px-3 pt-0 pb-3 justify-center']">
                            Income
                        </v-card-title>
                        <v-card-text class="mb-6 text-center">
                            <div class="body-1 font-weight-medium">
                                Select an income from below
                            </div>
                        </v-card-text>  
                    </template>

                    <v-carousel
                        light
                        :value="stepper"
                        :continuous="false"
                        :show-arrows="false"
                        hide-delimiters
                        touchless
                        hide-delimiter-background
                        height="auto"
                        >
                        <v-carousel-item v-for="(step, index) in steps" :key="step.id">

                                <!-- <ButtonGroup1 
                                    v-model="preselectIncomeSituation"
                                    large
                                    :options="incomeSituationOptions"
                                    label="Income Situation"
                                /> -->

                                <v-container v-if="!hasPreselectIncomeSituation" class="light_gray_1 px-4 py-2 rounded-lg"  :style="containerWidth">
                                    <v-row>
                                        <v-col
                                            v-for="option in incomeSituationOptions"
                                            class="pa-2"
                                            :key="option"
                                            cols="12">
                                        
                                            <v-card
                                                flat
                                                height="100%" 
                                                @click="selectOption(option)"
                                                rounded="lg"
                                                class="d-flex pa-4 align-center justify-center">
                                                <div class="flex-options body-2 font-weight-medium">
                                                    {{ option }}
                                                </div>
                                                <v-spacer></v-spacer>
                                                <v-btn color="secondary" :width="86" small depressed>
                                                    Select
                                                </v-btn>
                                            </v-card>

                                        </v-col>
                                    </v-row>
                                </v-container>

                                <template v-else-if="step.children.length > 0">

                                    <v-container v-if="step.children.length > 3" class="light_gray_1 px-4 py-2 rounded-lg">
                                        <v-row>
                                            <v-col
                                                v-for="child in step.children"
                                                class="pa-2"
                                                v-if="isVisible(child.id)"
                                                :key="child.id"
                                                cols="12"
                                                sm="6">
                                            
                                                <v-card
                                                    flat
                                                    height="100%" 
                                                    @click="next(child)"
                                                    rounded="lg"
                                                    class="d-flex pa-4 align-center justify-center">
                                                    <div class="flex-options body-2 font-weight-medium">
                                                        {{ child.name }} {{ development ? `${child.id}` : ''}}
                                                    </div>
                                                    <v-spacer></v-spacer>
                                                    <v-btn color="secondary" :width="86" small depressed>
                                                        Select
                                                    </v-btn>
                                                </v-card>

                                            </v-col>
                                        </v-row>
                                    </v-container>

                                    <v-container v-else class="light_gray_1 px-4 py-2 rounded-lg" :style="containerWidth">

                                        <v-row>
                                            <v-col
                                                v-for="child in step.children"
                                                class="pa-2"
                                                v-if="isVisible(child.id)"
                                                :key="child.id"
                                                cols="12"
                                            >
                                            
                                                <v-card
                                                    flat
                                                    height="100%" 
                                                    @click="next(child)"
                                                    rounded="lg"
                                                    class="d-flex pa-4 align-center justify-center"
                                                >

                                                    <div class="flex-options body-2 font-weight-medium">
                                                        {{ child.name }} {{ development ? `${child.id}` : ''}}
                                                    </div>
                                                    <v-spacer></v-spacer>
                                                    <v-btn color="secondary" :width="86" small depressed>
                                                        Select
                                                    </v-btn>

                                                </v-card>

                                            </v-col>
                                        </v-row>

                                    </v-container>

                                </template>

                                <v-container v-else>
                                    <v-card v-if="selectedEntityAll && !hasPreSectedRoles" flat tile>
                                        <v-card-text>
                                            <RoleSelector 
                                                v-model="preSelectRoles"
                                                label="Select Role"
                                                large
                                                :max="1"
                                                :data="entities"
                                                :parts="entityParts"
                                            />
                                        </v-card-text>
                                    </v-card>
                                    <template v-else>
                                        <PortalTarget name="income-fo-form">
                                            <!-- 
                                                Using portal to keep single instance of FOIncomeForms
                                                See #income-fo-form above ^
                                            -->
                                        </PortalTarget>
                                    </template>
                                </v-container>

                        </v-carousel-item>
                    </v-carousel>
                </v-card>

            </v-card-text>

            <v-card :height="30" tile flat />
            
            <Portal to="fo-editor-actions">
    
                <v-divider></v-divider>

                <v-card-actions class="pa-4 pa-md-6 flex-wrap controls">

                    <template v-if="selectedEntityAll && !hasPreSectedRoles">
                        <v-btn v-if="isLastStep" color="secondary" :disabled="preSelectRoles.length ==0" :min-width="140" depressed nuxt large @click="handleNext">Next</v-btn>
                    </template>
                    
                    <template v-else>
                        <v-btn v-if="isLastStep" color="secondary" :min-width="140" depressed nuxt large @click="save">Save & Close</v-btn>
                        <v-btn v-if="isLastStep && !isBusiness" color="secondary" :min-width="140" depressed nuxt large @click="saveAndNew">Save & New</v-btn>
                    </template>

                    <v-spacer></v-spacer>

                    <template v-if="!isBusiness">
                        
                        <template v-if="stepper == 0">
                            <v-btn color="light_gray_2" class="flex-back-button" v-if="hasPreselectIncomeSituation && preselectIncomeSituation == 'Current'" :min-width="140" depressed outlined nuxt large @click="cancel">Back</v-btn>
                            <v-btn color="light_gray_2" class="flex-back-button" v-if="hasPreselectIncomeSituation && preselectIncomeSituation != 'Current'" :min-width="140" depressed outlined nuxt large @click="resetPreselectIncomeSituation">Back</v-btn>
                            <v-btn color="light_gray_2" class="flex-back-button" v-if="!hasPreselectIncomeSituation" :min-width="140" depressed outlined nuxt large @click="cancel">Back</v-btn>
                        </template>

                        <template v-else>
                            <v-btn color="light_gray_2" class="flex-back-button" :min-width="140" depressed outlined nuxt large @click="back">Back</v-btn> 
                        </template>

                    </template>

                    <template v-if="isBusiness">
                        <v-btn color="light_gray_2" class="flex-back-button" v-if="hasPreselectIncomeSituation && stepper == 0" :min-width="140" depressed outlined nuxt large @click="cancel">Back</v-btn>
                    </template>

                </v-card-actions>
            </Portal>

        </v-card>
</template>

<script>

    import { mapState, mapActions, mapGetters, mapMutations } from 'vuex'
    import {store, actions} from '~/components/v1/FinancialObjectsEditor1/__store'
    import shared from '~/components/v1/FinancialObjectsEditor1/__shared'

    import FOIncomeForms from '~/components/v1/FinancialObjectsEditor1/_FOIncomeForms'
    import RoleSelector from '~/components/v1/FinancialObjectsEditor1/_RoleSelector'
    import ButtonGroup1 from '~/components/base/ButtonGroup1'


    export default {
        name: 'FOIncomeEditor',
        components: {
            FOIncomeForms,
            RoleSelector,
            ButtonGroup1
        },
        mixins: [shared],
        props: {
        },
        data() {
            return {
                containerWidth: {
                    width: '55%'
                },
                hasPreSectedRoles: false,
                visible: {
                    "12-30": false, // Business Income 12-30
                }
            }
        },
        computed: {
            ...mapGetters('kinds', ['getKindsIncome']),
            preSelectRoles: {
                get(){
                    return store.preSelectRoles
                },
                set(value){
                    actions.setPreSelectRoles(value)
                }
            },
            preselectIncomeSituation: {
                get(){
                    return store.preselectIncomeSituation
                },
                set(value){
                    actions.setPreselectIncomeSituation(value)
                }
            },
            hasPreselectIncomeSituation(){
                if(this.preselectIncomeSituation){
                    return true
                }
                return false
            },
            loading(){
                return store.saving
            },
        },
        mounted(){

            actions.setKindsIncome(this.getKindsIncome)
            actions.resetIncomeEditorDialog()

            // If Selected Entity is "All"
            if(this.entities.length == this.selectedEntity){
                actions.setSelectedEntityAll(true)
            }

            this.initializeIncomeEntityLogic()
            
        },
        methods: {
            cancel(){

                actions.closeDialog()

                setTimeout( () => {

                    actions.resetIncomeEditorDialog()

                    this.hasPreSectedRoles = false

                }, 300)

            },
            reset(){

                setTimeout( () => {

                    actions.resetIncomeEditorDialog()

                    this.hasPreSectedRoles = false
                    
                    this.initializeIncomeEntityLogic()

                }, 300)

            },
            next(child){
                actions.nextStep(child)
            },
            back(){
                actions.prevStep()
            },
            selectOption(value) {
                actions.setPreselectIncomeSituation(value)
            },
            save(){
                if(this.$refs.incomeForm){
                    this.$refs.incomeForm.save().then(res => {
                        if(res.status == 'ok'){
                            this.cancel()
                        }
                    })
                }

            },
            saveAndNew(){

                if(this.$refs.incomeForm){
                    this.$refs.incomeForm.save().then(res => {
                        if(res.status == 'ok'){
                            this.reset()
                        }
                    })
                }

            },
            handleNext(){

                this.hasPreSectedRoles = true

            },
            resetPreselectIncomeSituation(){
                actions.setPreselectIncomeSituation(null)
            },
            isVisible(key){
                
                const {visible} = this

                if(visible.hasOwnProperty(key)){
                    return visible[key]
                }

                return true

            },
  
        },
        watch: {
            isBusiness(val){

            }
        },

    }
</script>

<style lang="scss" scoped>

    .flex-tile {
        line-height: 1.4;
    }

</style>