<template>
    <v-card class="mx-auto" tile flat>
        <v-card-text class="pb-4 pt-0">
            <v-container pa-0 fluid>

                <ValidationObserver ref="observer" tag="form">
                    <v-row>                        
                        <v-col cols="12" md="6" class="mb-4 overflow-hidden px-10">

                            <div class="font-size-26 font-weight-medium mt-3 mb-6">Submission notes</div>
                            <ValidationProvider
                                v-slot="{ errors }"
                                name="Submission Comment"
                                :rules="getRules('submission-comments')"
                                tag="div"
                                class="submission-text-wrap"
                                vid="submission-comments">

                                    <v-textarea
                                        v-model="resources.loan.attributes['submission-comments']"
                                        auto-grow
                                        placeholder="Regarding any information to help with the deal"
                                        rows="10"
                                        height="100%"
                                        row-height="16"
                                        class="ma-0 pa-0"
                                        :error-messages="errors"
                                    >
                                    </v-textarea> 

                            </ValidationProvider>

                        </v-col>
                        <v-col cols="12" md="6" class="px-10">
                            <v-row>
                                <v-col cols="12" class="pb-5">
                                    <div class="font-size-26 font-weight-medium">Acknowledgement</div>
                                </v-col>
                                <v-col
                                    v-for="(fieldGroup, fkey) in acknowledgment"
                                    :key="fkey"
                                    class="py-0"
                                    :cols="getAckCol(acknowledgment)">

                                    <ValidationProvider
                                        v-slot="{ errors }"
                                        :name="fieldGroup.shortMessage | formalText"
                                        :rules="getRules(fieldGroup.meta)"
                                        :vid="fieldGroup.meta">

                                        <p class="font-size-16 pb-0 mb-0" style="max-width: 224px;">{{fieldGroup.label}}</p>

                                        <v-checkbox
                                            v-model="terms[fieldGroup.meta]"
                                            :error-messages="errors"
                                            background-color="transparent acknowledgment rounded-lg"
                                            class="shrink float-left pt-2 pa-0 ma-0 ">

                                            <template v-slot:label>
                                                <div class="pt-1 pb-0 mb-0 float-bottom font-size-16 font-weight-bold">Agree</div>
                                            </template>

                                        </v-checkbox>

                                    </ValidationProvider>

                                </v-col>
                            </v-row>
                        </v-col>
                        
                    </v-row>

                </ValidationObserver>

                <div class="px-8" v-if="!isNodifiConsumer">
                    <FileUpload
                        :required-docs="fileStructure" 
                        :loading="uploading"
                        :error-messages="fileErrorMessages"
                        @remove-file="removeFile"
                        @upload-files="uploadFile" />
                </div>
                
            </v-container>
        </v-card-text>

        <!-- This will replace Next button in StepIncrementer1  -->
        <Portal to="step-incrementer-next">
            <v-btn 
                v-if="authUserCanSubmitApp"
                color="secondary" 
                large depressed nuxt 
                :min-width="btnWidth" 
                @click="handleSubmit" 
                class="ml-3" 
                :disabled="processing">
                Submit
            </v-btn>
            <!-- Auth user not allowed to submit -->
            <div v-else></div>
        </Portal>

        <v-overlay class="ma-0 pa-0" :value="overlay" opacity=".2">
            <div class="spinner">      
                <div class="flex-brand-loader">
                    <div class="flex-brand d-block">
                        <loaderImage />
                    </div>
                </div>
            </div>
        </v-overlay>
    </v-card>
</template>

<script>

    import loaderImage from '~/components/base/loaderImage'
    import FileUpload from '~/components/base/FileUpload'
    import subscriptions from '~/components/shared/subscriptions'
    import { mapState, mapGetters, mapActions, mapMutations } from 'vuex'

    export default {
        components: {
            loaderImage,
            FileUpload
        },
        mixins: [subscriptions],

        data(){
            return {
                uploading: false,
                requiredDocs: {},
                confirmDialogTitle: '',
                confirmDialog: false,
                fileErrorMessages: {},

                overlay: false,
                validComponentData: true,

                resources: {
                    loan: {
                        type: "loan",
                        id: null,
                        attributes: {
                            "submission-comments": null,
                        },
                        relationships: {
                            application: {
                                data: {type: "applications", id: null}
                            }
                        }
                    },
                    applications: {
                        type: "applications",
                        id: null,
                        attributes: {},
                    }
                },
                terms: {
                    "agree-documents": "",
                    "agree-application": "",
                    "hybrid-declaration": "",
                },
                acknowledgment: [
                    {
                        label: 'I understand and advised the client they will receive communication relating to electronic privacy documents',
                        meta: 'agree-documents',
                        type: 'checkbox',
                        shortMessage: 'Acknowledgement'
                    },
                    {
                        label: 'I acknowledge the information supplied by me in this application to be true and correct to the best of my knowledge',
                        meta: 'agree-application',
                        type: 'checkbox',
                        shortMessage: 'Acknowledgement'
                    },
                ],
                validation_rules: {
                    type: "people",
                    attributes: {
                        "submission-comments": "required|min:250",
                        "agree-documents": "required|is_not:false",
                        "agree-application": "required",
                        "hybrid-declaration": "required|is_not:false"
                    }
                }
            }
        },

        computed: {
            ...mapState({
                appId: state => state.flows.appId,
                appGuid: state => state.flows.appGuid,
                flowData: state => state.flows.flowData,
            }),
            ...mapState('statuses', [
                'stepStatuses'
            ]),
            ...mapGetters('slug', [
                'getTenant',
            ]),
            ...mapGetters('statuses', [
                'getCurrentFlowStatus'
            ]),
            ...mapGetters('flows', [
                'getCalculation',
            ]),
            processing(){
                return  this.isDisabled
            },
            btnWidth () {
                switch (this.$vuetify.breakpoint.name) {
                    case 'xs': return 0
                    case 'lg': 
                    case 'xl':
                    case 'md': 
                    case 'sm': return 140
                }
            },
            isFinancePurpose() {
                if ( this.getFlowSlug.indexOf('commercial') >= 0 || this.getFlowSlug.indexOf('business') >= 0 )
                    return 'Yes'
                return 'No'
            },
            appSubmissionType() {

                if(this.resources.applications.attributes['hybrid-tnf'] === 1) {
                    return 'Hybrid'
                } else if(this.resources.applications.attributes['hybrid-tnf'] === 0) {
                    return 'Co-Pilot'
                } else {
                    return null
                }

            },
            isCommercial(){
              if(this.resources.loan){
                  return  this.resources.loan.attributes['finance-for-business-purposes'] == 'Yes'
              }
              return false
            },
            hybridApplication() {
                return ['loanmarket', 'choice', 'plan', 'fast', 'nodifi'].includes(this.getTenant)
            },
            fileStructure() {
                const { requiredDocs } = this
                let requiredFiles = []

                if(Object.keys(requiredDocs).length) {
                    Object.keys(requiredDocs).forEach(keys => {

                        if(!keys.includes('application')) {

                            const applicant = requiredDocs[keys]['name']

                            if(Object.keys(requiredDocs[keys].requirements).length) {

                                Object.keys(requiredDocs[keys].requirements).forEach(docType => {

                                    requiredFiles.push({
                                        'title': docType,
                                        'chip-name': docType,
                                        'chip-description': requiredDocs[keys].requirements[docType].description,
                                        'paths': requiredDocs[keys].requirements[docType].paths,
                                        'applicant': applicant ? applicant : 'Person',
                                        'prefix': requiredDocs[keys].requirements[docType]['upload_prefix'],
                                    })

                                })

                            }

                        } else {
                            requiredFiles.push({
                                'title': 'Other Documents',
                                'chip-name': 'Other Documents',
                                'chip-description': requiredDocs[keys].requirements['Other Documents'].description,
                                'paths': requiredDocs[keys].requirements['Other Documents'].paths,
                                'applicant': '',
                                'prefix': requiredDocs[keys].requirements['Other Documents']['upload_prefix'],
                            })
                        }

                    })
                }

                return requiredFiles
            },
            isNodifiConsumer(){
                return this.getCalculation?.['application-type'].includes('Consumer') && this.getTenant == "nodifi"
            }
        },
        methods: {
            ...mapActions('resource-actions', [
                'getLoan',
                'updateLoan',
                'getApplication',
                'runServiceUpload',
                'runServiceDeleteFile',
                'getRequiredDocs',
                'runAssisterSubmit'
            ]),
            ...mapActions('statuses', [
                'completeFlowStatus'
            ]),
            ...mapActions('flows', [
                'selectFinalStep',
            ]),
            ...mapMutations('utils', [
                'toggleAlert',
                'toggleQuickMessage'
            ]),

            async pull() {

                let requests = [
                    this.getApplication({ id: this.appId, 
                        params: {
                            include: 'loan'
                        }
                    }),
                ]

                await this.getRequiredDocuments()

                Promise.all(requests).then( data => {
                    let applications = data.find( dat => {
                        return dat.data.data.type === 'applications'
                    })

                    this.resources.applications = applications.data.data
                    this.resources.loan = this.resourceFieldPicker(this.resources.loan, applications.data.included[0])

                    if(this.hybridApplication && this.appSubmissionType === 'Hybrid') {
                        this.acknowledgment.push({
                            label: 'I acknowledge the client has not been quoted rates, repayments or lender recommendations to the client',
                            meta: 'hybrid-declaration',
                            type: 'checkbox',
                            shortMessage: 'Acknowledgement'
                        })
                    }

                    if ( !this.resources.loan.attributes['finance-for-business-purposes'] ) {
                        this.resources.loan.attributes['finance-for-business-purposes'] = this.isFinancePurpose
                    }

                    return data

                })

            },
            async push() {

              this.overlay = true

              const observer = this.$refs.observer
              let validSteps = true

              let iterations = this.stepStatuses.length

              this.stepStatuses.forEach( (step, i) => {
                  if (--iterations) {
                      if ( step.attributes.value !== 'valid' && step.attributes.included === 1 )
                          validSteps = false
                  }
              })

              const {valid, status} = await this.validateFormData()
              // const validFiles = this.validateFiles()

              const {loan} = this.resources

              // Exclude relationships and other stuff
              let payload = {
                  type: loan.type,
                  id: loan.id,
                  attributes: this.excludeInvalids(loan.attributes, observer.fields)
              }

              payload.attributes['finance-for-business-purposes'] = this.resources.loan.attributes['finance-for-business-purposes']

              if( loan?.id ) {

                await this.updateLoan(payload)

                if ( validSteps && valid ) {

                    // triger assister submission
                    if(this.submitFlag) {
                        await this.assistersSubmissions()
                    }

                } else {

                    this.overlay = false

                    this.toggleAlert({
                        text: 'Steps are incomplete... To continue, please fill the steps below, completely:',
                        isStepValidator: true,
                        type: 'error',
                        prominent: true,
                        dismissible: true
                    })
                }

              }

              return { valid: valid && validSteps, status: valid && validSteps ? 'valid' : 'dirty' }

            },
            handleSubmit(){
                /**
                 * This flag is to determine whether to run assister submit
                 * inside the push() method or NOT. (e.g Save & Exit )
                 */
                this.submitFlag = true
                this.selectFinalStep({flowStatus: this.getCurrentFlowStatus.id})
            },
            async assistersSubmissions() {

            let { appGuid, assisters, flowData } = this

            try {

                this.overlay = true

                let assisterSubmissions = {
                    'application': appGuid, // guid
                    'flow': flowData.attributes.slug // flow response slug
                }

                let res = await this.runAssisterSubmit(assisterSubmissions)


                this.overlay = false

            } catch (err) {
                this.overlay = false

                console.log(err)
            }

            },

            wordCount(field = '') {
                return this.resources?.loan?.attributes?.[field]?.length <= 250 ? 250 : false
            },

            async uploadFile(data) {
                try {

                    this.uploading = true

                    if(data.files.length > 0) {

                        let payload = new FormData()

                        payload.append('application', this.appGuid)
                        payload.append('prefix', data.prefix)

                        for(let index = 0; index < data.files.length; index++) {
                            payload.append('files['+ index +']', data.files[index])
                        }

                        const res = await this.runServiceUpload(payload)

                        if(res.status === 200) {

                            await this.getRequiredDocuments()

                        } else {

                            this.toggleQuickMessage({text: 'Uh oh! something went wrong!' , color: 'error'})
                            await this.getRequiredDocuments()

                        }

                    } else {

                        await this.getRequiredDocuments()

                    }

                    this.uploading = false
                    
                } catch (err) {
                    this.uploading = false
                    console.log(err)
                }
                
            },

            async removeFile(name) {
                try {

                    this.uploading = true

                    const res = await this.runServiceDeleteFile({ "application": this.appGuid, "file": name })

                    if(res.status === 200) {
                        await this.getRequiredDocuments()
                    }

                    this.uploading = false

                } catch (err) {
                    await this.getRequiredDocuments()
                    this.uploading = false
                    this.toggleQuickMessage({text: 'Uh oh! something went wrong!' , color: 'error'})
                }
            },

            async getRequiredDocuments() {
                try {

                    const res = await this.getRequiredDocs(this.appGuid)

                    if(res.status === 200) {
                        this.requiredDocs = res.data.data
                        //this.validateFiles()
                    }

                } catch (err) {
                    console.log(err)
                }
            },

            getPrefix(keys, document_name) {
                return keys + '_' + document_name
            },

            filterFiles(files, prefix) {

                if(files.length > 0) {
                    return files.filter(file => file.includes(prefix))
                }

                return files
            },

            validateFiles() {
                let valid = true
                const files = this.fileStructure
                this.fileErrorMessages = {}

                if(files.length > 0 && this.submitFlag) {

                    files.forEach(file => {
                        if(file.paths.length === 0) {
                            this.fileErrorMessages[file.prefix] = file['chip-name'] + ' is required'
                            valid = false
                        }
                    })

                }

                return valid

            },
            getAckCol(acknowledgment) {
                if(acknowledgment.length === 3 && this.$vuetify.breakpoint.lgAndUp) return 4
                else if (acknowledgment.length === 2 && this.$vuetify.breakpoint.lgAndUp) return 6
                return 12
            }
        },
        watch: {
            'terms': {
                handler(val) {
                    if (val['agree-documents'] === false) {
                        val['agree-documents'] = ''
                    }
                    if (val['agree-application'] === false) {
                        val['agree-application'] = ''
                    }
                    if (val['hybrid-declaration'] === false) {
                        val['hybrid-declaration'] = ''
                    }
                },
                deep: true
            },
            validComponentData(val){
                if(!val) {
                    this.showComponentWarning()
                }
            },
        }
    
    }
</script>

<style lang="scss">
    .v-input__slot.transparent.acknowledgment {
        height: 20px !important;
    }
</style>

