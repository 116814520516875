<template>
    <div>

        <v-menu
            bottom
            left
            v-if="user"
            offset-y
            min-width="300"
            rounded="lg"
            content-class="context-menu"
            class="user-menu px-4 mx-4">

            <template v-slot:activator="{ on, attrs }">
                <v-btn
                    dark
                    icon
                    height="40"
                    width="40"
                    class="light_gray_3"
                    v-bind="attrs"
                    v-on="on">
                    <v-icon>mdi-account</v-icon>
                </v-btn>
            </template>

            <v-list elevation="0" class="px-2">
                <v-list-item>
                    <v-list-item-content>
                        <v-list-item-title class="subtitle-1 font-weight-bold">
                            {{ user.username }}
                        </v-list-item-title>
                        <v-list-item-subtitle class="caption">
                            {{ user.email }}
                        </v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>
            </v-list>

            <v-divider></v-divider>

            <v-list
                nav
                dense
                class="px-0 py-2">
                <v-list-item-group
                    color="primary">
                    <v-list-item
                        v-for="(item, key) in menu"
                        :key="key"
                        :disabled="item.disabled"
                        :to="`/${getGroupSlug}/${item.to}`"
                        class="my-0 py-0 px-6">

                        <v-list-item-title
                            v-text="item.title"
                            class="body-2">
                        </v-list-item-title>

                    </v-list-item>
                </v-list-item-group>
            </v-list>

            <v-divider></v-divider>

            <v-list
                nav
                dense
                class="px-0 py-2">
                <v-list-item @click="logout" class="py-0 px-6" link>

                    <v-list-item-title class="body-2">
                        Logout
                    </v-list-item-title>

                </v-list-item>
            </v-list>

        </v-menu>
    </div>

</template>

<script>

    import { mapState, mapMutations, mapActions, mapGetters } from 'vuex'

    export default {
        name: 'UserMenu',
        data() {
            return {
                menu: {
                    'account-settings': { title: 'Account Settings', disabled: true, to: '/'},
                    'two-factor-authentication': { title: 'Two Factor Authentication', disabled: false, to: '2fa'},
                    'user-settings':  { title: 'User Settings', disabled: true, to: '/'},
                    'integrations':  { title: 'Integrations', disabled: true, to: 'integrations'},
                },
            }
        },
        computed: {
            ...mapState(['appConfig']),
            ...mapState('auth', ['user']),
            ...mapState('slug', ['groupSlug']),
            ...mapGetters('slug', [
                'getLoginPath',
                'getGroupSlug'
            ])
        },

        methods: {
            ...mapMutations('auth-actions', [
                'clearUserSetting'
            ]),
            ...mapActions('flows', [
                'resetFlowData'
            ]),
            async logout() {

                let token = this.$auth.getToken('local')

                // console.log(token)

                let tokenDecode = JSON.parse(Buffer.from(token.split('.')[1], 'base64').toString())

                // console.log(tokenDecode)

                if(tokenDecode && tokenDecode.hasOwnProperty('jti')){
                    this.$baseDatUrl.setHeader('Authorization', token)
                    await this.$baseDatUrl.delete(`/oauth/tokens/${tokenDecode.jti}`)
                }

                await this.$auth.logout()

                this.$router.push(`/${this.groupSlug}/login`)

                this.clearUserSetting()
                this.resetFlowData()

            },
            menuFormatter( title, value = {}){

                Object.keys(this.menu).forEach(item => {
                    if(this.menu[item].title == title) {
                        this.menu[item] = {...this.menu[item], ...value}
                    }
                });

            }
        },
        mounted(){

                // remove scenario on sidebar based on app-config
                if( this.appConfig.navigation.integrations ) {
                    this.menuFormatter('Integrations', {disabled: !this.appConfig.navigation.integrations})
                }

        },
    }
</script>

<style lang="scss" scoped>

</style>
