<template>
    <v-card class="mx-auto flex__step-incrementer" tile flat>
        <v-card-text class="px-4 px-md-6 pt-6 pt-md-6">
            <v-container pa-0 fluid>
            <v-row>
                <v-col class="pt-0 pb-0 d-flex justify-end">

                    <!-- <v-btn color="light_gray_5" class="prev-btn" large fab depressed nuxt @click="selectPrevStep" :disabled="!getPrevStep || isDisabled">
                        <v-icon color="body_text_color">mdi-arrow-left</v-icon>
                    </v-btn> -->

                    <v-btn color="secondary"
                        :min-width="btnWidth"
                        outlined large depressed nuxt
                        @click="selectPrevStep"
                        :disabled="!getPrevStep || isDisabled">
                            Back
                    </v-btn>

                    <v-spacer></v-spacer>

                    <PortalTarget name="step-incrementer-next">
                        <v-btn 
                            @click="handleNext" 
                            color="secondary" 
                            :min-width="btnWidth" 
                            large depressed nuxt                            
                            class="ml-3 next-btn" 
                            :disabled="!getNextStep || isDisabled">
                            {{ nextButtonLabel }}
                        </v-btn>
                    </PortalTarget>

                </v-col>
            </v-row>
            </v-container>
        </v-card-text>

        <ConfirmModal 
            async
            color="warning"
            title="Invalid or Missing Data"
            confirmText="Continue"
            :value="invalidDataDialog"
            :standalone="false"
            :processing="processing"
            message="There is still required data that is invalid or missing on this page. Please click continue to move to the next step, or click cancel to return to this page."
            @close="toggleInvalidDataDialog(false)"
            @confirm="confirmInvalidDataDialog" />

        <ConfirmModal 
            async
            color="warning"
            title="Confirm Client Details"
            confirmText="Continue"
            :value="confirmDetailsDialog"
            :standalone="false"
            :processing="processing"
            message="It appears that you haven't changed anything on this step for this copied loan application. Please make sure all details are up to date before you continue."
            @close="toggleConfirmDetailsDialog(false)"
            @confirm="confirmConfirmDetailsDialog" />

        <ConfirmModal 
            async
            color="warning"
            title="Incomplete Steps"
            :showCancel="false"
            confirmText="Close"
            :value="incompleteStepsDialog"
            :standalone="false"
            message="Please complete previous steps to proceed."
            @confirm="toggleIncompleteStepsDialog(false)"/>

    </v-card>
</template>

<script>

    import { mapState, mapGetters, mapActions, mapMutations } from 'vuex';
    import subscriptions from '~/components/shared/subscriptions'
    import ConfirmModal from '~/components/base/ConfirmModal2'
    import stepStates from '~/components/shared/step-states' // Consider placing this in subscriptions

    export default {
        mixins: [subscriptions, stepStates],
        components: {
            ConfirmModal,
        },
        data(){
            return {

            }
        },
        computed: {
            ...mapGetters('flows', ['getPrevStep', 'getNextStep']),

            ...mapState('dialogs', [
                    'invalidDataDialog', 
                    'confirmDetailsDialog', 
                    'incompleteStepsDialog', 
                    'processing'
            ]),
            btnWidth () {
                switch (this.$vuetify.breakpoint.name) {
                    case 'xs': return 0
                    case 'lg': 
                    case 'xl':
                    case 'md': 
                    case 'sm': return 140
                }
            },
            nextButtonLabel() {
                if(this.blockSettings?.nextButtonLabel)
                    return this.blockSettings.nextButtonLabel
                return 'Next'
            }
        },
        created() {

        },
        methods: {
            ...mapActions('flows', ['selectNextStep', 'selectPrevStep']),

            ...mapActions('dialogs', [
                    'evaluateSteps',
                    'confirmInvalidDataDialog',
                    'confirmConfirmDetailsDialog'
                ]),

            ...mapMutations('dialogs', [
                    'toggleInvalidDataDialog',
                    'toggleConfirmDetailsDialog', 
                    'toggleIncompleteStepsDialog', 
                ]),
            
            async handleNext(){

                /**
                 * This will trigger Veevalidate's form observer
                 */
                this.toggleFormFieldsObserver().then(() => {
                    this.evaluateSteps()
                })

            }
        }
    }
</script>

<style scoped lang="scss">

</style>