import { mapState, mapActions, mapGetters, mapMutations } from 'vuex'
import {store, actions} from '~/components/v1/DynamicField1/__store'

export default {  
    computed: {
        ...mapState({
            development: state => {
                return state.development
            },
        }),
        ...mapGetters('kinds', ['kinds', 'kindsUnformat']),
    },
    methods: {

        getRequiredField(data = [], key = ''){
            // Filter field

            let field = ''

            if (data.attributes[key]) field = data.attributes[key]

            return field

        },

        collectResource(data = {}){
            // collect resource
            let resource = {}

            if (data) {

                resource = {
                    id: data.id,
                    type: data.type,
                    attributes: data.attributes,
                    relationships: data.relationships
                }

            }

            return resource

        },

    }
}