
import templates from '~/components/base/RequirementChecklist1/templates.js' 

// initial state
export const  state = () => ({
   
    requirements: [],
})


// getters
export const  getters = {

    getRequirements(state){
        return state.requirements
    },
    
}

// actions
export const actions = {

}

// mutations
export const  mutations = {
    reset(state) {
        state.requirements = []
    },
    setRequirementChecklist(state, payload){

        if(!payload) return

        // console.log(payload)

        let temp = JSON.stringify(templates[payload.component])
        
        const reqObj = JSON.parse(temp)

        // console.log(reqObj)

        const sectionsArray = []

        reqObj["id"] = payload.id // applicant id
        reqObj["entity"] = payload.entity
        reqObj["sections"].forEach(section => {

            const sectionObj = {...section}

            Object.keys(section.requirements).forEach( key => {
                sectionObj.requirements[key] = {...payload.fields[key]}
            })

            sectionsArray.push(sectionObj)
        })

        reqObj.sections =  sectionsArray

        let requirementExists = state.requirements.find(req => req["id"] == payload.id)
        if(requirementExists) {

            state.requirements = state.requirements.map(req => {
                if(req["id"] == payload.id) return reqObj;
                return req
            })

        } else {
            state.requirements.push(reqObj)
        }

        // console.log(state.requirements)
    },
    setRequirementChecklistTemplateLess(state, payload){

        if(!payload) return

        // console.log('payload', payload)

        let temp = JSON.stringify(payload)

        const reqObj = JSON.parse(temp)

        // console.log(reqObj)

        reqObj["id"] = payload.id // applicant id
        reqObj["entity"] = payload.entity
        reqObj["sections"] = payload.sections

        let requirementExists = state.requirements.find(req => req["id"] == payload.id)
        if(requirementExists) {

            state.requirements = state.requirements.map(req => {
                if(req["id"] == payload.id) return reqObj;
                return req
            })

        } else {
            state.requirements.push(reqObj)
        }

        // console.log(state.requirements)
    },
}