export default async (context) => {

    // FLEX1-6841
    // redirect oldDomain to newDomain

    // const oldDomain = 'localhost:3000'
    const oldDomain = 'nodifi.cloud'
    const newDomain = 'nodificloud.com'

    try {
        if (window.location.host.includes(oldDomain)) {
            let oldRoute = window.location.href
            let newRoute = window.location.href.replace(oldDomain, newDomain)
            console.log(`Redirecting ${oldRoute} to ${newRoute}`)
            window.location = newRoute
        }
    } catch(error) {
        console.error(error);
    }


    const nodificloudDomain = 'nodifi.nodificloud.com'
    const assetfinanceDomain = 'https://portal.assetfinanceexchange.com.au/dashboard'

    try {

        if (window.location.host.includes(nodificloudDomain)) {
            window.location.replace(assetfinanceDomain)
        }

    } catch(error) {
        console.error(error);
    }


}