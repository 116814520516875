<template>
    <v-card class="mx-auto" tile outlined flat :disabled="isDisabled">

        <v-col pb-8 cols="12">
            <div class="overline mb-4">{{ block.subscriptions || 'None' }}</div>
            <div class="headline mb-1">
                Dave's TestEditor3
            </div>
            <div class="mb-1">
            To Test FLEX1-859: Component Template
            </div>
        </v-col>

        <!-- Your markup here -->

            <v-list-item three-line>
                <v-list-item-content>

                    <ValidationObserver ref="observer">
                        <form>
                            <ValidationProvider v-slot="{ errors }" name="test" rules="required" vid="test">
                                <v-text-field
                                v-model="resources.loan.attributes['lead-purpose']"
                                :error-messages="errors"
                                label="Purpose"
                                outlined
                                ></v-text-field>
                                <v-text-field
                                v-model="resources.loan.attributes['requested-required-loan-amount']"
                                :error-messages="errors"
                                label="Loan Amount"
                                outlined
                                ></v-text-field>
                            </ValidationProvider>
                        </form>
                    </ValidationObserver>

                </v-list-item-content>
            </v-list-item>
        <!-- /Your markup here -->

        <v-progress-linear indeterminate stream absolute bottom v-if="isDisabled"></v-progress-linear>
    </v-card>
</template>

<script>

    /**
     * NOTE: Before you start!
     *  
     * 1. Open components\v1\index.js
     * 2. Then register your component
     * 
     */


    /**
     * REQUIRED!
     */
    import subscriptions from '~/components/shared/subscriptions'  
    import { mapActions } from 'vuex'

    export default {
        name: 'TestEditor3',
        mixins: [subscriptions],
        components: {},
        computed: {
            getLoanId(){
                return this.appData.relationships.loan.data.id
            }
        },
        data(){
            return {
                resources: {
                    /////////////////////////////////////
                    /**
                     *  Put your resources data here. 
                     */ 
                    loan: {
                        type: "loans",
                        id: null,
                        attributes: {
                            "lead-purpose": '',
                            "requested-required-loan-amount": '',
                        }
                    }
                    ////////////////////////////////////
                }
            }
        },
        created() {

        },
        methods: {
            ...mapActions('resource-actions', [
                'getLoan',
                'updateLoan',
                'createLoan'
            ]),
            /**
             *  PULL method
             * 
             *  RECOMMENDED: If multiple requests are needed, do so asynchronously to reduce total time to response.
             */
            async pull(){

                try {

                    let res = await this.getLoan(this.getLoanId)
                    this.resources.loan = this.resourceObjHelper(res.data.data)

                } catch (error) {

                    console.log('Ooops!..', error)

                }

            },
            /**
             *  PUSH method should always return an Object. 
             *  {valid, status, data}
             * 
             *  RECOMMENDED: If multiple requests are needed, do so asynchronously to reduce total time to response.
             */
            async push(){
                /**
                 *  OPTIONAL
                 *  VeeValidate's observer holds fields status, we use this
                 *  in excludeInvalids to target fields to exclude.
                 */
                //const observer = this.$refs.observer
                
                /**
                 *  OPTIONAL
                 *  Exclude invalid fields to the request payload.
                 *  {fields} - list of resources fields
                 *  {observer} - VeeValidate's observer
                 * 
                 *  see components\shared\subscriptions.js 
                 *  for more helper functions.
                 */
                
                //const requestPayload = this.excludeInvalids({/* fields */}, observer.fields)


                /////////////////////////////////////

                /*
                * Put your logic here. 
                * When your logic completes:
                * 1. Pass POST / PATCH response(s) to the appDataHelper below.
                * 
                *   e.g: 
                * 
                    const promise1 = this.pushLoan(requestPayload)

                */

                const {resources} = this

                let payload = {
                    type: resources.loan.type,
                    id: resources.loan.id,
                    // attributes: this.nullifyInvalids(resources.attributes, observer.fields)
                    attributes: resources.loan.attributes,
                }

                let loanRes = null;
                
                if (this.getLoanId) {

                    loanRes = this.updateLoan(payload)

                } else {

                    // Include relationships when creating resource
                    //payload.relationships = resources.relationships
                    loanRes = this.createLoan(payload)
                }

                ////////////////////////////////////


                /**
                 *  REQUIRED!
                 */
                const {valid, status} = await this.validateFormData()

                /**
                 * REQUIRED!
                 */
                const data = this.appDataHelper(loanRes)


                /**
                 * REQUIRED!
                 */
                return {valid, status, data}

            },

            resourceObjHelper(responseData) {
                let local_params = {}
                //console.log(this.resources.loan.attributes)
                console.log(responseData)
                Object.keys(this.resources.loan.attributes).forEach( f => {
                    local_params[f] = responseData.attributes[f]
                })
                responseData.attributes = local_params
                return responseData
            },

            /////////////////////////////////////
            /*
            * Your methods, computed properties, etc..
            */
            ////////////////////////////////////

        }
    }
</script>

<style scoped lang="scss">

</style>
