<template>
    <v-dialog
        v-if="!isNodifiConsumer"
        v-model="showDialog"
        persistent
        width="600">
        <v-card :disabled="processing">
            <v-progress-linear color="secondary" indeterminate stream absolute top v-if="processing"></v-progress-linear>
            <v-card-text class="px-12 pt-12 body-1">
                <div class=" mb-6">To send through an asset finance referral. Please navigate to <strong>Asset Finance Referral</strong> under the ,<strong>Asset and Commercial</strong> tab in <strong>MyCRM</strong></div>
                <div class="">If writing the loan yourself, please click next.</div>
                <div class="">For anything else, please contact your BSM</div>
            </v-card-text>
            <v-card-actions class="px-9 pb-8">
                <v-spacer></v-spacer>
                <v-btn
                    color="secondary"
                    large depressed
                    min-width="140"
                    @click="handleClick">
                        Next
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>

    import {mapGetters } from 'vuex'

    export default {
        props: {
            showModal: {
                type: Boolean,
                default: false
            },
            title: {
                type: String,
                default: 'Customer Communication and Compliance'
            },
            processing: {
                type: Boolean,
                default: false
            }
        },

        data() {
            return {
                isHybrid: 0
            }
        },

        computed: {
            ...mapGetters('slug', [
                'getTenant',
                'getTenantAssister',
            ]),
            ...mapGetters('flows', [
                'getCalculation',
            ]),
            showDialog() {
                return this.showModal
            },
            isNodifiConsumer(){
                return this.getCalculation?.['application-type'].includes('Consumer') && ["nodifi", "lmgaf1"].includes(this.getTenant)
            }
        },
        mounted(){

        },
        methods: {
            handleClick() {
                this.$emit('handle-next', this.isHybrid)
            },

        },
    }
</script>

<style lang="scss" scoped>

</style>