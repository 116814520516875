var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"mx-auto",attrs:{"tile":"","flat":""}},[_c('v-card-text',{staticClass:"pa-4 pt-0 pa-md-10 pt-md-0"},[_c('ValidationObserver',{ref:"observer",attrs:{"tag":"form"}},[_c('div',{staticClass:"flex-form"},[_c('div',{staticClass:"flex-field"},[_c('ValidationProvider',{attrs:{"name":_vm.labels.repaymentAffordability,"rules":_vm.getRules('repaymentAffordability'),"vid":'repaymentAffordability'},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"items":['YES', 'NO'],"error-messages":errors,"color":"secondary","label":_vm.labels.repaymentAffordability},model:{value:(_vm.resources.loan.attributes.lender.macquarie.repaymentAffordability),callback:function ($$v) {_vm.$set(_vm.resources.loan.attributes.lender.macquarie, "repaymentAffordability", $$v)},expression:"resources.loan.attributes.lender.macquarie.repaymentAffordability"}})]}}])})],1),_vm._v(" "),_c('div',{staticClass:"flex-field"},[_c('ValidationProvider',{attrs:{"name":_vm.labels.creditOption,"rules":_vm.getRules('creditOption'),"vid":'creditOption'},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"items":_vm.options.creditOption,"error-messages":errors,"color":"secondary","label":_vm.labels.creditOption},model:{value:(_vm.resources.loan.attributes.lender.macquarie.creditOption),callback:function ($$v) {_vm.$set(_vm.resources.loan.attributes.lender.macquarie, "creditOption", $$v)},expression:"resources.loan.attributes.lender.macquarie.creditOption"}})]}}])})],1),_vm._v(" "),_c('div',{staticClass:"flex-field"},[(_vm.isIncluded('loanStatement'))?_c('ValidationProvider',{attrs:{"name":_vm.labels.loanStatement,"rules":_vm.getRules('loanStatement'),"vid":'loanStatement'},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"items":['YES', 'NO'],"error-messages":errors,"color":"secondary","label":_vm.labels.loanStatement},model:{value:(_vm.resources.loan.attributes.lender.macquarie.loanStatement),callback:function ($$v) {_vm.$set(_vm.resources.loan.attributes.lender.macquarie, "loanStatement", $$v)},expression:"resources.loan.attributes.lender.macquarie.loanStatement"}})]}}],null,false,3626695513)}):_vm._e()],1),_vm._v(" "),_c('div',{staticClass:"flex-field"},[(_vm.isIncluded('financialReference'))?_c('ValidationProvider',{attrs:{"name":_vm.labels.financialReference,"rules":_vm.getRules('financialReference'),"vid":'financialReference'},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"items":_vm.options.financialReference,"error-messages":errors,"color":"secondary","label":_vm.labels.financialReference},model:{value:(_vm.resources.loan.attributes.lender.macquarie.financialReference),callback:function ($$v) {_vm.$set(_vm.resources.loan.attributes.lender.macquarie, "financialReference", $$v)},expression:"resources.loan.attributes.lender.macquarie.financialReference"}})]}}],null,false,3023886113)}):_vm._e()],1)]),_vm._v(" "),_c('div',{staticClass:"flex-form"},[_c('div',{staticClass:"flex-field"},[(_vm.isIncluded('accountReference'))?_c('ValidationProvider',{attrs:{"name":_vm.labels.accountReference,"rules":_vm.getRules('accountReference'),"tag":"div","vid":'accountReference'},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-textarea',{attrs:{"auto-grow":"","label":_vm.labels.accountReference,"rows":"10","row-height":"10","error-messages":errors},model:{value:(_vm.resources.loan.attributes.lender.macquarie.accountReference),callback:function ($$v) {_vm.$set(_vm.resources.loan.attributes.lender.macquarie, "accountReference", $$v)},expression:"resources.loan.attributes.lender.macquarie.accountReference"}})]}}],null,false,2183335206)}):_vm._e()],1),_vm._v(" "),_c('div',{staticClass:"flex-field"})])])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }