<template>
    <v-card class="mx-auto" tile flat>
        <v-card-text class="pa-4 pt-0 pa-md-10 pt-md-0">
            <ValidationObserver ref="observer" tag="form" v-if="true">
                   <div class="flex-form">

                        <div class="flex-field">
                            <ValidationProvider
                                v-slot="{ errors }"
                                :name="labels['household-reason']"
                                :rules="getRules('household-reason')"
                                :vid="'household-reason'">

                                <v-select
                                    v-model="resources.expenses.attributes['household-reason']"
                                    :items="options['household-reason']"
                                    :error-messages="errors"
                                    color="secondary"
                                    :label="labels['household-reason']"
                                ></v-select>
                            </ValidationProvider>
                        </div>
                        <div class="flex-field"></div>
                        <div class="flex-field"></div>
                        <div class="flex-field"></div>
                    </div>
            </ValidationObserver>


            <v-alert v-else dense outlined type="info" >
                <div>Not Applicable</div>
            </v-alert>

        </v-card-text>
    </v-card>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex'
import helpers from '~/plugins/helpers'
import subscriptions from '~/components/shared/subscriptions'
import ButtonGroup1 from '~/components/base/ButtonGroup1'
import NodifiMoney from '~/components/base/NodifiMoney'

export default {
    components: { ButtonGroup1 , NodifiMoney},
    mixins: [subscriptions, helpers],
    data() {
        return {
            resources: {
                expenses: {
                    type: 'expenses',
                    id: null,
                    attributes: {
                        'household-reason': ''
                        // 'body-type': '',
                        // 'sale-type': '',
                    }
                }
            },
            vendorUnknown: false,
            options: {
                'household-reason': [
                    {text: 'Living With Family', value: 'LIVING_WITH_FAMILY'},
                    {text: 'Senior Citizen', value: 'SENIOR_CITIZEN'},
                    {text: 'Pre Tax Amount', value: 'PRE_TAX_AMOUNT'},
                    {text: 'Rural Residence', value: 'RURAL_RESIDENCE'},
                    {text: 'Other', value: 'OTHER'},
                ],
            },
            labels: {
                'household-reason': 'Household Reason',
            },
            validation_rules: {
                attributes: {
                    'household-reason': '',
                }
            },

            calculation: {}
        }
    },
    computed: {
        ...mapState({
            appId: state => state.flows.appId,
            loanData: state => state.flows.loanData,
            kindsUnformat: state => state.kinds.kindsUnformat,
        }),
        ...mapGetters('flows', [
            'getCalculation',
        ]),
        getLoanId(){
            return this.appData.relationships.loan.data.id
        },
        hasPurchases(){
            return this.appData.relationships.purchases.data.length
        },
        isConsumer(){
            return this.getCalculation?.['application-type'].includes('Consumer') 
        }
    },
    methods: {
        ...mapActions('resource-actions', [
            'getPurchases',
            'getApplicationLoan',
            'updateLoan',
            'updatePurchases',
            'getExpenses',
            'updateExpense',
        ]),
        async pull() {
            const expensesRes = await this.getExpenses()
            if(expensesRes.status == 200) {
                const livingExpense = expensesRes.data.data.find(expense => {
                    return expense.relationships.kind.data.id == '11-16'
                })

                this.resources.expenses.id = livingExpense.id
                this.resources.expenses.attributes['household-reason'] = livingExpense.attributes.lender.macquarie.householdReason
            }

            if (this.isConsumer) {
                this.validation_rules.attributes['household_reason'] = 'required'
            }
        },

        async push() {

            const observer = this.$refs.observer
            const {valid, status} = await this.validateFormData()

            if(valid) {
                
                

                await Promise.all([this.updateExpense({
                    ...this.resources.expenses,
                    attributes: {
                        lender: {
                            macquarie: {
                                householdReason: this.resources.expenses.attributes['household-reason']
                            }
                        }
                    }
                })]) 

                return {valid, status, data: {}}

            } else {

                return {valid, status, data: {}}
            }

        },

    },

}
</script>

<style>
</style>

