import Vue from "vue";

export const store = Vue.observable({
  steps: {
    personal: {
      title: "Applicant Information",
      subtitle: "",
      "form-ref": "PersonalInformationForm",
    },
    employment: {
      title: "Employment Information",
      subtitle: "",
      "form-ref": "EmploymentInformationForm",
    },
    employment2: {
      title: "Employment Information",
      subtitle: "",
      "form-ref": "EmploymentInformationForm2",
    },
    occupancy: {
      title: "Occupancy Information",
      subtitle: "",
      "form-ref": "OccupancyInformationForm",
    },
  },

  carousel: 0,
});

export const actions = {
  setCarousel(payload) {
    store.carousel = payload;
  },

  resetStore() {
    store.carousel = 0;
  },

  setCarouselNext() {
    store.carousel++;
  },
  setCarouselBack() {
    store.carousel--;
  },
  addSteps(payload){
    store.steps = {
      ...store.steps,
      payload
    }
  },
};
