<template>
    <HideWhenEmbedded>
        <div class="d-flex flex-column flex-grow-1">
            <v-spacer></v-spacer>

            <!-- save and exit button -->
            <div v-if="getLeftDrawerVariant" class="pa-2 pb-4">
                <v-btn
                color="secondary"
                fab
                small
                :disabled="isDisabled"
                depressed
                :loading="saving"
                @click="saveAndExitApplication">
                    <v-icon  size="18">mdi-content-save-all</v-icon>
                </v-btn>
            </div>
            <div v-else class="pa-4 pr-8">
                <v-btn
                color="secondary"
                large
                :disabled="isDisabled"
                depressed
                width="100%"
                :loading="saving"
                @click="saveAndExitApplication">
                    {{ blockSettings.label || 'Save' }}
                </v-btn>
            </div>
        </div>
    </HideWhenEmbedded>
</template>

<script>
    import HideWhenEmbedded from '~/components/base/HideWhenEmbedded.vue';
    import { mapState, mapGetters, mapActions, mapMutations } from 'vuex';
    import subscriptions from '~/components/shared/subscriptions'
    import stepStates from '~/components/shared/step-states' // Consider placing this in subscriptions

    export default {
        components: {
          HideWhenEmbedded
        },
        mixins: [
          subscriptions,
          stepStates
        ],
        data(){
            return {
              list: 0,
              saving: false
            }
        },
        computed: {
            ...mapGetters('flows', ['getSteps', 'getActiveStep']),
            ...mapGetters('slug', ['getGroupSlug']),
            ...mapGetters({
                getSpacesObj: 'flows/getSpacesObj',
            }),
            ...mapGetters({
              getLeftDrawerVariant: 'setup/getLeftDrawerVariant' ,
            }),
            ...mapState({
                activeStep: state => state.flows.activeStep,
                stepableMode: state => state.subscriptions.stepableMode,
            }),
            isDisabled(){
                return this.stepableMode == 'disabled'
            },
            selected(){

                this.list = parseInt(this.activeStep) - 1

                return this.list
            },
        },
        mounted() {

        },
        methods: {
            ...mapMutations('auth-actions', [
                'clearUserSetting'
            ]),
            ...mapActions('flows', ['selectStep', 'saveAndExit']),
            async saveAndExitApplication () {

              this.saving = true

              await this.saveAndExit()

              // if b2c
              if (this.blockSettings?.onClick == 'b2cSaveAndExit') {

                this.$router.push(`/${this.getGroupSlug}/b2c/home`)

                // await this.$auth.logout()

                // await this.$router.push({
                //     path: '/login',
                //     query: {
                //         app: this.appGuid,
                //     }
                // })

                // await this.clearUserSetting()

              } else {

                  this.$router.push(`/${this.getGroupSlug}/home`)
              }

              this.saving = false

            },
        }
    }
</script>

<style scoped lang="scss">


</style>
