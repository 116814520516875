export default {
    "ApplicantEditor1": {   
        "id": null,
        "entity": null,
        "sections": [
            {
                title: "Personal Details",
                requirements: {
                    "first-name": null,
                    "last-name": null,
                    "mobile-phone": null,
                    "email": null,
                    "gender": null,
                    "marital-status": null,
                }
            }, 
            {
                title: "Citizenship Information",
                requirements: {
                    "primary-country-of-citizenship": null,
                }
            }, 
            {
                title: "ID Information",
                requirements: {
                    "drivers-licence-number": null,
                    "drivers-licence-expiry": null,
                    "drivers-licence-state": null,
                    "drivers-licence-card-number": null,
                    "medicare-number": null,
                    "medicare-position-on-card": null,
                    "medicare-expiry": null,
                    "medicare-colour-of-card": null,
                    "passport-number": null,
                    "passport-country-of-issue": null,
                    "passport-issue-date": null,
                    "passport-expiry": null,
                }
            }, 
            {
                title: "Credit Information",
                requirements: {
                    "credit-status": null,
                    "comprehensive-credit-score": null,
                    "previous-bankrupt": null,
                    "bankruptcy-status": null,
                    "bankruptcy-declared-date": null,
                    "bankruptcy-discharge-date": null,
                }
            }, 
        ]
    }
}

