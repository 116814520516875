const middleware = {}

middleware['app-config'] = require('../middleware/app-config.js')
middleware['app-config'] = middleware['app-config'].default || middleware['app-config']

middleware['app-owner-settings'] = require('../middleware/app-owner-settings.js')
middleware['app-owner-settings'] = middleware['app-owner-settings'].default || middleware['app-owner-settings']

middleware['domain'] = require('../middleware/domain.js')
middleware['domain'] = middleware['domain'].default || middleware['domain']

middleware['embed'] = require('../middleware/embed.js')
middleware['embed'] = middleware['embed'].default || middleware['embed']

middleware['flex-config'] = require('../middleware/flex-config.js')
middleware['flex-config'] = middleware['flex-config'].default || middleware['flex-config']

middleware['flow-config'] = require('../middleware/flow-config.js')
middleware['flow-config'] = middleware['flow-config'].default || middleware['flow-config']

middleware['groups'] = require('../middleware/groups.js')
middleware['groups'] = middleware['groups'].default || middleware['groups']

middleware['guest'] = require('../middleware/guest.js')
middleware['guest'] = middleware['guest'].default || middleware['guest']

middleware['kinds'] = require('../middleware/kinds.js')
middleware['kinds'] = middleware['kinds'].default || middleware['kinds']

middleware['maintenance'] = require('../middleware/maintenance.js')
middleware['maintenance'] = middleware['maintenance'].default || middleware['maintenance']

middleware['route-guard'] = require('../middleware/route-guard.js')
middleware['route-guard'] = middleware['route-guard'].default || middleware['route-guard']

middleware['subscriptions'] = require('../middleware/subscriptions.js')
middleware['subscriptions'] = middleware['subscriptions'].default || middleware['subscriptions']

middleware['themes'] = require('../middleware/themes.js')
middleware['themes'] = middleware['themes'].default || middleware['themes']

middleware['token'] = require('../middleware/token.js')
middleware['token'] = middleware['token'].default || middleware['token']

middleware['user-settings'] = require('../middleware/user-settings.js')
middleware['user-settings'] = middleware['user-settings'].default || middleware['user-settings']

middleware['user'] = require('../middleware/user.js')
middleware['user'] = middleware['user'].default || middleware['user']

export default middleware
