
// initial state
export const  state = () => ({
    
    flowStatuses: [],
    flowStatus: {}, // user's last current-step
    stepStatuses: [],
    allStepStatuses: [],

})

// getters
export const  getters = {
    getStepStatuses: (state, getters, rootState) => {
        return state.stepStatuses
    },
    getActiveStepStatus: (state, getters, rootState) => {

        const {stepStatuses} = state
        const {activeStep} = rootState.flows

        return stepStatuses[parseInt(activeStep) - 1]

    },
    getCurrentFlowStatus: (state) => {
        return state.flowStatus
    }
}

// actions
export const  actions = {

    // Step Statuses

    createStepStatus({state, commit, getters, rootState}, payload){
            
        const {appId, flowId, flowStatusId, stepId} = payload // list of ids

        const stepStatusPayload = {
            "type": "step-statuses",
            "attributes": {
                "value": "pristine"
            },
            "relationships": {
                "step": {
                    "data": {
                            "type": "steps",
                            "id": stepId, 
                    }
                },
                "flow-status": {
                    "data": {
                        "type": "flow-statuses",
                        "id": flowStatusId
                    }
                }
            }
        }

        return this.$api.post(`/step-statuses`, {"data": stepStatusPayload}).then(res => res.data.data)
       
    },
    async updateStepStatus({state, commit, getters, rootState}, payload){
        
        const {stepStatuses} = state
        const {activeStep, steps} = rootState.flows

        const step = steps[parseInt(activeStep) - 1];

        const stepStatus = stepStatuses.find( status => {
            return status.relationships.step.data.id == step.id
        })

        const stepStatusPayload = {
            "id": stepStatus.id, 
            "type": "step-statuses",
            "attributes": {
                "value": payload
            }
        }

        return this.$api.patch(`/step-statuses/${stepStatus.id}`, {"data": stepStatusPayload}).then(res => res.data.data)
   
    },
    fetchStepStatus({state, commit, getters, rootState}, payload){

        return this.$api.get(`/step-statuses/${payload}`).then(res => res.data.data)

    },
    async fetchStepStatuses({state, commit, getters, rootState}){
        return this.$api.get(`/flow-statuses/${state.flowStatus.id}/step-statuses?page[size]=100`).then(res => res.data.data)
    },
    async evalStepStatuses({state, commit, getters, rootState}){

        const payload ={
            "type": "flow-statuses",
            "id": state.flowStatus.id
        }

        return this.$api.patch(`/flow-statuses/${state.flowStatus.id}?run-evaluate-states`, {"data": payload}).then(res => res.data.data)
    },
    deleteStepStatus({state, commit, getters, rootState}, payload){
        
        return this.$api.delete(`/step-statuses/${payload}`).then(res => res.data.data)
   
    },

    // Flow Statuses

    createFlowStatus({state, commit, getters, rootState}, payload){
        
        const {appId, flowId} = payload // list of ids

        let flowStatusPayload = {
            "type": "flow-statuses",
            "attributes": {
                "current-step": "1"
            },
            "relationships": {
                "application": {
                    "data": {
                        "type": "applications",
                        "id": appId
                    }
                },
                "flow": {
                    "data": {
                        "type": "flows",
                        "id": flowId
                    }
                }
            }
        }

        return this.$api.post(`/flow-statuses`, {"data": flowStatusPayload}).then(res => res.data.data)
   
    },
    updateFlowStatus({state, commit, getters, rootState}, payload){
        
        const {flowStatus} = state

        const flowStatusPayload = {
            "id": flowStatus.id, 
            "type": "flow-statuses",
            "attributes": {
                "current-step": `${payload}`
            }
        }

        return this.$api.patch(`/flow-statuses/${flowStatus.id}`, {"data": flowStatusPayload}).then(res => res.data.data)
   
    },
    // Well refactor this in the future
    updateFlowStatusOL({state, commit, getters, rootState}, payload){
        
        const {flowStatus} = state

        const flowStatusPayload = {
            "id": flowStatus.id, 
            "type": "flow-statuses",
            "attributes": {
                "current-step": `${payload.step}`
            }
        }

        let params = payload.params ? `?${payload.params.key}=${payload.params.value}` : ''

        return this.$api.patch(`/flow-statuses/${flowStatus.id}${params}`, {"data": flowStatusPayload}).then(res => res.data.data)
   
    },
    fetchFlowStatus({state, commit, getters, rootState}, payload){
        
        return this.$api.get(`/flow-statuses/${payload}`).then(res => res.data.data)
   
    },
    deleteFlowStatus({state, commit, getters, rootState}, payload){
        
        return this.$api.delete(`/flow-statuses/${payload}`).then(res => res.data.data)
   
    },

    completeFlowStatus({commit}, body = {}) {

        let res = this.$api.patch(`/flow-statuses/${body.id}`, {
            data: body
        })

        return res

    }
}

// mutations
export const  mutations = {

    // Flow Status
    setFlowStatuses (state, payload) {
        state.flowStatuses = payload
    },
    setAllStepStatuses (state, payload) {
        state.allStepStatuses = payload
    },
    setFlowStatus (state, payload) {
        state.flowStatus = payload
    },
    updateFlowStatus (state, payload) {

        if (state.flowStatus?.attributes?.["current-step"]) {
            state.flowStatus.attributes["current-step"] = payload?.attributes["current-step"]
        }

    },

    // Step Status
    setStepStatuses (state, payload) {
        state.stepStatuses = payload
    },
    createStepStatus (state, payload) {
        state.stepStatuses = [
            ...state.stepStatuses,
            payload
        ]
    },
    updateStepStatus (state, payload) {
        
        state.stepStatuses = state.stepStatuses.map( step => {
            
            if(step.id == payload.id) {
                step.attributes.value = payload.attributes.value
            }

            return step
        })

    },
    deleteStepStatus (state, payload) {
        state.stepStatuses = state.stepStatuses.filter( step => {
            
            if(step.id == payload.id) {
                return false
            }

            return true
        })

    },

}