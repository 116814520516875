/**
 * 
 * Define linked FOs 
 * NOTE: might change fo-required-logic.js using this logic...
 * 
 */
export default {
    incomes: {

    },
    assets: {

    },
    liabilities: {

        // Line of Credit - Secured
        "13-19": {part: "expenses", id: '11-29'},
        // Mortgage - Investment
        "13-20": {part: "expenses", id: '11-30'},
        // Mortgage - Owner Occupied
        "13-21": {part: "expenses", id: '11-6'},
        // Car Loan
        "13-9": {part: "expenses", id: '11-18'},
        // Novated Lease
        "13-10": {part: "expenses", id: '11-20'},
        // Buy Now Pay Later
        "13-26": {part: "expenses", id: '11-35'},
        
    },
    expenses: {
        // Line of Credit - Secured
        "11-29": {part: "liabilities", id: '13-19'},
        // Mortgage - Investment
        "11-30": {part: "liabilities", id: '13-20'},
        // Mortgage - Owner Occupied
        "11-6": {part: "liabilities", id: '13-21'},
        // Car Loan
        "11-18": {part: "liabilities", id: '13-9'},
        // Novated Lease
        "11-20": {part: "liabilities", id: '13-10'},
        // Buy Now Pay Later
        "11-35": {part: "liabilities", id: '13-26'},
    }

}