<template>
    <v-card class="mx-auto" tile flat>
        <v-card-text class="px-0 pb-4 pt-0 px-md-0 pb-md-10 pt-md-0">
            <v-data-table
                v-if="selectedPurchase"
                hide-default-footer
                :items-per-page="-1"
                :items="purchases"
                :headers="[
                {
                    text: 'Condition',
                    value: 'attributes.condition',
                },
                {
                    text: 'Description',
                    value: 'attributes.condition',
                },
                {
                    text: 'Price',
                    value: 'attributes.purchase-price',
                },
                {
                    text: 'Action',
                    value: 'action'
                }
                ]"
                class="px-8 v-data-table-2"
            >
            <template #body="{items}">
                <tbody>
                <tr v-for="(item, index) in items" :key="index" :class="{'light_gray_1': selectedPurchase.id == item.id}">
                    <td>
                    {{ item.attributes['condition'] ||'-' }}
                    </td>
                    <td>
                    {{ getPurchaseDescription(item) ||'-' }}
                    </td>
                    <td>
                    {{ item.attributes['purchase-price'] ||'-' }}
                    </td>
                    <td>
                    <v-chip v-if="selectedPurchase.id != item.id" depressed small color="secondary" @click="handleEdit(item)">
                        Edit
                    </v-chip>
                    <v-chip v-else depressed small>
                        Selected
                    </v-chip>
                    </td>
                </tr>
                </tbody>
            </template>
            </v-data-table>
    </v-card-text>

    <v-card-text class="px-0 pb-4 pt-0 px-md-0 pb-md-10 pt-md-0">
            <div class="d-md-flex" style="width: 100%">

                <v-sheet color="light_gray_1" class="py-12 px-12" style="min-width: 45%;" outlined elevation="0">
                    <v-img
                        max-width="120"
                        class="flex-brand"
                        :src="require(`~/assets/images/nodifi.svg`)"
                        position="center left"
                        contain>
                    </v-img>
                    <div class="mt-2 font-size-20 light_gray_2--text">Data</div>
                    <v-list color="transparent">
                        <v-list-item two-line class="px-0">
                            <v-list-item-content>
                                <v-list-item-title class="font-weight-medium">Year of Manufacture</v-list-item-title>
                                <v-list-item-subtitle>{{ selectedPurchase ? selectedPurchase.attributes['year-of-manufacture'] : '&mdash;' }}</v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                        <v-list-item two-line class="px-0">
                            <v-list-item-content>
                                <v-list-item-title class="font-weight-medium">Make</v-list-item-title>
                                <v-list-item-subtitle>{{ selectedPurchase ? selectedPurchase.attributes['make'] : '&mdash;' }}</v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                        <v-list-item two-line class="px-0">
                            <v-list-item-content>
                                <v-list-item-title class="font-weight-medium">Model</v-list-item-title>
                                <v-list-item-subtitle>{{ selectedPurchase ? selectedPurchase.attributes['model'] : '&mdash;' }}</v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list>
                </v-sheet>

                <v-sheet class="py-12 px-12">
                    <v-img
                        max-width="300"
                        class="flex-brand"
                        :src="require(`~/assets/images/logo/cropped/lender-logo-financeone.png`)"
                        position="center left"
                        contain>
                    </v-img>
                    <div class="mt-2 mb-6 font-size-20 light_gray_2--text">Requirements</div>

                        <ValidationObserver ref="observer" tag="form" v-if="selectedPurchase">
                                <div>
                                    <ValidationProvider
                                        v-if="isWatercraft"
                                        v-slot="{ errors }"
                                        :name="labels['watercraft-type']"
                                        :rules="getRules('watercraft-type')"
                                        :vid="'watercraft-type'">
                                        <v-select
                                            v-model="selectedPurchase.attributes.lender.financeOne.watercraftType"
                                            :items="options['watercraft-type']"
                                            item-text="key"
                                            item-value="value"
                                            :error-messages="errors"
                                            color="secondary"
                                            :label="labels['watercraft-type']"
                                        ></v-select>
                                    </ValidationProvider>
                                    <ValidationProvider
                                        v-else
                                        v-slot="{ errors }"
                                        :name="labels['body-type']"
                                        :rules="getRules('body-type')"
                                        :vid="'body-type'">
                                        <v-select
                                            v-model="selectedPurchase.attributes['body-type']"
                                            :items="options['body-type']"
                                            :error-messages="errors"
                                            item-text="key"
                                            item-value="value"
                                            color="secondary"
                                            :label="labels['body-type']"
                                        ></v-select>
                                    </ValidationProvider>
                                </div>
                        </ValidationObserver>

                        <v-alert v-else dense outlined type="info" >
                            <div>Not Applicable</div>
                        </v-alert>
                </v-sheet>

            </div>
    </v-card-text>
    </v-card>
</template>

<script>
import { mapActions, mapState, mapMutations } from 'vuex'
import helpers from '~/plugins/helpers'
import subscriptions from '~/components/shared/subscriptions'
import ButtonGroup1 from '~/components/base/ButtonGroup1'
import NodifiMoney from '~/components/base/NodifiMoney'

export default {
    components: { ButtonGroup1 , NodifiMoney},
    mixins: [subscriptions, helpers],
    data() {
        return {
            selectedPurchase: null,
            watercraftType: null,
            purchases: [],
            options: {
                'body-type': [
                    {value:'wagon', key:'Wagon'},
                    {value:'van', key:'Van'},
                    {value:'ute', key:'Ute'},
                    {value:'suv', key:'Suv'},
                    {value:'sedan', key:'Sedan'},
                    {value:'hatch', key:'Hatch'},
                    {value:'coupe', key:'Coupe'},
                    {value:'cab_chassis', key:'Cab Chassis'},
                    {value:'convertible', key:'Convertible'},
                    {value:'people_mover', key:'People Mover'},
                    {value:'light_truck', key:'Light Truck'},
                    {value:'bus', key:'Bus'},
                ],
                'watercraft-type': [
                    { value: 'personal_watercraft', key: 'Personal Watercraft'},
                    { value: 'curiser', key: 'Curiser'},
                    { value: 'leisure', key: 'Leisure'},
                    { value: 'ski_boat', key: 'Ski Boat'},
                    { value: 'fishing', key: 'Fishing '},
                    { value: 'sail', key: 'Sail'}
                ]
            },
            labels: {
                'body-type': 'Body Type',
                'watercraft-type': 'Watercraft Type',
            },
            validation_rules: {
                attributes: {
                    'body-type': 'required',
                    'watercraft-type': 'required',
                }
            },
        }
    },
    computed: {
        ...mapState({
            appId: state => state.flows.appId,
            loanData: state => state.flows.loanData,
            kindsUnformat: state => state.kinds.kindsUnformat,
        }),
        getLoanId(){
            return this.appData.relationships.loan.data.id
        },
        hasPurchases(){
            return this.appData.relationships.purchases.data.length
        },
        isMotorVehicle() {
            const {selectedPurchase} = this
            if(!selectedPurchase) return false
            return ['9-2', '9-15'].includes(selectedPurchase?.relationships?.kind?.data?.id)
        },
        isWatercraft() {
            const {selectedPurchase} = this
            if(!selectedPurchase) return false
            return ['9-4', '9-5', '9-9', '9-11'].includes(selectedPurchase?.relationships?.kind?.data?.id)
        },
        assetTypes() {
            let purchasesKinds = this.kinds.find( kind => {
                return kind.name == 'Purchase'
            })
            return purchasesKinds ? purchasesKinds.children : []
        },
    },
    methods: {
        ...mapActions('resource-actions', [
            'getPurchases',
            'updatePurchases',
        ]),

        async pull() {

            let res = await this.getPurchases()

            if(res.status == 200) {
                this.purchases =  res.data.data
                this.selectedPurchase = res.data.data.length ? res.data.data[0] : null
            }
    
        },

        async push() {

            const observer = this.$refs.observer
            const {valid, status} = await this.validateFormData()

            if(valid) {
                
                const {purchases} =  this

                let purchasesReqs = []

                purchases.forEach( purchase => {

                    let payload = {
                        type: purchase.type,
                        id: purchase.id,
                        attributes: purchase.attributes
                    }

                    let req = this.updatePurchases(payload)

                    purchasesReqs.push(req)
                })

                await Promise.all(purchasesReqs) 

                return {valid, status, data: {}}

            } else {

                return {valid, status, data: {}}
            }

        },
        handleEdit(purchase){
            this.selectedPurchase = purchase
        },
        getPurchaseDescription(purchase) {
                let fkind = purchase?.relationships?.kind.data?.id ? purchase.relationships.kind.data : 0
                let filteredKind = null
                let filteredParent = null

                this.assetTypes.forEach( kind => {
                    if ( kind.children?.length ) {
                        return kind.children.find( child_kind => {
                            if ( fkind.id === child_kind.id) {
                                filteredParent = kind
                                filteredKind = child_kind
                            }
                        })
                    }
                    else {
                        if ( kind.id === fkind.id )
                            filteredKind = kind
                    }
                })

                if ( filteredKind?.name === 'Motor Vehicle') {
                    let desc = `${purchase.attributes['year-of-manufacture']} ${this.$options.filters.formalAndCapitalize(purchase.attributes['make'])} ${purchase.attributes['model']}`
                    return desc.indexOf('null') >= 0 ? `Motor Vehicle (${purchase.attributes['year-of-manufacture'] ? purchase.attributes['year-of-manufacture'] : '--'})` : desc
                }
                
                if ( filteredParent?.name === filteredKind?.name)
                    return filteredKind?.name
                return `${filteredParent?.name} - ${filteredKind?.name}`
        },
    },
    watch:{
 
    }

}
</script>

<style>
</style>

