<template>

    <v-menu
        v-model="menu"
        :close-on-content-click="false"
        offset-overflow
        offset-y
        :position-x="120"
        max-width="290">

        <template v-slot:activator="{ on, attrs }">
            <v-text-field
                ref="testField"
                type="text"
                inputmode="decimal"
                v-model="dateFE" 
                :label="label"
                autocomplete="off"
                placeholder="dd-mm-yyyy"
                color="secondary"
                :error-messages="errors"
                :disabled="disabled"
                @focus="showPresetVal"
                @blur="handleBlur"
                @keydown.tab="handleTab"
                @click:clear="handleClear">

                <v-icon
                    v-bind="attrs"
                    v-on="on"
                    :disabled="disabled"
                    slot="append"
                    color="secondary"
                    >
                    mdi-calendar
                </v-icon>
            </v-text-field>
        </template>

        <v-date-picker
            v-model="date"
            color="secondary"
            no-title
            :min="min"
            :max="max"
            header-color="light_gray_1"
            @input="menu = false"
            @change="handleChange">
        </v-date-picker>

    </v-menu>

</template>

<script>

    export default {
        name: 'FlexDatePicker3',
        data(){
            return {
                menu: false,
                dateFE: '',
                date: null
            }
        },
        model: {
            prop: 'modelValue',
            event: 'change'
        },
        props: {
            modelValue: {
                type: String,
                default: ''
            },
            name: {
                type: String,
                default: ''
            },
            min: {
                type: String,
                default: undefined
            },
            max: {
                type: String,
                default: undefined
            },
            disabled: {
                type: Boolean,
                default: false
            },
            label: {
                type: String,
                default: ''
            },
            errors: {
                type: Array,
                default: ()=>[]
            },
            noPreset: {
                type: Boolean,
                default: false
            }
        },
        computed: {
            
        },
        
        watch: {
            modelValue(val) {
                /**
                 * Vuetify wont accept our format of date, only ISO format
                 */

                if (val) {

                    this.date = this.$options.filters.dateFormat(this.modelValue)
                    this.dateFE = this.dateFormmatFE(val)

                    return
                }

                this.date = ''
                this.dateFE = ''

            }
        },
        mounted(){

            this.dateFE = this.dateFormmatFE(this.modelValue)
            this.date = this.$options.filters.dateFormat(this.modelValue)

        },
        methods: {
            getDefaultDate(){
                
                let defaultDate =  new Date().toISOString().substr(0, 10) // Current Date

                const [defaultYear, defaultMonth, defaultDay] = defaultDate.split('-')

                defaultDate = `${defaultYear}-${defaultMonth.padStart(2, '0')}-${defaultDay.padStart(2, '0')}`


                if(this.min){
                    return this.$options.filters.dateFormat(this.min)
                }

                if(this.max){
                    return this.$options.filters.dateFormat(this.max)
                }
                
                return defaultDate

            },
            parseDate (date) {


                let defaultDate =  this.getDefaultDate()

                // If invalid Date, check for min, max for default value...
                if (!date) { 
                    return '' 
                }

                try {

                    let newDate = date.trim()

                    /**
                     * Format Date into yyyy-mm-dd
                     */

                    if(!newDate.includes('/') && !newDate.includes('-')){

                        newDate =  `${newDate.slice(4, 8)}-${newDate.slice(2, 4)}-${newDate.slice(0,2)}`

                    } else if( newDate.includes('/') ){

                        const [day, month, year] = newDate.split('/')

                        if(year.length != 4){
                            return defaultDate
                        }

                        newDate = `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}` 

                    }  else if( newDate.includes('-') ){

                        const [day, month, year] = newDate.split('-')

                        if(year.length != 4){
                            return defaultDate
                        }

                        newDate = `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}` 
                    } 

                    let dbSafeFormat = this.$options.filters.dateFormat(newDate)

                    if(dbSafeFormat == 'Invalid date'){
                        return defaultDate
                    }

                    return dbSafeFormat
                    
                } catch (error) {

                    return defaultDate

                }
    
            },
            showPresetVal(e) {
                if(this.noPreset == true) {
                    this.$emit('show-preset-value', e);
                }
            },
            handleBlur(e){
                
                let fieldValue = e.target.value
                let formatted = this.parseDate(fieldValue)

                this.dateFE = this.dateFormmatFE(formatted)

                this.$emit('change', formatted)

                
            },
            handleTab(){
                this.menu = false
            },
            handleChange(val){

                this.menu = false
                this.$emit('change', this.$options.filters.dateFormat(val))
                
            },
            handleClear(){
                this.date = null
                this.$emit('change', null)
            },
            dateFormmatFE(date){
                return this.$options.filters.dateFormatFE(date)
            }
        }
        
    }
</script>

<style scoped lang="scss">
    

</style>

