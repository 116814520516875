<template>
    <div class="income-sharing">
        <!-- 
            v-if="isSharable && !isPreviousIncome"
            Do not show if there is only one entity in the app
         -->
        <v-btn 
            color="secondary" 
            :min-width="140" depressed nuxt large 
            @click="handleOpen">
            {{label}}
        </v-btn>

        <v-dialog
            v-model="dialog"
            persistent
            max-width="1124"
        >
            <v-card tile flat class="rounded-lg income-sharing-editor" :disabled="disabled">

                <v-progress-linear color="secondary" indeterminate stream absolute top v-if="disabled"></v-progress-linear>

                <v-card-title class="align-start">
                    <v-spacer/>
                    <v-btn icon @click="dialog = false">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-card-title>

                <v-card tile flat class="mx-auto my-0" :max-width="1000">
                    <v-card-title class="align-start mb-6">
                        <div>
                            <div class="pl-0 pt-0 pb-2 v-card__title text-no-wrap">Rental Income Sharing </div>
                            <div>
                                <div class="body-1 font-weight-medium">
                                    {{this.getFullAddress(address)}}
                                </div>
                            </div>  
                        </div>
                        <v-spacer/>
                    </v-card-title>
                    <v-card-text>

                        <ValidationObserver ref="incomeShareObserver">

                            <v-row class="pa-0 ma-0">
                                <v-col cols="4" lg="4" md="4" sm="12">
                                    <div class="body-1 font-weight-medium"> Who </div>
                                </v-col>
                                <v-col cols="4" lg="4" md="4" sm="12">
                                    <div class="body-1 font-weight-medium">Dollar Share</div>
                                </v-col>
                                <v-col cols="4" lg="4" md="4" sm="12">
                                    <div class="body-1 font-weight-medium">Percentage Share</div>
                                </v-col>
                            </v-row>
                            <v-row class="pa-0 ma-0">
                                <v-col cols="4" lg="4" md="4" sm="12">
                                    <div class="body-1 mt-2">{{  getEntityFriendlyName(getShareeData)  }}</div>
                                </v-col>
                                <v-col cols="4" lg="4" md="4" sm="12">
                                        <ValidationProvider v-slot="{ errors }" name="Dollar amount" rules="required|is_positive">
                                            <v-text-field 
                                                type="number"
                                                label=""
                                                :error-messages="errors"
                                                prefix="$"
                                                v-model="shareeDollar"
                                                @input="handleShareeDollarChange"
                                                class="ma-0 pa-0"
                                                color="secondary">
                                            </v-text-field>
                                        </ValidationProvider>
                                </v-col>
                                <v-col cols="4" lg="4" md="4" sm="12">
                                    <ValidationProvider v-slot="{ errors }" name="Percentage" rules="required|is_positive|percentage_share:1,99">
                                        <v-text-field 
                                            type="number"
                                            label=""
                                            :error-messages="errors"
                                            suffix="%"
                                            v-model="shareePercent"
                                            @input="handleShareePercentChange"
                                            class="ma-0 pa-0"
                                            color="secondary">
                                        </v-text-field>
                                    </ValidationProvider>
                                </v-col>
                            </v-row>
                            <v-row class="pa-0 ma-0">
                                <v-col cols="4" lg="4" md="4" sm="12">
                                        <v-select
                                            :items="getShareHolders"
                                            item-text="name"
                                            :readonly="false"
                                            label=""
                                            class="ma-0 pa-0"
                                            :error-messages="errors"
                                            v-model="shareHolder"
                                            color="secondary">
                                        </v-select>
                                </v-col>
                                <v-col cols="4" lg="4" md="4" sm="12">
                                        <v-text-field 
                                            type="number"
                                            label=""
                                            :readonly="true"
                                            :disabled="true"
                                            :error-messages="errors"
                                            prefix="$"
                                            v-model="shareHolderDollar"
                                            class="ma-0 pa-0"
                                            color="secondary">
                                        </v-text-field>
                                </v-col>
                                <v-col cols="4" lg="4" md="4" sm="12">
                                        <v-text-field 
                                            type="number"
                                            label=""
                                            :readonly="true"
                                            :disabled="true"
                                            :error-messages="errors"
                                            suffix="%"
                                            v-model="shareHolderPercent"
                                            class="ma-0 pa-0"
                                            color="secondary">
                                        </v-text-field>
                                </v-col>
                            </v-row>

                            <v-row class="pa-0 ma-0">
                                <v-col cols="4" lg="4" md="4" sm="12" class="pt-0">
                                    <div class="text-h5 font-weight-bold secondary--text">
                                       Total
                                    </div>
                                </v-col>
                                <v-col cols="4" lg="4" md="4" sm="12" class="pt-0">
                                    <div class="text-h5 font-weight-medium secondary--text">
                                       {{ investmentIncome | currency }}
                                    </div>
                                </v-col>
                                <v-col cols="4" lg="4" md="4" sm="12" class="pt-0">
                                    <div class="text-h5 font-weight-medium secondary--text">
                                        100%
                                    </div>
                                </v-col>
                            </v-row>

                        </ValidationObserver>

                        <!-- NOTE: For dev use only, this is to show selected entities -->
                        <v-chip-group v-model="selected" column multiple @change="handleChange" v-if="false">
                            <v-chip :filter="false" v-for="entity in entities" :key="entity.id" 
                                v-if="entity.type == 'people'"
                                active-class="secondary"
                                color="light_gray_1"
                                :value="entity.id">
                                <span class="font-weight-bold">
                                    {{ getFriendlyRoles(entity, parts) }}
                                </span>&nbsp;
                                {{ getEntityFriendlyName(entity) }}
                            </v-chip>
                        </v-chip-group>
                        
                    </v-card-text>
                </v-card>

                <v-divider></v-divider>
    
                <v-card-actions class="pa-4 pa-md-6 flex-wrap controls">
                    <v-btn color="light_gray_2" class="px-5" :min-width="140" outlined depressed nuxt large @click="dialog = false">Back</v-btn> 
                    <v-spacer></v-spacer>
                    <v-btn color="secondary" class="px-5" :min-width="140" depressed nuxt large @click="handleSave">Save</v-btn> 
                </v-card-actions>

            </v-card>
        </v-dialog>
        
    </div>
</template>

<script>

    import { mapActions, mapState, mapMutations } from 'vuex'

    import resourceHelpers from '~/components/shared/resource-helpers'
    import { ValidationObserver, ValidationProvider } from 'vee-validate'

    export default {
        mixins: [resourceHelpers],
        components: {
            ValidationObserver,
            ValidationProvider
        },
        model: {
            prop: 'modelValue',
            event: 'change',
        },
        props: {
            modelValue: {
                type: Array,
                default: () => []
            },
            label: {
                type: String,
                default: ""
            },
            income: {
                type: [String, Object],
                default: null,
            },
            address: {
                type: [String, Object],
                default: null,
            },
            entities: {
                type: Array,
                default: () => [],
            },
            parts: {
                type: Array,
                default: () => [],
            },
            errors: {
                type: Array,
                default: () => [],
            },
            hidden: {
                type: Boolean,
                default: false,
            }
        },
        data() {
            return {
                selected: [],

                dialog: false,
                disabled: false,

                shareeDollar: 0,
                shareePercent: 100,

                shareHolder: null,
                shareHolderDollar: 0,
                shareHolderPercent: 0,
            }
        },
        mounted(){

        },
        computed: {
            getShareeData(){

                let shareeId = this.selected?.[0]

                let shareeData = this.entities.find(entity => {
                    return entity.id == shareeId
                })

                return shareeData ? shareeData : {}
            },
            getShareHolders(){

                let holders = []
                let shareeId = this.selected?.[0]

                this.entities.forEach(entity => {

                    if(entity.id !== shareeId && entity.type == 'people') {

                        holders.push({
                            name: this.getEntityFriendlyName(entity),
                            value: entity.id
                        })

                    }
                })

                return holders
            },
            investmentIncome() {
                return this.income ? this.income.attributes["investment-income"] : 0
            },
            primaryIncomeSplit() {

                if(this.income?.attributes?.["primary-income-split"] != 100){
                    return  this.income.attributes["primary-income-split"]
                }

                return 50
            },
            isSharable(){

                let peopleEntitycount = 0
                let shareeId = this.selected?.[0]

                this.entities.forEach(entity => {
                    if(entity.id !== shareeId && entity.type == 'people') {
                        peopleEntitycount++
                    }
                })

                return peopleEntitycount > 0

            },
            isPreviousIncome(){
                
                if(this.income) {
                    return this.income.attributes["income-situation"] == "Previous"
                }

                return false
            }
        },
        methods: {
            ...mapActions('resource-actions', [
                'updateIncome',
            ]),
            handleChange(value){
                // this.$emit('change', value)
            },
            handleOpen(){
                this.dialog = true
            },
            async handleSave(){

                /**
                 * TODO:
                 * 
                 *  retrieve percentage value to resource?
                 * 
                 * ✅ validate form
                 * 
                 * save percentage value to resource?
                 * 
                 * ✅emit change
                 *
                 */

                const {shareHolder} = this

                const valid = await this.$refs.incomeShareObserver.validate();

                this.disabled = true

                if(valid) {

                    // include shareholder to the selected entities array
                    if(!this.selected.includes(shareHolder)){
                        this.selected.push(shareHolder)
                    }

                    // console.log(this.selected)

                    this.$emit('share-percentage-update', this.shareePercent)

                    this.$emit('change', this.selected)

                    this.disabled = false
                    this.dialog = false

                } else {

                    this.disabled = false
                }

            },
            getPercentageByAmount(amount, total){
                let val = (amount / total) * 100

                if (val % 1 !== 0) {
                    return val.toFixed(2);
                }

                return val
            },
            getAmountByPercentage(percent, total){
                return (percent / 100) * total
            },
            handleShareeDollarChange(val){
                this.shareePercent = this.getPercentageByAmount(val, this.investmentIncome)
                this.calculateShareHolderValuesByAmount(val)
            },
            handleShareePercentChange(val){
                this.shareeDollar = this.getAmountByPercentage(val, this.investmentIncome).toFixed(2)
                this.calculateShareHolderValuesByAmount(this.shareeDollar)
            },
            calculateShareHolderValuesByAmount(value){

                let {investmentIncome} = this

                this.shareHolderDollar = (investmentIncome - value).toFixed(2)
                this.shareHolderPercent = this.getPercentageByAmount(this.shareHolderDollar, investmentIncome)

            },
            updateShareeFields(){

                // console.log(this.modelValue)

                this.selected = this.modelValue
                
                // Set holders default value 
                let holders = this.entities.filter(entity => {
                    return entity.id !== this.selected?.[0] && entity.type == 'people'
                })

                if(holders.length) {
                    this.shareHolder = holders?.[0].id
                }

                let incomeSplit = (this.primaryIncomeSplit / 100)

                // Set Sharee amount default value
                
                this.shareeDollar = (this.investmentIncome * incomeSplit)

                // Set Sharee percentage default value

                this.shareePercent = this.getPercentageByAmount(this.shareeDollar, this.investmentIncome)

                // Set share holder fields
                this.calculateShareHolderValuesByAmount(this.investmentIncome * incomeSplit)
            }
        },
        watch: {
            /**
             * Update the fields everytime the modal opens.
             */
            dialog(val){

                if(val) { 
                    this.updateShareeFields()
                }
            },
            // shareePercent(newVal, oldVal){
            //     if(newVal !== oldVal) { 
            //         this.$emit('share-percentage-update', newVal)
            //     }
            // }
        },

    }
</script>

<style lang="scss">

    .income-sharing-editor {
  


        .v-slide-group__wrapper {

            .v-chip {
                // padding: 12px;

                .v-chip__content {
                    padding: 5px;
                    font-weight: 500;
                }
            }

            .secondary {
                background: var(--v-secondary-base) !important;
            }

        }
            
    }

</style>