<!--  

    ConfirmModal2
    1. can handle async processes and custom buttons via slot (standalone == true)
    2. can be toggled outside when standalone == false

-->
<template>
    <v-dialog
        v-model="dialogProp"
        persistent
        width="500">

        <template v-slot:activator="{on}">
            <slot name="activator" v-bind:on="toggle"></slot>
        </template>

        <v-card :disabled="processing">

            <v-progress-linear :color="color" indeterminate stream absolute top v-if="processing"></v-progress-linear>
            
            <v-card-title class="px-8 pt-8">
                <div class="v-card__title sm pa-0">
                    {{ title }}
                </div>
            </v-card-title>

            <v-card-text class="px-8 pt-2 pb-8 body-1" v-html="message"></v-card-text>

            <v-card-actions class="px-8 pb-8">
                <v-spacer></v-spacer>

                <v-btn
                    v-if="showCancel"
                    class="flex-back-button"
                    outlined
                    large depressed
                    @click="handleCancel">
                    {{cancelText}} 
                </v-btn>

                <v-btn
                    v-if="showConfirm"
                    :color="color"
                    large depressed
                    @click="handleClick">
                    {{confirmText}}
                </v-btn>

            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
    export default {
        props: {
            title: {
                type: String,
                default: 'Confirm Action'
            },
            message: {
                type: String,
                default: 'Are you sure?'
            },
            showConfirm: {
                type: Boolean,
                default: true
            },
            confirmText: {
                type: String,
                default: 'Confirm'
            },
            showCancel: {
                type: Boolean,
                default: true
            },
            cancelText: {
                type: String,
                default: 'Cancel'
            },
            standalone: {
                type: Boolean,
                default: true
            },
            value: {
                type: Boolean,
                default: false
            },
            async: {
                type: Boolean,
                default: false
            },
            processing: {
                type: Boolean,
                default: false
            },
            color: {
                type: String,
                default: 'error'
            }
        },
        data() {
            return {
                dialog: false
            }
        },
        computed: {
            dialogProp: {
                get(){

                    if(this.standalone){
                        return this.dialog
                    }

                    return this.value

                },
                set(value){
                    this.dialog = value
                }
            },
        },
        methods: {
            toggle(){

                this.dialog = !this.dialog

            },
            handleClick() {

                this.$emit('confirm')

                if(!this.async){

                    this.dialog = false

                    if(!this.standalone){
                        this.$emit('close')
                    }

                }

            },
            handleCancel(){

                this.dialog = false

                if(!this.standalone){
                    this.$emit('close')
                }
            }
        },
        watch: {
            processing(newVal, oldVal){

                if(this.async && newVal != oldVal && newVal == false){

                    this.dialog = false

                    if(!this.standalone){
                        this.$emit('close')
                    }

                }

            }
        }
    }
</script>

<style lang="scss" scoped>

</style>