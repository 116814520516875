export default {
    "data":[
        {
          "slug": "nodifi",
          "scripts": [],
        },
        {
          "slug": "onlineloans",
          "scripts": [
            // Google Tag Manager Head
            {
                production: true,
                data: {
                  innerHTML: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                  new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                  j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                  'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                  })(window,document,'script','dataLayer','GTM-MSM2BQB');`,
                }
            },
            // Google Tag Manager Body
            {
              production: true,
              noscript: true,
              data: {
                body: true,
                innerHTML: `<!-- Google Tag Manager (noscript) --><iframe src="https://www.googletagmanager.com/ns.html?id=GTM-MSM2BQB" height="0" width="0" style="display:none;visibility:hidden"></iframe>`,
              }
            }
          ],
        },
        {
          "slug": "choice",
          "scripts": [
          ]
        },
        {
          "slug": "fast",
          "scripts": [
          ]
        },
        {
          "slug": "loanmarket",
          "scripts": [
          ]
        },
        {
          "slug": "loanmarket1",
          "scripts": [
          ]
        },
        {
          "slug": "money3",
          "scripts": [
          ]
        },
        {
          "slug": "plan",
          "scripts": [
          ]
        },
        {
          "slug": "medezi",
          "scripts": [
          ]
        },
        {
          "slug": "anchor",
          "scripts": [
          ]
        },
    ]
}