<template>
    <v-dialog
        v-model="dialog"
        width="600"
        persistent>
        <v-card>

            <v-progress-linear 
                v-if="loader"
                color="secondary"
                indeterminate stream top 
                value="0">
            </v-progress-linear>

            <v-overlay
                absolute
                :opacity="0.1"
                v-if="loader">                
            </v-overlay>
            
            <v-card-title>
                <v-subheader></v-subheader>
                <v-spacer />
                <v-btn icon @click="close" >
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-card-title>

            <v-card-text class="px-10">
                <SummaryDetail
                    @user-confirmed="confirmedLender"
                    :user-confirmed-lender="userConfirmedLender"
                    :selected-lender="selectedLender"
                    :selected-logo="selectedLogo"
                    :loan-resource="loanResource"
                    :centered="true"
                />
            </v-card-text>

        </v-card>
    </v-dialog>
</template>

<script>

    import SummaryDetail from './QuotableSummary'

    export default {
        name: 'QuotableSummaryDialog',

        props: {
            dialog: {
                type: Boolean,
                default: false
            },
            selectedLogo: {
                type: String,
                default: ''
            },
            userConfirmedLender: {
                type: String,
                default: ''
            },
            loanResource: {
                type: Object,
                default: () => {}
            },
            selectedLender: {
                type: Object,
                default: () => {}
            }
        },

        components: { 
            SummaryDetail
        },

        computed: {
            
        },

        methods: {
            
            close() {
                this.$emit('close', true)
            },

            confirmedLender(data) {
                this.loader = true

                this.selected_lender = data
                this.$emit('user-confirmed', this.selected_lender)

                this.$emit('close', true)
                this.loader = false
            }
            
        },

        watch: {
            selectedLender(newValue) {                
                this.selected_lender = ''                
            }
        },

        data() {
            return {
                selected_lender: '',
                resources: null,
                loader: false
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>