<template>
    <FlexWrap @resize="handleResize">
        <ValidationObserver :data-id="id" :vid="id" v-if="entity">
            <div v-for="(layout, index) in fieldLayout" :key="index">
                <div class="title ma-0 my-2 body_text_color--text" v-if="layout.title">
                    
                </div>
                <v-row>
                    <v-col :lg="labels[key] == 'Email' ? 6 : responsiveComponent"  :cols="responsiveComponent" class="py-0 my-0 mx-0" v-for="(field, key) in fieldLayoutMaker(layout, entity.attributes)" :key="key">
                        <ValidationProvider 
                            v-if="visible[key]"
                            v-slot="{ errors }" 
                            :name="labels[key]" 
                            :rules="getCustomRules(key)" 
                            :vid="`${key}`">

                            <ButtonGroup1 
                                v-if="isBtnGroup(key)"
                                v-model="entity.attributes[key]"
                                :error-messages="errors"
                                :options="['Yes', 'No']"
                                :label="labels[key]" />

                            <Incrementer1 
                                v-else-if="isIncrementer(key)"
                                v-model="entity.attributes[key]"
                                :errors="errors"
                                :label="labels[key]" />

                            <v-select
                                v-else-if="isFieldType('picklist', key)"
                                :items="getOptions(key)"
                                :label="labels[key]"
                                :error-messages="errors"
                                v-model="entity.attributes[key]"
                                clearable
                                color="secondary">
                            </v-select>

                            <template v-else-if="isFieldType('date', key)">
                                <FlexDatePicker3
                                    :label="labels[key]"
                                    :errors="errors"
                                    v-model="entity.attributes[key]">
                                </FlexDatePicker3>
                            </template>

                            <AbnLookup
                                v-else-if="labels[key] === 'ABN'"
                                v-model="entity.attributes[key]"
                                :errors="errors"
                                @accept="handleAbnData"
                                :label="labels[key]" />

                            <v-text-field 
                                v-else
                                :inputmode="inputMode(key)"
                                :type="getFieldType(key)"
                                :label="labels[key]"
                                :error-messages="errors"
                                :disabled="isReadOnly(key)"
                                v-model="entity.attributes[key]"
                                color="secondary">
                            </v-text-field>
                        </ValidationProvider>
                    </v-col>
                </v-row>
            </div>
        </ValidationObserver>
    </FlexWrap>
</template>

<script>
    
    import { mapActions, mapGetters, mapState, mapMutations } from 'vuex'
    import formHelpers from '~/components/shared/form-helpers'
    import veeValidate from '~/components/shared/validation'

    import entityData from '~/components/v1/ApplicantEditor5/entity-data'
    import fieldOptions from '~/components/shared/field-options'
    import baseComponents from '~/components/base'
    import includedComponents from '~/components/v1/ApplicantEditor5/includes'

    import { getDateValidations } from '~/plugins/date-helper.js'
    const { maxAgeDate, minAgeDate, minDate } = getDateValidations()

    export default {
        mixins: [formHelpers, veeValidate],
        components: {
            ...baseComponents,
            ...includedComponents
        },
        props: {
            loanKindId: {
                type: String,
                required: true,
            },
            data: {
                type: Object,
                required: true,
            },
            entityPartKindId: {
                type: String,
                required: true,
            },
            id: {
                type: String,
                default: '',
            },
        },
        data(){
            return {
                sizes: null,
                entity: null,
                errorMessages: {},
                visible: {
                    "first-name": true,
                    "middle-name": true,
                    "last-name": true,
                    "date-of-birth": true,
                    "email": true,
                    "mobile-phone": true,
                    "other-phone": true,
                    "primary-country-of-citizenship": false,
                    "secondary-country-of-citizenship": false,
                    "visa": false,
                    "visa-expiry-date": false,
                    "gender": true,
                    "marital-status": true,
                    "number-of-dependants": true,
                    "age-of-dependants": true,
                    "credit-status": false,
                    "previous-bankrupt": false,
                    "bankruptcy-status": false,
                    "bankruptcy-declared-date": false,
                    "bankruptcy-discharge-date": false,


                    "business-legal-name": false,
                    "registered-business-name": false,
                    "nature-of-business": false,
                    "acn-number": false,
                    "abn-number": false,
                    "date-registered-abn": false,
                    "date-registered-gst": false,

                    "drivers-licence-number": false,
                    "drivers-licence-state": false,
                    "drivers-licence-card-number": false,
                    "drivers-licence-expiry": false,

                    "medicare-number": false,
                    "passport-number": false,
                    "passport-expiry": false,

                    "comprehensive-credit-score": false
                },
                labels: {
                    "first-name": "First Name",
                    "middle-name": "Middle Name",
                    "last-name": "Last Name",
                    "date-of-birth": "Date of Birth",
                    "email": "Email",
                    "mobile-phone": "Mobile Number",
                    "other-phone": "Other Phone",
                    "primary-country-of-citizenship": "Primary Country of Citizenship",
                    "secondary-country-of-citizenship": "Secondary Country of Citizenship",
                    "visa": "Visa",
                    "visa-expiry-date": "Visa Expiry Date",
                    "gender": "Gender",
                    "marital-status": "Marital Status",
                    "number-of-dependants": "Dependants",
                    "age-of-dependants": "Age of Dependants",
                    "credit-status": "Credit Status",
                    "previous-bankrupt": "Previous Bankrupt",
                    "bankruptcy-status": "Bankruptcy Status",
                    "bankruptcy-declared-date": "Bankruptcy Declared Date",
                    "bankruptcy-discharge-date": "Bankruptcy Discharge Date",

                    "business-legal-name": "Business Legal Name",
                    "registered-business-name": "Registered Business Name",
                    "nature-of-business": "Nature of Business",
                    "acn-number": "ACN",
                    "abn-number": "ABN",
                    "date-registered-abn": "Date Registered ABN",
                    "date-registered-gst": "Date Registered GST",

                    "drivers-licence-number": "Drivers Licence Number",
                    "drivers-licence-state": "Drivers Licence State",
                    "drivers-licence-card-number": "Drivers Licence Card Number",
                    "drivers-licence-expiry": "Drivers Licence Expiry",

                    "medicare-number": "Medicare Number",
                    "passport-number": "Passport Number",
                    "passport-expiry": "Passport Expiry",

                    "comprehensive-credit-score": "Credit Score",

                },
                validation_rules: {
                    attributes: {
                        "first-name": "required|string",
                        "middle-name": "string",
                        "last-name": "required|string",
                        "date-of-birth": `required|string|date_format:Y/m/d|before:${minAgeDate}|after:${maxAgeDate}`,
                        "mobile-phone": "required",
                        "email": "required|email",
                        "mobile-phone": "required|numeric|digits_between:10,10",
                        "primary-country-of-citizenship": `required|in:${fieldOptions["country-of-citizenship"]}`,
                        "secondary-country-of-citizenship": `string|in:${fieldOptions["country-of-citizenship"]}`,
                        "visa": `required|in:${fieldOptions["visa"]}`,
                        "visa-expiry-date": "string|date_format:Y/m/d",
                        "gender": `required|in:${fieldOptions["gender"]}`,
                        "marital-status": `required|in:${fieldOptions["marital-status"]}`,
                        "number-of-dependants": 'required|integer',
                        "age-of-dependants": "required|string|max:225",
                        "previous-bankrupt": "required|string|in:Yes,No",
                        "credit-status": `required|in:${fieldOptions["credit-status"]}`,
                        "bankruptcy-status": `required_if:previous-bankrupt,Yes|in:${fieldOptions["bankruptcy-status"]}`,
                        "bankruptcy-declared-date": `required_if:previous-bankrupt,Yes|date_format:Y/m/d|after:${minDate}|before:now`,
                        "bankruptcy-discharge-date": `required_if:bankruptcy-status,Discharged Part 9,Discharged Bankrupt|date_format:Y/m/d|after:${minDate}|before:now`,

                        "business-legal-name": "required|string",
                        "registered-business-name": "required|string",
                        "nature-of-business": "required|string",
                        "acn-number": "required|string",
                        "abn-number": "required|digits_between:11,11",
                        "date-registered-abn": "required|date_format:Y/m/d|before:now",
                        "date-registered-gst": "string|date_format:Y/m/d|before:now",

                        "drivers-licence-number": "required|string",
                        "drivers-licence-state": `required|in:${fieldOptions["drivers-licence-state"]}`,
                        "drivers-licence-card-number": "string",
                        "drivers-licence-expiry": "required|date_format:Y/m/d|after:now",

                        "medicare-number": "required|numeric|digits_between:10,10",
                        "passport-number": "required",
                        "passport-expiry": "required|date_format:Y/m/d|after:now",

                        "other-phone": "required|numeric|digits_between:10,10",

                        "comprehensive-credit-score": 'required|integer',

                    }
                },
                readonly: {
                    "comprehensive-credit-score": true
                }
            }
        },
        computed: {
            ...mapGetters('kinds', ['kinds', 'kindsUnformat']),
            ...mapState('flows', [
                'loanData'
            ]),
            fieldLayout(){

                const {entityPartKindId, loanKindId} = this

                let loanKindName = this.getKindNameById(loanKindId)
                let entityKindName = null
                let key = null

                if(entityPartKindId){
                    entityKindName = this.getKindNameById(entityPartKindId)
                }

                Object.keys(entityData).forEach( type => {

                    if( entityData[type]['loan-kinds'].includes(loanKindName) && entityData[type]['entity-kinds'].includes(entityKindName) ) {
                        key = type
                    }

                })
                // console.log(loanKindName)
                // console.log(entityKindName)

                if(key){
                    return entityData[key].layout
                }

                return []
            },
            entityFormType(){

                const {entityPartKindId, loanKindId} = this

                let loanKindName = this.getKindNameById(loanKindId)
                let entityKindName = null
                let key = null

                if(entityPartKindId){
                    entityKindName = this.getKindNameById(entityPartKindId)
                }

                Object.keys(entityData).forEach( type => {

                    if( entityData[type]['loan-kinds'].includes(loanKindName) && entityData[type]['entity-kinds'].includes(entityKindName) ) {
                        key = type
                    }

                })

                return key
            },
            countryOfCitizenship(){
                return this.entity?.attributes["primary-country-of-citizenship"] +'|'+ this.entity?.attributes["secondary-country-of-citizenship"]
            },
            previousBankrupt(){
                return this.entity?.attributes["previous-bankrupt"]
            },
            bankruptcyStatus(){
                return this.entity?.attributes["bankruptcy-status"]
            },
            dependants(){
                return this.entity?.attributes["number-of-dependants"]
            },
            driversLicenceState(){
                return this.entity?.attributes["drivers-licence-state"]
            },
            responsiveComponent(){

                if(!this.sizes) return 12

                const {sm, xs, md, lgAndUp, width} = this.sizes

                let formTypes = [
                    'type4',
                    'type4-1',
                    'type3',
                    'type3-1',
                    'type3-2'
                ]
                
                if(lgAndUp){

                    if ( formTypes.includes(this.entityFormType) ) {
                        return 6
                    } else {
                        return 3
                    }
                }

                if(md){

                    if ( formTypes.includes(this.entityFormType)) {
                        return 6
                    } else {
                        return 3
                    }
                }

                if(sm){
                    return 6
                }

                if(xs){
                    return 12
                }

            },
            mobilePhone() {
                return this.entity?.attributes?.['mobile-phone']
            },
            otherPhone(){
                return this.entity?.attributes?.['other-phone'] 
            },
            medicareNumber() {
                return this.entity?.attributes?.['medicare-number']
            },
            passportNumber() {
                return this.entity?.attributes?.['passport-number']
            },
            isUnsecured(){
                if(this.loanData.attributes['loan-type'].search('Asset') == -1 ){
                    return true
                }
                return false
            },
        },
        mounted() {
            this.init()
        },
        methods: {
            ...mapActions('resource-actions', [
                'getPeople',
                'updatePerson',
                'createPerson'
            ]),
            init(){

                // localize data prop to trigger watchers
                this.entity = this.data


                // TEMP! - Hardcoded for now...
                if(this.isUnsecured) {
                    this.entity.attributes["comprehensive-credit-score"] = 750 
                }

            },
            inputMode(key) {
                if(key == 'age-of-dependants') {
                    return 'search'
                } else {
                    return 'text';
                }
            },
            getKindNameById(id){

                let kind = this.kindsUnformat.find( kind => kind.id == id)

                if(kind){
                    return kind.attributes.name
                }

                return null

            },
            getCustomRules(name){

                const defaultRules = this.getRules(name);

                if(name == 'age-of-dependants') {
                    return 'age_dependants:number-of-dependants|' + defaultRules
                } 

                if(name == 'mobile-phone') {
                    return 'is_mobile_number|has:04,+61|' + defaultRules
                } 

                if(name == 'other-phone') {
                    return 'has_other:0|' + defaultRules
                } 

                return defaultRules
            },
            isBtnGroup(key){

                let keys = [
                    "previous-bankrupt"
                ]

                return keys.includes(key)

            },
            isIncrementer(key) {
                let keys = [
                    "number-of-dependants"
                ]

                return keys.includes(key)
            },
            handleResize(sizes){
                this.sizes = sizes
            },
            handleAbnData(data) {
                this.entity.attributes['date-registered-abn'] = data['abn-reg']
                this.entity.attributes['date-registered-gst'] = data['gst-reg-date']
                this.entity.attributes['business-legal-name'] = data['abn-name']
                this.entity.attributes['acn-number'] = data['acn-number']
            },
            isReadOnly(key){
                
                const {readonly} = this

                if(readonly.hasOwnProperty(key)){
                    return readonly[key]
                }

                return false

            },
        },
        watch: {
            countryOfCitizenship(val){

                // let countries = val.split('|')

                // let cond = countries.includes('Australia') || countries.includes('New Zealand')

                // if(cond){

                //     this.visible["visa"] = false
                //     this.visible["visa-expiry-date"] = false

                // } else {

                //     if(countries[0] == 'null' && countries[1] == 'null'){
                        
                //         this.visible["visa"] = false
                //         this.visible["visa-expiry-date"] = false

                //     } else {

                //         this.visible["visa"] = true
                //         this.visible["visa-expiry-date"] = true

                //     }

                // }

            },
            previousBankrupt(val){

                // if(val == 'No' || val == null){

                //     this.entity.attributes["bankruptcy-status"] = ""
                //     this.entity.attributes["bankruptcy-declared-date"] = ""

                //     this.visible["bankruptcy-status"] = false
                //     this.visible["bankruptcy-declared-date"] = false
                // } 

                // if(val == 'Yes'){

                //     this.visible["bankruptcy-status"] = true
                //     this.visible["bankruptcy-declared-date"] = true
                // }

            },
            bankruptcyStatus(val){

                // if(val && val.includes('Discharged')){

                //     this.visible["bankruptcy-discharge-date"] = true

                // } else {

                //     this.entity.attributes["bankruptcy-discharge-date"] = ""
                //     this.visible["bankruptcy-discharge-date"] = false
                // }

            },
            dependants(val){

                if(parseInt(val) > 0){

                    this.visible["age-of-dependants"] = true

                } else {
                    
                    this.entity.attributes["age-of-dependants"] = ""
                    this.visible["age-of-dependants"] = false
                }

            },

            driversLicenceState(val){

                // if(val === 'New South Wales'){

                //     this.visible["drivers-licence-card-number"] = true

                // } else {
                    
                //     this.entity.attributes["drivers-licence-card-number"] = ""
                //     this.visible["drivers-licence-card-number"] = false
                // }

            },

            mobilePhone(val) {
                if(val) {
                    if (this.otherPhone) this.validation_rules.attributes['other-phone'] = "required|numeric|digits_between:10,10"
                    else this.validation_rules.attributes['other-phone'] = ''
                    this.validation_rules.attributes['mobile-phone'] = "required"
                }
                else {
                    if (!this.otherPhone) {
                        this.validation_rules.attributes['other-phone'] = "required|numeric|digits_between:10,10"
                        this.validation_rules.attributes['mobile-phone'] = "required"
                    }
                    else this.validation_rules.attributes['mobile-phone'] = ''
                }                
            },

            otherPhone(val) {
                if(val) {
                    if (this.mobilePhone) this.validation_rules.attributes['mobile-phone'] = "required"
                    else this.validation_rules.attributes['mobile-phone'] = ''
                    this.validation_rules.attributes['other-phone'] = "required|digits_between:10,10"
                }
                else {
                    if (!this.mobilePhone) {
                        this.validation_rules.attributes['mobile-phone'] = "required"
                        this.validation_rules.attributes['other-phone'] = "required|numeric|digits_between:10,10"
                    }
                    else this.validation_rules.attributes['other-phone'] = ''
                }
            },

            medicareNumber(val) {
                if(val) {
                    if (this.passportNumber) this.validation_rules.attributes['passport-number'] = "required"
                    else this.validation_rules.attributes['passport-number'] = ''                    
                    this.validation_rules.attributes['medicare-number'] = "required|numeric|digits_between:10,10"
                }
                else {
                    if (!this.passportNumber) {
                        this.validation_rules.attributes['passport-number'] = "required"
                        this.validation_rules.attributes['medicare-number'] = "required|numeric|digits_between:10,10"
                    }
                    else this.validation_rules.attributes['medicare-number'] = ""
                }
            },

            passportNumber(val) {

                // if(val){
                //     this.visible["passport-expiry"] = true

                // } else {                    
                //     this.entity.attributes["passport-expiry"] = ""
                //     this.visible["passport-expiry"] = false
                // }

                if(val) {
                    if (this.medicareNumber) this.validation_rules.attributes['medicare-number'] = "required|numeric|digits_between:10,10"
                    else this.validation_rules.attributes['medicare-number'] = ''
                    this.validation_rules.attributes['passport-number'] = "required"
                }
                else {
                    if (!this.medicareNumber) {
                        this.validation_rules.attributes['medicare-number'] = "required|numeric|digits_between:10,10"
                        this.validation_rules.attributes['passport-number'] = "required"
                    }
                    else this.validation_rules.attributes['passport-number'] = ""
                    // val = '0'
                }
            },

        }
    }
</script>

<style scoped lang="scss">
    
</style>
