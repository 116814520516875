import Vue from "vue";

export const store = Vue.observable({

    steps: {
        "citizenship": {
            "title": "Citizenship Information",
            "subtitle": "Please use the Nodifi Data to answer the questions in the Plenti Requirements",
            "form-ref": "CitizenshipInformationForm",
        },
        "employment": {
            "title": "Employment Information",
            "subtitle": "Please use the Nodifi Data to answer the questions in the Plenti Requirements",
            "form-ref": "EmploymentInformationForm",
        },
    },

    carousel: 0,

});


export const actions = {

    setCarousel(payload) {
        store.carousel = payload
    },

    resetStore() {
        store.carousel = 0
    },

    setCarouselNext() {
        store.carousel++
    },
    setCarouselBack() {
        store.carousel--
    },

};
