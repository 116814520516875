<template>

    <div class="pt-6 pr-4 text-center" v-if="!getLeftDrawerVariant">
        <img src="~/assets/images/societyone-submission-logo.png" class="mx-auto" width="186"/>
    </div>

</template>

<script>
    import { mapGetters } from 'vuex'

    export default {
        mixins: [],
        components: {
        },
        data(){
            return {
                dialog: false
            }
        },
        mounted() {

        },
        computed: {
            ...mapGetters('setup', [
                'getLeftDrawerVariant'
            ]),
            vb() {
                return this.$vuetify.breakpoint
            },
        },
        methods: {
            handleClick(){
                this.dialog = true
            },
        }
    }
</script>

<style lang="scss">


</style>
