<template>
    <v-dialog v-model="dialog" persistent :max-width="formType === 'key-information' ? '800px' :'1200px'">
            <v-card class="mx-auto" tile flat>

                <v-progress-linear v-if="loading" color="secondary" indeterminate stream top value="0"> </v-progress-linear>

                <div class="pt-10"></div>

                <v-card-text class="mx-auto pa-4 pt-0 pa-md-10 pt-md-0">
                    <v-card tile flat class="mx-auto" >
                        <v-container fluid pa-0>
                            <v-row>
                                <v-col cols="12" class="pt-0">

                                    <ValidationObserver ref="observer" tag="form">

                                        <div class="title my-4 body_text_color--text d-flex">
                                            {{ formTitle }}
                                            <v-spacer />
                                            <v-btn icon @click="close" >
                                                <v-icon>mdi-close</v-icon>
                                            </v-btn>
                                        </div>

                                        <div 
                                            v-for="(layout, lkey) in layouts" 
                                            :key="lkey" 
                                            class="flex-form" :class="{'single-field': isSingleField}">
                                            <template v-for="(field, key) in fieldLayoutMaker(layout, resourceObj[layoutType].attributes)">
                                                <div class="flex-field" :key="key" >
                                                    
                                                    <ValidationProvider 
                                                            v-slot="{ errors }" 
                                                            :name="labels[key]"
                                                            :rules="getRules(key)" 
                                                            :vid="key">

                                                                <div v-if="key === '__spacer'"></div>
                                                                
                                                                <v-img 
                                                                    v-else-if="key ==='lender'"
                                                                    height="50"
                                                                    class="mt-4"
                                                                    :src="require(`@/assets/images/logo/cropped/${lenderLogo}.png`)" 
                                                                    position="center left" 
                                                                    contain>
                                                                </v-img>
                                                                        
                                                                <ButtonGroup3 
                                                                    v-else-if="isBtnGroup(key)"
                                                                    v-model="resourceObj[layoutType].attributes[key]"
                                                                    :error-messages="errors"
                                                                    :options="getOptions(key)"
                                                                    :label="labels[key]" />

                                                                <v-select
                                                                    v-else-if="isSelect(key)"
                                                                    v-model="resourceObj[layoutType].attributes[key]"
                                                                    :items="getOptions(key)"
                                                                    :error-messages="errors"
                                                                    color="secondary"
                                                                    :label="labels[key]"
                                                                    ></v-select>

                                                                <FlexDatePicker3
                                                                    v-else-if="isDatePicker(key)"
                                                                    :disabled="isReadyOnly(key)"
                                                                    :label="labels[key]"
                                                                    :errors="errors"
                                                                    v-model="resourceObj[layoutType].attributes[key]"
                                                                ></FlexDatePicker3>

                                                                <v-text-field 
                                                                    v-else-if="isCurrency(key)"
                                                                    tile
                                                                    :label="labels[key]"
                                                                    color="secondary"
                                                                    prefix="$"
                                                                    type="number"
                                                                    :error-messages="errors"
                                                                    :disabled="isReadyOnly(key)"
                                                                    v-model="resourceObj[layoutType].attributes[key]"
                                                                    required>
                                                                </v-text-field>

                                                                <v-text-field 
                                                                    v-else-if="isPercent(key)"
                                                                    tile
                                                                    :label="labels[key]"
                                                                    color="secondary"
                                                                    prefix="%"
                                                                    type="number"
                                                                    :error-messages="errors"
                                                                    :disabled="isReadyOnly(key)"
                                                                    v-model="resourceObj[layoutType].attributes[key]"
                                                                    required>
                                                                </v-text-field>

                                                                <v-textarea
                                                                    v-else-if="isTextarea(key)"
                                                                    color="secondary"
                                                                    :error-messages="errors"
                                                                    :disabled="isReadyOnly(key)"
                                                                    v-model="resourceObj[layoutType].attributes[key]"
                                                                    rows="4"
                                                                    row-height="80"
                                                                    auto-grow
                                                                    required>
                                                                </v-textarea>

                                                                <v-text-field 
                                                                    v-else
                                                                    tile
                                                                    type="text"
                                                                    :label="labels[key]"
                                                                    color="secondary"
                                                                    :error-messages="errors"
                                                                    :disabled="isReadyOnly(key)"
                                                                    v-model="resourceObj[layoutType].attributes[key]"
                                                                    required>
                                                                </v-text-field>
                                                    </ValidationProvider>
                                                </div>
                                            </template>
                                            <!-- Temp Spacer -->
                                            <div class="flex-field" v-for="index in 6" :key="index"></div>

                                        </div>
                                    </ValidationObserver>
                                </v-col>
                            </v-row>
                        </v-container>
                        <v-card-actions>
                            <v-row no-gutters>
                                    <v-btn
                                        @click="close"
                                        depressed
                                        :min-width="140" large outlined
                                        color="light_gray_2"
                                        class="flex-back-button"
                                        :disabled="loading"
                                        text>Back</v-btn>
                                    <v-spacer></v-spacer>
                                    <v-btn
                                        @click="save"
                                        depressed
                                        :min-width="140" large
                                        color="secondary"
                                        :disabled="loading"
                                        >Save</v-btn>
                            </v-row>
                        </v-card-actions>
                    </v-card>
                </v-card-text>
            </v-card>
    </v-dialog>
</template>

<script>

    import { mapState, mapGetters, mapActions, mapMutations } from 'vuex'

    import FlexDatePicker3 from '~/components/base/FlexDatePicker3'
    import ButtonGroup3 from '~/components/base/ButtonGroup3'

    import helpers from '~/plugins/helpers'
    import lenders from '~/config/lenders-slug-algo'
    import fieldOptions from '~/components/shared/field-options'
    import validation from '~/components/shared/validation'
    import formHelpers from '~/components/shared/form-helpers.js'

    export default {
        name: 'ApplicationSummaryEditor',
        mixins: [helpers, formHelpers, validation],
        components: { FlexDatePicker3, ButtonGroup3 },
        props: {
            value: {
                type: Boolean,
                default: false
            },
            formType: {
                type: String,
                // approval-condition
                // settlement-requirements
                // loan-details
                // key-information
                default: 'loan-details'
            },
            keyInformation: {
                type: Object,
                default: () => {}
            },
            loanDetails: {
                type: Object,
                default: () => {}
            },
            appId: {
                type: [String, Number],
                default: ''
            },
            loanId: {
                type: [String, Number],
                default: ''
            },
        },
        data(){
            return {
                dialog: this.value,
                loading: false,
                resources: [
                    {
                        type: "loans",
                        id: null,
                        attributes: {
                            "lender": "",
                            "actual-lender-reference": "",
                            "actual-term-months": "",
                            "actual-balloon": "",
                            "actual-required-loan-amount": "",
                            "actual-broker-fee": "",
                            "actual-bank-fees": "",
                            "actual-total-amount-financed": "",
                            "actual-base-rate": "",
                            "actual-customer-rate": "",
                            "actual-broker-commission-inc-gst": "",
                            "actual-payment-frequency": "",
                            "budgeted-payment-amount": "",
                            "actual-payment-terms": "",
                            
                            // Textareas
                            "selected-lender-quote-algorithm-slug": "",
                            "__spacer": null,
                        },
                        relationships: {
                            application: {
                                data: { type: "applications", id: null } 
                            }
                        }
                    },
                    {
                        type: "applications",
                        id: null,
                        attributes: {
                            "submitted-date": "",
                            "credit-officer-name": "",
                            "approval-date": "",
                            "settlement-officer-name": "",
                            "settlement-date": "",
                            "actual-total-commission": "",
                            "actual-partner-split": "",
                            "updated-at": "",
                            // "partner-split": "",
                            "approval-condition": "",
                            "settlement-docs-req": "",
                        }
                    }
                ],
                loanDetailsLayout: [
                    [
                        "lender",
                        "actual-lender-reference",
                        "actual-term-months",
                        "actual-balloon",
                    ],
                    [
                        "actual-required-loan-amount",
                        "actual-broker-fee",
                        "actual-bank-fees",
                        "actual-total-amount-financed",
                    ],
                    [
                        "actual-base-rate",
                        "actual-customer-rate",
                        "actual-broker-commission-inc-gst",
                    ],
                    [
                        "actual-payment-frequency",
                        "budgeted-payment-amount",
                        '__spacer',
                        "actual-payment-terms"
                    ]
                ],
                keyInformationLayout: [
                    [
                        "submitted-date",
                        "credit-officer-name",
                    ],
                    [
                        "approval-date",
                        "settlement-officer-name",
                    ],
                    [
                        "settlement-date",
                        "actual-total-commission",
                    ],
                    [
                        "updated-at",
                        "actual-partner-split"
                    ],
                ],
                // Static field labels. cause sometimes field names and labels are different
                labels: {
                    // Loan Details
                    "lender": "Lender",
                    "actual-lender-reference": "Lender Reference",
                    "actual-term-months": "Loan Term",
                    "actual-balloon": "Balloon",
                    "actual-required-loan-amount": "Required Loan Amount",
                    "actual-broker-fee": "Original Fee",
                    "actual-bank-fees": "Total Bank Fee",
                    "actual-total-amount-financed": "Total Amount Financed",
                    "actual-base-rate": "Base Rate",
                    "actual-customer-rate": "Loan Rate",
                    "actual-broker-commission-inc-gst": "Brokage",
                    "actual-payment-frequency": "Payment Frequency",
                    "budgeted-payment-amount": "Payment Amount",
                    "actual-payment-terms": "Payment Terms",

                    // Key Information
                    "submitted-date": "Submitted",
                    "credit-officer-name": "Credit Officer",
                    "approval-date": "Approved",
                    "settlement-officer-name": "Settlement Officer",
                    "settlement-date": "Settled",
                    "actual-total-commission": "Total Commission",
                    "updated-at": "Last Updated",
                    "actual-partner-split": "Partner Split",

                    // Textareas
                    "approval-condition": "Approval Condition",
                    "settlement-docs-req": "Settlement Requirements",
                    "selected-lender-quote-algorithm-slug": "Settlement Requirements",
                    "__spacer": "Settlement Requirements",
                },
                options: {
                    "actual-payment-terms": ['In Arrears', 'In Advance'],
                },
                validation_rules: {
                    attributes: {
                        "actual-lender-reference": "string|max:50",
                        "actual-term-months": "required|in:12,24,36,48,60,72,84",
                        "actual-balloon": "required|string|max:50",
                        // "partner-split": "required|string|max:50",
                        "settlement-date": "required|string|max:50",
                        "settlement-officer-name": "required|string|max:50",
                        "submitted-date": "",
                        "actual-total-commission": "",
                        "updated-at": "",
                        "approval-date": "required",
                        "actual-partner-split": "required|between:0,9999999",
                        "credit-officer-name": "required|string|max:50",
                        "actual-required-loan-amount": "between:0,9999999",
                        "actual-broker-fee": "required|between:0,9999999",
                        "actual-bank-fees": "required|between:0,9999999",
                        "actual-total-amount-financed": "required|between:0,9999999",
                        "actual-base-rate": "required|between:0,100",
                        "actual-customer-rate": "required|between:0,100",
                        "brokage": "required|between:0,9999999",
                        "actual-payment-frequency": "required|in:Monthly,Fortnightly,Weekly,Annual",         
                        "budgeted-payment-amount": "required|between:0,9999999",
                        "actual-broker-commission-inc-gst": "required|between:0,9999999",
                        "settlement-docs-req": 'required',
                        "actual-payment-terms": 'required|in:In Arrears,In Advance',
                        "approval-condition": 'required',
                        "selected-lender-quote-algorithm-slug": 'required',
                        "__spacer": '',
                    }
                },
                /**
                 * resourceObj : This will hold formatted resources so we can easily map it to the view.
                 */
                resourceObj: {},
                /**
                 * payloadObj : This will give us access to the payloads in push method during Unit Test
                 */
                payloadObj: {},
            }
        },
        computed: {
            ...mapGetters('kinds', ['kinds', 'kindsUnformat']),
            layoutType () {
                return this.formType === 'loan-details' ? 'loans' : 'applications'
            },
            layouts () {
                const layouts = {
                    'approval-condition': [['approval-condition']],
                    'settlement-requirements': [['settlement-docs-req']],
                    'loan-details': this.loanDetailsLayout,
                    'key-information': this.keyInformationLayout
                }
                return layouts[this.formType]
            },
            formTitle () {
                const titles = {
                    "loan-details": "Loan Details",
                    "key-information": "Key Information",
                    "approval-condition": "Approval Condition",
                    "settlement-requirements": "Settlement Requirements",
                    // "settlement-requirements": "Settlement Requirements",
                }

                return titles[this.formType]
            },
            lenderLogo () {
                const asset = this.resourceObj.loans && this.resourceObj.loans.attributes['selected-lender-quote-algorithm-slug']
                if (asset) {
                    const lenderType = asset.includes('personal') ? 'personal' : 'asset'
                    const assetData = lenders[lenderType].find(item => item['lender-slug-algorithm'] === asset)
                    return assetData['logo-class']
                }
                return 'placeholder'
            },
            isSingleField () {
                return [
                    "approval-condition",
                    "settlement-requirements",
                ].includes(this.formType)
            },
            loanFormKeys () {
                if (this.loanDetails && this.resourceObj.loans) {
                    return Object.keys(this.resourceObj.loans.attributes)
                }
                return []
            },
            appFormKeys () {
                if (this.resourceObj.applications) {
                    return Object.keys(this.resourceObj.applications.attributes)
                }
                return []
            },
        },
        created() {
            this.resourceObj = this.resourceObjHelper()
        },
        mounted(){

        },
        methods: {
            ...mapActions('resource-actions', [
                'getPurchase',
                'updateApplication',
                'updateLoan',
                'createPurchase',
                'updatePurchases'
            ]),
            close () {
                this.dialog = false
                this.$emit('input', false)
            },
            async save () {

                let valid = await this.$refs.observer.validate()

                this.loading = true

                if (this.layoutType === 'loans') {

                    let attributes = { ...this.resourceObj.loans.attributes }
                    
                    delete attributes["__spacer"]
                    delete attributes["lender"]
                    // delete attributes["actual-lender-reference"]
                    // delete attributes["actual-total-amount-financed"]

                    await this.updateLoan({
                        attributes,
                        id: this.loanId.toString(),
                        type: "loans",
                    })

                    let app = await this.updateApplication({
                        id: this.appId,
                        type: "applications",
                        attributes: {
                            edited: 1,
                        }
                    })

                    this.$emit('updateLoans', this.resourceObj.loans.attributes)

                    if(app.status == 200) {
                        this.$emit('on-update', app.data.data)
                    }

                } else {

                    let attributes = { ...this.resourceObj.applications.attributes }
                    const actualPartnerSplit = attributes['actual-partner-split']

                    delete attributes['actual-total-commission']
                    delete attributes['submitted-date']
                    delete attributes['updated-at']
                    delete attributes['actual-partner-split']

                    await this.updateLoan({
                        attributes: {
                            'actual-partner-split': actualPartnerSplit
                        },
                        id: this.loanId.toString(),
                        type: "loans",
                    })

                    await this.updateApplication({
                        attributes,
                        id: this.appId,
                        type: "applications"
                    })

                    // await this.updateApplication(this.resourceObj.applications)
                    this.$emit('updateApplication', this.resourceObj.applications.attributes)
                }
                
                this.loading = false

                if(valid){
                    this.close()
                }

            },
            isCurrency (key) {
                return [
                    "actual-broker-fee",
                    "actual-bank-fees",
                    "actual-balloon",
                    "actual-total-amount-financed",
                    "actual-broker-commission-inc-gst",
                    "actual-total-commission",
                    "actual-partner-split",
                    "actual-required-loan-amount",
                    // "partner-split",
                    "budgeted-payment-amount",
                ].includes(key)
            },
            isPercent (key) {
                return ['actual-base-rate', 'actual-customer-rate'].includes(key)
            },
            isDatePicker (key) {
                return [
                    "submitted-date",
                    "approval-date",
                    "settlement-date",
                    "updated-at"
                ].includes(key)
            },
            isSelect (key) {
                return ['actual-payment-frequency', 'actual-term-months'].includes(key)
            },
            isTextarea (key) {
                return [
                    "approval-condition",
                    "settlement-docs-req",
                ].includes(key)
            },
            isBtnGroup(key){

                let keys = [
                    "actual-payment-terms",
                ]

                return keys.includes(key)

            },
            isReadyOnly(key){

                let keys = [
                    "submitted-date",
                    "updated-at",
                    "actual-total-commission",
                    "actual-required-loan-amount"
                ]
                
                return keys.includes(key)

            }
        },

        watch: {
            keyInformation: {
                handler(data) {
                    const keys = Object.keys(data)
                    keys.forEach(key => {
                        if(this.appFormKeys.includes(key)) {
                            if (['submitted-date', 'approval-date', 'settlement-date', 'updated-at'].includes(key)) {
                                this.resourceObj.applications.attributes[key] = this.$options.filters.dateFormat(this.keyInformation[key])
                            } else {
                                this.resourceObj.applications.attributes[key] = this.keyInformation[key]
                            }
                        }

                    })
                },
                immediate: true,
                deep: true
            },
            loanDetails:{
                handler(data) {
                    const keys = Object.keys(data)
                    keys.forEach(key => {
                        if(this.loanFormKeys.includes(key)) {
                            if (key === 'actual-term-months') {
                                this.resourceObj.loans.attributes[key] = this.loanDetails[key] ? this.loanDetails[key].toString() : ''
                            } else {
                                this.resourceObj.loans.attributes[key] = this.loanDetails[key]
                            }
                        }
                    })
                },
                immediate: true,
                deep: true
            },
            value(val) {
                this.dialog = val
            },
        }
    
     
    }
</script>

<style scoped lang="scss">
    .flex-form {
        display: grid;
        grid-column-gap: 15px;
        grid-row-gap: 0;
        grid-template-columns:  repeat(auto-fit, minmax(260px, 1fr));

        &.single-field {
            grid-template-columns:  repeat(auto-fit, minmax(100%, 1fr));
        }

        .flex-field {
            align-self: start;
        }
    }

    .btn-close {
        z-index: +999;
    }
</style>




