<template>


    <v-card class="text-center pa-0 mb-0" tile flat :disabled="isDisabled">

        <div class="icon-wrap mx-auto mb-4" v-if="currentStep.icon">
            <v-icon size="50" color="white" class="mx-auto">{{ currentStep.icon }}</v-icon>
            <div class="bg"></div>
        </div>

        <v-card-title class="px-4 px-md-10 pt-0">
            <v-container fluid class="pa-0">
                <v-row>
                    <v-col cols="12">
                        <p :class="`flex__step-header-title ${ vb.smAndUp ? '' : 'font-size-26'}`">{{ heading }}</p>
                    </v-col>
                </v-row>
            </v-container>
                
            <v-spacer/>

            <InviteApplicantToEdit
                v-if="getUserSetting && getUserSetting.b2b == 1"
                :is-flow="true"
                label="Invite Applicant"
                label-type="button" />

            <PortalTarget name="step-header-buttons" class="d-flex ml-2">
            </PortalTarget>

        </v-card-title>

    </v-card>

</template>

<script>

    import InviteApplicantToEdit from '~/components/base/InviteApplicantEditor'
    import { mapState, mapGetters } from 'vuex'

    export default {
        name: 'step-header',
        components: { InviteApplicantToEdit },
        props: {

        },
        computed: {
            ...mapGetters('flows', ['getSteps']),
            ...mapGetters('auth-actions', [
                'getUserSetting'
            ]),
            ...mapState({
                stepableMode: state => state.subscriptions.stepableMode,
                steps: state => state.flows.steps,
                activeStep: state => parseInt(state.flows.activeStep)
            }),
            currentStep(){

                const {steps, activeStep} = this

                let step = parseInt(activeStep)

                return steps[step - 1]

            },
            isDisabled(){
                return this.stepableMode == 'disabled'
            },
            heading(){

                const {steps, activeStep} = this

                if( steps[activeStep - 1] && steps[activeStep - 1].name ) {
                    return steps[activeStep - 1].name
                }

                return ''
            },
            vb() {
                return this.$vuetify.breakpoint
            },
        },
        
    }
</script>