<template>
    <v-card class="ol-income-editor-1" tile flat>
        <v-card-text class="pa-4 pt-0 pa-md-10 pt-md-0" v-if="errorMessages.length == 0">
            <v-container >

                <ValidationObserver ref="observer" tag="form">
                    <v-row>
                        <v-col cols="12">
                            <div class="title py-1">
                                Business Income Details
                            </div>
                        </v-col>
                        <v-col v-for="(field,key) in fields" :key="key" :lg="field.size" :md="field.size" cols="12">
                            <ValidationProvider v-slot="{ errors }" :name="field.label" :rules="field.rules">

                                <ButtonGroup2
                                    v-if="field.type == 'button-group'"
                                    :label="field.label"
                                    v-model="resources.attributes[field.model]"
                                    :large="true"
                                    :options="field.items"
                                    :error-messages="errors"
                                />

                                <v-text-field
                                    v-if="field.type == 'number'"
                                    :type="field.type"
                                    v-model="resources.attributes[field.model]"
                                    :error-messages="errors"
                                    :label="field.label"
                                    prefix="$"
                                >

                                </v-text-field>
                            </ValidationProvider>
                        </v-col>
                    </v-row>
                </ValidationObserver>
            </v-container> 
          
        </v-card-text>
        
    </v-card>
</template>
<script>
import subscriptions from '~/components/shared/subscriptions'
import helpers from '~/plugins/helpers'
import formHelpers from '~/components/shared/form-helpers'
import veeValidate from '~/components/shared/validation'
import { mapState, mapGetters, mapActions, mapMutations } from 'vuex'
import baseComponents from '~/components/base'
export default {
    name:'IncomeEditor1',
    components: {
        ...baseComponents
    },
    mixins: [subscriptions, helpers,formHelpers,veeValidate],
    computed: {
        ...mapState({
            appId: state => state.flows.appId,
        }),
        businessId () {
            if(this.$route?.params && this.$route?.params.record == 'business') {

                return this.$route?.params?.record_id
            }
            this.errorMessages.push('The (business and id) params might be is missing')
            return null

        }

    },
    methods: {
        ...mapActions('resource-actions',['getBusiness','getIncome','updateIncome','createIncome']),
        async pull () {
            if(this.businessId !== null){
                let incomes = {}
                let business  = await this.getBusiness(this.businessId)
                this.incomeIdList = business.data.data.relationships.incomes.data
                if(this.incomeIdList.length > 0){
                    this.incomeId = this.incomeIdList[this.incomeIdList.length - 1].id
                    incomes = await this.getIncome(this.incomeId)
                    this.setIncomeField(incomes.data.data)
                }
            }
        },
        async push () {
           const valid = await this.$refs.observer.validate()
           if(valid){
               await this.saveIncome()
               return  { valid: true, status: 'valid' } 
           }
           return  { valid: false, status: 'dirty' } 
        },
        setIncomeField (data) {
            let item = Object.keys(this.resources.attributes)
            item.forEach(i => {
                this.resources.attributes[i] = data.attributes[i]
            })
            
        },
        saveIncome (){
            
            this.resources.relationships.businesses.data.push({type: 'businesses',id: this.businessId})
            this.resources.relationships.application.data.id = this.appId
            if(this.incomeIdList.length > 0){
                this.resources.id = this.incomeId
                this.updateIncome(this.resources)
            } else {
                if(this.resources.hasOwnProperty('id')){
                    delete this.resources.id  
                }
                this.createIncome(this.resources)
            }
            

        }
    },
    data () {
        return {
            incomeId: '',
            incomeIdList: [],
            fields : [
                {
                    label:'Income Verification Method',
                    model : 'income-verification-method',rules:'required',size:12,
                    items:['Full Documentation','Self Declaration','No Documentation'],type:'button-group'
                },
                {
                    label:'Financial Reporting Period',
                    model : 'reporting-period',rules:'required',size:12,
                    items:['Previous FY','Current FY YTD','Projected'],type:'button-group'
                },
                {
                    label:'What is the annual business revenue?',
                    model : 'revenue',rules:'required',size:4,
                    type:'number',
                },
                {
                    label:'Depreciation and other add backs',
                    model :'depreciation',rules:'required',size:4,
                    type:'number'
                },
                {
                    label:'Annual Profit Before Tax',
                    model :'profit-before-tax-annual',rules:'required',size:4,
                    type:'number'
                },
                
            ],
            resources: {
                type: "incomes",
                attributes: {
                    'income-verification-method': '',
                    'reporting-period': '',
                    'revenue':  '',
                    'depreciation': '',
                    'profit-before-tax-annual':''
                },
                relationships: {
                    application: {
                        data: {
                            type: "applications",
                            id:null
                        }
                    },
                    businesses : {
                        data:[]
                    }
                }
            },
            errorMessages: []
        }
    }
}
</script>