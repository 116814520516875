<template>
    <FlexWrap @resize="handleResize">
        <v-card :disabled="isDisabled || !validComponentData" class="mx-auto" tile flat>
            <v-card-text class="pa-4 pt-0 pa-md-10 pt-md-0">
                <v-card tile flat class="mx-auto" max-width="800">
                    <v-container pa-0 fluid>
                    
                        <ValidationObserver ref="observer" tag="form">
                            <v-row>
                                <v-col cols="12" class="py-0">
                                    <div 
                                        class="button" 
                                        style="font-size: 1.2rem; font-weight: 500;">
                                        When did you move into your current home?
                                    </div>
                                </v-col>
                                
                                <v-col cols="12" sm="6" class="pb-0">                                    
                                    <ValidationProvider 
                                        v-slot="{ errors }" 
                                        :name="'residential situation' | formalText" 
                                        rules="required"
                                        vid="residential-situation">
                                        <v-select
                                            v-model="selectedMonth"
                                            :error-messages="errors"
                                            solo flat
                                            ref="residential-situation"
                                            :items="months"
                                            color="secondary"
                                            :hint="hint.residential_status"
                                            placeholder="Month"
                                            persistent-hint></v-select>
                                    </ValidationProvider>
                                </v-col>

                                <v-col cols="12" sm="6" class="pb-0">
                                    <ValidationProvider 
                                        v-slot="{ errors }" 
                                        :name="'residential-situation' | formalText" 
                                        rules="required"
                                        vid="residential-situation">
                                        <v-select
                                            v-model="selectedYear"
                                            :error-messages="errors"
                                            solo flat
                                            ref="residential-situation"
                                            :items="years"
                                            color="secondary"
                                            :hint="hint.residential_status"
                                            persistent-hint></v-select>
                                    </ValidationProvider>
                                </v-col>

                                <v-col v-if="currentHint" cols="12" class="py-0">
                                    <InfoBox1 hide-avatar>
                                        <template v-slot:text>
                                            {{ currentHint }}
                                        </template>
                                    </InfoBox1>
                                </v-col>
                                <v-col v-if="minimumMonthsOfStay"> 
                                    <ValidationProvider 
                                        v-slot="{ errors }" 
                                        :name="'previous-estimated-term-in-months' | formalText" 
                                        rules="required"
                                        vid="previous-estimated-term-in-months">
                                        <v-text-field
                                            v-model="resourceObj.previous.attributes['estimated-term-in-months']"
                                            :error-messages="errors"
                                            :label="labels['previous-estimated-term-in-months']"
                                            ref="previous-estimated-term-in-months"
                                            color="secondary"
                                            type="number"
                                            suffix="Months"
                                            :hint="hint.previous_term"
                                            persistent-hint></v-text-field>
                                    </ValidationProvider>         
                                </v-col>                            
                            </v-row>
                        </ValidationObserver>
                    </v-container>
                </v-card>
            </v-card-text>
        </v-card>
    </FlexWrap>
</template>

<script>

    import subscriptions from '~/components/shared/subscriptions'
    import FlexWrap from '~/components/base/FlexWrap'
    import InfoBox1 from '~/components/base/InfoBox1'
    import helpers from '~/plugins/helpers'
    import { mapActions, mapGetters } from 'vuex'
    import moment from 'moment'
    
    export default {
        name: 'ResidentialHistoryEditor4',

        components: { FlexWrap, InfoBox1 },

        mixins: [ subscriptions, helpers ],

        created() {
            this.resourceObj = this.resourceObjHelper()
        },

        computed: {
            ...mapGetters('kinds', ['validKindsWithPriorities']),

            activePerson() {
                return this.person
            },

            currentHint() {
                
                const { current } = this.resourceObj

                if ( current.attributes['estimated-term-in-months'] ) {
                    let date = moment().subtract(current.attributes['estimated-term-in-months'], 'months')
                    let year = moment().diff(date, 'years')
                    let month = parseInt(current.attributes['estimated-term-in-months']) - parseInt(year * 12)
                    return `${year} ${year > 1 ? 'years' : 'year'} and ${month} ${month > 1 ? 'months' : 'month'}`
                }
                return false
            },
            minimumMonthsOfStay() {
                let { current } = this.resourceObj
                return parseInt(current?.attributes?.['estimated-term-in-months']) < 36 ? true : false
            }
        },

        methods: {

            ...mapActions('resource-actions', [
                'getOccupanciesData',
                'saveOccupancies'
            ]),

            /**
             * Calling actions: getOccupanciesData 
             * from store which trigger an axios call,
             * pulling all data from an api response
             */
            async pull() {
                
                let response = await this.getOccupanciesData()
                const now = new Date().getUTCFullYear()
                
                this.months = moment.months()
                this.years = Array(now - (now - 100)).fill('').map((v, idx) => now - idx)

                // this.person = this.getPriorityParts( response.data )
                this.person = this.getPrimaryApplicant(response.data)
                
                this.validComponentData = this.validKindsWithPriorities
                
                if (response.data.included && response.data.included.length > 0) {   
                    this.resourceObj = this.resourceObjHelper(response.data, response.data.data)
                }
                
                this.resources = []
                this.resources[0] = this.resourceObj.current
                this.resources[1] = this.resourceObj.previous

                this.emptiedResources(this.validComponentData ? false : true)
                
                if ( this.activePerson?.id )
                    this.resourceObj = this.attachPersonRelationships(this.resourceObj, this.activePerson, 'occupancies', 'person')

                this.prepareData()
                
            },            

            /**
             * Calling actions: saveOccupancies 
             * from store which trigger an axios call,
             * saving/updating all data in DB from local resource
             */
            async push() {

                const valid = await this.$refs.observer.validate()
                const observer = this.$refs.observer
                const touched = this.$refs.observer.flags.touched

                // Getter getActiveStepStatus from: shared/subscriptions.js
                let status = this.getActiveStepStatus.attributes.value 

                if(this.hardValidation) {
                    status =  valid ? 'valid' : 'dirty'
                } else {
                    // if(touched) { status =  valid ? 'valid' : 'dirty' }
                    status =  valid ? 'valid' : 'dirty'
                }

                this.request_data = []
                
                this.resources.forEach( f => {
                    f.attributes = this.nullifyInvalids(f.attributes)
                })

                let res = await this.saveOccupancies( 
                    this.filterResourceWithoutTheseRelationships(['occupancies'], this.request_data) 
                )  
                
                if (this.isAllPromisesValid(res)) {
                    
                    let data = this.$options.filters.toAppDataFormat(res)

                    // await this.pull()
                    this.resourceObj = this.fetchNewlyUpdatedRecord( res, this.resources, this.fields, 'occupancy-situation' )
                    
                    return  { valid, status, data } 
                }
                else {
                    return  { valid, status } 
                }

            },

            /**
             * HELPERS:
             * NOTE: Can also be overrided if a specific helper will be implemented
             */
            nullifyInvalids(fields = {}) {
                
                let { request_data } = this
                let sortedFields = {}
                
                Object.keys(fields).forEach( field => {
                    
                    if(fields[field] || typeof fields[field] == 'boolean') {
                        sortedFields[field] = fields[field]
                    }
                    else {
                        sortedFields[field] = "" 
                    }
                    
                })

                let temp = {}

                if (sortedFields['occupancy-situation'] === 'Current') {
                    if ( (sortedFields['estimated-term-in-months']) || sortedFields['estimated-term-in-months'].length) {
                        
                        temp = Object.assign({}, this.resourceObj.current)
                        
                        Object.keys(temp.attributes).forEach( f => {
                            if (temp.attributes[f] === '' || temp.attributes[f] === null) {
                                delete temp.attributes[f]
                            }
                        })
                        
                        request_data.push( temp )
                    }
                }
                else if (sortedFields['occupancy-situation'] === 'Previous') {
                    if ( sortedFields['estimated-term-in-months'].length ) {
                        
                        temp = Object.assign({}, this.resourceObj.previous)
                        
                        Object.keys(temp.attributes).forEach( f => {
                            if (temp.attributes[f] === '' || temp.attributes[f] === null)
                                delete temp.attributes[f]
                        })

                        request_data.push( temp )
                    }
                }

                return sortedFields

            },

            /**
             * Fetching API response data for compilation
             * from API/axios call
             */
            resourceObjHelper(resources, parentData) {
                
                let obj = {}
                let collection = []

                /**
                 * IF fetched data is not empty:
                 * Data are fetch from the API and is compiled for interaction
                 */                
                if (parentData && this.activePerson?.relationships) {
                    
                    if ( this.activePerson?.relationships?.child ) {
                        collection = resources.included.filter( f => {
                            return f.type === 'occupancies' && ( f.relationships.person && JSON.stringify(f.relationships.person.data) === JSON.stringify(this.activePerson.relationships.child.data ) )
                        })
                    }
                    else {
                        collection = resources.included.filter( f => {                                
                            return f.type === 'occupancies' && ( f.relationships.person && f.relationships.person.data.id === this.activePerson.id )
                        })                   
                    }

                    if (collection.length) obj = this.compileResources( collection )
                    else obj = this.compileResources(this.resources)
                }

                /**
                 * ELSE: This will use component data as default
                 */
                else {
                    obj = this.compileResources(this.resources)
                }

                return obj

            },

            emptiedResources( isInvalid = false ) {
                this.resources.forEach( res => {
                    let temp = {}

                    Object.keys(this.fields).forEach( f => {
                        if ( f !== 'occupancy-situation') temp[f] = isInvalid ? null : res?.attributes[f]
                        else temp[f] = res?.attributes[f]
                    })

                    res.attributes = {}
                    res.attributes = temp
                    if (isInvalid) res.id = null
                })
            },

            compileResources(resObj) {
                
                let { resources } = this
                let sortedObj = resObj.sort( ( a, b ) => ( parseInt(a.id) > parseInt(b.id) ) ? 1 : -1 )
                let obj = {}

                /**
                 * Filter resources for CURRENT and PREVIOUS incomes
                 */
                obj.current = this.peopleResourceWithInclusion(sortedObj, 'occupancy-situation', 'Current')
                obj.previous = this.peopleResourceWithInclusion(sortedObj, 'occupancy-situation', 'Previous')
                
                /**
                 * If only CURRENT is empty
                 */
                if ( Object.keys(obj.previous).length > 0 &&  Object.keys(obj.current).length === 0 ) {
                    // this.validComponentData = false
                    obj.current =  this.getLocalResource('get', 'Current')[0]
                }

                /**
                 * If only PREVIOUS is empty
                 */
                else if ( Object.keys(obj.current).length > 0 &&  Object.keys(obj.previous).length === 0 ) {
                    obj.previous = this.getLocalResource('get', 'Previous')[0]
                }

                return obj

            },

            getLocalResource(action = '', keyword = '') {

                let filtered

                if ( action === 'get' ) {
                    filtered = this.resources.filter( res => {
                        return res.attributes['occupancy-situation'] === keyword
                    })
                }

                if ( action === 'set' ) {}

                return filtered
            },

            prepareData() {
                
                const { current, previous } = this.resourceObj
                
                if (current.attributes['estimated-term-in-months']) {
                    this.selectedMonth = moment().subtract(current.attributes['estimated-term-in-months'], 'months').format('MMMM')
                    this.selectedYear = parseInt(moment().subtract(current.attributes['estimated-term-in-months'], 'months').format('YYYY'))
                }                
            }

        },

        watch: {

            'resourceObj.current.attributes': {
                handler(val) {
                    if ( val['estimated-term-in-months'] != null && val['estimated-term-in-months'] != '' ) {
                        let term = this.subtractDateBy('months', this.resourceObj.current.attributes['estimated-term-in-months'], 'MMMM YYYY')
                        this.hint['current-term'] = `Current residential term is from ${term} to present.`
                        this.totalYears = Number(this.resourceObj.current.attributes['estimated-term-in-months']) + Number(this.resourceObj.previous.attributes['estimated-term-in-months'])
                    }
                }, 
                deep: true
            },

            'resourceObj.previous.attributes': {
                handler(val) {
                    this.totalYears = Number(this.resourceObj.current.attributes['estimated-term-in-months']) + Number(this.resourceObj.previous.attributes['estimated-term-in-months'])
                    this.validYears = this.resourceObj.previous.attributes['estimated-term-in-months'] ? true : false
                }, 
                deep: true
            },

            'totalYears': {
                handler(val) {
                    let end_term = this.subtractDateBy('months', this.resourceObj.current.attributes['estimated-term-in-months'], 'MMMM YYYY')
                    let start_term = this.subtractDateBy('months', this.totalYears, 'MMMM YYYY')
                    this.hint['previous-term'] = `Previous residential term is from ${start_term} to ${end_term}.`
                }, deep: true
            },

            selectedMonth() {
                const { current } = this.resourceObj
                let date = moment().subtract(current.attributes['estimated-term-in-months'], 'months')
                let newDate = date.month(this.selectedMonth)
                current.attributes['estimated-term-in-months'] = moment().diff(newDate, 'months')
            },

            selectedYear() {
                const { current } = this.resourceObj
                let date = moment().subtract(current.attributes['estimated-term-in-months'], 'months')
                let newDate = date.year(this.selectedYear)
                current.attributes['estimated-term-in-months'] = moment().diff(newDate, 'months')
            },

           "validComponentData": {
                handler(val) {
                    if ( !this.validComponentData ) {

                        this.showComponentWarning()

                    }
                },
                deep: true
            }

        },

        data(){

            return {
                person: {},
                request_data: [],
                validComponentData: true,
                resources: [
                    {
                        "type": "occupancies",
                        "id": null,
                        "relationships": {
                            "application": {
                                "data": {
                                    "type": "applications",
                                    "id": null
                                }
                            },
                            "parent-parts": {
                                "data": [
                                    {
                                        "type": "parts",
                                        "id": null
                                    }
                                ]
                            },
                            "person": {
                                "data": {
                                    "type": "people",
                                    "id": null
                                }
                            }
                        },
                        "attributes": {
                            "residential-situation": null,
                            "estimated-term-in-months": null,
                            "start-date": null,
                            "end-date": null,
                            "occupancy-situation": "Current"
                        }
                    },
                    {
                        "type": "occupancies",
                        "id": null,
                        "relationships": {
                            "application": {
                                "data": {
                                    "type": "applications",
                                    "id": null
                                }
                            },
                            "parent-parts": {
                                "data": [
                                    {
                                        "type": "parts",
                                        "id": null
                                    }
                                ]
                            },
                            "person": {
                                "data": {
                                    "type": "people",
                                    "id": null
                                }
                            }
                        },
                        "attributes": {
                            "residential-situation": null,
                            "estimated-term-in-months": null,
                            "start-date": null,
                            "end-date": null,
                            "occupancy-situation": "Previous"
                        }
                    }
                ],
                fields: {
                    "residential-situation": "",
                    "estimated-term-in-months": "",
                    "start-date": "",
                    "end-date": "",
                    "occupancy-situation": ""
                },
                labels: {
                    "residential-situation": 'Residential Situation',
                    "current-estimated-term-in-months": 'Time at Current Residence',
                    "previous-estimated-term-in-months": 'How long did you live at your previous address?'
                },
                validation_rules: {
                    types: 'occupancies',
                    attributes: {
                        "residential-situation": 'required|in:Boarder,Employer Provided,Living With Parents,Mortgage - Owner Occupied,Own Outright,Renter - Agent,Renter - Private,Mortgage,Renter Agent,Renter Private',
                        "current-estimated-term-in-months": 'required|integer',
                        "previous-estimated-term-in-months": 'required|integer',
                    }
                },
                // situation: ['Boarder', 'Employer Provided', 'Living With Parents', 'Mortgage - Owner Occupied', 'Own Outright', 'Renter - Agent', 'Renter - Private'],
                months: [],
                years: [],
                selectedMonth: '',
                selectedYear: '',
                totalYears: 0,
                validYears: false,
                resourceObj: {},
                hint: {
                    "residential-status": '',
                    "current-term": '',
                    "previous-term": ''
                }
            }

        }

    }
</script>

<style lang="scss" scoped>
    .flex-form {
        display: grid;
        grid-column-gap: 15px;
        grid-row-gap: 0;
        grid-template-columns:  repeat(auto-fit, minmax(260px, 1fr));

        .flex-field {
            align-self: start;
        }
    }
</style>