<template>
    <v-card flat class="mx-auto" :disabled="isDisabled">
        <v-card-text class="pa-0 px-10">
            <ValidationObserver ref="observer" tag="form">

                <div class="flex-form" >

                    <template v-for="(field, key) in resources.purchase.attributes">
                        <div v-if="isVisible(key)" class="flex-field" :key="key">
                            <ValidationProvider 
                                v-slot="{ errors }" 
                                :name="labels[key]"
                                :rules="getRules(key)" 
                                :vid="key">

                                <FlexDatePicker
                                    v-if="isDatePicker(key)"
                                    :label="labels[key]"
                                    :errors="errors"
                                    v-model="resources.purchase.attributes[key]">
                                </FlexDatePicker>

                                <v-select
                                    v-else-if="isFieldType('picklist', key)"
                                    v-model="resources.purchase.attributes[key]"
                                    :items="getOptions(key)"
                                    color="secondary"
                                    :error-messages="errors"
                                    :label="labels[key]">
                                </v-select>

                                <v-text-field 
                                    v-else
                                    :label="labels[key]"
                                    color="secondary"
                                    :error-messages="errors"
                                    v-model="resources.purchase.attributes[key]">
                                </v-text-field>

                            </ValidationProvider>
                        </div>
                    </template>
                    
                </div>
                    
            </ValidationObserver>
        </v-card-text>
    </v-card>
</template>
<script>
    import { mapActions, mapState, mapMutations } from 'vuex'
    import helpers from '~/plugins/helpers'
    import subscriptions from '~/components/shared/subscriptions'
    import FlexDatePicker from '~/components/base/FlexDatePicker'
    import fieldOptions from '~/components/shared/field-options'
    import { isEmpty } from 'lodash'

    export default {
        name: "LenderPepperSecurity1",
        components: { FlexDatePicker },
        mixins: [ helpers, subscriptions ],
        data() {
            return {
                purchase: null,
                assets: [],
                resources: {
                    purchase: {
                        attributes: {
                            "vin": null,
                            "hin": null,
                            "engine-number": null,
                            "registration-number": null,
                            "registration-state": null,
                            "odometer": null,
                            "compliance-date": null,
                            "colour": null
                        }
                    }
                },
                visible: {
                    "hin": false,
                    "vin": true,
                },
                labels: {
                    "vin": "VIN No",
                    "hin": "HIN No",
                    "engine-number": "Engine No",
                    "registration-number": "Registration No",
                    "registration-state": "Registration State",
                    "odometer": "Odometer",
                    "compliance-date": "Compliance Date",
                    "colour": "Colour"
                },
                validation_rules: {
                    attributes: {
                        "vin": "string|valid_vin",
                        "hin": "string",
                        "engine-number": "string",
                        "registration-number": "string",
                        "registration-state": `|in:${fieldOptions["drivers-licence-state"]}`,
                        "odometer": "integer",
                        "compliance-date": "string|date_format:Y/m/d|before:now",
                        "colour": "string"
                    }
                }
            }
        },
        computed: {
            ...mapState({
                appId: state => state.flows.appId
            }),
            motorVehicleAsset() {
                let motor = null
                let { assets } = this

                if(assets.length > 0) {
                    assets.forEach(asset => {
                        if(asset.relationships.kind.data.id == '10-33') {
                            motor = asset
                        }
                    })
                }

                return motor
            }
        },
        methods: {
            ...mapActions('resource-actions', [
                'getPurchases',
                'updatePurchases',
                'getAssets',
                'createAsset',
            ]),
            async pull() {

                try {

                    let res = await this.getPurchases()
                    let res2 = await this.getAssets()

                    if(res.status === 200) {
                        this.purchase = res.data.data[0]

                        await this.populateFields()
                    }

                    if(res2.status === 200) {
                        this.assets = res2.data.data
                    }

                } catch (err) {
                    console.log(err)
                }

            },
            async push() {

                try {

                    let res = null

                    if(this.purchase) {

                        res = await this.updatePurchases({
                            "id": this.purchase.id,
                            "type": "purchases",
                            "attributes": {
                                ...this.resources.purchase.attributes
                            }
                        })

                        if(res.status === 200) {

                            // if no motorvehicle asset then create one
                            if(isEmpty(this.motorVehicleAsset)) {
                                await this.createAssets()
                            }

                        }

                    }

                    const {valid, status} = await this.validateFormData()

                    return {valid, status, data: {}}

                } catch (err) {
                    console.log(err)
                }

            },
            async createAssets() {

                try {

                    const payload = {
                        type: "assets",
                        attributes: {},
                        relationships: {
                            application: {
                                data: {type: "applications", id: this.appId}
                            },
                            kind: {
                                data: {
                                    type: 'kinds',
                                    id: '10-33'
                                }
                            }
                        }
                    }

                    await this.createAsset(payload)

                } catch (err) {
                    console.log(err)
                }

            },
            populateFields() {

                let { purchase, resources } = this

                if(purchase) {

                    Object.keys(resources.purchase.attributes).forEach(key => {
                        resources.purchase.attributes[key] = purchase.attributes[key]
                    })

                    // Boat (Moored), Boat (Trailered), Jetski then show hin
                    if(purchase.relationships.kind.data.id == '9-4' || purchase.relationships.kind.data.id == '9-5' || purchase.relationships.kind.data.id == '9-9') {
                        this.visible['hin'] = true
                        this.visible['vin'] = false
                    }

                }

            },
            isDatePicker (key) {
                return [
                    "compliance-date"
                ].includes(key)
            },
            isVisible(key){
                
                const {visible} = this
    
                if(visible.hasOwnProperty(key)){
                    return visible[key]
                }
    
                return true
    
            },
        }
    }
</script>
<style lang="scss" scoped>
    .flex-form {
        display: grid;
        grid-column-gap: 50px;
        grid-row-gap: 0;
        grid-template-columns:  repeat(auto-fit, minmax(260px, 1fr));

        .flex-field {
            align-self: start;
        }
    }

</style>