<template>
    <v-card :disabled="isDisabled || !validComponentData" class="mx-auto" tile flat>
        <v-card-text class="pa-4 pt-0 pa-md-10 pt-md-0">
            <v-container pa-0 fluid>
                <v-row>
                    <v-col cols="12">
                        <ValidationObserver ref="observer" tag="form">

                            <div class="flex-form" v-for="layout in fieldLayout">
                                <template v-for="(field, key) in fieldLayoutMaker(layout, resources.people.attributes)">
                                    <div class="flex-field":key="key">
                                        <ValidationProvider 
                                            v-slot="{ errors }" 
                                            :name="labels[key]" 
                                            :class="[{'d-none': !visible[key]}]"
                                            :rules="getRules(key)" :vid="key">

                                            <ButtonGroup1 
                                                v-if="isBtnGroup(key)"
                                                v-model="resources.people.attributes[key]"
                                                :error-messages="errors"
                                                :options="['Male', 'Female']"
                                                :label="labels[key]" />

                                            <v-select
                                            v-else-if="isFieldType('picklist', key)"
                                            :items="getOptions(key)"
                                            :label="labels[key]"
                                            :error-messages="errors"
                                            v-model="resources.people.attributes[key]"
                                            color="secondary">
                                            </v-select>
                                            
                                            <template v-else-if="isFieldType('date', key)">
                                            <flex-date-picker
                                                :label="labels[key]"
                                                :errors="errors"
                                                v-model="resources.people.attributes[key]"
                                            ></flex-date-picker>
                                            </template>

                                            <v-text-field 
                                            v-else
                                            :type="'text'"
                                            :label="labels[key]"
                                            :error-messages="errors"
                                            v-model="resources.people.attributes[key]"
                                            color="secondary">
                                            </v-text-field>
                                        </ValidationProvider>
                                    </div>
                                </template>
                                <!-- Temp Spacer -->
                                <div class="flex-field" v-for="index in 6" :key="index"></div>
                            </div>

                        </ValidationObserver>
                    </v-col>
                </v-row>
            </v-container>
        </v-card-text>

        <!-- This will replace Next button in StepIncrementer1  -->
        <Portal to="step-incrementer-next">
            <v-btn color="secondary" large depressed nuxt :min-width="btnWidth" class="ml-3" @click="openTermsModal" :disabled="processing">Next</v-btn>
        </Portal>

        <ConfirmModal 
            async
            color="secondary"
            title="Terms and Conditions"
            confirmText="I Accept"
            :value="dialog"
            :standalone="false"
            :processing="processing"
            :message="`I accept <a href='#'><strong>terms and conditions</strong></a>`"
            @close="handleClose"
            @confirm="selectNextStep" />

    </v-card>
</template>

<script>

    import subscriptions from '~/components/shared/subscriptions'
    import { mapState, mapGetters, mapActions, mapMutations } from 'vuex'
    import FlexDatePicker from '~/components/base/FlexDatePicker'  
    import ConfirmModal from '~/components/base/ConfirmModal2'
    import ButtonGroup1 from '~/components/base/ButtonGroup1'
    import baseComponents from '~/components/base'
    import { getDateValidations } from '~/plugins/date-helper.js'
    const { maxAgeDate, minAgeDate } = getDateValidations()

    export default {
        components: {
            FlexDatePicker,
            ConfirmModal,
            ButtonGroup1
        },
        mixins: [subscriptions],
        data(){
            return {
                dialog: false,
                resources: {
                    people : {
                        type: "people",
                        id: null,
                        attributes: {
                            "first-name": "",
                            "middle-name": "",
                            "last-name": "",
                            "date-of-birth": "",
                            "gender": "",
                            "drivers-licence-number": "",

                            "credit-status": "",
                            "previous-bankrupt": "",
                            "comprehensive-credit-score": "",
                        },
                        relationships: {
                            application: {
                                data: {type: "applications", id: null}
                            }
                        }
                    }
                },
                fieldLayout: [
                    [
                        "first-name",
                        "middle-name",
                        "last-name"
                    ],
                    [
                        "date-of-birth",
                        "gender",
                        "drivers-licence-number"
                    ],
                    [
                        "credit-status",
                        "previous-bankrupt",
                        "comprehensive-credit-score"
                    ]
                ],
                visible: {
                    "first-name": true,
                    "middle-name": true,
                    "last-name": true,
                    "date-of-birth": true,
                    "gender": true,
                    "drivers-licence-number": true,
                    "credit-status": false,
                    "previous-bankrupt": false,
                    "comprehensive-credit-score": false,
                },
                // Static field labels. cause sometimes field names and labels are different
                labels: {
                    "first-name": "First Name",
                    "middle-name": "Middle Name",
                    "last-name": "Last Name",
                    "date-of-birth": "Date of Birth",
                    "gender": "Gender",
                    "drivers-licence-number": "Drivers Licence Number",
                    "comprehensive-credit-score": "Comprehensive Credit Score",
                    "previous-bankrupt": "Previous Bankrupt",
                    "credit-status": "Credit History",
                },
                validation_rules: {
                    type: "people",
                    attributes: {
                        "first-name": "required|string|max:200",
                        "middle-name": "required|string|max:200",
                        "last-name": "required|string|max:200",
                        "date-of-birth": `string|date_format:Y/m/d|before:${minAgeDate}|after:${maxAgeDate}`,
                        "gender": "required|string|in:Male,Female",
                        "drivers-licence-number": "required|string|max:200",
                        "comprehensive-credit-score": "string|max:200",
                        "previous-bankrupt": "string|in:Yes,No",
                        "credit-status": "string|max:100|in:No Infringements,Paid Infringements,Unpaid Infringements",
                    }
                },
                validComponentData: true
            }
        },
        computed: {
            ...mapState({
                appId: state => state.flows.appId
            }),
            ...mapGetters('kinds', [
                'kindsOfApplicant'
            ]),
            ...mapGetters('flows', ['getPrevStep', 'getNextStep']),
            hasPeople(){
                return this.appData.relationships.people.data.length > 0
            },
            processing(){
                return !this.getNextStep || this.isDisabled
            },
            btnWidth () {
                switch (this.$vuetify.breakpoint.name) {
                    case 'xs': return 0
                    case 'lg': 
                    case 'xl':
                    case 'md': 
                    case 'sm': return 140
                }
            }
        },
        created() {

        },
        mounted(){

            if(!this.hasPeople){
                // Error...
                this.validComponentData = false
            }

        },
        methods: {
            ...mapActions('resource-actions', [
                'getPeople',
                'updatePerson',
                'createPerson'
            ]),
            ...mapActions('flows', ['selectNextStep']),
            async pull() {

                let res = await this.getPeople({"include" : 'parent-parts'})

                let priority = this.getPrimaryApplicant(res.data)

                if(priority){
                    
                    // Object.assign(this.resources.people, res.data)
                    this.resources.people = this.resourceFieldPicker(this.resources.people, priority)

                    // Set default values
                    this.resources.people.attributes["credit-status"] = "No Infringements"
                    this.resources.people.attributes["previous-bankrupt"] = "No"
                    this.resources.people.attributes["comprehensive-credit-score"] = "900"

                } else {

                    // Error...
                    this.validComponentData = false
                }

            },
            handleClose(){
                this.dialog = false
            },
            async openTermsModal() {

                const {valid, status} = await this.validateFormData()

                if(valid){
                    this.dialog = true
                }

            },
            async push() {

                const observer = this.$refs.observer
     
                const {people} = this.resources

                // Exclude relationships and other stuff
                let payload = {
                    type: people.type,
                    id: people.id,
                    attributes: this.excludeInvalids(people.attributes, observer.fields)
                }

                let personRes = this.updatePerson(payload)

                const {valid, status} = await this.validateFormData()
                const data = await this.appDataHelper([personRes])

                return {valid, status, data}

            },
            getPrimaryApplicant(data){
                
                /**
                 * 1. check if data has included
                 * 2. filter included for 'parts'
                 * 3. select parts with kinds 'applicant'
                 * 4. select person based on selected part
                 */

                if(!data.included){
                    return null;
                }

                let parts = data.included.filter(part => {
                    return part.type == 'parts'
                })

                let applicants = [];	
                let primaryApplicant = null 

                parts.forEach((part) => {
                    
                    if (!part.relationships.kind.data) {	
                        // person must have part with kind
                        return null
                    }
                    
                    if (part.relationships.kind.data.id == this.kindsOfApplicant.id) {
                        applicants.push(part);
                    }
                })

                if (applicants.length == 0) {

                    // no applicant
                    return null

                } else if (applicants.length == 1) {
                    
                    if (applicants[0].attributes.priority == "1") {

                        primaryApplicant = applicants[0];

                    } else {

                        // primary applicant must be priority 1
                        return null
                        
                    }

                } else if (applicants.length > 1) {
                    
                    let primaryApplicants = applicants.filter((applicant) => {
                        return applicant.attributes.priority == "1"
                    })
                    
                    if( primaryApplicants.length != 1) {	

                        // too many, or too few primary applicants (must be 1 and only 1)
                        return null
                        
                    } else {		

                        primaryApplicant = primaryApplicants[0];
                    }

                }

                let applicant = data.data.find( person => {
                    if(person.relationships['parent-parts'].data[0]){
                        return person.relationships['parent-parts'].data[0].id == primaryApplicant.id
                    }
                })

                // Success!
                return applicant 
            
            },
            isBtnGroup(key){

                let keys = [
                    "gender"
                ]

                return keys.includes(key)

            }
        },
        watch: {
            validComponentData(val){
                if(!val) {
                    this.showComponentWarning()
                }
            },
        }
    
    }
</script>

<style scoped lang="scss">
</style>

