<template>
    <v-container class="flex__sub-component-Radio3">

        <div class="body-1 text-center font-weight-medium" style="font-size: 1.1rem !important;">{{ label }}</div>

            <v-item-group v-model="selected">
                <v-row align="center" justify="center">
                    <v-card outlined flat width="100%" class="d-flex rounded-pill justify-space-between">
                        <div
                            v-for="(n, key) in options"
                            :key="key"
                            class="pa-2 ma-0">

                            <v-item v-slot="{ active, toggle }">
                                <v-alert 
                                    @click="toggled(toggle, !active, n)" 
                                    :color="`${active ? 'secondary' : errors[0] ? 'error' : 'light_gray_1'}`" 
                                    class="rounded-pill pa-0 ma-0">
                                    <v-card
                                        :class="`select-pill align-center rounded-pill ${active ? 'secondary' : ''}`"
                                        flat>                            
                                        <v-card-text class="px-8">
                                            <div :class="`${active ? 'white--text' : 'black--text'} body-1`">{{ n.text }}</div>
                                        </v-card-text>                                
                                    </v-card>
                                </v-alert>
                            </v-item>
                            
                        </div>
                    </v-card>
                </v-row>
            </v-item-group>


            <div class="with-dynamic-validations caption text-center pt-1">
                {{ errors[0] }}
            </div>


    </v-container>
</template>

<script>
    export default {
        name: 'Radio3',
        props: {
            options: {
                type: Array,
                default: () => []
            },
            errors: {
                type: Array,
                default: () => []
            },
            label: {
                type: String,
                dafault: ''
            }
        },
        data() {
            return {
                selected: []
            }
        },
        methods: {
            toggled(toggle, active, item) {
                toggle()
                this.$emit('input', active ? item.text : '')
            }
        }
    }
</script>

<style lang="scss" >
    .flex__sub-component-Radio3 {

        .v-alert, .v-card {
            // min-height: 77px !important;
            width: 100%;
        }

        .v-card__text {
            // min-height: 77px !important;
            // font-size: 1.375rem !important;
        }

        .select-pill {
            cursor: pointer !important;
        }

    }
</style>