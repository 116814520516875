<template>
    <v-card class="mx-auto flex__step-incrementer" tile flat>
        <v-card-text class="px-4 px-md-6 pt-6 pt-md-6">
            <v-container pa-0 fluid>
            <v-row>
                <v-col class="pt-0 pb-0 d-flex justify-end">
                    <!-- if has settings -->

                    <Button1 v-if="getPrevStepButton" 
                        :settings="getPrevStepButton" 
                        :disabled="isDisabled"
                        outlined
                        size="large"
                        :min-width="btnWidth"
                        color="secondary">
                        <template v-slot:content>
                            {{getPrevStepButton.label}}
                        </template>
                    </Button1>

                    <v-btn v-else color="secondary"
                        :min-width="btnWidth"
                        outlined large depressed nuxt
                        @click="selectPrevStep"
                        :disabled="!getPrevStep || isDisabled">
                            Back
                    </v-btn>

                    <v-spacer></v-spacer>

                    <PortalTarget name="step-incrementer-next">

                        <Button1 v-if="getNextStepButton" 
                            :settings="getNextStepButton" 
                            :disabled="isDisabled"
                            data-btn="b2b-flow-next"
                            size="large"
                            :min-width="btnWidth"
                            color="secondary">
                            <template v-slot:content>
                                {{getNextStepButton.label}}
                            </template>
                        </Button1>

                        <v-btn 
                            v-else
                            color="secondary" 
                            data-btn="b2b-flow-next"
                            :min-width="btnWidth" large depressed nuxt 
                            class="ml-3" 
                            @click="handleNext" 
                            :disabled="!getNextStep || isDisabled">
                                Next
                        </v-btn>
                    </PortalTarget>

                </v-col>
            </v-row>
            </v-container>
        </v-card-text>

        <ConfirmModal 
            async
            color="warning"
            title="Invalid or Missing Data"
            confirmText="Continue"
            :value="invalidDataDialog"
            :standalone="false"
            :processing="processing"
            message="There is still required data that is invalid or missing on this page. Please click continue to move to the next step, or click cancel to return to this page."
            @close="toggleInvalidDataDialog(false)"
            @confirm="confirmInvalidDataDialog" />

        <ConfirmModal 
            async
            color="warning"
            title="Confirm Client Details"
            confirmText="Continue"
            :value="confirmDetailsDialog"
            :standalone="false"
            :processing="processing"
            message="It appears that you haven't changed anything on this step for this copied loan application. Please make sure all details are up to date before you continue."
            @close="toggleConfirmDetailsDialog(false)"
            @confirm="confirmConfirmDetailsDialog" />

        <ConfirmModal 
            async
            color="warning"
            title="Incomplete Steps"
            :showCancel="false"
            confirmText="Close"
            :value="incompleteStepsDialog"
            :standalone="false"
            message="Please complete previous steps to proceed."
            @confirm="toggleIncompleteStepsDialog(false)"/>

    </v-card>
</template>

<script>

    import { mapState, mapGetters, mapActions, mapMutations } from 'vuex';
    import subscriptions from '~/components/shared/subscriptions'
    import ConfirmModal from '~/components/base/ConfirmModal2'
    import stepStates from '~/components/shared/step-states' // Consider placing this in subscriptions
    import Button1 from '~/components/base/Button1'


    export default {
        mixins: [subscriptions, stepStates],
        components: {
            ConfirmModal,
            Button1
        },
        data(){
            return {

            }
        },
        computed: {
            ...mapGetters('flows', ['getPrevStep', 'getNextStep']),

            ...mapState('dialogs', [
                    'invalidDataDialog', 
                    'confirmDetailsDialog', 
                    'incompleteStepsDialog', 
                    'processing'
            ]),
            btnWidth () {
                let size = 0

                switch (this.$vuetify.breakpoint.name) {
                    case 'xs':
                        size = 0
                    case 'sm':
                        size = 140
                    default:
                        size = 140                
                }

                return size
            },
            subComponents(){

                const {blockSettings} = this
                
                let components = []
                
                if(blockSettings && blockSettings.hasOwnProperty('subComponents')) {
                    return blockSettings.subComponents
                }

                return components
            },
            getPrevStepButton(){

                let componentSetting = null

                this.subComponents.forEach(component => {
                    if(component.name == "PrevStepButton") {
                        componentSetting = component
                    }
                })

                return componentSetting
            },
            getNextStepButton(){

                let componentSetting = null

                this.subComponents.forEach(component => {
                    if(component.name == "NextStepButton") {
                        componentSetting = component
                    }
                })

                return componentSetting

            },
        },
        mounted() {

            // console.log(this.blockSettings)

        },
        methods: {
            ...mapMutations('flows', [
                'setAppID'
            ]),

            ...mapActions('flows', ['selectNextStep', 'selectPrevStep', 'saveDataOnly']),

            ...mapMutations('subscriptions', [
                'setGlobalMode',
            ]),

            ...mapActions('dialogs', [
                    'evaluateSteps',
                    'confirmInvalidDataDialog',
                    'confirmConfirmDetailsDialog'
                ]),

            ...mapMutations('dialogs', [
                    'toggleInvalidDataDialog',
                    'toggleConfirmDetailsDialog', 
                    'toggleIncompleteStepsDialog', 
                ]),
            
            handleNext(){

                /**
                 * This will trigger Veevalidate's form observer
                 */
                this.toggleFormFieldsObserver().then(() => {
                    this.evaluateSteps()
                })

            },
        }
    }
</script>

<style scoped lang="scss">

</style>