var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"mx-auto",attrs:{"tile":"","flat":""}},[_c('v-card-text',{staticClass:"pa-4 pt-0 pa-md-10 pt-md-0"},[_c('ValidationObserver',{ref:"observer",attrs:{"tag":"form"}},[_c('div',{staticClass:"flex-form"},[_c('div',{staticClass:"flex-field"},[_c('ValidationProvider',{attrs:{"name":_vm.labels["requested-broker-fee"],"rules":_vm.getRules("requested-broker-fee"),"vid":"requested-broker-fee"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('NodifiMoney',{attrs:{"label":_vm.labels["requested-broker-fee"],"errors":errors},model:{value:(_vm.resources.loan.attributes["requested-broker-fee"]),callback:function ($$v) {_vm.$set(_vm.resources.loan.attributes, "requested-broker-fee", $$v)},expression:"resources.loan.attributes[`requested-broker-fee`]"}})]}}])})],1),_vm._v(" "),_c('div',{staticClass:"flex-field"},[_c('ValidationProvider',{attrs:{"name":_vm.labels["requested-term-months"],"rules":_vm.getRules("requested-term-months"),"vid":"requested-term-months"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"items":_vm.getOptions("requested-term-months"),"error-messages":errors,"color":"secondary","label":_vm.labels["requested-term-months"]},model:{value:(_vm.resources.loan.attributes["requested-term-months"]),callback:function ($$v) {_vm.$set(_vm.resources.loan.attributes, "requested-term-months", $$v)},expression:"resources.loan.attributes[`requested-term-months`]"}})]}}])})],1),_vm._v(" "),_c('div',{staticClass:"flex-field"},[_c('ValidationProvider',{attrs:{"name":_vm.labels["customer-discount-rate"],"rules":_vm.getRules("customer-discount-rate"),"vid":"customer-discount-rate"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":_vm.labels["customer-discount-rate"],"error-messages":errors,"color":"secondary"},model:{value:(_vm.resources.loan.attributes["customer-discount-rate"]),callback:function ($$v) {_vm.$set(_vm.resources.loan.attributes, "customer-discount-rate", $$v)},expression:"resources.loan.attributes[`customer-discount-rate`]"}})]}}])})],1),_vm._v(" "),_c('div',{staticClass:"flex-field"},[_c('ValidationProvider',{attrs:{"name":_vm.labels["bank-account-name"],"rules":_vm.getRules("bank-account-name"),"vid":"bank-account-name"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":_vm.labels["bank-account-name"],"error-messages":errors,"color":"secondary"},model:{value:(_vm.resources.loan.attributes["bank-account-name"]),callback:function ($$v) {_vm.$set(_vm.resources.loan.attributes, "bank-account-name", $$v)},expression:"resources.loan.attributes[`bank-account-name`]"}})]}}])})],1)]),_vm._v(" "),_c('div',{staticClass:"flex-form"},[_c('div',{staticClass:"flex-field"},[_c('ValidationProvider',{attrs:{"name":_vm.labels["bank-bsb-number"],"rules":_vm.getRules("bank-bsb-number"),"vid":"bank-bsb-number"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":_vm.labels["bank-bsb-number"],"error-messages":errors,"color":"secondary"},model:{value:(_vm.resources.loan.attributes["bank-bsb-number"]),callback:function ($$v) {_vm.$set(_vm.resources.loan.attributes, "bank-bsb-number", $$v)},expression:"resources.loan.attributes[`bank-bsb-number`]"}})]}}])})],1),_vm._v(" "),_c('div',{staticClass:"flex-field"},[_c('ValidationProvider',{attrs:{"name":_vm.labels["bank-account-number"],"rules":_vm.getRules("bank-account-number"),"vid":"bank-account-number"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":_vm.labels["bank-account-number"],"error-messages":errors,"color":"secondary"},model:{value:(_vm.resources.loan.attributes["bank-account-number"]),callback:function ($$v) {_vm.$set(_vm.resources.loan.attributes, "bank-account-number", $$v)},expression:"resources.loan.attributes[`bank-account-number`]"}})]}}])})],1),_vm._v(" "),_c('div',{staticClass:"flex-field"}),_vm._v(" "),_c('div',{staticClass:"flex-field"})])])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }