<template>
  <div>
    <div v-if="vb.mdAndUp" class="d-flex" style="width: 100%">
        <v-sheet color="light_gray_1" class="py-12 px-12" width="50%" outlined elevation="0">
            <v-img
                max-width="120"
                class="flex-brand"
                :src="require(`~/assets/images/nodifi.svg`)"
                position="center left"
                contain>
            </v-img>
            <div class="mt-2 font-size-20 light_gray_2--text">Data</div>

            <v-list color="transparent">
                <v-list-item two-line class="px-0">
                    <v-list-item-content>
                        <v-list-item-title class="font-weight-medium">Employer Name</v-list-item-title>
                        <v-list-item-subtitle>{{data ? data.attributes['employer-name'] : '&mdash;'}}</v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>

                <v-list-item two-line class="px-0">
                    <v-list-item-content>
                        <v-list-item-title class="font-weight-medium">Job Title</v-list-item-title>
                        <v-list-item-subtitle>{{data ? data.attributes['job-title'] : '&mdash;'}}</v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>
            </v-list>

        </v-sheet>
        <v-sheet class="py-8 px-12" width="50%">

            <v-img
                max-width="120"
                class="flex-brand"
                :src="require(`~/assets/images/logo/cropped/lender-logo-money3.png`)"
                position="center left"
                contain>
            </v-img>
            <div class="mt-2 mb-8 font-size-20">Requirements</div>

            <ValidationObserver ref="observer" tag="form">
                <template v-for="(field, key) in resources.incomes.attributes['lender']['money3']" >
                <ValidationProvider
                        v-if="visible[key]"
                        :key="key"
                        v-slot="{ errors }"
                        :name="getLabel(key)"
                        :rules="getRules(key)"
                        :vid="key">

                            <v-select
                                v-if="isFieldType('picklist', key)"
                                v-model="resources.incomes.attributes['lender']['money3'][key]"
                                :items="getOptions(key)"
                                :error-messages="errors"
                                color="secondary"
                                :label="getLabel(key)"
                                ></v-select>

                            <v-text-field
                                v-else
                                tile
                                type="text"
                                :label="getLabel(key)"
                                color="secondary"
                                :error-messages="errors"
                                v-model="resources.incomes.attributes['lender']['money3'][key]"
                                required>
                            </v-text-field>

                </ValidationProvider>
                </template>
            </ValidationObserver>


        </v-sheet>
    </div>

    <!-- mobile version -->
    <div v-else>

        <v-card flat class="mx-5 rounded-xl" :style="`background-color: ${hexToRGBA(getActiveTheme.colors.secondary, 0.2)}`">
            <v-card-text class="d-flex py-1">
                <v-row>
                    <v-col cols="6" class="pa-1">
                        <v-card
                        min-height="45"
                            flat class="rounded-xl px-1"
                            :color="tabSelection == 'nodifi' ? 'white' : 'transparent'">
                            <div class="d-flex justify-center pt-1" @click="selectTab('nodifi')">
                                <v-img
                                    max-width="55"
                                    class="flex-brand"
                                    :src="require(`~/assets/images/nodifi.svg`)"
                                    position="center left"
                                    contain>
                                </v-img>
                                <div class="mt-3 font-size-12 pl-1">Data</div>
                            </div>
                        </v-card>
                    </v-col>
                    <v-col cols="6" class="pa-1">
                        <v-card
                            flat class="rounded-xl px-1"
                            :color="tabSelection == 'money3' ? 'white' : 'transparent'">
                            <div class="d-flex flex-column" @click="selectTab('money3')">
                                <v-img
                                    max-width="45"
                                    class="flex-brand mx-auto mt-2"
                                    :src="require(`~/assets/images/logo/cropped/lender-logo-money3.png`)"
                                    position="center left"
                                    contain>
                                </v-img>
                                <div class="font-size-12 pl-1 mx-auto">Requirements</div>
                            </div>
                        </v-card>
                    </v-col>
                </v-row>
            </v-card-text>

        </v-card>

        <v-list v-show="tabSelection == 'nodifi'" class="mx-8" color="transparent">
            <v-list-item two-line class="px-0">
                <v-list-item-content>
                    <v-list-item-title class="font-weight-medium">Employer Name</v-list-item-title>
                    <v-list-item-subtitle>{{data ? data.attributes['employer-name'] : '&mdash;'}}</v-list-item-subtitle>
                </v-list-item-content>
            </v-list-item>

            <v-list-item two-line class="px-0">
                <v-list-item-content>
                    <v-list-item-title class="font-weight-medium">Job Title</v-list-item-title>
                    <v-list-item-subtitle>{{data ? data.attributes['job-title'] : '&mdash;'}}</v-list-item-subtitle>
                </v-list-item-content>
            </v-list-item>
        </v-list>

        <div v-show="tabSelection != 'nodifi'" class="mx-8">
            <ValidationObserver ref="observer" tag="form">
                <template v-for="(field, key) in resources.incomes.attributes['lender']['money3']" >
                <ValidationProvider
                        v-if="visible[key]"
                        :key="key"
                        v-slot="{ errors }"
                        :name="getLabel(key)"
                        :rules="getRules(key)"
                        :vid="key">

                            <v-select
                                v-if="isFieldType('picklist', key)"
                                v-model="resources.incomes.attributes['lender']['money3'][key]"
                                :items="getOptions(key)"
                                :error-messages="errors"
                                color="secondary"
                                :label="getLabel(key)"
                                ></v-select>

                            <v-text-field
                                v-else
                                tile
                                type="text"
                                :label="getLabel(key)"
                                color="secondary"
                                :error-messages="errors"
                                v-model="resources.incomes.attributes['lender']['money3'][key]"
                                required>
                            </v-text-field>

                </ValidationProvider>
                </template>
            </ValidationObserver>
        </div>

    </div>
  </div>
</template>

<script>

    import { mapActions } from 'vuex'
    import subscriptions from '~/components/shared/subscriptions'
    import shared from '~/components/v1/submission/Money3Entities1/__shared'
    import Enums from '~/components/v1/submission/Money3Entities1/__enums'

    export default {
        components: {},
        mixins: [subscriptions, shared],
        props: {
            incomes: {
                type: Array,
                default: () => []
            },
            data: {
                type: Object,
                default: null
            },
            updater: undefined
        },
        data() {
            return {
                tabSelection: 'nodifi',
                resources: {
                    incomes: {
                        id: null,
                        type: "incomes",
                        attributes: {
                            "lender": {
                                "money3": {
                                    "industry" : null,
                                    "occupation" : null,
                                }
                            }
                        },
                        relationships: {
                            application: {
                                data : {
                                    type: "applications",
                                    id: null
                                }
                            }
                        }
                    }
                },
                visible: {
                    "industry": false,
                    "occupation": true
                },
                options: Enums,
                labels: {
                    "industry": "Industry",
                    "occupation": "Occupation",
                },
                validation_rules: {
                    attributes: {
                        "industry": `required|in:${Enums['industry-keys']}`,
                        "occupation": `required|in:${Enums['occupation-keys']}`,
                    }
                }
            }
        },
        computed: {
            vb() {
                return this.$vuetify.breakpoint
            },
        },
        mounted() {
            this.updateResourceFields()
        },
        methods: {
            ...mapActions('resource-actions', [
                'getIncome',
            ]),
            selectTab(tab) {
                this.tabSelection = tab
            },
            getLabel(key) {
                if(key === 'occupation') {
                    return this.data.attributes['income-situation'] + ' ' + this.labels[key]
                }

                return this.labels[key]
            },
            async validate(){

                const valid = await this.$refs.observer.validate()

                if(valid) {

                    // attache income id
                    // this.resources.incomes.id = this.data.id
                    this.$emit('submit', this.resources.incomes)
                }

            },
            async reset(){

                this.$refs.observer.reset()
                this.resources.incomes = {
                    id: null,
                    type: "incomes",
                    attributes: {
                        "lender": {
                            "money3": {
                                "industry" : null,
                                "occupation" : null,
                            }
                        }
                    },
                    relationships: {
                        application: {
                            data : {
                                type: "applications",
                                id: null
                            }
                        }
                    }
                }

            },
            async updateResourceFields(){

                // console.log('updateResourceFields')

                this.$emit('loading', true)

                const {data} = this

                if(data) {

                    let res = await this.getIncome(data.id)

                    this.resources.incomes = this.filterResourceFields(this.resources.incomes, res.data.data)

                    if(data.attributes['income-situation'] === 'Current') {
                        this.visible['industry'] = true
                    } else {
                        this.visible['industry'] = false
                    }

                    // console.log(this.resources['incomes'])

                    this.$emit('loading', false)

                } else {

                    this.$emit('loading', false)
                }



            }
        },
    }
</script>

<style lang="scss" scoped>
</style>
