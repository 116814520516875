<template>
    <div v-responsive="{ flex /* minus 20px padding & 2px border */ }">
        <slot v-bind:sizes="sizes"></slot>
    </div>
</template>

<script>

    import { ResponsiveDirective } from "vue-responsive-components"
    import {debounce} from 'lodash';

    export default {
        props: {

        },
        directives: {
            responsive: ResponsiveDirective
        },
        data() {
            return {
                sizes: {
                    xs: false,

                    sm: false,
                    smAndUp: false,
                    smAndDown: false,

                    md: false,
                    mdAndUp: false,
                    mdAndDown: false,

                    lg: false,
                    lgAndUp: false,
                    lgAndDown: false,

                    xl: false,

                    width: 0,
                    height: 0
                }
            }
        },
        created() {
            this.handleResize = debounce(this.handleResize, 300)
        },
        methods: {
            flex(el){
                return this.handleResize(el)
            },
            /**
             *  From Vuetify Breakpoints
             *  https://vuetifyjs.com/en/styles/display/#display
             * 
             *  NOTE: size of component, not the window. 
             * 
             */
            handleResize(el) {

                this.sizes.xs = el.width < 600;

                this.sizes.sm = (el.width > 600 && el.width < 960);
                this.sizes.smAndUp = el.width > 600;
                this.sizes.smAndDown = el.width < 960;

                this.sizes.md = (el.width > 960 && el.width < 1264);
                this.sizes.mdAndUp = el.width > 960
                this.sizes.mdAndDown = el.width < 1264

                this.sizes.lg = (el.width > 1264 && el.width < 1904);
                this.sizes.lgAndUp = el.width > 1264
                this.sizes.lgAndDown = el.width < 1904

                this.sizes.xl = el.width > 1904;

                this.sizes.width = el.width
                this.sizes.height = el.height

                this.$emit('resize', this.sizes)

                return false
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>