<template>
    <v-card class="mx-auto" tile flat>
        <v-card-text class="pa-4 pt-0 pa-md-10 pt-md-0">
            <ValidationObserver ref="observer" tag="form">
                    <div class="flex-form">
                        <div class="flex-field" style="width: 370px;">
                            <ValidationProvider
                                v-if="showOtherLoanTypeField"
                                v-slot="{ errors }"
                                :name="labels[`loanType`]"
                                :rules="getRules(`loanType`)"
                                :vid="`loanType`">

                                <v-select
                                    v-model="resources.loan.attributes[`loanType`]"
                                    :items="getOptions(`loanType`)"
                                    :error-messages="errors"
                                    color="secondary"
                                    :label="labels[`loanType`]">
                                </v-select>

                            </ValidationProvider>
                            <v-text-field
                                v-else
                                v-model="defaultLoanType"
                                disabled
                                color="secondary"
                                label="Loan Type">
                            </v-text-field>
                        </div>
                    </div>

            </ValidationObserver>

        </v-card-text>
    </v-card>
</template>

<script>
import { mapActions, mapState, mapMutations } from 'vuex'
import helpers from '~/plugins/helpers'
import subscriptions from '~/components/shared/subscriptions'
import Enum from '~/components/v1/submission/Money3Customizations1/__enum'

export default {
    name: 'Money3Customizations1',
    mixins: [subscriptions, helpers],
    data() {
        return {
            showOtherLoanTypeField: false,
            resources: {
                loan: {
                    type: 'loans',
                    id: null,
                    attributes: {
                        "personal-loan-reason": "",
                        "loanType": "",
                        "loan-type": "",
                    }
                }
            },
            defaultLoanType: "",
            labels: {
                "personal-loan-reason": "Loan Type",
                "loanType": "Loan Type"
            },
            validation_rules: {
                type: "loans",
                attributes: {
                    "personal-loan-reason": "",
                    "loanType": `in:${Enum['loan-type']}`,
                }
            },
        }
    },
    computed: {
        ...mapState({
            appId: state => state.flows.appId,

        }),
        loanType(){
            return this.resources?.loan?.attributes?.['loan-type']
        },
    },
    methods: {
        ...mapActions('resource-actions', [
            'getApplication',
            'updateLoan'
        ]),
        ...mapMutations('flows', [
            'setCommitments'
        ]),
        async pull() {
            let promise1 = this.getApplication({ id: this.appId, params: { include: 'loan,purchases' } })

            const res = await Promise.all([
                promise1,
            ])

            if(res[0].data?.included && res[0].data.included.length > 0) {
                this.resources.loan.attributes['loanType'] = res[0].data.included[0].attributes.lender['money3']['loanType']
                this.resources.loan.attributes['personal-loan-reason'] = res[0].data.included[0].attributes['personal-loan-reason']
                this.resources.loan.attributes['loan-type'] = res[0].data.included[0].attributes['loan-type']
                this.resources.loan.id = res[0].data.included[0].id
            }
            
            if(this.loanType === 'Asset Finance') {

                if(res[0].data.included[1]) {
                    // if other show other loan type field
                    if(res[0].data.included[1].relationships.kind.data.id === '9-31') {
                        this.showOtherLoanTypeField = true
                        this.validation_rules.attributes['loanType'] = `required|in:${Enum['loan-type']}`
                    } else {

                        if(Enum['purchase-kinds-value'][res[0].data.included[1].relationships.kind.data.id]) {
                            this.defaultLoanType = Enum['purchase-kinds-value'][res[0].data.included[1].relationships.kind.data.id]
                        }

                        this.showOtherLoanTypeField = false
                    }
                }

            } else {

                if(this.resources.loan.attributes['personal-loan-reason'] === 'Other') {
                    this.showOtherLoanTypeField = true
                    this.validation_rules.attributes['loanType'] = `required|in:${Enum['loan-type']}`
                } else {
                    this.defaultLoanType = this.resources.loan.attributes['personal-loan-reason']
                    this.showOtherLoanTypeField = false
                }

            }

        },

        async push() {

            const observer = this.$refs.observer
            const {valid, status} = await this.validateFormData()
            let loanRes = null

            if(valid) {
                
                const { loan } = this.resources
                    
                // Exclude resources.relationships and other stuff
                let payload = {
                    type: 'loans',
                    id: this.resources.loan.id,
                    attributes: {
                        "lender": {
                            "money3": {
                                "loanType": loan.attributes['loanType']
                            }
                        },
                    }
                }

                loanRes = await this.updateLoan(payload)

            }

            const data = await this.appDataHelper([loanRes])

            return {valid: valid, status: status, data}

        },
    },
}
</script>

<style>
</style>

