import Vue from "vue";

export const store = Vue.observable({

    steps: {
        "person": {
            "title": "Person Details",
            "subtitle": "Please use the Nodifi Data to answer the questions in the Society One Requirements",
            "form-ref": "FormPersonDetailsForm",
        }
    },

    carousel: 0,

});


export const actions = {
    setSteps(payload) {
        store.steps = payload
    },

    setCarousel(payload) {
        store.carousel = payload
    },

    resetStore() {
        store.carousel = 0
    },

    setCarouselNext() {
        store.carousel++
    },
    setCarouselBack() {
        store.carousel--
    },

};
