<template>
    <v-card class="d-flex flex-column justify-space-between align-center" flat tile>
        <v-card  class="d-flex flex-nowrap align-center pt-0 px-6 py-md-0 rounded-bottom flex-wrap" width="100%" tile flat max-width="1676" :color="vb.mdAndUp ? 'light_gray_3': 'transparent'" min-height="100">

            <v-col :lg="3" v-if="showRecordSelector" :class="vb.mdAndUp ? 'pa-0' : 'pt-6'">
                <div :class="['record-selector', {'mobile-record-selector': !vb.mdAndUp}]">
                    <EditPersonRecordButton :entities="entityList" :args="args" />
                    <div class="page-title body-1 py-4 text-center" v-if="!vb.mdAndUp">
                        {{getPageTitle}}
                    </div>
                </div>
            </v-col>

            <v-col :lg="3" v-if="!showRecordSelector && !vb.mdAndUp" class="pa-0">
                <div class="record-selector mobile-record-selector">
                    <div class="page-title body-1 py-4 text-center">
                        {{getPageTitle}}
                    </div>
                </div>
            </v-col>

        <template v-if="vb.mdAndUp">
            <v-col :lg="showRecordSelector ? 6 : 12" class="pa-0"> 
                <div class="d-flex steps justify-center  md-mt-12" >
                    <div :class="['step mx-6 text-no-wrap',{'active-step': key + 1 == activeStep}]"  v-for="(step, key) in computedSteps.steps" :key="key">
                        <button @click="handleNavigationClick(key + 1)">
                            {{step.name}}
                        </button>
                    </div>
                </div>
            </v-col>
            <v-col :lg="3" v-if="showRecordSelector" class="pa-0">
                <v-spacer></v-spacer>
            </v-col>
        </template>

        </v-card> 
    </v-card>     
</template>


<script>
    import subscriptions from '~/components/shared/subscriptions'
    import EditPersonRecordButton from './_EditPersonRecordButton'
    import { mapState, mapGetters, mapActions, mapMutations } from 'vuex'
    import resourceHelpers from '~/components/shared/resource-helpers'

    export default {
        components: {
            EditPersonRecordButton
        },
        mixins: [subscriptions, resourceHelpers],
        data () {
            return {
                entityList:[],

                people: [],
                businesses: [],

                args: {},
            }
        },
        computed: {
            ...mapState('flows',['calculation','appData']),
            ...mapState({
                activeStep: state => state.flows.activeStep,
                steps: state => state.flows.steps,
                stepStatuses: state => state.statuses.stepStatuses,
            }),
            computedSteps(){
                const {steps, activeStep} = this

                let bp = this.$vuetify.breakpoint.name;
                
                let newSteps = steps
                let newActive = parseInt(activeStep)

                newSteps = newSteps.map((step, i) => {
                    step.index = (i + 1)                    
                    return step
                })

                if(bp == 'xs' || bp == 'sm'){

                    if(newActive <= 2) {

                        newSteps = newSteps.slice(0, 3)

                    }  else if(newActive == steps.length) {

                        newSteps = newSteps.slice(newActive - 3)
                    
                    } else {

                        newSteps = newSteps.slice((newActive - 2), (newActive + 1))

                    }

                }

                return {
                    active: newActive,
                    steps: newSteps
                }

            },
            getPageTitle(){
                const {steps, activeStep} = this

                let stepName = ''
                let currentStepObj = steps[parseInt(activeStep) - 1]

                if(currentStepObj) {
                    stepName = currentStepObj.name
                }

                return stepName
            },
            showRecordSelector() {
                return parseInt(this.blockSettings.showRecordSelector) == 1
            }
        },
        mounted() {
            
            this.args = this.flowConfigParser()

            this.getEntityList()
        },
        methods: {
            ...mapActions('resource-actions',['getPeople','getBusinesses']),
            ...mapActions('flows', ['selectStep']),
            handleNavigationClick(index){
                this.selectStep(index)
            },
            async getEntityList () {

                if (this.calculation['application-type'].search('Consumer') != -1) {

                        this.getConsumerList() 

                } else {

                        this.getCommercialList()
                }
            },
            async getConsumerList(){

                let people = await this.getPeople()
                
                if(people.data?.data && people.data.data.length > 0) {

                    people.data.data.forEach(person => {

                        let name = this.getEntityFriendlyName(person)

                        this.entityList.push({text: name, value: person.id, type:'people' })  

                    });
                    
                }
                
            },
            async getCommercialList(id){

                let businesses = await this.getBusinesses()
                let people = await this.getPeople()

                if(businesses.data?.data && businesses.data.data.length > 0) {

                    businesses.data.data.forEach(business => {

                        let name = this.getEntityFriendlyName(business)

                        this.entityList.push({text: name, value: business.id, type:'business' })  

                    });
                    
                }

               if(people.data?.data && people.data.data.length > 0) {

                    people.data.data.forEach(person => {

                        let name = this.getEntityFriendlyName(person)

                        this.entityList.push({text: name, value: person.id, type:'people' })  

                    });
                    
                }

            }
        },
    }
</script>

<style  lang="scss">

    .flex__component-SubNavigation1 {

        .record-selector {
            max-width: 260px;
        }

        .mobile-record-selector {
            margin: auto;

            .page-title {
                position: relative;

                &::after {
                    content: '';
                    position: absolute;
                    display: block;
                    bottom: 0;
                    left: 50%;
                    transform: translateX(-50%);
                    width: 60px;
                    border-top: 3px solid var(--v-accent-base)
                }
            }
        }

        & > .v-card.v-card.rounded-bottom {
            border-bottom-left-radius: 40px !important;
            border-bottom-right-radius: 40px !important;
        }

        .step {
            display: flex;
            align-items: center;
            position: relative;
            height: 100px;
        }

        .active-step:before {
            content: "";
            position: absolute;
            background: var(--v-accent-base);
            width: 50%;
            height: 3px;
            bottom: 0;
            left: 50%;
            transform: translateX(-50%);

        }

    }
    

</style>
