<template>
    <v-card class="mx-auto" :disabled="isDisabled" tile flat>

        <v-card-text class="pa-4 pt-0 pa-md-10 pt-md-0">
            <v-container pa-0 fluid>
                <v-row>
                    <v-col cols="12">
                        <ValidationObserver ref="observer" tag="form">
                            <div v-show="false" class="flex-form">
                                <div
                                    v-for="(field, fkey) in fields"
                                    :key="fkey" 
                                    class="flex-field">

                                    <ValidationProvider
                                        v-slot="{ errors }"
                                        :name="field.meta | formalText"
                                        rules="required"
                                        :vid="field.meta">
                                        <v-text-field
                                            v-model="resourceObj[field.meta]"
                                            :error-messages="errors"
                                            :label="field.label">
                                        </v-text-field>
                                    </ValidationProvider>

                                </div>
                            </div>
                            <div class="flex-field">

                                <v-data-table
                                    :loading="isDisabled"
                                    loading-text="Please wait..."
                                    v-if="validComponentData"
                                    :headers="headers"
                                    :items="lenders"
                                    hide-default-footer
                                    class="elevation-0 v-data-table-2">

                                    <!-- Headers -->
                                    <template v-slot:header.lender="{ header }">
                                        <div class="start">{{ header.text }}</div>
                                    </template>

                                    <template v-slot:header.action="{ header }">
                                        <div class="end"></div>
                                    </template>

                                    <!-- Body -->
                                    <template v-slot:item.compare="{ item }">
                                        <div class="inner">
                                            <div class="d-flex flex-column ml-2">
                                                <v-checkbox
                                                    :disabled="selectedCount == 3 && !selected.includes(item['name'])"
                                                    v-model="selected"
                                                    :value="item['name']"
                                                    class="ma-0">
                                                </v-checkbox>
                                            </div>
                                        </div>
                                    </template>

                                    <template v-slot:item.lender="{ item }">
                                        <div class="inner">
                                            <v-img 
                                                max-width="80"
                                                class="flex-brand" 
                                                :src="getLenderLogo(item['logo-class'])" 
                                                position="center left" 
                                                contain>
                                            </v-img>
                                        </div>
                                    </template>

                                    <template v-slot:item.loan-term="{ item }">
                                        <div class="inner">
                                            {{ item['actual-payment']['term-in-months']  }} Months
                                        </div>
                                    </template>

                                    <template v-slot:item.repayments="{ item }">
                                        <div class="inner">
                                            ${{ ( (item['actual-payment']['monthly-payment'] * 12 ) / 52 ) | withDecimalPlacesOf(2) }}
                                            <span class="body-2">/wk</span>
                                        </div>
                                    </template>

                                    <template v-slot:item.comparison-rate="{ item }">
                                        <div class="inner">
                                            {{ item['actual-payment']['customer-rate'] | withDecimalPlacesOf(2) }}%
                                        </div>
                                    </template>

                                    <template v-slot:item.customer-rate="{ item }">
                                        <div class="inner">
                                            {{ item['actual-payment']['customer-rate'] | withDecimalPlacesOf(2) }}%
                                        </div>
                                    </template>

                                    <template v-slot:item.action="{ item }">
                                        <div class="inner end">
                                            <v-btn class="mr-2" color="primary" outlined @click="showInfoDialog(item)" large depressed> More Info </v-btn>
                                            <v-btn 
                                                :color="resourceObj['quoted-lender-selected'] === item.name ? 'secondary is-disabled' : 'secondary'"
                                                :disabled="disabled"
                                                @click="setSelected(item.name)"
                                                large
                                                depressed>{{ resourceObj['quoted-lender-selected'] === item.name ? 'Applied' : 'Apply Now' }}
                                            </v-btn>
                                        </div>
                                    </template>

                                </v-data-table>
                                
                                <v-card v-else tile flat class="rounded-lg mx-auto" max-width="700">
                                    <v-card-text class="text-center pa-0 pa-md-66">
                                        <v-icon size="48" color="secondary">mdi-information</v-icon>
                                        <v-spacer class="mb-6"/>
                                        <div class="body_text_color--text">
                                            <div class="headline mb-6">Sorry, we are having trouble locating options based on the information provided.</div>
                                            <p class="subtitle-1">But, please continue and submit the application with detailed submission notes, and our team will look at options using the entire panel.</p>
                                        </div>
                                    </v-card-text>
                                </v-card>

                            </div>
                        </ValidationObserver>
                    </v-col>
                    <v-col cols="12" class="text-left">
                        <v-btn @click="showCompareDialog" color="primary" large depressed :disabled="selectedCount < 2">Compare Products</v-btn>
                    </v-col>
                </v-row>
            </v-container>
        </v-card-text>

        <!-- More Info Modal -->
        <v-dialog
        max-width="750"
        v-if="dialogData"
        v-model="dialog">
            <v-card flat>

                    <v-card-title class="px-6 pt-4 pb-0">
                        <v-spacer/>
                        <v-btn icon @click="dialog = false">
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                    </v-card-title>

                    <v-card-text class="px-8 pt-0 pb-10 text-center"> 

                        <v-container fluid pa-0>
                            <v-row>
                                <v-col cols="12" class="pt-0">
                                    <v-img
                                        width="120"
                                        class="mx-auto mb-4"
                                        :src="getLenderLogo(dialogData['logo-class'])" 
                                        position="center" 
                                        contain>
                                    </v-img>
                                    <v-card-title class="text-center d-block">Basic Terms and Fees</v-card-title> 
                                </v-col>
                                <v-col cols="12" sm="6" md="4">
                                    <div class="text-overline">Amount Financed</div> 
                                    <div class="text-body-1 font-weight-bold">{{ dialogData['actual-payment']['naf'] | currency }}</div> 
                                </v-col>
                                <v-col cols="12" sm="6" md="4">
                                    <div class="text-overline">Loan Term</div> 
                                    <div class="text-body-1 font-weight-bold">{{ dialogData['actual-payment']['term-in-months'] }} Months</div> 
                                </v-col>
                                <v-col cols="12" sm="6" md="4">
                                    <div class="text-overline">Monthly Payment</div> 
                                    <div class="text-body-1 font-weight-bold">{{ dialogData['actual-payment']['monthly-payment'] | currency }}</div> 
                                </v-col>
                                <v-col cols="12" sm="6" md="4">
                                    <div class="text-overline">Origination Fee</div> 
                                    <div class="text-body-1 font-weight-bold">{{ dialogData['actual-payment']['broker-fee'] | currency }}</div> 
                                </v-col>
                                <v-col cols="12" sm="6" md="4">
                                    <div class="text-overline">Bank Setup Fees</div> 
                                    <div class="text-body-1 font-weight-bold">{{ dialogData['actual-payment']['bank-fees'] | currency }}</div> 
                                </v-col>
                                <v-col cols="12" sm="6" md="4">
                                    <div class="text-overline">Monthly Account Fee</div> 
                                    <div class="text-body-1 font-weight-bold">{{ dialogData['actual-payment']['account-fee'] | currency }}</div> 
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card-text>

            </v-card>
        </v-dialog>

        <!-- Compare Modal -->
        <v-dialog
            max-width="800"
            v-model="dialog2">
            <v-card flat class="pb-10">

                <v-card-title class="px-6 pt-4 pb-0">
                    <v-spacer/>
                    <v-btn icon @click="dialog2 = false">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-card-title>

                <v-card-title class="d-block text-center">
                    <h2 class="font-weight-black">Compare Products</h2>
                </v-card-title> 

                <v-card-text class="px-8 py-0 text-center"> 
                    <v-container fluid pa-0>
                        <v-row>
                            <v-col cols="12" sm="12" :md="selectedCount == 3 ? '4' : '6'" v-for="lender in selectedLenders" :key="lender.name">

                                <v-card flat>

                                    <v-card-title class="d-block text-center">
                                        <v-card flat class="mx-auto ma-0 pa-0 text-center" height="60">
                                            <v-card-text class="ma-0 pa-0 text-center">
                                                <div class="text-center">
                                                    <v-img 
                                                        height="60"
                                                        class="flex-brand text-center" 
                                                        :src="getLenderLogo(lender['logo-class'])" 
                                                        position="center" contain>
                                                    </v-img>
                                                </div>
                                            </v-card-text>                                            
                                        </v-card>                                        
                                    </v-card-title>

                                    <v-card-text class="pa-0 text-center"> 

                                            <v-divider class="my-2" /> 

                                            <div class="text-overline">Amount Financed</div> 
                                            <div class="text-body-1 font-weight-bold">{{ lender['actual-payment']['naf'] | currency }}</div> 
                                            <v-divider class="my-2" /> 

                                            <div class="text-overline">Loan Term</div> 
                                            <div class="text-body-1 font-weight-bold">{{ lender['actual-payment']['term-in-months'] }} Months</div> 
                                            <v-divider class="my-2" /> 


                                            <div class="text-overline">Monthly Payment</div> 
                                            <div class="text-body-1 font-weight-bold">{{ lender['actual-payment']['monthly-payment'] | currency }}</div> 
                                            <v-divider class="my-2" /> 
                                        
                                            <div class="text-overline">Origination Fee</div> 
                                            <div class="text-body-1 font-weight-bold">{{ lender['actual-payment']['broker-fee'] | currency }}</div> 
                                            <v-divider class="my-2" /> 
                                        
                                            <div class="text-overline">Bank Setup Fees</div> 
                                            <div class="text-body-1 font-weight-bold">{{ lender['actual-payment']['bank-fees'] | currency }}</div> 
                                            <v-divider class="my-2" /> 
                                        
                                            <div class="text-overline">Monthly Account Fee</div> 
                                            <div class="text-body-1 font-weight-bold">{{ lender['actual-payment']['account-fee'] | currency }}</div> 

                                    </v-card-text>

                                </v-card>

                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>

            </v-card>
        </v-dialog>

    </v-card>
</template>

<script>

    import subscriptions from '~/components/shared/subscriptions'
    import { mapActions, mapGetters, mapMutations } from 'vuex'
    import { isEmpty } from 'lodash'

    export default {
        name: 'Quotes1',
        mixins: [subscriptions],
        data() {
            return {
                dialog: false,
                dialog2: false,
                selected: [],
                dialogData: null,
                validComponentData: true,
                lenders: [],
                quotes: {},
                resources: {                        
                    "type": "loans",
                    "id": null,
                    "attributes": {
                        "quoted-lender-selected": null
                    },
                    "relationships": {
                        "application": {
                            "data": {
                                "type": "applications",
                                "id": null
                            },
                        }
                    }
                },
                headers: [
                    { text: '', sortable: false, value: 'compare', width: 'auto'},
                    { text: 'Lender', sortable: false, value: 'lender', width: '15%'},
                    { text: 'Loan Term', sortable: false, value: 'loan-term', width: '20%'},
                    { text: 'Repayments', sortable: false, value: 'repayments', width: '20%'},
                    { text: 'Comparison Rate', sortable: false, value: 'comparison-rate', width: '20%'},
                    { text: 'Customer Rate', sortable: false, value: 'customer-rate', width: '20%'},
                    { text: '', sortable: false, value: 'action'}
                ],
                resourceObj: {

                },
                fields: {
                    'quoted-lender-selected': { label: 'Lender', meta: 'quoted-lender-selected' }
                },
                disabled: false,
            }
        },
        computed: {
            selectedCount(){

                return this.selected.length

            },
            selectedLenders(){

                let lenders = []


                this.lenders.forEach( lender => {

                    if( this.selected.includes(lender.name)) {

                        lenders.push(lender)
                    }

                })

                return lenders

            }
        },
        methods: {
            ...mapActions('flows', ['selectNextStep']),
            ...mapActions('resource-actions', [
                'getQuotes',
                'setApplicationNow'
            ]),
            ...mapMutations('subscriptions', [
                'setStepableDataWorkable'
            ]),
            async pull() {

                let response = await this.getQuotes({                    
                    'type': 'applications',
                    'id': this.appId,
                    'attributes': {}
                })

                if ( response.length > 1 ) {

                    response.forEach( res => {
                        if (res.data.data.type === 'applications') {

                            let list = res?.data?.included?.[0]?.attributes?.results ? res?.data.included[0].attributes.results : {}
                            
                            if ( !isEmpty(list) ) 
                                list = JSON.parse( list )

                            this.quotes = list?.lenders

                        } else {
                            this.resources = res.data.data
                            this.resources.relationships = this.removeEmptyRelationships(this.resources.relationships)
                        }
                    })
                }

                if (this.quotes)
                    Object.keys(this.quotes).forEach( lender => {
                        this.lenders.push(this.quotes[lender])
                    })

                this.resourceObj = { 
                    'quoted-lender-selected': this.resources.attributes['quoted-lender-selected']
                }

                // sort lenders from lowest to highest monthly repayments
                if (this.lenders.length > 0)
                    this.lenders = this.sortLenders(this.lenders)

                // console.log(this.lenders)
                this.validComponentData = this.lenders.length > 0 ? true : false
            },

            async push() {

                let requestPayload = Object.assign({}, this.resources)

                let res = await this.setApplicationNow({
                    payload: this.resources,
                    picked: this.resourceObj['quoted-lender-selected']
                })
                
                const {valid, status} = await this.validateFormData()
                let data = this.$options.filters.toAppDataFormat(res)

                return  { valid, status, data } 
            },

            sortLenders(lenders) {

                lenders.sort((a, b) => {

                    if (a['actual-payment']['monthly-payment'] && b['actual-payment']['monthly-payment'])
                        return a['actual-payment']['monthly-payment'] - b['actual-payment']['monthly-payment']
                    
                })

                return lenders;
            },

            setSelected(name = '') {

                this.resourceObj = { 'quoted-lender-selected': name }

                this.selectNextStep()

            },

            removeEmptyRelationships(relationships) {
                
                let temp = {}

                Object.keys(relationships).forEach( key => {
                    if (relationships[key].data?.length > 0 || relationships[key].data?.id !== undefined)
                        temp[key] = relationships[key]
                })

                return temp
            },
            showInfoDialog(data){

                this.dialogData = data
                this.dialog = true

            },
            showCompareDialog(){

                this.dialog2 = true

            },
            getLenderLogo(name){

                let lender = require(`~/assets/images/logo/placeholder.png`)

                try {

                    lender = require(`~/assets/images/logo/${name}.png`)

                    return lender

                } catch (error) {

                    console.log('Ooops!..', error)

                    return lender

                }

            }
        },
        watch: {

            "validComponentData": {
                handler(val) {
                    if ( !this.validComponentData ) {

                        this.setStepableDataWorkable(false)

                    }
                },
                deep: true
            }
        }
    }
</script>

<style lang="scss" scoped>

    .flex-form {
        display: grid;
        grid-column-gap: 15px;
        grid-row-gap: 0;
        grid-template-columns:  repeat(auto-fit, minmax(260px, 1fr));

        .flex-field {
            align-self: start;
        }
    }

    .inline-icons {
        display: flex;
    }


</style> 