import Vue from "vue";

export const store = Vue.observable({
  steps: {
    address: {
      title: "Address Mapping",
      subtitle:
        "",
      "form-ref": "AddressInformationForm",
    },
    entity: {
      title: "ABN Continuity",
      subtitle:
        "",
      "form-ref": "BusinessInformationForm",
    },
    guarantor: {
      title: "Guarantor",
      subtitle:
        "",
      "form-ref": "GuarantorInformationForm",
    }
  },
  carousel: 0,
});

export const actions = {
  setCarousel(payload) {
    store.carousel = payload;
  },

  resetStore() {
    store.carousel = 0;
  },

  setCarouselNext() {
    store.carousel++;
  },
  setCarouselBack() {
    store.carousel--;
  },
};
