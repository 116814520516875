<template>
    <v-card class="mx-auto" tile flat>
        <v-card-text class="pa-4 pt-0 pa-md-10 pt-md-0">

            <v-row v-if="!toSingleColFields">
                <v-col  cols="12" sm="6" class="align-center">
                        <!-- spacer -->
                </v-col>
                <v-col  cols="12" sm="6">
                    <div class="flex-form">
                        <div class="flex-field">
                            <div class="body-1 font-weight-medium">Applicant 1</div>
                        </div>
                        <div class="flex-field" v-if="isJoint">
                            <div class="body-1 font-weight-medium">Applicant 2</div>
                        </div>
                    </div>
                </v-col>
            </v-row>

            <ValidationObserver ref="observer" tag="form">
                <template v-for="(value, key) in labels">
                    <v-row  v-if="isRowVisibleLogic(key)">
                        <v-col  cols="12" sm="6" class="align-center">
                            <div class="body-1 font-weight-medium">{{value}}</div>
                        </v-col>
                        <v-col  cols="12" sm="6">
                            <div class="flex-form">
                                <div v-for="(person, id, index) in resources" class="flex-field pb-4">
                                    <div :key="`${key}-${index}`" >
                                        <ValidationProvider
                                            v-slot="{ errors }"
                                            :name="labels[key]"
                                            v-if="isIncluded(`${key}-${index}`)"
                                            :rules="getRules(key)"
                                            :class="`${key}-${index}`"
                                            :vid="`${key}-${index}`">

                                            <v-select
                                                v-if="isMultiSelectType(key)"
                                                v-model="resources[id].attributes[key]"
                                                :items="getOptions(key)"
                                                :error-messages="errors"
                                                color="secondary"
                                                :readonly="isReadOnly(key)"
                                                attach
                                                :label="getLabel(index)"
                                                multiple
                                                class="pa-0"
                                            ></v-select>

                                            <v-select
                                                v-else-if="isFieldType('picklist', key)"
                                                v-model="resources[id].attributes[key]"
                                                :items="getOptions(key)"
                                                :error-messages="errors"
                                                color="secondary"
                                                item-text="key"
                                                item-value="value"
                                                :readonly="isReadOnly(key)"
                                                :prefix="prefix[key]"
                                                :label="getLabel(index)"
                                                class="pa-0"
                                            ></v-select>

                                            <v-text-field
                                                v-else
                                                v-model="resources[id].attributes[key]"
                                                :type="isNumber(key) ? 'number' : 'text'"
                                                :error-messages="errors"
                                                :prefix="prefix[key]"
                                                color="secondary"
                                                :label="getLabel(index)"
                                                class="pa-0"
                                            ></v-text-field> 
                                        </ValidationProvider>
                                    </div>
                                </div>
                        </div>

                        </v-col>
                    </v-row>
                </template>
            </ValidationObserver>

        </v-card-text>
     
    </v-card>
</template>

<script>

    import { mapActions, mapState } from 'vuex'
    import helpers from '~/plugins/helpers'
    import subscriptions from '~/components/shared/subscriptions'
    import fieldOptions from '~/components/shared/field-options'


    export default {
        components: { },

        mixins: [subscriptions, helpers],

        data() {
            return {
                resources: {
                    primaryApplicant: {
                            type: 'people',
                            id: null,
                            attributes: {
                                "lender-pepper-purpose": null,
                                "lender-pepper-term-suit": null,
                                "lender-pepper-term-suit-details": null,
                                "lender-pepper-loan-looking-for": null,
                                "lender-pepper-foreseeable-change": null,
                                "lender-pepper-change-expected": null,
                                "lender-pepper-loan-repayment-after-change": null,
                                "lender-pepper-increase-loan-amount": null,
                                "lender-pepper-is-balloon-payment": null,
                            }
                    },
                    secondaryApplicant: {
                        type: 'people',
                            id: null,
                            attributes: {
                                "lender-pepper-purpose": null,
                                "lender-pepper-term-suit": null,
                                "lender-pepper-term-suit-details": null,
                                "lender-pepper-loan-looking-for": null,
                                "lender-pepper-foreseeable-change": null,
                                "lender-pepper-change-expected": null,
                                "lender-pepper-loan-repayment-after-change": null,
                                "lender-pepper-increase-loan-amount": null,
                                "lender-pepper-is-balloon-payment": null,
                            }
                    }
                },
                formFields: [
                    "lender-pepper-purpose",
                    "lender-pepper-term-suit",
                    "lender-pepper-term-suit-details",
                    "lender-pepper-loan-looking-for",
                    "lender-pepper-foreseeable-change",
                    "lender-pepper-change-expected",
                    "lender-pepper-loan-repayment-after-change",
                    "lender-pepper-increase-loan-amount",
                    "lender-pepper-is-balloon-payment",
                ],
                options: {
                    "lender-pepper-loan-looking-for": [
                        "Competitive Interest Rate",
                        "Service",
                        "Broker Recommendation",
                        "I can make additional repayments at no cost",
                        "I can repay the loan early (fees & charges apply)",
                        "A balloon repayment"
                    ],
                    "lender-pepper-foreseeable-change": [
                        { key: "Yes", value: 1 },
                        { key: "No", value: 0 },
                    ],
                    "lender-pepper-increase-loan-amount": [
                        { key: "Yes", value: 1 },
                        { key: "No", value: 0 },
                    ],
                    "lender-pepper-is-balloon-payment": [
                        { key: "Yes", value: 1 },
                        { key: "No", value: 0 },
                    ]
                },
                labels: {
                    "lender-pepper-purpose": "What is the purpose of this Application?",
                    "lender-pepper-term-suit": "Why does this loan term suit your requirements?",
                    "lender-pepper-term-suit-details": "Details",
                    "lender-pepper-loan-looking-for": "What are you looking for in your loan?",
                    "lender-pepper-foreseeable-change": "Any foreseeable changes impacting loan repayment?",
                    "lender-pepper-change-expected": "Type of change expected?",
                    "lender-pepper-loan-repayment-after-change": "How does the applicant intend to continue repaying the loan after the change?",
                    "lender-pepper-increase-loan-amount": "Increase in loan amount due to financing insurnace",
                    "lender-pepper-is-balloon-payment": "Balloon payment at end of loan",
                },
                validation_rules: {
                    type: "people",
                    attributes: {
                        "lender-pepper-purpose": "required|in:Purchase the asset,Purchase the asset plus finance insurances,Refinance loan on the asset,Refinance loan on the asset plus finance insurances",
                        "lender-pepper-term-suit": "required|in:Want the maximum amount of time with the minimum repayment,Want to be able to pay off the loan over the selected term as it suits my budget,I want to pay off the loan as quickly as possible (and acknowledge that fees and charges apply for early payout of the loan),I will be looking to sell the car/ trade in at the end of the loan term,Other",
                        "lender-pepper-term-suit-details": "required",
                        "lender-pepper-loan-looking-for": "required",
                        "lender-pepper-foreseeable-change": "required|in:1,0",
                        "lender-pepper-change-expected": "required|in:End of employment contract/ loss of employment,Leaving employment,Increased debt repayment for a new/ existing loan,Large expenditure,Medical treatment/ illness,Extended unpaid leave (e.g. study or parental leave),Change to family situation,Retirement,Buying a house,Other (please give details along with management strategy)",
                        "lender-pepper-loan-repayment-after-change": "required|string",
                        "lender-pepper-increase-loan-amount": "string|in:1,0",
                        "lender-pepper-is-balloon-payment": "required|in:1,0",
                    }
                },
                included: {
                    "lender-pepper-term-suit-details-0": false,
                    "lender-pepper-term-suit-details-1": false,
                    "lender-pepper-change-expected-0": false,
                    "lender-pepper-change-expected-1": false,
                    "lender-pepper-loan-repayment-after-change-0": false,
                    "lender-pepper-loan-repayment-after-change-1": false,
                    "lender-pepper-is-balloon-payment-0": false,
                    "lender-pepper-is-balloon-payment-1": false,
                },
                prefix: {
                },
                fieldLayout: [
                ],
                readonly: {},
            }
        },
        computed: {
            ...mapState('flows', [
                'appId',
                'loanData',
                'calculation',
            ]),
            getLoanId(){
                return this.appData.relationships.loan.data.id
            },
            isJoint(){
                return this.calculation['application-type'].includes('Consumer - Joint')
            },
            vb(){
                return this.$vuetify.breakpoint
            },
            toSingleColFields(){
                return this.vb.width < 1526
            },
            getRequestedBaloon(){
                return this.loanData.attributes['requested-balloon']
            },
            fieldTermSuit1(){
                return this.resources.primaryApplicant?.attributes?.['lender-pepper-term-suit']
            },
            fieldTermSuit2(){
                if(this.isJoint) {
                    return this.resources?.secondaryApplicant?.attributes?.['lender-pepper-term-suit']
                } else {
                    return null
                }
            },
            fieldForeseeableChange1(){
                return this.resources.primaryApplicant?.attributes?.['lender-pepper-foreseeable-change']
            },
            fieldForeseeableChange2(){
                if(this.isJoint) {
                    return this.resources?.secondaryApplicant?.attributes?.['lender-pepper-foreseeable-change']
                } else {
                    return null
                }
            },

        },
        methods: {
            ...mapActions('resource-actions', [
                'getPeople',
                'updatePerson',
            ]),
            async pull() {

                try {

                    let res = await this.getPeople({"include" : 'parent-parts'})
                    
                    if(res.status === 200 && res.data.data && res.data.data.length){

                        let primaryApplicantId = this.calculation['primary-applicant-id']
                        let secondaryApplicantId = null

                        // Get the secondary applicant id
                        if(this.isJoint && res.data.included) {
                            res.data.included.forEach(part => {
                                if(part.attributes.priority == 2){
                                    secondaryApplicantId = part.relationships.child.data.id
                                }
                            })
                        }

                        // Get the primary & secondary applicant
                        res.data.data.forEach(person => {

                            if(person.id == primaryApplicantId) {
                                this.resources.primaryApplicant = this.filterFields(person)
                            }

                            if(person.id == secondaryApplicantId) {
                                let secondary = this.filterFields(person)
                                this.resources.secondaryApplicant = secondary
                            }

                        });

                        // Delete secondary applicant if not Joint
                        if(!this.isJoint) {
                            delete this.resources.secondaryApplicant
                        }

                        if(this.getRequestedBaloon > 0){
                            this.included['lender-pepper-is-balloon-payment-0'] = true
                            this.included['lender-pepper-is-balloon-payment-1'] = true
                        }
                    }


                } catch (error) {
                    console.log(error)
                }

            },
            async push() {

                try {
                    
                    const observer = this.$refs.observer

                    const {primaryApplicant, secondaryApplicant} = this.resources
                        
                    let promises = []

                    promises.push(this.updatePerson(primaryApplicant))

                    if(this.isJoint) {
                        promises.push(this.updatePerson(secondaryApplicant)) 
                    }

                    await Promise.all(promises)

                    const {valid, status} = await this.validateFormData()

                    return {valid, status}

                } catch (error) {

                    console.log(error)
                    return {valid: false, status: 'dirty'}
                }
                
            },
            filterFields(data){

                let attributes = {}

                this.formFields.forEach(field => {

                    if(data.attributes.hasOwnProperty(field)) {
                        attributes[field] = data.attributes[field]
                    }

                })

                return {
                    type: data.type,
                    id: data.id,
                    attributes
                }

            },
            isIncluded(field){

                const {included} = this

                if(included.hasOwnProperty(field)) {
                    return included[field]
                }

                return true
            },
            isReadOnly(key){
                
                const {readonly} = this

                if(readonly.hasOwnProperty(key)){
                    return readonly[key]
                }

                return false

            },
            isNumber(key){
                return [
                ].includes(key)
            },
            getLabel(index) {
                if(this.toSingleColFields) {
                    return `Applicant ${index + 1}`
                }
                return ''
            },
            isMultiSelectType(key) {
                return [
                    "lender-pepper-loan-looking-for"
                ].includes(key)
            },
            isRowVisibleLogic(key){

                if('lender-pepper-term-suit-details' == key) {
                    if(this.fieldTermSuit1 == 'Other' && this.fieldTermSuit2 == 'Other') {
                        return false
                    }
                }

                if('lender-pepper-change-expected' == key) {
                    if(this.fieldForeseeableChange1 == 0 && this.fieldForeseeableChange2 == 0) {
                        return false
                    }
                }

                if('lender-pepper-loan-repayment-after-change' == key) {
                    if(this.fieldForeseeableChange1 == 0 && this.fieldForeseeableChange2 == 0) {
                        return false
                    }
                }

                if('lender-pepper-is-balloon-payment' == key) {
                    if(!this.getRequestedBaloon) {
                        return false
                    }
                }

                return true
            },
        },
        watch: {
            fieldTermSuit1(val){
                if(val == "Other") {
                    this.included['lender-pepper-term-suit-details-0'] = false
                    this.resources.primaryApplicant.attributes['lender-pepper-term-suit-details'] = ""
                } else {
                    this.included['lender-pepper-term-suit-details-0'] = true
                }
            },
            fieldTermSuit2(val){
                if(val == "Other") {
                    this.included['lender-pepper-term-suit-details-1'] = false
                    this.resources.secondaryApplicant.attributes['lender-pepper-term-suit-details'] = ""
                } else {
                    this.included['lender-pepper-term-suit-details-1'] = true
                }
            },
            fieldForeseeableChange1(val){
                if(val == 1) {
                    this.included['lender-pepper-change-expected-0'] = true
                    this.included['lender-pepper-loan-repayment-after-change-0'] = true
                } else {
                    this.included['lender-pepper-change-expected-0'] = false
                    this.included['lender-pepper-loan-repayment-after-change-0'] = false
                    this.resources.primaryApplicant.attributes['lender-pepper-change-expected'] = ""
                    this.resources.primaryApplicant.attributes['lender-pepper-loan-repayment-after-change'] = ""
                }
            },
            fieldForeseeableChange2(val){
                if(val == 1) {
                    this.included['lender-pepper-change-expected-1'] = true
                    this.included['lender-pepper-loan-repayment-after-change-1'] = true
                } else {
                    this.included['lender-pepper-change-expected-1'] = false
                    this.included['lender-pepper-loan-repayment-after-change-1'] = false
                    this.resources.secondaryApplicant.attributes['lender-pepper-change-expected'] = ""
                    this.resources.secondaryApplicant.attributes['lender-pepper-loan-repayment-after-change'] = ""
                }
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>

