var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"mx-auto",attrs:{"disabled":_vm.isDisabled || !_vm.validComponentData,"tile":"","flat":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":_vm.standalone ? _vm.$vuetify.breakpoint.lgAndUp ? 6 : 12 : 12}},[_c('v-card-text',{staticClass:"pa-4 pt-4 pa-md-10 pt-md-0"},[_c('div',{staticClass:"title",class:_vm.$vuetify.breakpoint.smAndUp ? 'pb-4 pt-2' : ''},[_vm._v("\n                    Purchases\n                ")]),_vm._v(" "),_c('FlexWrap',{on:{"resize":_vm.handleResize}},[_c('v-data-table',{attrs:{"headers":_vm.tableHeaders,"items":_vm.resources.purchases,"hide-default-footer":"","items-per-page":_vm.numberOfPurchases,"mobile-breakpoint":0},scopedSlots:_vm._u([{key:"item.image",fn:function(ref){
var item = ref.item;
return [_c('v-avatar',{staticClass:"my-2 light_gray_1",attrs:{"size":"50px","rounded":""}},[(!_vm.isMotorVehicle(item))?_c('v-icon',{staticClass:"light_gray_1",attrs:{"color":"light_gray_2","x-large":""},domProps:{"textContent":_vm._s(_vm.getIcons(item))}}):(_vm.fetchImage(item.id))?_c('v-img',{attrs:{"lazy-src":_vm.fetchImage(item.id),"src":_vm.fetchImage(item.id),"aspect-ratio":1,"height":"50","width":"50"}}):_c('p-image',{attrs:{"item":item.attributes.nvic}})],1)]}},{key:"item.condition",fn:function(ref){
var item = ref.item;
return [_vm._v("\n                        "+_vm._s(item.attributes.condition)+"\n                    ")]}},{key:"item.description",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"cell"},[_c('div',{staticClass:"text-truncate"},[_vm._v(_vm._s(_vm.purchaseDescription(item)))])])]}},{key:"item.purchase-price",fn:function(ref){
var item = ref.item;
return [_vm._v("\n                        "+_vm._s(_vm._f("currency")(item.attributes['purchase-price']))+"\n                    ")]}},{key:"item.required-loan-amount",fn:function(ref){
var item = ref.item;
return [(item.attributes['required-loan-amount'])?_c('span',[_vm._v("\n                            "+_vm._s(_vm._f("currency")(item.attributes['required-loan-amount']))+"\n                        ")]):_c('span')]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"inline-icons"},[(_vm.referencedAndQuotable)?_c('EditPurchase',{attrs:{"force-open":_vm.referencedAndQuotable,"description":_vm.purchaseDescription(item),"purchased-item":item},on:{"confirmed-update":_vm.saveUpdatedPurchase}}):_c('EditPurchase',{attrs:{"description":_vm.purchaseDescription(item),"purchased-item":item},on:{"confirmed-update":_vm.saveUpdatedPurchase}}),_vm._v(" "),_c('DeletePurchase',{attrs:{"color":"light_gray_2","confirm-color":"error","button-type":"icon","id":item.id,"title":"Delete Purchase","confirm-text":"Delete","message":"Are you sure you want to remove the selected purchase?"},on:{"confirmed-action":_vm.removeSelectedPurchase}})],1)]}}],null,true)})],1),_vm._v(" "),_c('v-card-actions',{staticClass:"px-0 pt-4 pb-0"},[_c('v-spacer'),_vm._v(" "),_c('AddPurchase',{attrs:{"asset-icons":_vm.assetIcons},on:{"on-data-change":_vm.updatePurchaseObj,"confirmed-save":_vm.saveNewPurchase}})],1)],1)],1),_vm._v(" "),_c('v-col',{attrs:{"cols":_vm.standalone ? _vm.$vuetify.breakpoint.lgAndUp ? 6 : 12 : 12}},[_c('v-card-text',{staticClass:"pa-4 pt-4 pa-md-10 pt-md-0",class:_vm.standalone ? _vm.$vuetify.breakpoint.lgAndUp ? 'faint-line' : '' : ''},[_c('div',{staticClass:"title mb-4"},[_vm._v("Loan Details")]),_vm._v(" "),_c('v-container',{attrs:{"pa-0":"","fluid":""}},[_c('ValidationObserver',{ref:"observer",attrs:{"tag":"form"}},[_c('v-row',_vm._l((_vm.resources.loan.attributes),function(field,key){return _c('v-col',{key:key,staticClass:"py-0",attrs:{"cols":_vm.$vuetify.breakpoint.smAndUp ? 6 : 12}},[(_vm.isVisible(key))?_c('ValidationProvider',{attrs:{"name":_vm.labels[key],"rules":_vm.getRules(key),"vid":key},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [(_vm.isFieldType('picklist', key))?_c('v-select',{attrs:{"items":_vm.options[key],"item-text":"key","item-value":"value","error-messages":errors,"color":"secondary","suffix":_vm.suffix[key],"label":_vm.labels[key]},model:{value:(_vm.resources.loan.attributes[key]),callback:function ($$v) {_vm.$set(_vm.resources.loan.attributes, key, $$v)},expression:"resources.loan.attributes[key]"}}):[_c('NodifiMoney',{attrs:{"label":_vm.labels[key],"disabled":key === 'requested-required-loan-amount' ? true : false,"errors":errors},model:{value:(_vm.resources.loan.attributes[key]),callback:function ($$v) {_vm.$set(_vm.resources.loan.attributes, key, $$v)},expression:"resources.loan.attributes[key]"}})]]}}],null,true)}):_vm._e()],1)}),1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }