<template>
<div>
    <v-dialog v-model="dialog" persistent scrollable max-width="1800">

        <v-card class="full-height" elevation="0" :disabled="isLoading">

            <v-progress-linear v-if="isLoading" color="secondary" indeterminate stream top value="0"> </v-progress-linear>

            <v-card-title class="pa-8 pb-4 align-center">
                    <div class="title font-size-32"> 
                        Application Summary 
                    </div>
                <v-spacer />
                <v-btn icon @click="close" >
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-card-title>

            <v-card-text class="pa-8 pt-0">
                
                <v-expansion-panels flat :value="[0,1,2,3,4]" multiple>

                    <v-expansion-panel readonly>
                        <v-expansion-panel-header class="pa-0" expand-icon="">
                            <div class="d-flex align-center">
                                <v-btn v-if="isLender" @click.stop="formType = 'key-information'; showEditorForm = true" elevation="0" icon depressed class="mr-2">
                                    <v-icon size="20">mdi-pencil</v-icon>
                                </v-btn>
                                <span class="title">Key Information</span>
                            </div>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>

                            <v-row>
                                <v-col cols="12"  md="6" lg="3" class="px-4 px-lg-8">
                                    <div class="d-flex justify-space-between align-center mt-5 mb-4">
                                        <div>Submitted</div>
                                        <div>{{keyInformation['submitted-date'] | dateFormat | isEmpty}}</div>
                                    </div>

                                    <div class="d-flex justify-space-between align-center mb-4">
                                        <div>Approved</div>
                                        <div>{{keyInformation['approval-date'] | dateFormat | isEmpty}}</div>
                                    </div>

                                    <div class="d-flex justify-space-between align-center mb-4">
                                        <div>Settled</div>
                                        <div>{{keyInformation['settlement-date'] | dateFormat | isEmpty}}</div>
                                    </div>

                                    <div class="d-flex justify-space-between align-center mb-4">
                                        <div>Last Updated</div>
                                        <div>{{keyInformation['updated-at'] | dateFormat}}</div>
                                    </div>
                                </v-col>
                                <v-col cols="12" md="6" lg="3" class="px-5 d-none d-lg-block"></v-col>
                                <v-col cols="12"  md="6" lg="3" class="px-4 px-lg-8">
                                    <div class="d-flex justify-space-between align-center mt-5 mb-4">
                                        <div>Credit Officer</div>
                                        <div>{{keyInformation['credit-officer-name'] | isEmpty}}</div>
                                    </div>

                                    <div class="d-flex justify-space-between align-center mb-4">
                                        <div>Settlement Officer</div>
                                        <div>{{keyInformation['settlement-officer-name'] | isEmpty}}</div>
                                    </div>

                                    <div class="d-flex justify-space-between align-center mb-4">
                                        <div>Total Commission</div>
                                        <div>{{keyInformation['actual-total-commission'] | currency}}</div>
                                    </div>
                                    <div class="d-flex justify-space-between align-center mb-4">
                                        <div>Partner Split</div>
                                        <div>{{keyInformation['actual-partner-split'] | currency}}</div>
                                    </div>

                                <v-col cols="12" md="6" lg="3" class="px-5 d-none d-lg-block"></v-col>
                                </v-col>
                            </v-row>
                        </v-expansion-panel-content>
                    </v-expansion-panel>

                    <v-expansion-panel v-if="Object.keys(loanData).length" readonly>
                        <v-expansion-panel-header class="pa-0" expand-icon="">
                            <div class="d-flex align-center">
                                <v-btn v-if="!isApplicationLocked && isLender" @click.stop="formType = 'loan-details'; showEditorForm = true" elevation="0" icon depressed class="mr-2">
                                    <v-icon size="20">mdi-pencil</v-icon>
                                </v-btn>
                                <span class="title">Loan Details</span>
                            </div>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                            
                            <v-row>
                                <v-col cols="12"  md="6" lg="3" class="px-4 px-lg-8">
                                    
                                    <div class="d-flex justify-space-between align-center">
                                        <v-img 
                                            v-if="lenderLogo"
                                            max-width="120px"
                                            class="flex-brand" 
                                            :src="require(`@/assets/images/logo/cropped/${lenderLogo}.png`)" 
                                            position="center center" 
                                            contain>
                                        </v-img>

                                        <h3 v-else class="">No Lender Selected</h3>
                                    </div>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12"  md="6" lg="3" class="px-4 px-lg-8">

                                    <div class="d-flex justify-space-between align-center mb-4">
                                        <div>Lender Reference</div>
                                        <div>{{ loanData['actual-lender-reference'] | isEmpty }}</div>
                                    </div>

                                    <div class="d-flex justify-space-between align-center mb-4">
                                        <div>Required Loan Amount</div>
                                        <div>{{loanData['actual-required-loan-amount'] | currency}}</div>
                                    </div>

                                    <div class="d-flex justify-space-between align-center mb-4">
                                        <div>Base Rate</div>
                                        <div>{{loanData['actual-base-rate'] ? loanData['actual-base-rate'] : 0 }}%</div>
                                    </div>

                                    <div class="d-flex justify-space-between align-center mb-4">
                                        <div>Payment Amount</div>
                                        <div>{{loanData['budgeted-payment-amount'] | currency}}</div>
                                    </div>

                                    
                                </v-col>
                                <v-col cols="12"  md="6" lg="3" class="px-4 px-lg-8">
                                    
                                    <div class="d-flex justify-space-between align-center mb-4">
                                        <div>Loan Term</div>
                                        <div>{{loanData['actual-term-months'] == null ? '-' : `${loanData['actual-term-months']} Months` }} </div>
                                    </div>

                                    <div class="d-flex justify-space-between align-center mb-4">
                                        <div>Origination Fee</div>
                                        <div>{{loanData['actual-broker-fee'] | currency}}</div>
                                    </div>

                                    <div class="d-flex justify-space-between align-center mb-4">
                                        <div>Loan Rate</div>
                                        <div>{{loanData['actual-customer-rate'] ? loanData['actual-customer-rate'] : 0}}%</div>
                                    </div>

                                    <div class="d-flex justify-space-between align-center mb-4">
                                        <div>Payment Frequency</div>
                                        <div>{{loanData['actual-payment-frequency'] == null ? '-' : loanData['actual-payment-frequency'] }}</div>
                                    </div>

                                    
                                </v-col>
                                <v-col cols="12"  md="6" lg="3" class="px-4 px-lg-8">
                                    
                                        <div class="d-flex justify-space-between align-center mb-4">
                                        <div>Payment Terms</div>
                                        <div>{{loanData['actual-payment-terms']}}</div>
                                    </div>

                                    <div class="d-flex justify-space-between align-center mb-4">
                                        <div>Total Bank Fees</div>
                                        <div>{{loanData['actual-bank-fees'] | currency}}</div>
                                    </div>

                                    <div class="d-flex justify-space-between align-center mb-4">
                                        <div>Brokerage</div>
                                        <div>{{loanData['actual-broker-commission-inc-gst'] | currency}}</div>
                                    </div>

                                    <div class="d-flex justify-space-between align-center mb-4">
                                        <div>Monthly Payment</div>
                                        <div>{{loanData['actual-monthly-payments'] | currency}}</div>
                                    </div>

                                </v-col>
                                <v-col cols="12"  md="6" lg="3" class="px-4 px-lg-8">
                                    
                                    <div class="d-flex justify-space-between align-center mb-4">
                                        <div>Balloon</div>
                                        <div>{{loanData['actual-balloon'] | currency}}</div>
                                    </div>

                                    <div class="d-flex justify-space-between align-center mb-4">
                                        <div>Total Amount Financed</div>
                                        <div>{{loanData['actual-total-amount-financed'] | currency}}</div>
                                    </div>

                                    
                                </v-col>
                            </v-row>
                        </v-expansion-panel-content>
                    </v-expansion-panel>

                    <v-expansion-panel v-if="financialPosition" readonly>
                        <v-expansion-panel-header class="pa-0" expand-icon="">
                            <div class="d-flex align-center">
                                <span class="title">Finance Position</span>
                            </div>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <v-row>
                                <v-col cols="12"  md="6" lg="3" class="px-4 px-lg-8">
                                    <div class="d-flex justify-space-between align-center mt-5 mb-4">
                                        <div class="font-weight-bold">Servicing</div>
                                    </div>

                                    <div
                                        v-for="(value, key) in financialPosition['servicing']['total-income']"
                                        :key="key"
                                        class="d-flex justify-space-between align-center mb-4"
                                    >
                                        <div>{{key}}</div>
                                        <div>{{financialPosition['servicing']['total-income'][key] | currency}}</div>
                                    </div>

                                </v-col>
                                <v-col cols="12" md="6" lg="3" class="px-5 d-none d-lg-block"></v-col>
                                <v-col cols="12"  md="6" lg="3" class="px-4 px-lg-8">
                                    <div class="d-flex justify-space-between align-center mt-5 mb-4">
                                    </div>
                                    <div class="d-flex justify-space-between align-center mt-5 mb-4">
                                        <div>Financial Expenses</div>
                                        <div>{{financialPosition['servicing']['total-expenses']['financial-expense'] | currency}}</div>
                                    </div>

                                    <div class="d-flex justify-space-between align-center mb-4">
                                        <div>General Expenses</div>
                                        <div>{{financialPosition['servicing']['total-expenses']['general-expense'] | currency}}</div>
                                    </div>

                                    <div class="d-flex justify-space-between align-center mb-4">
                                        <div class="font-weight-bold font-italic">This Commitment</div>
                                        <div>{{financialPosition['servicing']['commitment'] | currency}}</div>
                                    </div>
                                    <div class="d-flex justify-space-between align-center mb-4">
                                        <div class="font-weight-bold">Surplus</div>
                                        <div>{{financialPosition['servicing']['total-surplus'] | currency}}</div>
                                    </div>
                                </v-col>
                                <v-col cols="12" md="6" lg="3" class="px-5 d-none d-lg-block"></v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12"  md="6" lg="3" class="px-4 px-lg-8">
                                    <div class="d-flex justify-space-between align-center mt-5 mb-4">
                                        <div class="font-weight-bold">Asset Position</div>
                                    </div>
                                    <div class="d-flex justify-space-between align-center mt-5 mb-4">
                                        <div>Asset</div>
                                        <div>{{financialPosition['asset-position']['total-assets'] | currency}}</div>
                                    </div>
                                </v-col>
                                <v-col cols="12" md="6" lg="3" class="px-5 d-none d-lg-block"></v-col>
                                <v-col cols="12"  md="6" lg="3" class="px-4 px-lg-8">
                                    <div class="d-flex justify-space-between align-center mt-5 mb-4">
                                    </div>
                                    <div class="d-flex justify-space-between align-center mt-5 mb-4">
                                        <div>Liability</div>
                                        <div>{{financialPosition['asset-position']['total-liabilities'] | currency}}</div>
                                    </div>

                                    <div class="d-flex justify-space-between align-center mb-4">
                                        <div>Net Position</div>
                                        <div>{{financialPosition['asset-position']['total-net-position'] | currency}}</div>
                                    </div>
                                </v-col>
                                <v-col cols="12" md="6" lg="3" class="px-5 d-none d-lg-block"></v-col>
                            </v-row>
                        </v-expansion-panel-content>
                    </v-expansion-panel>

                    <v-expansion-panel readonly>
                        <v-expansion-panel-header class="pa-0" expand-icon="">
                            <div class="d-flex align-center">
                                <v-btn v-if="isLender" @click.stop="formType = 'approval-condition'; showEditorForm = true" elevation="0" icon depressed class="mr-2">
                                    <v-icon size="20">mdi-pencil</v-icon>
                                </v-btn>
                                <span class="title">Approval Condition</span>
                            </div>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <v-row>
                                <v-col>
                                    <div style="min-height: 100px" class="pa-6 light_gray_1" v-html="keyInformation['approval-condition']"></div>
                                </v-col>
                            </v-row>
                        </v-expansion-panel-content>
                    </v-expansion-panel>

                    <v-expansion-panel readonly>
                        <v-expansion-panel-header class="pa-0" expand-icon="">
                            <div class="d-flex align-center">
                                <v-btn v-if="isLender" @click.stop="formType = 'settlement-requirements'; showEditorForm = true" elevation="0" icon depressed class="mr-2">
                                    <v-icon size="20">mdi-pencil</v-icon>
                                </v-btn>
                                <span class="title">Settlement Requirements</span>
                            </div>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <v-row>
                                <v-col>
                                    <div style="min-height: 100px" class="pa-6 light_gray_1" v-html="keyInformation['settlement-docs-req']"></div>
                                </v-col>
                            </v-row>
                        </v-expansion-panel-content>
                    </v-expansion-panel>

                </v-expansion-panels>
            </v-card-text>

        </v-card>

    </v-dialog>

    <ApplicationSummaryEditor 
        v-model="showEditorForm" 
        :formType="formType"

        :keyInformation="keyInformation"
        :loanDetails="loanData"

        :appId="getAppId"
        :loanId="getLoanId"

        @updateLoans="setLoans"
        @updateApplication="setApplication"
        @on-update="handleAppSummaryUpdate"
    />

</div>
</template>


<script>

import { mapGetters, mapActions, mapState } from 'vuex';
import lenders from '~/config/lenders-slug-algo'
import ApplicationSummaryEditor from './ApplicationSummaryEditor'

export default {
    name: 'ApplicationSummary',
    props: {
        value: {
            type: Boolean,
            default: false
        },
        appData: {
            type: Object,
            default: null
        }
    },
    components: {
        ApplicationSummaryEditor
    },
    data () {
        return {
            appSummary: {
                data: {
                    'finance-position': {
                        servicing: {
                            'total-income': {},
                            'total-expenses': {}
                        },
                        'asset-position': {}
                    },
                    'key-information': {},
                    'loan-details': {}
                },
                links: {
                    application: {},
                    loan: {
                        meta: {}
                    }
                }
            },
            dialog: this.value,
            showEditorForm: false,
            formType: '',
            isLoading: false,
        }
    },
    watch: {
        value (val) {
            this.dialog = val
        }
    },
    async created () {
        this.getSummary()
    },
    computed: {
        keyInformation () {
            return this.appSummary.data['key-information']
        },
        financialPosition () {
            return this.appSummary.data['finance-position']
        },
        loanData () {
            return this.appSummary.data['loan-details']
        },
        appSummaryLinks () {
            return this.appSummary.links
        },
        lenderLogo () {
            const asset = this.loanData['selected-lender-quote-algorithm-slug']
            
            if (asset) {
                const lenderType = asset.includes('personal') ? 'personal' : 'asset'
                const assetData = lenders[lenderType].find(item => item['lender-slug-algorithm'] === asset)

                if(assetData && assetData['logo-class']) {
                    return assetData['logo-class']
                } else {
                    return 'placeholder'
                }
            }

            return 'placeholder'
        },
        getLoanId () {
            return this.appSummaryLinks.loan.meta.id
        },

        isLender () {
            if (this.appData) {
                return ['Lender', 'Other'].includes(this.appData.attributes.target)
            }
            return false
        },
        isApplicationLocked () {
            if (this.appData) {
                return this.appData.attributes.locked == 1
            }
            return false
        },
        getAppId () {
            return this.appData.id 
         },
        getAppGuid () {
            return this.appData?.attributes?.guid
         },
        
    },
    methods: {
        ...mapActions('resource-actions', [
            'runApplicationSummary',
        ]),
        async getSummary () {

            try {

                this.isLoading = true

                const data = await this.runApplicationSummary({ 
                    application: this.getAppGuid 
                })

                this.appSummary = data.data
                this.isLoading = false

            } catch (error) {

                this.isLoading = false
                console.log(error)
                
            }
        },
        close () {
            this.dialog = false
            this.$emit('input', false)
        },
        setLoans () {
            this.getSummary()
        },
        setApplication () {
            this.getSummary()
        },
        handleAppSummaryUpdate (app) {
            this.$emit('on-update', app)
        },
    }
}
</script>

<style lang="scss" scoped>
.btn-close {
    z-index: +999;
}
</style>