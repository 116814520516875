<template>
    <v-card 
        :disabled="isDisabled || blockPermissionsReadOnly" 
        class="mx-auto" 
        tile flat>
        <v-card-text class="pa-4 pa-md-10 pt-md-0 pb-10">
            <v-container pa-0 fluid>
                <ValidationObserver ref="observer" tag="form">
                    <v-row>
                        <template v-for="(fieldGroup, fkey) in fields">
                            <v-col
                                :cols="$vuetify.breakpoint.smAndUp ? '6' : '12'"
                                :class="$vuetify.breakpoint.mdAndUp && fkey === 0 ? 'faint-line' : ''"
                                :key="fkey"
                            >

                                <div :class="fkey === 0 ? 'pr-0 pr-md-4' : 'pl-0 pl-md-4'">
                                    <p class="title pt-6"> {{ fieldGroup.title }} </p>
                                    <div v-for="(field, key) in fieldGroup.items" :key="key">
                                        <ValidationProvider
                                            v-slot="{ errors }"
                                            :name="field.shortMessage | formalText"
                                            :rules="getRules(key)"
                                            :vid="field.meta">

                                            <v-textarea
                                                v-if="field.type === 'textarea'"
                                                v-model="cpdd[field.meta]"
                                                rows="1"
                                                row-height="10"
                                                :error-messages="errors"
                                                :label="field.label"
                                            >
                                            </v-textarea>

                                            <NodifiMoney
                                            v-else-if="field.type === 'money'"
                                                v-model="cpdd[field.meta]"
                                                :label="field.label"
                                                :errors="errors" />

                                            <ButtonSelections 
                                                v-else-if="field.type === 'radio'"
                                                v-model="cpdd[field.meta]"
                                                :error-messages="errors"
                                                :options="['Yes', 'No']"
                                                :label="field.label"
                                            >
                                                <template v-slot:tooltip>
                                                    <v-tooltip
                                                        right
                                                        max-width="250px"
                                                    >
                                                        <template v-slot:activator="{ on, attrs }">
                                                            <v-icon
                                                                class="mt-n2"
                                                                size="17"
                                                                v-bind="attrs"
                                                                v-on="on"
                                                            >
                                                                mdi-information
                                                            </v-icon>
                                                        </template>
                                                        <span>{{ field.tooltipMessage }}</span>
                                                    </v-tooltip>
                                                </template>
                                            </ButtonSelections>
                                            
                                            <PriorityRatings 
                                                v-else-if="field.type === 'array'"
                                                v-model="cpdd[field.meta]"
                                                :error-messages="errors"
                                                :default-item="defaultItem"
                                                :label="field.label"
                                            >
                                            <template v-slot:tooltip>
                                                <v-tooltip
                                                    right
                                                >
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <v-icon
                                                            size="17"
                                                            class="mt-n2"
                                                            v-bind="attrs"
                                                            v-on="on"
                                                        >
                                                            mdi-information
                                                        </v-icon>
                                                    </template>
                                                    <span>{{ field.tooltipMessage }}</span>
                                                </v-tooltip>
                                            </template>
                                            </PriorityRatings>

                                            <template v-else>
                                                <div class="mb-n8 mt-4">
                                                    <span class="font-weight-bold">
                                                        {{ field.label }}
                                                    </span>
                                                    <v-tooltip
                                                        v-if="field.tooltipMessage"
                                                        right
                                                    >
                                                        <template v-slot:activator="{ on, attrs }">
                                                            <v-icon
                                                                class="mt-n2"
                                                                size="17"
                                                                v-bind="attrs"
                                                                v-on="on"
                                                            >
                                                                mdi-information
                                                            </v-icon>
                                                        </template>
                                                        <span>{{ field.tooltipMessage }}</span>
                                                    </v-tooltip>
                                                </div>
                                                <div class="mt-4">
                                                    <v-text-field
                                                        v-model="cpdd[field.meta]"
                                                        :error-messages="errors"
                                                    >
                                                    </v-text-field>
                                                </div>
                                            </template>

                                        </ValidationProvider>
                                    </div>
                                </div>

                            </v-col>
                        </template>
                    </v-row>
                </ValidationObserver>
            </v-container>            

         </v-card-text>
    </v-card>
</template>

<script>

    import { mapActions, mapGetters, mapMutations } from 'vuex'
    import subscriptions from '~/components/shared/subscriptions'
    import ButtonSelections from '~/components/base/ButtonSelections'
    import PriorityRatings from '~/components/v1/BidEditor1/_BidPriorityRatings'    

    export default {
        mixins: [ subscriptions ],
        components: { 
            ButtonSelections,
            PriorityRatings
        },
        computed: {
            getLoanId(){
                return this.appData.relationships.loan.data.id
            }
        },
        mounted() {

        },
        methods: {
            ...mapActions('resource-actions', [
                'createCreditProposalDisclosures',
                'updateCreditProposalDisclosures',
                'getCreditProposalDisclosures',
            ]),
            stringHelper(val) {
                val = val.toString()
                return '[' + val + ']'
            },
            async pull() {

                // Get the current CPDD
                let response = await this.getCreditProposalDisclosures(this.appGuid)

                if(response.status === 200) {

                    this.activeCpdd = response.data.data.find( cpdd => cpdd.status === 'Current')

                    if(this.activeCpdd) {

                        let priorityRating = []

                        if(this.activeCpdd?.priorities){
                            priorityRating = this.activeCpdd.priorities.replace(/[\[\]]/g, '');
                            priorityRating = priorityRating.split(',')
                            this.defaultItem = []
                        }

                        this.cpdd = {
                            client_lender_preference: this.activeCpdd?.client_lender_preference,
                            why_this_lender: this.activeCpdd?.why_this_lender,
                            client_budgeted_monthly_repayment: this.activeCpdd?.client_budgeted_monthly_repayment,
                            client_additional_payments: this.activeCpdd?.client_additional_payments,
                            priorities: priorityRating,
                            other_priorities: this.activeCpdd?.other_priorities,
                            needs_objectives: this.activeCpdd?.needs_objectives,
                            // looking_for: this.activeCpdd?.looking_for,
                            client_settlement: this.activeCpdd?.client_settlement
                        }
                    }
                }

            },
            async push() {

                const valid = await this.$refs.observer.validate()

                let requestPayload = Object.assign({}, this.cpdd)

                if( valid ) {

                    let payload = {
                        appGuid: this.appGuid,
                        data: requestPayload,
                    }

                    payload.data['priorities'] = this.stringHelper(payload.data['priorities'])

                    // if(this.activeCpdd) {
                    //     await this.updateCreditProposalDisclosures(payload)
                    //     return  { valid, status: 'valid', data: {} } 
                    // } else {
                        
                        await this.createCreditProposalDisclosures(payload)
                        return  { valid, status: 'valid', data: {} } 

                    // }

                } else {

                    return  { valid, status: 'dirty' } 
                }
                
            },
        },

        data() {
            return {
                defaultItem: ['Low Early Payout Fees', 'Fast Settlement', 'Lowest Repayments', 'Loan Flexibility', 'Maximum Pre-Approval Limit'],
                activeCpdd: undefined,
                cpdd: {
                    client_lender_preference: '',
                    why_this_lender: '',
                    client_budgeted_monthly_repayment: '',
                    client_additional_payments: '',
                    priorities: [],
                    other_priorities: '',
                    needs_objectives: '',
                    // looking_for: '',
                    client_settlement: ''
                },
                fields: [
                    {
                        title: 'Initial Preferences',
                        items: {
                            'client_lender_preference':
                                { 
                                    label: 'What is the customers preferred lender?', 
                                    meta: 'client_lender_preference', 
                                    type: 'text',
                                    shortMessage: 'Preferred Lender',
                                    tooltipMessage: ''
                                },    
                            'why_this_lender':
                                { 
                                    label: 'Why this Lender?',
                                    meta: 'why_this_lender', 
                                    type: 'textarea',
                                    shortMessage: 'Why this Lender' ,
                                    tooltipMessage: ''
                                },                        
                            'client_budgeted_monthly_repayment':
                                { 
                                    label: 'Monthly Repayment', 
                                    meta: 'client_budgeted_monthly_repayment', 
                                    type: 'money',
                                    shortMessage: 'Monthly Repayment' ,
                                    tooltipMessage: ''
                                },
                            'client_additional_payments':
                                { 
                                    label: 'Additional Payments?', 
                                    meta: 'client_additional_payments', 
                                    type: 'radio',
                                    shortMessage: 'Is Additional Payments' ,
                                    tooltipMessage: 'Is the client intending to any large additional payments (payment events), or do they expect to be regularly making additional payments on the loan that are outside the payment schedule?'
                                },
                            'client_settlement':
                                { 
                                    label: 'When Does the Loan Need to Settle?', 
                                    meta: 'client_settlement', 
                                    type: 'text',
                                    shortMessage: 'Settle By' ,
                                    tooltipMessage: ''
                                },
                        }
                    },
                    {
                        title: 'Customer Priorities',
                        items: {
                            'priorities':
                                {
                                    label: 'Customer Priorities Rating', 
                                    meta: 'priorities', 
                                    type: 'array',
                                    shortMessage: 'Rating on Customer Prior',
                                    tooltipMessage: 'Please rate the customer prior'
                                },
                            'other_priorities':
                                {
                                    label: 'Other Customer Priorities',
                                    meta: 'other_priorities', 
                                    type: 'textarea',
                                    shortMessage: 'Other Priorities',
                                    tooltipMessage: ''
                                },
                            'needs_objectives':
                                {
                                    label: "What are the client's immediate goals and objectives?",
                                    meta: 'needs_objectives', 
                                    type: 'textarea',
                                    shortMessage: 'Immediate goals and objectives',
                                    tooltipMessage: ''
                                },
                            // 'looking_for':
                            //     {
                            //         label: 'What is the client looking to do?',
                            //         meta: 'looking_for', 
                            //         type: 'textarea',
                            //         shortMessage: 'Looking For',
                            //         tooltipMessage: ''
                            //     }
                        }
                    }
                ],
                validation_rules: {
                    attributes: {
                        "client_lender_preference": "required",
                        "why_this_lender": "required",
                        "client_budgeted_monthly_repayment": "required",
                        "client_additional_payments": "required",
                        "priorities": "required|array|min:5",
                        "client_settlement": "required",
                        "other_priorities": "required",
                        "needs_objectives": "required",
                        // "looking_for": "required",
                    }
                },
            }
        }
    }
</script>

<style lang="scss" scoped>
    .faint-line {
        border-right: 2px solid var(--v-light_gray_1-base) !important
    }
    .flex-form {
        display: grid;
        grid-column-gap: 15px;
        grid-row-gap: 0;
        grid-template-columns:  repeat(auto-fit, minmax(260px, 1fr));

        .flex-field {
            align-self: start;
        }
    }
</style>