var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"mx-auto",attrs:{"disabled":_vm.isDisabled || !_vm.validComponentData,"tile":"","flat":""}},[_c('v-card-text',{staticClass:"pa-4 pt-0 pa-md-10 pt-md-0"},[_c('v-container',{attrs:{"fluid":"","pa-0":""}},[_c('v-row',[_c('v-col',{staticClass:"pt-0",attrs:{"cols":"12"}},[_c('ValidationObserver',{ref:"observer",attrs:{"tag":"form"}},[_c('div',{staticClass:"title mb-4 body_text_color--text"},[_vm._v("\n                            Purchase Type\n                        ")]),_vm._v(" "),_c('div',{staticClass:"flex-form"},[_c('div',{staticClass:"flex-field"},[_c('ValidationProvider',{attrs:{"name":"Sale Type","rules":_vm.getRules('sale-type'),"vid":"sale-type"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"items":_vm.getOptions('sale-type'),"error-messages":errors,"color":"secondary","tile":"","label":"Sale Type"},model:{value:(_vm.resourceObj.loans.attributes['sale-type']),callback:function ($$v) {_vm.$set(_vm.resourceObj.loans.attributes, 'sale-type', $$v)},expression:"resourceObj.loans.attributes['sale-type']"}})]}}])})],1),_vm._v(" "),_c('div',{staticClass:"flex-field"},[_c('ValidationProvider',{attrs:{"name":"Asset Type","rules":"required","vid":"asset_type"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"items":_vm.assetTypeList,"item-text":"name","item-value":"id","color":"secondary","error-messages":errors,"label":"Asset Type"},model:{value:(_vm.assetType),callback:function ($$v) {_vm.assetType=$$v},expression:"assetType"}})]}}])})],1),_vm._v(" "),_c('div',{staticClass:"flex-field"},[(_vm.assetType && (_vm.assetSubTypeList.length > 1))?_c('ValidationProvider',{attrs:{"name":"Asset Sub-Type","rules":"required","vid":"sub_asset_type"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"items":_vm.assetSubTypeList,"item-text":"name","item-value":"id","color":"secondary","error-messages":errors,"label":"Asset Sub-Type"},model:{value:(_vm.assetSubType),callback:function ($$v) {_vm.assetSubType=$$v},expression:"assetSubType"}})]}}],null,false,828455321)}):_vm._e()],1),_vm._v(" "),_vm._l((6),function(index){return _c('div',{key:index,staticClass:"flex-field"})})],2),_vm._v(" "),_c('div',{staticClass:"title my-4 body_text_color--text"},[_vm._v("\n                            Purchase Details\n                        ")]),_vm._v(" "),_vm._l((_vm.fieldLayout),function(layout){return _c('div',{staticClass:"flex-form"},[_vm._l((_vm.fieldLayoutMaker(layout, _vm.resourceObj.purchases.attributes)),function(field,key){return [_c('div',{key:key,staticClass:"flex-field"},[_c('ValidationProvider',{attrs:{"name":_vm.labels[key],"rules":_vm.getRules(key),"vid":key},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [(key == 'nvic')?_c('nvic-finder',{attrs:{"label":_vm.labels[key],"errors":errors,"default-value":_vm.resourceObj.purchases.attributes[key]},on:{"accept":_vm.handleNvicAccept},model:{value:(_vm.resourceObj.purchases.attributes[key]),callback:function ($$v) {_vm.$set(_vm.resourceObj.purchases.attributes, key, $$v)},expression:"resourceObj.purchases.attributes[key]"}}):(_vm.isFieldType('picklist', key))?_c('v-select',{attrs:{"items":_vm.getOptions(key),"error-messages":errors,"color":"secondary","label":_vm.labels[key]},model:{value:(_vm.resourceObj.purchases.attributes[key]),callback:function ($$v) {_vm.$set(_vm.resourceObj.purchases.attributes, key, $$v)},expression:"resourceObj.purchases.attributes[key]"}}):(_vm.isFieldType('date', key))?_c('flex-date-picker',{attrs:{"label":_vm.labels[key],"errors":errors},model:{value:(_vm.resourceObj.purchases.attributes[key]),callback:function ($$v) {_vm.$set(_vm.resourceObj.purchases.attributes, key, $$v)},expression:"resourceObj.purchases.attributes[key]"}}):_c('v-text-field',{attrs:{"tile":"","type":"text","label":_vm.labels[key],"color":"secondary","error-messages":errors,"required":""},model:{value:(_vm.resourceObj.purchases.attributes[key]),callback:function ($$v) {_vm.$set(_vm.resourceObj.purchases.attributes, key, $$v)},expression:"resourceObj.purchases.attributes[key]"}})]}}],null,true)})],1)]}),_vm._v(" "),_vm._l((6),function(index){return _c('div',{key:index,staticClass:"flex-field"})})],2)})],2)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }