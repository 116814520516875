<template>

    <v-card class="pa-0 ma-0" flat>

        <v-card-title>
            <v-row>
                <v-col cols="12">
                    <div class="text-center">
                        ABN Lookup
                    </div>
                </v-col>
            </v-row>            
        </v-card-title>

        <v-card-text class="px-0 px-md-2 px-sm-2 px-xs-2">
            <v-row class="px-4">

                <v-col cols="12" lg="4" md="6" class="pa-2">
                    <v-card outlined flat class="px-1 py-4" height="100%">
                        <v-card-text class="py-0">
                            
                            <p class="headline">Options</p>

                            <ValidationObserver
                                ref="observer"
                                tag="form">

                                <ValidationProvider
                                    v-slot="{ errors }"
                                    name="ABN"
                                    rules="required"
                                    vid="ABN">
                                    <v-text-field 
                                        v-model="abn"
                                        :error-messages="errors"
                                        :label="label"></v-text-field>
                                </ValidationProvider>
                                
                                <p class="headline ma-0">Filters</p>

                                <ValidationProvider
                                    v-slot="{ errors }"
                                    name="Filter"
                                    rules="required"
                                    vid="Filter">
                                    <ButtonSelection 
                                        v-model="searchFilters.filter"
                                        label="Select Location Type"
                                        :options="['State', 'Postcode']"
                                        :error-messages="errors"
                                        :small="true" />
                                </ValidationProvider>

                                <ValidationProvider
                                    v-slot="{ errors }"
                                    name="State Territory"
                                    rules="required"
                                    vid="State Territory">
                                    <v-select
                                        v-if="searchFilters.filter && searchFilters.filter === 'State'"
                                        v-model="searchFilters['state-territory']"
                                        :items="states"
                                        :error-messages="errors"
                                        label="State"></v-select>
                                </ValidationProvider>

                                <ValidationProvider
                                    v-slot="{ errors }"
                                    name="Postcode"
                                    rules="required"
                                    vid="Postcode">
                                    <v-text-field 
                                        v-if="searchFilters.filter && searchFilters.filter === 'Postcode'"
                                        v-model="searchFilters['postcode']"
                                        :error-messages="errors"
                                        label="Postcode"></v-text-field>
                                </ValidationProvider>

                                <ValidationProvider
                                    v-slot="{ errors }"
                                    name="Status"
                                    rules="required"
                                    vid="Status">
                                    <ButtonSelection 
                                        v-model="selectedStatus"
                                        label="Status"
                                        :options="status"
                                        :error-messages="errors"
                                        :small="true" />                                    
                                </ValidationProvider>
                                
                                

                            </ValidationObserver>

                            <v-btn @click="handleChange" depressed large block class="secondary mt-4">Search</v-btn>

                        </v-card-text>
                    </v-card>
                </v-col>

                <v-col cols="12" lg="4" md="6" class="pa-2">
                    
                    <v-card outlined flat class="px-2 py-4" height="100%">
                        
                        <v-progress-linear color="secondary" indeterminate stream absolute top v-if="resultLoader"></v-progress-linear>
                        <v-overlay absolute :opacity="0.1" v-if="resultLoader"></v-overlay>

                        <p class="headline px-4">Result</p>
                        <v-card-text v-if="results.length > 0" class="pa-0">

                            <v-card flat
                                height="450"
                                class="overflow-y-auto px-2 py-0">

                                <v-card-text class="px-0 py-0">
                                
                                    <v-list class="py-0" three-line>
                                        <v-list-item-group
                                            v-model="selected"
                                            :mandatory="mandatory"
                                            active-class="secondary--text">
                                            <template v-for="(item, key) in filteredResults">
                                                <v-list-item @click="getInfo(item)" class="px-2" :key="key">

                                                    <template v-slot:default="{ active }">

                                                        <v-list-item-action class="pr-0 mr-4">                                                        
                                                            <v-icon
                                                                v-if="!active"
                                                                color="grey lighten-1">
                                                                mdi-checkbox-blank-outline
                                                            </v-icon>

                                                            <v-icon
                                                                v-else
                                                                color="seoondary darken-3">
                                                                mdi-checkbox-marked
                                                            </v-icon>
                                                        </v-list-item-action>
                                                
                                                        <v-list-item-content class="py-2">
                                                            <v-list-item-title class="font-weight-medium button">
                                                                {{ item.abn }}
                                                                <v-chip
                                                                    :color="`${item.status === 'Active' ? 'success' : 'error'}`" 
                                                                    :class="`${item.status === 'Active' ? 'black--text' : 'white--text'} pa-1`" x-small>
                                                                    {{ item.status }}
                                                                </v-chip>
                                                            </v-list-item-title>

                                                            <v-list-item-subtitle
                                                                class="text--primary body-2"
                                                                v-html="item.name">
                                                            </v-list-item-subtitle>
                                                            <v-list-item-subtitle class="caption">Type: {{ item.type }}</v-list-item-subtitle>

                                                        </v-list-item-content>

                                                    </template>
                                                
                                                
                                                </v-list-item>

                                            </template>
                                        </v-list-item-group>
                                    </v-list>
                                </v-card-text>
                            </v-card>
                        </v-card-text>
                        
                        <v-card-text v-else>
                            <v-skeleton-loader
                                type="list-item-three-line, list-item-three-line, list-item-three-line"></v-skeleton-loader>
                        </v-card-text>
                    </v-card>
                </v-col>

                <v-col cols="12" lg="4" md="12" class="pa-2">
                    <v-card outlined flat class="px-1 py-4" height="100%">

                        <v-progress-linear color="secondary" indeterminate stream absolute top v-if="infoLoader"></v-progress-linear>
                        <v-overlay absolute :opacity="0.1" v-if="infoLoader"></v-overlay>

                        <p class="headline px-4">Info</p>

                        <v-card-text v-if="info" class="py-0">
                            
                            <v-tabs
                                v-model="tab"
                                background-color="white"
                                height="50"
                                dark show-arrows>
                                <v-tab
                                    v-for="item in tabs"
                                    :key="item.title">
                                    <v-list-item class="px-0">
                                        
                                        <v-list-item-content class="text-left">
                                            <v-list-item-title class="ma-0 mb-2 black--text">
                                                {{ item.title }}
                                            </v-list-item-title>
                                        </v-list-item-content>
                                    </v-list-item>

                                </v-tab>
                            </v-tabs>

                            <v-tabs-items v-model="tab">
                                <v-tab-item
                                    v-for="item in tabs"
                                    :key="item.title">
                                    <v-card class="pt-2" flat>

                                        <v-card-text class="pa-0" v-if="item.title === 'Current'">
                                            <v-row v-for="(info, ikey) in getCurrentInfo" :key="ikey" class="pa-0 ma-0">
                                                <v-col v-if="info.children.length || isValidData(info.data)" cols="12" class="caption font-weight-medium pa-0 py-2">
                                                    {{ info.title }}
                                                </v-col>
                                                <template v-if="info.title === 'ABN Details'">
                                                    <v-row v-for="(field, fkey) in info.children" :key="fkey">
                                                        <v-col v-if="field.value" class="py-0">{{ field.text }}</v-col>
                                                        <v-col v-if="field.value" class="py-0 font-weight-medium">{{ field.value }}</v-col>
                                                    </v-row>
                                                </template>
                                                <template v-else>
                                                    <v-row v-if="isValidData(info.data)">
                                                        <v-col class="py-0">{{ info.data.value }}</v-col>
                                                        <v-col class="py-0 font-weight-medium">From {{ info.data.from }}</v-col>
                                                    </v-row>
                                                </template>
                                                                                                
                                            </v-row>
                                        </v-card-text>

                                        <v-card-text class="px-0" v-else>
                                            <v-row v-for="(info, ikey) in getHistoricalInfo" :key="ikey">
                                                <v-col 
                                                    v-if="info.text === 'Business Names' || info.text === 'Entity Name' || info.text === 'Goods and Services Tax (GST)'"
                                                    class="py-0">
                                                    <p v-if="isValidData(info.value)" class="caption my-0 ">{{ info.text }}</p>
                                                    <v-row v-for="(itm, key) in info.value" :key="key">
                                                        <v-col class="caption font-weight-medium my-0 py-0" v-if="itm.from" cols="6">{{ itm.value }}</v-col>
                                                        <v-col class="caption font-weight-medium my-0 py-0" v-if="itm.from" cols="6">From {{ itm.from }} to {{ itm.to }}</v-col>
                                                        <v-col class="caption font-weight-medium my-0 py-0" v-else>{{ itm.value }}</v-col>
                                                    </v-row>                                                    
                                                </v-col>
                                                <v-col v-else class="py-0">
                                                    <p v-if="isValidData(info.value)" class="caption my-0 ">{{ info.text }}</p>
                                                    <p 
                                                        class="caption font-weight-medium my-0">{{ info.value }}</p>
                                                </v-col>
                                            </v-row>
                                        </v-card-text>
                                        
                                    </v-card>
                                </v-tab-item>
                            </v-tabs-items>

                        </v-card-text>
                        <v-card-text v-else>
                            <v-skeleton-loader
                                type="list-item-two-line, list-item-two-line, list-item-two-line, list-item-two-line, list-item-two-line"></v-skeleton-loader>
                        </v-card-text>
                    </v-card>
                </v-col>

            </v-row>
        </v-card-text>

    </v-card>
</template>

<script>

    import ButtonSelection from '~/components/base/ButtonSelections'
    import subscriptions from '~/components/shared/subscriptions'
    import { isEmpty } from 'lodash'
    
    import { mapActions } from 'vuex'

    export default {
        name: 'AbnLookupContent',

        mixins: [ subscriptions ],

        components: { ButtonSelection },

        model: {
            prop: 'modelValue',
            event: 'input'
        },

        props: {
            modelValue: {
                type: String,
                default: ''
            },
            defaultValue: {
                type: String,
                default: null
            },
            disabled: {
                type: Boolean,
                default: false
            },
            label: {
                type: String,
                default: ''
            },
            errors: {
                type: Array,
                default: ()=>[]
            }
        },

        computed: {
            filteredResults() {

                if ( this.selectedStatusAlt === 'Active' ) {
                    return this.results.filter( res => {
                        return res.status === 'Active'
                    })
                }
                return this.results
            },
            getCurrentInfo() {

                let mbl = null                
                if ( Array.isArray(this.info?.location) ) {
                    mbl = this.info?.location?.find( loc => {
                        return loc.to === '(current)'
                    })
                    mbl = mbl?.value
                }
                else {
                    mbl = this.info?.location
                }                

                let gst = this.info?.['gst-reg']?.find( gs => {
                    return gs.to === '(current)'
                })

                let name = this.info?.['name']
                if ( Array.isArray(name) ) {
                    name = name?.find( n => {
                        return n.to === '(current)'
                    })

                    name = name?.value 
                }

                let business = this.info?.['business-names']
                if ( Array.isArray(business) ) {
                    business = business.find( bz => {
                        return bz.to === '(current)'
                    })

                    if ( !business ) {
                        business = []
                    }
                }
                
                return [
                    { 
                        title: 'ABN Details',
                        children: [
                            { text: 'Entity Name', meta: 'name', value: name },
                            { text: 'ABN Status', meta: 'abn-reg', value: this.info?.['abn-reg'] },
                            { text: 'Entity Type', meta: 'info-type', value: this.info?.['info-type'] },
                            { text: 'Goods and Services Tax (GST)', meta: 'gst', value: gst?.from ? `${gst?.value} from ${gst?.from}` : 'Not currently registered for GST' },
                            { text: 'Main Business Location', meta: 'business-names', value: mbl },
                            { text: 'ACN', meta: 'business-names', value: this.info?.['acn'] }
                        ]
                    },
                    {
                        title: 'Business Names',
                        data: business,
                        children: []
                    }
                ]
            },
            getHistoricalInfo() {

                let name = this.info?.['name']                
                if ( !Array.isArray(name) ) {
                    name = [{ from: null, to: null, value: name }]
                }

                let business = this.info?.['business-names'] 
                if ( !Array.isArray(business) ) {
                    business = [business]
                }
                
                return [
                    { text: 'Entity Name', meta: 'entity-name', value: name },
                    { text: 'ABN Status', meta: 'abn-status', value: this.info?.['abn-reg'] },
                    { text: 'Entity Type', meta: 'entity-type', value: this.info?.['info-type'] },
                    { text: 'ACN', meta: 'business-names', value: this.info?.['acn'] },
                    { text: 'Goods and Services Tax (GST)', meta: 'gst', value: this.info?.['gst-reg'] },
                    { text: 'Business Names', meta: 'acn', value: business },                    
                ]
            }
        },

        mounted() {
            this.abn = this.modelValue
        },

        methods: {
            ...mapActions('utils', [
                'requestAbn'
            ]),

            async handleChange(val) {
                this.resultLoader = true

                const valid = this.$refs.observer.validate()

                if ( valid ) {
                    
                    this.$emit('input', val)

                    this.info = null
                    
                    let res = await this.requestAbn({
                        "data": {
                            "type":"abn-lookups",
                            "attributes": {
                                "input": {
                                    "end-point": "searchRandom", // searchRandom or searchByABN
                                    "params": {
                                        "filter": this.searchFilters.filter.toLowerCase(),
                                        "postcode": this.searchFilters['postcode'],
                                        "state-territory": this.searchFilters['state-territory']
                                    },
                                    "search": this.abn
                                }
                            }
                        }
                    })

                    this.results = res.data?.data?.attributes?.output?.list || []
                    this.selectedStatusAlt = this.selectedStatus === 'All' ? 'All' : 'Active'
                    this.mandatory = false
                    this.selected = undefined
                }
                
                this.resultLoader = false

            },

            async getInfo(data) {

                this.infoLoader = true

                let res = await this.requestAbn({
                    "data": {
                        "type":"abn-lookups",
                        "attributes": {
                            "input": {
                                "end-point": "searchByABN",
                                "abn": data.abn
                            }
                        }
                    }
                })

                this.info = res.data?.data?.attributes?.output?.company || {}
                
                this.infoLoader = false
            },
            
            isValidData(data) {
                return isEmpty(data) ? false : true
            }
        },

        watch: {
            defaultValue(){
                this.abn = this.modelValue
            },
            modelValue: {
                handler(val) {
                    this.abn = val
                },
                deep: true
            },
            'searchFilters.filter': {
                handler(val) {
                    this.searchFilters['postcode'] = ''
                    this.searchFilters['state-territory'] = ''
                },
                deep: true
            },
            'selected': {
                handler(val) {
                    if ( val !== undefined )
                        this.mandatory = true
                },
                deep: true
            }
        },

        data() {
            return {
                dialog: false,
                loading: false,
                abn: null,
                selected: null,
                searchFilters: {
                    'filter': 'State',                    
                    'postcode': '',
                    'state-territory': 'All',
                },
                states: [ 'All', 'ACT', 'NSW', 'NT', 'QLD', 'SA', 'TAS', 'VIC', 'WA' ],
                status: [ 'All', 'Active' ],
                selectedStatus: 'Active',
                selectedStatusAlt: null,
                tab: null,
                tabs: [
                    { title: 'Current', icon: 'mdi-information-variant', content: 'Current data content here...' },
                    { title: 'Historical', icon: 'mdi-history', content: 'Historical data content here...' }
                ],
                results: [],
                resultLoader: false,
                info: null,
                infoLoader: false,
                mandatory: false
            }
        }
    }
</script>

<style lang="scss" scoped>
    
</style>
