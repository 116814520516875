<template>
    <FlexWrap @resize="handleResize">
        <v-card 
            :disabled="isDisabled || !validComponentData" 
            class="mx-auto pt-0 px-4" 
            tile flat
            max-width="832">
            <v-container fluid pa-0>
                <ValidationObserver ref="observer" tag="form">
                    <v-row>
                        <v-col cols="12" class="py-0">
                            <ValidationProvider 
                                v-slot="{ errors }" 
                                name="Trade In Amount" 
                                rules="between:0,9999999" 
                                vid="trade-in-amount">
                                                                
                                <NodifiMoney
                                    v-model="tradeInAmount"
                                    label="Estimated trade in price (if applicable)"
                                    @input="watchTradeAmountInput"
                                    :errors="errors" />

                            </ValidationProvider>
                        </v-col>
                        <v-col cols="12" class="py-0">
                            <ValidationProvider 
                                v-slot="{ errors }" 
                                name="Cash Deposit" 
                                rules="between:0,9999999" 
                                vid="cash-deposit">     

                                <NodifiMoney
                                    v-model="cashDeposit"
                                    @input="watchCashDepositInput"
                                    label="Cash Deposit (if applicable)"
                                    :errors="errors" />

                            </ValidationProvider>
                        </v-col>
                        <v-col cols="12" class="py-0 mt-0">

                            <InfoBox1>
                                <template v-slot:text>
                                    Based on your contribution, your loan amount would be <strong>{{ requiredLoanAmount | currency }}</strong>. Well done!
                                </template>
                            </InfoBox1>

                        </v-col>
                    </v-row>
                </ValidationObserver>
                
            </v-container>
        </v-card>
    </FlexWrap>
</template>

<script>

    import subscriptions from '~/components/shared/subscriptions'
    import { mapState, mapGetters, mapActions, mapMutations } from 'vuex'
    import helpers from '~/plugins/helpers'
    import FlexWrap from '~/components/base/FlexWrap'
    import NodifiMoney from '~/components/base/NodifiMoney'
    import InfoBox1 from '~/components/base/InfoBox1'

    export default {
        name: 'PurchaseEditor3',
        mixins: [ subscriptions, helpers ],
        components: { FlexWrap, NodifiMoney, InfoBox1 },

        mounted(){
            if(!this.validComponentData){
                this.showComponentWarning()
            }
        },

        computed: {
            validComponentData(){
                
                const {data} = this.appData.relationships.loan

                if(data == null){
                    return false;
                }

                return true
            },
            loanAmount() {
                
                let purchasePrice = parseInt(this.resourceObj?.attributes?.['purchase-price'])

                if ( purchasePrice ) {
                    
                    let tradeInAmount = parseInt(this.resources?.attributes?.['trade-in-amount'] ? this.resources?.attributes?.['trade-in-amount'] : 0)
                    let cashDeposit = parseInt(this.resources?.attributes?.['cash-deposit'] ? this.resources?.attributes?.['cash-deposit'] : 0)
                    let amount = purchasePrice - ( tradeInAmount + cashDeposit )
                    
                    return amount // this.$options.filters.currency(amount)
                }

                return 0 // this.$options.filters.currency(0)

            },
            tradeInAmount: {
                get() {
                    return this.resources.attributes['trade-in-amount']
                },
                set(val) {
                    this.resources.attributes['trade-in-amount'] = val
                }
            },
            cashDeposit: {
                get() {
                    return this.resources.attributes['cash-deposit']
                },
                set(val) {
                    this.resources.attributes['cash-deposit'] = val
                }
            },
            requiredLoanAmount() {
                return parseInt(this.resources.attributes['required-loan-amount'])
            }
        },

        methods: {
            ...mapActions('resource-actions', [
                'getPurchase',
                'createPurchase',
                'updatePurchases',
                'getApplication'
            ]),
            async pull() {
                
                // Do nothing if minimum data required is not met.
                if(!this.validComponentData) return

                let res = await this.getApplication({
                    id: this.appData.id,
                    params: {
                        include: 'purchases'
                    }
                })

                if ( res.status === 200 ) {
                    this.resourceObj = res.data.included.find( purchase => {
                        return purchase.type === 'purchases'
                    })
                }

                this.prepareResources()

            },
            async push() {
                
                const observer = this.$refs.observer

                let res = null
                
                if ( this.resources?.id ) {
                    res = await this.updatePurchases(this.resources)
                }
                else {
                    res = await this.createPurchase(this.resources)
                }

                const {valid, status} = await this.validateFormData()

                return {valid, status, data: {}}

            },
            prepareResources() {
                this.resources.id = this.resourceObj?.id
                this.resources.relationships.application = this.resourceObj?.relationships?.application
                this.resources.relationships.kind = this.resourceObj?.relationships?.kind
                
                Object.keys(this.resources.attributes).forEach( attr => {
                    this.resources.attributes[attr] = this.resourceObj?.attributes?.[attr]
                })
            },
            watchCashDepositInput(val) {

                let amount = 0

                let purchasePrice = this.resourceObj?.attributes?.['purchase-price'] ? parseInt(this.resourceObj?.attributes?.['purchase-price']) : 0
                let tradeInAmount = this.resources?.attributes?.['trade-in-amount'] ? parseInt(this.resources?.attributes?.['trade-in-amount']) : 0
                let cashDeposit = val ? parseInt(val) : 0

                amount = purchasePrice - ( tradeInAmount + cashDeposit )

                this.resources.attributes['required-loan-amount'] = amount

            },
            watchTradeAmountInput(val) {
                let amount = 0

                let purchasePrice = this.resourceObj?.attributes?.['purchase-price'] ? parseInt(this.resourceObj?.attributes?.['purchase-price']) : 0
                let tradeInAmount = val ? parseInt(val) : 0
                let cashDeposit = this.resources?.attributes?.['cash-deposit'] ? parseInt(this.resources?.attributes?.['cash-deposit']) : 0

                amount = purchasePrice - ( tradeInAmount + cashDeposit )

                this.resources.attributes['required-loan-amount'] = amount
            }
        },

        watch: {
            validComponentData(val){
                if(!val) {
                    this.showComponentWarning()
                }
            }
        },

        data() {
            return {
                resourceObj: {},
                payloadObj: {},
                resources: {
                    type: "purchases",
                    id: null,
                    attributes: {
                        "trade-in-amount": 0,
                        "cash-deposit": 0,
                        "required-loan-amount": null
                    },
                    relationships: {
                        application: {
                            data: { type: "applications", id: null } 
                        },                
                        kind: {
                            data: { type: "kinds", id: null }  
                        }
                    }
                }
            }
        },
        
    }
</script>

<style lang="scss" scoped>

</style>