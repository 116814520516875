<template>

    <FlexWrap @resize="handleResize">
        <v-sheet class="mx-auto text-center" :max-width="breakPoint">
            <v-responsive 
                v-for="(value, key) in data"
                :key="key"
                width="240"
                height="280"
                class="ma-2 d-inline-flex"
                :aspect-ratio="1/1">

                <v-card 
                    color="secondary" 
                    flat height="100%" 
                    @click="handleClick(value)"
                    rounded="lg"
                    class="d-flex flex-column justify-center align-center flex-box px-6 py-12">

                    <div class="mx-auto text-center mb-5">
                        <v-icon size="56" color="white" class="mb-4" v-if="value.icon">{{ value.icon }}</v-icon>
                        <div v-html="value.markup" class="font-weight-bold white--text flex-tile-text"></div>
                        <div class="white--text py-6" v-if="value.subtitle">{{ value.subtitle }}</div>
                    </div>
                    <v-spacer></v-spacer>
                    <v-btn depressed large color="secondary" width="100">Go Now</v-btn>
                </v-card>

            </v-responsive>
        </v-sheet>
    </FlexWrap>

</template>

<script>
    import FlexWrap from '~/components/base/FlexWrap'
    export default {
        components: {
            FlexWrap
        },
        props: {
            data: {
                type: Array,
                default: ()=> [],
            }
        },
        data() {
            return {
                breakPoint: 'none'
            }
        },
        computed: {
            vb() {
                return this.$vuetify.breakpoint
            },
        },
        mounted (){

        },
        methods: {
            handleClick(data) {
                
                this.$emit('select', data)
            },
            handleResize(sizes){

                const {width} = sizes

                if(width < 1100){

                    this.breakPoint = 600

                } else {

                    this.breakPoint = 'none'
                }
            }
        }
    }   
</script>

<style lang="scss" scoped>
</style>