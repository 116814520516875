<template>
    <v-dialog
        v-model="dialog"
        persistent
        scrollable
        max-width="550px"
    >

        <template v-slot:activator="{on}">
            <slot name="activator" v-bind:on="toggle"></slot>
        </template>

            <v-card tile flat class="rounded-lg flex-dialog" :disabled="saving">
            <v-progress-linear color="secondary" indeterminate stream absolute top v-if="saving"></v-progress-linear>

            <v-card-title class="d-flex">
                <v-spacer />
                <v-btn icon @click="close" >
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-card-title>

            <v-card-text class="py-0">
                <slot name="title-1"></slot>
                <v-card-subtitle class="mt-4 mx-0">
                    Copy full address history to {{ getEntityFriendlyName(selectedEntity) }}
                </v-card-subtitle>
            </v-card-text>

            <v-card-text>

                <ValidationObserver ref="observer1" tag="form">
                    <v-container>
                        <ValidationProvider 
                            v-slot="{ errors }" 
                            :name="labels['role-name']" 
                            :rules="getRules('role-name')"
                            :vid="'role-name'">

                            <v-select
                                :items="options"
                                :label="labels['role-name']"
                                item-text="name"
                                item-value="id"
                                :error-messages="errors"
                                v-model="selectRole"
                                color="secondary"
                            >
                            </v-select>

                        </ValidationProvider>
                    </v-container>
                </ValidationObserver>

            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions class="pa-4 pa-md-6 d-flex justify-end">

                <v-btn color="secondary" :min-width="140" large elevation="0" nuxt @click="save" >Save</v-btn>
                <v-spacer></v-spacer>
                <v-btn color="light_gray_2" class="flex-back-button" :disabled="false" :min-width="140" large outlined elevation="0" nuxt @click="close">Back</v-btn>

            </v-card-actions>

        </v-card>

    </v-dialog>
</template>

<script>

    import { mapActions, mapState, mapGetters, mapMutations } from 'vuex'
    import formHelpers from '~/components/shared/form-helpers.js'
    import resourceHelpers from '~/components/shared/resource-helpers'
    import validation from '~/components/shared/validation'

    export default {
        name: 'AddressCopyDialog',
        model: {
            prop: 'modelValue',
            event: 'input'
        },

        mixins: [validation, formHelpers, resourceHelpers],

        props: {
            modelValue: {
                type: Boolean,
                default: false
            },

            selectedEntity: {
                type: Object,
                default: () => {}
            },

            resourcesData: {
                type: Object,
                default: () => {}
            },

            entity: {
                type: Array,
                default: () => []
            },

            options: {
                type: Array,
                default: () => []
            },
        },

        watch: {
            modelValue(value){
                this.dialog = value
            },
            selectRole (val) {

                if (val) {
                    // filter role by select
                    let entityFilter = this.entity.filter((el) => el.id == val)
                    this.copyType = entityFilter[0].type

                    // get occupancies id of selelected role
                    this.occupancyId = this.selectedRoleOccupancy(entityFilter)

                    // get address id of selected role
                    this.addressId = this.selectRoleHistory(entityFilter)
                }

            }
        },

        data () {
            return {
                copyType: '',
                addressId: [],
                occupancyId: [],
                saving: false,
                dialog: false,
                selectRole: '',
                labels: {
                    "role-name" : "Copy From",
                },
                validation_rules: {
                    attributes: {
                        "role-name" : "required|string",
                    }
                },
                resources: {
                    occupancies: {
                        type: 'occupancies',
                        attributes: {
                            "occupancy-situation": null,
                            "residential-situation": null,
                            "start-date": null,
                            "end-date": null
                        },
                        relationships: {
                            application: {
                                data: { type: "applications", id: null } 
                            },
                            person: {
                                data: { type: "people", id: null } 
                            },
                            address: {
                                data: { type: "addresses", id: null } 
                            }
                        }
                    },
                },
            }
        },

        computed: {
            ...mapState({
                appId: state => state.flows.appId,
            }),
        },

        mounted () {
        },

        methods: {
            ...mapActions('resource-actions', [
                'getAddress',
                'updateBusiness',
                'createOccupancy',
                'getOccupancy',
                'deleteOccupancy'
            ]),
            async save () {

                this.saving = true

                const {selectedEntity, copyType} = this

                const valid = await this.$refs.observer1.validate();

                const addressId = await this.addressId

                const occupancyId = await this.occupancyId

                if(valid){

                    // save business copy from business
                    if (selectedEntity.type == 'businesses' && copyType == 'businesses') {

                        if (addressId.length > 0) {
                            await this.saveBusinessToBusiness(addressId, selectedEntity)
                            this.close()
                        }

                    } else if (selectedEntity.type == 'people' && copyType == 'people') {

                        
                        if (occupancyId.length > 0) {
                            // console.log(selectedEntity.relationships.occupancies?.data.length)
                            if(selectedEntity.relationships.occupancies?.data.length > 0) {
                                selectedEntity.relationships.occupancies.data.forEach(async (el) => {
                                    await this.deleteOccupancy(el.id)
                                })
                            }
                            
                            addressId.forEach(async (addressid, index) => {

                                this.saving = true

                                // get occupancy
                                const occupancy = await this.getOccupancy(occupancyId[index])

                                // set data
                                this.resources.occupancies = {
                                    "type": "occupancies",
                                    "attributes": occupancy.data.data.attributes
                                }
                                this.resources.occupancies.relationships = {
                                    application: {data: { type: "applications", id: this.appId }},
                                    address: { data: { type: "addresses", id: addressid } },
                                    person: {data: {id: selectedEntity.id, type: 'people'}},
                                }

                                await this.savePeopleToPeople()

                            })

                            setTimeout(() => {
                                this.close()
                            }, 3000)
                            
                        }

                    }

                    
                }
            },

            async saveBusinessToBusiness(addressIds, selectedEntity) {

                    let businessReq = null
                    let data = null

                    let payload = {
                        ...selectedEntity
                    }

                    let addressData = []

                    // loop for address ids
                    addressIds.map(addressid => {
                        const data = {
                            id: addressid,
                            type: 'addresses'
                        }
                        addressData.push(data)
                    })

                    payload.relationships = {
                        "addresses": {
                            data: addressData,
                            meta: {
                                strategy: 'attach'
                            }
                        }
                    }

                    businessReq = this.updateBusiness(payload)

                    data = await this.processResponses([businessReq])

                    this.$emit('save', data)

            },

            async savePeopleToPeople() {

                const {occupancies} = this.resources

                let data = null

                let occupancyReq = await this.createOccupancy(occupancies)

                let addressReq = this.getAddress(occupancies.relationships.address.data.id)

                data = await this.processResponses([addressReq, occupancyReq])

                this.$emit('save', data)

            },

            async processResponses(args){

                try {

                    return await Promise.all(args).then(res => {
                        // add handler of requests error 
                        let items = []
                        res.forEach(data => {
                            items.push(data.data)
                        })

                        return items
                    })

                } catch (error) {

                    console.log('Ooops!..', error)

                    return {}
                }

            },
            
            async selectRoleHistory (entity) {

                const { occupancies } = this.resourcesData

                let entitiesAddressIds = []

                if (entity) {

                    if(entity[0].type == 'people') {

                        occupancies.forEach( occupancy => {

                            let addressId = occupancy.relationships?.address?.data?.id

                            if(occupancy.relationships?.person?.data.id == entity[0].id){

                                if(addressId){
                                    entitiesAddressIds.push(addressId)
                                } 

                            } 
                        })
                    }

                    if(entity[0].type == 'businesses'){
                        if(entity[0].relationships?.addresses?.data?.length){
                            entity[0].relationships.addresses.data.forEach(address => {
                                entitiesAddressIds.push(address.id)
                            })
                        } 
                    } 

                }

                return entitiesAddressIds
                
            },

            async selectedRoleOccupancy (entity) {

                const { occupancies } = this.resourcesData

                let entitiesOccupancyIds = []

                if (entity) {

                    if(entity[0].type == 'people') {

                        occupancies.forEach( occupancy => {

                            let occupancyId = occupancy.id

                            if(occupancy.relationships?.person?.data.id == entity[0].id){

                                if(occupancyId){
                                    entitiesOccupancyIds.push(occupancyId)
                                } 

                            } 
                        })
                    } 

                }

                return entitiesOccupancyIds
                
            },

            toggle(){
                this.dialog = !this.dialog
                this.saving = false
                this.selectRole = ''
            },
            close(){
                this.toggle()
                this.$emit('close')
            },
            getEntityFriendlyName(entity){

            let fullname = ''

            if (entity) {
                if(entity.type == 'businesses') {

                    fullname += entity.attributes['business-legal-name'] ? entity.attributes['business-legal-name'] : 'Business'

                }

                if(entity.type == 'people'){

                    fullname += entity.attributes['first-name'] ? entity.attributes['first-name'] + ' ' : ''

                    fullname += entity.attributes['last-name'] ? entity.attributes['last-name'] : ''

                    if(!entity.attributes['first-name'] && !entity.attributes['last-name']){
                        fullname = 'Person'
                    }
                }

            }

            return fullname

        },
        }
        
    }
</script>

<style lang="scss" scoped>

    .v-card__title {


        .v-subheader  {
            display: block;
            line-height: 1.4;
            height: auto;
        }
    }

</style>