<template>
    <v-card :disabled="isDisabled || !validComponentData || saving" flat class="mt-n4 px-4 pb-4">
        <loader :loading="saving" />
        <v-card-title class="sm" :class="vb.lgAndUp ? 'd-flex' : 'd-flex flex-column'">
            <div :class="vb.lgAndUp ? 'mb-0' : 'mb-2'">
                Loan Requirements
            </div>
            <v-spacer />
            <div class="d-flex">

                <!-- Generate pdf -->
                <template v-if="isConsumer">
                    <GenerateSoca
                        v-if="isBidUser && bidResource"
                        @saved-referrals="generateSoca"
                        :disabled="!hasSecondary || !hasPrimary"
                        :sync-loading="pdfLoading"
                        :bid-resource="bidResource" />
                    <QuoteToolCPDDGenerateSocaDialog
                        v-else
                        @saved-referrals="generateCPDD"
                        :selected-lender="confirmedSelectedLender"
                        :cppd-resources="CPPDResource"
                        :sync-loading="pdfLoading" />

                    <v-btn depressed large
                        v-if="isBidUser && bidResource"
                        :disabled="noBidDoc"
                        :loading="sendingBidDoc"
                        @click="sendBidDocument"
                        class="secondary mx-2">
                        Send BID Document
                    </v-btn>

                </template>

                <template v-if="authUserCanSubmitApp">
                    <v-btn
                        :disabled="disabledSubmitButton"
                        @click="handleSubmit"
                        large depressed                
                        class="secondary ml-2">
                        <template v-if="hasHybridOptions">
                            Proceed
                        </template>
                        <template v-else>
                            Submit
                        </template>
                    </v-btn>

                    <div style="position: relative;"  v-if="isBidFlow && hasHybridOptions && !isCommercial">
                        <v-tooltip
                            bottom
                            max-width="260">
                            <template v-slot:activator="{ on, attrs }">
                                <v-icon
                                    class="warning--text"
                                    style="cursor: pointer; position: absolute; top: -6px;left: -12px;"
                                    size="20"
                                    v-on="on">
                                    mdi-information
                                </v-icon>
                            </template>
                            <span>Generate {{ !isBidFlow ? 'CPDD' : 'BID' }} document to proceed</span>
                        </v-tooltip>
                    </div>

                </template>
            </div>
        </v-card-title>
        <v-card-text>
            <ValidationObserver 
                ref="observer" 
                tag="form"
            >   
                <v-row>
                    <v-col :cols="vb.smAndUp ? 3 : 12" 
                        v-for="(field, key) in filterFieldLayout(resources.loan.attributes)"
                        :key="key"
                        class="py-0"
                    >
                        <ValidationProvider
                            v-if="isVisible(key)"
                            v-slot="{ errors }"
                            :name="labels[key]"
                            :hidden="hiddens[key]"
                            :rules="getRules(key)"
                            :vid="key">

                            <v-select
                                v-if="isFieldType('picklist', key)"
                                v-model="resources.loan.attributes[key]"
                                :items="options[key]"
                                item-text="key"
                                item-value="value"
                                :error-messages="errors"
                                color="secondary"
                                :suffix="suffix[key]"
                                :label="labels[key]"
                            ></v-select>

                            <template v-else>

                                <v-text-field 
                                    v-if="key == 'requested-writing-rate'"
                                    v-model="requested_writing_rate"
                                    :label="rateField"
                                    :error-messages="errors"
                                    suffix="%"
                                    flat>
                                </v-text-field>
                                
                                <NodifiMoney
                                    v-else
                                    v-model="resources.loan.attributes[key]"
                                    :label="labels[key]"
                                    :disabled="key === 'requested-required-loan-amount' && !isUnsecured ? true : false"
                                    :errors="errors" />
                                    
                            </template>                                        

                        </ValidationProvider>
                        
                    </v-col>
                </v-row>
            </ValidationObserver>
        </v-card-text>

        <v-card-title class="sm" :class="vb.lgAndUp ? 'd-flex' : 'd-flex flex-column'">
            <div :class="vb.lgAndUp ? 'mb-0' : 'mb-2'">
                Indicative Estimate
            </div>
            <v-spacer />
            <div class="d-flex">
                <v-btn @click="calculate" :loading="calculateLoading" depressed large class="secondary mx-2">Calculate</v-btn>
            </div>
        </v-card-title>

        <v-card-text v-if="lendersData.length > 0">

            <div v-if="isConsumer">
                <QuoteToolTableBid
                    v-if="isBidUser"
                    :required-loan-amount="getRequestedRequiredLoanAmount"
                    :lenders="lendersData"
                    @edit-lender="onEditLender"
                    @save-primary-lender="runUserConnectors"
                    @reset-primary="resetLoanSelectedAlgo"
                />
                <QuoteToolCPDDTable
                    v-else
                    :lenders="lendersData"
                    :confirmed-selected-lender="confirmedSelectedLender"
                    @edit-lender="onEditLender"
                    @save-lender="saveLender"
                />
            </div>
            
            <QuoteToolTable
                v-else
                :lenders="lendersData"
                :confirmed-selected-lender="confirmedSelectedLender"
                @edit-lender="onEditLender"
                @save-lender="saveLender"
            />

        </v-card-text>
        
        <v-card-text v-else>
            <v-card flat class="text-center grey--text">
                <v-card-text class="subtitle-1 pb-0">
                    No Lenders Available
                </v-card-text>
                <v-card-text class="text-caption">
                    Unfortunately we have not been able to find any lenders suitable for the applicant. Don't worry, we may still be able to assist. You can send through your application and our processing team will review the application.
                </v-card-text>
            </v-card>
        </v-card-text>

        <template v-if="showOtherLenders">
            <v-card-title class="sm" :class="vb.lgAndUp ? 'd-flex' : 'd-flex flex-column'">
                Other Lenders
            </v-card-title>
            <v-card-text>

                <NoQuoteTableBid
                    v-if="showBidTable"
                    :lenders="unQuotedLenders"
                    :quoted-lenders="lendersData"
                    :other-lender-dialog="otherLenderDialog"
                    @save="addOtherLender"
                />
                <NoQuoteTable
                    v-else
                    :lenders="unQuotedLenders"
                    :quoted-lenders="lendersData"
                    :other-lender-dialog="otherLenderDialog"
                    @save="addOtherLender"
                />
            </v-card-text>
        </template>
        
        <QuoteToolCustomLenderModalForm
            :dialog="customLenderDialog"
            :required-loan-amount="getRequestedRequiredLoanAmount"
            :edit-form="editForm"
            @save-lender="updateLender"
            :logo-class="logoClass"
            @submit="addLender"
            @back="handleBackButton"
            @close-modal="customLenderDialog = false" />

        <QuoteToolSelectSubmission
            :dialog="SelectSubmitDialog"
            :processing="submissionSaving"
            :assisters="assisters"
            :has-lender-flow="hasLenderFlow"
            :has-assister-submit="hasAssisterSubmit"
            @submit-to-lender="submitToLender"
            @force-submit-to-lender="proceedForceSubmitToLender"
            @submit-to-assister="submitToAssister"
            @close-modal="closeSelectSubmit"
            @on-other="openOtherSubmit = true" />

        <OtherSubmitModal 
            v-model="openOtherSubmit"
            :processing="submittingToOther"
            @save-other-lender="handleOtherSubmit"
            @close-modal="openOtherSubmit = false"
        />

        <v-dialog
            persistent
            scrollable
            max-width="500"
            class="pa-4"
            v-model="confirmDialog">

                <v-card flat>

                    <v-card-title class="px-8 pt-8">
                        <div class="v-card__title sm pa-0">
                        </div>
                    </v-card-title>

                    <v-card-text class="px-8 pt-2 pb-6 body-1" v-html="confirmDialogTitle"></v-card-text>

                    <v-card-actions class="pa-4 ma-2">
                        <v-spacer></v-spacer>
                        <v-btn @click="confirmDialog = false"  class="flex-back-button" outlined large depressed>
                            Cancel
                        </v-btn>
                        <v-btn v-if="!isSameLender" @click="redirectToLenderFlow" class="warning"  large depressed >
                            Proceed
                        </v-btn>
                    </v-card-actions>

                </v-card>

        </v-dialog>
            
    </v-card>
    
</template>
<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex'
import { mutations, store } from './__store'
import subscriptions from '~/components/shared/subscriptions'
import resourceHelpers from '~/components/shared/resource-helpers'

import QuoteToolTable from './_QuoteToolTable'
import QuoteToolTableBid from './_QuoteToolTableBid'
import QuoteToolCPDDTable from './_QuoteToolCPDDTable'

import NoQuoteTable from './_NoQuoteTable'
import NoQuoteTableBid from './_NoQuoteTableBid'

import CustomLenderModal from './_QuoteToolCustomLenderModal'
import QuoteToolCustomLenderModalForm from './_QuoteToolCustomLenderModalForm'
import QuoteToolCPDDGenerateSocaDialog from './_QuoteToolCPDDGenerateSocaDialog'
import GenerateSoca from './_QuoteToolGenerateSocaDialog'
import QuoteToolSelectSubmission from './_QuoteToolSelectSubmission'
import helpers from '~/plugins/helpers'
import { isEmpty } from 'lodash'
import loader from '~/components/base/ProgressLoader'
import OtherSubmitModal from '../../base/OtherSubmitModal.vue'

export default {
    name: 'QuoteTool2',

    mixins: [ subscriptions, helpers, resourceHelpers ],

    components: {
        loader,
        QuoteToolTable,
        QuoteToolTableBid,
        QuoteToolCPDDTable,
        CustomLenderModal,
        GenerateSoca,
        QuoteToolCPDDGenerateSocaDialog,
        QuoteToolCustomLenderModalForm,
        QuoteToolSelectSubmission,
        OtherSubmitModal,
        NoQuoteTable,
        NoQuoteTableBid,
    },
    data() {
        return {
            sendingBidDoc: false,
            editForm: null,

            hasRunQuote: false,
            activeLenderSubmissions: null,
            confirmDialogTitle: '',
            confirmDialog: false,
            isSameLender: false,
            hasLenderFlow: false,
            lenderSubmissionFlowSlug: null,
            submissionSaving: false,
            submittingToOther: false,
            openOtherSubmit: false,
            assisters: [],
            noBidDoc: true,
            noCPDDDoc: true,
            hasUserConnector: false,
            
            showCompliance: false,
            SelectSubmitDialog: false,
            saving: false,

            radio: null,
            rateField: false,
            pdfLoading: false,
            calculateLoading: false,
            bidResource: null,
            validComponentData: true,
            lendersName: null,
            logoClass: null,
            lendersData: [],

            customLenderDialog: false,
            otherLenderDialog: false,

            primaryLender: null,
            secondaryLender: null,
            responsePayload: null,

            confirmedSelectedLender: '',
            selectedLender: null,
            requested_writing_rate: '',
            hiddens: {
                "selected-lender-quote-algorithm-slug": true,
                "customer-discount-rate": true,
            },
            "resources": {
                "loan": {
                    type: 'loans',
                    id: null,
                    attributes: {
                        "requested-required-loan-amount": '',
                        "sale-type": '',
                        "requested-term-months": '',
                        "requested-balloon": '',
                        "requested-broker-fee": '',
                        "requested-writing-rate": '',
                        "requested-payment-frequency": '',
                        "requested-payment-terms": '',
                        "budgeted-payment-amount": '',
                        "selected-lender-quote-algorithm-slug": '',
                        "customer-discount-rate": ''
                    }
                },
            },
            labels: {
                "requested-required-loan-amount": "Required Loan Amount",
                "personal-loan-reason": "Personal Loan Reason",
                "sale-type": "Sale Type",
                "requested-term-months": "Requested Loan Term",
                "requested-payment-terms": "Payment Terms",
                "requested-balloon": "Requested Balloon",
                "requested-payment-frequency": "Requested Payment Frequency",
                "requested-broker-fee": "Requested Origination Fee",
                "requested-writing-rate": "Preferred Interest Rate",
                "budgeted-payment-amount": 'Budgeted Payment Amount',
            },
            validation_rules: {
                type: "loans",
                attributes: {
                    "requested-required-loan-amount": "required",
                    "sale-type": "required|in:Dealer,Private Sale,Refinance",
                    "requested-term-months": "required|in:12,24,36,48,60,72,84",
                    "requested-payment-terms": "required|in:In Arrears,In Advance",
                    "requested-payment-frequency": "required|in:Monthly,Fortnightly,Weekly,Annual",
                    "requested-writing-rate": "required",
                    "requested-broker-fee": "required",
                    "requested-balloon": "",
                    "selected-lender-quote-algorithm-slug": "",
                    "customer-discount-rate": "",
                    "budgeted-payment-amount": "required",
                    "personal-loan-reason": "required|in:Debt Consolidation,Education,Funeral Expenses,Furniture and Appliances,Home Improvements,Medical Expenses,Motor or Leisure Vehicle,Other,Solar Energy,Tax Debt,Travel,Vehicle Repairs,Wedding",
                }
            },
            options: {
                "requested-term-months": [
                    { key: "12", value: 12 },
                    { key: "24", value: 24 },
                    { key: "36", value: 36 },
                    { key: "48", value: 48 },
                    { key: "60", value: 60 },
                    { key: "72", value: 72 },
                    { key: "84", value: 84 }
                ],
                "requested-payment-terms": [ "In Arrears", "In Advance" ],
                "requested-payment-frequency": [ "Monthly", "Fortnightly", "Weekly" ,"Annual" ],
                "sale-type": [ "Dealer", "Private Sale", "Refinance"],
                "personal-loan-reason": [ "Debt Consolidation", "Education", "Funeral Expenses", "Furniture and Appliances", "Home Improvements", "Medical Expenses", "Motor or Leisure Vehicle", "Other", "Solar Energy", "Tax Debt", "Travel", "Vehicle Repairs", "Wedding"]
            },
            visible: {
                "customer-discount-rate": true,
            },
            suffix: {
                "requested-term-months": 'months',
                "requested-payment-terms": ''
            },
            lenderConnectors: null,
            lendersResource: [],
            lenderSlugAlgoResource: [],

            CPPDResource: [],
        } 
    },
    computed: {
        ...mapState('flows', [
            'appGuid',
            'flowData',
            'loanData',
            'calculation',
            'redirectPaths',
        ]),
        ...mapState('auth', ['user']),
        ...mapState('statuses', [
            'flowStatus'
        ]),
        ...mapState('groups', [
            'groups'
        ]),
        ...mapState('auth-actions', [
            'user',
            'userSettings',
            'appOwnerSettings'
        ]),
        ...mapGetters('flows', [
            'getConfig',
        ]),
        ...mapGetters('groups', [
            'getApplicationGroup',
        ]),
        ...mapGetters('slug', [
            'getTenant',
            'getApiBase',
            'getGroupSlug',
        ]),

        isLoanMarket(){
            return this.getTenant == "loanmarket"
        },

        selectedLenderObj() {
            return store.selectedLenderObj
        },

        hasPrimary() {
            return isEmpty(this.selectedLenderObj.primary['bid-lender']?.id) ? false : true
        },

        hasSecondary() {
            return isEmpty(this.selectedLenderObj.secondary['bid-lender']?.id) ? false : true
        },
        isConsumer(){
            if(this.loanData){
                return  this.loanData.attributes['finance-for-business-purposes'] == 'No'
            }
            return false
        },
        isCommercial(){

            if(this.loanData){
                return  this.loanData.attributes['finance-for-business-purposes'] == 'Yes'
            }
            return false
        },
        isBidUser() {
            return this.appOwnerSettings.bid ? true : false
            // return true
        },
        isUnsecured() {
            return store.isUnsecured
        },
        
        isLoanTermEmpty() {
            return this.resources.loan.attributes['requested-term-months'] == null || this.resources.loan.attributes['requested-term-months'] == ''
        },

        isSaleTypeEmpty() {
            if(!this.isUnsecured)
                return this.resources.loan.attributes['sale-type'] == null || this.resources.loan.attributes['sale-type'] == ''
            else
                return false
        },
        vb() {
            return this.$vuetify.breakpoint
        },
        runQuoteLenders() {
                
            let lenders = []
            let quotes = {}

            quotes = this.responsePayload?.find( res => {
                return res.data?.data?.type === 'applications'
            })

            if ( quotes ) {  
                
                let data = quotes?.data?.included?.[0]?.attributes?.results ? quotes.data.included[0].attributes.results : {}
                
                if (!isEmpty(data)) {

                    data = JSON.parse(data)
                    // check if lenders is null return []
                    // console.log('data ssss',data.lenders)
                    
                    if ( data.lenders == null ) return lenders
                    
                    Object.keys( data?.lenders ).forEach( lender => {
                        // console.log(data.lenders[lender],data.lenders[lender].quotable)
                        if(!data?.lenders[lender].hasOwnProperty('status') && data?.lenders[lender]['quotable'] == 'Yes') {
                            lenders.push({ 
                            name: lender, 
                            inputs: data.inputs, 
                            details: data.lenders[lender],
                            'customer-rate': data.lenders[lender]['actual-payment']['customer-rate'],
                            'logo-class': data.lenders[lender]['logo-class'],
                            'term-in-months': data.lenders[lender]['actual-payment']['term-in-months'],
                            'repayments': data.lenders[lender]['actual-payment']['naf'],
                            'bank-fees': data.lenders[lender]['actual-payment']['bank-fees'],
                            'monthly-fees': data.lenders[lender]['actual-payment']['account-fee'],
                            'origination-fees': data.lenders[lender]['actual-payment']['broker-fee'],
                            'brokerage-commission': data.lenders[lender]['actual-payment']['commission-inc-gst'],
                            'total-commission': this.computedTotalCommission(data.lenders[lender]['actual-payment']['broker-fee'], data.lenders[lender]['actual-payment']['commission-inc-gst']),
                            'monthly-payment': data.lenders[lender]['actual-payment']['monthly-payment'],
                            'payment-terms': data.inputs['payment-terms'],
                        })
                        }
                    })
                    
                }
                
            }
            
            return lenders
            
        },
        unQuotedLenders() {

            const {runQuoteLenders: quotedLenders, isAsset, isConsumer, isCommercial} = this

            let collectedLenders = []

            let quotedLendersKeys = quotedLenders.map(data => {
                if(data?.["custom-lender"]){
                    return '--' // custom lender
                } else {
                    return data.name
                }
            })

            this.getLendersSlugAlgorithms.forEach(lender => {

                if(!quotedLendersKeys.includes(lender["lender-slug-algorithm"])) {

                    collectedLenders.push({ 
                        name: lender["lender-slug-algorithm"], 
                        alias: lender?.key,
                        connector: false,
                        inputs: {}, 
                        details: {
                            "logo-class": lender?.['logo-class'],
                            "name": lender?.['key'],
                            "actual-payment": {
                                "base-rate": 0,
                                "customer-rate": 0,
                                "term-in-months": 0,
                                "monthly-payment": 0,
                                "commission-inc-gst": 0,
                                "bank-fees": 0,
                                "broker-fee": 0,
                                "naf": 0,
                                "balloon": 0,
                                "account-fee": 0,
                                "comparison-rate": 0
                            },
                            "variables": {
                                "ppsr": 0,
                                "monthly-account-fee": 0,
                                "setup-risk-fee": 0
                            },
                        },
                        'customer-rate': 0,
                        'logo-class': lender?.['logo-class'],
                        'term-in-months': 0,
                        'repayments': 0,
                        'bank-fees': 0,
                        'monthly-fees': 0,
                        'origination-fees': 0,
                        'brokerage-commission': 0,
                        'total-commission': 0,
                        'monthly-payment': 0,
                        'payment-terms': "",
                    })
                }

            })


            /**
             * Add connector to lenders if exists
             */

            const {lenderConnectors} = this

            // console.log('collectedLenders', collectedLenders)
            // console.log('lenderConnectors', lenderConnectors)

            collectedLenders.forEach((lender, index) => {

                let lenderName = lender.alias

                if(lenderConnectors) {

                    lenderConnectors.forEach(connector => {
                        if(connector.name.includes(lenderName)) {
                            collectedLenders[index] = {
                                ...lender,
                                connector: true
                            }
                        }
                    })

                }

            })

            /**
             * Filter out lenders already in the Indicative Estimate
             */

             let filteredLenders = []

            collectedLenders.forEach((lender) => {
                let found = quotedLenders.some(quote => quote.details.name.includes(lender.alias))
                if(!found) {
                    filteredLenders.push(lender)
                }
            })

            // console.log('filteredLenders', filteredLenders)

            return filteredLenders
                    
        },
        isAsset(){
            const {calculation} = this
            return calculation ? calculation["app-loan-type"].includes('Asset') : false
        },
        getLendersSlugAlgorithms(){
            return store.lenderSlugAlgorithms
        },
        getLoanId(){
            return this.appData?.relationships?.loan?.data?.id
        },
        getTnfField(){
            return this.appData?.attributes?.tnf
        },
        getHybridTnfField() {
            return this.appData?.attributes?.['hybrid-tnf']
        },
        isBidFlow() {

            let flows = [
                "b2b-consumer-asset-bid", 
                "b2b-consumer-personal-bid"
            ]

            if( flows.includes(this.getFlowSlug)) {
                return true
            }

            return false
        },
        currentCPPDResource() {
            let { CPPDResource } = this
            let current = null

            if(CPPDResource.length > 0) {
                CPPDResource.forEach(cppd => {
                    if(cppd.status === 'Current') {
                        current = cppd
                    }
                })
            }

            return current
        },
        isDownloadedCPPDResource() {
            let { currentCPPDResource } = this
            let downloaded = false

            if(currentCPPDResource) {
                if(currentCPPDResource?.sent_at != null) {
                    downloaded = true
                }
            }

            return downloaded
        },
        hasHybridOptions() {

            // THIS HARDCODED TENANT BASED LOGIC IS TEMPORARY.
            // THIS WILL BE REPLACED BY CONFIGURABLE USER-SETTINGS.

            let tenants = [
                'loanmarket',
                'plan',
                'choice',
                'nectar',
                'nodifi',
                'fast'
            ]

            if( tenants.includes(this.getTenant)){
                return true
            }

            return false
        },
        isUltimo(){
            const {groups, getGroupSlug} = this

            const currentGroup = groups.find(group => group?.attributes?.slug === getGroupSlug)
            
            if(currentGroup) {
                return currentGroup.attributes.crm === 'ultimo'
            }

            return false
        },
        hasAssisterSubmit() {

            const {getApplicationGroup} = this

            if(this.isBidFlow) return false;
            if(this.isUltimo) return false;

            if(getApplicationGroup?.attributes?.crm === "nodificrm") {
                return false;
            }

            if(this.getTenant === 'app' && this.appTarget == 'Lender') {
                return false;
            }

            if(this.hasHybridOptions && this.getHybridTnfField === 0) {

                // Show Assister option for Nodifi and Commercial Co-Pilot ONLY!
                if(this.getTenant == 'nodifi' || this.isCommercial) {
                    return true
                } 

                return false
            }

            return true
        },
        isLenderSubmission() {
            let submit = false

            if(this.blockSettings && this.blockSettings['onClick']) {
                if(this.blockSettings['onClick'] == 'saveAndRedirectToLenderSubmission') {
                    submit = true
                }
            }

            return submit
        },
        disabledSubmitButton(){

            let disabled = true

            if(this.lendersData.length > 0) {

                if(this.isBidUser && !this.isCommercial) {

                    disabled = !this.hasSecondary || !this.hasPrimary || this.noBidDoc

                } else {

                    if(this.isCommercial) {
                        disabled = this.confirmedSelectedLender == ''
                    } else {
                        // disabled = this.confirmedSelectedLender == '' || !this.isDownloadedCPPDResource
                        disabled = this.confirmedSelectedLender == ''
                    }

                }

            } else if (this.hasRunQuote) {

                disabled = false

            }

            return disabled

        },
        appTarget() {
            return this.appData?.attributes?.target
        },
        disabledOtherSubmit() {
            return process.env.envNCEnableOtherSubmit == 0
        },
        disabledB2BLenderSubmit() {
            return process.env.envNCEnableB2BLenderSubmit == 0
        },
        hasProceedButton() {

            if(this.isBidFlow) {

                if(this.disabledOtherSubmit && this.disabledB2BLenderSubmit && !this.hasUserConnector) {
                    return false
                }

            }

            return true
        },
        showBidTable(){
            return this.isBidUser && !this.isCommercial
        },
        getRequestedRequiredLoanAmount(){
            return this.resources.loan.attributes['requested-required-loan-amount']
        },
        showOtherLenders(){
            
            if(this.hasRunQuote) {
                if(this.showBidTable) {
                    return true
                } else {
                    return this.unQuotedLenders.length > 0
                }
            }

            return false
        }
    },
    async beforeMount() {
        if(this.getLoanId) {
            let res = await this.getLoan(this.getLoanId)
            if(res.status === 200) {
                // console.log('loan typessss',res.data.data.attributes['loan-type'] == 'Unsecured' && !this.isCommercial)
                if(res.data.data.attributes['loan-type'] == 'Unsecured' && !this.isCommercial) {
                    mutations.setIsUnsecured(true)                                                                                                                                        
                    this.resources.loan.attributes = {
                        "requested-required-loan-amount": res.data.data.attributes["requested-required-loan-amount"],
                        "personal-loan-reason": res.data.data.attributes["personal-loan-reason"],
                        "selected-lender-quote-algorithm-slug": res.data.data.attributes["selected-lender-quote-algorithm-slug"],
                        "requested-payment-terms": res.data.data.attributes["requested-payment-terms"],
                        "requested-broker-fee": res.data.data.attributes["requested-broker-fee"],
                        "requested-term-months": res.data.data.attributes["requested-term-months"],
                        "requested-payment-frequency": res.data.data.attributes["requested-payment-frequency"],
                        "budgeted-payment-amount": res.data.data.attributes["budgeted-payment-amount"],
                    }
                }
            }
        }
    },
    created(){
        // console.log(this.blockSettings)
        // hide inputs for consumer
        this.visible['selected-lender-quote-algorithm-slug'] = true

        if(this.isCommercial) {
            this.hiddens['requested-payment-frequency'] = true
            this.validation_rules.attributes['requested-payment-frequency'] = ''
            this.visible['budgeted-payment-amount'] = false
            this.rateField = 'Preferred Interest Rate' 
        } else if (this.isConsumer) {
            this.visible['requested-payment-terms'] = false
            this.rateField = 'Customer Rate Reduction' 
        }

        if(this.hasHybridOptions) {
            this.showCompliance = true
        }
        
        if(this.getTnfField && this.getTnfField == 1) {
            this.radio = 'radio1'
        } else {
            this.radio = 'radio2'
        }
        /**
         * Set this step to touched by default
         */
        this.toggleTouched(true)
        
    },
    async mounted() {
        if(this.isBidFlow)
            await this.runUserConnectors()
    },
    watch: {
        'resources.purchases': {
            handler(val) {
                let totalLoanAmount = 0
                val.forEach( purchaseItem => {
                    totalLoanAmount += parseInt(purchaseItem.attributes['required-loan-amount'])
                })
                this.resources.loan.attributes['requested-required-loan-amount'] = totalLoanAmount
                
            },
            deep: true
        },
        runQuoteLenders(val) {

            this.lendersData = val

            // if bid flow don't map
            if(this.isBidFlow) return

            let slugAlgo = this.resources.loan.attributes['selected-lender-quote-algorithm-slug']

            if(slugAlgo) {

                let withoutQuote = true

                this.lendersData.forEach( lender => {
                    if ( lender.name === slugAlgo ) {
                        this.confirmedSelectedLender = lender.name
                        this.selectedLender = lender
                        withoutQuote = false
                    }
                }) 

                if(withoutQuote) {

                    let algo = this.getLendersSlugAlgorithms.find(item => slugAlgo == item["lender-slug-algorithm"])

                    let lender = this.buildCustomLenderData(algo)
                    this.lendersName = lender.name
                    this.logoClass = this.getLogoName(lender.name)

                    this.addLender({
                        "loan-term": lender.details['actual-payment']['term-in-months'],
                        "payment-terms": lender['payment-terms'],
                        "balloon-amount": lender.details['actual-payment']['balloon'],
                        "establishment-fee": lender.details['actual-payment']['bank-fees'],
                        "ppsr-fee": lender.details['variables']['ppsr'],
                        "origination-fee": lender.details['actual-payment']['broker-fee'],
                        "base-rate": lender.details['actual-payment']['base-rate'],
                        "customer-rate": lender.details['actual-payment']['customer-rate'],
                        "total-loan-amount": lender.details['actual-payment']['naf'],
                        "brokerage-commission": lender.details['actual-payment']['commission-inc-gst'],
                        "total-commission": parseFloat(lender.details['actual-payment']['commission-inc-gst'] + lender.details['actual-payment']['broker-fee']).toFixed(2),
                        "monthly-account-fee": lender.details['variables']['monthly-account-fee'],
                        "monthly-payment": lender.details['actual-payment']['monthly-payment'],
                        "lender-name": lender['name'],
                        "risk-fee": 0
                    })
                                        
                    this.confirmedSelectedLender = lender.name
                    this.selectedLender = lender

                }


            }
                
        },
        bidResource(val) {
            if(this.hasPrimary) {
                let isInLenders = this.isInLenders(this.runQuoteLenders, this.selectedLenderObj.primary['bid-lender'].attributes['lender-name'])
                
                if(!isInLenders) {
                    this.lendersName = this.selectedLenderObj.primary['bid-lender'].attributes['lender-name']
                    this.logoClass = this.getLogoName(this.selectedLenderObj.primary['bid-lender'].attributes['lender-name'])
                    this.addLender(this.selectedLenderObj.primary['bid-lender'].attributes)
                }
            }

            if(this.hasSecondary) {
                let isInLenders = this.isInLenders(this.runQuoteLenders, this.selectedLenderObj.secondary['bid-lender'].attributes['lender-name'])

                if(!isInLenders) {
                    this.lendersName = this.selectedLenderObj.secondary['bid-lender'].attributes['lender-name']
                    this.logoClass = this.getLogoName(this.selectedLenderObj.secondary['bid-lender'].attributes['lender-name'])
                    this.addLender(this.selectedLenderObj.secondary['bid-lender'].attributes)
                }
            }
        },
        currentCPPDResource(val) {

            // if bid flow don't map
            if(this.isBidFlow) return

            let slugAlgo = this.resources.loan.attributes?.['actual-lender-quote-algorithm-slug'] ?? this.resources.loan.attributes['selected-lender-quote-algorithm-slug']

            if(val?.lender_name && !slugAlgo) {

                // console.log(val.lender_name)
                // this.lendersName = val.lender_name
                // this.addLender(this.loanData.attributes)

                let lender = this.buildCustomLenderData({
                    "lender-slug-algorithm": val.lender_name,
                    "logo-class": val.lender_name,
                    "key": val.lender_name
                })

                this.lendersName = val.lender_name
                this.logoClass = this.getLogoName(val.lender_name)

                this.addLender({
                    "loan-term": lender.details['actual-payment']['term-in-months'],
                    "payment-terms": lender['payment-terms'],
                    "balloon-amount": lender.details['actual-payment']['balloon'],
                    "establishment-fee": lender.details['actual-payment']['bank-fees'],
                    "ppsr-fee": lender.details['variables']['ppsr'],
                    "origination-fee": lender.details['actual-payment']['broker-fee'],
                    "base-rate": lender.details['actual-payment']['base-rate'],
                    "customer-rate": lender.details['actual-payment']['customer-rate'],
                    "total-loan-amount": lender.details['actual-payment']['naf'],
                    "brokerage-commission": lender.details['actual-payment']['commission-inc-gst'],
                    "total-commission": parseFloat(lender.details['actual-payment']['commission-inc-gst'] + lender.details['actual-payment']['broker-fee']).toFixed(2),
                    "monthly-account-fee": lender.details['variables']['monthly-account-fee'],
                    "monthly-payment": lender.details['actual-payment']['monthly-payment'],
                    "lender-name": lender['name'],
                    "risk-fee": 0
                })
                                    
                this.confirmedSelectedLender = val.lender_name
                this.selectedLender = lender

            }

        }
    },
    methods: {
        buildCustomLenderData(algo){

            const {attributes: loan} = this.loanData

            // console.log('buildCustomLenderData', algo)

            let data = { 
                name: algo["lender-slug-algorithm"] ? algo["lender-slug-algorithm"] : '', 
                alias: algo?.key,
                connector: false,
                inputs: {}, 
                details: {
                    "logo-class": algo["logo-class"],
                    "actual-payment": {
                        "base-rate": loan['quoted-base-rate'],
                        "customer-rate": loan['quoted-customer-rate'],
                        "term-in-months": loan['quoted-term-months'],
                        "monthly-payment": loan['quoted-monthly-payments'],
                        "commission-inc-gst": loan['quoted-broker-commission-inc-gst'],
                        "bank-fees": loan['quoted-bank-fees'],
                        "broker-fee": loan['quoted-broker-fee'],
                        "naf": loan['quoted-naf'],
                        "balloon": loan['quoted-balloon'],
                        "account-fee": loan['quoted-account-fee'],
                        "comparison-rate": 0
                    },
                    "variables": {
                        "ppsr": 0,
                        "monthly-account-fee": 0,
                        "setup-risk-fee": 0
                    },
                },
                'customer-rate': 0,
                'logo-class': algo["logo-class"],
                'term-in-months': loan['quoted-term-months'],
                'repayments': 0,
                'bank-fees': 0,
                'monthly-fees': 0,
                'origination-fees': 0,
                'brokerage-commission': 0,
                'total-commission': 0,
                'monthly-payment': 0,
                'payment-terms': loan['quoted-payment-terms'],
            }
        
            return data
        },
        ...mapActions('resource-actions', [
            'getQuotes',
            'getApplicationLoan',
            'updateLoan',
            'getApplicationBid',
            'getLoan',
            'createLoan',
            'getPurchases',
            'createPurchase',
            'updatePurchases',
            'runCalculate',
            'getSub',
            'updateSub',
            'updateApplication',
            'getAssisters',
            'runAssisterSubmit',
            'submitOtherLender',
            'runLenderFlowService',
            'getLender',
            'getLenders',
            'getActiveLenderSubmissions',
            'createAssisterSubmissions',
            'deleteBidLender',
            'updateBidLender',
            'createCreditProposalDisclosures',
            'updateCreditProposalDisclosures',
            'generateCreditProposalDisclosures',
            'getCreditProposalDisclosures',
            'sendBidDoc'
        ]),
        ...mapActions('connectors', [
            'getConnectorTypes',
            'getConnectors',
        ]),
        ...mapActions('statuses', ['completeFlowStatus']),
        ...mapActions('flows', [
            'selectNextStep', 
            'forceCompleteApplication'
        ]),
        ...mapActions('dialogs', [
            'evaluateSteps'
        ]),
        ...mapActions('quote-actions', [
            'generateSocaPDF',
        ]),
        ...mapMutations('flows', [
            'setAppData'
        ]),
        ...mapMutations('statuses', {
            updateLocalStepStatus: 'updateStepStatus'
        }),
        ...mapMutations('dialogs', [
            'toggleTouched',
        ]),
        async handleOtherSubmit (form) {

            try {

                this.submittingToOther = true

                const res = await this.submitOtherLender({
                    "application": this.appGuid,
                    "name": form['lender-name'],
                    "reference": form['lender-reference'],
                })

                const flowStatusPayload = {
                    "id": this.flowStatus.id, 
                    "type": "flow-statuses",
                    "attributes": {
                        "completed": 1
                    }
                }

                await this.completeFlowStatus(flowStatusPayload)

                this.openOtherSubmit = false
                this.SelectSubmitDialog = false
                this.submittingToOther = false
                    
                const url = this.$flexConfigStringParser(this.redirectPaths['on-complete-flow'], {
                    config: this.getConfig,
                    route: {
                        groupSlug: this.getGroupSlug,
                        flowSlug: this.getFlowSlug,
                        applicationGuid: this.appGuid,
                    },
                    data: null
                })

                this.$router.push({ path: `/${this.getGroupSlug}/${url}` })


            } catch (err) {

                this.submittingToOther = false
            }

        },
        async pull() {
            try {

                let res = await this.fetchData()
                let loan = null

                if(res[0]?.status == 200) {

                    loan = res[0]?.data?.data

                    if(this.isConsumer) {
                        loan.attributes["requested-payment-terms"] = loan.attributes["requested-payment-terms"] ?? 'In Arrears'
                    }

                    mutations.setUpdatedLoanResource({
                        id: res[0]?.data?.data?.id,
                        type: 'loans',
                        attributes: {...loan.attributes}
                    })
                }

                this.resources.loan = await this.resourceFieldPicker(this.resources.loan, loan)

                this.resources.purchases = res[1].data.data

                if(res[2].data.data.length > 0) {
                    this.activeLenderSubmissions = res[2].data.data[0]
                }

                // Get Filtered Lenders 
                if(res[3].status == 200) {
                    this.lendersResource = res[3].data.data
                }

                // Get All Lender Slug Algorithms 
                if(res[4].status == 200) {
                    this.lenderSlugAlgoResource = res[4].data.data
                }

                this.setLendersSlugAlgorithms()

                // returns boolean
                const isRequirementsMeet = await this.evaluateQuoteRequirements(res[0].data.data)

                if(isRequirementsMeet) {

                    let response = await this.getQuotes({
                        'type': 'applications',
                        'id': this.appId,
                        'attributes': {},
                    })

                    this.hasRunQuote = true
                    this.responsePayload = response
                }

                if(this.isCommercial) {
                    this.visible['budgeted-payment-amount'] = false
                    this.requested_writing_rate = this.resources.loan.attributes['requested-writing-rate'] ? this.resources.loan.attributes['requested-writing-rate'] : 0
                } else {
                    this.visible['budgeted-payment-amount'] = true
                    this.requested_writing_rate = this.resources.loan.attributes['customer-discount-rate'] ? this.resources.loan.attributes['customer-discount-rate'] : 0
                }

                if(this.isBidUser && !this.isCommercial) {
                    await this.fetchBid()
                }

                const connectorsReqs = [this.getConnectorTypes(), this.getConnectors({ params: {users: [this.user.id] }})]
                const [connectorTypesRes, userConnectors] = await Promise.all(connectorsReqs)

                if(connectorTypesRes.status == 200 && userConnectors.status == 200) {

                    this.lenderConnectors = connectorTypesRes.data.data

                    const userConnectorTypes = userConnectors.data.data.map(connector => connector.type)

                    this.lenderConnectors = connectorTypesRes.data.data.filter( connectors => {
                        return userConnectorTypes.includes(connectors.slug)
                    })

                }

                let CPPD = await this.getCreditProposalDisclosures(this.appGuid)

                // Get All CPPD records
                if(CPPD && CPPD.status === 200) {
                    this.CPPDResource = CPPD.data.data
                }

            } catch (error) {
                console.log("errors.....", error)
            }
            
        },
        async push() {

            if(this.runQuoteLenders.length > 0) {

                if(this.isBidUser && !this.isCommercial) {
                    const valid = this.validateOptions()
                    let status = valid ? 'valid' : 'dirty'

                    return  { valid, status }

                } else {

                    const valid = !isEmpty(this.confirmedSelectedLender)

                    let status = valid ? 'valid' : 'dirty'
                    return  { valid, status } 
                    
                }

            } else {
                const valid = await this.$refs.observer.validate()
                const status = valid ? 'valid' : 'dirty'

                return { valid , status }

            }
           
        },
        async sendBidDocument() {
            try {
                this.sendingBidDoc = true

                const res = await this.sendBidDoc(this.appGuid)

                if(res.status === 200) {
                    this.$store.commit(
                        'utils/toggleQuickMessage',  
                        {
                            text: 'Successfully Sent BID Documents', 
                            color: 'success'
                        }
                    )
                }

                this.sendingBidDoc = false
            } catch (err) {
                this.$store.commit(
                    'utils/toggleQuickMessage',  
                    {
                        text: err.message, 
                        color: 'error'
                    }
                )
                this.sendingBidDoc = false
            }
           
        },
        onEditLender(lender) {
            this.editForm = lender
            this.logoClass = lender.details['logo-class']
            this.customLenderDialog = true
        },
        async updateLender(lender) {

            // console.log('updateLender', lender)
            // console.log('getLendersSlugAlgorithms', this.getLendersSlugAlgorithms)
            
            let data = {
                name: lender['lender-name'], 
                inputs: {
                    "payment-terms": lender['payment-terms']
                }, 
                details: {
                    "logo-class": this.getLogoName(lender['lender-name']),
                    "name": lender['lender-name'],
                    "actual-payment": {
                        "term-in-months": lender['loan-term'],
                        "balloon": lender['balloon-amount'],
                        "bank-fees": lender['establishment-fee'],
                        "base-rate": lender['base-rate'],
                        "naf": lender['total-loan-amount'],
                        "customer-rate": lender['customer-rate'],
                        "account-fee": lender['monthly-account-fee'],
                        "broker-fee": lender['origination-fee'],
                        "commission-inc-gst": lender['brokerage-commission'],
                        "monthly-payment": lender['monthly-payment'],
                    },
                    "variables": {
                        "setup-risk-fee": lender['risk-fee'],
                        "ppsr": lender['ppsr-fee'],
                        "monthly-account-fee": lender['monthly-account-fee']
                    },
                },
                "payment-terms": lender['payment-terms'],
                'customer-rate': lender['customer-rate'],
                'term-in-months': lender['loan-term'],
                'repayments': lender['naf'],
                'bank-fees': lender['bank-fees'],
                'monthly-fees': lender['monthly-account-fee'],
                'origination-fees': lender['origination-fe'],
                'brokerage-commission': lender['brokerage-commission-from'],
                'total-commission': lender['total-commission'],
                'monthly-payment': lender['monthly-payment'],
                "custom-lender": true,
            }

            const index = this.lendersData.findIndex(lenders => lenders['name'] === lender['lender-name'])

            if(index > -1) {
                Object.keys(data).forEach(dat => {
                    this.lendersData[index][dat] = data[dat]
                })
            }

            if(this.isBidFlow) {
                if(this.hasPrimary) {

                    if(this.selectedLenderObj.primary['bid-lender'].attributes['lender-name'] === lender['lender-name']) {
                        const obj = {
                            id: this.selectedLenderObj.primary['bid-lender'].id,
                            type: "bid-lenders",
                            attributes: {
                               ...lender
                            },
                            relationships: {
                                "bid": {
                                    "data": {
                                        "type": "bids",
                                        "id": this.selectedLenderObj.primary['bid-lender'].relationships.bid.data.id
                                    }
                                }
                            }
                        }
                        await this.updateBidLender(obj)
                    }
                    
                }

                if(this.hasSecondary) {

                    if(this.selectedLenderObj.secondary['bid-lender'].attributes['lender-name'] === lender['lender-name']) {
                        const obj = {
                            id: this.selectedLenderObj.secondary['bid-lender'].id,
                            type: "bid-lenders",
                            attributes: {
                            ...lender
                            },
                            relationships: {
                                "bid": {
                                    "data": {
                                        "type": "bids",
                                        "id": this.selectedLenderObj.secondary['bid-lender'].relationships.bid.data.id
                                    }
                                }
                            }
                        }
                        await this.updateBidLender(obj)
                    }

                }
            } else {

                let obj = {
                    id: this.getLoanId,
                    type: 'loans',
                    attributes: {
                        "requested-payment-terms": lender?.['payment-terms'],
                        "selected-lender-quote-algorithm-slug": lender?.['lender-name'],
                        "quoted-term-months": lender?.['loan-term'],
                        "quoted-bank-fees": lender?.['establishment-fee'],
                        "quoted-customer-rate": lender?.['customer-rate'],
                        "quoted-broker-fee": lender?.['origination-fee'],
                        "quoted-broker-commission-inc-gst": lender?.['brokerage-commission'],

                        ...(lender?.['base-rate'] && {"quoted-base-rate": lender?.['base-rate']}),
                        ...(lender?.['balloon-amount'] && {"quoted-balloon": lender?.['balloon-amount']}),
                        ...(lender?.['ppsr-fee'] && {"quoted-ppsr-fee": lender?.['ppsr-fee']}),
                        ...(lender?.['monthly-payment'] && {"quoted-monthly-payments": lender?.['monthly-payment']}),
                        ...(lender?.['monthly-account-fee'] && {"quoted-account-fee": lender?.['monthly-account-fee']}),

                    },
                    relationships: {
                        application: {
                            data: { type: "applications", id: this.appId }
                        }
                    }
                }

                let isLenderKnown = !!this.getLendersSlugAlgorithms.find((l) => l?.["lender-slug-algorithm"].includes(lender['lender-name']))

                if(!isLenderKnown){
                    delete obj.attributes["selected-lender-quote-algorithm-slug"]
                }
                    
                const res = await this.updateLoan(obj)
            }

        },
        async runUserConnectors() {
            const res = await this.runLenderFlowService(this.appGuid)

            if(res.status === 200) {
                this.hasUserConnector = true
            } else {
                this.hasUserConnector = false
            }
        },
        async resetLoanSelectedAlgo() {

            let obj = {
                id: this.getLoanId,
                type: 'loans',
                attributes: {
                    'selected-lender-quote-algorithm-slug': null
                },
                relationships: {
                    application: {
                        data: { type: "applications", id: this.appId }
                    }
                }
            }
                
            const res = await this.updateLoan(obj)
            this.noBidDoc = true

            if(res.status === 200) {
                await this.runUserConnectors()
            }
        },
        evaluateQuoteRequirements(loan = {}) {
            let valid = true

            if(loan.hasOwnProperty('attributes')) {
                // personal
                if(this.isUnsecured) {

                    if(!loan.attributes['requested-term-months']) {
                        valid = false
                    }

                } else {

                    if(!loan.attributes['sale-type'] && !loan.attributes['requested-term-months']) {
                        valid = false
                    }

                }
                
            }

            return valid
        },
        async submitToAssister() {
            let { appGuid } = this

            try {

                this.submissionSaving = true

                let assisterSubmissions = {
                    "set-target-only": 1,
                    'application': appGuid // guid
                }
                
                let res = await this.runAssisterSubmit(assisterSubmissions)
                
                if(res.status == 200) {

                    // Force update status to valid for cloned apps without any changes in the form of Indicative Quote 
                    if(this.appData?.attributes?.clone == 1) {
                        let stepStatusRes = await this.updateStepStatus('valid')
                        this.updateLocalStepStatus(stepStatusRes)
                    }

                    await this.evaluateSteps()
                }

                this.submissionSaving = false

            } catch (err) {
                console.log(err)
                this.submissionSaving = false
            }
            
        },
        validateOptions() {
            let valid = true
            if ( Object.keys(this.selectedLenderObj.primary).length == 0  ) {
                valid = false
            }
            if ( Object.keys(this.selectedLenderObj.primary).length == 0 ) {
                valid = false
            }
            return valid
        },
        async forceNextStep() {
            const valid = await this.$refs.observer.validate()
            if(valid) {
                await this.calculate()
                await this.selectNextStep()
            }
        },
        selectSubmit() {
            // show selection of lender to submit
            this.SelectSubmitDialog = true
        },
        closeSelectSubmit() {
            this.SelectSubmitDialog = false
        },
        async fetchBid() {
            let resApp = await this.getApplicationBid({ id: this.appId, include: 'bid-lenders'})
                
            let bid = resApp?.data?.data

            let bidLender = resApp?.data?.included?.filter( lender => {
                return lender.type === 'bid-lenders'
            })

            let primary = bidLender?.find( payload => {
                return payload.attributes['lender-type'] === 'Primary'
            })

            let secondary = bidLender?.find( payload => {
                return payload.attributes['lender-type'] === 'Alternative'
            })

            // get logo for primary selected
            if(this.lendersData.length > 0) {
                this.lendersData.forEach(lender => {
                    if(lender.name == this.resources.loan.attributes['selected-lender-quote-algorithm-slug'] ) {
                        mutations.setSelectedPrimaryLender(lender.details['logo-class'])
                    }
                })
            }

            // get logo in selected is in unquoted lender
            if(this.unQuotedLenders.length > 0) {
                this.unQuotedLenders.forEach(lender => {
                    if(lender.name == this.resources.loan.attributes['selected-lender-quote-algorithm-slug'] ) {
                        mutations.setSelectedPrimaryLender(lender.details['logo-class'])
                    }
                })
            }

            mutations.setPrimary(
                {
                    'bid': bid,
                    'bid-lender': primary
                }
            )

            mutations.setSecondary(
                {
                    'bid': bid,
                    'bid-lender': secondary
                }
            )

            mutations.setBidData(bid)
            this.bidResource = bid

        },
        selectCom(val) {
            this.radio = val
        },
        async calculate() {
            this.calculateLoading = true
            this.validComponentData = false
            const valid = await this.$refs.observer.validate()

            if(valid) {

                if(!this.isCommercial) {
                    this.resources.loan.attributes['customer-discount-rate'] = this.requested_writing_rate

                    // if not unsecured
                    if(!this.isUnsecured) {
                        this.resources.loan.attributes['requested-writing-rate'] = null
                    }

                } else {
                    this.resources.loan.attributes['requested-writing-rate'] = this.requested_writing_rate
                    this.resources.loan.attributes['customer-discount-rate'] = null
                    this.resources.loan.attributes['requested-payment-frequency'] = "Monthly"
                }

                let res = null

                if (this.getLoanId) {

                    if(this.resources?.loan?.relationships) {
                        this.resources.loan.relationships = this.filterRelationships(this.resources.loan.relationships)
                    }

                    //reset selection
                    this.resources.loan.attributes['selected-lender-quote-algorithm-slug'] = null


                    const loanPayload = {
                        ...this.resources.loan,
                        attributes: {
                            ...this.resources.loan.attributes,
                            'actual-required-loan-amount': this.resources.loan.attributes['requested-required-loan-amount']
                        }
                    }

                    res = await this.updateLoan(loanPayload)
                    // clear cpdd :lender-name

                    if(this.currentCPPDResource?.lender_name) {
                        let payload = {
                            appGuid: this.appGuid,
                            data: { lender_name: null },
                        }
                        await this.updateCreditProposalDisclosures(payload)
                    }

                } else {

                    delete this.resources.loan.id
                    this.resources.loan.relationships = {
                        application: {
                            data: { type: "applications", id: this.appId }
                        }
                    }

                    res = await this.createLoan(this.resources.loan)
                }

                if ( res.status === 200 || res.status === 201 ) {
                    await this.resetQuoteSelection()
                    await this.pull()
                }
            }

            this.validComponentData = true
            this.calculateLoading = false

        },
        async resetQuoteSelection() {

            try {

                if(this.isBidUser && !this.isCommercial) {

                    // bid flow

                    if(this.hasPrimary)
                        await this.deleteBidLender(this.selectedLenderObj.primary['bid-lender'].id)

                    if(this.hasSecondary)
                        await this.deleteBidLender(this.selectedLenderObj.secondary['bid-lender'].id)

                    mutations.resetSecondary()
                    mutations.resetPrimary()
                    mutations.setBidData(null)

                } else {

                    // asset flow

                    this.confirmedSelectedLender = ''
                    this.selectedLender = null

                }

            } catch (err) {
                console.log(err)
            }

        },
        isVisible(key){
                
            const {visible} = this

            if(visible.hasOwnProperty(key)){
                return visible[key]
            }

            return true

        },
        filterFieldLayout(attributes = {}) {

            if(!this.isUnsecured) {
                Object.keys(attributes).forEach(key => {
                    if(!this.isVisible(key) && key != 'customer-discount-rate') {
                        delete attributes[key]
                    }
                })
            }

            return attributes
        },
        async fetchData() {

            let loanRes = this.getLoan(this.getLoanId)
            let purchasesRes = this.getPurchases()
            let activeLenderSub = this.getActiveLenderSubmissions(this.appId)

            const {isAsset, isConsumer} = this

            let features = [
                'CommercialSecured',
                'CommercialUnsecured',
                'ConsumerSecured',
                'ConsumerUnsecured',
                'NovatedLease'
            ]

            let type1 = isConsumer ? 'Consumer' : 'Commercial'
            let type2 = isAsset ? 'Secured' : 'Unsecured'
            let feature = features.find( feat => feat.includes(type1) && feat.includes(type2))

            let lendersResource = this.getLenders({
                "filter[supports]": feature ? feature : 'NovatedLease',
                "page[size]": 100,
            })
            let lenderAlgos =  this.$api.get(`lender-quote-algorithms`, {params: {
                "page[size]": 100,
            }})
            
            return Promise.all([loanRes, purchasesRes, activeLenderSub, lendersResource, lenderAlgos])
            .then( data => {
                    return data
                }
            )
            .catch( err => {
                    return err
                }
            )
        },
        async generateSoca (data = {}) {
                
            this.pdfLoading = true

            this.bidResource.attributes['referral-fee-estimate'] = data['referral-fee-estimate']
            this.bidResource.attributes['referral-partner-name'] = data['referral-partner-name']
            
            const res = await this.generateSocaPDF(this.appId)

            if (res?.data?.included) {
                if (res.data.included[0].attributes['file-url']) {
                    // open to new tab
                    window.open(res.data.included[0].attributes['file-url'], '_blank');

                }
            } else {
                console.log('Some Error')
            }

            this.noBidDoc = false // flag for bid doc

            this.pdfLoading = false

        },
        async generateCPDD (data = {}) {
            
            try {

                this.pdfLoading = true

                let filteredData = {}
                
                Object.keys(data).forEach( key => {
                    if(data[key] != null) {
                        filteredData[key] = data[key]
                    } 
                })
            
                const res = await this.updateCreditProposalDisclosures({
                    data: filteredData,
                    appGuid: this.appGuid
                })

                if (res.status === 201 || res.status === 200) {

                    this.$store.commit(
                        'utils/toggleQuickMessage',  
                        {
                            text: 'Successfully Generate CPDD Documents', 
                            color: 'success'
                        }
                    )

                    await this.generateCreditProposalDisclosures({guid: this.appGuid}).then((response) => {
                        const url = window.URL.createObjectURL(new Blob([response.data]));
                        const link = document.createElement('a');
                        link.href = url;
                        link.setAttribute('download', `CreditProposalDisclosure-${this.appGuid}.pdf`);
                        document.body.appendChild(link);
                        link.click();
                    });

                    await this.pull()

                } else {

                    const errorMsg = 'We are missing information on your business to be able to generate your CPDD. To complete the setup contact asset.partners@loanmarketgroup.com'

                    this.$store.commit(
                        'utils/toggleQuickMessage',  
                        {
                            // text: res.hasOwnProperty('message') ? res.message : res.data.message, 
                            text: errorMsg, 
                            color: 'error'
                        }
                    )
                }

                this.noCPDDDoc = false

                this.pdfLoading = false

            } catch (err) {

                console.log(err)
            }
            
        },
        async saveLender(data) {

            // check if custom lender
            let selectedLenderSlugAlgo = this.getLendersSlugAlgorithms.find((lender) => lender?.["lender-slug-algorithm"].includes(data?.lender))

            try {
                this.saving = true

                // if commercial default requested-payment-frequency to Monthly
                if(this.isCommercial) {
                    data.loans.attributes['requested-payment-frequency'] = 'Monthly'
                }

                let obj = {
                    id: this.getLoanId,
                    type: 'loans',
                    attributes: {
                        ...this.resources.loan.attributes,
                        ...data.loans.attributes
                    },
                    relationships: {
                        application: {
                            data: { type: "applications", id: this.appId }
                        }
                    }
                }

                if(!selectedLenderSlugAlgo) {
                    obj.attributes["selected-lender-quote-algorithm-slug"] = null
                }

                // Update CPDD lender-name
                if(this.isConsumer) {
                    await this.updateCreditProposalDisclosures({
                        appGuid: this.appGuid,
                        data: { 
                            lender_name: !!selectedLenderSlugAlgo ?  selectedLenderSlugAlgo?.name  : data?.lender 
                        },
                    })
                }

                const loanRes = await this.updateLoan(obj)

                if(loanRes?.status == 200) {
                    mutations.setUpdatedLoanResource(loanRes?.data?.data)
                }

                this.confirmedSelectedLender = data.lender
                this.saving = false
                
            } catch (err) {
                this.saving = false
                console.log(err)
            }

        },
        async submitToLender() {

            let { activeLenderSubmissions } = this

            try {

                this.submissionSaving = true

                if( activeLenderSubmissions ) {

                    // get lender first
                    let lenderRes = await this.getLender(activeLenderSubmissions.relationships.lender.data.id)

                    if( lenderRes.status === 200 ) {

                        if( this.confirmedSelectedLender.includes(lenderRes.data.data.attributes.slug) ) {
                            this.isSameLender = true
                        } else {
                            this.isSameLender = false
                        }

                    }

                    if( this.isSameLender ) {

                        // if lender submission status === Lender Submission Failed proceed with lender submit else prompt message
                        if(activeLenderSubmissions.attributes.status == "Lender Submission Failed") {

                            await this.redirectToLenderFlow()

                        } else {

                            this.confirmDialog = true
                            this.confirmDialogTitle = "Application is already submitted or being submitted to this lender"

                        }
                        
                    } else {
                        // confirmation modal
                        this.confirmDialog = true
                        this.confirmDialogTitle = "You have an active submission, are you sure you want to proceed?"
                    }

                } else {
                    // if no active submissions call the lender-submit service
                    await this.redirectToLenderFlow()

                }

                this.submissionSaving = false

            } catch (err) {

                this.isSameLender = true
                this.confirmDialog = true
                this.confirmDialogTitle = err.message
                this.submissionSaving = false

            }

        },
        async proceedForceSubmitToLender() {

            // Update loan's actual fields

            let quotedFields = [
            "quoted-monthly-payments",
            "quoted-payment-terms",
            "quoted-naf",
            "quoted-balloon",
            "quoted-base-rate",
            "quoted-customer-rate",
            "quoted-broker-commission-inc-gst",
            "quoted-bank-fees",
            "quoted-account-fee",
            "quoted-ppsr-fee",
            "quoted-risk-fee",
            "requested-broker-fee",
            "requested-term-months",
            "requested-writing-rate",
            "requested-payment-frequency",
            "requested-required-loan-amount",
            "selected-lender-quote-algorithm-slug"]

            let actualFields = [
            "actual-monthly-payments",
            "actual-required-loan-amount",
            "actual-payment-frequency",
            "actual-payment-terms",
            "actual-naf",
            "actual-balloon",
            "actual-base-rate",
            "actual-customer-rate",
            "actual-broker-commission-inc-gst",
            "actual-bank-fees",
            "actual-broker-fee",
            "actual-term-months",
            "actual-account-fee",
            "actual-writing-rate",
            "actual-ppsr-fee",
            "actual-risk-fee",
            "actual-lender-quote-algorithm-slug"]

            let loanPayload = {
                id: store.updatedLoanResource?.id,
                type: 'loans',
                attributes: {}
            }

            quotedFields.forEach(field => {

                if(store.updatedLoanResource?.attributes?.[field] != null) {
                    
                    let unPrefixedField = field.replace('quoted-', '')
                        unPrefixedField = unPrefixedField.replace('requested-', '')
                        unPrefixedField = unPrefixedField.replace('selected-', '')

                    if(unPrefixedField === 'naf'){

                        loanPayload.attributes['actual-total-amount-financed'] = store.updatedLoanResource?.attributes?.[field]

                    }  else {

                        loanPayload.attributes[`actual-${unPrefixedField}`] = store.updatedLoanResource?.attributes?.[field]
                    }
                }
            })

            await this.updateLoan(loanPayload)

            // Complete Application
            this.forceCompleteApplication()
        },
        async redirectToLenderFlow() {

            if (this.lenderSubmissionFlowSlug) {

                const url = `/${this.getGroupSlug}/flows/${this.lenderSubmissionFlowSlug}/first/${this.appGuid}`

                if(url) {
                    window.location.replace(url)
                }
            }

        },
        computedTotalCommission(brokerFee = 0, cig = 0) {
            return parseFloat(cig) + parseFloat(brokerFee)
        },
        addOtherLender(name) {

            this.lendersName = name
            this.logoClass = this.getLogoName(name)
            this.editForm = null

            this.otherLenderDialog = false
    
            this.customLenderDialog = true

        },
        handleBackButton() {

            if(this.isBidFlow){
                this.otherLenderDialog = true 
            } 

            this.customLenderDialog = false

        },
        addLender(lender) {

            // console.log('addLender', lender)

            let data = {
                name: this.lendersName, 
                inputs: {
                    "payment-terms": lender['payment-terms']
                }, 
                details: {
                    "logo-class": this.logoClass,
                    "name": this.lendersName,
                    "actual-payment": {
                        "term-in-months": lender['loan-term'],
                        "balloon": lender['balloon-amount'],
                        "bank-fees": lender['establishment-fee'],
                        "base-rate": lender['base-rate'],
                        "naf": lender['total-loan-amount'],
                        "customer-rate": lender['customer-rate'],
                        "account-fee": lender['monthly-account-fee'],
                        "broker-fee": lender['origination-fee'],
                        "commission-inc-gst": lender['brokerage-commission'],
                        "monthly-payment": lender['monthly-payment'],
                    },
                    "variables": {
                        "setup-risk-fee": lender['risk-fee'],
                        "ppsr": lender['ppsr-fee'],
                        "monthly-account-fee": lender['monthly-account-fee']
                    },
                },
                "payment-terms": lender['payment-terms'],
                'customer-rate': lender['customer-rate'],
                'term-in-months': lender['loan-term'],
                'repayments': lender['naf'],
                'bank-fees': lender['bank-fees'],
                'monthly-fees': lender['monthly-account-fee'],
                'origination-fees': lender['origination-fe'],
                'brokerage-commission': lender['brokerage-commission-from'],
                'total-commission': lender['total-commission'],
                'monthly-payment': lender['monthly-payment'],
                "custom-lender": true,
            }

            this.lendersData.push(data)

        },
        getLogoName(name = '') {

            let algo = this.getLendersSlugAlgorithms.find(item => name == item["lender-slug-algorithm"])
            return algo ? algo['logo-class'] : 'placeholder'
                
        },
        isInLenders(lenders = [], name = '') {
            let isPresent = false

            lenders.forEach(lender => {
                if(lender.details.name == name) isPresent = true
            })

            return isPresent
        },
        async handleSubmit(){

            this.saving = true

            if(this.appTarget != 'Assister') {
                await this.runLenderFlow()
            }

            this.selectSubmit()
            this.saving = false

        },
        async runLenderFlow() {

            try {

                let res = await this.runLenderFlowService(this.appGuid)

                if(res.status === 200) {
                    this.hasLenderFlow = true
                    this.lenderSubmissionFlowSlug = res.data.flow
                } else {
                    this.hasLenderFlow = false
                }

            } catch (err) {
                console.log(err)
            }

        },
        setLendersSlugAlgorithms(){

            const {isAsset, isConsumer, isCommercial} = this

            let slugAlgos = []

            let prefixes = isAsset ? [
                    "api-finance",  // specific for FinanceOne
                    "nod-asset",
                    "api-asset",
                    "api-secured"
                ] : [
                    "api-money", // specific for MoneyPlace
                    "nod-personal",
                    "api-personal"
                ]

            this.lendersResource.forEach(lender => {

                let getSlugAlgos = this.lenderSlugAlgoResource.filter(algo => {
                    return algo.relationships.lender.data.id == lender.id
                })

                let slugAlgoArray = []

                if(getSlugAlgos.length){
                    slugAlgoArray = getSlugAlgos.filter(algo => {
                        return prefixes.some( prefix => algo.attributes.slug.includes(prefix))
                    })
                } 

                if(slugAlgoArray.length == 1) {
                    let lenderObj = {
                        "id": lender.id,
                        "name": lender.attributes.name,
                        "key": lender.attributes.name,
                        "features": lender.attributes.features,
                        "logo-class": lender.attributes.logo,
                        "lender-slug": lender.attributes.slug,
                        "lender-slug-algorithm": slugAlgoArray[0]?.attributes?.slug,
                        "lender-submission-flow-slug": null
                    }

                    slugAlgos.push(lenderObj)
                }

                /**
                 * NOTE: THIS IS A TEMPORARY FILTERING FOR LENDERS W/ MULTIPLE 
                 * ASSETS, THERE IS NO WAY TO IDENTIFY THE RELATED LQAS WHICH ONE IS COMMERCIAL OR CONSUMER
                 */
                let lendersWithMultiAssetType = isCommercial ? [
                    "nod-asset-pepper",
                    "nod-asset-finance-one-commercial",
                    "nod-asset-liberty-commercial"
                ] : [
                    "nod-asset-pepper-consumer",
                    "api-finance-one",
                    "api-asset-finance-one",
                    "nod-asset-liberty"
                ]

                if(slugAlgoArray.length > 1) {

                    let targetSlugAlgo = slugAlgoArray.find(algo => lendersWithMultiAssetType.includes(algo.attributes.slug))

                    if(!targetSlugAlgo) {
                        targetSlugAlgo = slugAlgoArray[0]
                    }

                    let lenderObj = {
                        "id": lender.id,
                        "name": lender.attributes.name,
                        "key": lender.attributes.name,
                        "features": lender.attributes.features,
                        "logo-class": lender.attributes.logo,
                        "lender-slug": lender.attributes.slug,
                        "lender-slug-algorithm": targetSlugAlgo?.attributes?.slug,
                        "lender-submission-flow-slug": null
                    }

                    slugAlgos.push(lenderObj)
                }
                // END TEMP FILTERING 

            })

            // console.log('lenderSlugAlgoResource', this.lenderSlugAlgoResource)

            mutations.setLenderSlugAlgorithms(slugAlgos)

        },
    }
}
</script>
<style lang="scss" scoped>
</style>
