<template>
    <ValidationObserver ref="observer" tag="form">
        <v-row>                        
            <v-col cols="12" md="6" class="px-3 py-0">
                <ValidationProvider 
                    v-slot="{ errors }" 
                    name="Name" 
                    rules="required"
                    vid="Name">
                    <v-text-field
                        v-model="form.reference.name"
                        color="secondary"
                        :error-messages="errors"
                        :label="fields['name'].label">
                    </v-text-field>
                </ValidationProvider>
            </v-col>
            <v-col 
                v-if="selectedReference === 'Accountant' ? false : true"
                cols="12" md="6" class="px-3 py-0">
                <ValidationProvider 
                    v-slot="{ errors }" 
                    name="Relationship" 
                    rules="required"
                    vid="Relationship">
                    <v-select
                        v-model="form.reference.relationship"
                        :items="relationship[selectedReference]"
                        :error-messages="errors"
                        color="secondary"
                        :label="fields['relationship'].label">
                    </v-select>
                </ValidationProvider>
            </v-col>
            <v-col 
                v-if="selectedReference === 'Accountant' || selectedReference === 'Landlord'"
                cols="12" md="6" class="px-3 py-0">
                <ValidationProvider 
                    v-slot="{ errors }" 
                    name="Email" 
                    rules="email"
                    vid="Email">
                    <v-text-field
                        v-model="form.reference.email"
                        :error-messages="errors"
                        color="secondary"
                        :label="fields['email'].label">
                    </v-text-field>
                </ValidationProvider>
            </v-col>
            <v-col 
                v-if="false"
                cols="12" md="6" class="px-3 py-0">
                <ValidationProvider 
                    v-slot="{ errors }" 
                    name="Date" 
                    rules="required"
                    vid="Date">
                    <FlexDatePicker 
                        v-model="form.reference.date"
                        :errors="errors"
                        color="secondary"
                        :label="fields['date'].label" />
                </ValidationProvider>
            </v-col>
            <v-col 
                cols="12" 
                :md="selectedReference === 'Accountant' || selectedReference === 'Business' ? 12 : 6" 
                class="px-3 py-0">
                <ValidationProvider 
                    v-slot="{ errors }" 
                    name="Phone" 
                    rules="required|length:10|numeric"
                    vid="Phone">
                    <v-text-field
                        v-model="form.reference.phone"
                        inputmode="numeric"
                        :error-messages="errors"
                        color="secondary"
                        :label="fields['phone'].label">
                    </v-text-field>
                </ValidationProvider>
            </v-col>
            <v-col 
                v-if="!isPersonal"
                cols="12" sm="6" md="5" class="px-3 py-0">
                <ValidationProvider 
                    v-slot="{ errors }" 
                    name="Is Business" 
                    rules="required"
                    vid="Is Business">
                    <IsBusiness 
                        v-model="form.reference['is-business']"
                        :items="['Yes', 'No']"
                        :error-messages="errors"
                        :label="fields['is-business'].label" />
                </ValidationProvider>
            </v-col>
            <v-col 
                v-if="!isPersonal && (form.reference['is-business'] !== 'No' && form.reference['is-business'] !== '' ? true : false)"                                   
                cols="12" sm="6" md="7" class="px-3 py-0">
                <ValidationProvider 
                    v-slot="{ errors }" 
                    name="Business Name" 
                    :rules="form.reference['is-business'] === 'Yes' ? 'required' : form.reference['is-business'] === '' ? 'required' : '' "
                    vid="Business Name">
                    <v-text-field
                        v-model="form.reference['business-name']"
                        :error-messages="errors"
                        :disabled="form.reference['is-business'] === 'No' ? true : false"
                        color="secondary"
                        :label="fields['business-name'].label">
                    </v-text-field>
                </ValidationProvider>
            </v-col>
        </v-row>
    </ValidationObserver>
</template>
<script>
import formHelpers from '~/components/shared/form-helpers.js'
import validation from '~/components/shared/validation'
import IsBusiness from '~/components/base/ButtonSelections'
import FlexDatePicker from '~/components/base/FlexDatePicker'

export default {
    name: 'ReferenceDetails',

    mixins: [formHelpers, validation],

    components: { IsBusiness, FlexDatePicker },

    props: {
        syncReset: {
            type: Boolean,
            default: false
        },
        selectedReference: {
            type: String,
            default: ''
        },
        reference: {
            type: Object,
            default: () => {}
        }
    },

    computed: {
        isPersonal() {
            return this.selectedReference == 'Personal'
        }
    },

    watch: {
        'form.reference': {
            async handler(val) {
                this.$emit('change-form', { form: val})
            },
            deep: true
        },
        reference(val) {
            if(Object.keys(val).length > 0) {
                Object.keys(val).forEach(f => {
                    Object.keys(this.form.reference).forEach(k => {
                        if(k == f)
                            this.form.reference[f] = val[f]
                    }) 
                })
            }
        },
        syncReset(val) {
            this.form.reference = {
                'name': '',
                'relationship': '',
                'is-business': '',
                'business-name': '',
                'phone': '',
                'date': '',
                'email': ''
            }
        }
    },

    data() {
        return {
            form: {
                reference: {
                    'name': '',
                    'relationship': '',
                    'is-business': '',
                    'business-name': '',
                    'phone': '',
                    'date': '',
                    'email': ''
                }
            },
            fields: {
                    'name': { placeholder: '', label: 'Contact Name', name: 'name' },
                    'relationship': { placeholder: '', label: 'Relationship', name: 'relationship' },
                    'is-business': { placeholder: '', label: 'Is Business', name: 'is-business' },
                    'business-name': { placeholder: '', label: 'Business Name', name: 'business-name' },
                    'phone': { placeholder: '', label: 'Phone Number', name: 'phone' },
                    'date': { placeholder: '', label: 'Reference Date', name: 'date' },
                    'email': { placeholder: '', label: 'Email', name: 'email' },
            },
            validation_rules: {
                attributes: {
                    "email": "required|email"
                }
            },
            relationship: {
                'Accountant': ['Accountant'],
                'Business': ['Trade', 'Work Colleague'],
                'Landlord': ['Landlord - RE Agent', 'Landlord - Private'],
                'Personal': ['Friend', 'Family Member']
            }
        }
    },

}
</script>
