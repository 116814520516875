var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"scrollable":"","width":"900","persistent":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',{staticClass:"mx-2",staticStyle:{"position":"relative"}},[_c('v-btn',_vm._g(_vm._b({class:_vm.cpddSent ? 'success' : 'secondary',attrs:{"depressed":"","large":"","disabled":_vm.disableCPDDButton,"loading":_vm.syncLoading}},'v-btn',attrs,false),on),[(_vm.$vuetify.breakpoint.smAndUp)?_c('div',[_vm._v("\n          "+_vm._s(_vm.cpddSent ? "Completed" : "CPDD Generation")+"\n        ")]):_c('div',[_vm._v("CPD")])]),_vm._v(" "),(_vm.privacyButtonTooltip)?_c('v-tooltip',{attrs:{"bottom":"","max-width":"300"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g({staticClass:"warning--text",staticStyle:{"cursor":"pointer","position":"absolute","left":"-8px"},attrs:{"size":"20"}},on),[_vm._v("\n            mdi-information\n          ")])]}}],null,true)},[_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.privacyButtonTooltip))])]):_vm._e()],1)]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_vm._v(" "),_c('v-card',{staticClass:"overflow-y-auto",attrs:{"flat":""}},[(_vm.pageLoader)?_c('v-progress-linear',{attrs:{"color":"secondary","indeterminate":"","stream":"","top":"","value":"0"}}):_vm._e(),_vm._v(" "),(_vm.pageLoader)?_c('v-overlay',{attrs:{"absolute":"","opacity":0.1}}):_vm._e(),_vm._v(" "),_c('v-card-title',[_c('v-spacer'),_vm._v(" "),_c('v-btn',{attrs:{"icon":""},on:{"click":_vm.close}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_vm._v(" "),_c('v-card-text',{staticClass:"pa-0"},[_c('v-card',{staticClass:"rounded-lg mx-auto",attrs:{"width":"600","tile":"","flat":""}},[_c('v-card-title',{staticClass:"pb-5"},[_vm._v(" Generate CPD Document ")]),_vm._v(" "),_c('v-card-text',[_c('ValidationObserver',{ref:"observer",attrs:{"tag":"form"}},[_c('v-stepper',{attrs:{"value":_vm.activeStep,"alt-labels":""}},[_c('v-stepper-items',[_c('v-stepper-content',{attrs:{"step":1}},[_c('ValidationProvider',{attrs:{"name":"Does the client fit the Target Market Determination for the product?","rules":_vm.getRules('tmd_client_fit'),"vid":"tmd_client_fit"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"tmd_client_fit"}},[_c('div',{staticClass:"font-weight-bold"},[_vm._v("\n                        Does the client fit the\n                        "),_c('a',{attrs:{"href":"https://nodifi.box.com/s/yxwbuombboifh3hc08tvx5lb6br3boc7","target":"_blank"}},[_vm._v("Target Market Determination")]),_vm._v("\n                        for the product?\n                      ")])]),_vm._v(" "),_c('ReferralPartner',{attrs:{"error-messages":errors,"label":''},model:{value:(_vm.form['tmd_client_fit']),callback:function ($$v) {_vm.$set(_vm.form, 'tmd_client_fit', $$v)},expression:"form['tmd_client_fit']"}})]}}])})],1),_vm._v(" "),_c('v-stepper-content',{attrs:{"step":2}},[_c('ValidationProvider',{attrs:{"name":"Why have you recommended this product to the client","rules":_vm.getRules('tmd_client_recommendation_reason'),"vid":"tmd_client_recommendation_reason"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"items":_vm.tmdPickList,"item-text":"name","item-value":"value","label":"Why have you recommended this product to the client","error-messages":errors},model:{value:(_vm.form['tmd_client_recommendation_reason']),callback:function ($$v) {_vm.$set(_vm.form, 'tmd_client_recommendation_reason', $$v)},expression:"form['tmd_client_recommendation_reason']"}})]}}])}),_vm._v(" "),(_vm.form['tmd_client_recommendation_reason'] == 'Other')?_c('ValidationProvider',{attrs:{"name":"Your answer","rules":_vm.getRules('tmd_client_recommendation_reason_other'),"vid":"tmd_client_recommendation_reason_other"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-textarea',{attrs:{"label":"Please outline how the customer does not meet the Target Market?","error-messages":errors},model:{value:(_vm.form['tmd_client_recommendation_reason_other']),callback:function ($$v) {_vm.$set(_vm.form, 'tmd_client_recommendation_reason_other', $$v)},expression:"form['tmd_client_recommendation_reason_other']"}})]}}],null,false,3868367013)}):_vm._e(),_vm._v(" "),_c('v-alert',{attrs:{"dense":"","type":"info","text":""}},[_c('div',{staticClass:"font-size-12"},[_vm._v("\n                      Please note: Under broker Design and Distribution\n                      Obligations (RG 274) please ensure that you carefully\n                      consider your client and the product target market when\n                      answering this question. If you indicate that the client\n                      does not meet the target market, we will provide this\n                      reporting directly to the lender in line with our\n                      distributor obligations under RG 274.\n                    ")])])],1),_vm._v(" "),_c('v-stepper-content',{attrs:{"step":3}},[_c('ValidationProvider',{attrs:{"name":"Referrer Payment","rules":_vm.getRules('referral_payment'),"vid":"referral_payment"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('ReferralPartner',{attrs:{"error-messages":errors,"label":"Will you be paying a referral partner for this deal?"},model:{value:(_vm.form['referral_payment']),callback:function ($$v) {_vm.$set(_vm.form, 'referral_payment', $$v)},expression:"form['referral_payment']"}})]}}])}),_vm._v(" "),_c('ValidationProvider',{attrs:{"name":"Referrer Name","rules":_vm.getRules('referral_partner_name'),"vid":"referral_partner_name"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Referrer Name","error-messages":errors},model:{value:(_vm.form['referral_partner_name']),callback:function ($$v) {_vm.$set(_vm.form, 'referral_partner_name', $$v)},expression:"form['referral_partner_name']"}})]}}])}),_vm._v(" "),_c('ValidationProvider',{attrs:{"name":"Referral Fee Estimate","rules":_vm.getRules('referral_fee_estimate'),"vid":"referral_fee_estimate"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('NodifiMoney',{attrs:{"label":"Referral Fee Estimate","errors":errors},model:{value:(_vm.form['referral_fee_estimate']),callback:function ($$v) {_vm.$set(_vm.form, 'referral_fee_estimate', $$v)},expression:"form['referral_fee_estimate']"}})]}}])})],1)],1)],1)],1)],1)],1)],1),_vm._v(" "),_c('v-divider'),_vm._v(" "),_c('v-card-actions',{staticClass:"pa-6 pa-md-8 flex-wrap controls"},[_c('v-btn',{staticStyle:{"min-width":"140px"},attrs:{"outlined":"","color":"grey lighten-1","large":""},on:{"click":_vm.back}},[_vm._v("\n        Back\n      ")]),_vm._v(" "),_c('v-spacer'),_vm._v(" "),(_vm.activeStep !== 1)?_c('v-btn',{staticStyle:{"min-width":"140px"},attrs:{"color":"secondary","depressed":"","large":""},on:{"click":_vm.saveData}},[_vm._v("\n        Download PDF\n      ")]):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }