<template>
    <v-dialog
            v-model="dialog"
            scrollable
            fullscreen
            content-class="print-dialog"
        >
            <v-card flat tile>
                <v-card-text>

                    <div class="d-flex">
                        <v-card-title class="mx-0 title">
                            {{ foTitle }}
                        </v-card-title>
                        <v-spacer></v-spacer>
                        <v-btn class="ma-2" icon @click="close" >
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                    </div>
                    
                    <v-data-table
                        class="mx-2"
                        :headers="tableHeader"
                        :items="foObject"
                        :items-per-page="100"
                        hide-default-footer
                        no-data-text="No Records"
                        :mobile-breakpoint="0">

                        <!-- Table Head -->
                        <template v-slot:header.amount="props">
                            <span>{{props.header.text[foType]}}</span>
                        </template>

                        <!-- Table Body -->
                        <template v-slot:item.description="props">
                            <div class="cell top">
                                <div class="text-truncate font-weight-bold">{{ props.item.description.main }}</div>
                                <div class="text-truncate">{{ props.item.description.sub }}</div>
                            </div>
                        </template>

                        <template v-slot:item.owners="props">
                            <div class="cell top">
                                <div class="text-truncate" v-for="(owner, i) in props.item.owners" :key="`${owner}-${i}`">
                                    {{ owner }}
                                </div>
                            </div>
                        </template>

                        <template v-slot:item.share="props">
                            <div v-for="(share, i) in props.item.share" :key="`${share}-${i}`">
                                {{ share }}
                            </div>
                        </template>

                        <template v-slot:item.links="props">
                            <div class="cell top">
                                <div class="text-truncate" v-for="(link, i) in props.item.links" :key="`${link.type}-${i}`">
                                    <span class="pill">{{ link.type }}</span> <span>{{ link.name }}</span>
                                </div>
                            </div>
                        </template>

                    </v-data-table>
                </v-card-text>
            </v-card>
        </v-dialog>
</template>

<script>

    export default {
        name: 'PrintModal',

        props: {
            dialog: {
                type: Boolean,
                default: false
            },
            foTitle: {
                type: String,
                default: ''
            },
            foType: {
                type: String,
                default: ''
            },
            tableHeader: {
                type: Array,
                default: () => ([])
            },
            foObject: {
                type: Array,
                default: () => ([])
            }
        },

        computed: {
            
        },

        methods: {
            
            close() {
                this.$emit('close', true)
            },
            
        },

        watch: {
            selectedLender(newValue) {                
                this.selected_lender = ''                
            }
        },

        data() {
            return {
                selected_lender: '',
                resources: null,
                loader: false
            }
        }
    }
</script>

<style lang="scss">
    .print-dialog {
        margin: 0;
        border-radius: 0;
        box-shadow: none;

        .v-card {
            box-shadow: none
        }
    }
</style>