<template>

  <div class="d-flex flex-column">
        
        <v-list nav dark class="flex-sidebar" :disabled="isDisabled">
            <v-list-item-group v-model="list" :flex="selected" active-class="flex-active">

            <v-list-item 
                v-for="(step, i) in getSteps" 
                :key="i"  
                class="mr-4"
                @click="handleClick(`${i+1}`)" 
                v-if="isStepIncludedServerSide(step)"
                :disabled="isStepDisabledServerSide(step) || step.name === getActiveStepData.name">

                    <v-list-item-action class="mr-4">
                    <v-icon  size="22" color="success" v-if="checkStep(step)">mdi-check-circle-outline</v-icon>
                    <v-icon  size="22" :disabled="isStepDisabledServerSide(step)" v-else>{{ step.icon }}</v-icon>
                    </v-list-item-action>

                    <v-list-item-content>
                    <v-list-item-title v-text="step.name" /> 
                    </v-list-item-content>

            </v-list-item>

            </v-list-item-group>
        </v-list>

  </div>

</template>

<script>

    import { mapState, mapGetters, mapActions, mapMutations } from 'vuex';
    import subscriptions from '~/components/shared/subscriptions'
    import stepStates from '~/components/shared/step-states' // Consider placing this in subscriptions

    export default {
        name: 'StepSelector1',
        mixins: [
          subscriptions, 
          stepStates
        ],
        data(){
            return {
              list: 0,
              saving: false
            }
        },        
        computed: {
            ...mapGetters('flows', ['getSteps', 'getActiveStepData']),
            ...mapGetters('slug', ['getGroupSlug']),
            ...mapGetters('statuses', ['getStepStatuses']),
            ...mapGetters({
                getSpacesObj: 'flows/getSpacesObj',
            }),
            ...mapState({
                activeStep: state => state.flows.activeStep
            }),
            ...mapState('subscriptions', [
                'validation'
            ]),
            selected(){
                let excludedsteps = 0

                this.getStepStatuses.forEach((status, index) => {
                  
                  if(index < parseInt(this.activeStep) -1) {
                    if(status.attributes.included === 0) excludedsteps += 1
                  }
                  
                })

                this.list = parseInt(this.activeStep) - (excludedsteps + 1)

                return this.list
            },
        },
        created() {
          // console.log(this.getSideBarSettings)
        },
        methods: {
            ...mapMutations('auth-actions', [
                'clearUserSetting'
            ]),
            ...mapActions('flows', ['selectStep', 'saveAndExit']),
            handleClick(route) {
                this.selectStep(route)
            },
            checkStep(data) {

                /**
                 * Always show checkmark if validation is false
                 * e.g  viewing from assister view. 
                 */
                if(!this.validation) {
                  return true
                }

                return this.isStepCompleted(data)
            }
        }
    }
</script>

<style scoped lang="scss">

  .flex-active{
      color: var(--v-secondary-base);
  }

  .flex-sidebar.v-list--nav {

    .subheader {
        font-weight: 600;
        font-size: 0.7rem;
        letter-spacing: 4px;
        color: var(--v-accent-base);
    }

    .v-list-item {
      min-height: 40px;

        &::before {
          //background-color: var(--v-accent-base);
        }

      .v-list-item__action {
          margin: 8px 0;
      }

      .v-list-item__content {
          padding: 8px 0;
      }

      .v-list-item__title {
        font-size: 0.9rem;
      }
    }

    .v-list-item:not(:last-child):not(:only-child) {
        margin-bottom: 2px;
    }

  }

</style>
