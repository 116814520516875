<template>
    <v-card :disabled="isDisabled" class="mx-auto" tile flat >
        <v-card-text class="pa-4 pt-0 pa-md-10 pt-md-0">
            <v-sheet max-width="800" class="mx-auto">
                
                <template v-if="people.length > 1">    
                    
                    <v-tabs v-model="selectedPerson" show-arrows :slider-color="slideColor" height="66">
                        <v-tab v-for="person in people" :key="person.id" >
                            <v-list-item class="px-0">
                                <v-list-item-icon class="align-self-center ma-0 mr-3" :color="slideHasError(person.id) ? 'error': ''">
                                    <v-icon size="32" class="mb-2">mdi-account</v-icon>
                                </v-list-item-icon>
                                <v-list-item-content class="text-left">
                                    <v-list-item-title :class="['ma-0', {'error--text': slideHasError(person.id)}]">
                                        {{ getFullName(person) }}
                                    </v-list-item-title> 
                                    <v-list-item-subtitle :class="['mb-2', {'error--text': slideHasError(person.id)}]">
                                        {{ person.roles.length > 1 ? `${person.roles[0]} +${person.roles.length - 1}`: person.roles.length == 1 ? person.roles[0] : '' }}
                                    </v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                        </v-tab>
                    </v-tabs>

                    <v-card class="pt-8" tile flat />
                </template>


                <ValidationObserver ref="observer">
                    <v-tabs-items v-model="selectedPerson">
                        <v-tab-item eager v-for="person in people" :key="person.id">

                            <EntityEditor2 :id="person.id" :loan-kind-id="loanKindId" :data="person" :entity-part-kind-id="getPart(person.id)"/>

                        </v-tab-item>
                    </v-tabs-items>
                </ValidationObserver>

            </v-sheet>

        </v-card-text>
    </v-card>
</template>

<script>
    
    import { mapActions, mapGetters, mapMutations } from 'vuex'
    import subscriptions from '~/components/shared/subscriptions'
    import EntityEditor2 from '~/components/v1/ApplicantEditor2/_EntityEditor2'

    export default {
        mixins: [subscriptions],
        components: {
            EntityEditor2
        },
        data(){
            return {
                loanKindId: null,
                people: [],
                parts: [],
                errorMessages: {},
                selectedPerson: 0,
            }
        },
        created() {

        },
        computed: {
            ...mapGetters('kinds', ['kinds', 'kindsUnformat']),
            getApplicantKinds(){

                let parts = this.kinds.find( kind => {
                    if(kind.name == 'Part'){
                        return kind
                    }
                })

                return parts.children
            },
            slideColor(){

                if(this.people.length) {

                    let id = this.people[this.selectedPerson].id

                    if( Object.keys(this.errorMessages).length > 0 ) {

                        if(this.errorMessages[id] && this.errorMessages[id].length) {
                            return 'error'
                        }

                    }

                }

                return 'primary'
            },
            getLoanId(){
                return this.appData?.relationships?.loan?.data?.id
            },
        },
        methods: {
            ...mapActions('resource-actions', [
                'getLoan',
                'getPeople',
                'updatePerson',
                'createPerson'
            ]),
            async pull(){

      
                try {

                    let promise1 = this.getLoan(this.getLoanId, {"include" : 'parent-parts'})
                    let promise2 = this.getPeople({"include" : 'parent-parts'})

                    const res = await Promise.all([promise1, promise2]) 

                    promise1 = res[0]
                    promise2 = res[1]

                    if(promise1.data?.data?.relationships?.kind?.data?.id){
                        this.loanKindId = promise1.data.data.relationships.kind.data.id
                    }

                    this.people = this.getPeopleByRoles(promise2.data)
                    
                    if(promise2.data.included){
                        this.parts = promise2.data.included.filter( part => {
                            return part.type == "parts"
                        })
                    }


                } catch (error) {

                    console.log('Ooops!..', error)

                }
            },
            async push(){

                const {people} = this
                const observer = this.$refs.observer

                await this.$refs.observer.validate()

                let collection = [] 
                let hasError = false


                people.forEach(person => {

                    let currentObserver = observer.observers.find(o => o.$attrs['data-id'] == person.id)

                    Object.keys(person.attributes).forEach( field => {
                        if(currentObserver.fields[field] && currentObserver.fields[field]["invalid"]) {
                            hasError = true
                        }
                    })

                    if(hasError){
                        this.errorMessages[person.id] = 'Error'
                    } else {
                        delete this.errorMessages[person.id]
                    }


                    let payload = {
                        type: person.type,
                        id: person.id,
                        attributes: this.excludeInvalids(person.attributes, currentObserver.fields)
                    }


                    let res = this.updatePerson(payload)

                    collection.push(res)
                
                })

                const {valid, status} = await this.validateFormData()


                /**
                 *  TEMP: Bizible Code
                 *  we'll move to config later
                 */
                people.forEach(person => {
                    if(window['Bizible']){
                        Bizible.Push('User', {
                            eMail: person.attributes.email  
                        });
                    }
                })

                const data = await this.appDataHelper(collection)

                return {valid, status, data}

            },
            getPart(id){

                let part = this.parts.find( part => {
                    return part.relationships.child.data.id == id
                })

                if(part){
                    return part.relationships.kind.data.id
                }

                return null

            },
            getPeopleByRoles(data){

                if(!data.included){
                    return [];
                }

                let parts = data.included.filter(part => {
                    return part.type == 'parts'
                })

                let roleIds = [];	
                let rolesObj = {}
                
                parts.forEach(part => {
                    
                    if (!part.relationships.kind.data) {	
                        // person must have part with kind
                        return []
                    }
                    
                    this.getApplicantKinds.forEach(kind => {

                        if(kind.id == part.relationships.kind.data.id) {

                            roleIds.push(part.id);
                            rolesObj[part.id] = kind.name

                        }

                    })
                })

                if(roleIds.length == 0) {
                    return []
                } 

                let people = [] 
                
                data.data.forEach( person => {

                    let parentParts = person.relationships['parent-parts'].data

                    person.roles = []

                    let dependantCount = 0

                    
                    if(person.attributes["age-of-dependants"]){
                        dependantCount = person.attributes["age-of-dependants"].split(',')
                        dependantCount = dependantCount.length
                    }

                    person.attributes["number-of-dependants"] = dependantCount // initialize dependants field
                    
                    if( parentParts.length ){

                        let included = false;

                        parentParts.forEach( part => {

                            if(roleIds.includes(part.id) ) {

                                // Add extra data to person
                                person.roles.push(rolesObj[part.id])

                                // Include person once
                                if(!included){
                                    people.push(person)
                                    included = true
                                }

                            }

                        })

                    }

                })

                return people 

            },
            getFullName(person){

                if(!person.hasOwnProperty('attributes')) return

                let fname = person.attributes['first-name']
                let lname = person.attributes['last-name']

                if(!fname && !lname){
                    return 'Person'
                }

                return `${fname ? fname : ''} ${lname ? lname : ''}` 
            },
            slideHasError(id){
                return (this.errorMessages[id] && this.errorMessages[id].length)
            }
        }
    }
</script>

<style scoped lang="scss">

</style>
