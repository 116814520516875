import { mapState, mapActions, mapGetters, mapMutations } from 'vuex'

export default {  
    computed: {
        ...mapState("flows", [
            "calculation",
          ]),
        ...mapGetters('kinds', ['kinds', 'kindsUnformat', 'kindsOfApplicant']),
        ...mapState({
            appId: state => state.flows.appId,
            loanData: state => state.flows.loanData,
        }),
        getLoanType(){
             return this.loanData?.attributes['loan-type']
        },
        getLoanId(){
            return this.appData.relationships.loan.data.id
        },
        ...mapGetters('themes', [
            'getActiveTheme'
        ]),
        getApplicantKinds() {
            let parts = this.kinds.find((kind) => {
              if (kind.name == "Part") {
                return kind;
              }
            });
      
            return parts.children;
        },
        isPersonal(){
            if(this.getLoanType){
                return  this.getLoanType == 'Unsecured'
            }
            return false
        },
        isConsumer(){
            if(this.loanData){
                return  this.loanData.attributes['finance-for-business-purposes'] == 'No'
            }
            return false
        },
        isCommercial(){

            if(this.loanData){
                return  this.loanData.attributes['finance-for-business-purposes'] == 'Yes'
            }
            return false
        },
        primaryApplicantId(){
        return this.calculation['primary-applicant-id']
        },
    },
    methods: {

        filterResourceFields(source, target){

            // console.log(source)
            // console.log(target)

            let newResource = {
                    type: "",
                    id: null,
                    attributes: {},
                    relationships: {}
            }

            Object.keys(source.attributes).forEach( field => {
                newResource.attributes[field] = target.attributes[field]
            })

            newResource.type = target.type
            newResource.id = target.id

            if(target.hasOwnProperty('relationships')) {

                Object.keys(target.relationships).forEach(item => {

                    if( target.relationships[item].hasOwnProperty('data') ) {
                        newResource.relationships[item] = target.relationships[item]
                    }
                })

            }
            return newResource
        },

        getPeopleByRoles(data){

            if(!data.included){
                return [];
            }

            let parts = data.included.filter(part => {
                return part.type == 'parts'
            })

            let roleIds = [];	
            let rolesObj = {}
            
            parts.forEach(part => {
                
                if (!part.relationships.kind.data) {	
                    // person must have part with kind
                    return []
                }
                
                this.getApplicantKinds.forEach(kind => {

                    if(kind.id == part.relationships.kind.data.id) {

                        roleIds.push(part.id);
                        rolesObj[part.id] = kind.name

                    }

                })
            })

            if(roleIds.length == 0) {
                return []
            } 

            let people = [] 
            
            data.data.forEach( person => {

                let parentParts = person.relationships['parent-parts'].data

                person.roles = []

                let dependantCount = 0

                
                if(person.attributes["age-of-dependants"]){
                    dependantCount = person.attributes["age-of-dependants"].split(',')
                    dependantCount = dependantCount.length
                }

                person.attributes["number-of-dependants"] = dependantCount // initialize dependants field
                
                if( parentParts.length ){

                    let included = false;

                    parentParts.forEach( part => {

                        if(roleIds.includes(part.id) ) {

                            // Add extra data to person
                            person.roles.push(rolesObj[part.id])

                            // Include person once
                            if(!included){
                                people.push(person)
                                included = true
                            }

                        }

                    })

                }

            })

            return people 

        },
        hexToRGBA(hex, a = 1){
            return this.$options.filters.hexToRGBA(hex, a)
        },
        getFriendlyName(role){

            let fullname = ''

            if(role && role.type == 'businesses') {

                fullname += role.attributes['business-legal-name'] ? role.attributes['business-legal-name'] : 'Business'

            }

            if(role && role.type == 'people'){

                fullname += role.attributes['first-name'] ? role.attributes['first-name'] + ' ' : ''

                fullname += role.attributes['last-name'] ? role.attributes['last-name'] : ''

                if(!role.attributes['first-name'] && !role.attributes['last-name']){
                    fullname = 'Person'
                }
            }

            return fullname

        },
        getRoleKind(role){

            let roleKind = null
            let roleKindName = ''

            if( role.hasOwnProperty('relationships') ){

                roleKind = this.kindsUnformat.find(kind => kind.id == role.relationships?.kind?.data?.id)

                if(roleKind){
                    roleKindName = roleKind.attributes.name 
                }
            }

            return roleKindName

        },
        getRolesEntity(entity){
                
            let roles = []

            let entityParentParts = entity.relationships['parent-parts']

            if(entity.parent){

                let parent = entity.parent

                let parentChildParts = parent.relationships['child-parts']
    
    
                if(entityParentParts.data.length > 0 && parentChildParts.data.length > 0){
    
                    let parentChildPartsIds = []
    
                    parentChildParts.data.forEach( part => {
                        parentChildPartsIds.push(part.id)
                    })
    
    
                    entityParentParts.data.forEach( (part1) => {
    
                        if(parentChildPartsIds.includes(part1.id)){
    
                            this.combinedEntitiesParts.forEach(part2 => {
    
                                if(part1.id == part2.id){
        
                                    let partKind = this.kindsUnformat.find(kind => kind.id == part2.relationships?.kind?.data?.id)
        
                                    if(!roles.includes(partKind.attributes.name)){
                                        roles.push(partKind.attributes.name)
                                    }
                                
                                }
        
                            })
    
                        }
    
                    });
    
                }


            } else {


                if(entityParentParts.data.length > 0){
    
                    entityParentParts.data.forEach( (part1) => {
    
                        this.combinedEntitiesParts.forEach(part2 => {

                            if(part1.id == part2.id){
    
                                let partKind = this.kindsUnformat.find(kind => kind.id == part2.relationships?.kind?.data?.id)
    
                                if(!roles.includes(partKind.attributes.name)){
                                    roles.push(partKind.attributes.name)
                                }
                            
                            }
    
                        })

    
                    });
    
                }


            }

            
            return roles

        },
        getFullName(person){

            if(!person.hasOwnProperty('attributes')) return

            let fname = person.attributes['first-name']
            let lname = person.attributes['last-name']

            if(!fname && !lname){
                return 'Person'
            }

            return `${fname ? fname : ''} ${lname ? lname : ''}` 
        },
        getLenderLogo(name){

            let lender = require(`~/assets/images/logo/placeholder.png`)

            try {

                lender = require(`~/assets/images/logo/${name}.png`)
                return lender

            } catch (error) {

                console.log('Ooops!..', error)
                return lender

            }

        },

        isBtnGroup(key) {
            let keys = [
                "drivers-licence-has-special-restriction"
            ];
        
            return keys.includes(key);
        },
    }
}