<template>
    <v-card flat class="mx-auto" :disabled="isDisabled">
        <v-card-text class="pa-0 px-10">
            <ValidationObserver ref="observer" tag="form">

                <div class="flex-form" >

                    <template v-for="(field, key) in resources.purchase.attributes">
                        <div v-if="isVisible(key)" class="flex-field" :key="key">
                            <ValidationProvider 
                                v-slot="{ errors }" 
                                :hidden="isHidden(key)"
                                :name="labels[key]"
                                :rules="getRules(key)" 
                                :vid="key">

                                <v-select
                                    v-if="isFieldType('picklist', key)"
                                    v-model="resources.purchase.attributes[key]"
                                    :items="getOptions(key)"
                                    color="secondary"
                                    :error-messages="errors"
                                    :label="labels[key]">
                                </v-select>

                                <v-text-field 
                                    v-else
                                    :label="labels[key]"
                                    color="secondary"
                                    :error-messages="errors"
                                    v-model="resources.purchase.attributes[key]">
                                </v-text-field>

                            </ValidationProvider>
                        </div>
                    </template>
                    
                </div>
                    
            </ValidationObserver>
        </v-card-text>
    </v-card>
</template>
<script>

    import { mapActions, mapState, mapMutations } from 'vuex'
    import helpers from '~/plugins/helpers'
    import subscriptions from '~/components/shared/subscriptions'
    import fieldOptions from '~/components/shared/field-options'
    import { isEmpty } from 'lodash'

    export default {
        name: "LenderWisrSecurity1",

        components: {  },

        mixins: [ helpers, subscriptions ],

        data() {
            return {
                purchase: null,
                resources: {
                    purchase: {
                        attributes: {
                            "vendor-known": null,
                            "vin": null,
                            "registration-number": null,
                            "registration-state": null,
                            "odometer": null,
                            "condition": null,
                            "__spacer": null
                        }
                    }
                },
                visible: {
                    "vin": false,
                    "registration-number": false,
                    "registration-state": false,
                    "odometer": false,
                },
                hidden: {
                    "__spacer": true,
                },
                labels: {
                    "vendor-known": "Vehicle Found",
                    "vin": "VIN No",
                    "registration-number": "Registration No",
                    "registration-state": "Registration State",
                    "odometer": "Odometer",
                    "condition": "Condition",
                    "__spacer": null
                },
                validation_rules: {
                    attributes: {
                        "vendor-known": "string|in:Yes,No",
                        "vin": "string|valid_vin",
                        "registration-number": "string",
                        "registration-state": `|in:${fieldOptions["drivers-licence-state"]}`,
                        "odometer": "integer",
                        "condition": "string|in:New,Used",
                        "__spacer": ""
                    }
                }
            }
        },

        computed: {
            ...mapState({
                appId: state => state.flows.appId
            }),
            vehicleKnown() {
                return this.resources.purchase.attributes['vendor-known']
            }
        },

        methods: {
            ...mapActions('resource-actions', [
                'getPurchases',
                'updatePurchases'
            ]),
            async pull() {

                try {

                    let res = await this.getPurchases()

                    if(res.status === 200) {
                        this.purchase = res.data.data[0]

                        await this.populateFields()
                    }

                } catch (err) {
                    console.log(err)
                }

            },
            async push() {

                try {

                    let res = null

                    if(this.purchase) {

                        res = await this.updatePurchases({
                            "id": this.purchase.id,
                            "type": "purchases",
                            "attributes": {
                                ...this.resources.purchase.attributes
                            }
                        })

                    }

                    const {valid, status} = await this.validateFormData()

                    return {valid, status, data: {}}

                } catch (err) {
                    console.log(err)
                }

            },
            populateFields() {

                let { purchase, resources } = this

                if(purchase) {

                    Object.keys(resources.purchase.attributes).forEach(key => {
                        resources.purchase.attributes[key] = purchase.attributes[key]
                    })

                }

            },
            isVisible(key){
                
                const {visible} = this
    
                if(visible.hasOwnProperty(key)){
                    return visible[key]
                }
    
                return true
    
            },
            isHidden(key){
                
                const {hidden} = this
    
                if(hidden.hasOwnProperty(key)){
                    return hidden[key]
                }
    
                return false
    
            },
            resetFields() {
                this.resources.purchase.attributes['vin'] = null
                this.resources.purchase.attributes['registration-number'] = null
                this.resources.purchase.attributes['registration-state'] = null
                this.resources.purchase.attributes['odometer'] = null
            }
        },

        watch: {
            vehicleKnown(val) {

                if(val && val == 'Yes') {
                    this.visible['vin'] = true
                    this.visible['registration-number'] = true
                    this.visible['registration-state'] = true
                    this.visible['odometer'] = true
                } else {
                    this.visible['vin'] = false
                    this.visible['registration-number'] = false
                    this.visible['registration-state'] = false
                    this.visible['odometer'] = false
                    this.resetFields()
                }
                
            }
        }

    }
</script>
<style lang="scss" scoped>
    .flex-form {
        display: grid;
        grid-column-gap: 50px;
        grid-row-gap: 0;
        grid-template-columns:  repeat(auto-fit, minmax(260px, 1fr));

        .flex-field {
            align-self: start;
        }
    }

</style>