export default function (context) {

    if(parseInt(process.env.envNCMaintenance) && context.route.name != '503'){
        context.redirect('/503')
        return
    }

    if(parseInt(process.env.envNCMaintenance) == false && context.route.name == '503'){
        context.redirect('/')
    }

}