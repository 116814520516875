<template>
    <v-dialog
        v-model="dialog"
        width="700"
        scrollable
        persistent>
   

        <v-card flat class="ol-residential-history-address-form">
            <v-card-title class="pa-0 ma-0 pr-5 pt-3 mb-n5">
                <v-spacer></v-spacer>
                <v-btn icon rounded small @click="close" depressed>
                    <v-icon color="primary" size="20">mdi-close</v-icon>
                </v-btn>
            </v-card-title>
            
            <v-card-title class="px-8 text-center">
                <div class="sm pa-0 text-center" style="width:100%">
                   {{title}}
                </div>
            </v-card-title>
            <v-card-text class="mt-n4" :class="vb.mdAndUp ? 'px-16 ' : 'px-4'">
                <ValidationObserver ref="observer1" tag="form">
                    <!-- <ValidationProvider>
                        <v-select v-model="selected" placeholder="Select existing Adress" :items="addressItems"  @change="selectedAddress"></v-select>
                    </ValidationProvider> -->
                    <ValidationProvider v-slot="{ errors }" name="Address" rules="required">
                        <google-address-tool
                            name="address"
                            :errors="errors"
                            v-model="fullAddress" 
                            @change="handleAddressToolChange"
                        ></google-address-tool>
                    </ValidationProvider>
                    <v-row v-if="false">
                        <v-col :lg="field.size" :md="field.size" :sm="field.size" v-for="(field, key) in fields" :key="key">
                            <ValidationProvider v-slot="{ errors }"  :name="field.name"  rules="required">
                                <v-text-field :error-messages="errors" :label="field.name" v-model="resources.address.attributes[field.model]" >
                                </v-text-field>
                            </ValidationProvider>
                        </v-col>
                    </v-row>
                    </ValidationObserver>
            </v-card-text>
            <!-- <v-card-text class="d-flex justify-center" v-if="actionType == 'edit'">
                <v-btn class="px-10 mx-4 secondary" :loading="isSaving" depressed @click="update">update</v-btn>
            </v-card-text> -->
            <v-card-text class="d-flex justify-center pb-8" >
                <v-btn class="px-10 " width="140" height="50" depressed large @click="back">Back</v-btn>
                <v-btn class="px-10 mx-4 secondary" width="140" height="50" depressed large @click="nextStep">Next</v-btn>
            </v-card-text>
        </v-card>

    </v-dialog>
</template>
<script>
import {store,mutations} from './__store'
import shared from './__shared'
import { mapState, mapActions, mapGetters } from 'vuex'
import resourceHelpers from '~/components/shared/resource-helpers'
import subscriptions from '~/components/shared/subscriptions'
import GoogleAddressTool from '~/components/base/GoogleAddressTool'
import formHelpers from '~/components/shared/form-helpers.js'
import validation from '~/components/shared/validation'
export default {
    components: {
       GoogleAddressTool
    },
    mixins: [subscriptions,resourceHelpers,formHelpers,validation,shared],
    props : {
        title: {
            type: String,
            default: 'Title'
        },
        step: {
            type: String,
            default: ''
        },
        addresses: {
            type:Array,
            default: () => [] 
        },
        address:{
            Object,
            default: () => {}
        },
        actionType: {
            type:String,
            default: ''
        },
        occupancySituation : {
            type: String,
            default: 'Previous'
        }
    },
    computed: {
        ...mapState({
            appId: state => state.flows.appId,
        })
    },
    watch: {
        dialog(val) {
            //console.log(this.actionType)
            if(val) {
                if(this.address.address && Object.keys(this.address.address).length > 0) {
                    this.resources.address.attributes['street-number'] = this.address.address.attributes['street-number']
                    this.resources.address.attributes['street-name'] = this.address.address.attributes['street-name']
                    this.resources.address.attributes['street-type'] = this.address.address.attributes['street-type']
                    this.resources.address.attributes['suburb'] = this.address.address.attributes['suburb']
                    this.resources.address.attributes['state'] = this.address.address.attributes['state']
                    this.resources.address.attributes['postcode'] = this.address.address.attributes['postcode']
                    this.fullAddress = this.getFullAddress(this.address.address)
                }
            } else {
                if(this.actionType != 'edit')
                    this.fullAddress = ''
            }

            //console.log(this.fullAddress)

        },
        "step": {
            handler (val) {
                val == 'address-form' ? this.dialog = true : this.dialog = false
            }
        },
        "addresses": {
            handler (val) {
                this.getCompleteAddress(val)
            }
        },
        "address": {
            handler (val) {
                if (val.hasOwnProperty('address')){
                    this.resources.address.attributes['street-number'] = val.address.attributes['street-number']
                    this.resources.address.attributes['street-name'] = val.address.attributes['street-name']
                    this.resources.address.attributes['street-type'] = val.address.attributes['street-type']
                    this.resources.address.attributes['suburb'] = val.address.attributes['suburb']
                    this.resources.address.attributes['state'] = val.address.attributes['state']
                    this.resources.address.attributes['postcode'] = val.address.attributes['postcode']
                } else {
                    this.resources.address.attributes['street-number'] = ""
                    this.resources.address.attributes['street-name'] = ""
                    this.resources.address.attributes['street-type'] = ""
                    this.resources.address.attributes['suburb'] = ""
                    this.resources.address.attributes['state'] = ""
                    this.resources.address.attributes['postcode'] = ""
                }
            }
        },
        'actionType': {
            handler (val) {
                return val
            }
        }
    },
    methods: {
        ...mapActions('resource-actions',['createAddress','getAddress','updateAddress']),
        async nextStep(data) {
           let valid = await this.$refs.observer1.validate();
           if(valid){
            //    console.log('form address',this.resources.address)
               this.saveAddress()
               this.$emit('step','date-move')
           }  
        },
        back() {
            this.$emit('back',null)
            //this.dialog = false
        },
        async selectedAddress () {
            let data = await this.getAddress(this.selected)
            let obj = data.data.data.attributes
            this.resources.address.attributes['street-number'] = obj['street-number']
            this.resources.address.attributes['street-name'] = obj['street-name']
            this.resources.address.attributes['street-type'] = obj['street-type']
            this.resources.address.attributes['suburb'] = obj['suburb']
            this.resources.address.attributes['state'] = obj['state']
            this.resources.address.attributes['postcode'] = obj['postcode']
        },
        getCompleteAddress (items) {
            if(items.length > 0) {
                items.forEach(data => {
                    let item  = this.getFullAddress(data.address)
                    if(data?.address?.id){
                        this.addressItems.push({value: data?.address?.id, text:item})
                    }
                })
               
            }
        },
        handleAddressToolChange(address, obj){
            this.resources.address.attributes['street-number'] = obj['street-number']
            this.resources.address.attributes['street-name'] = obj['street-name']
            this.resources.address.attributes['street-type'] = obj['street-type']
            this.resources.address.attributes['suburb'] = obj['suburb']
            this.resources.address.attributes['state'] = obj['state']
            this.resources.address.attributes['postcode'] = obj['postcode']
        },
        async saveAddress() {
            this.resources.address.relationships.application.data.id = this.appId
            mutations.setAddressResource(this.resources.address)
        },
        close(){
           this.$emit('close',null)
        },
        async update () {
            this.isSaving = true
            let valid = await this.$refs.observer1.validate()
            if (valid) {
                // this.resources.address.relationships.occupancies = this.address.address.relationships.occupancies
                // this.resources.address.id = this.address.address.id
                // this.resources.address.relationships.application = this.address.address.relationships.application
                // await this.updateAddress(this.resources.address)
                // this.address.address.attributes = {...this.resources.address.attributes}
                // this.$emit('updateTable',this.address)
                // this.$emit('back',null)
            }
            this.isSaving = false
        }
    },
    data() {
        return {
            dialog :false,
            form: {

            },
            isSaving: false,
            selected: '',
            fullAddress: '',
         
            fields: [
                {name: 'Street Number', model: 'street-number',size: 4,require: 'required'},
                {name: 'Street Name', model: 'street-name',size: 4,require: 'required'},
                {name: 'Street Type', model: 'street-type',size: 4,require: 'required'},
                {name: 'Suburb', model: 'suburb',size: 4,require: ''},
                {name: 'State', model: 'state',size: 4,require: 'required'},
                {name: 'Postcode', model: 'postcode',size: 4,require: 'required'},
            ],
            addressItems: [{value:"",text:""}],
            resources: {
                    people: {
                        type: 'people',
                        attributes: {}
                    },
                    business: {
                        type: 'businesses',
                        attributes: {}
                    },
                    address: {
                        type: 'addresses',
                        attributes: {
                            "street-number": null,
                            "street-name": null,
                            "street-type": null,
                            "suburb": null,
                            "state": null,
                            "postcode": null
                        },
                        relationships: {
                            application: {
                                data: { type: "applications", id: null } 
                            }
                        }
                    },
                    occupancy: {
                        type: 'occupancies',
                        attributes: {
                            "occupancy-situation": null,
                            "residential-situation": null,
                            "start-date": null,
                            "end-date": null
                        },
                        relationships: {
                            application: {
                                data: { type: "applications", id:  null} 
                            }
                        }
                    },
            },
        }
    }
}
</script>
<style lang="scss" >
    .ol-residential-history-address-form {
        .close-icon {
            position: absolute;
            right: 40px;
            top: 30px;
            //background: var(--v-secondary-lighten2);
            padding: 6px;
            border-radius: 36px !important;
        }
    }
</style>