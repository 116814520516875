
export default async (context) => {

    if(!context.$auth.loggedIn) {
        
        try {

            const guest = await context.app.$axios.post(`oauth/token`)

            context.$api.setHeader('Authorization', 'Bearer ' + guest.data.access_token)
    
        } catch (error) {
            
            console.log('Uh oh!', error)

        }

    }


}

